export interface MedicalItemOrder {
    id: string
    weeklyAccepted: number[]
    weeklyRejected: number[]
    weeklyHold: number[]
    weeklyBackordered: number[]
}

export const mockMedicalItemOrders: MedicalItemOrder[] = [
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        44,
        49,
        28,
        5,
        46,
        29,
        2,
        38,
        4,
        3,
        46,
        5,
        38,
        21,
        17,
        20,
        41,
        48,
        8,
        32,
        13,
        49,
        38,
        49,
        23,
        32,
        0,
        41,
        14,
        49,
        29,
        19,
        49,
        27,
        29,
        34,
        47,
        48,
        49,
        49,
        22,
        34,
        43,
        19,
        9,
        28,
        8,
        1,
        7,
        22,
        0,
        32
      ],
      "weeklyRejected": [
        49,
        0,
        21,
        23,
        34,
        40,
        49,
        0,
        49,
        32,
        20,
        28,
        34,
        25,
        48,
        2,
        0,
        42,
        21,
        0,
        37,
        33,
        38,
        49,
        27,
        49,
        34,
        30,
        47,
        41,
        31,
        47,
        33,
        13,
        23,
        49,
        31,
        28,
        1,
        18,
        31,
        0,
        42,
        43,
        49,
        37,
        49,
        43,
        6,
        26,
        0,
        30
      ],
      "weeklyHold": [
        1,
        35,
        28,
        27,
        25,
        18,
        25,
        11,
        0,
        9,
        38,
        29,
        26,
        19,
        23,
        0,
        12,
        7,
        11,
        32,
        0,
        17,
        15,
        31,
        11,
        48,
        0,
        14,
        21,
        49,
        26,
        30,
        0,
        34,
        44,
        49,
        14,
        0,
        49,
        28,
        15,
        40,
        48,
        28,
        24,
        15,
        3,
        0,
        0,
        0,
        21,
        4
      ],
      "weeklyBackordered": [
        0,
        42,
        0,
        28,
        49,
        33,
        31,
        47,
        0,
        22,
        29,
        30,
        6,
        10,
        4,
        22,
        30,
        30,
        28,
        0,
        44,
        21,
        10,
        21,
        33,
        12,
        5,
        31,
        27,
        49,
        26,
        6,
        28,
        26,
        0,
        0,
        1,
        13,
        30,
        49,
        24,
        28,
        49,
        49,
        19,
        45,
        25,
        18,
        31,
        0,
        38,
        30
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        24,
        49,
        0,
        13,
        28,
        14,
        7,
        36,
        27,
        12,
        1,
        15,
        24,
        32,
        20,
        11,
        40,
        0,
        29,
        33,
        49,
        2,
        19,
        0,
        7,
        26,
        19,
        28,
        13,
        33,
        0,
        38,
        49,
        14,
        48,
        2,
        38,
        46,
        18,
        49,
        29,
        47,
        47,
        37,
        48,
        3,
        10,
        44,
        19,
        44,
        16,
        48
      ],
      "weeklyRejected": [
        38,
        12,
        22,
        2,
        36,
        20,
        0,
        49,
        32,
        1,
        34,
        47,
        0,
        48,
        48,
        14,
        0,
        49,
        16,
        8,
        0,
        12,
        10,
        22,
        24,
        14,
        49,
        29,
        17,
        26,
        49,
        24,
        46,
        32,
        0,
        41,
        35,
        49,
        49,
        16,
        4,
        40,
        21,
        3,
        49,
        10,
        16,
        0,
        0,
        13,
        0,
        49
      ],
      "weeklyHold": [
        11,
        20,
        31,
        29,
        11,
        48,
        26,
        49,
        30,
        0,
        33,
        20,
        48,
        26,
        35,
        0,
        2,
        22,
        37,
        25,
        22,
        27,
        2,
        21,
        44,
        15,
        1,
        21,
        17,
        34,
        48,
        32,
        32,
        44,
        14,
        49,
        0,
        31,
        22,
        17,
        43,
        49,
        27,
        49,
        21,
        0,
        17,
        40,
        0,
        14,
        0,
        20
      ],
      "weeklyBackordered": [
        31,
        0,
        0,
        22,
        49,
        29,
        47,
        8,
        20,
        4,
        0,
        25,
        3,
        1,
        41,
        37,
        22,
        44,
        31,
        46,
        27,
        40,
        9,
        34,
        11,
        10,
        19,
        16,
        41,
        29,
        3,
        28,
        12,
        37,
        4,
        0,
        5,
        22,
        14,
        4,
        17,
        28,
        0,
        49,
        1,
        49,
        16,
        30,
        27,
        49,
        1,
        35
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        49,
        18,
        31,
        11,
        1,
        0,
        11,
        48,
        29,
        15,
        23,
        0,
        49,
        46,
        1,
        14,
        49,
        17,
        0,
        34,
        27,
        0,
        38,
        21,
        19,
        47,
        41,
        17,
        6,
        8,
        14,
        25,
        2,
        5,
        48,
        14,
        31,
        16,
        28,
        49,
        4,
        41,
        49,
        40,
        45,
        14,
        21,
        39,
        3,
        1,
        37,
        39
      ],
      "weeklyRejected": [
        49,
        49,
        44,
        20,
        5,
        15,
        13,
        38,
        49,
        24,
        45,
        38,
        16,
        26,
        48,
        0,
        3,
        43,
        22,
        18,
        14,
        8,
        10,
        49,
        18,
        49,
        35,
        43,
        47,
        34,
        26,
        18,
        38,
        14,
        0,
        34,
        8,
        30,
        23,
        47,
        31,
        20,
        42,
        22,
        24,
        18,
        38,
        48,
        0,
        28,
        13,
        13
      ],
      "weeklyHold": [
        14,
        0,
        40,
        38,
        1,
        26,
        27,
        2,
        22,
        49,
        28,
        1,
        31,
        49,
        21,
        24,
        0,
        9,
        0,
        49,
        34,
        21,
        10,
        0,
        41,
        48,
        10,
        43,
        20,
        9,
        48,
        0,
        27,
        29,
        8,
        33,
        48,
        6,
        24,
        0,
        29,
        17,
        0,
        35,
        27,
        29,
        49,
        21,
        0,
        29,
        26,
        49
      ],
      "weeklyBackordered": [
        40,
        35,
        0,
        45,
        34,
        42,
        40,
        49,
        38,
        7,
        22,
        49,
        41,
        22,
        0,
        17,
        40,
        40,
        0,
        37,
        38,
        26,
        12,
        0,
        37,
        26,
        29,
        49,
        19,
        48,
        22,
        49,
        4,
        37,
        0,
        0,
        11,
        25,
        0,
        49,
        35,
        36,
        4,
        49,
        0,
        9,
        37,
        28,
        22,
        34,
        17,
        10
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        23,
        49,
        29,
        44,
        16,
        4,
        14,
        49,
        31,
        8,
        24,
        5,
        13,
        39,
        8,
        6,
        49,
        0,
        39,
        49,
        27,
        14,
        43,
        0,
        24,
        41,
        15,
        20,
        32,
        20,
        11,
        17,
        18,
        0,
        20,
        34,
        32,
        48,
        9,
        49,
        32,
        30,
        49,
        36,
        49,
        0,
        2,
        36,
        4,
        43,
        11,
        9
      ],
      "weeklyRejected": [
        49,
        29,
        49,
        45,
        18,
        20,
        10,
        20,
        49,
        30,
        48,
        49,
        19,
        22,
        48,
        5,
        39,
        49,
        25,
        42,
        0,
        0,
        49,
        37,
        0,
        49,
        42,
        49,
        47,
        28,
        39,
        32,
        48,
        49,
        18,
        49,
        28,
        21,
        30,
        49,
        48,
        17,
        15,
        0,
        39,
        38,
        35,
        14,
        0,
        49,
        7,
        43
      ],
      "weeklyHold": [
        29,
        31,
        41,
        1,
        0,
        0,
        46,
        16,
        22,
        22,
        47,
        34,
        48,
        21,
        31,
        13,
        0,
        12,
        22,
        12,
        32,
        49,
        28,
        20,
        35,
        20,
        36,
        3,
        29,
        22,
        31,
        18,
        0,
        21,
        4,
        33,
        5,
        3,
        10,
        41,
        49,
        33,
        0,
        49,
        47,
        22,
        49,
        49,
        4,
        49,
        36,
        5
      ],
      "weeklyBackordered": [
        25,
        16,
        24,
        33,
        49,
        28,
        6,
        32,
        5,
        0,
        30,
        35,
        43,
        32,
        33,
        2,
        34,
        47,
        9,
        23,
        35,
        34,
        49,
        30,
        11,
        33,
        26,
        15,
        31,
        29,
        34,
        21,
        45,
        49,
        49,
        0,
        31,
        6,
        29,
        0,
        37,
        31,
        8,
        42,
        25,
        49,
        35,
        13,
        36,
        28,
        3,
        9
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyAccepted": [
        37,
        30,
        3,
        0,
        12,
        41,
        3,
        23,
        48,
        8,
        44,
        24,
        22,
        0,
        46,
        29,
        49,
        0,
        0,
        0,
        25,
        40,
        19,
        49,
        0,
        17,
        19,
        26,
        31,
        25,
        0,
        43,
        49,
        8,
        37,
        44,
        40,
        0,
        30,
        37,
        19,
        27,
        49,
        18,
        49,
        24,
        14,
        30,
        49,
        33,
        29,
        16
      ],
      "weeklyRejected": [
        11,
        16,
        2,
        47,
        3,
        0,
        17,
        3,
        19,
        26,
        29,
        30,
        13,
        45,
        25,
        31,
        30,
        49,
        12,
        32,
        18,
        0,
        6,
        4,
        16,
        5,
        41,
        47,
        23,
        14,
        37,
        12,
        43,
        11,
        0,
        1,
        24,
        49,
        1,
        49,
        34,
        48,
        7,
        46,
        49,
        10,
        0,
        22,
        13,
        44,
        0,
        49
      ],
      "weeklyHold": [
        15,
        49,
        23,
        1,
        0,
        42,
        13,
        49,
        7,
        4,
        40,
        11,
        1,
        17,
        35,
        12,
        3,
        48,
        37,
        11,
        31,
        10,
        8,
        7,
        49,
        48,
        35,
        29,
        35,
        2,
        48,
        17,
        31,
        0,
        26,
        5,
        7,
        0,
        46,
        1,
        43,
        30,
        17,
        24,
        35,
        47,
        0,
        41,
        5,
        29,
        27,
        15
      ],
      "weeklyBackordered": [
        31,
        0,
        32,
        4,
        32,
        41,
        19,
        28,
        29,
        0,
        49,
        0,
        31,
        33,
        22,
        37,
        43,
        24,
        14,
        0,
        29,
        3,
        20,
        34,
        22,
        19,
        30,
        49,
        49,
        45,
        19,
        29,
        41,
        31,
        49,
        14,
        12,
        0,
        44,
        49,
        23,
        40,
        11,
        34,
        0,
        26,
        3,
        47,
        21,
        49,
        33,
        22
      ]
    },
    {
      "id": "049BCC88",
      "weeklyAccepted": [
        14,
        43,
        30,
        48,
        21,
        9,
        1,
        40,
        27,
        33,
        33,
        4,
        43,
        13,
        18,
        23,
        25,
        26,
        0,
        49,
        22,
        37,
        25,
        47,
        26,
        37,
        19,
        49,
        34,
        1,
        17,
        27,
        49,
        39,
        43,
        46,
        4,
        16,
        43,
        49,
        11,
        28,
        47,
        0,
        29,
        0,
        0,
        47,
        22,
        46,
        0,
        42
      ],
      "weeklyRejected": [
        0,
        45,
        28,
        42,
        39,
        28,
        18,
        49,
        22,
        1,
        40,
        15,
        23,
        48,
        27,
        21,
        33,
        14,
        27,
        49,
        44,
        17,
        0,
        42,
        7,
        24,
        48,
        49,
        48,
        0,
        49,
        47,
        46,
        13,
        0,
        49,
        10,
        17,
        1,
        49,
        45,
        6,
        35,
        21,
        49,
        31,
        35,
        5,
        0,
        27,
        0,
        39
      ],
      "weeklyHold": [
        49,
        35,
        49,
        1,
        49,
        31,
        0,
        21,
        13,
        27,
        15,
        25,
        13,
        11,
        0,
        0,
        2,
        18,
        0,
        2,
        8,
        49,
        39,
        33,
        49,
        48,
        0,
        15,
        34,
        37,
        32,
        19,
        0,
        29,
        11,
        19,
        35,
        34,
        36,
        49,
        20,
        49,
        22,
        28,
        35,
        49,
        24,
        42,
        4,
        7,
        30,
        46
      ],
      "weeklyBackordered": [
        12,
        49,
        8,
        0,
        47,
        4,
        35,
        1,
        6,
        0,
        6,
        35,
        3,
        49,
        22,
        28,
        32,
        48,
        0,
        6,
        40,
        33,
        12,
        8,
        2,
        9,
        1,
        43,
        34,
        12,
        45,
        26,
        28,
        49,
        0,
        11,
        49,
        8,
        40,
        27,
        11,
        29,
        0,
        26,
        12,
        25,
        18,
        31,
        46,
        49,
        38,
        22
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        46,
        22,
        25,
        22,
        20,
        18,
        31,
        28,
        48,
        27,
        49,
        15,
        36,
        19,
        8,
        43,
        33,
        0,
        20,
        38,
        3,
        41,
        17,
        44,
        0,
        32,
        31,
        12,
        14,
        49,
        34,
        17,
        49,
        42,
        12,
        24,
        15,
        31,
        49,
        44,
        38,
        49,
        49,
        49,
        9,
        21,
        5,
        21,
        27,
        42,
        43,
        48
      ],
      "weeklyRejected": [
        41,
        1,
        43,
        33,
        49,
        28,
        18,
        23,
        14,
        1,
        2,
        0,
        41,
        47,
        5,
        47,
        33,
        39,
        33,
        0,
        35,
        38,
        24,
        14,
        40,
        49,
        0,
        0,
        7,
        0,
        18,
        25,
        48,
        27,
        49,
        49,
        25,
        31,
        1,
        0,
        37,
        35,
        25,
        9,
        49,
        20,
        0,
        14,
        2,
        26,
        0,
        47
      ],
      "weeklyHold": [
        0,
        31,
        49,
        1,
        38,
        39,
        2,
        49,
        19,
        6,
        47,
        1,
        8,
        49,
        20,
        31,
        18,
        20,
        0,
        0,
        21,
        49,
        29,
        49,
        39,
        14,
        12,
        30,
        41,
        12,
        23,
        0,
        12,
        49,
        20,
        29,
        36,
        0,
        49,
        45,
        0,
        13,
        12,
        27,
        47,
        17,
        31,
        17,
        0,
        40,
        49,
        16
      ],
      "weeklyBackordered": [
        15,
        6,
        1,
        3,
        47,
        43,
        28,
        21,
        47,
        18,
        45,
        3,
        3,
        21,
        47,
        31,
        26,
        18,
        0,
        49,
        0,
        46,
        8,
        27,
        16,
        12,
        49,
        1,
        12,
        49,
        22,
        28,
        0,
        49,
        0,
        13,
        1,
        48,
        49,
        36,
        1,
        22,
        8,
        49,
        3,
        0,
        39,
        30,
        44,
        23,
        1,
        12
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        0,
        40,
        31,
        48,
        33,
        9,
        49,
        49,
        48,
        3,
        49,
        0,
        34,
        0,
        49,
        26,
        49,
        34,
        19,
        15,
        21,
        9,
        34,
        45,
        7,
        47,
        35,
        0,
        1,
        22,
        27,
        45,
        23,
        31,
        48,
        2,
        0,
        48,
        11,
        34,
        28,
        28,
        49,
        29,
        23,
        0,
        21,
        5,
        0,
        23,
        12,
        48
      ],
      "weeklyRejected": [
        49,
        24,
        30,
        47,
        8,
        36,
        45,
        46,
        17,
        42,
        30,
        29,
        49,
        25,
        45,
        26,
        0,
        38,
        40,
        0,
        49,
        0,
        12,
        43,
        32,
        18,
        38,
        3,
        40,
        30,
        38,
        25,
        48,
        15,
        48,
        44,
        20,
        21,
        40,
        6,
        38,
        30,
        49,
        0,
        41,
        14,
        23,
        22,
        18,
        45,
        0,
        49
      ],
      "weeklyHold": [
        30,
        32,
        12,
        22,
        22,
        26,
        17,
        2,
        6,
        27,
        47,
        18,
        20,
        27,
        0,
        49,
        0,
        36,
        0,
        5,
        36,
        26,
        10,
        7,
        43,
        25,
        47,
        16,
        2,
        33,
        38,
        0,
        11,
        20,
        17,
        20,
        9,
        0,
        37,
        0,
        43,
        34,
        9,
        49,
        49,
        40,
        33,
        45,
        0,
        25,
        47,
        25
      ],
      "weeklyBackordered": [
        0,
        40,
        10,
        23,
        42,
        34,
        20,
        18,
        45,
        29,
        19,
        19,
        3,
        49,
        4,
        19,
        47,
        35,
        5,
        37,
        17,
        46,
        49,
        13,
        39,
        22,
        32,
        6,
        23,
        43,
        25,
        23,
        0,
        49,
        0,
        3,
        49,
        0,
        22,
        5,
        45,
        28,
        37,
        46,
        40,
        16,
        35,
        16,
        33,
        49,
        15,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        2,
        43,
        21,
        35,
        32,
        10,
        21,
        49,
        26,
        12,
        47,
        13,
        1,
        33,
        29,
        11,
        31,
        47,
        0,
        28,
        37,
        49,
        0,
        14,
        0,
        22,
        3,
        40,
        48,
        49,
        21,
        40,
        41,
        37,
        48,
        6,
        23,
        17,
        25,
        49,
        44,
        49,
        49,
        13,
        30,
        17,
        0,
        49,
        0,
        9,
        49,
        23
      ],
      "weeklyRejected": [
        49,
        3,
        5,
        30,
        49,
        28,
        27,
        19,
        49,
        48,
        0,
        28,
        19,
        14,
        30,
        10,
        15,
        45,
        0,
        27,
        7,
        14,
        0,
        37,
        40,
        30,
        27,
        19,
        38,
        32,
        1,
        47,
        24,
        17,
        8,
        24,
        38,
        10,
        30,
        15,
        48,
        49,
        24,
        11,
        49,
        14,
        49,
        49,
        0,
        47,
        23,
        33
      ],
      "weeklyHold": [
        17,
        38,
        49,
        17,
        45,
        45,
        22,
        19,
        10,
        49,
        38,
        28,
        21,
        49,
        20,
        23,
        4,
        39,
        16,
        0,
        0,
        0,
        19,
        8,
        31,
        48,
        0,
        27,
        34,
        30,
        48,
        22,
        19,
        32,
        48,
        22,
        49,
        10,
        41,
        20,
        7,
        46,
        18,
        41,
        26,
        49,
        26,
        4,
        2,
        12,
        0,
        49
      ],
      "weeklyBackordered": [
        38,
        35,
        0,
        0,
        7,
        27,
        30,
        33,
        49,
        9,
        14,
        12,
        15,
        49,
        0,
        37,
        49,
        0,
        10,
        14,
        20,
        1,
        49,
        49,
        35,
        30,
        27,
        45,
        24,
        47,
        49,
        27,
        4,
        31,
        0,
        0,
        49,
        18,
        47,
        21,
        9,
        22,
        6,
        20,
        39,
        49,
        28,
        29,
        0,
        0,
        28,
        18
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        7,
        24,
        26,
        7,
        48,
        16,
        1,
        25,
        28,
        29,
        43,
        21,
        33,
        14,
        25,
        49,
        4,
        48,
        15,
        0,
        49,
        43,
        9,
        21,
        0,
        47,
        42,
        45,
        1,
        31,
        0,
        24,
        39,
        49,
        32,
        19,
        32,
        48,
        42,
        49,
        31,
        15,
        32,
        9,
        46,
        8,
        3,
        30,
        49,
        49,
        20,
        22
      ],
      "weeklyRejected": [
        49,
        9,
        34,
        47,
        48,
        25,
        49,
        5,
        6,
        10,
        43,
        23,
        17,
        21,
        21,
        0,
        18,
        0,
        49,
        18,
        49,
        20,
        41,
        40,
        20,
        45,
        31,
        0,
        48,
        48,
        23,
        47,
        25,
        49,
        26,
        49,
        32,
        49,
        20,
        25,
        13,
        15,
        34,
        48,
        44,
        1,
        12,
        8,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        6,
        0,
        26,
        1,
        16,
        48,
        49,
        48,
        45,
        43,
        20,
        49,
        3,
        44,
        39,
        17,
        38,
        33,
        0,
        46,
        36,
        4,
        0,
        28,
        29,
        48,
        11,
        11,
        49,
        0,
        43,
        15,
        17,
        30,
        48,
        48,
        29,
        0,
        49,
        0,
        37,
        49,
        8,
        8,
        49,
        26,
        15,
        12,
        0,
        6,
        12,
        40
      ],
      "weeklyBackordered": [
        21,
        10,
        17,
        29,
        45,
        39,
        16,
        49,
        49,
        4,
        21,
        11,
        29,
        45,
        29,
        49,
        1,
        49,
        0,
        20,
        36,
        0,
        27,
        19,
        17,
        22,
        16,
        49,
        1,
        16,
        4,
        41,
        34,
        35,
        6,
        8,
        14,
        27,
        5,
        25,
        28,
        41,
        25,
        49,
        49,
        25,
        49,
        7,
        13,
        49,
        40,
        11
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        35,
        24,
        20,
        0,
        34,
        25,
        7,
        2,
        29,
        0,
        32,
        0,
        8,
        23,
        34,
        39,
        42,
        0,
        8,
        12,
        0,
        43,
        0,
        38,
        12,
        47,
        24,
        45,
        18,
        27,
        0,
        22,
        2,
        1,
        48,
        16,
        0,
        25,
        49,
        28,
        0,
        1,
        41,
        10,
        49,
        47,
        6,
        9,
        28,
        49,
        41,
        0
      ],
      "weeklyRejected": [
        29,
        24,
        5,
        44,
        43,
        17,
        42,
        21,
        39,
        4,
        49,
        31,
        0,
        48,
        23,
        0,
        27,
        49,
        11,
        11,
        3,
        22,
        39,
        48,
        31,
        49,
        39,
        49,
        9,
        30,
        22,
        23,
        48,
        49,
        17,
        49,
        49,
        39,
        26,
        12,
        43,
        26,
        0,
        12,
        18,
        24,
        0,
        0,
        21,
        43,
        5,
        49
      ],
      "weeklyHold": [
        6,
        35,
        40,
        27,
        15,
        37,
        4,
        25,
        20,
        0,
        27,
        3,
        29,
        22,
        40,
        23,
        12,
        6,
        25,
        7,
        29,
        49,
        23,
        21,
        49,
        15,
        23,
        49,
        10,
        12,
        28,
        21,
        7,
        49,
        19,
        48,
        0,
        32,
        24,
        49,
        8,
        30,
        26,
        0,
        49,
        48,
        6,
        31,
        18,
        39,
        43,
        46
      ],
      "weeklyBackordered": [
        9,
        49,
        8,
        35,
        49,
        32,
        49,
        23,
        0,
        10,
        27,
        49,
        12,
        49,
        40,
        22,
        47,
        34,
        17,
        49,
        10,
        5,
        18,
        0,
        42,
        9,
        6,
        14,
        47,
        49,
        10,
        12,
        29,
        21,
        18,
        0,
        49,
        0,
        46,
        3,
        4,
        31,
        0,
        49,
        25,
        43,
        46,
        27,
        19,
        49,
        21,
        15
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        29,
        24,
        49,
        26,
        21,
        12,
        16,
        47,
        21,
        7,
        49,
        0,
        36,
        19,
        22,
        0,
        49,
        0,
        11,
        36,
        6,
        42,
        2,
        34,
        49,
        12,
        36,
        25,
        28,
        33,
        26,
        41,
        38,
        0,
        42,
        34,
        15,
        39,
        49,
        49,
        14,
        1,
        49,
        34,
        49,
        0,
        0,
        21,
        1,
        35,
        33,
        22
      ],
      "weeklyRejected": [
        9,
        9,
        49,
        31,
        29,
        33,
        45,
        32,
        41,
        25,
        29,
        27,
        49,
        48,
        13,
        25,
        29,
        49,
        15,
        29,
        15,
        13,
        27,
        49,
        29,
        12,
        39,
        18,
        26,
        34,
        34,
        40,
        31,
        49,
        0,
        46,
        42,
        49,
        1,
        19,
        48,
        49,
        12,
        38,
        23,
        6,
        14,
        0,
        19,
        0,
        47,
        25
      ],
      "weeklyHold": [
        0,
        12,
        49,
        48,
        49,
        32,
        26,
        19,
        36,
        4,
        21,
        10,
        26,
        42,
        39,
        34,
        1,
        25,
        20,
        24,
        20,
        28,
        1,
        0,
        13,
        13,
        36,
        8,
        23,
        21,
        48,
        22,
        27,
        49,
        28,
        38,
        25,
        0,
        31,
        9,
        22,
        12,
        0,
        18,
        29,
        11,
        4,
        31,
        0,
        30,
        22,
        34
      ],
      "weeklyBackordered": [
        28,
        30,
        10,
        31,
        21,
        25,
        42,
        10,
        0,
        23,
        45,
        49,
        29,
        45,
        24,
        49,
        26,
        27,
        0,
        33,
        17,
        33,
        16,
        27,
        1,
        32,
        1,
        16,
        20,
        38,
        26,
        33,
        4,
        34,
        0,
        0,
        23,
        9,
        22,
        43,
        48,
        48,
        0,
        42,
        0,
        5,
        36,
        47,
        33,
        16,
        43,
        34
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        0,
        39,
        48,
        31,
        21,
        27,
        1,
        14,
        48,
        6,
        12,
        9,
        35,
        48,
        26,
        17,
        47,
        6,
        31,
        5,
        49,
        20,
        9,
        37,
        32,
        22,
        0,
        28,
        17,
        39,
        29,
        11,
        49,
        24,
        48,
        49,
        6,
        35,
        24,
        20,
        7,
        18,
        32,
        10,
        37,
        0,
        0,
        35,
        49,
        20,
        32,
        9
      ],
      "weeklyRejected": [
        18,
        0,
        49,
        47,
        49,
        30,
        28,
        10,
        45,
        1,
        0,
        21,
        49,
        48,
        34,
        40,
        42,
        40,
        35,
        20,
        40,
        49,
        49,
        15,
        14,
        49,
        34,
        17,
        40,
        48,
        37,
        47,
        18,
        4,
        0,
        49,
        30,
        46,
        1,
        19,
        31,
        49,
        11,
        13,
        26,
        0,
        0,
        8,
        0,
        25,
        0,
        26
      ],
      "weeklyHold": [
        24,
        35,
        36,
        1,
        39,
        48,
        13,
        2,
        27,
        34,
        47,
        23,
        12,
        41,
        24,
        0,
        20,
        0,
        7,
        0,
        30,
        34,
        15,
        49,
        29,
        28,
        26,
        0,
        34,
        13,
        48,
        0,
        24,
        49,
        41,
        40,
        26,
        19,
        8,
        49,
        19,
        49,
        0,
        22,
        11,
        36,
        0,
        35,
        30,
        15,
        11,
        35
      ],
      "weeklyBackordered": [
        32,
        6,
        0,
        38,
        32,
        40,
        18,
        25,
        24,
        30,
        18,
        49,
        29,
        20,
        49,
        15,
        12,
        35,
        17,
        27,
        35,
        26,
        0,
        49,
        1,
        1,
        38,
        28,
        16,
        49,
        6,
        11,
        48,
        44,
        18,
        0,
        46,
        38,
        49,
        41,
        0,
        31,
        25,
        49,
        33,
        41,
        13,
        14,
        34,
        34,
        49,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        11,
        6,
        49,
        30,
        41,
        49,
        5,
        19,
        40,
        32,
        35,
        0,
        49,
        44,
        22,
        40,
        36,
        0,
        31,
        46,
        41,
        7,
        49,
        19,
        9,
        47,
        16,
        20,
        1,
        27,
        7,
        0,
        46,
        21,
        29,
        35,
        4,
        42,
        25,
        49,
        37,
        27,
        43,
        43,
        39,
        13,
        0,
        8,
        23,
        19,
        11,
        7
      ],
      "weeklyRejected": [
        38,
        38,
        49,
        0,
        29,
        4,
        26,
        41,
        29,
        26,
        27,
        34,
        28,
        39,
        48,
        4,
        47,
        19,
        49,
        22,
        22,
        49,
        45,
        19,
        13,
        49,
        49,
        47,
        29,
        48,
        43,
        47,
        0,
        21,
        0,
        36,
        46,
        27,
        10,
        49,
        35,
        3,
        28,
        0,
        49,
        49,
        37,
        9,
        0,
        11,
        0,
        49
      ],
      "weeklyHold": [
        20,
        24,
        38,
        1,
        42,
        11,
        48,
        2,
        0,
        49,
        42,
        31,
        24,
        22,
        42,
        17,
        9,
        9,
        38,
        28,
        48,
        0,
        16,
        17,
        2,
        27,
        16,
        30,
        35,
        10,
        40,
        0,
        0,
        15,
        16,
        49,
        5,
        39,
        26,
        38,
        49,
        49,
        22,
        47,
        20,
        49,
        29,
        41,
        14,
        4,
        43,
        28
      ],
      "weeklyBackordered": [
        36,
        28,
        16,
        49,
        49,
        36,
        3,
        46,
        6,
        17,
        15,
        38,
        43,
        40,
        49,
        43,
        14,
        28,
        28,
        9,
        30,
        0,
        6,
        0,
        15,
        18,
        26,
        21,
        1,
        42,
        23,
        38,
        34,
        41,
        0,
        0,
        4,
        0,
        47,
        10,
        23,
        48,
        24,
        49,
        0,
        23,
        0,
        0,
        44,
        26,
        28,
        24
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        47,
        47,
        16,
        0,
        8,
        13,
        1,
        45,
        32,
        27,
        28,
        23,
        13,
        6,
        1,
        47,
        49,
        48,
        0,
        9,
        49,
        32,
        28,
        22,
        3,
        38,
        10,
        29,
        24,
        6,
        23,
        17,
        49,
        15,
        48,
        34,
        22,
        47,
        33,
        49,
        32,
        35,
        44,
        49,
        23,
        9,
        0,
        16,
        2,
        8,
        2,
        36
      ],
      "weeklyRejected": [
        39,
        14,
        2,
        27,
        13,
        0,
        49,
        13,
        49,
        1,
        19,
        0,
        0,
        47,
        44,
        34,
        0,
        36,
        49,
        4,
        49,
        0,
        17,
        18,
        1,
        49,
        30,
        17,
        48,
        47,
        30,
        9,
        21,
        10,
        36,
        48,
        32,
        35,
        29,
        14,
        11,
        49,
        49,
        36,
        49,
        49,
        3,
        0,
        12,
        19,
        0,
        49
      ],
      "weeklyHold": [
        0,
        28,
        38,
        1,
        0,
        16,
        32,
        15,
        0,
        33,
        0,
        13,
        22,
        1,
        41,
        11,
        0,
        21,
        8,
        40,
        0,
        29,
        32,
        19,
        13,
        48,
        30,
        35,
        47,
        0,
        48,
        16,
        0,
        32,
        46,
        40,
        34,
        49,
        40,
        14,
        38,
        27,
        24,
        22,
        31,
        38,
        0,
        49,
        0,
        0,
        19,
        36
      ],
      "weeklyBackordered": [
        32,
        28,
        2,
        14,
        28,
        43,
        23,
        30,
        20,
        22,
        37,
        17,
        3,
        2,
        0,
        38,
        24,
        34,
        0,
        48,
        14,
        27,
        34,
        20,
        48,
        1,
        22,
        49,
        11,
        4,
        10,
        49,
        15,
        36,
        0,
        0,
        4,
        20,
        27,
        15,
        25,
        42,
        49,
        49,
        48,
        1,
        49,
        8,
        0,
        35,
        35,
        35
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        12,
        29,
        37,
        48,
        29,
        47,
        1,
        7,
        48,
        29,
        39,
        31,
        27,
        18,
        18,
        45,
        28,
        4,
        0,
        49,
        0,
        31,
        35,
        45,
        13,
        47,
        33,
        4,
        4,
        21,
        21,
        49,
        49,
        49,
        33,
        49,
        14,
        0,
        18,
        49,
        32,
        40,
        49,
        46,
        19,
        43,
        35,
        17,
        39,
        49,
        7,
        35
      ],
      "weeklyRejected": [
        42,
        11,
        49,
        33,
        29,
        3,
        20,
        9,
        35,
        15,
        17,
        22,
        42,
        0,
        32,
        32,
        17,
        42,
        0,
        21,
        14,
        48,
        25,
        30,
        12,
        49,
        49,
        30,
        32,
        12,
        35,
        22,
        33,
        14,
        23,
        23,
        15,
        0,
        1,
        20,
        29,
        40,
        15,
        27,
        32,
        0,
        0,
        27,
        16,
        49,
        0,
        39
      ],
      "weeklyHold": [
        13,
        22,
        0,
        18,
        8,
        0,
        29,
        22,
        0,
        49,
        47,
        43,
        45,
        26,
        35,
        21,
        31,
        39,
        14,
        26,
        30,
        27,
        24,
        49,
        6,
        27,
        49,
        19,
        49,
        6,
        18,
        48,
        22,
        5,
        23,
        15,
        44,
        0,
        28,
        40,
        38,
        11,
        29,
        40,
        43,
        45,
        33,
        49,
        12,
        49,
        42,
        0
      ],
      "weeklyBackordered": [
        2,
        19,
        10,
        39,
        44,
        14,
        28,
        46,
        49,
        15,
        37,
        29,
        8,
        26,
        1,
        1,
        36,
        49,
        9,
        5,
        46,
        48,
        27,
        47,
        11,
        19,
        4,
        25,
        6,
        9,
        45,
        43,
        48,
        49,
        1,
        2,
        32,
        15,
        9,
        22,
        23,
        48,
        0,
        31,
        0,
        40,
        49,
        18,
        45,
        49,
        8,
        34
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        24,
        13,
        20,
        40,
        27,
        5,
        10,
        49,
        25,
        35,
        49,
        15,
        34,
        18,
        15,
        23,
        49,
        4,
        0,
        45,
        25,
        0,
        38,
        49,
        6,
        47,
        23,
        18,
        9,
        19,
        30,
        0,
        34,
        16,
        9,
        39,
        27,
        30,
        0,
        48,
        0,
        21,
        49,
        6,
        49,
        9,
        14,
        12,
        0,
        0,
        19,
        0
      ],
      "weeklyRejected": [
        36,
        41,
        35,
        47,
        37,
        49,
        15,
        5,
        3,
        18,
        25,
        24,
        19,
        32,
        40,
        48,
        35,
        18,
        0,
        26,
        10,
        1,
        48,
        49,
        11,
        26,
        42,
        35,
        48,
        0,
        49,
        20,
        19,
        36,
        36,
        49,
        49,
        29,
        9,
        29,
        34,
        19,
        49,
        0,
        40,
        26,
        22,
        25,
        13,
        47,
        15,
        44
      ],
      "weeklyHold": [
        49,
        24,
        45,
        27,
        11,
        38,
        0,
        2,
        15,
        0,
        35,
        38,
        28,
        16,
        32,
        21,
        17,
        22,
        41,
        11,
        31,
        49,
        44,
        0,
        30,
        46,
        4,
        13,
        32,
        42,
        32,
        16,
        23,
        49,
        6,
        38,
        44,
        15,
        49,
        0,
        42,
        49,
        31,
        37,
        19,
        29,
        9,
        37,
        0,
        22,
        49,
        49
      ],
      "weeklyBackordered": [
        2,
        31,
        37,
        34,
        30,
        25,
        30,
        22,
        42,
        28,
        37,
        18,
        3,
        3,
        17,
        38,
        30,
        37,
        29,
        49,
        0,
        43,
        14,
        43,
        31,
        22,
        36,
        49,
        28,
        49,
        43,
        49,
        48,
        24,
        0,
        0,
        7,
        17,
        8,
        49,
        0,
        48,
        34,
        45,
        0,
        38,
        46,
        13,
        48,
        42,
        44,
        42
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        0,
        26,
        32,
        26,
        9,
        0,
        49,
        22,
        34,
        2,
        49,
        28,
        23,
        0,
        25,
        28,
        35,
        4,
        7,
        40,
        23,
        0,
        47,
        11,
        27,
        46,
        39,
        18,
        10,
        8,
        7,
        42,
        35,
        49,
        48,
        42,
        38,
        18,
        40,
        14,
        14,
        36,
        49,
        25,
        14,
        17,
        1,
        31,
        13,
        27,
        16,
        48
      ],
      "weeklyRejected": [
        39,
        19,
        29,
        47,
        13,
        39,
        0,
        21,
        24,
        48,
        15,
        25,
        28,
        11,
        48,
        0,
        49,
        41,
        49,
        32,
        41,
        40,
        44,
        49,
        15,
        49,
        12,
        0,
        15,
        3,
        40,
        47,
        48,
        27,
        0,
        30,
        49,
        38,
        1,
        36,
        33,
        26,
        17,
        8,
        48,
        49,
        49,
        38,
        2,
        39,
        21,
        4
      ],
      "weeklyHold": [
        30,
        19,
        49,
        2,
        49,
        46,
        0,
        16,
        0,
        25,
        32,
        39,
        48,
        1,
        9,
        35,
        0,
        28,
        32,
        9,
        34,
        15,
        30,
        40,
        22,
        47,
        31,
        2,
        22,
        37,
        48,
        10,
        10,
        49,
        29,
        41,
        49,
        32,
        3,
        33,
        25,
        49,
        26,
        49,
        47,
        5,
        19,
        32,
        49,
        49,
        19,
        27
      ],
      "weeklyBackordered": [
        49,
        42,
        33,
        23,
        47,
        35,
        37,
        31,
        36,
        11,
        44,
        49,
        4,
        30,
        14,
        8,
        46,
        49,
        31,
        19,
        49,
        40,
        36,
        3,
        5,
        22,
        27,
        2,
        1,
        2,
        19,
        1,
        48,
        3,
        0,
        0,
        49,
        0,
        24,
        27,
        31,
        48,
        40,
        49,
        27,
        12,
        0,
        15,
        0,
        49,
        23,
        20
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        26,
        47,
        41,
        34,
        16,
        49,
        2,
        36,
        39,
        37,
        49,
        12,
        49,
        27,
        16,
        12,
        17,
        0,
        13,
        14,
        0,
        47,
        23,
        44,
        49,
        28,
        0,
        23,
        1,
        20,
        4,
        26,
        36,
        44,
        23,
        2,
        36,
        46,
        16,
        49,
        0,
        36,
        30,
        9,
        41,
        29,
        37,
        15,
        6,
        40,
        17,
        3
      ],
      "weeklyRejected": [
        4,
        19,
        28,
        36,
        35,
        8,
        13,
        49,
        49,
        13,
        49,
        26,
        18,
        48,
        31,
        12,
        42,
        49,
        5,
        30,
        6,
        46,
        7,
        43,
        28,
        25,
        49,
        5,
        39,
        27,
        31,
        16,
        48,
        20,
        2,
        33,
        0,
        3,
        1,
        22,
        41,
        42,
        2,
        25,
        48,
        20,
        11,
        32,
        32,
        45,
        0,
        49
      ],
      "weeklyHold": [
        6,
        11,
        49,
        1,
        11,
        37,
        8,
        11,
        9,
        0,
        47,
        2,
        29,
        21,
        37,
        0,
        0,
        35,
        28,
        0,
        39,
        49,
        22,
        0,
        41,
        24,
        0,
        47,
        49,
        0,
        48,
        36,
        0,
        28,
        48,
        18,
        16,
        3,
        23,
        21,
        7,
        0,
        49,
        22,
        0,
        22,
        0,
        43,
        0,
        49,
        15,
        25
      ],
      "weeklyBackordered": [
        14,
        11,
        5,
        31,
        7,
        20,
        45,
        42,
        6,
        0,
        17,
        5,
        3,
        44,
        25,
        32,
        34,
        49,
        6,
        24,
        47,
        34,
        49,
        28,
        31,
        1,
        1,
        8,
        6,
        49,
        20,
        49,
        17,
        45,
        37,
        39,
        9,
        0,
        49,
        42,
        48,
        48,
        5,
        49,
        18,
        43,
        49,
        22,
        20,
        32,
        21,
        28
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        14,
        8,
        37,
        28,
        49,
        0,
        10,
        16,
        42,
        12,
        0,
        5,
        49,
        23,
        17,
        21,
        46,
        46,
        0,
        14,
        49,
        18,
        49,
        39,
        0,
        36,
        33,
        34,
        37,
        27,
        16,
        15,
        2,
        36,
        21,
        26,
        44,
        48,
        23,
        49,
        49,
        49,
        49,
        39,
        16,
        10,
        29,
        39,
        0,
        0,
        40,
        20
      ],
      "weeklyRejected": [
        23,
        4,
        49,
        15,
        20,
        0,
        28,
        39,
        28,
        48,
        49,
        0,
        49,
        0,
        19,
        26,
        0,
        22,
        13,
        25,
        34,
        45,
        44,
        4,
        23,
        44,
        29,
        49,
        39,
        5,
        29,
        46,
        48,
        25,
        37,
        49,
        20,
        14,
        30,
        17,
        37,
        23,
        49,
        9,
        48,
        18,
        0,
        27,
        4,
        29,
        6,
        43
      ],
      "weeklyHold": [
        25,
        49,
        20,
        1,
        27,
        36,
        0,
        2,
        0,
        25,
        47,
        40,
        22,
        49,
        0,
        32,
        17,
        32,
        20,
        31,
        30,
        32,
        40,
        0,
        11,
        36,
        39,
        7,
        12,
        14,
        48,
        4,
        0,
        22,
        25,
        35,
        3,
        2,
        16,
        1,
        30,
        22,
        24,
        35,
        28,
        25,
        4,
        0,
        0,
        32,
        37,
        24
      ],
      "weeklyBackordered": [
        33,
        49,
        11,
        49,
        27,
        34,
        30,
        48,
        25,
        7,
        20,
        41,
        4,
        49,
        0,
        31,
        49,
        38,
        0,
        49,
        35,
        35,
        22,
        46,
        6,
        35,
        21,
        37,
        25,
        44,
        30,
        34,
        48,
        49,
        13,
        0,
        48,
        34,
        26,
        18,
        0,
        21,
        20,
        49,
        28,
        31,
        39,
        5,
        41,
        49,
        1,
        27
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        18,
        1,
        12,
        9,
        1,
        4,
        34,
        47,
        44,
        0,
        24,
        10,
        39,
        47,
        20,
        12,
        0,
        7,
        28,
        15,
        0,
        46,
        11,
        31,
        45,
        47,
        8,
        33,
        6,
        42,
        34,
        20,
        49,
        3,
        21,
        21,
        40,
        43,
        45,
        44,
        5,
        10,
        46,
        42,
        49,
        47,
        3,
        20,
        1,
        49,
        0,
        31
      ],
      "weeklyRejected": [
        44,
        28,
        47,
        0,
        24,
        0,
        28,
        4,
        49,
        46,
        28,
        28,
        27,
        45,
        18,
        27,
        1,
        49,
        49,
        26,
        0,
        40,
        10,
        17,
        49,
        33,
        49,
        7,
        45,
        0,
        25,
        26,
        38,
        49,
        8,
        46,
        33,
        11,
        3,
        10,
        44,
        49,
        49,
        49,
        47,
        19,
        34,
        28,
        21,
        40,
        0,
        49
      ],
      "weeklyHold": [
        14,
        4,
        34,
        1,
        0,
        48,
        45,
        28,
        0,
        13,
        0,
        6,
        1,
        33,
        33,
        14,
        29,
        34,
        5,
        0,
        28,
        32,
        34,
        0,
        29,
        30,
        20,
        49,
        33,
        12,
        37,
        0,
        29,
        49,
        46,
        24,
        5,
        27,
        46,
        34,
        44,
        49,
        0,
        30,
        14,
        35,
        32,
        45,
        0,
        49,
        44,
        18
      ],
      "weeklyBackordered": [
        49,
        41,
        0,
        8,
        32,
        46,
        16,
        27,
        0,
        22,
        40,
        49,
        10,
        41,
        29,
        15,
        44,
        33,
        11,
        49,
        31,
        19,
        36,
        1,
        1,
        32,
        18,
        38,
        33,
        48,
        12,
        33,
        45,
        49,
        32,
        20,
        45,
        37,
        14,
        34,
        33,
        47,
        7,
        6,
        22,
        13,
        49,
        46,
        1,
        49,
        47,
        28
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        0,
        26,
        1,
        34,
        49,
        36,
        1,
        24,
        48,
        29,
        10,
        0,
        29,
        48,
        48,
        17,
        35,
        0,
        7,
        18,
        28,
        17,
        34,
        21,
        20,
        0,
        0,
        34,
        22,
        49,
        0,
        0,
        40,
        21,
        48,
        31,
        14,
        40,
        49,
        19,
        0,
        18,
        49,
        16,
        49,
        43,
        15,
        0,
        49,
        32,
        0,
        43
      ],
      "weeklyRejected": [
        20,
        25,
        25,
        37,
        30,
        0,
        29,
        22,
        0,
        30,
        49,
        33,
        14,
        18,
        35,
        0,
        18,
        46,
        49,
        49,
        18,
        26,
        49,
        9,
        49,
        49,
        43,
        49,
        35,
        30,
        34,
        33,
        0,
        19,
        0,
        49,
        49,
        49,
        1,
        0,
        38,
        42,
        26,
        1,
        46,
        8,
        20,
        30,
        0,
        49,
        10,
        31
      ],
      "weeklyHold": [
        33,
        35,
        32,
        22,
        39,
        20,
        18,
        20,
        15,
        35,
        47,
        16,
        47,
        12,
        35,
        0,
        41,
        15,
        0,
        5,
        47,
        34,
        47,
        49,
        0,
        29,
        14,
        16,
        49,
        24,
        46,
        0,
        0,
        49,
        15,
        0,
        23,
        16,
        1,
        34,
        0,
        36,
        17,
        15,
        19,
        23,
        12,
        16,
        0,
        43,
        8,
        10
      ],
      "weeklyBackordered": [
        1,
        6,
        0,
        49,
        39,
        17,
        22,
        29,
        45,
        0,
        15,
        49,
        3,
        29,
        40,
        30,
        9,
        19,
        0,
        4,
        49,
        7,
        0,
        49,
        11,
        14,
        27,
        0,
        33,
        40,
        49,
        41,
        48,
        49,
        47,
        0,
        30,
        0,
        49,
        30,
        32,
        46,
        17,
        49,
        34,
        5,
        0,
        17,
        9,
        35,
        19,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        0,
        49,
        10,
        48,
        20,
        0,
        24,
        47,
        48,
        14,
        35,
        0,
        30,
        22,
        22,
        27,
        49,
        9,
        0,
        40,
        10,
        41,
        49,
        12,
        44,
        47,
        0,
        45,
        4,
        40,
        42,
        28,
        46,
        36,
        11,
        19,
        47,
        48,
        43,
        49,
        0,
        0,
        49,
        43,
        35,
        0,
        0,
        0,
        0,
        41,
        31,
        0
      ],
      "weeklyRejected": [
        49,
        45,
        40,
        31,
        1,
        41,
        12,
        24,
        36,
        15,
        19,
        22,
        36,
        48,
        32,
        44,
        21,
        49,
        38,
        36,
        9,
        16,
        31,
        8,
        31,
        49,
        40,
        13,
        48,
        0,
        20,
        24,
        48,
        49,
        49,
        49,
        0,
        34,
        49,
        35,
        36,
        49,
        28,
        46,
        45,
        29,
        0,
        37,
        45,
        42,
        31,
        41
      ],
      "weeklyHold": [
        49,
        0,
        49,
        12,
        0,
        25,
        12,
        40,
        42,
        17,
        16,
        2,
        7,
        39,
        35,
        0,
        17,
        21,
        0,
        27,
        33,
        48,
        40,
        4,
        37,
        35,
        12,
        45,
        11,
        0,
        48,
        0,
        0,
        12,
        8,
        44,
        47,
        7,
        49,
        40,
        15,
        49,
        25,
        8,
        14,
        33,
        23,
        31,
        0,
        49,
        43,
        31
      ],
      "weeklyBackordered": [
        46,
        31,
        25,
        46,
        25,
        46,
        40,
        18,
        20,
        40,
        44,
        29,
        22,
        25,
        31,
        12,
        18,
        40,
        0,
        45,
        0,
        39,
        0,
        6,
        35,
        28,
        1,
        39,
        11,
        49,
        5,
        17,
        48,
        15,
        42,
        7,
        1,
        0,
        16,
        49,
        48,
        6,
        19,
        32,
        0,
        30,
        45,
        7,
        24,
        46,
        34,
        9
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        14,
        40,
        28,
        0,
        1,
        40,
        4,
        24,
        21,
        49,
        33,
        1,
        0,
        31,
        32,
        7,
        21,
        18,
        49,
        35,
        16,
        48,
        47,
        35,
        15,
        47,
        4,
        20,
        29,
        34,
        12,
        16,
        47,
        27,
        8,
        49,
        42,
        10,
        15,
        11,
        24,
        15,
        24,
        41,
        40,
        49,
        4,
        1,
        32,
        49,
        6,
        28
      ],
      "weeklyRejected": [
        49,
        15,
        4,
        8,
        49,
        0,
        0,
        11,
        46,
        1,
        28,
        0,
        34,
        48,
        2,
        17,
        49,
        49,
        49,
        36,
        0,
        30,
        6,
        41,
        21,
        49,
        0,
        0,
        29,
        10,
        16,
        18,
        16,
        0,
        16,
        49,
        49,
        49,
        1,
        8,
        0,
        40,
        2,
        28,
        34,
        18,
        0,
        10,
        0,
        49,
        0,
        48
      ],
      "weeklyHold": [
        11,
        0,
        33,
        1,
        4,
        22,
        0,
        14,
        36,
        17,
        26,
        16,
        1,
        1,
        49,
        0,
        22,
        24,
        40,
        29,
        12,
        28,
        14,
        27,
        32,
        48,
        11,
        49,
        49,
        1,
        48,
        11,
        10,
        37,
        36,
        16,
        23,
        33,
        45,
        48,
        24,
        10,
        44,
        26,
        40,
        23,
        6,
        46,
        3,
        37,
        14,
        0
      ],
      "weeklyBackordered": [
        49,
        1,
        5,
        1,
        38,
        46,
        28,
        42,
        18,
        28,
        17,
        17,
        5,
        12,
        49,
        49,
        11,
        30,
        0,
        26,
        49,
        30,
        49,
        30,
        48,
        5,
        40,
        28,
        1,
        49,
        0,
        49,
        14,
        49,
        44,
        0,
        26,
        15,
        12,
        49,
        26,
        35,
        16,
        19,
        37,
        33,
        22,
        20,
        21,
        49,
        49,
        2
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        0,
        40,
        40,
        1,
        29,
        44,
        1,
        6,
        48,
        0,
        49,
        0,
        22,
        49,
        9,
        18,
        16,
        7,
        19,
        0,
        10,
        42,
        9,
        6,
        28,
        25,
        18,
        49,
        32,
        39,
        0,
        0,
        34,
        0,
        41,
        11,
        47,
        48,
        27,
        47,
        25,
        40,
        9,
        0,
        49,
        18,
        13,
        32,
        32,
        15,
        20,
        23
      ],
      "weeklyRejected": [
        49,
        0,
        23,
        25,
        39,
        0,
        49,
        14,
        42,
        32,
        14,
        33,
        0,
        21,
        33,
        17,
        0,
        49,
        8,
        19,
        49,
        33,
        0,
        26,
        6,
        49,
        42,
        46,
        48,
        0,
        14,
        26,
        27,
        27,
        13,
        49,
        34,
        0,
        5,
        49,
        37,
        49,
        44,
        22,
        39,
        0,
        19,
        49,
        0,
        40,
        0,
        31
      ],
      "weeklyHold": [
        49,
        2,
        49,
        1,
        21,
        45,
        35,
        2,
        22,
        23,
        31,
        25,
        48,
        17,
        16,
        12,
        0,
        23,
        6,
        11,
        7,
        34,
        18,
        18,
        49,
        27,
        0,
        8,
        16,
        0,
        48,
        14,
        0,
        0,
        5,
        0,
        8,
        5,
        49,
        28,
        6,
        37,
        10,
        39,
        46,
        11,
        30,
        11,
        13,
        0,
        6,
        32
      ],
      "weeklyBackordered": [
        8,
        16,
        4,
        46,
        13,
        41,
        43,
        38,
        24,
        6,
        10,
        0,
        14,
        47,
        30,
        49,
        29,
        40,
        6,
        32,
        28,
        18,
        11,
        49,
        9,
        1,
        36,
        0,
        37,
        49,
        47,
        24,
        0,
        48,
        2,
        7,
        26,
        29,
        21,
        25,
        0,
        48,
        9,
        45,
        5,
        15,
        49,
        34,
        0,
        49,
        49,
        49
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyAccepted": [
        2,
        23,
        22,
        14,
        41,
        7,
        1,
        39,
        48,
        0,
        26,
        24,
        13,
        3,
        19,
        16,
        42,
        0,
        33,
        0,
        46,
        15,
        26,
        6,
        13,
        23,
        14,
        13,
        7,
        10,
        0,
        38,
        49,
        30,
        48,
        43,
        15,
        20,
        9,
        27,
        46,
        32,
        49,
        23,
        49,
        0,
        34,
        16,
        14,
        21,
        18,
        16
      ],
      "weeklyRejected": [
        18,
        17,
        11,
        47,
        6,
        39,
        49,
        28,
        23,
        42,
        36,
        43,
        4,
        48,
        41,
        0,
        0,
        49,
        49,
        20,
        9,
        49,
        10,
        0,
        25,
        49,
        30,
        26,
        3,
        48,
        13,
        47,
        27,
        1,
        21,
        49,
        49,
        34,
        1,
        0,
        41,
        32,
        26,
        0,
        49,
        24,
        19,
        24,
        10,
        41,
        8,
        37
      ],
      "weeklyHold": [
        6,
        3,
        21,
        16,
        3,
        4,
        24,
        3,
        48,
        22,
        11,
        37,
        20,
        12,
        16,
        49,
        27,
        9,
        15,
        30,
        0,
        33,
        0,
        26,
        49,
        30,
        49,
        23,
        49,
        13,
        12,
        0,
        0,
        40,
        14,
        41,
        28,
        16,
        38,
        13,
        16,
        35,
        0,
        24,
        28,
        31,
        20,
        24,
        0,
        25,
        10,
        34
      ],
      "weeklyBackordered": [
        0,
        21,
        0,
        41,
        14,
        46,
        43,
        42,
        45,
        15,
        49,
        49,
        13,
        10,
        47,
        4,
        10,
        44,
        10,
        0,
        26,
        33,
        33,
        49,
        48,
        8,
        24,
        37,
        1,
        13,
        4,
        9,
        21,
        40,
        0,
        0,
        28,
        0,
        36,
        40,
        48,
        48,
        37,
        49,
        26,
        8,
        49,
        9,
        23,
        24,
        49,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        23,
        13,
        15,
        23,
        38,
        15,
        1,
        49,
        29,
        33,
        43,
        4,
        49,
        49,
        12,
        32,
        49,
        32,
        21,
        43,
        37,
        45,
        23,
        41,
        32,
        5,
        18,
        18,
        48,
        49,
        26,
        35,
        26,
        5,
        31,
        48,
        47,
        8,
        25,
        49,
        13,
        17,
        49,
        31,
        13,
        5,
        0,
        49,
        7,
        49,
        5,
        27
      ],
      "weeklyRejected": [
        43,
        0,
        20,
        43,
        33,
        14,
        0,
        23,
        49,
        24,
        21,
        46,
        13,
        48,
        6,
        49,
        18,
        21,
        0,
        0,
        0,
        34,
        44,
        49,
        33,
        27,
        47,
        48,
        14,
        28,
        38,
        17,
        17,
        49,
        36,
        49,
        49,
        31,
        12,
        16,
        48,
        19,
        49,
        0,
        28,
        13,
        34,
        46,
        25,
        38,
        30,
        44
      ],
      "weeklyHold": [
        4,
        28,
        49,
        1,
        49,
        29,
        14,
        30,
        33,
        20,
        27,
        33,
        41,
        40,
        35,
        10,
        7,
        1,
        49,
        0,
        12,
        49,
        38,
        22,
        19,
        48,
        49,
        0,
        32,
        0,
        48,
        11,
        6,
        27,
        41,
        49,
        15,
        49,
        49,
        23,
        19,
        49,
        11,
        37,
        49,
        49,
        27,
        36,
        0,
        44,
        42,
        13
      ],
      "weeklyBackordered": [
        22,
        27,
        27,
        39,
        49,
        27,
        8,
        1,
        49,
        10,
        32,
        3,
        49,
        20,
        0,
        46,
        9,
        19,
        28,
        49,
        49,
        0,
        22,
        16,
        28,
        35,
        1,
        8,
        1,
        32,
        0,
        49,
        30,
        8,
        0,
        0,
        30,
        13,
        7,
        15,
        3,
        48,
        2,
        49,
        49,
        49,
        37,
        0,
        0,
        0,
        39,
        11
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        5,
        49,
        14,
        48,
        49,
        3,
        9,
        49,
        5,
        21,
        22,
        0,
        49,
        38,
        22,
        14,
        23,
        15,
        29,
        0,
        41,
        6,
        49,
        49,
        26,
        21,
        10,
        35,
        20,
        49,
        34,
        32,
        49,
        0,
        30,
        47,
        47,
        0,
        17,
        32,
        0,
        18,
        49,
        49,
        22,
        49,
        9,
        34,
        49,
        32,
        7,
        4
      ],
      "weeklyRejected": [
        23,
        11,
        34,
        47,
        12,
        18,
        37,
        19,
        45,
        30,
        33,
        43,
        18,
        47,
        33,
        40,
        24,
        12,
        14,
        11,
        33,
        25,
        38,
        19,
        14,
        49,
        0,
        19,
        35,
        35,
        0,
        14,
        0,
        21,
        15,
        49,
        44,
        5,
        1,
        28,
        40,
        49,
        14,
        0,
        31,
        30,
        6,
        31,
        10,
        31,
        10,
        19
      ],
      "weeklyHold": [
        27,
        39,
        2,
        1,
        41,
        34,
        34,
        26,
        25,
        38,
        30,
        35,
        24,
        36,
        41,
        0,
        12,
        27,
        0,
        21,
        23,
        15,
        1,
        28,
        0,
        23,
        49,
        0,
        49,
        32,
        38,
        0,
        0,
        31,
        18,
        0,
        0,
        0,
        15,
        47,
        35,
        38,
        7,
        7,
        49,
        49,
        6,
        27,
        0,
        0,
        43,
        0
      ],
      "weeklyBackordered": [
        49,
        17,
        17,
        24,
        49,
        46,
        0,
        49,
        33,
        5,
        9,
        49,
        24,
        12,
        15,
        18,
        49,
        49,
        0,
        24,
        49,
        14,
        26,
        24,
        6,
        26,
        1,
        25,
        19,
        14,
        4,
        46,
        48,
        40,
        19,
        3,
        49,
        0,
        46,
        19,
        22,
        48,
        9,
        49,
        11,
        22,
        48,
        49,
        0,
        13,
        49,
        1
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        3,
        5,
        15,
        4,
        39,
        17,
        23,
        41,
        48,
        20,
        36,
        8,
        29,
        25,
        37,
        34,
        0,
        0,
        19,
        27,
        37,
        27,
        49,
        49,
        49,
        2,
        0,
        11,
        24,
        49,
        10,
        41,
        20,
        41,
        48,
        49,
        10,
        8,
        35,
        14,
        0,
        0,
        32,
        9,
        49,
        49,
        5,
        46,
        49,
        32,
        24,
        36
      ],
      "weeklyRejected": [
        35,
        24,
        40,
        36,
        12,
        41,
        24,
        22,
        10,
        34,
        44,
        21,
        8,
        48,
        29,
        0,
        7,
        31,
        49,
        11,
        28,
        10,
        45,
        13,
        13,
        37,
        20,
        43,
        47,
        17,
        0,
        47,
        24,
        26,
        47,
        49,
        49,
        14,
        1,
        45,
        19,
        31,
        7,
        0,
        49,
        25,
        0,
        18,
        0,
        22,
        0,
        3
      ],
      "weeklyHold": [
        38,
        34,
        32,
        25,
        49,
        48,
        24,
        30,
        5,
        49,
        44,
        1,
        16,
        39,
        46,
        0,
        49,
        4,
        0,
        0,
        37,
        39,
        31,
        0,
        49,
        44,
        35,
        21,
        49,
        9,
        45,
        3,
        14,
        36,
        16,
        0,
        5,
        11,
        17,
        26,
        39,
        34,
        0,
        36,
        24,
        42,
        41,
        19,
        0,
        10,
        22,
        0
      ],
      "weeklyBackordered": [
        15,
        25,
        13,
        19,
        49,
        46,
        8,
        40,
        26,
        19,
        16,
        49,
        20,
        0,
        42,
        8,
        49,
        49,
        0,
        21,
        35,
        31,
        0,
        0,
        37,
        7,
        40,
        49,
        15,
        44,
        0,
        34,
        13,
        27,
        21,
        16,
        37,
        0,
        49,
        31,
        22,
        46,
        22,
        49,
        9,
        25,
        0,
        49,
        0,
        22,
        41,
        2
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        0,
        1,
        22,
        16,
        28,
        9,
        7,
        47,
        14,
        49,
        26,
        28,
        49,
        2,
        28,
        0,
        49,
        0,
        0,
        0,
        12,
        21,
        31,
        23,
        21,
        32,
        6,
        38,
        16,
        45,
        47,
        0,
        43,
        29,
        48,
        45,
        14,
        2,
        26,
        19,
        0,
        30,
        49,
        25,
        29,
        0,
        0,
        17,
        24,
        49,
        0,
        17
      ],
      "weeklyRejected": [
        18,
        14,
        32,
        47,
        34,
        13,
        21,
        40,
        48,
        35,
        34,
        0,
        33,
        48,
        43,
        0,
        43,
        19,
        41,
        13,
        0,
        47,
        17,
        33,
        29,
        33,
        49,
        31,
        6,
        44,
        49,
        9,
        12,
        30,
        37,
        29,
        49,
        27,
        1,
        0,
        19,
        27,
        20,
        17,
        38,
        12,
        4,
        0,
        0,
        49,
        12,
        27
      ],
      "weeklyHold": [
        34,
        20,
        49,
        29,
        11,
        17,
        8,
        26,
        29,
        0,
        47,
        19,
        33,
        18,
        49,
        0,
        24,
        38,
        49,
        0,
        45,
        29,
        4,
        0,
        0,
        32,
        3,
        27,
        49,
        43,
        48,
        42,
        0,
        44,
        20,
        39,
        23,
        0,
        30,
        41,
        49,
        38,
        0,
        41,
        49,
        31,
        33,
        41,
        0,
        37,
        49,
        23
      ],
      "weeklyBackordered": [
        49,
        48,
        0,
        43,
        46,
        40,
        36,
        14,
        17,
        15,
        43,
        49,
        4,
        0,
        24,
        32,
        46,
        49,
        49,
        0,
        45,
        32,
        17,
        1,
        48,
        35,
        1,
        5,
        36,
        20,
        0,
        37,
        48,
        0,
        33,
        0,
        34,
        0,
        22,
        42,
        48,
        48,
        1,
        49,
        8,
        31,
        27,
        30,
        0,
        1,
        49,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        8,
        34,
        21,
        19,
        11,
        3,
        6,
        26,
        6,
        31,
        14,
        2,
        39,
        49,
        8,
        12,
        49,
        0,
        0,
        15,
        12,
        17,
        22,
        10,
        19,
        47,
        4,
        49,
        1,
        24,
        2,
        2,
        49,
        0,
        24,
        5,
        32,
        48,
        49,
        49,
        0,
        40,
        43,
        20,
        36,
        0,
        0,
        49,
        0,
        10,
        49,
        48
      ],
      "weeklyRejected": [
        49,
        13,
        31,
        44,
        21,
        0,
        38,
        25,
        33,
        1,
        17,
        0,
        49,
        45,
        48,
        28,
        0,
        43,
        8,
        18,
        17,
        43,
        32,
        46,
        4,
        41,
        37,
        7,
        48,
        31,
        33,
        15,
        21,
        30,
        27,
        38,
        14,
        36,
        1,
        49,
        10,
        0,
        23,
        0,
        28,
        49,
        0,
        0,
        0,
        36,
        0,
        36
      ],
      "weeklyHold": [
        36,
        14,
        18,
        1,
        16,
        19,
        0,
        18,
        27,
        18,
        25,
        49,
        22,
        49,
        17,
        11,
        0,
        0,
        15,
        15,
        17,
        26,
        33,
        43,
        41,
        48,
        35,
        0,
        49,
        33,
        34,
        0,
        0,
        19,
        15,
        14,
        23,
        27,
        9,
        9,
        17,
        49,
        19,
        6,
        0,
        16,
        29,
        40,
        3,
        49,
        18,
        49
      ],
      "weeklyBackordered": [
        19,
        0,
        38,
        49,
        39,
        0,
        14,
        44,
        38,
        1,
        46,
        49,
        7,
        14,
        28,
        43,
        12,
        0,
        13,
        27,
        36,
        23,
        0,
        34,
        1,
        14,
        44,
        14,
        38,
        49,
        22,
        49,
        44,
        25,
        7,
        0,
        10,
        11,
        23,
        22,
        12,
        48,
        0,
        49,
        0,
        38,
        22,
        10,
        41,
        49,
        49,
        24
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        0,
        49,
        27,
        48,
        11,
        20,
        4,
        32,
        48,
        20,
        41,
        0,
        0,
        0,
        48,
        8,
        49,
        9,
        23,
        24,
        20,
        45,
        49,
        20,
        46,
        3,
        33,
        44,
        30,
        23,
        6,
        0,
        35,
        0,
        37,
        49,
        45,
        16,
        11,
        32,
        0,
        0,
        48,
        16,
        49,
        0,
        0,
        0,
        30,
        49,
        0,
        42
      ],
      "weeklyRejected": [
        23,
        31,
        49,
        32,
        31,
        16,
        25,
        49,
        5,
        1,
        16,
        26,
        49,
        33,
        23,
        9,
        37,
        25,
        36,
        28,
        49,
        42,
        14,
        49,
        18,
        22,
        43,
        30,
        35,
        9,
        49,
        47,
        39,
        28,
        0,
        35,
        18,
        49,
        7,
        20,
        12,
        18,
        36,
        6,
        49,
        20,
        23,
        47,
        24,
        49,
        10,
        34
      ],
      "weeklyHold": [
        20,
        46,
        16,
        15,
        49,
        37,
        37,
        8,
        19,
        0,
        47,
        1,
        41,
        11,
        13,
        35,
        18,
        10,
        27,
        4,
        20,
        49,
        29,
        20,
        17,
        48,
        0,
        48,
        49,
        0,
        45,
        0,
        0,
        15,
        22,
        32,
        41,
        0,
        25,
        12,
        49,
        17,
        0,
        49,
        28,
        49,
        13,
        31,
        49,
        25,
        46,
        25
      ],
      "weeklyBackordered": [
        49,
        16,
        0,
        26,
        49,
        36,
        7,
        34,
        49,
        6,
        44,
        3,
        3,
        49,
        49,
        27,
        38,
        40,
        0,
        25,
        43,
        0,
        34,
        9,
        1,
        49,
        1,
        25,
        27,
        42,
        0,
        49,
        35,
        32,
        0,
        0,
        49,
        0,
        47,
        49,
        20,
        25,
        6,
        49,
        49,
        0,
        27,
        26,
        0,
        36,
        21,
        49
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        4,
        27,
        49,
        14,
        13,
        8,
        29,
        17,
        48,
        49,
        42,
        20,
        0,
        36,
        1,
        49,
        49,
        40,
        2,
        15,
        9,
        31,
        10,
        35,
        0,
        28,
        19,
        2,
        34,
        49,
        29,
        7,
        34,
        19,
        48,
        20,
        42,
        15,
        12,
        45,
        31,
        41,
        49,
        44,
        29,
        13,
        0,
        49,
        40,
        18,
        17,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        44,
        31,
        49,
        18,
        49,
        0,
        34,
        6,
        47,
        0,
        30,
        21,
        33,
        0,
        11,
        49,
        49,
        49,
        14,
        49,
        33,
        49,
        15,
        49,
        11,
        49,
        48,
        24,
        49,
        47,
        48,
        23,
        10,
        5,
        24,
        38,
        18,
        0,
        43,
        17,
        8,
        0,
        49,
        49,
        42,
        41,
        0,
        49,
        0,
        30
      ],
      "weeklyHold": [
        0,
        15,
        46,
        24,
        31,
        39,
        25,
        7,
        27,
        40,
        37,
        24,
        22,
        28,
        49,
        4,
        0,
        34,
        0,
        49,
        25,
        49,
        29,
        31,
        49,
        48,
        15,
        0,
        49,
        0,
        14,
        9,
        41,
        0,
        17,
        20,
        18,
        3,
        27,
        17,
        15,
        49,
        36,
        30,
        49,
        47,
        45,
        40,
        30,
        8,
        23,
        22
      ],
      "weeklyBackordered": [
        7,
        44,
        18,
        35,
        49,
        33,
        24,
        43,
        41,
        42,
        35,
        0,
        3,
        35,
        6,
        34,
        45,
        8,
        0,
        26,
        42,
        6,
        18,
        40,
        39,
        1,
        23,
        14,
        23,
        16,
        0,
        44,
        12,
        39,
        29,
        0,
        21,
        5,
        48,
        42,
        33,
        44,
        19,
        49,
        41,
        49,
        38,
        8,
        23,
        35,
        43,
        38
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        4,
        1,
        5,
        16,
        42,
        13,
        27,
        49,
        24,
        13,
        38,
        0,
        24,
        49,
        22,
        28,
        17,
        12,
        31,
        0,
        27,
        40,
        47,
        39,
        9,
        16,
        6,
        5,
        15,
        33,
        20,
        18,
        39,
        32,
        41,
        32,
        18,
        47,
        49,
        49,
        23,
        49,
        0,
        0,
        49,
        0,
        18,
        23,
        17,
        49,
        20,
        0
      ],
      "weeklyRejected": [
        5,
        0,
        25,
        32,
        7,
        30,
        4,
        20,
        49,
        1,
        41,
        48,
        48,
        47,
        48,
        32,
        25,
        48,
        48,
        14,
        5,
        0,
        0,
        49,
        49,
        18,
        41,
        24,
        8,
        14,
        27,
        29,
        48,
        49,
        25,
        49,
        19,
        35,
        1,
        28,
        11,
        25,
        49,
        14,
        47,
        25,
        25,
        15,
        0,
        11,
        20,
        40
      ],
      "weeklyHold": [
        45,
        49,
        49,
        1,
        42,
        37,
        12,
        40,
        25,
        25,
        10,
        1,
        48,
        23,
        12,
        21,
        0,
        42,
        2,
        0,
        46,
        42,
        44,
        29,
        49,
        9,
        44,
        16,
        49,
        6,
        45,
        38,
        0,
        11,
        48,
        18,
        27,
        0,
        49,
        49,
        0,
        31,
        17,
        49,
        49,
        31,
        0,
        36,
        17,
        38,
        0,
        10
      ],
      "weeklyBackordered": [
        21,
        42,
        10,
        14,
        15,
        29,
        49,
        18,
        32,
        35,
        6,
        3,
        3,
        42,
        0,
        17,
        49,
        2,
        18,
        0,
        34,
        0,
        7,
        34,
        48,
        21,
        25,
        49,
        26,
        32,
        38,
        49,
        35,
        49,
        21,
        13,
        27,
        48,
        32,
        37,
        0,
        48,
        3,
        49,
        11,
        30,
        34,
        45,
        0,
        41,
        35,
        1
      ]
    },
    {
      "id": "DB301E71",
      "weeklyAccepted": [
        37,
        22,
        14,
        12,
        22,
        17,
        26,
        44,
        36,
        37,
        28,
        0,
        13,
        24,
        29,
        16,
        49,
        10,
        0,
        6,
        25,
        15,
        7,
        2,
        12,
        13,
        27,
        44,
        34,
        29,
        49,
        0,
        9,
        14,
        48,
        23,
        47,
        31,
        29,
        49,
        5,
        3,
        28,
        49,
        49,
        49,
        11,
        1,
        40,
        49,
        49,
        19
      ],
      "weeklyRejected": [
        49,
        11,
        22,
        11,
        49,
        17,
        24,
        49,
        33,
        1,
        27,
        32,
        25,
        30,
        48,
        14,
        21,
        49,
        40,
        17,
        17,
        0,
        19,
        10,
        11,
        37,
        25,
        0,
        37,
        48,
        3,
        47,
        34,
        27,
        25,
        49,
        18,
        37,
        1,
        0,
        48,
        0,
        27,
        13,
        40,
        0,
        17,
        3,
        0,
        35,
        0,
        28
      ],
      "weeklyHold": [
        49,
        49,
        32,
        8,
        49,
        41,
        38,
        16,
        28,
        38,
        43,
        37,
        6,
        39,
        49,
        0,
        29,
        27,
        19,
        30,
        30,
        18,
        26,
        28,
        44,
        48,
        4,
        14,
        36,
        17,
        38,
        17,
        39,
        45,
        37,
        0,
        49,
        0,
        32,
        42,
        30,
        49,
        4,
        21,
        19,
        17,
        49,
        17,
        26,
        19,
        38,
        1
      ],
      "weeklyBackordered": [
        45,
        21,
        15,
        18,
        49,
        29,
        21,
        1,
        35,
        12,
        0,
        49,
        22,
        16,
        39,
        7,
        1,
        15,
        12,
        25,
        49,
        8,
        15,
        0,
        11,
        20,
        3,
        49,
        19,
        49,
        34,
        44,
        27,
        47,
        0,
        8,
        6,
        13,
        49,
        31,
        25,
        48,
        11,
        25,
        11,
        39,
        9,
        0,
        0,
        19,
        49,
        3
      ]
    },
    {
      "id": "049BCC88",
      "weeklyAccepted": [
        17,
        1,
        23,
        18,
        35,
        37,
        11,
        23,
        34,
        4,
        49,
        0,
        46,
        49,
        18,
        49,
        39,
        6,
        7,
        29,
        22,
        37,
        8,
        19,
        0,
        17,
        25,
        18,
        33,
        42,
        26,
        0,
        44,
        36,
        24,
        30,
        14,
        11,
        0,
        46,
        17,
        17,
        40,
        0,
        36,
        0,
        0,
        0,
        11,
        49,
        20,
        0
      ],
      "weeklyRejected": [
        32,
        6,
        2,
        40,
        0,
        4,
        49,
        10,
        46,
        1,
        19,
        49,
        11,
        17,
        23,
        19,
        34,
        33,
        40,
        46,
        21,
        40,
        38,
        49,
        25,
        47,
        42,
        14,
        0,
        39,
        49,
        29,
        13,
        47,
        24,
        49,
        29,
        33,
        1,
        23,
        17,
        32,
        49,
        0,
        13,
        49,
        0,
        4,
        22,
        49,
        13,
        28
      ],
      "weeklyHold": [
        14,
        23,
        42,
        12,
        47,
        34,
        20,
        17,
        13,
        5,
        24,
        39,
        46,
        23,
        34,
        48,
        13,
        26,
        44,
        20,
        25,
        31,
        42,
        11,
        49,
        46,
        6,
        32,
        49,
        25,
        48,
        11,
        14,
        7,
        48,
        16,
        19,
        0,
        22,
        49,
        6,
        33,
        35,
        47,
        29,
        49,
        0,
        35,
        1,
        22,
        19,
        11
      ],
      "weeklyBackordered": [
        27,
        19,
        19,
        29,
        11,
        27,
        49,
        46,
        28,
        15,
        8,
        49,
        40,
        6,
        32,
        27,
        33,
        35,
        21,
        38,
        34,
        7,
        0,
        27,
        14,
        1,
        20,
        28,
        31,
        36,
        43,
        0,
        39,
        49,
        0,
        16,
        49,
        30,
        5,
        26,
        48,
        33,
        15,
        38,
        15,
        16,
        27,
        19,
        5,
        38,
        42,
        1
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        17,
        49,
        45,
        20,
        25,
        18,
        28,
        12,
        12,
        49,
        28,
        10,
        38,
        33,
        32,
        0,
        34,
        0,
        0,
        19,
        12,
        36,
        14,
        26,
        10,
        36,
        48,
        49,
        11,
        14,
        31,
        0,
        49,
        0,
        3,
        49,
        47,
        15,
        28,
        49,
        0,
        15,
        49,
        49,
        28,
        17,
        26,
        23,
        49,
        49,
        6,
        39
      ],
      "weeklyRejected": [
        42,
        23,
        35,
        47,
        49,
        0,
        0,
        36,
        36,
        40,
        31,
        1,
        0,
        39,
        25,
        0,
        29,
        34,
        41,
        36,
        10,
        49,
        35,
        11,
        0,
        49,
        37,
        41,
        40,
        24,
        39,
        25,
        32,
        32,
        10,
        49,
        46,
        33,
        10,
        0,
        32,
        47,
        49,
        6,
        43,
        49,
        25,
        22,
        44,
        49,
        0,
        24
      ],
      "weeklyHold": [
        20,
        5,
        49,
        17,
        34,
        34,
        35,
        11,
        15,
        15,
        36,
        31,
        28,
        22,
        49,
        11,
        0,
        9,
        10,
        26,
        29,
        0,
        30,
        22,
        13,
        48,
        18,
        0,
        49,
        11,
        30,
        16,
        11,
        49,
        9,
        42,
        36,
        14,
        12,
        27,
        37,
        32,
        0,
        20,
        32,
        30,
        49,
        25,
        0,
        49,
        16,
        49
      ],
      "weeklyBackordered": [
        41,
        1,
        35,
        10,
        44,
        8,
        36,
        13,
        19,
        7,
        14,
        49,
        32,
        34,
        0,
        1,
        14,
        49,
        0,
        14,
        36,
        33,
        2,
        4,
        32,
        21,
        2,
        24,
        19,
        49,
        47,
        44,
        48,
        0,
        35,
        0,
        28,
        1,
        19,
        36,
        18,
        24,
        0,
        49,
        0,
        49,
        49,
        9,
        27,
        32,
        24,
        31
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        28,
        19,
        22,
        46,
        1,
        0,
        37,
        49,
        19,
        46,
        9,
        0,
        3,
        41,
        1,
        22,
        49,
        24,
        24,
        0,
        2,
        49,
        22,
        0,
        32,
        26,
        33,
        40,
        10,
        46,
        0,
        25,
        18,
        0,
        48,
        2,
        40,
        48,
        47,
        49,
        49,
        49,
        40,
        31,
        48,
        25,
        7,
        49,
        23,
        46,
        6,
        28
      ],
      "weeklyRejected": [
        49,
        13,
        7,
        11,
        21,
        0,
        49,
        10,
        49,
        11,
        16,
        0,
        28,
        48,
        46,
        17,
        38,
        30,
        44,
        0,
        49,
        31,
        49,
        12,
        2,
        15,
        25,
        20,
        48,
        21,
        21,
        47,
        37,
        43,
        27,
        49,
        5,
        40,
        2,
        14,
        24,
        31,
        1,
        34,
        49,
        23,
        17,
        25,
        12,
        49,
        10,
        49
      ],
      "weeklyHold": [
        20,
        9,
        14,
        38,
        17,
        35,
        5,
        20,
        40,
        30,
        11,
        27,
        1,
        13,
        5,
        6,
        12,
        0,
        8,
        17,
        14,
        13,
        17,
        19,
        26,
        17,
        49,
        13,
        49,
        12,
        35,
        7,
        0,
        29,
        24,
        45,
        45,
        1,
        35,
        27,
        0,
        39,
        0,
        0,
        49,
        32,
        33,
        41,
        1,
        29,
        40,
        29
      ],
      "weeklyBackordered": [
        34,
        19,
        0,
        34,
        44,
        20,
        29,
        15,
        14,
        18,
        35,
        39,
        49,
        42,
        0,
        48,
        36,
        22,
        9,
        8,
        25,
        0,
        19,
        43,
        26,
        25,
        29,
        12,
        1,
        18,
        46,
        36,
        25,
        39,
        0,
        0,
        49,
        17,
        37,
        2,
        3,
        5,
        26,
        38,
        40,
        0,
        22,
        28,
        0,
        33,
        49,
        31
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        0,
        49,
        33,
        48,
        1,
        36,
        17,
        18,
        34,
        49,
        17,
        3,
        15,
        18,
        17,
        0,
        49,
        15,
        10,
        8,
        15,
        41,
        46,
        33,
        17,
        41,
        10,
        38,
        27,
        49,
        5,
        4,
        47,
        44,
        48,
        17,
        30,
        44,
        21,
        49,
        14,
        39,
        19,
        49,
        49,
        38,
        12,
        22,
        24,
        21,
        15,
        46
      ],
      "weeklyRejected": [
        49,
        23,
        15,
        47,
        9,
        16,
        27,
        23,
        34,
        10,
        0,
        30,
        11,
        37,
        2,
        12,
        27,
        42,
        49,
        49,
        49,
        38,
        8,
        18,
        36,
        38,
        33,
        19,
        46,
        20,
        49,
        19,
        24,
        10,
        23,
        49,
        49,
        0,
        11,
        30,
        15,
        28,
        18,
        25,
        49,
        37,
        4,
        49,
        19,
        49,
        7,
        13
      ],
      "weeklyHold": [
        49,
        0,
        10,
        17,
        49,
        6,
        26,
        23,
        18,
        27,
        0,
        17,
        16,
        9,
        28,
        28,
        0,
        44,
        48,
        1,
        10,
        18,
        38,
        4,
        0,
        34,
        0,
        2,
        49,
        17,
        28,
        17,
        7,
        13,
        48,
        47,
        18,
        29,
        49,
        49,
        26,
        15,
        0,
        36,
        44,
        48,
        9,
        46,
        0,
        5,
        49,
        10
      ],
      "weeklyBackordered": [
        16,
        12,
        32,
        0,
        12,
        7,
        39,
        49,
        3,
        13,
        0,
        29,
        27,
        14,
        28,
        37,
        14,
        49,
        2,
        16,
        49,
        0,
        0,
        44,
        41,
        33,
        12,
        33,
        21,
        49,
        46,
        0,
        0,
        49,
        19,
        0,
        1,
        0,
        5,
        30,
        20,
        48,
        0,
        48,
        0,
        30,
        49,
        1,
        37,
        0,
        30,
        1
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        14,
        42,
        9,
        32,
        22,
        0,
        34,
        45,
        45,
        24,
        35,
        0,
        45,
        12,
        28,
        7,
        41,
        30,
        41,
        24,
        49,
        23,
        38,
        4,
        33,
        37,
        25,
        34,
        1,
        37,
        29,
        9,
        49,
        9,
        12,
        8,
        43,
        48,
        49,
        49,
        22,
        5,
        42,
        32,
        35,
        7,
        5,
        9,
        49,
        49,
        0,
        48
      ],
      "weeklyRejected": [
        27,
        38,
        49,
        47,
        30,
        35,
        46,
        31,
        28,
        22,
        39,
        30,
        9,
        21,
        48,
        21,
        6,
        47,
        32,
        8,
        49,
        31,
        10,
        49,
        20,
        42,
        35,
        42,
        46,
        0,
        27,
        26,
        48,
        20,
        36,
        49,
        5,
        0,
        15,
        9,
        48,
        0,
        49,
        14,
        49,
        23,
        29,
        21,
        37,
        7,
        0,
        40
      ],
      "weeklyHold": [
        23,
        34,
        18,
        26,
        30,
        15,
        28,
        2,
        1,
        19,
        47,
        30,
        41,
        5,
        27,
        41,
        17,
        43,
        10,
        39,
        43,
        25,
        7,
        6,
        31,
        25,
        22,
        0,
        25,
        4,
        35,
        9,
        0,
        35,
        48,
        0,
        10,
        0,
        39,
        0,
        49,
        33,
        0,
        41,
        49,
        5,
        23,
        47,
        0,
        25,
        14,
        45
      ],
      "weeklyBackordered": [
        49,
        37,
        23,
        38,
        47,
        20,
        0,
        33,
        49,
        17,
        12,
        34,
        29,
        41,
        35,
        35,
        46,
        41,
        0,
        32,
        3,
        49,
        49,
        0,
        1,
        32,
        16,
        43,
        5,
        49,
        19,
        41,
        37,
        18,
        0,
        1,
        15,
        0,
        14,
        6,
        0,
        48,
        11,
        38,
        14,
        27,
        49,
        0,
        33,
        35,
        26,
        5
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        18,
        22,
        2,
        14,
        22,
        7,
        1,
        49,
        48,
        49,
        43,
        0,
        23,
        39,
        3,
        14,
        49,
        0,
        22,
        32,
        30,
        37,
        31,
        32,
        27,
        8,
        40,
        49,
        9,
        33,
        13,
        26,
        46,
        0,
        48,
        37,
        24,
        48,
        13,
        44,
        0,
        27,
        26,
        11,
        49,
        34,
        28,
        0,
        19,
        30,
        16,
        21
      ],
      "weeklyRejected": [
        27,
        18,
        22,
        23,
        10,
        4,
        49,
        33,
        49,
        23,
        28,
        5,
        0,
        40,
        31,
        20,
        3,
        42,
        49,
        14,
        30,
        26,
        12,
        46,
        28,
        36,
        45,
        33,
        0,
        33,
        41,
        47,
        37,
        20,
        19,
        49,
        38,
        49,
        7,
        0,
        44,
        30,
        49,
        48,
        36,
        29,
        32,
        14,
        27,
        16,
        0,
        31
      ],
      "weeklyHold": [
        3,
        0,
        49,
        48,
        42,
        48,
        18,
        7,
        0,
        2,
        42,
        18,
        46,
        13,
        36,
        14,
        5,
        0,
        1,
        37,
        4,
        49,
        35,
        12,
        47,
        25,
        15,
        23,
        21,
        30,
        48,
        27,
        19,
        0,
        34,
        8,
        1,
        5,
        21,
        25,
        5,
        5,
        12,
        19,
        25,
        18,
        0,
        40,
        23,
        36,
        48,
        20
      ],
      "weeklyBackordered": [
        18,
        3,
        2,
        31,
        49,
        46,
        49,
        49,
        1,
        11,
        34,
        13,
        49,
        49,
        2,
        48,
        29,
        48,
        0,
        8,
        23,
        49,
        33,
        49,
        17,
        1,
        13,
        49,
        34,
        22,
        49,
        28,
        42,
        47,
        39,
        0,
        22,
        0,
        35,
        31,
        48,
        48,
        0,
        49,
        25,
        0,
        46,
        35,
        4,
        45,
        25,
        19
      ]
    },
    {
      "id": "8164C892",
      "weeklyAccepted": [
        0,
        39,
        17,
        0,
        20,
        17,
        1,
        2,
        48,
        12,
        49,
        0,
        15,
        42,
        1,
        0,
        41,
        16,
        35,
        0,
        0,
        18,
        25,
        49,
        22,
        39,
        40,
        25,
        20,
        35,
        48,
        22,
        33,
        10,
        48,
        36,
        24,
        48,
        49,
        32,
        23,
        27,
        49,
        38,
        49,
        0,
        14,
        49,
        5,
        9,
        49,
        42
      ],
      "weeklyRejected": [
        49,
        0,
        8,
        0,
        27,
        27,
        42,
        0,
        40,
        11,
        30,
        0,
        8,
        23,
        15,
        47,
        48,
        31,
        0,
        0,
        23,
        49,
        49,
        40,
        37,
        48,
        49,
        26,
        40,
        17,
        3,
        38,
        12,
        49,
        1,
        26,
        0,
        25,
        3,
        49,
        33,
        49,
        49,
        29,
        49,
        39,
        49,
        34,
        17,
        47,
        0,
        29
      ],
      "weeklyHold": [
        6,
        47,
        24,
        15,
        0,
        26,
        34,
        36,
        28,
        33,
        13,
        47,
        33,
        17,
        49,
        26,
        0,
        19,
        13,
        2,
        0,
        49,
        1,
        49,
        43,
        15,
        0,
        32,
        49,
        24,
        48,
        1,
        0,
        7,
        23,
        20,
        22,
        23,
        33,
        8,
        24,
        35,
        26,
        49,
        36,
        25,
        21,
        37,
        7,
        49,
        35,
        44
      ],
      "weeklyBackordered": [
        38,
        39,
        0,
        49,
        25,
        30,
        45,
        1,
        42,
        30,
        49,
        0,
        31,
        49,
        29,
        24,
        49,
        28,
        22,
        15,
        0,
        0,
        0,
        0,
        26,
        18,
        4,
        20,
        21,
        31,
        0,
        49,
        14,
        44,
        0,
        0,
        46,
        30,
        11,
        49,
        47,
        24,
        0,
        49,
        30,
        41,
        36,
        0,
        0,
        3,
        10,
        38
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        20,
        39,
        49,
        13,
        1,
        26,
        21,
        41,
        38,
        0,
        35,
        0,
        26,
        26,
        6,
        27,
        49,
        17,
        31,
        9,
        49,
        46,
        5,
        49,
        19,
        30,
        0,
        13,
        27,
        49,
        16,
        43,
        47,
        25,
        38,
        10,
        47,
        40,
        45,
        49,
        19,
        23,
        16,
        49,
        38,
        8,
        22,
        49,
        36,
        12,
        7,
        46
      ],
      "weeklyRejected": [
        26,
        0,
        16,
        0,
        28,
        24,
        27,
        25,
        49,
        17,
        45,
        16,
        15,
        1,
        48,
        8,
        3,
        47,
        39,
        36,
        14,
        35,
        0,
        18,
        0,
        49,
        47,
        21,
        13,
        32,
        12,
        27,
        31,
        23,
        49,
        49,
        7,
        31,
        28,
        18,
        48,
        49,
        15,
        0,
        49,
        0,
        9,
        0,
        35,
        49,
        0,
        49
      ],
      "weeklyHold": [
        48,
        31,
        49,
        14,
        0,
        37,
        46,
        2,
        1,
        49,
        47,
        35,
        13,
        24,
        49,
        6,
        0,
        19,
        13,
        0,
        1,
        35,
        10,
        0,
        32,
        22,
        21,
        26,
        41,
        0,
        48,
        17,
        0,
        37,
        15,
        9,
        16,
        2,
        14,
        49,
        0,
        38,
        3,
        31,
        15,
        13,
        0,
        41,
        7,
        39,
        0,
        24
      ],
      "weeklyBackordered": [
        39,
        35,
        0,
        30,
        32,
        27,
        49,
        25,
        34,
        15,
        25,
        13,
        27,
        8,
        49,
        29,
        49,
        0,
        0,
        3,
        14,
        38,
        22,
        41,
        38,
        1,
        49,
        3,
        49,
        27,
        5,
        49,
        48,
        47,
        22,
        46,
        11,
        38,
        9,
        18,
        48,
        36,
        11,
        49,
        11,
        20,
        30,
        20,
        30,
        23,
        1,
        5
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        0,
        9,
        2,
        29,
        1,
        29,
        19,
        3,
        48,
        21,
        19,
        14,
        15,
        35,
        27,
        35,
        49,
        0,
        15,
        46,
        19,
        47,
        24,
        0,
        16,
        47,
        21,
        31,
        49,
        28,
        0,
        2,
        30,
        0,
        37,
        49,
        25,
        25,
        11,
        49,
        17,
        0,
        33,
        30,
        49,
        12,
        10,
        0,
        9,
        49,
        27,
        29
      ],
      "weeklyRejected": [
        46,
        22,
        41,
        34,
        2,
        31,
        23,
        49,
        49,
        20,
        16,
        0,
        48,
        0,
        30,
        14,
        49,
        49,
        44,
        33,
        10,
        13,
        24,
        48,
        2,
        26,
        36,
        29,
        45,
        28,
        0,
        21,
        30,
        41,
        34,
        46,
        35,
        31,
        15,
        17,
        12,
        0,
        2,
        14,
        35,
        40,
        7,
        0,
        48,
        49,
        42,
        23
      ],
      "weeklyHold": [
        8,
        44,
        49,
        27,
        10,
        39,
        0,
        21,
        26,
        49,
        35,
        29,
        40,
        1,
        49,
        19,
        7,
        13,
        12,
        49,
        24,
        49,
        3,
        26,
        28,
        28,
        4,
        0,
        22,
        48,
        48,
        0,
        15,
        39,
        32,
        15,
        19,
        0,
        12,
        15,
        19,
        49,
        0,
        34,
        49,
        0,
        5,
        49,
        9,
        32,
        49,
        40
      ],
      "weeklyBackordered": [
        7,
        22,
        30,
        32,
        48,
        23,
        21,
        10,
        7,
        45,
        5,
        31,
        16,
        42,
        32,
        32,
        14,
        32,
        10,
        12,
        49,
        49,
        34,
        15,
        15,
        20,
        47,
        20,
        27,
        10,
        5,
        22,
        42,
        21,
        28,
        0,
        49,
        13,
        28,
        10,
        19,
        24,
        17,
        8,
        12,
        46,
        32,
        10,
        12,
        35,
        44,
        1
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        32,
        49,
        23,
        17,
        23,
        0,
        4,
        29,
        48,
        0,
        48,
        7,
        49,
        26,
        9,
        49,
        38,
        29,
        6,
        0,
        47,
        21,
        38,
        49,
        9,
        25,
        12,
        33,
        43,
        28,
        32,
        22,
        49,
        1,
        35,
        39,
        37,
        7,
        49,
        49,
        31,
        49,
        49,
        0,
        49,
        0,
        0,
        29,
        2,
        31,
        16,
        5
      ],
      "weeklyRejected": [
        35,
        10,
        43,
        47,
        44,
        0,
        0,
        12,
        49,
        24,
        31,
        13,
        49,
        17,
        43,
        36,
        21,
        49,
        33,
        49,
        49,
        0,
        49,
        21,
        19,
        43,
        28,
        1,
        28,
        19,
        20,
        24,
        40,
        34,
        33,
        49,
        9,
        0,
        1,
        49,
        41,
        24,
        37,
        0,
        38,
        20,
        40,
        46,
        14,
        13,
        0,
        49
      ],
      "weeklyHold": [
        17,
        5,
        32,
        13,
        25,
        29,
        26,
        3,
        3,
        2,
        0,
        43,
        19,
        39,
        4,
        29,
        22,
        0,
        32,
        30,
        29,
        20,
        41,
        17,
        12,
        41,
        33,
        12,
        49,
        5,
        48,
        3,
        0,
        16,
        48,
        3,
        20,
        7,
        33,
        23,
        15,
        33,
        0,
        17,
        27,
        47,
        8,
        25,
        0,
        0,
        31,
        18
      ],
      "weeklyBackordered": [
        26,
        33,
        42,
        42,
        46,
        32,
        49,
        12,
        38,
        0,
        30,
        49,
        3,
        40,
        49,
        29,
        13,
        0,
        15,
        41,
        17,
        4,
        9,
        38,
        1,
        14,
        19,
        0,
        19,
        29,
        46,
        32,
        34,
        49,
        0,
        2,
        3,
        45,
        27,
        6,
        0,
        48,
        0,
        49,
        40,
        21,
        49,
        21,
        16,
        40,
        33,
        31
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        30,
        7,
        49,
        37,
        14,
        3,
        15,
        33,
        12,
        49,
        30,
        0,
        49,
        49,
        1,
        3,
        47,
        31,
        37,
        33,
        0,
        26,
        39,
        29,
        0,
        47,
        49,
        49,
        32,
        38,
        23,
        47,
        36,
        30,
        48,
        41,
        25,
        18,
        7,
        49,
        15,
        49,
        21,
        19,
        23,
        0,
        16,
        23,
        24,
        17,
        16,
        2
      ],
      "weeklyRejected": [
        32,
        49,
        2,
        25,
        30,
        25,
        49,
        13,
        41,
        15,
        26,
        10,
        11,
        14,
        14,
        0,
        6,
        31,
        49,
        0,
        0,
        41,
        0,
        49,
        29,
        49,
        41,
        32,
        41,
        21,
        49,
        47,
        17,
        47,
        14,
        49,
        23,
        48,
        31,
        49,
        36,
        14,
        37,
        32,
        49,
        26,
        10,
        18,
        0,
        20,
        39,
        44
      ],
      "weeklyHold": [
        45,
        0,
        44,
        4,
        0,
        33,
        34,
        29,
        0,
        30,
        16,
        17,
        19,
        12,
        49,
        27,
        0,
        0,
        0,
        1,
        0,
        31,
        15,
        27,
        35,
        10,
        12,
        39,
        49,
        11,
        48,
        33,
        0,
        40,
        33,
        49,
        37,
        49,
        49,
        47,
        14,
        26,
        36,
        21,
        49,
        17,
        48,
        49,
        6,
        6,
        27,
        31
      ],
      "weeklyBackordered": [
        40,
        3,
        19,
        49,
        49,
        32,
        38,
        39,
        26,
        21,
        37,
        20,
        28,
        35,
        0,
        38,
        49,
        46,
        43,
        49,
        46,
        6,
        13,
        49,
        40,
        49,
        36,
        17,
        25,
        49,
        41,
        33,
        24,
        38,
        19,
        32,
        1,
        30,
        0,
        5,
        43,
        48,
        37,
        49,
        44,
        49,
        32,
        39,
        32,
        33,
        35,
        42
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        17,
        25,
        29,
        48,
        11,
        49,
        1,
        49,
        48,
        26,
        48,
        0,
        0,
        28,
        30,
        6,
        35,
        38,
        49,
        29,
        48,
        36,
        31,
        0,
        47,
        13,
        12,
        22,
        26,
        49,
        39,
        24,
        15,
        0,
        48,
        38,
        35,
        47,
        10,
        49,
        22,
        0,
        0,
        26,
        49,
        0,
        6,
        0,
        16,
        38,
        0,
        39
      ],
      "weeklyRejected": [
        36,
        3,
        2,
        19,
        36,
        0,
        46,
        46,
        45,
        1,
        20,
        2,
        49,
        0,
        46,
        10,
        29,
        39,
        49,
        29,
        14,
        6,
        43,
        23,
        24,
        39,
        37,
        0,
        48,
        7,
        14,
        47,
        30,
        0,
        49,
        36,
        49,
        49,
        1,
        16,
        25,
        38,
        43,
        16,
        49,
        49,
        15,
        3,
        0,
        44,
        15,
        49
      ],
      "weeklyHold": [
        45,
        1,
        41,
        44,
        5,
        11,
        39,
        21,
        22,
        49,
        37,
        37,
        9,
        5,
        19,
        0,
        22,
        29,
        0,
        0,
        0,
        30,
        29,
        49,
        37,
        48,
        4,
        0,
        28,
        0,
        48,
        35,
        18,
        49,
        21,
        49,
        11,
        0,
        49,
        10,
        0,
        29,
        20,
        29,
        19,
        47,
        6,
        25,
        35,
        0,
        28,
        21
      ],
      "weeklyBackordered": [
        15,
        36,
        19,
        17,
        49,
        39,
        28,
        49,
        29,
        21,
        15,
        14,
        13,
        25,
        18,
        24,
        35,
        49,
        11,
        18,
        28,
        49,
        26,
        17,
        26,
        30,
        20,
        16,
        22,
        40,
        49,
        0,
        48,
        40,
        0,
        0,
        47,
        20,
        25,
        21,
        27,
        48,
        29,
        9,
        44,
        2,
        49,
        4,
        0,
        45,
        42,
        11
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        1,
        48,
        23,
        37,
        11,
        35,
        44,
        19,
        48,
        49,
        39,
        10,
        16,
        49,
        15,
        10,
        26,
        34,
        0,
        49,
        13,
        37,
        39,
        41,
        29,
        38,
        44,
        38,
        1,
        44,
        43,
        25,
        49,
        45,
        18,
        49,
        34,
        1,
        5,
        49,
        25,
        0,
        49,
        49,
        33,
        0,
        2,
        49,
        49,
        44,
        6,
        17
      ],
      "weeklyRejected": [
        0,
        0,
        24,
        33,
        9,
        42,
        49,
        10,
        39,
        6,
        19,
        33,
        12,
        32,
        48,
        15,
        27,
        9,
        0,
        42,
        23,
        49,
        49,
        9,
        27,
        49,
        49,
        29,
        44,
        0,
        49,
        2,
        46,
        43,
        30,
        37,
        44,
        45,
        1,
        49,
        48,
        49,
        25,
        13,
        40,
        49,
        37,
        46,
        1,
        49,
        0,
        41
      ],
      "weeklyHold": [
        15,
        0,
        26,
        13,
        24,
        3,
        49,
        5,
        25,
        46,
        47,
        22,
        24,
        9,
        39,
        8,
        3,
        40,
        12,
        49,
        0,
        19,
        11,
        0,
        24,
        48,
        13,
        10,
        49,
        17,
        48,
        0,
        0,
        49,
        0,
        49,
        39,
        7,
        30,
        0,
        14,
        49,
        15,
        45,
        8,
        49,
        19,
        33,
        0,
        0,
        36,
        44
      ],
      "weeklyBackordered": [
        17,
        21,
        0,
        8,
        48,
        12,
        23,
        49,
        45,
        26,
        46,
        30,
        16,
        38,
        27,
        32,
        18,
        7,
        0,
        0,
        11,
        21,
        16,
        49,
        48,
        3,
        13,
        49,
        23,
        12,
        46,
        24,
        35,
        12,
        0,
        0,
        9,
        0,
        14,
        24,
        29,
        48,
        12,
        49,
        16,
        19,
        49,
        26,
        0,
        27,
        36,
        48
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        7,
        49,
        22,
        28,
        49,
        2,
        15,
        34,
        43,
        39,
        29,
        42,
        7,
        26,
        33,
        48,
        45,
        9,
        0,
        0,
        44,
        7,
        29,
        39,
        29,
        29,
        43,
        34,
        34,
        8,
        25,
        13,
        49,
        40,
        21,
        49,
        24,
        41,
        22,
        45,
        25,
        49,
        49,
        49,
        32,
        0,
        16,
        18,
        23,
        49,
        20,
        48
      ],
      "weeklyRejected": [
        49,
        14,
        48,
        47,
        49,
        29,
        27,
        6,
        24,
        5,
        32,
        35,
        18,
        46,
        38,
        25,
        0,
        11,
        19,
        9,
        49,
        12,
        21,
        16,
        49,
        49,
        49,
        9,
        33,
        2,
        49,
        25,
        23,
        30,
        27,
        34,
        49,
        31,
        1,
        17,
        28,
        31,
        29,
        31,
        49,
        16,
        40,
        3,
        0,
        31,
        17,
        49
      ],
      "weeklyHold": [
        17,
        0,
        21,
        1,
        23,
        29,
        45,
        22,
        15,
        31,
        21,
        49,
        19,
        39,
        39,
        45,
        9,
        23,
        13,
        49,
        34,
        5,
        11,
        25,
        0,
        48,
        49,
        4,
        49,
        2,
        26,
        4,
        12,
        15,
        48,
        48,
        18,
        37,
        49,
        0,
        41,
        20,
        29,
        7,
        49,
        38,
        27,
        28,
        0,
        0,
        44,
        22
      ],
      "weeklyBackordered": [
        38,
        24,
        11,
        21,
        49,
        13,
        4,
        24,
        47,
        25,
        49,
        15,
        49,
        32,
        0,
        35,
        40,
        49,
        18,
        29,
        34,
        21,
        7,
        26,
        18,
        1,
        27,
        49,
        16,
        29,
        12,
        32,
        32,
        28,
        0,
        0,
        1,
        26,
        23,
        0,
        1,
        30,
        0,
        49,
        33,
        44,
        49,
        0,
        49,
        22,
        27,
        27
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        8,
        19,
        0,
        48,
        9,
        35,
        33,
        42,
        10,
        22,
        22,
        0,
        49,
        27,
        1,
        10,
        12,
        28,
        13,
        37,
        37,
        49,
        49,
        21,
        10,
        34,
        14,
        46,
        4,
        49,
        49,
        39,
        49,
        29,
        36,
        26,
        47,
        32,
        45,
        49,
        26,
        34,
        33,
        46,
        49,
        23,
        40,
        28,
        9,
        16,
        18,
        11
      ],
      "weeklyRejected": [
        49,
        21,
        33,
        22,
        24,
        0,
        44,
        34,
        49,
        1,
        39,
        27,
        49,
        48,
        48,
        0,
        7,
        47,
        44,
        24,
        21,
        49,
        7,
        43,
        17,
        49,
        2,
        23,
        48,
        24,
        35,
        47,
        48,
        14,
        41,
        49,
        49,
        0,
        2,
        0,
        48,
        22,
        36,
        10,
        32,
        49,
        2,
        42,
        25,
        49,
        0,
        41
      ],
      "weeklyHold": [
        19,
        26,
        7,
        12,
        48,
        9,
        49,
        11,
        15,
        35,
        27,
        42,
        39,
        44,
        30,
        12,
        15,
        26,
        22,
        32,
        19,
        35,
        35,
        49,
        49,
        48,
        21,
        22,
        25,
        22,
        29,
        8,
        37,
        27,
        27,
        37,
        36,
        7,
        46,
        35,
        9,
        23,
        25,
        22,
        26,
        49,
        17,
        34,
        36,
        20,
        41,
        49
      ],
      "weeklyBackordered": [
        26,
        33,
        16,
        41,
        26,
        20,
        16,
        49,
        49,
        39,
        19,
        23,
        29,
        23,
        12,
        23,
        49,
        35,
        0,
        26,
        18,
        0,
        13,
        26,
        48,
        31,
        20,
        33,
        1,
        47,
        28,
        49,
        36,
        49,
        0,
        0,
        37,
        12,
        31,
        27,
        15,
        45,
        15,
        40,
        0,
        21,
        25,
        33,
        33,
        48,
        41,
        14
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        14,
        24,
        29,
        0,
        5,
        7,
        21,
        41,
        0,
        30,
        49,
        49,
        44,
        0,
        37,
        27,
        49,
        22,
        29,
        24,
        2,
        0,
        49,
        17,
        27,
        0,
        0,
        49,
        2,
        20,
        43,
        9,
        49,
        16,
        29,
        38,
        25,
        43,
        10,
        49,
        0,
        9,
        44,
        42,
        16,
        0,
        4,
        22,
        0,
        13,
        32,
        43
      ],
      "weeklyRejected": [
        49,
        3,
        38,
        22,
        25,
        25,
        17,
        26,
        49,
        5,
        25,
        0,
        14,
        48,
        22,
        21,
        30,
        32,
        49,
        39,
        17,
        47,
        34,
        28,
        23,
        20,
        25,
        1,
        12,
        16,
        16,
        31,
        45,
        19,
        31,
        49,
        23,
        49,
        4,
        49,
        15,
        0,
        0,
        0,
        27,
        47,
        0,
        26,
        17,
        37,
        12,
        49
      ],
      "weeklyHold": [
        19,
        30,
        34,
        16,
        4,
        43,
        10,
        32,
        0,
        49,
        23,
        6,
        16,
        23,
        49,
        0,
        25,
        0,
        12,
        38,
        12,
        11,
        49,
        0,
        0,
        38,
        49,
        10,
        26,
        33,
        35,
        16,
        0,
        49,
        44,
        49,
        30,
        12,
        4,
        0,
        25,
        11,
        0,
        49,
        20,
        48,
        12,
        21,
        0,
        17,
        26,
        10
      ],
      "weeklyBackordered": [
        46,
        18,
        15,
        16,
        35,
        46,
        16,
        48,
        15,
        20,
        0,
        49,
        3,
        20,
        25,
        30,
        49,
        49,
        31,
        1,
        46,
        30,
        37,
        49,
        11,
        32,
        11,
        29,
        29,
        16,
        16,
        21,
        17,
        29,
        24,
        37,
        16,
        4,
        19,
        35,
        0,
        48,
        11,
        23,
        31,
        9,
        17,
        27,
        17,
        8,
        1,
        43
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        0,
        18,
        30,
        39,
        4,
        32,
        49,
        2,
        44,
        49,
        27,
        0,
        46,
        23,
        33,
        31,
        49,
        9,
        0,
        30,
        48,
        31,
        12,
        41,
        38,
        20,
        49,
        37,
        23,
        31,
        22,
        21,
        37,
        36,
        23,
        33,
        18,
        44,
        24,
        49,
        31,
        36,
        49,
        10,
        49,
        0,
        33,
        0,
        14,
        15,
        28,
        42
      ],
      "weeklyRejected": [
        19,
        4,
        20,
        47,
        7,
        20,
        49,
        49,
        0,
        21,
        43,
        29,
        25,
        16,
        21,
        48,
        15,
        49,
        33,
        7,
        49,
        31,
        1,
        49,
        47,
        13,
        38,
        49,
        35,
        33,
        40,
        28,
        48,
        20,
        0,
        18,
        15,
        32,
        1,
        21,
        44,
        24,
        5,
        0,
        49,
        47,
        0,
        49,
        13,
        40,
        0,
        35
      ],
      "weeklyHold": [
        39,
        0,
        36,
        39,
        32,
        13,
        18,
        9,
        14,
        35,
        47,
        13,
        1,
        1,
        25,
        33,
        20,
        13,
        0,
        48,
        46,
        49,
        49,
        21,
        49,
        48,
        17,
        19,
        49,
        49,
        48,
        0,
        0,
        12,
        24,
        26,
        49,
        42,
        27,
        0,
        12,
        49,
        0,
        43,
        49,
        49,
        0,
        44,
        14,
        23,
        14,
        46
      ],
      "weeklyBackordered": [
        33,
        48,
        15,
        32,
        45,
        30,
        19,
        42,
        44,
        28,
        39,
        49,
        8,
        49,
        49,
        1,
        19,
        38,
        0,
        28,
        49,
        48,
        13,
        0,
        15,
        13,
        11,
        32,
        46,
        0,
        35,
        49,
        48,
        38,
        22,
        0,
        26,
        0,
        49,
        49,
        44,
        48,
        0,
        30,
        30,
        10,
        25,
        13,
        42,
        33,
        43,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyAccepted": [
        18,
        2,
        24,
        48,
        14,
        24,
        1,
        19,
        14,
        11,
        16,
        26,
        49,
        10,
        41,
        32,
        49,
        21,
        0,
        49,
        49,
        28,
        42,
        47,
        0,
        47,
        15,
        18,
        1,
        4,
        20,
        0,
        41,
        32,
        48,
        43,
        28,
        44,
        16,
        49,
        18,
        19,
        49,
        37,
        49,
        2,
        16,
        49,
        17,
        13,
        26,
        19
      ],
      "weeklyRejected": [
        16,
        0,
        35,
        47,
        0,
        0,
        4,
        20,
        44,
        1,
        24,
        27,
        0,
        30,
        39,
        15,
        15,
        49,
        47,
        10,
        43,
        8,
        49,
        49,
        15,
        49,
        35,
        31,
        48,
        48,
        40,
        37,
        44,
        49,
        0,
        26,
        49,
        33,
        12,
        45,
        43,
        35,
        15,
        39,
        1,
        49,
        0,
        25,
        0,
        45,
        12,
        40
      ],
      "weeklyHold": [
        37,
        0,
        40,
        23,
        26,
        48,
        0,
        15,
        23,
        21,
        24,
        18,
        1,
        42,
        26,
        8,
        7,
        12,
        0,
        6,
        39,
        49,
        27,
        49,
        18,
        44,
        8,
        18,
        34,
        28,
        48,
        23,
        0,
        28,
        12,
        27,
        49,
        18,
        49,
        0,
        15,
        28,
        7,
        49,
        13,
        23,
        49,
        49,
        0,
        3,
        29,
        37
      ],
      "weeklyBackordered": [
        17,
        3,
        22,
        23,
        47,
        29,
        0,
        44,
        30,
        0,
        49,
        38,
        3,
        20,
        44,
        11,
        33,
        49,
        0,
        9,
        49,
        0,
        0,
        0,
        48,
        5,
        38,
        49,
        2,
        28,
        19,
        37,
        30,
        46,
        0,
        0,
        47,
        17,
        12,
        34,
        40,
        25,
        28,
        37,
        9,
        49,
        49,
        16,
        29,
        17,
        39,
        10
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        25,
        41,
        28,
        44,
        45,
        1,
        27,
        49,
        17,
        20,
        49,
        0,
        22,
        18,
        26,
        28,
        36,
        12,
        4,
        20,
        35,
        0,
        26,
        18,
        8,
        19,
        1,
        10,
        25,
        49,
        44,
        2,
        35,
        10,
        48,
        4,
        47,
        21,
        49,
        34,
        41,
        24,
        20,
        8,
        28,
        0,
        10,
        21,
        23,
        37,
        8,
        15
      ],
      "weeklyRejected": [
        37,
        0,
        49,
        9,
        47,
        13,
        49,
        30,
        29,
        28,
        6,
        0,
        27,
        48,
        36,
        33,
        18,
        34,
        28,
        30,
        38,
        32,
        49,
        49,
        12,
        12,
        49,
        2,
        48,
        17,
        44,
        1,
        48,
        49,
        27,
        49,
        23,
        47,
        1,
        11,
        30,
        16,
        23,
        8,
        49,
        20,
        20,
        38,
        0,
        21,
        25,
        32
      ],
      "weeklyHold": [
        29,
        0,
        43,
        4,
        13,
        40,
        43,
        30,
        12,
        49,
        47,
        16,
        29,
        19,
        30,
        0,
        23,
        4,
        33,
        32,
        29,
        7,
        0,
        5,
        35,
        48,
        25,
        0,
        10,
        0,
        48,
        24,
        21,
        0,
        45,
        9,
        18,
        7,
        40,
        15,
        49,
        28,
        8,
        30,
        39,
        0,
        30,
        49,
        9,
        0,
        22,
        41
      ],
      "weeklyBackordered": [
        47,
        44,
        29,
        33,
        47,
        4,
        49,
        32,
        33,
        31,
        12,
        44,
        47,
        19,
        32,
        20,
        48,
        5,
        0,
        22,
        44,
        41,
        18,
        34,
        8,
        31,
        1,
        19,
        1,
        49,
        39,
        14,
        48,
        32,
        0,
        0,
        41,
        12,
        37,
        34,
        15,
        40,
        6,
        27,
        21,
        47,
        39,
        8,
        28,
        21,
        36,
        13
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        0,
        49,
        49,
        38,
        30,
        15,
        1,
        34,
        40,
        6,
        36,
        20,
        49,
        49,
        26,
        1,
        49,
        17,
        31,
        33,
        15,
        19,
        21,
        5,
        5,
        25,
        1,
        9,
        5,
        22,
        5,
        19,
        49,
        26,
        48,
        14,
        37,
        25,
        20,
        49,
        28,
        49,
        49,
        44,
        35,
        0,
        25,
        23,
        25,
        49,
        0,
        5
      ],
      "weeklyRejected": [
        49,
        10,
        38,
        47,
        9,
        20,
        14,
        31,
        25,
        32,
        23,
        45,
        27,
        31,
        14,
        12,
        9,
        49,
        18,
        24,
        37,
        47,
        49,
        49,
        49,
        35,
        21,
        21,
        47,
        10,
        28,
        10,
        33,
        35,
        10,
        49,
        38,
        3,
        1,
        20,
        32,
        35,
        36,
        17,
        39,
        41,
        33,
        39,
        0,
        24,
        14,
        37
      ],
      "weeklyHold": [
        49,
        0,
        49,
        19,
        49,
        0,
        34,
        2,
        0,
        0,
        1,
        35,
        40,
        36,
        2,
        9,
        0,
        0,
        13,
        0,
        0,
        49,
        16,
        15,
        0,
        45,
        2,
        0,
        41,
        10,
        43,
        16,
        0,
        27,
        28,
        37,
        21,
        13,
        49,
        0,
        29,
        27,
        32,
        49,
        17,
        49,
        0,
        0,
        3,
        37,
        0,
        49
      ],
      "weeklyBackordered": [
        40,
        6,
        0,
        49,
        45,
        39,
        49,
        20,
        21,
        8,
        49,
        25,
        20,
        1,
        0,
        49,
        41,
        35,
        0,
        33,
        16,
        0,
        0,
        45,
        17,
        1,
        1,
        46,
        26,
        1,
        34,
        38,
        41,
        26,
        0,
        8,
        48,
        0,
        18,
        34,
        7,
        48,
        6,
        49,
        0,
        49,
        29,
        35,
        0,
        6,
        40,
        23
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyAccepted": [
        0,
        17,
        39,
        33,
        12,
        28,
        12,
        2,
        38,
        35,
        45,
        0,
        49,
        0,
        29,
        24,
        49,
        0,
        9,
        43,
        34,
        36,
        49,
        34,
        0,
        37,
        28,
        23,
        1,
        1,
        20,
        0,
        49,
        23,
        35,
        25,
        10,
        13,
        49,
        29,
        0,
        0,
        49,
        37,
        49,
        18,
        0,
        41,
        19,
        30,
        10,
        48
      ],
      "weeklyRejected": [
        20,
        24,
        18,
        22,
        12,
        0,
        0,
        49,
        15,
        9,
        29,
        7,
        20,
        23,
        27,
        0,
        2,
        49,
        49,
        0,
        18,
        49,
        49,
        45,
        13,
        46,
        41,
        3,
        42,
        14,
        20,
        25,
        45,
        14,
        46,
        49,
        49,
        44,
        22,
        49,
        44,
        11,
        35,
        15,
        31,
        49,
        0,
        0,
        0,
        49,
        0,
        25
      ],
      "weeklyHold": [
        10,
        23,
        32,
        15,
        24,
        25,
        32,
        22,
        0,
        13,
        39,
        14,
        29,
        1,
        49,
        37,
        0,
        42,
        37,
        35,
        34,
        45,
        13,
        14,
        31,
        1,
        16,
        48,
        31,
        33,
        44,
        1,
        0,
        47,
        0,
        49,
        20,
        24,
        1,
        44,
        12,
        48,
        12,
        24,
        21,
        13,
        22,
        49,
        0,
        40,
        49,
        49
      ],
      "weeklyBackordered": [
        13,
        0,
        48,
        19,
        33,
        43,
        37,
        22,
        18,
        3,
        49,
        49,
        49,
        40,
        32,
        14,
        28,
        41,
        18,
        0,
        19,
        33,
        22,
        23,
        39,
        28,
        34,
        1,
        15,
        35,
        43,
        48,
        48,
        34,
        13,
        8,
        49,
        8,
        18,
        18,
        39,
        48,
        0,
        49,
        21,
        24,
        42,
        5,
        24,
        23,
        15,
        41
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        2,
        49,
        18,
        36,
        30,
        5,
        15,
        44,
        23,
        18,
        49,
        0,
        24,
        49,
        15,
        35,
        49,
        22,
        0,
        21,
        15,
        6,
        14,
        49,
        23,
        14,
        49,
        10,
        5,
        49,
        15,
        0,
        7,
        49,
        48,
        24,
        5,
        21,
        27,
        43,
        49,
        27,
        40,
        0,
        0,
        33,
        0,
        31,
        34,
        1,
        0,
        45
      ],
      "weeklyRejected": [
        34,
        0,
        49,
        5,
        31,
        20,
        49,
        49,
        30,
        13,
        22,
        30,
        22,
        0,
        48,
        0,
        44,
        38,
        26,
        46,
        22,
        0,
        31,
        49,
        35,
        49,
        27,
        29,
        48,
        7,
        42,
        34,
        48,
        2,
        23,
        10,
        19,
        35,
        26,
        0,
        45,
        13,
        10,
        0,
        49,
        23,
        0,
        49,
        0,
        36,
        15,
        26
      ],
      "weeklyHold": [
        11,
        48,
        49,
        26,
        49,
        34,
        35,
        5,
        36,
        31,
        44,
        44,
        26,
        13,
        35,
        11,
        10,
        28,
        15,
        8,
        36,
        36,
        34,
        0,
        43,
        35,
        22,
        24,
        16,
        0,
        48,
        0,
        0,
        23,
        23,
        1,
        43,
        0,
        42,
        35,
        32,
        49,
        0,
        24,
        12,
        49,
        0,
        43,
        0,
        25,
        24,
        26
      ],
      "weeklyBackordered": [
        17,
        9,
        0,
        7,
        49,
        46,
        38,
        43,
        16,
        19,
        20,
        17,
        3,
        49,
        39,
        36,
        35,
        3,
        14,
        31,
        45,
        42,
        22,
        22,
        14,
        24,
        22,
        31,
        11,
        49,
        25,
        37,
        6,
        49,
        0,
        9,
        27,
        17,
        46,
        26,
        32,
        48,
        2,
        49,
        32,
        35,
        34,
        20,
        0,
        35,
        32,
        42
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        0,
        39,
        49,
        33,
        24,
        0,
        38,
        39,
        48,
        31,
        47,
        8,
        11,
        22,
        45,
        28,
        43,
        0,
        19,
        32,
        31,
        10,
        35,
        23,
        0,
        35,
        49,
        22,
        43,
        6,
        5,
        25,
        43,
        0,
        48,
        42,
        47,
        17,
        30,
        49,
        12,
        0,
        5,
        21,
        33,
        7,
        21,
        7,
        19,
        39,
        21,
        0
      ],
      "weeklyRejected": [
        42,
        22,
        3,
        24,
        0,
        43,
        49,
        39,
        48,
        12,
        1,
        46,
        32,
        48,
        27,
        16,
        35,
        49,
        33,
        5,
        49,
        22,
        24,
        43,
        32,
        39,
        18,
        19,
        14,
        5,
        39,
        43,
        14,
        49,
        0,
        31,
        47,
        35,
        1,
        21,
        34,
        25,
        49,
        28,
        36,
        22,
        15,
        37,
        0,
        41,
        25,
        47
      ],
      "weeklyHold": [
        38,
        27,
        49,
        25,
        49,
        46,
        26,
        16,
        0,
        30,
        28,
        1,
        24,
        1,
        26,
        15,
        7,
        48,
        32,
        0,
        19,
        17,
        15,
        9,
        41,
        34,
        13,
        4,
        31,
        0,
        36,
        0,
        14,
        1,
        37,
        21,
        25,
        0,
        41,
        34,
        26,
        26,
        19,
        29,
        31,
        27,
        1,
        15,
        0,
        24,
        49,
        37
      ],
      "weeklyBackordered": [
        44,
        15,
        6,
        14,
        49,
        25,
        49,
        16,
        49,
        14,
        37,
        49,
        36,
        42,
        47,
        5,
        49,
        49,
        4,
        18,
        1,
        32,
        36,
        27,
        37,
        31,
        43,
        0,
        1,
        45,
        26,
        0,
        39,
        47,
        0,
        0,
        15,
        5,
        33,
        8,
        14,
        42,
        35,
        48,
        0,
        0,
        33,
        14,
        11,
        49,
        19,
        1
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        12,
        49,
        34,
        21,
        9,
        20,
        18,
        43,
        21,
        49,
        34,
        17,
        19,
        44,
        1,
        17,
        49,
        27,
        7,
        30,
        42,
        39,
        38,
        17,
        23,
        0,
        0,
        41,
        20,
        10,
        16,
        39,
        44,
        0,
        48,
        45,
        47,
        18,
        30,
        49,
        9,
        14,
        49,
        22,
        41,
        40,
        15,
        49,
        49,
        43,
        49,
        18
      ],
      "weeklyRejected": [
        43,
        0,
        32,
        38,
        38,
        28,
        48,
        6,
        38,
        1,
        29,
        45,
        42,
        37,
        33,
        49,
        34,
        21,
        22,
        31,
        0,
        36,
        0,
        23,
        27,
        16,
        17,
        18,
        0,
        2,
        19,
        30,
        37,
        15,
        7,
        49,
        37,
        0,
        1,
        33,
        12,
        21,
        41,
        0,
        48,
        17,
        0,
        30,
        0,
        16,
        20,
        49
      ],
      "weeklyHold": [
        0,
        32,
        16,
        1,
        49,
        46,
        49,
        12,
        25,
        30,
        6,
        39,
        48,
        2,
        38,
        21,
        18,
        20,
        43,
        49,
        0,
        0,
        24,
        23,
        24,
        48,
        27,
        41,
        30,
        40,
        48,
        12,
        15,
        16,
        19,
        38,
        49,
        9,
        32,
        11,
        15,
        34,
        12,
        49,
        39,
        49,
        33,
        34,
        9,
        49,
        39,
        24
      ],
      "weeklyBackordered": [
        49,
        8,
        6,
        33,
        19,
        35,
        42,
        45,
        41,
        0,
        18,
        44,
        28,
        29,
        9,
        49,
        17,
        39,
        0,
        37,
        11,
        0,
        12,
        49,
        1,
        26,
        29,
        7,
        1,
        46,
        11,
        49,
        14,
        31,
        0,
        18,
        25,
        1,
        9,
        4,
        33,
        48,
        7,
        49,
        39,
        49,
        31,
        34,
        8,
        28,
        49,
        3
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        4,
        39,
        30,
        10,
        11,
        0,
        39,
        49,
        8,
        49,
        38,
        48,
        38,
        39,
        35,
        49,
        31,
        0,
        19,
        33,
        8,
        47,
        0,
        49,
        29,
        9,
        0,
        25,
        1,
        49,
        8,
        4,
        48,
        8,
        24,
        16,
        27,
        28,
        49,
        48,
        28,
        15,
        49,
        16,
        45,
        11,
        0,
        22,
        18,
        33,
        49,
        48
      ],
      "weeklyRejected": [
        43,
        0,
        49,
        21,
        34,
        15,
        17,
        5,
        47,
        38,
        49,
        0,
        49,
        48,
        37,
        17,
        25,
        19,
        35,
        9,
        40,
        48,
        21,
        44,
        25,
        37,
        31,
        20,
        9,
        32,
        0,
        23,
        48,
        23,
        34,
        49,
        49,
        27,
        1,
        49,
        32,
        45,
        32,
        9,
        40,
        0,
        31,
        45,
        8,
        3,
        0,
        0
      ],
      "weeklyHold": [
        0,
        49,
        49,
        2,
        49,
        4,
        43,
        18,
        47,
        13,
        14,
        29,
        1,
        35,
        5,
        49,
        25,
        6,
        18,
        45,
        9,
        18,
        49,
        0,
        45,
        48,
        26,
        0,
        37,
        11,
        48,
        0,
        0,
        0,
        4,
        49,
        49,
        0,
        49,
        3,
        0,
        49,
        16,
        49,
        27,
        21,
        30,
        14,
        9,
        39,
        35,
        41
      ],
      "weeklyBackordered": [
        40,
        42,
        0,
        34,
        49,
        39,
        49,
        26,
        49,
        29,
        8,
        34,
        3,
        16,
        45,
        12,
        32,
        30,
        0,
        9,
        22,
        3,
        0,
        15,
        48,
        9,
        1,
        15,
        34,
        31,
        21,
        49,
        48,
        49,
        0,
        14,
        42,
        25,
        49,
        36,
        29,
        30,
        13,
        49,
        0,
        22,
        9,
        33,
        16,
        23,
        12,
        16
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        23,
        10,
        16,
        13,
        40,
        0,
        49,
        28,
        35,
        32,
        26,
        19,
        49,
        20,
        9,
        34,
        49,
        0,
        0,
        49,
        13,
        49,
        46,
        0,
        24,
        47,
        0,
        38,
        31,
        48,
        0,
        0,
        19,
        30,
        31,
        32,
        19,
        16,
        39,
        37,
        18,
        32,
        49,
        35,
        49,
        0,
        5,
        34,
        0,
        29,
        31,
        14
      ],
      "weeklyRejected": [
        49,
        30,
        49,
        27,
        23,
        19,
        49,
        39,
        39,
        10,
        34,
        7,
        33,
        46,
        33,
        28,
        16,
        49,
        35,
        21,
        7,
        27,
        49,
        49,
        35,
        32,
        33,
        49,
        48,
        22,
        19,
        23,
        11,
        9,
        42,
        41,
        23,
        19,
        9,
        49,
        23,
        8,
        23,
        6,
        19,
        42,
        0,
        15,
        0,
        48,
        26,
        49
      ],
      "weeklyHold": [
        26,
        0,
        49,
        32,
        21,
        21,
        49,
        2,
        5,
        12,
        14,
        20,
        16,
        49,
        25,
        21,
        0,
        0,
        31,
        2,
        20,
        38,
        49,
        14,
        30,
        43,
        46,
        36,
        38,
        25,
        48,
        0,
        7,
        27,
        6,
        49,
        41,
        0,
        27,
        49,
        49,
        49,
        0,
        13,
        34,
        18,
        47,
        47,
        0,
        49,
        37,
        35
      ],
      "weeklyBackordered": [
        0,
        17,
        13,
        12,
        41,
        36,
        37,
        30,
        9,
        18,
        21,
        45,
        26,
        21,
        30,
        32,
        26,
        49,
        0,
        9,
        20,
        0,
        38,
        30,
        30,
        22,
        20,
        40,
        36,
        44,
        48,
        3,
        39,
        43,
        44,
        17,
        25,
        5,
        49,
        25,
        43,
        47,
        0,
        20,
        26,
        35,
        0,
        32,
        34,
        49,
        29,
        23
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        23,
        49,
        8,
        20,
        41,
        36,
        7,
        18,
        48,
        0,
        49,
        0,
        15,
        13,
        1,
        49,
        28,
        11,
        40,
        48,
        0,
        46,
        15,
        49,
        49,
        39,
        0,
        34,
        16,
        32,
        36,
        38,
        41,
        24,
        48,
        22,
        40,
        44,
        0,
        41,
        44,
        47,
        49,
        49,
        27,
        16,
        23,
        26,
        10,
        37,
        7,
        3
      ],
      "weeklyRejected": [
        49,
        0,
        36,
        40,
        2,
        0,
        47,
        33,
        25,
        5,
        41,
        0,
        24,
        48,
        36,
        19,
        19,
        49,
        16,
        49,
        44,
        32,
        11,
        9,
        49,
        49,
        40,
        0,
        0,
        48,
        46,
        41,
        48,
        17,
        39,
        49,
        21,
        0,
        7,
        20,
        40,
        49,
        43,
        0,
        33,
        35,
        0,
        49,
        13,
        49,
        0,
        36
      ],
      "weeklyHold": [
        25,
        32,
        46,
        27,
        42,
        0,
        0,
        35,
        10,
        0,
        17,
        13,
        1,
        32,
        49,
        19,
        2,
        25,
        40,
        0,
        4,
        28,
        16,
        39,
        18,
        37,
        6,
        29,
        37,
        13,
        48,
        11,
        0,
        49,
        27,
        49,
        45,
        11,
        28,
        41,
        20,
        18,
        6,
        47,
        26,
        0,
        37,
        49,
        1,
        26,
        30,
        49
      ],
      "weeklyBackordered": [
        27,
        21,
        42,
        49,
        49,
        8,
        31,
        2,
        11,
        21,
        7,
        45,
        5,
        32,
        49,
        2,
        41,
        37,
        49,
        0,
        11,
        18,
        41,
        20,
        4,
        12,
        37,
        46,
        13,
        31,
        14,
        20,
        26,
        49,
        25,
        12,
        27,
        24,
        11,
        26,
        28,
        34,
        0,
        23,
        0,
        15,
        49,
        0,
        0,
        49,
        25,
        46
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        31,
        49,
        49,
        41,
        1,
        24,
        28,
        49,
        48,
        37,
        43,
        21,
        31,
        5,
        16,
        18,
        26,
        48,
        0,
        0,
        34,
        49,
        18,
        35,
        23,
        43,
        34,
        49,
        12,
        49,
        49,
        48,
        2,
        0,
        48,
        25,
        29,
        27,
        49,
        24,
        0,
        23,
        49,
        20,
        49,
        31,
        0,
        33,
        23,
        46,
        44,
        48
      ],
      "weeklyRejected": [
        49,
        2,
        49,
        47,
        7,
        12,
        25,
        3,
        49,
        31,
        48,
        19,
        19,
        38,
        44,
        20,
        30,
        37,
        30,
        28,
        21,
        24,
        0,
        49,
        6,
        49,
        36,
        23,
        42,
        0,
        19,
        47,
        48,
        49,
        19,
        49,
        41,
        12,
        3,
        8,
        48,
        24,
        49,
        40,
        45,
        19,
        22,
        38,
        0,
        42,
        0,
        49
      ],
      "weeklyHold": [
        47,
        6,
        13,
        35,
        14,
        43,
        0,
        33,
        10,
        20,
        30,
        41,
        48,
        28,
        22,
        13,
        16,
        35,
        30,
        40,
        15,
        25,
        11,
        12,
        3,
        37,
        14,
        1,
        11,
        24,
        25,
        48,
        10,
        25,
        9,
        8,
        6,
        11,
        31,
        4,
        42,
        23,
        0,
        27,
        49,
        5,
        42,
        49,
        2,
        38,
        35,
        18
      ],
      "weeklyBackordered": [
        22,
        28,
        2,
        20,
        32,
        41,
        31,
        47,
        47,
        0,
        31,
        33,
        11,
        12,
        38,
        43,
        49,
        49,
        0,
        3,
        5,
        0,
        9,
        14,
        19,
        38,
        10,
        25,
        32,
        49,
        29,
        23,
        19,
        40,
        0,
        21,
        31,
        43,
        9,
        33,
        22,
        12,
        5,
        10,
        49,
        0,
        49,
        28,
        37,
        49,
        5,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        12,
        24,
        22,
        45,
        28,
        10,
        7,
        19,
        0,
        19,
        11,
        0,
        44,
        24,
        19,
        40,
        49,
        5,
        4,
        5,
        9,
        12,
        35,
        17,
        25,
        25,
        46,
        18,
        8,
        15,
        28,
        0,
        49,
        19,
        11,
        22,
        30,
        31,
        44,
        49,
        0,
        16,
        49,
        37,
        48,
        18,
        0,
        45,
        0,
        49,
        26,
        31
      ],
      "weeklyRejected": [
        49,
        0,
        37,
        41,
        21,
        49,
        34,
        28,
        45,
        32,
        21,
        38,
        24,
        39,
        43,
        20,
        38,
        49,
        47,
        49,
        0,
        27,
        26,
        34,
        24,
        26,
        35,
        31,
        44,
        23,
        25,
        35,
        0,
        49,
        34,
        41,
        49,
        49,
        1,
        1,
        39,
        31,
        45,
        23,
        49,
        13,
        0,
        6,
        0,
        23,
        10,
        0
      ],
      "weeklyHold": [
        39,
        42,
        49,
        16,
        49,
        35,
        5,
        11,
        2,
        32,
        47,
        44,
        29,
        49,
        0,
        5,
        7,
        27,
        41,
        30,
        26,
        36,
        0,
        7,
        5,
        37,
        2,
        31,
        49,
        4,
        35,
        21,
        26,
        49,
        40,
        27,
        14,
        2,
        15,
        20,
        45,
        49,
        8,
        46,
        49,
        38,
        49,
        27,
        0,
        42,
        49,
        10
      ],
      "weeklyBackordered": [
        49,
        49,
        16,
        14,
        49,
        32,
        49,
        1,
        34,
        14,
        30,
        49,
        22,
        21,
        11,
        36,
        49,
        33,
        36,
        36,
        47,
        25,
        7,
        28,
        8,
        35,
        1,
        49,
        38,
        49,
        0,
        49,
        18,
        4,
        6,
        0,
        49,
        0,
        25,
        21,
        30,
        48,
        0,
        41,
        0,
        38,
        36,
        29,
        0,
        32,
        49,
        1
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        21,
        6,
        13,
        14,
        1,
        35,
        4,
        27,
        43,
        23,
        9,
        0,
        49,
        17,
        14,
        0,
        21,
        1,
        0,
        31,
        1,
        36,
        28,
        29,
        44,
        33,
        20,
        34,
        46,
        24,
        36,
        23,
        49,
        33,
        35,
        49,
        21,
        9,
        49,
        49,
        12,
        29,
        18,
        43,
        35,
        0,
        16,
        26,
        1,
        49,
        24,
        28
      ],
      "weeklyRejected": [
        49,
        20,
        2,
        27,
        48,
        0,
        18,
        0,
        38,
        10,
        13,
        0,
        0,
        48,
        32,
        13,
        49,
        49,
        30,
        6,
        9,
        49,
        37,
        49,
        38,
        49,
        14,
        3,
        48,
        25,
        31,
        26,
        29,
        22,
        14,
        47,
        30,
        23,
        1,
        0,
        4,
        13,
        20,
        49,
        45,
        28,
        0,
        0,
        36,
        40,
        0,
        49
      ],
      "weeklyHold": [
        4,
        3,
        43,
        2,
        2,
        45,
        44,
        5,
        24,
        13,
        14,
        34,
        30,
        31,
        43,
        0,
        10,
        0,
        0,
        7,
        0,
        49,
        5,
        49,
        2,
        1,
        49,
        3,
        49,
        14,
        48,
        48,
        26,
        35,
        0,
        31,
        18,
        5,
        49,
        44,
        49,
        49,
        0,
        27,
        47,
        0,
        20,
        31,
        5,
        0,
        49,
        13
      ],
      "weeklyBackordered": [
        9,
        22,
        14,
        44,
        31,
        26,
        16,
        16,
        0,
        49,
        49,
        49,
        22,
        49,
        44,
        49,
        16,
        49,
        13,
        24,
        27,
        16,
        0,
        5,
        22,
        15,
        11,
        0,
        42,
        3,
        0,
        34,
        29,
        37,
        0,
        0,
        41,
        0,
        0,
        0,
        18,
        41,
        4,
        45,
        0,
        12,
        49,
        35,
        25,
        38,
        48,
        25
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        22,
        49,
        19,
        40,
        18,
        27,
        21,
        2,
        1,
        22,
        49,
        7,
        23,
        12,
        27,
        41,
        49,
        28,
        0,
        36,
        20,
        49,
        32,
        48,
        48,
        47,
        30,
        18,
        31,
        49,
        41,
        0,
        16,
        27,
        20,
        48,
        46,
        48,
        44,
        22,
        15,
        22,
        21,
        13,
        27,
        7,
        0,
        49,
        49,
        45,
        0,
        48
      ],
      "weeklyRejected": [
        48,
        10,
        47,
        47,
        10,
        5,
        29,
        18,
        43,
        1,
        38,
        0,
        13,
        42,
        48,
        21,
        45,
        49,
        19,
        33,
        25,
        30,
        44,
        15,
        0,
        49,
        30,
        4,
        43,
        10,
        48,
        47,
        48,
        13,
        15,
        49,
        0,
        44,
        1,
        49,
        48,
        34,
        15,
        0,
        36,
        24,
        30,
        21,
        3,
        38,
        0,
        22
      ],
      "weeklyHold": [
        42,
        29,
        49,
        18,
        39,
        48,
        0,
        17,
        48,
        29,
        18,
        9,
        13,
        38,
        49,
        8,
        11,
        20,
        39,
        18,
        24,
        22,
        33,
        36,
        43,
        48,
        4,
        0,
        43,
        48,
        48,
        0,
        0,
        41,
        0,
        30,
        11,
        11,
        37,
        30,
        12,
        30,
        6,
        0,
        0,
        18,
        49,
        26,
        3,
        24,
        14,
        38
      ],
      "weeklyBackordered": [
        20,
        49,
        0,
        37,
        34,
        36,
        5,
        38,
        35,
        7,
        8,
        19,
        33,
        20,
        35,
        1,
        6,
        0,
        33,
        20,
        9,
        19,
        15,
        12,
        47,
        10,
        15,
        24,
        27,
        46,
        20,
        14,
        16,
        49,
        1,
        16,
        26,
        28,
        37,
        30,
        38,
        29,
        33,
        49,
        28,
        21,
        49,
        6,
        0,
        31,
        49,
        31
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        24,
        3,
        6,
        48,
        4,
        12,
        9,
        18,
        48,
        15,
        49,
        0,
        24,
        40,
        25,
        27,
        33,
        27,
        49,
        49,
        15,
        35,
        36,
        49,
        3,
        47,
        49,
        4,
        38,
        49,
        5,
        12,
        49,
        19,
        19,
        49,
        36,
        16,
        42,
        49,
        37,
        36,
        49,
        27,
        49,
        38,
        0,
        14,
        49,
        49,
        17,
        31
      ],
      "weeklyRejected": [
        49,
        49,
        46,
        21,
        27,
        0,
        49,
        18,
        0,
        10,
        0,
        19,
        33,
        35,
        48,
        26,
        5,
        49,
        39,
        21,
        9,
        0,
        9,
        49,
        22,
        49,
        10,
        12,
        29,
        30,
        49,
        47,
        48,
        30,
        0,
        39,
        26,
        1,
        1,
        0,
        16,
        45,
        44,
        48,
        49,
        49,
        35,
        46,
        10,
        49,
        0,
        10
      ],
      "weeklyHold": [
        27,
        33,
        32,
        1,
        49,
        37,
        25,
        25,
        0,
        49,
        13,
        16,
        37,
        38,
        49,
        0,
        13,
        43,
        19,
        4,
        43,
        7,
        13,
        20,
        22,
        19,
        0,
        29,
        37,
        0,
        38,
        0,
        23,
        13,
        48,
        32,
        49,
        4,
        19,
        49,
        26,
        24,
        5,
        0,
        28,
        33,
        49,
        49,
        40,
        47,
        49,
        0
      ],
      "weeklyBackordered": [
        21,
        26,
        0,
        0,
        49,
        38,
        0,
        49,
        14,
        2,
        41,
        12,
        3,
        49,
        25,
        21,
        40,
        49,
        0,
        7,
        36,
        49,
        5,
        46,
        1,
        27,
        10,
        49,
        12,
        47,
        20,
        12,
        0,
        49,
        6,
        0,
        3,
        11,
        49,
        30,
        43,
        48,
        47,
        43,
        37,
        6,
        37,
        2,
        35,
        28,
        45,
        49
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        21,
        28,
        26,
        34,
        21,
        3,
        1,
        40,
        41,
        49,
        28,
        10,
        13,
        49,
        31,
        28,
        30,
        10,
        0,
        25,
        28,
        49,
        37,
        29,
        31,
        44,
        32,
        49,
        49,
        25,
        13,
        33,
        38,
        41,
        48,
        49,
        28,
        0,
        0,
        49,
        0,
        21,
        49,
        38,
        49,
        5,
        42,
        29,
        9,
        49,
        38,
        43
      ],
      "weeklyRejected": [
        30,
        23,
        29,
        32,
        13,
        21,
        41,
        28,
        49,
        16,
        33,
        44,
        49,
        48,
        23,
        0,
        26,
        38,
        49,
        9,
        23,
        14,
        31,
        49,
        15,
        29,
        33,
        28,
        44,
        48,
        34,
        47,
        12,
        26,
        32,
        49,
        49,
        11,
        18,
        14,
        44,
        49,
        20,
        48,
        24,
        32,
        18,
        41,
        0,
        26,
        12,
        43
      ],
      "weeklyHold": [
        45,
        0,
        23,
        27,
        49,
        26,
        21,
        31,
        30,
        49,
        13,
        34,
        2,
        15,
        23,
        15,
        12,
        18,
        0,
        6,
        33,
        29,
        0,
        34,
        0,
        48,
        0,
        0,
        49,
        49,
        48,
        0,
        38,
        18,
        11,
        10,
        18,
        7,
        1,
        49,
        22,
        32,
        2,
        37,
        43,
        39,
        39,
        46,
        0,
        0,
        22,
        23
      ],
      "weeklyBackordered": [
        7,
        0,
        23,
        16,
        35,
        28,
        30,
        49,
        38,
        36,
        49,
        3,
        29,
        13,
        33,
        44,
        31,
        35,
        0,
        20,
        34,
        19,
        23,
        49,
        17,
        49,
        20,
        18,
        11,
        49,
        14,
        38,
        30,
        20,
        14,
        19,
        4,
        0,
        0,
        19,
        23,
        24,
        0,
        49,
        31,
        26,
        45,
        33,
        0,
        4,
        30,
        1
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        0,
        1,
        31,
        6,
        11,
        1,
        49,
        40,
        34,
        1,
        28,
        10,
        37,
        0,
        37,
        49,
        23,
        18,
        0,
        19,
        24,
        30,
        37,
        49,
        22,
        36,
        43,
        49,
        1,
        20,
        0,
        1,
        43,
        47,
        40,
        19,
        36,
        0,
        32,
        31,
        21,
        45,
        49,
        41,
        38,
        28,
        0,
        18,
        11,
        17,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        17,
        13,
        12,
        8,
        45,
        39,
        19,
        5,
        20,
        19,
        41,
        2,
        48,
        48,
        13,
        0,
        49,
        48,
        13,
        35,
        33,
        49,
        28,
        37,
        48,
        29,
        0,
        27,
        15,
        17,
        47,
        48,
        34,
        32,
        30,
        32,
        23,
        4,
        40,
        8,
        49,
        42,
        27,
        49,
        41,
        5,
        0,
        0,
        0,
        33,
        29
      ],
      "weeklyHold": [
        0,
        0,
        34,
        17,
        35,
        0,
        35,
        30,
        11,
        0,
        39,
        10,
        12,
        27,
        49,
        40,
        20,
        21,
        32,
        19,
        25,
        21,
        49,
        5,
        17,
        30,
        29,
        9,
        34,
        47,
        44,
        1,
        29,
        17,
        16,
        20,
        35,
        13,
        1,
        49,
        17,
        36,
        26,
        43,
        25,
        6,
        0,
        49,
        0,
        43,
        19,
        21
      ],
      "weeklyBackordered": [
        15,
        29,
        0,
        0,
        38,
        46,
        49,
        41,
        28,
        13,
        26,
        49,
        3,
        41,
        41,
        2,
        22,
        24,
        22,
        23,
        42,
        21,
        23,
        49,
        12,
        28,
        21,
        31,
        1,
        41,
        9,
        42,
        2,
        25,
        0,
        0,
        1,
        30,
        49,
        49,
        35,
        48,
        7,
        31,
        22,
        0,
        13,
        24,
        43,
        0,
        1,
        23
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        0,
        46,
        24,
        35,
        2,
        27,
        22,
        25,
        48,
        30,
        20,
        0,
        11,
        11,
        1,
        22,
        49,
        23,
        17,
        13,
        44,
        13,
        30,
        22,
        0,
        47,
        0,
        34,
        6,
        32,
        49,
        29,
        38,
        45,
        38,
        2,
        36,
        48,
        25,
        49,
        12,
        3,
        49,
        28,
        49,
        0,
        35,
        14,
        15,
        49,
        49,
        25
      ],
      "weeklyRejected": [
        23,
        2,
        10,
        35,
        38,
        40,
        49,
        34,
        47,
        12,
        42,
        22,
        22,
        48,
        40,
        15,
        0,
        39,
        49,
        26,
        31,
        32,
        8,
        44,
        15,
        49,
        42,
        22,
        48,
        1,
        34,
        47,
        47,
        1,
        0,
        46,
        10,
        17,
        4,
        20,
        35,
        49,
        31,
        9,
        49,
        20,
        19,
        0,
        26,
        46,
        19,
        45
      ],
      "weeklyHold": [
        36,
        0,
        34,
        25,
        0,
        15,
        2,
        17,
        22,
        37,
        47,
        34,
        25,
        35,
        44,
        17,
        3,
        12,
        0,
        45,
        30,
        15,
        15,
        28,
        49,
        31,
        0,
        17,
        41,
        4,
        48,
        48,
        0,
        27,
        0,
        47,
        38,
        0,
        41,
        16,
        19,
        2,
        0,
        49,
        7,
        28,
        32,
        23,
        0,
        49,
        10,
        27
      ],
      "weeklyBackordered": [
        42,
        46,
        23,
        47,
        47,
        36,
        0,
        49,
        10,
        20,
        8,
        19,
        21,
        49,
        22,
        49,
        16,
        44,
        20,
        11,
        49,
        0,
        11,
        49,
        15,
        31,
        23,
        23,
        44,
        13,
        11,
        16,
        33,
        26,
        2,
        23,
        49,
        8,
        0,
        24,
        26,
        10,
        19,
        33,
        25,
        31,
        28,
        19,
        47,
        49,
        19,
        32
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        32,
        1,
        0,
        3,
        10,
        22,
        13,
        46,
        48,
        12,
        37,
        11,
        12,
        29,
        1,
        21,
        16,
        12,
        19,
        0,
        0,
        44,
        42,
        19,
        35,
        0,
        29,
        49,
        19,
        19,
        18,
        12,
        43,
        16,
        32,
        27,
        39,
        31,
        34,
        33,
        3,
        0,
        49,
        40,
        10,
        17,
        27,
        10,
        5,
        38,
        49,
        6
      ],
      "weeklyRejected": [
        49,
        1,
        29,
        22,
        10,
        34,
        49,
        19,
        41,
        35,
        17,
        0,
        8,
        48,
        43,
        19,
        0,
        41,
        46,
        7,
        27,
        0,
        43,
        21,
        21,
        49,
        49,
        29,
        48,
        40,
        9,
        29,
        48,
        20,
        0,
        49,
        10,
        49,
        6,
        29,
        29,
        13,
        49,
        0,
        33,
        33,
        17,
        6,
        34,
        19,
        0,
        49
      ],
      "weeklyHold": [
        16,
        10,
        16,
        26,
        5,
        21,
        47,
        13,
        0,
        8,
        47,
        16,
        31,
        19,
        36,
        22,
        0,
        15,
        0,
        14,
        0,
        29,
        37,
        49,
        34,
        18,
        0,
        22,
        42,
        0,
        19,
        36,
        13,
        36,
        0,
        38,
        23,
        1,
        36,
        47,
        29,
        39,
        26,
        25,
        19,
        41,
        22,
        32,
        8,
        49,
        46,
        31
      ],
      "weeklyBackordered": [
        49,
        19,
        25,
        39,
        40,
        40,
        47,
        33,
        36,
        6,
        23,
        25,
        3,
        29,
        36,
        47,
        49,
        24,
        0,
        49,
        23,
        39,
        24,
        15,
        48,
        19,
        45,
        41,
        25,
        46,
        5,
        0,
        20,
        34,
        7,
        41,
        1,
        14,
        49,
        26,
        26,
        24,
        2,
        49,
        49,
        1,
        23,
        7,
        1,
        43,
        32,
        36
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        0,
        24,
        27,
        34,
        2,
        16,
        13,
        32,
        38,
        19,
        9,
        15,
        36,
        49,
        32,
        24,
        25,
        30,
        13,
        23,
        19,
        38,
        22,
        20,
        49,
        45,
        15,
        0,
        49,
        38,
        29,
        31,
        5,
        26,
        48,
        2,
        28,
        48,
        19,
        49,
        20,
        21,
        49,
        49,
        34,
        41,
        0,
        22,
        0,
        39,
        24,
        48
      ],
      "weeklyRejected": [
        49,
        3,
        22,
        0,
        20,
        12,
        2,
        49,
        49,
        6,
        19,
        14,
        49,
        44,
        38,
        1,
        26,
        31,
        49,
        34,
        49,
        2,
        49,
        21,
        33,
        27,
        13,
        0,
        48,
        8,
        27,
        47,
        46,
        17,
        33,
        42,
        49,
        32,
        2,
        30,
        31,
        45,
        18,
        0,
        45,
        49,
        0,
        27,
        0,
        49,
        29,
        46
      ],
      "weeklyHold": [
        49,
        0,
        33,
        17,
        35,
        34,
        34,
        7,
        38,
        49,
        34,
        1,
        36,
        19,
        38,
        36,
        2,
        42,
        19,
        16,
        21,
        0,
        45,
        10,
        41,
        48,
        46,
        39,
        49,
        0,
        21,
        18,
        48,
        9,
        44,
        31,
        49,
        0,
        36,
        0,
        36,
        16,
        4,
        49,
        26,
        24,
        49,
        49,
        16,
        0,
        49,
        11
      ],
      "weeklyBackordered": [
        19,
        0,
        7,
        30,
        49,
        26,
        30,
        49,
        39,
        0,
        42,
        0,
        49,
        2,
        22,
        8,
        3,
        35,
        0,
        20,
        29,
        20,
        0,
        19,
        48,
        23,
        43,
        20,
        9,
        10,
        38,
        15,
        39,
        27,
        14,
        0,
        17,
        15,
        0,
        28,
        36,
        48,
        14,
        20,
        45,
        4,
        49,
        10,
        47,
        49,
        49,
        42
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        28,
        19,
        25,
        24,
        30,
        4,
        42,
        38,
        42,
        2,
        10,
        2,
        25,
        16,
        12,
        22,
        32,
        32,
        11,
        23,
        11,
        49,
        49,
        49,
        18,
        25,
        0,
        49,
        1,
        32,
        14,
        49,
        29,
        47,
        31,
        39,
        40,
        48,
        27,
        40,
        34,
        28,
        44,
        24,
        38,
        16,
        0,
        35,
        35,
        27,
        1,
        23
      ],
      "weeklyRejected": [
        49,
        1,
        49,
        0,
        22,
        0,
        39,
        0,
        29,
        35,
        35,
        0,
        41,
        48,
        18,
        29,
        49,
        0,
        35,
        49,
        49,
        32,
        49,
        0,
        33,
        49,
        46,
        33,
        34,
        24,
        0,
        47,
        32,
        34,
        38,
        49,
        11,
        10,
        1,
        10,
        48,
        34,
        42,
        1,
        37,
        0,
        12,
        49,
        6,
        15,
        2,
        49
      ],
      "weeklyHold": [
        21,
        20,
        39,
        15,
        16,
        13,
        16,
        27,
        12,
        39,
        1,
        49,
        1,
        39,
        3,
        27,
        9,
        37,
        32,
        13,
        9,
        15,
        37,
        27,
        6,
        39,
        23,
        9,
        35,
        23,
        41,
        0,
        0,
        42,
        17,
        8,
        15,
        30,
        39,
        26,
        11,
        49,
        0,
        41,
        29,
        4,
        0,
        43,
        0,
        27,
        35,
        8
      ],
      "weeklyBackordered": [
        49,
        42,
        17,
        39,
        34,
        18,
        31,
        34,
        45,
        3,
        28,
        27,
        19,
        36,
        29,
        34,
        41,
        36,
        5,
        45,
        0,
        0,
        0,
        38,
        21,
        39,
        25,
        49,
        15,
        20,
        18,
        31,
        37,
        49,
        5,
        40,
        11,
        36,
        13,
        28,
        0,
        47,
        12,
        49,
        42,
        34,
        2,
        15,
        48,
        22,
        40,
        49
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        34,
        26,
        49,
        19,
        46,
        5,
        17,
        31,
        48,
        0,
        38,
        17,
        31,
        22,
        42,
        25,
        36,
        15,
        27,
        19,
        14,
        13,
        28,
        27,
        43,
        47,
        0,
        49,
        12,
        49,
        39,
        14,
        35,
        46,
        48,
        49,
        37,
        48,
        23,
        49,
        6,
        43,
        49,
        17,
        16,
        20,
        0,
        34,
        9,
        49,
        21,
        2
      ],
      "weeklyRejected": [
        38,
        7,
        31,
        47,
        49,
        26,
        40,
        17,
        33,
        44,
        37,
        35,
        34,
        33,
        28,
        0,
        36,
        49,
        25,
        15,
        21,
        4,
        31,
        7,
        28,
        49,
        45,
        11,
        32,
        38,
        0,
        15,
        44,
        15,
        0,
        32,
        29,
        12,
        2,
        8,
        48,
        49,
        27,
        15,
        47,
        8,
        15,
        33,
        0,
        48,
        0,
        43
      ],
      "weeklyHold": [
        15,
        26,
        49,
        17,
        0,
        22,
        35,
        2,
        36,
        21,
        47,
        41,
        1,
        22,
        49,
        0,
        21,
        38,
        24,
        6,
        9,
        0,
        22,
        11,
        18,
        1,
        15,
        49,
        49,
        0,
        47,
        0,
        37,
        49,
        20,
        26,
        0,
        0,
        49,
        34,
        22,
        49,
        34,
        45,
        10,
        39,
        16,
        2,
        16,
        26,
        15,
        10
      ],
      "weeklyBackordered": [
        22,
        49,
        0,
        49,
        49,
        39,
        13,
        30,
        0,
        9,
        33,
        0,
        21,
        0,
        39,
        42,
        1,
        24,
        0,
        28,
        0,
        27,
        46,
        0,
        40,
        22,
        20,
        49,
        1,
        49,
        24,
        3,
        48,
        28,
        0,
        0,
        49,
        0,
        49,
        17,
        6,
        48,
        20,
        49,
        35,
        49,
        33,
        18,
        0,
        49,
        32,
        41
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        27,
        49,
        18,
        48,
        28,
        6,
        11,
        49,
        48,
        23,
        47,
        26,
        17,
        49,
        1,
        0,
        49,
        0,
        0,
        12,
        0,
        34,
        12,
        39,
        27,
        14,
        30,
        37,
        35,
        49,
        49,
        15,
        22,
        19,
        37,
        44,
        28,
        48,
        20,
        18,
        33,
        49,
        47,
        6,
        49,
        0,
        0,
        35,
        32,
        24,
        24,
        17
      ],
      "weeklyRejected": [
        49,
        4,
        16,
        13,
        35,
        49,
        23,
        49,
        49,
        1,
        35,
        30,
        45,
        23,
        19,
        5,
        25,
        49,
        0,
        39,
        44,
        44,
        0,
        23,
        31,
        49,
        44,
        29,
        48,
        27,
        41,
        21,
        48,
        49,
        9,
        25,
        6,
        45,
        49,
        28,
        31,
        37,
        38,
        0,
        49,
        34,
        20,
        41,
        14,
        49,
        27,
        27
      ],
      "weeklyHold": [
        47,
        19,
        49,
        26,
        1,
        44,
        7,
        2,
        15,
        49,
        43,
        17,
        25,
        24,
        37,
        0,
        2,
        44,
        24,
        10,
        3,
        19,
        7,
        0,
        39,
        48,
        9,
        0,
        0,
        0,
        21,
        11,
        10,
        49,
        48,
        12,
        6,
        3,
        19,
        24,
        49,
        45,
        0,
        28,
        39,
        39,
        4,
        42,
        25,
        9,
        49,
        17
      ],
      "weeklyBackordered": [
        45,
        39,
        0,
        37,
        49,
        46,
        45,
        35,
        0,
        47,
        28,
        23,
        12,
        31,
        34,
        1,
        49,
        14,
        9,
        49,
        2,
        18,
        20,
        0,
        9,
        1,
        25,
        49,
        26,
        49,
        5,
        42,
        10,
        34,
        43,
        0,
        15,
        36,
        43,
        49,
        29,
        34,
        21,
        1,
        11,
        49,
        17,
        0,
        0,
        49,
        49,
        1
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        12,
        42,
        29,
        24,
        49,
        18,
        3,
        31,
        48,
        37,
        6,
        3,
        36,
        20,
        49,
        46,
        24,
        28,
        49,
        10,
        49,
        43,
        29,
        49,
        34,
        47,
        15,
        10,
        39,
        11,
        14,
        0,
        49,
        26,
        7,
        11,
        43,
        0,
        3,
        49,
        28,
        30,
        23,
        41,
        49,
        44,
        27,
        0,
        18,
        27,
        8,
        48
      ],
      "weeklyRejected": [
        49,
        2,
        25,
        23,
        0,
        37,
        37,
        31,
        23,
        19,
        11,
        29,
        31,
        41,
        45,
        14,
        1,
        49,
        24,
        26,
        12,
        47,
        27,
        49,
        28,
        49,
        23,
        16,
        42,
        48,
        49,
        47,
        41,
        0,
        38,
        38,
        34,
        49,
        1,
        49,
        28,
        29,
        0,
        0,
        31,
        31,
        16,
        44,
        5,
        44,
        28,
        49
      ],
      "weeklyHold": [
        21,
        0,
        35,
        14,
        49,
        33,
        44,
        42,
        47,
        20,
        37,
        39,
        17,
        1,
        49,
        15,
        5,
        31,
        10,
        46,
        0,
        34,
        33,
        49,
        44,
        48,
        17,
        11,
        49,
        19,
        40,
        34,
        0,
        49,
        13,
        49,
        35,
        17,
        34,
        17,
        32,
        36,
        37,
        30,
        34,
        8,
        23,
        44,
        43,
        14,
        21,
        48
      ],
      "weeklyBackordered": [
        49,
        7,
        15,
        49,
        49,
        40,
        49,
        46,
        17,
        32,
        0,
        30,
        17,
        12,
        49,
        1,
        25,
        0,
        42,
        37,
        15,
        7,
        12,
        49,
        29,
        30,
        13,
        18,
        3,
        47,
        0,
        19,
        27,
        12,
        43,
        0,
        6,
        0,
        44,
        49,
        28,
        39,
        33,
        49,
        0,
        44,
        25,
        0,
        48,
        49,
        49,
        38
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyAccepted": [
        20,
        43,
        11,
        25,
        49,
        0,
        1,
        2,
        46,
        32,
        49,
        0,
        29,
        24,
        26,
        37,
        49,
        48,
        2,
        6,
        34,
        41,
        39,
        49,
        0,
        47,
        14,
        13,
        1,
        1,
        10,
        49,
        16,
        49,
        34,
        2,
        31,
        36,
        14,
        46,
        39,
        45,
        49,
        40,
        49,
        0,
        23,
        11,
        0,
        22,
        1,
        35
      ],
      "weeklyRejected": [
        39,
        20,
        30,
        26,
        8,
        0,
        49,
        27,
        11,
        25,
        40,
        20,
        27,
        2,
        48,
        25,
        3,
        49,
        22,
        5,
        44,
        12,
        42,
        1,
        0,
        38,
        21,
        22,
        22,
        45,
        49,
        33,
        16,
        11,
        30,
        43,
        8,
        24,
        40,
        23,
        48,
        0,
        27,
        47,
        34,
        33,
        17,
        15,
        0,
        49,
        22,
        23
      ],
      "weeklyHold": [
        39,
        8,
        49,
        25,
        4,
        6,
        42,
        10,
        16,
        49,
        47,
        21,
        48,
        1,
        49,
        18,
        0,
        25,
        9,
        46,
        25,
        49,
        1,
        31,
        38,
        16,
        28,
        33,
        39,
        22,
        48,
        48,
        21,
        20,
        15,
        23,
        49,
        40,
        42,
        34,
        26,
        25,
        33,
        49,
        22,
        3,
        0,
        47,
        0,
        13,
        47,
        42
      ],
      "weeklyBackordered": [
        23,
        20,
        7,
        42,
        49,
        40,
        41,
        30,
        23,
        24,
        0,
        22,
        3,
        32,
        30,
        14,
        49,
        13,
        15,
        0,
        18,
        8,
        0,
        21,
        23,
        49,
        11,
        11,
        12,
        43,
        34,
        49,
        0,
        41,
        18,
        8,
        13,
        0,
        24,
        14,
        18,
        48,
        31,
        49,
        49,
        49,
        23,
        12,
        27,
        49,
        49,
        31
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        43,
        34,
        5,
        13,
        49,
        36,
        31,
        12,
        48,
        18,
        49,
        8,
        47,
        1,
        49,
        11,
        42,
        0,
        48,
        30,
        21,
        27,
        21,
        22,
        24,
        47,
        18,
        21,
        22,
        19,
        7,
        17,
        49,
        11,
        48,
        7,
        25,
        41,
        32,
        27,
        17,
        32,
        40,
        26,
        43,
        0,
        0,
        0,
        0,
        34,
        29,
        3
      ],
      "weeklyRejected": [
        38,
        34,
        49,
        25,
        24,
        3,
        49,
        49,
        1,
        36,
        20,
        41,
        33,
        39,
        46,
        4,
        10,
        47,
        46,
        0,
        40,
        11,
        49,
        40,
        10,
        49,
        48,
        15,
        23,
        0,
        45,
        25,
        23,
        31,
        0,
        30,
        49,
        48,
        8,
        0,
        1,
        15,
        21,
        39,
        38,
        17,
        8,
        11,
        16,
        49,
        0,
        49
      ],
      "weeklyHold": [
        18,
        32,
        49,
        1,
        0,
        28,
        49,
        24,
        0,
        5,
        22,
        28,
        24,
        1,
        49,
        6,
        2,
        45,
        3,
        16,
        43,
        17,
        0,
        29,
        7,
        1,
        42,
        29,
        49,
        28,
        42,
        18,
        42,
        5,
        21,
        13,
        0,
        12,
        28,
        33,
        41,
        49,
        11,
        39,
        42,
        18,
        0,
        0,
        22,
        47,
        49,
        49
      ],
      "weeklyBackordered": [
        49,
        0,
        0,
        9,
        35,
        36,
        0,
        38,
        23,
        2,
        45,
        49,
        49,
        49,
        49,
        45,
        1,
        19,
        0,
        42,
        49,
        42,
        25,
        25,
        1,
        49,
        7,
        21,
        17,
        40,
        0,
        10,
        25,
        29,
        15,
        34,
        16,
        0,
        4,
        0,
        25,
        48,
        23,
        33,
        0,
        26,
        33,
        17,
        34,
        37,
        1,
        1
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        0,
        49,
        49,
        20,
        6,
        8,
        28,
        49,
        48,
        31,
        49,
        17,
        35,
        9,
        1,
        49,
        35,
        9,
        0,
        12,
        25,
        25,
        49,
        43,
        22,
        31,
        35,
        1,
        17,
        31,
        0,
        19,
        41,
        38,
        48,
        47,
        37,
        26,
        36,
        33,
        0,
        18,
        36,
        49,
        16,
        0,
        35,
        48,
        3,
        14,
        22,
        16
      ],
      "weeklyRejected": [
        13,
        14,
        29,
        45,
        19,
        41,
        49,
        22,
        33,
        48,
        2,
        26,
        46,
        34,
        21,
        17,
        1,
        49,
        41,
        13,
        13,
        15,
        16,
        29,
        3,
        49,
        34,
        35,
        48,
        31,
        7,
        23,
        40,
        22,
        11,
        34,
        47,
        39,
        1,
        27,
        48,
        49,
        28,
        14,
        34,
        35,
        35,
        34,
        7,
        36,
        13,
        49
      ],
      "weeklyHold": [
        20,
        0,
        20,
        1,
        1,
        9,
        39,
        31,
        21,
        16,
        45,
        16,
        37,
        1,
        40,
        40,
        0,
        46,
        8,
        21,
        46,
        0,
        0,
        30,
        17,
        20,
        24,
        35,
        3,
        12,
        30,
        0,
        49,
        38,
        15,
        45,
        2,
        18,
        11,
        19,
        33,
        49,
        12,
        35,
        31,
        29,
        49,
        26,
        0,
        23,
        32,
        49
      ],
      "weeklyBackordered": [
        49,
        0,
        12,
        26,
        40,
        40,
        26,
        14,
        13,
        22,
        33,
        49,
        37,
        39,
        42,
        1,
        33,
        49,
        10,
        49,
        27,
        34,
        0,
        49,
        48,
        49,
        18,
        49,
        17,
        36,
        0,
        4,
        36,
        25,
        0,
        31,
        18,
        7,
        35,
        9,
        48,
        48,
        13,
        31,
        9,
        0,
        42,
        22,
        22,
        10,
        13,
        3
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        30,
        30,
        0,
        18,
        19,
        0,
        2,
        47,
        48,
        0,
        29,
        0,
        37,
        42,
        15,
        14,
        36,
        41,
        0,
        49,
        23,
        19,
        49,
        0,
        11,
        6,
        29,
        18,
        9,
        31,
        25,
        25,
        33,
        24,
        32,
        41,
        38,
        18,
        27,
        49,
        11,
        33,
        49,
        5,
        29,
        0,
        0,
        49,
        25,
        49,
        0,
        27
      ],
      "weeklyRejected": [
        31,
        49,
        13,
        20,
        24,
        19,
        0,
        20,
        0,
        48,
        49,
        10,
        27,
        42,
        48,
        35,
        39,
        19,
        14,
        28,
        29,
        8,
        33,
        17,
        49,
        49,
        43,
        42,
        48,
        13,
        11,
        46,
        48,
        26,
        13,
        49,
        19,
        11,
        14,
        1,
        48,
        32,
        49,
        3,
        49,
        21,
        30,
        5,
        16,
        32,
        5,
        27
      ],
      "weeklyHold": [
        34,
        43,
        47,
        12,
        16,
        38,
        30,
        2,
        1,
        34,
        34,
        41,
        36,
        1,
        0,
        23,
        0,
        19,
        16,
        3,
        19,
        0,
        16,
        2,
        38,
        19,
        15,
        15,
        49,
        12,
        22,
        48,
        0,
        0,
        29,
        21,
        12,
        0,
        1,
        12,
        23,
        18,
        34,
        49,
        39,
        49,
        28,
        49,
        7,
        44,
        0,
        23
      ],
      "weeklyBackordered": [
        22,
        49,
        14,
        49,
        49,
        35,
        1,
        35,
        48,
        0,
        11,
        25,
        22,
        21,
        9,
        22,
        31,
        41,
        0,
        15,
        44,
        30,
        0,
        49,
        18,
        31,
        1,
        30,
        39,
        48,
        38,
        38,
        48,
        33,
        0,
        0,
        2,
        14,
        6,
        0,
        6,
        48,
        30,
        42,
        12,
        37,
        46,
        0,
        8,
        22,
        19,
        17
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        0,
        49,
        49,
        36,
        27,
        5,
        49,
        49,
        25,
        39,
        13,
        5,
        0,
        17,
        32,
        46,
        49,
        41,
        0,
        23,
        34,
        0,
        25,
        44,
        12,
        30,
        0,
        36,
        1,
        13,
        0,
        36,
        49,
        41,
        31,
        41,
        12,
        46,
        32,
        49,
        29,
        43,
        49,
        49,
        34,
        0,
        7,
        38,
        28,
        41,
        25,
        48
      ],
      "weeklyRejected": [
        49,
        39,
        39,
        27,
        3,
        29,
        0,
        12,
        40,
        48,
        47,
        41,
        34,
        48,
        48,
        0,
        0,
        35,
        28,
        49,
        23,
        49,
        49,
        0,
        24,
        49,
        49,
        17,
        44,
        42,
        40,
        35,
        41,
        46,
        0,
        43,
        49,
        35,
        7,
        27,
        45,
        0,
        0,
        2,
        49,
        25,
        0,
        0,
        11,
        49,
        16,
        42
      ],
      "weeklyHold": [
        22,
        0,
        33,
        1,
        49,
        37,
        0,
        30,
        3,
        49,
        35,
        42,
        16,
        40,
        10,
        28,
        0,
        5,
        46,
        13,
        14,
        0,
        23,
        24,
        0,
        41,
        20,
        0,
        37,
        0,
        10,
        0,
        0,
        45,
        4,
        49,
        39,
        22,
        1,
        10,
        24,
        40,
        0,
        49,
        44,
        29,
        8,
        0,
        0,
        40,
        0,
        32
      ],
      "weeklyBackordered": [
        26,
        31,
        48,
        38,
        48,
        19,
        20,
        49,
        49,
        24,
        26,
        49,
        33,
        28,
        17,
        17,
        2,
        49,
        47,
        24,
        37,
        44,
        20,
        21,
        35,
        19,
        21,
        18,
        48,
        12,
        13,
        29,
        48,
        11,
        4,
        0,
        49,
        12,
        49,
        22,
        14,
        48,
        7,
        49,
        37,
        36,
        41,
        10,
        31,
        34,
        26,
        17
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        0,
        49,
        16,
        46,
        40,
        38,
        3,
        27,
        7,
        0,
        31,
        0,
        32,
        44,
        28,
        11,
        41,
        29,
        40,
        49,
        35,
        49,
        0,
        40,
        0,
        47,
        23,
        22,
        16,
        32,
        36,
        49,
        34,
        16,
        24,
        49,
        47,
        13,
        45,
        38,
        10,
        10,
        49,
        14,
        19,
        0,
        30,
        24,
        25,
        47,
        17,
        36
      ],
      "weeklyRejected": [
        45,
        49,
        2,
        6,
        49,
        34,
        37,
        29,
        49,
        29,
        32,
        49,
        35,
        10,
        28,
        2,
        39,
        34,
        21,
        16,
        0,
        49,
        11,
        49,
        7,
        49,
        49,
        3,
        33,
        19,
        49,
        15,
        16,
        46,
        27,
        47,
        49,
        0,
        2,
        33,
        29,
        0,
        30,
        34,
        49,
        7,
        6,
        49,
        30,
        49,
        0,
        49
      ],
      "weeklyHold": [
        23,
        16,
        49,
        16,
        33,
        32,
        49,
        23,
        16,
        27,
        47,
        3,
        23,
        16,
        27,
        41,
        6,
        48,
        21,
        23,
        4,
        38,
        39,
        12,
        0,
        32,
        29,
        49,
        49,
        5,
        48,
        19,
        0,
        37,
        14,
        34,
        28,
        26,
        49,
        29,
        13,
        41,
        6,
        28,
        20,
        49,
        32,
        21,
        0,
        38,
        49,
        27
      ],
      "weeklyBackordered": [
        45,
        0,
        0,
        39,
        47,
        35,
        0,
        32,
        49,
        2,
        49,
        9,
        19,
        21,
        0,
        3,
        49,
        15,
        49,
        10,
        22,
        26,
        46,
        0,
        29,
        21,
        29,
        6,
        21,
        24,
        37,
        37,
        48,
        45,
        19,
        0,
        37,
        2,
        14,
        47,
        19,
        48,
        15,
        36,
        3,
        17,
        49,
        30,
        0,
        27,
        34,
        26
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        31,
        33,
        45,
        19,
        16,
        16,
        9,
        28,
        30,
        23,
        38,
        5,
        26,
        39,
        27,
        28,
        25,
        2,
        5,
        22,
        13,
        5,
        37,
        21,
        49,
        18,
        0,
        15,
        24,
        24,
        49,
        18,
        49,
        0,
        45,
        31,
        46,
        9,
        22,
        49,
        35,
        15,
        49,
        7,
        49,
        12,
        0,
        14,
        21,
        34,
        49,
        37
      ],
      "weeklyRejected": [
        39,
        24,
        49,
        24,
        39,
        49,
        49,
        29,
        49,
        25,
        8,
        34,
        16,
        48,
        32,
        8,
        49,
        33,
        26,
        0,
        8,
        0,
        6,
        14,
        15,
        49,
        0,
        5,
        38,
        23,
        49,
        47,
        43,
        29,
        0,
        49,
        35,
        31,
        12,
        34,
        0,
        25,
        6,
        13,
        44,
        0,
        0,
        33,
        0,
        43,
        21,
        35
      ],
      "weeklyHold": [
        14,
        19,
        13,
        15,
        49,
        30,
        0,
        6,
        16,
        21,
        0,
        34,
        29,
        7,
        49,
        11,
        36,
        10,
        30,
        24,
        13,
        0,
        31,
        6,
        38,
        37,
        49,
        30,
        49,
        18,
        29,
        0,
        36,
        3,
        18,
        23,
        0,
        19,
        26,
        10,
        40,
        32,
        21,
        35,
        31,
        13,
        44,
        10,
        16,
        35,
        5,
        0
      ],
      "weeklyBackordered": [
        11,
        8,
        21,
        20,
        44,
        10,
        35,
        36,
        49,
        2,
        0,
        49,
        48,
        0,
        26,
        19,
        38,
        45,
        0,
        41,
        21,
        21,
        0,
        16,
        11,
        33,
        40,
        19,
        8,
        49,
        0,
        7,
        21,
        14,
        0,
        0,
        8,
        26,
        24,
        2,
        0,
        48,
        5,
        21,
        24,
        19,
        18,
        10,
        0,
        19,
        37,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        26,
        34,
        16,
        48,
        1,
        14,
        1,
        26,
        34,
        18,
        22,
        0,
        32,
        1,
        18,
        18,
        49,
        21,
        0,
        17,
        40,
        26,
        29,
        41,
        10,
        36,
        13,
        24,
        49,
        1,
        13,
        15,
        20,
        31,
        48,
        47,
        20,
        48,
        42,
        49,
        7,
        33,
        40,
        35,
        49,
        2,
        13,
        49,
        21,
        10,
        46,
        29
      ],
      "weeklyRejected": [
        23,
        30,
        14,
        22,
        35,
        0,
        0,
        39,
        49,
        48,
        47,
        0,
        0,
        45,
        48,
        0,
        26,
        49,
        31,
        22,
        0,
        23,
        10,
        26,
        0,
        49,
        49,
        31,
        33,
        28,
        28,
        16,
        0,
        14,
        0,
        49,
        49,
        49,
        18,
        24,
        35,
        34,
        7,
        1,
        49,
        10,
        18,
        27,
        29,
        49,
        0,
        49
      ],
      "weeklyHold": [
        49,
        27,
        49,
        17,
        2,
        10,
        35,
        24,
        11,
        0,
        38,
        27,
        48,
        7,
        49,
        0,
        33,
        14,
        0,
        11,
        0,
        41,
        0,
        0,
        47,
        12,
        26,
        18,
        49,
        15,
        47,
        41,
        0,
        49,
        10,
        19,
        0,
        11,
        29,
        10,
        14,
        49,
        12,
        33,
        0,
        20,
        0,
        40,
        4,
        49,
        25,
        48
      ],
      "weeklyBackordered": [
        35,
        49,
        21,
        33,
        49,
        32,
        16,
        35,
        28,
        21,
        47,
        49,
        3,
        46,
        38,
        24,
        26,
        19,
        10,
        0,
        48,
        27,
        35,
        25,
        35,
        1,
        47,
        23,
        23,
        49,
        30,
        28,
        47,
        5,
        17,
        0,
        49,
        29,
        2,
        0,
        28,
        39,
        39,
        49,
        0,
        30,
        49,
        49,
        0,
        40,
        28,
        21
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        12,
        37,
        49,
        4,
        1,
        13,
        5,
        31,
        26,
        49,
        29,
        0,
        27,
        47,
        25,
        29,
        49,
        0,
        4,
        34,
        30,
        26,
        27,
        0,
        33,
        47,
        0,
        46,
        33,
        45,
        18,
        0,
        46,
        40,
        48,
        3,
        14,
        48,
        36,
        49,
        1,
        32,
        37,
        39,
        19,
        25,
        6,
        31,
        0,
        3,
        6,
        7
      ],
      "weeklyRejected": [
        45,
        12,
        8,
        1,
        43,
        0,
        9,
        49,
        12,
        3,
        35,
        27,
        0,
        27,
        41,
        37,
        0,
        49,
        27,
        21,
        21,
        12,
        18,
        27,
        42,
        23,
        15,
        30,
        39,
        0,
        12,
        11,
        14,
        15,
        49,
        49,
        49,
        1,
        1,
        17,
        21,
        39,
        42,
        0,
        41,
        45,
        0,
        19,
        0,
        21,
        0,
        32
      ],
      "weeklyHold": [
        0,
        21,
        26,
        1,
        49,
        26,
        6,
        20,
        30,
        9,
        36,
        21,
        19,
        49,
        34,
        31,
        29,
        29,
        8,
        29,
        39,
        22,
        28,
        29,
        19,
        41,
        43,
        30,
        49,
        49,
        48,
        0,
        0,
        48,
        31,
        16,
        24,
        3,
        11,
        0,
        2,
        49,
        17,
        0,
        0,
        0,
        3,
        12,
        0,
        22,
        42,
        0
      ],
      "weeklyBackordered": [
        26,
        40,
        44,
        0,
        43,
        46,
        29,
        21,
        16,
        0,
        0,
        48,
        3,
        0,
        49,
        47,
        29,
        49,
        20,
        49,
        22,
        32,
        14,
        9,
        1,
        1,
        40,
        22,
        47,
        0,
        41,
        13,
        0,
        22,
        0,
        0,
        6,
        23,
        49,
        26,
        29,
        48,
        11,
        42,
        4,
        0,
        19,
        0,
        0,
        22,
        49,
        1
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        7,
        31,
        33,
        43,
        49,
        29,
        10,
        44,
        37,
        34,
        49,
        0,
        14,
        18,
        1,
        0,
        49,
        0,
        15,
        7,
        1,
        1,
        49,
        38,
        0,
        6,
        49,
        0,
        1,
        9,
        13,
        30,
        49,
        15,
        48,
        31,
        23,
        19,
        49,
        49,
        14,
        42,
        41,
        37,
        39,
        0,
        10,
        39,
        6,
        6,
        3,
        28
      ],
      "weeklyRejected": [
        48,
        43,
        29,
        0,
        31,
        40,
        5,
        1,
        45,
        48,
        33,
        37,
        28,
        48,
        44,
        8,
        23,
        49,
        32,
        10,
        19,
        49,
        19,
        49,
        20,
        23,
        48,
        24,
        4,
        28,
        49,
        38,
        4,
        1,
        8,
        47,
        42,
        28,
        43,
        49,
        38,
        10,
        0,
        40,
        41,
        27,
        0,
        25,
        25,
        48,
        0,
        49
      ],
      "weeklyHold": [
        49,
        22,
        49,
        28,
        0,
        27,
        3,
        32,
        20,
        0,
        24,
        20,
        35,
        8,
        31,
        0,
        0,
        16,
        29,
        11,
        17,
        39,
        12,
        40,
        7,
        1,
        41,
        27,
        49,
        29,
        33,
        28,
        0,
        33,
        8,
        11,
        0,
        0,
        39,
        42,
        21,
        41,
        34,
        33,
        43,
        43,
        0,
        43,
        0,
        3,
        40,
        40
      ],
      "weeklyBackordered": [
        0,
        0,
        48,
        47,
        24,
        40,
        2,
        40,
        40,
        8,
        38,
        16,
        6,
        0,
        0,
        49,
        49,
        47,
        28,
        0,
        31,
        43,
        42,
        34,
        44,
        31,
        1,
        0,
        17,
        37,
        49,
        37,
        15,
        49,
        0,
        21,
        34,
        0,
        0,
        33,
        22,
        38,
        0,
        49,
        49,
        2,
        49,
        11,
        49,
        37,
        15,
        29
      ]
    },
    {
      "id": "DB301E71",
      "weeklyAccepted": [
        7,
        32,
        37,
        20,
        49,
        14,
        1,
        49,
        46,
        38,
        49,
        13,
        0,
        17,
        31,
        17,
        36,
        21,
        6,
        27,
        47,
        49,
        36,
        47,
        5,
        0,
        20,
        0,
        1,
        27,
        29,
        39,
        49,
        2,
        48,
        41,
        44,
        48,
        27,
        49,
        0,
        40,
        43,
        46,
        39,
        30,
        19,
        49,
        45,
        26,
        27,
        48
      ],
      "weeklyRejected": [
        12,
        10,
        31,
        23,
        30,
        44,
        21,
        30,
        8,
        40,
        0,
        0,
        37,
        40,
        28,
        31,
        49,
        21,
        41,
        49,
        5,
        49,
        49,
        49,
        0,
        25,
        44,
        5,
        14,
        33,
        49,
        26,
        38,
        19,
        44,
        49,
        5,
        25,
        40,
        19,
        19,
        42,
        15,
        21,
        31,
        28,
        18,
        29,
        28,
        49,
        12,
        19
      ],
      "weeklyHold": [
        0,
        49,
        49,
        1,
        9,
        34,
        49,
        22,
        24,
        25,
        35,
        49,
        9,
        21,
        17,
        0,
        3,
        9,
        49,
        0,
        18,
        8,
        6,
        20,
        31,
        48,
        5,
        3,
        49,
        22,
        48,
        24,
        0,
        44,
        32,
        36,
        8,
        0,
        13,
        18,
        49,
        49,
        5,
        31,
        30,
        31,
        31,
        37,
        18,
        43,
        19,
        19
      ],
      "weeklyBackordered": [
        17,
        29,
        2,
        0,
        39,
        46,
        21,
        1,
        49,
        20,
        23,
        36,
        12,
        36,
        31,
        10,
        33,
        33,
        16,
        6,
        3,
        42,
        2,
        30,
        28,
        38,
        6,
        12,
        14,
        44,
        7,
        35,
        45,
        23,
        10,
        20,
        15,
        0,
        22,
        44,
        20,
        31,
        15,
        49,
        7,
        10,
        22,
        20,
        2,
        49,
        39,
        34
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        32,
        11,
        32,
        39,
        19,
        32,
        18,
        17,
        45,
        0,
        43,
        20,
        28,
        36,
        2,
        14,
        20,
        9,
        2,
        46,
        12,
        44,
        24,
        0,
        0,
        47,
        35,
        21,
        6,
        22,
        0,
        36,
        49,
        7,
        48,
        49,
        16,
        12,
        33,
        49,
        27,
        49,
        49,
        37,
        27,
        0,
        35,
        0,
        10,
        23,
        0,
        48
      ],
      "weeklyRejected": [
        29,
        6,
        49,
        47,
        8,
        8,
        26,
        24,
        0,
        33,
        26,
        0,
        0,
        3,
        42,
        23,
        0,
        49,
        17,
        49,
        48,
        40,
        39,
        49,
        7,
        15,
        46,
        23,
        25,
        17,
        49,
        41,
        48,
        34,
        0,
        49,
        16,
        26,
        1,
        27,
        8,
        21,
        43,
        17,
        49,
        45,
        49,
        20,
        0,
        49,
        19,
        49
      ],
      "weeklyHold": [
        0,
        15,
        8,
        1,
        49,
        48,
        21,
        28,
        31,
        0,
        0,
        1,
        48,
        34,
        33,
        13,
        15,
        48,
        0,
        49,
        14,
        14,
        40,
        8,
        31,
        48,
        11,
        26,
        22,
        0,
        25,
        7,
        26,
        0,
        48,
        37,
        46,
        37,
        49,
        0,
        12,
        9,
        18,
        49,
        13,
        41,
        0,
        29,
        0,
        26,
        31,
        48
      ],
      "weeklyBackordered": [
        40,
        0,
        45,
        12,
        9,
        32,
        42,
        1,
        39,
        32,
        49,
        32,
        3,
        49,
        46,
        35,
        21,
        42,
        0,
        49,
        43,
        27,
        26,
        17,
        14,
        40,
        36,
        0,
        41,
        25,
        22,
        16,
        37,
        8,
        0,
        8,
        49,
        16,
        14,
        27,
        11,
        21,
        1,
        49,
        39,
        3,
        27,
        15,
        5,
        48,
        17,
        20
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        29,
        22,
        49,
        23,
        22,
        7,
        3,
        26,
        39,
        21,
        18,
        20,
        49,
        22,
        9,
        49,
        45,
        19,
        0,
        23,
        3,
        27,
        49,
        49,
        35,
        0,
        14,
        0,
        1,
        46,
        8,
        49,
        49,
        24,
        47,
        43,
        22,
        0,
        49,
        49,
        31,
        25,
        42,
        43,
        30,
        15,
        0,
        22,
        0,
        31,
        49,
        48
      ],
      "weeklyRejected": [
        34,
        49,
        43,
        36,
        35,
        43,
        14,
        33,
        49,
        34,
        5,
        44,
        0,
        20,
        48,
        0,
        19,
        45,
        46,
        0,
        9,
        0,
        49,
        47,
        14,
        8,
        21,
        10,
        40,
        45,
        33,
        24,
        27,
        29,
        0,
        29,
        8,
        7,
        1,
        22,
        21,
        13,
        16,
        8,
        43,
        6,
        5,
        39,
        0,
        27,
        0,
        41
      ],
      "weeklyHold": [
        7,
        3,
        44,
        12,
        0,
        32,
        49,
        2,
        0,
        49,
        1,
        12,
        1,
        1,
        41,
        33,
        28,
        32,
        44,
        44,
        44,
        12,
        40,
        0,
        20,
        40,
        49,
        34,
        49,
        0,
        48,
        26,
        0,
        29,
        48,
        30,
        36,
        2,
        25,
        35,
        24,
        26,
        9,
        39,
        49,
        44,
        0,
        19,
        12,
        43,
        48,
        20
      ],
      "weeklyBackordered": [
        47,
        18,
        39,
        26,
        49,
        46,
        49,
        42,
        42,
        46,
        49,
        24,
        38,
        30,
        1,
        44,
        30,
        37,
        43,
        0,
        37,
        16,
        40,
        43,
        36,
        32,
        23,
        34,
        7,
        29,
        26,
        35,
        18,
        28,
        18,
        15,
        1,
        7,
        29,
        5,
        28,
        29,
        1,
        49,
        0,
        49,
        49,
        33,
        0,
        0,
        20,
        13
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        7,
        39,
        19,
        39,
        42,
        49,
        1,
        2,
        45,
        19,
        32,
        40,
        37,
        19,
        15,
        34,
        45,
        40,
        41,
        14,
        16,
        0,
        28,
        49,
        49,
        41,
        33,
        45,
        15,
        24,
        29,
        22,
        49,
        44,
        48,
        49,
        47,
        27,
        28,
        49,
        32,
        18,
        43,
        31,
        21,
        27,
        14,
        44,
        46,
        49,
        32,
        36
      ],
      "weeklyRejected": [
        45,
        39,
        49,
        47,
        39,
        6,
        22,
        0,
        14,
        12,
        8,
        0,
        49,
        48,
        19,
        0,
        28,
        23,
        40,
        30,
        19,
        35,
        32,
        31,
        7,
        49,
        49,
        39,
        47,
        48,
        46,
        47,
        48,
        29,
        19,
        49,
        49,
        49,
        1,
        19,
        38,
        49,
        44,
        28,
        24,
        47,
        15,
        41,
        41,
        49,
        0,
        8
      ],
      "weeklyHold": [
        34,
        49,
        34,
        13,
        45,
        33,
        21,
        31,
        28,
        49,
        14,
        16,
        20,
        49,
        43,
        17,
        42,
        23,
        16,
        31,
        18,
        1,
        4,
        7,
        33,
        48,
        35,
        17,
        49,
        0,
        38,
        19,
        44,
        45,
        48,
        16,
        48,
        0,
        49,
        23,
        49,
        48,
        0,
        23,
        46,
        47,
        41,
        36,
        24,
        0,
        49,
        30
      ],
      "weeklyBackordered": [
        25,
        46,
        6,
        30,
        49,
        38,
        33,
        43,
        49,
        3,
        49,
        14,
        26,
        49,
        48,
        18,
        37,
        49,
        13,
        0,
        37,
        0,
        9,
        0,
        1,
        36,
        30,
        22,
        1,
        31,
        17,
        43,
        38,
        49,
        0,
        0,
        49,
        15,
        0,
        0,
        10,
        36,
        8,
        45,
        20,
        26,
        37,
        20,
        49,
        26,
        49,
        11
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        0,
        42,
        29,
        9,
        46,
        12,
        14,
        49,
        11,
        27,
        49,
        6,
        19,
        23,
        1,
        25,
        49,
        25,
        0,
        4,
        14,
        4,
        1,
        24,
        48,
        8,
        9,
        24,
        6,
        49,
        37,
        45,
        49,
        38,
        43,
        27,
        30,
        48,
        1,
        22,
        11,
        34,
        0,
        15,
        49,
        42,
        12,
        27,
        24,
        20,
        49,
        39
      ],
      "weeklyRejected": [
        22,
        22,
        2,
        9,
        38,
        32,
        25,
        49,
        44,
        1,
        32,
        49,
        18,
        48,
        48,
        33,
        35,
        25,
        16,
        26,
        49,
        34,
        13,
        49,
        33,
        42,
        24,
        35,
        38,
        43,
        3,
        24,
        46,
        49,
        0,
        27,
        23,
        0,
        12,
        22,
        11,
        26,
        12,
        10,
        49,
        9,
        37,
        36,
        6,
        0,
        15,
        31
      ],
      "weeklyHold": [
        49,
        22,
        23,
        17,
        49,
        48,
        19,
        37,
        33,
        20,
        36,
        3,
        8,
        16,
        36,
        5,
        10,
        48,
        49,
        23,
        10,
        29,
        28,
        18,
        37,
        48,
        40,
        6,
        45,
        41,
        41,
        24,
        28,
        26,
        35,
        28,
        6,
        3,
        30,
        20,
        28,
        11,
        5,
        31,
        49,
        35,
        0,
        35,
        0,
        22,
        0,
        15
      ],
      "weeklyBackordered": [
        0,
        35,
        33,
        21,
        33,
        29,
        45,
        29,
        20,
        43,
        27,
        21,
        33,
        15,
        13,
        16,
        49,
        49,
        15,
        49,
        35,
        15,
        0,
        25,
        25,
        1,
        12,
        19,
        42,
        44,
        27,
        49,
        26,
        37,
        0,
        18,
        16,
        12,
        29,
        29,
        22,
        48,
        10,
        49,
        17,
        14,
        8,
        49,
        18,
        19,
        45,
        24
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyAccepted": [
        24,
        36,
        49,
        48,
        36,
        2,
        1,
        49,
        27,
        17,
        49,
        8,
        49,
        34,
        24,
        33,
        24,
        11,
        32,
        23,
        25,
        45,
        11,
        45,
        0,
        14,
        12,
        32,
        1,
        49,
        15,
        16,
        42,
        35,
        48,
        14,
        28,
        30,
        29,
        49,
        0,
        2,
        4,
        33,
        0,
        17,
        0,
        41,
        12,
        40,
        28,
        32
      ],
      "weeklyRejected": [
        44,
        4,
        11,
        35,
        0,
        49,
        13,
        37,
        49,
        13,
        16,
        0,
        14,
        48,
        9,
        7,
        31,
        0,
        19,
        32,
        4,
        41,
        31,
        17,
        8,
        49,
        47,
        0,
        48,
        48,
        34,
        47,
        0,
        22,
        49,
        49,
        22,
        0,
        1,
        19,
        39,
        44,
        44,
        30,
        30,
        42,
        4,
        5,
        41,
        49,
        0,
        4
      ],
      "weeklyHold": [
        45,
        27,
        49,
        1,
        15,
        29,
        23,
        44,
        21,
        29,
        47,
        23,
        23,
        32,
        40,
        0,
        4,
        30,
        5,
        0,
        19,
        18,
        49,
        0,
        0,
        31,
        0,
        46,
        36,
        3,
        31,
        0,
        0,
        49,
        25,
        49,
        30,
        46,
        48,
        49,
        36,
        49,
        17,
        49,
        19,
        1,
        0,
        47,
        34,
        38,
        49,
        21
      ],
      "weeklyBackordered": [
        49,
        26,
        3,
        26,
        49,
        46,
        40,
        44,
        49,
        29,
        29,
        27,
        16,
        21,
        0,
        5,
        25,
        49,
        33,
        7,
        0,
        4,
        38,
        25,
        4,
        20,
        28,
        34,
        27,
        40,
        42,
        35,
        40,
        15,
        14,
        0,
        49,
        0,
        49,
        0,
        0,
        26,
        47,
        42,
        22,
        39,
        30,
        8,
        21,
        24,
        49,
        25
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        45,
        23,
        49,
        23,
        16,
        12,
        22,
        46,
        48,
        9,
        45,
        0,
        39,
        41,
        29,
        28,
        36,
        8,
        0,
        14,
        11,
        20,
        16,
        45,
        49,
        47,
        23,
        20,
        1,
        35,
        5,
        11,
        47,
        0,
        48,
        32,
        23,
        38,
        43,
        49,
        43,
        34,
        49,
        28,
        42,
        0,
        0,
        11,
        2,
        14,
        34,
        44
      ],
      "weeklyRejected": [
        30,
        11,
        49,
        34,
        43,
        11,
        25,
        33,
        38,
        28,
        49,
        22,
        30,
        35,
        21,
        7,
        0,
        11,
        26,
        16,
        49,
        34,
        11,
        18,
        41,
        22,
        48,
        7,
        48,
        17,
        9,
        10,
        27,
        34,
        2,
        49,
        49,
        38,
        11,
        7,
        30,
        14,
        7,
        3,
        32,
        49,
        46,
        6,
        12,
        43,
        7,
        30
      ],
      "weeklyHold": [
        28,
        0,
        32,
        40,
        20,
        48,
        42,
        20,
        49,
        29,
        34,
        28,
        34,
        18,
        27,
        16,
        0,
        47,
        49,
        5,
        35,
        48,
        32,
        44,
        37,
        29,
        40,
        35,
        36,
        17,
        5,
        0,
        14,
        39,
        28,
        12,
        36,
        20,
        1,
        0,
        21,
        30,
        0,
        49,
        24,
        24,
        49,
        44,
        10,
        26,
        49,
        0
      ],
      "weeklyBackordered": [
        7,
        29,
        17,
        48,
        49,
        3,
        36,
        49,
        45,
        11,
        22,
        49,
        3,
        1,
        8,
        24,
        18,
        30,
        31,
        0,
        27,
        0,
        21,
        10,
        1,
        20,
        49,
        0,
        1,
        26,
        44,
        14,
        28,
        23,
        0,
        21,
        23,
        0,
        17,
        34,
        45,
        11,
        0,
        0,
        16,
        31,
        49,
        31,
        47,
        31,
        1,
        1
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        8,
        33,
        22,
        30,
        40,
        15,
        20,
        45,
        35,
        39,
        30,
        0,
        20,
        0,
        18,
        3,
        24,
        34,
        16,
        11,
        0,
        37,
        28,
        49,
        13,
        23,
        30,
        20,
        47,
        49,
        44,
        0,
        22,
        36,
        34,
        39,
        32,
        38,
        49,
        49,
        19,
        49,
        49,
        0,
        49,
        0,
        16,
        24,
        46,
        33,
        0,
        26
      ],
      "weeklyRejected": [
        49,
        14,
        42,
        29,
        33,
        30,
        45,
        49,
        24,
        34,
        0,
        19,
        30,
        34,
        48,
        9,
        24,
        37,
        8,
        10,
        27,
        0,
        4,
        19,
        0,
        22,
        24,
        49,
        30,
        16,
        37,
        21,
        35,
        16,
        28,
        36,
        9,
        14,
        21,
        49,
        48,
        49,
        49,
        49,
        47,
        17,
        32,
        49,
        16,
        25,
        19,
        49
      ],
      "weeklyHold": [
        15,
        12,
        49,
        8,
        16,
        26,
        49,
        42,
        0,
        12,
        19,
        40,
        1,
        36,
        0,
        0,
        9,
        4,
        41,
        35,
        0,
        31,
        1,
        8,
        13,
        42,
        25,
        13,
        38,
        20,
        48,
        26,
        0,
        29,
        0,
        45,
        33,
        5,
        45,
        49,
        29,
        16,
        34,
        0,
        36,
        0,
        26,
        13,
        0,
        33,
        8,
        49
      ],
      "weeklyBackordered": [
        32,
        49,
        27,
        40,
        45,
        46,
        26,
        9,
        11,
        49,
        11,
        47,
        6,
        49,
        18,
        1,
        35,
        41,
        0,
        46,
        10,
        0,
        10,
        38,
        48,
        1,
        13,
        35,
        15,
        49,
        15,
        8,
        24,
        49,
        16,
        0,
        23,
        38,
        49,
        33,
        31,
        21,
        26,
        49,
        38,
        14,
        40,
        7,
        28,
        49,
        1,
        7
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        17,
        36,
        15,
        8,
        4,
        0,
        1,
        49,
        26,
        5,
        49,
        18,
        45,
        33,
        22,
        20,
        39,
        15,
        49,
        15,
        49,
        48,
        49,
        49,
        49,
        47,
        2,
        32,
        4,
        29,
        5,
        34,
        49,
        20,
        48,
        43,
        47,
        16,
        16,
        49,
        0,
        27,
        17,
        5,
        49,
        22,
        11,
        30,
        0,
        10,
        6,
        28
      ],
      "weeklyRejected": [
        15,
        17,
        15,
        20,
        16,
        15,
        21,
        30,
        41,
        23,
        0,
        24,
        36,
        48,
        42,
        18,
        35,
        49,
        28,
        22,
        25,
        39,
        0,
        29,
        49,
        49,
        4,
        49,
        46,
        0,
        23,
        29,
        0,
        37,
        49,
        49,
        44,
        40,
        16,
        49,
        0,
        49,
        27,
        8,
        46,
        26,
        0,
        13,
        29,
        44,
        18,
        24
      ],
      "weeklyHold": [
        49,
        42,
        43,
        1,
        2,
        36,
        0,
        27,
        42,
        11,
        47,
        32,
        48,
        1,
        31,
        15,
        20,
        22,
        10,
        18,
        14,
        49,
        35,
        43,
        35,
        1,
        43,
        27,
        33,
        7,
        48,
        5,
        31,
        25,
        14,
        26,
        25,
        16,
        39,
        2,
        41,
        34,
        5,
        45,
        49,
        7,
        28,
        35,
        0,
        36,
        49,
        49
      ],
      "weeklyBackordered": [
        34,
        49,
        29,
        49,
        49,
        34,
        10,
        48,
        21,
        18,
        24,
        11,
        45,
        22,
        25,
        1,
        31,
        26,
        9,
        49,
        36,
        0,
        2,
        36,
        30,
        46,
        13,
        26,
        30,
        24,
        22,
        49,
        26,
        47,
        30,
        19,
        1,
        0,
        0,
        22,
        23,
        42,
        48,
        20,
        16,
        35,
        40,
        26,
        0,
        49,
        32,
        31
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        0,
        38,
        24,
        23,
        49,
        16,
        49,
        48,
        35,
        8,
        0,
        7,
        49,
        37,
        41,
        12,
        49,
        0,
        0,
        49,
        49,
        34,
        36,
        49,
        14,
        23,
        34,
        5,
        1,
        7,
        16,
        18,
        47,
        19,
        41,
        48,
        21,
        0,
        30,
        23,
        11,
        0,
        49,
        49,
        46,
        25,
        34,
        49,
        49,
        17,
        12,
        48
      ],
      "weeklyRejected": [
        45,
        29,
        15,
        22,
        0,
        6,
        9,
        23,
        39,
        28,
        49,
        28,
        0,
        48,
        38,
        13,
        41,
        49,
        49,
        49,
        45,
        18,
        1,
        16,
        12,
        49,
        32,
        47,
        23,
        29,
        38,
        29,
        48,
        0,
        0,
        49,
        24,
        20,
        1,
        22,
        48,
        37,
        45,
        6,
        45,
        36,
        24,
        49,
        10,
        26,
        0,
        41
      ],
      "weeklyHold": [
        30,
        38,
        20,
        18,
        15,
        7,
        9,
        19,
        21,
        40,
        30,
        24,
        1,
        1,
        27,
        32,
        19,
        14,
        31,
        49,
        38,
        8,
        11,
        0,
        22,
        48,
        49,
        49,
        49,
        24,
        44,
        4,
        0,
        27,
        48,
        14,
        22,
        16,
        10,
        32,
        20,
        32,
        11,
        15,
        44,
        49,
        5,
        32,
        6,
        17,
        35,
        30
      ],
      "weeklyBackordered": [
        0,
        8,
        0,
        26,
        0,
        27,
        10,
        2,
        22,
        8,
        47,
        49,
        14,
        45,
        47,
        3,
        49,
        22,
        49,
        0,
        40,
        3,
        12,
        44,
        30,
        28,
        23,
        49,
        13,
        36,
        15,
        6,
        45,
        24,
        23,
        13,
        47,
        42,
        49,
        36,
        40,
        40,
        6,
        49,
        27,
        0,
        49,
        16,
        18,
        30,
        1,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        4,
        27,
        9,
        47,
        49,
        0,
        33,
        49,
        48,
        28,
        37,
        0,
        21,
        0,
        30,
        49,
        30,
        48,
        4,
        10,
        49,
        49,
        12,
        38,
        27,
        25,
        23,
        29,
        1,
        39,
        0,
        20,
        12,
        39,
        42,
        25,
        27,
        17,
        46,
        32,
        49,
        13,
        28,
        3,
        49,
        23,
        0,
        20,
        4,
        14,
        38,
        36
      ],
      "weeklyRejected": [
        47,
        2,
        18,
        10,
        29,
        1,
        49,
        49,
        41,
        28,
        32,
        28,
        26,
        40,
        19,
        30,
        36,
        23,
        39,
        2,
        42,
        39,
        43,
        38,
        5,
        49,
        49,
        42,
        17,
        28,
        27,
        44,
        0,
        42,
        30,
        49,
        48,
        49,
        4,
        26,
        31,
        22,
        49,
        25,
        35,
        7,
        13,
        49,
        29,
        49,
        5,
        49
      ],
      "weeklyHold": [
        0,
        15,
        31,
        23,
        29,
        37,
        11,
        31,
        9,
        17,
        31,
        41,
        18,
        10,
        21,
        17,
        20,
        0,
        37,
        49,
        0,
        0,
        13,
        15,
        10,
        30,
        18,
        43,
        36,
        40,
        48,
        10,
        0,
        34,
        48,
        35,
        0,
        19,
        15,
        11,
        49,
        32,
        30,
        40,
        49,
        26,
        0,
        32,
        7,
        14,
        49,
        38
      ],
      "weeklyBackordered": [
        9,
        29,
        48,
        6,
        42,
        33,
        19,
        49,
        23,
        29,
        39,
        0,
        21,
        41,
        34,
        16,
        22,
        34,
        27,
        27,
        37,
        13,
        0,
        12,
        27,
        39,
        15,
        31,
        1,
        49,
        31,
        7,
        26,
        39,
        0,
        12,
        21,
        8,
        46,
        31,
        3,
        44,
        28,
        41,
        31,
        19,
        49,
        15,
        11,
        49,
        46,
        23
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        30,
        49,
        13,
        35,
        15,
        43,
        1,
        25,
        45,
        34,
        36,
        0,
        23,
        33,
        15,
        33,
        49,
        2,
        23,
        37,
        13,
        47,
        35,
        17,
        30,
        24,
        5,
        10,
        49,
        37,
        49,
        1,
        49,
        0,
        48,
        26,
        0,
        21,
        17,
        49,
        15,
        0,
        19,
        0,
        48,
        0,
        0,
        6,
        5,
        49,
        0,
        12
      ],
      "weeklyRejected": [
        37,
        17,
        49,
        26,
        37,
        15,
        37,
        44,
        49,
        1,
        25,
        15,
        49,
        48,
        20,
        23,
        49,
        45,
        25,
        37,
        0,
        16,
        0,
        32,
        12,
        49,
        31,
        17,
        44,
        0,
        0,
        5,
        48,
        49,
        49,
        49,
        33,
        38,
        1,
        47,
        6,
        36,
        26,
        0,
        49,
        25,
        49,
        19,
        22,
        49,
        0,
        49
      ],
      "weeklyHold": [
        29,
        13,
        39,
        14,
        24,
        19,
        35,
        2,
        33,
        24,
        0,
        31,
        48,
        39,
        33,
        0,
        30,
        0,
        0,
        49,
        28,
        37,
        12,
        32,
        34,
        48,
        19,
        27,
        49,
        0,
        34,
        0,
        0,
        37,
        0,
        24,
        14,
        10,
        46,
        43,
        11,
        49,
        9,
        49,
        26,
        14,
        45,
        49,
        0,
        34,
        22,
        0
      ],
      "weeklyBackordered": [
        49,
        47,
        19,
        19,
        30,
        37,
        31,
        45,
        49,
        1,
        5,
        47,
        48,
        36,
        49,
        42,
        18,
        39,
        0,
        41,
        25,
        30,
        9,
        19,
        1,
        24,
        2,
        49,
        44,
        49,
        0,
        12,
        11,
        31,
        0,
        0,
        30,
        0,
        0,
        49,
        18,
        37,
        0,
        49,
        0,
        43,
        38,
        7,
        0,
        32,
        49,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        0,
        49,
        32,
        35,
        23,
        0,
        49,
        49,
        35,
        0,
        49,
        27,
        49,
        35,
        49,
        26,
        49,
        0,
        8,
        49,
        2,
        29,
        49,
        21,
        1,
        47,
        0,
        7,
        9,
        21,
        12,
        49,
        49,
        0,
        27,
        14,
        27,
        35,
        13,
        45,
        24,
        38,
        49,
        49,
        28,
        0,
        0,
        49,
        0,
        49,
        29,
        35
      ],
      "weeklyRejected": [
        39,
        13,
        31,
        47,
        41,
        19,
        13,
        41,
        32,
        41,
        34,
        0,
        43,
        26,
        48,
        13,
        34,
        49,
        49,
        32,
        0,
        43,
        13,
        28,
        5,
        28,
        3,
        37,
        48,
        35,
        49,
        12,
        28,
        24,
        26,
        49,
        25,
        15,
        1,
        49,
        42,
        27,
        13,
        20,
        49,
        35,
        14,
        38,
        0,
        49,
        28,
        33
      ],
      "weeklyHold": [
        49,
        8,
        40,
        19,
        26,
        48,
        32,
        8,
        37,
        36,
        43,
        2,
        13,
        1,
        17,
        11,
        0,
        32,
        17,
        19,
        39,
        31,
        24,
        0,
        43,
        48,
        49,
        0,
        33,
        49,
        48,
        42,
        0,
        28,
        0,
        43,
        26,
        5,
        30,
        33,
        49,
        38,
        3,
        49,
        29,
        45,
        45,
        21,
        24,
        49,
        42,
        49
      ],
      "weeklyBackordered": [
        37,
        17,
        9,
        49,
        47,
        36,
        7,
        47,
        29,
        0,
        48,
        0,
        49,
        16,
        0,
        12,
        49,
        37,
        0,
        0,
        8,
        3,
        0,
        0,
        48,
        28,
        8,
        7,
        3,
        33,
        43,
        46,
        43,
        27,
        49,
        0,
        49,
        0,
        19,
        44,
        36,
        42,
        20,
        49,
        0,
        10,
        0,
        37,
        0,
        49,
        49,
        8
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        38,
        31,
        49,
        48,
        13,
        30,
        47,
        36,
        37,
        0,
        23,
        26,
        20,
        38,
        26,
        15,
        49,
        0,
        0,
        21,
        36,
        30,
        10,
        15,
        0,
        43,
        0,
        44,
        9,
        49,
        6,
        41,
        49,
        17,
        48,
        32,
        6,
        48,
        19,
        49,
        13,
        13,
        49,
        37,
        42,
        0,
        42,
        0,
        41,
        30,
        49,
        48
      ],
      "weeklyRejected": [
        39,
        19,
        49,
        32,
        39,
        24,
        18,
        45,
        30,
        1,
        30,
        23,
        29,
        18,
        46,
        2,
        21,
        49,
        31,
        24,
        3,
        42,
        4,
        29,
        1,
        39,
        49,
        19,
        48,
        0,
        34,
        38,
        7,
        34,
        0,
        30,
        20,
        5,
        10,
        0,
        20,
        34,
        29,
        9,
        43,
        9,
        24,
        7,
        0,
        44,
        10,
        49
      ],
      "weeklyHold": [
        49,
        23,
        34,
        20,
        15,
        37,
        14,
        12,
        0,
        30,
        47,
        45,
        26,
        3,
        11,
        13,
        16,
        48,
        0,
        20,
        0,
        0,
        0,
        27,
        30,
        24,
        20,
        0,
        25,
        0,
        36,
        2,
        6,
        49,
        21,
        31,
        31,
        0,
        1,
        21,
        4,
        38,
        0,
        30,
        35,
        49,
        46,
        33,
        17,
        5,
        39,
        37
      ],
      "weeklyBackordered": [
        38,
        41,
        41,
        34,
        33,
        46,
        35,
        19,
        43,
        4,
        32,
        49,
        15,
        0,
        16,
        36,
        11,
        49,
        9,
        32,
        49,
        43,
        3,
        9,
        7,
        33,
        35,
        23,
        22,
        49,
        24,
        0,
        39,
        18,
        0,
        0,
        8,
        0,
        41,
        28,
        31,
        29,
        0,
        49,
        0,
        22,
        32,
        10,
        0,
        49,
        26,
        32
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        11,
        49,
        19,
        23,
        49,
        14,
        1,
        25,
        48,
        9,
        10,
        18,
        14,
        47,
        20,
        16,
        49,
        10,
        49,
        0,
        0,
        30,
        46,
        26,
        2,
        29,
        16,
        0,
        49,
        49,
        49,
        29,
        49,
        0,
        34,
        4,
        28,
        21,
        17,
        49,
        24,
        22,
        47,
        29,
        21,
        3,
        3,
        2,
        0,
        0,
        49,
        44
      ],
      "weeklyRejected": [
        30,
        5,
        9,
        22,
        49,
        37,
        49,
        40,
        33,
        5,
        41,
        36,
        10,
        14,
        25,
        35,
        28,
        36,
        25,
        17,
        34,
        6,
        14,
        49,
        27,
        15,
        39,
        49,
        26,
        0,
        23,
        26,
        32,
        43,
        39,
        49,
        33,
        21,
        15,
        29,
        0,
        49,
        22,
        0,
        49,
        0,
        22,
        30,
        2,
        13,
        12,
        38
      ],
      "weeklyHold": [
        38,
        45,
        46,
        1,
        21,
        28,
        44,
        12,
        5,
        16,
        41,
        39,
        45,
        29,
        9,
        49,
        22,
        12,
        11,
        12,
        0,
        5,
        20,
        17,
        42,
        23,
        25,
        0,
        12,
        10,
        42,
        5,
        0,
        40,
        48,
        25,
        24,
        0,
        49,
        31,
        32,
        23,
        0,
        45,
        2,
        11,
        36,
        49,
        0,
        15,
        0,
        22
      ],
      "weeklyBackordered": [
        41,
        49,
        0,
        48,
        43,
        32,
        49,
        49,
        49,
        30,
        31,
        0,
        44,
        38,
        0,
        30,
        49,
        40,
        17,
        7,
        5,
        0,
        0,
        49,
        13,
        24,
        7,
        8,
        49,
        16,
        5,
        49,
        48,
        49,
        0,
        0,
        1,
        21,
        44,
        49,
        0,
        27,
        11,
        49,
        25,
        49,
        49,
        16,
        0,
        15,
        14,
        26
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        0,
        44,
        49,
        41,
        49,
        0,
        18,
        37,
        48,
        32,
        49,
        0,
        9,
        19,
        19,
        14,
        34,
        12,
        7,
        0,
        12,
        44,
        41,
        42,
        24,
        20,
        0,
        30,
        16,
        15,
        11,
        30,
        49,
        10,
        48,
        44,
        13,
        48,
        42,
        42,
        7,
        42,
        49,
        25,
        49,
        0,
        0,
        37,
        19,
        49,
        29,
        48
      ],
      "weeklyRejected": [
        31,
        23,
        49,
        17,
        49,
        26,
        12,
        49,
        30,
        41,
        13,
        0,
        27,
        48,
        48,
        0,
        49,
        38,
        49,
        46,
        28,
        41,
        5,
        44,
        12,
        49,
        49,
        49,
        33,
        10,
        11,
        23,
        20,
        49,
        9,
        44,
        49,
        35,
        1,
        45,
        33,
        18,
        21,
        6,
        49,
        27,
        49,
        11,
        29,
        49,
        0,
        49
      ],
      "weeklyHold": [
        42,
        22,
        49,
        5,
        3,
        36,
        18,
        2,
        0,
        18,
        42,
        8,
        29,
        18,
        30,
        33,
        0,
        31,
        2,
        7,
        37,
        39,
        0,
        11,
        5,
        38,
        0,
        32,
        38,
        0,
        31,
        0,
        13,
        37,
        20,
        32,
        30,
        31,
        39,
        49,
        23,
        24,
        0,
        31,
        49,
        39,
        49,
        0,
        0,
        43,
        47,
        26
      ],
      "weeklyBackordered": [
        41,
        23,
        1,
        30,
        2,
        15,
        0,
        47,
        25,
        8,
        14,
        49,
        34,
        27,
        29,
        24,
        48,
        10,
        14,
        0,
        49,
        27,
        3,
        46,
        23,
        1,
        28,
        25,
        20,
        49,
        12,
        36,
        32,
        49,
        0,
        29,
        15,
        0,
        44,
        31,
        8,
        46,
        9,
        45,
        29,
        12,
        49,
        27,
        49,
        49,
        23,
        29
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        22,
        25,
        9,
        24,
        2,
        0,
        27,
        49,
        45,
        24,
        6,
        0,
        0,
        36,
        15,
        1,
        49,
        48,
        9,
        49,
        45,
        22,
        28,
        34,
        13,
        47,
        49,
        18,
        31,
        24,
        14,
        28,
        42,
        0,
        32,
        28,
        45,
        48,
        0,
        38,
        16,
        24,
        49,
        49,
        11,
        0,
        2,
        49,
        23,
        0,
        21,
        31
      ],
      "weeklyRejected": [
        45,
        28,
        33,
        14,
        43,
        45,
        49,
        21,
        41,
        1,
        26,
        0,
        36,
        15,
        34,
        0,
        0,
        30,
        49,
        7,
        0,
        34,
        0,
        49,
        5,
        38,
        49,
        49,
        48,
        48,
        26,
        32,
        36,
        0,
        44,
        46,
        49,
        25,
        33,
        49,
        30,
        49,
        9,
        2,
        39,
        18,
        0,
        36,
        20,
        48,
        0,
        30
      ],
      "weeklyHold": [
        27,
        33,
        40,
        31,
        49,
        38,
        7,
        2,
        21,
        34,
        47,
        5,
        1,
        49,
        49,
        19,
        26,
        16,
        0,
        49,
        16,
        0,
        49,
        0,
        7,
        48,
        0,
        13,
        49,
        49,
        48,
        14,
        0,
        32,
        45,
        33,
        49,
        0,
        4,
        0,
        33,
        29,
        0,
        19,
        19,
        39,
        35,
        15,
        16,
        49,
        34,
        49
      ],
      "weeklyBackordered": [
        15,
        3,
        23,
        1,
        45,
        46,
        19,
        47,
        39,
        49,
        4,
        12,
        22,
        35,
        0,
        20,
        49,
        46,
        0,
        35,
        38,
        36,
        12,
        32,
        5,
        40,
        1,
        49,
        14,
        38,
        49,
        28,
        34,
        38,
        0,
        0,
        49,
        14,
        6,
        44,
        32,
        44,
        14,
        33,
        32,
        28,
        32,
        14,
        26,
        34,
        24,
        31
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyAccepted": [
        4,
        18,
        13,
        20,
        15,
        14,
        38,
        25,
        25,
        49,
        46,
        19,
        33,
        16,
        26,
        42,
        6,
        31,
        27,
        27,
        27,
        0,
        15,
        44,
        29,
        35,
        13,
        32,
        4,
        49,
        41,
        5,
        34,
        44,
        45,
        36,
        47,
        2,
        49,
        49,
        40,
        9,
        6,
        38,
        49,
        36,
        0,
        49,
        4,
        31,
        45,
        20
      ],
      "weeklyRejected": [
        49,
        13,
        43,
        36,
        49,
        22,
        47,
        21,
        0,
        1,
        16,
        19,
        24,
        48,
        36,
        0,
        41,
        35,
        7,
        31,
        25,
        49,
        22,
        49,
        17,
        27,
        31,
        41,
        26,
        20,
        18,
        25,
        48,
        27,
        29,
        40,
        28,
        30,
        23,
        34,
        48,
        49,
        30,
        32,
        49,
        30,
        33,
        20,
        30,
        17,
        10,
        15
      ],
      "weeklyHold": [
        36,
        49,
        44,
        20,
        49,
        44,
        26,
        5,
        44,
        49,
        47,
        30,
        44,
        1,
        49,
        26,
        19,
        48,
        16,
        0,
        8,
        45,
        2,
        7,
        41,
        43,
        26,
        0,
        32,
        0,
        36,
        25,
        33,
        5,
        48,
        5,
        19,
        0,
        44,
        21,
        49,
        49,
        19,
        0,
        29,
        22,
        28,
        16,
        20,
        18,
        49,
        49
      ],
      "weeklyBackordered": [
        19,
        30,
        24,
        22,
        44,
        46,
        13,
        45,
        49,
        49,
        36,
        31,
        18,
        49,
        33,
        11,
        1,
        49,
        10,
        32,
        0,
        31,
        36,
        40,
        21,
        22,
        29,
        7,
        1,
        49,
        18,
        49,
        18,
        49,
        0,
        30,
        11,
        5,
        0,
        18,
        40,
        47,
        14,
        49,
        0,
        15,
        7,
        19,
        0,
        15,
        34,
        27
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        0,
        41,
        12,
        48,
        49,
        13,
        1,
        15,
        43,
        49,
        49,
        0,
        15,
        49,
        8,
        7,
        49,
        14,
        0,
        26,
        13,
        33,
        6,
        22,
        31,
        38,
        22,
        0,
        10,
        49,
        25,
        0,
        19,
        26,
        10,
        12,
        47,
        48,
        29,
        20,
        32,
        37,
        49,
        49,
        49,
        27,
        24,
        20,
        11,
        36,
        1,
        48
      ],
      "weeklyRejected": [
        49,
        32,
        14,
        16,
        34,
        28,
        5,
        39,
        49,
        48,
        40,
        38,
        37,
        28,
        48,
        20,
        33,
        29,
        0,
        39,
        11,
        34,
        37,
        9,
        46,
        17,
        33,
        37,
        36,
        0,
        34,
        25,
        35,
        22,
        35,
        29,
        0,
        8,
        44,
        49,
        44,
        14,
        19,
        4,
        22,
        12,
        45,
        35,
        27,
        15,
        49,
        18
      ],
      "weeklyHold": [
        30,
        15,
        42,
        13,
        49,
        23,
        31,
        36,
        39,
        49,
        47,
        19,
        48,
        30,
        49,
        45,
        17,
        2,
        39,
        12,
        22,
        25,
        49,
        16,
        3,
        48,
        14,
        37,
        19,
        31,
        33,
        26,
        10,
        37,
        39,
        30,
        35,
        0,
        26,
        37,
        17,
        11,
        25,
        26,
        23,
        33,
        18,
        2,
        12,
        49,
        15,
        32
      ],
      "weeklyBackordered": [
        0,
        49,
        46,
        10,
        37,
        26,
        22,
        49,
        42,
        41,
        34,
        23,
        29,
        22,
        0,
        49,
        49,
        49,
        30,
        20,
        40,
        10,
        0,
        49,
        1,
        30,
        32,
        5,
        49,
        49,
        32,
        48,
        35,
        44,
        0,
        0,
        14,
        8,
        11,
        24,
        24,
        48,
        8,
        29,
        37,
        11,
        16,
        5,
        39,
        32,
        3,
        43
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        40,
        25,
        28,
        24,
        32,
        20,
        22,
        46,
        18,
        47,
        47,
        22,
        27,
        37,
        28,
        48,
        49,
        26,
        44,
        36,
        19,
        33,
        49,
        7,
        0,
        14,
        0,
        49,
        1,
        26,
        48,
        17,
        33,
        26,
        18,
        38,
        25,
        21,
        49,
        49,
        19,
        10,
        47,
        1,
        23,
        0,
        0,
        4,
        0,
        26,
        44,
        0
      ],
      "weeklyRejected": [
        44,
        20,
        18,
        26,
        15,
        27,
        42,
        49,
        49,
        1,
        25,
        49,
        26,
        0,
        15,
        0,
        21,
        49,
        18,
        42,
        23,
        35,
        12,
        15,
        7,
        49,
        39,
        23,
        47,
        0,
        49,
        14,
        37,
        49,
        48,
        49,
        46,
        18,
        1,
        13,
        48,
        19,
        42,
        14,
        43,
        0,
        0,
        16,
        11,
        17,
        19,
        40
      ],
      "weeklyHold": [
        0,
        25,
        47,
        19,
        0,
        7,
        45,
        2,
        0,
        24,
        47,
        16,
        42,
        33,
        49,
        16,
        32,
        28,
        34,
        24,
        41,
        0,
        30,
        15,
        5,
        35,
        0,
        0,
        44,
        0,
        1,
        1,
        25,
        16,
        48,
        15,
        12,
        0,
        30,
        0,
        9,
        49,
        0,
        4,
        27,
        39,
        0,
        34,
        0,
        0,
        40,
        6
      ],
      "weeklyBackordered": [
        0,
        0,
        21,
        10,
        32,
        46,
        39,
        49,
        34,
        9,
        43,
        49,
        3,
        18,
        48,
        38,
        19,
        48,
        0,
        48,
        32,
        0,
        0,
        0,
        1,
        24,
        49,
        22,
        25,
        49,
        37,
        28,
        0,
        0,
        0,
        0,
        1,
        8,
        2,
        0,
        9,
        41,
        31,
        1,
        26,
        49,
        23,
        5,
        0,
        35,
        40,
        1
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        2,
        10,
        17,
        31,
        27,
        0,
        17,
        42,
        27,
        26,
        29,
        8,
        4,
        8,
        34,
        49,
        25,
        47,
        0,
        17,
        17,
        29,
        19,
        49,
        1,
        27,
        15,
        26,
        12,
        25,
        49,
        16,
        47,
        5,
        29,
        20,
        40,
        46,
        42,
        49,
        40,
        44,
        21,
        46,
        47,
        0,
        0,
        36,
        17,
        15,
        0,
        10
      ],
      "weeklyRejected": [
        49,
        13,
        49,
        47,
        27,
        16,
        18,
        12,
        33,
        11,
        34,
        25,
        40,
        41,
        6,
        48,
        0,
        35,
        49,
        47,
        24,
        49,
        0,
        30,
        12,
        49,
        6,
        22,
        48,
        6,
        49,
        32,
        48,
        34,
        22,
        49,
        24,
        28,
        9,
        14,
        45,
        24,
        22,
        5,
        44,
        5,
        0,
        49,
        2,
        4,
        0,
        36
      ],
      "weeklyHold": [
        42,
        32,
        26,
        26,
        32,
        17,
        49,
        41,
        10,
        2,
        6,
        49,
        43,
        22,
        17,
        14,
        20,
        0,
        2,
        9,
        26,
        40,
        0,
        36,
        21,
        15,
        32,
        36,
        49,
        3,
        44,
        6,
        0,
        49,
        48,
        0,
        28,
        39,
        49,
        8,
        32,
        49,
        49,
        0,
        34,
        49,
        38,
        38,
        0,
        7,
        49,
        13
      ],
      "weeklyBackordered": [
        23,
        49,
        25,
        39,
        37,
        7,
        29,
        33,
        20,
        27,
        49,
        20,
        29,
        20,
        31,
        23,
        26,
        48,
        33,
        49,
        16,
        36,
        27,
        6,
        28,
        4,
        1,
        0,
        37,
        33,
        25,
        49,
        20,
        49,
        4,
        12,
        33,
        11,
        1,
        37,
        27,
        48,
        18,
        49,
        31,
        40,
        30,
        16,
        23,
        16,
        21,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        0,
        32,
        49,
        0,
        34,
        45,
        38,
        41,
        32,
        47,
        0,
        0,
        49,
        30,
        27,
        0,
        49,
        0,
        33,
        16,
        39,
        27,
        29,
        27,
        0,
        43,
        0,
        16,
        1,
        49,
        19,
        48,
        36,
        46,
        34,
        37,
        20,
        9,
        28,
        22,
        7,
        24,
        25,
        40,
        21,
        0,
        37,
        5,
        18,
        0,
        37,
        30
      ],
      "weeklyRejected": [
        49,
        14,
        7,
        20,
        19,
        9,
        28,
        33,
        26,
        1,
        0,
        30,
        34,
        48,
        48,
        7,
        0,
        39,
        49,
        37,
        25,
        0,
        35,
        49,
        21,
        23,
        41,
        38,
        37,
        29,
        0,
        13,
        42,
        10,
        27,
        29,
        25,
        22,
        1,
        23,
        48,
        29,
        10,
        40,
        38,
        40,
        0,
        49,
        10,
        43,
        0,
        46
      ],
      "weeklyHold": [
        18,
        0,
        9,
        14,
        48,
        23,
        36,
        2,
        19,
        49,
        42,
        38,
        10,
        36,
        34,
        2,
        49,
        11,
        49,
        1,
        16,
        18,
        18,
        40,
        26,
        48,
        1,
        0,
        49,
        33,
        41,
        0,
        0,
        17,
        42,
        22,
        32,
        0,
        49,
        18,
        15,
        36,
        0,
        0,
        15,
        23,
        35,
        0,
        14,
        0,
        34,
        12
      ],
      "weeklyBackordered": [
        31,
        44,
        0,
        47,
        27,
        38,
        28,
        44,
        46,
        10,
        49,
        13,
        3,
        0,
        44,
        18,
        26,
        16,
        5,
        18,
        47,
        32,
        11,
        35,
        5,
        33,
        27,
        0,
        9,
        34,
        41,
        22,
        13,
        21,
        0,
        16,
        30,
        0,
        42,
        49,
        34,
        48,
        47,
        49,
        16,
        34,
        0,
        16,
        0,
        42,
        49,
        26
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyAccepted": [
        21,
        3,
        20,
        48,
        1,
        9,
        31,
        36,
        25,
        37,
        17,
        5,
        0,
        49,
        4,
        0,
        49,
        0,
        7,
        0,
        31,
        32,
        49,
        34,
        25,
        29,
        9,
        4,
        22,
        32,
        21,
        34,
        35,
        11,
        40,
        48,
        25,
        48,
        0,
        46,
        32,
        49,
        13,
        12,
        22,
        8,
        0,
        9,
        0,
        39,
        34,
        30
      ],
      "weeklyRejected": [
        35,
        19,
        34,
        26,
        12,
        6,
        0,
        3,
        37,
        27,
        17,
        37,
        22,
        48,
        39,
        18,
        31,
        19,
        29,
        1,
        45,
        49,
        27,
        49,
        31,
        49,
        49,
        37,
        42,
        0,
        30,
        47,
        48,
        20,
        26,
        21,
        49,
        49,
        1,
        16,
        40,
        33,
        49,
        0,
        49,
        35,
        8,
        29,
        0,
        12,
        0,
        37
      ],
      "weeklyHold": [
        49,
        11,
        9,
        23,
        0,
        38,
        1,
        2,
        26,
        49,
        42,
        30,
        25,
        5,
        49,
        37,
        15,
        21,
        25,
        49,
        39,
        0,
        31,
        2,
        26,
        47,
        49,
        12,
        35,
        0,
        21,
        24,
        34,
        20,
        46,
        14,
        49,
        0,
        42,
        27,
        39,
        23,
        0,
        49,
        46,
        20,
        38,
        43,
        13,
        29,
        10,
        0
      ],
      "weeklyBackordered": [
        49,
        49,
        29,
        49,
        40,
        19,
        9,
        5,
        36,
        20,
        32,
        49,
        11,
        17,
        24,
        16,
        32,
        42,
        0,
        16,
        22,
        1,
        0,
        37,
        11,
        12,
        42,
        3,
        17,
        49,
        31,
        49,
        48,
        49,
        10,
        0,
        2,
        36,
        0,
        0,
        48,
        32,
        36,
        49,
        24,
        21,
        37,
        12,
        29,
        49,
        37,
        8
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyAccepted": [
        3,
        42,
        49,
        48,
        17,
        26,
        14,
        37,
        18,
        26,
        37,
        10,
        41,
        32,
        17,
        16,
        49,
        5,
        0,
        32,
        10,
        36,
        3,
        36,
        14,
        7,
        0,
        0,
        1,
        20,
        30,
        10,
        8,
        39,
        48,
        23,
        28,
        21,
        17,
        49,
        31,
        42,
        28,
        10,
        2,
        46,
        4,
        37,
        23,
        49,
        29,
        19
      ],
      "weeklyRejected": [
        28,
        14,
        39,
        41,
        30,
        13,
        0,
        43,
        49,
        8,
        27,
        29,
        17,
        26,
        25,
        14,
        4,
        27,
        30,
        36,
        40,
        21,
        3,
        6,
        8,
        49,
        18,
        21,
        9,
        0,
        0,
        28,
        42,
        8,
        31,
        49,
        0,
        36,
        6,
        49,
        48,
        20,
        29,
        17,
        45,
        29,
        0,
        32,
        25,
        12,
        9,
        13
      ],
      "weeklyHold": [
        27,
        37,
        49,
        1,
        41,
        38,
        12,
        13,
        41,
        20,
        47,
        35,
        48,
        12,
        0,
        26,
        13,
        19,
        0,
        28,
        26,
        2,
        0,
        15,
        15,
        41,
        8,
        28,
        17,
        0,
        38,
        0,
        15,
        49,
        48,
        49,
        11,
        2,
        38,
        25,
        0,
        35,
        16,
        49,
        23,
        46,
        13,
        25,
        0,
        37,
        24,
        32
      ],
      "weeklyBackordered": [
        37,
        42,
        2,
        0,
        49,
        39,
        49,
        44,
        26,
        0,
        27,
        22,
        6,
        13,
        0,
        24,
        19,
        49,
        3,
        21,
        28,
        2,
        45,
        47,
        37,
        12,
        46,
        49,
        39,
        49,
        49,
        34,
        0,
        28,
        0,
        0,
        23,
        18,
        26,
        6,
        17,
        41,
        44,
        49,
        47,
        0,
        16,
        34,
        24,
        16,
        49,
        21
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        0,
        49,
        3,
        14,
        34,
        13,
        44,
        49,
        36,
        15,
        49,
        14,
        14,
        0,
        37,
        21,
        34,
        45,
        0,
        36,
        37,
        19,
        14,
        49,
        0,
        47,
        40,
        48,
        1,
        49,
        8,
        49,
        34,
        3,
        38,
        49,
        44,
        48,
        49,
        49,
        25,
        0,
        49,
        23,
        49,
        0,
        24,
        49,
        5,
        49,
        29,
        43
      ],
      "weeklyRejected": [
        35,
        23,
        49,
        27,
        27,
        35,
        43,
        44,
        42,
        28,
        0,
        41,
        25,
        48,
        28,
        6,
        0,
        37,
        40,
        16,
        35,
        36,
        17,
        49,
        3,
        28,
        42,
        33,
        45,
        13,
        49,
        31,
        47,
        35,
        37,
        49,
        14,
        48,
        23,
        49,
        36,
        0,
        38,
        32,
        37,
        30,
        27,
        49,
        6,
        49,
        0,
        23
      ],
      "weeklyHold": [
        49,
        14,
        19,
        16,
        15,
        17,
        24,
        39,
        20,
        27,
        47,
        2,
        24,
        44,
        19,
        19,
        10,
        19,
        45,
        19,
        0,
        30,
        18,
        0,
        24,
        26,
        16,
        6,
        49,
        22,
        16,
        27,
        22,
        45,
        29,
        30,
        12,
        1,
        25,
        18,
        34,
        33,
        0,
        0,
        29,
        49,
        47,
        49,
        36,
        49,
        49,
        37
      ],
      "weeklyBackordered": [
        11,
        29,
        28,
        15,
        42,
        37,
        22,
        9,
        35,
        19,
        25,
        28,
        6,
        7,
        37,
        31,
        46,
        36,
        3,
        8,
        42,
        21,
        34,
        36,
        46,
        30,
        21,
        28,
        2,
        49,
        24,
        49,
        48,
        16,
        14,
        13,
        36,
        0,
        29,
        14,
        0,
        48,
        19,
        27,
        37,
        16,
        49,
        28,
        43,
        49,
        34,
        29
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        2,
        49,
        2,
        42,
        9,
        19,
        39,
        49,
        22,
        41,
        43,
        1,
        40,
        38,
        1,
        6,
        47,
        1,
        23,
        22,
        30,
        0,
        49,
        23,
        23,
        47,
        21,
        49,
        30,
        31,
        16,
        19,
        49,
        8,
        43,
        47,
        12,
        8,
        15,
        49,
        4,
        11,
        49,
        30,
        37,
        1,
        49,
        18,
        17,
        44,
        15,
        0
      ],
      "weeklyRejected": [
        38,
        49,
        20,
        47,
        29,
        34,
        0,
        39,
        32,
        45,
        15,
        46,
        0,
        41,
        48,
        0,
        24,
        49,
        43,
        49,
        0,
        23,
        34,
        0,
        19,
        49,
        31,
        25,
        47,
        25,
        42,
        27,
        48,
        49,
        14,
        49,
        49,
        6,
        1,
        10,
        43,
        48,
        49,
        0,
        45,
        49,
        0,
        17,
        0,
        33,
        15,
        43
      ],
      "weeklyHold": [
        43,
        35,
        18,
        1,
        0,
        0,
        42,
        2,
        0,
        37,
        38,
        32,
        24,
        17,
        45,
        46,
        6,
        47,
        0,
        11,
        49,
        40,
        0,
        14,
        49,
        22,
        0,
        37,
        49,
        0,
        48,
        5,
        0,
        39,
        31,
        8,
        49,
        0,
        32,
        44,
        0,
        30,
        1,
        4,
        15,
        49,
        40,
        49,
        19,
        39,
        30,
        38
      ],
      "weeklyBackordered": [
        0,
        18,
        20,
        19,
        47,
        9,
        21,
        49,
        25,
        0,
        9,
        49,
        49,
        0,
        40,
        19,
        22,
        24,
        16,
        7,
        34,
        38,
        31,
        32,
        26,
        34,
        14,
        43,
        18,
        49,
        31,
        10,
        41,
        43,
        19,
        0,
        1,
        0,
        40,
        16,
        16,
        48,
        13,
        49,
        25,
        18,
        49,
        31,
        18,
        49,
        31,
        16
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        7,
        22,
        21,
        44,
        27,
        0,
        32,
        41,
        36,
        0,
        30,
        0,
        17,
        30,
        24,
        28,
        49,
        35,
        0,
        38,
        33,
        26,
        34,
        46,
        13,
        37,
        0,
        36,
        44,
        49,
        3,
        13,
        44,
        42,
        38,
        32,
        6,
        19,
        49,
        49,
        28,
        0,
        49,
        39,
        13,
        12,
        0,
        33,
        7,
        25,
        13,
        39
      ],
      "weeklyRejected": [
        39,
        7,
        37,
        31,
        22,
        1,
        49,
        49,
        34,
        38,
        8,
        0,
        18,
        48,
        48,
        4,
        8,
        30,
        49,
        49,
        49,
        18,
        38,
        3,
        3,
        49,
        19,
        25,
        48,
        29,
        21,
        4,
        26,
        49,
        0,
        49,
        49,
        32,
        5,
        18,
        48,
        0,
        49,
        31,
        33,
        13,
        42,
        15,
        0,
        48,
        13,
        31
      ],
      "weeklyHold": [
        36,
        25,
        44,
        1,
        49,
        22,
        49,
        2,
        15,
        33,
        42,
        15,
        1,
        32,
        19,
        28,
        49,
        25,
        1,
        17,
        13,
        21,
        3,
        6,
        34,
        8,
        5,
        23,
        49,
        0,
        48,
        8,
        7,
        1,
        30,
        49,
        49,
        0,
        33,
        12,
        5,
        26,
        6,
        14,
        48,
        38,
        44,
        30,
        0,
        24,
        39,
        26
      ],
      "weeklyBackordered": [
        28,
        49,
        20,
        49,
        47,
        33,
        8,
        49,
        16,
        3,
        37,
        49,
        3,
        20,
        48,
        43,
        20,
        48,
        9,
        34,
        27,
        20,
        16,
        41,
        1,
        25,
        45,
        1,
        22,
        41,
        13,
        16,
        0,
        0,
        0,
        0,
        1,
        10,
        36,
        49,
        19,
        48,
        0,
        49,
        20,
        0,
        33,
        33,
        24,
        49,
        45,
        15
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        42,
        24,
        14,
        0,
        8,
        0,
        18,
        49,
        46,
        10,
        17,
        15,
        45,
        31,
        42,
        42,
        35,
        24,
        15,
        2,
        34,
        48,
        40,
        1,
        29,
        47,
        0,
        46,
        1,
        1,
        21,
        4,
        34,
        28,
        14,
        6,
        12,
        22,
        14,
        49,
        41,
        44,
        43,
        26,
        12,
        30,
        0,
        26,
        3,
        44,
        0,
        1
      ],
      "weeklyRejected": [
        21,
        49,
        29,
        47,
        0,
        16,
        49,
        16,
        49,
        9,
        10,
        40,
        1,
        20,
        48,
        13,
        39,
        41,
        49,
        10,
        5,
        2,
        13,
        11,
        23,
        46,
        37,
        29,
        37,
        42,
        2,
        32,
        35,
        49,
        39,
        20,
        24,
        48,
        35,
        13,
        26,
        47,
        47,
        11,
        49,
        41,
        16,
        21,
        0,
        45,
        42,
        49
      ],
      "weeklyHold": [
        40,
        0,
        37,
        1,
        14,
        38,
        0,
        18,
        23,
        21,
        12,
        29,
        19,
        18,
        49,
        20,
        0,
        0,
        49,
        19,
        25,
        31,
        30,
        49,
        0,
        36,
        24,
        11,
        26,
        2,
        31,
        40,
        0,
        49,
        29,
        27,
        25,
        30,
        19,
        33,
        49,
        40,
        15,
        49,
        33,
        18,
        32,
        31,
        8,
        33,
        16,
        0
      ],
      "weeklyBackordered": [
        30,
        2,
        0,
        49,
        0,
        37,
        33,
        43,
        0,
        10,
        49,
        26,
        34,
        39,
        31,
        49,
        29,
        45,
        0,
        28,
        27,
        0,
        9,
        12,
        20,
        32,
        1,
        2,
        49,
        33,
        34,
        49,
        38,
        47,
        31,
        0,
        44,
        12,
        0,
        10,
        40,
        48,
        0,
        49,
        19,
        43,
        49,
        0,
        9,
        49,
        49,
        48
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        16,
        40,
        23,
        25,
        1,
        3,
        26,
        49,
        6,
        10,
        9,
        0,
        8,
        47,
        8,
        18,
        37,
        6,
        0,
        36,
        25,
        37,
        49,
        0,
        0,
        14,
        13,
        49,
        26,
        46,
        0,
        49,
        49,
        24,
        48,
        49,
        35,
        28,
        5,
        22,
        15,
        22,
        47,
        17,
        13,
        28,
        44,
        3,
        8,
        49,
        18,
        41
      ],
      "weeklyRejected": [
        24,
        14,
        49,
        32,
        26,
        13,
        49,
        13,
        43,
        19,
        18,
        6,
        19,
        48,
        48,
        34,
        49,
        49,
        49,
        15,
        29,
        42,
        14,
        27,
        24,
        49,
        22,
        43,
        48,
        23,
        9,
        30,
        10,
        0,
        49,
        49,
        30,
        8,
        16,
        37,
        48,
        34,
        15,
        25,
        49,
        46,
        1,
        28,
        27,
        44,
        0,
        34
      ],
      "weeklyHold": [
        6,
        22,
        0,
        20,
        40,
        19,
        6,
        7,
        35,
        49,
        29,
        24,
        43,
        22,
        44,
        11,
        0,
        6,
        0,
        25,
        29,
        34,
        29,
        23,
        26,
        38,
        49,
        28,
        49,
        49,
        48,
        0,
        26,
        48,
        48,
        13,
        35,
        2,
        39,
        38,
        26,
        31,
        5,
        2,
        48,
        49,
        49,
        24,
        25,
        48,
        5,
        0
      ],
      "weeklyBackordered": [
        39,
        2,
        26,
        3,
        38,
        28,
        16,
        5,
        18,
        17,
        45,
        20,
        49,
        0,
        43,
        18,
        49,
        12,
        12,
        28,
        23,
        48,
        31,
        49,
        26,
        19,
        26,
        49,
        1,
        49,
        38,
        32,
        31,
        39,
        6,
        0,
        21,
        8,
        45,
        14,
        35,
        21,
        19,
        49,
        27,
        36,
        49,
        32,
        27,
        49,
        35,
        46
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyAccepted": [
        43,
        38,
        32,
        19,
        31,
        11,
        1,
        49,
        48,
        41,
        11,
        0,
        19,
        42,
        11,
        26,
        29,
        10,
        22,
        21,
        27,
        30,
        27,
        39,
        19,
        42,
        46,
        0,
        6,
        49,
        0,
        0,
        49,
        0,
        16,
        35,
        19,
        33,
        15,
        49,
        21,
        0,
        1,
        25,
        27,
        0,
        0,
        22,
        15,
        32,
        8,
        1
      ],
      "weeklyRejected": [
        22,
        4,
        29,
        47,
        29,
        25,
        37,
        25,
        49,
        17,
        9,
        35,
        16,
        18,
        46,
        49,
        29,
        49,
        36,
        0,
        3,
        29,
        41,
        30,
        35,
        49,
        13,
        26,
        35,
        28,
        0,
        33,
        11,
        26,
        41,
        41,
        49,
        41,
        5,
        49,
        41,
        49,
        34,
        40,
        49,
        35,
        22,
        13,
        10,
        26,
        3,
        30
      ],
      "weeklyHold": [
        21,
        0,
        24,
        1,
        45,
        48,
        23,
        30,
        19,
        19,
        41,
        16,
        10,
        38,
        31,
        40,
        8,
        48,
        46,
        0,
        17,
        49,
        2,
        22,
        31,
        41,
        0,
        18,
        38,
        27,
        17,
        3,
        0,
        32,
        16,
        23,
        4,
        8,
        49,
        49,
        30,
        18,
        0,
        17,
        22,
        39,
        34,
        39,
        4,
        35,
        33,
        1
      ],
      "weeklyBackordered": [
        24,
        28,
        0,
        11,
        40,
        46,
        41,
        46,
        0,
        9,
        32,
        37,
        49,
        39,
        19,
        49,
        19,
        39,
        29,
        10,
        9,
        31,
        25,
        6,
        1,
        49,
        25,
        10,
        46,
        41,
        0,
        37,
        24,
        35,
        19,
        0,
        1,
        15,
        5,
        30,
        13,
        48,
        25,
        39,
        6,
        30,
        49,
        8,
        14,
        13,
        49,
        3
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        9,
        32,
        44,
        48,
        11,
        11,
        3,
        18,
        6,
        2,
        30,
        0,
        31,
        22,
        1,
        30,
        49,
        38,
        0,
        34,
        4,
        49,
        17,
        0,
        18,
        38,
        49,
        15,
        1,
        34,
        49,
        36,
        9,
        0,
        48,
        2,
        16,
        48,
        38,
        46,
        0,
        32,
        49,
        0,
        27,
        41,
        0,
        49,
        0,
        35,
        49,
        12
      ],
      "weeklyRejected": [
        49,
        10,
        49,
        33,
        49,
        18,
        49,
        49,
        46,
        1,
        9,
        26,
        28,
        9,
        48,
        38,
        49,
        49,
        26,
        31,
        2,
        16,
        11,
        36,
        13,
        34,
        18,
        24,
        41,
        4,
        0,
        0,
        34,
        33,
        39,
        49,
        23,
        0,
        25,
        26,
        48,
        24,
        32,
        34,
        30,
        0,
        0,
        46,
        0,
        49,
        22,
        49
      ],
      "weeklyHold": [
        17,
        0,
        36,
        26,
        16,
        47,
        14,
        2,
        2,
        37,
        43,
        16,
        48,
        17,
        3,
        42,
        14,
        37,
        0,
        22,
        36,
        9,
        0,
        39,
        11,
        21,
        4,
        2,
        49,
        0,
        48,
        27,
        0,
        49,
        24,
        47,
        23,
        0,
        21,
        0,
        0,
        19,
        19,
        40,
        29,
        25,
        0,
        47,
        5,
        49,
        16,
        23
      ],
      "weeklyBackordered": [
        49,
        49,
        2,
        32,
        35,
        44,
        0,
        20,
        23,
        12,
        27,
        28,
        3,
        49,
        21,
        27,
        49,
        43,
        13,
        46,
        47,
        0,
        18,
        4,
        22,
        18,
        26,
        25,
        3,
        41,
        37,
        26,
        31,
        37,
        0,
        11,
        21,
        28,
        22,
        12,
        38,
        41,
        12,
        49,
        2,
        36,
        49,
        49,
        0,
        18,
        45,
        16
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        0,
        9,
        32,
        23,
        21,
        25,
        33,
        49,
        48,
        18,
        39,
        0,
        0,
        0,
        28,
        21,
        6,
        17,
        0,
        43,
        49,
        42,
        48,
        45,
        32,
        36,
        49,
        25,
        1,
        30,
        0,
        23,
        49,
        21,
        24,
        26,
        26,
        7,
        29,
        49,
        0,
        37,
        49,
        29,
        37,
        32,
        36,
        0,
        25,
        39,
        6,
        36
      ],
      "weeklyRejected": [
        49,
        22,
        34,
        31,
        22,
        25,
        8,
        37,
        32,
        39,
        49,
        23,
        37,
        48,
        37,
        0,
        49,
        49,
        29,
        24,
        49,
        31,
        49,
        49,
        35,
        47,
        49,
        33,
        21,
        20,
        6,
        47,
        0,
        47,
        45,
        48,
        49,
        32,
        1,
        19,
        48,
        24,
        16,
        6,
        40,
        43,
        0,
        24,
        26,
        49,
        6,
        27
      ],
      "weeklyHold": [
        43,
        20,
        49,
        20,
        49,
        30,
        6,
        27,
        4,
        46,
        31,
        28,
        39,
        40,
        4,
        8,
        0,
        29,
        0,
        12,
        17,
        36,
        38,
        47,
        0,
        27,
        0,
        31,
        32,
        15,
        48,
        21,
        0,
        22,
        30,
        49,
        10,
        28,
        15,
        14,
        42,
        28,
        45,
        48,
        49,
        27,
        27,
        0,
        4,
        12,
        26,
        49
      ],
      "weeklyBackordered": [
        49,
        16,
        30,
        49,
        33,
        39,
        0,
        19,
        17,
        49,
        49,
        48,
        16,
        21,
        18,
        15,
        3,
        49,
        45,
        4,
        37,
        37,
        32,
        1,
        20,
        49,
        23,
        46,
        15,
        1,
        41,
        40,
        28,
        43,
        9,
        0,
        49,
        1,
        37,
        45,
        9,
        40,
        10,
        30,
        36,
        17,
        16,
        31,
        40,
        42,
        49,
        15
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        0,
        44,
        0,
        27,
        25,
        0,
        8,
        45,
        46,
        22,
        13,
        0,
        37,
        30,
        8,
        26,
        41,
        0,
        0,
        31,
        1,
        25,
        27,
        0,
        1,
        23,
        47,
        15,
        19,
        49,
        26,
        0,
        22,
        0,
        37,
        48,
        15,
        30,
        49,
        49,
        0,
        31,
        10,
        46,
        49,
        0,
        9,
        49,
        4,
        44,
        22,
        45
      ],
      "weeklyRejected": [
        49,
        0,
        42,
        0,
        49,
        3,
        5,
        49,
        49,
        18,
        49,
        27,
        0,
        14,
        32,
        31,
        40,
        26,
        44,
        11,
        49,
        49,
        15,
        44,
        0,
        49,
        40,
        38,
        0,
        26,
        30,
        47,
        48,
        38,
        26,
        49,
        49,
        14,
        20,
        11,
        37,
        38,
        34,
        1,
        36,
        24,
        12,
        49,
        7,
        0,
        0,
        48
      ],
      "weeklyHold": [
        0,
        2,
        38,
        32,
        12,
        48,
        5,
        33,
        0,
        9,
        21,
        49,
        48,
        40,
        38,
        8,
        45,
        0,
        24,
        16,
        16,
        0,
        36,
        49,
        11,
        10,
        29,
        14,
        49,
        1,
        48,
        31,
        0,
        37,
        14,
        0,
        0,
        1,
        37,
        0,
        3,
        17,
        11,
        20,
        39,
        49,
        17,
        21,
        0,
        28,
        8,
        45
      ],
      "weeklyBackordered": [
        0,
        40,
        36,
        33,
        13,
        0,
        19,
        39,
        49,
        39,
        49,
        49,
        34,
        49,
        29,
        49,
        44,
        40,
        0,
        49,
        20,
        11,
        0,
        19,
        1,
        32,
        1,
        17,
        1,
        34,
        34,
        21,
        48,
        49,
        0,
        0,
        26,
        26,
        0,
        25,
        29,
        48,
        0,
        49,
        25,
        46,
        46,
        38,
        2,
        40,
        24,
        22
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        18,
        34,
        48,
        24,
        1,
        25,
        34,
        48,
        9,
        0,
        49,
        0,
        7,
        10,
        27,
        36,
        7,
        13,
        0,
        32,
        0,
        23,
        49,
        8,
        16,
        47,
        8,
        7,
        12,
        45,
        18,
        24,
        27,
        42,
        6,
        39,
        36,
        6,
        13,
        43,
        32,
        11,
        49,
        49,
        49,
        13,
        0,
        23,
        1,
        49,
        11,
        12
      ],
      "weeklyRejected": [
        23,
        26,
        4,
        26,
        39,
        37,
        49,
        49,
        49,
        1,
        11,
        18,
        33,
        41,
        24,
        12,
        27,
        48,
        0,
        36,
        0,
        13,
        24,
        43,
        48,
        32,
        30,
        24,
        37,
        0,
        1,
        19,
        31,
        29,
        35,
        49,
        29,
        17,
        17,
        28,
        48,
        32,
        4,
        23,
        41,
        20,
        11,
        49,
        0,
        45,
        22,
        48
      ],
      "weeklyHold": [
        15,
        5,
        34,
        20,
        49,
        37,
        46,
        11,
        0,
        35,
        24,
        2,
        39,
        1,
        7,
        43,
        0,
        0,
        11,
        6,
        34,
        20,
        33,
        0,
        16,
        36,
        49,
        39,
        49,
        3,
        48,
        0,
        0,
        12,
        7,
        49,
        7,
        0,
        45,
        0,
        6,
        39,
        0,
        26,
        40,
        49,
        23,
        22,
        10,
        43,
        34,
        46
      ],
      "weeklyBackordered": [
        48,
        7,
        28,
        5,
        41,
        46,
        0,
        49,
        21,
        7,
        42,
        49,
        36,
        1,
        18,
        42,
        11,
        49,
        21,
        34,
        29,
        29,
        49,
        48,
        27,
        37,
        42,
        35,
        36,
        49,
        7,
        49,
        39,
        37,
        0,
        0,
        9,
        17,
        20,
        0,
        34,
        48,
        24,
        49,
        0,
        13,
        34,
        30,
        0,
        36,
        49,
        23
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        36,
        2,
        17,
        40,
        1,
        0,
        49,
        9,
        36,
        32,
        17,
        0,
        44,
        47,
        1,
        16,
        29,
        0,
        25,
        0,
        12,
        43,
        0,
        44,
        45,
        19,
        2,
        33,
        49,
        22,
        8,
        0,
        49,
        32,
        39,
        21,
        42,
        48,
        46,
        39,
        18,
        25,
        30,
        4,
        17,
        29,
        17,
        0,
        19,
        49,
        34,
        48
      ],
      "weeklyRejected": [
        23,
        7,
        47,
        11,
        16,
        16,
        49,
        40,
        40,
        1,
        16,
        19,
        21,
        48,
        35,
        43,
        28,
        10,
        46,
        27,
        49,
        49,
        28,
        49,
        35,
        49,
        10,
        35,
        27,
        0,
        49,
        18,
        18,
        49,
        0,
        49,
        30,
        41,
        3,
        49,
        23,
        34,
        42,
        39,
        49,
        7,
        24,
        32,
        3,
        0,
        0,
        44
      ],
      "weeklyHold": [
        6,
        0,
        12,
        1,
        49,
        14,
        40,
        34,
        30,
        49,
        24,
        49,
        28,
        33,
        33,
        15,
        0,
        37,
        4,
        0,
        31,
        49,
        35,
        11,
        25,
        48,
        8,
        0,
        24,
        0,
        48,
        0,
        1,
        24,
        29,
        49,
        13,
        7,
        49,
        13,
        6,
        41,
        0,
        24,
        49,
        18,
        46,
        11,
        16,
        33,
        35,
        35
      ],
      "weeklyBackordered": [
        49,
        47,
        6,
        49,
        49,
        43,
        15,
        33,
        1,
        14,
        35,
        43,
        29,
        4,
        24,
        6,
        14,
        17,
        20,
        14,
        20,
        44,
        0,
        46,
        1,
        13,
        7,
        23,
        37,
        49,
        34,
        30,
        21,
        49,
        16,
        0,
        49,
        7,
        23,
        21,
        48,
        48,
        0,
        49,
        1,
        13,
        45,
        20,
        6,
        40,
        10,
        4
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        37,
        27,
        49,
        33,
        1,
        0,
        6,
        24,
        24,
        49,
        46,
        6,
        28,
        26,
        18,
        49,
        42,
        21,
        0,
        49,
        0,
        44,
        47,
        9,
        0,
        22,
        27,
        49,
        42,
        49,
        34,
        34,
        25,
        0,
        48,
        16,
        40,
        48,
        45,
        49,
        0,
        11,
        40,
        25,
        49,
        9,
        0,
        49,
        0,
        39,
        49,
        14
      ],
      "weeklyRejected": [
        38,
        34,
        30,
        45,
        45,
        39,
        28,
        31,
        49,
        8,
        8,
        49,
        0,
        48,
        43,
        0,
        25,
        37,
        30,
        0,
        49,
        17,
        33,
        15,
        41,
        49,
        25,
        0,
        48,
        0,
        43,
        23,
        47,
        49,
        0,
        42,
        49,
        49,
        25,
        16,
        30,
        0,
        48,
        32,
        48,
        1,
        19,
        17,
        0,
        23,
        8,
        49
      ],
      "weeklyHold": [
        19,
        0,
        41,
        20,
        33,
        38,
        38,
        2,
        43,
        30,
        38,
        35,
        43,
        45,
        49,
        49,
        32,
        25,
        0,
        26,
        49,
        0,
        35,
        0,
        0,
        31,
        42,
        8,
        39,
        0,
        37,
        1,
        0,
        8,
        40,
        1,
        49,
        18,
        49,
        29,
        18,
        18,
        0,
        49,
        16,
        8,
        0,
        39,
        18,
        21,
        40,
        11
      ],
      "weeklyBackordered": [
        3,
        36,
        26,
        37,
        49,
        46,
        24,
        2,
        41,
        0,
        49,
        27,
        23,
        0,
        43,
        24,
        37,
        42,
        0,
        41,
        30,
        16,
        13,
        31,
        15,
        14,
        2,
        48,
        18,
        49,
        5,
        49,
        38,
        37,
        0,
        0,
        4,
        0,
        29,
        43,
        48,
        44,
        6,
        49,
        17,
        31,
        28,
        31,
        0,
        27,
        17,
        7
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        26,
        11,
        0,
        35,
        28,
        21,
        15,
        26,
        16,
        46,
        26,
        0,
        26,
        17,
        1,
        39,
        41,
        0,
        24,
        13,
        25,
        18,
        23,
        34,
        33,
        10,
        4,
        37,
        49,
        30,
        44,
        27,
        49,
        46,
        48,
        40,
        43,
        18,
        4,
        20,
        36,
        46,
        21,
        40,
        49,
        0,
        0,
        49,
        17,
        34,
        33,
        11
      ],
      "weeklyRejected": [
        17,
        7,
        20,
        47,
        11,
        3,
        26,
        20,
        45,
        12,
        0,
        32,
        30,
        36,
        11,
        15,
        0,
        37,
        19,
        18,
        25,
        41,
        49,
        26,
        28,
        49,
        41,
        46,
        48,
        31,
        28,
        45,
        4,
        22,
        17,
        25,
        0,
        20,
        15,
        24,
        40,
        49,
        24,
        31,
        20,
        18,
        17,
        39,
        5,
        35,
        0,
        33
      ],
      "weeklyHold": [
        49,
        36,
        49,
        1,
        17,
        1,
        41,
        26,
        27,
        27,
        28,
        15,
        48,
        42,
        49,
        7,
        0,
        48,
        48,
        7,
        35,
        20,
        38,
        1,
        25,
        8,
        35,
        8,
        49,
        7,
        48,
        30,
        14,
        41,
        6,
        24,
        11,
        17,
        35,
        49,
        36,
        40,
        0,
        17,
        49,
        34,
        18,
        23,
        19,
        0,
        44,
        0
      ],
      "weeklyBackordered": [
        28,
        0,
        26,
        36,
        31,
        11,
        40,
        14,
        49,
        46,
        49,
        34,
        24,
        28,
        14,
        16,
        13,
        32,
        20,
        28,
        49,
        0,
        6,
        29,
        48,
        20,
        9,
        19,
        24,
        49,
        14,
        44,
        48,
        49,
        0,
        0,
        46,
        0,
        30,
        0,
        3,
        48,
        0,
        27,
        0,
        17,
        34,
        6,
        39,
        49,
        29,
        28
      ]
    },
    {
      "id": "FB70040E",
      "weeklyAccepted": [
        0,
        19,
        20,
        34,
        23,
        48,
        1,
        2,
        48,
        0,
        49,
        19,
        36,
        0,
        12,
        23,
        44,
        38,
        16,
        18,
        21,
        45,
        0,
        49,
        45,
        39,
        13,
        16,
        23,
        36,
        49,
        16,
        45,
        49,
        11,
        47,
        39,
        13,
        0,
        21,
        35,
        13,
        49,
        33,
        47,
        0,
        9,
        49,
        19,
        43,
        12,
        5
      ],
      "weeklyRejected": [
        49,
        11,
        47,
        21,
        23,
        18,
        18,
        2,
        28,
        22,
        22,
        31,
        27,
        6,
        43,
        32,
        37,
        39,
        40,
        43,
        6,
        49,
        0,
        49,
        14,
        14,
        49,
        43,
        28,
        43,
        45,
        0,
        36,
        16,
        16,
        24,
        25,
        17,
        1,
        18,
        41,
        45,
        49,
        0,
        37,
        10,
        14,
        30,
        31,
        48,
        0,
        29
      ],
      "weeklyHold": [
        16,
        24,
        49,
        1,
        43,
        28,
        11,
        2,
        28,
        10,
        47,
        40,
        42,
        19,
        49,
        22,
        17,
        25,
        30,
        0,
        20,
        49,
        0,
        2,
        28,
        48,
        44,
        17,
        23,
        10,
        48,
        12,
        23,
        46,
        8,
        28,
        35,
        0,
        40,
        11,
        31,
        37,
        0,
        30,
        35,
        26,
        40,
        43,
        0,
        49,
        40,
        21
      ],
      "weeklyBackordered": [
        0,
        20,
        43,
        42,
        7,
        46,
        0,
        32,
        18,
        22,
        49,
        1,
        3,
        49,
        45,
        27,
        30,
        31,
        47,
        30,
        37,
        35,
        29,
        25,
        7,
        18,
        1,
        32,
        49,
        16,
        0,
        19,
        29,
        42,
        1,
        0,
        16,
        28,
        13,
        26,
        48,
        48,
        15,
        49,
        10,
        18,
        35,
        36,
        3,
        24,
        49,
        18
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        0,
        49,
        42,
        0,
        47,
        0,
        26,
        49,
        48,
        0,
        46,
        9,
        49,
        19,
        43,
        0,
        49,
        8,
        0,
        4,
        9,
        19,
        18,
        49,
        14,
        39,
        0,
        10,
        44,
        41,
        20,
        7,
        10,
        29,
        6,
        30,
        32,
        44,
        49,
        24,
        1,
        30,
        49,
        4,
        0,
        0,
        8,
        8,
        28,
        47,
        13,
        43
      ],
      "weeklyRejected": [
        49,
        7,
        47,
        33,
        25,
        29,
        0,
        45,
        20,
        1,
        5,
        33,
        41,
        27,
        26,
        15,
        49,
        44,
        13,
        32,
        45,
        0,
        38,
        28,
        23,
        34,
        39,
        26,
        43,
        34,
        20,
        47,
        48,
        43,
        15,
        47,
        7,
        45,
        34,
        14,
        48,
        26,
        23,
        13,
        47,
        17,
        7,
        23,
        0,
        28,
        31,
        49
      ],
      "weeklyHold": [
        20,
        20,
        49,
        28,
        28,
        32,
        24,
        19,
        26,
        11,
        43,
        37,
        1,
        36,
        11,
        14,
        0,
        42,
        49,
        22,
        0,
        12,
        43,
        10,
        44,
        40,
        46,
        48,
        23,
        0,
        38,
        3,
        0,
        31,
        35,
        49,
        28,
        9,
        1,
        29,
        40,
        49,
        20,
        16,
        37,
        23,
        26,
        0,
        0,
        0,
        0,
        0
      ],
      "weeklyBackordered": [
        18,
        37,
        16,
        47,
        24,
        31,
        39,
        21,
        0,
        42,
        40,
        13,
        8,
        0,
        30,
        6,
        33,
        49,
        24,
        24,
        7,
        49,
        36,
        25,
        21,
        8,
        9,
        30,
        22,
        44,
        0,
        37,
        43,
        16,
        15,
        0,
        3,
        0,
        26,
        38,
        23,
        45,
        0,
        30,
        45,
        33,
        7,
        0,
        13,
        40,
        1,
        11
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        0,
        39,
        9,
        14,
        49,
        30,
        25,
        49,
        48,
        17,
        21,
        7,
        37,
        24,
        22,
        22,
        34,
        24,
        5,
        32,
        2,
        49,
        12,
        49,
        0,
        0,
        19,
        49,
        1,
        48,
        16,
        33,
        49,
        13,
        43,
        42,
        21,
        8,
        23,
        28,
        27,
        27,
        49,
        23,
        31,
        3,
        1,
        0,
        48,
        49,
        27,
        16
      ],
      "weeklyRejected": [
        19,
        0,
        42,
        3,
        10,
        23,
        0,
        0,
        34,
        48,
        35,
        0,
        44,
        48,
        35,
        29,
        17,
        10,
        36,
        18,
        30,
        41,
        33,
        11,
        21,
        49,
        49,
        15,
        43,
        48,
        0,
        9,
        17,
        3,
        28,
        49,
        7,
        49,
        1,
        4,
        48,
        41,
        8,
        18,
        7,
        9,
        7,
        7,
        0,
        31,
        0,
        13
      ],
      "weeklyHold": [
        31,
        5,
        36,
        32,
        49,
        0,
        17,
        2,
        19,
        7,
        39,
        33,
        1,
        11,
        0,
        8,
        38,
        2,
        5,
        48,
        27,
        36,
        37,
        7,
        35,
        40,
        0,
        0,
        26,
        30,
        27,
        12,
        10,
        40,
        23,
        49,
        19,
        5,
        4,
        8,
        34,
        40,
        16,
        36,
        42,
        22,
        18,
        36,
        7,
        33,
        38,
        34
      ],
      "weeklyBackordered": [
        2,
        5,
        0,
        48,
        44,
        46,
        18,
        39,
        35,
        26,
        31,
        11,
        20,
        30,
        5,
        1,
        49,
        39,
        17,
        0,
        49,
        15,
        17,
        30,
        41,
        4,
        8,
        36,
        21,
        20,
        19,
        36,
        14,
        0,
        20,
        0,
        6,
        0,
        47,
        49,
        43,
        48,
        17,
        42,
        17,
        26,
        47,
        49,
        42,
        45,
        34,
        8
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        21,
        39,
        6,
        2,
        31,
        29,
        1,
        49,
        25,
        11,
        44,
        22,
        49,
        24,
        19,
        16,
        31,
        36,
        29,
        37,
        36,
        49,
        9,
        17,
        0,
        19,
        42,
        49,
        49,
        49,
        27,
        19,
        31,
        40,
        26,
        46,
        26,
        46,
        46,
        32,
        21,
        29,
        34,
        28,
        49,
        49,
        0,
        14,
        0,
        49,
        19,
        6
      ],
      "weeklyRejected": [
        43,
        22,
        8,
        34,
        49,
        24,
        30,
        20,
        34,
        24,
        16,
        49,
        0,
        48,
        48,
        0,
        10,
        42,
        44,
        0,
        47,
        38,
        49,
        42,
        28,
        49,
        49,
        18,
        47,
        5,
        17,
        47,
        44,
        20,
        20,
        49,
        26,
        49,
        1,
        0,
        35,
        42,
        23,
        30,
        45,
        49,
        41,
        25,
        0,
        46,
        31,
        49
      ],
      "weeklyHold": [
        46,
        13,
        44,
        18,
        15,
        34,
        27,
        34,
        34,
        17,
        37,
        19,
        15,
        24,
        20,
        0,
        3,
        31,
        44,
        0,
        14,
        20,
        49,
        20,
        44,
        44,
        19,
        5,
        29,
        3,
        40,
        44,
        37,
        49,
        1,
        28,
        2,
        5,
        32,
        40,
        0,
        38,
        39,
        45,
        36,
        38,
        35,
        47,
        0,
        45,
        28,
        49
      ],
      "weeklyBackordered": [
        21,
        26,
        30,
        39,
        49,
        26,
        29,
        43,
        7,
        15,
        25,
        11,
        33,
        49,
        8,
        23,
        39,
        29,
        39,
        14,
        22,
        4,
        11,
        0,
        48,
        20,
        2,
        41,
        13,
        18,
        32,
        43,
        36,
        0,
        0,
        0,
        17,
        45,
        20,
        32,
        29,
        48,
        3,
        49,
        11,
        49,
        49,
        28,
        24,
        39,
        49,
        18
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        0,
        41,
        49,
        32,
        43,
        0,
        9,
        22,
        48,
        49,
        31,
        17,
        49,
        19,
        9,
        48,
        47,
        0,
        0,
        35,
        13,
        34,
        16,
        0,
        9,
        25,
        4,
        40,
        39,
        44,
        0,
        29,
        42,
        0,
        28,
        18,
        22,
        18,
        49,
        49,
        0,
        38,
        29,
        31,
        49,
        23,
        0,
        0,
        0,
        49,
        49,
        4
      ],
      "weeklyRejected": [
        49,
        22,
        49,
        39,
        39,
        27,
        25,
        49,
        13,
        30,
        13,
        49,
        31,
        45,
        29,
        19,
        41,
        49,
        27,
        49,
        9,
        12,
        43,
        9,
        38,
        44,
        0,
        26,
        18,
        15,
        8,
        33,
        40,
        49,
        22,
        49,
        18,
        44,
        5,
        49,
        2,
        12,
        31,
        10,
        48,
        26,
        1,
        19,
        0,
        28,
        0,
        42
      ],
      "weeklyHold": [
        18,
        7,
        49,
        35,
        26,
        45,
        28,
        21,
        0,
        19,
        10,
        49,
        28,
        5,
        41,
        0,
        0,
        26,
        49,
        19,
        43,
        9,
        49,
        13,
        46,
        14,
        32,
        20,
        49,
        9,
        23,
        4,
        11,
        17,
        44,
        15,
        31,
        0,
        18,
        34,
        0,
        16,
        25,
        49,
        49,
        22,
        20,
        18,
        0,
        28,
        13,
        5
      ],
      "weeklyBackordered": [
        37,
        3,
        29,
        41,
        49,
        16,
        49,
        49,
        10,
        0,
        32,
        49,
        3,
        49,
        41,
        4,
        49,
        49,
        14,
        18,
        27,
        14,
        0,
        0,
        15,
        17,
        18,
        0,
        35,
        39,
        37,
        0,
        24,
        39,
        20,
        0,
        1,
        24,
        0,
        38,
        19,
        48,
        5,
        49,
        33,
        49,
        37,
        17,
        29,
        27,
        39,
        1
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        33,
        16,
        15,
        33,
        42,
        0,
        27,
        31,
        18,
        21,
        32,
        0,
        11,
        14,
        5,
        49,
        32,
        6,
        0,
        0,
        0,
        0,
        49,
        29,
        13,
        42,
        34,
        8,
        32,
        49,
        6,
        24,
        49,
        41,
        31,
        7,
        25,
        27,
        28,
        49,
        0,
        49,
        37,
        36,
        15,
        30,
        8,
        0,
        9,
        37,
        30,
        48
      ],
      "weeklyRejected": [
        49,
        27,
        31,
        20,
        8,
        41,
        34,
        49,
        29,
        33,
        35,
        49,
        2,
        48,
        48,
        0,
        39,
        25,
        49,
        25,
        0,
        37,
        49,
        30,
        22,
        33,
        1,
        49,
        18,
        11,
        49,
        16,
        8,
        19,
        2,
        49,
        42,
        5,
        1,
        44,
        30,
        3,
        16,
        6,
        48,
        18,
        30,
        15,
        0,
        30,
        0,
        49
      ],
      "weeklyHold": [
        3,
        49,
        42,
        30,
        29,
        48,
        0,
        2,
        2,
        15,
        47,
        25,
        48,
        6,
        13,
        7,
        1,
        34,
        14,
        25,
        9,
        0,
        49,
        0,
        1,
        33,
        49,
        21,
        49,
        17,
        38,
        0,
        0,
        36,
        0,
        13,
        22,
        0,
        45,
        36,
        21,
        38,
        0,
        22,
        32,
        44,
        49,
        15,
        0,
        34,
        35,
        49
      ],
      "weeklyBackordered": [
        40,
        27,
        33,
        30,
        48,
        9,
        36,
        10,
        49,
        11,
        24,
        49,
        35,
        21,
        27,
        33,
        49,
        42,
        23,
        47,
        49,
        47,
        13,
        0,
        25,
        13,
        30,
        49,
        40,
        49,
        35,
        49,
        48,
        8,
        12,
        8,
        9,
        8,
        40,
        38,
        13,
        48,
        1,
        49,
        21,
        16,
        49,
        36,
        0,
        14,
        14,
        18
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        10,
        49,
        8,
        31,
        7,
        0,
        1,
        47,
        28,
        0,
        28,
        0,
        45,
        24,
        1,
        35,
        47,
        48,
        0,
        37,
        0,
        26,
        21,
        18,
        29,
        28,
        2,
        13,
        12,
        41,
        33,
        37,
        38,
        0,
        14,
        7,
        29,
        22,
        49,
        42,
        24,
        42,
        49,
        36,
        49,
        12,
        0,
        22,
        17,
        0,
        0,
        14
      ],
      "weeklyRejected": [
        38,
        36,
        11,
        0,
        0,
        0,
        47,
        49,
        38,
        28,
        0,
        2,
        49,
        28,
        26,
        21,
        25,
        31,
        35,
        41,
        25,
        11,
        32,
        21,
        11,
        47,
        49,
        49,
        48,
        3,
        42,
        33,
        13,
        39,
        27,
        22,
        30,
        0,
        48,
        20,
        48,
        37,
        24,
        0,
        49,
        42,
        14,
        22,
        15,
        21,
        46,
        35
      ],
      "weeklyHold": [
        34,
        0,
        26,
        1,
        42,
        0,
        28,
        18,
        0,
        49,
        47,
        40,
        4,
        24,
        7,
        13,
        45,
        38,
        14,
        41,
        30,
        17,
        8,
        0,
        26,
        48,
        7,
        0,
        49,
        0,
        37,
        0,
        0,
        24,
        0,
        27,
        22,
        47,
        7,
        4,
        0,
        38,
        0,
        31,
        32,
        49,
        32,
        49,
        10,
        44,
        47,
        49
      ],
      "weeklyBackordered": [
        0,
        18,
        0,
        24,
        42,
        38,
        41,
        35,
        49,
        11,
        4,
        49,
        31,
        0,
        0,
        11,
        49,
        21,
        0,
        9,
        42,
        31,
        46,
        28,
        28,
        32,
        28,
        32,
        1,
        45,
        15,
        24,
        41,
        49,
        0,
        0,
        25,
        4,
        0,
        46,
        18,
        48,
        0,
        49,
        40,
        27,
        49,
        13,
        48,
        33,
        49,
        35
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        0,
        1,
        18,
        28,
        49,
        4,
        41,
        49,
        23,
        35,
        48,
        36,
        35,
        6,
        37,
        30,
        41,
        36,
        1,
        49,
        43,
        33,
        41,
        3,
        23,
        47,
        14,
        0,
        22,
        49,
        28,
        0,
        49,
        32,
        37,
        14,
        33,
        4,
        28,
        49,
        0,
        39,
        40,
        13,
        49,
        0,
        23,
        22,
        15,
        29,
        0,
        24
      ],
      "weeklyRejected": [
        34,
        37,
        16,
        47,
        23,
        2,
        0,
        16,
        0,
        12,
        24,
        0,
        49,
        48,
        2,
        17,
        15,
        28,
        45,
        37,
        28,
        31,
        8,
        49,
        23,
        25,
        35,
        11,
        42,
        0,
        34,
        37,
        48,
        28,
        13,
        43,
        0,
        21,
        33,
        49,
        23,
        49,
        23,
        7,
        36,
        43,
        22,
        24,
        31,
        48,
        0,
        30
      ],
      "weeklyHold": [
        49,
        25,
        49,
        1,
        39,
        36,
        14,
        33,
        6,
        4,
        47,
        49,
        35,
        32,
        0,
        8,
        14,
        34,
        18,
        6,
        41,
        19,
        5,
        14,
        6,
        27,
        15,
        1,
        37,
        6,
        48,
        31,
        0,
        40,
        12,
        40,
        15,
        9,
        1,
        9,
        33,
        49,
        24,
        0,
        49,
        12,
        49,
        41,
        20,
        49,
        27,
        39
      ],
      "weeklyBackordered": [
        9,
        48,
        0,
        20,
        32,
        6,
        47,
        37,
        0,
        2,
        22,
        19,
        3,
        49,
        19,
        1,
        33,
        49,
        38,
        0,
        45,
        49,
        21,
        49,
        2,
        1,
        5,
        2,
        49,
        28,
        27,
        49,
        31,
        35,
        48,
        24,
        22,
        2,
        41,
        49,
        48,
        43,
        33,
        26,
        20,
        31,
        47,
        20,
        0,
        49,
        22,
        24
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        25,
        1,
        4,
        31,
        37,
        36,
        29,
        35,
        37,
        35,
        33,
        0,
        27,
        27,
        18,
        0,
        21,
        5,
        15,
        36,
        0,
        16,
        34,
        26,
        49,
        42,
        18,
        40,
        26,
        25,
        15,
        0,
        40,
        2,
        48,
        30,
        47,
        42,
        32,
        49,
        10,
        34,
        44,
        33,
        42,
        37,
        0,
        13,
        0,
        49,
        41,
        18
      ],
      "weeklyRejected": [
        42,
        7,
        26,
        32,
        26,
        0,
        40,
        32,
        15,
        24,
        11,
        25,
        49,
        47,
        6,
        33,
        46,
        39,
        25,
        41,
        5,
        22,
        43,
        40,
        25,
        49,
        5,
        29,
        48,
        5,
        20,
        21,
        22,
        49,
        0,
        46,
        49,
        49,
        3,
        15,
        0,
        45,
        22,
        36,
        34,
        8,
        41,
        11,
        0,
        49,
        10,
        49
      ],
      "weeklyHold": [
        44,
        0,
        43,
        24,
        46,
        21,
        0,
        36,
        46,
        29,
        23,
        6,
        40,
        1,
        42,
        21,
        5,
        48,
        32,
        5,
        41,
        49,
        8,
        20,
        49,
        45,
        0,
        12,
        49,
        4,
        48,
        5,
        49,
        49,
        25,
        0,
        24,
        0,
        39,
        32,
        16,
        22,
        0,
        36,
        49,
        0,
        37,
        49,
        0,
        28,
        49,
        0
      ],
      "weeklyBackordered": [
        49,
        35,
        27,
        10,
        45,
        46,
        49,
        20,
        13,
        14,
        47,
        43,
        25,
        49,
        29,
        6,
        13,
        43,
        10,
        49,
        39,
        35,
        32,
        27,
        14,
        21,
        49,
        34,
        27,
        47,
        16,
        0,
        0,
        23,
        14,
        0,
        13,
        7,
        15,
        9,
        21,
        48,
        7,
        2,
        19,
        49,
        49,
        5,
        27,
        49,
        21,
        1
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        0,
        49,
        15,
        48,
        3,
        0,
        6,
        36,
        48,
        46,
        5,
        0,
        30,
        29,
        5,
        49,
        41,
        0,
        5,
        49,
        46,
        31,
        42,
        34,
        29,
        44,
        46,
        49,
        23,
        1,
        17,
        0,
        49,
        4,
        22,
        42,
        40,
        1,
        29,
        35,
        23,
        34,
        41,
        49,
        43,
        10,
        10,
        16,
        18,
        37,
        0,
        38
      ],
      "weeklyRejected": [
        42,
        12,
        38,
        0,
        40,
        22,
        10,
        7,
        28,
        39,
        41,
        0,
        38,
        33,
        31,
        4,
        37,
        49,
        12,
        17,
        35,
        42,
        9,
        23,
        18,
        49,
        27,
        14,
        34,
        29,
        41,
        33,
        19,
        49,
        23,
        49,
        18,
        26,
        37,
        49,
        42,
        49,
        36,
        7,
        45,
        6,
        0,
        12,
        1,
        49,
        31,
        49
      ],
      "weeklyHold": [
        45,
        0,
        49,
        23,
        49,
        0,
        0,
        14,
        0,
        0,
        27,
        31,
        35,
        1,
        29,
        6,
        0,
        8,
        3,
        40,
        32,
        49,
        48,
        34,
        0,
        1,
        24,
        6,
        49,
        11,
        48,
        48,
        0,
        30,
        48,
        47,
        18,
        0,
        25,
        0,
        6,
        21,
        13,
        48,
        16,
        18,
        40,
        49,
        0,
        36,
        25,
        28
      ],
      "weeklyBackordered": [
        34,
        49,
        22,
        43,
        49,
        46,
        0,
        28,
        11,
        0,
        17,
        19,
        16,
        0,
        37,
        1,
        49,
        18,
        4,
        24,
        38,
        37,
        1,
        39,
        21,
        49,
        1,
        28,
        47,
        27,
        31,
        30,
        34,
        49,
        2,
        35,
        48,
        7,
        4,
        5,
        32,
        44,
        0,
        49,
        0,
        4,
        43,
        42,
        31,
        49,
        17,
        33
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        6,
        29,
        32,
        37,
        49,
        49,
        12,
        14,
        48,
        11,
        47,
        0,
        15,
        26,
        3,
        20,
        40,
        14,
        49,
        23,
        10,
        28,
        0,
        28,
        19,
        17,
        19,
        26,
        29,
        14,
        0,
        34,
        25,
        0,
        29,
        8,
        28,
        48,
        24,
        49,
        35,
        28,
        39,
        8,
        33,
        20,
        26,
        14,
        47,
        0,
        20,
        37
      ],
      "weeklyRejected": [
        19,
        21,
        28,
        45,
        30,
        0,
        49,
        0,
        29,
        33,
        49,
        7,
        31,
        37,
        22,
        7,
        24,
        40,
        48,
        22,
        49,
        17,
        27,
        37,
        0,
        30,
        49,
        15,
        27,
        36,
        22,
        1,
        6,
        26,
        14,
        49,
        12,
        49,
        1,
        15,
        34,
        12,
        25,
        6,
        19,
        19,
        28,
        6,
        26,
        38,
        0,
        46
      ],
      "weeklyHold": [
        6,
        6,
        36,
        1,
        5,
        9,
        34,
        2,
        26,
        43,
        47,
        41,
        48,
        1,
        37,
        10,
        37,
        13,
        0,
        11,
        34,
        21,
        0,
        12,
        31,
        23,
        2,
        0,
        49,
        22,
        26,
        13,
        0,
        47,
        18,
        47,
        32,
        27,
        23,
        22,
        0,
        35,
        19,
        49,
        27,
        37,
        24,
        31,
        0,
        20,
        7,
        37
      ],
      "weeklyBackordered": [
        0,
        0,
        0,
        40,
        37,
        24,
        25,
        45,
        35,
        29,
        10,
        46,
        8,
        27,
        36,
        49,
        43,
        14,
        11,
        8,
        49,
        0,
        26,
        19,
        48,
        26,
        49,
        2,
        15,
        49,
        20,
        0,
        1,
        32,
        16,
        0,
        30,
        0,
        49,
        47,
        0,
        40,
        0,
        30,
        0,
        0,
        37,
        3,
        21,
        20,
        26,
        3
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        37,
        27,
        31,
        0,
        49,
        49,
        1,
        42,
        4,
        20,
        12,
        0,
        49,
        19,
        18,
        49,
        34,
        24,
        8,
        22,
        22,
        49,
        29,
        20,
        9,
        8,
        0,
        18,
        4,
        13,
        44,
        39,
        47,
        49,
        10,
        2,
        27,
        21,
        49,
        49,
        34,
        0,
        29,
        35,
        49,
        49,
        5,
        29,
        0,
        40,
        0,
        43
      ],
      "weeklyRejected": [
        49,
        42,
        2,
        30,
        22,
        0,
        28,
        32,
        39,
        33,
        41,
        42,
        0,
        48,
        36,
        0,
        0,
        43,
        15,
        0,
        31,
        49,
        22,
        39,
        33,
        47,
        48,
        5,
        45,
        31,
        0,
        47,
        48,
        32,
        32,
        49,
        49,
        16,
        14,
        10,
        48,
        31,
        22,
        13,
        43,
        9,
        33,
        49,
        0,
        28,
        0,
        44
      ],
      "weeklyHold": [
        15,
        0,
        49,
        1,
        0,
        48,
        42,
        21,
        30,
        26,
        5,
        15,
        1,
        26,
        32,
        37,
        0,
        22,
        0,
        9,
        37,
        25,
        12,
        49,
        49,
        17,
        30,
        9,
        37,
        4,
        39,
        0,
        9,
        49,
        35,
        9,
        18,
        0,
        49,
        41,
        23,
        49,
        33,
        22,
        38,
        10,
        0,
        14,
        3,
        21,
        0,
        14
      ],
      "weeklyBackordered": [
        44,
        49,
        21,
        40,
        49,
        13,
        49,
        49,
        33,
        0,
        15,
        25,
        22,
        36,
        26,
        49,
        1,
        15,
        27,
        49,
        26,
        0,
        0,
        22,
        35,
        21,
        17,
        14,
        22,
        28,
        8,
        49,
        0,
        20,
        0,
        19,
        11,
        27,
        49,
        0,
        10,
        48,
        1,
        34,
        24,
        0,
        28,
        0,
        21,
        19,
        49,
        32
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        21,
        49,
        10,
        24,
        49,
        0,
        43,
        29,
        46,
        1,
        26,
        43,
        18,
        22,
        37,
        10,
        49,
        48,
        0,
        49,
        43,
        30,
        2,
        37,
        12,
        20,
        49,
        0,
        49,
        49,
        12,
        14,
        49,
        1,
        42,
        20,
        28,
        21,
        7,
        10,
        30,
        12,
        49,
        0,
        26,
        29,
        26,
        4,
        49,
        28,
        14,
        48
      ],
      "weeklyRejected": [
        20,
        37,
        19,
        37,
        37,
        49,
        13,
        47,
        25,
        10,
        49,
        32,
        0,
        32,
        33,
        7,
        28,
        49,
        23,
        29,
        26,
        26,
        10,
        12,
        32,
        22,
        21,
        30,
        17,
        15,
        38,
        47,
        27,
        26,
        29,
        35,
        17,
        38,
        46,
        41,
        21,
        49,
        8,
        6,
        49,
        12,
        10,
        0,
        34,
        40,
        0,
        21
      ],
      "weeklyHold": [
        0,
        9,
        49,
        1,
        49,
        26,
        28,
        2,
        16,
        36,
        47,
        15,
        1,
        32,
        30,
        20,
        0,
        48,
        0,
        1,
        24,
        49,
        18,
        49,
        41,
        1,
        8,
        25,
        49,
        39,
        27,
        22,
        0,
        14,
        48,
        24,
        2,
        35,
        19,
        34,
        24,
        14,
        49,
        2,
        35,
        0,
        19,
        49,
        24,
        43,
        38,
        26
      ],
      "weeklyBackordered": [
        32,
        5,
        0,
        22,
        38,
        30,
        30,
        43,
        24,
        6,
        10,
        25,
        3,
        48,
        47,
        7,
        25,
        46,
        7,
        17,
        41,
        47,
        20,
        39,
        16,
        3,
        18,
        28,
        14,
        32,
        5,
        23,
        37,
        34,
        31,
        0,
        5,
        0,
        49,
        36,
        33,
        43,
        49,
        49,
        17,
        0,
        36,
        0,
        21,
        49,
        49,
        9
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        12,
        29,
        21,
        22,
        49,
        29,
        13,
        36,
        30,
        48,
        49,
        4,
        43,
        29,
        13,
        0,
        32,
        36,
        10,
        15,
        12,
        5,
        29,
        36,
        1,
        1,
        24,
        11,
        1,
        28,
        27,
        0,
        14,
        9,
        0,
        46,
        0,
        48,
        33,
        49,
        19,
        0,
        35,
        13,
        49,
        25,
        9,
        49,
        13,
        49,
        28,
        48
      ],
      "weeklyRejected": [
        38,
        17,
        38,
        44,
        24,
        28,
        49,
        10,
        49,
        47,
        38,
        7,
        5,
        43,
        2,
        11,
        11,
        22,
        36,
        25,
        49,
        49,
        8,
        46,
        12,
        49,
        49,
        34,
        42,
        11,
        44,
        47,
        48,
        49,
        29,
        33,
        49,
        25,
        1,
        46,
        25,
        24,
        38,
        1,
        48,
        0,
        28,
        14,
        23,
        49,
        11,
        48
      ],
      "weeklyHold": [
        11,
        28,
        46,
        1,
        35,
        41,
        34,
        40,
        30,
        21,
        47,
        40,
        15,
        32,
        3,
        0,
        2,
        30,
        23,
        0,
        0,
        0,
        20,
        31,
        15,
        12,
        47,
        22,
        33,
        2,
        34,
        22,
        0,
        42,
        48,
        17,
        21,
        0,
        39,
        9,
        23,
        18,
        1,
        1,
        42,
        8,
        21,
        49,
        0,
        38,
        42,
        18
      ],
      "weeklyBackordered": [
        16,
        28,
        9,
        38,
        39,
        15,
        26,
        20,
        45,
        14,
        17,
        0,
        41,
        49,
        8,
        19,
        42,
        49,
        31,
        30,
        45,
        36,
        45,
        49,
        12,
        8,
        12,
        35,
        49,
        33,
        37,
        33,
        35,
        12,
        16,
        17,
        27,
        0,
        0,
        6,
        27,
        48,
        17,
        49,
        24,
        20,
        39,
        49,
        29,
        24,
        23,
        43
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        17,
        38,
        33,
        12,
        20,
        45,
        1,
        2,
        0,
        6,
        31,
        21,
        0,
        33,
        12,
        8,
        49,
        23,
        25,
        17,
        9,
        49,
        17,
        17,
        49,
        43,
        7,
        22,
        21,
        49,
        17,
        2,
        42,
        16,
        30,
        22,
        47,
        0,
        48,
        49,
        2,
        40,
        20,
        31,
        49,
        39,
        0,
        0,
        0,
        49,
        0,
        0
      ],
      "weeklyRejected": [
        49,
        18,
        44,
        41,
        21,
        29,
        49,
        2,
        49,
        14,
        13,
        48,
        14,
        32,
        31,
        11,
        33,
        31,
        6,
        0,
        38,
        49,
        25,
        13,
        30,
        49,
        34,
        21,
        0,
        41,
        37,
        47,
        19,
        47,
        27,
        49,
        23,
        27,
        22,
        49,
        48,
        39,
        40,
        10,
        49,
        43,
        3,
        49,
        21,
        44,
        0,
        36
      ],
      "weeklyHold": [
        49,
        0,
        49,
        1,
        27,
        4,
        47,
        25,
        37,
        9,
        1,
        33,
        18,
        1,
        47,
        27,
        0,
        19,
        37,
        7,
        0,
        38,
        49,
        32,
        49,
        22,
        49,
        0,
        49,
        46,
        48,
        27,
        29,
        49,
        31,
        49,
        18,
        12,
        49,
        49,
        41,
        23,
        34,
        0,
        0,
        48,
        28,
        5,
        2,
        49,
        33,
        35
      ],
      "weeklyBackordered": [
        49,
        19,
        0,
        43,
        49,
        46,
        49,
        10,
        45,
        8,
        49,
        27,
        8,
        3,
        12,
        40,
        7,
        30,
        0,
        25,
        0,
        19,
        22,
        23,
        31,
        24,
        1,
        0,
        1,
        17,
        29,
        15,
        28,
        37,
        0,
        0,
        35,
        31,
        47,
        12,
        43,
        48,
        9,
        42,
        0,
        34,
        30,
        40,
        13,
        25,
        29,
        39
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        0,
        49,
        33,
        0,
        31,
        22,
        22,
        13,
        44,
        1,
        32,
        0,
        29,
        41,
        4,
        38,
        46,
        0,
        11,
        49,
        0,
        9,
        27,
        37,
        17,
        44,
        38,
        17,
        17,
        42,
        0,
        6,
        2,
        0,
        1,
        35,
        0,
        25,
        49,
        28,
        9,
        19,
        45,
        35,
        49,
        37,
        37,
        43,
        9,
        35,
        21,
        25
      ],
      "weeklyRejected": [
        21,
        35,
        45,
        9,
        49,
        3,
        39,
        34,
        14,
        26,
        34,
        23,
        38,
        48,
        9,
        49,
        49,
        46,
        0,
        29,
        49,
        0,
        43,
        47,
        2,
        49,
        49,
        18,
        48,
        34,
        19,
        24,
        46,
        33,
        8,
        23,
        23,
        49,
        49,
        49,
        46,
        34,
        40,
        0,
        49,
        25,
        4,
        18,
        21,
        49,
        15,
        42
      ],
      "weeklyHold": [
        36,
        29,
        46,
        1,
        22,
        30,
        31,
        19,
        17,
        33,
        47,
        49,
        48,
        1,
        13,
        0,
        13,
        1,
        11,
        49,
        30,
        49,
        0,
        49,
        2,
        11,
        42,
        44,
        26,
        8,
        35,
        17,
        0,
        0,
        48,
        0,
        18,
        6,
        29,
        21,
        0,
        28,
        16,
        0,
        39,
        49,
        0,
        30,
        41,
        40,
        49,
        17
      ],
      "weeklyBackordered": [
        20,
        49,
        0,
        33,
        41,
        44,
        17,
        24,
        43,
        0,
        49,
        31,
        6,
        47,
        40,
        27,
        49,
        15,
        0,
        49,
        18,
        49,
        49,
        37,
        7,
        28,
        1,
        36,
        34,
        38,
        0,
        49,
        10,
        43,
        0,
        14,
        49,
        0,
        0,
        12,
        39,
        23,
        0,
        49,
        42,
        26,
        36,
        21,
        30,
        49,
        1,
        39
      ]
    },
    {
      "id": "FB70040E",
      "weeklyAccepted": [
        7,
        49,
        49,
        36,
        21,
        26,
        1,
        49,
        22,
        41,
        49,
        5,
        5,
        35,
        19,
        41,
        40,
        0,
        5,
        6,
        49,
        32,
        49,
        0,
        30,
        16,
        49,
        0,
        1,
        9,
        1,
        18,
        37,
        0,
        8,
        48,
        28,
        48,
        8,
        49,
        26,
        20,
        43,
        49,
        49,
        0,
        28,
        15,
        5,
        46,
        18,
        6
      ],
      "weeklyRejected": [
        40,
        22,
        25,
        23,
        22,
        49,
        13,
        32,
        49,
        3,
        41,
        38,
        46,
        48,
        22,
        49,
        35,
        49,
        16,
        27,
        11,
        43,
        49,
        49,
        39,
        33,
        38,
        27,
        48,
        29,
        49,
        27,
        24,
        35,
        19,
        49,
        49,
        45,
        23,
        28,
        39,
        0,
        25,
        0,
        49,
        17,
        3,
        23,
        31,
        49,
        29,
        20
      ],
      "weeklyHold": [
        25,
        44,
        49,
        24,
        49,
        48,
        0,
        49,
        45,
        0,
        0,
        49,
        44,
        31,
        37,
        9,
        28,
        21,
        0,
        49,
        47,
        48,
        10,
        48,
        34,
        27,
        36,
        3,
        49,
        22,
        48,
        24,
        0,
        49,
        22,
        27,
        16,
        0,
        35,
        18,
        39,
        25,
        0,
        0,
        49,
        31,
        18,
        41,
        10,
        37,
        48,
        23
      ],
      "weeklyBackordered": [
        36,
        14,
        25,
        49,
        49,
        38,
        0,
        26,
        4,
        40,
        46,
        49,
        49,
        5,
        45,
        9,
        48,
        45,
        15,
        33,
        42,
        38,
        21,
        10,
        1,
        16,
        1,
        22,
        18,
        49,
        15,
        49,
        46,
        35,
        16,
        12,
        40,
        0,
        42,
        29,
        5,
        48,
        22,
        49,
        19,
        14,
        31,
        21,
        6,
        49,
        31,
        31
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        38,
        35,
        27,
        37,
        14,
        0,
        29,
        48,
        20,
        49,
        26,
        0,
        49,
        0,
        49,
        13,
        30,
        20,
        22,
        19,
        44,
        49,
        9,
        24,
        42,
        34,
        49,
        49,
        49,
        49,
        49,
        1,
        42,
        19,
        48,
        3,
        47,
        15,
        49,
        48,
        7,
        49,
        15,
        30,
        23,
        10,
        14,
        11,
        41,
        49,
        49,
        11
      ],
      "weeklyRejected": [
        41,
        10,
        23,
        0,
        25,
        49,
        49,
        32,
        37,
        1,
        0,
        12,
        16,
        48,
        33,
        0,
        35,
        37,
        49,
        0,
        18,
        17,
        28,
        49,
        39,
        39,
        7,
        28,
        36,
        21,
        47,
        47,
        26,
        18,
        35,
        40,
        34,
        35,
        14,
        10,
        12,
        37,
        42,
        17,
        18,
        19,
        0,
        49,
        28,
        0,
        34,
        31
      ],
      "weeklyHold": [
        38,
        5,
        49,
        1,
        38,
        48,
        21,
        2,
        4,
        23,
        47,
        5,
        4,
        32,
        49,
        47,
        49,
        39,
        20,
        22,
        0,
        49,
        49,
        0,
        24,
        41,
        37,
        21,
        49,
        49,
        36,
        38,
        12,
        39,
        48,
        49,
        24,
        0,
        49,
        35,
        30,
        49,
        12,
        22,
        48,
        5,
        34,
        30,
        26,
        32,
        49,
        49
      ],
      "weeklyBackordered": [
        33,
        45,
        20,
        24,
        38,
        46,
        49,
        42,
        45,
        0,
        49,
        45,
        30,
        31,
        23,
        8,
        26,
        49,
        6,
        36,
        27,
        0,
        6,
        3,
        48,
        24,
        17,
        49,
        18,
        49,
        49,
        49,
        26,
        49,
        0,
        0,
        49,
        35,
        19,
        36,
        25,
        31,
        26,
        32,
        15,
        37,
        14,
        36,
        25,
        48,
        6,
        1
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        24,
        41,
        27,
        25,
        21,
        34,
        20,
        27,
        41,
        1,
        49,
        17,
        49,
        49,
        27,
        19,
        28,
        29,
        0,
        12,
        18,
        49,
        29,
        49,
        11,
        27,
        34,
        49,
        15,
        36,
        28,
        0,
        49,
        0,
        23,
        43,
        19,
        3,
        49,
        25,
        36,
        29,
        39,
        20,
        49,
        11,
        27,
        48,
        32,
        24,
        28,
        0
      ],
      "weeklyRejected": [
        33,
        15,
        49,
        28,
        41,
        22,
        49,
        0,
        49,
        37,
        18,
        24,
        13,
        25,
        48,
        37,
        0,
        31,
        13,
        0,
        1,
        29,
        0,
        29,
        18,
        37,
        27,
        38,
        40,
        36,
        49,
        27,
        48,
        21,
        24,
        49,
        15,
        1,
        1,
        26,
        27,
        39,
        38,
        11,
        36,
        0,
        27,
        35,
        44,
        46,
        0,
        11
      ],
      "weeklyHold": [
        0,
        33,
        27,
        16,
        49,
        35,
        49,
        2,
        0,
        32,
        47,
        39,
        48,
        9,
        42,
        15,
        0,
        6,
        0,
        24,
        0,
        21,
        21,
        49,
        49,
        38,
        0,
        1,
        49,
        0,
        43,
        23,
        4,
        28,
        48,
        0,
        3,
        20,
        15,
        40,
        28,
        35,
        36,
        0,
        42,
        41,
        0,
        29,
        8,
        39,
        38,
        33
      ],
      "weeklyBackordered": [
        18,
        29,
        0,
        16,
        31,
        26,
        48,
        38,
        24,
        16,
        49,
        7,
        12,
        21,
        4,
        1,
        36,
        31,
        17,
        0,
        29,
        0,
        7,
        49,
        2,
        1,
        7,
        31,
        12,
        33,
        30,
        49,
        31,
        49,
        0,
        17,
        17,
        14,
        3,
        49,
        29,
        45,
        3,
        23,
        13,
        1,
        49,
        32,
        28,
        49,
        49,
        18
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        49,
        6,
        25,
        31,
        49,
        0,
        15,
        49,
        47,
        19,
        49,
        35,
        47,
        0,
        1,
        49,
        44,
        2,
        0,
        28,
        30,
        16,
        49,
        39,
        49,
        24,
        18,
        47,
        21,
        32,
        23,
        31,
        27,
        0,
        30,
        20,
        19,
        34,
        18,
        49,
        34,
        40,
        49,
        48,
        49,
        19,
        6,
        49,
        44,
        30,
        28,
        46
      ],
      "weeklyRejected": [
        49,
        19,
        25,
        14,
        49,
        0,
        24,
        17,
        41,
        8,
        48,
        15,
        40,
        36,
        48,
        20,
        26,
        41,
        0,
        49,
        11,
        40,
        8,
        26,
        1,
        41,
        49,
        32,
        17,
        0,
        36,
        35,
        48,
        22,
        15,
        48,
        19,
        0,
        9,
        0,
        48,
        4,
        32,
        6,
        33,
        0,
        49,
        49,
        26,
        49,
        13,
        32
      ],
      "weeklyHold": [
        0,
        6,
        49,
        1,
        49,
        17,
        0,
        20,
        17,
        25,
        43,
        49,
        1,
        38,
        29,
        8,
        18,
        9,
        0,
        0,
        6,
        1,
        37,
        7,
        14,
        41,
        30,
        13,
        36,
        32,
        28,
        33,
        22,
        36,
        48,
        19,
        19,
        10,
        29,
        21,
        1,
        24,
        26,
        47,
        21,
        24,
        13,
        25,
        0,
        17,
        30,
        38
      ],
      "weeklyBackordered": [
        40,
        43,
        48,
        41,
        31,
        28,
        29,
        49,
        49,
        23,
        22,
        34,
        17,
        25,
        16,
        24,
        21,
        40,
        17,
        49,
        29,
        8,
        0,
        0,
        1,
        17,
        31,
        7,
        1,
        47,
        37,
        0,
        8,
        49,
        22,
        0,
        49,
        40,
        13,
        7,
        31,
        48,
        4,
        17,
        0,
        49,
        35,
        36,
        0,
        45,
        8,
        21
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyAccepted": [
        12,
        49,
        31,
        14,
        38,
        28,
        24,
        49,
        48,
        49,
        24,
        11,
        0,
        0,
        5,
        21,
        47,
        2,
        6,
        13,
        45,
        26,
        49,
        33,
        0,
        21,
        0,
        14,
        1,
        11,
        49,
        22,
        37,
        49,
        35,
        42,
        19,
        5,
        31,
        49,
        41,
        30,
        49,
        35,
        49,
        2,
        21,
        41,
        46,
        46,
        13,
        48
      ],
      "weeklyRejected": [
        43,
        47,
        49,
        29,
        0,
        0,
        0,
        36,
        21,
        39,
        49,
        22,
        32,
        48,
        21,
        12,
        37,
        49,
        41,
        13,
        6,
        31,
        16,
        18,
        16,
        49,
        43,
        8,
        48,
        27,
        49,
        41,
        24,
        49,
        49,
        37,
        21,
        48,
        6,
        13,
        41,
        40,
        1,
        21,
        21,
        0,
        12,
        0,
        0,
        8,
        8,
        49
      ],
      "weeklyHold": [
        49,
        8,
        20,
        1,
        0,
        9,
        10,
        49,
        48,
        38,
        27,
        20,
        37,
        12,
        30,
        33,
        10,
        26,
        44,
        33,
        35,
        37,
        34,
        3,
        0,
        9,
        47,
        49,
        25,
        19,
        28,
        0,
        0,
        26,
        27,
        0,
        44,
        24,
        30,
        23,
        15,
        49,
        22,
        13,
        48,
        49,
        49,
        0,
        0,
        22,
        27,
        20
      ],
      "weeklyBackordered": [
        27,
        49,
        30,
        14,
        32,
        34,
        15,
        31,
        31,
        0,
        20,
        49,
        3,
        0,
        49,
        23,
        45,
        20,
        1,
        5,
        24,
        14,
        36,
        23,
        48,
        12,
        32,
        33,
        18,
        46,
        21,
        41,
        38,
        18,
        8,
        5,
        25,
        0,
        33,
        36,
        48,
        25,
        14,
        32,
        15,
        24,
        49,
        14,
        11,
        37,
        38,
        5
      ]
    },
    {
      "id": "BB20277D",
      "weeklyAccepted": [
        26,
        24,
        20,
        32,
        24,
        0,
        1,
        10,
        26,
        33,
        1,
        18,
        49,
        41,
        34,
        25,
        42,
        5,
        0,
        39,
        2,
        13,
        40,
        48,
        28,
        38,
        14,
        10,
        11,
        9,
        49,
        0,
        31,
        2,
        48,
        40,
        0,
        8,
        42,
        49,
        45,
        37,
        49,
        44,
        26,
        0,
        10,
        49,
        10,
        34,
        9,
        26
      ],
      "weeklyRejected": [
        42,
        23,
        37,
        40,
        44,
        0,
        16,
        29,
        46,
        18,
        24,
        18,
        34,
        48,
        48,
        27,
        49,
        49,
        9,
        23,
        0,
        49,
        7,
        28,
        15,
        48,
        24,
        16,
        47,
        25,
        34,
        39,
        10,
        33,
        29,
        49,
        23,
        0,
        1,
        12,
        43,
        49,
        39,
        31,
        48,
        6,
        10,
        26,
        0,
        24,
        0,
        49
      ],
      "weeklyHold": [
        17,
        43,
        49,
        17,
        28,
        29,
        0,
        16,
        20,
        27,
        19,
        49,
        1,
        36,
        0,
        0,
        21,
        46,
        0,
        20,
        42,
        25,
        5,
        3,
        5,
        24,
        28,
        12,
        49,
        0,
        48,
        15,
        0,
        26,
        19,
        7,
        38,
        0,
        1,
        25,
        23,
        15,
        0,
        31,
        31,
        11,
        14,
        49,
        10,
        24,
        32,
        28
      ],
      "weeklyBackordered": [
        10,
        7,
        26,
        26,
        47,
        18,
        46,
        48,
        26,
        45,
        14,
        40,
        26,
        49,
        30,
        16,
        16,
        0,
        0,
        31,
        41,
        30,
        0,
        28,
        1,
        35,
        1,
        10,
        11,
        21,
        3,
        35,
        13,
        24,
        46,
        11,
        24,
        24,
        7,
        7,
        15,
        17,
        16,
        42,
        0,
        14,
        16,
        2,
        0,
        25,
        47,
        11
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        28,
        21,
        48,
        0,
        31,
        34,
        22,
        16,
        48,
        17,
        41,
        11,
        24,
        26,
        17,
        40,
        20,
        9,
        34,
        22,
        0,
        38,
        49,
        43,
        19,
        44,
        14,
        36,
        49,
        41,
        31,
        0,
        37,
        32,
        48,
        38,
        33,
        3,
        42,
        43,
        26,
        29,
        26,
        26,
        31,
        4,
        13,
        41,
        12,
        49,
        7,
        8
      ],
      "weeklyRejected": [
        21,
        17,
        2,
        25,
        0,
        23,
        42,
        11,
        47,
        12,
        4,
        0,
        12,
        48,
        25,
        14,
        11,
        49,
        49,
        21,
        25,
        49,
        49,
        6,
        15,
        44,
        34,
        13,
        6,
        34,
        25,
        27,
        14,
        46,
        3,
        49,
        49,
        8,
        7,
        10,
        45,
        49,
        0,
        12,
        20,
        29,
        4,
        15,
        0,
        0,
        0,
        3
      ],
      "weeklyHold": [
        7,
        22,
        40,
        29,
        15,
        28,
        46,
        36,
        49,
        7,
        23,
        18,
        15,
        19,
        49,
        7,
        6,
        28,
        43,
        0,
        24,
        36,
        19,
        49,
        33,
        9,
        0,
        10,
        49,
        21,
        45,
        0,
        0,
        40,
        40,
        38,
        49,
        7,
        35,
        19,
        26,
        42,
        32,
        49,
        49,
        49,
        20,
        26,
        9,
        43,
        25,
        18
      ],
      "weeklyBackordered": [
        47,
        5,
        14,
        0,
        45,
        20,
        49,
        49,
        36,
        16,
        18,
        0,
        9,
        21,
        10,
        19,
        4,
        49,
        0,
        3,
        5,
        0,
        0,
        49,
        39,
        1,
        1,
        18,
        38,
        49,
        7,
        4,
        48,
        35,
        49,
        22,
        9,
        15,
        49,
        19,
        28,
        14,
        13,
        39,
        19,
        23,
        20,
        22,
        24,
        33,
        49,
        1
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        0,
        49,
        49,
        48,
        11,
        12,
        31,
        41,
        26,
        0,
        49,
        36,
        27,
        14,
        8,
        7,
        24,
        17,
        11,
        26,
        49,
        48,
        13,
        0,
        0,
        46,
        49,
        16,
        32,
        41,
        11,
        33,
        40,
        7,
        20,
        12,
        32,
        48,
        33,
        20,
        0,
        0,
        37,
        49,
        49,
        0,
        8,
        24,
        12,
        12,
        28,
        48
      ],
      "weeklyRejected": [
        46,
        0,
        26,
        47,
        49,
        36,
        49,
        2,
        13,
        48,
        0,
        22,
        8,
        45,
        11,
        7,
        26,
        49,
        20,
        44,
        0,
        18,
        29,
        38,
        10,
        23,
        13,
        49,
        48,
        15,
        2,
        42,
        23,
        23,
        27,
        13,
        49,
        20,
        8,
        20,
        21,
        49,
        12,
        0,
        49,
        7,
        22,
        38,
        17,
        44,
        0,
        21
      ],
      "weeklyHold": [
        5,
        15,
        45,
        1,
        49,
        48,
        10,
        27,
        43,
        17,
        39,
        30,
        24,
        46,
        22,
        10,
        0,
        21,
        2,
        0,
        0,
        49,
        25,
        1,
        26,
        26,
        34,
        10,
        4,
        0,
        48,
        39,
        2,
        40,
        19,
        40,
        49,
        13,
        20,
        15,
        27,
        49,
        0,
        21,
        19,
        24,
        49,
        29,
        11,
        23,
        48,
        41
      ],
      "weeklyBackordered": [
        35,
        24,
        12,
        27,
        34,
        40,
        48,
        1,
        39,
        29,
        34,
        34,
        3,
        49,
        19,
        1,
        16,
        49,
        31,
        49,
        49,
        44,
        41,
        0,
        22,
        1,
        21,
        8,
        49,
        49,
        7,
        33,
        26,
        9,
        5,
        0,
        20,
        14,
        34,
        49,
        28,
        48,
        15,
        42,
        21,
        22,
        5,
        20,
        20,
        36,
        40,
        6
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        49,
        39,
        0,
        24,
        47,
        16,
        19,
        49,
        48,
        39,
        21,
        49,
        13,
        34,
        8,
        23,
        12,
        11,
        0,
        31,
        0,
        21,
        25,
        25,
        0,
        2,
        32,
        46,
        1,
        21,
        13,
        14,
        20,
        39,
        23,
        49,
        47,
        29,
        26,
        49,
        20,
        30,
        33,
        49,
        8,
        26,
        14,
        0,
        11,
        48,
        15,
        28
      ],
      "weeklyRejected": [
        49,
        13,
        32,
        16,
        32,
        20,
        30,
        0,
        49,
        13,
        49,
        22,
        33,
        48,
        38,
        37,
        34,
        49,
        9,
        49,
        23,
        13,
        29,
        40,
        36,
        22,
        49,
        41,
        34,
        10,
        10,
        47,
        48,
        49,
        0,
        49,
        42,
        15,
        24,
        40,
        26,
        43,
        49,
        0,
        18,
        21,
        0,
        49,
        20,
        26,
        18,
        43
      ],
      "weeklyHold": [
        36,
        49,
        28,
        1,
        40,
        38,
        18,
        2,
        0,
        22,
        14,
        49,
        46,
        49,
        0,
        17,
        20,
        1,
        49,
        38,
        22,
        27,
        32,
        18,
        13,
        1,
        0,
        15,
        33,
        28,
        14,
        37,
        14,
        41,
        48,
        0,
        28,
        0,
        49,
        12,
        38,
        49,
        7,
        34,
        33,
        26,
        26,
        34,
        0,
        28,
        13,
        0
      ],
      "weeklyBackordered": [
        49,
        15,
        0,
        9,
        18,
        46,
        0,
        1,
        14,
        0,
        49,
        0,
        3,
        17,
        11,
        25,
        46,
        49,
        8,
        49,
        15,
        29,
        29,
        45,
        1,
        44,
        16,
        27,
        37,
        49,
        0,
        49,
        1,
        49,
        0,
        0,
        35,
        18,
        18,
        36,
        0,
        48,
        0,
        38,
        31,
        14,
        49,
        18,
        22,
        24,
        49,
        21
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        5,
        29,
        11,
        0,
        38,
        0,
        5,
        45,
        48,
        12,
        0,
        0,
        43,
        43,
        22,
        0,
        46,
        38,
        0,
        27,
        47,
        49,
        29,
        49,
        10,
        13,
        19,
        9,
        49,
        47,
        0,
        49,
        49,
        35,
        6,
        33,
        23,
        17,
        29,
        49,
        40,
        33,
        30,
        22,
        43,
        0,
        41,
        45,
        0,
        0,
        49,
        48
      ],
      "weeklyRejected": [
        23,
        9,
        14,
        8,
        20,
        42,
        29,
        0,
        49,
        44,
        44,
        25,
        0,
        22,
        35,
        24,
        0,
        49,
        49,
        3,
        21,
        49,
        20,
        46,
        0,
        49,
        40,
        49,
        10,
        18,
        42,
        27,
        4,
        45,
        41,
        24,
        33,
        5,
        36,
        49,
        38,
        49,
        15,
        23,
        49,
        22,
        1,
        0,
        10,
        15,
        0,
        48
      ],
      "weeklyHold": [
        8,
        43,
        49,
        29,
        37,
        27,
        49,
        2,
        23,
        21,
        39,
        49,
        42,
        16,
        34,
        45,
        42,
        0,
        17,
        28,
        7,
        49,
        0,
        26,
        46,
        18,
        48,
        0,
        49,
        34,
        38,
        18,
        0,
        49,
        36,
        37,
        0,
        43,
        16,
        14,
        34,
        49,
        21,
        38,
        48,
        36,
        11,
        49,
        9,
        27,
        41,
        49
      ],
      "weeklyBackordered": [
        0,
        31,
        0,
        33,
        14,
        26,
        49,
        28,
        49,
        15,
        23,
        44,
        13,
        49,
        4,
        12,
        49,
        5,
        0,
        10,
        8,
        0,
        11,
        49,
        22,
        23,
        1,
        11,
        31,
        27,
        49,
        49,
        27,
        42,
        8,
        4,
        19,
        27,
        19,
        18,
        39,
        28,
        11,
        34,
        22,
        38,
        48,
        18,
        49,
        48,
        18,
        39
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        10,
        34,
        42,
        48,
        15,
        0,
        7,
        49,
        33,
        28,
        44,
        0,
        35,
        41,
        40,
        34,
        45,
        29,
        0,
        30,
        35,
        36,
        49,
        28,
        35,
        38,
        49,
        24,
        15,
        28,
        0,
        49,
        37,
        0,
        21,
        42,
        31,
        1,
        49,
        49,
        14,
        0,
        49,
        30,
        49,
        0,
        13,
        34,
        0,
        44,
        43,
        4
      ],
      "weeklyRejected": [
        30,
        49,
        41,
        47,
        0,
        32,
        49,
        26,
        19,
        9,
        29,
        5,
        38,
        27,
        48,
        30,
        18,
        49,
        25,
        7,
        40,
        38,
        0,
        30,
        13,
        13,
        10,
        17,
        48,
        30,
        13,
        44,
        47,
        49,
        35,
        49,
        16,
        14,
        44,
        21,
        32,
        28,
        49,
        32,
        46,
        30,
        32,
        23,
        18,
        31,
        0,
        29
      ],
      "weeklyHold": [
        49,
        0,
        49,
        1,
        23,
        12,
        49,
        21,
        4,
        35,
        14,
        1,
        43,
        35,
        40,
        0,
        6,
        0,
        19,
        49,
        49,
        49,
        29,
        44,
        30,
        20,
        33,
        0,
        22,
        32,
        48,
        13,
        0,
        22,
        42,
        15,
        9,
        0,
        1,
        24,
        49,
        14,
        35,
        34,
        35,
        17,
        9,
        49,
        0,
        34,
        45,
        24
      ],
      "weeklyBackordered": [
        0,
        4,
        0,
        39,
        46,
        0,
        18,
        49,
        49,
        49,
        23,
        49,
        40,
        21,
        29,
        18,
        28,
        49,
        32,
        33,
        7,
        31,
        37,
        15,
        25,
        25,
        14,
        22,
        15,
        38,
        0,
        49,
        28,
        48,
        24,
        0,
        27,
        0,
        19,
        49,
        27,
        48,
        4,
        49,
        0,
        44,
        42,
        15,
        0,
        49,
        49,
        11
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        14,
        34,
        0,
        0,
        15,
        3,
        21,
        15,
        30,
        22,
        46,
        0,
        11,
        49,
        1,
        22,
        20,
        34,
        6,
        32,
        1,
        49,
        11,
        16,
        6,
        0,
        6,
        2,
        7,
        25,
        49,
        0,
        5,
        14,
        35,
        2,
        47,
        27,
        44,
        49,
        29,
        23,
        15,
        26,
        49,
        49,
        5,
        7,
        14,
        33,
        7,
        38
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        0,
        49,
        10,
        29,
        27,
        49,
        15,
        2,
        24,
        36,
        48,
        11,
        47,
        18,
        31,
        49,
        27,
        49,
        0,
        49,
        8,
        28,
        36,
        6,
        32,
        5,
        0,
        15,
        47,
        44,
        3,
        36,
        37,
        25,
        40,
        7,
        33,
        0,
        32,
        40,
        14,
        30,
        6,
        0,
        49,
        23,
        5,
        0,
        20
      ],
      "weeklyHold": [
        36,
        42,
        45,
        24,
        43,
        17,
        33,
        26,
        23,
        13,
        28,
        16,
        37,
        4,
        13,
        49,
        20,
        14,
        47,
        0,
        3,
        0,
        49,
        49,
        8,
        25,
        23,
        30,
        13,
        4,
        48,
        0,
        0,
        26,
        40,
        37,
        0,
        18,
        48,
        49,
        28,
        49,
        2,
        28,
        43,
        6,
        49,
        34,
        15,
        12,
        37,
        0
      ],
      "weeklyBackordered": [
        32,
        49,
        44,
        21,
        38,
        31,
        43,
        17,
        49,
        0,
        15,
        46,
        7,
        25,
        38,
        49,
        49,
        31,
        21,
        49,
        7,
        0,
        1,
        10,
        1,
        32,
        12,
        27,
        49,
        49,
        49,
        29,
        0,
        49,
        16,
        0,
        21,
        26,
        20,
        31,
        0,
        31,
        4,
        45,
        38,
        41,
        17,
        12,
        21,
        49,
        39,
        32
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        24,
        7,
        28,
        17,
        17,
        7,
        11,
        26,
        48,
        33,
        30,
        25,
        40,
        41,
        36,
        46,
        49,
        43,
        7,
        29,
        39,
        32,
        23,
        49,
        0,
        47,
        9,
        1,
        21,
        24,
        34,
        14,
        49,
        45,
        48,
        44,
        30,
        2,
        21,
        36,
        21,
        39,
        45,
        30,
        13,
        20,
        15,
        45,
        43,
        49,
        49,
        1
      ],
      "weeklyRejected": [
        34,
        15,
        34,
        38,
        0,
        4,
        49,
        37,
        45,
        18,
        5,
        0,
        17,
        43,
        48,
        10,
        9,
        49,
        22,
        0,
        49,
        33,
        18,
        29,
        0,
        49,
        40,
        3,
        33,
        19,
        22,
        21,
        21,
        0,
        16,
        49,
        0,
        13,
        6,
        42,
        39,
        47,
        26,
        25,
        39,
        3,
        7,
        20,
        0,
        37,
        0,
        46
      ],
      "weeklyHold": [
        24,
        44,
        0,
        38,
        2,
        48,
        28,
        9,
        0,
        29,
        42,
        31,
        42,
        18,
        32,
        0,
        25,
        0,
        0,
        31,
        47,
        2,
        1,
        24,
        36,
        46,
        9,
        34,
        48,
        1,
        39,
        8,
        12,
        20,
        13,
        32,
        49,
        0,
        7,
        37,
        12,
        36,
        11,
        46,
        3,
        49,
        22,
        32,
        24,
        1,
        25,
        41
      ],
      "weeklyBackordered": [
        43,
        14,
        21,
        11,
        49,
        39,
        23,
        49,
        49,
        20,
        34,
        29,
        7,
        45,
        10,
        10,
        37,
        22,
        0,
        9,
        21,
        41,
        6,
        26,
        1,
        38,
        49,
        19,
        24,
        25,
        1,
        49,
        19,
        30,
        16,
        7,
        47,
        12,
        0,
        0,
        31,
        21,
        0,
        27,
        26,
        36,
        20,
        19,
        18,
        23,
        35,
        8
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        18,
        2,
        14,
        32,
        2,
        8,
        15,
        37,
        26,
        24,
        8,
        11,
        39,
        21,
        1,
        12,
        45,
        12,
        0,
        49,
        42,
        19,
        22,
        18,
        43,
        45,
        23,
        13,
        1,
        46,
        28,
        11,
        49,
        41,
        18,
        41,
        26,
        43,
        47,
        49,
        1,
        27,
        35,
        42,
        4,
        12,
        10,
        16,
        17,
        48,
        10,
        48
      ],
      "weeklyRejected": [
        32,
        25,
        37,
        38,
        37,
        49,
        12,
        24,
        49,
        22,
        16,
        19,
        16,
        35,
        45,
        23,
        40,
        34,
        49,
        49,
        0,
        36,
        0,
        42,
        15,
        26,
        29,
        39,
        48,
        20,
        16,
        36,
        40,
        49,
        37,
        32,
        34,
        22,
        10,
        49,
        35,
        44,
        31,
        0,
        35,
        13,
        32,
        49,
        16,
        32,
        27,
        45
      ],
      "weeklyHold": [
        33,
        22,
        21,
        1,
        49,
        15,
        1,
        37,
        12,
        49,
        24,
        28,
        31,
        1,
        39,
        8,
        49,
        25,
        36,
        11,
        1,
        0,
        38,
        18,
        17,
        30,
        14,
        5,
        28,
        24,
        32,
        10,
        4,
        30,
        28,
        49,
        35,
        45,
        35,
        13,
        21,
        30,
        0,
        16,
        49,
        25,
        36,
        49,
        0,
        14,
        32,
        16
      ],
      "weeklyBackordered": [
        12,
        49,
        30,
        0,
        32,
        46,
        47,
        21,
        33,
        17,
        26,
        49,
        28,
        1,
        49,
        27,
        1,
        32,
        21,
        39,
        44,
        11,
        0,
        49,
        1,
        35,
        1,
        15,
        21,
        49,
        2,
        16,
        22,
        41,
        0,
        12,
        31,
        45,
        9,
        32,
        48,
        29,
        27,
        49,
        0,
        18,
        16,
        0,
        30,
        35,
        28,
        1
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        42,
        34,
        18,
        43,
        17,
        32,
        2,
        22,
        27,
        33,
        43,
        0,
        26,
        13,
        1,
        25,
        35,
        23,
        5,
        0,
        35,
        49,
        49,
        8,
        18,
        47,
        0,
        49,
        28,
        49,
        28,
        32,
        25,
        31,
        35,
        4,
        13,
        30,
        19,
        49,
        17,
        33,
        0,
        0,
        49,
        1,
        0,
        24,
        0,
        29,
        7,
        23
      ],
      "weeklyRejected": [
        49,
        49,
        27,
        47,
        17,
        20,
        49,
        49,
        35,
        2,
        47,
        0,
        8,
        48,
        21,
        3,
        31,
        26,
        22,
        49,
        49,
        1,
        18,
        48,
        29,
        49,
        49,
        41,
        48,
        0,
        5,
        26,
        35,
        49,
        39,
        49,
        42,
        45,
        1,
        0,
        24,
        49,
        37,
        26,
        49,
        15,
        24,
        12,
        0,
        47,
        0,
        40
      ],
      "weeklyHold": [
        49,
        35,
        40,
        3,
        0,
        19,
        23,
        14,
        48,
        7,
        47,
        8,
        42,
        24,
        3,
        5,
        2,
        38,
        45,
        19,
        34,
        21,
        8,
        0,
        36,
        23,
        0,
        33,
        49,
        0,
        41,
        48,
        17,
        32,
        27,
        11,
        32,
        0,
        39,
        20,
        14,
        23,
        20,
        49,
        36,
        0,
        4,
        40,
        28,
        16,
        35,
        33
      ],
      "weeklyBackordered": [
        49,
        23,
        18,
        27,
        49,
        46,
        39,
        42,
        16,
        1,
        13,
        46,
        16,
        27,
        11,
        30,
        23,
        43,
        0,
        49,
        25,
        18,
        0,
        46,
        10,
        6,
        16,
        14,
        4,
        49,
        41,
        22,
        28,
        18,
        5,
        20,
        1,
        11,
        0,
        8,
        15,
        48,
        39,
        30,
        19,
        14,
        44,
        17,
        32,
        49,
        35,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        23,
        13,
        17,
        21,
        49,
        13,
        26,
        49,
        48,
        29,
        43,
        0,
        37,
        49,
        31,
        0,
        43,
        45,
        0,
        33,
        3,
        49,
        32,
        33,
        30,
        32,
        12,
        47,
        10,
        43,
        8,
        10,
        49,
        6,
        11,
        29,
        47,
        27,
        31,
        49,
        35,
        32,
        42,
        40,
        46,
        31,
        0,
        16,
        0,
        3,
        15,
        25
      ],
      "weeklyRejected": [
        41,
        47,
        17,
        0,
        38,
        7,
        29,
        24,
        32,
        39,
        25,
        38,
        42,
        37,
        48,
        45,
        0,
        33,
        26,
        3,
        22,
        0,
        49,
        21,
        8,
        49,
        49,
        26,
        33,
        25,
        16,
        34,
        48,
        42,
        23,
        47,
        46,
        35,
        1,
        0,
        11,
        20,
        49,
        49,
        49,
        4,
        30,
        16,
        0,
        0,
        31,
        0
      ],
      "weeklyHold": [
        8,
        49,
        49,
        1,
        0,
        3,
        6,
        19,
        13,
        15,
        27,
        31,
        7,
        48,
        9,
        49,
        49,
        37,
        33,
        0,
        0,
        46,
        0,
        6,
        15,
        29,
        44,
        36,
        39,
        28,
        40,
        48,
        0,
        29,
        43,
        28,
        33,
        29,
        13,
        36,
        26,
        49,
        12,
        43,
        33,
        0,
        45,
        26,
        4,
        19,
        38,
        29
      ],
      "weeklyBackordered": [
        32,
        37,
        16,
        36,
        49,
        24,
        31,
        1,
        29,
        40,
        37,
        2,
        21,
        30,
        21,
        43,
        49,
        0,
        12,
        16,
        48,
        24,
        0,
        40,
        32,
        29,
        33,
        0,
        38,
        46,
        0,
        43,
        25,
        28,
        8,
        26,
        7,
        15,
        31,
        16,
        28,
        48,
        0,
        49,
        31,
        33,
        46,
        17,
        27,
        27,
        19,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        22,
        29,
        49,
        0,
        16,
        5,
        46,
        2,
        29,
        1,
        49,
        0,
        49,
        49,
        1,
        49,
        0,
        40,
        0,
        24,
        6,
        49,
        0,
        43,
        0,
        20,
        13,
        16,
        31,
        30,
        0,
        0,
        45,
        22,
        27,
        49,
        43,
        48,
        15,
        49,
        37,
        40,
        37,
        41,
        38,
        0,
        16,
        19,
        25,
        0,
        48,
        47
      ],
      "weeklyRejected": [
        40,
        7,
        34,
        47,
        29,
        30,
        0,
        13,
        49,
        17,
        1,
        0,
        35,
        48,
        23,
        14,
        49,
        29,
        7,
        17,
        28,
        49,
        0,
        23,
        0,
        49,
        21,
        19,
        20,
        15,
        40,
        46,
        31,
        49,
        31,
        49,
        16,
        35,
        6,
        23,
        1,
        34,
        45,
        8,
        44,
        37,
        0,
        9,
        27,
        49,
        0,
        49
      ],
      "weeklyHold": [
        0,
        49,
        36,
        15,
        14,
        0,
        49,
        24,
        27,
        36,
        38,
        33,
        30,
        24,
        14,
        24,
        27,
        30,
        1,
        12,
        24,
        44,
        49,
        26,
        5,
        48,
        8,
        0,
        0,
        8,
        48,
        0,
        0,
        9,
        26,
        32,
        27,
        42,
        29,
        41,
        21,
        31,
        16,
        47,
        42,
        48,
        39,
        0,
        16,
        49,
        43,
        11
      ],
      "weeklyBackordered": [
        13,
        49,
        0,
        39,
        41,
        40,
        48,
        34,
        49,
        0,
        3,
        42,
        3,
        0,
        36,
        15,
        30,
        5,
        0,
        44,
        27,
        49,
        27,
        49,
        36,
        11,
        34,
        15,
        5,
        43,
        0,
        13,
        48,
        18,
        17,
        29,
        13,
        8,
        49,
        49,
        43,
        48,
        20,
        49,
        11,
        0,
        1,
        0,
        0,
        49,
        38,
        11
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        19,
        49,
        19,
        48,
        19,
        0,
        24,
        47,
        48,
        23,
        20,
        15,
        14,
        0,
        24,
        49,
        35,
        14,
        13,
        49,
        6,
        49,
        12,
        49,
        24,
        14,
        39,
        4,
        23,
        49,
        18,
        18,
        26,
        12,
        21,
        2,
        41,
        48,
        43,
        40,
        3,
        35,
        46,
        0,
        13,
        25,
        0,
        49,
        6,
        48,
        0,
        14
      ],
      "weeklyRejected": [
        29,
        16,
        48,
        20,
        17,
        8,
        49,
        41,
        43,
        1,
        46,
        18,
        32,
        44,
        48,
        21,
        23,
        43,
        41,
        40,
        22,
        8,
        7,
        6,
        22,
        46,
        43,
        46,
        48,
        0,
        37,
        0,
        48,
        0,
        21,
        42,
        28,
        0,
        49,
        28,
        14,
        36,
        41,
        12,
        46,
        26,
        49,
        49,
        21,
        43,
        16,
        40
      ],
      "weeklyHold": [
        21,
        0,
        49,
        29,
        1,
        38,
        0,
        31,
        45,
        12,
        45,
        38,
        30,
        20,
        16,
        0,
        5,
        0,
        0,
        19,
        13,
        25,
        20,
        15,
        49,
        5,
        38,
        17,
        24,
        23,
        31,
        32,
        0,
        46,
        9,
        34,
        39,
        16,
        49,
        38,
        28,
        35,
        27,
        28,
        9,
        13,
        32,
        49,
        0,
        29,
        19,
        42
      ],
      "weeklyBackordered": [
        0,
        37,
        0,
        0,
        12,
        15,
        21,
        20,
        33,
        30,
        44,
        0,
        34,
        49,
        41,
        7,
        41,
        46,
        0,
        37,
        46,
        49,
        16,
        48,
        37,
        1,
        12,
        49,
        12,
        21,
        34,
        37,
        45,
        49,
        3,
        0,
        46,
        16,
        23,
        37,
        18,
        7,
        5,
        21,
        0,
        9,
        49,
        24,
        32,
        49,
        31,
        18
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        13,
        49,
        49,
        16,
        6,
        0,
        31,
        21,
        48,
        43,
        47,
        0,
        49,
        47,
        44,
        30,
        49,
        39,
        0,
        37,
        8,
        49,
        23,
        29,
        36,
        43,
        0,
        49,
        49,
        23,
        0,
        14,
        41,
        0,
        48,
        32,
        25,
        16,
        27,
        19,
        0,
        32,
        49,
        33,
        20,
        18,
        15,
        19,
        22,
        12,
        36,
        2
      ],
      "weeklyRejected": [
        32,
        32,
        2,
        21,
        25,
        0,
        31,
        14,
        49,
        16,
        36,
        0,
        31,
        24,
        47,
        29,
        23,
        49,
        22,
        49,
        5,
        10,
        4,
        19,
        21,
        49,
        10,
        22,
        48,
        20,
        32,
        29,
        13,
        26,
        29,
        41,
        22,
        36,
        1,
        41,
        20,
        49,
        49,
        17,
        49,
        42,
        0,
        0,
        22,
        40,
        0,
        43
      ],
      "weeklyHold": [
        43,
        6,
        25,
        17,
        16,
        8,
        42,
        2,
        15,
        16,
        37,
        35,
        32,
        1,
        49,
        0,
        25,
        0,
        14,
        1,
        40,
        49,
        37,
        4,
        39,
        44,
        15,
        36,
        14,
        8,
        48,
        0,
        0,
        49,
        16,
        49,
        0,
        1,
        2,
        34,
        27,
        36,
        13,
        49,
        40,
        41,
        16,
        30,
        6,
        47,
        49,
        49
      ],
      "weeklyBackordered": [
        31,
        5,
        17,
        49,
        15,
        46,
        38,
        45,
        32,
        21,
        29,
        32,
        3,
        0,
        48,
        19,
        9,
        43,
        14,
        49,
        42,
        24,
        49,
        47,
        26,
        7,
        49,
        17,
        43,
        0,
        10,
        27,
        0,
        0,
        10,
        11,
        24,
        16,
        0,
        34,
        33,
        48,
        13,
        31,
        17,
        0,
        45,
        8,
        0,
        45,
        48,
        13
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        49,
        34,
        28,
        12,
        14,
        10,
        49,
        49,
        45,
        27,
        27,
        39,
        49,
        23,
        14,
        24,
        49,
        23,
        0,
        34,
        18,
        36,
        24,
        27,
        39,
        2,
        18,
        49,
        14,
        21,
        30,
        30,
        28,
        2,
        38,
        34,
        17,
        22,
        49,
        41,
        37,
        27,
        27,
        9,
        41,
        0,
        0,
        19,
        30,
        17,
        49,
        48
      ],
      "weeklyRejected": [
        35,
        34,
        31,
        25,
        14,
        10,
        49,
        9,
        49,
        1,
        41,
        24,
        12,
        48,
        28,
        0,
        0,
        29,
        49,
        0,
        24,
        35,
        49,
        30,
        18,
        48,
        25,
        49,
        48,
        34,
        49,
        41,
        40,
        7,
        0,
        49,
        6,
        49,
        19,
        9,
        42,
        0,
        31,
        27,
        49,
        49,
        15,
        12,
        28,
        16,
        0,
        44
      ],
      "weeklyHold": [
        5,
        9,
        32,
        1,
        12,
        37,
        27,
        2,
        10,
        23,
        19,
        28,
        1,
        19,
        21,
        43,
        1,
        0,
        21,
        36,
        23,
        30,
        22,
        8,
        0,
        45,
        49,
        12,
        49,
        0,
        15,
        0,
        3,
        37,
        12,
        16,
        18,
        20,
        43,
        14,
        46,
        41,
        21,
        33,
        39,
        25,
        17,
        15,
        0,
        21,
        34,
        22
      ],
      "weeklyBackordered": [
        16,
        7,
        19,
        1,
        26,
        26,
        49,
        24,
        49,
        19,
        49,
        32,
        18,
        6,
        0,
        42,
        49,
        25,
        21,
        0,
        49,
        0,
        16,
        49,
        48,
        19,
        29,
        21,
        6,
        49,
        15,
        49,
        48,
        25,
        17,
        0,
        16,
        0,
        35,
        1,
        30,
        48,
        21,
        49,
        49,
        12,
        49,
        24,
        30,
        35,
        14,
        12
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        33,
        49,
        30,
        28,
        41,
        0,
        1,
        33,
        36,
        12,
        49,
        0,
        24,
        33,
        27,
        49,
        29,
        34,
        32,
        40,
        25,
        32,
        30,
        27,
        0,
        35,
        31,
        30,
        1,
        49,
        19,
        29,
        49,
        39,
        48,
        19,
        38,
        17,
        40,
        32,
        44,
        36,
        46,
        16,
        49,
        6,
        9,
        12,
        21,
        21,
        0,
        16
      ],
      "weeklyRejected": [
        49,
        23,
        38,
        47,
        5,
        0,
        38,
        45,
        49,
        38,
        34,
        35,
        22,
        48,
        41,
        11,
        0,
        14,
        45,
        0,
        13,
        18,
        35,
        47,
        4,
        49,
        49,
        7,
        28,
        33,
        28,
        5,
        20,
        49,
        12,
        35,
        33,
        22,
        21,
        14,
        26,
        31,
        27,
        33,
        47,
        15,
        17,
        7,
        48,
        49,
        0,
        41
      ],
      "weeklyHold": [
        33,
        25,
        40,
        1,
        12,
        38,
        49,
        29,
        41,
        49,
        9,
        29,
        10,
        28,
        37,
        37,
        0,
        7,
        0,
        40,
        36,
        39,
        1,
        49,
        21,
        11,
        49,
        0,
        33,
        5,
        35,
        25,
        4,
        22,
        29,
        17,
        2,
        6,
        17,
        49,
        15,
        28,
        9,
        45,
        49,
        27,
        0,
        47,
        0,
        12,
        45,
        35
      ],
      "weeklyBackordered": [
        6,
        6,
        0,
        33,
        49,
        46,
        26,
        49,
        0,
        49,
        26,
        7,
        5,
        0,
        39,
        46,
        49,
        49,
        1,
        0,
        48,
        13,
        49,
        12,
        25,
        2,
        39,
        49,
        8,
        37,
        10,
        13,
        47,
        49,
        6,
        13,
        49,
        0,
        33,
        0,
        30,
        17,
        5,
        49,
        27,
        49,
        38,
        19,
        43,
        49,
        26,
        23
      ]
    },
    {
      "id": "F04529EA",
      "weeklyAccepted": [
        4,
        45,
        37,
        0,
        12,
        7,
        24,
        37,
        31,
        0,
        49,
        0,
        49,
        49,
        12,
        49,
        25,
        25,
        43,
        0,
        34,
        31,
        49,
        49,
        0,
        44,
        16,
        33,
        31,
        38,
        42,
        0,
        43,
        9,
        36,
        43,
        47,
        3,
        40,
        32,
        6,
        18,
        35,
        6,
        44,
        49,
        8,
        49,
        44,
        32,
        23,
        19
      ],
      "weeklyRejected": [
        23,
        0,
        6,
        9,
        41,
        0,
        38,
        16,
        37,
        46,
        20,
        27,
        24,
        48,
        34,
        0,
        20,
        29,
        13,
        33,
        16,
        49,
        23,
        44,
        32,
        49,
        49,
        0,
        48,
        39,
        26,
        41,
        17,
        2,
        24,
        49,
        15,
        36,
        18,
        20,
        36,
        37,
        3,
        11,
        39,
        44,
        49,
        28,
        35,
        32,
        0,
        32
      ],
      "weeklyHold": [
        8,
        35,
        49,
        14,
        35,
        25,
        8,
        16,
        35,
        24,
        47,
        33,
        48,
        35,
        27,
        0,
        0,
        25,
        35,
        22,
        18,
        37,
        0,
        13,
        24,
        45,
        0,
        11,
        25,
        0,
        40,
        0,
        0,
        49,
        32,
        0,
        27,
        11,
        38,
        43,
        12,
        39,
        33,
        37,
        12,
        0,
        41,
        31,
        0,
        8,
        33,
        28
      ],
      "weeklyBackordered": [
        35,
        28,
        18,
        36,
        49,
        46,
        41,
        18,
        4,
        13,
        42,
        17,
        10,
        34,
        24,
        7,
        49,
        42,
        43,
        5,
        9,
        30,
        25,
        49,
        48,
        10,
        38,
        12,
        11,
        13,
        0,
        21,
        38,
        14,
        49,
        0,
        49,
        0,
        30,
        0,
        39,
        48,
        49,
        17,
        21,
        25,
        49,
        30,
        26,
        49,
        40,
        31
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        35,
        10,
        17,
        48,
        12,
        47,
        1,
        6,
        39,
        1,
        30,
        34,
        27,
        22,
        9,
        2,
        49,
        21,
        35,
        13,
        0,
        22,
        17,
        28,
        19,
        39,
        20,
        9,
        2,
        49,
        31,
        0,
        38,
        5,
        48,
        36,
        47,
        48,
        4,
        45,
        14,
        27,
        18,
        17,
        49,
        44,
        0,
        40,
        0,
        27,
        31,
        14
      ],
      "weeklyRejected": [
        49,
        36,
        49,
        4,
        25,
        14,
        19,
        34,
        37,
        8,
        12,
        35,
        16,
        37,
        48,
        26,
        25,
        32,
        37,
        49,
        29,
        49,
        26,
        18,
        21,
        41,
        49,
        12,
        0,
        24,
        24,
        28,
        48,
        49,
        11,
        49,
        32,
        2,
        9,
        17,
        48,
        49,
        39,
        31,
        19,
        16,
        20,
        40,
        17,
        35,
        0,
        3
      ],
      "weeklyHold": [
        0,
        33,
        43,
        1,
        13,
        14,
        12,
        2,
        37,
        32,
        47,
        30,
        44,
        29,
        26,
        21,
        19,
        23,
        13,
        7,
        14,
        49,
        38,
        36,
        44,
        48,
        3,
        49,
        32,
        17,
        41,
        0,
        49,
        19,
        36,
        42,
        23,
        0,
        49,
        0,
        23,
        37,
        0,
        31,
        23,
        32,
        40,
        49,
        21,
        31,
        49,
        13
      ],
      "weeklyBackordered": [
        49,
        17,
        31,
        8,
        42,
        46,
        10,
        20,
        32,
        11,
        1,
        19,
        35,
        0,
        42,
        24,
        49,
        49,
        0,
        21,
        35,
        36,
        43,
        17,
        1,
        12,
        38,
        24,
        39,
        49,
        32,
        29,
        40,
        38,
        0,
        31,
        9,
        0,
        0,
        0,
        48,
        23,
        0,
        49,
        26,
        14,
        49,
        33,
        23,
        23,
        49,
        26
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        0,
        49,
        49,
        33,
        49,
        11,
        1,
        44,
        44,
        12,
        49,
        2,
        28,
        0,
        9,
        19,
        47,
        21,
        9,
        14,
        27,
        33,
        38,
        18,
        49,
        47,
        0,
        49,
        10,
        24,
        7,
        10,
        49,
        37,
        39,
        6,
        19,
        29,
        31,
        49,
        0,
        26,
        49,
        40,
        49,
        9,
        7,
        29,
        0,
        13,
        19,
        11
      ],
      "weeklyRejected": [
        38,
        4,
        14,
        47,
        47,
        31,
        34,
        47,
        30,
        48,
        28,
        10,
        33,
        16,
        31,
        0,
        11,
        37,
        36,
        6,
        13,
        21,
        22,
        46,
        5,
        46,
        49,
        32,
        48,
        14,
        37,
        11,
        47,
        49,
        25,
        49,
        22,
        10,
        19,
        41,
        30,
        0,
        49,
        0,
        44,
        31,
        39,
        40,
        5,
        9,
        0,
        41
      ],
      "weeklyHold": [
        7,
        14,
        27,
        15,
        6,
        14,
        49,
        38,
        22,
        0,
        19,
        39,
        1,
        35,
        15,
        36,
        16,
        0,
        33,
        8,
        29,
        38,
        11,
        41,
        36,
        24,
        14,
        9,
        44,
        0,
        36,
        44,
        0,
        40,
        2,
        22,
        41,
        0,
        49,
        9,
        18,
        38,
        7,
        34,
        29,
        19,
        39,
        21,
        0,
        24,
        42,
        27
      ],
      "weeklyBackordered": [
        29,
        45,
        0,
        33,
        36,
        39,
        45,
        21,
        7,
        1,
        13,
        49,
        9,
        37,
        3,
        49,
        49,
        36,
        36,
        2,
        15,
        13,
        33,
        5,
        48,
        7,
        39,
        28,
        1,
        40,
        44,
        31,
        11,
        18,
        1,
        7,
        29,
        10,
        28,
        40,
        0,
        20,
        6,
        49,
        28,
        46,
        21,
        31,
        0,
        27,
        18,
        44
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        27,
        49,
        49,
        39,
        27,
        3,
        1,
        38,
        48,
        16,
        44,
        0,
        0,
        23,
        25,
        35,
        49,
        0,
        15,
        17,
        10,
        20,
        27,
        30,
        33,
        47,
        6,
        0,
        12,
        47,
        26,
        20,
        5,
        12,
        23,
        18,
        43,
        26,
        32,
        40,
        19,
        30,
        49,
        19,
        49,
        0,
        0,
        49,
        14,
        33,
        0,
        48
      ],
      "weeklyRejected": [
        34,
        29,
        49,
        22,
        49,
        16,
        41,
        28,
        34,
        9,
        35,
        27,
        46,
        48,
        48,
        1,
        23,
        34,
        37,
        25,
        43,
        22,
        4,
        29,
        30,
        49,
        33,
        49,
        48,
        0,
        29,
        19,
        46,
        49,
        32,
        49,
        9,
        5,
        25,
        49,
        31,
        24,
        38,
        36,
        49,
        32,
        0,
        27,
        0,
        46,
        0,
        24
      ],
      "weeklyHold": [
        7,
        49,
        35,
        1,
        27,
        48,
        32,
        20,
        49,
        22,
        11,
        46,
        22,
        49,
        9,
        14,
        7,
        23,
        4,
        21,
        10,
        24,
        34,
        17,
        16,
        3,
        49,
        29,
        49,
        0,
        48,
        10,
        0,
        25,
        48,
        27,
        32,
        0,
        32,
        49,
        44,
        44,
        0,
        0,
        0,
        21,
        7,
        21,
        9,
        10,
        40,
        0
      ],
      "weeklyBackordered": [
        25,
        21,
        0,
        0,
        45,
        42,
        16,
        13,
        45,
        0,
        8,
        22,
        25,
        11,
        18,
        29,
        28,
        49,
        10,
        26,
        33,
        25,
        0,
        0,
        47,
        6,
        15,
        12,
        15,
        27,
        24,
        17,
        41,
        49,
        5,
        0,
        5,
        21,
        17,
        14,
        0,
        33,
        27,
        49,
        33,
        35,
        36,
        14,
        16,
        28,
        34,
        33
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        0,
        49,
        34,
        43,
        33,
        14,
        14,
        49,
        48,
        23,
        27,
        11,
        20,
        30,
        24,
        32,
        36,
        38,
        12,
        36,
        27,
        49,
        22,
        15,
        0,
        47,
        20,
        13,
        37,
        21,
        0,
        0,
        49,
        10,
        9,
        32,
        32,
        3,
        24,
        28,
        32,
        31,
        49,
        34,
        49,
        14,
        21,
        4,
        21,
        49,
        9,
        29
      ],
      "weeklyRejected": [
        32,
        29,
        36,
        18,
        32,
        19,
        27,
        49,
        45,
        11,
        49,
        17,
        49,
        36,
        48,
        23,
        40,
        40,
        43,
        7,
        45,
        12,
        49,
        7,
        2,
        36,
        18,
        49,
        48,
        28,
        49,
        31,
        0,
        7,
        28,
        49,
        43,
        43,
        49,
        12,
        48,
        31,
        33,
        30,
        42,
        30,
        1,
        13,
        0,
        49,
        7,
        37
      ],
      "weeklyHold": [
        39,
        0,
        38,
        36,
        49,
        0,
        30,
        2,
        31,
        30,
        28,
        30,
        36,
        19,
        30,
        0,
        0,
        0,
        12,
        31,
        0,
        0,
        33,
        20,
        0,
        22,
        24,
        39,
        49,
        49,
        48,
        7,
        0,
        23,
        48,
        28,
        14,
        10,
        1,
        29,
        15,
        26,
        10,
        10,
        49,
        49,
        33,
        33,
        16,
        33,
        20,
        41
      ],
      "weeklyBackordered": [
        49,
        22,
        0,
        35,
        46,
        46,
        22,
        17,
        0,
        49,
        33,
        7,
        29,
        28,
        22,
        48,
        39,
        46,
        0,
        0,
        42,
        31,
        14,
        49,
        20,
        20,
        1,
        35,
        14,
        38,
        0,
        49,
        35,
        11,
        8,
        0,
        30,
        0,
        5,
        2,
        7,
        43,
        18,
        49,
        25,
        19,
        28,
        37,
        30,
        34,
        49,
        12
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        18,
        38,
        22,
        31,
        1,
        0,
        49,
        2,
        48,
        5,
        42,
        0,
        37,
        0,
        26,
        22,
        45,
        34,
        5,
        3,
        21,
        8,
        49,
        27,
        43,
        40,
        0,
        42,
        1,
        31,
        45,
        3,
        2,
        49,
        48,
        2,
        27,
        28,
        26,
        15,
        10,
        4,
        49,
        34,
        49,
        0,
        0,
        31,
        0,
        21,
        49,
        16
      ],
      "weeklyRejected": [
        26,
        40,
        26,
        34,
        17,
        31,
        49,
        14,
        28,
        27,
        49,
        22,
        24,
        39,
        26,
        6,
        7,
        49,
        5,
        34,
        49,
        0,
        43,
        6,
        9,
        38,
        43,
        22,
        29,
        0,
        0,
        23,
        37,
        22,
        40,
        49,
        0,
        8,
        34,
        24,
        46,
        33,
        44,
        8,
        8,
        4,
        19,
        7,
        26,
        18,
        12,
        46
      ],
      "weeklyHold": [
        24,
        49,
        38,
        1,
        23,
        1,
        18,
        23,
        21,
        3,
        41,
        33,
        27,
        30,
        49,
        29,
        9,
        14,
        35,
        15,
        20,
        12,
        46,
        21,
        44,
        27,
        40,
        28,
        49,
        23,
        35,
        14,
        16,
        46,
        28,
        11,
        26,
        2,
        2,
        46,
        13,
        32,
        20,
        11,
        32,
        49,
        2,
        11,
        21,
        29,
        10,
        31
      ],
      "weeklyBackordered": [
        25,
        40,
        47,
        35,
        41,
        29,
        34,
        1,
        35,
        0,
        3,
        49,
        32,
        0,
        24,
        21,
        49,
        23,
        0,
        49,
        0,
        31,
        17,
        29,
        9,
        22,
        25,
        14,
        1,
        38,
        31,
        11,
        48,
        31,
        0,
        0,
        14,
        16,
        10,
        32,
        48,
        42,
        18,
        10,
        28,
        33,
        49,
        34,
        15,
        49,
        21,
        1
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        0,
        13,
        49,
        0,
        30,
        0,
        20,
        39,
        48,
        44,
        40,
        0,
        28,
        46,
        23,
        0,
        49,
        30,
        44,
        36,
        49,
        49,
        49,
        44,
        5,
        47,
        15,
        48,
        1,
        36,
        2,
        23,
        49,
        27,
        36,
        39,
        47,
        37,
        20,
        24,
        0,
        0,
        49,
        33,
        49,
        0,
        0,
        0,
        47,
        49,
        3,
        47
      ],
      "weeklyRejected": [
        49,
        20,
        29,
        19,
        5,
        43,
        28,
        26,
        21,
        17,
        11,
        46,
        3,
        41,
        32,
        24,
        0,
        0,
        49,
        29,
        6,
        5,
        29,
        31,
        49,
        49,
        38,
        39,
        48,
        0,
        30,
        28,
        28,
        22,
        13,
        39,
        5,
        10,
        1,
        49,
        48,
        15,
        49,
        25,
        39,
        32,
        24,
        45,
        30,
        49,
        29,
        49
      ],
      "weeklyHold": [
        49,
        23,
        45,
        25,
        35,
        48,
        30,
        2,
        28,
        39,
        21,
        49,
        40,
        49,
        7,
        10,
        0,
        36,
        49,
        13,
        16,
        36,
        38,
        0,
        32,
        34,
        9,
        4,
        12,
        9,
        37,
        8,
        0,
        49,
        16,
        47,
        49,
        7,
        1,
        38,
        0,
        40,
        29,
        29,
        34,
        19,
        23,
        28,
        16,
        11,
        47,
        49
      ],
      "weeklyBackordered": [
        20,
        23,
        0,
        28,
        46,
        46,
        11,
        13,
        0,
        4,
        27,
        0,
        42,
        42,
        27,
        49,
        16,
        49,
        25,
        21,
        22,
        3,
        0,
        0,
        8,
        49,
        10,
        0,
        13,
        40,
        17,
        49,
        38,
        28,
        0,
        0,
        13,
        9,
        0,
        14,
        14,
        48,
        0,
        46,
        49,
        49,
        34,
        0,
        22,
        49,
        26,
        33
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        19,
        49,
        43,
        19,
        43,
        36,
        33,
        32,
        48,
        38,
        41,
        0,
        19,
        31,
        17,
        0,
        34,
        24,
        28,
        15,
        23,
        34,
        31,
        41,
        16,
        0,
        8,
        12,
        12,
        32,
        26,
        17,
        25,
        21,
        9,
        18,
        36,
        35,
        30,
        20,
        49,
        49,
        0,
        49,
        42,
        14,
        0,
        47,
        10,
        20,
        7,
        28
      ],
      "weeklyRejected": [
        49,
        19,
        19,
        0,
        16,
        49,
        28,
        31,
        4,
        27,
        35,
        21,
        49,
        48,
        45,
        2,
        33,
        47,
        27,
        49,
        7,
        33,
        37,
        5,
        17,
        45,
        49,
        32,
        36,
        37,
        0,
        30,
        37,
        14,
        13,
        36,
        7,
        49,
        16,
        49,
        9,
        22,
        27,
        0,
        49,
        40,
        10,
        21,
        1,
        37,
        0,
        23
      ],
      "weeklyHold": [
        44,
        34,
        38,
        12,
        39,
        0,
        33,
        29,
        30,
        13,
        35,
        49,
        32,
        1,
        49,
        22,
        12,
        2,
        25,
        10,
        0,
        42,
        49,
        0,
        0,
        38,
        21,
        11,
        6,
        44,
        15,
        15,
        0,
        16,
        20,
        43,
        15,
        17,
        25,
        36,
        7,
        8,
        23,
        38,
        41,
        9,
        1,
        49,
        29,
        49,
        47,
        41
      ],
      "weeklyBackordered": [
        0,
        49,
        0,
        38,
        44,
        36,
        31,
        44,
        38,
        7,
        25,
        43,
        14,
        18,
        21,
        13,
        40,
        30,
        13,
        7,
        45,
        37,
        9,
        49,
        1,
        11,
        10,
        5,
        7,
        49,
        38,
        9,
        46,
        38,
        36,
        11,
        49,
        0,
        44,
        47,
        48,
        48,
        27,
        49,
        13,
        39,
        39,
        49,
        37,
        49,
        8,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyAccepted": [
        42,
        18,
        49,
        13,
        25,
        0,
        9,
        34,
        48,
        17,
        49,
        0,
        36,
        21,
        15,
        36,
        39,
        4,
        17,
        42,
        0,
        37,
        29,
        20,
        49,
        35,
        29,
        10,
        24,
        36,
        9,
        8,
        32,
        23,
        38,
        31,
        34,
        48,
        22,
        29,
        9,
        15,
        49,
        33,
        21,
        0,
        0,
        5,
        5,
        49,
        26,
        37
      ],
      "weeklyRejected": [
        36,
        43,
        45,
        47,
        0,
        0,
        49,
        23,
        19,
        17,
        40,
        26,
        39,
        22,
        16,
        4,
        0,
        49,
        1,
        24,
        49,
        43,
        12,
        47,
        19,
        21,
        43,
        22,
        27,
        42,
        15,
        30,
        48,
        22,
        30,
        49,
        30,
        0,
        42,
        37,
        29,
        46,
        10,
        0,
        26,
        18,
        0,
        3,
        48,
        17,
        14,
        40
      ],
      "weeklyHold": [
        14,
        19,
        49,
        19,
        12,
        33,
        0,
        2,
        38,
        49,
        19,
        1,
        27,
        38,
        9,
        11,
        0,
        33,
        2,
        16,
        21,
        37,
        29,
        8,
        39,
        23,
        35,
        13,
        49,
        0,
        30,
        4,
        0,
        21,
        19,
        0,
        49,
        21,
        48,
        29,
        45,
        42,
        11,
        32,
        49,
        28,
        28,
        42,
        15,
        26,
        49,
        13
      ],
      "weeklyBackordered": [
        15,
        49,
        0,
        28,
        12,
        27,
        47,
        17,
        12,
        8,
        49,
        39,
        3,
        49,
        20,
        1,
        17,
        21,
        0,
        41,
        15,
        20,
        0,
        19,
        24,
        9,
        13,
        15,
        26,
        8,
        9,
        49,
        39,
        0,
        11,
        1,
        19,
        26,
        0,
        32,
        12,
        39,
        49,
        41,
        6,
        14,
        29,
        9,
        20,
        49,
        49,
        25
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        42,
        38,
        6,
        46,
        49,
        17,
        49,
        26,
        22,
        0,
        46,
        18,
        36,
        15,
        16,
        31,
        38,
        23,
        0,
        41,
        49,
        33,
        0,
        30,
        46,
        35,
        28,
        49,
        24,
        41,
        49,
        18,
        39,
        14,
        48,
        49,
        8,
        23,
        29,
        44,
        17,
        40,
        36,
        44,
        49,
        2,
        0,
        38,
        49,
        49,
        11,
        26
      ],
      "weeklyRejected": [
        37,
        21,
        40,
        28,
        15,
        23,
        0,
        11,
        49,
        44,
        49,
        49,
        35,
        14,
        24,
        20,
        24,
        25,
        49,
        32,
        0,
        36,
        12,
        31,
        33,
        49,
        49,
        9,
        48,
        19,
        46,
        36,
        44,
        49,
        0,
        34,
        20,
        19,
        23,
        1,
        23,
        30,
        29,
        20,
        27,
        3,
        6,
        11,
        25,
        48,
        8,
        49
      ],
      "weeklyHold": [
        49,
        8,
        49,
        22,
        5,
        42,
        13,
        20,
        35,
        21,
        12,
        19,
        25,
        4,
        35,
        0,
        17,
        0,
        6,
        1,
        15,
        41,
        27,
        4,
        7,
        42,
        6,
        12,
        34,
        25,
        33,
        36,
        35,
        47,
        43,
        10,
        20,
        0,
        5,
        21,
        22,
        37,
        43,
        29,
        49,
        34,
        44,
        36,
        0,
        9,
        13,
        19
      ],
      "weeklyBackordered": [
        37,
        16,
        23,
        41,
        42,
        46,
        21,
        45,
        32,
        0,
        6,
        34,
        34,
        0,
        13,
        39,
        48,
        27,
        15,
        49,
        49,
        0,
        0,
        4,
        31,
        1,
        49,
        18,
        24,
        42,
        12,
        32,
        29,
        22,
        32,
        2,
        24,
        19,
        33,
        1,
        38,
        47,
        2,
        6,
        41,
        45,
        49,
        31,
        34,
        25,
        31,
        22
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        34,
        14,
        0,
        9,
        11,
        28,
        8,
        45,
        48,
        44,
        39,
        47,
        40,
        15,
        1,
        7,
        38,
        1,
        18,
        39,
        4,
        36,
        13,
        48,
        27,
        8,
        20,
        30,
        36,
        39,
        49,
        0,
        40,
        0,
        48,
        37,
        1,
        38,
        0,
        35,
        21,
        40,
        39,
        34,
        49,
        19,
        31,
        31,
        10,
        47,
        26,
        19
      ],
      "weeklyRejected": [
        32,
        49,
        21,
        0,
        32,
        23,
        29,
        28,
        49,
        17,
        37,
        10,
        35,
        25,
        29,
        49,
        42,
        49,
        4,
        10,
        0,
        0,
        12,
        40,
        33,
        20,
        49,
        3,
        31,
        46,
        38,
        22,
        8,
        27,
        2,
        49,
        44,
        22,
        29,
        38,
        48,
        49,
        27,
        18,
        39,
        18,
        0,
        33,
        35,
        30,
        22,
        40
      ],
      "weeklyHold": [
        0,
        2,
        49,
        16,
        32,
        48,
        0,
        2,
        47,
        4,
        18,
        1,
        1,
        1,
        25,
        48,
        29,
        15,
        17,
        0,
        13,
        46,
        26,
        6,
        20,
        28,
        22,
        13,
        21,
        49,
        48,
        1,
        1,
        36,
        48,
        48,
        0,
        17,
        33,
        31,
        4,
        3,
        23,
        20,
        49,
        41,
        0,
        49,
        8,
        42,
        49,
        29
      ],
      "weeklyBackordered": [
        41,
        0,
        0,
        21,
        21,
        29,
        38,
        18,
        49,
        49,
        38,
        0,
        21,
        46,
        11,
        1,
        49,
        44,
        10,
        35,
        24,
        19,
        30,
        20,
        28,
        16,
        3,
        35,
        17,
        10,
        0,
        38,
        27,
        0,
        24,
        17,
        23,
        26,
        0,
        27,
        6,
        48,
        0,
        27,
        18,
        24,
        49,
        28,
        4,
        18,
        44,
        44
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        0,
        14,
        22,
        0,
        19,
        26,
        2,
        37,
        26,
        45,
        30,
        16,
        24,
        49,
        1,
        0,
        3,
        15,
        0,
        34,
        0,
        43,
        16,
        11,
        11,
        29,
        0,
        9,
        1,
        19,
        27,
        46,
        41,
        32,
        40,
        35,
        40,
        14,
        28,
        49,
        38,
        36,
        18,
        37,
        21,
        31,
        10,
        43,
        0,
        49,
        39,
        17
      ],
      "weeklyRejected": [
        45,
        11,
        41,
        7,
        39,
        1,
        21,
        13,
        44,
        48,
        35,
        30,
        49,
        48,
        25,
        22,
        0,
        0,
        49,
        0,
        10,
        25,
        3,
        25,
        28,
        26,
        43,
        0,
        48,
        14,
        38,
        47,
        48,
        49,
        42,
        49,
        7,
        27,
        1,
        49,
        20,
        49,
        24,
        21,
        49,
        49,
        0,
        26,
        24,
        32,
        19,
        42
      ],
      "weeklyHold": [
        49,
        0,
        36,
        20,
        38,
        4,
        5,
        14,
        1,
        16,
        22,
        39,
        27,
        26,
        49,
        7,
        22,
        13,
        33,
        0,
        0,
        11,
        35,
        5,
        10,
        29,
        15,
        0,
        25,
        19,
        48,
        26,
        23,
        48,
        26,
        49,
        26,
        11,
        48,
        49,
        0,
        49,
        32,
        33,
        49,
        24,
        49,
        33,
        5,
        0,
        0,
        29
      ],
      "weeklyBackordered": [
        25,
        6,
        11,
        49,
        46,
        41,
        21,
        42,
        13,
        29,
        49,
        49,
        5,
        28,
        0,
        5,
        35,
        24,
        0,
        25,
        40,
        0,
        0,
        23,
        48,
        1,
        49,
        49,
        14,
        20,
        44,
        11,
        7,
        49,
        0,
        23,
        30,
        23,
        16,
        2,
        44,
        48,
        6,
        44,
        16,
        9,
        49,
        36,
        41,
        25,
        25,
        18
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        0,
        8,
        49,
        48,
        36,
        24,
        1,
        20,
        11,
        30,
        21,
        37,
        49,
        21,
        18,
        42,
        19,
        25,
        13,
        29,
        1,
        32,
        20,
        21,
        0,
        47,
        9,
        36,
        33,
        47,
        20,
        17,
        49,
        8,
        35,
        28,
        33,
        0,
        10,
        49,
        12,
        49,
        24,
        0,
        49,
        12,
        8,
        1,
        23,
        49,
        36,
        0
      ],
      "weeklyRejected": [
        9,
        21,
        2,
        47,
        41,
        0,
        35,
        31,
        49,
        12,
        35,
        33,
        21,
        46,
        47,
        10,
        21,
        40,
        49,
        37,
        12,
        39,
        7,
        18,
        33,
        49,
        5,
        13,
        24,
        45,
        33,
        47,
        25,
        16,
        0,
        7,
        47,
        0,
        4,
        23,
        31,
        49,
        1,
        13,
        0,
        24,
        5,
        0,
        0,
        49,
        14,
        13
      ],
      "weeklyHold": [
        0,
        17,
        36,
        27,
        20,
        42,
        17,
        2,
        24,
        19,
        47,
        20,
        27,
        31,
        38,
        17,
        16,
        35,
        8,
        19,
        49,
        12,
        0,
        20,
        44,
        6,
        0,
        16,
        49,
        0,
        48,
        5,
        49,
        29,
        45,
        24,
        49,
        10,
        49,
        49,
        1,
        0,
        26,
        46,
        40,
        36,
        0,
        45,
        0,
        49,
        2,
        26
      ],
      "weeklyBackordered": [
        31,
        0,
        17,
        24,
        18,
        17,
        49,
        49,
        14,
        18,
        0,
        11,
        23,
        11,
        10,
        24,
        41,
        32,
        0,
        0,
        49,
        16,
        20,
        19,
        3,
        17,
        27,
        20,
        16,
        4,
        38,
        39,
        29,
        36,
        11,
        0,
        24,
        28,
        33,
        23,
        10,
        48,
        40,
        28,
        20,
        35,
        49,
        23,
        29,
        49,
        39,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        22,
        49,
        33,
        4,
        32,
        14,
        1,
        33,
        41,
        26,
        33,
        16,
        49,
        38,
        31,
        12,
        47,
        0,
        26,
        29,
        35,
        49,
        19,
        38,
        14,
        47,
        23,
        36,
        29,
        31,
        0,
        26,
        43,
        40,
        31,
        6,
        11,
        30,
        34,
        49,
        0,
        49,
        49,
        0,
        46,
        0,
        0,
        0,
        41,
        5,
        12,
        24
      ],
      "weeklyRejected": [
        32,
        30,
        31,
        23,
        49,
        0,
        49,
        18,
        17,
        14,
        35,
        40,
        40,
        17,
        40,
        5,
        0,
        38,
        43,
        14,
        8,
        28,
        9,
        48,
        22,
        4,
        36,
        49,
        28,
        0,
        23,
        32,
        27,
        17,
        24,
        49,
        30,
        0,
        1,
        0,
        42,
        0,
        49,
        31,
        49,
        0,
        26,
        30,
        0,
        23,
        6,
        49
      ],
      "weeklyHold": [
        21,
        24,
        49,
        30,
        3,
        35,
        29,
        2,
        3,
        26,
        34,
        19,
        40,
        39,
        37,
        39,
        0,
        34,
        0,
        29,
        13,
        37,
        12,
        42,
        48,
        48,
        9,
        0,
        40,
        19,
        48,
        11,
        0,
        29,
        48,
        12,
        37,
        37,
        49,
        47,
        20,
        21,
        38,
        27,
        47,
        49,
        0,
        24,
        0,
        33,
        1,
        36
      ],
      "weeklyBackordered": [
        31,
        36,
        10,
        49,
        7,
        40,
        29,
        49,
        41,
        5,
        28,
        3,
        5,
        6,
        20,
        41,
        26,
        26,
        9,
        29,
        18,
        17,
        5,
        3,
        27,
        18,
        43,
        2,
        12,
        49,
        36,
        46,
        21,
        42,
        11,
        15,
        8,
        24,
        2,
        35,
        16,
        48,
        0,
        49,
        19,
        10,
        7,
        0,
        49,
        19,
        26,
        29
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        47,
        49,
        25,
        38,
        10,
        0,
        40,
        48,
        31,
        35,
        27,
        7,
        21,
        28,
        13,
        25,
        49,
        45,
        0,
        49,
        26,
        19,
        39,
        10,
        12,
        8,
        25,
        8,
        15,
        46,
        33,
        13,
        46,
        22,
        48,
        7,
        35,
        48,
        49,
        16,
        8,
        38,
        22,
        17,
        3,
        0,
        0,
        14,
        0,
        0,
        47,
        22
      ],
      "weeklyRejected": [
        35,
        9,
        49,
        0,
        19,
        0,
        49,
        8,
        49,
        25,
        36,
        30,
        35,
        11,
        33,
        20,
        7,
        17,
        41,
        45,
        49,
        22,
        31,
        26,
        14,
        49,
        13,
        46,
        26,
        21,
        28,
        34,
        31,
        32,
        49,
        49,
        29,
        22,
        21,
        0,
        48,
        31,
        45,
        13,
        47,
        41,
        0,
        17,
        0,
        45,
        0,
        24
      ],
      "weeklyHold": [
        35,
        49,
        48,
        14,
        48,
        40,
        28,
        24,
        0,
        21,
        47,
        49,
        38,
        33,
        33,
        44,
        19,
        13,
        41,
        10,
        8,
        10,
        6,
        2,
        7,
        26,
        49,
        0,
        38,
        22,
        28,
        32,
        22,
        22,
        42,
        29,
        45,
        7,
        31,
        49,
        14,
        29,
        2,
        12,
        49,
        49,
        5,
        44,
        13,
        15,
        40,
        11
      ],
      "weeklyBackordered": [
        18,
        34,
        41,
        43,
        40,
        46,
        29,
        38,
        44,
        0,
        38,
        24,
        44,
        29,
        26,
        49,
        49,
        49,
        0,
        43,
        9,
        1,
        42,
        0,
        27,
        15,
        49,
        7,
        20,
        49,
        41,
        23,
        1,
        46,
        0,
        0,
        35,
        45,
        16,
        5,
        37,
        48,
        30,
        30,
        0,
        37,
        8,
        20,
        11,
        48,
        30,
        1
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyAccepted": [
        13,
        29,
        30,
        48,
        23,
        31,
        49,
        22,
        35,
        22,
        40,
        0,
        30,
        49,
        8,
        21,
        49,
        48,
        7,
        48,
        4,
        28,
        40,
        30,
        26,
        33,
        4,
        35,
        1,
        36,
        7,
        0,
        49,
        17,
        0,
        46,
        47,
        19,
        19,
        0,
        40,
        0,
        49,
        36,
        39,
        0,
        16,
        0,
        22,
        39,
        9,
        48
      ],
      "weeklyRejected": [
        49,
        24,
        27,
        6,
        0,
        49,
        14,
        14,
        24,
        23,
        6,
        34,
        17,
        19,
        48,
        15,
        38,
        17,
        31,
        49,
        0,
        49,
        22,
        35,
        31,
        16,
        38,
        33,
        24,
        1,
        44,
        47,
        12,
        23,
        8,
        44,
        11,
        26,
        19,
        32,
        46,
        10,
        23,
        0,
        46,
        3,
        16,
        0,
        0,
        49,
        0,
        30
      ],
      "weeklyHold": [
        49,
        0,
        20,
        16,
        48,
        23,
        49,
        31,
        0,
        21,
        47,
        31,
        11,
        1,
        43,
        24,
        0,
        0,
        23,
        25,
        22,
        25,
        28,
        3,
        10,
        44,
        0,
        0,
        37,
        48,
        48,
        0,
        0,
        23,
        0,
        30,
        25,
        13,
        23,
        48,
        35,
        49,
        12,
        24,
        11,
        49,
        46,
        12,
        0,
        36,
        39,
        37
      ],
      "weeklyBackordered": [
        25,
        0,
        29,
        17,
        49,
        40,
        0,
        47,
        0,
        15,
        11,
        49,
        9,
        0,
        38,
        7,
        47,
        49,
        0,
        0,
        49,
        40,
        11,
        49,
        1,
        31,
        1,
        0,
        9,
        49,
        36,
        39,
        48,
        34,
        0,
        17,
        3,
        0,
        34,
        10,
        48,
        48,
        6,
        23,
        19,
        9,
        32,
        6,
        44,
        16,
        31,
        1
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        1,
        46,
        25,
        26,
        12,
        49,
        17,
        21,
        44,
        18,
        1,
        6,
        49,
        0,
        21,
        32,
        40,
        0,
        6,
        26,
        34,
        49,
        46,
        29,
        49,
        32,
        0,
        18,
        15,
        49,
        49,
        49,
        25,
        0,
        48,
        30,
        47,
        48,
        24,
        49,
        30,
        4,
        20,
        49,
        37,
        0,
        13,
        11,
        49,
        49,
        20,
        18
      ],
      "weeklyRejected": [
        24,
        0,
        29,
        47,
        49,
        27,
        24,
        49,
        48,
        37,
        22,
        33,
        48,
        14,
        48,
        30,
        49,
        3,
        39,
        38,
        17,
        28,
        39,
        49,
        33,
        49,
        49,
        0,
        21,
        5,
        42,
        23,
        37,
        39,
        31,
        37,
        17,
        24,
        25,
        0,
        15,
        35,
        17,
        0,
        49,
        9,
        0,
        44,
        36,
        32,
        16,
        40
      ],
      "weeklyHold": [
        0,
        18,
        44,
        20,
        32,
        26,
        7,
        2,
        29,
        23,
        36,
        33,
        32,
        3,
        23,
        39,
        26,
        26,
        9,
        0,
        0,
        49,
        49,
        49,
        46,
        48,
        43,
        7,
        37,
        0,
        41,
        29,
        26,
        49,
        45,
        46,
        27,
        0,
        49,
        18,
        18,
        18,
        0,
        37,
        27,
        0,
        0,
        49,
        0,
        37,
        27,
        26
      ],
      "weeklyBackordered": [
        25,
        30,
        33,
        0,
        27,
        15,
        0,
        1,
        16,
        16,
        10,
        20,
        48,
        49,
        1,
        25,
        35,
        34,
        24,
        20,
        49,
        41,
        31,
        9,
        8,
        22,
        19,
        13,
        6,
        11,
        13,
        40,
        27,
        0,
        0,
        0,
        14,
        12,
        8,
        23,
        24,
        41,
        8,
        38,
        6,
        49,
        49,
        28,
        37,
        24,
        16,
        49
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        0,
        21,
        26,
        34,
        44,
        49,
        35,
        36,
        44,
        24,
        49,
        34,
        38,
        36,
        1,
        30,
        0,
        48,
        11,
        37,
        16,
        49,
        0,
        32,
        13,
        21,
        49,
        6,
        1,
        37,
        15,
        11,
        33,
        24,
        17,
        49,
        47,
        46,
        0,
        46,
        3,
        16,
        49,
        22,
        26,
        36,
        26,
        13,
        49,
        49,
        46,
        41
      ],
      "weeklyRejected": [
        49,
        15,
        31,
        23,
        26,
        35,
        41,
        0,
        49,
        15,
        13,
        0,
        23,
        42,
        48,
        24,
        43,
        34,
        31,
        40,
        17,
        20,
        30,
        49,
        25,
        22,
        20,
        16,
        11,
        2,
        2,
        47,
        10,
        33,
        1,
        26,
        19,
        0,
        49,
        21,
        40,
        0,
        31,
        2,
        32,
        43,
        0,
        49,
        36,
        21,
        0,
        25
      ],
      "weeklyHold": [
        32,
        4,
        39,
        1,
        49,
        43,
        32,
        29,
        21,
        36,
        42,
        42,
        41,
        44,
        24,
        23,
        48,
        32,
        47,
        13,
        0,
        24,
        30,
        49,
        31,
        48,
        10,
        17,
        31,
        7,
        12,
        0,
        9,
        38,
        15,
        33,
        22,
        6,
        14,
        21,
        10,
        49,
        14,
        4,
        45,
        32,
        0,
        40,
        4,
        0,
        41,
        12
      ],
      "weeklyBackordered": [
        2,
        41,
        0,
        0,
        40,
        46,
        17,
        49,
        33,
        14,
        42,
        21,
        22,
        45,
        40,
        41,
        49,
        47,
        43,
        49,
        42,
        44,
        49,
        32,
        16,
        49,
        5,
        30,
        29,
        49,
        3,
        15,
        0,
        8,
        0,
        0,
        5,
        5,
        12,
        6,
        14,
        48,
        6,
        49,
        49,
        0,
        13,
        33,
        26,
        16,
        36,
        23
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        39,
        34,
        37,
        33,
        1,
        7,
        14,
        49,
        42,
        0,
        49,
        1,
        32,
        46,
        23,
        46,
        43,
        38,
        19,
        11,
        12,
        49,
        47,
        49,
        44,
        47,
        29,
        33,
        7,
        21,
        49,
        30,
        48,
        0,
        48,
        18,
        19,
        44,
        24,
        49,
        37,
        49,
        46,
        49,
        42,
        29,
        14,
        2,
        13,
        46,
        10,
        48
      ],
      "weeklyRejected": [
        26,
        48,
        49,
        10,
        38,
        40,
        49,
        9,
        49,
        48,
        17,
        24,
        40,
        48,
        44,
        7,
        23,
        34,
        30,
        0,
        9,
        14,
        0,
        48,
        11,
        47,
        49,
        30,
        16,
        48,
        30,
        47,
        33,
        49,
        12,
        44,
        44,
        30,
        11,
        27,
        46,
        29,
        39,
        4,
        38,
        0,
        0,
        28,
        12,
        39,
        40,
        15
      ],
      "weeklyHold": [
        36,
        22,
        47,
        1,
        49,
        44,
        49,
        2,
        18,
        38,
        47,
        42,
        31,
        9,
        21,
        40,
        14,
        3,
        21,
        17,
        21,
        49,
        5,
        49,
        1,
        23,
        49,
        25,
        37,
        49,
        38,
        24,
        27,
        49,
        48,
        25,
        13,
        29,
        1,
        48,
        40,
        34,
        3,
        49,
        49,
        5,
        45,
        49,
        34,
        22,
        49,
        8
      ],
      "weeklyBackordered": [
        48,
        36,
        0,
        33,
        48,
        46,
        18,
        12,
        49,
        11,
        15,
        48,
        25,
        45,
        40,
        44,
        45,
        49,
        31,
        4,
        27,
        46,
        38,
        12,
        34,
        49,
        21,
        0,
        38,
        43,
        28,
        25,
        43,
        49,
        0,
        19,
        26,
        9,
        3,
        0,
        0,
        48,
        7,
        49,
        0,
        49,
        29,
        8,
        45,
        49,
        25,
        40
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        34,
        21,
        46,
        25,
        25,
        0,
        1,
        24,
        29,
        17,
        21,
        8,
        12,
        25,
        20,
        16,
        49,
        6,
        0,
        4,
        9,
        42,
        39,
        35,
        49,
        2,
        49,
        29,
        24,
        14,
        0,
        2,
        47,
        28,
        40,
        20,
        28,
        25,
        49,
        49,
        30,
        36,
        49,
        43,
        28,
        40,
        18,
        48,
        9,
        39,
        10,
        33
      ],
      "weeklyRejected": [
        36,
        35,
        16,
        47,
        35,
        27,
        30,
        7,
        3,
        48,
        4,
        0,
        21,
        32,
        45,
        49,
        24,
        49,
        0,
        46,
        22,
        28,
        10,
        4,
        7,
        45,
        49,
        18,
        23,
        0,
        39,
        34,
        31,
        49,
        30,
        49,
        29,
        28,
        15,
        0,
        29,
        49,
        49,
        25,
        48,
        10,
        11,
        12,
        9,
        49,
        0,
        49
      ],
      "weeklyHold": [
        40,
        31,
        0,
        17,
        22,
        19,
        0,
        14,
        17,
        22,
        36,
        37,
        48,
        40,
        5,
        46,
        12,
        48,
        0,
        4,
        34,
        7,
        12,
        17,
        36,
        48,
        26,
        0,
        29,
        11,
        32,
        24,
        9,
        29,
        34,
        19,
        21,
        8,
        1,
        38,
        19,
        31,
        33,
        0,
        20,
        0,
        49,
        28,
        1,
        35,
        7,
        13
      ],
      "weeklyBackordered": [
        31,
        14,
        28,
        30,
        42,
        19,
        43,
        3,
        16,
        7,
        20,
        35,
        17,
        23,
        26,
        1,
        32,
        32,
        18,
        8,
        18,
        49,
        0,
        19,
        20,
        24,
        47,
        19,
        24,
        49,
        0,
        37,
        19,
        49,
        0,
        0,
        1,
        9,
        40,
        16,
        6,
        48,
        0,
        49,
        0,
        49,
        24,
        31,
        47,
        39,
        17,
        32
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        19,
        30,
        25,
        24,
        12,
        38,
        25,
        39,
        16,
        36,
        0,
        0,
        40,
        29,
        1,
        9,
        49,
        33,
        22,
        7,
        48,
        30,
        26,
        47,
        0,
        28,
        20,
        47,
        42,
        26,
        32,
        0,
        49,
        42,
        40,
        41,
        24,
        41,
        0,
        12,
        10,
        13,
        31,
        12,
        49,
        30,
        0,
        22,
        49,
        44,
        49,
        20
      ],
      "weeklyRejected": [
        49,
        13,
        2,
        30,
        18,
        0,
        37,
        19,
        35,
        28,
        0,
        0,
        8,
        7,
        2,
        49,
        31,
        37,
        36,
        24,
        12,
        30,
        4,
        49,
        18,
        49,
        49,
        0,
        48,
        15,
        36,
        47,
        13,
        6,
        43,
        30,
        48,
        19,
        44,
        22,
        48,
        30,
        34,
        30,
        42,
        37,
        43,
        20,
        30,
        49,
        0,
        49
      ],
      "weeklyHold": [
        22,
        0,
        45,
        1,
        19,
        41,
        14,
        30,
        0,
        49,
        34,
        48,
        1,
        24,
        2,
        12,
        2,
        10,
        20,
        49,
        0,
        49,
        0,
        21,
        18,
        34,
        21,
        17,
        49,
        2,
        21,
        2,
        4,
        34,
        3,
        49,
        28,
        49,
        22,
        18,
        22,
        33,
        0,
        22,
        49,
        49,
        45,
        26,
        0,
        37,
        49,
        38
      ],
      "weeklyBackordered": [
        39,
        30,
        4,
        0,
        36,
        46,
        0,
        32,
        19,
        36,
        8,
        28,
        13,
        25,
        10,
        37,
        15,
        46,
        23,
        24,
        23,
        40,
        0,
        16,
        21,
        18,
        46,
        49,
        1,
        39,
        36,
        49,
        29,
        46,
        34,
        0,
        25,
        18,
        0,
        0,
        17,
        23,
        0,
        26,
        27,
        28,
        42,
        8,
        0,
        18,
        36,
        24
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        0,
        49,
        32,
        16,
        17,
        5,
        15,
        24,
        48,
        34,
        28,
        3,
        11,
        7,
        28,
        10,
        12,
        7,
        0,
        36,
        49,
        49,
        33,
        13,
        29,
        47,
        42,
        3,
        1,
        24,
        2,
        27,
        28,
        0,
        24,
        31,
        47,
        38,
        49,
        46,
        31,
        44,
        43,
        47,
        10,
        23,
        16,
        30,
        17,
        15,
        49,
        27
      ],
      "weeklyRejected": [
        41,
        4,
        32,
        25,
        0,
        21,
        33,
        9,
        36,
        5,
        14,
        20,
        15,
        48,
        21,
        37,
        0,
        43,
        49,
        42,
        49,
        34,
        32,
        22,
        12,
        49,
        16,
        16,
        35,
        4,
        49,
        17,
        42,
        44,
        15,
        49,
        25,
        24,
        1,
        49,
        35,
        29,
        33,
        24,
        44,
        34,
        0,
        32,
        0,
        49,
        0,
        44
      ],
      "weeklyHold": [
        0,
        40,
        15,
        15,
        49,
        41,
        0,
        38,
        37,
        34,
        19,
        49,
        40,
        6,
        18,
        8,
        11,
        15,
        14,
        27,
        25,
        40,
        0,
        25,
        31,
        48,
        34,
        9,
        29,
        17,
        33,
        0,
        6,
        49,
        18,
        18,
        47,
        9,
        22,
        40,
        45,
        30,
        0,
        31,
        49,
        1,
        24,
        22,
        0,
        40,
        44,
        34
      ],
      "weeklyBackordered": [
        39,
        19,
        40,
        27,
        36,
        46,
        11,
        1,
        14,
        12,
        40,
        31,
        20,
        0,
        48,
        5,
        21,
        38,
        0,
        26,
        29,
        17,
        0,
        34,
        10,
        13,
        1,
        11,
        6,
        36,
        28,
        34,
        26,
        36,
        0,
        14,
        32,
        27,
        23,
        19,
        16,
        34,
        49,
        49,
        46,
        16,
        14,
        36,
        4,
        42,
        22,
        26
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        30,
        49,
        0,
        21,
        34,
        42,
        49,
        6,
        42,
        4,
        49,
        19,
        45,
        35,
        28,
        7,
        36,
        3,
        29,
        11,
        43,
        49,
        16,
        45,
        18,
        20,
        24,
        49,
        4,
        47,
        16,
        20,
        39,
        36,
        32,
        23,
        16,
        0,
        6,
        49,
        28,
        18,
        49,
        49,
        49,
        49,
        17,
        49,
        25,
        49,
        39,
        24
      ],
      "weeklyRejected": [
        49,
        27,
        29,
        30,
        19,
        17,
        25,
        47,
        49,
        48,
        49,
        49,
        35,
        33,
        5,
        4,
        4,
        40,
        17,
        7,
        0,
        4,
        32,
        18,
        31,
        47,
        49,
        23,
        34,
        37,
        13,
        47,
        48,
        33,
        17,
        36,
        14,
        34,
        28,
        7,
        27,
        49,
        42,
        16,
        22,
        21,
        0,
        21,
        7,
        24,
        4,
        0
      ],
      "weeklyHold": [
        42,
        2,
        37,
        12,
        44,
        30,
        49,
        45,
        28,
        29,
        39,
        26,
        44,
        34,
        34,
        6,
        0,
        45,
        0,
        14,
        0,
        0,
        37,
        15,
        20,
        36,
        0,
        0,
        29,
        49,
        43,
        14,
        49,
        49,
        48,
        7,
        0,
        33,
        12,
        41,
        47,
        36,
        9,
        41,
        41,
        16,
        15,
        32,
        7,
        33,
        40,
        22
      ],
      "weeklyBackordered": [
        42,
        8,
        7,
        40,
        39,
        26,
        10,
        3,
        47,
        49,
        49,
        43,
        29,
        5,
        19,
        32,
        14,
        33,
        0,
        24,
        22,
        0,
        23,
        49,
        41,
        29,
        1,
        24,
        22,
        49,
        21,
        8,
        48,
        29,
        27,
        20,
        30,
        0,
        1,
        0,
        48,
        16,
        0,
        27,
        32,
        7,
        36,
        38,
        13,
        49,
        49,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        20,
        38,
        34,
        8,
        49,
        0,
        18,
        49,
        26,
        7,
        49,
        0,
        34,
        27,
        11,
        11,
        25,
        9,
        20,
        7,
        0,
        33,
        27,
        7,
        0,
        29,
        13,
        12,
        1,
        20,
        11,
        46,
        49,
        10,
        46,
        49,
        10,
        13,
        35,
        49,
        49,
        4,
        8,
        49,
        29,
        0,
        21,
        1,
        27,
        23,
        32,
        48
      ],
      "weeklyRejected": [
        38,
        11,
        49,
        47,
        31,
        24,
        27,
        0,
        26,
        13,
        21,
        49,
        15,
        32,
        13,
        0,
        0,
        43,
        16,
        0,
        49,
        49,
        47,
        49,
        8,
        49,
        5,
        39,
        34,
        0,
        1,
        28,
        43,
        23,
        9,
        49,
        14,
        39,
        1,
        18,
        41,
        14,
        32,
        13,
        49,
        32,
        2,
        12,
        0,
        42,
        12,
        26
      ],
      "weeklyHold": [
        4,
        33,
        27,
        1,
        12,
        40,
        42,
        10,
        31,
        44,
        25,
        49,
        45,
        13,
        8,
        1,
        23,
        17,
        0,
        49,
        34,
        6,
        0,
        28,
        10,
        1,
        49,
        13,
        21,
        0,
        2,
        41,
        13,
        41,
        13,
        49,
        1,
        0,
        40,
        31,
        29,
        40,
        31,
        49,
        21,
        11,
        45,
        4,
        2,
        0,
        49,
        35
      ],
      "weeklyBackordered": [
        35,
        27,
        9,
        45,
        48,
        32,
        13,
        31,
        28,
        1,
        49,
        12,
        13,
        49,
        26,
        19,
        48,
        49,
        15,
        26,
        36,
        5,
        41,
        0,
        1,
        22,
        2,
        25,
        25,
        49,
        38,
        38,
        48,
        33,
        0,
        0,
        22,
        26,
        31,
        49,
        19,
        48,
        13,
        36,
        49,
        49,
        35,
        24,
        8,
        49,
        23,
        33
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        9,
        49,
        29,
        29,
        7,
        31,
        8,
        49,
        48,
        4,
        49,
        6,
        27,
        29,
        38,
        23,
        49,
        6,
        10,
        27,
        7,
        13,
        49,
        32,
        0,
        34,
        0,
        0,
        1,
        16,
        6,
        33,
        49,
        21,
        48,
        29,
        12,
        23,
        49,
        32,
        42,
        16,
        49,
        37,
        49,
        0,
        0,
        21,
        0,
        49,
        34,
        12
      ],
      "weeklyRejected": [
        30,
        26,
        16,
        34,
        0,
        38,
        42,
        32,
        49,
        17,
        0,
        0,
        34,
        48,
        48,
        24,
        22,
        49,
        4,
        24,
        20,
        24,
        36,
        0,
        18,
        49,
        38,
        2,
        48,
        45,
        49,
        33,
        8,
        49,
        0,
        49,
        32,
        43,
        1,
        16,
        39,
        40,
        0,
        4,
        36,
        0,
        4,
        30,
        0,
        49,
        16,
        24
      ],
      "weeklyHold": [
        23,
        49,
        40,
        15,
        0,
        33,
        6,
        43,
        0,
        22,
        33,
        33,
        13,
        1,
        43,
        35,
        2,
        32,
        49,
        29,
        34,
        11,
        5,
        3,
        39,
        23,
        0,
        49,
        49,
        25,
        35,
        1,
        0,
        17,
        19,
        36,
        19,
        6,
        1,
        17,
        31,
        49,
        7,
        31,
        26,
        45,
        3,
        26,
        0,
        7,
        41,
        36
      ],
      "weeklyBackordered": [
        16,
        29,
        48,
        27,
        47,
        38,
        49,
        49,
        43,
        1,
        31,
        45,
        3,
        16,
        48,
        48,
        27,
        0,
        0,
        13,
        0,
        18,
        18,
        20,
        17,
        20,
        30,
        45,
        7,
        40,
        24,
        5,
        42,
        25,
        4,
        0,
        49,
        0,
        0,
        0,
        29,
        30,
        15,
        49,
        13,
        16,
        45,
        19,
        27,
        49,
        22,
        30
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        10,
        49,
        49,
        30,
        17,
        0,
        2,
        27,
        48,
        43,
        38,
        5,
        41,
        49,
        1,
        26,
        49,
        0,
        0,
        49,
        21,
        36,
        20,
        0,
        21,
        44,
        17,
        29,
        34,
        46,
        20,
        20,
        49,
        22,
        48,
        8,
        32,
        12,
        29,
        49,
        26,
        47,
        49,
        38,
        49,
        19,
        38,
        16,
        29,
        41,
        15,
        40
      ],
      "weeklyRejected": [
        31,
        14,
        19,
        13,
        45,
        16,
        7,
        24,
        30,
        48,
        48,
        3,
        0,
        40,
        48,
        0,
        0,
        40,
        49,
        10,
        0,
        44,
        16,
        12,
        21,
        49,
        28,
        20,
        48,
        29,
        49,
        19,
        0,
        19,
        22,
        49,
        49,
        34,
        1,
        35,
        29,
        44,
        42,
        8,
        49,
        13,
        26,
        21,
        0,
        29,
        12,
        26
      ],
      "weeklyHold": [
        45,
        24,
        49,
        1,
        49,
        32,
        49,
        49,
        5,
        0,
        0,
        9,
        35,
        7,
        45,
        0,
        15,
        42,
        15,
        2,
        15,
        0,
        24,
        0,
        30,
        33,
        29,
        0,
        41,
        49,
        33,
        0,
        37,
        45,
        17,
        41,
        33,
        30,
        18,
        37,
        10,
        41,
        17,
        31,
        22,
        42,
        40,
        49,
        0,
        9,
        36,
        49
      ],
      "weeklyBackordered": [
        32,
        7,
        6,
        36,
        39,
        46,
        49,
        46,
        43,
        21,
        41,
        34,
        19,
        16,
        6,
        49,
        16,
        48,
        18,
        0,
        0,
        20,
        25,
        49,
        22,
        22,
        16,
        32,
        14,
        35,
        43,
        25,
        32,
        17,
        32,
        0,
        18,
        0,
        49,
        0,
        38,
        13,
        0,
        49,
        0,
        32,
        49,
        32,
        0,
        8,
        49,
        14
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        49,
        49,
        6,
        48,
        23,
        29,
        5,
        49,
        15,
        12,
        49,
        0,
        24,
        0,
        25,
        1,
        31,
        16,
        4,
        10,
        3,
        23,
        49,
        25,
        8,
        41,
        7,
        36,
        49,
        49,
        49,
        19,
        16,
        42,
        48,
        10,
        42,
        48,
        33,
        49,
        20,
        32,
        0,
        49,
        36,
        23,
        0,
        11,
        0,
        24,
        36,
        0
      ],
      "weeklyRejected": [
        31,
        26,
        18,
        0,
        45,
        17,
        49,
        21,
        22,
        1,
        25,
        0,
        43,
        48,
        35,
        0,
        11,
        6,
        44,
        13,
        26,
        6,
        6,
        11,
        20,
        49,
        41,
        28,
        28,
        5,
        21,
        47,
        48,
        49,
        17,
        49,
        39,
        31,
        2,
        27,
        13,
        41,
        49,
        33,
        49,
        2,
        33,
        44,
        27,
        0,
        10,
        49
      ],
      "weeklyHold": [
        30,
        0,
        36,
        1,
        38,
        47,
        49,
        18,
        0,
        18,
        34,
        15,
        34,
        33,
        40,
        3,
        17,
        43,
        23,
        21,
        3,
        15,
        0,
        26,
        4,
        48,
        0,
        39,
        38,
        1,
        24,
        15,
        30,
        25,
        29,
        49,
        19,
        49,
        36,
        24,
        33,
        33,
        0,
        33,
        19,
        49,
        46,
        49,
        5,
        0,
        47,
        27
      ],
      "weeklyBackordered": [
        33,
        24,
        0,
        24,
        45,
        34,
        0,
        49,
        16,
        8,
        0,
        49,
        10,
        0,
        33,
        49,
        12,
        49,
        0,
        10,
        44,
        26,
        14,
        31,
        10,
        49,
        11,
        49,
        28,
        49,
        23,
        25,
        30,
        49,
        9,
        0,
        4,
        23,
        12,
        49,
        9,
        48,
        13,
        49,
        1,
        49,
        49,
        20,
        4,
        25,
        48,
        18
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        11,
        9,
        18,
        20,
        49,
        49,
        1,
        49,
        17,
        31,
        30,
        32,
        49,
        24,
        21,
        3,
        41,
        5,
        7,
        20,
        0,
        31,
        23,
        24,
        16,
        21,
        25,
        19,
        1,
        40,
        34,
        15,
        41,
        13,
        48,
        31,
        26,
        35,
        48,
        27,
        0,
        44,
        30,
        49,
        49,
        2,
        14,
        27,
        17,
        25,
        6,
        25
      ],
      "weeklyRejected": [
        42,
        19,
        48,
        21,
        48,
        8,
        6,
        9,
        49,
        12,
        15,
        49,
        40,
        48,
        16,
        28,
        36,
        29,
        3,
        31,
        0,
        13,
        35,
        30,
        0,
        49,
        21,
        7,
        41,
        32,
        14,
        13,
        23,
        49,
        34,
        49,
        34,
        39,
        27,
        42,
        48,
        14,
        19,
        0,
        47,
        23,
        42,
        37,
        20,
        49,
        0,
        18
      ],
      "weeklyHold": [
        17,
        16,
        46,
        16,
        18,
        19,
        10,
        2,
        38,
        13,
        25,
        3,
        35,
        37,
        43,
        30,
        34,
        27,
        26,
        0,
        42,
        6,
        17,
        49,
        4,
        38,
        39,
        43,
        49,
        10,
        45,
        44,
        49,
        49,
        13,
        10,
        39,
        38,
        49,
        31,
        37,
        21,
        7,
        33,
        33,
        19,
        48,
        37,
        19,
        9,
        24,
        20
      ],
      "weeklyBackordered": [
        26,
        35,
        0,
        14,
        31,
        31,
        40,
        49,
        36,
        2,
        31,
        43,
        3,
        12,
        35,
        36,
        8,
        49,
        19,
        11,
        29,
        0,
        0,
        32,
        32,
        28,
        39,
        11,
        22,
        43,
        18,
        44,
        6,
        22,
        0,
        0,
        44,
        0,
        22,
        0,
        8,
        48,
        8,
        15,
        39,
        49,
        21,
        42,
        16,
        34,
        49,
        7
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        0,
        48,
        41,
        5,
        7,
        44,
        40,
        12,
        18,
        23,
        27,
        12,
        4,
        0,
        16,
        29,
        37,
        12,
        9,
        0,
        49,
        26,
        36,
        0,
        15,
        32,
        27,
        0,
        17,
        37,
        10,
        0,
        49,
        0,
        32,
        47,
        47,
        48,
        18,
        49,
        2,
        16,
        49,
        49,
        49,
        38,
        0,
        49,
        23,
        29,
        17,
        6
      ],
      "weeklyRejected": [
        40,
        11,
        30,
        36,
        41,
        17,
        9,
        49,
        27,
        16,
        49,
        43,
        8,
        40,
        2,
        39,
        13,
        38,
        37,
        0,
        49,
        37,
        49,
        31,
        30,
        34,
        49,
        0,
        19,
        33,
        18,
        19,
        26,
        49,
        10,
        38,
        49,
        33,
        30,
        49,
        48,
        49,
        33,
        2,
        49,
        39,
        11,
        36,
        5,
        47,
        28,
        25
      ],
      "weeklyHold": [
        5,
        35,
        18,
        23,
        43,
        48,
        21,
        2,
        0,
        27,
        39,
        39,
        30,
        17,
        36,
        26,
        16,
        16,
        25,
        44,
        0,
        18,
        20,
        0,
        27,
        14,
        33,
        14,
        49,
        42,
        48,
        13,
        3,
        21,
        1,
        49,
        33,
        0,
        18,
        0,
        36,
        18,
        0,
        34,
        12,
        37,
        23,
        16,
        3,
        33,
        42,
        49
      ],
      "weeklyBackordered": [
        8,
        8,
        24,
        28,
        44,
        46,
        2,
        1,
        19,
        0,
        30,
        49,
        3,
        0,
        24,
        14,
        40,
        36,
        0,
        13,
        49,
        16,
        39,
        35,
        1,
        12,
        49,
        7,
        49,
        17,
        34,
        49,
        48,
        0,
        28,
        0,
        1,
        17,
        5,
        20,
        14,
        29,
        12,
        23,
        14,
        4,
        49,
        19,
        27,
        49,
        25,
        35
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        38,
        1,
        49,
        33,
        33,
        17,
        12,
        42,
        48,
        0,
        13,
        7,
        48,
        4,
        36,
        35,
        49,
        0,
        15,
        33,
        49,
        47,
        34,
        23,
        22,
        47,
        20,
        21,
        1,
        1,
        8,
        45,
        28,
        10,
        48,
        29,
        42,
        18,
        43,
        49,
        11,
        13,
        49,
        49,
        21,
        10,
        0,
        24,
        27,
        26,
        27,
        4
      ],
      "weeklyRejected": [
        11,
        8,
        49,
        47,
        26,
        10,
        43,
        35,
        31,
        21,
        49,
        30,
        13,
        25,
        48,
        14,
        10,
        49,
        34,
        0,
        9,
        40,
        36,
        49,
        15,
        32,
        49,
        36,
        1,
        44,
        32,
        21,
        22,
        40,
        17,
        49,
        0,
        0,
        7,
        0,
        40,
        18,
        22,
        25,
        31,
        10,
        0,
        28,
        33,
        26,
        0,
        49
      ],
      "weeklyHold": [
        0,
        49,
        38,
        27,
        10,
        41,
        14,
        2,
        0,
        3,
        0,
        49,
        24,
        35,
        21,
        10,
        0,
        48,
        35,
        17,
        35,
        27,
        18,
        26,
        10,
        15,
        34,
        8,
        37,
        44,
        37,
        48,
        0,
        0,
        48,
        30,
        21,
        0,
        1,
        29,
        49,
        18,
        12,
        0,
        0,
        29,
        0,
        15,
        1,
        43,
        0,
        36
      ],
      "weeklyBackordered": [
        49,
        6,
        10,
        2,
        44,
        32,
        24,
        1,
        27,
        3,
        4,
        35,
        3,
        26,
        49,
        7,
        35,
        49,
        12,
        25,
        13,
        23,
        13,
        7,
        5,
        27,
        17,
        3,
        37,
        5,
        20,
        16,
        47,
        23,
        13,
        49,
        40,
        7,
        6,
        41,
        11,
        45,
        1,
        13,
        30,
        49,
        49,
        22,
        14,
        48,
        20,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        0,
        24,
        31,
        48,
        47,
        41,
        32,
        30,
        48,
        34,
        46,
        0,
        9,
        49,
        47,
        47,
        42,
        29,
        22,
        24,
        28,
        43,
        49,
        49,
        13,
        19,
        0,
        0,
        29,
        22,
        0,
        18,
        49,
        30,
        48,
        24,
        47,
        4,
        12,
        49,
        6,
        49,
        23,
        41,
        32,
        22,
        27,
        4,
        5,
        49,
        0,
        27
      ],
      "weeklyRejected": [
        49,
        6,
        10,
        47,
        48,
        0,
        23,
        38,
        27,
        16,
        0,
        7,
        44,
        26,
        41,
        25,
        1,
        33,
        2,
        4,
        46,
        48,
        49,
        26,
        27,
        49,
        42,
        0,
        42,
        0,
        35,
        21,
        48,
        28,
        14,
        10,
        5,
        0,
        1,
        49,
        45,
        31,
        49,
        0,
        45,
        27,
        0,
        0,
        18,
        49,
        33,
        49
      ],
      "weeklyHold": [
        48,
        14,
        44,
        1,
        0,
        4,
        24,
        13,
        4,
        30,
        47,
        44,
        41,
        36,
        14,
        28,
        2,
        39,
        30,
        17,
        35,
        22,
        49,
        47,
        28,
        44,
        14,
        23,
        8,
        12,
        48,
        14,
        0,
        21,
        0,
        0,
        3,
        0,
        49,
        47,
        0,
        23,
        33,
        49,
        10,
        49,
        30,
        49,
        30,
        31,
        34,
        23
      ],
      "weeklyBackordered": [
        15,
        19,
        12,
        32,
        49,
        38,
        5,
        49,
        32,
        0,
        16,
        49,
        21,
        0,
        17,
        45,
        2,
        7,
        30,
        14,
        49,
        14,
        20,
        25,
        1,
        31,
        20,
        0,
        24,
        47,
        35,
        37,
        20,
        42,
        0,
        0,
        11,
        1,
        35,
        18,
        10,
        17,
        22,
        49,
        26,
        13,
        49,
        3,
        26,
        49,
        1,
        32
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        28,
        45,
        36,
        14,
        29,
        46,
        11,
        49,
        42,
        12,
        48,
        10,
        0,
        34,
        37,
        29,
        24,
        2,
        48,
        46,
        7,
        0,
        49,
        1,
        8,
        47,
        6,
        23,
        1,
        11,
        19,
        0,
        33,
        17,
        48,
        37,
        30,
        10,
        27,
        49,
        49,
        49,
        49,
        33,
        34,
        11,
        6,
        0,
        0,
        39,
        42,
        24
      ],
      "weeklyRejected": [
        30,
        49,
        24,
        0,
        49,
        22,
        0,
        34,
        49,
        5,
        39,
        0,
        38,
        48,
        37,
        14,
        26,
        49,
        46,
        15,
        35,
        27,
        11,
        49,
        14,
        32,
        27,
        21,
        48,
        34,
        46,
        47,
        45,
        27,
        0,
        49,
        36,
        17,
        11,
        17,
        42,
        36,
        0,
        12,
        22,
        31,
        31,
        24,
        15,
        29,
        0,
        34
      ],
      "weeklyHold": [
        17,
        7,
        25,
        1,
        47,
        8,
        48,
        42,
        30,
        8,
        39,
        1,
        14,
        10,
        49,
        0,
        0,
        38,
        3,
        31,
        20,
        13,
        41,
        33,
        0,
        18,
        22,
        7,
        15,
        0,
        33,
        30,
        12,
        49,
        39,
        35,
        4,
        3,
        19,
        16,
        15,
        14,
        25,
        16,
        49,
        46,
        49,
        49,
        0,
        49,
        47,
        8
      ],
      "weeklyBackordered": [
        0,
        25,
        45,
        0,
        49,
        35,
        0,
        40,
        39,
        0,
        15,
        10,
        26,
        26,
        39,
        49,
        42,
        49,
        18,
        25,
        39,
        40,
        37,
        45,
        42,
        47,
        32,
        42,
        9,
        49,
        31,
        49,
        47,
        21,
        24,
        26,
        23,
        0,
        6,
        14,
        39,
        30,
        9,
        41,
        18,
        15,
        49,
        30,
        24,
        22,
        38,
        20
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        4,
        21,
        15,
        46,
        25,
        2,
        37,
        30,
        48,
        49,
        9,
        8,
        24,
        29,
        26,
        34,
        49,
        21,
        0,
        28,
        28,
        47,
        47,
        48,
        46,
        47,
        19,
        49,
        22,
        17,
        30,
        34,
        42,
        21,
        12,
        41,
        26,
        32,
        49,
        49,
        26,
        39,
        0,
        12,
        36,
        30,
        9,
        11,
        13,
        33,
        25,
        28
      ],
      "weeklyRejected": [
        49,
        32,
        35,
        32,
        49,
        33,
        13,
        1,
        11,
        30,
        30,
        10,
        17,
        27,
        42,
        49,
        13,
        49,
        29,
        26,
        49,
        49,
        8,
        42,
        1,
        49,
        1,
        0,
        32,
        18,
        24,
        47,
        38,
        49,
        23,
        35,
        0,
        27,
        23,
        13,
        34,
        24,
        48,
        19,
        49,
        39,
        45,
        1,
        5,
        7,
        0,
        49
      ],
      "weeklyHold": [
        17,
        27,
        16,
        19,
        49,
        40,
        15,
        2,
        0,
        49,
        30,
        15,
        48,
        27,
        15,
        49,
        23,
        29,
        0,
        49,
        41,
        49,
        49,
        0,
        17,
        41,
        49,
        37,
        13,
        0,
        21,
        0,
        0,
        29,
        23,
        20,
        28,
        22,
        49,
        44,
        6,
        45,
        13,
        7,
        49,
        0,
        22,
        36,
        0,
        5,
        40,
        21
      ],
      "weeklyBackordered": [
        43,
        49,
        0,
        0,
        42,
        46,
        39,
        17,
        43,
        29,
        33,
        43,
        27,
        41,
        37,
        29,
        5,
        49,
        0,
        27,
        42,
        49,
        0,
        49,
        1,
        26,
        9,
        27,
        14,
        49,
        0,
        49,
        41,
        49,
        7,
        29,
        13,
        1,
        19,
        27,
        12,
        48,
        42,
        49,
        31,
        0,
        49,
        22,
        21,
        15,
        9,
        1
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        0,
        46,
        6,
        4,
        21,
        27,
        28,
        49,
        24,
        38,
        44,
        24,
        0,
        11,
        49,
        22,
        40,
        5,
        10,
        49,
        23,
        32,
        49,
        19,
        0,
        47,
        42,
        21,
        26,
        15,
        0,
        0,
        35,
        7,
        40,
        38,
        12,
        48,
        32,
        24,
        31,
        17,
        23,
        31,
        49,
        31,
        27,
        36,
        27,
        38,
        5,
        48
      ],
      "weeklyRejected": [
        44,
        13,
        4,
        9,
        37,
        0,
        49,
        49,
        30,
        1,
        6,
        23,
        34,
        31,
        28,
        21,
        49,
        32,
        9,
        4,
        21,
        49,
        7,
        21,
        32,
        24,
        49,
        26,
        18,
        4,
        12,
        30,
        24,
        49,
        0,
        37,
        46,
        25,
        1,
        41,
        48,
        26,
        42,
        24,
        25,
        5,
        16,
        40,
        27,
        29,
        2,
        43
      ],
      "weeklyHold": [
        9,
        49,
        30,
        1,
        32,
        19,
        22,
        9,
        24,
        15,
        38,
        19,
        23,
        25,
        12,
        17,
        0,
        13,
        3,
        29,
        6,
        27,
        3,
        49,
        8,
        25,
        3,
        18,
        32,
        2,
        48,
        36,
        4,
        0,
        48,
        24,
        31,
        0,
        27,
        38,
        40,
        44,
        38,
        15,
        28,
        40,
        30,
        3,
        1,
        28,
        49,
        0
      ],
      "weeklyBackordered": [
        18,
        37,
        14,
        47,
        20,
        34,
        33,
        46,
        32,
        13,
        0,
        20,
        40,
        19,
        0,
        39,
        49,
        32,
        0,
        2,
        49,
        0,
        11,
        10,
        1,
        5,
        36,
        0,
        15,
        38,
        39,
        49,
        34,
        33,
        35,
        7,
        9,
        0,
        20,
        12,
        18,
        9,
        17,
        38,
        22,
        0,
        27,
        49,
        26,
        49,
        41,
        23
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        32,
        49,
        15,
        16,
        43,
        5,
        11,
        49,
        9,
        49,
        26,
        0,
        13,
        49,
        20,
        27,
        46,
        2,
        6,
        27,
        13,
        36,
        49,
        18,
        0,
        21,
        24,
        22,
        33,
        23,
        25,
        13,
        16,
        0,
        48,
        15,
        30,
        20,
        0,
        33,
        9,
        8,
        49,
        17,
        21,
        32,
        28,
        6,
        11,
        31,
        19,
        6
      ],
      "weeklyRejected": [
        39,
        29,
        18,
        47,
        43,
        1,
        48,
        34,
        49,
        20,
        29,
        19,
        0,
        13,
        48,
        10,
        0,
        49,
        0,
        8,
        9,
        19,
        3,
        1,
        25,
        49,
        28,
        42,
        24,
        20,
        0,
        8,
        24,
        49,
        35,
        33,
        49,
        28,
        1,
        29,
        19,
        7,
        37,
        0,
        43,
        24,
        12,
        25,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        22,
        14,
        43,
        1,
        22,
        17,
        21,
        11,
        18,
        43,
        47,
        18,
        45,
        49,
        22,
        30,
        14,
        1,
        24,
        0,
        29,
        22,
        31,
        1,
        27,
        16,
        49,
        0,
        16,
        0,
        44,
        16,
        0,
        44,
        1,
        37,
        21,
        0,
        35,
        49,
        0,
        49,
        0,
        13,
        39,
        20,
        4,
        35,
        0,
        33,
        43,
        11
      ],
      "weeklyBackordered": [
        0,
        49,
        18,
        17,
        47,
        38,
        37,
        10,
        17,
        0,
        33,
        49,
        27,
        41,
        26,
        1,
        16,
        49,
        14,
        3,
        36,
        26,
        4,
        11,
        18,
        26,
        25,
        0,
        40,
        49,
        41,
        30,
        46,
        49,
        8,
        8,
        1,
        0,
        25,
        0,
        13,
        33,
        16,
        49,
        5,
        42,
        37,
        11,
        20,
        19,
        1,
        49
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        7,
        49,
        14,
        38,
        22,
        22,
        11,
        31,
        0,
        23,
        40,
        22,
        4,
        29,
        21,
        5,
        34,
        48,
        25,
        12,
        46,
        29,
        10,
        39,
        13,
        39,
        0,
        49,
        9,
        5,
        1,
        37,
        26,
        25,
        14,
        49,
        45,
        48,
        0,
        35,
        5,
        28,
        0,
        18,
        49,
        3,
        0,
        42,
        44,
        5,
        45,
        16
      ],
      "weeklyRejected": [
        21,
        17,
        40,
        3,
        24,
        0,
        34,
        49,
        49,
        1,
        38,
        0,
        37,
        48,
        40,
        31,
        45,
        19,
        23,
        49,
        19,
        39,
        26,
        22,
        8,
        33,
        49,
        13,
        27,
        18,
        18,
        7,
        33,
        9,
        37,
        15,
        22,
        42,
        48,
        30,
        21,
        38,
        35,
        27,
        22,
        6,
        0,
        39,
        12,
        49,
        0,
        49
      ],
      "weeklyHold": [
        36,
        0,
        37,
        1,
        11,
        40,
        32,
        8,
        17,
        23,
        26,
        41,
        41,
        30,
        22,
        12,
        24,
        14,
        0,
        7,
        0,
        41,
        40,
        14,
        29,
        48,
        0,
        10,
        45,
        4,
        48,
        11,
        15,
        9,
        41,
        10,
        0,
        26,
        49,
        34,
        49,
        23,
        14,
        0,
        49,
        34,
        0,
        27,
        0,
        38,
        23,
        10
      ],
      "weeklyBackordered": [
        30,
        7,
        8,
        15,
        0,
        19,
        27,
        33,
        39,
        46,
        17,
        0,
        21,
        14,
        0,
        49,
        49,
        7,
        20,
        8,
        27,
        5,
        30,
        32,
        38,
        1,
        49,
        19,
        14,
        49,
        13,
        46,
        37,
        33,
        5,
        33,
        37,
        4,
        19,
        27,
        21,
        13,
        39,
        43,
        13,
        24,
        40,
        32,
        0,
        49,
        49,
        23
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        11,
        47,
        29,
        31,
        1,
        5,
        26,
        29,
        43,
        42,
        31,
        5,
        46,
        0,
        42,
        33,
        41,
        15,
        0,
        14,
        34,
        8,
        41,
        32,
        18,
        47,
        12,
        49,
        49,
        47,
        6,
        21,
        37,
        44,
        0,
        16,
        47,
        2,
        49,
        49,
        0,
        21,
        49,
        40,
        48,
        0,
        0,
        49,
        27,
        49,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        49,
        24,
        35,
        49,
        19,
        0,
        0,
        4,
        32,
        32,
        34,
        14,
        24,
        20,
        0,
        11,
        49,
        33,
        25,
        9,
        20,
        49,
        26,
        33,
        49,
        25,
        1,
        48,
        0,
        40,
        28,
        34,
        49,
        35,
        33,
        36,
        24,
        19,
        43,
        43,
        16,
        38,
        0,
        49,
        49,
        15,
        32,
        3,
        49,
        0,
        49
      ],
      "weeklyHold": [
        11,
        0,
        43,
        13,
        37,
        48,
        4,
        39,
        0,
        39,
        26,
        49,
        48,
        13,
        10,
        28,
        26,
        3,
        0,
        27,
        11,
        21,
        0,
        19,
        12,
        16,
        0,
        17,
        30,
        4,
        40,
        7,
        24,
        31,
        37,
        20,
        34,
        3,
        35,
        43,
        49,
        36,
        11,
        49,
        38,
        4,
        19,
        49,
        24,
        43,
        12,
        15
      ],
      "weeklyBackordered": [
        49,
        21,
        22,
        26,
        49,
        46,
        26,
        44,
        37,
        0,
        46,
        18,
        7,
        0,
        0,
        1,
        47,
        49,
        41,
        24,
        49,
        30,
        31,
        49,
        21,
        22,
        1,
        6,
        7,
        38,
        2,
        34,
        41,
        15,
        24,
        10,
        49,
        13,
        0,
        0,
        16,
        48,
        12,
        49,
        11,
        49,
        37,
        32,
        32,
        49,
        18,
        42
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        34,
        21,
        0,
        9,
        45,
        49,
        1,
        22,
        48,
        49,
        42,
        12,
        13,
        9,
        1,
        8,
        8,
        0,
        4,
        42,
        15,
        27,
        24,
        42,
        13,
        36,
        32,
        32,
        28,
        33,
        8,
        15,
        28,
        37,
        48,
        21,
        7,
        25,
        49,
        49,
        0,
        0,
        34,
        28,
        49,
        20,
        0,
        19,
        0,
        49,
        49,
        47
      ],
      "weeklyRejected": [
        49,
        21,
        15,
        10,
        26,
        14,
        47,
        49,
        38,
        8,
        33,
        0,
        0,
        48,
        48,
        17,
        37,
        30,
        47,
        6,
        2,
        20,
        19,
        0,
        20,
        49,
        33,
        24,
        27,
        24,
        28,
        4,
        18,
        32,
        0,
        47,
        32,
        7,
        8,
        1,
        48,
        48,
        25,
        12,
        28,
        49,
        3,
        48,
        7,
        23,
        0,
        49
      ],
      "weeklyHold": [
        0,
        0,
        41,
        1,
        10,
        4,
        8,
        32,
        4,
        16,
        40,
        33,
        10,
        6,
        37,
        0,
        44,
        43,
        0,
        8,
        12,
        28,
        27,
        2,
        49,
        6,
        26,
        49,
        41,
        26,
        48,
        13,
        0,
        40,
        46,
        37,
        33,
        44,
        49,
        46,
        31,
        36,
        20,
        0,
        38,
        38,
        26,
        3,
        4,
        16,
        21,
        25
      ],
      "weeklyBackordered": [
        35,
        19,
        0,
        0,
        29,
        44,
        19,
        5,
        28,
        0,
        39,
        9,
        42,
        49,
        45,
        47,
        5,
        34,
        0,
        17,
        15,
        18,
        13,
        2,
        10,
        6,
        1,
        46,
        1,
        31,
        16,
        10,
        40,
        9,
        41,
        12,
        49,
        13,
        11,
        2,
        32,
        0,
        22,
        49,
        14,
        23,
        16,
        22,
        0,
        32,
        22,
        34
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        26,
        8,
        26,
        0,
        17,
        11,
        31,
        39,
        48,
        49,
        39,
        0,
        35,
        42,
        34,
        49,
        31,
        48,
        0,
        49,
        31,
        34,
        49,
        32,
        1,
        47,
        36,
        0,
        1,
        37,
        7,
        46,
        22,
        13,
        48,
        21,
        0,
        36,
        13,
        49,
        41,
        49,
        36,
        39,
        17,
        0,
        8,
        26,
        0,
        18,
        49,
        0
      ],
      "weeklyRejected": [
        49,
        15,
        37,
        45,
        0,
        9,
        20,
        11,
        49,
        13,
        32,
        32,
        30,
        26,
        19,
        32,
        13,
        49,
        49,
        33,
        41,
        31,
        39,
        33,
        12,
        49,
        21,
        3,
        48,
        27,
        14,
        28,
        41,
        0,
        38,
        49,
        0,
        12,
        10,
        38,
        28,
        21,
        16,
        29,
        36,
        12,
        0,
        11,
        0,
        49,
        8,
        12
      ],
      "weeklyHold": [
        35,
        32,
        49,
        1,
        21,
        42,
        8,
        40,
        35,
        34,
        36,
        23,
        10,
        1,
        41,
        27,
        2,
        9,
        22,
        1,
        42,
        49,
        21,
        12,
        31,
        39,
        10,
        29,
        40,
        11,
        26,
        8,
        0,
        2,
        22,
        25,
        6,
        4,
        1,
        19,
        0,
        31,
        21,
        14,
        49,
        23,
        27,
        36,
        34,
        44,
        31,
        49
      ],
      "weeklyBackordered": [
        30,
        21,
        12,
        32,
        49,
        46,
        32,
        32,
        23,
        6,
        37,
        21,
        29,
        40,
        5,
        1,
        24,
        15,
        24,
        41,
        0,
        38,
        24,
        2,
        13,
        12,
        49,
        49,
        1,
        37,
        13,
        41,
        16,
        27,
        19,
        16,
        18,
        0,
        27,
        13,
        30,
        19,
        18,
        17,
        24,
        35,
        49,
        14,
        6,
        41,
        49,
        18
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        0,
        34,
        29,
        31,
        34,
        42,
        12,
        26,
        31,
        31,
        37,
        26,
        42,
        28,
        23,
        49,
        37,
        38,
        0,
        49,
        22,
        49,
        49,
        49,
        35,
        23,
        0,
        2,
        8,
        49,
        9,
        30,
        28,
        46,
        31,
        44,
        47,
        12,
        4,
        39,
        1,
        49,
        49,
        33,
        17,
        0,
        28,
        47,
        22,
        30,
        0,
        15
      ],
      "weeklyRejected": [
        0,
        13,
        49,
        47,
        17,
        8,
        13,
        4,
        33,
        15,
        49,
        29,
        38,
        27,
        33,
        38,
        26,
        49,
        5,
        37,
        7,
        0,
        7,
        45,
        28,
        33,
        25,
        35,
        48,
        48,
        29,
        7,
        37,
        49,
        31,
        33,
        19,
        19,
        18,
        37,
        43,
        23,
        13,
        28,
        35,
        29,
        0,
        16,
        17,
        49,
        0,
        26
      ],
      "weeklyHold": [
        29,
        12,
        49,
        19,
        11,
        35,
        26,
        10,
        37,
        34,
        15,
        32,
        33,
        13,
        30,
        27,
        15,
        18,
        18,
        15,
        49,
        25,
        36,
        36,
        27,
        12,
        35,
        43,
        49,
        21,
        22,
        9,
        0,
        0,
        46,
        35,
        12,
        14,
        35,
        49,
        0,
        33,
        0,
        8,
        29,
        32,
        10,
        14,
        4,
        22,
        41,
        2
      ],
      "weeklyBackordered": [
        30,
        11,
        0,
        11,
        35,
        35,
        28,
        45,
        16,
        41,
        47,
        7,
        14,
        0,
        8,
        27,
        7,
        14,
        35,
        0,
        36,
        0,
        21,
        31,
        46,
        1,
        49,
        49,
        3,
        49,
        29,
        0,
        0,
        26,
        1,
        24,
        5,
        11,
        49,
        33,
        11,
        48,
        12,
        49,
        17,
        0,
        6,
        49,
        32,
        19,
        49,
        49
      ]
    },
    {
      "id": "BB20277D",
      "weeklyAccepted": [
        0,
        23,
        27,
        19,
        49,
        27,
        23,
        30,
        48,
        21,
        49,
        0,
        15,
        31,
        49,
        21,
        49,
        9,
        10,
        49,
        31,
        19,
        37,
        29,
        9,
        47,
        0,
        9,
        9,
        11,
        0,
        15,
        46,
        43,
        21,
        30,
        9,
        0,
        30,
        49,
        21,
        0,
        43,
        49,
        49,
        2,
        30,
        24,
        19,
        39,
        27,
        37
      ],
      "weeklyRejected": [
        28,
        31,
        49,
        41,
        42,
        36,
        46,
        24,
        37,
        28,
        37,
        19,
        36,
        46,
        32,
        6,
        26,
        27,
        28,
        31,
        7,
        21,
        49,
        20,
        8,
        33,
        36,
        29,
        27,
        11,
        31,
        11,
        34,
        49,
        28,
        38,
        28,
        47,
        1,
        0,
        45,
        11,
        33,
        11,
        49,
        13,
        28,
        18,
        0,
        49,
        0,
        41
      ],
      "weeklyHold": [
        6,
        8,
        18,
        17,
        21,
        48,
        13,
        9,
        49,
        36,
        47,
        17,
        48,
        19,
        36,
        0,
        29,
        15,
        43,
        0,
        45,
        30,
        6,
        27,
        14,
        48,
        21,
        0,
        38,
        19,
        48,
        0,
        26,
        36,
        40,
        15,
        4,
        35,
        1,
        49,
        28,
        33,
        2,
        49,
        25,
        42,
        0,
        27,
        22,
        4,
        37,
        35
      ],
      "weeklyBackordered": [
        49,
        0,
        8,
        48,
        48,
        33,
        34,
        37,
        39,
        28,
        49,
        29,
        48,
        31,
        34,
        43,
        32,
        49,
        33,
        8,
        29,
        0,
        5,
        0,
        7,
        27,
        33,
        0,
        15,
        18,
        0,
        22,
        46,
        31,
        30,
        0,
        26,
        7,
        41,
        0,
        18,
        48,
        6,
        49,
        36,
        49,
        49,
        8,
        16,
        46,
        20,
        36
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        5,
        49,
        49,
        44,
        41,
        0,
        14,
        49,
        48,
        0,
        49,
        0,
        40,
        26,
        49,
        49,
        49,
        0,
        30,
        2,
        25,
        13,
        44,
        33,
        21,
        47,
        36,
        9,
        1,
        49,
        9,
        20,
        24,
        0,
        48,
        2,
        16,
        11,
        15,
        49,
        21,
        17,
        49,
        23,
        49,
        14,
        37,
        32,
        16,
        41,
        0,
        21
      ],
      "weeklyRejected": [
        5,
        12,
        49,
        47,
        32,
        34,
        48,
        49,
        49,
        1,
        39,
        25,
        38,
        11,
        48,
        6,
        26,
        38,
        30,
        43,
        2,
        0,
        20,
        7,
        26,
        35,
        46,
        30,
        46,
        29,
        49,
        31,
        48,
        49,
        11,
        15,
        0,
        45,
        25,
        49,
        12,
        49,
        19,
        7,
        28,
        8,
        0,
        26,
        23,
        49,
        19,
        42
      ],
      "weeklyHold": [
        49,
        13,
        44,
        19,
        14,
        27,
        49,
        19,
        43,
        34,
        41,
        13,
        35,
        1,
        43,
        47,
        0,
        25,
        10,
        0,
        39,
        0,
        8,
        0,
        11,
        1,
        0,
        49,
        26,
        0,
        13,
        15,
        0,
        21,
        48,
        17,
        49,
        1,
        1,
        45,
        40,
        36,
        32,
        5,
        49,
        32,
        39,
        31,
        0,
        27,
        15,
        43
      ],
      "weeklyBackordered": [
        17,
        0,
        17,
        33,
        49,
        46,
        49,
        49,
        33,
        0,
        22,
        49,
        13,
        4,
        19,
        49,
        21,
        49,
        14,
        28,
        35,
        26,
        32,
        22,
        25,
        32,
        49,
        49,
        20,
        46,
        39,
        10,
        47,
        20,
        0,
        25,
        1,
        0,
        5,
        22,
        31,
        33,
        17,
        49,
        49,
        45,
        49,
        35,
        32,
        49,
        40,
        31
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        0,
        41,
        29,
        3,
        35,
        0,
        10,
        48,
        34,
        46,
        35,
        19,
        18,
        49,
        30,
        0,
        44,
        18,
        0,
        23,
        0,
        4,
        33,
        49,
        19,
        23,
        1,
        1,
        1,
        39,
        22,
        10,
        49,
        36,
        48,
        36,
        16,
        48,
        32,
        1,
        17,
        7,
        49,
        3,
        49,
        42,
        0,
        9,
        49,
        32,
        44,
        36
      ],
      "weeklyRejected": [
        49,
        11,
        27,
        37,
        49,
        0,
        7,
        49,
        29,
        1,
        17,
        0,
        22,
        48,
        4,
        8,
        32,
        41,
        33,
        41,
        6,
        32,
        38,
        0,
        35,
        49,
        41,
        30,
        45,
        0,
        0,
        25,
        48,
        14,
        27,
        49,
        14,
        49,
        1,
        4,
        41,
        24,
        33,
        20,
        46,
        10,
        16,
        44,
        0,
        33,
        0,
        34
      ],
      "weeklyHold": [
        3,
        49,
        46,
        15,
        35,
        36,
        4,
        3,
        31,
        10,
        46,
        27,
        1,
        49,
        26,
        8,
        29,
        0,
        13,
        48,
        0,
        39,
        37,
        18,
        16,
        48,
        40,
        0,
        33,
        17,
        18,
        0,
        0,
        29,
        18,
        49,
        1,
        0,
        49,
        45,
        21,
        49,
        33,
        0,
        20,
        48,
        5,
        20,
        2,
        21,
        0,
        20
      ],
      "weeklyBackordered": [
        17,
        16,
        0,
        41,
        39,
        46,
        49,
        42,
        49,
        0,
        12,
        2,
        32,
        46,
        19,
        16,
        29,
        49,
        0,
        0,
        31,
        17,
        9,
        25,
        38,
        1,
        2,
        16,
        12,
        30,
        18,
        17,
        48,
        49,
        12,
        11,
        10,
        10,
        27,
        32,
        28,
        48,
        3,
        49,
        37,
        30,
        43,
        19,
        28,
        44,
        49,
        11
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        21,
        8,
        40,
        19,
        1,
        0,
        28,
        49,
        34,
        0,
        49,
        0,
        21,
        19,
        17,
        11,
        49,
        0,
        3,
        0,
        25,
        22,
        21,
        0,
        7,
        47,
        10,
        25,
        1,
        49,
        49,
        34,
        24,
        32,
        48,
        41,
        47,
        48,
        49,
        49,
        18,
        6,
        27,
        49,
        49,
        1,
        0,
        17,
        25,
        33,
        9,
        32
      ],
      "weeklyRejected": [
        49,
        0,
        24,
        18,
        13,
        39,
        49,
        42,
        49,
        10,
        18,
        45,
        17,
        48,
        45,
        41,
        46,
        49,
        49,
        19,
        45,
        49,
        49,
        49,
        0,
        3,
        49,
        25,
        13,
        48,
        13,
        37,
        14,
        40,
        19,
        48,
        27,
        17,
        2,
        21,
        48,
        27,
        0,
        6,
        49,
        8,
        0,
        24,
        0,
        35,
        0,
        21
      ],
      "weeklyHold": [
        14,
        24,
        49,
        15,
        49,
        46,
        8,
        2,
        12,
        29,
        42,
        46,
        1,
        36,
        20,
        20,
        14,
        36,
        24,
        13,
        10,
        0,
        28,
        9,
        15,
        41,
        5,
        23,
        38,
        32,
        43,
        48,
        0,
        49,
        48,
        49,
        5,
        4,
        35,
        0,
        49,
        35,
        0,
        31,
        0,
        25,
        20,
        7,
        30,
        11,
        38,
        49
      ],
      "weeklyBackordered": [
        49,
        38,
        48,
        43,
        49,
        46,
        49,
        32,
        20,
        17,
        49,
        49,
        36,
        21,
        1,
        45,
        33,
        19,
        44,
        49,
        32,
        19,
        0,
        49,
        1,
        26,
        29,
        40,
        32,
        49,
        45,
        43,
        37,
        0,
        0,
        0,
        48,
        5,
        38,
        28,
        20,
        48,
        20,
        46,
        0,
        30,
        6,
        9,
        0,
        49,
        30,
        4
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        33,
        47,
        25,
        7,
        34,
        16,
        1,
        28,
        32,
        0,
        49,
        25,
        19,
        0,
        27,
        38,
        49,
        0,
        2,
        0,
        14,
        26,
        11,
        28,
        24,
        9,
        0,
        49,
        18,
        49,
        39,
        2,
        26,
        10,
        48,
        48,
        16,
        45,
        49,
        49,
        34,
        30,
        45,
        15,
        25,
        5,
        0,
        28,
        17,
        9,
        27,
        3
      ],
      "weeklyRejected": [
        15,
        0,
        21,
        35,
        25,
        37,
        38,
        40,
        34,
        15,
        28,
        43,
        3,
        38,
        33,
        19,
        36,
        29,
        18,
        0,
        14,
        29,
        30,
        19,
        10,
        20,
        45,
        7,
        25,
        35,
        25,
        36,
        21,
        46,
        17,
        49,
        30,
        25,
        44,
        33,
        7,
        40,
        13,
        37,
        49,
        8,
        6,
        4,
        18,
        25,
        22,
        49
      ],
      "weeklyHold": [
        11,
        0,
        40,
        31,
        2,
        43,
        40,
        49,
        41,
        22,
        33,
        37,
        48,
        17,
        40,
        25,
        39,
        0,
        28,
        5,
        23,
        0,
        33,
        20,
        37,
        2,
        33,
        17,
        33,
        0,
        48,
        28,
        10,
        25,
        47,
        29,
        32,
        17,
        35,
        17,
        49,
        44,
        0,
        36,
        15,
        22,
        4,
        18,
        7,
        49,
        0,
        33
      ],
      "weeklyBackordered": [
        18,
        18,
        0,
        39,
        36,
        28,
        32,
        30,
        49,
        9,
        29,
        49,
        3,
        49,
        20,
        49,
        23,
        9,
        23,
        37,
        44,
        0,
        20,
        23,
        24,
        24,
        30,
        24,
        21,
        39,
        23,
        24,
        32,
        0,
        4,
        0,
        12,
        4,
        43,
        12,
        19,
        46,
        0,
        0,
        25,
        49,
        1,
        14,
        0,
        30,
        27,
        1
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        32,
        31,
        49,
        15,
        11,
        8,
        1,
        11,
        48,
        16,
        48,
        28,
        47,
        49,
        25,
        38,
        30,
        12,
        0,
        24,
        32,
        33,
        43,
        29,
        35,
        34,
        25,
        22,
        5,
        49,
        39,
        22,
        43,
        15,
        48,
        35,
        15,
        7,
        16,
        49,
        2,
        29,
        49,
        38,
        43,
        1,
        10,
        3,
        10,
        11,
        20,
        0
      ],
      "weeklyRejected": [
        3,
        6,
        49,
        47,
        14,
        49,
        49,
        31,
        49,
        26,
        49,
        41,
        28,
        22,
        48,
        40,
        0,
        42,
        29,
        32,
        30,
        0,
        49,
        1,
        31,
        20,
        21,
        46,
        21,
        21,
        30,
        38,
        21,
        7,
        47,
        49,
        12,
        49,
        18,
        8,
        25,
        44,
        20,
        2,
        30,
        49,
        0,
        37,
        0,
        21,
        24,
        8
      ],
      "weeklyHold": [
        27,
        42,
        41,
        34,
        13,
        6,
        35,
        7,
        4,
        23,
        37,
        42,
        48,
        1,
        49,
        13,
        28,
        0,
        4,
        31,
        7,
        44,
        49,
        0,
        42,
        12,
        24,
        14,
        17,
        49,
        30,
        0,
        0,
        16,
        17,
        13,
        19,
        0,
        24,
        25,
        41,
        49,
        49,
        49,
        30,
        43,
        12,
        39,
        0,
        29,
        12,
        8
      ],
      "weeklyBackordered": [
        40,
        39,
        0,
        6,
        49,
        40,
        49,
        30,
        49,
        3,
        46,
        22,
        5,
        3,
        20,
        5,
        22,
        49,
        30,
        16,
        9,
        0,
        31,
        49,
        24,
        23,
        49,
        28,
        42,
        49,
        23,
        5,
        32,
        36,
        14,
        15,
        1,
        4,
        49,
        40,
        48,
        30,
        6,
        43,
        27,
        26,
        49,
        49,
        0,
        6,
        46,
        1
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        31,
        34,
        32,
        37,
        22,
        18,
        7,
        33,
        45,
        8,
        46,
        0,
        39,
        48,
        49,
        43,
        8,
        11,
        0,
        16,
        44,
        0,
        49,
        28,
        31,
        34,
        14,
        41,
        21,
        4,
        49,
        39,
        24,
        17,
        31,
        45,
        47,
        0,
        23,
        34,
        28,
        0,
        47,
        49,
        47,
        37,
        26,
        30,
        44,
        29,
        12,
        48
      ],
      "weeklyRejected": [
        14,
        30,
        27,
        35,
        40,
        14,
        19,
        48,
        49,
        48,
        44,
        27,
        21,
        33,
        44,
        29,
        2,
        24,
        46,
        16,
        42,
        0,
        24,
        39,
        0,
        49,
        16,
        32,
        44,
        10,
        10,
        21,
        48,
        24,
        42,
        49,
        34,
        35,
        1,
        49,
        39,
        0,
        24,
        0,
        40,
        36,
        46,
        21,
        4,
        28,
        0,
        29
      ],
      "weeklyHold": [
        46,
        49,
        0,
        1,
        39,
        23,
        37,
        24,
        32,
        32,
        12,
        41,
        47,
        16,
        4,
        4,
        13,
        15,
        18,
        49,
        0,
        27,
        25,
        31,
        27,
        20,
        0,
        30,
        49,
        0,
        7,
        0,
        14,
        7,
        28,
        31,
        32,
        28,
        36,
        12,
        1,
        41,
        0,
        41,
        49,
        49,
        42,
        25,
        21,
        27,
        38,
        23
      ],
      "weeklyBackordered": [
        48,
        18,
        13,
        0,
        49,
        35,
        22,
        19,
        49,
        0,
        23,
        43,
        44,
        16,
        23,
        1,
        49,
        43,
        0,
        49,
        44,
        30,
        38,
        22,
        11,
        28,
        1,
        49,
        13,
        49,
        34,
        49,
        18,
        49,
        0,
        18,
        46,
        0,
        12,
        41,
        17,
        48,
        49,
        49,
        49,
        31,
        35,
        36,
        14,
        33,
        6,
        41
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyAccepted": [
        27,
        38,
        43,
        43,
        10,
        15,
        27,
        13,
        16,
        0,
        44,
        25,
        34,
        34,
        1,
        0,
        41,
        7,
        3,
        32,
        39,
        49,
        28,
        49,
        27,
        28,
        28,
        15,
        23,
        49,
        47,
        49,
        37,
        0,
        48,
        46,
        33,
        2,
        9,
        49,
        0,
        8,
        42,
        24,
        20,
        30,
        0,
        6,
        24,
        22,
        29,
        23
      ],
      "weeklyRejected": [
        39,
        2,
        49,
        45,
        14,
        49,
        31,
        44,
        49,
        10,
        37,
        30,
        31,
        31,
        48,
        0,
        46,
        33,
        0,
        45,
        16,
        27,
        21,
        49,
        28,
        21,
        35,
        31,
        46,
        0,
        30,
        27,
        48,
        49,
        36,
        26,
        35,
        16,
        12,
        0,
        0,
        47,
        44,
        4,
        49,
        5,
        35,
        49,
        33,
        32,
        0,
        43
      ],
      "weeklyHold": [
        49,
        41,
        42,
        15,
        21,
        44,
        22,
        9,
        11,
        49,
        47,
        38,
        42,
        9,
        41,
        0,
        24,
        48,
        38,
        0,
        0,
        0,
        13,
        0,
        2,
        48,
        22,
        12,
        46,
        25,
        19,
        6,
        28,
        46,
        48,
        13,
        47,
        2,
        28,
        7,
        31,
        40,
        12,
        27,
        27,
        10,
        0,
        46,
        10,
        18,
        44,
        42
      ],
      "weeklyBackordered": [
        25,
        49,
        0,
        11,
        30,
        22,
        17,
        12,
        42,
        37,
        36,
        31,
        26,
        35,
        0,
        29,
        19,
        49,
        0,
        33,
        6,
        49,
        49,
        49,
        29,
        31,
        22,
        24,
        42,
        41,
        39,
        49,
        48,
        9,
        17,
        0,
        20,
        9,
        16,
        37,
        14,
        48,
        9,
        47,
        0,
        5,
        5,
        23,
        17,
        23,
        25,
        9
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        0,
        1,
        22,
        19,
        42,
        12,
        14,
        13,
        6,
        11,
        49,
        2,
        13,
        6,
        12,
        49,
        49,
        11,
        9,
        25,
        0,
        36,
        0,
        49,
        15,
        38,
        37,
        0,
        9,
        37,
        1,
        2,
        28,
        28,
        48,
        49,
        9,
        8,
        9,
        44,
        3,
        40,
        49,
        5,
        25,
        13,
        0,
        38,
        0,
        42,
        32,
        46
      ],
      "weeklyRejected": [
        36,
        14,
        17,
        47,
        9,
        9,
        48,
        49,
        49,
        48,
        0,
        29,
        49,
        27,
        36,
        11,
        1,
        47,
        30,
        0,
        19,
        49,
        49,
        49,
        1,
        20,
        38,
        49,
        31,
        35,
        48,
        33,
        8,
        6,
        23,
        49,
        32,
        17,
        1,
        32,
        26,
        49,
        1,
        42,
        41,
        41,
        0,
        13,
        31,
        24,
        0,
        21
      ],
      "weeklyHold": [
        48,
        38,
        39,
        19,
        49,
        36,
        49,
        42,
        49,
        49,
        47,
        34,
        48,
        1,
        46,
        16,
        0,
        48,
        40,
        0,
        26,
        41,
        5,
        14,
        18,
        23,
        33,
        32,
        37,
        49,
        39,
        17,
        0,
        49,
        8,
        27,
        22,
        0,
        49,
        38,
        12,
        35,
        40,
        27,
        31,
        35,
        30,
        34,
        0,
        49,
        40,
        49
      ],
      "weeklyBackordered": [
        48,
        43,
        34,
        43,
        30,
        24,
        41,
        14,
        44,
        49,
        49,
        49,
        13,
        33,
        0,
        14,
        49,
        24,
        34,
        30,
        23,
        16,
        26,
        21,
        35,
        49,
        19,
        9,
        45,
        33,
        0,
        49,
        36,
        47,
        8,
        16,
        49,
        5,
        3,
        37,
        48,
        48,
        0,
        40,
        13,
        34,
        47,
        7,
        28,
        43,
        49,
        42
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        49,
        1,
        34,
        24,
        35,
        43,
        8,
        47,
        39,
        28,
        33,
        23,
        31,
        17,
        1,
        29,
        40,
        0,
        25,
        28,
        42,
        15,
        29,
        49,
        9,
        5,
        13,
        7,
        1,
        32,
        38,
        4,
        38,
        25,
        29,
        43,
        41,
        11,
        46,
        13,
        28,
        49,
        31,
        0,
        49,
        49,
        0,
        49,
        0,
        42,
        18,
        48
      ],
      "weeklyRejected": [
        19,
        21,
        29,
        38,
        37,
        0,
        42,
        38,
        49,
        3,
        32,
        0,
        49,
        17,
        43,
        5,
        30,
        48,
        42,
        49,
        6,
        44,
        47,
        49,
        0,
        15,
        1,
        49,
        36,
        30,
        45,
        31,
        0,
        4,
        22,
        49,
        18,
        39,
        40,
        4,
        48,
        49,
        11,
        0,
        49,
        35,
        0,
        34,
        9,
        49,
        0,
        18
      ],
      "weeklyHold": [
        4,
        49,
        49,
        48,
        41,
        44,
        46,
        13,
        23,
        49,
        37,
        49,
        29,
        10,
        16,
        3,
        35,
        23,
        18,
        25,
        0,
        14,
        11,
        1,
        45,
        48,
        47,
        2,
        49,
        0,
        34,
        20,
        24,
        39,
        25,
        40,
        0,
        0,
        49,
        20,
        49,
        22,
        48,
        0,
        30,
        36,
        0,
        44,
        3,
        44,
        40,
        49
      ],
      "weeklyBackordered": [
        49,
        32,
        2,
        27,
        17,
        44,
        35,
        47,
        42,
        41,
        37,
        0,
        3,
        49,
        0,
        2,
        30,
        0,
        0,
        38,
        0,
        30,
        37,
        49,
        3,
        1,
        6,
        48,
        7,
        32,
        23,
        49,
        34,
        35,
        0,
        28,
        9,
        25,
        0,
        28,
        33,
        25,
        44,
        49,
        16,
        21,
        16,
        49,
        0,
        49,
        49,
        16
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        3,
        44,
        21,
        48,
        19,
        0,
        17,
        49,
        45,
        49,
        36,
        5,
        5,
        46,
        44,
        49,
        22,
        9,
        30,
        29,
        0,
        25,
        28,
        49,
        0,
        38,
        15,
        14,
        16,
        49,
        0,
        44,
        49,
        21,
        48,
        34,
        12,
        14,
        2,
        49,
        0,
        41,
        26,
        0,
        36,
        22,
        42,
        13,
        17,
        33,
        19,
        37
      ],
      "weeklyRejected": [
        17,
        11,
        41,
        47,
        0,
        14,
        49,
        48,
        25,
        1,
        49,
        17,
        22,
        40,
        15,
        0,
        49,
        49,
        48,
        44,
        15,
        39,
        43,
        43,
        19,
        23,
        49,
        48,
        48,
        0,
        38,
        0,
        16,
        13,
        47,
        12,
        0,
        20,
        1,
        31,
        14,
        43,
        28,
        12,
        42,
        43,
        7,
        2,
        21,
        9,
        0,
        44
      ],
      "weeklyHold": [
        32,
        35,
        49,
        3,
        15,
        32,
        49,
        24,
        0,
        3,
        39,
        2,
        48,
        1,
        47,
        36,
        15,
        20,
        0,
        10,
        49,
        33,
        32,
        0,
        49,
        47,
        42,
        16,
        49,
        0,
        48,
        0,
        0,
        0,
        7,
        25,
        20,
        0,
        30,
        15,
        0,
        40,
        1,
        19,
        30,
        49,
        24,
        30,
        0,
        26,
        48,
        49
      ],
      "weeklyBackordered": [
        21,
        39,
        18,
        24,
        47,
        46,
        12,
        43,
        42,
        14,
        44,
        12,
        3,
        34,
        33,
        10,
        25,
        49,
        0,
        49,
        49,
        39,
        45,
        47,
        33,
        31,
        25,
        19,
        36,
        48,
        3,
        38,
        48,
        49,
        0,
        0,
        6,
        0,
        37,
        42,
        36,
        48,
        16,
        49,
        14,
        0,
        36,
        44,
        0,
        49,
        41,
        11
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        29,
        39,
        17,
        0,
        42,
        2,
        14,
        20,
        10,
        25,
        12,
        15,
        49,
        20,
        1,
        12,
        49,
        6,
        37,
        10,
        2,
        0,
        22,
        40,
        23,
        23,
        23,
        49,
        35,
        49,
        49,
        0,
        49,
        43,
        27,
        43,
        43,
        0,
        49,
        16,
        11,
        46,
        46,
        49,
        49,
        0,
        11,
        0,
        4,
        17,
        14,
        23
      ],
      "weeklyRejected": [
        42,
        21,
        8,
        1,
        34,
        0,
        27,
        16,
        33,
        5,
        20,
        30,
        15,
        48,
        30,
        0,
        0,
        34,
        1,
        47,
        21,
        49,
        32,
        49,
        30,
        49,
        40,
        49,
        23,
        48,
        32,
        30,
        42,
        49,
        15,
        41,
        42,
        49,
        1,
        3,
        43,
        22,
        37,
        35,
        38,
        0,
        11,
        31,
        11,
        40,
        0,
        49
      ],
      "weeklyHold": [
        9,
        8,
        39,
        6,
        17,
        14,
        21,
        40,
        36,
        29,
        20,
        37,
        24,
        26,
        37,
        0,
        0,
        40,
        46,
        14,
        16,
        45,
        15,
        11,
        14,
        39,
        0,
        0,
        38,
        6,
        48,
        0,
        17,
        42,
        9,
        0,
        0,
        49,
        8,
        22,
        32,
        27,
        19,
        35,
        37,
        7,
        17,
        18,
        17,
        39,
        41,
        47
      ],
      "weeklyBackordered": [
        49,
        26,
        10,
        36,
        37,
        26,
        45,
        36,
        5,
        30,
        22,
        13,
        13,
        49,
        1,
        49,
        11,
        19,
        0,
        0,
        10,
        27,
        5,
        26,
        48,
        24,
        1,
        39,
        38,
        49,
        6,
        28,
        48,
        17,
        20,
        0,
        4,
        0,
        0,
        24,
        48,
        48,
        13,
        12,
        31,
        18,
        21,
        0,
        16,
        23,
        21,
        1
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        0,
        33,
        30,
        48,
        21,
        0,
        38,
        48,
        48,
        37,
        49,
        0,
        19,
        24,
        8,
        27,
        40,
        30,
        20,
        49,
        6,
        35,
        12,
        15,
        25,
        29,
        11,
        26,
        15,
        30,
        49,
        30,
        22,
        17,
        48,
        24,
        44,
        4,
        49,
        49,
        37,
        20,
        37,
        49,
        38,
        49,
        0,
        8,
        30,
        42,
        15,
        21
      ],
      "weeklyRejected": [
        18,
        4,
        48,
        2,
        49,
        10,
        35,
        46,
        49,
        34,
        19,
        19,
        10,
        19,
        20,
        31,
        23,
        29,
        40,
        18,
        21,
        49,
        33,
        14,
        24,
        31,
        30,
        11,
        48,
        0,
        10,
        47,
        48,
        45,
        49,
        4,
        15,
        20,
        24,
        9,
        0,
        36,
        40,
        22,
        45,
        49,
        11,
        49,
        0,
        37,
        4,
        39
      ],
      "weeklyHold": [
        29,
        17,
        44,
        1,
        22,
        32,
        0,
        2,
        3,
        49,
        47,
        28,
        29,
        38,
        22,
        38,
        18,
        13,
        44,
        30,
        34,
        17,
        1,
        40,
        0,
        48,
        17,
        40,
        49,
        5,
        13,
        0,
        21,
        49,
        10,
        49,
        20,
        13,
        28,
        34,
        30,
        49,
        0,
        40,
        11,
        25,
        3,
        24,
        1,
        3,
        7,
        15
      ],
      "weeklyBackordered": [
        25,
        49,
        23,
        0,
        33,
        27,
        49,
        27,
        27,
        43,
        49,
        20,
        26,
        38,
        30,
        34,
        27,
        49,
        5,
        38,
        49,
        28,
        24,
        9,
        17,
        36,
        23,
        0,
        30,
        33,
        36,
        33,
        31,
        32,
        0,
        0,
        49,
        17,
        49,
        22,
        20,
        48,
        0,
        49,
        34,
        36,
        41,
        15,
        18,
        32,
        25,
        20
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        36,
        11,
        33,
        26,
        24,
        0,
        39,
        36,
        48,
        25,
        49,
        21,
        31,
        49,
        14,
        0,
        46,
        29,
        23,
        49,
        27,
        29,
        23,
        0,
        0,
        41,
        22,
        30,
        4,
        36,
        49,
        5,
        18,
        0,
        19,
        2,
        47,
        28,
        2,
        24,
        9,
        14,
        41,
        28,
        27,
        42,
        1,
        27,
        23,
        49,
        10,
        29
      ],
      "weeklyRejected": [
        46,
        5,
        27,
        23,
        48,
        13,
        48,
        30,
        32,
        34,
        36,
        37,
        6,
        40,
        24,
        49,
        0,
        45,
        16,
        18,
        47,
        0,
        9,
        24,
        32,
        49,
        49,
        49,
        48,
        15,
        45,
        14,
        48,
        0,
        48,
        49,
        34,
        0,
        12,
        35,
        48,
        34,
        21,
        14,
        45,
        48,
        27,
        49,
        20,
        42,
        9,
        25
      ],
      "weeklyHold": [
        49,
        12,
        32,
        33,
        0,
        41,
        33,
        28,
        26,
        26,
        47,
        33,
        44,
        49,
        35,
        0,
        16,
        48,
        30,
        12,
        0,
        32,
        25,
        18,
        49,
        36,
        49,
        3,
        13,
        0,
        48,
        48,
        16,
        41,
        24,
        13,
        49,
        5,
        34,
        49,
        0,
        38,
        49,
        0,
        17,
        47,
        40,
        49,
        36,
        38,
        37,
        20
      ],
      "weeklyBackordered": [
        44,
        47,
        19,
        30,
        49,
        28,
        33,
        32,
        12,
        0,
        0,
        0,
        5,
        31,
        0,
        23,
        49,
        0,
        15,
        17,
        24,
        0,
        46,
        6,
        19,
        3,
        24,
        47,
        46,
        34,
        34,
        42,
        38,
        20,
        28,
        0,
        28,
        14,
        25,
        49,
        42,
        25,
        48,
        49,
        37,
        31,
        49,
        20,
        48,
        49,
        49,
        30
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        11,
        15,
        12,
        15,
        12,
        0,
        1,
        45,
        30,
        24,
        22,
        0,
        11,
        26,
        1,
        0,
        25,
        18,
        15,
        7,
        43,
        0,
        23,
        10,
        6,
        39,
        17,
        48,
        33,
        27,
        6,
        2,
        43,
        0,
        30,
        48,
        47,
        26,
        7,
        31,
        13,
        39,
        49,
        28,
        49,
        30,
        0,
        17,
        39,
        37,
        17,
        14
      ],
      "weeklyRejected": [
        34,
        35,
        49,
        41,
        11,
        19,
        12,
        49,
        49,
        26,
        28,
        49,
        24,
        26,
        37,
        24,
        0,
        49,
        32,
        23,
        49,
        20,
        10,
        21,
        37,
        31,
        4,
        38,
        48,
        12,
        18,
        33,
        10,
        49,
        36,
        49,
        22,
        43,
        14,
        47,
        0,
        37,
        45,
        0,
        38,
        33,
        2,
        29,
        16,
        5,
        7,
        41
      ],
      "weeklyHold": [
        5,
        33,
        49,
        30,
        35,
        22,
        41,
        23,
        45,
        24,
        20,
        43,
        48,
        1,
        40,
        0,
        0,
        18,
        40,
        22,
        0,
        28,
        7,
        0,
        36,
        36,
        13,
        19,
        49,
        0,
        48,
        13,
        0,
        2,
        48,
        2,
        48,
        0,
        37,
        15,
        16,
        29,
        37,
        48,
        44,
        49,
        1,
        48,
        9,
        49,
        49,
        49
      ],
      "weeklyBackordered": [
        49,
        14,
        41,
        45,
        40,
        43,
        49,
        49,
        38,
        49,
        21,
        15,
        25,
        17,
        0,
        21,
        22,
        32,
        0,
        25,
        42,
        3,
        33,
        42,
        10,
        12,
        7,
        3,
        34,
        36,
        33,
        16,
        0,
        7,
        0,
        0,
        41,
        1,
        0,
        16,
        0,
        48,
        14,
        49,
        4,
        49,
        49,
        17,
        0,
        24,
        49,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        24,
        46,
        4,
        24,
        40,
        12,
        8,
        49,
        9,
        11,
        47,
        0,
        15,
        9,
        17,
        0,
        37,
        32,
        0,
        49,
        5,
        49,
        49,
        28,
        10,
        25,
        7,
        0,
        1,
        45,
        12,
        44,
        33,
        6,
        40,
        20,
        35,
        11,
        18,
        49,
        20,
        47,
        35,
        42,
        49,
        0,
        23,
        46,
        5,
        16,
        0,
        47
      ],
      "weeklyRejected": [
        45,
        16,
        31,
        25,
        18,
        9,
        16,
        21,
        11,
        22,
        37,
        46,
        25,
        37,
        48,
        5,
        15,
        9,
        49,
        9,
        25,
        7,
        49,
        12,
        36,
        49,
        3,
        45,
        14,
        14,
        48,
        27,
        47,
        49,
        32,
        31,
        36,
        31,
        1,
        0,
        46,
        30,
        31,
        0,
        45,
        39,
        0,
        49,
        23,
        34,
        0,
        45
      ],
      "weeklyHold": [
        49,
        36,
        49,
        17,
        0,
        28,
        2,
        33,
        33,
        0,
        47,
        30,
        18,
        12,
        45,
        27,
        23,
        19,
        4,
        45,
        15,
        18,
        18,
        14,
        8,
        31,
        31,
        17,
        38,
        21,
        48,
        25,
        0,
        26,
        30,
        35,
        34,
        0,
        37,
        7,
        49,
        17,
        45,
        42,
        41,
        11,
        13,
        24,
        28,
        12,
        49,
        28
      ],
      "weeklyBackordered": [
        17,
        23,
        18,
        49,
        46,
        10,
        0,
        49,
        22,
        31,
        15,
        49,
        17,
        49,
        15,
        31,
        27,
        29,
        49,
        0,
        46,
        0,
        12,
        27,
        17,
        25,
        33,
        49,
        19,
        49,
        37,
        49,
        6,
        49,
        0,
        0,
        14,
        0,
        44,
        9,
        43,
        48,
        28,
        49,
        0,
        34,
        2,
        23,
        0,
        9,
        15,
        30
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        11,
        41,
        4,
        48,
        39,
        44,
        1,
        43,
        48,
        49,
        49,
        35,
        0,
        49,
        46,
        38,
        39,
        6,
        5,
        27,
        30,
        0,
        7,
        6,
        22,
        10,
        25,
        4,
        1,
        18,
        0,
        25,
        32,
        32,
        48,
        49,
        47,
        0,
        49,
        29,
        11,
        27,
        19,
        4,
        47,
        26,
        6,
        29,
        32,
        49,
        26,
        12
      ],
      "weeklyRejected": [
        15,
        18,
        31,
        47,
        24,
        34,
        47,
        49,
        4,
        15,
        41,
        32,
        36,
        21,
        48,
        28,
        47,
        23,
        46,
        49,
        49,
        10,
        4,
        49,
        35,
        34,
        4,
        17,
        34,
        14,
        45,
        46,
        40,
        21,
        15,
        35,
        4,
        49,
        22,
        45,
        20,
        33,
        49,
        21,
        48,
        48,
        18,
        32,
        4,
        21,
        13,
        5
      ],
      "weeklyHold": [
        21,
        17,
        44,
        1,
        36,
        37,
        13,
        41,
        24,
        31,
        17,
        17,
        48,
        1,
        9,
        0,
        14,
        24,
        33,
        0,
        22,
        13,
        16,
        19,
        2,
        33,
        26,
        8,
        49,
        6,
        34,
        6,
        0,
        0,
        48,
        30,
        21,
        38,
        20,
        29,
        49,
        21,
        28,
        41,
        40,
        49,
        28,
        33,
        24,
        8,
        19,
        26
      ],
      "weeklyBackordered": [
        29,
        24,
        0,
        24,
        37,
        46,
        48,
        26,
        49,
        0,
        49,
        0,
        22,
        49,
        36,
        49,
        1,
        36,
        0,
        32,
        49,
        0,
        22,
        29,
        12,
        35,
        30,
        26,
        22,
        49,
        43,
        49,
        9,
        25,
        0,
        22,
        20,
        2,
        34,
        27,
        1,
        48,
        43,
        49,
        32,
        20,
        27,
        2,
        0,
        13,
        14,
        11
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        0,
        44,
        27,
        27,
        49,
        47,
        10,
        49,
        30,
        14,
        49,
        0,
        45,
        49,
        34,
        38,
        0,
        21,
        12,
        10,
        14,
        38,
        0,
        32,
        12,
        47,
        18,
        0,
        1,
        49,
        12,
        33,
        2,
        38,
        48,
        12,
        0,
        48,
        14,
        22,
        22,
        0,
        49,
        44,
        49,
        5,
        24,
        32,
        17,
        35,
        30,
        9
      ],
      "weeklyRejected": [
        13,
        3,
        49,
        37,
        49,
        9,
        13,
        6,
        30,
        38,
        37,
        19,
        40,
        48,
        45,
        0,
        17,
        49,
        18,
        19,
        17,
        19,
        0,
        13,
        13,
        49,
        49,
        23,
        43,
        10,
        36,
        35,
        48,
        49,
        29,
        49,
        5,
        22,
        8,
        0,
        48,
        43,
        31,
        3,
        49,
        15,
        0,
        0,
        14,
        49,
        7,
        0
      ],
      "weeklyHold": [
        5,
        19,
        34,
        25,
        10,
        36,
        21,
        42,
        2,
        18,
        37,
        38,
        39,
        49,
        44,
        33,
        9,
        30,
        0,
        6,
        48,
        49,
        4,
        18,
        29,
        18,
        14,
        37,
        15,
        5,
        46,
        0,
        0,
        26,
        11,
        45,
        33,
        9,
        40,
        45,
        7,
        28,
        10,
        0,
        13,
        34,
        41,
        14,
        2,
        35,
        0,
        22
      ],
      "weeklyBackordered": [
        35,
        7,
        25,
        15,
        35,
        34,
        35,
        49,
        31,
        6,
        10,
        49,
        15,
        38,
        31,
        24,
        1,
        22,
        0,
        32,
        14,
        49,
        45,
        42,
        24,
        17,
        47,
        49,
        11,
        45,
        0,
        26,
        45,
        21,
        23,
        0,
        33,
        4,
        49,
        9,
        44,
        48,
        21,
        46,
        24,
        14,
        49,
        27,
        29,
        49,
        49,
        12
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        31,
        49,
        48,
        48,
        36,
        24,
        1,
        24,
        6,
        8,
        38,
        15,
        26,
        1,
        49,
        42,
        36,
        0,
        18,
        34,
        7,
        49,
        31,
        49,
        23,
        41,
        15,
        19,
        1,
        12,
        23,
        0,
        9,
        0,
        48,
        34,
        47,
        0,
        30,
        49,
        49,
        20,
        17,
        25,
        49,
        32,
        0,
        46,
        25,
        42,
        14,
        41
      ],
      "weeklyRejected": [
        49,
        25,
        20,
        37,
        49,
        0,
        49,
        26,
        21,
        1,
        18,
        0,
        26,
        31,
        13,
        11,
        49,
        47,
        41,
        6,
        20,
        12,
        42,
        48,
        22,
        11,
        39,
        16,
        48,
        0,
        27,
        13,
        43,
        0,
        24,
        49,
        35,
        0,
        5,
        41,
        42,
        6,
        14,
        1,
        31,
        32,
        0,
        43,
        22,
        48,
        0,
        25
      ],
      "weeklyHold": [
        18,
        49,
        40,
        13,
        46,
        25,
        0,
        13,
        0,
        6,
        7,
        34,
        23,
        14,
        24,
        23,
        39,
        48,
        5,
        26,
        30,
        40,
        49,
        4,
        17,
        5,
        49,
        22,
        35,
        29,
        11,
        22,
        9,
        38,
        16,
        46,
        22,
        10,
        49,
        33,
        32,
        20,
        42,
        12,
        1,
        3,
        0,
        20,
        0,
        49,
        5,
        42
      ],
      "weeklyBackordered": [
        39,
        8,
        19,
        0,
        43,
        32,
        21,
        39,
        49,
        16,
        48,
        45,
        6,
        20,
        24,
        49,
        24,
        31,
        19,
        9,
        49,
        25,
        13,
        24,
        15,
        17,
        26,
        49,
        26,
        43,
        31,
        49,
        38,
        42,
        4,
        13,
        49,
        0,
        12,
        6,
        0,
        48,
        9,
        49,
        0,
        43,
        24,
        27,
        36,
        0,
        30,
        4
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        0,
        37,
        39,
        39,
        44,
        11,
        40,
        25,
        40,
        10,
        12,
        6,
        6,
        38,
        12,
        33,
        38,
        0,
        6,
        40,
        47,
        36,
        8,
        27,
        24,
        20,
        3,
        34,
        31,
        38,
        2,
        38,
        46,
        20,
        25,
        18,
        47,
        19,
        29,
        49,
        49,
        25,
        47,
        44,
        49,
        8,
        1,
        34,
        4,
        29,
        2,
        31
      ],
      "weeklyRejected": [
        34,
        39,
        49,
        9,
        2,
        42,
        0,
        36,
        49,
        32,
        1,
        6,
        49,
        43,
        23,
        49,
        28,
        45,
        49,
        49,
        2,
        22,
        13,
        0,
        27,
        5,
        11,
        14,
        47,
        21,
        31,
        17,
        35,
        29,
        7,
        32,
        21,
        11,
        27,
        49,
        38,
        23,
        5,
        9,
        38,
        36,
        17,
        49,
        17,
        38,
        39,
        23
      ],
      "weeklyHold": [
        49,
        9,
        46,
        12,
        49,
        0,
        9,
        16,
        22,
        15,
        12,
        40,
        48,
        49,
        49,
        6,
        0,
        40,
        40,
        20,
        26,
        32,
        49,
        49,
        0,
        29,
        14,
        33,
        49,
        13,
        48,
        0,
        45,
        25,
        48,
        5,
        0,
        35,
        46,
        37,
        47,
        11,
        12,
        40,
        41,
        19,
        49,
        37,
        0,
        46,
        49,
        41
      ],
      "weeklyBackordered": [
        27,
        19,
        40,
        1,
        46,
        22,
        31,
        47,
        15,
        4,
        27,
        38,
        3,
        43,
        44,
        29,
        49,
        21,
        44,
        28,
        44,
        0,
        12,
        5,
        1,
        44,
        1,
        24,
        36,
        45,
        9,
        13,
        20,
        47,
        3,
        0,
        23,
        0,
        17,
        20,
        37,
        48,
        0,
        35,
        29,
        47,
        34,
        33,
        44,
        35,
        7,
        32
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        41,
        44,
        44,
        0,
        33,
        26,
        1,
        32,
        48,
        8,
        47,
        40,
        34,
        49,
        15,
        8,
        37,
        11,
        49,
        7,
        0,
        6,
        39,
        28,
        25,
        37,
        0,
        41,
        12,
        20,
        28,
        14,
        14,
        5,
        24,
        42,
        47,
        4,
        29,
        32,
        49,
        36,
        49,
        36,
        44,
        26,
        4,
        21,
        3,
        31,
        34,
        1
      ],
      "weeklyRejected": [
        49,
        18,
        49,
        0,
        49,
        36,
        26,
        37,
        38,
        15,
        29,
        3,
        22,
        48,
        48,
        6,
        19,
        49,
        3,
        10,
        16,
        49,
        20,
        41,
        36,
        49,
        39,
        14,
        26,
        26,
        28,
        47,
        29,
        49,
        3,
        33,
        37,
        33,
        9,
        0,
        34,
        47,
        25,
        0,
        20,
        11,
        22,
        4,
        48,
        33,
        13,
        38
      ],
      "weeklyHold": [
        5,
        49,
        33,
        1,
        1,
        45,
        26,
        3,
        7,
        43,
        36,
        32,
        20,
        34,
        49,
        24,
        8,
        18,
        35,
        21,
        23,
        48,
        6,
        18,
        49,
        26,
        24,
        44,
        22,
        26,
        48,
        0,
        0,
        26,
        45,
        40,
        39,
        0,
        7,
        9,
        9,
        49,
        7,
        38,
        49,
        12,
        4,
        30,
        18,
        39,
        49,
        0
      ],
      "weeklyBackordered": [
        39,
        49,
        16,
        43,
        49,
        46,
        36,
        36,
        20,
        0,
        6,
        11,
        33,
        6,
        20,
        34,
        42,
        40,
        21,
        20,
        13,
        9,
        41,
        18,
        1,
        21,
        25,
        10,
        35,
        35,
        0,
        38,
        46,
        18,
        18,
        0,
        28,
        0,
        41,
        18,
        0,
        48,
        14,
        31,
        44,
        43,
        49,
        0,
        3,
        7,
        37,
        1
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        26,
        40,
        49,
        24,
        9,
        7,
        19,
        29,
        7,
        48,
        0,
        1,
        23,
        49,
        11,
        18,
        36,
        15,
        45,
        0,
        0,
        45,
        49,
        25,
        17,
        46,
        41,
        23,
        38,
        13,
        49,
        6,
        26,
        0,
        30,
        2,
        34,
        26,
        31,
        49,
        39,
        20,
        40,
        44,
        33,
        0,
        8,
        31,
        10,
        44,
        18,
        39
      ],
      "weeklyRejected": [
        43,
        0,
        23,
        13,
        49,
        0,
        39,
        49,
        49,
        20,
        36,
        24,
        32,
        42,
        35,
        46,
        24,
        49,
        45,
        49,
        16,
        0,
        4,
        29,
        8,
        40,
        38,
        49,
        48,
        15,
        10,
        19,
        45,
        33,
        16,
        49,
        39,
        11,
        1,
        20,
        26,
        16,
        0,
        3,
        49,
        40,
        0,
        49,
        0,
        49,
        0,
        37
      ],
      "weeklyHold": [
        42,
        9,
        22,
        32,
        22,
        39,
        24,
        26,
        8,
        49,
        12,
        40,
        18,
        10,
        0,
        30,
        0,
        38,
        0,
        0,
        0,
        38,
        0,
        42,
        17,
        48,
        35,
        0,
        49,
        3,
        48,
        16,
        0,
        49,
        15,
        26,
        39,
        29,
        49,
        28,
        5,
        45,
        38,
        39,
        36,
        40,
        21,
        45,
        0,
        49,
        39,
        49
      ],
      "weeklyBackordered": [
        20,
        12,
        28,
        28,
        32,
        41,
        11,
        49,
        9,
        24,
        31,
        29,
        11,
        49,
        0,
        25,
        21,
        49,
        8,
        3,
        18,
        0,
        1,
        40,
        18,
        30,
        49,
        19,
        25,
        16,
        31,
        19,
        30,
        0,
        17,
        0,
        22,
        19,
        2,
        25,
        28,
        41,
        26,
        28,
        40,
        12,
        25,
        12,
        12,
        36,
        49,
        49
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        0,
        49,
        21,
        33,
        44,
        0,
        20,
        49,
        24,
        49,
        37,
        25,
        18,
        29,
        36,
        14,
        47,
        33,
        36,
        0,
        34,
        38,
        36,
        18,
        45,
        28,
        11,
        17,
        33,
        1,
        22,
        21,
        41,
        5,
        12,
        39,
        32,
        14,
        4,
        49,
        0,
        41,
        25,
        49,
        49,
        0,
        14,
        31,
        47,
        10,
        0,
        0
      ],
      "weeklyRejected": [
        49,
        49,
        30,
        47,
        35,
        40,
        49,
        15,
        49,
        19,
        45,
        42,
        49,
        48,
        48,
        42,
        41,
        38,
        49,
        15,
        3,
        37,
        0,
        26,
        34,
        49,
        16,
        32,
        46,
        8,
        30,
        34,
        5,
        13,
        24,
        49,
        42,
        0,
        1,
        0,
        35,
        32,
        24,
        11,
        34,
        22,
        0,
        28,
        3,
        47,
        44,
        49
      ],
      "weeklyHold": [
        43,
        11,
        49,
        1,
        29,
        41,
        0,
        2,
        2,
        10,
        25,
        36,
        15,
        35,
        49,
        14,
        0,
        24,
        24,
        23,
        0,
        49,
        32,
        3,
        14,
        2,
        0,
        45,
        24,
        4,
        40,
        0,
        5,
        18,
        15,
        49,
        23,
        6,
        30,
        30,
        26,
        30,
        2,
        0,
        48,
        0,
        48,
        49,
        6,
        49,
        49,
        25
      ],
      "weeklyBackordered": [
        49,
        18,
        0,
        0,
        48,
        20,
        38,
        41,
        0,
        8,
        31,
        15,
        33,
        23,
        49,
        43,
        49,
        47,
        0,
        18,
        35,
        31,
        0,
        8,
        31,
        30,
        1,
        0,
        15,
        40,
        0,
        49,
        21,
        0,
        27,
        0,
        1,
        12,
        11,
        45,
        11,
        48,
        0,
        49,
        30,
        49,
        36,
        27,
        38,
        35,
        49,
        31
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        9,
        4,
        10,
        48,
        11,
        20,
        36,
        30,
        23,
        40,
        36,
        0,
        25,
        24,
        24,
        12,
        21,
        26,
        3,
        49,
        0,
        42,
        39,
        49,
        22,
        47,
        47,
        32,
        12,
        30,
        49,
        0,
        45,
        0,
        43,
        30,
        34,
        12,
        49,
        49,
        9,
        31,
        0,
        10,
        49,
        47,
        0,
        0,
        12,
        44,
        4,
        0
      ],
      "weeklyRejected": [
        49,
        20,
        33,
        5,
        26,
        1,
        49,
        42,
        1,
        34,
        6,
        22,
        23,
        27,
        35,
        31,
        2,
        49,
        3,
        31,
        44,
        0,
        11,
        15,
        26,
        49,
        45,
        29,
        25,
        0,
        40,
        26,
        31,
        45,
        32,
        49,
        13,
        0,
        3,
        22,
        28,
        49,
        49,
        44,
        0,
        0,
        0,
        34,
        0,
        30,
        4,
        38
      ],
      "weeklyHold": [
        21,
        16,
        21,
        27,
        42,
        25,
        13,
        2,
        14,
        0,
        27,
        36,
        16,
        2,
        13,
        46,
        23,
        14,
        0,
        6,
        8,
        49,
        32,
        26,
        29,
        48,
        25,
        12,
        4,
        31,
        24,
        25,
        26,
        42,
        15,
        23,
        17,
        0,
        47,
        40,
        3,
        12,
        20,
        24,
        49,
        26,
        32,
        40,
        9,
        0,
        36,
        27
      ],
      "weeklyBackordered": [
        37,
        49,
        0,
        49,
        24,
        40,
        49,
        47,
        9,
        11,
        13,
        38,
        15,
        49,
        38,
        5,
        47,
        49,
        0,
        4,
        39,
        40,
        31,
        7,
        10,
        49,
        40,
        24,
        26,
        49,
        49,
        16,
        3,
        40,
        0,
        19,
        23,
        3,
        19,
        49,
        10,
        46,
        20,
        23,
        18,
        0,
        29,
        49,
        7,
        49,
        45,
        30
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        49,
        29,
        49,
        22,
        49,
        21,
        40,
        27,
        48,
        35,
        45,
        28,
        18,
        49,
        1,
        15,
        49,
        0,
        16,
        49,
        5,
        31,
        0,
        20,
        18,
        33,
        0,
        17,
        1,
        49,
        0,
        20,
        29,
        9,
        32,
        19,
        38,
        22,
        24,
        49,
        16,
        28,
        49,
        0,
        26,
        0,
        0,
        8,
        5,
        47,
        0,
        29
      ],
      "weeklyRejected": [
        49,
        15,
        49,
        0,
        47,
        2,
        0,
        49,
        33,
        10,
        46,
        2,
        25,
        12,
        48,
        13,
        30,
        40,
        14,
        35,
        6,
        38,
        49,
        27,
        39,
        41,
        49,
        11,
        44,
        3,
        0,
        22,
        48,
        23,
        33,
        49,
        45,
        31,
        1,
        15,
        48,
        0,
        13,
        9,
        38,
        43,
        7,
        1,
        0,
        28,
        0,
        42
      ],
      "weeklyHold": [
        10,
        7,
        35,
        20,
        29,
        39,
        20,
        25,
        28,
        2,
        47,
        39,
        1,
        32,
        19,
        4,
        2,
        45,
        19,
        6,
        23,
        5,
        22,
        18,
        49,
        48,
        20,
        0,
        49,
        12,
        48,
        11,
        38,
        34,
        47,
        18,
        2,
        0,
        21,
        28,
        0,
        34,
        27,
        49,
        49,
        28,
        0,
        28,
        0,
        35,
        23,
        16
      ],
      "weeklyBackordered": [
        26,
        11,
        14,
        34,
        49,
        38,
        31,
        49,
        28,
        0,
        23,
        0,
        5,
        49,
        26,
        8,
        49,
        11,
        0,
        0,
        16,
        10,
        17,
        33,
        30,
        1,
        49,
        7,
        1,
        35,
        46,
        12,
        47,
        39,
        0,
        4,
        17,
        13,
        38,
        11,
        3,
        48,
        16,
        49,
        18,
        0,
        39,
        19,
        10,
        49,
        1,
        19
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        6,
        41,
        25,
        0,
        22,
        0,
        11,
        45,
        48,
        32,
        48,
        0,
        14,
        2,
        49,
        44,
        49,
        0,
        0,
        23,
        14,
        35,
        27,
        0,
        0,
        47,
        49,
        0,
        18,
        6,
        10,
        0,
        49,
        0,
        13,
        43,
        30,
        48,
        34,
        49,
        23,
        49,
        6,
        31,
        40,
        0,
        9,
        37,
        25,
        3,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        31,
        7,
        49,
        34,
        35,
        49,
        3,
        1,
        40,
        49,
        0,
        48,
        37,
        11,
        0,
        49,
        45,
        0,
        49,
        21,
        49,
        49,
        28,
        49,
        32,
        12,
        33,
        0,
        49,
        19,
        9,
        12,
        0,
        47,
        49,
        18,
        5,
        49,
        19,
        0,
        33,
        0,
        49,
        21,
        10,
        2,
        17,
        49,
        0,
        37
      ],
      "weeklyHold": [
        46,
        22,
        11,
        1,
        0,
        33,
        6,
        23,
        30,
        9,
        47,
        1,
        48,
        1,
        43,
        49,
        0,
        48,
        18,
        34,
        16,
        1,
        43,
        19,
        46,
        16,
        0,
        0,
        49,
        8,
        48,
        20,
        0,
        23,
        45,
        19,
        16,
        44,
        38,
        26,
        0,
        0,
        26,
        39,
        0,
        25,
        0,
        49,
        8,
        33,
        0,
        44
      ],
      "weeklyBackordered": [
        0,
        29,
        35,
        39,
        39,
        28,
        30,
        1,
        19,
        0,
        49,
        29,
        3,
        20,
        35,
        42,
        49,
        29,
        0,
        0,
        34,
        25,
        4,
        47,
        10,
        30,
        12,
        5,
        25,
        49,
        24,
        49,
        48,
        45,
        12,
        0,
        49,
        0,
        17,
        4,
        25,
        48,
        15,
        49,
        0,
        43,
        49,
        6,
        49,
        42,
        13,
        38
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        0,
        31,
        30,
        12,
        10,
        0,
        15,
        5,
        39,
        14,
        43,
        22,
        36,
        11,
        3,
        30,
        49,
        20,
        9,
        37,
        1,
        49,
        33,
        49,
        29,
        47,
        49,
        16,
        5,
        32,
        9,
        28,
        38,
        33,
        19,
        42,
        26,
        0,
        17,
        39,
        18,
        19,
        30,
        26,
        21,
        0,
        21,
        0,
        7,
        17,
        24,
        48
      ],
      "weeklyRejected": [
        33,
        32,
        18,
        47,
        43,
        32,
        39,
        0,
        48,
        1,
        24,
        0,
        40,
        41,
        24,
        36,
        49,
        32,
        32,
        37,
        0,
        16,
        0,
        48,
        40,
        49,
        28,
        36,
        28,
        24,
        49,
        38,
        48,
        49,
        13,
        29,
        47,
        2,
        1,
        30,
        47,
        49,
        0,
        33,
        25,
        15,
        12,
        29,
        48,
        18,
        0,
        43
      ],
      "weeklyHold": [
        49,
        0,
        34,
        1,
        36,
        12,
        48,
        7,
        8,
        28,
        36,
        28,
        1,
        11,
        49,
        0,
        25,
        47,
        23,
        0,
        0,
        49,
        15,
        49,
        49,
        30,
        17,
        49,
        27,
        9,
        48,
        40,
        20,
        40,
        39,
        30,
        31,
        0,
        49,
        24,
        22,
        49,
        0,
        0,
        29,
        49,
        28,
        47,
        0,
        14,
        38,
        11
      ],
      "weeklyBackordered": [
        0,
        7,
        0,
        30,
        0,
        46,
        49,
        44,
        0,
        0,
        37,
        13,
        9,
        4,
        49,
        23,
        1,
        48,
        0,
        21,
        12,
        15,
        31,
        49,
        31,
        11,
        1,
        39,
        30,
        27,
        10,
        44,
        1,
        37,
        0,
        15,
        1,
        10,
        18,
        49,
        39,
        32,
        1,
        49,
        0,
        32,
        46,
        49,
        0,
        35,
        21,
        46
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        34,
        42,
        32,
        26,
        2,
        0,
        29,
        3,
        25,
        49,
        49,
        12,
        9,
        49,
        15,
        2,
        49,
        11,
        0,
        49,
        0,
        23,
        35,
        6,
        21,
        17,
        33,
        23,
        24,
        8,
        0,
        30,
        49,
        40,
        40,
        2,
        26,
        15,
        28,
        49,
        43,
        49,
        49,
        49,
        48,
        28,
        25,
        26,
        0,
        21,
        8,
        44
      ],
      "weeklyRejected": [
        36,
        12,
        7,
        5,
        49,
        32,
        49,
        26,
        49,
        6,
        38,
        49,
        23,
        27,
        48,
        0,
        0,
        41,
        49,
        18,
        18,
        49,
        35,
        26,
        8,
        32,
        49,
        49,
        20,
        19,
        49,
        42,
        32,
        49,
        0,
        49,
        17,
        40,
        14,
        49,
        46,
        4,
        27,
        18,
        49,
        2,
        0,
        0,
        0,
        46,
        0,
        15
      ],
      "weeklyHold": [
        19,
        0,
        14,
        1,
        49,
        14,
        48,
        28,
        18,
        14,
        13,
        26,
        25,
        9,
        49,
        29,
        1,
        14,
        20,
        7,
        20,
        10,
        11,
        49,
        43,
        45,
        14,
        14,
        40,
        15,
        48,
        47,
        0,
        11,
        13,
        44,
        34,
        16,
        14,
        14,
        49,
        29,
        23,
        23,
        15,
        49,
        25,
        28,
        8,
        13,
        0,
        22
      ],
      "weeklyBackordered": [
        18,
        0,
        27,
        43,
        30,
        34,
        34,
        44,
        27,
        26,
        49,
        32,
        8,
        49,
        39,
        19,
        49,
        49,
        10,
        0,
        32,
        15,
        0,
        11,
        48,
        19,
        13,
        39,
        18,
        49,
        49,
        33,
        47,
        49,
        0,
        0,
        3,
        0,
        49,
        31,
        26,
        32,
        14,
        49,
        33,
        27,
        20,
        12,
        49,
        27,
        25,
        32
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        23,
        12,
        29,
        39,
        10,
        14,
        27,
        20,
        35,
        2,
        43,
        0,
        11,
        31,
        1,
        49,
        34,
        29,
        18,
        27,
        30,
        44,
        45,
        43,
        0,
        47,
        24,
        15,
        1,
        1,
        0,
        25,
        45,
        0,
        17,
        49,
        20,
        26,
        44,
        15,
        2,
        29,
        30,
        33,
        49,
        0,
        3,
        42,
        45,
        21,
        0,
        0
      ],
      "weeklyRejected": [
        36,
        15,
        34,
        36,
        33,
        0,
        7,
        16,
        8,
        31,
        49,
        2,
        14,
        38,
        42,
        35,
        29,
        44,
        0,
        43,
        18,
        49,
        9,
        27,
        12,
        31,
        49,
        17,
        45,
        29,
        37,
        0,
        46,
        22,
        18,
        42,
        33,
        15,
        3,
        49,
        24,
        49,
        22,
        16,
        38,
        25,
        0,
        32,
        8,
        49,
        11,
        38
      ],
      "weeklyHold": [
        29,
        31,
        49,
        1,
        0,
        48,
        40,
        19,
        0,
        17,
        7,
        30,
        48,
        41,
        49,
        16,
        49,
        7,
        30,
        26,
        31,
        44,
        17,
        2,
        49,
        40,
        49,
        27,
        38,
        31,
        48,
        5,
        0,
        0,
        29,
        19,
        1,
        0,
        45,
        38,
        43,
        35,
        7,
        49,
        15,
        32,
        32,
        49,
        3,
        33,
        49,
        29
      ],
      "weeklyBackordered": [
        10,
        2,
        4,
        30,
        48,
        18,
        0,
        46,
        49,
        13,
        39,
        32,
        3,
        37,
        22,
        6,
        49,
        43,
        0,
        4,
        16,
        37,
        34,
        3,
        27,
        1,
        43,
        49,
        49,
        45,
        22,
        18,
        38,
        49,
        36,
        0,
        32,
        16,
        48,
        17,
        21,
        1,
        9,
        38,
        24,
        48,
        49,
        24,
        20,
        43,
        48,
        25
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        0,
        24,
        0,
        48,
        14,
        6,
        1,
        10,
        0,
        19,
        49,
        44,
        29,
        9,
        1,
        21,
        26,
        3,
        2,
        8,
        0,
        45,
        32,
        27,
        0,
        24,
        0,
        15,
        25,
        39,
        41,
        10,
        18,
        17,
        48,
        38,
        47,
        47,
        31,
        0,
        18,
        28,
        49,
        37,
        49,
        34,
        0,
        37,
        16,
        20,
        43,
        12
      ],
      "weeklyRejected": [
        49,
        4,
        19,
        47,
        19,
        44,
        49,
        0,
        47,
        35,
        14,
        49,
        27,
        48,
        2,
        26,
        35,
        42,
        25,
        12,
        0,
        33,
        49,
        49,
        25,
        46,
        0,
        39,
        27,
        22,
        26,
        11,
        12,
        49,
        28,
        49,
        49,
        34,
        18,
        0,
        35,
        49,
        9,
        24,
        34,
        14,
        0,
        23,
        20,
        31,
        0,
        3
      ],
      "weeklyHold": [
        24,
        0,
        41,
        35,
        9,
        43,
        29,
        32,
        19,
        15,
        28,
        49,
        35,
        41,
        49,
        11,
        0,
        21,
        9,
        7,
        20,
        0,
        14,
        0,
        0,
        29,
        46,
        32,
        36,
        12,
        48,
        5,
        44,
        3,
        0,
        49,
        23,
        35,
        26,
        28,
        31,
        17,
        28,
        2,
        28,
        24,
        16,
        45,
        1,
        28,
        30,
        49
      ],
      "weeklyBackordered": [
        49,
        10,
        14,
        7,
        46,
        0,
        49,
        2,
        38,
        32,
        49,
        39,
        11,
        0,
        0,
        29,
        42,
        46,
        9,
        20,
        16,
        0,
        18,
        30,
        48,
        14,
        33,
        49,
        42,
        33,
        0,
        36,
        48,
        15,
        0,
        0,
        25,
        2,
        8,
        12,
        48,
        48,
        0,
        49,
        9,
        36,
        31,
        37,
        0,
        20,
        31,
        1
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        5,
        45,
        33,
        33,
        48,
        18,
        8,
        46,
        48,
        49,
        43,
        8,
        35,
        22,
        23,
        49,
        36,
        43,
        30,
        42,
        22,
        24,
        32,
        37,
        0,
        36,
        0,
        25,
        37,
        1,
        20,
        20,
        49,
        0,
        40,
        49,
        12,
        12,
        14,
        49,
        21,
        37,
        42,
        49,
        49,
        24,
        25,
        20,
        30,
        13,
        16,
        13
      ],
      "weeklyRejected": [
        32,
        21,
        49,
        32,
        49,
        0,
        45,
        12,
        32,
        19,
        49,
        28,
        6,
        23,
        25,
        10,
        0,
        49,
        12,
        49,
        32,
        42,
        41,
        47,
        14,
        38,
        40,
        17,
        47,
        12,
        36,
        18,
        16,
        32,
        18,
        38,
        17,
        13,
        1,
        0,
        43,
        6,
        49,
        0,
        38,
        0,
        20,
        4,
        12,
        49,
        18,
        38
      ],
      "weeklyHold": [
        0,
        0,
        17,
        1,
        36,
        48,
        27,
        2,
        5,
        46,
        33,
        17,
        38,
        18,
        48,
        0,
        27,
        38,
        9,
        44,
        23,
        32,
        14,
        0,
        9,
        48,
        35,
        48,
        39,
        6,
        48,
        23,
        9,
        35,
        45,
        32,
        38,
        0,
        37,
        37,
        36,
        0,
        17,
        4,
        10,
        49,
        25,
        46,
        22,
        34,
        49,
        21
      ],
      "weeklyBackordered": [
        49,
        23,
        48,
        30,
        49,
        33,
        22,
        38,
        6,
        6,
        29,
        44,
        3,
        6,
        10,
        41,
        49,
        49,
        0,
        11,
        49,
        40,
        3,
        24,
        1,
        32,
        35,
        38,
        12,
        39,
        0,
        37,
        36,
        30,
        32,
        36,
        49,
        23,
        31,
        18,
        0,
        29,
        17,
        49,
        25,
        22,
        49,
        37,
        5,
        40,
        42,
        45
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        38,
        21,
        49,
        0,
        17,
        7,
        28,
        49,
        45,
        19,
        28,
        0,
        33,
        32,
        40,
        14,
        49,
        2,
        49,
        13,
        49,
        19,
        15,
        49,
        18,
        39,
        0,
        33,
        25,
        49,
        5,
        30,
        48,
        49,
        30,
        21,
        39,
        0,
        30,
        44,
        44,
        16,
        38,
        10,
        44,
        0,
        17,
        24,
        33,
        49,
        0,
        27
      ],
      "weeklyRejected": [
        49,
        14,
        19,
        28,
        23,
        10,
        47,
        49,
        24,
        17,
        4,
        20,
        7,
        43,
        48,
        32,
        0,
        45,
        32,
        3,
        28,
        49,
        37,
        47,
        26,
        19,
        40,
        49,
        36,
        39,
        32,
        47,
        30,
        28,
        11,
        32,
        26,
        16,
        1,
        49,
        47,
        0,
        49,
        49,
        47,
        14,
        0,
        46,
        4,
        16,
        31,
        9
      ],
      "weeklyHold": [
        29,
        0,
        37,
        22,
        49,
        25,
        41,
        49,
        21,
        35,
        16,
        40,
        20,
        41,
        43,
        0,
        0,
        28,
        49,
        0,
        18,
        26,
        24,
        49,
        23,
        9,
        10,
        3,
        18,
        0,
        48,
        1,
        13,
        2,
        23,
        42,
        0,
        36,
        31,
        37,
        6,
        49,
        0,
        28,
        31,
        10,
        25,
        21,
        0,
        29,
        49,
        36
      ],
      "weeklyBackordered": [
        14,
        19,
        15,
        41,
        36,
        46,
        49,
        33,
        42,
        49,
        31,
        16,
        22,
        47,
        13,
        49,
        41,
        42,
        20,
        11,
        30,
        0,
        12,
        12,
        10,
        6,
        11,
        11,
        30,
        16,
        0,
        49,
        29,
        24,
        0,
        0,
        46,
        32,
        18,
        27,
        0,
        31,
        32,
        49,
        30,
        28,
        49,
        3,
        25,
        10,
        42,
        7
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        0,
        25,
        49,
        0,
        40,
        27,
        30,
        40,
        19,
        24,
        46,
        6,
        43,
        46,
        45,
        3,
        11,
        3,
        1,
        27,
        20,
        6,
        41,
        23,
        17,
        31,
        0,
        24,
        8,
        35,
        49,
        32,
        49,
        23,
        47,
        4,
        46,
        0,
        49,
        49,
        14,
        5,
        20,
        49,
        16,
        27,
        7,
        11,
        12,
        29,
        1,
        46
      ],
      "weeklyRejected": [
        19,
        28,
        49,
        47,
        48,
        23,
        15,
        33,
        0,
        18,
        49,
        16,
        25,
        36,
        46,
        5,
        23,
        26,
        33,
        37,
        38,
        21,
        43,
        40,
        37,
        38,
        17,
        35,
        48,
        0,
        0,
        22,
        48,
        49,
        39,
        27,
        22,
        49,
        1,
        21,
        31,
        49,
        0,
        3,
        49,
        34,
        0,
        49,
        18,
        33,
        8,
        41
      ],
      "weeklyHold": [
        35,
        0,
        37,
        12,
        27,
        24,
        38,
        25,
        31,
        49,
        47,
        7,
        48,
        12,
        49,
        26,
        0,
        8,
        26,
        4,
        46,
        15,
        49,
        0,
        34,
        48,
        49,
        0,
        20,
        0,
        26,
        21,
        29,
        49,
        2,
        38,
        21,
        29,
        49,
        24,
        0,
        49,
        8,
        49,
        49,
        49,
        0,
        0,
        0,
        18,
        0,
        49
      ],
      "weeklyBackordered": [
        48,
        28,
        42,
        30,
        49,
        46,
        49,
        41,
        43,
        0,
        34,
        49,
        3,
        49,
        20,
        24,
        49,
        25,
        27,
        28,
        45,
        23,
        14,
        0,
        35,
        38,
        1,
        0,
        17,
        17,
        33,
        28,
        46,
        15,
        0,
        0,
        37,
        0,
        20,
        15,
        48,
        48,
        7,
        49,
        20,
        40,
        27,
        32,
        16,
        44,
        1,
        49
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        0,
        29,
        23,
        33,
        31,
        43,
        20,
        30,
        22,
        23,
        39,
        0,
        1,
        19,
        42,
        33,
        49,
        48,
        0,
        4,
        9,
        35,
        45,
        32,
        49,
        20,
        0,
        42,
        1,
        39,
        28,
        4,
        49,
        33,
        48,
        47,
        19,
        1,
        4,
        39,
        23,
        26,
        49,
        27,
        49,
        49,
        49,
        0,
        0,
        37,
        11,
        19
      ],
      "weeklyRejected": [
        24,
        45,
        2,
        46,
        23,
        0,
        0,
        26,
        27,
        48,
        27,
        31,
        5,
        45,
        35,
        0,
        6,
        29,
        49,
        25,
        49,
        38,
        31,
        22,
        30,
        49,
        38,
        30,
        35,
        30,
        49,
        35,
        34,
        16,
        16,
        49,
        49,
        9,
        7,
        16,
        48,
        45,
        11,
        33,
        15,
        37,
        12,
        23,
        26,
        49,
        0,
        16
      ],
      "weeklyHold": [
        49,
        12,
        4,
        27,
        49,
        6,
        49,
        23,
        21,
        27,
        0,
        13,
        48,
        1,
        3,
        49,
        0,
        38,
        7,
        23,
        26,
        10,
        7,
        21,
        18,
        32,
        6,
        42,
        32,
        0,
        48,
        38,
        6,
        14,
        8,
        4,
        38,
        37,
        11,
        49,
        40,
        36,
        15,
        25,
        33,
        49,
        49,
        21,
        47,
        49,
        7,
        17
      ],
      "weeklyBackordered": [
        49,
        12,
        0,
        30,
        49,
        46,
        49,
        46,
        29,
        0,
        2,
        45,
        25,
        6,
        32,
        20,
        17,
        27,
        15,
        20,
        38,
        7,
        21,
        45,
        25,
        39,
        1,
        3,
        14,
        31,
        45,
        9,
        48,
        38,
        12,
        13,
        5,
        0,
        12,
        0,
        35,
        48,
        12,
        49,
        12,
        5,
        37,
        26,
        33,
        20,
        48,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        0,
        34,
        9,
        30,
        23,
        11,
        4,
        39,
        48,
        2,
        38,
        0,
        33,
        28,
        30,
        28,
        41,
        0,
        31,
        18,
        14,
        11,
        49,
        49,
        6,
        47,
        9,
        49,
        20,
        24,
        9,
        18,
        41,
        0,
        35,
        49,
        10,
        29,
        4,
        43,
        1,
        0,
        49,
        45,
        49,
        0,
        0,
        0,
        40,
        49,
        27,
        0
      ],
      "weeklyRejected": [
        43,
        21,
        35,
        40,
        24,
        29,
        16,
        24,
        34,
        29,
        9,
        22,
        33,
        48,
        30,
        13,
        45,
        42,
        0,
        0,
        5,
        39,
        28,
        16,
        19,
        42,
        40,
        27,
        38,
        48,
        34,
        47,
        45,
        24,
        23,
        49,
        14,
        49,
        1,
        19,
        34,
        41,
        12,
        35,
        16,
        45,
        26,
        49,
        0,
        49,
        0,
        24
      ],
      "weeklyHold": [
        28,
        44,
        39,
        35,
        41,
        26,
        21,
        3,
        25,
        43,
        22,
        46,
        31,
        33,
        26,
        17,
        7,
        16,
        21,
        47,
        10,
        43,
        24,
        49,
        4,
        48,
        44,
        49,
        36,
        16,
        35,
        5,
        21,
        28,
        36,
        35,
        45,
        19,
        49,
        34,
        27,
        30,
        0,
        39,
        44,
        49,
        35,
        17,
        7,
        0,
        34,
        15
      ],
      "weeklyBackordered": [
        39,
        4,
        28,
        49,
        49,
        16,
        13,
        31,
        0,
        0,
        25,
        49,
        39,
        15,
        7,
        15,
        45,
        49,
        0,
        18,
        0,
        0,
        6,
        24,
        21,
        34,
        34,
        49,
        26,
        33,
        6,
        0,
        48,
        16,
        3,
        0,
        14,
        18,
        0,
        0,
        0,
        43,
        24,
        28,
        4,
        32,
        24,
        20,
        5,
        49,
        42,
        6
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        0,
        31,
        4,
        47,
        15,
        15,
        26,
        31,
        45,
        16,
        47,
        8,
        0,
        35,
        27,
        17,
        49,
        0,
        0,
        38,
        49,
        29,
        24,
        45,
        20,
        12,
        13,
        31,
        1,
        1,
        19,
        19,
        34,
        14,
        40,
        43,
        35,
        10,
        49,
        49,
        47,
        2,
        24,
        19,
        49,
        7,
        14,
        49,
        29,
        26,
        14,
        0
      ],
      "weeklyRejected": [
        45,
        44,
        39,
        26,
        0,
        11,
        0,
        44,
        49,
        6,
        29,
        29,
        36,
        32,
        48,
        37,
        19,
        24,
        32,
        0,
        49,
        42,
        15,
        22,
        0,
        30,
        2,
        37,
        48,
        21,
        43,
        47,
        38,
        48,
        0,
        34,
        24,
        44,
        23,
        49,
        38,
        27,
        31,
        15,
        31,
        42,
        9,
        11,
        32,
        49,
        17,
        25
      ],
      "weeklyHold": [
        49,
        49,
        1,
        14,
        41,
        6,
        13,
        8,
        2,
        49,
        12,
        34,
        47,
        9,
        42,
        23,
        38,
        7,
        0,
        20,
        27,
        2,
        30,
        29,
        28,
        47,
        7,
        34,
        49,
        13,
        19,
        3,
        1,
        6,
        48,
        1,
        48,
        15,
        20,
        12,
        39,
        18,
        0,
        0,
        39,
        49,
        25,
        0,
        38,
        48,
        13,
        21
      ],
      "weeklyBackordered": [
        25,
        38,
        0,
        0,
        37,
        43,
        36,
        49,
        31,
        15,
        49,
        25,
        22,
        21,
        48,
        25,
        29,
        49,
        8,
        20,
        24,
        4,
        0,
        0,
        1,
        18,
        18,
        16,
        17,
        49,
        0,
        37,
        29,
        49,
        0,
        15,
        1,
        2,
        2,
        15,
        47,
        30,
        49,
        49,
        40,
        31,
        20,
        40,
        49,
        49,
        49,
        1
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        16,
        45,
        10,
        4,
        48,
        6,
        31,
        49,
        43,
        49,
        41,
        10,
        24,
        34,
        8,
        27,
        46,
        7,
        4,
        44,
        40,
        18,
        23,
        49,
        11,
        10,
        40,
        0,
        28,
        43,
        16,
        25,
        44,
        0,
        17,
        35,
        47,
        38,
        27,
        30,
        15,
        4,
        19,
        32,
        49,
        23,
        24,
        15,
        39,
        0,
        47,
        48
      ],
      "weeklyRejected": [
        49,
        33,
        48,
        21,
        28,
        44,
        0,
        47,
        38,
        29,
        33,
        13,
        17,
        5,
        48,
        21,
        0,
        43,
        17,
        5,
        0,
        41,
        43,
        44,
        0,
        49,
        32,
        10,
        48,
        0,
        31,
        43,
        42,
        14,
        49,
        16,
        12,
        46,
        4,
        34,
        26,
        49,
        49,
        10,
        45,
        14,
        1,
        0,
        22,
        10,
        20,
        20
      ],
      "weeklyHold": [
        49,
        5,
        31,
        20,
        34,
        23,
        44,
        45,
        37,
        33,
        31,
        21,
        48,
        1,
        49,
        20,
        25,
        48,
        29,
        47,
        16,
        0,
        20,
        48,
        43,
        48,
        15,
        5,
        28,
        0,
        29,
        34,
        20,
        3,
        2,
        9,
        22,
        45,
        37,
        41,
        44,
        33,
        9,
        27,
        49,
        35,
        47,
        46,
        0,
        32,
        38,
        43
      ],
      "weeklyBackordered": [
        33,
        28,
        16,
        25,
        29,
        46,
        45,
        39,
        3,
        18,
        7,
        36,
        19,
        40,
        25,
        38,
        36,
        20,
        41,
        0,
        14,
        18,
        3,
        10,
        30,
        28,
        31,
        44,
        31,
        49,
        10,
        48,
        0,
        32,
        2,
        0,
        1,
        0,
        0,
        20,
        2,
        48,
        36,
        32,
        31,
        49,
        47,
        0,
        32,
        49,
        35,
        34
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        34,
        40,
        39,
        31,
        30,
        0,
        5,
        49,
        40,
        0,
        49,
        2,
        33,
        49,
        22,
        44,
        49,
        15,
        36,
        12,
        49,
        9,
        11,
        18,
        40,
        40,
        16,
        14,
        1,
        36,
        26,
        15,
        33,
        39,
        48,
        39,
        38,
        15,
        14,
        49,
        0,
        16,
        33,
        11,
        38,
        0,
        21,
        32,
        23,
        0,
        24,
        3
      ],
      "weeklyRejected": [
        12,
        47,
        35,
        47,
        17,
        18,
        27,
        49,
        49,
        18,
        40,
        49,
        16,
        20,
        48,
        12,
        28,
        49,
        0,
        15,
        0,
        49,
        0,
        13,
        15,
        22,
        48,
        49,
        48,
        28,
        49,
        30,
        47,
        27,
        0,
        31,
        29,
        0,
        29,
        17,
        42,
        0,
        34,
        0,
        49,
        12,
        21,
        26,
        4,
        40,
        31,
        24
      ],
      "weeklyHold": [
        20,
        46,
        49,
        31,
        37,
        46,
        46,
        2,
        26,
        26,
        28,
        1,
        48,
        20,
        34,
        0,
        5,
        29,
        4,
        0,
        25,
        49,
        28,
        5,
        0,
        48,
        18,
        35,
        23,
        0,
        48,
        20,
        3,
        19,
        48,
        6,
        48,
        0,
        13,
        25,
        43,
        15,
        19,
        27,
        49,
        38,
        18,
        12,
        5,
        2,
        11,
        37
      ],
      "weeklyBackordered": [
        44,
        2,
        0,
        30,
        29,
        46,
        20,
        31,
        19,
        35,
        32,
        30,
        21,
        49,
        26,
        24,
        30,
        49,
        9,
        39,
        19,
        0,
        0,
        40,
        18,
        32,
        33,
        0,
        35,
        49,
        7,
        29,
        0,
        46,
        11,
        0,
        22,
        13,
        9,
        11,
        18,
        27,
        42,
        32,
        20,
        42,
        35,
        37,
        0,
        49,
        36,
        36
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        25,
        22,
        22,
        35,
        47,
        24,
        15,
        28,
        18,
        49,
        18,
        34,
        0,
        47,
        22,
        17,
        49,
        0,
        13,
        48,
        10,
        49,
        0,
        49,
        18,
        14,
        49,
        33,
        49,
        49,
        46,
        14,
        49,
        2,
        48,
        43,
        21,
        0,
        32,
        49,
        15,
        49,
        32,
        18,
        49,
        44,
        25,
        33,
        15,
        49,
        26,
        45
      ],
      "weeklyRejected": [
        33,
        1,
        28,
        0,
        49,
        4,
        41,
        0,
        49,
        19,
        49,
        0,
        14,
        48,
        5,
        21,
        49,
        13,
        26,
        0,
        0,
        35,
        2,
        42,
        12,
        49,
        45,
        37,
        37,
        22,
        36,
        0,
        48,
        0,
        0,
        31,
        49,
        49,
        3,
        23,
        48,
        44,
        49,
        20,
        12,
        24,
        2,
        36,
        48,
        49,
        0,
        18
      ],
      "weeklyHold": [
        19,
        5,
        43,
        14,
        22,
        26,
        18,
        19,
        32,
        0,
        47,
        19,
        1,
        49,
        12,
        0,
        10,
        22,
        21,
        0,
        23,
        26,
        12,
        0,
        0,
        22,
        35,
        40,
        49,
        19,
        48,
        10,
        0,
        0,
        12,
        49,
        0,
        31,
        49,
        36,
        49,
        34,
        32,
        0,
        40,
        7,
        42,
        22,
        0,
        2,
        31,
        45
      ],
      "weeklyBackordered": [
        25,
        18,
        0,
        17,
        29,
        34,
        49,
        20,
        0,
        11,
        33,
        9,
        3,
        20,
        30,
        46,
        49,
        44,
        13,
        8,
        38,
        0,
        0,
        23,
        42,
        1,
        30,
        49,
        1,
        41,
        0,
        49,
        31,
        49,
        8,
        0,
        1,
        0,
        49,
        41,
        44,
        32,
        26,
        49,
        15,
        0,
        19,
        49,
        3,
        16,
        21,
        22
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        25,
        1,
        34,
        25,
        45,
        46,
        23,
        28,
        35,
        0,
        32,
        12,
        44,
        34,
        41,
        0,
        35,
        12,
        16,
        21,
        29,
        31,
        33,
        33,
        11,
        1,
        9,
        24,
        7,
        49,
        42,
        44,
        49,
        4,
        48,
        2,
        41,
        40,
        15,
        49,
        44,
        0,
        20,
        0,
        3,
        20,
        6,
        23,
        49,
        49,
        10,
        38
      ],
      "weeklyRejected": [
        7,
        0,
        49,
        15,
        49,
        49,
        11,
        15,
        49,
        8,
        1,
        43,
        6,
        38,
        29,
        0,
        29,
        49,
        24,
        46,
        0,
        49,
        8,
        31,
        8,
        46,
        39,
        49,
        10,
        4,
        34,
        47,
        48,
        47,
        34,
        49,
        23,
        17,
        23,
        40,
        30,
        22,
        0,
        0,
        37,
        4,
        4,
        17,
        0,
        49,
        0,
        3
      ],
      "weeklyHold": [
        30,
        49,
        38,
        1,
        49,
        48,
        49,
        9,
        49,
        32,
        33,
        49,
        48,
        29,
        21,
        40,
        19,
        30,
        18,
        0,
        4,
        49,
        29,
        47,
        26,
        37,
        28,
        0,
        49,
        0,
        46,
        48,
        0,
        48,
        48,
        16,
        3,
        17,
        49,
        40,
        28,
        36,
        9,
        7,
        33,
        0,
        0,
        49,
        21,
        17,
        49,
        19
      ],
      "weeklyBackordered": [
        29,
        49,
        12,
        31,
        49,
        46,
        40,
        35,
        15,
        21,
        20,
        0,
        3,
        33,
        45,
        1,
        29,
        16,
        2,
        0,
        46,
        38,
        10,
        27,
        24,
        4,
        10,
        4,
        49,
        49,
        11,
        49,
        23,
        40,
        31,
        14,
        29,
        2,
        49,
        38,
        19,
        48,
        0,
        49,
        0,
        33,
        36,
        25,
        32,
        38,
        49,
        25
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        30,
        20,
        49,
        48,
        23,
        0,
        6,
        28,
        48,
        32,
        49,
        0,
        37,
        9,
        41,
        38,
        49,
        37,
        0,
        49,
        49,
        49,
        19,
        0,
        6,
        47,
        49,
        49,
        9,
        12,
        19,
        43,
        39,
        0,
        41,
        49,
        44,
        0,
        33,
        30,
        5,
        49,
        49,
        40,
        40,
        0,
        0,
        20,
        28,
        41,
        0,
        45
      ],
      "weeklyRejected": [
        44,
        49,
        49,
        47,
        30,
        0,
        45,
        48,
        32,
        47,
        49,
        17,
        49,
        37,
        18,
        15,
        3,
        49,
        28,
        28,
        49,
        16,
        35,
        49,
        0,
        26,
        42,
        6,
        48,
        0,
        30,
        37,
        48,
        27,
        8,
        41,
        0,
        2,
        7,
        49,
        0,
        10,
        49,
        31,
        49,
        24,
        0,
        41,
        18,
        49,
        12,
        41
      ],
      "weeklyHold": [
        23,
        30,
        36,
        21,
        4,
        34,
        0,
        2,
        32,
        49,
        35,
        1,
        26,
        30,
        7,
        10,
        9,
        32,
        0,
        49,
        43,
        49,
        6,
        0,
        22,
        38,
        33,
        49,
        35,
        12,
        43,
        0,
        9,
        0,
        39,
        0,
        44,
        0,
        37,
        24,
        22,
        12,
        0,
        44,
        31,
        49,
        21,
        28,
        8,
        22,
        4,
        26
      ],
      "weeklyBackordered": [
        0,
        2,
        0,
        31,
        49,
        43,
        0,
        1,
        6,
        0,
        49,
        4,
        3,
        26,
        0,
        49,
        49,
        49,
        0,
        26,
        49,
        17,
        16,
        0,
        21,
        19,
        32,
        30,
        16,
        49,
        0,
        49,
        48,
        20,
        6,
        0,
        49,
        0,
        39,
        31,
        9,
        17,
        49,
        49,
        49,
        49,
        47,
        35,
        5,
        38,
        1,
        45
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        0,
        49,
        36,
        17,
        25,
        39,
        1,
        47,
        31,
        25,
        49,
        0,
        0,
        10,
        20,
        6,
        35,
        0,
        15,
        6,
        40,
        12,
        16,
        27,
        17,
        47,
        31,
        38,
        2,
        26,
        47,
        18,
        49,
        2,
        48,
        47,
        46,
        33,
        7,
        49,
        35,
        0,
        35,
        44,
        49,
        15,
        8,
        34,
        27,
        45,
        29,
        42
      ],
      "weeklyRejected": [
        49,
        39,
        15,
        18,
        31,
        40,
        25,
        40,
        49,
        12,
        24,
        18,
        0,
        34,
        25,
        1,
        9,
        49,
        39,
        4,
        47,
        49,
        49,
        32,
        16,
        38,
        29,
        13,
        4,
        48,
        16,
        17,
        18,
        7,
        1,
        22,
        49,
        49,
        8,
        17,
        14,
        14,
        8,
        0,
        48,
        35,
        17,
        0,
        21,
        49,
        0,
        49
      ],
      "weeklyHold": [
        0,
        0,
        44,
        24,
        49,
        46,
        0,
        2,
        29,
        6,
        47,
        28,
        14,
        28,
        49,
        0,
        0,
        24,
        21,
        11,
        0,
        15,
        30,
        49,
        23,
        26,
        43,
        7,
        49,
        12,
        16,
        16,
        0,
        36,
        17,
        22,
        0,
        22,
        27,
        10,
        0,
        26,
        19,
        32,
        0,
        44,
        32,
        27,
        0,
        41,
        12,
        49
      ],
      "weeklyBackordered": [
        43,
        17,
        15,
        25,
        46,
        41,
        6,
        20,
        36,
        14,
        49,
        19,
        32,
        41,
        30,
        49,
        15,
        14,
        0,
        16,
        42,
        21,
        0,
        49,
        8,
        9,
        49,
        15,
        30,
        24,
        0,
        39,
        48,
        23,
        0,
        0,
        2,
        4,
        20,
        22,
        0,
        18,
        49,
        49,
        39,
        30,
        49,
        28,
        0,
        39,
        49,
        29
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        5,
        49,
        44,
        27,
        1,
        0,
        1,
        7,
        25,
        6,
        46,
        19,
        21,
        48,
        6,
        21,
        13,
        14,
        34,
        0,
        12,
        45,
        26,
        26,
        49,
        9,
        5,
        13,
        1,
        49,
        21,
        0,
        43,
        0,
        23,
        29,
        18,
        44,
        33,
        49,
        6,
        49,
        45,
        8,
        49,
        2,
        0,
        6,
        49,
        23,
        46,
        18
      ],
      "weeklyRejected": [
        49,
        14,
        30,
        47,
        43,
        8,
        20,
        49,
        20,
        2,
        45,
        27,
        26,
        33,
        48,
        35,
        29,
        47,
        28,
        49,
        0,
        0,
        28,
        19,
        45,
        49,
        31,
        47,
        48,
        20,
        49,
        14,
        32,
        49,
        0,
        37,
        30,
        49,
        1,
        3,
        0,
        27,
        4,
        0,
        49,
        8,
        11,
        8,
        20,
        46,
        31,
        32
      ],
      "weeklyHold": [
        34,
        37,
        39,
        24,
        18,
        48,
        0,
        2,
        14,
        1,
        47,
        19,
        48,
        49,
        35,
        40,
        26,
        17,
        32,
        10,
        26,
        49,
        49,
        2,
        49,
        32,
        20,
        28,
        28,
        16,
        48,
        9,
        0,
        15,
        13,
        11,
        40,
        0,
        38,
        0,
        14,
        44,
        15,
        18,
        6,
        0,
        3,
        0,
        21,
        13,
        31,
        49
      ],
      "weeklyBackordered": [
        33,
        0,
        17,
        38,
        49,
        35,
        19,
        37,
        49,
        49,
        0,
        35,
        3,
        46,
        37,
        27,
        40,
        13,
        0,
        49,
        44,
        29,
        14,
        49,
        37,
        1,
        11,
        28,
        7,
        49,
        30,
        19,
        28,
        49,
        10,
        12,
        23,
        29,
        20,
        32,
        32,
        48,
        46,
        49,
        18,
        25,
        26,
        37,
        29,
        49,
        49,
        41
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        32,
        49,
        28,
        0,
        28,
        9,
        18,
        49,
        35,
        0,
        28,
        5,
        36,
        43,
        8,
        1,
        49,
        37,
        8,
        0,
        44,
        29,
        27,
        36,
        22,
        30,
        0,
        35,
        1,
        30,
        36,
        0,
        49,
        12,
        32,
        6,
        17,
        11,
        49,
        19,
        39,
        34,
        46,
        44,
        49,
        45,
        6,
        0,
        26,
        49,
        42,
        17
      ],
      "weeklyRejected": [
        43,
        0,
        49,
        10,
        11,
        35,
        34,
        26,
        42,
        21,
        26,
        45,
        23,
        43,
        43,
        34,
        23,
        30,
        36,
        13,
        41,
        17,
        40,
        0,
        32,
        14,
        36,
        26,
        47,
        32,
        17,
        47,
        23,
        30,
        8,
        48,
        36,
        39,
        22,
        21,
        36,
        44,
        3,
        11,
        2,
        17,
        0,
        49,
        0,
        21,
        13,
        27
      ],
      "weeklyHold": [
        26,
        45,
        28,
        40,
        38,
        15,
        28,
        2,
        49,
        49,
        10,
        33,
        24,
        32,
        9,
        20,
        9,
        12,
        36,
        26,
        11,
        46,
        24,
        21,
        31,
        20,
        0,
        24,
        36,
        30,
        28,
        19,
        24,
        38,
        48,
        26,
        0,
        26,
        46,
        46,
        42,
        49,
        0,
        48,
        40,
        5,
        0,
        19,
        20,
        26,
        15,
        16
      ],
      "weeklyBackordered": [
        37,
        49,
        18,
        20,
        49,
        46,
        49,
        1,
        21,
        8,
        35,
        49,
        3,
        41,
        36,
        49,
        33,
        9,
        15,
        0,
        0,
        20,
        0,
        4,
        1,
        15,
        26,
        49,
        44,
        49,
        29,
        44,
        22,
        37,
        0,
        21,
        21,
        0,
        15,
        0,
        25,
        48,
        10,
        31,
        0,
        38,
        49,
        6,
        48,
        17,
        49,
        10
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        0,
        19,
        15,
        48,
        31,
        1,
        8,
        49,
        45,
        17,
        39,
        20,
        42,
        29,
        17,
        28,
        28,
        19,
        19,
        49,
        1,
        7,
        35,
        40,
        48,
        42,
        0,
        13,
        13,
        49,
        28,
        49,
        49,
        30,
        41,
        49,
        35,
        21,
        37,
        49,
        15,
        49,
        34,
        29,
        0,
        0,
        0,
        49,
        0,
        48,
        0,
        42
      ],
      "weeklyRejected": [
        49,
        0,
        34,
        43,
        49,
        49,
        19,
        0,
        27,
        48,
        0,
        19,
        43,
        44,
        23,
        26,
        20,
        14,
        45,
        31,
        46,
        49,
        4,
        46,
        35,
        27,
        49,
        11,
        44,
        19,
        15,
        47,
        25,
        49,
        49,
        49,
        30,
        0,
        3,
        28,
        11,
        49,
        11,
        4,
        41,
        0,
        19,
        36,
        0,
        19,
        0,
        49
      ],
      "weeklyHold": [
        42,
        30,
        43,
        1,
        25,
        0,
        25,
        28,
        20,
        2,
        33,
        34,
        39,
        47,
        0,
        37,
        49,
        19,
        49,
        4,
        0,
        0,
        47,
        25,
        12,
        48,
        49,
        26,
        32,
        4,
        48,
        9,
        0,
        41,
        22,
        28,
        22,
        2,
        49,
        47,
        0,
        49,
        22,
        49,
        0,
        21,
        44,
        44,
        0,
        1,
        48,
        22
      ],
      "weeklyBackordered": [
        10,
        43,
        0,
        49,
        43,
        28,
        10,
        26,
        40,
        8,
        49,
        24,
        49,
        39,
        5,
        29,
        8,
        49,
        22,
        35,
        33,
        49,
        2,
        16,
        1,
        42,
        17,
        19,
        30,
        37,
        24,
        44,
        21,
        13,
        4,
        0,
        42,
        21,
        30,
        49,
        9,
        48,
        28,
        49,
        0,
        49,
        28,
        33,
        24,
        42,
        49,
        46
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        14,
        49,
        42,
        26,
        49,
        0,
        22,
        14,
        27,
        17,
        49,
        6,
        46,
        0,
        13,
        0,
        8,
        0,
        0,
        22,
        24,
        30,
        30,
        49,
        47,
        19,
        2,
        48,
        1,
        49,
        31,
        46,
        35,
        49,
        5,
        44,
        35,
        46,
        42,
        41,
        46,
        41,
        34,
        39,
        30,
        29,
        5,
        41,
        35,
        49,
        0,
        11
      ],
      "weeklyRejected": [
        36,
        49,
        8,
        0,
        20,
        33,
        31,
        30,
        29,
        31,
        20,
        19,
        6,
        31,
        48,
        0,
        27,
        0,
        49,
        48,
        39,
        40,
        32,
        12,
        49,
        49,
        49,
        49,
        27,
        0,
        21,
        21,
        48,
        35,
        3,
        49,
        27,
        5,
        1,
        7,
        44,
        48,
        33,
        29,
        45,
        49,
        0,
        49,
        0,
        27,
        4,
        47
      ],
      "weeklyHold": [
        28,
        10,
        40,
        1,
        22,
        36,
        9,
        21,
        11,
        10,
        10,
        42,
        2,
        14,
        49,
        0,
        0,
        20,
        27,
        32,
        9,
        0,
        40,
        18,
        46,
        30,
        48,
        26,
        29,
        0,
        48,
        0,
        3,
        3,
        48,
        23,
        28,
        29,
        38,
        49,
        17,
        15,
        30,
        28,
        25,
        49,
        38,
        49,
        0,
        15,
        49,
        40
      ],
      "weeklyBackordered": [
        23,
        14,
        14,
        9,
        33,
        46,
        49,
        48,
        0,
        5,
        21,
        8,
        30,
        18,
        40,
        49,
        43,
        49,
        16,
        14,
        49,
        0,
        11,
        37,
        11,
        21,
        35,
        48,
        1,
        45,
        25,
        0,
        4,
        48,
        2,
        0,
        1,
        18,
        44,
        13,
        30,
        48,
        7,
        49,
        31,
        26,
        49,
        24,
        33,
        33,
        49,
        23
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        32,
        5,
        27,
        48,
        20,
        4,
        25,
        48,
        42,
        34,
        16,
        30,
        31,
        8,
        43,
        30,
        11,
        20,
        0,
        49,
        4,
        0,
        49,
        2,
        0,
        47,
        29,
        34,
        1,
        18,
        14,
        18,
        25,
        0,
        14,
        37,
        43,
        48,
        44,
        39,
        31,
        20,
        49,
        49,
        30,
        0,
        30,
        39,
        22,
        41,
        25,
        0
      ],
      "weeklyRejected": [
        49,
        26,
        33,
        47,
        21,
        10,
        49,
        21,
        16,
        17,
        30,
        31,
        49,
        48,
        27,
        0,
        23,
        49,
        49,
        22,
        14,
        32,
        38,
        49,
        25,
        30,
        49,
        38,
        48,
        36,
        44,
        24,
        48,
        30,
        11,
        28,
        45,
        25,
        1,
        35,
        48,
        45,
        0,
        49,
        49,
        49,
        8,
        20,
        16,
        36,
        0,
        49
      ],
      "weeklyHold": [
        16,
        16,
        49,
        25,
        24,
        48,
        30,
        20,
        30,
        0,
        35,
        1,
        1,
        5,
        49,
        0,
        0,
        27,
        0,
        24,
        36,
        31,
        35,
        1,
        22,
        42,
        49,
        0,
        38,
        0,
        36,
        48,
        3,
        30,
        26,
        38,
        20,
        0,
        33,
        2,
        49,
        17,
        14,
        0,
        39,
        31,
        49,
        49,
        13,
        8,
        49,
        29
      ],
      "weeklyBackordered": [
        8,
        5,
        13,
        34,
        49,
        40,
        1,
        49,
        0,
        19,
        27,
        17,
        4,
        44,
        37,
        13,
        43,
        49,
        0,
        22,
        21,
        17,
        25,
        26,
        24,
        32,
        38,
        35,
        8,
        46,
        22,
        28,
        34,
        41,
        0,
        0,
        12,
        0,
        18,
        4,
        48,
        48,
        0,
        33,
        0,
        15,
        49,
        29,
        49,
        23,
        39,
        26
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        42,
        8,
        40,
        0,
        31,
        35,
        7,
        49,
        20,
        0,
        44,
        0,
        45,
        30,
        1,
        0,
        45,
        0,
        0,
        49,
        1,
        6,
        41,
        33,
        28,
        47,
        0,
        21,
        5,
        34,
        25,
        0,
        27,
        0,
        27,
        49,
        35,
        48,
        49,
        10,
        20,
        17,
        49,
        43,
        49,
        40,
        0,
        41,
        49,
        49,
        28,
        19
      ],
      "weeklyRejected": [
        16,
        28,
        26,
        0,
        33,
        35,
        15,
        49,
        46,
        48,
        49,
        49,
        0,
        48,
        48,
        21,
        17,
        43,
        49,
        49,
        5,
        49,
        10,
        0,
        12,
        42,
        49,
        31,
        48,
        43,
        40,
        25,
        48,
        14,
        33,
        21,
        49,
        1,
        24,
        26,
        43,
        6,
        23,
        26,
        48,
        45,
        4,
        42,
        4,
        33,
        29,
        49
      ],
      "weeklyHold": [
        19,
        19,
        49,
        1,
        49,
        14,
        47,
        9,
        15,
        9,
        39,
        1,
        38,
        29,
        36,
        17,
        38,
        4,
        28,
        3,
        12,
        4,
        25,
        0,
        0,
        27,
        21,
        39,
        29,
        32,
        48,
        10,
        6,
        49,
        40,
        42,
        19,
        25,
        49,
        20,
        34,
        27,
        16,
        26,
        49,
        49,
        31,
        37,
        9,
        7,
        49,
        44
      ],
      "weeklyBackordered": [
        22,
        0,
        28,
        49,
        10,
        46,
        26,
        34,
        17,
        0,
        49,
        42,
        24,
        29,
        10,
        11,
        32,
        49,
        1,
        27,
        45,
        40,
        23,
        0,
        13,
        1,
        49,
        13,
        41,
        31,
        36,
        24,
        8,
        0,
        0,
        0,
        1,
        0,
        5,
        0,
        35,
        48,
        0,
        49,
        0,
        0,
        49,
        19,
        17,
        35,
        46,
        25
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyAccepted": [
        28,
        30,
        8,
        22,
        29,
        24,
        12,
        32,
        48,
        40,
        29,
        0,
        26,
        21,
        49,
        3,
        33,
        31,
        13,
        49,
        3,
        27,
        49,
        41,
        9,
        27,
        0,
        15,
        1,
        47,
        4,
        25,
        49,
        39,
        32,
        39,
        47,
        1,
        47,
        49,
        33,
        5,
        14,
        45,
        49,
        13,
        9,
        18,
        7,
        17,
        8,
        9
      ],
      "weeklyRejected": [
        49,
        9,
        37,
        12,
        32,
        49,
        49,
        26,
        48,
        7,
        5,
        26,
        31,
        48,
        35,
        8,
        0,
        49,
        49,
        5,
        25,
        39,
        49,
        27,
        18,
        47,
        0,
        34,
        8,
        48,
        31,
        47,
        0,
        49,
        25,
        49,
        36,
        39,
        1,
        4,
        40,
        35,
        31,
        28,
        36,
        12,
        0,
        3,
        14,
        21,
        10,
        2
      ],
      "weeklyHold": [
        13,
        44,
        44,
        1,
        45,
        29,
        28,
        38,
        0,
        43,
        0,
        36,
        1,
        26,
        25,
        29,
        26,
        43,
        30,
        10,
        17,
        12,
        13,
        18,
        24,
        48,
        36,
        3,
        49,
        38,
        48,
        14,
        14,
        46,
        7,
        41,
        0,
        0,
        3,
        49,
        39,
        18,
        3,
        19,
        30,
        21,
        0,
        0,
        4,
        0,
        27,
        49
      ],
      "weeklyBackordered": [
        45,
        17,
        18,
        35,
        49,
        29,
        39,
        45,
        49,
        43,
        49,
        41,
        23,
        0,
        34,
        49,
        1,
        49,
        17,
        17,
        0,
        27,
        24,
        45,
        1,
        33,
        22,
        18,
        14,
        24,
        41,
        11,
        48,
        18,
        0,
        0,
        30,
        27,
        18,
        32,
        4,
        22,
        0,
        49,
        10,
        14,
        33,
        24,
        1,
        47,
        23,
        1
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        11,
        24,
        43,
        35,
        49,
        3,
        19,
        49,
        17,
        12,
        25,
        10,
        43,
        23,
        16,
        18,
        27,
        13,
        12,
        6,
        34,
        49,
        1,
        22,
        22,
        45,
        41,
        49,
        4,
        49,
        45,
        23,
        49,
        6,
        3,
        18,
        39,
        48,
        44,
        49,
        0,
        28,
        27,
        11,
        37,
        41,
        13,
        39,
        1,
        33,
        13,
        0
      ],
      "weeklyRejected": [
        28,
        49,
        49,
        12,
        49,
        9,
        13,
        22,
        49,
        16,
        35,
        32,
        32,
        45,
        19,
        33,
        12,
        48,
        40,
        34,
        49,
        48,
        49,
        43,
        5,
        49,
        30,
        13,
        38,
        10,
        8,
        30,
        20,
        29,
        26,
        22,
        32,
        37,
        2,
        19,
        23,
        19,
        38,
        17,
        33,
        47,
        29,
        10,
        16,
        4,
        0,
        34
      ],
      "weeklyHold": [
        31,
        6,
        27,
        1,
        23,
        27,
        23,
        4,
        6,
        20,
        28,
        38,
        27,
        38,
        4,
        34,
        19,
        13,
        5,
        8,
        41,
        49,
        49,
        29,
        27,
        21,
        48,
        0,
        27,
        21,
        48,
        0,
        0,
        26,
        26,
        21,
        23,
        5,
        49,
        28,
        0,
        36,
        0,
        46,
        32,
        10,
        27,
        14,
        12,
        49,
        0,
        45
      ],
      "weeklyBackordered": [
        28,
        24,
        36,
        12,
        49,
        32,
        17,
        19,
        5,
        5,
        0,
        45,
        49,
        8,
        3,
        27,
        3,
        20,
        0,
        41,
        49,
        36,
        45,
        49,
        37,
        49,
        5,
        49,
        21,
        18,
        26,
        30,
        22,
        43,
        49,
        0,
        49,
        10,
        36,
        49,
        0,
        48,
        25,
        37,
        4,
        0,
        24,
        26,
        30,
        29,
        29,
        1
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        7,
        35,
        40,
        11,
        20,
        22,
        7,
        2,
        48,
        13,
        42,
        0,
        19,
        25,
        49,
        0,
        43,
        3,
        0,
        44,
        49,
        48,
        44,
        18,
        20,
        16,
        27,
        49,
        4,
        49,
        18,
        10,
        45,
        0,
        39,
        49,
        47,
        18,
        49,
        49,
        0,
        15,
        26,
        44,
        27,
        0,
        15,
        49,
        24,
        30,
        10,
        27
      ],
      "weeklyRejected": [
        14,
        16,
        44,
        3,
        24,
        20,
        0,
        8,
        17,
        4,
        21,
        45,
        33,
        48,
        23,
        30,
        44,
        49,
        19,
        21,
        3,
        15,
        23,
        15,
        35,
        49,
        49,
        0,
        39,
        48,
        0,
        20,
        48,
        49,
        0,
        49,
        49,
        28,
        1,
        49,
        22,
        9,
        31,
        4,
        17,
        16,
        14,
        1,
        36,
        20,
        18,
        49
      ],
      "weeklyHold": [
        30,
        19,
        17,
        19,
        0,
        47,
        42,
        28,
        31,
        15,
        18,
        10,
        43,
        49,
        49,
        13,
        49,
        0,
        26,
        27,
        7,
        19,
        20,
        2,
        23,
        1,
        35,
        49,
        30,
        49,
        48,
        22,
        1,
        4,
        1,
        0,
        30,
        8,
        49,
        0,
        49,
        49,
        0,
        49,
        24,
        40,
        10,
        29,
        18,
        20,
        30,
        23
      ],
      "weeklyBackordered": [
        7,
        0,
        0,
        38,
        49,
        19,
        0,
        12,
        13,
        10,
        22,
        45,
        29,
        14,
        38,
        23,
        49,
        49,
        15,
        49,
        0,
        13,
        0,
        11,
        1,
        22,
        12,
        30,
        23,
        49,
        0,
        37,
        39,
        49,
        1,
        0,
        49,
        0,
        36,
        31,
        27,
        20,
        5,
        49,
        13,
        42,
        49,
        37,
        14,
        48,
        47,
        22
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        0,
        46,
        11,
        8,
        10,
        0,
        1,
        35,
        23,
        7,
        49,
        0,
        13,
        12,
        31,
        33,
        49,
        28,
        17,
        40,
        44,
        0,
        49,
        12,
        49,
        36,
        0,
        10,
        32,
        49,
        49,
        44,
        23,
        0,
        48,
        38,
        8,
        18,
        0,
        22,
        22,
        8,
        32,
        45,
        30,
        15,
        48,
        36,
        20,
        1,
        0,
        33
      ],
      "weeklyRejected": [
        22,
        16,
        2,
        39,
        43,
        0,
        49,
        47,
        49,
        1,
        43,
        49,
        49,
        22,
        47,
        8,
        19,
        49,
        42,
        18,
        9,
        49,
        25,
        49,
        49,
        49,
        29,
        11,
        22,
        22,
        43,
        18,
        48,
        49,
        39,
        31,
        49,
        34,
        39,
        32,
        48,
        48,
        8,
        0,
        48,
        32,
        49,
        49,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        40,
        34,
        41,
        1,
        49,
        1,
        49,
        25,
        45,
        18,
        33,
        19,
        11,
        18,
        25,
        33,
        0,
        48,
        4,
        0,
        13,
        24,
        40,
        5,
        44,
        39,
        18,
        4,
        36,
        0,
        37,
        6,
        0,
        49,
        24,
        38,
        10,
        0,
        46,
        3,
        4,
        36,
        5,
        15,
        23,
        28,
        0,
        7,
        13,
        0,
        49,
        49
      ],
      "weeklyBackordered": [
        26,
        49,
        21,
        49,
        49,
        12,
        44,
        37,
        4,
        34,
        32,
        49,
        3,
        37,
        0,
        6,
        27,
        43,
        31,
        44,
        13,
        0,
        19,
        23,
        12,
        1,
        4,
        2,
        30,
        20,
        21,
        49,
        48,
        0,
        0,
        0,
        1,
        11,
        5,
        22,
        47,
        30,
        21,
        49,
        19,
        24,
        45,
        27,
        0,
        17,
        46,
        45
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        14,
        49,
        24,
        7,
        1,
        21,
        25,
        49,
        10,
        0,
        21,
        0,
        34,
        13,
        1,
        28,
        40,
        0,
        49,
        0,
        48,
        33,
        25,
        49,
        49,
        36,
        0,
        0,
        4,
        9,
        33,
        12,
        41,
        28,
        20,
        32,
        18,
        15,
        20,
        49,
        28,
        0,
        49,
        16,
        42,
        19,
        0,
        43,
        12,
        26,
        49,
        23
      ],
      "weeklyRejected": [
        49,
        2,
        26,
        29,
        33,
        40,
        34,
        17,
        14,
        21,
        10,
        47,
        43,
        42,
        39,
        48,
        49,
        24,
        49,
        24,
        2,
        38,
        7,
        49,
        30,
        33,
        25,
        13,
        47,
        31,
        0,
        47,
        44,
        26,
        40,
        49,
        44,
        48,
        47,
        49,
        29,
        0,
        26,
        0,
        49,
        18,
        8,
        42,
        38,
        28,
        27,
        11
      ],
      "weeklyHold": [
        8,
        45,
        44,
        25,
        0,
        27,
        21,
        37,
        35,
        31,
        47,
        21,
        1,
        10,
        7,
        0,
        35,
        32,
        49,
        7,
        0,
        24,
        16,
        49,
        43,
        32,
        31,
        29,
        49,
        10,
        43,
        2,
        0,
        49,
        48,
        27,
        29,
        14,
        20,
        11,
        32,
        34,
        10,
        42,
        35,
        16,
        0,
        40,
        8,
        26,
        21,
        34
      ],
      "weeklyBackordered": [
        49,
        26,
        13,
        45,
        47,
        42,
        28,
        44,
        49,
        21,
        29,
        42,
        15,
        5,
        9,
        27,
        49,
        4,
        10,
        49,
        0,
        11,
        40,
        15,
        37,
        12,
        26,
        25,
        40,
        49,
        0,
        32,
        24,
        27,
        0,
        40,
        49,
        4,
        34,
        13,
        0,
        48,
        33,
        49,
        26,
        14,
        29,
        45,
        9,
        21,
        44,
        10
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        14,
        12,
        49,
        19,
        13,
        49,
        45,
        2,
        34,
        17,
        42,
        0,
        22,
        49,
        43,
        18,
        40,
        35,
        17,
        49,
        15,
        37,
        12,
        40,
        14,
        47,
        49,
        49,
        2,
        17,
        15,
        29,
        25,
        33,
        48,
        7,
        32,
        9,
        17,
        49,
        43,
        19,
        49,
        22,
        7,
        16,
        20,
        2,
        17,
        39,
        22,
        6
      ],
      "weeklyRejected": [
        31,
        41,
        49,
        25,
        32,
        0,
        35,
        34,
        49,
        7,
        16,
        41,
        36,
        0,
        48,
        37,
        0,
        46,
        28,
        22,
        3,
        26,
        19,
        23,
        47,
        49,
        30,
        8,
        47,
        40,
        7,
        47,
        41,
        49,
        0,
        5,
        37,
        26,
        10,
        36,
        42,
        44,
        42,
        32,
        32,
        13,
        0,
        14,
        0,
        49,
        0,
        0
      ],
      "weeklyHold": [
        44,
        25,
        31,
        26,
        49,
        40,
        5,
        2,
        30,
        49,
        47,
        1,
        48,
        7,
        38,
        23,
        5,
        34,
        0,
        0,
        21,
        35,
        17,
        29,
        34,
        42,
        0,
        16,
        35,
        14,
        48,
        7,
        9,
        35,
        36,
        18,
        24,
        0,
        34,
        23,
        33,
        34,
        0,
        49,
        35,
        30,
        0,
        23,
        39,
        21,
        49,
        23
      ],
      "weeklyBackordered": [
        42,
        16,
        17,
        0,
        43,
        42,
        8,
        49,
        15,
        49,
        49,
        13,
        17,
        49,
        25,
        30,
        12,
        23,
        0,
        0,
        49,
        0,
        22,
        19,
        26,
        11,
        30,
        49,
        26,
        27,
        40,
        42,
        0,
        49,
        0,
        0,
        49,
        11,
        38,
        1,
        0,
        48,
        11,
        5,
        38,
        0,
        19,
        0,
        11,
        40,
        49,
        25
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        9,
        47,
        24,
        22,
        49,
        36,
        1,
        33,
        45,
        49,
        43,
        6,
        14,
        45,
        1,
        0,
        49,
        26,
        2,
        37,
        20,
        47,
        4,
        13,
        12,
        47,
        28,
        0,
        1,
        28,
        16,
        28,
        19,
        17,
        26,
        42,
        47,
        48,
        8,
        49,
        49,
        47,
        49,
        15,
        49,
        6,
        13,
        34,
        13,
        0,
        38,
        48
      ],
      "weeklyRejected": [
        45,
        16,
        23,
        7,
        3,
        4,
        6,
        11,
        49,
        33,
        49,
        49,
        11,
        10,
        48,
        28,
        0,
        49,
        27,
        0,
        29,
        17,
        37,
        49,
        8,
        49,
        35,
        22,
        48,
        29,
        13,
        24,
        19,
        26,
        19,
        13,
        22,
        35,
        32,
        7,
        30,
        6,
        14,
        30,
        47,
        49,
        47,
        26,
        27,
        9,
        39,
        22
      ],
      "weeklyHold": [
        0,
        21,
        25,
        1,
        2,
        37,
        44,
        49,
        33,
        40,
        43,
        49,
        47,
        19,
        32,
        12,
        7,
        17,
        44,
        29,
        0,
        32,
        27,
        28,
        49,
        36,
        17,
        0,
        49,
        45,
        30,
        34,
        0,
        1,
        0,
        33,
        24,
        21,
        41,
        20,
        47,
        37,
        27,
        40,
        24,
        49,
        40,
        16,
        0,
        35,
        49,
        49
      ],
      "weeklyBackordered": [
        0,
        30,
        44,
        24,
        48,
        46,
        3,
        37,
        29,
        0,
        31,
        0,
        23,
        28,
        0,
        49,
        41,
        41,
        0,
        47,
        49,
        0,
        27,
        0,
        15,
        20,
        33,
        0,
        20,
        49,
        29,
        49,
        0,
        38,
        0,
        0,
        40,
        0,
        0,
        23,
        0,
        43,
        14,
        49,
        34,
        49,
        10,
        18,
        0,
        42,
        44,
        49
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        5,
        21,
        0,
        48,
        12,
        23,
        44,
        11,
        48,
        28,
        46,
        0,
        25,
        14,
        16,
        13,
        34,
        0,
        0,
        42,
        27,
        29,
        45,
        45,
        0,
        44,
        20,
        36,
        23,
        40,
        16,
        0,
        49,
        0,
        35,
        30,
        26,
        48,
        40,
        29,
        9,
        46,
        49,
        19,
        49,
        0,
        0,
        39,
        30,
        35,
        6,
        11
      ],
      "weeklyRejected": [
        47,
        49,
        37,
        43,
        49,
        15,
        0,
        26,
        24,
        21,
        49,
        26,
        0,
        26,
        48,
        20,
        49,
        20,
        47,
        42,
        37,
        49,
        18,
        43,
        3,
        11,
        49,
        18,
        30,
        24,
        44,
        23,
        48,
        49,
        25,
        27,
        25,
        49,
        6,
        15,
        44,
        18,
        45,
        0,
        44,
        0,
        13,
        48,
        6,
        49,
        19,
        49
      ],
      "weeklyHold": [
        13,
        36,
        49,
        26,
        49,
        43,
        0,
        20,
        0,
        8,
        12,
        40,
        27,
        39,
        18,
        17,
        2,
        12,
        23,
        12,
        0,
        19,
        23,
        0,
        28,
        36,
        25,
        5,
        22,
        47,
        37,
        44,
        0,
        49,
        13,
        46,
        4,
        8,
        1,
        45,
        0,
        33,
        49,
        27,
        26,
        10,
        46,
        49,
        0,
        28,
        47,
        49
      ],
      "weeklyBackordered": [
        0,
        49,
        0,
        45,
        34,
        6,
        0,
        49,
        19,
        49,
        49,
        49,
        7,
        49,
        33,
        39,
        49,
        34,
        2,
        16,
        39,
        49,
        37,
        0,
        11,
        5,
        44,
        0,
        49,
        28,
        16,
        41,
        47,
        29,
        22,
        14,
        25,
        0,
        23,
        26,
        7,
        48,
        14,
        49,
        28,
        2,
        47,
        7,
        49,
        49,
        32,
        33
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        49,
        44,
        28,
        28,
        35,
        34,
        32,
        49,
        0,
        31,
        42,
        14,
        0,
        14,
        1,
        0,
        49,
        33,
        25,
        4,
        26,
        0,
        14,
        26,
        10,
        14,
        9,
        26,
        14,
        23,
        0,
        0,
        17,
        49,
        48,
        41,
        15,
        42,
        21,
        49,
        17,
        42,
        0,
        11,
        49,
        21,
        7,
        48,
        24,
        11,
        49,
        31
      ],
      "weeklyRejected": [
        49,
        13,
        12,
        11,
        29,
        0,
        45,
        24,
        49,
        14,
        49,
        17,
        17,
        8,
        13,
        3,
        12,
        39,
        34,
        9,
        17,
        33,
        13,
        41,
        17,
        46,
        49,
        0,
        32,
        10,
        0,
        46,
        48,
        5,
        44,
        13,
        2,
        26,
        42,
        49,
        48,
        14,
        36,
        0,
        22,
        27,
        16,
        0,
        16,
        12,
        27,
        39
      ],
      "weeklyHold": [
        49,
        10,
        49,
        17,
        49,
        33,
        0,
        2,
        21,
        42,
        47,
        1,
        1,
        32,
        27,
        46,
        9,
        4,
        44,
        0,
        47,
        30,
        49,
        22,
        29,
        20,
        0,
        1,
        29,
        0,
        48,
        11,
        0,
        0,
        45,
        23,
        14,
        0,
        35,
        28,
        12,
        49,
        19,
        0,
        40,
        37,
        32,
        49,
        25,
        0,
        38,
        17
      ],
      "weeklyBackordered": [
        0,
        4,
        0,
        0,
        10,
        15,
        0,
        24,
        0,
        29,
        0,
        47,
        42,
        8,
        31,
        41,
        19,
        41,
        0,
        49,
        47,
        42,
        40,
        10,
        11,
        26,
        24,
        0,
        5,
        44,
        3,
        48,
        16,
        49,
        31,
        47,
        35,
        31,
        12,
        18,
        29,
        48,
        0,
        29,
        0,
        39,
        21,
        22,
        0,
        38,
        49,
        17
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        0,
        47,
        49,
        0,
        38,
        2,
        49,
        33,
        41,
        29,
        0,
        14,
        23,
        33,
        49,
        12,
        49,
        48,
        4,
        44,
        16,
        38,
        49,
        34,
        0,
        35,
        48,
        31,
        1,
        33,
        0,
        49,
        49,
        11,
        26,
        41,
        30,
        8,
        39,
        49,
        9,
        36,
        49,
        45,
        34,
        0,
        49,
        12,
        4,
        44,
        40,
        48
      ],
      "weeklyRejected": [
        36,
        21,
        33,
        47,
        24,
        40,
        49,
        11,
        27,
        1,
        49,
        49,
        49,
        0,
        29,
        0,
        11,
        46,
        27,
        11,
        27,
        49,
        26,
        30,
        47,
        32,
        36,
        49,
        37,
        0,
        38,
        42,
        17,
        49,
        31,
        26,
        21,
        2,
        1,
        25,
        42,
        34,
        3,
        21,
        49,
        29,
        10,
        44,
        0,
        49,
        12,
        35
      ],
      "weeklyHold": [
        18,
        0,
        5,
        25,
        4,
        40,
        49,
        2,
        49,
        25,
        47,
        7,
        42,
        1,
        34,
        12,
        0,
        42,
        9,
        49,
        24,
        49,
        0,
        25,
        31,
        48,
        40,
        13,
        20,
        5,
        48,
        2,
        11,
        0,
        2,
        13,
        49,
        7,
        23,
        15,
        16,
        35,
        0,
        32,
        0,
        34,
        49,
        30,
        16,
        15,
        49,
        49
      ],
      "weeklyBackordered": [
        40,
        30,
        0,
        43,
        49,
        29,
        31,
        30,
        38,
        8,
        49,
        15,
        5,
        27,
        19,
        7,
        47,
        40,
        1,
        14,
        35,
        28,
        0,
        49,
        34,
        14,
        21,
        15,
        8,
        34,
        24,
        49,
        48,
        31,
        0,
        0,
        19,
        1,
        40,
        30,
        24,
        37,
        46,
        40,
        13,
        38,
        37,
        18,
        0,
        43,
        47,
        16
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        19,
        49,
        16,
        26,
        29,
        0,
        1,
        48,
        19,
        42,
        11,
        0,
        26,
        36,
        1,
        19,
        49,
        16,
        16,
        49,
        0,
        28,
        49,
        0,
        30,
        34,
        22,
        12,
        1,
        49,
        3,
        21,
        43,
        0,
        10,
        35,
        0,
        23,
        26,
        49,
        20,
        14,
        49,
        47,
        49,
        0,
        27,
        31,
        47,
        37,
        10,
        28
      ],
      "weeklyRejected": [
        42,
        13,
        49,
        3,
        0,
        0,
        26,
        10,
        33,
        15,
        23,
        30,
        26,
        48,
        39,
        32,
        40,
        49,
        20,
        34,
        17,
        40,
        44,
        41,
        27,
        49,
        41,
        49,
        26,
        2,
        49,
        23,
        48,
        7,
        46,
        49,
        27,
        49,
        11,
        37,
        45,
        5,
        25,
        0,
        37,
        34,
        28,
        30,
        28,
        36,
        2,
        25
      ],
      "weeklyHold": [
        5,
        40,
        18,
        14,
        32,
        46,
        28,
        27,
        39,
        8,
        32,
        12,
        36,
        35,
        15,
        21,
        0,
        11,
        13,
        36,
        17,
        22,
        42,
        46,
        30,
        46,
        25,
        1,
        24,
        0,
        48,
        0,
        13,
        14,
        22,
        21,
        0,
        13,
        25,
        28,
        15,
        49,
        0,
        25,
        21,
        49,
        22,
        26,
        28,
        49,
        17,
        49
      ],
      "weeklyBackordered": [
        26,
        8,
        1,
        26,
        24,
        0,
        0,
        3,
        15,
        32,
        42,
        42,
        27,
        13,
        18,
        28,
        27,
        0,
        0,
        0,
        28,
        26,
        21,
        1,
        12,
        8,
        1,
        49,
        40,
        49,
        30,
        49,
        41,
        49,
        0,
        21,
        1,
        0,
        37,
        49,
        19,
        28,
        14,
        49,
        38,
        2,
        28,
        27,
        10,
        17,
        22,
        46
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        13,
        33,
        43,
        3,
        5,
        30,
        5,
        25,
        7,
        28,
        42,
        0,
        21,
        49,
        38,
        49,
        41,
        0,
        29,
        49,
        20,
        28,
        48,
        11,
        16,
        36,
        32,
        0,
        1,
        45,
        12,
        26,
        40,
        33,
        48,
        27,
        38,
        8,
        23,
        49,
        12,
        10,
        27,
        16,
        49,
        0,
        34,
        17,
        0,
        10,
        1,
        2
      ],
      "weeklyRejected": [
        43,
        12,
        10,
        21,
        27,
        21,
        49,
        16,
        49,
        1,
        22,
        49,
        34,
        23,
        43,
        19,
        30,
        43,
        18,
        0,
        20,
        43,
        28,
        49,
        26,
        49,
        35,
        18,
        41,
        22,
        0,
        11,
        0,
        16,
        33,
        49,
        47,
        49,
        1,
        49,
        26,
        26,
        21,
        9,
        49,
        31,
        35,
        0,
        0,
        40,
        11,
        38
      ],
      "weeklyHold": [
        49,
        11,
        13,
        12,
        38,
        32,
        40,
        2,
        0,
        26,
        47,
        32,
        48,
        30,
        49,
        29,
        49,
        48,
        0,
        21,
        31,
        49,
        49,
        23,
        26,
        27,
        7,
        18,
        39,
        0,
        48,
        0,
        20,
        49,
        19,
        26,
        20,
        0,
        31,
        39,
        30,
        21,
        15,
        49,
        33,
        43,
        38,
        32,
        13,
        47,
        15,
        4
      ],
      "weeklyBackordered": [
        0,
        0,
        11,
        31,
        48,
        28,
        15,
        39,
        36,
        0,
        43,
        38,
        25,
        15,
        27,
        49,
        27,
        49,
        0,
        46,
        37,
        16,
        0,
        6,
        9,
        31,
        1,
        24,
        1,
        43,
        13,
        49,
        22,
        49,
        13,
        13,
        45,
        0,
        49,
        27,
        48,
        48,
        1,
        40,
        49,
        28,
        49,
        49,
        0,
        49,
        33,
        37
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        36,
        1,
        22,
        25,
        15,
        4,
        1,
        15,
        41,
        23,
        33,
        11,
        6,
        21,
        24,
        49,
        36,
        11,
        33,
        13,
        15,
        20,
        30,
        12,
        19,
        32,
        21,
        14,
        9,
        20,
        0,
        17,
        38,
        35,
        48,
        14,
        18,
        48,
        2,
        49,
        10,
        30,
        44,
        32,
        23,
        0,
        16,
        22,
        19,
        34,
        0,
        0
      ],
      "weeklyRejected": [
        20,
        8,
        44,
        31,
        19,
        49,
        15,
        47,
        45,
        37,
        28,
        15,
        20,
        46,
        48,
        20,
        10,
        43,
        49,
        0,
        7,
        43,
        34,
        49,
        23,
        9,
        43,
        17,
        35,
        23,
        35,
        19,
        8,
        49,
        0,
        41,
        0,
        24,
        49,
        18,
        29,
        49,
        3,
        33,
        20,
        0,
        0,
        7,
        12,
        32,
        0,
        49
      ],
      "weeklyHold": [
        46,
        23,
        49,
        36,
        0,
        17,
        0,
        18,
        0,
        21,
        29,
        45,
        48,
        33,
        0,
        0,
        0,
        13,
        14,
        25,
        26,
        29,
        26,
        18,
        14,
        13,
        41,
        36,
        49,
        3,
        48,
        26,
        0,
        0,
        21,
        5,
        0,
        7,
        26,
        19,
        49,
        48,
        0,
        49,
        24,
        0,
        49,
        49,
        25,
        14,
        49,
        40
      ],
      "weeklyBackordered": [
        34,
        27,
        15,
        49,
        49,
        39,
        0,
        36,
        0,
        8,
        27,
        43,
        6,
        5,
        35,
        49,
        49,
        19,
        21,
        20,
        39,
        43,
        25,
        10,
        21,
        38,
        8,
        49,
        49,
        49,
        0,
        49,
        47,
        41,
        0,
        0,
        13,
        0,
        10,
        0,
        48,
        38,
        9,
        32,
        30,
        49,
        35,
        20,
        32,
        31,
        1,
        11
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        24,
        39,
        1,
        22,
        25,
        0,
        12,
        31,
        34,
        20,
        0,
        39,
        19,
        12,
        32,
        19,
        17,
        39,
        3,
        49,
        0,
        49,
        49,
        41,
        40,
        36,
        30,
        35,
        8,
        14,
        3,
        11,
        49,
        42,
        41,
        40,
        39,
        0,
        34,
        49,
        43,
        43,
        49,
        49,
        0,
        28,
        0,
        49,
        19,
        2,
        5,
        10
      ],
      "weeklyRejected": [
        43,
        4,
        36,
        24,
        37,
        5,
        40,
        10,
        8,
        34,
        16,
        20,
        8,
        48,
        29,
        33,
        19,
        49,
        47,
        14,
        31,
        15,
        49,
        33,
        16,
        49,
        44,
        8,
        33,
        36,
        44,
        47,
        20,
        12,
        25,
        35,
        31,
        26,
        26,
        6,
        48,
        49,
        3,
        49,
        26,
        0,
        0,
        24,
        0,
        49,
        2,
        49
      ],
      "weeklyHold": [
        12,
        30,
        36,
        1,
        46,
        30,
        18,
        18,
        26,
        32,
        35,
        29,
        1,
        23,
        23,
        0,
        30,
        29,
        26,
        48,
        35,
        42,
        22,
        49,
        27,
        37,
        21,
        30,
        49,
        49,
        38,
        6,
        24,
        26,
        48,
        41,
        26,
        6,
        49,
        48,
        48,
        22,
        8,
        24,
        49,
        8,
        43,
        49,
        9,
        37,
        49,
        15
      ],
      "weeklyBackordered": [
        15,
        0,
        0,
        23,
        38,
        46,
        23,
        28,
        7,
        49,
        49,
        18,
        8,
        43,
        23,
        1,
        43,
        49,
        21,
        13,
        45,
        25,
        10,
        28,
        1,
        15,
        14,
        34,
        15,
        6,
        39,
        20,
        42,
        42,
        14,
        0,
        2,
        5,
        11,
        29,
        27,
        13,
        0,
        44,
        18,
        28,
        8,
        5,
        19,
        37,
        49,
        19
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        2,
        49,
        23,
        30,
        1,
        22,
        9,
        38,
        48,
        48,
        40,
        1,
        27,
        49,
        31,
        39,
        42,
        16,
        10,
        25,
        47,
        41,
        49,
        25,
        0,
        43,
        29,
        35,
        43,
        17,
        10,
        11,
        35,
        11,
        32,
        49,
        28,
        13,
        49,
        18,
        21,
        4,
        42,
        49,
        49,
        3,
        0,
        0,
        13,
        33,
        33,
        38
      ],
      "weeklyRejected": [
        30,
        7,
        20,
        39,
        16,
        0,
        43,
        49,
        16,
        31,
        0,
        0,
        0,
        48,
        36,
        17,
        22,
        34,
        42,
        0,
        18,
        12,
        20,
        47,
        27,
        49,
        0,
        36,
        43,
        27,
        34,
        27,
        39,
        48,
        11,
        49,
        30,
        40,
        19,
        10,
        48,
        30,
        24,
        33,
        42,
        20,
        22,
        15,
        19,
        49,
        0,
        49
      ],
      "weeklyHold": [
        38,
        40,
        43,
        24,
        4,
        26,
        0,
        24,
        1,
        26,
        11,
        37,
        48,
        4,
        43,
        0,
        0,
        9,
        20,
        43,
        49,
        24,
        0,
        5,
        47,
        30,
        49,
        7,
        48,
        33,
        48,
        35,
        0,
        19,
        31,
        36,
        0,
        10,
        22,
        45,
        49,
        37,
        24,
        24,
        24,
        49,
        19,
        11,
        28,
        23,
        0,
        3
      ],
      "weeklyBackordered": [
        0,
        6,
        17,
        33,
        37,
        38,
        0,
        30,
        15,
        0,
        35,
        49,
        29,
        42,
        40,
        18,
        12,
        49,
        17,
        0,
        0,
        11,
        20,
        45,
        44,
        42,
        31,
        14,
        49,
        33,
        0,
        49,
        48,
        31,
        16,
        25,
        10,
        1,
        0,
        40,
        28,
        41,
        0,
        49,
        5,
        34,
        49,
        1,
        13,
        44,
        1,
        12
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        14,
        14,
        49,
        30,
        28,
        38,
        1,
        49,
        43,
        24,
        49,
        11,
        13,
        14,
        8,
        6,
        49,
        20,
        42,
        33,
        23,
        21,
        31,
        4,
        44,
        2,
        19,
        49,
        1,
        48,
        1,
        49,
        49,
        24,
        48,
        48,
        47,
        8,
        26,
        49,
        0,
        36,
        14,
        38,
        49,
        14,
        4,
        2,
        25,
        43,
        0,
        6
      ],
      "weeklyRejected": [
        49,
        0,
        22,
        5,
        44,
        8,
        45,
        0,
        27,
        3,
        24,
        34,
        28,
        48,
        42,
        40,
        49,
        37,
        42,
        49,
        0,
        43,
        0,
        29,
        40,
        49,
        33,
        33,
        13,
        7,
        31,
        47,
        47,
        49,
        38,
        49,
        14,
        40,
        3,
        9,
        27,
        40,
        49,
        18,
        42,
        39,
        0,
        19,
        0,
        17,
        29,
        47
      ],
      "weeklyHold": [
        49,
        33,
        29,
        24,
        15,
        40,
        0,
        2,
        18,
        24,
        21,
        25,
        1,
        49,
        27,
        11,
        23,
        0,
        25,
        17,
        0,
        44,
        49,
        38,
        29,
        3,
        19,
        17,
        39,
        0,
        39,
        14,
        0,
        5,
        9,
        29,
        0,
        3,
        49,
        49,
        13,
        36,
        36,
        16,
        49,
        49,
        49,
        35,
        49,
        14,
        49,
        9
      ],
      "weeklyBackordered": [
        22,
        6,
        27,
        32,
        19,
        46,
        49,
        46,
        0,
        4,
        35,
        5,
        47,
        15,
        34,
        39,
        34,
        49,
        0,
        11,
        40,
        0,
        20,
        18,
        22,
        2,
        43,
        0,
        2,
        49,
        0,
        39,
        11,
        36,
        5,
        28,
        30,
        7,
        38,
        49,
        13,
        48,
        15,
        49,
        39,
        2,
        10,
        49,
        8,
        44,
        33,
        26
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        36,
        49,
        26,
        21,
        29,
        32,
        14,
        28,
        33,
        24,
        49,
        0,
        38,
        25,
        36,
        22,
        27,
        9,
        19,
        17,
        10,
        9,
        49,
        34,
        34,
        47,
        43,
        0,
        20,
        49,
        5,
        0,
        48,
        0,
        48,
        39,
        22,
        6,
        49,
        31,
        47,
        49,
        49,
        43,
        49,
        15,
        25,
        6,
        22,
        13,
        0,
        16
      ],
      "weeklyRejected": [
        49,
        25,
        10,
        37,
        30,
        17,
        28,
        43,
        28,
        34,
        49,
        34,
        25,
        42,
        13,
        27,
        6,
        49,
        10,
        5,
        38,
        16,
        24,
        48,
        25,
        32,
        49,
        7,
        34,
        11,
        12,
        44,
        48,
        40,
        28,
        33,
        40,
        3,
        1,
        49,
        0,
        30,
        42,
        0,
        26,
        24,
        6,
        25,
        21,
        4,
        16,
        33
      ],
      "weeklyHold": [
        6,
        14,
        39,
        15,
        0,
        47,
        26,
        2,
        0,
        0,
        35,
        40,
        13,
        4,
        17,
        24,
        6,
        32,
        40,
        1,
        16,
        49,
        48,
        6,
        43,
        8,
        0,
        15,
        25,
        17,
        48,
        23,
        9,
        14,
        1,
        15,
        0,
        0,
        27,
        23,
        14,
        0,
        49,
        32,
        0,
        49,
        0,
        26,
        5,
        43,
        40,
        49
      ],
      "weeklyBackordered": [
        40,
        19,
        40,
        22,
        49,
        31,
        49,
        17,
        33,
        0,
        0,
        45,
        49,
        43,
        45,
        25,
        34,
        46,
        32,
        14,
        36,
        47,
        5,
        0,
        19,
        7,
        33,
        0,
        29,
        46,
        0,
        29,
        17,
        30,
        24,
        40,
        9,
        0,
        12,
        31,
        22,
        38,
        0,
        47,
        19,
        49,
        43,
        49,
        35,
        20,
        28,
        22
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyAccepted": [
        23,
        39,
        26,
        24,
        1,
        18,
        10,
        16,
        48,
        45,
        49,
        0,
        31,
        49,
        20,
        35,
        49,
        20,
        49,
        29,
        20,
        12,
        49,
        49,
        43,
        19,
        24,
        46,
        19,
        27,
        17,
        22,
        49,
        10,
        48,
        25,
        35,
        48,
        34,
        49,
        38,
        31,
        38,
        16,
        25,
        18,
        0,
        0,
        0,
        0,
        22,
        6
      ],
      "weeklyRejected": [
        30,
        49,
        20,
        1,
        49,
        22,
        24,
        25,
        49,
        32,
        0,
        21,
        31,
        37,
        42,
        49,
        49,
        35,
        46,
        23,
        0,
        40,
        0,
        10,
        29,
        45,
        27,
        20,
        41,
        48,
        29,
        33,
        17,
        24,
        29,
        49,
        28,
        43,
        30,
        49,
        48,
        36,
        47,
        28,
        39,
        45,
        3,
        0,
        35,
        18,
        19,
        43
      ],
      "weeklyHold": [
        21,
        41,
        42,
        18,
        13,
        48,
        44,
        25,
        0,
        49,
        11,
        10,
        48,
        30,
        36,
        0,
        12,
        5,
        49,
        23,
        5,
        49,
        24,
        10,
        35,
        36,
        30,
        31,
        49,
        15,
        48,
        17,
        15,
        0,
        48,
        31,
        5,
        14,
        17,
        39,
        32,
        22,
        19,
        49,
        32,
        22,
        0,
        25,
        0,
        30,
        34,
        1
      ],
      "weeklyBackordered": [
        49,
        48,
        0,
        4,
        37,
        46,
        34,
        28,
        39,
        0,
        32,
        0,
        13,
        8,
        36,
        33,
        49,
        49,
        10,
        25,
        19,
        0,
        26,
        38,
        32,
        10,
        24,
        20,
        25,
        46,
        9,
        49,
        29,
        35,
        0,
        32,
        38,
        48,
        18,
        28,
        19,
        0,
        19,
        0,
        27,
        41,
        49,
        8,
        27,
        19,
        49,
        22
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        14,
        49,
        21,
        47,
        33,
        29,
        1,
        49,
        48,
        16,
        0,
        13,
        36,
        19,
        49,
        18,
        36,
        15,
        24,
        0,
        4,
        22,
        19,
        18,
        49,
        47,
        12,
        28,
        25,
        49,
        49,
        15,
        32,
        26,
        48,
        2,
        25,
        21,
        49,
        26,
        0,
        23,
        2,
        41,
        49,
        29,
        11,
        0,
        19,
        40,
        7,
        27
      ],
      "weeklyRejected": [
        30,
        30,
        20,
        19,
        11,
        8,
        20,
        33,
        42,
        41,
        30,
        28,
        16,
        35,
        32,
        0,
        19,
        16,
        20,
        2,
        0,
        32,
        12,
        22,
        33,
        26,
        31,
        36,
        42,
        26,
        21,
        26,
        19,
        49,
        34,
        41,
        45,
        8,
        4,
        14,
        41,
        49,
        8,
        16,
        45,
        28,
        11,
        0,
        0,
        40,
        0,
        28
      ],
      "weeklyHold": [
        38,
        0,
        16,
        18,
        27,
        48,
        23,
        17,
        5,
        24,
        47,
        17,
        1,
        46,
        35,
        0,
        0,
        13,
        8,
        12,
        22,
        30,
        5,
        0,
        40,
        25,
        0,
        0,
        16,
        28,
        48,
        0,
        14,
        48,
        7,
        4,
        34,
        0,
        44,
        42,
        0,
        26,
        15,
        30,
        26,
        49,
        49,
        49,
        0,
        46,
        25,
        39
      ],
      "weeklyBackordered": [
        37,
        18,
        42,
        4,
        47,
        5,
        49,
        40,
        0,
        3,
        0,
        49,
        48,
        0,
        3,
        14,
        19,
        23,
        0,
        0,
        48,
        28,
        13,
        49,
        1,
        37,
        14,
        49,
        28,
        49,
        49,
        38,
        48,
        28,
        5,
        0,
        12,
        0,
        12,
        49,
        40,
        48,
        5,
        35,
        13,
        16,
        49,
        31,
        2,
        49,
        21,
        32
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyAccepted": [
        0,
        49,
        25,
        6,
        32,
        7,
        34,
        26,
        44,
        20,
        39,
        25,
        21,
        40,
        9,
        34,
        49,
        35,
        9,
        0,
        11,
        46,
        40,
        17,
        18,
        27,
        13,
        39,
        36,
        49,
        23,
        17,
        30,
        25,
        47,
        46,
        21,
        43,
        49,
        35,
        49,
        49,
        38,
        37,
        49,
        49,
        7,
        20,
        42,
        34,
        44,
        13
      ],
      "weeklyRejected": [
        32,
        28,
        47,
        36,
        36,
        26,
        34,
        5,
        31,
        33,
        49,
        32,
        30,
        48,
        34,
        16,
        25,
        21,
        42,
        13,
        49,
        49,
        31,
        26,
        11,
        49,
        21,
        43,
        46,
        23,
        30,
        21,
        37,
        18,
        13,
        18,
        17,
        31,
        1,
        31,
        22,
        49,
        5,
        29,
        49,
        6,
        30,
        42,
        17,
        49,
        0,
        20
      ],
      "weeklyHold": [
        0,
        16,
        28,
        12,
        49,
        31,
        27,
        2,
        0,
        37,
        31,
        7,
        1,
        29,
        15,
        32,
        4,
        25,
        0,
        5,
        20,
        24,
        49,
        23,
        39,
        48,
        35,
        0,
        49,
        8,
        48,
        0,
        0,
        18,
        0,
        23,
        34,
        0,
        28,
        0,
        3,
        36,
        0,
        26,
        39,
        42,
        49,
        33,
        0,
        0,
        16,
        9
      ],
      "weeklyBackordered": [
        17,
        33,
        7,
        9,
        49,
        4,
        14,
        45,
        49,
        6,
        31,
        4,
        37,
        44,
        40,
        35,
        20,
        49,
        0,
        35,
        49,
        35,
        18,
        31,
        22,
        17,
        37,
        19,
        45,
        10,
        49,
        49,
        22,
        49,
        0,
        0,
        4,
        0,
        14,
        30,
        18,
        35,
        13,
        38,
        15,
        14,
        18,
        17,
        0,
        13,
        40,
        16
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        3,
        49,
        16,
        12,
        21,
        11,
        7,
        44,
        24,
        16,
        40,
        27,
        25,
        7,
        31,
        37,
        38,
        0,
        0,
        23,
        0,
        0,
        5,
        43,
        21,
        7,
        15,
        24,
        20,
        19,
        22,
        23,
        41,
        38,
        48,
        42,
        35,
        13,
        12,
        15,
        2,
        34,
        48,
        41,
        34,
        17,
        25,
        21,
        12,
        10,
        2,
        11
      ],
      "weeklyRejected": [
        18,
        0,
        22,
        11,
        20,
        8,
        28,
        19,
        29,
        6,
        23,
        0,
        30,
        28,
        48,
        23,
        8,
        49,
        42,
        6,
        29,
        6,
        49,
        21,
        31,
        48,
        42,
        27,
        20,
        7,
        12,
        12,
        48,
        13,
        22,
        49,
        12,
        0,
        1,
        38,
        22,
        46,
        17,
        0,
        46,
        24,
        17,
        49,
        0,
        0,
        0,
        49
      ],
      "weeklyHold": [
        10,
        31,
        27,
        32,
        18,
        27,
        16,
        6,
        19,
        18,
        47,
        11,
        20,
        40,
        49,
        49,
        30,
        21,
        21,
        5,
        31,
        45,
        4,
        10,
        38,
        11,
        26,
        29,
        49,
        22,
        28,
        32,
        0,
        39,
        0,
        39,
        49,
        10,
        49,
        24,
        10,
        28,
        0,
        39,
        0,
        49,
        49,
        13,
        27,
        48,
        10,
        17
      ],
      "weeklyBackordered": [
        9,
        13,
        34,
        30,
        49,
        46,
        6,
        41,
        35,
        5,
        17,
        47,
        4,
        32,
        9,
        1,
        32,
        21,
        14,
        5,
        39,
        0,
        19,
        30,
        24,
        9,
        25,
        20,
        49,
        27,
        13,
        48,
        47,
        33,
        49,
        0,
        27,
        16,
        14,
        8,
        48,
        48,
        0,
        49,
        0,
        22,
        31,
        32,
        24,
        49,
        39,
        35
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        17,
        1,
        2,
        20,
        14,
        25,
        1,
        26,
        42,
        46,
        29,
        0,
        49,
        27,
        28,
        36,
        49,
        0,
        9,
        43,
        49,
        14,
        49,
        13,
        30,
        47,
        16,
        4,
        37,
        49,
        49,
        20,
        34,
        46,
        27,
        49,
        47,
        15,
        10,
        14,
        12,
        24,
        19,
        26,
        49,
        0,
        23,
        18,
        7,
        27,
        43,
        45
      ],
      "weeklyRejected": [
        26,
        13,
        31,
        25,
        29,
        0,
        11,
        43,
        1,
        28,
        12,
        42,
        26,
        2,
        2,
        13,
        29,
        49,
        0,
        41,
        19,
        13,
        26,
        27,
        14,
        21,
        49,
        7,
        15,
        5,
        27,
        29,
        42,
        34,
        44,
        37,
        49,
        32,
        1,
        24,
        48,
        16,
        29,
        22,
        49,
        41,
        43,
        0,
        18,
        37,
        8,
        47
      ],
      "weeklyHold": [
        26,
        2,
        49,
        15,
        29,
        16,
        49,
        24,
        6,
        2,
        28,
        13,
        43,
        24,
        49,
        49,
        26,
        48,
        23,
        0,
        0,
        42,
        27,
        22,
        17,
        48,
        0,
        17,
        8,
        27,
        27,
        18,
        2,
        43,
        29,
        1,
        43,
        0,
        49,
        10,
        38,
        32,
        10,
        20,
        40,
        1,
        19,
        29,
        0,
        44,
        39,
        24
      ],
      "weeklyBackordered": [
        42,
        21,
        26,
        3,
        33,
        33,
        32,
        31,
        49,
        0,
        16,
        36,
        3,
        23,
        19,
        29,
        9,
        34,
        0,
        27,
        32,
        44,
        0,
        28,
        15,
        13,
        7,
        0,
        17,
        49,
        49,
        32,
        22,
        24,
        0,
        30,
        27,
        10,
        0,
        6,
        48,
        17,
        34,
        35,
        17,
        27,
        49,
        37,
        36,
        18,
        22,
        2
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        48,
        49,
        28,
        36,
        11,
        0,
        31,
        49,
        43,
        6,
        48,
        9,
        7,
        40,
        45,
        25,
        35,
        37,
        10,
        0,
        49,
        40,
        29,
        43,
        6,
        41,
        39,
        46,
        1,
        49,
        41,
        15,
        29,
        6,
        48,
        25,
        47,
        15,
        25,
        49,
        29,
        30,
        49,
        12,
        49,
        31,
        12,
        24,
        49,
        21,
        21,
        24
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        47,
        45,
        46,
        22,
        14,
        45,
        4,
        17,
        0,
        27,
        48,
        48,
        0,
        19,
        19,
        31,
        15,
        49,
        49,
        41,
        10,
        6,
        36,
        49,
        30,
        28,
        2,
        23,
        32,
        48,
        18,
        40,
        49,
        49,
        20,
        12,
        36,
        39,
        16,
        48,
        0,
        49,
        24,
        9,
        49,
        1,
        24,
        14,
        37
      ],
      "weeklyHold": [
        49,
        21,
        16,
        28,
        19,
        3,
        20,
        26,
        17,
        29,
        47,
        33,
        36,
        27,
        19,
        49,
        1,
        12,
        0,
        28,
        9,
        24,
        49,
        11,
        4,
        38,
        15,
        4,
        41,
        0,
        16,
        0,
        21,
        41,
        43,
        21,
        49,
        25,
        42,
        43,
        40,
        49,
        1,
        46,
        32,
        13,
        36,
        45,
        19,
        37,
        47,
        23
      ],
      "weeklyBackordered": [
        21,
        27,
        5,
        38,
        45,
        20,
        11,
        45,
        44,
        33,
        0,
        41,
        30,
        0,
        3,
        1,
        1,
        49,
        15,
        0,
        32,
        42,
        49,
        42,
        15,
        34,
        1,
        49,
        9,
        49,
        20,
        23,
        48,
        40,
        0,
        0,
        38,
        15,
        20,
        8,
        24,
        22,
        33,
        49,
        27,
        37,
        49,
        0,
        36,
        49,
        42,
        16
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyAccepted": [
        0,
        49,
        0,
        5,
        36,
        0,
        1,
        2,
        0,
        0,
        37,
        6,
        49,
        49,
        21,
        49,
        24,
        46,
        8,
        0,
        21,
        38,
        26,
        39,
        32,
        43,
        23,
        13,
        26,
        41,
        27,
        0,
        47,
        5,
        48,
        26,
        47,
        24,
        49,
        45,
        12,
        49,
        35,
        37,
        48,
        47,
        12,
        20,
        0,
        17,
        18,
        22
      ],
      "weeklyRejected": [
        45,
        0,
        19,
        34,
        11,
        0,
        49,
        18,
        16,
        1,
        24,
        40,
        40,
        21,
        26,
        40,
        30,
        49,
        5,
        14,
        18,
        36,
        40,
        49,
        13,
        34,
        18,
        25,
        24,
        15,
        28,
        43,
        43,
        49,
        49,
        49,
        30,
        9,
        1,
        15,
        24,
        46,
        27,
        23,
        0,
        27,
        44,
        16,
        3,
        27,
        0,
        25
      ],
      "weeklyHold": [
        28,
        23,
        25,
        13,
        24,
        39,
        32,
        28,
        12,
        1,
        24,
        35,
        25,
        49,
        33,
        21,
        49,
        0,
        0,
        11,
        14,
        49,
        0,
        16,
        26,
        37,
        0,
        10,
        27,
        13,
        41,
        38,
        0,
        21,
        23,
        4,
        0,
        7,
        21,
        31,
        38,
        42,
        34,
        24,
        0,
        28,
        24,
        0,
        0,
        49,
        43,
        34
      ],
      "weeklyBackordered": [
        49,
        32,
        17,
        20,
        46,
        40,
        49,
        30,
        35,
        23,
        6,
        26,
        19,
        30,
        49,
        32,
        21,
        20,
        8,
        23,
        33,
        0,
        16,
        16,
        12,
        5,
        40,
        35,
        3,
        39,
        4,
        19,
        18,
        49,
        0,
        0,
        32,
        0,
        23,
        49,
        17,
        48,
        19,
        49,
        4,
        42,
        47,
        45,
        23,
        0,
        49,
        11
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        27,
        43,
        49,
        27,
        42,
        48,
        5,
        17,
        17,
        24,
        47,
        40,
        26,
        30,
        12,
        0,
        43,
        8,
        23,
        2,
        0,
        43,
        0,
        37,
        24,
        38,
        9,
        46,
        34,
        49,
        49,
        22,
        32,
        8,
        48,
        49,
        27,
        48,
        31,
        49,
        29,
        23,
        49,
        41,
        49,
        30,
        0,
        49,
        14,
        2,
        49,
        48
      ],
      "weeklyRejected": [
        37,
        0,
        33,
        0,
        35,
        0,
        36,
        28,
        12,
        13,
        0,
        35,
        36,
        16,
        33,
        39,
        27,
        0,
        27,
        0,
        28,
        47,
        3,
        27,
        10,
        16,
        44,
        13,
        27,
        14,
        23,
        47,
        48,
        28,
        0,
        33,
        25,
        5,
        1,
        20,
        28,
        24,
        49,
        3,
        49,
        9,
        37,
        38,
        9,
        46,
        10,
        0
      ],
      "weeklyHold": [
        0,
        49,
        44,
        12,
        49,
        45,
        16,
        18,
        0,
        40,
        6,
        9,
        19,
        32,
        17,
        11,
        36,
        48,
        49,
        0,
        29,
        33,
        24,
        0,
        11,
        39,
        14,
        7,
        6,
        8,
        48,
        48,
        2,
        24,
        18,
        28,
        49,
        21,
        43,
        0,
        30,
        14,
        33,
        0,
        30,
        22,
        49,
        0,
        0,
        39,
        0,
        43
      ],
      "weeklyBackordered": [
        31,
        49,
        0,
        27,
        32,
        46,
        25,
        49,
        21,
        16,
        30,
        0,
        43,
        49,
        20,
        21,
        49,
        27,
        0,
        1,
        44,
        0,
        0,
        24,
        12,
        29,
        32,
        1,
        23,
        40,
        45,
        0,
        45,
        49,
        6,
        0,
        23,
        48,
        0,
        36,
        21,
        46,
        22,
        49,
        0,
        17,
        47,
        4,
        23,
        40,
        34,
        17
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        26,
        18,
        22,
        44,
        8,
        43,
        13,
        38,
        32,
        32,
        36,
        14,
        24,
        24,
        11,
        21,
        45,
        3,
        0,
        49,
        0,
        34,
        37,
        0,
        17,
        37,
        49,
        0,
        2,
        23,
        20,
        0,
        30,
        0,
        27,
        7,
        47,
        25,
        49,
        49,
        24,
        49,
        21,
        46,
        37,
        2,
        8,
        17,
        0,
        19,
        31,
        48
      ],
      "weeklyRejected": [
        49,
        19,
        22,
        5,
        45,
        18,
        49,
        35,
        23,
        27,
        19,
        0,
        49,
        43,
        22,
        20,
        13,
        39,
        43,
        12,
        7,
        44,
        3,
        44,
        4,
        48,
        20,
        19,
        48,
        22,
        7,
        41,
        47,
        4,
        7,
        33,
        9,
        24,
        31,
        30,
        27,
        49,
        44,
        18,
        49,
        49,
        0,
        8,
        37,
        49,
        0,
        15
      ],
      "weeklyHold": [
        23,
        5,
        35,
        12,
        13,
        30,
        0,
        40,
        0,
        3,
        42,
        18,
        19,
        6,
        46,
        49,
        0,
        4,
        0,
        18,
        34,
        12,
        34,
        21,
        3,
        8,
        21,
        34,
        21,
        48,
        34,
        48,
        43,
        13,
        14,
        46,
        31,
        14,
        42,
        49,
        43,
        24,
        16,
        18,
        49,
        3,
        12,
        49,
        0,
        43,
        49,
        34
      ],
      "weeklyBackordered": [
        9,
        49,
        37,
        11,
        49,
        21,
        45,
        23,
        25,
        22,
        40,
        27,
        3,
        49,
        0,
        17,
        41,
        28,
        0,
        46,
        37,
        35,
        40,
        0,
        11,
        20,
        1,
        48,
        20,
        44,
        49,
        24,
        48,
        49,
        0,
        26,
        5,
        12,
        21,
        31,
        24,
        24,
        34,
        49,
        0,
        0,
        9,
        2,
        21,
        34,
        26,
        45
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        24,
        33,
        17,
        0,
        1,
        15,
        22,
        22,
        23,
        49,
        35,
        0,
        5,
        7,
        42,
        26,
        49,
        30,
        0,
        49,
        34,
        48,
        24,
        1,
        32,
        28,
        35,
        49,
        15,
        36,
        20,
        0,
        42,
        25,
        36,
        28,
        47,
        48,
        11,
        49,
        8,
        6,
        47,
        36,
        46,
        44,
        24,
        5,
        43,
        49,
        18,
        42
      ],
      "weeklyRejected": [
        42,
        22,
        36,
        8,
        49,
        21,
        19,
        28,
        36,
        1,
        37,
        0,
        8,
        18,
        46,
        49,
        30,
        10,
        40,
        16,
        18,
        28,
        2,
        32,
        35,
        27,
        7,
        15,
        29,
        47,
        19,
        47,
        40,
        2,
        0,
        49,
        33,
        36,
        49,
        27,
        27,
        26,
        37,
        7,
        38,
        35,
        0,
        28,
        7,
        28,
        26,
        49
      ],
      "weeklyHold": [
        8,
        11,
        26,
        1,
        49,
        28,
        35,
        22,
        30,
        31,
        0,
        31,
        5,
        19,
        6,
        25,
        5,
        29,
        3,
        21,
        0,
        17,
        16,
        33,
        43,
        9,
        35,
        28,
        38,
        4,
        48,
        32,
        5,
        49,
        48,
        17,
        25,
        0,
        27,
        38,
        21,
        49,
        33,
        37,
        34,
        32,
        23,
        45,
        0,
        49,
        5,
        19
      ],
      "weeklyBackordered": [
        7,
        49,
        9,
        23,
        49,
        35,
        28,
        29,
        32,
        29,
        36,
        15,
        49,
        11,
        37,
        30,
        45,
        49,
        25,
        49,
        23,
        33,
        49,
        41,
        14,
        33,
        1,
        49,
        5,
        42,
        23,
        28,
        36,
        28,
        0,
        0,
        29,
        1,
        22,
        0,
        19,
        48,
        49,
        49,
        23,
        45,
        39,
        21,
        0,
        31,
        49,
        38
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        8,
        26,
        25,
        27,
        18,
        14,
        16,
        2,
        48,
        2,
        0,
        0,
        46,
        24,
        6,
        15,
        49,
        4,
        0,
        24,
        5,
        22,
        13,
        38,
        49,
        47,
        20,
        38,
        1,
        30,
        0,
        24,
        38,
        26,
        48,
        4,
        47,
        48,
        49,
        9,
        37,
        13,
        45,
        49,
        36,
        38,
        17,
        6,
        0,
        24,
        49,
        29
      ],
      "weeklyRejected": [
        25,
        41,
        49,
        15,
        25,
        0,
        33,
        33,
        16,
        48,
        31,
        0,
        23,
        47,
        46,
        32,
        49,
        11,
        15,
        49,
        14,
        49,
        49,
        0,
        32,
        38,
        22,
        8,
        32,
        24,
        11,
        33,
        42,
        23,
        25,
        49,
        18,
        31,
        2,
        41,
        33,
        11,
        2,
        35,
        42,
        27,
        28,
        41,
        7,
        49,
        25,
        35
      ],
      "weeklyHold": [
        14,
        0,
        28,
        17,
        49,
        38,
        0,
        26,
        20,
        13,
        24,
        25,
        8,
        1,
        23,
        15,
        0,
        10,
        30,
        0,
        42,
        18,
        10,
        29,
        23,
        48,
        0,
        31,
        49,
        0,
        48,
        0,
        32,
        10,
        41,
        25,
        10,
        9,
        14,
        16,
        5,
        49,
        0,
        49,
        28,
        3,
        0,
        0,
        4,
        0,
        19,
        23
      ],
      "weeklyBackordered": [
        39,
        0,
        43,
        18,
        37,
        33,
        48,
        43,
        0,
        19,
        32,
        41,
        42,
        49,
        47,
        11,
        24,
        37,
        45,
        15,
        49,
        22,
        6,
        9,
        6,
        20,
        49,
        49,
        49,
        19,
        44,
        49,
        44,
        20,
        0,
        0,
        20,
        11,
        49,
        1,
        0,
        48,
        3,
        36,
        23,
        0,
        31,
        0,
        49,
        40,
        2,
        1
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        45,
        22,
        31,
        15,
        49,
        0,
        13,
        39,
        34,
        36,
        49,
        0,
        16,
        25,
        3,
        6,
        49,
        36,
        8,
        10,
        4,
        30,
        35,
        29,
        20,
        33,
        12,
        37,
        16,
        39,
        42,
        2,
        49,
        0,
        48,
        49,
        26,
        37,
        49,
        29,
        16,
        38,
        38,
        41,
        37,
        0,
        0,
        2,
        48,
        0,
        11,
        35
      ],
      "weeklyRejected": [
        9,
        9,
        9,
        47,
        39,
        12,
        45,
        41,
        37,
        7,
        43,
        31,
        10,
        48,
        46,
        12,
        0,
        20,
        10,
        17,
        49,
        35,
        11,
        49,
        20,
        13,
        49,
        27,
        5,
        15,
        25,
        20,
        41,
        25,
        27,
        21,
        28,
        17,
        6,
        11,
        7,
        38,
        44,
        17,
        49,
        18,
        43,
        0,
        0,
        23,
        0,
        31
      ],
      "weeklyHold": [
        49,
        49,
        22,
        22,
        47,
        42,
        25,
        17,
        18,
        15,
        46,
        38,
        25,
        6,
        0,
        44,
        12,
        48,
        49,
        16,
        0,
        45,
        22,
        12,
        3,
        15,
        14,
        3,
        47,
        18,
        36,
        23,
        0,
        20,
        30,
        10,
        34,
        0,
        27,
        49,
        38,
        35,
        37,
        43,
        49,
        48,
        1,
        26,
        17,
        7,
        0,
        16
      ],
      "weeklyBackordered": [
        35,
        18,
        0,
        49,
        49,
        27,
        42,
        21,
        40,
        0,
        0,
        49,
        18,
        0,
        7,
        32,
        16,
        34,
        0,
        0,
        3,
        2,
        5,
        33,
        8,
        8,
        45,
        11,
        47,
        49,
        31,
        49,
        48,
        49,
        45,
        32,
        9,
        10,
        39,
        45,
        25,
        18,
        0,
        49,
        18,
        44,
        6,
        0,
        21,
        27,
        16,
        7
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        5,
        1,
        15,
        0,
        24,
        12,
        49,
        26,
        48,
        0,
        18,
        12,
        49,
        43,
        1,
        5,
        39,
        11,
        17,
        0,
        11,
        38,
        31,
        49,
        28,
        40,
        0,
        19,
        7,
        49,
        30,
        20,
        49,
        32,
        45,
        48,
        35,
        21,
        4,
        27,
        10,
        16,
        49,
        29,
        8,
        14,
        0,
        0,
        49,
        42,
        49,
        28
      ],
      "weeklyRejected": [
        49,
        34,
        41,
        0,
        22,
        18,
        0,
        19,
        42,
        42,
        34,
        0,
        47,
        9,
        36,
        8,
        0,
        49,
        49,
        13,
        45,
        27,
        14,
        49,
        16,
        7,
        24,
        0,
        42,
        34,
        36,
        6,
        32,
        49,
        29,
        49,
        45,
        42,
        39,
        2,
        8,
        33,
        0,
        6,
        49,
        43,
        0,
        26,
        12,
        36,
        9,
        27
      ],
      "weeklyHold": [
        0,
        49,
        32,
        1,
        28,
        21,
        20,
        2,
        16,
        28,
        47,
        31,
        1,
        37,
        15,
        31,
        25,
        38,
        39,
        22,
        34,
        26,
        25,
        40,
        26,
        45,
        29,
        47,
        34,
        13,
        33,
        48,
        8,
        40,
        16,
        41,
        49,
        0,
        16,
        5,
        3,
        34,
        10,
        44,
        30,
        49,
        49,
        20,
        10,
        0,
        39,
        26
      ],
      "weeklyBackordered": [
        49,
        19,
        4,
        45,
        41,
        42,
        16,
        46,
        49,
        19,
        32,
        20,
        3,
        49,
        0,
        34,
        22,
        44,
        0,
        40,
        31,
        42,
        31,
        0,
        1,
        1,
        49,
        33,
        2,
        18,
        17,
        48,
        34,
        14,
        7,
        15,
        14,
        0,
        49,
        9,
        30,
        48,
        16,
        29,
        16,
        36,
        49,
        4,
        0,
        44,
        43,
        4
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        28,
        35,
        10,
        30,
        41,
        31,
        1,
        49,
        34,
        24,
        44,
        0,
        31,
        37,
        17,
        16,
        29,
        15,
        9,
        0,
        49,
        29,
        49,
        40,
        38,
        47,
        0,
        49,
        1,
        9,
        38,
        17,
        33,
        9,
        33,
        15,
        47,
        0,
        3,
        41,
        38,
        27,
        38,
        46,
        22,
        18,
        0,
        12,
        21,
        49,
        37,
        0
      ],
      "weeklyRejected": [
        44,
        5,
        13,
        47,
        20,
        19,
        29,
        49,
        47,
        34,
        43,
        29,
        49,
        48,
        48,
        22,
        32,
        30,
        25,
        11,
        19,
        36,
        31,
        44,
        39,
        44,
        27,
        9,
        48,
        22,
        44,
        20,
        33,
        7,
        45,
        49,
        45,
        41,
        17,
        44,
        32,
        30,
        39,
        49,
        40,
        24,
        19,
        38,
        0,
        33,
        0,
        16
      ],
      "weeklyHold": [
        48,
        9,
        36,
        39,
        0,
        26,
        33,
        47,
        22,
        49,
        6,
        40,
        1,
        13,
        36,
        25,
        12,
        0,
        35,
        11,
        19,
        0,
        47,
        41,
        25,
        38,
        49,
        0,
        33,
        4,
        48,
        9,
        0,
        11,
        36,
        49,
        27,
        0,
        9,
        30,
        42,
        36,
        8,
        0,
        35,
        35,
        43,
        36,
        0,
        0,
        25,
        15
      ],
      "weeklyBackordered": [
        49,
        32,
        48,
        49,
        49,
        26,
        47,
        49,
        32,
        34,
        0,
        30,
        49,
        0,
        21,
        45,
        25,
        49,
        3,
        18,
        31,
        0,
        0,
        49,
        19,
        49,
        11,
        49,
        49,
        25,
        23,
        23,
        40,
        43,
        21,
        0,
        1,
        7,
        14,
        45,
        46,
        47,
        13,
        49,
        0,
        18,
        49,
        8,
        3,
        49,
        47,
        1
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        23,
        27,
        24,
        24,
        33,
        4,
        41,
        49,
        48,
        0,
        28,
        11,
        40,
        49,
        33,
        13,
        46,
        34,
        48,
        49,
        49,
        35,
        35,
        22,
        35,
        42,
        14,
        9,
        1,
        38,
        7,
        1,
        2,
        7,
        6,
        18,
        35,
        17,
        24,
        49,
        49,
        15,
        49,
        42,
        43,
        17,
        0,
        41,
        0,
        43,
        9,
        7
      ],
      "weeklyRejected": [
        28,
        8,
        30,
        22,
        13,
        26,
        47,
        34,
        49,
        36,
        49,
        42,
        42,
        35,
        48,
        30,
        0,
        47,
        25,
        37,
        9,
        25,
        3,
        15,
        49,
        48,
        46,
        3,
        48,
        22,
        1,
        47,
        48,
        24,
        49,
        27,
        21,
        13,
        28,
        44,
        44,
        49,
        5,
        0,
        26,
        10,
        2,
        20,
        0,
        14,
        16,
        30
      ],
      "weeklyHold": [
        49,
        32,
        40,
        7,
        14,
        11,
        38,
        2,
        22,
        29,
        19,
        49,
        1,
        32,
        24,
        31,
        0,
        16,
        0,
        34,
        30,
        31,
        22,
        49,
        24,
        47,
        26,
        12,
        42,
        9,
        48,
        29,
        0,
        21,
        28,
        24,
        40,
        0,
        49,
        49,
        26,
        42,
        12,
        33,
        26,
        49,
        2,
        49,
        12,
        14,
        33,
        23
      ],
      "weeklyBackordered": [
        15,
        7,
        0,
        38,
        49,
        25,
        17,
        25,
        24,
        0,
        21,
        18,
        16,
        24,
        0,
        46,
        7,
        47,
        0,
        0,
        39,
        23,
        16,
        25,
        6,
        27,
        49,
        33,
        20,
        21,
        14,
        37,
        3,
        24,
        0,
        8,
        1,
        0,
        0,
        4,
        11,
        30,
        14,
        44,
        17,
        5,
        49,
        29,
        0,
        49,
        28,
        10
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        6,
        49,
        11,
        46,
        18,
        18,
        13,
        16,
        32,
        0,
        26,
        5,
        19,
        49,
        1,
        34,
        49,
        0,
        17,
        0,
        35,
        0,
        0,
        10,
        0,
        38,
        26,
        31,
        31,
        49,
        2,
        18,
        41,
        0,
        48,
        40,
        36,
        18,
        49,
        46,
        0,
        49,
        14,
        33,
        16,
        32,
        8,
        49,
        49,
        29,
        0,
        31
      ],
      "weeklyRejected": [
        49,
        0,
        43,
        21,
        33,
        0,
        25,
        44,
        33,
        16,
        49,
        35,
        5,
        31,
        35,
        0,
        35,
        2,
        46,
        26,
        49,
        49,
        21,
        27,
        12,
        0,
        49,
        13,
        11,
        48,
        11,
        43,
        9,
        49,
        14,
        49,
        3,
        40,
        1,
        0,
        31,
        25,
        40,
        0,
        49,
        49,
        5,
        49,
        0,
        28,
        0,
        6
      ],
      "weeklyHold": [
        23,
        30,
        7,
        20,
        39,
        34,
        3,
        45,
        26,
        8,
        0,
        1,
        37,
        12,
        26,
        23,
        0,
        1,
        23,
        0,
        25,
        33,
        12,
        0,
        0,
        35,
        38,
        28,
        38,
        13,
        48,
        0,
        0,
        27,
        48,
        0,
        11,
        29,
        36,
        31,
        33,
        31,
        9,
        42,
        31,
        2,
        49,
        35,
        0,
        49,
        11,
        34
      ],
      "weeklyBackordered": [
        38,
        49,
        32,
        42,
        44,
        3,
        49,
        1,
        39,
        15,
        20,
        49,
        36,
        47,
        34,
        35,
        31,
        4,
        0,
        36,
        20,
        27,
        0,
        12,
        27,
        17,
        49,
        2,
        31,
        31,
        41,
        24,
        48,
        49,
        48,
        0,
        49,
        36,
        6,
        2,
        13,
        48,
        39,
        49,
        0,
        24,
        23,
        1,
        26,
        46,
        10,
        6
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyAccepted": [
        0,
        37,
        0,
        20,
        1,
        10,
        40,
        34,
        18,
        9,
        37,
        4,
        28,
        35,
        10,
        22,
        27,
        44,
        12,
        10,
        0,
        7,
        26,
        36,
        24,
        25,
        48,
        43,
        23,
        49,
        49,
        0,
        15,
        15,
        34,
        7,
        28,
        25,
        49,
        39,
        46,
        38,
        4,
        49,
        38,
        49,
        7,
        29,
        0,
        21,
        43,
        4
      ],
      "weeklyRejected": [
        49,
        0,
        15,
        0,
        49,
        19,
        49,
        23,
        41,
        1,
        27,
        0,
        27,
        46,
        26,
        31,
        24,
        38,
        13,
        30,
        7,
        38,
        44,
        0,
        9,
        39,
        46,
        23,
        48,
        3,
        3,
        41,
        48,
        28,
        33,
        29,
        33,
        18,
        49,
        12,
        22,
        49,
        49,
        6,
        29,
        13,
        0,
        14,
        0,
        49,
        0,
        28
      ],
      "weeklyHold": [
        49,
        6,
        42,
        12,
        49,
        23,
        0,
        10,
        16,
        4,
        16,
        49,
        34,
        40,
        17,
        2,
        22,
        36,
        0,
        26,
        11,
        30,
        37,
        14,
        0,
        48,
        27,
        0,
        49,
        21,
        48,
        2,
        0,
        49,
        36,
        18,
        0,
        30,
        44,
        49,
        0,
        39,
        6,
        0,
        30,
        28,
        43,
        34,
        0,
        24,
        49,
        33
      ],
      "weeklyBackordered": [
        1,
        21,
        20,
        3,
        13,
        46,
        47,
        43,
        32,
        30,
        16,
        24,
        49,
        15,
        20,
        38,
        38,
        23,
        0,
        23,
        15,
        46,
        14,
        0,
        17,
        19,
        35,
        35,
        20,
        41,
        47,
        1,
        43,
        49,
        0,
        0,
        8,
        15,
        28,
        18,
        41,
        42,
        22,
        45,
        42,
        12,
        6,
        14,
        0,
        49,
        49,
        25
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        0,
        40,
        33,
        4,
        42,
        32,
        28,
        28,
        48,
        49,
        19,
        12,
        7,
        0,
        22,
        0,
        33,
        17,
        0,
        24,
        8,
        8,
        34,
        30,
        0,
        32,
        37,
        23,
        1,
        26,
        47,
        12,
        39,
        15,
        19,
        21,
        30,
        18,
        46,
        34,
        37,
        49,
        35,
        49,
        49,
        48,
        25,
        0,
        46,
        29,
        20,
        48
      ],
      "weeklyRejected": [
        49,
        4,
        27,
        27,
        30,
        40,
        31,
        18,
        32,
        29,
        30,
        36,
        0,
        48,
        48,
        1,
        19,
        20,
        29,
        34,
        41,
        45,
        49,
        15,
        33,
        48,
        49,
        22,
        21,
        26,
        30,
        33,
        43,
        5,
        26,
        34,
        18,
        18,
        2,
        49,
        46,
        29,
        36,
        12,
        47,
        27,
        0,
        49,
        24,
        49,
        14,
        16
      ],
      "weeklyHold": [
        18,
        43,
        37,
        1,
        22,
        26,
        14,
        6,
        12,
        23,
        40,
        38,
        48,
        17,
        28,
        14,
        22,
        35,
        49,
        49,
        0,
        0,
        10,
        2,
        26,
        36,
        42,
        5,
        49,
        46,
        35,
        0,
        22,
        41,
        33,
        31,
        40,
        0,
        24,
        49,
        40,
        33,
        12,
        33,
        33,
        49,
        37,
        24,
        44,
        0,
        13,
        30
      ],
      "weeklyBackordered": [
        20,
        28,
        13,
        49,
        43,
        46,
        34,
        33,
        20,
        1,
        35,
        48,
        33,
        15,
        5,
        25,
        40,
        37,
        28,
        0,
        33,
        36,
        11,
        39,
        21,
        42,
        1,
        39,
        5,
        37,
        8,
        15,
        44,
        0,
        22,
        0,
        49,
        0,
        32,
        0,
        6,
        48,
        9,
        33,
        30,
        35,
        49,
        4,
        26,
        49,
        38,
        23
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        37,
        43,
        33,
        34,
        6,
        24,
        49,
        30,
        48,
        29,
        44,
        16,
        16,
        24,
        4,
        49,
        26,
        8,
        0,
        33,
        0,
        34,
        19,
        49,
        48,
        19,
        24,
        25,
        14,
        2,
        28,
        4,
        30,
        0,
        48,
        17,
        21,
        48,
        0,
        49,
        49,
        42,
        49,
        0,
        34,
        0,
        13,
        30,
        16,
        39,
        1,
        19
      ],
      "weeklyRejected": [
        28,
        49,
        49,
        47,
        40,
        42,
        18,
        49,
        38,
        1,
        49,
        47,
        2,
        27,
        48,
        37,
        16,
        49,
        26,
        10,
        0,
        26,
        7,
        13,
        37,
        25,
        49,
        49,
        33,
        20,
        49,
        36,
        45,
        18,
        22,
        49,
        30,
        42,
        23,
        49,
        23,
        8,
        17,
        0,
        38,
        11,
        0,
        0,
        27,
        23,
        23,
        42
      ],
      "weeklyHold": [
        46,
        22,
        39,
        1,
        47,
        29,
        49,
        23,
        26,
        26,
        23,
        49,
        37,
        9,
        19,
        0,
        0,
        5,
        20,
        6,
        18,
        29,
        47,
        13,
        49,
        16,
        29,
        0,
        21,
        49,
        40,
        48,
        0,
        45,
        19,
        47,
        0,
        10,
        15,
        43,
        49,
        36,
        0,
        40,
        23,
        41,
        45,
        49,
        26,
        45,
        27,
        44
      ],
      "weeklyBackordered": [
        39,
        10,
        0,
        30,
        49,
        4,
        0,
        49,
        44,
        40,
        49,
        34,
        7,
        42,
        49,
        19,
        31,
        29,
        20,
        38,
        26,
        13,
        43,
        18,
        1,
        7,
        1,
        49,
        1,
        49,
        7,
        19,
        22,
        0,
        24,
        0,
        49,
        0,
        49,
        30,
        7,
        48,
        2,
        49,
        22,
        30,
        42,
        38,
        46,
        49,
        44,
        31
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        0,
        32,
        0,
        34,
        4,
        0,
        18,
        48,
        48,
        44,
        37,
        12,
        20,
        40,
        49,
        25,
        39,
        7,
        49,
        16,
        29,
        31,
        49,
        6,
        14,
        47,
        0,
        36,
        1,
        42,
        0,
        35,
        49,
        0,
        0,
        38,
        18,
        2,
        20,
        49,
        0,
        10,
        49,
        49,
        49,
        0,
        24,
        3,
        3,
        27,
        35,
        48
      ],
      "weeklyRejected": [
        32,
        28,
        29,
        47,
        14,
        0,
        9,
        49,
        7,
        21,
        46,
        43,
        32,
        29,
        48,
        15,
        10,
        43,
        38,
        49,
        0,
        0,
        49,
        20,
        26,
        49,
        46,
        17,
        48,
        14,
        43,
        34,
        30,
        29,
        20,
        35,
        48,
        49,
        1,
        49,
        42,
        0,
        0,
        0,
        18,
        30,
        2,
        49,
        44,
        49,
        28,
        49
      ],
      "weeklyHold": [
        49,
        11,
        27,
        18,
        0,
        30,
        49,
        13,
        20,
        37,
        47,
        49,
        24,
        37,
        39,
        23,
        4,
        6,
        21,
        49,
        39,
        0,
        22,
        31,
        14,
        21,
        27,
        9,
        38,
        24,
        33,
        0,
        0,
        40,
        31,
        49,
        0,
        0,
        4,
        31,
        12,
        33,
        32,
        16,
        22,
        25,
        3,
        46,
        0,
        28,
        6,
        0
      ],
      "weeklyBackordered": [
        0,
        12,
        0,
        35,
        49,
        23,
        0,
        49,
        19,
        16,
        10,
        12,
        26,
        34,
        25,
        1,
        49,
        49,
        5,
        0,
        6,
        29,
        40,
        26,
        1,
        23,
        4,
        46,
        41,
        40,
        21,
        48,
        41,
        40,
        5,
        0,
        1,
        0,
        11,
        20,
        12,
        48,
        0,
        49,
        49,
        49,
        49,
        30,
        49,
        32,
        6,
        49
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyAccepted": [
        27,
        1,
        49,
        0,
        22,
        0,
        26,
        40,
        41,
        7,
        47,
        13,
        29,
        42,
        22,
        49,
        49,
        0,
        30,
        0,
        42,
        16,
        11,
        23,
        20,
        43,
        3,
        7,
        27,
        48,
        22,
        3,
        21,
        10,
        39,
        6,
        17,
        0,
        27,
        49,
        34,
        46,
        7,
        49,
        33,
        0,
        10,
        0,
        0,
        38,
        9,
        0
      ],
      "weeklyRejected": [
        49,
        22,
        17,
        30,
        27,
        26,
        6,
        49,
        49,
        20,
        36,
        27,
        30,
        45,
        30,
        24,
        9,
        30,
        49,
        47,
        29,
        33,
        25,
        49,
        8,
        29,
        38,
        7,
        0,
        24,
        6,
        31,
        12,
        28,
        11,
        49,
        3,
        49,
        17,
        27,
        7,
        14,
        17,
        16,
        9,
        49,
        0,
        2,
        21,
        40,
        12,
        8
      ],
      "weeklyHold": [
        20,
        27,
        17,
        1,
        1,
        18,
        42,
        14,
        16,
        34,
        13,
        49,
        34,
        18,
        24,
        12,
        19,
        27,
        6,
        46,
        40,
        34,
        26,
        49,
        7,
        1,
        49,
        29,
        49,
        22,
        40,
        15,
        0,
        18,
        48,
        18,
        0,
        5,
        1,
        49,
        40,
        5,
        36,
        49,
        40,
        26,
        10,
        39,
        31,
        35,
        0,
        22
      ],
      "weeklyBackordered": [
        32,
        0,
        24,
        45,
        45,
        46,
        46,
        46,
        15,
        8,
        30,
        49,
        31,
        0,
        28,
        23,
        28,
        31,
        49,
        46,
        26,
        49,
        49,
        12,
        1,
        49,
        5,
        0,
        18,
        39,
        16,
        18,
        29,
        40,
        34,
        0,
        49,
        14,
        18,
        0,
        27,
        48,
        30,
        49,
        0,
        40,
        49,
        5,
        15,
        45,
        20,
        13
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        16,
        4,
        49,
        48,
        49,
        34,
        36,
        21,
        0,
        15,
        40,
        20,
        49,
        48,
        29,
        34,
        30,
        0,
        0,
        25,
        13,
        38,
        35,
        44,
        25,
        15,
        0,
        35,
        37,
        29,
        8,
        30,
        22,
        0,
        38,
        38,
        47,
        18,
        49,
        25,
        6,
        45,
        13,
        49,
        39,
        0,
        27,
        49,
        3,
        0,
        38,
        10
      ],
      "weeklyRejected": [
        45,
        4,
        14,
        27,
        48,
        0,
        21,
        26,
        22,
        25,
        49,
        15,
        15,
        2,
        30,
        35,
        28,
        46,
        33,
        27,
        4,
        49,
        13,
        23,
        11,
        0,
        49,
        49,
        40,
        33,
        36,
        0,
        47,
        26,
        0,
        49,
        16,
        14,
        1,
        22,
        39,
        6,
        37,
        49,
        23,
        11,
        0,
        22,
        0,
        0,
        32,
        49
      ],
      "weeklyHold": [
        24,
        0,
        49,
        1,
        0,
        36,
        46,
        25,
        0,
        17,
        18,
        34,
        31,
        7,
        41,
        20,
        8,
        32,
        49,
        11,
        18,
        22,
        6,
        49,
        16,
        37,
        37,
        23,
        24,
        5,
        48,
        48,
        18,
        39,
        11,
        5,
        0,
        26,
        28,
        38,
        36,
        24,
        0,
        28,
        30,
        35,
        18,
        13,
        4,
        14,
        0,
        27
      ],
      "weeklyBackordered": [
        12,
        13,
        5,
        28,
        17,
        16,
        37,
        45,
        27,
        34,
        47,
        49,
        23,
        6,
        49,
        24,
        23,
        43,
        21,
        0,
        13,
        43,
        39,
        49,
        13,
        1,
        13,
        3,
        47,
        27,
        33,
        24,
        48,
        49,
        32,
        3,
        7,
        0,
        45,
        14,
        35,
        48,
        3,
        32,
        23,
        32,
        49,
        25,
        27,
        49,
        39,
        21
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        19,
        31,
        5,
        36,
        35,
        23,
        4,
        2,
        16,
        49,
        49,
        0,
        36,
        20,
        10,
        35,
        49,
        6,
        7,
        15,
        38,
        29,
        23,
        7,
        49,
        36,
        22,
        49,
        17,
        23,
        0,
        18,
        49,
        20,
        48,
        2,
        32,
        5,
        34,
        49,
        9,
        23,
        34,
        10,
        49,
        18,
        0,
        24,
        29,
        49,
        0,
        32
      ],
      "weeklyRejected": [
        42,
        49,
        49,
        47,
        37,
        15,
        46,
        38,
        48,
        14,
        46,
        10,
        49,
        48,
        28,
        47,
        0,
        33,
        33,
        37,
        15,
        0,
        8,
        27,
        38,
        45,
        49,
        11,
        37,
        42,
        49,
        24,
        48,
        49,
        0,
        24,
        20,
        33,
        1,
        4,
        0,
        0,
        49,
        3,
        45,
        34,
        7,
        0,
        33,
        41,
        0,
        23
      ],
      "weeklyHold": [
        0,
        18,
        32,
        15,
        0,
        48,
        24,
        15,
        17,
        31,
        0,
        49,
        47,
        32,
        36,
        0,
        17,
        21,
        0,
        20,
        11,
        49,
        4,
        20,
        0,
        38,
        28,
        1,
        49,
        0,
        44,
        10,
        16,
        49,
        47,
        3,
        10,
        7,
        49,
        7,
        35,
        15,
        0,
        16,
        18,
        8,
        49,
        49,
        0,
        31,
        30,
        49
      ],
      "weeklyBackordered": [
        49,
        7,
        0,
        49,
        47,
        30,
        41,
        49,
        31,
        2,
        49,
        10,
        3,
        49,
        6,
        49,
        22,
        36,
        18,
        17,
        38,
        36,
        26,
        8,
        35,
        1,
        27,
        32,
        1,
        32,
        9,
        0,
        21,
        49,
        0,
        13,
        24,
        0,
        30,
        5,
        33,
        48,
        36,
        49,
        20,
        37,
        49,
        9,
        8,
        33,
        49,
        38
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        24,
        49,
        49,
        7,
        31,
        39,
        1,
        10,
        42,
        22,
        32,
        2,
        9,
        20,
        24,
        36,
        49,
        0,
        32,
        49,
        8,
        37,
        29,
        28,
        8,
        47,
        0,
        19,
        24,
        36,
        40,
        26,
        49,
        25,
        40,
        2,
        21,
        13,
        49,
        49,
        20,
        2,
        23,
        47,
        40,
        0,
        0,
        49,
        0,
        46,
        5,
        21
      ],
      "weeklyRejected": [
        0,
        45,
        8,
        2,
        28,
        20,
        49,
        46,
        31,
        45,
        35,
        19,
        49,
        48,
        30,
        13,
        22,
        32,
        49,
        18,
        20,
        35,
        0,
        17,
        8,
        48,
        49,
        16,
        48,
        0,
        31,
        26,
        32,
        8,
        24,
        49,
        26,
        4,
        37,
        3,
        14,
        49,
        7,
        0,
        49,
        22,
        11,
        49,
        20,
        4,
        0,
        43
      ],
      "weeklyHold": [
        8,
        0,
        35,
        18,
        49,
        0,
        32,
        37,
        40,
        29,
        47,
        18,
        1,
        15,
        0,
        1,
        13,
        38,
        0,
        11,
        29,
        0,
        29,
        16,
        49,
        45,
        20,
        30,
        12,
        38,
        48,
        0,
        0,
        25,
        35,
        34,
        13,
        13,
        47,
        8,
        0,
        39,
        5,
        20,
        45,
        37,
        49,
        22,
        4,
        26,
        15,
        13
      ],
      "weeklyBackordered": [
        39,
        28,
        0,
        24,
        26,
        46,
        49,
        12,
        49,
        14,
        6,
        38,
        26,
        33,
        40,
        9,
        2,
        49,
        0,
        32,
        26,
        39,
        43,
        18,
        19,
        49,
        19,
        9,
        42,
        36,
        22,
        9,
        34,
        48,
        0,
        0,
        9,
        40,
        37,
        29,
        42,
        37,
        35,
        49,
        31,
        0,
        26,
        10,
        19,
        49,
        7,
        1
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        40,
        35,
        49,
        12,
        49,
        0,
        7,
        49,
        35,
        24,
        33,
        11,
        40,
        15,
        1,
        45,
        16,
        25,
        1,
        19,
        35,
        37,
        49,
        29,
        6,
        25,
        15,
        41,
        34,
        18,
        7,
        3,
        34,
        25,
        0,
        47,
        6,
        37,
        0,
        49,
        24,
        38,
        49,
        27,
        49,
        0,
        1,
        12,
        42,
        46,
        31,
        47
      ],
      "weeklyRejected": [
        49,
        31,
        39,
        15,
        49,
        33,
        25,
        29,
        30,
        36,
        0,
        27,
        30,
        3,
        44,
        14,
        24,
        49,
        0,
        24,
        6,
        46,
        22,
        23,
        20,
        35,
        26,
        7,
        31,
        24,
        49,
        13,
        24,
        26,
        0,
        49,
        49,
        16,
        1,
        20,
        13,
        49,
        24,
        3,
        49,
        39,
        3,
        44,
        3,
        49,
        0,
        5
      ],
      "weeklyHold": [
        16,
        49,
        29,
        33,
        40,
        48,
        2,
        22,
        16,
        49,
        0,
        49,
        1,
        22,
        21,
        16,
        0,
        48,
        33,
        37,
        34,
        31,
        5,
        26,
        30,
        48,
        12,
        3,
        15,
        36,
        48,
        0,
        10,
        31,
        3,
        34,
        22,
        18,
        12,
        10,
        27,
        49,
        16,
        49,
        42,
        49,
        49,
        49,
        0,
        49,
        27,
        35
      ],
      "weeklyBackordered": [
        49,
        49,
        0,
        29,
        49,
        41,
        0,
        37,
        13,
        26,
        36,
        35,
        38,
        31,
        20,
        16,
        46,
        49,
        10,
        32,
        4,
        8,
        26,
        22,
        11,
        27,
        44,
        23,
        41,
        49,
        1,
        49,
        48,
        20,
        24,
        21,
        19,
        35,
        13,
        45,
        0,
        41,
        0,
        8,
        0,
        30,
        27,
        43,
        9,
        49,
        2,
        4
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        34,
        49,
        33,
        26,
        35,
        0,
        10,
        4,
        48,
        15,
        3,
        0,
        19,
        0,
        38,
        27,
        49,
        0,
        0,
        17,
        6,
        26,
        33,
        30,
        8,
        24,
        33,
        49,
        44,
        35,
        37,
        3,
        35,
        4,
        36,
        36,
        24,
        23,
        49,
        49,
        40,
        49,
        49,
        10,
        20,
        0,
        21,
        16,
        8,
        0,
        34,
        0
      ],
      "weeklyRejected": [
        25,
        46,
        30,
        0,
        34,
        33,
        40,
        20,
        30,
        48,
        4,
        28,
        23,
        16,
        42,
        7,
        38,
        40,
        18,
        0,
        23,
        35,
        33,
        15,
        24,
        49,
        43,
        24,
        32,
        37,
        22,
        34,
        32,
        32,
        13,
        49,
        35,
        45,
        4,
        0,
        19,
        49,
        38,
        13,
        18,
        21,
        0,
        49,
        25,
        16,
        0,
        49
      ],
      "weeklyHold": [
        18,
        34,
        27,
        20,
        0,
        1,
        16,
        6,
        0,
        5,
        21,
        49,
        16,
        1,
        49,
        42,
        21,
        39,
        33,
        1,
        49,
        0,
        35,
        0,
        44,
        11,
        40,
        28,
        49,
        30,
        48,
        48,
        15,
        15,
        48,
        9,
        14,
        0,
        45,
        18,
        49,
        34,
        32,
        44,
        19,
        49,
        0,
        49,
        3,
        4,
        22,
        48
      ],
      "weeklyBackordered": [
        34,
        0,
        0,
        49,
        20,
        33,
        0,
        34,
        29,
        49,
        49,
        19,
        3,
        49,
        14,
        19,
        49,
        37,
        0,
        4,
        16,
        14,
        0,
        13,
        12,
        33,
        13,
        0,
        19,
        49,
        14,
        0,
        25,
        41,
        0,
        0,
        25,
        21,
        23,
        0,
        29,
        48,
        0,
        46,
        12,
        36,
        49,
        44,
        49,
        15,
        36,
        1
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        0,
        48,
        49,
        31,
        15,
        14,
        17,
        19,
        41,
        45,
        35,
        0,
        31,
        49,
        49,
        34,
        42,
        0,
        5,
        32,
        1,
        34,
        0,
        49,
        10,
        47,
        38,
        11,
        25,
        26,
        28,
        15,
        48,
        40,
        1,
        2,
        29,
        45,
        49,
        10,
        32,
        19,
        49,
        49,
        15,
        0,
        4,
        41,
        12,
        30,
        2,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        47,
        36,
        22,
        3,
        18,
        19,
        1,
        0,
        0,
        31,
        35,
        34,
        49,
        30,
        49,
        10,
        38,
        21,
        0,
        10,
        25,
        25,
        41,
        5,
        27,
        46,
        10,
        4,
        43,
        41,
        21,
        28,
        36,
        36,
        0,
        1,
        42,
        44,
        47,
        1,
        47,
        49,
        30,
        13,
        27,
        0,
        49,
        0,
        45
      ],
      "weeklyHold": [
        34,
        32,
        0,
        1,
        0,
        44,
        25,
        29,
        5,
        25,
        37,
        1,
        35,
        30,
        0,
        0,
        11,
        35,
        8,
        0,
        13,
        7,
        22,
        49,
        30,
        46,
        40,
        11,
        12,
        13,
        47,
        1,
        0,
        15,
        37,
        24,
        33,
        29,
        49,
        42,
        37,
        40,
        3,
        5,
        14,
        0,
        49,
        21,
        0,
        28,
        49,
        0
      ],
      "weeklyBackordered": [
        38,
        43,
        0,
        2,
        37,
        46,
        45,
        36,
        15,
        0,
        49,
        18,
        8,
        49,
        49,
        29,
        3,
        41,
        28,
        3,
        0,
        44,
        0,
        30,
        1,
        7,
        40,
        8,
        49,
        29,
        23,
        7,
        0,
        27,
        10,
        0,
        2,
        39,
        28,
        17,
        9,
        48,
        10,
        49,
        25,
        33,
        0,
        36,
        0,
        49,
        1,
        24
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        38,
        47,
        7,
        36,
        49,
        31,
        12,
        49,
        48,
        14,
        49,
        29,
        2,
        29,
        32,
        0,
        49,
        1,
        49,
        22,
        30,
        0,
        41,
        45,
        0,
        28,
        0,
        29,
        1,
        41,
        22,
        18,
        49,
        9,
        33,
        47,
        30,
        11,
        16,
        49,
        18,
        38,
        49,
        11,
        45,
        17,
        31,
        15,
        19,
        49,
        49,
        21
      ],
      "weeklyRejected": [
        49,
        49,
        24,
        44,
        15,
        1,
        23,
        28,
        49,
        18,
        34,
        10,
        49,
        30,
        36,
        0,
        9,
        49,
        17,
        5,
        9,
        16,
        0,
        49,
        0,
        14,
        45,
        0,
        48,
        31,
        49,
        12,
        19,
        31,
        0,
        49,
        24,
        16,
        1,
        43,
        42,
        49,
        34,
        20,
        49,
        34,
        49,
        6,
        0,
        49,
        13,
        35
      ],
      "weeklyHold": [
        0,
        3,
        49,
        1,
        13,
        25,
        16,
        31,
        23,
        24,
        25,
        6,
        1,
        32,
        36,
        11,
        0,
        3,
        9,
        4,
        0,
        16,
        0,
        1,
        30,
        25,
        0,
        0,
        35,
        0,
        48,
        39,
        12,
        11,
        46,
        31,
        45,
        0,
        17,
        40,
        0,
        28,
        0,
        39,
        29,
        49,
        0,
        49,
        0,
        15,
        49,
        37
      ],
      "weeklyBackordered": [
        24,
        0,
        0,
        47,
        49,
        46,
        0,
        49,
        28,
        35,
        49,
        3,
        49,
        20,
        22,
        49,
        27,
        37,
        0,
        16,
        37,
        14,
        0,
        49,
        48,
        45,
        2,
        49,
        9,
        49,
        36,
        34,
        44,
        43,
        0,
        0,
        49,
        0,
        13,
        40,
        11,
        48,
        31,
        49,
        23,
        45,
        49,
        36,
        49,
        0,
        43,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        0,
        27,
        29,
        0,
        24,
        15,
        35,
        2,
        37,
        30,
        47,
        0,
        22,
        20,
        42,
        27,
        44,
        48,
        7,
        31,
        15,
        15,
        24,
        21,
        10,
        47,
        6,
        40,
        39,
        14,
        0,
        0,
        49,
        49,
        11,
        49,
        42,
        32,
        48,
        21,
        13,
        49,
        49,
        19,
        49,
        10,
        9,
        24,
        18,
        32,
        17,
        48
      ],
      "weeklyRejected": [
        24,
        45,
        21,
        15,
        49,
        0,
        17,
        0,
        13,
        32,
        49,
        8,
        49,
        38,
        2,
        13,
        18,
        27,
        14,
        11,
        0,
        36,
        38,
        25,
        18,
        49,
        49,
        0,
        48,
        19,
        49,
        13,
        0,
        25,
        6,
        49,
        25,
        31,
        9,
        37,
        40,
        25,
        37,
        18,
        44,
        37,
        31,
        1,
        0,
        49,
        23,
        38
      ],
      "weeklyHold": [
        23,
        49,
        49,
        18,
        14,
        5,
        48,
        6,
        38,
        0,
        47,
        26,
        38,
        16,
        22,
        12,
        0,
        2,
        0,
        22,
        20,
        49,
        7,
        44,
        23,
        48,
        30,
        49,
        47,
        48,
        36,
        23,
        3,
        45,
        7,
        32,
        30,
        48,
        26,
        0,
        46,
        39,
        23,
        37,
        34,
        49,
        7,
        6,
        1,
        3,
        49,
        49
      ],
      "weeklyBackordered": [
        42,
        40,
        36,
        49,
        49,
        30,
        0,
        28,
        5,
        0,
        49,
        49,
        28,
        23,
        15,
        35,
        38,
        33,
        11,
        6,
        29,
        49,
        42,
        15,
        1,
        25,
        4,
        0,
        13,
        41,
        24,
        49,
        48,
        0,
        8,
        10,
        31,
        0,
        0,
        29,
        4,
        48,
        37,
        49,
        0,
        49,
        49,
        32,
        0,
        29,
        34,
        45
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        15,
        49,
        22,
        47,
        2,
        24,
        10,
        49,
        30,
        16,
        30,
        0,
        22,
        43,
        12,
        9,
        43,
        0,
        0,
        46,
        25,
        49,
        48,
        24,
        9,
        47,
        0,
        0,
        7,
        48,
        47,
        40,
        49,
        28,
        48,
        41,
        10,
        28,
        0,
        37,
        16,
        3,
        49,
        24,
        49,
        0,
        5,
        47,
        3,
        29,
        15,
        47
      ],
      "weeklyRejected": [
        10,
        5,
        40,
        10,
        49,
        33,
        1,
        0,
        49,
        11,
        48,
        19,
        24,
        27,
        11,
        19,
        25,
        31,
        39,
        49,
        0,
        2,
        32,
        10,
        1,
        34,
        41,
        16,
        46,
        13,
        32,
        8,
        35,
        49,
        39,
        28,
        41,
        27,
        24,
        0,
        48,
        39,
        0,
        0,
        40,
        32,
        0,
        0,
        33,
        49,
        9,
        36
      ],
      "weeklyHold": [
        39,
        22,
        49,
        1,
        0,
        6,
        45,
        2,
        31,
        27,
        32,
        15,
        13,
        42,
        26,
        49,
        17,
        0,
        10,
        35,
        5,
        31,
        49,
        17,
        13,
        35,
        31,
        32,
        49,
        17,
        48,
        32,
        16,
        36,
        16,
        49,
        0,
        7,
        42,
        35,
        49,
        26,
        0,
        36,
        35,
        48,
        20,
        49,
        18,
        6,
        48,
        30
      ],
      "weeklyBackordered": [
        40,
        40,
        30,
        6,
        43,
        46,
        21,
        29,
        36,
        32,
        28,
        49,
        3,
        46,
        10,
        35,
        29,
        33,
        0,
        12,
        23,
        49,
        45,
        44,
        14,
        25,
        8,
        30,
        33,
        5,
        10,
        49,
        35,
        43,
        0,
        0,
        46,
        0,
        33,
        49,
        45,
        48,
        4,
        8,
        0,
        27,
        28,
        49,
        0,
        24,
        36,
        31
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        0,
        43,
        38,
        20,
        27,
        20,
        20,
        2,
        42,
        49,
        44,
        29,
        3,
        49,
        1,
        0,
        49,
        6,
        16,
        49,
        0,
        40,
        2,
        0,
        17,
        47,
        24,
        25,
        27,
        49,
        0,
        0,
        49,
        20,
        48,
        38,
        10,
        39,
        16,
        49,
        0,
        45,
        46,
        31,
        49,
        0,
        0,
        49,
        0,
        11,
        41,
        20
      ],
      "weeklyRejected": [
        49,
        38,
        6,
        32,
        49,
        0,
        49,
        28,
        32,
        2,
        16,
        29,
        0,
        11,
        45,
        16,
        39,
        39,
        20,
        32,
        24,
        13,
        6,
        37,
        26,
        34,
        49,
        49,
        48,
        0,
        46,
        26,
        26,
        23,
        1,
        49,
        49,
        0,
        42,
        34,
        36,
        49,
        35,
        10,
        44,
        46,
        20,
        10,
        4,
        49,
        2,
        23
      ],
      "weeklyHold": [
        0,
        0,
        49,
        15,
        49,
        11,
        34,
        2,
        0,
        39,
        20,
        8,
        17,
        42,
        49,
        19,
        0,
        27,
        12,
        13,
        24,
        49,
        43,
        0,
        35,
        48,
        14,
        49,
        49,
        21,
        42,
        25,
        0,
        9,
        0,
        49,
        1,
        4,
        49,
        6,
        12,
        31,
        11,
        25,
        25,
        49,
        17,
        27,
        6,
        37,
        22,
        49
      ],
      "weeklyBackordered": [
        40,
        39,
        0,
        10,
        20,
        46,
        49,
        39,
        25,
        34,
        29,
        35,
        48,
        31,
        32,
        49,
        40,
        47,
        24,
        5,
        28,
        24,
        21,
        49,
        4,
        37,
        1,
        13,
        49,
        32,
        49,
        48,
        8,
        9,
        18,
        0,
        37,
        40,
        28,
        20,
        36,
        24,
        8,
        24,
        0,
        42,
        24,
        38,
        0,
        20,
        38,
        38
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        30,
        7,
        14,
        44,
        49,
        0,
        1,
        45,
        48,
        0,
        43,
        0,
        49,
        35,
        29,
        21,
        38,
        31,
        5,
        42,
        19,
        0,
        45,
        37,
        11,
        0,
        9,
        35,
        30,
        49,
        30,
        32,
        47,
        6,
        48,
        33,
        16,
        0,
        24,
        49,
        9,
        15,
        27,
        25,
        41,
        43,
        11,
        33,
        30,
        30,
        4,
        7
      ],
      "weeklyRejected": [
        0,
        2,
        17,
        47,
        49,
        2,
        11,
        49,
        19,
        27,
        37,
        26,
        21,
        46,
        2,
        30,
        23,
        3,
        37,
        30,
        39,
        2,
        0,
        49,
        25,
        18,
        44,
        5,
        24,
        6,
        19,
        5,
        48,
        49,
        9,
        49,
        3,
        24,
        2,
        0,
        32,
        49,
        49,
        29,
        44,
        2,
        23,
        16,
        22,
        34,
        0,
        19
      ],
      "weeklyHold": [
        49,
        44,
        49,
        23,
        22,
        28,
        36,
        26,
        37,
        47,
        47,
        35,
        1,
        29,
        44,
        0,
        20,
        28,
        0,
        0,
        49,
        21,
        0,
        9,
        0,
        1,
        0,
        7,
        25,
        0,
        48,
        28,
        0,
        15,
        48,
        0,
        45,
        9,
        42,
        23,
        7,
        15,
        11,
        9,
        37,
        25,
        18,
        36,
        32,
        23,
        4,
        16
      ],
      "weeklyBackordered": [
        49,
        4,
        0,
        24,
        38,
        34,
        38,
        5,
        14,
        30,
        5,
        0,
        3,
        20,
        18,
        35,
        13,
        24,
        0,
        0,
        47,
        7,
        35,
        13,
        6,
        8,
        10,
        34,
        48,
        49,
        34,
        12,
        23,
        49,
        49,
        39,
        29,
        0,
        0,
        33,
        34,
        48,
        12,
        4,
        34,
        47,
        34,
        8,
        22,
        49,
        21,
        3
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        38,
        43,
        18,
        34,
        40,
        34,
        8,
        28,
        32,
        9,
        37,
        23,
        29,
        27,
        8,
        15,
        45,
        22,
        0,
        40,
        21,
        35,
        29,
        49,
        3,
        47,
        28,
        0,
        1,
        49,
        49,
        22,
        28,
        8,
        48,
        40,
        47,
        6,
        16,
        49,
        16,
        29,
        46,
        38,
        49,
        2,
        25,
        26,
        15,
        15,
        45,
        31
      ],
      "weeklyRejected": [
        49,
        15,
        49,
        29,
        30,
        34,
        0,
        7,
        49,
        39,
        0,
        49,
        36,
        9,
        48,
        7,
        31,
        45,
        12,
        49,
        0,
        49,
        31,
        49,
        29,
        20,
        49,
        20,
        27,
        33,
        49,
        47,
        43,
        19,
        42,
        23,
        43,
        20,
        44,
        49,
        21,
        20,
        43,
        46,
        47,
        27,
        14,
        0,
        21,
        21,
        23,
        39
      ],
      "weeklyHold": [
        42,
        29,
        43,
        13,
        11,
        29,
        49,
        30,
        0,
        49,
        47,
        26,
        1,
        1,
        20,
        13,
        48,
        25,
        21,
        12,
        0,
        22,
        11,
        23,
        13,
        21,
        0,
        39,
        25,
        49,
        48,
        32,
        25,
        5,
        44,
        49,
        26,
        10,
        49,
        16,
        11,
        43,
        42,
        10,
        0,
        24,
        5,
        49,
        25,
        31,
        40,
        46
      ],
      "weeklyBackordered": [
        31,
        11,
        0,
        0,
        37,
        41,
        48,
        44,
        29,
        1,
        17,
        34,
        26,
        16,
        5,
        34,
        12,
        43,
        39,
        22,
        49,
        29,
        20,
        0,
        12,
        24,
        17,
        0,
        8,
        49,
        34,
        29,
        15,
        49,
        29,
        18,
        16,
        13,
        4,
        36,
        46,
        48,
        0,
        49,
        0,
        14,
        18,
        18,
        0,
        48,
        24,
        32
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        19,
        1,
        18,
        6,
        40,
        15,
        27,
        49,
        37,
        18,
        49,
        0,
        49,
        22,
        15,
        7,
        29,
        44,
        0,
        49,
        33,
        35,
        49,
        43,
        20,
        34,
        0,
        4,
        8,
        12,
        6,
        22,
        33,
        5,
        0,
        26,
        28,
        39,
        49,
        23,
        0,
        39,
        19,
        0,
        12,
        0,
        10,
        0,
        1,
        18,
        47,
        13
      ],
      "weeklyRejected": [
        5,
        9,
        49,
        3,
        33,
        40,
        0,
        46,
        27,
        30,
        0,
        13,
        49,
        30,
        17,
        49,
        0,
        44,
        49,
        30,
        43,
        0,
        49,
        43,
        28,
        49,
        33,
        44,
        47,
        33,
        0,
        6,
        48,
        0,
        36,
        46,
        9,
        48,
        28,
        25,
        28,
        28,
        43,
        32,
        42,
        49,
        0,
        27,
        0,
        20,
        0,
        49
      ],
      "weeklyHold": [
        49,
        36,
        49,
        1,
        0,
        21,
        0,
        11,
        0,
        16,
        43,
        42,
        48,
        24,
        5,
        13,
        21,
        0,
        47,
        49,
        27,
        25,
        0,
        1,
        9,
        13,
        5,
        16,
        49,
        0,
        47,
        14,
        0,
        49,
        48,
        29,
        33,
        6,
        38,
        16,
        11,
        30,
        22,
        42,
        33,
        43,
        18,
        23,
        4,
        49,
        31,
        42
      ],
      "weeklyBackordered": [
        8,
        49,
        18,
        38,
        49,
        42,
        5,
        1,
        15,
        12,
        44,
        0,
        3,
        49,
        0,
        44,
        49,
        23,
        0,
        49,
        27,
        43,
        47,
        34,
        38,
        1,
        11,
        26,
        16,
        23,
        17,
        49,
        4,
        49,
        0,
        0,
        32,
        0,
        12,
        21,
        0,
        48,
        20,
        49,
        37,
        8,
        23,
        13,
        26,
        33,
        27,
        39
      ]
    },
    {
      "id": "DB301E71",
      "weeklyAccepted": [
        39,
        9,
        24,
        48,
        14,
        19,
        14,
        37,
        48,
        13,
        0,
        27,
        16,
        22,
        47,
        10,
        49,
        23,
        0,
        26,
        11,
        30,
        4,
        31,
        16,
        39,
        0,
        44,
        15,
        25,
        2,
        34,
        39,
        6,
        48,
        10,
        18,
        48,
        49,
        37,
        23,
        41,
        49,
        28,
        14,
        3,
        19,
        0,
        7,
        1,
        38,
        48
      ],
      "weeklyRejected": [
        38,
        20,
        38,
        0,
        4,
        0,
        9,
        28,
        49,
        35,
        22,
        0,
        41,
        11,
        48,
        0,
        29,
        27,
        49,
        29,
        0,
        20,
        11,
        28,
        12,
        32,
        49,
        18,
        45,
        26,
        44,
        47,
        48,
        19,
        46,
        47,
        49,
        0,
        7,
        23,
        16,
        35,
        34,
        49,
        42,
        44,
        0,
        26,
        15,
        39,
        27,
        49
      ],
      "weeklyHold": [
        17,
        0,
        49,
        36,
        49,
        43,
        0,
        32,
        3,
        16,
        32,
        18,
        1,
        30,
        5,
        3,
        1,
        35,
        33,
        23,
        20,
        31,
        41,
        0,
        43,
        48,
        0,
        9,
        49,
        0,
        48,
        0,
        14,
        25,
        23,
        48,
        24,
        31,
        2,
        4,
        29,
        27,
        24,
        27,
        37,
        49,
        7,
        38,
        0,
        31,
        49,
        39
      ],
      "weeklyBackordered": [
        18,
        3,
        18,
        14,
        29,
        39,
        36,
        33,
        11,
        49,
        0,
        26,
        3,
        21,
        0,
        3,
        3,
        17,
        0,
        18,
        43,
        11,
        0,
        12,
        17,
        1,
        34,
        49,
        34,
        49,
        36,
        49,
        22,
        0,
        0,
        17,
        1,
        8,
        22,
        49,
        13,
        48,
        8,
        49,
        16,
        15,
        49,
        0,
        31,
        0,
        41,
        1
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        0,
        49,
        49,
        6,
        39,
        3,
        1,
        13,
        48,
        10,
        33,
        4,
        13,
        19,
        23,
        0,
        46,
        28,
        0,
        14,
        35,
        29,
        49,
        0,
        2,
        47,
        6,
        29,
        1,
        41,
        43,
        15,
        49,
        13,
        25,
        34,
        4,
        48,
        26,
        42,
        27,
        9,
        22,
        42,
        49,
        0,
        0,
        31,
        0,
        11,
        19,
        36
      ],
      "weeklyRejected": [
        49,
        49,
        45,
        22,
        7,
        0,
        30,
        1,
        48,
        21,
        18,
        0,
        34,
        48,
        20,
        31,
        0,
        42,
        29,
        32,
        29,
        49,
        37,
        0,
        12,
        49,
        10,
        38,
        48,
        26,
        0,
        47,
        32,
        22,
        13,
        49,
        21,
        36,
        17,
        46,
        48,
        4,
        23,
        24,
        45,
        28,
        4,
        47,
        43,
        40,
        19,
        28
      ],
      "weeklyHold": [
        49,
        23,
        43,
        1,
        10,
        0,
        0,
        2,
        32,
        36,
        47,
        49,
        17,
        45,
        31,
        3,
        7,
        0,
        15,
        28,
        7,
        49,
        0,
        8,
        25,
        37,
        20,
        49,
        21,
        10,
        48,
        11,
        0,
        36,
        0,
        18,
        13,
        19,
        27,
        49,
        6,
        43,
        12,
        19,
        0,
        32,
        40,
        26,
        0,
        28,
        31,
        0
      ],
      "weeklyBackordered": [
        29,
        31,
        46,
        31,
        39,
        34,
        49,
        19,
        13,
        4,
        14,
        34,
        20,
        16,
        39,
        20,
        47,
        33,
        30,
        31,
        40,
        0,
        0,
        39,
        9,
        2,
        20,
        28,
        15,
        47,
        13,
        49,
        27,
        0,
        0,
        24,
        26,
        0,
        27,
        5,
        31,
        48,
        16,
        49,
        49,
        32,
        49,
        9,
        34,
        32,
        49,
        24
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        33,
        28,
        41,
        40,
        1,
        0,
        46,
        49,
        48,
        15,
        46,
        7,
        14,
        11,
        7,
        18,
        48,
        32,
        3,
        49,
        10,
        49,
        38,
        0,
        24,
        35,
        49,
        44,
        31,
        49,
        24,
        24,
        49,
        11,
        48,
        23,
        47,
        48,
        13,
        49,
        25,
        48,
        49,
        18,
        43,
        18,
        3,
        38,
        16,
        34,
        7,
        40
      ],
      "weeklyRejected": [
        21,
        22,
        49,
        47,
        18,
        2,
        0,
        25,
        48,
        25,
        43,
        47,
        26,
        39,
        17,
        16,
        0,
        49,
        0,
        24,
        49,
        35,
        16,
        13,
        29,
        49,
        19,
        16,
        43,
        7,
        40,
        47,
        48,
        33,
        7,
        49,
        31,
        0,
        41,
        19,
        43,
        49,
        44,
        8,
        49,
        9,
        2,
        16,
        48,
        49,
        0,
        22
      ],
      "weeklyHold": [
        2,
        49,
        27,
        21,
        38,
        48,
        32,
        7,
        28,
        19,
        32,
        42,
        48,
        37,
        38,
        5,
        12,
        41,
        12,
        0,
        0,
        7,
        38,
        48,
        38,
        19,
        49,
        42,
        47,
        0,
        38,
        23,
        6,
        12,
        24,
        15,
        40,
        1,
        12,
        37,
        8,
        21,
        41,
        24,
        49,
        49,
        25,
        49,
        0,
        7,
        26,
        28
      ],
      "weeklyBackordered": [
        9,
        49,
        1,
        33,
        49,
        40,
        38,
        24,
        49,
        4,
        30,
        1,
        3,
        0,
        30,
        46,
        25,
        38,
        0,
        49,
        36,
        0,
        21,
        22,
        26,
        1,
        5,
        25,
        27,
        40,
        9,
        49,
        0,
        40,
        0,
        34,
        49,
        19,
        39,
        0,
        0,
        48,
        44,
        49,
        20,
        33,
        33,
        23,
        33,
        37,
        18,
        12
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        15,
        18,
        48,
        10,
        34,
        13,
        6,
        49,
        30,
        0,
        38,
        0,
        48,
        14,
        19,
        8,
        37,
        7,
        19,
        34,
        35,
        49,
        25,
        49,
        0,
        30,
        28,
        3,
        16,
        48,
        0,
        7,
        49,
        27,
        46,
        36,
        18,
        0,
        46,
        49,
        12,
        0,
        47,
        49,
        13,
        26,
        0,
        41,
        36,
        49,
        31,
        0
      ],
      "weeklyRejected": [
        30,
        5,
        27,
        47,
        11,
        0,
        9,
        38,
        20,
        16,
        39,
        27,
        28,
        41,
        39,
        28,
        21,
        45,
        20,
        15,
        18,
        22,
        29,
        11,
        0,
        49,
        43,
        3,
        46,
        45,
        41,
        20,
        48,
        46,
        8,
        21,
        9,
        35,
        12,
        8,
        47,
        49,
        34,
        34,
        49,
        35,
        19,
        0,
        0,
        44,
        23,
        49
      ],
      "weeklyHold": [
        3,
        45,
        41,
        26,
        35,
        24,
        34,
        23,
        4,
        49,
        36,
        40,
        20,
        22,
        47,
        13,
        18,
        0,
        21,
        0,
        47,
        43,
        11,
        20,
        15,
        39,
        4,
        3,
        34,
        16,
        21,
        20,
        3,
        16,
        9,
        12,
        26,
        0,
        44,
        49,
        20,
        30,
        17,
        35,
        49,
        20,
        33,
        42,
        6,
        39,
        28,
        22
      ],
      "weeklyBackordered": [
        24,
        15,
        8,
        30,
        27,
        32,
        24,
        2,
        5,
        15,
        22,
        36,
        3,
        39,
        26,
        49,
        15,
        42,
        0,
        12,
        38,
        45,
        28,
        25,
        35,
        17,
        14,
        29,
        1,
        46,
        37,
        13,
        31,
        43,
        4,
        37,
        1,
        1,
        28,
        6,
        26,
        34,
        7,
        49,
        14,
        31,
        49,
        24,
        37,
        49,
        2,
        11
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        33,
        49,
        6,
        9,
        15,
        0,
        18,
        49,
        48,
        40,
        49,
        18,
        22,
        29,
        11,
        18,
        49,
        48,
        36,
        12,
        25,
        0,
        49,
        49,
        3,
        26,
        40,
        2,
        30,
        49,
        16,
        41,
        26,
        27,
        48,
        43,
        17,
        24,
        21,
        21,
        19,
        34,
        49,
        17,
        41,
        11,
        18,
        41,
        0,
        36,
        24,
        15
      ],
      "weeklyRejected": [
        44,
        19,
        43,
        0,
        15,
        33,
        2,
        34,
        49,
        13,
        12,
        49,
        41,
        18,
        25,
        32,
        0,
        49,
        28,
        44,
        10,
        0,
        43,
        7,
        36,
        15,
        0,
        39,
        48,
        38,
        30,
        41,
        34,
        23,
        39,
        49,
        18,
        49,
        49,
        2,
        18,
        49,
        36,
        15,
        35,
        38,
        15,
        14,
        0,
        44,
        18,
        40
      ],
      "weeklyHold": [
        21,
        10,
        45,
        34,
        20,
        27,
        36,
        2,
        0,
        49,
        47,
        49,
        1,
        33,
        49,
        41,
        30,
        33,
        0,
        19,
        18,
        14,
        12,
        46,
        0,
        39,
        9,
        0,
        49,
        29,
        44,
        0,
        0,
        26,
        15,
        31,
        49,
        11,
        16,
        17,
        38,
        36,
        0,
        34,
        49,
        44,
        15,
        24,
        0,
        2,
        48,
        39
      ],
      "weeklyBackordered": [
        38,
        15,
        14,
        7,
        49,
        30,
        0,
        17,
        49,
        21,
        29,
        27,
        4,
        10,
        0,
        34,
        18,
        31,
        0,
        20,
        49,
        0,
        35,
        0,
        15,
        1,
        25,
        40,
        13,
        49,
        24,
        27,
        48,
        40,
        9,
        0,
        1,
        0,
        44,
        8,
        24,
        32,
        28,
        40,
        28,
        16,
        47,
        26,
        0,
        17,
        49,
        2
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        13,
        36,
        49,
        48,
        3,
        0,
        1,
        49,
        41,
        15,
        49,
        42,
        22,
        32,
        25,
        21,
        49,
        0,
        8,
        42,
        10,
        0,
        49,
        30,
        37,
        13,
        11,
        0,
        14,
        49,
        0,
        20,
        38,
        0,
        12,
        31,
        36,
        45,
        22,
        49,
        0,
        46,
        31,
        49,
        49,
        30,
        32,
        27,
        36,
        0,
        49,
        48
      ],
      "weeklyRejected": [
        40,
        19,
        16,
        0,
        49,
        6,
        16,
        22,
        47,
        1,
        14,
        29,
        34,
        43,
        48,
        14,
        18,
        49,
        44,
        28,
        12,
        28,
        2,
        18,
        26,
        39,
        49,
        27,
        43,
        9,
        41,
        47,
        48,
        2,
        32,
        28,
        33,
        44,
        30,
        15,
        23,
        49,
        34,
        10,
        49,
        17,
        0,
        1,
        0,
        2,
        0,
        32
      ],
      "weeklyHold": [
        0,
        23,
        17,
        18,
        4,
        39,
        33,
        23,
        0,
        48,
        47,
        35,
        1,
        26,
        49,
        8,
        33,
        33,
        21,
        49,
        0,
        3,
        2,
        9,
        27,
        28,
        19,
        19,
        9,
        0,
        31,
        43,
        29,
        11,
        48,
        13,
        35,
        24,
        12,
        14,
        25,
        5,
        0,
        0,
        19,
        13,
        38,
        49,
        4,
        49,
        14,
        49
      ],
      "weeklyBackordered": [
        23,
        8,
        23,
        3,
        19,
        42,
        45,
        49,
        5,
        20,
        15,
        20,
        45,
        49,
        35,
        19,
        43,
        36,
        0,
        26,
        44,
        43,
        30,
        12,
        11,
        34,
        23,
        33,
        27,
        49,
        7,
        27,
        36,
        49,
        13,
        0,
        20,
        0,
        18,
        35,
        48,
        48,
        45,
        49,
        25,
        17,
        24,
        25,
        25,
        18,
        41,
        19
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyAccepted": [
        0,
        49,
        34,
        25,
        49,
        0,
        18,
        36,
        48,
        0,
        18,
        31,
        39,
        39,
        30,
        0,
        41,
        0,
        4,
        30,
        40,
        5,
        41,
        13,
        10,
        33,
        13,
        33,
        1,
        42,
        9,
        49,
        33,
        49,
        25,
        19,
        47,
        13,
        33,
        49,
        16,
        20,
        49,
        24,
        4,
        0,
        0,
        12,
        18,
        49,
        17,
        48
      ],
      "weeklyRejected": [
        45,
        0,
        25,
        40,
        44,
        38,
        27,
        20,
        0,
        36,
        28,
        18,
        25,
        9,
        26,
        3,
        49,
        29,
        13,
        30,
        49,
        21,
        49,
        49,
        49,
        15,
        15,
        18,
        48,
        39,
        38,
        47,
        26,
        13,
        0,
        49,
        25,
        49,
        19,
        0,
        36,
        46,
        0,
        41,
        49,
        23,
        0,
        49,
        10,
        49,
        15,
        37
      ],
      "weeklyHold": [
        45,
        35,
        23,
        13,
        31,
        21,
        36,
        25,
        19,
        18,
        44,
        44,
        27,
        9,
        27,
        12,
        5,
        48,
        32,
        22,
        10,
        0,
        10,
        49,
        38,
        29,
        26,
        0,
        47,
        20,
        35,
        13,
        5,
        19,
        30,
        34,
        35,
        26,
        15,
        20,
        36,
        32,
        38,
        39,
        0,
        49,
        0,
        37,
        0,
        8,
        0,
        21
      ],
      "weeklyBackordered": [
        37,
        49,
        21,
        41,
        27,
        41,
        49,
        23,
        49,
        11,
        28,
        49,
        22,
        43,
        0,
        6,
        47,
        27,
        29,
        9,
        25,
        49,
        25,
        49,
        7,
        28,
        14,
        0,
        21,
        49,
        35,
        32,
        47,
        31,
        3,
        19,
        49,
        0,
        25,
        6,
        5,
        30,
        0,
        49,
        37,
        34,
        12,
        0,
        24,
        25,
        1,
        31
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        0,
        40,
        49,
        23,
        24,
        0,
        28,
        49,
        25,
        9,
        49,
        0,
        23,
        49,
        45,
        19,
        7,
        12,
        33,
        21,
        40,
        30,
        36,
        37,
        0,
        47,
        0,
        36,
        10,
        29,
        0,
        38,
        49,
        49,
        22,
        37,
        0,
        41,
        12,
        49,
        27,
        49,
        25,
        49,
        33,
        1,
        10,
        34,
        19,
        25,
        17,
        48
      ],
      "weeklyRejected": [
        40,
        36,
        37,
        47,
        21,
        0,
        25,
        25,
        49,
        32,
        27,
        0,
        42,
        28,
        38,
        2,
        0,
        29,
        36,
        17,
        0,
        0,
        46,
        38,
        7,
        48,
        14,
        3,
        48,
        25,
        42,
        35,
        4,
        49,
        45,
        30,
        16,
        10,
        1,
        44,
        25,
        49,
        0,
        21,
        46,
        28,
        16,
        9,
        0,
        49,
        0,
        32
      ],
      "weeklyHold": [
        49,
        0,
        31,
        7,
        46,
        40,
        29,
        7,
        14,
        31,
        21,
        3,
        14,
        20,
        16,
        15,
        4,
        46,
        25,
        0,
        11,
        46,
        0,
        4,
        0,
        48,
        0,
        9,
        28,
        25,
        39,
        0,
        0,
        26,
        0,
        27,
        49,
        5,
        27,
        42,
        17,
        35,
        14,
        49,
        27,
        33,
        22,
        49,
        0,
        0,
        49,
        48
      ],
      "weeklyBackordered": [
        38,
        18,
        0,
        0,
        48,
        43,
        49,
        42,
        26,
        32,
        14,
        46,
        33,
        41,
        31,
        24,
        3,
        36,
        20,
        9,
        44,
        19,
        19,
        12,
        19,
        13,
        42,
        8,
        46,
        28,
        49,
        27,
        25,
        28,
        0,
        0,
        28,
        31,
        22,
        0,
        0,
        48,
        0,
        27,
        8,
        39,
        49,
        0,
        0,
        8,
        49,
        22
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        27,
        29,
        39,
        27,
        1,
        0,
        1,
        49,
        48,
        42,
        0,
        9,
        24,
        38,
        15,
        9,
        18,
        33,
        13,
        16,
        7,
        8,
        49,
        44,
        14,
        37,
        0,
        49,
        7,
        19,
        31,
        27,
        49,
        41,
        48,
        46,
        23,
        18,
        18,
        49,
        13,
        44,
        28,
        37,
        41,
        16,
        0,
        20,
        24,
        42,
        9,
        32
      ],
      "weeklyRejected": [
        40,
        10,
        49,
        20,
        0,
        0,
        23,
        25,
        49,
        46,
        29,
        27,
        49,
        24,
        43,
        13,
        34,
        24,
        26,
        49,
        13,
        32,
        19,
        33,
        20,
        13,
        30,
        26,
        47,
        27,
        49,
        42,
        48,
        26,
        34,
        49,
        30,
        26,
        16,
        34,
        43,
        11,
        8,
        26,
        44,
        24,
        1,
        45,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        49,
        43,
        26,
        11,
        0,
        25,
        45,
        30,
        16,
        30,
        47,
        26,
        33,
        1,
        40,
        23,
        40,
        14,
        45,
        12,
        7,
        18,
        0,
        49,
        15,
        19,
        49,
        18,
        49,
        15,
        48,
        32,
        18,
        16,
        48,
        2,
        17,
        16,
        49,
        24,
        15,
        26,
        10,
        40,
        39,
        43,
        26,
        49,
        22,
        49,
        39,
        23
      ],
      "weeklyBackordered": [
        49,
        10,
        0,
        41,
        13,
        16,
        25,
        15,
        32,
        2,
        15,
        39,
        23,
        0,
        36,
        7,
        49,
        11,
        4,
        1,
        45,
        14,
        37,
        35,
        7,
        46,
        1,
        0,
        29,
        49,
        18,
        30,
        48,
        49,
        49,
        23,
        32,
        8,
        1,
        2,
        27,
        48,
        0,
        49,
        18,
        43,
        49,
        11,
        0,
        49,
        25,
        16
      ]
    },
    {
      "id": "FB70040E",
      "weeklyAccepted": [
        5,
        23,
        45,
        26,
        17,
        2,
        15,
        20,
        34,
        47,
        25,
        0,
        26,
        21,
        15,
        0,
        22,
        21,
        16,
        22,
        15,
        21,
        49,
        42,
        0,
        10,
        19,
        34,
        39,
        19,
        49,
        19,
        48,
        31,
        47,
        26,
        47,
        17,
        29,
        49,
        0,
        24,
        12,
        41,
        17,
        49,
        24,
        14,
        23,
        48,
        0,
        12
      ],
      "weeklyRejected": [
        40,
        49,
        49,
        0,
        49,
        40,
        49,
        20,
        33,
        1,
        16,
        0,
        35,
        36,
        21,
        12,
        28,
        49,
        14,
        13,
        49,
        32,
        21,
        27,
        49,
        49,
        0,
        22,
        40,
        0,
        9,
        47,
        48,
        49,
        49,
        24,
        16,
        0,
        1,
        20,
        8,
        49,
        49,
        27,
        49,
        31,
        18,
        28,
        25,
        28,
        24,
        44
      ],
      "weeklyHold": [
        14,
        24,
        33,
        1,
        20,
        8,
        38,
        32,
        10,
        21,
        8,
        37,
        38,
        43,
        25,
        14,
        14,
        25,
        35,
        0,
        24,
        27,
        8,
        6,
        0,
        25,
        28,
        14,
        5,
        0,
        25,
        3,
        26,
        16,
        38,
        35,
        44,
        3,
        49,
        49,
        26,
        49,
        0,
        0,
        49,
        6,
        33,
        20,
        0,
        4,
        29,
        32
      ],
      "weeklyBackordered": [
        27,
        49,
        0,
        15,
        14,
        10,
        45,
        47,
        0,
        0,
        25,
        49,
        25,
        12,
        49,
        7,
        33,
        44,
        0,
        2,
        34,
        37,
        13,
        21,
        1,
        49,
        41,
        25,
        14,
        42,
        10,
        38,
        24,
        47,
        0,
        12,
        22,
        0,
        13,
        33,
        1,
        38,
        1,
        49,
        0,
        0,
        28,
        31,
        0,
        16,
        26,
        15
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        6,
        29,
        0,
        24,
        49,
        13,
        30,
        49,
        10,
        35,
        44,
        29,
        21,
        49,
        24,
        0,
        30,
        22,
        19,
        30,
        23,
        6,
        34,
        25,
        14,
        0,
        16,
        11,
        1,
        20,
        1,
        9,
        49,
        30,
        7,
        29,
        46,
        48,
        49,
        49,
        3,
        26,
        23,
        49,
        27,
        32,
        28,
        18,
        12,
        49,
        14,
        18
      ],
      "weeklyRejected": [
        43,
        1,
        20,
        33,
        42,
        16,
        8,
        32,
        22,
        19,
        18,
        0,
        34,
        46,
        31,
        49,
        28,
        4,
        49,
        47,
        0,
        49,
        49,
        2,
        9,
        49,
        20,
        35,
        46,
        32,
        3,
        33,
        45,
        0,
        7,
        49,
        15,
        36,
        15,
        49,
        39,
        36,
        41,
        11,
        36,
        39,
        0,
        30,
        0,
        14,
        23,
        34
      ],
      "weeklyHold": [
        32,
        0,
        49,
        14,
        39,
        12,
        26,
        49,
        0,
        17,
        36,
        3,
        20,
        49,
        25,
        0,
        23,
        0,
        18,
        9,
        0,
        0,
        0,
        28,
        26,
        47,
        43,
        0,
        49,
        0,
        37,
        7,
        0,
        32,
        25,
        35,
        0,
        0,
        38,
        6,
        30,
        36,
        0,
        49,
        9,
        21,
        42,
        31,
        0,
        49,
        18,
        36
      ],
      "weeklyBackordered": [
        27,
        1,
        21,
        7,
        37,
        46,
        13,
        29,
        49,
        13,
        34,
        0,
        3,
        29,
        47,
        49,
        49,
        46,
        14,
        19,
        27,
        45,
        32,
        5,
        1,
        8,
        42,
        49,
        10,
        49,
        27,
        15,
        48,
        30,
        1,
        0,
        1,
        4,
        27,
        40,
        26,
        45,
        1,
        49,
        49,
        22,
        42,
        5,
        30,
        28,
        49,
        46
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        0,
        1,
        44,
        26,
        11,
        4,
        1,
        14,
        29,
        42,
        48,
        0,
        20,
        39,
        11,
        32,
        49,
        33,
        0,
        49,
        44,
        34,
        26,
        43,
        19,
        47,
        35,
        19,
        1,
        14,
        35,
        0,
        45,
        31,
        48,
        36,
        0,
        48,
        23,
        49,
        34,
        0,
        49,
        31,
        16,
        21,
        17,
        0,
        0,
        9,
        28,
        9
      ],
      "weeklyRejected": [
        23,
        13,
        37,
        47,
        25,
        22,
        49,
        49,
        7,
        1,
        0,
        48,
        49,
        23,
        48,
        49,
        8,
        27,
        35,
        0,
        40,
        20,
        49,
        21,
        2,
        49,
        33,
        36,
        48,
        36,
        49,
        36,
        48,
        46,
        27,
        31,
        49,
        0,
        1,
        26,
        41,
        48,
        29,
        43,
        45,
        43,
        0,
        0,
        17,
        49,
        0,
        28
      ],
      "weeklyHold": [
        26,
        49,
        14,
        21,
        29,
        12,
        41,
        2,
        0,
        36,
        5,
        12,
        36,
        30,
        8,
        28,
        22,
        48,
        7,
        13,
        39,
        41,
        42,
        27,
        39,
        8,
        25,
        49,
        36,
        49,
        48,
        8,
        8,
        12,
        30,
        49,
        27,
        1,
        1,
        33,
        49,
        31,
        6,
        34,
        25,
        10,
        25,
        35,
        35,
        40,
        33,
        0
      ],
      "weeklyBackordered": [
        25,
        27,
        0,
        23,
        49,
        46,
        0,
        40,
        37,
        39,
        32,
        49,
        3,
        49,
        49,
        44,
        47,
        49,
        0,
        32,
        37,
        1,
        0,
        1,
        12,
        16,
        49,
        49,
        10,
        37,
        27,
        47,
        10,
        37,
        0,
        17,
        10,
        0,
        0,
        49,
        33,
        48,
        18,
        49,
        31,
        19,
        25,
        12,
        6,
        15,
        33,
        29
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        49,
        31,
        4,
        8,
        34,
        44,
        1,
        39,
        43,
        0,
        8,
        0,
        39,
        21,
        10,
        1,
        32,
        13,
        13,
        25,
        33,
        36,
        34,
        40,
        0,
        47,
        5,
        27,
        49,
        30,
        0,
        0,
        15,
        0,
        48,
        38,
        25,
        11,
        38,
        32,
        0,
        49,
        40,
        25,
        49,
        23,
        11,
        12,
        17,
        49,
        49,
        0
      ],
      "weeklyRejected": [
        28,
        19,
        22,
        41,
        31,
        0,
        49,
        3,
        49,
        28,
        33,
        11,
        28,
        35,
        46,
        5,
        48,
        49,
        20,
        0,
        0,
        26,
        43,
        34,
        3,
        49,
        49,
        0,
        28,
        31,
        19,
        26,
        20,
        18,
        22,
        49,
        36,
        21,
        49,
        12,
        32,
        31,
        26,
        43,
        24,
        30,
        45,
        18,
        23,
        49,
        27,
        6
      ],
      "weeklyHold": [
        39,
        11,
        49,
        20,
        18,
        44,
        15,
        16,
        12,
        3,
        47,
        23,
        14,
        30,
        34,
        30,
        0,
        14,
        8,
        27,
        9,
        33,
        15,
        15,
        9,
        13,
        35,
        18,
        16,
        41,
        48,
        22,
        2,
        8,
        0,
        33,
        16,
        0,
        16,
        49,
        34,
        2,
        18,
        28,
        49,
        2,
        41,
        49,
        1,
        28,
        22,
        49
      ],
      "weeklyBackordered": [
        20,
        18,
        1,
        17,
        33,
        12,
        10,
        22,
        30,
        14,
        4,
        42,
        6,
        37,
        25,
        45,
        49,
        27,
        6,
        0,
        49,
        26,
        0,
        29,
        21,
        14,
        23,
        21,
        19,
        42,
        22,
        42,
        48,
        27,
        49,
        21,
        26,
        20,
        0,
        13,
        0,
        39,
        0,
        3,
        3,
        3,
        49,
        12,
        0,
        25,
        16,
        24
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        28,
        17,
        19,
        39,
        8,
        6,
        15,
        49,
        48,
        9,
        37,
        8,
        18,
        47,
        29,
        7,
        48,
        9,
        16,
        6,
        49,
        44,
        31,
        21,
        5,
        12,
        43,
        13,
        1,
        41,
        28,
        33,
        49,
        9,
        42,
        49,
        6,
        34,
        35,
        49,
        21,
        12,
        49,
        12,
        49,
        41,
        0,
        5,
        11,
        40,
        8,
        48
      ],
      "weeklyRejected": [
        32,
        8,
        21,
        16,
        49,
        20,
        49,
        49,
        49,
        39,
        3,
        14,
        23,
        16,
        44,
        15,
        27,
        49,
        49,
        14,
        16,
        49,
        22,
        16,
        12,
        44,
        2,
        29,
        14,
        8,
        35,
        35,
        0,
        45,
        15,
        45,
        46,
        43,
        25,
        0,
        7,
        27,
        39,
        15,
        47,
        20,
        4,
        12,
        17,
        37,
        7,
        34
      ],
      "weeklyHold": [
        19,
        10,
        35,
        20,
        38,
        17,
        17,
        11,
        0,
        31,
        31,
        3,
        18,
        3,
        5,
        45,
        13,
        27,
        17,
        9,
        32,
        27,
        49,
        21,
        0,
        1,
        48,
        21,
        49,
        30,
        46,
        0,
        17,
        16,
        20,
        41,
        0,
        20,
        24,
        21,
        15,
        45,
        49,
        25,
        5,
        26,
        35,
        0,
        0,
        36,
        32,
        29
      ],
      "weeklyBackordered": [
        19,
        1,
        26,
        32,
        30,
        35,
        41,
        11,
        49,
        18,
        30,
        42,
        9,
        7,
        12,
        49,
        31,
        49,
        16,
        49,
        30,
        16,
        15,
        8,
        19,
        16,
        26,
        40,
        10,
        18,
        36,
        30,
        18,
        39,
        9,
        7,
        38,
        0,
        45,
        16,
        23,
        14,
        7,
        49,
        22,
        15,
        30,
        18,
        20,
        30,
        33,
        1
      ]
    },
    {
      "id": "F04529EA",
      "weeklyAccepted": [
        27,
        3,
        49,
        33,
        46,
        15,
        30,
        49,
        42,
        49,
        7,
        0,
        25,
        48,
        17,
        20,
        30,
        30,
        31,
        13,
        49,
        35,
        49,
        49,
        0,
        4,
        2,
        23,
        30,
        28,
        16,
        0,
        44,
        49,
        4,
        40,
        31,
        45,
        30,
        19,
        47,
        14,
        45,
        24,
        26,
        5,
        5,
        21,
        49,
        49,
        0,
        31
      ],
      "weeklyRejected": [
        38,
        29,
        28,
        42,
        14,
        17,
        49,
        49,
        35,
        29,
        31,
        0,
        42,
        48,
        30,
        0,
        49,
        13,
        49,
        22,
        49,
        25,
        20,
        30,
        33,
        49,
        32,
        15,
        48,
        10,
        24,
        28,
        17,
        31,
        34,
        49,
        11,
        47,
        16,
        36,
        42,
        35,
        39,
        0,
        46,
        41,
        20,
        15,
        4,
        49,
        6,
        17
      ],
      "weeklyHold": [
        49,
        3,
        23,
        1,
        17,
        0,
        24,
        7,
        17,
        17,
        36,
        43,
        35,
        19,
        15,
        29,
        9,
        38,
        18,
        32,
        0,
        19,
        22,
        6,
        19,
        47,
        3,
        7,
        25,
        0,
        23,
        12,
        44,
        35,
        48,
        14,
        39,
        15,
        35,
        37,
        49,
        49,
        44,
        40,
        49,
        24,
        39,
        47,
        0,
        17,
        27,
        27
      ],
      "weeklyBackordered": [
        20,
        39,
        23,
        30,
        46,
        36,
        44,
        48,
        16,
        40,
        39,
        49,
        17,
        0,
        20,
        49,
        27,
        45,
        0,
        14,
        39,
        30,
        8,
        35,
        31,
        27,
        1,
        42,
        30,
        35,
        45,
        46,
        39,
        49,
        0,
        0,
        21,
        0,
        0,
        8,
        27,
        17,
        17,
        41,
        17,
        0,
        9,
        3,
        48,
        41,
        24,
        8
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        21,
        18,
        19,
        13,
        36,
        26,
        1,
        33,
        1,
        0,
        49,
        22,
        49,
        8,
        9,
        24,
        25,
        28,
        31,
        33,
        49,
        31,
        0,
        6,
        27,
        9,
        15,
        9,
        6,
        49,
        49,
        0,
        49,
        9,
        22,
        18,
        24,
        14,
        29,
        49,
        19,
        22,
        0,
        30,
        35,
        18,
        8,
        49,
        31,
        33,
        0,
        10
      ],
      "weeklyRejected": [
        43,
        17,
        7,
        25,
        33,
        0,
        49,
        35,
        29,
        1,
        4,
        49,
        14,
        37,
        14,
        0,
        8,
        33,
        48,
        39,
        49,
        45,
        10,
        49,
        40,
        21,
        24,
        0,
        44,
        17,
        19,
        47,
        48,
        49,
        49,
        37,
        39,
        29,
        1,
        27,
        10,
        30,
        49,
        12,
        40,
        38,
        0,
        15,
        5,
        49,
        18,
        0
      ],
      "weeklyHold": [
        45,
        49,
        32,
        13,
        6,
        48,
        49,
        35,
        14,
        15,
        31,
        36,
        40,
        29,
        34,
        0,
        48,
        25,
        12,
        9,
        0,
        22,
        23,
        20,
        43,
        35,
        15,
        5,
        34,
        13,
        42,
        46,
        32,
        48,
        48,
        19,
        26,
        0,
        49,
        19,
        20,
        42,
        21,
        13,
        13,
        29,
        9,
        24,
        14,
        25,
        32,
        30
      ],
      "weeklyBackordered": [
        28,
        9,
        0,
        28,
        48,
        46,
        49,
        14,
        49,
        4,
        15,
        49,
        11,
        21,
        26,
        47,
        3,
        20,
        49,
        49,
        34,
        0,
        42,
        0,
        18,
        20,
        7,
        0,
        10,
        37,
        39,
        31,
        15,
        20,
        0,
        13,
        38,
        2,
        48,
        14,
        0,
        48,
        37,
        32,
        29,
        46,
        40,
        22,
        7,
        0,
        38,
        12
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyAccepted": [
        0,
        24,
        49,
        23,
        26,
        24,
        13,
        49,
        48,
        0,
        46,
        0,
        17,
        47,
        34,
        4,
        5,
        21,
        3,
        35,
        26,
        43,
        26,
        15,
        22,
        38,
        14,
        49,
        45,
        30,
        19,
        28,
        31,
        29,
        48,
        45,
        47,
        12,
        22,
        31,
        19,
        21,
        14,
        15,
        41,
        27,
        11,
        31,
        17,
        29,
        18,
        0
      ],
      "weeklyRejected": [
        24,
        23,
        24,
        0,
        49,
        6,
        29,
        49,
        28,
        20,
        44,
        28,
        29,
        43,
        31,
        32,
        22,
        49,
        49,
        20,
        45,
        29,
        14,
        34,
        13,
        49,
        33,
        0,
        48,
        25,
        16,
        17,
        23,
        16,
        3,
        49,
        5,
        29,
        17,
        45,
        7,
        48,
        49,
        40,
        19,
        25,
        7,
        14,
        0,
        9,
        0,
        16
      ],
      "weeklyHold": [
        49,
        4,
        30,
        17,
        6,
        48,
        44,
        2,
        11,
        10,
        32,
        13,
        1,
        1,
        40,
        24,
        22,
        5,
        49,
        0,
        20,
        0,
        3,
        0,
        34,
        17,
        0,
        27,
        26,
        4,
        48,
        0,
        0,
        34,
        11,
        6,
        26,
        21,
        45,
        49,
        27,
        35,
        28,
        2,
        15,
        44,
        31,
        28,
        6,
        33,
        7,
        47
      ],
      "weeklyBackordered": [
        27,
        0,
        0,
        16,
        30,
        42,
        21,
        49,
        7,
        1,
        1,
        42,
        30,
        22,
        47,
        49,
        17,
        2,
        0,
        33,
        19,
        20,
        5,
        27,
        10,
        17,
        49,
        35,
        19,
        49,
        44,
        0,
        20,
        44,
        6,
        30,
        18,
        4,
        36,
        34,
        26,
        48,
        32,
        49,
        32,
        9,
        30,
        20,
        0,
        49,
        35,
        33
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        0,
        39,
        28,
        34,
        29,
        24,
        21,
        45,
        35,
        49,
        29,
        2,
        40,
        0,
        46,
        31,
        9,
        12,
        18,
        44,
        7,
        42,
        22,
        35,
        28,
        11,
        0,
        27,
        28,
        49,
        2,
        43,
        49,
        49,
        48,
        49,
        13,
        31,
        49,
        11,
        3,
        33,
        13,
        23,
        49,
        0,
        15,
        17,
        41,
        4,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        9,
        21,
        47,
        44,
        28,
        49,
        21,
        1,
        38,
        20,
        18,
        1,
        31,
        2,
        49,
        49,
        19,
        49,
        16,
        31,
        42,
        40,
        31,
        29,
        49,
        30,
        49,
        36,
        0,
        46,
        28,
        48,
        49,
        18,
        46,
        49,
        8,
        5,
        0,
        0,
        22,
        35,
        8,
        39,
        6,
        8,
        39,
        26,
        26,
        16,
        30
      ],
      "weeklyHold": [
        0,
        11,
        18,
        37,
        17,
        31,
        49,
        30,
        20,
        34,
        29,
        49,
        47,
        23,
        16,
        17,
        15,
        6,
        0,
        24,
        28,
        35,
        27,
        37,
        24,
        24,
        21,
        34,
        33,
        27,
        47,
        0,
        6,
        49,
        34,
        42,
        18,
        37,
        13,
        14,
        30,
        33,
        16,
        45,
        26,
        10,
        0,
        47,
        0,
        38,
        26,
        49
      ],
      "weeklyBackordered": [
        17,
        35,
        0,
        4,
        16,
        33,
        43,
        20,
        49,
        49,
        41,
        7,
        31,
        37,
        45,
        49,
        24,
        49,
        31,
        23,
        21,
        32,
        43,
        45,
        20,
        6,
        12,
        4,
        23,
        49,
        24,
        27,
        34,
        27,
        0,
        24,
        46,
        29,
        20,
        36,
        48,
        12,
        0,
        49,
        13,
        49,
        49,
        13,
        15,
        0,
        33,
        14
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        0,
        45,
        49,
        13,
        16,
        6,
        31,
        19,
        48,
        42,
        33,
        6,
        49,
        0,
        49,
        30,
        37,
        3,
        0,
        49,
        2,
        41,
        29,
        49,
        49,
        43,
        32,
        49,
        26,
        10,
        0,
        4,
        49,
        14,
        48,
        22,
        0,
        14,
        23,
        49,
        13,
        7,
        49,
        49,
        37,
        27,
        34,
        0,
        2,
        15,
        27,
        34
      ],
      "weeklyRejected": [
        16,
        37,
        49,
        37,
        42,
        49,
        49,
        47,
        43,
        1,
        13,
        0,
        21,
        16,
        11,
        16,
        26,
        49,
        17,
        48,
        47,
        40,
        12,
        34,
        28,
        49,
        7,
        36,
        23,
        25,
        13,
        47,
        48,
        30,
        27,
        49,
        44,
        27,
        2,
        26,
        19,
        27,
        19,
        34,
        49,
        19,
        0,
        49,
        14,
        18,
        12,
        25
      ],
      "weeklyHold": [
        49,
        19,
        42,
        1,
        49,
        28,
        0,
        12,
        1,
        49,
        16,
        26,
        48,
        1,
        36,
        10,
        5,
        30,
        10,
        22,
        49,
        42,
        29,
        37,
        0,
        46,
        20,
        15,
        20,
        20,
        48,
        0,
        13,
        23,
        48,
        0,
        22,
        3,
        1,
        14,
        46,
        16,
        3,
        7,
        24,
        0,
        0,
        46,
        24,
        0,
        31,
        0
      ],
      "weeklyBackordered": [
        33,
        37,
        0,
        37,
        22,
        28,
        16,
        19,
        15,
        30,
        24,
        26,
        26,
        46,
        49,
        42,
        47,
        49,
        18,
        33,
        12,
        20,
        0,
        25,
        1,
        25,
        12,
        14,
        35,
        44,
        19,
        36,
        18,
        26,
        3,
        34,
        15,
        7,
        17,
        37,
        39,
        25,
        2,
        49,
        42,
        11,
        49,
        31,
        0,
        39,
        48,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        0,
        30,
        22,
        32,
        21,
        0,
        21,
        25,
        48,
        25,
        9,
        0,
        48,
        49,
        24,
        17,
        49,
        0,
        11,
        33,
        40,
        46,
        24,
        0,
        17,
        39,
        16,
        16,
        19,
        49,
        33,
        16,
        31,
        31,
        38,
        10,
        6,
        15,
        21,
        49,
        8,
        37,
        49,
        30,
        39,
        12,
        0,
        32,
        1,
        7,
        37,
        48
      ],
      "weeklyRejected": [
        49,
        20,
        49,
        47,
        0,
        38,
        2,
        40,
        49,
        1,
        42,
        49,
        36,
        18,
        38,
        5,
        36,
        25,
        36,
        37,
        4,
        6,
        4,
        2,
        16,
        42,
        27,
        49,
        48,
        27,
        49,
        25,
        37,
        32,
        22,
        49,
        35,
        21,
        1,
        1,
        48,
        0,
        49,
        48,
        49,
        45,
        20,
        29,
        0,
        33,
        41,
        22
      ],
      "weeklyHold": [
        3,
        10,
        16,
        17,
        21,
        22,
        0,
        2,
        36,
        45,
        21,
        1,
        41,
        32,
        33,
        0,
        4,
        13,
        48,
        3,
        28,
        16,
        47,
        4,
        13,
        48,
        0,
        30,
        16,
        13,
        36,
        6,
        0,
        0,
        48,
        37,
        21,
        9,
        20,
        29,
        27,
        23,
        27,
        9,
        24,
        49,
        24,
        18,
        30,
        33,
        43,
        29
      ],
      "weeklyBackordered": [
        23,
        20,
        0,
        8,
        11,
        16,
        47,
        17,
        24,
        0,
        15,
        18,
        23,
        25,
        30,
        26,
        49,
        48,
        12,
        37,
        34,
        43,
        0,
        38,
        8,
        49,
        37,
        0,
        12,
        49,
        25,
        49,
        19,
        31,
        25,
        0,
        15,
        0,
        24,
        49,
        0,
        48,
        0,
        49,
        45,
        39,
        43,
        30,
        49,
        29,
        40,
        1
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        30,
        33,
        1,
        36,
        1,
        0,
        18,
        2,
        26,
        36,
        0,
        11,
        17,
        49,
        38,
        9,
        49,
        38,
        6,
        28,
        22,
        15,
        46,
        0,
        42,
        16,
        49,
        28,
        9,
        49,
        0,
        40,
        13,
        21,
        48,
        2,
        20,
        48,
        29,
        0,
        49,
        39,
        31,
        13,
        19,
        32,
        12,
        49,
        0,
        18,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        24,
        49,
        5,
        49,
        0,
        49,
        32,
        49,
        14,
        35,
        17,
        49,
        36,
        47,
        0,
        38,
        44,
        46,
        36,
        0,
        14,
        0,
        12,
        14,
        36,
        22,
        45,
        48,
        11,
        37,
        43,
        32,
        16,
        48,
        44,
        29,
        22,
        28,
        11,
        40,
        49,
        0,
        43,
        34,
        32,
        0,
        4,
        25,
        49,
        0,
        25
      ],
      "weeklyHold": [
        29,
        0,
        49,
        15,
        18,
        6,
        33,
        49,
        0,
        27,
        47,
        9,
        16,
        1,
        42,
        49,
        27,
        0,
        0,
        0,
        30,
        18,
        49,
        17,
        14,
        47,
        49,
        14,
        49,
        27,
        48,
        4,
        11,
        18,
        0,
        34,
        0,
        26,
        21,
        49,
        0,
        49,
        22,
        23,
        49,
        24,
        49,
        49,
        0,
        0,
        0,
        16
      ],
      "weeklyBackordered": [
        7,
        0,
        19,
        21,
        5,
        22,
        7,
        49,
        29,
        22,
        19,
        29,
        3,
        26,
        25,
        40,
        40,
        27,
        0,
        0,
        49,
        0,
        0,
        5,
        15,
        7,
        47,
        8,
        29,
        49,
        49,
        8,
        33,
        49,
        0,
        0,
        23,
        0,
        45,
        0,
        48,
        48,
        3,
        5,
        13,
        0,
        12,
        31,
        6,
        40,
        49,
        5
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        12,
        28,
        7,
        12,
        49,
        19,
        32,
        46,
        42,
        13,
        45,
        0,
        49,
        38,
        25,
        28,
        49,
        0,
        21,
        46,
        31,
        18,
        6,
        32,
        10,
        47,
        17,
        35,
        36,
        42,
        22,
        0,
        2,
        25,
        36,
        21,
        29,
        16,
        49,
        44,
        19,
        38,
        30,
        0,
        42,
        23,
        0,
        20,
        49,
        31,
        19,
        14
      ],
      "weeklyRejected": [
        49,
        24,
        49,
        28,
        49,
        23,
        14,
        49,
        49,
        43,
        44,
        38,
        40,
        48,
        48,
        28,
        39,
        35,
        27,
        45,
        6,
        49,
        11,
        5,
        25,
        35,
        36,
        22,
        31,
        0,
        30,
        47,
        39,
        32,
        13,
        31,
        25,
        31,
        31,
        3,
        11,
        43,
        49,
        11,
        42,
        0,
        6,
        0,
        11,
        37,
        7,
        40
      ],
      "weeklyHold": [
        0,
        30,
        49,
        20,
        17,
        33,
        37,
        11,
        0,
        7,
        18,
        20,
        44,
        21,
        24,
        8,
        0,
        18,
        32,
        7,
        15,
        47,
        49,
        2,
        20,
        40,
        0,
        46,
        32,
        45,
        43,
        14,
        21,
        30,
        45,
        43,
        18,
        33,
        49,
        33,
        33,
        33,
        12,
        0,
        21,
        28,
        13,
        0,
        7,
        22,
        42,
        49
      ],
      "weeklyBackordered": [
        49,
        49,
        29,
        14,
        28,
        39,
        49,
        41,
        49,
        0,
        0,
        49,
        20,
        24,
        6,
        36,
        16,
        49,
        0,
        5,
        49,
        0,
        24,
        49,
        18,
        10,
        17,
        13,
        30,
        49,
        42,
        19,
        33,
        19,
        49,
        9,
        49,
        45,
        38,
        18,
        0,
        48,
        0,
        49,
        38,
        25,
        49,
        17,
        39,
        39,
        34,
        1
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        16,
        49,
        35,
        20,
        16,
        30,
        14,
        39,
        32,
        41,
        43,
        21,
        42,
        39,
        24,
        11,
        40,
        9,
        8,
        31,
        29,
        29,
        9,
        49,
        37,
        47,
        13,
        41,
        4,
        14,
        26,
        14,
        32,
        0,
        48,
        17,
        31,
        48,
        19,
        48,
        19,
        29,
        41,
        26,
        49,
        0,
        8,
        38,
        19,
        33,
        22,
        25
      ],
      "weeklyRejected": [
        41,
        12,
        27,
        11,
        15,
        0,
        37,
        27,
        45,
        42,
        10,
        36,
        18,
        0,
        25,
        9,
        17,
        13,
        34,
        37,
        49,
        37,
        49,
        41,
        27,
        41,
        38,
        0,
        42,
        33,
        49,
        37,
        35,
        0,
        21,
        49,
        49,
        26,
        4,
        48,
        48,
        30,
        40,
        13,
        49,
        37,
        8,
        40,
        0,
        49,
        25,
        49
      ],
      "weeklyHold": [
        0,
        0,
        32,
        42,
        28,
        35,
        0,
        16,
        35,
        31,
        17,
        49,
        19,
        24,
        0,
        20,
        22,
        0,
        0,
        20,
        0,
        38,
        29,
        26,
        23,
        12,
        0,
        17,
        33,
        0,
        35,
        8,
        0,
        49,
        40,
        45,
        47,
        0,
        49,
        13,
        26,
        49,
        31,
        49,
        1,
        3,
        0,
        32,
        0,
        49,
        0,
        37
      ],
      "weeklyBackordered": [
        1,
        1,
        0,
        38,
        46,
        46,
        29,
        43,
        2,
        21,
        36,
        23,
        49,
        0,
        28,
        41,
        20,
        48,
        49,
        39,
        16,
        7,
        6,
        38,
        40,
        2,
        49,
        19,
        41,
        31,
        29,
        35,
        8,
        0,
        38,
        10,
        5,
        48,
        26,
        3,
        28,
        22,
        47,
        49,
        49,
        48,
        37,
        2,
        15,
        23,
        49,
        45
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        2,
        30,
        36,
        17,
        41,
        16,
        29,
        19,
        48,
        0,
        30,
        21,
        49,
        1,
        36,
        31,
        37,
        14,
        49,
        8,
        17,
        28,
        35,
        48,
        20,
        19,
        0,
        30,
        1,
        28,
        0,
        3,
        49,
        38,
        27,
        25,
        35,
        20,
        14,
        23,
        0,
        49,
        49,
        23,
        4,
        45,
        28,
        30,
        21,
        35,
        23,
        7
      ],
      "weeklyRejected": [
        34,
        11,
        18,
        27,
        49,
        42,
        9,
        0,
        49,
        28,
        30,
        29,
        49,
        48,
        48,
        27,
        43,
        22,
        49,
        6,
        21,
        41,
        12,
        32,
        26,
        29,
        49,
        20,
        2,
        32,
        15,
        38,
        37,
        31,
        26,
        5,
        28,
        49,
        1,
        45,
        31,
        49,
        39,
        24,
        49,
        23,
        31,
        49,
        6,
        49,
        0,
        23
      ],
      "weeklyHold": [
        37,
        46,
        41,
        18,
        17,
        5,
        39,
        8,
        47,
        11,
        28,
        49,
        23,
        49,
        32,
        49,
        8,
        22,
        27,
        11,
        14,
        20,
        0,
        49,
        18,
        7,
        15,
        49,
        48,
        9,
        48,
        13,
        33,
        43,
        48,
        0,
        7,
        12,
        27,
        36,
        49,
        14,
        0,
        43,
        28,
        20,
        47,
        49,
        14,
        27,
        10,
        0
      ],
      "weeklyBackordered": [
        43,
        2,
        0,
        25,
        43,
        46,
        29,
        31,
        41,
        24,
        17,
        49,
        27,
        0,
        34,
        15,
        46,
        0,
        0,
        0,
        46,
        49,
        24,
        49,
        22,
        10,
        17,
        49,
        39,
        36,
        6,
        31,
        33,
        49,
        15,
        14,
        49,
        0,
        24,
        7,
        30,
        48,
        20,
        49,
        16,
        42,
        49,
        39,
        38,
        32,
        40,
        25
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        17,
        48,
        28,
        29,
        2,
        11,
        14,
        46,
        39,
        19,
        44,
        14,
        40,
        28,
        1,
        36,
        47,
        21,
        0,
        49,
        0,
        36,
        0,
        34,
        22,
        21,
        17,
        0,
        49,
        29,
        40,
        0,
        2,
        28,
        48,
        27,
        17,
        17,
        44,
        47,
        2,
        25,
        49,
        25,
        47,
        5,
        0,
        29,
        0,
        49,
        33,
        4
      ],
      "weeklyRejected": [
        49,
        49,
        49,
        19,
        49,
        49,
        28,
        38,
        46,
        12,
        14,
        49,
        15,
        0,
        48,
        11,
        6,
        49,
        46,
        15,
        0,
        0,
        0,
        0,
        17,
        49,
        49,
        26,
        38,
        24,
        49,
        26,
        34,
        17,
        0,
        49,
        26,
        11,
        49,
        0,
        39,
        20,
        6,
        1,
        48,
        27,
        11,
        14,
        31,
        24,
        32,
        36
      ],
      "weeklyHold": [
        0,
        0,
        49,
        1,
        7,
        40,
        49,
        2,
        0,
        17,
        47,
        27,
        16,
        37,
        38,
        0,
        7,
        23,
        22,
        3,
        10,
        29,
        49,
        21,
        43,
        1,
        49,
        49,
        32,
        15,
        48,
        15,
        0,
        49,
        36,
        31,
        5,
        19,
        29,
        33,
        0,
        36,
        0,
        32,
        30,
        37,
        0,
        39,
        0,
        49,
        31,
        43
      ],
      "weeklyBackordered": [
        29,
        1,
        0,
        42,
        41,
        46,
        5,
        49,
        40,
        21,
        49,
        25,
        32,
        33,
        0,
        23,
        27,
        49,
        0,
        24,
        23,
        22,
        11,
        17,
        18,
        15,
        11,
        49,
        20,
        48,
        41,
        6,
        29,
        12,
        0,
        19,
        49,
        42,
        18,
        0,
        47,
        48,
        0,
        49,
        45,
        37,
        49,
        36,
        13,
        0,
        42,
        11
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        0,
        14,
        2,
        0,
        4,
        49,
        49,
        28,
        45,
        0,
        30,
        0,
        33,
        49,
        1,
        34,
        49,
        10,
        22,
        32,
        18,
        46,
        48,
        29,
        15,
        47,
        0,
        7,
        1,
        22,
        28,
        49,
        49,
        30,
        28,
        31,
        37,
        42,
        28,
        49,
        38,
        23,
        31,
        35,
        49,
        10,
        7,
        49,
        6,
        49,
        36,
        4
      ],
      "weeklyRejected": [
        38,
        34,
        49,
        14,
        23,
        32,
        49,
        0,
        41,
        8,
        0,
        22,
        46,
        24,
        48,
        28,
        49,
        24,
        49,
        0,
        0,
        30,
        49,
        18,
        0,
        49,
        32,
        29,
        48,
        13,
        5,
        41,
        0,
        3,
        49,
        49,
        16,
        49,
        3,
        32,
        38,
        49,
        0,
        48,
        18,
        23,
        24,
        0,
        22,
        29,
        37,
        17
      ],
      "weeklyHold": [
        0,
        4,
        36,
        31,
        49,
        29,
        49,
        9,
        28,
        49,
        0,
        36,
        1,
        23,
        17,
        49,
        6,
        2,
        0,
        30,
        18,
        18,
        44,
        30,
        0,
        48,
        27,
        20,
        40,
        6,
        48,
        0,
        10,
        35,
        0,
        36,
        0,
        10,
        29,
        23,
        41,
        49,
        10,
        11,
        49,
        14,
        32,
        49,
        2,
        0,
        44,
        27
      ],
      "weeklyBackordered": [
        48,
        21,
        0,
        2,
        45,
        38,
        47,
        38,
        25,
        49,
        4,
        49,
        40,
        41,
        33,
        30,
        10,
        49,
        0,
        22,
        25,
        45,
        15,
        29,
        15,
        41,
        49,
        49,
        23,
        49,
        32,
        25,
        4,
        43,
        0,
        0,
        33,
        42,
        31,
        49,
        16,
        29,
        28,
        27,
        39,
        22,
        43,
        0,
        29,
        35,
        49,
        49
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        49,
        49,
        49,
        48,
        1,
        28,
        34,
        46,
        20,
        30,
        22,
        0,
        0,
        15,
        10,
        0,
        36,
        3,
        0,
        8,
        31,
        49,
        15,
        26,
        0,
        13,
        47,
        26,
        19,
        40,
        6,
        49,
        8,
        1,
        33,
        20,
        30,
        29,
        28,
        22,
        33,
        49,
        0,
        4,
        44,
        33,
        22,
        1,
        23,
        32,
        30,
        48
      ],
      "weeklyRejected": [
        42,
        7,
        43,
        46,
        29,
        32,
        27,
        0,
        49,
        1,
        17,
        19,
        26,
        48,
        23,
        13,
        34,
        44,
        0,
        39,
        4,
        49,
        28,
        46,
        0,
        15,
        9,
        29,
        46,
        10,
        0,
        10,
        23,
        16,
        5,
        39,
        19,
        29,
        1,
        49,
        24,
        33,
        49,
        22,
        49,
        0,
        6,
        24,
        34,
        27,
        18,
        49
      ],
      "weeklyHold": [
        33,
        0,
        38,
        16,
        49,
        17,
        8,
        23,
        13,
        34,
        28,
        49,
        48,
        15,
        32,
        28,
        10,
        23,
        26,
        34,
        0,
        13,
        19,
        28,
        32,
        26,
        32,
        15,
        4,
        0,
        45,
        4,
        21,
        42,
        14,
        17,
        17,
        0,
        32,
        15,
        0,
        39,
        13,
        20,
        49,
        39,
        32,
        43,
        0,
        7,
        12,
        12
      ],
      "weeklyBackordered": [
        17,
        32,
        23,
        9,
        28,
        34,
        49,
        47,
        31,
        6,
        33,
        49,
        13,
        26,
        10,
        49,
        38,
        25,
        17,
        16,
        0,
        41,
        49,
        21,
        28,
        26,
        1,
        22,
        27,
        44,
        49,
        16,
        25,
        41,
        0,
        12,
        6,
        22,
        16,
        42,
        21,
        21,
        0,
        49,
        18,
        24,
        36,
        12,
        15,
        49,
        20,
        40
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        49,
        49,
        26,
        11,
        49,
        0,
        35,
        36,
        48,
        22,
        32,
        3,
        22,
        1,
        22,
        16,
        41,
        20,
        30,
        28,
        14,
        49,
        31,
        23,
        24,
        34,
        21,
        30,
        18,
        48,
        0,
        11,
        49,
        0,
        29,
        15,
        47,
        48,
        33,
        32,
        32,
        35,
        46,
        22,
        33,
        0,
        29,
        0,
        29,
        48,
        26,
        48
      ],
      "weeklyRejected": [
        27,
        27,
        37,
        17,
        33,
        35,
        47,
        0,
        4,
        48,
        19,
        17,
        21,
        39,
        48,
        0,
        8,
        47,
        42,
        0,
        49,
        16,
        0,
        35,
        32,
        49,
        49,
        29,
        22,
        48,
        49,
        47,
        25,
        18,
        14,
        49,
        39,
        40,
        1,
        20,
        11,
        14,
        49,
        3,
        49,
        35,
        22,
        17,
        19,
        49,
        0,
        27
      ],
      "weeklyHold": [
        23,
        35,
        32,
        1,
        48,
        22,
        0,
        21,
        15,
        17,
        47,
        49,
        35,
        11,
        28,
        30,
        0,
        26,
        40,
        8,
        0,
        0,
        25,
        11,
        40,
        48,
        45,
        11,
        46,
        27,
        24,
        10,
        10,
        49,
        40,
        39,
        31,
        34,
        1,
        49,
        49,
        49,
        0,
        47,
        42,
        8,
        49,
        48,
        7,
        49,
        41,
        41
      ],
      "weeklyBackordered": [
        26,
        49,
        0,
        31,
        49,
        46,
        24,
        49,
        49,
        0,
        49,
        31,
        49,
        12,
        25,
        15,
        49,
        27,
        0,
        0,
        12,
        49,
        49,
        9,
        27,
        46,
        1,
        0,
        14,
        40,
        7,
        48,
        48,
        49,
        4,
        0,
        49,
        0,
        27,
        0,
        48,
        31,
        14,
        49,
        32,
        27,
        49,
        0,
        49,
        29,
        26,
        3
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        0,
        49,
        27,
        12,
        49,
        0,
        43,
        27,
        39,
        0,
        19,
        17,
        19,
        41,
        35,
        16,
        39,
        11,
        4,
        0,
        49,
        34,
        49,
        45,
        6,
        47,
        16,
        49,
        1,
        49,
        35,
        25,
        49,
        22,
        46,
        9,
        38,
        48,
        49,
        49,
        36,
        49,
        29,
        15,
        49,
        18,
        0,
        3,
        17,
        18,
        21,
        46
      ],
      "weeklyRejected": [
        38,
        21,
        32,
        0,
        33,
        37,
        13,
        0,
        49,
        48,
        28,
        49,
        39,
        48,
        48,
        0,
        18,
        37,
        35,
        0,
        25,
        0,
        19,
        10,
        14,
        49,
        49,
        6,
        28,
        38,
        49,
        34,
        24,
        21,
        1,
        8,
        28,
        38,
        21,
        30,
        30,
        12,
        49,
        25,
        44,
        0,
        23,
        49,
        18,
        49,
        0,
        49
      ],
      "weeklyHold": [
        41,
        46,
        34,
        1,
        41,
        14,
        33,
        16,
        20,
        18,
        7,
        49,
        23,
        31,
        31,
        9,
        3,
        36,
        44,
        37,
        0,
        21,
        22,
        23,
        0,
        22,
        0,
        23,
        24,
        28,
        42,
        19,
        0,
        49,
        48,
        48,
        0,
        23,
        8,
        10,
        35,
        32,
        10,
        37,
        26,
        27,
        0,
        0,
        1,
        15,
        0,
        29
      ],
      "weeklyBackordered": [
        28,
        30,
        0,
        27,
        12,
        46,
        14,
        7,
        35,
        49,
        49,
        24,
        41,
        0,
        31,
        11,
        23,
        49,
        46,
        0,
        41,
        2,
        29,
        16,
        48,
        15,
        8,
        25,
        1,
        49,
        3,
        49,
        39,
        48,
        20,
        0,
        33,
        18,
        43,
        15,
        22,
        48,
        8,
        49,
        29,
        49,
        49,
        17,
        33,
        33,
        9,
        30
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        49,
        49,
        23,
        48,
        22,
        34,
        7,
        49,
        47,
        13,
        25,
        19,
        40,
        7,
        47,
        38,
        16,
        0,
        33,
        8,
        35,
        36,
        13,
        17,
        38,
        47,
        10,
        36,
        35,
        49,
        21,
        25,
        38,
        45,
        48,
        38,
        0,
        47,
        0,
        22,
        30,
        17,
        40,
        27,
        49,
        0,
        25,
        0,
        28,
        43,
        28,
        35
      ],
      "weeklyRejected": [
        37,
        4,
        2,
        23,
        23,
        32,
        7,
        29,
        25,
        3,
        46,
        0,
        28,
        12,
        46,
        15,
        49,
        49,
        29,
        45,
        4,
        34,
        0,
        34,
        24,
        49,
        49,
        31,
        29,
        38,
        43,
        37,
        10,
        19,
        0,
        49,
        40,
        31,
        1,
        29,
        24,
        49,
        5,
        3,
        21,
        0,
        12,
        16,
        48,
        32,
        7,
        41
      ],
      "weeklyHold": [
        28,
        20,
        49,
        7,
        2,
        0,
        25,
        28,
        0,
        21,
        35,
        2,
        21,
        26,
        49,
        0,
        24,
        23,
        12,
        21,
        14,
        49,
        19,
        25,
        45,
        39,
        21,
        36,
        43,
        33,
        25,
        0,
        34,
        42,
        40,
        4,
        19,
        8,
        49,
        9,
        49,
        23,
        0,
        16,
        49,
        0,
        41,
        49,
        0,
        20,
        39,
        0
      ],
      "weeklyBackordered": [
        42,
        43,
        17,
        34,
        49,
        21,
        0,
        31,
        4,
        49,
        7,
        41,
        3,
        37,
        48,
        22,
        7,
        38,
        29,
        40,
        25,
        45,
        10,
        22,
        30,
        3,
        34,
        24,
        41,
        36,
        0,
        49,
        48,
        39,
        49,
        0,
        25,
        0,
        41,
        2,
        32,
        42,
        9,
        0,
        13,
        49,
        27,
        4,
        27,
        49,
        44,
        17
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        11,
        25,
        25,
        0,
        36,
        13,
        13,
        43,
        17,
        32,
        48,
        12,
        25,
        41,
        10,
        7,
        49,
        24,
        0,
        25,
        7,
        35,
        39,
        40,
        8,
        12,
        0,
        12,
        49,
        34,
        9,
        9,
        40,
        35,
        21,
        8,
        47,
        48,
        30,
        44,
        0,
        35,
        9,
        14,
        49,
        26,
        0,
        49,
        16,
        18,
        13,
        20
      ],
      "weeklyRejected": [
        15,
        15,
        24,
        33,
        8,
        49,
        49,
        10,
        20,
        8,
        49,
        23,
        23,
        48,
        26,
        49,
        19,
        10,
        49,
        16,
        25,
        1,
        4,
        26,
        12,
        49,
        49,
        22,
        43,
        30,
        1,
        35,
        36,
        39,
        49,
        32,
        4,
        38,
        28,
        37,
        17,
        38,
        24,
        18,
        49,
        31,
        0,
        21,
        4,
        29,
        19,
        20
      ],
      "weeklyHold": [
        49,
        49,
        49,
        15,
        0,
        33,
        41,
        25,
        26,
        29,
        47,
        43,
        48,
        27,
        31,
        0,
        0,
        32,
        39,
        27,
        12,
        5,
        28,
        26,
        33,
        37,
        2,
        31,
        49,
        31,
        37,
        30,
        18,
        40,
        0,
        2,
        9,
        30,
        22,
        9,
        49,
        16,
        9,
        19,
        30,
        19,
        18,
        22,
        7,
        11,
        0,
        12
      ],
      "weeklyBackordered": [
        37,
        49,
        4,
        48,
        14,
        18,
        49,
        12,
        8,
        0,
        0,
        32,
        31,
        21,
        35,
        22,
        49,
        49,
        13,
        13,
        44,
        0,
        0,
        45,
        18,
        23,
        37,
        0,
        15,
        49,
        12,
        49,
        4,
        36,
        28,
        0,
        20,
        40,
        21,
        18,
        25,
        48,
        0,
        49,
        27,
        43,
        29,
        46,
        21,
        49,
        42,
        21
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        0,
        11,
        11,
        48,
        22,
        0,
        18,
        14,
        23,
        0,
        44,
        32,
        0,
        13,
        21,
        27,
        23,
        5,
        19,
        20,
        1,
        33,
        24,
        41,
        10,
        18,
        12,
        0,
        18,
        35,
        0,
        23,
        44,
        29,
        48,
        39,
        36,
        48,
        49,
        49,
        20,
        46,
        49,
        12,
        25,
        19,
        10,
        15,
        0,
        25,
        0,
        48
      ],
      "weeklyRejected": [
        33,
        0,
        49,
        15,
        18,
        31,
        7,
        36,
        49,
        30,
        31,
        0,
        31,
        7,
        34,
        35,
        3,
        15,
        15,
        25,
        49,
        38,
        31,
        23,
        16,
        25,
        35,
        11,
        48,
        38,
        0,
        20,
        39,
        49,
        21,
        38,
        16,
        40,
        1,
        26,
        22,
        45,
        35,
        8,
        37,
        49,
        13,
        20,
        0,
        33,
        0,
        49
      ],
      "weeklyHold": [
        49,
        29,
        23,
        1,
        5,
        25,
        24,
        43,
        4,
        21,
        47,
        31,
        48,
        32,
        12,
        19,
        12,
        48,
        26,
        49,
        0,
        22,
        7,
        37,
        38,
        46,
        32,
        0,
        46,
        0,
        0,
        35,
        18,
        6,
        42,
        0,
        49,
        0,
        37,
        17,
        49,
        49,
        0,
        49,
        18,
        32,
        8,
        29,
        0,
        34,
        0,
        34
      ],
      "weeklyBackordered": [
        37,
        30,
        21,
        9,
        49,
        28,
        25,
        35,
        49,
        15,
        49,
        0,
        3,
        46,
        26,
        28,
        19,
        49,
        25,
        24,
        6,
        32,
        10,
        21,
        4,
        34,
        14,
        23,
        45,
        45,
        0,
        49,
        24,
        35,
        0,
        0,
        18,
        0,
        13,
        49,
        14,
        48,
        26,
        49,
        21,
        49,
        39,
        35,
        11,
        49,
        17,
        32
      ]
    },
    {
      "id": "93D87233",
      "weeklyAccepted": [
        8,
        33,
        49,
        13,
        12,
        18,
        2,
        2,
        34,
        49,
        49,
        0,
        49,
        49,
        49,
        30,
        41,
        0,
        5,
        26,
        9,
        49,
        46,
        38,
        11,
        10,
        27,
        18,
        21,
        27,
        13,
        0,
        49,
        0,
        48,
        43,
        38,
        24,
        35,
        49,
        0,
        0,
        41,
        25,
        49,
        34,
        9,
        15,
        2,
        47,
        9,
        5
      ],
      "weeklyRejected": [
        36,
        30,
        49,
        25,
        49,
        13,
        44,
        7,
        48,
        31,
        13,
        21,
        38,
        48,
        25,
        47,
        25,
        36,
        49,
        5,
        31,
        24,
        14,
        6,
        32,
        49,
        21,
        24,
        29,
        18,
        24,
        38,
        37,
        49,
        0,
        49,
        10,
        30,
        5,
        32,
        10,
        43,
        49,
        21,
        48,
        19,
        1,
        24,
        5,
        30,
        13,
        43
      ],
      "weeklyHold": [
        35,
        26,
        29,
        1,
        49,
        48,
        15,
        47,
        25,
        9,
        17,
        1,
        32,
        49,
        27,
        20,
        13,
        24,
        2,
        5,
        19,
        18,
        36,
        7,
        0,
        19,
        0,
        17,
        24,
        0,
        48,
        18,
        20,
        0,
        48,
        24,
        15,
        24,
        33,
        47,
        14,
        20,
        5,
        32,
        49,
        32,
        9,
        25,
        6,
        39,
        49,
        49
      ],
      "weeklyBackordered": [
        28,
        13,
        20,
        21,
        47,
        40,
        49,
        37,
        41,
        0,
        20,
        18,
        28,
        41,
        47,
        31,
        49,
        23,
        0,
        25,
        49,
        16,
        18,
        14,
        1,
        25,
        1,
        9,
        49,
        49,
        31,
        48,
        3,
        46,
        20,
        0,
        17,
        7,
        39,
        39,
        22,
        32,
        9,
        49,
        1,
        3,
        15,
        49,
        48,
        38,
        16,
        25
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyAccepted": [
        31,
        11,
        27,
        20,
        20,
        13,
        1,
        49,
        12,
        32,
        49,
        49,
        47,
        16,
        39,
        49,
        15,
        38,
        0,
        27,
        19,
        0,
        18,
        34,
        40,
        37,
        49,
        49,
        15,
        33,
        32,
        49,
        49,
        38,
        38,
        20,
        17,
        12,
        9,
        33,
        0,
        33,
        38,
        9,
        49,
        15,
        11,
        45,
        0,
        8,
        0,
        0
      ],
      "weeklyRejected": [
        36,
        33,
        38,
        47,
        29,
        37,
        49,
        3,
        39,
        7,
        25,
        25,
        1,
        48,
        48,
        24,
        31,
        49,
        49,
        13,
        19,
        47,
        0,
        15,
        18,
        46,
        25,
        37,
        48,
        16,
        18,
        18,
        36,
        33,
        0,
        30,
        30,
        41,
        41,
        8,
        37,
        49,
        49,
        26,
        47,
        23,
        0,
        9,
        0,
        7,
        14,
        49
      ],
      "weeklyHold": [
        8,
        5,
        45,
        1,
        0,
        45,
        35,
        2,
        26,
        31,
        10,
        35,
        41,
        42,
        36,
        14,
        21,
        26,
        0,
        23,
        26,
        43,
        0,
        27,
        43,
        1,
        49,
        0,
        7,
        20,
        28,
        34,
        19,
        49,
        35,
        0,
        6,
        0,
        49,
        41,
        32,
        34,
        8,
        35,
        7,
        4,
        49,
        49,
        0,
        5,
        16,
        45
      ],
      "weeklyBackordered": [
        2,
        34,
        0,
        47,
        34,
        34,
        36,
        1,
        15,
        49,
        40,
        9,
        29,
        0,
        49,
        8,
        1,
        49,
        17,
        49,
        44,
        0,
        0,
        0,
        47,
        1,
        11,
        47,
        21,
        20,
        5,
        32,
        6,
        49,
        13,
        17,
        43,
        40,
        49,
        49,
        29,
        48,
        0,
        43,
        16,
        49,
        38,
        49,
        4,
        38,
        49,
        11
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        37,
        49,
        9,
        48,
        1,
        6,
        14,
        41,
        45,
        36,
        46,
        16,
        29,
        45,
        22,
        49,
        38,
        26,
        11,
        16,
        24,
        38,
        34,
        9,
        4,
        47,
        13,
        0,
        23,
        49,
        17,
        0,
        49,
        42,
        48,
        6,
        39,
        46,
        10,
        43,
        24,
        34,
        49,
        23,
        49,
        49,
        0,
        25,
        16,
        35,
        0,
        17
      ],
      "weeklyRejected": [
        32,
        1,
        22,
        47,
        49,
        0,
        36,
        34,
        49,
        1,
        3,
        8,
        31,
        45,
        19,
        15,
        36,
        17,
        0,
        49,
        15,
        26,
        47,
        18,
        23,
        49,
        28,
        38,
        48,
        0,
        23,
        38,
        22,
        49,
        49,
        49,
        0,
        38,
        1,
        6,
        26,
        10,
        15,
        0,
        35,
        3,
        10,
        26,
        0,
        35,
        0,
        46
      ],
      "weeklyHold": [
        12,
        17,
        49,
        1,
        44,
        29,
        0,
        31,
        12,
        10,
        35,
        27,
        18,
        38,
        27,
        47,
        16,
        21,
        0,
        21,
        0,
        22,
        49,
        0,
        2,
        48,
        41,
        28,
        49,
        6,
        45,
        4,
        11,
        43,
        48,
        22,
        0,
        0,
        38,
        1,
        2,
        49,
        44,
        11,
        10,
        46,
        36,
        0,
        0,
        31,
        41,
        0
      ],
      "weeklyBackordered": [
        39,
        34,
        17,
        12,
        34,
        46,
        35,
        29,
        42,
        0,
        18,
        16,
        42,
        15,
        1,
        43,
        3,
        18,
        25,
        26,
        47,
        45,
        45,
        4,
        13,
        6,
        46,
        46,
        1,
        27,
        32,
        49,
        33,
        49,
        20,
        0,
        10,
        6,
        40,
        1,
        48,
        48,
        10,
        49,
        20,
        0,
        46,
        36,
        0,
        32,
        47,
        12
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        0,
        4,
        49,
        0,
        49,
        33,
        16,
        46,
        48,
        39,
        45,
        0,
        22,
        33,
        10,
        43,
        27,
        15,
        8,
        0,
        0,
        48,
        31,
        49,
        49,
        45,
        46,
        2,
        1,
        10,
        0,
        25,
        45,
        32,
        33,
        40,
        21,
        21,
        32,
        49,
        27,
        40,
        49,
        28,
        19,
        0,
        19,
        0,
        0,
        49,
        8,
        29
      ],
      "weeklyRejected": [
        49,
        16,
        44,
        47,
        21,
        19,
        44,
        0,
        26,
        11,
        14,
        23,
        32,
        48,
        19,
        25,
        0,
        22,
        49,
        3,
        47,
        14,
        35,
        22,
        9,
        38,
        0,
        7,
        14,
        14,
        4,
        28,
        31,
        27,
        9,
        49,
        28,
        31,
        4,
        49,
        10,
        0,
        39,
        0,
        33,
        49,
        0,
        32,
        20,
        44,
        11,
        49
      ],
      "weeklyHold": [
        28,
        15,
        40,
        15,
        8,
        27,
        20,
        17,
        0,
        13,
        24,
        20,
        11,
        14,
        28,
        32,
        49,
        30,
        16,
        5,
        42,
        11,
        46,
        48,
        4,
        31,
        40,
        48,
        49,
        23,
        48,
        0,
        0,
        25,
        48,
        49,
        10,
        11,
        49,
        45,
        36,
        37,
        13,
        42,
        12,
        29,
        21,
        36,
        5,
        32,
        12,
        22
      ],
      "weeklyBackordered": [
        34,
        8,
        7,
        16,
        43,
        46,
        26,
        36,
        33,
        18,
        2,
        43,
        11,
        15,
        37,
        31,
        49,
        49,
        34,
        26,
        49,
        0,
        22,
        13,
        27,
        43,
        27,
        21,
        1,
        35,
        8,
        42,
        0,
        26,
        1,
        0,
        34,
        17,
        34,
        25,
        5,
        45,
        29,
        38,
        31,
        19,
        6,
        12,
        13,
        20,
        47,
        6
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        34,
        30,
        2,
        31,
        35,
        33,
        25,
        47,
        21,
        6,
        44,
        39,
        15,
        18,
        1,
        22,
        47,
        21,
        0,
        2,
        49,
        0,
        0,
        0,
        12,
        47,
        0,
        6,
        28,
        49,
        0,
        19,
        21,
        8,
        35,
        35,
        47,
        48,
        11,
        19,
        0,
        49,
        29,
        19,
        49,
        19,
        10,
        49,
        20,
        33,
        0,
        28
      ],
      "weeklyRejected": [
        38,
        49,
        19,
        47,
        25,
        0,
        38,
        22,
        45,
        1,
        49,
        49,
        0,
        47,
        41,
        32,
        0,
        41,
        45,
        0,
        15,
        0,
        49,
        49,
        33,
        49,
        49,
        25,
        44,
        0,
        19,
        28,
        29,
        32,
        0,
        49,
        40,
        4,
        21,
        0,
        25,
        17,
        19,
        0,
        32,
        3,
        0,
        10,
        0,
        32,
        0,
        21
      ],
      "weeklyHold": [
        46,
        49,
        49,
        1,
        0,
        48,
        13,
        25,
        7,
        10,
        47,
        27,
        48,
        1,
        45,
        0,
        0,
        43,
        30,
        0,
        44,
        24,
        45,
        7,
        49,
        1,
        19,
        30,
        49,
        16,
        48,
        6,
        43,
        0,
        2,
        17,
        27,
        0,
        30,
        49,
        27,
        21,
        28,
        49,
        32,
        49,
        49,
        49,
        19,
        39,
        19,
        49
      ],
      "weeklyBackordered": [
        49,
        11,
        10,
        43,
        39,
        37,
        18,
        25,
        11,
        0,
        11,
        49,
        3,
        12,
        34,
        5,
        49,
        23,
        0,
        17,
        49,
        14,
        21,
        24,
        22,
        14,
        36,
        17,
        11,
        49,
        25,
        21,
        18,
        28,
        12,
        23,
        47,
        25,
        22,
        0,
        20,
        48,
        33,
        14,
        20,
        31,
        11,
        26,
        47,
        35,
        34,
        19
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        37,
        36,
        18,
        24,
        28,
        0,
        38,
        35,
        37,
        21,
        22,
        0,
        0,
        0,
        1,
        16,
        38,
        26,
        0,
        0,
        40,
        42,
        35,
        19,
        11,
        35,
        29,
        49,
        29,
        49,
        12,
        0,
        49,
        24,
        26,
        44,
        40,
        48,
        19,
        25,
        7,
        34,
        49,
        8,
        49,
        24,
        31,
        30,
        26,
        40,
        8,
        48
      ],
      "weeklyRejected": [
        40,
        0,
        45,
        35,
        34,
        43,
        32,
        0,
        0,
        48,
        27,
        35,
        49,
        48,
        19,
        1,
        49,
        33,
        49,
        37,
        49,
        29,
        24,
        40,
        48,
        49,
        49,
        49,
        36,
        12,
        42,
        47,
        33,
        49,
        15,
        49,
        26,
        14,
        23,
        1,
        40,
        23,
        4,
        7,
        49,
        0,
        45,
        48,
        15,
        48,
        0,
        38
      ],
      "weeklyHold": [
        11,
        15,
        13,
        28,
        49,
        0,
        46,
        34,
        45,
        49,
        35,
        35,
        20,
        49,
        26,
        38,
        1,
        22,
        34,
        49,
        32,
        0,
        38,
        49,
        0,
        41,
        20,
        5,
        49,
        8,
        33,
        16,
        13,
        49,
        5,
        21,
        0,
        21,
        25,
        31,
        44,
        22,
        1,
        25,
        49,
        22,
        17,
        46,
        11,
        13,
        5,
        14
      ],
      "weeklyBackordered": [
        42,
        26,
        22,
        42,
        49,
        20,
        26,
        30,
        15,
        0,
        0,
        45,
        13,
        0,
        35,
        20,
        42,
        18,
        0,
        28,
        49,
        0,
        19,
        49,
        31,
        45,
        1,
        11,
        1,
        49,
        11,
        46,
        31,
        38,
        9,
        0,
        27,
        0,
        40,
        8,
        48,
        25,
        10,
        49,
        0,
        49,
        49,
        12,
        36,
        0,
        19,
        10
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyAccepted": [
        0,
        49,
        49,
        17,
        30,
        29,
        1,
        49,
        48,
        49,
        49,
        20,
        46,
        44,
        1,
        19,
        49,
        7,
        41,
        33,
        15,
        45,
        42,
        47,
        0,
        37,
        28,
        19,
        14,
        34,
        0,
        16,
        31,
        0,
        48,
        49,
        20,
        9,
        49,
        49,
        9,
        22,
        39,
        24,
        37,
        13,
        20,
        44,
        0,
        35,
        49,
        30
      ],
      "weeklyRejected": [
        49,
        0,
        25,
        47,
        7,
        8,
        49,
        9,
        47,
        25,
        29,
        1,
        40,
        30,
        38,
        2,
        0,
        47,
        22,
        0,
        33,
        24,
        49,
        49,
        19,
        49,
        3,
        19,
        43,
        6,
        0,
        29,
        33,
        16,
        38,
        49,
        44,
        16,
        19,
        14,
        19,
        40,
        49,
        0,
        48,
        34,
        22,
        26,
        20,
        28,
        0,
        49
      ],
      "weeklyHold": [
        14,
        41,
        31,
        23,
        20,
        48,
        33,
        2,
        38,
        47,
        10,
        20,
        40,
        36,
        30,
        9,
        10,
        16,
        27,
        48,
        49,
        39,
        32,
        20,
        28,
        32,
        13,
        21,
        32,
        0,
        48,
        30,
        0,
        0,
        0,
        19,
        40,
        12,
        5,
        8,
        47,
        49,
        13,
        49,
        39,
        49,
        4,
        8,
        4,
        34,
        27,
        44
      ],
      "weeklyBackordered": [
        34,
        28,
        4,
        41,
        41,
        37,
        49,
        36,
        49,
        0,
        29,
        35,
        17,
        49,
        42,
        1,
        21,
        49,
        9,
        47,
        35,
        32,
        0,
        11,
        44,
        28,
        25,
        8,
        12,
        49,
        42,
        49,
        48,
        0,
        1,
        0,
        49,
        24,
        41,
        33,
        11,
        44,
        14,
        22,
        4,
        49,
        36,
        8,
        0,
        9,
        49,
        13
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        30,
        48,
        33,
        45,
        49,
        49,
        31,
        27,
        24,
        40,
        36,
        0,
        49,
        47,
        16,
        31,
        49,
        5,
        0,
        27,
        17,
        10,
        34,
        45,
        40,
        47,
        8,
        14,
        1,
        26,
        17,
        16,
        13,
        0,
        32,
        28,
        20,
        30,
        14,
        49,
        0,
        26,
        44,
        7,
        49,
        4,
        12,
        27,
        33,
        44,
        24,
        32
      ],
      "weeklyRejected": [
        36,
        26,
        18,
        20,
        33,
        31,
        39,
        0,
        28,
        36,
        26,
        39,
        10,
        48,
        43,
        32,
        43,
        46,
        45,
        24,
        0,
        49,
        43,
        38,
        31,
        49,
        49,
        0,
        28,
        38,
        49,
        42,
        30,
        33,
        0,
        29,
        35,
        29,
        4,
        23,
        43,
        19,
        12,
        34,
        15,
        3,
        0,
        21,
        18,
        49,
        0,
        29
      ],
      "weeklyHold": [
        0,
        5,
        49,
        1,
        9,
        48,
        49,
        10,
        34,
        16,
        0,
        49,
        19,
        49,
        26,
        35,
        20,
        15,
        49,
        26,
        40,
        35,
        20,
        25,
        38,
        33,
        17,
        40,
        49,
        8,
        30,
        31,
        23,
        32,
        0,
        40,
        18,
        24,
        6,
        46,
        0,
        38,
        12,
        25,
        18,
        46,
        49,
        19,
        0,
        11,
        35,
        15
      ],
      "weeklyBackordered": [
        45,
        0,
        2,
        46,
        25,
        40,
        49,
        43,
        38,
        4,
        29,
        24,
        21,
        42,
        25,
        47,
        45,
        0,
        24,
        22,
        35,
        6,
        17,
        32,
        48,
        9,
        47,
        20,
        1,
        31,
        3,
        15,
        38,
        32,
        17,
        5,
        3,
        0,
        40,
        10,
        9,
        48,
        5,
        49,
        21,
        32,
        49,
        16,
        43,
        31,
        34,
        22
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        28,
        49,
        42,
        11,
        12,
        27,
        28,
        20,
        23,
        20,
        10,
        0,
        49,
        43,
        37,
        12,
        46,
        6,
        39,
        8,
        30,
        24,
        0,
        49,
        31,
        19,
        33,
        49,
        1,
        26,
        7,
        0,
        38,
        0,
        10,
        42,
        26,
        30,
        15,
        8,
        42,
        49,
        32,
        0,
        49,
        0,
        0,
        33,
        41,
        49,
        30,
        45
      ],
      "weeklyRejected": [
        49,
        21,
        25,
        29,
        36,
        16,
        0,
        22,
        49,
        5,
        31,
        5,
        5,
        32,
        36,
        16,
        49,
        49,
        35,
        17,
        0,
        41,
        36,
        49,
        15,
        41,
        48,
        17,
        29,
        41,
        23,
        26,
        27,
        44,
        0,
        49,
        35,
        31,
        1,
        36,
        48,
        0,
        27,
        18,
        29,
        0,
        14,
        49,
        0,
        49,
        0,
        25
      ],
      "weeklyHold": [
        1,
        14,
        49,
        28,
        39,
        21,
        3,
        15,
        14,
        11,
        23,
        1,
        1,
        12,
        26,
        49,
        31,
        8,
        20,
        49,
        0,
        18,
        49,
        49,
        16,
        40,
        37,
        15,
        49,
        47,
        47,
        5,
        13,
        46,
        11,
        11,
        12,
        0,
        48,
        2,
        6,
        27,
        0,
        39,
        25,
        0,
        0,
        29,
        4,
        45,
        44,
        0
      ],
      "weeklyBackordered": [
        18,
        13,
        19,
        17,
        49,
        46,
        39,
        5,
        18,
        39,
        0,
        23,
        15,
        0,
        18,
        27,
        32,
        22,
        11,
        23,
        30,
        35,
        23,
        38,
        48,
        10,
        29,
        27,
        1,
        49,
        34,
        49,
        34,
        47,
        10,
        0,
        24,
        0,
        41,
        27,
        32,
        48,
        11,
        49,
        0,
        36,
        36,
        9,
        32,
        0,
        49,
        14
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyAccepted": [
        33,
        38,
        17,
        13,
        18,
        6,
        29,
        30,
        0,
        40,
        26,
        0,
        45,
        44,
        13,
        20,
        48,
        31,
        2,
        0,
        29,
        30,
        24,
        15,
        49,
        33,
        44,
        16,
        28,
        49,
        0,
        17,
        48,
        8,
        38,
        23,
        26,
        10,
        21,
        2,
        0,
        45,
        17,
        18,
        40,
        39,
        8,
        23,
        48,
        5,
        0,
        44
      ],
      "weeklyRejected": [
        44,
        17,
        10,
        15,
        49,
        0,
        49,
        26,
        49,
        11,
        0,
        33,
        9,
        45,
        32,
        21,
        2,
        37,
        49,
        12,
        0,
        3,
        11,
        49,
        37,
        49,
        49,
        27,
        32,
        17,
        45,
        37,
        42,
        21,
        0,
        33,
        40,
        49,
        1,
        0,
        26,
        31,
        45,
        29,
        23,
        20,
        0,
        13,
        0,
        16,
        5,
        48
      ],
      "weeklyHold": [
        49,
        19,
        26,
        1,
        22,
        37,
        47,
        20,
        12,
        33,
        23,
        47,
        30,
        40,
        37,
        28,
        47,
        0,
        0,
        38,
        38,
        29,
        12,
        19,
        41,
        31,
        41,
        0,
        49,
        11,
        47,
        0,
        7,
        27,
        13,
        33,
        27,
        42,
        49,
        46,
        49,
        35,
        10,
        0,
        35,
        14,
        39,
        49,
        0,
        14,
        0,
        31
      ],
      "weeklyBackordered": [
        34,
        0,
        18,
        49,
        26,
        41,
        8,
        36,
        38,
        0,
        2,
        47,
        38,
        14,
        37,
        17,
        12,
        40,
        0,
        35,
        44,
        26,
        15,
        39,
        48,
        21,
        30,
        8,
        28,
        21,
        24,
        21,
        29,
        49,
        0,
        18,
        1,
        39,
        14,
        26,
        48,
        48,
        0,
        49,
        0,
        13,
        26,
        0,
        14,
        8,
        46,
        3
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        9,
        49,
        32,
        48,
        10,
        0,
        1,
        49,
        48,
        30,
        37,
        16,
        49,
        49,
        1,
        29,
        40,
        5,
        22,
        25,
        0,
        33,
        49,
        27,
        13,
        39,
        9,
        0,
        33,
        21,
        17,
        21,
        12,
        0,
        12,
        49,
        30,
        0,
        31,
        44,
        0,
        0,
        14,
        35,
        49,
        0,
        6,
        31,
        18,
        28,
        16,
        10
      ],
      "weeklyRejected": [
        37,
        16,
        10,
        39,
        48,
        9,
        45,
        23,
        45,
        20,
        17,
        10,
        49,
        48,
        13,
        32,
        7,
        49,
        13,
        24,
        30,
        0,
        23,
        27,
        35,
        35,
        40,
        11,
        46,
        25,
        0,
        42,
        48,
        28,
        18,
        41,
        46,
        16,
        5,
        49,
        48,
        45,
        0,
        0,
        47,
        34,
        0,
        19,
        0,
        45,
        20,
        34
      ],
      "weeklyHold": [
        49,
        0,
        49,
        1,
        8,
        21,
        44,
        28,
        28,
        49,
        24,
        40,
        15,
        19,
        49,
        29,
        0,
        0,
        0,
        23,
        23,
        49,
        21,
        40,
        17,
        25,
        30,
        12,
        26,
        0,
        39,
        19,
        4,
        32,
        6,
        16,
        24,
        0,
        49,
        3,
        0,
        0,
        17,
        0,
        46,
        45,
        17,
        15,
        0,
        49,
        20,
        49
      ],
      "weeklyBackordered": [
        48,
        0,
        26,
        25,
        44,
        7,
        48,
        49,
        33,
        19,
        22,
        44,
        22,
        0,
        0,
        6,
        20,
        46,
        0,
        17,
        10,
        20,
        24,
        40,
        35,
        12,
        24,
        17,
        2,
        49,
        1,
        41,
        48,
        39,
        20,
        0,
        29,
        19,
        33,
        2,
        22,
        48,
        8,
        49,
        11,
        21,
        40,
        27,
        7,
        18,
        49,
        1
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        13,
        1,
        25,
        8,
        49,
        0,
        48,
        49,
        48,
        6,
        49,
        1,
        20,
        37,
        14,
        18,
        27,
        48,
        41,
        18,
        26,
        0,
        49,
        49,
        19,
        10,
        31,
        36,
        6,
        43,
        24,
        0,
        47,
        49,
        20,
        44,
        10,
        10,
        24,
        49,
        19,
        4,
        48,
        35,
        49,
        21,
        21,
        44,
        2,
        49,
        21,
        26
      ],
      "weeklyRejected": [
        37,
        22,
        23,
        29,
        44,
        39,
        5,
        21,
        39,
        48,
        22,
        39,
        4,
        29,
        48,
        6,
        0,
        7,
        15,
        0,
        39,
        49,
        17,
        16,
        3,
        49,
        47,
        0,
        39,
        13,
        34,
        32,
        0,
        49,
        0,
        30,
        27,
        0,
        28,
        15,
        21,
        27,
        43,
        39,
        36,
        49,
        35,
        6,
        32,
        49,
        0,
        38
      ],
      "weeklyHold": [
        16,
        24,
        47,
        1,
        26,
        0,
        33,
        28,
        0,
        23,
        25,
        37,
        36,
        1,
        19,
        21,
        0,
        48,
        18,
        8,
        19,
        0,
        29,
        0,
        21,
        26,
        20,
        10,
        49,
        49,
        34,
        15,
        0,
        21,
        11,
        45,
        31,
        3,
        1,
        29,
        49,
        38,
        4,
        17,
        38,
        38,
        30,
        28,
        0,
        29,
        4,
        49
      ],
      "weeklyBackordered": [
        15,
        40,
        13,
        33,
        45,
        21,
        35,
        33,
        46,
        29,
        49,
        48,
        49,
        49,
        25,
        32,
        29,
        49,
        7,
        0,
        2,
        9,
        31,
        16,
        47,
        20,
        14,
        18,
        8,
        45,
        49,
        49,
        48,
        11,
        18,
        0,
        1,
        0,
        24,
        27,
        0,
        18,
        17,
        49,
        24,
        15,
        49,
        16,
        36,
        21,
        1,
        4
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        13,
        1,
        0,
        31,
        49,
        12,
        10,
        49,
        48,
        38,
        13,
        0,
        0,
        2,
        1,
        4,
        46,
        24,
        41,
        39,
        8,
        20,
        49,
        14,
        0,
        24,
        0,
        19,
        1,
        2,
        4,
        24,
        38,
        21,
        19,
        20,
        35,
        45,
        12,
        49,
        44,
        29,
        49,
        49,
        49,
        0,
        43,
        49,
        24,
        25,
        26,
        27
      ],
      "weeklyRejected": [
        49,
        8,
        19,
        21,
        40,
        18,
        20,
        7,
        20,
        12,
        42,
        29,
        17,
        22,
        39,
        5,
        16,
        47,
        38,
        48,
        9,
        45,
        0,
        19,
        0,
        44,
        45,
        49,
        3,
        19,
        20,
        47,
        48,
        49,
        0,
        40,
        24,
        10,
        1,
        21,
        41,
        30,
        33,
        0,
        49,
        6,
        0,
        40,
        0,
        49,
        12,
        38
      ],
      "weeklyHold": [
        0,
        27,
        8,
        14,
        6,
        11,
        33,
        18,
        21,
        27,
        42,
        28,
        6,
        31,
        49,
        49,
        46,
        16,
        0,
        17,
        20,
        0,
        0,
        45,
        33,
        32,
        2,
        0,
        39,
        17,
        47,
        38,
        11,
        1,
        30,
        18,
        22,
        6,
        27,
        23,
        18,
        20,
        26,
        42,
        5,
        49,
        14,
        26,
        36,
        46,
        28,
        37
      ],
      "weeklyBackordered": [
        34,
        21,
        4,
        49,
        49,
        34,
        0,
        47,
        49,
        0,
        0,
        0,
        25,
        14,
        24,
        36,
        49,
        0,
        6,
        0,
        46,
        26,
        48,
        38,
        14,
        15,
        40,
        4,
        15,
        49,
        24,
        49,
        36,
        40,
        5,
        0,
        30,
        0,
        14,
        7,
        48,
        48,
        16,
        49,
        36,
        49,
        30,
        37,
        13,
        49,
        43,
        47
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        0,
        49,
        26,
        36,
        7,
        13,
        1,
        45,
        30,
        49,
        20,
        39,
        49,
        31,
        35,
        27,
        38,
        3,
        28,
        0,
        46,
        0,
        34,
        49,
        0,
        39,
        32,
        0,
        7,
        49,
        33,
        0,
        31,
        39,
        48,
        26,
        15,
        48,
        6,
        22,
        0,
        26,
        49,
        37,
        24,
        7,
        27,
        4,
        47,
        12,
        11,
        37
      ],
      "weeklyRejected": [
        49,
        11,
        42,
        27,
        30,
        49,
        46,
        9,
        44,
        10,
        0,
        40,
        0,
        33,
        38,
        0,
        39,
        23,
        45,
        22,
        11,
        49,
        48,
        49,
        49,
        49,
        45,
        10,
        48,
        0,
        49,
        47,
        46,
        30,
        40,
        33,
        49,
        41,
        7,
        45,
        32,
        13,
        46,
        6,
        43,
        34,
        28,
        36,
        0,
        48,
        0,
        44
      ],
      "weeklyHold": [
        0,
        28,
        49,
        17,
        33,
        48,
        12,
        5,
        0,
        5,
        28,
        29,
        31,
        31,
        13,
        2,
        21,
        48,
        17,
        14,
        32,
        13,
        22,
        30,
        26,
        48,
        0,
        8,
        16,
        0,
        21,
        0,
        32,
        33,
        0,
        41,
        44,
        48,
        44,
        19,
        31,
        36,
        14,
        32,
        23,
        31,
        41,
        36,
        20,
        0,
        47,
        38
      ],
      "weeklyBackordered": [
        31,
        47,
        0,
        43,
        49,
        46,
        15,
        40,
        44,
        12,
        6,
        49,
        27,
        11,
        7,
        41,
        41,
        49,
        33,
        49,
        47,
        27,
        7,
        0,
        8,
        11,
        1,
        20,
        44,
        35,
        0,
        49,
        9,
        47,
        31,
        0,
        39,
        4,
        18,
        6,
        22,
        48,
        0,
        49,
        23,
        32,
        14,
        10,
        26,
        17,
        48,
        8
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        0,
        27,
        17,
        48,
        47,
        8,
        33,
        37,
        48,
        8,
        4,
        9,
        29,
        25,
        18,
        15,
        35,
        19,
        4,
        0,
        49,
        49,
        17,
        42,
        40,
        44,
        23,
        33,
        26,
        49,
        33,
        29,
        49,
        33,
        24,
        27,
        15,
        48,
        44,
        49,
        22,
        22,
        49,
        49,
        46,
        19,
        0,
        5,
        45,
        23,
        49,
        48
      ],
      "weeklyRejected": [
        32,
        0,
        40,
        25,
        48,
        49,
        46,
        11,
        33,
        32,
        29,
        20,
        15,
        3,
        39,
        0,
        49,
        16,
        44,
        34,
        7,
        38,
        27,
        43,
        4,
        49,
        28,
        5,
        9,
        5,
        5,
        33,
        5,
        17,
        49,
        49,
        0,
        22,
        1,
        8,
        32,
        43,
        30,
        44,
        34,
        1,
        21,
        0,
        22,
        38,
        28,
        27
      ],
      "weeklyHold": [
        37,
        0,
        29,
        11,
        49,
        27,
        10,
        29,
        0,
        28,
        22,
        28,
        18,
        33,
        1,
        41,
        9,
        11,
        2,
        11,
        0,
        39,
        22,
        28,
        45,
        48,
        0,
        0,
        49,
        12,
        22,
        0,
        21,
        8,
        15,
        13,
        41,
        0,
        49,
        13,
        0,
        38,
        5,
        27,
        29,
        25,
        33,
        30,
        0,
        47,
        27,
        40
      ],
      "weeklyBackordered": [
        44,
        33,
        19,
        34,
        27,
        37,
        29,
        49,
        23,
        11,
        14,
        10,
        34,
        0,
        0,
        32,
        32,
        16,
        26,
        0,
        32,
        48,
        2,
        9,
        35,
        28,
        24,
        6,
        33,
        19,
        26,
        49,
        20,
        28,
        0,
        0,
        20,
        35,
        25,
        12,
        17,
        14,
        36,
        49,
        22,
        2,
        13,
        18,
        12,
        28,
        49,
        19
      ]
    },
    {
      "id": "BB20277D",
      "weeklyAccepted": [
        24,
        1,
        49,
        42,
        39,
        28,
        1,
        42,
        37,
        13,
        47,
        9,
        36,
        45,
        4,
        27,
        41,
        24,
        0,
        13,
        0,
        3,
        36,
        37,
        23,
        31,
        49,
        2,
        1,
        49,
        12,
        25,
        33,
        7,
        14,
        48,
        47,
        10,
        49,
        49,
        11,
        0,
        11,
        28,
        30,
        0,
        10,
        8,
        17,
        6,
        16,
        48
      ],
      "weeklyRejected": [
        44,
        8,
        49,
        43,
        36,
        37,
        49,
        16,
        49,
        45,
        24,
        0,
        20,
        26,
        37,
        0,
        30,
        12,
        19,
        1,
        33,
        6,
        40,
        49,
        0,
        34,
        22,
        10,
        44,
        0,
        8,
        27,
        48,
        49,
        40,
        32,
        6,
        2,
        1,
        49,
        48,
        26,
        30,
        49,
        41,
        49,
        30,
        26,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        16,
        0,
        36,
        22,
        29,
        22,
        15,
        49,
        0,
        28,
        47,
        19,
        20,
        1,
        40,
        16,
        7,
        48,
        0,
        1,
        20,
        15,
        31,
        9,
        0,
        41,
        0,
        6,
        49,
        0,
        48,
        37,
        3,
        49,
        6,
        19,
        49,
        5,
        47,
        17,
        32,
        19,
        22,
        47,
        8,
        42,
        20,
        26,
        1,
        20,
        22,
        27
      ],
      "weeklyBackordered": [
        32,
        24,
        35,
        24,
        49,
        43,
        2,
        29,
        40,
        0,
        0,
        49,
        19,
        31,
        14,
        19,
        39,
        45,
        0,
        23,
        45,
        34,
        26,
        39,
        18,
        49,
        16,
        18,
        16,
        34,
        45,
        12,
        48,
        46,
        0,
        0,
        4,
        0,
        19,
        42,
        30,
        41,
        5,
        9,
        27,
        31,
        46,
        18,
        18,
        49,
        1,
        10
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        0,
        1,
        49,
        20,
        18,
        25,
        1,
        21,
        48,
        11,
        49,
        0,
        24,
        2,
        27,
        13,
        29,
        35,
        0,
        27,
        5,
        0,
        10,
        40,
        16,
        41,
        49,
        49,
        39,
        40,
        22,
        15,
        49,
        28,
        28,
        9,
        30,
        48,
        33,
        49,
        22,
        33,
        49,
        24,
        29,
        14,
        0,
        0,
        0,
        49,
        27,
        29
      ],
      "weeklyRejected": [
        19,
        0,
        32,
        33,
        49,
        31,
        31,
        11,
        49,
        28,
        12,
        22,
        31,
        15,
        48,
        0,
        10,
        46,
        42,
        5,
        39,
        6,
        32,
        10,
        2,
        43,
        19,
        0,
        15,
        23,
        35,
        24,
        29,
        33,
        22,
        49,
        2,
        0,
        1,
        33,
        42,
        36,
        49,
        44,
        42,
        20,
        27,
        28,
        0,
        49,
        0,
        30
      ],
      "weeklyHold": [
        34,
        15,
        10,
        47,
        49,
        43,
        49,
        38,
        25,
        49,
        47,
        1,
        48,
        32,
        27,
        29,
        7,
        28,
        3,
        24,
        49,
        9,
        0,
        49,
        41,
        25,
        49,
        25,
        49,
        28,
        37,
        25,
        20,
        40,
        26,
        47,
        49,
        1,
        25,
        25,
        13,
        35,
        0,
        37,
        28,
        49,
        27,
        24,
        0,
        1,
        20,
        45
      ],
      "weeklyBackordered": [
        6,
        48,
        0,
        43,
        49,
        46,
        6,
        23,
        0,
        12,
        49,
        32,
        31,
        49,
        35,
        8,
        38,
        18,
        10,
        0,
        49,
        16,
        15,
        26,
        22,
        31,
        3,
        11,
        44,
        26,
        45,
        35,
        10,
        49,
        0,
        0,
        45,
        0,
        45,
        23,
        0,
        27,
        0,
        49,
        20,
        44,
        35,
        23,
        24,
        15,
        1,
        33
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        0,
        35,
        31,
        48,
        24,
        22,
        4,
        49,
        8,
        3,
        49,
        0,
        49,
        6,
        14,
        49,
        36,
        20,
        0,
        42,
        10,
        21,
        26,
        21,
        0,
        47,
        27,
        0,
        2,
        33,
        0,
        23,
        49,
        6,
        17,
        38,
        22,
        45,
        49,
        49,
        31,
        1,
        49,
        17,
        49,
        0,
        0,
        49,
        49,
        27,
        4,
        41
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        47,
        22,
        0,
        8,
        32,
        16,
        19,
        31,
        24,
        23,
        11,
        2,
        25,
        16,
        9,
        45,
        15,
        49,
        43,
        3,
        49,
        8,
        33,
        17,
        3,
        48,
        34,
        49,
        47,
        48,
        29,
        2,
        46,
        49,
        29,
        1,
        49,
        48,
        42,
        47,
        40,
        45,
        40,
        10,
        24,
        12,
        25,
        21,
        0
      ],
      "weeklyHold": [
        0,
        7,
        20,
        23,
        49,
        48,
        38,
        13,
        22,
        18,
        25,
        43,
        2,
        49,
        4,
        15,
        0,
        10,
        15,
        6,
        0,
        43,
        3,
        49,
        37,
        41,
        22,
        44,
        45,
        0,
        32,
        22,
        27,
        15,
        0,
        22,
        9,
        44,
        48,
        9,
        0,
        24,
        47,
        28,
        31,
        42,
        33,
        13,
        0,
        36,
        4,
        32
      ],
      "weeklyBackordered": [
        19,
        27,
        0,
        39,
        25,
        30,
        49,
        42,
        29,
        25,
        49,
        44,
        3,
        49,
        48,
        34,
        49,
        35,
        4,
        21,
        39,
        45,
        22,
        46,
        34,
        3,
        19,
        21,
        1,
        15,
        43,
        32,
        13,
        35,
        0,
        17,
        28,
        45,
        16,
        27,
        38,
        48,
        9,
        49,
        36,
        0,
        49,
        30,
        15,
        49,
        49,
        20
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        0,
        36,
        24,
        28,
        42,
        5,
        37,
        16,
        48,
        13,
        41,
        8,
        32,
        36,
        15,
        49,
        49,
        0,
        0,
        28,
        16,
        0,
        39,
        6,
        29,
        47,
        0,
        0,
        37,
        23,
        23,
        38,
        49,
        13,
        36,
        25,
        33,
        19,
        16,
        48,
        7,
        49,
        49,
        49,
        42,
        25,
        0,
        21,
        10,
        24,
        20,
        7
      ],
      "weeklyRejected": [
        41,
        26,
        2,
        34,
        29,
        45,
        48,
        49,
        42,
        44,
        39,
        31,
        17,
        0,
        48,
        27,
        17,
        19,
        37,
        42,
        23,
        30,
        12,
        14,
        30,
        6,
        13,
        8,
        38,
        27,
        30,
        0,
        18,
        49,
        23,
        44,
        14,
        24,
        2,
        5,
        19,
        7,
        27,
        0,
        42,
        40,
        30,
        0,
        0,
        19,
        21,
        41
      ],
      "weeklyHold": [
        27,
        12,
        45,
        29,
        32,
        48,
        0,
        2,
        0,
        20,
        36,
        5,
        1,
        49,
        25,
        24,
        0,
        48,
        0,
        19,
        40,
        20,
        33,
        0,
        49,
        35,
        22,
        0,
        41,
        0,
        48,
        0,
        26,
        49,
        22,
        40,
        47,
        11,
        22,
        49,
        26,
        49,
        0,
        10,
        36,
        11,
        30,
        44,
        10,
        28,
        27,
        43
      ],
      "weeklyBackordered": [
        4,
        49,
        0,
        17,
        47,
        26,
        49,
        3,
        0,
        32,
        8,
        39,
        3,
        3,
        37,
        7,
        49,
        32,
        2,
        11,
        49,
        6,
        0,
        0,
        25,
        20,
        44,
        33,
        23,
        25,
        43,
        47,
        45,
        31,
        0,
        0,
        5,
        13,
        49,
        36,
        14,
        48,
        12,
        38,
        31,
        21,
        10,
        3,
        6,
        48,
        43,
        17
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        37,
        23,
        33,
        0,
        8,
        23,
        8,
        49,
        47,
        14,
        41,
        0,
        25,
        2,
        7,
        23,
        49,
        25,
        0,
        20,
        16,
        29,
        14,
        27,
        34,
        47,
        38,
        49,
        17,
        17,
        49,
        0,
        49,
        25,
        46,
        47,
        33,
        24,
        48,
        23,
        0,
        45,
        31,
        6,
        49,
        8,
        0,
        13,
        26,
        49,
        40,
        48
      ],
      "weeklyRejected": [
        23,
        31,
        33,
        26,
        15,
        22,
        8,
        39,
        29,
        48,
        49,
        31,
        11,
        15,
        3,
        32,
        0,
        48,
        49,
        15,
        0,
        18,
        0,
        49,
        35,
        45,
        35,
        29,
        48,
        42,
        49,
        47,
        19,
        49,
        13,
        49,
        28,
        0,
        4,
        5,
        17,
        23,
        0,
        33,
        41,
        4,
        4,
        12,
        3,
        14,
        18,
        0
      ],
      "weeklyHold": [
        24,
        45,
        15,
        16,
        31,
        48,
        26,
        9,
        20,
        24,
        0,
        49,
        48,
        34,
        5,
        10,
        12,
        0,
        23,
        16,
        0,
        49,
        6,
        8,
        9,
        26,
        49,
        34,
        49,
        21,
        45,
        5,
        0,
        39,
        33,
        38,
        4,
        45,
        14,
        0,
        38,
        49,
        13,
        49,
        29,
        47,
        38,
        40,
        21,
        48,
        41,
        0
      ],
      "weeklyBackordered": [
        34,
        0,
        0,
        15,
        49,
        30,
        24,
        49,
        16,
        5,
        5,
        28,
        11,
        9,
        28,
        49,
        41,
        41,
        0,
        47,
        37,
        30,
        19,
        27,
        30,
        1,
        39,
        14,
        49,
        49,
        8,
        45,
        21,
        49,
        10,
        0,
        38,
        18,
        9,
        27,
        10,
        48,
        0,
        25,
        38,
        9,
        49,
        19,
        5,
        49,
        26,
        48
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        4,
        39,
        38,
        34,
        33,
        49,
        34,
        49,
        28,
        41,
        36,
        29,
        49,
        49,
        30,
        0,
        49,
        48,
        25,
        49,
        34,
        44,
        49,
        0,
        0,
        43,
        0,
        49,
        34,
        23,
        0,
        46,
        49,
        19,
        48,
        11,
        43,
        2,
        17,
        49,
        11,
        37,
        47,
        40,
        49,
        25,
        0,
        0,
        0,
        49,
        0,
        0
      ],
      "weeklyRejected": [
        21,
        10,
        34,
        32,
        49,
        0,
        0,
        0,
        49,
        11,
        42,
        33,
        49,
        48,
        25,
        11,
        18,
        39,
        42,
        42,
        0,
        25,
        49,
        31,
        49,
        44,
        36,
        9,
        44,
        23,
        0,
        43,
        45,
        49,
        41,
        35,
        49,
        24,
        1,
        43,
        48,
        49,
        13,
        12,
        23,
        42,
        15,
        3,
        21,
        49,
        18,
        37
      ],
      "weeklyHold": [
        15,
        0,
        44,
        15,
        21,
        30,
        0,
        4,
        28,
        23,
        33,
        42,
        48,
        10,
        42,
        0,
        3,
        29,
        24,
        0,
        49,
        38,
        48,
        8,
        22,
        1,
        1,
        0,
        22,
        17,
        24,
        48,
        31,
        30,
        8,
        33,
        36,
        23,
        21,
        49,
        22,
        49,
        0,
        47,
        34,
        0,
        5,
        49,
        45,
        18,
        34,
        7
      ],
      "weeklyBackordered": [
        42,
        12,
        1,
        26,
        45,
        40,
        4,
        38,
        12,
        35,
        38,
        44,
        3,
        49,
        22,
        49,
        49,
        49,
        13,
        0,
        23,
        16,
        0,
        32,
        2,
        10,
        48,
        14,
        27,
        49,
        49,
        24,
        44,
        24,
        16,
        1,
        33,
        0,
        5,
        11,
        21,
        26,
        0,
        14,
        0,
        38,
        28,
        36,
        0,
        28,
        46,
        13
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        20,
        26,
        19,
        45,
        27,
        0,
        1,
        23,
        43,
        19,
        25,
        0,
        48,
        39,
        17,
        0,
        49,
        48,
        0,
        17,
        29,
        16,
        22,
        30,
        24,
        15,
        22,
        19,
        13,
        42,
        25,
        32,
        49,
        40,
        31,
        31,
        47,
        48,
        24,
        49,
        0,
        49,
        49,
        17,
        1,
        31,
        0,
        18,
        0,
        32,
        9,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        26,
        4,
        28,
        14,
        0,
        0,
        49,
        25,
        34,
        0,
        49,
        27,
        2,
        39,
        39,
        42,
        26,
        43,
        49,
        8,
        21,
        42,
        29,
        7,
        49,
        15,
        48,
        7,
        0,
        33,
        48,
        49,
        25,
        49,
        16,
        40,
        20,
        33,
        48,
        24,
        49,
        11,
        39,
        17,
        14,
        24,
        17,
        41,
        3,
        12
      ],
      "weeklyHold": [
        27,
        17,
        49,
        1,
        42,
        19,
        34,
        49,
        0,
        25,
        47,
        26,
        32,
        40,
        0,
        25,
        22,
        39,
        34,
        0,
        0,
        17,
        42,
        45,
        10,
        46,
        0,
        41,
        49,
        0,
        45,
        29,
        26,
        49,
        33,
        41,
        43,
        49,
        26,
        17,
        27,
        16,
        9,
        49,
        33,
        0,
        9,
        38,
        4,
        13,
        49,
        27
      ],
      "weeklyBackordered": [
        49,
        43,
        10,
        10,
        49,
        37,
        49,
        47,
        24,
        6,
        19,
        26,
        5,
        26,
        14,
        33,
        22,
        29,
        19,
        43,
        43,
        24,
        18,
        49,
        21,
        16,
        1,
        29,
        21,
        49,
        27,
        49,
        0,
        23,
        0,
        0,
        13,
        8,
        23,
        22,
        10,
        48,
        30,
        31,
        13,
        49,
        8,
        8,
        27,
        0,
        17,
        44
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        35,
        11,
        19,
        38,
        21,
        6,
        16,
        29,
        46,
        28,
        41,
        29,
        6,
        49,
        28,
        35,
        49,
        6,
        12,
        31,
        6,
        39,
        44,
        49,
        7,
        7,
        9,
        0,
        10,
        49,
        23,
        29,
        42,
        18,
        48,
        49,
        26,
        37,
        49,
        27,
        0,
        36,
        49,
        41,
        37,
        21,
        8,
        49,
        25,
        22,
        25,
        48
      ],
      "weeklyRejected": [
        49,
        46,
        32,
        47,
        26,
        48,
        15,
        22,
        23,
        42,
        35,
        22,
        13,
        16,
        33,
        17,
        33,
        22,
        22,
        11,
        49,
        49,
        7,
        49,
        11,
        35,
        30,
        42,
        33,
        0,
        48,
        37,
        19,
        30,
        45,
        19,
        49,
        18,
        1,
        49,
        38,
        33,
        6,
        0,
        49,
        10,
        42,
        42,
        20,
        14,
        0,
        39
      ],
      "weeklyHold": [
        37,
        39,
        49,
        41,
        45,
        0,
        32,
        27,
        17,
        24,
        31,
        19,
        6,
        39,
        0,
        4,
        15,
        31,
        7,
        0,
        0,
        18,
        28,
        0,
        4,
        34,
        8,
        15,
        27,
        0,
        48,
        25,
        0,
        23,
        39,
        0,
        39,
        40,
        43,
        30,
        12,
        29,
        0,
        24,
        22,
        21,
        0,
        0,
        4,
        4,
        45,
        22
      ],
      "weeklyBackordered": [
        24,
        49,
        17,
        36,
        23,
        14,
        24,
        33,
        33,
        49,
        32,
        26,
        3,
        44,
        14,
        49,
        31,
        0,
        19,
        40,
        49,
        18,
        27,
        48,
        22,
        16,
        20,
        40,
        45,
        20,
        47,
        27,
        48,
        41,
        0,
        9,
        28,
        46,
        4,
        40,
        17,
        48,
        34,
        49,
        11,
        19,
        6,
        21,
        0,
        41,
        8,
        38
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        22,
        31,
        15,
        0,
        15,
        0,
        1,
        35,
        48,
        0,
        40,
        18,
        7,
        26,
        1,
        49,
        40,
        4,
        3,
        19,
        25,
        9,
        0,
        14,
        18,
        1,
        49,
        19,
        24,
        49,
        0,
        14,
        49,
        3,
        48,
        20,
        21,
        21,
        29,
        33,
        27,
        47,
        49,
        19,
        49,
        32,
        8,
        24,
        0,
        20,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        13,
        49,
        25,
        31,
        0,
        0,
        30,
        36,
        48,
        49,
        49,
        49,
        22,
        18,
        17,
        8,
        49,
        37,
        22,
        12,
        24,
        1,
        49,
        11,
        28,
        49,
        0,
        18,
        30,
        31,
        37,
        6,
        33,
        22,
        49,
        46,
        28,
        35,
        7,
        41,
        39,
        18,
        9,
        49,
        0,
        0,
        17,
        0,
        35,
        0,
        0
      ],
      "weeklyHold": [
        0,
        32,
        44,
        1,
        47,
        37,
        3,
        16,
        16,
        0,
        32,
        46,
        1,
        49,
        0,
        4,
        22,
        14,
        0,
        8,
        22,
        19,
        0,
        19,
        49,
        32,
        38,
        24,
        49,
        23,
        48,
        23,
        16,
        25,
        20,
        0,
        17,
        6,
        32,
        28,
        18,
        28,
        35,
        49,
        49,
        49,
        8,
        28,
        4,
        23,
        18,
        40
      ],
      "weeklyBackordered": [
        48,
        3,
        0,
        48,
        41,
        46,
        17,
        17,
        34,
        24,
        49,
        9,
        3,
        17,
        30,
        49,
        48,
        0,
        15,
        24,
        6,
        0,
        49,
        18,
        23,
        6,
        49,
        3,
        1,
        32,
        0,
        28,
        0,
        39,
        9,
        19,
        49,
        0,
        49,
        0,
        8,
        48,
        31,
        49,
        29,
        18,
        47,
        40,
        0,
        40,
        24,
        49
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        25,
        23,
        24,
        22,
        34,
        0,
        38,
        49,
        45,
        24,
        25,
        0,
        21,
        0,
        43,
        4,
        49,
        8,
        16,
        49,
        28,
        15,
        36,
        45,
        26,
        0,
        9,
        6,
        5,
        34,
        49,
        49,
        42,
        49,
        48,
        2,
        19,
        23,
        49,
        49,
        32,
        8,
        49,
        36,
        48,
        0,
        42,
        31,
        18,
        10,
        41,
        48
      ],
      "weeklyRejected": [
        34,
        21,
        20,
        4,
        0,
        46,
        49,
        44,
        13,
        20,
        49,
        40,
        13,
        39,
        48,
        0,
        8,
        49,
        44,
        48,
        49,
        31,
        4,
        36,
        42,
        0,
        49,
        18,
        39,
        0,
        49,
        17,
        47,
        10,
        21,
        41,
        0,
        0,
        1,
        32,
        48,
        2,
        20,
        0,
        49,
        0,
        9,
        49,
        0,
        13,
        0,
        35
      ],
      "weeklyHold": [
        41,
        22,
        4,
        14,
        49,
        0,
        35,
        21,
        41,
        23,
        29,
        19,
        12,
        21,
        6,
        43,
        0,
        39,
        31,
        0,
        0,
        9,
        24,
        0,
        10,
        1,
        30,
        0,
        6,
        49,
        44,
        22,
        0,
        23,
        48,
        23,
        49,
        0,
        4,
        35,
        8,
        40,
        40,
        18,
        36,
        49,
        8,
        30,
        30,
        0,
        3,
        45
      ],
      "weeklyBackordered": [
        0,
        33,
        0,
        21,
        35,
        4,
        49,
        11,
        25,
        36,
        27,
        49,
        12,
        46,
        30,
        13,
        21,
        0,
        19,
        11,
        49,
        0,
        16,
        22,
        1,
        33,
        1,
        20,
        16,
        0,
        35,
        49,
        31,
        49,
        1,
        0,
        39,
        0,
        38,
        49,
        37,
        48,
        3,
        49,
        11,
        0,
        49,
        18,
        15,
        49,
        1,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        9,
        48,
        29,
        1,
        11,
        19,
        18,
        40,
        16,
        34,
        49,
        7,
        0,
        0,
        1,
        3,
        44,
        48,
        0,
        26,
        49,
        30,
        26,
        26,
        49,
        24,
        39,
        41,
        45,
        49,
        30,
        1,
        30,
        14,
        42,
        32,
        47,
        48,
        36,
        44,
        28,
        27,
        34,
        20,
        49,
        31,
        0,
        32,
        42,
        39,
        49,
        48
      ],
      "weeklyRejected": [
        26,
        4,
        23,
        34,
        35,
        49,
        49,
        0,
        44,
        7,
        37,
        19,
        0,
        48,
        35,
        20,
        0,
        26,
        38,
        0,
        30,
        16,
        10,
        49,
        6,
        17,
        48,
        37,
        12,
        0,
        49,
        38,
        48,
        49,
        22,
        49,
        14,
        22,
        13,
        44,
        27,
        23,
        34,
        33,
        47,
        0,
        17,
        21,
        13,
        28,
        0,
        24
      ],
      "weeklyHold": [
        31,
        22,
        40,
        21,
        49,
        48,
        38,
        2,
        12,
        15,
        24,
        28,
        35,
        40,
        33,
        0,
        0,
        48,
        20,
        21,
        0,
        28,
        18,
        19,
        4,
        15,
        43,
        40,
        25,
        20,
        41,
        25,
        21,
        0,
        22,
        11,
        26,
        33,
        39,
        21,
        46,
        49,
        26,
        29,
        49,
        35,
        46,
        0,
        17,
        0,
        38,
        49
      ],
      "weeklyBackordered": [
        44,
        49,
        23,
        8,
        43,
        21,
        13,
        19,
        0,
        16,
        33,
        34,
        4,
        14,
        21,
        18,
        24,
        35,
        0,
        28,
        5,
        7,
        9,
        34,
        33,
        27,
        8,
        12,
        9,
        46,
        24,
        49,
        13,
        25,
        0,
        8,
        36,
        3,
        25,
        27,
        0,
        22,
        0,
        20,
        26,
        45,
        23,
        29,
        0,
        18,
        35,
        23
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        0,
        12,
        18,
        0,
        33,
        35,
        9,
        49,
        21,
        0,
        8,
        3,
        49,
        28,
        17,
        49,
        42,
        28,
        4,
        17,
        10,
        0,
        15,
        25,
        0,
        47,
        15,
        10,
        1,
        49,
        32,
        31,
        49,
        16,
        17,
        27,
        25,
        21,
        49,
        28,
        6,
        2,
        49,
        10,
        29,
        49,
        13,
        9,
        11,
        32,
        38,
        38
      ],
      "weeklyRejected": [
        5,
        0,
        42,
        16,
        49,
        22,
        49,
        47,
        23,
        1,
        49,
        24,
        24,
        7,
        18,
        36,
        8,
        49,
        49,
        22,
        4,
        34,
        41,
        0,
        0,
        49,
        25,
        22,
        31,
        27,
        0,
        20,
        20,
        6,
        0,
        34,
        0,
        2,
        21,
        23,
        45,
        18,
        26,
        46,
        49,
        44,
        0,
        3,
        17,
        27,
        0,
        21
      ],
      "weeklyHold": [
        20,
        0,
        49,
        1,
        18,
        24,
        10,
        19,
        8,
        13,
        42,
        26,
        24,
        5,
        49,
        11,
        27,
        15,
        8,
        26,
        8,
        29,
        21,
        30,
        30,
        21,
        42,
        3,
        23,
        14,
        26,
        20,
        0,
        41,
        11,
        37,
        4,
        0,
        6,
        49,
        19,
        30,
        30,
        19,
        0,
        25,
        6,
        10,
        0,
        35,
        19,
        23
      ],
      "weeklyBackordered": [
        0,
        2,
        24,
        27,
        49,
        46,
        47,
        49,
        44,
        9,
        44,
        30,
        3,
        13,
        37,
        34,
        31,
        49,
        9,
        49,
        12,
        16,
        0,
        15,
        10,
        1,
        30,
        27,
        44,
        27,
        29,
        0,
        14,
        36,
        15,
        0,
        8,
        0,
        32,
        19,
        23,
        48,
        8,
        49,
        24,
        33,
        41,
        8,
        0,
        28,
        16,
        8
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        28,
        38,
        49,
        48,
        1,
        12,
        1,
        49,
        48,
        49,
        49,
        17,
        0,
        23,
        31,
        41,
        49,
        1,
        49,
        11,
        41,
        31,
        49,
        47,
        21,
        25,
        46,
        49,
        29,
        9,
        2,
        0,
        49,
        42,
        48,
        36,
        5,
        48,
        49,
        47,
        43,
        43,
        48,
        21,
        19,
        0,
        0,
        22,
        12,
        49,
        0,
        8
      ],
      "weeklyRejected": [
        49,
        24,
        21,
        15,
        18,
        0,
        38,
        8,
        31,
        34,
        0,
        28,
        39,
        29,
        19,
        6,
        17,
        49,
        29,
        4,
        22,
        40,
        8,
        35,
        21,
        49,
        6,
        49,
        29,
        30,
        49,
        34,
        34,
        18,
        19,
        49,
        23,
        33,
        6,
        25,
        35,
        33,
        41,
        49,
        33,
        13,
        22,
        10,
        0,
        36,
        0,
        20
      ],
      "weeklyHold": [
        32,
        11,
        26,
        26,
        49,
        31,
        26,
        49,
        20,
        5,
        23,
        1,
        14,
        39,
        49,
        47,
        0,
        16,
        0,
        2,
        27,
        47,
        26,
        29,
        19,
        48,
        31,
        3,
        49,
        1,
        48,
        0,
        0,
        0,
        19,
        32,
        20,
        12,
        49,
        46,
        19,
        25,
        32,
        29,
        49,
        30,
        11,
        43,
        13,
        0,
        49,
        8
      ],
      "weeklyBackordered": [
        45,
        34,
        0,
        9,
        25,
        35,
        0,
        8,
        22,
        40,
        49,
        27,
        30,
        44,
        24,
        41,
        10,
        45,
        1,
        0,
        46,
        1,
        9,
        24,
        36,
        15,
        43,
        24,
        26,
        46,
        20,
        44,
        12,
        49,
        1,
        0,
        37,
        17,
        25,
        31,
        17,
        36,
        27,
        6,
        0,
        18,
        32,
        0,
        26,
        44,
        49,
        22
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        40,
        38,
        40,
        0,
        22,
        28,
        36,
        29,
        9,
        25,
        38,
        10,
        49,
        27,
        10,
        49,
        27,
        31,
        14,
        14,
        0,
        31,
        13,
        49,
        10,
        18,
        11,
        29,
        24,
        12,
        29,
        12,
        46,
        28,
        48,
        35,
        6,
        48,
        49,
        30,
        24,
        35,
        30,
        33,
        35,
        8,
        3,
        0,
        25,
        49,
        20,
        24
      ],
      "weeklyRejected": [
        29,
        45,
        29,
        0,
        47,
        0,
        14,
        5,
        0,
        23,
        49,
        0,
        27,
        24,
        48,
        21,
        46,
        49,
        39,
        18,
        10,
        49,
        0,
        39,
        10,
        49,
        37,
        49,
        32,
        28,
        23,
        29,
        48,
        31,
        0,
        49,
        30,
        46,
        33,
        20,
        16,
        10,
        41,
        0,
        41,
        11,
        4,
        49,
        8,
        22,
        0,
        49
      ],
      "weeklyHold": [
        33,
        14,
        18,
        1,
        2,
        3,
        0,
        2,
        0,
        5,
        44,
        45,
        10,
        17,
        40,
        18,
        18,
        29,
        39,
        34,
        17,
        16,
        49,
        4,
        46,
        15,
        35,
        11,
        22,
        0,
        34,
        12,
        31,
        28,
        36,
        0,
        16,
        0,
        48,
        17,
        35,
        24,
        49,
        36,
        30,
        22,
        7,
        13,
        0,
        49,
        0,
        1
      ],
      "weeklyBackordered": [
        45,
        32,
        10,
        44,
        46,
        46,
        0,
        38,
        49,
        0,
        7,
        21,
        30,
        23,
        39,
        38,
        32,
        41,
        11,
        49,
        20,
        31,
        0,
        27,
        27,
        1,
        22,
        0,
        16,
        21,
        8,
        39,
        43,
        33,
        0,
        3,
        49,
        48,
        24,
        0,
        16,
        48,
        3,
        49,
        40,
        36,
        49,
        0,
        36,
        38,
        21,
        1
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        9,
        26,
        31,
        0,
        1,
        18,
        38,
        33,
        34,
        49,
        47,
        0,
        29,
        40,
        1,
        29,
        49,
        15,
        25,
        49,
        21,
        44,
        0,
        0,
        49,
        47,
        28,
        49,
        30,
        22,
        9,
        0,
        48,
        2,
        18,
        49,
        47,
        29,
        27,
        49,
        0,
        47,
        28,
        27,
        49,
        0,
        3,
        38,
        0,
        8,
        0,
        11
      ],
      "weeklyRejected": [
        49,
        49,
        18,
        5,
        12,
        4,
        0,
        13,
        20,
        1,
        10,
        34,
        37,
        48,
        2,
        33,
        44,
        36,
        38,
        0,
        29,
        0,
        42,
        49,
        34,
        28,
        49,
        0,
        34,
        24,
        36,
        15,
        48,
        33,
        22,
        38,
        49,
        24,
        22,
        23,
        6,
        11,
        49,
        0,
        49,
        46,
        28,
        38,
        2,
        0,
        48,
        42
      ],
      "weeklyHold": [
        27,
        0,
        49,
        16,
        49,
        47,
        29,
        10,
        15,
        5,
        25,
        35,
        16,
        28,
        10,
        19,
        8,
        48,
        5,
        0,
        21,
        19,
        49,
        0,
        0,
        47,
        19,
        20,
        0,
        0,
        48,
        0,
        0,
        31,
        8,
        10,
        24,
        41,
        49,
        33,
        31,
        49,
        0,
        34,
        49,
        49,
        48,
        0,
        5,
        30,
        49,
        19
      ],
      "weeklyBackordered": [
        24,
        2,
        36,
        49,
        14,
        40,
        36,
        45,
        14,
        0,
        49,
        18,
        22,
        12,
        43,
        34,
        43,
        49,
        26,
        49,
        27,
        37,
        39,
        0,
        44,
        24,
        43,
        0,
        32,
        9,
        49,
        49,
        0,
        0,
        0,
        8,
        45,
        11,
        32,
        0,
        10,
        31,
        0,
        49,
        49,
        23,
        7,
        8,
        0,
        47,
        13,
        9
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        0,
        1,
        49,
        26,
        26,
        0,
        43,
        34,
        21,
        0,
        45,
        0,
        28,
        47,
        16,
        31,
        1,
        0,
        0,
        18,
        12,
        41,
        30,
        18,
        0,
        47,
        18,
        38,
        49,
        3,
        3,
        29,
        49,
        0,
        48,
        44,
        14,
        48,
        30,
        49,
        0,
        24,
        21,
        22,
        36,
        9,
        13,
        0,
        16,
        9,
        29,
        48
      ],
      "weeklyRejected": [
        48,
        21,
        2,
        12,
        42,
        45,
        49,
        31,
        18,
        21,
        7,
        0,
        2,
        48,
        47,
        26,
        23,
        37,
        6,
        23,
        32,
        37,
        20,
        41,
        12,
        49,
        36,
        3,
        36,
        48,
        27,
        33,
        32,
        32,
        22,
        28,
        41,
        49,
        1,
        21,
        0,
        20,
        42,
        0,
        49,
        49,
        0,
        36,
        7,
        49,
        12,
        49
      ],
      "weeklyHold": [
        49,
        8,
        36,
        1,
        36,
        24,
        0,
        6,
        22,
        26,
        22,
        43,
        39,
        23,
        18,
        18,
        0,
        48,
        44,
        24,
        49,
        0,
        14,
        24,
        44,
        17,
        45,
        0,
        49,
        0,
        48,
        3,
        0,
        42,
        15,
        34,
        27,
        21,
        32,
        49,
        9,
        49,
        22,
        43,
        49,
        13,
        0,
        39,
        0,
        41,
        0,
        0
      ],
      "weeklyBackordered": [
        25,
        49,
        19,
        49,
        41,
        6,
        13,
        1,
        21,
        36,
        31,
        47,
        39,
        2,
        9,
        34,
        49,
        24,
        15,
        38,
        35,
        3,
        12,
        26,
        20,
        23,
        39,
        11,
        25,
        9,
        11,
        16,
        25,
        40,
        21,
        15,
        49,
        25,
        15,
        35,
        17,
        21,
        0,
        49,
        37,
        21,
        22,
        23,
        24,
        26,
        1,
        6
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        20,
        49,
        35,
        40,
        33,
        0,
        21,
        2,
        40,
        38,
        24,
        24,
        49,
        32,
        1,
        21,
        49,
        0,
        0,
        49,
        9,
        30,
        35,
        28,
        5,
        30,
        0,
        8,
        1,
        49,
        49,
        20,
        20,
        26,
        13,
        15,
        42,
        48,
        49,
        37,
        0,
        49,
        49,
        41,
        46,
        0,
        0,
        49,
        5,
        24,
        11,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        45,
        0,
        26,
        17,
        49,
        24,
        35,
        19,
        22,
        22,
        37,
        48,
        11,
        2,
        29,
        49,
        20,
        18,
        49,
        22,
        49,
        49,
        34,
        44,
        1,
        3,
        48,
        23,
        26,
        35,
        43,
        49,
        37,
        35,
        0,
        4,
        13,
        31,
        21,
        28,
        13,
        30,
        45,
        47,
        6,
        39,
        3,
        28,
        0,
        36
      ],
      "weeklyHold": [
        16,
        15,
        34,
        1,
        47,
        11,
        23,
        24,
        38,
        3,
        44,
        1,
        1,
        34,
        11,
        10,
        4,
        33,
        18,
        9,
        13,
        14,
        34,
        0,
        2,
        48,
        15,
        0,
        10,
        4,
        37,
        4,
        29,
        49,
        34,
        21,
        36,
        0,
        31,
        32,
        0,
        17,
        7,
        3,
        37,
        49,
        2,
        28,
        0,
        28,
        36,
        36
      ],
      "weeklyBackordered": [
        22,
        37,
        16,
        13,
        49,
        39,
        49,
        36,
        6,
        9,
        3,
        49,
        31,
        32,
        48,
        18,
        19,
        10,
        0,
        30,
        44,
        18,
        0,
        17,
        22,
        19,
        21,
        23,
        9,
        38,
        13,
        39,
        27,
        23,
        0,
        0,
        26,
        0,
        29,
        49,
        38,
        48,
        13,
        49,
        20,
        12,
        44,
        2,
        0,
        32,
        26,
        1
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        16,
        33,
        21,
        43,
        22,
        44,
        22,
        39,
        35,
        29,
        7,
        6,
        49,
        33,
        47,
        17,
        49,
        13,
        11,
        22,
        43,
        14,
        49,
        19,
        9,
        47,
        11,
        29,
        30,
        49,
        36,
        23,
        18,
        0,
        48,
        5,
        35,
        17,
        0,
        29,
        40,
        46,
        49,
        44,
        19,
        0,
        7,
        23,
        20,
        38,
        25,
        8
      ],
      "weeklyRejected": [
        17,
        20,
        35,
        47,
        24,
        30,
        35,
        49,
        29,
        13,
        49,
        21,
        0,
        40,
        40,
        0,
        49,
        49,
        49,
        42,
        0,
        21,
        10,
        49,
        28,
        38,
        42,
        40,
        48,
        48,
        43,
        25,
        48,
        30,
        30,
        21,
        49,
        19,
        1,
        18,
        48,
        20,
        19,
        29,
        36,
        41,
        7,
        1,
        0,
        49,
        14,
        34
      ],
      "weeklyHold": [
        49,
        11,
        49,
        20,
        11,
        15,
        24,
        24,
        0,
        21,
        37,
        35,
        48,
        23,
        22,
        32,
        7,
        43,
        0,
        5,
        29,
        24,
        22,
        32,
        26,
        48,
        24,
        19,
        49,
        39,
        16,
        48,
        20,
        35,
        36,
        17,
        29,
        0,
        8,
        43,
        49,
        19,
        0,
        46,
        49,
        34,
        29,
        39,
        9,
        49,
        31,
        24
      ],
      "weeklyBackordered": [
        49,
        21,
        0,
        15,
        48,
        36,
        38,
        49,
        0,
        18,
        0,
        39,
        3,
        33,
        12,
        17,
        45,
        49,
        35,
        16,
        44,
        10,
        17,
        21,
        9,
        29,
        19,
        20,
        36,
        49,
        17,
        9,
        28,
        44,
        24,
        0,
        43,
        0,
        28,
        33,
        45,
        36,
        4,
        49,
        21,
        49,
        38,
        38,
        12,
        21,
        30,
        12
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        1,
        20,
        49,
        0,
        10,
        6,
        13,
        39,
        15,
        42,
        49,
        0,
        3,
        0,
        16,
        32,
        41,
        0,
        13,
        48,
        13,
        9,
        2,
        43,
        0,
        47,
        36,
        37,
        49,
        27,
        49,
        0,
        37,
        43,
        48,
        22,
        25,
        15,
        49,
        48,
        16,
        36,
        49,
        42,
        16,
        22,
        9,
        45,
        9,
        49,
        35,
        23
      ],
      "weeklyRejected": [
        30,
        11,
        3,
        0,
        32,
        21,
        4,
        18,
        49,
        45,
        0,
        48,
        1,
        2,
        26,
        28,
        4,
        45,
        18,
        13,
        49,
        29,
        19,
        31,
        14,
        10,
        23,
        12,
        28,
        47,
        8,
        41,
        22,
        49,
        0,
        49,
        49,
        10,
        16,
        3,
        19,
        48,
        0,
        0,
        49,
        7,
        15,
        43,
        40,
        4,
        0,
        28
      ],
      "weeklyHold": [
        18,
        30,
        31,
        25,
        47,
        5,
        49,
        19,
        19,
        24,
        8,
        1,
        41,
        1,
        21,
        14,
        19,
        48,
        6,
        0,
        49,
        0,
        9,
        30,
        21,
        12,
        34,
        12,
        49,
        8,
        48,
        25,
        0,
        29,
        18,
        0,
        23,
        10,
        26,
        13,
        2,
        49,
        39,
        16,
        8,
        33,
        0,
        33,
        1,
        0,
        3,
        19
      ],
      "weeklyBackordered": [
        19,
        16,
        0,
        41,
        38,
        32,
        49,
        49,
        21,
        9,
        49,
        28,
        3,
        6,
        40,
        37,
        16,
        43,
        3,
        18,
        4,
        22,
        25,
        10,
        30,
        20,
        11,
        0,
        20,
        0,
        9,
        24,
        0,
        49,
        0,
        0,
        13,
        20,
        0,
        40,
        1,
        48,
        0,
        49,
        0,
        29,
        49,
        15,
        25,
        23,
        36,
        30
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        0,
        40,
        49,
        24,
        15,
        0,
        17,
        49,
        26,
        41,
        49,
        0,
        40,
        37,
        7,
        9,
        49,
        1,
        17,
        26,
        25,
        5,
        49,
        23,
        25,
        30,
        35,
        40,
        35,
        23,
        49,
        0,
        49,
        8,
        26,
        45,
        19,
        27,
        14,
        49,
        0,
        21,
        36,
        21,
        49,
        10,
        15,
        16,
        10,
        1,
        15,
        26
      ],
      "weeklyRejected": [
        49,
        5,
        17,
        22,
        41,
        20,
        27,
        40,
        49,
        1,
        0,
        49,
        0,
        22,
        48,
        16,
        21,
        2,
        0,
        48,
        12,
        49,
        18,
        49,
        31,
        20,
        42,
        0,
        48,
        27,
        49,
        47,
        41,
        34,
        26,
        20,
        39,
        15,
        1,
        30,
        38,
        49,
        39,
        25,
        49,
        34,
        49,
        39,
        0,
        49,
        19,
        32
      ],
      "weeklyHold": [
        49,
        30,
        38,
        20,
        49,
        43,
        40,
        38,
        0,
        35,
        38,
        47,
        48,
        41,
        3,
        0,
        0,
        48,
        49,
        0,
        0,
        28,
        10,
        0,
        0,
        44,
        0,
        6,
        21,
        0,
        38,
        28,
        0,
        49,
        17,
        48,
        49,
        16,
        49,
        0,
        36,
        36,
        11,
        29,
        41,
        49,
        37,
        0,
        46,
        6,
        42,
        23
      ],
      "weeklyBackordered": [
        38,
        39,
        28,
        34,
        46,
        46,
        27,
        29,
        18,
        8,
        49,
        41,
        42,
        0,
        16,
        19,
        49,
        49,
        12,
        17,
        0,
        0,
        19,
        9,
        1,
        32,
        49,
        16,
        17,
        49,
        9,
        49,
        42,
        27,
        0,
        0,
        47,
        4,
        0,
        15,
        24,
        48,
        22,
        49,
        27,
        0,
        49,
        12,
        14,
        49,
        25,
        37
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        4,
        49,
        34,
        19,
        26,
        2,
        25,
        49,
        48,
        30,
        33,
        19,
        24,
        46,
        23,
        40,
        30,
        11,
        49,
        23,
        35,
        0,
        49,
        20,
        3,
        12,
        3,
        0,
        1,
        49,
        43,
        2,
        49,
        41,
        38,
        41,
        26,
        8,
        8,
        49,
        7,
        0,
        49,
        26,
        49,
        35,
        13,
        42,
        27,
        25,
        29,
        19
      ],
      "weeklyRejected": [
        49,
        18,
        32,
        47,
        1,
        49,
        13,
        49,
        36,
        14,
        14,
        49,
        0,
        0,
        38,
        8,
        10,
        6,
        40,
        0,
        9,
        26,
        45,
        20,
        25,
        24,
        43,
        29,
        47,
        0,
        40,
        39,
        13,
        49,
        5,
        35,
        14,
        44,
        23,
        3,
        33,
        31,
        7,
        0,
        46,
        31,
        49,
        10,
        18,
        49,
        28,
        41
      ],
      "weeklyHold": [
        22,
        0,
        35,
        27,
        34,
        45,
        49,
        2,
        22,
        24,
        30,
        27,
        36,
        22,
        44,
        8,
        4,
        0,
        11,
        37,
        27,
        49,
        23,
        0,
        41,
        15,
        19,
        16,
        29,
        22,
        28,
        0,
        0,
        0,
        0,
        20,
        31,
        31,
        1,
        0,
        46,
        37,
        25,
        49,
        40,
        36,
        35,
        49,
        0,
        12,
        45,
        49
      ],
      "weeklyBackordered": [
        34,
        36,
        0,
        36,
        49,
        2,
        49,
        40,
        45,
        10,
        46,
        34,
        10,
        23,
        31,
        35,
        27,
        3,
        1,
        32,
        49,
        32,
        19,
        24,
        39,
        33,
        23,
        40,
        23,
        43,
        11,
        49,
        48,
        25,
        0,
        0,
        1,
        0,
        30,
        0,
        36,
        48,
        0,
        49,
        34,
        49,
        49,
        47,
        14,
        22,
        39,
        9
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyAccepted": [
        2,
        48,
        47,
        0,
        18,
        17,
        34,
        20,
        8,
        0,
        48,
        16,
        49,
        38,
        14,
        18,
        49,
        0,
        30,
        8,
        0,
        18,
        12,
        24,
        9,
        37,
        27,
        0,
        3,
        43,
        35,
        31,
        49,
        42,
        22,
        21,
        37,
        27,
        37,
        49,
        11,
        15,
        15,
        29,
        49,
        21,
        17,
        18,
        0,
        49,
        5,
        36
      ],
      "weeklyRejected": [
        47,
        0,
        17,
        1,
        32,
        33,
        37,
        0,
        10,
        13,
        7,
        36,
        39,
        30,
        18,
        12,
        4,
        49,
        17,
        43,
        4,
        28,
        0,
        49,
        15,
        3,
        34,
        23,
        35,
        27,
        20,
        47,
        23,
        49,
        49,
        25,
        40,
        49,
        1,
        17,
        24,
        33,
        23,
        10,
        49,
        19,
        39,
        7,
        18,
        29,
        0,
        41
      ],
      "weeklyHold": [
        49,
        3,
        31,
        25,
        46,
        32,
        18,
        2,
        44,
        8,
        38,
        11,
        26,
        1,
        25,
        29,
        9,
        0,
        31,
        36,
        12,
        2,
        0,
        26,
        17,
        48,
        0,
        15,
        49,
        0,
        39,
        10,
        0,
        49,
        18,
        49,
        19,
        0,
        15,
        7,
        0,
        37,
        14,
        0,
        6,
        21,
        0,
        23,
        0,
        20,
        35,
        42
      ],
      "weeklyBackordered": [
        30,
        49,
        10,
        49,
        39,
        46,
        42,
        1,
        49,
        1,
        31,
        0,
        13,
        39,
        37,
        37,
        24,
        34,
        18,
        23,
        6,
        49,
        35,
        49,
        17,
        12,
        10,
        6,
        31,
        14,
        8,
        40,
        40,
        24,
        0,
        11,
        13,
        0,
        35,
        24,
        30,
        34,
        49,
        49,
        23,
        15,
        49,
        0,
        23,
        26,
        30,
        28
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        27,
        5,
        16,
        36,
        42,
        10,
        42,
        32,
        48,
        49,
        26,
        16,
        27,
        12,
        49,
        27,
        16,
        0,
        0,
        20,
        21,
        4,
        17,
        49,
        19,
        39,
        0,
        49,
        12,
        42,
        10,
        0,
        8,
        27,
        3,
        38,
        39,
        25,
        33,
        49,
        0,
        0,
        49,
        26,
        34,
        49,
        33,
        0,
        27,
        49,
        47,
        17
      ],
      "weeklyRejected": [
        35,
        32,
        23,
        39,
        0,
        33,
        23,
        44,
        33,
        13,
        44,
        49,
        6,
        22,
        32,
        0,
        49,
        49,
        26,
        16,
        22,
        28,
        24,
        12,
        40,
        49,
        32,
        23,
        45,
        12,
        34,
        26,
        22,
        49,
        0,
        49,
        32,
        0,
        1,
        16,
        29,
        24,
        6,
        13,
        49,
        3,
        0,
        49,
        25,
        48,
        0,
        34
      ],
      "weeklyHold": [
        9,
        43,
        43,
        14,
        15,
        19,
        40,
        12,
        0,
        28,
        41,
        2,
        12,
        49,
        23,
        20,
        16,
        41,
        32,
        31,
        39,
        46,
        14,
        17,
        40,
        48,
        3,
        49,
        33,
        27,
        42,
        15,
        44,
        30,
        1,
        31,
        45,
        31,
        49,
        27,
        49,
        49,
        0,
        47,
        27,
        22,
        14,
        12,
        29,
        49,
        37,
        30
      ],
      "weeklyBackordered": [
        48,
        31,
        17,
        17,
        26,
        34,
        22,
        43,
        25,
        6,
        49,
        49,
        3,
        49,
        8,
        13,
        49,
        40,
        45,
        8,
        49,
        11,
        4,
        17,
        16,
        49,
        30,
        39,
        12,
        49,
        29,
        49,
        17,
        22,
        41,
        1,
        1,
        20,
        37,
        39,
        28,
        47,
        15,
        38,
        31,
        43,
        18,
        19,
        30,
        35,
        1,
        2
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        16,
        34,
        32,
        39,
        22,
        3,
        25,
        49,
        48,
        17,
        21,
        35,
        49,
        5,
        15,
        23,
        26,
        0,
        5,
        22,
        15,
        49,
        47,
        28,
        33,
        30,
        22,
        18,
        1,
        17,
        32,
        1,
        49,
        6,
        48,
        49,
        45,
        48,
        45,
        49,
        9,
        16,
        49,
        30,
        34,
        13,
        0,
        21,
        28,
        49,
        11,
        48
      ],
      "weeklyRejected": [
        49,
        13,
        49,
        19,
        12,
        15,
        37,
        14,
        32,
        1,
        0,
        31,
        0,
        46,
        42,
        34,
        14,
        49,
        26,
        30,
        14,
        11,
        17,
        40,
        7,
        0,
        49,
        0,
        48,
        3,
        49,
        33,
        48,
        40,
        1,
        49,
        41,
        0,
        38,
        49,
        25,
        33,
        49,
        14,
        49,
        33,
        0,
        40,
        36,
        49,
        0,
        40
      ],
      "weeklyHold": [
        30,
        49,
        37,
        1,
        49,
        48,
        33,
        39,
        44,
        17,
        44,
        3,
        34,
        37,
        0,
        15,
        1,
        8,
        0,
        0,
        11,
        0,
        16,
        25,
        0,
        43,
        16,
        0,
        44,
        0,
        8,
        4,
        20,
        49,
        48,
        36,
        42,
        0,
        40,
        4,
        35,
        26,
        0,
        21,
        22,
        8,
        15,
        15,
        10,
        6,
        37,
        49
      ],
      "weeklyBackordered": [
        6,
        8,
        0,
        11,
        31,
        34,
        14,
        1,
        24,
        11,
        43,
        13,
        3,
        49,
        0,
        1,
        11,
        40,
        18,
        49,
        14,
        24,
        0,
        0,
        21,
        35,
        1,
        10,
        20,
        44,
        46,
        49,
        48,
        35,
        0,
        11,
        48,
        0,
        45,
        2,
        8,
        48,
        0,
        49,
        46,
        4,
        24,
        22,
        27,
        41,
        46,
        49
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        17,
        49,
        49,
        25,
        4,
        0,
        27,
        39,
        42,
        4,
        6,
        7,
        44,
        33,
        15,
        11,
        25,
        0,
        0,
        27,
        44,
        40,
        26,
        18,
        27,
        26,
        46,
        22,
        17,
        1,
        3,
        9,
        42,
        1,
        8,
        10,
        34,
        30,
        25,
        22,
        26,
        49,
        46,
        28,
        21,
        19,
        0,
        22,
        2,
        45,
        26,
        5
      ],
      "weeklyRejected": [
        38,
        27,
        26,
        24,
        43,
        49,
        4,
        49,
        45,
        18,
        49,
        1,
        0,
        37,
        18,
        16,
        11,
        29,
        31,
        0,
        34,
        13,
        49,
        49,
        49,
        49,
        20,
        42,
        44,
        3,
        3,
        22,
        29,
        49,
        26,
        49,
        11,
        48,
        17,
        0,
        28,
        34,
        42,
        14,
        49,
        10,
        31,
        26,
        15,
        0,
        20,
        49
      ],
      "weeklyHold": [
        0,
        45,
        28,
        1,
        0,
        48,
        22,
        2,
        23,
        5,
        0,
        2,
        34,
        30,
        28,
        44,
        1,
        5,
        27,
        23,
        11,
        49,
        12,
        12,
        31,
        11,
        49,
        4,
        25,
        29,
        29,
        0,
        11,
        49,
        48,
        22,
        34,
        0,
        34,
        22,
        38,
        29,
        0,
        49,
        39,
        7,
        19,
        49,
        0,
        49,
        37,
        18
      ],
      "weeklyBackordered": [
        20,
        43,
        23,
        8,
        44,
        35,
        49,
        1,
        32,
        0,
        41,
        49,
        9,
        49,
        47,
        22,
        20,
        49,
        0,
        38,
        6,
        49,
        47,
        20,
        29,
        26,
        14,
        30,
        40,
        45,
        17,
        49,
        45,
        35,
        22,
        0,
        49,
        14,
        49,
        26,
        0,
        25,
        0,
        32,
        36,
        23,
        5,
        46,
        34,
        15,
        22,
        49
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        49,
        16,
        33,
        21,
        35,
        3,
        1,
        38,
        41,
        26,
        31,
        19,
        30,
        49,
        26,
        25,
        25,
        39,
        16,
        35,
        0,
        28,
        5,
        49,
        10,
        11,
        0,
        38,
        35,
        49,
        37,
        0,
        43,
        35,
        46,
        2,
        9,
        14,
        0,
        49,
        12,
        40,
        43,
        49,
        19,
        14,
        6,
        16,
        1,
        24,
        16,
        1
      ],
      "weeklyRejected": [
        21,
        0,
        32,
        47,
        49,
        49,
        49,
        2,
        45,
        8,
        49,
        0,
        16,
        48,
        48,
        0,
        28,
        34,
        47,
        4,
        0,
        40,
        21,
        38,
        30,
        48,
        11,
        49,
        48,
        46,
        42,
        28,
        22,
        20,
        21,
        49,
        46,
        22,
        4,
        14,
        35,
        49,
        25,
        27,
        25,
        0,
        31,
        19,
        0,
        23,
        12,
        28
      ],
      "weeklyHold": [
        0,
        8,
        49,
        15,
        11,
        48,
        38,
        32,
        49,
        31,
        47,
        13,
        38,
        46,
        22,
        14,
        5,
        21,
        16,
        48,
        26,
        36,
        27,
        31,
        25,
        40,
        18,
        0,
        13,
        7,
        45,
        19,
        21,
        16,
        22,
        31,
        48,
        9,
        42,
        24,
        21,
        28,
        31,
        32,
        49,
        49,
        0,
        45,
        37,
        30,
        38,
        14
      ],
      "weeklyBackordered": [
        34,
        27,
        8,
        47,
        49,
        15,
        28,
        28,
        26,
        29,
        31,
        17,
        33,
        33,
        46,
        8,
        22,
        31,
        1,
        31,
        0,
        13,
        0,
        43,
        1,
        42,
        1,
        49,
        32,
        40,
        31,
        6,
        33,
        42,
        31,
        8,
        46,
        28,
        26,
        42,
        16,
        44,
        0,
        23,
        11,
        23,
        30,
        49,
        0,
        40,
        25,
        15
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        39,
        12,
        29,
        35,
        36,
        2,
        10,
        49,
        19,
        31,
        48,
        14,
        3,
        42,
        39,
        38,
        20,
        14,
        16,
        0,
        8,
        49,
        49,
        19,
        24,
        36,
        29,
        19,
        1,
        31,
        0,
        40,
        49,
        0,
        48,
        2,
        33,
        14,
        43,
        49,
        9,
        14,
        44,
        0,
        14,
        0,
        13,
        15,
        29,
        46,
        0,
        42
      ],
      "weeklyRejected": [
        49,
        0,
        17,
        24,
        1,
        35,
        18,
        13,
        49,
        17,
        36,
        27,
        49,
        43,
        7,
        21,
        15,
        0,
        27,
        6,
        49,
        0,
        27,
        49,
        22,
        27,
        44,
        29,
        34,
        6,
        45,
        42,
        38,
        49,
        35,
        43,
        23,
        45,
        1,
        14,
        25,
        0,
        44,
        30,
        17,
        18,
        16,
        19,
        17,
        48,
        0,
        23
      ],
      "weeklyHold": [
        0,
        49,
        20,
        22,
        17,
        30,
        44,
        18,
        18,
        22,
        30,
        49,
        22,
        49,
        37,
        41,
        17,
        48,
        21,
        35,
        43,
        49,
        4,
        20,
        39,
        15,
        22,
        18,
        49,
        0,
        39,
        22,
        3,
        15,
        45,
        24,
        31,
        0,
        36,
        25,
        7,
        28,
        0,
        49,
        7,
        5,
        22,
        36,
        3,
        29,
        1,
        39
      ],
      "weeklyBackordered": [
        39,
        35,
        0,
        45,
        49,
        24,
        31,
        35,
        49,
        16,
        35,
        30,
        3,
        49,
        21,
        21,
        49,
        17,
        0,
        24,
        11,
        34,
        16,
        49,
        39,
        16,
        27,
        19,
        19,
        29,
        28,
        49,
        42,
        37,
        20,
        0,
        18,
        0,
        11,
        39,
        7,
        46,
        28,
        49,
        0,
        39,
        25,
        32,
        33,
        46,
        43,
        45
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        22,
        43,
        27,
        24,
        1,
        49,
        11,
        20,
        28,
        49,
        46,
        14,
        35,
        45,
        13,
        27,
        2,
        32,
        49,
        0,
        27,
        44,
        39,
        47,
        15,
        30,
        23,
        49,
        19,
        7,
        17,
        0,
        40,
        30,
        48,
        36,
        2,
        24,
        28,
        49,
        31,
        43,
        24,
        26,
        41,
        23,
        11,
        2,
        1,
        5,
        20,
        45
      ],
      "weeklyRejected": [
        49,
        49,
        14,
        30,
        28,
        14,
        11,
        35,
        49,
        22,
        12,
        31,
        17,
        48,
        40,
        48,
        0,
        36,
        39,
        22,
        0,
        49,
        23,
        26,
        32,
        49,
        15,
        11,
        6,
        42,
        40,
        47,
        27,
        31,
        27,
        49,
        49,
        19,
        1,
        47,
        19,
        22,
        36,
        21,
        37,
        48,
        12,
        45,
        0,
        49,
        17,
        24
      ],
      "weeklyHold": [
        0,
        32,
        9,
        48,
        36,
        26,
        0,
        24,
        49,
        37,
        0,
        49,
        33,
        18,
        0,
        45,
        21,
        8,
        0,
        13,
        33,
        18,
        36,
        23,
        5,
        44,
        31,
        2,
        31,
        29,
        48,
        39,
        0,
        9,
        0,
        46,
        24,
        16,
        37,
        25,
        25,
        49,
        35,
        38,
        33,
        36,
        16,
        0,
        0,
        39,
        14,
        7
      ],
      "weeklyBackordered": [
        0,
        2,
        0,
        23,
        33,
        29,
        0,
        49,
        17,
        17,
        27,
        21,
        23,
        36,
        30,
        27,
        30,
        49,
        16,
        16,
        43,
        5,
        41,
        27,
        22,
        1,
        9,
        4,
        15,
        22,
        49,
        21,
        11,
        49,
        17,
        0,
        25,
        17,
        15,
        8,
        6,
        48,
        6,
        49,
        25,
        22,
        36,
        15,
        0,
        31,
        49,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        33,
        18,
        16,
        36,
        20,
        7,
        49,
        27,
        48,
        26,
        49,
        36,
        48,
        11,
        17,
        30,
        23,
        3,
        0,
        31,
        21,
        49,
        16,
        34,
        47,
        22,
        49,
        25,
        7,
        49,
        40,
        42,
        34,
        0,
        48,
        45,
        29,
        37,
        34,
        49,
        11,
        35,
        49,
        17,
        12,
        22,
        23,
        7,
        5,
        38,
        49,
        20
      ],
      "weeklyRejected": [
        29,
        0,
        42,
        31,
        42,
        46,
        0,
        0,
        49,
        30,
        40,
        49,
        40,
        0,
        48,
        36,
        0,
        37,
        8,
        30,
        7,
        43,
        49,
        7,
        34,
        49,
        47,
        10,
        42,
        25,
        35,
        44,
        44,
        16,
        0,
        28,
        14,
        17,
        9,
        0,
        22,
        28,
        49,
        0,
        34,
        36,
        8,
        28,
        30,
        46,
        0,
        34
      ],
      "weeklyHold": [
        6,
        18,
        49,
        1,
        7,
        1,
        13,
        2,
        0,
        42,
        33,
        41,
        40,
        25,
        17,
        3,
        26,
        0,
        29,
        8,
        0,
        49,
        39,
        6,
        29,
        37,
        49,
        0,
        23,
        26,
        36,
        0,
        9,
        49,
        48,
        13,
        5,
        20,
        20,
        28,
        0,
        49,
        40,
        11,
        19,
        46,
        33,
        32,
        0,
        33,
        40,
        33
      ],
      "weeklyBackordered": [
        41,
        34,
        0,
        13,
        36,
        46,
        0,
        1,
        39,
        35,
        35,
        21,
        10,
        44,
        0,
        22,
        4,
        19,
        0,
        41,
        26,
        11,
        45,
        12,
        23,
        6,
        35,
        39,
        4,
        49,
        0,
        18,
        0,
        49,
        0,
        0,
        25,
        4,
        22,
        7,
        48,
        47,
        0,
        49,
        0,
        3,
        49,
        24,
        29,
        49,
        49,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        37,
        24,
        49,
        18,
        36,
        10,
        30,
        15,
        48,
        49,
        34,
        4,
        38,
        16,
        17,
        33,
        16,
        0,
        0,
        13,
        0,
        46,
        36,
        35,
        0,
        0,
        49,
        48,
        49,
        30,
        40,
        19,
        36,
        0,
        37,
        49,
        9,
        18,
        34,
        34,
        25,
        24,
        26,
        29,
        49,
        15,
        6,
        36,
        14,
        35,
        0,
        37
      ],
      "weeklyRejected": [
        13,
        49,
        49,
        19,
        0,
        8,
        20,
        49,
        17,
        14,
        30,
        49,
        19,
        2,
        24,
        15,
        12,
        44,
        31,
        10,
        49,
        31,
        40,
        23,
        12,
        36,
        12,
        10,
        5,
        10,
        8,
        47,
        30,
        49,
        0,
        49,
        38,
        49,
        1,
        28,
        48,
        9,
        21,
        7,
        49,
        49,
        49,
        49,
        5,
        49,
        8,
        33
      ],
      "weeklyHold": [
        11,
        40,
        26,
        1,
        39,
        35,
        37,
        23,
        20,
        0,
        25,
        22,
        34,
        22,
        30,
        0,
        0,
        48,
        27,
        28,
        16,
        27,
        7,
        4,
        34,
        24,
        36,
        35,
        33,
        42,
        36,
        9,
        18,
        29,
        0,
        0,
        16,
        0,
        34,
        16,
        41,
        43,
        37,
        49,
        22,
        14,
        10,
        18,
        8,
        34,
        35,
        10
      ],
      "weeklyBackordered": [
        29,
        49,
        16,
        11,
        49,
        24,
        23,
        38,
        38,
        0,
        49,
        49,
        17,
        49,
        33,
        39,
        30,
        33,
        1,
        23,
        17,
        0,
        0,
        0,
        20,
        31,
        1,
        0,
        19,
        44,
        20,
        49,
        8,
        25,
        0,
        11,
        31,
        2,
        12,
        16,
        22,
        48,
        0,
        49,
        20,
        11,
        23,
        21,
        12,
        11,
        1,
        1
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        0,
        38,
        25,
        48,
        6,
        19,
        1,
        2,
        42,
        41,
        23,
        21,
        16,
        37,
        29,
        30,
        32,
        17,
        2,
        16,
        25,
        49,
        49,
        49,
        49,
        47,
        0,
        0,
        22,
        45,
        30,
        6,
        42,
        34,
        32,
        37,
        24,
        20,
        25,
        48,
        29,
        30,
        49,
        15,
        49,
        0,
        45,
        40,
        31,
        23,
        3,
        16
      ],
      "weeklyRejected": [
        49,
        18,
        49,
        47,
        45,
        0,
        24,
        22,
        0,
        28,
        40,
        18,
        49,
        42,
        24,
        33,
        49,
        49,
        7,
        49,
        4,
        0,
        9,
        49,
        30,
        49,
        26,
        36,
        48,
        17,
        0,
        47,
        48,
        49,
        25,
        49,
        42,
        1,
        1,
        23,
        35,
        37,
        10,
        9,
        33,
        0,
        21,
        27,
        29,
        49,
        9,
        49
      ],
      "weeklyHold": [
        35,
        29,
        23,
        11,
        44,
        48,
        38,
        26,
        27,
        47,
        14,
        19,
        38,
        31,
        17,
        45,
        8,
        26,
        0,
        16,
        47,
        42,
        29,
        14,
        40,
        48,
        8,
        21,
        49,
        24,
        48,
        1,
        0,
        2,
        5,
        46,
        49,
        4,
        8,
        19,
        49,
        0,
        9,
        12,
        20,
        45,
        10,
        29,
        1,
        49,
        40,
        34
      ],
      "weeklyBackordered": [
        35,
        5,
        0,
        2,
        49,
        21,
        29,
        49,
        22,
        5,
        8,
        0,
        3,
        39,
        49,
        46,
        16,
        49,
        0,
        46,
        42,
        19,
        9,
        41,
        12,
        5,
        17,
        49,
        49,
        47,
        35,
        18,
        11,
        43,
        0,
        0,
        1,
        0,
        20,
        44,
        0,
        41,
        0,
        3,
        0,
        33,
        28,
        3,
        20,
        30,
        1,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyAccepted": [
        49,
        28,
        12,
        0,
        23,
        49,
        3,
        8,
        41,
        25,
        49,
        23,
        43,
        39,
        1,
        8,
        49,
        14,
        44,
        31,
        0,
        49,
        42,
        39,
        23,
        47,
        37,
        23,
        39,
        42,
        4,
        0,
        49,
        5,
        23,
        2,
        17,
        48,
        0,
        49,
        1,
        8,
        49,
        31,
        32,
        0,
        7,
        41,
        26,
        22,
        49,
        13
      ],
      "weeklyRejected": [
        36,
        1,
        21,
        0,
        49,
        35,
        33,
        23,
        48,
        1,
        13,
        22,
        49,
        36,
        46,
        49,
        27,
        17,
        47,
        0,
        0,
        29,
        0,
        38,
        21,
        49,
        22,
        17,
        18,
        18,
        7,
        24,
        46,
        25,
        24,
        49,
        49,
        35,
        29,
        47,
        17,
        33,
        37,
        5,
        49,
        39,
        12,
        36,
        11,
        20,
        22,
        48
      ],
      "weeklyHold": [
        29,
        0,
        49,
        28,
        49,
        22,
        48,
        2,
        42,
        49,
        47,
        1,
        29,
        34,
        49,
        11,
        0,
        1,
        13,
        0,
        0,
        34,
        49,
        49,
        28,
        30,
        0,
        45,
        39,
        0,
        43,
        36,
        11,
        46,
        48,
        31,
        35,
        23,
        23,
        12,
        7,
        16,
        27,
        11,
        14,
        27,
        25,
        33,
        4,
        21,
        26,
        26
      ],
      "weeklyBackordered": [
        43,
        7,
        16,
        49,
        38,
        43,
        49,
        46,
        10,
        17,
        49,
        0,
        49,
        10,
        27,
        49,
        49,
        49,
        0,
        8,
        11,
        15,
        0,
        28,
        10,
        32,
        49,
        31,
        25,
        49,
        16,
        44,
        33,
        20,
        0,
        0,
        14,
        1,
        0,
        20,
        48,
        32,
        1,
        36,
        39,
        37,
        49,
        49,
        25,
        18,
        47,
        49
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        15,
        49,
        34,
        0,
        40,
        0,
        40,
        2,
        36,
        22,
        44,
        8,
        10,
        0,
        34,
        15,
        42,
        13,
        0,
        49,
        44,
        29,
        39,
        38,
        33,
        38,
        34,
        5,
        21,
        1,
        38,
        24,
        49,
        1,
        41,
        39,
        31,
        7,
        43,
        32,
        12,
        24,
        49,
        23,
        21,
        22,
        6,
        25,
        21,
        12,
        25,
        48
      ],
      "weeklyRejected": [
        47,
        29,
        36,
        2,
        15,
        22,
        0,
        49,
        17,
        32,
        2,
        28,
        22,
        25,
        39,
        49,
        24,
        43,
        38,
        33,
        4,
        7,
        0,
        49,
        49,
        24,
        36,
        11,
        26,
        30,
        49,
        47,
        41,
        44,
        49,
        0,
        22,
        13,
        49,
        44,
        28,
        26,
        47,
        0,
        48,
        7,
        3,
        15,
        34,
        49,
        11,
        32
      ],
      "weeklyHold": [
        4,
        33,
        49,
        1,
        39,
        23,
        27,
        26,
        49,
        0,
        47,
        14,
        12,
        16,
        49,
        0,
        5,
        20,
        8,
        1,
        18,
        9,
        6,
        0,
        45,
        21,
        20,
        22,
        39,
        43,
        33,
        12,
        0,
        21,
        40,
        9,
        17,
        8,
        1,
        37,
        25,
        35,
        49,
        32,
        0,
        21,
        0,
        34,
        14,
        49,
        31,
        14
      ],
      "weeklyBackordered": [
        14,
        49,
        11,
        26,
        49,
        25,
        0,
        16,
        49,
        49,
        49,
        25,
        27,
        49,
        7,
        15,
        27,
        19,
        49,
        49,
        16,
        36,
        2,
        3,
        1,
        16,
        21,
        15,
        5,
        49,
        16,
        44,
        48,
        46,
        0,
        22,
        31,
        3,
        46,
        41,
        27,
        48,
        0,
        48,
        35,
        11,
        49,
        0,
        14,
        49,
        5,
        49
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        9,
        1,
        28,
        23,
        1,
        0,
        20,
        4,
        48,
        18,
        27,
        11,
        32,
        24,
        48,
        8,
        36,
        4,
        23,
        19,
        28,
        24,
        24,
        13,
        2,
        47,
        49,
        28,
        25,
        40,
        18,
        24,
        49,
        26,
        23,
        6,
        18,
        20,
        49,
        49,
        30,
        49,
        46,
        49,
        13,
        49,
        0,
        13,
        31,
        25,
        10,
        48
      ],
      "weeklyRejected": [
        49,
        49,
        13,
        46,
        41,
        41,
        33,
        34,
        20,
        8,
        9,
        19,
        15,
        45,
        48,
        0,
        49,
        41,
        42,
        40,
        18,
        5,
        10,
        10,
        31,
        37,
        13,
        2,
        32,
        9,
        31,
        20,
        18,
        30,
        44,
        37,
        24,
        31,
        7,
        16,
        25,
        49,
        3,
        2,
        49,
        44,
        11,
        32,
        0,
        49,
        48,
        13
      ],
      "weeklyHold": [
        0,
        33,
        25,
        5,
        6,
        43,
        0,
        18,
        25,
        20,
        31,
        24,
        23,
        13,
        29,
        42,
        0,
        29,
        37,
        4,
        28,
        0,
        46,
        44,
        26,
        48,
        34,
        15,
        49,
        32,
        35,
        13,
        36,
        49,
        43,
        14,
        25,
        4,
        14,
        49,
        3,
        29,
        46,
        29,
        22,
        32,
        11,
        25,
        23,
        30,
        7,
        29
      ],
      "weeklyBackordered": [
        13,
        8,
        0,
        49,
        46,
        17,
        45,
        23,
        8,
        3,
        0,
        49,
        33,
        25,
        37,
        1,
        49,
        35,
        1,
        14,
        16,
        11,
        10,
        38,
        13,
        49,
        1,
        0,
        34,
        19,
        31,
        43,
        48,
        30,
        24,
        0,
        39,
        12,
        14,
        2,
        36,
        42,
        0,
        49,
        23,
        34,
        25,
        13,
        10,
        14,
        35,
        1
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        24,
        25,
        1,
        48,
        6,
        5,
        7,
        32,
        48,
        0,
        43,
        22,
        46,
        0,
        20,
        44,
        49,
        0,
        0,
        31,
        11,
        42,
        35,
        41,
        0,
        28,
        5,
        49,
        12,
        49,
        39,
        2,
        49,
        21,
        48,
        30,
        47,
        32,
        49,
        20,
        19,
        12,
        45,
        21,
        49,
        25,
        8,
        30,
        24,
        49,
        7,
        45
      ],
      "weeklyRejected": [
        16,
        15,
        37,
        2,
        35,
        35,
        49,
        0,
        41,
        1,
        49,
        0,
        49,
        25,
        48,
        4,
        28,
        22,
        38,
        9,
        6,
        15,
        15,
        15,
        23,
        27,
        49,
        19,
        48,
        24,
        6,
        29,
        47,
        25,
        44,
        49,
        25,
        34,
        8,
        23,
        40,
        49,
        13,
        0,
        43,
        0,
        0,
        42,
        4,
        47,
        0,
        29
      ],
      "weeklyHold": [
        43,
        25,
        44,
        13,
        44,
        39,
        0,
        28,
        17,
        32,
        47,
        49,
        13,
        17,
        48,
        49,
        44,
        33,
        45,
        6,
        28,
        4,
        38,
        25,
        43,
        30,
        24,
        11,
        49,
        14,
        37,
        21,
        0,
        34,
        48,
        0,
        24,
        14,
        24,
        24,
        38,
        49,
        27,
        0,
        36,
        33,
        0,
        49,
        0,
        17,
        19,
        29
      ],
      "weeklyBackordered": [
        0,
        15,
        19,
        31,
        37,
        33,
        40,
        39,
        49,
        16,
        41,
        33,
        3,
        33,
        38,
        49,
        29,
        18,
        0,
        38,
        44,
        23,
        29,
        41,
        46,
        29,
        32,
        19,
        49,
        16,
        0,
        2,
        34,
        41,
        5,
        0,
        10,
        0,
        37,
        17,
        14,
        48,
        21,
        49,
        12,
        0,
        44,
        35,
        36,
        42,
        48,
        1
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        8,
        49,
        30,
        16,
        25,
        0,
        37,
        30,
        18,
        0,
        49,
        31,
        49,
        22,
        20,
        24,
        36,
        9,
        13,
        49,
        45,
        46,
        49,
        34,
        18,
        40,
        0,
        8,
        21,
        31,
        40,
        43,
        21,
        37,
        27,
        40,
        17,
        26,
        49,
        49,
        7,
        0,
        36,
        49,
        47,
        0,
        0,
        49,
        15,
        41,
        0,
        38
      ],
      "weeklyRejected": [
        7,
        0,
        49,
        36,
        38,
        18,
        14,
        14,
        29,
        15,
        29,
        20,
        16,
        48,
        48,
        0,
        27,
        47,
        0,
        26,
        49,
        46,
        31,
        30,
        10,
        34,
        49,
        0,
        41,
        19,
        21,
        47,
        48,
        49,
        49,
        35,
        47,
        30,
        1,
        27,
        42,
        37,
        30,
        6,
        48,
        49,
        49,
        49,
        4,
        45,
        0,
        26
      ],
      "weeklyHold": [
        30,
        30,
        49,
        13,
        22,
        10,
        37,
        2,
        0,
        2,
        47,
        1,
        44,
        38,
        29,
        38,
        8,
        48,
        49,
        33,
        0,
        15,
        23,
        0,
        49,
        48,
        0,
        8,
        4,
        0,
        31,
        0,
        18,
        49,
        48,
        49,
        49,
        0,
        32,
        0,
        30,
        32,
        0,
        43,
        49,
        11,
        4,
        12,
        5,
        13,
        28,
        49
      ],
      "weeklyBackordered": [
        3,
        40,
        0,
        34,
        29,
        3,
        47,
        6,
        43,
        18,
        49,
        49,
        33,
        22,
        29,
        1,
        49,
        49,
        15,
        37,
        0,
        42,
        15,
        7,
        45,
        23,
        32,
        38,
        19,
        45,
        0,
        30,
        46,
        12,
        0,
        0,
        21,
        29,
        11,
        20,
        38,
        48,
        14,
        49,
        33,
        9,
        30,
        0,
        15,
        32,
        5,
        8
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        40,
        49,
        10,
        21,
        27,
        18,
        34,
        20,
        48,
        0,
        47,
        22,
        21,
        7,
        15,
        27,
        49,
        24,
        0,
        35,
        49,
        5,
        35,
        49,
        36,
        27,
        0,
        49,
        37,
        48,
        38,
        0,
        49,
        8,
        48,
        42,
        7,
        48,
        16,
        27,
        0,
        35,
        46,
        49,
        23,
        29,
        17,
        27,
        49,
        33,
        42,
        42
      ],
      "weeklyRejected": [
        37,
        24,
        2,
        47,
        16,
        41,
        27,
        16,
        27,
        17,
        49,
        38,
        0,
        48,
        10,
        0,
        43,
        23,
        38,
        49,
        13,
        30,
        0,
        26,
        25,
        49,
        33,
        33,
        38,
        21,
        34,
        12,
        48,
        49,
        0,
        49,
        17,
        1,
        1,
        11,
        48,
        38,
        27,
        24,
        40,
        27,
        0,
        28,
        38,
        49,
        0,
        30
      ],
      "weeklyHold": [
        40,
        49,
        39,
        1,
        5,
        23,
        49,
        30,
        7,
        49,
        43,
        13,
        18,
        8,
        24,
        13,
        0,
        48,
        0,
        0,
        6,
        41,
        14,
        47,
        38,
        23,
        0,
        39,
        26,
        9,
        48,
        19,
        0,
        45,
        48,
        1,
        29,
        0,
        25,
        14,
        16,
        31,
        30,
        9,
        49,
        49,
        41,
        46,
        4,
        40,
        22,
        39
      ],
      "weeklyBackordered": [
        49,
        49,
        25,
        43,
        45,
        19,
        24,
        48,
        24,
        13,
        23,
        19,
        3,
        41,
        22,
        1,
        49,
        28,
        0,
        39,
        0,
        39,
        1,
        29,
        25,
        1,
        26,
        45,
        19,
        29,
        36,
        49,
        39,
        49,
        18,
        7,
        10,
        22,
        7,
        8,
        41,
        8,
        14,
        49,
        12,
        46,
        33,
        19,
        3,
        47,
        22,
        26
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        6,
        32,
        28,
        13,
        34,
        43,
        23,
        22,
        0,
        20,
        36,
        6,
        48,
        49,
        11,
        28,
        49,
        27,
        14,
        46,
        0,
        47,
        9,
        42,
        13,
        19,
        14,
        2,
        1,
        49,
        45,
        25,
        32,
        0,
        19,
        22,
        16,
        26,
        37,
        0,
        30,
        49,
        45,
        6,
        49,
        6,
        0,
        13,
        25,
        11,
        0,
        30
      ],
      "weeklyRejected": [
        23,
        37,
        49,
        11,
        18,
        20,
        13,
        17,
        24,
        35,
        14,
        34,
        13,
        20,
        26,
        37,
        34,
        49,
        22,
        30,
        0,
        8,
        6,
        28,
        22,
        16,
        22,
        0,
        36,
        2,
        15,
        36,
        18,
        21,
        31,
        49,
        33,
        0,
        19,
        49,
        44,
        36,
        31,
        18,
        46,
        36,
        0,
        9,
        3,
        16,
        0,
        9
      ],
      "weeklyHold": [
        4,
        14,
        49,
        18,
        28,
        33,
        48,
        17,
        30,
        19,
        33,
        32,
        37,
        1,
        48,
        36,
        26,
        19,
        17,
        32,
        22,
        49,
        41,
        41,
        41,
        44,
        24,
        7,
        6,
        13,
        48,
        32,
        0,
        11,
        30,
        8,
        14,
        4,
        32,
        14,
        39,
        49,
        0,
        24,
        31,
        16,
        15,
        29,
        0,
        36,
        4,
        7
      ],
      "weeklyBackordered": [
        49,
        15,
        0,
        14,
        40,
        27,
        49,
        24,
        25,
        17,
        33,
        24,
        3,
        0,
        39,
        30,
        15,
        27,
        32,
        0,
        21,
        32,
        25,
        41,
        12,
        8,
        49,
        8,
        16,
        49,
        43,
        33,
        23,
        37,
        41,
        14,
        11,
        0,
        22,
        7,
        43,
        44,
        15,
        28,
        30,
        27,
        49,
        17,
        0,
        47,
        28,
        24
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        0,
        42,
        20,
        0,
        43,
        49,
        1,
        20,
        44,
        1,
        0,
        0,
        48,
        49,
        9,
        0,
        26,
        9,
        0,
        22,
        15,
        32,
        15,
        49,
        13,
        42,
        0,
        0,
        27,
        49,
        5,
        18,
        49,
        0,
        2,
        30,
        38,
        1,
        20,
        15,
        1,
        20,
        49,
        38,
        25,
        42,
        45,
        0,
        24,
        49,
        23,
        11
      ],
      "weeklyRejected": [
        10,
        46,
        11,
        4,
        39,
        31,
        40,
        3,
        49,
        48,
        23,
        6,
        20,
        48,
        48,
        23,
        24,
        38,
        49,
        7,
        5,
        49,
        49,
        9,
        26,
        49,
        40,
        10,
        0,
        24,
        45,
        7,
        29,
        0,
        25,
        49,
        42,
        23,
        3,
        49,
        39,
        49,
        13,
        0,
        40,
        46,
        9,
        11,
        44,
        49,
        0,
        29
      ],
      "weeklyHold": [
        0,
        0,
        34,
        14,
        49,
        1,
        40,
        7,
        10,
        17,
        13,
        9,
        18,
        22,
        49,
        30,
        29,
        34,
        38,
        23,
        0,
        15,
        45,
        33,
        49,
        1,
        41,
        19,
        35,
        40,
        48,
        9,
        3,
        48,
        10,
        36,
        18,
        11,
        24,
        40,
        9,
        30,
        0,
        0,
        35,
        49,
        49,
        24,
        7,
        33,
        15,
        31
      ],
      "weeklyBackordered": [
        49,
        0,
        0,
        49,
        39,
        40,
        49,
        49,
        0,
        7,
        11,
        30,
        3,
        21,
        47,
        12,
        49,
        23,
        6,
        7,
        49,
        42,
        11,
        39,
        48,
        24,
        4,
        45,
        19,
        42,
        23,
        48,
        11,
        5,
        13,
        0,
        14,
        0,
        10,
        7,
        27,
        48,
        10,
        49,
        15,
        17,
        49,
        23,
        27,
        0,
        49,
        39
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        0,
        49,
        17,
        32,
        1,
        0,
        4,
        7,
        25,
        11,
        10,
        0,
        40,
        17,
        45,
        33,
        49,
        3,
        23,
        26,
        32,
        14,
        41,
        40,
        17,
        33,
        27,
        37,
        7,
        13,
        33,
        42,
        42,
        0,
        48,
        47,
        47,
        46,
        49,
        49,
        0,
        6,
        49,
        34,
        23,
        0,
        0,
        49,
        0,
        32,
        1,
        0
      ],
      "weeklyRejected": [
        49,
        10,
        44,
        32,
        32,
        49,
        32,
        49,
        37,
        37,
        0,
        3,
        8,
        48,
        33,
        35,
        44,
        49,
        5,
        0,
        0,
        21,
        31,
        12,
        35,
        46,
        26,
        8,
        48,
        21,
        0,
        30,
        20,
        49,
        17,
        49,
        46,
        24,
        1,
        24,
        2,
        49,
        35,
        0,
        38,
        40,
        12,
        49,
        0,
        8,
        14,
        49
      ],
      "weeklyHold": [
        30,
        49,
        31,
        14,
        0,
        24,
        46,
        32,
        0,
        22,
        40,
        36,
        36,
        13,
        49,
        37,
        23,
        27,
        9,
        29,
        49,
        18,
        41,
        16,
        19,
        26,
        19,
        12,
        23,
        18,
        48,
        2,
        9,
        32,
        41,
        9,
        3,
        13,
        49,
        49,
        46,
        32,
        2,
        45,
        31,
        33,
        33,
        49,
        0,
        44,
        30,
        24
      ],
      "weeklyBackordered": [
        49,
        12,
        0,
        23,
        21,
        42,
        34,
        1,
        18,
        4,
        38,
        49,
        20,
        0,
        21,
        1,
        27,
        49,
        0,
        33,
        43,
        36,
        0,
        49,
        13,
        36,
        15,
        29,
        1,
        49,
        31,
        3,
        48,
        33,
        0,
        0,
        1,
        0,
        27,
        13,
        22,
        33,
        5,
        17,
        46,
        39,
        22,
        49,
        26,
        49,
        1,
        7
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        14,
        20,
        30,
        0,
        43,
        9,
        4,
        2,
        36,
        0,
        21,
        1,
        11,
        18,
        49,
        45,
        43,
        10,
        10,
        30,
        22,
        25,
        28,
        42,
        37,
        37,
        31,
        22,
        21,
        49,
        18,
        0,
        49,
        20,
        23,
        44,
        23,
        14,
        21,
        49,
        34,
        44,
        49,
        43,
        48,
        0,
        4,
        2,
        20,
        49,
        28,
        38
      ],
      "weeklyRejected": [
        31,
        9,
        6,
        20,
        19,
        4,
        49,
        9,
        30,
        23,
        20,
        19,
        38,
        48,
        45,
        27,
        0,
        39,
        39,
        8,
        49,
        23,
        47,
        2,
        19,
        49,
        49,
        19,
        13,
        4,
        18,
        47,
        21,
        0,
        47,
        36,
        49,
        7,
        1,
        49,
        27,
        17,
        46,
        0,
        27,
        14,
        6,
        23,
        3,
        49,
        0,
        37
      ],
      "weeklyHold": [
        44,
        40,
        49,
        1,
        20,
        38,
        22,
        2,
        3,
        27,
        47,
        16,
        39,
        30,
        26,
        6,
        21,
        34,
        0,
        49,
        0,
        48,
        49,
        15,
        16,
        13,
        34,
        20,
        49,
        26,
        48,
        30,
        0,
        27,
        31,
        49,
        44,
        7,
        49,
        41,
        47,
        36,
        0,
        43,
        10,
        24,
        20,
        20,
        32,
        4,
        34,
        35
      ],
      "weeklyBackordered": [
        49,
        49,
        23,
        23,
        49,
        40,
        25,
        49,
        12,
        2,
        0,
        49,
        6,
        0,
        27,
        1,
        49,
        28,
        22,
        43,
        35,
        22,
        0,
        38,
        1,
        25,
        25,
        9,
        29,
        31,
        11,
        28,
        0,
        11,
        0,
        36,
        21,
        39,
        25,
        30,
        0,
        48,
        20,
        49,
        27,
        5,
        2,
        10,
        49,
        48,
        15,
        25
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        12,
        37,
        31,
        48,
        40,
        7,
        7,
        23,
        48,
        43,
        38,
        0,
        49,
        1,
        49,
        0,
        45,
        23,
        0,
        41,
        22,
        0,
        49,
        28,
        39,
        47,
        43,
        25,
        1,
        30,
        0,
        44,
        9,
        6,
        13,
        21,
        11,
        10,
        49,
        25,
        0,
        17,
        49,
        40,
        49,
        13,
        8,
        28,
        21,
        49,
        15,
        48
      ],
      "weeklyRejected": [
        49,
        34,
        15,
        21,
        47,
        9,
        49,
        49,
        46,
        25,
        49,
        27,
        26,
        34,
        47,
        6,
        18,
        47,
        23,
        7,
        8,
        36,
        16,
        39,
        39,
        34,
        48,
        12,
        48,
        13,
        28,
        33,
        48,
        32,
        15,
        49,
        47,
        16,
        1,
        19,
        48,
        0,
        34,
        21,
        49,
        2,
        5,
        48,
        24,
        39,
        31,
        37
      ],
      "weeklyHold": [
        47,
        25,
        5,
        20,
        14,
        32,
        39,
        28,
        32,
        49,
        47,
        32,
        22,
        45,
        22,
        24,
        0,
        19,
        21,
        35,
        18,
        43,
        21,
        0,
        49,
        17,
        40,
        30,
        34,
        10,
        48,
        1,
        25,
        16,
        21,
        43,
        43,
        0,
        28,
        37,
        46,
        27,
        0,
        6,
        23,
        12,
        16,
        41,
        26,
        49,
        37,
        49
      ],
      "weeklyBackordered": [
        0,
        29,
        15,
        43,
        49,
        26,
        6,
        49,
        22,
        16,
        49,
        42,
        29,
        7,
        34,
        1,
        39,
        49,
        0,
        0,
        43,
        0,
        11,
        18,
        3,
        2,
        11,
        22,
        17,
        14,
        11,
        19,
        48,
        20,
        38,
        0,
        49,
        15,
        24,
        4,
        17,
        48,
        21,
        49,
        31,
        0,
        49,
        18,
        42,
        23,
        38,
        22
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        0,
        33,
        49,
        7,
        9,
        0,
        11,
        46,
        48,
        17,
        31,
        0,
        33,
        48,
        20,
        18,
        49,
        0,
        13,
        25,
        8,
        34,
        10,
        49,
        0,
        30,
        41,
        15,
        11,
        49,
        6,
        0,
        39,
        0,
        29,
        44,
        15,
        8,
        23,
        36,
        0,
        10,
        36,
        49,
        35,
        0,
        12,
        49,
        40,
        39,
        17,
        17
      ],
      "weeklyRejected": [
        49,
        10,
        2,
        7,
        26,
        35,
        32,
        36,
        49,
        5,
        11,
        49,
        21,
        33,
        32,
        29,
        14,
        1,
        0,
        11,
        2,
        45,
        10,
        49,
        0,
        49,
        35,
        46,
        7,
        29,
        49,
        9,
        38,
        0,
        20,
        44,
        49,
        33,
        1,
        25,
        45,
        48,
        29,
        22,
        48,
        37,
        2,
        49,
        0,
        40,
        21,
        26
      ],
      "weeklyHold": [
        1,
        0,
        20,
        23,
        49,
        44,
        47,
        10,
        1,
        43,
        38,
        32,
        2,
        49,
        14,
        0,
        0,
        0,
        22,
        31,
        14,
        49,
        20,
        49,
        5,
        19,
        29,
        49,
        33,
        26,
        48,
        34,
        1,
        41,
        12,
        8,
        27,
        23,
        28,
        0,
        12,
        34,
        17,
        18,
        18,
        38,
        36,
        27,
        5,
        49,
        30,
        49
      ],
      "weeklyBackordered": [
        36,
        21,
        44,
        14,
        34,
        46,
        44,
        4,
        6,
        35,
        32,
        7,
        49,
        17,
        23,
        6,
        49,
        22,
        12,
        0,
        44,
        47,
        17,
        48,
        42,
        19,
        1,
        39,
        30,
        40,
        40,
        47,
        33,
        42,
        0,
        11,
        49,
        14,
        17,
        32,
        32,
        48,
        27,
        37,
        48,
        35,
        49,
        26,
        0,
        49,
        31,
        38
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        4,
        10,
        22,
        0,
        10,
        0,
        38,
        19,
        48,
        47,
        35,
        12,
        0,
        33,
        25,
        21,
        43,
        19,
        43,
        0,
        28,
        47,
        0,
        49,
        28,
        22,
        37,
        3,
        13,
        49,
        0,
        39,
        39,
        34,
        30,
        13,
        22,
        48,
        34,
        30,
        17,
        34,
        49,
        10,
        5,
        37,
        8,
        6,
        0,
        41,
        0,
        40
      ],
      "weeklyRejected": [
        49,
        28,
        2,
        0,
        32,
        20,
        16,
        32,
        44,
        22,
        23,
        33,
        0,
        29,
        33,
        7,
        21,
        30,
        16,
        0,
        19,
        17,
        21,
        0,
        10,
        49,
        21,
        25,
        29,
        24,
        0,
        34,
        39,
        49,
        44,
        49,
        0,
        34,
        7,
        0,
        16,
        24,
        28,
        16,
        49,
        19,
        0,
        25,
        25,
        49,
        0,
        27
      ],
      "weeklyHold": [
        23,
        25,
        49,
        21,
        49,
        37,
        10,
        16,
        12,
        22,
        35,
        30,
        11,
        25,
        36,
        21,
        4,
        37,
        28,
        8,
        43,
        0,
        39,
        16,
        49,
        24,
        25,
        5,
        14,
        19,
        27,
        0,
        16,
        49,
        48,
        15,
        12,
        18,
        28,
        49,
        0,
        49,
        15,
        0,
        28,
        13,
        0,
        0,
        7,
        31,
        45,
        25
      ],
      "weeklyBackordered": [
        42,
        39,
        0,
        29,
        46,
        46,
        48,
        15,
        32,
        16,
        18,
        4,
        30,
        3,
        35,
        31,
        35,
        37,
        0,
        43,
        39,
        30,
        24,
        24,
        1,
        16,
        11,
        37,
        31,
        45,
        25,
        43,
        27,
        27,
        0,
        0,
        13,
        7,
        49,
        47,
        31,
        45,
        4,
        16,
        32,
        18,
        0,
        16,
        0,
        33,
        25,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        2,
        1,
        19,
        16,
        37,
        32,
        1,
        36,
        38,
        33,
        42,
        11,
        41,
        42,
        18,
        49,
        18,
        48,
        32,
        43,
        0,
        37,
        49,
        49,
        49,
        47,
        14,
        21,
        49,
        47,
        9,
        30,
        35,
        37,
        23,
        36,
        39,
        40,
        12,
        49,
        28,
        38,
        49,
        31,
        49,
        32,
        31,
        39,
        0,
        30,
        0,
        39
      ],
      "weeklyRejected": [
        31,
        23,
        24,
        26,
        40,
        22,
        0,
        0,
        49,
        48,
        24,
        8,
        27,
        42,
        22,
        17,
        18,
        44,
        47,
        49,
        13,
        43,
        25,
        22,
        33,
        49,
        18,
        49,
        31,
        29,
        42,
        28,
        17,
        49,
        2,
        49,
        49,
        46,
        1,
        49,
        48,
        43,
        36,
        0,
        49,
        15,
        49,
        14,
        23,
        33,
        0,
        42
      ],
      "weeklyHold": [
        30,
        25,
        24,
        14,
        34,
        8,
        39,
        2,
        0,
        16,
        20,
        49,
        32,
        39,
        41,
        46,
        23,
        36,
        19,
        0,
        0,
        49,
        0,
        19,
        3,
        35,
        43,
        47,
        35,
        0,
        42,
        0,
        0,
        27,
        0,
        39,
        49,
        9,
        37,
        42,
        33,
        25,
        8,
        49,
        16,
        0,
        27,
        46,
        0,
        49,
        49,
        38
      ],
      "weeklyBackordered": [
        35,
        49,
        8,
        7,
        41,
        19,
        49,
        44,
        49,
        25,
        49,
        6,
        3,
        23,
        19,
        1,
        22,
        41,
        23,
        28,
        34,
        5,
        29,
        49,
        17,
        14,
        32,
        10,
        17,
        49,
        0,
        38,
        48,
        31,
        27,
        0,
        17,
        0,
        0,
        16,
        34,
        48,
        2,
        49,
        24,
        26,
        49,
        32,
        35,
        32,
        36,
        8
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        0,
        22,
        49,
        48,
        16,
        0,
        27,
        31,
        42,
        36,
        36,
        10,
        49,
        47,
        9,
        18,
        49,
        0,
        0,
        21,
        0,
        0,
        26,
        26,
        1,
        33,
        35,
        45,
        1,
        42,
        35,
        20,
        49,
        49,
        34,
        4,
        21,
        5,
        35,
        46,
        2,
        17,
        17,
        19,
        23,
        0,
        0,
        0,
        7,
        20,
        21,
        29
      ],
      "weeklyRejected": [
        36,
        29,
        49,
        47,
        49,
        31,
        49,
        49,
        41,
        24,
        16,
        30,
        22,
        36,
        48,
        3,
        0,
        49,
        47,
        0,
        13,
        0,
        14,
        40,
        11,
        32,
        41,
        7,
        48,
        0,
        18,
        34,
        22,
        0,
        0,
        28,
        22,
        36,
        19,
        22,
        5,
        0,
        43,
        33,
        41,
        14,
        0,
        9,
        0,
        49,
        0,
        30
      ],
      "weeklyHold": [
        35,
        7,
        49,
        1,
        26,
        9,
        4,
        23,
        15,
        4,
        28,
        49,
        38,
        32,
        38,
        43,
        10,
        0,
        25,
        0,
        12,
        24,
        49,
        14,
        18,
        1,
        32,
        37,
        49,
        34,
        27,
        5,
        0,
        39,
        6,
        22,
        0,
        0,
        18,
        42,
        6,
        39,
        31,
        3,
        0,
        32,
        18,
        39,
        0,
        30,
        5,
        49
      ],
      "weeklyBackordered": [
        24,
        21,
        4,
        17,
        22,
        36,
        39,
        30,
        46,
        18,
        42,
        43,
        42,
        40,
        42,
        49,
        6,
        36,
        5,
        23,
        33,
        40,
        47,
        22,
        48,
        29,
        6,
        37,
        18,
        17,
        46,
        0,
        27,
        32,
        0,
        0,
        31,
        0,
        14,
        45,
        24,
        48,
        8,
        49,
        3,
        14,
        49,
        25,
        0,
        0,
        31,
        5
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        0,
        44,
        29,
        37,
        49,
        6,
        25,
        49,
        48,
        21,
        40,
        4,
        34,
        15,
        14,
        28,
        49,
        32,
        25,
        1,
        31,
        46,
        31,
        35,
        0,
        5,
        27,
        47,
        18,
        29,
        0,
        30,
        49,
        39,
        44,
        48,
        21,
        48,
        24,
        39,
        11,
        27,
        21,
        31,
        12,
        31,
        0,
        18,
        16,
        41,
        21,
        0
      ],
      "weeklyRejected": [
        31,
        8,
        30,
        33,
        37,
        1,
        37,
        29,
        37,
        4,
        0,
        23,
        20,
        27,
        48,
        18,
        0,
        32,
        21,
        8,
        23,
        14,
        28,
        0,
        32,
        49,
        49,
        38,
        48,
        34,
        11,
        12,
        48,
        42,
        9,
        47,
        10,
        20,
        19,
        16,
        21,
        46,
        46,
        31,
        47,
        2,
        21,
        49,
        0,
        25,
        0,
        49
      ],
      "weeklyHold": [
        23,
        23,
        33,
        17,
        9,
        43,
        33,
        11,
        2,
        49,
        47,
        49,
        1,
        44,
        43,
        10,
        11,
        36,
        30,
        3,
        39,
        13,
        7,
        7,
        35,
        28,
        21,
        1,
        49,
        5,
        26,
        27,
        26,
        11,
        28,
        49,
        28,
        12,
        27,
        37,
        28,
        40,
        1,
        49,
        40,
        35,
        49,
        26,
        0,
        18,
        39,
        41
      ],
      "weeklyBackordered": [
        0,
        14,
        47,
        20,
        41,
        46,
        20,
        49,
        42,
        9,
        12,
        23,
        49,
        19,
        27,
        35,
        19,
        28,
        0,
        13,
        10,
        24,
        22,
        0,
        21,
        16,
        24,
        15,
        16,
        41,
        35,
        43,
        12,
        29,
        0,
        0,
        1,
        5,
        49,
        40,
        2,
        27,
        1,
        48,
        11,
        42,
        46,
        16,
        22,
        17,
        48,
        1
      ]
    },
    {
      "id": "8164C892",
      "weeklyAccepted": [
        31,
        32,
        49,
        21,
        49,
        0,
        1,
        49,
        25,
        10,
        45,
        17,
        14,
        12,
        8,
        0,
        49,
        33,
        10,
        22,
        30,
        0,
        49,
        15,
        0,
        47,
        10,
        43,
        21,
        7,
        18,
        0,
        18,
        48,
        34,
        41,
        24,
        21,
        49,
        49,
        0,
        15,
        49,
        39,
        26,
        0,
        27,
        10,
        9,
        3,
        0,
        0
      ],
      "weeklyRejected": [
        35,
        14,
        8,
        24,
        33,
        13,
        49,
        7,
        37,
        20,
        35,
        29,
        37,
        48,
        17,
        9,
        0,
        49,
        46,
        23,
        3,
        45,
        24,
        49,
        25,
        40,
        49,
        49,
        27,
        31,
        31,
        29,
        31,
        8,
        0,
        41,
        41,
        0,
        39,
        10,
        41,
        0,
        24,
        0,
        49,
        4,
        0,
        49,
        46,
        24,
        3,
        38
      ],
      "weeklyHold": [
        0,
        23,
        49,
        46,
        43,
        31,
        25,
        2,
        11,
        17,
        18,
        49,
        13,
        36,
        33,
        24,
        10,
        6,
        0,
        0,
        12,
        28,
        32,
        4,
        29,
        13,
        46,
        49,
        38,
        17,
        48,
        32,
        8,
        48,
        0,
        49,
        0,
        17,
        49,
        16,
        49,
        33,
        0,
        0,
        30,
        38,
        10,
        15,
        0,
        49,
        12,
        19
      ],
      "weeklyBackordered": [
        35,
        16,
        21,
        46,
        44,
        32,
        44,
        49,
        17,
        7,
        25,
        18,
        14,
        49,
        29,
        31,
        24,
        49,
        33,
        6,
        49,
        14,
        49,
        49,
        4,
        1,
        26,
        6,
        32,
        46,
        16,
        20,
        35,
        19,
        23,
        18,
        49,
        0,
        19,
        44,
        48,
        48,
        0,
        49,
        0,
        49,
        42,
        43,
        0,
        49,
        49,
        8
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        3,
        32,
        28,
        29,
        28,
        0,
        25,
        21,
        48,
        19,
        9,
        21,
        49,
        49,
        46,
        49,
        22,
        0,
        3,
        49,
        16,
        11,
        31,
        34,
        46,
        33,
        0,
        47,
        42,
        49,
        45,
        42,
        18,
        31,
        46,
        16,
        47,
        48,
        20,
        47,
        23,
        24,
        49,
        12,
        49,
        0,
        15,
        49,
        0,
        26,
        44,
        48
      ],
      "weeklyRejected": [
        37,
        0,
        22,
        46,
        26,
        36,
        39,
        21,
        45,
        1,
        7,
        23,
        21,
        18,
        46,
        0,
        30,
        47,
        24,
        33,
        33,
        49,
        21,
        38,
        27,
        34,
        49,
        37,
        48,
        0,
        47,
        10,
        48,
        49,
        0,
        32,
        43,
        2,
        1,
        18,
        28,
        0,
        16,
        1,
        49,
        0,
        49,
        8,
        0,
        14,
        9,
        45
      ],
      "weeklyHold": [
        15,
        19,
        49,
        1,
        49,
        25,
        37,
        18,
        45,
        38,
        47,
        1,
        35,
        41,
        19,
        40,
        23,
        48,
        20,
        35,
        2,
        14,
        6,
        0,
        18,
        47,
        0,
        17,
        24,
        0,
        34,
        0,
        0,
        45,
        46,
        36,
        46,
        35,
        33,
        24,
        0,
        39,
        0,
        26,
        11,
        13,
        10,
        34,
        0,
        34,
        4,
        43
      ],
      "weeklyBackordered": [
        6,
        38,
        0,
        41,
        49,
        43,
        49,
        47,
        49,
        0,
        49,
        49,
        3,
        20,
        11,
        1,
        49,
        8,
        17,
        49,
        41,
        44,
        31,
        48,
        35,
        21,
        1,
        0,
        1,
        40,
        0,
        33,
        42,
        39,
        43,
        0,
        1,
        0,
        49,
        15,
        17,
        48,
        9,
        49,
        16,
        33,
        4,
        10,
        31,
        42,
        10,
        6
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        0,
        48,
        49,
        48,
        21,
        25,
        49,
        17,
        48,
        32,
        32,
        0,
        10,
        49,
        31,
        31,
        21,
        12,
        9,
        0,
        15,
        45,
        29,
        16,
        0,
        32,
        35,
        23,
        26,
        29,
        0,
        47,
        43,
        0,
        48,
        25,
        13,
        48,
        0,
        33,
        44,
        46,
        49,
        15,
        28,
        0,
        38,
        0,
        6,
        49,
        49,
        30
      ],
      "weeklyRejected": [
        49,
        22,
        49,
        33,
        23,
        38,
        21,
        42,
        49,
        12,
        46,
        42,
        29,
        48,
        35,
        0,
        31,
        48,
        35,
        46,
        49,
        0,
        49,
        0,
        5,
        49,
        25,
        17,
        40,
        45,
        36,
        15,
        48,
        49,
        17,
        19,
        49,
        33,
        1,
        29,
        48,
        45,
        19,
        11,
        49,
        12,
        0,
        44,
        0,
        49,
        8,
        42
      ],
      "weeklyHold": [
        19,
        31,
        16,
        1,
        0,
        46,
        43,
        15,
        22,
        39,
        37,
        49,
        39,
        1,
        19,
        19,
        2,
        23,
        18,
        30,
        43,
        17,
        45,
        40,
        39,
        45,
        34,
        0,
        29,
        0,
        48,
        10,
        0,
        8,
        45,
        36,
        49,
        0,
        46,
        12,
        0,
        28,
        3,
        49,
        49,
        47,
        0,
        44,
        36,
        49,
        0,
        31
      ],
      "weeklyBackordered": [
        46,
        0,
        38,
        49,
        49,
        44,
        26,
        49,
        38,
        28,
        16,
        36,
        15,
        34,
        38,
        1,
        49,
        28,
        0,
        0,
        19,
        22,
        49,
        49,
        11,
        27,
        17,
        29,
        49,
        32,
        4,
        0,
        26,
        45,
        34,
        9,
        1,
        0,
        49,
        34,
        39,
        48,
        15,
        49,
        24,
        23,
        49,
        22,
        38,
        41,
        1,
        7
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        16,
        37,
        1,
        48,
        25,
        0,
        19,
        19,
        37,
        21,
        38,
        0,
        15,
        16,
        14,
        0,
        20,
        39,
        9,
        42,
        49,
        26,
        40,
        11,
        14,
        47,
        19,
        49,
        26,
        19,
        36,
        18,
        20,
        49,
        28,
        19,
        47,
        40,
        0,
        49,
        28,
        41,
        49,
        43,
        25,
        29,
        31,
        32,
        7,
        49,
        0,
        0
      ],
      "weeklyRejected": [
        49,
        36,
        3,
        0,
        31,
        0,
        24,
        4,
        36,
        37,
        11,
        38,
        3,
        22,
        47,
        14,
        32,
        19,
        45,
        45,
        9,
        49,
        22,
        13,
        49,
        35,
        34,
        17,
        44,
        16,
        49,
        35,
        41,
        26,
        0,
        39,
        49,
        0,
        1,
        12,
        45,
        49,
        41,
        20,
        49,
        3,
        23,
        22,
        26,
        22,
        17,
        31
      ],
      "weeklyHold": [
        11,
        21,
        49,
        1,
        21,
        33,
        0,
        18,
        13,
        15,
        12,
        41,
        1,
        49,
        14,
        0,
        0,
        48,
        35,
        0,
        32,
        25,
        38,
        2,
        16,
        12,
        0,
        36,
        45,
        0,
        48,
        19,
        27,
        37,
        48,
        40,
        13,
        0,
        49,
        12,
        38,
        10,
        22,
        28,
        49,
        10,
        35,
        49,
        5,
        22,
        49,
        32
      ],
      "weeklyBackordered": [
        38,
        16,
        27,
        3,
        27,
        11,
        49,
        32,
        3,
        49,
        18,
        17,
        9,
        40,
        28,
        24,
        16,
        49,
        20,
        26,
        45,
        41,
        27,
        49,
        7,
        8,
        1,
        14,
        40,
        0,
        26,
        46,
        30,
        44,
        0,
        0,
        22,
        25,
        9,
        15,
        0,
        46,
        22,
        49,
        0,
        5,
        49,
        37,
        27,
        39,
        36,
        9
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyAccepted": [
        24,
        20,
        49,
        32,
        49,
        8,
        37,
        44,
        34,
        0,
        32,
        0,
        42,
        22,
        1,
        49,
        44,
        26,
        25,
        7,
        37,
        0,
        48,
        23,
        20,
        13,
        0,
        40,
        28,
        22,
        29,
        0,
        49,
        34,
        21,
        21,
        43,
        8,
        15,
        28,
        27,
        19,
        30,
        26,
        49,
        15,
        5,
        49,
        49,
        49,
        8,
        8
      ],
      "weeklyRejected": [
        49,
        10,
        36,
        5,
        47,
        0,
        42,
        45,
        25,
        48,
        39,
        35,
        0,
        48,
        25,
        16,
        8,
        25,
        49,
        12,
        26,
        34,
        49,
        46,
        10,
        44,
        49,
        14,
        41,
        26,
        25,
        47,
        15,
        49,
        32,
        49,
        47,
        35,
        26,
        48,
        42,
        49,
        23,
        4,
        49,
        42,
        13,
        40,
        0,
        27,
        4,
        8
      ],
      "weeklyHold": [
        38,
        9,
        24,
        22,
        18,
        21,
        35,
        21,
        35,
        32,
        38,
        37,
        45,
        36,
        5,
        0,
        29,
        0,
        47,
        49,
        0,
        12,
        18,
        49,
        31,
        41,
        49,
        3,
        46,
        0,
        18,
        32,
        19,
        0,
        24,
        14,
        39,
        48,
        49,
        19,
        25,
        33,
        34,
        49,
        18,
        42,
        2,
        45,
        2,
        11,
        30,
        22
      ],
      "weeklyBackordered": [
        28,
        49,
        26,
        32,
        42,
        30,
        49,
        30,
        26,
        0,
        49,
        49,
        28,
        28,
        40,
        49,
        45,
        47,
        12,
        9,
        45,
        25,
        0,
        40,
        14,
        29,
        31,
        34,
        13,
        27,
        28,
        1,
        43,
        47,
        0,
        0,
        17,
        32,
        0,
        20,
        23,
        11,
        21,
        28,
        45,
        49,
        37,
        23,
        49,
        49,
        46,
        32
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        0,
        30,
        30,
        33,
        2,
        22,
        5,
        49,
        25,
        32,
        49,
        0,
        49,
        40,
        18,
        41,
        45,
        2,
        10,
        28,
        11,
        27,
        13,
        40,
        0,
        19,
        13,
        36,
        31,
        35,
        45,
        7,
        32,
        22,
        26,
        26,
        14,
        37,
        25,
        11,
        2,
        32,
        31,
        0,
        49,
        18,
        0,
        43,
        35,
        32,
        13,
        21
      ],
      "weeklyRejected": [
        46,
        29,
        37,
        47,
        24,
        10,
        31,
        2,
        7,
        1,
        20,
        0,
        13,
        7,
        29,
        5,
        19,
        33,
        46,
        6,
        49,
        38,
        38,
        16,
        9,
        18,
        13,
        35,
        48,
        0,
        33,
        20,
        48,
        42,
        11,
        24,
        13,
        49,
        23,
        49,
        22,
        4,
        27,
        1,
        49,
        24,
        43,
        23,
        0,
        30,
        18,
        31
      ],
      "weeklyHold": [
        30,
        8,
        49,
        17,
        20,
        8,
        15,
        2,
        20,
        45,
        26,
        47,
        30,
        14,
        38,
        35,
        0,
        30,
        18,
        14,
        37,
        39,
        49,
        0,
        49,
        30,
        0,
        15,
        49,
        0,
        14,
        34,
        0,
        46,
        23,
        38,
        20,
        1,
        38,
        42,
        19,
        33,
        46,
        49,
        38,
        40,
        0,
        29,
        1,
        40,
        0,
        36
      ],
      "weeklyBackordered": [
        0,
        49,
        0,
        28,
        20,
        46,
        49,
        24,
        41,
        29,
        39,
        25,
        3,
        42,
        27,
        14,
        44,
        1,
        49,
        23,
        22,
        32,
        14,
        27,
        41,
        1,
        8,
        13,
        49,
        49,
        30,
        30,
        31,
        24,
        0,
        13,
        19,
        17,
        17,
        49,
        23,
        34,
        25,
        49,
        39,
        11,
        49,
        0,
        34,
        35,
        18,
        12
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        0,
        49,
        11,
        37,
        8,
        35,
        9,
        49,
        17,
        14,
        49,
        0,
        49,
        49,
        31,
        37,
        7,
        8,
        40,
        37,
        19,
        14,
        5,
        19,
        0,
        39,
        34,
        0,
        23,
        6,
        0,
        27,
        49,
        21,
        46,
        27,
        35,
        30,
        20,
        49,
        6,
        6,
        42,
        21,
        49,
        41,
        0,
        0,
        5,
        49,
        20,
        5
      ],
      "weeklyRejected": [
        49,
        42,
        21,
        0,
        49,
        0,
        32,
        29,
        41,
        1,
        34,
        49,
        18,
        23,
        9,
        29,
        16,
        28,
        25,
        49,
        14,
        31,
        27,
        43,
        38,
        49,
        19,
        31,
        40,
        0,
        4,
        30,
        14,
        49,
        16,
        49,
        49,
        31,
        49,
        37,
        47,
        26,
        47,
        0,
        49,
        41,
        4,
        38,
        4,
        10,
        49,
        39
      ],
      "weeklyHold": [
        46,
        3,
        45,
        14,
        26,
        35,
        21,
        19,
        12,
        0,
        47,
        49,
        44,
        29,
        40,
        16,
        1,
        24,
        0,
        49,
        0,
        41,
        49,
        25,
        27,
        26,
        16,
        23,
        26,
        5,
        26,
        6,
        3,
        49,
        16,
        31,
        26,
        11,
        27,
        10,
        0,
        36,
        24,
        49,
        6,
        49,
        19,
        49,
        0,
        28,
        11,
        49
      ],
      "weeklyBackordered": [
        32,
        13,
        28,
        42,
        37,
        46,
        47,
        49,
        49,
        19,
        49,
        15,
        30,
        22,
        24,
        49,
        32,
        49,
        43,
        13,
        37,
        0,
        25,
        17,
        23,
        4,
        4,
        13,
        49,
        16,
        34,
        22,
        42,
        35,
        1,
        0,
        27,
        24,
        12,
        33,
        26,
        26,
        0,
        49,
        38,
        49,
        49,
        26,
        21,
        30,
        49,
        42
      ]
    },
    {
      "id": "8164C892",
      "weeklyAccepted": [
        13,
        11,
        27,
        16,
        1,
        5,
        36,
        22,
        31,
        49,
        49,
        32,
        16,
        47,
        21,
        8,
        49,
        31,
        4,
        27,
        3,
        41,
        17,
        49,
        8,
        26,
        34,
        48,
        16,
        49,
        36,
        0,
        39,
        11,
        9,
        33,
        37,
        27,
        0,
        47,
        19,
        46,
        49,
        37,
        32,
        0,
        6,
        40,
        3,
        24,
        24,
        21
      ],
      "weeklyRejected": [
        45,
        39,
        26,
        0,
        49,
        31,
        0,
        49,
        49,
        23,
        17,
        49,
        35,
        23,
        27,
        22,
        48,
        39,
        49,
        0,
        6,
        10,
        19,
        49,
        38,
        49,
        0,
        17,
        0,
        23,
        24,
        36,
        20,
        27,
        26,
        49,
        46,
        32,
        8,
        49,
        48,
        7,
        16,
        28,
        34,
        6,
        0,
        49,
        24,
        49,
        28,
        26
      ],
      "weeklyHold": [
        33,
        40,
        21,
        14,
        49,
        25,
        40,
        2,
        0,
        24,
        35,
        28,
        1,
        33,
        36,
        32,
        31,
        19,
        32,
        0,
        0,
        25,
        44,
        49,
        11,
        37,
        0,
        29,
        33,
        22,
        48,
        0,
        0,
        17,
        32,
        49,
        0,
        22,
        49,
        49,
        34,
        26,
        0,
        19,
        8,
        49,
        19,
        11,
        0,
        18,
        34,
        18
      ],
      "weeklyBackordered": [
        14,
        44,
        0,
        32,
        38,
        46,
        7,
        49,
        43,
        31,
        14,
        10,
        5,
        27,
        42,
        49,
        49,
        27,
        18,
        5,
        29,
        27,
        18,
        0,
        1,
        9,
        46,
        32,
        22,
        27,
        35,
        49,
        2,
        49,
        0,
        7,
        15,
        0,
        40,
        49,
        0,
        46,
        4,
        49,
        0,
        0,
        0,
        49,
        11,
        21,
        31,
        43
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        0,
        20,
        26,
        48,
        49,
        2,
        20,
        49,
        44,
        18,
        49,
        5,
        42,
        25,
        24,
        46,
        0,
        48,
        0,
        15,
        13,
        17,
        34,
        32,
        49,
        47,
        0,
        20,
        9,
        28,
        3,
        29,
        49,
        44,
        9,
        41,
        46,
        48,
        44,
        37,
        21,
        30,
        30,
        49,
        40,
        0,
        27,
        48,
        24,
        49,
        1,
        3
      ],
      "weeklyRejected": [
        19,
        9,
        27,
        47,
        18,
        0,
        44,
        12,
        16,
        30,
        49,
        0,
        29,
        42,
        21,
        0,
        3,
        5,
        49,
        27,
        47,
        49,
        0,
        49,
        0,
        42,
        41,
        3,
        46,
        0,
        34,
        33,
        46,
        26,
        49,
        49,
        2,
        0,
        4,
        44,
        44,
        41,
        49,
        46,
        25,
        19,
        29,
        9,
        9,
        49,
        8,
        47
      ],
      "weeklyHold": [
        49,
        15,
        49,
        1,
        49,
        32,
        9,
        22,
        22,
        18,
        21,
        20,
        26,
        22,
        0,
        20,
        10,
        35,
        17,
        0,
        47,
        35,
        0,
        7,
        7,
        48,
        28,
        27,
        27,
        15,
        48,
        14,
        0,
        46,
        33,
        49,
        38,
        0,
        49,
        21,
        42,
        28,
        9,
        39,
        31,
        18,
        45,
        37,
        7,
        13,
        25,
        30
      ],
      "weeklyBackordered": [
        49,
        30,
        31,
        15,
        25,
        43,
        0,
        49,
        7,
        15,
        21,
        40,
        10,
        33,
        24,
        26,
        30,
        35,
        0,
        32,
        43,
        36,
        24,
        38,
        21,
        20,
        49,
        49,
        22,
        24,
        44,
        22,
        23,
        19,
        0,
        9,
        24,
        0,
        8,
        49,
        16,
        21,
        27,
        24,
        26,
        23,
        36,
        28,
        20,
        49,
        36,
        32
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        4,
        24,
        26,
        20,
        35,
        8,
        46,
        13,
        39,
        15,
        49,
        9,
        22,
        23,
        10,
        6,
        49,
        36,
        3,
        49,
        5,
        27,
        49,
        29,
        0,
        42,
        20,
        41,
        6,
        13,
        0,
        19,
        49,
        8,
        31,
        2,
        4,
        48,
        49,
        44,
        25,
        27,
        49,
        0,
        47,
        0,
        0,
        26,
        14,
        49,
        16,
        35
      ],
      "weeklyRejected": [
        40,
        31,
        49,
        23,
        49,
        0,
        26,
        27,
        26,
        48,
        29,
        33,
        3,
        12,
        48,
        0,
        31,
        38,
        39,
        2,
        17,
        10,
        9,
        17,
        14,
        10,
        49,
        27,
        48,
        0,
        49,
        37,
        48,
        17,
        20,
        35,
        10,
        0,
        27,
        49,
        43,
        9,
        41,
        0,
        38,
        38,
        10,
        17,
        19,
        49,
        0,
        49
      ],
      "weeklyHold": [
        25,
        0,
        30,
        1,
        8,
        7,
        34,
        20,
        0,
        42,
        47,
        39,
        48,
        1,
        36,
        31,
        0,
        0,
        0,
        19,
        9,
        15,
        20,
        0,
        26,
        18,
        3,
        7,
        31,
        48,
        29,
        22,
        0,
        26,
        0,
        24,
        15,
        15,
        39,
        49,
        0,
        38,
        18,
        33,
        11,
        49,
        43,
        28,
        0,
        34,
        7,
        49
      ],
      "weeklyBackordered": [
        0,
        33,
        48,
        49,
        7,
        39,
        0,
        49,
        31,
        1,
        49,
        49,
        7,
        49,
        38,
        26,
        49,
        47,
        9,
        22,
        44,
        49,
        0,
        3,
        45,
        47,
        8,
        1,
        21,
        43,
        48,
        30,
        46,
        49,
        0,
        0,
        29,
        0,
        15,
        0,
        0,
        48,
        1,
        49,
        0,
        39,
        42,
        2,
        30,
        35,
        23,
        34
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        46,
        47,
        49,
        13,
        24,
        0,
        14,
        30,
        15,
        49,
        34,
        0,
        3,
        38,
        1,
        49,
        35,
        0,
        0,
        47,
        21,
        47,
        49,
        1,
        21,
        47,
        13,
        9,
        37,
        16,
        21,
        18,
        46,
        44,
        24,
        41,
        40,
        14,
        10,
        17,
        18,
        26,
        28,
        30,
        49,
        16,
        0,
        40,
        12,
        26,
        13,
        16
      ],
      "weeklyRejected": [
        49,
        46,
        35,
        0,
        38,
        15,
        37,
        38,
        49,
        1,
        0,
        0,
        14,
        48,
        48,
        0,
        42,
        49,
        49,
        25,
        48,
        49,
        47,
        5,
        32,
        49,
        1,
        35,
        42,
        0,
        9,
        33,
        17,
        39,
        27,
        49,
        49,
        26,
        11,
        30,
        42,
        29,
        45,
        2,
        49,
        0,
        3,
        49,
        37,
        49,
        0,
        40
      ],
      "weeklyHold": [
        35,
        12,
        49,
        1,
        5,
        18,
        30,
        49,
        6,
        2,
        42,
        49,
        30,
        12,
        49,
        18,
        0,
        48,
        28,
        14,
        4,
        27,
        31,
        17,
        47,
        39,
        14,
        18,
        35,
        0,
        48,
        12,
        23,
        33,
        47,
        34,
        2,
        0,
        36,
        44,
        0,
        35,
        42,
        40,
        10,
        23,
        16,
        39,
        38,
        34,
        38,
        0
      ],
      "weeklyBackordered": [
        49,
        49,
        14,
        0,
        49,
        46,
        49,
        13,
        23,
        6,
        6,
        47,
        35,
        2,
        41,
        48,
        27,
        37,
        16,
        9,
        20,
        5,
        29,
        26,
        3,
        19,
        1,
        33,
        40,
        49,
        22,
        27,
        6,
        45,
        27,
        11,
        43,
        0,
        10,
        2,
        14,
        48,
        27,
        29,
        30,
        38,
        49,
        0,
        38,
        49,
        39,
        21
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        30,
        1,
        23,
        37,
        45,
        9,
        17,
        49,
        48,
        11,
        49,
        12,
        49,
        37,
        47,
        38,
        47,
        0,
        35,
        14,
        45,
        0,
        49,
        38,
        12,
        39,
        0,
        7,
        1,
        49,
        14,
        2,
        37,
        0,
        45,
        12,
        47,
        7,
        33,
        49,
        0,
        12,
        49,
        26,
        48,
        5,
        2,
        30,
        8,
        49,
        5,
        17
      ],
      "weeklyRejected": [
        49,
        9,
        49,
        37,
        27,
        42,
        21,
        42,
        38,
        8,
        33,
        49,
        11,
        31,
        13,
        28,
        10,
        49,
        31,
        34,
        2,
        7,
        49,
        48,
        25,
        4,
        49,
        8,
        16,
        19,
        0,
        20,
        20,
        49,
        25,
        49,
        0,
        18,
        10,
        24,
        29,
        0,
        35,
        7,
        43,
        38,
        0,
        12,
        0,
        26,
        30,
        0
      ],
      "weeklyHold": [
        4,
        48,
        45,
        18,
        23,
        43,
        29,
        24,
        45,
        14,
        47,
        43,
        27,
        27,
        49,
        0,
        45,
        28,
        37,
        3,
        15,
        0,
        0,
        27,
        7,
        37,
        5,
        4,
        28,
        7,
        48,
        25,
        16,
        6,
        48,
        11,
        27,
        0,
        1,
        49,
        20,
        32,
        7,
        49,
        24,
        10,
        1,
        49,
        14,
        22,
        40,
        19
      ],
      "weeklyBackordered": [
        49,
        17,
        20,
        21,
        49,
        46,
        49,
        1,
        39,
        0,
        11,
        49,
        3,
        30,
        24,
        49,
        40,
        41,
        45,
        46,
        33,
        7,
        27,
        29,
        4,
        31,
        19,
        11,
        25,
        49,
        49,
        20,
        29,
        27,
        8,
        0,
        32,
        0,
        49,
        27,
        16,
        48,
        8,
        49,
        49,
        38,
        49,
        35,
        0,
        49,
        4,
        5
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        28,
        32,
        34,
        40,
        49,
        9,
        18,
        49,
        48,
        17,
        35,
        23,
        20,
        49,
        29,
        12,
        26,
        10,
        5,
        0,
        26,
        31,
        27,
        21,
        42,
        0,
        41,
        21,
        10,
        9,
        19,
        30,
        49,
        0,
        26,
        18,
        47,
        23,
        18,
        49,
        35,
        9,
        44,
        49,
        49,
        26,
        10,
        49,
        49,
        33,
        0,
        1
      ],
      "weeklyRejected": [
        41,
        0,
        36,
        47,
        20,
        3,
        49,
        28,
        49,
        11,
        29,
        17,
        49,
        18,
        19,
        16,
        11,
        21,
        2,
        1,
        31,
        48,
        22,
        25,
        0,
        37,
        38,
        25,
        42,
        0,
        49,
        12,
        26,
        26,
        43,
        44,
        29,
        5,
        1,
        25,
        19,
        9,
        43,
        0,
        49,
        4,
        34,
        13,
        6,
        38,
        21,
        31
      ],
      "weeklyHold": [
        8,
        25,
        36,
        1,
        18,
        13,
        11,
        12,
        35,
        27,
        31,
        49,
        48,
        27,
        3,
        0,
        0,
        29,
        0,
        0,
        21,
        49,
        32,
        9,
        44,
        28,
        38,
        0,
        24,
        29,
        43,
        0,
        18,
        44,
        11,
        38,
        24,
        0,
        16,
        23,
        0,
        32,
        33,
        37,
        26,
        14,
        14,
        49,
        0,
        49,
        28,
        9
      ],
      "weeklyBackordered": [
        0,
        0,
        38,
        49,
        43,
        24,
        32,
        22,
        40,
        1,
        35,
        41,
        29,
        11,
        0,
        36,
        4,
        49,
        23,
        0,
        44,
        24,
        20,
        32,
        24,
        42,
        30,
        38,
        48,
        47,
        38,
        13,
        38,
        20,
        0,
        0,
        49,
        8,
        13,
        37,
        11,
        48,
        31,
        49,
        41,
        27,
        40,
        0,
        30,
        49,
        43,
        31
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyAccepted": [
        6,
        35,
        36,
        48,
        33,
        27,
        1,
        29,
        7,
        42,
        43,
        27,
        8,
        34,
        10,
        17,
        20,
        11,
        0,
        34,
        36,
        23,
        37,
        0,
        12,
        34,
        0,
        36,
        9,
        25,
        14,
        17,
        49,
        48,
        48,
        31,
        23,
        7,
        2,
        49,
        6,
        10,
        49,
        49,
        49,
        0,
        11,
        49,
        26,
        21,
        6,
        0
      ],
      "weeklyRejected": [
        3,
        25,
        40,
        25,
        22,
        21,
        46,
        24,
        49,
        21,
        35,
        20,
        0,
        45,
        44,
        26,
        11,
        28,
        6,
        0,
        20,
        28,
        29,
        26,
        4,
        49,
        49,
        31,
        10,
        15,
        43,
        8,
        28,
        47,
        0,
        37,
        5,
        1,
        27,
        49,
        38,
        48,
        37,
        0,
        49,
        44,
        9,
        32,
        0,
        37,
        24,
        49
      ],
      "weeklyHold": [
        10,
        31,
        23,
        1,
        44,
        22,
        49,
        7,
        33,
        0,
        32,
        45,
        39,
        22,
        32,
        0,
        5,
        24,
        0,
        0,
        26,
        48,
        8,
        13,
        48,
        32,
        11,
        44,
        48,
        0,
        20,
        25,
        24,
        17,
        40,
        28,
        49,
        17,
        18,
        27,
        27,
        31,
        31,
        27,
        17,
        45,
        2,
        49,
        0,
        49,
        4,
        19
      ],
      "weeklyBackordered": [
        0,
        47,
        22,
        23,
        39,
        46,
        20,
        40,
        4,
        27,
        47,
        9,
        3,
        41,
        8,
        34,
        28,
        49,
        12,
        31,
        49,
        26,
        14,
        49,
        40,
        13,
        16,
        22,
        20,
        35,
        47,
        41,
        32,
        36,
        0,
        10,
        13,
        0,
        5,
        39,
        32,
        46,
        46,
        27,
        12,
        49,
        38,
        35,
        25,
        49,
        21,
        10
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        16,
        30,
        24,
        33,
        49,
        26,
        20,
        49,
        39,
        33,
        22,
        0,
        36,
        10,
        25,
        25,
        43,
        24,
        0,
        6,
        34,
        22,
        22,
        38,
        11,
        26,
        0,
        36,
        10,
        49,
        0,
        19,
        22,
        0,
        48,
        2,
        32,
        6,
        28,
        49,
        21,
        21,
        49,
        9,
        49,
        31,
        15,
        13,
        38,
        49,
        0,
        48
      ],
      "weeklyRejected": [
        34,
        21,
        33,
        28,
        33,
        21,
        23,
        47,
        49,
        21,
        7,
        19,
        31,
        48,
        48,
        3,
        20,
        5,
        43,
        28,
        11,
        20,
        0,
        27,
        29,
        49,
        31,
        3,
        48,
        8,
        27,
        6,
        43,
        49,
        0,
        33,
        27,
        45,
        1,
        16,
        47,
        11,
        49,
        10,
        34,
        39,
        21,
        43,
        13,
        49,
        0,
        49
      ],
      "weeklyHold": [
        38,
        13,
        7,
        14,
        44,
        25,
        0,
        20,
        0,
        25,
        40,
        19,
        18,
        46,
        14,
        0,
        16,
        20,
        49,
        21,
        30,
        15,
        0,
        26,
        39,
        35,
        23,
        14,
        22,
        0,
        48,
        20,
        0,
        31,
        27,
        0,
        7,
        0,
        29,
        19,
        19,
        35,
        15,
        5,
        49,
        47,
        49,
        49,
        0,
        5,
        6,
        27
      ],
      "weeklyBackordered": [
        33,
        49,
        26,
        45,
        49,
        46,
        0,
        18,
        49,
        0,
        9,
        49,
        4,
        0,
        0,
        29,
        49,
        35,
        4,
        4,
        49,
        33,
        40,
        16,
        37,
        21,
        49,
        0,
        12,
        39,
        38,
        49,
        5,
        42,
        26,
        25,
        36,
        32,
        24,
        6,
        40,
        48,
        33,
        49,
        26,
        24,
        49,
        49,
        34,
        16,
        16,
        12
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        0,
        49,
        25,
        25,
        8,
        0,
        16,
        33,
        48,
        36,
        49,
        12,
        20,
        3,
        32,
        48,
        42,
        46,
        3,
        14,
        22,
        7,
        36,
        49,
        0,
        42,
        26,
        16,
        41,
        1,
        9,
        0,
        49,
        4,
        40,
        27,
        10,
        41,
        0,
        41,
        1,
        40,
        49,
        28,
        37,
        19,
        15,
        0,
        0,
        24,
        0,
        43
      ],
      "weeklyRejected": [
        37,
        35,
        49,
        4,
        0,
        0,
        49,
        49,
        28,
        29,
        23,
        44,
        14,
        19,
        28,
        32,
        18,
        49,
        49,
        49,
        17,
        0,
        11,
        18,
        49,
        46,
        49,
        27,
        45,
        18,
        45,
        20,
        0,
        18,
        37,
        49,
        12,
        0,
        27,
        44,
        26,
        17,
        49,
        27,
        49,
        49,
        29,
        47,
        0,
        30,
        6,
        49
      ],
      "weeklyHold": [
        26,
        43,
        37,
        1,
        35,
        26,
        49,
        5,
        2,
        31,
        47,
        16,
        28,
        30,
        49,
        18,
        15,
        48,
        0,
        33,
        41,
        26,
        18,
        38,
        0,
        48,
        28,
        26,
        0,
        22,
        28,
        20,
        20,
        35,
        15,
        4,
        49,
        27,
        23,
        48,
        22,
        23,
        20,
        34,
        30,
        28,
        39,
        27,
        0,
        8,
        48,
        5
      ],
      "weeklyBackordered": [
        27,
        14,
        23,
        49,
        46,
        38,
        0,
        18,
        40,
        7,
        48,
        37,
        3,
        48,
        49,
        20,
        49,
        35,
        20,
        37,
        43,
        49,
        42,
        3,
        6,
        17,
        6,
        5,
        25,
        49,
        49,
        9,
        48,
        49,
        40,
        0,
        1,
        9,
        0,
        0,
        34,
        27,
        0,
        49,
        32,
        0,
        44,
        20,
        0,
        30,
        44,
        10
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        49,
        49,
        45,
        0,
        20,
        0,
        1,
        2,
        7,
        25,
        48,
        0,
        49,
        12,
        7,
        49,
        49,
        25,
        0,
        5,
        1,
        25,
        49,
        45,
        36,
        40,
        45,
        25,
        42,
        23,
        14,
        27,
        49,
        49,
        17,
        16,
        47,
        48,
        49,
        49,
        43,
        49,
        49,
        38,
        49,
        0,
        5,
        49,
        10,
        38,
        4,
        12
      ],
      "weeklyRejected": [
        49,
        43,
        18,
        12,
        18,
        0,
        16,
        42,
        17,
        31,
        1,
        23,
        20,
        40,
        48,
        33,
        19,
        49,
        1,
        10,
        46,
        27,
        11,
        35,
        27,
        49,
        31,
        0,
        43,
        1,
        42,
        19,
        48,
        13,
        0,
        49,
        18,
        7,
        28,
        44,
        35,
        18,
        49,
        0,
        48,
        7,
        6,
        16,
        6,
        43,
        14,
        42
      ],
      "weeklyHold": [
        21,
        6,
        40,
        1,
        6,
        21,
        16,
        17,
        18,
        16,
        15,
        49,
        48,
        37,
        49,
        21,
        0,
        1,
        0,
        10,
        7,
        40,
        28,
        27,
        35,
        14,
        49,
        29,
        38,
        0,
        41,
        14,
        17,
        15,
        42,
        21,
        17,
        49,
        41,
        36,
        36,
        15,
        20,
        49,
        27,
        14,
        8,
        16,
        0,
        49,
        14,
        17
      ],
      "weeklyBackordered": [
        25,
        48,
        26,
        41,
        40,
        2,
        24,
        10,
        9,
        9,
        26,
        0,
        3,
        49,
        29,
        38,
        16,
        49,
        25,
        49,
        28,
        0,
        9,
        0,
        48,
        1,
        24,
        48,
        10,
        2,
        6,
        13,
        5,
        42,
        0,
        0,
        1,
        48,
        32,
        10,
        0,
        48,
        3,
        49,
        26,
        35,
        33,
        8,
        24,
        14,
        38,
        37
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        3,
        44,
        8,
        6,
        49,
        20,
        13,
        21,
        43,
        19,
        29,
        0,
        14,
        16,
        15,
        31,
        49,
        0,
        9,
        7,
        49,
        10,
        11,
        1,
        34,
        30,
        28,
        20,
        18,
        49,
        0,
        31,
        49,
        32,
        38,
        40,
        12,
        40,
        42,
        49,
        18,
        49,
        49,
        49,
        49,
        0,
        28,
        41,
        35,
        22,
        47,
        46
      ],
      "weeklyRejected": [
        49,
        22,
        33,
        5,
        11,
        49,
        28,
        38,
        46,
        22,
        32,
        5,
        0,
        40,
        48,
        4,
        26,
        49,
        49,
        21,
        26,
        37,
        49,
        28,
        11,
        49,
        49,
        24,
        24,
        48,
        49,
        44,
        24,
        25,
        23,
        49,
        31,
        34,
        12,
        31,
        23,
        13,
        1,
        4,
        24,
        48,
        0,
        0,
        2,
        49,
        0,
        28
      ],
      "weeklyHold": [
        13,
        10,
        21,
        1,
        23,
        27,
        43,
        37,
        6,
        49,
        35,
        42,
        31,
        49,
        28,
        24,
        0,
        35,
        36,
        15,
        28,
        1,
        34,
        11,
        16,
        44,
        36,
        39,
        37,
        49,
        34,
        27,
        0,
        16,
        8,
        42,
        38,
        36,
        10,
        20,
        39,
        49,
        0,
        6,
        17,
        25,
        46,
        38,
        4,
        6,
        35,
        43
      ],
      "weeklyBackordered": [
        0,
        0,
        27,
        26,
        45,
        10,
        0,
        49,
        22,
        0,
        49,
        44,
        9,
        40,
        49,
        10,
        49,
        18,
        0,
        8,
        49,
        11,
        7,
        20,
        8,
        49,
        1,
        38,
        10,
        32,
        19,
        40,
        48,
        30,
        33,
        1,
        28,
        0,
        22,
        24,
        28,
        48,
        16,
        49,
        23,
        49,
        19,
        2,
        29,
        27,
        45,
        17
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        10,
        47,
        49,
        44,
        14,
        29,
        10,
        17,
        48,
        8,
        15,
        0,
        39,
        0,
        10,
        20,
        49,
        0,
        0,
        49,
        23,
        34,
        36,
        21,
        27,
        20,
        25,
        19,
        34,
        36,
        0,
        10,
        49,
        23,
        48,
        13,
        13,
        31,
        49,
        26,
        16,
        0,
        45,
        21,
        49,
        20,
        0,
        0,
        12,
        49,
        8,
        15
      ],
      "weeklyRejected": [
        0,
        48,
        10,
        35,
        15,
        11,
        34,
        32,
        28,
        11,
        49,
        44,
        15,
        0,
        48,
        25,
        28,
        46,
        35,
        48,
        49,
        0,
        26,
        20,
        49,
        19,
        0,
        24,
        48,
        29,
        28,
        31,
        28,
        34,
        18,
        49,
        9,
        30,
        5,
        32,
        48,
        49,
        29,
        21,
        45,
        25,
        0,
        44,
        0,
        49,
        23,
        49
      ],
      "weeklyHold": [
        49,
        15,
        49,
        23,
        33,
        45,
        42,
        2,
        0,
        12,
        43,
        19,
        22,
        1,
        49,
        42,
        0,
        8,
        25,
        32,
        44,
        37,
        49,
        7,
        38,
        1,
        31,
        11,
        7,
        20,
        48,
        0,
        6,
        35,
        33,
        25,
        29,
        0,
        1,
        7,
        7,
        49,
        49,
        49,
        39,
        49,
        13,
        13,
        21,
        0,
        41,
        18
      ],
      "weeklyBackordered": [
        0,
        25,
        0,
        8,
        39,
        9,
        47,
        45,
        27,
        19,
        3,
        49,
        4,
        43,
        36,
        22,
        49,
        3,
        15,
        22,
        38,
        0,
        0,
        0,
        40,
        21,
        1,
        46,
        35,
        49,
        27,
        11,
        32,
        49,
        3,
        0,
        1,
        18,
        41,
        14,
        30,
        44,
        0,
        37,
        15,
        0,
        46,
        12,
        8,
        38,
        30,
        1
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        0,
        21,
        34,
        32,
        35,
        0,
        28,
        34,
        28,
        6,
        25,
        28,
        23,
        24,
        10,
        25,
        36,
        42,
        20,
        0,
        42,
        49,
        18,
        2,
        30,
        44,
        0,
        5,
        48,
        49,
        35,
        10,
        49,
        25,
        48,
        29,
        7,
        48,
        45,
        48,
        25,
        43,
        47,
        49,
        49,
        22,
        13,
        26,
        0,
        30,
        48,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        15,
        40,
        47,
        14,
        47,
        9,
        49,
        15,
        45,
        26,
        39,
        40,
        48,
        9,
        10,
        42,
        42,
        3,
        18,
        44,
        15,
        13,
        15,
        49,
        49,
        1,
        0,
        16,
        23,
        18,
        42,
        13,
        36,
        49,
        49,
        49,
        3,
        5,
        43,
        33,
        40,
        22,
        33,
        14,
        27,
        48,
        12,
        29,
        0,
        49
      ],
      "weeklyHold": [
        0,
        0,
        22,
        33,
        43,
        41,
        2,
        30,
        14,
        4,
        25,
        11,
        25,
        8,
        10,
        0,
        18,
        0,
        46,
        9,
        0,
        28,
        11,
        28,
        39,
        26,
        49,
        0,
        34,
        0,
        48,
        7,
        10,
        49,
        0,
        42,
        14,
        26,
        38,
        0,
        0,
        28,
        0,
        2,
        30,
        25,
        39,
        3,
        0,
        34,
        18,
        14
      ],
      "weeklyBackordered": [
        32,
        49,
        13,
        49,
        29,
        37,
        11,
        42,
        44,
        40,
        17,
        49,
        47,
        19,
        34,
        20,
        49,
        48,
        14,
        26,
        20,
        8,
        0,
        49,
        48,
        18,
        49,
        20,
        1,
        6,
        15,
        19,
        18,
        44,
        9,
        13,
        1,
        27,
        17,
        37,
        48,
        41,
        18,
        32,
        30,
        14,
        48,
        3,
        0,
        49,
        27,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        6,
        40,
        49,
        3,
        5,
        0,
        7,
        18,
        42,
        21,
        29,
        8,
        49,
        7,
        2,
        0,
        49,
        0,
        10,
        37,
        24,
        0,
        49,
        40,
        22,
        22,
        22,
        43,
        43,
        23,
        15,
        0,
        49,
        37,
        40,
        28,
        30,
        26,
        49,
        49,
        1,
        18,
        35,
        49,
        25,
        28,
        0,
        27,
        19,
        17,
        46,
        48
      ],
      "weeklyRejected": [
        35,
        0,
        27,
        3,
        35,
        0,
        49,
        49,
        22,
        20,
        29,
        29,
        8,
        48,
        2,
        22,
        24,
        15,
        8,
        30,
        19,
        6,
        19,
        49,
        37,
        49,
        26,
        27,
        23,
        24,
        28,
        30,
        48,
        25,
        36,
        49,
        32,
        27,
        3,
        0,
        45,
        42,
        40,
        9,
        40,
        32,
        29,
        49,
        15,
        13,
        20,
        22
      ],
      "weeklyHold": [
        26,
        26,
        31,
        1,
        6,
        36,
        1,
        35,
        21,
        12,
        31,
        15,
        9,
        24,
        20,
        0,
        15,
        0,
        24,
        8,
        19,
        17,
        4,
        22,
        3,
        35,
        32,
        0,
        49,
        39,
        36,
        16,
        0,
        23,
        42,
        28,
        29,
        10,
        49,
        0,
        21,
        5,
        22,
        41,
        12,
        30,
        45,
        26,
        1,
        0,
        7,
        36
      ],
      "weeklyBackordered": [
        14,
        46,
        43,
        37,
        39,
        11,
        22,
        49,
        40,
        0,
        2,
        49,
        12,
        37,
        12,
        19,
        7,
        46,
        17,
        0,
        2,
        0,
        5,
        49,
        11,
        4,
        44,
        34,
        38,
        26,
        2,
        21,
        37,
        44,
        0,
        0,
        8,
        25,
        19,
        6,
        46,
        48,
        19,
        49,
        18,
        26,
        49,
        0,
        0,
        0,
        29,
        3
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyAccepted": [
        16,
        28,
        23,
        24,
        22,
        8,
        49,
        49,
        42,
        0,
        5,
        21,
        5,
        11,
        49,
        17,
        49,
        0,
        1,
        5,
        49,
        22,
        24,
        12,
        17,
        47,
        0,
        24,
        1,
        46,
        46,
        20,
        33,
        27,
        38,
        2,
        39,
        48,
        16,
        49,
        21,
        47,
        49,
        34,
        43,
        0,
        0,
        28,
        7,
        19,
        43,
        29
      ],
      "weeklyRejected": [
        49,
        12,
        41,
        15,
        37,
        47,
        0,
        49,
        49,
        32,
        16,
        10,
        49,
        17,
        44,
        24,
        15,
        44,
        40,
        7,
        35,
        19,
        6,
        49,
        49,
        43,
        47,
        21,
        48,
        43,
        43,
        31,
        48,
        49,
        19,
        49,
        41,
        32,
        1,
        0,
        46,
        33,
        47,
        5,
        44,
        0,
        44,
        37,
        0,
        23,
        0,
        32
      ],
      "weeklyHold": [
        27,
        9,
        25,
        1,
        4,
        48,
        0,
        21,
        24,
        9,
        40,
        35,
        1,
        32,
        11,
        31,
        8,
        21,
        0,
        24,
        16,
        19,
        21,
        42,
        0,
        42,
        40,
        10,
        44,
        17,
        48,
        14,
        9,
        48,
        48,
        25,
        11,
        0,
        49,
        49,
        48,
        14,
        10,
        49,
        20,
        13,
        5,
        30,
        10,
        16,
        0,
        26
      ],
      "weeklyBackordered": [
        0,
        35,
        6,
        38,
        14,
        28,
        49,
        3,
        14,
        31,
        13,
        38,
        21,
        16,
        33,
        42,
        1,
        26,
        0,
        49,
        31,
        0,
        15,
        49,
        7,
        1,
        49,
        16,
        6,
        49,
        20,
        33,
        8,
        49,
        0,
        0,
        30,
        7,
        11,
        25,
        0,
        20,
        0,
        33,
        33,
        0,
        43,
        40,
        0,
        49,
        24,
        25
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        45,
        38,
        46,
        8,
        16,
        14,
        1,
        32,
        4,
        23,
        46,
        7,
        34,
        11,
        1,
        32,
        49,
        1,
        0,
        19,
        49,
        30,
        0,
        27,
        25,
        31,
        26,
        46,
        28,
        35,
        49,
        0,
        29,
        10,
        32,
        45,
        28,
        48,
        41,
        43,
        10,
        33,
        43,
        3,
        33,
        0,
        0,
        49,
        49,
        49,
        25,
        14
      ],
      "weeklyRejected": [
        41,
        0,
        18,
        46,
        14,
        17,
        49,
        13,
        32,
        24,
        39,
        15,
        35,
        33,
        48,
        32,
        49,
        27,
        9,
        35,
        14,
        0,
        4,
        46,
        18,
        20,
        49,
        15,
        44,
        0,
        31,
        37,
        48,
        49,
        0,
        49,
        2,
        23,
        18,
        18,
        48,
        34,
        40,
        43,
        49,
        19,
        44,
        49,
        0,
        33,
        0,
        31
      ],
      "weeklyHold": [
        0,
        32,
        42,
        1,
        38,
        46,
        31,
        14,
        25,
        16,
        37,
        49,
        30,
        49,
        22,
        17,
        0,
        1,
        28,
        49,
        31,
        49,
        20,
        0,
        49,
        48,
        40,
        10,
        18,
        18,
        39,
        38,
        0,
        9,
        46,
        13,
        0,
        16,
        18,
        19,
        49,
        49,
        18,
        0,
        45,
        21,
        40,
        42,
        0,
        37,
        49,
        35
      ],
      "weeklyBackordered": [
        33,
        49,
        0,
        44,
        42,
        24,
        40,
        26,
        0,
        0,
        49,
        43,
        49,
        38,
        44,
        6,
        49,
        38,
        0,
        38,
        14,
        47,
        9,
        8,
        28,
        1,
        6,
        28,
        15,
        32,
        1,
        28,
        45,
        0,
        22,
        0,
        1,
        42,
        20,
        45,
        48,
        48,
        7,
        49,
        0,
        25,
        26,
        7,
        0,
        44,
        49,
        26
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        35,
        33,
        36,
        43,
        8,
        13,
        37,
        49,
        27,
        29,
        46,
        17,
        42,
        9,
        25,
        3,
        25,
        33,
        2,
        28,
        0,
        9,
        25,
        35,
        23,
        46,
        34,
        20,
        7,
        49,
        43,
        8,
        37,
        37,
        38,
        21,
        40,
        4,
        28,
        49,
        0,
        10,
        49,
        10,
        49,
        37,
        0,
        4,
        35,
        49,
        32,
        48
      ],
      "weeklyRejected": [
        19,
        49,
        49,
        36,
        44,
        23,
        26,
        41,
        36,
        24,
        46,
        28,
        25,
        48,
        14,
        18,
        7,
        16,
        2,
        10,
        36,
        0,
        0,
        21,
        20,
        29,
        25,
        20,
        35,
        0,
        17,
        35,
        48,
        48,
        13,
        49,
        15,
        24,
        1,
        0,
        41,
        23,
        19,
        14,
        32,
        20,
        0,
        49,
        25,
        6,
        8,
        49
      ],
      "weeklyHold": [
        12,
        12,
        9,
        16,
        33,
        23,
        48,
        49,
        5,
        31,
        33,
        37,
        30,
        18,
        22,
        18,
        23,
        2,
        26,
        11,
        0,
        38,
        1,
        5,
        49,
        16,
        35,
        8,
        36,
        14,
        17,
        18,
        10,
        26,
        40,
        10,
        1,
        0,
        24,
        26,
        0,
        42,
        16,
        1,
        32,
        49,
        48,
        44,
        7,
        37,
        49,
        25
      ],
      "weeklyBackordered": [
        18,
        30,
        6,
        30,
        49,
        46,
        0,
        49,
        23,
        0,
        22,
        22,
        13,
        49,
        32,
        33,
        12,
        24,
        0,
        3,
        16,
        35,
        27,
        16,
        18,
        12,
        18,
        49,
        1,
        49,
        22,
        26,
        23,
        37,
        3,
        4,
        46,
        0,
        7,
        49,
        33,
        43,
        17,
        49,
        36,
        0,
        49,
        49,
        49,
        48,
        32,
        35
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        23,
        46,
        15,
        20,
        32,
        0,
        26,
        14,
        3,
        49,
        18,
        10,
        39,
        15,
        49,
        12,
        49,
        9,
        0,
        0,
        4,
        20,
        27,
        43,
        31,
        47,
        0,
        49,
        12,
        29,
        26,
        24,
        49,
        38,
        31,
        49,
        30,
        48,
        21,
        49,
        27,
        41,
        37,
        38,
        49,
        28,
        0,
        10,
        46,
        49,
        37,
        42
      ],
      "weeklyRejected": [
        39,
        15,
        8,
        41,
        10,
        24,
        0,
        36,
        30,
        1,
        27,
        0,
        18,
        48,
        40,
        0,
        46,
        49,
        49,
        15,
        14,
        25,
        0,
        21,
        14,
        49,
        2,
        16,
        48,
        35,
        24,
        31,
        36,
        49,
        0,
        49,
        49,
        39,
        1,
        29,
        34,
        28,
        32,
        29,
        49,
        13,
        17,
        24,
        0,
        47,
        0,
        41
      ],
      "weeklyHold": [
        49,
        38,
        47,
        1,
        41,
        0,
        22,
        10,
        0,
        16,
        24,
        3,
        23,
        37,
        39,
        49,
        49,
        48,
        41,
        0,
        10,
        0,
        13,
        41,
        16,
        48,
        3,
        15,
        49,
        0,
        43,
        15,
        0,
        28,
        4,
        45,
        49,
        34,
        15,
        0,
        35,
        8,
        0,
        27,
        49,
        9,
        18,
        21,
        7,
        13,
        37,
        12
      ],
      "weeklyBackordered": [
        8,
        23,
        21,
        5,
        49,
        38,
        1,
        36,
        28,
        17,
        41,
        49,
        19,
        19,
        12,
        21,
        11,
        49,
        10,
        14,
        34,
        26,
        19,
        48,
        19,
        24,
        29,
        36,
        28,
        49,
        26,
        35,
        48,
        33,
        0,
        0,
        16,
        29,
        8,
        13,
        28,
        48,
        13,
        49,
        30,
        21,
        0,
        2,
        0,
        17,
        28,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        0,
        44,
        18,
        19,
        38,
        21,
        33,
        35,
        44,
        27,
        38,
        0,
        49,
        49,
        15,
        0,
        46,
        41,
        21,
        29,
        14,
        46,
        32,
        36,
        32,
        41,
        5,
        28,
        1,
        11,
        33,
        20,
        21,
        0,
        42,
        39,
        22,
        46,
        42,
        24,
        0,
        16,
        49,
        7,
        49,
        19,
        10,
        14,
        14,
        49,
        49,
        30
      ],
      "weeklyRejected": [
        28,
        49,
        49,
        47,
        24,
        0,
        0,
        49,
        40,
        46,
        49,
        49,
        32,
        48,
        34,
        43,
        29,
        35,
        20,
        9,
        7,
        24,
        43,
        7,
        49,
        33,
        6,
        16,
        48,
        33,
        23,
        33,
        48,
        49,
        0,
        49,
        42,
        45,
        1,
        21,
        48,
        27,
        16,
        5,
        49,
        45,
        49,
        34,
        0,
        34,
        43,
        48
      ],
      "weeklyHold": [
        49,
        40,
        12,
        1,
        25,
        3,
        47,
        22,
        49,
        49,
        47,
        19,
        35,
        8,
        43,
        0,
        1,
        48,
        27,
        46,
        42,
        32,
        24,
        0,
        0,
        12,
        29,
        43,
        26,
        15,
        13,
        26,
        14,
        17,
        0,
        28,
        24,
        31,
        29,
        32,
        34,
        49,
        0,
        49,
        45,
        45,
        4,
        0,
        1,
        40,
        47,
        24
      ],
      "weeklyBackordered": [
        38,
        49,
        40,
        37,
        46,
        29,
        44,
        48,
        13,
        0,
        37,
        40,
        35,
        0,
        0,
        26,
        49,
        49,
        0,
        39,
        34,
        15,
        0,
        17,
        32,
        49,
        18,
        12,
        8,
        49,
        38,
        35,
        48,
        0,
        30,
        0,
        30,
        7,
        0,
        3,
        33,
        48,
        1,
        29,
        0,
        49,
        49,
        21,
        5,
        49,
        21,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        28,
        13,
        24,
        30,
        47,
        22,
        19,
        47,
        25,
        7,
        49,
        6,
        0,
        13,
        19,
        0,
        46,
        14,
        23,
        0,
        35,
        6,
        32,
        23,
        0,
        47,
        49,
        33,
        17,
        28,
        13,
        28,
        49,
        34,
        48,
        34,
        47,
        6,
        49,
        30,
        49,
        49,
        37,
        49,
        22,
        0,
        30,
        37,
        49,
        43,
        2,
        45
      ],
      "weeklyRejected": [
        45,
        0,
        19,
        31,
        0,
        18,
        44,
        49,
        33,
        1,
        28,
        6,
        0,
        11,
        24,
        36,
        2,
        38,
        49,
        13,
        42,
        46,
        0,
        32,
        13,
        25,
        39,
        16,
        37,
        26,
        18,
        33,
        18,
        24,
        23,
        49,
        24,
        3,
        9,
        8,
        35,
        27,
        12,
        23,
        49,
        20,
        18,
        1,
        18,
        49,
        1,
        33
      ],
      "weeklyHold": [
        49,
        8,
        37,
        26,
        33,
        48,
        6,
        30,
        23,
        49,
        10,
        23,
        15,
        15,
        41,
        21,
        20,
        23,
        32,
        7,
        36,
        38,
        10,
        23,
        16,
        37,
        36,
        0,
        49,
        39,
        48,
        47,
        0,
        25,
        9,
        49,
        20,
        22,
        4,
        49,
        23,
        17,
        28,
        19,
        49,
        17,
        16,
        37,
        0,
        5,
        42,
        18
      ],
      "weeklyBackordered": [
        0,
        11,
        36,
        35,
        49,
        31,
        26,
        40,
        8,
        40,
        20,
        16,
        25,
        7,
        37,
        33,
        15,
        49,
        25,
        31,
        49,
        23,
        11,
        27,
        37,
        16,
        8,
        15,
        36,
        26,
        23,
        49,
        24,
        28,
        11,
        0,
        13,
        7,
        20,
        19,
        22,
        48,
        29,
        33,
        17,
        49,
        34,
        0,
        6,
        26,
        49,
        12
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyAccepted": [
        6,
        44,
        15,
        43,
        32,
        0,
        1,
        46,
        31,
        43,
        2,
        32,
        22,
        9,
        10,
        0,
        49,
        38,
        15,
        4,
        11,
        49,
        35,
        49,
        26,
        22,
        42,
        0,
        13,
        49,
        32,
        32,
        12,
        34,
        23,
        46,
        44,
        42,
        25,
        12,
        23,
        4,
        49,
        16,
        18,
        42,
        5,
        22,
        16,
        26,
        24,
        22
      ],
      "weeklyRejected": [
        23,
        34,
        25,
        27,
        16,
        6,
        47,
        0,
        49,
        16,
        28,
        17,
        21,
        48,
        47,
        18,
        22,
        4,
        12,
        1,
        6,
        49,
        19,
        26,
        2,
        37,
        42,
        42,
        18,
        21,
        5,
        25,
        16,
        5,
        14,
        49,
        0,
        12,
        1,
        46,
        48,
        41,
        49,
        33,
        33,
        23,
        0,
        2,
        0,
        49,
        11,
        28
      ],
      "weeklyHold": [
        21,
        46,
        41,
        1,
        14,
        48,
        43,
        2,
        24,
        49,
        44,
        39,
        31,
        33,
        26,
        0,
        13,
        31,
        14,
        8,
        2,
        49,
        0,
        18,
        45,
        25,
        22,
        5,
        49,
        0,
        36,
        48,
        15,
        32,
        22,
        16,
        48,
        0,
        24,
        37,
        33,
        31,
        0,
        0,
        20,
        33,
        31,
        44,
        35,
        30,
        39,
        36
      ],
      "weeklyBackordered": [
        34,
        38,
        12,
        43,
        39,
        42,
        0,
        4,
        49,
        24,
        40,
        1,
        8,
        35,
        33,
        14,
        28,
        0,
        0,
        14,
        44,
        39,
        35,
        49,
        11,
        32,
        4,
        18,
        1,
        49,
        17,
        47,
        19,
        49,
        5,
        41,
        24,
        40,
        2,
        46,
        45,
        15,
        0,
        47,
        30,
        26,
        49,
        36,
        0,
        37,
        49,
        35
      ]
    },
    {
      "id": "FB70040E",
      "weeklyAccepted": [
        0,
        15,
        28,
        40,
        5,
        5,
        36,
        10,
        35,
        27,
        28,
        0,
        5,
        33,
        10,
        17,
        49,
        8,
        27,
        6,
        20,
        46,
        11,
        49,
        0,
        25,
        48,
        20,
        49,
        49,
        0,
        31,
        49,
        22,
        48,
        26,
        21,
        19,
        23,
        36,
        13,
        8,
        18,
        33,
        39,
        31,
        30,
        0,
        5,
        0,
        32,
        24
      ],
      "weeklyRejected": [
        43,
        13,
        19,
        0,
        49,
        30,
        49,
        23,
        49,
        1,
        20,
        35,
        5,
        27,
        48,
        0,
        32,
        28,
        41,
        11,
        36,
        44,
        47,
        35,
        11,
        49,
        18,
        49,
        31,
        0,
        28,
        8,
        31,
        47,
        38,
        49,
        25,
        28,
        2,
        49,
        33,
        49,
        49,
        23,
        46,
        49,
        14,
        32,
        20,
        49,
        17,
        49
      ],
      "weeklyHold": [
        31,
        0,
        37,
        1,
        49,
        31,
        24,
        2,
        0,
        9,
        46,
        13,
        26,
        26,
        49,
        0,
        14,
        41,
        4,
        31,
        35,
        9,
        49,
        0,
        31,
        37,
        3,
        11,
        33,
        8,
        48,
        0,
        0,
        19,
        48,
        38,
        20,
        20,
        1,
        49,
        0,
        49,
        0,
        0,
        19,
        49,
        34,
        45,
        0,
        0,
        20,
        49
      ],
      "weeklyBackordered": [
        0,
        0,
        11,
        2,
        45,
        46,
        2,
        49,
        23,
        32,
        22,
        48,
        28,
        21,
        49,
        39,
        44,
        19,
        11,
        0,
        0,
        9,
        14,
        34,
        7,
        32,
        27,
        28,
        35,
        30,
        49,
        11,
        48,
        31,
        0,
        0,
        1,
        13,
        21,
        49,
        48,
        48,
        15,
        49,
        22,
        20,
        49,
        26,
        29,
        29,
        28,
        20
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        29,
        27,
        34,
        14,
        34,
        6,
        20,
        49,
        25,
        49,
        39,
        0,
        49,
        21,
        42,
        11,
        32,
        6,
        0,
        49,
        28,
        32,
        49,
        49,
        29,
        44,
        21,
        31,
        12,
        46,
        0,
        20,
        45,
        45,
        48,
        49,
        27,
        11,
        21,
        49,
        1,
        44,
        31,
        28,
        46,
        23,
        18,
        22,
        22,
        23,
        11,
        17
      ],
      "weeklyRejected": [
        45,
        22,
        2,
        47,
        40,
        0,
        9,
        16,
        34,
        6,
        25,
        18,
        42,
        10,
        9,
        4,
        28,
        49,
        49,
        16,
        18,
        49,
        26,
        49,
        39,
        45,
        21,
        18,
        39,
        6,
        42,
        47,
        32,
        37,
        19,
        49,
        37,
        19,
        1,
        19,
        37,
        30,
        49,
        2,
        39,
        40,
        3,
        30,
        0,
        0,
        2,
        30
      ],
      "weeklyHold": [
        41,
        6,
        29,
        17,
        49,
        12,
        49,
        18,
        34,
        45,
        39,
        31,
        1,
        16,
        35,
        0,
        8,
        48,
        22,
        46,
        32,
        35,
        49,
        24,
        0,
        48,
        14,
        12,
        49,
        27,
        48,
        0,
        47,
        2,
        48,
        36,
        11,
        41,
        47,
        48,
        47,
        43,
        49,
        4,
        30,
        44,
        4,
        42,
        0,
        32,
        32,
        41
      ],
      "weeklyBackordered": [
        48,
        49,
        16,
        34,
        38,
        46,
        43,
        40,
        46,
        22,
        0,
        49,
        49,
        41,
        0,
        22,
        25,
        49,
        40,
        11,
        39,
        37,
        23,
        36,
        38,
        1,
        18,
        5,
        22,
        18,
        25,
        49,
        24,
        49,
        2,
        7,
        19,
        26,
        21,
        4,
        35,
        48,
        0,
        49,
        9,
        49,
        49,
        16,
        0,
        35,
        32,
        8
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        0,
        27,
        31,
        26,
        44,
        0,
        5,
        24,
        48,
        12,
        17,
        2,
        21,
        49,
        45,
        25,
        36,
        30,
        0,
        0,
        48,
        0,
        33,
        20,
        0,
        13,
        14,
        31,
        17,
        1,
        9,
        0,
        43,
        46,
        10,
        34,
        0,
        0,
        49,
        49,
        14,
        43,
        24,
        49,
        49,
        19,
        4,
        22,
        23,
        49,
        33,
        10
      ],
      "weeklyRejected": [
        49,
        49,
        49,
        47,
        29,
        33,
        8,
        35,
        40,
        48,
        11,
        38,
        14,
        27,
        37,
        32,
        0,
        31,
        49,
        12,
        0,
        15,
        49,
        29,
        24,
        24,
        39,
        49,
        40,
        24,
        21,
        47,
        46,
        24,
        0,
        38,
        43,
        26,
        9,
        23,
        24,
        2,
        16,
        11,
        49,
        33,
        0,
        9,
        0,
        49,
        0,
        48
      ],
      "weeklyHold": [
        47,
        0,
        44,
        20,
        6,
        27,
        49,
        26,
        3,
        22,
        10,
        13,
        48,
        38,
        25,
        19,
        0,
        29,
        6,
        0,
        27,
        43,
        12,
        0,
        1,
        37,
        28,
        28,
        49,
        3,
        48,
        8,
        0,
        12,
        0,
        26,
        7,
        49,
        21,
        44,
        19,
        49,
        0,
        48,
        15,
        49,
        21,
        27,
        9,
        26,
        11,
        18
      ],
      "weeklyBackordered": [
        34,
        0,
        24,
        38,
        49,
        3,
        47,
        48,
        30,
        35,
        21,
        49,
        8,
        37,
        42,
        25,
        1,
        32,
        13,
        0,
        39,
        14,
        12,
        12,
        21,
        1,
        14,
        31,
        31,
        35,
        34,
        28,
        48,
        0,
        31,
        0,
        35,
        24,
        49,
        38,
        0,
        31,
        6,
        48,
        33,
        0,
        34,
        14,
        21,
        25,
        49,
        17
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        10,
        22,
        32,
        32,
        20,
        30,
        49,
        16,
        0,
        43,
        0,
        0,
        49,
        23,
        20,
        49,
        49,
        0,
        5,
        49,
        46,
        49,
        20,
        0,
        13,
        34,
        0,
        31,
        5,
        18,
        21,
        29,
        44,
        49,
        35,
        2,
        22,
        9,
        31,
        49,
        22,
        9,
        48,
        49,
        44,
        7,
        23,
        30,
        0,
        34,
        49,
        45
      ],
      "weeklyRejected": [
        26,
        0,
        12,
        24,
        32,
        6,
        49,
        42,
        43,
        6,
        26,
        45,
        48,
        47,
        48,
        19,
        0,
        49,
        49,
        16,
        0,
        25,
        41,
        46,
        34,
        30,
        11,
        45,
        13,
        30,
        15,
        47,
        48,
        12,
        25,
        45,
        49,
        17,
        4,
        10,
        40,
        38,
        49,
        42,
        43,
        22,
        0,
        26,
        21,
        19,
        0,
        6
      ],
      "weeklyHold": [
        0,
        0,
        45,
        1,
        49,
        28,
        47,
        28,
        32,
        26,
        47,
        29,
        8,
        4,
        31,
        26,
        13,
        34,
        30,
        19,
        26,
        13,
        30,
        49,
        20,
        46,
        43,
        0,
        49,
        27,
        48,
        0,
        2,
        49,
        12,
        29,
        10,
        0,
        25,
        36,
        0,
        49,
        29,
        0,
        24,
        29,
        37,
        37,
        3,
        24,
        23,
        25
      ],
      "weeklyBackordered": [
        38,
        34,
        25,
        21,
        33,
        31,
        45,
        37,
        43,
        31,
        13,
        49,
        25,
        49,
        38,
        15,
        35,
        21,
        18,
        25,
        49,
        11,
        0,
        49,
        31,
        23,
        1,
        16,
        20,
        5,
        34,
        49,
        26,
        49,
        0,
        5,
        18,
        27,
        35,
        9,
        27,
        48,
        22,
        49,
        17,
        45,
        0,
        43,
        19,
        36,
        35,
        10
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        17,
        17,
        21,
        15,
        30,
        4,
        1,
        30,
        9,
        35,
        44,
        6,
        49,
        49,
        7,
        23,
        29,
        0,
        32,
        32,
        0,
        0,
        4,
        42,
        24,
        13,
        0,
        21,
        28,
        43,
        40,
        0,
        29,
        15,
        41,
        9,
        47,
        7,
        22,
        29,
        25,
        13,
        17,
        35,
        49,
        17,
        0,
        21,
        0,
        49,
        49,
        0
      ],
      "weeklyRejected": [
        39,
        4,
        32,
        16,
        49,
        8,
        28,
        48,
        49,
        27,
        1,
        46,
        7,
        37,
        2,
        8,
        0,
        49,
        49,
        8,
        0,
        0,
        49,
        12,
        17,
        32,
        30,
        17,
        6,
        31,
        41,
        26,
        41,
        46,
        4,
        49,
        4,
        47,
        26,
        25,
        36,
        16,
        11,
        0,
        44,
        21,
        18,
        26,
        3,
        9,
        7,
        45
      ],
      "weeklyHold": [
        17,
        7,
        49,
        16,
        0,
        38,
        49,
        40,
        12,
        37,
        16,
        32,
        30,
        1,
        36,
        0,
        0,
        15,
        49,
        0,
        0,
        0,
        12,
        15,
        26,
        18,
        49,
        23,
        43,
        12,
        48,
        12,
        7,
        1,
        0,
        42,
        0,
        0,
        46,
        42,
        16,
        22,
        12,
        25,
        1,
        38,
        49,
        27,
        0,
        29,
        38,
        42
      ],
      "weeklyBackordered": [
        30,
        6,
        18,
        41,
        49,
        42,
        38,
        47,
        45,
        0,
        47,
        49,
        7,
        49,
        0,
        45,
        23,
        28,
        0,
        14,
        28,
        30,
        30,
        23,
        48,
        14,
        9,
        29,
        2,
        38,
        21,
        8,
        26,
        34,
        0,
        0,
        1,
        42,
        5,
        46,
        13,
        48,
        0,
        49,
        0,
        6,
        31,
        32,
        0,
        21,
        40,
        1
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        35,
        24,
        11,
        38,
        15,
        14,
        47,
        49,
        47,
        0,
        26,
        17,
        29,
        0,
        32,
        9,
        49,
        47,
        4,
        34,
        29,
        31,
        34,
        14,
        19,
        40,
        9,
        6,
        35,
        40,
        49,
        42,
        2,
        0,
        48,
        2,
        27,
        48,
        4,
        49,
        21,
        39,
        16,
        32,
        49,
        0,
        0,
        44,
        0,
        28,
        44,
        0
      ],
      "weeklyRejected": [
        13,
        12,
        21,
        30,
        43,
        39,
        49,
        18,
        47,
        48,
        49,
        18,
        14,
        48,
        48,
        0,
        0,
        49,
        41,
        31,
        23,
        49,
        14,
        25,
        30,
        5,
        37,
        33,
        45,
        18,
        13,
        38,
        48,
        29,
        27,
        28,
        49,
        16,
        49,
        0,
        41,
        49,
        49,
        24,
        44,
        0,
        0,
        22,
        9,
        39,
        6,
        49
      ],
      "weeklyHold": [
        15,
        19,
        49,
        25,
        0,
        20,
        21,
        29,
        48,
        24,
        47,
        33,
        22,
        26,
        14,
        49,
        23,
        16,
        46,
        27,
        27,
        0,
        27,
        12,
        14,
        22,
        0,
        21,
        49,
        17,
        48,
        11,
        7,
        49,
        48,
        3,
        0,
        7,
        35,
        2,
        39,
        6,
        15,
        37,
        49,
        41,
        5,
        48,
        32,
        1,
        26,
        26
      ],
      "weeklyBackordered": [
        45,
        49,
        14,
        41,
        35,
        46,
        27,
        33,
        49,
        32,
        44,
        30,
        8,
        27,
        21,
        24,
        19,
        49,
        0,
        41,
        16,
        0,
        30,
        49,
        11,
        37,
        23,
        23,
        2,
        49,
        24,
        0,
        41,
        22,
        38,
        18,
        26,
        0,
        4,
        39,
        45,
        33,
        37,
        49,
        22,
        9,
        41,
        9,
        28,
        49,
        29,
        16
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        20,
        16,
        49,
        15,
        22,
        36,
        36,
        29,
        16,
        15,
        36,
        20,
        49,
        30,
        12,
        26,
        33,
        6,
        0,
        44,
        49,
        34,
        49,
        0,
        12,
        37,
        0,
        21,
        1,
        39,
        25,
        36,
        31,
        8,
        26,
        12,
        24,
        48,
        48,
        28,
        0,
        9,
        27,
        49,
        36,
        28,
        20,
        31,
        23,
        34,
        27,
        35
      ],
      "weeklyRejected": [
        23,
        0,
        48,
        43,
        16,
        38,
        49,
        23,
        28,
        31,
        39,
        44,
        14,
        48,
        48,
        13,
        0,
        49,
        38,
        44,
        25,
        49,
        49,
        24,
        27,
        49,
        20,
        0,
        27,
        25,
        32,
        13,
        48,
        28,
        0,
        49,
        33,
        49,
        1,
        10,
        47,
        49,
        1,
        21,
        49,
        49,
        0,
        29,
        1,
        29,
        0,
        49
      ],
      "weeklyHold": [
        15,
        7,
        39,
        1,
        34,
        36,
        36,
        36,
        32,
        16,
        33,
        41,
        13,
        30,
        49,
        3,
        0,
        10,
        37,
        27,
        40,
        22,
        33,
        24,
        41,
        48,
        43,
        0,
        30,
        7,
        48,
        7,
        12,
        29,
        9,
        49,
        49,
        0,
        49,
        23,
        17,
        36,
        43,
        28,
        30,
        47,
        49,
        49,
        25,
        23,
        15,
        28
      ],
      "weeklyBackordered": [
        49,
        22,
        21,
        0,
        47,
        22,
        34,
        46,
        42,
        0,
        0,
        49,
        6,
        35,
        13,
        1,
        49,
        42,
        25,
        33,
        15,
        49,
        15,
        31,
        36,
        39,
        1,
        49,
        13,
        48,
        37,
        45,
        48,
        46,
        47,
        0,
        21,
        12,
        49,
        8,
        42,
        48,
        5,
        49,
        0,
        13,
        17,
        29,
        17,
        41,
        21,
        30
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        0,
        46,
        49,
        48,
        24,
        32,
        1,
        2,
        16,
        15,
        27,
        26,
        49,
        49,
        31,
        18,
        49,
        43,
        6,
        49,
        0,
        45,
        30,
        47,
        0,
        25,
        24,
        18,
        9,
        29,
        24,
        16,
        49,
        33,
        22,
        14,
        39,
        17,
        24,
        49,
        49,
        0,
        5,
        49,
        49,
        21,
        0,
        49,
        18,
        24,
        0,
        38
      ],
      "weeklyRejected": [
        49,
        13,
        49,
        16,
        49,
        42,
        23,
        49,
        49,
        3,
        0,
        0,
        17,
        19,
        5,
        0,
        23,
        25,
        49,
        24,
        15,
        49,
        7,
        49,
        18,
        49,
        0,
        45,
        48,
        12,
        6,
        14,
        42,
        49,
        33,
        22,
        24,
        8,
        11,
        14,
        47,
        37,
        5,
        0,
        49,
        4,
        49,
        0,
        19,
        28,
        0,
        14
      ],
      "weeklyHold": [
        49,
        2,
        49,
        14,
        49,
        20,
        49,
        21,
        16,
        17,
        47,
        15,
        13,
        1,
        49,
        32,
        7,
        37,
        15,
        18,
        36,
        32,
        18,
        32,
        19,
        38,
        3,
        8,
        37,
        0,
        48,
        13,
        29,
        49,
        33,
        47,
        44,
        33,
        31,
        45,
        22,
        38,
        25,
        40,
        14,
        0,
        25,
        43,
        4,
        0,
        21,
        49
      ],
      "weeklyBackordered": [
        31,
        38,
        31,
        10,
        41,
        20,
        49,
        49,
        49,
        16,
        49,
        49,
        28,
        49,
        39,
        35,
        1,
        49,
        0,
        0,
        42,
        0,
        12,
        39,
        48,
        28,
        1,
        40,
        40,
        33,
        47,
        49,
        48,
        31,
        18,
        0,
        1,
        0,
        0,
        26,
        5,
        21,
        6,
        49,
        19,
        29,
        45,
        45,
        32,
        17,
        49,
        5
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        18,
        49,
        30,
        0,
        31,
        20,
        6,
        40,
        25,
        32,
        24,
        1,
        49,
        14,
        45,
        7,
        27,
        2,
        15,
        41,
        5,
        17,
        49,
        34,
        18,
        47,
        31,
        12,
        17,
        32,
        27,
        0,
        49,
        41,
        44,
        49,
        39,
        23,
        13,
        41,
        0,
        39,
        39,
        38,
        49,
        0,
        22,
        20,
        49,
        49,
        33,
        48
      ],
      "weeklyRejected": [
        11,
        20,
        32,
        36,
        31,
        19,
        12,
        23,
        23,
        20,
        31,
        0,
        31,
        48,
        8,
        24,
        18,
        49,
        17,
        0,
        0,
        8,
        31,
        27,
        17,
        49,
        29,
        20,
        44,
        48,
        30,
        9,
        12,
        36,
        33,
        49,
        49,
        8,
        10,
        35,
        48,
        18,
        20,
        7,
        27,
        12,
        37,
        4,
        21,
        38,
        0,
        39
      ],
      "weeklyHold": [
        45,
        24,
        15,
        14,
        20,
        39,
        49,
        34,
        15,
        2,
        5,
        27,
        34,
        43,
        49,
        0,
        19,
        20,
        0,
        34,
        0,
        15,
        0,
        23,
        18,
        39,
        49,
        3,
        47,
        30,
        21,
        37,
        13,
        34,
        0,
        49,
        14,
        0,
        38,
        30,
        48,
        36,
        14,
        18,
        31,
        36,
        49,
        20,
        1,
        25,
        36,
        22
      ],
      "weeklyBackordered": [
        49,
        7,
        15,
        0,
        49,
        46,
        19,
        1,
        14,
        34,
        27,
        49,
        3,
        0,
        33,
        20,
        49,
        49,
        24,
        0,
        41,
        49,
        20,
        21,
        12,
        10,
        17,
        6,
        14,
        49,
        5,
        43,
        25,
        24,
        26,
        0,
        12,
        0,
        13,
        25,
        13,
        44,
        0,
        27,
        17,
        43,
        49,
        27,
        2,
        32,
        43,
        6
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        3,
        30,
        6,
        38,
        45,
        3,
        1,
        37,
        37,
        0,
        49,
        0,
        49,
        34,
        10,
        10,
        49,
        7,
        29,
        37,
        21,
        45,
        43,
        34,
        23,
        14,
        0,
        27,
        2,
        49,
        0,
        20,
        49,
        0,
        48,
        27,
        34,
        14,
        49,
        34,
        22,
        13,
        46,
        32,
        26,
        22,
        5,
        1,
        15,
        29,
        31,
        17
      ],
      "weeklyRejected": [
        3,
        8,
        48,
        47,
        30,
        20,
        21,
        25,
        40,
        35,
        49,
        8,
        47,
        38,
        21,
        18,
        27,
        15,
        1,
        0,
        22,
        35,
        20,
        18,
        30,
        19,
        3,
        1,
        46,
        27,
        24,
        8,
        48,
        21,
        33,
        49,
        13,
        34,
        3,
        49,
        34,
        49,
        24,
        12,
        26,
        49,
        0,
        32,
        0,
        49,
        0,
        33
      ],
      "weeklyHold": [
        49,
        0,
        49,
        1,
        27,
        16,
        0,
        9,
        24,
        40,
        26,
        23,
        46,
        30,
        36,
        9,
        7,
        48,
        3,
        7,
        12,
        27,
        49,
        0,
        3,
        30,
        35,
        14,
        34,
        2,
        48,
        25,
        0,
        49,
        48,
        46,
        42,
        24,
        37,
        19,
        26,
        30,
        6,
        3,
        35,
        19,
        0,
        16,
        5,
        16,
        49,
        49
      ],
      "weeklyBackordered": [
        29,
        49,
        3,
        40,
        32,
        33,
        12,
        5,
        38,
        15,
        32,
        36,
        3,
        49,
        17,
        1,
        49,
        22,
        0,
        30,
        21,
        8,
        30,
        5,
        47,
        22,
        35,
        44,
        17,
        8,
        37,
        12,
        48,
        23,
        5,
        0,
        49,
        0,
        33,
        24,
        23,
        48,
        25,
        49,
        0,
        13,
        10,
        0,
        0,
        19,
        49,
        27
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        36,
        33,
        30,
        18,
        27,
        0,
        1,
        42,
        39,
        16,
        49,
        0,
        28,
        40,
        1,
        17,
        35,
        10,
        19,
        23,
        15,
        32,
        38,
        42,
        16,
        44,
        13,
        30,
        2,
        17,
        1,
        35,
        29,
        0,
        48,
        49,
        24,
        48,
        27,
        49,
        19,
        22,
        21,
        33,
        17,
        0,
        0,
        16,
        20,
        45,
        29,
        21
      ],
      "weeklyRejected": [
        15,
        43,
        12,
        3,
        46,
        18,
        48,
        7,
        11,
        23,
        44,
        21,
        8,
        44,
        2,
        48,
        25,
        48,
        0,
        0,
        39,
        24,
        15,
        16,
        1,
        49,
        49,
        12,
        23,
        23,
        14,
        0,
        44,
        49,
        0,
        35,
        8,
        40,
        6,
        38,
        12,
        0,
        41,
        8,
        49,
        21,
        2,
        9,
        43,
        34,
        0,
        42
      ],
      "weeklyHold": [
        14,
        33,
        18,
        1,
        15,
        45,
        0,
        6,
        0,
        0,
        47,
        16,
        13,
        25,
        25,
        3,
        0,
        32,
        0,
        8,
        27,
        19,
        0,
        18,
        32,
        6,
        0,
        22,
        0,
        9,
        48,
        28,
        31,
        29,
        0,
        18,
        28,
        15,
        38,
        25,
        16,
        49,
        27,
        10,
        6,
        16,
        11,
        48,
        0,
        5,
        15,
        37
      ],
      "weeklyBackordered": [
        27,
        43,
        27,
        20,
        29,
        46,
        30,
        40,
        31,
        0,
        26,
        28,
        27,
        31,
        0,
        30,
        49,
        13,
        0,
        10,
        37,
        48,
        28,
        0,
        15,
        33,
        26,
        22,
        20,
        39,
        31,
        43,
        48,
        26,
        28,
        6,
        49,
        0,
        0,
        35,
        15,
        11,
        26,
        37,
        7,
        37,
        37,
        15,
        8,
        37,
        29,
        12
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        0,
        11,
        28,
        14,
        7,
        0,
        1,
        49,
        27,
        21,
        0,
        11,
        49,
        40,
        43,
        32,
        7,
        41,
        10,
        28,
        6,
        49,
        49,
        49,
        0,
        14,
        0,
        35,
        1,
        3,
        31,
        35,
        32,
        20,
        41,
        38,
        10,
        2,
        18,
        0,
        10,
        34,
        49,
        27,
        45,
        0,
        0,
        47,
        26,
        49,
        48,
        39
      ],
      "weeklyRejected": [
        19,
        0,
        7,
        38,
        11,
        0,
        31,
        12,
        26,
        9,
        10,
        44,
        26,
        41,
        48,
        11,
        49,
        29,
        34,
        30,
        24,
        49,
        5,
        49,
        46,
        49,
        44,
        33,
        48,
        4,
        49,
        20,
        42,
        19,
        37,
        49,
        19,
        16,
        1,
        19,
        40,
        36,
        20,
        24,
        36,
        28,
        37,
        22,
        0,
        49,
        15,
        49
      ],
      "weeklyHold": [
        18,
        41,
        49,
        32,
        35,
        48,
        13,
        16,
        24,
        23,
        46,
        42,
        1,
        47,
        0,
        18,
        0,
        47,
        21,
        0,
        21,
        49,
        26,
        0,
        40,
        33,
        38,
        21,
        49,
        3,
        48,
        4,
        0,
        15,
        40,
        25,
        35,
        0,
        34,
        37,
        0,
        24,
        38,
        32,
        40,
        40,
        0,
        17,
        20,
        27,
        35,
        26
      ],
      "weeklyBackordered": [
        40,
        0,
        0,
        27,
        38,
        25,
        22,
        25,
        0,
        49,
        19,
        2,
        3,
        37,
        1,
        1,
        49,
        36,
        0,
        0,
        37,
        28,
        17,
        36,
        1,
        10,
        37,
        49,
        29,
        33,
        37,
        17,
        48,
        40,
        38,
        0,
        35,
        16,
        44,
        41,
        20,
        31,
        21,
        23,
        25,
        12,
        20,
        44,
        0,
        49,
        31,
        13
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        29,
        29,
        17,
        29,
        35,
        0,
        12,
        24,
        48,
        35,
        37,
        0,
        45,
        23,
        42,
        15,
        49,
        12,
        0,
        36,
        37,
        17,
        10,
        49,
        18,
        29,
        29,
        40,
        49,
        49,
        27,
        22,
        22,
        0,
        48,
        16,
        3,
        48,
        38,
        49,
        38,
        49,
        12,
        14,
        49,
        0,
        9,
        6,
        28,
        49,
        19,
        10
      ],
      "weeklyRejected": [
        49,
        28,
        49,
        38,
        23,
        25,
        13,
        49,
        31,
        11,
        21,
        49,
        32,
        7,
        32,
        0,
        33,
        42,
        13,
        43,
        28,
        29,
        14,
        31,
        30,
        21,
        37,
        36,
        33,
        18,
        49,
        31,
        12,
        49,
        31,
        49,
        49,
        21,
        32,
        22,
        22,
        49,
        33,
        0,
        49,
        11,
        9,
        30,
        43,
        49,
        19,
        34
      ],
      "weeklyHold": [
        18,
        0,
        49,
        1,
        49,
        42,
        8,
        2,
        0,
        24,
        27,
        31,
        48,
        36,
        26,
        35,
        14,
        19,
        22,
        20,
        19,
        12,
        49,
        8,
        6,
        33,
        0,
        5,
        42,
        38,
        48,
        15,
        29,
        10,
        48,
        0,
        33,
        27,
        49,
        17,
        38,
        2,
        8,
        49,
        40,
        14,
        33,
        43,
        11,
        4,
        43,
        45
      ],
      "weeklyBackordered": [
        0,
        0,
        0,
        49,
        49,
        32,
        45,
        49,
        0,
        9,
        49,
        38,
        34,
        13,
        35,
        49,
        21,
        49,
        0,
        16,
        19,
        0,
        10,
        9,
        13,
        25,
        44,
        45,
        49,
        19,
        35,
        41,
        31,
        10,
        9,
        13,
        45,
        0,
        7,
        6,
        13,
        48,
        31,
        20,
        22,
        30,
        42,
        20,
        6,
        46,
        21,
        26
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        23,
        43,
        18,
        32,
        36,
        46,
        42,
        28,
        48,
        23,
        49,
        16,
        0,
        23,
        4,
        0,
        49,
        28,
        7,
        16,
        2,
        41,
        29,
        24,
        17,
        31,
        43,
        0,
        9,
        28,
        48,
        11,
        49,
        0,
        16,
        12,
        28,
        48,
        29,
        49,
        25,
        0,
        15,
        13,
        49,
        9,
        12,
        9,
        16,
        49,
        25,
        24
      ],
      "weeklyRejected": [
        40,
        7,
        33,
        47,
        38,
        33,
        36,
        25,
        31,
        17,
        31,
        20,
        34,
        48,
        22,
        6,
        35,
        48,
        38,
        33,
        49,
        0,
        49,
        40,
        0,
        19,
        43,
        0,
        28,
        25,
        17,
        10,
        48,
        19,
        20,
        30,
        49,
        31,
        33,
        15,
        24,
        5,
        36,
        18,
        26,
        24,
        9,
        3,
        20,
        49,
        0,
        49
      ],
      "weeklyHold": [
        49,
        32,
        27,
        1,
        34,
        20,
        0,
        2,
        16,
        26,
        39,
        33,
        46,
        3,
        26,
        32,
        1,
        32,
        23,
        44,
        22,
        12,
        1,
        38,
        45,
        37,
        2,
        43,
        26,
        6,
        30,
        24,
        21,
        33,
        45,
        0,
        49,
        42,
        23,
        20,
        11,
        12,
        0,
        39,
        32,
        0,
        47,
        49,
        30,
        20,
        39,
        40
      ],
      "weeklyBackordered": [
        29,
        22,
        0,
        7,
        37,
        31,
        18,
        8,
        35,
        2,
        42,
        39,
        11,
        42,
        45,
        8,
        16,
        23,
        0,
        28,
        6,
        40,
        48,
        23,
        1,
        49,
        1,
        29,
        43,
        49,
        1,
        23,
        23,
        20,
        13,
        0,
        21,
        0,
        0,
        19,
        6,
        26,
        16,
        49,
        20,
        41,
        44,
        35,
        15,
        34,
        22,
        5
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        5,
        10,
        26,
        22,
        22,
        32,
        11,
        49,
        0,
        33,
        49,
        6,
        20,
        16,
        35,
        31,
        49,
        35,
        30,
        49,
        18,
        20,
        20,
        49,
        0,
        20,
        32,
        27,
        1,
        49,
        5,
        12,
        49,
        42,
        42,
        44,
        47,
        48,
        36,
        49,
        0,
        0,
        46,
        29,
        43,
        9,
        0,
        19,
        6,
        21,
        0,
        20
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        14,
        49,
        7,
        23,
        13,
        28,
        42,
        22,
        49,
        7,
        32,
        48,
        17,
        38,
        37,
        42,
        17,
        0,
        11,
        38,
        31,
        23,
        49,
        33,
        0,
        32,
        48,
        31,
        34,
        30,
        27,
        17,
        48,
        17,
        24,
        1,
        5,
        38,
        0,
        49,
        41,
        38,
        49,
        0,
        2,
        0,
        3,
        15,
        27
      ],
      "weeklyHold": [
        41,
        6,
        49,
        1,
        31,
        38,
        39,
        13,
        10,
        27,
        47,
        36,
        42,
        13,
        49,
        24,
        49,
        0,
        4,
        17,
        47,
        10,
        0,
        49,
        35,
        48,
        10,
        0,
        49,
        0,
        48,
        13,
        0,
        39,
        0,
        45,
        27,
        17,
        36,
        28,
        39,
        40,
        0,
        39,
        28,
        0,
        25,
        14,
        0,
        10,
        32,
        44
      ],
      "weeklyBackordered": [
        37,
        24,
        26,
        36,
        45,
        39,
        34,
        49,
        6,
        37,
        49,
        44,
        39,
        16,
        12,
        19,
        22,
        4,
        13,
        27,
        49,
        0,
        17,
        6,
        24,
        19,
        23,
        29,
        10,
        49,
        10,
        49,
        10,
        39,
        0,
        18,
        6,
        10,
        49,
        34,
        36,
        48,
        3,
        37,
        24,
        28,
        0,
        26,
        20,
        14,
        49,
        33
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        2,
        23,
        23,
        6,
        21,
        0,
        11,
        35,
        48,
        35,
        22,
        11,
        34,
        49,
        23,
        25,
        35,
        16,
        0,
        49,
        20,
        49,
        37,
        0,
        49,
        47,
        32,
        0,
        22,
        44,
        4,
        49,
        49,
        3,
        20,
        11,
        40,
        19,
        49,
        49,
        27,
        27,
        49,
        47,
        49,
        22,
        42,
        0,
        32,
        48,
        41,
        0
      ],
      "weeklyRejected": [
        49,
        12,
        36,
        23,
        49,
        41,
        25,
        49,
        49,
        29,
        40,
        49,
        15,
        48,
        48,
        41,
        33,
        49,
        49,
        0,
        1,
        24,
        12,
        18,
        21,
        44,
        23,
        12,
        0,
        37,
        32,
        47,
        40,
        49,
        5,
        49,
        10,
        21,
        11,
        29,
        13,
        31,
        36,
        17,
        40,
        49,
        0,
        9,
        38,
        5,
        30,
        49
      ],
      "weeklyHold": [
        0,
        0,
        21,
        15,
        49,
        4,
        44,
        49,
        13,
        8,
        15,
        35,
        35,
        28,
        42,
        49,
        0,
        0,
        20,
        3,
        9,
        15,
        40,
        23,
        13,
        26,
        30,
        17,
        28,
        27,
        48,
        8,
        2,
        28,
        40,
        49,
        29,
        10,
        17,
        49,
        25,
        17,
        0,
        15,
        28,
        49,
        16,
        17,
        0,
        31,
        0,
        10
      ],
      "weeklyBackordered": [
        31,
        16,
        24,
        27,
        38,
        40,
        8,
        49,
        21,
        14,
        21,
        24,
        49,
        0,
        46,
        32,
        16,
        37,
        5,
        16,
        36,
        40,
        28,
        49,
        1,
        33,
        6,
        21,
        12,
        41,
        25,
        22,
        30,
        39,
        20,
        0,
        37,
        6,
        29,
        0,
        48,
        27,
        0,
        49,
        13,
        20,
        49,
        31,
        0,
        49,
        35,
        47
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        17,
        38,
        18,
        36,
        49,
        22,
        20,
        23,
        11,
        37,
        34,
        3,
        19,
        12,
        47,
        49,
        29,
        0,
        26,
        29,
        1,
        35,
        28,
        37,
        49,
        47,
        12,
        30,
        19,
        1,
        21,
        0,
        12,
        21,
        47,
        32,
        33,
        7,
        0,
        22,
        26,
        49,
        28,
        29,
        23,
        8,
        22,
        0,
        49,
        49,
        0,
        6
      ],
      "weeklyRejected": [
        31,
        49,
        30,
        47,
        37,
        25,
        35,
        20,
        33,
        4,
        49,
        18,
        29,
        37,
        48,
        33,
        0,
        49,
        49,
        12,
        23,
        0,
        2,
        29,
        39,
        49,
        49,
        33,
        31,
        28,
        6,
        17,
        38,
        18,
        37,
        37,
        35,
        13,
        1,
        44,
        3,
        22,
        19,
        0,
        17,
        0,
        0,
        11,
        8,
        49,
        0,
        49
      ],
      "weeklyHold": [
        49,
        27,
        35,
        13,
        0,
        36,
        0,
        2,
        21,
        44,
        40,
        49,
        48,
        28,
        49,
        36,
        35,
        22,
        6,
        34,
        16,
        33,
        29,
        0,
        7,
        39,
        17,
        1,
        37,
        0,
        26,
        7,
        0,
        49,
        24,
        49,
        33,
        7,
        32,
        39,
        49,
        30,
        22,
        29,
        29,
        0,
        26,
        41,
        0,
        25,
        26,
        21
      ],
      "weeklyBackordered": [
        49,
        34,
        13,
        3,
        39,
        46,
        0,
        1,
        29,
        6,
        0,
        35,
        27,
        17,
        19,
        1,
        45,
        49,
        0,
        18,
        44,
        49,
        16,
        34,
        25,
        1,
        21,
        34,
        21,
        49,
        27,
        46,
        39,
        43,
        2,
        0,
        19,
        2,
        34,
        28,
        14,
        48,
        6,
        41,
        0,
        19,
        49,
        22,
        35,
        49,
        25,
        4
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        23,
        33,
        7,
        29,
        26,
        13,
        9,
        40,
        48,
        32,
        27,
        22,
        49,
        48,
        49,
        39,
        37,
        20,
        0,
        15,
        30,
        32,
        49,
        49,
        0,
        47,
        22,
        45,
        30,
        32,
        32,
        0,
        49,
        16,
        30,
        48,
        8,
        20,
        27,
        49,
        49,
        28,
        34,
        47,
        36,
        0,
        39,
        3,
        49,
        42,
        49,
        29
      ],
      "weeklyRejected": [
        49,
        0,
        36,
        47,
        34,
        30,
        49,
        31,
        37,
        48,
        38,
        18,
        20,
        37,
        25,
        45,
        32,
        49,
        18,
        0,
        24,
        26,
        40,
        33,
        8,
        43,
        26,
        22,
        48,
        35,
        49,
        47,
        25,
        49,
        0,
        45,
        49,
        6,
        1,
        17,
        41,
        34,
        45,
        23,
        36,
        4,
        0,
        20,
        0,
        49,
        10,
        47
      ],
      "weeklyHold": [
        21,
        9,
        11,
        30,
        48,
        31,
        37,
        21,
        45,
        49,
        27,
        45,
        45,
        49,
        0,
        2,
        30,
        2,
        13,
        41,
        4,
        49,
        0,
        24,
        0,
        16,
        22,
        4,
        49,
        0,
        41,
        46,
        0,
        44,
        13,
        29,
        24,
        0,
        39,
        9,
        49,
        49,
        0,
        36,
        49,
        37,
        33,
        49,
        17,
        25,
        44,
        0
      ],
      "weeklyBackordered": [
        17,
        12,
        17,
        40,
        49,
        14,
        0,
        41,
        20,
        49,
        49,
        49,
        34,
        19,
        2,
        2,
        32,
        49,
        0,
        0,
        30,
        28,
        9,
        6,
        17,
        40,
        36,
        12,
        32,
        49,
        0,
        49,
        8,
        6,
        0,
        0,
        6,
        0,
        37,
        27,
        16,
        25,
        19,
        49,
        0,
        46,
        39,
        7,
        0,
        49,
        20,
        39
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        0,
        6,
        35,
        48,
        15,
        27,
        15,
        2,
        18,
        1,
        49,
        0,
        21,
        9,
        34,
        35,
        49,
        41,
        1,
        31,
        16,
        49,
        33,
        6,
        25,
        21,
        19,
        12,
        4,
        44,
        18,
        31,
        49,
        18,
        29,
        49,
        22,
        19,
        7,
        49,
        24,
        2,
        27,
        8,
        45,
        8,
        5,
        41,
        13,
        12,
        26,
        0
      ],
      "weeklyRejected": [
        9,
        5,
        23,
        32,
        22,
        6,
        25,
        24,
        47,
        16,
        16,
        19,
        24,
        22,
        33,
        37,
        12,
        20,
        26,
        38,
        31,
        35,
        49,
        30,
        14,
        30,
        1,
        40,
        17,
        36,
        0,
        28,
        47,
        34,
        22,
        37,
        30,
        0,
        1,
        46,
        27,
        16,
        27,
        9,
        49,
        40,
        0,
        14,
        0,
        45,
        17,
        21
      ],
      "weeklyHold": [
        31,
        49,
        32,
        1,
        49,
        44,
        37,
        2,
        24,
        28,
        47,
        45,
        30,
        5,
        43,
        38,
        41,
        48,
        0,
        3,
        37,
        13,
        13,
        0,
        37,
        37,
        15,
        11,
        35,
        0,
        48,
        9,
        17,
        20,
        46,
        49,
        23,
        0,
        41,
        33,
        45,
        22,
        0,
        30,
        43,
        49,
        0,
        34,
        45,
        10,
        32,
        38
      ],
      "weeklyBackordered": [
        36,
        0,
        17,
        0,
        45,
        31,
        42,
        3,
        10,
        16,
        13,
        49,
        18,
        2,
        35,
        28,
        27,
        18,
        16,
        12,
        34,
        18,
        16,
        19,
        3,
        24,
        3,
        27,
        23,
        43,
        25,
        23,
        0,
        34,
        0,
        0,
        1,
        5,
        35,
        23,
        12,
        48,
        23,
        15,
        17,
        14,
        6,
        49,
        35,
        39,
        49,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        22,
        1,
        25,
        25,
        24,
        39,
        14,
        19,
        30,
        0,
        49,
        0,
        22,
        0,
        15,
        34,
        10,
        21,
        9,
        22,
        0,
        25,
        49,
        33,
        3,
        47,
        49,
        0,
        7,
        49,
        14,
        1,
        43,
        0,
        48,
        17,
        47,
        11,
        27,
        26,
        2,
        0,
        7,
        12,
        49,
        22,
        23,
        28,
        3,
        8,
        2,
        13
      ],
      "weeklyRejected": [
        32,
        0,
        2,
        0,
        45,
        27,
        19,
        17,
        10,
        47,
        44,
        22,
        20,
        34,
        40,
        33,
        0,
        23,
        32,
        34,
        49,
        49,
        49,
        49,
        31,
        29,
        24,
        18,
        0,
        26,
        6,
        21,
        42,
        9,
        42,
        49,
        49,
        0,
        11,
        29,
        31,
        49,
        45,
        0,
        49,
        26,
        4,
        49,
        18,
        0,
        9,
        41
      ],
      "weeklyHold": [
        34,
        49,
        30,
        15,
        49,
        28,
        49,
        2,
        25,
        0,
        32,
        25,
        48,
        35,
        7,
        37,
        49,
        28,
        40,
        0,
        0,
        3,
        0,
        20,
        19,
        35,
        31,
        12,
        21,
        0,
        48,
        16,
        0,
        49,
        36,
        39,
        47,
        0,
        49,
        18,
        23,
        49,
        49,
        27,
        2,
        32,
        0,
        9,
        2,
        2,
        32,
        41
      ],
      "weeklyBackordered": [
        0,
        44,
        0,
        15,
        39,
        46,
        49,
        49,
        9,
        17,
        33,
        48,
        22,
        49,
        7,
        1,
        49,
        49,
        0,
        30,
        18,
        38,
        49,
        22,
        29,
        45,
        1,
        19,
        29,
        15,
        35,
        30,
        41,
        47,
        0,
        22,
        1,
        28,
        15,
        43,
        19,
        29,
        49,
        49,
        46,
        35,
        27,
        37,
        0,
        19,
        32,
        32
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyAccepted": [
        0,
        25,
        49,
        37,
        25,
        30,
        49,
        2,
        12,
        49,
        47,
        0,
        41,
        49,
        49,
        20,
        49,
        12,
        0,
        21,
        25,
        46,
        8,
        39,
        16,
        37,
        2,
        8,
        49,
        49,
        0,
        21,
        49,
        44,
        0,
        31,
        35,
        32,
        34,
        49,
        0,
        5,
        49,
        42,
        27,
        0,
        9,
        15,
        11,
        0,
        33,
        48
      ],
      "weeklyRejected": [
        49,
        5,
        15,
        8,
        49,
        49,
        37,
        38,
        36,
        1,
        5,
        0,
        49,
        32,
        48,
        21,
        49,
        44,
        21,
        49,
        9,
        44,
        28,
        16,
        34,
        29,
        0,
        49,
        37,
        22,
        49,
        25,
        29,
        29,
        32,
        31,
        40,
        27,
        1,
        9,
        10,
        31,
        47,
        8,
        49,
        19,
        10,
        0,
        0,
        38,
        37,
        11
      ],
      "weeklyHold": [
        23,
        23,
        49,
        1,
        28,
        48,
        27,
        2,
        21,
        46,
        40,
        1,
        28,
        3,
        43,
        0,
        25,
        48,
        14,
        14,
        26,
        34,
        48,
        0,
        44,
        48,
        20,
        14,
        16,
        21,
        48,
        6,
        16,
        49,
        44,
        49,
        49,
        27,
        1,
        0,
        25,
        32,
        13,
        18,
        7,
        17,
        2,
        31,
        28,
        39,
        30,
        46
      ],
      "weeklyBackordered": [
        19,
        47,
        0,
        45,
        49,
        41,
        49,
        29,
        32,
        14,
        0,
        49,
        7,
        17,
        26,
        1,
        8,
        49,
        34,
        38,
        32,
        43,
        10,
        49,
        1,
        30,
        1,
        3,
        22,
        21,
        22,
        21,
        41,
        0,
        18,
        0,
        24,
        12,
        27,
        49,
        25,
        48,
        11,
        49,
        10,
        0,
        17,
        0,
        0,
        48,
        31,
        19
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        4,
        31,
        17,
        0,
        17,
        1,
        37,
        13,
        48,
        41,
        21,
        19,
        49,
        23,
        49,
        7,
        49,
        14,
        24,
        31,
        24,
        49,
        49,
        49,
        0,
        47,
        49,
        14,
        6,
        37,
        0,
        1,
        49,
        34,
        48,
        16,
        40,
        16,
        34,
        47,
        2,
        41,
        49,
        41,
        9,
        28,
        37,
        26,
        9,
        37,
        39,
        28
      ],
      "weeklyRejected": [
        21,
        16,
        35,
        22,
        39,
        34,
        25,
        15,
        4,
        31,
        8,
        0,
        49,
        38,
        19,
        26,
        26,
        49,
        24,
        0,
        8,
        7,
        0,
        23,
        26,
        33,
        5,
        0,
        41,
        24,
        0,
        19,
        48,
        40,
        12,
        39,
        20,
        21,
        1,
        15,
        20,
        49,
        25,
        18,
        49,
        46,
        7,
        23,
        39,
        42,
        22,
        37
      ],
      "weeklyHold": [
        11,
        0,
        16,
        25,
        43,
        41,
        16,
        2,
        0,
        10,
        41,
        1,
        28,
        38,
        36,
        36,
        0,
        15,
        22,
        26,
        49,
        39,
        16,
        22,
        13,
        31,
        9,
        37,
        39,
        13,
        48,
        13,
        9,
        10,
        36,
        29,
        41,
        24,
        31,
        40,
        49,
        16,
        20,
        34,
        23,
        31,
        49,
        49,
        0,
        39,
        45,
        47
      ],
      "weeklyBackordered": [
        43,
        6,
        17,
        0,
        49,
        39,
        46,
        45,
        11,
        25,
        49,
        10,
        3,
        49,
        28,
        36,
        49,
        49,
        0,
        32,
        47,
        36,
        1,
        11,
        34,
        24,
        16,
        28,
        36,
        49,
        0,
        49,
        2,
        17,
        23,
        0,
        1,
        16,
        39,
        0,
        19,
        41,
        7,
        49,
        17,
        49,
        35,
        6,
        46,
        27,
        13,
        38
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyAccepted": [
        30,
        23,
        23,
        26,
        43,
        0,
        42,
        39,
        15,
        24,
        7,
        43,
        45,
        49,
        21,
        18,
        49,
        11,
        7,
        17,
        49,
        20,
        49,
        25,
        8,
        29,
        24,
        27,
        11,
        23,
        1,
        39,
        39,
        41,
        33,
        41,
        17,
        29,
        49,
        49,
        28,
        18,
        42,
        31,
        6,
        0,
        0,
        14,
        47,
        18,
        20,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        24,
        39,
        9,
        10,
        48,
        49,
        28,
        5,
        14,
        27,
        7,
        33,
        48,
        0,
        31,
        49,
        11,
        0,
        42,
        42,
        24,
        15,
        0,
        28,
        37,
        37,
        48,
        0,
        41,
        19,
        48,
        19,
        0,
        22,
        46,
        13,
        6,
        49,
        43,
        31,
        30,
        43,
        48,
        28,
        6,
        29,
        20,
        19,
        0,
        42
      ],
      "weeklyHold": [
        21,
        12,
        49,
        21,
        49,
        19,
        34,
        21,
        0,
        49,
        30,
        1,
        17,
        33,
        0,
        2,
        6,
        38,
        35,
        35,
        0,
        0,
        29,
        6,
        37,
        48,
        34,
        22,
        27,
        0,
        15,
        17,
        16,
        9,
        32,
        49,
        36,
        0,
        39,
        29,
        49,
        37,
        0,
        30,
        31,
        0,
        33,
        23,
        10,
        0,
        49,
        14
      ],
      "weeklyBackordered": [
        13,
        39,
        0,
        15,
        44,
        26,
        18,
        31,
        2,
        49,
        14,
        49,
        3,
        49,
        27,
        19,
        42,
        49,
        0,
        33,
        8,
        42,
        0,
        14,
        41,
        28,
        40,
        49,
        43,
        41,
        0,
        49,
        3,
        38,
        13,
        12,
        1,
        28,
        14,
        49,
        9,
        37,
        9,
        49,
        24,
        5,
        0,
        0,
        34,
        25,
        14,
        49
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        0,
        49,
        49,
        11,
        49,
        0,
        32,
        34,
        44,
        17,
        0,
        0,
        49,
        14,
        1,
        11,
        47,
        11,
        25,
        37,
        8,
        37,
        0,
        0,
        2,
        47,
        22,
        5,
        1,
        29,
        23,
        49,
        31,
        45,
        48,
        21,
        16,
        48,
        21,
        14,
        31,
        38,
        49,
        25,
        49,
        40,
        0,
        11,
        44,
        25,
        49,
        26
      ],
      "weeklyRejected": [
        29,
        23,
        30,
        1,
        26,
        5,
        30,
        49,
        15,
        22,
        49,
        11,
        12,
        26,
        37,
        0,
        0,
        49,
        49,
        6,
        26,
        17,
        26,
        0,
        28,
        49,
        26,
        45,
        8,
        35,
        36,
        33,
        48,
        43,
        19,
        49,
        25,
        41,
        19,
        4,
        19,
        16,
        0,
        45,
        49,
        21,
        3,
        16,
        5,
        45,
        12,
        49
      ],
      "weeklyHold": [
        31,
        49,
        43,
        22,
        5,
        38,
        10,
        27,
        42,
        30,
        24,
        1,
        34,
        1,
        38,
        29,
        36,
        37,
        16,
        3,
        23,
        22,
        49,
        6,
        32,
        1,
        36,
        49,
        49,
        49,
        17,
        4,
        0,
        23,
        23,
        42,
        14,
        0,
        30,
        49,
        0,
        30,
        27,
        30,
        13,
        49,
        41,
        0,
        0,
        49,
        39,
        22
      ],
      "weeklyBackordered": [
        26,
        49,
        30,
        44,
        49,
        46,
        5,
        48,
        40,
        0,
        38,
        44,
        19,
        42,
        4,
        11,
        8,
        29,
        0,
        0,
        26,
        23,
        49,
        0,
        11,
        10,
        24,
        14,
        18,
        29,
        34,
        28,
        44,
        14,
        23,
        19,
        49,
        0,
        29,
        4,
        25,
        48,
        0,
        49,
        24,
        38,
        12,
        5,
        14,
        33,
        43,
        28
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        14,
        24,
        6,
        34,
        11,
        28,
        6,
        16,
        30,
        6,
        41,
        20,
        39,
        46,
        8,
        31,
        33,
        45,
        0,
        49,
        28,
        38,
        25,
        23,
        32,
        35,
        41,
        28,
        33,
        49,
        0,
        6,
        28,
        11,
        24,
        2,
        26,
        15,
        22,
        49,
        20,
        22,
        29,
        13,
        34,
        34,
        16,
        4,
        16,
        49,
        0,
        9
      ],
      "weeklyRejected": [
        27,
        18,
        49,
        47,
        48,
        49,
        49,
        15,
        48,
        1,
        28,
        46,
        45,
        29,
        48,
        0,
        34,
        0,
        36,
        26,
        0,
        0,
        42,
        0,
        22,
        43,
        49,
        26,
        48,
        0,
        29,
        12,
        43,
        28,
        4,
        35,
        49,
        26,
        1,
        21,
        24,
        0,
        9,
        36,
        35,
        12,
        20,
        31,
        4,
        49,
        32,
        33
      ],
      "weeklyHold": [
        22,
        25,
        49,
        6,
        6,
        43,
        32,
        25,
        46,
        18,
        39,
        10,
        48,
        10,
        22,
        29,
        0,
        33,
        45,
        0,
        28,
        21,
        37,
        9,
        30,
        35,
        25,
        41,
        38,
        11,
        32,
        35,
        0,
        22,
        46,
        2,
        24,
        21,
        44,
        39,
        23,
        34,
        31,
        29,
        24,
        28,
        40,
        47,
        4,
        42,
        43,
        21
      ],
      "weeklyBackordered": [
        36,
        33,
        0,
        37,
        40,
        46,
        41,
        49,
        32,
        0,
        21,
        29,
        24,
        9,
        31,
        5,
        27,
        40,
        9,
        44,
        14,
        0,
        39,
        10,
        9,
        22,
        49,
        19,
        1,
        49,
        49,
        15,
        40,
        40,
        0,
        0,
        10,
        10,
        13,
        38,
        6,
        44,
        49,
        49,
        34,
        21,
        42,
        49,
        0,
        49,
        41,
        6
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        0,
        12,
        25,
        45,
        48,
        29,
        26,
        33,
        3,
        24,
        30,
        0,
        22,
        49,
        34,
        12,
        49,
        0,
        49,
        29,
        21,
        21,
        42,
        0,
        0,
        29,
        0,
        0,
        21,
        40,
        0,
        23,
        49,
        48,
        48,
        26,
        47,
        28,
        32,
        49,
        3,
        49,
        19,
        44,
        25,
        26,
        0,
        48,
        0,
        41,
        15,
        31
      ],
      "weeklyRejected": [
        49,
        49,
        49,
        37,
        11,
        32,
        27,
        31,
        32,
        7,
        11,
        45,
        11,
        48,
        48,
        6,
        16,
        49,
        49,
        49,
        14,
        26,
        49,
        0,
        28,
        16,
        5,
        16,
        39,
        8,
        28,
        47,
        44,
        49,
        32,
        44,
        36,
        19,
        1,
        46,
        39,
        34,
        0,
        5,
        47,
        19,
        20,
        11,
        13,
        30,
        4,
        40
      ],
      "weeklyHold": [
        42,
        32,
        41,
        20,
        2,
        31,
        23,
        28,
        12,
        17,
        47,
        9,
        33,
        4,
        42,
        41,
        0,
        39,
        9,
        21,
        13,
        14,
        49,
        20,
        30,
        48,
        0,
        0,
        19,
        0,
        22,
        9,
        0,
        38,
        13,
        0,
        40,
        0,
        40,
        24,
        23,
        33,
        0,
        27,
        27,
        34,
        14,
        22,
        0,
        31,
        40,
        16
      ],
      "weeklyBackordered": [
        29,
        21,
        45,
        47,
        48,
        7,
        49,
        25,
        41,
        11,
        31,
        49,
        23,
        4,
        43,
        1,
        9,
        49,
        6,
        29,
        49,
        38,
        8,
        21,
        17,
        30,
        1,
        46,
        41,
        21,
        43,
        29,
        48,
        0,
        0,
        7,
        17,
        0,
        49,
        0,
        23,
        31,
        21,
        49,
        39,
        25,
        34,
        1,
        0,
        36,
        23,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        0,
        1,
        26,
        34,
        22,
        49,
        10,
        2,
        48,
        49,
        14,
        0,
        35,
        49,
        48,
        41,
        40,
        48,
        0,
        14,
        11,
        21,
        39,
        49,
        26,
        43,
        16,
        49,
        35,
        25,
        0,
        0,
        33,
        46,
        14,
        29,
        35,
        27,
        49,
        49,
        49,
        31,
        31,
        45,
        24,
        0,
        0,
        25,
        0,
        40,
        36,
        34
      ],
      "weeklyRejected": [
        49,
        32,
        49,
        12,
        23,
        49,
        23,
        21,
        30,
        31,
        6,
        0,
        19,
        11,
        48,
        49,
        0,
        39,
        41,
        0,
        26,
        14,
        19,
        49,
        12,
        49,
        33,
        49,
        32,
        11,
        49,
        47,
        27,
        49,
        0,
        33,
        34,
        30,
        1,
        28,
        48,
        32,
        48,
        27,
        48,
        0,
        21,
        31,
        0,
        37,
        16,
        40
      ],
      "weeklyHold": [
        49,
        0,
        21,
        1,
        36,
        0,
        37,
        11,
        4,
        28,
        47,
        25,
        37,
        41,
        30,
        31,
        10,
        24,
        23,
        49,
        49,
        18,
        19,
        7,
        48,
        48,
        14,
        0,
        4,
        49,
        48,
        0,
        0,
        0,
        27,
        16,
        34,
        43,
        22,
        49,
        28,
        32,
        0,
        35,
        13,
        39,
        29,
        49,
        34,
        18,
        31,
        30
      ],
      "weeklyBackordered": [
        47,
        27,
        0,
        1,
        49,
        29,
        21,
        46,
        0,
        0,
        7,
        49,
        42,
        21,
        29,
        14,
        11,
        48,
        0,
        0,
        49,
        16,
        5,
        49,
        19,
        7,
        1,
        21,
        44,
        49,
        49,
        31,
        3,
        49,
        10,
        24,
        19,
        24,
        24,
        49,
        17,
        25,
        12,
        49,
        31,
        32,
        31,
        9,
        40,
        11,
        29,
        1
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        11,
        49,
        49,
        48,
        49,
        6,
        25,
        29,
        20,
        32,
        49,
        26,
        49,
        49,
        49,
        23,
        49,
        11,
        38,
        29,
        0,
        21,
        20,
        38,
        5,
        37,
        0,
        49,
        32,
        37,
        18,
        0,
        49,
        17,
        25,
        49,
        11,
        20,
        35,
        28,
        2,
        5,
        28,
        16,
        48,
        0,
        12,
        49,
        0,
        0,
        34,
        0
      ],
      "weeklyRejected": [
        38,
        47,
        3,
        11,
        39,
        34,
        49,
        19,
        40,
        1,
        31,
        18,
        29,
        2,
        15,
        49,
        29,
        47,
        3,
        0,
        7,
        46,
        9,
        48,
        33,
        49,
        25,
        15,
        38,
        26,
        23,
        22,
        31,
        26,
        9,
        42,
        30,
        29,
        1,
        30,
        13,
        21,
        26,
        4,
        49,
        13,
        37,
        17,
        20,
        24,
        28,
        49
      ],
      "weeklyHold": [
        13,
        9,
        49,
        3,
        46,
        37,
        0,
        22,
        19,
        13,
        41,
        12,
        7,
        49,
        43,
        13,
        20,
        29,
        0,
        0,
        19,
        0,
        25,
        15,
        35,
        48,
        20,
        25,
        36,
        0,
        41,
        0,
        20,
        43,
        14,
        25,
        22,
        28,
        38,
        18,
        9,
        49,
        0,
        37,
        3,
        37,
        2,
        12,
        4,
        19,
        16,
        33
      ],
      "weeklyBackordered": [
        27,
        20,
        0,
        20,
        47,
        22,
        16,
        49,
        29,
        26,
        26,
        0,
        37,
        35,
        40,
        31,
        25,
        48,
        0,
        49,
        9,
        28,
        10,
        0,
        27,
        25,
        19,
        28,
        46,
        44,
        14,
        49,
        32,
        16,
        0,
        0,
        24,
        13,
        49,
        35,
        21,
        45,
        34,
        20,
        49,
        49,
        38,
        32,
        0,
        18,
        46,
        14
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        23,
        42,
        36,
        19,
        16,
        0,
        47,
        30,
        28,
        14,
        49,
        16,
        49,
        40,
        15,
        25,
        38,
        48,
        0,
        22,
        19,
        0,
        12,
        0,
        18,
        29,
        18,
        32,
        1,
        49,
        20,
        4,
        49,
        27,
        31,
        4,
        35,
        21,
        5,
        49,
        15,
        34,
        44,
        49,
        13,
        0,
        0,
        34,
        0,
        44,
        3,
        12
      ],
      "weeklyRejected": [
        39,
        15,
        22,
        4,
        35,
        49,
        26,
        12,
        49,
        29,
        40,
        49,
        38,
        26,
        22,
        7,
        26,
        32,
        17,
        33,
        9,
        46,
        7,
        49,
        36,
        0,
        47,
        14,
        46,
        5,
        49,
        47,
        48,
        49,
        0,
        29,
        33,
        8,
        49,
        27,
        48,
        24,
        32,
        0,
        40,
        4,
        0,
        0,
        45,
        23,
        0,
        49
      ],
      "weeklyHold": [
        31,
        0,
        49,
        18,
        24,
        25,
        47,
        39,
        7,
        20,
        47,
        15,
        22,
        11,
        36,
        14,
        0,
        22,
        49,
        29,
        20,
        17,
        26,
        49,
        28,
        18,
        2,
        28,
        49,
        0,
        48,
        28,
        26,
        49,
        47,
        33,
        12,
        18,
        18,
        19,
        17,
        37,
        33,
        24,
        49,
        45,
        37,
        49,
        23,
        34,
        12,
        30
      ],
      "weeklyBackordered": [
        49,
        34,
        48,
        40,
        46,
        20,
        2,
        46,
        48,
        8,
        14,
        23,
        49,
        0,
        31,
        21,
        18,
        29,
        41,
        49,
        10,
        29,
        49,
        13,
        29,
        33,
        13,
        0,
        27,
        42,
        12,
        12,
        30,
        43,
        23,
        17,
        12,
        0,
        6,
        0,
        48,
        48,
        6,
        44,
        9,
        49,
        49,
        18,
        20,
        49,
        33,
        49
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        8,
        38,
        9,
        0,
        30,
        32,
        21,
        29,
        9,
        15,
        17,
        10,
        42,
        45,
        12,
        16,
        49,
        25,
        16,
        38,
        37,
        17,
        42,
        29,
        5,
        11,
        23,
        30,
        6,
        20,
        32,
        0,
        2,
        10,
        34,
        32,
        22,
        48,
        27,
        19,
        34,
        30,
        13,
        49,
        49,
        14,
        0,
        24,
        45,
        18,
        18,
        8
      ],
      "weeklyRejected": [
        32,
        25,
        30,
        0,
        49,
        27,
        49,
        25,
        49,
        1,
        17,
        41,
        16,
        48,
        36,
        0,
        27,
        22,
        17,
        40,
        10,
        36,
        0,
        35,
        41,
        49,
        39,
        0,
        34,
        12,
        20,
        28,
        43,
        17,
        18,
        49,
        0,
        4,
        1,
        18,
        45,
        17,
        49,
        13,
        36,
        32,
        0,
        49,
        0,
        35,
        4,
        44
      ],
      "weeklyHold": [
        9,
        49,
        33,
        22,
        38,
        13,
        30,
        2,
        16,
        24,
        36,
        17,
        26,
        23,
        49,
        23,
        49,
        0,
        0,
        14,
        11,
        34,
        33,
        0,
        25,
        13,
        40,
        8,
        27,
        5,
        48,
        38,
        0,
        25,
        43,
        3,
        28,
        5,
        49,
        36,
        16,
        23,
        27,
        26,
        0,
        49,
        35,
        16,
        34,
        4,
        32,
        17
      ],
      "weeklyBackordered": [
        39,
        49,
        25,
        48,
        33,
        46,
        18,
        30,
        23,
        0,
        49,
        40,
        23,
        1,
        28,
        49,
        7,
        9,
        0,
        0,
        38,
        46,
        18,
        6,
        7,
        18,
        34,
        19,
        1,
        31,
        45,
        32,
        48,
        42,
        4,
        0,
        39,
        17,
        21,
        21,
        28,
        48,
        4,
        49,
        33,
        0,
        48,
        17,
        2,
        49,
        37,
        40
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        0,
        29,
        8,
        19,
        32,
        26,
        9,
        49,
        28,
        46,
        26,
        12,
        3,
        10,
        35,
        23,
        49,
        4,
        0,
        22,
        5,
        13,
        14,
        49,
        0,
        5,
        27,
        0,
        25,
        11,
        0,
        14,
        25,
        25,
        48,
        12,
        30,
        28,
        29,
        44,
        19,
        49,
        34,
        42,
        36,
        39,
        7,
        17,
        13,
        27,
        49,
        43
      ],
      "weeklyRejected": [
        32,
        8,
        18,
        0,
        46,
        16,
        42,
        4,
        32,
        12,
        34,
        43,
        48,
        48,
        32,
        24,
        22,
        49,
        46,
        2,
        0,
        23,
        21,
        48,
        18,
        49,
        24,
        34,
        0,
        24,
        24,
        47,
        0,
        3,
        41,
        35,
        33,
        40,
        1,
        7,
        9,
        49,
        48,
        11,
        49,
        0,
        11,
        1,
        0,
        13,
        0,
        32
      ],
      "weeklyHold": [
        0,
        12,
        18,
        19,
        10,
        47,
        37,
        2,
        0,
        0,
        29,
        49,
        40,
        21,
        49,
        4,
        17,
        0,
        0,
        2,
        18,
        12,
        0,
        11,
        49,
        26,
        24,
        0,
        14,
        49,
        48,
        15,
        40,
        29,
        12,
        47,
        42,
        0,
        41,
        49,
        41,
        24,
        17,
        0,
        46,
        20,
        37,
        28,
        39,
        35,
        49,
        49
      ],
      "weeklyBackordered": [
        37,
        29,
        8,
        42,
        27,
        33,
        21,
        24,
        17,
        40,
        28,
        30,
        3,
        39,
        1,
        30,
        49,
        11,
        19,
        5,
        34,
        32,
        13,
        21,
        23,
        1,
        4,
        0,
        20,
        43,
        31,
        37,
        35,
        25,
        19,
        0,
        2,
        27,
        23,
        3,
        29,
        46,
        10,
        49,
        0,
        49,
        11,
        49,
        17,
        49,
        21,
        40
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        31,
        17,
        23,
        32,
        13,
        0,
        18,
        20,
        38,
        27,
        49,
        0,
        31,
        18,
        1,
        26,
        49,
        3,
        0,
        17,
        15,
        25,
        21,
        20,
        15,
        21,
        7,
        14,
        12,
        6,
        33,
        9,
        29,
        0,
        48,
        7,
        13,
        48,
        29,
        31,
        11,
        19,
        49,
        22,
        45,
        20,
        34,
        18,
        0,
        20,
        49,
        24
      ],
      "weeklyRejected": [
        49,
        0,
        27,
        43,
        0,
        37,
        49,
        49,
        3,
        42,
        31,
        28,
        0,
        28,
        30,
        36,
        15,
        34,
        35,
        18,
        15,
        34,
        18,
        40,
        39,
        32,
        49,
        8,
        33,
        35,
        49,
        47,
        38,
        49,
        26,
        49,
        34,
        0,
        18,
        46,
        35,
        28,
        15,
        0,
        44,
        18,
        32,
        38,
        25,
        0,
        0,
        49
      ],
      "weeklyHold": [
        8,
        30,
        32,
        15,
        10,
        12,
        43,
        25,
        9,
        19,
        0,
        49,
        26,
        19,
        7,
        15,
        0,
        40,
        8,
        15,
        0,
        0,
        37,
        9,
        28,
        15,
        32,
        0,
        31,
        13,
        47,
        6,
        0,
        42,
        46,
        0,
        0,
        6,
        11,
        0,
        0,
        40,
        3,
        37,
        30,
        46,
        15,
        11,
        0,
        24,
        42,
        38
      ],
      "weeklyBackordered": [
        23,
        49,
        48,
        46,
        37,
        9,
        49,
        1,
        49,
        16,
        46,
        16,
        26,
        34,
        21,
        23,
        22,
        38,
        0,
        49,
        0,
        0,
        5,
        17,
        33,
        5,
        16,
        38,
        29,
        31,
        39,
        16,
        35,
        49,
        0,
        0,
        46,
        31,
        20,
        32,
        28,
        31,
        0,
        49,
        0,
        36,
        17,
        0,
        43,
        36,
        24,
        20
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyAccepted": [
        32,
        49,
        9,
        33,
        1,
        40,
        14,
        44,
        48,
        19,
        49,
        16,
        49,
        5,
        13,
        0,
        6,
        28,
        21,
        29,
        16,
        37,
        40,
        26,
        33,
        37,
        29,
        38,
        25,
        47,
        46,
        24,
        27,
        25,
        34,
        18,
        33,
        48,
        40,
        49,
        9,
        44,
        15,
        29,
        49,
        0,
        13,
        12,
        9,
        7,
        30,
        15
      ],
      "weeklyRejected": [
        11,
        49,
        49,
        0,
        49,
        0,
        39,
        23,
        49,
        32,
        22,
        26,
        0,
        48,
        27,
        24,
        39,
        30,
        49,
        22,
        17,
        41,
        0,
        11,
        26,
        28,
        41,
        16,
        21,
        18,
        17,
        47,
        35,
        49,
        5,
        49,
        38,
        2,
        26,
        21,
        0,
        42,
        29,
        20,
        49,
        0,
        0,
        6,
        48,
        25,
        0,
        36
      ],
      "weeklyHold": [
        28,
        14,
        31,
        1,
        0,
        22,
        3,
        9,
        3,
        16,
        0,
        49,
        25,
        5,
        40,
        31,
        1,
        6,
        24,
        0,
        0,
        49,
        35,
        16,
        30,
        23,
        11,
        15,
        40,
        29,
        48,
        7,
        1,
        22,
        43,
        28,
        21,
        15,
        49,
        1,
        33,
        6,
        36,
        44,
        49,
        23,
        37,
        4,
        0,
        36,
        3,
        27
      ],
      "weeklyBackordered": [
        27,
        17,
        27,
        0,
        10,
        37,
        42,
        41,
        18,
        0,
        49,
        11,
        49,
        32,
        31,
        24,
        40,
        42,
        0,
        14,
        0,
        34,
        30,
        27,
        20,
        4,
        44,
        26,
        42,
        13,
        7,
        49,
        42,
        22,
        17,
        26,
        12,
        9,
        0,
        33,
        33,
        21,
        49,
        37,
        31,
        27,
        49,
        33,
        27,
        36,
        43,
        31
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        3,
        1,
        49,
        42,
        36,
        1,
        1,
        38,
        32,
        33,
        49,
        4,
        35,
        49,
        11,
        23,
        49,
        22,
        49,
        0,
        19,
        27,
        49,
        34,
        0,
        18,
        0,
        47,
        39,
        49,
        0,
        25,
        18,
        29,
        48,
        44,
        25,
        46,
        23,
        49,
        3,
        44,
        6,
        0,
        30,
        10,
        0,
        21,
        5,
        7,
        1,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        21,
        36,
        4,
        29,
        44,
        10,
        21,
        41,
        15,
        13,
        19,
        30,
        20,
        0,
        49,
        13,
        25,
        0,
        49,
        13,
        22,
        27,
        10,
        30,
        35,
        42,
        36,
        22,
        23,
        27,
        41,
        49,
        14,
        20,
        0,
        31,
        1,
        20,
        24,
        0,
        20,
        9,
        49,
        19,
        33,
        24,
        22,
        36,
        0,
        49
      ],
      "weeklyHold": [
        42,
        46,
        49,
        1,
        16,
        12,
        16,
        11,
        25,
        25,
        47,
        45,
        1,
        8,
        49,
        41,
        0,
        35,
        28,
        6,
        27,
        25,
        29,
        14,
        36,
        35,
        17,
        0,
        45,
        0,
        21,
        8,
        0,
        0,
        25,
        5,
        16,
        2,
        34,
        49,
        42,
        39,
        26,
        40,
        42,
        0,
        0,
        34,
        29,
        15,
        44,
        8
      ],
      "weeklyBackordered": [
        1,
        25,
        32,
        35,
        49,
        9,
        28,
        38,
        24,
        0,
        11,
        0,
        3,
        41,
        37,
        49,
        49,
        24,
        20,
        24,
        30,
        29,
        37,
        39,
        1,
        12,
        44,
        3,
        28,
        31,
        0,
        49,
        39,
        44,
        24,
        0,
        49,
        1,
        35,
        11,
        47,
        23,
        7,
        49,
        18,
        49,
        33,
        27,
        1,
        46,
        1,
        12
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        42,
        34,
        49,
        14,
        43,
        0,
        1,
        26,
        36,
        31,
        49,
        0,
        38,
        34,
        1,
        10,
        32,
        9,
        49,
        0,
        49,
        0,
        26,
        49,
        10,
        37,
        8,
        18,
        1,
        25,
        12,
        0,
        49,
        29,
        41,
        17,
        17,
        13,
        28,
        29,
        14,
        47,
        49,
        44,
        49,
        0,
        17,
        1,
        38,
        49,
        12,
        18
      ],
      "weeklyRejected": [
        49,
        33,
        24,
        47,
        21,
        12,
        26,
        41,
        49,
        18,
        49,
        19,
        0,
        14,
        36,
        0,
        0,
        42,
        49,
        11,
        26,
        47,
        6,
        0,
        11,
        31,
        21,
        25,
        48,
        44,
        49,
        28,
        0,
        31,
        33,
        46,
        6,
        19,
        6,
        1,
        39,
        0,
        44,
        48,
        42,
        21,
        0,
        0,
        6,
        45,
        0,
        35
      ],
      "weeklyHold": [
        14,
        23,
        24,
        8,
        0,
        20,
        20,
        16,
        18,
        49,
        5,
        26,
        1,
        3,
        29,
        0,
        0,
        44,
        2,
        0,
        0,
        38,
        2,
        20,
        31,
        16,
        9,
        25,
        39,
        21,
        27,
        16,
        0,
        35,
        39,
        31,
        22,
        8,
        23,
        22,
        35,
        35,
        49,
        49,
        28,
        45,
        2,
        42,
        25,
        32,
        35,
        1
      ],
      "weeklyBackordered": [
        16,
        31,
        40,
        49,
        49,
        36,
        30,
        27,
        32,
        19,
        22,
        18,
        4,
        0,
        30,
        18,
        38,
        39,
        22,
        5,
        40,
        12,
        49,
        46,
        22,
        30,
        35,
        0,
        13,
        49,
        22,
        36,
        28,
        0,
        46,
        35,
        25,
        7,
        36,
        2,
        27,
        48,
        24,
        49,
        24,
        33,
        49,
        0,
        5,
        27,
        41,
        43
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        19,
        31,
        49,
        18,
        41,
        2,
        24,
        9,
        18,
        29,
        46,
        34,
        35,
        34,
        6,
        30,
        49,
        9,
        26,
        17,
        25,
        27,
        36,
        49,
        21,
        42,
        21,
        49,
        35,
        34,
        11,
        0,
        49,
        0,
        39,
        46,
        20,
        3,
        19,
        22,
        20,
        49,
        24,
        39,
        47,
        19,
        28,
        42,
        30,
        28,
        6,
        11
      ],
      "weeklyRejected": [
        49,
        22,
        16,
        23,
        33,
        8,
        22,
        0,
        35,
        1,
        0,
        0,
        35,
        0,
        42,
        49,
        49,
        34,
        14,
        49,
        49,
        49,
        20,
        49,
        42,
        27,
        48,
        4,
        41,
        35,
        17,
        0,
        0,
        27,
        28,
        43,
        28,
        2,
        1,
        19,
        10,
        46,
        49,
        37,
        15,
        20,
        7,
        45,
        0,
        34,
        9,
        30
      ],
      "weeklyHold": [
        26,
        19,
        4,
        15,
        4,
        0,
        49,
        2,
        24,
        22,
        25,
        12,
        1,
        5,
        10,
        14,
        49,
        25,
        41,
        13,
        37,
        49,
        7,
        17,
        0,
        48,
        17,
        49,
        49,
        7,
        48,
        0,
        0,
        23,
        4,
        14,
        6,
        31,
        39,
        29,
        15,
        27,
        5,
        19,
        39,
        23,
        32,
        31,
        3,
        49,
        33,
        10
      ],
      "weeklyBackordered": [
        17,
        38,
        0,
        31,
        45,
        46,
        24,
        36,
        24,
        27,
        5,
        32,
        30,
        13,
        47,
        45,
        49,
        49,
        19,
        15,
        25,
        49,
        36,
        0,
        48,
        7,
        20,
        49,
        23,
        2,
        44,
        27,
        36,
        49,
        31,
        3,
        49,
        0,
        42,
        31,
        14,
        48,
        0,
        49,
        13,
        19,
        33,
        10,
        11,
        45,
        23,
        12
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        1,
        32,
        33,
        27,
        28,
        0,
        49,
        31,
        45,
        49,
        26,
        0,
        19,
        4,
        49,
        27,
        23,
        19,
        0,
        39,
        4,
        49,
        33,
        41,
        0,
        39,
        36,
        26,
        4,
        46,
        14,
        30,
        40,
        10,
        32,
        42,
        20,
        7,
        49,
        45,
        31,
        38,
        49,
        31,
        44,
        26,
        20,
        39,
        22,
        49,
        24,
        32
      ],
      "weeklyRejected": [
        49,
        9,
        39,
        38,
        49,
        9,
        0,
        34,
        38,
        24,
        0,
        23,
        40,
        48,
        48,
        9,
        16,
        11,
        49,
        41,
        49,
        45,
        0,
        25,
        0,
        40,
        13,
        0,
        36,
        46,
        49,
        47,
        48,
        12,
        3,
        49,
        13,
        1,
        1,
        49,
        48,
        49,
        41,
        29,
        49,
        35,
        49,
        49,
        0,
        49,
        0,
        13
      ],
      "weeklyHold": [
        0,
        36,
        31,
        18,
        49,
        33,
        43,
        23,
        33,
        49,
        47,
        10,
        42,
        49,
        0,
        21,
        9,
        45,
        0,
        44,
        7,
        0,
        0,
        33,
        0,
        31,
        9,
        11,
        4,
        19,
        11,
        21,
        0,
        16,
        34,
        37,
        49,
        12,
        38,
        27,
        0,
        5,
        0,
        0,
        40,
        49,
        34,
        43,
        2,
        0,
        0,
        36
      ],
      "weeklyBackordered": [
        30,
        3,
        16,
        27,
        0,
        25,
        31,
        45,
        35,
        39,
        44,
        36,
        35,
        33,
        24,
        1,
        28,
        49,
        0,
        14,
        37,
        0,
        48,
        10,
        8,
        1,
        33,
        49,
        19,
        39,
        49,
        3,
        15,
        49,
        8,
        30,
        15,
        7,
        3,
        46,
        31,
        25,
        11,
        42,
        13,
        12,
        49,
        34,
        0,
        49,
        22,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        6,
        33,
        32,
        14,
        1,
        0,
        1,
        49,
        30,
        10,
        31,
        22,
        49,
        27,
        23,
        41,
        36,
        20,
        29,
        0,
        24,
        28,
        49,
        43,
        9,
        25,
        0,
        49,
        5,
        11,
        19,
        49,
        33,
        20,
        48,
        33,
        18,
        4,
        0,
        49,
        8,
        17,
        49,
        0,
        30,
        23,
        0,
        10,
        13,
        43,
        32,
        15
      ],
      "weeklyRejected": [
        34,
        7,
        11,
        42,
        0,
        29,
        16,
        28,
        22,
        8,
        25,
        9,
        16,
        43,
        17,
        20,
        0,
        37,
        11,
        44,
        44,
        43,
        40,
        25,
        30,
        49,
        9,
        10,
        48,
        10,
        33,
        38,
        27,
        49,
        23,
        49,
        49,
        39,
        1,
        3,
        19,
        7,
        0,
        17,
        32,
        31,
        31,
        18,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        24,
        29,
        49,
        26,
        0,
        27,
        21,
        4,
        32,
        32,
        29,
        49,
        21,
        1,
        24,
        44,
        19,
        48,
        0,
        0,
        19,
        47,
        8,
        14,
        35,
        34,
        27,
        0,
        42,
        0,
        7,
        0,
        2,
        0,
        7,
        47,
        31,
        12,
        36,
        13,
        0,
        49,
        32,
        49,
        49,
        42,
        0,
        36,
        22,
        49,
        0,
        25
      ],
      "weeklyBackordered": [
        22,
        22,
        0,
        8,
        49,
        46,
        26,
        37,
        35,
        18,
        0,
        30,
        3,
        23,
        0,
        7,
        43,
        0,
        0,
        23,
        49,
        0,
        34,
        27,
        5,
        19,
        28,
        49,
        44,
        49,
        6,
        49,
        15,
        0,
        15,
        0,
        27,
        32,
        48,
        4,
        11,
        48,
        35,
        49,
        0,
        8,
        26,
        17,
        17,
        32,
        2,
        16
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        0,
        35,
        49,
        18,
        26,
        0,
        37,
        49,
        48,
        46,
        49,
        0,
        11,
        0,
        49,
        30,
        49,
        0,
        5,
        26,
        29,
        49,
        29,
        46,
        49,
        47,
        15,
        6,
        15,
        17,
        4,
        27,
        49,
        26,
        11,
        15,
        47,
        43,
        49,
        49,
        2,
        23,
        49,
        26,
        49,
        11,
        20,
        0,
        4,
        29,
        0,
        42
      ],
      "weeklyRejected": [
        49,
        23,
        36,
        0,
        0,
        43,
        0,
        26,
        0,
        27,
        31,
        0,
        42,
        34,
        48,
        35,
        49,
        34,
        49,
        43,
        49,
        27,
        23,
        49,
        22,
        11,
        49,
        21,
        38,
        48,
        26,
        47,
        48,
        24,
        49,
        29,
        9,
        46,
        14,
        49,
        15,
        3,
        49,
        6,
        49,
        31,
        18,
        31,
        35,
        47,
        10,
        49
      ],
      "weeklyHold": [
        49,
        5,
        38,
        1,
        49,
        18,
        46,
        36,
        49,
        22,
        40,
        49,
        19,
        38,
        10,
        14,
        13,
        7,
        43,
        1,
        4,
        37,
        10,
        23,
        23,
        26,
        28,
        30,
        29,
        49,
        44,
        47,
        0,
        49,
        48,
        49,
        49,
        26,
        34,
        27,
        27,
        39,
        0,
        38,
        1,
        0,
        30,
        34,
        2,
        39,
        40,
        21
      ],
      "weeklyBackordered": [
        0,
        49,
        25,
        37,
        31,
        1,
        2,
        1,
        27,
        42,
        33,
        28,
        33,
        30,
        41,
        12,
        33,
        49,
        18,
        0,
        0,
        7,
        25,
        21,
        42,
        45,
        1,
        0,
        49,
        19,
        1,
        40,
        37,
        49,
        0,
        0,
        30,
        0,
        30,
        39,
        24,
        29,
        48,
        49,
        13,
        0,
        49,
        15,
        49,
        49,
        13,
        2
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        0,
        20,
        49,
        19,
        22,
        34,
        27,
        37,
        19,
        4,
        12,
        0,
        32,
        45,
        49,
        9,
        49,
        33,
        13,
        23,
        36,
        49,
        27,
        21,
        0,
        47,
        29,
        21,
        1,
        48,
        33,
        0,
        49,
        0,
        5,
        22,
        47,
        21,
        49,
        25,
        1,
        1,
        9,
        41,
        49,
        28,
        20,
        49,
        12,
        18,
        10,
        17
      ],
      "weeklyRejected": [
        49,
        9,
        49,
        46,
        0,
        0,
        33,
        5,
        49,
        32,
        22,
        49,
        28,
        48,
        41,
        25,
        49,
        47,
        15,
        49,
        11,
        3,
        49,
        34,
        10,
        40,
        49,
        18,
        48,
        39,
        0,
        38,
        15,
        49,
        34,
        45,
        20,
        49,
        1,
        20,
        32,
        33,
        31,
        33,
        26,
        48,
        0,
        12,
        0,
        49,
        0,
        30
      ],
      "weeklyHold": [
        24,
        2,
        32,
        11,
        29,
        39,
        16,
        33,
        33,
        49,
        47,
        49,
        21,
        49,
        18,
        5,
        26,
        11,
        36,
        40,
        40,
        19,
        16,
        26,
        20,
        48,
        49,
        0,
        33,
        49,
        38,
        13,
        0,
        49,
        8,
        28,
        19,
        0,
        10,
        45,
        26,
        39,
        4,
        3,
        30,
        8,
        29,
        49,
        6,
        42,
        38,
        42
      ],
      "weeklyBackordered": [
        49,
        0,
        5,
        0,
        40,
        37,
        20,
        1,
        31,
        12,
        49,
        49,
        38,
        0,
        28,
        36,
        1,
        48,
        0,
        6,
        40,
        28,
        44,
        14,
        1,
        31,
        2,
        9,
        19,
        49,
        24,
        28,
        46,
        34,
        34,
        8,
        34,
        0,
        47,
        12,
        0,
        24,
        0,
        49,
        41,
        32,
        49,
        36,
        1,
        49,
        37,
        17
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        34,
        10,
        31,
        0,
        21,
        49,
        3,
        31,
        30,
        49,
        37,
        2,
        31,
        32,
        16,
        22,
        45,
        1,
        19,
        35,
        0,
        36,
        49,
        27,
        33,
        47,
        0,
        49,
        11,
        32,
        2,
        14,
        21,
        17,
        48,
        8,
        31,
        45,
        28,
        48,
        16,
        49,
        26,
        43,
        49,
        29,
        0,
        23,
        9,
        49,
        16,
        7
      ],
      "weeklyRejected": [
        36,
        38,
        3,
        10,
        41,
        34,
        31,
        10,
        49,
        48,
        15,
        30,
        49,
        45,
        39,
        16,
        38,
        32,
        41,
        15,
        49,
        49,
        49,
        49,
        45,
        49,
        35,
        19,
        31,
        0,
        37,
        37,
        36,
        20,
        28,
        36,
        49,
        25,
        12,
        5,
        15,
        45,
        8,
        47,
        34,
        20,
        13,
        39,
        20,
        49,
        0,
        25
      ],
      "weeklyHold": [
        33,
        0,
        49,
        1,
        18,
        36,
        0,
        5,
        34,
        49,
        17,
        28,
        35,
        16,
        18,
        31,
        2,
        36,
        46,
        0,
        5,
        14,
        22,
        30,
        39,
        29,
        49,
        13,
        49,
        0,
        42,
        32,
        11,
        49,
        40,
        23,
        40,
        49,
        43,
        36,
        20,
        36,
        0,
        49,
        28,
        35,
        33,
        0,
        34,
        3,
        28,
        37
      ],
      "weeklyBackordered": [
        41,
        49,
        4,
        23,
        38,
        46,
        29,
        40,
        9,
        2,
        49,
        19,
        16,
        46,
        0,
        49,
        28,
        49,
        17,
        38,
        28,
        25,
        0,
        2,
        41,
        31,
        28,
        8,
        28,
        33,
        9,
        32,
        0,
        14,
        0,
        0,
        49,
        30,
        1,
        31,
        10,
        31,
        24,
        44,
        31,
        36,
        0,
        7,
        49,
        27,
        49,
        10
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        13,
        36,
        2,
        23,
        16,
        27,
        23,
        30,
        33,
        32,
        28,
        3,
        0,
        49,
        29,
        9,
        28,
        4,
        0,
        49,
        0,
        33,
        33,
        13,
        27,
        36,
        4,
        32,
        16,
        22,
        21,
        19,
        31,
        19,
        45,
        39,
        18,
        0,
        33,
        33,
        25,
        0,
        49,
        40,
        49,
        21,
        0,
        13,
        9,
        49,
        46,
        28
      ],
      "weeklyRejected": [
        49,
        21,
        36,
        12,
        29,
        28,
        5,
        42,
        11,
        48,
        20,
        32,
        8,
        41,
        48,
        44,
        34,
        38,
        13,
        20,
        49,
        28,
        15,
        14,
        5,
        49,
        42,
        34,
        18,
        0,
        46,
        11,
        35,
        12,
        18,
        40,
        24,
        0,
        1,
        26,
        48,
        33,
        42,
        0,
        31,
        20,
        38,
        34,
        12,
        32,
        0,
        28
      ],
      "weeklyHold": [
        16,
        47,
        19,
        19,
        7,
        25,
        46,
        5,
        0,
        49,
        39,
        41,
        24,
        20,
        49,
        0,
        27,
        48,
        0,
        18,
        0,
        30,
        5,
        2,
        49,
        3,
        17,
        24,
        49,
        15,
        25,
        1,
        36,
        49,
        0,
        9,
        24,
        6,
        18,
        33,
        5,
        28,
        0,
        36,
        18,
        49,
        22,
        25,
        2,
        49,
        33,
        27
      ],
      "weeklyBackordered": [
        49,
        43,
        0,
        11,
        49,
        25,
        25,
        26,
        19,
        0,
        0,
        49,
        49,
        25,
        30,
        1,
        39,
        49,
        18,
        0,
        18,
        0,
        0,
        49,
        28,
        32,
        12,
        22,
        1,
        47,
        37,
        0,
        11,
        28,
        0,
        0,
        14,
        11,
        11,
        27,
        13,
        27,
        20,
        49,
        19,
        27,
        46,
        16,
        0,
        10,
        22,
        8
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        19,
        42,
        49,
        0,
        37,
        18,
        43,
        27,
        48,
        49,
        49,
        0,
        49,
        4,
        19,
        36,
        41,
        3,
        0,
        27,
        0,
        49,
        49,
        49,
        39,
        29,
        42,
        41,
        23,
        39,
        0,
        0,
        34,
        8,
        42,
        2,
        17,
        48,
        28,
        49,
        5,
        9,
        49,
        49,
        49,
        5,
        0,
        16,
        0,
        46,
        48,
        27
      ],
      "weeklyRejected": [
        40,
        3,
        49,
        5,
        14,
        12,
        24,
        17,
        29,
        31,
        41,
        26,
        39,
        39,
        48,
        29,
        46,
        49,
        49,
        15,
        3,
        22,
        11,
        5,
        35,
        49,
        0,
        4,
        39,
        17,
        46,
        22,
        48,
        34,
        20,
        49,
        49,
        32,
        1,
        31,
        39,
        26,
        47,
        23,
        49,
        8,
        0,
        49,
        10,
        42,
        0,
        49
      ],
      "weeklyHold": [
        0,
        8,
        14,
        22,
        0,
        22,
        15,
        20,
        25,
        19,
        19,
        26,
        26,
        31,
        9,
        11,
        3,
        29,
        8,
        18,
        24,
        29,
        9,
        32,
        29,
        18,
        36,
        29,
        23,
        37,
        48,
        1,
        21,
        27,
        14,
        0,
        34,
        10,
        23,
        42,
        32,
        49,
        0,
        0,
        33,
        49,
        49,
        45,
        0,
        24,
        38,
        22
      ],
      "weeklyBackordered": [
        20,
        29,
        0,
        44,
        49,
        46,
        21,
        8,
        48,
        13,
        22,
        30,
        3,
        7,
        47,
        24,
        28,
        49,
        18,
        46,
        49,
        30,
        7,
        43,
        43,
        9,
        4,
        6,
        17,
        36,
        9,
        21,
        0,
        36,
        33,
        0,
        16,
        0,
        49,
        26,
        18,
        48,
        10,
        49,
        49,
        49,
        22,
        11,
        44,
        19,
        17,
        3
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        22,
        27,
        49,
        25,
        8,
        30,
        1,
        29,
        43,
        32,
        49,
        1,
        17,
        7,
        1,
        24,
        35,
        31,
        9,
        37,
        0,
        20,
        49,
        42,
        49,
        42,
        8,
        1,
        8,
        18,
        14,
        0,
        26,
        32,
        27,
        13,
        9,
        48,
        9,
        32,
        49,
        27,
        49,
        26,
        40,
        0,
        10,
        26,
        18,
        49,
        40,
        48
      ],
      "weeklyRejected": [
        24,
        25,
        34,
        47,
        13,
        6,
        13,
        14,
        19,
        33,
        49,
        34,
        17,
        43,
        48,
        0,
        2,
        47,
        15,
        49,
        38,
        0,
        13,
        19,
        0,
        48,
        38,
        12,
        42,
        17,
        31,
        47,
        48,
        25,
        0,
        17,
        18,
        0,
        16,
        9,
        47,
        0,
        34,
        18,
        49,
        17,
        47,
        16,
        17,
        49,
        0,
        40
      ],
      "weeklyHold": [
        0,
        0,
        39,
        25,
        24,
        7,
        0,
        12,
        35,
        31,
        27,
        13,
        4,
        35,
        7,
        25,
        19,
        35,
        0,
        20,
        49,
        49,
        2,
        42,
        46,
        35,
        0,
        0,
        20,
        42,
        36,
        18,
        0,
        29,
        0,
        18,
        41,
        0,
        16,
        34,
        10,
        24,
        47,
        20,
        7,
        10,
        0,
        49,
        11,
        49,
        0,
        5
      ],
      "weeklyBackordered": [
        27,
        49,
        0,
        17,
        49,
        38,
        10,
        12,
        49,
        20,
        33,
        30,
        16,
        37,
        49,
        21,
        38,
        47,
        11,
        18,
        40,
        4,
        33,
        28,
        23,
        10,
        24,
        9,
        38,
        33,
        28,
        2,
        16,
        24,
        0,
        0,
        21,
        10,
        49,
        14,
        26,
        48,
        1,
        49,
        12,
        49,
        49,
        11,
        42,
        23,
        34,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        45,
        40,
        0,
        0,
        47,
        0,
        19,
        7,
        30,
        45,
        3,
        0,
        1,
        20,
        37,
        0,
        40,
        21,
        25,
        0,
        11,
        9,
        38,
        25,
        35,
        16,
        16,
        49,
        29,
        5,
        46,
        0,
        35,
        0,
        36,
        49,
        34,
        28,
        12,
        48,
        33,
        49,
        37,
        35,
        49,
        49,
        7,
        0,
        0,
        7,
        49,
        29
      ],
      "weeklyRejected": [
        36,
        26,
        34,
        17,
        26,
        36,
        14,
        37,
        49,
        19,
        26,
        32,
        48,
        48,
        48,
        0,
        19,
        36,
        13,
        0,
        49,
        47,
        26,
        12,
        49,
        49,
        0,
        42,
        26,
        25,
        40,
        24,
        48,
        33,
        0,
        49,
        36,
        39,
        5,
        0,
        29,
        5,
        32,
        0,
        49,
        0,
        2,
        17,
        26,
        28,
        0,
        35
      ],
      "weeklyHold": [
        33,
        17,
        0,
        20,
        0,
        13,
        49,
        2,
        0,
        22,
        20,
        29,
        44,
        20,
        30,
        22,
        20,
        11,
        16,
        48,
        17,
        30,
        20,
        48,
        13,
        14,
        49,
        0,
        49,
        18,
        24,
        6,
        4,
        49,
        14,
        22,
        22,
        17,
        49,
        31,
        22,
        35,
        9,
        24,
        2,
        22,
        5,
        39,
        17,
        35,
        49,
        9
      ],
      "weeklyBackordered": [
        37,
        26,
        34,
        28,
        49,
        22,
        26,
        49,
        23,
        0,
        4,
        49,
        33,
        20,
        24,
        22,
        49,
        49,
        7,
        0,
        35,
        0,
        0,
        2,
        5,
        25,
        36,
        0,
        1,
        49,
        0,
        48,
        33,
        49,
        0,
        12,
        49,
        4,
        30,
        0,
        33,
        48,
        0,
        49,
        15,
        49,
        49,
        12,
        17,
        12,
        1,
        3
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        27,
        37,
        26,
        48,
        11,
        0,
        1,
        49,
        0,
        14,
        49,
        8,
        37,
        31,
        22,
        10,
        30,
        0,
        12,
        33,
        42,
        4,
        38,
        0,
        0,
        38,
        49,
        7,
        13,
        1,
        13,
        25,
        49,
        26,
        48,
        46,
        0,
        2,
        26,
        25,
        0,
        28,
        44,
        26,
        34,
        0,
        4,
        9,
        15,
        49,
        47,
        5
      ],
      "weeklyRejected": [
        26,
        14,
        6,
        29,
        5,
        0,
        14,
        23,
        39,
        32,
        33,
        47,
        46,
        39,
        43,
        12,
        24,
        49,
        8,
        0,
        41,
        16,
        44,
        22,
        36,
        47,
        49,
        25,
        42,
        0,
        35,
        37,
        5,
        0,
        25,
        34,
        30,
        18,
        1,
        49,
        39,
        43,
        34,
        37,
        49,
        49,
        13,
        6,
        8,
        1,
        11,
        49
      ],
      "weeklyHold": [
        46,
        33,
        47,
        16,
        46,
        48,
        20,
        42,
        0,
        16,
        0,
        49,
        31,
        35,
        23,
        11,
        0,
        14,
        4,
        0,
        2,
        49,
        26,
        13,
        40,
        1,
        42,
        0,
        33,
        11,
        25,
        22,
        13,
        49,
        13,
        23,
        29,
        0,
        32,
        17,
        10,
        36,
        34,
        0,
        15,
        49,
        43,
        0,
        9,
        35,
        15,
        12
      ],
      "weeklyBackordered": [
        30,
        11,
        46,
        9,
        41,
        23,
        0,
        18,
        7,
        0,
        49,
        0,
        33,
        35,
        0,
        24,
        39,
        49,
        0,
        26,
        20,
        0,
        44,
        16,
        37,
        8,
        1,
        24,
        1,
        49,
        47,
        33,
        48,
        42,
        15,
        0,
        35,
        0,
        35,
        28,
        15,
        39,
        3,
        44,
        28,
        42,
        26,
        16,
        33,
        47,
        25,
        10
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        21,
        43,
        49,
        27,
        6,
        28,
        27,
        8,
        35,
        37,
        49,
        2,
        49,
        41,
        8,
        0,
        24,
        0,
        32,
        20,
        0,
        39,
        31,
        48,
        31,
        30,
        35,
        6,
        27,
        49,
        41,
        0,
        39,
        3,
        48,
        29,
        23,
        18,
        27,
        49,
        26,
        38,
        49,
        21,
        16,
        32,
        21,
        39,
        38,
        49,
        28,
        14
      ],
      "weeklyRejected": [
        38,
        26,
        18,
        0,
        43,
        16,
        49,
        0,
        17,
        28,
        36,
        11,
        49,
        6,
        33,
        38,
        1,
        43,
        24,
        22,
        19,
        9,
        3,
        20,
        10,
        49,
        46,
        17,
        48,
        10,
        45,
        10,
        35,
        49,
        0,
        49,
        5,
        0,
        1,
        30,
        20,
        47,
        21,
        1,
        42,
        13,
        0,
        9,
        38,
        47,
        0,
        24
      ],
      "weeklyHold": [
        1,
        22,
        31,
        1,
        44,
        21,
        40,
        28,
        8,
        28,
        0,
        37,
        28,
        46,
        33,
        20,
        6,
        39,
        0,
        19,
        25,
        38,
        23,
        25,
        27,
        18,
        48,
        9,
        26,
        25,
        29,
        24,
        4,
        3,
        7,
        18,
        19,
        0,
        38,
        45,
        17,
        49,
        24,
        0,
        13,
        25,
        23,
        0,
        0,
        0,
        47,
        11
      ],
      "weeklyBackordered": [
        0,
        44,
        24,
        26,
        49,
        46,
        49,
        27,
        26,
        48,
        32,
        0,
        16,
        7,
        49,
        49,
        26,
        26,
        8,
        38,
        15,
        0,
        23,
        49,
        35,
        19,
        1,
        17,
        14,
        22,
        49,
        38,
        8,
        49,
        14,
        0,
        20,
        19,
        49,
        16,
        20,
        29,
        0,
        29,
        16,
        39,
        22,
        25,
        19,
        11,
        49,
        3
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyAccepted": [
        0,
        25,
        44,
        0,
        15,
        4,
        21,
        12,
        28,
        3,
        49,
        20,
        35,
        11,
        39,
        41,
        35,
        30,
        0,
        36,
        2,
        49,
        49,
        43,
        41,
        24,
        11,
        16,
        21,
        49,
        6,
        45,
        36,
        9,
        36,
        33,
        28,
        5,
        27,
        49,
        0,
        0,
        13,
        49,
        46,
        0,
        0,
        7,
        0,
        39,
        32,
        3
      ],
      "weeklyRejected": [
        0,
        3,
        35,
        44,
        24,
        33,
        36,
        5,
        21,
        7,
        19,
        29,
        47,
        29,
        3,
        18,
        1,
        49,
        49,
        0,
        0,
        21,
        0,
        24,
        7,
        30,
        47,
        23,
        0,
        30,
        11,
        35,
        36,
        11,
        28,
        49,
        3,
        35,
        1,
        19,
        38,
        49,
        20,
        0,
        17,
        34,
        11,
        17,
        48,
        35,
        0,
        10
      ],
      "weeklyHold": [
        0,
        40,
        49,
        24,
        34,
        48,
        49,
        2,
        5,
        5,
        34,
        1,
        35,
        38,
        49,
        39,
        41,
        1,
        39,
        4,
        39,
        49,
        0,
        23,
        36,
        1,
        26,
        18,
        33,
        41,
        44,
        34,
        0,
        31,
        14,
        47,
        21,
        0,
        33,
        49,
        49,
        42,
        15,
        49,
        29,
        12,
        0,
        36,
        16,
        3,
        14,
        49
      ],
      "weeklyBackordered": [
        49,
        0,
        0,
        28,
        45,
        25,
        37,
        40,
        24,
        13,
        6,
        46,
        28,
        33,
        36,
        46,
        20,
        30,
        34,
        0,
        1,
        31,
        15,
        23,
        10,
        8,
        26,
        37,
        13,
        49,
        29,
        5,
        39,
        26,
        0,
        17,
        41,
        13,
        25,
        21,
        31,
        48,
        15,
        38,
        19,
        47,
        49,
        49,
        7,
        11,
        35,
        49
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        36,
        49,
        48,
        48,
        40,
        0,
        44,
        44,
        48,
        22,
        24,
        0,
        21,
        49,
        41,
        16,
        40,
        20,
        44,
        14,
        49,
        8,
        14,
        9,
        14,
        26,
        0,
        47,
        1,
        1,
        49,
        37,
        49,
        36,
        48,
        36,
        10,
        45,
        37,
        49,
        23,
        6,
        22,
        34,
        49,
        34,
        0,
        32,
        23,
        35,
        26,
        29
      ],
      "weeklyRejected": [
        23,
        27,
        23,
        47,
        45,
        49,
        17,
        49,
        47,
        1,
        37,
        0,
        0,
        48,
        33,
        14,
        21,
        38,
        43,
        40,
        8,
        36,
        18,
        43,
        23,
        6,
        0,
        31,
        39,
        32,
        44,
        36,
        48,
        33,
        39,
        6,
        0,
        25,
        1,
        12,
        44,
        32,
        13,
        22,
        34,
        0,
        7,
        0,
        48,
        49,
        0,
        35
      ],
      "weeklyHold": [
        30,
        49,
        0,
        1,
        43,
        25,
        31,
        2,
        24,
        21,
        33,
        20,
        7,
        40,
        27,
        44,
        10,
        30,
        25,
        1,
        0,
        33,
        37,
        0,
        0,
        1,
        17,
        38,
        32,
        8,
        17,
        0,
        8,
        41,
        48,
        31,
        0,
        30,
        48,
        11,
        14,
        24,
        17,
        40,
        26,
        26,
        0,
        29,
        26,
        24,
        49,
        0
      ],
      "weeklyBackordered": [
        9,
        15,
        2,
        10,
        45,
        39,
        40,
        42,
        41,
        24,
        9,
        27,
        34,
        16,
        34,
        20,
        1,
        41,
        8,
        21,
        27,
        41,
        40,
        29,
        6,
        23,
        28,
        30,
        20,
        49,
        0,
        46,
        48,
        41,
        0,
        0,
        49,
        0,
        30,
        1,
        17,
        48,
        21,
        49,
        21,
        0,
        49,
        0,
        10,
        15,
        39,
        13
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        0,
        30,
        49,
        24,
        22,
        16,
        7,
        27,
        47,
        6,
        37,
        14,
        5,
        19,
        32,
        9,
        27,
        0,
        24,
        41,
        0,
        7,
        14,
        45,
        14,
        12,
        28,
        0,
        5,
        18,
        9,
        28,
        45,
        0,
        14,
        24,
        33,
        0,
        43,
        49,
        40,
        34,
        49,
        19,
        15,
        0,
        0,
        1,
        20,
        42,
        3,
        20
      ],
      "weeklyRejected": [
        36,
        9,
        34,
        17,
        28,
        32,
        9,
        32,
        49,
        40,
        25,
        35,
        39,
        43,
        48,
        22,
        20,
        5,
        46,
        0,
        42,
        49,
        35,
        20,
        26,
        49,
        27,
        22,
        39,
        46,
        35,
        31,
        36,
        1,
        30,
        49,
        37,
        45,
        1,
        47,
        30,
        3,
        49,
        8,
        49,
        45,
        34,
        29,
        0,
        46,
        32,
        49
      ],
      "weeklyHold": [
        17,
        44,
        20,
        1,
        49,
        10,
        13,
        17,
        34,
        49,
        30,
        48,
        28,
        32,
        31,
        31,
        17,
        34,
        20,
        26,
        34,
        30,
        32,
        22,
        25,
        13,
        49,
        8,
        49,
        0,
        48,
        0,
        0,
        27,
        48,
        42,
        37,
        0,
        23,
        10,
        17,
        49,
        0,
        49,
        31,
        36,
        44,
        24,
        0,
        37,
        6,
        17
      ],
      "weeklyBackordered": [
        26,
        25,
        14,
        8,
        49,
        46,
        38,
        38,
        37,
        30,
        42,
        27,
        49,
        27,
        36,
        21,
        49,
        49,
        15,
        4,
        49,
        12,
        8,
        34,
        1,
        49,
        41,
        0,
        26,
        43,
        21,
        46,
        0,
        48,
        12,
        11,
        23,
        2,
        48,
        19,
        16,
        48,
        16,
        49,
        44,
        12,
        49,
        10,
        10,
        0,
        49,
        1
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        5,
        18,
        35,
        0,
        29,
        12,
        16,
        47,
        48,
        0,
        37,
        8,
        0,
        30,
        20,
        0,
        49,
        34,
        35,
        17,
        49,
        1,
        37,
        7,
        25,
        17,
        22,
        27,
        15,
        12,
        2,
        49,
        45,
        41,
        48,
        47,
        40,
        17,
        26,
        49,
        22,
        49,
        26,
        34,
        32,
        0,
        20,
        17,
        0,
        45,
        48,
        10
      ],
      "weeklyRejected": [
        49,
        26,
        2,
        30,
        20,
        24,
        39,
        30,
        48,
        37,
        0,
        0,
        8,
        48,
        44,
        13,
        13,
        49,
        17,
        49,
        17,
        36,
        47,
        11,
        21,
        27,
        24,
        18,
        40,
        0,
        0,
        25,
        0,
        49,
        37,
        31,
        6,
        24,
        30,
        35,
        38,
        41,
        29,
        8,
        49,
        49,
        25,
        36,
        28,
        49,
        0,
        37
      ],
      "weeklyHold": [
        41,
        11,
        44,
        1,
        29,
        48,
        18,
        30,
        15,
        32,
        19,
        3,
        1,
        33,
        0,
        14,
        4,
        45,
        40,
        6,
        0,
        24,
        0,
        0,
        10,
        44,
        35,
        28,
        26,
        0,
        48,
        11,
        0,
        49,
        20,
        32,
        35,
        0,
        34,
        26,
        0,
        40,
        0,
        16,
        26,
        37,
        24,
        20,
        31,
        6,
        43,
        28
      ],
      "weeklyBackordered": [
        16,
        49,
        20,
        0,
        36,
        46,
        10,
        2,
        31,
        21,
        31,
        19,
        3,
        40,
        0,
        37,
        49,
        28,
        0,
        20,
        48,
        0,
        37,
        28,
        40,
        18,
        6,
        49,
        25,
        49,
        1,
        49,
        44,
        28,
        0,
        0,
        30,
        0,
        46,
        37,
        0,
        15,
        30,
        49,
        21,
        11,
        25,
        8,
        23,
        49,
        49,
        41
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        44,
        39,
        28,
        14,
        25,
        18,
        22,
        35,
        32,
        28,
        26,
        27,
        48,
        20,
        20,
        7,
        49,
        0,
        24,
        10,
        0,
        15,
        2,
        49,
        45,
        43,
        0,
        30,
        9,
        49,
        19,
        6,
        31,
        49,
        35,
        26,
        46,
        0,
        49,
        26,
        9,
        46,
        13,
        8,
        49,
        0,
        20,
        6,
        18,
        18,
        34,
        48
      ],
      "weeklyRejected": [
        35,
        25,
        49,
        47,
        0,
        45,
        18,
        2,
        49,
        44,
        42,
        25,
        20,
        18,
        37,
        0,
        26,
        49,
        49,
        25,
        15,
        18,
        37,
        49,
        6,
        32,
        49,
        49,
        4,
        37,
        49,
        47,
        0,
        30,
        0,
        47,
        49,
        24,
        1,
        6,
        20,
        4,
        0,
        43,
        32,
        0,
        19,
        26,
        0,
        41,
        26,
        41
      ],
      "weeklyHold": [
        39,
        9,
        49,
        23,
        47,
        29,
        41,
        46,
        8,
        41,
        47,
        30,
        26,
        16,
        49,
        13,
        27,
        1,
        18,
        19,
        16,
        32,
        30,
        15,
        32,
        48,
        37,
        18,
        49,
        22,
        48,
        14,
        0,
        4,
        31,
        32,
        13,
        14,
        1,
        38,
        25,
        31,
        0,
        9,
        19,
        49,
        23,
        26,
        34,
        19,
        22,
        34
      ],
      "weeklyBackordered": [
        16,
        5,
        20,
        25,
        49,
        36,
        30,
        31,
        22,
        25,
        15,
        49,
        19,
        26,
        43,
        32,
        44,
        25,
        33,
        20,
        26,
        49,
        19,
        27,
        1,
        24,
        21,
        30,
        19,
        49,
        22,
        39,
        36,
        36,
        28,
        0,
        20,
        0,
        49,
        14,
        48,
        48,
        9,
        49,
        0,
        19,
        30,
        20,
        6,
        28,
        49,
        4
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        0,
        38,
        21,
        7,
        33,
        9,
        40,
        13,
        19,
        6,
        0,
        4,
        23,
        39,
        14,
        0,
        49,
        0,
        31,
        45,
        28,
        6,
        15,
        0,
        0,
        22,
        25,
        49,
        44,
        26,
        0,
        6,
        49,
        24,
        16,
        24,
        22,
        19,
        27,
        16,
        13,
        5,
        49,
        49,
        44,
        3,
        8,
        11,
        23,
        19,
        1,
        0
      ],
      "weeklyRejected": [
        49,
        27,
        27,
        0,
        28,
        43,
        0,
        49,
        49,
        48,
        0,
        38,
        0,
        48,
        48,
        13,
        30,
        49,
        31,
        19,
        49,
        49,
        49,
        0,
        17,
        41,
        45,
        11,
        47,
        19,
        22,
        44,
        38,
        27,
        16,
        39,
        49,
        23,
        22,
        18,
        43,
        14,
        20,
        15,
        49,
        49,
        0,
        33,
        0,
        49,
        2,
        49
      ],
      "weeklyHold": [
        38,
        6,
        16,
        21,
        17,
        11,
        0,
        2,
        33,
        36,
        32,
        16,
        22,
        22,
        41,
        26,
        0,
        0,
        29,
        49,
        17,
        35,
        49,
        24,
        34,
        19,
        49,
        19,
        24,
        6,
        48,
        0,
        0,
        48,
        33,
        11,
        42,
        11,
        29,
        49,
        12,
        33,
        6,
        0,
        41,
        33,
        40,
        49,
        38,
        49,
        9,
        2
      ],
      "weeklyBackordered": [
        30,
        0,
        18,
        23,
        49,
        26,
        41,
        49,
        0,
        0,
        14,
        49,
        40,
        0,
        38,
        10,
        41,
        49,
        0,
        49,
        26,
        49,
        10,
        47,
        8,
        49,
        23,
        36,
        14,
        49,
        0,
        11,
        48,
        10,
        37,
        0,
        23,
        0,
        4,
        0,
        15,
        25,
        0,
        49,
        13,
        1,
        27,
        8,
        36,
        46,
        25,
        13
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        15,
        26,
        49,
        19,
        1,
        19,
        8,
        34,
        48,
        44,
        21,
        0,
        49,
        3,
        1,
        42,
        49,
        35,
        4,
        10,
        27,
        49,
        36,
        49,
        30,
        21,
        19,
        35,
        31,
        37,
        25,
        39,
        49,
        18,
        0,
        40,
        30,
        48,
        44,
        44,
        0,
        0,
        49,
        26,
        49,
        38,
        8,
        2,
        49,
        49,
        5,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        47,
        44,
        26,
        18,
        39,
        0,
        37,
        49,
        27,
        3,
        33,
        48,
        17,
        24,
        47,
        39,
        17,
        35,
        0,
        24,
        0,
        29,
        49,
        25,
        31,
        41,
        43,
        19,
        30,
        48,
        49,
        17,
        49,
        31,
        28,
        9,
        0,
        21,
        21,
        31,
        1,
        49,
        49,
        23,
        4,
        2,
        40,
        34,
        49
      ],
      "weeklyHold": [
        4,
        49,
        30,
        18,
        49,
        22,
        49,
        2,
        45,
        19,
        24,
        43,
        25,
        17,
        31,
        39,
        29,
        48,
        12,
        0,
        27,
        30,
        24,
        34,
        49,
        48,
        41,
        15,
        34,
        49,
        42,
        0,
        0,
        42,
        17,
        25,
        34,
        49,
        25,
        33,
        45,
        49,
        0,
        0,
        5,
        49,
        0,
        14,
        0,
        46,
        18,
        10
      ],
      "weeklyBackordered": [
        2,
        49,
        0,
        32,
        39,
        39,
        0,
        18,
        37,
        1,
        33,
        30,
        3,
        4,
        49,
        1,
        49,
        18,
        0,
        21,
        31,
        46,
        20,
        0,
        39,
        7,
        15,
        9,
        2,
        49,
        0,
        18,
        14,
        22,
        0,
        9,
        35,
        15,
        49,
        1,
        17,
        48,
        0,
        49,
        17,
        34,
        15,
        20,
        31,
        33,
        44,
        15
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        21,
        22,
        17,
        23,
        1,
        31,
        29,
        10,
        37,
        33,
        42,
        12,
        44,
        11,
        15,
        36,
        44,
        48,
        3,
        7,
        29,
        49,
        0,
        48,
        48,
        47,
        36,
        24,
        1,
        46,
        1,
        16,
        27,
        0,
        36,
        42,
        36,
        46,
        6,
        49,
        31,
        42,
        49,
        49,
        35,
        16,
        2,
        0,
        48,
        36,
        6,
        38
      ],
      "weeklyRejected": [
        37,
        8,
        49,
        29,
        22,
        18,
        21,
        18,
        38,
        23,
        49,
        31,
        34,
        36,
        36,
        49,
        13,
        49,
        48,
        23,
        26,
        49,
        10,
        49,
        26,
        37,
        42,
        34,
        27,
        29,
        25,
        47,
        44,
        20,
        5,
        36,
        33,
        4,
        7,
        7,
        41,
        26,
        42,
        28,
        45,
        45,
        0,
        42,
        21,
        17,
        14,
        45
      ],
      "weeklyHold": [
        0,
        0,
        49,
        23,
        47,
        46,
        37,
        2,
        18,
        26,
        47,
        34,
        5,
        27,
        38,
        7,
        49,
        11,
        0,
        30,
        0,
        49,
        40,
        49,
        8,
        25,
        40,
        3,
        49,
        16,
        48,
        48,
        15,
        37,
        33,
        32,
        21,
        25,
        6,
        16,
        8,
        11,
        33,
        38,
        49,
        42,
        32,
        47,
        5,
        41,
        31,
        49
      ],
      "weeklyBackordered": [
        49,
        16,
        0,
        39,
        49,
        46,
        0,
        47,
        0,
        38,
        0,
        49,
        48,
        49,
        46,
        23,
        49,
        34,
        14,
        27,
        49,
        47,
        28,
        40,
        27,
        1,
        28,
        7,
        39,
        20,
        25,
        38,
        9,
        34,
        3,
        32,
        34,
        25,
        34,
        46,
        24,
        31,
        6,
        0,
        25,
        21,
        41,
        1,
        10,
        24,
        49,
        16
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        4,
        49,
        31,
        27,
        15,
        24,
        49,
        9,
        0,
        5,
        47,
        0,
        19,
        0,
        18,
        37,
        49,
        20,
        0,
        46,
        30,
        47,
        20,
        30,
        9,
        25,
        34,
        21,
        25,
        1,
        11,
        25,
        45,
        35,
        43,
        3,
        32,
        24,
        4,
        43,
        28,
        40,
        49,
        49,
        44,
        15,
        0,
        39,
        22,
        32,
        5,
        37
      ],
      "weeklyRejected": [
        49,
        15,
        16,
        43,
        8,
        20,
        49,
        31,
        38,
        1,
        6,
        48,
        14,
        48,
        48,
        25,
        20,
        49,
        18,
        8,
        49,
        41,
        6,
        15,
        39,
        47,
        35,
        9,
        22,
        9,
        44,
        33,
        48,
        18,
        29,
        49,
        26,
        0,
        49,
        26,
        39,
        5,
        24,
        22,
        49,
        13,
        8,
        33,
        5,
        49,
        0,
        39
      ],
      "weeklyHold": [
        40,
        0,
        32,
        29,
        24,
        35,
        25,
        18,
        0,
        14,
        28,
        34,
        48,
        1,
        28,
        0,
        0,
        35,
        1,
        11,
        16,
        30,
        33,
        22,
        21,
        16,
        16,
        23,
        44,
        3,
        43,
        27,
        0,
        23,
        4,
        37,
        18,
        6,
        25,
        49,
        0,
        40,
        19,
        39,
        16,
        28,
        38,
        21,
        13,
        26,
        9,
        49
      ],
      "weeklyBackordered": [
        24,
        12,
        5,
        31,
        38,
        0,
        37,
        32,
        0,
        14,
        49,
        45,
        15,
        25,
        13,
        6,
        45,
        49,
        0,
        12,
        4,
        1,
        0,
        0,
        44,
        27,
        1,
        6,
        5,
        38,
        38,
        21,
        44,
        49,
        0,
        10,
        1,
        0,
        19,
        0,
        0,
        48,
        0,
        28,
        9,
        0,
        30,
        0,
        42,
        11,
        2,
        19
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        44,
        26,
        49,
        46,
        19,
        0,
        30,
        49,
        43,
        0,
        31,
        49,
        33,
        29,
        49,
        10,
        47,
        7,
        28,
        29,
        23,
        0,
        30,
        40,
        0,
        47,
        26,
        0,
        1,
        49,
        48,
        6,
        33,
        4,
        13,
        27,
        21,
        29,
        27,
        49,
        0,
        17,
        49,
        33,
        43,
        0,
        0,
        36,
        13,
        42,
        49,
        48
      ],
      "weeklyRejected": [
        49,
        15,
        41,
        4,
        49,
        49,
        13,
        25,
        30,
        32,
        9,
        49,
        28,
        20,
        45,
        16,
        40,
        49,
        0,
        10,
        12,
        49,
        3,
        22,
        34,
        3,
        43,
        0,
        36,
        0,
        36,
        4,
        40,
        26,
        18,
        46,
        49,
        44,
        20,
        38,
        19,
        21,
        0,
        0,
        46,
        0,
        12,
        17,
        24,
        19,
        0,
        22
      ],
      "weeklyHold": [
        11,
        44,
        40,
        18,
        42,
        43,
        31,
        25,
        31,
        41,
        47,
        33,
        23,
        35,
        0,
        0,
        11,
        27,
        49,
        0,
        12,
        0,
        37,
        6,
        0,
        42,
        12,
        27,
        35,
        0,
        29,
        44,
        0,
        6,
        26,
        0,
        19,
        8,
        45,
        0,
        35,
        30,
        0,
        29,
        35,
        49,
        15,
        0,
        27,
        30,
        6,
        40
      ],
      "weeklyBackordered": [
        49,
        21,
        0,
        40,
        49,
        31,
        37,
        3,
        34,
        0,
        49,
        15,
        3,
        32,
        20,
        49,
        20,
        24,
        2,
        49,
        0,
        17,
        11,
        49,
        1,
        36,
        49,
        44,
        1,
        42,
        27,
        49,
        47,
        19,
        0,
        0,
        11,
        0,
        5,
        49,
        0,
        30,
        20,
        21,
        49,
        49,
        12,
        37,
        1,
        26,
        1,
        34
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        3,
        46,
        15,
        19,
        38,
        5,
        15,
        2,
        47,
        12,
        5,
        0,
        27,
        26,
        28,
        0,
        49,
        8,
        16,
        4,
        6,
        33,
        49,
        11,
        15,
        47,
        21,
        49,
        38,
        28,
        35,
        35,
        47,
        39,
        42,
        20,
        0,
        8,
        0,
        49,
        49,
        42,
        49,
        49,
        49,
        0,
        49,
        19,
        0,
        16,
        17,
        27
      ],
      "weeklyRejected": [
        20,
        17,
        24,
        39,
        10,
        0,
        23,
        6,
        38,
        16,
        49,
        0,
        9,
        8,
        2,
        17,
        0,
        49,
        49,
        28,
        0,
        49,
        33,
        40,
        0,
        49,
        29,
        25,
        48,
        27,
        41,
        2,
        8,
        33,
        24,
        49,
        49,
        36,
        14,
        49,
        44,
        40,
        37,
        0,
        37,
        13,
        0,
        11,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        0,
        38,
        0,
        17,
        7,
        11,
        39,
        18,
        26,
        12,
        38,
        42,
        37,
        49,
        25,
        18,
        17,
        6,
        0,
        9,
        40,
        34,
        0,
        33,
        0,
        39,
        28,
        4,
        20,
        21,
        48,
        0,
        0,
        49,
        0,
        38,
        36,
        18,
        2,
        38,
        0,
        33,
        18,
        36,
        23,
        42,
        16,
        34,
        0,
        40,
        49,
        18
      ],
      "weeklyBackordered": [
        0,
        0,
        21,
        41,
        49,
        46,
        40,
        45,
        49,
        8,
        40,
        24,
        5,
        0,
        49,
        47,
        9,
        28,
        0,
        32,
        28,
        43,
        49,
        49,
        1,
        4,
        28,
        0,
        47,
        24,
        42,
        49,
        26,
        31,
        9,
        20,
        4,
        1,
        4,
        49,
        4,
        48,
        4,
        49,
        45,
        22,
        48,
        30,
        0,
        49,
        7,
        16
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        7,
        49,
        49,
        25,
        1,
        35,
        8,
        34,
        48,
        19,
        49,
        2,
        1,
        11,
        33,
        32,
        28,
        19,
        0,
        20,
        27,
        27,
        34,
        38,
        41,
        44,
        21,
        5,
        1,
        49,
        22,
        12,
        49,
        0,
        48,
        33,
        36,
        36,
        43,
        49,
        36,
        49,
        13,
        17,
        49,
        22,
        19,
        1,
        5,
        49,
        3,
        14
      ],
      "weeklyRejected": [
        49,
        11,
        42,
        43,
        0,
        4,
        38,
        47,
        31,
        16,
        40,
        37,
        26,
        48,
        26,
        33,
        0,
        49,
        27,
        5,
        46,
        0,
        13,
        44,
        35,
        38,
        30,
        13,
        48,
        13,
        19,
        47,
        41,
        49,
        49,
        8,
        30,
        17,
        1,
        27,
        15,
        49,
        37,
        10,
        49,
        48,
        0,
        49,
        12,
        34,
        0,
        41
      ],
      "weeklyHold": [
        39,
        38,
        36,
        16,
        0,
        39,
        4,
        2,
        18,
        23,
        23,
        44,
        24,
        31,
        31,
        0,
        21,
        37,
        28,
        33,
        28,
        13,
        26,
        16,
        19,
        11,
        49,
        32,
        32,
        9,
        16,
        0,
        7,
        20,
        2,
        31,
        46,
        3,
        42,
        25,
        1,
        49,
        19,
        49,
        16,
        16,
        42,
        41,
        23,
        0,
        5,
        38
      ],
      "weeklyBackordered": [
        0,
        49,
        0,
        22,
        39,
        32,
        34,
        26,
        43,
        10,
        42,
        49,
        9,
        0,
        48,
        38,
        1,
        49,
        25,
        12,
        7,
        19,
        35,
        38,
        28,
        6,
        32,
        6,
        1,
        49,
        39,
        49,
        25,
        42,
        0,
        0,
        25,
        9,
        21,
        35,
        10,
        48,
        42,
        49,
        28,
        26,
        23,
        13,
        39,
        22,
        42,
        29
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        5,
        3,
        49,
        0,
        29,
        10,
        45,
        2,
        44,
        37,
        15,
        0,
        40,
        49,
        27,
        5,
        49,
        3,
        49,
        0,
        18,
        27,
        49,
        41,
        1,
        14,
        0,
        21,
        17,
        25,
        34,
        19,
        39,
        49,
        28,
        25,
        34,
        24,
        11,
        49,
        2,
        37,
        49,
        49,
        4,
        5,
        48,
        8,
        0,
        9,
        49,
        48
      ],
      "weeklyRejected": [
        49,
        4,
        4,
        31,
        6,
        6,
        48,
        40,
        21,
        1,
        24,
        5,
        8,
        45,
        40,
        1,
        1,
        28,
        38,
        3,
        49,
        45,
        49,
        10,
        37,
        49,
        40,
        11,
        48,
        14,
        18,
        21,
        32,
        20,
        37,
        49,
        22,
        30,
        1,
        35,
        37,
        49,
        40,
        1,
        34,
        24,
        28,
        20,
        10,
        46,
        15,
        35
      ],
      "weeklyHold": [
        14,
        41,
        25,
        1,
        0,
        0,
        23,
        24,
        8,
        26,
        36,
        39,
        1,
        31,
        19,
        10,
        20,
        22,
        35,
        42,
        49,
        18,
        8,
        0,
        45,
        48,
        25,
        0,
        35,
        15,
        43,
        0,
        20,
        22,
        17,
        49,
        31,
        2,
        22,
        49,
        46,
        41,
        9,
        28,
        22,
        1,
        49,
        47,
        30,
        44,
        25,
        48
      ],
      "weeklyBackordered": [
        30,
        28,
        0,
        49,
        47,
        46,
        49,
        42,
        31,
        25,
        0,
        43,
        31,
        0,
        23,
        16,
        13,
        38,
        28,
        0,
        0,
        20,
        4,
        16,
        1,
        26,
        31,
        49,
        1,
        26,
        31,
        49,
        11,
        26,
        4,
        0,
        1,
        0,
        49,
        9,
        34,
        48,
        15,
        49,
        32,
        14,
        0,
        0,
        23,
        49,
        40,
        17
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        9,
        41,
        0,
        48,
        25,
        14,
        45,
        48,
        43,
        22,
        23,
        8,
        18,
        4,
        26,
        0,
        49,
        30,
        0,
        25,
        7,
        21,
        31,
        31,
        9,
        26,
        48,
        49,
        1,
        48,
        47,
        2,
        49,
        0,
        47,
        49,
        25,
        13,
        42,
        49,
        34,
        39,
        49,
        38,
        34,
        22,
        8,
        40,
        0,
        31,
        49,
        42
      ],
      "weeklyRejected": [
        46,
        18,
        49,
        34,
        37,
        43,
        29,
        0,
        49,
        1,
        16,
        0,
        26,
        44,
        32,
        12,
        49,
        37,
        22,
        41,
        1,
        47,
        20,
        6,
        0,
        29,
        44,
        0,
        44,
        9,
        19,
        47,
        48,
        34,
        39,
        36,
        21,
        17,
        8,
        49,
        29,
        49,
        49,
        8,
        6,
        36,
        21,
        31,
        25,
        47,
        0,
        36
      ],
      "weeklyHold": [
        26,
        13,
        0,
        1,
        42,
        14,
        10,
        25,
        40,
        46,
        47,
        15,
        48,
        1,
        19,
        33,
        12,
        23,
        40,
        26,
        19,
        18,
        0,
        24,
        12,
        13,
        7,
        20,
        23,
        24,
        45,
        0,
        0,
        44,
        48,
        19,
        4,
        3,
        32,
        25,
        27,
        28,
        0,
        49,
        49,
        49,
        42,
        20,
        15,
        11,
        44,
        49
      ],
      "weeklyBackordered": [
        38,
        49,
        0,
        11,
        13,
        33,
        30,
        32,
        35,
        0,
        49,
        49,
        38,
        26,
        29,
        49,
        49,
        49,
        23,
        41,
        24,
        2,
        25,
        31,
        1,
        49,
        8,
        42,
        30,
        39,
        17,
        44,
        37,
        46,
        0,
        16,
        15,
        0,
        28,
        34,
        27,
        8,
        15,
        49,
        40,
        0,
        33,
        16,
        4,
        35,
        14,
        32
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        0,
        38,
        22,
        17,
        29,
        0,
        21,
        21,
        41,
        0,
        36,
        1,
        18,
        49,
        45,
        33,
        49,
        13,
        0,
        23,
        19,
        42,
        34,
        48,
        38,
        31,
        49,
        0,
        24,
        31,
        19,
        20,
        49,
        20,
        19,
        2,
        24,
        3,
        49,
        49,
        24,
        18,
        49,
        41,
        35,
        0,
        22,
        1,
        9,
        33,
        9,
        22
      ],
      "weeklyRejected": [
        15,
        22,
        36,
        17,
        44,
        39,
        48,
        5,
        32,
        25,
        14,
        30,
        25,
        34,
        42,
        36,
        0,
        49,
        20,
        3,
        25,
        0,
        17,
        0,
        8,
        13,
        35,
        49,
        43,
        48,
        49,
        17,
        34,
        26,
        29,
        49,
        16,
        40,
        28,
        49,
        28,
        49,
        44,
        30,
        26,
        49,
        28,
        0,
        2,
        13,
        1,
        28
      ],
      "weeklyHold": [
        19,
        37,
        49,
        20,
        13,
        20,
        40,
        2,
        14,
        15,
        17,
        1,
        25,
        49,
        37,
        41,
        9,
        13,
        21,
        22,
        49,
        49,
        27,
        6,
        49,
        31,
        5,
        38,
        17,
        14,
        39,
        0,
        0,
        21,
        48,
        3,
        26,
        49,
        10,
        42,
        25,
        28,
        7,
        26,
        35,
        49,
        15,
        49,
        9,
        21,
        38,
        42
      ],
      "weeklyBackordered": [
        7,
        0,
        0,
        11,
        37,
        46,
        35,
        34,
        35,
        24,
        46,
        32,
        24,
        28,
        38,
        33,
        17,
        10,
        0,
        0,
        0,
        15,
        14,
        38,
        1,
        26,
        1,
        49,
        18,
        49,
        26,
        28,
        41,
        25,
        0,
        0,
        1,
        0,
        32,
        32,
        26,
        48,
        5,
        21,
        20,
        28,
        49,
        34,
        10,
        49,
        49,
        11
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        0,
        15,
        49,
        0,
        23,
        0,
        16,
        49,
        48,
        23,
        49,
        46,
        19,
        0,
        1,
        49,
        38,
        24,
        17,
        18,
        19,
        21,
        3,
        49,
        34,
        6,
        26,
        41,
        36,
        38,
        47,
        0,
        30,
        20,
        38,
        35,
        20,
        27,
        49,
        49,
        34,
        43,
        49,
        41,
        24,
        0,
        16,
        0,
        9,
        44,
        20,
        19
      ],
      "weeklyRejected": [
        45,
        0,
        20,
        7,
        24,
        19,
        32,
        0,
        40,
        17,
        0,
        40,
        49,
        0,
        19,
        49,
        26,
        39,
        47,
        22,
        45,
        40,
        29,
        49,
        6,
        49,
        43,
        12,
        48,
        32,
        43,
        25,
        48,
        3,
        13,
        49,
        26,
        40,
        49,
        0,
        36,
        49,
        30,
        18,
        36,
        47,
        13,
        38,
        13,
        46,
        0,
        49
      ],
      "weeklyHold": [
        28,
        11,
        49,
        1,
        24,
        8,
        31,
        27,
        18,
        20,
        12,
        49,
        18,
        18,
        0,
        0,
        8,
        0,
        13,
        25,
        16,
        49,
        28,
        10,
        1,
        30,
        31,
        0,
        15,
        49,
        48,
        3,
        0,
        21,
        48,
        31,
        13,
        37,
        13,
        0,
        24,
        28,
        13,
        49,
        49,
        49,
        45,
        37,
        0,
        37,
        0,
        49
      ],
      "weeklyBackordered": [
        45,
        21,
        1,
        49,
        44,
        46,
        0,
        21,
        44,
        20,
        49,
        28,
        30,
        6,
        27,
        20,
        45,
        42,
        18,
        49,
        24,
        9,
        12,
        10,
        38,
        16,
        16,
        49,
        1,
        19,
        20,
        4,
        15,
        49,
        0,
        0,
        30,
        23,
        19,
        8,
        47,
        39,
        9,
        40,
        39,
        26,
        49,
        0,
        0,
        23,
        34,
        29
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyAccepted": [
        42,
        48,
        1,
        0,
        29,
        8,
        1,
        42,
        48,
        9,
        12,
        0,
        16,
        29,
        47,
        39,
        49,
        7,
        21,
        42,
        24,
        0,
        37,
        19,
        19,
        47,
        0,
        1,
        17,
        49,
        26,
        23,
        37,
        28,
        43,
        24,
        1,
        14,
        49,
        49,
        36,
        41,
        25,
        18,
        47,
        0,
        27,
        42,
        7,
        41,
        27,
        13
      ],
      "weeklyRejected": [
        49,
        14,
        31,
        16,
        49,
        21,
        49,
        13,
        20,
        46,
        46,
        35,
        13,
        40,
        48,
        4,
        20,
        44,
        37,
        16,
        0,
        1,
        14,
        20,
        17,
        49,
        44,
        25,
        42,
        17,
        33,
        1,
        36,
        49,
        17,
        49,
        19,
        42,
        1,
        0,
        48,
        29,
        49,
        11,
        7,
        23,
        4,
        15,
        23,
        24,
        0,
        49
      ],
      "weeklyHold": [
        19,
        9,
        44,
        17,
        19,
        39,
        49,
        44,
        0,
        5,
        20,
        25,
        2,
        19,
        38,
        34,
        30,
        0,
        7,
        35,
        46,
        18,
        12,
        49,
        25,
        38,
        39,
        10,
        41,
        22,
        48,
        45,
        18,
        4,
        48,
        14,
        49,
        4,
        29,
        29,
        13,
        24,
        0,
        23,
        49,
        25,
        40,
        49,
        31,
        37,
        20,
        7
      ],
      "weeklyBackordered": [
        0,
        0,
        0,
        27,
        49,
        0,
        0,
        36,
        7,
        16,
        7,
        49,
        28,
        31,
        39,
        45,
        45,
        30,
        0,
        27,
        9,
        11,
        21,
        23,
        10,
        21,
        34,
        12,
        29,
        43,
        3,
        45,
        26,
        40,
        9,
        0,
        1,
        13,
        45,
        17,
        28,
        48,
        5,
        3,
        0,
        44,
        48,
        39,
        5,
        15,
        25,
        1
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        12,
        40,
        18,
        37,
        27,
        0,
        10,
        47,
        25,
        0,
        39,
        32,
        47,
        41,
        24,
        49,
        49,
        1,
        16,
        0,
        15,
        27,
        21,
        16,
        12,
        18,
        0,
        9,
        14,
        18,
        15,
        20,
        46,
        31,
        48,
        14,
        0,
        23,
        7,
        20,
        6,
        19,
        23,
        14,
        22,
        11,
        8,
        13,
        0,
        37,
        48,
        9
      ],
      "weeklyRejected": [
        23,
        49,
        10,
        32,
        25,
        0,
        36,
        29,
        20,
        29,
        36,
        23,
        49,
        39,
        12,
        15,
        6,
        49,
        49,
        29,
        10,
        17,
        10,
        20,
        0,
        49,
        26,
        10,
        45,
        48,
        23,
        47,
        13,
        0,
        22,
        49,
        49,
        47,
        29,
        14,
        48,
        49,
        26,
        1,
        22,
        12,
        0,
        37,
        24,
        49,
        0,
        27
      ],
      "weeklyHold": [
        0,
        49,
        49,
        28,
        49,
        38,
        29,
        3,
        28,
        30,
        41,
        31,
        10,
        37,
        8,
        49,
        49,
        28,
        0,
        40,
        29,
        26,
        31,
        3,
        8,
        48,
        43,
        34,
        49,
        2,
        48,
        0,
        21,
        49,
        23,
        38,
        18,
        0,
        27,
        21,
        40,
        12,
        45,
        20,
        34,
        49,
        5,
        9,
        2,
        49,
        37,
        13
      ],
      "weeklyBackordered": [
        38,
        22,
        0,
        12,
        23,
        46,
        39,
        33,
        6,
        28,
        0,
        47,
        49,
        20,
        0,
        30,
        40,
        0,
        21,
        36,
        27,
        49,
        43,
        43,
        48,
        6,
        26,
        38,
        35,
        49,
        49,
        49,
        34,
        33,
        0,
        0,
        25,
        43,
        15,
        23,
        36,
        48,
        25,
        49,
        0,
        19,
        14,
        23,
        39,
        41,
        20,
        5
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        12,
        49,
        16,
        38,
        3,
        0,
        5,
        2,
        48,
        49,
        30,
        0,
        17,
        19,
        8,
        10,
        45,
        0,
        0,
        46,
        7,
        49,
        35,
        0,
        0,
        47,
        34,
        31,
        4,
        2,
        5,
        28,
        49,
        4,
        12,
        37,
        20,
        28,
        40,
        49,
        15,
        8,
        48,
        34,
        25,
        18,
        24,
        0,
        6,
        31,
        1,
        30
      ],
      "weeklyRejected": [
        38,
        34,
        49,
        47,
        43,
        11,
        49,
        49,
        33,
        31,
        0,
        0,
        0,
        31,
        29,
        0,
        0,
        49,
        39,
        1,
        36,
        12,
        14,
        47,
        7,
        49,
        31,
        49,
        48,
        36,
        49,
        32,
        25,
        15,
        45,
        49,
        22,
        29,
        1,
        25,
        38,
        46,
        49,
        49,
        49,
        47,
        8,
        35,
        0,
        49,
        8,
        35
      ],
      "weeklyHold": [
        8,
        22,
        26,
        15,
        33,
        27,
        49,
        27,
        5,
        41,
        46,
        7,
        47,
        8,
        30,
        17,
        16,
        19,
        13,
        33,
        0,
        47,
        0,
        9,
        2,
        33,
        26,
        0,
        29,
        42,
        31,
        16,
        15,
        49,
        32,
        44,
        47,
        0,
        49,
        5,
        1,
        27,
        30,
        0,
        15,
        35,
        46,
        36,
        21,
        2,
        49,
        40
      ],
      "weeklyBackordered": [
        22,
        14,
        41,
        22,
        43,
        46,
        0,
        28,
        9,
        9,
        25,
        49,
        35,
        32,
        49,
        1,
        46,
        47,
        0,
        0,
        14,
        32,
        15,
        5,
        7,
        49,
        1,
        20,
        21,
        23,
        49,
        49,
        13,
        40,
        6,
        0,
        46,
        0,
        30,
        7,
        48,
        48,
        31,
        33,
        0,
        26,
        40,
        37,
        36,
        31,
        2,
        49
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyAccepted": [
        8,
        46,
        32,
        45,
        49,
        1,
        13,
        48,
        17,
        10,
        49,
        23,
        31,
        49,
        48,
        30,
        24,
        5,
        0,
        49,
        39,
        42,
        44,
        48,
        40,
        31,
        8,
        34,
        8,
        5,
        23,
        19,
        37,
        14,
        48,
        13,
        31,
        48,
        28,
        49,
        0,
        5,
        37,
        21,
        28,
        16,
        6,
        10,
        0,
        25,
        49,
        42
      ],
      "weeklyRejected": [
        49,
        48,
        35,
        35,
        15,
        30,
        29,
        49,
        35,
        34,
        0,
        40,
        31,
        48,
        48,
        4,
        22,
        49,
        28,
        28,
        42,
        0,
        43,
        19,
        48,
        49,
        44,
        14,
        43,
        48,
        42,
        27,
        48,
        17,
        37,
        28,
        13,
        20,
        1,
        27,
        19,
        30,
        0,
        38,
        38,
        49,
        19,
        0,
        0,
        49,
        0,
        19
      ],
      "weeklyHold": [
        23,
        32,
        30,
        4,
        0,
        36,
        32,
        26,
        0,
        49,
        20,
        1,
        35,
        24,
        30,
        10,
        49,
        48,
        32,
        14,
        43,
        6,
        28,
        6,
        46,
        41,
        0,
        44,
        49,
        15,
        26,
        0,
        2,
        7,
        41,
        49,
        49,
        5,
        42,
        49,
        49,
        37,
        7,
        49,
        49,
        45,
        9,
        15,
        6,
        35,
        32,
        19
      ],
      "weeklyBackordered": [
        47,
        24,
        0,
        24,
        49,
        46,
        37,
        49,
        49,
        20,
        1,
        49,
        3,
        37,
        47,
        2,
        31,
        0,
        28,
        0,
        29,
        0,
        31,
        0,
        19,
        28,
        32,
        24,
        5,
        49,
        27,
        12,
        48,
        49,
        18,
        0,
        1,
        3,
        19,
        49,
        27,
        38,
        18,
        49,
        0,
        0,
        30,
        0,
        30,
        29,
        26,
        37
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        0,
        46,
        26,
        36,
        25,
        0,
        25,
        2,
        46,
        0,
        40,
        22,
        12,
        42,
        49,
        36,
        39,
        17,
        0,
        49,
        22,
        13,
        32,
        32,
        15,
        0,
        32,
        18,
        5,
        49,
        11,
        10,
        49,
        5,
        44,
        2,
        37,
        18,
        49,
        49,
        1,
        37,
        21,
        39,
        49,
        33,
        19,
        37,
        0,
        16,
        24,
        12
      ],
      "weeklyRejected": [
        22,
        0,
        49,
        47,
        37,
        14,
        1,
        27,
        45,
        2,
        33,
        33,
        42,
        2,
        32,
        19,
        48,
        45,
        31,
        49,
        16,
        27,
        13,
        43,
        17,
        13,
        3,
        24,
        48,
        33,
        49,
        47,
        31,
        22,
        16,
        25,
        5,
        46,
        35,
        36,
        26,
        49,
        34,
        0,
        49,
        49,
        0,
        15,
        5,
        49,
        11,
        12
      ],
      "weeklyHold": [
        49,
        0,
        40,
        12,
        17,
        7,
        38,
        2,
        0,
        5,
        33,
        34,
        8,
        16,
        49,
        0,
        17,
        0,
        5,
        17,
        8,
        16,
        0,
        9,
        0,
        48,
        19,
        16,
        49,
        15,
        48,
        0,
        9,
        0,
        48,
        0,
        30,
        37,
        20,
        3,
        32,
        16,
        22,
        35,
        43,
        42,
        6,
        28,
        22,
        18,
        10,
        36
      ],
      "weeklyBackordered": [
        15,
        49,
        0,
        48,
        14,
        46,
        49,
        24,
        49,
        20,
        49,
        25,
        9,
        0,
        46,
        32,
        28,
        35,
        0,
        41,
        11,
        0,
        0,
        49,
        20,
        1,
        1,
        26,
        42,
        23,
        21,
        49,
        31,
        49,
        0,
        25,
        2,
        0,
        45,
        46,
        48,
        48,
        29,
        49,
        27,
        38,
        49,
        6,
        5,
        27,
        30,
        32
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        0,
        37,
        25,
        30,
        1,
        0,
        18,
        31,
        43,
        27,
        0,
        21,
        15,
        8,
        18,
        19,
        30,
        11,
        12,
        0,
        49,
        49,
        32,
        38,
        20,
        42,
        20,
        49,
        49,
        36,
        3,
        9,
        49,
        30,
        48,
        49,
        47,
        0,
        10,
        43,
        21,
        39,
        33,
        20,
        49,
        12,
        0,
        49,
        23,
        30,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        24,
        19,
        41,
        32,
        49,
        10,
        17,
        34,
        1,
        23,
        30,
        28,
        45,
        45,
        25,
        16,
        29,
        34,
        29,
        18,
        49,
        9,
        44,
        49,
        49,
        0,
        36,
        26,
        31,
        45,
        47,
        13,
        31,
        0,
        49,
        33,
        49,
        20,
        42,
        9,
        46,
        35,
        9,
        49,
        8,
        24,
        37,
        1,
        27,
        0,
        22
      ],
      "weeklyHold": [
        20,
        33,
        25,
        1,
        35,
        22,
        46,
        13,
        41,
        19,
        42,
        35,
        35,
        1,
        40,
        29,
        19,
        12,
        0,
        14,
        20,
        39,
        43,
        28,
        21,
        24,
        8,
        17,
        34,
        24,
        7,
        17,
        1,
        39,
        42,
        0,
        26,
        0,
        22,
        27,
        49,
        38,
        28,
        35,
        49,
        7,
        10,
        9,
        42,
        34,
        14,
        30
      ],
      "weeklyBackordered": [
        35,
        9,
        8,
        49,
        48,
        34,
        49,
        40,
        49,
        7,
        10,
        42,
        25,
        26,
        41,
        7,
        7,
        36,
        0,
        27,
        19,
        0,
        9,
        49,
        31,
        29,
        8,
        0,
        25,
        38,
        10,
        45,
        31,
        44,
        8,
        19,
        30,
        0,
        48,
        0,
        1,
        48,
        0,
        44,
        26,
        46,
        21,
        10,
        43,
        49,
        36,
        30
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        25,
        48,
        25,
        35,
        1,
        20,
        1,
        24,
        48,
        19,
        33,
        11,
        11,
        49,
        24,
        11,
        20,
        9,
        47,
        25,
        27,
        49,
        0,
        46,
        2,
        23,
        48,
        5,
        38,
        47,
        31,
        36,
        49,
        3,
        48,
        34,
        16,
        16,
        35,
        49,
        0,
        28,
        22,
        25,
        38,
        0,
        16,
        12,
        22,
        31,
        10,
        3
      ],
      "weeklyRejected": [
        17,
        17,
        17,
        23,
        49,
        27,
        49,
        31,
        33,
        28,
        12,
        20,
        40,
        48,
        48,
        12,
        39,
        49,
        28,
        0,
        42,
        49,
        6,
        31,
        5,
        49,
        33,
        9,
        30,
        12,
        49,
        25,
        28,
        49,
        29,
        49,
        7,
        0,
        11,
        0,
        22,
        49,
        48,
        11,
        40,
        10,
        1,
        0,
        25,
        24,
        20,
        41
      ],
      "weeklyHold": [
        32,
        43,
        49,
        15,
        15,
        30,
        30,
        13,
        0,
        8,
        30,
        3,
        25,
        8,
        42,
        29,
        13,
        35,
        3,
        12,
        30,
        0,
        16,
        1,
        49,
        31,
        49,
        0,
        46,
        15,
        48,
        29,
        0,
        9,
        4,
        43,
        0,
        0,
        23,
        21,
        9,
        26,
        7,
        9,
        29,
        40,
        0,
        40,
        2,
        23,
        30,
        44
      ],
      "weeklyBackordered": [
        32,
        14,
        0,
        49,
        49,
        20,
        7,
        1,
        49,
        32,
        49,
        49,
        3,
        49,
        14,
        43,
        30,
        18,
        5,
        0,
        30,
        45,
        28,
        23,
        20,
        26,
        25,
        12,
        6,
        49,
        10,
        36,
        42,
        43,
        0,
        16,
        17,
        48,
        10,
        25,
        5,
        46,
        0,
        49,
        0,
        22,
        47,
        21,
        15,
        0,
        1,
        35
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        0,
        33,
        14,
        0,
        44,
        0,
        1,
        18,
        1,
        49,
        46,
        0,
        16,
        7,
        34,
        41,
        41,
        5,
        49,
        0,
        49,
        27,
        42,
        17,
        45,
        45,
        48,
        40,
        17,
        9,
        8,
        0,
        9,
        0,
        48,
        32,
        24,
        44,
        37,
        29,
        12,
        38,
        49,
        39,
        49,
        0,
        0,
        0,
        0,
        0,
        13,
        32
      ],
      "weeklyRejected": [
        49,
        22,
        22,
        8,
        21,
        23,
        35,
        34,
        17,
        1,
        2,
        17,
        25,
        43,
        31,
        38,
        21,
        47,
        38,
        0,
        25,
        49,
        49,
        49,
        32,
        9,
        13,
        30,
        40,
        43,
        0,
        22,
        24,
        37,
        21,
        49,
        19,
        20,
        17,
        17,
        13,
        0,
        26,
        11,
        45,
        25,
        11,
        12,
        1,
        21,
        42,
        22
      ],
      "weeklyHold": [
        0,
        43,
        15,
        24,
        48,
        25,
        13,
        30,
        15,
        11,
        14,
        44,
        27,
        26,
        10,
        39,
        7,
        32,
        25,
        1,
        12,
        1,
        23,
        0,
        0,
        1,
        34,
        29,
        24,
        12,
        37,
        15,
        0,
        9,
        40,
        36,
        8,
        14,
        31,
        19,
        45,
        24,
        0,
        42,
        29,
        12,
        2,
        13,
        0,
        49,
        48,
        34
      ],
      "weeklyBackordered": [
        20,
        49,
        32,
        27,
        41,
        36,
        37,
        37,
        21,
        9,
        49,
        27,
        49,
        4,
        29,
        41,
        18,
        49,
        3,
        29,
        34,
        18,
        16,
        30,
        1,
        49,
        29,
        3,
        1,
        40,
        0,
        24,
        37,
        0,
        0,
        0,
        47,
        0,
        13,
        49,
        0,
        30,
        26,
        45,
        10,
        41,
        0,
        12,
        0,
        43,
        16,
        19
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyAccepted": [
        0,
        28,
        49,
        25,
        39,
        0,
        28,
        49,
        48,
        14,
        49,
        6,
        49,
        46,
        12,
        33,
        47,
        3,
        49,
        32,
        49,
        33,
        35,
        40,
        33,
        47,
        15,
        0,
        7,
        19,
        0,
        5,
        36,
        29,
        13,
        49,
        47,
        48,
        49,
        33,
        12,
        6,
        49,
        46,
        49,
        0,
        0,
        49,
        49,
        25,
        46,
        38
      ],
      "weeklyRejected": [
        49,
        23,
        32,
        18,
        5,
        17,
        0,
        49,
        0,
        24,
        22,
        44,
        36,
        17,
        30,
        39,
        5,
        49,
        11,
        31,
        44,
        0,
        47,
        49,
        46,
        30,
        49,
        19,
        47,
        8,
        0,
        47,
        48,
        1,
        32,
        49,
        19,
        32,
        34,
        29,
        23,
        29,
        31,
        10,
        48,
        23,
        40,
        22,
        0,
        25,
        31,
        23
      ],
      "weeklyHold": [
        40,
        49,
        42,
        17,
        49,
        46,
        0,
        27,
        14,
        49,
        29,
        38,
        48,
        49,
        0,
        7,
        0,
        28,
        1,
        30,
        17,
        29,
        49,
        12,
        35,
        48,
        0,
        1,
        4,
        18,
        48,
        17,
        0,
        14,
        27,
        39,
        49,
        16,
        49,
        13,
        47,
        38,
        28,
        37,
        49,
        49,
        45,
        9,
        19,
        14,
        18,
        13
      ],
      "weeklyBackordered": [
        46,
        21,
        0,
        29,
        49,
        30,
        29,
        26,
        43,
        0,
        42,
        13,
        14,
        41,
        0,
        19,
        49,
        27,
        27,
        49,
        17,
        34,
        9,
        18,
        27,
        28,
        8,
        0,
        28,
        49,
        28,
        48,
        0,
        16,
        0,
        20,
        8,
        22,
        11,
        49,
        0,
        43,
        8,
        34,
        40,
        49,
        11,
        13,
        24,
        49,
        49,
        12
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        18,
        41,
        23,
        30,
        2,
        0,
        12,
        22,
        41,
        18,
        8,
        0,
        49,
        24,
        35,
        49,
        45,
        0,
        0,
        49,
        26,
        37,
        21,
        11,
        14,
        47,
        21,
        21,
        49,
        6,
        17,
        46,
        2,
        8,
        36,
        2,
        0,
        16,
        21,
        40,
        26,
        49,
        49,
        29,
        49,
        10,
        14,
        47,
        12,
        0,
        35,
        9
      ],
      "weeklyRejected": [
        12,
        22,
        17,
        44,
        22,
        18,
        29,
        49,
        49,
        48,
        49,
        49,
        41,
        16,
        48,
        0,
        19,
        49,
        49,
        22,
        13,
        19,
        5,
        13,
        12,
        32,
        14,
        23,
        39,
        4,
        39,
        14,
        17,
        25,
        7,
        49,
        36,
        49,
        9,
        17,
        31,
        48,
        0,
        26,
        49,
        41,
        0,
        33,
        0,
        49,
        0,
        22
      ],
      "weeklyHold": [
        13,
        10,
        36,
        1,
        14,
        31,
        26,
        36,
        5,
        26,
        42,
        1,
        25,
        1,
        41,
        0,
        0,
        9,
        8,
        2,
        46,
        26,
        49,
        0,
        49,
        33,
        26,
        49,
        44,
        0,
        27,
        5,
        0,
        0,
        28,
        38,
        33,
        0,
        1,
        4,
        17,
        16,
        8,
        36,
        49,
        35,
        2,
        49,
        0,
        49,
        33,
        49
      ],
      "weeklyBackordered": [
        0,
        19,
        0,
        49,
        49,
        34,
        16,
        31,
        25,
        20,
        20,
        42,
        24,
        9,
        43,
        1,
        49,
        49,
        12,
        28,
        27,
        0,
        10,
        49,
        20,
        44,
        22,
        19,
        31,
        36,
        37,
        38,
        31,
        12,
        8,
        0,
        13,
        25,
        38,
        0,
        37,
        48,
        16,
        49,
        15,
        9,
        49,
        38,
        49,
        49,
        31,
        1
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        0,
        28,
        15,
        33,
        37,
        5,
        8,
        20,
        19,
        49,
        36,
        35,
        19,
        49,
        11,
        3,
        26,
        9,
        0,
        24,
        18,
        46,
        5,
        49,
        8,
        31,
        46,
        15,
        4,
        23,
        39,
        0,
        49,
        35,
        19,
        29,
        47,
        16,
        34,
        49,
        11,
        49,
        49,
        45,
        29,
        19,
        15,
        31,
        37,
        21,
        18,
        27
      ],
      "weeklyRejected": [
        32,
        21,
        22,
        35,
        24,
        22,
        49,
        18,
        49,
        1,
        41,
        4,
        15,
        35,
        10,
        37,
        27,
        0,
        48,
        21,
        41,
        45,
        20,
        32,
        19,
        41,
        0,
        18,
        48,
        0,
        35,
        17,
        48,
        49,
        36,
        49,
        41,
        13,
        1,
        17,
        0,
        30,
        49,
        0,
        42,
        7,
        25,
        25,
        3,
        32,
        33,
        0
      ],
      "weeklyHold": [
        14,
        29,
        20,
        26,
        49,
        33,
        30,
        8,
        22,
        14,
        11,
        4,
        36,
        5,
        41,
        16,
        14,
        3,
        9,
        0,
        11,
        10,
        10,
        0,
        16,
        48,
        27,
        7,
        49,
        5,
        48,
        48,
        0,
        21,
        15,
        27,
        11,
        0,
        48,
        37,
        40,
        40,
        9,
        49,
        26,
        49,
        43,
        0,
        10,
        29,
        34,
        16
      ],
      "weeklyBackordered": [
        48,
        29,
        27,
        33,
        29,
        21,
        36,
        49,
        39,
        0,
        30,
        30,
        11,
        32,
        26,
        49,
        43,
        49,
        0,
        28,
        49,
        35,
        11,
        21,
        19,
        25,
        1,
        12,
        47,
        49,
        49,
        37,
        36,
        26,
        0,
        0,
        49,
        0,
        7,
        36,
        25,
        37,
        0,
        49,
        3,
        27,
        17,
        35,
        0,
        4,
        41,
        13
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        27,
        14,
        31,
        48,
        15,
        39,
        13,
        45,
        43,
        35,
        43,
        23,
        28,
        36,
        28,
        0,
        44,
        0,
        13,
        49,
        0,
        13,
        12,
        37,
        0,
        0,
        26,
        25,
        47,
        49,
        49,
        12,
        26,
        5,
        48,
        8,
        32,
        42,
        1,
        27,
        14,
        49,
        39,
        12,
        19,
        32,
        15,
        33,
        0,
        21,
        7,
        22
      ],
      "weeklyRejected": [
        38,
        10,
        3,
        0,
        49,
        44,
        20,
        48,
        49,
        3,
        0,
        39,
        37,
        22,
        48,
        12,
        49,
        42,
        13,
        19,
        24,
        45,
        0,
        23,
        43,
        0,
        4,
        42,
        39,
        13,
        28,
        7,
        34,
        49,
        31,
        49,
        48,
        0,
        1,
        23,
        20,
        49,
        9,
        2,
        49,
        14,
        46,
        30,
        0,
        27,
        0,
        26
      ],
      "weeklyHold": [
        14,
        18,
        42,
        38,
        12,
        43,
        22,
        19,
        0,
        35,
        25,
        12,
        17,
        5,
        16,
        49,
        27,
        42,
        12,
        2,
        26,
        21,
        49,
        33,
        49,
        48,
        23,
        0,
        11,
        16,
        25,
        44,
        11,
        9,
        39,
        28,
        11,
        0,
        49,
        47,
        49,
        17,
        16,
        35,
        34,
        40,
        17,
        28,
        41,
        19,
        10,
        4
      ],
      "weeklyBackordered": [
        39,
        42,
        37,
        8,
        44,
        46,
        30,
        46,
        49,
        6,
        21,
        49,
        3,
        43,
        17,
        8,
        29,
        31,
        0,
        22,
        38,
        0,
        20,
        21,
        7,
        13,
        10,
        30,
        19,
        37,
        34,
        12,
        34,
        44,
        0,
        12,
        1,
        48,
        32,
        9,
        40,
        28,
        9,
        21,
        11,
        2,
        9,
        29,
        0,
        0,
        4,
        13
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyAccepted": [
        6,
        16,
        12,
        24,
        42,
        24,
        12,
        49,
        43,
        2,
        49,
        29,
        36,
        29,
        1,
        0,
        27,
        22,
        0,
        0,
        12,
        6,
        33,
        29,
        37,
        46,
        26,
        2,
        11,
        30,
        29,
        19,
        33,
        8,
        30,
        3,
        6,
        38,
        49,
        35,
        8,
        28,
        41,
        36,
        49,
        14,
        0,
        21,
        24,
        47,
        7,
        48
      ],
      "weeklyRejected": [
        49,
        3,
        49,
        23,
        49,
        10,
        49,
        36,
        20,
        48,
        21,
        31,
        28,
        38,
        42,
        24,
        14,
        45,
        17,
        36,
        15,
        49,
        17,
        0,
        17,
        49,
        49,
        32,
        39,
        13,
        43,
        34,
        19,
        34,
        14,
        37,
        49,
        20,
        8,
        4,
        31,
        49,
        49,
        28,
        49,
        42,
        0,
        36,
        0,
        28,
        10,
        20
      ],
      "weeklyHold": [
        0,
        49,
        49,
        1,
        24,
        29,
        40,
        31,
        23,
        26,
        17,
        49,
        24,
        49,
        21,
        8,
        20,
        24,
        0,
        0,
        27,
        0,
        26,
        16,
        41,
        16,
        49,
        8,
        49,
        0,
        42,
        36,
        49,
        42,
        29,
        18,
        0,
        19,
        38,
        45,
        6,
        26,
        0,
        9,
        15,
        5,
        22,
        10,
        16,
        31,
        49,
        27
      ],
      "weeklyBackordered": [
        0,
        37,
        0,
        42,
        49,
        46,
        26,
        49,
        31,
        0,
        29,
        49,
        46,
        34,
        33,
        27,
        27,
        48,
        13,
        27,
        9,
        0,
        35,
        1,
        17,
        23,
        5,
        32,
        1,
        49,
        26,
        7,
        31,
        10,
        0,
        13,
        26,
        35,
        5,
        20,
        0,
        47,
        19,
        49,
        15,
        13,
        28,
        35,
        26,
        49,
        1,
        1
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        0,
        48,
        44,
        33,
        1,
        1,
        11,
        22,
        29,
        7,
        49,
        12,
        29,
        7,
        16,
        15,
        25,
        34,
        26,
        40,
        40,
        49,
        49,
        38,
        9,
        33,
        14,
        10,
        49,
        49,
        15,
        16,
        49,
        10,
        35,
        49,
        47,
        36,
        8,
        20,
        48,
        29,
        15,
        26,
        27,
        29,
        2,
        43,
        7,
        32,
        4,
        35
      ],
      "weeklyRejected": [
        23,
        1,
        49,
        21,
        49,
        19,
        46,
        0,
        31,
        10,
        12,
        23,
        13,
        33,
        19,
        29,
        42,
        30,
        36,
        49,
        33,
        41,
        26,
        49,
        34,
        49,
        8,
        7,
        42,
        28,
        39,
        47,
        48,
        16,
        13,
        35,
        49,
        1,
        4,
        17,
        39,
        25,
        33,
        0,
        31,
        49,
        24,
        17,
        13,
        14,
        31,
        46
      ],
      "weeklyHold": [
        22,
        26,
        49,
        13,
        24,
        45,
        7,
        2,
        44,
        25,
        35,
        18,
        32,
        19,
        49,
        30,
        17,
        24,
        23,
        0,
        45,
        4,
        40,
        49,
        3,
        39,
        36,
        19,
        35,
        0,
        43,
        2,
        5,
        17,
        35,
        0,
        49,
        0,
        49,
        32,
        16,
        40,
        21,
        14,
        6,
        49,
        26,
        49,
        18,
        0,
        31,
        46
      ],
      "weeklyBackordered": [
        29,
        19,
        0,
        12,
        49,
        12,
        16,
        46,
        36,
        5,
        49,
        9,
        11,
        16,
        40,
        26,
        49,
        49,
        8,
        5,
        14,
        25,
        0,
        15,
        1,
        37,
        2,
        12,
        4,
        42,
        17,
        40,
        25,
        44,
        0,
        0,
        11,
        14,
        37,
        30,
        0,
        25,
        6,
        32,
        37,
        31,
        46,
        25,
        6,
        36,
        41,
        49
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        0,
        37,
        31,
        43,
        49,
        7,
        1,
        7,
        45,
        49,
        40,
        11,
        36,
        30,
        11,
        19,
        19,
        19,
        49,
        47,
        0,
        49,
        26,
        37,
        17,
        47,
        27,
        25,
        49,
        31,
        25,
        40,
        49,
        29,
        27,
        42,
        47,
        12,
        49,
        49,
        25,
        46,
        19,
        32,
        48,
        1,
        24,
        0,
        0,
        47,
        1,
        20
      ],
      "weeklyRejected": [
        38,
        21,
        5,
        28,
        26,
        0,
        49,
        49,
        49,
        31,
        0,
        49,
        34,
        18,
        21,
        21,
        0,
        47,
        34,
        11,
        0,
        21,
        49,
        49,
        22,
        49,
        0,
        0,
        40,
        2,
        44,
        30,
        19,
        49,
        16,
        46,
        26,
        39,
        8,
        14,
        31,
        42,
        49,
        37,
        31,
        39,
        29,
        0,
        13,
        49,
        0,
        49
      ],
      "weeklyHold": [
        32,
        0,
        27,
        1,
        29,
        12,
        46,
        2,
        0,
        11,
        40,
        44,
        36,
        9,
        18,
        26,
        28,
        20,
        22,
        14,
        49,
        49,
        6,
        32,
        46,
        16,
        49,
        29,
        49,
        13,
        41,
        7,
        6,
        26,
        5,
        20,
        22,
        22,
        36,
        43,
        14,
        33,
        9,
        44,
        12,
        27,
        26,
        24,
        0,
        12,
        39,
        45
      ],
      "weeklyBackordered": [
        19,
        5,
        41,
        22,
        49,
        37,
        22,
        39,
        14,
        27,
        49,
        33,
        33,
        49,
        36,
        36,
        49,
        49,
        14,
        29,
        44,
        39,
        31,
        0,
        2,
        20,
        5,
        0,
        5,
        40,
        12,
        26,
        0,
        42,
        12,
        0,
        40,
        0,
        0,
        2,
        22,
        48,
        4,
        41,
        36,
        31,
        31,
        24,
        0,
        18,
        32,
        16
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        4,
        49,
        16,
        27,
        19,
        0,
        20,
        49,
        48,
        23,
        38,
        0,
        37,
        18,
        31,
        28,
        49,
        36,
        0,
        43,
        18,
        20,
        26,
        33,
        30,
        47,
        49,
        33,
        42,
        33,
        38,
        14,
        49,
        31,
        39,
        10,
        47,
        32,
        20,
        49,
        46,
        47,
        47,
        17,
        32,
        4,
        0,
        16,
        7,
        49,
        14,
        7
      ],
      "weeklyRejected": [
        49,
        0,
        34,
        4,
        49,
        9,
        49,
        49,
        12,
        1,
        27,
        24,
        49,
        6,
        26,
        29,
        8,
        49,
        11,
        37,
        35,
        15,
        0,
        49,
        35,
        42,
        49,
        29,
        26,
        27,
        31,
        33,
        48,
        49,
        49,
        49,
        29,
        0,
        33,
        22,
        48,
        22,
        49,
        11,
        32,
        19,
        24,
        33,
        11,
        24,
        49,
        19
      ],
      "weeklyHold": [
        49,
        19,
        32,
        18,
        47,
        23,
        40,
        2,
        0,
        31,
        47,
        20,
        42,
        23,
        14,
        3,
        16,
        1,
        0,
        0,
        15,
        37,
        27,
        11,
        3,
        41,
        24,
        0,
        24,
        15,
        48,
        32,
        20,
        0,
        30,
        15,
        0,
        0,
        32,
        3,
        28,
        49,
        11,
        48,
        15,
        40,
        28,
        34,
        17,
        7,
        29,
        49
      ],
      "weeklyBackordered": [
        4,
        47,
        2,
        49,
        32,
        46,
        33,
        20,
        46,
        4,
        25,
        49,
        3,
        43,
        18,
        41,
        49,
        35,
        19,
        24,
        44,
        25,
        0,
        0,
        17,
        13,
        17,
        43,
        27,
        29,
        45,
        48,
        44,
        28,
        0,
        10,
        35,
        26,
        0,
        21,
        0,
        48,
        40,
        49,
        30,
        10,
        8,
        36,
        21,
        35,
        42,
        11
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        2,
        39,
        27,
        10,
        1,
        7,
        1,
        42,
        18,
        23,
        18,
        0,
        36,
        23,
        1,
        6,
        33,
        0,
        3,
        31,
        0,
        29,
        31,
        13,
        37,
        47,
        41,
        28,
        1,
        49,
        32,
        30,
        49,
        21,
        48,
        48,
        47,
        17,
        49,
        49,
        15,
        0,
        3,
        43,
        49,
        3,
        5,
        12,
        23,
        38,
        4,
        29
      ],
      "weeklyRejected": [
        36,
        8,
        21,
        33,
        49,
        49,
        49,
        15,
        38,
        13,
        23,
        25,
        15,
        48,
        33,
        13,
        49,
        38,
        49,
        26,
        26,
        18,
        0,
        27,
        14,
        9,
        26,
        0,
        0,
        16,
        0,
        27,
        18,
        13,
        20,
        49,
        36,
        49,
        1,
        11,
        11,
        13,
        26,
        28,
        49,
        28,
        11,
        38,
        20,
        30,
        0,
        37
      ],
      "weeklyHold": [
        10,
        0,
        43,
        12,
        49,
        48,
        26,
        2,
        0,
        32,
        18,
        12,
        18,
        14,
        49,
        29,
        21,
        10,
        36,
        40,
        0,
        19,
        49,
        41,
        19,
        48,
        49,
        49,
        49,
        21,
        48,
        17,
        19,
        49,
        31,
        49,
        23,
        31,
        33,
        14,
        31,
        30,
        16,
        15,
        26,
        37,
        0,
        0,
        0,
        0,
        0,
        25
      ],
      "weeklyBackordered": [
        7,
        49,
        27,
        49,
        23,
        35,
        32,
        21,
        6,
        0,
        21,
        34,
        48,
        0,
        29,
        49,
        17,
        49,
        11,
        49,
        0,
        19,
        15,
        17,
        19,
        25,
        1,
        19,
        1,
        27,
        33,
        27,
        32,
        23,
        7,
        0,
        33,
        7,
        28,
        30,
        48,
        32,
        15,
        49,
        34,
        6,
        18,
        31,
        0,
        7,
        41,
        38
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        32,
        35,
        15,
        12,
        1,
        23,
        6,
        33,
        45,
        24,
        37,
        0,
        38,
        30,
        34,
        29,
        46,
        29,
        8,
        8,
        0,
        17,
        19,
        22,
        5,
        19,
        25,
        0,
        38,
        21,
        16,
        1,
        44,
        28,
        45,
        7,
        13,
        16,
        0,
        49,
        26,
        11,
        49,
        0,
        7,
        0,
        0,
        23,
        2,
        7,
        38,
        25
      ],
      "weeklyRejected": [
        49,
        30,
        21,
        47,
        20,
        40,
        41,
        11,
        29,
        18,
        0,
        20,
        5,
        29,
        48,
        37,
        33,
        33,
        35,
        26,
        23,
        33,
        37,
        24,
        10,
        49,
        49,
        49,
        37,
        1,
        49,
        47,
        26,
        26,
        22,
        49,
        29,
        45,
        10,
        14,
        48,
        13,
        25,
        18,
        49,
        0,
        0,
        2,
        29,
        43,
        29,
        49
      ],
      "weeklyHold": [
        36,
        30,
        49,
        45,
        5,
        38,
        34,
        2,
        32,
        5,
        28,
        8,
        35,
        18,
        28,
        4,
        21,
        0,
        29,
        5,
        1,
        49,
        4,
        37,
        36,
        47,
        10,
        4,
        49,
        11,
        31,
        17,
        0,
        30,
        48,
        22,
        10,
        32,
        1,
        35,
        49,
        49,
        41,
        30,
        49,
        0,
        46,
        49,
        20,
        27,
        48,
        3
      ],
      "weeklyBackordered": [
        0,
        16,
        0,
        46,
        37,
        46,
        26,
        49,
        49,
        2,
        45,
        0,
        3,
        46,
        34,
        35,
        49,
        38,
        0,
        0,
        23,
        29,
        8,
        49,
        32,
        14,
        18,
        33,
        49,
        49,
        41,
        49,
        41,
        44,
        0,
        10,
        2,
        12,
        33,
        41,
        33,
        28,
        22,
        17,
        26,
        0,
        12,
        8,
        15,
        33,
        44,
        16
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        0,
        37,
        32,
        47,
        39,
        0,
        7,
        48,
        48,
        0,
        49,
        0,
        49,
        29,
        16,
        20,
        42,
        23,
        27,
        0,
        13,
        49,
        3,
        49,
        14,
        35,
        14,
        12,
        36,
        18,
        20,
        41,
        17,
        8,
        43,
        9,
        21,
        16,
        33,
        31,
        18,
        14,
        30,
        36,
        49,
        12,
        0,
        34,
        13,
        0,
        14,
        31
      ],
      "weeklyRejected": [
        49,
        17,
        18,
        45,
        49,
        32,
        48,
        5,
        49,
        1,
        19,
        19,
        40,
        32,
        25,
        31,
        21,
        48,
        27,
        0,
        33,
        16,
        37,
        6,
        21,
        49,
        49,
        8,
        45,
        10,
        49,
        31,
        25,
        49,
        45,
        19,
        37,
        44,
        22,
        47,
        13,
        25,
        10,
        37,
        34,
        31,
        30,
        26,
        44,
        17,
        6,
        49
      ],
      "weeklyHold": [
        0,
        15,
        40,
        1,
        0,
        24,
        32,
        2,
        35,
        11,
        0,
        27,
        24,
        38,
        5,
        9,
        2,
        26,
        26,
        0,
        0,
        49,
        12,
        36,
        0,
        18,
        49,
        49,
        26,
        0,
        48,
        0,
        0,
        42,
        5,
        8,
        35,
        49,
        27,
        31,
        49,
        33,
        15,
        49,
        11,
        30,
        34,
        15,
        0,
        28,
        39,
        22
      ],
      "weeklyBackordered": [
        26,
        22,
        0,
        45,
        13,
        17,
        49,
        30,
        23,
        47,
        19,
        23,
        35,
        0,
        27,
        35,
        27,
        44,
        10,
        18,
        0,
        18,
        2,
        0,
        19,
        13,
        28,
        15,
        1,
        38,
        0,
        24,
        20,
        0,
        0,
        6,
        49,
        8,
        41,
        49,
        0,
        47,
        8,
        49,
        43,
        49,
        28,
        17,
        43,
        38,
        38,
        28
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        35,
        37,
        30,
        48,
        46,
        40,
        7,
        30,
        26,
        49,
        33,
        0,
        34,
        31,
        49,
        1,
        12,
        5,
        0,
        49,
        21,
        25,
        14,
        49,
        3,
        47,
        18,
        27,
        10,
        29,
        21,
        17,
        15,
        34,
        33,
        19,
        38,
        18,
        15,
        29,
        2,
        34,
        34,
        40,
        0,
        11,
        4,
        12,
        32,
        37,
        15,
        26
      ],
      "weeklyRejected": [
        38,
        8,
        20,
        6,
        21,
        18,
        49,
        10,
        11,
        13,
        5,
        19,
        49,
        48,
        44,
        16,
        9,
        38,
        42,
        0,
        0,
        49,
        26,
        49,
        27,
        33,
        37,
        13,
        28,
        7,
        47,
        30,
        28,
        11,
        49,
        49,
        49,
        10,
        1,
        49,
        42,
        46,
        10,
        7,
        22,
        44,
        0,
        48,
        2,
        49,
        2,
        49
      ],
      "weeklyHold": [
        49,
        0,
        49,
        13,
        27,
        0,
        21,
        2,
        24,
        12,
        29,
        1,
        27,
        15,
        32,
        32,
        49,
        39,
        0,
        25,
        6,
        25,
        49,
        49,
        12,
        48,
        0,
        0,
        22,
        15,
        25,
        16,
        34,
        35,
        48,
        27,
        49,
        10,
        47,
        39,
        0,
        33,
        0,
        22,
        25,
        25,
        20,
        4,
        4,
        2,
        24,
        30
      ],
      "weeklyBackordered": [
        0,
        0,
        11,
        4,
        16,
        15,
        49,
        48,
        27,
        0,
        0,
        32,
        49,
        14,
        25,
        9,
        1,
        23,
        6,
        0,
        49,
        30,
        27,
        45,
        10,
        8,
        49,
        31,
        40,
        49,
        49,
        38,
        40,
        49,
        0,
        13,
        1,
        0,
        0,
        19,
        0,
        0,
        46,
        5,
        0,
        23,
        19,
        15,
        25,
        0,
        38,
        36
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        9,
        49,
        30,
        40,
        9,
        38,
        9,
        40,
        45,
        27,
        42,
        0,
        0,
        9,
        12,
        49,
        35,
        48,
        8,
        38,
        7,
        49,
        29,
        46,
        30,
        30,
        34,
        2,
        1,
        49,
        18,
        20,
        49,
        41,
        22,
        34,
        17,
        48,
        43,
        33,
        35,
        24,
        42,
        43,
        49,
        32,
        20,
        38,
        6,
        6,
        45,
        22
      ],
      "weeklyRejected": [
        42,
        34,
        22,
        15,
        2,
        39,
        2,
        25,
        42,
        48,
        44,
        0,
        42,
        11,
        34,
        15,
        29,
        43,
        49,
        14,
        18,
        31,
        25,
        14,
        0,
        38,
        47,
        29,
        18,
        18,
        24,
        15,
        0,
        49,
        31,
        49,
        21,
        25,
        30,
        15,
        12,
        47,
        13,
        0,
        49,
        33,
        21,
        0,
        0,
        38,
        26,
        16
      ],
      "weeklyHold": [
        16,
        17,
        33,
        19,
        4,
        46,
        31,
        17,
        8,
        14,
        43,
        46,
        40,
        6,
        23,
        49,
        24,
        20,
        16,
        12,
        0,
        3,
        0,
        27,
        49,
        48,
        26,
        15,
        33,
        9,
        39,
        23,
        26,
        39,
        0,
        17,
        49,
        9,
        13,
        35,
        22,
        49,
        7,
        0,
        41,
        31,
        30,
        49,
        0,
        23,
        22,
        49
      ],
      "weeklyBackordered": [
        21,
        22,
        0,
        7,
        19,
        26,
        32,
        2,
        42,
        33,
        20,
        16,
        7,
        49,
        40,
        49,
        49,
        0,
        0,
        45,
        28,
        24,
        34,
        7,
        45,
        21,
        28,
        14,
        20,
        20,
        6,
        41,
        32,
        48,
        0,
        0,
        32,
        7,
        41,
        49,
        8,
        36,
        5,
        49,
        28,
        0,
        49,
        43,
        0,
        29,
        49,
        49
      ]
    },
    {
      "id": "FB70040E",
      "weeklyAccepted": [
        24,
        48,
        33,
        33,
        14,
        0,
        1,
        49,
        37,
        39,
        49,
        19,
        19,
        17,
        10,
        19,
        49,
        14,
        0,
        0,
        48,
        48,
        49,
        49,
        41,
        28,
        0,
        31,
        49,
        11,
        49,
        3,
        38,
        7,
        48,
        41,
        14,
        21,
        0,
        28,
        23,
        37,
        49,
        22,
        49,
        0,
        29,
        0,
        31,
        0,
        40,
        48
      ],
      "weeklyRejected": [
        39,
        20,
        49,
        47,
        5,
        8,
        31,
        9,
        39,
        32,
        24,
        47,
        40,
        29,
        29,
        0,
        35,
        26,
        0,
        0,
        49,
        47,
        36,
        24,
        16,
        13,
        20,
        1,
        48,
        30,
        37,
        25,
        32,
        49,
        0,
        49,
        36,
        15,
        1,
        17,
        38,
        0,
        29,
        26,
        39,
        0,
        29,
        22,
        13,
        40,
        11,
        45
      ],
      "weeklyHold": [
        6,
        13,
        35,
        18,
        49,
        31,
        31,
        19,
        27,
        34,
        5,
        36,
        31,
        49,
        31,
        37,
        9,
        15,
        44,
        23,
        4,
        30,
        8,
        8,
        14,
        1,
        43,
        10,
        30,
        36,
        36,
        24,
        0,
        28,
        25,
        14,
        21,
        0,
        31,
        36,
        37,
        40,
        0,
        32,
        40,
        24,
        0,
        25,
        12,
        0,
        25,
        18
      ],
      "weeklyBackordered": [
        16,
        37,
        0,
        44,
        48,
        12,
        30,
        47,
        27,
        26,
        30,
        46,
        3,
        29,
        26,
        26,
        49,
        44,
        0,
        39,
        19,
        0,
        16,
        29,
        1,
        24,
        25,
        2,
        11,
        44,
        17,
        45,
        17,
        29,
        0,
        0,
        4,
        13,
        9,
        6,
        11,
        48,
        6,
        49,
        27,
        47,
        34,
        13,
        19,
        0,
        22,
        4
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        21,
        49,
        21,
        48,
        3,
        0,
        8,
        38,
        12,
        8,
        15,
        21,
        42,
        32,
        49,
        21,
        49,
        0,
        0,
        20,
        42,
        8,
        21,
        16,
        30,
        26,
        21,
        45,
        40,
        49,
        0,
        27,
        49,
        5,
        16,
        2,
        36,
        48,
        49,
        40,
        14,
        6,
        49,
        46,
        49,
        5,
        0,
        40,
        6,
        25,
        38,
        26
      ],
      "weeklyRejected": [
        31,
        33,
        29,
        15,
        49,
        31,
        32,
        49,
        32,
        26,
        36,
        34,
        30,
        38,
        48,
        14,
        40,
        43,
        49,
        0,
        1,
        49,
        2,
        49,
        6,
        49,
        31,
        29,
        15,
        0,
        37,
        47,
        31,
        30,
        6,
        49,
        38,
        35,
        46,
        0,
        43,
        25,
        17,
        0,
        37,
        16,
        10,
        47,
        0,
        25,
        1,
        1
      ],
      "weeklyHold": [
        20,
        33,
        29,
        18,
        37,
        30,
        31,
        2,
        10,
        18,
        0,
        37,
        24,
        31,
        39,
        26,
        19,
        3,
        0,
        18,
        25,
        12,
        1,
        18,
        41,
        1,
        22,
        21,
        28,
        24,
        39,
        8,
        0,
        34,
        35,
        0,
        38,
        18,
        39,
        32,
        24,
        16,
        11,
        18,
        4,
        36,
        15,
        6,
        12,
        43,
        14,
        49
      ],
      "weeklyBackordered": [
        0,
        20,
        18,
        0,
        49,
        36,
        25,
        45,
        0,
        27,
        45,
        49,
        7,
        25,
        1,
        34,
        37,
        49,
        0,
        27,
        38,
        33,
        0,
        39,
        19,
        29,
        13,
        49,
        49,
        31,
        48,
        30,
        40,
        47,
        7,
        11,
        21,
        26,
        25,
        36,
        24,
        26,
        1,
        13,
        48,
        28,
        49,
        33,
        0,
        37,
        1,
        4
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        25,
        47,
        8,
        0,
        19,
        0,
        1,
        49,
        37,
        49,
        33,
        40,
        49,
        43,
        15,
        19,
        49,
        0,
        0,
        45,
        39,
        0,
        36,
        45,
        30,
        0,
        19,
        0,
        44,
        28,
        23,
        0,
        49,
        20,
        5,
        35,
        15,
        48,
        17,
        10,
        19,
        24,
        49,
        49,
        18,
        0,
        49,
        13,
        0,
        18,
        1,
        34
      ],
      "weeklyRejected": [
        49,
        13,
        49,
        47,
        29,
        7,
        14,
        29,
        0,
        1,
        0,
        32,
        18,
        48,
        2,
        33,
        0,
        49,
        13,
        10,
        16,
        23,
        49,
        49,
        28,
        49,
        0,
        27,
        43,
        2,
        5,
        30,
        48,
        49,
        15,
        49,
        49,
        34,
        1,
        49,
        40,
        49,
        34,
        0,
        49,
        5,
        17,
        0,
        0,
        35,
        0,
        11
      ],
      "weeklyHold": [
        0,
        0,
        30,
        24,
        24,
        48,
        22,
        23,
        18,
        24,
        40,
        49,
        22,
        44,
        2,
        0,
        32,
        48,
        10,
        22,
        33,
        0,
        26,
        17,
        27,
        48,
        10,
        0,
        31,
        10,
        48,
        0,
        17,
        33,
        15,
        35,
        49,
        8,
        18,
        14,
        3,
        14,
        5,
        0,
        23,
        9,
        37,
        49,
        12,
        18,
        45,
        26
      ],
      "weeklyBackordered": [
        49,
        29,
        0,
        13,
        49,
        46,
        49,
        22,
        49,
        0,
        0,
        49,
        3,
        49,
        24,
        34,
        43,
        49,
        25,
        34,
        49,
        25,
        10,
        33,
        13,
        6,
        34,
        46,
        22,
        49,
        28,
        49,
        0,
        44,
        0,
        0,
        1,
        23,
        2,
        48,
        21,
        48,
        0,
        47,
        17,
        43,
        29,
        24,
        15,
        0,
        20,
        43
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        0,
        49,
        18,
        37,
        21,
        11,
        1,
        38,
        31,
        0,
        49,
        44,
        14,
        49,
        1,
        20,
        49,
        24,
        17,
        2,
        49,
        23,
        0,
        30,
        15,
        33,
        20,
        12,
        1,
        49,
        29,
        19,
        49,
        34,
        43,
        42,
        0,
        9,
        11,
        7,
        44,
        27,
        49,
        5,
        49,
        17,
        12,
        37,
        31,
        47,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        31,
        47,
        49,
        0,
        14,
        28,
        47,
        11,
        16,
        5,
        49,
        1,
        31,
        23,
        15,
        26,
        41,
        49,
        3,
        34,
        0,
        20,
        0,
        33,
        36,
        16,
        48,
        0,
        41,
        15,
        47,
        49,
        24,
        49,
        48,
        14,
        22,
        42,
        48,
        24,
        2,
        18,
        49,
        0,
        11,
        8,
        0,
        49,
        0,
        13
      ],
      "weeklyHold": [
        25,
        0,
        31,
        31,
        18,
        20,
        22,
        36,
        37,
        43,
        46,
        34,
        16,
        49,
        30,
        0,
        0,
        0,
        0,
        0,
        0,
        34,
        0,
        44,
        0,
        48,
        31,
        2,
        15,
        0,
        48,
        41,
        0,
        38,
        39,
        2,
        12,
        30,
        35,
        0,
        13,
        4,
        24,
        21,
        25,
        33,
        38,
        49,
        2,
        44,
        40,
        32
      ],
      "weeklyBackordered": [
        6,
        32,
        21,
        21,
        41,
        30,
        39,
        48,
        20,
        27,
        19,
        0,
        6,
        29,
        7,
        27,
        24,
        30,
        0,
        25,
        31,
        1,
        26,
        32,
        6,
        1,
        21,
        19,
        48,
        21,
        49,
        47,
        35,
        49,
        0,
        0,
        30,
        20,
        41,
        22,
        22,
        48,
        22,
        20,
        33,
        41,
        37,
        15,
        0,
        49,
        49,
        15
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        0,
        31,
        26,
        22,
        35,
        49,
        20,
        10,
        48,
        23,
        34,
        0,
        24,
        49,
        28,
        34,
        17,
        11,
        0,
        37,
        0,
        35,
        28,
        46,
        7,
        20,
        28,
        49,
        34,
        11,
        6,
        19,
        34,
        36,
        23,
        16,
        47,
        2,
        21,
        41,
        20,
        43,
        49,
        31,
        49,
        47,
        6,
        49,
        36,
        49,
        0,
        27
      ],
      "weeklyRejected": [
        49,
        18,
        31,
        24,
        49,
        3,
        49,
        15,
        34,
        41,
        0,
        28,
        2,
        30,
        31,
        42,
        0,
        18,
        49,
        3,
        24,
        32,
        22,
        36,
        11,
        49,
        29,
        4,
        2,
        16,
        40,
        30,
        36,
        35,
        30,
        49,
        49,
        0,
        1,
        15,
        48,
        28,
        48,
        49,
        35,
        33,
        0,
        49,
        3,
        41,
        0,
        13
      ],
      "weeklyHold": [
        1,
        0,
        49,
        1,
        49,
        10,
        38,
        5,
        35,
        13,
        0,
        9,
        48,
        22,
        35,
        26,
        4,
        31,
        29,
        8,
        36,
        28,
        7,
        15,
        16,
        34,
        24,
        21,
        39,
        1,
        31,
        0,
        0,
        39,
        48,
        40,
        41,
        43,
        42,
        49,
        21,
        46,
        30,
        44,
        1,
        49,
        26,
        22,
        6,
        46,
        49,
        22
      ],
      "weeklyBackordered": [
        13,
        1,
        20,
        42,
        49,
        46,
        31,
        43,
        25,
        26,
        40,
        3,
        28,
        0,
        28,
        18,
        14,
        49,
        3,
        10,
        33,
        18,
        0,
        40,
        1,
        11,
        38,
        9,
        21,
        48,
        49,
        25,
        0,
        43,
        0,
        3,
        1,
        17,
        0,
        11,
        0,
        48,
        36,
        49,
        5,
        17,
        49,
        10,
        46,
        0,
        34,
        6
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        6,
        39,
        26,
        40,
        49,
        6,
        10,
        30,
        48,
        49,
        32,
        27,
        33,
        31,
        2,
        49,
        45,
        12,
        0,
        21,
        1,
        15,
        49,
        49,
        12,
        18,
        0,
        0,
        20,
        45,
        32,
        0,
        18,
        10,
        23,
        9,
        0,
        34,
        34,
        49,
        1,
        49,
        49,
        9,
        8,
        29,
        17,
        10,
        0,
        49,
        45,
        23
      ],
      "weeklyRejected": [
        49,
        23,
        47,
        31,
        19,
        48,
        35,
        35,
        33,
        40,
        44,
        26,
        48,
        32,
        48,
        35,
        0,
        49,
        30,
        38,
        48,
        23,
        25,
        13,
        23,
        49,
        0,
        43,
        40,
        31,
        49,
        19,
        45,
        45,
        16,
        22,
        39,
        19,
        10,
        35,
        34,
        39,
        21,
        0,
        48,
        27,
        0,
        48,
        0,
        43,
        13,
        37
      ],
      "weeklyHold": [
        0,
        29,
        49,
        1,
        0,
        33,
        48,
        31,
        0,
        21,
        38,
        40,
        31,
        34,
        19,
        1,
        0,
        45,
        8,
        30,
        49,
        26,
        26,
        49,
        42,
        22,
        39,
        41,
        29,
        48,
        46,
        40,
        0,
        17,
        3,
        19,
        18,
        20,
        38,
        4,
        25,
        15,
        27,
        27,
        45,
        18,
        26,
        23,
        0,
        19,
        37,
        37
      ],
      "weeklyBackordered": [
        49,
        35,
        24,
        43,
        49,
        18,
        49,
        27,
        21,
        0,
        36,
        49,
        37,
        40,
        22,
        1,
        49,
        22,
        30,
        36,
        30,
        49,
        24,
        42,
        29,
        1,
        11,
        28,
        27,
        49,
        24,
        7,
        9,
        7,
        30,
        0,
        28,
        9,
        45,
        0,
        11,
        48,
        2,
        49,
        7,
        49,
        49,
        35,
        8,
        9,
        46,
        1
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        0,
        47,
        13,
        48,
        31,
        46,
        25,
        25,
        11,
        49,
        30,
        0,
        46,
        35,
        19,
        21,
        43,
        0,
        5,
        31,
        15,
        19,
        27,
        3,
        14,
        18,
        45,
        48,
        14,
        49,
        13,
        9,
        38,
        27,
        25,
        49,
        22,
        9,
        10,
        27,
        39,
        24,
        9,
        49,
        2,
        37,
        13,
        49,
        49,
        49,
        13,
        3
      ],
      "weeklyRejected": [
        49,
        0,
        11,
        14,
        19,
        3,
        48,
        21,
        49,
        1,
        5,
        30,
        46,
        48,
        30,
        45,
        22,
        49,
        36,
        7,
        17,
        5,
        47,
        49,
        49,
        28,
        31,
        8,
        35,
        18,
        49,
        43,
        27,
        28,
        22,
        36,
        34,
        25,
        1,
        34,
        31,
        40,
        37,
        49,
        44,
        42,
        0,
        0,
        0,
        49,
        0,
        46
      ],
      "weeklyHold": [
        45,
        6,
        38,
        16,
        34,
        37,
        10,
        23,
        0,
        5,
        47,
        12,
        33,
        28,
        43,
        9,
        0,
        8,
        5,
        3,
        26,
        12,
        8,
        18,
        0,
        29,
        38,
        15,
        28,
        27,
        48,
        5,
        16,
        28,
        36,
        27,
        29,
        0,
        10,
        49,
        8,
        26,
        0,
        41,
        14,
        0,
        36,
        48,
        11,
        0,
        25,
        26
      ],
      "weeklyBackordered": [
        22,
        0,
        23,
        49,
        28,
        40,
        47,
        15,
        2,
        11,
        49,
        49,
        26,
        0,
        0,
        41,
        5,
        22,
        15,
        19,
        38,
        31,
        31,
        49,
        48,
        15,
        28,
        49,
        18,
        26,
        45,
        13,
        31,
        49,
        22,
        0,
        1,
        5,
        30,
        0,
        48,
        48,
        10,
        49,
        0,
        38,
        49,
        25,
        27,
        21,
        45,
        26
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        49,
        32,
        1,
        19,
        13,
        46,
        41,
        2,
        24,
        0,
        34,
        17,
        49,
        0,
        15,
        34,
        35,
        28,
        7,
        46,
        24,
        37,
        16,
        35,
        0,
        47,
        6,
        49,
        13,
        49,
        27,
        22,
        42,
        39,
        37,
        23,
        30,
        17,
        12,
        29,
        0,
        38,
        17,
        21,
        39,
        32,
        8,
        35,
        46,
        49,
        43,
        0
      ],
      "weeklyRejected": [
        7,
        32,
        2,
        31,
        49,
        27,
        43,
        27,
        49,
        1,
        1,
        2,
        1,
        48,
        39,
        4,
        22,
        25,
        37,
        49,
        0,
        34,
        11,
        0,
        14,
        34,
        49,
        49,
        13,
        38,
        43,
        27,
        27,
        49,
        7,
        49,
        25,
        24,
        1,
        28,
        18,
        2,
        8,
        16,
        25,
        12,
        6,
        14,
        0,
        31,
        0,
        49
      ],
      "weeklyHold": [
        2,
        3,
        49,
        1,
        2,
        48,
        26,
        35,
        29,
        23,
        37,
        49,
        39,
        2,
        27,
        16,
        49,
        9,
        17,
        2,
        24,
        36,
        41,
        40,
        24,
        30,
        22,
        49,
        31,
        21,
        41,
        6,
        17,
        41,
        9,
        18,
        24,
        4,
        34,
        35,
        22,
        20,
        9,
        15,
        27,
        48,
        36,
        49,
        33,
        0,
        8,
        41
      ],
      "weeklyBackordered": [
        29,
        0,
        7,
        39,
        31,
        46,
        6,
        8,
        8,
        2,
        41,
        36,
        31,
        0,
        0,
        49,
        28,
        25,
        19,
        41,
        44,
        30,
        17,
        49,
        24,
        21,
        25,
        27,
        16,
        39,
        0,
        34,
        0,
        44,
        0,
        26,
        49,
        5,
        26,
        24,
        39,
        48,
        3,
        16,
        2,
        27,
        49,
        33,
        1,
        49,
        49,
        13
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        0,
        49,
        33,
        32,
        22,
        41,
        1,
        46,
        7,
        49,
        21,
        10,
        49,
        49,
        19,
        24,
        49,
        25,
        0,
        49,
        13,
        49,
        10,
        44,
        20,
        38,
        0,
        12,
        23,
        42,
        12,
        0,
        49,
        0,
        28,
        21,
        31,
        48,
        49,
        49,
        25,
        11,
        49,
        0,
        40,
        42,
        0,
        34,
        28,
        16,
        47,
        36
      ],
      "weeklyRejected": [
        45,
        11,
        35,
        36,
        26,
        0,
        15,
        49,
        36,
        35,
        49,
        38,
        49,
        25,
        13,
        31,
        38,
        39,
        28,
        15,
        0,
        16,
        2,
        47,
        15,
        23,
        31,
        5,
        48,
        0,
        0,
        47,
        24,
        29,
        0,
        6,
        20,
        0,
        1,
        39,
        28,
        49,
        49,
        0,
        40,
        29,
        18,
        3,
        20,
        12,
        11,
        49
      ],
      "weeklyHold": [
        0,
        1,
        41,
        18,
        12,
        41,
        28,
        2,
        20,
        6,
        28,
        45,
        40,
        49,
        28,
        0,
        23,
        19,
        25,
        0,
        22,
        49,
        12,
        21,
        49,
        48,
        0,
        21,
        26,
        0,
        37,
        25,
        29,
        41,
        20,
        0,
        38,
        0,
        35,
        19,
        23,
        31,
        8,
        17,
        26,
        3,
        17,
        0,
        0,
        12,
        4,
        15
      ],
      "weeklyBackordered": [
        21,
        47,
        0,
        31,
        18,
        29,
        31,
        36,
        16,
        4,
        34,
        9,
        3,
        49,
        25,
        49,
        7,
        0,
        10,
        42,
        41,
        0,
        9,
        0,
        22,
        1,
        22,
        4,
        43,
        40,
        28,
        49,
        24,
        44,
        0,
        0,
        4,
        27,
        29,
        40,
        17,
        31,
        12,
        49,
        16,
        49,
        2,
        26,
        36,
        15,
        45,
        36
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        31,
        48,
        5,
        48,
        47,
        7,
        44,
        38,
        40,
        0,
        21,
        2,
        12,
        23,
        24,
        25,
        44,
        27,
        2,
        40,
        17,
        30,
        23,
        38,
        30,
        47,
        11,
        13,
        28,
        36,
        24,
        0,
        38,
        28,
        17,
        39,
        47,
        48,
        49,
        49,
        9,
        42,
        49,
        22,
        40,
        17,
        0,
        21,
        8,
        49,
        32,
        48
      ],
      "weeklyRejected": [
        49,
        4,
        44,
        10,
        49,
        0,
        35,
        24,
        43,
        1,
        10,
        43,
        41,
        21,
        33,
        33,
        21,
        9,
        41,
        1,
        21,
        26,
        16,
        23,
        35,
        41,
        43,
        42,
        47,
        32,
        42,
        47,
        0,
        49,
        20,
        49,
        10,
        6,
        29,
        13,
        17,
        47,
        30,
        49,
        49,
        13,
        21,
        16,
        22,
        28,
        11,
        26
      ],
      "weeklyHold": [
        12,
        25,
        13,
        26,
        10,
        26,
        36,
        49,
        7,
        0,
        18,
        49,
        21,
        49,
        21,
        18,
        9,
        23,
        11,
        9,
        20,
        0,
        23,
        48,
        12,
        23,
        29,
        19,
        49,
        7,
        48,
        8,
        2,
        0,
        43,
        10,
        46,
        27,
        22,
        48,
        9,
        26,
        21,
        30,
        34,
        31,
        20,
        1,
        12,
        28,
        20,
        28
      ],
      "weeklyBackordered": [
        44,
        33,
        30,
        35,
        49,
        17,
        45,
        17,
        20,
        34,
        35,
        8,
        33,
        0,
        28,
        18,
        20,
        0,
        24,
        11,
        36,
        49,
        29,
        15,
        1,
        8,
        18,
        49,
        13,
        43,
        38,
        39,
        0,
        49,
        0,
        0,
        35,
        16,
        49,
        18,
        20,
        22,
        31,
        49,
        14,
        25,
        19,
        4,
        49,
        20,
        41,
        40
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        23,
        44,
        49,
        3,
        29,
        49,
        38,
        49,
        48,
        0,
        49,
        6,
        22,
        27,
        12,
        37,
        14,
        15,
        0,
        49,
        23,
        31,
        0,
        15,
        16,
        47,
        49,
        0,
        16,
        31,
        25,
        31,
        23,
        21,
        8,
        2,
        47,
        24,
        23,
        49,
        34,
        15,
        32,
        28,
        35,
        17,
        0,
        36,
        49,
        49,
        20,
        0
      ],
      "weeklyRejected": [
        49,
        0,
        21,
        0,
        49,
        41,
        29,
        42,
        25,
        29,
        28,
        41,
        49,
        16,
        31,
        33,
        13,
        49,
        39,
        49,
        49,
        49,
        13,
        7,
        14,
        21,
        37,
        13,
        40,
        3,
        31,
        46,
        48,
        49,
        42,
        49,
        9,
        44,
        8,
        0,
        43,
        37,
        43,
        0,
        49,
        27,
        0,
        25,
        34,
        49,
        2,
        6
      ],
      "weeklyHold": [
        14,
        45,
        41,
        15,
        46,
        40,
        32,
        26,
        22,
        49,
        18,
        31,
        28,
        15,
        45,
        19,
        0,
        1,
        0,
        45,
        12,
        24,
        18,
        0,
        48,
        13,
        49,
        2,
        10,
        22,
        48,
        26,
        0,
        38,
        48,
        20,
        20,
        1,
        3,
        20,
        0,
        35,
        25,
        33,
        30,
        49,
        47,
        27,
        14,
        37,
        39,
        49
      ],
      "weeklyBackordered": [
        49,
        38,
        48,
        28,
        34,
        0,
        42,
        19,
        0,
        0,
        43,
        41,
        49,
        28,
        7,
        49,
        39,
        33,
        0,
        41,
        17,
        31,
        48,
        49,
        13,
        23,
        36,
        27,
        32,
        36,
        32,
        3,
        35,
        29,
        0,
        29,
        31,
        0,
        11,
        0,
        14,
        48,
        11,
        49,
        16,
        11,
        49,
        24,
        31,
        49,
        48,
        29
      ]
    },
    {
      "id": "F04529EA",
      "weeklyAccepted": [
        12,
        28,
        9,
        34,
        6,
        7,
        14,
        33,
        12,
        0,
        34,
        0,
        16,
        13,
        1,
        23,
        48,
        10,
        0,
        0,
        0,
        22,
        36,
        43,
        49,
        9,
        24,
        28,
        24,
        49,
        24,
        1,
        45,
        35,
        48,
        49,
        47,
        26,
        29,
        19,
        44,
        49,
        21,
        0,
        38,
        0,
        0,
        30,
        0,
        18,
        45,
        48
      ],
      "weeklyRejected": [
        45,
        49,
        15,
        0,
        19,
        26,
        6,
        0,
        38,
        17,
        12,
        29,
        30,
        10,
        48,
        2,
        32,
        31,
        49,
        32,
        45,
        0,
        25,
        12,
        34,
        36,
        49,
        28,
        44,
        42,
        49,
        47,
        27,
        49,
        33,
        49,
        33,
        49,
        13,
        32,
        33,
        10,
        9,
        37,
        40,
        30,
        0,
        0,
        0,
        40,
        12,
        26
      ],
      "weeklyHold": [
        33,
        39,
        49,
        1,
        49,
        22,
        17,
        2,
        0,
        8,
        37,
        33,
        1,
        9,
        4,
        49,
        49,
        22,
        42,
        32,
        0,
        29,
        29,
        0,
        0,
        48,
        15,
        30,
        49,
        33,
        48,
        8,
        14,
        43,
        16,
        40,
        33,
        41,
        33,
        12,
        3,
        36,
        0,
        0,
        49,
        49,
        43,
        24,
        8,
        9,
        49,
        16
      ],
      "weeklyBackordered": [
        0,
        23,
        13,
        49,
        40,
        42,
        43,
        28,
        40,
        24,
        49,
        49,
        3,
        25,
        48,
        37,
        47,
        25,
        0,
        18,
        49,
        49,
        34,
        48,
        12,
        5,
        49,
        8,
        1,
        14,
        22,
        49,
        42,
        4,
        0,
        0,
        48,
        1,
        33,
        7,
        29,
        34,
        28,
        38,
        34,
        7,
        44,
        26,
        13,
        9,
        49,
        31
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        49,
        13,
        29,
        33,
        49,
        0,
        26,
        36,
        48,
        19,
        43,
        6,
        49,
        18,
        1,
        16,
        49,
        26,
        0,
        49,
        31,
        31,
        36,
        0,
        35,
        47,
        22,
        8,
        9,
        49,
        0,
        38,
        49,
        47,
        46,
        35,
        36,
        1,
        49,
        49,
        27,
        32,
        25,
        30,
        18,
        22,
        6,
        8,
        5,
        49,
        0,
        14
      ],
      "weeklyRejected": [
        35,
        49,
        28,
        21,
        49,
        0,
        30,
        49,
        40,
        28,
        47,
        42,
        29,
        36,
        15,
        0,
        0,
        49,
        41,
        49,
        29,
        37,
        35,
        23,
        32,
        21,
        49,
        11,
        40,
        0,
        49,
        24,
        43,
        23,
        31,
        42,
        47,
        21,
        1,
        1,
        34,
        16,
        16,
        16,
        49,
        41,
        0,
        15,
        6,
        33,
        38,
        40
      ],
      "weeklyHold": [
        24,
        3,
        49,
        22,
        3,
        34,
        36,
        24,
        25,
        12,
        12,
        25,
        48,
        30,
        30,
        18,
        16,
        48,
        0,
        0,
        37,
        0,
        49,
        5,
        0,
        20,
        49,
        0,
        49,
        0,
        38,
        2,
        45,
        33,
        37,
        43,
        37,
        32,
        45,
        46,
        0,
        26,
        1,
        12,
        19,
        37,
        32,
        24,
        0,
        32,
        49,
        49
      ],
      "weeklyBackordered": [
        26,
        19,
        48,
        36,
        35,
        32,
        34,
        32,
        16,
        26,
        49,
        25,
        7,
        19,
        15,
        49,
        2,
        37,
        40,
        13,
        47,
        22,
        39,
        9,
        22,
        43,
        27,
        18,
        1,
        30,
        47,
        5,
        24,
        48,
        0,
        0,
        22,
        8,
        18,
        0,
        28,
        48,
        0,
        49,
        24,
        39,
        49,
        8,
        22,
        44,
        31,
        20
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        23,
        49,
        0,
        32,
        15,
        0,
        32,
        49,
        13,
        22,
        2,
        29,
        15,
        10,
        22,
        0,
        49,
        25,
        8,
        33,
        12,
        44,
        49,
        0,
        0,
        43,
        41,
        1,
        1,
        3,
        49,
        3,
        11,
        14,
        10,
        27,
        38,
        48,
        13,
        44,
        37,
        42,
        49,
        30,
        27,
        18,
        0,
        15,
        0,
        17,
        17,
        39
      ],
      "weeklyRejected": [
        46,
        39,
        49,
        0,
        20,
        2,
        35,
        13,
        26,
        1,
        0,
        20,
        28,
        48,
        48,
        49,
        0,
        41,
        39,
        10,
        6,
        14,
        30,
        22,
        31,
        35,
        34,
        36,
        39,
        22,
        30,
        28,
        48,
        49,
        13,
        38,
        49,
        19,
        26,
        34,
        34,
        31,
        49,
        5,
        42,
        0,
        1,
        30,
        36,
        49,
        0,
        49
      ],
      "weeklyHold": [
        0,
        46,
        45,
        17,
        43,
        42,
        0,
        8,
        2,
        46,
        40,
        42,
        30,
        32,
        14,
        36,
        6,
        11,
        44,
        34,
        5,
        19,
        32,
        7,
        42,
        45,
        43,
        3,
        26,
        5,
        48,
        15,
        18,
        2,
        13,
        41,
        5,
        24,
        24,
        0,
        38,
        18,
        23,
        27,
        33,
        40,
        49,
        20,
        28,
        21,
        49,
        23
      ],
      "weeklyBackordered": [
        11,
        14,
        7,
        17,
        13,
        33,
        0,
        41,
        38,
        13,
        49,
        13,
        3,
        22,
        48,
        34,
        17,
        42,
        0,
        33,
        47,
        36,
        32,
        5,
        9,
        33,
        49,
        40,
        13,
        49,
        21,
        42,
        16,
        35,
        4,
        0,
        46,
        0,
        33,
        40,
        29,
        48,
        18,
        49,
        22,
        30,
        35,
        7,
        9,
        46,
        17,
        33
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        19,
        28,
        49,
        7,
        1,
        20,
        12,
        40,
        48,
        37,
        34,
        20,
        49,
        17,
        23,
        0,
        49,
        0,
        31,
        31,
        15,
        37,
        40,
        45,
        24,
        8,
        22,
        0,
        17,
        29,
        39,
        0,
        33,
        35,
        6,
        31,
        38,
        23,
        30,
        49,
        18,
        3,
        49,
        38,
        14,
        17,
        5,
        21,
        0,
        49,
        22,
        43
      ],
      "weeklyRejected": [
        49,
        23,
        49,
        10,
        46,
        43,
        40,
        22,
        39,
        24,
        0,
        8,
        25,
        1,
        48,
        23,
        11,
        27,
        49,
        11,
        0,
        40,
        49,
        43,
        34,
        7,
        23,
        17,
        48,
        14,
        45,
        18,
        18,
        49,
        9,
        48,
        24,
        37,
        30,
        49,
        5,
        49,
        18,
        17,
        49,
        49,
        1,
        13,
        2,
        27,
        0,
        49
      ],
      "weeklyHold": [
        0,
        28,
        22,
        25,
        28,
        39,
        49,
        6,
        8,
        31,
        42,
        38,
        39,
        16,
        29,
        0,
        0,
        5,
        0,
        0,
        23,
        11,
        12,
        17,
        27,
        48,
        36,
        16,
        45,
        3,
        48,
        25,
        0,
        2,
        0,
        30,
        49,
        6,
        33,
        18,
        11,
        40,
        0,
        49,
        16,
        17,
        0,
        46,
        0,
        49,
        11,
        46
      ],
      "weeklyBackordered": [
        22,
        0,
        0,
        44,
        36,
        9,
        49,
        33,
        14,
        24,
        49,
        36,
        14,
        49,
        29,
        42,
        34,
        14,
        0,
        31,
        12,
        0,
        12,
        17,
        34,
        49,
        31,
        41,
        32,
        21,
        49,
        15,
        14,
        0,
        0,
        0,
        13,
        0,
        8,
        32,
        24,
        48,
        7,
        34,
        27,
        30,
        25,
        30,
        0,
        32,
        17,
        34
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        49,
        32,
        23,
        9,
        26,
        33,
        1,
        49,
        36,
        14,
        49,
        19,
        23,
        25,
        1,
        4,
        44,
        0,
        16,
        34,
        37,
        39,
        49,
        15,
        29,
        20,
        4,
        0,
        1,
        1,
        32,
        0,
        2,
        0,
        48,
        26,
        42,
        19,
        12,
        49,
        37,
        42,
        46,
        48,
        14,
        0,
        0,
        39,
        49,
        19,
        49,
        31
      ],
      "weeklyRejected": [
        28,
        3,
        2,
        18,
        34,
        43,
        47,
        47,
        36,
        46,
        21,
        38,
        0,
        48,
        48,
        6,
        20,
        49,
        13,
        2,
        49,
        16,
        15,
        40,
        27,
        49,
        0,
        23,
        31,
        14,
        30,
        19,
        31,
        22,
        32,
        49,
        18,
        7,
        25,
        16,
        27,
        15,
        35,
        4,
        49,
        22,
        29,
        27,
        25,
        9,
        0,
        32
      ],
      "weeklyHold": [
        49,
        27,
        22,
        19,
        25,
        9,
        18,
        26,
        13,
        14,
        44,
        20,
        33,
        15,
        28,
        31,
        6,
        20,
        19,
        11,
        13,
        26,
        19,
        49,
        33,
        1,
        9,
        5,
        32,
        0,
        13,
        13,
        25,
        41,
        48,
        9,
        38,
        0,
        49,
        37,
        13,
        31,
        0,
        28,
        40,
        45,
        2,
        49,
        9,
        40,
        3,
        0
      ],
      "weeklyBackordered": [
        49,
        39,
        0,
        49,
        42,
        43,
        49,
        43,
        2,
        0,
        9,
        21,
        11,
        14,
        20,
        26,
        40,
        46,
        0,
        49,
        19,
        0,
        21,
        35,
        8,
        49,
        39,
        49,
        29,
        49,
        31,
        37,
        18,
        18,
        25,
        0,
        20,
        0,
        18,
        1,
        15,
        46,
        14,
        44,
        41,
        6,
        39,
        36,
        35,
        30,
        41,
        49
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyAccepted": [
        0,
        49,
        24,
        26,
        27,
        7,
        5,
        14,
        48,
        1,
        28,
        17,
        40,
        18,
        18,
        21,
        36,
        17,
        0,
        27,
        25,
        31,
        32,
        47,
        29,
        47,
        14,
        16,
        1,
        36,
        40,
        41,
        49,
        0,
        48,
        39,
        19,
        20,
        28,
        1,
        11,
        20,
        38,
        17,
        45,
        14,
        4,
        46,
        12,
        28,
        0,
        14
      ],
      "weeklyRejected": [
        23,
        0,
        38,
        47,
        49,
        12,
        36,
        37,
        49,
        44,
        33,
        30,
        18,
        32,
        13,
        49,
        42,
        24,
        45,
        32,
        16,
        47,
        42,
        49,
        26,
        11,
        40,
        0,
        48,
        32,
        19,
        47,
        48,
        19,
        30,
        22,
        41,
        31,
        10,
        19,
        33,
        20,
        30,
        27,
        28,
        18,
        17,
        44,
        19,
        49,
        0,
        34
      ],
      "weeklyHold": [
        44,
        36,
        49,
        1,
        49,
        39,
        17,
        2,
        33,
        49,
        39,
        41,
        48,
        16,
        10,
        9,
        35,
        6,
        11,
        14,
        27,
        25,
        0,
        49,
        0,
        48,
        31,
        40,
        49,
        0,
        48,
        3,
        17,
        49,
        48,
        28,
        2,
        35,
        37,
        29,
        8,
        17,
        0,
        35,
        44,
        41,
        6,
        49,
        16,
        27,
        45,
        43
      ],
      "weeklyBackordered": [
        49,
        35,
        17,
        19,
        49,
        46,
        35,
        27,
        0,
        0,
        49,
        25,
        27,
        49,
        0,
        47,
        27,
        38,
        0,
        35,
        35,
        19,
        0,
        9,
        4,
        22,
        21,
        19,
        45,
        27,
        47,
        17,
        48,
        20,
        21,
        17,
        12,
        26,
        0,
        34,
        2,
        26,
        6,
        44,
        43,
        49,
        33,
        11,
        0,
        23,
        49,
        21
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        0,
        23,
        30,
        0,
        20,
        1,
        10,
        21,
        48,
        18,
        41,
        0,
        17,
        15,
        8,
        45,
        49,
        6,
        0,
        20,
        9,
        17,
        0,
        36,
        38,
        36,
        3,
        0,
        1,
        32,
        14,
        25,
        49,
        13,
        48,
        35,
        47,
        48,
        49,
        34,
        23,
        13,
        49,
        41,
        49,
        20,
        0,
        0,
        6,
        49,
        6,
        21
      ],
      "weeklyRejected": [
        49,
        7,
        22,
        34,
        0,
        41,
        40,
        25,
        43,
        16,
        49,
        34,
        34,
        39,
        13,
        49,
        0,
        46,
        41,
        0,
        41,
        49,
        17,
        29,
        33,
        37,
        9,
        13,
        44,
        25,
        21,
        36,
        48,
        16,
        49,
        19,
        49,
        40,
        20,
        20,
        3,
        7,
        17,
        26,
        47,
        36,
        0,
        11,
        15,
        23,
        8,
        49
      ],
      "weeklyHold": [
        45,
        0,
        40,
        25,
        15,
        40,
        18,
        37,
        49,
        36,
        46,
        26,
        20,
        39,
        3,
        15,
        0,
        11,
        46,
        49,
        0,
        49,
        35,
        13,
        0,
        1,
        26,
        17,
        49,
        0,
        37,
        14,
        26,
        40,
        1,
        49,
        1,
        37,
        30,
        12,
        14,
        13,
        3,
        6,
        49,
        17,
        0,
        26,
        1,
        37,
        49,
        28
      ],
      "weeklyBackordered": [
        45,
        49,
        35,
        33,
        49,
        23,
        37,
        1,
        2,
        4,
        48,
        24,
        3,
        37,
        28,
        1,
        44,
        0,
        38,
        49,
        49,
        27,
        16,
        0,
        13,
        10,
        22,
        9,
        49,
        19,
        15,
        49,
        33,
        43,
        9,
        11,
        24,
        0,
        46,
        13,
        48,
        31,
        18,
        19,
        49,
        0,
        34,
        0,
        34,
        49,
        49,
        6
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        0,
        49,
        0,
        18,
        49,
        0,
        1,
        44,
        48,
        0,
        23,
        0,
        28,
        49,
        34,
        18,
        46,
        48,
        12,
        0,
        49,
        26,
        29,
        38,
        32,
        47,
        0,
        0,
        1,
        22,
        0,
        3,
        39,
        5,
        22,
        10,
        21,
        48,
        49,
        49,
        49,
        18,
        49,
        49,
        49,
        18,
        0,
        40,
        49,
        25,
        37,
        48
      ],
      "weeklyRejected": [
        25,
        16,
        49,
        11,
        31,
        35,
        44,
        23,
        49,
        30,
        27,
        25,
        0,
        48,
        48,
        20,
        40,
        5,
        28,
        46,
        0,
        38,
        33,
        0,
        0,
        49,
        49,
        29,
        29,
        29,
        15,
        47,
        2,
        33,
        24,
        49,
        49,
        49,
        28,
        21,
        32,
        14,
        22,
        39,
        42,
        17,
        7,
        19,
        0,
        49,
        34,
        49
      ],
      "weeklyHold": [
        0,
        0,
        31,
        18,
        33,
        38,
        13,
        2,
        26,
        16,
        41,
        40,
        14,
        41,
        35,
        20,
        0,
        14,
        25,
        49,
        0,
        31,
        0,
        3,
        0,
        24,
        42,
        35,
        31,
        29,
        48,
        5,
        0,
        14,
        33,
        31,
        13,
        36,
        1,
        37,
        29,
        49,
        14,
        1,
        35,
        40,
        35,
        46,
        21,
        26,
        10,
        28
      ],
      "weeklyBackordered": [
        42,
        0,
        0,
        21,
        49,
        22,
        26,
        1,
        44,
        5,
        24,
        49,
        48,
        0,
        6,
        10,
        44,
        45,
        42,
        7,
        32,
        20,
        27,
        11,
        11,
        49,
        49,
        49,
        8,
        49,
        0,
        35,
        29,
        6,
        40,
        0,
        18,
        0,
        17,
        19,
        29,
        33,
        0,
        38,
        15,
        25,
        49,
        30,
        12,
        29,
        1,
        9
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyAccepted": [
        8,
        22,
        0,
        17,
        12,
        9,
        11,
        49,
        33,
        5,
        8,
        15,
        18,
        14,
        49,
        29,
        49,
        0,
        15,
        30,
        49,
        20,
        49,
        22,
        20,
        46,
        12,
        15,
        1,
        33,
        0,
        6,
        49,
        12,
        47,
        33,
        29,
        27,
        35,
        29,
        12,
        0,
        49,
        49,
        49,
        15,
        12,
        23,
        23,
        33,
        40,
        48
      ],
      "weeklyRejected": [
        42,
        15,
        31,
        9,
        26,
        38,
        30,
        49,
        49,
        27,
        49,
        41,
        7,
        30,
        17,
        23,
        49,
        49,
        49,
        13,
        0,
        17,
        23,
        18,
        22,
        49,
        40,
        24,
        48,
        31,
        8,
        47,
        47,
        33,
        22,
        45,
        37,
        47,
        49,
        22,
        30,
        6,
        6,
        13,
        49,
        37,
        10,
        15,
        46,
        44,
        30,
        49
      ],
      "weeklyHold": [
        25,
        5,
        19,
        21,
        22,
        48,
        5,
        12,
        39,
        21,
        26,
        19,
        36,
        44,
        31,
        0,
        0,
        12,
        26,
        34,
        19,
        31,
        49,
        49,
        4,
        13,
        27,
        16,
        49,
        49,
        33,
        0,
        0,
        49,
        34,
        49,
        30,
        0,
        22,
        0,
        37,
        24,
        1,
        19,
        12,
        0,
        9,
        49,
        0,
        49,
        33,
        20
      ],
      "weeklyBackordered": [
        16,
        23,
        23,
        23,
        49,
        26,
        0,
        24,
        23,
        17,
        47,
        17,
        49,
        16,
        38,
        23,
        19,
        49,
        3,
        46,
        35,
        24,
        25,
        0,
        20,
        33,
        1,
        0,
        18,
        49,
        7,
        49,
        48,
        48,
        22,
        0,
        25,
        0,
        23,
        27,
        41,
        32,
        4,
        49,
        29,
        49,
        44,
        49,
        20,
        14,
        7,
        49
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        4,
        10,
        7,
        30,
        33,
        21,
        8,
        49,
        31,
        5,
        49,
        0,
        49,
        31,
        19,
        33,
        47,
        3,
        35,
        38,
        18,
        19,
        49,
        38,
        26,
        31,
        0,
        0,
        6,
        37,
        42,
        31,
        36,
        20,
        15,
        21,
        30,
        48,
        13,
        12,
        39,
        40,
        44,
        27,
        49,
        5,
        9,
        35,
        11,
        48,
        2,
        44
      ],
      "weeklyRejected": [
        42,
        10,
        19,
        35,
        37,
        1,
        0,
        24,
        30,
        15,
        26,
        49,
        49,
        45,
        39,
        49,
        13,
        30,
        27,
        49,
        30,
        46,
        39,
        37,
        41,
        27,
        37,
        21,
        42,
        13,
        0,
        9,
        48,
        49,
        23,
        33,
        35,
        15,
        5,
        30,
        48,
        30,
        15,
        2,
        33,
        0,
        2,
        16,
        0,
        11,
        0,
        28
      ],
      "weeklyHold": [
        49,
        34,
        26,
        16,
        5,
        10,
        43,
        14,
        32,
        26,
        47,
        49,
        44,
        49,
        29,
        21,
        0,
        1,
        37,
        49,
        17,
        11,
        49,
        15,
        49,
        38,
        48,
        0,
        49,
        7,
        47,
        20,
        9,
        0,
        15,
        22,
        0,
        0,
        39,
        17,
        37,
        35,
        44,
        44,
        19,
        49,
        32,
        22,
        4,
        34,
        36,
        0
      ],
      "weeklyBackordered": [
        9,
        49,
        10,
        42,
        49,
        6,
        34,
        38,
        42,
        0,
        35,
        26,
        36,
        26,
        28,
        45,
        27,
        18,
        8,
        44,
        26,
        12,
        0,
        24,
        12,
        1,
        10,
        8,
        29,
        39,
        49,
        49,
        41,
        49,
        6,
        0,
        15,
        14,
        10,
        1,
        0,
        48,
        12,
        49,
        30,
        28,
        27,
        14,
        9,
        49,
        41,
        11
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        30,
        26,
        35,
        40,
        18,
        10,
        26,
        49,
        48,
        49,
        49,
        13,
        23,
        13,
        1,
        18,
        27,
        39,
        0,
        22,
        34,
        33,
        25,
        49,
        0,
        32,
        49,
        16,
        48,
        49,
        31,
        30,
        34,
        2,
        11,
        22,
        20,
        26,
        49,
        28,
        14,
        42,
        47,
        20,
        13,
        8,
        0,
        30,
        14,
        30,
        6,
        48
      ],
      "weeklyRejected": [
        49,
        27,
        43,
        47,
        49,
        47,
        49,
        32,
        30,
        11,
        35,
        18,
        8,
        32,
        48,
        19,
        12,
        49,
        12,
        49,
        11,
        20,
        0,
        39,
        1,
        49,
        11,
        43,
        48,
        0,
        33,
        32,
        48,
        29,
        46,
        48,
        0,
        9,
        1,
        8,
        35,
        49,
        38,
        26,
        49,
        36,
        18,
        29,
        23,
        29,
        0,
        1
      ],
      "weeklyHold": [
        14,
        49,
        31,
        18,
        49,
        48,
        42,
        27,
        38,
        29,
        42,
        45,
        45,
        37,
        0,
        13,
        28,
        33,
        0,
        42,
        15,
        21,
        26,
        0,
        39,
        48,
        0,
        3,
        0,
        15,
        40,
        46,
        0,
        42,
        29,
        8,
        49,
        21,
        19,
        46,
        28,
        15,
        26,
        0,
        49,
        49,
        22,
        49,
        0,
        49,
        27,
        24
      ],
      "weeklyBackordered": [
        5,
        49,
        0,
        11,
        6,
        44,
        49,
        47,
        26,
        11,
        0,
        38,
        36,
        48,
        49,
        17,
        44,
        38,
        0,
        42,
        0,
        34,
        16,
        20,
        11,
        1,
        1,
        10,
        24,
        49,
        27,
        20,
        7,
        45,
        0,
        4,
        27,
        36,
        37,
        49,
        25,
        48,
        16,
        49,
        11,
        0,
        49,
        2,
        0,
        49,
        17,
        20
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        4,
        4,
        49,
        6,
        47,
        49,
        1,
        31,
        48,
        23,
        41,
        0,
        49,
        31,
        29,
        8,
        49,
        25,
        35,
        18,
        27,
        20,
        21,
        49,
        38,
        34,
        12,
        33,
        29,
        35,
        49,
        26,
        49,
        23,
        48,
        13,
        32,
        20,
        49,
        22,
        49,
        49,
        23,
        19,
        49,
        28,
        0,
        13,
        23,
        27,
        35,
        35
      ],
      "weeklyRejected": [
        49,
        28,
        49,
        39,
        49,
        10,
        21,
        15,
        19,
        28,
        0,
        21,
        49,
        32,
        48,
        0,
        0,
        37,
        34,
        17,
        49,
        0,
        26,
        19,
        5,
        49,
        24,
        13,
        35,
        0,
        16,
        47,
        23,
        22,
        8,
        49,
        34,
        22,
        22,
        18,
        11,
        38,
        6,
        49,
        17,
        33,
        0,
        31,
        0,
        31,
        14,
        41
      ],
      "weeklyHold": [
        43,
        8,
        30,
        40,
        23,
        48,
        30,
        18,
        45,
        41,
        32,
        21,
        26,
        4,
        38,
        30,
        45,
        0,
        35,
        17,
        13,
        15,
        29,
        29,
        17,
        24,
        34,
        0,
        39,
        34,
        48,
        48,
        2,
        35,
        34,
        0,
        41,
        3,
        30,
        39,
        39,
        23,
        1,
        8,
        49,
        0,
        46,
        49,
        0,
        0,
        0,
        20
      ],
      "weeklyBackordered": [
        25,
        19,
        0,
        43,
        49,
        40,
        18,
        49,
        12,
        34,
        10,
        10,
        22,
        27,
        26,
        37,
        49,
        49,
        28,
        0,
        32,
        16,
        11,
        44,
        1,
        49,
        24,
        0,
        40,
        49,
        49,
        33,
        0,
        32,
        17,
        0,
        34,
        20,
        17,
        20,
        0,
        22,
        17,
        0,
        19,
        49,
        47,
        22,
        24,
        7,
        49,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        11,
        1,
        44,
        8,
        1,
        0,
        14,
        12,
        45,
        0,
        0,
        0,
        12,
        34,
        11,
        47,
        41,
        22,
        0,
        19,
        10,
        34,
        0,
        42,
        10,
        19,
        42,
        0,
        11,
        34,
        21,
        39,
        49,
        0,
        19,
        2,
        33,
        28,
        35,
        34,
        49,
        26,
        49,
        33,
        7,
        5,
        11,
        27,
        0,
        49,
        5,
        22
      ],
      "weeklyRejected": [
        18,
        27,
        49,
        38,
        35,
        49,
        49,
        26,
        48,
        10,
        31,
        20,
        13,
        30,
        48,
        0,
        0,
        33,
        49,
        22,
        29,
        39,
        21,
        38,
        0,
        26,
        0,
        49,
        0,
        27,
        15,
        21,
        44,
        36,
        32,
        49,
        14,
        49,
        1,
        49,
        31,
        49,
        30,
        4,
        40,
        14,
        5,
        18,
        3,
        39,
        9,
        21
      ],
      "weeklyHold": [
        5,
        34,
        49,
        19,
        29,
        34,
        47,
        18,
        19,
        24,
        33,
        23,
        48,
        1,
        49,
        49,
        10,
        0,
        0,
        49,
        44,
        44,
        23,
        26,
        31,
        37,
        49,
        0,
        1,
        18,
        48,
        0,
        0,
        45,
        48,
        16,
        2,
        0,
        4,
        25,
        0,
        33,
        8,
        39,
        15,
        27,
        44,
        49,
        6,
        23,
        8,
        20
      ],
      "weeklyBackordered": [
        34,
        36,
        18,
        32,
        42,
        43,
        32,
        1,
        44,
        3,
        49,
        38,
        3,
        43,
        49,
        1,
        49,
        6,
        38,
        7,
        30,
        48,
        16,
        33,
        15,
        21,
        22,
        35,
        49,
        38,
        36,
        44,
        0,
        49,
        0,
        0,
        47,
        40,
        49,
        43,
        48,
        48,
        0,
        49,
        24,
        24,
        2,
        29,
        0,
        49,
        22,
        34
      ]
    },
    {
      "id": "BB20277D",
      "weeklyAccepted": [
        14,
        38,
        38,
        40,
        27,
        14,
        40,
        20,
        27,
        0,
        25,
        0,
        31,
        17,
        28,
        13,
        49,
        46,
        26,
        49,
        7,
        3,
        13,
        49,
        26,
        33,
        34,
        33,
        15,
        16,
        18,
        32,
        49,
        25,
        29,
        47,
        22,
        22,
        32,
        40,
        29,
        0,
        33,
        49,
        49,
        33,
        0,
        49,
        10,
        40,
        38,
        10
      ],
      "weeklyRejected": [
        25,
        23,
        27,
        0,
        27,
        27,
        35,
        28,
        49,
        1,
        10,
        0,
        3,
        31,
        11,
        0,
        36,
        40,
        49,
        41,
        46,
        49,
        46,
        29,
        0,
        49,
        19,
        39,
        19,
        48,
        0,
        47,
        18,
        48,
        0,
        39,
        49,
        49,
        49,
        13,
        28,
        7,
        7,
        17,
        1,
        31,
        15,
        0,
        44,
        39,
        0,
        13
      ],
      "weeklyHold": [
        21,
        30,
        49,
        1,
        49,
        29,
        36,
        2,
        18,
        31,
        26,
        1,
        6,
        18,
        49,
        20,
        22,
        37,
        28,
        14,
        0,
        38,
        30,
        20,
        36,
        23,
        6,
        6,
        49,
        30,
        34,
        0,
        15,
        49,
        18,
        18,
        21,
        31,
        29,
        41,
        4,
        37,
        4,
        36,
        10,
        49,
        0,
        24,
        0,
        42,
        49,
        31
      ],
      "weeklyBackordered": [
        1,
        39,
        0,
        19,
        43,
        42,
        20,
        41,
        42,
        0,
        49,
        39,
        17,
        34,
        47,
        39,
        11,
        36,
        18,
        0,
        0,
        5,
        28,
        38,
        27,
        3,
        25,
        37,
        41,
        28,
        32,
        24,
        48,
        23,
        0,
        0,
        18,
        7,
        18,
        33,
        2,
        28,
        7,
        35,
        37,
        9,
        49,
        35,
        22,
        40,
        49,
        14
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        8,
        21,
        32,
        30,
        10,
        0,
        11,
        45,
        44,
        49,
        27,
        0,
        38,
        19,
        48,
        25,
        49,
        0,
        29,
        17,
        14,
        28,
        49,
        32,
        10,
        17,
        19,
        20,
        1,
        49,
        46,
        0,
        48,
        26,
        22,
        27,
        4,
        17,
        46,
        15,
        0,
        6,
        41,
        45,
        46,
        7,
        0,
        26,
        49,
        49,
        16,
        28
      ],
      "weeklyRejected": [
        39,
        18,
        40,
        47,
        41,
        49,
        49,
        46,
        33,
        42,
        30,
        47,
        0,
        23,
        48,
        0,
        1,
        47,
        34,
        9,
        26,
        15,
        45,
        22,
        28,
        44,
        12,
        15,
        48,
        43,
        36,
        46,
        2,
        46,
        30,
        17,
        22,
        16,
        16,
        0,
        14,
        43,
        34,
        0,
        49,
        4,
        0,
        19,
        0,
        49,
        32,
        13
      ],
      "weeklyHold": [
        0,
        19,
        42,
        23,
        49,
        48,
        41,
        2,
        30,
        26,
        47,
        49,
        34,
        27,
        32,
        0,
        24,
        25,
        8,
        34,
        17,
        20,
        49,
        6,
        0,
        24,
        8,
        29,
        9,
        47,
        47,
        21,
        0,
        11,
        3,
        3,
        29,
        0,
        13,
        31,
        7,
        36,
        5,
        26,
        27,
        19,
        17,
        43,
        10,
        16,
        4,
        49
      ],
      "weeklyBackordered": [
        29,
        20,
        0,
        37,
        43,
        46,
        26,
        2,
        34,
        34,
        49,
        49,
        37,
        6,
        13,
        17,
        4,
        49,
        32,
        38,
        49,
        0,
        0,
        9,
        26,
        49,
        1,
        2,
        49,
        27,
        29,
        49,
        47,
        12,
        0,
        0,
        1,
        5,
        33,
        31,
        33,
        48,
        8,
        49,
        0,
        26,
        35,
        36,
        18,
        13,
        42,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        28,
        49,
        28,
        20,
        34,
        9,
        9,
        22,
        48,
        9,
        49,
        2,
        39,
        29,
        1,
        10,
        49,
        0,
        28,
        17,
        49,
        40,
        32,
        33,
        0,
        41,
        36,
        49,
        23,
        44,
        17,
        25,
        49,
        0,
        37,
        49,
        25,
        11,
        26,
        37,
        24,
        34,
        26,
        38,
        16,
        0,
        0,
        1,
        32,
        45,
        29,
        0
      ],
      "weeklyRejected": [
        44,
        4,
        16,
        0,
        4,
        30,
        15,
        32,
        36,
        25,
        39,
        41,
        49,
        25,
        14,
        27,
        39,
        49,
        19,
        0,
        1,
        43,
        49,
        36,
        6,
        39,
        36,
        18,
        17,
        45,
        38,
        47,
        7,
        21,
        15,
        49,
        34,
        43,
        3,
        22,
        22,
        27,
        45,
        0,
        40,
        18,
        12,
        49,
        31,
        17,
        17,
        33
      ],
      "weeklyHold": [
        39,
        30,
        31,
        1,
        7,
        16,
        26,
        9,
        7,
        49,
        47,
        1,
        33,
        28,
        28,
        25,
        33,
        0,
        49,
        32,
        11,
        19,
        49,
        40,
        0,
        21,
        49,
        10,
        49,
        10,
        48,
        0,
        17,
        9,
        48,
        36,
        32,
        4,
        25,
        28,
        21,
        38,
        16,
        48,
        28,
        37,
        39,
        42,
        0,
        9,
        30,
        19
      ],
      "weeklyBackordered": [
        49,
        0,
        20,
        33,
        30,
        46,
        31,
        1,
        29,
        20,
        0,
        33,
        47,
        0,
        30,
        30,
        49,
        37,
        17,
        14,
        16,
        21,
        13,
        4,
        14,
        32,
        49,
        5,
        38,
        49,
        0,
        47,
        44,
        49,
        14,
        0,
        24,
        12,
        24,
        36,
        8,
        44,
        12,
        31,
        17,
        0,
        22,
        24,
        32,
        37,
        49,
        8
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        17,
        49,
        35,
        21,
        20,
        0,
        24,
        49,
        48,
        10,
        37,
        15,
        0,
        15,
        28,
        12,
        45,
        21,
        10,
        47,
        39,
        34,
        12,
        49,
        33,
        24,
        0,
        33,
        49,
        39,
        0,
        13,
        30,
        0,
        6,
        31,
        30,
        48,
        20,
        25,
        31,
        49,
        49,
        23,
        28,
        0,
        6,
        28,
        15,
        26,
        10,
        25
      ],
      "weeklyRejected": [
        38,
        27,
        17,
        45,
        49,
        35,
        24,
        49,
        45,
        12,
        11,
        0,
        23,
        48,
        29,
        0,
        43,
        41,
        49,
        23,
        41,
        24,
        0,
        23,
        8,
        49,
        41,
        49,
        9,
        17,
        16,
        31,
        48,
        18,
        23,
        28,
        25,
        42,
        6,
        43,
        39,
        37,
        42,
        0,
        49,
        8,
        0,
        12,
        16,
        30,
        37,
        46
      ],
      "weeklyHold": [
        47,
        49,
        39,
        44,
        49,
        43,
        12,
        13,
        13,
        49,
        14,
        7,
        32,
        8,
        31,
        22,
        7,
        48,
        15,
        17,
        14,
        0,
        49,
        35,
        44,
        38,
        18,
        32,
        34,
        22,
        21,
        0,
        0,
        41,
        1,
        12,
        27,
        0,
        18,
        48,
        12,
        33,
        24,
        0,
        31,
        49,
        49,
        15,
        0,
        26,
        0,
        26
      ],
      "weeklyBackordered": [
        18,
        43,
        13,
        31,
        44,
        46,
        38,
        49,
        39,
        16,
        4,
        40,
        30,
        22,
        3,
        16,
        49,
        49,
        5,
        0,
        10,
        40,
        17,
        49,
        29,
        17,
        49,
        14,
        33,
        35,
        49,
        24,
        48,
        10,
        25,
        20,
        27,
        9,
        29,
        44,
        0,
        28,
        0,
        49,
        16,
        27,
        12,
        23,
        10,
        34,
        32,
        37
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        15,
        49,
        49,
        0,
        48,
        30,
        39,
        49,
        33,
        0,
        38,
        2,
        45,
        2,
        1,
        8,
        49,
        19,
        5,
        17,
        19,
        17,
        20,
        29,
        5,
        33,
        26,
        43,
        1,
        1,
        40,
        23,
        35,
        27,
        33,
        36,
        34,
        30,
        49,
        38,
        49,
        36,
        34,
        29,
        26,
        15,
        24,
        13,
        0,
        35,
        20,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        34,
        29,
        14,
        23,
        49,
        0,
        34,
        32,
        41,
        42,
        49,
        25,
        48,
        0,
        40,
        44,
        35,
        34,
        48,
        49,
        42,
        1,
        0,
        26,
        49,
        0,
        26,
        18,
        49,
        31,
        48,
        23,
        19,
        30,
        11,
        49,
        16,
        15,
        48,
        19,
        47,
        17,
        45,
        45,
        13,
        49,
        0,
        26,
        0,
        21
      ],
      "weeklyHold": [
        26,
        27,
        15,
        42,
        24,
        36,
        24,
        20,
        28,
        14,
        47,
        39,
        20,
        28,
        42,
        15,
        4,
        8,
        17,
        25,
        0,
        0,
        8,
        19,
        49,
        48,
        31,
        33,
        49,
        4,
        25,
        8,
        3,
        44,
        3,
        28,
        31,
        13,
        44,
        16,
        29,
        34,
        0,
        26,
        19,
        31,
        0,
        14,
        25,
        17,
        31,
        49
      ],
      "weeklyBackordered": [
        11,
        49,
        28,
        49,
        21,
        46,
        49,
        34,
        30,
        25,
        36,
        36,
        36,
        49,
        46,
        4,
        49,
        8,
        25,
        35,
        0,
        45,
        4,
        41,
        4,
        31,
        13,
        0,
        34,
        49,
        5,
        6,
        47,
        21,
        0,
        0,
        28,
        33,
        11,
        16,
        47,
        48,
        12,
        49,
        18,
        9,
        40,
        20,
        44,
        49,
        36,
        31
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        0,
        45,
        28,
        34,
        32,
        0,
        2,
        49,
        45,
        38,
        30,
        0,
        13,
        21,
        23,
        49,
        13,
        0,
        4,
        19,
        8,
        9,
        19,
        49,
        30,
        19,
        25,
        49,
        17,
        42,
        14,
        31,
        19,
        21,
        30,
        49,
        22,
        26,
        22,
        24,
        10,
        31,
        49,
        0,
        47,
        0,
        0,
        34,
        3,
        49,
        0,
        16
      ],
      "weeklyRejected": [
        8,
        18,
        20,
        47,
        33,
        37,
        11,
        48,
        28,
        24,
        38,
        48,
        25,
        19,
        48,
        49,
        48,
        31,
        25,
        47,
        49,
        8,
        46,
        8,
        26,
        37,
        45,
        17,
        33,
        48,
        21,
        15,
        43,
        30,
        12,
        49,
        34,
        36,
        1,
        23,
        48,
        0,
        49,
        34,
        45,
        13,
        33,
        3,
        0,
        43,
        7,
        49
      ],
      "weeklyHold": [
        15,
        44,
        22,
        1,
        49,
        6,
        29,
        23,
        27,
        23,
        47,
        46,
        32,
        35,
        17,
        0,
        9,
        32,
        49,
        29,
        37,
        23,
        19,
        41,
        5,
        9,
        2,
        31,
        49,
        16,
        36,
        3,
        0,
        34,
        0,
        40,
        15,
        18,
        28,
        39,
        49,
        41,
        0,
        31,
        22,
        19,
        8,
        23,
        14,
        0,
        22,
        29
      ],
      "weeklyBackordered": [
        45,
        32,
        0,
        42,
        47,
        33,
        1,
        36,
        40,
        6,
        32,
        49,
        16,
        49,
        22,
        20,
        37,
        26,
        0,
        21,
        44,
        38,
        32,
        35,
        16,
        1,
        15,
        0,
        37,
        49,
        0,
        10,
        15,
        33,
        25,
        13,
        15,
        3,
        13,
        49,
        47,
        48,
        1,
        49,
        2,
        2,
        33,
        49,
        38,
        49,
        1,
        1
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        16,
        9,
        49,
        28,
        21,
        45,
        1,
        22,
        6,
        19,
        49,
        0,
        49,
        0,
        1,
        40,
        45,
        10,
        0,
        49,
        16,
        35,
        28,
        45,
        18,
        47,
        0,
        24,
        1,
        49,
        37,
        0,
        47,
        17,
        18,
        27,
        47,
        48,
        7,
        15,
        44,
        7,
        12,
        49,
        29,
        0,
        0,
        36,
        15,
        33,
        27,
        26
      ],
      "weeklyRejected": [
        39,
        10,
        10,
        22,
        21,
        39,
        35,
        0,
        49,
        7,
        0,
        27,
        23,
        48,
        17,
        20,
        29,
        14,
        45,
        9,
        38,
        42,
        33,
        27,
        26,
        35,
        37,
        19,
        38,
        9,
        16,
        36,
        44,
        39,
        49,
        14,
        23,
        24,
        1,
        19,
        30,
        12,
        18,
        5,
        38,
        35,
        26,
        13,
        27,
        24,
        28,
        42
      ],
      "weeklyHold": [
        44,
        0,
        49,
        24,
        33,
        40,
        0,
        2,
        36,
        30,
        20,
        25,
        42,
        11,
        14,
        1,
        7,
        0,
        18,
        49,
        14,
        6,
        49,
        11,
        30,
        48,
        37,
        0,
        45,
        1,
        40,
        0,
        2,
        5,
        36,
        49,
        46,
        6,
        49,
        49,
        0,
        38,
        0,
        4,
        8,
        0,
        27,
        22,
        10,
        0,
        12,
        49
      ],
      "weeklyBackordered": [
        26,
        26,
        1,
        48,
        31,
        36,
        42,
        16,
        33,
        27,
        44,
        45,
        12,
        30,
        25,
        18,
        42,
        24,
        36,
        0,
        36,
        2,
        14,
        0,
        48,
        1,
        33,
        24,
        6,
        44,
        8,
        49,
        29,
        41,
        9,
        0,
        11,
        4,
        6,
        31,
        28,
        47,
        6,
        49,
        0,
        17,
        26,
        23,
        7,
        48,
        30,
        49
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        0,
        49,
        49,
        16,
        1,
        34,
        6,
        21,
        48,
        6,
        46,
        13,
        0,
        14,
        19,
        49,
        35,
        0,
        0,
        49,
        5,
        19,
        0,
        49,
        6,
        20,
        34,
        0,
        22,
        49,
        1,
        0,
        30,
        17,
        30,
        6,
        32,
        30,
        49,
        17,
        25,
        4,
        34,
        3,
        4,
        0,
        23,
        10,
        46,
        31,
        24,
        0
      ],
      "weeklyRejected": [
        16,
        15,
        18,
        1,
        29,
        35,
        49,
        8,
        0,
        4,
        0,
        26,
        31,
        48,
        36,
        25,
        31,
        48,
        31,
        46,
        49,
        28,
        11,
        16,
        40,
        45,
        29,
        1,
        25,
        0,
        42,
        15,
        42,
        0,
        27,
        49,
        38,
        16,
        1,
        46,
        38,
        49,
        30,
        0,
        36,
        10,
        0,
        0,
        10,
        29,
        8,
        31
      ],
      "weeklyHold": [
        35,
        31,
        45,
        16,
        26,
        32,
        7,
        14,
        6,
        23,
        47,
        8,
        24,
        14,
        27,
        22,
        0,
        43,
        18,
        20,
        21,
        0,
        45,
        6,
        47,
        1,
        13,
        20,
        0,
        2,
        48,
        28,
        0,
        42,
        48,
        32,
        34,
        0,
        49,
        49,
        0,
        18,
        8,
        33,
        37,
        42,
        35,
        30,
        6,
        22,
        26,
        39
      ],
      "weeklyBackordered": [
        26,
        32,
        0,
        49,
        41,
        36,
        49,
        22,
        0,
        1,
        21,
        48,
        3,
        49,
        34,
        3,
        38,
        35,
        23,
        19,
        23,
        45,
        23,
        42,
        18,
        23,
        1,
        20,
        20,
        1,
        38,
        1,
        28,
        42,
        0,
        0,
        3,
        15,
        25,
        32,
        29,
        48,
        49,
        49,
        0,
        7,
        17,
        1,
        0,
        38,
        15,
        1
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        4,
        48,
        49,
        41,
        13,
        16,
        1,
        21,
        32,
        28,
        48,
        27,
        29,
        15,
        14,
        35,
        28,
        25,
        0,
        26,
        30,
        49,
        23,
        24,
        43,
        38,
        20,
        17,
        15,
        40,
        9,
        0,
        49,
        16,
        0,
        49,
        19,
        13,
        49,
        32,
        14,
        1,
        49,
        43,
        43,
        34,
        6,
        49,
        47,
        49,
        25,
        25
      ],
      "weeklyRejected": [
        40,
        47,
        9,
        9,
        49,
        0,
        49,
        49,
        49,
        14,
        0,
        4,
        0,
        17,
        43,
        28,
        23,
        25,
        34,
        29,
        28,
        17,
        49,
        44,
        0,
        49,
        16,
        18,
        27,
        8,
        11,
        47,
        47,
        49,
        44,
        42,
        12,
        12,
        22,
        49,
        7,
        19,
        12,
        13,
        49,
        11,
        7,
        39,
        27,
        49,
        8,
        40
      ],
      "weeklyHold": [
        32,
        9,
        37,
        25,
        49,
        38,
        3,
        41,
        28,
        12,
        37,
        37,
        29,
        1,
        24,
        15,
        26,
        17,
        0,
        3,
        0,
        10,
        30,
        41,
        49,
        18,
        21,
        31,
        28,
        0,
        39,
        0,
        30,
        25,
        48,
        33,
        10,
        16,
        32,
        1,
        34,
        49,
        42,
        2,
        24,
        34,
        3,
        21,
        5,
        15,
        14,
        20
      ],
      "weeklyBackordered": [
        30,
        13,
        0,
        0,
        31,
        43,
        3,
        49,
        0,
        6,
        14,
        10,
        18,
        33,
        36,
        30,
        9,
        42,
        0,
        43,
        36,
        3,
        19,
        9,
        48,
        25,
        42,
        18,
        29,
        40,
        28,
        28,
        29,
        40,
        0,
        8,
        13,
        30,
        0,
        35,
        12,
        37,
        1,
        0,
        6,
        5,
        15,
        36,
        36,
        39,
        33,
        27
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        0,
        49,
        16,
        14,
        15,
        0,
        23,
        30,
        48,
        0,
        49,
        8,
        0,
        14,
        11,
        0,
        25,
        25,
        0,
        32,
        28,
        26,
        38,
        34,
        16,
        47,
        49,
        28,
        4,
        3,
        18,
        14,
        19,
        4,
        21,
        49,
        21,
        36,
        0,
        49,
        14,
        0,
        49,
        36,
        42,
        10,
        23,
        19,
        46,
        44,
        34,
        4
      ],
      "weeklyRejected": [
        32,
        18,
        22,
        36,
        9,
        13,
        17,
        49,
        24,
        31,
        24,
        37,
        4,
        39,
        19,
        22,
        7,
        49,
        0,
        12,
        49,
        0,
        45,
        25,
        9,
        47,
        18,
        3,
        48,
        28,
        39,
        26,
        38,
        49,
        16,
        49,
        39,
        45,
        49,
        13,
        48,
        19,
        20,
        23,
        46,
        15,
        0,
        37,
        11,
        49,
        0,
        29
      ],
      "weeklyHold": [
        15,
        32,
        48,
        24,
        18,
        48,
        2,
        43,
        24,
        33,
        14,
        31,
        14,
        28,
        21,
        0,
        4,
        19,
        49,
        10,
        13,
        0,
        12,
        27,
        48,
        30,
        49,
        13,
        13,
        20,
        35,
        9,
        34,
        12,
        32,
        28,
        7,
        0,
        3,
        26,
        20,
        49,
        0,
        47,
        39,
        49,
        27,
        49,
        28,
        31,
        49,
        0
      ],
      "weeklyBackordered": [
        25,
        11,
        0,
        24,
        37,
        32,
        0,
        30,
        7,
        18,
        24,
        45,
        3,
        17,
        49,
        1,
        41,
        15,
        0,
        18,
        49,
        46,
        32,
        17,
        11,
        18,
        2,
        22,
        20,
        49,
        0,
        49,
        43,
        49,
        49,
        4,
        22,
        0,
        33,
        12,
        11,
        48,
        17,
        40,
        17,
        0,
        31,
        22,
        16,
        29,
        12,
        32
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        0,
        38,
        15,
        33,
        24,
        19,
        28,
        40,
        17,
        33,
        49,
        16,
        23,
        31,
        33,
        34,
        14,
        0,
        3,
        28,
        0,
        34,
        2,
        40,
        30,
        47,
        0,
        9,
        13,
        46,
        0,
        27,
        14,
        0,
        37,
        8,
        0,
        22,
        19,
        26,
        12,
        49,
        49,
        24,
        49,
        26,
        0,
        49,
        0,
        49,
        26,
        17
      ],
      "weeklyRejected": [
        49,
        12,
        49,
        19,
        23,
        20,
        11,
        49,
        49,
        1,
        49,
        34,
        49,
        30,
        36,
        34,
        49,
        43,
        0,
        25,
        12,
        11,
        49,
        9,
        33,
        30,
        49,
        34,
        40,
        40,
        49,
        39,
        32,
        49,
        32,
        49,
        49,
        49,
        36,
        40,
        19,
        35,
        12,
        0,
        31,
        43,
        6,
        15,
        0,
        28,
        1,
        49
      ],
      "weeklyHold": [
        1,
        21,
        38,
        1,
        0,
        48,
        14,
        23,
        23,
        23,
        16,
        1,
        45,
        23,
        38,
        2,
        8,
        13,
        40,
        40,
        33,
        25,
        49,
        14,
        25,
        40,
        27,
        49,
        35,
        32,
        48,
        9,
        13,
        40,
        45,
        24,
        24,
        19,
        19,
        15,
        49,
        49,
        49,
        27,
        19,
        0,
        0,
        49,
        26,
        44,
        30,
        3
      ],
      "weeklyBackordered": [
        38,
        30,
        25,
        5,
        48,
        31,
        43,
        34,
        48,
        27,
        49,
        44,
        49,
        34,
        36,
        1,
        23,
        21,
        49,
        45,
        4,
        8,
        31,
        1,
        21,
        20,
        30,
        25,
        39,
        24,
        22,
        0,
        48,
        49,
        36,
        9,
        48,
        0,
        17,
        30,
        12,
        48,
        0,
        22,
        11,
        18,
        35,
        41,
        20,
        49,
        49,
        23
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        25,
        35,
        16,
        10,
        33,
        0,
        31,
        49,
        0,
        29,
        44,
        29,
        40,
        43,
        12,
        49,
        49,
        8,
        28,
        0,
        4,
        18,
        16,
        49,
        10,
        27,
        0,
        43,
        38,
        27,
        34,
        16,
        10,
        24,
        26,
        12,
        1,
        48,
        34,
        49,
        28,
        19,
        33,
        22,
        49,
        0,
        2,
        49,
        23,
        16,
        49,
        10
      ],
      "weeklyRejected": [
        40,
        16,
        3,
        38,
        23,
        14,
        13,
        43,
        49,
        9,
        49,
        40,
        45,
        35,
        29,
        4,
        0,
        0,
        29,
        0,
        32,
        49,
        18,
        49,
        5,
        33,
        7,
        32,
        24,
        28,
        39,
        47,
        47,
        29,
        9,
        26,
        49,
        36,
        16,
        5,
        44,
        33,
        33,
        6,
        17,
        7,
        0,
        34,
        0,
        49,
        0,
        44
      ],
      "weeklyHold": [
        49,
        27,
        49,
        18,
        32,
        13,
        49,
        2,
        7,
        49,
        35,
        14,
        47,
        8,
        49,
        0,
        49,
        29,
        14,
        13,
        37,
        40,
        4,
        21,
        46,
        30,
        1,
        33,
        34,
        0,
        32,
        22,
        1,
        49,
        25,
        41,
        22,
        30,
        19,
        25,
        21,
        35,
        4,
        49,
        46,
        48,
        4,
        31,
        1,
        30,
        28,
        25
      ],
      "weeklyBackordered": [
        40,
        34,
        40,
        49,
        41,
        23,
        32,
        49,
        49,
        0,
        35,
        44,
        10,
        43,
        13,
        10,
        48,
        49,
        21,
        42,
        25,
        24,
        0,
        16,
        37,
        24,
        3,
        40,
        20,
        23,
        49,
        39,
        26,
        28,
        4,
        0,
        28,
        34,
        6,
        24,
        18,
        48,
        0,
        49,
        28,
        46,
        36,
        0,
        32,
        30,
        25,
        20
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        2,
        18,
        5,
        48,
        43,
        28,
        1,
        21,
        14,
        49,
        38,
        4,
        37,
        33,
        1,
        0,
        37,
        48,
        11,
        15,
        48,
        49,
        41,
        46,
        11,
        47,
        2,
        49,
        8,
        8,
        49,
        19,
        2,
        0,
        48,
        37,
        16,
        17,
        2,
        45,
        32,
        33,
        49,
        34,
        49,
        30,
        0,
        8,
        49,
        49,
        26,
        13
      ],
      "weeklyRejected": [
        36,
        8,
        36,
        43,
        26,
        0,
        16,
        48,
        8,
        17,
        33,
        49,
        30,
        36,
        44,
        49,
        11,
        44,
        18,
        27,
        12,
        36,
        28,
        25,
        35,
        49,
        49,
        49,
        35,
        11,
        49,
        25,
        48,
        42,
        21,
        18,
        26,
        26,
        1,
        9,
        21,
        34,
        40,
        31,
        47,
        9,
        19,
        22,
        11,
        16,
        28,
        41
      ],
      "weeklyHold": [
        0,
        35,
        41,
        23,
        17,
        39,
        42,
        18,
        11,
        24,
        13,
        20,
        38,
        29,
        0,
        0,
        24,
        24,
        0,
        38,
        17,
        49,
        0,
        8,
        0,
        28,
        40,
        6,
        34,
        0,
        37,
        11,
        15,
        14,
        47,
        17,
        23,
        20,
        36,
        18,
        19,
        36,
        8,
        0,
        49,
        25,
        23,
        24,
        13,
        20,
        49,
        24
      ],
      "weeklyBackordered": [
        43,
        23,
        20,
        29,
        46,
        46,
        10,
        43,
        17,
        21,
        31,
        18,
        13,
        29,
        0,
        7,
        45,
        27,
        0,
        13,
        49,
        19,
        0,
        14,
        43,
        1,
        49,
        26,
        24,
        38,
        24,
        14,
        17,
        49,
        0,
        0,
        30,
        48,
        48,
        34,
        28,
        48,
        40,
        49,
        39,
        49,
        30,
        15,
        20,
        49,
        36,
        1
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        0,
        49,
        49,
        48,
        23,
        13,
        37,
        31,
        48,
        32,
        49,
        0,
        41,
        0,
        43,
        34,
        2,
        28,
        10,
        30,
        4,
        49,
        49,
        46,
        33,
        27,
        28,
        15,
        26,
        25,
        10,
        15,
        32,
        32,
        48,
        22,
        8,
        45,
        22,
        49,
        0,
        12,
        49,
        41,
        41,
        41,
        18,
        37,
        34,
        38,
        9,
        19
      ],
      "weeklyRejected": [
        8,
        2,
        49,
        47,
        23,
        40,
        18,
        1,
        48,
        17,
        44,
        5,
        21,
        18,
        40,
        13,
        6,
        33,
        29,
        49,
        20,
        39,
        1,
        42,
        33,
        35,
        0,
        31,
        43,
        18,
        37,
        46,
        34,
        43,
        47,
        49,
        44,
        3,
        1,
        9,
        18,
        41,
        43,
        0,
        49,
        0,
        24,
        49,
        22,
        35,
        0,
        24
      ],
      "weeklyHold": [
        10,
        3,
        23,
        1,
        27,
        27,
        49,
        2,
        6,
        1,
        27,
        9,
        48,
        1,
        40,
        21,
        49,
        25,
        25,
        39,
        15,
        0,
        27,
        31,
        21,
        11,
        5,
        32,
        17,
        5,
        34,
        0,
        16,
        49,
        6,
        49,
        49,
        0,
        16,
        16,
        18,
        37,
        0,
        28,
        19,
        16,
        3,
        49,
        17,
        16,
        43,
        49
      ],
      "weeklyBackordered": [
        34,
        43,
        4,
        22,
        35,
        19,
        45,
        24,
        17,
        0,
        12,
        49,
        3,
        44,
        49,
        33,
        6,
        49,
        6,
        19,
        44,
        24,
        0,
        32,
        27,
        10,
        6,
        0,
        38,
        0,
        19,
        34,
        29,
        38,
        32,
        0,
        5,
        22,
        19,
        44,
        13,
        48,
        20,
        49,
        0,
        22,
        8,
        20,
        24,
        43,
        38,
        5
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        2,
        1,
        11,
        48,
        2,
        0,
        33,
        27,
        43,
        21,
        42,
        5,
        35,
        49,
        21,
        45,
        24,
        6,
        23,
        26,
        25,
        49,
        49,
        7,
        49,
        30,
        9,
        49,
        21,
        17,
        0,
        35,
        44,
        20,
        48,
        42,
        43,
        15,
        20,
        49,
        6,
        17,
        49,
        28,
        32,
        15,
        10,
        3,
        33,
        49,
        0,
        29
      ],
      "weeklyRejected": [
        22,
        22,
        17,
        23,
        18,
        35,
        49,
        3,
        23,
        32,
        34,
        22,
        46,
        48,
        20,
        49,
        3,
        44,
        26,
        35,
        48,
        21,
        26,
        32,
        34,
        20,
        29,
        20,
        48,
        15,
        0,
        47,
        38,
        49,
        14,
        15,
        39,
        46,
        8,
        0,
        24,
        15,
        16,
        12,
        19,
        7,
        0,
        15,
        22,
        16,
        17,
        40
      ],
      "weeklyHold": [
        29,
        6,
        25,
        1,
        40,
        2,
        0,
        26,
        32,
        3,
        47,
        26,
        13,
        36,
        10,
        2,
        37,
        45,
        36,
        27,
        49,
        41,
        40,
        0,
        45,
        27,
        15,
        19,
        24,
        3,
        48,
        0,
        0,
        44,
        48,
        33,
        0,
        9,
        6,
        37,
        0,
        32,
        11,
        13,
        35,
        22,
        34,
        49,
        0,
        32,
        24,
        0
      ],
      "weeklyBackordered": [
        17,
        49,
        18,
        11,
        49,
        36,
        39,
        41,
        29,
        16,
        12,
        48,
        15,
        12,
        35,
        49,
        49,
        40,
        0,
        49,
        34,
        0,
        1,
        11,
        1,
        5,
        18,
        37,
        12,
        49,
        0,
        5,
        28,
        21,
        27,
        0,
        21,
        14,
        49,
        49,
        48,
        48,
        0,
        38,
        19,
        17,
        49,
        38,
        0,
        43,
        34,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        1,
        16,
        49,
        37,
        19,
        18,
        31,
        49,
        34,
        31,
        17,
        6,
        41,
        22,
        49,
        26,
        49,
        0,
        40,
        14,
        49,
        19,
        32,
        14,
        0,
        31,
        14,
        25,
        1,
        21,
        44,
        15,
        49,
        0,
        18,
        5,
        8,
        21,
        7,
        49,
        17,
        15,
        49,
        48,
        39,
        0,
        0,
        31,
        33,
        41,
        20,
        26
      ],
      "weeklyRejected": [
        23,
        17,
        32,
        31,
        34,
        22,
        42,
        49,
        0,
        30,
        49,
        44,
        23,
        41,
        26,
        4,
        1,
        24,
        31,
        23,
        28,
        43,
        11,
        49,
        19,
        49,
        49,
        9,
        41,
        38,
        49,
        20,
        35,
        17,
        35,
        32,
        17,
        16,
        1,
        15,
        38,
        49,
        13,
        0,
        49,
        23,
        4,
        47,
        0,
        49,
        17,
        49
      ],
      "weeklyHold": [
        45,
        39,
        32,
        33,
        2,
        5,
        5,
        28,
        19,
        26,
        19,
        26,
        1,
        32,
        22,
        12,
        3,
        37,
        14,
        16,
        44,
        27,
        10,
        35,
        20,
        32,
        29,
        24,
        24,
        14,
        44,
        20,
        21,
        13,
        48,
        49,
        17,
        9,
        23,
        21,
        30,
        19,
        33,
        20,
        40,
        43,
        38,
        49,
        0,
        12,
        43,
        26
      ],
      "weeklyBackordered": [
        0,
        41,
        0,
        33,
        18,
        14,
        3,
        29,
        34,
        9,
        10,
        47,
        49,
        19,
        34,
        1,
        12,
        49,
        5,
        24,
        23,
        2,
        23,
        7,
        8,
        35,
        12,
        49,
        24,
        3,
        41,
        13,
        31,
        49,
        1,
        0,
        16,
        0,
        0,
        18,
        0,
        35,
        13,
        30,
        23,
        36,
        49,
        36,
        27,
        16,
        38,
        31
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        17,
        33,
        25,
        0,
        49,
        3,
        18,
        42,
        48,
        24,
        29,
        8,
        27,
        22,
        1,
        44,
        49,
        11,
        0,
        49,
        25,
        22,
        25,
        49,
        11,
        42,
        49,
        45,
        22,
        1,
        18,
        29,
        17,
        27,
        48,
        49,
        15,
        48,
        25,
        49,
        49,
        38,
        49,
        28,
        32,
        15,
        12,
        49,
        15,
        32,
        10,
        15
      ],
      "weeklyRejected": [
        31,
        17,
        26,
        26,
        13,
        49,
        10,
        49,
        0,
        48,
        37,
        14,
        0,
        48,
        48,
        14,
        0,
        49,
        23,
        12,
        39,
        41,
        0,
        15,
        35,
        49,
        40,
        14,
        7,
        17,
        35,
        41,
        48,
        33,
        15,
        48,
        25,
        34,
        25,
        0,
        16,
        0,
        14,
        0,
        44,
        7,
        49,
        43,
        0,
        25,
        0,
        23
      ],
      "weeklyHold": [
        8,
        24,
        30,
        40,
        39,
        48,
        49,
        17,
        29,
        8,
        15,
        1,
        36,
        38,
        16,
        7,
        22,
        18,
        14,
        16,
        0,
        21,
        15,
        16,
        37,
        29,
        38,
        49,
        37,
        13,
        47,
        22,
        11,
        49,
        38,
        19,
        20,
        4,
        11,
        0,
        0,
        25,
        9,
        27,
        48,
        49,
        20,
        49,
        21,
        27,
        49,
        39
      ],
      "weeklyBackordered": [
        19,
        27,
        0,
        41,
        49,
        36,
        8,
        5,
        6,
        10,
        34,
        43,
        3,
        49,
        30,
        16,
        49,
        49,
        8,
        30,
        45,
        20,
        21,
        19,
        1,
        1,
        17,
        42,
        1,
        31,
        0,
        27,
        20,
        34,
        19,
        0,
        45,
        43,
        49,
        47,
        0,
        48,
        14,
        49,
        6,
        35,
        49,
        32,
        5,
        36,
        23,
        27
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        24,
        33,
        48,
        38,
        1,
        9,
        1,
        5,
        0,
        0,
        49,
        0,
        49,
        49,
        9,
        49,
        23,
        45,
        18,
        22,
        5,
        19,
        23,
        49,
        0,
        47,
        6,
        43,
        1,
        23,
        49,
        42,
        30,
        2,
        10,
        44,
        15,
        30,
        15,
        49,
        23,
        22,
        35,
        22,
        37,
        0,
        22,
        15,
        11,
        49,
        34,
        14
      ],
      "weeklyRejected": [
        0,
        49,
        49,
        29,
        40,
        0,
        21,
        27,
        49,
        35,
        42,
        0,
        49,
        32,
        48,
        22,
        20,
        30,
        29,
        49,
        10,
        46,
        10,
        15,
        7,
        49,
        49,
        14,
        48,
        26,
        34,
        38,
        0,
        31,
        36,
        49,
        0,
        6,
        14,
        18,
        37,
        47,
        47,
        20,
        36,
        6,
        0,
        7,
        38,
        49,
        0,
        49
      ],
      "weeklyHold": [
        36,
        28,
        41,
        1,
        21,
        20,
        31,
        27,
        46,
        29,
        0,
        41,
        36,
        1,
        30,
        28,
        0,
        34,
        8,
        9,
        0,
        39,
        0,
        4,
        0,
        24,
        7,
        11,
        38,
        9,
        33,
        42,
        0,
        38,
        18,
        27,
        21,
        0,
        43,
        8,
        15,
        20,
        40,
        49,
        49,
        49,
        0,
        49,
        0,
        30,
        30,
        10
      ],
      "weeklyBackordered": [
        9,
        34,
        19,
        49,
        47,
        12,
        0,
        48,
        49,
        49,
        21,
        25,
        3,
        27,
        5,
        13,
        37,
        0,
        25,
        35,
        27,
        5,
        28,
        37,
        10,
        1,
        23,
        49,
        43,
        49,
        44,
        0,
        29,
        34,
        6,
        8,
        25,
        0,
        0,
        12,
        1,
        34,
        41,
        9,
        0,
        37,
        49,
        49,
        30,
        47,
        49,
        11
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyAccepted": [
        0,
        21,
        49,
        35,
        24,
        15,
        3,
        49,
        22,
        49,
        20,
        3,
        42,
        26,
        42,
        27,
        31,
        2,
        14,
        49,
        0,
        36,
        49,
        33,
        32,
        47,
        25,
        0,
        43,
        11,
        5,
        21,
        30,
        39,
        26,
        21,
        0,
        0,
        49,
        49,
        0,
        25,
        49,
        41,
        49,
        17,
        4,
        0,
        7,
        29,
        39,
        29
      ],
      "weeklyRejected": [
        30,
        49,
        13,
        26,
        29,
        3,
        35,
        40,
        49,
        27,
        18,
        5,
        0,
        5,
        16,
        28,
        49,
        49,
        17,
        11,
        2,
        24,
        25,
        42,
        25,
        49,
        49,
        49,
        40,
        31,
        14,
        29,
        32,
        49,
        0,
        49,
        32,
        41,
        7,
        36,
        23,
        49,
        20,
        6,
        47,
        48,
        33,
        36,
        21,
        36,
        0,
        49
      ],
      "weeklyHold": [
        0,
        26,
        37,
        26,
        0,
        27,
        4,
        2,
        19,
        11,
        27,
        15,
        22,
        38,
        16,
        1,
        1,
        33,
        13,
        15,
        23,
        48,
        25,
        28,
        23,
        26,
        7,
        18,
        49,
        21,
        48,
        43,
        8,
        49,
        17,
        21,
        49,
        11,
        34,
        42,
        0,
        12,
        49,
        40,
        22,
        43,
        14,
        41,
        0,
        49,
        48,
        4
      ],
      "weeklyBackordered": [
        19,
        27,
        19,
        35,
        0,
        40,
        32,
        28,
        20,
        21,
        49,
        19,
        46,
        40,
        25,
        34,
        40,
        33,
        0,
        33,
        46,
        0,
        0,
        27,
        26,
        7,
        2,
        1,
        31,
        22,
        0,
        23,
        18,
        0,
        0,
        13,
        31,
        28,
        43,
        37,
        25,
        43,
        7,
        44,
        43,
        37,
        18,
        30,
        4,
        17,
        33,
        1
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        22,
        19,
        49,
        22,
        17,
        40,
        8,
        9,
        38,
        5,
        12,
        0,
        11,
        37,
        22,
        48,
        40,
        0,
        0,
        31,
        8,
        48,
        45,
        0,
        38,
        47,
        30,
        7,
        30,
        14,
        27,
        32,
        49,
        19,
        48,
        47,
        27,
        40,
        36,
        49,
        8,
        37,
        49,
        34,
        12,
        0,
        0,
        16,
        0,
        49,
        49,
        20
      ],
      "weeklyRejected": [
        49,
        40,
        31,
        23,
        12,
        18,
        16,
        36,
        49,
        23,
        23,
        4,
        19,
        1,
        48,
        6,
        39,
        47,
        49,
        7,
        25,
        29,
        31,
        49,
        13,
        49,
        17,
        13,
        47,
        27,
        2,
        41,
        39,
        36,
        36,
        49,
        45,
        18,
        31,
        2,
        25,
        31,
        35,
        23,
        49,
        35,
        19,
        18,
        18,
        31,
        0,
        49
      ],
      "weeklyHold": [
        40,
        30,
        34,
        1,
        0,
        40,
        31,
        49,
        45,
        10,
        24,
        6,
        2,
        1,
        49,
        13,
        9,
        23,
        0,
        0,
        43,
        25,
        16,
        0,
        0,
        21,
        44,
        21,
        49,
        5,
        40,
        0,
        0,
        40,
        14,
        42,
        0,
        0,
        28,
        25,
        22,
        24,
        3,
        36,
        49,
        29,
        6,
        49,
        0,
        41,
        22,
        47
      ],
      "weeklyBackordered": [
        49,
        0,
        15,
        23,
        46,
        46,
        0,
        1,
        1,
        49,
        10,
        20,
        5,
        49,
        27,
        10,
        37,
        39,
        9,
        42,
        46,
        0,
        0,
        16,
        1,
        46,
        41,
        49,
        7,
        34,
        17,
        23,
        43,
        19,
        31,
        0,
        20,
        20,
        16,
        0,
        29,
        44,
        28,
        12,
        13,
        13,
        40,
        3,
        0,
        10,
        16,
        16
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        8,
        49,
        20,
        15,
        14,
        49,
        1,
        23,
        36,
        42,
        43,
        7,
        17,
        49,
        44,
        37,
        23,
        3,
        46,
        7,
        14,
        10,
        29,
        20,
        26,
        47,
        48,
        0,
        31,
        7,
        0,
        20,
        49,
        42,
        23,
        44,
        0,
        42,
        20,
        49,
        9,
        44,
        49,
        33,
        0,
        30,
        23,
        34,
        0,
        49,
        6,
        18
      ],
      "weeklyRejected": [
        49,
        12,
        45,
        40,
        15,
        0,
        49,
        18,
        49,
        12,
        0,
        20,
        21,
        25,
        15,
        28,
        9,
        49,
        39,
        31,
        21,
        3,
        43,
        48,
        31,
        49,
        33,
        49,
        37,
        45,
        42,
        47,
        43,
        5,
        27,
        49,
        49,
        27,
        1,
        46,
        48,
        49,
        40,
        14,
        47,
        24,
        24,
        45,
        0,
        47,
        29,
        28
      ],
      "weeklyHold": [
        19,
        15,
        0,
        17,
        48,
        33,
        39,
        30,
        29,
        17,
        47,
        1,
        48,
        49,
        24,
        0,
        43,
        0,
        0,
        22,
        34,
        43,
        18,
        27,
        30,
        21,
        24,
        19,
        49,
        6,
        48,
        0,
        17,
        16,
        37,
        49,
        30,
        16,
        18,
        40,
        4,
        8,
        0,
        23,
        1,
        0,
        22,
        41,
        0,
        49,
        42,
        0
      ],
      "weeklyBackordered": [
        49,
        30,
        8,
        0,
        49,
        42,
        32,
        28,
        46,
        22,
        26,
        0,
        30,
        45,
        28,
        18,
        35,
        49,
        22,
        9,
        32,
        31,
        12,
        49,
        42,
        24,
        12,
        13,
        41,
        40,
        21,
        49,
        0,
        47,
        10,
        0,
        49,
        31,
        35,
        25,
        0,
        41,
        23,
        49,
        49,
        23,
        49,
        16,
        18,
        3,
        38,
        36
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        43,
        22,
        31,
        20,
        7,
        0,
        49,
        7,
        22,
        0,
        42,
        18,
        21,
        20,
        30,
        20,
        40,
        18,
        32,
        35,
        39,
        26,
        39,
        0,
        37,
        35,
        12,
        36,
        21,
        32,
        29,
        20,
        49,
        19,
        48,
        14,
        12,
        28,
        8,
        27,
        45,
        40,
        25,
        11,
        49,
        30,
        0,
        49,
        7,
        0,
        31,
        21
      ],
      "weeklyRejected": [
        32,
        5,
        13,
        16,
        30,
        18,
        37,
        32,
        22,
        1,
        42,
        45,
        22,
        30,
        27,
        2,
        49,
        49,
        12,
        34,
        49,
        46,
        20,
        14,
        20,
        49,
        36,
        21,
        48,
        3,
        19,
        35,
        48,
        49,
        0,
        45,
        4,
        0,
        34,
        9,
        18,
        26,
        18,
        0,
        48,
        10,
        0,
        29,
        24,
        47,
        0,
        49
      ],
      "weeklyHold": [
        49,
        21,
        36,
        1,
        22,
        48,
        0,
        7,
        20,
        27,
        47,
        42,
        23,
        8,
        13,
        18,
        44,
        48,
        35,
        27,
        17,
        12,
        36,
        42,
        25,
        48,
        29,
        0,
        49,
        4,
        44,
        0,
        0,
        46,
        17,
        19,
        27,
        0,
        49,
        28,
        1,
        34,
        0,
        14,
        49,
        49,
        23,
        0,
        33,
        27,
        32,
        49
      ],
      "weeklyBackordered": [
        28,
        47,
        11,
        36,
        36,
        46,
        14,
        30,
        40,
        29,
        33,
        30,
        12,
        4,
        49,
        25,
        4,
        41,
        0,
        49,
        7,
        0,
        6,
        27,
        19,
        18,
        3,
        0,
        16,
        15,
        31,
        34,
        28,
        42,
        0,
        0,
        1,
        11,
        22,
        31,
        3,
        48,
        5,
        26,
        32,
        48,
        19,
        43,
        15,
        28,
        41,
        1
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        35,
        49,
        28,
        41,
        25,
        21,
        11,
        11,
        48,
        23,
        37,
        8,
        41,
        36,
        13,
        40,
        39,
        44,
        9,
        0,
        6,
        20,
        12,
        49,
        37,
        21,
        0,
        17,
        24,
        26,
        26,
        22,
        32,
        0,
        48,
        28,
        13,
        1,
        31,
        45,
        32,
        23,
        33,
        37,
        49,
        17,
        2,
        7,
        37,
        39,
        8,
        29
      ],
      "weeklyRejected": [
        32,
        16,
        20,
        32,
        28,
        22,
        12,
        17,
        49,
        41,
        26,
        7,
        20,
        48,
        14,
        10,
        23,
        41,
        28,
        47,
        38,
        49,
        0,
        36,
        14,
        18,
        39,
        28,
        4,
        32,
        21,
        44,
        40,
        49,
        0,
        49,
        29,
        31,
        1,
        28,
        24,
        47,
        32,
        6,
        23,
        0,
        0,
        22,
        14,
        29,
        0,
        35
      ],
      "weeklyHold": [
        10,
        22,
        28,
        1,
        37,
        0,
        35,
        20,
        49,
        39,
        19,
        47,
        41,
        28,
        14,
        30,
        22,
        48,
        0,
        49,
        30,
        49,
        11,
        41,
        9,
        38,
        9,
        36,
        25,
        0,
        48,
        9,
        10,
        49,
        38,
        2,
        22,
        0,
        39,
        12,
        5,
        24,
        18,
        7,
        47,
        35,
        47,
        46,
        7,
        46,
        3,
        47
      ],
      "weeklyBackordered": [
        32,
        16,
        17,
        49,
        49,
        27,
        49,
        40,
        49,
        7,
        22,
        38,
        25,
        45,
        21,
        32,
        9,
        36,
        19,
        22,
        47,
        42,
        19,
        25,
        20,
        26,
        47,
        41,
        12,
        26,
        0,
        5,
        23,
        41,
        49,
        0,
        27,
        0,
        11,
        32,
        10,
        25,
        2,
        49,
        16,
        0,
        25,
        38,
        0,
        17,
        49,
        11
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        1,
        2,
        32,
        34,
        49,
        31,
        23,
        49,
        48,
        5,
        22,
        0,
        15,
        44,
        27,
        18,
        35,
        30,
        21,
        35,
        19,
        0,
        1,
        45,
        15,
        38,
        22,
        26,
        1,
        23,
        6,
        33,
        49,
        0,
        3,
        10,
        26,
        29,
        49,
        33,
        44,
        30,
        49,
        18,
        20,
        14,
        24,
        39,
        1,
        24,
        30,
        43
      ],
      "weeklyRejected": [
        41,
        0,
        30,
        15,
        49,
        42,
        25,
        40,
        49,
        11,
        49,
        20,
        21,
        34,
        48,
        25,
        15,
        49,
        43,
        28,
        49,
        49,
        0,
        49,
        5,
        10,
        49,
        8,
        47,
        16,
        33,
        31,
        48,
        25,
        17,
        26,
        30,
        0,
        1,
        49,
        43,
        33,
        27,
        8,
        49,
        31,
        33,
        0,
        14,
        47,
        15,
        32
      ],
      "weeklyHold": [
        26,
        34,
        15,
        12,
        39,
        45,
        49,
        16,
        47,
        23,
        47,
        20,
        36,
        20,
        17,
        17,
        0,
        0,
        7,
        22,
        9,
        49,
        0,
        26,
        32,
        1,
        0,
        26,
        49,
        3,
        20,
        38,
        0,
        34,
        48,
        36,
        29,
        7,
        26,
        12,
        36,
        28,
        31,
        49,
        49,
        49,
        37,
        49,
        18,
        49,
        0,
        20
      ],
      "weeklyBackordered": [
        46,
        36,
        0,
        48,
        38,
        20,
        33,
        40,
        14,
        29,
        13,
        30,
        18,
        47,
        11,
        1,
        49,
        16,
        21,
        20,
        32,
        40,
        49,
        49,
        1,
        22,
        8,
        25,
        22,
        40,
        6,
        49,
        19,
        14,
        1,
        21,
        35,
        3,
        24,
        33,
        32,
        22,
        7,
        33,
        0,
        24,
        49,
        6,
        13,
        31,
        1,
        49
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        3,
        11,
        0,
        17,
        47,
        21,
        16,
        28,
        32,
        49,
        46,
        12,
        43,
        9,
        48,
        2,
        49,
        13,
        29,
        37,
        17,
        49,
        49,
        41,
        5,
        37,
        0,
        27,
        1,
        21,
        19,
        49,
        31,
        23,
        46,
        47,
        27,
        11,
        26,
        49,
        3,
        16,
        49,
        49,
        49,
        10,
        22,
        21,
        5,
        15,
        31,
        35
      ],
      "weeklyRejected": [
        0,
        49,
        37,
        39,
        15,
        38,
        38,
        18,
        49,
        10,
        13,
        30,
        49,
        38,
        48,
        49,
        31,
        49,
        45,
        9,
        7,
        49,
        37,
        10,
        18,
        49,
        3,
        49,
        48,
        48,
        43,
        21,
        41,
        0,
        0,
        41,
        43,
        43,
        18,
        21,
        33,
        49,
        9,
        14,
        12,
        30,
        0,
        25,
        0,
        49,
        20,
        32
      ],
      "weeklyHold": [
        18,
        49,
        32,
        19,
        5,
        41,
        31,
        2,
        30,
        26,
        32,
        1,
        1,
        1,
        49,
        39,
        1,
        20,
        0,
        18,
        2,
        1,
        27,
        16,
        42,
        40,
        15,
        21,
        38,
        34,
        48,
        0,
        0,
        44,
        8,
        49,
        32,
        0,
        25,
        41,
        45,
        23,
        0,
        22,
        49,
        33,
        2,
        39,
        0,
        19,
        49,
        49
      ],
      "weeklyBackordered": [
        21,
        23,
        0,
        1,
        49,
        44,
        32,
        47,
        49,
        39,
        32,
        12,
        10,
        0,
        23,
        5,
        49,
        49,
        9,
        0,
        42,
        13,
        16,
        43,
        16,
        8,
        48,
        49,
        22,
        35,
        16,
        37,
        19,
        0,
        0,
        9,
        49,
        0,
        19,
        21,
        48,
        17,
        14,
        49,
        0,
        39,
        49,
        49,
        16,
        49,
        25,
        42
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        37,
        1,
        6,
        21,
        24,
        11,
        27,
        33,
        18,
        11,
        49,
        41,
        32,
        23,
        1,
        49,
        15,
        19,
        0,
        12,
        2,
        20,
        32,
        43,
        12,
        47,
        13,
        10,
        1,
        49,
        6,
        21,
        45,
        3,
        36,
        40,
        20,
        47,
        33,
        49,
        38,
        22,
        49,
        41,
        22,
        29,
        25,
        20,
        0,
        28,
        25,
        14
      ],
      "weeklyRejected": [
        49,
        46,
        3,
        30,
        45,
        0,
        40,
        2,
        31,
        20,
        20,
        49,
        38,
        48,
        12,
        36,
        1,
        49,
        19,
        14,
        0,
        49,
        14,
        13,
        33,
        32,
        33,
        8,
        48,
        16,
        49,
        46,
        48,
        33,
        15,
        49,
        47,
        32,
        34,
        7,
        39,
        49,
        13,
        8,
        49,
        25,
        13,
        6,
        9,
        49,
        0,
        49
      ],
      "weeklyHold": [
        31,
        0,
        49,
        28,
        0,
        7,
        35,
        34,
        8,
        18,
        0,
        24,
        15,
        27,
        12,
        22,
        11,
        24,
        31,
        6,
        21,
        10,
        24,
        23,
        15,
        36,
        0,
        16,
        49,
        0,
        47,
        5,
        16,
        18,
        28,
        29,
        25,
        34,
        33,
        0,
        49,
        39,
        11,
        21,
        49,
        28,
        49,
        3,
        6,
        0,
        27,
        42
      ],
      "weeklyBackordered": [
        49,
        0,
        25,
        12,
        49,
        16,
        25,
        49,
        21,
        49,
        49,
        36,
        3,
        49,
        40,
        45,
        23,
        27,
        26,
        49,
        33,
        33,
        12,
        6,
        4,
        26,
        49,
        49,
        29,
        49,
        0,
        12,
        48,
        49,
        4,
        0,
        7,
        0,
        6,
        14,
        3,
        19,
        15,
        21,
        12,
        0,
        48,
        6,
        40,
        38,
        43,
        30
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        27,
        35,
        18,
        6,
        1,
        7,
        1,
        29,
        14,
        18,
        49,
        40,
        49,
        0,
        49,
        49,
        23,
        27,
        0,
        2,
        19,
        49,
        4,
        25,
        35,
        47,
        42,
        27,
        1,
        17,
        8,
        0,
        49,
        43,
        0,
        18,
        5,
        18,
        18,
        49,
        32,
        49,
        13,
        28,
        41,
        16,
        8,
        49,
        24,
        49,
        22,
        30
      ],
      "weeklyRejected": [
        30,
        12,
        12,
        27,
        21,
        0,
        25,
        19,
        49,
        5,
        47,
        37,
        49,
        48,
        48,
        0,
        44,
        38,
        32,
        0,
        12,
        23,
        16,
        21,
        19,
        49,
        32,
        0,
        47,
        30,
        49,
        47,
        46,
        49,
        14,
        49,
        37,
        49,
        1,
        4,
        5,
        21,
        13,
        24,
        49,
        22,
        0,
        8,
        0,
        28,
        0,
        36
      ],
      "weeklyHold": [
        3,
        0,
        49,
        12,
        4,
        43,
        0,
        35,
        44,
        18,
        26,
        29,
        41,
        39,
        49,
        12,
        31,
        0,
        18,
        43,
        20,
        49,
        28,
        14,
        49,
        48,
        34,
        28,
        49,
        0,
        47,
        0,
        31,
        33,
        32,
        49,
        33,
        39,
        31,
        8,
        38,
        27,
        3,
        19,
        24,
        26,
        0,
        40,
        0,
        4,
        35,
        35
      ],
      "weeklyBackordered": [
        34,
        14,
        43,
        2,
        33,
        46,
        36,
        39,
        0,
        0,
        49,
        26,
        47,
        0,
        46,
        16,
        5,
        49,
        0,
        36,
        9,
        15,
        25,
        12,
        48,
        1,
        1,
        49,
        1,
        3,
        0,
        49,
        34,
        49,
        8,
        0,
        24,
        13,
        9,
        0,
        48,
        28,
        35,
        49,
        25,
        28,
        49,
        23,
        29,
        37,
        49,
        46
      ]
    },
    {
      "id": "8164C892",
      "weeklyAccepted": [
        18,
        6,
        49,
        39,
        35,
        3,
        3,
        49,
        32,
        15,
        49,
        39,
        21,
        43,
        42,
        36,
        44,
        21,
        29,
        41,
        9,
        13,
        18,
        28,
        6,
        25,
        1,
        16,
        13,
        24,
        10,
        49,
        38,
        18,
        18,
        11,
        29,
        17,
        25,
        49,
        0,
        35,
        49,
        37,
        49,
        26,
        49,
        24,
        0,
        24,
        18,
        25
      ],
      "weeklyRejected": [
        46,
        21,
        29,
        19,
        12,
        41,
        14,
        1,
        30,
        12,
        30,
        37,
        27,
        0,
        38,
        2,
        39,
        4,
        24,
        43,
        40,
        49,
        2,
        30,
        17,
        32,
        49,
        7,
        47,
        18,
        44,
        33,
        48,
        21,
        49,
        49,
        22,
        0,
        2,
        41,
        43,
        49,
        13,
        8,
        41,
        34,
        49,
        1,
        0,
        16,
        39,
        22
      ],
      "weeklyHold": [
        17,
        29,
        33,
        1,
        49,
        43,
        0,
        2,
        27,
        36,
        31,
        35,
        1,
        49,
        49,
        15,
        10,
        48,
        6,
        38,
        0,
        30,
        37,
        0,
        36,
        48,
        11,
        7,
        41,
        0,
        37,
        7,
        0,
        48,
        48,
        49,
        49,
        11,
        1,
        42,
        23,
        39,
        10,
        33,
        35,
        20,
        49,
        49,
        11,
        49,
        33,
        42
      ],
      "weeklyBackordered": [
        36,
        49,
        0,
        39,
        41,
        25,
        38,
        22,
        49,
        0,
        19,
        30,
        35,
        33,
        0,
        11,
        26,
        14,
        24,
        0,
        37,
        49,
        22,
        44,
        48,
        22,
        11,
        32,
        49,
        49,
        49,
        49,
        46,
        0,
        11,
        1,
        45,
        0,
        0,
        27,
        33,
        25,
        3,
        49,
        10,
        15,
        49,
        29,
        0,
        49,
        29,
        15
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        13,
        49,
        49,
        33,
        46,
        49,
        24,
        36,
        43,
        17,
        42,
        49,
        13,
        0,
        2,
        35,
        49,
        10,
        34,
        31,
        10,
        26,
        12,
        36,
        21,
        9,
        46,
        12,
        1,
        29,
        34,
        0,
        49,
        33,
        45,
        44,
        20,
        48,
        22,
        49,
        49,
        49,
        49,
        30,
        7,
        11,
        40,
        27,
        30,
        26,
        16,
        23
      ],
      "weeklyRejected": [
        40,
        12,
        30,
        23,
        24,
        35,
        18,
        37,
        31,
        24,
        30,
        37,
        34,
        26,
        47,
        0,
        0,
        49,
        40,
        23,
        6,
        34,
        8,
        49,
        1,
        49,
        49,
        5,
        40,
        48,
        25,
        47,
        30,
        0,
        34,
        17,
        37,
        41,
        13,
        3,
        48,
        42,
        16,
        19,
        49,
        12,
        0,
        18,
        18,
        49,
        0,
        39
      ],
      "weeklyHold": [
        18,
        0,
        49,
        15,
        45,
        46,
        34,
        49,
        13,
        34,
        34,
        39,
        39,
        35,
        5,
        0,
        8,
        31,
        36,
        0,
        25,
        31,
        34,
        38,
        47,
        48,
        42,
        23,
        20,
        39,
        48,
        5,
        1,
        21,
        7,
        28,
        44,
        8,
        33,
        15,
        12,
        36,
        0,
        49,
        14,
        49,
        13,
        31,
        4,
        34,
        10,
        47
      ],
      "weeklyBackordered": [
        28,
        5,
        24,
        23,
        49,
        37,
        30,
        33,
        41,
        0,
        49,
        46,
        25,
        49,
        10,
        31,
        21,
        22,
        0,
        3,
        49,
        5,
        35,
        14,
        25,
        1,
        13,
        45,
        13,
        23,
        15,
        49,
        48,
        49,
        0,
        0,
        49,
        28,
        5,
        11,
        0,
        12,
        8,
        49,
        18,
        3,
        42,
        49,
        0,
        25,
        45,
        40
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        18,
        49,
        48,
        48,
        41,
        17,
        14,
        26,
        22,
        19,
        17,
        8,
        11,
        48,
        1,
        0,
        49,
        0,
        0,
        41,
        49,
        49,
        38,
        0,
        10,
        30,
        0,
        3,
        8,
        44,
        29,
        47,
        49,
        0,
        48,
        49,
        47,
        48,
        25,
        28,
        13,
        2,
        44,
        49,
        49,
        0,
        0,
        49,
        49,
        48,
        20,
        14
      ],
      "weeklyRejected": [
        49,
        3,
        31,
        12,
        48,
        14,
        26,
        49,
        14,
        1,
        44,
        11,
        6,
        39,
        48,
        37,
        26,
        30,
        49,
        49,
        20,
        37,
        49,
        49,
        38,
        7,
        48,
        9,
        47,
        21,
        30,
        47,
        37,
        10,
        43,
        47,
        22,
        48,
        40,
        20,
        43,
        35,
        30,
        5,
        31,
        49,
        0,
        26,
        24,
        24,
        43,
        19
      ],
      "weeklyHold": [
        49,
        8,
        35,
        16,
        49,
        33,
        0,
        38,
        26,
        34,
        32,
        40,
        47,
        49,
        40,
        10,
        8,
        29,
        1,
        49,
        14,
        49,
        19,
        23,
        29,
        33,
        0,
        17,
        33,
        39,
        48,
        43,
        28,
        49,
        6,
        49,
        21,
        0,
        31,
        20,
        18,
        24,
        13,
        17,
        47,
        0,
        1,
        19,
        0,
        49,
        0,
        42
      ],
      "weeklyBackordered": [
        0,
        35,
        26,
        49,
        44,
        13,
        29,
        24,
        12,
        23,
        31,
        42,
        29,
        49,
        0,
        21,
        49,
        49,
        34,
        36,
        32,
        12,
        0,
        34,
        42,
        1,
        7,
        6,
        19,
        5,
        33,
        38,
        28,
        0,
        28,
        0,
        10,
        14,
        0,
        49,
        35,
        10,
        0,
        49,
        33,
        49,
        29,
        12,
        0,
        14,
        49,
        8
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyAccepted": [
        34,
        41,
        0,
        13,
        18,
        15,
        39,
        39,
        16,
        12,
        38,
        8,
        49,
        35,
        1,
        16,
        49,
        13,
        18,
        38,
        16,
        32,
        36,
        43,
        29,
        19,
        39,
        15,
        33,
        49,
        5,
        0,
        20,
        4,
        48,
        26,
        40,
        39,
        49,
        49,
        39,
        0,
        41,
        30,
        49,
        49,
        0,
        23,
        5,
        41,
        16,
        0
      ],
      "weeklyRejected": [
        14,
        28,
        29,
        16,
        42,
        3,
        0,
        20,
        44,
        20,
        7,
        49,
        0,
        45,
        46,
        6,
        8,
        40,
        43,
        39,
        14,
        0,
        49,
        32,
        28,
        47,
        29,
        17,
        43,
        34,
        18,
        47,
        47,
        13,
        0,
        48,
        48,
        49,
        29,
        38,
        35,
        26,
        43,
        22,
        49,
        14,
        0,
        18,
        25,
        30,
        25,
        49
      ],
      "weeklyHold": [
        11,
        24,
        44,
        8,
        16,
        38,
        0,
        7,
        17,
        16,
        47,
        37,
        8,
        32,
        48,
        35,
        0,
        16,
        46,
        6,
        38,
        7,
        25,
        0,
        4,
        33,
        19,
        45,
        31,
        21,
        36,
        34,
        0,
        49,
        41,
        20,
        28,
        22,
        38,
        41,
        49,
        49,
        0,
        17,
        40,
        45,
        35,
        28,
        16,
        4,
        37,
        11
      ],
      "weeklyBackordered": [
        33,
        32,
        0,
        35,
        23,
        46,
        36,
        36,
        3,
        0,
        49,
        22,
        3,
        44,
        0,
        49,
        29,
        49,
        0,
        36,
        39,
        21,
        6,
        0,
        31,
        26,
        36,
        31,
        34,
        49,
        35,
        17,
        45,
        38,
        0,
        0,
        44,
        36,
        26,
        0,
        21,
        48,
        0,
        19,
        39,
        17,
        21,
        49,
        47,
        32,
        37,
        5
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        29,
        28,
        22,
        41,
        29,
        28,
        26,
        49,
        43,
        0,
        38,
        14,
        32,
        14,
        17,
        8,
        49,
        15,
        23,
        40,
        36,
        41,
        49,
        32,
        2,
        30,
        21,
        16,
        22,
        48,
        14,
        35,
        48,
        44,
        48,
        30,
        19,
        15,
        18,
        49,
        20,
        23,
        46,
        28,
        30,
        19,
        41,
        34,
        49,
        41,
        28,
        18
      ],
      "weeklyRejected": [
        27,
        18,
        10,
        14,
        31,
        39,
        31,
        49,
        30,
        27,
        49,
        21,
        9,
        29,
        48,
        15,
        19,
        49,
        4,
        38,
        30,
        26,
        33,
        41,
        49,
        13,
        42,
        13,
        8,
        29,
        24,
        36,
        24,
        49,
        49,
        48,
        44,
        0,
        9,
        18,
        38,
        35,
        31,
        8,
        34,
        26,
        0,
        26,
        12,
        19,
        14,
        49
      ],
      "weeklyHold": [
        27,
        2,
        49,
        1,
        15,
        34,
        26,
        30,
        20,
        27,
        39,
        45,
        1,
        42,
        49,
        29,
        40,
        24,
        44,
        38,
        18,
        32,
        19,
        34,
        47,
        14,
        0,
        5,
        38,
        36,
        41,
        4,
        1,
        14,
        20,
        24,
        42,
        0,
        33,
        39,
        37,
        28,
        46,
        23,
        41,
        19,
        0,
        32,
        21,
        49,
        10,
        28
      ],
      "weeklyBackordered": [
        23,
        35,
        47,
        47,
        49,
        42,
        30,
        1,
        38,
        36,
        0,
        31,
        16,
        2,
        28,
        37,
        22,
        8,
        4,
        0,
        0,
        44,
        0,
        29,
        3,
        19,
        4,
        0,
        23,
        49,
        19,
        49,
        38,
        38,
        15,
        20,
        6,
        48,
        31,
        49,
        48,
        12,
        13,
        9,
        29,
        5,
        47,
        0,
        29,
        49,
        2,
        21
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        49,
        49,
        16,
        32,
        7,
        0,
        1,
        40,
        48,
        22,
        23,
        0,
        49,
        25,
        1,
        16,
        39,
        0,
        0,
        21,
        24,
        37,
        49,
        49,
        46,
        47,
        36,
        37,
        27,
        49,
        12,
        15,
        46,
        2,
        24,
        34,
        47,
        48,
        49,
        48,
        16,
        13,
        49,
        48,
        45,
        11,
        0,
        13,
        16,
        49,
        13,
        2
      ],
      "weeklyRejected": [
        49,
        23,
        49,
        0,
        37,
        31,
        27,
        49,
        49,
        16,
        33,
        10,
        7,
        48,
        48,
        0,
        42,
        49,
        49,
        10,
        10,
        14,
        40,
        7,
        33,
        49,
        37,
        30,
        17,
        32,
        21,
        28,
        40,
        23,
        11,
        36,
        33,
        25,
        19,
        45,
        48,
        17,
        28,
        24,
        49,
        16,
        17,
        0,
        23,
        26,
        11,
        49
      ],
      "weeklyHold": [
        5,
        9,
        41,
        18,
        9,
        13,
        13,
        36,
        26,
        0,
        3,
        11,
        13,
        31,
        49,
        6,
        1,
        25,
        21,
        0,
        27,
        8,
        33,
        0,
        22,
        15,
        0,
        24,
        49,
        27,
        48,
        46,
        0,
        45,
        31,
        49,
        21,
        7,
        30,
        19,
        0,
        0,
        0,
        17,
        49,
        5,
        0,
        44,
        7,
        13,
        0,
        49
      ],
      "weeklyBackordered": [
        49,
        0,
        0,
        31,
        43,
        42,
        44,
        18,
        41,
        21,
        23,
        22,
        28,
        6,
        27,
        26,
        11,
        7,
        0,
        38,
        0,
        4,
        22,
        22,
        34,
        6,
        1,
        49,
        1,
        49,
        0,
        0,
        30,
        24,
        22,
        0,
        1,
        16,
        0,
        19,
        0,
        48,
        0,
        23,
        25,
        0,
        38,
        28,
        39,
        0,
        4,
        8
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        0,
        38,
        40,
        29,
        49,
        23,
        28,
        21,
        31,
        35,
        43,
        0,
        0,
        32,
        49,
        27,
        36,
        0,
        9,
        48,
        36,
        49,
        26,
        49,
        0,
        23,
        39,
        27,
        4,
        3,
        22,
        28,
        45,
        22,
        8,
        36,
        38,
        7,
        11,
        36,
        30,
        0,
        44,
        0,
        40,
        26,
        0,
        29,
        49,
        40,
        26,
        1
      ],
      "weeklyRejected": [
        12,
        27,
        9,
        47,
        22,
        10,
        36,
        49,
        22,
        13,
        49,
        49,
        12,
        48,
        47,
        17,
        45,
        0,
        1,
        10,
        15,
        17,
        49,
        43,
        49,
        49,
        16,
        12,
        48,
        7,
        41,
        47,
        43,
        49,
        19,
        42,
        0,
        34,
        17,
        19,
        19,
        38,
        21,
        9,
        49,
        49,
        22,
        49,
        0,
        31,
        0,
        32
      ],
      "weeklyHold": [
        38,
        31,
        38,
        21,
        6,
        24,
        49,
        30,
        17,
        0,
        47,
        24,
        34,
        1,
        49,
        16,
        12,
        13,
        6,
        34,
        36,
        19,
        0,
        3,
        25,
        21,
        45,
        0,
        29,
        48,
        29,
        4,
        6,
        33,
        28,
        49,
        0,
        0,
        45,
        16,
        49,
        38,
        49,
        30,
        49,
        49,
        0,
        32,
        1,
        0,
        32,
        42
      ],
      "weeklyBackordered": [
        23,
        38,
        30,
        20,
        49,
        33,
        49,
        21,
        24,
        33,
        45,
        49,
        13,
        21,
        2,
        45,
        7,
        49,
        40,
        42,
        36,
        0,
        3,
        31,
        14,
        7,
        14,
        0,
        2,
        49,
        10,
        49,
        35,
        40,
        0,
        24,
        49,
        0,
        49,
        48,
        18,
        33,
        0,
        49,
        26,
        8,
        39,
        22,
        20,
        42,
        20,
        1
      ]
    },
    {
      "id": "DB301E71",
      "weeklyAccepted": [
        0,
        45,
        0,
        20,
        15,
        18,
        46,
        2,
        41,
        12,
        35,
        0,
        4,
        19,
        6,
        0,
        49,
        19,
        49,
        28,
        23,
        48,
        49,
        0,
        49,
        44,
        0,
        49,
        21,
        31,
        0,
        6,
        8,
        6,
        37,
        37,
        26,
        48,
        49,
        49,
        0,
        41,
        40,
        35,
        49,
        13,
        24,
        11,
        0,
        26,
        49,
        0
      ],
      "weeklyRejected": [
        35,
        16,
        49,
        28,
        20,
        1,
        40,
        32,
        49,
        8,
        29,
        4,
        49,
        35,
        25,
        49,
        15,
        49,
        49,
        15,
        25,
        0,
        49,
        14,
        8,
        41,
        36,
        21,
        0,
        39,
        7,
        47,
        38,
        30,
        35,
        49,
        3,
        49,
        11,
        18,
        15,
        49,
        2,
        28,
        0,
        28,
        0,
        1,
        15,
        22,
        32,
        35
      ],
      "weeklyHold": [
        16,
        13,
        16,
        20,
        7,
        22,
        24,
        28,
        31,
        33,
        47,
        16,
        48,
        28,
        35,
        49,
        7,
        2,
        0,
        45,
        48,
        44,
        14,
        49,
        35,
        19,
        19,
        24,
        30,
        49,
        48,
        0,
        13,
        44,
        30,
        28,
        17,
        0,
        41,
        49,
        24,
        7,
        0,
        49,
        27,
        49,
        35,
        49,
        7,
        49,
        44,
        11
      ],
      "weeklyBackordered": [
        44,
        26,
        21,
        16,
        38,
        40,
        48,
        26,
        22,
        2,
        0,
        21,
        35,
        10,
        26,
        32,
        43,
        33,
        0,
        14,
        30,
        23,
        27,
        29,
        1,
        11,
        14,
        25,
        11,
        49,
        24,
        13,
        16,
        49,
        11,
        0,
        20,
        0,
        43,
        29,
        11,
        28,
        0,
        41,
        49,
        49,
        49,
        49,
        16,
        15,
        24,
        43
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        23,
        31,
        5,
        48,
        29,
        0,
        26,
        5,
        6,
        17,
        41,
        14,
        23,
        13,
        28,
        14,
        48,
        0,
        18,
        9,
        38,
        23,
        32,
        31,
        45,
        47,
        28,
        0,
        23,
        40,
        0,
        12,
        49,
        31,
        36,
        42,
        46,
        8,
        23,
        23,
        0,
        49,
        49,
        49,
        0,
        0,
        3,
        49,
        34,
        17,
        16,
        12
      ],
      "weeklyRejected": [
        41,
        20,
        29,
        17,
        24,
        44,
        49,
        31,
        33,
        8,
        0,
        29,
        26,
        48,
        38,
        21,
        35,
        35,
        45,
        24,
        19,
        26,
        25,
        49,
        31,
        23,
        49,
        0,
        48,
        0,
        49,
        47,
        15,
        27,
        34,
        49,
        49,
        24,
        20,
        49,
        18,
        49,
        10,
        2,
        46,
        13,
        10,
        13,
        0,
        49,
        20,
        49
      ],
      "weeklyHold": [
        49,
        26,
        49,
        1,
        49,
        25,
        0,
        19,
        26,
        29,
        47,
        6,
        33,
        21,
        17,
        12,
        0,
        29,
        0,
        0,
        49,
        17,
        36,
        0,
        25,
        32,
        36,
        12,
        49,
        0,
        36,
        32,
        6,
        27,
        3,
        49,
        25,
        25,
        1,
        46,
        41,
        27,
        0,
        49,
        3,
        3,
        45,
        49,
        12,
        11,
        37,
        49
      ],
      "weeklyBackordered": [
        25,
        8,
        0,
        37,
        49,
        46,
        49,
        49,
        0,
        21,
        49,
        22,
        49,
        23,
        0,
        6,
        5,
        49,
        0,
        49,
        46,
        40,
        0,
        0,
        11,
        3,
        1,
        49,
        23,
        31,
        9,
        49,
        34,
        33,
        2,
        0,
        1,
        0,
        4,
        26,
        9,
        48,
        6,
        43,
        21,
        49,
        28,
        9,
        49,
        30,
        28,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        0,
        32,
        25,
        0,
        49,
        0,
        1,
        28,
        30,
        14,
        37,
        0,
        16,
        10,
        20,
        28,
        49,
        0,
        31,
        33,
        41,
        4,
        34,
        32,
        0,
        47,
        0,
        20,
        22,
        11,
        10,
        3,
        13,
        34,
        31,
        19,
        34,
        48,
        27,
        49,
        49,
        16,
        19,
        41,
        40,
        29,
        0,
        43,
        44,
        27,
        12,
        12
      ],
      "weeklyRejected": [
        24,
        8,
        18,
        18,
        16,
        0,
        41,
        49,
        49,
        1,
        33,
        16,
        25,
        12,
        48,
        39,
        41,
        49,
        19,
        2,
        49,
        39,
        17,
        11,
        0,
        16,
        0,
        26,
        28,
        48,
        33,
        21,
        20,
        26,
        17,
        49,
        28,
        0,
        1,
        49,
        43,
        0,
        3,
        33,
        49,
        18,
        0,
        26,
        0,
        49,
        0,
        4
      ],
      "weeklyHold": [
        0,
        31,
        33,
        30,
        49,
        35,
        43,
        49,
        25,
        30,
        18,
        9,
        29,
        9,
        10,
        15,
        0,
        24,
        20,
        27,
        24,
        18,
        3,
        41,
        34,
        43,
        49,
        0,
        27,
        20,
        46,
        0,
        1,
        0,
        36,
        0,
        15,
        7,
        8,
        47,
        28,
        9,
        27,
        6,
        49,
        49,
        0,
        49,
        25,
        43,
        27,
        45
      ],
      "weeklyBackordered": [
        31,
        47,
        30,
        33,
        48,
        21,
        14,
        49,
        49,
        49,
        26,
        37,
        18,
        32,
        27,
        3,
        48,
        16,
        31,
        35,
        20,
        49,
        9,
        29,
        1,
        13,
        22,
        30,
        1,
        37,
        0,
        49,
        17,
        49,
        19,
        20,
        6,
        5,
        13,
        4,
        15,
        46,
        1,
        49,
        9,
        49,
        41,
        13,
        11,
        32,
        14,
        34
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        0,
        41,
        30,
        0,
        49,
        3,
        1,
        11,
        48,
        19,
        46,
        2,
        40,
        22,
        42,
        5,
        48,
        0,
        27,
        15,
        42,
        29,
        33,
        32,
        45,
        47,
        29,
        49,
        21,
        12,
        26,
        17,
        38,
        32,
        18,
        47,
        34,
        5,
        19,
        49,
        35,
        0,
        19,
        18,
        35,
        0,
        3,
        3,
        46,
        49,
        32,
        3
      ],
      "weeklyRejected": [
        49,
        25,
        8,
        14,
        30,
        49,
        9,
        43,
        27,
        1,
        12,
        46,
        38,
        48,
        30,
        3,
        24,
        2,
        18,
        12,
        11,
        42,
        0,
        22,
        22,
        49,
        32,
        33,
        31,
        38,
        18,
        35,
        26,
        18,
        0,
        49,
        33,
        30,
        18,
        49,
        17,
        19,
        39,
        6,
        41,
        6,
        0,
        39,
        10,
        37,
        12,
        33
      ],
      "weeklyHold": [
        49,
        31,
        49,
        12,
        23,
        0,
        26,
        36,
        0,
        43,
        25,
        18,
        48,
        31,
        40,
        16,
        0,
        39,
        32,
        43,
        46,
        49,
        45,
        17,
        45,
        22,
        10,
        6,
        49,
        0,
        39,
        21,
        30,
        28,
        41,
        39,
        21,
        1,
        28,
        49,
        37,
        49,
        21,
        37,
        6,
        26,
        49,
        34,
        0,
        44,
        14,
        19
      ],
      "weeklyBackordered": [
        36,
        17,
        31,
        49,
        44,
        46,
        5,
        23,
        49,
        0,
        10,
        28,
        34,
        15,
        45,
        23,
        3,
        49,
        42,
        0,
        5,
        29,
        0,
        0,
        33,
        27,
        1,
        23,
        19,
        39,
        43,
        13,
        35,
        49,
        45,
        3,
        14,
        31,
        28,
        34,
        23,
        29,
        0,
        49,
        49,
        4,
        34,
        2,
        37,
        49,
        35,
        32
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        18,
        48,
        27,
        37,
        21,
        35,
        25,
        18,
        48,
        32,
        12,
        0,
        3,
        47,
        20,
        23,
        49,
        48,
        24,
        40,
        5,
        27,
        36,
        38,
        0,
        43,
        0,
        12,
        49,
        23,
        8,
        16,
        49,
        3,
        48,
        29,
        0,
        28,
        1,
        22,
        49,
        41,
        30,
        26,
        12,
        38,
        0,
        25,
        14,
        48,
        36,
        26
      ],
      "weeklyRejected": [
        36,
        18,
        38,
        38,
        49,
        18,
        47,
        24,
        16,
        48,
        0,
        25,
        15,
        41,
        45,
        0,
        12,
        49,
        31,
        44,
        0,
        34,
        3,
        11,
        0,
        49,
        30,
        0,
        31,
        35,
        42,
        47,
        48,
        28,
        16,
        49,
        28,
        17,
        1,
        49,
        48,
        28,
        34,
        9,
        33,
        0,
        0,
        5,
        27,
        49,
        0,
        31
      ],
      "weeklyHold": [
        21,
        41,
        33,
        24,
        4,
        41,
        49,
        6,
        12,
        40,
        31,
        36,
        48,
        27,
        49,
        49,
        49,
        35,
        44,
        17,
        42,
        38,
        10,
        49,
        23,
        17,
        41,
        29,
        49,
        35,
        35,
        28,
        0,
        20,
        9,
        17,
        41,
        0,
        17,
        29,
        43,
        41,
        0,
        49,
        49,
        49,
        16,
        49,
        12,
        36,
        0,
        0
      ],
      "weeklyBackordered": [
        39,
        5,
        0,
        46,
        47,
        38,
        6,
        29,
        27,
        0,
        15,
        43,
        3,
        49,
        15,
        33,
        33,
        21,
        0,
        0,
        49,
        0,
        43,
        25,
        12,
        47,
        49,
        0,
        33,
        49,
        25,
        18,
        24,
        0,
        17,
        0,
        31,
        4,
        26,
        0,
        7,
        47,
        28,
        31,
        0,
        41,
        49,
        19,
        30,
        32,
        49,
        1
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        2,
        49,
        43,
        12,
        28,
        40,
        12,
        24,
        13,
        12,
        35,
        19,
        39,
        45,
        10,
        29,
        39,
        33,
        12,
        0,
        10,
        0,
        5,
        47,
        0,
        32,
        13,
        24,
        1,
        37,
        41,
        21,
        2,
        0,
        6,
        14,
        37,
        37,
        12,
        32,
        49,
        39,
        28,
        18,
        33,
        10,
        0,
        26,
        17,
        38,
        22,
        24
      ],
      "weeklyRejected": [
        39,
        0,
        24,
        3,
        45,
        3,
        49,
        3,
        49,
        1,
        24,
        25,
        29,
        42,
        26,
        10,
        0,
        49,
        49,
        34,
        34,
        42,
        0,
        24,
        0,
        49,
        33,
        0,
        39,
        34,
        49,
        47,
        44,
        46,
        26,
        46,
        15,
        28,
        31,
        17,
        48,
        0,
        15,
        42,
        49,
        0,
        0,
        24,
        22,
        49,
        3,
        34
      ],
      "weeklyHold": [
        37,
        0,
        13,
        1,
        15,
        30,
        33,
        8,
        23,
        46,
        22,
        44,
        19,
        16,
        32,
        0,
        15,
        8,
        3,
        8,
        14,
        39,
        8,
        49,
        26,
        35,
        49,
        9,
        46,
        0,
        48,
        28,
        0,
        48,
        41,
        22,
        3,
        19,
        49,
        13,
        17,
        28,
        0,
        2,
        0,
        49,
        49,
        49,
        16,
        16,
        25,
        45
      ],
      "weeklyBackordered": [
        29,
        38,
        25,
        44,
        44,
        46,
        0,
        44,
        9,
        5,
        49,
        21,
        3,
        25,
        24,
        49,
        49,
        30,
        0,
        27,
        42,
        49,
        27,
        33,
        30,
        6,
        1,
        31,
        1,
        49,
        1,
        13,
        11,
        49,
        0,
        10,
        28,
        4,
        0,
        15,
        23,
        48,
        32,
        49,
        30,
        35,
        35,
        8,
        0,
        13,
        38,
        49
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyAccepted": [
        6,
        37,
        23,
        38,
        7,
        11,
        49,
        39,
        29,
        9,
        49,
        0,
        32,
        29,
        12,
        49,
        49,
        20,
        0,
        49,
        24,
        17,
        49,
        0,
        22,
        47,
        13,
        14,
        23,
        27,
        0,
        38,
        45,
        0,
        48,
        36,
        37,
        40,
        12,
        49,
        11,
        38,
        49,
        49,
        49,
        2,
        35,
        12,
        0,
        46,
        30,
        9
      ],
      "weeklyRejected": [
        49,
        30,
        43,
        47,
        34,
        31,
        49,
        34,
        49,
        25,
        49,
        15,
        39,
        46,
        36,
        24,
        25,
        40,
        13,
        48,
        40,
        49,
        46,
        33,
        40,
        45,
        9,
        0,
        48,
        12,
        26,
        23,
        27,
        49,
        19,
        49,
        49,
        21,
        1,
        2,
        38,
        30,
        22,
        7,
        49,
        36,
        49,
        11,
        0,
        33,
        0,
        49
      ],
      "weeklyHold": [
        3,
        5,
        49,
        1,
        49,
        16,
        10,
        2,
        33,
        21,
        47,
        19,
        35,
        40,
        37,
        17,
        0,
        9,
        19,
        11,
        32,
        0,
        8,
        37,
        22,
        48,
        21,
        10,
        49,
        0,
        46,
        0,
        49,
        43,
        17,
        15,
        20,
        15,
        31,
        30,
        0,
        24,
        15,
        49,
        39,
        32,
        7,
        49,
        0,
        49,
        46,
        38
      ],
      "weeklyBackordered": [
        36,
        25,
        48,
        39,
        49,
        34,
        28,
        49,
        25,
        0,
        26,
        35,
        42,
        11,
        0,
        1,
        25,
        0,
        0,
        49,
        39,
        6,
        21,
        27,
        11,
        14,
        17,
        14,
        12,
        22,
        0,
        48,
        46,
        41,
        14,
        0,
        24,
        0,
        28,
        21,
        48,
        48,
        6,
        49,
        35,
        3,
        49,
        33,
        47,
        49,
        37,
        1
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        3,
        32,
        49,
        0,
        22,
        0,
        49,
        34,
        26,
        18,
        16,
        0,
        37,
        22,
        1,
        12,
        42,
        34,
        17,
        49,
        12,
        49,
        10,
        23,
        7,
        47,
        0,
        6,
        20,
        49,
        0,
        14,
        49,
        15,
        39,
        2,
        19,
        48,
        31,
        49,
        49,
        37,
        49,
        16,
        49,
        17,
        0,
        0,
        14,
        31,
        29,
        34
      ],
      "weeklyRejected": [
        32,
        49,
        49,
        27,
        34,
        49,
        49,
        32,
        12,
        28,
        0,
        44,
        11,
        46,
        37,
        14,
        11,
        47,
        42,
        40,
        35,
        27,
        23,
        27,
        36,
        49,
        46,
        34,
        43,
        25,
        38,
        47,
        40,
        7,
        31,
        49,
        3,
        5,
        12,
        20,
        38,
        24,
        49,
        12,
        47,
        48,
        0,
        27,
        0,
        38,
        18,
        43
      ],
      "weeklyHold": [
        2,
        12,
        34,
        2,
        49,
        31,
        2,
        2,
        25,
        49,
        47,
        42,
        15,
        33,
        38,
        10,
        0,
        18,
        33,
        15,
        28,
        39,
        39,
        22,
        45,
        39,
        26,
        0,
        23,
        16,
        28,
        0,
        19,
        17,
        46,
        25,
        32,
        0,
        23,
        49,
        5,
        40,
        49,
        49,
        49,
        11,
        13,
        49,
        0,
        17,
        26,
        26
      ],
      "weeklyBackordered": [
        0,
        40,
        11,
        49,
        36,
        28,
        24,
        49,
        1,
        0,
        31,
        38,
        18,
        3,
        38,
        49,
        41,
        34,
        4,
        18,
        12,
        0,
        0,
        26,
        45,
        10,
        20,
        9,
        10,
        23,
        6,
        0,
        48,
        23,
        12,
        0,
        1,
        18,
        14,
        14,
        0,
        48,
        8,
        41,
        34,
        42,
        38,
        23,
        25,
        27,
        49,
        2
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        9,
        33,
        27,
        19,
        40,
        27,
        26,
        31,
        45,
        49,
        14,
        12,
        49,
        49,
        49,
        34,
        49,
        13,
        25,
        22,
        7,
        43,
        29,
        31,
        39,
        46,
        7,
        13,
        23,
        16,
        23,
        34,
        32,
        49,
        46,
        40,
        23,
        8,
        49,
        11,
        0,
        0,
        47,
        45,
        49,
        30,
        35,
        0,
        35,
        45,
        36,
        34
      ],
      "weeklyRejected": [
        24,
        35,
        23,
        34,
        2,
        28,
        28,
        49,
        43,
        1,
        26,
        31,
        39,
        11,
        35,
        0,
        36,
        49,
        46,
        22,
        3,
        46,
        38,
        19,
        20,
        49,
        22,
        46,
        23,
        28,
        0,
        18,
        0,
        48,
        11,
        9,
        0,
        47,
        13,
        49,
        32,
        18,
        34,
        19,
        49,
        26,
        13,
        0,
        20,
        46,
        9,
        49
      ],
      "weeklyHold": [
        0,
        0,
        47,
        15,
        31,
        45,
        49,
        41,
        28,
        37,
        34,
        45,
        14,
        1,
        37,
        38,
        9,
        36,
        0,
        28,
        27,
        34,
        12,
        0,
        21,
        47,
        25,
        12,
        23,
        3,
        36,
        40,
        40,
        43,
        4,
        36,
        49,
        0,
        1,
        42,
        37,
        46,
        4,
        25,
        3,
        15,
        2,
        29,
        0,
        33,
        45,
        49
      ],
      "weeklyBackordered": [
        14,
        37,
        1,
        6,
        47,
        46,
        23,
        23,
        43,
        17,
        25,
        49,
        25,
        28,
        28,
        1,
        43,
        49,
        17,
        0,
        49,
        48,
        16,
        21,
        28,
        49,
        9,
        40,
        1,
        32,
        14,
        45,
        48,
        21,
        0,
        0,
        11,
        7,
        20,
        39,
        25,
        41,
        2,
        49,
        0,
        49,
        49,
        48,
        0,
        39,
        42,
        1
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        44,
        15,
        15,
        35,
        8,
        16,
        18,
        49,
        40,
        49,
        4,
        0,
        31,
        36,
        13,
        13,
        49,
        0,
        25,
        0,
        3,
        33,
        48,
        25,
        49,
        25,
        49,
        40,
        49,
        33,
        30,
        0,
        30,
        22,
        48,
        23,
        10,
        48,
        25,
        49,
        19,
        17,
        49,
        49,
        35,
        0,
        21,
        12,
        49,
        35,
        7,
        45
      ],
      "weeklyRejected": [
        42,
        31,
        34,
        32,
        49,
        36,
        36,
        34,
        27,
        37,
        45,
        22,
        0,
        33,
        48,
        0,
        26,
        49,
        0,
        37,
        22,
        49,
        0,
        49,
        24,
        8,
        31,
        30,
        39,
        0,
        37,
        20,
        42,
        24,
        31,
        49,
        49,
        16,
        7,
        19,
        48,
        35,
        34,
        2,
        49,
        35,
        29,
        17,
        4,
        49,
        3,
        43
      ],
      "weeklyHold": [
        0,
        9,
        25,
        36,
        34,
        32,
        24,
        49,
        15,
        4,
        26,
        1,
        11,
        38,
        43,
        25,
        0,
        30,
        0,
        0,
        14,
        36,
        15,
        27,
        4,
        31,
        38,
        2,
        37,
        21,
        37,
        24,
        24,
        43,
        19,
        37,
        48,
        11,
        30,
        22,
        7,
        19,
        16,
        0,
        34,
        47,
        26,
        49,
        0,
        43,
        44,
        23
      ],
      "weeklyBackordered": [
        0,
        45,
        22,
        49,
        41,
        0,
        33,
        1,
        0,
        12,
        24,
        36,
        19,
        28,
        44,
        12,
        34,
        49,
        0,
        17,
        33,
        36,
        49,
        13,
        26,
        17,
        1,
        0,
        4,
        37,
        34,
        26,
        43,
        31,
        0,
        0,
        38,
        22,
        12,
        13,
        48,
        48,
        0,
        49,
        0,
        22,
        49,
        29,
        49,
        42,
        1,
        6
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        21,
        45,
        32,
        31,
        2,
        9,
        3,
        49,
        48,
        0,
        24,
        7,
        25,
        29,
        26,
        16,
        49,
        27,
        0,
        9,
        23,
        3,
        27,
        25,
        27,
        45,
        26,
        11,
        16,
        13,
        10,
        35,
        38,
        26,
        23,
        2,
        36,
        35,
        18,
        29,
        13,
        31,
        49,
        21,
        21,
        7,
        33,
        27,
        8,
        0,
        0,
        48
      ],
      "weeklyRejected": [
        25,
        17,
        49,
        44,
        25,
        23,
        49,
        38,
        23,
        27,
        31,
        12,
        32,
        3,
        48,
        27,
        5,
        45,
        49,
        14,
        8,
        23,
        0,
        9,
        1,
        9,
        49,
        0,
        42,
        3,
        39,
        16,
        48,
        32,
        41,
        35,
        19,
        14,
        1,
        15,
        29,
        1,
        21,
        0,
        43,
        24,
        31,
        36,
        0,
        9,
        0,
        34
      ],
      "weeklyHold": [
        11,
        20,
        0,
        24,
        49,
        48,
        38,
        42,
        49,
        3,
        33,
        49,
        35,
        9,
        0,
        18,
        0,
        11,
        0,
        43,
        0,
        37,
        22,
        39,
        28,
        21,
        35,
        19,
        32,
        41,
        48,
        27,
        10,
        34,
        15,
        19,
        32,
        0,
        48,
        16,
        12,
        19,
        0,
        28,
        17,
        32,
        32,
        46,
        7,
        39,
        5,
        32
      ],
      "weeklyBackordered": [
        18,
        24,
        0,
        37,
        36,
        34,
        23,
        34,
        31,
        0,
        17,
        22,
        20,
        5,
        38,
        4,
        23,
        30,
        0,
        49,
        37,
        21,
        32,
        23,
        1,
        32,
        23,
        0,
        23,
        37,
        38,
        40,
        36,
        46,
        0,
        0,
        33,
        0,
        14,
        21,
        29,
        48,
        47,
        49,
        20,
        45,
        17,
        30,
        0,
        44,
        43,
        24
      ]
    },
    {
      "id": "049BCC88",
      "weeklyAccepted": [
        0,
        27,
        31,
        17,
        49,
        0,
        1,
        34,
        19,
        49,
        49,
        0,
        36,
        49,
        37,
        48,
        0,
        1,
        44,
        30,
        42,
        7,
        38,
        40,
        0,
        16,
        23,
        39,
        24,
        31,
        0,
        27,
        49,
        48,
        38,
        42,
        29,
        48,
        26,
        18,
        1,
        49,
        30,
        27,
        38,
        27,
        22,
        29,
        49,
        0,
        10,
        41
      ],
      "weeklyRejected": [
        49,
        15,
        32,
        20,
        36,
        14,
        43,
        45,
        46,
        1,
        32,
        0,
        48,
        48,
        15,
        30,
        49,
        29,
        47,
        47,
        49,
        41,
        40,
        49,
        32,
        49,
        39,
        49,
        23,
        17,
        6,
        47,
        31,
        49,
        44,
        49,
        0,
        15,
        10,
        30,
        34,
        41,
        8,
        15,
        48,
        48,
        0,
        49,
        3,
        28,
        19,
        0
      ],
      "weeklyHold": [
        49,
        36,
        25,
        14,
        49,
        39,
        8,
        17,
        24,
        25,
        26,
        32,
        48,
        5,
        34,
        16,
        26,
        11,
        32,
        49,
        32,
        37,
        11,
        16,
        0,
        48,
        0,
        0,
        18,
        6,
        48,
        0,
        23,
        30,
        48,
        0,
        17,
        4,
        45,
        45,
        42,
        32,
        27,
        0,
        49,
        37,
        21,
        28,
        16,
        39,
        49,
        6
      ],
      "weeklyBackordered": [
        24,
        13,
        11,
        6,
        37,
        31,
        32,
        41,
        49,
        21,
        36,
        7,
        20,
        0,
        32,
        29,
        34,
        46,
        8,
        0,
        42,
        44,
        18,
        34,
        9,
        16,
        1,
        49,
        32,
        49,
        21,
        30,
        20,
        49,
        0,
        26,
        15,
        0,
        6,
        33,
        18,
        17,
        26,
        49,
        42,
        24,
        3,
        16,
        2,
        36,
        13,
        46
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        1,
        33,
        49,
        24,
        49,
        12,
        12,
        12,
        48,
        34,
        5,
        0,
        43,
        49,
        37,
        25,
        49,
        12,
        25,
        32,
        27,
        44,
        49,
        2,
        0,
        19,
        0,
        23,
        45,
        11,
        12,
        24,
        31,
        6,
        27,
        30,
        9,
        19,
        1,
        49,
        28,
        36,
        49,
        2,
        32,
        0,
        0,
        49,
        0,
        36,
        22,
        48
      ],
      "weeklyRejected": [
        47,
        0,
        49,
        6,
        49,
        33,
        12,
        49,
        49,
        27,
        12,
        45,
        30,
        0,
        40,
        0,
        49,
        24,
        49,
        18,
        1,
        44,
        29,
        23,
        28,
        40,
        10,
        4,
        48,
        28,
        49,
        33,
        13,
        30,
        16,
        21,
        37,
        43,
        28,
        49,
        33,
        32,
        28,
        0,
        49,
        41,
        27,
        44,
        1,
        49,
        25,
        33
      ],
      "weeklyHold": [
        25,
        41,
        48,
        1,
        16,
        15,
        49,
        2,
        12,
        49,
        47,
        49,
        44,
        1,
        18,
        38,
        0,
        40,
        33,
        20,
        19,
        0,
        0,
        29,
        26,
        33,
        15,
        23,
        28,
        0,
        28,
        14,
        0,
        26,
        48,
        1,
        25,
        0,
        31,
        25,
        17,
        49,
        32,
        49,
        33,
        49,
        9,
        45,
        21,
        33,
        42,
        46
      ],
      "weeklyBackordered": [
        45,
        33,
        0,
        23,
        48,
        34,
        47,
        36,
        38,
        0,
        43,
        41,
        39,
        49,
        0,
        26,
        49,
        39,
        12,
        10,
        32,
        13,
        16,
        42,
        23,
        49,
        13,
        0,
        43,
        49,
        23,
        31,
        48,
        41,
        22,
        2,
        1,
        0,
        0,
        11,
        0,
        43,
        0,
        49,
        16,
        49,
        45,
        22,
        21,
        49,
        16,
        4
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        0,
        20,
        44,
        30,
        49,
        20,
        49,
        49,
        34,
        34,
        0,
        4,
        30,
        49,
        47,
        21,
        49,
        13,
        42,
        28,
        23,
        27,
        25,
        0,
        17,
        12,
        9,
        49,
        24,
        18,
        23,
        2,
        49,
        48,
        33,
        5,
        31,
        19,
        49,
        14,
        0,
        8,
        28,
        15,
        49,
        23,
        21,
        15,
        49,
        27,
        13,
        39
      ],
      "weeklyRejected": [
        16,
        0,
        49,
        47,
        16,
        0,
        49,
        49,
        40,
        1,
        8,
        24,
        11,
        48,
        27,
        12,
        19,
        29,
        49,
        18,
        49,
        20,
        1,
        49,
        49,
        49,
        42,
        47,
        48,
        46,
        21,
        30,
        24,
        23,
        15,
        23,
        41,
        29,
        14,
        21,
        48,
        35,
        15,
        13,
        44,
        42,
        49,
        29,
        0,
        25,
        0,
        31
      ],
      "weeklyHold": [
        44,
        22,
        15,
        25,
        17,
        26,
        0,
        22,
        4,
        49,
        47,
        2,
        29,
        30,
        24,
        37,
        0,
        34,
        0,
        15,
        18,
        7,
        0,
        49,
        1,
        38,
        22,
        14,
        40,
        4,
        48,
        0,
        0,
        37,
        35,
        22,
        23,
        41,
        22,
        49,
        28,
        45,
        11,
        46,
        39,
        21,
        13,
        29,
        0,
        33,
        45,
        27
      ],
      "weeklyBackordered": [
        21,
        29,
        17,
        3,
        36,
        0,
        24,
        16,
        25,
        44,
        42,
        49,
        16,
        33,
        3,
        44,
        40,
        25,
        9,
        3,
        26,
        40,
        0,
        41,
        20,
        17,
        1,
        3,
        22,
        41,
        49,
        36,
        48,
        45,
        0,
        0,
        37,
        0,
        42,
        27,
        9,
        12,
        17,
        49,
        43,
        39,
        10,
        1,
        35,
        6,
        38,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        0,
        43,
        42,
        7,
        49,
        0,
        2,
        2,
        27,
        31,
        46,
        0,
        31,
        49,
        5,
        11,
        49,
        33,
        30,
        31,
        7,
        44,
        0,
        20,
        0,
        5,
        39,
        32,
        33,
        49,
        23,
        8,
        30,
        0,
        34,
        7,
        13,
        44,
        48,
        47,
        35,
        38,
        23,
        41,
        49,
        0,
        27,
        4,
        2,
        25,
        11,
        25
      ],
      "weeklyRejected": [
        49,
        0,
        33,
        19,
        20,
        26,
        15,
        30,
        49,
        1,
        8,
        48,
        49,
        43,
        25,
        10,
        12,
        49,
        37,
        40,
        0,
        33,
        49,
        2,
        44,
        49,
        5,
        2,
        41,
        48,
        47,
        44,
        22,
        25,
        1,
        49,
        49,
        49,
        1,
        27,
        46,
        49,
        45,
        14,
        27,
        19,
        24,
        13,
        0,
        49,
        0,
        1
      ],
      "weeklyHold": [
        5,
        37,
        30,
        1,
        26,
        36,
        23,
        12,
        0,
        10,
        41,
        17,
        48,
        43,
        49,
        11,
        5,
        17,
        15,
        17,
        48,
        14,
        24,
        20,
        5,
        48,
        36,
        21,
        37,
        13,
        34,
        11,
        1,
        42,
        14,
        0,
        0,
        32,
        13,
        49,
        26,
        13,
        33,
        43,
        27,
        34,
        6,
        17,
        11,
        16,
        49,
        40
      ],
      "weeklyBackordered": [
        0,
        36,
        34,
        26,
        49,
        32,
        22,
        28,
        40,
        26,
        43,
        37,
        27,
        38,
        47,
        48,
        49,
        15,
        35,
        6,
        17,
        0,
        24,
        22,
        1,
        9,
        8,
        45,
        14,
        47,
        47,
        29,
        33,
        49,
        24,
        17,
        35,
        0,
        49,
        10,
        36,
        48,
        17,
        49,
        0,
        46,
        29,
        49,
        9,
        25,
        49,
        27
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        0,
        49,
        11,
        2,
        1,
        14,
        22,
        43,
        36,
        29,
        35,
        3,
        49,
        28,
        49,
        11,
        49,
        24,
        13,
        49,
        39,
        32,
        49,
        29,
        30,
        45,
        32,
        49,
        15,
        40,
        6,
        49,
        49,
        22,
        10,
        22,
        38,
        2,
        26,
        49,
        0,
        0,
        49,
        29,
        41,
        38,
        18,
        15,
        29,
        49,
        49,
        48
      ],
      "weeklyRejected": [
        46,
        27,
        32,
        17,
        49,
        16,
        30,
        24,
        38,
        2,
        49,
        49,
        41,
        48,
        48,
        18,
        14,
        48,
        49,
        21,
        0,
        8,
        14,
        5,
        49,
        46,
        23,
        14,
        48,
        29,
        15,
        23,
        24,
        23,
        0,
        26,
        6,
        23,
        26,
        0,
        26,
        13,
        37,
        25,
        44,
        22,
        0,
        6,
        25,
        33,
        37,
        21
      ],
      "weeklyHold": [
        0,
        20,
        22,
        15,
        49,
        48,
        44,
        2,
        16,
        0,
        25,
        21,
        32,
        20,
        42,
        27,
        13,
        21,
        17,
        5,
        6,
        39,
        25,
        49,
        37,
        35,
        22,
        9,
        49,
        21,
        36,
        31,
        29,
        47,
        41,
        5,
        0,
        5,
        1,
        0,
        0,
        49,
        31,
        41,
        30,
        34,
        5,
        39,
        0,
        21,
        26,
        27
      ],
      "weeklyBackordered": [
        45,
        17,
        20,
        34,
        46,
        41,
        43,
        25,
        49,
        1,
        49,
        1,
        12,
        36,
        3,
        37,
        49,
        6,
        26,
        29,
        31,
        35,
        0,
        49,
        21,
        15,
        21,
        0,
        14,
        40,
        26,
        49,
        17,
        4,
        14,
        29,
        1,
        0,
        28,
        0,
        48,
        48,
        20,
        39,
        7,
        27,
        40,
        49,
        2,
        44,
        23,
        40
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        0,
        40,
        49,
        26,
        43,
        0,
        43,
        31,
        34,
        0,
        49,
        1,
        18,
        12,
        41,
        49,
        24,
        30,
        7,
        17,
        24,
        49,
        21,
        39,
        49,
        9,
        12,
        39,
        6,
        6,
        9,
        49,
        21,
        49,
        21,
        26,
        32,
        27,
        31,
        17,
        8,
        25,
        49,
        49,
        43,
        4,
        37,
        0,
        10,
        16,
        0,
        19
      ],
      "weeklyRejected": [
        20,
        28,
        22,
        31,
        0,
        23,
        20,
        38,
        41,
        6,
        4,
        28,
        49,
        48,
        48,
        6,
        26,
        0,
        35,
        49,
        48,
        16,
        1,
        28,
        30,
        49,
        49,
        26,
        30,
        18,
        31,
        42,
        26,
        31,
        49,
        49,
        8,
        1,
        7,
        49,
        18,
        0,
        49,
        0,
        49,
        27,
        7,
        49,
        40,
        49,
        13,
        6
      ],
      "weeklyHold": [
        13,
        49,
        27,
        1,
        28,
        39,
        14,
        49,
        0,
        33,
        25,
        21,
        8,
        18,
        12,
        13,
        17,
        30,
        0,
        24,
        26,
        36,
        39,
        3,
        27,
        36,
        49,
        0,
        33,
        49,
        40,
        0,
        0,
        49,
        26,
        49,
        29,
        4,
        1,
        18,
        0,
        37,
        33,
        22,
        49,
        11,
        12,
        18,
        12,
        15,
        27,
        26
      ],
      "weeklyBackordered": [
        46,
        40,
        28,
        33,
        42,
        13,
        0,
        1,
        34,
        42,
        0,
        49,
        27,
        0,
        34,
        4,
        49,
        6,
        48,
        20,
        41,
        48,
        48,
        10,
        21,
        11,
        14,
        28,
        20,
        48,
        32,
        49,
        48,
        49,
        0,
        0,
        49,
        34,
        49,
        28,
        48,
        48,
        8,
        49,
        22,
        20,
        49,
        8,
        7,
        49,
        35,
        28
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        0,
        31,
        26,
        16,
        1,
        0,
        28,
        49,
        42,
        24,
        26,
        28,
        23,
        49,
        7,
        28,
        45,
        28,
        0,
        21,
        22,
        3,
        28,
        24,
        8,
        43,
        30,
        13,
        22,
        11,
        14,
        0,
        36,
        7,
        24,
        25,
        19,
        33,
        47,
        46,
        4,
        19,
        49,
        23,
        33,
        0,
        11,
        30,
        38,
        31,
        49,
        41
      ],
      "weeklyRejected": [
        0,
        22,
        30,
        47,
        21,
        27,
        8,
        18,
        49,
        20,
        10,
        0,
        25,
        24,
        48,
        16,
        0,
        4,
        48,
        0,
        2,
        15,
        20,
        0,
        6,
        33,
        16,
        22,
        44,
        0,
        33,
        47,
        44,
        8,
        1,
        35,
        0,
        49,
        15,
        29,
        48,
        42,
        5,
        0,
        49,
        11,
        33,
        18,
        26,
        33,
        0,
        49
      ],
      "weeklyHold": [
        42,
        9,
        49,
        41,
        46,
        41,
        31,
        15,
        10,
        0,
        47,
        43,
        18,
        22,
        20,
        0,
        0,
        12,
        2,
        29,
        0,
        18,
        19,
        0,
        32,
        46,
        19,
        48,
        25,
        9,
        48,
        25,
        4,
        28,
        0,
        9,
        24,
        0,
        15,
        0,
        6,
        49,
        25,
        16,
        49,
        49,
        42,
        15,
        0,
        0,
        1,
        49
      ],
      "weeklyBackordered": [
        0,
        31,
        0,
        49,
        26,
        10,
        31,
        24,
        0,
        6,
        8,
        49,
        3,
        33,
        46,
        10,
        49,
        4,
        0,
        28,
        43,
        9,
        16,
        47,
        1,
        18,
        1,
        49,
        13,
        35,
        19,
        49,
        11,
        27,
        20,
        0,
        16,
        10,
        43,
        34,
        7,
        37,
        0,
        31,
        0,
        15,
        21,
        49,
        23,
        49,
        35,
        44
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        8,
        6,
        25,
        24,
        3,
        31,
        7,
        49,
        42,
        34,
        25,
        0,
        49,
        30,
        48,
        27,
        49,
        41,
        16,
        5,
        48,
        18,
        14,
        47,
        22,
        11,
        5,
        32,
        27,
        29,
        0,
        27,
        44,
        42,
        38,
        39,
        47,
        1,
        49,
        49,
        0,
        24,
        23,
        22,
        44,
        16,
        10,
        29,
        18,
        19,
        0,
        38
      ],
      "weeklyRejected": [
        41,
        25,
        8,
        27,
        49,
        17,
        49,
        23,
        49,
        27,
        17,
        19,
        34,
        15,
        20,
        23,
        0,
        27,
        42,
        25,
        46,
        26,
        22,
        49,
        30,
        49,
        31,
        25,
        44,
        34,
        30,
        28,
        39,
        13,
        12,
        49,
        36,
        27,
        1,
        35,
        5,
        9,
        49,
        0,
        14,
        49,
        0,
        5,
        0,
        10,
        15,
        29
      ],
      "weeklyHold": [
        49,
        23,
        42,
        1,
        0,
        17,
        29,
        2,
        23,
        42,
        43,
        28,
        1,
        12,
        40,
        4,
        42,
        48,
        20,
        12,
        49,
        0,
        17,
        9,
        0,
        48,
        46,
        10,
        49,
        0,
        48,
        38,
        24,
        42,
        36,
        7,
        25,
        8,
        30,
        49,
        20,
        37,
        0,
        30,
        35,
        47,
        0,
        49,
        0,
        37,
        28,
        49
      ],
      "weeklyBackordered": [
        24,
        26,
        15,
        49,
        30,
        42,
        44,
        48,
        49,
        8,
        17,
        27,
        3,
        28,
        39,
        49,
        43,
        49,
        2,
        10,
        49,
        18,
        10,
        23,
        1,
        5,
        24,
        25,
        1,
        15,
        49,
        0,
        11,
        10,
        0,
        8,
        36,
        48,
        14,
        36,
        9,
        17,
        7,
        7,
        24,
        25,
        36,
        26,
        0,
        15,
        49,
        11
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        13,
        3,
        5,
        14,
        49,
        2,
        38,
        29,
        38,
        17,
        49,
        49,
        43,
        40,
        20,
        22,
        49,
        30,
        18,
        32,
        25,
        29,
        20,
        47,
        0,
        47,
        8,
        0,
        1,
        30,
        19,
        28,
        18,
        0,
        15,
        42,
        27,
        25,
        19,
        32,
        12,
        38,
        22,
        38,
        49,
        24,
        0,
        38,
        10,
        42,
        15,
        27
      ],
      "weeklyRejected": [
        46,
        19,
        31,
        20,
        38,
        26,
        32,
        20,
        36,
        38,
        49,
        29,
        46,
        48,
        36,
        28,
        5,
        28,
        34,
        30,
        49,
        49,
        16,
        12,
        0,
        49,
        45,
        0,
        40,
        17,
        49,
        23,
        48,
        20,
        0,
        15,
        0,
        25,
        38,
        17,
        7,
        0,
        17,
        0,
        15,
        15,
        8,
        46,
        48,
        41,
        0,
        49
      ],
      "weeklyHold": [
        19,
        37,
        37,
        1,
        11,
        33,
        11,
        2,
        27,
        25,
        33,
        49,
        43,
        26,
        28,
        33,
        0,
        12,
        24,
        49,
        1,
        42,
        33,
        0,
        2,
        18,
        47,
        28,
        49,
        19,
        43,
        38,
        0,
        0,
        34,
        0,
        14,
        15,
        16,
        24,
        42,
        32,
        49,
        46,
        48,
        37,
        29,
        24,
        26,
        35,
        31,
        9
      ],
      "weeklyBackordered": [
        19,
        49,
        0,
        48,
        42,
        18,
        22,
        37,
        26,
        26,
        41,
        8,
        49,
        26,
        0,
        20,
        49,
        46,
        10,
        30,
        32,
        49,
        48,
        49,
        23,
        1,
        25,
        31,
        7,
        49,
        16,
        49,
        40,
        32,
        16,
        17,
        49,
        0,
        33,
        22,
        48,
        41,
        24,
        49,
        6,
        33,
        39,
        25,
        22,
        49,
        32,
        23
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyAccepted": [
        9,
        19,
        7,
        27,
        8,
        3,
        32,
        42,
        14,
        25,
        9,
        0,
        38,
        12,
        1,
        13,
        32,
        20,
        6,
        37,
        26,
        48,
        37,
        0,
        49,
        47,
        29,
        0,
        1,
        21,
        34,
        47,
        32,
        38,
        48,
        40,
        24,
        48,
        15,
        49,
        31,
        34,
        49,
        49,
        49,
        0,
        0,
        49,
        20,
        21,
        14,
        5
      ],
      "weeklyRejected": [
        35,
        40,
        49,
        20,
        22,
        26,
        43,
        25,
        49,
        9,
        49,
        40,
        28,
        48,
        16,
        35,
        10,
        48,
        41,
        10,
        47,
        10,
        18,
        32,
        31,
        29,
        49,
        27,
        27,
        0,
        0,
        20,
        48,
        49,
        25,
        35,
        46,
        3,
        21,
        6,
        30,
        9,
        42,
        2,
        49,
        39,
        0,
        49,
        0,
        1,
        10,
        30
      ],
      "weeklyHold": [
        35,
        10,
        25,
        1,
        0,
        48,
        0,
        2,
        17,
        13,
        47,
        22,
        48,
        45,
        49,
        25,
        19,
        21,
        3,
        20,
        14,
        24,
        37,
        11,
        43,
        31,
        26,
        19,
        36,
        6,
        43,
        9,
        0,
        26,
        30,
        15,
        37,
        0,
        1,
        27,
        41,
        16,
        6,
        41,
        22,
        5,
        32,
        49,
        26,
        45,
        41,
        9
      ],
      "weeklyBackordered": [
        49,
        0,
        0,
        23,
        33,
        0,
        49,
        1,
        41,
        9,
        34,
        31,
        3,
        34,
        38,
        32,
        43,
        49,
        7,
        30,
        26,
        28,
        0,
        0,
        10,
        1,
        31,
        0,
        49,
        22,
        9,
        49,
        46,
        45,
        2,
        0,
        35,
        4,
        7,
        28,
        15,
        48,
        0,
        45,
        46,
        21,
        41,
        31,
        25,
        48,
        49,
        6
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        0,
        20,
        49,
        36,
        25,
        36,
        3,
        3,
        42,
        23,
        49,
        0,
        3,
        5,
        49,
        39,
        16,
        5,
        2,
        14,
        17,
        48,
        10,
        12,
        13,
        23,
        16,
        4,
        5,
        49,
        0,
        24,
        49,
        0,
        27,
        39,
        19,
        30,
        31,
        49,
        10,
        49,
        6,
        16,
        39,
        0,
        7,
        34,
        35,
        49,
        0,
        23
      ],
      "weeklyRejected": [
        49,
        15,
        30,
        47,
        6,
        37,
        17,
        7,
        49,
        1,
        32,
        24,
        33,
        31,
        44,
        27,
        49,
        10,
        33,
        31,
        44,
        39,
        49,
        49,
        0,
        49,
        4,
        17,
        48,
        22,
        34,
        34,
        22,
        40,
        29,
        49,
        31,
        39,
        34,
        31,
        43,
        49,
        6,
        31,
        48,
        19,
        0,
        0,
        22,
        49,
        10,
        25
      ],
      "weeklyHold": [
        0,
        36,
        49,
        1,
        40,
        48,
        15,
        23,
        38,
        38,
        38,
        35,
        22,
        12,
        42,
        0,
        10,
        0,
        34,
        13,
        28,
        14,
        42,
        12,
        0,
        47,
        49,
        14,
        37,
        31,
        48,
        41,
        18,
        22,
        46,
        31,
        0,
        1,
        24,
        15,
        37,
        18,
        25,
        4,
        49,
        49,
        0,
        49,
        0,
        14,
        41,
        36
      ],
      "weeklyBackordered": [
        24,
        0,
        24,
        0,
        26,
        33,
        27,
        45,
        24,
        36,
        49,
        0,
        49,
        23,
        49,
        31,
        29,
        17,
        0,
        21,
        0,
        28,
        47,
        49,
        1,
        1,
        29,
        22,
        22,
        7,
        39,
        49,
        47,
        18,
        0,
        30,
        49,
        0,
        8,
        42,
        33,
        47,
        26,
        37,
        20,
        28,
        49,
        49,
        22,
        49,
        19,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        40,
        41,
        49,
        34,
        31,
        26,
        29,
        49,
        31,
        0,
        16,
        12,
        43,
        31,
        11,
        27,
        49,
        36,
        15,
        8,
        49,
        23,
        30,
        25,
        25,
        17,
        0,
        0,
        1,
        39,
        0,
        2,
        44,
        2,
        25,
        34,
        19,
        14,
        14,
        49,
        3,
        22,
        49,
        48,
        46,
        41,
        8,
        2,
        34,
        18,
        47,
        8
      ],
      "weeklyRejected": [
        33,
        0,
        49,
        25,
        25,
        0,
        22,
        17,
        49,
        4,
        36,
        29,
        49,
        26,
        35,
        46,
        13,
        21,
        15,
        41,
        0,
        20,
        41,
        47,
        19,
        18,
        39,
        0,
        37,
        39,
        15,
        13,
        48,
        24,
        48,
        34,
        36,
        49,
        1,
        15,
        38,
        43,
        36,
        8,
        24,
        8,
        0,
        0,
        0,
        49,
        0,
        41
      ],
      "weeklyHold": [
        0,
        0,
        42,
        24,
        31,
        37,
        4,
        17,
        28,
        49,
        47,
        49,
        10,
        1,
        35,
        34,
        0,
        0,
        4,
        7,
        41,
        49,
        3,
        49,
        0,
        40,
        19,
        0,
        26,
        0,
        48,
        0,
        6,
        12,
        48,
        33,
        23,
        38,
        1,
        0,
        47,
        34,
        11,
        40,
        42,
        39,
        14,
        49,
        35,
        49,
        44,
        35
      ],
      "weeklyBackordered": [
        49,
        36,
        13,
        37,
        38,
        27,
        6,
        34,
        28,
        32,
        23,
        42,
        10,
        3,
        0,
        27,
        1,
        38,
        24,
        49,
        34,
        26,
        0,
        27,
        14,
        24,
        12,
        28,
        49,
        12,
        40,
        47,
        48,
        49,
        17,
        0,
        25,
        24,
        27,
        34,
        41,
        34,
        24,
        22,
        31,
        0,
        49,
        3,
        4,
        49,
        49,
        36
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        31,
        35,
        0,
        35,
        45,
        21,
        43,
        20,
        46,
        5,
        41,
        15,
        14,
        11,
        25,
        16,
        49,
        48,
        31,
        40,
        32,
        20,
        0,
        37,
        29,
        47,
        43,
        37,
        21,
        45,
        37,
        43,
        23,
        43,
        48,
        49,
        22,
        6,
        0,
        31,
        31,
        32,
        49,
        25,
        49,
        31,
        12,
        6,
        19,
        12,
        49,
        26
      ],
      "weeklyRejected": [
        49,
        24,
        30,
        47,
        39,
        0,
        8,
        15,
        25,
        11,
        43,
        36,
        38,
        30,
        30,
        49,
        35,
        25,
        23,
        40,
        34,
        24,
        6,
        26,
        41,
        49,
        49,
        39,
        13,
        33,
        49,
        47,
        33,
        35,
        0,
        42,
        13,
        9,
        2,
        33,
        21,
        20,
        28,
        49,
        24,
        38,
        16,
        36,
        32,
        49,
        0,
        42
      ],
      "weeklyHold": [
        29,
        31,
        49,
        18,
        0,
        24,
        42,
        28,
        19,
        42,
        18,
        18,
        39,
        7,
        10,
        2,
        8,
        26,
        0,
        28,
        18,
        49,
        16,
        35,
        47,
        1,
        41,
        24,
        49,
        42,
        38,
        9,
        0,
        22,
        48,
        12,
        18,
        33,
        25,
        0,
        25,
        28,
        10,
        49,
        49,
        30,
        49,
        0,
        2,
        1,
        49,
        41
      ],
      "weeklyBackordered": [
        32,
        4,
        9,
        34,
        49,
        17,
        0,
        49,
        47,
        17,
        49,
        9,
        9,
        14,
        34,
        46,
        49,
        21,
        0,
        0,
        6,
        0,
        46,
        14,
        17,
        1,
        48,
        49,
        31,
        20,
        25,
        18,
        27,
        49,
        0,
        0,
        17,
        14,
        6,
        40,
        48,
        41,
        15,
        49,
        22,
        30,
        49,
        17,
        37,
        49,
        49,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        15,
        43,
        4,
        16,
        17,
        23,
        11,
        46,
        33,
        36,
        34,
        18,
        25,
        28,
        27,
        15,
        37,
        46,
        20,
        23,
        16,
        3,
        49,
        46,
        23,
        15,
        33,
        27,
        13,
        45,
        33,
        18,
        49,
        31,
        5,
        23,
        47,
        24,
        14,
        49,
        25,
        49,
        47,
        18,
        49,
        14,
        29,
        26,
        4,
        12,
        0,
        47
      ],
      "weeklyRejected": [
        45,
        49,
        24,
        20,
        27,
        3,
        49,
        8,
        49,
        14,
        26,
        31,
        30,
        35,
        48,
        13,
        8,
        39,
        49,
        9,
        0,
        49,
        1,
        22,
        36,
        32,
        49,
        0,
        47,
        16,
        48,
        17,
        48,
        32,
        5,
        8,
        21,
        0,
        12,
        23,
        29,
        25,
        44,
        1,
        10,
        28,
        41,
        19,
        21,
        19,
        0,
        35
      ],
      "weeklyHold": [
        29,
        0,
        39,
        12,
        14,
        0,
        41,
        5,
        29,
        7,
        31,
        49,
        10,
        22,
        20,
        43,
        40,
        9,
        32,
        47,
        30,
        46,
        0,
        26,
        33,
        18,
        0,
        1,
        18,
        2,
        48,
        18,
        5,
        0,
        0,
        5,
        0,
        31,
        22,
        49,
        43,
        29,
        30,
        30,
        0,
        0,
        8,
        14,
        0,
        33,
        43,
        42
      ],
      "weeklyBackordered": [
        0,
        9,
        7,
        21,
        35,
        5,
        21,
        20,
        47,
        25,
        22,
        0,
        13,
        49,
        44,
        49,
        29,
        49,
        33,
        11,
        34,
        18,
        18,
        0,
        7,
        1,
        3,
        15,
        41,
        49,
        19,
        49,
        48,
        49,
        0,
        11,
        6,
        9,
        0,
        34,
        0,
        48,
        6,
        49,
        21,
        0,
        49,
        36,
        18,
        29,
        49,
        26
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        0,
        28,
        0,
        23,
        41,
        24,
        17,
        33,
        48,
        0,
        18,
        21,
        21,
        6,
        16,
        39,
        10,
        11,
        13,
        0,
        14,
        0,
        49,
        39,
        49,
        32,
        24,
        29,
        18,
        24,
        12,
        12,
        43,
        17,
        31,
        19,
        0,
        35,
        20,
        33,
        5,
        39,
        49,
        41,
        22,
        0,
        42,
        26,
        1,
        49,
        16,
        48
      ],
      "weeklyRejected": [
        49,
        49,
        49,
        13,
        49,
        49,
        37,
        27,
        45,
        19,
        21,
        43,
        0,
        20,
        48,
        13,
        11,
        32,
        36,
        6,
        19,
        49,
        12,
        49,
        13,
        49,
        49,
        0,
        42,
        13,
        49,
        25,
        16,
        33,
        21,
        49,
        49,
        16,
        11,
        25,
        24,
        28,
        49,
        0,
        48,
        0,
        0,
        11,
        27,
        16,
        0,
        49
      ],
      "weeklyHold": [
        1,
        21,
        44,
        12,
        49,
        48,
        32,
        2,
        16,
        3,
        25,
        1,
        26,
        39,
        30,
        11,
        2,
        21,
        12,
        12,
        0,
        0,
        28,
        15,
        29,
        26,
        19,
        35,
        36,
        0,
        48,
        37,
        9,
        32,
        38,
        8,
        0,
        11,
        1,
        46,
        42,
        11,
        0,
        22,
        28,
        35,
        49,
        49,
        15,
        0,
        17,
        12
      ],
      "weeklyBackordered": [
        32,
        1,
        21,
        42,
        32,
        31,
        40,
        1,
        11,
        7,
        35,
        44,
        3,
        0,
        49,
        20,
        30,
        40,
        0,
        13,
        49,
        10,
        0,
        28,
        1,
        42,
        24,
        23,
        1,
        49,
        48,
        49,
        0,
        49,
        4,
        0,
        19,
        0,
        31,
        21,
        17,
        48,
        0,
        49,
        46,
        24,
        12,
        21,
        27,
        29,
        22,
        40
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        19,
        49,
        24,
        48,
        18,
        27,
        12,
        27,
        48,
        0,
        49,
        0,
        13,
        49,
        11,
        17,
        49,
        15,
        45,
        27,
        2,
        0,
        27,
        38,
        29,
        9,
        18,
        19,
        42,
        33,
        5,
        21,
        49,
        41,
        48,
        41,
        18,
        48,
        12,
        25,
        42,
        49,
        49,
        14,
        0,
        1,
        14,
        0,
        0,
        18,
        44,
        15
      ],
      "weeklyRejected": [
        49,
        43,
        9,
        47,
        34,
        0,
        16,
        27,
        27,
        19,
        22,
        0,
        49,
        0,
        48,
        10,
        17,
        29,
        9,
        0,
        41,
        34,
        9,
        8,
        5,
        22,
        38,
        24,
        48,
        0,
        46,
        28,
        48,
        30,
        0,
        37,
        7,
        0,
        1,
        40,
        0,
        9,
        5,
        15,
        49,
        3,
        0,
        0,
        32,
        48,
        0,
        49
      ],
      "weeklyHold": [
        49,
        49,
        36,
        32,
        32,
        48,
        49,
        12,
        8,
        49,
        45,
        1,
        18,
        5,
        34,
        34,
        0,
        30,
        0,
        25,
        41,
        29,
        17,
        30,
        41,
        15,
        11,
        10,
        45,
        3,
        42,
        10,
        0,
        0,
        30,
        30,
        41,
        0,
        49,
        49,
        14,
        49,
        5,
        31,
        49,
        49,
        25,
        29,
        15,
        2,
        21,
        27
      ],
      "weeklyBackordered": [
        2,
        17,
        0,
        41,
        47,
        46,
        16,
        49,
        49,
        29,
        0,
        26,
        3,
        14,
        29,
        35,
        1,
        23,
        0,
        13,
        27,
        0,
        12,
        0,
        32,
        8,
        27,
        32,
        4,
        47,
        35,
        0,
        6,
        49,
        0,
        0,
        17,
        22,
        19,
        31,
        11,
        47,
        29,
        49,
        12,
        11,
        49,
        0,
        30,
        48,
        49,
        11
      ]
    },
    {
      "id": "93D87233",
      "weeklyAccepted": [
        43,
        49,
        31,
        35,
        8,
        33,
        20,
        27,
        18,
        0,
        49,
        27,
        45,
        33,
        32,
        0,
        49,
        14,
        2,
        49,
        1,
        49,
        6,
        45,
        41,
        17,
        38,
        8,
        49,
        49,
        14,
        15,
        18,
        6,
        28,
        36,
        3,
        32,
        33,
        49,
        27,
        47,
        7,
        1,
        45,
        0,
        0,
        49,
        0,
        23,
        18,
        28
      ],
      "weeklyRejected": [
        49,
        16,
        3,
        0,
        39,
        17,
        45,
        39,
        49,
        9,
        11,
        17,
        49,
        29,
        46,
        14,
        34,
        46,
        14,
        20,
        6,
        17,
        15,
        17,
        0,
        27,
        30,
        25,
        25,
        47,
        13,
        47,
        48,
        6,
        38,
        49,
        49,
        24,
        18,
        49,
        31,
        16,
        18,
        6,
        49,
        18,
        24,
        14,
        3,
        0,
        0,
        49
      ],
      "weeklyHold": [
        44,
        49,
        42,
        1,
        36,
        30,
        18,
        10,
        0,
        21,
        47,
        1,
        32,
        18,
        39,
        47,
        0,
        31,
        18,
        28,
        12,
        6,
        34,
        23,
        31,
        18,
        49,
        13,
        46,
        6,
        31,
        13,
        0,
        36,
        48,
        22,
        35,
        38,
        41,
        0,
        0,
        49,
        16,
        9,
        44,
        13,
        0,
        35,
        0,
        2,
        3,
        40
      ],
      "weeklyBackordered": [
        25,
        43,
        0,
        10,
        32,
        33,
        37,
        22,
        29,
        1,
        32,
        13,
        16,
        31,
        6,
        36,
        49,
        31,
        0,
        43,
        17,
        22,
        19,
        49,
        12,
        4,
        15,
        13,
        19,
        45,
        27,
        5,
        0,
        46,
        0,
        5,
        19,
        48,
        10,
        25,
        0,
        46,
        0,
        32,
        26,
        49,
        49,
        29,
        32,
        12,
        47,
        23
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        0,
        41,
        49,
        32,
        30,
        49,
        24,
        49,
        42,
        8,
        34,
        6,
        49,
        49,
        10,
        46,
        35,
        38,
        14,
        20,
        0,
        43,
        8,
        49,
        41,
        47,
        0,
        39,
        18,
        21,
        49,
        0,
        31,
        27,
        27,
        32,
        14,
        4,
        19,
        49,
        36,
        10,
        16,
        9,
        13,
        0,
        26,
        24,
        18,
        49,
        49,
        14
      ],
      "weeklyRejected": [
        49,
        49,
        49,
        47,
        9,
        6,
        49,
        34,
        0,
        32,
        49,
        32,
        34,
        25,
        43,
        30,
        0,
        42,
        49,
        48,
        7,
        27,
        27,
        26,
        0,
        32,
        44,
        18,
        43,
        9,
        49,
        40,
        48,
        7,
        24,
        49,
        0,
        0,
        11,
        8,
        39,
        0,
        20,
        15,
        13,
        32,
        5,
        28,
        2,
        49,
        0,
        12
      ],
      "weeklyHold": [
        49,
        10,
        26,
        1,
        8,
        0,
        21,
        2,
        14,
        32,
        47,
        29,
        48,
        18,
        37,
        22,
        13,
        0,
        26,
        21,
        32,
        42,
        49,
        36,
        19,
        6,
        49,
        49,
        49,
        25,
        32,
        13,
        0,
        16,
        41,
        23,
        15,
        0,
        1,
        49,
        28,
        37,
        24,
        49,
        38,
        30,
        49,
        40,
        18,
        22,
        2,
        0
      ],
      "weeklyBackordered": [
        48,
        23,
        27,
        26,
        44,
        23,
        13,
        35,
        49,
        29,
        39,
        33,
        3,
        28,
        18,
        49,
        37,
        49,
        25,
        18,
        37,
        7,
        35,
        8,
        5,
        17,
        14,
        22,
        3,
        42,
        10,
        23,
        0,
        39,
        28,
        26,
        29,
        0,
        14,
        18,
        8,
        48,
        9,
        26,
        16,
        33,
        40,
        19,
        22,
        19,
        32,
        38
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        0,
        18,
        47,
        17,
        35,
        49,
        26,
        49,
        4,
        30,
        39,
        15,
        39,
        18,
        46,
        27,
        28,
        11,
        5,
        17,
        29,
        23,
        0,
        32,
        0,
        45,
        19,
        30,
        33,
        43,
        32,
        0,
        16,
        49,
        48,
        18,
        46,
        32,
        35,
        49,
        21,
        32,
        8,
        27,
        49,
        41,
        14,
        6,
        49,
        40,
        0,
        46
      ],
      "weeklyRejected": [
        24,
        37,
        2,
        1,
        47,
        49,
        16,
        49,
        35,
        20,
        0,
        28,
        22,
        21,
        29,
        5,
        24,
        49,
        46,
        46,
        37,
        32,
        23,
        49,
        32,
        49,
        0,
        10,
        29,
        24,
        29,
        47,
        47,
        11,
        16,
        48,
        32,
        0,
        47,
        0,
        38,
        12,
        6,
        0,
        49,
        28,
        8,
        49,
        17,
        34,
        27,
        2
      ],
      "weeklyHold": [
        29,
        36,
        44,
        12,
        46,
        37,
        0,
        2,
        0,
        27,
        43,
        24,
        40,
        11,
        26,
        20,
        47,
        43,
        41,
        15,
        21,
        44,
        48,
        0,
        31,
        48,
        17,
        20,
        46,
        0,
        45,
        7,
        24,
        49,
        5,
        28,
        23,
        26,
        49,
        25,
        2,
        30,
        0,
        42,
        0,
        43,
        0,
        3,
        25,
        0,
        31,
        34
      ],
      "weeklyBackordered": [
        31,
        31,
        14,
        0,
        41,
        33,
        49,
        44,
        13,
        19,
        37,
        48,
        32,
        0,
        44,
        43,
        24,
        49,
        25,
        28,
        47,
        0,
        1,
        49,
        26,
        36,
        23,
        24,
        20,
        24,
        1,
        29,
        8,
        11,
        11,
        14,
        47,
        10,
        35,
        0,
        35,
        48,
        0,
        38,
        17,
        25,
        14,
        17,
        0,
        23,
        34,
        14
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        19,
        23,
        23,
        37,
        32,
        0,
        23,
        49,
        9,
        4,
        49,
        0,
        34,
        48,
        15,
        9,
        49,
        0,
        0,
        21,
        25,
        41,
        36,
        20,
        29,
        40,
        44,
        11,
        31,
        49,
        33,
        49,
        49,
        5,
        48,
        35,
        46,
        2,
        46,
        49,
        4,
        14,
        49,
        33,
        49,
        12,
        17,
        49,
        0,
        15,
        28,
        20
      ],
      "weeklyRejected": [
        38,
        0,
        34,
        47,
        49,
        22,
        32,
        0,
        49,
        6,
        31,
        27,
        6,
        43,
        2,
        23,
        31,
        30,
        16,
        0,
        10,
        34,
        21,
        26,
        22,
        19,
        49,
        10,
        20,
        21,
        41,
        17,
        43,
        49,
        0,
        49,
        25,
        37,
        6,
        49,
        28,
        49,
        35,
        1,
        26,
        29,
        43,
        12,
        0,
        17,
        28,
        49
      ],
      "weeklyHold": [
        47,
        10,
        27,
        1,
        49,
        27,
        29,
        22,
        0,
        0,
        25,
        26,
        26,
        40,
        24,
        49,
        19,
        11,
        18,
        0,
        47,
        5,
        4,
        29,
        46,
        1,
        12,
        20,
        39,
        44,
        31,
        13,
        0,
        4,
        1,
        6,
        47,
        33,
        45,
        32,
        2,
        49,
        0,
        49,
        49,
        37,
        12,
        41,
        0,
        28,
        14,
        36
      ],
      "weeklyBackordered": [
        36,
        10,
        0,
        10,
        47,
        31,
        43,
        19,
        40,
        0,
        49,
        49,
        22,
        31,
        49,
        49,
        32,
        33,
        0,
        20,
        21,
        24,
        49,
        33,
        15,
        48,
        7,
        36,
        33,
        29,
        33,
        13,
        48,
        49,
        0,
        30,
        49,
        12,
        23,
        43,
        41,
        43,
        0,
        49,
        15,
        19,
        21,
        31,
        21,
        10,
        40,
        39
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        0,
        24,
        23,
        18,
        49,
        19,
        49,
        10,
        41,
        22,
        49,
        31,
        21,
        48,
        49,
        1,
        49,
        3,
        0,
        27,
        7,
        20,
        31,
        48,
        35,
        23,
        24,
        49,
        19,
        43,
        49,
        13,
        21,
        7,
        0,
        43,
        23,
        0,
        49,
        49,
        9,
        0,
        49,
        1,
        29,
        0,
        9,
        15,
        18,
        40,
        36,
        27
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        35,
        40,
        9,
        8,
        18,
        22,
        3,
        21,
        18,
        26,
        23,
        42,
        38,
        49,
        3,
        3,
        47,
        10,
        2,
        31,
        13,
        19,
        42,
        49,
        40,
        46,
        22,
        10,
        47,
        48,
        49,
        0,
        11,
        20,
        49,
        6,
        35,
        33,
        49,
        49,
        10,
        38,
        32,
        49,
        37,
        0,
        49,
        3,
        49
      ],
      "weeklyHold": [
        26,
        39,
        34,
        38,
        49,
        6,
        49,
        3,
        44,
        36,
        47,
        9,
        29,
        11,
        32,
        12,
        0,
        45,
        2,
        33,
        32,
        43,
        6,
        1,
        6,
        48,
        0,
        47,
        35,
        0,
        19,
        9,
        10,
        20,
        14,
        0,
        23,
        16,
        1,
        9,
        27,
        49,
        27,
        49,
        37,
        30,
        40,
        44,
        21,
        39,
        44,
        41
      ],
      "weeklyBackordered": [
        32,
        34,
        21,
        17,
        43,
        44,
        12,
        19,
        40,
        0,
        28,
        27,
        36,
        49,
        8,
        23,
        33,
        49,
        34,
        0,
        25,
        24,
        12,
        27,
        14,
        25,
        21,
        17,
        7,
        49,
        41,
        44,
        48,
        24,
        1,
        0,
        30,
        0,
        16,
        22,
        11,
        27,
        15,
        44,
        39,
        36,
        37,
        30,
        17,
        16,
        49,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        25,
        37,
        49,
        0,
        15,
        4,
        21,
        2,
        38,
        7,
        47,
        1,
        18,
        19,
        16,
        22,
        49,
        32,
        0,
        19,
        0,
        0,
        41,
        11,
        29,
        31,
        17,
        44,
        10,
        32,
        46,
        44,
        25,
        41,
        48,
        13,
        9,
        16,
        24,
        41,
        21,
        31,
        49,
        33,
        34,
        0,
        26,
        32,
        0,
        7,
        11,
        48
      ],
      "weeklyRejected": [
        18,
        4,
        7,
        13,
        20,
        11,
        26,
        11,
        22,
        26,
        45,
        12,
        14,
        48,
        48,
        16,
        10,
        49,
        31,
        35,
        43,
        43,
        23,
        12,
        0,
        36,
        42,
        23,
        37,
        48,
        26,
        17,
        39,
        5,
        6,
        23,
        23,
        15,
        41,
        16,
        16,
        7,
        10,
        30,
        49,
        27,
        0,
        49,
        18,
        38,
        8,
        29
      ],
      "weeklyHold": [
        38,
        8,
        6,
        16,
        3,
        24,
        11,
        34,
        15,
        22,
        30,
        49,
        17,
        22,
        4,
        3,
        49,
        25,
        0,
        42,
        20,
        21,
        0,
        23,
        5,
        25,
        24,
        41,
        27,
        16,
        34,
        0,
        0,
        49,
        10,
        21,
        47,
        1,
        7,
        22,
        28,
        43,
        23,
        25,
        29,
        22,
        20,
        30,
        0,
        32,
        0,
        21
      ],
      "weeklyBackordered": [
        15,
        25,
        31,
        1,
        36,
        35,
        28,
        11,
        14,
        17,
        17,
        16,
        13,
        30,
        24,
        1,
        38,
        26,
        0,
        40,
        37,
        0,
        40,
        25,
        33,
        21,
        20,
        0,
        25,
        43,
        16,
        8,
        26,
        32,
        10,
        0,
        6,
        0,
        49,
        49,
        48,
        48,
        22,
        36,
        49,
        37,
        25,
        0,
        0,
        17,
        40,
        47
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        0,
        40,
        27,
        0,
        48,
        3,
        8,
        38,
        41,
        32,
        32,
        8,
        22,
        42,
        31,
        12,
        39,
        29,
        25,
        49,
        19,
        46,
        17,
        35,
        30,
        28,
        22,
        17,
        1,
        49,
        0,
        0,
        26,
        29,
        36,
        5,
        24,
        9,
        30,
        34,
        13,
        17,
        49,
        22,
        20,
        19,
        6,
        1,
        5,
        49,
        37,
        9
      ],
      "weeklyRejected": [
        49,
        14,
        25,
        0,
        21,
        1,
        17,
        8,
        0,
        40,
        36,
        31,
        26,
        0,
        23,
        26,
        26,
        49,
        21,
        31,
        0,
        0,
        30,
        42,
        49,
        40,
        27,
        9,
        48,
        44,
        6,
        31,
        32,
        49,
        31,
        25,
        25,
        5,
        15,
        38,
        39,
        49,
        49,
        21,
        49,
        23,
        29,
        49,
        12,
        27,
        0,
        20
      ],
      "weeklyHold": [
        0,
        1,
        23,
        25,
        41,
        0,
        37,
        2,
        5,
        10,
        9,
        43,
        1,
        42,
        39,
        4,
        30,
        48,
        47,
        18,
        36,
        7,
        16,
        47,
        49,
        39,
        12,
        18,
        4,
        8,
        48,
        19,
        39,
        27,
        37,
        26,
        37,
        32,
        28,
        27,
        30,
        31,
        48,
        25,
        37,
        49,
        30,
        35,
        25,
        36,
        3,
        15
      ],
      "weeklyBackordered": [
        46,
        11,
        7,
        4,
        44,
        23,
        49,
        3,
        10,
        5,
        17,
        43,
        26,
        16,
        22,
        41,
        22,
        18,
        28,
        24,
        39,
        4,
        34,
        42,
        1,
        24,
        17,
        19,
        14,
        49,
        41,
        49,
        0,
        27,
        0,
        0,
        49,
        3,
        49,
        30,
        0,
        48,
        0,
        49,
        49,
        30,
        26,
        11,
        39,
        23,
        49,
        2
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        0,
        17,
        33,
        41,
        7,
        9,
        16,
        24,
        48,
        7,
        32,
        11,
        46,
        18,
        49,
        37,
        32,
        25,
        0,
        49,
        35,
        48,
        2,
        49,
        35,
        17,
        40,
        32,
        45,
        49,
        48,
        26,
        49,
        0,
        29,
        49,
        28,
        1,
        12,
        49,
        24,
        6,
        49,
        29,
        42,
        0,
        5,
        30,
        26,
        49,
        21,
        42
      ],
      "weeklyRejected": [
        45,
        9,
        38,
        47,
        26,
        13,
        20,
        45,
        36,
        37,
        27,
        17,
        28,
        39,
        9,
        39,
        32,
        49,
        1,
        21,
        18,
        34,
        14,
        27,
        29,
        49,
        7,
        47,
        48,
        17,
        49,
        40,
        48,
        49,
        21,
        49,
        17,
        25,
        2,
        0,
        24,
        16,
        0,
        0,
        38,
        0,
        11,
        42,
        8,
        49,
        28,
        33
      ],
      "weeklyHold": [
        12,
        45,
        42,
        23,
        10,
        39,
        21,
        20,
        29,
        21,
        1,
        1,
        1,
        48,
        25,
        21,
        48,
        15,
        47,
        16,
        18,
        21,
        0,
        21,
        35,
        34,
        49,
        45,
        49,
        29,
        32,
        0,
        0,
        0,
        27,
        17,
        22,
        1,
        1,
        12,
        49,
        49,
        0,
        32,
        49,
        48,
        13,
        34,
        23,
        33,
        49,
        29
      ],
      "weeklyBackordered": [
        29,
        0,
        22,
        13,
        49,
        11,
        49,
        37,
        19,
        9,
        7,
        35,
        3,
        49,
        49,
        13,
        19,
        49,
        0,
        25,
        36,
        0,
        0,
        9,
        1,
        2,
        35,
        6,
        45,
        25,
        0,
        41,
        25,
        15,
        49,
        0,
        24,
        19,
        49,
        29,
        19,
        48,
        0,
        0,
        16,
        25,
        28,
        11,
        43,
        38,
        46,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        0,
        1,
        49,
        19,
        34,
        8,
        21,
        43,
        31,
        38,
        28,
        43,
        46,
        0,
        24,
        49,
        47,
        0,
        6,
        49,
        12,
        31,
        42,
        41,
        41,
        27,
        11,
        11,
        27,
        13,
        49,
        39,
        20,
        30,
        48,
        36,
        35,
        28,
        11,
        25,
        17,
        30,
        49,
        25,
        6,
        0,
        22,
        3,
        0,
        39,
        33,
        0
      ],
      "weeklyRejected": [
        49,
        5,
        12,
        8,
        26,
        44,
        0,
        31,
        23,
        29,
        22,
        49,
        49,
        48,
        48,
        21,
        49,
        49,
        49,
        22,
        37,
        49,
        18,
        49,
        40,
        31,
        49,
        25,
        32,
        23,
        24,
        42,
        41,
        31,
        1,
        49,
        48,
        28,
        18,
        16,
        11,
        44,
        0,
        2,
        39,
        21,
        0,
        31,
        32,
        49,
        0,
        43
      ],
      "weeklyHold": [
        26,
        49,
        49,
        29,
        0,
        7,
        31,
        2,
        10,
        3,
        47,
        49,
        23,
        12,
        46,
        30,
        25,
        13,
        22,
        29,
        2,
        33,
        23,
        1,
        47,
        10,
        49,
        14,
        36,
        22,
        20,
        13,
        0,
        9,
        48,
        13,
        32,
        11,
        19,
        47,
        29,
        29,
        14,
        49,
        49,
        28,
        1,
        45,
        0,
        21,
        2,
        49
      ],
      "weeklyBackordered": [
        22,
        49,
        0,
        37,
        44,
        36,
        49,
        32,
        26,
        20,
        49,
        49,
        3,
        36,
        16,
        2,
        35,
        16,
        33,
        3,
        24,
        0,
        33,
        45,
        22,
        33,
        13,
        9,
        30,
        38,
        0,
        49,
        43,
        18,
        3,
        0,
        1,
        0,
        0,
        0,
        15,
        46,
        35,
        49,
        13,
        49,
        21,
        33,
        28,
        30,
        1,
        29
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        24,
        49,
        17,
        29,
        41,
        30,
        35,
        19,
        46,
        16,
        36,
        0,
        41,
        29,
        20,
        41,
        29,
        43,
        0,
        31,
        7,
        29,
        49,
        30,
        41,
        33,
        29,
        32,
        1,
        25,
        35,
        6,
        24,
        0,
        27,
        29,
        30,
        48,
        0,
        49,
        49,
        25,
        30,
        0,
        49,
        24,
        10,
        21,
        40,
        36,
        21,
        44
      ],
      "weeklyRejected": [
        36,
        10,
        49,
        27,
        49,
        32,
        0,
        27,
        34,
        27,
        36,
        34,
        34,
        31,
        29,
        16,
        7,
        49,
        46,
        17,
        0,
        16,
        17,
        23,
        2,
        33,
        43,
        36,
        28,
        14,
        29,
        47,
        45,
        22,
        32,
        44,
        33,
        49,
        40,
        21,
        19,
        18,
        30,
        0,
        49,
        13,
        23,
        0,
        29,
        39,
        10,
        31
      ],
      "weeklyHold": [
        49,
        7,
        40,
        1,
        4,
        7,
        35,
        14,
        31,
        14,
        47,
        46,
        44,
        11,
        27,
        15,
        17,
        25,
        0,
        35,
        9,
        49,
        16,
        29,
        30,
        44,
        37,
        2,
        34,
        12,
        25,
        30,
        0,
        49,
        19,
        17,
        30,
        5,
        38,
        33,
        28,
        24,
        37,
        42,
        4,
        0,
        25,
        16,
        0,
        49,
        25,
        0
      ],
      "weeklyBackordered": [
        13,
        20,
        22,
        30,
        40,
        45,
        34,
        9,
        44,
        6,
        41,
        25,
        3,
        0,
        14,
        16,
        21,
        4,
        0,
        5,
        7,
        29,
        48,
        49,
        28,
        3,
        18,
        9,
        13,
        31,
        26,
        2,
        35,
        25,
        18,
        34,
        24,
        4,
        46,
        17,
        18,
        48,
        24,
        25,
        23,
        22,
        48,
        26,
        5,
        34,
        49,
        35
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        0,
        41,
        42,
        25,
        30,
        0,
        45,
        17,
        29,
        17,
        19,
        0,
        23,
        20,
        35,
        10,
        33,
        0,
        0,
        36,
        0,
        39,
        34,
        16,
        48,
        47,
        28,
        44,
        1,
        49,
        0,
        13,
        42,
        46,
        20,
        2,
        26,
        29,
        49,
        49,
        0,
        18,
        49,
        49,
        43,
        0,
        7,
        13,
        27,
        49,
        24,
        0
      ],
      "weeklyRejected": [
        36,
        1,
        44,
        28,
        30,
        49,
        30,
        21,
        40,
        29,
        33,
        49,
        15,
        48,
        48,
        15,
        12,
        49,
        37,
        21,
        49,
        21,
        40,
        22,
        19,
        46,
        48,
        11,
        41,
        48,
        36,
        31,
        38,
        11,
        18,
        49,
        36,
        38,
        15,
        1,
        14,
        15,
        26,
        30,
        41,
        49,
        18,
        34,
        0,
        0,
        0,
        49
      ],
      "weeklyHold": [
        11,
        12,
        40,
        5,
        49,
        0,
        49,
        2,
        39,
        7,
        27,
        46,
        48,
        24,
        49,
        24,
        0,
        24,
        1,
        27,
        32,
        16,
        49,
        0,
        21,
        1,
        24,
        0,
        38,
        0,
        48,
        0,
        0,
        49,
        18,
        48,
        49,
        17,
        10,
        39,
        0,
        22,
        4,
        18,
        43,
        14,
        0,
        49,
        0,
        20,
        24,
        36
      ],
      "weeklyBackordered": [
        38,
        4,
        17,
        31,
        49,
        16,
        45,
        3,
        0,
        39,
        22,
        49,
        22,
        49,
        0,
        6,
        49,
        39,
        9,
        2,
        40,
        0,
        14,
        49,
        4,
        18,
        27,
        49,
        14,
        13,
        17,
        44,
        34,
        34,
        0,
        23,
        19,
        0,
        49,
        8,
        31,
        30,
        29,
        49,
        37,
        7,
        44,
        6,
        29,
        22,
        14,
        1
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        0,
        4,
        49,
        12,
        23,
        49,
        49,
        20,
        40,
        33,
        49,
        7,
        41,
        18,
        40,
        14,
        49,
        11,
        0,
        34,
        0,
        43,
        47,
        42,
        19,
        47,
        42,
        0,
        7,
        49,
        0,
        18,
        23,
        32,
        14,
        2,
        44,
        0,
        7,
        40,
        9,
        49,
        49,
        38,
        49,
        0,
        9,
        19,
        1,
        46,
        12,
        43
      ],
      "weeklyRejected": [
        31,
        33,
        27,
        40,
        21,
        28,
        49,
        41,
        45,
        31,
        25,
        0,
        49,
        25,
        48,
        38,
        0,
        49,
        0,
        22,
        0,
        21,
        27,
        47,
        35,
        41,
        46,
        0,
        48,
        0,
        20,
        31,
        31,
        22,
        43,
        26,
        33,
        30,
        9,
        49,
        23,
        23,
        28,
        11,
        48,
        40,
        0,
        49,
        4,
        21,
        25,
        42
      ],
      "weeklyHold": [
        26,
        1,
        34,
        23,
        25,
        32,
        33,
        47,
        30,
        28,
        25,
        41,
        25,
        13,
        39,
        17,
        18,
        31,
        28,
        31,
        24,
        23,
        48,
        0,
        18,
        27,
        20,
        28,
        28,
        44,
        33,
        48,
        39,
        34,
        9,
        49,
        27,
        0,
        32,
        49,
        17,
        19,
        0,
        44,
        49,
        40,
        40,
        49,
        24,
        32,
        45,
        49
      ],
      "weeklyBackordered": [
        14,
        0,
        14,
        34,
        38,
        46,
        39,
        42,
        10,
        17,
        17,
        49,
        3,
        8,
        23,
        14,
        49,
        39,
        16,
        34,
        46,
        32,
        49,
        17,
        1,
        22,
        18,
        0,
        32,
        41,
        35,
        49,
        0,
        0,
        6,
        21,
        19,
        0,
        0,
        22,
        44,
        35,
        14,
        49,
        34,
        3,
        49,
        25,
        42,
        49,
        32,
        41
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        0,
        47,
        49,
        48,
        35,
        37,
        11,
        49,
        43,
        42,
        49,
        6,
        43,
        9,
        29,
        25,
        42,
        30,
        0,
        41,
        18,
        49,
        0,
        37,
        38,
        47,
        47,
        13,
        2,
        20,
        0,
        49,
        49,
        15,
        29,
        28,
        23,
        48,
        49,
        9,
        14,
        49,
        46,
        0,
        31,
        0,
        14,
        40,
        33,
        49,
        24,
        12
      ],
      "weeklyRejected": [
        49,
        17,
        23,
        20,
        31,
        0,
        23,
        22,
        0,
        1,
        31,
        29,
        49,
        11,
        27,
        49,
        0,
        31,
        10,
        0,
        49,
        19,
        6,
        17,
        6,
        30,
        36,
        6,
        46,
        30,
        35,
        18,
        26,
        0,
        37,
        49,
        49,
        1,
        23,
        25,
        43,
        0,
        41,
        12,
        37,
        41,
        8,
        25,
        0,
        38,
        0,
        49
      ],
      "weeklyHold": [
        28,
        41,
        27,
        17,
        42,
        44,
        0,
        2,
        0,
        15,
        45,
        49,
        48,
        39,
        22,
        14,
        25,
        16,
        36,
        27,
        43,
        32,
        14,
        31,
        29,
        1,
        30,
        16,
        30,
        26,
        33,
        15,
        0,
        24,
        11,
        42,
        0,
        32,
        27,
        23,
        0,
        36,
        30,
        38,
        21,
        28,
        0,
        35,
        0,
        10,
        0,
        29
      ],
      "weeklyBackordered": [
        17,
        12,
        23,
        38,
        17,
        22,
        24,
        42,
        31,
        3,
        32,
        40,
        7,
        29,
        17,
        37,
        48,
        35,
        49,
        34,
        37,
        36,
        49,
        42,
        48,
        1,
        30,
        10,
        47,
        25,
        46,
        49,
        0,
        0,
        16,
        30,
        35,
        16,
        5,
        11,
        0,
        48,
        12,
        49,
        0,
        37,
        49,
        5,
        10,
        49,
        26,
        33
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        49,
        15,
        19,
        29,
        32,
        0,
        13,
        31,
        48,
        32,
        29,
        13,
        35,
        42,
        27,
        32,
        35,
        48,
        0,
        13,
        13,
        4,
        34,
        42,
        36,
        38,
        27,
        38,
        1,
        12,
        14,
        17,
        13,
        19,
        36,
        32,
        4,
        26,
        19,
        27,
        21,
        11,
        13,
        6,
        23,
        11,
        0,
        15,
        8,
        7,
        6,
        17
      ],
      "weeklyRejected": [
        19,
        9,
        36,
        20,
        33,
        20,
        48,
        6,
        28,
        48,
        0,
        12,
        36,
        45,
        46,
        37,
        47,
        49,
        45,
        36,
        3,
        49,
        11,
        48,
        26,
        42,
        49,
        28,
        48,
        18,
        0,
        47,
        19,
        5,
        21,
        48,
        37,
        37,
        4,
        16,
        36,
        0,
        21,
        24,
        10,
        31,
        18,
        41,
        8,
        49,
        0,
        44
      ],
      "weeklyHold": [
        12,
        23,
        47,
        16,
        0,
        34,
        35,
        21,
        39,
        31,
        25,
        6,
        27,
        24,
        18,
        36,
        7,
        20,
        43,
        22,
        27,
        27,
        1,
        44,
        23,
        28,
        35,
        20,
        34,
        0,
        6,
        12,
        19,
        12,
        17,
        36,
        16,
        10,
        1,
        16,
        49,
        17,
        8,
        19,
        49,
        36,
        35,
        44,
        0,
        13,
        30,
        26
      ],
      "weeklyBackordered": [
        40,
        3,
        48,
        43,
        40,
        46,
        32,
        26,
        21,
        32,
        49,
        42,
        9,
        49,
        41,
        15,
        37,
        49,
        0,
        21,
        11,
        16,
        0,
        27,
        18,
        27,
        24,
        24,
        22,
        49,
        12,
        5,
        31,
        46,
        19,
        0,
        49,
        21,
        27,
        30,
        20,
        31,
        0,
        0,
        21,
        49,
        49,
        14,
        32,
        27,
        27,
        1
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        0,
        8,
        27,
        27,
        43,
        32,
        1,
        49,
        11,
        29,
        49,
        30,
        17,
        0,
        1,
        11,
        43,
        20,
        0,
        49,
        10,
        45,
        18,
        45,
        5,
        41,
        14,
        1,
        1,
        25,
        28,
        22,
        31,
        39,
        38,
        35,
        9,
        27,
        5,
        20,
        12,
        12,
        40,
        7,
        28,
        6,
        7,
        49,
        10,
        49,
        32,
        48
      ],
      "weeklyRejected": [
        38,
        13,
        30,
        28,
        23,
        14,
        4,
        49,
        21,
        13,
        24,
        25,
        28,
        46,
        28,
        23,
        31,
        5,
        40,
        5,
        10,
        48,
        32,
        11,
        49,
        18,
        37,
        16,
        48,
        2,
        11,
        20,
        48,
        49,
        8,
        49,
        48,
        28,
        1,
        3,
        18,
        39,
        0,
        22,
        49,
        8,
        49,
        31,
        17,
        46,
        0,
        49
      ],
      "weeklyHold": [
        36,
        4,
        25,
        8,
        17,
        29,
        28,
        31,
        23,
        28,
        35,
        19,
        24,
        49,
        22,
        15,
        31,
        16,
        21,
        20,
        7,
        6,
        24,
        6,
        21,
        36,
        35,
        30,
        49,
        19,
        32,
        9,
        29,
        49,
        0,
        13,
        2,
        0,
        43,
        0,
        0,
        18,
        2,
        5,
        49,
        45,
        12,
        49,
        0,
        38,
        28,
        49
      ],
      "weeklyBackordered": [
        19,
        19,
        18,
        1,
        49,
        4,
        6,
        43,
        1,
        17,
        19,
        32,
        21,
        49,
        2,
        25,
        14,
        12,
        38,
        24,
        48,
        24,
        33,
        34,
        25,
        8,
        2,
        49,
        38,
        40,
        44,
        36,
        48,
        48,
        5,
        0,
        33,
        11,
        19,
        31,
        21,
        30,
        0,
        49,
        21,
        27,
        36,
        4,
        8,
        43,
        34,
        4
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyAccepted": [
        25,
        29,
        24,
        48,
        23,
        0,
        22,
        32,
        29,
        49,
        49,
        0,
        31,
        29,
        16,
        44,
        27,
        14,
        3,
        28,
        8,
        5,
        49,
        44,
        0,
        25,
        15,
        42,
        13,
        23,
        17,
        0,
        45,
        9,
        28,
        38,
        36,
        11,
        8,
        49,
        18,
        22,
        48,
        43,
        19,
        5,
        32,
        0,
        0,
        0,
        0,
        48
      ],
      "weeklyRejected": [
        24,
        18,
        35,
        41,
        34,
        17,
        49,
        0,
        41,
        48,
        25,
        4,
        34,
        9,
        14,
        18,
        24,
        49,
        49,
        45,
        13,
        34,
        21,
        13,
        29,
        49,
        35,
        30,
        45,
        32,
        0,
        38,
        10,
        23,
        34,
        49,
        35,
        23,
        36,
        47,
        43,
        46,
        49,
        3,
        42,
        49,
        10,
        9,
        3,
        49,
        10,
        49
      ],
      "weeklyHold": [
        25,
        25,
        22,
        15,
        49,
        0,
        26,
        17,
        16,
        17,
        42,
        46,
        48,
        24,
        35,
        43,
        18,
        32,
        26,
        39,
        7,
        11,
        22,
        26,
        28,
        48,
        0,
        0,
        26,
        6,
        37,
        8,
        16,
        43,
        11,
        32,
        27,
        0,
        48,
        0,
        0,
        26,
        21,
        49,
        42,
        33,
        49,
        49,
        8,
        0,
        49,
        30
      ],
      "weeklyBackordered": [
        33,
        34,
        24,
        31,
        48,
        14,
        0,
        38,
        33,
        16,
        11,
        30,
        5,
        43,
        38,
        13,
        4,
        49,
        0,
        8,
        49,
        17,
        9,
        11,
        33,
        24,
        1,
        21,
        45,
        49,
        29,
        13,
        9,
        49,
        0,
        0,
        1,
        0,
        0,
        6,
        48,
        48,
        0,
        27,
        16,
        46,
        34,
        27,
        25,
        15,
        16,
        13
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        34,
        39,
        49,
        29,
        49,
        49,
        7,
        49,
        48,
        0,
        49,
        0,
        49,
        26,
        1,
        37,
        0,
        16,
        8,
        49,
        0,
        34,
        0,
        27,
        42,
        47,
        49,
        49,
        5,
        21,
        12,
        23,
        14,
        0,
        17,
        44,
        37,
        15,
        15,
        49,
        5,
        34,
        27,
        25,
        49,
        26,
        19,
        4,
        11,
        49,
        31,
        30
      ],
      "weeklyRejected": [
        49,
        32,
        25,
        20,
        46,
        23,
        0,
        39,
        49,
        20,
        28,
        18,
        8,
        9,
        48,
        38,
        30,
        31,
        43,
        0,
        11,
        26,
        29,
        49,
        17,
        0,
        35,
        15,
        39,
        38,
        43,
        17,
        3,
        22,
        21,
        49,
        49,
        14,
        4,
        28,
        6,
        5,
        49,
        11,
        46,
        49,
        23,
        30,
        18,
        49,
        12,
        30
      ],
      "weeklyHold": [
        2,
        0,
        35,
        1,
        47,
        48,
        38,
        29,
        40,
        29,
        23,
        40,
        48,
        26,
        41,
        0,
        0,
        48,
        0,
        21,
        23,
        35,
        5,
        33,
        29,
        16,
        35,
        5,
        29,
        23,
        48,
        17,
        24,
        44,
        16,
        30,
        40,
        0,
        35,
        39,
        49,
        31,
        12,
        42,
        0,
        49,
        49,
        36,
        0,
        35,
        24,
        15
      ],
      "weeklyBackordered": [
        35,
        22,
        34,
        0,
        49,
        34,
        46,
        31,
        7,
        8,
        32,
        23,
        49,
        7,
        19,
        1,
        32,
        41,
        25,
        0,
        44,
        21,
        49,
        0,
        16,
        20,
        8,
        11,
        21,
        49,
        33,
        49,
        0,
        32,
        12,
        0,
        31,
        0,
        0,
        0,
        0,
        27,
        0,
        33,
        26,
        35,
        49,
        46,
        28,
        23,
        49,
        49
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        23,
        36,
        33,
        24,
        21,
        45,
        1,
        8,
        36,
        27,
        15,
        0,
        30,
        34,
        29,
        11,
        47,
        21,
        0,
        27,
        3,
        46,
        24,
        49,
        13,
        45,
        0,
        37,
        31,
        1,
        23,
        0,
        49,
        15,
        48,
        33,
        29,
        48,
        17,
        49,
        19,
        49,
        49,
        25,
        49,
        4,
        10,
        41,
        20,
        43,
        14,
        12
      ],
      "weeklyRejected": [
        49,
        18,
        30,
        17,
        33,
        0,
        15,
        36,
        22,
        1,
        0,
        0,
        27,
        48,
        15,
        49,
        23,
        41,
        18,
        33,
        9,
        1,
        27,
        32,
        23,
        49,
        49,
        12,
        33,
        0,
        26,
        42,
        22,
        28,
        10,
        34,
        31,
        0,
        1,
        15,
        48,
        39,
        48,
        34,
        10,
        6,
        17,
        27,
        0,
        25,
        48,
        49
      ],
      "weeklyHold": [
        37,
        31,
        0,
        1,
        13,
        38,
        16,
        2,
        46,
        0,
        17,
        29,
        27,
        37,
        18,
        49,
        4,
        24,
        20,
        0,
        26,
        18,
        17,
        32,
        32,
        22,
        9,
        31,
        4,
        2,
        48,
        0,
        21,
        8,
        15,
        16,
        43,
        11,
        28,
        49,
        12,
        26,
        25,
        49,
        4,
        26,
        34,
        31,
        29,
        37,
        49,
        0
      ],
      "weeklyBackordered": [
        2,
        0,
        0,
        4,
        49,
        46,
        44,
        40,
        13,
        2,
        49,
        14,
        3,
        14,
        41,
        49,
        29,
        48,
        0,
        22,
        49,
        0,
        4,
        19,
        27,
        1,
        45,
        2,
        3,
        49,
        34,
        48,
        16,
        45,
        11,
        4,
        34,
        40,
        28,
        3,
        6,
        38,
        3,
        49,
        10,
        36,
        28,
        36,
        16,
        30,
        26,
        17
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        5,
        41,
        49,
        46,
        44,
        0,
        1,
        27,
        37,
        0,
        41,
        33,
        0,
        0,
        29,
        17,
        49,
        30,
        23,
        6,
        20,
        47,
        49,
        32,
        4,
        17,
        18,
        23,
        14,
        7,
        19,
        0,
        31,
        0,
        48,
        49,
        8,
        22,
        18,
        49,
        41,
        2,
        26,
        23,
        33,
        0,
        0,
        22,
        23,
        0,
        24,
        10
      ],
      "weeklyRejected": [
        30,
        11,
        17,
        32,
        26,
        26,
        0,
        19,
        27,
        11,
        0,
        18,
        30,
        48,
        20,
        14,
        43,
        35,
        13,
        34,
        49,
        35,
        20,
        49,
        21,
        49,
        22,
        30,
        48,
        35,
        19,
        40,
        48,
        26,
        0,
        49,
        7,
        49,
        1,
        14,
        7,
        49,
        45,
        33,
        49,
        21,
        28,
        20,
        0,
        25,
        8,
        33
      ],
      "weeklyHold": [
        49,
        49,
        45,
        27,
        0,
        48,
        8,
        15,
        40,
        23,
        17,
        49,
        19,
        13,
        5,
        11,
        15,
        39,
        31,
        13,
        31,
        49,
        1,
        0,
        46,
        36,
        0,
        16,
        49,
        0,
        39,
        6,
        10,
        33,
        36,
        0,
        38,
        8,
        33,
        6,
        24,
        49,
        10,
        39,
        44,
        18,
        9,
        0,
        9,
        36,
        26,
        0
      ],
      "weeklyBackordered": [
        49,
        41,
        0,
        31,
        31,
        46,
        49,
        1,
        26,
        14,
        35,
        49,
        8,
        49,
        49,
        1,
        19,
        15,
        4,
        22,
        0,
        43,
        0,
        0,
        11,
        5,
        23,
        49,
        27,
        49,
        0,
        0,
        28,
        18,
        42,
        0,
        13,
        17,
        38,
        47,
        0,
        48,
        39,
        37,
        33,
        15,
        26,
        8,
        17,
        42,
        9,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        3,
        49,
        7,
        9,
        29,
        30,
        1,
        49,
        30,
        49,
        27,
        20,
        15,
        42,
        29,
        0,
        46,
        1,
        12,
        14,
        8,
        15,
        49,
        6,
        13,
        22,
        44,
        1,
        24,
        41,
        0,
        23,
        36,
        0,
        33,
        9,
        6,
        0,
        21,
        49,
        23,
        28,
        3,
        34,
        49,
        39,
        5,
        31,
        16,
        22,
        18,
        32
      ],
      "weeklyRejected": [
        35,
        34,
        32,
        44,
        49,
        0,
        33,
        32,
        4,
        17,
        32,
        9,
        31,
        48,
        46,
        48,
        12,
        49,
        17,
        0,
        43,
        25,
        5,
        40,
        28,
        49,
        39,
        36,
        24,
        25,
        0,
        28,
        31,
        49,
        16,
        23,
        14,
        0,
        18,
        12,
        36,
        47,
        45,
        49,
        49,
        0,
        0,
        45,
        0,
        24,
        13,
        13
      ],
      "weeklyHold": [
        49,
        49,
        18,
        1,
        0,
        20,
        49,
        2,
        29,
        49,
        25,
        37,
        48,
        1,
        11,
        49,
        4,
        27,
        32,
        16,
        49,
        21,
        0,
        21,
        0,
        1,
        49,
        0,
        28,
        25,
        48,
        26,
        13,
        0,
        29,
        14,
        29,
        34,
        9,
        41,
        27,
        27,
        0,
        39,
        26,
        21,
        49,
        25,
        4,
        21,
        42,
        15
      ],
      "weeklyBackordered": [
        13,
        0,
        0,
        38,
        35,
        46,
        49,
        31,
        49,
        10,
        49,
        34,
        7,
        24,
        42,
        40,
        16,
        39,
        0,
        1,
        28,
        0,
        0,
        37,
        28,
        18,
        6,
        2,
        38,
        41,
        12,
        49,
        28,
        49,
        19,
        26,
        28,
        0,
        0,
        13,
        2,
        26,
        9,
        49,
        19,
        24,
        27,
        35,
        35,
        26,
        1,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        29,
        21,
        31,
        36,
        21,
        0,
        2,
        45,
        43,
        21,
        24,
        1,
        45,
        24,
        18,
        27,
        49,
        0,
        0,
        41,
        15,
        0,
        49,
        26,
        17,
        32,
        18,
        7,
        10,
        9,
        21,
        35,
        31,
        0,
        47,
        49,
        36,
        5,
        5,
        26,
        33,
        0,
        3,
        46,
        47,
        6,
        0,
        49,
        25,
        49,
        20,
        48
      ],
      "weeklyRejected": [
        40,
        28,
        35,
        16,
        37,
        31,
        49,
        47,
        47,
        48,
        47,
        15,
        27,
        38,
        48,
        31,
        0,
        49,
        43,
        6,
        23,
        14,
        49,
        49,
        34,
        17,
        39,
        15,
        48,
        9,
        31,
        47,
        48,
        29,
        32,
        43,
        4,
        47,
        47,
        22,
        39,
        14,
        23,
        45,
        49,
        49,
        43,
        47,
        4,
        49,
        10,
        29
      ],
      "weeklyHold": [
        25,
        39,
        35,
        26,
        4,
        32,
        17,
        26,
        27,
        17,
        26,
        15,
        17,
        1,
        37,
        18,
        9,
        0,
        43,
        38,
        12,
        0,
        6,
        12,
        18,
        23,
        19,
        29,
        49,
        37,
        32,
        7,
        14,
        40,
        28,
        41,
        23,
        0,
        26,
        38,
        36,
        39,
        0,
        9,
        33,
        46,
        23,
        49,
        0,
        0,
        49,
        31
      ],
      "weeklyBackordered": [
        24,
        4,
        48,
        43,
        49,
        46,
        0,
        19,
        29,
        27,
        42,
        32,
        34,
        41,
        35,
        44,
        49,
        49,
        22,
        25,
        17,
        0,
        27,
        0,
        29,
        49,
        31,
        48,
        1,
        36,
        13,
        0,
        47,
        39,
        6,
        7,
        12,
        0,
        13,
        11,
        48,
        48,
        0,
        32,
        28,
        14,
        49,
        14,
        0,
        30,
        1,
        24
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        43,
        18,
        24,
        27,
        33,
        22,
        42,
        41,
        48,
        49,
        0,
        12,
        49,
        21,
        23,
        20,
        49,
        0,
        0,
        39,
        19,
        0,
        30,
        43,
        10,
        39,
        18,
        25,
        7,
        49,
        49,
        28,
        3,
        22,
        48,
        24,
        47,
        48,
        34,
        49,
        27,
        43,
        18,
        49,
        0,
        34,
        0,
        18,
        41,
        11,
        19,
        48
      ],
      "weeklyRejected": [
        49,
        19,
        38,
        0,
        7,
        49,
        40,
        23,
        16,
        30,
        0,
        0,
        23,
        48,
        48,
        0,
        0,
        20,
        49,
        0,
        29,
        47,
        22,
        49,
        34,
        0,
        30,
        18,
        23,
        0,
        9,
        35,
        41,
        23,
        18,
        33,
        45,
        31,
        1,
        45,
        34,
        0,
        49,
        0,
        45,
        39,
        0,
        33,
        24,
        25,
        0,
        29
      ],
      "weeklyHold": [
        36,
        46,
        49,
        1,
        19,
        13,
        4,
        2,
        12,
        34,
        47,
        36,
        28,
        49,
        43,
        15,
        49,
        20,
        16,
        37,
        0,
        10,
        32,
        31,
        30,
        44,
        16,
        0,
        49,
        0,
        35,
        18,
        29,
        47,
        45,
        49,
        49,
        41,
        15,
        13,
        7,
        49,
        0,
        9,
        29,
        0,
        43,
        14,
        7,
        0,
        40,
        32
      ],
      "weeklyBackordered": [
        38,
        49,
        0,
        34,
        48,
        46,
        20,
        21,
        40,
        6,
        49,
        16,
        3,
        39,
        3,
        47,
        27,
        49,
        0,
        3,
        36,
        23,
        8,
        26,
        1,
        49,
        49,
        0,
        21,
        49,
        33,
        44,
        24,
        27,
        0,
        10,
        16,
        5,
        34,
        4,
        34,
        18,
        0,
        49,
        18,
        22,
        15,
        4,
        9,
        15,
        42,
        20
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        49,
        39,
        22,
        36,
        32,
        48,
        1,
        49,
        22,
        1,
        48,
        0,
        9,
        38,
        21,
        49,
        49,
        30,
        8,
        23,
        36,
        29,
        23,
        46,
        38,
        25,
        0,
        4,
        1,
        46,
        35,
        36,
        49,
        8,
        48,
        42,
        39,
        48,
        49,
        49,
        8,
        9,
        36,
        14,
        40,
        0,
        4,
        27,
        0,
        48,
        28,
        20
      ],
      "weeklyRejected": [
        26,
        41,
        49,
        0,
        43,
        2,
        23,
        49,
        49,
        1,
        16,
        4,
        6,
        39,
        26,
        22,
        5,
        34,
        49,
        36,
        0,
        0,
        48,
        0,
        15,
        20,
        42,
        2,
        48,
        48,
        0,
        47,
        25,
        49,
        47,
        28,
        7,
        43,
        3,
        3,
        15,
        49,
        34,
        0,
        41,
        12,
        41,
        0,
        0,
        13,
        22,
        21
      ],
      "weeklyHold": [
        26,
        22,
        49,
        13,
        16,
        48,
        10,
        37,
        8,
        29,
        0,
        40,
        1,
        36,
        32,
        38,
        14,
        0,
        49,
        9,
        27,
        0,
        0,
        26,
        26,
        32,
        9,
        0,
        49,
        2,
        48,
        12,
        21,
        21,
        46,
        13,
        0,
        10,
        39,
        0,
        32,
        37,
        0,
        46,
        14,
        42,
        0,
        35,
        0,
        31,
        0,
        28
      ],
      "weeklyBackordered": [
        26,
        35,
        13,
        11,
        40,
        25,
        29,
        20,
        41,
        25,
        11,
        49,
        35,
        7,
        28,
        42,
        8,
        2,
        10,
        15,
        0,
        22,
        19,
        0,
        28,
        17,
        30,
        21,
        38,
        49,
        7,
        16,
        43,
        5,
        0,
        0,
        16,
        28,
        32,
        25,
        6,
        48,
        20,
        21,
        21,
        38,
        49,
        49,
        23,
        27,
        49,
        8
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        0,
        40,
        33,
        48,
        33,
        11,
        30,
        49,
        48,
        0,
        14,
        0,
        25,
        43,
        23,
        11,
        35,
        48,
        0,
        49,
        37,
        49,
        28,
        44,
        0,
        44,
        9,
        23,
        24,
        49,
        25,
        19,
        39,
        26,
        0,
        39,
        47,
        1,
        23,
        49,
        0,
        38,
        25,
        12,
        49,
        44,
        0,
        49,
        49,
        41,
        9,
        47
      ],
      "weeklyRejected": [
        46,
        15,
        32,
        24,
        49,
        20,
        0,
        30,
        27,
        18,
        49,
        41,
        25,
        48,
        21,
        38,
        43,
        49,
        28,
        46,
        24,
        34,
        14,
        23,
        33,
        49,
        42,
        2,
        45,
        10,
        44,
        39,
        48,
        49,
        36,
        29,
        12,
        11,
        1,
        3,
        48,
        7,
        34,
        22,
        49,
        41,
        43,
        11,
        0,
        45,
        30,
        47
      ],
      "weeklyHold": [
        49,
        0,
        17,
        1,
        49,
        24,
        49,
        4,
        25,
        49,
        37,
        38,
        30,
        49,
        49,
        15,
        4,
        45,
        12,
        27,
        19,
        27,
        49,
        24,
        20,
        38,
        0,
        46,
        41,
        32,
        22,
        0,
        13,
        0,
        19,
        15,
        24,
        0,
        49,
        41,
        27,
        49,
        28,
        20,
        13,
        41,
        49,
        22,
        6,
        28,
        29,
        28
      ],
      "weeklyBackordered": [
        23,
        20,
        22,
        0,
        22,
        46,
        49,
        49,
        3,
        0,
        48,
        33,
        18,
        17,
        17,
        49,
        49,
        49,
        0,
        17,
        49,
        26,
        22,
        10,
        12,
        29,
        49,
        0,
        17,
        49,
        41,
        44,
        30,
        33,
        8,
        0,
        30,
        0,
        24,
        49,
        17,
        48,
        0,
        39,
        9,
        47,
        49,
        37,
        14,
        49,
        30,
        42
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        17,
        31,
        37,
        0,
        49,
        0,
        34,
        24,
        41,
        19,
        42,
        10,
        0,
        49,
        14,
        27,
        20,
        0,
        26,
        0,
        37,
        37,
        49,
        27,
        49,
        21,
        0,
        33,
        1,
        1,
        9,
        10,
        49,
        12,
        29,
        47,
        0,
        21,
        47,
        49,
        15,
        23,
        49,
        38,
        15,
        0,
        14,
        5,
        18,
        49,
        27,
        7
      ],
      "weeklyRejected": [
        41,
        33,
        37,
        33,
        19,
        0,
        15,
        27,
        33,
        34,
        49,
        0,
        24,
        38,
        48,
        30,
        0,
        27,
        5,
        20,
        28,
        17,
        49,
        0,
        31,
        40,
        42,
        18,
        33,
        34,
        8,
        47,
        23,
        49,
        0,
        49,
        32,
        47,
        6,
        45,
        32,
        29,
        7,
        13,
        49,
        36,
        0,
        49,
        0,
        25,
        9,
        40
      ],
      "weeklyHold": [
        21,
        15,
        30,
        26,
        0,
        14,
        0,
        17,
        24,
        31,
        29,
        49,
        1,
        46,
        17,
        12,
        3,
        30,
        22,
        37,
        23,
        13,
        39,
        21,
        12,
        23,
        38,
        49,
        49,
        20,
        30,
        0,
        0,
        49,
        32,
        33,
        0,
        0,
        28,
        22,
        21,
        33,
        18,
        6,
        21,
        27,
        35,
        6,
        0,
        27,
        22,
        9
      ],
      "weeklyBackordered": [
        21,
        1,
        39,
        25,
        33,
        37,
        30,
        1,
        41,
        24,
        18,
        49,
        29,
        31,
        42,
        29,
        8,
        35,
        0,
        33,
        45,
        45,
        28,
        27,
        13,
        12,
        49,
        29,
        1,
        48,
        0,
        41,
        37,
        36,
        10,
        15,
        44,
        14,
        49,
        0,
        14,
        48,
        3,
        17,
        49,
        19,
        47,
        10,
        24,
        45,
        3,
        16
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        30,
        35,
        49,
        36,
        15,
        0,
        2,
        49,
        48,
        21,
        33,
        7,
        10,
        33,
        19,
        17,
        49,
        9,
        35,
        22,
        36,
        41,
        43,
        28,
        23,
        24,
        33,
        10,
        18,
        13,
        30,
        19,
        49,
        0,
        6,
        40,
        21,
        48,
        49,
        49,
        26,
        46,
        49,
        49,
        49,
        1,
        44,
        19,
        17,
        49,
        0,
        33
      ],
      "weeklyRejected": [
        39,
        10,
        32,
        47,
        26,
        10,
        27,
        17,
        49,
        30,
        39,
        19,
        38,
        48,
        21,
        34,
        19,
        44,
        49,
        20,
        12,
        42,
        49,
        49,
        32,
        35,
        46,
        34,
        37,
        29,
        37,
        30,
        21,
        49,
        34,
        49,
        49,
        49,
        1,
        49,
        42,
        3,
        49,
        0,
        49,
        8,
        22,
        0,
        3,
        36,
        23,
        32
      ],
      "weeklyHold": [
        31,
        24,
        0,
        27,
        49,
        0,
        29,
        40,
        0,
        15,
        36,
        32,
        39,
        41,
        11,
        11,
        23,
        9,
        0,
        1,
        0,
        39,
        0,
        49,
        21,
        25,
        8,
        9,
        49,
        22,
        4,
        0,
        0,
        28,
        0,
        37,
        0,
        16,
        29,
        29,
        27,
        39,
        0,
        38,
        29,
        23,
        49,
        29,
        0,
        49,
        49,
        34
      ],
      "weeklyBackordered": [
        15,
        44,
        31,
        39,
        48,
        33,
        27,
        37,
        41,
        5,
        39,
        49,
        35,
        0,
        31,
        40,
        47,
        23,
        9,
        5,
        30,
        16,
        22,
        9,
        23,
        31,
        18,
        0,
        22,
        49,
        2,
        49,
        44,
        21,
        16,
        0,
        49,
        1,
        27,
        35,
        12,
        39,
        8,
        37,
        49,
        49,
        49,
        34,
        26,
        39,
        31,
        16
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        15,
        37,
        30,
        46,
        27,
        0,
        16,
        20,
        42,
        49,
        49,
        0,
        39,
        23,
        10,
        35,
        27,
        0,
        7,
        49,
        13,
        12,
        41,
        40,
        19,
        24,
        12,
        46,
        30,
        49,
        15,
        0,
        33,
        28,
        14,
        42,
        18,
        25,
        17,
        26,
        18,
        7,
        35,
        45,
        49,
        6,
        26,
        34,
        33,
        43,
        5,
        12
      ],
      "weeklyRejected": [
        40,
        29,
        49,
        42,
        37,
        49,
        49,
        20,
        49,
        5,
        30,
        25,
        27,
        48,
        18,
        17,
        10,
        49,
        34,
        33,
        49,
        41,
        49,
        29,
        22,
        49,
        0,
        3,
        48,
        0,
        39,
        47,
        41,
        26,
        15,
        49,
        49,
        49,
        1,
        49,
        43,
        37,
        23,
        1,
        37,
        28,
        9,
        0,
        0,
        49,
        0,
        34
      ],
      "weeklyHold": [
        49,
        27,
        30,
        12,
        47,
        12,
        37,
        19,
        8,
        49,
        38,
        15,
        43,
        47,
        49,
        15,
        49,
        33,
        37,
        32,
        14,
        42,
        44,
        43,
        6,
        1,
        0,
        43,
        33,
        2,
        33,
        5,
        16,
        43,
        10,
        29,
        49,
        14,
        34,
        48,
        16,
        29,
        0,
        49,
        49,
        26,
        33,
        40,
        11,
        46,
        48,
        0
      ],
      "weeklyBackordered": [
        0,
        49,
        4,
        0,
        49,
        39,
        29,
        35,
        49,
        0,
        49,
        34,
        9,
        19,
        49,
        12,
        47,
        45,
        4,
        4,
        25,
        36,
        0,
        49,
        29,
        23,
        2,
        7,
        46,
        47,
        3,
        37,
        14,
        19,
        41,
        10,
        1,
        0,
        0,
        0,
        0,
        23,
        32,
        48,
        31,
        29,
        26,
        39,
        27,
        30,
        44,
        1
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        10,
        34,
        11,
        34,
        21,
        10,
        30,
        49,
        35,
        19,
        41,
        20,
        27,
        49,
        17,
        49,
        49,
        42,
        25,
        25,
        24,
        7,
        26,
        33,
        37,
        34,
        32,
        31,
        22,
        45,
        12,
        34,
        49,
        22,
        30,
        2,
        18,
        48,
        6,
        12,
        12,
        28,
        49,
        24,
        46,
        3,
        10,
        12,
        13,
        16,
        12,
        0
      ],
      "weeklyRejected": [
        27,
        16,
        43,
        26,
        0,
        8,
        16,
        49,
        49,
        36,
        18,
        22,
        28,
        0,
        48,
        10,
        42,
        47,
        11,
        49,
        3,
        49,
        16,
        28,
        49,
        19,
        49,
        37,
        36,
        0,
        49,
        26,
        6,
        11,
        42,
        13,
        20,
        23,
        32,
        49,
        48,
        44,
        32,
        19,
        49,
        7,
        5,
        14,
        14,
        49,
        12,
        49
      ],
      "weeklyHold": [
        17,
        8,
        49,
        28,
        5,
        28,
        37,
        25,
        11,
        19,
        45,
        42,
        38,
        13,
        49,
        40,
        0,
        32,
        28,
        7,
        11,
        23,
        49,
        0,
        45,
        30,
        8,
        0,
        49,
        8,
        34,
        48,
        0,
        5,
        24,
        35,
        14,
        0,
        25,
        14,
        35,
        49,
        13,
        40,
        37,
        49,
        5,
        49,
        29,
        19,
        24,
        49
      ],
      "weeklyBackordered": [
        18,
        18,
        48,
        47,
        39,
        16,
        0,
        49,
        37,
        49,
        8,
        23,
        6,
        25,
        24,
        29,
        49,
        23,
        8,
        0,
        17,
        29,
        41,
        18,
        9,
        20,
        1,
        45,
        19,
        37,
        36,
        3,
        41,
        49,
        15,
        0,
        1,
        2,
        25,
        2,
        48,
        27,
        17,
        48,
        11,
        20,
        49,
        30,
        26,
        39,
        11,
        25
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        33,
        47,
        16,
        18,
        40,
        17,
        28,
        45,
        0,
        9,
        47,
        17,
        48,
        22,
        22,
        41,
        28,
        14,
        30,
        46,
        41,
        37,
        0,
        47,
        43,
        23,
        28,
        49,
        30,
        49,
        49,
        0,
        7,
        49,
        48,
        29,
        27,
        39,
        18,
        26,
        36,
        11,
        29,
        21,
        49,
        0,
        0,
        44,
        20,
        29,
        9,
        3
      ],
      "weeklyRejected": [
        29,
        5,
        20,
        0,
        40,
        13,
        49,
        19,
        49,
        29,
        44,
        47,
        37,
        33,
        48,
        33,
        38,
        35,
        21,
        35,
        39,
        41,
        1,
        28,
        19,
        13,
        47,
        5,
        27,
        28,
        48,
        47,
        48,
        5,
        22,
        22,
        25,
        0,
        43,
        42,
        18,
        39,
        16,
        28,
        30,
        13,
        11,
        39,
        43,
        19,
        0,
        38
      ],
      "weeklyHold": [
        42,
        0,
        49,
        1,
        47,
        27,
        35,
        49,
        41,
        32,
        47,
        31,
        8,
        9,
        23,
        15,
        22,
        19,
        45,
        10,
        17,
        24,
        11,
        0,
        9,
        48,
        13,
        0,
        29,
        8,
        48,
        17,
        0,
        30,
        36,
        33,
        42,
        0,
        49,
        0,
        0,
        36,
        16,
        5,
        49,
        43,
        14,
        4,
        21,
        0,
        11,
        42
      ],
      "weeklyBackordered": [
        9,
        44,
        0,
        38,
        49,
        0,
        49,
        36,
        0,
        49,
        12,
        13,
        3,
        38,
        20,
        42,
        49,
        0,
        35,
        5,
        35,
        22,
        19,
        17,
        9,
        9,
        27,
        29,
        23,
        42,
        32,
        23,
        17,
        34,
        0,
        17,
        1,
        31,
        46,
        11,
        48,
        48,
        13,
        29,
        12,
        0,
        37,
        11,
        0,
        29,
        44,
        49
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        7,
        31,
        49,
        48,
        25,
        29,
        10,
        12,
        39,
        49,
        49,
        0,
        27,
        49,
        13,
        0,
        8,
        0,
        14,
        25,
        10,
        49,
        30,
        41,
        5,
        25,
        49,
        19,
        41,
        49,
        42,
        31,
        49,
        24,
        28,
        32,
        47,
        48,
        49,
        49,
        38,
        25,
        49,
        28,
        49,
        13,
        0,
        22,
        2,
        5,
        7,
        23
      ],
      "weeklyRejected": [
        40,
        0,
        45,
        3,
        39,
        0,
        11,
        18,
        32,
        35,
        31,
        25,
        12,
        48,
        41,
        13,
        30,
        36,
        18,
        7,
        11,
        40,
        29,
        48,
        15,
        0,
        42,
        33,
        32,
        3,
        30,
        24,
        24,
        27,
        49,
        48,
        38,
        36,
        1,
        49,
        21,
        41,
        44,
        21,
        36,
        12,
        0,
        0,
        3,
        46,
        19,
        49
      ],
      "weeklyHold": [
        19,
        23,
        42,
        1,
        35,
        29,
        16,
        49,
        1,
        0,
        47,
        41,
        47,
        29,
        37,
        35,
        38,
        31,
        23,
        0,
        32,
        47,
        39,
        11,
        35,
        40,
        0,
        29,
        49,
        26,
        48,
        26,
        20,
        35,
        43,
        49,
        16,
        0,
        49,
        42,
        32,
        49,
        49,
        42,
        1,
        42,
        24,
        3,
        0,
        19,
        27,
        45
      ],
      "weeklyBackordered": [
        46,
        11,
        0,
        41,
        25,
        46,
        49,
        1,
        38,
        8,
        44,
        28,
        3,
        49,
        29,
        35,
        25,
        6,
        22,
        43,
        19,
        32,
        29,
        20,
        12,
        7,
        1,
        6,
        48,
        29,
        29,
        18,
        0,
        32,
        9,
        7,
        31,
        0,
        13,
        30,
        21,
        48,
        15,
        49,
        0,
        23,
        26,
        18,
        0,
        38,
        35,
        39
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        28,
        45,
        40,
        4,
        2,
        16,
        4,
        49,
        48,
        37,
        46,
        39,
        0,
        15,
        16,
        32,
        41,
        40,
        20,
        42,
        2,
        39,
        33,
        19,
        28,
        6,
        32,
        0,
        11,
        49,
        14,
        0,
        49,
        8,
        30,
        18,
        10,
        23,
        49,
        41,
        11,
        21,
        41,
        49,
        42,
        44,
        39,
        0,
        26,
        0,
        2,
        33
      ],
      "weeklyRejected": [
        44,
        14,
        15,
        0,
        32,
        12,
        49,
        4,
        34,
        7,
        49,
        24,
        3,
        35,
        42,
        21,
        0,
        37,
        28,
        0,
        49,
        28,
        27,
        16,
        16,
        49,
        44,
        46,
        32,
        5,
        20,
        23,
        16,
        7,
        46,
        49,
        49,
        30,
        11,
        44,
        39,
        49,
        41,
        18,
        49,
        12,
        22,
        0,
        0,
        49,
        9,
        13
      ],
      "weeklyHold": [
        34,
        33,
        33,
        1,
        49,
        27,
        44,
        9,
        0,
        4,
        10,
        10,
        19,
        27,
        29,
        6,
        6,
        34,
        36,
        31,
        0,
        0,
        20,
        9,
        25,
        29,
        0,
        48,
        41,
        0,
        43,
        0,
        21,
        2,
        0,
        49,
        45,
        31,
        7,
        28,
        17,
        48,
        20,
        34,
        49,
        15,
        32,
        19,
        0,
        0,
        6,
        49
      ],
      "weeklyBackordered": [
        32,
        6,
        0,
        14,
        44,
        35,
        46,
        48,
        49,
        45,
        32,
        28,
        25,
        10,
        42,
        1,
        49,
        19,
        0,
        21,
        0,
        43,
        15,
        49,
        28,
        29,
        22,
        49,
        13,
        38,
        34,
        32,
        20,
        44,
        0,
        0,
        1,
        18,
        43,
        33,
        48,
        48,
        49,
        49,
        20,
        13,
        25,
        8,
        32,
        13,
        31,
        23
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        0,
        16,
        41,
        16,
        49,
        30,
        1,
        43,
        13,
        18,
        25,
        8,
        49,
        32,
        27,
        34,
        35,
        5,
        21,
        15,
        16,
        49,
        29,
        39,
        0,
        23,
        9,
        17,
        1,
        49,
        17,
        30,
        49,
        4,
        23,
        49,
        46,
        0,
        24,
        13,
        36,
        40,
        45,
        49,
        49,
        40,
        7,
        34,
        49,
        46,
        19,
        10
      ],
      "weeklyRejected": [
        46,
        22,
        49,
        5,
        30,
        19,
        34,
        24,
        45,
        48,
        29,
        37,
        27,
        48,
        34,
        39,
        23,
        36,
        24,
        0,
        0,
        19,
        19,
        48,
        49,
        49,
        49,
        32,
        24,
        0,
        27,
        0,
        45,
        3,
        30,
        24,
        49,
        18,
        6,
        19,
        34,
        49,
        4,
        42,
        35,
        31,
        27,
        49,
        16,
        49,
        0,
        49
      ],
      "weeklyHold": [
        28,
        49,
        28,
        31,
        3,
        15,
        2,
        10,
        49,
        23,
        40,
        8,
        18,
        1,
        27,
        27,
        10,
        12,
        21,
        0,
        39,
        33,
        28,
        13,
        49,
        19,
        28,
        19,
        49,
        16,
        48,
        12,
        0,
        49,
        26,
        17,
        15,
        0,
        49,
        37,
        32,
        28,
        38,
        49,
        49,
        20,
        37,
        19,
        0,
        33,
        30,
        25
      ],
      "weeklyBackordered": [
        45,
        0,
        0,
        23,
        43,
        46,
        24,
        41,
        32,
        24,
        15,
        9,
        3,
        32,
        36,
        1,
        22,
        49,
        5,
        4,
        0,
        0,
        44,
        12,
        48,
        3,
        29,
        7,
        41,
        16,
        0,
        42,
        41,
        27,
        49,
        1,
        14,
        10,
        22,
        16,
        16,
        43,
        0,
        49,
        0,
        2,
        49,
        43,
        0,
        12,
        49,
        22
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        44,
        49,
        14,
        33,
        1,
        0,
        6,
        36,
        45,
        49,
        24,
        2,
        49,
        43,
        1,
        21,
        39,
        0,
        16,
        20,
        13,
        49,
        49,
        24,
        21,
        0,
        41,
        36,
        2,
        5,
        13,
        15,
        47,
        0,
        31,
        28,
        22,
        8,
        12,
        49,
        6,
        33,
        42,
        32,
        49,
        11,
        0,
        0,
        26,
        36,
        10,
        14
      ],
      "weeklyRejected": [
        49,
        25,
        49,
        45,
        25,
        40,
        46,
        31,
        38,
        5,
        33,
        49,
        22,
        33,
        47,
        0,
        33,
        49,
        11,
        0,
        4,
        40,
        9,
        48,
        27,
        27,
        17,
        21,
        42,
        33,
        10,
        21,
        24,
        37,
        0,
        33,
        49,
        32,
        13,
        7,
        48,
        8,
        22,
        25,
        33,
        17,
        0,
        25,
        11,
        49,
        17,
        49
      ],
      "weeklyHold": [
        28,
        25,
        20,
        8,
        28,
        22,
        0,
        18,
        0,
        27,
        47,
        33,
        48,
        2,
        24,
        38,
        35,
        48,
        28,
        46,
        9,
        0,
        49,
        8,
        28,
        48,
        42,
        0,
        29,
        0,
        40,
        0,
        47,
        37,
        48,
        16,
        38,
        21,
        43,
        14,
        38,
        19,
        13,
        27,
        34,
        40,
        0,
        26,
        21,
        17,
        49,
        14
      ],
      "weeklyBackordered": [
        25,
        1,
        5,
        21,
        41,
        38,
        17,
        37,
        20,
        12,
        49,
        38,
        33,
        45,
        34,
        20,
        38,
        49,
        8,
        49,
        39,
        33,
        19,
        17,
        29,
        19,
        19,
        22,
        21,
        32,
        13,
        49,
        37,
        16,
        0,
        0,
        34,
        19,
        32,
        13,
        0,
        25,
        12,
        42,
        14,
        47,
        45,
        19,
        30,
        3,
        21,
        1
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyAccepted": [
        20,
        20,
        25,
        37,
        35,
        13,
        39,
        33,
        48,
        19,
        31,
        10,
        49,
        29,
        12,
        21,
        40,
        27,
        0,
        31,
        0,
        43,
        18,
        49,
        0,
        34,
        31,
        29,
        1,
        27,
        17,
        0,
        22,
        2,
        34,
        25,
        0,
        43,
        17,
        24,
        14,
        49,
        48,
        15,
        23,
        0,
        14,
        25,
        13,
        49,
        3,
        16
      ],
      "weeklyRejected": [
        37,
        49,
        29,
        25,
        25,
        0,
        7,
        22,
        31,
        1,
        29,
        0,
        43,
        48,
        27,
        30,
        26,
        49,
        0,
        35,
        3,
        31,
        31,
        36,
        37,
        49,
        49,
        35,
        43,
        1,
        12,
        42,
        16,
        27,
        13,
        49,
        0,
        7,
        15,
        0,
        21,
        36,
        0,
        49,
        34,
        23,
        11,
        25,
        4,
        49,
        3,
        49
      ],
      "weeklyHold": [
        25,
        48,
        49,
        23,
        3,
        19,
        0,
        2,
        0,
        0,
        36,
        28,
        20,
        33,
        6,
        0,
        19,
        17,
        8,
        7,
        31,
        34,
        37,
        0,
        14,
        30,
        22,
        46,
        35,
        30,
        30,
        0,
        0,
        0,
        0,
        49,
        11,
        11,
        28,
        46,
        35,
        36,
        2,
        29,
        43,
        46,
        35,
        24,
        10,
        5,
        47,
        45
      ],
      "weeklyBackordered": [
        20,
        19,
        18,
        49,
        36,
        37,
        33,
        24,
        0,
        0,
        27,
        49,
        27,
        7,
        49,
        22,
        12,
        25,
        25,
        0,
        13,
        49,
        19,
        0,
        12,
        1,
        16,
        3,
        38,
        24,
        24,
        27,
        40,
        43,
        32,
        12,
        27,
        0,
        38,
        25,
        0,
        28,
        11,
        49,
        1,
        34,
        15,
        22,
        40,
        24,
        21,
        43
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        33,
        25,
        21,
        1,
        2,
        0,
        1,
        3,
        29,
        30,
        36,
        7,
        0,
        33,
        32,
        48,
        9,
        0,
        8,
        5,
        33,
        46,
        33,
        9,
        41,
        44,
        12,
        32,
        30,
        1,
        19,
        29,
        41,
        0,
        48,
        21,
        6,
        20,
        30,
        49,
        20,
        28,
        49,
        49,
        36,
        34,
        12,
        49,
        18,
        17,
        41,
        3
      ],
      "weeklyRejected": [
        49,
        49,
        20,
        47,
        20,
        27,
        49,
        49,
        29,
        1,
        40,
        21,
        26,
        48,
        37,
        26,
        41,
        44,
        30,
        36,
        49,
        41,
        43,
        5,
        13,
        49,
        36,
        29,
        48,
        41,
        10,
        41,
        41,
        5,
        18,
        47,
        13,
        17,
        41,
        48,
        28,
        49,
        11,
        44,
        40,
        36,
        0,
        10,
        7,
        49,
        9,
        32
      ],
      "weeklyHold": [
        0,
        5,
        49,
        30,
        41,
        31,
        0,
        24,
        17,
        37,
        1,
        49,
        1,
        22,
        9,
        10,
        33,
        0,
        9,
        6,
        9,
        36,
        12,
        40,
        40,
        18,
        21,
        19,
        38,
        12,
        48,
        4,
        0,
        42,
        2,
        4,
        18,
        0,
        25,
        34,
        29,
        49,
        49,
        3,
        49,
        45,
        0,
        47,
        11,
        49,
        8,
        27
      ],
      "weeklyBackordered": [
        14,
        29,
        0,
        10,
        44,
        14,
        0,
        49,
        23,
        31,
        26,
        25,
        28,
        18,
        21,
        1,
        44,
        47,
        0,
        36,
        14,
        16,
        10,
        11,
        39,
        25,
        3,
        20,
        44,
        0,
        30,
        23,
        40,
        49,
        0,
        0,
        22,
        13,
        12,
        44,
        29,
        14,
        14,
        0,
        48,
        23,
        45,
        26,
        0,
        49,
        28,
        20
      ]
    },
    {
      "id": "8164C892",
      "weeklyAccepted": [
        15,
        42,
        19,
        25,
        24,
        42,
        29,
        26,
        41,
        1,
        27,
        8,
        0,
        23,
        16,
        0,
        48,
        14,
        7,
        11,
        9,
        0,
        19,
        49,
        10,
        40,
        30,
        23,
        49,
        35,
        43,
        14,
        40,
        4,
        35,
        18,
        44,
        19,
        29,
        45,
        3,
        49,
        11,
        49,
        47,
        47,
        0,
        49,
        9,
        49,
        25,
        21
      ],
      "weeklyRejected": [
        49,
        10,
        32,
        16,
        33,
        15,
        21,
        49,
        27,
        27,
        23,
        20,
        35,
        34,
        48,
        14,
        6,
        36,
        17,
        49,
        0,
        48,
        0,
        10,
        4,
        40,
        0,
        29,
        10,
        27,
        0,
        28,
        48,
        49,
        1,
        20,
        17,
        6,
        9,
        15,
        34,
        49,
        8,
        0,
        18,
        0,
        24,
        12,
        31,
        38,
        12,
        0
      ],
      "weeklyHold": [
        49,
        49,
        40,
        1,
        36,
        9,
        43,
        2,
        22,
        33,
        36,
        36,
        39,
        31,
        41,
        17,
        6,
        15,
        1,
        21,
        46,
        14,
        6,
        10,
        42,
        1,
        11,
        8,
        45,
        5,
        48,
        15,
        16,
        37,
        9,
        7,
        20,
        36,
        49,
        39,
        20,
        27,
        7,
        7,
        17,
        43,
        49,
        49,
        0,
        19,
        42,
        28
      ],
      "weeklyBackordered": [
        15,
        28,
        28,
        27,
        45,
        34,
        40,
        49,
        22,
        1,
        0,
        46,
        22,
        20,
        47,
        47,
        30,
        44,
        0,
        12,
        41,
        7,
        0,
        2,
        1,
        25,
        17,
        0,
        48,
        49,
        35,
        27,
        0,
        43,
        0,
        0,
        1,
        46,
        0,
        3,
        28,
        31,
        0,
        25,
        0,
        43,
        31,
        25,
        0,
        44,
        9,
        5
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        33,
        18,
        45,
        0,
        37,
        6,
        11,
        8,
        42,
        14,
        28,
        0,
        14,
        49,
        31,
        46,
        49,
        7,
        0,
        6,
        48,
        33,
        2,
        25,
        30,
        41,
        0,
        22,
        29,
        49,
        49,
        2,
        49,
        0,
        42,
        2,
        38,
        48,
        49,
        21,
        36,
        33,
        40,
        5,
        49,
        37,
        5,
        10,
        0,
        49,
        9,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        31,
        29,
        34,
        0,
        49,
        12,
        32,
        16,
        49,
        37,
        19,
        17,
        2,
        16,
        0,
        32,
        49,
        48,
        49,
        11,
        11,
        3,
        29,
        18,
        39,
        49,
        0,
        38,
        43,
        47,
        40,
        20,
        31,
        49,
        49,
        38,
        4,
        6,
        47,
        22,
        20,
        0,
        48,
        6,
        19,
        16,
        0,
        45,
        0,
        25
      ],
      "weeklyHold": [
        1,
        37,
        26,
        18,
        49,
        34,
        24,
        2,
        42,
        13,
        34,
        12,
        45,
        49,
        0,
        17,
        49,
        0,
        49,
        0,
        23,
        49,
        32,
        22,
        43,
        40,
        20,
        9,
        44,
        25,
        41,
        5,
        0,
        40,
        14,
        11,
        28,
        20,
        33,
        27,
        0,
        31,
        48,
        44,
        0,
        49,
        22,
        0,
        29,
        33,
        0,
        0
      ],
      "weeklyBackordered": [
        34,
        28,
        40,
        28,
        49,
        46,
        49,
        43,
        12,
        0,
        2,
        47,
        23,
        0,
        29,
        29,
        15,
        24,
        25,
        11,
        49,
        49,
        11,
        49,
        33,
        1,
        49,
        3,
        27,
        49,
        49,
        0,
        2,
        49,
        16,
        0,
        32,
        15,
        43,
        13,
        48,
        48,
        0,
        49,
        0,
        42,
        42,
        0,
        31,
        23,
        49,
        21
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        49,
        20,
        49,
        39,
        1,
        9,
        9,
        37,
        33,
        0,
        36,
        20,
        45,
        11,
        1,
        49,
        28,
        8,
        42,
        23,
        49,
        33,
        25,
        13,
        46,
        45,
        26,
        0,
        13,
        22,
        49,
        32,
        36,
        0,
        48,
        26,
        47,
        48,
        20,
        33,
        0,
        22,
        46,
        20,
        5,
        0,
        7,
        14,
        48,
        43,
        0,
        9
      ],
      "weeklyRejected": [
        49,
        14,
        2,
        28,
        19,
        0,
        0,
        16,
        49,
        22,
        49,
        44,
        0,
        48,
        30,
        23,
        0,
        47,
        36,
        6,
        29,
        6,
        12,
        37,
        30,
        6,
        49,
        0,
        48,
        48,
        29,
        14,
        0,
        34,
        34,
        49,
        49,
        25,
        11,
        18,
        29,
        33,
        29,
        0,
        49,
        18,
        0,
        11,
        22,
        48,
        0,
        49
      ],
      "weeklyHold": [
        0,
        45,
        37,
        17,
        21,
        46,
        0,
        29,
        11,
        16,
        47,
        1,
        43,
        28,
        0,
        0,
        0,
        14,
        0,
        49,
        0,
        32,
        20,
        49,
        31,
        11,
        22,
        1,
        49,
        0,
        48,
        0,
        0,
        48,
        48,
        35,
        0,
        8,
        49,
        9,
        42,
        26,
        17,
        31,
        49,
        18,
        0,
        43,
        0,
        37,
        0,
        46
      ],
      "weeklyBackordered": [
        33,
        5,
        0,
        0,
        43,
        29,
        1,
        15,
        34,
        12,
        15,
        14,
        40,
        17,
        0,
        21,
        49,
        49,
        0,
        49,
        41,
        37,
        49,
        47,
        33,
        17,
        40,
        39,
        13,
        42,
        0,
        49,
        29,
        0,
        42,
        0,
        27,
        1,
        18,
        6,
        12,
        17,
        29,
        30,
        38,
        38,
        40,
        10,
        17,
        15,
        5,
        45
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        16,
        30,
        34,
        31,
        49,
        0,
        20,
        49,
        26,
        0,
        48,
        0,
        45,
        14,
        14,
        49,
        43,
        16,
        0,
        38,
        37,
        12,
        42,
        49,
        40,
        40,
        9,
        17,
        1,
        49,
        0,
        26,
        49,
        10,
        41,
        38,
        22,
        0,
        23,
        30,
        11,
        0,
        47,
        10,
        49,
        0,
        0,
        19,
        12,
        34,
        14,
        0
      ],
      "weeklyRejected": [
        29,
        0,
        45,
        33,
        0,
        0,
        27,
        43,
        46,
        10,
        48,
        49,
        36,
        23,
        41,
        49,
        0,
        16,
        29,
        0,
        20,
        44,
        49,
        26,
        42,
        44,
        49,
        0,
        0,
        30,
        19,
        30,
        6,
        22,
        31,
        46,
        16,
        27,
        1,
        25,
        13,
        16,
        26,
        0,
        4,
        34,
        14,
        49,
        7,
        26,
        20,
        6
      ],
      "weeklyHold": [
        25,
        38,
        49,
        1,
        30,
        17,
        26,
        18,
        0,
        28,
        7,
        27,
        1,
        38,
        49,
        0,
        0,
        48,
        49,
        47,
        8,
        42,
        26,
        13,
        13,
        11,
        0,
        17,
        49,
        12,
        48,
        2,
        16,
        32,
        34,
        28,
        2,
        15,
        38,
        30,
        15,
        24,
        34,
        49,
        15,
        19,
        0,
        11,
        3,
        22,
        46,
        17
      ],
      "weeklyBackordered": [
        0,
        29,
        19,
        27,
        40,
        38,
        41,
        24,
        11,
        0,
        13,
        45,
        22,
        49,
        33,
        25,
        26,
        14,
        49,
        21,
        39,
        23,
        17,
        29,
        15,
        1,
        37,
        3,
        15,
        40,
        29,
        14,
        48,
        8,
        0,
        14,
        21,
        0,
        49,
        20,
        24,
        48,
        17,
        43,
        35,
        5,
        37,
        30,
        29,
        25,
        35,
        33
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        17,
        48,
        49,
        17,
        16,
        0,
        40,
        39,
        48,
        1,
        46,
        28,
        47,
        9,
        23,
        27,
        33,
        48,
        3,
        20,
        16,
        42,
        5,
        45,
        49,
        43,
        6,
        8,
        30,
        49,
        30,
        19,
        49,
        22,
        48,
        49,
        35,
        14,
        14,
        43,
        13,
        49,
        48,
        23,
        46,
        18,
        21,
        11,
        4,
        21,
        47,
        23
      ],
      "weeklyRejected": [
        40,
        33,
        12,
        46,
        46,
        12,
        2,
        13,
        26,
        6,
        2,
        25,
        38,
        23,
        48,
        30,
        49,
        48,
        36,
        32,
        10,
        49,
        43,
        26,
        29,
        49,
        49,
        48,
        8,
        48,
        44,
        7,
        36,
        5,
        36,
        30,
        33,
        41,
        1,
        47,
        36,
        25,
        15,
        0,
        48,
        10,
        0,
        17,
        27,
        12,
        10,
        47
      ],
      "weeklyHold": [
        41,
        0,
        37,
        1,
        44,
        48,
        17,
        2,
        1,
        49,
        36,
        39,
        1,
        30,
        33,
        40,
        8,
        37,
        45,
        27,
        18,
        49,
        43,
        32,
        46,
        48,
        48,
        0,
        23,
        15,
        48,
        0,
        18,
        0,
        30,
        11,
        39,
        12,
        39,
        47,
        31,
        28,
        1,
        0,
        49,
        43,
        10,
        49,
        7,
        11,
        26,
        49
      ],
      "weeklyBackordered": [
        0,
        47,
        11,
        27,
        25,
        32,
        49,
        49,
        40,
        45,
        17,
        7,
        12,
        34,
        10,
        16,
        49,
        49,
        0,
        4,
        15,
        35,
        0,
        28,
        14,
        1,
        13,
        8,
        7,
        49,
        28,
        10,
        26,
        49,
        8,
        23,
        1,
        42,
        39,
        32,
        22,
        47,
        38,
        48,
        16,
        28,
        49,
        43,
        3,
        46,
        45,
        18
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyAccepted": [
        43,
        15,
        7,
        15,
        33,
        36,
        5,
        24,
        24,
        35,
        32,
        29,
        2,
        25,
        38,
        0,
        14,
        29,
        12,
        38,
        1,
        49,
        28,
        32,
        23,
        43,
        9,
        49,
        3,
        13,
        23,
        21,
        49,
        0,
        25,
        49,
        0,
        15,
        28,
        25,
        0,
        14,
        49,
        40,
        37,
        0,
        8,
        11,
        20,
        49,
        18,
        3
      ],
      "weeklyRejected": [
        23,
        20,
        49,
        11,
        26,
        25,
        28,
        18,
        14,
        42,
        49,
        27,
        16,
        30,
        17,
        29,
        49,
        47,
        24,
        38,
        0,
        1,
        49,
        28,
        19,
        34,
        42,
        26,
        27,
        21,
        18,
        38,
        36,
        10,
        24,
        49,
        49,
        34,
        1,
        0,
        0,
        25,
        19,
        21,
        45,
        27,
        0,
        0,
        17,
        35,
        0,
        49
      ],
      "weeklyHold": [
        9,
        3,
        31,
        16,
        40,
        40,
        7,
        29,
        33,
        8,
        40,
        1,
        43,
        14,
        41,
        0,
        5,
        14,
        3,
        37,
        43,
        38,
        34,
        0,
        1,
        11,
        36,
        47,
        28,
        33,
        44,
        18,
        0,
        26,
        37,
        30,
        0,
        0,
        19,
        42,
        46,
        22,
        9,
        13,
        49,
        39,
        38,
        49,
        19,
        49,
        13,
        37
      ],
      "weeklyBackordered": [
        26,
        1,
        17,
        39,
        49,
        18,
        0,
        4,
        20,
        26,
        45,
        47,
        34,
        28,
        14,
        24,
        49,
        42,
        36,
        24,
        46,
        2,
        28,
        41,
        34,
        13,
        1,
        27,
        14,
        34,
        0,
        33,
        43,
        29,
        40,
        25,
        31,
        0,
        3,
        38,
        3,
        24,
        2,
        27,
        0,
        17,
        21,
        28,
        40,
        11,
        43,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        29,
        26,
        22,
        0,
        17,
        0,
        1,
        26,
        48,
        34,
        44,
        0,
        16,
        34,
        19,
        45,
        42,
        0,
        0,
        49,
        45,
        45,
        49,
        25,
        0,
        24,
        10,
        0,
        44,
        26,
        12,
        31,
        49,
        0,
        19,
        29,
        47,
        19,
        12,
        33,
        14,
        21,
        36,
        38,
        47,
        49,
        0,
        23,
        23,
        40,
        19,
        47
      ],
      "weeklyRejected": [
        34,
        48,
        30,
        12,
        5,
        42,
        44,
        24,
        0,
        1,
        40,
        31,
        14,
        48,
        47,
        17,
        35,
        49,
        46,
        0,
        29,
        37,
        49,
        46,
        34,
        44,
        46,
        26,
        48,
        11,
        16,
        16,
        0,
        20,
        5,
        41,
        24,
        29,
        23,
        36,
        37,
        35,
        31,
        10,
        49,
        27,
        10,
        22,
        0,
        0,
        0,
        35
      ],
      "weeklyHold": [
        9,
        49,
        49,
        16,
        0,
        47,
        49,
        36,
        19,
        1,
        18,
        45,
        15,
        21,
        24,
        8,
        31,
        12,
        28,
        49,
        26,
        17,
        32,
        9,
        40,
        8,
        49,
        0,
        29,
        7,
        18,
        21,
        40,
        26,
        3,
        34,
        32,
        2,
        39,
        28,
        16,
        36,
        22,
        18,
        31,
        37,
        49,
        49,
        6,
        16,
        27,
        24
      ],
      "weeklyBackordered": [
        41,
        16,
        14,
        39,
        49,
        30,
        36,
        1,
        44,
        37,
        24,
        2,
        3,
        30,
        0,
        22,
        36,
        42,
        36,
        16,
        27,
        36,
        2,
        4,
        38,
        4,
        8,
        49,
        17,
        49,
        0,
        49,
        24,
        35,
        8,
        19,
        1,
        2,
        49,
        32,
        22,
        48,
        13,
        49,
        12,
        49,
        49,
        49,
        39,
        31,
        31,
        3
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        28,
        10,
        33,
        36,
        22,
        17,
        27,
        21,
        36,
        30,
        49,
        2,
        49,
        45,
        15,
        42,
        41,
        14,
        0,
        15,
        49,
        19,
        35,
        23,
        25,
        20,
        10,
        49,
        39,
        40,
        9,
        0,
        30,
        23,
        26,
        26,
        47,
        26,
        49,
        13,
        41,
        23,
        40,
        25,
        49,
        7,
        0,
        49,
        36,
        23,
        5,
        7
      ],
      "weeklyRejected": [
        27,
        6,
        27,
        12,
        36,
        9,
        24,
        32,
        27,
        28,
        49,
        7,
        42,
        29,
        27,
        35,
        0,
        49,
        49,
        48,
        34,
        32,
        9,
        24,
        18,
        49,
        34,
        12,
        25,
        18,
        35,
        26,
        45,
        7,
        37,
        31,
        24,
        0,
        21,
        47,
        43,
        19,
        36,
        24,
        41,
        37,
        0,
        29,
        0,
        25,
        18,
        49
      ],
      "weeklyHold": [
        49,
        43,
        14,
        18,
        0,
        44,
        48,
        32,
        0,
        17,
        14,
        45,
        48,
        17,
        49,
        26,
        5,
        0,
        9,
        49,
        49,
        45,
        38,
        22,
        38,
        1,
        48,
        23,
        49,
        21,
        48,
        28,
        32,
        36,
        10,
        17,
        11,
        5,
        26,
        49,
        41,
        26,
        0,
        33,
        23,
        43,
        12,
        39,
        21,
        33,
        24,
        5
      ],
      "weeklyBackordered": [
        49,
        49,
        48,
        38,
        36,
        16,
        21,
        17,
        0,
        0,
        14,
        49,
        26,
        39,
        17,
        39,
        49,
        39,
        24,
        49,
        49,
        17,
        49,
        6,
        1,
        15,
        1,
        24,
        49,
        49,
        0,
        0,
        30,
        43,
        17,
        14,
        30,
        0,
        14,
        11,
        23,
        48,
        1,
        22,
        37,
        49,
        49,
        40,
        47,
        49,
        1,
        19
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        38,
        28,
        28,
        48,
        1,
        12,
        6,
        11,
        15,
        49,
        16,
        0,
        42,
        31,
        1,
        14,
        48,
        0,
        14,
        5,
        46,
        8,
        49,
        49,
        11,
        34,
        28,
        25,
        14,
        22,
        19,
        14,
        12,
        0,
        48,
        46,
        47,
        42,
        28,
        26,
        0,
        4,
        49,
        49,
        49,
        48,
        0,
        41,
        45,
        49,
        0,
        0
      ],
      "weeklyRejected": [
        42,
        49,
        36,
        26,
        20,
        0,
        31,
        10,
        37,
        1,
        49,
        26,
        34,
        48,
        48,
        9,
        1,
        44,
        23,
        20,
        29,
        15,
        6,
        32,
        29,
        34,
        15,
        30,
        48,
        18,
        3,
        23,
        48,
        49,
        20,
        37,
        20,
        0,
        3,
        37,
        36,
        25,
        49,
        7,
        49,
        2,
        2,
        4,
        0,
        49,
        24,
        22
      ],
      "weeklyHold": [
        42,
        49,
        18,
        1,
        28,
        30,
        0,
        6,
        30,
        49,
        25,
        49,
        11,
        49,
        49,
        37,
        44,
        38,
        43,
        19,
        28,
        21,
        10,
        4,
        0,
        48,
        19,
        3,
        49,
        24,
        48,
        24,
        0,
        44,
        12,
        10,
        5,
        0,
        44,
        8,
        8,
        28,
        0,
        0,
        21,
        25,
        23,
        11,
        20,
        15,
        49,
        15
      ],
      "weeklyBackordered": [
        45,
        38,
        0,
        27,
        49,
        46,
        4,
        40,
        43,
        0,
        26,
        0,
        16,
        0,
        26,
        1,
        49,
        0,
        0,
        39,
        49,
        46,
        39,
        25,
        33,
        24,
        1,
        4,
        31,
        49,
        25,
        35,
        33,
        29,
        0,
        0,
        49,
        10,
        32,
        0,
        29,
        48,
        14,
        49,
        11,
        24,
        49,
        18,
        0,
        46,
        26,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        29,
        48,
        10,
        48,
        19,
        0,
        18,
        40,
        42,
        41,
        42,
        31,
        6,
        0,
        1,
        9,
        11,
        9,
        38,
        19,
        49,
        12,
        34,
        41,
        16,
        24,
        37,
        18,
        17,
        37,
        47,
        31,
        49,
        8,
        48,
        28,
        34,
        47,
        0,
        49,
        49,
        37,
        49,
        0,
        49,
        15,
        24,
        34,
        6,
        25,
        23,
        30
      ],
      "weeklyRejected": [
        41,
        11,
        49,
        39,
        26,
        27,
        0,
        49,
        21,
        27,
        13,
        43,
        16,
        25,
        48,
        12,
        23,
        49,
        38,
        49,
        37,
        42,
        37,
        27,
        38,
        0,
        26,
        34,
        48,
        22,
        43,
        1,
        48,
        30,
        0,
        49,
        49,
        0,
        19,
        45,
        19,
        34,
        18,
        0,
        49,
        0,
        13,
        30,
        14,
        32,
        15,
        45
      ],
      "weeklyHold": [
        27,
        9,
        49,
        1,
        7,
        29,
        28,
        2,
        15,
        49,
        17,
        14,
        22,
        26,
        24,
        48,
        11,
        41,
        5,
        27,
        0,
        26,
        33,
        40,
        18,
        24,
        29,
        0,
        49,
        0,
        17,
        37,
        0,
        37,
        48,
        40,
        49,
        4,
        38,
        0,
        38,
        18,
        29,
        49,
        30,
        35,
        0,
        47,
        0,
        44,
        37,
        49
      ],
      "weeklyBackordered": [
        16,
        1,
        20,
        31,
        49,
        1,
        33,
        23,
        32,
        25,
        43,
        30,
        21,
        11,
        19,
        32,
        39,
        40,
        1,
        17,
        7,
        28,
        28,
        46,
        16,
        13,
        3,
        40,
        48,
        43,
        14,
        42,
        45,
        29,
        11,
        0,
        1,
        0,
        10,
        39,
        0,
        48,
        16,
        46,
        26,
        22,
        47,
        49,
        49,
        49,
        15,
        18
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        0,
        49,
        49,
        36,
        22,
        8,
        7,
        33,
        6,
        49,
        27,
        0,
        21,
        31,
        31,
        0,
        49,
        10,
        13,
        17,
        42,
        46,
        45,
        37,
        17,
        47,
        10,
        10,
        11,
        49,
        0,
        4,
        49,
        0,
        4,
        46,
        30,
        46,
        49,
        30,
        6,
        21,
        48,
        22,
        49,
        27,
        28,
        0,
        37,
        21,
        12,
        48
      ],
      "weeklyRejected": [
        49,
        27,
        20,
        10,
        49,
        0,
        31,
        30,
        19,
        18,
        43,
        43,
        21,
        26,
        2,
        30,
        4,
        49,
        30,
        0,
        11,
        30,
        9,
        31,
        33,
        46,
        13,
        49,
        41,
        15,
        10,
        25,
        8,
        30,
        17,
        16,
        42,
        13,
        5,
        27,
        46,
        47,
        49,
        22,
        49,
        12,
        0,
        30,
        21,
        30,
        0,
        16
      ],
      "weeklyHold": [
        20,
        38,
        9,
        1,
        49,
        41,
        18,
        11,
        0,
        30,
        0,
        40,
        45,
        49,
        17,
        22,
        26,
        30,
        0,
        13,
        1,
        36,
        7,
        19,
        42,
        48,
        0,
        9,
        19,
        0,
        47,
        0,
        4,
        30,
        26,
        48,
        37,
        25,
        49,
        0,
        28,
        48,
        6,
        34,
        34,
        28,
        29,
        8,
        0,
        44,
        49,
        4
      ],
      "weeklyBackordered": [
        0,
        24,
        13,
        0,
        37,
        36,
        8,
        19,
        49,
        28,
        17,
        34,
        17,
        33,
        29,
        36,
        24,
        21,
        24,
        12,
        27,
        30,
        27,
        28,
        40,
        1,
        17,
        39,
        7,
        49,
        0,
        40,
        13,
        41,
        2,
        0,
        14,
        15,
        34,
        43,
        9,
        48,
        18,
        49,
        9,
        49,
        22,
        18,
        0,
        25,
        20,
        39
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyAccepted": [
        30,
        17,
        49,
        26,
        30,
        5,
        22,
        39,
        7,
        1,
        46,
        0,
        37,
        20,
        24,
        20,
        49,
        14,
        1,
        4,
        1,
        45,
        49,
        29,
        7,
        45,
        22,
        37,
        14,
        29,
        0,
        0,
        40,
        12,
        17,
        22,
        44,
        30,
        47,
        49,
        0,
        33,
        49,
        10,
        27,
        0,
        11,
        19,
        7,
        21,
        30,
        48
      ],
      "weeklyRejected": [
        49,
        30,
        14,
        13,
        0,
        14,
        25,
        21,
        45,
        34,
        44,
        49,
        29,
        42,
        18,
        11,
        44,
        26,
        49,
        49,
        0,
        22,
        21,
        39,
        35,
        37,
        39,
        5,
        47,
        32,
        49,
        24,
        31,
        49,
        30,
        49,
        13,
        0,
        1,
        49,
        48,
        49,
        11,
        45,
        43,
        32,
        3,
        38,
        0,
        14,
        10,
        21
      ],
      "weeklyHold": [
        46,
        2,
        49,
        19,
        30,
        10,
        8,
        49,
        7,
        25,
        10,
        36,
        1,
        40,
        16,
        12,
        2,
        7,
        10,
        27,
        0,
        10,
        32,
        6,
        0,
        24,
        35,
        28,
        29,
        11,
        48,
        42,
        11,
        34,
        42,
        37,
        42,
        0,
        17,
        6,
        9,
        49,
        47,
        0,
        49,
        8,
        49,
        49,
        16,
        11,
        26,
        0
      ],
      "weeklyBackordered": [
        3,
        18,
        22,
        49,
        49,
        29,
        27,
        24,
        0,
        26,
        34,
        5,
        14,
        37,
        0,
        29,
        49,
        0,
        33,
        0,
        0,
        12,
        3,
        20,
        10,
        22,
        3,
        49,
        42,
        49,
        49,
        11,
        48,
        9,
        15,
        20,
        5,
        34,
        38,
        32,
        29,
        42,
        0,
        34,
        0,
        49,
        49,
        28,
        13,
        25,
        49,
        22
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        0,
        23,
        31,
        37,
        2,
        0,
        6,
        13,
        48,
        27,
        49,
        0,
        0,
        41,
        22,
        25,
        32,
        0,
        32,
        17,
        6,
        46,
        49,
        0,
        2,
        44,
        49,
        18,
        25,
        16,
        0,
        16,
        48,
        12,
        32,
        17,
        13,
        17,
        1,
        49,
        49,
        19,
        49,
        49,
        33,
        0,
        29,
        49,
        49,
        49,
        36,
        28
      ],
      "weeklyRejected": [
        47,
        0,
        26,
        47,
        40,
        25,
        34,
        12,
        49,
        48,
        0,
        7,
        19,
        45,
        35,
        49,
        27,
        22,
        49,
        10,
        40,
        9,
        23,
        23,
        23,
        49,
        0,
        49,
        44,
        46,
        46,
        47,
        48,
        37,
        35,
        48,
        49,
        47,
        15,
        23,
        48,
        49,
        13,
        14,
        49,
        46,
        0,
        49,
        3,
        43,
        0,
        0
      ],
      "weeklyHold": [
        0,
        32,
        10,
        33,
        49,
        38,
        40,
        23,
        16,
        23,
        2,
        8,
        48,
        25,
        29,
        32,
        0,
        29,
        0,
        2,
        22,
        30,
        8,
        49,
        0,
        48,
        26,
        20,
        38,
        0,
        41,
        0,
        2,
        25,
        23,
        20,
        0,
        9,
        27,
        28,
        24,
        5,
        4,
        45,
        49,
        15,
        28,
        49,
        0,
        27,
        42,
        0
      ],
      "weeklyBackordered": [
        0,
        31,
        44,
        17,
        49,
        41,
        49,
        49,
        16,
        38,
        48,
        28,
        7,
        30,
        1,
        29,
        49,
        46,
        1,
        42,
        0,
        30,
        3,
        45,
        26,
        37,
        1,
        49,
        19,
        41,
        16,
        12,
        2,
        49,
        0,
        0,
        26,
        15,
        9,
        49,
        0,
        48,
        28,
        49,
        49,
        9,
        46,
        18,
        3,
        16,
        38,
        49
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        0,
        49,
        27,
        48,
        49,
        24,
        1,
        31,
        46,
        16,
        45,
        22,
        49,
        49,
        37,
        48,
        40,
        3,
        10,
        21,
        22,
        37,
        27,
        41,
        36,
        42,
        0,
        38,
        22,
        48,
        8,
        0,
        49,
        1,
        48,
        46,
        25,
        22,
        47,
        24,
        0,
        27,
        33,
        12,
        49,
        0,
        18,
        9,
        49,
        49,
        32,
        11
      ],
      "weeklyRejected": [
        26,
        16,
        43,
        43,
        18,
        6,
        23,
        49,
        19,
        18,
        28,
        49,
        41,
        41,
        12,
        0,
        37,
        49,
        5,
        27,
        18,
        42,
        49,
        49,
        33,
        14,
        49,
        43,
        48,
        0,
        40,
        5,
        27,
        38,
        0,
        49,
        21,
        1,
        2,
        22,
        14,
        10,
        37,
        27,
        49,
        45,
        49,
        15,
        27,
        29,
        44,
        28
      ],
      "weeklyHold": [
        25,
        31,
        49,
        1,
        38,
        48,
        45,
        19,
        31,
        29,
        16,
        1,
        12,
        25,
        35,
        0,
        0,
        31,
        49,
        30,
        19,
        36,
        49,
        19,
        40,
        48,
        16,
        20,
        29,
        11,
        48,
        2,
        49,
        22,
        32,
        17,
        26,
        0,
        49,
        38,
        0,
        32,
        0,
        47,
        49,
        49,
        22,
        39,
        5,
        8,
        25,
        49
      ],
      "weeklyBackordered": [
        28,
        34,
        33,
        30,
        49,
        36,
        34,
        20,
        6,
        0,
        37,
        42,
        29,
        36,
        0,
        1,
        41,
        49,
        36,
        6,
        48,
        0,
        0,
        45,
        1,
        32,
        40,
        1,
        22,
        49,
        13,
        36,
        38,
        30,
        22,
        0,
        30,
        18,
        23,
        12,
        0,
        31,
        0,
        49,
        0,
        34,
        49,
        20,
        8,
        13,
        42,
        9
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        30,
        32,
        25,
        16,
        26,
        12,
        20,
        49,
        48,
        0,
        33,
        8,
        42,
        49,
        9,
        32,
        49,
        37,
        22,
        35,
        17,
        9,
        31,
        43,
        34,
        18,
        0,
        0,
        15,
        24,
        49,
        0,
        47,
        31,
        36,
        31,
        47,
        27,
        12,
        49,
        49,
        1,
        49,
        38,
        49,
        26,
        43,
        20,
        25,
        29,
        49,
        22
      ],
      "weeklyRejected": [
        45,
        13,
        13,
        35,
        19,
        6,
        38,
        16,
        29,
        9,
        49,
        0,
        30,
        20,
        40,
        43,
        24,
        49,
        0,
        7,
        0,
        37,
        14,
        45,
        33,
        49,
        19,
        14,
        48,
        48,
        49,
        47,
        48,
        34,
        24,
        44,
        8,
        0,
        14,
        22,
        37,
        49,
        42,
        7,
        31,
        41,
        0,
        5,
        35,
        30,
        0,
        33
      ],
      "weeklyHold": [
        21,
        49,
        31,
        1,
        44,
        23,
        41,
        6,
        23,
        35,
        1,
        30,
        1,
        47,
        22,
        37,
        20,
        48,
        7,
        0,
        26,
        6,
        14,
        16,
        36,
        41,
        18,
        25,
        44,
        0,
        30,
        33,
        14,
        29,
        20,
        11,
        43,
        47,
        49,
        39,
        8,
        33,
        0,
        15,
        49,
        49,
        17,
        36,
        0,
        26,
        49,
        30
      ],
      "weeklyBackordered": [
        28,
        15,
        0,
        43,
        7,
        42,
        41,
        12,
        33,
        49,
        18,
        5,
        3,
        49,
        22,
        49,
        32,
        15,
        15,
        28,
        32,
        0,
        44,
        8,
        10,
        26,
        26,
        42,
        1,
        15,
        14,
        29,
        38,
        0,
        0,
        0,
        36,
        19,
        2,
        20,
        19,
        48,
        33,
        49,
        20,
        0,
        28,
        5,
        1,
        31,
        49,
        8
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyAccepted": [
        1,
        49,
        17,
        0,
        24,
        0,
        23,
        49,
        39,
        0,
        48,
        13,
        48,
        10,
        49,
        40,
        49,
        30,
        11,
        33,
        49,
        19,
        26,
        0,
        0,
        47,
        49,
        24,
        23,
        49,
        0,
        12,
        49,
        22,
        1,
        7,
        31,
        18,
        34,
        33,
        22,
        35,
        0,
        10,
        49,
        21,
        0,
        14,
        3,
        49,
        11,
        8
      ],
      "weeklyRejected": [
        21,
        49,
        29,
        16,
        49,
        49,
        49,
        8,
        26,
        11,
        18,
        49,
        12,
        48,
        48,
        3,
        34,
        41,
        49,
        49,
        49,
        49,
        15,
        8,
        3,
        49,
        0,
        15,
        48,
        26,
        34,
        47,
        0,
        49,
        39,
        49,
        23,
        49,
        1,
        41,
        26,
        28,
        45,
        13,
        49,
        35,
        38,
        22,
        1,
        1,
        0,
        41
      ],
      "weeklyHold": [
        38,
        14,
        20,
        13,
        47,
        34,
        37,
        12,
        15,
        7,
        47,
        1,
        44,
        1,
        44,
        21,
        4,
        0,
        29,
        49,
        23,
        0,
        16,
        32,
        0,
        48,
        49,
        0,
        49,
        0,
        41,
        7,
        39,
        49,
        12,
        29,
        23,
        19,
        32,
        8,
        8,
        49,
        33,
        49,
        49,
        47,
        42,
        48,
        3,
        25,
        42,
        49
      ],
      "weeklyBackordered": [
        9,
        26,
        42,
        39,
        40,
        35,
        9,
        39,
        36,
        5,
        36,
        49,
        10,
        0,
        39,
        49,
        49,
        49,
        0,
        31,
        46,
        2,
        0,
        28,
        20,
        31,
        10,
        35,
        11,
        49,
        29,
        49,
        15,
        38,
        1,
        14,
        7,
        0,
        20,
        0,
        0,
        48,
        41,
        49,
        49,
        30,
        9,
        34,
        0,
        46,
        1,
        41
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        0,
        28,
        3,
        26,
        28,
        0,
        7,
        30,
        29,
        47,
        8,
        0,
        24,
        23,
        39,
        0,
        42,
        7,
        4,
        32,
        49,
        28,
        49,
        0,
        0,
        0,
        17,
        15,
        1,
        37,
        1,
        25,
        45,
        7,
        36,
        34,
        47,
        14,
        20,
        49,
        5,
        4,
        31,
        11,
        10,
        0,
        24,
        17,
        13,
        43,
        15,
        21
      ],
      "weeklyRejected": [
        11,
        14,
        48,
        16,
        13,
        24,
        49,
        45,
        47,
        9,
        25,
        2,
        0,
        48,
        19,
        21,
        45,
        46,
        49,
        10,
        49,
        46,
        37,
        24,
        28,
        49,
        29,
        37,
        48,
        3,
        16,
        47,
        15,
        8,
        27,
        43,
        19,
        20,
        17,
        23,
        29,
        26,
        28,
        6,
        49,
        49,
        0,
        0,
        0,
        43,
        31,
        15
      ],
      "weeklyHold": [
        3,
        33,
        34,
        1,
        5,
        2,
        13,
        2,
        5,
        30,
        38,
        44,
        37,
        26,
        28,
        12,
        14,
        13,
        49,
        36,
        41,
        31,
        49,
        18,
        42,
        6,
        39,
        49,
        49,
        49,
        48,
        47,
        34,
        29,
        48,
        46,
        0,
        16,
        43,
        27,
        45,
        22,
        24,
        40,
        24,
        2,
        17,
        49,
        0,
        0,
        25,
        4
      ],
      "weeklyBackordered": [
        28,
        32,
        30,
        18,
        27,
        46,
        35,
        1,
        18,
        27,
        36,
        39,
        7,
        24,
        48,
        49,
        10,
        49,
        19,
        20,
        49,
        34,
        0,
        49,
        24,
        49,
        11,
        14,
        30,
        23,
        25,
        18,
        37,
        46,
        16,
        24,
        23,
        0,
        3,
        21,
        37,
        36,
        19,
        20,
        20,
        39,
        49,
        44,
        34,
        18,
        37,
        29
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        24,
        5,
        14,
        35,
        37,
        22,
        25,
        39,
        48,
        0,
        49,
        49,
        34,
        29,
        1,
        0,
        49,
        20,
        7,
        30,
        49,
        2,
        36,
        0,
        29,
        24,
        42,
        0,
        1,
        31,
        6,
        22,
        35,
        34,
        27,
        5,
        47,
        48,
        26,
        49,
        3,
        46,
        49,
        34,
        17,
        28,
        3,
        35,
        43,
        14,
        17,
        30
      ],
      "weeklyRejected": [
        36,
        19,
        49,
        0,
        33,
        0,
        10,
        49,
        19,
        11,
        5,
        48,
        18,
        10,
        23,
        22,
        13,
        45,
        12,
        15,
        26,
        44,
        46,
        10,
        49,
        12,
        29,
        0,
        42,
        0,
        29,
        6,
        48,
        34,
        0,
        49,
        22,
        0,
        33,
        18,
        15,
        8,
        28,
        9,
        40,
        49,
        36,
        24,
        0,
        33,
        18,
        42
      ],
      "weeklyHold": [
        0,
        29,
        40,
        13,
        49,
        46,
        38,
        4,
        0,
        0,
        47,
        17,
        4,
        49,
        16,
        22,
        6,
        29,
        22,
        0,
        47,
        0,
        10,
        1,
        11,
        48,
        14,
        0,
        49,
        0,
        32,
        4,
        49,
        23,
        35,
        20,
        31,
        0,
        49,
        34,
        9,
        33,
        3,
        45,
        43,
        31,
        44,
        15,
        0,
        9,
        37,
        41
      ],
      "weeklyBackordered": [
        4,
        19,
        25,
        42,
        45,
        34,
        42,
        22,
        34,
        2,
        45,
        49,
        3,
        49,
        31,
        27,
        35,
        43,
        21,
        49,
        46,
        0,
        0,
        3,
        5,
        12,
        34,
        13,
        11,
        49,
        49,
        38,
        23,
        49,
        1,
        0,
        8,
        34,
        25,
        0,
        0,
        48,
        10,
        30,
        0,
        19,
        28,
        29,
        31,
        6,
        23,
        23
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        0,
        49,
        49,
        0,
        3,
        9,
        1,
        49,
        17,
        34,
        49,
        0,
        49,
        45,
        11,
        20,
        33,
        0,
        17,
        49,
        13,
        26,
        19,
        32,
        38,
        47,
        39,
        32,
        4,
        14,
        20,
        0,
        46,
        0,
        12,
        49,
        38,
        29,
        49,
        25,
        0,
        17,
        49,
        26,
        30,
        0,
        4,
        7,
        19,
        19,
        7,
        9
      ],
      "weeklyRejected": [
        49,
        5,
        32,
        47,
        32,
        0,
        49,
        49,
        49,
        29,
        0,
        24,
        28,
        43,
        27,
        22,
        22,
        47,
        27,
        24,
        49,
        20,
        0,
        20,
        9,
        49,
        46,
        0,
        48,
        13,
        40,
        47,
        9,
        13,
        41,
        34,
        9,
        20,
        9,
        30,
        48,
        0,
        49,
        38,
        49,
        32,
        0,
        0,
        36,
        49,
        40,
        29
      ],
      "weeklyHold": [
        21,
        17,
        49,
        39,
        10,
        28,
        2,
        13,
        2,
        34,
        32,
        23,
        10,
        1,
        5,
        8,
        11,
        0,
        10,
        0,
        40,
        12,
        7,
        15,
        3,
        41,
        17,
        0,
        39,
        0,
        48,
        14,
        7,
        5,
        22,
        49,
        0,
        0,
        36,
        13,
        0,
        30,
        11,
        25,
        35,
        49,
        0,
        49,
        1,
        8,
        49,
        31
      ],
      "weeklyBackordered": [
        30,
        49,
        0,
        28,
        31,
        43,
        21,
        43,
        0,
        30,
        49,
        25,
        3,
        36,
        32,
        1,
        27,
        30,
        39,
        21,
        49,
        4,
        6,
        18,
        16,
        24,
        27,
        43,
        49,
        36,
        43,
        13,
        19,
        27,
        0,
        22,
        1,
        25,
        0,
        28,
        22,
        21,
        0,
        49,
        20,
        27,
        42,
        0,
        0,
        37,
        49,
        23
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        0,
        23,
        20,
        37,
        49,
        0,
        20,
        17,
        43,
        0,
        0,
        8,
        39,
        22,
        1,
        46,
        21,
        34,
        21,
        0,
        49,
        46,
        49,
        33,
        0,
        35,
        16,
        49,
        18,
        38,
        22,
        49,
        49,
        47,
        38,
        34,
        5,
        3,
        1,
        29,
        49,
        14,
        49,
        49,
        49,
        24,
        0,
        48,
        17,
        29,
        38,
        1
      ],
      "weeklyRejected": [
        4,
        14,
        30,
        36,
        48,
        25,
        49,
        29,
        49,
        14,
        23,
        33,
        0,
        42,
        48,
        17,
        0,
        30,
        42,
        33,
        7,
        49,
        49,
        10,
        2,
        49,
        48,
        12,
        15,
        35,
        49,
        47,
        8,
        49,
        14,
        49,
        15,
        39,
        2,
        0,
        36,
        30,
        35,
        25,
        43,
        49,
        13,
        23,
        0,
        49,
        11,
        49
      ],
      "weeklyHold": [
        6,
        9,
        42,
        1,
        16,
        48,
        49,
        12,
        25,
        5,
        47,
        17,
        48,
        38,
        42,
        0,
        25,
        0,
        26,
        40,
        36,
        41,
        0,
        32,
        19,
        1,
        26,
        20,
        49,
        0,
        43,
        0,
        0,
        17,
        12,
        29,
        27,
        29,
        38,
        40,
        0,
        34,
        33,
        26,
        38,
        45,
        25,
        11,
        6,
        23,
        0,
        19
      ],
      "weeklyBackordered": [
        3,
        6,
        0,
        49,
        28,
        25,
        49,
        47,
        33,
        16,
        10,
        49,
        3,
        49,
        49,
        39,
        20,
        49,
        0,
        0,
        27,
        1,
        8,
        29,
        10,
        6,
        49,
        6,
        11,
        29,
        10,
        34,
        39,
        27,
        2,
        21,
        36,
        0,
        45,
        24,
        0,
        23,
        5,
        49,
        32,
        0,
        25,
        17,
        25,
        49,
        19,
        2
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        9,
        30,
        37,
        21,
        35,
        0,
        17,
        49,
        10,
        25,
        49,
        15,
        49,
        36,
        14,
        22,
        38,
        5,
        0,
        32,
        28,
        35,
        3,
        40,
        40,
        35,
        0,
        43,
        34,
        37,
        42,
        49,
        49,
        24,
        48,
        13,
        4,
        44,
        34,
        49,
        29,
        36,
        11,
        49,
        36,
        17,
        12,
        49,
        0,
        36,
        49,
        8
      ],
      "weeklyRejected": [
        23,
        0,
        22,
        7,
        46,
        15,
        0,
        0,
        49,
        48,
        49,
        7,
        49,
        48,
        34,
        21,
        26,
        0,
        33,
        19,
        29,
        14,
        0,
        17,
        29,
        42,
        49,
        42,
        5,
        22,
        14,
        36,
        42,
        29,
        5,
        49,
        25,
        6,
        20,
        27,
        31,
        49,
        43,
        0,
        49,
        0,
        47,
        31,
        0,
        11,
        0,
        49
      ],
      "weeklyHold": [
        37,
        26,
        49,
        16,
        20,
        23,
        23,
        19,
        24,
        20,
        28,
        29,
        26,
        38,
        23,
        1,
        18,
        20,
        18,
        0,
        13,
        44,
        0,
        2,
        40,
        28,
        5,
        16,
        49,
        31,
        39,
        48,
        13,
        36,
        19,
        0,
        0,
        33,
        19,
        43,
        40,
        39,
        47,
        34,
        15,
        49,
        16,
        0,
        6,
        49,
        22,
        49
      ],
      "weeklyBackordered": [
        47,
        36,
        0,
        43,
        0,
        21,
        49,
        32,
        49,
        10,
        38,
        13,
        17,
        25,
        4,
        41,
        49,
        22,
        0,
        31,
        45,
        23,
        0,
        38,
        12,
        10,
        25,
        0,
        30,
        35,
        26,
        47,
        27,
        27,
        4,
        11,
        49,
        22,
        0,
        42,
        12,
        23,
        0,
        49,
        0,
        26,
        21,
        31,
        4,
        31,
        20,
        20
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        8,
        31,
        25,
        46,
        29,
        18,
        19,
        18,
        31,
        0,
        17,
        0,
        33,
        24,
        1,
        10,
        9,
        48,
        0,
        21,
        45,
        49,
        49,
        16,
        19,
        47,
        4,
        25,
        1,
        49,
        9,
        33,
        49,
        11,
        6,
        2,
        47,
        47,
        7,
        49,
        8,
        45,
        49,
        24,
        14,
        21,
        2,
        14,
        35,
        49,
        18,
        31
      ],
      "weeklyRejected": [
        20,
        6,
        49,
        31,
        37,
        25,
        20,
        0,
        23,
        41,
        49,
        33,
        49,
        24,
        46,
        40,
        0,
        49,
        49,
        6,
        0,
        21,
        49,
        49,
        49,
        49,
        49,
        38,
        43,
        7,
        33,
        19,
        34,
        23,
        49,
        22,
        9,
        6,
        1,
        6,
        38,
        12,
        21,
        10,
        21,
        49,
        0,
        19,
        0,
        46,
        0,
        49
      ],
      "weeklyHold": [
        40,
        23,
        45,
        13,
        1,
        28,
        49,
        18,
        22,
        22,
        47,
        31,
        27,
        33,
        23,
        18,
        46,
        20,
        2,
        29,
        38,
        49,
        18,
        25,
        20,
        37,
        3,
        9,
        34,
        20,
        40,
        3,
        13,
        28,
        6,
        20,
        25,
        13,
        21,
        47,
        0,
        35,
        17,
        18,
        16,
        16,
        36,
        49,
        0,
        39,
        9,
        49
      ],
      "weeklyBackordered": [
        49,
        0,
        3,
        40,
        49,
        46,
        18,
        18,
        43,
        6,
        0,
        43,
        5,
        18,
        0,
        16,
        49,
        34,
        8,
        10,
        49,
        45,
        39,
        49,
        33,
        49,
        5,
        14,
        27,
        49,
        34,
        20,
        17,
        40,
        30,
        0,
        28,
        0,
        49,
        37,
        48,
        48,
        2,
        12,
        0,
        34,
        49,
        29,
        27,
        49,
        17,
        8
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        44,
        41,
        31,
        48,
        29,
        22,
        19,
        49,
        48,
        24,
        49,
        0,
        11,
        13,
        22,
        19,
        49,
        18,
        0,
        26,
        34,
        22,
        49,
        17,
        1,
        29,
        40,
        40,
        4,
        20,
        23,
        20,
        31,
        0,
        48,
        46,
        19,
        48,
        49,
        49,
        38,
        17,
        38,
        27,
        34,
        0,
        0,
        10,
        0,
        0,
        18,
        21
      ],
      "weeklyRejected": [
        44,
        29,
        46,
        17,
        27,
        17,
        49,
        46,
        49,
        7,
        16,
        17,
        17,
        41,
        43,
        13,
        44,
        49,
        0,
        10,
        21,
        13,
        11,
        38,
        11,
        30,
        18,
        26,
        45,
        3,
        8,
        31,
        41,
        49,
        35,
        49,
        49,
        45,
        23,
        30,
        48,
        11,
        49,
        17,
        49,
        13,
        0,
        49,
        13,
        30,
        13,
        38
      ],
      "weeklyHold": [
        49,
        23,
        19,
        1,
        8,
        48,
        0,
        2,
        0,
        49,
        35,
        1,
        48,
        40,
        40,
        24,
        5,
        21,
        0,
        16,
        0,
        7,
        12,
        37,
        23,
        31,
        0,
        49,
        11,
        0,
        27,
        7,
        8,
        49,
        48,
        49,
        35,
        42,
        34,
        12,
        6,
        3,
        0,
        16,
        32,
        43,
        36,
        23,
        23,
        7,
        37,
        24
      ],
      "weeklyBackordered": [
        39,
        47,
        6,
        15,
        16,
        25,
        18,
        46,
        19,
        31,
        49,
        27,
        27,
        49,
        3,
        49,
        49,
        49,
        0,
        49,
        14,
        32,
        22,
        0,
        10,
        47,
        15,
        48,
        8,
        49,
        0,
        48,
        23,
        13,
        0,
        3,
        49,
        14,
        12,
        49,
        17,
        32,
        24,
        46,
        24,
        19,
        45,
        0,
        0,
        35,
        18,
        26
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        15,
        43,
        31,
        48,
        27,
        43,
        36,
        2,
        33,
        31,
        12,
        5,
        39,
        49,
        49,
        0,
        47,
        10,
        19,
        28,
        7,
        2,
        10,
        44,
        2,
        45,
        13,
        37,
        8,
        33,
        10,
        18,
        40,
        27,
        48,
        32,
        41,
        47,
        19,
        28,
        38,
        49,
        49,
        23,
        17,
        0,
        0,
        33,
        32,
        14,
        31,
        34
      ],
      "weeklyRejected": [
        49,
        30,
        24,
        24,
        28,
        28,
        49,
        45,
        39,
        1,
        38,
        24,
        28,
        15,
        37,
        3,
        48,
        49,
        49,
        18,
        9,
        49,
        24,
        7,
        0,
        16,
        49,
        35,
        31,
        35,
        42,
        29,
        48,
        2,
        16,
        26,
        4,
        22,
        1,
        49,
        26,
        26,
        25,
        7,
        49,
        49,
        17,
        31,
        16,
        35,
        18,
        37
      ],
      "weeklyHold": [
        15,
        6,
        18,
        26,
        5,
        40,
        49,
        25,
        0,
        49,
        37,
        1,
        21,
        20,
        49,
        13,
        5,
        15,
        10,
        23,
        25,
        27,
        29,
        2,
        22,
        38,
        9,
        25,
        49,
        3,
        5,
        0,
        0,
        30,
        14,
        46,
        44,
        20,
        49,
        49,
        13,
        33,
        0,
        7,
        32,
        49,
        20,
        22,
        8,
        49,
        41,
        41
      ],
      "weeklyBackordered": [
        0,
        0,
        0,
        43,
        41,
        46,
        41,
        49,
        24,
        26,
        4,
        41,
        21,
        5,
        26,
        38,
        25,
        48,
        12,
        8,
        45,
        29,
        9,
        28,
        29,
        49,
        32,
        39,
        1,
        13,
        41,
        0,
        11,
        49,
        0,
        0,
        2,
        0,
        46,
        30,
        27,
        32,
        20,
        49,
        24,
        19,
        26,
        0,
        13,
        49,
        49,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        17,
        28,
        3,
        16,
        36,
        8,
        11,
        30,
        23,
        5,
        35,
        21,
        49,
        0,
        9,
        24,
        49,
        44,
        23,
        0,
        31,
        7,
        0,
        48,
        26,
        0,
        33,
        0,
        1,
        49,
        32,
        24,
        2,
        17,
        47,
        38,
        24,
        30,
        28,
        19,
        11,
        21,
        32,
        49,
        49,
        9,
        0,
        14,
        29,
        5,
        11,
        27
      ],
      "weeklyRejected": [
        49,
        10,
        21,
        0,
        45,
        0,
        24,
        34,
        49,
        20,
        0,
        0,
        46,
        22,
        13,
        25,
        42,
        49,
        33,
        0,
        49,
        32,
        17,
        28,
        0,
        40,
        42,
        27,
        35,
        34,
        15,
        47,
        11,
        27,
        35,
        49,
        8,
        11,
        42,
        31,
        10,
        26,
        24,
        18,
        38,
        10,
        30,
        11,
        48,
        10,
        0,
        48
      ],
      "weeklyHold": [
        25,
        43,
        46,
        16,
        16,
        23,
        0,
        9,
        17,
        22,
        17,
        37,
        13,
        36,
        0,
        29,
        4,
        36,
        49,
        0,
        25,
        24,
        38,
        41,
        0,
        25,
        23,
        47,
        34,
        44,
        32,
        39,
        12,
        49,
        13,
        38,
        17,
        12,
        27,
        2,
        46,
        26,
        15,
        0,
        9,
        3,
        0,
        26,
        12,
        42,
        42,
        42
      ],
      "weeklyBackordered": [
        18,
        49,
        45,
        14,
        26,
        46,
        1,
        14,
        29,
        15,
        32,
        18,
        5,
        49,
        28,
        49,
        49,
        31,
        26,
        31,
        49,
        27,
        11,
        4,
        9,
        1,
        48,
        22,
        29,
        42,
        29,
        35,
        19,
        27,
        0,
        1,
        49,
        32,
        24,
        8,
        22,
        48,
        19,
        25,
        18,
        49,
        46,
        2,
        23,
        49,
        1,
        25
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        6,
        45,
        22,
        31,
        21,
        0,
        32,
        5,
        8,
        1,
        39,
        9,
        49,
        32,
        17,
        21,
        49,
        0,
        14,
        10,
        47,
        43,
        49,
        30,
        0,
        27,
        12,
        49,
        28,
        49,
        25,
        1,
        22,
        35,
        48,
        41,
        47,
        12,
        49,
        49,
        9,
        24,
        21,
        21,
        49,
        18,
        27,
        3,
        4,
        14,
        7,
        7
      ],
      "weeklyRejected": [
        38,
        7,
        20,
        39,
        25,
        0,
        28,
        5,
        44,
        1,
        37,
        30,
        21,
        48,
        12,
        0,
        0,
        21,
        31,
        25,
        49,
        49,
        42,
        36,
        30,
        49,
        42,
        31,
        12,
        12,
        0,
        47,
        19,
        49,
        34,
        49,
        49,
        40,
        18,
        46,
        48,
        24,
        49,
        19,
        9,
        49,
        21,
        13,
        0,
        0,
        38,
        30
      ],
      "weeklyHold": [
        16,
        5,
        45,
        12,
        33,
        25,
        47,
        44,
        16,
        19,
        44,
        32,
        44,
        34,
        38,
        15,
        21,
        27,
        49,
        15,
        0,
        32,
        49,
        13,
        30,
        38,
        5,
        0,
        49,
        40,
        48,
        3,
        0,
        25,
        17,
        13,
        37,
        18,
        28,
        0,
        22,
        35,
        23,
        17,
        16,
        0,
        34,
        21,
        16,
        3,
        16,
        37
      ],
      "weeklyBackordered": [
        9,
        37,
        31,
        36,
        49,
        37,
        40,
        46,
        49,
        1,
        47,
        33,
        20,
        0,
        25,
        25,
        24,
        23,
        27,
        49,
        28,
        0,
        0,
        49,
        48,
        26,
        35,
        15,
        6,
        13,
        26,
        30,
        39,
        49,
        10,
        0,
        26,
        28,
        49,
        23,
        48,
        48,
        40,
        20,
        19,
        0,
        31,
        17,
        0,
        32,
        49,
        21
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        0,
        46,
        0,
        40,
        49,
        0,
        23,
        17,
        48,
        22,
        32,
        0,
        41,
        18,
        12,
        27,
        36,
        36,
        23,
        0,
        49,
        15,
        28,
        28,
        7,
        16,
        28,
        34,
        39,
        33,
        3,
        15,
        26,
        18,
        48,
        34,
        15,
        5,
        16,
        49,
        16,
        49,
        46,
        15,
        49,
        22,
        0,
        49,
        11,
        29,
        4,
        25
      ],
      "weeklyRejected": [
        49,
        25,
        47,
        30,
        42,
        4,
        0,
        8,
        48,
        17,
        25,
        41,
        18,
        43,
        48,
        49,
        10,
        32,
        17,
        7,
        8,
        10,
        39,
        22,
        4,
        49,
        19,
        49,
        28,
        38,
        25,
        10,
        0,
        49,
        0,
        49,
        49,
        27,
        49,
        9,
        27,
        49,
        0,
        18,
        20,
        34,
        0,
        17,
        0,
        36,
        27,
        32
      ],
      "weeklyHold": [
        0,
        6,
        17,
        4,
        25,
        48,
        28,
        31,
        45,
        33,
        44,
        15,
        25,
        27,
        26,
        0,
        9,
        18,
        0,
        4,
        47,
        43,
        49,
        8,
        31,
        28,
        49,
        49,
        49,
        28,
        22,
        18,
        4,
        0,
        6,
        0,
        0,
        41,
        27,
        3,
        42,
        18,
        37,
        33,
        49,
        38,
        22,
        49,
        10,
        49,
        49,
        11
      ],
      "weeklyBackordered": [
        0,
        0,
        1,
        18,
        35,
        46,
        38,
        39,
        47,
        33,
        49,
        0,
        16,
        17,
        41,
        40,
        49,
        26,
        0,
        49,
        49,
        11,
        44,
        49,
        1,
        13,
        21,
        5,
        1,
        36,
        0,
        40,
        4,
        37,
        0,
        11,
        49,
        0,
        11,
        9,
        24,
        48,
        11,
        49,
        49,
        43,
        35,
        17,
        25,
        49,
        49,
        15
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        38,
        46,
        49,
        27,
        11,
        0,
        9,
        40,
        41,
        11,
        49,
        33,
        13,
        47,
        18,
        32,
        27,
        0,
        11,
        16,
        0,
        31,
        49,
        46,
        6,
        8,
        3,
        24,
        26,
        49,
        31,
        21,
        49,
        36,
        48,
        49,
        18,
        48,
        13,
        25,
        49,
        36,
        49,
        46,
        49,
        26,
        0,
        49,
        9,
        33,
        46,
        44
      ],
      "weeklyRejected": [
        49,
        8,
        2,
        0,
        30,
        36,
        14,
        49,
        49,
        47,
        40,
        39,
        18,
        48,
        32,
        4,
        27,
        28,
        49,
        12,
        29,
        49,
        38,
        27,
        20,
        29,
        49,
        23,
        17,
        33,
        49,
        47,
        31,
        49,
        38,
        49,
        49,
        49,
        1,
        49,
        5,
        48,
        13,
        3,
        49,
        30,
        22,
        45,
        20,
        0,
        0,
        26
      ],
      "weeklyHold": [
        1,
        42,
        49,
        1,
        19,
        48,
        25,
        26,
        24,
        42,
        0,
        1,
        12,
        46,
        30,
        0,
        30,
        0,
        16,
        31,
        0,
        17,
        49,
        22,
        15,
        3,
        14,
        47,
        38,
        31,
        48,
        0,
        10,
        12,
        18,
        37,
        2,
        8,
        1,
        16,
        32,
        38,
        0,
        34,
        49,
        49,
        49,
        40,
        1,
        18,
        49,
        37
      ],
      "weeklyBackordered": [
        34,
        17,
        1,
        20,
        49,
        22,
        44,
        33,
        40,
        16,
        15,
        49,
        36,
        21,
        49,
        32,
        7,
        42,
        11,
        17,
        10,
        38,
        0,
        4,
        24,
        49,
        1,
        6,
        48,
        17,
        7,
        22,
        45,
        49,
        19,
        0,
        46,
        17,
        13,
        10,
        19,
        48,
        0,
        49,
        21,
        33,
        49,
        15,
        23,
        10,
        17,
        20
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        15,
        31,
        49,
        43,
        1,
        26,
        5,
        42,
        34,
        22,
        35,
        26,
        49,
        21,
        47,
        41,
        49,
        0,
        25,
        41,
        49,
        26,
        26,
        39,
        49,
        26,
        4,
        0,
        8,
        21,
        29,
        28,
        27,
        0,
        48,
        28,
        40,
        46,
        0,
        49,
        0,
        38,
        49,
        4,
        49,
        23,
        11,
        36,
        20,
        47,
        16,
        47
      ],
      "weeklyRejected": [
        38,
        24,
        49,
        24,
        49,
        32,
        17,
        49,
        0,
        16,
        49,
        17,
        34,
        28,
        48,
        39,
        20,
        16,
        27,
        46,
        3,
        15,
        25,
        22,
        42,
        18,
        40,
        30,
        23,
        18,
        31,
        47,
        48,
        21,
        4,
        44,
        49,
        0,
        15,
        49,
        48,
        18,
        20,
        5,
        49,
        28,
        49,
        7,
        0,
        26,
        18,
        39
      ],
      "weeklyHold": [
        40,
        24,
        45,
        1,
        0,
        34,
        46,
        2,
        37,
        14,
        26,
        49,
        38,
        43,
        9,
        14,
        0,
        43,
        18,
        8,
        0,
        22,
        41,
        0,
        47,
        48,
        28,
        21,
        49,
        9,
        48,
        5,
        0,
        21,
        27,
        26,
        49,
        1,
        40,
        40,
        4,
        38,
        7,
        44,
        11,
        30,
        29,
        10,
        9,
        11,
        0,
        31
      ],
      "weeklyBackordered": [
        1,
        43,
        12,
        15,
        49,
        0,
        46,
        47,
        25,
        0,
        33,
        35,
        3,
        43,
        30,
        22,
        31,
        26,
        0,
        0,
        17,
        0,
        8,
        26,
        1,
        1,
        15,
        12,
        16,
        23,
        44,
        49,
        46,
        20,
        0,
        13,
        20,
        32,
        27,
        35,
        8,
        48,
        30,
        49,
        32,
        34,
        32,
        24,
        49,
        32,
        15,
        30
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        11,
        47,
        20,
        48,
        10,
        0,
        15,
        48,
        32,
        17,
        31,
        0,
        29,
        47,
        15,
        32,
        49,
        18,
        46,
        34,
        17,
        12,
        14,
        23,
        26,
        27,
        29,
        27,
        32,
        18,
        15,
        12,
        43,
        21,
        38,
        41,
        18,
        21,
        11,
        26,
        0,
        30,
        49,
        11,
        30,
        18,
        34,
        28,
        2,
        4,
        0,
        30
      ],
      "weeklyRejected": [
        49,
        12,
        11,
        33,
        49,
        10,
        13,
        47,
        49,
        1,
        28,
        23,
        31,
        30,
        48,
        33,
        23,
        40,
        27,
        20,
        14,
        0,
        12,
        21,
        18,
        29,
        16,
        18,
        34,
        21,
        0,
        12,
        19,
        2,
        0,
        32,
        46,
        7,
        5,
        37,
        48,
        5,
        27,
        3,
        49,
        34,
        23,
        5,
        0,
        34,
        23,
        49
      ],
      "weeklyHold": [
        25,
        6,
        23,
        1,
        49,
        20,
        41,
        11,
        33,
        42,
        11,
        42,
        6,
        23,
        30,
        0,
        8,
        0,
        16,
        0,
        40,
        49,
        46,
        27,
        25,
        35,
        49,
        0,
        40,
        0,
        48,
        6,
        17,
        49,
        19,
        49,
        14,
        23,
        17,
        32,
        0,
        38,
        17,
        14,
        49,
        35,
        21,
        49,
        5,
        44,
        17,
        7
      ],
      "weeklyBackordered": [
        13,
        33,
        0,
        21,
        49,
        29,
        19,
        26,
        25,
        26,
        36,
        36,
        3,
        10,
        9,
        34,
        36,
        7,
        8,
        49,
        49,
        22,
        49,
        0,
        36,
        4,
        14,
        10,
        13,
        34,
        20,
        44,
        44,
        43,
        19,
        0,
        32,
        24,
        49,
        22,
        23,
        29,
        5,
        49,
        49,
        41,
        30,
        8,
        0,
        33,
        37,
        1
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        15,
        42,
        15,
        21,
        21,
        12,
        17,
        30,
        48,
        0,
        30,
        5,
        30,
        49,
        26,
        15,
        25,
        31,
        11,
        38,
        10,
        4,
        25,
        49,
        34,
        36,
        49,
        0,
        1,
        49,
        0,
        0,
        27,
        46,
        41,
        29,
        17,
        35,
        10,
        18,
        36,
        13,
        49,
        49,
        38,
        4,
        29,
        25,
        19,
        26,
        0,
        45
      ],
      "weeklyRejected": [
        49,
        11,
        16,
        34,
        23,
        30,
        20,
        49,
        47,
        30,
        34,
        40,
        49,
        45,
        43,
        11,
        49,
        28,
        49,
        44,
        0,
        42,
        49,
        24,
        49,
        37,
        44,
        23,
        41,
        43,
        21,
        26,
        24,
        46,
        32,
        49,
        49,
        39,
        1,
        34,
        48,
        13,
        24,
        0,
        49,
        23,
        35,
        2,
        6,
        42,
        20,
        20
      ],
      "weeklyHold": [
        49,
        0,
        49,
        34,
        47,
        21,
        0,
        27,
        4,
        34,
        11,
        10,
        1,
        34,
        35,
        0,
        9,
        15,
        9,
        11,
        0,
        36,
        6,
        20,
        0,
        48,
        1,
        4,
        37,
        0,
        48,
        24,
        0,
        49,
        1,
        49,
        25,
        33,
        28,
        28,
        16,
        29,
        27,
        34,
        44,
        29,
        23,
        26,
        0,
        49,
        47,
        10
      ],
      "weeklyBackordered": [
        3,
        49,
        14,
        34,
        41,
        25,
        13,
        8,
        13,
        10,
        27,
        7,
        17,
        28,
        42,
        16,
        39,
        31,
        9,
        32,
        49,
        22,
        6,
        29,
        46,
        14,
        16,
        8,
        10,
        35,
        48,
        46,
        48,
        47,
        17,
        0,
        7,
        4,
        40,
        19,
        28,
        46,
        0,
        40,
        36,
        17,
        38,
        14,
        0,
        49,
        49,
        49
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyAccepted": [
        30,
        37,
        49,
        0,
        23,
        0,
        4,
        36,
        26,
        1,
        49,
        18,
        49,
        49,
        10,
        2,
        28,
        28,
        21,
        10,
        12,
        41,
        48,
        26,
        23,
        29,
        1,
        0,
        1,
        13,
        49,
        6,
        14,
        32,
        14,
        47,
        47,
        29,
        0,
        18,
        9,
        30,
        49,
        44,
        49,
        0,
        5,
        39,
        15,
        33,
        25,
        0
      ],
      "weeklyRejected": [
        49,
        6,
        9,
        34,
        26,
        44,
        49,
        25,
        22,
        33,
        20,
        32,
        3,
        27,
        21,
        21,
        0,
        40,
        30,
        0,
        6,
        43,
        49,
        22,
        21,
        27,
        23,
        23,
        32,
        14,
        21,
        13,
        24,
        40,
        27,
        49,
        19,
        46,
        14,
        13,
        22,
        16,
        30,
        5,
        48,
        49,
        27,
        36,
        10,
        39,
        0,
        40
      ],
      "weeklyHold": [
        41,
        21,
        41,
        1,
        28,
        25,
        18,
        2,
        49,
        33,
        47,
        33,
        23,
        29,
        33,
        11,
        5,
        31,
        2,
        28,
        0,
        32,
        16,
        0,
        11,
        40,
        48,
        49,
        49,
        0,
        42,
        3,
        18,
        4,
        27,
        49,
        48,
        13,
        42,
        41,
        0,
        35,
        19,
        24,
        34,
        39,
        0,
        29,
        33,
        42,
        12,
        39
      ],
      "weeklyBackordered": [
        48,
        41,
        39,
        41,
        48,
        24,
        37,
        40,
        33,
        46,
        49,
        13,
        3,
        4,
        17,
        19,
        49,
        49,
        49,
        49,
        9,
        16,
        25,
        49,
        25,
        49,
        22,
        29,
        44,
        49,
        18,
        49,
        41,
        18,
        0,
        0,
        20,
        22,
        18,
        14,
        15,
        48,
        20,
        49,
        22,
        43,
        27,
        3,
        17,
        37,
        46,
        39
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        0,
        40,
        20,
        0,
        27,
        0,
        27,
        39,
        37,
        38,
        23,
        0,
        39,
        42,
        10,
        29,
        37,
        48,
        14,
        48,
        37,
        49,
        37,
        25,
        32,
        47,
        0,
        49,
        29,
        27,
        30,
        39,
        30,
        11,
        8,
        7,
        27,
        12,
        1,
        48,
        49,
        17,
        25,
        28,
        40,
        0,
        12,
        0,
        49,
        28,
        9,
        3
      ],
      "weeklyRejected": [
        49,
        29,
        8,
        47,
        16,
        4,
        40,
        3,
        40,
        11,
        45,
        40,
        24,
        34,
        37,
        14,
        0,
        49,
        19,
        23,
        8,
        29,
        27,
        12,
        19,
        25,
        17,
        43,
        48,
        19,
        36,
        27,
        48,
        44,
        35,
        10,
        33,
        33,
        18,
        17,
        37,
        25,
        0,
        37,
        37,
        25,
        6,
        0,
        11,
        49,
        27,
        49
      ],
      "weeklyHold": [
        36,
        14,
        49,
        3,
        28,
        48,
        44,
        19,
        24,
        35,
        38,
        6,
        46,
        14,
        49,
        19,
        0,
        26,
        0,
        49,
        27,
        49,
        0,
        12,
        39,
        47,
        2,
        0,
        32,
        0,
        39,
        34,
        10,
        12,
        0,
        38,
        5,
        17,
        31,
        4,
        11,
        49,
        32,
        49,
        31,
        9,
        23,
        49,
        0,
        28,
        49,
        22
      ],
      "weeklyBackordered": [
        15,
        24,
        20,
        49,
        49,
        1,
        35,
        49,
        38,
        22,
        33,
        11,
        49,
        34,
        22,
        4,
        49,
        49,
        23,
        10,
        39,
        22,
        0,
        49,
        8,
        17,
        28,
        26,
        42,
        6,
        0,
        48,
        48,
        20,
        49,
        0,
        1,
        17,
        0,
        32,
        29,
        47,
        12,
        15,
        27,
        42,
        49,
        5,
        49,
        49,
        49,
        18
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        49,
        46,
        0,
        48,
        41,
        15,
        31,
        29,
        39,
        31,
        33,
        12,
        49,
        49,
        1,
        0,
        49,
        33,
        0,
        14,
        17,
        0,
        49,
        6,
        17,
        32,
        2,
        16,
        31,
        27,
        41,
        0,
        49,
        4,
        28,
        3,
        29,
        7,
        26,
        49,
        34,
        7,
        31,
        27,
        49,
        27,
        0,
        30,
        49,
        49,
        12,
        0
      ],
      "weeklyRejected": [
        29,
        6,
        49,
        30,
        49,
        33,
        49,
        20,
        49,
        18,
        41,
        40,
        6,
        48,
        28,
        2,
        15,
        48,
        6,
        0,
        30,
        1,
        32,
        21,
        15,
        49,
        24,
        39,
        46,
        25,
        43,
        46,
        47,
        49,
        0,
        34,
        14,
        0,
        23,
        0,
        44,
        18,
        27,
        14,
        36,
        3,
        17,
        0,
        13,
        17,
        0,
        16
      ],
      "weeklyHold": [
        0,
        3,
        49,
        11,
        47,
        48,
        44,
        2,
        0,
        13,
        47,
        37,
        48,
        6,
        45,
        5,
        0,
        0,
        0,
        37,
        26,
        26,
        43,
        24,
        9,
        26,
        26,
        0,
        49,
        45,
        48,
        17,
        20,
        0,
        48,
        21,
        43,
        0,
        14,
        44,
        0,
        49,
        0,
        0,
        36,
        49,
        48,
        49,
        14,
        32,
        19,
        26
      ],
      "weeklyBackordered": [
        49,
        28,
        0,
        40,
        48,
        14,
        0,
        49,
        18,
        19,
        26,
        21,
        21,
        3,
        28,
        40,
        49,
        12,
        7,
        19,
        34,
        0,
        8,
        33,
        27,
        25,
        1,
        49,
        18,
        49,
        31,
        9,
        27,
        37,
        4,
        0,
        8,
        6,
        10,
        0,
        48,
        48,
        0,
        49,
        13,
        49,
        49,
        40,
        39,
        0,
        47,
        8
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        5,
        49,
        29,
        36,
        29,
        20,
        7,
        49,
        9,
        5,
        43,
        27,
        49,
        0,
        4,
        0,
        18,
        23,
        7,
        29,
        12,
        38,
        43,
        30,
        28,
        29,
        30,
        48,
        1,
        49,
        19,
        21,
        49,
        11,
        42,
        49,
        22,
        45,
        37,
        31,
        0,
        37,
        49,
        39,
        49,
        6,
        6,
        11,
        6,
        30,
        10,
        19
      ],
      "weeklyRejected": [
        30,
        15,
        32,
        4,
        36,
        15,
        21,
        38,
        31,
        45,
        44,
        32,
        1,
        48,
        43,
        49,
        13,
        41,
        47,
        6,
        5,
        24,
        0,
        21,
        24,
        49,
        32,
        44,
        35,
        5,
        36,
        24,
        48,
        44,
        13,
        49,
        32,
        0,
        49,
        26,
        47,
        33,
        36,
        11,
        49,
        0,
        0,
        49,
        1,
        11,
        0,
        49
      ],
      "weeklyHold": [
        49,
        0,
        36,
        32,
        29,
        16,
        42,
        39,
        21,
        31,
        47,
        16,
        47,
        43,
        21,
        0,
        38,
        1,
        8,
        45,
        0,
        22,
        31,
        29,
        0,
        8,
        40,
        28,
        20,
        49,
        40,
        25,
        12,
        38,
        27,
        32,
        0,
        0,
        49,
        46,
        34,
        13,
        19,
        0,
        38,
        11,
        47,
        49,
        0,
        6,
        6,
        20
      ],
      "weeklyBackordered": [
        27,
        0,
        3,
        24,
        49,
        46,
        46,
        11,
        0,
        24,
        5,
        22,
        18,
        15,
        32,
        1,
        49,
        39,
        0,
        29,
        41,
        29,
        47,
        49,
        24,
        20,
        38,
        32,
        22,
        49,
        28,
        49,
        0,
        43,
        29,
        35,
        29,
        8,
        22,
        32,
        0,
        48,
        0,
        31,
        27,
        49,
        30,
        49,
        23,
        22,
        49,
        41
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        0,
        21,
        14,
        7,
        15,
        19,
        1,
        17,
        45,
        31,
        49,
        0,
        10,
        13,
        15,
        16,
        49,
        46,
        24,
        41,
        10,
        8,
        35,
        32,
        19,
        14,
        30,
        8,
        16,
        34,
        4,
        0,
        49,
        25,
        39,
        38,
        19,
        40,
        25,
        49,
        35,
        49,
        49,
        49,
        40,
        0,
        18,
        32,
        10,
        40,
        22,
        48
      ],
      "weeklyRejected": [
        49,
        24,
        49,
        0,
        31,
        26,
        17,
        34,
        43,
        48,
        9,
        42,
        13,
        48,
        21,
        19,
        10,
        35,
        49,
        0,
        15,
        22,
        0,
        49,
        49,
        49,
        34,
        16,
        15,
        3,
        15,
        33,
        30,
        38,
        18,
        49,
        41,
        36,
        20,
        12,
        24,
        23,
        45,
        12,
        40,
        39,
        12,
        9,
        0,
        49,
        49,
        24
      ],
      "weeklyHold": [
        0,
        28,
        49,
        22,
        20,
        48,
        29,
        26,
        0,
        9,
        31,
        1,
        33,
        1,
        7,
        39,
        0,
        9,
        30,
        20,
        32,
        38,
        18,
        49,
        21,
        25,
        40,
        28,
        36,
        24,
        48,
        48,
        9,
        17,
        48,
        49,
        21,
        31,
        48,
        33,
        41,
        34,
        2,
        49,
        13,
        9,
        9,
        36,
        17,
        31,
        6,
        31
      ],
      "weeklyBackordered": [
        39,
        0,
        0,
        26,
        48,
        46,
        26,
        40,
        49,
        37,
        32,
        48,
        3,
        49,
        4,
        25,
        11,
        44,
        1,
        17,
        32,
        33,
        17,
        48,
        35,
        49,
        20,
        26,
        8,
        49,
        36,
        16,
        28,
        49,
        0,
        15,
        7,
        32,
        0,
        14,
        0,
        29,
        0,
        14,
        7,
        34,
        3,
        32,
        8,
        27,
        26,
        36
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        25,
        1,
        33,
        4,
        45,
        49,
        4,
        35,
        22,
        0,
        25,
        30,
        29,
        21,
        23,
        0,
        49,
        0,
        21,
        7,
        2,
        24,
        0,
        0,
        0,
        28,
        0,
        28,
        2,
        1,
        0,
        25,
        49,
        27,
        48,
        32,
        11,
        48,
        8,
        49,
        7,
        23,
        49,
        23,
        49,
        39,
        5,
        1,
        41,
        49,
        41,
        48
      ],
      "weeklyRejected": [
        49,
        25,
        49,
        47,
        49,
        0,
        31,
        49,
        48,
        47,
        0,
        34,
        0,
        18,
        17,
        0,
        0,
        49,
        44,
        0,
        24,
        49,
        49,
        3,
        0,
        49,
        45,
        41,
        27,
        35,
        2,
        47,
        15,
        27,
        0,
        49,
        49,
        14,
        15,
        0,
        48,
        30,
        0,
        34,
        43,
        9,
        0,
        42,
        17,
        49,
        17,
        23
      ],
      "weeklyHold": [
        1,
        6,
        48,
        6,
        22,
        43,
        49,
        26,
        16,
        49,
        47,
        9,
        40,
        20,
        1,
        18,
        0,
        35,
        43,
        36,
        36,
        29,
        49,
        34,
        31,
        21,
        37,
        29,
        33,
        2,
        48,
        28,
        30,
        29,
        12,
        49,
        14,
        0,
        3,
        19,
        16,
        21,
        0,
        39,
        46,
        49,
        17,
        49,
        14,
        29,
        7,
        47
      ],
      "weeklyBackordered": [
        24,
        0,
        14,
        13,
        46,
        18,
        17,
        14,
        9,
        2,
        49,
        37,
        11,
        49,
        47,
        3,
        1,
        42,
        20,
        4,
        49,
        49,
        5,
        36,
        15,
        49,
        49,
        35,
        39,
        49,
        13,
        32,
        22,
        18,
        34,
        13,
        49,
        7,
        22,
        22,
        21,
        48,
        0,
        41,
        13,
        13,
        31,
        25,
        9,
        31,
        11,
        1
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        0,
        23,
        14,
        3,
        20,
        6,
        41,
        49,
        43,
        29,
        13,
        0,
        14,
        49,
        18,
        17,
        48,
        9,
        16,
        26,
        22,
        14,
        49,
        11,
        29,
        13,
        29,
        46,
        11,
        49,
        8,
        29,
        40,
        0,
        27,
        31,
        47,
        6,
        49,
        49,
        6,
        29,
        40,
        49,
        49,
        2,
        21,
        0,
        33,
        49,
        14,
        6
      ],
      "weeklyRejected": [
        8,
        24,
        35,
        8,
        45,
        49,
        17,
        30,
        49,
        35,
        20,
        49,
        28,
        48,
        48,
        30,
        5,
        35,
        39,
        3,
        22,
        0,
        5,
        49,
        24,
        30,
        49,
        0,
        27,
        19,
        23,
        47,
        13,
        29,
        20,
        38,
        25,
        49,
        1,
        2,
        38,
        14,
        49,
        0,
        43,
        0,
        49,
        25,
        7,
        46,
        34,
        49
      ],
      "weeklyHold": [
        33,
        27,
        31,
        19,
        49,
        16,
        27,
        2,
        0,
        12,
        47,
        1,
        48,
        46,
        49,
        49,
        0,
        29,
        36,
        12,
        49,
        39,
        35,
        14,
        26,
        22,
        31,
        21,
        49,
        7,
        48,
        21,
        0,
        19,
        26,
        5,
        19,
        39,
        32,
        49,
        33,
        49,
        19,
        36,
        25,
        49,
        31,
        39,
        49,
        49,
        25,
        7
      ],
      "weeklyBackordered": [
        49,
        13,
        0,
        30,
        45,
        29,
        20,
        1,
        8,
        15,
        49,
        27,
        33,
        22,
        41,
        48,
        25,
        22,
        2,
        48,
        35,
        20,
        0,
        10,
        1,
        31,
        48,
        5,
        17,
        49,
        0,
        49,
        0,
        45,
        7,
        6,
        49,
        3,
        27,
        3,
        26,
        48,
        3,
        44,
        3,
        28,
        3,
        34,
        28,
        36,
        19,
        19
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        0,
        49,
        49,
        4,
        24,
        6,
        1,
        49,
        35,
        19,
        32,
        1,
        23,
        5,
        1,
        5,
        39,
        8,
        5,
        17,
        22,
        42,
        30,
        49,
        48,
        40,
        42,
        49,
        10,
        1,
        9,
        29,
        49,
        39,
        33,
        47,
        25,
        48,
        49,
        49,
        3,
        32,
        49,
        49,
        49,
        0,
        0,
        37,
        32,
        49,
        39,
        19
      ],
      "weeklyRejected": [
        33,
        39,
        49,
        47,
        8,
        26,
        0,
        30,
        40,
        48,
        40,
        31,
        1,
        9,
        2,
        49,
        12,
        46,
        3,
        21,
        34,
        5,
        39,
        45,
        12,
        43,
        39,
        22,
        44,
        36,
        7,
        29,
        26,
        28,
        27,
        49,
        19,
        49,
        1,
        18,
        10,
        12,
        21,
        37,
        42,
        13,
        12,
        19,
        14,
        11,
        22,
        49
      ],
      "weeklyHold": [
        1,
        0,
        16,
        26,
        12,
        29,
        43,
        20,
        0,
        25,
        32,
        39,
        15,
        49,
        11,
        10,
        15,
        19,
        0,
        49,
        0,
        31,
        10,
        10,
        13,
        29,
        19,
        22,
        20,
        8,
        16,
        2,
        2,
        39,
        22,
        37,
        31,
        19,
        49,
        26,
        1,
        26,
        0,
        5,
        36,
        30,
        21,
        46,
        0,
        30,
        38,
        40
      ],
      "weeklyBackordered": [
        0,
        25,
        5,
        21,
        43,
        46,
        32,
        1,
        23,
        36,
        17,
        45,
        11,
        25,
        9,
        26,
        49,
        49,
        12,
        9,
        35,
        4,
        31,
        9,
        48,
        15,
        37,
        39,
        18,
        29,
        0,
        49,
        17,
        0,
        0,
        0,
        25,
        16,
        42,
        32,
        19,
        48,
        7,
        49,
        33,
        28,
        49,
        49,
        26,
        46,
        37,
        3
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        49,
        44,
        31,
        0,
        8,
        49,
        17,
        17,
        48,
        0,
        46,
        8,
        20,
        22,
        30,
        49,
        46,
        0,
        14,
        49,
        36,
        44,
        32,
        24,
        14,
        37,
        0,
        28,
        29,
        21,
        37,
        0,
        49,
        22,
        46,
        17,
        38,
        48,
        21,
        49,
        32,
        49,
        49,
        36,
        44,
        31,
        47,
        23,
        0,
        49,
        26,
        0
      ],
      "weeklyRejected": [
        49,
        41,
        4,
        6,
        9,
        38,
        8,
        49,
        26,
        18,
        6,
        13,
        16,
        48,
        38,
        36,
        17,
        49,
        27,
        25,
        16,
        38,
        0,
        43,
        24,
        49,
        43,
        0,
        48,
        25,
        31,
        25,
        33,
        49,
        24,
        49,
        5,
        30,
        2,
        0,
        27,
        33,
        27,
        9,
        16,
        33,
        36,
        49,
        4,
        24,
        0,
        37
      ],
      "weeklyHold": [
        30,
        47,
        49,
        14,
        20,
        0,
        0,
        33,
        17,
        6,
        21,
        21,
        23,
        16,
        22,
        28,
        14,
        38,
        5,
        36,
        29,
        11,
        26,
        0,
        38,
        33,
        36,
        5,
        12,
        35,
        38,
        38,
        9,
        49,
        29,
        17,
        24,
        22,
        16,
        43,
        9,
        24,
        0,
        0,
        16,
        5,
        31,
        49,
        0,
        9,
        49,
        20
      ],
      "weeklyBackordered": [
        37,
        4,
        42,
        14,
        49,
        46,
        43,
        29,
        8,
        0,
        0,
        31,
        8,
        32,
        25,
        23,
        20,
        25,
        0,
        26,
        31,
        31,
        0,
        5,
        11,
        40,
        8,
        49,
        1,
        14,
        47,
        0,
        13,
        37,
        21,
        9,
        49,
        30,
        49,
        0,
        14,
        29,
        43,
        36,
        0,
        29,
        49,
        29,
        8,
        36,
        18,
        37
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        49,
        49,
        35,
        29,
        39,
        49,
        35,
        40,
        48,
        14,
        38,
        9,
        49,
        27,
        28,
        34,
        45,
        21,
        0,
        45,
        13,
        49,
        49,
        0,
        45,
        25,
        29,
        49,
        12,
        45,
        30,
        38,
        49,
        3,
        48,
        36,
        36,
        18,
        21,
        49,
        49,
        41,
        31,
        49,
        49,
        0,
        5,
        48,
        10,
        24,
        49,
        24
      ],
      "weeklyRejected": [
        43,
        15,
        49,
        6,
        49,
        0,
        22,
        18,
        30,
        47,
        38,
        27,
        30,
        10,
        48,
        2,
        17,
        49,
        0,
        0,
        37,
        0,
        5,
        31,
        1,
        39,
        49,
        9,
        48,
        0,
        49,
        18,
        24,
        21,
        6,
        34,
        49,
        35,
        1,
        48,
        24,
        34,
        49,
        33,
        49,
        39,
        18,
        11,
        3,
        24,
        6,
        44
      ],
      "weeklyHold": [
        21,
        14,
        39,
        19,
        0,
        47,
        12,
        49,
        0,
        34,
        26,
        49,
        13,
        16,
        34,
        6,
        0,
        48,
        30,
        26,
        33,
        49,
        17,
        21,
        49,
        43,
        47,
        13,
        30,
        24,
        48,
        12,
        30,
        41,
        46,
        49,
        49,
        25,
        31,
        19,
        3,
        30,
        14,
        27,
        4,
        26,
        17,
        23,
        0,
        38,
        0,
        49
      ],
      "weeklyBackordered": [
        0,
        21,
        29,
        49,
        18,
        40,
        25,
        30,
        24,
        17,
        47,
        14,
        45,
        49,
        32,
        46,
        1,
        49,
        0,
        38,
        21,
        0,
        0,
        43,
        39,
        12,
        49,
        0,
        13,
        48,
        40,
        11,
        0,
        0,
        8,
        0,
        4,
        37,
        0,
        25,
        0,
        40,
        27,
        46,
        17,
        36,
        28,
        22,
        49,
        31,
        22,
        29
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        2,
        49,
        49,
        9,
        23,
        25,
        7,
        2,
        41,
        0,
        49,
        19,
        44,
        24,
        25,
        33,
        24,
        0,
        0,
        46,
        19,
        47,
        16,
        49,
        3,
        47,
        38,
        33,
        23,
        35,
        18,
        0,
        38,
        30,
        4,
        39,
        37,
        42,
        49,
        49,
        25,
        37,
        34,
        30,
        49,
        15,
        8,
        48,
        33,
        49,
        3,
        36
      ],
      "weeklyRejected": [
        28,
        26,
        13,
        5,
        18,
        0,
        49,
        33,
        37,
        15,
        42,
        0,
        0,
        33,
        31,
        0,
        31,
        49,
        27,
        37,
        30,
        41,
        10,
        0,
        31,
        19,
        21,
        37,
        48,
        0,
        32,
        17,
        46,
        17,
        36,
        49,
        13,
        0,
        1,
        18,
        27,
        14,
        49,
        3,
        49,
        33,
        12,
        2,
        2,
        24,
        0,
        47
      ],
      "weeklyHold": [
        22,
        32,
        49,
        27,
        5,
        7,
        18,
        20,
        0,
        37,
        35,
        1,
        29,
        28,
        49,
        49,
        13,
        6,
        23,
        14,
        15,
        11,
        30,
        0,
        49,
        13,
        24,
        49,
        5,
        1,
        48,
        0,
        0,
        21,
        48,
        13,
        25,
        24,
        48,
        45,
        0,
        25,
        25,
        36,
        24,
        19,
        28,
        49,
        0,
        10,
        0,
        26
      ],
      "weeklyBackordered": [
        22,
        30,
        0,
        11,
        49,
        46,
        43,
        24,
        24,
        34,
        0,
        49,
        3,
        49,
        49,
        20,
        49,
        49,
        26,
        41,
        22,
        39,
        34,
        0,
        1,
        25,
        16,
        7,
        49,
        21,
        11,
        29,
        19,
        34,
        49,
        16,
        5,
        30,
        27,
        40,
        0,
        17,
        15,
        49,
        13,
        0,
        29,
        5,
        0,
        0,
        30,
        12
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        0,
        49,
        23,
        0,
        49,
        0,
        27,
        31,
        48,
        0,
        49,
        0,
        18,
        49,
        12,
        21,
        49,
        7,
        0,
        49,
        23,
        29,
        22,
        0,
        0,
        47,
        28,
        33,
        15,
        19,
        0,
        35,
        24,
        31,
        13,
        49,
        23,
        10,
        28,
        1,
        23,
        36,
        48,
        44,
        49,
        21,
        17,
        49,
        17,
        16,
        25,
        22
      ],
      "weeklyRejected": [
        49,
        17,
        7,
        1,
        49,
        20,
        5,
        3,
        13,
        48,
        49,
        23,
        23,
        9,
        23,
        49,
        28,
        49,
        48,
        49,
        0,
        49,
        29,
        6,
        32,
        35,
        16,
        38,
        21,
        14,
        0,
        32,
        5,
        49,
        0,
        18,
        37,
        38,
        10,
        0,
        17,
        6,
        49,
        17,
        32,
        40,
        7,
        37,
        0,
        28,
        0,
        32
      ],
      "weeklyHold": [
        47,
        11,
        24,
        22,
        0,
        35,
        49,
        3,
        17,
        18,
        47,
        9,
        1,
        34,
        25,
        33,
        16,
        31,
        18,
        49,
        12,
        29,
        49,
        17,
        0,
        45,
        39,
        41,
        27,
        20,
        46,
        0,
        38,
        11,
        10,
        49,
        0,
        15,
        49,
        13,
        35,
        21,
        10,
        28,
        42,
        43,
        25,
        4,
        0,
        24,
        31,
        35
      ],
      "weeklyBackordered": [
        29,
        20,
        31,
        13,
        27,
        22,
        47,
        5,
        41,
        6,
        21,
        25,
        49,
        41,
        12,
        17,
        49,
        46,
        37,
        16,
        49,
        28,
        28,
        8,
        25,
        19,
        32,
        12,
        34,
        31,
        0,
        49,
        13,
        48,
        14,
        14,
        3,
        9,
        43,
        15,
        11,
        32,
        0,
        49,
        41,
        41,
        33,
        45,
        0,
        27,
        8,
        1
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyAccepted": [
        0,
        44,
        16,
        13,
        13,
        0,
        11,
        49,
        48,
        10,
        34,
        8,
        25,
        49,
        36,
        13,
        49,
        11,
        16,
        41,
        0,
        31,
        49,
        49,
        14,
        30,
        14,
        7,
        9,
        33,
        32,
        0,
        49,
        4,
        48,
        13,
        25,
        48,
        37,
        34,
        7,
        34,
        49,
        45,
        47,
        0,
        5,
        46,
        16,
        27,
        8,
        33
      ],
      "weeklyRejected": [
        49,
        25,
        49,
        11,
        16,
        0,
        11,
        25,
        28,
        27,
        16,
        20,
        47,
        38,
        37,
        49,
        28,
        49,
        49,
        0,
        8,
        13,
        31,
        49,
        49,
        0,
        13,
        35,
        48,
        0,
        16,
        10,
        48,
        0,
        35,
        39,
        6,
        49,
        1,
        30,
        35,
        34,
        40,
        0,
        0,
        36,
        23,
        0,
        0,
        0,
        12,
        39
      ],
      "weeklyHold": [
        28,
        49,
        49,
        1,
        22,
        29,
        6,
        2,
        0,
        9,
        32,
        16,
        1,
        9,
        12,
        49,
        0,
        12,
        34,
        0,
        47,
        18,
        11,
        49,
        15,
        48,
        20,
        21,
        49,
        47,
        48,
        14,
        18,
        20,
        45,
        39,
        41,
        0,
        46,
        35,
        49,
        49,
        32,
        15,
        49,
        31,
        0,
        5,
        7,
        19,
        49,
        31
      ],
      "weeklyBackordered": [
        26,
        48,
        11,
        4,
        20,
        46,
        49,
        1,
        18,
        9,
        37,
        47,
        19,
        42,
        6,
        49,
        49,
        49,
        0,
        0,
        48,
        36,
        12,
        24,
        1,
        44,
        4,
        15,
        20,
        49,
        49,
        7,
        35,
        37,
        0,
        11,
        1,
        10,
        21,
        36,
        0,
        20,
        0,
        42,
        25,
        37,
        0,
        46,
        0,
        39,
        39,
        38
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        3,
        29,
        24,
        48,
        40,
        0,
        6,
        10,
        41,
        40,
        49,
        10,
        43,
        49,
        4,
        23,
        48,
        0,
        0,
        26,
        0,
        16,
        37,
        16,
        44,
        38,
        0,
        2,
        15,
        28,
        33,
        0,
        49,
        18,
        48,
        11,
        29,
        48,
        49,
        49,
        0,
        31,
        49,
        39,
        49,
        45,
        15,
        27,
        0,
        19,
        40,
        48
      ],
      "weeklyRejected": [
        49,
        11,
        31,
        27,
        33,
        0,
        32,
        36,
        39,
        21,
        47,
        29,
        49,
        48,
        26,
        36,
        49,
        49,
        0,
        49,
        13,
        49,
        45,
        21,
        24,
        45,
        22,
        10,
        27,
        7,
        8,
        27,
        42,
        49,
        0,
        49,
        26,
        21,
        1,
        22,
        16,
        0,
        39,
        7,
        47,
        25,
        11,
        36,
        0,
        39,
        18,
        23
      ],
      "weeklyHold": [
        0,
        37,
        37,
        25,
        24,
        45,
        15,
        24,
        14,
        9,
        43,
        45,
        48,
        28,
        39,
        17,
        10,
        6,
        38,
        49,
        12,
        8,
        35,
        16,
        38,
        39,
        44,
        0,
        3,
        18,
        48,
        6,
        0,
        9,
        0,
        11,
        25,
        0,
        1,
        25,
        40,
        34,
        10,
        4,
        38,
        30,
        14,
        12,
        0,
        49,
        5,
        49
      ],
      "weeklyBackordered": [
        38,
        24,
        11,
        43,
        40,
        0,
        49,
        25,
        49,
        0,
        13,
        46,
        21,
        24,
        9,
        32,
        49,
        18,
        21,
        25,
        48,
        26,
        0,
        0,
        1,
        15,
        1,
        0,
        43,
        49,
        10,
        49,
        36,
        26,
        0,
        10,
        34,
        17,
        29,
        49,
        34,
        48,
        0,
        49,
        0,
        49,
        38,
        14,
        0,
        38,
        24,
        1
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        27,
        37,
        8,
        22,
        4,
        8,
        8,
        33,
        8,
        35,
        34,
        0,
        49,
        2,
        21,
        49,
        49,
        20,
        5,
        14,
        11,
        30,
        33,
        31,
        48,
        29,
        19,
        6,
        16,
        40,
        28,
        0,
        49,
        0,
        27,
        49,
        29,
        25,
        48,
        49,
        5,
        0,
        49,
        34,
        49,
        37,
        0,
        38,
        34,
        49,
        15,
        0
      ],
      "weeklyRejected": [
        49,
        20,
        42,
        36,
        41,
        14,
        16,
        36,
        11,
        1,
        28,
        25,
        40,
        42,
        6,
        33,
        49,
        49,
        21,
        20,
        38,
        6,
        26,
        19,
        49,
        0,
        19,
        31,
        44,
        7,
        4,
        37,
        42,
        49,
        0,
        49,
        0,
        13,
        1,
        17,
        16,
        10,
        0,
        12,
        25,
        14,
        10,
        7,
        29,
        40,
        0,
        14
      ],
      "weeklyHold": [
        0,
        49,
        37,
        17,
        42,
        25,
        20,
        6,
        22,
        29,
        22,
        1,
        10,
        29,
        49,
        5,
        49,
        40,
        23,
        49,
        30,
        10,
        43,
        10,
        20,
        48,
        33,
        49,
        29,
        0,
        37,
        16,
        0,
        0,
        46,
        33,
        30,
        0,
        17,
        32,
        49,
        25,
        19,
        27,
        45,
        20,
        9,
        37,
        20,
        49,
        33,
        0
      ],
      "weeklyBackordered": [
        34,
        11,
        0,
        5,
        49,
        15,
        49,
        24,
        47,
        9,
        14,
        17,
        42,
        33,
        44,
        45,
        7,
        49,
        27,
        49,
        38,
        25,
        17,
        10,
        15,
        17,
        16,
        32,
        45,
        49,
        15,
        21,
        47,
        25,
        0,
        0,
        1,
        0,
        33,
        3,
        37,
        48,
        0,
        49,
        0,
        49,
        20,
        23,
        28,
        0,
        32,
        14
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        4,
        16,
        3,
        3,
        27,
        5,
        9,
        6,
        48,
        13,
        49,
        33,
        10,
        48,
        1,
        49,
        27,
        0,
        16,
        19,
        2,
        30,
        29,
        49,
        10,
        47,
        0,
        12,
        29,
        26,
        24,
        14,
        31,
        22,
        48,
        32,
        23,
        48,
        10,
        49,
        41,
        5,
        46,
        49,
        49,
        0,
        0,
        4,
        11,
        24,
        16,
        0
      ],
      "weeklyRejected": [
        27,
        35,
        26,
        34,
        0,
        9,
        40,
        49,
        28,
        13,
        49,
        1,
        49,
        21,
        23,
        8,
        14,
        49,
        30,
        29,
        27,
        34,
        30,
        49,
        18,
        49,
        41,
        28,
        36,
        30,
        15,
        41,
        31,
        34,
        47,
        31,
        30,
        49,
        21,
        26,
        38,
        44,
        22,
        0,
        17,
        23,
        0,
        45,
        40,
        39,
        0,
        49
      ],
      "weeklyHold": [
        45,
        10,
        49,
        20,
        13,
        4,
        19,
        30,
        44,
        20,
        39,
        12,
        43,
        49,
        0,
        0,
        11,
        19,
        4,
        45,
        36,
        49,
        15,
        45,
        33,
        14,
        7,
        42,
        48,
        6,
        48,
        23,
        17,
        20,
        40,
        12,
        30,
        21,
        39,
        44,
        31,
        9,
        32,
        49,
        32,
        24,
        13,
        5,
        13,
        44,
        27,
        0
      ],
      "weeklyBackordered": [
        5,
        19,
        15,
        49,
        30,
        39,
        49,
        49,
        8,
        17,
        14,
        20,
        3,
        0,
        33,
        6,
        49,
        28,
        25,
        38,
        49,
        18,
        47,
        15,
        11,
        18,
        23,
        42,
        1,
        47,
        0,
        38,
        9,
        49,
        49,
        0,
        38,
        15,
        36,
        30,
        48,
        43,
        4,
        49,
        30,
        7,
        14,
        16,
        1,
        49,
        27,
        27
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        21,
        29,
        49,
        10,
        15,
        0,
        46,
        48,
        31,
        0,
        49,
        27,
        47,
        30,
        21,
        14,
        49,
        0,
        26,
        0,
        31,
        39,
        47,
        17,
        34,
        34,
        21,
        44,
        7,
        49,
        27,
        0,
        41,
        21,
        46,
        23,
        47,
        45,
        29,
        35,
        2,
        13,
        49,
        39,
        34,
        28,
        5,
        49,
        26,
        22,
        1,
        20
      ],
      "weeklyRejected": [
        39,
        0,
        31,
        47,
        37,
        5,
        0,
        15,
        20,
        1,
        37,
        0,
        18,
        48,
        15,
        29,
        0,
        40,
        3,
        38,
        15,
        36,
        43,
        27,
        37,
        47,
        49,
        14,
        47,
        20,
        18,
        45,
        48,
        20,
        46,
        43,
        49,
        30,
        20,
        24,
        4,
        38,
        46,
        0,
        41,
        34,
        17,
        29,
        0,
        5,
        4,
        49
      ],
      "weeklyHold": [
        0,
        36,
        31,
        25,
        23,
        43,
        38,
        32,
        41,
        22,
        32,
        3,
        24,
        37,
        5,
        12,
        14,
        5,
        31,
        39,
        0,
        4,
        27,
        33,
        21,
        48,
        13,
        14,
        42,
        0,
        16,
        6,
        22,
        29,
        25,
        3,
        42,
        30,
        49,
        15,
        25,
        35,
        13,
        39,
        48,
        0,
        25,
        3,
        17,
        4,
        49,
        24
      ],
      "weeklyBackordered": [
        41,
        49,
        26,
        28,
        45,
        22,
        37,
        1,
        8,
        4,
        2,
        40,
        4,
        43,
        42,
        29,
        34,
        49,
        0,
        20,
        40,
        33,
        22,
        12,
        31,
        16,
        27,
        0,
        45,
        18,
        36,
        45,
        46,
        23,
        28,
        0,
        16,
        0,
        13,
        26,
        17,
        38,
        24,
        27,
        24,
        20,
        24,
        11,
        27,
        49,
        42,
        49
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        38,
        46,
        32,
        22,
        10,
        32,
        26,
        26,
        5,
        28,
        40,
        0,
        13,
        11,
        6,
        12,
        49,
        9,
        0,
        44,
        18,
        25,
        8,
        2,
        49,
        20,
        34,
        0,
        49,
        49,
        49,
        16,
        49,
        27,
        48,
        22,
        28,
        48,
        14,
        49,
        25,
        13,
        49,
        16,
        40,
        0,
        0,
        14,
        0,
        11,
        10,
        18
      ],
      "weeklyRejected": [
        49,
        1,
        49,
        0,
        49,
        42,
        46,
        30,
        49,
        1,
        40,
        33,
        35,
        48,
        48,
        25,
        49,
        10,
        25,
        0,
        9,
        48,
        21,
        31,
        24,
        27,
        41,
        40,
        28,
        32,
        49,
        26,
        21,
        49,
        46,
        49,
        48,
        41,
        1,
        33,
        6,
        47,
        15,
        0,
        36,
        16,
        7,
        9,
        48,
        49,
        19,
        7
      ],
      "weeklyHold": [
        36,
        0,
        49,
        19,
        49,
        16,
        26,
        6,
        0,
        16,
        26,
        9,
        4,
        2,
        44,
        17,
        17,
        41,
        27,
        0,
        0,
        15,
        49,
        38,
        18,
        31,
        6,
        0,
        49,
        49,
        48,
        0,
        1,
        49,
        6,
        43,
        22,
        0,
        16,
        0,
        10,
        49,
        0,
        24,
        49,
        33,
        45,
        49,
        0,
        7,
        1,
        7
      ],
      "weeklyBackordered": [
        17,
        17,
        28,
        30,
        49,
        46,
        32,
        49,
        31,
        2,
        12,
        39,
        49,
        1,
        49,
        36,
        7,
        19,
        0,
        20,
        14,
        48,
        8,
        1,
        16,
        33,
        1,
        48,
        49,
        39,
        3,
        19,
        32,
        47,
        0,
        0,
        14,
        8,
        19,
        20,
        48,
        48,
        19,
        15,
        0,
        13,
        28,
        3,
        19,
        49,
        41,
        49
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyAccepted": [
        31,
        27,
        24,
        20,
        1,
        0,
        20,
        31,
        17,
        20,
        42,
        1,
        42,
        10,
        1,
        49,
        49,
        10,
        26,
        22,
        23,
        25,
        4,
        15,
        9,
        15,
        20,
        38,
        1,
        49,
        12,
        2,
        32,
        46,
        48,
        29,
        44,
        25,
        21,
        49,
        42,
        29,
        29,
        43,
        30,
        31,
        0,
        49,
        0,
        20,
        11,
        0
      ],
      "weeklyRejected": [
        49,
        13,
        14,
        30,
        25,
        14,
        19,
        41,
        49,
        1,
        0,
        49,
        49,
        0,
        30,
        49,
        24,
        44,
        4,
        0,
        31,
        17,
        24,
        34,
        26,
        49,
        0,
        31,
        43,
        17,
        11,
        41,
        0,
        44,
        0,
        45,
        45,
        26,
        15,
        11,
        21,
        40,
        46,
        17,
        46,
        49,
        0,
        49,
        0,
        29,
        16,
        29
      ],
      "weeklyHold": [
        0,
        0,
        49,
        1,
        8,
        29,
        26,
        17,
        7,
        39,
        18,
        49,
        48,
        39,
        39,
        3,
        25,
        15,
        43,
        0,
        36,
        24,
        13,
        49,
        9,
        48,
        35,
        13,
        49,
        0,
        39,
        34,
        13,
        18,
        33,
        40,
        35,
        49,
        39,
        30,
        19,
        33,
        0,
        8,
        42,
        12,
        6,
        43,
        0,
        27,
        44,
        0
      ],
      "weeklyBackordered": [
        28,
        48,
        15,
        7,
        20,
        28,
        0,
        25,
        49,
        48,
        49,
        14,
        8,
        47,
        17,
        49,
        3,
        29,
        16,
        40,
        49,
        0,
        8,
        18,
        22,
        4,
        47,
        40,
        30,
        49,
        34,
        20,
        18,
        41,
        4,
        0,
        19,
        48,
        0,
        0,
        10,
        35,
        49,
        34,
        19,
        36,
        23,
        37,
        0,
        13,
        27,
        13
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        0,
        49,
        28,
        0,
        14,
        3,
        9,
        24,
        35,
        12,
        42,
        0,
        49,
        49,
        18,
        0,
        33,
        20,
        49,
        0,
        23,
        49,
        49,
        35,
        0,
        47,
        32,
        26,
        9,
        1,
        6,
        49,
        40,
        9,
        48,
        40,
        34,
        21,
        49,
        25,
        49,
        18,
        49,
        12,
        49,
        1,
        16,
        3,
        39,
        14,
        35,
        1
      ],
      "weeklyRejected": [
        23,
        4,
        11,
        14,
        22,
        0,
        34,
        35,
        49,
        24,
        1,
        47,
        8,
        25,
        29,
        27,
        11,
        49,
        23,
        9,
        0,
        48,
        23,
        13,
        0,
        49,
        2,
        13,
        27,
        40,
        29,
        20,
        0,
        26,
        16,
        49,
        49,
        19,
        19,
        47,
        47,
        0,
        49,
        0,
        41,
        49,
        22,
        15,
        0,
        32,
        17,
        36
      ],
      "weeklyHold": [
        36,
        18,
        0,
        15,
        6,
        0,
        15,
        2,
        21,
        37,
        17,
        29,
        48,
        9,
        30,
        0,
        4,
        20,
        0,
        37,
        18,
        0,
        0,
        49,
        0,
        1,
        41,
        26,
        22,
        0,
        48,
        37,
        0,
        33,
        20,
        36,
        9,
        28,
        25,
        10,
        33,
        34,
        16,
        38,
        15,
        38,
        0,
        5,
        1,
        21,
        37,
        7
      ],
      "weeklyBackordered": [
        24,
        0,
        26,
        12,
        49,
        46,
        37,
        9,
        27,
        49,
        18,
        29,
        10,
        0,
        0,
        1,
        49,
        42,
        7,
        18,
        19,
        30,
        25,
        40,
        31,
        22,
        35,
        48,
        1,
        12,
        35,
        47,
        1,
        27,
        0,
        19,
        49,
        0,
        36,
        29,
        9,
        48,
        8,
        49,
        49,
        28,
        49,
        24,
        0,
        31,
        32,
        40
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        0,
        49,
        33,
        22,
        36,
        0,
        31,
        29,
        28,
        24,
        34,
        1,
        49,
        45,
        14,
        45,
        44,
        4,
        0,
        21,
        13,
        24,
        30,
        41,
        42,
        36,
        28,
        18,
        24,
        49,
        0,
        9,
        13,
        7,
        0,
        26,
        5,
        48,
        23,
        27,
        18,
        0,
        49,
        47,
        49,
        0,
        0,
        40,
        32,
        22,
        31,
        31
      ],
      "weeklyRejected": [
        14,
        27,
        2,
        47,
        35,
        43,
        46,
        32,
        34,
        41,
        29,
        42,
        8,
        48,
        25,
        3,
        27,
        42,
        20,
        30,
        49,
        0,
        49,
        10,
        24,
        31,
        49,
        29,
        21,
        0,
        22,
        47,
        0,
        49,
        28,
        49,
        4,
        41,
        6,
        49,
        38,
        13,
        0,
        12,
        49,
        16,
        8,
        48,
        15,
        44,
        0,
        44
      ],
      "weeklyHold": [
        24,
        23,
        29,
        19,
        30,
        17,
        41,
        35,
        32,
        19,
        42,
        13,
        27,
        44,
        33,
        0,
        15,
        32,
        0,
        41,
        26,
        0,
        15,
        0,
        19,
        27,
        35,
        44,
        48,
        0,
        43,
        0,
        9,
        39,
        0,
        25,
        2,
        0,
        40,
        30,
        35,
        38,
        7,
        0,
        9,
        49,
        0,
        0,
        0,
        3,
        4,
        1
      ],
      "weeklyBackordered": [
        1,
        49,
        0,
        49,
        49,
        46,
        49,
        5,
        40,
        1,
        49,
        46,
        3,
        0,
        18,
        21,
        47,
        18,
        14,
        28,
        26,
        12,
        0,
        0,
        1,
        17,
        30,
        17,
        37,
        49,
        15,
        49,
        48,
        12,
        8,
        0,
        7,
        0,
        49,
        15,
        39,
        48,
        0,
        39,
        15,
        38,
        29,
        49,
        40,
        10,
        42,
        34
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyAccepted": [
        15,
        35,
        15,
        35,
        5,
        22,
        30,
        25,
        48,
        43,
        20,
        4,
        32,
        34,
        27,
        49,
        42,
        6,
        0,
        49,
        0,
        7,
        26,
        49,
        1,
        14,
        39,
        36,
        28,
        30,
        15,
        0,
        34,
        1,
        33,
        8,
        0,
        20,
        13,
        43,
        0,
        42,
        49,
        22,
        22,
        1,
        39,
        49,
        6,
        30,
        36,
        41
      ],
      "weeklyRejected": [
        23,
        24,
        31,
        31,
        18,
        17,
        13,
        49,
        12,
        21,
        30,
        37,
        35,
        39,
        19,
        14,
        0,
        49,
        32,
        31,
        32,
        9,
        2,
        7,
        32,
        49,
        32,
        5,
        27,
        0,
        36,
        6,
        48,
        27,
        0,
        17,
        44,
        37,
        12,
        27,
        46,
        47,
        49,
        0,
        39,
        0,
        3,
        22,
        9,
        49,
        0,
        34
      ],
      "weeklyHold": [
        13,
        15,
        32,
        1,
        6,
        16,
        3,
        2,
        0,
        35,
        47,
        25,
        48,
        1,
        26,
        0,
        14,
        24,
        32,
        3,
        45,
        0,
        24,
        0,
        41,
        40,
        21,
        39,
        25,
        3,
        34,
        0,
        14,
        11,
        17,
        5,
        16,
        0,
        49,
        30,
        0,
        19,
        33,
        5,
        22,
        49,
        42,
        49,
        0,
        1,
        30,
        34
      ],
      "weeklyBackordered": [
        27,
        37,
        25,
        0,
        49,
        40,
        36,
        32,
        37,
        0,
        49,
        49,
        3,
        39,
        15,
        37,
        6,
        49,
        0,
        1,
        49,
        31,
        7,
        29,
        1,
        18,
        19,
        11,
        10,
        31,
        22,
        16,
        22,
        37,
        8,
        36,
        1,
        4,
        33,
        12,
        18,
        48,
        30,
        49,
        21,
        21,
        36,
        30,
        31,
        43,
        27,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyAccepted": [
        7,
        46,
        24,
        38,
        29,
        31,
        1,
        49,
        48,
        44,
        49,
        28,
        49,
        0,
        5,
        2,
        28,
        14,
        32,
        12,
        4,
        44,
        28,
        49,
        31,
        35,
        29,
        35,
        23,
        20,
        3,
        0,
        49,
        37,
        40,
        17,
        29,
        15,
        49,
        49,
        16,
        7,
        49,
        26,
        49,
        0,
        7,
        33,
        49,
        48,
        17,
        4
      ],
      "weeklyRejected": [
        40,
        25,
        49,
        47,
        30,
        44,
        21,
        41,
        3,
        5,
        44,
        40,
        16,
        43,
        37,
        5,
        20,
        49,
        15,
        12,
        8,
        26,
        28,
        25,
        31,
        41,
        34,
        24,
        40,
        18,
        37,
        47,
        39,
        49,
        0,
        33,
        49,
        25,
        22,
        35,
        34,
        49,
        19,
        36,
        42,
        9,
        1,
        0,
        21,
        49,
        15,
        35
      ],
      "weeklyHold": [
        0,
        47,
        49,
        18,
        19,
        19,
        5,
        14,
        27,
        21,
        16,
        38,
        26,
        34,
        49,
        19,
        0,
        34,
        0,
        0,
        49,
        20,
        47,
        2,
        38,
        1,
        0,
        25,
        13,
        49,
        48,
        48,
        0,
        0,
        43,
        0,
        24,
        27,
        10,
        14,
        17,
        16,
        0,
        15,
        48,
        29,
        0,
        17,
        28,
        23,
        2,
        40
      ],
      "weeklyBackordered": [
        10,
        0,
        19,
        25,
        0,
        46,
        19,
        35,
        40,
        1,
        49,
        33,
        3,
        49,
        48,
        33,
        19,
        49,
        44,
        30,
        6,
        10,
        0,
        39,
        21,
        25,
        1,
        49,
        45,
        15,
        7,
        13,
        45,
        10,
        24,
        36,
        11,
        0,
        9,
        29,
        0,
        24,
        14,
        20,
        12,
        32,
        49,
        15,
        25,
        33,
        20,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        14,
        43,
        29,
        26,
        49,
        19,
        1,
        12,
        48,
        49,
        37,
        6,
        35,
        20,
        34,
        26,
        17,
        3,
        2,
        7,
        41,
        45,
        49,
        40,
        28,
        47,
        20,
        21,
        21,
        49,
        0,
        28,
        49,
        27,
        25,
        17,
        29,
        12,
        48,
        20,
        15,
        0,
        49,
        0,
        25,
        18,
        41,
        4,
        23,
        48,
        17,
        48
      ],
      "weeklyRejected": [
        34,
        7,
        36,
        30,
        0,
        0,
        31,
        49,
        14,
        48,
        49,
        0,
        39,
        48,
        29,
        27,
        32,
        35,
        46,
        27,
        11,
        0,
        49,
        24,
        25,
        21,
        15,
        36,
        45,
        12,
        15,
        27,
        33,
        27,
        11,
        49,
        20,
        29,
        1,
        25,
        26,
        34,
        26,
        10,
        24,
        12,
        32,
        9,
        3,
        49,
        0,
        29
      ],
      "weeklyHold": [
        31,
        0,
        13,
        1,
        49,
        39,
        0,
        14,
        28,
        0,
        25,
        15,
        22,
        36,
        11,
        47,
        41,
        24,
        19,
        0,
        18,
        49,
        23,
        26,
        24,
        48,
        3,
        0,
        38,
        23,
        48,
        0,
        0,
        35,
        26,
        38,
        0,
        0,
        1,
        10,
        13,
        49,
        32,
        49,
        49,
        1,
        7,
        17,
        0,
        32,
        17,
        31
      ],
      "weeklyBackordered": [
        17,
        0,
        37,
        0,
        49,
        27,
        33,
        49,
        48,
        26,
        10,
        0,
        3,
        49,
        4,
        27,
        49,
        23,
        0,
        25,
        42,
        20,
        0,
        48,
        30,
        12,
        43,
        37,
        34,
        34,
        27,
        18,
        30,
        20,
        26,
        0,
        3,
        0,
        31,
        38,
        0,
        25,
        36,
        17,
        46,
        25,
        34,
        39,
        0,
        49,
        43,
        13
      ]
    },
    {
      "id": "049BCC88",
      "weeklyAccepted": [
        13,
        42,
        31,
        40,
        19,
        0,
        32,
        43,
        25,
        35,
        27,
        20,
        27,
        48,
        15,
        20,
        49,
        0,
        15,
        30,
        14,
        31,
        39,
        10,
        17,
        30,
        17,
        29,
        49,
        31,
        45,
        23,
        29,
        3,
        28,
        2,
        11,
        24,
        4,
        49,
        10,
        38,
        49,
        14,
        49,
        34,
        14,
        26,
        0,
        5,
        49,
        22
      ],
      "weeklyRejected": [
        49,
        33,
        25,
        7,
        32,
        3,
        0,
        17,
        27,
        25,
        38,
        16,
        34,
        18,
        2,
        27,
        42,
        7,
        10,
        24,
        21,
        40,
        0,
        5,
        24,
        3,
        0,
        35,
        43,
        19,
        13,
        21,
        45,
        23,
        5,
        30,
        47,
        49,
        10,
        31,
        18,
        23,
        18,
        0,
        26,
        31,
        9,
        44,
        5,
        49,
        13,
        23
      ],
      "weeklyHold": [
        15,
        15,
        49,
        22,
        10,
        39,
        21,
        2,
        10,
        30,
        19,
        49,
        31,
        31,
        22,
        39,
        2,
        48,
        22,
        14,
        23,
        25,
        16,
        30,
        23,
        33,
        32,
        16,
        20,
        3,
        48,
        0,
        5,
        41,
        48,
        9,
        18,
        12,
        5,
        26,
        20,
        32,
        11,
        49,
        49,
        41,
        0,
        38,
        5,
        49,
        20,
        25
      ],
      "weeklyBackordered": [
        16,
        49,
        0,
        18,
        28,
        19,
        36,
        36,
        31,
        12,
        32,
        11,
        17,
        0,
        19,
        41,
        49,
        40,
        25,
        49,
        32,
        14,
        0,
        33,
        21,
        4,
        30,
        2,
        35,
        33,
        0,
        35,
        19,
        13,
        29,
        0,
        26,
        27,
        37,
        20,
        19,
        48,
        8,
        49,
        0,
        43,
        33,
        14,
        16,
        49,
        31,
        14
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        16,
        40,
        17,
        0,
        41,
        0,
        25,
        25,
        30,
        46,
        25,
        0,
        12,
        0,
        18,
        38,
        20,
        39,
        0,
        0,
        0,
        13,
        49,
        36,
        22,
        29,
        39,
        0,
        44,
        40,
        22,
        13,
        49,
        24,
        24,
        27,
        25,
        20,
        0,
        10,
        36,
        19,
        49,
        15,
        49,
        25,
        0,
        33,
        12,
        15,
        40,
        35
      ],
      "weeklyRejected": [
        33,
        49,
        14,
        40,
        2,
        41,
        35,
        2,
        0,
        36,
        0,
        28,
        47,
        48,
        27,
        18,
        15,
        17,
        49,
        48,
        49,
        18,
        6,
        24,
        34,
        40,
        25,
        20,
        48,
        37,
        13,
        47,
        36,
        49,
        1,
        49,
        34,
        42,
        34,
        37,
        32,
        48,
        16,
        40,
        44,
        26,
        28,
        48,
        18,
        28,
        13,
        12
      ],
      "weeklyHold": [
        37,
        23,
        33,
        24,
        18,
        21,
        30,
        2,
        49,
        49,
        47,
        31,
        17,
        31,
        14,
        27,
        8,
        48,
        4,
        41,
        0,
        3,
        0,
        0,
        31,
        46,
        17,
        12,
        49,
        3,
        36,
        7,
        0,
        49,
        26,
        31,
        33,
        43,
        18,
        0,
        34,
        29,
        49,
        49,
        40,
        49,
        18,
        24,
        10,
        8,
        49,
        49
      ],
      "weeklyBackordered": [
        6,
        30,
        24,
        44,
        44,
        37,
        45,
        42,
        34,
        24,
        43,
        20,
        36,
        12,
        32,
        17,
        38,
        49,
        0,
        44,
        24,
        0,
        16,
        12,
        48,
        3,
        23,
        46,
        36,
        49,
        0,
        34,
        48,
        49,
        15,
        0,
        20,
        0,
        42,
        1,
        48,
        48,
        0,
        49,
        7,
        21,
        49,
        0,
        31,
        3,
        47,
        29
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        27,
        40,
        49,
        0,
        38,
        11,
        1,
        49,
        10,
        0,
        49,
        0,
        16,
        33,
        44,
        49,
        2,
        0,
        42,
        24,
        49,
        29,
        30,
        27,
        0,
        45,
        23,
        9,
        48,
        15,
        0,
        44,
        26,
        2,
        24,
        49,
        32,
        2,
        4,
        31,
        39,
        0,
        24,
        5,
        46,
        0,
        7,
        32,
        16,
        36,
        49,
        0
      ],
      "weeklyRejected": [
        49,
        3,
        12,
        19,
        41,
        0,
        46,
        16,
        26,
        8,
        33,
        49,
        25,
        41,
        43,
        4,
        21,
        5,
        24,
        10,
        49,
        49,
        49,
        49,
        20,
        13,
        40,
        10,
        22,
        30,
        38,
        47,
        16,
        10,
        20,
        49,
        30,
        43,
        1,
        37,
        48,
        0,
        34,
        34,
        42,
        49,
        14,
        49,
        0,
        26,
        40,
        49
      ],
      "weeklyHold": [
        19,
        25,
        12,
        44,
        49,
        47,
        35,
        4,
        39,
        37,
        25,
        3,
        21,
        17,
        20,
        13,
        42,
        0,
        0,
        9,
        29,
        15,
        0,
        49,
        0,
        34,
        49,
        0,
        33,
        0,
        48,
        12,
        0,
        16,
        48,
        45,
        15,
        7,
        37,
        15,
        40,
        35,
        0,
        10,
        38,
        27,
        37,
        0,
        1,
        20,
        0,
        37
      ],
      "weeklyBackordered": [
        26,
        21,
        17,
        49,
        49,
        0,
        12,
        10,
        5,
        23,
        48,
        16,
        49,
        12,
        24,
        1,
        37,
        1,
        17,
        25,
        0,
        14,
        21,
        31,
        26,
        44,
        49,
        13,
        1,
        29,
        32,
        18,
        48,
        29,
        10,
        0,
        49,
        16,
        10,
        26,
        13,
        46,
        49,
        49,
        49,
        20,
        28,
        33,
        0,
        49,
        8,
        20
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        4,
        24,
        19,
        19,
        40,
        0,
        33,
        6,
        48,
        35,
        29,
        34,
        49,
        24,
        12,
        0,
        32,
        48,
        0,
        0,
        20,
        6,
        24,
        49,
        7,
        42,
        41,
        1,
        10,
        25,
        37,
        21,
        38,
        33,
        34,
        30,
        18,
        48,
        22,
        34,
        21,
        39,
        49,
        42,
        39,
        46,
        30,
        6,
        0,
        22,
        15,
        34
      ],
      "weeklyRejected": [
        49,
        22,
        32,
        8,
        22,
        10,
        5,
        39,
        49,
        1,
        49,
        14,
        38,
        5,
        48,
        10,
        15,
        44,
        0,
        0,
        0,
        23,
        20,
        49,
        26,
        49,
        44,
        0,
        48,
        4,
        49,
        47,
        44,
        49,
        49,
        23,
        32,
        17,
        25,
        16,
        26,
        47,
        0,
        28,
        40,
        34,
        0,
        9,
        48,
        47,
        15,
        47
      ],
      "weeklyHold": [
        10,
        18,
        29,
        1,
        0,
        48,
        38,
        9,
        2,
        20,
        45,
        25,
        18,
        42,
        40,
        0,
        0,
        12,
        13,
        31,
        12,
        46,
        12,
        13,
        16,
        48,
        1,
        15,
        49,
        27,
        48,
        17,
        8,
        17,
        29,
        6,
        33,
        10,
        1,
        25,
        15,
        49,
        27,
        6,
        7,
        49,
        49,
        49,
        0,
        49,
        27,
        39
      ],
      "weeklyBackordered": [
        25,
        20,
        5,
        32,
        40,
        46,
        0,
        49,
        12,
        6,
        0,
        31,
        16,
        5,
        28,
        45,
        41,
        38,
        0,
        22,
        49,
        34,
        8,
        0,
        23,
        5,
        18,
        6,
        14,
        49,
        27,
        49,
        22,
        37,
        0,
        0,
        49,
        12,
        34,
        0,
        30,
        23,
        8,
        7,
        48,
        49,
        35,
        23,
        0,
        44,
        49,
        35
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        41,
        48,
        47,
        23,
        1,
        39,
        4,
        16,
        14,
        35,
        49,
        0,
        19,
        42,
        27,
        25,
        49,
        8,
        48,
        23,
        49,
        5,
        14,
        49,
        8,
        47,
        25,
        19,
        13,
        23,
        8,
        0,
        33,
        6,
        48,
        23,
        25,
        18,
        43,
        49,
        25,
        39,
        49,
        39,
        49,
        27,
        8,
        43,
        32,
        34,
        31,
        40
      ],
      "weeklyRejected": [
        43,
        34,
        14,
        0,
        21,
        0,
        37,
        31,
        38,
        29,
        8,
        12,
        32,
        30,
        5,
        5,
        27,
        49,
        29,
        41,
        33,
        14,
        32,
        12,
        3,
        33,
        44,
        16,
        42,
        36,
        32,
        47,
        3,
        49,
        1,
        20,
        49,
        18,
        1,
        32,
        47,
        1,
        0,
        7,
        49,
        49,
        0,
        40,
        0,
        49,
        23,
        22
      ],
      "weeklyHold": [
        25,
        36,
        10,
        33,
        9,
        35,
        0,
        17,
        0,
        41,
        20,
        31,
        23,
        38,
        21,
        14,
        17,
        48,
        0,
        46,
        0,
        21,
        40,
        23,
        26,
        37,
        22,
        22,
        38,
        23,
        48,
        0,
        0,
        16,
        31,
        24,
        31,
        38,
        33,
        0,
        28,
        11,
        0,
        29,
        49,
        40,
        1,
        0,
        16,
        38,
        31,
        13
      ],
      "weeklyBackordered": [
        27,
        32,
        44,
        2,
        42,
        46,
        23,
        14,
        47,
        31,
        49,
        49,
        28,
        12,
        5,
        48,
        46,
        29,
        47,
        31,
        32,
        43,
        22,
        21,
        1,
        49,
        49,
        40,
        1,
        49,
        41,
        11,
        27,
        37,
        0,
        0,
        31,
        45,
        21,
        0,
        0,
        17,
        14,
        49,
        27,
        29,
        21,
        31,
        37,
        39,
        38,
        11
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        13,
        17,
        49,
        35,
        20,
        12,
        28,
        44,
        4,
        34,
        49,
        6,
        0,
        36,
        6,
        29,
        49,
        18,
        17,
        25,
        20,
        0,
        49,
        11,
        49,
        28,
        0,
        37,
        37,
        27,
        0,
        17,
        2,
        0,
        48,
        32,
        26,
        48,
        49,
        49,
        0,
        40,
        49,
        13,
        39,
        13,
        0,
        18,
        31,
        8,
        7,
        17
      ],
      "weeklyRejected": [
        49,
        30,
        6,
        47,
        38,
        5,
        49,
        49,
        46,
        48,
        17,
        0,
        1,
        22,
        48,
        0,
        11,
        44,
        49,
        17,
        13,
        0,
        32,
        31,
        23,
        49,
        47,
        25,
        38,
        19,
        18,
        9,
        29,
        9,
        13,
        30,
        36,
        43,
        1,
        38,
        26,
        0,
        5,
        43,
        42,
        40,
        0,
        37,
        29,
        49,
        13,
        45
      ],
      "weeklyHold": [
        23,
        17,
        49,
        18,
        9,
        12,
        49,
        40,
        15,
        34,
        30,
        14,
        42,
        3,
        49,
        0,
        18,
        45,
        31,
        0,
        31,
        19,
        32,
        2,
        15,
        23,
        34,
        31,
        33,
        0,
        48,
        0,
        0,
        49,
        0,
        15,
        8,
        0,
        7,
        9,
        27,
        40,
        18,
        34,
        22,
        49,
        24,
        49,
        0,
        49,
        15,
        49
      ],
      "weeklyBackordered": [
        23,
        44,
        15,
        49,
        49,
        41,
        20,
        49,
        22,
        16,
        13,
        34,
        3,
        32,
        42,
        30,
        49,
        38,
        0,
        42,
        49,
        41,
        49,
        37,
        17,
        26,
        25,
        16,
        28,
        23,
        21,
        18,
        41,
        0,
        29,
        0,
        49,
        0,
        39,
        29,
        33,
        33,
        16,
        49,
        3,
        19,
        49,
        39,
        29,
        49,
        35,
        19
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        35,
        36,
        8,
        6,
        1,
        20,
        8,
        17,
        32,
        8,
        49,
        23,
        32,
        49,
        3,
        10,
        48,
        2,
        49,
        27,
        12,
        21,
        18,
        0,
        9,
        28,
        32,
        49,
        34,
        49,
        39,
        0,
        49,
        35,
        48,
        12,
        38,
        16,
        49,
        49,
        0,
        10,
        24,
        0,
        49,
        9,
        7,
        28,
        5,
        32,
        21,
        48
      ],
      "weeklyRejected": [
        28,
        28,
        47,
        0,
        37,
        49,
        27,
        49,
        49,
        8,
        17,
        28,
        0,
        29,
        35,
        3,
        13,
        17,
        43,
        21,
        22,
        14,
        40,
        18,
        34,
        49,
        32,
        33,
        15,
        2,
        0,
        43,
        39,
        34,
        14,
        49,
        28,
        39,
        27,
        8,
        34,
        1,
        0,
        21,
        36,
        29,
        0,
        28,
        34,
        29,
        17,
        33
      ],
      "weeklyHold": [
        13,
        47,
        37,
        21,
        0,
        38,
        36,
        2,
        34,
        14,
        0,
        21,
        48,
        35,
        25,
        37,
        17,
        7,
        28,
        0,
        7,
        16,
        26,
        49,
        27,
        20,
        15,
        36,
        47,
        0,
        48,
        26,
        21,
        49,
        0,
        26,
        23,
        7,
        27,
        27,
        30,
        24,
        15,
        49,
        45,
        42,
        2,
        9,
        0,
        22,
        18,
        0
      ],
      "weeklyBackordered": [
        0,
        0,
        15,
        37,
        34,
        41,
        33,
        49,
        32,
        0,
        37,
        32,
        31,
        49,
        36,
        49,
        47,
        49,
        0,
        20,
        4,
        15,
        4,
        6,
        1,
        16,
        35,
        6,
        49,
        33,
        29,
        49,
        24,
        9,
        14,
        0,
        16,
        8,
        4,
        11,
        30,
        27,
        6,
        49,
        30,
        49,
        29,
        30,
        25,
        49,
        31,
        36
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        0,
        36,
        42,
        13,
        24,
        1,
        17,
        33,
        48,
        41,
        1,
        9,
        6,
        32,
        43,
        9,
        41,
        42,
        11,
        46,
        1,
        30,
        24,
        35,
        8,
        47,
        45,
        13,
        24,
        26,
        27,
        37,
        46,
        10,
        38,
        42,
        14,
        21,
        15,
        39,
        12,
        16,
        49,
        49,
        49,
        0,
        15,
        9,
        49,
        49,
        26,
        27
      ],
      "weeklyRejected": [
        34,
        0,
        13,
        27,
        33,
        44,
        49,
        0,
        36,
        1,
        28,
        25,
        37,
        48,
        2,
        36,
        40,
        23,
        33,
        0,
        0,
        46,
        0,
        30,
        38,
        45,
        49,
        47,
        5,
        31,
        49,
        19,
        48,
        43,
        30,
        23,
        26,
        49,
        1,
        14,
        36,
        9,
        20,
        13,
        48,
        20,
        10,
        0,
        25,
        26,
        5,
        49
      ],
      "weeklyHold": [
        24,
        11,
        21,
        22,
        49,
        48,
        40,
        2,
        49,
        49,
        10,
        26,
        43,
        33,
        33,
        0,
        0,
        0,
        20,
        12,
        34,
        49,
        0,
        31,
        35,
        1,
        4,
        0,
        49,
        12,
        48,
        18,
        0,
        0,
        30,
        49,
        49,
        13,
        48,
        24,
        5,
        33,
        8,
        28,
        13,
        14,
        34,
        48,
        44,
        49,
        18,
        23
      ],
      "weeklyBackordered": [
        9,
        18,
        2,
        47,
        18,
        43,
        30,
        49,
        26,
        0,
        49,
        12,
        49,
        0,
        26,
        16,
        1,
        45,
        1,
        7,
        5,
        28,
        17,
        49,
        48,
        19,
        12,
        4,
        49,
        31,
        0,
        49,
        11,
        48,
        0,
        0,
        24,
        1,
        4,
        41,
        36,
        48,
        44,
        49,
        16,
        23,
        41,
        0,
        0,
        49,
        49,
        49
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        49,
        2,
        9,
        30,
        10,
        5,
        36,
        49,
        35,
        25,
        15,
        16,
        31,
        47,
        13,
        0,
        41,
        48,
        0,
        28,
        27,
        21,
        49,
        0,
        23,
        30,
        17,
        0,
        14,
        19,
        32,
        33,
        42,
        17,
        2,
        34,
        2,
        24,
        8,
        49,
        38,
        31,
        44,
        38,
        49,
        16,
        16,
        15,
        16,
        31,
        27,
        9
      ],
      "weeklyRejected": [
        37,
        49,
        31,
        0,
        39,
        20,
        49,
        23,
        49,
        48,
        48,
        37,
        31,
        30,
        33,
        26,
        20,
        49,
        19,
        28,
        10,
        17,
        1,
        34,
        25,
        49,
        17,
        45,
        33,
        20,
        9,
        36,
        7,
        24,
        22,
        49,
        49,
        39,
        4,
        31,
        46,
        22,
        28,
        10,
        42,
        10,
        12,
        26,
        0,
        49,
        8,
        14
      ],
      "weeklyHold": [
        22,
        8,
        13,
        16,
        41,
        38,
        38,
        19,
        16,
        49,
        25,
        23,
        1,
        48,
        2,
        39,
        21,
        0,
        4,
        34,
        0,
        31,
        23,
        24,
        0,
        18,
        49,
        37,
        49,
        12,
        48,
        47,
        0,
        12,
        27,
        25,
        6,
        3,
        10,
        25,
        11,
        26,
        35,
        9,
        25,
        49,
        36,
        49,
        4,
        4,
        42,
        13
      ],
      "weeklyBackordered": [
        49,
        26,
        39,
        46,
        49,
        42,
        0,
        33,
        38,
        49,
        18,
        22,
        16,
        0,
        18,
        18,
        49,
        45,
        8,
        30,
        7,
        0,
        28,
        49,
        1,
        5,
        34,
        0,
        13,
        43,
        18,
        49,
        0,
        42,
        7,
        3,
        22,
        15,
        0,
        37,
        17,
        48,
        6,
        49,
        23,
        0,
        49,
        18,
        45,
        27,
        27,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        0,
        35,
        13,
        48,
        22,
        0,
        24,
        49,
        36,
        22,
        41,
        16,
        45,
        31,
        13,
        0,
        49,
        12,
        25,
        21,
        15,
        47,
        47,
        49,
        0,
        32,
        14,
        26,
        1,
        19,
        38,
        22,
        48,
        16,
        20,
        49,
        8,
        48,
        17,
        49,
        4,
        23,
        49,
        42,
        29,
        0,
        18,
        45,
        1,
        49,
        19,
        48
      ],
      "weeklyRejected": [
        41,
        33,
        49,
        18,
        49,
        41,
        10,
        0,
        49,
        25,
        0,
        26,
        46,
        48,
        48,
        41,
        40,
        44,
        49,
        33,
        16,
        49,
        40,
        22,
        25,
        31,
        38,
        18,
        48,
        30,
        47,
        47,
        35,
        17,
        34,
        31,
        45,
        16,
        26,
        49,
        39,
        49,
        25,
        10,
        49,
        18,
        49,
        22,
        1,
        23,
        4,
        46
      ],
      "weeklyHold": [
        21,
        49,
        39,
        8,
        49,
        39,
        0,
        47,
        41,
        13,
        47,
        35,
        43,
        24,
        46,
        10,
        1,
        0,
        15,
        9,
        14,
        0,
        30,
        20,
        22,
        24,
        35,
        46,
        31,
        18,
        23,
        48,
        0,
        42,
        26,
        34,
        31,
        8,
        28,
        37,
        0,
        24,
        18,
        11,
        49,
        5,
        49,
        39,
        0,
        37,
        40,
        43
      ],
      "weeklyBackordered": [
        41,
        43,
        37,
        36,
        37,
        30,
        7,
        30,
        21,
        40,
        34,
        3,
        3,
        49,
        22,
        18,
        42,
        28,
        8,
        24,
        23,
        33,
        34,
        2,
        10,
        40,
        1,
        24,
        27,
        49,
        19,
        49,
        32,
        36,
        0,
        23,
        28,
        18,
        18,
        48,
        14,
        38,
        15,
        49,
        8,
        29,
        24,
        22,
        17,
        29,
        36,
        25
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        8,
        37,
        22,
        36,
        1,
        0,
        10,
        49,
        23,
        39,
        0,
        9,
        49,
        23,
        1,
        21,
        49,
        26,
        33,
        10,
        43,
        49,
        33,
        44,
        0,
        0,
        10,
        20,
        5,
        49,
        30,
        14,
        13,
        23,
        3,
        43,
        47,
        43,
        34,
        27,
        10,
        12,
        17,
        23,
        49,
        23,
        0,
        49,
        15,
        44,
        7,
        13
      ],
      "weeklyRejected": [
        49,
        9,
        5,
        10,
        49,
        0,
        31,
        49,
        49,
        1,
        11,
        0,
        11,
        48,
        24,
        4,
        45,
        39,
        37,
        19,
        16,
        5,
        21,
        29,
        16,
        49,
        37,
        45,
        48,
        13,
        0,
        0,
        22,
        10,
        21,
        49,
        47,
        12,
        1,
        16,
        48,
        26,
        44,
        10,
        34,
        37,
        39,
        49,
        11,
        37,
        0,
        36
      ],
      "weeklyHold": [
        36,
        12,
        35,
        1,
        10,
        37,
        0,
        13,
        14,
        27,
        47,
        30,
        23,
        20,
        28,
        26,
        27,
        16,
        12,
        49,
        22,
        36,
        0,
        13,
        40,
        40,
        12,
        28,
        38,
        0,
        26,
        4,
        0,
        0,
        31,
        47,
        15,
        44,
        46,
        44,
        0,
        48,
        0,
        25,
        19,
        5,
        0,
        0,
        0,
        14,
        49,
        43
      ],
      "weeklyBackordered": [
        10,
        34,
        27,
        16,
        16,
        40,
        28,
        48,
        42,
        0,
        49,
        17,
        6,
        38,
        23,
        22,
        49,
        21,
        0,
        14,
        25,
        29,
        7,
        20,
        48,
        17,
        9,
        49,
        31,
        49,
        14,
        25,
        48,
        49,
        0,
        26,
        1,
        28,
        8,
        7,
        26,
        39,
        42,
        49,
        29,
        30,
        49,
        13,
        19,
        46,
        19,
        8
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        4,
        41,
        47,
        42,
        38,
        0,
        40,
        49,
        44,
        0,
        45,
        0,
        0,
        49,
        42,
        15,
        38,
        4,
        48,
        19,
        47,
        26,
        32,
        28,
        0,
        25,
        2,
        41,
        15,
        14,
        6,
        39,
        49,
        23,
        48,
        18,
        42,
        27,
        26,
        49,
        7,
        11,
        36,
        28,
        49,
        0,
        49,
        17,
        49,
        0,
        22,
        17
      ],
      "weeklyRejected": [
        27,
        10,
        29,
        20,
        35,
        23,
        26,
        48,
        31,
        5,
        20,
        34,
        31,
        45,
        48,
        0,
        9,
        41,
        18,
        0,
        48,
        38,
        1,
        16,
        11,
        44,
        28,
        26,
        42,
        0,
        49,
        13,
        27,
        34,
        40,
        37,
        0,
        25,
        8,
        12,
        1,
        26,
        49,
        21,
        44,
        46,
        49,
        5,
        0,
        22,
        0,
        38
      ],
      "weeklyHold": [
        49,
        35,
        32,
        1,
        24,
        13,
        33,
        43,
        1,
        14,
        28,
        1,
        48,
        26,
        41,
        32,
        5,
        29,
        2,
        6,
        48,
        49,
        34,
        0,
        45,
        44,
        16,
        18,
        2,
        14,
        20,
        30,
        0,
        26,
        21,
        44,
        43,
        0,
        43,
        49,
        7,
        39,
        0,
        49,
        23,
        48,
        23,
        4,
        27,
        20,
        38,
        49
      ],
      "weeklyBackordered": [
        45,
        35,
        0,
        35,
        49,
        39,
        0,
        1,
        45,
        1,
        38,
        49,
        12,
        26,
        38,
        1,
        38,
        26,
        0,
        22,
        17,
        0,
        0,
        0,
        15,
        32,
        14,
        2,
        36,
        49,
        28,
        21,
        42,
        49,
        9,
        0,
        49,
        11,
        2,
        44,
        22,
        45,
        26,
        49,
        29,
        20,
        31,
        0,
        9,
        49,
        22,
        6
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyAccepted": [
        2,
        23,
        20,
        25,
        24,
        4,
        24,
        26,
        35,
        15,
        28,
        0,
        19,
        4,
        13,
        23,
        49,
        35,
        3,
        20,
        31,
        0,
        33,
        46,
        0,
        25,
        35,
        25,
        34,
        49,
        37,
        16,
        27,
        34,
        25,
        37,
        30,
        48,
        0,
        49,
        25,
        33,
        49,
        29,
        45,
        15,
        17,
        18,
        15,
        23,
        43,
        37
      ],
      "weeklyRejected": [
        28,
        19,
        49,
        16,
        20,
        40,
        29,
        13,
        18,
        6,
        26,
        25,
        33,
        13,
        10,
        15,
        27,
        37,
        29,
        35,
        49,
        41,
        49,
        45,
        6,
        32,
        15,
        0,
        46,
        34,
        38,
        43,
        43,
        34,
        17,
        49,
        35,
        35,
        1,
        21,
        4,
        8,
        0,
        30,
        49,
        18,
        8,
        36,
        0,
        49,
        15,
        31
      ],
      "weeklyHold": [
        14,
        12,
        27,
        1,
        49,
        42,
        42,
        2,
        29,
        37,
        29,
        7,
        25,
        19,
        28,
        25,
        25,
        40,
        46,
        31,
        33,
        13,
        22,
        1,
        26,
        42,
        0,
        30,
        22,
        8,
        48,
        0,
        18,
        49,
        2,
        0,
        40,
        30,
        11,
        38,
        9,
        49,
        14,
        44,
        29,
        49,
        10,
        13,
        21,
        19,
        49,
        3
      ],
      "weeklyBackordered": [
        13,
        19,
        19,
        30,
        34,
        23,
        22,
        45,
        30,
        19,
        41,
        42,
        28,
        22,
        38,
        49,
        28,
        41,
        0,
        47,
        49,
        1,
        11,
        7,
        11,
        35,
        36,
        21,
        26,
        32,
        28,
        35,
        31,
        35,
        0,
        10,
        30,
        0,
        0,
        11,
        32,
        48,
        0,
        49,
        0,
        21,
        17,
        49,
        28,
        30,
        14,
        33
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyAccepted": [
        2,
        27,
        29,
        39,
        49,
        25,
        44,
        2,
        39,
        13,
        46,
        4,
        49,
        31,
        1,
        39,
        18,
        48,
        0,
        9,
        5,
        34,
        42,
        48,
        22,
        40,
        42,
        49,
        31,
        20,
        49,
        16,
        26,
        42,
        0,
        33,
        18,
        48,
        19,
        49,
        49,
        36,
        36,
        36,
        49,
        20,
        7,
        48,
        21,
        49,
        15,
        8
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        47,
        44,
        49,
        26,
        17,
        13,
        48,
        14,
        0,
        17,
        48,
        36,
        49,
        30,
        11,
        9,
        12,
        21,
        22,
        29,
        49,
        17,
        49,
        27,
        13,
        21,
        30,
        20,
        47,
        48,
        49,
        13,
        43,
        47,
        31,
        4,
        14,
        18,
        30,
        19,
        43,
        47,
        32,
        38,
        0,
        21,
        49,
        0,
        41
      ],
      "weeklyHold": [
        48,
        10,
        32,
        1,
        49,
        41,
        24,
        27,
        3,
        27,
        12,
        34,
        46,
        19,
        25,
        29,
        0,
        47,
        9,
        24,
        10,
        46,
        4,
        22,
        12,
        16,
        32,
        37,
        38,
        25,
        25,
        33,
        20,
        49,
        7,
        29,
        0,
        38,
        1,
        48,
        23,
        36,
        21,
        49,
        39,
        23,
        13,
        31,
        0,
        17,
        24,
        0
      ],
      "weeklyBackordered": [
        40,
        40,
        48,
        30,
        47,
        27,
        29,
        9,
        18,
        49,
        21,
        16,
        49,
        31,
        49,
        40,
        27,
        45,
        11,
        18,
        15,
        27,
        0,
        48,
        8,
        9,
        1,
        49,
        11,
        43,
        23,
        16,
        48,
        0,
        0,
        7,
        27,
        5,
        32,
        12,
        26,
        47,
        4,
        25,
        0,
        40,
        37,
        45,
        49,
        32,
        6,
        4
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        3,
        14,
        21,
        15,
        24,
        41,
        2,
        49,
        32,
        0,
        49,
        0,
        29,
        49,
        17,
        0,
        8,
        19,
        0,
        0,
        25,
        46,
        0,
        24,
        5,
        31,
        46,
        1,
        13,
        27,
        0,
        2,
        47,
        0,
        48,
        38,
        39,
        28,
        22,
        48,
        0,
        38,
        1,
        18,
        46,
        34,
        32,
        0,
        16,
        49,
        0,
        0
      ],
      "weeklyRejected": [
        31,
        15,
        19,
        20,
        30,
        1,
        49,
        36,
        49,
        11,
        30,
        18,
        16,
        37,
        30,
        39,
        21,
        49,
        15,
        0,
        11,
        16,
        41,
        23,
        26,
        49,
        49,
        36,
        13,
        18,
        5,
        47,
        38,
        2,
        11,
        49,
        43,
        14,
        12,
        21,
        3,
        44,
        38,
        13,
        14,
        19,
        1,
        0,
        23,
        39,
        0,
        41
      ],
      "weeklyHold": [
        21,
        26,
        49,
        1,
        36,
        32,
        0,
        20,
        23,
        0,
        3,
        49,
        42,
        23,
        36,
        13,
        0,
        6,
        19,
        0,
        27,
        48,
        22,
        49,
        22,
        9,
        45,
        27,
        32,
        22,
        48,
        18,
        14,
        27,
        0,
        49,
        16,
        8,
        28,
        38,
        30,
        49,
        0,
        0,
        30,
        24,
        2,
        16,
        0,
        33,
        41,
        0
      ],
      "weeklyBackordered": [
        49,
        35,
        2,
        37,
        43,
        37,
        43,
        12,
        1,
        19,
        19,
        36,
        7,
        1,
        49,
        16,
        22,
        49,
        3,
        4,
        14,
        39,
        1,
        33,
        7,
        6,
        17,
        0,
        49,
        49,
        5,
        36,
        22,
        49,
        16,
        1,
        49,
        5,
        45,
        46,
        30,
        48,
        23,
        33,
        0,
        0,
        37,
        5,
        0,
        7,
        49,
        37
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        0,
        19,
        28,
        48,
        35,
        23,
        46,
        45,
        42,
        34,
        31,
        11,
        49,
        30,
        1,
        42,
        49,
        4,
        7,
        29,
        2,
        49,
        49,
        36,
        0,
        47,
        15,
        41,
        28,
        9,
        49,
        0,
        20,
        7,
        34,
        49,
        30,
        35,
        27,
        49,
        11,
        14,
        31,
        37,
        31,
        1,
        36,
        19,
        49,
        49,
        49,
        0
      ],
      "weeklyRejected": [
        29,
        18,
        24,
        18,
        25,
        0,
        12,
        15,
        28,
        1,
        49,
        19,
        23,
        48,
        48,
        17,
        49,
        45,
        0,
        7,
        31,
        34,
        4,
        22,
        0,
        49,
        49,
        5,
        48,
        42,
        33,
        37,
        28,
        49,
        15,
        1,
        30,
        0,
        15,
        14,
        0,
        26,
        30,
        49,
        49,
        8,
        49,
        29,
        0,
        46,
        0,
        41
      ],
      "weeklyHold": [
        36,
        9,
        30,
        1,
        0,
        26,
        5,
        15,
        14,
        38,
        29,
        38,
        24,
        18,
        13,
        0,
        10,
        37,
        49,
        22,
        35,
        30,
        25,
        0,
        40,
        48,
        14,
        32,
        44,
        0,
        13,
        1,
        5,
        12,
        0,
        25,
        35,
        25,
        1,
        29,
        28,
        35,
        16,
        46,
        35,
        49,
        41,
        49,
        11,
        6,
        49,
        32
      ],
      "weeklyBackordered": [
        46,
        49,
        9,
        29,
        49,
        13,
        34,
        40,
        0,
        21,
        40,
        46,
        3,
        0,
        30,
        5,
        39,
        39,
        0,
        6,
        49,
        49,
        14,
        13,
        14,
        33,
        49,
        43,
        1,
        49,
        2,
        40,
        39,
        20,
        5,
        0,
        1,
        13,
        26,
        24,
        10,
        38,
        4,
        17,
        3,
        49,
        0,
        14,
        8,
        2,
        37,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        0,
        31,
        28,
        47,
        30,
        0,
        1,
        42,
        44,
        0,
        31,
        0,
        49,
        22,
        49,
        38,
        40,
        0,
        5,
        49,
        37,
        42,
        21,
        45,
        8,
        33,
        18,
        32,
        32,
        49,
        0,
        17,
        49,
        37,
        29,
        26,
        47,
        2,
        49,
        49,
        41,
        0,
        49,
        49,
        27,
        4,
        21,
        49,
        14,
        33,
        12,
        7
      ],
      "weeklyRejected": [
        17,
        13,
        49,
        20,
        43,
        24,
        46,
        25,
        29,
        8,
        23,
        17,
        49,
        11,
        48,
        38,
        5,
        40,
        28,
        27,
        12,
        49,
        13,
        31,
        37,
        49,
        25,
        37,
        4,
        43,
        49,
        47,
        48,
        30,
        16,
        49,
        29,
        0,
        1,
        22,
        48,
        46,
        21,
        37,
        45,
        31,
        14,
        49,
        22,
        28,
        0,
        12
      ],
      "weeklyHold": [
        49,
        43,
        39,
        20,
        49,
        21,
        49,
        2,
        19,
        3,
        9,
        1,
        20,
        31,
        49,
        4,
        15,
        48,
        17,
        7,
        47,
        48,
        44,
        44,
        21,
        1,
        14,
        29,
        39,
        21,
        48,
        48,
        24,
        0,
        48,
        14,
        35,
        18,
        36,
        10,
        49,
        23,
        17,
        40,
        27,
        40,
        7,
        27,
        23,
        36,
        26,
        24
      ],
      "weeklyBackordered": [
        0,
        24,
        0,
        39,
        45,
        29,
        30,
        47,
        4,
        0,
        7,
        25,
        3,
        49,
        49,
        18,
        14,
        0,
        0,
        10,
        26,
        19,
        6,
        14,
        4,
        26,
        11,
        12,
        49,
        4,
        11,
        36,
        25,
        48,
        11,
        25,
        23,
        0,
        18,
        0,
        31,
        29,
        15,
        49,
        14,
        0,
        30,
        19,
        30,
        49,
        27,
        1
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        0,
        12,
        49,
        10,
        40,
        23,
        20,
        49,
        37,
        31,
        36,
        0,
        32,
        49,
        24,
        19,
        38,
        4,
        0,
        32,
        49,
        45,
        36,
        39,
        27,
        13,
        1,
        32,
        21,
        40,
        0,
        24,
        42,
        36,
        18,
        49,
        47,
        7,
        24,
        15,
        8,
        29,
        40,
        19,
        49,
        24,
        11,
        12,
        15,
        20,
        34,
        37
      ],
      "weeklyRejected": [
        35,
        9,
        47,
        47,
        24,
        49,
        25,
        25,
        33,
        9,
        49,
        26,
        20,
        16,
        30,
        33,
        13,
        49,
        29,
        11,
        48,
        12,
        48,
        16,
        11,
        35,
        0,
        35,
        38,
        4,
        14,
        10,
        35,
        49,
        17,
        49,
        5,
        20,
        2,
        19,
        37,
        12,
        4,
        0,
        27,
        0,
        25,
        7,
        5,
        0,
        24,
        49
      ],
      "weeklyHold": [
        8,
        37,
        42,
        1,
        15,
        21,
        49,
        2,
        36,
        17,
        21,
        16,
        1,
        28,
        49,
        27,
        30,
        25,
        11,
        22,
        41,
        28,
        49,
        7,
        7,
        10,
        35,
        18,
        49,
        18,
        48,
        8,
        31,
        0,
        48,
        31,
        5,
        0,
        30,
        10,
        43,
        20,
        27,
        34,
        39,
        49,
        3,
        26,
        0,
        29,
        41,
        15
      ],
      "weeklyBackordered": [
        29,
        25,
        0,
        21,
        38,
        46,
        49,
        37,
        49,
        0,
        11,
        48,
        3,
        22,
        31,
        49,
        36,
        16,
        19,
        49,
        49,
        0,
        15,
        6,
        28,
        9,
        19,
        3,
        29,
        49,
        20,
        49,
        22,
        0,
        0,
        0,
        19,
        25,
        49,
        13,
        18,
        28,
        21,
        49,
        20,
        24,
        1,
        22,
        0,
        32,
        35,
        1
      ]
    },
    {
      "id": "FB70040E",
      "weeklyAccepted": [
        17,
        47,
        27,
        5,
        31,
        1,
        1,
        49,
        37,
        32,
        41,
        26,
        49,
        31,
        26,
        0,
        49,
        16,
        0,
        26,
        32,
        24,
        21,
        0,
        11,
        47,
        15,
        28,
        5,
        44,
        24,
        0,
        49,
        19,
        48,
        28,
        28,
        14,
        25,
        49,
        6,
        48,
        32,
        44,
        43,
        0,
        15,
        49,
        43,
        30,
        29,
        32
      ],
      "weeklyRejected": [
        38,
        20,
        2,
        47,
        41,
        10,
        0,
        32,
        34,
        19,
        49,
        25,
        36,
        22,
        17,
        3,
        0,
        44,
        32,
        1,
        19,
        36,
        34,
        42,
        10,
        26,
        34,
        5,
        40,
        0,
        21,
        41,
        23,
        15,
        37,
        20,
        0,
        17,
        19,
        17,
        0,
        34,
        0,
        27,
        49,
        26,
        24,
        13,
        7,
        13,
        0,
        20
      ],
      "weeklyHold": [
        31,
        31,
        49,
        16,
        12,
        30,
        35,
        9,
        29,
        21,
        21,
        47,
        1,
        28,
        33,
        7,
        15,
        32,
        2,
        5,
        13,
        31,
        5,
        0,
        15,
        34,
        33,
        0,
        41,
        24,
        39,
        16,
        2,
        31,
        47,
        7,
        21,
        28,
        18,
        45,
        10,
        49,
        0,
        22,
        34,
        15,
        6,
        20,
        15,
        36,
        16,
        41
      ],
      "weeklyBackordered": [
        31,
        41,
        30,
        24,
        44,
        35,
        49,
        1,
        21,
        8,
        40,
        15,
        7,
        0,
        20,
        24,
        30,
        24,
        20,
        0,
        23,
        0,
        3,
        12,
        20,
        17,
        1,
        17,
        27,
        41,
        24,
        30,
        48,
        10,
        27,
        0,
        7,
        7,
        20,
        24,
        48,
        34,
        20,
        49,
        25,
        45,
        49,
        5,
        0,
        33,
        39,
        25
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        25,
        38,
        35,
        31,
        28,
        1,
        10,
        25,
        48,
        39,
        49,
        7,
        49,
        35,
        8,
        12,
        43,
        2,
        0,
        45,
        31,
        35,
        28,
        48,
        36,
        35,
        47,
        0,
        1,
        48,
        0,
        17,
        38,
        33,
        33,
        37,
        15,
        48,
        49,
        49,
        0,
        34,
        49,
        49,
        31,
        0,
        0,
        49,
        49,
        49,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        12,
        29,
        47,
        49,
        17,
        0,
        49,
        26,
        48,
        49,
        0,
        40,
        28,
        42,
        7,
        17,
        23,
        0,
        34,
        0,
        22,
        1,
        17,
        43,
        49,
        27,
        49,
        48,
        19,
        49,
        21,
        48,
        30,
        0,
        49,
        18,
        0,
        1,
        6,
        48,
        17,
        28,
        24,
        48,
        0,
        3,
        49,
        25,
        46,
        0,
        42
      ],
      "weeklyHold": [
        36,
        1,
        31,
        2,
        25,
        39,
        14,
        17,
        29,
        49,
        36,
        17,
        43,
        37,
        0,
        0,
        4,
        3,
        18,
        0,
        28,
        49,
        10,
        13,
        38,
        14,
        44,
        42,
        49,
        14,
        48,
        19,
        0,
        24,
        41,
        12,
        28,
        0,
        29,
        11,
        11,
        28,
        49,
        27,
        0,
        41,
        22,
        33,
        0,
        36,
        4,
        5
      ],
      "weeklyBackordered": [
        5,
        18,
        0,
        49,
        47,
        19,
        22,
        20,
        26,
        0,
        0,
        15,
        10,
        49,
        48,
        24,
        49,
        0,
        0,
        26,
        16,
        49,
        17,
        3,
        6,
        1,
        42,
        0,
        13,
        22,
        0,
        39,
        38,
        49,
        0,
        0,
        11,
        30,
        7,
        49,
        27,
        37,
        0,
        48,
        25,
        13,
        49,
        13,
        26,
        48,
        17,
        40
      ]
    },
    {
      "id": "FB70040E",
      "weeklyAccepted": [
        13,
        49,
        49,
        32,
        1,
        0,
        2,
        41,
        47,
        2,
        49,
        7,
        24,
        18,
        41,
        30,
        26,
        0,
        28,
        27,
        31,
        49,
        41,
        49,
        20,
        47,
        30,
        0,
        1,
        43,
        7,
        16,
        49,
        43,
        48,
        2,
        26,
        21,
        34,
        33,
        0,
        49,
        18,
        0,
        49,
        0,
        8,
        6,
        1,
        19,
        7,
        0
      ],
      "weeklyRejected": [
        49,
        49,
        18,
        21,
        8,
        33,
        27,
        7,
        49,
        1,
        21,
        42,
        29,
        21,
        48,
        21,
        35,
        49,
        36,
        18,
        0,
        12,
        5,
        29,
        38,
        37,
        17,
        11,
        24,
        0,
        49,
        32,
        25,
        8,
        30,
        49,
        49,
        41,
        1,
        49,
        9,
        29,
        35,
        21,
        39,
        44,
        18,
        22,
        15,
        27,
        49,
        38
      ],
      "weeklyHold": [
        49,
        32,
        49,
        16,
        35,
        38,
        0,
        2,
        12,
        0,
        36,
        35,
        28,
        1,
        49,
        17,
        33,
        4,
        32,
        0,
        0,
        41,
        42,
        0,
        36,
        21,
        7,
        48,
        27,
        49,
        44,
        48,
        0,
        24,
        48,
        35,
        38,
        43,
        49,
        0,
        0,
        36,
        32,
        36,
        48,
        0,
        0,
        23,
        6,
        22,
        25,
        39
      ],
      "weeklyBackordered": [
        36,
        7,
        18,
        26,
        7,
        46,
        36,
        6,
        27,
        15,
        32,
        10,
        3,
        16,
        14,
        37,
        49,
        49,
        0,
        19,
        0,
        28,
        22,
        28,
        48,
        20,
        9,
        21,
        45,
        41,
        34,
        45,
        12,
        0,
        0,
        44,
        14,
        33,
        12,
        34,
        0,
        48,
        0,
        49,
        0,
        19,
        47,
        18,
        23,
        10,
        27,
        23
      ]
    },
    {
      "id": "271D2E55",
      "weeklyAccepted": [
        7,
        30,
        15,
        46,
        43,
        7,
        8,
        42,
        15,
        4,
        41,
        0,
        16,
        23,
        8,
        0,
        48,
        48,
        0,
        33,
        0,
        43,
        0,
        19,
        0,
        37,
        26,
        33,
        16,
        49,
        16,
        40,
        49,
        23,
        44,
        2,
        29,
        44,
        9,
        25,
        21,
        49,
        49,
        0,
        28,
        22,
        10,
        42,
        1,
        19,
        14,
        12
      ],
      "weeklyRejected": [
        49,
        15,
        39,
        6,
        11,
        4,
        36,
        49,
        49,
        19,
        14,
        37,
        20,
        35,
        48,
        0,
        3,
        49,
        49,
        44,
        22,
        0,
        33,
        45,
        24,
        49,
        49,
        44,
        48,
        28,
        26,
        16,
        22,
        49,
        6,
        36,
        13,
        7,
        1,
        13,
        41,
        18,
        10,
        0,
        21,
        47,
        0,
        49,
        24,
        38,
        0,
        45
      ],
      "weeklyHold": [
        23,
        10,
        49,
        18,
        31,
        26,
        23,
        2,
        11,
        23,
        31,
        14,
        8,
        49,
        49,
        16,
        25,
        25,
        19,
        49,
        28,
        7,
        49,
        9,
        18,
        16,
        44,
        32,
        37,
        23,
        48,
        0,
        25,
        23,
        42,
        45,
        0,
        17,
        10,
        25,
        11,
        19,
        13,
        27,
        36,
        49,
        49,
        49,
        0,
        43,
        33,
        30
      ],
      "weeklyBackordered": [
        0,
        14,
        31,
        0,
        43,
        33,
        37,
        31,
        18,
        27,
        0,
        45,
        48,
        49,
        26,
        49,
        17,
        10,
        0,
        0,
        49,
        34,
        13,
        1,
        25,
        12,
        45,
        49,
        9,
        46,
        30,
        42,
        5,
        49,
        0,
        0,
        45,
        21,
        49,
        34,
        0,
        34,
        10,
        37,
        27,
        36,
        25,
        30,
        1,
        31,
        49,
        11
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        7,
        22,
        49,
        39,
        16,
        15,
        17,
        38,
        13,
        49,
        49,
        0,
        46,
        21,
        20,
        12,
        49,
        0,
        49,
        28,
        16,
        29,
        45,
        30,
        26,
        17,
        31,
        9,
        19,
        21,
        0,
        14,
        45,
        0,
        38,
        47,
        35,
        23,
        21,
        41,
        9,
        26,
        49,
        0,
        49,
        31,
        11,
        2,
        18,
        3,
        29,
        13
      ],
      "weeklyRejected": [
        49,
        17,
        31,
        31,
        6,
        0,
        16,
        40,
        47,
        36,
        10,
        31,
        42,
        17,
        14,
        34,
        17,
        49,
        13,
        12,
        0,
        0,
        49,
        30,
        17,
        40,
        42,
        5,
        48,
        16,
        4,
        30,
        48,
        0,
        30,
        49,
        27,
        28,
        13,
        49,
        5,
        22,
        49,
        3,
        41,
        49,
        21,
        0,
        21,
        49,
        16,
        22
      ],
      "weeklyHold": [
        23,
        49,
        32,
        24,
        49,
        29,
        34,
        10,
        41,
        30,
        11,
        26,
        48,
        47,
        20,
        39,
        6,
        8,
        0,
        44,
        15,
        49,
        38,
        27,
        42,
        28,
        28,
        0,
        7,
        45,
        48,
        37,
        10,
        49,
        0,
        49,
        43,
        0,
        8,
        0,
        49,
        49,
        7,
        10,
        49,
        35,
        12,
        21,
        8,
        47,
        49,
        38
      ],
      "weeklyBackordered": [
        48,
        31,
        0,
        9,
        49,
        38,
        0,
        7,
        29,
        9,
        25,
        7,
        31,
        27,
        27,
        29,
        28,
        35,
        18,
        49,
        36,
        34,
        25,
        7,
        48,
        24,
        7,
        8,
        34,
        38,
        27,
        26,
        27,
        42,
        33,
        11,
        49,
        21,
        21,
        6,
        21,
        33,
        11,
        28,
        49,
        49,
        35,
        0,
        0,
        34,
        26,
        5
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        21,
        27,
        24,
        19,
        27,
        47,
        11,
        36,
        29,
        40,
        34,
        6,
        42,
        18,
        20,
        44,
        41,
        1,
        0,
        46,
        16,
        4,
        38,
        25,
        0,
        40,
        8,
        11,
        1,
        41,
        16,
        0,
        49,
        37,
        40,
        48,
        4,
        0,
        28,
        49,
        21,
        49,
        30,
        44,
        30,
        22,
        29,
        28,
        45,
        49,
        5,
        6
      ],
      "weeklyRejected": [
        16,
        41,
        49,
        9,
        49,
        0,
        0,
        0,
        0,
        28,
        49,
        0,
        39,
        32,
        9,
        36,
        0,
        49,
        13,
        29,
        25,
        3,
        4,
        25,
        21,
        45,
        49,
        28,
        22,
        47,
        43,
        16,
        48,
        28,
        18,
        13,
        49,
        35,
        1,
        35,
        43,
        49,
        43,
        43,
        12,
        27,
        0,
        11,
        0,
        42,
        7,
        34
      ],
      "weeklyHold": [
        0,
        22,
        42,
        1,
        30,
        14,
        0,
        31,
        19,
        0,
        0,
        38,
        1,
        42,
        49,
        10,
        21,
        36,
        39,
        34,
        31,
        13,
        0,
        17,
        0,
        39,
        37,
        8,
        49,
        0,
        48,
        0,
        0,
        0,
        15,
        6,
        13,
        6,
        35,
        43,
        14,
        34,
        24,
        0,
        36,
        49,
        42,
        41,
        0,
        0,
        49,
        2
      ],
      "weeklyBackordered": [
        4,
        21,
        48,
        22,
        31,
        37,
        8,
        29,
        34,
        37,
        30,
        47,
        3,
        39,
        49,
        24,
        17,
        0,
        2,
        32,
        33,
        8,
        25,
        21,
        9,
        6,
        14,
        45,
        31,
        0,
        6,
        42,
        19,
        49,
        0,
        0,
        49,
        15,
        49,
        0,
        48,
        48,
        17,
        35,
        9,
        23,
        0,
        36,
        37,
        19,
        12,
        1
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        30,
        32,
        14,
        32,
        5,
        0,
        25,
        49,
        14,
        26,
        13,
        3,
        20,
        49,
        32,
        49,
        48,
        1,
        8,
        3,
        49,
        30,
        14,
        30,
        23,
        26,
        29,
        40,
        34,
        39,
        30,
        22,
        45,
        27,
        48,
        26,
        35,
        12,
        19,
        20,
        2,
        19,
        49,
        2,
        33,
        0,
        0,
        3,
        49,
        11,
        21,
        4
      ],
      "weeklyRejected": [
        41,
        23,
        32,
        47,
        33,
        28,
        47,
        49,
        39,
        1,
        12,
        18,
        0,
        35,
        33,
        0,
        35,
        21,
        49,
        0,
        39,
        18,
        14,
        0,
        0,
        49,
        49,
        49,
        48,
        13,
        39,
        0,
        0,
        42,
        0,
        49,
        27,
        14,
        10,
        0,
        38,
        14,
        31,
        0,
        49,
        9,
        19,
        14,
        18,
        34,
        18,
        42
      ],
      "weeklyHold": [
        49,
        0,
        32,
        8,
        49,
        24,
        49,
        4,
        12,
        49,
        37,
        15,
        1,
        46,
        43,
        9,
        0,
        42,
        36,
        0,
        31,
        2,
        3,
        5,
        33,
        42,
        49,
        49,
        33,
        1,
        35,
        5,
        0,
        49,
        4,
        7,
        6,
        8,
        36,
        20,
        29,
        42,
        16,
        39,
        6,
        48,
        11,
        30,
        8,
        23,
        26,
        22
      ],
      "weeklyBackordered": [
        13,
        30,
        32,
        49,
        47,
        31,
        0,
        49,
        40,
        23,
        44,
        28,
        14,
        25,
        15,
        11,
        49,
        49,
        0,
        25,
        39,
        19,
        27,
        3,
        19,
        49,
        20,
        47,
        1,
        49,
        27,
        49,
        42,
        28,
        26,
        0,
        49,
        0,
        0,
        15,
        42,
        48,
        12,
        31,
        28,
        44,
        27,
        26,
        26,
        26,
        45,
        35
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        10,
        47,
        0,
        4,
        28,
        37,
        13,
        9,
        25,
        0,
        49,
        2,
        32,
        34,
        38,
        49,
        31,
        8,
        39,
        3,
        49,
        13,
        33,
        49,
        14,
        0,
        37,
        42,
        13,
        20,
        34,
        23,
        26,
        14,
        48,
        47,
        32,
        7,
        49,
        49,
        49,
        0,
        39,
        12,
        27,
        4,
        36,
        37,
        10,
        49,
        25,
        6
      ],
      "weeklyRejected": [
        36,
        0,
        28,
        1,
        28,
        9,
        49,
        0,
        49,
        21,
        15,
        3,
        29,
        48,
        48,
        49,
        28,
        17,
        2,
        18,
        49,
        35,
        31,
        49,
        0,
        49,
        28,
        5,
        35,
        27,
        15,
        26,
        46,
        49,
        0,
        49,
        0,
        24,
        17,
        44,
        37,
        10,
        49,
        6,
        44,
        14,
        16,
        4,
        47,
        49,
        0,
        30
      ],
      "weeklyHold": [
        49,
        13,
        0,
        1,
        20,
        0,
        49,
        2,
        0,
        49,
        12,
        21,
        16,
        23,
        32,
        31,
        11,
        10,
        0,
        0,
        38,
        17,
        2,
        49,
        0,
        19,
        15,
        0,
        39,
        14,
        41,
        0,
        0,
        0,
        48,
        12,
        35,
        10,
        46,
        0,
        32,
        49,
        10,
        40,
        49,
        25,
        13,
        37,
        24,
        0,
        0,
        7
      ],
      "weeklyBackordered": [
        27,
        28,
        0,
        35,
        49,
        22,
        0,
        49,
        23,
        15,
        23,
        44,
        6,
        40,
        32,
        29,
        39,
        28,
        0,
        0,
        10,
        18,
        1,
        49,
        15,
        49,
        44,
        49,
        19,
        31,
        49,
        41,
        21,
        44,
        2,
        0,
        1,
        0,
        22,
        0,
        0,
        43,
        8,
        49,
        46,
        48,
        39,
        26,
        40,
        5,
        20,
        1
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        22,
        49,
        18,
        37,
        33,
        49,
        25,
        49,
        37,
        39,
        40,
        24,
        20,
        18,
        14,
        13,
        39,
        25,
        33,
        32,
        0,
        41,
        49,
        47,
        49,
        33,
        10,
        24,
        13,
        48,
        8,
        16,
        35,
        20,
        11,
        44,
        47,
        36,
        0,
        49,
        17,
        11,
        49,
        35,
        49,
        26,
        2,
        35,
        43,
        40,
        41,
        0
      ],
      "weeklyRejected": [
        49,
        1,
        18,
        19,
        17,
        0,
        27,
        49,
        17,
        15,
        48,
        0,
        49,
        48,
        48,
        49,
        0,
        49,
        8,
        49,
        13,
        0,
        18,
        15,
        39,
        12,
        19,
        41,
        48,
        24,
        32,
        6,
        11,
        0,
        21,
        49,
        46,
        20,
        14,
        25,
        48,
        49,
        44,
        25,
        43,
        33,
        27,
        49,
        15,
        8,
        15,
        24
      ],
      "weeklyHold": [
        17,
        17,
        49,
        1,
        49,
        3,
        47,
        2,
        28,
        16,
        22,
        49,
        9,
        30,
        27,
        20,
        3,
        37,
        20,
        8,
        0,
        49,
        26,
        47,
        37,
        48,
        0,
        2,
        38,
        10,
        48,
        20,
        19,
        29,
        20,
        39,
        36,
        0,
        48,
        6,
        27,
        33,
        49,
        6,
        33,
        42,
        30,
        20,
        8,
        30,
        4,
        7
      ],
      "weeklyBackordered": [
        0,
        0,
        0,
        20,
        12,
        26,
        49,
        49,
        0,
        20,
        18,
        0,
        3,
        1,
        28,
        49,
        17,
        23,
        0,
        9,
        26,
        2,
        13,
        49,
        2,
        6,
        14,
        39,
        1,
        27,
        48,
        0,
        0,
        38,
        24,
        0,
        1,
        5,
        5,
        19,
        8,
        48,
        27,
        35,
        12,
        28,
        14,
        0,
        8,
        34,
        49,
        29
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        2,
        41,
        7,
        33,
        25,
        22,
        27,
        28,
        20,
        16,
        36,
        23,
        25,
        16,
        1,
        31,
        23,
        18,
        12,
        49,
        0,
        0,
        7,
        27,
        41,
        43,
        30,
        14,
        8,
        39,
        0,
        0,
        42,
        43,
        31,
        19,
        36,
        41,
        49,
        25,
        5,
        41,
        49,
        49,
        8,
        0,
        19,
        16,
        7,
        32,
        35,
        15
      ],
      "weeklyRejected": [
        43,
        11,
        33,
        16,
        16,
        18,
        28,
        21,
        42,
        29,
        45,
        0,
        0,
        48,
        15,
        22,
        0,
        44,
        0,
        47,
        47,
        12,
        36,
        43,
        21,
        49,
        45,
        0,
        41,
        34,
        26,
        34,
        48,
        34,
        24,
        47,
        31,
        27,
        15,
        5,
        11,
        20,
        31,
        12,
        42,
        49,
        46,
        16,
        0,
        15,
        13,
        49
      ],
      "weeklyHold": [
        3,
        0,
        49,
        1,
        19,
        41,
        0,
        30,
        46,
        14,
        26,
        28,
        32,
        45,
        35,
        0,
        12,
        45,
        6,
        17,
        35,
        23,
        13,
        47,
        27,
        41,
        25,
        0,
        24,
        3,
        45,
        15,
        17,
        49,
        1,
        32,
        24,
        0,
        30,
        27,
        0,
        32,
        43,
        18,
        49,
        36,
        22,
        27,
        0,
        49,
        32,
        21
      ],
      "weeklyBackordered": [
        22,
        29,
        7,
        28,
        17,
        46,
        0,
        4,
        20,
        9,
        49,
        27,
        8,
        40,
        0,
        33,
        13,
        40,
        0,
        3,
        40,
        15,
        23,
        27,
        4,
        1,
        23,
        40,
        8,
        25,
        26,
        14,
        40,
        39,
        0,
        0,
        49,
        18,
        49,
        46,
        33,
        48,
        12,
        49,
        41,
        21,
        33,
        2,
        0,
        44,
        1,
        11
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        4,
        31,
        37,
        42,
        24,
        17,
        31,
        11,
        38,
        27,
        42,
        12,
        27,
        14,
        6,
        38,
        49,
        8,
        18,
        0,
        13,
        47,
        33,
        49,
        0,
        39,
        0,
        49,
        1,
        12,
        0,
        9,
        37,
        0,
        33,
        18,
        23,
        8,
        17,
        49,
        17,
        11,
        49,
        49,
        26,
        43,
        13,
        20,
        38,
        18,
        49,
        18
      ],
      "weeklyRejected": [
        37,
        1,
        24,
        27,
        19,
        2,
        41,
        22,
        49,
        5,
        20,
        0,
        45,
        22,
        36,
        47,
        12,
        49,
        32,
        13,
        7,
        49,
        28,
        45,
        24,
        48,
        45,
        39,
        20,
        48,
        34,
        31,
        30,
        11,
        0,
        49,
        22,
        30,
        35,
        40,
        31,
        24,
        15,
        31,
        21,
        30,
        0,
        33,
        3,
        24,
        5,
        8
      ],
      "weeklyHold": [
        12,
        24,
        24,
        42,
        18,
        21,
        36,
        26,
        31,
        13,
        0,
        25,
        48,
        16,
        49,
        0,
        6,
        9,
        18,
        44,
        22,
        20,
        6,
        49,
        15,
        21,
        4,
        0,
        18,
        26,
        16,
        15,
        25,
        10,
        14,
        37,
        31,
        0,
        21,
        20,
        36,
        49,
        1,
        34,
        36,
        38,
        47,
        49,
        1,
        7,
        49,
        29
      ],
      "weeklyBackordered": [
        27,
        13,
        3,
        35,
        49,
        28,
        44,
        48,
        13,
        22,
        20,
        15,
        36,
        36,
        13,
        34,
        43,
        49,
        0,
        22,
        22,
        0,
        0,
        9,
        14,
        48,
        10,
        26,
        48,
        37,
        3,
        18,
        48,
        49,
        33,
        0,
        9,
        0,
        0,
        49,
        3,
        31,
        13,
        43,
        4,
        36,
        49,
        10,
        48,
        49,
        26,
        9
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        25,
        27,
        10,
        48,
        30,
        32,
        49,
        49,
        48,
        10,
        49,
        28,
        22,
        19,
        27,
        12,
        38,
        16,
        34,
        14,
        31,
        37,
        16,
        49,
        0,
        29,
        0,
        49,
        38,
        49,
        23,
        34,
        49,
        34,
        48,
        31,
        36,
        23,
        43,
        49,
        32,
        43,
        0,
        0,
        43,
        8,
        10,
        29,
        15,
        49,
        12,
        0
      ],
      "weeklyRejected": [
        38,
        8,
        49,
        47,
        49,
        25,
        49,
        43,
        0,
        1,
        49,
        28,
        49,
        28,
        40,
        2,
        15,
        49,
        28,
        24,
        40,
        28,
        0,
        49,
        0,
        13,
        49,
        12,
        41,
        11,
        49,
        47,
        48,
        49,
        0,
        40,
        7,
        16,
        49,
        1,
        0,
        49,
        21,
        43,
        42,
        6,
        0,
        0,
        14,
        49,
        15,
        10
      ],
      "weeklyHold": [
        49,
        32,
        38,
        1,
        1,
        8,
        41,
        25,
        2,
        35,
        37,
        20,
        34,
        1,
        15,
        40,
        0,
        48,
        21,
        0,
        16,
        19,
        2,
        17,
        12,
        48,
        1,
        20,
        49,
        0,
        30,
        27,
        0,
        0,
        30,
        10,
        39,
        14,
        14,
        38,
        42,
        31,
        37,
        49,
        49,
        26,
        49,
        37,
        0,
        49,
        3,
        18
      ],
      "weeklyBackordered": [
        49,
        10,
        11,
        24,
        36,
        8,
        49,
        18,
        49,
        46,
        26,
        23,
        3,
        49,
        39,
        49,
        13,
        49,
        14,
        4,
        21,
        49,
        28,
        28,
        22,
        8,
        12,
        13,
        39,
        49,
        11,
        48,
        46,
        47,
        17,
        25,
        28,
        0,
        37,
        20,
        12,
        48,
        5,
        49,
        0,
        49,
        49,
        12,
        27,
        24,
        34,
        1
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        19,
        38,
        26,
        10,
        23,
        12,
        20,
        36,
        48,
        49,
        48,
        21,
        22,
        39,
        36,
        35,
        17,
        4,
        9,
        0,
        35,
        21,
        25,
        34,
        31,
        35,
        16,
        49,
        1,
        41,
        0,
        0,
        49,
        27,
        48,
        49,
        47,
        31,
        12,
        49,
        0,
        0,
        49,
        37,
        44,
        7,
        10,
        0,
        30,
        36,
        14,
        6
      ],
      "weeklyRejected": [
        23,
        36,
        29,
        24,
        26,
        18,
        0,
        3,
        49,
        39,
        49,
        37,
        14,
        36,
        34,
        0,
        49,
        49,
        5,
        20,
        0,
        23,
        26,
        19,
        30,
        31,
        49,
        49,
        0,
        30,
        24,
        40,
        0,
        49,
        18,
        49,
        24,
        49,
        25,
        49,
        28,
        27,
        36,
        16,
        13,
        4,
        11,
        28,
        0,
        49,
        10,
        16
      ],
      "weeklyHold": [
        0,
        25,
        33,
        18,
        28,
        14,
        49,
        28,
        0,
        0,
        23,
        18,
        26,
        11,
        49,
        0,
        14,
        3,
        23,
        26,
        33,
        19,
        48,
        0,
        27,
        41,
        13,
        33,
        49,
        33,
        14,
        2,
        27,
        19,
        13,
        26,
        4,
        0,
        1,
        47,
        42,
        15,
        2,
        0,
        49,
        37,
        16,
        19,
        31,
        41,
        49,
        0
      ],
      "weeklyBackordered": [
        17,
        40,
        21,
        44,
        49,
        46,
        30,
        48,
        24,
        5,
        22,
        35,
        49,
        0,
        48,
        49,
        31,
        46,
        6,
        0,
        42,
        24,
        22,
        22,
        33,
        30,
        30,
        23,
        19,
        49,
        17,
        30,
        42,
        37,
        17,
        0,
        7,
        0,
        28,
        13,
        38,
        44,
        10,
        43,
        17,
        16,
        38,
        10,
        25,
        26,
        44,
        19
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        41,
        3,
        8,
        23,
        6,
        9,
        43,
        18,
        33,
        29,
        34,
        0,
        40,
        12,
        11,
        47,
        46,
        13,
        0,
        0,
        36,
        0,
        49,
        35,
        49,
        17,
        0,
        49,
        3,
        36,
        48,
        17,
        27,
        11,
        46,
        49,
        47,
        2,
        30,
        49,
        25,
        32,
        49,
        5,
        27,
        29,
        3,
        49,
        32,
        15,
        0,
        4
      ],
      "weeklyRejected": [
        49,
        3,
        30,
        47,
        24,
        24,
        47,
        35,
        49,
        9,
        37,
        47,
        14,
        48,
        35,
        25,
        0,
        29,
        2,
        27,
        49,
        49,
        12,
        22,
        9,
        48,
        36,
        23,
        48,
        38,
        0,
        29,
        48,
        35,
        0,
        49,
        23,
        26,
        20,
        13,
        35,
        11,
        38,
        10,
        23,
        1,
        1,
        0,
        0,
        22,
        10,
        22
      ],
      "weeklyHold": [
        27,
        2,
        41,
        17,
        0,
        18,
        11,
        11,
        10,
        49,
        46,
        46,
        1,
        32,
        41,
        0,
        27,
        0,
        14,
        13,
        38,
        23,
        17,
        49,
        49,
        29,
        33,
        0,
        49,
        22,
        21,
        7,
        0,
        27,
        19,
        0,
        32,
        0,
        1,
        10,
        32,
        28,
        0,
        36,
        48,
        23,
        21,
        46,
        3,
        14,
        9,
        31
      ],
      "weeklyBackordered": [
        33,
        32,
        0,
        45,
        20,
        36,
        47,
        39,
        45,
        0,
        0,
        48,
        26,
        0,
        48,
        33,
        5,
        49,
        0,
        40,
        44,
        44,
        24,
        44,
        13,
        3,
        42,
        0,
        1,
        36,
        0,
        15,
        17,
        33,
        0,
        45,
        5,
        5,
        12,
        0,
        26,
        48,
        4,
        40,
        28,
        35,
        28,
        13,
        22,
        31,
        45,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        17,
        38,
        17,
        26,
        43,
        7,
        32,
        32,
        15,
        26,
        49,
        0,
        45,
        42,
        49,
        1,
        36,
        5,
        14,
        39,
        44,
        25,
        40,
        0,
        16,
        24,
        0,
        49,
        1,
        38,
        0,
        49,
        49,
        33,
        21,
        37,
        25,
        23,
        43,
        49,
        18,
        26,
        35,
        49,
        45,
        0,
        20,
        48,
        0,
        47,
        0,
        8
      ],
      "weeklyRejected": [
        49,
        4,
        28,
        41,
        15,
        2,
        0,
        49,
        27,
        35,
        30,
        32,
        0,
        48,
        48,
        0,
        0,
        42,
        40,
        15,
        19,
        43,
        49,
        20,
        35,
        40,
        39,
        19,
        39,
        0,
        3,
        47,
        19,
        49,
        27,
        49,
        43,
        46,
        45,
        5,
        48,
        25,
        9,
        11,
        49,
        35,
        13,
        29,
        0,
        31,
        0,
        32
      ],
      "weeklyHold": [
        31,
        49,
        0,
        14,
        4,
        7,
        27,
        29,
        26,
        27,
        37,
        23,
        48,
        23,
        49,
        0,
        6,
        23,
        40,
        24,
        16,
        35,
        11,
        20,
        15,
        23,
        21,
        21,
        33,
        16,
        23,
        7,
        20,
        49,
        42,
        13,
        0,
        8,
        33,
        46,
        14,
        49,
        28,
        49,
        39,
        23,
        2,
        41,
        0,
        41,
        37,
        17
      ],
      "weeklyBackordered": [
        27,
        38,
        41,
        23,
        45,
        9,
        48,
        23,
        39,
        18,
        35,
        49,
        49,
        34,
        0,
        1,
        21,
        49,
        46,
        15,
        49,
        24,
        32,
        49,
        25,
        25,
        2,
        20,
        7,
        42,
        37,
        38,
        45,
        14,
        49,
        0,
        41,
        0,
        49,
        0,
        18,
        48,
        3,
        49,
        47,
        49,
        34,
        3,
        38,
        49,
        16,
        1
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        0,
        49,
        49,
        48,
        21,
        12,
        11,
        46,
        48,
        13,
        3,
        18,
        16,
        0,
        1,
        25,
        34,
        1,
        12,
        28,
        47,
        33,
        28,
        45,
        10,
        0,
        23,
        33,
        28,
        48,
        15,
        28,
        49,
        40,
        38,
        49,
        0,
        9,
        43,
        33,
        13,
        49,
        45,
        35,
        31,
        7,
        21,
        8,
        9,
        6,
        11,
        27
      ],
      "weeklyRejected": [
        28,
        5,
        24,
        43,
        9,
        0,
        49,
        32,
        35,
        4,
        31,
        0,
        15,
        48,
        32,
        9,
        36,
        44,
        31,
        5,
        28,
        20,
        26,
        29,
        27,
        49,
        49,
        14,
        5,
        39,
        32,
        47,
        15,
        5,
        13,
        49,
        49,
        1,
        22,
        0,
        43,
        38,
        12,
        23,
        37,
        18,
        0,
        16,
        24,
        12,
        9,
        49
      ],
      "weeklyHold": [
        15,
        0,
        34,
        18,
        7,
        33,
        0,
        32,
        13,
        36,
        47,
        31,
        24,
        20,
        25,
        13,
        9,
        25,
        24,
        28,
        34,
        49,
        0,
        19,
        30,
        48,
        34,
        15,
        49,
        49,
        48,
        0,
        44,
        49,
        42,
        0,
        17,
        1,
        48,
        11,
        13,
        18,
        0,
        29,
        20,
        30,
        0,
        49,
        0,
        6,
        26,
        38
      ],
      "weeklyBackordered": [
        30,
        31,
        48,
        13,
        4,
        9,
        0,
        28,
        25,
        36,
        43,
        49,
        5,
        49,
        21,
        12,
        3,
        38,
        30,
        26,
        44,
        35,
        27,
        49,
        15,
        6,
        17,
        10,
        11,
        23,
        46,
        0,
        46,
        49,
        33,
        3,
        18,
        2,
        19,
        4,
        48,
        41,
        24,
        45,
        6,
        0,
        49,
        36,
        37,
        49,
        22,
        4
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        14,
        31,
        5,
        8,
        49,
        2,
        2,
        33,
        48,
        34,
        0,
        0,
        46,
        41,
        21,
        0,
        49,
        19,
        6,
        36,
        32,
        36,
        49,
        9,
        0,
        44,
        13,
        33,
        49,
        29,
        0,
        3,
        33,
        26,
        31,
        17,
        47,
        15,
        49,
        49,
        29,
        49,
        49,
        41,
        21,
        0,
        42,
        28,
        34,
        33,
        36,
        20
      ],
      "weeklyRejected": [
        17,
        48,
        38,
        43,
        32,
        19,
        39,
        9,
        0,
        8,
        0,
        33,
        22,
        2,
        43,
        24,
        21,
        49,
        28,
        0,
        49,
        8,
        6,
        29,
        4,
        49,
        37,
        7,
        46,
        17,
        1,
        47,
        48,
        21,
        0,
        49,
        6,
        0,
        8,
        43,
        43,
        22,
        28,
        49,
        49,
        14,
        37,
        48,
        2,
        11,
        0,
        49
      ],
      "weeklyHold": [
        21,
        31,
        16,
        1,
        16,
        16,
        46,
        12,
        47,
        16,
        38,
        1,
        45,
        33,
        13,
        35,
        0,
        37,
        4,
        10,
        35,
        33,
        3,
        29,
        25,
        39,
        0,
        48,
        14,
        0,
        48,
        23,
        23,
        23,
        22,
        0,
        27,
        0,
        26,
        8,
        25,
        0,
        16,
        4,
        14,
        49,
        34,
        31,
        10,
        2,
        49,
        0
      ],
      "weeklyBackordered": [
        21,
        14,
        0,
        33,
        49,
        45,
        33,
        31,
        16,
        0,
        49,
        0,
        27,
        49,
        46,
        29,
        49,
        49,
        7,
        32,
        49,
        25,
        10,
        5,
        9,
        38,
        11,
        14,
        20,
        49,
        29,
        49,
        19,
        40,
        25,
        2,
        47,
        0,
        13,
        3,
        13,
        19,
        12,
        25,
        49,
        49,
        49,
        39,
        30,
        31,
        1,
        48
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        6,
        45,
        49,
        9,
        14,
        49,
        5,
        49,
        46,
        32,
        12,
        2,
        42,
        39,
        49,
        18,
        49,
        30,
        34,
        36,
        49,
        49,
        21,
        0,
        13,
        37,
        35,
        49,
        25,
        20,
        10,
        21,
        49,
        26,
        24,
        40,
        0,
        22,
        49,
        18,
        10,
        36,
        31,
        32,
        49,
        28,
        0,
        41,
        22,
        15,
        49,
        31
      ],
      "weeklyRejected": [
        48,
        49,
        49,
        14,
        0,
        9,
        27,
        44,
        49,
        14,
        40,
        42,
        33,
        8,
        48,
        21,
        27,
        41,
        45,
        48,
        5,
        33,
        5,
        1,
        19,
        49,
        13,
        43,
        41,
        12,
        35,
        47,
        42,
        10,
        18,
        39,
        22,
        10,
        1,
        0,
        15,
        40,
        49,
        24,
        42,
        38,
        3,
        29,
        23,
        49,
        27,
        29
      ],
      "weeklyHold": [
        19,
        17,
        39,
        16,
        4,
        45,
        24,
        13,
        4,
        19,
        31,
        42,
        43,
        24,
        19,
        15,
        25,
        32,
        18,
        16,
        49,
        16,
        15,
        0,
        37,
        48,
        41,
        46,
        49,
        0,
        48,
        38,
        0,
        39,
        45,
        33,
        48,
        2,
        42,
        37,
        38,
        28,
        10,
        24,
        9,
        10,
        8,
        7,
        0,
        49,
        22,
        44
      ],
      "weeklyBackordered": [
        16,
        43,
        34,
        24,
        16,
        44,
        28,
        22,
        7,
        41,
        28,
        30,
        22,
        49,
        18,
        23,
        2,
        26,
        25,
        20,
        0,
        14,
        0,
        24,
        1,
        1,
        35,
        36,
        35,
        49,
        38,
        0,
        31,
        47,
        0,
        0,
        41,
        3,
        11,
        4,
        22,
        29,
        19,
        49,
        15,
        35,
        41,
        30,
        0,
        47,
        49,
        36
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        1,
        29,
        26,
        25,
        1,
        15,
        27,
        36,
        39,
        0,
        36,
        21,
        49,
        0,
        36,
        38,
        39,
        21,
        0,
        39,
        35,
        49,
        49,
        17,
        14,
        20,
        8,
        21,
        16,
        22,
        0,
        0,
        27,
        5,
        37,
        49,
        33,
        0,
        49,
        49,
        0,
        21,
        49,
        43,
        32,
        26,
        8,
        49,
        29,
        12,
        36,
        17
      ],
      "weeklyRejected": [
        40,
        26,
        29,
        30,
        33,
        16,
        9,
        35,
        6,
        25,
        35,
        49,
        34,
        0,
        6,
        40,
        0,
        49,
        14,
        23,
        0,
        35,
        34,
        31,
        49,
        49,
        12,
        12,
        13,
        19,
        2,
        47,
        48,
        31,
        1,
        49,
        18,
        0,
        29,
        49,
        43,
        33,
        33,
        0,
        38,
        21,
        10,
        35,
        48,
        0,
        0,
        24
      ],
      "weeklyHold": [
        8,
        41,
        49,
        14,
        49,
        21,
        28,
        15,
        14,
        1,
        6,
        23,
        10,
        49,
        11,
        10,
        44,
        24,
        28,
        28,
        16,
        38,
        27,
        24,
        0,
        45,
        17,
        5,
        30,
        15,
        48,
        0,
        35,
        4,
        26,
        19,
        40,
        0,
        21,
        11,
        36,
        49,
        9,
        12,
        34,
        46,
        43,
        0,
        7,
        11,
        46,
        33
      ],
      "weeklyBackordered": [
        41,
        28,
        0,
        0,
        49,
        35,
        15,
        41,
        8,
        18,
        42,
        25,
        15,
        46,
        41,
        36,
        43,
        19,
        0,
        49,
        38,
        24,
        3,
        49,
        6,
        1,
        18,
        25,
        49,
        24,
        0,
        19,
        21,
        12,
        0,
        0,
        18,
        32,
        35,
        38,
        0,
        48,
        13,
        49,
        31,
        36,
        14,
        45,
        4,
        49,
        13,
        20
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        8,
        49,
        9,
        0,
        13,
        0,
        1,
        15,
        48,
        49,
        31,
        0,
        0,
        47,
        8,
        16,
        49,
        17,
        12,
        49,
        41,
        49,
        22,
        44,
        0,
        47,
        49,
        5,
        35,
        17,
        0,
        0,
        25,
        22,
        27,
        45,
        34,
        48,
        31,
        38,
        6,
        0,
        49,
        23,
        33,
        32,
        24,
        3,
        22,
        14,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        5,
        27,
        33,
        14,
        0,
        18,
        44,
        0,
        22,
        0,
        28,
        14,
        42,
        32,
        23,
        15,
        45,
        2,
        18,
        33,
        16,
        49,
        13,
        33,
        49,
        22,
        27,
        48,
        0,
        12,
        12,
        48,
        0,
        48,
        49,
        49,
        35,
        35,
        49,
        48,
        22,
        40,
        20,
        44,
        24,
        25,
        0,
        13,
        49,
        10,
        33
      ],
      "weeklyHold": [
        49,
        36,
        32,
        1,
        11,
        48,
        19,
        5,
        9,
        24,
        47,
        22,
        48,
        33,
        19,
        30,
        2,
        44,
        18,
        20,
        44,
        34,
        37,
        49,
        32,
        48,
        25,
        47,
        49,
        24,
        48,
        48,
        0,
        26,
        21,
        16,
        28,
        41,
        47,
        38,
        28,
        49,
        13,
        26,
        39,
        0,
        29,
        32,
        0,
        41,
        49,
        12
      ],
      "weeklyBackordered": [
        45,
        9,
        28,
        9,
        45,
        46,
        0,
        13,
        9,
        0,
        31,
        0,
        16,
        36,
        32,
        44,
        49,
        49,
        13,
        45,
        16,
        40,
        14,
        26,
        13,
        28,
        29,
        0,
        49,
        26,
        36,
        49,
        42,
        23,
        49,
        0,
        9,
        12,
        15,
        15,
        0,
        34,
        8,
        32,
        3,
        49,
        22,
        0,
        20,
        22,
        49,
        44
      ]
    },
    {
      "id": "93D87233",
      "weeklyAccepted": [
        0,
        40,
        30,
        41,
        49,
        31,
        1,
        47,
        26,
        0,
        24,
        8,
        28,
        11,
        21,
        30,
        49,
        17,
        0,
        13,
        33,
        28,
        0,
        28,
        11,
        37,
        17,
        16,
        36,
        39,
        41,
        2,
        29,
        0,
        25,
        29,
        23,
        10,
        27,
        44,
        17,
        37,
        49,
        49,
        49,
        24,
        0,
        21,
        49,
        49,
        49,
        27
      ],
      "weeklyRejected": [
        49,
        0,
        32,
        18,
        33,
        41,
        0,
        49,
        49,
        48,
        49,
        21,
        30,
        18,
        35,
        23,
        20,
        37,
        24,
        38,
        30,
        49,
        32,
        20,
        37,
        47,
        7,
        21,
        0,
        48,
        30,
        12,
        27,
        49,
        3,
        49,
        49,
        29,
        2,
        39,
        23,
        38,
        11,
        5,
        49,
        0,
        0,
        46,
        6,
        21,
        15,
        45
      ],
      "weeklyHold": [
        7,
        11,
        17,
        39,
        49,
        8,
        16,
        49,
        0,
        13,
        28,
        29,
        40,
        23,
        26,
        39,
        5,
        2,
        4,
        47,
        16,
        9,
        33,
        6,
        2,
        20,
        24,
        1,
        6,
        28,
        29,
        0,
        15,
        49,
        9,
        30,
        20,
        33,
        22,
        14,
        26,
        25,
        0,
        0,
        24,
        47,
        5,
        35,
        1,
        42,
        0,
        28
      ],
      "weeklyBackordered": [
        4,
        42,
        5,
        48,
        45,
        25,
        39,
        23,
        6,
        18,
        27,
        46,
        3,
        0,
        27,
        22,
        16,
        24,
        0,
        14,
        47,
        36,
        34,
        27,
        23,
        11,
        46,
        30,
        14,
        31,
        8,
        27,
        48,
        24,
        19,
        0,
        32,
        5,
        37,
        28,
        48,
        48,
        23,
        49,
        30,
        19,
        39,
        24,
        0,
        40,
        40,
        24
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        7,
        47,
        18,
        11,
        49,
        32,
        49,
        27,
        18,
        14,
        44,
        0,
        32,
        0,
        27,
        23,
        35,
        0,
        35,
        0,
        7,
        22,
        41,
        26,
        10,
        11,
        28,
        27,
        22,
        30,
        42,
        22,
        20,
        19,
        48,
        33,
        42,
        26,
        22,
        43,
        49,
        44,
        7,
        12,
        31,
        26,
        4,
        49,
        6,
        49,
        6,
        0
      ],
      "weeklyRejected": [
        39,
        25,
        35,
        16,
        43,
        15,
        0,
        28,
        39,
        2,
        0,
        45,
        39,
        48,
        42,
        8,
        40,
        48,
        18,
        29,
        21,
        49,
        44,
        22,
        4,
        34,
        49,
        10,
        0,
        48,
        47,
        47,
        20,
        14,
        33,
        49,
        49,
        0,
        27,
        33,
        43,
        47,
        49,
        17,
        49,
        24,
        16,
        2,
        0,
        39,
        0,
        43
      ],
      "weeklyHold": [
        27,
        26,
        43,
        1,
        8,
        38,
        16,
        16,
        0,
        0,
        47,
        43,
        48,
        34,
        10,
        2,
        8,
        10,
        30,
        3,
        0,
        35,
        30,
        0,
        45,
        45,
        36,
        2,
        30,
        21,
        18,
        37,
        0,
        42,
        18,
        10,
        27,
        0,
        39,
        30,
        49,
        24,
        11,
        41,
        36,
        40,
        26,
        41,
        14,
        36,
        44,
        47
      ],
      "weeklyBackordered": [
        9,
        49,
        39,
        26,
        12,
        8,
        12,
        25,
        43,
        0,
        21,
        49,
        38,
        49,
        29,
        1,
        49,
        28,
        40,
        20,
        37,
        49,
        30,
        6,
        44,
        8,
        12,
        49,
        9,
        39,
        20,
        11,
        29,
        49,
        3,
        0,
        24,
        4,
        24,
        3,
        15,
        48,
        25,
        45,
        23,
        49,
        29,
        22,
        0,
        39,
        40,
        1
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        11,
        20,
        26,
        28,
        31,
        9,
        7,
        29,
        40,
        5,
        49,
        0,
        14,
        21,
        29,
        21,
        39,
        0,
        0,
        32,
        19,
        26,
        15,
        44,
        34,
        47,
        3,
        23,
        18,
        39,
        4,
        3,
        13,
        27,
        40,
        32,
        23,
        32,
        49,
        49,
        0,
        2,
        49,
        33,
        32,
        2,
        0,
        12,
        12,
        49,
        49,
        16
      ],
      "weeklyRejected": [
        41,
        12,
        6,
        33,
        15,
        15,
        19,
        49,
        0,
        40,
        33,
        13,
        32,
        41,
        45,
        21,
        11,
        49,
        19,
        20,
        49,
        13,
        14,
        43,
        13,
        17,
        22,
        7,
        42,
        0,
        43,
        19,
        24,
        49,
        44,
        41,
        49,
        19,
        20,
        25,
        31,
        44,
        2,
        13,
        49,
        9,
        46,
        43,
        14,
        49,
        11,
        35
      ],
      "weeklyHold": [
        13,
        31,
        31,
        13,
        29,
        42,
        31,
        48,
        12,
        46,
        38,
        49,
        33,
        38,
        0,
        12,
        15,
        48,
        16,
        0,
        35,
        11,
        31,
        0,
        39,
        48,
        0,
        49,
        0,
        0,
        45,
        15,
        0,
        25,
        20,
        21,
        49,
        17,
        49,
        0,
        19,
        36,
        8,
        31,
        14,
        49,
        40,
        11,
        0,
        24,
        12,
        20
      ],
      "weeklyBackordered": [
        23,
        49,
        8,
        17,
        9,
        19,
        24,
        22,
        41,
        21,
        18,
        47,
        4,
        30,
        0,
        22,
        49,
        0,
        20,
        49,
        49,
        27,
        38,
        4,
        16,
        7,
        25,
        35,
        1,
        30,
        44,
        42,
        29,
        15,
        0,
        0,
        21,
        31,
        28,
        30,
        48,
        48,
        22,
        49,
        48,
        0,
        10,
        28,
        0,
        30,
        41,
        1
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        17,
        27,
        0,
        9,
        49,
        49,
        33,
        28,
        40,
        7,
        35,
        9,
        17,
        9,
        27,
        19,
        49,
        17,
        21,
        0,
        0,
        37,
        17,
        42,
        16,
        33,
        6,
        37,
        1,
        49,
        37,
        46,
        49,
        36,
        1,
        32,
        41,
        48,
        20,
        49,
        12,
        28,
        17,
        24,
        49,
        3,
        0,
        49,
        35,
        48,
        16,
        31
      ],
      "weeklyRejected": [
        36,
        0,
        27,
        21,
        5,
        6,
        21,
        18,
        31,
        30,
        24,
        29,
        49,
        48,
        48,
        2,
        39,
        41,
        49,
        30,
        6,
        28,
        9,
        49,
        38,
        49,
        31,
        23,
        16,
        35,
        40,
        14,
        4,
        49,
        40,
        43,
        34,
        23,
        9,
        0,
        42,
        33,
        4,
        20,
        49,
        0,
        43,
        6,
        41,
        49,
        0,
        34
      ],
      "weeklyHold": [
        32,
        46,
        45,
        1,
        13,
        29,
        22,
        23,
        49,
        49,
        47,
        42,
        3,
        8,
        38,
        38,
        29,
        44,
        33,
        0,
        0,
        34,
        6,
        28,
        36,
        28,
        42,
        42,
        49,
        14,
        41,
        40,
        0,
        16,
        26,
        6,
        0,
        23,
        35,
        16,
        30,
        19,
        10,
        49,
        36,
        35,
        48,
        32,
        7,
        44,
        49,
        26
      ],
      "weeklyBackordered": [
        32,
        49,
        48,
        15,
        49,
        37,
        44,
        30,
        25,
        3,
        49,
        47,
        3,
        19,
        36,
        35,
        47,
        23,
        7,
        37,
        40,
        4,
        36,
        21,
        16,
        1,
        49,
        8,
        11,
        39,
        0,
        49,
        28,
        0,
        8,
        27,
        35,
        1,
        12,
        1,
        27,
        27,
        0,
        49,
        11,
        35,
        0,
        33,
        0,
        21,
        32,
        38
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        27,
        40,
        49,
        30,
        46,
        22,
        3,
        38,
        48,
        0,
        36,
        8,
        15,
        45,
        47,
        13,
        39,
        9,
        0,
        34,
        0,
        4,
        49,
        0,
        4,
        41,
        27,
        49,
        26,
        49,
        6,
        18,
        22,
        30,
        33,
        39,
        40,
        0,
        7,
        49,
        11,
        33,
        38,
        46,
        27,
        37,
        0,
        35,
        21,
        49,
        8,
        8
      ],
      "weeklyRejected": [
        49,
        0,
        20,
        23,
        34,
        40,
        49,
        0,
        49,
        35,
        0,
        27,
        11,
        48,
        16,
        17,
        0,
        40,
        32,
        42,
        49,
        20,
        6,
        49,
        23,
        49,
        49,
        32,
        48,
        45,
        36,
        33,
        48,
        49,
        1,
        23,
        34,
        13,
        1,
        0,
        39,
        14,
        7,
        23,
        31,
        0,
        35,
        33,
        0,
        49,
        10,
        41
      ],
      "weeklyHold": [
        49,
        0,
        9,
        1,
        25,
        45,
        0,
        9,
        47,
        37,
        9,
        46,
        47,
        46,
        8,
        43,
        0,
        38,
        8,
        0,
        15,
        38,
        0,
        23,
        15,
        14,
        37,
        0,
        49,
        0,
        29,
        36,
        0,
        38,
        23,
        11,
        33,
        23,
        36,
        38,
        0,
        9,
        12,
        15,
        19,
        49,
        3,
        49,
        49,
        6,
        9,
        7
      ],
      "weeklyBackordered": [
        47,
        0,
        48,
        41,
        49,
        46,
        49,
        49,
        0,
        14,
        43,
        49,
        49,
        46,
        0,
        39,
        27,
        38,
        15,
        0,
        38,
        0,
        12,
        28,
        9,
        25,
        12,
        18,
        12,
        33,
        0,
        24,
        18,
        13,
        45,
        28,
        30,
        14,
        35,
        32,
        0,
        34,
        27,
        18,
        14,
        40,
        49,
        16,
        4,
        24,
        42,
        21
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyAccepted": [
        11,
        49,
        26,
        21,
        8,
        8,
        21,
        33,
        10,
        49,
        49,
        2,
        37,
        32,
        6,
        8,
        44,
        0,
        6,
        34,
        0,
        49,
        24,
        11,
        0,
        40,
        19,
        13,
        15,
        36,
        16,
        49,
        22,
        0,
        48,
        46,
        46,
        31,
        49,
        49,
        39,
        32,
        22,
        28,
        36,
        13,
        0,
        17,
        0,
        0,
        8,
        9
      ],
      "weeklyRejected": [
        49,
        3,
        20,
        1,
        49,
        30,
        35,
        26,
        49,
        12,
        0,
        49,
        49,
        34,
        48,
        16,
        41,
        37,
        0,
        0,
        34,
        26,
        2,
        32,
        17,
        35,
        19,
        13,
        43,
        27,
        48,
        23,
        27,
        17,
        34,
        29,
        17,
        26,
        4,
        28,
        21,
        33,
        49,
        6,
        49,
        29,
        0,
        9,
        0,
        18,
        0,
        29
      ],
      "weeklyHold": [
        17,
        18,
        49,
        1,
        16,
        39,
        40,
        2,
        12,
        49,
        32,
        25,
        48,
        1,
        29,
        24,
        12,
        43,
        17,
        12,
        41,
        11,
        7,
        0,
        14,
        48,
        21,
        12,
        34,
        0,
        48,
        0,
        17,
        36,
        24,
        21,
        47,
        3,
        49,
        28,
        19,
        39,
        0,
        12,
        22,
        28,
        44,
        18,
        0,
        31,
        6,
        22
      ],
      "weeklyBackordered": [
        36,
        25,
        20,
        49,
        41,
        23,
        26,
        35,
        40,
        49,
        49,
        41,
        3,
        49,
        26,
        21,
        11,
        47,
        21,
        49,
        42,
        9,
        11,
        17,
        41,
        37,
        24,
        1,
        42,
        42,
        0,
        49,
        27,
        40,
        10,
        12,
        29,
        17,
        43,
        34,
        44,
        48,
        29,
        49,
        30,
        0,
        21,
        10,
        0,
        36,
        39,
        21
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        0,
        15,
        22,
        24,
        1,
        23,
        6,
        34,
        29,
        1,
        26,
        0,
        46,
        0,
        8,
        49,
        19,
        28,
        49,
        33,
        42,
        18,
        18,
        14,
        19,
        23,
        42,
        43,
        9,
        46,
        36,
        7,
        29,
        28,
        48,
        46,
        45,
        46,
        31,
        25,
        31,
        30,
        24,
        29,
        32,
        0,
        0,
        32,
        31,
        49,
        9,
        0
      ],
      "weeklyRejected": [
        49,
        24,
        22,
        13,
        25,
        10,
        18,
        35,
        0,
        21,
        9,
        15,
        40,
        48,
        7,
        34,
        2,
        15,
        24,
        0,
        49,
        49,
        39,
        32,
        9,
        20,
        45,
        19,
        29,
        41,
        33,
        10,
        48,
        5,
        0,
        39,
        22,
        3,
        2,
        17,
        48,
        40,
        21,
        7,
        30,
        8,
        11,
        35,
        27,
        49,
        0,
        17
      ],
      "weeklyHold": [
        0,
        49,
        49,
        1,
        41,
        40,
        0,
        2,
        19,
        40,
        0,
        40,
        45,
        28,
        33,
        0,
        0,
        0,
        0,
        28,
        1,
        49,
        0,
        21,
        49,
        20,
        49,
        44,
        39,
        22,
        48,
        30,
        0,
        32,
        0,
        27,
        19,
        19,
        49,
        19,
        26,
        34,
        37,
        15,
        27,
        12,
        31,
        0,
        4,
        43,
        44,
        35
      ],
      "weeklyBackordered": [
        8,
        0,
        0,
        28,
        42,
        13,
        0,
        34,
        37,
        15,
        49,
        15,
        3,
        49,
        35,
        49,
        49,
        31,
        7,
        7,
        45,
        0,
        4,
        25,
        19,
        2,
        49,
        49,
        32,
        16,
        20,
        10,
        43,
        14,
        0,
        26,
        31,
        29,
        24,
        17,
        8,
        45,
        10,
        9,
        35,
        49,
        49,
        20,
        13,
        42,
        36,
        25
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        0,
        6,
        49,
        48,
        21,
        18,
        13,
        21,
        48,
        21,
        46,
        21,
        49,
        18,
        39,
        25,
        44,
        24,
        0,
        49,
        15,
        49,
        29,
        39,
        19,
        47,
        46,
        49,
        18,
        49,
        49,
        8,
        49,
        5,
        42,
        26,
        46,
        48,
        49,
        28,
        29,
        11,
        49,
        15,
        49,
        0,
        23,
        30,
        25,
        31,
        25,
        3
      ],
      "weeklyRejected": [
        11,
        21,
        10,
        47,
        40,
        36,
        49,
        8,
        0,
        1,
        25,
        41,
        33,
        37,
        48,
        14,
        0,
        22,
        20,
        36,
        16,
        0,
        0,
        8,
        49,
        39,
        40,
        49,
        41,
        9,
        31,
        40,
        48,
        49,
        46,
        37,
        31,
        38,
        1,
        28,
        39,
        3,
        29,
        29,
        25,
        32,
        37,
        48,
        0,
        48,
        0,
        49
      ],
      "weeklyHold": [
        29,
        46,
        36,
        17,
        11,
        48,
        33,
        19,
        49,
        39,
        29,
        8,
        48,
        13,
        26,
        37,
        0,
        48,
        11,
        34,
        25,
        22,
        42,
        3,
        42,
        46,
        9,
        31,
        19,
        12,
        48,
        47,
        22,
        22,
        32,
        1,
        49,
        7,
        1,
        49,
        0,
        29,
        49,
        33,
        28,
        35,
        42,
        49,
        28,
        32,
        46,
        29
      ],
      "weeklyBackordered": [
        22,
        28,
        16,
        0,
        49,
        45,
        49,
        37,
        37,
        0,
        17,
        35,
        3,
        0,
        24,
        7,
        44,
        4,
        4,
        0,
        49,
        22,
        16,
        16,
        44,
        30,
        38,
        18,
        1,
        47,
        18,
        40,
        33,
        36,
        3,
        0,
        1,
        23,
        24,
        40,
        48,
        48,
        31,
        26,
        9,
        6,
        49,
        7,
        19,
        29,
        49,
        13
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyAccepted": [
        17,
        49,
        17,
        33,
        16,
        0,
        29,
        22,
        30,
        14,
        30,
        12,
        0,
        6,
        10,
        4,
        37,
        2,
        0,
        42,
        21,
        23,
        49,
        0,
        15,
        26,
        31,
        42,
        14,
        20,
        0,
        25,
        49,
        0,
        27,
        22,
        45,
        9,
        18,
        23,
        49,
        28,
        49,
        45,
        24,
        0,
        37,
        2,
        1,
        19,
        0,
        43
      ],
      "weeklyRejected": [
        31,
        29,
        12,
        11,
        16,
        47,
        20,
        49,
        34,
        43,
        4,
        48,
        35,
        46,
        48,
        14,
        23,
        41,
        22,
        14,
        22,
        33,
        10,
        45,
        14,
        44,
        44,
        24,
        48,
        41,
        24,
        39,
        30,
        15,
        19,
        49,
        35,
        32,
        49,
        29,
        35,
        7,
        26,
        0,
        33,
        29,
        23,
        2,
        17,
        28,
        19,
        49
      ],
      "weeklyHold": [
        41,
        15,
        38,
        16,
        0,
        2,
        32,
        25,
        17,
        26,
        34,
        9,
        1,
        14,
        37,
        38,
        0,
        11,
        9,
        49,
        19,
        28,
        28,
        33,
        15,
        9,
        26,
        32,
        34,
        6,
        31,
        15,
        0,
        36,
        25,
        14,
        0,
        31,
        9,
        28,
        22,
        31,
        22,
        41,
        12,
        31,
        0,
        49,
        17,
        31,
        48,
        33
      ],
      "weeklyBackordered": [
        0,
        19,
        25,
        36,
        46,
        9,
        6,
        39,
        11,
        48,
        17,
        10,
        12,
        37,
        38,
        4,
        44,
        49,
        2,
        15,
        46,
        26,
        0,
        18,
        17,
        5,
        32,
        39,
        1,
        49,
        3,
        49,
        34,
        18,
        20,
        0,
        17,
        0,
        33,
        0,
        48,
        48,
        2,
        42,
        25,
        35,
        32,
        23,
        40,
        36,
        16,
        35
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        39,
        35,
        16,
        15,
        4,
        0,
        1,
        21,
        0,
        38,
        22,
        0,
        21,
        10,
        18,
        21,
        34,
        40,
        0,
        32,
        8,
        49,
        35,
        13,
        30,
        30,
        35,
        38,
        12,
        49,
        17,
        24,
        27,
        10,
        14,
        47,
        13,
        17,
        23,
        28,
        17,
        33,
        49,
        23,
        49,
        23,
        9,
        49,
        28,
        2,
        22,
        8
      ],
      "weeklyRejected": [
        49,
        49,
        19,
        44,
        20,
        0,
        49,
        0,
        45,
        38,
        49,
        21,
        31,
        35,
        45,
        35,
        0,
        49,
        17,
        30,
        0,
        41,
        18,
        21,
        2,
        48,
        49,
        29,
        48,
        27,
        2,
        36,
        0,
        20,
        28,
        45,
        8,
        0,
        25,
        0,
        37,
        18,
        32,
        42,
        49,
        20,
        6,
        28,
        12,
        6,
        34,
        49
      ],
      "weeklyHold": [
        10,
        16,
        49,
        17,
        32,
        19,
        29,
        28,
        13,
        7,
        30,
        49,
        17,
        23,
        26,
        31,
        18,
        35,
        38,
        24,
        20,
        49,
        29,
        2,
        0,
        48,
        18,
        49,
        49,
        17,
        32,
        20,
        14,
        38,
        20,
        0,
        17,
        0,
        45,
        47,
        40,
        39,
        29,
        0,
        28,
        14,
        34,
        49,
        1,
        47,
        49,
        16
      ],
      "weeklyBackordered": [
        0,
        6,
        40,
        12,
        13,
        46,
        0,
        12,
        0,
        19,
        39,
        27,
        44,
        0,
        14,
        16,
        49,
        30,
        0,
        49,
        49,
        39,
        21,
        29,
        13,
        13,
        20,
        20,
        24,
        43,
        25,
        49,
        48,
        14,
        5,
        31,
        46,
        2,
        21,
        6,
        22,
        48,
        27,
        41,
        26,
        32,
        29,
        30,
        23,
        45,
        49,
        49
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyAccepted": [
        49,
        36,
        49,
        29,
        39,
        48,
        1,
        49,
        37,
        9,
        41,
        17,
        25,
        49,
        17,
        44,
        42,
        25,
        0,
        32,
        26,
        0,
        26,
        28,
        34,
        7,
        9,
        49,
        34,
        40,
        24,
        9,
        49,
        44,
        48,
        36,
        47,
        7,
        23,
        49,
        21,
        2,
        31,
        34,
        23,
        49,
        36,
        49,
        49,
        19,
        2,
        45
      ],
      "weeklyRejected": [
        22,
        0,
        15,
        47,
        0,
        18,
        29,
        45,
        18,
        40,
        19,
        36,
        23,
        32,
        2,
        30,
        0,
        39,
        40,
        32,
        23,
        30,
        7,
        23,
        26,
        12,
        21,
        17,
        48,
        0,
        39,
        47,
        48,
        23,
        16,
        49,
        42,
        7,
        1,
        9,
        45,
        30,
        49,
        42,
        49,
        28,
        7,
        49,
        30,
        41,
        0,
        10
      ],
      "weeklyHold": [
        49,
        0,
        47,
        34,
        49,
        44,
        49,
        47,
        0,
        43,
        47,
        36,
        20,
        42,
        26,
        31,
        0,
        31,
        1,
        0,
        0,
        33,
        0,
        22,
        15,
        1,
        14,
        9,
        49,
        0,
        38,
        17,
        16,
        4,
        48,
        20,
        23,
        14,
        49,
        0,
        0,
        33,
        26,
        13,
        49,
        13,
        47,
        26,
        1,
        20,
        49,
        17
      ],
      "weeklyBackordered": [
        31,
        30,
        0,
        32,
        49,
        6,
        13,
        32,
        0,
        4,
        0,
        36,
        3,
        48,
        37,
        8,
        9,
        34,
        0,
        17,
        32,
        22,
        0,
        16,
        29,
        1,
        49,
        19,
        11,
        42,
        33,
        37,
        48,
        40,
        48,
        0,
        5,
        48,
        37,
        0,
        48,
        48,
        8,
        21,
        0,
        25,
        49,
        14,
        43,
        11,
        49,
        25
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        31,
        45,
        26,
        12,
        1,
        0,
        1,
        49,
        48,
        21,
        12,
        17,
        6,
        0,
        1,
        41,
        49,
        48,
        20,
        43,
        14,
        41,
        15,
        40,
        36,
        47,
        38,
        19,
        1,
        37,
        38,
        49,
        4,
        33,
        48,
        2,
        31,
        48,
        42,
        49,
        48,
        40,
        22,
        0,
        32,
        8,
        38,
        32,
        31,
        40,
        12,
        48
      ],
      "weeklyRejected": [
        49,
        26,
        9,
        22,
        29,
        26,
        49,
        7,
        49,
        1,
        45,
        26,
        9,
        12,
        7,
        9,
        8,
        48,
        49,
        49,
        1,
        19,
        0,
        49,
        20,
        35,
        49,
        44,
        16,
        48,
        36,
        33,
        48,
        25,
        43,
        21,
        49,
        40,
        1,
        27,
        5,
        22,
        44,
        7,
        37,
        33,
        32,
        49,
        17,
        43,
        0,
        42
      ],
      "weeklyHold": [
        6,
        2,
        49,
        1,
        27,
        32,
        22,
        2,
        9,
        34,
        30,
        45,
        1,
        49,
        0,
        0,
        9,
        40,
        32,
        24,
        0,
        37,
        0,
        28,
        10,
        33,
        31,
        17,
        27,
        7,
        48,
        11,
        0,
        25,
        34,
        45,
        44,
        16,
        34,
        9,
        18,
        28,
        32,
        49,
        10,
        2,
        6,
        31,
        0,
        19,
        5,
        49
      ],
      "weeklyBackordered": [
        0,
        25,
        0,
        7,
        40,
        44,
        49,
        1,
        49,
        28,
        29,
        37,
        3,
        49,
        1,
        9,
        43,
        39,
        29,
        0,
        10,
        24,
        49,
        35,
        27,
        1,
        49,
        49,
        10,
        0,
        32,
        49,
        20,
        42,
        0,
        0,
        5,
        48,
        47,
        41,
        3,
        25,
        48,
        49,
        19,
        35,
        37,
        6,
        0,
        24,
        31,
        30
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        33,
        49,
        36,
        2,
        20,
        8,
        9,
        33,
        24,
        5,
        39,
        1,
        44,
        27,
        30,
        35,
        38,
        24,
        16,
        33,
        23,
        39,
        11,
        42,
        17,
        22,
        49,
        29,
        25,
        49,
        1,
        39,
        35,
        18,
        28,
        49,
        47,
        10,
        43,
        41,
        24,
        49,
        9,
        14,
        18,
        45,
        0,
        49,
        34,
        49,
        0,
        20
      ],
      "weeklyRejected": [
        42,
        19,
        16,
        21,
        49,
        0,
        14,
        49,
        44,
        14,
        17,
        34,
        1,
        33,
        24,
        39,
        20,
        49,
        27,
        14,
        31,
        36,
        0,
        49,
        25,
        49,
        38,
        26,
        33,
        23,
        35,
        40,
        21,
        48,
        49,
        49,
        15,
        25,
        1,
        20,
        48,
        45,
        49,
        42,
        49,
        37,
        0,
        23,
        19,
        49,
        14,
        43
      ],
      "weeklyHold": [
        49,
        48,
        31,
        31,
        13,
        44,
        14,
        32,
        4,
        1,
        10,
        1,
        27,
        23,
        49,
        0,
        9,
        1,
        28,
        0,
        20,
        17,
        4,
        49,
        25,
        4,
        15,
        23,
        34,
        0,
        48,
        12,
        0,
        18,
        48,
        41,
        39,
        0,
        49,
        49,
        33,
        20,
        5,
        11,
        39,
        1,
        46,
        32,
        0,
        5,
        35,
        0
      ],
      "weeklyBackordered": [
        20,
        0,
        16,
        2,
        24,
        46,
        41,
        23,
        44,
        0,
        42,
        20,
        25,
        49,
        40,
        40,
        14,
        49,
        0,
        30,
        0,
        2,
        23,
        7,
        30,
        24,
        44,
        28,
        19,
        26,
        44,
        49,
        30,
        49,
        49,
        0,
        12,
        0,
        30,
        23,
        0,
        48,
        19,
        18,
        49,
        21,
        25,
        17,
        0,
        49,
        23,
        33
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        16,
        23,
        49,
        23,
        1,
        13,
        1,
        5,
        16,
        4,
        49,
        17,
        47,
        44,
        47,
        48,
        34,
        0,
        30,
        44,
        21,
        23,
        49,
        33,
        16,
        47,
        29,
        20,
        25,
        45,
        25,
        24,
        49,
        0,
        25,
        14,
        5,
        45,
        45,
        49,
        0,
        11,
        49,
        36,
        49,
        16,
        18,
        37,
        23,
        49,
        49,
        40
      ],
      "weeklyRejected": [
        39,
        31,
        35,
        11,
        30,
        31,
        30,
        23,
        37,
        33,
        13,
        40,
        22,
        13,
        48,
        1,
        20,
        25,
        49,
        28,
        0,
        16,
        34,
        49,
        34,
        47,
        0,
        8,
        48,
        9,
        23,
        26,
        39,
        46,
        8,
        48,
        0,
        26,
        1,
        49,
        26,
        25,
        36,
        25,
        49,
        47,
        49,
        10,
        1,
        49,
        22,
        38
      ],
      "weeklyHold": [
        23,
        37,
        38,
        23,
        19,
        41,
        4,
        2,
        46,
        22,
        35,
        27,
        41,
        1,
        16,
        0,
        14,
        31,
        0,
        8,
        49,
        23,
        0,
        18,
        49,
        41,
        22,
        23,
        34,
        0,
        48,
        48,
        18,
        48,
        22,
        20,
        49,
        4,
        40,
        18,
        18,
        36,
        17,
        49,
        49,
        23,
        29,
        45,
        0,
        9,
        40,
        32
      ],
      "weeklyBackordered": [
        35,
        22,
        13,
        49,
        49,
        45,
        16,
        32,
        13,
        32,
        18,
        34,
        27,
        34,
        19,
        33,
        49,
        11,
        33,
        30,
        49,
        19,
        17,
        24,
        34,
        15,
        34,
        25,
        23,
        39,
        5,
        49,
        29,
        17,
        4,
        0,
        1,
        0,
        27,
        4,
        21,
        48,
        29,
        49,
        25,
        11,
        25,
        44,
        24,
        46,
        25,
        23
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        19,
        28,
        49,
        48,
        30,
        9,
        8,
        49,
        35,
        20,
        35,
        0,
        16,
        46,
        31,
        31,
        47,
        13,
        0,
        39,
        38,
        33,
        49,
        41,
        31,
        47,
        2,
        25,
        1,
        1,
        8,
        5,
        49,
        49,
        46,
        46,
        43,
        2,
        28,
        49,
        6,
        49,
        49,
        22,
        35,
        11,
        0,
        22,
        42,
        24,
        47,
        48
      ],
      "weeklyRejected": [
        49,
        31,
        32,
        46,
        6,
        0,
        44,
        31,
        12,
        19,
        49,
        32,
        18,
        33,
        15,
        30,
        0,
        49,
        39,
        4,
        47,
        22,
        0,
        29,
        20,
        32,
        41,
        19,
        48,
        17,
        38,
        41,
        28,
        34,
        37,
        33,
        20,
        9,
        11,
        14,
        1,
        0,
        38,
        3,
        49,
        26,
        24,
        1,
        10,
        36,
        15,
        38
      ],
      "weeklyHold": [
        49,
        24,
        23,
        1,
        17,
        29,
        2,
        49,
        32,
        49,
        0,
        35,
        43,
        42,
        11,
        0,
        0,
        16,
        0,
        49,
        36,
        33,
        37,
        20,
        27,
        1,
        37,
        5,
        49,
        49,
        34,
        34,
        0,
        28,
        12,
        12,
        5,
        16,
        43,
        20,
        26,
        49,
        20,
        49,
        49,
        2,
        0,
        38,
        0,
        49,
        2,
        12
      ],
      "weeklyBackordered": [
        24,
        43,
        0,
        42,
        43,
        23,
        32,
        41,
        3,
        31,
        21,
        23,
        14,
        16,
        25,
        39,
        5,
        45,
        12,
        47,
        42,
        22,
        17,
        19,
        1,
        9,
        24,
        10,
        15,
        35,
        15,
        49,
        42,
        32,
        0,
        4,
        23,
        15,
        13,
        37,
        41,
        16,
        0,
        10,
        0,
        49,
        37,
        10,
        30,
        27,
        12,
        33
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        9,
        49,
        31,
        28,
        18,
        49,
        24,
        45,
        44,
        25,
        49,
        0,
        5,
        49,
        11,
        31,
        49,
        0,
        5,
        39,
        0,
        19,
        27,
        45,
        26,
        25,
        1,
        34,
        11,
        26,
        23,
        11,
        49,
        34,
        35,
        33,
        27,
        15,
        12,
        49,
        9,
        34,
        49,
        29,
        34,
        0,
        6,
        5,
        0,
        49,
        25,
        6
      ],
      "weeklyRejected": [
        49,
        49,
        12,
        0,
        6,
        32,
        0,
        0,
        33,
        42,
        27,
        33,
        0,
        48,
        8,
        41,
        0,
        49,
        0,
        35,
        13,
        29,
        46,
        10,
        33,
        49,
        48,
        1,
        19,
        47,
        40,
        0,
        13,
        1,
        4,
        49,
        25,
        29,
        9,
        23,
        30,
        0,
        0,
        0,
        49,
        26,
        25,
        12,
        0,
        49,
        0,
        39
      ],
      "weeklyHold": [
        0,
        25,
        46,
        1,
        18,
        48,
        29,
        16,
        24,
        19,
        47,
        43,
        1,
        49,
        39,
        7,
        0,
        17,
        24,
        0,
        12,
        22,
        5,
        18,
        3,
        38,
        0,
        24,
        49,
        17,
        48,
        3,
        2,
        35,
        4,
        33,
        9,
        38,
        20,
        49,
        34,
        41,
        0,
        3,
        37,
        24,
        0,
        42,
        24,
        49,
        49,
        19
      ],
      "weeklyBackordered": [
        49,
        5,
        0,
        26,
        28,
        46,
        35,
        16,
        30,
        0,
        49,
        0,
        47,
        0,
        49,
        10,
        24,
        16,
        0,
        10,
        41,
        24,
        0,
        35,
        22,
        33,
        1,
        14,
        11,
        49,
        42,
        19,
        32,
        22,
        5,
        0,
        49,
        0,
        17,
        24,
        6,
        48,
        10,
        10,
        15,
        26,
        43,
        8,
        0,
        49,
        38,
        21
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyAccepted": [
        14,
        29,
        16,
        24,
        31,
        0,
        45,
        36,
        48,
        0,
        28,
        0,
        28,
        28,
        9,
        19,
        49,
        17,
        7,
        20,
        4,
        6,
        14,
        27,
        49,
        41,
        49,
        26,
        6,
        12,
        0,
        0,
        49,
        16,
        48,
        44,
        30,
        48,
        26,
        49,
        12,
        49,
        49,
        49,
        8,
        24,
        49,
        2,
        46,
        7,
        15,
        33
      ],
      "weeklyRejected": [
        46,
        12,
        37,
        1,
        16,
        33,
        22,
        27,
        12,
        47,
        28,
        0,
        34,
        48,
        2,
        22,
        21,
        47,
        43,
        24,
        25,
        49,
        17,
        44,
        14,
        42,
        12,
        2,
        13,
        46,
        39,
        47,
        48,
        32,
        26,
        49,
        5,
        13,
        25,
        23,
        26,
        49,
        44,
        8,
        25,
        5,
        4,
        49,
        26,
        49,
        0,
        26
      ],
      "weeklyHold": [
        20,
        49,
        17,
        1,
        49,
        24,
        3,
        23,
        0,
        11,
        26,
        6,
        32,
        23,
        15,
        18,
        0,
        48,
        2,
        49,
        0,
        21,
        11,
        32,
        0,
        33,
        17,
        13,
        35,
        28,
        48,
        0,
        0,
        46,
        28,
        49,
        48,
        13,
        15,
        14,
        0,
        41,
        26,
        39,
        49,
        49,
        43,
        46,
        24,
        25,
        42,
        20
      ],
      "weeklyBackordered": [
        18,
        29,
        0,
        49,
        48,
        46,
        36,
        1,
        32,
        24,
        31,
        39,
        21,
        36,
        35,
        6,
        49,
        20,
        12,
        12,
        32,
        8,
        18,
        29,
        1,
        21,
        33,
        13,
        31,
        40,
        14,
        6,
        7,
        49,
        0,
        9,
        10,
        47,
        13,
        13,
        34,
        48,
        48,
        49,
        20,
        14,
        49,
        0,
        49,
        37,
        16,
        44
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyAccepted": [
        36,
        27,
        49,
        21,
        1,
        24,
        1,
        34,
        33,
        16,
        43,
        28,
        28,
        3,
        7,
        47,
        39,
        31,
        22,
        7,
        14,
        37,
        0,
        12,
        0,
        47,
        34,
        45,
        11,
        47,
        22,
        0,
        2,
        8,
        48,
        40,
        46,
        48,
        31,
        43,
        14,
        49,
        35,
        1,
        29,
        22,
        0,
        40,
        38,
        23,
        27,
        4
      ],
      "weeklyRejected": [
        49,
        22,
        43,
        32,
        38,
        0,
        35,
        20,
        25,
        24,
        38,
        0,
        47,
        28,
        2,
        10,
        25,
        49,
        34,
        16,
        49,
        15,
        14,
        15,
        10,
        0,
        37,
        0,
        48,
        5,
        2,
        2,
        42,
        49,
        49,
        49,
        49,
        19,
        17,
        17,
        33,
        0,
        0,
        46,
        49,
        45,
        0,
        43,
        17,
        49,
        12,
        49
      ],
      "weeklyHold": [
        14,
        6,
        49,
        16,
        7,
        38,
        29,
        23,
        22,
        41,
        32,
        7,
        15,
        28,
        27,
        9,
        36,
        31,
        16,
        19,
        0,
        9,
        49,
        8,
        0,
        1,
        23,
        8,
        3,
        0,
        32,
        14,
        9,
        43,
        48,
        0,
        6,
        23,
        40,
        32,
        7,
        48,
        13,
        23,
        38,
        27,
        9,
        0,
        1,
        7,
        17,
        20
      ],
      "weeklyBackordered": [
        34,
        20,
        0,
        13,
        21,
        46,
        8,
        11,
        0,
        7,
        49,
        0,
        3,
        36,
        49,
        49,
        49,
        37,
        0,
        48,
        49,
        20,
        32,
        19,
        33,
        12,
        49,
        2,
        26,
        49,
        42,
        36,
        2,
        1,
        1,
        8,
        31,
        16,
        49,
        18,
        1,
        25,
        28,
        37,
        34,
        0,
        21,
        5,
        0,
        41,
        49,
        42
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        0,
        49,
        45,
        2,
        49,
        11,
        25,
        24,
        20,
        6,
        46,
        0,
        20,
        34,
        15,
        49,
        33,
        48,
        39,
        0,
        48,
        17,
        43,
        39,
        0,
        0,
        1,
        30,
        49,
        49,
        0,
        0,
        49,
        22,
        0,
        17,
        11,
        25,
        33,
        12,
        14,
        8,
        49,
        7,
        49,
        15,
        6,
        46,
        28,
        31,
        14,
        36
      ],
      "weeklyRejected": [
        30,
        15,
        10,
        47,
        0,
        22,
        49,
        42,
        42,
        24,
        15,
        28,
        11,
        26,
        48,
        24,
        10,
        46,
        39,
        49,
        11,
        1,
        36,
        0,
        32,
        49,
        21,
        2,
        34,
        0,
        0,
        0,
        10,
        16,
        21,
        49,
        16,
        16,
        18,
        27,
        18,
        9,
        46,
        31,
        35,
        0,
        0,
        1,
        5,
        49,
        0,
        38
      ],
      "weeklyHold": [
        41,
        13,
        28,
        1,
        49,
        48,
        49,
        49,
        9,
        18,
        47,
        7,
        6,
        1,
        33,
        27,
        5,
        26,
        49,
        24,
        47,
        12,
        7,
        40,
        38,
        28,
        17,
        34,
        49,
        33,
        43,
        13,
        20,
        18,
        0,
        4,
        1,
        0,
        1,
        49,
        10,
        49,
        26,
        33,
        49,
        41,
        43,
        2,
        12,
        7,
        49,
        11
      ],
      "weeklyBackordered": [
        36,
        0,
        0,
        27,
        44,
        29,
        10,
        11,
        0,
        3,
        0,
        36,
        36,
        25,
        49,
        23,
        49,
        36,
        0,
        4,
        34,
        0,
        20,
        35,
        24,
        24,
        13,
        0,
        42,
        49,
        3,
        0,
        48,
        49,
        0,
        0,
        12,
        0,
        49,
        42,
        6,
        48,
        0,
        39,
        26,
        0,
        15,
        6,
        37,
        24,
        28,
        21
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        30,
        28,
        9,
        41,
        15,
        0,
        9,
        34,
        39,
        5,
        33,
        0,
        49,
        34,
        19,
        0,
        49,
        13,
        25,
        49,
        0,
        15,
        33,
        45,
        32,
        31,
        2,
        0,
        15,
        49,
        49,
        41,
        21,
        4,
        48,
        38,
        47,
        19,
        28,
        44,
        11,
        49,
        15,
        0,
        49,
        0,
        3,
        29,
        1,
        20,
        31,
        18
      ],
      "weeklyRejected": [
        27,
        23,
        37,
        0,
        42,
        30,
        49,
        12,
        33,
        19,
        16,
        9,
        39,
        48,
        48,
        38,
        16,
        49,
        49,
        27,
        22,
        26,
        32,
        45,
        0,
        49,
        31,
        49,
        16,
        48,
        4,
        47,
        25,
        9,
        25,
        49,
        49,
        49,
        1,
        5,
        34,
        49,
        49,
        0,
        26,
        15,
        3,
        21,
        0,
        13,
        0,
        18
      ],
      "weeklyHold": [
        2,
        31,
        41,
        1,
        22,
        48,
        0,
        2,
        30,
        49,
        27,
        45,
        48,
        44,
        0,
        3,
        31,
        26,
        6,
        33,
        0,
        29,
        36,
        12,
        49,
        35,
        49,
        14,
        35,
        34,
        48,
        1,
        0,
        49,
        27,
        10,
        31,
        25,
        49,
        44,
        25,
        25,
        15,
        26,
        49,
        45,
        0,
        34,
        26,
        8,
        43,
        18
      ],
      "weeklyBackordered": [
        38,
        29,
        18,
        1,
        39,
        43,
        36,
        49,
        47,
        15,
        45,
        41,
        49,
        41,
        0,
        49,
        49,
        49,
        11,
        44,
        33,
        37,
        25,
        21,
        17,
        10,
        16,
        22,
        10,
        49,
        7,
        22,
        30,
        21,
        0,
        1,
        31,
        0,
        0,
        27,
        6,
        48,
        42,
        37,
        45,
        25,
        17,
        37,
        15,
        10,
        49,
        28
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        47,
        48,
        8,
        34,
        27,
        0,
        27,
        49,
        36,
        14,
        49,
        21,
        18,
        49,
        25,
        29,
        28,
        31,
        24,
        10,
        33,
        25,
        49,
        49,
        35,
        3,
        22,
        34,
        21,
        19,
        43,
        18,
        49,
        21,
        48,
        49,
        38,
        10,
        26,
        49,
        20,
        1,
        49,
        49,
        26,
        17,
        33,
        13,
        25,
        41,
        29,
        5
      ],
      "weeklyRejected": [
        34,
        19,
        17,
        41,
        24,
        22,
        34,
        49,
        48,
        1,
        24,
        37,
        0,
        39,
        27,
        48,
        0,
        42,
        37,
        22,
        37,
        30,
        0,
        43,
        8,
        47,
        49,
        46,
        23,
        16,
        49,
        30,
        12,
        3,
        21,
        14,
        29,
        8,
        42,
        14,
        41,
        41,
        5,
        8,
        21,
        15,
        0,
        18,
        0,
        37,
        26,
        14
      ],
      "weeklyHold": [
        34,
        40,
        49,
        1,
        26,
        0,
        16,
        16,
        11,
        44,
        47,
        19,
        1,
        26,
        27,
        14,
        0,
        29,
        23,
        13,
        19,
        43,
        44,
        8,
        6,
        1,
        16,
        31,
        30,
        29,
        45,
        13,
        0,
        16,
        44,
        1,
        36,
        4,
        40,
        47,
        37,
        49,
        14,
        31,
        23,
        43,
        33,
        42,
        0,
        34,
        13,
        49
      ],
      "weeklyBackordered": [
        23,
        40,
        19,
        6,
        44,
        37,
        28,
        45,
        36,
        32,
        47,
        49,
        3,
        20,
        45,
        1,
        17,
        23,
        49,
        0,
        29,
        40,
        8,
        46,
        1,
        20,
        5,
        30,
        28,
        40,
        36,
        29,
        48,
        49,
        26,
        19,
        8,
        34,
        31,
        11,
        23,
        48,
        10,
        44,
        22,
        5,
        49,
        23,
        0,
        37,
        49,
        25
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        0,
        8,
        30,
        15,
        45,
        29,
        1,
        14,
        26,
        7,
        47,
        11,
        49,
        29,
        1,
        40,
        0,
        24,
        33,
        0,
        36,
        25,
        49,
        30,
        37,
        36,
        15,
        25,
        1,
        27,
        13,
        32,
        49,
        43,
        28,
        38,
        47,
        28,
        33,
        20,
        21,
        10,
        39,
        20,
        11,
        42,
        19,
        49,
        42,
        49,
        49,
        36
      ],
      "weeklyRejected": [
        34,
        0,
        2,
        0,
        0,
        0,
        0,
        6,
        38,
        14,
        0,
        21,
        13,
        33,
        17,
        29,
        2,
        32,
        0,
        0,
        49,
        43,
        37,
        49,
        20,
        49,
        26,
        11,
        42,
        19,
        45,
        47,
        48,
        5,
        43,
        49,
        36,
        15,
        19,
        49,
        37,
        32,
        41,
        29,
        49,
        17,
        9,
        36,
        23,
        28,
        9,
        32
      ],
      "weeklyHold": [
        22,
        18,
        41,
        1,
        49,
        44,
        0,
        38,
        19,
        19,
        41,
        1,
        17,
        41,
        20,
        12,
        0,
        22,
        49,
        0,
        12,
        27,
        28,
        38,
        44,
        40,
        0,
        0,
        45,
        0,
        48,
        0,
        20,
        29,
        16,
        49,
        25,
        30,
        49,
        30,
        20,
        41,
        14,
        27,
        35,
        30,
        31,
        0,
        0,
        15,
        34,
        37
      ],
      "weeklyBackordered": [
        49,
        28,
        42,
        28,
        49,
        40,
        25,
        46,
        10,
        20,
        15,
        23,
        30,
        4,
        0,
        43,
        49,
        24,
        0,
        0,
        31,
        25,
        3,
        49,
        2,
        18,
        49,
        40,
        5,
        24,
        34,
        49,
        25,
        37,
        11,
        0,
        1,
        9,
        42,
        9,
        0,
        5,
        40,
        40,
        22,
        21,
        9,
        4,
        20,
        49,
        41,
        49
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        18,
        14,
        21,
        48,
        49,
        0,
        10,
        49,
        25,
        17,
        26,
        22,
        41,
        22,
        31,
        35,
        27,
        0,
        4,
        49,
        49,
        21,
        29,
        17,
        4,
        32,
        29,
        0,
        33,
        49,
        23,
        24,
        32,
        21,
        30,
        11,
        12,
        48,
        28,
        49,
        4,
        27,
        48,
        49,
        49,
        0,
        0,
        49,
        0,
        38,
        0,
        14
      ],
      "weeklyRejected": [
        37,
        15,
        13,
        4,
        41,
        25,
        5,
        49,
        33,
        48,
        12,
        0,
        26,
        44,
        48,
        44,
        32,
        31,
        5,
        19,
        22,
        49,
        28,
        44,
        26,
        49,
        23,
        20,
        47,
        10,
        28,
        21,
        48,
        16,
        37,
        37,
        49,
        0,
        23,
        3,
        30,
        49,
        1,
        0,
        45,
        23,
        7,
        49,
        0,
        23,
        0,
        49
      ],
      "weeklyHold": [
        25,
        0,
        43,
        33,
        3,
        44,
        0,
        44,
        2,
        33,
        28,
        3,
        1,
        41,
        0,
        28,
        23,
        48,
        30,
        6,
        49,
        21,
        9,
        0,
        49,
        19,
        0,
        17,
        49,
        0,
        45,
        7,
        36,
        44,
        4,
        46,
        28,
        18,
        31,
        27,
        22,
        34,
        13,
        0,
        13,
        6,
        32,
        30,
        0,
        49,
        24,
        39
      ],
      "weeklyBackordered": [
        49,
        49,
        0,
        11,
        31,
        24,
        15,
        46,
        6,
        7,
        4,
        28,
        11,
        46,
        10,
        20,
        47,
        49,
        0,
        26,
        38,
        16,
        9,
        8,
        10,
        22,
        28,
        42,
        49,
        29,
        4,
        27,
        11,
        38,
        17,
        0,
        28,
        48,
        2,
        36,
        0,
        16,
        1,
        0,
        31,
        36,
        21,
        6,
        33,
        49,
        22,
        19
      ]
    },
    {
      "id": "93D87233",
      "weeklyAccepted": [
        0,
        34,
        49,
        46,
        8,
        16,
        4,
        38,
        42,
        49,
        12,
        37,
        14,
        35,
        34,
        26,
        17,
        7,
        0,
        37,
        49,
        10,
        26,
        23,
        0,
        15,
        36,
        0,
        31,
        26,
        10,
        3,
        49,
        21,
        13,
        32,
        45,
        0,
        49,
        49,
        39,
        36,
        49,
        49,
        0,
        46,
        0,
        14,
        23,
        10,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        20,
        49,
        47,
        23,
        31,
        6,
        49,
        1,
        16,
        0,
        9,
        35,
        18,
        33,
        27,
        11,
        47,
        46,
        24,
        44,
        45,
        49,
        29,
        36,
        27,
        5,
        35,
        45,
        0,
        26,
        11,
        37,
        49,
        49,
        49,
        30,
        33,
        6,
        49,
        27,
        49,
        49,
        0,
        49,
        11,
        4,
        49,
        0,
        49,
        7,
        28
      ],
      "weeklyHold": [
        30,
        24,
        45,
        13,
        49,
        35,
        49,
        12,
        26,
        26,
        47,
        20,
        41,
        7,
        45,
        22,
        44,
        25,
        14,
        42,
        30,
        0,
        0,
        41,
        40,
        48,
        19,
        0,
        37,
        0,
        37,
        22,
        17,
        26,
        34,
        43,
        43,
        0,
        20,
        47,
        0,
        34,
        4,
        49,
        7,
        47,
        0,
        19,
        0,
        11,
        49,
        40
      ],
      "weeklyBackordered": [
        36,
        32,
        3,
        35,
        43,
        46,
        16,
        27,
        49,
        0,
        47,
        23,
        3,
        18,
        43,
        32,
        29,
        13,
        2,
        45,
        49,
        15,
        12,
        33,
        1,
        30,
        1,
        16,
        10,
        26,
        44,
        48,
        6,
        36,
        0,
        0,
        14,
        0,
        43,
        49,
        0,
        48,
        0,
        49,
        5,
        34,
        27,
        23,
        0,
        49,
        49,
        29
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        0,
        26,
        44,
        20,
        11,
        24,
        9,
        37,
        39,
        0,
        43,
        0,
        28,
        27,
        12,
        14,
        37,
        24,
        28,
        11,
        16,
        35,
        0,
        39,
        18,
        17,
        33,
        31,
        1,
        49,
        24,
        0,
        49,
        6,
        45,
        49,
        47,
        48,
        36,
        22,
        17,
        37,
        49,
        28,
        35,
        43,
        0,
        31,
        18,
        18,
        12,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        27,
        0,
        16,
        10,
        8,
        34,
        49,
        35,
        22,
        12,
        37,
        8,
        18,
        39,
        32,
        41,
        38,
        24,
        15,
        17,
        42,
        49,
        26,
        12,
        47,
        34,
        31,
        0,
        4,
        31,
        40,
        15,
        43,
        49,
        49,
        23,
        11,
        9,
        19,
        0,
        49,
        23,
        49,
        8,
        49,
        47,
        14,
        49,
        13,
        0
      ],
      "weeklyHold": [
        29,
        21,
        18,
        1,
        49,
        48,
        0,
        2,
        22,
        30,
        37,
        21,
        40,
        42,
        0,
        47,
        28,
        35,
        27,
        16,
        15,
        28,
        26,
        32,
        34,
        23,
        37,
        10,
        28,
        12,
        48,
        18,
        0,
        21,
        43,
        32,
        29,
        24,
        37,
        12,
        26,
        25,
        3,
        17,
        16,
        14,
        26,
        8,
        0,
        21,
        31,
        29
      ],
      "weeklyBackordered": [
        15,
        46,
        0,
        0,
        49,
        42,
        35,
        32,
        25,
        0,
        38,
        9,
        17,
        1,
        19,
        49,
        49,
        35,
        0,
        33,
        27,
        21,
        0,
        2,
        36,
        4,
        49,
        1,
        21,
        49,
        21,
        49,
        0,
        49,
        0,
        12,
        24,
        21,
        0,
        34,
        1,
        48,
        16,
        49,
        23,
        19,
        49,
        21,
        33,
        0,
        49,
        28
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        0,
        38,
        29,
        13,
        18,
        47,
        49,
        47,
        48,
        23,
        46,
        8,
        48,
        0,
        30,
        49,
        28,
        7,
        0,
        49,
        15,
        27,
        39,
        20,
        29,
        32,
        26,
        49,
        5,
        27,
        2,
        11,
        20,
        28,
        48,
        45,
        11,
        12,
        3,
        47,
        2,
        19,
        7,
        48,
        49,
        11,
        35,
        2,
        21,
        34,
        31,
        8
      ],
      "weeklyRejected": [
        0,
        49,
        41,
        35,
        0,
        39,
        49,
        0,
        24,
        10,
        29,
        49,
        33,
        46,
        45,
        0,
        12,
        49,
        0,
        16,
        13,
        31,
        11,
        10,
        36,
        49,
        39,
        28,
        39,
        23,
        47,
        25,
        48,
        0,
        19,
        49,
        6,
        13,
        15,
        7,
        45,
        34,
        37,
        1,
        26,
        10,
        13,
        28,
        0,
        40,
        8,
        43
      ],
      "weeklyHold": [
        45,
        32,
        49,
        25,
        28,
        40,
        42,
        2,
        0,
        20,
        40,
        13,
        45,
        1,
        49,
        26,
        9,
        17,
        36,
        10,
        49,
        29,
        38,
        0,
        42,
        32,
        0,
        14,
        28,
        16,
        48,
        1,
        45,
        45,
        48,
        39,
        27,
        0,
        49,
        40,
        25,
        39,
        34,
        32,
        49,
        49,
        4,
        0,
        46,
        11,
        49,
        49
      ],
      "weeklyBackordered": [
        49,
        47,
        12,
        47,
        36,
        33,
        37,
        35,
        8,
        0,
        49,
        45,
        17,
        34,
        46,
        49,
        17,
        49,
        40,
        27,
        40,
        0,
        23,
        29,
        48,
        25,
        18,
        42,
        5,
        49,
        46,
        23,
        15,
        22,
        0,
        7,
        31,
        28,
        31,
        0,
        48,
        33,
        26,
        49,
        0,
        12,
        49,
        37,
        31,
        49,
        13,
        12
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        17,
        12,
        31,
        7,
        36,
        18,
        9,
        29,
        16,
        7,
        47,
        12,
        42,
        31,
        25,
        10,
        49,
        24,
        28,
        17,
        12,
        22,
        49,
        49,
        17,
        32,
        46,
        13,
        1,
        37,
        16,
        29,
        38,
        0,
        46,
        5,
        37,
        11,
        35,
        47,
        1,
        12,
        32,
        44,
        49,
        17,
        5,
        21,
        7,
        49,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        49,
        33,
        30,
        43,
        32,
        23,
        39,
        49,
        25,
        38,
        31,
        22,
        48,
        39,
        0,
        0,
        21,
        49,
        23,
        43,
        47,
        34,
        28,
        39,
        49,
        49,
        28,
        10,
        25,
        47,
        22,
        16,
        41,
        38,
        49,
        49,
        17,
        1,
        27,
        18,
        2,
        5,
        1,
        49,
        46,
        49,
        12,
        6,
        49,
        0,
        26
      ],
      "weeklyHold": [
        49,
        40,
        49,
        15,
        6,
        42,
        26,
        38,
        0,
        19,
        7,
        31,
        14,
        28,
        35,
        34,
        4,
        48,
        11,
        30,
        18,
        28,
        39,
        16,
        0,
        22,
        45,
        9,
        45,
        0,
        18,
        23,
        0,
        43,
        30,
        21,
        47,
        0,
        49,
        17,
        30,
        35,
        3,
        47,
        10,
        0,
        10,
        39,
        15,
        43,
        1,
        24
      ],
      "weeklyBackordered": [
        49,
        49,
        13,
        41,
        49,
        44,
        41,
        3,
        10,
        20,
        49,
        23,
        25,
        14,
        49,
        34,
        49,
        25,
        41,
        29,
        44,
        0,
        19,
        13,
        35,
        29,
        1,
        24,
        35,
        49,
        33,
        37,
        36,
        36,
        21,
        12,
        30,
        25,
        0,
        49,
        0,
        25,
        11,
        49,
        43,
        10,
        7,
        49,
        20,
        17,
        43,
        27
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        9,
        31,
        25,
        0,
        12,
        0,
        13,
        22,
        47,
        40,
        7,
        15,
        0,
        11,
        24,
        49,
        30,
        1,
        8,
        49,
        49,
        34,
        28,
        38,
        32,
        47,
        28,
        44,
        49,
        44,
        49,
        6,
        49,
        14,
        48,
        16,
        7,
        8,
        14,
        45,
        38,
        38,
        15,
        37,
        49,
        41,
        0,
        49,
        8,
        49,
        22,
        45
      ],
      "weeklyRejected": [
        20,
        14,
        47,
        21,
        18,
        16,
        11,
        49,
        45,
        19,
        40,
        28,
        31,
        37,
        48,
        1,
        19,
        49,
        37,
        6,
        17,
        49,
        0,
        17,
        39,
        49,
        0,
        44,
        40,
        19,
        35,
        41,
        48,
        49,
        0,
        49,
        47,
        40,
        1,
        3,
        48,
        49,
        39,
        0,
        49,
        25,
        28,
        48,
        9,
        27,
        14,
        34
      ],
      "weeklyHold": [
        25,
        30,
        38,
        1,
        48,
        16,
        25,
        2,
        22,
        26,
        0,
        1,
        35,
        41,
        35,
        24,
        0,
        41,
        0,
        14,
        43,
        27,
        43,
        8,
        49,
        1,
        18,
        21,
        49,
        40,
        48,
        0,
        46,
        1,
        48,
        3,
        2,
        12,
        31,
        49,
        37,
        28,
        14,
        49,
        49,
        34,
        25,
        49,
        0,
        49,
        37,
        40
      ],
      "weeklyBackordered": [
        45,
        21,
        29,
        38,
        19,
        10,
        49,
        7,
        27,
        0,
        31,
        37,
        6,
        40,
        27,
        36,
        49,
        49,
        0,
        10,
        40,
        1,
        5,
        49,
        34,
        12,
        3,
        0,
        22,
        4,
        0,
        49,
        13,
        20,
        11,
        0,
        14,
        19,
        5,
        31,
        48,
        48,
        4,
        48,
        16,
        18,
        49,
        24,
        19,
        31,
        38,
        27
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        0,
        2,
        30,
        27,
        49,
        0,
        42,
        31,
        41,
        4,
        33,
        0,
        39,
        33,
        14,
        20,
        49,
        27,
        11,
        39,
        13,
        39,
        38,
        49,
        33,
        13,
        6,
        30,
        42,
        49,
        23,
        8,
        49,
        2,
        26,
        17,
        21,
        19,
        24,
        17,
        17,
        29,
        49,
        30,
        32,
        0,
        29,
        0,
        3,
        49,
        49,
        14
      ],
      "weeklyRejected": [
        14,
        3,
        32,
        12,
        13,
        34,
        15,
        3,
        28,
        17,
        8,
        35,
        14,
        29,
        29,
        19,
        9,
        23,
        41,
        24,
        25,
        49,
        39,
        41,
        5,
        40,
        49,
        14,
        23,
        13,
        44,
        47,
        35,
        11,
        2,
        49,
        43,
        44,
        1,
        47,
        22,
        46,
        38,
        5,
        35,
        49,
        34,
        3,
        30,
        27,
        0,
        24
      ],
      "weeklyHold": [
        0,
        29,
        49,
        1,
        46,
        34,
        49,
        6,
        16,
        14,
        40,
        1,
        29,
        17,
        13,
        23,
        38,
        41,
        41,
        0,
        9,
        33,
        24,
        0,
        44,
        42,
        0,
        0,
        34,
        36,
        48,
        14,
        29,
        31,
        8,
        43,
        34,
        0,
        30,
        19,
        14,
        49,
        14,
        49,
        49,
        36,
        26,
        48,
        0,
        0,
        45,
        30
      ],
      "weeklyBackordered": [
        25,
        8,
        28,
        36,
        33,
        46,
        32,
        30,
        49,
        14,
        6,
        49,
        8,
        7,
        6,
        13,
        22,
        32,
        0,
        25,
        22,
        43,
        28,
        38,
        48,
        20,
        45,
        10,
        24,
        20,
        10,
        14,
        48,
        42,
        0,
        0,
        14,
        4,
        25,
        38,
        6,
        13,
        29,
        49,
        17,
        0,
        49,
        21,
        21,
        3,
        40,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        17,
        24,
        49,
        4,
        24,
        42,
        23,
        24,
        48,
        34,
        49,
        0,
        36,
        49,
        10,
        35,
        40,
        7,
        39,
        48,
        22,
        49,
        49,
        40,
        0,
        47,
        27,
        49,
        29,
        27,
        0,
        4,
        38,
        33,
        40,
        19,
        45,
        10,
        49,
        26,
        11,
        49,
        0,
        46,
        49,
        2,
        0,
        0,
        0,
        23,
        46,
        10
      ],
      "weeklyRejected": [
        22,
        0,
        49,
        0,
        49,
        49,
        49,
        0,
        49,
        13,
        37,
        18,
        44,
        48,
        17,
        26,
        10,
        39,
        25,
        42,
        8,
        25,
        26,
        35,
        26,
        49,
        37,
        37,
        1,
        8,
        48,
        47,
        27,
        16,
        18,
        32,
        49,
        47,
        1,
        10,
        27,
        33,
        49,
        0,
        34,
        43,
        0,
        27,
        0,
        36,
        16,
        49
      ],
      "weeklyHold": [
        10,
        32,
        36,
        18,
        37,
        34,
        39,
        18,
        33,
        21,
        26,
        31,
        45,
        17,
        49,
        2,
        19,
        16,
        18,
        25,
        46,
        23,
        49,
        17,
        0,
        47,
        49,
        26,
        36,
        22,
        48,
        0,
        10,
        3,
        48,
        49,
        30,
        8,
        27,
        29,
        32,
        34,
        10,
        40,
        27,
        38,
        29,
        32,
        0,
        0,
        28,
        49
      ],
      "weeklyBackordered": [
        49,
        28,
        18,
        16,
        49,
        33,
        19,
        20,
        25,
        9,
        36,
        44,
        33,
        28,
        5,
        38,
        29,
        49,
        19,
        38,
        0,
        33,
        39,
        42,
        19,
        14,
        12,
        7,
        27,
        49,
        25,
        45,
        12,
        16,
        0,
        0,
        49,
        33,
        18,
        31,
        28,
        44,
        13,
        34,
        0,
        38,
        15,
        34,
        20,
        34,
        20,
        1
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        30,
        31,
        26,
        38,
        35,
        0,
        23,
        12,
        48,
        0,
        36,
        0,
        8,
        49,
        47,
        16,
        46,
        0,
        11,
        36,
        3,
        22,
        11,
        17,
        7,
        22,
        43,
        26,
        33,
        49,
        0,
        24,
        49,
        0,
        12,
        26,
        33,
        48,
        49,
        49,
        12,
        49,
        13,
        46,
        49,
        44,
        36,
        0,
        14,
        31,
        34,
        9
      ],
      "weeklyRejected": [
        31,
        3,
        32,
        2,
        27,
        15,
        7,
        49,
        49,
        1,
        24,
        28,
        4,
        5,
        35,
        25,
        8,
        46,
        37,
        2,
        46,
        0,
        49,
        1,
        39,
        0,
        44,
        0,
        48,
        0,
        49,
        33,
        25,
        49,
        19,
        29,
        6,
        31,
        1,
        15,
        0,
        36,
        49,
        15,
        43,
        16,
        8,
        12,
        12,
        34,
        0,
        49
      ],
      "weeklyHold": [
        16,
        0,
        40,
        20,
        49,
        26,
        48,
        35,
        0,
        21,
        39,
        25,
        40,
        20,
        16,
        49,
        0,
        10,
        43,
        2,
        39,
        0,
        6,
        0,
        24,
        31,
        1,
        8,
        23,
        7,
        28,
        46,
        0,
        28,
        15,
        17,
        26,
        13,
        1,
        32,
        33,
        36,
        8,
        17,
        49,
        28,
        23,
        49,
        17,
        0,
        28,
        49
      ],
      "weeklyBackordered": [
        42,
        33,
        48,
        37,
        49,
        16,
        39,
        3,
        31,
        45,
        49,
        29,
        11,
        33,
        21,
        12,
        49,
        26,
        18,
        41,
        31,
        41,
        13,
        0,
        1,
        32,
        7,
        6,
        16,
        49,
        33,
        22,
        36,
        28,
        0,
        0,
        21,
        0,
        44,
        0,
        27,
        48,
        2,
        49,
        24,
        28,
        49,
        3,
        22,
        23,
        43,
        17
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        0,
        49,
        15,
        0,
        6,
        0,
        1,
        27,
        26,
        1,
        20,
        11,
        34,
        30,
        46,
        36,
        44,
        0,
        25,
        39,
        15,
        49,
        11,
        28,
        2,
        45,
        0,
        6,
        1,
        49,
        18,
        1,
        49,
        29,
        34,
        34,
        11,
        48,
        37,
        20,
        11,
        18,
        49,
        49,
        20,
        9,
        0,
        9,
        0,
        40,
        7,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        25,
        20,
        32,
        3,
        49,
        25,
        49,
        1,
        13,
        0,
        32,
        26,
        19,
        31,
        39,
        49,
        43,
        12,
        7,
        40,
        4,
        17,
        13,
        49,
        12,
        43,
        48,
        11,
        6,
        4,
        23,
        18,
        29,
        49,
        27,
        41,
        5,
        23,
        29,
        25,
        23,
        19,
        49,
        25,
        5,
        0,
        19,
        26,
        14,
        34
      ],
      "weeklyHold": [
        40,
        26,
        35,
        1,
        26,
        28,
        0,
        2,
        0,
        26,
        47,
        1,
        21,
        1,
        35,
        46,
        27,
        12,
        24,
        49,
        49,
        18,
        38,
        49,
        7,
        48,
        23,
        25,
        11,
        6,
        41,
        6,
        3,
        12,
        48,
        49,
        18,
        3,
        18,
        29,
        49,
        25,
        4,
        49,
        19,
        15,
        44,
        38,
        0,
        45,
        10,
        23
      ],
      "weeklyBackordered": [
        40,
        1,
        0,
        6,
        48,
        40,
        49,
        15,
        49,
        17,
        29,
        30,
        24,
        26,
        41,
        33,
        49,
        49,
        0,
        49,
        34,
        0,
        8,
        7,
        48,
        41,
        33,
        49,
        31,
        0,
        11,
        7,
        11,
        49,
        0,
        0,
        1,
        35,
        49,
        49,
        0,
        31,
        0,
        45,
        46,
        41,
        0,
        3,
        0,
        36,
        30,
        5
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        0,
        26,
        26,
        48,
        45,
        31,
        1,
        18,
        37,
        45,
        0,
        41,
        39,
        10,
        1,
        2,
        33,
        6,
        23,
        39,
        6,
        24,
        29,
        0,
        22,
        21,
        15,
        35,
        1,
        49,
        0,
        33,
        49,
        35,
        18,
        23,
        47,
        26,
        49,
        29,
        23,
        0,
        47,
        12,
        44,
        0,
        8,
        36,
        9,
        28,
        0,
        13
      ],
      "weeklyRejected": [
        39,
        0,
        49,
        24,
        30,
        13,
        21,
        2,
        25,
        30,
        0,
        7,
        21,
        24,
        41,
        33,
        5,
        45,
        49,
        27,
        0,
        47,
        49,
        22,
        22,
        19,
        29,
        15,
        42,
        32,
        1,
        40,
        33,
        49,
        21,
        49,
        24,
        11,
        1,
        14,
        45,
        28,
        0,
        17,
        49,
        27,
        0,
        44,
        4,
        41,
        17,
        30
      ],
      "weeklyHold": [
        20,
        20,
        27,
        1,
        8,
        46,
        34,
        33,
        0,
        10,
        40,
        34,
        37,
        15,
        49,
        17,
        0,
        39,
        26,
        42,
        13,
        8,
        9,
        31,
        49,
        45,
        0,
        37,
        13,
        8,
        48,
        5,
        24,
        0,
        0,
        49,
        27,
        0,
        28,
        24,
        26,
        32,
        49,
        49,
        0,
        49,
        28,
        27,
        21,
        16,
        40,
        49
      ],
      "weeklyBackordered": [
        29,
        15,
        3,
        49,
        49,
        46,
        32,
        49,
        0,
        27,
        43,
        40,
        34,
        19,
        32,
        30,
        20,
        25,
        0,
        27,
        42,
        0,
        0,
        32,
        31,
        1,
        22,
        35,
        1,
        49,
        17,
        46,
        23,
        32,
        5,
        21,
        23,
        0,
        36,
        49,
        27,
        45,
        0,
        43,
        25,
        11,
        23,
        13,
        43,
        43,
        32,
        49
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        0,
        45,
        48,
        36,
        42,
        35,
        10,
        29,
        10,
        23,
        16,
        0,
        2,
        47,
        1,
        18,
        21,
        8,
        22,
        27,
        34,
        39,
        16,
        21,
        48,
        47,
        0,
        26,
        20,
        49,
        16,
        7,
        49,
        11,
        26,
        2,
        47,
        22,
        49,
        49,
        49,
        45,
        44,
        49,
        49,
        10,
        0,
        8,
        3,
        40,
        49,
        48
      ],
      "weeklyRejected": [
        37,
        22,
        24,
        5,
        32,
        0,
        31,
        16,
        46,
        38,
        22,
        1,
        26,
        48,
        34,
        0,
        30,
        2,
        49,
        12,
        16,
        29,
        49,
        16,
        30,
        30,
        28,
        11,
        37,
        32,
        10,
        20,
        26,
        19,
        40,
        48,
        46,
        26,
        5,
        19,
        35,
        6,
        26,
        38,
        46,
        39,
        3,
        49,
        1,
        37,
        19,
        46
      ],
      "weeklyHold": [
        32,
        0,
        38,
        21,
        29,
        30,
        20,
        28,
        33,
        19,
        5,
        20,
        30,
        16,
        28,
        6,
        0,
        37,
        40,
        0,
        0,
        39,
        18,
        49,
        39,
        29,
        0,
        0,
        34,
        8,
        48,
        0,
        6,
        49,
        2,
        49,
        26,
        5,
        33,
        40,
        19,
        24,
        28,
        28,
        0,
        49,
        34,
        13,
        2,
        33,
        12,
        43
      ],
      "weeklyBackordered": [
        38,
        11,
        32,
        12,
        31,
        25,
        49,
        45,
        30,
        9,
        16,
        48,
        24,
        19,
        15,
        42,
        37,
        22,
        44,
        4,
        49,
        7,
        0,
        49,
        48,
        3,
        19,
        38,
        19,
        25,
        33,
        0,
        24,
        49,
        18,
        0,
        15,
        40,
        3,
        42,
        38,
        48,
        27,
        49,
        36,
        16,
        46,
        19,
        49,
        49,
        39,
        25
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        18,
        34,
        28,
        48,
        1,
        49,
        40,
        2,
        28,
        17,
        28,
        0,
        49,
        39,
        24,
        20,
        26,
        19,
        18,
        49,
        7,
        24,
        49,
        18,
        0,
        26,
        0,
        15,
        19,
        17,
        0,
        7,
        46,
        38,
        48,
        3,
        25,
        13,
        28,
        33,
        11,
        33,
        39,
        14,
        32,
        32,
        13,
        33,
        5,
        49,
        27,
        10
      ],
      "weeklyRejected": [
        43,
        0,
        2,
        22,
        29,
        0,
        15,
        49,
        23,
        36,
        15,
        9,
        43,
        10,
        25,
        0,
        41,
        49,
        49,
        49,
        13,
        49,
        39,
        49,
        49,
        34,
        27,
        33,
        46,
        0,
        48,
        30,
        48,
        49,
        18,
        47,
        12,
        0,
        35,
        37,
        41,
        49,
        9,
        18,
        49,
        26,
        25,
        33,
        14,
        35,
        0,
        28
      ],
      "weeklyHold": [
        29,
        17,
        29,
        19,
        24,
        18,
        49,
        37,
        16,
        19,
        47,
        8,
        1,
        29,
        11,
        0,
        0,
        25,
        43,
        10,
        15,
        15,
        0,
        9,
        49,
        35,
        27,
        17,
        26,
        0,
        48,
        15,
        0,
        38,
        36,
        49,
        4,
        45,
        49,
        9,
        0,
        36,
        42,
        33,
        42,
        31,
        0,
        43,
        27,
        4,
        8,
        44
      ],
      "weeklyBackordered": [
        49,
        46,
        18,
        33,
        45,
        13,
        38,
        42,
        39,
        18,
        46,
        5,
        17,
        49,
        31,
        49,
        49,
        0,
        10,
        36,
        43,
        39,
        29,
        49,
        26,
        16,
        11,
        13,
        19,
        9,
        40,
        21,
        35,
        43,
        7,
        22,
        8,
        23,
        45,
        34,
        27,
        44,
        8,
        35,
        31,
        30,
        6,
        30,
        0,
        35,
        1,
        32
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        0,
        36,
        41,
        25,
        16,
        40,
        11,
        49,
        47,
        49,
        37,
        15,
        37,
        39,
        28,
        35,
        49,
        0,
        19,
        13,
        12,
        13,
        46,
        27,
        47,
        34,
        4,
        42,
        8,
        20,
        17,
        5,
        37,
        9,
        48,
        27,
        38,
        0,
        43,
        46,
        8,
        29,
        36,
        14,
        49,
        9,
        38,
        20,
        49,
        21,
        39,
        11
      ],
      "weeklyRejected": [
        32,
        0,
        30,
        47,
        14,
        0,
        49,
        39,
        49,
        1,
        14,
        26,
        31,
        11,
        15,
        25,
        16,
        49,
        49,
        4,
        30,
        0,
        2,
        47,
        29,
        49,
        44,
        4,
        48,
        48,
        29,
        36,
        36,
        4,
        2,
        45,
        29,
        49,
        1,
        0,
        38,
        33,
        15,
        0,
        8,
        28,
        24,
        28,
        0,
        43,
        17,
        25
      ],
      "weeklyHold": [
        38,
        0,
        49,
        1,
        47,
        30,
        39,
        12,
        23,
        32,
        34,
        17,
        27,
        11,
        14,
        44,
        8,
        18,
        16,
        23,
        19,
        40,
        37,
        12,
        0,
        27,
        27,
        49,
        49,
        0,
        48,
        0,
        0,
        49,
        28,
        23,
        37,
        0,
        44,
        14,
        23,
        8,
        16,
        49,
        10,
        44,
        0,
        45,
        0,
        40,
        37,
        24
      ],
      "weeklyBackordered": [
        33,
        27,
        27,
        0,
        49,
        46,
        23,
        4,
        49,
        0,
        0,
        49,
        5,
        49,
        24,
        21,
        30,
        44,
        22,
        15,
        41,
        23,
        23,
        49,
        41,
        10,
        49,
        31,
        32,
        38,
        39,
        14,
        24,
        30,
        0,
        16,
        20,
        0,
        23,
        43,
        39,
        48,
        17,
        49,
        30,
        15,
        49,
        15,
        23,
        10,
        49,
        23
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        18,
        49,
        44,
        15,
        49,
        0,
        38,
        45,
        45,
        5,
        49,
        0,
        39,
        21,
        28,
        35,
        31,
        0,
        25,
        43,
        11,
        47,
        49,
        36,
        0,
        47,
        29,
        10,
        22,
        37,
        22,
        25,
        18,
        14,
        41,
        40,
        14,
        5,
        49,
        49,
        27,
        0,
        34,
        44,
        49,
        0,
        0,
        16,
        30,
        49,
        0,
        23
      ],
      "weeklyRejected": [
        49,
        9,
        2,
        41,
        42,
        46,
        35,
        15,
        29,
        27,
        49,
        13,
        15,
        37,
        2,
        0,
        39,
        49,
        7,
        0,
        43,
        49,
        25,
        10,
        34,
        40,
        23,
        38,
        42,
        33,
        27,
        10,
        48,
        49,
        26,
        27,
        22,
        29,
        1,
        14,
        36,
        0,
        40,
        0,
        49,
        40,
        49,
        49,
        0,
        0,
        8,
        41
      ],
      "weeklyHold": [
        20,
        25,
        49,
        30,
        14,
        48,
        5,
        6,
        0,
        5,
        47,
        25,
        23,
        34,
        17,
        11,
        0,
        45,
        10,
        39,
        13,
        0,
        23,
        0,
        49,
        41,
        17,
        46,
        24,
        1,
        48,
        6,
        0,
        33,
        15,
        26,
        38,
        0,
        39,
        23,
        33,
        40,
        17,
        0,
        49,
        49,
        15,
        29,
        0,
        19,
        34,
        21
      ],
      "weeklyBackordered": [
        34,
        49,
        1,
        31,
        49,
        17,
        29,
        38,
        4,
        21,
        18,
        32,
        38,
        49,
        49,
        23,
        49,
        33,
        28,
        6,
        26,
        22,
        14,
        0,
        1,
        36,
        48,
        20,
        11,
        17,
        0,
        49,
        48,
        0,
        6,
        0,
        9,
        18,
        49,
        49,
        0,
        22,
        16,
        31,
        35,
        49,
        49,
        0,
        0,
        30,
        24,
        43
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyAccepted": [
        35,
        26,
        6,
        39,
        49,
        2,
        7,
        49,
        26,
        0,
        20,
        0,
        8,
        41,
        3,
        20,
        44,
        2,
        1,
        19,
        33,
        0,
        49,
        0,
        6,
        26,
        0,
        24,
        1,
        9,
        49,
        33,
        28,
        3,
        40,
        25,
        22,
        0,
        19,
        49,
        30,
        29,
        43,
        41,
        49,
        12,
        0,
        42,
        16,
        31,
        49,
        33
      ],
      "weeklyRejected": [
        35,
        0,
        46,
        47,
        12,
        5,
        10,
        16,
        49,
        48,
        22,
        37,
        24,
        32,
        48,
        0,
        10,
        33,
        18,
        5,
        38,
        39,
        49,
        34,
        13,
        49,
        0,
        49,
        38,
        23,
        49,
        40,
        37,
        37,
        18,
        49,
        26,
        40,
        27,
        0,
        23,
        28,
        29,
        18,
        26,
        44,
        49,
        0,
        0,
        23,
        0,
        31
      ],
      "weeklyHold": [
        23,
        11,
        49,
        16,
        15,
        0,
        32,
        10,
        3,
        21,
        6,
        30,
        14,
        12,
        24,
        29,
        0,
        41,
        0,
        21,
        25,
        15,
        15,
        0,
        0,
        24,
        41,
        15,
        49,
        11,
        6,
        20,
        0,
        37,
        48,
        49,
        24,
        17,
        41,
        20,
        15,
        49,
        8,
        29,
        8,
        42,
        8,
        33,
        0,
        10,
        48,
        26
      ],
      "weeklyBackordered": [
        30,
        25,
        24,
        12,
        48,
        37,
        28,
        30,
        49,
        0,
        24,
        49,
        26,
        20,
        0,
        20,
        49,
        49,
        0,
        43,
        0,
        17,
        0,
        47,
        48,
        27,
        34,
        49,
        6,
        41,
        29,
        23,
        48,
        49,
        0,
        0,
        7,
        0,
        37,
        18,
        48,
        48,
        9,
        49,
        45,
        33,
        49,
        29,
        0,
        0,
        24,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        25,
        28,
        49,
        45,
        3,
        22,
        15,
        11,
        42,
        47,
        42,
        0,
        44,
        0,
        1,
        49,
        33,
        22,
        0,
        49,
        2,
        27,
        28,
        48,
        17,
        39,
        49,
        19,
        39,
        28,
        24,
        12,
        35,
        10,
        0,
        42,
        25,
        48,
        49,
        49,
        26,
        24,
        49,
        41,
        24,
        0,
        20,
        21,
        19,
        16,
        15,
        38
      ],
      "weeklyRejected": [
        16,
        24,
        48,
        47,
        13,
        8,
        8,
        33,
        45,
        43,
        26,
        24,
        0,
        24,
        19,
        18,
        49,
        32,
        18,
        24,
        24,
        41,
        10,
        39,
        0,
        21,
        12,
        0,
        48,
        20,
        22,
        0,
        36,
        49,
        24,
        49,
        48,
        0,
        7,
        49,
        17,
        41,
        43,
        14,
        49,
        38,
        16,
        13,
        29,
        49,
        0,
        49
      ],
      "weeklyHold": [
        8,
        0,
        31,
        19,
        42,
        11,
        29,
        17,
        30,
        8,
        38,
        1,
        20,
        18,
        15,
        0,
        49,
        0,
        25,
        10,
        44,
        24,
        12,
        8,
        0,
        31,
        25,
        38,
        16,
        5,
        37,
        48,
        0,
        36,
        43,
        32,
        32,
        0,
        35,
        27,
        17,
        35,
        15,
        49,
        19,
        10,
        20,
        33,
        0,
        23,
        30,
        33
      ],
      "weeklyBackordered": [
        17,
        49,
        32,
        35,
        19,
        21,
        36,
        27,
        31,
        11,
        14,
        35,
        3,
        49,
        43,
        18,
        25,
        49,
        36,
        49,
        31,
        37,
        44,
        0,
        8,
        1,
        13,
        33,
        30,
        49,
        45,
        46,
        9,
        15,
        12,
        9,
        1,
        0,
        40,
        3,
        27,
        48,
        1,
        49,
        26,
        0,
        37,
        0,
        0,
        27,
        23,
        26
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        0,
        37,
        22,
        26,
        11,
        1,
        47,
        46,
        48,
        1,
        39,
        42,
        0,
        0,
        22,
        22,
        17,
        17,
        2,
        29,
        7,
        0,
        15,
        11,
        27,
        36,
        49,
        41,
        1,
        49,
        7,
        0,
        38,
        28,
        20,
        2,
        42,
        31,
        35,
        49,
        22,
        13,
        49,
        35,
        49,
        0,
        2,
        18,
        0,
        49,
        30,
        27
      ],
      "weeklyRejected": [
        49,
        32,
        13,
        47,
        1,
        48,
        36,
        47,
        19,
        8,
        49,
        38,
        0,
        48,
        35,
        0,
        8,
        49,
        14,
        0,
        49,
        17,
        20,
        22,
        0,
        7,
        36,
        10,
        48,
        0,
        37,
        29,
        48,
        24,
        12,
        46,
        0,
        13,
        29,
        49,
        0,
        49,
        30,
        20,
        49,
        35,
        45,
        23,
        0,
        49,
        10,
        40
      ],
      "weeklyHold": [
        3,
        0,
        49,
        25,
        35,
        44,
        29,
        30,
        0,
        11,
        47,
        1,
        18,
        28,
        41,
        17,
        0,
        36,
        0,
        12,
        49,
        6,
        27,
        0,
        28,
        48,
        0,
        48,
        5,
        5,
        32,
        0,
        0,
        36,
        8,
        29,
        30,
        20,
        1,
        42,
        0,
        27,
        11,
        22,
        37,
        49,
        31,
        18,
        0,
        31,
        18,
        49
      ],
      "weeklyBackordered": [
        32,
        26,
        26,
        33,
        38,
        36,
        37,
        34,
        49,
        0,
        35,
        49,
        3,
        21,
        30,
        1,
        8,
        26,
        0,
        41,
        39,
        34,
        38,
        0,
        17,
        33,
        1,
        38,
        13,
        29,
        43,
        39,
        17,
        15,
        0,
        0,
        33,
        4,
        26,
        33,
        40,
        48,
        34,
        49,
        31,
        14,
        49,
        24,
        0,
        43,
        2,
        49
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        0,
        15,
        27,
        35,
        6,
        0,
        36,
        34,
        48,
        21,
        27,
        19,
        38,
        0,
        17,
        11,
        48,
        0,
        14,
        33,
        41,
        42,
        16,
        40,
        38,
        47,
        38,
        49,
        30,
        48,
        18,
        49,
        49,
        26,
        43,
        39,
        47,
        14,
        49,
        49,
        0,
        39,
        49,
        0,
        49,
        2,
        26,
        2,
        1,
        5,
        14,
        37
      ],
      "weeklyRejected": [
        0,
        0,
        5,
        36,
        21,
        42,
        38,
        20,
        33,
        16,
        49,
        0,
        4,
        30,
        33,
        0,
        26,
        43,
        9,
        49,
        18,
        25,
        49,
        34,
        17,
        35,
        13,
        28,
        41,
        8,
        4,
        28,
        42,
        21,
        2,
        29,
        35,
        1,
        2,
        4,
        0,
        20,
        2,
        49,
        46,
        10,
        6,
        1,
        3,
        29,
        21,
        49
      ],
      "weeklyHold": [
        25,
        0,
        44,
        38,
        23,
        28,
        19,
        35,
        24,
        22,
        47,
        23,
        30,
        44,
        35,
        0,
        0,
        32,
        43,
        2,
        21,
        40,
        44,
        49,
        48,
        1,
        30,
        26,
        23,
        49,
        27,
        27,
        0,
        49,
        17,
        22,
        16,
        37,
        15,
        35,
        8,
        49,
        49,
        26,
        22,
        24,
        23,
        49,
        10,
        11,
        12,
        49
      ],
      "weeklyBackordered": [
        0,
        49,
        0,
        14,
        41,
        46,
        32,
        27,
        19,
        23,
        37,
        2,
        3,
        38,
        33,
        31,
        49,
        0,
        32,
        5,
        49,
        49,
        49,
        18,
        2,
        4,
        33,
        0,
        22,
        37,
        11,
        20,
        3,
        11,
        47,
        0,
        35,
        21,
        26,
        39,
        30,
        23,
        12,
        49,
        23,
        31,
        0,
        0,
        0,
        49,
        7,
        30
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        8,
        44,
        0,
        24,
        1,
        0,
        1,
        16,
        26,
        1,
        49,
        0,
        36,
        23,
        28,
        23,
        49,
        18,
        0,
        16,
        29,
        10,
        5,
        47,
        22,
        42,
        49,
        39,
        1,
        30,
        29,
        36,
        27,
        0,
        42,
        49,
        46,
        16,
        49,
        34,
        33,
        49,
        49,
        21,
        9,
        16,
        6,
        49,
        18,
        31,
        3,
        0
      ],
      "weeklyRejected": [
        34,
        43,
        2,
        6,
        19,
        24,
        41,
        20,
        49,
        12,
        3,
        49,
        6,
        14,
        25,
        17,
        0,
        35,
        17,
        22,
        32,
        22,
        26,
        22,
        27,
        27,
        44,
        0,
        43,
        46,
        47,
        15,
        46,
        49,
        37,
        4,
        36,
        33,
        34,
        16,
        6,
        26,
        10,
        25,
        40,
        0,
        0,
        23,
        27,
        0,
        0,
        49
      ],
      "weeklyHold": [
        42,
        32,
        49,
        1,
        1,
        46,
        30,
        5,
        36,
        33,
        47,
        28,
        8,
        34,
        20,
        48,
        30,
        4,
        23,
        13,
        0,
        11,
        22,
        8,
        0,
        45,
        27,
        11,
        37,
        0,
        48,
        0,
        0,
        35,
        40,
        4,
        40,
        0,
        45,
        19,
        26,
        40,
        13,
        49,
        7,
        7,
        20,
        13,
        43,
        41,
        40,
        38
      ],
      "weeklyBackordered": [
        15,
        39,
        0,
        44,
        49,
        46,
        31,
        39,
        28,
        38,
        49,
        16,
        22,
        0,
        0,
        27,
        42,
        0,
        0,
        49,
        45,
        21,
        16,
        0,
        34,
        49,
        49,
        49,
        25,
        39,
        2,
        49,
        15,
        49,
        2,
        0,
        2,
        14,
        12,
        0,
        48,
        48,
        49,
        49,
        31,
        13,
        49,
        0,
        0,
        27,
        49,
        34
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        19,
        24,
        41,
        0,
        1,
        13,
        32,
        12,
        43,
        24,
        44,
        0,
        22,
        20,
        1,
        23,
        49,
        4,
        22,
        49,
        49,
        49,
        2,
        27,
        46,
        23,
        3,
        16,
        28,
        49,
        17,
        5,
        39,
        0,
        48,
        34,
        40,
        48,
        24,
        41,
        20,
        49,
        49,
        38,
        32,
        19,
        0,
        20,
        9,
        8,
        34,
        12
      ],
      "weeklyRejected": [
        38,
        6,
        22,
        47,
        36,
        49,
        49,
        49,
        17,
        8,
        49,
        12,
        11,
        32,
        48,
        49,
        1,
        49,
        49,
        0,
        44,
        22,
        21,
        30,
        49,
        49,
        47,
        28,
        34,
        47,
        0,
        11,
        47,
        19,
        14,
        31,
        17,
        28,
        3,
        15,
        44,
        34,
        30,
        0,
        43,
        11,
        15,
        47,
        3,
        11,
        0,
        31
      ],
      "weeklyHold": [
        17,
        49,
        45,
        32,
        16,
        18,
        49,
        2,
        20,
        43,
        47,
        6,
        41,
        38,
        37,
        49,
        13,
        9,
        19,
        25,
        33,
        25,
        49,
        42,
        49,
        28,
        14,
        9,
        47,
        37,
        48,
        0,
        35,
        42,
        44,
        0,
        13,
        0,
        48,
        45,
        49,
        17,
        0,
        22,
        29,
        21,
        0,
        5,
        0,
        7,
        0,
        0
      ],
      "weeklyBackordered": [
        20,
        12,
        20,
        26,
        30,
        40,
        24,
        24,
        41,
        13,
        24,
        28,
        3,
        23,
        44,
        16,
        45,
        0,
        10,
        48,
        26,
        33,
        15,
        6,
        1,
        7,
        31,
        1,
        1,
        27,
        28,
        24,
        8,
        45,
        0,
        0,
        22,
        45,
        49,
        13,
        38,
        48,
        0,
        29,
        24,
        49,
        17,
        36,
        12,
        49,
        40,
        35
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        4,
        49,
        49,
        41,
        40,
        0,
        1,
        49,
        40,
        49,
        35,
        0,
        36,
        49,
        34,
        31,
        30,
        4,
        23,
        43,
        49,
        21,
        49,
        32,
        0,
        47,
        5,
        27,
        25,
        6,
        0,
        3,
        2,
        39,
        48,
        49,
        30,
        23,
        10,
        33,
        7,
        24,
        49,
        49,
        8,
        16,
        2,
        8,
        49,
        39,
        22,
        37
      ],
      "weeklyRejected": [
        45,
        3,
        32,
        41,
        23,
        9,
        49,
        34,
        15,
        30,
        23,
        19,
        44,
        13,
        39,
        9,
        7,
        37,
        0,
        30,
        28,
        19,
        23,
        16,
        49,
        40,
        35,
        33,
        48,
        7,
        26,
        47,
        42,
        40,
        8,
        47,
        0,
        14,
        1,
        49,
        48,
        14,
        49,
        2,
        49,
        25,
        3,
        49,
        0,
        38,
        12,
        20
      ],
      "weeklyHold": [
        49,
        17,
        9,
        29,
        16,
        48,
        8,
        2,
        26,
        49,
        47,
        28,
        28,
        12,
        0,
        17,
        0,
        48,
        0,
        41,
        20,
        20,
        27,
        11,
        30,
        48,
        10,
        1,
        26,
        0,
        29,
        0,
        0,
        16,
        29,
        49,
        27,
        1,
        22,
        29,
        1,
        39,
        11,
        42,
        39,
        49,
        31,
        18,
        0,
        32,
        42,
        40
      ],
      "weeklyBackordered": [
        0,
        29,
        0,
        49,
        49,
        22,
        22,
        47,
        22,
        30,
        7,
        15,
        32,
        20,
        23,
        41,
        45,
        30,
        6,
        4,
        49,
        0,
        20,
        0,
        21,
        25,
        18,
        19,
        8,
        49,
        35,
        49,
        35,
        49,
        10,
        0,
        47,
        10,
        25,
        34,
        0,
        32,
        3,
        30,
        30,
        9,
        31,
        14,
        0,
        18,
        49,
        20
      ]
    },
    {
      "id": "049BCC88",
      "weeklyAccepted": [
        0,
        36,
        31,
        48,
        1,
        0,
        9,
        2,
        48,
        27,
        46,
        12,
        19,
        49,
        20,
        13,
        49,
        15,
        13,
        10,
        0,
        27,
        27,
        28,
        32,
        43,
        39,
        5,
        23,
        13,
        10,
        0,
        29,
        31,
        48,
        43,
        47,
        19,
        34,
        49,
        35,
        34,
        33,
        43,
        49,
        0,
        19,
        43,
        1,
        28,
        22,
        48
      ],
      "weeklyRejected": [
        49,
        12,
        25,
        7,
        37,
        43,
        5,
        12,
        49,
        24,
        11,
        49,
        6,
        6,
        17,
        23,
        24,
        49,
        0,
        0,
        0,
        26,
        6,
        32,
        21,
        49,
        43,
        18,
        0,
        39,
        49,
        33,
        48,
        31,
        14,
        49,
        39,
        22,
        4,
        31,
        37,
        19,
        24,
        0,
        49,
        36,
        49,
        48,
        18,
        24,
        31,
        21
      ],
      "weeklyHold": [
        18,
        0,
        27,
        3,
        26,
        38,
        42,
        2,
        1,
        4,
        12,
        33,
        48,
        34,
        49,
        15,
        0,
        15,
        0,
        22,
        16,
        49,
        28,
        32,
        0,
        16,
        49,
        18,
        34,
        13,
        48,
        30,
        33,
        15,
        9,
        17,
        44,
        16,
        16,
        11,
        39,
        29,
        15,
        33,
        27,
        49,
        24,
        12,
        9,
        49,
        25,
        48
      ],
      "weeklyBackordered": [
        23,
        0,
        0,
        45,
        45,
        23,
        43,
        34,
        26,
        33,
        49,
        35,
        49,
        45,
        49,
        17,
        49,
        49,
        0,
        0,
        34,
        13,
        38,
        42,
        36,
        20,
        1,
        17,
        32,
        14,
        0,
        34,
        28,
        49,
        0,
        19,
        49,
        7,
        0,
        21,
        22,
        48,
        13,
        49,
        38,
        21,
        49,
        33,
        0,
        2,
        9,
        41
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        37,
        30,
        49,
        28,
        18,
        49,
        6,
        34,
        48,
        2,
        13,
        0,
        19,
        9,
        37,
        49,
        39,
        10,
        0,
        20,
        12,
        0,
        2,
        17,
        20,
        8,
        0,
        14,
        4,
        35,
        29,
        24,
        2,
        49,
        15,
        2,
        40,
        26,
        22,
        36,
        49,
        25,
        49,
        26,
        28,
        40,
        5,
        49,
        9,
        49,
        44,
        18
      ],
      "weeklyRejected": [
        16,
        9,
        35,
        47,
        11,
        4,
        45,
        42,
        49,
        21,
        19,
        15,
        49,
        48,
        31,
        16,
        26,
        44,
        44,
        49,
        49,
        0,
        12,
        41,
        5,
        26,
        0,
        40,
        37,
        0,
        5,
        25,
        48,
        25,
        10,
        49,
        39,
        4,
        5,
        40,
        48,
        0,
        0,
        25,
        49,
        15,
        19,
        39,
        14,
        49,
        0,
        38
      ],
      "weeklyHold": [
        25,
        49,
        49,
        1,
        21,
        40,
        35,
        14,
        47,
        34,
        26,
        21,
        39,
        10,
        21,
        21,
        4,
        29,
        27,
        21,
        37,
        28,
        8,
        20,
        29,
        36,
        49,
        49,
        35,
        27,
        8,
        20,
        16,
        49,
        32,
        22,
        35,
        8,
        49,
        21,
        49,
        30,
        0,
        34,
        24,
        32,
        0,
        49,
        31,
        21,
        9,
        12
      ],
      "weeklyBackordered": [
        2,
        49,
        0,
        32,
        44,
        46,
        30,
        24,
        39,
        0,
        28,
        47,
        3,
        46,
        35,
        10,
        49,
        49,
        0,
        30,
        49,
        27,
        15,
        21,
        9,
        29,
        24,
        34,
        26,
        36,
        4,
        49,
        17,
        17,
        0,
        0,
        49,
        0,
        17,
        2,
        18,
        48,
        0,
        49,
        18,
        0,
        40,
        11,
        10,
        38,
        30,
        2
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        19,
        45,
        24,
        26,
        22,
        0,
        4,
        16,
        5,
        21,
        49,
        0,
        39,
        35,
        27,
        49,
        49,
        0,
        3,
        42,
        20,
        0,
        49,
        24,
        39,
        47,
        0,
        33,
        14,
        44,
        17,
        28,
        41,
        15,
        48,
        19,
        29,
        18,
        43,
        49,
        19,
        32,
        49,
        47,
        49,
        31,
        4,
        24,
        46,
        4,
        11,
        15
      ],
      "weeklyRejected": [
        14,
        38,
        7,
        47,
        49,
        3,
        17,
        19,
        49,
        8,
        37,
        0,
        0,
        6,
        23,
        0,
        31,
        32,
        27,
        31,
        10,
        35,
        19,
        8,
        28,
        48,
        42,
        26,
        30,
        35,
        19,
        29,
        36,
        27,
        0,
        28,
        15,
        47,
        7,
        9,
        33,
        0,
        26,
        0,
        12,
        37,
        26,
        0,
        0,
        49,
        20,
        32
      ],
      "weeklyHold": [
        4,
        0,
        38,
        1,
        34,
        16,
        0,
        2,
        2,
        29,
        24,
        1,
        15,
        32,
        48,
        22,
        48,
        0,
        0,
        49,
        38,
        28,
        49,
        0,
        1,
        23,
        0,
        40,
        23,
        5,
        36,
        0,
        15,
        33,
        6,
        23,
        1,
        0,
        1,
        34,
        0,
        24,
        27,
        28,
        17,
        26,
        11,
        42,
        0,
        11,
        14,
        22
      ],
      "weeklyBackordered": [
        18,
        5,
        28,
        27,
        46,
        13,
        35,
        37,
        33,
        41,
        3,
        33,
        23,
        27,
        42,
        17,
        38,
        43,
        35,
        16,
        41,
        37,
        34,
        0,
        41,
        5,
        20,
        49,
        4,
        18,
        41,
        17,
        46,
        47,
        12,
        0,
        22,
        12,
        49,
        39,
        28,
        48,
        2,
        49,
        0,
        0,
        49,
        11,
        0,
        49,
        48,
        5
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        0,
        1,
        3,
        48,
        23,
        2,
        20,
        42,
        3,
        49,
        13,
        7,
        17,
        6,
        29,
        0,
        24,
        9,
        14,
        39,
        15,
        16,
        49,
        23,
        0,
        43,
        6,
        0,
        1,
        33,
        0,
        18,
        49,
        49,
        48,
        34,
        27,
        3,
        32,
        49,
        8,
        22,
        49,
        49,
        49,
        18,
        11,
        49,
        20,
        49,
        18,
        18
      ],
      "weeklyRejected": [
        0,
        44,
        49,
        47,
        44,
        35,
        29,
        3,
        29,
        16,
        30,
        37,
        0,
        42,
        28,
        35,
        2,
        49,
        10,
        18,
        7,
        22,
        28,
        49,
        0,
        49,
        49,
        45,
        21,
        17,
        10,
        47,
        35,
        20,
        0,
        36,
        49,
        29,
        23,
        6,
        25,
        49,
        49,
        17,
        49,
        1,
        9,
        33,
        14,
        24,
        0,
        27
      ],
      "weeklyHold": [
        10,
        25,
        38,
        16,
        41,
        11,
        24,
        23,
        0,
        11,
        28,
        25,
        48,
        44,
        44,
        22,
        6,
        18,
        0,
        36,
        19,
        49,
        5,
        36,
        37,
        16,
        28,
        38,
        34,
        49,
        48,
        22,
        9,
        44,
        42,
        49,
        29,
        5,
        48,
        27,
        27,
        22,
        9,
        20,
        0,
        29,
        5,
        37,
        4,
        38,
        17,
        49
      ],
      "weeklyBackordered": [
        2,
        10,
        0,
        30,
        20,
        29,
        35,
        34,
        49,
        22,
        40,
        29,
        3,
        32,
        41,
        40,
        11,
        49,
        26,
        19,
        15,
        26,
        11,
        49,
        8,
        14,
        3,
        34,
        49,
        33,
        34,
        49,
        19,
        39,
        0,
        27,
        45,
        0,
        23,
        49,
        27,
        16,
        1,
        49,
        43,
        49,
        32,
        27,
        5,
        42,
        1,
        22
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        3,
        31,
        49,
        24,
        25,
        17,
        27,
        49,
        46,
        28,
        37,
        27,
        24,
        1,
        46,
        15,
        11,
        4,
        0,
        41,
        30,
        16,
        43,
        33,
        12,
        47,
        11,
        15,
        1,
        42,
        1,
        26,
        49,
        31,
        10,
        32,
        17,
        23,
        22,
        49,
        20,
        1,
        47,
        39,
        49,
        10,
        0,
        2,
        0,
        24,
        3,
        35
      ],
      "weeklyRejected": [
        29,
        25,
        29,
        31,
        12,
        11,
        49,
        49,
        33,
        21,
        49,
        26,
        18,
        48,
        39,
        8,
        7,
        49,
        49,
        6,
        17,
        23,
        43,
        18,
        28,
        33,
        0,
        48,
        45,
        16,
        34,
        32,
        48,
        49,
        0,
        49,
        49,
        22,
        1,
        23,
        48,
        41,
        0,
        42,
        41,
        49,
        0,
        28,
        3,
        49,
        0,
        27
      ],
      "weeklyHold": [
        31,
        17,
        38,
        9,
        49,
        36,
        49,
        28,
        17,
        15,
        15,
        1,
        1,
        40,
        33,
        0,
        12,
        22,
        12,
        6,
        49,
        29,
        49,
        0,
        35,
        24,
        15,
        49,
        47,
        0,
        33,
        0,
        2,
        0,
        0,
        47,
        49,
        41,
        1,
        49,
        16,
        28,
        0,
        44,
        23,
        49,
        8,
        31,
        0,
        49,
        0,
        49
      ],
      "weeklyBackordered": [
        36,
        14,
        19,
        21,
        47,
        5,
        49,
        49,
        21,
        11,
        19,
        39,
        4,
        18,
        49,
        24,
        10,
        34,
        26,
        31,
        8,
        27,
        20,
        31,
        26,
        33,
        6,
        38,
        25,
        34,
        19,
        49,
        41,
        14,
        38,
        0,
        29,
        0,
        25,
        45,
        37,
        28,
        5,
        49,
        0,
        18,
        49,
        22,
        30,
        30,
        42,
        17
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyAccepted": [
        0,
        49,
        33,
        0,
        39,
        18,
        1,
        12,
        48,
        18,
        48,
        0,
        46,
        9,
        47,
        49,
        25,
        0,
        35,
        46,
        10,
        19,
        2,
        14,
        49,
        47,
        49,
        49,
        19,
        36,
        0,
        22,
        31,
        16,
        36,
        41,
        37,
        28,
        49,
        33,
        0,
        34,
        28,
        22,
        49,
        0,
        0,
        25,
        25,
        8,
        0,
        20
      ],
      "weeklyRejected": [
        49,
        21,
        16,
        47,
        39,
        0,
        5,
        49,
        49,
        28,
        36,
        49,
        0,
        8,
        26,
        28,
        24,
        32,
        49,
        0,
        23,
        16,
        10,
        48,
        37,
        32,
        49,
        9,
        48,
        24,
        26,
        38,
        27,
        17,
        27,
        45,
        19,
        37,
        43,
        22,
        0,
        40,
        48,
        29,
        49,
        49,
        13,
        16,
        16,
        35,
        35,
        0
      ],
      "weeklyHold": [
        29,
        0,
        49,
        23,
        43,
        37,
        49,
        2,
        14,
        15,
        26,
        47,
        41,
        33,
        32,
        1,
        1,
        38,
        38,
        16,
        13,
        14,
        49,
        21,
        48,
        22,
        0,
        24,
        39,
        27,
        27,
        7,
        0,
        15,
        15,
        36,
        0,
        32,
        31,
        4,
        49,
        35,
        10,
        49,
        15,
        36,
        0,
        34,
        13,
        49,
        0,
        41
      ],
      "weeklyBackordered": [
        16,
        1,
        30,
        32,
        49,
        12,
        49,
        37,
        0,
        0,
        26,
        49,
        34,
        23,
        47,
        49,
        35,
        27,
        48,
        49,
        49,
        14,
        20,
        4,
        48,
        42,
        46,
        0,
        19,
        4,
        18,
        10,
        24,
        24,
        0,
        42,
        8,
        10,
        22,
        0,
        40,
        48,
        0,
        49,
        31,
        44,
        22,
        10,
        0,
        21,
        28,
        22
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        0,
        49,
        26,
        25,
        24,
        27,
        29,
        37,
        9,
        26,
        45,
        5,
        0,
        49,
        15,
        46,
        21,
        5,
        27,
        43,
        23,
        17,
        49,
        49,
        13,
        47,
        4,
        34,
        3,
        34,
        15,
        24,
        48,
        15,
        8,
        37,
        23,
        9,
        19,
        49,
        49,
        9,
        49,
        7,
        40,
        0,
        0,
        0,
        23,
        49,
        34,
        48
      ],
      "weeklyRejected": [
        41,
        49,
        43,
        31,
        31,
        49,
        0,
        49,
        44,
        48,
        28,
        23,
        28,
        48,
        40,
        0,
        13,
        28,
        29,
        16,
        6,
        25,
        26,
        49,
        32,
        25,
        49,
        1,
        33,
        0,
        49,
        20,
        25,
        0,
        21,
        32,
        40,
        7,
        24,
        31,
        41,
        44,
        36,
        0,
        49,
        0,
        39,
        0,
        0,
        24,
        26,
        35
      ],
      "weeklyHold": [
        28,
        37,
        35,
        7,
        49,
        0,
        15,
        49,
        49,
        13,
        28,
        25,
        18,
        1,
        41,
        14,
        0,
        17,
        7,
        13,
        0,
        37,
        43,
        38,
        28,
        28,
        0,
        0,
        49,
        16,
        28,
        40,
        0,
        49,
        17,
        49,
        40,
        0,
        34,
        31,
        46,
        34,
        15,
        49,
        49,
        49,
        0,
        33,
        0,
        49,
        48,
        49
      ],
      "weeklyBackordered": [
        15,
        37,
        0,
        28,
        49,
        28,
        0,
        49,
        40,
        29,
        47,
        30,
        22,
        37,
        0,
        49,
        4,
        49,
        3,
        11,
        49,
        6,
        20,
        0,
        48,
        29,
        1,
        27,
        44,
        49,
        0,
        49,
        48,
        17,
        49,
        0,
        21,
        0,
        24,
        46,
        0,
        48,
        16,
        49,
        47,
        11,
        48,
        33,
        41,
        28,
        21,
        25
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        23,
        29,
        31,
        41,
        26,
        33,
        36,
        8,
        48,
        9,
        49,
        0,
        39,
        10,
        49,
        49,
        33,
        8,
        1,
        0,
        47,
        20,
        9,
        49,
        0,
        22,
        0,
        49,
        5,
        47,
        26,
        0,
        49,
        16,
        48,
        47,
        13,
        10,
        49,
        49,
        19,
        31,
        30,
        28,
        39,
        0,
        16,
        12,
        49,
        49,
        13,
        48
      ],
      "weeklyRejected": [
        32,
        13,
        32,
        41,
        20,
        0,
        48,
        49,
        36,
        38,
        36,
        27,
        36,
        0,
        8,
        21,
        8,
        0,
        8,
        0,
        18,
        37,
        26,
        27,
        0,
        10,
        31,
        17,
        48,
        23,
        35,
        41,
        47,
        32,
        30,
        49,
        33,
        6,
        1,
        49,
        9,
        33,
        21,
        13,
        49,
        22,
        0,
        42,
        23,
        42,
        0,
        38
      ],
      "weeklyHold": [
        22,
        49,
        49,
        12,
        12,
        16,
        41,
        45,
        38,
        23,
        25,
        36,
        37,
        5,
        26,
        0,
        14,
        41,
        22,
        3,
        0,
        22,
        2,
        2,
        0,
        35,
        27,
        1,
        49,
        13,
        20,
        31,
        5,
        24,
        17,
        30,
        37,
        34,
        39,
        16,
        5,
        39,
        0,
        34,
        0,
        26,
        1,
        17,
        3,
        22,
        0,
        33
      ],
      "weeklyBackordered": [
        0,
        34,
        1,
        39,
        39,
        46,
        49,
        45,
        35,
        49,
        0,
        49,
        27,
        0,
        49,
        7,
        2,
        34,
        47,
        22,
        47,
        0,
        26,
        28,
        30,
        22,
        9,
        0,
        24,
        49,
        49,
        47,
        37,
        17,
        21,
        0,
        34,
        3,
        12,
        9,
        26,
        48,
        18,
        49,
        14,
        5,
        32,
        29,
        15,
        49,
        14,
        19
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyAccepted": [
        0,
        43,
        27,
        4,
        49,
        49,
        12,
        46,
        48,
        15,
        22,
        0,
        45,
        25,
        23,
        12,
        44,
        0,
        16,
        33,
        0,
        26,
        49,
        12,
        0,
        8,
        39,
        32,
        49,
        29,
        0,
        30,
        15,
        0,
        48,
        36,
        20,
        11,
        28,
        49,
        18,
        35,
        30,
        10,
        49,
        0,
        36,
        33,
        0,
        44,
        21,
        19
      ],
      "weeklyRejected": [
        14,
        40,
        49,
        16,
        48,
        0,
        29,
        14,
        31,
        21,
        46,
        40,
        49,
        40,
        7,
        2,
        19,
        49,
        31,
        19,
        19,
        49,
        27,
        31,
        7,
        30,
        47,
        12,
        37,
        30,
        27,
        0,
        4,
        25,
        0,
        28,
        7,
        3,
        1,
        0,
        48,
        11,
        34,
        8,
        49,
        33,
        8,
        19,
        3,
        49,
        0,
        49
      ],
      "weeklyHold": [
        34,
        14,
        37,
        14,
        4,
        1,
        25,
        20,
        34,
        7,
        30,
        17,
        48,
        23,
        34,
        21,
        0,
        44,
        31,
        6,
        19,
        35,
        5,
        22,
        46,
        1,
        35,
        24,
        43,
        49,
        48,
        34,
        4,
        23,
        4,
        0,
        22,
        12,
        27,
        49,
        22,
        7,
        26,
        5,
        45,
        49,
        49,
        49,
        18,
        37,
        49,
        23
      ],
      "weeklyBackordered": [
        0,
        7,
        0,
        49,
        29,
        10,
        0,
        1,
        5,
        4,
        49,
        7,
        3,
        27,
        31,
        49,
        49,
        41,
        0,
        13,
        37,
        0,
        39,
        0,
        17,
        8,
        8,
        1,
        6,
        23,
        9,
        49,
        45,
        49,
        42,
        23,
        49,
        0,
        27,
        7,
        33,
        48,
        0,
        49,
        13,
        49,
        49,
        29,
        9,
        49,
        25,
        9
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        0,
        24,
        34,
        27,
        30,
        13,
        49,
        38,
        33,
        19,
        14,
        6,
        29,
        49,
        35,
        20,
        44,
        17,
        6,
        19,
        32,
        0,
        49,
        7,
        0,
        41,
        19,
        32,
        23,
        18,
        14,
        0,
        30,
        42,
        0,
        33,
        36,
        48,
        49,
        35,
        29,
        0,
        29,
        24,
        49,
        0,
        9,
        1,
        0,
        49,
        41,
        2
      ],
      "weeklyRejected": [
        37,
        1,
        10,
        22,
        7,
        12,
        40,
        49,
        49,
        48,
        35,
        42,
        49,
        3,
        48,
        4,
        5,
        18,
        42,
        49,
        49,
        27,
        49,
        49,
        0,
        49,
        43,
        13,
        45,
        40,
        49,
        31,
        17,
        49,
        45,
        34,
        48,
        49,
        1,
        44,
        48,
        0,
        0,
        8,
        49,
        49,
        5,
        49,
        0,
        42,
        12,
        31
      ],
      "weeklyHold": [
        24,
        14,
        49,
        19,
        49,
        38,
        14,
        7,
        26,
        49,
        38,
        18,
        46,
        1,
        20,
        14,
        3,
        17,
        24,
        49,
        30,
        0,
        6,
        9,
        49,
        48,
        37,
        10,
        23,
        0,
        29,
        0,
        49,
        49,
        20,
        23,
        49,
        28,
        1,
        0,
        44,
        26,
        13,
        49,
        30,
        49,
        0,
        3,
        4,
        49,
        22,
        48
      ],
      "weeklyBackordered": [
        24,
        49,
        0,
        49,
        29,
        27,
        0,
        41,
        0,
        0,
        27,
        49,
        24,
        0,
        45,
        22,
        48,
        31,
        0,
        14,
        49,
        8,
        0,
        14,
        1,
        42,
        13,
        0,
        1,
        7,
        2,
        44,
        33,
        0,
        0,
        0,
        49,
        18,
        47,
        31,
        33,
        48,
        0,
        37,
        15,
        0,
        46,
        0,
        25,
        41,
        24,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyAccepted": [
        0,
        33,
        49,
        48,
        1,
        35,
        1,
        45,
        48,
        41,
        40,
        0,
        28,
        31,
        20,
        14,
        18,
        27,
        5,
        49,
        18,
        49,
        26,
        37,
        21,
        32,
        14,
        36,
        27,
        32,
        5,
        34,
        39,
        0,
        24,
        32,
        32,
        26,
        25,
        49,
        16,
        30,
        43,
        23,
        49,
        13,
        0,
        27,
        24,
        30,
        32,
        34
      ],
      "weeklyRejected": [
        46,
        23,
        23,
        22,
        0,
        44,
        49,
        42,
        49,
        42,
        49,
        33,
        23,
        9,
        48,
        17,
        9,
        37,
        42,
        39,
        47,
        9,
        17,
        29,
        20,
        28,
        43,
        17,
        48,
        6,
        31,
        23,
        0,
        16,
        22,
        49,
        20,
        42,
        1,
        43,
        7,
        23,
        45,
        32,
        49,
        38,
        5,
        0,
        16,
        18,
        39,
        49
      ],
      "weeklyHold": [
        31,
        18,
        41,
        1,
        15,
        32,
        16,
        2,
        0,
        49,
        40,
        35,
        23,
        48,
        39,
        17,
        13,
        18,
        4,
        0,
        46,
        23,
        34,
        0,
        49,
        47,
        16,
        24,
        11,
        13,
        43,
        5,
        18,
        27,
        24,
        8,
        35,
        1,
        10,
        25,
        0,
        49,
        10,
        49,
        42,
        25,
        0,
        0,
        7,
        49,
        20,
        43
      ],
      "weeklyBackordered": [
        49,
        11,
        0,
        12,
        30,
        31,
        49,
        2,
        33,
        0,
        11,
        49,
        21,
        0,
        1,
        18,
        15,
        23,
        21,
        0,
        33,
        3,
        7,
        7,
        20,
        20,
        49,
        3,
        21,
        40,
        9,
        16,
        46,
        21,
        8,
        4,
        15,
        21,
        27,
        37,
        20,
        16,
        0,
        20,
        0,
        8,
        31,
        27,
        0,
        45,
        47,
        11
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        49,
        49,
        13,
        33,
        49,
        36,
        17,
        40,
        48,
        10,
        43,
        35,
        28,
        1,
        1,
        41,
        48,
        0,
        15,
        26,
        10,
        13,
        4,
        45,
        19,
        43,
        23,
        27,
        49,
        25,
        20,
        20,
        42,
        2,
        48,
        49,
        23,
        20,
        49,
        49,
        15,
        43,
        44,
        0,
        39,
        29,
        0,
        34,
        49,
        36,
        0,
        24
      ],
      "weeklyRejected": [
        33,
        16,
        9,
        47,
        26,
        0,
        41,
        22,
        29,
        18,
        49,
        21,
        5,
        41,
        43,
        0,
        0,
        42,
        13,
        0,
        49,
        0,
        20,
        29,
        0,
        49,
        49,
        23,
        48,
        21,
        0,
        0,
        37,
        23,
        0,
        49,
        13,
        9,
        14,
        6,
        13,
        39,
        10,
        27,
        25,
        3,
        44,
        31,
        23,
        49,
        8,
        30
      ],
      "weeklyHold": [
        15,
        33,
        49,
        16,
        37,
        0,
        15,
        32,
        0,
        39,
        31,
        15,
        20,
        42,
        20,
        32,
        0,
        40,
        41,
        35,
        18,
        42,
        0,
        1,
        47,
        1,
        31,
        44,
        12,
        10,
        25,
        27,
        13,
        6,
        0,
        49,
        21,
        43,
        43,
        34,
        1,
        49,
        17,
        18,
        32,
        38,
        18,
        49,
        0,
        34,
        9,
        24
      ],
      "weeklyBackordered": [
        19,
        49,
        14,
        4,
        49,
        46,
        4,
        16,
        42,
        5,
        44,
        49,
        11,
        34,
        23,
        47,
        20,
        32,
        18,
        35,
        48,
        49,
        24,
        28,
        48,
        5,
        19,
        45,
        24,
        49,
        21,
        13,
        7,
        19,
        49,
        17,
        17,
        6,
        0,
        45,
        25,
        48,
        0,
        49,
        34,
        48,
        49,
        29,
        18,
        24,
        22,
        6
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        12,
        2,
        46,
        4,
        39,
        21,
        11,
        49,
        48,
        36,
        7,
        28,
        49,
        21,
        8,
        40,
        49,
        45,
        16,
        49,
        16,
        31,
        41,
        30,
        0,
        43,
        0,
        32,
        1,
        49,
        0,
        0,
        40,
        37,
        13,
        16,
        8,
        48,
        49,
        39,
        7,
        45,
        38,
        16,
        29,
        36,
        6,
        7,
        4,
        13,
        12,
        48
      ],
      "weeklyRejected": [
        35,
        42,
        36,
        47,
        28,
        0,
        0,
        15,
        49,
        29,
        25,
        37,
        24,
        24,
        39,
        45,
        30,
        14,
        49,
        0,
        13,
        41,
        14,
        23,
        8,
        49,
        24,
        0,
        48,
        36,
        28,
        37,
        48,
        49,
        28,
        45,
        49,
        44,
        12,
        20,
        30,
        18,
        6,
        23,
        49,
        33,
        26,
        22,
        8,
        49,
        0,
        49
      ],
      "weeklyHold": [
        23,
        8,
        35,
        1,
        44,
        42,
        16,
        5,
        35,
        37,
        34,
        34,
        38,
        49,
        15,
        0,
        35,
        11,
        49,
        29,
        16,
        23,
        0,
        30,
        2,
        43,
        49,
        49,
        49,
        0,
        48,
        14,
        0,
        23,
        33,
        29,
        8,
        30,
        49,
        20,
        35,
        34,
        8,
        40,
        39,
        16,
        46,
        26,
        0,
        14,
        28,
        49
      ],
      "weeklyBackordered": [
        0,
        23,
        0,
        16,
        34,
        33,
        0,
        49,
        25,
        15,
        31,
        23,
        3,
        40,
        22,
        49,
        49,
        41,
        16,
        42,
        49,
        23,
        27,
        37,
        7,
        30,
        36,
        0,
        16,
        33,
        3,
        49,
        0,
        19,
        8,
        15,
        49,
        2,
        14,
        12,
        12,
        48,
        14,
        49,
        41,
        27,
        49,
        12,
        16,
        49,
        46,
        33
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        14,
        11,
        28,
        42,
        13,
        49,
        34,
        32,
        28,
        14,
        15,
        0,
        23,
        30,
        1,
        35,
        49,
        18,
        16,
        49,
        49,
        45,
        16,
        27,
        7,
        31,
        0,
        49,
        23,
        43,
        0,
        0,
        38,
        30,
        28,
        12,
        41,
        23,
        49,
        49,
        0,
        36,
        0,
        32,
        49,
        36,
        18,
        49,
        26,
        0,
        11,
        39
      ],
      "weeklyRejected": [
        49,
        27,
        17,
        9,
        15,
        0,
        47,
        48,
        24,
        22,
        19,
        0,
        48,
        16,
        48,
        7,
        25,
        49,
        39,
        28,
        49,
        38,
        0,
        36,
        4,
        45,
        26,
        15,
        48,
        21,
        31,
        25,
        10,
        16,
        45,
        49,
        8,
        0,
        1,
        20,
        17,
        4,
        49,
        20,
        49,
        38,
        49,
        46,
        2,
        21,
        0,
        31
      ],
      "weeklyHold": [
        49,
        49,
        15,
        1,
        3,
        20,
        49,
        23,
        0,
        27,
        47,
        29,
        18,
        16,
        49,
        20,
        27,
        48,
        32,
        11,
        5,
        35,
        21,
        13,
        32,
        44,
        12,
        45,
        10,
        1,
        32,
        11,
        42,
        36,
        13,
        8,
        35,
        0,
        15,
        13,
        0,
        24,
        0,
        20,
        0,
        49,
        16,
        22,
        17,
        26,
        49,
        49
      ],
      "weeklyBackordered": [
        49,
        34,
        14,
        5,
        26,
        39,
        26,
        49,
        40,
        8,
        16,
        27,
        3,
        28,
        36,
        31,
        49,
        48,
        14,
        33,
        37,
        0,
        20,
        35,
        18,
        13,
        26,
        0,
        15,
        42,
        29,
        0,
        46,
        21,
        11,
        38,
        45,
        26,
        27,
        6,
        28,
        35,
        0,
        41,
        0,
        0,
        49,
        41,
        22,
        31,
        40,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyAccepted": [
        40,
        21,
        0,
        23,
        34,
        49,
        7,
        45,
        48,
        34,
        49,
        34,
        24,
        24,
        32,
        49,
        40,
        31,
        6,
        12,
        35,
        0,
        27,
        21,
        0,
        47,
        49,
        4,
        28,
        34,
        4,
        14,
        49,
        35,
        48,
        49,
        21,
        48,
        11,
        49,
        49,
        34,
        31,
        2,
        49,
        0,
        18,
        31,
        9,
        38,
        49,
        39
      ],
      "weeklyRejected": [
        38,
        1,
        49,
        47,
        38,
        13,
        38,
        14,
        29,
        34,
        31,
        21,
        4,
        13,
        22,
        0,
        49,
        45,
        36,
        15,
        27,
        8,
        0,
        30,
        0,
        49,
        18,
        19,
        27,
        26,
        49,
        35,
        0,
        32,
        16,
        49,
        49,
        19,
        9,
        0,
        48,
        39,
        49,
        9,
        49,
        20,
        22,
        30,
        4,
        37,
        0,
        17
      ],
      "weeklyHold": [
        41,
        24,
        46,
        15,
        49,
        42,
        25,
        49,
        26,
        16,
        32,
        26,
        42,
        1,
        48,
        24,
        2,
        21,
        8,
        27,
        0,
        31,
        6,
        5,
        0,
        48,
        49,
        49,
        49,
        0,
        32,
        48,
        39,
        36,
        48,
        26,
        41,
        40,
        20,
        16,
        45,
        31,
        9,
        16,
        49,
        49,
        29,
        4,
        6,
        28,
        49,
        49
      ],
      "weeklyBackordered": [
        3,
        49,
        16,
        10,
        17,
        46,
        20,
        24,
        18,
        10,
        49,
        34,
        43,
        38,
        19,
        21,
        18,
        46,
        0,
        27,
        23,
        35,
        43,
        32,
        41,
        25,
        12,
        49,
        16,
        49,
        0,
        49,
        37,
        49,
        0,
        10,
        19,
        16,
        4,
        15,
        15,
        33,
        8,
        37,
        0,
        0,
        49,
        33,
        0,
        30,
        23,
        27
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        0,
        1,
        21,
        48,
        40,
        9,
        41,
        22,
        47,
        7,
        49,
        0,
        23,
        27,
        1,
        25,
        49,
        48,
        9,
        32,
        11,
        14,
        16,
        47,
        0,
        47,
        26,
        36,
        28,
        49,
        7,
        0,
        2,
        31,
        32,
        6,
        47,
        26,
        14,
        49,
        18,
        9,
        49,
        28,
        49,
        34,
        36,
        49,
        0,
        31,
        31,
        34
      ],
      "weeklyRejected": [
        22,
        11,
        49,
        32,
        26,
        41,
        49,
        0,
        49,
        26,
        9,
        33,
        21,
        0,
        48,
        0,
        4,
        7,
        42,
        46,
        0,
        49,
        44,
        3,
        48,
        43,
        37,
        49,
        44,
        0,
        47,
        29,
        40,
        33,
        0,
        29,
        2,
        16,
        6,
        11,
        44,
        0,
        24,
        2,
        41,
        0,
        33,
        35,
        0,
        24,
        0,
        28
      ],
      "weeklyHold": [
        33,
        49,
        49,
        24,
        49,
        24,
        49,
        7,
        49,
        32,
        47,
        37,
        48,
        12,
        41,
        39,
        0,
        39,
        0,
        18,
        23,
        12,
        27,
        49,
        46,
        43,
        20,
        26,
        49,
        31,
        34,
        15,
        37,
        28,
        48,
        9,
        15,
        0,
        18,
        22,
        24,
        49,
        49,
        2,
        3,
        38,
        27,
        39,
        24,
        28,
        49,
        44
      ],
      "weeklyBackordered": [
        22,
        49,
        23,
        39,
        49,
        26,
        49,
        49,
        18,
        0,
        27,
        19,
        16,
        32,
        21,
        9,
        49,
        49,
        33,
        37,
        49,
        18,
        20,
        43,
        1,
        49,
        9,
        0,
        3,
        38,
        36,
        40,
        46,
        33,
        0,
        28,
        32,
        2,
        49,
        0,
        48,
        33,
        38,
        49,
        26,
        41,
        49,
        30,
        37,
        49,
        14,
        10
      ]
    },
    {
      "id": "DB301E71",
      "weeklyAccepted": [
        18,
        49,
        42,
        25,
        46,
        0,
        36,
        34,
        37,
        32,
        41,
        0,
        49,
        36,
        1,
        14,
        35,
        1,
        33,
        20,
        40,
        14,
        49,
        45,
        13,
        47,
        13,
        34,
        43,
        29,
        1,
        24,
        35,
        38,
        1,
        8,
        23,
        0,
        23,
        43,
        29,
        29,
        49,
        15,
        38,
        21,
        0,
        44,
        0,
        48,
        18,
        35
      ],
      "weeklyRejected": [
        49,
        32,
        37,
        5,
        12,
        3,
        24,
        44,
        7,
        24,
        29,
        10,
        33,
        47,
        48,
        17,
        49,
        29,
        22,
        41,
        0,
        28,
        7,
        17,
        31,
        36,
        8,
        47,
        34,
        23,
        32,
        18,
        48,
        48,
        0,
        49,
        0,
        49,
        14,
        0,
        46,
        49,
        26,
        49,
        43,
        23,
        10,
        49,
        0,
        42,
        0,
        49
      ],
      "weeklyHold": [
        0,
        25,
        25,
        1,
        49,
        0,
        16,
        15,
        22,
        21,
        31,
        49,
        1,
        22,
        31,
        21,
        16,
        23,
        3,
        22,
        34,
        13,
        23,
        2,
        34,
        9,
        0,
        0,
        49,
        11,
        48,
        0,
        0,
        0,
        37,
        6,
        43,
        0,
        36,
        49,
        0,
        37,
        0,
        0,
        26,
        49,
        33,
        42,
        0,
        23,
        33,
        21
      ],
      "weeklyBackordered": [
        20,
        3,
        0,
        48,
        40,
        46,
        49,
        49,
        44,
        21,
        18,
        38,
        39,
        33,
        25,
        1,
        19,
        11,
        0,
        0,
        20,
        44,
        0,
        49,
        45,
        17,
        12,
        40,
        3,
        22,
        49,
        28,
        14,
        37,
        7,
        0,
        1,
        18,
        17,
        0,
        17,
        48,
        0,
        49,
        24,
        0,
        40,
        0,
        46,
        43,
        35,
        14
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        22,
        49,
        33,
        33,
        21,
        5,
        1,
        34,
        34,
        46,
        49,
        29,
        25,
        29,
        16,
        26,
        11,
        16,
        21,
        49,
        19,
        27,
        41,
        21,
        21,
        23,
        1,
        21,
        13,
        49,
        9,
        33,
        45,
        22,
        48,
        13,
        30,
        5,
        10,
        49,
        28,
        29,
        28,
        15,
        49,
        0,
        4,
        6,
        4,
        15,
        0,
        10
      ],
      "weeklyRejected": [
        1,
        27,
        8,
        27,
        43,
        16,
        32,
        49,
        49,
        6,
        39,
        38,
        49,
        43,
        33,
        17,
        17,
        49,
        0,
        42,
        0,
        35,
        4,
        49,
        41,
        23,
        33,
        21,
        48,
        6,
        47,
        13,
        48,
        49,
        4,
        49,
        28,
        8,
        18,
        36,
        38,
        25,
        27,
        6,
        49,
        13,
        3,
        37,
        30,
        40,
        0,
        43
      ],
      "weeklyHold": [
        49,
        44,
        47,
        1,
        6,
        8,
        49,
        16,
        36,
        17,
        38,
        49,
        33,
        25,
        24,
        26,
        4,
        10,
        27,
        3,
        18,
        15,
        33,
        49,
        20,
        14,
        17,
        0,
        25,
        0,
        28,
        28,
        0,
        44,
        48,
        15,
        35,
        0,
        25,
        49,
        17,
        44,
        23,
        39,
        40,
        28,
        0,
        26,
        23,
        0,
        1,
        21
      ],
      "weeklyBackordered": [
        28,
        49,
        33,
        48,
        33,
        39,
        32,
        47,
        10,
        20,
        25,
        22,
        20,
        49,
        33,
        18,
        49,
        24,
        23,
        5,
        42,
        0,
        36,
        45,
        14,
        4,
        1,
        1,
        49,
        33,
        15,
        33,
        26,
        47,
        0,
        0,
        4,
        0,
        44,
        42,
        44,
        48,
        27,
        49,
        18,
        32,
        49,
        0,
        17,
        49,
        36,
        49
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        27,
        30,
        35,
        48,
        24,
        6,
        31,
        49,
        38,
        49,
        36,
        29,
        40,
        19,
        11,
        0,
        49,
        48,
        9,
        34,
        13,
        27,
        49,
        32,
        24,
        21,
        49,
        0,
        26,
        1,
        0,
        34,
        28,
        36,
        39,
        37,
        21,
        48,
        16,
        49,
        0,
        36,
        49,
        49,
        27,
        5,
        6,
        34,
        21,
        27,
        40,
        48
      ],
      "weeklyRejected": [
        49,
        14,
        21,
        47,
        29,
        1,
        0,
        49,
        47,
        28,
        49,
        18,
        30,
        21,
        2,
        6,
        29,
        24,
        0,
        49,
        49,
        48,
        2,
        44,
        30,
        37,
        26,
        28,
        48,
        0,
        49,
        43,
        48,
        49,
        45,
        49,
        0,
        0,
        1,
        23,
        48,
        18,
        49,
        4,
        49,
        35,
        14,
        23,
        0,
        46,
        0,
        30
      ],
      "weeklyHold": [
        21,
        49,
        22,
        1,
        37,
        48,
        12,
        7,
        5,
        37,
        32,
        45,
        27,
        45,
        13,
        25,
        0,
        33,
        10,
        41,
        0,
        44,
        2,
        10,
        36,
        48,
        13,
        21,
        27,
        7,
        25,
        22,
        24,
        0,
        33,
        2,
        49,
        18,
        37,
        28,
        19,
        25,
        20,
        30,
        49,
        49,
        40,
        20,
        15,
        49,
        33,
        49
      ],
      "weeklyBackordered": [
        30,
        41,
        0,
        49,
        26,
        25,
        49,
        42,
        48,
        0,
        49,
        20,
        45,
        49,
        0,
        9,
        49,
        45,
        10,
        26,
        39,
        3,
        35,
        0,
        14,
        1,
        32,
        3,
        25,
        47,
        7,
        49,
        14,
        11,
        0,
        0,
        49,
        24,
        17,
        33,
        6,
        48,
        0,
        49,
        0,
        0,
        49,
        8,
        29,
        40,
        40,
        13
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        12,
        49,
        49,
        47,
        33,
        0,
        1,
        49,
        48,
        5,
        24,
        0,
        47,
        38,
        32,
        18,
        49,
        0,
        25,
        37,
        28,
        49,
        29,
        40,
        18,
        47,
        7,
        39,
        49,
        33,
        0,
        38,
        30,
        0,
        48,
        6,
        11,
        12,
        11,
        49,
        23,
        20,
        49,
        19,
        49,
        0,
        7,
        0,
        39,
        27,
        49,
        37
      ],
      "weeklyRejected": [
        7,
        7,
        25,
        32,
        49,
        32,
        34,
        26,
        37,
        1,
        40,
        1,
        49,
        10,
        26,
        32,
        49,
        30,
        26,
        5,
        0,
        37,
        0,
        7,
        11,
        49,
        3,
        49,
        48,
        23,
        12,
        37,
        31,
        20,
        22,
        34,
        46,
        21,
        1,
        49,
        44,
        38,
        0,
        19,
        32,
        24,
        13,
        35,
        26,
        49,
        21,
        41
      ],
      "weeklyHold": [
        49,
        6,
        40,
        1,
        42,
        0,
        8,
        2,
        9,
        49,
        9,
        17,
        19,
        30,
        24,
        19,
        2,
        31,
        9,
        36,
        43,
        49,
        49,
        22,
        25,
        26,
        34,
        27,
        49,
        0,
        42,
        17,
        0,
        42,
        26,
        16,
        49,
        35,
        33,
        37,
        49,
        30,
        24,
        45,
        38,
        25,
        9,
        49,
        10,
        34,
        8,
        42
      ],
      "weeklyBackordered": [
        31,
        22,
        0,
        47,
        31,
        21,
        11,
        41,
        0,
        44,
        45,
        10,
        49,
        44,
        34,
        17,
        23,
        37,
        15,
        22,
        6,
        42,
        33,
        5,
        11,
        29,
        48,
        18,
        46,
        42,
        20,
        38,
        35,
        39,
        21,
        0,
        44,
        0,
        42,
        36,
        28,
        47,
        33,
        49,
        38,
        47,
        35,
        35,
        39,
        20,
        15,
        19
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        0,
        25,
        30,
        35,
        46,
        30,
        27,
        2,
        39,
        49,
        31,
        0,
        18,
        16,
        32,
        32,
        37,
        22,
        35,
        34,
        9,
        47,
        38,
        45,
        32,
        47,
        43,
        49,
        9,
        20,
        12,
        23,
        8,
        30,
        30,
        34,
        27,
        48,
        43,
        49,
        6,
        19,
        49,
        24,
        47,
        0,
        38,
        22,
        47,
        41,
        0,
        28
      ],
      "weeklyRejected": [
        20,
        22,
        47,
        42,
        0,
        39,
        13,
        5,
        0,
        48,
        1,
        0,
        36,
        41,
        27,
        34,
        42,
        15,
        13,
        29,
        42,
        9,
        30,
        20,
        31,
        49,
        49,
        46,
        28,
        48,
        40,
        34,
        36,
        15,
        0,
        24,
        29,
        20,
        19,
        30,
        33,
        30,
        25,
        16,
        29,
        5,
        12,
        25,
        30,
        20,
        22,
        49
      ],
      "weeklyHold": [
        0,
        49,
        39,
        28,
        39,
        27,
        28,
        37,
        22,
        32,
        5,
        43,
        20,
        2,
        49,
        18,
        7,
        0,
        33,
        11,
        33,
        49,
        47,
        49,
        30,
        13,
        6,
        16,
        49,
        28,
        24,
        3,
        19,
        15,
        6,
        7,
        39,
        0,
        19,
        44,
        1,
        49,
        38,
        4,
        20,
        10,
        0,
        31,
        18,
        36,
        38,
        7
      ],
      "weeklyBackordered": [
        30,
        49,
        0,
        13,
        44,
        9,
        18,
        47,
        0,
        49,
        18,
        0,
        3,
        49,
        36,
        35,
        49,
        46,
        46,
        15,
        11,
        36,
        34,
        49,
        10,
        49,
        1,
        14,
        18,
        49,
        32,
        47,
        0,
        35,
        38,
        0,
        30,
        0,
        48,
        31,
        18,
        42,
        9,
        10,
        0,
        34,
        19,
        9,
        37,
        39,
        34,
        22
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        7,
        34,
        31,
        31,
        49,
        20,
        48,
        16,
        35,
        18,
        49,
        0,
        29,
        49,
        23,
        10,
        21,
        0,
        0,
        49,
        1,
        42,
        46,
        49,
        0,
        38,
        49,
        33,
        2,
        21,
        22,
        0,
        49,
        2,
        11,
        26,
        13,
        26,
        49,
        49,
        0,
        24,
        32,
        41,
        29,
        0,
        42,
        27,
        16,
        49,
        4,
        16
      ],
      "weeklyRejected": [
        17,
        33,
        22,
        42,
        46,
        34,
        17,
        0,
        23,
        48,
        20,
        0,
        24,
        33,
        2,
        18,
        45,
        49,
        13,
        23,
        9,
        13,
        0,
        34,
        26,
        49,
        43,
        0,
        48,
        0,
        13,
        22,
        48,
        26,
        24,
        49,
        7,
        25,
        14,
        28,
        26,
        37,
        21,
        3,
        49,
        0,
        29,
        48,
        0,
        42,
        0,
        49
      ],
      "weeklyHold": [
        14,
        7,
        33,
        1,
        0,
        30,
        0,
        27,
        20,
        0,
        47,
        49,
        48,
        15,
        18,
        0,
        18,
        44,
        15,
        13,
        33,
        25,
        49,
        0,
        0,
        22,
        42,
        32,
        5,
        1,
        44,
        20,
        0,
        17,
        8,
        13,
        40,
        19,
        49,
        45,
        16,
        37,
        26,
        16,
        13,
        38,
        37,
        49,
        33,
        39,
        31,
        37
      ],
      "weeklyBackordered": [
        24,
        30,
        0,
        32,
        25,
        46,
        0,
        3,
        39,
        3,
        32,
        18,
        47,
        49,
        17,
        18,
        49,
        37,
        10,
        30,
        19,
        35,
        12,
        5,
        13,
        23,
        1,
        39,
        10,
        38,
        13,
        45,
        20,
        49,
        22,
        26,
        30,
        12,
        49,
        25,
        10,
        18,
        0,
        28,
        27,
        46,
        49,
        29,
        6,
        27,
        1,
        18
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        44,
        17,
        49,
        16,
        1,
        23,
        1,
        45,
        43,
        21,
        17,
        2,
        49,
        46,
        35,
        49,
        11,
        12,
        11,
        4,
        35,
        49,
        41,
        27,
        0,
        37,
        0,
        13,
        30,
        18,
        19,
        0,
        2,
        0,
        39,
        26,
        40,
        17,
        0,
        49,
        44,
        3,
        46,
        12,
        43,
        24,
        0,
        21,
        23,
        27,
        0,
        30
      ],
      "weeklyRejected": [
        49,
        34,
        41,
        36,
        17,
        3,
        27,
        49,
        48,
        33,
        48,
        24,
        34,
        48,
        35,
        2,
        9,
        49,
        36,
        0,
        12,
        27,
        6,
        12,
        49,
        49,
        30,
        18,
        48,
        0,
        20,
        12,
        40,
        49,
        0,
        49,
        49,
        49,
        1,
        15,
        27,
        8,
        49,
        27,
        40,
        49,
        23,
        30,
        0,
        49,
        10,
        49
      ],
      "weeklyHold": [
        34,
        44,
        35,
        9,
        0,
        11,
        40,
        40,
        29,
        12,
        20,
        37,
        23,
        25,
        32,
        34,
        1,
        26,
        2,
        19,
        39,
        23,
        16,
        1,
        32,
        1,
        49,
        32,
        37,
        20,
        19,
        23,
        7,
        35,
        0,
        18,
        21,
        13,
        43,
        49,
        42,
        49,
        0,
        41,
        44,
        13,
        0,
        38,
        27,
        48,
        31,
        10
      ],
      "weeklyBackordered": [
        7,
        21,
        20,
        27,
        49,
        45,
        16,
        20,
        32,
        0,
        21,
        39,
        3,
        16,
        46,
        43,
        10,
        43,
        0,
        23,
        33,
        18,
        25,
        32,
        12,
        33,
        7,
        0,
        36,
        37,
        0,
        45,
        41,
        39,
        32,
        0,
        44,
        0,
        0,
        0,
        8,
        48,
        11,
        40,
        12,
        43,
        35,
        15,
        45,
        26,
        3,
        10
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        22,
        19,
        49,
        48,
        23,
        0,
        20,
        2,
        48,
        0,
        41,
        8,
        11,
        49,
        1,
        33,
        45,
        48,
        17,
        40,
        49,
        49,
        27,
        49,
        31,
        47,
        0,
        14,
        21,
        49,
        3,
        0,
        24,
        0,
        44,
        24,
        41,
        47,
        39,
        49,
        2,
        15,
        49,
        28,
        49,
        15,
        2,
        37,
        48,
        33,
        5,
        2
      ],
      "weeklyRejected": [
        33,
        33,
        49,
        6,
        15,
        41,
        22,
        16,
        34,
        42,
        24,
        49,
        32,
        15,
        48,
        29,
        31,
        40,
        22,
        41,
        32,
        49,
        37,
        0,
        43,
        49,
        33,
        33,
        25,
        4,
        38,
        33,
        45,
        25,
        25,
        49,
        0,
        9,
        33,
        0,
        43,
        22,
        49,
        6,
        49,
        15,
        26,
        35,
        0,
        30,
        49,
        34
      ],
      "weeklyHold": [
        0,
        4,
        47,
        38,
        49,
        14,
        10,
        20,
        0,
        49,
        26,
        35,
        48,
        22,
        23,
        23,
        2,
        32,
        39,
        25,
        47,
        22,
        25,
        19,
        49,
        34,
        8,
        27,
        30,
        12,
        43,
        0,
        23,
        17,
        16,
        24,
        1,
        0,
        1,
        21,
        49,
        32,
        46,
        15,
        39,
        46,
        9,
        0,
        17,
        49,
        26,
        34
      ],
      "weeklyBackordered": [
        26,
        23,
        0,
        46,
        45,
        10,
        8,
        19,
        0,
        8,
        29,
        26,
        26,
        43,
        37,
        42,
        45,
        36,
        8,
        33,
        35,
        36,
        22,
        12,
        1,
        22,
        28,
        0,
        49,
        9,
        41,
        37,
        48,
        0,
        24,
        0,
        42,
        21,
        32,
        25,
        11,
        26,
        0,
        49,
        16,
        47,
        39,
        19,
        7,
        49,
        49,
        1
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        0,
        40,
        20,
        31,
        33,
        37,
        18,
        10,
        19,
        0,
        42,
        0,
        41,
        27,
        16,
        0,
        40,
        11,
        25,
        33,
        5,
        47,
        49,
        0,
        49,
        34,
        12,
        48,
        1,
        21,
        11,
        18,
        49,
        0,
        31,
        33,
        47,
        7,
        34,
        49,
        31,
        5,
        49,
        49,
        49,
        0,
        0,
        12,
        18,
        35,
        14,
        0
      ],
      "weeklyRejected": [
        49,
        24,
        15,
        17,
        30,
        13,
        47,
        42,
        40,
        8,
        18,
        49,
        12,
        48,
        30,
        7,
        48,
        35,
        37,
        23,
        27,
        49,
        5,
        5,
        0,
        21,
        49,
        15,
        48,
        7,
        3,
        47,
        26,
        3,
        36,
        49,
        7,
        49,
        4,
        17,
        14,
        0,
        39,
        11,
        41,
        41,
        26,
        13,
        0,
        29,
        8,
        17
      ],
      "weeklyHold": [
        34,
        2,
        17,
        16,
        34,
        0,
        27,
        12,
        18,
        20,
        32,
        11,
        33,
        1,
        42,
        32,
        5,
        17,
        7,
        36,
        28,
        16,
        25,
        14,
        11,
        19,
        13,
        34,
        49,
        1,
        44,
        0,
        0,
        34,
        34,
        49,
        5,
        48,
        29,
        15,
        0,
        29,
        8,
        33,
        45,
        33,
        18,
        48,
        16,
        17,
        0,
        33
      ],
      "weeklyBackordered": [
        30,
        16,
        31,
        25,
        41,
        31,
        42,
        17,
        0,
        19,
        49,
        38,
        49,
        0,
        26,
        27,
        49,
        30,
        29,
        31,
        31,
        2,
        9,
        3,
        48,
        49,
        23,
        15,
        14,
        34,
        15,
        39,
        20,
        25,
        0,
        9,
        11,
        0,
        21,
        4,
        22,
        48,
        0,
        49,
        44,
        38,
        21,
        14,
        26,
        43,
        30,
        32
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        1,
        31,
        6,
        28,
        35,
        19,
        49,
        24,
        47,
        35,
        41,
        22,
        0,
        37,
        14,
        28,
        49,
        13,
        19,
        28,
        0,
        27,
        30,
        35,
        0,
        19,
        8,
        16,
        1,
        49,
        21,
        23,
        41,
        8,
        24,
        10,
        14,
        48,
        28,
        19,
        29,
        49,
        18,
        9,
        49,
        33,
        18,
        25,
        0,
        26,
        40,
        48
      ],
      "weeklyRejected": [
        49,
        14,
        49,
        19,
        27,
        0,
        0,
        41,
        49,
        16,
        24,
        34,
        30,
        48,
        22,
        49,
        36,
        34,
        29,
        25,
        29,
        0,
        12,
        16,
        49,
        49,
        37,
        13,
        48,
        25,
        28,
        25,
        48,
        36,
        40,
        12,
        13,
        44,
        19,
        45,
        48,
        47,
        30,
        4,
        49,
        47,
        13,
        9,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        48,
        17,
        34,
        6,
        0,
        48,
        42,
        23,
        31,
        36,
        30,
        33,
        44,
        23,
        49,
        33,
        6,
        9,
        22,
        26,
        19,
        49,
        0,
        23,
        49,
        46,
        49,
        0,
        26,
        0,
        48,
        0,
        0,
        12,
        8,
        7,
        17,
        43,
        15,
        14,
        17,
        23,
        13,
        18,
        44,
        7,
        42,
        49,
        19,
        32,
        29,
        0
      ],
      "weeklyBackordered": [
        49,
        49,
        9,
        0,
        48,
        6,
        43,
        47,
        49,
        7,
        8,
        27,
        12,
        36,
        25,
        33,
        49,
        37,
        19,
        49,
        44,
        45,
        16,
        49,
        35,
        1,
        2,
        0,
        27,
        42,
        0,
        37,
        36,
        38,
        30,
        0,
        26,
        0,
        22,
        0,
        48,
        48,
        9,
        49,
        41,
        26,
        43,
        13,
        44,
        49,
        29,
        1
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        9,
        1,
        9,
        0,
        34,
        0,
        1,
        49,
        32,
        0,
        41,
        29,
        49,
        37,
        17,
        49,
        0,
        0,
        32,
        0,
        16,
        23,
        24,
        34,
        48,
        47,
        0,
        49,
        45,
        32,
        47,
        27,
        49,
        49,
        48,
        25,
        10,
        20,
        33,
        49,
        0,
        11,
        25,
        49,
        45,
        22,
        0,
        39,
        18,
        39,
        21,
        2
      ],
      "weeklyRejected": [
        45,
        45,
        25,
        41,
        46,
        25,
        24,
        25,
        12,
        48,
        49,
        23,
        47,
        48,
        48,
        0,
        14,
        42,
        36,
        19,
        20,
        14,
        15,
        18,
        39,
        49,
        37,
        8,
        32,
        35,
        6,
        21,
        34,
        23,
        10,
        49,
        18,
        49,
        16,
        9,
        21,
        49,
        27,
        9,
        41,
        6,
        33,
        0,
        9,
        24,
        0,
        49
      ],
      "weeklyHold": [
        0,
        49,
        43,
        20,
        49,
        22,
        25,
        42,
        0,
        4,
        0,
        9,
        38,
        41,
        28,
        12,
        8,
        14,
        41,
        0,
        17,
        9,
        42,
        0,
        45,
        13,
        49,
        49,
        46,
        23,
        48,
        14,
        17,
        39,
        26,
        2,
        32,
        45,
        41,
        33,
        16,
        49,
        0,
        49,
        23,
        46,
        37,
        14,
        5,
        49,
        13,
        39
      ],
      "weeklyBackordered": [
        34,
        49,
        6,
        44,
        41,
        30,
        35,
        16,
        49,
        13,
        46,
        42,
        19,
        45,
        0,
        17,
        49,
        49,
        21,
        40,
        49,
        2,
        49,
        21,
        26,
        26,
        20,
        18,
        23,
        41,
        0,
        47,
        39,
        0,
        8,
        0,
        22,
        45,
        27,
        45,
        9,
        36,
        26,
        49,
        5,
        4,
        9,
        29,
        27,
        49,
        14,
        24
      ]
    },
    {
      "id": "C71B9801",
      "weeklyAccepted": [
        7,
        26,
        49,
        7,
        26,
        16,
        28,
        49,
        0,
        49,
        39,
        9,
        39,
        49,
        29,
        27,
        35,
        0,
        49,
        18,
        48,
        49,
        49,
        34,
        0,
        2,
        0,
        49,
        45,
        11,
        0,
        0,
        44,
        29,
        33,
        31,
        6,
        0,
        1,
        41,
        6,
        44,
        22,
        35,
        43,
        26,
        0,
        24,
        0,
        11,
        25,
        7
      ],
      "weeklyRejected": [
        49,
        26,
        26,
        47,
        21,
        34,
        36,
        49,
        18,
        13,
        18,
        48,
        18,
        48,
        38,
        20,
        29,
        49,
        25,
        24,
        0,
        32,
        46,
        49,
        13,
        49,
        49,
        10,
        35,
        48,
        18,
        47,
        42,
        38,
        20,
        30,
        17,
        4,
        1,
        13,
        37,
        42,
        49,
        14,
        34,
        49,
        0,
        0,
        0,
        49,
        11,
        24
      ],
      "weeklyHold": [
        17,
        4,
        49,
        16,
        27,
        9,
        49,
        26,
        3,
        26,
        37,
        39,
        43,
        46,
        31,
        0,
        18,
        32,
        17,
        0,
        49,
        46,
        39,
        0,
        49,
        26,
        25,
        23,
        19,
        8,
        32,
        19,
        13,
        30,
        0,
        33,
        41,
        0,
        26,
        47,
        23,
        32,
        25,
        5,
        49,
        21,
        46,
        41,
        0,
        40,
        49,
        14
      ],
      "weeklyBackordered": [
        26,
        8,
        0,
        49,
        38,
        46,
        9,
        49,
        0,
        49,
        10,
        48,
        49,
        33,
        38,
        1,
        41,
        39,
        20,
        9,
        49,
        48,
        48,
        29,
        8,
        8,
        32,
        14,
        31,
        31,
        19,
        16,
        44,
        46,
        36,
        0,
        10,
        12,
        31,
        23,
        31,
        27,
        2,
        49,
        16,
        41,
        38,
        11,
        22,
        30,
        18,
        14
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        12,
        26,
        29,
        40,
        18,
        0,
        25,
        19,
        37,
        42,
        49,
        29,
        16,
        23,
        18,
        11,
        37,
        22,
        2,
        25,
        22,
        36,
        16,
        27,
        22,
        30,
        32,
        28,
        32,
        41,
        0,
        17,
        41,
        20,
        41,
        24,
        47,
        6,
        49,
        49,
        25,
        49,
        49,
        47,
        49,
        10,
        24,
        30,
        15,
        21,
        0,
        48
      ],
      "weeklyRejected": [
        44,
        0,
        37,
        38,
        49,
        49,
        49,
        0,
        44,
        31,
        18,
        46,
        15,
        22,
        27,
        34,
        22,
        44,
        12,
        45,
        44,
        47,
        25,
        49,
        30,
        49,
        4,
        27,
        10,
        30,
        0,
        3,
        34,
        25,
        4,
        5,
        49,
        43,
        15,
        4,
        32,
        49,
        49,
        7,
        41,
        30,
        10,
        39,
        2,
        47,
        0,
        40
      ],
      "weeklyHold": [
        0,
        0,
        30,
        12,
        15,
        32,
        49,
        15,
        0,
        20,
        47,
        27,
        38,
        17,
        29,
        19,
        48,
        46,
        14,
        40,
        36,
        18,
        49,
        14,
        26,
        39,
        32,
        0,
        49,
        15,
        48,
        7,
        19,
        35,
        48,
        33,
        23,
        8,
        25,
        13,
        17,
        22,
        38,
        35,
        49,
        24,
        17,
        28,
        7,
        31,
        3,
        45
      ],
      "weeklyBackordered": [
        1,
        2,
        32,
        49,
        47,
        46,
        45,
        25,
        49,
        38,
        49,
        32,
        3,
        48,
        20,
        39,
        27,
        31,
        0,
        49,
        47,
        49,
        25,
        44,
        1,
        14,
        14,
        0,
        1,
        49,
        46,
        37,
        22,
        49,
        11,
        0,
        25,
        0,
        49,
        20,
        23,
        46,
        1,
        49,
        11,
        14,
        15,
        26,
        40,
        5,
        1,
        19
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        32,
        41,
        40,
        16,
        5,
        11,
        26,
        13,
        48,
        22,
        49,
        26,
        4,
        2,
        1,
        19,
        7,
        24,
        31,
        30,
        29,
        47,
        31,
        43,
        11,
        44,
        14,
        22,
        31,
        33,
        0,
        0,
        38,
        0,
        21,
        41,
        47,
        8,
        46,
        49,
        19,
        18,
        43,
        33,
        49,
        0,
        0,
        21,
        12,
        49,
        3,
        19
      ],
      "weeklyRejected": [
        37,
        10,
        18,
        47,
        44,
        1,
        33,
        37,
        8,
        35,
        12,
        24,
        36,
        47,
        42,
        30,
        44,
        49,
        25,
        0,
        35,
        0,
        28,
        49,
        8,
        24,
        16,
        39,
        33,
        24,
        0,
        12,
        11,
        29,
        0,
        49,
        32,
        49,
        36,
        33,
        5,
        39,
        7,
        0,
        49,
        13,
        5,
        0,
        12,
        46,
        0,
        38
      ],
      "weeklyHold": [
        7,
        0,
        26,
        13,
        49,
        39,
        40,
        13,
        0,
        6,
        17,
        29,
        29,
        26,
        34,
        15,
        0,
        31,
        46,
        0,
        40,
        44,
        11,
        19,
        49,
        1,
        44,
        0,
        49,
        24,
        48,
        3,
        0,
        0,
        34,
        34,
        0,
        19,
        47,
        21,
        27,
        49,
        0,
        49,
        33,
        23,
        33,
        49,
        26,
        26,
        0,
        40
      ],
      "weeklyBackordered": [
        19,
        0,
        0,
        9,
        48,
        27,
        0,
        21,
        15,
        49,
        28,
        23,
        11,
        18,
        44,
        49,
        8,
        49,
        0,
        39,
        0,
        0,
        49,
        36,
        35,
        22,
        2,
        31,
        15,
        49,
        0,
        31,
        32,
        40,
        34,
        9,
        15,
        0,
        43,
        3,
        0,
        48,
        3,
        10,
        3,
        49,
        23,
        49,
        47,
        0,
        26,
        34
      ]
    },
    {
      "id": "3D26994F",
      "weeklyAccepted": [
        23,
        39,
        19,
        29,
        29,
        0,
        1,
        36,
        46,
        5,
        42,
        14,
        27,
        49,
        49,
        32,
        16,
        10,
        42,
        14,
        28,
        4,
        49,
        49,
        4,
        28,
        5,
        49,
        17,
        38,
        34,
        0,
        39,
        46,
        37,
        42,
        23,
        24,
        6,
        46,
        9,
        18,
        30,
        5,
        49,
        0,
        49,
        0,
        49,
        38,
        19,
        14
      ],
      "weeklyRejected": [
        49,
        49,
        12,
        47,
        4,
        16,
        1,
        48,
        0,
        43,
        45,
        24,
        39,
        48,
        12,
        21,
        49,
        49,
        25,
        49,
        27,
        9,
        0,
        49,
        38,
        36,
        2,
        40,
        48,
        32,
        7,
        47,
        31,
        30,
        21,
        49,
        43,
        21,
        11,
        38,
        48,
        49,
        23,
        22,
        49,
        45,
        29,
        30,
        12,
        49,
        0,
        35
      ],
      "weeklyHold": [
        39,
        49,
        9,
        15,
        6,
        19,
        0,
        8,
        33,
        40,
        47,
        31,
        16,
        40,
        49,
        39,
        6,
        38,
        30,
        38,
        29,
        24,
        4,
        12,
        12,
        43,
        0,
        46,
        49,
        1,
        25,
        0,
        34,
        35,
        21,
        3,
        49,
        13,
        49,
        28,
        0,
        40,
        7,
        32,
        49,
        49,
        32,
        23,
        19,
        19,
        41,
        0
      ],
      "weeklyBackordered": [
        0,
        30,
        8,
        49,
        46,
        20,
        34,
        49,
        25,
        2,
        10,
        8,
        47,
        4,
        28,
        21,
        25,
        33,
        0,
        6,
        14,
        20,
        42,
        49,
        29,
        4,
        1,
        34,
        19,
        49,
        33,
        26,
        35,
        49,
        8,
        0,
        2,
        21,
        17,
        18,
        33,
        13,
        27,
        49,
        31,
        27,
        40,
        12,
        29,
        48,
        45,
        14
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyAccepted": [
        21,
        34,
        23,
        32,
        33,
        10,
        9,
        4,
        24,
        19,
        49,
        0,
        39,
        49,
        21,
        32,
        39,
        9,
        35,
        9,
        6,
        41,
        36,
        42,
        30,
        37,
        29,
        30,
        21,
        32,
        21,
        3,
        32,
        32,
        3,
        34,
        47,
        28,
        40,
        49,
        20,
        12,
        39,
        3,
        49,
        0,
        0,
        47,
        31,
        19,
        0,
        0
      ],
      "weeklyRejected": [
        49,
        11,
        32,
        15,
        37,
        23,
        29,
        17,
        44,
        7,
        44,
        13,
        46,
        47,
        45,
        29,
        11,
        45,
        24,
        28,
        0,
        49,
        23,
        19,
        18,
        49,
        45,
        23,
        40,
        18,
        35,
        47,
        29,
        42,
        3,
        35,
        1,
        49,
        35,
        47,
        28,
        35,
        49,
        12,
        49,
        16,
        18,
        0,
        35,
        2,
        8,
        32
      ],
      "weeklyHold": [
        28,
        18,
        40,
        1,
        30,
        32,
        12,
        22,
        26,
        0,
        13,
        46,
        37,
        49,
        12,
        24,
        20,
        3,
        4,
        49,
        23,
        49,
        2,
        22,
        40,
        23,
        15,
        0,
        33,
        0,
        23,
        0,
        10,
        43,
        10,
        35,
        27,
        11,
        49,
        42,
        0,
        32,
        5,
        25,
        43,
        10,
        0,
        25,
        0,
        49,
        34,
        0
      ],
      "weeklyBackordered": [
        49,
        49,
        15,
        36,
        49,
        31,
        47,
        1,
        16,
        19,
        11,
        0,
        5,
        28,
        42,
        49,
        36,
        27,
        12,
        25,
        19,
        30,
        0,
        13,
        48,
        9,
        1,
        30,
        1,
        18,
        0,
        47,
        48,
        49,
        15,
        0,
        49,
        40,
        49,
        49,
        10,
        48,
        14,
        49,
        49,
        10,
        37,
        22,
        11,
        49,
        49,
        25
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        0,
        24,
        49,
        32,
        25,
        20,
        18,
        2,
        38,
        5,
        39,
        17,
        7,
        38,
        25,
        28,
        16,
        0,
        9,
        15,
        15,
        5,
        0,
        37,
        3,
        31,
        11,
        1,
        32,
        49,
        3,
        5,
        37,
        0,
        30,
        48,
        27,
        13,
        49,
        22,
        25,
        17,
        35,
        11,
        49,
        2,
        23,
        36,
        27,
        44,
        36,
        48
      ],
      "weeklyRejected": [
        42,
        1,
        33,
        17,
        28,
        0,
        49,
        20,
        32,
        35,
        29,
        35,
        13,
        48,
        15,
        49,
        19,
        49,
        12,
        25,
        28,
        34,
        29,
        42,
        2,
        40,
        0,
        36,
        28,
        19,
        43,
        18,
        48,
        22,
        0,
        49,
        12,
        0,
        1,
        49,
        42,
        9,
        19,
        30,
        49,
        36,
        46,
        37,
        0,
        49,
        0,
        26
      ],
      "weeklyHold": [
        49,
        45,
        0,
        17,
        32,
        48,
        32,
        43,
        25,
        37,
        23,
        25,
        27,
        19,
        21,
        31,
        0,
        48,
        19,
        42,
        27,
        31,
        19,
        0,
        29,
        48,
        30,
        40,
        21,
        0,
        39,
        12,
        0,
        41,
        0,
        19,
        41,
        0,
        47,
        21,
        0,
        32,
        10,
        20,
        18,
        49,
        31,
        33,
        25,
        40,
        10,
        0
      ],
      "weeklyBackordered": [
        34,
        48,
        23,
        4,
        49,
        17,
        40,
        29,
        3,
        5,
        27,
        33,
        3,
        10,
        33,
        1,
        11,
        47,
        0,
        17,
        43,
        19,
        23,
        17,
        14,
        14,
        12,
        38,
        44,
        38,
        49,
        41,
        39,
        13,
        29,
        0,
        33,
        10,
        25,
        32,
        14,
        15,
        12,
        49,
        26,
        0,
        33,
        26,
        28,
        15,
        39,
        27
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        27,
        49,
        20,
        0,
        49,
        30,
        1,
        48,
        38,
        0,
        45,
        3,
        12,
        6,
        1,
        28,
        41,
        7,
        0,
        21,
        9,
        12,
        7,
        43,
        11,
        32,
        5,
        30,
        49,
        45,
        28,
        10,
        18,
        0,
        48,
        29,
        43,
        48,
        49,
        24,
        9,
        0,
        49,
        29,
        49,
        5,
        9,
        0,
        12,
        25,
        27,
        48
      ],
      "weeklyRejected": [
        33,
        14,
        2,
        29,
        41,
        0,
        0,
        49,
        7,
        25,
        0,
        26,
        27,
        35,
        2,
        18,
        46,
        46,
        15,
        0,
        27,
        46,
        34,
        35,
        26,
        30,
        23,
        8,
        9,
        19,
        0,
        28,
        18,
        26,
        48,
        35,
        33,
        42,
        37,
        16,
        26,
        0,
        2,
        0,
        49,
        9,
        38,
        47,
        24,
        9,
        0,
        26
      ],
      "weeklyHold": [
        48,
        9,
        25,
        36,
        44,
        8,
        15,
        47,
        28,
        42,
        24,
        33,
        40,
        8,
        49,
        17,
        0,
        27,
        25,
        0,
        20,
        43,
        2,
        28,
        29,
        2,
        0,
        13,
        21,
        7,
        48,
        9,
        0,
        38,
        25,
        45,
        19,
        0,
        37,
        19,
        37,
        43,
        14,
        49,
        20,
        21,
        19,
        49,
        0,
        26,
        0,
        0
      ],
      "weeklyBackordered": [
        12,
        30,
        31,
        39,
        39,
        25,
        1,
        1,
        0,
        0,
        6,
        49,
        12,
        24,
        46,
        43,
        44,
        41,
        23,
        21,
        46,
        17,
        33,
        0,
        6,
        49,
        33,
        0,
        22,
        49,
        0,
        49,
        11,
        44,
        43,
        16,
        24,
        36,
        22,
        33,
        33,
        48,
        0,
        49,
        49,
        32,
        49,
        0,
        0,
        14,
        18,
        49
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        0,
        33,
        24,
        48,
        1,
        7,
        37,
        41,
        38,
        37,
        38,
        5,
        29,
        40,
        18,
        49,
        32,
        25,
        4,
        37,
        37,
        18,
        41,
        43,
        39,
        44,
        8,
        6,
        21,
        16,
        17,
        18,
        49,
        41,
        20,
        30,
        0,
        48,
        11,
        35,
        33,
        18,
        49,
        25,
        49,
        6,
        0,
        49,
        17,
        49,
        5,
        18
      ],
      "weeklyRejected": [
        29,
        22,
        22,
        13,
        42,
        26,
        49,
        37,
        27,
        1,
        0,
        46,
        49,
        48,
        48,
        49,
        17,
        49,
        21,
        33,
        13,
        4,
        49,
        11,
        22,
        49,
        43,
        33,
        48,
        12,
        49,
        23,
        48,
        49,
        0,
        49,
        45,
        12,
        1,
        11,
        21,
        27,
        0,
        26,
        49,
        44,
        11,
        20,
        16,
        49,
        10,
        49
      ],
      "weeklyHold": [
        13,
        4,
        49,
        18,
        20,
        48,
        7,
        10,
        38,
        26,
        12,
        5,
        16,
        29,
        39,
        0,
        29,
        30,
        28,
        45,
        49,
        49,
        26,
        6,
        49,
        6,
        38,
        29,
        28,
        40,
        23,
        10,
        0,
        5,
        31,
        42,
        8,
        15,
        33,
        49,
        35,
        25,
        14,
        0,
        49,
        40,
        21,
        49,
        24,
        20,
        7,
        36
      ],
      "weeklyBackordered": [
        23,
        0,
        0,
        5,
        49,
        45,
        15,
        24,
        26,
        20,
        13,
        49,
        49,
        49,
        49,
        1,
        42,
        49,
        37,
        29,
        49,
        0,
        5,
        0,
        9,
        29,
        11,
        34,
        23,
        37,
        0,
        49,
        32,
        1,
        0,
        0,
        3,
        11,
        26,
        35,
        0,
        48,
        0,
        12,
        3,
        33,
        24,
        18,
        31,
        28,
        14,
        11
      ]
    },
    {
      "id": "099FDC48",
      "weeklyAccepted": [
        28,
        49,
        49,
        37,
        3,
        26,
        2,
        22,
        25,
        33,
        46,
        0,
        47,
        25,
        31,
        20,
        49,
        11,
        10,
        3,
        23,
        35,
        22,
        23,
        0,
        47,
        36,
        10,
        34,
        23,
        30,
        33,
        49,
        0,
        7,
        19,
        29,
        6,
        23,
        49,
        19,
        0,
        45,
        25,
        49,
        0,
        9,
        2,
        44,
        49,
        0,
        1
      ],
      "weeklyRejected": [
        49,
        20,
        8,
        1,
        49,
        1,
        31,
        49,
        33,
        14,
        31,
        24,
        8,
        8,
        48,
        48,
        0,
        49,
        3,
        0,
        49,
        29,
        49,
        9,
        25,
        16,
        42,
        4,
        43,
        20,
        32,
        10,
        12,
        17,
        29,
        49,
        38,
        14,
        10,
        25,
        10,
        18,
        18,
        0,
        43,
        0,
        3,
        22,
        10,
        7,
        44,
        49
      ],
      "weeklyHold": [
        34,
        0,
        32,
        30,
        0,
        37,
        27,
        2,
        4,
        18,
        47,
        15,
        16,
        16,
        39,
        22,
        0,
        3,
        18,
        0,
        4,
        49,
        13,
        42,
        35,
        32,
        0,
        17,
        21,
        27,
        42,
        40,
        0,
        49,
        27,
        49,
        28,
        12,
        47,
        0,
        0,
        30,
        0,
        16,
        0,
        26,
        29,
        45,
        0,
        15,
        17,
        29
      ],
      "weeklyBackordered": [
        0,
        0,
        11,
        43,
        49,
        38,
        26,
        43,
        14,
        18,
        49,
        42,
        45,
        21,
        25,
        49,
        4,
        49,
        0,
        45,
        39,
        25,
        26,
        8,
        17,
        22,
        27,
        35,
        48,
        10,
        17,
        7,
        18,
        15,
        34,
        0,
        25,
        0,
        14,
        7,
        21,
        48,
        20,
        49,
        8,
        5,
        49,
        0,
        8,
        13,
        36,
        27
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        21,
        37,
        29,
        0,
        30,
        11,
        15,
        49,
        29,
        49,
        31,
        0,
        49,
        49,
        25,
        0,
        39,
        0,
        8,
        17,
        28,
        49,
        16,
        31,
        8,
        19,
        35,
        5,
        28,
        39,
        8,
        36,
        49,
        0,
        10,
        32,
        44,
        28,
        0,
        49,
        4,
        23,
        36,
        42,
        46,
        22,
        0,
        19,
        15,
        31,
        20,
        46
      ],
      "weeklyRejected": [
        49,
        14,
        25,
        3,
        42,
        49,
        45,
        9,
        49,
        24,
        23,
        20,
        37,
        32,
        29,
        27,
        25,
        43,
        23,
        0,
        12,
        40,
        41,
        14,
        27,
        49,
        41,
        14,
        41,
        0,
        33,
        24,
        44,
        27,
        27,
        49,
        33,
        28,
        11,
        27,
        31,
        12,
        6,
        0,
        49,
        27,
        0,
        35,
        28,
        14,
        32,
        49
      ],
      "weeklyHold": [
        8,
        0,
        49,
        1,
        26,
        13,
        45,
        12,
        43,
        26,
        37,
        19,
        41,
        11,
        49,
        0,
        9,
        2,
        21,
        24,
        14,
        0,
        41,
        8,
        35,
        40,
        30,
        1,
        49,
        9,
        48,
        35,
        22,
        42,
        1,
        49,
        16,
        0,
        6,
        1,
        32,
        49,
        0,
        7,
        49,
        49,
        49,
        48,
        0,
        38,
        34,
        3
      ],
      "weeklyBackordered": [
        0,
        8,
        17,
        44,
        33,
        46,
        49,
        49,
        18,
        12,
        43,
        47,
        36,
        3,
        41,
        32,
        1,
        37,
        34,
        5,
        30,
        17,
        10,
        15,
        24,
        23,
        1,
        0,
        27,
        43,
        27,
        49,
        31,
        0,
        25,
        17,
        1,
        16,
        18,
        25,
        20,
        48,
        0,
        21,
        24,
        21,
        49,
        20,
        22,
        11,
        49,
        26
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        0,
        16,
        10,
        3,
        49,
        9,
        25,
        49,
        33,
        21,
        31,
        7,
        27,
        27,
        15,
        4,
        46,
        0,
        30,
        13,
        18,
        14,
        27,
        40,
        5,
        47,
        0,
        0,
        34,
        22,
        18,
        14,
        16,
        4,
        14,
        27,
        18,
        30,
        49,
        40,
        49,
        0,
        33,
        19,
        49,
        0,
        0,
        18,
        0,
        49,
        41,
        3
      ],
      "weeklyRejected": [
        49,
        0,
        10,
        15,
        37,
        34,
        0,
        49,
        29,
        20,
        22,
        39,
        41,
        22,
        44,
        18,
        13,
        39,
        40,
        0,
        12,
        27,
        49,
        16,
        7,
        49,
        31,
        39,
        48,
        48,
        28,
        27,
        34,
        0,
        17,
        0,
        49,
        47,
        35,
        4,
        40,
        39,
        0,
        0,
        47,
        0,
        23,
        21,
        40,
        22,
        0,
        44
      ],
      "weeklyHold": [
        29,
        3,
        49,
        18,
        3,
        48,
        20,
        16,
        48,
        38,
        42,
        35,
        12,
        1,
        34,
        0,
        0,
        1,
        34,
        28,
        20,
        49,
        28,
        39,
        41,
        16,
        32,
        0,
        49,
        19,
        41,
        19,
        31,
        49,
        11,
        49,
        17,
        15,
        47,
        25,
        49,
        49,
        0,
        47,
        39,
        0,
        38,
        40,
        0,
        33,
        49,
        49
      ],
      "weeklyBackordered": [
        49,
        40,
        17,
        39,
        42,
        0,
        38,
        34,
        11,
        49,
        31,
        26,
        3,
        42,
        0,
        40,
        15,
        32,
        38,
        0,
        28,
        44,
        10,
        43,
        5,
        1,
        23,
        33,
        39,
        23,
        44,
        48,
        47,
        10,
        49,
        0,
        5,
        0,
        35,
        40,
        39,
        32,
        16,
        49,
        27,
        34,
        22,
        26,
        9,
        35,
        49,
        13
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        12,
        28,
        49,
        48,
        26,
        14,
        4,
        49,
        48,
        28,
        37,
        0,
        49,
        14,
        18,
        0,
        21,
        0,
        0,
        25,
        0,
        34,
        32,
        40,
        21,
        35,
        9,
        49,
        13,
        44,
        49,
        22,
        49,
        5,
        29,
        38,
        15,
        9,
        49,
        7,
        43,
        25,
        26,
        18,
        0,
        14,
        21,
        31,
        49,
        13,
        4,
        48
      ],
      "weeklyRejected": [
        49,
        16,
        29,
        26,
        27,
        0,
        22,
        31,
        0,
        39,
        45,
        19,
        37,
        31,
        2,
        6,
        12,
        48,
        40,
        15,
        0,
        0,
        7,
        49,
        19,
        49,
        49,
        39,
        47,
        0,
        26,
        31,
        48,
        32,
        49,
        43,
        49,
        0,
        6,
        0,
        40,
        2,
        39,
        4,
        43,
        49,
        8,
        0,
        19,
        49,
        0,
        49
      ],
      "weeklyHold": [
        13,
        43,
        13,
        16,
        13,
        12,
        25,
        41,
        44,
        49,
        15,
        15,
        31,
        23,
        5,
        49,
        27,
        13,
        15,
        0,
        0,
        49,
        6,
        38,
        1,
        48,
        15,
        14,
        23,
        1,
        28,
        18,
        16,
        33,
        41,
        41,
        18,
        0,
        49,
        18,
        25,
        40,
        16,
        0,
        17,
        35,
        9,
        3,
        0,
        23,
        35,
        20
      ],
      "weeklyBackordered": [
        8,
        49,
        10,
        26,
        49,
        46,
        29,
        25,
        20,
        30,
        45,
        49,
        3,
        41,
        45,
        1,
        43,
        38,
        5,
        48,
        26,
        40,
        28,
        0,
        17,
        1,
        3,
        29,
        1,
        49,
        19,
        13,
        37,
        43,
        37,
        0,
        22,
        0,
        35,
        23,
        34,
        48,
        6,
        23,
        0,
        26,
        30,
        6,
        11,
        20,
        44,
        10
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        40,
        28,
        41,
        33,
        14,
        32,
        15,
        23,
        48,
        49,
        49,
        0,
        11,
        25,
        10,
        25,
        29,
        14,
        0,
        0,
        1,
        0,
        49,
        0,
        26,
        47,
        10,
        49,
        45,
        49,
        16,
        15,
        34,
        41,
        48,
        14,
        34,
        48,
        49,
        46,
        2,
        47,
        38,
        23,
        49,
        20,
        13,
        41,
        0,
        36,
        38,
        48
      ],
      "weeklyRejected": [
        49,
        25,
        28,
        47,
        0,
        0,
        43,
        49,
        35,
        12,
        26,
        22,
        39,
        41,
        24,
        24,
        36,
        41,
        39,
        40,
        10,
        23,
        0,
        20,
        19,
        41,
        28,
        41,
        48,
        0,
        15,
        15,
        22,
        47,
        42,
        49,
        20,
        28,
        39,
        16,
        7,
        48,
        14,
        8,
        44,
        11,
        32,
        23,
        28,
        49,
        0,
        5
      ],
      "weeklyHold": [
        49,
        0,
        49,
        1,
        13,
        47,
        0,
        43,
        32,
        15,
        20,
        24,
        46,
        45,
        49,
        0,
        0,
        9,
        26,
        0,
        13,
        22,
        13,
        0,
        0,
        29,
        0,
        49,
        48,
        0,
        48,
        0,
        1,
        49,
        22,
        10,
        9,
        49,
        3,
        31,
        49,
        49,
        19,
        33,
        23,
        16,
        47,
        46,
        2,
        32,
        39,
        46
      ],
      "weeklyBackordered": [
        41,
        0,
        23,
        22,
        37,
        39,
        32,
        27,
        21,
        4,
        23,
        21,
        3,
        34,
        34,
        30,
        35,
        32,
        0,
        49,
        46,
        22,
        25,
        18,
        25,
        6,
        3,
        21,
        4,
        49,
        45,
        32,
        0,
        35,
        16,
        0,
        28,
        9,
        7,
        35,
        25,
        44,
        0,
        42,
        35,
        17,
        36,
        35,
        0,
        17,
        49,
        12
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        0,
        20,
        49,
        0,
        49,
        38,
        20,
        41,
        0,
        23,
        31,
        18,
        26,
        2,
        41,
        0,
        39,
        14,
        2,
        27,
        9,
        31,
        39,
        1,
        0,
        47,
        14,
        49,
        38,
        24,
        0,
        0,
        49,
        0,
        48,
        43,
        15,
        5,
        49,
        49,
        0,
        35,
        43,
        20,
        49,
        26,
        16,
        36,
        0,
        18,
        21,
        24
      ],
      "weeklyRejected": [
        43,
        11,
        8,
        14,
        34,
        0,
        26,
        34,
        28,
        34,
        19,
        44,
        0,
        25,
        43,
        29,
        0,
        49,
        0,
        14,
        0,
        7,
        46,
        49,
        38,
        9,
        44,
        12,
        48,
        35,
        26,
        47,
        22,
        25,
        21,
        12,
        49,
        35,
        15,
        49,
        34,
        27,
        49,
        30,
        22,
        26,
        0,
        34,
        0,
        49,
        35,
        49
      ],
      "weeklyHold": [
        9,
        0,
        12,
        15,
        15,
        46,
        49,
        5,
        22,
        23,
        20,
        27,
        29,
        44,
        39,
        0,
        0,
        34,
        49,
        0,
        32,
        0,
        6,
        27,
        34,
        29,
        29,
        49,
        16,
        23,
        48,
        23,
        44,
        0,
        27,
        49,
        12,
        21,
        39,
        49,
        8,
        31,
        17,
        10,
        31,
        49,
        24,
        30,
        7,
        28,
        34,
        49
      ],
      "weeklyBackordered": [
        22,
        0,
        0,
        12,
        12,
        46,
        37,
        49,
        0,
        46,
        37,
        0,
        48,
        45,
        0,
        49,
        49,
        40,
        16,
        0,
        49,
        21,
        7,
        40,
        1,
        16,
        29,
        5,
        26,
        49,
        6,
        37,
        17,
        33,
        14,
        0,
        32,
        4,
        19,
        18,
        13,
        48,
        0,
        49,
        10,
        49,
        46,
        49,
        0,
        48,
        46,
        49
      ]
    },
    {
      "id": "42A1729B",
      "weeklyAccepted": [
        24,
        49,
        10,
        15,
        49,
        0,
        7,
        41,
        48,
        0,
        29,
        15,
        33,
        21,
        9,
        39,
        35,
        9,
        27,
        18,
        26,
        36,
        49,
        35,
        29,
        25,
        26,
        15,
        7,
        43,
        49,
        35,
        42,
        39,
        19,
        41,
        9,
        10,
        35,
        49,
        31,
        45,
        49,
        40,
        49,
        15,
        21,
        40,
        0,
        47,
        49,
        21
      ],
      "weeklyRejected": [
        49,
        13,
        49,
        35,
        1,
        5,
        0,
        10,
        19,
        44,
        21,
        31,
        38,
        5,
        21,
        28,
        21,
        49,
        0,
        3,
        15,
        47,
        23,
        30,
        16,
        30,
        49,
        7,
        48,
        22,
        45,
        28,
        48,
        36,
        18,
        46,
        49,
        26,
        1,
        13,
        33,
        19,
        38,
        49,
        49,
        0,
        49,
        19,
        29,
        47,
        22,
        47
      ],
      "weeklyHold": [
        42,
        0,
        49,
        19,
        3,
        0,
        14,
        38,
        12,
        49,
        1,
        39,
        48,
        49,
        9,
        10,
        9,
        9,
        39,
        0,
        15,
        45,
        15,
        18,
        16,
        4,
        49,
        11,
        49,
        9,
        48,
        48,
        2,
        25,
        35,
        23,
        45,
        9,
        19,
        0,
        49,
        25,
        0,
        41,
        42,
        0,
        49,
        49,
        11,
        31,
        34,
        11
      ],
      "weeklyBackordered": [
        49,
        35,
        28,
        31,
        36,
        17,
        28,
        11,
        49,
        49,
        49,
        28,
        11,
        49,
        20,
        34,
        49,
        10,
        6,
        14,
        0,
        24,
        35,
        38,
        1,
        33,
        10,
        14,
        13,
        38,
        0,
        49,
        3,
        37,
        0,
        19,
        23,
        0,
        0,
        30,
        12,
        48,
        16,
        49,
        13,
        25,
        49,
        28,
        49,
        49,
        7,
        23
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        33,
        42,
        28,
        17,
        30,
        40,
        33,
        49,
        4,
        28,
        18,
        0,
        28,
        0,
        36,
        25,
        44,
        10,
        0,
        10,
        49,
        0,
        28,
        19,
        39,
        14,
        7,
        41,
        25,
        49,
        26,
        0,
        33,
        39,
        17,
        48,
        37,
        48,
        49,
        49,
        32,
        7,
        35,
        39,
        35,
        0,
        0,
        34,
        48,
        47,
        41,
        42
      ],
      "weeklyRejected": [
        35,
        9,
        9,
        35,
        32,
        30,
        26,
        49,
        32,
        21,
        36,
        28,
        6,
        30,
        29,
        49,
        49,
        35,
        24,
        20,
        17,
        19,
        36,
        41,
        0,
        37,
        44,
        19,
        48,
        11,
        31,
        47,
        41,
        49,
        0,
        49,
        25,
        39,
        17,
        0,
        43,
        8,
        0,
        8,
        49,
        39,
        5,
        32,
        0,
        15,
        5,
        41
      ],
      "weeklyHold": [
        16,
        38,
        13,
        18,
        22,
        48,
        41,
        49,
        7,
        36,
        47,
        36,
        47,
        30,
        1,
        0,
        19,
        19,
        49,
        36,
        14,
        25,
        26,
        49,
        31,
        29,
        37,
        9,
        36,
        2,
        37,
        17,
        26,
        49,
        16,
        22,
        31,
        0,
        49,
        16,
        49,
        24,
        0,
        39,
        19,
        44,
        49,
        0,
        0,
        38,
        22,
        27
      ],
      "weeklyBackordered": [
        6,
        39,
        43,
        11,
        34,
        29,
        24,
        38,
        27,
        7,
        29,
        35,
        36,
        49,
        32,
        49,
        11,
        49,
        2,
        49,
        43,
        49,
        0,
        0,
        3,
        26,
        49,
        8,
        48,
        43,
        36,
        44,
        48,
        6,
        14,
        0,
        40,
        0,
        11,
        4,
        2,
        48,
        5,
        40,
        21,
        49,
        49,
        38,
        44,
        37,
        39,
        15
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        16,
        49,
        14,
        37,
        16,
        0,
        19,
        24,
        46,
        30,
        32,
        28,
        27,
        9,
        38,
        0,
        21,
        13,
        27,
        20,
        9,
        33,
        47,
        13,
        0,
        29,
        23,
        32,
        5,
        1,
        16,
        0,
        40,
        24,
        48,
        34,
        4,
        43,
        49,
        49,
        41,
        49,
        49,
        26,
        25,
        18,
        0,
        11,
        35,
        0,
        22,
        15
      ],
      "weeklyRejected": [
        43,
        28,
        33,
        6,
        20,
        17,
        49,
        13,
        43,
        45,
        0,
        0,
        11,
        23,
        17,
        17,
        8,
        24,
        20,
        27,
        18,
        44,
        16,
        29,
        28,
        39,
        49,
        11,
        48,
        23,
        49,
        41,
        19,
        4,
        39,
        48,
        49,
        32,
        24,
        0,
        27,
        31,
        26,
        18,
        49,
        19,
        13,
        10,
        17,
        49,
        2,
        36
      ],
      "weeklyHold": [
        11,
        22,
        17,
        1,
        0,
        26,
        13,
        3,
        49,
        19,
        29,
        20,
        30,
        6,
        24,
        6,
        49,
        19,
        0,
        31,
        15,
        26,
        6,
        1,
        17,
        34,
        26,
        0,
        49,
        3,
        48,
        11,
        0,
        23,
        37,
        34,
        49,
        9,
        28,
        12,
        0,
        24,
        44,
        46,
        43,
        7,
        40,
        0,
        0,
        39,
        38,
        43
      ],
      "weeklyBackordered": [
        13,
        0,
        5,
        2,
        28,
        23,
        0,
        17,
        31,
        49,
        49,
        38,
        49,
        32,
        0,
        38,
        17,
        28,
        0,
        0,
        17,
        20,
        33,
        27,
        36,
        2,
        37,
        23,
        23,
        49,
        30,
        29,
        28,
        36,
        0,
        3,
        36,
        0,
        0,
        7,
        17,
        43,
        2,
        48,
        0,
        19,
        31,
        33,
        17,
        2,
        24,
        24
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        10,
        49,
        29,
        28,
        33,
        4,
        23,
        49,
        7,
        3,
        44,
        10,
        40,
        9,
        49,
        23,
        46,
        2,
        0,
        21,
        2,
        35,
        49,
        49,
        33,
        47,
        29,
        15,
        1,
        48,
        33,
        35,
        41,
        38,
        40,
        41,
        17,
        36,
        26,
        49,
        28,
        0,
        0,
        48,
        49,
        12,
        19,
        0,
        0,
        34,
        0,
        22
      ],
      "weeklyRejected": [
        49,
        7,
        31,
        31,
        37,
        45,
        49,
        17,
        49,
        45,
        21,
        13,
        40,
        34,
        48,
        0,
        45,
        49,
        6,
        11,
        38,
        41,
        40,
        31,
        22,
        27,
        25,
        0,
        31,
        48,
        41,
        37,
        20,
        49,
        30,
        49,
        33,
        44,
        1,
        0,
        38,
        45,
        21,
        27,
        49,
        45,
        20,
        49,
        11,
        19,
        0,
        49
      ],
      "weeklyHold": [
        35,
        40,
        49,
        1,
        49,
        21,
        35,
        24,
        38,
        31,
        20,
        10,
        1,
        9,
        17,
        1,
        1,
        29,
        36,
        5,
        13,
        42,
        5,
        3,
        49,
        48,
        31,
        48,
        20,
        3,
        48,
        48,
        0,
        39,
        32,
        49,
        47,
        6,
        49,
        15,
        49,
        38,
        2,
        30,
        12,
        36,
        0,
        0,
        14,
        49,
        49,
        35
      ],
      "weeklyBackordered": [
        38,
        49,
        22,
        49,
        46,
        25,
        2,
        34,
        17,
        0,
        48,
        33,
        26,
        25,
        0,
        22,
        49,
        43,
        28,
        38,
        21,
        22,
        20,
        12,
        48,
        36,
        1,
        4,
        24,
        17,
        0,
        49,
        42,
        16,
        11,
        11,
        26,
        0,
        5,
        23,
        48,
        48,
        37,
        49,
        0,
        35,
        32,
        21,
        0,
        23,
        36,
        31
      ]
    },
    {
      "id": "F55885BD",
      "weeklyAccepted": [
        0,
        46,
        11,
        15,
        21,
        0,
        1,
        38,
        28,
        49,
        28,
        11,
        33,
        0,
        10,
        17,
        49,
        48,
        0,
        27,
        6,
        9,
        17,
        37,
        36,
        39,
        21,
        0,
        46,
        23,
        2,
        16,
        49,
        3,
        48,
        30,
        29,
        48,
        38,
        49,
        3,
        41,
        33,
        49,
        49,
        17,
        15,
        14,
        0,
        2,
        24,
        42
      ],
      "weeklyRejected": [
        49,
        0,
        20,
        0,
        17,
        0,
        40,
        25,
        22,
        35,
        33,
        42,
        33,
        32,
        29,
        19,
        16,
        27,
        37,
        21,
        40,
        49,
        49,
        36,
        21,
        23,
        32,
        20,
        48,
        19,
        20,
        30,
        26,
        29,
        6,
        15,
        32,
        0,
        49,
        6,
        26,
        21,
        1,
        0,
        37,
        32,
        16,
        9,
        23,
        6,
        0,
        49
      ],
      "weeklyHold": [
        0,
        1,
        49,
        1,
        15,
        22,
        5,
        2,
        0,
        24,
        45,
        38,
        10,
        27,
        47,
        25,
        0,
        25,
        10,
        17,
        26,
        25,
        34,
        41,
        21,
        1,
        9,
        7,
        15,
        3,
        48,
        17,
        0,
        29,
        32,
        46,
        22,
        29,
        49,
        22,
        28,
        9,
        45,
        36,
        41,
        26,
        0,
        34,
        3,
        37,
        48,
        49
      ],
      "weeklyBackordered": [
        0,
        36,
        42,
        32,
        21,
        12,
        49,
        28,
        23,
        34,
        34,
        24,
        32,
        37,
        9,
        41,
        49,
        1,
        37,
        25,
        40,
        17,
        19,
        20,
        20,
        1,
        6,
        8,
        32,
        38,
        41,
        49,
        11,
        48,
        0,
        0,
        18,
        42,
        0,
        5,
        5,
        48,
        5,
        49,
        21,
        44,
        49,
        6,
        29,
        18,
        7,
        38
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        0,
        49,
        17,
        6,
        49,
        49,
        21,
        45,
        48,
        7,
        49,
        0,
        29,
        15,
        12,
        24,
        31,
        40,
        0,
        9,
        0,
        30,
        5,
        26,
        4,
        38,
        11,
        49,
        1,
        12,
        35,
        0,
        49,
        28,
        3,
        42,
        11,
        28,
        49,
        49,
        28,
        1,
        49,
        14,
        20,
        0,
        11,
        1,
        8,
        49,
        49,
        43
      ],
      "weeklyRejected": [
        33,
        47,
        49,
        23,
        34,
        0,
        27,
        31,
        0,
        48,
        8,
        24,
        30,
        13,
        36,
        0,
        49,
        30,
        23,
        38,
        8,
        0,
        40,
        18,
        0,
        41,
        36,
        21,
        41,
        16,
        34,
        33,
        23,
        48,
        1,
        49,
        4,
        44,
        3,
        9,
        14,
        0,
        27,
        35,
        45,
        49,
        49,
        4,
        6,
        35,
        6,
        33
      ],
      "weeklyHold": [
        0,
        49,
        49,
        1,
        49,
        43,
        49,
        23,
        23,
        49,
        14,
        30,
        21,
        19,
        5,
        45,
        10,
        44,
        49,
        32,
        0,
        19,
        18,
        31,
        45,
        42,
        0,
        49,
        49,
        32,
        35,
        29,
        45,
        0,
        42,
        12,
        40,
        32,
        28,
        48,
        26,
        49,
        0,
        48,
        13,
        43,
        48,
        41,
        13,
        19,
        49,
        9
      ],
      "weeklyBackordered": [
        24,
        42,
        0,
        0,
        49,
        46,
        5,
        33,
        19,
        0,
        31,
        42,
        11,
        49,
        22,
        49,
        49,
        49,
        12,
        14,
        46,
        0,
        33,
        25,
        8,
        30,
        39,
        11,
        30,
        49,
        1,
        43,
        0,
        35,
        0,
        0,
        12,
        0,
        4,
        12,
        0,
        27,
        17,
        35,
        25,
        40,
        24,
        5,
        0,
        0,
        49,
        31
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyAccepted": [
        11,
        49,
        10,
        39,
        24,
        25,
        13,
        28,
        48,
        28,
        49,
        11,
        11,
        48,
        45,
        31,
        48,
        9,
        4,
        34,
        32,
        49,
        35,
        13,
        27,
        47,
        27,
        21,
        11,
        1,
        0,
        28,
        49,
        0,
        34,
        35,
        19,
        3,
        13,
        49,
        34,
        0,
        34,
        1,
        49,
        13,
        33,
        1,
        0,
        30,
        11,
        0
      ],
      "weeklyRejected": [
        41,
        0,
        23,
        16,
        0,
        7,
        27,
        15,
        42,
        1,
        0,
        38,
        35,
        48,
        37,
        29,
        0,
        49,
        25,
        0,
        48,
        30,
        49,
        38,
        41,
        31,
        41,
        34,
        10,
        14,
        36,
        47,
        48,
        23,
        3,
        49,
        12,
        49,
        12,
        36,
        40,
        26,
        12,
        23,
        0,
        7,
        0,
        0,
        0,
        44,
        14,
        41
      ],
      "weeklyHold": [
        43,
        28,
        43,
        1,
        4,
        17,
        34,
        7,
        29,
        28,
        44,
        49,
        48,
        12,
        49,
        1,
        0,
        39,
        3,
        4,
        48,
        34,
        0,
        38,
        23,
        1,
        0,
        29,
        49,
        4,
        45,
        7,
        0,
        28,
        27,
        16,
        28,
        0,
        32,
        21,
        39,
        42,
        20,
        17,
        8,
        7,
        0,
        44,
        25,
        43,
        18,
        47
      ],
      "weeklyBackordered": [
        21,
        2,
        20,
        49,
        49,
        31,
        49,
        16,
        38,
        0,
        36,
        19,
        20,
        36,
        22,
        1,
        39,
        49,
        39,
        26,
        0,
        0,
        0,
        0,
        28,
        33,
        7,
        20,
        38,
        47,
        4,
        43,
        37,
        49,
        0,
        0,
        6,
        0,
        26,
        49,
        0,
        48,
        36,
        49,
        29,
        49,
        39,
        7,
        18,
        9,
        49,
        4
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        0,
        35,
        30,
        18,
        37,
        9,
        1,
        47,
        9,
        49,
        44,
        10,
        49,
        34,
        27,
        10,
        49,
        20,
        8,
        30,
        49,
        24,
        49,
        9,
        16,
        47,
        24,
        0,
        1,
        3,
        15,
        15,
        48,
        27,
        23,
        28,
        37,
        39,
        49,
        49,
        0,
        0,
        49,
        49,
        34,
        0,
        21,
        27,
        0,
        21,
        0,
        34
      ],
      "weeklyRejected": [
        38,
        49,
        33,
        39,
        28,
        22,
        23,
        1,
        26,
        16,
        45,
        10,
        36,
        38,
        25,
        49,
        0,
        28,
        3,
        5,
        13,
        35,
        0,
        33,
        39,
        33,
        26,
        34,
        48,
        37,
        22,
        22,
        4,
        34,
        30,
        49,
        43,
        3,
        1,
        49,
        39,
        36,
        49,
        6,
        49,
        24,
        19,
        10,
        48,
        41,
        22,
        49
      ],
      "weeklyHold": [
        49,
        47,
        8,
        12,
        49,
        33,
        22,
        2,
        35,
        30,
        19,
        28,
        39,
        43,
        42,
        15,
        0,
        47,
        0,
        17,
        28,
        31,
        0,
        13,
        37,
        1,
        0,
        17,
        1,
        5,
        48,
        46,
        8,
        45,
        13,
        28,
        19,
        12,
        37,
        9,
        45,
        49,
        18,
        25,
        45,
        0,
        0,
        9,
        0,
        32,
        28,
        19
      ],
      "weeklyBackordered": [
        28,
        46,
        35,
        49,
        45,
        46,
        47,
        49,
        38,
        8,
        35,
        0,
        38,
        28,
        0,
        31,
        49,
        45,
        15,
        14,
        21,
        0,
        17,
        37,
        39,
        26,
        1,
        1,
        41,
        41,
        27,
        41,
        48,
        0,
        15,
        27,
        38,
        9,
        0,
        38,
        23,
        48,
        0,
        49,
        3,
        44,
        42,
        1,
        0,
        20,
        21,
        49
      ]
    },
    {
      "id": "DB301E71",
      "weeklyAccepted": [
        29,
        49,
        0,
        48,
        42,
        0,
        25,
        33,
        48,
        13,
        0,
        5,
        36,
        38,
        49,
        17,
        22,
        5,
        0,
        22,
        42,
        46,
        49,
        44,
        20,
        37,
        33,
        49,
        15,
        49,
        0,
        15,
        43,
        25,
        42,
        43,
        46,
        9,
        17,
        33,
        49,
        0,
        48,
        32,
        29,
        21,
        16,
        49,
        25,
        28,
        9,
        6
      ],
      "weeklyRejected": [
        33,
        6,
        49,
        31,
        12,
        39,
        44,
        45,
        49,
        9,
        41,
        0,
        49,
        47,
        35,
        23,
        26,
        49,
        22,
        40,
        49,
        49,
        23,
        38,
        1,
        49,
        45,
        17,
        48,
        38,
        37,
        21,
        40,
        49,
        0,
        32,
        38,
        44,
        15,
        41,
        16,
        49,
        43,
        13,
        49,
        31,
        8,
        22,
        0,
        49,
        0,
        42
      ],
      "weeklyHold": [
        49,
        35,
        0,
        1,
        0,
        29,
        25,
        2,
        0,
        16,
        46,
        49,
        1,
        30,
        49,
        0,
        4,
        24,
        27,
        19,
        12,
        4,
        0,
        0,
        10,
        31,
        0,
        0,
        49,
        27,
        9,
        0,
        0,
        23,
        0,
        6,
        47,
        10,
        1,
        29,
        49,
        25,
        15,
        40,
        31,
        0,
        32,
        49,
        18,
        1,
        32,
        21
      ],
      "weeklyBackordered": [
        36,
        30,
        42,
        44,
        38,
        40,
        32,
        39,
        42,
        9,
        9,
        49,
        5,
        25,
        45,
        14,
        36,
        0,
        0,
        18,
        35,
        0,
        12,
        21,
        1,
        33,
        37,
        24,
        49,
        43,
        0,
        12,
        31,
        49,
        0,
        42,
        8,
        0,
        14,
        45,
        45,
        31,
        0,
        0,
        17,
        26,
        42,
        17,
        49,
        49,
        35,
        1
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        22,
        49,
        22,
        0,
        14,
        30,
        1,
        25,
        26,
        32,
        0,
        0,
        49,
        44,
        7,
        42,
        49,
        0,
        31,
        49,
        15,
        49,
        35,
        0,
        21,
        35,
        19,
        24,
        34,
        35,
        0,
        32,
        41,
        25,
        15,
        45,
        28,
        20,
        49,
        28,
        11,
        9,
        20,
        49,
        21,
        14,
        26,
        19,
        12,
        44,
        2,
        25
      ],
      "weeklyRejected": [
        49,
        45,
        19,
        8,
        16,
        13,
        49,
        0,
        45,
        43,
        27,
        11,
        0,
        48,
        46,
        0,
        0,
        49,
        49,
        8,
        8,
        41,
        22,
        0,
        27,
        49,
        15,
        12,
        34,
        31,
        0,
        5,
        0,
        12,
        0,
        49,
        49,
        21,
        1,
        0,
        42,
        33,
        37,
        30,
        44,
        37,
        9,
        18,
        0,
        42,
        17,
        49
      ],
      "weeklyHold": [
        40,
        9,
        34,
        16,
        8,
        7,
        35,
        29,
        33,
        5,
        43,
        6,
        8,
        3,
        34,
        36,
        14,
        0,
        0,
        49,
        49,
        20,
        33,
        45,
        0,
        1,
        15,
        25,
        28,
        37,
        48,
        17,
        22,
        47,
        0,
        33,
        15,
        32,
        15,
        49,
        24,
        49,
        0,
        43,
        43,
        6,
        42,
        49,
        6,
        39,
        45,
        49
      ],
      "weeklyBackordered": [
        42,
        0,
        23,
        21,
        49,
        35,
        24,
        1,
        0,
        13,
        49,
        46,
        3,
        27,
        43,
        12,
        49,
        42,
        37,
        20,
        45,
        38,
        23,
        5,
        23,
        42,
        20,
        46,
        2,
        34,
        36,
        36,
        18,
        44,
        19,
        14,
        15,
        0,
        49,
        18,
        46,
        31,
        0,
        49,
        13,
        24,
        47,
        46,
        15,
        0,
        1,
        24
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        19,
        36,
        0,
        16,
        40,
        24,
        12,
        38,
        48,
        46,
        43,
        15,
        49,
        20,
        32,
        6,
        49,
        8,
        33,
        38,
        0,
        42,
        37,
        0,
        31,
        23,
        20,
        22,
        16,
        48,
        7,
        47,
        20,
        22,
        48,
        30,
        35,
        19,
        49,
        38,
        0,
        0,
        49,
        49,
        49,
        8,
        29,
        7,
        0,
        0,
        49,
        16
      ],
      "weeklyRejected": [
        19,
        10,
        14,
        13,
        27,
        6,
        49,
        34,
        22,
        11,
        7,
        0,
        27,
        41,
        2,
        26,
        24,
        49,
        10,
        13,
        7,
        28,
        49,
        49,
        31,
        49,
        19,
        49,
        44,
        31,
        22,
        0,
        48,
        44,
        0,
        34,
        25,
        40,
        29,
        29,
        18,
        31,
        6,
        0,
        49,
        27,
        1,
        24,
        34,
        12,
        15,
        27
      ],
      "weeklyHold": [
        0,
        24,
        49,
        17,
        35,
        34,
        15,
        38,
        11,
        5,
        43,
        14,
        30,
        21,
        29,
        0,
        9,
        26,
        0,
        22,
        11,
        14,
        9,
        28,
        23,
        42,
        32,
        0,
        29,
        0,
        48,
        23,
        46,
        46,
        22,
        32,
        45,
        6,
        35,
        28,
        14,
        31,
        12,
        0,
        0,
        0,
        41,
        28,
        0,
        49,
        45,
        49
      ],
      "weeklyBackordered": [
        49,
        4,
        0,
        0,
        40,
        43,
        38,
        41,
        43,
        0,
        49,
        16,
        3,
        49,
        0,
        4,
        30,
        49,
        0,
        49,
        38,
        13,
        0,
        38,
        26,
        19,
        4,
        37,
        12,
        49,
        26,
        33,
        17,
        22,
        9,
        0,
        21,
        0,
        49,
        49,
        24,
        48,
        49,
        47,
        0,
        48,
        4,
        49,
        0,
        49,
        43,
        24
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyAccepted": [
        8,
        49,
        0,
        24,
        1,
        23,
        6,
        12,
        42,
        32,
        49,
        33,
        0,
        9,
        30,
        12,
        23,
        1,
        0,
        41,
        9,
        40,
        49,
        34,
        10,
        36,
        17,
        22,
        32,
        49,
        38,
        0,
        49,
        28,
        37,
        32,
        40,
        22,
        24,
        45,
        0,
        49,
        49,
        49,
        49,
        0,
        0,
        28,
        0,
        48,
        4,
        17
      ],
      "weeklyRejected": [
        44,
        49,
        13,
        12,
        35,
        0,
        9,
        13,
        37,
        18,
        16,
        11,
        0,
        48,
        2,
        28,
        7,
        42,
        33,
        5,
        0,
        24,
        17,
        2,
        39,
        49,
        43,
        49,
        46,
        10,
        35,
        47,
        48,
        22,
        13,
        25,
        40,
        49,
        15,
        0,
        33,
        46,
        37,
        17,
        33,
        6,
        0,
        30,
        7,
        49,
        0,
        49
      ],
      "weeklyHold": [
        24,
        0,
        45,
        1,
        0,
        41,
        15,
        4,
        16,
        0,
        22,
        49,
        20,
        43,
        40,
        0,
        0,
        13,
        0,
        0,
        17,
        0,
        24,
        4,
        0,
        1,
        40,
        39,
        42,
        0,
        48,
        35,
        22,
        15,
        8,
        24,
        45,
        46,
        49,
        10,
        26,
        25,
        5,
        34,
        27,
        19,
        23,
        31,
        14,
        35,
        49,
        44
      ],
      "weeklyBackordered": [
        41,
        0,
        3,
        0,
        39,
        30,
        0,
        21,
        20,
        12,
        49,
        15,
        33,
        39,
        14,
        19,
        9,
        28,
        0,
        4,
        36,
        0,
        0,
        0,
        12,
        1,
        38,
        19,
        33,
        34,
        44,
        0,
        48,
        42,
        0,
        0,
        19,
        33,
        49,
        0,
        2,
        29,
        17,
        49,
        2,
        19,
        15,
        32,
        19,
        27,
        1,
        40
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        0,
        35,
        4,
        48,
        31,
        13,
        1,
        49,
        33,
        49,
        7,
        0,
        15,
        2,
        8,
        15,
        42,
        47,
        0,
        14,
        44,
        28,
        36,
        16,
        0,
        44,
        34,
        15,
        1,
        31,
        36,
        2,
        49,
        7,
        48,
        47,
        41,
        48,
        36,
        49,
        12,
        37,
        45,
        45,
        49,
        30,
        9,
        42,
        19,
        49,
        13,
        21
      ],
      "weeklyRejected": [
        45,
        40,
        49,
        30,
        49,
        40,
        19,
        6,
        49,
        48,
        29,
        36,
        0,
        48,
        39,
        49,
        0,
        25,
        35,
        0,
        30,
        18,
        12,
        49,
        12,
        49,
        21,
        17,
        29,
        2,
        36,
        32,
        48,
        49,
        15,
        49,
        47,
        35,
        1,
        33,
        25,
        21,
        32,
        24,
        17,
        43,
        0,
        18,
        0,
        43,
        0,
        29
      ],
      "weeklyHold": [
        25,
        0,
        26,
        1,
        37,
        48,
        23,
        36,
        25,
        33,
        0,
        49,
        48,
        29,
        21,
        0,
        13,
        27,
        22,
        49,
        24,
        29,
        18,
        46,
        21,
        37,
        49,
        22,
        33,
        24,
        27,
        19,
        12,
        23,
        30,
        23,
        25,
        42,
        32,
        46,
        6,
        13,
        0,
        0,
        37,
        29,
        48,
        34,
        4,
        20,
        26,
        0
      ],
      "weeklyBackordered": [
        49,
        0,
        48,
        16,
        49,
        31,
        4,
        43,
        49,
        21,
        30,
        3,
        21,
        0,
        49,
        26,
        49,
        49,
        0,
        30,
        49,
        49,
        10,
        9,
        18,
        15,
        27,
        49,
        24,
        35,
        7,
        45,
        33,
        49,
        0,
        0,
        36,
        11,
        9,
        3,
        6,
        22,
        0,
        49,
        36,
        49,
        25,
        42,
        9,
        29,
        36,
        29
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        19,
        38,
        23,
        48,
        26,
        0,
        7,
        39,
        48,
        35,
        33,
        0,
        16,
        49,
        21,
        4,
        24,
        0,
        0,
        49,
        8,
        24,
        36,
        32,
        0,
        45,
        6,
        21,
        14,
        49,
        49,
        0,
        49,
        0,
        18,
        26,
        36,
        23,
        45,
        29,
        24,
        29,
        42,
        17,
        35,
        25,
        15,
        5,
        0,
        21,
        25,
        0
      ],
      "weeklyRejected": [
        49,
        30,
        19,
        47,
        45,
        28,
        49,
        36,
        49,
        22,
        27,
        0,
        29,
        48,
        48,
        9,
        0,
        41,
        22,
        8,
        34,
        6,
        17,
        23,
        29,
        49,
        18,
        30,
        48,
        10,
        18,
        37,
        17,
        34,
        1,
        41,
        28,
        0,
        13,
        30,
        25,
        27,
        47,
        10,
        39,
        2,
        0,
        38,
        0,
        49,
        0,
        47
      ],
      "weeklyHold": [
        30,
        18,
        49,
        16,
        0,
        25,
        32,
        26,
        32,
        34,
        39,
        49,
        46,
        32,
        31,
        49,
        12,
        48,
        7,
        0,
        38,
        17,
        17,
        0,
        32,
        19,
        16,
        18,
        47,
        17,
        34,
        16,
        0,
        49,
        0,
        34,
        38,
        12,
        28,
        49,
        7,
        48,
        0,
        25,
        37,
        28,
        49,
        42,
        0,
        11,
        49,
        17
      ],
      "weeklyBackordered": [
        34,
        37,
        2,
        29,
        43,
        44,
        26,
        39,
        40,
        23,
        34,
        49,
        10,
        20,
        39,
        1,
        20,
        45,
        0,
        18,
        22,
        26,
        2,
        0,
        14,
        32,
        1,
        33,
        49,
        49,
        31,
        36,
        0,
        45,
        33,
        0,
        38,
        0,
        33,
        6,
        1,
        48,
        8,
        49,
        10,
        42,
        49,
        7,
        0,
        43,
        30,
        19
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        27,
        49,
        21,
        17,
        49,
        47,
        5,
        46,
        27,
        12,
        41,
        21,
        0,
        19,
        1,
        0,
        46,
        21,
        28,
        43,
        0,
        1,
        23,
        0,
        0,
        45,
        15,
        49,
        10,
        19,
        31,
        2,
        45,
        15,
        48,
        38,
        23,
        18,
        49,
        49,
        49,
        2,
        49,
        30,
        41,
        7,
        14,
        0,
        0,
        26,
        49,
        0
      ],
      "weeklyRejected": [
        42,
        17,
        19,
        35,
        40,
        0,
        49,
        2,
        47,
        36,
        24,
        24,
        34,
        18,
        30,
        0,
        0,
        49,
        18,
        5,
        15,
        0,
        35,
        20,
        26,
        49,
        49,
        40,
        48,
        20,
        26,
        24,
        44,
        49,
        0,
        49,
        49,
        43,
        1,
        17,
        48,
        6,
        39,
        12,
        49,
        11,
        4,
        38,
        0,
        11,
        0,
        33
      ],
      "weeklyHold": [
        24,
        14,
        8,
        1,
        0,
        29,
        31,
        18,
        12,
        21,
        8,
        2,
        15,
        33,
        4,
        3,
        15,
        42,
        13,
        0,
        0,
        19,
        5,
        7,
        32,
        36,
        24,
        1,
        49,
        0,
        41,
        25,
        29,
        13,
        0,
        23,
        21,
        8,
        49,
        9,
        14,
        49,
        4,
        31,
        22,
        49,
        46,
        20,
        6,
        0,
        40,
        2
      ],
      "weeklyBackordered": [
        22,
        22,
        17,
        39,
        49,
        30,
        44,
        49,
        34,
        12,
        36,
        43,
        6,
        38,
        26,
        49,
        49,
        37,
        0,
        49,
        31,
        13,
        14,
        0,
        42,
        17,
        36,
        26,
        11,
        49,
        0,
        49,
        47,
        49,
        4,
        0,
        2,
        0,
        46,
        11,
        0,
        48,
        11,
        49,
        42,
        18,
        48,
        0,
        26,
        0,
        49,
        11
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        7,
        41,
        18,
        40,
        40,
        7,
        42,
        28,
        39,
        18,
        39,
        1,
        0,
        10,
        20,
        49,
        49,
        48,
        4,
        49,
        46,
        37,
        20,
        34,
        23,
        47,
        18,
        30,
        24,
        37,
        42,
        19,
        36,
        19,
        18,
        34,
        47,
        20,
        46,
        48,
        13,
        0,
        9,
        49,
        28,
        43,
        14,
        9,
        0,
        8,
        15,
        48
      ],
      "weeklyRejected": [
        49,
        0,
        47,
        9,
        49,
        24,
        24,
        26,
        49,
        1,
        37,
        0,
        30,
        29,
        27,
        23,
        0,
        30,
        37,
        28,
        49,
        29,
        6,
        4,
        35,
        41,
        43,
        0,
        45,
        9,
        1,
        29,
        42,
        18,
        49,
        38,
        35,
        46,
        21,
        25,
        34,
        39,
        29,
        0,
        15,
        13,
        19,
        18,
        41,
        49,
        3,
        44
      ],
      "weeklyHold": [
        49,
        17,
        49,
        1,
        35,
        0,
        49,
        29,
        28,
        29,
        34,
        28,
        32,
        46,
        4,
        19,
        26,
        25,
        29,
        37,
        11,
        23,
        0,
        32,
        3,
        44,
        28,
        25,
        49,
        3,
        48,
        14,
        4,
        32,
        46,
        8,
        16,
        45,
        35,
        49,
        1,
        49,
        42,
        37,
        19,
        24,
        19,
        31,
        7,
        15,
        30,
        0
      ],
      "weeklyBackordered": [
        27,
        44,
        41,
        4,
        49,
        6,
        49,
        49,
        12,
        0,
        6,
        28,
        8,
        18,
        27,
        19,
        23,
        39,
        29,
        42,
        0,
        49,
        9,
        17,
        15,
        20,
        49,
        1,
        1,
        49,
        20,
        20,
        26,
        48,
        0,
        0,
        1,
        13,
        0,
        34,
        15,
        14,
        49,
        49,
        35,
        44,
        49,
        9,
        4,
        25,
        35,
        39
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        21,
        47,
        24,
        12,
        49,
        0,
        49,
        40,
        39,
        12,
        49,
        23,
        8,
        12,
        1,
        49,
        23,
        6,
        1,
        22,
        37,
        37,
        49,
        44,
        20,
        29,
        0,
        34,
        21,
        37,
        24,
        30,
        49,
        49,
        41,
        36,
        18,
        48,
        0,
        49,
        6,
        15,
        49,
        0,
        49,
        49,
        19,
        20,
        15,
        39,
        0,
        15
      ],
      "weeklyRejected": [
        30,
        0,
        35,
        38,
        43,
        28,
        28,
        49,
        0,
        27,
        49,
        27,
        18,
        48,
        18,
        3,
        23,
        49,
        35,
        7,
        10,
        7,
        44,
        21,
        41,
        41,
        49,
        41,
        38,
        24,
        13,
        39,
        25,
        41,
        1,
        44,
        35,
        37,
        1,
        5,
        39,
        49,
        28,
        0,
        31,
        44,
        26,
        8,
        11,
        49,
        0,
        40
      ],
      "weeklyHold": [
        46,
        11,
        32,
        1,
        13,
        30,
        43,
        16,
        17,
        0,
        43,
        27,
        1,
        15,
        28,
        39,
        0,
        9,
        28,
        4,
        32,
        47,
        13,
        41,
        48,
        8,
        16,
        25,
        34,
        19,
        45,
        3,
        0,
        32,
        0,
        35,
        37,
        0,
        49,
        49,
        28,
        38,
        12,
        38,
        38,
        49,
        12,
        44,
        27,
        49,
        12,
        38
      ],
      "weeklyBackordered": [
        37,
        8,
        24,
        14,
        49,
        40,
        45,
        45,
        26,
        16,
        3,
        49,
        3,
        34,
        46,
        10,
        15,
        35,
        24,
        14,
        41,
        13,
        49,
        10,
        48,
        9,
        46,
        23,
        7,
        49,
        0,
        21,
        39,
        43,
        20,
        0,
        12,
        0,
        36,
        8,
        48,
        48,
        5,
        49,
        0,
        0,
        49,
        0,
        7,
        49,
        27,
        17
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        0,
        25,
        49,
        34,
        24,
        31,
        1,
        17,
        28,
        49,
        43,
        3,
        28,
        42,
        1,
        9,
        21,
        0,
        7,
        46,
        14,
        36,
        24,
        41,
        0,
        41,
        25,
        9,
        10,
        26,
        8,
        10,
        49,
        44,
        48,
        49,
        43,
        1,
        49,
        30,
        0,
        38,
        49,
        17,
        32,
        0,
        25,
        43,
        11,
        37,
        31,
        13
      ],
      "weeklyRejected": [
        49,
        0,
        12,
        47,
        20,
        0,
        35,
        49,
        14,
        38,
        32,
        25,
        26,
        0,
        20,
        49,
        2,
        36,
        24,
        6,
        0,
        38,
        46,
        32,
        30,
        49,
        30,
        23,
        36,
        10,
        49,
        8,
        35,
        49,
        0,
        43,
        38,
        0,
        1,
        49,
        44,
        49,
        22,
        15,
        24,
        35,
        27,
        26,
        11,
        25,
        34,
        24
      ],
      "weeklyHold": [
        0,
        0,
        49,
        1,
        1,
        34,
        39,
        49,
        0,
        11,
        1,
        46,
        29,
        22,
        16,
        21,
        11,
        35,
        30,
        22,
        1,
        16,
        41,
        35,
        13,
        31,
        5,
        1,
        44,
        14,
        35,
        21,
        6,
        0,
        6,
        27,
        0,
        0,
        37,
        20,
        9,
        30,
        31,
        38,
        37,
        39,
        10,
        13,
        0,
        11,
        40,
        38
      ],
      "weeklyBackordered": [
        14,
        2,
        40,
        28,
        23,
        36,
        43,
        49,
        43,
        3,
        49,
        18,
        47,
        24,
        1,
        23,
        49,
        43,
        39,
        0,
        43,
        6,
        15,
        1,
        31,
        1,
        2,
        31,
        35,
        22,
        41,
        0,
        30,
        49,
        0,
        20,
        26,
        30,
        29,
        40,
        0,
        1,
        0,
        30,
        33,
        25,
        4,
        35,
        4,
        47,
        1,
        24
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        20,
        24,
        12,
        37,
        34,
        8,
        49,
        49,
        48,
        47,
        0,
        3,
        20,
        8,
        6,
        9,
        41,
        21,
        0,
        17,
        0,
        20,
        39,
        28,
        19,
        27,
        13,
        15,
        41,
        37,
        49,
        30,
        45,
        40,
        8,
        16,
        2,
        29,
        49,
        49,
        49,
        43,
        15,
        49,
        49,
        27,
        11,
        32,
        49,
        49,
        19,
        48
      ],
      "weeklyRejected": [
        16,
        22,
        29,
        8,
        36,
        26,
        49,
        49,
        12,
        28,
        49,
        9,
        24,
        25,
        22,
        3,
        37,
        49,
        35,
        12,
        49,
        49,
        0,
        13,
        0,
        49,
        41,
        17,
        9,
        38,
        32,
        27,
        41,
        24,
        36,
        46,
        38,
        19,
        1,
        0,
        48,
        49,
        40,
        17,
        49,
        11,
        49,
        26,
        8,
        46,
        0,
        21
      ],
      "weeklyHold": [
        16,
        35,
        39,
        16,
        23,
        0,
        19,
        47,
        7,
        42,
        47,
        19,
        1,
        27,
        25,
        47,
        0,
        30,
        43,
        27,
        31,
        49,
        13,
        24,
        31,
        45,
        3,
        23,
        39,
        49,
        48,
        21,
        0,
        33,
        13,
        0,
        0,
        0,
        36,
        44,
        12,
        19,
        37,
        24,
        33,
        49,
        25,
        38,
        10,
        30,
        9,
        14
      ],
      "weeklyBackordered": [
        0,
        43,
        4,
        0,
        17,
        13,
        3,
        41,
        28,
        3,
        41,
        31,
        29,
        33,
        41,
        23,
        7,
        26,
        0,
        14,
        36,
        40,
        38,
        49,
        2,
        25,
        40,
        6,
        22,
        28,
        15,
        48,
        19,
        40,
        0,
        13,
        13,
        6,
        10,
        20,
        37,
        42,
        0,
        49,
        0,
        0,
        49,
        33,
        49,
        46,
        12,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        21,
        49,
        35,
        21,
        8,
        0,
        35,
        24,
        48,
        49,
        49,
        26,
        28,
        31,
        24,
        24,
        48,
        0,
        4,
        30,
        36,
        49,
        5,
        49,
        17,
        16,
        49,
        49,
        36,
        1,
        40,
        3,
        49,
        23,
        6,
        44,
        47,
        48,
        33,
        20,
        0,
        36,
        25,
        8,
        27,
        4,
        21,
        2,
        49,
        5,
        0,
        44
      ],
      "weeklyRejected": [
        49,
        18,
        49,
        26,
        20,
        44,
        16,
        36,
        0,
        13,
        23,
        37,
        49,
        26,
        11,
        38,
        23,
        36,
        0,
        34,
        16,
        28,
        32,
        49,
        41,
        25,
        40,
        42,
        40,
        0,
        49,
        35,
        45,
        14,
        40,
        49,
        11,
        0,
        1,
        49,
        11,
        47,
        36,
        49,
        49,
        49,
        0,
        0,
        16,
        45,
        0,
        35
      ],
      "weeklyHold": [
        49,
        41,
        5,
        14,
        45,
        34,
        48,
        21,
        0,
        21,
        16,
        49,
        41,
        46,
        49,
        26,
        0,
        24,
        45,
        18,
        5,
        49,
        39,
        0,
        7,
        26,
        38,
        40,
        29,
        6,
        8,
        10,
        0,
        49,
        17,
        33,
        13,
        30,
        1,
        29,
        16,
        38,
        35,
        23,
        49,
        0,
        27,
        0,
        44,
        30,
        29,
        15
      ],
      "weeklyBackordered": [
        47,
        16,
        0,
        31,
        46,
        23,
        49,
        38,
        0,
        6,
        43,
        20,
        11,
        43,
        39,
        31,
        1,
        38,
        8,
        11,
        16,
        27,
        28,
        0,
        24,
        1,
        18,
        37,
        21,
        33,
        41,
        49,
        48,
        44,
        49,
        24,
        29,
        8,
        39,
        8,
        9,
        48,
        38,
        49,
        43,
        0,
        49,
        9,
        38,
        49,
        21,
        49
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        0,
        32,
        0,
        16,
        18,
        11,
        20,
        41,
        40,
        44,
        31,
        4,
        14,
        22,
        1,
        19,
        44,
        0,
        23,
        12,
        17,
        28,
        0,
        9,
        21,
        9,
        39,
        7,
        1,
        31,
        36,
        12,
        2,
        0,
        38,
        49,
        28,
        48,
        17,
        49,
        18,
        10,
        46,
        37,
        49,
        46,
        2,
        17,
        3,
        20,
        49,
        48
      ],
      "weeklyRejected": [
        24,
        13,
        39,
        28,
        45,
        45,
        0,
        20,
        13,
        47,
        0,
        46,
        26,
        23,
        24,
        24,
        42,
        49,
        0,
        17,
        0,
        15,
        49,
        49,
        23,
        49,
        49,
        18,
        32,
        3,
        40,
        15,
        48,
        19,
        15,
        49,
        41,
        42,
        11,
        49,
        0,
        28,
        0,
        3,
        49,
        31,
        13,
        42,
        0,
        28,
        0,
        31
      ],
      "weeklyHold": [
        5,
        49,
        38,
        6,
        14,
        48,
        33,
        19,
        23,
        36,
        27,
        15,
        48,
        17,
        24,
        5,
        0,
        29,
        49,
        32,
        11,
        12,
        28,
        0,
        30,
        45,
        7,
        24,
        48,
        25,
        25,
        0,
        40,
        0,
        8,
        20,
        37,
        0,
        30,
        31,
        19,
        26,
        6,
        38,
        11,
        0,
        43,
        21,
        0,
        31,
        32,
        44
      ],
      "weeklyBackordered": [
        31,
        3,
        29,
        26,
        42,
        40,
        49,
        1,
        37,
        0,
        38,
        33,
        14,
        49,
        0,
        34,
        48,
        49,
        0,
        46,
        49,
        0,
        4,
        20,
        24,
        33,
        1,
        43,
        38,
        49,
        10,
        49,
        48,
        32,
        0,
        0,
        21,
        3,
        10,
        40,
        10,
        18,
        37,
        46,
        48,
        27,
        21,
        30,
        20,
        49,
        28,
        5
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        16,
        33,
        6,
        18,
        1,
        25,
        1,
        20,
        19,
        36,
        38,
        16,
        49,
        24,
        1,
        45,
        49,
        7,
        0,
        47,
        0,
        45,
        32,
        33,
        11,
        35,
        31,
        18,
        49,
        48,
        22,
        6,
        41,
        0,
        48,
        45,
        7,
        8,
        49,
        17,
        1,
        17,
        49,
        35,
        49,
        11,
        7,
        49,
        49,
        20,
        49,
        0
      ],
      "weeklyRejected": [
        24,
        0,
        49,
        17,
        37,
        0,
        4,
        30,
        49,
        32,
        49,
        22,
        18,
        31,
        22,
        29,
        5,
        49,
        7,
        16,
        0,
        49,
        1,
        35,
        17,
        49,
        49,
        28,
        33,
        46,
        25,
        0,
        48,
        49,
        19,
        49,
        49,
        33,
        14,
        0,
        13,
        49,
        24,
        13,
        43,
        0,
        40,
        45,
        16,
        36,
        0,
        12
      ],
      "weeklyHold": [
        5,
        10,
        42,
        3,
        7,
        46,
        48,
        8,
        23,
        24,
        29,
        11,
        21,
        29,
        38,
        0,
        18,
        19,
        8,
        9,
        6,
        49,
        7,
        7,
        32,
        1,
        17,
        16,
        49,
        26,
        43,
        8,
        31,
        44,
        18,
        0,
        27,
        0,
        37,
        40,
        49,
        15,
        26,
        7,
        19,
        19,
        0,
        23,
        6,
        49,
        20,
        49
      ],
      "weeklyBackordered": [
        23,
        16,
        0,
        28,
        35,
        37,
        41,
        20,
        21,
        34,
        49,
        25,
        20,
        49,
        12,
        47,
        41,
        25,
        0,
        23,
        41,
        0,
        14,
        0,
        23,
        13,
        49,
        23,
        15,
        32,
        4,
        20,
        48,
        22,
        26,
        12,
        21,
        24,
        26,
        36,
        26,
        42,
        6,
        37,
        13,
        6,
        42,
        49,
        8,
        34,
        49,
        6
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        36,
        49,
        0,
        48,
        30,
        0,
        26,
        40,
        43,
        14,
        49,
        6,
        0,
        3,
        14,
        39,
        41,
        5,
        11,
        13,
        42,
        11,
        17,
        0,
        14,
        31,
        23,
        49,
        9,
        45,
        13,
        24,
        46,
        21,
        48,
        39,
        23,
        48,
        21,
        30,
        25,
        48,
        49,
        10,
        49,
        0,
        36,
        22,
        49,
        49,
        36,
        48
      ],
      "weeklyRejected": [
        19,
        0,
        27,
        47,
        27,
        0,
        41,
        32,
        49,
        16,
        47,
        20,
        9,
        48,
        48,
        0,
        25,
        26,
        11,
        10,
        48,
        0,
        49,
        8,
        18,
        49,
        49,
        14,
        39,
        14,
        49,
        38,
        31,
        49,
        22,
        31,
        0,
        43,
        1,
        30,
        28,
        6,
        1,
        0,
        49,
        26,
        0,
        11,
        39,
        31,
        0,
        44
      ],
      "weeklyHold": [
        39,
        0,
        48,
        24,
        35,
        0,
        44,
        42,
        40,
        0,
        47,
        22,
        33,
        1,
        49,
        23,
        4,
        24,
        0,
        21,
        33,
        3,
        29,
        0,
        43,
        10,
        20,
        16,
        30,
        33,
        5,
        18,
        15,
        49,
        31,
        49,
        3,
        0,
        13,
        27,
        6,
        49,
        0,
        13,
        33,
        43,
        10,
        30,
        0,
        32,
        12,
        49
      ],
      "weeklyBackordered": [
        19,
        4,
        41,
        41,
        49,
        40,
        0,
        35,
        49,
        10,
        20,
        27,
        3,
        41,
        26,
        1,
        40,
        0,
        0,
        9,
        11,
        32,
        21,
        20,
        41,
        13,
        15,
        20,
        16,
        37,
        6,
        42,
        48,
        31,
        34,
        0,
        28,
        1,
        5,
        32,
        48,
        48,
        14,
        49,
        38,
        30,
        33,
        9,
        9,
        49,
        15,
        15
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyAccepted": [
        0,
        12,
        28,
        14,
        39,
        0,
        36,
        15,
        11,
        0,
        49,
        1,
        18,
        0,
        2,
        41,
        49,
        5,
        6,
        32,
        0,
        24,
        9,
        38,
        40,
        10,
        0,
        0,
        1,
        29,
        7,
        27,
        12,
        25,
        33,
        48,
        28,
        48,
        22,
        13,
        0,
        4,
        26,
        39,
        21,
        0,
        5,
        0,
        49,
        14,
        49,
        11
      ],
      "weeklyRejected": [
        49,
        28,
        2,
        15,
        38,
        42,
        10,
        49,
        27,
        3,
        23,
        0,
        33,
        48,
        34,
        12,
        41,
        49,
        21,
        7,
        22,
        0,
        0,
        37,
        7,
        49,
        40,
        11,
        46,
        41,
        37,
        20,
        48,
        17,
        49,
        49,
        31,
        17,
        5,
        46,
        46,
        33,
        0,
        1,
        27,
        20,
        3,
        15,
        13,
        49,
        9,
        38
      ],
      "weeklyHold": [
        49,
        49,
        49,
        34,
        10,
        25,
        0,
        22,
        16,
        29,
        38,
        5,
        19,
        1,
        49,
        17,
        26,
        34,
        32,
        24,
        35,
        12,
        49,
        0,
        25,
        31,
        26,
        26,
        16,
        13,
        48,
        10,
        24,
        17,
        26,
        23,
        40,
        0,
        37,
        49,
        21,
        16,
        0,
        0,
        36,
        49,
        19,
        11,
        31,
        36,
        49,
        10
      ],
      "weeklyBackordered": [
        0,
        49,
        21,
        0,
        49,
        46,
        43,
        32,
        25,
        9,
        0,
        27,
        32,
        19,
        43,
        34,
        49,
        25,
        0,
        5,
        14,
        28,
        24,
        0,
        17,
        10,
        36,
        49,
        49,
        49,
        3,
        21,
        48,
        49,
        19,
        0,
        29,
        25,
        9,
        30,
        48,
        48,
        39,
        39,
        0,
        0,
        34,
        0,
        35,
        49,
        14,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        17,
        23,
        23,
        42,
        32,
        13,
        22,
        42,
        47,
        21,
        49,
        8,
        49,
        34,
        27,
        21,
        16,
        32,
        0,
        20,
        40,
        37,
        29,
        38,
        30,
        24,
        0,
        17,
        21,
        34,
        1,
        34,
        49,
        15,
        48,
        2,
        47,
        2,
        40,
        49,
        14,
        42,
        49,
        43,
        49,
        43,
        19,
        24,
        10,
        13,
        0,
        27
      ],
      "weeklyRejected": [
        49,
        2,
        32,
        37,
        41,
        10,
        49,
        0,
        26,
        9,
        26,
        33,
        31,
        40,
        47,
        49,
        6,
        49,
        42,
        49,
        22,
        0,
        48,
        49,
        25,
        36,
        17,
        21,
        48,
        41,
        1,
        26,
        48,
        49,
        31,
        49,
        21,
        2,
        1,
        49,
        30,
        41,
        39,
        2,
        42,
        45,
        14,
        17,
        0,
        23,
        0,
        35
      ],
      "weeklyHold": [
        16,
        20,
        49,
        1,
        47,
        29,
        17,
        43,
        32,
        15,
        47,
        34,
        20,
        49,
        40,
        41,
        13,
        21,
        49,
        0,
        31,
        34,
        18,
        37,
        33,
        9,
        16,
        16,
        19,
        25,
        27,
        48,
        24,
        37,
        21,
        40,
        40,
        0,
        48,
        49,
        6,
        0,
        20,
        49,
        49,
        16,
        18,
        21,
        38,
        11,
        43,
        32
      ],
      "weeklyBackordered": [
        40,
        26,
        5,
        33,
        49,
        46,
        33,
        49,
        27,
        16,
        0,
        25,
        3,
        26,
        36,
        14,
        36,
        34,
        14,
        31,
        31,
        15,
        16,
        49,
        1,
        46,
        6,
        5,
        18,
        49,
        23,
        21,
        0,
        39,
        4,
        22,
        49,
        46,
        34,
        17,
        0,
        48,
        10,
        15,
        8,
        39,
        9,
        27,
        28,
        39,
        25,
        11
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        34,
        48,
        14,
        7,
        49,
        2,
        9,
        49,
        48,
        17,
        24,
        33,
        17,
        34,
        18,
        24,
        49,
        19,
        7,
        35,
        23,
        49,
        29,
        14,
        7,
        13,
        27,
        31,
        1,
        28,
        25,
        23,
        47,
        11,
        33,
        39,
        33,
        19,
        20,
        49,
        29,
        35,
        49,
        47,
        45,
        0,
        10,
        49,
        6,
        22,
        23,
        48
      ],
      "weeklyRejected": [
        37,
        4,
        35,
        47,
        17,
        16,
        49,
        32,
        49,
        25,
        32,
        27,
        39,
        48,
        16,
        42,
        0,
        47,
        49,
        10,
        2,
        49,
        26,
        47,
        40,
        49,
        34,
        36,
        28,
        0,
        38,
        30,
        48,
        44,
        16,
        36,
        18,
        39,
        49,
        4,
        3,
        14,
        42,
        18,
        49,
        41,
        19,
        29,
        2,
        28,
        0,
        49
      ],
      "weeklyHold": [
        0,
        16,
        24,
        1,
        33,
        38,
        46,
        34,
        28,
        17,
        47,
        11,
        17,
        36,
        23,
        12,
        28,
        0,
        27,
        49,
        18,
        15,
        32,
        13,
        10,
        40,
        6,
        19,
        49,
        0,
        33,
        0,
        0,
        41,
        34,
        45,
        12,
        46,
        18,
        49,
        0,
        49,
        0,
        37,
        37,
        2,
        49,
        46,
        5,
        11,
        41,
        27
      ],
      "weeklyBackordered": [
        45,
        17,
        10,
        27,
        40,
        46,
        49,
        1,
        36,
        10,
        37,
        27,
        3,
        23,
        39,
        8,
        24,
        49,
        12,
        42,
        43,
        15,
        0,
        49,
        1,
        29,
        9,
        20,
        18,
        22,
        17,
        28,
        41,
        41,
        0,
        0,
        1,
        17,
        26,
        4,
        23,
        19,
        20,
        48,
        3,
        31,
        49,
        0,
        33,
        23,
        4,
        49
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        22,
        34,
        13,
        0,
        1,
        2,
        24,
        22,
        25,
        28,
        44,
        11,
        25,
        6,
        30,
        47,
        42,
        22,
        13,
        43,
        16,
        39,
        45,
        17,
        28,
        25,
        24,
        0,
        25,
        49,
        16,
        15,
        47,
        25,
        31,
        2,
        14,
        29,
        19,
        49,
        18,
        26,
        49,
        24,
        16,
        13,
        11,
        12,
        34,
        49,
        12,
        24
      ],
      "weeklyRejected": [
        38,
        30,
        27,
        4,
        33,
        49,
        9,
        49,
        37,
        22,
        49,
        8,
        0,
        48,
        27,
        7,
        16,
        47,
        48,
        26,
        34,
        27,
        45,
        49,
        21,
        24,
        49,
        37,
        9,
        48,
        43,
        31,
        45,
        31,
        4,
        49,
        46,
        49,
        16,
        32,
        48,
        8,
        28,
        0,
        48,
        41,
        25,
        48,
        17,
        21,
        0,
        31
      ],
      "weeklyHold": [
        0,
        36,
        28,
        17,
        37,
        36,
        31,
        9,
        7,
        24,
        13,
        13,
        33,
        49,
        41,
        48,
        22,
        20,
        3,
        49,
        13,
        0,
        5,
        33,
        39,
        14,
        49,
        17,
        32,
        6,
        20,
        10,
        30,
        48,
        47,
        41,
        33,
        0,
        27,
        19,
        25,
        11,
        9,
        9,
        39,
        25,
        0,
        48,
        0,
        49,
        14,
        19
      ],
      "weeklyBackordered": [
        21,
        33,
        28,
        37,
        49,
        46,
        13,
        1,
        49,
        4,
        33,
        24,
        14,
        49,
        10,
        28,
        49,
        17,
        0,
        48,
        36,
        24,
        19,
        49,
        1,
        4,
        23,
        0,
        1,
        40,
        22,
        42,
        37,
        30,
        0,
        26,
        31,
        0,
        49,
        29,
        33,
        48,
        22,
        49,
        49,
        18,
        33,
        17,
        36,
        38,
        27,
        49
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        17,
        49,
        22,
        0,
        49,
        13,
        1,
        49,
        7,
        30,
        41,
        0,
        32,
        15,
        13,
        21,
        29,
        0,
        41,
        15,
        30,
        40,
        29,
        24,
        25,
        0,
        2,
        49,
        49,
        26,
        26,
        6,
        49,
        36,
        30,
        46,
        17,
        11,
        34,
        49,
        0,
        24,
        5,
        0,
        36,
        49,
        7,
        0,
        4,
        33,
        25,
        40
      ],
      "weeklyRejected": [
        49,
        35,
        10,
        47,
        23,
        0,
        0,
        49,
        48,
        41,
        46,
        25,
        18,
        48,
        24,
        18,
        29,
        36,
        27,
        13,
        5,
        0,
        7,
        18,
        19,
        49,
        11,
        45,
        38,
        47,
        31,
        34,
        21,
        13,
        21,
        49,
        47,
        38,
        1,
        0,
        12,
        47,
        25,
        9,
        45,
        11,
        10,
        12,
        12,
        40,
        0,
        38
      ],
      "weeklyHold": [
        49,
        0,
        37,
        36,
        23,
        21,
        38,
        18,
        16,
        28,
        8,
        31,
        36,
        25,
        37,
        0,
        21,
        37,
        15,
        44,
        23,
        37,
        0,
        7,
        12,
        20,
        41,
        10,
        37,
        11,
        39,
        4,
        0,
        38,
        0,
        12,
        26,
        46,
        25,
        33,
        29,
        30,
        27,
        39,
        44,
        21,
        10,
        21,
        0,
        49,
        0,
        22
      ],
      "weeklyBackordered": [
        25,
        22,
        42,
        6,
        37,
        19,
        21,
        35,
        43,
        0,
        26,
        49,
        29,
        44,
        23,
        42,
        13,
        31,
        0,
        8,
        12,
        15,
        0,
        8,
        36,
        5,
        49,
        21,
        49,
        49,
        29,
        10,
        48,
        18,
        22,
        0,
        26,
        18,
        0,
        41,
        14,
        48,
        3,
        39,
        22,
        43,
        48,
        2,
        8,
        49,
        1,
        1
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        49,
        6,
        49,
        48,
        49,
        25,
        1,
        49,
        48,
        34,
        40,
        0,
        5,
        46,
        47,
        10,
        49,
        0,
        32,
        16,
        19,
        24,
        25,
        35,
        23,
        14,
        0,
        17,
        45,
        49,
        12,
        39,
        28,
        26,
        8,
        32,
        47,
        42,
        32,
        49,
        34,
        0,
        45,
        0,
        49,
        0,
        0,
        40,
        19,
        37,
        38,
        48
      ],
      "weeklyRejected": [
        29,
        42,
        49,
        44,
        35,
        37,
        45,
        8,
        47,
        38,
        25,
        18,
        0,
        30,
        33,
        19,
        8,
        48,
        39,
        8,
        6,
        21,
        9,
        30,
        0,
        49,
        0,
        49,
        33,
        0,
        49,
        47,
        45,
        20,
        41,
        49,
        20,
        17,
        10,
        49,
        29,
        0,
        33,
        7,
        40,
        11,
        18,
        35,
        28,
        39,
        30,
        43
      ],
      "weeklyHold": [
        23,
        38,
        49,
        1,
        19,
        46,
        45,
        49,
        42,
        25,
        22,
        31,
        22,
        30,
        32,
        0,
        12,
        28,
        43,
        38,
        0,
        33,
        0,
        29,
        49,
        39,
        0,
        4,
        29,
        49,
        35,
        23,
        7,
        49,
        17,
        49,
        23,
        8,
        49,
        38,
        40,
        39,
        27,
        40,
        0,
        28,
        27,
        49,
        0,
        28,
        48,
        2
      ],
      "weeklyBackordered": [
        29,
        38,
        0,
        36,
        36,
        38,
        26,
        17,
        11,
        5,
        0,
        0,
        20,
        49,
        0,
        9,
        27,
        41,
        38,
        32,
        20,
        21,
        0,
        0,
        10,
        34,
        1,
        0,
        35,
        49,
        24,
        42,
        0,
        14,
        6,
        0,
        41,
        9,
        0,
        49,
        25,
        48,
        49,
        49,
        36,
        49,
        30,
        0,
        19,
        18,
        23,
        33
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        34,
        20,
        29,
        44,
        49,
        10,
        5,
        26,
        41,
        39,
        26,
        0,
        49,
        32,
        10,
        14,
        37,
        32,
        5,
        22,
        21,
        31,
        33,
        49,
        0,
        0,
        3,
        12,
        34,
        30,
        21,
        2,
        28,
        49,
        48,
        49,
        47,
        46,
        36,
        49,
        30,
        29,
        18,
        37,
        20,
        6,
        0,
        42,
        4,
        49,
        0,
        29
      ],
      "weeklyRejected": [
        35,
        10,
        49,
        47,
        28,
        24,
        22,
        25,
        27,
        31,
        49,
        24,
        30,
        37,
        23,
        20,
        13,
        42,
        4,
        15,
        49,
        20,
        39,
        49,
        42,
        49,
        1,
        46,
        18,
        14,
        0,
        31,
        48,
        36,
        20,
        49,
        12,
        49,
        11,
        27,
        48,
        49,
        32,
        11,
        42,
        16,
        20,
        11,
        6,
        0,
        0,
        17
      ],
      "weeklyHold": [
        36,
        42,
        49,
        14,
        49,
        2,
        24,
        16,
        21,
        14,
        29,
        30,
        42,
        32,
        36,
        13,
        2,
        48,
        0,
        0,
        43,
        32,
        41,
        2,
        29,
        31,
        25,
        14,
        47,
        21,
        45,
        6,
        41,
        32,
        2,
        0,
        45,
        5,
        27,
        49,
        34,
        37,
        2,
        41,
        23,
        33,
        21,
        15,
        0,
        49,
        44,
        8
      ],
      "weeklyBackordered": [
        36,
        49,
        0,
        28,
        16,
        38,
        15,
        33,
        23,
        17,
        49,
        49,
        3,
        49,
        22,
        49,
        38,
        33,
        0,
        36,
        2,
        3,
        19,
        49,
        40,
        1,
        49,
        35,
        17,
        49,
        0,
        49,
        48,
        49,
        0,
        23,
        49,
        11,
        36,
        28,
        47,
        26,
        10,
        8,
        0,
        17,
        25,
        22,
        28,
        17,
        37,
        3
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        0,
        49,
        29,
        36,
        39,
        33,
        35,
        15,
        35,
        8,
        38,
        29,
        45,
        39,
        16,
        25,
        38,
        0,
        46,
        16,
        26,
        43,
        42,
        37,
        27,
        9,
        0,
        0,
        9,
        13,
        8,
        33,
        49,
        5,
        0,
        11,
        4,
        39,
        19,
        49,
        8,
        35,
        49,
        49,
        49,
        0,
        0,
        49,
        7,
        47,
        27,
        48
      ],
      "weeklyRejected": [
        30,
        46,
        22,
        3,
        49,
        29,
        32,
        49,
        43,
        32,
        41,
        0,
        49,
        24,
        48,
        24,
        12,
        28,
        49,
        30,
        0,
        34,
        35,
        15,
        32,
        49,
        8,
        2,
        39,
        19,
        27,
        20,
        45,
        15,
        33,
        49,
        6,
        24,
        18,
        26,
        48,
        20,
        1,
        0,
        40,
        35,
        5,
        49,
        37,
        43,
        0,
        30
      ],
      "weeklyHold": [
        30,
        2,
        49,
        21,
        15,
        4,
        0,
        10,
        14,
        15,
        30,
        49,
        1,
        46,
        18,
        23,
        0,
        26,
        0,
        15,
        0,
        6,
        16,
        45,
        38,
        17,
        28,
        5,
        40,
        0,
        40,
        12,
        19,
        48,
        20,
        49,
        0,
        8,
        44,
        0,
        24,
        49,
        26,
        22,
        29,
        49,
        46,
        0,
        0,
        0,
        36,
        31
      ],
      "weeklyBackordered": [
        27,
        15,
        0,
        0,
        25,
        6,
        10,
        49,
        23,
        0,
        0,
        17,
        7,
        29,
        32,
        49,
        49,
        24,
        15,
        4,
        43,
        45,
        34,
        49,
        48,
        1,
        49,
        49,
        22,
        13,
        12,
        13,
        27,
        44,
        33,
        4,
        21,
        23,
        49,
        8,
        18,
        13,
        0,
        37,
        49,
        11,
        49,
        13,
        17,
        45,
        29,
        43
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        0,
        32,
        34,
        29,
        28,
        26,
        35,
        28,
        48,
        15,
        12,
        13,
        0,
        32,
        39,
        9,
        36,
        0,
        39,
        43,
        40,
        49,
        16,
        37,
        36,
        27,
        15,
        20,
        3,
        49,
        16,
        0,
        49,
        6,
        29,
        41,
        47,
        48,
        46,
        26,
        5,
        39,
        46,
        49,
        44,
        17,
        1,
        22,
        22,
        47,
        49,
        28
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        30,
        23,
        32,
        30,
        49,
        27,
        22,
        0,
        0,
        33,
        47,
        30,
        38,
        38,
        0,
        21,
        36,
        32,
        34,
        49,
        47,
        15,
        44,
        16,
        49,
        48,
        8,
        35,
        47,
        29,
        23,
        32,
        49,
        0,
        32,
        6,
        13,
        29,
        49,
        40,
        0,
        27,
        25,
        7,
        37,
        4,
        49,
        1,
        25
      ],
      "weeklyHold": [
        12,
        40,
        39,
        1,
        31,
        18,
        17,
        34,
        29,
        34,
        16,
        32,
        48,
        18,
        49,
        32,
        0,
        19,
        43,
        13,
        0,
        20,
        34,
        25,
        10,
        48,
        6,
        0,
        45,
        24,
        38,
        1,
        42,
        8,
        15,
        2,
        38,
        10,
        1,
        49,
        49,
        33,
        6,
        24,
        49,
        26,
        35,
        46,
        28,
        13,
        49,
        29
      ],
      "weeklyBackordered": [
        25,
        27,
        39,
        48,
        42,
        34,
        49,
        49,
        45,
        13,
        39,
        20,
        49,
        25,
        38,
        20,
        39,
        43,
        14,
        7,
        0,
        0,
        17,
        18,
        1,
        49,
        17,
        11,
        36,
        49,
        39,
        21,
        29,
        42,
        0,
        0,
        24,
        7,
        14,
        30,
        25,
        24,
        0,
        8,
        18,
        36,
        38,
        2,
        13,
        7,
        29,
        2
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        27,
        49,
        31,
        18,
        38,
        6,
        23,
        2,
        32,
        38,
        45,
        0,
        46,
        17,
        17,
        0,
        49,
        5,
        28,
        20,
        40,
        7,
        49,
        44,
        0,
        47,
        7,
        36,
        49,
        28,
        0,
        21,
        35,
        34,
        34,
        31,
        27,
        37,
        18,
        49,
        0,
        49,
        49,
        12,
        24,
        0,
        17,
        20,
        23,
        18,
        0,
        27
      ],
      "weeklyRejected": [
        49,
        25,
        21,
        0,
        18,
        0,
        8,
        42,
        19,
        31,
        26,
        0,
        30,
        20,
        29,
        24,
        27,
        49,
        38,
        30,
        20,
        14,
        12,
        9,
        49,
        42,
        20,
        0,
        48,
        48,
        45,
        22,
        21,
        35,
        0,
        49,
        23,
        2,
        7,
        46,
        29,
        24,
        17,
        9,
        38,
        17,
        28,
        44,
        0,
        37,
        20,
        49
      ],
      "weeklyHold": [
        49,
        28,
        47,
        1,
        10,
        0,
        49,
        26,
        13,
        49,
        47,
        23,
        24,
        49,
        37,
        24,
        4,
        44,
        49,
        0,
        8,
        28,
        49,
        43,
        32,
        32,
        0,
        27,
        49,
        0,
        24,
        13,
        0,
        0,
        17,
        5,
        20,
        13,
        46,
        13,
        0,
        42,
        28,
        49,
        0,
        46,
        20,
        49,
        0,
        44,
        42,
        14
      ],
      "weeklyBackordered": [
        28,
        6,
        29,
        15,
        49,
        29,
        44,
        49,
        9,
        13,
        0,
        41,
        3,
        14,
        38,
        31,
        49,
        29,
        20,
        36,
        41,
        48,
        12,
        48,
        24,
        11,
        49,
        0,
        1,
        49,
        1,
        30,
        34,
        30,
        29,
        18,
        18,
        2,
        33,
        14,
        37,
        48,
        1,
        49,
        38,
        49,
        49,
        32,
        40,
        47,
        1,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyAccepted": [
        7,
        23,
        0,
        48,
        8,
        32,
        1,
        40,
        31,
        44,
        32,
        32,
        43,
        39,
        36,
        44,
        49,
        6,
        7,
        49,
        20,
        0,
        23,
        30,
        19,
        46,
        0,
        40,
        2,
        43,
        0,
        12,
        38,
        36,
        35,
        49,
        37,
        2,
        20,
        49,
        0,
        33,
        28,
        49,
        49,
        20,
        19,
        31,
        18,
        18,
        0,
        21
      ],
      "weeklyRejected": [
        31,
        16,
        46,
        22,
        23,
        18,
        15,
        49,
        26,
        31,
        36,
        0,
        36,
        24,
        41,
        1,
        49,
        31,
        49,
        49,
        28,
        49,
        39,
        49,
        24,
        33,
        44,
        19,
        35,
        6,
        0,
        47,
        8,
        19,
        7,
        49,
        17,
        33,
        7,
        20,
        36,
        47,
        4,
        21,
        49,
        49,
        14,
        27,
        24,
        29,
        16,
        39
      ],
      "weeklyHold": [
        49,
        27,
        34,
        16,
        20,
        0,
        0,
        2,
        0,
        17,
        47,
        38,
        4,
        26,
        20,
        4,
        49,
        2,
        27,
        0,
        4,
        4,
        49,
        23,
        0,
        48,
        19,
        17,
        49,
        19,
        23,
        25,
        12,
        39,
        25,
        12,
        24,
        1,
        20,
        0,
        35,
        29,
        0,
        43,
        49,
        19,
        27,
        38,
        0,
        14,
        44,
        48
      ],
      "weeklyBackordered": [
        0,
        28,
        24,
        46,
        41,
        36,
        16,
        43,
        34,
        18,
        27,
        49,
        6,
        38,
        33,
        39,
        1,
        36,
        23,
        12,
        47,
        43,
        43,
        38,
        25,
        31,
        49,
        11,
        41,
        5,
        45,
        44,
        45,
        43,
        0,
        30,
        45,
        7,
        0,
        37,
        48,
        44,
        1,
        49,
        40,
        15,
        25,
        49,
        1,
        49,
        29,
        17
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        13,
        34,
        35,
        17,
        13,
        0,
        11,
        30,
        44,
        8,
        47,
        7,
        0,
        32,
        31,
        29,
        49,
        0,
        2,
        18,
        10,
        22,
        26,
        20,
        4,
        3,
        19,
        41,
        8,
        49,
        7,
        25,
        40,
        0,
        48,
        20,
        24,
        48,
        49,
        49,
        17,
        43,
        49,
        49,
        49,
        22,
        15,
        39,
        0,
        33,
        39,
        39
      ],
      "weeklyRejected": [
        49,
        22,
        49,
        37,
        27,
        17,
        18,
        36,
        49,
        23,
        31,
        27,
        10,
        48,
        48,
        16,
        0,
        49,
        49,
        10,
        13,
        12,
        1,
        1,
        31,
        23,
        15,
        19,
        36,
        48,
        33,
        45,
        45,
        49,
        0,
        48,
        11,
        16,
        17,
        49,
        28,
        34,
        15,
        6,
        39,
        32,
        9,
        29,
        10,
        28,
        0,
        22
      ],
      "weeklyHold": [
        0,
        49,
        33,
        22,
        17,
        25,
        34,
        6,
        33,
        32,
        0,
        28,
        48,
        36,
        19,
        33,
        46,
        0,
        12,
        33,
        43,
        41,
        0,
        16,
        3,
        23,
        49,
        49,
        30,
        0,
        48,
        11,
        0,
        22,
        48,
        9,
        10,
        25,
        33,
        42,
        22,
        23,
        0,
        48,
        32,
        0,
        48,
        22,
        13,
        49,
        49,
        18
      ],
      "weeklyBackordered": [
        32,
        29,
        0,
        34,
        48,
        33,
        49,
        1,
        49,
        23,
        40,
        8,
        3,
        38,
        5,
        19,
        30,
        32,
        24,
        33,
        4,
        3,
        36,
        47,
        1,
        12,
        40,
        30,
        10,
        49,
        0,
        49,
        2,
        31,
        23,
        0,
        1,
        0,
        8,
        8,
        20,
        48,
        30,
        49,
        35,
        43,
        48,
        9,
        2,
        32,
        45,
        40
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        21,
        49,
        21,
        32,
        18,
        0,
        1,
        28,
        28,
        49,
        49,
        6,
        39,
        49,
        19,
        49,
        15,
        7,
        49,
        11,
        21,
        25,
        49,
        41,
        22,
        47,
        0,
        28,
        40,
        5,
        41,
        0,
        49,
        0,
        26,
        30,
        34,
        0,
        1,
        49,
        28,
        17,
        45,
        49,
        49,
        38,
        0,
        28,
        18,
        34,
        5,
        7
      ],
      "weeklyRejected": [
        45,
        48,
        49,
        47,
        29,
        0,
        40,
        47,
        49,
        17,
        23,
        40,
        27,
        20,
        15,
        49,
        0,
        45,
        18,
        38,
        13,
        38,
        23,
        20,
        49,
        49,
        24,
        0,
        48,
        17,
        49,
        26,
        3,
        22,
        27,
        49,
        15,
        0,
        12,
        23,
        39,
        34,
        26,
        21,
        33,
        36,
        28,
        39,
        8,
        38,
        38,
        49
      ],
      "weeklyHold": [
        29,
        13,
        44,
        1,
        9,
        31,
        8,
        2,
        24,
        28,
        0,
        47,
        33,
        15,
        14,
        14,
        0,
        48,
        14,
        20,
        39,
        49,
        0,
        29,
        0,
        24,
        35,
        4,
        32,
        3,
        46,
        7,
        11,
        12,
        17,
        27,
        35,
        0,
        30,
        40,
        19,
        18,
        14,
        35,
        19,
        9,
        0,
        46,
        2,
        49,
        34,
        0
      ],
      "weeklyBackordered": [
        15,
        0,
        17,
        32,
        49,
        33,
        49,
        25,
        26,
        14,
        21,
        25,
        38,
        35,
        42,
        1,
        49,
        45,
        1,
        27,
        24,
        19,
        11,
        41,
        45,
        10,
        11,
        5,
        10,
        40,
        49,
        1,
        45,
        49,
        21,
        7,
        7,
        6,
        0,
        29,
        6,
        41,
        12,
        38,
        49,
        49,
        49,
        19,
        0,
        34,
        49,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyAccepted": [
        46,
        44,
        27,
        48,
        47,
        15,
        29,
        45,
        48,
        10,
        49,
        11,
        27,
        5,
        18,
        10,
        40,
        0,
        16,
        12,
        0,
        0,
        18,
        7,
        3,
        39,
        49,
        30,
        49,
        49,
        49,
        21,
        18,
        13,
        25,
        11,
        6,
        30,
        21,
        49,
        4,
        43,
        49,
        0,
        49,
        0,
        36,
        5,
        47,
        42,
        26,
        11
      ],
      "weeklyRejected": [
        0,
        49,
        44,
        19,
        31,
        38,
        20,
        44,
        29,
        31,
        49,
        32,
        26,
        21,
        41,
        0,
        12,
        49,
        10,
        49,
        37,
        15,
        3,
        22,
        0,
        49,
        49,
        29,
        48,
        13,
        49,
        24,
        6,
        47,
        8,
        49,
        7,
        11,
        40,
        7,
        28,
        49,
        3,
        11,
        49,
        15,
        0,
        16,
        0,
        49,
        0,
        49
      ],
      "weeklyHold": [
        18,
        17,
        41,
        20,
        13,
        28,
        27,
        10,
        11,
        13,
        47,
        7,
        34,
        21,
        44,
        0,
        10,
        42,
        11,
        22,
        33,
        12,
        0,
        6,
        37,
        18,
        20,
        17,
        30,
        0,
        48,
        26,
        10,
        37,
        47,
        18,
        49,
        0,
        42,
        24,
        0,
        48,
        49,
        0,
        49,
        49,
        49,
        42,
        13,
        12,
        24,
        37
      ],
      "weeklyBackordered": [
        0,
        31,
        0,
        2,
        14,
        37,
        0,
        20,
        6,
        4,
        9,
        49,
        25,
        49,
        20,
        30,
        23,
        16,
        5,
        23,
        37,
        4,
        31,
        0,
        1,
        20,
        9,
        47,
        1,
        49,
        37,
        49,
        2,
        25,
        10,
        0,
        1,
        0,
        42,
        46,
        0,
        48,
        49,
        26,
        13,
        49,
        49,
        26,
        19,
        33,
        41,
        11
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyAccepted": [
        0,
        34,
        12,
        48,
        27,
        0,
        49,
        49,
        48,
        21,
        49,
        0,
        24,
        30,
        13,
        49,
        47,
        48,
        3,
        15,
        0,
        49,
        30,
        49,
        37,
        8,
        45,
        6,
        36,
        49,
        49,
        29,
        49,
        21,
        48,
        29,
        15,
        48,
        24,
        49,
        47,
        14,
        49,
        49,
        37,
        41,
        15,
        16,
        23,
        49,
        0,
        23
      ],
      "weeklyRejected": [
        36,
        30,
        2,
        29,
        14,
        10,
        22,
        24,
        26,
        1,
        0,
        22,
        49,
        38,
        10,
        18,
        36,
        22,
        39,
        31,
        49,
        18,
        0,
        30,
        39,
        49,
        11,
        22,
        26,
        0,
        49,
        26,
        48,
        49,
        23,
        49,
        0,
        0,
        1,
        13,
        19,
        49,
        49,
        0,
        34,
        8,
        5,
        31,
        7,
        42,
        0,
        49
      ],
      "weeklyHold": [
        19,
        27,
        49,
        13,
        28,
        3,
        0,
        2,
        0,
        31,
        39,
        38,
        1,
        15,
        8,
        42,
        33,
        44,
        27,
        0,
        0,
        0,
        34,
        11,
        25,
        45,
        22,
        8,
        23,
        0,
        46,
        28,
        16,
        42,
        40,
        16,
        48,
        7,
        46,
        49,
        0,
        39,
        23,
        32,
        38,
        49,
        25,
        27,
        5,
        5,
        9,
        28
      ],
      "weeklyBackordered": [
        0,
        49,
        0,
        44,
        49,
        34,
        19,
        49,
        49,
        49,
        20,
        40,
        26,
        18,
        32,
        20,
        49,
        29,
        6,
        11,
        9,
        23,
        22,
        3,
        18,
        30,
        49,
        16,
        17,
        39,
        47,
        18,
        48,
        49,
        0,
        0,
        1,
        16,
        22,
        22,
        13,
        37,
        25,
        49,
        17,
        5,
        49,
        15,
        0,
        39,
        32,
        49
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        10,
        49,
        32,
        48,
        27,
        49,
        5,
        28,
        9,
        31,
        41,
        11,
        12,
        19,
        1,
        49,
        17,
        0,
        48,
        34,
        0,
        17,
        11,
        5,
        0,
        47,
        11,
        11,
        33,
        4,
        15,
        25,
        29,
        9,
        43,
        46,
        34,
        37,
        8,
        27,
        24,
        13,
        49,
        49,
        49,
        0,
        11,
        17,
        49,
        49,
        45,
        0
      ],
      "weeklyRejected": [
        33,
        49,
        27,
        28,
        0,
        49,
        31,
        37,
        49,
        48,
        18,
        23,
        12,
        29,
        48,
        15,
        42,
        46,
        15,
        27,
        14,
        49,
        49,
        45,
        20,
        35,
        27,
        24,
        17,
        48,
        37,
        0,
        0,
        22,
        0,
        21,
        49,
        47,
        9,
        43,
        46,
        13,
        15,
        0,
        40,
        26,
        17,
        2,
        28,
        38,
        0,
        49
      ],
      "weeklyHold": [
        14,
        22,
        38,
        35,
        0,
        9,
        24,
        19,
        25,
        30,
        17,
        45,
        32,
        1,
        44,
        9,
        23,
        25,
        7,
        24,
        16,
        0,
        36,
        39,
        44,
        17,
        26,
        38,
        49,
        4,
        19,
        6,
        0,
        49,
        0,
        30,
        1,
        0,
        49,
        36,
        0,
        35,
        0,
        47,
        49,
        48,
        0,
        49,
        11,
        49,
        36,
        31
      ],
      "weeklyBackordered": [
        19,
        26,
        0,
        19,
        35,
        23,
        19,
        10,
        21,
        26,
        49,
        49,
        3,
        27,
        45,
        1,
        46,
        20,
        28,
        1,
        39,
        27,
        22,
        46,
        25,
        49,
        13,
        10,
        30,
        32,
        22,
        49,
        45,
        15,
        17,
        14,
        31,
        0,
        49,
        0,
        24,
        48,
        29,
        11,
        12,
        18,
        20,
        31,
        0,
        9,
        43,
        16
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        0,
        41,
        49,
        30,
        4,
        18,
        29,
        2,
        23,
        49,
        19,
        5,
        4,
        7,
        47,
        16,
        21,
        40,
        0,
        37,
        41,
        49,
        49,
        37,
        6,
        39,
        0,
        49,
        31,
        32,
        14,
        20,
        40,
        36,
        48,
        45,
        21,
        4,
        6,
        32,
        36,
        21,
        26,
        11,
        28,
        29,
        0,
        0,
        21,
        44,
        27,
        42
      ],
      "weeklyRejected": [
        5,
        23,
        35,
        26,
        20,
        29,
        34,
        40,
        20,
        1,
        43,
        0,
        6,
        28,
        8,
        49,
        0,
        42,
        29,
        4,
        12,
        29,
        4,
        49,
        9,
        23,
        25,
        49,
        48,
        30,
        34,
        6,
        35,
        8,
        0,
        49,
        33,
        19,
        13,
        11,
        48,
        16,
        9,
        49,
        23,
        13,
        8,
        11,
        0,
        15,
        0,
        49
      ],
      "weeklyHold": [
        22,
        26,
        36,
        30,
        46,
        31,
        0,
        14,
        36,
        33,
        33,
        15,
        20,
        27,
        17,
        0,
        18,
        37,
        14,
        31,
        43,
        49,
        23,
        38,
        25,
        48,
        0,
        0,
        49,
        49,
        48,
        41,
        2,
        11,
        25,
        49,
        34,
        0,
        35,
        23,
        49,
        20,
        35,
        12,
        31,
        8,
        0,
        34,
        8,
        46,
        29,
        34
      ],
      "weeklyBackordered": [
        18,
        27,
        12,
        22,
        49,
        46,
        0,
        49,
        31,
        22,
        49,
        31,
        3,
        15,
        46,
        3,
        20,
        38,
        0,
        0,
        43,
        0,
        0,
        49,
        38,
        13,
        41,
        26,
        49,
        44,
        15,
        26,
        31,
        34,
        34,
        6,
        8,
        21,
        26,
        40,
        33,
        14,
        9,
        0,
        0,
        40,
        19,
        25,
        0,
        49,
        42,
        9
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        2,
        28,
        25,
        33,
        20,
        0,
        48,
        22,
        48,
        21,
        49,
        10,
        0,
        0,
        18,
        30,
        23,
        9,
        18,
        8,
        27,
        35,
        12,
        32,
        11,
        30,
        49,
        34,
        24,
        49,
        19,
        32,
        49,
        16,
        48,
        20,
        47,
        48,
        24,
        34,
        24,
        42,
        20,
        19,
        40,
        16,
        44,
        0,
        0,
        49,
        49,
        28
      ],
      "weeklyRejected": [
        14,
        22,
        19,
        13,
        25,
        29,
        5,
        13,
        33,
        19,
        16,
        49,
        21,
        18,
        8,
        18,
        27,
        49,
        49,
        49,
        40,
        35,
        43,
        3,
        21,
        4,
        27,
        5,
        16,
        31,
        13,
        47,
        30,
        49,
        26,
        1,
        11,
        39,
        17,
        48,
        29,
        42,
        49,
        39,
        22,
        34,
        0,
        32,
        23,
        41,
        16,
        49
      ],
      "weeklyHold": [
        22,
        0,
        49,
        1,
        13,
        34,
        43,
        20,
        35,
        49,
        35,
        44,
        48,
        36,
        25,
        37,
        14,
        27,
        49,
        29,
        3,
        12,
        12,
        35,
        13,
        34,
        26,
        15,
        39,
        0,
        0,
        0,
        20,
        44,
        27,
        31,
        0,
        3,
        27,
        17,
        0,
        35,
        32,
        49,
        49,
        49,
        30,
        25,
        0,
        17,
        42,
        22
      ],
      "weeklyBackordered": [
        24,
        34,
        27,
        14,
        45,
        8,
        49,
        49,
        30,
        37,
        34,
        27,
        3,
        48,
        35,
        35,
        28,
        28,
        21,
        32,
        31,
        35,
        30,
        5,
        1,
        9,
        43,
        13,
        35,
        17,
        9,
        20,
        28,
        49,
        0,
        0,
        1,
        31,
        47,
        42,
        0,
        43,
        23,
        49,
        39,
        45,
        49,
        26,
        4,
        49,
        37,
        8
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        0,
        49,
        31,
        0,
        12,
        16,
        8,
        49,
        4,
        34,
        16,
        3,
        38,
        35,
        35,
        31,
        49,
        0,
        46,
        34,
        49,
        39,
        37,
        0,
        16,
        32,
        5,
        39,
        36,
        44,
        29,
        13,
        49,
        49,
        42,
        36,
        41,
        48,
        27,
        42,
        0,
        49,
        19,
        37,
        22,
        33,
        6,
        43,
        0,
        17,
        29,
        0
      ],
      "weeklyRejected": [
        49,
        49,
        19,
        0,
        29,
        20,
        14,
        49,
        27,
        1,
        11,
        34,
        8,
        18,
        48,
        35,
        44,
        49,
        47,
        3,
        26,
        49,
        49,
        49,
        49,
        41,
        13,
        15,
        48,
        12,
        23,
        25,
        47,
        23,
        23,
        45,
        49,
        40,
        1,
        49,
        19,
        11,
        12,
        25,
        49,
        18,
        24,
        34,
        16,
        40,
        8,
        49
      ],
      "weeklyHold": [
        42,
        3,
        49,
        1,
        49,
        46,
        0,
        9,
        4,
        37,
        25,
        1,
        25,
        1,
        42,
        0,
        19,
        31,
        49,
        0,
        30,
        31,
        42,
        10,
        13,
        48,
        18,
        14,
        49,
        4,
        48,
        0,
        0,
        31,
        32,
        49,
        14,
        0,
        40,
        26,
        45,
        38,
        0,
        49,
        49,
        0,
        28,
        0,
        0,
        0,
        36,
        19
      ],
      "weeklyBackordered": [
        19,
        21,
        12,
        44,
        48,
        21,
        45,
        43,
        0,
        22,
        49,
        10,
        10,
        21,
        45,
        49,
        9,
        49,
        44,
        40,
        14,
        11,
        0,
        5,
        1,
        3,
        18,
        36,
        49,
        0,
        49,
        49,
        43,
        15,
        0,
        0,
        16,
        33,
        24,
        20,
        0,
        48,
        3,
        37,
        34,
        25,
        14,
        0,
        16,
        49,
        34,
        1
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyAccepted": [
        31,
        32,
        1,
        32,
        18,
        36,
        35,
        49,
        48,
        0,
        13,
        36,
        38,
        20,
        8,
        0,
        23,
        9,
        0,
        17,
        6,
        27,
        15,
        49,
        27,
        33,
        12,
        16,
        36,
        38,
        27,
        0,
        26,
        23,
        38,
        2,
        32,
        48,
        21,
        49,
        0,
        29,
        24,
        37,
        3,
        46,
        5,
        12,
        2,
        37,
        49,
        21
      ],
      "weeklyRejected": [
        49,
        22,
        12,
        0,
        49,
        34,
        1,
        43,
        32,
        25,
        40,
        0,
        15,
        48,
        40,
        9,
        23,
        19,
        0,
        36,
        49,
        21,
        16,
        2,
        2,
        36,
        49,
        0,
        12,
        4,
        18,
        43,
        48,
        33,
        31,
        49,
        0,
        26,
        30,
        0,
        40,
        49,
        49,
        0,
        45,
        0,
        17,
        49,
        12,
        38,
        0,
        33
      ],
      "weeklyHold": [
        5,
        49,
        34,
        18,
        32,
        26,
        45,
        18,
        27,
        40,
        47,
        40,
        48,
        16,
        17,
        37,
        49,
        42,
        18,
        44,
        12,
        43,
        1,
        16,
        2,
        5,
        16,
        17,
        38,
        13,
        42,
        0,
        13,
        29,
        44,
        29,
        0,
        11,
        23,
        12,
        37,
        30,
        11,
        0,
        49,
        49,
        47,
        23,
        8,
        0,
        17,
        35
      ],
      "weeklyBackordered": [
        45,
        37,
        0,
        1,
        39,
        43,
        38,
        44,
        34,
        0,
        23,
        9,
        10,
        11,
        7,
        36,
        49,
        37,
        0,
        49,
        17,
        42,
        48,
        46,
        17,
        35,
        7,
        19,
        3,
        37,
        31,
        48,
        0,
        49,
        0,
        0,
        8,
        0,
        16,
        6,
        18,
        38,
        14,
        49,
        14,
        0,
        13,
        37,
        16,
        49,
        1,
        44
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        10,
        30,
        20,
        5,
        15,
        19,
        12,
        14,
        23,
        33,
        22,
        2,
        15,
        0,
        45,
        5,
        49,
        13,
        11,
        49,
        40,
        26,
        3,
        19,
        30,
        47,
        49,
        32,
        18,
        34,
        22,
        16,
        49,
        42,
        48,
        7,
        5,
        36,
        38,
        49,
        16,
        20,
        49,
        11,
        49,
        30,
        39,
        19,
        43,
        46,
        34,
        30
      ],
      "weeklyRejected": [
        29,
        39,
        21,
        15,
        35,
        31,
        44,
        17,
        24,
        1,
        19,
        0,
        11,
        32,
        38,
        39,
        16,
        49,
        18,
        39,
        37,
        0,
        21,
        22,
        27,
        37,
        7,
        22,
        39,
        5,
        44,
        30,
        48,
        19,
        30,
        49,
        15,
        30,
        1,
        25,
        12,
        49,
        49,
        26,
        37,
        16,
        0,
        25,
        16,
        24,
        0,
        48
      ],
      "weeklyHold": [
        43,
        19,
        29,
        1,
        18,
        46,
        30,
        29,
        39,
        40,
        15,
        39,
        22,
        1,
        42,
        49,
        23,
        47,
        0,
        27,
        0,
        27,
        17,
        49,
        0,
        48,
        34,
        33,
        19,
        38,
        48,
        40,
        0,
        44,
        11,
        31,
        21,
        0,
        49,
        49,
        28,
        18,
        49,
        26,
        47,
        45,
        28,
        15,
        0,
        12,
        22,
        38
      ],
      "weeklyBackordered": [
        18,
        49,
        24,
        27,
        21,
        12,
        38,
        49,
        13,
        34,
        11,
        8,
        26,
        31,
        0,
        40,
        13,
        49,
        4,
        49,
        37,
        20,
        49,
        17,
        28,
        42,
        1,
        0,
        38,
        16,
        5,
        49,
        18,
        49,
        22,
        0,
        15,
        8,
        49,
        49,
        24,
        2,
        43,
        49,
        30,
        0,
        38,
        12,
        0,
        38,
        47,
        40
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyAccepted": [
        17,
        49,
        28,
        15,
        16,
        0,
        8,
        48,
        17,
        13,
        17,
        6,
        31,
        41,
        9,
        33,
        28,
        16,
        0,
        23,
        49,
        49,
        49,
        22,
        7,
        47,
        19,
        12,
        21,
        49,
        49,
        6,
        3,
        0,
        48,
        49,
        37,
        23,
        7,
        34,
        41,
        24,
        15,
        38,
        49,
        0,
        0,
        34,
        8,
        5,
        9,
        47
      ],
      "weeklyRejected": [
        38,
        0,
        43,
        25,
        45,
        0,
        30,
        28,
        35,
        1,
        47,
        39,
        26,
        14,
        13,
        19,
        0,
        40,
        14,
        49,
        28,
        0,
        23,
        30,
        28,
        49,
        49,
        49,
        35,
        24,
        39,
        13,
        42,
        4,
        49,
        49,
        47,
        22,
        28,
        38,
        44,
        3,
        41,
        0,
        49,
        49,
        3,
        49,
        11,
        19,
        0,
        18
      ],
      "weeklyHold": [
        17,
        13,
        27,
        12,
        0,
        40,
        24,
        20,
        49,
        30,
        28,
        40,
        29,
        34,
        19,
        21,
        11,
        10,
        0,
        21,
        0,
        49,
        41,
        49,
        37,
        27,
        49,
        16,
        33,
        0,
        23,
        0,
        20,
        49,
        10,
        44,
        17,
        2,
        27,
        21,
        49,
        49,
        2,
        0,
        49,
        29,
        42,
        30,
        18,
        49,
        43,
        0
      ],
      "weeklyBackordered": [
        31,
        25,
        13,
        22,
        48,
        37,
        47,
        49,
        24,
        0,
        4,
        23,
        33,
        0,
        26,
        30,
        18,
        21,
        12,
        22,
        3,
        19,
        47,
        15,
        25,
        3,
        21,
        35,
        18,
        38,
        0,
        15,
        36,
        49,
        29,
        0,
        44,
        9,
        3,
        31,
        25,
        48,
        0,
        49,
        49,
        25,
        49,
        25,
        33,
        38,
        33,
        35
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        0,
        23,
        27,
        0,
        17,
        0,
        31,
        40,
        48,
        1,
        7,
        0,
        9,
        49,
        19,
        41,
        4,
        0,
        25,
        18,
        3,
        0,
        34,
        49,
        0,
        27,
        0,
        39,
        4,
        14,
        0,
        23,
        2,
        34,
        22,
        16,
        35,
        29,
        17,
        49,
        11,
        26,
        8,
        37,
        0,
        7,
        15,
        9,
        0,
        49,
        21,
        21
      ],
      "weeklyRejected": [
        35,
        46,
        7,
        18,
        14,
        21,
        32,
        49,
        14,
        47,
        1,
        41,
        42,
        48,
        48,
        22,
        20,
        36,
        40,
        33,
        49,
        17,
        41,
        32,
        49,
        48,
        29,
        7,
        20,
        8,
        0,
        47,
        21,
        15,
        21,
        30,
        18,
        25,
        1,
        49,
        48,
        28,
        7,
        45,
        46,
        0,
        0,
        18,
        0,
        44,
        0,
        37
      ],
      "weeklyHold": [
        0,
        42,
        37,
        16,
        39,
        30,
        0,
        22,
        24,
        48,
        47,
        42,
        17,
        9,
        0,
        15,
        0,
        41,
        34,
        0,
        17,
        0,
        16,
        33,
        36,
        32,
        18,
        0,
        49,
        20,
        15,
        20,
        0,
        29,
        48,
        44,
        39,
        0,
        49,
        36,
        6,
        49,
        29,
        49,
        49,
        32,
        0,
        0,
        0,
        0,
        42,
        0
      ],
      "weeklyBackordered": [
        29,
        30,
        0,
        47,
        39,
        39,
        12,
        34,
        12,
        0,
        49,
        14,
        3,
        43,
        8,
        5,
        19,
        29,
        8,
        12,
        49,
        7,
        26,
        45,
        1,
        19,
        45,
        22,
        20,
        32,
        41,
        47,
        34,
        42,
        0,
        0,
        1,
        26,
        49,
        7,
        22,
        48,
        33,
        49,
        43,
        0,
        3,
        3,
        12,
        49,
        16,
        19
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        0,
        47,
        49,
        35,
        49,
        0,
        26,
        34,
        45,
        49,
        49,
        27,
        45,
        19,
        36,
        49,
        31,
        5,
        12,
        9,
        11,
        3,
        23,
        34,
        28,
        20,
        0,
        42,
        11,
        8,
        0,
        33,
        36,
        5,
        48,
        49,
        21,
        48,
        46,
        33,
        12,
        45,
        48,
        36,
        40,
        2,
        36,
        49,
        2,
        3,
        49,
        16
      ],
      "weeklyRejected": [
        49,
        40,
        13,
        31,
        20,
        0,
        23,
        32,
        33,
        1,
        29,
        35,
        41,
        13,
        48,
        15,
        49,
        49,
        13,
        22,
        29,
        49,
        24,
        21,
        35,
        15,
        30,
        4,
        39,
        27,
        11,
        14,
        34,
        1,
        20,
        37,
        19,
        0,
        27,
        42,
        48,
        40,
        27,
        5,
        49,
        49,
        25,
        24,
        33,
        49,
        12,
        42
      ],
      "weeklyHold": [
        27,
        39,
        21,
        15,
        0,
        46,
        19,
        10,
        4,
        19,
        47,
        24,
        23,
        30,
        39,
        14,
        0,
        48,
        25,
        0,
        35,
        49,
        29,
        7,
        40,
        48,
        31,
        0,
        5,
        0,
        48,
        0,
        32,
        21,
        48,
        0,
        38,
        0,
        49,
        38,
        33,
        49,
        0,
        20,
        49,
        33,
        27,
        41,
        20,
        42,
        36,
        49
      ],
      "weeklyBackordered": [
        48,
        0,
        45,
        8,
        49,
        5,
        37,
        36,
        24,
        39,
        20,
        0,
        49,
        0,
        45,
        39,
        28,
        49,
        0,
        21,
        44,
        0,
        45,
        19,
        45,
        19,
        9,
        14,
        1,
        10,
        0,
        15,
        48,
        29,
        30,
        0,
        49,
        0,
        33,
        16,
        13,
        48,
        0,
        39,
        3,
        49,
        49,
        49,
        27,
        44,
        37,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        15,
        49,
        27,
        26,
        27,
        11,
        9,
        49,
        9,
        20,
        37,
        15,
        34,
        8,
        15,
        25,
        28,
        28,
        7,
        46,
        0,
        38,
        49,
        24,
        1,
        23,
        0,
        41,
        22,
        49,
        37,
        47,
        40,
        25,
        48,
        49,
        13,
        39,
        15,
        49,
        1,
        40,
        20,
        34,
        34,
        6,
        23,
        24,
        21,
        23,
        49,
        16
      ],
      "weeklyRejected": [
        43,
        6,
        8,
        29,
        20,
        22,
        49,
        18,
        49,
        20,
        16,
        33,
        49,
        44,
        48,
        38,
        27,
        37,
        39,
        11,
        23,
        46,
        0,
        44,
        29,
        49,
        34,
        23,
        45,
        12,
        29,
        40,
        29,
        49,
        26,
        49,
        29,
        9,
        14,
        3,
        30,
        15,
        6,
        49,
        23,
        2,
        12,
        38,
        34,
        0,
        10,
        48
      ],
      "weeklyHold": [
        49,
        12,
        49,
        1,
        8,
        48,
        0,
        2,
        7,
        22,
        10,
        1,
        1,
        1,
        19,
        13,
        23,
        13,
        10,
        2,
        3,
        24,
        34,
        0,
        19,
        48,
        42,
        15,
        25,
        0,
        39,
        0,
        0,
        24,
        48,
        30,
        22,
        1,
        49,
        18,
        0,
        31,
        48,
        20,
        41,
        39,
        45,
        29,
        1,
        18,
        36,
        21
      ],
      "weeklyBackordered": [
        6,
        33,
        3,
        9,
        33,
        46,
        40,
        24,
        27,
        32,
        49,
        0,
        26,
        45,
        2,
        30,
        31,
        38,
        27,
        42,
        9,
        11,
        42,
        0,
        29,
        22,
        49,
        37,
        19,
        43,
        5,
        49,
        28,
        24,
        13,
        3,
        7,
        0,
        35,
        39,
        36,
        31,
        26,
        49,
        16,
        14,
        49,
        31,
        0,
        0,
        49,
        16
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        20,
        9,
        49,
        15,
        20,
        0,
        42,
        2,
        47,
        17,
        40,
        1,
        14,
        35,
        36,
        19,
        45,
        0,
        0,
        49,
        0,
        30,
        32,
        41,
        25,
        47,
        36,
        7,
        1,
        49,
        45,
        28,
        23,
        4,
        14,
        14,
        28,
        48,
        31,
        49,
        38,
        49,
        49,
        13,
        11,
        0,
        24,
        14,
        0,
        46,
        40,
        48
      ],
      "weeklyRejected": [
        43,
        20,
        31,
        4,
        49,
        15,
        36,
        49,
        49,
        28,
        35,
        12,
        42,
        5,
        48,
        2,
        1,
        48,
        18,
        19,
        13,
        14,
        6,
        15,
        0,
        28,
        40,
        19,
        48,
        12,
        46,
        17,
        48,
        49,
        21,
        47,
        25,
        17,
        1,
        0,
        0,
        0,
        47,
        0,
        49,
        35,
        27,
        49,
        29,
        44,
        18,
        49
      ],
      "weeklyHold": [
        3,
        16,
        49,
        12,
        49,
        39,
        49,
        28,
        12,
        40,
        9,
        9,
        30,
        1,
        15,
        31,
        0,
        48,
        0,
        30,
        15,
        2,
        41,
        22,
        22,
        31,
        44,
        0,
        49,
        20,
        45,
        2,
        0,
        49,
        48,
        5,
        18,
        20,
        37,
        12,
        12,
        34,
        18,
        13,
        13,
        6,
        0,
        49,
        0,
        49,
        0,
        32
      ],
      "weeklyBackordered": [
        20,
        37,
        26,
        44,
        48,
        32,
        49,
        1,
        36,
        41,
        49,
        0,
        35,
        39,
        25,
        49,
        35,
        16,
        17,
        49,
        35,
        34,
        25,
        0,
        7,
        29,
        49,
        0,
        3,
        11,
        21,
        49,
        4,
        21,
        0,
        0,
        36,
        26,
        49,
        16,
        17,
        33,
        23,
        49,
        21,
        25,
        49,
        8,
        0,
        39,
        39,
        29
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        0,
        38,
        28,
        23,
        18,
        0,
        5,
        49,
        48,
        0,
        12,
        20,
        33,
        20,
        5,
        30,
        42,
        48,
        29,
        16,
        13,
        25,
        32,
        41,
        19,
        47,
        22,
        43,
        12,
        49,
        13,
        16,
        49,
        0,
        36,
        44,
        42,
        25,
        41,
        13,
        7,
        31,
        31,
        33,
        17,
        25,
        0,
        0,
        15,
        49,
        20,
        0
      ],
      "weeklyRejected": [
        49,
        15,
        23,
        35,
        29,
        44,
        49,
        0,
        23,
        12,
        24,
        41,
        42,
        48,
        48,
        27,
        24,
        30,
        33,
        17,
        25,
        2,
        29,
        18,
        37,
        49,
        23,
        32,
        19,
        20,
        22,
        47,
        17,
        39,
        34,
        31,
        19,
        31,
        27,
        8,
        47,
        39,
        20,
        32,
        22,
        0,
        27,
        35,
        5,
        32,
        0,
        15
      ],
      "weeklyHold": [
        34,
        28,
        49,
        6,
        20,
        34,
        33,
        16,
        11,
        29,
        43,
        42,
        3,
        45,
        24,
        24,
        1,
        16,
        36,
        11,
        15,
        0,
        25,
        22,
        11,
        23,
        7,
        43,
        49,
        5,
        21,
        13,
        21,
        20,
        36,
        16,
        1,
        8,
        29,
        29,
        2,
        8,
        0,
        18,
        49,
        30,
        49,
        26,
        6,
        26,
        38,
        31
      ],
      "weeklyBackordered": [
        33,
        23,
        10,
        46,
        49,
        27,
        39,
        49,
        27,
        0,
        0,
        30,
        18,
        25,
        0,
        47,
        46,
        45,
        8,
        29,
        49,
        0,
        14,
        28,
        1,
        4,
        20,
        21,
        8,
        49,
        41,
        47,
        33,
        48,
        8,
        0,
        17,
        0,
        20,
        0,
        13,
        48,
        23,
        15,
        41,
        23,
        26,
        14,
        3,
        3,
        49,
        28
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        33,
        1,
        0,
        32,
        36,
        10,
        42,
        49,
        44,
        15,
        48,
        8,
        21,
        49,
        4,
        42,
        49,
        16,
        12,
        48,
        37,
        38,
        37,
        28,
        9,
        17,
        6,
        31,
        17,
        20,
        49,
        0,
        49,
        0,
        39,
        25,
        47,
        7,
        14,
        49,
        32,
        37,
        21,
        26,
        41,
        49,
        7,
        0,
        18,
        35,
        9,
        0
      ],
      "weeklyRejected": [
        13,
        22,
        28,
        23,
        49,
        21,
        37,
        30,
        47,
        1,
        49,
        36,
        16,
        48,
        45,
        49,
        16,
        48,
        29,
        27,
        17,
        49,
        10,
        44,
        10,
        32,
        0,
        34,
        36,
        12,
        33,
        37,
        48,
        7,
        0,
        12,
        17,
        28,
        43,
        24,
        33,
        22,
        23,
        3,
        38,
        18,
        0,
        28,
        8,
        46,
        0,
        49
      ],
      "weeklyHold": [
        17,
        17,
        49,
        13,
        49,
        5,
        30,
        20,
        17,
        21,
        47,
        9,
        19,
        2,
        4,
        19,
        0,
        15,
        0,
        12,
        29,
        16,
        37,
        49,
        19,
        1,
        33,
        14,
        49,
        0,
        48,
        13,
        17,
        49,
        45,
        17,
        20,
        0,
        34,
        38,
        49,
        49,
        2,
        45,
        49,
        6,
        0,
        36,
        8,
        49,
        42,
        39
      ],
      "weeklyBackordered": [
        47,
        49,
        46,
        28,
        49,
        46,
        24,
        34,
        27,
        6,
        27,
        30,
        3,
        43,
        4,
        13,
        49,
        24,
        1,
        49,
        26,
        26,
        5,
        15,
        8,
        24,
        1,
        33,
        41,
        49,
        16,
        31,
        40,
        49,
        3,
        0,
        41,
        3,
        20,
        38,
        2,
        48,
        44,
        49,
        31,
        0,
        31,
        17,
        10,
        49,
        1,
        11
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        46,
        32,
        47,
        23,
        20,
        40,
        1,
        49,
        23,
        41,
        49,
        8,
        49,
        40,
        7,
        26,
        8,
        48,
        0,
        30,
        15,
        23,
        18,
        31,
        0,
        19,
        4,
        28,
        1,
        26,
        0,
        3,
        49,
        20,
        0,
        43,
        26,
        38,
        14,
        49,
        21,
        39,
        49,
        30,
        31,
        0,
        14,
        37,
        1,
        49,
        0,
        35
      ],
      "weeklyRejected": [
        24,
        0,
        17,
        18,
        48,
        21,
        19,
        49,
        11,
        24,
        7,
        49,
        1,
        21,
        48,
        0,
        24,
        26,
        0,
        40,
        4,
        49,
        44,
        18,
        34,
        27,
        49,
        49,
        48,
        3,
        22,
        29,
        47,
        49,
        13,
        48,
        10,
        24,
        19,
        5,
        48,
        27,
        15,
        22,
        48,
        16,
        13,
        42,
        26,
        39,
        24,
        43
      ],
      "weeklyHold": [
        22,
        7,
        49,
        13,
        24,
        38,
        49,
        49,
        28,
        30,
        35,
        42,
        1,
        23,
        32,
        0,
        1,
        8,
        49,
        0,
        21,
        27,
        0,
        23,
        34,
        45,
        49,
        14,
        36,
        29,
        39,
        33,
        28,
        20,
        48,
        49,
        13,
        17,
        31,
        19,
        49,
        38,
        27,
        49,
        49,
        34,
        0,
        48,
        0,
        40,
        1,
        23
      ],
      "weeklyBackordered": [
        30,
        21,
        10,
        49,
        40,
        20,
        20,
        40,
        21,
        38,
        38,
        2,
        3,
        49,
        10,
        12,
        12,
        43,
        49,
        15,
        7,
        0,
        0,
        49,
        37,
        8,
        1,
        26,
        21,
        45,
        12,
        49,
        32,
        34,
        0,
        0,
        21,
        13,
        40,
        49,
        33,
        48,
        6,
        9,
        12,
        39,
        30,
        18,
        15,
        38,
        15,
        29
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyAccepted": [
        7,
        19,
        23,
        27,
        10,
        0,
        49,
        49,
        48,
        22,
        9,
        9,
        43,
        19,
        1,
        0,
        42,
        48,
        11,
        28,
        39,
        7,
        39,
        24,
        0,
        24,
        33,
        10,
        1,
        49,
        17,
        26,
        49,
        21,
        11,
        4,
        47,
        22,
        49,
        26,
        24,
        44,
        19,
        9,
        49,
        28,
        6,
        11,
        25,
        49,
        0,
        29
      ],
      "weeklyRejected": [
        46,
        0,
        37,
        46,
        49,
        7,
        35,
        28,
        41,
        24,
        44,
        11,
        35,
        48,
        27,
        5,
        26,
        49,
        49,
        0,
        49,
        3,
        25,
        49,
        22,
        25,
        44,
        4,
        48,
        12,
        24,
        37,
        33,
        19,
        37,
        30,
        35,
        13,
        49,
        0,
        33,
        0,
        16,
        18,
        49,
        35,
        15,
        27,
        9,
        46,
        0,
        32
      ],
      "weeklyHold": [
        36,
        0,
        6,
        13,
        39,
        11,
        0,
        15,
        9,
        23,
        28,
        47,
        11,
        30,
        7,
        19,
        3,
        5,
        13,
        47,
        0,
        21,
        19,
        20,
        0,
        18,
        49,
        34,
        49,
        0,
        28,
        4,
        14,
        39,
        0,
        12,
        14,
        49,
        49,
        17,
        49,
        40,
        23,
        28,
        31,
        47,
        49,
        24,
        0,
        23,
        17,
        49
      ],
      "weeklyBackordered": [
        42,
        49,
        29,
        39,
        10,
        21,
        9,
        33,
        41,
        49,
        31,
        39,
        18,
        0,
        0,
        42,
        45,
        29,
        27,
        24,
        33,
        41,
        49,
        19,
        9,
        12,
        33,
        10,
        1,
        43,
        9,
        49,
        33,
        30,
        0,
        0,
        49,
        0,
        3,
        4,
        4,
        43,
        0,
        49,
        36,
        33,
        23,
        22,
        33,
        11,
        44,
        5
      ]
    },
    {
      "id": "BB20277D",
      "weeklyAccepted": [
        38,
        29,
        28,
        24,
        25,
        15,
        6,
        29,
        20,
        0,
        49,
        3,
        49,
        33,
        7,
        15,
        0,
        0,
        0,
        43,
        0,
        5,
        29,
        49,
        10,
        47,
        42,
        0,
        2,
        44,
        0,
        0,
        44,
        0,
        0,
        2,
        47,
        22,
        49,
        49,
        38,
        0,
        39,
        49,
        36,
        3,
        0,
        19,
        29,
        38,
        36,
        20
      ],
      "weeklyRejected": [
        49,
        17,
        23,
        47,
        45,
        48,
        10,
        17,
        43,
        38,
        0,
        36,
        22,
        48,
        15,
        46,
        0,
        41,
        16,
        6,
        3,
        17,
        47,
        3,
        42,
        49,
        8,
        11,
        46,
        0,
        47,
        44,
        40,
        34,
        21,
        49,
        23,
        42,
        1,
        49,
        42,
        16,
        6,
        15,
        45,
        41,
        1,
        20,
        28,
        49,
        0,
        24
      ],
      "weeklyHold": [
        19,
        5,
        49,
        4,
        36,
        44,
        26,
        2,
        0,
        0,
        12,
        4,
        48,
        45,
        10,
        14,
        0,
        0,
        11,
        27,
        4,
        22,
        5,
        49,
        38,
        1,
        16,
        20,
        34,
        1,
        40,
        48,
        33,
        13,
        17,
        17,
        0,
        29,
        20,
        19,
        39,
        49,
        0,
        36,
        41,
        38,
        49,
        23,
        0,
        40,
        30,
        19
      ],
      "weeklyBackordered": [
        20,
        21,
        0,
        5,
        49,
        23,
        49,
        28,
        25,
        1,
        14,
        31,
        3,
        42,
        24,
        41,
        33,
        49,
        0,
        18,
        49,
        19,
        18,
        3,
        32,
        49,
        13,
        5,
        41,
        49,
        0,
        15,
        0,
        19,
        1,
        0,
        1,
        11,
        27,
        36,
        10,
        48,
        0,
        49,
        31,
        27,
        41,
        29,
        41,
        38,
        44,
        19
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyAccepted": [
        11,
        38,
        16,
        46,
        48,
        18,
        44,
        46,
        32,
        36,
        24,
        47,
        48,
        36,
        30,
        46,
        47,
        15,
        13,
        39,
        10,
        4,
        19,
        22,
        27,
        42,
        17,
        16,
        27,
        49,
        25,
        0,
        24,
        6,
        48,
        4,
        46,
        13,
        9,
        27,
        0,
        49,
        13,
        49,
        4,
        42,
        18,
        19,
        5,
        15,
        34,
        6
      ],
      "weeklyRejected": [
        44,
        15,
        35,
        31,
        45,
        26,
        49,
        16,
        49,
        1,
        24,
        34,
        20,
        8,
        19,
        15,
        0,
        49,
        2,
        31,
        14,
        2,
        17,
        49,
        14,
        25,
        0,
        10,
        48,
        36,
        47,
        43,
        41,
        22,
        7,
        35,
        0,
        14,
        27,
        34,
        19,
        24,
        46,
        30,
        42,
        43,
        0,
        0,
        0,
        40,
        15,
        4
      ],
      "weeklyHold": [
        8,
        36,
        41,
        1,
        49,
        48,
        11,
        27,
        8,
        38,
        47,
        18,
        23,
        16,
        38,
        0,
        23,
        17,
        0,
        19,
        0,
        6,
        49,
        0,
        20,
        33,
        8,
        0,
        26,
        0,
        48,
        8,
        27,
        38,
        43,
        40,
        49,
        8,
        33,
        29,
        2,
        33,
        29,
        1,
        13,
        41,
        19,
        35,
        0,
        0,
        47,
        34
      ],
      "weeklyBackordered": [
        33,
        29,
        27,
        23,
        49,
        17,
        49,
        1,
        49,
        47,
        34,
        36,
        8,
        36,
        19,
        29,
        36,
        49,
        1,
        41,
        49,
        0,
        0,
        0,
        1,
        13,
        18,
        39,
        44,
        21,
        49,
        42,
        48,
        42,
        0,
        0,
        36,
        14,
        49,
        49,
        21,
        48,
        4,
        26,
        6,
        5,
        42,
        14,
        0,
        19,
        49,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        0,
        26,
        26,
        17,
        49,
        11,
        22,
        44,
        35,
        33,
        41,
        40,
        49,
        11,
        11,
        21,
        34,
        0,
        0,
        49,
        14,
        42,
        13,
        46,
        28,
        21,
        12,
        19,
        9,
        39,
        0,
        32,
        49,
        7,
        29,
        49,
        40,
        0,
        49,
        24,
        0,
        9,
        33,
        23,
        32,
        25,
        14,
        0,
        49,
        26,
        24,
        48
      ],
      "weeklyRejected": [
        41,
        12,
        49,
        47,
        48,
        25,
        40,
        21,
        26,
        15,
        49,
        41,
        24,
        25,
        2,
        19,
        0,
        39,
        8,
        27,
        41,
        32,
        24,
        31,
        40,
        49,
        34,
        29,
        38,
        0,
        46,
        43,
        41,
        15,
        0,
        30,
        12,
        0,
        9,
        21,
        20,
        41,
        49,
        0,
        49,
        14,
        18,
        26,
        5,
        8,
        0,
        30
      ],
      "weeklyHold": [
        0,
        22,
        36,
        1,
        36,
        14,
        49,
        10,
        23,
        0,
        5,
        35,
        48,
        49,
        22,
        22,
        18,
        17,
        7,
        46,
        35,
        31,
        49,
        47,
        1,
        35,
        17,
        43,
        19,
        20,
        48,
        28,
        0,
        48,
        12,
        27,
        33,
        0,
        48,
        12,
        29,
        31,
        13,
        0,
        19,
        22,
        28,
        28,
        0,
        0,
        24,
        39
      ],
      "weeklyBackordered": [
        46,
        3,
        0,
        5,
        40,
        25,
        30,
        15,
        24,
        1,
        20,
        40,
        29,
        49,
        47,
        6,
        32,
        38,
        12,
        11,
        45,
        7,
        30,
        49,
        1,
        11,
        1,
        20,
        1,
        13,
        36,
        30,
        30,
        49,
        23,
        0,
        13,
        6,
        23,
        41,
        34,
        42,
        4,
        49,
        25,
        21,
        45,
        29,
        25,
        35,
        38,
        48
      ]
    },
    {
      "id": "88F245C1",
      "weeklyAccepted": [
        16,
        32,
        24,
        40,
        1,
        25,
        20,
        22,
        42,
        11,
        49,
        22,
        49,
        7,
        46,
        0,
        49,
        0,
        27,
        26,
        0,
        46,
        43,
        32,
        37,
        14,
        14,
        15,
        1,
        32,
        13,
        22,
        49,
        49,
        48,
        40,
        35,
        8,
        49,
        20,
        0,
        2,
        16,
        39,
        38,
        10,
        0,
        0,
        0,
        35,
        26,
        11
      ],
      "weeklyRejected": [
        24,
        23,
        14,
        24,
        18,
        0,
        16,
        42,
        5,
        13,
        30,
        28,
        28,
        48,
        30,
        19,
        22,
        47,
        49,
        29,
        10,
        35,
        34,
        26,
        32,
        30,
        49,
        34,
        33,
        35,
        27,
        39,
        31,
        7,
        22,
        48,
        0,
        41,
        8,
        19,
        20,
        39,
        0,
        32,
        49,
        26,
        0,
        44,
        0,
        0,
        19,
        49
      ],
      "weeklyHold": [
        47,
        8,
        37,
        21,
        0,
        48,
        49,
        29,
        37,
        6,
        47,
        39,
        20,
        24,
        26,
        23,
        0,
        48,
        24,
        29,
        32,
        42,
        24,
        5,
        0,
        31,
        49,
        23,
        16,
        3,
        42,
        0,
        8,
        25,
        13,
        42,
        9,
        19,
        19,
        49,
        2,
        20,
        0,
        41,
        7,
        29,
        5,
        8,
        0,
        45,
        38,
        49
      ],
      "weeklyBackordered": [
        28,
        34,
        17,
        37,
        49,
        43,
        49,
        36,
        0,
        35,
        49,
        49,
        10,
        47,
        39,
        17,
        9,
        49,
        1,
        0,
        42,
        20,
        19,
        14,
        36,
        4,
        27,
        16,
        34,
        0,
        24,
        11,
        46,
        44,
        0,
        0,
        22,
        0,
        34,
        29,
        20,
        24,
        2,
        28,
        7,
        0,
        27,
        47,
        0,
        45,
        34,
        12
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        48,
        28,
        20,
        48,
        1,
        0,
        1,
        49,
        48,
        0,
        35,
        0,
        38,
        16,
        14,
        32,
        35,
        10,
        0,
        0,
        41,
        19,
        49,
        35,
        38,
        32,
        10,
        31,
        1,
        22,
        0,
        5,
        16,
        0,
        47,
        20,
        27,
        14,
        37,
        31,
        19,
        19,
        49,
        33,
        42,
        12,
        0,
        9,
        21,
        46,
        31,
        35
      ],
      "weeklyRejected": [
        17,
        17,
        30,
        40,
        40,
        35,
        49,
        20,
        22,
        1,
        20,
        0,
        49,
        36,
        48,
        16,
        43,
        36,
        36,
        23,
        49,
        23,
        0,
        20,
        25,
        49,
        26,
        27,
        31,
        16,
        11,
        47,
        48,
        49,
        30,
        49,
        5,
        8,
        27,
        0,
        14,
        33,
        6,
        0,
        6,
        0,
        3,
        41,
        5,
        20,
        0,
        14
      ],
      "weeklyHold": [
        48,
        48,
        46,
        28,
        20,
        40,
        24,
        22,
        20,
        49,
        47,
        49,
        13,
        3,
        49,
        44,
        11,
        29,
        3,
        34,
        23,
        6,
        46,
        49,
        4,
        15,
        9,
        22,
        39,
        5,
        40,
        19,
        32,
        28,
        48,
        12,
        40,
        5,
        4,
        47,
        0,
        12,
        28,
        0,
        23,
        16,
        5,
        46,
        0,
        0,
        0,
        29
      ],
      "weeklyBackordered": [
        14,
        27,
        13,
        39,
        41,
        35,
        34,
        30,
        49,
        0,
        0,
        49,
        3,
        13,
        30,
        49,
        23,
        33,
        0,
        26,
        49,
        35,
        25,
        12,
        1,
        35,
        21,
        49,
        25,
        27,
        30,
        0,
        14,
        49,
        0,
        40,
        1,
        3,
        11,
        38,
        29,
        48,
        44,
        49,
        30,
        0,
        20,
        0,
        49,
        49,
        30,
        7
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        7,
        18,
        25,
        40,
        21,
        3,
        17,
        5,
        22,
        32,
        48,
        13,
        49,
        22,
        21,
        46,
        49,
        13,
        0,
        22,
        45,
        27,
        10,
        33,
        14,
        44,
        1,
        22,
        1,
        14,
        22,
        0,
        49,
        0,
        39,
        2,
        9,
        12,
        49,
        49,
        5,
        26,
        12,
        28,
        26,
        41,
        0,
        49,
        9,
        49,
        19,
        1
      ],
      "weeklyRejected": [
        30,
        30,
        49,
        40,
        21,
        13,
        17,
        34,
        49,
        17,
        42,
        20,
        30,
        26,
        48,
        28,
        0,
        36,
        8,
        9,
        3,
        14,
        16,
        42,
        10,
        49,
        45,
        25,
        47,
        9,
        20,
        32,
        36,
        34,
        33,
        49,
        21,
        0,
        3,
        49,
        7,
        49,
        6,
        31,
        25,
        29,
        0,
        11,
        0,
        45,
        0,
        14
      ],
      "weeklyHold": [
        0,
        40,
        49,
        1,
        30,
        38,
        18,
        2,
        49,
        14,
        36,
        28,
        42,
        26,
        33,
        4,
        25,
        22,
        36,
        48,
        49,
        38,
        11,
        0,
        28,
        48,
        37,
        0,
        6,
        49,
        39,
        0,
        10,
        25,
        2,
        9,
        22,
        0,
        27,
        49,
        48,
        17,
        21,
        43,
        11,
        26,
        37,
        44,
        0,
        24,
        28,
        32
      ],
      "weeklyBackordered": [
        25,
        17,
        11,
        33,
        33,
        46,
        48,
        43,
        40,
        17,
        26,
        46,
        43,
        34,
        16,
        12,
        40,
        25,
        5,
        0,
        43,
        21,
        28,
        0,
        31,
        2,
        30,
        27,
        49,
        35,
        35,
        41,
        19,
        35,
        0,
        11,
        1,
        22,
        9,
        23,
        48,
        48,
        11,
        49,
        15,
        0,
        30,
        49,
        0,
        49,
        28,
        14
      ]
    },
    {
      "id": "DB301E71",
      "weeklyAccepted": [
        0,
        38,
        49,
        15,
        49,
        3,
        22,
        49,
        9,
        20,
        40,
        6,
        12,
        49,
        1,
        49,
        0,
        0,
        49,
        13,
        7,
        47,
        30,
        30,
        0,
        0,
        11,
        40,
        20,
        1,
        49,
        10,
        32,
        47,
        43,
        34,
        23,
        48,
        2,
        49,
        12,
        49,
        20,
        37,
        34,
        0,
        28,
        41,
        10,
        17,
        3,
        0
      ],
      "weeklyRejected": [
        44,
        8,
        32,
        47,
        0,
        34,
        42,
        34,
        49,
        13,
        17,
        44,
        25,
        11,
        48,
        32,
        12,
        35,
        29,
        0,
        23,
        49,
        14,
        49,
        49,
        42,
        18,
        13,
        48,
        31,
        49,
        47,
        29,
        49,
        26,
        49,
        27,
        40,
        1,
        6,
        36,
        49,
        30,
        15,
        49,
        20,
        12,
        0,
        7,
        49,
        0,
        21
      ],
      "weeklyHold": [
        49,
        29,
        49,
        7,
        22,
        0,
        45,
        2,
        15,
        4,
        39,
        49,
        39,
        1,
        49,
        0,
        18,
        39,
        48,
        19,
        0,
        49,
        15,
        0,
        39,
        48,
        49,
        14,
        46,
        0,
        13,
        0,
        0,
        45,
        0,
        34,
        49,
        8,
        9,
        28,
        27,
        49,
        26,
        49,
        33,
        42,
        0,
        37,
        7,
        41,
        7,
        19
      ],
      "weeklyBackordered": [
        33,
        49,
        19,
        34,
        45,
        25,
        33,
        49,
        34,
        48,
        32,
        32,
        47,
        28,
        49,
        26,
        44,
        43,
        0,
        17,
        13,
        27,
        15,
        6,
        35,
        1,
        11,
        12,
        47,
        49,
        40,
        49,
        41,
        35,
        27,
        0,
        20,
        0,
        39,
        49,
        48,
        48,
        0,
        49,
        20,
        40,
        49,
        28,
        0,
        5,
        49,
        23
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        0,
        49,
        49,
        23,
        46,
        7,
        1,
        42,
        0,
        0,
        49,
        43,
        18,
        49,
        1,
        49,
        47,
        23,
        0,
        49,
        49,
        5,
        0,
        18,
        8,
        47,
        23,
        20,
        37,
        23,
        28,
        7,
        49,
        27,
        1,
        18,
        26,
        27,
        48,
        49,
        8,
        3,
        49,
        42,
        40,
        0,
        0,
        34,
        46,
        27,
        35,
        16
      ],
      "weeklyRejected": [
        49,
        14,
        5,
        20,
        21,
        27,
        12,
        19,
        43,
        28,
        19,
        19,
        26,
        23,
        29,
        14,
        39,
        41,
        7,
        49,
        24,
        42,
        25,
        14,
        18,
        26,
        34,
        13,
        48,
        2,
        49,
        1,
        21,
        49,
        0,
        49,
        45,
        0,
        17,
        11,
        33,
        0,
        2,
        22,
        44,
        49,
        22,
        0,
        3,
        37,
        26,
        49
      ],
      "weeklyHold": [
        5,
        0,
        40,
        1,
        48,
        32,
        25,
        2,
        9,
        49,
        32,
        47,
        37,
        36,
        40,
        2,
        13,
        5,
        23,
        24,
        0,
        0,
        49,
        0,
        49,
        48,
        0,
        7,
        49,
        7,
        33,
        7,
        25,
        33,
        48,
        31,
        49,
        30,
        30,
        0,
        13,
        35,
        26,
        28,
        29,
        49,
        1,
        0,
        0,
        49,
        0,
        43
      ],
      "weeklyBackordered": [
        0,
        3,
        0,
        38,
        43,
        22,
        22,
        48,
        33,
        0,
        23,
        41,
        20,
        41,
        0,
        27,
        49,
        33,
        27,
        2,
        28,
        0,
        17,
        20,
        2,
        14,
        49,
        34,
        5,
        32,
        49,
        0,
        44,
        31,
        0,
        0,
        8,
        37,
        3,
        29,
        17,
        12,
        0,
        49,
        31,
        20,
        31,
        24,
        21,
        36,
        43,
        3
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        3,
        40,
        18,
        14,
        48,
        0,
        37,
        34,
        39,
        6,
        37,
        21,
        13,
        13,
        49,
        20,
        49,
        0,
        33,
        21,
        28,
        9,
        49,
        31,
        49,
        47,
        10,
        49,
        30,
        28,
        8,
        40,
        41,
        6,
        26,
        18,
        35,
        12,
        34,
        49,
        26,
        40,
        43,
        35,
        49,
        0,
        11,
        2,
        0,
        45,
        49,
        44
      ],
      "weeklyRejected": [
        44,
        26,
        22,
        35,
        26,
        43,
        29,
        46,
        35,
        1,
        1,
        31,
        22,
        19,
        46,
        14,
        25,
        49,
        7,
        19,
        8,
        34,
        49,
        49,
        29,
        49,
        45,
        23,
        0,
        38,
        49,
        24,
        48,
        29,
        13,
        15,
        49,
        49,
        1,
        49,
        34,
        28,
        21,
        7,
        33,
        27,
        0,
        8,
        0,
        30,
        18,
        49
      ],
      "weeklyHold": [
        4,
        8,
        42,
        2,
        0,
        48,
        24,
        2,
        29,
        14,
        35,
        5,
        33,
        25,
        32,
        18,
        16,
        36,
        46,
        0,
        39,
        41,
        38,
        18,
        27,
        22,
        0,
        0,
        49,
        13,
        48,
        0,
        3,
        40,
        1,
        14,
        7,
        12,
        15,
        25,
        25,
        49,
        0,
        49,
        16,
        0,
        34,
        35,
        37,
        49,
        31,
        14
      ],
      "weeklyBackordered": [
        8,
        8,
        17,
        36,
        49,
        22,
        34,
        46,
        35,
        0,
        3,
        35,
        49,
        17,
        42,
        17,
        10,
        33,
        20,
        0,
        15,
        35,
        6,
        9,
        5,
        25,
        31,
        40,
        2,
        38,
        22,
        17,
        40,
        49,
        8,
        0,
        11,
        0,
        49,
        0,
        17,
        15,
        10,
        23,
        49,
        20,
        28,
        28,
        18,
        49,
        39,
        3
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        2,
        23,
        49,
        20,
        41,
        5,
        49,
        24,
        48,
        2,
        38,
        0,
        28,
        49,
        11,
        49,
        42,
        28,
        0,
        42,
        39,
        28,
        49,
        49,
        24,
        45,
        14,
        49,
        49,
        34,
        37,
        0,
        20,
        3,
        27,
        20,
        34,
        41,
        49,
        49,
        10,
        22,
        49,
        26,
        49,
        42,
        15,
        23,
        0,
        29,
        38,
        0
      ],
      "weeklyRejected": [
        49,
        31,
        17,
        36,
        49,
        34,
        18,
        17,
        20,
        1,
        26,
        26,
        29,
        48,
        7,
        49,
        11,
        49,
        23,
        23,
        26,
        49,
        8,
        15,
        49,
        49,
        3,
        6,
        27,
        7,
        18,
        23,
        32,
        16,
        24,
        31,
        24,
        0,
        18,
        23,
        17,
        49,
        48,
        0,
        42,
        25,
        9,
        10,
        25,
        0,
        34,
        35
      ],
      "weeklyHold": [
        49,
        12,
        24,
        15,
        49,
        31,
        36,
        2,
        2,
        31,
        10,
        26,
        28,
        28,
        39,
        33,
        24,
        19,
        1,
        14,
        22,
        49,
        48,
        49,
        8,
        38,
        37,
        16,
        0,
        0,
        39,
        1,
        3,
        45,
        48,
        0,
        24,
        27,
        46,
        49,
        12,
        19,
        49,
        18,
        49,
        49,
        37,
        29,
        24,
        49,
        29,
        49
      ],
      "weeklyBackordered": [
        49,
        37,
        1,
        40,
        46,
        46,
        29,
        49,
        0,
        17,
        41,
        26,
        3,
        0,
        37,
        49,
        11,
        6,
        17,
        1,
        44,
        49,
        31,
        0,
        12,
        1,
        42,
        0,
        12,
        48,
        36,
        2,
        15,
        21,
        0,
        20,
        49,
        6,
        44,
        9,
        38,
        39,
        6,
        49,
        41,
        49,
        39,
        4,
        0,
        46,
        49,
        41
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        24,
        8,
        49,
        48,
        49,
        0,
        1,
        34,
        48,
        25,
        10,
        26,
        44,
        36,
        20,
        47,
        40,
        36,
        0,
        9,
        5,
        31,
        46,
        38,
        29,
        33,
        0,
        23,
        6,
        19,
        20,
        21,
        38,
        49,
        11,
        21,
        0,
        9,
        10,
        49,
        22,
        18,
        43,
        0,
        12,
        0,
        2,
        10,
        31,
        28,
        23,
        44
      ],
      "weeklyRejected": [
        48,
        17,
        48,
        41,
        5,
        4,
        29,
        19,
        38,
        35,
        49,
        7,
        30,
        17,
        32,
        0,
        49,
        32,
        49,
        49,
        0,
        16,
        21,
        43,
        31,
        22,
        29,
        45,
        38,
        7,
        38,
        21,
        0,
        17,
        20,
        49,
        23,
        30,
        7,
        14,
        45,
        41,
        14,
        38,
        45,
        9,
        14,
        0,
        0,
        49,
        11,
        36
      ],
      "weeklyHold": [
        17,
        0,
        35,
        40,
        49,
        43,
        18,
        14,
        37,
        8,
        26,
        6,
        1,
        6,
        0,
        0,
        7,
        14,
        13,
        0,
        13,
        49,
        3,
        33,
        29,
        38,
        0,
        19,
        49,
        4,
        39,
        6,
        0,
        10,
        48,
        42,
        4,
        14,
        1,
        24,
        10,
        49,
        26,
        49,
        49,
        22,
        22,
        49,
        38,
        35,
        11,
        33
      ],
      "weeklyBackordered": [
        47,
        0,
        47,
        35,
        27,
        19,
        26,
        38,
        0,
        48,
        29,
        26,
        3,
        0,
        5,
        19,
        9,
        0,
        17,
        25,
        49,
        9,
        28,
        36,
        1,
        29,
        13,
        17,
        38,
        49,
        43,
        39,
        5,
        16,
        0,
        27,
        49,
        9,
        37,
        19,
        13,
        48,
        1,
        49,
        5,
        5,
        49,
        11,
        49,
        35,
        47,
        45
      ]
    },
    {
      "id": "F04529EA",
      "weeklyAccepted": [
        12,
        49,
        13,
        0,
        1,
        13,
        3,
        38,
        21,
        13,
        42,
        0,
        9,
        24,
        11,
        49,
        49,
        0,
        38,
        11,
        0,
        0,
        26,
        5,
        42,
        47,
        49,
        49,
        34,
        12,
        0,
        0,
        42,
        30,
        42,
        49,
        25,
        24,
        27,
        49,
        28,
        46,
        49,
        36,
        37,
        26,
        0,
        0,
        17,
        24,
        5,
        2
      ],
      "weeklyRejected": [
        49,
        20,
        25,
        25,
        23,
        29,
        49,
        21,
        7,
        27,
        21,
        16,
        23,
        48,
        20,
        22,
        24,
        49,
        45,
        0,
        47,
        0,
        4,
        45,
        26,
        49,
        39,
        28,
        41,
        13,
        49,
        46,
        47,
        4,
        17,
        49,
        47,
        49,
        8,
        0,
        3,
        0,
        40,
        0,
        33,
        13,
        0,
        12,
        0,
        27,
        30,
        34
      ],
      "weeklyHold": [
        28,
        16,
        49,
        39,
        32,
        30,
        34,
        4,
        0,
        18,
        21,
        1,
        26,
        1,
        34,
        16,
        0,
        45,
        28,
        0,
        49,
        29,
        24,
        8,
        10,
        17,
        35,
        2,
        36,
        0,
        48,
        5,
        8,
        25,
        24,
        20,
        32,
        29,
        25,
        49,
        29,
        44,
        0,
        21,
        49,
        33,
        38,
        34,
        17,
        49,
        11,
        47
      ],
      "weeklyBackordered": [
        49,
        0,
        0,
        34,
        49,
        23,
        0,
        49,
        40,
        23,
        36,
        49,
        3,
        30,
        23,
        49,
        38,
        44,
        0,
        15,
        34,
        14,
        8,
        1,
        42,
        26,
        7,
        45,
        1,
        49,
        0,
        13,
        35,
        12,
        0,
        0,
        1,
        13,
        45,
        0,
        6,
        30,
        0,
        34,
        33,
        18,
        34,
        0,
        23,
        24,
        35,
        27
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        15,
        3,
        12,
        48,
        3,
        12,
        16,
        7,
        41,
        26,
        39,
        0,
        19,
        43,
        8,
        15,
        41,
        47,
        0,
        49,
        49,
        36,
        4,
        24,
        0,
        47,
        20,
        49,
        31,
        21,
        39,
        11,
        4,
        40,
        28,
        29,
        45,
        48,
        26,
        44,
        33,
        49,
        28,
        8,
        14,
        14,
        16,
        39,
        7,
        37,
        33,
        33
      ],
      "weeklyRejected": [
        39,
        33,
        38,
        21,
        32,
        0,
        49,
        27,
        49,
        12,
        18,
        26,
        0,
        27,
        19,
        0,
        45,
        49,
        27,
        49,
        19,
        40,
        0,
        11,
        0,
        33,
        41,
        33,
        48,
        45,
        47,
        28,
        8,
        27,
        40,
        32,
        45,
        0,
        11,
        5,
        31,
        13,
        23,
        15,
        47,
        0,
        48,
        24,
        48,
        49,
        9,
        44
      ],
      "weeklyHold": [
        48,
        32,
        35,
        1,
        31,
        42,
        0,
        5,
        15,
        27,
        36,
        41,
        48,
        27,
        44,
        17,
        12,
        48,
        32,
        32,
        0,
        39,
        19,
        26,
        27,
        17,
        2,
        9,
        49,
        2,
        34,
        17,
        15,
        30,
        13,
        42,
        19,
        33,
        49,
        34,
        0,
        31,
        31,
        25,
        25,
        43,
        47,
        48,
        12,
        35,
        49,
        32
      ],
      "weeklyBackordered": [
        10,
        39,
        0,
        17,
        19,
        33,
        22,
        29,
        26,
        15,
        32,
        26,
        21,
        42,
        33,
        28,
        40,
        0,
        0,
        4,
        45,
        32,
        13,
        49,
        10,
        2,
        29,
        19,
        7,
        43,
        34,
        49,
        18,
        26,
        0,
        10,
        49,
        16,
        23,
        18,
        29,
        41,
        30,
        49,
        15,
        18,
        49,
        0,
        24,
        30,
        45,
        11
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        0,
        24,
        49,
        25,
        29,
        9,
        24,
        40,
        23,
        18,
        45,
        17,
        39,
        8,
        4,
        23,
        44,
        43,
        27,
        33,
        17,
        21,
        8,
        23,
        28,
        25,
        21,
        37,
        9,
        49,
        42,
        21,
        47,
        10,
        44,
        2,
        33,
        38,
        11,
        49,
        0,
        35,
        45,
        36,
        45,
        21,
        1,
        9,
        0,
        47,
        7,
        46
      ],
      "weeklyRejected": [
        34,
        0,
        20,
        32,
        41,
        3,
        6,
        41,
        36,
        8,
        25,
        49,
        35,
        19,
        36,
        17,
        13,
        24,
        40,
        19,
        46,
        29,
        35,
        49,
        49,
        42,
        35,
        43,
        48,
        30,
        33,
        24,
        46,
        27,
        17,
        43,
        25,
        37,
        1,
        12,
        20,
        36,
        13,
        25,
        49,
        39,
        8,
        29,
        0,
        46,
        0,
        49
      ],
      "weeklyHold": [
        13,
        10,
        35,
        11,
        14,
        32,
        49,
        22,
        35,
        30,
        13,
        22,
        37,
        30,
        21,
        42,
        0,
        33,
        40,
        2,
        38,
        29,
        12,
        28,
        12,
        15,
        0,
        29,
        46,
        10,
        48,
        30,
        29,
        0,
        48,
        22,
        24,
        32,
        45,
        31,
        39,
        18,
        18,
        49,
        38,
        3,
        37,
        22,
        25,
        27,
        0,
        41
      ],
      "weeklyBackordered": [
        49,
        44,
        0,
        49,
        49,
        15,
        49,
        46,
        46,
        47,
        49,
        24,
        8,
        25,
        0,
        17,
        25,
        14,
        41,
        37,
        49,
        0,
        7,
        0,
        18,
        38,
        32,
        49,
        13,
        49,
        39,
        31,
        43,
        48,
        24,
        0,
        26,
        0,
        22,
        18,
        7,
        25,
        31,
        13,
        9,
        49,
        16,
        20,
        23,
        49,
        43,
        15
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        9,
        28,
        20,
        0,
        41,
        19,
        49,
        49,
        30,
        30,
        29,
        1,
        34,
        25,
        28,
        0,
        49,
        0,
        37,
        32,
        49,
        0,
        20,
        35,
        6,
        4,
        0,
        33,
        46,
        49,
        18,
        0,
        49,
        45,
        35,
        32,
        33,
        48,
        29,
        49,
        25,
        49,
        49,
        49,
        48,
        0,
        0,
        25,
        4,
        41,
        13,
        48
      ],
      "weeklyRejected": [
        38,
        22,
        9,
        0,
        14,
        27,
        14,
        17,
        32,
        29,
        25,
        16,
        24,
        25,
        41,
        20,
        24,
        28,
        49,
        43,
        17,
        49,
        1,
        49,
        29,
        22,
        49,
        23,
        37,
        48,
        49,
        36,
        15,
        20,
        24,
        6,
        4,
        32,
        14,
        30,
        13,
        21,
        27,
        15,
        39,
        46,
        38,
        36,
        13,
        13,
        1,
        33
      ],
      "weeklyHold": [
        16,
        0,
        49,
        19,
        49,
        1,
        43,
        2,
        22,
        33,
        34,
        24,
        17,
        49,
        7,
        34,
        18,
        48,
        42,
        0,
        0,
        0,
        40,
        13,
        22,
        48,
        0,
        0,
        49,
        28,
        36,
        6,
        0,
        32,
        18,
        49,
        47,
        44,
        44,
        42,
        33,
        30,
        9,
        25,
        37,
        11,
        0,
        17,
        12,
        40,
        34,
        35
      ],
      "weeklyBackordered": [
        0,
        49,
        4,
        21,
        31,
        18,
        26,
        33,
        22,
        19,
        48,
        7,
        3,
        0,
        42,
        36,
        39,
        0,
        23,
        0,
        37,
        40,
        7,
        15,
        38,
        1,
        13,
        5,
        11,
        49,
        31,
        49,
        7,
        43,
        0,
        0,
        26,
        30,
        45,
        22,
        42,
        12,
        11,
        29,
        41,
        37,
        11,
        2,
        22,
        12,
        14,
        32
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        46,
        29,
        33,
        48,
        44,
        23,
        32,
        42,
        36,
        38,
        41,
        10,
        32,
        29,
        6,
        23,
        49,
        33,
        14,
        15,
        33,
        3,
        2,
        22,
        49,
        35,
        31,
        49,
        6,
        42,
        42,
        14,
        20,
        49,
        48,
        28,
        33,
        39,
        0,
        49,
        34,
        30,
        27,
        20,
        49,
        29,
        0,
        6,
        49,
        49,
        8,
        48
      ],
      "weeklyRejected": [
        43,
        8,
        49,
        45,
        43,
        10,
        0,
        49,
        49,
        1,
        25,
        33,
        13,
        40,
        44,
        0,
        0,
        47,
        12,
        14,
        0,
        10,
        0,
        48,
        35,
        33,
        46,
        35,
        48,
        48,
        36,
        23,
        46,
        39,
        48,
        49,
        42,
        38,
        1,
        17,
        35,
        0,
        31,
        16,
        29,
        45,
        1,
        20,
        38,
        45,
        1,
        16
      ],
      "weeklyHold": [
        38,
        0,
        47,
        19,
        49,
        48,
        49,
        21,
        20,
        38,
        41,
        27,
        28,
        39,
        41,
        18,
        6,
        27,
        0,
        13,
        0,
        27,
        15,
        12,
        7,
        44,
        31,
        7,
        4,
        0,
        26,
        2,
        20,
        49,
        44,
        49,
        45,
        10,
        29,
        34,
        0,
        11,
        15,
        0,
        23,
        39,
        38,
        45,
        7,
        29,
        43,
        42
      ],
      "weeklyBackordered": [
        0,
        0,
        17,
        6,
        21,
        27,
        32,
        45,
        0,
        14,
        0,
        28,
        35,
        24,
        23,
        27,
        45,
        49,
        0,
        8,
        20,
        7,
        0,
        39,
        48,
        13,
        46,
        39,
        1,
        36,
        49,
        11,
        12,
        49,
        0,
        0,
        10,
        11,
        8,
        24,
        29,
        48,
        9,
        49,
        5,
        11,
        49,
        0,
        0,
        29,
        41,
        22
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        4,
        36,
        0,
        18,
        49,
        0,
        27,
        43,
        30,
        24,
        33,
        5,
        14,
        49,
        27,
        18,
        49,
        18,
        43,
        40,
        23,
        23,
        8,
        49,
        20,
        10,
        49,
        23,
        17,
        36,
        23,
        30,
        35,
        27,
        11,
        13,
        22,
        28,
        49,
        19,
        28,
        13,
        33,
        31,
        2,
        0,
        18,
        25,
        12,
        30,
        1,
        14
      ],
      "weeklyRejected": [
        49,
        0,
        11,
        20,
        34,
        31,
        35,
        24,
        48,
        1,
        16,
        49,
        0,
        35,
        48,
        17,
        33,
        39,
        10,
        0,
        14,
        49,
        35,
        49,
        22,
        28,
        46,
        13,
        6,
        17,
        15,
        12,
        28,
        0,
        28,
        37,
        44,
        16,
        34,
        0,
        17,
        30,
        27,
        0,
        49,
        23,
        13,
        11,
        8,
        22,
        28,
        32
      ],
      "weeklyHold": [
        0,
        8,
        49,
        17,
        49,
        17,
        28,
        2,
        0,
        4,
        39,
        39,
        24,
        15,
        44,
        7,
        20,
        0,
        42,
        39,
        44,
        28,
        11,
        2,
        16,
        41,
        28,
        8,
        0,
        14,
        48,
        4,
        0,
        0,
        48,
        15,
        40,
        30,
        33,
        28,
        29,
        30,
        23,
        28,
        32,
        0,
        49,
        49,
        5,
        37,
        5,
        39
      ],
      "weeklyBackordered": [
        24,
        2,
        0,
        0,
        23,
        26,
        37,
        23,
        49,
        35,
        36,
        34,
        22,
        49,
        20,
        37,
        45,
        49,
        25,
        11,
        22,
        49,
        47,
        39,
        28,
        30,
        9,
        36,
        10,
        49,
        4,
        49,
        0,
        45,
        25,
        9,
        1,
        10,
        14,
        3,
        35,
        48,
        0,
        31,
        0,
        46,
        47,
        13,
        0,
        16,
        46,
        19
      ]
    },
    {
      "id": "58D36D79",
      "weeklyAccepted": [
        13,
        1,
        49,
        24,
        18,
        7,
        42,
        2,
        3,
        33,
        13,
        17,
        49,
        41,
        17,
        28,
        48,
        29,
        11,
        49,
        0,
        46,
        9,
        35,
        24,
        31,
        35,
        0,
        1,
        1,
        0,
        2,
        21,
        0,
        48,
        41,
        37,
        48,
        33,
        49,
        0,
        39,
        49,
        44,
        0,
        46,
        0,
        0,
        3,
        0,
        26,
        31
      ],
      "weeklyRejected": [
        34,
        8,
        19,
        24,
        42,
        12,
        17,
        46,
        19,
        1,
        0,
        10,
        48,
        47,
        45,
        49,
        13,
        44,
        30,
        11,
        23,
        24,
        49,
        49,
        32,
        35,
        45,
        1,
        29,
        4,
        0,
        26,
        16,
        49,
        28,
        49,
        11,
        29,
        1,
        30,
        17,
        21,
        30,
        34,
        49,
        40,
        0,
        22,
        5,
        45,
        0,
        49
      ],
      "weeklyHold": [
        1,
        13,
        30,
        12,
        49,
        48,
        0,
        49,
        23,
        18,
        35,
        37,
        32,
        22,
        38,
        22,
        0,
        37,
        10,
        37,
        34,
        6,
        29,
        32,
        49,
        31,
        9,
        9,
        33,
        0,
        41,
        5,
        23,
        49,
        12,
        49,
        42,
        49,
        29,
        40,
        0,
        35,
        0,
        49,
        0,
        26,
        24,
        42,
        19,
        42,
        35,
        49
      ],
      "weeklyBackordered": [
        49,
        0,
        22,
        18,
        49,
        28,
        38,
        45,
        0,
        9,
        38,
        24,
        3,
        49,
        0,
        3,
        49,
        40,
        15,
        11,
        37,
        35,
        0,
        5,
        13,
        7,
        12,
        7,
        33,
        33,
        0,
        1,
        48,
        49,
        18,
        0,
        3,
        3,
        49,
        41,
        25,
        48,
        7,
        23,
        12,
        43,
        7,
        10,
        11,
        49,
        48,
        32
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyAccepted": [
        3,
        49,
        24,
        0,
        38,
        42,
        2,
        49,
        37,
        49,
        13,
        26,
        16,
        22,
        33,
        0,
        49,
        22,
        0,
        38,
        31,
        24,
        49,
        7,
        4,
        43,
        33,
        36,
        19,
        27,
        0,
        0,
        24,
        49,
        48,
        49,
        41,
        26,
        49,
        27,
        47,
        42,
        49,
        45,
        49,
        45,
        10,
        18,
        26,
        49,
        26,
        48
      ],
      "weeklyRejected": [
        49,
        26,
        49,
        0,
        32,
        26,
        49,
        0,
        49,
        32,
        49,
        0,
        6,
        34,
        43,
        14,
        27,
        14,
        49,
        11,
        0,
        49,
        34,
        24,
        21,
        38,
        20,
        46,
        31,
        26,
        28,
        36,
        47,
        22,
        0,
        31,
        49,
        0,
        4,
        3,
        41,
        10,
        49,
        47,
        42,
        49,
        33,
        49,
        36,
        25,
        29,
        19
      ],
      "weeklyHold": [
        0,
        4,
        43,
        1,
        49,
        32,
        31,
        34,
        0,
        2,
        9,
        12,
        24,
        35,
        4,
        10,
        0,
        0,
        29,
        32,
        12,
        37,
        4,
        33,
        0,
        39,
        11,
        40,
        41,
        47,
        24,
        1,
        48,
        42,
        17,
        49,
        3,
        22,
        41,
        48,
        43,
        29,
        13,
        15,
        49,
        19,
        49,
        0,
        9,
        23,
        49,
        20
      ],
      "weeklyBackordered": [
        22,
        43,
        29,
        26,
        36,
        43,
        34,
        38,
        0,
        0,
        49,
        36,
        32,
        4,
        33,
        17,
        11,
        49,
        0,
        29,
        35,
        26,
        18,
        25,
        1,
        8,
        6,
        6,
        1,
        28,
        26,
        41,
        2,
        49,
        0,
        0,
        49,
        16,
        23,
        34,
        0,
        23,
        5,
        49,
        37,
        38,
        0,
        28,
        8,
        12,
        38,
        33
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        32,
        49,
        30,
        19,
        49,
        15,
        20,
        49,
        48,
        0,
        49,
        31,
        16,
        40,
        13,
        0,
        44,
        0,
        0,
        29,
        41,
        0,
        49,
        14,
        38,
        42,
        26,
        39,
        18,
        49,
        38,
        0,
        37,
        0,
        48,
        44,
        19,
        48,
        33,
        49,
        49,
        41,
        35,
        23,
        49,
        34,
        14,
        32,
        49,
        33,
        23,
        3
      ],
      "weeklyRejected": [
        40,
        21,
        49,
        0,
        14,
        25,
        37,
        49,
        22,
        3,
        49,
        26,
        40,
        48,
        42,
        31,
        0,
        49,
        13,
        19,
        49,
        19,
        10,
        49,
        40,
        16,
        49,
        13,
        45,
        26,
        15,
        25,
        42,
        49,
        45,
        49,
        0,
        35,
        19,
        18,
        35,
        49,
        49,
        14,
        29,
        14,
        7,
        11,
        0,
        5,
        0,
        49
      ],
      "weeklyHold": [
        49,
        16,
        33,
        1,
        35,
        32,
        49,
        4,
        38,
        31,
        35,
        2,
        9,
        25,
        49,
        19,
        0,
        48,
        49,
        36,
        30,
        13,
        25,
        26,
        2,
        44,
        14,
        15,
        0,
        0,
        23,
        0,
        28,
        14,
        48,
        1,
        48,
        3,
        49,
        49,
        8,
        39,
        0,
        24,
        49,
        37,
        49,
        29,
        11,
        28,
        41,
        19
      ],
      "weeklyBackordered": [
        31,
        18,
        26,
        7,
        28,
        46,
        49,
        49,
        0,
        0,
        13,
        11,
        32,
        11,
        29,
        30,
        33,
        49,
        0,
        7,
        22,
        42,
        1,
        0,
        16,
        18,
        49,
        17,
        1,
        45,
        34,
        32,
        48,
        49,
        12,
        7,
        1,
        0,
        49,
        49,
        33,
        48,
        0,
        48,
        0,
        2,
        19,
        5,
        25,
        47,
        41,
        14
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyAccepted": [
        15,
        9,
        49,
        0,
        2,
        22,
        9,
        18,
        34,
        17,
        49,
        24,
        29,
        10,
        14,
        16,
        0,
        35,
        30,
        33,
        2,
        42,
        26,
        37,
        39,
        24,
        13,
        8,
        49,
        48,
        16,
        0,
        31,
        44,
        35,
        22,
        23,
        48,
        12,
        49,
        25,
        49,
        41,
        0,
        40,
        3,
        10,
        13,
        0,
        44,
        0,
        15
      ],
      "weeklyRejected": [
        49,
        14,
        29,
        34,
        0,
        25,
        33,
        3,
        7,
        19,
        16,
        27,
        41,
        28,
        42,
        18,
        0,
        46,
        28,
        30,
        49,
        45,
        9,
        28,
        20,
        33,
        14,
        21,
        48,
        0,
        45,
        27,
        0,
        38,
        49,
        33,
        25,
        38,
        12,
        14,
        0,
        38,
        41,
        6,
        49,
        47,
        0,
        49,
        6,
        29,
        9,
        49
      ],
      "weeklyHold": [
        43,
        12,
        33,
        19,
        0,
        6,
        36,
        14,
        0,
        0,
        27,
        49,
        48,
        29,
        35,
        24,
        9,
        27,
        1,
        22,
        0,
        26,
        42,
        27,
        0,
        7,
        33,
        0,
        10,
        0,
        20,
        0,
        22,
        49,
        19,
        43,
        44,
        0,
        37,
        41,
        0,
        31,
        44,
        37,
        5,
        36,
        31,
        40,
        0,
        0,
        6,
        2
      ],
      "weeklyBackordered": [
        34,
        30,
        6,
        43,
        29,
        34,
        46,
        29,
        19,
        49,
        44,
        0,
        25,
        40,
        26,
        4,
        13,
        29,
        5,
        49,
        42,
        13,
        19,
        26,
        18,
        1,
        22,
        5,
        11,
        38,
        43,
        27,
        0,
        49,
        0,
        0,
        14,
        19,
        49,
        49,
        30,
        48,
        6,
        28,
        40,
        31,
        41,
        0,
        49,
        32,
        27,
        10
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        31,
        47,
        32,
        42,
        10,
        1,
        1,
        27,
        47,
        49,
        49,
        49,
        44,
        49,
        24,
        49,
        43,
        35,
        15,
        26,
        0,
        25,
        31,
        41,
        29,
        43,
        35,
        12,
        33,
        49,
        0,
        0,
        46,
        30,
        20,
        13,
        33,
        48,
        49,
        49,
        8,
        49,
        49,
        0,
        49,
        0,
        4,
        0,
        0,
        0,
        0,
        48
      ],
      "weeklyRejected": [
        45,
        44,
        39,
        47,
        33,
        0,
        21,
        19,
        49,
        15,
        37,
        21,
        23,
        0,
        35,
        12,
        19,
        35,
        47,
        49,
        49,
        24,
        28,
        11,
        14,
        26,
        45,
        46,
        48,
        21,
        49,
        47,
        25,
        49,
        35,
        28,
        39,
        18,
        1,
        34,
        16,
        44,
        42,
        21,
        34,
        49,
        18,
        0,
        16,
        49,
        5,
        49
      ],
      "weeklyHold": [
        22,
        14,
        40,
        1,
        36,
        17,
        44,
        2,
        0,
        30,
        47,
        49,
        48,
        21,
        49,
        44,
        20,
        48,
        0,
        15,
        32,
        22,
        31,
        19,
        16,
        44,
        0,
        5,
        48,
        4,
        22,
        3,
        0,
        14,
        24,
        0,
        36,
        11,
        1,
        34,
        6,
        49,
        21,
        17,
        0,
        49,
        13,
        38,
        0,
        49,
        22,
        18
      ],
      "weeklyBackordered": [
        8,
        49,
        6,
        0,
        33,
        46,
        49,
        45,
        49,
        0,
        41,
        11,
        49,
        43,
        49,
        25,
        20,
        49,
        0,
        41,
        38,
        3,
        0,
        2,
        37,
        2,
        36,
        0,
        5,
        20,
        39,
        19,
        0,
        45,
        0,
        13,
        1,
        6,
        49,
        32,
        13,
        48,
        5,
        49,
        15,
        5,
        37,
        14,
        13,
        12,
        49,
        28
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        0,
        32,
        40,
        0,
        33,
        16,
        1,
        47,
        36,
        0,
        49,
        18,
        20,
        1,
        24,
        22,
        36,
        17,
        5,
        19,
        17,
        5,
        0,
        44,
        24,
        18,
        34,
        18,
        24,
        40,
        30,
        3,
        30,
        0,
        33,
        44,
        29,
        42,
        49,
        49,
        31,
        36,
        22,
        39,
        49,
        33,
        23,
        27,
        1,
        49,
        49,
        36
      ],
      "weeklyRejected": [
        46,
        0,
        46,
        15,
        37,
        14,
        0,
        40,
        39,
        48,
        26,
        49,
        35,
        27,
        16,
        33,
        26,
        48,
        43,
        0,
        17,
        49,
        20,
        49,
        0,
        49,
        18,
        0,
        8,
        21,
        7,
        29,
        48,
        49,
        29,
        49,
        49,
        23,
        1,
        28,
        48,
        49,
        2,
        0,
        49,
        0,
        23,
        49,
        46,
        36,
        14,
        39
      ],
      "weeklyHold": [
        49,
        49,
        35,
        16,
        49,
        16,
        0,
        19,
        28,
        15,
        43,
        1,
        12,
        26,
        37,
        13,
        18,
        17,
        43,
        0,
        5,
        9,
        0,
        26,
        0,
        15,
        36,
        36,
        38,
        18,
        48,
        44,
        8,
        0,
        48,
        0,
        0,
        0,
        39,
        46,
        2,
        43,
        24,
        0,
        49,
        44,
        47,
        41,
        32,
        32,
        12,
        41
      ],
      "weeklyBackordered": [
        17,
        39,
        46,
        18,
        41,
        25,
        49,
        4,
        25,
        19,
        34,
        32,
        6,
        44,
        39,
        40,
        31,
        49,
        8,
        26,
        0,
        0,
        17,
        28,
        1,
        49,
        9,
        0,
        18,
        41,
        0,
        20,
        30,
        34,
        17,
        0,
        18,
        0,
        7,
        21,
        0,
        26,
        33,
        29,
        0,
        0,
        38,
        5,
        0,
        30,
        18,
        18
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        16,
        29,
        44,
        15,
        49,
        11,
        1,
        27,
        9,
        25,
        41,
        25,
        25,
        18,
        19,
        0,
        13,
        0,
        20,
        26,
        25,
        23,
        9,
        43,
        7,
        39,
        0,
        38,
        1,
        17,
        0,
        0,
        40,
        2,
        48,
        49,
        47,
        13,
        17,
        49,
        0,
        49,
        41,
        2,
        26,
        0,
        4,
        5,
        5,
        33,
        19,
        0
      ],
      "weeklyRejected": [
        37,
        10,
        11,
        0,
        33,
        19,
        1,
        49,
        29,
        42,
        49,
        25,
        49,
        48,
        31,
        34,
        30,
        39,
        49,
        0,
        5,
        24,
        42,
        17,
        37,
        41,
        36,
        17,
        18,
        48,
        2,
        33,
        11,
        6,
        0,
        49,
        43,
        35,
        2,
        49,
        32,
        35,
        0,
        5,
        25,
        49,
        5,
        3,
        31,
        24,
        0,
        33
      ],
      "weeklyHold": [
        28,
        0,
        49,
        15,
        49,
        0,
        16,
        36,
        9,
        0,
        22,
        43,
        43,
        11,
        49,
        8,
        3,
        12,
        27,
        29,
        14,
        34,
        49,
        7,
        34,
        11,
        15,
        37,
        49,
        49,
        48,
        0,
        2,
        49,
        39,
        37,
        16,
        16,
        6,
        13,
        6,
        15,
        34,
        48,
        19,
        41,
        0,
        41,
        4,
        49,
        13,
        11
      ],
      "weeklyBackordered": [
        0,
        0,
        35,
        0,
        49,
        31,
        17,
        48,
        12,
        7,
        29,
        15,
        49,
        0,
        34,
        1,
        44,
        49,
        30,
        0,
        36,
        0,
        23,
        39,
        15,
        1,
        22,
        40,
        14,
        49,
        15,
        49,
        48,
        37,
        18,
        0,
        24,
        0,
        24,
        13,
        48,
        48,
        10,
        40,
        20,
        43,
        49,
        30,
        31,
        22,
        15,
        1
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        0,
        14,
        25,
        21,
        31,
        11,
        39,
        49,
        47,
        27,
        43,
        0,
        19,
        25,
        23,
        18,
        46,
        0,
        0,
        42,
        32,
        7,
        18,
        23,
        29,
        27,
        28,
        33,
        7,
        49,
        7,
        19,
        46,
        22,
        48,
        41,
        14,
        32,
        22,
        42,
        22,
        6,
        49,
        0,
        49,
        35,
        7,
        3,
        15,
        36,
        49,
        25
      ],
      "weeklyRejected": [
        49,
        0,
        24,
        30,
        13,
        0,
        25,
        21,
        47,
        1,
        30,
        41,
        11,
        47,
        7,
        23,
        0,
        49,
        45,
        2,
        30,
        9,
        49,
        49,
        23,
        49,
        45,
        10,
        22,
        39,
        19,
        37,
        48,
        16,
        23,
        48,
        31,
        36,
        47,
        31,
        46,
        49,
        27,
        49,
        19,
        19,
        0,
        18,
        0,
        0,
        25,
        23
      ],
      "weeklyHold": [
        12,
        0,
        49,
        21,
        6,
        45,
        0,
        49,
        0,
        22,
        47,
        30,
        1,
        41,
        2,
        21,
        0,
        28,
        14,
        17,
        21,
        23,
        49,
        0,
        43,
        45,
        24,
        39,
        35,
        49,
        40,
        9,
        32,
        20,
        41,
        41,
        0,
        0,
        28,
        22,
        5,
        15,
        0,
        0,
        38,
        39,
        49,
        25,
        9,
        9,
        49,
        49
      ],
      "weeklyBackordered": [
        32,
        32,
        0,
        0,
        34,
        36,
        48,
        27,
        18,
        16,
        31,
        49,
        3,
        49,
        22,
        40,
        13,
        5,
        32,
        43,
        40,
        49,
        20,
        34,
        14,
        25,
        18,
        49,
        10,
        42,
        26,
        24,
        0,
        49,
        0,
        0,
        10,
        15,
        48,
        2,
        39,
        48,
        37,
        49,
        30,
        0,
        19,
        18,
        15,
        8,
        24,
        37
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        17,
        49,
        21,
        44,
        23,
        0,
        49,
        49,
        36,
        42,
        31,
        46,
        15,
        24,
        32,
        7,
        18,
        31,
        16,
        16,
        16,
        18,
        29,
        27,
        49,
        28,
        5,
        46,
        47,
        49,
        16,
        33,
        49,
        34,
        22,
        25,
        10,
        31,
        30,
        49,
        26,
        46,
        49,
        49,
        49,
        0,
        8,
        45,
        37,
        28,
        17,
        48
      ],
      "weeklyRejected": [
        42,
        5,
        2,
        43,
        22,
        3,
        21,
        22,
        34,
        24,
        15,
        31,
        20,
        12,
        48,
        0,
        49,
        22,
        38,
        36,
        0,
        48,
        0,
        25,
        40,
        0,
        49,
        25,
        40,
        22,
        49,
        27,
        22,
        49,
        22,
        33,
        49,
        8,
        1,
        46,
        11,
        39,
        44,
        1,
        49,
        20,
        35,
        14,
        0,
        36,
        38,
        49
      ],
      "weeklyHold": [
        17,
        0,
        41,
        1,
        23,
        43,
        49,
        2,
        16,
        20,
        37,
        22,
        20,
        49,
        49,
        32,
        14,
        3,
        37,
        11,
        24,
        0,
        9,
        4,
        14,
        48,
        0,
        3,
        49,
        17,
        48,
        21,
        26,
        11,
        48,
        47,
        49,
        0,
        49,
        23,
        41,
        40,
        0,
        47,
        26,
        49,
        26,
        26,
        7,
        42,
        2,
        44
      ],
      "weeklyBackordered": [
        37,
        17,
        0,
        48,
        42,
        0,
        42,
        49,
        41,
        38,
        49,
        49,
        24,
        18,
        46,
        7,
        33,
        29,
        0,
        23,
        24,
        0,
        10,
        34,
        18,
        2,
        18,
        6,
        39,
        49,
        33,
        7,
        37,
        49,
        0,
        0,
        5,
        4,
        18,
        42,
        10,
        48,
        0,
        49,
        16,
        38,
        0,
        25,
        34,
        43,
        36,
        27
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        9,
        49,
        49,
        44,
        18,
        14,
        49,
        49,
        15,
        9,
        38,
        1,
        29,
        27,
        39,
        17,
        49,
        0,
        40,
        18,
        49,
        23,
        22,
        27,
        32,
        47,
        0,
        44,
        4,
        1,
        11,
        12,
        49,
        31,
        17,
        30,
        38,
        4,
        44,
        49,
        3,
        13,
        47,
        49,
        32,
        6,
        23,
        49,
        5,
        35,
        7,
        13
      ],
      "weeklyRejected": [
        38,
        24,
        26,
        9,
        35,
        11,
        32,
        49,
        34,
        9,
        48,
        12,
        22,
        30,
        43,
        16,
        3,
        5,
        33,
        17,
        41,
        38,
        27,
        18,
        21,
        26,
        47,
        11,
        48,
        14,
        40,
        18,
        38,
        49,
        8,
        41,
        19,
        33,
        1,
        16,
        48,
        49,
        20,
        39,
        49,
        35,
        9,
        49,
        0,
        14,
        28,
        27
      ],
      "weeklyHold": [
        40,
        34,
        7,
        1,
        49,
        31,
        22,
        47,
        39,
        49,
        43,
        46,
        1,
        32,
        20,
        9,
        0,
        5,
        30,
        0,
        0,
        27,
        0,
        27,
        8,
        47,
        3,
        11,
        0,
        10,
        48,
        9,
        24,
        49,
        2,
        28,
        28,
        22,
        45,
        31,
        22,
        37,
        3,
        23,
        45,
        49,
        18,
        34,
        6,
        42,
        11,
        49
      ],
      "weeklyBackordered": [
        24,
        48,
        0,
        44,
        43,
        28,
        22,
        30,
        9,
        15,
        35,
        43,
        49,
        2,
        46,
        7,
        27,
        34,
        47,
        42,
        9,
        40,
        13,
        0,
        28,
        27,
        22,
        11,
        39,
        23,
        42,
        18,
        37,
        11,
        29,
        0,
        25,
        24,
        5,
        32,
        42,
        34,
        9,
        49,
        32,
        30,
        49,
        35,
        27,
        37,
        49,
        11
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        36,
        12,
        39,
        21,
        49,
        49,
        1,
        18,
        48,
        29,
        49,
        20,
        14,
        43,
        14,
        49,
        42,
        24,
        11,
        7,
        5,
        43,
        6,
        49,
        24,
        43,
        0,
        49,
        34,
        36,
        0,
        0,
        37,
        1,
        48,
        49,
        32,
        0,
        1,
        16,
        33,
        48,
        49,
        0,
        45,
        6,
        15,
        0,
        0,
        13,
        23,
        41
      ],
      "weeklyRejected": [
        29,
        17,
        49,
        47,
        23,
        0,
        19,
        16,
        1,
        47,
        16,
        20,
        34,
        48,
        31,
        28,
        15,
        49,
        10,
        35,
        2,
        27,
        6,
        49,
        31,
        49,
        47,
        40,
        47,
        11,
        3,
        25,
        24,
        30,
        27,
        49,
        23,
        49,
        6,
        28,
        33,
        31,
        26,
        14,
        14,
        7,
        24,
        18,
        4,
        45,
        8,
        49
      ],
      "weeklyHold": [
        49,
        4,
        49,
        1,
        16,
        48,
        30,
        39,
        3,
        34,
        31,
        19,
        15,
        49,
        31,
        24,
        14,
        30,
        48,
        38,
        49,
        37,
        20,
        17,
        46,
        28,
        0,
        0,
        49,
        15,
        37,
        25,
        41,
        16,
        0,
        9,
        0,
        0,
        3,
        49,
        24,
        36,
        28,
        33,
        22,
        7,
        49,
        42,
        20,
        20,
        49,
        0
      ],
      "weeklyBackordered": [
        29,
        19,
        17,
        40,
        49,
        39,
        49,
        49,
        24,
        1,
        0,
        49,
        3,
        37,
        44,
        1,
        49,
        0,
        0,
        0,
        49,
        0,
        11,
        49,
        41,
        11,
        10,
        0,
        34,
        49,
        30,
        13,
        25,
        49,
        20,
        10,
        30,
        13,
        38,
        49,
        46,
        16,
        8,
        0,
        12,
        16,
        24,
        22,
        35,
        40,
        40,
        21
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyAccepted": [
        6,
        12,
        30,
        24,
        44,
        11,
        44,
        30,
        25,
        15,
        35,
        17,
        49,
        24,
        1,
        6,
        49,
        16,
        5,
        32,
        49,
        17,
        26,
        1,
        22,
        26,
        13,
        33,
        1,
        41,
        18,
        40,
        48,
        49,
        36,
        37,
        26,
        23,
        29,
        49,
        14,
        28,
        49,
        49,
        49,
        0,
        0,
        42,
        13,
        49,
        35,
        0
      ],
      "weeklyRejected": [
        20,
        19,
        23,
        47,
        0,
        29,
        38,
        46,
        36,
        1,
        20,
        36,
        49,
        48,
        48,
        26,
        33,
        29,
        44,
        26,
        26,
        18,
        49,
        49,
        21,
        32,
        3,
        49,
        41,
        12,
        40,
        36,
        21,
        26,
        39,
        49,
        27,
        24,
        25,
        10,
        40,
        49,
        35,
        0,
        49,
        17,
        23,
        24,
        7,
        16,
        22,
        11
      ],
      "weeklyHold": [
        49,
        49,
        49,
        1,
        29,
        17,
        3,
        7,
        3,
        33,
        5,
        49,
        13,
        34,
        41,
        8,
        21,
        34,
        27,
        16,
        40,
        14,
        31,
        0,
        49,
        22,
        43,
        36,
        32,
        9,
        48,
        17,
        24,
        42,
        41,
        17,
        2,
        32,
        4,
        35,
        9,
        49,
        14,
        36,
        39,
        4,
        14,
        9,
        0,
        49,
        49,
        31
      ],
      "weeklyBackordered": [
        40,
        27,
        27,
        45,
        48,
        35,
        33,
        24,
        49,
        0,
        33,
        35,
        24,
        30,
        20,
        28,
        44,
        49,
        0,
        14,
        0,
        0,
        8,
        45,
        30,
        14,
        8,
        16,
        1,
        32,
        6,
        29,
        37,
        0,
        0,
        0,
        49,
        27,
        31,
        34,
        16,
        48,
        31,
        28,
        17,
        20,
        26,
        5,
        6,
        22,
        45,
        1
      ]
    },
    {
      "id": "F04529EA",
      "weeklyAccepted": [
        23,
        29,
        49,
        29,
        9,
        10,
        49,
        9,
        48,
        28,
        30,
        0,
        38,
        31,
        43,
        15,
        44,
        8,
        8,
        23,
        24,
        49,
        33,
        20,
        22,
        36,
        9,
        29,
        30,
        38,
        0,
        3,
        32,
        22,
        26,
        49,
        40,
        48,
        31,
        49,
        18,
        24,
        49,
        0,
        42,
        0,
        17,
        11,
        27,
        26,
        8,
        48
      ],
      "weeklyRejected": [
        35,
        33,
        33,
        0,
        49,
        33,
        4,
        0,
        49,
        13,
        39,
        38,
        0,
        28,
        44,
        2,
        31,
        49,
        49,
        21,
        22,
        7,
        16,
        9,
        21,
        49,
        36,
        49,
        38,
        48,
        19,
        26,
        28,
        49,
        0,
        27,
        29,
        49,
        12,
        32,
        48,
        18,
        49,
        0,
        43,
        0,
        14,
        44,
        6,
        41,
        9,
        49
      ],
      "weeklyHold": [
        44,
        13,
        0,
        16,
        0,
        30,
        25,
        2,
        13,
        45,
        32,
        28,
        27,
        30,
        49,
        37,
        9,
        13,
        45,
        33,
        3,
        28,
        49,
        30,
        24,
        21,
        34,
        13,
        49,
        18,
        48,
        27,
        30,
        34,
        28,
        25,
        14,
        8,
        8,
        10,
        0,
        32,
        0,
        0,
        0,
        49,
        9,
        21,
        31,
        49,
        45,
        6
      ],
      "weeklyBackordered": [
        42,
        0,
        0,
        2,
        43,
        18,
        22,
        8,
        38,
        0,
        24,
        0,
        10,
        22,
        43,
        42,
        46,
        33,
        0,
        0,
        39,
        30,
        46,
        26,
        36,
        18,
        13,
        20,
        1,
        13,
        19,
        49,
        27,
        24,
        23,
        0,
        49,
        0,
        49,
        16,
        4,
        48,
        0,
        49,
        1,
        28,
        49,
        41,
        8,
        32,
        5,
        33
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        15,
        23,
        3,
        1,
        5,
        3,
        10,
        12,
        44,
        12,
        0,
        7,
        23,
        24,
        24,
        17,
        11,
        26,
        30,
        32,
        40,
        38,
        38,
        15,
        15,
        11,
        22,
        49,
        1,
        29,
        34,
        26,
        49,
        12,
        48,
        2,
        0,
        48,
        49,
        36,
        41,
        23,
        32,
        32,
        49,
        29,
        9,
        11,
        49,
        49,
        24,
        0
      ],
      "weeklyRejected": [
        25,
        19,
        10,
        0,
        18,
        15,
        49,
        25,
        49,
        48,
        37,
        37,
        19,
        48,
        48,
        22,
        18,
        22,
        48,
        7,
        16,
        28,
        19,
        24,
        0,
        49,
        39,
        15,
        39,
        0,
        35,
        29,
        13,
        5,
        49,
        49,
        48,
        4,
        29,
        17,
        15,
        49,
        45,
        32,
        48,
        31,
        0,
        15,
        43,
        29,
        0,
        49
      ],
      "weeklyHold": [
        49,
        0,
        49,
        16,
        41,
        17,
        35,
        34,
        30,
        24,
        26,
        24,
        46,
        12,
        23,
        28,
        0,
        18,
        0,
        13,
        30,
        0,
        34,
        5,
        20,
        16,
        16,
        36,
        49,
        25,
        48,
        2,
        0,
        26,
        22,
        49,
        18,
        31,
        1,
        49,
        0,
        45,
        10,
        49,
        33,
        38,
        19,
        41,
        4,
        21,
        39,
        49
      ],
      "weeklyBackordered": [
        24,
        0,
        8,
        24,
        9,
        36,
        30,
        37,
        13,
        20,
        49,
        0,
        37,
        20,
        0,
        11,
        49,
        49,
        0,
        3,
        13,
        20,
        35,
        41,
        27,
        41,
        15,
        32,
        31,
        36,
        46,
        49,
        42,
        29,
        0,
        12,
        49,
        24,
        23,
        22,
        15,
        33,
        4,
        39,
        34,
        4,
        49,
        32,
        49,
        37,
        17,
        8
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        15,
        30,
        49,
        48,
        38,
        0,
        7,
        49,
        18,
        24,
        46,
        24,
        26,
        32,
        7,
        49,
        16,
        15,
        9,
        14,
        49,
        32,
        36,
        39,
        1,
        40,
        26,
        20,
        21,
        1,
        9,
        24,
        41,
        20,
        13,
        32,
        43,
        26,
        0,
        32,
        0,
        17,
        46,
        26,
        38,
        14,
        0,
        1,
        17,
        42,
        9,
        18
      ],
      "weeklyRejected": [
        49,
        14,
        43,
        35,
        35,
        0,
        23,
        49,
        21,
        18,
        22,
        32,
        30,
        26,
        10,
        28,
        9,
        49,
        49,
        13,
        49,
        20,
        14,
        49,
        49,
        33,
        45,
        33,
        37,
        6,
        49,
        47,
        24,
        16,
        49,
        38,
        25,
        0,
        23,
        16,
        17,
        45,
        49,
        25,
        36,
        16,
        0,
        31,
        0,
        38,
        14,
        36
      ],
      "weeklyHold": [
        30,
        30,
        34,
        1,
        28,
        20,
        0,
        30,
        14,
        40,
        29,
        18,
        14,
        49,
        6,
        0,
        5,
        12,
        0,
        0,
        19,
        49,
        0,
        2,
        0,
        28,
        27,
        40,
        49,
        17,
        48,
        4,
        0,
        46,
        20,
        28,
        25,
        1,
        29,
        49,
        43,
        25,
        5,
        29,
        27,
        22,
        7,
        29,
        0,
        11,
        34,
        39
      ],
      "weeklyBackordered": [
        26,
        46,
        0,
        15,
        49,
        39,
        49,
        19,
        44,
        20,
        35,
        17,
        23,
        0,
        43,
        46,
        34,
        44,
        37,
        43,
        14,
        48,
        49,
        33,
        1,
        15,
        25,
        49,
        49,
        40,
        31,
        49,
        8,
        49,
        3,
        0,
        35,
        17,
        23,
        19,
        14,
        48,
        16,
        49,
        27,
        25,
        49,
        16,
        26,
        37,
        17,
        32
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        49,
        19,
        25,
        31,
        38,
        26,
        2,
        45,
        14,
        25,
        47,
        0,
        18,
        23,
        16,
        49,
        35,
        0,
        1,
        24,
        0,
        33,
        30,
        20,
        0,
        47,
        9,
        14,
        7,
        1,
        25,
        1,
        37,
        30,
        48,
        2,
        17,
        48,
        10,
        49,
        14,
        0,
        49,
        16,
        39,
        0,
        0,
        9,
        0,
        6,
        38,
        16
      ],
      "weeklyRejected": [
        32,
        21,
        31,
        29,
        9,
        0,
        49,
        49,
        49,
        22,
        0,
        34,
        29,
        43,
        48,
        31,
        2,
        49,
        0,
        47,
        28,
        49,
        19,
        28,
        21,
        16,
        46,
        37,
        48,
        11,
        49,
        8,
        15,
        21,
        22,
        29,
        49,
        49,
        1,
        7,
        48,
        26,
        35,
        15,
        47,
        31,
        14,
        5,
        0,
        37,
        0,
        39
      ],
      "weeklyHold": [
        47,
        43,
        25,
        14,
        4,
        31,
        18,
        20,
        36,
        23,
        34,
        20,
        29,
        4,
        49,
        49,
        0,
        36,
        16,
        29,
        8,
        49,
        0,
        19,
        49,
        23,
        0,
        0,
        30,
        0,
        48,
        4,
        7,
        49,
        2,
        37,
        38,
        0,
        21,
        25,
        41,
        30,
        0,
        10,
        24,
        34,
        0,
        49,
        1,
        36,
        37,
        49
      ],
      "weeklyBackordered": [
        49,
        0,
        24,
        10,
        45,
        18,
        49,
        28,
        14,
        17,
        17,
        39,
        24,
        19,
        0,
        1,
        33,
        34,
        5,
        34,
        3,
        0,
        0,
        41,
        19,
        41,
        33,
        6,
        22,
        49,
        17,
        19,
        32,
        25,
        23,
        28,
        44,
        0,
        20,
        11,
        44,
        48,
        28,
        7,
        22,
        10,
        49,
        4,
        39,
        32,
        38,
        5
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        22,
        49,
        14,
        27,
        26,
        20,
        1,
        12,
        34,
        13,
        49,
        0,
        0,
        47,
        19,
        27,
        49,
        21,
        0,
        40,
        30,
        36,
        37,
        49,
        28,
        18,
        0,
        49,
        10,
        40,
        22,
        48,
        47,
        36,
        48,
        43,
        43,
        47,
        49,
        49,
        20,
        24,
        44,
        28,
        49,
        49,
        35,
        39,
        9,
        27,
        26,
        47
      ],
      "weeklyRejected": [
        49,
        15,
        11,
        25,
        35,
        0,
        49,
        39,
        29,
        20,
        13,
        35,
        20,
        23,
        16,
        24,
        0,
        32,
        37,
        43,
        9,
        7,
        13,
        41,
        49,
        49,
        40,
        33,
        44,
        2,
        31,
        14,
        48,
        49,
        2,
        48,
        0,
        18,
        1,
        0,
        35,
        41,
        36,
        15,
        49,
        47,
        14,
        24,
        3,
        0,
        25,
        48
      ],
      "weeklyHold": [
        0,
        33,
        19,
        12,
        6,
        34,
        34,
        43,
        18,
        26,
        26,
        49,
        1,
        37,
        33,
        1,
        28,
        48,
        0,
        21,
        35,
        30,
        16,
        3,
        12,
        48,
        20,
        15,
        20,
        14,
        48,
        0,
        13,
        49,
        15,
        9,
        49,
        5,
        49,
        40,
        21,
        20,
        37,
        0,
        11,
        22,
        8,
        32,
        0,
        17,
        34,
        13
      ],
      "weeklyBackordered": [
        0,
        35,
        33,
        8,
        49,
        46,
        42,
        49,
        25,
        11,
        32,
        2,
        10,
        46,
        12,
        49,
        10,
        29,
        18,
        27,
        30,
        0,
        24,
        0,
        7,
        22,
        47,
        0,
        1,
        23,
        40,
        27,
        3,
        27,
        0,
        0,
        41,
        25,
        39,
        30,
        48,
        47,
        22,
        49,
        18,
        28,
        14,
        21,
        19,
        9,
        49,
        49
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        12,
        49,
        2,
        48,
        49,
        20,
        1,
        17,
        46,
        22,
        43,
        21,
        25,
        32,
        49,
        32,
        49,
        27,
        34,
        49,
        5,
        49,
        34,
        24,
        0,
        24,
        8,
        6,
        24,
        49,
        43,
        23,
        38,
        17,
        48,
        37,
        21,
        44,
        33,
        49,
        27,
        33,
        46,
        17,
        27,
        6,
        49,
        6,
        0,
        14,
        39,
        46
      ],
      "weeklyRejected": [
        49,
        15,
        49,
        6,
        14,
        0,
        49,
        19,
        0,
        29,
        15,
        38,
        38,
        32,
        48,
        18,
        49,
        49,
        19,
        0,
        0,
        30,
        26,
        16,
        49,
        32,
        49,
        15,
        48,
        28,
        0,
        11,
        48,
        43,
        0,
        48,
        4,
        0,
        26,
        10,
        40,
        49,
        21,
        0,
        26,
        16,
        38,
        4,
        0,
        38,
        9,
        49
      ],
      "weeklyHold": [
        0,
        42,
        5,
        1,
        19,
        47,
        42,
        21,
        22,
        34,
        42,
        44,
        1,
        12,
        41,
        28,
        35,
        37,
        24,
        0,
        45,
        0,
        14,
        29,
        19,
        48,
        18,
        4,
        46,
        9,
        48,
        12,
        34,
        0,
        31,
        0,
        47,
        0,
        31,
        39,
        49,
        22,
        2,
        34,
        28,
        49,
        4,
        11,
        29,
        36,
        40,
        25
      ],
      "weeklyBackordered": [
        8,
        0,
        20,
        26,
        49,
        8,
        36,
        30,
        6,
        29,
        39,
        28,
        49,
        40,
        49,
        20,
        46,
        37,
        0,
        6,
        49,
        17,
        8,
        30,
        1,
        49,
        1,
        3,
        1,
        49,
        40,
        33,
        34,
        48,
        0,
        0,
        1,
        0,
        43,
        5,
        45,
        22,
        0,
        27,
        14,
        49,
        22,
        30,
        0,
        49,
        21,
        25
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        1,
        48,
        27,
        48,
        49,
        26,
        4,
        11,
        40,
        49,
        45,
        0,
        49,
        12,
        47,
        23,
        49,
        0,
        0,
        47,
        0,
        46,
        18,
        49,
        24,
        36,
        0,
        0,
        21,
        47,
        15,
        0,
        49,
        13,
        10,
        22,
        27,
        48,
        36,
        49,
        0,
        49,
        49,
        35,
        49,
        0,
        0,
        15,
        45,
        49,
        42,
        48
      ],
      "weeklyRejected": [
        35,
        49,
        49,
        30,
        21,
        5,
        0,
        22,
        19,
        1,
        18,
        21,
        30,
        29,
        36,
        49,
        49,
        49,
        29,
        44,
        0,
        0,
        20,
        49,
        33,
        22,
        49,
        42,
        45,
        14,
        49,
        19,
        46,
        49,
        17,
        49,
        37,
        31,
        10,
        49,
        36,
        0,
        0,
        34,
        49,
        3,
        15,
        16,
        0,
        49,
        36,
        38
      ],
      "weeklyHold": [
        0,
        2,
        40,
        1,
        20,
        48,
        0,
        31,
        37,
        18,
        35,
        49,
        14,
        45,
        4,
        0,
        0,
        22,
        19,
        19,
        40,
        29,
        18,
        0,
        27,
        48,
        16,
        0,
        49,
        5,
        34,
        38,
        20,
        9,
        0,
        6,
        25,
        23,
        25,
        49,
        43,
        2,
        9,
        12,
        36,
        27,
        0,
        4,
        0,
        35,
        33,
        34
      ],
      "weeklyBackordered": [
        0,
        45,
        0,
        31,
        49,
        38,
        33,
        45,
        0,
        5,
        4,
        21,
        14,
        23,
        0,
        13,
        49,
        49,
        11,
        0,
        49,
        27,
        6,
        0,
        12,
        20,
        25,
        45,
        30,
        49,
        28,
        25,
        48,
        22,
        0,
        0,
        4,
        0,
        45,
        22,
        30,
        48,
        8,
        49,
        7,
        11,
        34,
        19,
        46,
        8,
        32,
        20
      ]
    },
    {
      "id": "7B415249",
      "weeklyAccepted": [
        0,
        5,
        47,
        29,
        21,
        34,
        24,
        26,
        48,
        12,
        11,
        9,
        0,
        0,
        49,
        24,
        49,
        0,
        21,
        40,
        3,
        49,
        49,
        38,
        21,
        26,
        7,
        32,
        1,
        5,
        0,
        14,
        36,
        23,
        35,
        44,
        0,
        12,
        49,
        21,
        34,
        27,
        49,
        49,
        15,
        0,
        20,
        17,
        19,
        36,
        44,
        15
      ],
      "weeklyRejected": [
        0,
        27,
        49,
        31,
        0,
        46,
        25,
        36,
        18,
        16,
        49,
        0,
        34,
        35,
        29,
        41,
        49,
        49,
        46,
        12,
        22,
        11,
        25,
        49,
        16,
        18,
        46,
        47,
        19,
        14,
        49,
        47,
        48,
        3,
        37,
        49,
        6,
        13,
        17,
        49,
        28,
        29,
        0,
        0,
        49,
        14,
        7,
        17,
        6,
        26,
        15,
        47
      ],
      "weeklyHold": [
        30,
        49,
        34,
        29,
        11,
        11,
        0,
        15,
        38,
        28,
        47,
        11,
        20,
        29,
        26,
        0,
        15,
        35,
        24,
        37,
        28,
        49,
        9,
        23,
        22,
        48,
        9,
        23,
        43,
        18,
        48,
        0,
        18,
        17,
        26,
        18,
        20,
        30,
        18,
        29,
        49,
        27,
        0,
        27,
        29,
        49,
        17,
        49,
        0,
        9,
        49,
        14
      ],
      "weeklyBackordered": [
        32,
        46,
        0,
        9,
        49,
        37,
        12,
        1,
        16,
        38,
        41,
        3,
        41,
        29,
        42,
        1,
        15,
        42,
        0,
        12,
        31,
        23,
        0,
        18,
        33,
        1,
        14,
        23,
        49,
        34,
        29,
        38,
        48,
        6,
        11,
        0,
        37,
        20,
        49,
        34,
        36,
        24,
        21,
        26,
        6,
        39,
        49,
        37,
        26,
        48,
        31,
        19
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyAccepted": [
        0,
        30,
        36,
        15,
        10,
        0,
        13,
        49,
        1,
        0,
        38,
        0,
        34,
        33,
        34,
        5,
        37,
        18,
        0,
        27,
        0,
        8,
        48,
        49,
        27,
        47,
        30,
        16,
        1,
        47,
        19,
        9,
        43,
        15,
        42,
        46,
        16,
        22,
        0,
        49,
        0,
        7,
        49,
        49,
        35,
        0,
        10,
        9,
        31,
        28,
        33,
        48
      ],
      "weeklyRejected": [
        40,
        10,
        35,
        47,
        20,
        49,
        49,
        17,
        49,
        1,
        49,
        44,
        35,
        33,
        33,
        7,
        49,
        36,
        22,
        13,
        9,
        49,
        0,
        39,
        10,
        49,
        45,
        14,
        25,
        13,
        48,
        26,
        30,
        49,
        13,
        49,
        0,
        0,
        1,
        41,
        3,
        0,
        17,
        0,
        41,
        9,
        29,
        0,
        15,
        37,
        0,
        29
      ],
      "weeklyHold": [
        36,
        49,
        42,
        25,
        49,
        25,
        39,
        2,
        0,
        49,
        47,
        45,
        11,
        45,
        34,
        16,
        49,
        33,
        21,
        5,
        36,
        18,
        43,
        0,
        25,
        31,
        0,
        6,
        49,
        28,
        39,
        19,
        0,
        38,
        9,
        41,
        5,
        0,
        1,
        18,
        12,
        49,
        26,
        44,
        35,
        49,
        39,
        16,
        47,
        22,
        43,
        49
      ],
      "weeklyBackordered": [
        1,
        17,
        10,
        48,
        40,
        35,
        49,
        44,
        32,
        33,
        38,
        49,
        3,
        19,
        0,
        9,
        35,
        22,
        15,
        0,
        47,
        27,
        27,
        7,
        20,
        20,
        44,
        49,
        29,
        49,
        13,
        40,
        32,
        0,
        0,
        0,
        11,
        0,
        49,
        47,
        6,
        48,
        4,
        49,
        20,
        0,
        0,
        23,
        22,
        29,
        49,
        1
      ]
    },
    {
      "id": "7257F77C",
      "weeklyAccepted": [
        20,
        10,
        49,
        11,
        49,
        32,
        16,
        24,
        34,
        21,
        8,
        1,
        45,
        23,
        22,
        19,
        47,
        36,
        14,
        17,
        33,
        49,
        2,
        35,
        8,
        41,
        20,
        49,
        25,
        49,
        15,
        39,
        22,
        49,
        30,
        29,
        29,
        23,
        12,
        49,
        34,
        32,
        9,
        10,
        43,
        0,
        25,
        13,
        6,
        31,
        28,
        48
      ],
      "weeklyRejected": [
        31,
        4,
        49,
        26,
        17,
        13,
        0,
        0,
        49,
        7,
        49,
        24,
        32,
        0,
        48,
        5,
        33,
        1,
        0,
        30,
        7,
        49,
        17,
        20,
        6,
        49,
        45,
        49,
        48,
        26,
        49,
        8,
        33,
        49,
        0,
        38,
        35,
        0,
        4,
        25,
        29,
        22,
        16,
        19,
        37,
        0,
        11,
        47,
        14,
        49,
        0,
        49
      ],
      "weeklyHold": [
        15,
        25,
        49,
        2,
        42,
        32,
        24,
        19,
        19,
        16,
        47,
        36,
        46,
        9,
        26,
        2,
        49,
        19,
        47,
        30,
        31,
        27,
        15,
        27,
        24,
        42,
        40,
        0,
        45,
        0,
        37,
        32,
        36,
        41,
        26,
        6,
        29,
        36,
        47,
        40,
        49,
        49,
        0,
        35,
        45,
        49,
        0,
        42,
        49,
        9,
        30,
        21
      ],
      "weeklyBackordered": [
        28,
        49,
        24,
        36,
        49,
        9,
        3,
        49,
        21,
        0,
        0,
        38,
        22,
        0,
        7,
        29,
        43,
        17,
        0,
        9,
        42,
        24,
        24,
        33,
        11,
        30,
        20,
        14,
        1,
        49,
        26,
        38,
        0,
        49,
        0,
        0,
        37,
        0,
        20,
        12,
        23,
        44,
        40,
        49,
        24,
        21,
        28,
        5,
        3,
        49,
        26,
        24
      ]
    },
    {
      "id": "D9345860",
      "weeklyAccepted": [
        25,
        38,
        30,
        31,
        25,
        0,
        1,
        49,
        42,
        28,
        12,
        1,
        8,
        29,
        31,
        9,
        49,
        0,
        0,
        49,
        31,
        44,
        49,
        27,
        34,
        27,
        3,
        39,
        1,
        11,
        21,
        7,
        25,
        0,
        48,
        49,
        14,
        48,
        25,
        16,
        30,
        37,
        49,
        4,
        49,
        12,
        6,
        21,
        0,
        20,
        33,
        9
      ],
      "weeklyRejected": [
        49,
        24,
        49,
        4,
        26,
        0,
        46,
        49,
        49,
        25,
        43,
        15,
        29,
        39,
        39,
        49,
        13,
        49,
        49,
        32,
        7,
        28,
        34,
        23,
        8,
        49,
        49,
        49,
        48,
        2,
        23,
        14,
        0,
        0,
        41,
        49,
        19,
        19,
        11,
        28,
        0,
        49,
        49,
        3,
        49,
        32,
        34,
        49,
        0,
        3,
        3,
        44
      ],
      "weeklyHold": [
        43,
        10,
        49,
        44,
        0,
        43,
        6,
        11,
        0,
        15,
        35,
        28,
        25,
        15,
        49,
        25,
        26,
        22,
        15,
        45,
        20,
        1,
        49,
        0,
        12,
        25,
        7,
        33,
        49,
        5,
        22,
        0,
        8,
        20,
        31,
        33,
        24,
        0,
        15,
        49,
        0,
        24,
        0,
        32,
        49,
        49,
        27,
        5,
        0,
        23,
        48,
        49
      ],
      "weeklyBackordered": [
        33,
        49,
        45,
        30,
        49,
        36,
        29,
        43,
        14,
        49,
        11,
        30,
        41,
        18,
        11,
        9,
        46,
        23,
        0,
        47,
        40,
        0,
        0,
        0,
        13,
        9,
        49,
        32,
        39,
        49,
        31,
        27,
        13,
        32,
        26,
        0,
        3,
        0,
        41,
        37,
        0,
        48,
        30,
        37,
        17,
        0,
        8,
        37,
        46,
        18,
        39,
        6
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        8,
        15,
        14,
        2,
        49,
        12,
        15,
        42,
        40,
        6,
        36,
        0,
        31,
        9,
        25,
        49,
        49,
        0,
        49,
        0,
        46,
        8,
        49,
        13,
        10,
        6,
        0,
        46,
        16,
        2,
        23,
        17,
        32,
        2,
        48,
        49,
        11,
        12,
        20,
        49,
        8,
        24,
        49,
        21,
        49,
        23,
        4,
        0,
        26,
        49,
        49,
        28
      ],
      "weeklyRejected": [
        49,
        29,
        27,
        47,
        10,
        0,
        8,
        26,
        46,
        48,
        22,
        12,
        29,
        32,
        27,
        0,
        12,
        39,
        46,
        19,
        42,
        10,
        49,
        41,
        17,
        49,
        36,
        17,
        48,
        30,
        39,
        27,
        34,
        23,
        19,
        37,
        49,
        32,
        15,
        13,
        48,
        34,
        10,
        22,
        49,
        49,
        19,
        30,
        5,
        38,
        14,
        43
      ],
      "weeklyHold": [
        36,
        28,
        31,
        1,
        0,
        14,
        0,
        25,
        16,
        28,
        0,
        1,
        7,
        7,
        15,
        0,
        0,
        39,
        10,
        0,
        49,
        13,
        31,
        11,
        23,
        24,
        32,
        18,
        49,
        0,
        32,
        0,
        0,
        6,
        42,
        23,
        0,
        14,
        23,
        20,
        23,
        21,
        29,
        42,
        34,
        29,
        31,
        43,
        4,
        29,
        8,
        10
      ],
      "weeklyBackordered": [
        29,
        7,
        12,
        30,
        40,
        33,
        37,
        46,
        9,
        0,
        40,
        32,
        11,
        38,
        42,
        1,
        49,
        49,
        22,
        49,
        49,
        20,
        18,
        0,
        36,
        32,
        32,
        30,
        8,
        49,
        0,
        0,
        39,
        0,
        27,
        0,
        19,
        0,
        10,
        0,
        8,
        15,
        30,
        44,
        36,
        33,
        37,
        0,
        12,
        40,
        35,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyAccepted": [
        0,
        4,
        49,
        34,
        36,
        12,
        25,
        2,
        43,
        28,
        49,
        16,
        49,
        0,
        1,
        49,
        29,
        15,
        24,
        49,
        7,
        41,
        28,
        44,
        0,
        35,
        8,
        0,
        13,
        17,
        20,
        17,
        49,
        32,
        28,
        31,
        34,
        36,
        42,
        49,
        0,
        27,
        49,
        26,
        25,
        37,
        2,
        0,
        33,
        24,
        1,
        9
      ],
      "weeklyRejected": [
        17,
        19,
        49,
        6,
        24,
        49,
        34,
        9,
        20,
        48,
        0,
        34,
        22,
        38,
        23,
        34,
        26,
        31,
        17,
        36,
        13,
        29,
        37,
        30,
        36,
        49,
        6,
        2,
        16,
        30,
        39,
        37,
        31,
        22,
        9,
        14,
        49,
        18,
        6,
        23,
        30,
        49,
        35,
        12,
        37,
        49,
        13,
        35,
        18,
        19,
        0,
        31
      ],
      "weeklyHold": [
        0,
        43,
        31,
        15,
        20,
        30,
        6,
        41,
        0,
        4,
        36,
        44,
        32,
        31,
        22,
        18,
        40,
        31,
        48,
        9,
        0,
        3,
        46,
        49,
        39,
        19,
        13,
        22,
        23,
        14,
        31,
        2,
        12,
        14,
        15,
        41,
        31,
        21,
        35,
        44,
        17,
        22,
        28,
        32,
        28,
        36,
        0,
        43,
        8,
        18,
        45,
        33
      ],
      "weeklyBackordered": [
        13,
        45,
        7,
        0,
        49,
        36,
        14,
        41,
        49,
        0,
        49,
        39,
        3,
        49,
        30,
        13,
        32,
        44,
        20,
        17,
        6,
        24,
        4,
        19,
        15,
        5,
        19,
        49,
        30,
        49,
        32,
        21,
        11,
        49,
        0,
        0,
        10,
        14,
        8,
        37,
        18,
        48,
        31,
        36,
        31,
        45,
        48,
        28,
        17,
        14,
        8,
        44
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyAccepted": [
        43,
        31,
        14,
        36,
        29,
        6,
        1,
        43,
        19,
        0,
        39,
        0,
        25,
        49,
        25,
        45,
        49,
        0,
        0,
        0,
        15,
        11,
        1,
        9,
        14,
        24,
        40,
        31,
        49,
        49,
        33,
        20,
        26,
        17,
        34,
        16,
        46,
        25,
        24,
        49,
        0,
        13,
        27,
        26,
        49,
        45,
        11,
        18,
        35,
        23,
        49,
        11
      ],
      "weeklyRejected": [
        29,
        21,
        14,
        5,
        49,
        15,
        26,
        34,
        49,
        26,
        27,
        0,
        4,
        33,
        30,
        0,
        39,
        49,
        4,
        21,
        28,
        45,
        6,
        14,
        36,
        20,
        43,
        11,
        48,
        28,
        30,
        18,
        0,
        49,
        30,
        49,
        10,
        49,
        1,
        0,
        14,
        44,
        12,
        2,
        43,
        0,
        17,
        12,
        0,
        46,
        1,
        49
      ],
      "weeklyHold": [
        40,
        23,
        24,
        5,
        27,
        18,
        0,
        12,
        1,
        45,
        44,
        37,
        35,
        49,
        49,
        0,
        33,
        14,
        0,
        37,
        0,
        49,
        39,
        17,
        32,
        9,
        1,
        13,
        49,
        26,
        17,
        48,
        10,
        46,
        38,
        12,
        2,
        18,
        37,
        4,
        49,
        34,
        14,
        22,
        41,
        49,
        32,
        47,
        16,
        49,
        41,
        35
      ],
      "weeklyBackordered": [
        0,
        49,
        0,
        19,
        42,
        46,
        18,
        32,
        35,
        0,
        39,
        16,
        25,
        16,
        16,
        41,
        47,
        15,
        0,
        40,
        27,
        18,
        0,
        0,
        35,
        31,
        1,
        40,
        25,
        49,
        4,
        48,
        22,
        49,
        19,
        11,
        49,
        0,
        0,
        0,
        48,
        38,
        23,
        49,
        25,
        3,
        46,
        28,
        0,
        23,
        49,
        49
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        10,
        8,
        27,
        42,
        36,
        16,
        1,
        39,
        38,
        49,
        33,
        29,
        49,
        26,
        45,
        40,
        19,
        26,
        0,
        10,
        11,
        40,
        49,
        28,
        0,
        27,
        16,
        24,
        16,
        23,
        4,
        0,
        49,
        0,
        2,
        42,
        22,
        7,
        26,
        49,
        0,
        24,
        49,
        49,
        49,
        0,
        16,
        0,
        18,
        6,
        22,
        40
      ],
      "weeklyRejected": [
        49,
        35,
        31,
        39,
        29,
        0,
        38,
        28,
        49,
        39,
        21,
        48,
        38,
        13,
        2,
        32,
        45,
        35,
        6,
        4,
        8,
        36,
        6,
        29,
        36,
        25,
        1,
        4,
        48,
        13,
        29,
        19,
        5,
        32,
        29,
        35,
        49,
        0,
        1,
        32,
        39,
        14,
        41,
        26,
        47,
        45,
        29,
        18,
        28,
        48,
        0,
        40
      ],
      "weeklyHold": [
        0,
        11,
        27,
        15,
        38,
        32,
        31,
        32,
        26,
        24,
        18,
        12,
        28,
        33,
        20,
        17,
        18,
        42,
        11,
        49,
        42,
        49,
        15,
        4,
        0,
        40,
        18,
        45,
        31,
        14,
        48,
        12,
        20,
        47,
        20,
        19,
        19,
        0,
        37,
        16,
        0,
        49,
        17,
        49,
        28,
        40,
        25,
        27,
        2,
        49,
        49,
        49
      ],
      "weeklyBackordered": [
        16,
        20,
        19,
        38,
        49,
        38,
        0,
        44,
        9,
        27,
        49,
        45,
        6,
        26,
        34,
        39,
        49,
        38,
        21,
        49,
        44,
        20,
        25,
        27,
        21,
        17,
        11,
        10,
        13,
        42,
        24,
        49,
        30,
        19,
        2,
        0,
        43,
        0,
        0,
        16,
        0,
        32,
        4,
        32,
        24,
        48,
        49,
        49,
        5,
        5,
        36,
        45
      ]
    },
    {
      "id": "048701CE",
      "weeklyAccepted": [
        0,
        49,
        19,
        0,
        37,
        9,
        4,
        18,
        23,
        22,
        31,
        7,
        29,
        0,
        21,
        10,
        4,
        1,
        48,
        12,
        0,
        35,
        31,
        28,
        15,
        0,
        29,
        49,
        45,
        49,
        26,
        35,
        49,
        24,
        3,
        23,
        15,
        21,
        22,
        49,
        43,
        49,
        31,
        0,
        32,
        0,
        22,
        0,
        3,
        34,
        0,
        0
      ],
      "weeklyRejected": [
        46,
        21,
        25,
        2,
        49,
        32,
        0,
        42,
        48,
        30,
        49,
        32,
        0,
        35,
        48,
        29,
        28,
        37,
        49,
        42,
        29,
        28,
        9,
        49,
        32,
        29,
        10,
        1,
        44,
        34,
        17,
        29,
        42,
        49,
        0,
        27,
        21,
        21,
        29,
        43,
        44,
        18,
        49,
        19,
        46,
        15,
        7,
        23,
        0,
        37,
        14,
        49
      ],
      "weeklyHold": [
        25,
        8,
        46,
        1,
        22,
        26,
        14,
        17,
        3,
        0,
        47,
        37,
        8,
        35,
        40,
        0,
        17,
        0,
        38,
        12,
        26,
        38,
        26,
        20,
        27,
        1,
        36,
        12,
        49,
        15,
        48,
        33,
        0,
        44,
        48,
        35,
        23,
        0,
        16,
        0,
        3,
        49,
        49,
        22,
        49,
        49,
        0,
        49,
        0,
        7,
        4,
        21
      ],
      "weeklyBackordered": [
        15,
        49,
        0,
        43,
        23,
        46,
        0,
        29,
        10,
        36,
        6,
        0,
        3,
        27,
        49,
        47,
        48,
        26,
        0,
        10,
        28,
        1,
        35,
        49,
        1,
        1,
        1,
        0,
        16,
        49,
        9,
        49,
        38,
        26,
        34,
        23,
        30,
        0,
        12,
        34,
        2,
        48,
        0,
        49,
        22,
        29,
        49,
        27,
        14,
        26,
        19,
        7
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyAccepted": [
        0,
        49,
        21,
        9,
        30,
        12,
        11,
        24,
        48,
        46,
        0,
        15,
        9,
        13,
        48,
        11,
        49,
        0,
        0,
        9,
        40,
        16,
        40,
        29,
        0,
        19,
        22,
        37,
        49,
        10,
        0,
        46,
        49,
        23,
        48,
        46,
        38,
        15,
        34,
        30,
        29,
        21,
        49,
        49,
        12,
        8,
        44,
        23,
        49,
        26,
        0,
        33
      ],
      "weeklyRejected": [
        49,
        0,
        28,
        24,
        24,
        0,
        49,
        27,
        16,
        1,
        23,
        45,
        1,
        48,
        48,
        10,
        21,
        40,
        48,
        25,
        49,
        49,
        21,
        49,
        30,
        40,
        49,
        23,
        18,
        0,
        37,
        18,
        48,
        27,
        15,
        49,
        49,
        28,
        13,
        0,
        46,
        22,
        27,
        16,
        46,
        0,
        9,
        28,
        0,
        0,
        10,
        30
      ],
      "weeklyHold": [
        19,
        20,
        8,
        19,
        23,
        47,
        37,
        30,
        30,
        27,
        0,
        17,
        31,
        25,
        34,
        26,
        0,
        34,
        15,
        43,
        17,
        24,
        6,
        32,
        23,
        48,
        49,
        16,
        7,
        28,
        48,
        14,
        14,
        34,
        19,
        39,
        49,
        0,
        49,
        31,
        13,
        48,
        7,
        0,
        44,
        41,
        40,
        21,
        0,
        6,
        49,
        0
      ],
      "weeklyBackordered": [
        21,
        37,
        2,
        28,
        49,
        46,
        40,
        39,
        25,
        33,
        49,
        15,
        23,
        3,
        21,
        31,
        1,
        49,
        15,
        0,
        15,
        12,
        8,
        29,
        16,
        16,
        15,
        0,
        28,
        49,
        4,
        32,
        39,
        49,
        40,
        0,
        15,
        0,
        10,
        11,
        4,
        33,
        0,
        49,
        0,
        49,
        0,
        22,
        6,
        0,
        22,
        25
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        0,
        48,
        21,
        18,
        22,
        24,
        28,
        26,
        13,
        38,
        0,
        21,
        24,
        49,
        27,
        0,
        49,
        12,
        0,
        49,
        12,
        47,
        0,
        45,
        24,
        17,
        15,
        0,
        36,
        49,
        11,
        17,
        49,
        23,
        24,
        37,
        35,
        48,
        49,
        49,
        16,
        43,
        42,
        11,
        49,
        12,
        40,
        8,
        49,
        36,
        25,
        34
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        10,
        25,
        42,
        29,
        5,
        47,
        20,
        0,
        17,
        28,
        22,
        17,
        24,
        30,
        43,
        49,
        10,
        12,
        44,
        8,
        11,
        0,
        26,
        49,
        49,
        44,
        21,
        11,
        2,
        12,
        47,
        17,
        49,
        43,
        49,
        10,
        49,
        18,
        4,
        0,
        2,
        39,
        0,
        15,
        8,
        20,
        25,
        23,
        42
      ],
      "weeklyHold": [
        49,
        0,
        4,
        12,
        26,
        48,
        9,
        25,
        12,
        31,
        40,
        48,
        35,
        44,
        21,
        0,
        49,
        22,
        46,
        46,
        0,
        18,
        27,
        21,
        40,
        48,
        36,
        0,
        34,
        14,
        48,
        0,
        0,
        49,
        12,
        26,
        23,
        23,
        31,
        21,
        46,
        42,
        5,
        17,
        21,
        34,
        35,
        0,
        0,
        23,
        0,
        28
      ],
      "weeklyBackordered": [
        28,
        16,
        48,
        12,
        35,
        35,
        27,
        40,
        19,
        43,
        31,
        49,
        10,
        49,
        43,
        49,
        1,
        35,
        0,
        14,
        44,
        30,
        10,
        49,
        13,
        22,
        1,
        27,
        14,
        49,
        33,
        27,
        46,
        37,
        6,
        0,
        35,
        0,
        16,
        49,
        4,
        25,
        0,
        28,
        24,
        41,
        28,
        0,
        21,
        44,
        49,
        39
      ]
    },
    {
      "id": "965102D9",
      "weeklyAccepted": [
        0,
        1,
        27,
        36,
        40,
        15,
        9,
        20,
        48,
        30,
        49,
        0,
        38,
        27,
        1,
        49,
        22,
        38,
        47,
        0,
        8,
        2,
        23,
        45,
        32,
        37,
        9,
        0,
        36,
        5,
        23,
        0,
        49,
        11,
        20,
        49,
        34,
        48,
        8,
        19,
        14,
        13,
        49,
        26,
        34,
        1,
        25,
        16,
        23,
        34,
        49,
        28
      ],
      "weeklyRejected": [
        40,
        32,
        37,
        40,
        30,
        0,
        32,
        3,
        0,
        30,
        25,
        15,
        43,
        48,
        46,
        49,
        0,
        49,
        25,
        17,
        46,
        25,
        25,
        24,
        0,
        49,
        18,
        35,
        48,
        40,
        21,
        35,
        10,
        8,
        34,
        49,
        11,
        4,
        1,
        26,
        48,
        41,
        37,
        7,
        49,
        12,
        0,
        12,
        4,
        49,
        8,
        43
      ],
      "weeklyHold": [
        20,
        49,
        49,
        1,
        49,
        42,
        7,
        4,
        48,
        27,
        41,
        34,
        39,
        41,
        11,
        10,
        14,
        35,
        0,
        0,
        22,
        49,
        0,
        12,
        32,
        16,
        39,
        24,
        19,
        8,
        48,
        4,
        23,
        49,
        15,
        35,
        16,
        0,
        26,
        33,
        49,
        49,
        5,
        26,
        49,
        38,
        47,
        6,
        34,
        42,
        49,
        14
      ],
      "weeklyBackordered": [
        49,
        37,
        0,
        0,
        49,
        46,
        37,
        10,
        9,
        0,
        41,
        47,
        23,
        35,
        38,
        37,
        49,
        49,
        1,
        17,
        49,
        17,
        33,
        21,
        12,
        20,
        1,
        29,
        16,
        48,
        16,
        30,
        0,
        18,
        0,
        2,
        31,
        0,
        29,
        19,
        24,
        27,
        7,
        49,
        35,
        0,
        49,
        12,
        25,
        49,
        38,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        2,
        47,
        0,
        0,
        19,
        19,
        7,
        11,
        39,
        42,
        32,
        1,
        3,
        36,
        17,
        6,
        31,
        1,
        3,
        34,
        0,
        45,
        0,
        17,
        23,
        31,
        49,
        33,
        16,
        36,
        22,
        20,
        36,
        0,
        38,
        49,
        47,
        48,
        34,
        49,
        15,
        31,
        47,
        2,
        35,
        16,
        0,
        4,
        18,
        13,
        9,
        48
      ],
      "weeklyRejected": [
        49,
        2,
        34,
        26,
        49,
        18,
        49,
        34,
        44,
        15,
        5,
        1,
        0,
        36,
        48,
        0,
        15,
        49,
        49,
        18,
        0,
        15,
        25,
        0,
        3,
        36,
        25,
        49,
        12,
        22,
        13,
        33,
        48,
        41,
        19,
        49,
        49,
        8,
        1,
        18,
        41,
        15,
        12,
        25,
        27,
        0,
        9,
        28,
        27,
        38,
        0,
        30
      ],
      "weeklyHold": [
        0,
        42,
        49,
        10,
        49,
        48,
        18,
        18,
        16,
        16,
        11,
        40,
        15,
        47,
        47,
        20,
        16,
        0,
        0,
        1,
        17,
        29,
        49,
        49,
        11,
        19,
        49,
        10,
        34,
        48,
        47,
        10,
        0,
        49,
        17,
        44,
        13,
        42,
        36,
        45,
        27,
        2,
        30,
        0,
        34,
        25,
        19,
        34,
        0,
        14,
        11,
        28
      ],
      "weeklyBackordered": [
        0,
        32,
        25,
        10,
        27,
        46,
        17,
        49,
        38,
        21,
        45,
        6,
        10,
        38,
        32,
        27,
        49,
        49,
        0,
        9,
        36,
        38,
        38,
        49,
        11,
        34,
        34,
        26,
        1,
        44,
        38,
        14,
        20,
        33,
        0,
        0,
        28,
        21,
        10,
        49,
        0,
        46,
        2,
        30,
        14,
        40,
        0,
        37,
        13,
        25,
        47,
        24
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        37,
        41,
        49,
        35,
        42,
        38,
        37,
        49,
        23,
        6,
        49,
        0,
        20,
        19,
        1,
        40,
        45,
        0,
        0,
        33,
        9,
        0,
        15,
        13,
        12,
        31,
        0,
        31,
        13,
        35,
        20,
        43,
        30,
        24,
        48,
        28,
        36,
        48,
        49,
        33,
        49,
        35,
        45,
        43,
        49,
        0,
        0,
        38,
        34,
        39,
        49,
        4
      ],
      "weeklyRejected": [
        2,
        10,
        12,
        0,
        24,
        20,
        19,
        49,
        9,
        26,
        49,
        23,
        49,
        25,
        23,
        13,
        41,
        49,
        21,
        24,
        29,
        49,
        19,
        46,
        10,
        20,
        49,
        17,
        31,
        33,
        16,
        41,
        14,
        20,
        22,
        49,
        8,
        7,
        37,
        49,
        31,
        0,
        22,
        11,
        49,
        0,
        10,
        49,
        0,
        31,
        0,
        33
      ],
      "weeklyHold": [
        1,
        49,
        40,
        5,
        20,
        10,
        26,
        49,
        32,
        9,
        47,
        35,
        15,
        15,
        0,
        28,
        0,
        26,
        17,
        44,
        0,
        0,
        0,
        0,
        35,
        28,
        26,
        13,
        18,
        8,
        41,
        29,
        0,
        16,
        29,
        44,
        18,
        19,
        36,
        0,
        0,
        49,
        49,
        11,
        38,
        30,
        0,
        20,
        0,
        17,
        35,
        49
      ],
      "weeklyBackordered": [
        49,
        49,
        0,
        25,
        33,
        32,
        18,
        1,
        25,
        0,
        49,
        39,
        5,
        29,
        0,
        39,
        32,
        18,
        19,
        41,
        32,
        26,
        26,
        49,
        37,
        4,
        27,
        40,
        49,
        45,
        0,
        49,
        36,
        3,
        28,
        34,
        24,
        14,
        14,
        28,
        0,
        23,
        0,
        35,
        0,
        15,
        49,
        22,
        4,
        49,
        1,
        23
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyAccepted": [
        0,
        3,
        39,
        39,
        16,
        33,
        10,
        26,
        33,
        29,
        20,
        7,
        0,
        31,
        1,
        36,
        46,
        20,
        32,
        12,
        38,
        32,
        40,
        49,
        14,
        7,
        17,
        7,
        1,
        49,
        38,
        1,
        49,
        43,
        45,
        2,
        47,
        15,
        20,
        49,
        49,
        43,
        49,
        11,
        26,
        36,
        27,
        40,
        49,
        49,
        18,
        14
      ],
      "weeklyRejected": [
        49,
        0,
        49,
        47,
        49,
        43,
        6,
        32,
        49,
        29,
        0,
        20,
        32,
        7,
        48,
        11,
        39,
        13,
        44,
        25,
        34,
        18,
        33,
        49,
        0,
        49,
        42,
        0,
        48,
        23,
        45,
        47,
        14,
        34,
        32,
        49,
        17,
        6,
        1,
        29,
        28,
        49,
        39,
        34,
        44,
        40,
        11,
        0,
        0,
        24,
        14,
        31
      ],
      "weeklyHold": [
        5,
        11,
        45,
        1,
        49,
        30,
        49,
        14,
        0,
        25,
        45,
        24,
        44,
        22,
        10,
        48,
        49,
        13,
        0,
        0,
        41,
        25,
        19,
        31,
        0,
        47,
        24,
        0,
        49,
        0,
        47,
        0,
        32,
        45,
        44,
        46,
        15,
        36,
        30,
        47,
        21,
        10,
        34,
        49,
        22,
        49,
        49,
        49,
        14,
        22,
        49,
        16
      ],
      "weeklyBackordered": [
        40,
        22,
        2,
        25,
        46,
        46,
        49,
        49,
        49,
        21,
        0,
        39,
        40,
        0,
        5,
        49,
        11,
        15,
        0,
        21,
        46,
        44,
        41,
        3,
        20,
        12,
        40,
        12,
        1,
        49,
        47,
        4,
        28,
        48,
        0,
        0,
        32,
        7,
        30,
        37,
        21,
        13,
        19,
        49,
        46,
        38,
        49,
        30,
        31,
        42,
        40,
        32
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyAccepted": [
        23,
        40,
        10,
        1,
        33,
        42,
        34,
        24,
        45,
        0,
        36,
        0,
        23,
        18,
        32,
        49,
        37,
        0,
        30,
        31,
        0,
        0,
        20,
        14,
        23,
        0,
        13,
        48,
        4,
        9,
        22,
        39,
        28,
        37,
        48,
        15,
        39,
        24,
        28,
        49,
        34,
        47,
        49,
        21,
        35,
        0,
        0,
        49,
        0,
        49,
        49,
        7
      ],
      "weeklyRejected": [
        49,
        20,
        24,
        39,
        4,
        49,
        29,
        19,
        49,
        48,
        34,
        23,
        24,
        35,
        40,
        22,
        0,
        49,
        38,
        5,
        15,
        24,
        23,
        8,
        0,
        31,
        27,
        24,
        8,
        48,
        0,
        23,
        16,
        18,
        38,
        49,
        21,
        49,
        27,
        28,
        33,
        0,
        27,
        0,
        0,
        22,
        0,
        0,
        0,
        16,
        0,
        41
      ],
      "weeklyHold": [
        0,
        49,
        12,
        1,
        0,
        24,
        23,
        39,
        11,
        49,
        37,
        1,
        36,
        17,
        49,
        4,
        0,
        24,
        16,
        28,
        49,
        19,
        11,
        38,
        30,
        18,
        49,
        19,
        37,
        25,
        30,
        14,
        37,
        31,
        41,
        49,
        5,
        0,
        17,
        49,
        0,
        29,
        14,
        41,
        14,
        27,
        4,
        49,
        9,
        49,
        3,
        29
      ],
      "weeklyBackordered": [
        49,
        4,
        17,
        24,
        41,
        46,
        39,
        6,
        36,
        5,
        0,
        48,
        5,
        0,
        0,
        15,
        31,
        36,
        0,
        4,
        22,
        5,
        30,
        49,
        28,
        15,
        23,
        41,
        10,
        49,
        0,
        13,
        4,
        0,
        0,
        35,
        21,
        4,
        49,
        0,
        40,
        47,
        0,
        42,
        2,
        49,
        49,
        15,
        17,
        47,
        46,
        1
      ]
    },
    {
      "id": "F413914D",
      "weeklyAccepted": [
        0,
        49,
        0,
        45,
        14,
        8,
        43,
        17,
        45,
        10,
        0,
        0,
        0,
        49,
        31,
        0,
        39,
        0,
        49,
        22,
        49,
        38,
        38,
        8,
        9,
        44,
        9,
        26,
        37,
        35,
        0,
        0,
        49,
        33,
        46,
        38,
        35,
        43,
        34,
        49,
        18,
        46,
        29,
        31,
        49,
        49,
        7,
        49,
        0,
        49,
        14,
        15
      ],
      "weeklyRejected": [
        49,
        14,
        49,
        38,
        49,
        34,
        13,
        30,
        21,
        1,
        0,
        35,
        18,
        41,
        42,
        29,
        49,
        17,
        26,
        0,
        19,
        36,
        49,
        49,
        41,
        31,
        48,
        34,
        0,
        3,
        0,
        41,
        27,
        36,
        3,
        49,
        17,
        32,
        8,
        7,
        48,
        49,
        24,
        0,
        47,
        0,
        6,
        26,
        0,
        35,
        23,
        0
      ],
      "weeklyHold": [
        27,
        49,
        49,
        18,
        17,
        15,
        40,
        5,
        43,
        14,
        17,
        24,
        48,
        44,
        49,
        10,
        10,
        13,
        42,
        49,
        49,
        42,
        1,
        8,
        25,
        25,
        36,
        22,
        36,
        31,
        48,
        27,
        2,
        0,
        21,
        0,
        21,
        11,
        49,
        49,
        49,
        34,
        0,
        49,
        45,
        46,
        47,
        1,
        7,
        49,
        49,
        49
      ],
      "weeklyBackordered": [
        49,
        5,
        0,
        33,
        38,
        20,
        0,
        37,
        45,
        0,
        42,
        36,
        33,
        0,
        30,
        26,
        49,
        49,
        9,
        12,
        19,
        49,
        8,
        32,
        1,
        35,
        30,
        0,
        49,
        47,
        3,
        38,
        38,
        0,
        20,
        0,
        41,
        26,
        0,
        32,
        0,
        43,
        2,
        49,
        23,
        49,
        49,
        34,
        30,
        35,
        28,
        14
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        28,
        49,
        49,
        19,
        16,
        24,
        1,
        37,
        38,
        9,
        21,
        0,
        49,
        0,
        27,
        13,
        35,
        0,
        40,
        18,
        11,
        19,
        49,
        14,
        49,
        47,
        0,
        26,
        5,
        49,
        6,
        42,
        49,
        36,
        48,
        29,
        47,
        1,
        49,
        49,
        23,
        45,
        49,
        49,
        41,
        0,
        0,
        49,
        32,
        49,
        47,
        48
      ],
      "weeklyRejected": [
        26,
        45,
        30,
        28,
        33,
        2,
        37,
        22,
        40,
        9,
        42,
        21,
        0,
        48,
        44,
        0,
        1,
        49,
        42,
        7,
        21,
        49,
        49,
        43,
        34,
        49,
        23,
        49,
        48,
        40,
        7,
        37,
        48,
        49,
        15,
        49,
        39,
        49,
        1,
        31,
        35,
        26,
        9,
        0,
        47,
        44,
        34,
        49,
        23,
        27,
        0,
        25
      ],
      "weeklyHold": [
        0,
        20,
        49,
        15,
        43,
        48,
        0,
        36,
        49,
        14,
        0,
        25,
        17,
        38,
        28,
        35,
        0,
        0,
        16,
        30,
        17,
        0,
        26,
        0,
        48,
        24,
        38,
        27,
        34,
        0,
        48,
        12,
        22,
        30,
        35,
        46,
        17,
        17,
        49,
        28,
        26,
        12,
        6,
        0,
        49,
        0,
        0,
        33,
        0,
        0,
        45,
        49
      ],
      "weeklyBackordered": [
        19,
        31,
        0,
        41,
        48,
        40,
        49,
        2,
        3,
        0,
        15,
        29,
        14,
        17,
        0,
        1,
        17,
        30,
        0,
        34,
        24,
        27,
        19,
        26,
        40,
        34,
        1,
        30,
        20,
        32,
        0,
        40,
        31,
        28,
        0,
        11,
        16,
        17,
        42,
        40,
        0,
        48,
        6,
        49,
        5,
        39,
        30,
        8,
        16,
        11,
        49,
        33
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        14,
        40,
        49,
        26,
        34,
        0,
        21,
        49,
        48,
        0,
        36,
        16,
        29,
        16,
        49,
        26,
        49,
        0,
        0,
        41,
        49,
        0,
        31,
        0,
        36,
        47,
        13,
        43,
        1,
        20,
        28,
        30,
        33,
        41,
        48,
        31,
        37,
        39,
        19,
        49,
        24,
        21,
        5,
        37,
        49,
        0,
        4,
        20,
        16,
        35,
        29,
        7
      ],
      "weeklyRejected": [
        42,
        26,
        38,
        19,
        49,
        45,
        12,
        34,
        49,
        20,
        30,
        22,
        49,
        43,
        40,
        18,
        0,
        49,
        31,
        3,
        29,
        49,
        33,
        31,
        6,
        43,
        21,
        26,
        31,
        31,
        49,
        33,
        22,
        49,
        3,
        49,
        49,
        37,
        11,
        18,
        48,
        6,
        44,
        0,
        49,
        49,
        14,
        18,
        24,
        46,
        11,
        14
      ],
      "weeklyHold": [
        26,
        27,
        44,
        15,
        5,
        48,
        4,
        19,
        27,
        21,
        47,
        10,
        20,
        18,
        32,
        0,
        0,
        0,
        13,
        33,
        16,
        27,
        21,
        49,
        16,
        9,
        35,
        1,
        49,
        0,
        29,
        10,
        0,
        49,
        48,
        49,
        44,
        10,
        37,
        18,
        15,
        22,
        0,
        49,
        0,
        48,
        8,
        47,
        0,
        27,
        47,
        47
      ],
      "weeklyBackordered": [
        8,
        22,
        48,
        42,
        45,
        36,
        3,
        49,
        49,
        13,
        49,
        25,
        38,
        0,
        26,
        1,
        48,
        44,
        0,
        19,
        2,
        0,
        14,
        0,
        6,
        8,
        22,
        24,
        8,
        45,
        1,
        29,
        48,
        31,
        0,
        25,
        49,
        11,
        5,
        7,
        39,
        45,
        30,
        28,
        49,
        16,
        49,
        47,
        44,
        19,
        45,
        4
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        49,
        30,
        35,
        0,
        27,
        0,
        45,
        21,
        37,
        17,
        3,
        17,
        49,
        49,
        1,
        15,
        49,
        7,
        44,
        5,
        12,
        43,
        38,
        32,
        44,
        23,
        0,
        44,
        1,
        34,
        33,
        28,
        49,
        6,
        7,
        20,
        0,
        39,
        0,
        49,
        3,
        42,
        49,
        46,
        49,
        0,
        0,
        0,
        28,
        49,
        41,
        5
      ],
      "weeklyRejected": [
        41,
        29,
        22,
        21,
        21,
        40,
        13,
        38,
        49,
        38,
        31,
        49,
        2,
        10,
        39,
        0,
        39,
        24,
        49,
        10,
        12,
        43,
        47,
        22,
        24,
        43,
        33,
        44,
        44,
        8,
        48,
        35,
        32,
        23,
        0,
        49,
        4,
        0,
        49,
        0,
        24,
        0,
        33,
        30,
        39,
        5,
        0,
        47,
        0,
        29,
        20,
        26
      ],
      "weeklyHold": [
        17,
        27,
        37,
        27,
        41,
        7,
        49,
        9,
        14,
        34,
        35,
        8,
        5,
        44,
        34,
        18,
        32,
        4,
        26,
        14,
        0,
        35,
        49,
        16,
        2,
        26,
        12,
        38,
        23,
        41,
        48,
        7,
        0,
        47,
        31,
        31,
        33,
        0,
        22,
        30,
        10,
        35,
        0,
        33,
        49,
        46,
        22,
        45,
        0,
        20,
        49,
        0
      ],
      "weeklyBackordered": [
        15,
        0,
        24,
        49,
        49,
        40,
        0,
        47,
        22,
        17,
        49,
        25,
        26,
        49,
        31,
        31,
        49,
        34,
        23,
        49,
        26,
        38,
        9,
        48,
        1,
        49,
        36,
        36,
        22,
        46,
        10,
        45,
        37,
        4,
        11,
        15,
        35,
        6,
        41,
        20,
        0,
        48,
        10,
        49,
        24,
        6,
        42,
        27,
        30,
        3,
        19,
        24
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        14,
        42,
        49,
        41,
        46,
        16,
        49,
        29,
        47,
        22,
        49,
        21,
        22,
        23,
        18,
        0,
        43,
        14,
        21,
        27,
        36,
        21,
        23,
        31,
        19,
        41,
        28,
        14,
        6,
        24,
        0,
        25,
        49,
        49,
        34,
        47,
        10,
        37,
        47,
        49,
        9,
        19,
        47,
        34,
        49,
        0,
        19,
        25,
        13,
        32,
        30,
        48
      ],
      "weeklyRejected": [
        26,
        11,
        20,
        46,
        24,
        25,
        33,
        10,
        31,
        33,
        10,
        38,
        40,
        0,
        11,
        23,
        38,
        32,
        41,
        21,
        18,
        23,
        40,
        49,
        20,
        15,
        49,
        19,
        33,
        41,
        44,
        47,
        48,
        49,
        44,
        24,
        0,
        4,
        21,
        30,
        35,
        20,
        43,
        1,
        34,
        18,
        16,
        28,
        48,
        1,
        24,
        21
      ],
      "weeklyHold": [
        10,
        16,
        19,
        1,
        42,
        0,
        33,
        2,
        3,
        17,
        22,
        30,
        18,
        48,
        49,
        0,
        10,
        5,
        7,
        22,
        0,
        49,
        23,
        24,
        34,
        48,
        0,
        20,
        32,
        38,
        48,
        20,
        0,
        14,
        30,
        47,
        27,
        31,
        32,
        7,
        49,
        49,
        11,
        19,
        49,
        31,
        4,
        23,
        0,
        0,
        49,
        30
      ],
      "weeklyBackordered": [
        0,
        9,
        1,
        42,
        29,
        46,
        0,
        34,
        17,
        28,
        49,
        0,
        38,
        30,
        0,
        49,
        23,
        43,
        6,
        37,
        31,
        16,
        10,
        49,
        38,
        11,
        44,
        9,
        25,
        5,
        15,
        49,
        33,
        22,
        0,
        15,
        37,
        0,
        7,
        22,
        15,
        21,
        24,
        49,
        32,
        33,
        49,
        18,
        21,
        39,
        44,
        24
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyAccepted": [
        2,
        18,
        12,
        44,
        23,
        0,
        2,
        31,
        27,
        23,
        0,
        0,
        49,
        49,
        8,
        26,
        43,
        9,
        0,
        36,
        25,
        14,
        33,
        39,
        36,
        41,
        49,
        0,
        36,
        49,
        25,
        13,
        48,
        6,
        48,
        41,
        47,
        26,
        49,
        39,
        25,
        17,
        49,
        45,
        46,
        16,
        23,
        39,
        12,
        15,
        0,
        19
      ],
      "weeklyRejected": [
        49,
        28,
        29,
        0,
        41,
        0,
        40,
        0,
        19,
        14,
        49,
        0,
        40,
        20,
        25,
        20,
        26,
        49,
        3,
        12,
        0,
        48,
        25,
        15,
        19,
        49,
        22,
        31,
        0,
        18,
        4,
        26,
        48,
        31,
        46,
        49,
        48,
        4,
        6,
        49,
        39,
        38,
        49,
        3,
        46,
        49,
        25,
        28,
        48,
        26,
        0,
        49
      ],
      "weeklyHold": [
        30,
        0,
        49,
        1,
        46,
        5,
        25,
        6,
        0,
        0,
        10,
        29,
        21,
        19,
        34,
        31,
        14,
        12,
        23,
        0,
        47,
        18,
        37,
        48,
        16,
        42,
        33,
        24,
        22,
        1,
        48,
        17,
        4,
        35,
        1,
        21,
        0,
        45,
        25,
        24,
        29,
        24,
        0,
        6,
        8,
        0,
        28,
        14,
        0,
        45,
        32,
        21
      ],
      "weeklyBackordered": [
        12,
        17,
        0,
        42,
        11,
        42,
        30,
        17,
        0,
        14,
        49,
        0,
        12,
        5,
        38,
        23,
        47,
        28,
        19,
        44,
        43,
        22,
        21,
        40,
        21,
        9,
        28,
        28,
        14,
        35,
        34,
        22,
        27,
        41,
        6,
        0,
        1,
        14,
        36,
        0,
        31,
        48,
        9,
        49,
        13,
        35,
        41,
        15,
        44,
        0,
        22,
        44
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyAccepted": [
        15,
        49,
        26,
        30,
        1,
        14,
        1,
        45,
        17,
        0,
        49,
        42,
        10,
        49,
        1,
        2,
        49,
        0,
        49,
        0,
        0,
        29,
        48,
        25,
        0,
        10,
        0,
        36,
        15,
        49,
        18,
        13,
        49,
        12,
        34,
        22,
        19,
        27,
        18,
        17,
        36,
        43,
        49,
        49,
        40,
        19,
        0,
        30,
        0,
        31,
        0,
        14
      ],
      "weeklyRejected": [
        35,
        20,
        49,
        0,
        49,
        17,
        41,
        20,
        49,
        24,
        2,
        21,
        49,
        27,
        40,
        18,
        39,
        33,
        49,
        20,
        19,
        49,
        4,
        43,
        7,
        49,
        3,
        7,
        20,
        14,
        38,
        21,
        0,
        49,
        29,
        49,
        49,
        49,
        1,
        33,
        32,
        48,
        43,
        12,
        34,
        10,
        17,
        23,
        15,
        43,
        16,
        31
      ],
      "weeklyHold": [
        49,
        34,
        20,
        1,
        25,
        41,
        30,
        2,
        29,
        20,
        47,
        35,
        39,
        25,
        36,
        49,
        49,
        26,
        48,
        0,
        20,
        0,
        0,
        7,
        28,
        23,
        49,
        30,
        49,
        35,
        48,
        13,
        0,
        32,
        31,
        32,
        22,
        24,
        49,
        49,
        31,
        27,
        8,
        49,
        9,
        47,
        49,
        47,
        0,
        13,
        25,
        18
      ],
      "weeklyBackordered": [
        20,
        0,
        0,
        40,
        41,
        46,
        46,
        49,
        22,
        30,
        10,
        6,
        7,
        0,
        18,
        37,
        26,
        39,
        0,
        42,
        6,
        12,
        7,
        49,
        34,
        37,
        33,
        15,
        9,
        37,
        3,
        37,
        0,
        49,
        0,
        29,
        2,
        0,
        25,
        40,
        24,
        48,
        9,
        37,
        33,
        27,
        43,
        31,
        18,
        21,
        30,
        18
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        37,
        1,
        7,
        6,
        11,
        28,
        1,
        40,
        48,
        39,
        35,
        35,
        0,
        49,
        1,
        48,
        9,
        9,
        13,
        49,
        31,
        27,
        18,
        30,
        15,
        38,
        6,
        31,
        18,
        49,
        21,
        32,
        49,
        20,
        27,
        22,
        0,
        33,
        20,
        13,
        30,
        45,
        36,
        14,
        49,
        28,
        0,
        33,
        12,
        35,
        10,
        8
      ],
      "weeklyRejected": [
        49,
        32,
        26,
        28,
        12,
        0,
        22,
        7,
        48,
        15,
        48,
        25,
        28,
        48,
        6,
        29,
        0,
        48,
        49,
        20,
        49,
        26,
        48,
        20,
        25,
        49,
        16,
        49,
        48,
        5,
        4,
        9,
        31,
        9,
        49,
        49,
        49,
        45,
        1,
        5,
        38,
        49,
        24,
        47,
        44,
        41,
        0,
        22,
        25,
        42,
        0,
        25
      ],
      "weeklyHold": [
        24,
        24,
        42,
        44,
        24,
        39,
        42,
        18,
        11,
        29,
        6,
        19,
        29,
        1,
        30,
        0,
        0,
        9,
        25,
        37,
        24,
        49,
        49,
        2,
        49,
        8,
        41,
        49,
        49,
        45,
        48,
        9,
        33,
        9,
        30,
        15,
        23,
        10,
        26,
        35,
        0,
        8,
        14,
        37,
        49,
        39,
        30,
        49,
        45,
        49,
        49,
        39
      ],
      "weeklyBackordered": [
        34,
        49,
        23,
        16,
        43,
        31,
        26,
        12,
        0,
        3,
        24,
        24,
        7,
        15,
        47,
        37,
        25,
        25,
        5,
        35,
        22,
        0,
        28,
        49,
        1,
        9,
        6,
        32,
        9,
        49,
        44,
        14,
        0,
        49,
        15,
        30,
        46,
        0,
        6,
        16,
        48,
        26,
        30,
        29,
        0,
        7,
        36,
        14,
        29,
        34,
        45,
        31
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        29,
        39,
        13,
        25,
        49,
        49,
        1,
        49,
        44,
        39,
        49,
        0,
        40,
        26,
        1,
        9,
        9,
        0,
        1,
        1,
        40,
        18,
        35,
        49,
        0,
        0,
        0,
        49,
        49,
        39,
        49,
        20,
        30,
        21,
        48,
        28,
        11,
        35,
        39,
        49,
        0,
        35,
        36,
        26,
        49,
        27,
        17,
        49,
        1,
        49,
        29,
        20
      ],
      "weeklyRejected": [
        11,
        18,
        26,
        37,
        17,
        0,
        12,
        49,
        28,
        37,
        49,
        18,
        27,
        48,
        21,
        0,
        22,
        35,
        44,
        0,
        19,
        49,
        11,
        9,
        2,
        49,
        16,
        27,
        35,
        28,
        37,
        47,
        48,
        7,
        35,
        49,
        16,
        21,
        1,
        24,
        33,
        49,
        30,
        14,
        28,
        35,
        19,
        49,
        0,
        36,
        8,
        48
      ],
      "weeklyHold": [
        18,
        27,
        49,
        1,
        0,
        22,
        37,
        37,
        35,
        0,
        27,
        11,
        1,
        7,
        32,
        16,
        0,
        48,
        45,
        8,
        0,
        43,
        26,
        0,
        27,
        26,
        28,
        48,
        25,
        3,
        47,
        21,
        12,
        28,
        25,
        2,
        15,
        30,
        40,
        19,
        45,
        18,
        16,
        40,
        49,
        44,
        16,
        0,
        2,
        10,
        46,
        49
      ],
      "weeklyBackordered": [
        33,
        47,
        34,
        38,
        32,
        17,
        40,
        41,
        46,
        24,
        17,
        49,
        3,
        44,
        2,
        1,
        39,
        36,
        6,
        16,
        25,
        0,
        14,
        22,
        1,
        1,
        24,
        39,
        40,
        26,
        7,
        27,
        41,
        23,
        29,
        0,
        49,
        0,
        12,
        37,
        5,
        48,
        35,
        27,
        24,
        15,
        5,
        32,
        0,
        31,
        1,
        1
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyAccepted": [
        0,
        49,
        8,
        48,
        1,
        35,
        1,
        15,
        9,
        18,
        28,
        0,
        28,
        30,
        28,
        0,
        12,
        13,
        0,
        49,
        40,
        10,
        35,
        29,
        0,
        43,
        49,
        23,
        15,
        35,
        15,
        32,
        49,
        9,
        48,
        43,
        17,
        19,
        6,
        49,
        9,
        49,
        33,
        36,
        49,
        12,
        11,
        24,
        6,
        15,
        49,
        31
      ],
      "weeklyRejected": [
        37,
        27,
        6,
        18,
        49,
        9,
        39,
        20,
        36,
        8,
        0,
        0,
        27,
        11,
        28,
        49,
        26,
        35,
        0,
        9,
        0,
        28,
        12,
        29,
        21,
        49,
        33,
        22,
        48,
        10,
        49,
        35,
        40,
        49,
        0,
        29,
        42,
        19,
        21,
        24,
        26,
        49,
        20,
        19,
        40,
        24,
        33,
        17,
        10,
        36,
        0,
        49
      ],
      "weeklyHold": [
        35,
        47,
        13,
        1,
        24,
        40,
        38,
        2,
        44,
        31,
        47,
        31,
        45,
        27,
        34,
        32,
        1,
        21,
        0,
        46,
        28,
        42,
        0,
        13,
        44,
        33,
        10,
        27,
        11,
        11,
        37,
        17,
        0,
        36,
        42,
        42,
        49,
        0,
        49,
        16,
        7,
        16,
        16,
        49,
        8,
        49,
        46,
        40,
        11,
        34,
        49,
        11
      ],
      "weeklyBackordered": [
        4,
        0,
        0,
        21,
        32,
        42,
        0,
        49,
        33,
        0,
        49,
        24,
        14,
        48,
        38,
        10,
        40,
        49,
        0,
        0,
        49,
        19,
        0,
        30,
        17,
        1,
        35,
        47,
        16,
        40,
        25,
        41,
        35,
        49,
        0,
        6,
        40,
        0,
        6,
        32,
        48,
        19,
        13,
        49,
        0,
        18,
        49,
        36,
        18,
        49,
        4,
        36
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        2,
        36,
        25,
        16,
        49,
        19,
        13,
        30,
        35,
        43,
        26,
        0,
        36,
        0,
        1,
        21,
        46,
        2,
        0,
        31,
        38,
        46,
        25,
        33,
        0,
        47,
        0,
        21,
        45,
        33,
        30,
        14,
        49,
        30,
        27,
        32,
        46,
        0,
        7,
        21,
        20,
        13,
        6,
        17,
        48,
        27,
        4,
        39,
        28,
        49,
        8,
        2
      ],
      "weeklyRejected": [
        27,
        41,
        39,
        28,
        10,
        42,
        21,
        49,
        39,
        17,
        3,
        20,
        26,
        29,
        29,
        49,
        30,
        46,
        0,
        49,
        49,
        8,
        48,
        49,
        43,
        49,
        39,
        35,
        24,
        24,
        7,
        17,
        48,
        26,
        42,
        43,
        49,
        0,
        13,
        25,
        37,
        12,
        0,
        26,
        10,
        22,
        0,
        28,
        22,
        49,
        0,
        30
      ],
      "weeklyHold": [
        33,
        14,
        49,
        23,
        48,
        10,
        35,
        40,
        15,
        18,
        21,
        4,
        34,
        27,
        23,
        24,
        0,
        28,
        49,
        24,
        24,
        49,
        28,
        1,
        49,
        18,
        0,
        49,
        49,
        49,
        48,
        48,
        6,
        35,
        44,
        17,
        30,
        20,
        28,
        37,
        18,
        1,
        34,
        49,
        34,
        34,
        0,
        37,
        44,
        24,
        49,
        14
      ],
      "weeklyBackordered": [
        28,
        26,
        18,
        44,
        28,
        46,
        21,
        49,
        0,
        18,
        20,
        31,
        19,
        0,
        6,
        30,
        40,
        49,
        0,
        17,
        33,
        40,
        27,
        45,
        1,
        16,
        4,
        16,
        44,
        38,
        0,
        39,
        48,
        17,
        4,
        37,
        31,
        13,
        0,
        9,
        8,
        48,
        0,
        49,
        28,
        36,
        49,
        2,
        41,
        49,
        36,
        1
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyAccepted": [
        5,
        48,
        0,
        5,
        31,
        0,
        17,
        27,
        22,
        44,
        45,
        0,
        21,
        47,
        35,
        17,
        49,
        18,
        0,
        19,
        37,
        30,
        31,
        48,
        5,
        18,
        45,
        38,
        20,
        16,
        16,
        36,
        49,
        26,
        17,
        22,
        47,
        48,
        33,
        17,
        0,
        34,
        6,
        29,
        49,
        40,
        49,
        15,
        49,
        18,
        11,
        27
      ],
      "weeklyRejected": [
        36,
        16,
        44,
        9,
        49,
        49,
        49,
        49,
        28,
        14,
        22,
        28,
        4,
        48,
        37,
        23,
        41,
        38,
        41,
        0,
        8,
        32,
        26,
        18,
        16,
        49,
        29,
        19,
        0,
        0,
        45,
        47,
        41,
        49,
        10,
        49,
        0,
        17,
        1,
        24,
        39,
        45,
        49,
        24,
        43,
        41,
        12,
        12,
        7,
        40,
        0,
        49
      ],
      "weeklyHold": [
        22,
        26,
        43,
        16,
        26,
        23,
        0,
        2,
        0,
        0,
        30,
        22,
        18,
        27,
        49,
        49,
        17,
        48,
        25,
        12,
        49,
        27,
        6,
        18,
        45,
        27,
        4,
        37,
        45,
        40,
        37,
        16,
        20,
        27,
        0,
        17,
        2,
        40,
        25,
        44,
        30,
        49,
        14,
        0,
        31,
        7,
        29,
        20,
        14,
        20,
        6,
        27
      ],
      "weeklyBackordered": [
        39,
        29,
        0,
        0,
        19,
        46,
        34,
        22,
        17,
        16,
        38,
        38,
        3,
        33,
        27,
        16,
        48,
        19,
        21,
        15,
        46,
        40,
        33,
        47,
        13,
        17,
        6,
        31,
        39,
        49,
        37,
        28,
        48,
        49,
        0,
        0,
        1,
        3,
        41,
        37,
        2,
        48,
        0,
        49,
        32,
        17,
        49,
        0,
        0,
        38,
        25,
        13
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        20,
        49,
        42,
        19,
        29,
        34,
        4,
        2,
        30,
        7,
        40,
        16,
        29,
        49,
        25,
        20,
        20,
        37,
        0,
        49,
        27,
        49,
        26,
        37,
        21,
        47,
        16,
        49,
        49,
        38,
        5,
        43,
        49,
        27,
        40,
        39,
        26,
        5,
        48,
        21,
        39,
        3,
        34,
        49,
        18,
        16,
        10,
        19,
        5,
        37,
        16,
        18
      ],
      "weeklyRejected": [
        26,
        19,
        22,
        33,
        47,
        26,
        9,
        24,
        40,
        32,
        0,
        25,
        21,
        32,
        27,
        28,
        0,
        39,
        37,
        39,
        0,
        15,
        0,
        5,
        49,
        49,
        39,
        30,
        23,
        24,
        1,
        28,
        48,
        48,
        22,
        49,
        33,
        23,
        12,
        49,
        38,
        36,
        49,
        17,
        7,
        41,
        0,
        27,
        16,
        48,
        3,
        49
      ],
      "weeklyHold": [
        41,
        12,
        49,
        1,
        37,
        48,
        46,
        16,
        29,
        25,
        19,
        16,
        11,
        21,
        34,
        35,
        8,
        22,
        0,
        9,
        26,
        49,
        30,
        45,
        32,
        1,
        38,
        39,
        49,
        22,
        48,
        3,
        0,
        26,
        4,
        49,
        24,
        26,
        49,
        42,
        15,
        35,
        43,
        49,
        21,
        20,
        43,
        12,
        10,
        36,
        22,
        11
      ],
      "weeklyBackordered": [
        21,
        21,
        0,
        11,
        48,
        46,
        14,
        49,
        36,
        0,
        49,
        37,
        3,
        49,
        49,
        27,
        19,
        25,
        19,
        0,
        34,
        21,
        24,
        47,
        19,
        31,
        49,
        36,
        19,
        36,
        22,
        0,
        44,
        49,
        4,
        0,
        49,
        21,
        30,
        5,
        8,
        26,
        0,
        49,
        26,
        22,
        49,
        11,
        38,
        25,
        20,
        31
      ]
    },
    {
      "id": "6E657E21",
      "weeklyAccepted": [
        20,
        1,
        30,
        36,
        49,
        0,
        16,
        40,
        33,
        38,
        2,
        0,
        15,
        31,
        7,
        0,
        49,
        26,
        29,
        25,
        18,
        23,
        35,
        21,
        19,
        26,
        0,
        10,
        7,
        17,
        21,
        49,
        12,
        13,
        42,
        49,
        17,
        27,
        0,
        46,
        26,
        31,
        37,
        34,
        4,
        30,
        0,
        49,
        14,
        49,
        13,
        0
      ],
      "weeklyRejected": [
        17,
        23,
        49,
        27,
        48,
        43,
        0,
        49,
        49,
        39,
        44,
        0,
        40,
        48,
        24,
        13,
        0,
        0,
        13,
        44,
        23,
        13,
        26,
        45,
        7,
        49,
        49,
        19,
        18,
        34,
        46,
        47,
        48,
        24,
        28,
        49,
        7,
        19,
        11,
        49,
        48,
        25,
        38,
        0,
        49,
        5,
        0,
        35,
        0,
        49,
        17,
        40
      ],
      "weeklyHold": [
        33,
        0,
        27,
        1,
        22,
        46,
        15,
        25,
        5,
        37,
        30,
        42,
        48,
        49,
        28,
        0,
        0,
        13,
        27,
        20,
        22,
        46,
        0,
        13,
        0,
        1,
        20,
        26,
        49,
        3,
        48,
        16,
        0,
        46,
        39,
        47,
        0,
        15,
        49,
        43,
        0,
        49,
        29,
        9,
        40,
        40,
        11,
        49,
        0,
        28,
        30,
        15
      ],
      "weeklyBackordered": [
        10,
        46,
        40,
        23,
        48,
        40,
        25,
        41,
        18,
        0,
        49,
        14,
        3,
        29,
        26,
        38,
        49,
        9,
        0,
        22,
        49,
        20,
        40,
        34,
        14,
        18,
        9,
        42,
        33,
        27,
        0,
        49,
        31,
        46,
        0,
        0,
        36,
        7,
        20,
        36,
        48,
        48,
        0,
        32,
        36,
        34,
        49,
        37,
        8,
        49,
        13,
        13
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        3,
        49,
        25,
        1,
        1,
        3,
        11,
        35,
        21,
        30,
        30,
        26,
        49,
        49,
        47,
        18,
        49,
        0,
        10,
        34,
        18,
        13,
        24,
        43,
        0,
        47,
        37,
        9,
        1,
        30,
        47,
        25,
        20,
        1,
        48,
        46,
        0,
        12,
        10,
        20,
        17,
        21,
        27,
        35,
        41,
        0,
        13,
        13,
        25,
        33,
        19,
        16
      ],
      "weeklyRejected": [
        49,
        28,
        11,
        30,
        49,
        0,
        49,
        49,
        49,
        17,
        24,
        46,
        3,
        37,
        48,
        0,
        31,
        49,
        7,
        18,
        6,
        46,
        14,
        49,
        27,
        49,
        44,
        11,
        43,
        19,
        49,
        47,
        25,
        23,
        23,
        49,
        49,
        0,
        7,
        18,
        46,
        0,
        0,
        15,
        47,
        20,
        4,
        4,
        0,
        49,
        11,
        48
      ],
      "weeklyHold": [
        49,
        26,
        42,
        1,
        35,
        33,
        27,
        10,
        35,
        26,
        42,
        2,
        11,
        24,
        42,
        0,
        21,
        42,
        19,
        6,
        5,
        6,
        34,
        9,
        31,
        24,
        3,
        18,
        49,
        0,
        21,
        8,
        11,
        0,
        0,
        35,
        25,
        0,
        34,
        49,
        0,
        49,
        34,
        0,
        14,
        45,
        9,
        13,
        2,
        10,
        44,
        45
      ],
      "weeklyBackordered": [
        0,
        0,
        13,
        39,
        45,
        46,
        0,
        14,
        12,
        36,
        49,
        47,
        41,
        32,
        18,
        12,
        3,
        27,
        0,
        20,
        49,
        29,
        24,
        19,
        28,
        46,
        13,
        23,
        18,
        49,
        19,
        49,
        47,
        20,
        29,
        0,
        49,
        6,
        30,
        0,
        27,
        48,
        2,
        49,
        14,
        29,
        49,
        19,
        0,
        18,
        49,
        6
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        38,
        1,
        49,
        36,
        36,
        44,
        49,
        19,
        37,
        0,
        49,
        15,
        48,
        48,
        15,
        13,
        38,
        23,
        49,
        29,
        10,
        49,
        41,
        44,
        40,
        33,
        1,
        16,
        29,
        49,
        29,
        20,
        42,
        45,
        48,
        11,
        28,
        48,
        6,
        20,
        9,
        34,
        38,
        23,
        27,
        49,
        14,
        16,
        0,
        48,
        48,
        15
      ],
      "weeklyRejected": [
        39,
        15,
        23,
        20,
        0,
        12,
        11,
        42,
        49,
        8,
        0,
        18,
        49,
        28,
        36,
        0,
        49,
        26,
        41,
        29,
        0,
        13,
        12,
        24,
        17,
        49,
        18,
        0,
        24,
        40,
        47,
        19,
        45,
        28,
        49,
        42,
        20,
        42,
        1,
        0,
        15,
        42,
        37,
        11,
        49,
        18,
        0,
        17,
        27,
        46,
        20,
        42
      ],
      "weeklyHold": [
        29,
        45,
        49,
        1,
        49,
        34,
        42,
        2,
        28,
        37,
        43,
        10,
        1,
        41,
        49,
        18,
        18,
        2,
        42,
        0,
        23,
        21,
        37,
        21,
        1,
        48,
        26,
        23,
        15,
        3,
        34,
        16,
        31,
        49,
        17,
        28,
        49,
        19,
        21,
        49,
        25,
        37,
        0,
        28,
        34,
        26,
        21,
        49,
        49,
        6,
        46,
        29
      ],
      "weeklyBackordered": [
        49,
        12,
        13,
        7,
        36,
        21,
        25,
        12,
        18,
        16,
        0,
        10,
        21,
        30,
        25,
        40,
        49,
        0,
        29,
        23,
        45,
        35,
        36,
        19,
        1,
        19,
        47,
        25,
        17,
        49,
        11,
        23,
        7,
        43,
        0,
        11,
        21,
        0,
        25,
        27,
        48,
        48,
        8,
        47,
        13,
        23,
        19,
        8,
        0,
        49,
        47,
        32
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyAccepted": [
        38,
        1,
        13,
        18,
        32,
        0,
        36,
        30,
        48,
        2,
        41,
        43,
        43,
        30,
        9,
        45,
        38,
        35,
        4,
        5,
        9,
        16,
        8,
        41,
        26,
        36,
        5,
        26,
        1,
        49,
        31,
        0,
        34,
        22,
        38,
        9,
        47,
        24,
        26,
        39,
        9,
        12,
        39,
        29,
        35,
        0,
        0,
        30,
        44,
        16,
        0,
        23
      ],
      "weeklyRejected": [
        40,
        27,
        13,
        7,
        49,
        49,
        24,
        44,
        49,
        25,
        49,
        15,
        49,
        40,
        48,
        23,
        3,
        49,
        20,
        0,
        46,
        49,
        49,
        25,
        32,
        34,
        49,
        4,
        34,
        2,
        37,
        13,
        38,
        11,
        33,
        39,
        5,
        0,
        31,
        15,
        27,
        23,
        0,
        2,
        33,
        25,
        24,
        33,
        27,
        49,
        4,
        49
      ],
      "weeklyHold": [
        22,
        33,
        49,
        16,
        36,
        21,
        23,
        2,
        15,
        0,
        2,
        1,
        34,
        37,
        20,
        16,
        0,
        1,
        42,
        35,
        9,
        25,
        33,
        5,
        32,
        1,
        27,
        33,
        32,
        26,
        39,
        25,
        2,
        48,
        28,
        22,
        36,
        0,
        6,
        29,
        21,
        49,
        21,
        28,
        26,
        32,
        11,
        23,
        0,
        30,
        10,
        49
      ],
      "weeklyBackordered": [
        0,
        43,
        0,
        42,
        46,
        35,
        30,
        32,
        21,
        5,
        49,
        16,
        33,
        4,
        35,
        28,
        49,
        49,
        0,
        0,
        12,
        43,
        49,
        7,
        11,
        1,
        16,
        0,
        1,
        43,
        17,
        49,
        36,
        0,
        6,
        17,
        26,
        3,
        1,
        24,
        18,
        48,
        5,
        47,
        0,
        44,
        38,
        25,
        49,
        40,
        27,
        49
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        0,
        12,
        49,
        34,
        15,
        0,
        37,
        49,
        37,
        49,
        32,
        0,
        47,
        31,
        40,
        0,
        0,
        11,
        0,
        23,
        0,
        49,
        3,
        26,
        33,
        47,
        45,
        43,
        8,
        49,
        29,
        8,
        43,
        15,
        48,
        13,
        0,
        48,
        24,
        49,
        13,
        45,
        49,
        40,
        9,
        30,
        37,
        6,
        7,
        36,
        25,
        26
      ],
      "weeklyRejected": [
        44,
        5,
        49,
        41,
        44,
        16,
        34,
        49,
        31,
        1,
        15,
        20,
        37,
        11,
        48,
        24,
        26,
        32,
        42,
        15,
        49,
        22,
        10,
        45,
        30,
        42,
        17,
        10,
        46,
        26,
        44,
        47,
        37,
        22,
        37,
        49,
        2,
        16,
        1,
        31,
        18,
        24,
        37,
        14,
        44,
        0,
        0,
        24,
        3,
        27,
        7,
        35
      ],
      "weeklyHold": [
        12,
        39,
        6,
        1,
        45,
        11,
        49,
        38,
        17,
        49,
        22,
        49,
        33,
        20,
        11,
        36,
        22,
        47,
        0,
        0,
        35,
        17,
        20,
        47,
        30,
        42,
        40,
        21,
        49,
        1,
        47,
        0,
        0,
        43,
        41,
        6,
        31,
        0,
        17,
        49,
        34,
        49,
        0,
        36,
        34,
        49,
        46,
        32,
        16,
        21,
        0,
        13
      ],
      "weeklyBackordered": [
        43,
        8,
        0,
        35,
        18,
        33,
        22,
        47,
        15,
        15,
        0,
        49,
        44,
        33,
        49,
        1,
        1,
        31,
        0,
        19,
        29,
        1,
        49,
        0,
        1,
        7,
        24,
        19,
        45,
        30,
        19,
        45,
        0,
        46,
        0,
        11,
        16,
        0,
        33,
        49,
        33,
        28,
        6,
        49,
        0,
        9,
        49,
        30,
        10,
        44,
        11,
        1
      ]
    },
    {
      "id": "14968630",
      "weeklyAccepted": [
        4,
        49,
        20,
        0,
        14,
        31,
        2,
        22,
        42,
        14,
        46,
        0,
        3,
        43,
        30,
        4,
        49,
        16,
        18,
        23,
        0,
        45,
        8,
        29,
        15,
        45,
        6,
        6,
        13,
        45,
        0,
        0,
        49,
        35,
        48,
        2,
        47,
        48,
        49,
        29,
        14,
        37,
        31,
        41,
        49,
        40,
        0,
        49,
        1,
        42,
        4,
        35
      ],
      "weeklyRejected": [
        49,
        6,
        3,
        1,
        30,
        0,
        49,
        49,
        37,
        29,
        26,
        0,
        12,
        37,
        47,
        0,
        3,
        49,
        49,
        7,
        0,
        45,
        13,
        18,
        32,
        0,
        37,
        27,
        1,
        0,
        45,
        37,
        35,
        42,
        32,
        45,
        29,
        16,
        3,
        14,
        45,
        38,
        24,
        31,
        49,
        41,
        0,
        31,
        11,
        30,
        0,
        7
      ],
      "weeklyHold": [
        37,
        33,
        49,
        17,
        35,
        28,
        45,
        28,
        49,
        34,
        43,
        1,
        35,
        33,
        32,
        43,
        0,
        42,
        9,
        12,
        9,
        49,
        7,
        15,
        39,
        37,
        0,
        10,
        0,
        49,
        48,
        46,
        35,
        10,
        39,
        40,
        0,
        48,
        49,
        49,
        41,
        10,
        18,
        27,
        15,
        2,
        13,
        49,
        15,
        49,
        29,
        22
      ],
      "weeklyBackordered": [
        39,
        0,
        0,
        23,
        49,
        46,
        43,
        1,
        11,
        0,
        26,
        8,
        19,
        49,
        23,
        41,
        36,
        49,
        0,
        11,
        49,
        44,
        19,
        7,
        1,
        7,
        7,
        5,
        27,
        45,
        45,
        49,
        6,
        28,
        40,
        17,
        20,
        23,
        11,
        20,
        13,
        48,
        0,
        49,
        40,
        44,
        1,
        4,
        0,
        32,
        7,
        2
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyAccepted": [
        38,
        30,
        22,
        0,
        49,
        49,
        22,
        41,
        48,
        41,
        49,
        0,
        49,
        40,
        9,
        49,
        43,
        0,
        11,
        35,
        2,
        26,
        31,
        32,
        48,
        47,
        9,
        29,
        1,
        49,
        36,
        0,
        28,
        1,
        28,
        49,
        26,
        30,
        37,
        49,
        40,
        49,
        49,
        39,
        43,
        35,
        16,
        49,
        10,
        28,
        42,
        21
      ],
      "weeklyRejected": [
        49,
        21,
        25,
        21,
        49,
        0,
        16,
        0,
        48,
        41,
        49,
        16,
        12,
        36,
        27,
        30,
        6,
        49,
        22,
        21,
        0,
        49,
        31,
        9,
        23,
        49,
        38,
        18,
        22,
        20,
        21,
        36,
        24,
        18,
        0,
        49,
        25,
        24,
        46,
        31,
        45,
        10,
        18,
        13,
        14,
        1,
        0,
        0,
        45,
        43,
        0,
        38
      ],
      "weeklyHold": [
        1,
        7,
        32,
        1,
        5,
        32,
        43,
        49,
        39,
        9,
        34,
        23,
        1,
        16,
        31,
        49,
        24,
        0,
        12,
        0,
        23,
        39,
        43,
        20,
        39,
        1,
        49,
        49,
        38,
        14,
        48,
        9,
        28,
        16,
        2,
        1,
        14,
        7,
        49,
        8,
        25,
        14,
        33,
        34,
        49,
        15,
        42,
        38,
        0,
        49,
        42,
        27
      ],
      "weeklyBackordered": [
        17,
        8,
        47,
        11,
        48,
        28,
        37,
        49,
        20,
        20,
        13,
        16,
        3,
        23,
        4,
        38,
        49,
        29,
        18,
        34,
        49,
        19,
        24,
        16,
        11,
        2,
        23,
        49,
        5,
        41,
        31,
        21,
        19,
        43,
        8,
        32,
        40,
        0,
        23,
        0,
        48,
        42,
        11,
        33,
        18,
        23,
        49,
        38,
        23,
        49,
        49,
        33
      ]
    },
    {
      "id": "067037B3",
      "weeklyAccepted": [
        11,
        33,
        49,
        15,
        21,
        0,
        18,
        49,
        13,
        49,
        49,
        0,
        49,
        49,
        9,
        37,
        43,
        26,
        13,
        5,
        1,
        41,
        34,
        32,
        26,
        34,
        0,
        27,
        1,
        13,
        0,
        15,
        49,
        0,
        48,
        32,
        15,
        28,
        26,
        42,
        14,
        49,
        46,
        25,
        49,
        2,
        11,
        33,
        47,
        18,
        13,
        16
      ],
      "weeklyRejected": [
        39,
        2,
        30,
        8,
        22,
        4,
        49,
        42,
        49,
        1,
        1,
        35,
        27,
        46,
        48,
        16,
        40,
        45,
        21,
        33,
        24,
        46,
        7,
        16,
        5,
        49,
        45,
        0,
        48,
        34,
        33,
        47,
        0,
        0,
        6,
        26,
        23,
        22,
        21,
        29,
        42,
        26,
        49,
        24,
        49,
        49,
        3,
        17,
        13,
        49,
        49,
        33
      ],
      "weeklyHold": [
        0,
        49,
        14,
        21,
        49,
        48,
        25,
        14,
        24,
        32,
        40,
        49,
        19,
        31,
        13,
        0,
        49,
        5,
        2,
        31,
        23,
        18,
        7,
        5,
        47,
        48,
        33,
        21,
        18,
        0,
        33,
        0,
        0,
        37,
        28,
        28,
        42,
        27,
        22,
        49,
        11,
        37,
        17,
        37,
        23,
        0,
        9,
        21,
        0,
        18,
        47,
        47
      ],
      "weeklyBackordered": [
        38,
        49,
        0,
        22,
        28,
        46,
        47,
        48,
        28,
        49,
        48,
        11,
        13,
        48,
        29,
        23,
        49,
        49,
        16,
        45,
        38,
        36,
        43,
        14,
        40,
        15,
        23,
        15,
        1,
        49,
        8,
        44,
        11,
        25,
        20,
        7,
        4,
        34,
        46,
        49,
        11,
        47,
        29,
        49,
        44,
        49,
        28,
        22,
        0,
        49,
        49,
        34
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyAccepted": [
        0,
        19,
        32,
        16,
        49,
        13,
        24,
        30,
        16,
        7,
        49,
        35,
        12,
        0,
        28,
        49,
        0,
        0,
        19,
        28,
        24,
        18,
        20,
        39,
        27,
        47,
        0,
        11,
        20,
        39,
        16,
        19,
        45,
        29,
        48,
        39,
        35,
        0,
        25,
        49,
        0,
        49,
        49,
        44,
        14,
        22,
        19,
        27,
        5,
        49,
        18,
        44
      ],
      "weeklyRejected": [
        28,
        13,
        49,
        47,
        40,
        49,
        41,
        29,
        35,
        37,
        23,
        30,
        49,
        48,
        32,
        23,
        8,
        12,
        17,
        0,
        43,
        27,
        38,
        49,
        28,
        36,
        7,
        0,
        36,
        25,
        0,
        22,
        48,
        12,
        44,
        25,
        40,
        39,
        22,
        29,
        19,
        38,
        26,
        0,
        27,
        27,
        47,
        37,
        20,
        49,
        24,
        41
      ],
      "weeklyHold": [
        0,
        38,
        33,
        1,
        49,
        25,
        0,
        46,
        15,
        22,
        19,
        49,
        31,
        16,
        8,
        37,
        16,
        31,
        47,
        17,
        37,
        0,
        11,
        17,
        49,
        32,
        2,
        16,
        0,
        25,
        3,
        25,
        21,
        0,
        48,
        34,
        45,
        0,
        1,
        33,
        1,
        8,
        32,
        44,
        29,
        13,
        0,
        42,
        0,
        32,
        0,
        15
      ],
      "weeklyBackordered": [
        38,
        36,
        13,
        38,
        36,
        24,
        16,
        41,
        17,
        23,
        0,
        33,
        8,
        36,
        4,
        9,
        49,
        29,
        13,
        34,
        48,
        44,
        46,
        47,
        22,
        17,
        18,
        36,
        1,
        49,
        0,
        41,
        19,
        27,
        0,
        7,
        42,
        10,
        18,
        24,
        34,
        20,
        14,
        49,
        23,
        49,
        48,
        8,
        42,
        19,
        22,
        17
      ]
    },
    {
      "id": "330FF32E",
      "weeklyAccepted": [
        24,
        38,
        49,
        10,
        17,
        40,
        19,
        38,
        45,
        40,
        23,
        0,
        25,
        22,
        1,
        19,
        49,
        23,
        0,
        20,
        11,
        42,
        43,
        45,
        21,
        30,
        42,
        49,
        34,
        37,
        18,
        21,
        2,
        44,
        0,
        38,
        47,
        19,
        11,
        30,
        49,
        0,
        19,
        24,
        49,
        0,
        7,
        9,
        12,
        47,
        8,
        6
      ],
      "weeklyRejected": [
        37,
        49,
        49,
        8,
        8,
        17,
        46,
        25,
        36,
        26,
        33,
        3,
        8,
        35,
        34,
        23,
        7,
        41,
        49,
        0,
        22,
        6,
        49,
        16,
        7,
        42,
        40,
        23,
        0,
        31,
        49,
        47,
        15,
        9,
        21,
        49,
        49,
        15,
        1,
        17,
        33,
        39,
        0,
        18,
        12,
        0,
        0,
        0,
        24,
        49,
        9,
        37
      ],
      "weeklyHold": [
        46,
        33,
        49,
        28,
        16,
        20,
        44,
        40,
        32,
        45,
        9,
        35,
        48,
        48,
        38,
        13,
        49,
        0,
        21,
        26,
        32,
        36,
        36,
        49,
        30,
        10,
        41,
        26,
        40,
        49,
        30,
        48,
        18,
        49,
        9,
        8,
        20,
        40,
        44,
        49,
        43,
        27,
        0,
        30,
        33,
        47,
        0,
        35,
        16,
        25,
        30,
        6
      ],
      "weeklyBackordered": [
        20,
        10,
        34,
        18,
        45,
        33,
        0,
        31,
        0,
        11,
        2,
        49,
        10,
        0,
        35,
        12,
        26,
        31,
        25,
        36,
        28,
        29,
        6,
        33,
        16,
        33,
        1,
        44,
        13,
        37,
        43,
        10,
        0,
        41,
        8,
        24,
        28,
        8,
        31,
        7,
        13,
        48,
        18,
        24,
        19,
        43,
        10,
        3,
        49,
        36,
        32,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyAccepted": [
        20,
        29,
        49,
        6,
        13,
        24,
        1,
        41,
        48,
        40,
        45,
        19,
        25,
        17,
        10,
        34,
        3,
        0,
        42,
        0,
        35,
        45,
        46,
        46,
        0,
        30,
        5,
        26,
        12,
        28,
        42,
        24,
        21,
        16,
        44,
        33,
        15,
        46,
        34,
        24,
        0,
        37,
        49,
        45,
        39,
        25,
        24,
        16,
        4,
        33,
        49,
        16
      ],
      "weeklyRejected": [
        36,
        27,
        25,
        25,
        9,
        35,
        27,
        9,
        40,
        23,
        17,
        30,
        14,
        48,
        15,
        16,
        49,
        38,
        6,
        0,
        13,
        41,
        2,
        13,
        49,
        49,
        15,
        3,
        35,
        40,
        33,
        30,
        41,
        49,
        10,
        46,
        33,
        15,
        1,
        16,
        24,
        37,
        31,
        1,
        45,
        25,
        4,
        26,
        28,
        49,
        0,
        44
      ],
      "weeklyHold": [
        0,
        49,
        31,
        15,
        0,
        28,
        4,
        35,
        12,
        26,
        27,
        49,
        11,
        25,
        49,
        13,
        16,
        43,
        34,
        6,
        30,
        45,
        15,
        49,
        49,
        42,
        27,
        39,
        28,
        46,
        48,
        9,
        0,
        26,
        29,
        9,
        19,
        7,
        35,
        41,
        0,
        31,
        39,
        29,
        49,
        0,
        6,
        46,
        3,
        13,
        19,
        31
      ],
      "weeklyBackordered": [
        0,
        36,
        18,
        21,
        24,
        24,
        34,
        43,
        33,
        18,
        49,
        0,
        3,
        49,
        0,
        48,
        49,
        40,
        12,
        1,
        20,
        46,
        13,
        47,
        3,
        1,
        28,
        7,
        13,
        31,
        6,
        48,
        34,
        49,
        14,
        35,
        30,
        8,
        0,
        13,
        34,
        14,
        24,
        49,
        9,
        3,
        37,
        19,
        0,
        30,
        23,
        40
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        1,
        16,
        49,
        13,
        49,
        24,
        33,
        38,
        37,
        35,
        44,
        28,
        44,
        30,
        29,
        14,
        35,
        26,
        0,
        7,
        3,
        17,
        0,
        49,
        27,
        3,
        29,
        32,
        1,
        49,
        0,
        3,
        17,
        0,
        32,
        27,
        47,
        35,
        49,
        15,
        31,
        45,
        36,
        35,
        13,
        34,
        13,
        38,
        16,
        33,
        29,
        48
      ],
      "weeklyRejected": [
        49,
        4,
        34,
        4,
        47,
        18,
        49,
        36,
        40,
        1,
        22,
        42,
        25,
        17,
        26,
        10,
        0,
        33,
        29,
        12,
        11,
        49,
        4,
        26,
        13,
        8,
        49,
        0,
        16,
        18,
        49,
        35,
        34,
        10,
        24,
        49,
        49,
        22,
        16,
        32,
        37,
        0,
        49,
        21,
        49,
        17,
        41,
        4,
        9,
        49,
        7,
        30
      ],
      "weeklyHold": [
        0,
        27,
        25,
        26,
        39,
        29,
        49,
        21,
        40,
        21,
        46,
        12,
        18,
        11,
        26,
        8,
        10,
        40,
        28,
        9,
        0,
        39,
        49,
        6,
        0,
        46,
        36,
        28,
        42,
        24,
        48,
        48,
        28,
        20,
        28,
        24,
        3,
        16,
        49,
        26,
        0,
        17,
        12,
        0,
        23,
        49,
        20,
        9,
        14,
        26,
        38,
        49
      ],
      "weeklyBackordered": [
        49,
        13,
        1,
        27,
        46,
        46,
        49,
        49,
        20,
        25,
        40,
        23,
        3,
        26,
        0,
        1,
        49,
        37,
        32,
        45,
        49,
        13,
        37,
        20,
        1,
        14,
        48,
        34,
        26,
        49,
        37,
        21,
        26,
        39,
        0,
        13,
        32,
        12,
        3,
        22,
        2,
        48,
        49,
        49,
        14,
        18,
        49,
        21,
        0,
        38,
        34,
        39
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyAccepted": [
        7,
        35,
        49,
        48,
        34,
        32,
        2,
        30,
        25,
        49,
        38,
        0,
        32,
        42,
        1,
        10,
        18,
        5,
        22,
        14,
        0,
        0,
        0,
        9,
        44,
        4,
        28,
        16,
        6,
        49,
        49,
        0,
        33,
        12,
        47,
        35,
        19,
        0,
        49,
        40,
        39,
        24,
        20,
        49,
        48,
        38,
        9,
        46,
        36,
        49,
        8,
        15
      ],
      "weeklyRejected": [
        49,
        27,
        24,
        3,
        49,
        0,
        24,
        26,
        49,
        1,
        0,
        25,
        49,
        48,
        30,
        25,
        49,
        39,
        5,
        31,
        20,
        38,
        1,
        16,
        21,
        49,
        45,
        23,
        28,
        13,
        28,
        23,
        48,
        41,
        30,
        49,
        12,
        27,
        16,
        28,
        48,
        40,
        29,
        18,
        28,
        37,
        0,
        38,
        0,
        49,
        10,
        16
      ],
      "weeklyHold": [
        31,
        49,
        49,
        12,
        49,
        42,
        29,
        15,
        19,
        12,
        0,
        21,
        25,
        42,
        49,
        14,
        5,
        35,
        17,
        0,
        23,
        49,
        12,
        10,
        25,
        26,
        49,
        49,
        30,
        21,
        45,
        32,
        8,
        29,
        40,
        44,
        22,
        22,
        48,
        49,
        14,
        8,
        10,
        21,
        0,
        49,
        49,
        34,
        7,
        35,
        49,
        12
      ],
      "weeklyBackordered": [
        38,
        0,
        33,
        27,
        22,
        46,
        49,
        34,
        1,
        0,
        49,
        24,
        3,
        46,
        21,
        49,
        26,
        22,
        24,
        0,
        47,
        16,
        0,
        16,
        18,
        1,
        33,
        49,
        27,
        40,
        26,
        20,
        0,
        42,
        1,
        0,
        9,
        0,
        34,
        26,
        48,
        48,
        16,
        49,
        31,
        15,
        45,
        26,
        0,
        25,
        49,
        12
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyAccepted": [
        0,
        1,
        28,
        10,
        27,
        0,
        26,
        49,
        45,
        0,
        11,
        9,
        36,
        0,
        49,
        34,
        39,
        7,
        0,
        37,
        17,
        15,
        0,
        15,
        31,
        41,
        13,
        49,
        1,
        42,
        11,
        49,
        49,
        12,
        48,
        25,
        17,
        26,
        47,
        49,
        4,
        20,
        49,
        28,
        8,
        37,
        1,
        0,
        10,
        49,
        30,
        48
      ],
      "weeklyRejected": [
        21,
        18,
        49,
        42,
        25,
        39,
        0,
        0,
        49,
        42,
        46,
        44,
        4,
        38,
        27,
        4,
        0,
        49,
        16,
        32,
        15,
        16,
        13,
        38,
        44,
        49,
        22,
        43,
        47,
        45,
        49,
        37,
        39,
        35,
        31,
        49,
        49,
        21,
        1,
        20,
        35,
        4,
        48,
        29,
        47,
        24,
        49,
        49,
        0,
        49,
        0,
        48
      ],
      "weeklyHold": [
        0,
        38,
        0,
        31,
        49,
        38,
        49,
        17,
        6,
        49,
        20,
        16,
        36,
        38,
        11,
        40,
        13,
        3,
        19,
        49,
        3,
        41,
        7,
        21,
        0,
        33,
        49,
        37,
        34,
        8,
        48,
        0,
        2,
        46,
        48,
        49,
        29,
        17,
        19,
        0,
        12,
        37,
        10,
        49,
        38,
        44,
        16,
        10,
        9,
        17,
        39,
        49
      ],
      "weeklyBackordered": [
        41,
        26,
        0,
        47,
        48,
        23,
        47,
        23,
        34,
        49,
        49,
        48,
        5,
        12,
        0,
        1,
        49,
        49,
        0,
        49,
        48,
        23,
        31,
        22,
        1,
        15,
        14,
        18,
        1,
        49,
        38,
        49,
        18,
        49,
        0,
        0,
        47,
        43,
        49,
        12,
        0,
        48,
        0,
        49,
        28,
        10,
        41,
        27,
        33,
        21,
        10,
        18
      ]
    },
    {
      "id": "EB58F890",
      "weeklyAccepted": [
        44,
        3,
        16,
        32,
        10,
        4,
        1,
        43,
        16,
        49,
        38,
        13,
        47,
        14,
        12,
        0,
        23,
        22,
        0,
        28,
        16,
        44,
        0,
        49,
        48,
        15,
        30,
        46,
        31,
        43,
        49,
        10,
        24,
        17,
        48,
        5,
        9,
        23,
        22,
        42,
        0,
        46,
        17,
        0,
        49,
        0,
        5,
        49,
        16,
        49,
        12,
        23
      ],
      "weeklyRejected": [
        39,
        0,
        49,
        11,
        31,
        22,
        0,
        49,
        49,
        1,
        6,
        0,
        15,
        39,
        6,
        4,
        49,
        16,
        49,
        49,
        0,
        3,
        24,
        40,
        28,
        49,
        0,
        49,
        31,
        33,
        15,
        46,
        41,
        14,
        29,
        49,
        49,
        44,
        1,
        49,
        48,
        41,
        48,
        5,
        49,
        26,
        24,
        23,
        0,
        25,
        0,
        42
      ],
      "weeklyHold": [
        0,
        49,
        43,
        1,
        49,
        46,
        0,
        12,
        16,
        27,
        25,
        31,
        33,
        49,
        21,
        37,
        22,
        14,
        34,
        0,
        0,
        38,
        39,
        10,
        0,
        48,
        24,
        2,
        36,
        33,
        18,
        0,
        0,
        43,
        22,
        24,
        17,
        9,
        44,
        27,
        28,
        17,
        18,
        0,
        37,
        10,
        0,
        31,
        0,
        39,
        49,
        0
      ],
      "weeklyBackordered": [
        13,
        49,
        41,
        22,
        0,
        30,
        38,
        1,
        35,
        7,
        42,
        31,
        16,
        45,
        0,
        33,
        8,
        44,
        0,
        31,
        9,
        0,
        14,
        49,
        48,
        1,
        1,
        34,
        8,
        48,
        46,
        40,
        29,
        29,
        5,
        0,
        48,
        36,
        10,
        20,
        37,
        38,
        0,
        41,
        21,
        42,
        39,
        49,
        0,
        49,
        49,
        4
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyAccepted": [
        0,
        25,
        34,
        42,
        34,
        4,
        9,
        30,
        37,
        5,
        34,
        0,
        0,
        24,
        35,
        20,
        49,
        48,
        47,
        38,
        49,
        15,
        49,
        0,
        26,
        47,
        0,
        49,
        36,
        30,
        23,
        49,
        49,
        30,
        10,
        49,
        37,
        43,
        42,
        49,
        36,
        11,
        39,
        33,
        49,
        0,
        0,
        31,
        15,
        41,
        0,
        21
      ],
      "weeklyRejected": [
        29,
        14,
        49,
        32,
        17,
        19,
        44,
        49,
        49,
        10,
        0,
        40,
        26,
        31,
        37,
        15,
        42,
        19,
        49,
        49,
        43,
        2,
        40,
        23,
        28,
        49,
        45,
        0,
        48,
        17,
        24,
        36,
        19,
        29,
        24,
        47,
        26,
        40,
        36,
        46,
        43,
        49,
        49,
        13,
        41,
        13,
        22,
        17,
        3,
        49,
        14,
        34
      ],
      "weeklyHold": [
        49,
        0,
        39,
        14,
        41,
        20,
        25,
        2,
        37,
        35,
        46,
        49,
        22,
        3,
        30,
        8,
        16,
        32,
        25,
        27,
        21,
        23,
        0,
        27,
        0,
        48,
        0,
        5,
        39,
        24,
        44,
        5,
        0,
        49,
        48,
        16,
        3,
        29,
        49,
        22,
        17,
        39,
        24,
        46,
        37,
        45,
        25,
        18,
        7,
        10,
        40,
        32
      ],
      "weeklyBackordered": [
        0,
        34,
        24,
        20,
        20,
        31,
        19,
        23,
        11,
        46,
        20,
        41,
        49,
        16,
        22,
        48,
        47,
        27,
        42,
        25,
        49,
        31,
        5,
        11,
        1,
        25,
        27,
        36,
        11,
        48,
        39,
        4,
        26,
        30,
        0,
        0,
        20,
        0,
        24,
        26,
        19,
        48,
        28,
        49,
        16,
        48,
        49,
        26,
        0,
        49,
        49,
        7
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyAccepted": [
        1,
        1,
        21,
        45,
        27,
        35,
        30,
        42,
        48,
        32,
        49,
        34,
        34,
        11,
        46,
        24,
        41,
        8,
        17,
        5,
        15,
        40,
        10,
        46,
        23,
        24,
        0,
        20,
        4,
        13,
        28,
        24,
        3,
        49,
        48,
        11,
        16,
        19,
        17,
        49,
        40,
        34,
        23,
        46,
        25,
        0,
        0,
        14,
        49,
        29,
        22,
        48
      ],
      "weeklyRejected": [
        39,
        36,
        49,
        47,
        38,
        22,
        24,
        0,
        21,
        5,
        26,
        17,
        49,
        48,
        25,
        6,
        49,
        31,
        26,
        12,
        41,
        21,
        24,
        49,
        4,
        24,
        49,
        38,
        13,
        3,
        34,
        47,
        48,
        6,
        44,
        25,
        39,
        18,
        8,
        3,
        20,
        24,
        23,
        22,
        47,
        4,
        34,
        18,
        2,
        49,
        0,
        39
      ],
      "weeklyHold": [
        31,
        45,
        39,
        26,
        0,
        45,
        23,
        47,
        31,
        17,
        47,
        2,
        13,
        17,
        7,
        47,
        43,
        14,
        9,
        7,
        10,
        49,
        48,
        0,
        49,
        47,
        29,
        43,
        49,
        43,
        21,
        27,
        12,
        18,
        48,
        9,
        31,
        0,
        26,
        12,
        20,
        20,
        1,
        47,
        34,
        35,
        26,
        31,
        27,
        41,
        16,
        18
      ],
      "weeklyBackordered": [
        27,
        34,
        41,
        19,
        49,
        27,
        5,
        1,
        33,
        34,
        7,
        48,
        38,
        23,
        49,
        2,
        49,
        49,
        35,
        23,
        0,
        49,
        36,
        18,
        1,
        25,
        29,
        49,
        14,
        31,
        19,
        41,
        48,
        49,
        23,
        7,
        49,
        11,
        16,
        25,
        43,
        32,
        9,
        24,
        0,
        37,
        15,
        8,
        31,
        46,
        31,
        7
      ]
    },
    {
      "id": "864437DD",
      "weeklyAccepted": [
        1,
        15,
        1,
        48,
        13,
        0,
        17,
        2,
        44,
        35,
        40,
        0,
        0,
        44,
        9,
        21,
        22,
        18,
        0,
        45,
        28,
        27,
        49,
        27,
        18,
        47,
        43,
        34,
        49,
        49,
        28,
        0,
        34,
        0,
        28,
        9,
        40,
        9,
        34,
        49,
        7,
        35,
        49,
        27,
        37,
        49,
        23,
        28,
        22,
        49,
        0,
        48
      ],
      "weeklyRejected": [
        42,
        46,
        49,
        14,
        37,
        18,
        49,
        22,
        0,
        48,
        36,
        0,
        13,
        36,
        38,
        1,
        0,
        39,
        49,
        24,
        49,
        0,
        0,
        10,
        1,
        49,
        49,
        49,
        45,
        10,
        30,
        0,
        46,
        33,
        0,
        49,
        44,
        0,
        1,
        26,
        39,
        49,
        49,
        21,
        47,
        16,
        27,
        13,
        9,
        17,
        2,
        45
      ],
      "weeklyHold": [
        36,
        4,
        17,
        30,
        26,
        45,
        33,
        11,
        0,
        10,
        18,
        48,
        48,
        24,
        10,
        39,
        33,
        48,
        0,
        0,
        36,
        28,
        0,
        37,
        49,
        33,
        24,
        1,
        49,
        49,
        48,
        10,
        0,
        46,
        13,
        22,
        38,
        22,
        38,
        49,
        15,
        49,
        12,
        24,
        33,
        49,
        49,
        38,
        0,
        46,
        38,
        45
      ],
      "weeklyBackordered": [
        0,
        19,
        18,
        12,
        49,
        46,
        49,
        49,
        25,
        0,
        25,
        49,
        9,
        49,
        49,
        27,
        40,
        49,
        0,
        9,
        39,
        49,
        0,
        49,
        4,
        20,
        1,
        0,
        30,
        35,
        36,
        0,
        0,
        49,
        0,
        0,
        17,
        27,
        26,
        40,
        0,
        48,
        0,
        49,
        0,
        20,
        35,
        34,
        34,
        49,
        20,
        49
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        26,
        35,
        18,
        23,
        47,
        0,
        4,
        20,
        29,
        4,
        45,
        12,
        24,
        46,
        32,
        29,
        35,
        0,
        34,
        28,
        11,
        49,
        14,
        49,
        20,
        47,
        30,
        12,
        1,
        39,
        11,
        23,
        40,
        45,
        48,
        21,
        40,
        18,
        44,
        49,
        0,
        33,
        4,
        20,
        49,
        0,
        0,
        49,
        11,
        36,
        0,
        4
      ],
      "weeklyRejected": [
        39,
        49,
        29,
        47,
        12,
        36,
        43,
        39,
        49,
        13,
        41,
        30,
        0,
        48,
        33,
        24,
        49,
        29,
        5,
        5,
        5,
        37,
        23,
        49,
        21,
        35,
        45,
        43,
        21,
        31,
        43,
        47,
        26,
        30,
        13,
        49,
        0,
        4,
        8,
        31,
        44,
        46,
        49,
        0,
        49,
        29,
        9,
        18,
        6,
        49,
        20,
        19
      ],
      "weeklyHold": [
        6,
        30,
        35,
        14,
        26,
        19,
        4,
        36,
        15,
        5,
        47,
        3,
        48,
        20,
        49,
        31,
        0,
        10,
        0,
        16,
        0,
        30,
        33,
        0,
        38,
        1,
        0,
        33,
        49,
        32,
        48,
        19,
        19,
        35,
        25,
        0,
        23,
        8,
        10,
        39,
        49,
        33,
        0,
        19,
        16,
        16,
        18,
        49,
        5,
        17,
        48,
        4
      ],
      "weeklyBackordered": [
        12,
        20,
        1,
        18,
        49,
        46,
        29,
        49,
        5,
        11,
        0,
        39,
        41,
        44,
        49,
        1,
        30,
        49,
        0,
        13,
        19,
        49,
        17,
        26,
        32,
        49,
        1,
        49,
        6,
        49,
        33,
        30,
        48,
        45,
        35,
        3,
        30,
        27,
        0,
        16,
        25,
        48,
        21,
        49,
        21,
        43,
        43,
        49,
        38,
        43,
        29,
        14
      ]
    },
    {
      "id": "41097027",
      "weeklyAccepted": [
        30,
        1,
        41,
        37,
        37,
        12,
        45,
        22,
        20,
        48,
        38,
        15,
        49,
        48,
        16,
        49,
        34,
        20,
        8,
        9,
        2,
        19,
        21,
        41,
        5,
        21,
        12,
        40,
        26,
        7,
        40,
        22,
        32,
        32,
        1,
        36,
        24,
        41,
        26,
        49,
        0,
        22,
        42,
        16,
        34,
        7,
        0,
        15,
        40,
        11,
        2,
        11
      ],
      "weeklyRejected": [
        41,
        28,
        47,
        12,
        34,
        26,
        19,
        41,
        49,
        1,
        48,
        0,
        11,
        48,
        30,
        8,
        49,
        32,
        41,
        49,
        31,
        38,
        0,
        10,
        19,
        49,
        42,
        46,
        41,
        40,
        23,
        0,
        48,
        49,
        2,
        49,
        17,
        30,
        4,
        25,
        10,
        34,
        42,
        0,
        49,
        22,
        0,
        0,
        12,
        23,
        9,
        49
      ],
      "weeklyHold": [
        6,
        47,
        29,
        13,
        7,
        0,
        0,
        2,
        29,
        29,
        38,
        49,
        48,
        42,
        18,
        19,
        16,
        22,
        0,
        31,
        5,
        28,
        15,
        6,
        31,
        30,
        20,
        16,
        34,
        0,
        35,
        0,
        0,
        42,
        26,
        0,
        35,
        7,
        47,
        44,
        16,
        21,
        27,
        21,
        0,
        49,
        0,
        17,
        0,
        49,
        1,
        12
      ],
      "weeklyBackordered": [
        11,
        38,
        0,
        3,
        49,
        30,
        1,
        49,
        36,
        0,
        26,
        30,
        27,
        0,
        49,
        39,
        13,
        26,
        0,
        42,
        30,
        33,
        47,
        31,
        29,
        11,
        6,
        43,
        1,
        49,
        0,
        0,
        8,
        37,
        0,
        0,
        22,
        0,
        18,
        0,
        21,
        31,
        0,
        49,
        34,
        31,
        26,
        6,
        42,
        27,
        40,
        20
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyAccepted": [
        0,
        41,
        41,
        18,
        36,
        0,
        21,
        23,
        48,
        23,
        49,
        3,
        45,
        27,
        26,
        6,
        45,
        0,
        32,
        16,
        49,
        28,
        46,
        31,
        25,
        38,
        28,
        24,
        36,
        49,
        17,
        12,
        49,
        31,
        34,
        36,
        26,
        28,
        34,
        49,
        0,
        44,
        18,
        48,
        49,
        15,
        24,
        10,
        0,
        15,
        46,
        27
      ],
      "weeklyRejected": [
        23,
        49,
        21,
        30,
        18,
        0,
        49,
        23,
        47,
        1,
        25,
        43,
        11,
        22,
        5,
        1,
        17,
        49,
        49,
        32,
        27,
        39,
        49,
        9,
        10,
        0,
        4,
        44,
        48,
        28,
        49,
        34,
        48,
        49,
        19,
        29,
        49,
        23,
        24,
        49,
        18,
        18,
        45,
        21,
        49,
        48,
        19,
        20,
        19,
        29,
        20,
        26
      ],
      "weeklyHold": [
        32,
        21,
        36,
        22,
        42,
        31,
        16,
        16,
        26,
        2,
        4,
        1,
        48,
        39,
        16,
        0,
        26,
        26,
        31,
        10,
        8,
        17,
        31,
        18,
        0,
        33,
        8,
        40,
        25,
        0,
        48,
        20,
        0,
        46,
        48,
        46,
        40,
        32,
        46,
        6,
        49,
        25,
        0,
        24,
        49,
        0,
        0,
        34,
        0,
        14,
        38,
        49
      ],
      "weeklyBackordered": [
        24,
        40,
        17,
        26,
        42,
        28,
        49,
        49,
        32,
        43,
        49,
        46,
        23,
        42,
        32,
        34,
        35,
        49,
        0,
        37,
        25,
        0,
        5,
        19,
        13,
        31,
        34,
        4,
        49,
        49,
        33,
        27,
        13,
        25,
        0,
        0,
        30,
        13,
        5,
        35,
        15,
        48,
        45,
        17,
        23,
        40,
        26,
        36,
        0,
        42,
        48,
        49
      ]
    },
    {
      "id": "11102F47",
      "weeklyAccepted": [
        31,
        26,
        11,
        19,
        31,
        0,
        20,
        49,
        48,
        25,
        21,
        19,
        45,
        0,
        1,
        48,
        49,
        48,
        4,
        49,
        49,
        40,
        38,
        49,
        38,
        47,
        0,
        15,
        1,
        15,
        49,
        30,
        2,
        37,
        38,
        22,
        16,
        19,
        0,
        43,
        42,
        29,
        20,
        27,
        16,
        15,
        0,
        49,
        43,
        48,
        34,
        42
      ],
      "weeklyRejected": [
        34,
        3,
        43,
        28,
        7,
        31,
        40,
        16,
        49,
        39,
        1,
        24,
        9,
        48,
        40,
        9,
        15,
        28,
        47,
        39,
        12,
        49,
        27,
        49,
        3,
        49,
        31,
        0,
        13,
        26,
        49,
        47,
        48,
        49,
        0,
        49,
        16,
        21,
        22,
        20,
        48,
        22,
        38,
        33,
        39,
        13,
        0,
        15,
        0,
        49,
        16,
        43
      ],
      "weeklyHold": [
        33,
        0,
        49,
        20,
        33,
        22,
        0,
        42,
        23,
        24,
        47,
        26,
        13,
        31,
        13,
        22,
        11,
        11,
        11,
        22,
        9,
        30,
        0,
        29,
        23,
        26,
        0,
        26,
        49,
        36,
        40,
        2,
        0,
        49,
        47,
        30,
        0,
        5,
        17,
        0,
        49,
        25,
        38,
        37,
        49,
        12,
        42,
        41,
        5,
        8,
        16,
        42
      ],
      "weeklyBackordered": [
        38,
        49,
        48,
        44,
        35,
        34,
        0,
        10,
        37,
        13,
        34,
        41,
        37,
        2,
        23,
        1,
        38,
        34,
        24,
        34,
        49,
        37,
        22,
        19,
        28,
        24,
        49,
        16,
        32,
        26,
        0,
        7,
        48,
        34,
        0,
        0,
        30,
        11,
        16,
        13,
        3,
        48,
        15,
        34,
        29,
        6,
        22,
        6,
        18,
        49,
        49,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        22,
        38,
        35,
        19,
        49,
        9,
        38,
        34,
        44,
        0,
        20,
        0,
        31,
        33,
        2,
        42,
        40,
        0,
        8,
        49,
        18,
        16,
        45,
        47,
        12,
        47,
        49,
        26,
        6,
        16,
        0,
        44,
        49,
        0,
        48,
        19,
        0,
        48,
        28,
        49,
        25,
        41,
        49,
        20,
        49,
        3,
        5,
        35,
        19,
        49,
        26,
        44
      ],
      "weeklyRejected": [
        36,
        28,
        28,
        26,
        32,
        2,
        17,
        21,
        41,
        48,
        38,
        42,
        26,
        0,
        34,
        1,
        11,
        27,
        49,
        12,
        49,
        49,
        49,
        42,
        4,
        32,
        38,
        8,
        42,
        24,
        34,
        37,
        19,
        49,
        0,
        49,
        22,
        9,
        5,
        0,
        48,
        0,
        0,
        34,
        49,
        36,
        17,
        22,
        9,
        49,
        19,
        32
      ],
      "weeklyHold": [
        3,
        43,
        38,
        1,
        48,
        43,
        40,
        17,
        10,
        49,
        31,
        14,
        1,
        39,
        9,
        9,
        12,
        0,
        5,
        49,
        29,
        37,
        3,
        35,
        48,
        21,
        49,
        0,
        49,
        5,
        8,
        0,
        0,
        49,
        43,
        49,
        0,
        8,
        23,
        26,
        26,
        39,
        21,
        49,
        33,
        43,
        28,
        43,
        0,
        0,
        49,
        46
      ],
      "weeklyBackordered": [
        0,
        30,
        0,
        40,
        46,
        46,
        3,
        3,
        25,
        24,
        49,
        49,
        16,
        49,
        27,
        14,
        49,
        49,
        45,
        15,
        23,
        0,
        20,
        4,
        1,
        15,
        49,
        16,
        1,
        0,
        0,
        49,
        26,
        15,
        25,
        0,
        1,
        18,
        49,
        19,
        11,
        19,
        0,
        49,
        40,
        16,
        37,
        25,
        48,
        21,
        22,
        1
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyAccepted": [
        17,
        34,
        12,
        21,
        9,
        44,
        1,
        49,
        27,
        13,
        42,
        0,
        7,
        25,
        14,
        18,
        46,
        25,
        5,
        16,
        26,
        42,
        20,
        40,
        49,
        47,
        18,
        1,
        31,
        4,
        20,
        18,
        49,
        49,
        42,
        38,
        47,
        24,
        14,
        49,
        0,
        14,
        49,
        0,
        49,
        14,
        0,
        29,
        39,
        49,
        24,
        13
      ],
      "weeklyRejected": [
        48,
        40,
        31,
        35,
        49,
        36,
        49,
        0,
        27,
        24,
        15,
        0,
        0,
        21,
        31,
        7,
        49,
        45,
        32,
        46,
        11,
        8,
        30,
        19,
        0,
        49,
        46,
        8,
        48,
        6,
        21,
        25,
        41,
        48,
        0,
        43,
        33,
        49,
        1,
        0,
        22,
        0,
        49,
        39,
        49,
        16,
        43,
        3,
        29,
        31,
        19,
        49
      ],
      "weeklyHold": [
        19,
        2,
        44,
        10,
        16,
        31,
        13,
        2,
        2,
        27,
        36,
        14,
        8,
        1,
        32,
        34,
        2,
        41,
        16,
        0,
        0,
        49,
        11,
        6,
        44,
        21,
        0,
        49,
        49,
        30,
        47,
        29,
        9,
        44,
        12,
        35,
        21,
        25,
        15,
        34,
        1,
        33,
        39,
        48,
        49,
        41,
        23,
        48,
        0,
        49,
        20,
        47
      ],
      "weeklyBackordered": [
        36,
        49,
        7,
        48,
        10,
        37,
        9,
        21,
        7,
        20,
        44,
        16,
        3,
        35,
        44,
        6,
        21,
        39,
        12,
        12,
        38,
        43,
        18,
        21,
        44,
        22,
        5,
        21,
        38,
        49,
        0,
        49,
        22,
        0,
        0,
        35,
        44,
        29,
        13,
        7,
        8,
        34,
        14,
        49,
        12,
        30,
        33,
        32,
        0,
        49,
        49,
        35
      ]
    },
    {
      "id": "22633EF7",
      "weeklyAccepted": [
        40,
        42,
        49,
        10,
        35,
        13,
        7,
        37,
        26,
        11,
        49,
        0,
        47,
        49,
        11,
        22,
        38,
        12,
        34,
        34,
        30,
        49,
        23,
        12,
        0,
        31,
        14,
        20,
        9,
        33,
        1,
        49,
        19,
        15,
        48,
        49,
        13,
        22,
        0,
        19,
        21,
        29,
        11,
        0,
        29,
        0,
        16,
        35,
        0,
        18,
        49,
        25
      ],
      "weeklyRejected": [
        17,
        11,
        7,
        18,
        41,
        49,
        40,
        23,
        49,
        1,
        46,
        49,
        32,
        20,
        34,
        0,
        33,
        31,
        49,
        13,
        0,
        49,
        27,
        45,
        22,
        33,
        35,
        20,
        30,
        30,
        37,
        34,
        21,
        5,
        11,
        32,
        49,
        4,
        28,
        23,
        48,
        48,
        20,
        8,
        46,
        31,
        0,
        11,
        32,
        43,
        0,
        10
      ],
      "weeklyHold": [
        20,
        3,
        43,
        23,
        49,
        48,
        0,
        17,
        0,
        24,
        32,
        39,
        21,
        27,
        46,
        6,
        27,
        26,
        49,
        0,
        15,
        21,
        49,
        4,
        24,
        48,
        40,
        0,
        48,
        19,
        48,
        16,
        7,
        49,
        13,
        49,
        18,
        15,
        36,
        5,
        43,
        39,
        4,
        20,
        37,
        33,
        17,
        18,
        15,
        20,
        38,
        28
      ],
      "weeklyBackordered": [
        23,
        32,
        31,
        33,
        26,
        28,
        34,
        46,
        49,
        11,
        11,
        49,
        18,
        37,
        30,
        40,
        18,
        11,
        13,
        40,
        3,
        35,
        49,
        14,
        35,
        46,
        34,
        34,
        49,
        33,
        28,
        49,
        48,
        41,
        16,
        15,
        30,
        22,
        8,
        6,
        33,
        48,
        40,
        49,
        26,
        14,
        40,
        27,
        0,
        35,
        49,
        14
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        0,
        22,
        24,
        39,
        34,
        41,
        33,
        44,
        23,
        37,
        28,
        3,
        46,
        39,
        23,
        43,
        49,
        10,
        34,
        20,
        9,
        22,
        49,
        36,
        0,
        47,
        0,
        21,
        49,
        36,
        0,
        19,
        32,
        39,
        48,
        10,
        10,
        28,
        13,
        42,
        37,
        38,
        44,
        35,
        34,
        0,
        8,
        37,
        25,
        49,
        17,
        19
      ],
      "weeklyRejected": [
        49,
        49,
        41,
        18,
        20,
        11,
        12,
        34,
        46,
        46,
        25,
        49,
        29,
        9,
        41,
        0,
        19,
        49,
        4,
        16,
        27,
        36,
        29,
        12,
        33,
        49,
        49,
        12,
        48,
        6,
        49,
        41,
        16,
        49,
        2,
        24,
        47,
        31,
        7,
        20,
        46,
        5,
        16,
        19,
        44,
        14,
        38,
        49,
        0,
        49,
        32,
        30
      ],
      "weeklyHold": [
        23,
        0,
        49,
        7,
        0,
        48,
        29,
        18,
        0,
        49,
        43,
        1,
        34,
        19,
        16,
        15,
        0,
        48,
        22,
        20,
        12,
        0,
        33,
        0,
        8,
        48,
        45,
        0,
        18,
        7,
        48,
        0,
        19,
        0,
        30,
        27,
        42,
        5,
        41,
        39,
        34,
        33,
        0,
        49,
        47,
        34,
        3,
        39,
        25,
        32,
        43,
        47
      ],
      "weeklyBackordered": [
        26,
        22,
        17,
        49,
        29,
        7,
        42,
        25,
        41,
        25,
        41,
        49,
        30,
        37,
        31,
        19,
        34,
        46,
        25,
        34,
        18,
        0,
        0,
        6,
        19,
        3,
        39,
        0,
        30,
        24,
        24,
        41,
        29,
        12,
        3,
        0,
        48,
        34,
        36,
        0,
        8,
        40,
        0,
        49,
        48,
        29,
        0,
        6,
        32,
        11,
        45,
        2
      ]
    },
    {
      "id": "19A12B20",
      "weeklyAccepted": [
        0,
        28,
        23,
        0,
        38,
        32,
        38,
        37,
        46,
        14,
        41,
        8,
        49,
        49,
        27,
        33,
        49,
        12,
        21,
        38,
        0,
        44,
        6,
        37,
        39,
        47,
        0,
        34,
        16,
        14,
        0,
        9,
        49,
        13,
        8,
        43,
        25,
        16,
        42,
        48,
        0,
        0,
        35,
        37,
        37,
        17,
        14,
        0,
        24,
        49,
        46,
        7
      ],
      "weeklyRejected": [
        49,
        17,
        20,
        4,
        27,
        21,
        0,
        49,
        49,
        29,
        0,
        49,
        19,
        48,
        2,
        24,
        3,
        46,
        28,
        1,
        17,
        6,
        13,
        15,
        36,
        26,
        49,
        3,
        38,
        40,
        23,
        17,
        44,
        18,
        37,
        49,
        49,
        44,
        1,
        4,
        18,
        20,
        12,
        15,
        46,
        47,
        0,
        7,
        0,
        49,
        25,
        30
      ],
      "weeklyHold": [
        49,
        1,
        49,
        19,
        9,
        39,
        30,
        2,
        38,
        23,
        27,
        13,
        37,
        34,
        36,
        0,
        48,
        23,
        24,
        9,
        6,
        27,
        44,
        26,
        49,
        35,
        1,
        47,
        38,
        0,
        34,
        19,
        24,
        42,
        16,
        39,
        32,
        9,
        20,
        49,
        0,
        27,
        6,
        49,
        12,
        0,
        23,
        33,
        19,
        4,
        48,
        1
      ],
      "weeklyBackordered": [
        49,
        28,
        11,
        46,
        49,
        46,
        38,
        1,
        22,
        26,
        49,
        24,
        3,
        38,
        32,
        3,
        34,
        31,
        34,
        48,
        39,
        4,
        17,
        39,
        1,
        15,
        49,
        42,
        49,
        41,
        0,
        10,
        48,
        0,
        0,
        0,
        38,
        7,
        26,
        0,
        0,
        48,
        0,
        11,
        0,
        0,
        34,
        6,
        34,
        32,
        49,
        47
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        21,
        1,
        44,
        6,
        49,
        6,
        28,
        49,
        9,
        49,
        28,
        0,
        11,
        37,
        1,
        26,
        49,
        20,
        31,
        9,
        23,
        31,
        21,
        24,
        17,
        0,
        29,
        15,
        41,
        22,
        10,
        18,
        21,
        1,
        36,
        45,
        36,
        6,
        34,
        49,
        11,
        49,
        21,
        49,
        21,
        46,
        44,
        0,
        0,
        15,
        42,
        48
      ],
      "weeklyRejected": [
        49,
        49,
        18,
        18,
        23,
        25,
        21,
        16,
        49,
        10,
        34,
        38,
        0,
        3,
        25,
        16,
        24,
        28,
        35,
        6,
        49,
        34,
        29,
        34,
        25,
        34,
        0,
        20,
        14,
        30,
        14,
        24,
        18,
        49,
        0,
        49,
        47,
        14,
        1,
        46,
        17,
        19,
        49,
        10,
        49,
        40,
        11,
        20,
        0,
        36,
        0,
        27
      ],
      "weeklyHold": [
        3,
        2,
        40,
        21,
        49,
        0,
        13,
        27,
        4,
        27,
        0,
        1,
        48,
        35,
        33,
        28,
        1,
        37,
        13,
        28,
        49,
        34,
        46,
        49,
        25,
        34,
        45,
        0,
        47,
        0,
        48,
        0,
        0,
        46,
        48,
        27,
        0,
        16,
        33,
        41,
        47,
        49,
        16,
        44,
        40,
        40,
        33,
        0,
        0,
        26,
        19,
        0
      ],
      "weeklyBackordered": [
        49,
        43,
        0,
        31,
        40,
        36,
        3,
        35,
        49,
        0,
        16,
        7,
        40,
        33,
        21,
        30,
        15,
        40,
        10,
        41,
        49,
        36,
        30,
        31,
        39,
        31,
        15,
        0,
        1,
        49,
        40,
        17,
        0,
        49,
        18,
        0,
        25,
        2,
        6,
        24,
        23,
        48,
        0,
        49,
        19,
        32,
        25,
        5,
        0,
        37,
        17,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyAccepted": [
        45,
        25,
        26,
        34,
        16,
        17,
        7,
        38,
        25,
        19,
        46,
        27,
        27,
        8,
        26,
        0,
        38,
        0,
        12,
        0,
        49,
        25,
        49,
        4,
        30,
        28,
        8,
        46,
        25,
        17,
        0,
        40,
        47,
        24,
        48,
        49,
        43,
        43,
        35,
        30,
        0,
        23,
        44,
        16,
        31,
        34,
        41,
        8,
        40,
        4,
        13,
        15
      ],
      "weeklyRejected": [
        38,
        11,
        16,
        18,
        6,
        31,
        21,
        10,
        41,
        1,
        49,
        22,
        14,
        36,
        48,
        13,
        36,
        44,
        49,
        28,
        34,
        33,
        15,
        49,
        39,
        29,
        29,
        21,
        48,
        48,
        17,
        47,
        48,
        12,
        31,
        30,
        34,
        39,
        22,
        49,
        21,
        36,
        14,
        0,
        36,
        0,
        2,
        7,
        13,
        49,
        13,
        29
      ],
      "weeklyHold": [
        49,
        11,
        32,
        41,
        3,
        17,
        17,
        41,
        14,
        22,
        47,
        24,
        1,
        19,
        49,
        0,
        5,
        10,
        2,
        0,
        0,
        17,
        30,
        24,
        32,
        12,
        0,
        12,
        35,
        9,
        48,
        42,
        12,
        49,
        31,
        46,
        22,
        0,
        1,
        28,
        49,
        32,
        10,
        49,
        14,
        25,
        11,
        45,
        12,
        6,
        36,
        47
      ],
      "weeklyBackordered": [
        0,
        35,
        0,
        28,
        45,
        41,
        30,
        47,
        26,
        33,
        8,
        29,
        3,
        11,
        11,
        12,
        27,
        23,
        0,
        0,
        25,
        42,
        34,
        49,
        48,
        9,
        24,
        49,
        17,
        46,
        38,
        27,
        22,
        46,
        0,
        11,
        29,
        15,
        20,
        12,
        40,
        48,
        20,
        31,
        10,
        20,
        49,
        29,
        24,
        16,
        13,
        16
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        49,
        18,
        27,
        32,
        8,
        6,
        1,
        21,
        48,
        19,
        49,
        0,
        5,
        24,
        28,
        0,
        47,
        0,
        0,
        49,
        5,
        28,
        3,
        23,
        32,
        29,
        31,
        7,
        5,
        49,
        0,
        49,
        49,
        16,
        48,
        13,
        26,
        21,
        29,
        49,
        23,
        40,
        49,
        16,
        8,
        0,
        42,
        0,
        6,
        14,
        39,
        34
      ],
      "weeklyRejected": [
        49,
        2,
        9,
        19,
        8,
        15,
        0,
        49,
        29,
        30,
        1,
        39,
        11,
        5,
        29,
        2,
        29,
        49,
        25,
        49,
        5,
        49,
        6,
        49,
        49,
        0,
        49,
        49,
        12,
        18,
        22,
        45,
        33,
        35,
        22,
        49,
        49,
        17,
        1,
        19,
        24,
        9,
        0,
        0,
        44,
        20,
        0,
        13,
        36,
        42,
        32,
        49
      ],
      "weeklyHold": [
        3,
        16,
        36,
        34,
        43,
        23,
        36,
        30,
        34,
        34,
        44,
        27,
        7,
        15,
        11,
        0,
        21,
        40,
        13,
        0,
        15,
        0,
        33,
        30,
        33,
        48,
        9,
        16,
        48,
        49,
        38,
        6,
        25,
        22,
        48,
        39,
        2,
        10,
        33,
        34,
        15,
        49,
        43,
        24,
        27,
        49,
        0,
        42,
        0,
        30,
        31,
        30
      ],
      "weeklyBackordered": [
        0,
        0,
        20,
        33,
        39,
        37,
        34,
        40,
        49,
        7,
        49,
        18,
        12,
        36,
        0,
        1,
        1,
        30,
        0,
        19,
        24,
        40,
        10,
        32,
        12,
        10,
        43,
        0,
        49,
        19,
        49,
        27,
        48,
        19,
        17,
        0,
        11,
        0,
        34,
        36,
        16,
        48,
        3,
        49,
        29,
        28,
        21,
        34,
        7,
        40,
        30,
        1
      ]
    },
    {
      "id": "9202537",
      "weeklyAccepted": [
        33,
        42,
        49,
        48,
        1,
        7,
        41,
        49,
        37,
        0,
        27,
        16,
        42,
        0,
        1,
        19,
        49,
        0,
        11,
        0,
        45,
        39,
        22,
        12,
        49,
        0,
        1,
        49,
        33,
        41,
        41,
        49,
        49,
        10,
        26,
        2,
        18,
        23,
        49,
        28,
        20,
        31,
        25,
        29,
        39,
        16,
        7,
        7,
        11,
        45,
        49,
        17
      ],
      "weeklyRejected": [
        49,
        38,
        27,
        10,
        26,
        37,
        29,
        49,
        49,
        16,
        49,
        43,
        20,
        32,
        40,
        19,
        24,
        36,
        22,
        45,
        49,
        30,
        0,
        12,
        27,
        15,
        34,
        45,
        48,
        15,
        46,
        24,
        31,
        29,
        33,
        49,
        45,
        11,
        43,
        5,
        27,
        0,
        35,
        27,
        49,
        31,
        26,
        29,
        0,
        30,
        0,
        49
      ],
      "weeklyHold": [
        37,
        18,
        49,
        1,
        49,
        33,
        1,
        23,
        19,
        49,
        46,
        14,
        1,
        26,
        34,
        11,
        1,
        14,
        37,
        12,
        9,
        35,
        0,
        0,
        28,
        17,
        49,
        33,
        49,
        0,
        26,
        9,
        4,
        49,
        29,
        40,
        18,
        4,
        42,
        0,
        0,
        37,
        27,
        21,
        49,
        25,
        0,
        43,
        0,
        1,
        41,
        49
      ],
      "weeklyBackordered": [
        46,
        39,
        9,
        34,
        35,
        19,
        49,
        35,
        20,
        42,
        23,
        29,
        38,
        36,
        37,
        31,
        49,
        49,
        16,
        49,
        6,
        1,
        13,
        0,
        7,
        44,
        32,
        24,
        25,
        49,
        4,
        25,
        27,
        9,
        0,
        12,
        19,
        0,
        4,
        46,
        1,
        48,
        17,
        49,
        44,
        0,
        37,
        18,
        31,
        3,
        44,
        24
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyAccepted": [
        24,
        42,
        28,
        23,
        3,
        24,
        1,
        6,
        40,
        15,
        49,
        19,
        36,
        47,
        48,
        14,
        18,
        41,
        9,
        49,
        28,
        14,
        49,
        46,
        14,
        31,
        33,
        0,
        1,
        8,
        22,
        43,
        2,
        25,
        48,
        34,
        47,
        17,
        0,
        49,
        28,
        37,
        36,
        20,
        49,
        10,
        10,
        47,
        3,
        40,
        11,
        15
      ],
      "weeklyRejected": [
        21,
        27,
        25,
        47,
        31,
        21,
        0,
        17,
        37,
        32,
        46,
        49,
        47,
        40,
        48,
        11,
        0,
        49,
        14,
        7,
        0,
        49,
        20,
        14,
        34,
        12,
        32,
        38,
        48,
        0,
        49,
        0,
        48,
        49,
        17,
        49,
        3,
        28,
        12,
        49,
        43,
        49,
        30,
        21,
        49,
        0,
        15,
        0,
        32,
        49,
        18,
        49
      ],
      "weeklyHold": [
        49,
        20,
        42,
        9,
        0,
        43,
        49,
        4,
        21,
        5,
        47,
        23,
        13,
        6,
        14,
        28,
        0,
        46,
        10,
        0,
        3,
        19,
        10,
        0,
        38,
        7,
        19,
        17,
        49,
        0,
        48,
        48,
        12,
        12,
        16,
        10,
        49,
        0,
        47,
        32,
        24,
        27,
        0,
        44,
        34,
        23,
        7,
        38,
        32,
        40,
        4,
        19
      ],
      "weeklyBackordered": [
        19,
        26,
        24,
        36,
        25,
        6,
        25,
        27,
        49,
        23,
        21,
        20,
        16,
        36,
        42,
        13,
        1,
        12,
        0,
        15,
        31,
        21,
        0,
        39,
        43,
        1,
        28,
        24,
        49,
        29,
        27,
        49,
        43,
        48,
        0,
        44,
        22,
        0,
        30,
        42,
        0,
        32,
        12,
        19,
        0,
        19,
        49,
        23,
        35,
        36,
        38,
        33
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        22,
        49,
        32,
        48,
        49,
        14,
        1,
        49,
        48,
        4,
        34,
        0,
        27,
        2,
        17,
        37,
        15,
        0,
        18,
        9,
        1,
        16,
        39,
        34,
        14,
        28,
        33,
        15,
        19,
        49,
        0,
        28,
        27,
        25,
        42,
        15,
        47,
        46,
        7,
        49,
        49,
        27,
        49,
        49,
        27,
        16,
        15,
        10,
        15,
        44,
        8,
        48
      ],
      "weeklyRejected": [
        43,
        0,
        39,
        16,
        9,
        44,
        32,
        20,
        24,
        48,
        46,
        0,
        20,
        27,
        41,
        29,
        32,
        43,
        40,
        16,
        49,
        39,
        36,
        38,
        49,
        49,
        10,
        24,
        18,
        17,
        49,
        47,
        37,
        35,
        35,
        49,
        49,
        17,
        8,
        22,
        42,
        17,
        30,
        22,
        49,
        12,
        35,
        49,
        36,
        35,
        0,
        49
      ],
      "weeklyHold": [
        2,
        15,
        46,
        1,
        48,
        5,
        48,
        27,
        41,
        1,
        45,
        44,
        27,
        36,
        31,
        33,
        0,
        36,
        49,
        22,
        3,
        15,
        12,
        35,
        42,
        29,
        23,
        16,
        49,
        0,
        48,
        18,
        7,
        46,
        6,
        38,
        0,
        0,
        46,
        23,
        28,
        49,
        49,
        42,
        49,
        32,
        41,
        49,
        0,
        2,
        35,
        0
      ],
      "weeklyBackordered": [
        30,
        49,
        29,
        35,
        46,
        34,
        45,
        12,
        30,
        24,
        33,
        39,
        6,
        35,
        22,
        16,
        49,
        31,
        1,
        21,
        49,
        26,
        19,
        42,
        13,
        22,
        5,
        0,
        1,
        28,
        9,
        46,
        24,
        44,
        0,
        0,
        7,
        0,
        49,
        41,
        37,
        48,
        26,
        49,
        6,
        0,
        46,
        3,
        31,
        25,
        45,
        32
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyAccepted": [
        15,
        1,
        23,
        33,
        27,
        24,
        32,
        49,
        39,
        42,
        29,
        0,
        49,
        25,
        10,
        33,
        17,
        48,
        9,
        21,
        49,
        14,
        49,
        18,
        49,
        37,
        36,
        7,
        12,
        14,
        12,
        12,
        33,
        22,
        46,
        49,
        41,
        18,
        1,
        49,
        20,
        49,
        49,
        35,
        49,
        18,
        0,
        25,
        49,
        4,
        22,
        48
      ],
      "weeklyRejected": [
        39,
        21,
        2,
        37,
        2,
        25,
        47,
        0,
        49,
        1,
        44,
        12,
        0,
        14,
        47,
        49,
        0,
        40,
        30,
        14,
        36,
        2,
        17,
        22,
        28,
        40,
        49,
        0,
        48,
        48,
        4,
        33,
        33,
        49,
        15,
        43,
        32,
        6,
        1,
        24,
        34,
        4,
        49,
        2,
        30,
        49,
        17,
        5,
        16,
        49,
        21,
        49
      ],
      "weeklyHold": [
        8,
        48,
        14,
        1,
        21,
        48,
        10,
        33,
        49,
        49,
        37,
        36,
        1,
        42,
        12,
        0,
        0,
        30,
        12,
        6,
        12,
        32,
        4,
        31,
        39,
        27,
        20,
        12,
        49,
        13,
        48,
        21,
        16,
        1,
        23,
        37,
        37,
        0,
        1,
        20,
        38,
        40,
        0,
        36,
        49,
        3,
        8,
        35,
        1,
        14,
        32,
        35
      ],
      "weeklyBackordered": [
        35,
        13,
        35,
        10,
        49,
        7,
        27,
        44,
        42,
        47,
        17,
        42,
        32,
        0,
        0,
        19,
        44,
        49,
        34,
        46,
        49,
        34,
        35,
        12,
        15,
        27,
        2,
        8,
        13,
        47,
        0,
        49,
        43,
        14,
        0,
        0,
        16,
        0,
        1,
        0,
        0,
        30,
        0,
        48,
        33,
        49,
        49,
        10,
        10,
        43,
        49,
        22
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyAccepted": [
        0,
        49,
        23,
        32,
        1,
        22,
        10,
        30,
        23,
        5,
        32,
        22,
        29,
        40,
        14,
        44,
        32,
        0,
        24,
        4,
        4,
        26,
        14,
        22,
        29,
        31,
        14,
        13,
        33,
        41,
        42,
        48,
        49,
        16,
        45,
        44,
        7,
        22,
        49,
        44,
        16,
        31,
        49,
        49,
        0,
        28,
        11,
        48,
        20,
        44,
        10,
        29
      ],
      "weeklyRejected": [
        49,
        31,
        49,
        0,
        39,
        0,
        41,
        49,
        49,
        34,
        23,
        21,
        33,
        29,
        31,
        21,
        12,
        24,
        49,
        23,
        22,
        12,
        20,
        21,
        12,
        7,
        41,
        26,
        38,
        46,
        24,
        23,
        48,
        18,
        49,
        49,
        27,
        14,
        1,
        45,
        25,
        39,
        32,
        16,
        49,
        41,
        26,
        49,
        17,
        35,
        30,
        6
      ],
      "weeklyHold": [
        32,
        23,
        42,
        1,
        49,
        47,
        0,
        16,
        34,
        49,
        31,
        16,
        36,
        30,
        38,
        15,
        0,
        8,
        0,
        0,
        14,
        29,
        19,
        1,
        34,
        27,
        49,
        18,
        46,
        23,
        48,
        0,
        0,
        49,
        40,
        49,
        18,
        0,
        39,
        21,
        21,
        32,
        18,
        28,
        13,
        27,
        41,
        32,
        0,
        23,
        42,
        29
      ],
      "weeklyBackordered": [
        49,
        42,
        19,
        34,
        43,
        46,
        37,
        37,
        28,
        36,
        43,
        0,
        39,
        49,
        28,
        9,
        17,
        49,
        37,
        47,
        19,
        12,
        10,
        12,
        22,
        18,
        49,
        49,
        24,
        30,
        11,
        49,
        17,
        20,
        0,
        0,
        33,
        44,
        14,
        49,
        36,
        44,
        5,
        28,
        27,
        14,
        44,
        0,
        0,
        43,
        30,
        45
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyAccepted": [
        0,
        38,
        36,
        8,
        41,
        22,
        22,
        49,
        0,
        30,
        1,
        8,
        49,
        23,
        2,
        17,
        49,
        0,
        6,
        21,
        45,
        37,
        7,
        49,
        33,
        43,
        0,
        9,
        31,
        49,
        40,
        49,
        49,
        23,
        27,
        21,
        25,
        15,
        21,
        21,
        0,
        36,
        33,
        36,
        49,
        26,
        20,
        17,
        40,
        42,
        19,
        15
      ],
      "weeklyRejected": [
        49,
        33,
        33,
        42,
        48,
        35,
        49,
        24,
        49,
        1,
        26,
        33,
        21,
        43,
        48,
        0,
        9,
        24,
        42,
        0,
        11,
        26,
        0,
        26,
        37,
        35,
        19,
        26,
        25,
        2,
        48,
        47,
        48,
        49,
        36,
        27,
        43,
        45,
        1,
        9,
        18,
        49,
        49,
        49,
        48,
        23,
        17,
        49,
        18,
        22,
        0,
        46
      ],
      "weeklyHold": [
        13,
        7,
        49,
        43,
        0,
        41,
        20,
        5,
        0,
        20,
        14,
        1,
        22,
        8,
        39,
        8,
        18,
        23,
        33,
        5,
        14,
        49,
        3,
        0,
        16,
        36,
        3,
        28,
        22,
        23,
        37,
        39,
        29,
        49,
        0,
        43,
        48,
        22,
        48,
        14,
        25,
        47,
        11,
        0,
        24,
        14,
        44,
        18,
        12,
        0,
        49,
        49
      ],
      "weeklyBackordered": [
        43,
        49,
        0,
        28,
        27,
        40,
        35,
        24,
        31,
        29,
        7,
        49,
        3,
        41,
        15,
        49,
        41,
        49,
        24,
        16,
        38,
        15,
        28,
        21,
        20,
        13,
        29,
        12,
        23,
        49,
        43,
        40,
        36,
        49,
        49,
        0,
        35,
        32,
        18,
        39,
        28,
        48,
        11,
        36,
        20,
        17,
        18,
        41,
        28,
        21,
        48,
        11
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyAccepted": [
        49,
        43,
        2,
        40,
        49,
        32,
        26,
        43,
        17,
        9,
        26,
        49,
        4,
        49,
        19,
        18,
        33,
        0,
        2,
        2,
        24,
        30,
        5,
        17,
        28,
        23,
        10,
        7,
        1,
        49,
        2,
        23,
        19,
        36,
        36,
        43,
        24,
        48,
        0,
        22,
        0,
        49,
        49,
        28,
        17,
        0,
        6,
        29,
        39,
        37,
        25,
        18
      ],
      "weeklyRejected": [
        39,
        0,
        31,
        1,
        19,
        9,
        12,
        49,
        49,
        1,
        31,
        24,
        20,
        34,
        42,
        29,
        49,
        18,
        0,
        29,
        0,
        49,
        49,
        49,
        24,
        49,
        27,
        0,
        44,
        37,
        34,
        39,
        41,
        16,
        49,
        36,
        36,
        22,
        49,
        0,
        37,
        49,
        3,
        0,
        42,
        35,
        43,
        27,
        21,
        49,
        0,
        25
      ],
      "weeklyHold": [
        4,
        0,
        49,
        17,
        24,
        31,
        0,
        2,
        14,
        12,
        32,
        28,
        8,
        42,
        41,
        0,
        6,
        23,
        39,
        21,
        18,
        0,
        32,
        0,
        13,
        27,
        1,
        0,
        36,
        7,
        48,
        4,
        34,
        31,
        48,
        22,
        38,
        0,
        15,
        0,
        46,
        25,
        8,
        37,
        49,
        33,
        1,
        27,
        0,
        0,
        35,
        28
      ],
      "weeklyBackordered": [
        24,
        0,
        9,
        0,
        0,
        33,
        25,
        27,
        30,
        25,
        35,
        19,
        4,
        46,
        15,
        29,
        41,
        49,
        2,
        30,
        49,
        19,
        17,
        49,
        40,
        25,
        1,
        33,
        30,
        8,
        32,
        49,
        44,
        49,
        0,
        0,
        12,
        15,
        21,
        0,
        31,
        48,
        0,
        27,
        23,
        21,
        49,
        16,
        40,
        4,
        31,
        10
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        8,
        38,
        34,
        22,
        26,
        0,
        49,
        46,
        31,
        3,
        44,
        35,
        49,
        16,
        17,
        48,
        38,
        20,
        0,
        24,
        49,
        33,
        45,
        49,
        44,
        1,
        49,
        28,
        40,
        49,
        49,
        0,
        49,
        0,
        22,
        29,
        8,
        20,
        10,
        49,
        0,
        31,
        43,
        18,
        45,
        40,
        3,
        12,
        7,
        32,
        39,
        48
      ],
      "weeklyRejected": [
        35,
        17,
        34,
        28,
        25,
        38,
        35,
        46,
        9,
        9,
        35,
        44,
        32,
        41,
        48,
        0,
        49,
        48,
        0,
        49,
        24,
        19,
        0,
        45,
        39,
        49,
        12,
        0,
        48,
        7,
        40,
        24,
        43,
        49,
        47,
        37,
        24,
        0,
        1,
        6,
        9,
        36,
        9,
        0,
        49,
        0,
        33,
        40,
        0,
        20,
        11,
        41
      ],
      "weeklyHold": [
        46,
        33,
        49,
        15,
        49,
        45,
        35,
        23,
        25,
        33,
        47,
        22,
        28,
        37,
        7,
        35,
        18,
        48,
        16,
        35,
        42,
        47,
        16,
        5,
        49,
        48,
        0,
        15,
        38,
        0,
        29,
        0,
        0,
        32,
        13,
        8,
        32,
        5,
        13,
        45,
        14,
        38,
        16,
        2,
        40,
        8,
        36,
        12,
        6,
        0,
        33,
        22
      ],
      "weeklyBackordered": [
        45,
        49,
        0,
        35,
        42,
        21,
        49,
        27,
        23,
        0,
        24,
        49,
        3,
        38,
        37,
        26,
        49,
        48,
        0,
        8,
        31,
        22,
        24,
        3,
        1,
        21,
        8,
        14,
        12,
        49,
        3,
        24,
        40,
        43,
        6,
        8,
        6,
        14,
        49,
        49,
        0,
        48,
        5,
        49,
        23,
        0,
        17,
        7,
        11,
        14,
        25,
        20
      ]
    },
    {
      "id": "F36679BC",
      "weeklyAccepted": [
        7,
        24,
        11,
        48,
        36,
        32,
        16,
        2,
        38,
        21,
        46,
        23,
        42,
        0,
        14,
        49,
        7,
        0,
        15,
        24,
        3,
        22,
        49,
        49,
        13,
        47,
        0,
        23,
        24,
        40,
        4,
        16,
        27,
        20,
        34,
        43,
        32,
        10,
        21,
        12,
        9,
        41,
        11,
        8,
        49,
        0,
        10,
        49,
        49,
        29,
        0,
        37
      ],
      "weeklyRejected": [
        26,
        22,
        45,
        47,
        17,
        0,
        1,
        44,
        26,
        13,
        24,
        21,
        38,
        21,
        15,
        34,
        43,
        45,
        0,
        20,
        27,
        41,
        49,
        49,
        18,
        48,
        49,
        16,
        40,
        0,
        0,
        17,
        39,
        42,
        19,
        49,
        16,
        12,
        1,
        26,
        22,
        49,
        0,
        40,
        21,
        29,
        6,
        5,
        19,
        47,
        0,
        49
      ],
      "weeklyHold": [
        49,
        45,
        49,
        21,
        21,
        13,
        31,
        16,
        32,
        4,
        40,
        9,
        48,
        14,
        16,
        6,
        11,
        41,
        30,
        4,
        15,
        43,
        19,
        28,
        49,
        25,
        10,
        32,
        35,
        28,
        13,
        2,
        19,
        22,
        39,
        0,
        16,
        20,
        49,
        49,
        43,
        40,
        20,
        2,
        41,
        5,
        22,
        26,
        15,
        32,
        49,
        31
      ],
      "weeklyBackordered": [
        49,
        1,
        7,
        13,
        25,
        1,
        43,
        34,
        5,
        0,
        24,
        30,
        7,
        31,
        30,
        49,
        45,
        44,
        13,
        41,
        21,
        25,
        3,
        23,
        1,
        25,
        46,
        49,
        16,
        49,
        0,
        15,
        48,
        26,
        37,
        6,
        1,
        14,
        0,
        7,
        14,
        39,
        23,
        5,
        9,
        49,
        33,
        32,
        49,
        49,
        35,
        1
      ]
    },
    {
      "id": "4FF22342",
      "weeklyAccepted": [
        39,
        14,
        49,
        35,
        32,
        18,
        26,
        20,
        34,
        0,
        49,
        0,
        49,
        49,
        1,
        49,
        21,
        37,
        0,
        36,
        25,
        32,
        34,
        25,
        30,
        47,
        0,
        49,
        1,
        19,
        16,
        0,
        19,
        13,
        48,
        49,
        47,
        6,
        32,
        49,
        26,
        37,
        23,
        25,
        32,
        30,
        0,
        36,
        0,
        24,
        49,
        0
      ],
      "weeklyRejected": [
        49,
        25,
        19,
        47,
        49,
        0,
        17,
        23,
        37,
        47,
        49,
        38,
        19,
        25,
        21,
        3,
        0,
        43,
        0,
        25,
        26,
        44,
        12,
        10,
        38,
        48,
        42,
        3,
        39,
        26,
        0,
        29,
        36,
        18,
        24,
        49,
        18,
        8,
        49,
        14,
        48,
        42,
        24,
        17,
        45,
        42,
        6,
        28,
        37,
        48,
        10,
        21
      ],
      "weeklyHold": [
        26,
        49,
        49,
        13,
        0,
        2,
        49,
        2,
        0,
        0,
        35,
        24,
        29,
        40,
        7,
        0,
        17,
        32,
        19,
        21,
        19,
        45,
        2,
        49,
        20,
        27,
        37,
        29,
        49,
        0,
        43,
        0,
        20,
        49,
        47,
        19,
        21,
        16,
        42,
        6,
        5,
        49,
        0,
        6,
        15,
        42,
        0,
        19,
        0,
        31,
        49,
        49
      ],
      "weeklyBackordered": [
        31,
        15,
        24,
        10,
        23,
        46,
        34,
        49,
        30,
        16,
        49,
        29,
        15,
        42,
        27,
        49,
        28,
        48,
        0,
        49,
        34,
        0,
        23,
        29,
        1,
        22,
        20,
        19,
        1,
        44,
        25,
        0,
        46,
        9,
        0,
        10,
        1,
        11,
        19,
        15,
        22,
        48,
        23,
        37,
        45,
        24,
        39,
        16,
        0,
        24,
        34,
        17
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        17,
        44,
        15,
        32,
        15,
        35,
        6,
        20,
        48,
        26,
        49,
        0,
        17,
        12,
        8,
        35,
        49,
        36,
        0,
        0,
        18,
        44,
        24,
        49,
        25,
        25,
        1,
        11,
        2,
        11,
        2,
        0,
        49,
        12,
        44,
        46,
        47,
        44,
        40,
        49,
        36,
        39,
        49,
        49,
        49,
        13,
        49,
        41,
        25,
        12,
        11,
        11
      ],
      "weeklyRejected": [
        39,
        6,
        38,
        0,
        5,
        19,
        35,
        39,
        36,
        48,
        49,
        24,
        49,
        48,
        48,
        8,
        0,
        49,
        43,
        7,
        15,
        49,
        49,
        18,
        20,
        49,
        0,
        35,
        3,
        37,
        42,
        35,
        25,
        40,
        4,
        49,
        49,
        38,
        19,
        24,
        39,
        36,
        33,
        12,
        44,
        22,
        0,
        22,
        26,
        48,
        0,
        25
      ],
      "weeklyHold": [
        18,
        49,
        4,
        32,
        38,
        33,
        33,
        17,
        8,
        41,
        18,
        49,
        23,
        28,
        49,
        45,
        0,
        37,
        13,
        29,
        10,
        1,
        32,
        49,
        38,
        7,
        48,
        3,
        49,
        0,
        38,
        12,
        31,
        19,
        25,
        33,
        34,
        9,
        20,
        18,
        14,
        49,
        0,
        22,
        30,
        23,
        15,
        17,
        10,
        49,
        49,
        47
      ],
      "weeklyBackordered": [
        35,
        49,
        0,
        15,
        49,
        16,
        39,
        1,
        35,
        7,
        5,
        25,
        3,
        12,
        38,
        16,
        49,
        0,
        15,
        16,
        49,
        49,
        17,
        49,
        14,
        39,
        32,
        0,
        23,
        49,
        7,
        42,
        15,
        38,
        36,
        0,
        1,
        0,
        49,
        0,
        37,
        48,
        29,
        30,
        10,
        0,
        21,
        20,
        4,
        0,
        49,
        14
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyAccepted": [
        40,
        22,
        9,
        40,
        40,
        17,
        1,
        29,
        48,
        5,
        22,
        38,
        49,
        10,
        1,
        0,
        49,
        25,
        0,
        19,
        29,
        13,
        21,
        49,
        0,
        10,
        0,
        15,
        2,
        49,
        49,
        4,
        10,
        21,
        36,
        42,
        33,
        41,
        49,
        16,
        0,
        8,
        21,
        11,
        40,
        7,
        0,
        49,
        49,
        11,
        0,
        48
      ],
      "weeklyRejected": [
        17,
        22,
        41,
        25,
        38,
        0,
        38,
        31,
        34,
        45,
        27,
        0,
        22,
        3,
        3,
        9,
        16,
        46,
        0,
        49,
        19,
        49,
        3,
        27,
        26,
        31,
        47,
        49,
        35,
        20,
        0,
        47,
        42,
        25,
        39,
        49,
        42,
        11,
        4,
        8,
        45,
        49,
        24,
        22,
        47,
        0,
        0,
        43,
        24,
        23,
        0,
        37
      ],
      "weeklyHold": [
        28,
        33,
        34,
        20,
        1,
        46,
        26,
        28,
        18,
        29,
        41,
        26,
        43,
        29,
        27,
        43,
        49,
        31,
        15,
        0,
        0,
        49,
        13,
        0,
        41,
        30,
        32,
        21,
        49,
        49,
        12,
        31,
        0,
        49,
        2,
        17,
        31,
        3,
        39,
        28,
        5,
        4,
        4,
        0,
        0,
        40,
        0,
        0,
        1,
        25,
        10,
        34
      ],
      "weeklyBackordered": [
        0,
        49,
        18,
        31,
        20,
        35,
        31,
        36,
        48,
        0,
        33,
        48,
        30,
        49,
        21,
        45,
        49,
        49,
        0,
        0,
        27,
        17,
        49,
        23,
        18,
        17,
        32,
        22,
        23,
        49,
        24,
        2,
        45,
        47,
        13,
        49,
        21,
        3,
        12,
        47,
        48,
        48,
        0,
        49,
        11,
        33,
        41,
        29,
        5,
        40,
        1,
        43
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyAccepted": [
        1,
        44,
        13,
        48,
        20,
        0,
        11,
        23,
        36,
        25,
        34,
        0,
        38,
        43,
        18,
        46,
        49,
        47,
        0,
        34,
        49,
        31,
        48,
        32,
        12,
        22,
        19,
        38,
        3,
        10,
        3,
        11,
        30,
        49,
        17,
        49,
        2,
        18,
        31,
        48,
        38,
        35,
        23,
        19,
        49,
        12,
        11,
        18,
        6,
        49,
        16,
        19
      ],
      "weeklyRejected": [
        1,
        11,
        21,
        25,
        35,
        0,
        0,
        14,
        48,
        48,
        1,
        20,
        17,
        14,
        2,
        29,
        49,
        32,
        14,
        49,
        49,
        10,
        42,
        13,
        15,
        49,
        46,
        8,
        48,
        13,
        24,
        47,
        30,
        0,
        0,
        10,
        4,
        43,
        11,
        17,
        31,
        21,
        18,
        16,
        39,
        26,
        23,
        49,
        10,
        49,
        13,
        49
      ],
      "weeklyHold": [
        49,
        0,
        18,
        1,
        19,
        11,
        20,
        2,
        37,
        36,
        41,
        48,
        24,
        5,
        31,
        15,
        25,
        0,
        0,
        41,
        33,
        35,
        49,
        6,
        9,
        48,
        36,
        5,
        49,
        0,
        48,
        21,
        0,
        11,
        48,
        9,
        24,
        26,
        38,
        12,
        20,
        49,
        22,
        0,
        40,
        49,
        3,
        25,
        18,
        31,
        38,
        25
      ],
      "weeklyBackordered": [
        0,
        5,
        12,
        41,
        28,
        33,
        17,
        34,
        17,
        31,
        38,
        17,
        22,
        24,
        21,
        49,
        49,
        23,
        0,
        40,
        49,
        6,
        0,
        49,
        9,
        15,
        1,
        37,
        30,
        32,
        11,
        49,
        48,
        49,
        0,
        0,
        36,
        0,
        0,
        25,
        18,
        48,
        0,
        35,
        42,
        31,
        49,
        34,
        19,
        34,
        44,
        1
      ]
    },
    {
      "id": "D33377F5",
      "weeklyAccepted": [
        16,
        34,
        49,
        25,
        1,
        13,
        19,
        49,
        32,
        39,
        35,
        11,
        26,
        36,
        39,
        31,
        29,
        37,
        28,
        43,
        49,
        4,
        32,
        30,
        34,
        32,
        49,
        29,
        49,
        41,
        22,
        32,
        49,
        41,
        47,
        8,
        47,
        40,
        22,
        30,
        11,
        18,
        49,
        19,
        35,
        1,
        48,
        9,
        37,
        0,
        0,
        33
      ],
      "weeklyRejected": [
        36,
        26,
        44,
        36,
        0,
        35,
        47,
        32,
        0,
        25,
        24,
        37,
        13,
        24,
        17,
        44,
        0,
        46,
        49,
        30,
        29,
        38,
        15,
        31,
        37,
        8,
        20,
        24,
        41,
        25,
        45,
        31,
        46,
        21,
        43,
        11,
        49,
        0,
        7,
        25,
        40,
        41,
        23,
        14,
        48,
        12,
        0,
        44,
        19,
        9,
        32,
        41
      ],
      "weeklyHold": [
        49,
        30,
        49,
        12,
        49,
        26,
        2,
        14,
        41,
        27,
        8,
        49,
        25,
        9,
        5,
        13,
        0,
        47,
        32,
        21,
        0,
        10,
        15,
        42,
        49,
        36,
        18,
        0,
        34,
        46,
        42,
        48,
        9,
        33,
        48,
        30,
        32,
        0,
        29,
        0,
        4,
        28,
        26,
        41,
        35,
        15,
        0,
        37,
        0,
        12,
        24,
        13
      ],
      "weeklyBackordered": [
        0,
        31,
        18,
        23,
        49,
        0,
        37,
        49,
        0,
        46,
        13,
        20,
        24,
        23,
        13,
        17,
        16,
        44,
        20,
        0,
        14,
        24,
        10,
        25,
        15,
        23,
        20,
        44,
        49,
        0,
        32,
        49,
        24,
        43,
        2,
        0,
        7,
        9,
        0,
        43,
        16,
        28,
        19,
        49,
        15,
        49,
        36,
        0,
        0,
        32,
        33,
        6
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyAccepted": [
        9,
        23,
        17,
        11,
        49,
        0,
        32,
        29,
        37,
        0,
        0,
        0,
        49,
        39,
        28,
        13,
        8,
        0,
        49,
        0,
        49,
        34,
        36,
        32,
        11,
        19,
        18,
        31,
        15,
        14,
        0,
        21,
        42,
        21,
        48,
        37,
        42,
        16,
        26,
        33,
        37,
        24,
        40,
        20,
        42,
        40,
        7,
        4,
        44,
        37,
        22,
        5
      ],
      "weeklyRejected": [
        40,
        13,
        49,
        42,
        16,
        39,
        48,
        49,
        29,
        1,
        32,
        36,
        13,
        37,
        48,
        0,
        8,
        42,
        35,
        6,
        0,
        49,
        49,
        20,
        11,
        34,
        42,
        17,
        43,
        39,
        35,
        26,
        48,
        22,
        0,
        49,
        11,
        49,
        21,
        8,
        42,
        49,
        42,
        21,
        49,
        27,
        8,
        45,
        19,
        49,
        0,
        49
      ],
      "weeklyHold": [
        22,
        15,
        49,
        1,
        10,
        26,
        32,
        32,
        0,
        0,
        45,
        49,
        29,
        1,
        49,
        14,
        0,
        0,
        16,
        35,
        23,
        16,
        14,
        13,
        33,
        18,
        40,
        0,
        2,
        13,
        29,
        0,
        39,
        47,
        31,
        49,
        0,
        12,
        1,
        37,
        42,
        49,
        0,
        28,
        31,
        25,
        31,
        11,
        18,
        24,
        48,
        49
      ],
      "weeklyBackordered": [
        26,
        27,
        2,
        34,
        41,
        23,
        16,
        38,
        18,
        38,
        24,
        49,
        16,
        49,
        27,
        1,
        29,
        45,
        23,
        32,
        49,
        47,
        20,
        12,
        29,
        49,
        1,
        3,
        34,
        46,
        35,
        48,
        48,
        46,
        49,
        0,
        36,
        0,
        36,
        35,
        19,
        48,
        10,
        49,
        27,
        0,
        22,
        13,
        33,
        36,
        1,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyAccepted": [
        27,
        26,
        20,
        48,
        49,
        29,
        28,
        34,
        43,
        20,
        36,
        7,
        12,
        19,
        34,
        34,
        23,
        40,
        15,
        0,
        49,
        36,
        5,
        18,
        0,
        35,
        15,
        6,
        1,
        49,
        6,
        22,
        36,
        49,
        48,
        49,
        36,
        44,
        11,
        49,
        21,
        49,
        38,
        22,
        46,
        34,
        7,
        23,
        19,
        49,
        0,
        4
      ],
      "weeklyRejected": [
        18,
        18,
        49,
        47,
        10,
        24,
        8,
        33,
        35,
        5,
        40,
        0,
        7,
        34,
        31,
        14,
        44,
        28,
        38,
        32,
        14,
        15,
        5,
        22,
        12,
        27,
        49,
        12,
        39,
        48,
        0,
        28,
        18,
        26,
        11,
        30,
        15,
        47,
        6,
        19,
        48,
        46,
        27,
        10,
        43,
        12,
        31,
        6,
        0,
        32,
        0,
        22
      ],
      "weeklyHold": [
        33,
        0,
        3,
        20,
        2,
        24,
        7,
        28,
        0,
        40,
        47,
        26,
        39,
        29,
        49,
        19,
        16,
        26,
        17,
        24,
        9,
        13,
        9,
        17,
        23,
        47,
        11,
        13,
        18,
        2,
        45,
        19,
        11,
        31,
        18,
        0,
        36,
        23,
        21,
        31,
        49,
        29,
        17,
        29,
        49,
        45,
        49,
        49,
        12,
        12,
        49,
        0
      ],
      "weeklyBackordered": [
        44,
        44,
        23,
        0,
        26,
        31,
        17,
        26,
        49,
        27,
        38,
        43,
        35,
        27,
        45,
        34,
        6,
        49,
        0,
        9,
        49,
        32,
        20,
        49,
        48,
        4,
        29,
        49,
        43,
        49,
        49,
        27,
        38,
        37,
        0,
        0,
        26,
        15,
        12,
        14,
        20,
        22,
        2,
        39,
        33,
        10,
        39,
        11,
        0,
        26,
        22,
        34
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyAccepted": [
        3,
        1,
        35,
        0,
        20,
        42,
        19,
        17,
        38,
        0,
        47,
        0,
        12,
        27,
        1,
        23,
        18,
        0,
        2,
        39,
        0,
        21,
        0,
        7,
        22,
        17,
        8,
        30,
        8,
        49,
        20,
        0,
        33,
        28,
        30,
        22,
        6,
        11,
        49,
        22,
        49,
        29,
        16,
        12,
        37,
        24,
        25,
        32,
        25,
        30,
        26,
        17
      ],
      "weeklyRejected": [
        31,
        21,
        2,
        0,
        29,
        19,
        27,
        46,
        46,
        33,
        8,
        38,
        16,
        48,
        35,
        25,
        23,
        36,
        34,
        49,
        21,
        49,
        42,
        10,
        16,
        48,
        41,
        12,
        11,
        33,
        25,
        36,
        48,
        9,
        1,
        31,
        37,
        4,
        18,
        32,
        28,
        4,
        28,
        17,
        12,
        38,
        24,
        36,
        30,
        48,
        0,
        21
      ],
      "weeklyHold": [
        36,
        9,
        37,
        1,
        12,
        3,
        19,
        2,
        36,
        30,
        38,
        1,
        17,
        16,
        21,
        49,
        0,
        30,
        24,
        0,
        8,
        2,
        49,
        17,
        30,
        8,
        26,
        19,
        46,
        21,
        40,
        0,
        30,
        47,
        40,
        38,
        9,
        22,
        35,
        17,
        23,
        23,
        16,
        10,
        32,
        47,
        0,
        28,
        9,
        49,
        49,
        26
      ],
      "weeklyBackordered": [
        0,
        17,
        1,
        20,
        49,
        45,
        24,
        6,
        27,
        0,
        45,
        34,
        11,
        49,
        9,
        14,
        42,
        27,
        13,
        0,
        28,
        41,
        33,
        7,
        17,
        24,
        47,
        27,
        13,
        49,
        25,
        35,
        44,
        29,
        6,
        0,
        21,
        30,
        11,
        0,
        32,
        48,
        9,
        13,
        19,
        0,
        31,
        1,
        27,
        22,
        36,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyAccepted": [
        35,
        35,
        10,
        48,
        33,
        14,
        35,
        48,
        48,
        0,
        49,
        0,
        37,
        49,
        2,
        49,
        41,
        0,
        49,
        20,
        27,
        19,
        49,
        43,
        29,
        33,
        17,
        8,
        14,
        31,
        0,
        20,
        49,
        13,
        12,
        17,
        47,
        24,
        19,
        49,
        22,
        8,
        49,
        24,
        28,
        46,
        4,
        3,
        0,
        49,
        0,
        0
      ],
      "weeklyRejected": [
        26,
        1,
        24,
        4,
        49,
        0,
        25,
        8,
        0,
        1,
        35,
        37,
        24,
        25,
        11,
        26,
        40,
        38,
        26,
        20,
        39,
        49,
        49,
        48,
        8,
        45,
        48,
        26,
        40,
        4,
        15,
        38,
        31,
        23,
        18,
        49,
        30,
        0,
        21,
        6,
        27,
        19,
        36,
        21,
        29,
        36,
        0,
        6,
        0,
        6,
        3,
        28
      ],
      "weeklyHold": [
        9,
        26,
        29,
        23,
        49,
        22,
        20,
        33,
        0,
        14,
        27,
        33,
        1,
        7,
        24,
        22,
        0,
        10,
        30,
        0,
        46,
        16,
        29,
        33,
        14,
        20,
        37,
        31,
        17,
        0,
        48,
        6,
        0,
        29,
        26,
        14,
        36,
        8,
        31,
        47,
        43,
        28,
        12,
        25,
        44,
        15,
        3,
        26,
        4,
        2,
        49,
        43
      ],
      "weeklyBackordered": [
        47,
        27,
        0,
        46,
        44,
        46,
        49,
        45,
        23,
        17,
        30,
        43,
        20,
        49,
        38,
        18,
        11,
        0,
        11,
        28,
        37,
        49,
        27,
        22,
        3,
        29,
        48,
        23,
        19,
        29,
        49,
        28,
        38,
        49,
        4,
        0,
        49,
        6,
        5,
        6,
        10,
        48,
        5,
        35,
        29,
        28,
        23,
        28,
        15,
        49,
        40,
        22
      ]
    },
    {
      "id": "74B98557",
      "weeklyAccepted": [
        22,
        29,
        34,
        5,
        17,
        27,
        1,
        49,
        48,
        26,
        31,
        14,
        29,
        30,
        10,
        30,
        45,
        39,
        0,
        16,
        21,
        22,
        49,
        29,
        13,
        47,
        40,
        22,
        24,
        13,
        0,
        14,
        49,
        47,
        39,
        24,
        47,
        24,
        28,
        31,
        6,
        23,
        49,
        49,
        49,
        0,
        8,
        23,
        9,
        11,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        2,
        49,
        47,
        15,
        28,
        34,
        4,
        49,
        48,
        17,
        24,
        12,
        31,
        35,
        24,
        9,
        27,
        46,
        0,
        23,
        29,
        49,
        45,
        26,
        29,
        43,
        26,
        27,
        0,
        49,
        47,
        34,
        49,
        14,
        49,
        34,
        8,
        16,
        25,
        45,
        0,
        12,
        28,
        43,
        49,
        28,
        49,
        25,
        49,
        14,
        49
      ],
      "weeklyHold": [
        41,
        4,
        0,
        1,
        26,
        41,
        49,
        2,
        36,
        15,
        18,
        1,
        35,
        19,
        41,
        41,
        0,
        0,
        20,
        4,
        0,
        38,
        0,
        34,
        25,
        20,
        40,
        10,
        26,
        30,
        34,
        48,
        0,
        18,
        0,
        32,
        32,
        33,
        38,
        11,
        37,
        18,
        12,
        40,
        41,
        49,
        49,
        49,
        12,
        28,
        49,
        21
      ],
      "weeklyBackordered": [
        17,
        15,
        6,
        49,
        49,
        3,
        0,
        33,
        8,
        0,
        32,
        17,
        14,
        49,
        0,
        26,
        49,
        37,
        10,
        7,
        35,
        0,
        13,
        0,
        11,
        28,
        22,
        10,
        27,
        38,
        8,
        49,
        48,
        31,
        0,
        13,
        41,
        6,
        7,
        20,
        20,
        48,
        0,
        49,
        3,
        49,
        49,
        33,
        23,
        49,
        1,
        33
      ]
    },
    {
      "id": "E491B04D",
      "weeklyAccepted": [
        0,
        44,
        49,
        6,
        34,
        49,
        16,
        38,
        21,
        49,
        49,
        0,
        41,
        48,
        13,
        49,
        12,
        13,
        30,
        15,
        0,
        30,
        46,
        19,
        1,
        40,
        40,
        26,
        27,
        49,
        0,
        0,
        49,
        38,
        31,
        49,
        45,
        18,
        29,
        25,
        39,
        20,
        49,
        29,
        39,
        15,
        49,
        6,
        0,
        6,
        42,
        21
      ],
      "weeklyRejected": [
        49,
        24,
        2,
        24,
        31,
        49,
        49,
        11,
        2,
        44,
        23,
        10,
        49,
        8,
        46,
        37,
        4,
        49,
        36,
        35,
        19,
        43,
        49,
        49,
        49,
        49,
        38,
        25,
        24,
        23,
        41,
        46,
        0,
        30,
        17,
        33,
        32,
        11,
        3,
        49,
        38,
        33,
        7,
        3,
        37,
        26,
        4,
        49,
        29,
        26,
        23,
        49
      ],
      "weeklyHold": [
        36,
        0,
        49,
        1,
        47,
        7,
        30,
        26,
        34,
        28,
        10,
        35,
        27,
        27,
        27,
        12,
        2,
        36,
        8,
        4,
        49,
        26,
        13,
        19,
        41,
        31,
        41,
        31,
        26,
        0,
        43,
        48,
        6,
        49,
        28,
        49,
        27,
        26,
        28,
        13,
        38,
        49,
        0,
        20,
        30,
        18,
        43,
        6,
        0,
        40,
        49,
        1
      ],
      "weeklyBackordered": [
        8,
        46,
        18,
        23,
        49,
        41,
        45,
        35,
        12,
        19,
        49,
        21,
        3,
        49,
        42,
        15,
        13,
        39,
        49,
        32,
        15,
        21,
        34,
        11,
        22,
        26,
        43,
        48,
        6,
        46,
        31,
        49,
        17,
        24,
        0,
        0,
        32,
        0,
        44,
        43,
        11,
        48,
        0,
        21,
        8,
        32,
        34,
        12,
        0,
        46,
        49,
        46
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        0,
        20,
        49,
        28,
        34,
        0,
        49,
        22,
        0,
        0,
        36,
        5,
        48,
        11,
        14,
        46,
        12,
        0,
        17,
        19,
        27,
        28,
        27,
        40,
        4,
        32,
        23,
        35,
        32,
        27,
        49,
        2,
        38,
        0,
        15,
        24,
        23,
        29,
        21,
        43,
        0,
        24,
        49,
        49,
        46,
        9,
        0,
        28,
        36,
        37,
        28,
        41
      ],
      "weeklyRejected": [
        33,
        49,
        49,
        41,
        16,
        46,
        18,
        49,
        1,
        1,
        49,
        6,
        32,
        29,
        48,
        7,
        49,
        17,
        21,
        47,
        49,
        24,
        43,
        10,
        0,
        49,
        31,
        17,
        23,
        11,
        49,
        23,
        34,
        49,
        31,
        49,
        12,
        43,
        13,
        2,
        27,
        4,
        27,
        13,
        35,
        0,
        0,
        9,
        1,
        36,
        11,
        48
      ],
      "weeklyHold": [
        39,
        25,
        49,
        22,
        23,
        0,
        0,
        3,
        17,
        33,
        47,
        38,
        31,
        23,
        1,
        0,
        9,
        11,
        25,
        32,
        48,
        13,
        49,
        0,
        11,
        18,
        49,
        30,
        18,
        32,
        45,
        0,
        0,
        28,
        48,
        10,
        32,
        0,
        1,
        6,
        0,
        49,
        13,
        0,
        45,
        37,
        46,
        36,
        15,
        0,
        3,
        24
      ],
      "weeklyBackordered": [
        30,
        35,
        0,
        49,
        49,
        10,
        30,
        14,
        35,
        0,
        33,
        43,
        11,
        18,
        34,
        16,
        49,
        28,
        0,
        28,
        44,
        49,
        49,
        13,
        20,
        49,
        37,
        46,
        22,
        39,
        14,
        33,
        48,
        33,
        29,
        11,
        49,
        1,
        49,
        28,
        24,
        48,
        0,
        49,
        6,
        31,
        49,
        7,
        7,
        49,
        24,
        15
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyAccepted": [
        19,
        49,
        44,
        12,
        29,
        29,
        27,
        32,
        48,
        10,
        49,
        0,
        1,
        15,
        4,
        13,
        45,
        21,
        10,
        31,
        27,
        10,
        49,
        14,
        16,
        25,
        49,
        35,
        16,
        33,
        2,
        18,
        49,
        14,
        14,
        49,
        45,
        48,
        13,
        0,
        7,
        15,
        49,
        11,
        19,
        49,
        31,
        0,
        10,
        49,
        49,
        10
      ],
      "weeklyRejected": [
        21,
        10,
        32,
        0,
        49,
        30,
        49,
        44,
        45,
        21,
        33,
        34,
        40,
        0,
        29,
        38,
        37,
        36,
        43,
        31,
        0,
        49,
        49,
        19,
        35,
        49,
        8,
        19,
        19,
        0,
        49,
        37,
        48,
        49,
        23,
        49,
        19,
        42,
        25,
        12,
        37,
        22,
        40,
        24,
        41,
        20,
        10,
        37,
        17,
        46,
        17,
        12
      ],
      "weeklyHold": [
        0,
        8,
        42,
        1,
        46,
        25,
        49,
        17,
        29,
        34,
        23,
        1,
        42,
        42,
        48,
        1,
        21,
        0,
        11,
        49,
        25,
        23,
        6,
        24,
        31,
        8,
        36,
        7,
        26,
        23,
        25,
        27,
        0,
        24,
        23,
        40,
        10,
        0,
        29,
        49,
        21,
        20,
        35,
        49,
        29,
        20,
        27,
        48,
        0,
        23,
        49,
        0
      ],
      "weeklyBackordered": [
        49,
        28,
        33,
        26,
        49,
        46,
        49,
        34,
        49,
        0,
        29,
        2,
        3,
        43,
        37,
        49,
        40,
        35,
        0,
        0,
        14,
        49,
        28,
        24,
        23,
        18,
        48,
        22,
        42,
        38,
        13,
        40,
        48,
        49,
        0,
        0,
        1,
        0,
        10,
        35,
        21,
        48,
        16,
        49,
        0,
        29,
        49,
        6,
        2,
        0,
        22,
        49
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        0,
        13,
        49,
        0,
        24,
        26,
        38,
        42,
        15,
        10,
        49,
        0,
        39,
        32,
        1,
        42,
        45,
        0,
        16,
        22,
        15,
        0,
        28,
        0,
        34,
        47,
        49,
        45,
        1,
        39,
        16,
        0,
        15,
        8,
        37,
        17,
        44,
        28,
        31,
        35,
        0,
        18,
        9,
        18,
        49,
        31,
        12,
        5,
        26,
        28,
        43,
        0
      ],
      "weeklyRejected": [
        19,
        0,
        49,
        14,
        39,
        49,
        49,
        32,
        43,
        8,
        41,
        49,
        41,
        39,
        48,
        49,
        42,
        49,
        45,
        10,
        13,
        16,
        15,
        0,
        30,
        7,
        34,
        30,
        35,
        28,
        0,
        20,
        43,
        44,
        16,
        49,
        11,
        0,
        5,
        0,
        28,
        35,
        0,
        21,
        35,
        33,
        0,
        15,
        0,
        14,
        0,
        46
      ],
      "weeklyHold": [
        28,
        39,
        41,
        1,
        49,
        48,
        5,
        37,
        0,
        15,
        20,
        28,
        36,
        35,
        32,
        14,
        8,
        27,
        45,
        9,
        27,
        40,
        34,
        20,
        42,
        48,
        49,
        20,
        47,
        0,
        44,
        5,
        7,
        35,
        21,
        13,
        17,
        0,
        38,
        22,
        19,
        31,
        34,
        32,
        0,
        41,
        0,
        17,
        0,
        5,
        0,
        35
      ],
      "weeklyBackordered": [
        49,
        25,
        3,
        47,
        49,
        13,
        49,
        24,
        27,
        30,
        38,
        37,
        15,
        16,
        29,
        49,
        19,
        48,
        0,
        49,
        14,
        0,
        21,
        21,
        3,
        18,
        20,
        27,
        1,
        22,
        49,
        8,
        41,
        49,
        0,
        0,
        6,
        28,
        32,
        24,
        19,
        48,
        0,
        49,
        38,
        41,
        46,
        33,
        0,
        49,
        49,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyAccepted": [
        25,
        44,
        30,
        11,
        11,
        26,
        30,
        44,
        34,
        4,
        41,
        11,
        25,
        16,
        25,
        16,
        41,
        0,
        0,
        35,
        30,
        25,
        49,
        26,
        0,
        47,
        0,
        9,
        17,
        30,
        0,
        30,
        44,
        9,
        29,
        35,
        45,
        46,
        11,
        35,
        12,
        34,
        41,
        21,
        49,
        35,
        0,
        17,
        0,
        6,
        49,
        28
      ],
      "weeklyRejected": [
        49,
        16,
        38,
        21,
        47,
        0,
        25,
        0,
        49,
        1,
        13,
        44,
        20,
        3,
        19,
        7,
        1,
        49,
        10,
        19,
        0,
        41,
        49,
        37,
        16,
        31,
        30,
        2,
        42,
        37,
        0,
        27,
        13,
        24,
        34,
        18,
        45,
        24,
        49,
        49,
        48,
        36,
        29,
        19,
        37,
        8,
        0,
        30,
        0,
        49,
        0,
        40
      ],
      "weeklyHold": [
        6,
        19,
        42,
        32,
        8,
        43,
        10,
        7,
        0,
        29,
        37,
        32,
        38,
        21,
        49,
        21,
        0,
        24,
        0,
        36,
        0,
        43,
        9,
        35,
        1,
        21,
        17,
        13,
        42,
        25,
        48,
        18,
        34,
        23,
        16,
        49,
        24,
        9,
        49,
        35,
        16,
        49,
        11,
        23,
        29,
        41,
        0,
        45,
        0,
        40,
        6,
        49
      ],
      "weeklyBackordered": [
        49,
        38,
        0,
        27,
        36,
        46,
        49,
        47,
        3,
        31,
        39,
        26,
        3,
        49,
        18,
        49,
        4,
        37,
        18,
        38,
        26,
        0,
        42,
        49,
        24,
        1,
        49,
        34,
        45,
        32,
        42,
        46,
        36,
        14,
        0,
        0,
        19,
        21,
        43,
        25,
        0,
        48,
        16,
        44,
        19,
        49,
        43,
        33,
        33,
        44,
        34,
        11
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        6,
        16,
        0,
        2,
        42,
        11,
        37,
        49,
        43,
        14,
        30,
        13,
        6,
        0,
        7,
        27,
        34,
        44,
        14,
        33,
        22,
        29,
        12,
        49,
        14,
        24,
        32,
        49,
        15,
        25,
        26,
        22,
        27,
        8,
        16,
        45,
        4,
        48,
        3,
        26,
        22,
        32,
        49,
        35,
        49,
        27,
        17,
        9,
        28,
        35,
        18,
        48
      ],
      "weeklyRejected": [
        25,
        41,
        47,
        30,
        49,
        7,
        49,
        13,
        27,
        1,
        43,
        49,
        5,
        3,
        47,
        4,
        17,
        21,
        26,
        11,
        24,
        3,
        1,
        37,
        27,
        49,
        45,
        2,
        41,
        25,
        49,
        47,
        48,
        21,
        0,
        47,
        49,
        0,
        11,
        18,
        48,
        19,
        40,
        9,
        35,
        0,
        10,
        0,
        7,
        45,
        11,
        49
      ],
      "weeklyHold": [
        0,
        38,
        33,
        10,
        32,
        34,
        29,
        27,
        26,
        1,
        39,
        8,
        1,
        37,
        31,
        20,
        28,
        46,
        17,
        0,
        11,
        44,
        3,
        49,
        41,
        1,
        32,
        31,
        26,
        11,
        48,
        48,
        0,
        11,
        40,
        38,
        24,
        36,
        47,
        28,
        39,
        38,
        49,
        8,
        39,
        49,
        49,
        32,
        1,
        0,
        46,
        16
      ],
      "weeklyBackordered": [
        19,
        24,
        0,
        26,
        46,
        35,
        31,
        11,
        17,
        0,
        37,
        14,
        3,
        45,
        33,
        30,
        42,
        48,
        8,
        29,
        43,
        46,
        49,
        28,
        18,
        1,
        49,
        4,
        4,
        49,
        11,
        43,
        0,
        21,
        20,
        6,
        11,
        11,
        49,
        0,
        12,
        47,
        0,
        49,
        34,
        49,
        42,
        40,
        7,
        11,
        32,
        49
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyAccepted": [
        20,
        40,
        49,
        35,
        37,
        20,
        18,
        49,
        37,
        16,
        49,
        6,
        37,
        6,
        22,
        33,
        43,
        21,
        23,
        13,
        26,
        21,
        35,
        47,
        26,
        38,
        10,
        49,
        11,
        17,
        33,
        4,
        47,
        34,
        48,
        46,
        26,
        23,
        26,
        27,
        14,
        0,
        24,
        49,
        25,
        5,
        13,
        0,
        34,
        15,
        0,
        0
      ],
      "weeklyRejected": [
        49,
        21,
        6,
        4,
        19,
        32,
        48,
        35,
        28,
        48,
        0,
        31,
        8,
        48,
        10,
        18,
        9,
        49,
        0,
        0,
        25,
        17,
        15,
        5,
        10,
        49,
        49,
        46,
        3,
        38,
        19,
        47,
        10,
        0,
        32,
        36,
        49,
        45,
        1,
        0,
        39,
        40,
        23,
        28,
        30,
        0,
        0,
        25,
        9,
        49,
        26,
        49
      ],
      "weeklyHold": [
        10,
        9,
        44,
        25,
        20,
        31,
        35,
        30,
        0,
        49,
        41,
        9,
        10,
        10,
        22,
        0,
        25,
        48,
        22,
        0,
        18,
        49,
        39,
        2,
        40,
        35,
        30,
        44,
        35,
        3,
        48,
        0,
        45,
        45,
        43,
        49,
        42,
        32,
        29,
        46,
        35,
        49,
        0,
        38,
        34,
        22,
        0,
        29,
        0,
        23,
        49,
        24
      ],
      "weeklyBackordered": [
        7,
        40,
        0,
        39,
        45,
        46,
        19,
        20,
        0,
        24,
        32,
        40,
        3,
        49,
        39,
        2,
        16,
        14,
        49,
        0,
        32,
        49,
        19,
        6,
        41,
        34,
        29,
        18,
        1,
        49,
        45,
        19,
        2,
        49,
        0,
        0,
        25,
        31,
        1,
        44,
        48,
        48,
        32,
        26,
        15,
        0,
        24,
        17,
        24,
        35,
        28,
        43
      ]
    },
    {
      "id": "049BCC88",
      "weeklyAccepted": [
        0,
        24,
        31,
        27,
        49,
        24,
        8,
        32,
        6,
        49,
        37,
        7,
        35,
        5,
        38,
        13,
        44,
        0,
        32,
        45,
        34,
        9,
        49,
        15,
        22,
        21,
        8,
        14,
        3,
        12,
        7,
        34,
        49,
        49,
        14,
        34,
        20,
        2,
        18,
        30,
        10,
        39,
        49,
        26,
        0,
        17,
        5,
        10,
        6,
        24,
        0,
        48
      ],
      "weeklyRejected": [
        49,
        45,
        25,
        47,
        10,
        0,
        22,
        20,
        30,
        20,
        14,
        38,
        0,
        14,
        23,
        4,
        25,
        37,
        49,
        0,
        47,
        28,
        30,
        49,
        40,
        36,
        49,
        2,
        43,
        0,
        48,
        33,
        43,
        15,
        49,
        49,
        28,
        3,
        1,
        14,
        14,
        5,
        0,
        34,
        49,
        19,
        0,
        1,
        6,
        49,
        0,
        32
      ],
      "weeklyHold": [
        36,
        20,
        15,
        30,
        38,
        25,
        33,
        25,
        20,
        34,
        27,
        32,
        43,
        20,
        49,
        0,
        9,
        24,
        0,
        44,
        18,
        41,
        19,
        26,
        0,
        18,
        28,
        32,
        49,
        0,
        33,
        20,
        27,
        38,
        10,
        46,
        32,
        25,
        46,
        0,
        19,
        20,
        10,
        49,
        37,
        17,
        39,
        42,
        4,
        29,
        49,
        14
      ],
      "weeklyBackordered": [
        39,
        0,
        19,
        13,
        49,
        11,
        33,
        40,
        37,
        34,
        30,
        0,
        49,
        40,
        26,
        49,
        18,
        49,
        29,
        0,
        49,
        0,
        0,
        34,
        28,
        13,
        33,
        9,
        48,
        38,
        47,
        49,
        39,
        49,
        0,
        0,
        17,
        0,
        28,
        24,
        0,
        27,
        0,
        32,
        42,
        45,
        40,
        0,
        12,
        22,
        49,
        7
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        10,
        49,
        15,
        28,
        1,
        15,
        1,
        31,
        48,
        22,
        32,
        0,
        36,
        34,
        32,
        33,
        30,
        5,
        47,
        0,
        23,
        38,
        49,
        30,
        36,
        47,
        0,
        27,
        6,
        49,
        5,
        0,
        49,
        1,
        39,
        18,
        18,
        2,
        49,
        49,
        0,
        27,
        49,
        35,
        46,
        0,
        17,
        21,
        8,
        49,
        5,
        48
      ],
      "weeklyRejected": [
        42,
        17,
        45,
        47,
        42,
        7,
        44,
        49,
        49,
        40,
        21,
        35,
        34,
        27,
        39,
        15,
        43,
        49,
        11,
        17,
        32,
        49,
        18,
        23,
        12,
        49,
        6,
        18,
        48,
        45,
        0,
        19,
        19,
        22,
        31,
        49,
        49,
        24,
        1,
        20,
        37,
        26,
        44,
        0,
        48,
        7,
        30,
        16,
        0,
        27,
        0,
        23
      ],
      "weeklyHold": [
        0,
        28,
        39,
        35,
        48,
        13,
        34,
        13,
        16,
        31,
        47,
        28,
        34,
        49,
        0,
        0,
        7,
        48,
        0,
        0,
        16,
        18,
        44,
        27,
        18,
        1,
        35,
        18,
        32,
        19,
        42,
        12,
        0,
        33,
        28,
        39,
        13,
        6,
        25,
        19,
        29,
        6,
        16,
        24,
        6,
        0,
        0,
        0,
        0,
        49,
        26,
        49
      ],
      "weeklyBackordered": [
        49,
        49,
        15,
        40,
        49,
        40,
        49,
        34,
        23,
        26,
        49,
        4,
        6,
        46,
        37,
        29,
        46,
        49,
        18,
        37,
        31,
        18,
        3,
        49,
        12,
        49,
        25,
        0,
        1,
        47,
        0,
        49,
        25,
        0,
        5,
        7,
        19,
        47,
        9,
        49,
        9,
        37,
        13,
        49,
        0,
        21,
        15,
        26,
        27,
        46,
        32,
        19
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyAccepted": [
        0,
        32,
        6,
        48,
        49,
        13,
        40,
        35,
        42,
        18,
        15,
        0,
        42,
        12,
        27,
        49,
        49,
        45,
        6,
        49,
        6,
        27,
        20,
        30,
        40,
        32,
        19,
        16,
        1,
        17,
        10,
        22,
        9,
        4,
        48,
        25,
        30,
        2,
        16,
        40,
        33,
        49,
        32,
        49,
        7,
        0,
        24,
        49,
        49,
        47,
        49,
        17
      ],
      "weeklyRejected": [
        49,
        12,
        39,
        44,
        20,
        41,
        40,
        35,
        22,
        33,
        37,
        7,
        36,
        5,
        48,
        21,
        4,
        49,
        23,
        36,
        0,
        20,
        16,
        49,
        2,
        49,
        49,
        19,
        33,
        30,
        28,
        10,
        46,
        5,
        33,
        49,
        16,
        13,
        20,
        0,
        44,
        18,
        35,
        26,
        41,
        0,
        17,
        24,
        5,
        27,
        0,
        49
      ],
      "weeklyHold": [
        16,
        17,
        28,
        23,
        39,
        45,
        0,
        7,
        0,
        7,
        35,
        49,
        3,
        40,
        21,
        35,
        2,
        24,
        49,
        13,
        32,
        45,
        21,
        17,
        14,
        21,
        42,
        49,
        21,
        19,
        48,
        12,
        0,
        49,
        22,
        13,
        24,
        21,
        28,
        30,
        44,
        49,
        0,
        11,
        49,
        32,
        48,
        21,
        41,
        49,
        40,
        33
      ],
      "weeklyBackordered": [
        36,
        5,
        0,
        49,
        49,
        26,
        21,
        24,
        0,
        11,
        19,
        20,
        3,
        34,
        21,
        1,
        49,
        17,
        21,
        27,
        49,
        40,
        40,
        23,
        16,
        49,
        48,
        49,
        8,
        46,
        1,
        25,
        28,
        16,
        4,
        0,
        31,
        0,
        30,
        0,
        18,
        48,
        32,
        49,
        23,
        0,
        24,
        48,
        41,
        49,
        48,
        16
      ]
    },
    {
      "id": "958C4CED",
      "weeklyAccepted": [
        30,
        46,
        49,
        36,
        38,
        3,
        35,
        22,
        48,
        27,
        15,
        38,
        18,
        32,
        41,
        1,
        19,
        1,
        11,
        30,
        4,
        10,
        41,
        0,
        49,
        47,
        49,
        49,
        29,
        26,
        35,
        2,
        49,
        44,
        26,
        3,
        32,
        48,
        26,
        49,
        41,
        34,
        49,
        46,
        13,
        15,
        20,
        34,
        18,
        38,
        43,
        19
      ],
      "weeklyRejected": [
        44,
        15,
        49,
        16,
        24,
        25,
        49,
        21,
        22,
        1,
        37,
        25,
        38,
        48,
        48,
        12,
        14,
        49,
        49,
        0,
        49,
        19,
        7,
        49,
        31,
        41,
        49,
        10,
        45,
        7,
        48,
        47,
        37,
        49,
        41,
        49,
        28,
        36,
        15,
        3,
        41,
        49,
        6,
        18,
        36,
        10,
        14,
        49,
        10,
        20,
        0,
        46
      ],
      "weeklyHold": [
        25,
        23,
        49,
        7,
        39,
        39,
        9,
        32,
        18,
        0,
        19,
        31,
        48,
        27,
        0,
        24,
        0,
        15,
        31,
        7,
        0,
        17,
        41,
        20,
        33,
        27,
        0,
        40,
        43,
        30,
        44,
        48,
        0,
        41,
        39,
        49,
        2,
        21,
        14,
        26,
        23,
        18,
        45,
        34,
        31,
        49,
        38,
        49,
        12,
        14,
        17,
        47
      ],
      "weeklyBackordered": [
        17,
        26,
        2,
        22,
        33,
        28,
        28,
        44,
        0,
        0,
        27,
        47,
        43,
        49,
        42,
        1,
        18,
        49,
        0,
        49,
        47,
        0,
        19,
        12,
        1,
        16,
        36,
        49,
        16,
        35,
        44,
        49,
        8,
        24,
        5,
        31,
        44,
        18,
        9,
        28,
        19,
        48,
        3,
        49,
        0,
        3,
        2,
        27,
        40,
        12,
        15,
        49
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyAccepted": [
        10,
        29,
        28,
        27,
        47,
        13,
        1,
        30,
        37,
        17,
        49,
        24,
        46,
        39,
        28,
        17,
        37,
        37,
        0,
        49,
        29,
        45,
        13,
        36,
        1,
        39,
        20,
        0,
        26,
        28,
        4,
        8,
        49,
        8,
        31,
        20,
        15,
        12,
        49,
        49,
        25,
        49,
        49,
        36,
        49,
        25,
        0,
        32,
        27,
        33,
        24,
        0
      ],
      "weeklyRejected": [
        37,
        28,
        24,
        33,
        40,
        8,
        49,
        49,
        26,
        48,
        49,
        16,
        17,
        10,
        48,
        21,
        0,
        30,
        37,
        3,
        5,
        31,
        0,
        29,
        22,
        49,
        34,
        12,
        0,
        9,
        49,
        47,
        46,
        49,
        27,
        44,
        9,
        22,
        1,
        5,
        34,
        11,
        40,
        15,
        49,
        16,
        0,
        40,
        0,
        47,
        0,
        0
      ],
      "weeklyHold": [
        13,
        0,
        36,
        20,
        46,
        28,
        30,
        2,
        0,
        27,
        47,
        49,
        28,
        38,
        0,
        33,
        49,
        8,
        10,
        0,
        46,
        15,
        0,
        0,
        49,
        10,
        25,
        23,
        49,
        19,
        48,
        31,
        0,
        0,
        46,
        20,
        39,
        2,
        21,
        25,
        3,
        49,
        24,
        28,
        19,
        49,
        22,
        16,
        20,
        11,
        3,
        49
      ],
      "weeklyBackordered": [
        0,
        18,
        0,
        15,
        49,
        34,
        47,
        44,
        36,
        29,
        9,
        41,
        3,
        27,
        23,
        34,
        49,
        29,
        10,
        23,
        24,
        29,
        38,
        21,
        41,
        19,
        49,
        2,
        20,
        29,
        3,
        49,
        0,
        31,
        4,
        29,
        37,
        13,
        28,
        36,
        0,
        48,
        12,
        47,
        24,
        12,
        29,
        19,
        1,
        14,
        49,
        1
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyAccepted": [
        40,
        41,
        23,
        4,
        15,
        30,
        1,
        32,
        48,
        0,
        35,
        3,
        27,
        11,
        38,
        0,
        49,
        0,
        23,
        22,
        22,
        15,
        49,
        49,
        41,
        20,
        34,
        49,
        12,
        44,
        49,
        23,
        49,
        35,
        32,
        10,
        26,
        48,
        49,
        49,
        44,
        5,
        22,
        18,
        39,
        41,
        10,
        0,
        17,
        25,
        21,
        48
      ],
      "weeklyRejected": [
        26,
        49,
        17,
        2,
        44,
        38,
        24,
        49,
        49,
        46,
        23,
        21,
        41,
        38,
        40,
        13,
        43,
        49,
        49,
        35,
        11,
        3,
        35,
        24,
        30,
        49,
        18,
        0,
        27,
        48,
        34,
        47,
        48,
        33,
        19,
        49,
        1,
        0,
        49,
        19,
        25,
        28,
        18,
        28,
        49,
        22,
        10,
        33,
        48,
        27,
        29,
        49
      ],
      "weeklyHold": [
        29,
        24,
        24,
        17,
        36,
        0,
        21,
        49,
        0,
        21,
        16,
        34,
        15,
        25,
        29,
        22,
        9,
        10,
        22,
        36,
        0,
        5,
        29,
        32,
        34,
        14,
        41,
        17,
        35,
        38,
        28,
        34,
        35,
        40,
        34,
        40,
        23,
        0,
        21,
        0,
        40,
        8,
        9,
        27,
        24,
        41,
        9,
        34,
        3,
        48,
        19,
        24
      ],
      "weeklyBackordered": [
        0,
        1,
        0,
        27,
        36,
        36,
        0,
        46,
        27,
        2,
        23,
        30,
        28,
        11,
        49,
        49,
        49,
        49,
        0,
        21,
        5,
        2,
        0,
        17,
        21,
        26,
        12,
        19,
        12,
        35,
        5,
        3,
        43,
        29,
        16,
        35,
        21,
        0,
        0,
        0,
        22,
        48,
        4,
        49,
        49,
        16,
        37,
        24,
        34,
        25,
        34,
        24
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyAccepted": [
        36,
        32,
        13,
        11,
        16,
        0,
        36,
        49,
        33,
        49,
        24,
        30,
        16,
        0,
        31,
        5,
        43,
        17,
        8,
        49,
        0,
        30,
        35,
        20,
        3,
        7,
        36,
        49,
        1,
        28,
        44,
        35,
        17,
        24,
        48,
        17,
        18,
        48,
        49,
        49,
        8,
        45,
        37,
        49,
        32,
        46,
        0,
        49,
        0,
        32,
        25,
        29
      ],
      "weeklyRejected": [
        43,
        32,
        37,
        23,
        8,
        27,
        49,
        23,
        20,
        1,
        41,
        40,
        0,
        45,
        36,
        14,
        0,
        49,
        14,
        5,
        19,
        14,
        5,
        33,
        9,
        13,
        14,
        36,
        22,
        15,
        49,
        47,
        48,
        34,
        25,
        49,
        17,
        26,
        30,
        21,
        48,
        40,
        49,
        49,
        41,
        39,
        0,
        20,
        39,
        49,
        0,
        17
      ],
      "weeklyHold": [
        20,
        49,
        38,
        16,
        10,
        34,
        49,
        2,
        12,
        37,
        26,
        1,
        48,
        1,
        31,
        49,
        0,
        7,
        0,
        30,
        3,
        49,
        11,
        0,
        6,
        15,
        49,
        17,
        49,
        29,
        48,
        25,
        0,
        24,
        12,
        28,
        2,
        8,
        45,
        49,
        36,
        20,
        22,
        36,
        20,
        1,
        49,
        36,
        13,
        15,
        49,
        49
      ],
      "weeklyBackordered": [
        46,
        9,
        0,
        44,
        16,
        33,
        39,
        27,
        46,
        21,
        49,
        6,
        3,
        15,
        23,
        1,
        49,
        49,
        0,
        13,
        29,
        21,
        18,
        43,
        1,
        25,
        17,
        27,
        9,
        49,
        26,
        49,
        31,
        49,
        13,
        27,
        1,
        11,
        7,
        7,
        11,
        42,
        6,
        49,
        13,
        14,
        49,
        29,
        7,
        29,
        1,
        11
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyAccepted": [
        39,
        1,
        40,
        26,
        29,
        8,
        20,
        2,
        36,
        2,
        20,
        0,
        10,
        32,
        14,
        22,
        44,
        16,
        19,
        5,
        16,
        32,
        26,
        49,
        31,
        38,
        5,
        0,
        25,
        49,
        0,
        18,
        45,
        11,
        6,
        25,
        39,
        29,
        23,
        28,
        20,
        43,
        49,
        14,
        30,
        17,
        25,
        0,
        0,
        29,
        41,
        45
      ],
      "weeklyRejected": [
        49,
        43,
        49,
        0,
        36,
        33,
        21,
        7,
        49,
        48,
        49,
        12,
        33,
        11,
        48,
        21,
        0,
        24,
        35,
        49,
        36,
        27,
        29,
        42,
        39,
        48,
        2,
        8,
        20,
        48,
        0,
        29,
        9,
        49,
        38,
        49,
        17,
        0,
        33,
        25,
        34,
        49,
        49,
        3,
        49,
        17,
        0,
        0,
        0,
        42,
        14,
        8
      ],
      "weeklyHold": [
        3,
        6,
        24,
        1,
        39,
        22,
        17,
        7,
        32,
        26,
        29,
        28,
        17,
        44,
        16,
        1,
        49,
        33,
        4,
        9,
        19,
        29,
        24,
        30,
        22,
        48,
        0,
        21,
        36,
        3,
        48,
        17,
        15,
        46,
        25,
        9,
        43,
        7,
        20,
        23,
        40,
        46,
        16,
        20,
        35,
        49,
        12,
        37,
        16,
        23,
        22,
        26
      ],
      "weeklyBackordered": [
        36,
        39,
        19,
        3,
        34,
        41,
        0,
        11,
        49,
        24,
        6,
        28,
        37,
        0,
        1,
        36,
        49,
        44,
        0,
        49,
        42,
        31,
        14,
        16,
        1,
        49,
        23,
        0,
        4,
        44,
        4,
        40,
        0,
        49,
        6,
        0,
        45,
        19,
        13,
        39,
        12,
        45,
        13,
        13,
        37,
        39,
        43,
        22,
        0,
        23,
        5,
        1
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        1,
        39,
        49,
        48,
        17,
        0,
        30,
        49,
        17,
        0,
        47,
        0,
        45,
        49,
        33,
        35,
        30,
        0,
        49,
        46,
        7,
        22,
        49,
        0,
        31,
        42,
        21,
        0,
        26,
        40,
        28,
        10,
        49,
        25,
        13,
        2,
        35,
        26,
        35,
        49,
        0,
        31,
        49,
        32,
        44,
        0,
        36,
        49,
        11,
        19,
        2,
        48
      ],
      "weeklyRejected": [
        32,
        7,
        32,
        32,
        22,
        26,
        49,
        5,
        23,
        33,
        43,
        7,
        2,
        39,
        35,
        13,
        31,
        27,
        32,
        29,
        0,
        38,
        49,
        33,
        49,
        24,
        14,
        32,
        48,
        0,
        32,
        10,
        11,
        31,
        5,
        49,
        30,
        49,
        1,
        0,
        19,
        49,
        49,
        0,
        38,
        12,
        18,
        7,
        0,
        8,
        0,
        40
      ],
      "weeklyHold": [
        21,
        0,
        31,
        17,
        49,
        1,
        26,
        21,
        0,
        0,
        46,
        14,
        1,
        42,
        35,
        0,
        3,
        18,
        19,
        0,
        31,
        21,
        24,
        18,
        45,
        48,
        33,
        13,
        17,
        0,
        48,
        0,
        3,
        35,
        2,
        34,
        27,
        49,
        23,
        2,
        15,
        49,
        27,
        11,
        0,
        18,
        35,
        49,
        0,
        24,
        27,
        49
      ],
      "weeklyBackordered": [
        8,
        13,
        0,
        17,
        44,
        21,
        49,
        32,
        49,
        14,
        42,
        49,
        21,
        29,
        21,
        20,
        49,
        49,
        0,
        28,
        34,
        44,
        9,
        5,
        35,
        18,
        1,
        7,
        27,
        28,
        40,
        49,
        37,
        26,
        33,
        0,
        1,
        0,
        49,
        30,
        48,
        48,
        0,
        49,
        9,
        37,
        45,
        37,
        0,
        49,
        26,
        49
      ]
    },
    {
      "id": "065DB72B",
      "weeklyAccepted": [
        5,
        34,
        21,
        43,
        1,
        0,
        3,
        18,
        19,
        31,
        43,
        3,
        40,
        0,
        12,
        36,
        0,
        0,
        0,
        34,
        21,
        49,
        49,
        36,
        32,
        47,
        49,
        49,
        19,
        17,
        8,
        20,
        49,
        7,
        28,
        33,
        23,
        17,
        19,
        49,
        0,
        22,
        41,
        25,
        30,
        13,
        6,
        16,
        26,
        20,
        0,
        31
      ],
      "weeklyRejected": [
        13,
        49,
        49,
        47,
        22,
        14,
        49,
        28,
        15,
        32,
        30,
        0,
        48,
        48,
        48,
        7,
        24,
        5,
        46,
        36,
        25,
        11,
        0,
        48,
        16,
        49,
        45,
        49,
        48,
        43,
        29,
        17,
        39,
        46,
        11,
        49,
        41,
        20,
        1,
        41,
        44,
        41,
        32,
        35,
        49,
        13,
        0,
        25,
        3,
        49,
        0,
        37
      ],
      "weeklyHold": [
        42,
        24,
        35,
        17,
        49,
        34,
        0,
        40,
        0,
        0,
        25,
        49,
        48,
        42,
        14,
        0,
        0,
        30,
        15,
        22,
        11,
        19,
        6,
        15,
        10,
        38,
        3,
        5,
        40,
        0,
        48,
        14,
        22,
        49,
        39,
        0,
        22,
        5,
        37,
        8,
        21,
        23,
        26,
        39,
        19,
        30,
        48,
        41,
        8,
        37,
        24,
        17
      ],
      "weeklyBackordered": [
        28,
        33,
        0,
        9,
        46,
        19,
        15,
        46,
        2,
        23,
        49,
        44,
        3,
        43,
        45,
        1,
        11,
        49,
        0,
        0,
        43,
        16,
        0,
        49,
        23,
        1,
        1,
        49,
        18,
        0,
        28,
        23,
        48,
        48,
        1,
        0,
        44,
        1,
        45,
        30,
        44,
        48,
        22,
        49,
        13,
        8,
        35,
        25,
        25,
        49,
        29,
        38
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyAccepted": [
        0,
        2,
        14,
        30,
        35,
        31,
        26,
        25,
        31,
        0,
        41,
        49,
        46,
        0,
        14,
        31,
        16,
        9,
        41,
        20,
        49,
        3,
        15,
        30,
        13,
        20,
        6,
        31,
        49,
        13,
        10,
        0,
        6,
        12,
        13,
        26,
        12,
        48,
        31,
        38,
        14,
        6,
        49,
        32,
        49,
        7,
        13,
        49,
        25,
        48,
        24,
        22
      ],
      "weeklyRejected": [
        29,
        7,
        36,
        47,
        30,
        20,
        7,
        48,
        35,
        12,
        19,
        37,
        8,
        7,
        14,
        0,
        7,
        16,
        28,
        25,
        14,
        37,
        23,
        30,
        16,
        49,
        45,
        0,
        35,
        1,
        45,
        23,
        21,
        24,
        32,
        32,
        38,
        43,
        20,
        32,
        32,
        45,
        40,
        30,
        48,
        0,
        0,
        11,
        24,
        49,
        0,
        32
      ],
      "weeklyHold": [
        35,
        38,
        38,
        25,
        39,
        39,
        31,
        26,
        43,
        0,
        35,
        3,
        3,
        9,
        31,
        16,
        16,
        37,
        18,
        20,
        9,
        3,
        10,
        5,
        0,
        38,
        35,
        49,
        16,
        0,
        48,
        26,
        0,
        43,
        17,
        13,
        42,
        28,
        29,
        6,
        0,
        37,
        6,
        35,
        26,
        41,
        35,
        43,
        0,
        45,
        44,
        49
      ],
      "weeklyBackordered": [
        40,
        4,
        0,
        29,
        13,
        40,
        21,
        1,
        39,
        49,
        10,
        38,
        3,
        49,
        3,
        29,
        49,
        0,
        0,
        39,
        47,
        0,
        49,
        3,
        1,
        1,
        33,
        25,
        28,
        49,
        25,
        17,
        48,
        48,
        7,
        0,
        26,
        0,
        35,
        14,
        0,
        23,
        35,
        49,
        17,
        23,
        49,
        31,
        0,
        49,
        1,
        36
      ]
    },
    {
      "id": "44804BD4",
      "weeklyAccepted": [
        0,
        40,
        23,
        43,
        27,
        4,
        13,
        49,
        32,
        8,
        38,
        0,
        10,
        7,
        22,
        28,
        33,
        11,
        3,
        49,
        32,
        17,
        35,
        2,
        36,
        33,
        28,
        49,
        1,
        18,
        20,
        34,
        49,
        47,
        48,
        2,
        7,
        25,
        19,
        49,
        16,
        41,
        49,
        37,
        49,
        0,
        0,
        5,
        49,
        34,
        1,
        18
      ],
      "weeklyRejected": [
        27,
        0,
        43,
        47,
        9,
        12,
        20,
        49,
        26,
        44,
        49,
        37,
        2,
        47,
        21,
        24,
        43,
        48,
        40,
        0,
        36,
        32,
        14,
        22,
        14,
        26,
        49,
        47,
        31,
        40,
        49,
        32,
        33,
        49,
        0,
        49,
        14,
        8,
        16,
        2,
        36,
        5,
        23,
        29,
        47,
        49,
        16,
        49,
        3,
        15,
        0,
        49
      ],
      "weeklyHold": [
        15,
        0,
        27,
        34,
        14,
        22,
        39,
        41,
        3,
        49,
        34,
        1,
        21,
        49,
        0,
        0,
        0,
        0,
        15,
        23,
        49,
        49,
        26,
        38,
        21,
        21,
        33,
        18,
        49,
        11,
        39,
        22,
        0,
        25,
        48,
        40,
        18,
        16,
        26,
        22,
        23,
        31,
        1,
        49,
        49,
        24,
        33,
        49,
        0,
        32,
        30,
        49
      ],
      "weeklyBackordered": [
        41,
        39,
        22,
        26,
        38,
        13,
        15,
        45,
        30,
        32,
        22,
        47,
        3,
        49,
        40,
        15,
        27,
        32,
        20,
        49,
        44,
        41,
        19,
        29,
        11,
        42,
        1,
        48,
        4,
        40,
        34,
        32,
        42,
        35,
        31,
        0,
        11,
        0,
        33,
        49,
        20,
        8,
        3,
        11,
        34,
        31,
        33,
        16,
        21,
        33,
        33,
        15
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyAccepted": [
        12,
        37,
        46,
        21,
        1,
        0,
        7,
        2,
        48,
        43,
        36,
        0,
        12,
        13,
        10,
        22,
        49,
        41,
        9,
        35,
        4,
        37,
        35,
        11,
        21,
        2,
        21,
        15,
        30,
        34,
        36,
        46,
        46,
        13,
        31,
        29,
        20,
        4,
        26,
        49,
        11,
        16,
        49,
        49,
        48,
        0,
        19,
        48,
        0,
        0,
        1,
        29
      ],
      "weeklyRejected": [
        37,
        37,
        33,
        26,
        5,
        5,
        49,
        17,
        47,
        1,
        0,
        2,
        16,
        48,
        40,
        36,
        20,
        49,
        37,
        35,
        49,
        30,
        20,
        49,
        27,
        0,
        39,
        33,
        39,
        26,
        27,
        21,
        18,
        0,
        10,
        29,
        46,
        8,
        1,
        29,
        28,
        45,
        48,
        39,
        49,
        49,
        9,
        10,
        0,
        26,
        23,
        26
      ],
      "weeklyHold": [
        29,
        0,
        36,
        32,
        49,
        29,
        40,
        28,
        26,
        31,
        18,
        13,
        29,
        25,
        37,
        0,
        0,
        27,
        5,
        0,
        5,
        36,
        6,
        25,
        29,
        46,
        46,
        20,
        49,
        3,
        40,
        0,
        19,
        0,
        0,
        44,
        49,
        0,
        31,
        31,
        12,
        16,
        0,
        14,
        20,
        37,
        13,
        49,
        0,
        31,
        47,
        33
      ],
      "weeklyBackordered": [
        24,
        0,
        14,
        20,
        25,
        9,
        49,
        49,
        41,
        0,
        40,
        32,
        38,
        48,
        48,
        13,
        8,
        48,
        16,
        21,
        14,
        0,
        13,
        30,
        44,
        9,
        1,
        49,
        49,
        38,
        24,
        49,
        35,
        44,
        0,
        0,
        1,
        0,
        36,
        49,
        48,
        29,
        0,
        16,
        26,
        49,
        31,
        25,
        8,
        29,
        33,
        49
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyAccepted": [
        41,
        49,
        26,
        35,
        43,
        22,
        1,
        49,
        36,
        0,
        33,
        15,
        20,
        24,
        6,
        36,
        27,
        26,
        42,
        9,
        49,
        28,
        14,
        1,
        29,
        36,
        0,
        0,
        49,
        39,
        49,
        0,
        18,
        38,
        25,
        2,
        33,
        29,
        18,
        22,
        29,
        40,
        31,
        24,
        42,
        19,
        0,
        49,
        22,
        24,
        0,
        15
      ],
      "weeklyRejected": [
        49,
        0,
        17,
        7,
        39,
        3,
        0,
        49,
        36,
        1,
        49,
        32,
        49,
        11,
        31,
        20,
        44,
        0,
        14,
        49,
        1,
        47,
        0,
        49,
        49,
        44,
        49,
        25,
        42,
        11,
        39,
        47,
        48,
        49,
        49,
        49,
        0,
        17,
        45,
        13,
        38,
        49,
        17,
        0,
        39,
        24,
        0,
        29,
        0,
        34,
        11,
        49
      ],
      "weeklyHold": [
        49,
        18,
        49,
        24,
        42,
        43,
        49,
        23,
        28,
        37,
        47,
        47,
        30,
        44,
        13,
        46,
        4,
        1,
        41,
        9,
        20,
        34,
        42,
        17,
        28,
        1,
        14,
        39,
        39,
        0,
        48,
        17,
        0,
        16,
        48,
        27,
        22,
        26,
        42,
        19,
        39,
        36,
        9,
        8,
        37,
        33,
        11,
        49,
        15,
        39,
        0,
        28
      ],
      "weeklyBackordered": [
        30,
        49,
        0,
        38,
        49,
        14,
        20,
        22,
        22,
        43,
        8,
        0,
        3,
        3,
        22,
        49,
        38,
        0,
        12,
        34,
        39,
        17,
        31,
        49,
        18,
        11,
        28,
        11,
        13,
        34,
        3,
        33,
        4,
        49,
        36,
        0,
        43,
        35,
        7,
        41,
        4,
        48,
        0,
        18,
        16,
        9,
        21,
        14,
        49,
        28,
        49,
        31
      ]
    },
    {
      "id": "200EA71C",
      "weeklyAccepted": [
        16,
        22,
        49,
        0,
        21,
        1,
        43,
        2,
        40,
        15,
        46,
        0,
        45,
        20,
        1,
        16,
        49,
        8,
        17,
        43,
        19,
        49,
        47,
        38,
        25,
        47,
        23,
        1,
        1,
        41,
        49,
        27,
        46,
        26,
        33,
        44,
        47,
        24,
        0,
        31,
        39,
        33,
        7,
        30,
        24,
        0,
        0,
        25,
        11,
        49,
        28,
        28
      ],
      "weeklyRejected": [
        49,
        4,
        46,
        47,
        40,
        27,
        36,
        41,
        37,
        1,
        34,
        35,
        5,
        29,
        25,
        13,
        26,
        15,
        33,
        39,
        41,
        44,
        37,
        9,
        10,
        41,
        49,
        44,
        30,
        3,
        28,
        28,
        42,
        49,
        44,
        46,
        5,
        30,
        30,
        25,
        38,
        23,
        0,
        23,
        49,
        49,
        21,
        0,
        3,
        49,
        0,
        37
      ],
      "weeklyHold": [
        0,
        13,
        49,
        1,
        25,
        48,
        38,
        8,
        41,
        26,
        28,
        42,
        25,
        49,
        49,
        31,
        29,
        40,
        28,
        18,
        0,
        27,
        7,
        39,
        22,
        48,
        42,
        5,
        36,
        0,
        33,
        7,
        0,
        31,
        34,
        20,
        48,
        25,
        31,
        49,
        37,
        49,
        14,
        23,
        28,
        5,
        17,
        49,
        7,
        36,
        43,
        20
      ],
      "weeklyBackordered": [
        49,
        49,
        28,
        49,
        41,
        31,
        49,
        17,
        31,
        24,
        31,
        25,
        3,
        40,
        0,
        2,
        49,
        42,
        0,
        25,
        32,
        42,
        17,
        49,
        16,
        15,
        21,
        49,
        31,
        14,
        35,
        37,
        48,
        0,
        0,
        17,
        46,
        10,
        20,
        25,
        18,
        48,
        36,
        49,
        0,
        49,
        38,
        28,
        19,
        45,
        49,
        32
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyAccepted": [
        26,
        36,
        49,
        21,
        32,
        13,
        1,
        49,
        48,
        21,
        19,
        23,
        33,
        36,
        10,
        25,
        38,
        47,
        11,
        12,
        36,
        49,
        39,
        36,
        39,
        18,
        24,
        43,
        10,
        36,
        4,
        18,
        45,
        30,
        10,
        41,
        39,
        18,
        31,
        49,
        35,
        33,
        38,
        17,
        49,
        23,
        9,
        0,
        0,
        49,
        0,
        0
      ],
      "weeklyRejected": [
        42,
        0,
        2,
        39,
        30,
        20,
        11,
        49,
        49,
        18,
        26,
        0,
        37,
        32,
        47,
        39,
        23,
        49,
        15,
        4,
        19,
        7,
        49,
        15,
        14,
        49,
        44,
        0,
        19,
        32,
        13,
        37,
        27,
        49,
        16,
        42,
        47,
        28,
        31,
        31,
        31,
        38,
        26,
        35,
        22,
        20,
        2,
        9,
        5,
        19,
        0,
        40
      ],
      "weeklyHold": [
        34,
        27,
        49,
        1,
        1,
        17,
        0,
        37,
        27,
        22,
        47,
        49,
        1,
        42,
        19,
        37,
        8,
        11,
        42,
        47,
        15,
        32,
        23,
        49,
        4,
        19,
        39,
        5,
        49,
        0,
        39,
        19,
        35,
        12,
        48,
        33,
        19,
        10,
        49,
        49,
        19,
        2,
        0,
        35,
        37,
        4,
        13,
        49,
        7,
        45,
        49,
        13
      ],
      "weeklyBackordered": [
        0,
        28,
        0,
        7,
        49,
        38,
        38,
        1,
        49,
        17,
        0,
        29,
        3,
        18,
        19,
        3,
        35,
        0,
        1,
        39,
        24,
        45,
        23,
        43,
        34,
        17,
        39,
        15,
        1,
        37,
        9,
        34,
        0,
        49,
        0,
        19,
        13,
        18,
        49,
        25,
        24,
        15,
        40,
        49,
        39,
        17,
        36,
        9,
        36,
        40,
        49,
        1
      ]
    },
    {
      "id": "0AB87239",
      "weeklyAccepted": [
        49,
        25,
        31,
        48,
        23,
        12,
        16,
        48,
        48,
        37,
        32,
        0,
        8,
        49,
        15,
        19,
        49,
        0,
        0,
        27,
        3,
        18,
        34,
        20,
        39,
        25,
        24,
        20,
        17,
        22,
        0,
        0,
        49,
        20,
        48,
        39,
        27,
        15,
        40,
        49,
        27,
        42,
        49,
        45,
        8,
        7,
        23,
        43,
        1,
        20,
        0,
        30
      ],
      "weeklyRejected": [
        49,
        46,
        32,
        47,
        47,
        3,
        17,
        23,
        0,
        28,
        49,
        18,
        49,
        30,
        38,
        43,
        0,
        49,
        44,
        19,
        8,
        42,
        4,
        49,
        49,
        28,
        24,
        19,
        47,
        17,
        37,
        47,
        48,
        26,
        35,
        25,
        35,
        24,
        1,
        30,
        41,
        28,
        49,
        11,
        49,
        25,
        29,
        49,
        0,
        41,
        15,
        33
      ],
      "weeklyHold": [
        49,
        24,
        0,
        16,
        10,
        25,
        1,
        41,
        40,
        17,
        13,
        12,
        38,
        42,
        14,
        27,
        0,
        44,
        16,
        37,
        49,
        19,
        23,
        16,
        30,
        48,
        17,
        1,
        36,
        41,
        36,
        34,
        25,
        27,
        0,
        43,
        25,
        5,
        22,
        49,
        14,
        32,
        0,
        23,
        33,
        36,
        6,
        49,
        8,
        33,
        36,
        6
      ],
      "weeklyBackordered": [
        22,
        35,
        0,
        0,
        41,
        21,
        22,
        19,
        24,
        3,
        0,
        0,
        20,
        49,
        49,
        39,
        21,
        49,
        26,
        23,
        29,
        48,
        49,
        1,
        13,
        8,
        1,
        5,
        47,
        35,
        29,
        21,
        41,
        26,
        38,
        15,
        49,
        0,
        7,
        14,
        14,
        48,
        3,
        49,
        0,
        46,
        49,
        0,
        12,
        49,
        21,
        40
      ]
    },
    {
      "id": "CDA15976",
      "weeklyAccepted": [
        31,
        27,
        16,
        32,
        40,
        39,
        36,
        4,
        48,
        38,
        28,
        1,
        49,
        0,
        16,
        7,
        49,
        25,
        14,
        28,
        32,
        2,
        12,
        35,
        31,
        47,
        29,
        35,
        24,
        49,
        49,
        0,
        49,
        0,
        20,
        2,
        18,
        25,
        37,
        10,
        38,
        40,
        0,
        34,
        49,
        41,
        8,
        49,
        31,
        49,
        42,
        48
      ],
      "weeklyRejected": [
        40,
        49,
        49,
        47,
        24,
        17,
        5,
        45,
        49,
        36,
        49,
        35,
        0,
        48,
        7,
        9,
        16,
        15,
        49,
        42,
        19,
        49,
        24,
        49,
        7,
        49,
        14,
        13,
        31,
        0,
        49,
        36,
        46,
        49,
        9,
        49,
        48,
        35,
        2,
        0,
        40,
        36,
        42,
        27,
        7,
        35,
        0,
        1,
        34,
        36,
        13,
        20
      ],
      "weeklyHold": [
        19,
        9,
        31,
        14,
        40,
        40,
        37,
        9,
        24,
        15,
        0,
        24,
        48,
        49,
        18,
        23,
        15,
        0,
        0,
        26,
        0,
        49,
        0,
        18,
        17,
        31,
        35,
        49,
        49,
        43,
        41,
        33,
        7,
        44,
        17,
        0,
        0,
        22,
        20,
        49,
        44,
        17,
        21,
        33,
        14,
        49,
        49,
        46,
        29,
        8,
        42,
        31
      ],
      "weeklyBackordered": [
        49,
        45,
        33,
        49,
        22,
        40,
        22,
        18,
        41,
        5,
        20,
        32,
        35,
        13,
        47,
        49,
        37,
        49,
        27,
        38,
        32,
        0,
        29,
        0,
        1,
        49,
        5,
        25,
        2,
        37,
        0,
        40,
        23,
        45,
        0,
        0,
        49,
        0,
        3,
        16,
        39,
        48,
        0,
        49,
        35,
        39,
        43,
        49,
        48,
        36,
        34,
        1
      ]
    },
    {
      "id": "11660B2E",
      "weeklyAccepted": [
        15,
        7,
        19,
        0,
        16,
        0,
        22,
        2,
        40,
        17,
        49,
        0,
        42,
        49,
        49,
        6,
        38,
        42,
        37,
        39,
        19,
        49,
        3,
        36,
        12,
        47,
        49,
        35,
        4,
        26,
        28,
        42,
        49,
        35,
        0,
        27,
        47,
        13,
        6,
        28,
        0,
        4,
        49,
        10,
        49,
        0,
        9,
        3,
        27,
        35,
        29,
        3
      ],
      "weeklyRejected": [
        42,
        8,
        11,
        0,
        49,
        49,
        31,
        42,
        23,
        1,
        14,
        2,
        0,
        25,
        44,
        32,
        27,
        40,
        18,
        37,
        28,
        38,
        42,
        17,
        44,
        32,
        31,
        1,
        30,
        26,
        45,
        12,
        20,
        23,
        47,
        32,
        24,
        49,
        41,
        49,
        16,
        49,
        28,
        0,
        45,
        9,
        6,
        25,
        37,
        36,
        20,
        49
      ],
      "weeklyHold": [
        25,
        21,
        42,
        18,
        19,
        29,
        0,
        2,
        49,
        14,
        47,
        4,
        26,
        32,
        41,
        28,
        11,
        22,
        16,
        49,
        49,
        49,
        22,
        19,
        19,
        19,
        29,
        24,
        35,
        27,
        44,
        0,
        0,
        24,
        48,
        49,
        2,
        14,
        21,
        26,
        37,
        32,
        35,
        49,
        31,
        15,
        16,
        49,
        1,
        36,
        23,
        24
      ],
      "weeklyBackordered": [
        23,
        0,
        0,
        43,
        39,
        33,
        16,
        15,
        18,
        49,
        32,
        9,
        49,
        39,
        36,
        44,
        43,
        31,
        28,
        29,
        40,
        48,
        43,
        48,
        13,
        20,
        32,
        42,
        26,
        16,
        15,
        49,
        23,
        21,
        31,
        12,
        32,
        0,
        40,
        39,
        13,
        21,
        35,
        49,
        25,
        31,
        31,
        6,
        0,
        49,
        22,
        46
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyAccepted": [
        2,
        22,
        49,
        0,
        4,
        0,
        3,
        38,
        43,
        49,
        36,
        3,
        33,
        16,
        44,
        27,
        49,
        16,
        0,
        20,
        0,
        33,
        15,
        20,
        13,
        36,
        37,
        22,
        18,
        49,
        3,
        49,
        41,
        4,
        13,
        2,
        24,
        28,
        1,
        49,
        2,
        31,
        35,
        29,
        38,
        23,
        10,
        13,
        0,
        0,
        27,
        8
      ],
      "weeklyRejected": [
        28,
        18,
        22,
        42,
        33,
        49,
        38,
        12,
        49,
        1,
        0,
        46,
        21,
        14,
        37,
        27,
        21,
        49,
        42,
        0,
        10,
        27,
        0,
        20,
        24,
        42,
        17,
        19,
        28,
        48,
        22,
        47,
        20,
        49,
        22,
        39,
        33,
        25,
        11,
        32,
        48,
        49,
        49,
        6,
        49,
        7,
        0,
        0,
        15,
        0,
        0,
        29
      ],
      "weeklyHold": [
        0,
        17,
        27,
        19,
        48,
        46,
        46,
        13,
        25,
        28,
        47,
        34,
        22,
        22,
        37,
        40,
        13,
        15,
        19,
        0,
        46,
        18,
        13,
        25,
        43,
        1,
        25,
        15,
        41,
        17,
        41,
        39,
        11,
        38,
        25,
        30,
        12,
        5,
        28,
        26,
        4,
        49,
        27,
        32,
        34,
        13,
        46,
        28,
        0,
        33,
        8,
        23
      ],
      "weeklyBackordered": [
        24,
        27,
        40,
        49,
        3,
        46,
        40,
        34,
        40,
        33,
        23,
        40,
        44,
        0,
        42,
        12,
        40,
        5,
        18,
        49,
        0,
        0,
        36,
        14,
        34,
        11,
        9,
        49,
        1,
        42,
        45,
        49,
        0,
        23,
        0,
        35,
        1,
        12,
        10,
        27,
        48,
        28,
        0,
        27,
        20,
        40,
        11,
        35,
        0,
        49,
        48,
        21
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyAccepted": [
        8,
        26,
        20,
        30,
        49,
        26,
        6,
        3,
        48,
        0,
        28,
        11,
        7,
        35,
        21,
        0,
        12,
        3,
        15,
        0,
        21,
        22,
        10,
        49,
        6,
        42,
        20,
        20,
        7,
        38,
        9,
        0,
        49,
        3,
        21,
        37,
        30,
        48,
        49,
        49,
        0,
        1,
        49,
        27,
        49,
        0,
        37,
        0,
        30,
        36,
        16,
        48
      ],
      "weeklyRejected": [
        35,
        25,
        28,
        22,
        16,
        24,
        44,
        15,
        17,
        30,
        31,
        0,
        3,
        48,
        48,
        25,
        16,
        18,
        13,
        17,
        39,
        43,
        39,
        44,
        16,
        49,
        49,
        23,
        23,
        22,
        32,
        24,
        48,
        49,
        2,
        24,
        0,
        32,
        1,
        36,
        0,
        25,
        49,
        0,
        49,
        45,
        23,
        25,
        37,
        49,
        0,
        49
      ],
      "weeklyHold": [
        0,
        3,
        49,
        1,
        33,
        31,
        0,
        37,
        30,
        0,
        47,
        39,
        4,
        29,
        47,
        31,
        0,
        32,
        9,
        49,
        5,
        34,
        47,
        0,
        41,
        22,
        0,
        0,
        4,
        49,
        48,
        8,
        0,
        38,
        9,
        31,
        0,
        0,
        1,
        47,
        38,
        27,
        31,
        0,
        37,
        35,
        31,
        21,
        4,
        0,
        27,
        40
      ],
      "weeklyBackordered": [
        28,
        48,
        25,
        7,
        41,
        28,
        22,
        1,
        49,
        17,
        12,
        41,
        6,
        30,
        39,
        18,
        48,
        41,
        0,
        0,
        14,
        0,
        11,
        38,
        1,
        15,
        19,
        19,
        27,
        49,
        18,
        10,
        44,
        39,
        14,
        0,
        12,
        0,
        49,
        49,
        21,
        48,
        10,
        44,
        22,
        0,
        0,
        2,
        32,
        49,
        13,
        49
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyAccepted": [
        0,
        49,
        11,
        33,
        13,
        33,
        1,
        25,
        34,
        22,
        49,
        0,
        16,
        34,
        1,
        35,
        46,
        6,
        1,
        20,
        23,
        16,
        14,
        37,
        17,
        32,
        38,
        24,
        16,
        40,
        0,
        18,
        19,
        45,
        48,
        2,
        47,
        31,
        18,
        24,
        34,
        24,
        0,
        5,
        40,
        16,
        12,
        49,
        13,
        22,
        16,
        42
      ],
      "weeklyRejected": [
        37,
        0,
        2,
        27,
        30,
        49,
        49,
        30,
        0,
        22,
        29,
        28,
        0,
        25,
        48,
        12,
        15,
        38,
        35,
        40,
        9,
        0,
        16,
        18,
        29,
        49,
        40,
        49,
        41,
        20,
        49,
        23,
        48,
        49,
        17,
        49,
        49,
        35,
        1,
        28,
        38,
        32,
        0,
        7,
        45,
        40,
        0,
        18,
        29,
        37,
        0,
        49
      ],
      "weeklyHold": [
        49,
        14,
        48,
        19,
        31,
        23,
        28,
        2,
        24,
        8,
        30,
        15,
        48,
        16,
        19,
        4,
        49,
        36,
        0,
        49,
        0,
        29,
        49,
        0,
        43,
        48,
        14,
        0,
        33,
        23,
        48,
        23,
        10,
        49,
        41,
        29,
        0,
        0,
        49,
        10,
        27,
        49,
        6,
        35,
        33,
        49,
        13,
        28,
        2,
        12,
        11,
        49
      ],
      "weeklyBackordered": [
        16,
        21,
        28,
        11,
        49,
        25,
        17,
        49,
        43,
        16,
        30,
        36,
        6,
        25,
        5,
        28,
        31,
        34,
        0,
        45,
        2,
        34,
        0,
        36,
        34,
        1,
        46,
        20,
        31,
        33,
        43,
        12,
        28,
        43,
        0,
        0,
        3,
        26,
        29,
        20,
        32,
        48,
        48,
        32,
        0,
        0,
        2,
        41,
        23,
        25,
        49,
        25
      ]
    },
    {
      "id": "02A8E856",
      "weeklyAccepted": [
        27,
        33,
        16,
        34,
        22,
        25,
        5,
        49,
        32,
        0,
        47,
        23,
        27,
        26,
        14,
        15,
        46,
        6,
        0,
        9,
        48,
        7,
        39,
        30,
        26,
        8,
        9,
        11,
        21,
        41,
        49,
        0,
        49,
        20,
        48,
        49,
        15,
        10,
        39,
        40,
        27,
        38,
        49,
        39,
        47,
        0,
        13,
        23,
        49,
        29,
        0,
        11
      ],
      "weeklyRejected": [
        5,
        1,
        36,
        41,
        49,
        9,
        17,
        28,
        40,
        28,
        16,
        48,
        27,
        48,
        23,
        16,
        43,
        30,
        15,
        0,
        3,
        49,
        20,
        29,
        7,
        49,
        33,
        8,
        5,
        23,
        48,
        47,
        48,
        11,
        0,
        49,
        49,
        49,
        1,
        21,
        48,
        49,
        32,
        0,
        18,
        2,
        0,
        44,
        10,
        49,
        0,
        26
      ],
      "weeklyHold": [
        0,
        46,
        44,
        4,
        26,
        45,
        27,
        11,
        35,
        0,
        36,
        1,
        43,
        33,
        36,
        14,
        3,
        21,
        16,
        0,
        49,
        0,
        36,
        20,
        0,
        16,
        20,
        49,
        39,
        7,
        48,
        6,
        4,
        25,
        46,
        25,
        29,
        13,
        43,
        36,
        16,
        25,
        0,
        13,
        49,
        49,
        0,
        49,
        12,
        24,
        2,
        27
      ],
      "weeklyBackordered": [
        8,
        8,
        19,
        47,
        22,
        46,
        49,
        32,
        0,
        14,
        3,
        47,
        29,
        49,
        38,
        27,
        2,
        37,
        0,
        19,
        41,
        0,
        0,
        30,
        1,
        27,
        27,
        48,
        46,
        23,
        26,
        21,
        35,
        49,
        5,
        0,
        15,
        0,
        0,
        30,
        46,
        48,
        18,
        43,
        14,
        10,
        30,
        10,
        46,
        49,
        35,
        36
      ]
    }
  ]