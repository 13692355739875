const SearchBar = (props: { placeholder?: string, onChange?: (text: string) => void }) => {
    return (
        <div className="w-full flex items-center font-body border border-neutral-2 bg-white rounded-md px-3.5 py-1.5 text-neutral-5">
            <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
            <input 
            className="w-full bg-transparent placeholder:text-neutral-4 focus:outline-none" 
            placeholder={props.placeholder || "Search..."}
            onChange={(e) => props.onChange?.(e.target.value)}
            />
        </div>
    )
}

export default SearchBar