import { Outlet, useLocation, matchPath, Link, useNavigate } from "react-router-dom"
import { set, ref, serverTimestamp, onValue, update, DatabaseReference } from "firebase/database"
import { database, firestore } from "../../firebase/firebase"
import { signOut } from "firebase/auth"
import { auth } from "../../firebase/firebase"
import { useCookies } from "react-cookie"

import Select from "../../components/select/select"
import PrimaryButton from "../../components/primaryButton/primaryButton"
import Chat from "../../components/chat/chat"
import Dialog from "../../components/dialog/dialog"
import { useState, useEffect, useRef, useContext } from "react"
import SearchBar from "../../components/searchBar/searchBar"
import { ChatContext, CursotChatContext, EntityContext, LocationContext, TeamContext, UserContext } from "../../context/globalContext"
import ChatPanel from "../../components/chatPanel/chatPanel"
import BarChart from "../../components/barChart/barChart"
import { mockSpecialties } from "../../model/specialty/specialty"
import { ChatMessage, Chatroom } from "../../model/chat/chat"
import ProfileDialog from "../../components/profileDialog/profileDialog"
import { Unsubscribe, arrayUnion, collection, doc, getDoc, onSnapshot, query, updateDoc, where } from "firebase/firestore"
import NotificationAlert from "../../components/notificationAlert/notificationAlert"
import { Notification } from "../../model/notification/notification"
import ProfilePicture from "../../components/profilePicture/profilePicture"
import { locations } from "../../model/location/location"
import GlobalSearch from "../../components/globalSearch/globalSearch"
import AIAssistant from "../../components/aiAssistant/aiAssistant"

const Home = () => {
    const [expanded, setExpanded] = useState(false)
    const [globalSearchShown, setGlobalSearchShown] = useState(false)
    const [shareDialogShown, setShareDialogShown] = useState(false)

    const [profilePanel, setProfilePanel] = useState(false)

    const [notifications, setNotifications] = useState<Notification[]>()
    const [shownNotification, setShownNotification] = useState<Notification>()

    const [chats, setChats] = useState<Chatroom[]>()
    const [latestChat, setLatestChat] = useState<ChatMessage | undefined>()

    const [recents, setRecents] = useState<{ [path: string]: { timeSpent: number, clicks: number, title: string, type: string } }>()
    const [recentPaths, setRecentPaths] = useState<{ timeSpent: number, clicks: number, title: string, path: string, type: string }[]>()

    const elementRef = useRef<HTMLInputElement>(null)

    const cursotChatContext = useContext(CursotChatContext)
    const userContext = useContext(UserContext)
    const chatContext = useContext(ChatContext)
    const locationContext = useContext(LocationContext)
    const teamContext = useContext(TeamContext)

    const recentStorage = localStorage.getItem('recents')

    const location = useLocation()
    const navigate = useNavigate()

    const isOverview = matchPath('dashboard', location.pathname)
    const isInventory = matchPath('dashboard/inventory', location.pathname)
    const isInventoryItem = matchPath('dashboard/inventory/:id', location.pathname)
    const isProcedureItem = matchPath('dashboard/procedure/:id', location.pathname)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!elementRef.current?.contains(event.target as Node)) {
                setExpanded(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        const q = query(collection(firestore, "chats"), where('participants', 'array-contains', auth.currentUser?.uid || ''))

        let lastMessageUnsub: Unsubscribe | null = null

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)

            const sortedChats = chats.sort((a, b) => {
                return (a.lastMessage?.createdAt.seconds || 0) - (b.lastMessage?.createdAt.seconds || 0)
            })

            setChats(sortedChats)

            if (sortedChats.length > 0 && sortedChats[0].lastMessage) {
                const lastMessageSnapshot = onSnapshot(doc(firestore, 'chats', sortedChats[0].id, 'messages', sortedChats[0].lastMessage?.id), (doc) => {
                    setLatestChat(doc.data() as ChatMessage)
                })

                lastMessageUnsub = lastMessageSnapshot
            }
        })

        const notificationQuery = query(collection(firestore, "notifications"), where('recipients', 'array-contains', auth.currentUser?.uid || ''))

        const notificationUnsub = onSnapshot(notificationQuery, (collection) => {
            const docs = collection.docs

            const notifications = docs.map((doc) => doc.data() as Notification)
            setNotifications(notifications)
        })

        return () => {
            unsub()
            notificationUnsub()
            lastMessageUnsub?.()
        }
    }, [auth.currentUser?.uid])

    useEffect(() => {
        const storedData: { [path: string]: { timeSpent: number, clicks: number, title: string, type: string } } = JSON.parse(localStorage.getItem('recents') || '{}') || {}

        const recentPaths = Object.entries(storedData).map((v) => {
            return {
                timeSpent: v[1].timeSpent,
                clicks: v[1].clicks,
                title: v[1].title,
                type: v[1].type,
                path: v[0],
            }
        })

        const sortedPaths = [...recentPaths.sort((a, b) => {
            if (a.timeSpent !== b.timeSpent) {
                return b.timeSpent - a.timeSpent
            } else {
                return b.clicks - a.clicks
            }
        })]

        setRecents(storedData)
        setRecentPaths(sortedPaths)
    }, [recentStorage])

    useEffect(() => {
        setShownNotification(notifications?.find((v) => !v.seenBy.includes(auth.currentUser?.uid || '')))
    }, [notifications])

    const onCursorChatTapped = (cursor: {
        mouseX: number;
        mouseY: number;
        uid: string;
        username: string;
        currentPage: string;
        photoURL?: string | undefined;
    }) => {
        window.location.replace(cursor.currentPage);
    }

    const dismissNotification = (notification: Notification) => {
        setShownNotification(undefined)

        updateDoc(doc(firestore, 'notifications', notification.id), {
            seenBy: arrayUnion(auth.currentUser?.uid || '')
        })
    }

    return (

        <div className={`flex items-center w-screen h-screen bg-repeat-x bg-page bg-top ${window.location.pathname === '/dashboard' ? `bg-[url('/public/images/backgrounds/bg-pattern.svg')]` : ''}`}>
            <div className={`relative overflow-x-hidden w-full h-screen flex flex-col min-h-screen mx-auto items-center`}>
                <div className="p-4 absolute top-0 flex flex-col w-full h-[80px] max-w-[1440px]">
                    <div ref={elementRef} className="relative flex flex-col border border-neutral-2 rounded-md">
                        <div className="h-12 bg-page rounded-md flex items-center justify-between">
                            <div className="flex items-center">
                                <div onClick={() => setExpanded(!expanded)} className="p-3.5 cursor-pointer">
                                    <img className="h-4" src={expanded ? '/images/icons/ic-xmark-grey.svg' : '/images/icons/ic-hamburger.svg'}></img>
                                </div>

                                {window.location.pathname === "/dashboard" ?
                                    <>
                                        <img onClick={() => navigate('../dashboard', { replace: true, state: { previousLocationPathName: location.pathname } })} className="h-6 mx-2 cursor-pointer" src={'/images/icons/ic-dashboard.svg'}></img>

                                        <p className="font-body font-semibold">Dashboard</p>

                                        <div className="w-[1px] h-[16px] ml-2 bg-neutral-5"></div>
                                    </>
                                    :
                                    window.location.pathname === "/dashboard/tasks" ?
                                        <>
                                            <img onClick={() => navigate('../dashboard/tasks#items', { replace: true, state: { previousLocationPathName: location.pathname } })} className="h-6 mx-2 cursor-pointer" src={'/images/icons/ic-tasks.png'}></img>

                                            <p className="font-body font-semibold">Tasks</p>

                                            <div className="w-[1px] h-[16px] ml-2 bg-neutral-5"></div>
                                        </>
                                        :

                                        <>
                                            <img onClick={() => navigate('../dashboard/inventory#items', { replace: true, state: { previousLocationPathName: location.pathname } })} className="h-6 mx-2 cursor-pointer" src={'/images/icons/ic-inventory.svg'}></img>

                                            <p className="font-body font-semibold">Inventory</p>

                                            <div className="w-[1px] h-[16px] ml-2 bg-neutral-5"></div>
                                        </>
                                }

                                <Select
                                    type="ghost"
                                    size="lg"
                                    options={[
                                        {
                                            label: 'All Locations',
                                            value: 'all'
                                        },
                                        ...locations.map((location) => {
                                            return {
                                                label: location,
                                                value: location
                                            }
                                        })
                                    ]}
                                    label={!locationContext?.selectedLocation ? 'All' : locationContext.selectedLocation === 'all' ? 'Locations' : ''}
                                    selectedOption={locationContext?.selectedLocation}
                                    onChange={(v) => {
                                        locationContext?.setSelectedLocation(v)
                                    }}
                                ></Select>
                            </div>

                            <div onClick={() => setGlobalSearchShown(true)} className="w-[400px] flex items-center font-body border border-neutral-2 bg-neutral-1 rounded-md px-3.5 py-1.5 text-neutral-5 cursor-pointer">
                                <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                                <input disabled className="w-full bg-transparent placeholder:text-neutral-5 focus:outline-none pointer-events-none" placeholder="Search..."></input>
                            </div>

                            <div className="flex items-center px-2 gap-2">
                                <div className="flex items-center mx-4 gap-2">
                                    {cursotChatContext?.cursors[location.pathname]?.filter((v) => v.uid !== auth.currentUser?.uid && v.uid).map((cursor) => {
                                        return (
                                            <>
                                                {cursor.photoURL ?

                                                    <img className="w-[24px] h-[24px] rounded-full" src={cursor?.photoURL} onClick={() => onCursorChatTapped(cursor)}></img>
                                                    :
                                                    <div className="w-[24px] h-[24px] gradient-light-grey rounded-full text-white text-center" onClick={() => onCursorChatTapped(cursor)}>
                                                        <p className="font-body mt-[4px]">{cursor.username ? cursor.username[0] : ''}</p>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}
                                </div>

                                {(isOverview || isInventory) && (
                                    <Select
                                        size="lg"
                                        options={[{
                                            label: 'All Teams',
                                            value: 'all'
                                        }, ...mockSpecialties.map((specialty) => {
                                            return {
                                                label: specialty,
                                                value: specialty
                                            }
                                        })]}
                                        label={!teamContext?.selectedTeam ? 'All' : teamContext.selectedTeam === 'all' ? 'Team' : ''}
                                        selectedOption={teamContext?.selectedTeam || ''}
                                        onChange={(v) => teamContext?.setSelectedTeam(v)}
                                    />
                                )}

                                <div className="relative">
                                    <img onClick={() => chatContext?.setChatPanelOpen('open')} className="cursor-pointer h-[16px]" src={'/images/icons/ic-message-bubble.svg'}></img>

                                    {!latestChat?.readBy.includes(auth.currentUser?.uid || '') && (
                                        <div className="absolute top-[-4px] right-[-2px] rounded-full bg-critical w-1.5 h-1.5"></div>
                                    )}
                                </div>


                                {(isInventoryItem || isProcedureItem) && (
                                    <PrimaryButton
                                        title="Share"
                                        type="light-ghost"
                                        icon="ic-person-circle-plus.svg"
                                        size="md"
                                        onClick={() => setShareDialogShown(true)}
                                    />
                                )}

                                <div className="h-[16px] border-l border-neutral-2"></div>

                                {userContext?.user && (
                                    <div onClick={() => setProfilePanel(true)} className="cursor-pointer">
                                        <ProfilePicture
                                            user={userContext?.user}
                                            size={24}
                                        />
                                    </div>
                                )}
                            </div>

                        </div>

                        <div style={{ opacity: expanded ? '1' : '0', height: expanded ? 'fit-content' : '0px', pointerEvents: expanded ? 'auto' : 'none' }} className="bg-material-ultrathin backdrop-blur-lg z-[300] flex transition-opacity transition-height duration-200 ease-in-out">
                            <div className="flex flex-col gap-3 ml-[40px] my-[20px]">
                                <p className="font-callout text-neutral-5">Apps</p>

                                {/* <p className="font-title-1 font-bold">Arcadia AI</p>
                                <p className="font-title-1 font-bold">Calendar</p> */}

                                <Link onClick={() => setExpanded(false)} to={'dashboard/inventory#items'}>
                                    <p className="font-title-1 font-bold cursor-pointer">Inventory</p>
                                </Link>

                                <Link onClick={() => setExpanded(false)} to={'dashboard'}>
                                    <p className="font-title-1 font-bold cursor-pointer">Dashboard</p>
                                </Link>


                                <Link onClick={() => setExpanded(false)} to={'dashboard/tasks#overview'}>
                                    <p className="font-title-1 font-bold cursor-pointer">Tasks</p>
                                </Link>
                            </div>

                            <div className="flex flex-col gap-3 ml-[156px] my-[20px]">
                                <p className="font-callout text-neutral-5">Jump Back</p>

                                {recentPaths?.slice(0, 4).map((path) => {
                                    return <Link onClick={() => setExpanded(false)} to={path.path} replace={true}>
                                        <div className="flex gap-2">
                                            {path.type === 'item' && (
                                                <img className="w-[16px]" src={'/images/icons/ic-shipping-box-fill.svg'}></img>
                                            )}

                                            {path.type === 'procedure' && (
                                                <img className="w-[16px]" src={'/images/icons/ic-scenes.svg'}></img>
                                            )}

                                            {path.type === 'vendor' && (
                                                <img className="w-[16px]" src={'/images/icons/ic-credit-card.svg'}></img>
                                            )}


                                            <p className="font-body text-neutral-6">{path.title}</p>
                                        </div>
                                    </Link>
                                })}
                            </div>

                            <div className="flex flex-col gap-3 ml-auto w-[340px] my-[20px] mr-[40px]">
                                <p className="font-callout text-neutral-5">Latest message</p>
                                <div className="flex flex-col border border-neutral-2 bg-white p-2 rounded-md">
                                    <div className="flex gap-2 mb-4">
                                        <img className="w-[16px]" src={'/images/icons/ic-shipping-box-fill.svg'}></img>

                                        <p className="font-body text-neutral-6">
                                            {chats?.find((v) => v.lastMessage?.id === latestChat?.id)?.title}
                                        </p>
                                    </div>

                                    <div className="flex gap-1">
                                        <div className="mt-auto mb-3 w-[28px] h-[28px] gradient-light-grey rounded-full text-white text-center">
                                            <p className="mt-[2px]">J</p>
                                        </div>

                                        <div className="flex flex-col px-2">
                                            <p className="font-subheadline text-neutral-5 mb-1">{userContext?.users.find((v) => v.id === latestChat?.createdBy)?.name}</p>
                                            {latestChat && (
                                                <div className="max-h-[100px] overflow-hidden">
                                                    <Chat message={latestChat} isSend={false} withTail={true}></Chat>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div>
                                        <PrimaryButton
                                            title="Open Conversation"
                                            type="gray"
                                            maxWidth={true}
                                            onClick={() => {
                                                const chatroomId = chats?.find((v) => v.lastMessage?.id === latestChat?.id)?.id

                                                if (chatroomId) {
                                                    chatContext?.setChatPanelOpen(chatroomId)
                                                    setExpanded(false)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {profilePanel && (
                            <ProfileDialog onDismiss={() => setProfilePanel(false)} />
                        )}
                    </div>
                </div>

                <div className="overflow-y-auto flex-grow mt-[80px] w-full max-w-[1440px]">
                    <Outlet />
                </div>

                {shareDialogShown && (
                    <Dialog>
                        <div className="relative p-5 w-[460px]">
                            <div className="flex">
                                <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                                    <img className="h-[36px] w-[36px] gradient-light-grey p-1.5 rounded-full" src={'/images/icons/ic-share-person.svg'}></img>
                                </div>

                            </div>

                            <div className="flex flex-col gap-[10px] text-center my-[20px]">
                                <p className="font-body font-neutral-6 font-semibold">Share Access</p>
                                <p className="font-subheadline font-neutral-2">Share entity access with other team members or external parties outside the Hospital System.</p>
                            </div>

                            <div className="flex flex-col gap-2.5 p-2.5 rounded-md border border-neutral-2 bg-header">
                                <SearchBar />

                                <div className="flex p-2.5 bg-white border border-neutral-2 rounded-md w-full">
                                    <div className="">
                                        <p className="font-body">Everyone</p>
                                        <p className="font-subheadline text-neutral-5">Everyone at Cromwell Hospital can access this item</p>
                                    </div>

                                    <div className="ml-auto rounded-md">
                                        <Select
                                            size="lg"
                                            type="white"
                                            options={[
                                                {
                                                    label: 'Everyone',
                                                    value: 'everyone',
                                                },
                                                {
                                                    label: 'Anyone with the link',
                                                    value: 'anyone',
                                                },
                                                {
                                                    label: 'Invited',
                                                    value: 'invited',
                                                },
                                            ]}
                                            selectedOption="everyone"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2 p-2.5 bg-white border border-neutral-2 rounded-md w-full">
                                    <div className="flex h-[36px] items-center">
                                        <div className="">
                                            <p className="font-body">Nancy <span className="text-neutral-5">{'(you)'}</span></p>
                                        </div>

                                        <p className="ml-auto font-body text-neutral-5">Owner</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2"></div>

                                    <div className="flex h-[36px] items-center">
                                        <div className="">
                                            <p className="font-body">Mark Benfield</p>
                                            <p className="font-subheadline text-neutral-5">mbenfield@outsider.com</p>
                                        </div>

                                        <div className="ml-auto rounded-md">
                                            <Select
                                                size="lg"
                                                type="ghost"
                                                options={[
                                                    {
                                                        label: 'Owner',
                                                        value: 'Owner',
                                                    },
                                                    {
                                                        label: 'Can edit',
                                                        value: 'editor',
                                                    },
                                                    {
                                                        label: 'Can view',
                                                        value: 'viewer',
                                                    },
                                                ]}
                                                selectedOption="viewer"
                                            />
                                        </div>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2"></div>

                                    <div className="flex h-[36px] items-center">
                                        <div className="">
                                            <p className="font-body">Alita Delta</p>
                                            <p className="font-subheadline text-neutral-5">adelta@outsider.comm</p>
                                        </div>

                                        <div className="ml-auto rounded-md">
                                            <Select
                                                size="lg"
                                                type="ghost"
                                                options={[
                                                    {
                                                        label: 'Owner',
                                                        value: 'Owner',
                                                    },
                                                    {
                                                        label: 'Can edit',
                                                        value: 'editor',
                                                    },
                                                    {
                                                        label: 'Can view',
                                                        value: 'viewer',
                                                    },
                                                ]}
                                                selectedOption="viewer"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="flex mt-4">
                                <div>
                                    <PrimaryButton
                                        title="Dismiss"
                                        type="gray"
                                        onClick={() => setShareDialogShown(false)}
                                    />
                                </div>

                                <div className="ml-auto rounded-md">
                                    <PrimaryButton
                                        title="Copy link"
                                        type="white"
                                        onClick={() => {
                                            navigator.clipboard.writeText(window.location.href)
                                            setShareDialogShown(false)
                                        }}
                                    />
                                </div>
                            </div>

                            <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
                        </div>
                    </Dialog>
                )}

                {chatContext?.chatPanelOpen && (
                    <ChatPanel
                        onDismiss={() => chatContext.setChatPanelOpen('')}
                        selectedChatroomId={chatContext.chatPanelOpen}
                    />
                )}

                <AIAssistant />

                {shownNotification && !shownNotification.seenBy.includes(auth.currentUser?.uid || '') && (
                    <NotificationAlert
                        notification={shownNotification}
                        onDismiss={() => dismissNotification(shownNotification)}
                    />
                )}

                {globalSearchShown && (
                    <GlobalSearch
                        onDismiss={() => setGlobalSearchShown(false)}
                    />
                )}
            </div>
        </div>
    )
}

export default Home