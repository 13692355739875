import { Route, Routes } from "react-router-dom"
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import ProtectedRoute from "./utils/protectedRoute";

import Overview from "./pages/overview/overview";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Inventory from "./pages/inventory/inventory";
import InventoryItem from "./pages/inventoryItem.tsx/inventoryItem";
import ProcedureItem from "./pages/procedureItem/procedureItem";
import Onboarding from "./pages/onboarding/onboarding";
import GlobalContext from "./context/globalContext";
import VendorItem from "./pages/vendorItem/vendorItem";
import Tasks from "./pages/tasks/tasks";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['refreshToken', 'email', 'displayName', 'uid'])

  return (
    <GlobalContext>
      <Routes>
        <Route path="/login/*" element={<Login />} />
        <Route path="/onboarding" element={<Onboarding />} />

        <Route path="/*" element={
          <ProtectedRoute hasAccess={cookies.uid} redirect='/login'>
            <Home />
          </ProtectedRoute>
        } >
          <Route path="*" element={<Navigate to={"dashboard"} replace />} />
          <Route path="dashboard" element={<Overview />} />
          <Route path="dashboard/inventory" element={<Inventory />} />
          <Route path="dashboard/tasks" element={<Tasks />} />
          <Route path="dashboard/inventory/:id" element={<InventoryItem />} />
          <Route path="dashboard/procedure/:id" element={<ProcedureItem />} />
          <Route path="dashboard/vendor/:id" element={<VendorItem />} />
        </Route>
      </Routes>
    </GlobalContext>
  );
}

export default App;
