import { useContext, useEffect, useRef, useState } from "react"
import { EntityContext, UserContext } from "../../context/globalContext"
import ListItemRow from "../listItemRow/listItemRow"
import { useLocation, useNavigate } from "react-router-dom"
import PrimaryButton from "../primaryButton/primaryButton"
import { mockProcedures } from "../../model/procedure/procedure"
import { mockOrders } from "../../model/order/order"
import { Vendor, mockVendors } from "../../model/vendor/vendor"
import { mockContracts } from "../../model/contract/contract"
import { mockSurgeons } from "../../model/people/people"
import { useHotkeys } from "react-hotkeys-hook"

const GlobalSearch = (props: { initialSearch?: string, selectable?: boolean, onSelect?: (id: string, name: string) => void, onDismiss: () => void }) => {
    const [search, setSearch] = useState('')

    const userContext = useContext(UserContext)
    const entityContext = useContext(EntityContext)

    const ref = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()
    const location = useLocation()

    useHotkeys('esc', () => { props.onDismiss() }, { enableOnFormTags: true })

    const filteredItems = entityContext?.medicalItems.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.id.toLowerCase().includes(search.toLowerCase())
    }).slice(0, 4) || []

    const filteredProcedures = mockProcedures.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.id.toLowerCase().includes(search.toLowerCase())
    }).slice(0, 4) || []

    const filteredOrders = mockOrders.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.id.toLowerCase().includes(search.toLowerCase())
    }).slice(0, 4) || []

    const filteredVendors = mockVendors.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.id.toLowerCase().includes(search.toLowerCase())
    }).slice(0, 4) || []

    const filteredPeoples = mockSurgeons.filter((item) => {
        return `${item.name} ${item.surname}`.toLowerCase().includes(search.toLowerCase())
    }).slice(0, 4) || []

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!ref.current?.contains(event.target as Node)) {
                props.onDismiss()
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    const getVendorFillRate = (vendor: Vendor): number => {
        const contracts = mockContracts.filter((contract) => vendor.contracts.toArray().includes(contract.id))
        const fillRates = contracts.map((v) => v.fillRate).reduce((a, c) => a + c, 0)

        if (contracts.length === 0) {
            return 0
        }

        return fillRates / contracts.length
    }

    return (
        <div className="fixed inset-0 bg-gradient-to-b from-black/60 to-black/10 z-[600]">
            <div ref={ref} style={{ maxHeight: 'calc(100vh - 160px)' }} className="flex flex-col mx-auto bg-dialog rounded-md max-w-[600px] overflow-hidden mt-20 backdrop-blur-2xl">
                <div className="flex gap-2 items-center py-2 px-3">
                    <img className="w-[26px] h-[26px]" src='/images/icons/ic-box-gray.svg'></img>
                    <input onChange={(e) => setSearch(e.target.value)} value={search} className="font-large-title caret-primary-4  placeholder:text-neutral-5 bg-transparent focus:outline-none" autoFocus placeholder="Search..."></input>
                </div>

                {search && filteredItems.length > 0 && (
                    <div className="h-[1px] bg-neutral-2"></div>
                )}

                <div className="flex flex-col gap-0 overflow-y-auto">
                    {search && filteredItems.length > 0 && (
                        <div className="flex flex-col gap-2">
                            <p className="font-body font-semibold ml-4 mt-4">Item</p>

                            <div className="flex flex-col gap-1 ml-1 pr-1">
                                {filteredItems?.map((item) => {
                                    const disruption = entityContext?.disruptions.find((v) => v.entity === item.id)
                                    const assignee = userContext?.users.find((v) => v.id === disruption?.assignee)

                                    return (
                                        <ListItemRow
                                            key={item.id}
                                            title={item.name}
                                            subtitle={disruption ? disruption.type.charAt(0).toUpperCase() + disruption.type.slice(1) : item.status}
                                            icon={disruption ? {
                                                icon: 'ic-warning-triangle.svg',
                                                gradient: 'orange'
                                            } : {
                                                icon: 'ic-circle-dashed.svg',
                                                gradient: 'blue'
                                            }}
                                            arrowLabel={assignee?.name}
                                            hideBackground={true}
                                            hideBorder={true}
                                            arrowRight={true}
                                            onArrowClick={() => {
                                                props.onDismiss()
                                                if (props.selectable) {
                                                    props.onSelect?.(item.id, item.name)
                                                } else {
                                                    navigate(`../dashboard/inventory/${item.id}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                }
                                            }}
                                        >
                                            {!assignee && disruption && (
                                                <div className="z-[100]">
                                                    <PrimaryButton
                                                        title="Assign"
                                                        type="light-ghost"
                                                        onClick={() => {
                                                            // setDisruptedDialog(item.id)
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </ListItemRow>
                                    )
                                })}
                            </div>

                            <div className="h-[1px] bg-neutral-2 mx-4"></div>
                        </div>

                    )}

                    {search && filteredProcedures.length > 0 && (
                        <div className="flex flex-col gap-2">
                            <p className="font-body font-semibold ml-4 mt-4">Scene</p>

                            <div className="flex flex-col gap-1 ml-1 pr-1">
                                {filteredProcedures?.map((item) => {
                                    return (
                                        <ListItemRow
                                            title={item.name}
                                            subtitle={`${item.items} items • £${item.cost}`}
                                            arrowRight={true}
                                            hideBorder={true}
                                            hideBackground={true}
                                            onArrowClick={() => {
                                                props.onDismiss()
                                                if (props.selectable) {
                                                    props.onSelect?.(item.id, item.name)
                                                } else {
                                                    navigate(`../dashboard/procedure/${item.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                }
                                            }}
                                        />
                                    )
                                })}
                            </div>

                            <div className="h-[1px] bg-neutral-2 mx-4"></div>
                        </div>

                    )}

                    {search && filteredOrders.length > 0 && (
                        <div className="flex flex-col gap-2">
                            <p className="font-body font-semibold ml-4 mt-4">Vendor</p>

                            <div className="flex flex-col gap-1 ml-1 pr-1">
                                {filteredOrders?.map((order) => {
                                    return (
                                        <ListItemRow
                                            title={order.name}
                                            subtitle={`${order.itemsTotal} Items`}
                                            icon={order.status === 'Disrupted' ? {
                                                icon: 'ic-info-circle-fill-white.svg',
                                                gradient: 'red'
                                            } : order.status === 'Fill' ? {
                                                icon: 'ic-warning-triangle.svg',
                                                gradient: 'orange'
                                            } : {
                                                icon: 'ic-checkmark.svg',
                                                gradient: 'blue'
                                            }}
                                            arrowLabel={order.location}
                                            hideBackground={true}
                                            hideBorder={true}
                                            arrowRight={true}
                                            onArrowClick={() => {
                                                props.onDismiss()

                                                if (props.selectable) {
                                                    props.onSelect?.(order.id, order.name)
                                                }
                                            }}
                                        />
                                    )
                                })}
                            </div>

                            <div className="h-[1px] bg-neutral-2 mx-4"></div>
                        </div>
                    )}

                    {search && filteredVendors.length > 0 && (
                        <div className="flex flex-col gap-2">
                            <p className="font-body font-semibold ml-4 mt-4">Vendor</p>

                            <div className="flex flex-col gap-1 ml-1 pr-1">
                                {filteredVendors?.map((vendor) => {
                                    return (
                                        <ListItemRow
                                            title={vendor.name}
                                            subtitle={`${getVendorFillRate(vendor).toFixed(2)}% on time in full`}
                                            icon={{
                                                icon: getVendorFillRate(vendor) <= 55 ? 'ic-warning-circle-fill.svg' : getVendorFillRate(vendor) <= 70 ? 'ic-warning-triangle.svg' : 'ic-checkmark.svg',
                                                gradient: getVendorFillRate(vendor) <= 55 ? 'red' : getVendorFillRate(vendor) <= 70 ? 'orange' : 'light-grey'
                                            }}
                                            arrowLabel={`${vendor.contracts.toArray().length} contracts`}
                                            arrowRight={true}
                                            hideBackground={true}
                                            hideBorder={true}
                                            onArrowClick={() => {
                                                props.onDismiss()
                                                if (props.selectable) {
                                                    props.onSelect?.(vendor.id, vendor.name)
                                                } else {
                                                    navigate(`../dashboard/vendor/${vendor.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                }
                                            }}
                                        />
                                    )
                                })}
                            </div>

                            <div className="h-[1px] bg-neutral-2 mx-4"></div>
                        </div>
                    )}

                    {search && filteredPeoples.length > 0 && (
                        <div className="flex flex-col gap-2">
                            <p className="font-body font-semibold ml-4 mt-4">People</p>

                            <div className="flex flex-col gap-1 ml-1 pr-1">
                                {filteredPeoples?.map((people) => {
                                    return (
                                        <ListItemRow
                                            title={`${people.name} ${people.surname}`}
                                            subtitle={people.status !== 'Inactive' ? `Access to ${people.accessApps} apps` : 'Access disabled'}
                                            arrowRight={true}
                                            hideBackground={true}
                                            hideBorder={true}
                                            icon={people.status === 'Inactive' ? {
                                                icon: 'ic-minus-circle.svg',
                                                gradient: 'light-grey'
                                            } : undefined}
                                        />
                                    )
                                })}
                            </div>

                            <div className="h-[1px] bg-neutral-2 mx-4"></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GlobalSearch