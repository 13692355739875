import { useContext, useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import Input from "../input/input"
import { mockSurgeons } from "../../model/people/people"
import { UserContext } from "../../context/globalContext"

const UseSubstituteDialog = (props: { onDismiss: () => void, onSubmit: (date: Date | undefined) => void }) => {
    const [completedby, setCompletedBy] = useState<Date | undefined>()

    return (
        <Dialog>
            <div className="relative p-5 w-[330px]">
                <div className="flex">
                    <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                        <div className="flex items-center justify-center h-[44px] w-[44px] gradient-teal rounded-full">
                            <img className="h-[26px] w-[26px]" src={'/images/icons/ic-substitute-white.svg'}></img>
                        </div>

                    </div>

                </div>

                <div className="flex flex-col gap-[10px] text-center my-[20px]">
                    <p className="font-body font-neutral-6 font-semibold">Use as Substitute</p>
                    <p className="font-subheadline font-neutral-2">When using the item as substitute, it will replace the primary item everywhere temporarily.</p>
                </div>

                <div className="flex flex-col gap-2">
                    <Input
                        type="date"
                        dateValue={completedby}
                        label="Substitute Length (optional)"
                        onDateChange={(d) => setCompletedBy(d)}
                    />
                </div>


                <div className="flex mt-5">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        size="xl"
                        onClick={() => props.onDismiss()}
                    />

                    <div className="flex gap-2 ml-auto">
                        <PrimaryButton
                            title="Use as Substitute"
                            type="primary"
                            size="xl"
                            onClick={() => props.onSubmit(completedby)}
                        />
                    </div>
                </div>

                <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
            </div>
        </Dialog>
    )
}

export default UseSubstituteDialog