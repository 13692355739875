const TabPicker = (props: { selectedTab: string, tabs: string[], onChange: (tab: string) => void }) => {
    return (
        <div style={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset' }} className="flex cursor-pointer rounded-md bg-neutral-2 w-full p-[1px] gap-[2px]">
            {props.tabs.map((tab) => {
                return (
                    <>
                        {props.selectedTab === tab
                            ?
                            <p style={{ boxShadow: '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.15)' }} className="font-body w-full bg-white rounded-[5px] py-[2px] border-[0.5px] border-neutral-3 text-center">{tab}</p>

                            :
                            <p onClick={() => props.onChange(tab)} className="cursor-pointer font-body w-full bg-transparent rounded-md py-[2px] text-center">{tab}</p>
                        }
                    </>
                )
            })}


        </div>
    )
}

export default TabPicker