import { useState, useEffect, useContext } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { mockProcedures } from "../../model/procedure/procedure"
import { useCookies } from "react-cookie"
import uuid from "react-uuid"

import BarChart from "../../components/barChart/barChart"
import Toggle from "../../components/toggle/toggle"
import SidebarNavigationLink from "../../components/sidebarNavigationLink/sidebarNavigationLink"
import SearchBar from "../../components/searchBar/searchBar"
import PrimaryButton from "../../components/primaryButton/primaryButton"
import Select from "../../components/select/select"
import ListItemRow from "../../components/listItemRow/listItemRow"
import StackedListBar from "../../components/stackedListBar/stackedListBar"
import Card from "../../components/card/card"
import Tag from "../../components/tag/tag"
import SwapDialog from "../../components/swapDialog/swapDialog"
import PreferenceDialog from "../../components/preferenceDialog/preferenceDialog"
import SubscribeDialog from "../../components/subscribeDialog/subscribeDialog"
import Accordion from "../../components/accordion/accordion"
import { mockSurgeons } from "../../model/people/people"
import { MedicalItem } from "../../model/medicalItem/medicalItem"
import DisableDialog from "../../components/disableDialog/disableDialog"
import { mockProcedureUtilisations } from "../../model/procedure/procedureUtilisation"
import { getPastSixWeeksStartDates } from "../../utils/date"
import { mockPreferences } from "../../model/preference/preference"
import SidePanel from "../../components/sidePanel/sidePanel"
import { mockOrders, Order } from "../../model/order/order"
import { mockMedicalItemOrders } from "../../model/medicalItem/medicalItemOrder"
import { mockMedicalItemUsages } from "../../model/medicalItem/medicalItemUsage"

import '../../utils/string'
import { mockVendors } from "../../model/vendor/vendor"
import { EntityContext, UserContext } from "../../context/globalContext"
import moment from "moment"
import { doc, increment, updateDoc } from "firebase/firestore"
import { firestore } from "../../firebase/firebase"

const ProcedureItem = () => {
    const { id } = useParams()

    const [cookies, setCookie, removeCookie] = useCookies(['displayName', 'uid']);
    const [uid, setUid] = useState(cookies.uid || uuid().toString())

    const [currentPage, setCurrentPage] = useState('')
    const [subscribe, setSubscribe] = useState('')
    const [selectedSurgeon, setSelectedSurgeon] = useState('surgeon')
    const [selectedOrder, setSelectedOrder] = useState<Order>()
    const [selectedMedicalItem, setSelectedMedicalItem] = useState<MedicalItem>()

    const [subscribeDialog, setSubscribeDialog] = useState(false)
    const [swapDialog, setSwapDialog] = useState(false)
    const [preferenceDialog, setPreferenceDialog] = useState(false)
    const [activeDialog, setActiveDialog] = useState(false)

    const [procedure, setProcedure] = useState(mockProcedures.find((item) => item.name === id) || {
        id: "",
        name: "Appendectomy",
        surgeons: 10,
        status: "FALSE",
        items: 12,
        category: "Surgeries",
        cost: "1,000",
        location: "St. Mary's Hospital",
        performedAmount: 100,
        availablePreferences: 5,
        acceptedOrders: 0,
        rejectedOrders: 0,
        holdOrders: 0,
        backorderedOrders: 0,
        deletedOrders: 0,
        requiredItems: []
    })

    const entityContext = useContext(EntityContext)
    const userContext = useContext(UserContext)

    const location = useLocation()
    const navigate = useNavigate()

    const itemCategories = new Set(entityContext?.medicalItems.filter((v) => procedure.requiredItems.includes(v.id)).map((v) => v.category))
    const orders = mockOrders.filter((v) => v.items.toArray().some((v) => procedure.requiredItems.includes(v)))
    const lastUpdatedUser = userContext?.users.find((v) => v.id === procedure.lastUpdatedBy)
    const previousLocation = location.state?.previousLocationPathName?.split('/').at(-1) || ''

    useEffect(() => {
        if (location.hash === '#addpreference') {
            setCurrentPage('#overview')
            setPreferenceDialog(true)
            window.location.replace('#overview');
        } else {
            setCurrentPage(location.hash)
        }
    }, [location])

    useEffect(() => {
        document.title = `Procedure — ${procedure.name}`
    }, [procedure.name])

    useEffect(() => {
        const startTime = Date.now()

        return () => {
            const path = location.pathname + location.hash
            var storedData: { [path: string]: { timeSpent: number, clicks: number, title: string, type: string } } = JSON.parse(localStorage.getItem('recents') || '{}') || {}

            const endTime = Date.now()
            const totalTimeSpent = endTime - startTime

            if (storedData[path] === undefined) {
                storedData[path] = { timeSpent: 0, clicks: 0, title: '', type: 'procedure' }
            }

            storedData[path].timeSpent = (storedData[path]?.timeSpent || 0) + totalTimeSpent
            storedData[path].title = procedure.name
            localStorage.setItem('recents', JSON.stringify(storedData))
        }
    }, [])

    useEffect(() => {
        const handleClick = () => {
            const path = location.pathname + location.hash
            var storedData: { [path: string]: { timeSpent: number, clicks: number, title: string, type: string } } = JSON.parse(localStorage.getItem('recents') || '{}') || {}

            if (storedData[path] === undefined) {
                storedData[path] = { timeSpent: 0, clicks: 0, title: '', type: 'procedure' }
            }

            storedData[path].clicks += 1
            storedData[path].title = procedure.name
            localStorage.setItem('recents', JSON.stringify(storedData));
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [])

    const updateItem = async (value: Record<string, any>) => {
        if (selectedMedicalItem) {
            await updateDoc(doc(firestore, 'items', selectedMedicalItem.id), value)
        }
    }

    return (
        <>
            <div className="relative flex mt-4 pt-2 px-4 pb-4">
                <div className="w-[265px] top-[80px] bottom-4 fixed flex flex-col border border-neutral-2 bg-neutral-1 rounded-md">
                    <div onClick={() => navigate(-1)} className="cursor-pointer flex items-center gap-3 p-2">
                        <img className="h-[12px] cursor-pointer" src={process.env.PUBLIC_URL + '/images/icons/ic-chevron-left.svg'}></img>
                        <p className="font-title-3 font-semibold">{previousLocation.charAt(0).toUpperCase() + previousLocation.slice(1)}</p>
                    </div>

                    <div className="h-[1px] bg-neutral-2"></div>

                    <div className="flex p-2">
                        <div className="flex flex-col gap-1">
                            <p className="font-body line-clamp-1 text-ellipsis">{procedure.name}</p>

                            <div className="flex gap-2 items-center">
                                <div className="w-[10px] h-[10px] bg-success rounded-full"></div>
                                <p className="font-body text-neutral-5">Active</p>
                            </div>
                        </div>

                        <div className="ml-auto flex items-center">
                            <Toggle
                                value={procedure.status === "TRUE"}
                                onChange={() => {
                                    if (procedure.status === "TRUE") {
                                        setActiveDialog(true)
                                    } else {
                                        setProcedure({ ...procedure, status: procedure.status === "TRUE" ? "FALSE" : "TRUE" })
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="h-[1px] bg-neutral-2"></div>

                    <div className="flex flex-col gap-1 p-2">
                        <SidebarNavigationLink
                            title="Overview"
                            icon="ic-gauge.svg"
                            hashLink="#overview"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Preferences"
                            icon="ic-preferences.svg"
                            hashLink="#preferences"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Orders"
                            icon="ic-shipping-box.svg"
                            hashLink="#orders"
                            currentHashLink={currentPage}
                        />
                    </div>

                    {lastUpdatedUser && (
                        <div className="flex mt-auto p-2 gap-2 items-center">
                            <img className="h-[26px] w=[26px] p-1.5 bg-gradient-to-t from-neutral-4 to-neutral-3 rounded-md" src={'/images/icons/ic-pencil.svg'}></img>

                            <div className="flex flex-col justify-start">
                                <p className="font-body">{lastUpdatedUser.name}</p>
                                <p className="font-subheadline text-neutral-5">{`Edited ${moment(procedure.lastUpdated?.toDate()).fromNow()}`}</p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="ml-[265px] flex-grow">
                    <div className="w-[825px] items-start mx-auto">
                        {currentPage.includes('#overview') && (
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <div className="flex gap-3 items-center">
                                        <p className="font-large-title font-bold">{procedure?.name}</p>
                                    </div>

                                    <div className="flex gap-1 items-center">
                                        <Tag title={procedure.category}></Tag>

                                        <div className="font-subheadline font-bold text-primary-4">
                                            Add Tag
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky top-0 z-[100] flex p-2 w-full bg-white border-neutral-2 border rounded-md gap-2">
                                    <PrimaryButton
                                        title="Add Preference"
                                        type="primary"
                                        size="xl"
                                        onClick={() => setPreferenceDialog(true)}
                                    />

                                    {subscribe ?
                                        <Select
                                            size="xl"
                                            options={[
                                                {
                                                    label: 'All',
                                                    value: 'all',
                                                    icon: 'ic-bell-waves-fill.svg'
                                                },
                                                {
                                                    label: 'Personalised',
                                                    value: 'personalised',
                                                    icon: 'ic-bell.svg'
                                                },
                                                {
                                                    label: 'Unsubscribe',
                                                    value: 'unsubscribe',
                                                    icon: 'ic-minus.svg'
                                                },
                                            ]}
                                            selectedOption={subscribe}
                                            label="Subscribed"
                                            onChange={(value) => {
                                                if (value === 'unsubscribe') {
                                                    setSubscribe('')
                                                } else {
                                                    setSubscribe(value)
                                                }
                                            }}
                                        />
                                        :
                                        <PrimaryButton
                                            title="Subscribe"
                                            type="gray"
                                            size="xl"
                                            onClick={() => setSubscribeDialog(true)}
                                        />
                                    }

                                    <div className="flex ml-auto mr-2 items-center gap-[40px]">
                                        <div>
                                            <p className="font-callout font-semibold">{procedure?.id}</p>
                                            <p className="font-subheadline text-neutral-5">ID</p>
                                        </div>

                                        <div>
                                            <p className="font-callout font-semibold">{`${procedure?.surgeons} performing`}</p>
                                            <p className="font-subheadline text-neutral-5">Surgeons</p>
                                        </div>

                                        <div>
                                            <p className="font-callout font-semibold">{`£${procedure?.cost}`}</p>
                                            <p className="font-subheadline text-neutral-5">Avg. cost</p>
                                        </div>

                                        <div>
                                            <p className="font-callout font-semibold">1 hospital</p>
                                            <p className="font-subheadline text-neutral-5">Locations</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <Card title="Utilisation" subtitle="£ 4,280 avg. cost" rightArrow={true}>
                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: mockProcedureUtilisations.find((v) => v.id === procedure.id)?.wastedWeekly.slice(-6) || [],
                                                        color: 'teal'
                                                    },
                                                    {
                                                        data: mockProcedureUtilisations.find((v) => v.id === procedure.id)?.holdWeekly.slice(-6) || [],
                                                        color: 'caution'
                                                    },
                                                    {
                                                        data: mockProcedureUtilisations.find((v) => v.id === procedure.id)?.openWeekly.slice(-6) || [],
                                                        color: 'info'
                                                    }
                                                ]}
                                                breakpoints={[0, 2, 4, 6, 8, 10]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Wasted",
                                                        subtitle: `${(mockProcedureUtilisations.find((v) => v.id === procedure.id)?.wastedWeekly.slice(-6) || []).reduce((a, b) => a + b, 0)} units`,
                                                        color: "teal"
                                                    },
                                                    {
                                                        title: "Hold",
                                                        subtitle: `${(mockProcedureUtilisations.find((v) => v.id === procedure.id)?.holdWeekly.slice(-6) || []).reduce((a, b) => a + b, 0)} units`,
                                                        color: "caution"
                                                    },
                                                    {
                                                        title: "Open",
                                                        subtitle: `${(mockProcedureUtilisations.find((v) => v.id === procedure.id)?.openWeekly.slice(-6) || []).reduce((a, b) => a + b, 0)} units`,
                                                        color: "info"
                                                    }
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </Card>

                                    <Card title="Preferences" subtitle={`1 surgeon`}>
                                        <div className="max-h-full rounded-md border border-neutral-2 mt-2 overflow-y-auto">
                                            <ListItemRow
                                                title={mockPreferences.find((v) => v.id === procedure.id)?.surgeon || ''}
                                                subtitle={`Spent £${mockPreferences.find((v) => v.id === procedure.id)?.cost || 0} so far`}
                                                arrowRight={true}
                                                hideBorder={true}
                                                hideBackground={true}
                                            />
                                        </div>
                                    </Card>
                                </div>

                                <div className="grid grid-cols-1 gap-4 w-full">
                                    <Card title="Orders" subtitle="32 this month" rightArrow={true}>
                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: [75, 100, 125, 165, 175, 65],
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: [10, 20, 30, 40, 50, 10],
                                                        color: 'info'
                                                    }
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']}
                                                legends={[
                                                    {
                                                        title: "Used",
                                                        subtitle: "810 units",
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        title: "Wasted",
                                                        subtitle: "110 units",
                                                        color: "info"
                                                    }
                                                ]}
                                                selectedLabel={'Jul'}
                                            />
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        )}

                        {currentPage === '#preferences' && (
                            <div className="flex flex-col gap-4">
                                <div className="flex gap-5">
                                    <div>
                                        <p className="font-body">Standard Cost</p>
                                        <p className="font-large-title font-bold">{`£${procedure?.cost}`}</p>
                                    </div>

                                    <div>
                                        <p className="font-body">Items</p>
                                        <p className="font-large-title font-bold">{`${procedure?.items} Available`}</p>
                                    </div>

                                    <div>
                                        <p className="font-body">Preferences</p>
                                        <p className="font-large-title font-bold">{`${mockPreferences.filter((v) => v.id === procedure.id).length} Surgeon`}</p>
                                    </div>
                                </div>

                                <StackedListBar
                                    title="Item Breakdown"
                                    subtitle={`${mockPreferences.filter((v) => v.id === procedure.id).map((v) => v.add.toArray().length).reduce((a, b) => a + b, 0)} of ${procedure.requiredItems.length + mockPreferences.filter((v) => v.id === procedure.id).map((v) => v.add.toArray().length).reduce((a, b) => a + b, 0)} personalised items`}
                                    datasets={[
                                        {
                                            title: 'Default items',
                                            count: procedure.requiredItems.length,
                                            color: 'info',

                                        },
                                        {
                                            title: 'Personalised',
                                            count: mockPreferences.filter((v) => v.id === procedure.id).map((v) => v.add.toArray().length).reduce((a, b) => a + b, 0),
                                            color: 'caution',

                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="z-[100] flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[...mockPreferences.filter((v) => v.id === procedure.id).map((preference) => {
                                                return {
                                                    label: preference.surgeon,
                                                    value: preference.surgeon
                                                }
                                            }), { label: 'Surgeon', value: 'surgeon' }]}
                                            selectedOption={selectedSurgeon}
                                            onChange={(v) => setSelectedSurgeon(v)}
                                        />

                                        <PrimaryButton
                                            type="light-ghost"
                                            title="Add Item"
                                        />

                                        <PrimaryButton
                                            type="primary"
                                            title="Add Preference"
                                            icon="ic-plus-circle-white.svg"
                                            onClick={() => setPreferenceDialog(true)}
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 340px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {selectedSurgeon === "surgeon" ?
                                            <ListItemRow
                                                title={`${mockPreferences.filter((v) => v.id === procedure.id).map((v) => v.add.toArray().length).reduce((a, b) => a + b, 0)} preference Items hidden`}
                                                subtitle={'Filter by surgeon to view their item preferences in each category.'}
                                                icon={{
                                                    icon: 'ic-info-circle-fill-white.svg',
                                                    gradient: 'blue'
                                                }}
                                            />
                                            :
                                            <ListItemRow
                                                title={`Viewing Dr. ${selectedSurgeon.split(" ")[1]} Preferences`}
                                                subtitle={'You are currently viewing only the items required as per the surgeon\'s preference.'}
                                                icon={{
                                                    icon: 'ic-filter-fill.svg',
                                                    gradient: 'orange'
                                                }}
                                            >
                                                <PrimaryButton
                                                    title="Clear Filter"
                                                    type="gray"
                                                    onClick={() => {
                                                        setSelectedSurgeon("surgeon")
                                                    }}
                                                />
                                            </ListItemRow>
                                        }

                                        {Array.from(itemCategories).sort().filter((category) => {
                                            const items = entityContext?.medicalItems.filter((v) => procedure.requiredItems.includes(v.id) && v.category === category) || []
                                            const itemsRemoved = items.filter((v) => !mockPreferences.find((v) => v.id === procedure.id)?.remove.toArray().includes(v.id))
                                            const itemsAdded = entityContext?.medicalItems.filter((v) => v.category === category && mockPreferences.find((v) => v.id === procedure.id)?.add.toArray().includes(v.id)) || []


                                            return itemsAdded.length > 0 || itemsRemoved.length > 0
                                        }).map((category) => {
                                            const items = entityContext?.medicalItems.filter((v) => procedure.requiredItems.includes(v.id) && v.category === category) || []
                                            const preferences = mockPreferences.filter((v) => v.id === procedure.id && (v.remove.toArray().some(v => items.map((v) => v.id).includes(v)) || v.add.toArray().some(v => items.map((v) => v.id).includes(v))))
                                            const itemsRemoved = items.filter((v) => !mockPreferences.find((v) => v.id === procedure.id)?.remove.toArray().includes(v.id))
                                            const itemsAdded = entityContext?.medicalItems.filter((v) => v.category === category && mockPreferences.find((v) => v.id === procedure.id)?.add.toArray().includes(v.id)) || []

                                            return (
                                                <Accordion
                                                    title={category || 'No Category'}
                                                    subtitle={`${selectedSurgeon !== "surgeon" ? itemsRemoved.length + itemsAdded.length : items.length} item${items.length !== 1 ? 's' : ''} • £${items.map((v) => v.price).reduce((a, b) => a + b, 0)}`}
                                                    arrowLabel={`${preferences.length} preference${preferences.length !== 1 ? 's' : ''}`}
                                                >
                                                    {selectedSurgeon !== "surgeon" && (
                                                        itemsAdded.map((item) => {
                                                            return (
                                                                <>
                                                                    <ListItemRow
                                                                        title={item.name}
                                                                        subtitle={`Dr. ${selectedSurgeon} • ${item.price}`}
                                                                        icon={{
                                                                            icon: 'ic-person-fill.svg',
                                                                            gradient: 'purple'
                                                                        }}
                                                                        hideBorder={true}
                                                                        onArrowClick={() => setSelectedMedicalItem(item)}
                                                                    >
                                                                        <div className="flex gap-4 items-center mr-4">
                                                                            <img className="h-[14px] w-[14px]" src={'/images/icons/ic-trash.svg'}></img>

                                                                            <PrimaryButton
                                                                                title="Replace"
                                                                                type="gray"
                                                                                onClick={() => {
                                                                                    setSwapDialog(true)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </ListItemRow>
                                                                    <div className="h-[1px] bg-neutral-2 mx-2"></div>
                                                                </>
                                                            )
                                                        })
                                                    )}

                                                    {(selectedSurgeon !== "surgeon" ? itemsRemoved : items).map((item) => {
                                                        return (
                                                            <>
                                                                <ListItemRow
                                                                    title={item.name}
                                                                    subtitle={`Default item • £${item.price}`}
                                                                    hideBorder={true}
                                                                    onArrowClick={() => setSelectedMedicalItem(item)}
                                                                >
                                                                    <div className="flex gap-4 items-center mr-4">
                                                                        <img className="h-[14px] w-[14px]" src={'/images/icons/ic-trash.svg'}></img>

                                                                        <PrimaryButton
                                                                            title="Replace"
                                                                            type="gray"
                                                                            onClick={() => {
                                                                                setSwapDialog(true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </ListItemRow>
                                                                <div className="h-[1px] bg-neutral-2 mx-2"></div>
                                                            </>
                                                        )
                                                    })}
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#orders' && (
                            <div className="flex flex-col gap-4">
                                <StackedListBar
                                    title="Orders"
                                    subtitle={`${orders.filter((v) => v.status === 'Pending').length} of ${orders.length} in progress`}
                                    datasets={[
                                        {
                                            title: 'Accepted',
                                            count: orders.filter((v) => v.status === 'Accepted').length,
                                            color: 'success',

                                        },
                                        {
                                            title: 'Filled',
                                            count: orders.filter((v) => v.status === 'Filled').length,
                                            color: 'info',

                                        },
                                        {
                                            title: 'Hold',
                                            count: orders.filter((v) => v.status === 'Hold').length,
                                            color: 'caution',
                                        },
                                        {
                                            title: 'Disrupted',
                                            count: orders.filter((v) => v.status === 'Disrupted').length,
                                            color: 'critical',
                                        },
                                        {
                                            title: `${orders.filter((v) => v.status === 'Pending').length} Pending`,
                                            count: orders.filter((v) => v.status === 'Pending').length,
                                            color: '',
                                            showLabel: true
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Vendor',
                                                    value: 'vendor',
                                                }
                                            ]}
                                            selectedOption="vendor"
                                        />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Status',
                                                    value: 'status',
                                                }
                                            ]}
                                            selectedOption="status"
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 275px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {orders.map((order) => {
                                            return (
                                                <ListItemRow
                                                    title={order.name}
                                                    subtitle={`${order.itemsTotal} Items`}
                                                    icon={order.status === 'Disrupted' ? {
                                                        icon: 'ic-info-circle-fill-white.svg',
                                                        gradient: 'red'
                                                    } : order.status === 'Fill' ? {
                                                        icon: 'ic-warning-triangle.svg',
                                                        gradient: 'orange'
                                                    } : {
                                                        icon: 'ic-checkmark.svg',
                                                        gradient: 'blue'
                                                    }}
                                                    arrowLabel={order.location}
                                                    arrowRight={true}
                                                    onArrowClick={() => setSelectedOrder(order)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {swapDialog && (
                    <SwapDialog onSubmit={() => {
                        setSwapDialog(false)
                    }} />
                )}

                {preferenceDialog && (
                    <PreferenceDialog onSubmit={() => setPreferenceDialog(false)} />
                )}

                {subscribeDialog && (
                    <SubscribeDialog
                        onSubmit={() => {
                            setSubscribeDialog(false)
                            setSubscribe('all')
                        }}

                        onDismiss={() => {
                            setSubscribeDialog(false)
                        }}
                    />
                )}

                {activeDialog && (
                    <DisableDialog
                        onSubmit={() => {
                            setProcedure({ ...procedure, status: "FALSE" })
                            setActiveDialog(false)
                        }}
                        onDismiss={() => setActiveDialog(false)}
                    />
                )}
            </div>

            {selectedOrder && (
                <SidePanel
                    title={selectedOrder?.name || ''}
                    titleLabel="Order"
                    icon="ic-box-truck-black.svg"
                    iconGradient="yellow"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedOrder(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Overview</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder.id}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="pt-2">
                                        <div style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.13) inset' }} className="flex w-full h-[21px] gap-[2px] bg-neutral-1 rounded-md">
                                            <div style={{ width: `${selectedOrder.receivedQuantity / selectedOrder.itemsTotal * 100}%` }} className={`bg-info rounded-s-md`}>

                                            </div>

                                            <div style={{ width: `${selectedOrder.leftQuantity / selectedOrder.itemsTotal * 100}%` }} className="font-caption-2 text-neutral-5 text-center my-auto">
                                                {`${selectedOrder.leftQuantity} left`}
                                            </div>


                                        </div>

                                        <div className="flex py-1 gap-2">
                                            <div className="flex items-center font-caption-1 gap-1.5">
                                                <div className={`h-2 w-2 bg-info rounded-full`}>

                                                </div>

                                                <div>
                                                    <p className="font-caption-1 text-neutral-5">Received</p>
                                                    <p className="font-caption-1 text-neutral-6">{selectedOrder.receivedQuantity}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Location</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Branch</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder?.location}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Financial</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5 items-center">
                                        <p className="font-body">Items</p>
                                        <p className="font-body text-neutral-5 ml-auto">

                                            <PrimaryButton
                                                title="View All"
                                                type="white"
                                                size="md"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full">
                            <PrimaryButton
                                title="Call Supplier"
                                type="primary"
                                size="xl"
                                icon="ic-phone-white.svg"
                                iconSize={12}
                                maxWidth={true}
                            />
                        </div>

                    }
                />
            )}

            {selectedMedicalItem && (
                <SidePanel
                    title={selectedMedicalItem.name || ''}
                    titleLabel="Item"
                    icon="ic-shipping-box-black.svg"
                    iconGradient="teal"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedMedicalItem(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Manufacturer</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Name</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedMedicalItem?.manufacturer}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Item ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedMedicalItem?.manufacturerID}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Vendor</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Name</p>
                                        <p className="font-body text-neutral-5 ml-auto">{mockVendors.find((v) => v.id === selectedMedicalItem.vendor)?.name}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Item ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{mockVendors.find((v) => v.id === selectedMedicalItem.vendor)?.id}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Settings</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex px-2.5 pt-1 pb-2.5 items-center">
                                        <p className="font-body">Open</p>
                                        <div className="flex items-center border border-neutral-3 p-1 rounded-md ml-auto">
                                            <p className="ml-2 w-[50px] font-body">{entityContext?.medicalItems.find((v) => v.id === selectedMedicalItem.id)?.open || '0'}</p>
                                            <div className="relative">
                                                <img className="rounded-md p-1.5 bg-neutral-2 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down.svg`}></img>

                                                <div onClick={() => updateItem({ open: increment(1) })} className="w-full h-1/2 absolute top-0 left-0 cursor-pointer"></div>
                                                <div onClick={() => updateItem({ open: increment(-1) })} className="w-full h-1/2 absolute bottom-0 left-0 cursor-pointer"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex px-2.5 pt-2.5 pb-1 items-center">
                                        <p className="font-body">Hold</p>

                                        <div className="flex items-center border border-neutral-3 p-1 rounded-md ml-auto">
                                            <p className="ml-2 w-[50px] font-body">{entityContext?.medicalItems.find((v) => v.id === selectedMedicalItem.id)?.hold || '0'}</p>

                                            <div className="relative">
                                                <img className="rounded-md p-1.5 bg-neutral-2 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down.svg`}></img>

                                                <div onClick={() => updateItem({ hold: increment(1) })} className="w-full h-1/2 absolute top-0 left-0 cursor-pointer"></div>
                                                <div onClick={() => updateItem({ hold: increment(-1) })} className="w-full h-1/2 absolute bottom-0 left-0 cursor-pointer"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Utilisation</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-2">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Units Used</p>

                                        <p className="font-body text-neutral-5 ml-auto">{(mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyUsage.slice(-6).reduce((a, b) => a + b, 0) || 0) + (mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyWasted.slice(-6).reduce((a, b) => a + b, 0) || 0)}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Cases</p>
                                        <p className="font-body text-neutral-5 ml-auto">{mockProcedures.filter((v) => v.requiredItems.includes(selectedMedicalItem.id)).length}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="h-[250px] mt-2">
                                        <BarChart
                                            datasets={[
                                                {
                                                    data: mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyUsage.slice(-6) || [],
                                                    color: 'neutral-4'
                                                },
                                                {
                                                    data: mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyWasted.slice(-6) || [],
                                                    color: 'info'
                                                }
                                            ]}
                                            breakpoints={[0, 50, 100, 150, 200]}
                                            labels={getPastSixWeeksStartDates()}
                                            legends={[
                                                {
                                                    title: "Used",
                                                    subtitle: `${mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyUsage.slice(-6).reduce((a, b) => a + b, 0)} units`,
                                                    color: 'neutral-4'
                                                },
                                                {
                                                    title: "Wasted",
                                                    subtitle: `${mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyWasted.slice(-6).reduce((a, b) => a + b, 0)} units`,
                                                    color: "info"
                                                }
                                            ]}
                                            selectedLabel={getPastSixWeeksStartDates()[5]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full gap-2">
                            <PrimaryButton
                                title="Delete"
                                type="ghost"
                                size="xl"
                                iconSize={10}
                            />

                            <PrimaryButton
                                title="Replace"
                                type="white"
                                size="xl"
                                maxWidth={true}
                            />
                        </div>

                    }
                />
            )}
        </>
    )
}

export default ProcedureItem