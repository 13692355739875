import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"

const DisableDialog = (props: { onDismiss: () => void, onSubmit: () => void }) => {
    return (
        <Dialog>
            <div className="relative p-5 w-[290px]">
                <div className="flex">
                    <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                        <img className="h-[36px] w-[36px] gradient-red p-1.5 rounded-full" src={'/images/icons/ic-circle-slash.svg'}></img>
                    </div>

                </div>

                <div className="flex flex-col gap-[10px] text-center my-[20px]">
                    <p className="font-body font-neutral-6 font-semibold">Disable Item?</p>
                    <p className="font-subheadline font-neutral-2">Disabling the item will make it unusable for all the healthcare system.</p>
                </div>

                <div className="flex flex-col gap-[10px]">
                    <PrimaryButton
                        title="Yes, Disable"
                        type="primary"
                        maxWidth={true}
                        onClick={() => props.onSubmit()}
                    />

                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        maxWidth={true}
                        onClick={() => props.onDismiss()}
                    />
                </div>

                <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
            </div>
        </Dialog>
    )
}

export default DisableDialog