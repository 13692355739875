import { useContext, useState, useEffect } from "react"
import SidebarNavigationLink from "../../components/sidebarNavigationLink/sidebarNavigationLink"
import ProfilePicture from "../../components/profilePicture/profilePicture"
import { ChatContext, EntityContext, UserContext } from "../../context/globalContext"
import { Disruption } from "../../model/disruption/disruption"
import { collection, doc, getDoc, onSnapshot, query, updateDoc, where } from "firebase/firestore"
import { auth, firestore } from "../../firebase/firebase"
import ListItemRow from "../../components/listItemRow/listItemRow"
import SidePanel from "../../components/sidePanel/sidePanel"
import { Chatroom } from "../../model/chat/chat"
import moment from "moment"
import PrimaryButton from "../../components/primaryButton/primaryButton"
import ChatPanel from "../../components/chatPanel/chatPanel"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Task } from "../../model/task/task"
import { mockVendors } from "../../model/vendor/vendor"
import uuid from "react-uuid"

const Tasks = () => {
    const [currentPage, setCurrentPage] = useState('#overview')
    const [tasks, setTasks] = useState<Task[]>([])
    const [allTasks, setAllTasks] = useState<Task[]>([])
    const [resolvedTasks, setResolvedTasks] = useState<Task[]>([])
    const [selectedTask, setSelectedTask] = useState<Task>()
    const [selectedTab, setSelectedTab] = useState('Overview')
    const [chats, setChats] = useState<Chatroom[]>([])
    const [substituteSidePanel, setSubstituteSidePanel] = useState(false)

    const userContext = useContext(UserContext)
    const chatContext = useContext(ChatContext)
    const entityContext = useContext(EntityContext)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const disruptions = entityContext?.disruptions || []

        const tasks: Task[] = []
        const allTasks: Task[] = []
        const resolvedTasks: Task[] = []

        console.log(entityContext?.substitutes)

        disruptions.filter((v) => v.resolved !== true).forEach((disruption) => {
            const substitutes = entityContext?.substitutes.filter((v) => v.primaryItemID === disruption.entity) || []

            if (substitutes.length > 0) {
                substitutes.forEach((substitute) => {
                    allTasks.push({
                        disruption: disruption,
                        substitute: substitute
                    })

                    if (disruption.assignee === auth.currentUser?.uid) {
                        tasks.push({
                            disruption: disruption,
                            substitute: substitute
                        })
                    }
                })

            } else {
                allTasks.push({
                    disruption: disruption
                })

                if (disruption.assignee === auth.currentUser?.uid) {
                    tasks.push({
                        disruption: disruption
                    })
                }
            }
        })

        disruptions.filter((v) => v.resolved === true).forEach((disruption) => {
            const substitutes = entityContext?.substitutes.filter((v) => v.primaryItemID === disruption.entity) || []

            if (substitutes.length > 0) {
                substitutes.forEach((substitute) => {
                    resolvedTasks.push({
                        disruption: disruption,
                        substitute: substitute
                    })
                })

            } else {
                resolvedTasks.push({
                    disruption: disruption
                })
            }
        })

        setTasks(tasks)
        setAllTasks(allTasks)
        setResolvedTasks(resolvedTasks)
    }, [entityContext?.disruptions, entityContext?.substitutes])

    useEffect(() => {
        const q = query(collection(firestore, "chats"),
            where('participants', 'array-contains', auth.currentUser?.uid || ''),
            where('hasEntity', '==', true),
            where('resolved', '==', false)
        )

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)

            const sortedChats = chats.sort((a, b) => {
                if (!b.lastMessage) {
                    return -1
                }

                if (!a.lastMessage) {
                    return -1
                }

                return (b.lastMessage?.createdAt.seconds || 0) - (a.lastMessage?.createdAt.seconds || 0)
            })

            setChats(sortedChats)
        }, (err) => console.log(err))

        return () => {
            unsub()
        }
    }, [auth.currentUser?.uid])

    useEffect(() => {
        if (!selectedTask) {
            setSelectedTab('Overview')
        }
    }, [selectedTask])

    useEffect(() => {
        setCurrentPage(location.hash)
    }, [location.hash])

    const updateDisruption = async (value: Record<string, any>) => {
        if (!selectedTask?.disruption) { return }
        await updateDoc(doc(firestore, 'disruptions', selectedTask.disruption.entity), value)
    }

    return (
        <>
            <div className="relative flex mt-8 pb-4">
                <div className="flex mx-auto gap-4">
                    <div className="fixed flex flex-col gap-1 w-[170px]">
                        <Link to={'#overview'} replace={true}>
                            <div className={`flex gap-2 py-1 px-1.5 items-center w-full ${currentPage === '#overview' ? 'bg-neutral-2' : ''} rounded-md mb-4 cursor-pointer`}>
                                <ProfilePicture
                                    user={userContext?.user}
                                    size={38}
                                />

                                <div className="flex flex-col gap-0">
                                    <p className="font-body">Overview</p>
                                    <p className="font-subheadline text-neutral-5">{`${tasks.length} tasks`}</p>
                                </div>
                            </div>
                        </Link>

                        <SidebarNavigationLink
                            title="Items"
                            icon="ic-shipping-box.svg"
                            hashLink="#items"
                            currentHashLink={currentPage}
                            subtitle={`${tasks.length}`}
                        />
                    </div>

                    <div className="ml-[186px] flex flex-col gap-2">
                        <div className="flex flex-col bg-page rounded-md w-[730px]">

                            {currentPage !== '#items' && (
                                <div className="flex flex-col gap-2 p-2 overflow-y-auto">
                                    {tasks.length > 0 && (
                                        <div className="flex flex-col gap-2 mt-4">
                                            <p className="font-subheadline text-neutral-5 font-semibold">Assigned to Me</p>

                                            <div className="flex flex-col gap-2.5">
                                                {tasks.map((item) => {
                                                    return (
                                                        <ListItemRow
                                                            key={item.disruption?.id}
                                                            title={entityContext?.medicalItems.find((v) => v.id === item.disruption?.entity)?.name || ''}
                                                            subtitle={(item.disruption?.type.charAt(0).toUpperCase() || '') + item.disruption?.type.slice(1)}
                                                            icon={{
                                                                icon: 'ic-warning-triangle.svg',
                                                                gradient: 'orange'
                                                            }}
                                                            arrowLabel={`${userContext?.user?.team || ''} (Me)`}
                                                            arrowRight={true}
                                                            onArrowClick={() => {
                                                                setSubstituteSidePanel(false)
                                                                setSelectedTask(item)
                                                            }}
                                                        >

                                                        </ListItemRow>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}

                                    {chats.length > 0 && (
                                        <div className="flex flex-col gap-2 mt-4">
                                            <p className="font-subheadline text-neutral-5 font-semibold">Needs my input</p>

                                            <div className="flex flex-col gap-2.5">
                                                {chats?.map((chat) => {
                                                    const substitute = entityContext?.substitutes.find((v) => v.chatRef === chat.id)

                                                    return (
                                                        <div onClick={() => {
                                                            console.log('debug 0', chat.entityIds, chat.id, tasks, resolvedTasks)

                                                            if (substitute) {
                                                                const task = [...tasks, ...resolvedTasks].find((v) => v.substitute?.chatRef === chat.id)
                                                                setSubstituteSidePanel(false)

                                                                if (task) {
                                                                    setSelectedTask(task)
                                                                } else {
                                                                    var newTask: Task = {
                                                                        substitute: substitute
                                                                    }

                                                                    const disruption = entityContext?.disruptions.find((v) => chat.entityIds.includes(v?.entity || ''))

                                                                    if (disruption) {
                                                                        newTask.disruption = disruption
                                                                    }

                                                                    setSelectedTask(newTask)
                                                                }

                                                                console.log('debug 1', [...tasks, ...resolvedTasks].map((v) => v.substitute?.chatRef))
                                                            } else {
                                                                const task = [...tasks, ...resolvedTasks].find((v) => chat.entityIds.includes(v.disruption?.entity || ''))
                                                                setSubstituteSidePanel(false)

                                                                if (task) {
                                                                    setSelectedTask(task)
                                                                } else {
                                                                    const disruption = entityContext?.disruptions.find((v) => chat.entityIds.includes(v?.entity || ''))

                                                                    const newTask: Task = {
                                                                        disruption: disruption
                                                                    }

                                                                    setSelectedTask(newTask)
                                                                }
                                                                console.log('debug 2', [...tasks, ...resolvedTasks].map((v) => v.disruption?.entity))
                                                            }
                                                        }} style={{ borderWidth: `1px`, backgroundColor: 'white' }} className="flex border-neutral-2 rounded-md items-center px-3 py-2 gap-2 cursor-pointer">
                                                            <div className={`h-[40px] min-w-[40px] gradient-teal rounded-md flex items-center justify-center mb-auto`}>
                                                                <img className="h-[16px] w-[16px]" src={`/images/icons/ic-shipping-box-white.svg`}></img>
                                                            </div>

                                                            <div className="flex flex-col gap-0 mr-auto w-full">
                                                                <div className="flex">
                                                                    <div className="flex flex-col">
                                                                        <div className="flex gap-2">
                                                                            <p className="font-body text-neutral-6 w-fit line-clamp-1">{chat.title}</p>
                                                                            <p className="font-body text-neutral-5 w-fit line-clamp-1">{moment(chat.lastMessage?.createdAt.toDate()).fromNow()}</p>


                                                                        </div>

                                                                        <p className="font-subheadline text-neutral-5 line-clamp-1 ">{chat.lastMessage?.message}</p>
                                                                    </div>

                                                                    <div className="ml-auto flex h-[40px] items-center">
                                                                        <div style={{ transform: `translate(${8 * (chat.participants.length >= 3 ? 2 : chat.participants.length - 1)}px)` }} className="flex gap-0 ml-auto mr-2">
                                                                            {chat.participants.slice(0, chat.participants.length > 3 ? 2 : 3).map((participant, index) => {
                                                                                const user = userContext?.users.find((v) => v.id === participant)

                                                                                return (
                                                                                    <div
                                                                                        style={{ transform: `translate(-${8 * index}px)` }}
                                                                                        className={`bg-white p-[1px] rounded-full z-[${index}]`}>
                                                                                        {user ?
                                                                                            <ProfilePicture
                                                                                                user={user}
                                                                                                size={30}
                                                                                            />
                                                                                            :
                                                                                            <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] gradient-light-grey rounded-full"></div>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })}

                                                                            {chat.participants.length > 3 && (
                                                                                <div
                                                                                    style={{ transform: `translate(-${16}px)` }}
                                                                                    className={`bg-white p-[1px] rounded-full z-[${2}]`}>
                                                                                    <div style={{ width: `30px`, height: `30px`, minWidth: `30px`, minHeight: `30px` }} className="flex items-center justify-center gradient-light-grey rounded-full text-white text-center">
                                                                                        <p style={{ fontSize: `${30 * 0.5}px` }} className="">{`+${chat.participants.length - 2}`}</p>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        <img className="pl-1 h-[13px]" src={process.env.PUBLIC_URL + '/images/icons/ic-chevron-right.svg'}></img>
                                                                    </div>
                                                                </div>

                                                                {chat.hasPoll && (
                                                                    <>
                                                                        <div className="h-[1px] bg-neutral-2 mt-2 mb-3"></div>

                                                                        <PrimaryButton
                                                                            title="Answer Poll"
                                                                            type="white"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                if (substitute) {
                                                                                    const task = [...tasks, ...resolvedTasks].find((v) => v.substitute?.chatRef === chat.id)
                                                                                    setSubstituteSidePanel(false)

                                                                                    if (task) {
                                                                                        setSelectedTask(task)
                                                                                    } else {
                                                                                        const newTask: Task = {
                                                                                            substitute: substitute
                                                                                        }

                                                                                        setSelectedTask(newTask)
                                                                                    }

                                                                                    console.log('debug 1', [...tasks, ...resolvedTasks].map((v) => v.substitute?.chatRef))
                                                                                } else {
                                                                                    const task = [...tasks, ...resolvedTasks].find((v) => chat.entityIds.includes(v.disruption?.entity || ''))
                                                                                    setSubstituteSidePanel(false)

                                                                                    setSelectedTask(task)
                                                                                    console.log('debug 2', [...tasks, ...resolvedTasks].map((v) => v.disruption?.entity))
                                                                                }

                                                                                setSelectedTab('')

                                                                                setTimeout(() => {
                                                                                    setSelectedTab('Chat')
                                                                                }, 100)
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}

                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {currentPage === '#items' && (
                                <div className="flex flex-col gap-2 p-2 overflow-y-auto">
                                    {allTasks.length > 0 && (
                                        <div className="flex flex-col gap-2 mt-4">
                                            <div className="flex flex-col gap-2.5">
                                                {allTasks.map((item) => {
                                                    return (
                                                        <ListItemRow
                                                            key={item.disruption?.id}
                                                            title={entityContext?.medicalItems.find((v) => v.id === item.disruption?.entity)?.name || ''}
                                                            subtitle={(item.disruption?.type.charAt(0).toUpperCase() || '') + item.disruption?.type.slice(1)}
                                                            icon={{
                                                                icon: 'ic-warning-triangle.svg',
                                                                gradient: 'orange'
                                                            }}
                                                            arrowLabel={`${userContext?.user?.team || ''} (Me)`}
                                                            arrowRight={true}
                                                            onArrowClick={() => {
                                                                setSubstituteSidePanel(false)
                                                                setSelectedTask(item)
                                                            }}
                                                        >

                                                        </ListItemRow>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {selectedTask && (
                <SidePanel
                    title={entityContext?.medicalItems.find((v) => v.id === (substituteSidePanel ? selectedTask.substitute?.substituteItemID : selectedTask.disruption?.entity))?.name || ''}
                    titleLabel="Item"
                    icon="ic-shipping-box-black.svg"
                    iconGradient="teal"
                    chatRoomIds={selectedTask.substitute?.chatRef ? [selectedTask.substitute.chatRef] : [selectedTask.disruption?.chatRef || '']}
                    onDismiss={() => setSelectedTask(undefined)}
                    selectedTab={selectedTab}
                    content={
                        <div className="flex flex-col px-2.5 py-4 gap-2">
                            {!substituteSidePanel && selectedTask.substitute && (
                                <div className="flex flex-col gap-2">
                                    <p className="font-body font-semibold px-2">Requested Substitute</p>

                                    <div onClick={() => {
                                        // chatContext?.setChatPanelOpen(chat.id)
                                    }} style={{ borderWidth: `1px`, backgroundColor: 'white' }} className="flex border-neutral-2 rounded-md items-center px-3 py-2 gap-2">
                                        <div className="w-[32px] h-[32px] min-w-[32px] p-[1px] border-neutral-2 bg-white rounded-[2px] mb-auto">
                                            <div className="w-full h-full border-[0.5px] border-neutral-1 bg-neutral-1 rounded-[2px] shadow-inner">
                                                <img className="w-full p-[1px]" src={'/images/icons/ic-mock-item.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-0 mr-auto w-full">
                                            <div className="flex">
                                                <div className="flex flex-col">
                                                    <div className="flex gap-2">
                                                        <p className="font-body text-neutral-6 w-fit line-clamp-1">{selectedTask.substitute ? entityContext?.medicalItems.find((v) => v.id === selectedTask.substitute?.substituteItemID)?.name : 'No Substitute'}</p>
                                                    </div>

                                                    <p className="font-subheadline text-neutral-5 line-clamp-1 ">{selectedTask.substitute ? `Manufactured by ${mockVendors.find((v) => v.id === entityContext?.medicalItems.find((v) => v.id === selectedTask.substitute?.substituteItemID)?.vendor)?.name || ''}` : 'No pre-approved substitute'}</p>
                                                </div>
                                            </div>

                                            <div className="h-[1px] bg-neutral-2 mt-2 mb-3"></div>

                                            <div className="flex gap-1">
                                                <PrimaryButton
                                                    title="View Conversation"
                                                    type="white"
                                                    size="sm"
                                                    onClick={() => {
                                                        setSelectedTab('')

                                                        setTimeout(() => {
                                                            setSelectedTab('Chat')
                                                        }, 100)
                                                    }}
                                                />

                                                <PrimaryButton
                                                    title="View Item"
                                                    type="ghost"
                                                    size="sm"
                                                    icon="ic-arrow-top-right-grey.svg"
                                                    iconSize={9}
                                                    onClick={() => navigate(`../dashboard/inventory/${selectedTask.substitute?.substituteItemID || selectedTask.disruption?.entity || ''}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {substituteSidePanel && (
                                <div className="flex flex-col gap-2">
                                    <p className="font-body font-semibold px-2">Currently Substituting</p>

                                    <div className="flex flex-col rounded-md border border-neutral-2">
                                        <div className="flex gap-2 p-3">
                                            <ProfilePicture
                                                user={userContext?.users.find((v) => v.id === selectedTask.substitute?.requestedBy)}
                                                size={32}
                                            />

                                            <div className="flex flex-col gap-0.5">
                                                <p className="font-body font-semibold">{userContext?.users.find((v) => v.id === selectedTask.substitute?.requestedBy)?.name || ''}</p>
                                                <p className="font-body text-neutral-5">Requested By</p>
                                            </div>
                                        </div>

                                        <div className="h-[1px] mx-1 bg-neutral-2"></div>

                                        <ListItemRow
                                            key={uuid()}
                                            title={entityContext?.medicalItems.find((v) => v.id === selectedTask.disruption?.entity)?.name || ''}
                                            subtitle={(selectedTask.disruption?.type.charAt(0).toUpperCase() || '') + selectedTask.disruption?.type.slice(1)}
                                            icon={{
                                                icon: 'ic-warning-triangle.svg',
                                                gradient: 'orange'
                                            }}
                                            arrowLabel={userContext?.users.find((v) => v.id === selectedTask.disruption?.assignee)?.name}
                                            arrowRight={true}
                                            hideBorder={true}
                                            iconSize={32}
                                            onArrowClick={() => {
                                                navigate(`../dashboard/inventory/${selectedTask.disruption?.entity}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                            }}
                                        >
                                            {!userContext?.users.find((v) => v.id === selectedTask.disruption?.assignee) && (
                                                <div className="z-[100]">
                                                    <PrimaryButton
                                                        title="Assign"
                                                        type="light-ghost"
                                                        onClick={() => {

                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </ListItemRow>

                                        <div className="h-[1px] mx-1 bg-neutral-2"></div>

                                        <p className="font-callout text-neutral-5 p-2">
                                            The above item will be substituted once the request is approved.
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Details</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Status</p>
                                        <p className="font-body text-neutral-5 ml-auto">{(selectedTask.disruption?.type.charAt(0).toUpperCase() || '') + selectedTask.disruption?.type.slice(1)}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Length</p>
                                        <p className="font-body text-neutral-5 ml-auto">{`${selectedTask.disruption?.resolveBy?.toDate()?.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) || 'Indefinite'}`}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Reported By</p>
                                        <p className="font-body text-neutral-5 ml-auto">{userContext?.users.find((v) => v.id === selectedTask.disruption?.createdBy)?.name}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex py-1.5 px-2.5 items-center">
                                        <p className="font-body">Assigned To</p>
                                        <div className="ml-auto">
                                            <ProfilePicture
                                                user={userContext?.users.find((v) => v.id === selectedTask.disruption?.createdBy)}
                                                size={28}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">{selectedTask.substitute ? 'Substitute' : 'Substitutes'}</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Approved</p>
                                        <p className="font-body text-neutral-5 ml-auto">{`${entityContext?.substitutes.filter((v) => v.primaryItemID === selectedTask.disruption?.entity && v.status !== 'Requested').length} items`}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Pending</p>
                                        <p className="font-body text-neutral-5 ml-auto">{`${entityContext?.substitutes.filter((v) => v.primaryItemID === selectedTask.disruption?.entity && v.status === 'Requested').length} items`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full gap-2">
                            <PrimaryButton
                                title="View Item"
                                type={selectedTask.substitute ? 'ghost' : "light-ghost"}
                                size="xl"
                                onClick={() => navigate(`../dashboard/inventory/${selectedTask.disruption?.entity}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })}
                            />

                            {selectedTask.substitute ?
                                <PrimaryButton
                                    title="Answer Poll"
                                    type="primary"
                                    size="xl"
                                    maxWidth={true}
                                    onClick={() => {
                                        setSelectedTab('')

                                        setTimeout(() => {
                                            setSelectedTab('Chat')
                                        }, 100)
                                    }}
                                />
                                :
                                <PrimaryButton
                                    title="Mark as Resolved"
                                    type="green"
                                    size="xl"
                                    maxWidth={true}
                                    onClick={() => {
                                        updateDisruption({
                                            resolved: true
                                        })

                                        setSelectedTask(undefined)
                                    }}
                                />
                            }

                        </div>

                    }
                />
            )}

            {chatContext?.chatPanelOpen && (
                <ChatPanel
                    onDismiss={() => chatContext.setChatPanelOpen('')}
                    selectedChatroomId={chatContext.chatPanelOpen}
                />
            )}
        </>
    )
}

export default Tasks