import { useContext, useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import Input from "../input/input"
import { mockSurgeons } from "../../model/people/people"
import { UserContext } from "../../context/globalContext"

const DisruptedDialog = (props: { onDismiss: () => void, onSubmit: (assignee: string | undefined, type: string, resolveBy: Date | undefined) => void }) => {
    const [reason, setReason] = useState("backorder")
    const [completedby, setCompletedBy] = useState<Date | undefined>()
    const [assigneeText, setAssigneeText] = useState<string | undefined>()
    const [assignee, setAssignee] = useState<{
        label: string,
        value: string,
        icon?: string,
        subtitle?: string
    } | undefined>()

    const userContext = useContext(UserContext)

    return (
        <Dialog>
            <div className="relative p-5 w-[330px]">
                <div className="flex">
                    <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                        <img className="h-[36px] w-[36px] pr-2 gradient-light-grey p-1.5 rounded-full" src={'/images/icons/ic-person-circle-plus-white.svg'}></img>
                    </div>

                </div>

                <div className="flex flex-col gap-[10px] text-center my-[20px]">
                    <p className="font-body font-neutral-6 font-semibold">Assign Disruption</p>
                    <p className="font-subheadline font-neutral-2">You can choose to assign disruption to a team, or mark item as disrupted immediately.</p>
                </div>

                <div className="flex flex-col gap-2">
                    <Input
                        type="select"
                        label="Substitute Reason"
                        value={reason}
                        options={[
                            {
                                label: "Backorder",
                                value: "backorder"
                            },
                            {
                                label: "Discontinued",
                                value: "discontinued"
                            },
                            {
                                label: "Recall",
                                value: "recall"
                            },
                            {
                                label: "Rejected",
                                value: "rejected"
                            },
                        ]}
                        onChange={(v) => setReason(v)}
                    />

                    <Input
                        type="search"
                        label="Assignee"
                        options={userContext?.users?.map((user) => {
                            return {
                                label: `${user.name}`,
                                value: user.id,
                                subtitle: user.team
                            }
                        })}
                        value={assigneeText}
                        onChange={(v) => setAssigneeText(v)}
                        onSearchChange={(v) => setAssigneeText(v)}
                        onOptionChange={(v) => {
                            setAssigneeText(`${v.label} (${v.subtitle})`)
                            setAssignee(v)
                        }}
                    />

                    <Input
                        type="date"
                        dateValue={completedby}
                        label="Complete By"
                        onDateChange={(d) => setCompletedBy(d)}
                    />
                </div>


                <div className="flex mt-5">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        onClick={() => props.onDismiss()}
                    />

                    <div className="flex gap-2 ml-auto">
                        <PrimaryButton
                            title="Skip"
                            type="gray"
                            onClick={() => props.onSubmit(assignee?.value, reason, completedby)}
                        />

                        <PrimaryButton
                            title="Assign"
                            type="primary"
                            disabled={!assignee}
                            onClick={() => props.onSubmit(assignee?.value, reason, completedby)}
                        />
                    </div>
                </div>

                <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
            </div>
        </Dialog>
    )
}

export default DisruptedDialog