import { useContext, useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import Input from "../input/input"
import ListItemRow from "../listItemRow/listItemRow"
import { mockProcedures } from "../../model/procedure/procedure"
import { MedicalItem } from "../../model/medicalItem/medicalItem"
import { mockSpecialties } from "../../model/specialty/specialty"
import DatePickerDialog from "../datePickerDialog/datePickerDialog"
import { EntityContext } from "../../context/globalContext"

const SubstituteDialog = (props: { onSubmit: (selectedItem: MedicalItem | undefined) => void }) => {
    const [currentPage, setCurrentPage] = useState('overview')

    const [specialty, setSpecialty] = useState('Cardiology')
    const [reason, setReason] = useState('backorder')
    const [search, setSearch] = useState('')

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

    const [selectedItem, setSelectedItem] = useState<MedicalItem>()

    const [procedureDialog, setProcedureDialog] = useState(false)
    const [zoomDialog, setZoomDialog] = useState(false)
    const [dateDialog, setDateDialog] = useState(false)

    const entityContext = useContext(EntityContext)

    return (
        <>
            <Dialog>
                <div className={`flex flex-col relative w-[800px] h-[540px] ${currentPage === "overview" ? "bg-[url('/public/images/backgrounds/bg-gradient-dialog.svg')]" : ""} bg-no-repeat bg-top rounded-md`}>

                    <div className="flex flex-col p-5 mx-[120px]">
                        {currentPage === 'overview' && (
                            <div className="mx-[60px]">
                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[20px]">
                                    <p className="font-large-title font-neutral-6 font-bold">Request Subsitute</p>
                                    <p className="font-body font-neutral-5">You are about to replace the item with a substitute. The substitute will also be replaced on the following entities:</p>
                                </div>

                                <div className="flex flex-col gap-3">
                                    <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                        <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                            <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-medkit.svg`}></img>
                                        </div>

                                        <div>
                                            <p className="font-subheadline font-semibold">Procedures</p>
                                            <p className="font-subheadline text-neutral-5">Percutaneous coronary +3 more</p>
                                        </div>

                                        <img onClick={() => setProcedureDialog(true)} className="cursor-pointer ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                    </div>

                                    <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                        <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                            <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-menu-white.svg`}></img>
                                        </div>

                                        <div>
                                            <p className="font-subheadline font-semibold">Surgeon Preferences</p>
                                            <p className="font-subheadline text-neutral-5">Coronary Angiography +7 more</p>
                                        </div>

                                        <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                    </div>

                                    <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                        <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                            <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-box-truck-white.svg`}></img>
                                        </div>

                                        <div>
                                            <p className="font-subheadline font-semibold">Orders</p>
                                            <p className="font-subheadline text-neutral-5">Orders with only the item contained will be canceled</p>
                                        </div>

                                        <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                    </div>

                                    <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                        <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                            <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-signature-white.svg`}></img>
                                        </div>

                                        <div>
                                            <p className="font-subheadline font-semibold">Contracts</p>
                                            <p className="font-subheadline text-neutral-5">Substitute notification will be sent to all vendors</p>
                                        </div>

                                        <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === 'items' && (
                            <>
                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px] mx-[40px]">
                                    <p className="font-large-title font-neutral-6 font-bold">Specify Items</p>
                                    <p className="font-title-3 text-neutral-5">Specify what item you’d like to use as replacement. You can browse your inventory or enter a brand new item.</p>
                                </div>

                                <div className="flex flex-col gap-2.5 mx-[40px] overflow-y-hidden h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md pt-2.5">
                                    <div className="px-2.5">
                                        <div className="flex items-center w-full font-body placeholder:text-neutral-4 focus:outline-none text-neutral-6 bg-transparent p-2 rounded-md border border-neutral-2">
                                            <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                                            <input value={search} onChange={(e) => setSearch(e.target.value)} className="w-full bg-transparent focus:outline-none" type="text" placeholder="Search"></input>
                                        </div>
                                    </div>



                                    <div className="flex flex-col gap-2.5 overflow-y-auto px-2.5 pb-2.5">
                                        {entityContext?.medicalItems.filter((item) => selectedItem ? item === selectedItem : (item.name.toLowerCase().includes(search.toLowerCase()) || !search)).map((item) => {
                                            return (
                                                <div className="flex gap-2 bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                                    <div onClick={() => setZoomDialog(true)} className="cursor-pointer w-[28px] h-[28px] border=[0.5px] p-[1px] border-neutral-2 bg-white rounded-[2px]">
                                                        <div className="w-full h-full border-[0.5px] border-neutral-1 bg-neutral-1 rounded-[2px] shadow-inner">
                                                            <img className="w-full p-[1px]" src={'/images/icons/ic-mock-item.svg'}></img>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className="font-body">{item.name}</p>
                                                        <p className="font-subheadline text-neutral-5">{item.status}</p>
                                                    </div>

                                                    <div className="flex items-center ml-auto">
                                                        <PrimaryButton
                                                            title={item === selectedItem ? "Change" : "Select"}
                                                            type={item === selectedItem ? "white" : "gray"}
                                                            size="lg"
                                                            onClick={() => {
                                                                if (item === selectedItem) {
                                                                    setSelectedItem(undefined)
                                                                } else {
                                                                    setSelectedItem(item)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <div className="flex flex-col gap-1.5 border border-neutral-2 rounded-md bg-white p-1.5">
                                            <div className="flex p-1.5">
                                                <p className="font-body">ID</p>
                                                <p className="font-body text-neutral-5 ml-auto">{selectedItem?.erpID}</p>
                                            </div>

                                            <div className="h-[1px] bg-neutral-2"></div>

                                            <div className="flex p-1.5">
                                                <p className="font-body">Manufacturer</p>
                                                <p className="font-body text-neutral-5 ml-auto">{selectedItem?.manufacturer}</p>
                                            </div>

                                            <div className="h-[1px] bg-neutral-2"></div>

                                            <div className="flex p-1.5">
                                                <p className="font-body">Manufacturer ID</p>
                                                <p className="font-body text-neutral-5 ml-auto">{selectedItem?.manufacturerID}</p>
                                            </div>

                                            <div className="h-[1px] bg-neutral-2"></div>

                                            <div className="flex p-1.5 items-center">
                                                <div className="flex flex-col gap-1">
                                                    <p className="font-body">Vendor</p>
                                                    <p className="font-subheadline text-neutral-5">On Contract</p>
                                                </div>
                                                <p className="font-body text-neutral-5 ml-auto">Cardinal Health</p>
                                            </div>

                                            <div className="h-[1px] bg-neutral-2"></div>

                                            <div className="flex p-1.5">
                                                <p className="font-body">Status</p>
                                                <p className="font-body text-neutral-5 ml-auto">{selectedItem?.status}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {currentPage === 'reason' && (
                            <>
                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px] mx-[40px]">
                                    <p className="font-large-title font-neutral-6 font-bold">Substitution Reason</p>
                                    <p className="font-title-3 text-neutral-5">Specify the essential details for the substitute to raise awareness of other members in the hospital.</p>
                                </div>

                                <div className="flex flex-col gap-2 mx-[100px]">
                                    <Input
                                        type="select"
                                        label="Specialty"
                                        value={specialty}
                                        options={mockSpecialties.map((specialty) => {
                                            return (
                                                {
                                                    label: specialty,
                                                    value: specialty
                                                }
                                            )
                                        })}
                                        onChange={(v) => setSpecialty(v)}
                                    />

                                    <Input
                                        type="select"
                                        label="Substitute Reason"
                                        value={reason}
                                        options={[
                                            {
                                                label: "Backorder",
                                                value: "backorder"
                                            },
                                            {
                                                label: "Discontinued",
                                                value: "discontinued"
                                            },
                                            {
                                                label: "Recall",
                                                value: "recall"
                                            },
                                            {
                                                label: "Rejected",
                                                value: "rejected"
                                            },
                                        ]}
                                        onChange={(v) => setReason(v)}
                                    />

                                    <Input
                                        type="date"
                                        label="Substitution Length"
                                        value="indefinite"
                                        options={[
                                            {
                                                label: "Indefinite",
                                                value: "indefinite"
                                            },
                                        ]}
                                    />

                                    <Input
                                        type="textarea"
                                        label="Additional Information (optional)"
                                        placeholder="Help team members understand the reason behind this substitution"
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="mt-auto h-[1px] bg-neutral-2"></div>

                    <div className="flex p-5 h-[74px]">
                        <PrimaryButton
                            title="Dismiss"
                            type="gray"
                            size="xl"
                            onClick={() => props.onSubmit(selectedItem)}
                        />

                        <div className="flex gap-3 ml-auto">
                            {currentPage === 'overview' && (
                                <PrimaryButton
                                    title="Specify Items"
                                    type="white"
                                    size="xl"
                                    onClick={() => {
                                        setCurrentPage('items')
                                    }}
                                />
                            )}

                            {currentPage === 'items' && !selectedItem && (
                                <>
                                    <PrimaryButton
                                        title="Back"
                                        type="gray"
                                        size="xl"
                                        onClick={() => {
                                            setCurrentPage("overview")
                                        }}
                                    />

                                    <PrimaryButton
                                        title="Skip"
                                        type="gray"
                                        size="xl"
                                        onClick={() => {
                                            setCurrentPage("reason")
                                        }}
                                    />
                                </>
                            )}

                            {currentPage === 'items' && selectedItem && (
                                <>
                                    <PrimaryButton
                                        title="Substitute Reason"
                                        type="gray"
                                        size="xl"
                                        onClick={() => {
                                            setCurrentPage("reason")
                                        }}
                                    />
                                </>
                            )}

                            {currentPage === 'reason' && (
                                <>
                                    <PrimaryButton
                                        title="Back"
                                        type="gray"
                                        size="xl"
                                        onClick={() => {
                                            setCurrentPage("items")
                                        }}
                                    />

                                    <PrimaryButton
                                        title="Save as Draft"
                                        type="white"
                                        size="xl"
                                        onClick={() => {
                                            props.onSubmit(selectedItem)
                                        }}
                                    />

                                    <PrimaryButton
                                        title="Request Substitution"
                                        type="primary"
                                        size="xl"
                                        onClick={() => {
                                            props.onSubmit(selectedItem)
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>


            </Dialog>




            {procedureDialog && (
                <Dialog>
                    <div className="flex flex-col w-[490px] h-[350px] rounded-md bg-white">
                        <div className="flex flex-col gap-3 p-5">
                            {mockProcedures.slice(0, 4).map((procedure) => {
                                return (
                                    <ListItemRow
                                        title={procedure.name}
                                        subtitle={`${procedure.surgeons} physicians`}
                                        arrowRight={true}
                                    />
                                )
                            })}

                        </div>

                        <div className="mt-auto h-[1px] bg-neutral-2"></div>

                        <div className="flex px-5 h-[68px] items-center">
                            <PrimaryButton
                                title="Dismiss"
                                type="gray"
                                size="md"
                                onClick={() => setProcedureDialog(false)}
                            />
                        </div>
                    </div>
                </Dialog>
            )}

            {zoomDialog && (
                <Dialog>
                    <div className="flex flex-col relative w-[490px] h-[450px] rounded-md bg-white">
                        <div className="flex flex-col gap-3 p-5">
                            <img src={`/images/icons/ic-mock-item-zoom.svg`}></img>
                        </div>

                        <div className="mt-auto h-[1px] bg-neutral-2"></div>

                        <div className="flex px-5 h-[68px] items-center">
                            <PrimaryButton
                                title="Dismiss"
                                type="gray"
                                size="md"
                                onClick={() => setZoomDialog(false)}
                            />

                            <div className="ml-auto">
                                <PrimaryButton
                                    title="Replace Image"
                                    type="gray"
                                    size="md"
                                    icon="ic-square-arrow-up.svg"
                                    onClick={() => setZoomDialog(false)}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    )
}

export default SubstituteDialog