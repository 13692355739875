export interface Order {
    id: string
    status: string // Filled, Pending, Accepted
    name: string
    requestedBy: string // Name of requestor (mock data)
    itemsTotal: number 
    location: string
    receivedQuantity: number
    leftQuantity: number
    items: string // Items in this order
    contract: string // Corresponding contract ID
}

export const mockOrders: Order[] = [
    {
      "id": "ORDR-0-0000000",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000028",
      "items": "[7B415249,11102F48,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000001",
      "status": "Pending",
      "name": "HealthAxis Supplies",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005, CNTRCT-0000007",
      "items": "[F04529EA,8607492C]"
    },
    {
      "id": "ORDR-0-0000002",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000004",
      "items": "[D33377F5,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000003",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[88F245C1,BAF9CD1A,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000004",
      "status": "Hold",
      "name": "VitalityGear Solutions",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000018",
      "items": "[E0A95BBC,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000005",
      "status": "Disrupted",
      "name": "MedLogistics Partners",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,065DB72B]"
    },
    {
      "id": "ORDR-0-0000006",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E80AAE4A,22633EF8,C36BF121]"
    },
    {
      "id": "ORDR-0-0000007",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[7A8CC6BD,74B98558,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000008",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Mary Durham",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "CNTRCT-0000021",
      "items": "[4E1CFAD9,7B415250,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000009",
      "status": "Disrupted",
      "name": "VitalSigns Supplies",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000017",
      "items": "[049BCC88,44804BD5,C36BF121]"
    },
    {
      "id": "ORDR-0-0000010",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[200EA71C,BAF9CD1A,D33377F9]"
    },
    {
      "id": "ORDR-0-0000011",
      "status": "Hold",
      "name": "BioVital Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[049BCC88,B688BAF4,F55885BD]"
    },
    {
      "id": "ORDR-0-0000012",
      "status": "Disrupted",
      "name": "VitalCare Distribution",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000005, CNTRCT-0000031",
      "items": "[4CD72DAE,22633EF8,CB6FF511,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000013",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001",
      "items": "[7A8CC6BD,7B415250,74B98559]"
    },
    {
      "id": "ORDR-0-0000014",
      "status": "Accepted",
      "name": "CareBridge Equipment",
      "requestedBy": "Philip Brown",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[542BBC0E,14968630,CDA15978]"
    },
    {
      "id": "ORDR-0-0000015",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,049BCC89,E491B04D,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000016",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000, CNTRCT-0000024",
      "items": "[DC16E9E8,E80AAE4A,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000017",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000005",
      "items": "[DF6676C9,4E1CFAD10,C36BF122]"
    },
    {
      "id": "ORDR-0-0000018",
      "status": "Accepted",
      "name": "HealthHorizon Partners",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "",
      "items": "[200EA71C,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000019",
      "status": "Accepted",
      "name": "BioMedix International",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[E491B04D,A0E7F6C2,8607492C]"
    },
    {
      "id": "ORDR-0-0000020",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,9DD6903A,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000021",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000003",
      "items": "[19A12B20,9EAD0C20,3D26994F]"
    },
    {
      "id": "ORDR-0-0000022",
      "status": "Disrupted",
      "name": "CureCraft Technologies",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000006, CNTRCT-0000018",
      "items": "[44804BD4,9EAD0C20,F55885BD,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000023",
      "status": "Hold",
      "name": "BioHealth Systems",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000001, CNTRCT-0000003",
      "items": "[C36BF119,DB301E72,93D87235]"
    },
    {
      "id": "ORDR-0-0000024",
      "status": "Filled",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Michael Hall",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 2,
      "contract": "",
      "items": "[E0A95BBC,F55885BD,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000025",
      "status": "Pending",
      "name": "HealthStream Supplies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[C36BF119,02A8E857,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000026",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000020",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000027",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 4,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,8607492C,7A8CC6BD,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000028",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[7A8CC6BD,3D26994F,DF6676C11,7B415254]"
    },
    {
      "id": "ORDR-0-0000029",
      "status": "Pending",
      "name": "VitalNet Distribution",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[ACF7D73A,1EBC5379,93D87235]"
    },
    {
      "id": "ORDR-0-0000030",
      "status": "Disrupted",
      "name": "CureCraft Technologies",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,C36BF120,E491B04D,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000031",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[44804BD4,9EAD0C20,7B500D6B,099FDC51]"
    },
    {
      "id": "ORDR-0-0000032",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Christina Horton",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000033",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000000",
      "items": "[5EDCDAE3,7B415250,BD2DB5FE,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000034",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[9DD6903A,E80AAE4A,11102F49]"
    },
    {
      "id": "ORDR-0-0000035",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000027",
      "items": "[7B415249,74B98558,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000036",
      "status": "Hold",
      "name": "BioVital Supplies",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000007, CNTRCT-0000008",
      "items": "[4E1CFAD9,9FC8D91A,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000037",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "CNTRCT-0000002, CNTRCT-0000008",
      "items": "[049BCC88,049BCC89,F04529EA,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000038",
      "status": "Disrupted",
      "name": "CarePoint Technologies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,4FF22345,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000039",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[CCD70D1D,F55885BD,74B98559]"
    },
    {
      "id": "ORDR-0-0000040",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002, CNTRCT-0000012",
      "items": "[200EA71C,A0E7F6C2,E491B04D]"
    },
    {
      "id": "ORDR-0-0000041",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[E491B04D,58D36D80,099FDC51]"
    },
    {
      "id": "ORDR-0-0000042",
      "status": "Disrupted",
      "name": "BioCare Distribution",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000004, CNTRCT-0000014",
      "items": "[BAF9CD1A,F55885BD,F2EF4027,D9345863]"
    },
    {
      "id": "ORDR-0-0000043",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Natasha Perez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,7B415250,E491B04D]"
    },
    {
      "id": "ORDR-0-0000044",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Mary Khan",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008",
      "items": "[048701CE,85ECBAF6]"
    },
    {
      "id": "ORDR-0-0000045",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[7B415249,42A1729B,E491B04D]"
    },
    {
      "id": "ORDR-0-0000046",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000030",
      "items": "[D33377F5,02A8E857]"
    },
    {
      "id": "ORDR-0-0000047",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011",
      "items": "[58D36D79,E80AAE4A,8607492C]"
    },
    {
      "id": "ORDR-0-0000048",
      "status": "Hold",
      "name": "LifeCare Logistics",
      "requestedBy": "Karen Wright",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "",
      "items": "[4E1CFAD9,049BCC89,F2EF4027,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000049",
      "status": "Filled",
      "name": "MedLogistics Partners",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[5EDCDAE3,BD2DB5FE,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000050",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000017",
      "items": "[C36BF119,E0A5E11A,8607492C]"
    },
    {
      "id": "ORDR-0-0000051",
      "status": "Pending",
      "name": "HealthSupply Innovations",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001, CNTRCT-0000021",
      "items": "[D33377F5,DB07BD67,C36BF121]"
    },
    {
      "id": "ORDR-0-0000052",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000017",
      "items": "[049BCC88,42A1729B,F04529EA]"
    },
    {
      "id": "ORDR-0-0000053",
      "status": "Accepted",
      "name": "HealthStream Supplies",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[9FC8D91A,74B98558,74B98559]"
    },
    {
      "id": "ORDR-0-0000054",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[F04529EA,DB07BD67,D8F6DF56]"
    },
    {
      "id": "ORDR-0-0000055",
      "status": "Accepted",
      "name": "WellnessWare Group",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000004, CNTRCT-0000010, CNTRCT-0000028, CNTRCT-0000029",
      "items": "[BAF9CD1A,41097027]"
    },
    {
      "id": "ORDR-0-0000056",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[58D36D79,E80AAE4A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000057",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[B21A9DE2,E80AAE4A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000058",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[542BBC0E,7B415250,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000059",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000019",
      "items": "[049BCC88,42A1729B,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000060",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Michael Hall",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[B21A9DE2,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000061",
      "status": "Accepted",
      "name": "WellnessWare Group",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[F04529EA,E80AAE4A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000062",
      "status": "Accepted",
      "name": "LifeCare Logistics",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[9FC8D91A,E80AAE4A,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000063",
      "status": "Filled",
      "name": "VitalCare Distribution",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,DB07BD67]"
    },
    {
      "id": "ORDR-0-0000064",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000008",
      "items": "[049BCC88,E80AAE4A,8607492C]"
    },
    {
      "id": "ORDR-0-0000065",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000020, CNTRCT-0000025",
      "items": "[E491B04D,DC16E9E9]"
    },
    {
      "id": "ORDR-0-0000066",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000012",
      "items": "[200EA71C,E80AAE4A,B688BAF5,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000067",
      "status": "Filled",
      "name": "CarePoint Technologies",
      "requestedBy": "Scott Dean",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000019",
      "items": "[7A8CC6BD,9FC8D91A,DF6676C11,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000068",
      "status": "Disrupted",
      "name": "VitalSigns Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001",
      "items": "[B21A9DE2,44804BD5,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000069",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005",
      "items": "[B21A9DE2,E0A5E11A,CDA15978]"
    },
    {
      "id": "ORDR-0-0000070",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000018",
      "items": "[7B415249,44804BD5,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000071",
      "status": "Accepted",
      "name": "ProHealth Systems",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000010, CNTRCT-0000016",
      "items": "[200EA71C,F55885BD,E491B04D]"
    },
    {
      "id": "ORDR-0-0000072",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000073",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000026",
      "items": "[4FF22342,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000074",
      "status": "Disrupted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001, CNTRCT-0000007, CNTRCT-0000028",
      "items": "[E0A95BBC,D9345861,8607492C]"
    },
    {
      "id": "ORDR-0-0000075",
      "status": "Hold",
      "name": "ProHealth Systems",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000",
      "items": "[4E1CFAD9,A0E7F6C2,F2EF4027,DB301E75]"
    },
    {
      "id": "ORDR-0-0000076",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002",
      "items": "[C36BF119,02A8E857]"
    },
    {
      "id": "ORDR-0-0000077",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000003, CNTRCT-0000019, CNTRCT-0000029",
      "items": "[7D206E43,DB07BD67,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000078",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[200EA71C,A0E7F6C2,E491B04D]"
    },
    {
      "id": "ORDR-0-0000079",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000020",
      "items": "[19A12B20,8164C893,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000080",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011, CNTRCT-0000013, CNTRCT-0000024, CNTRCT-0000027",
      "items": "[4E1CFAD9,F55885BD,44804BD6,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000081",
      "status": "Pending",
      "name": "VitalCare Distribution",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "",
      "items": "[200EA71C,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000082",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[9FC8D91A,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000083",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4FF22342,D8F6DF55,B688BAF5]"
    },
    {
      "id": "ORDR-0-0000084",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[44804BD4,C36BF121]"
    },
    {
      "id": "ORDR-0-0000085",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000012",
      "items": "[E491B04D,44804BD5,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000086",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E0A95BBC,F55885BD,E491B04D]"
    },
    {
      "id": "ORDR-0-0000087",
      "status": "Disrupted",
      "name": "LifeCare Logistics",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000010",
      "items": "[44804BD4,330FF32E,9DD6903A]"
    },
    {
      "id": "ORDR-0-0000088",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[7A8CC6BD,BD2DB5FE,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000089",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,41097027,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000090",
      "status": "Filled",
      "name": "CarePoint Technologies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E0A95BBC,E0A5E11A,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000091",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "",
      "items": "[049BCC88,7B415250,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000092",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 1,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9]"
    },
    {
      "id": "ORDR-0-0000093",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000020",
      "items": "[C36BF119,02A8E857,17B2D2B2,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000094",
      "status": "Pending",
      "name": "VitalityGear Solutions",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,A0E7F6C2]"
    },
    {
      "id": "ORDR-0-0000095",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E0A95BBC,F55885BD,5EDCDAE5,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000096",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[9FC8D91A,A0E7F6C2,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000097",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[7B415249,42A1729B,CDA15978]"
    },
    {
      "id": "ORDR-0-0000098",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,42A1729B,8607492C]"
    },
    {
      "id": "ORDR-0-0000099",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000020",
      "items": "[0ED43D83,F55885BD,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000100",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000006",
      "items": "[E80AAE4A,DC16E9E9]"
    },
    {
      "id": "ORDR-0-0000101",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,14968630,ACF7D73A,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000102",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,CDA15978,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000103",
      "status": "Pending",
      "name": "VitalityGear Solutions",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000008",
      "items": "[E80AAE4A,85ECBAF6,D8F6DF56]"
    },
    {
      "id": "ORDR-0-0000104",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[E491B04D,BAF9CD1A,BD2DB5FE]"
    },
    {
      "id": "ORDR-0-0000105",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008, CNTRCT-0000013, CNTRCT-0000014",
      "items": "[88F245C1,DB301E72,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000106",
      "status": "Disrupted",
      "name": "HealthStream Supplies",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000008",
      "items": "[4CD72DAE,0ED43D84,11660B2E]"
    },
    {
      "id": "ORDR-0-0000107",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000019",
      "items": "[D33377F5,85ECBAF6,3D26994F,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000108",
      "status": "Accepted",
      "name": "VitalSigns Supplies",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000010",
      "items": "[D8F6DF54,330FF32E]"
    },
    {
      "id": "ORDR-0-0000109",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Scott Dean",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002, CNTRCT-0000008",
      "items": "[58D36D79,F36679BC]"
    },
    {
      "id": "ORDR-0-0000110",
      "status": "Accepted",
      "name": "MediSupply Chain",
      "requestedBy": "Michael Hall",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000004",
      "items": "[7B415249,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000111",
      "status": "Disrupted",
      "name": "WellnessWare Group",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[44804BD4,7B415250,3D26994F]"
    },
    {
      "id": "ORDR-0-0000112",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[D33377F5,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000113",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000008",
      "items": "[4CD72DAE,0ED43D84,E491B04D]"
    },
    {
      "id": "ORDR-0-0000114",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005, CNTRCT-0000007",
      "items": "[14968630,A0E7F6C2]"
    },
    {
      "id": "ORDR-0-0000115",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000015, CNTRCT-0000030",
      "items": "[E80AAE4A,88F245C2,14968630]"
    },
    {
      "id": "ORDR-0-0000116",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DF6676C9,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000117",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000016",
      "items": "[200EA71C,F55885BD,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000118",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[F2EF4025,330FF32E,8607492C]"
    },
    {
      "id": "ORDR-0-0000119",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000006, CNTRCT-0000020",
      "items": "[048701CE,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000120",
      "status": "Hold",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000000",
      "items": "[44804BD4,14968630]"
    },
    {
      "id": "ORDR-0-0000121",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,4E1CFAD13,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000122",
      "status": "Hold",
      "name": "VitalCare Distribution",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000028",
      "items": "[BAF9CD1A,9FC8D91A,44804BD6]"
    },
    {
      "id": "ORDR-0-0000123",
      "status": "Disrupted",
      "name": "CareFusion Services",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001, CNTRCT-0000008, CNTRCT-0000024",
      "items": "[7B415249,F55885BD,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000124",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004",
      "items": "[F04529EA,11102F48,9C01A6AA]"
    },
    {
      "id": "ORDR-0-0000125",
      "status": "Filled",
      "name": "BioHealth Systems",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[19A12B20,F55885BD,44804BD6]"
    },
    {
      "id": "ORDR-0-0000126",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[E491B04D,E80AAE4A,3D26994F]"
    },
    {
      "id": "ORDR-0-0000127",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D9345860,42A1729B,F2EF4027,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000128",
      "status": "Filled",
      "name": "WellnessWare Group",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000004",
      "items": "[7B415249,F55885BD,11660B2E,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000129",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[11660B2E,DC16E9E9,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000130",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,DB07BD67,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000131",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Roger Graham",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,DB301E73,C36BF122,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000132",
      "status": "Accepted",
      "name": "CareBridge Equipment",
      "requestedBy": "Michael Hall",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000020",
      "items": "[4FF22342,E80AAE4A,11660B2E,4FF22345]"
    },
    {
      "id": "ORDR-0-0000133",
      "status": "Accepted",
      "name": "BioCare Distribution",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000009",
      "items": "[200EA71C,4E1CFAD10,11660B2E,C36BF122,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000134",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000010, CNTRCT-0000021",
      "items": "[BAF9CD1A,F36679BC,44804BD6,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000135",
      "status": "Accepted",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[8164C892,44804BD5,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000136",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[542BBC0E,599EA2B4,F36679BC,D9345863]"
    },
    {
      "id": "ORDR-0-0000137",
      "status": "Disrupted",
      "name": "HealthWave Technologies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002",
      "items": "[44804BD4,DB301E72,8607492C]"
    },
    {
      "id": "ORDR-0-0000138",
      "status": "Hold",
      "name": "HealthWave Technologies",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[049BCC88,065DB72B]"
    },
    {
      "id": "ORDR-0-0000139",
      "status": "Hold",
      "name": "CureCraft Technologies",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[42A1729B,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000140",
      "status": "Pending",
      "name": "MediSupply Chain",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000029",
      "items": "[048701CE,0ED43D84,E491B04D,4FF22345]"
    },
    {
      "id": "ORDR-0-0000141",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000006",
      "items": "[542BBC0E,C36BF120,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000142",
      "status": "Hold",
      "name": "CarePoint Technologies",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008",
      "items": "[ACF7D73A,D33377F6,11660B2E]"
    },
    {
      "id": "ORDR-0-0000143",
      "status": "Disrupted",
      "name": "PurePath Equipment",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,F55885BD]"
    },
    {
      "id": "ORDR-0-0000144",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000015",
      "items": "[E491B04D,A0E7F6C2,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000145",
      "status": "Filled",
      "name": "ProHealth Systems",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F2EF4025,66AB5D2E,C36BF121]"
    },
    {
      "id": "ORDR-0-0000146",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003",
      "items": "[79A6C1CA,330FF32E,8164C894]"
    },
    {
      "id": "ORDR-0-0000147",
      "status": "Accepted",
      "name": "CareBridge Equipment",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000029",
      "items": "[F2EF4025,F36679BC,CCD70D1D,C36BF122]"
    },
    {
      "id": "ORDR-0-0000148",
      "status": "Pending",
      "name": "WellnessWare Group",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[0ED43D83,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000149",
      "status": "Hold",
      "name": "BioMedix International",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000030",
      "items": "[4E1CFAD9,41097027,3D26994F]"
    },
    {
      "id": "ORDR-0-0000150",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DF6676C9,C36BF120,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000151",
      "status": "Accepted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,DB07BD67,14968630]"
    },
    {
      "id": "ORDR-0-0000152",
      "status": "Disrupted",
      "name": "HealthStream Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "",
      "items": "[A0E7F6C1,B688BAF4,7A8CC6BD,D9345863,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000153",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000003, CNTRCT-0000029",
      "items": "[DC16E9E8,F55885BD,7A8CC6BD,DB301E75]"
    },
    {
      "id": "ORDR-0-0000154",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001, CNTRCT-0000008",
      "items": "[9FC8D91A,F55885BD,B688BAF5,ACF7D73A,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000155",
      "status": "Accepted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000006",
      "items": "[B21A9DE2,7B415250,F55885BD]"
    },
    {
      "id": "ORDR-0-0000156",
      "status": "Pending",
      "name": "BioHealth Systems",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,11102F48,CDA15978]"
    },
    {
      "id": "ORDR-0-0000157",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000016",
      "items": "[44804BD4,D33377F6]"
    },
    {
      "id": "ORDR-0-0000158",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[58D36D79,DB07BD67,F55885BD,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000159",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000021",
      "items": "[B21A9DE2,F55885BD,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000160",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000021",
      "items": "[7B415249,A0E7F6C2,17B2D2B2,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000161",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[58D36D79,7B500D6B,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000162",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[DF6676C9,BAF9CD1A,CD9A49D2,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000163",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000000",
      "items": "[D33377F5,7B500D6B,17B2D2B2,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000164",
      "status": "Filled",
      "name": "HealthHorizon Partners",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000010",
      "items": "[44804BD4,44804BD5,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000165",
      "status": "Pending",
      "name": "HealthSupply Innovations",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[85ECBAF5,CDA15977,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000166",
      "status": "Disrupted",
      "name": "PharmaPoint International",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002",
      "items": "[B688BAF3,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000167",
      "status": "Disrupted",
      "name": "HealthWave Technologies",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000008, CNTRCT-0000020",
      "items": "[E491B04D,C36BF120,17B2D2B2,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000168",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,E0A5E11A,F04529EA]"
    },
    {
      "id": "ORDR-0-0000169",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[7B415249,E80AAE4A,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000170",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[C36BF119,88F245C2,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000171",
      "status": "Hold",
      "name": "CareBridge Equipment",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[88F245C1,11102F49]"
    },
    {
      "id": "ORDR-0-0000172",
      "status": "Accepted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000018",
      "items": "[4FF22342,BAF9CD1A]"
    },
    {
      "id": "ORDR-0-0000173",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 1,
      "contract": "",
      "items": "[DC16E9E8,8164C893,8607492C,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000174",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Natasha Perez",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "",
      "items": "[F04529EA,065DB72B,CB6FF511,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000175",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000005",
      "items": "[9DD6903A,7B500D6B,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000176",
      "status": "Hold",
      "name": "BioHealth Systems",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[7A8CC6BD,4E1CFAD10,CCD70D1D,D33377F9]"
    },
    {
      "id": "ORDR-0-0000177",
      "status": "Disrupted",
      "name": "CareFusion Services",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[DC16E9E8,D33377F6,E491B04D]"
    },
    {
      "id": "ORDR-0-0000178",
      "status": "Accepted",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,42A1729B,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000179",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Emily Graves",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000020",
      "items": "[E80AAE4A,E80AAE4A,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000180",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005, CNTRCT-0000011",
      "items": "[19A12B20,DB301E72]"
    },
    {
      "id": "ORDR-0-0000181",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001, CNTRCT-0000010",
      "items": "[200EA71C,F55885BD,542BBC0E,DB301E75]"
    },
    {
      "id": "ORDR-0-0000182",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 2,
      "contract": "",
      "items": "[E491B04D,F55885BD,F04529EA,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000183",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000184",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000009",
      "items": "[049BCC88,DB301E72,CDA15978]"
    },
    {
      "id": "ORDR-0-0000185",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,DB301E72]"
    },
    {
      "id": "ORDR-0-0000186",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000029",
      "items": "[4FF22342,D33377F6,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000187",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Natasha Perez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[11660B2E,DB07BD67,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000188",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[7A8CC6BD,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000189",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[DF6676C9,E80AAE4A,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000190",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000008",
      "items": "[5EDCDAE3,85ECBAF6,CB6FF511,DB301E75]"
    },
    {
      "id": "ORDR-0-0000191",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Roger Graham",
      "itemsTotal": 1,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000009, CNTRCT-0000017",
      "items": "[58D36D79]"
    },
    {
      "id": "ORDR-0-0000192",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005",
      "items": "[44804BD4,D33377F6,8607492C,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000193",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000027",
      "items": "[7A8CC6BD,065DB72B,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000194",
      "status": "Filled",
      "name": "VitalCare Distribution",
      "requestedBy": "Chris Harris",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000007, CNTRCT-0000027",
      "items": "[E491B04D,9C01A6AA,ACF7D73A,D33377F9]"
    },
    {
      "id": "ORDR-0-0000195",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001, CNTRCT-0000009",
      "items": "[DF6676C9,E0A5E11A,93D87235]"
    },
    {
      "id": "ORDR-0-0000196",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,E80AAE4A,8607492C]"
    },
    {
      "id": "ORDR-0-0000197",
      "status": "Pending",
      "name": "CareBridge Equipment",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[58D36D79,DB07BD67,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000198",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DB07BD66,02A8E857,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000199",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,B688BAF4,C36BF121]"
    },
    {
      "id": "ORDR-0-0000200",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[4E1CFAD9,1EBC5379,11660B2E,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000201",
      "status": "Accepted",
      "name": "HealthSupply Innovations",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[E80AAE4A,58D36D80,CDA15978]"
    },
    {
      "id": "ORDR-0-0000202",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[44804BD4,E0A5E11A,79A6C1CA,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000203",
      "status": "Pending",
      "name": "HealthSupply Innovations",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[CCD70D1D,42A1729B]"
    },
    {
      "id": "ORDR-0-0000204",
      "status": "Filled",
      "name": "VitalNet Distribution",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[200EA71C,22633EF8,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000205",
      "status": "Disrupted",
      "name": "BioHealth Systems",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000010",
      "items": "[19A12B20,7B500D6B,5EDCDAE5]"
    },
    {
      "id": "ORDR-0-0000206",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000207",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000",
      "items": "[049BCC88,3D26994F,3D26994F]"
    },
    {
      "id": "ORDR-0-0000208",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000017",
      "items": "[4E1CFAD9,F55885BD,CD9A49D2,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000209",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Mary Durham",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "",
      "items": "[200EA71C,F55885BD,11660B2E,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000210",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "John Miller",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[542BBC0E,85ECBAF6]"
    },
    {
      "id": "ORDR-0-0000211",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Chris Harris",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000017",
      "items": "[44804BD4,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000212",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[CCD70D1D,C36BF120,E491B04D]"
    },
    {
      "id": "ORDR-0-0000213",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[542BBC0E,1EBC5379,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000214",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000016",
      "items": "[88F245C1,BAF9CD1A,8164C894]"
    },
    {
      "id": "ORDR-0-0000215",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005",
      "items": "[19A12B20,11102F48,E491B04D]"
    },
    {
      "id": "ORDR-0-0000216",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000000",
      "items": "[D33377F5,7B415250,E491B04D]"
    },
    {
      "id": "ORDR-0-0000217",
      "status": "Pending",
      "name": "PurePath Equipment",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000013",
      "items": "[E491B04D,8164C893]"
    },
    {
      "id": "ORDR-0-0000218",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7B415249,9FC8D91A,9C01A6AA]"
    },
    {
      "id": "ORDR-0-0000219",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008, CNTRCT-0000024",
      "items": "[F2EF4025,02A8E857]"
    },
    {
      "id": "ORDR-0-0000220",
      "status": "Disrupted",
      "name": "BioHealth Systems",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005",
      "items": "[88F245C1,DB07BD68,D9345863]"
    },
    {
      "id": "ORDR-0-0000221",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Christina Horton",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,542BBC0E,C36BF122,DB301E75]"
    },
    {
      "id": "ORDR-0-0000222",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000010",
      "items": "[58D36D79,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000223",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[0ED43D83,C36BF120,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000224",
      "status": "Hold",
      "name": "Medique Innovations",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001, CNTRCT-0000028",
      "items": "[9DD6903A,7B415250,F2EF4027,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000225",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,F36679BC,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000226",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000017",
      "items": "[0AB87239,3D26994F,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000227",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[42A1729B,3D26994F,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000228",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[C36BF119,E80AAE4A,D8F6DF56]"
    },
    {
      "id": "ORDR-0-0000229",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[66AB5D2E,C36BF120,C36BF121,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000230",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Mary Khan",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[5EDCDAE3,3D26994F,CD9A49D2,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000231",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[B21A9DE2,F55885BD,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000232",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000028",
      "items": "[19A12B20,DB301E72,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000233",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "",
      "items": "[DF6676C9,599EA2B4,F2EF4027,ACF7D73A,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000234",
      "status": "Pending",
      "name": "LifeCare Logistics",
      "requestedBy": "Michael Hall",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000010",
      "items": "[7B415249,41097027,79A6C1CA,D9345863]"
    },
    {
      "id": "ORDR-0-0000235",
      "status": "Disrupted",
      "name": "BioMedix International",
      "requestedBy": "Michael Hall",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[DC16E9E8,B688BAF4,F55885BD,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000236",
      "status": "Hold",
      "name": "VitalCare Distribution",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000006",
      "items": "[ACF7D73A,44804BD6]"
    },
    {
      "id": "ORDR-0-0000237",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[D9345860,7B415250,E491B04D,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000238",
      "status": "Pending",
      "name": "Medique Innovations",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000029",
      "items": "[9FC8D91A,C36BF120,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000239",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9,02A8E857]"
    },
    {
      "id": "ORDR-0-0000240",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,065DB72B,8164C894]"
    },
    {
      "id": "ORDR-0-0000241",
      "status": "Hold",
      "name": "Medique Innovations",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000017, CNTRCT-0000029",
      "items": "[9DD6903A,14968630,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000242",
      "status": "Disrupted",
      "name": "VitalNet Distribution",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000006",
      "items": "[048701CE,F36679BC,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000243",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000012",
      "items": "[42A1729B,7B415250,CB6FF511,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000244",
      "status": "Disrupted",
      "name": "CareBridge Equipment",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000031",
      "items": "[E0A95BBC,A0E7F6C2,CDA15978]"
    },
    {
      "id": "ORDR-0-0000245",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[BAF9CD1A,B688BAF4,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000246",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[CCD70D1D,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000247",
      "status": "Pending",
      "name": "HealthAxis Supplies",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001, CNTRCT-0000005",
      "items": "[D8F6DF54,3D26994F,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000248",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[44804BD4,02A8E857]"
    },
    {
      "id": "ORDR-0-0000249",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000017",
      "items": "[DC16E9E8,F55885BD,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000250",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005, CNTRCT-0000007, CNTRCT-0000010, CNTRCT-0000012, CNTRCT-0000027, CNTRCT-0000029",
      "items": "[19A12B20,7B500D6B,F55885BD]"
    },
    {
      "id": "ORDR-0-0000251",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Michael Hall",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003",
      "items": "[7B415249,049BCC89,E491B04D,ACF7D73A,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000252",
      "status": "Accepted",
      "name": "PharmaPoint International",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000000, CNTRCT-0000015",
      "items": "[0AB87239,A0E7F6C2]"
    },
    {
      "id": "ORDR-0-0000253",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,C36BF120,11660B2E,ACF7D73A,D33377F9]"
    },
    {
      "id": "ORDR-0-0000254",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[542BBC0E,74B98558,ACF7D73A,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000255",
      "status": "Disrupted",
      "name": "HealthStream Supplies",
      "requestedBy": "Scott Dean",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000017",
      "items": "[D8F6DF54,DB301E72,CDA15978,099FDC51]"
    },
    {
      "id": "ORDR-0-0000256",
      "status": "Hold",
      "name": "LifeLine Supplies",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[9DD6903A,BAF9CD1A,8164C894]"
    },
    {
      "id": "ORDR-0-0000257",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001, CNTRCT-0000003",
      "items": "[9DD6903A,A0E7F6C2,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000258",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,88F245C2,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000259",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000022, CNTRCT-0000024",
      "items": "[58D36D79,F55885BD,E491B04D]"
    },
    {
      "id": "ORDR-0-0000260",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[4E1CFAD9,44804BD5,EB58F892]"
    },
    {
      "id": "ORDR-0-0000261",
      "status": "Filled",
      "name": "LifeCare Logistics",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[200EA71C,C36BF120,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000262",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[66AB5D2E,7B500D6B,BD23BA18,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000263",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[66AB5D2E,66AB5D2E,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000264",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[F04529EA,BD2DB5FE,CDA15978]"
    },
    {
      "id": "ORDR-0-0000265",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[F04529EA,BAF9CD1A]"
    },
    {
      "id": "ORDR-0-0000266",
      "status": "Disrupted",
      "name": "HealthStream Supplies",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[B688BAF3,C36BF120,BD2DB5FE]"
    },
    {
      "id": "ORDR-0-0000267",
      "status": "Disrupted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[D33377F5,0ED43D84,8607492C]"
    },
    {
      "id": "ORDR-0-0000268",
      "status": "Accepted",
      "name": "ProHealth Systems",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[9FC8D91A,BD2DB5FE,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000269",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000003, CNTRCT-0000006",
      "items": "[4E1CFAD9,F55885BD,DB301E75,7B415254]"
    },
    {
      "id": "ORDR-0-0000270",
      "status": "Filled",
      "name": "CureCraft Technologies",
      "requestedBy": "Mary Durham",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[F2EF4025,58D36D80,17B2D2B2,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000271",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[44804BD4,42A1729B,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000272",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004, CNTRCT-0000028",
      "items": "[4CD72DAE,958C4CED,E491B04D]"
    },
    {
      "id": "ORDR-0-0000273",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[CCD70D1D,0ED43D84,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000274",
      "status": "Accepted",
      "name": "CureCraft Technologies",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,7B415254]"
    },
    {
      "id": "ORDR-0-0000275",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,DB301E72,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000276",
      "status": "Accepted",
      "name": "HealthHorizon Partners",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[DC16E9E8,F55885BD,DB301E73,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000277",
      "status": "Filled",
      "name": "BioVital Supplies",
      "requestedBy": "Philip Brown",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005, CNTRCT-0000008, CNTRCT-0000030",
      "items": "[44804BD4,7B500D6B,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000278",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000002, CNTRCT-0000009, CNTRCT-0000028",
      "items": "[F2EF4025,E0A5E11A,44804BD6]"
    },
    {
      "id": "ORDR-0-0000279",
      "status": "Hold",
      "name": "LifeLine Supplies",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,42A1729B,44804BD6]"
    },
    {
      "id": "ORDR-0-0000280",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000024, CNTRCT-0000025",
      "items": "[C36BF119,B688BAF4,17B2D2B2,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000281",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000, CNTRCT-0000009, CNTRCT-0000018",
      "items": "[7B415249,74B98558,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000282",
      "status": "Filled",
      "name": "CarePoint Technologies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001",
      "items": "[049BCC88,D8F6DF55,CDA15978]"
    },
    {
      "id": "ORDR-0-0000283",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000028",
      "items": "[C36BF119,02A8E857,F04529EA,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000284",
      "status": "Filled",
      "name": "CareBridge Equipment",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[C36BF119,330FF32E]"
    },
    {
      "id": "ORDR-0-0000285",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "CNTRCT-0000008",
      "items": "[200EA71C,F36679BC,F55885BD,4FF22345,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000286",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Amy Davis",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7A8CC6BD,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000287",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,58D36D80,B688BAF5,D8F6DF58]"
    },
    {
      "id": "ORDR-0-0000288",
      "status": "Disrupted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[B688BAF3,0ED43D84,8607492C]"
    },
    {
      "id": "ORDR-0-0000289",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,E80AAE4A,C36BF121]"
    },
    {
      "id": "ORDR-0-0000290",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000009, CNTRCT-0000011, CNTRCT-0000029",
      "items": "[F2EF4025,1EBC5379,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000291",
      "status": "Filled",
      "name": "BioHealth Systems",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000025",
      "items": "[DC16E9E8,E80AAE4A,8607492C]"
    },
    {
      "id": "ORDR-0-0000292",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[542BBC0E,F36679BC,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000293",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "CNTRCT-0000003, CNTRCT-0000005, CNTRCT-0000010, CNTRCT-0000031",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000294",
      "status": "Pending",
      "name": "HealthStream Supplies",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000020",
      "items": "[44804BD4,C36BF120,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000295",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[CCD70D1D,BAF9CD1A,CD9A49D2,099FDC51]"
    },
    {
      "id": "ORDR-0-0000296",
      "status": "Disrupted",
      "name": "CarePoint Technologies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000026",
      "items": "[C36BF119,F36679BC,F2EF4027,D33377F9]"
    },
    {
      "id": "ORDR-0-0000297",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,E491B04D,5EDCDAE6,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000298",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[8164C892,8164C893,F04529EA]"
    },
    {
      "id": "ORDR-0-0000299",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[D8F6DF54,44804BD5]"
    },
    {
      "id": "ORDR-0-0000300",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[DC16E9E8,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000301",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001, CNTRCT-0000003",
      "items": "[58D36D79,3D26994F]"
    },
    {
      "id": "ORDR-0-0000302",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[14968630,7B415250,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000303",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E0A95BBC,42A1729B,C36BF121]"
    },
    {
      "id": "ORDR-0-0000304",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000011",
      "items": "[4E1CFAD9,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000305",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001",
      "items": "[9FC8D91A,66AB5D2E,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000306",
      "status": "Accepted",
      "name": "VitalSigns Supplies",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D9345860,02A8E857,5EDCDAE5]"
    },
    {
      "id": "ORDR-0-0000307",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Michael Hall",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000019",
      "items": "[4E1CFAD9,7B415250,F55885BD,DB301E75]"
    },
    {
      "id": "ORDR-0-0000308",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E491B04D,DB301E72,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000309",
      "status": "Hold",
      "name": "VitalCare Distribution",
      "requestedBy": "Mary Durham",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 5,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000028",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000310",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[44804BD4,22633EF8,542BBC0E,4FF22345]"
    },
    {
      "id": "ORDR-0-0000311",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000031",
      "items": "[4E1CFAD9,F36679BC,8607492C]"
    },
    {
      "id": "ORDR-0-0000312",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000019, CNTRCT-0000026",
      "items": "[44804BD4,1EBC5379,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000313",
      "status": "Hold",
      "name": "CareBridge Equipment",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[A0E7F6C1,E80AAE4A,CDA15978]"
    },
    {
      "id": "ORDR-0-0000314",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000005",
      "items": "[66AB5D2E,330FF32E]"
    },
    {
      "id": "ORDR-0-0000315",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[58D36D79,9FC8D91A,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000316",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000004, CNTRCT-0000026, CNTRCT-0000030",
      "items": "[048701CE,7B415250,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000317",
      "status": "Pending",
      "name": "VitalNet Distribution",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "",
      "items": "[200EA71C,F55885BD,DF6676C11,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000318",
      "status": "Accepted",
      "name": "CareBridge Equipment",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000005",
      "items": "[4E1CFAD9,9DD6903A,F2EF4027,DC16E9E12,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000319",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7D206E43,22633EF8,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000320",
      "status": "Hold",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[44804BD4,049BCC89,F55885BD]"
    },
    {
      "id": "ORDR-0-0000321",
      "status": "Accepted",
      "name": "HealthSupply Innovations",
      "requestedBy": "John Miller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003",
      "items": "[4E1CFAD9,22633EF8,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000322",
      "status": "Pending",
      "name": "LifeCare Logistics",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003, CNTRCT-0000020",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,DB301E75]"
    },
    {
      "id": "ORDR-0-0000323",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000000, CNTRCT-0000003, CNTRCT-0000005, CNTRCT-0000024",
      "items": "[A0E7F6C1,330FF32E,2C1769CF,DB301E75]"
    },
    {
      "id": "ORDR-0-0000324",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[14968630,BAF9CD1A,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000325",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[049BCC88,599EA2B4,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000326",
      "status": "Hold",
      "name": "BioMedix International",
      "requestedBy": "John Miller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[44804BD4,7B415250,DB301E73]"
    },
    {
      "id": "ORDR-0-0000327",
      "status": "Pending",
      "name": "PurePath Equipment",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000014",
      "items": "[E491B04D,F55885BD,C36BF121,D8F6DF58]"
    },
    {
      "id": "ORDR-0-0000328",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,C36BF120,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000329",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000022",
      "items": "[7B415249,85ECBAF6,C36BF121]"
    },
    {
      "id": "ORDR-0-0000330",
      "status": "Filled",
      "name": "CarePoint Technologies",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000",
      "items": "[4E1CFAD9,D9345861]"
    },
    {
      "id": "ORDR-0-0000331",
      "status": "Accepted",
      "name": "CareFusion Services",
      "requestedBy": "Ann Perry",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000003, CNTRCT-0000018, CNTRCT-0000028, CNTRCT-0000029",
      "items": "[7B415249,9EAD0C20]"
    },
    {
      "id": "ORDR-0-0000332",
      "status": "Hold",
      "name": "HealthSupply Innovations",
      "requestedBy": "Karen Wright",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001",
      "items": "[049BCC88,F36679BC]"
    },
    {
      "id": "ORDR-0-0000333",
      "status": "Disrupted",
      "name": "WellnessWare Group",
      "requestedBy": "Natasha Perez",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000000",
      "items": "[4E1CFAD9,F55885BD,11660B2E,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000334",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4FF22342,7B500D6B,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000335",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,02A8E857,F55885BD]"
    },
    {
      "id": "ORDR-0-0000336",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[B21A9DE2,66AB5D2E,11660B2E,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000337",
      "status": "Hold",
      "name": "ProHealth Systems",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[D9345860,3D26994F]"
    },
    {
      "id": "ORDR-0-0000338",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000019",
      "items": "[7D206E43,DC16E9E9,F2EF4027,ACF7D73A,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000339",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[F2EF4025,66AB5D2E]"
    },
    {
      "id": "ORDR-0-0000340",
      "status": "Disrupted",
      "name": "HealthHorizon Partners",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[A0E7F6C1,7B415250,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000341",
      "status": "Hold",
      "name": "HealthWave Technologies",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,85ECBAF6,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000342",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Chris Harris",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F1C1E748,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000343",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[B688BAF3,7B415250,9C01A6AA]"
    },
    {
      "id": "ORDR-0-0000344",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,D8F6DF55,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000345",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000013",
      "items": "[4E1CFAD9,F55885BD,BD23BA18,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000346",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000347",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000004, CNTRCT-0000006",
      "items": "[44804BD4,C36BF120,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000348",
      "status": "Disrupted",
      "name": "BioCare Distribution",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011",
      "items": "[D33377F5,11102F48,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000349",
      "status": "Filled",
      "name": "PharmaPoint International",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000011",
      "items": "[D33377F5,7B415250,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000350",
      "status": "Accepted",
      "name": "CareFusion Services",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000027",
      "items": "[7B415249,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000351",
      "status": "Pending",
      "name": "BioHealth Systems",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7B415249,F2EF4027,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000352",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000019",
      "items": "[CCD70D1D,E80AAE4A,F2EF4027,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000353",
      "status": "Disrupted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Michael Hall",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000025, CNTRCT-0000026",
      "items": "[D8F6DF54,14968630]"
    },
    {
      "id": "ORDR-0-0000354",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000, CNTRCT-0000007",
      "items": "[049BCC88,66AB5D2E,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000355",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000011, CNTRCT-0000030",
      "items": "[D9345860,F55885BD,BD23BA18,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000356",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,B688BAF4]"
    },
    {
      "id": "ORDR-0-0000357",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Mary Khan",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,8607492C]"
    },
    {
      "id": "ORDR-0-0000358",
      "status": "Accepted",
      "name": "ProHealth Systems",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "",
      "items": "[44804BD4,E0A5E11A,17B2D2B2,D9345863,DB301E75]"
    },
    {
      "id": "ORDR-0-0000359",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Karen Wright",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "",
      "items": "[D8F6DF54,4E1CFAD10,93D87235,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000360",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[DC16E9E8,F55885BD,F55885BD]"
    },
    {
      "id": "ORDR-0-0000361",
      "status": "Accepted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4FF22342,C36BF120,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000362",
      "status": "Disrupted",
      "name": "CureTech Innovations",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000002",
      "items": "[58D36D79,7B415250,8607492C]"
    },
    {
      "id": "ORDR-0-0000363",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005, CNTRCT-0000010",
      "items": "[F2EF4025,D33377F6,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000364",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000000",
      "items": "[44804BD4,F55885BD,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000365",
      "status": "Accepted",
      "name": "MedLogistics Partners",
      "requestedBy": "Mary Khan",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[DC16E9E8,D9345861,CD9A49D2,4FF22345]"
    },
    {
      "id": "ORDR-0-0000366",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[A0E7F6C1,11102F48,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000367",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008",
      "items": "[A0E7F6C1,A0E7F6C2,CDA15978]"
    },
    {
      "id": "ORDR-0-0000368",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[7A8CC6BD,7B415250,C36BF121]"
    },
    {
      "id": "ORDR-0-0000369",
      "status": "Pending",
      "name": "VitalSigns Supplies",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[DC16E9E8,BD2DB5FE,E491B04D,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000370",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[A0E7F6C1,E80AAE4A,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000371",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000002",
      "items": "[DF6676C9,9FC8D91A,74B98559]"
    },
    {
      "id": "ORDR-0-0000372",
      "status": "Pending",
      "name": "VitalCare Distribution",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D8F6DF54,8164C893,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000373",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000019",
      "items": "[D8F6DF54,BD2DB5FE,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000374",
      "status": "Filled",
      "name": "CareBridge Equipment",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000028",
      "items": "[ACF7D73A,41097027]"
    },
    {
      "id": "ORDR-0-0000375",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004",
      "items": "[0AB87239,E80AAE4A,93D87235]"
    },
    {
      "id": "ORDR-0-0000376",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000",
      "items": "[200EA71C,BAF9CD1A,F04529EA]"
    },
    {
      "id": "ORDR-0-0000377",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000001, CNTRCT-0000005, CNTRCT-0000020",
      "items": "[DC16E9E8,66AB5D2E,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000378",
      "status": "Accepted",
      "name": "HealthHorizon Partners",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000009",
      "items": "[4E1CFAD9,BAF9CD1A,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000379",
      "status": "Disrupted",
      "name": "BioMedix International",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000003",
      "items": "[58D36D79,F36679BC,79A6C1CA,7A8CC6BD,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000380",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000027",
      "items": "[D8F6DF54,DB07BD67,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000381",
      "status": "Pending",
      "name": "VitalityGear Solutions",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000019",
      "items": "[4E1CFAD9,7B415250,B688BAF5]"
    },
    {
      "id": "ORDR-0-0000382",
      "status": "Pending",
      "name": "CureCraft Technologies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000013",
      "items": "[A0E7F6C1,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000383",
      "status": "Disrupted",
      "name": "BioHealth Systems",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[F2EF4025,02A8E857,F04529EA]"
    },
    {
      "id": "ORDR-0-0000384",
      "status": "Pending",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[8164C892,DC16E9E9,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000385",
      "status": "Pending",
      "name": "HealthStream Supplies",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[200EA71C,1EBC5379,F04529EA]"
    },
    {
      "id": "ORDR-0-0000386",
      "status": "Hold",
      "name": "LifeLine Supplies",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[7B415249,E0A5E11A,8607492C]"
    },
    {
      "id": "ORDR-0-0000387",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Roger Graham",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000388",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[DF6676C9,22633EF8,DB301E73]"
    },
    {
      "id": "ORDR-0-0000389",
      "status": "Pending",
      "name": "VitalNet Distribution",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DF6676C9,B688BAF4,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000390",
      "status": "Filled",
      "name": "CureCraft Technologies",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[44804BD4,A0E7F6C2,BD2DB5FE]"
    },
    {
      "id": "ORDR-0-0000391",
      "status": "Pending",
      "name": "CureTech Innovations",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,F36679BC,93D87235]"
    },
    {
      "id": "ORDR-0-0000392",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005, CNTRCT-0000021",
      "items": "[049BCC88,BD2DB5FE,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000393",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000021",
      "items": "[44804BD4,E80AAE4A,D9345863]"
    },
    {
      "id": "ORDR-0-0000394",
      "status": "Pending",
      "name": "CarePoint Technologies",
      "requestedBy": "Philip Brown",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000006",
      "items": "[8164C892,E80AAE4A,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000395",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008, CNTRCT-0000025",
      "items": "[88F245C1,E491B04D]"
    },
    {
      "id": "ORDR-0-0000396",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000005",
      "items": "[DC16E9E8,BAF9CD1A,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000397",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Roger Graham",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000010",
      "items": "[4E1CFAD9,F55885BD,74B98559,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000398",
      "status": "Pending",
      "name": "CarePoint Technologies",
      "requestedBy": "Mary Durham",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[0ED43D83,F36679BC]"
    },
    {
      "id": "ORDR-0-0000399",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000019",
      "items": "[DF6676C9,A0E7F6C2,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000400",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[E491B04D,7B415250,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000401",
      "status": "Disrupted",
      "name": "CureTech Innovations",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D8F6DF54,DB07BD67,CDA15978]"
    },
    {
      "id": "ORDR-0-0000402",
      "status": "Accepted",
      "name": "BioCare Distribution",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4FF22342,9DD6903A,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000403",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000019",
      "items": "[7B415249,958C4CED,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000404",
      "status": "Disrupted",
      "name": "VitalCare Distribution",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000012",
      "items": "[19A12B20,3D26994F,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000405",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4CD72DAE,958C4CED,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000406",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 4,
      "contract": "",
      "items": "[E491B04D,F55885BD,F2EF4027,ACF7D73A,4E1CFAD13,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000407",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7B415249,7B415250,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000408",
      "status": "Filled",
      "name": "LifeCare Logistics",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7A8CC6BD,065DB72B,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000409",
      "status": "Accepted",
      "name": "MedLogistics Partners",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005, CNTRCT-0000030",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,D33377F9]"
    },
    {
      "id": "ORDR-0-0000410",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000411",
      "status": "Pending",
      "name": "HealthSupply Innovations",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004, CNTRCT-0000024",
      "items": "[9DD6903A,7B500D6B,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000412",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7D206E43,330FF32E,8607492C]"
    },
    {
      "id": "ORDR-0-0000413",
      "status": "Hold",
      "name": "HealthStream Supplies",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008",
      "items": "[E80AAE4A,CDA15978]"
    },
    {
      "id": "ORDR-0-0000414",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[11660B2E,DB07BD67,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000415",
      "status": "Disrupted",
      "name": "WellnessWare Group",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000014",
      "items": "[5EDCDAE3,14968630,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000416",
      "status": "Accepted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[B688BAF3,C36BF120,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000417",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000008",
      "items": "[42A1729B,F36679BC,DB301E73]"
    },
    {
      "id": "ORDR-0-0000418",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[049BCC88,7B415250,E491B04D]"
    },
    {
      "id": "ORDR-0-0000419",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000009",
      "items": "[58D36D79,065DB72B,93D87235,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000420",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005",
      "items": "[4FF22342,DB07BD67,F55885BD]"
    },
    {
      "id": "ORDR-0-0000421",
      "status": "Hold",
      "name": "WellnessWare Group",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,B688BAF4]"
    },
    {
      "id": "ORDR-0-0000422",
      "status": "Pending",
      "name": "LifeCare Logistics",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000000",
      "items": "[F04529EA,22633EF8]"
    },
    {
      "id": "ORDR-0-0000423",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Christina Horton",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[D8F6DF54,BD2DB5FE,C36BF121]"
    },
    {
      "id": "ORDR-0-0000424",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[C36BF119,F36679BC,C36BF121]"
    },
    {
      "id": "ORDR-0-0000425",
      "status": "Filled",
      "name": "BioVital Supplies",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000006",
      "items": "[E491B04D,8164C893,F55885BD]"
    },
    {
      "id": "ORDR-0-0000426",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Roger Graham",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000021",
      "items": "[DC16E9E8,F36679BC,CD9A49D2,099FDC51,DB301E75]"
    },
    {
      "id": "ORDR-0-0000427",
      "status": "Hold",
      "name": "CarePoint Technologies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[8164C892,F55885BD,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000428",
      "status": "Pending",
      "name": "VitalSigns Supplies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[4E1CFAD9,599EA2B4,CDA15978,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000429",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000022",
      "items": "[B688BAF3,8164C893]"
    },
    {
      "id": "ORDR-0-0000430",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Karen Wright",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000005, CNTRCT-0000006",
      "items": "[542BBC0E,D8F6DF55,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000431",
      "status": "Accepted",
      "name": "ProHealth Systems",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000009",
      "items": "[DB07BD66,330FF32E,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000432",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000000, CNTRCT-0000004",
      "items": "[4E1CFAD9,3D26994F,F2EF4027,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000433",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003, CNTRCT-0000007",
      "items": "[D33377F5,E80AAE4A,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000434",
      "status": "Disrupted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000000",
      "items": "[7D206E43,9EAD0C20,F55885BD]"
    },
    {
      "id": "ORDR-0-0000435",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Ann Perry",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000029",
      "items": "[CCD70D1D,42A1729B,F2EF4027,099FDC51]"
    },
    {
      "id": "ORDR-0-0000436",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,B688BAF4,CDA15978,D8F6DF58,7B415254]"
    },
    {
      "id": "ORDR-0-0000437",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000009",
      "items": "[BAF9CD1A,DC16E9E9,8607492C]"
    },
    {
      "id": "ORDR-0-0000438",
      "status": "Hold",
      "name": "BioHealth Systems",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000021",
      "items": "[F04529EA,02A8E857,C36BF121]"
    },
    {
      "id": "ORDR-0-0000439",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Philip Brown",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[049BCC88,F55885BD,F2EF4027,DB301E75]"
    },
    {
      "id": "ORDR-0-0000440",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Philip Brown",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[44804BD4,7B415250]"
    },
    {
      "id": "ORDR-0-0000441",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000026, CNTRCT-0000029",
      "items": "[7D206E43,E80AAE4A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000442",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 1,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000004, CNTRCT-0000008",
      "items": "[7B415249]"
    },
    {
      "id": "ORDR-0-0000443",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[B21A9DE2,7B415250,EB58F892]"
    },
    {
      "id": "ORDR-0-0000444",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000019",
      "items": "[B21A9DE2,74B98558,74B98559]"
    },
    {
      "id": "ORDR-0-0000445",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[42A1729B,C36BF120,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000446",
      "status": "Hold",
      "name": "BioVital Supplies",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000004",
      "items": "[048701CE,BAF9CD1A]"
    },
    {
      "id": "ORDR-0-0000447",
      "status": "Disrupted",
      "name": "LifeCare Logistics",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[66AB5D2E,66AB5D2E]"
    },
    {
      "id": "ORDR-0-0000448",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Scott Dean",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000014",
      "items": "[7B415249,F04529EA]"
    },
    {
      "id": "ORDR-0-0000449",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[048701CE,F55885BD,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000450",
      "status": "Pending",
      "name": "CarePoint Technologies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000018",
      "items": "[ACF7D73A,C36BF120,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000451",
      "status": "Hold",
      "name": "BioVital Supplies",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[7B415249,E80AAE4A,93D87235]"
    },
    {
      "id": "ORDR-0-0000452",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Mary Khan",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4CD72DAE,D8F6DF55,74B98559,099FDC51,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000453",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[C36BF119,02A8E857,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000454",
      "status": "Disrupted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[ACF7D73A,7B415250,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000455",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[E80AAE4A,44804BD5,C36BF121]"
    },
    {
      "id": "ORDR-0-0000456",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000029",
      "items": "[44804BD4,7B415250,C36BF121]"
    },
    {
      "id": "ORDR-0-0000457",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000000",
      "items": "[E0A95BBC,F36679BC,E491B04D,5EDCDAE6,D8F6DF58]"
    },
    {
      "id": "ORDR-0-0000458",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008, CNTRCT-0000009, CNTRCT-0000030",
      "items": "[9FC8D91A,E80AAE4A,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000459",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[85ECBAF5,41097027,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000460",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001, CNTRCT-0000006",
      "items": "[CCD70D1D,BD2DB5FE,C36BF121]"
    },
    {
      "id": "ORDR-0-0000461",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Emily Graves",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[CCD70D1D,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000462",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000030",
      "items": "[85ECBAF5,DC16E9E9,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000463",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000005",
      "items": "[D33377F5,958C4CED,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000464",
      "status": "Disrupted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000008",
      "items": "[7B415249,5EDCDAE5]"
    },
    {
      "id": "ORDR-0-0000465",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000011",
      "items": "[19A12B20,C36BF120,9C01A6AA]"
    },
    {
      "id": "ORDR-0-0000466",
      "status": "Filled",
      "name": "HealthStream Supplies",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[58D36D79,9EAD0C20]"
    },
    {
      "id": "ORDR-0-0000467",
      "status": "Hold",
      "name": "LifeLine Supplies",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000019",
      "items": "[ACF7D73A,BAF9CD1A,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000468",
      "status": "Accepted",
      "name": "VitalSigns Supplies",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[E491B04D,9EAD0C20,C36BF121]"
    },
    {
      "id": "ORDR-0-0000469",
      "status": "Hold",
      "name": "LifeLine Supplies",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[CCD70D1D,74B98558,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000470",
      "status": "Hold",
      "name": "MediSupply Chain",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000024",
      "items": "[F2EF4025,D8F6DF55,CDA15978]"
    },
    {
      "id": "ORDR-0-0000471",
      "status": "Accepted",
      "name": "WellnessWare Group",
      "requestedBy": "Ann Perry",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000018, CNTRCT-0000027",
      "items": "[049BCC88,F55885BD,F04529EA,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000472",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000000, CNTRCT-0000008",
      "items": "[19A12B20,BAF9CD1A,CDA15978,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000473",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000002",
      "items": "[A0E7F6C1,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000474",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Natasha Perez",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "",
      "items": "[049BCC88,065DB72B,F2EF4027,DB301E75]"
    },
    {
      "id": "ORDR-0-0000475",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[58D36D79,7B415250,F2EF4027,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000476",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[049BCC88,11102F48,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000477",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Natasha Perez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[CCD70D1D,BD2DB5FE,B688BAF5]"
    },
    {
      "id": "ORDR-0-0000478",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Christina Horton",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,9DD6903A]"
    },
    {
      "id": "ORDR-0-0000479",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,C36BF120,E491B04D]"
    },
    {
      "id": "ORDR-0-0000480",
      "status": "Pending",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000029",
      "items": "[14968630,7B500D6B,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000481",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[200EA71C,A0E7F6C2,F04529EA]"
    },
    {
      "id": "ORDR-0-0000482",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[7B415249,F55885BD,F2EF4027,DB301E75]"
    },
    {
      "id": "ORDR-0-0000483",
      "status": "Hold",
      "name": "CareFusion Services",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000012, CNTRCT-0000028",
      "items": "[4E1CFAD9,42A1729B,BD23BA18,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000484",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000007",
      "items": "[44804BD4,330FF32E,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000485",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000031",
      "items": "[7D206E43,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000486",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000014",
      "items": "[DC16E9E8,88F245C2,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000487",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[D33377F5,88F245C2,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000488",
      "status": "Hold",
      "name": "WellnessWare Group",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[4E1CFAD9,D8F6DF55,9C01A6AA,DB301E75]"
    },
    {
      "id": "ORDR-0-0000489",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003",
      "items": "[DC16E9E8,9EAD0C20,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000490",
      "status": "Disrupted",
      "name": "CarePoint Technologies",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000003",
      "items": "[44804BD4,7B415250,F55885BD]"
    },
    {
      "id": "ORDR-0-0000491",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[66AB5D2E,E80AAE4A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000492",
      "status": "Hold",
      "name": "CareBridge Equipment",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,22633EF8,F04529EA]"
    },
    {
      "id": "ORDR-0-0000493",
      "status": "Accepted",
      "name": "BioCare Distribution",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000026",
      "items": "[DB07BD66,7B500D6B,8607492C]"
    },
    {
      "id": "ORDR-0-0000494",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[542BBC0E,44804BD5]"
    },
    {
      "id": "ORDR-0-0000495",
      "status": "Pending",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[0ED43D83,958C4CED]"
    },
    {
      "id": "ORDR-0-0000496",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000028",
      "items": "[4E1CFAD9,D8F6DF55,C36BF121]"
    },
    {
      "id": "ORDR-0-0000497",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[4E1CFAD9,F55885BD,11660B2E,ACF7D73A,4E1CFAD13,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000498",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[8164C892,14968630,8607492C]"
    },
    {
      "id": "ORDR-0-0000499",
      "status": "Pending",
      "name": "CureCraft Technologies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000027",
      "items": "[D8F6DF54,2C1769CF,F55885BD]"
    },
    {
      "id": "ORDR-0-0000500",
      "status": "Disrupted",
      "name": "MediSupply Chain",
      "requestedBy": "Philip Brown",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005, CNTRCT-0000018",
      "items": "[0ED43D83,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000501",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[44804BD4,E80AAE4A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000502",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[D33377F5,CDA15978]"
    },
    {
      "id": "ORDR-0-0000503",
      "status": "Disrupted",
      "name": "PurePath Equipment",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,74B98558,8607492C]"
    },
    {
      "id": "ORDR-0-0000504",
      "status": "Hold",
      "name": "LifeLine Supplies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000018",
      "items": "[C36BF119,330FF32E,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000505",
      "status": "Accepted",
      "name": "ProHealth Systems",
      "requestedBy": "Ann Perry",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[C36BF119,E80AAE4A,DB301E73,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000506",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000019",
      "items": "[B21A9DE2,66AB5D2E,93D87235,DB301E75]"
    },
    {
      "id": "ORDR-0-0000507",
      "status": "Accepted",
      "name": "CareBridge Equipment",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000004",
      "items": "[44804BD4,F36679BC]"
    },
    {
      "id": "ORDR-0-0000508",
      "status": "Filled",
      "name": "WellnessWare Group",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4FF22342,7B415250,3D26994F]"
    },
    {
      "id": "ORDR-0-0000509",
      "status": "Pending",
      "name": "BioMedix International",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[F2EF4025,88F245C2]"
    },
    {
      "id": "ORDR-0-0000510",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000026",
      "items": "[0ED43D83,CD9A49D2,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000511",
      "status": "Accepted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000026, CNTRCT-0000029",
      "items": "[BAF9CD1A,A0E7F6C2,D9345863]"
    },
    {
      "id": "ORDR-0-0000512",
      "status": "Filled",
      "name": "HealthStream Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000030",
      "items": "[B688BAF3,42A1729B,F04529EA,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000513",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,E0A5E11A,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000514",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,F36679BC,5EDCDAE5]"
    },
    {
      "id": "ORDR-0-0000515",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[44804BD4,A0E7F6C2,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000516",
      "status": "Pending",
      "name": "BioHealth Systems",
      "requestedBy": "Roger Graham",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[0AB87239,42A1729B]"
    },
    {
      "id": "ORDR-0-0000517",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,DC16E9E9,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000518",
      "status": "Disrupted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DF6676C9,1EBC5379,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000519",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[9FC8D91A,02A8E857,F04529EA]"
    },
    {
      "id": "ORDR-0-0000520",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000024",
      "items": "[7B415249,F55885BD,05BD5A7D,DB301E75]"
    },
    {
      "id": "ORDR-0-0000521",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Mary Durham",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000007, CNTRCT-0000028",
      "items": "[4E1CFAD9,9DD6903A,F55885BD,D33377F9]"
    },
    {
      "id": "ORDR-0-0000522",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,CDA15977,F04529EA]"
    },
    {
      "id": "ORDR-0-0000523",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Chris Harris",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000014, CNTRCT-0000030",
      "items": "[14968630,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000524",
      "status": "Pending",
      "name": "HealthHorizon Partners",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000026",
      "items": "[0ED43D83,11102F48]"
    },
    {
      "id": "ORDR-0-0000525",
      "status": "Pending",
      "name": "HealthSupply Innovations",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[049BCC88,42A1729B,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000526",
      "status": "Accepted",
      "name": "PharmaPoint International",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000025",
      "items": "[44804BD4,7B415250,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000527",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000014, CNTRCT-0000015",
      "items": "[ACF7D73A,F55885BD,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000528",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000031",
      "items": "[4E1CFAD9,F55885BD,BD2DB5FE,ACF7D73A,DC16E9E12,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000529",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E491B04D,065DB72B,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000530",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[19A12B20,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000531",
      "status": "Hold",
      "name": "CareBridge Equipment",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E491B04D,11102F48,F04529EA]"
    },
    {
      "id": "ORDR-0-0000532",
      "status": "Accepted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[44804BD4,F55885BD,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000533",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[542BBC0E,DB07BD67,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000534",
      "status": "Disrupted",
      "name": "CureTech Innovations",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[D33377F5,CDA15977,CDA15978]"
    },
    {
      "id": "ORDR-0-0000535",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "John Miller",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[79A6C1CA,DC16E9E9,D8F6DF56]"
    },
    {
      "id": "ORDR-0-0000536",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000006",
      "items": "[CCD70D1D,14968630,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000537",
      "status": "Accepted",
      "name": "CareFusion Services",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "",
      "items": "[542BBC0E,E80AAE4A,BD23BA18,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000538",
      "status": "Accepted",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F04529EA,DB07BD67,EB58F892]"
    },
    {
      "id": "ORDR-0-0000539",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[4E1CFAD9,7B415250,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000540",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[11660B2E,7B415250]"
    },
    {
      "id": "ORDR-0-0000541",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[E80AAE4A,CDA15978]"
    },
    {
      "id": "ORDR-0-0000542",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000, CNTRCT-0000009, CNTRCT-0000010, CNTRCT-0000024",
      "items": "[E491B04D,14968630,3D26994F]"
    },
    {
      "id": "ORDR-0-0000543",
      "status": "Accepted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Roger Graham",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000544",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000029",
      "items": "[44804BD4,F04529EA]"
    },
    {
      "id": "ORDR-0-0000545",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4FF22342,7B500D6B,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000546",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "John Miller",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000007, CNTRCT-0000008, CNTRCT-0000015, CNTRCT-0000018",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000547",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000000, CNTRCT-0000001, CNTRCT-0000012, CNTRCT-0000030",
      "items": "[0AB87239,BAF9CD1A,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000548",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000, CNTRCT-0000003, CNTRCT-0000009, CNTRCT-0000015",
      "items": "[D8F6DF54,3D26994F,E491B04D]"
    },
    {
      "id": "ORDR-0-0000549",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000006",
      "items": "[BAF9CD1A,DC16E9E9,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000550",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001, CNTRCT-0000007, CNTRCT-0000010, CNTRCT-0000011, CNTRCT-0000025",
      "items": "[58D36D79,DC16E9E9,9DD6903A]"
    },
    {
      "id": "ORDR-0-0000551",
      "status": "Filled",
      "name": "VitalCare Distribution",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000012, CNTRCT-0000017",
      "items": "[4E1CFAD9,F55885BD,93D87235,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000552",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[11660B2E,22633EF8,F04529EA]"
    },
    {
      "id": "ORDR-0-0000553",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D8F6DF54,7B415250,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000554",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Philip Brown",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000027",
      "items": "[200EA71C,8164C893,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000555",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000013",
      "items": "[0AB87239,E80AAE4A,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000556",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000012",
      "items": "[DC16E9E8,11102F48,F55885BD]"
    },
    {
      "id": "ORDR-0-0000557",
      "status": "Hold",
      "name": "CareBridge Equipment",
      "requestedBy": "Mark Wilson",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "",
      "items": "[9FC8D91A,F55885BD,F2EF4027,ACF7D73A,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000558",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000024",
      "items": "[19A12B20,66AB5D2E,74B98559]"
    },
    {
      "id": "ORDR-0-0000559",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F2EF4025,E0A5E11A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000560",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000",
      "items": "[19A12B20,02A8E857,F2EF4027,DB301E75]"
    },
    {
      "id": "ORDR-0-0000561",
      "status": "Filled",
      "name": "HealthSupply Innovations",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[5EDCDAE3,CDA15977,F04529EA]"
    },
    {
      "id": "ORDR-0-0000562",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000030",
      "items": "[4E1CFAD9,DC16E9E9,F2EF4027,C36BF122,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000563",
      "status": "Disrupted",
      "name": "HealthSupply Innovations",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[44804BD4,E80AAE4A,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000564",
      "status": "Accepted",
      "name": "VitalNet Distribution",
      "requestedBy": "Mark Wilson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[7B415249,B688BAF4]"
    },
    {
      "id": "ORDR-0-0000565",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000028",
      "items": "[F2EF4025,BAF9CD1A]"
    },
    {
      "id": "ORDR-0-0000566",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,049BCC89,F2EF4027,ACF7D73A,D8F6DF58]"
    },
    {
      "id": "ORDR-0-0000567",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000020",
      "items": "[42A1729B,330FF32E,F55885BD,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000568",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000019, CNTRCT-0000022",
      "items": "[049BCC88,11102F48,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000569",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000007, CNTRCT-0000018",
      "items": "[048701CE,F36679BC]"
    },
    {
      "id": "ORDR-0-0000570",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000031",
      "items": "[19A12B20,8164C893]"
    },
    {
      "id": "ORDR-0-0000571",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000008",
      "items": "[542BBC0E,F55885BD,F2EF4027,5EDCDAE6,DB301E75]"
    },
    {
      "id": "ORDR-0-0000572",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000022",
      "items": "[0AB87239,7B500D6B,C36BF122]"
    },
    {
      "id": "ORDR-0-0000573",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,D33377F6,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000574",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000012, CNTRCT-0000028",
      "items": "[BAF9CD1A,A0E7F6C2,C36BF121]"
    },
    {
      "id": "ORDR-0-0000575",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[D8F6DF54,DB301E72,E491B04D]"
    },
    {
      "id": "ORDR-0-0000576",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000006",
      "items": "[7B415249,DB07BD67,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000577",
      "status": "Accepted",
      "name": "PurePath Equipment",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000001",
      "items": "[ACF7D73A,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000578",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,C36BF120]"
    },
    {
      "id": "ORDR-0-0000579",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000031",
      "items": "[BAF9CD1A,F55885BD]"
    },
    {
      "id": "ORDR-0-0000580",
      "status": "Pending",
      "name": "BioHealth Systems",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000028",
      "items": "[4E1CFAD9,F55885BD,BD23BA18,4E1CFAD13,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000581",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[8164C892,DC16E9E9]"
    },
    {
      "id": "ORDR-0-0000582",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003",
      "items": "[ACF7D73A,11102F48,11102F49]"
    },
    {
      "id": "ORDR-0-0000583",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DF6676C9,7B500D6B,DB301E73]"
    },
    {
      "id": "ORDR-0-0000584",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000026",
      "items": "[DB07BD66,F55885BD,5EDCDAE5,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000585",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005, CNTRCT-0000008, CNTRCT-0000027",
      "items": "[D9345860,9EAD0C20]"
    },
    {
      "id": "ORDR-0-0000586",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,41097027,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000587",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,7B415250,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000588",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[0AB87239,93D87235]"
    },
    {
      "id": "ORDR-0-0000589",
      "status": "Disrupted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001, CNTRCT-0000015, CNTRCT-0000029",
      "items": "[200EA71C,14968630,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000590",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F2EF4025,11102F48,E491B04D]"
    },
    {
      "id": "ORDR-0-0000591",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000002, CNTRCT-0000009",
      "items": "[44804BD4,85ECBAF6,9DD6903A]"
    },
    {
      "id": "ORDR-0-0000592",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000000, CNTRCT-0000012",
      "items": "[14968630,E80AAE4A,F55885BD]"
    },
    {
      "id": "ORDR-0-0000593",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,44804BD5,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000594",
      "status": "Hold",
      "name": "LifeCare Logistics",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[4CD72DAE,41097027,3D26994F]"
    },
    {
      "id": "ORDR-0-0000595",
      "status": "Accepted",
      "name": "BioMedix International",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,065DB72B]"
    },
    {
      "id": "ORDR-0-0000596",
      "status": "Pending",
      "name": "VitalCare Distribution",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000013",
      "items": "[7B415249,74B98558,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000597",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[0ED43D83,C36BF120]"
    },
    {
      "id": "ORDR-0-0000598",
      "status": "Hold",
      "name": "Medique Innovations",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,D33377F6,F04529EA]"
    },
    {
      "id": "ORDR-0-0000599",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,02A8E857,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000600",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "",
      "items": "[048701CE,B688BAF4,C36BF121,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000601",
      "status": "Hold",
      "name": "Medique Innovations",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[049BCC88,F55885BD,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000602",
      "status": "Hold",
      "name": "VitalNet Distribution",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[ACF7D73A,330FF32E,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000603",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000019",
      "items": "[9FC8D91A,C36BF120,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000604",
      "status": "Disrupted",
      "name": "CareBridge Equipment",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000021",
      "items": "[44804BD4,02A8E857,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000605",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F04529EA,DB301E72,F2EF4027,D9345863]"
    },
    {
      "id": "ORDR-0-0000606",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000013",
      "items": "[A0E7F6C1,4E1CFAD10,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000607",
      "status": "Disrupted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[14968630,B688BAF4,7B500D6B,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000608",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000006, CNTRCT-0000012",
      "items": "[049BCC88,DB301E72,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000609",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[11660B2E,02A8E857,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000610",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000018",
      "items": "[A0E7F6C1,BD2DB5FE,E491B04D,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000611",
      "status": "Accepted",
      "name": "ProHealth Systems",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4CD72DAE,8164C893,CD9A49D2,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000612",
      "status": "Pending",
      "name": "PharmaPoint International",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[11660B2E,22633EF8,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000613",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000012",
      "items": "[8164C892,42A1729B,8607492C,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000614",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000004, CNTRCT-0000030",
      "items": "[200EA71C,7B415250,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000615",
      "status": "Pending",
      "name": "HealthStream Supplies",
      "requestedBy": "Philip Brown",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000010, CNTRCT-0000022",
      "items": "[C36BF119,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000616",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000006, CNTRCT-0000017, CNTRCT-0000019",
      "items": "[19A12B20,9EAD0C20,C36BF121]"
    },
    {
      "id": "ORDR-0-0000617",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[9DD6903A,02A8E857,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000618",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D8F6DF54,A0E7F6C2,F55885BD]"
    },
    {
      "id": "ORDR-0-0000619",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,11102F48]"
    },
    {
      "id": "ORDR-0-0000620",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[542BBC0E,88F245C2,8607492C]"
    },
    {
      "id": "ORDR-0-0000621",
      "status": "Accepted",
      "name": "LifeCare Logistics",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000002",
      "items": "[7B415249,F55885BD,CDA15978]"
    },
    {
      "id": "ORDR-0-0000622",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002",
      "items": "[9FC8D91A,9FC8D91A,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000623",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[CCD70D1D,85ECBAF6,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000624",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E0A95BBC,958C4CED]"
    },
    {
      "id": "ORDR-0-0000625",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[4E1CFAD9,9FC8D91A,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000626",
      "status": "Disrupted",
      "name": "HealthStream Supplies",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E491B04D,065DB72B,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000627",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[19A12B20,7B415250,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000628",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[200EA71C,14968630,CDA15978]"
    },
    {
      "id": "ORDR-0-0000629",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000020",
      "items": "[7A8CC6BD,7B415250,E491B04D]"
    },
    {
      "id": "ORDR-0-0000630",
      "status": "Accepted",
      "name": "CureCraft Technologies",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000018",
      "items": "[7B415249,1EBC5379,D8F6DF56]"
    },
    {
      "id": "ORDR-0-0000631",
      "status": "Filled",
      "name": "BioHealth Systems",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D33377F5,4E1CFAD10,F04529EA]"
    },
    {
      "id": "ORDR-0-0000632",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,85ECBAF6,F2EF4027,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000633",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[88F245C1,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000634",
      "status": "Disrupted",
      "name": "CureCraft Technologies",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000014",
      "items": "[CCD70D1D,E80AAE4A,8607492C,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000635",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[D8F6DF54,D8F6DF55,D8F6DF56]"
    },
    {
      "id": "ORDR-0-0000636",
      "status": "Disrupted",
      "name": "HealthHorizon Partners",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[58D36D79,8164C893,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000637",
      "status": "Filled",
      "name": "BioVital Supplies",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[0ED43D83,11102F48,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000638",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "",
      "items": "[42A1729B,F55885BD,7B500D6B,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000639",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,599EA2B4,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000640",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000031",
      "items": "[DC16E9E8,E80AAE4A,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000641",
      "status": "Filled",
      "name": "CarePoint Technologies",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000007, CNTRCT-0000020",
      "items": "[ACF7D73A,330FF32E]"
    },
    {
      "id": "ORDR-0-0000642",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,958C4CED,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000643",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Philip Brown",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[200EA71C,1EBC5379]"
    },
    {
      "id": "ORDR-0-0000644",
      "status": "Filled",
      "name": "CareBridge Equipment",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[7B415249,66AB5D2E,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000645",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,E0A5E11A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000646",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000010",
      "items": "[D33377F5,1EBC5379,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000647",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[D33377F5,02A8E857,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000648",
      "status": "Pending",
      "name": "VitalityGear Solutions",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000649",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[D8F6DF54,E80AAE4A,CDA15978]"
    },
    {
      "id": "ORDR-0-0000650",
      "status": "Pending",
      "name": "VitalityGear Solutions",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[D9345860,D8F6DF55,DB301E73]"
    },
    {
      "id": "ORDR-0-0000651",
      "status": "Disrupted",
      "name": "BioHealth Systems",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[048701CE,BD2DB5FE,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000652",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000",
      "items": "[C36BF119,9FC8D91A,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000653",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[ACF7D73A,DB07BD67,14968630]"
    },
    {
      "id": "ORDR-0-0000654",
      "status": "Accepted",
      "name": "HealthStream Supplies",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9,958C4CED,BD23BA18,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000655",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[CCD70D1D,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000656",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[19A12B20,7B415250,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000657",
      "status": "Pending",
      "name": "Medique Innovations",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004, CNTRCT-0000017",
      "items": "[DF6676C9,88F245C2,74B98559]"
    },
    {
      "id": "ORDR-0-0000658",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,DC16E9E9,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000659",
      "status": "Hold",
      "name": "HealthWave Technologies",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000028",
      "items": "[CCD70D1D,44804BD5]"
    },
    {
      "id": "ORDR-0-0000660",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000015, CNTRCT-0000019",
      "items": "[4E1CFAD9,F55885BD,DB301E73]"
    },
    {
      "id": "ORDR-0-0000661",
      "status": "Pending",
      "name": "VitalityGear Solutions",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[F2EF4025,14968630,5EDCDAE5,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000662",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000017",
      "items": "[DF6676C9,F55885BD,8607492C]"
    },
    {
      "id": "ORDR-0-0000663",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,E80AAE4A,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000664",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[C36BF119,7B500D6B,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000665",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000010",
      "items": "[9DD6903A,C36BF120,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000666",
      "status": "Disrupted",
      "name": "VitalSigns Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000028",
      "items": "[E0A95BBC,4E1CFAD10,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000667",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000012",
      "items": "[E80AAE4A,9EAD0C20,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000668",
      "status": "Filled",
      "name": "BioVital Supplies",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001, CNTRCT-0000007",
      "items": "[4E1CFAD9,F55885BD]"
    },
    {
      "id": "ORDR-0-0000669",
      "status": "Pending",
      "name": "VitalCare Distribution",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000012",
      "items": "[7B415249,88F245C2,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000670",
      "status": "Filled",
      "name": "BioVital Supplies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E491B04D,F55885BD,EB58F892,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000671",
      "status": "Disrupted",
      "name": "HealthWave Technologies",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[44804BD4,D33377F6,44804BD6]"
    },
    {
      "id": "ORDR-0-0000672",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 1,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000010",
      "items": "[B688BAF3]"
    },
    {
      "id": "ORDR-0-0000673",
      "status": "Disrupted",
      "name": "CareBridge Equipment",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[B688BAF3,7B415250,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000674",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,BAF9CD1A,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000675",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[ACF7D73A,9FC8D91A,F36679BC]"
    },
    {
      "id": "ORDR-0-0000676",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[F2EF4025,F55885BD,CCD70D1D,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000677",
      "status": "Accepted",
      "name": "VitalNet Distribution",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000029",
      "items": "[BAF9CD1A,7B415250]"
    },
    {
      "id": "ORDR-0-0000678",
      "status": "Hold",
      "name": "CareBridge Equipment",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,02A8E857,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000679",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[0AB87239,85ECBAF6,17B2D2B2,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000680",
      "status": "Pending",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000012, CNTRCT-0000029",
      "items": "[42A1729B,9DD6903A]"
    },
    {
      "id": "ORDR-0-0000681",
      "status": "Disrupted",
      "name": "HealthSupply Innovations",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003",
      "items": "[66AB5D2E,74B98559]"
    },
    {
      "id": "ORDR-0-0000682",
      "status": "Accepted",
      "name": "LifeCare Logistics",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001",
      "items": "[E0A95BBC,02A8E857,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000683",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000004, CNTRCT-0000020",
      "items": "[88F245C1,9FC8D91A,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000684",
      "status": "Hold",
      "name": "Medique Innovations",
      "requestedBy": "Scott Dean",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[B688BAF3,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000685",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 6,
      "leftQuantity": 0,
      "contract": "",
      "items": "[19A12B20,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000686",
      "status": "Filled",
      "name": "BioMedix International",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000002",
      "items": "[88F245C1,14968630,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000687",
      "status": "Accepted",
      "name": "PurePath Equipment",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[E0A95BBC,958C4CED]"
    },
    {
      "id": "ORDR-0-0000688",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000689",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[9DD6903A,1EBC5379,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000690",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000013",
      "items": "[F04529EA,C36BF120,E491B04D]"
    },
    {
      "id": "ORDR-0-0000691",
      "status": "Accepted",
      "name": "CareFusion Services",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000006, CNTRCT-0000010, CNTRCT-0000027",
      "items": "[049BCC88,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000692",
      "status": "Accepted",
      "name": "HealthSupply Innovations",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000025",
      "items": "[DF6676C9,F55885BD,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000693",
      "status": "Accepted",
      "name": "WellnessWare Group",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[ACF7D73A,E0A5E11A,93D87235]"
    },
    {
      "id": "ORDR-0-0000694",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4CD72DAE,A0E7F6C2,B688BAF5]"
    },
    {
      "id": "ORDR-0-0000695",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000012",
      "items": "[7D206E43,44804BD5]"
    },
    {
      "id": "ORDR-0-0000696",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000026",
      "items": "[048701CE,599EA2B4,542BBC0E,4E1CFAD13,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000697",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[B21A9DE2,85ECBAF6,8607492C]"
    },
    {
      "id": "ORDR-0-0000698",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7D206E43,11102F48,F04529EA]"
    },
    {
      "id": "ORDR-0-0000699",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "",
      "items": "[200EA71C,F55885BD,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000700",
      "status": "Pending",
      "name": "HealthHorizon Partners",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[F2EF4025,42A1729B,11660B2E]"
    },
    {
      "id": "ORDR-0-0000701",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000009, CNTRCT-0000024",
      "items": "[F2EF4025,E80AAE4A,DB301E73]"
    },
    {
      "id": "ORDR-0-0000702",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003, CNTRCT-0000013",
      "items": "[542BBC0E,DB07BD67,C36BF121]"
    },
    {
      "id": "ORDR-0-0000703",
      "status": "Hold",
      "name": "MediFlow Systems",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000013",
      "items": "[200EA71C,D8F6DF55,E491B04D]"
    },
    {
      "id": "ORDR-0-0000704",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Christina Horton",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[4E1CFAD9,14968630,F2EF4027,C36BF122]"
    },
    {
      "id": "ORDR-0-0000705",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007, CNTRCT-0000011",
      "items": "[CCD70D1D,7B500D6B,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000706",
      "status": "Accepted",
      "name": "ProHealth Systems",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[42A1729B,8164C893,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000707",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000024",
      "items": "[D9345860,88F245C2,E491B04D]"
    },
    {
      "id": "ORDR-0-0000708",
      "status": "Pending",
      "name": "BioCare Distribution",
      "requestedBy": "Ann Perry",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[200EA71C,F55885BD,F55885BD,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000709",
      "status": "Accepted",
      "name": "PharmaPoint International",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000007",
      "items": "[66AB5D2E,C36BF120,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000710",
      "status": "Pending",
      "name": "CareFusion Services",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000029",
      "items": "[E0A95BBC,E0A5E11A,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000711",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000018, CNTRCT-0000024, CNTRCT-0000025",
      "items": "[88F245C1,E80AAE4A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000712",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[7D206E43,DC16E9E9,3D26994F,DB301E75]"
    },
    {
      "id": "ORDR-0-0000713",
      "status": "Hold",
      "name": "HealthAxis Supplies",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000013",
      "items": "[7D206E43,11102F48,93D87235,D9345863]"
    },
    {
      "id": "ORDR-0-0000714",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000012, CNTRCT-0000029",
      "items": "[CCD70D1D,330FF32E,F04529EA]"
    },
    {
      "id": "ORDR-0-0000715",
      "status": "Hold",
      "name": "HealthWave Technologies",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7D206E43,7B415250,D33377F9]"
    },
    {
      "id": "ORDR-0-0000716",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 1,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000014, CNTRCT-0000027",
      "items": "[7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000717",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 1,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "",
      "items": "[DC16E9E8]"
    },
    {
      "id": "ORDR-0-0000718",
      "status": "Filled",
      "name": "ProHealth Systems",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[ACF7D73A,DC16E9E9,542BBC0E,D9345863]"
    },
    {
      "id": "ORDR-0-0000719",
      "status": "Pending",
      "name": "Medique Innovations",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000018",
      "items": "[44804BD4,DB07BD67,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000720",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[44804BD4,C36BF120,E491B04D,099FDC51]"
    },
    {
      "id": "ORDR-0-0000721",
      "status": "Disrupted",
      "name": "HealthAxis Supplies",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000006",
      "items": "[79A6C1CA,E0A5E11A,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000722",
      "status": "Hold",
      "name": "CureTech Innovations",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,66AB5D2E]"
    },
    {
      "id": "ORDR-0-0000723",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[F2EF4025,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000724",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000725",
      "status": "Accepted",
      "name": "MedLogistics Partners",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[14968630,66AB5D2E,BD2DB5FE]"
    },
    {
      "id": "ORDR-0-0000726",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Ann Perry",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000031",
      "items": "[049BCC88,F55885BD]"
    },
    {
      "id": "ORDR-0-0000727",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Donald Ochoa",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,A0E7F6C2,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000728",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000013, CNTRCT-0000031",
      "items": "[DF6676C9,7B415250,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000729",
      "status": "Hold",
      "name": "VitalSigns Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000026",
      "items": "[14968630,F55885BD,EB58F892,C36BF122]"
    },
    {
      "id": "ORDR-0-0000730",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E80AAE4A,66AB5D2E,9DD6903A]"
    },
    {
      "id": "ORDR-0-0000731",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "John Miller",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000004, CNTRCT-0000030",
      "items": "[048701CE,F55885BD,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000732",
      "status": "Disrupted",
      "name": "VitalCare Distribution",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000004, CNTRCT-0000005",
      "items": "[7A8CC6BD,14968630,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000733",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Ann Perry",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7A8CC6BD,7B415250]"
    },
    {
      "id": "ORDR-0-0000734",
      "status": "Filled",
      "name": "CareBridge Equipment",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[58D36D79,330FF32E,BD2DB5FE]"
    },
    {
      "id": "ORDR-0-0000735",
      "status": "Pending",
      "name": "Medique Innovations",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000003",
      "items": "[7D206E43,DB07BD67,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000736",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000029, CNTRCT-0000031",
      "items": "[0ED43D83,41097027,C36BF121]"
    },
    {
      "id": "ORDR-0-0000737",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[CCD70D1D,E80AAE4A,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000738",
      "status": "Hold",
      "name": "HealthHorizon Partners",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000021",
      "items": "[4E1CFAD9,E0A5E11A,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000739",
      "status": "Disrupted",
      "name": "BioMedix International",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 6,
      "contract": "",
      "items": "[4E1CFAD9,049BCC89,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000740",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[D8F6DF54,BAF9CD1A,5EDCDAE5]"
    },
    {
      "id": "ORDR-0-0000741",
      "status": "Pending",
      "name": "VitalityGear Solutions",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000002",
      "items": "[79A6C1CA,E80AAE4A,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000742",
      "status": "Disrupted",
      "name": "CureCraft Technologies",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[19A12B20,DB301E72,099FDC51]"
    },
    {
      "id": "ORDR-0-0000743",
      "status": "Pending",
      "name": "BioHealth Systems",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000002, CNTRCT-0000027",
      "items": "[048701CE,7B500D6B,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000744",
      "status": "Hold",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003, CNTRCT-0000007",
      "items": "[0ED43D83,85ECBAF6,C36BF121]"
    },
    {
      "id": "ORDR-0-0000745",
      "status": "Accepted",
      "name": "HealthStream Supplies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000016, CNTRCT-0000029",
      "items": "[D33377F5,42A1729B,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000746",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000007, CNTRCT-0000008",
      "items": "[4E1CFAD9,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000747",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DF6676C9,9FC8D91A,93D87235]"
    },
    {
      "id": "ORDR-0-0000748",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[D8F6DF54,3D26994F,CDA15978]"
    },
    {
      "id": "ORDR-0-0000749",
      "status": "Pending",
      "name": "VitalNet Distribution",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[049BCC88,8164C893]"
    },
    {
      "id": "ORDR-0-0000750",
      "status": "Hold",
      "name": "CureCraft Technologies",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002, CNTRCT-0000021",
      "items": "[E491B04D,A0E7F6C2]"
    },
    {
      "id": "ORDR-0-0000751",
      "status": "Disrupted",
      "name": "CureTech Innovations",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[14968630,D9345861,93D87235]"
    },
    {
      "id": "ORDR-0-0000752",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000, CNTRCT-0000010, CNTRCT-0000027",
      "items": "[DB07BD66,42A1729B,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000753",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000010, CNTRCT-0000012",
      "items": "[5EDCDAE3,599EA2B4,3D26994F]"
    },
    {
      "id": "ORDR-0-0000754",
      "status": "Filled",
      "name": "CarePoint Technologies",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[66AB5D2E,02A8E857]"
    },
    {
      "id": "ORDR-0-0000755",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000025",
      "items": "[11660B2E,7B500D6B,5EDCDAE5,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000756",
      "status": "Filled",
      "name": "BioVital Supplies",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000027",
      "items": "[4E1CFAD9,66AB5D2E,BD23BA18,C36BF122]"
    },
    {
      "id": "ORDR-0-0000757",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[ACF7D73A,7B415250,8607492C]"
    },
    {
      "id": "ORDR-0-0000758",
      "status": "Hold",
      "name": "CarePoint Technologies",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000031",
      "items": "[E491B04D,9DD6903A,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000759",
      "status": "Disrupted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Amy Davis",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[0ED43D83,F36679BC]"
    },
    {
      "id": "ORDR-0-0000760",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000005",
      "items": "[7B415249,D33377F6,F36679BC]"
    },
    {
      "id": "ORDR-0-0000761",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000008",
      "items": "[E80AAE4A,E80AAE4A,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000762",
      "status": "Pending",
      "name": "BioCare Distribution",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000",
      "items": "[048701CE,DB07BD67,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000763",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002",
      "items": "[0AB87239,D33377F6]"
    },
    {
      "id": "ORDR-0-0000764",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,85ECBAF6,9C01A6AA]"
    },
    {
      "id": "ORDR-0-0000765",
      "status": "Disrupted",
      "name": "HealthWave Technologies",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000766",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001, CNTRCT-0000007",
      "items": "[9DD6903A,7B415250]"
    },
    {
      "id": "ORDR-0-0000767",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011",
      "items": "[7A8CC6BD,02A8E857,E491B04D]"
    },
    {
      "id": "ORDR-0-0000768",
      "status": "Hold",
      "name": "LifeCare Logistics",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000, CNTRCT-0000001",
      "items": "[D8F6DF54,11102F48,93D87235,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000769",
      "status": "Pending",
      "name": "MedLogistics Partners",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,F55885BD]"
    },
    {
      "id": "ORDR-0-0000770",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000026",
      "items": "[58D36D79,DB301E72,E491B04D]"
    },
    {
      "id": "ORDR-0-0000771",
      "status": "Hold",
      "name": "HealthSupply Innovations",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004, CNTRCT-0000030",
      "items": "[11660B2E,74B98558,C36BF121]"
    },
    {
      "id": "ORDR-0-0000772",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4CD72DAE,599EA2B4,F04529EA]"
    },
    {
      "id": "ORDR-0-0000773",
      "status": "Accepted",
      "name": "HealthStream Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000002, CNTRCT-0000008",
      "items": "[11660B2E,9FC8D91A]"
    },
    {
      "id": "ORDR-0-0000774",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000010",
      "items": "[D9345860,11660B2E]"
    },
    {
      "id": "ORDR-0-0000775",
      "status": "Hold",
      "name": "WellnessWare Group",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000006, CNTRCT-0000027",
      "items": "[44804BD4,F55885BD,DF6676C11,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000776",
      "status": "Accepted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[CCD70D1D,E0A5E11A,8164C894]"
    },
    {
      "id": "ORDR-0-0000777",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Karen Wright",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000017",
      "items": "[4E1CFAD9,F55885BD,E491B04D,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000778",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Taylor Duran",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,7B500D6B,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000779",
      "status": "Hold",
      "name": "MedPulse Supply Network",
      "requestedBy": "Joshua Johnson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,58D36D80,E491B04D]"
    },
    {
      "id": "ORDR-0-0000780",
      "status": "Hold",
      "name": "ProHealth Systems",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[88F245C1,7B415250]"
    },
    {
      "id": "ORDR-0-0000781",
      "status": "Pending",
      "name": "WellnessWare Group",
      "requestedBy": "Michael Hall",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000005",
      "items": "[7B415249,F55885BD,F2EF4027,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000782",
      "status": "Accepted",
      "name": "LifeCare Logistics",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 2,
      "contract": "",
      "items": "[200EA71C,B688BAF4,F2EF4027,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000783",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000015, CNTRCT-0000029",
      "items": "[4CD72DAE,B688BAF4,C36BF121]"
    },
    {
      "id": "ORDR-0-0000784",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 6,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,049BCC89,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000785",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[44804BD4,F36679BC,11102F49]"
    },
    {
      "id": "ORDR-0-0000786",
      "status": "Hold",
      "name": "MediFlow Systems",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[DC16E9E8,11102F48,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000787",
      "status": "Hold",
      "name": "CarePoint Technologies",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000004",
      "items": "[42A1729B,D8F6DF55,8607492C]"
    },
    {
      "id": "ORDR-0-0000788",
      "status": "Disrupted",
      "name": "VitalSigns Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[58D36D79,02A8E857,CDA15978,ACF7D73A,D8F6DF58]"
    },
    {
      "id": "ORDR-0-0000789",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000016",
      "items": "[4E1CFAD9,C36BF120,3D26994F,DB301E75,7B415254]"
    },
    {
      "id": "ORDR-0-0000790",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[049BCC88,8164C893]"
    },
    {
      "id": "ORDR-0-0000791",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Michelle Rivera",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,DB301E72,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000792",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000001",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000793",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Nicole Dunlap",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DF6676C9,02A8E857]"
    },
    {
      "id": "ORDR-0-0000794",
      "status": "Pending",
      "name": "HealthAxis Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000026",
      "items": "[D8F6DF54,958C4CED,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000795",
      "status": "Hold",
      "name": "ProHealth Systems",
      "requestedBy": "Mary Durham",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F04529EA,330FF32E,5EDCDAE5,4FF22345]"
    },
    {
      "id": "ORDR-0-0000796",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[4E1CFAD9,C36BF120,7B500D6B,D33377F9,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000797",
      "status": "Hold",
      "name": "CarePoint Technologies",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000030",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000798",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[049BCC88,F36679BC,C36BF121]"
    },
    {
      "id": "ORDR-0-0000799",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "John Miller",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[542BBC0E,02A8E857,11102F49]"
    },
    {
      "id": "ORDR-0-0000800",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "John Miller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000020",
      "items": "[D33377F5,B688BAF4,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000801",
      "status": "Filled",
      "name": "VitalCare Distribution",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 6,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000020",
      "items": "[D8F6DF54,BAF9CD1A,2C1769CF,099FDC51,DC16E9E12,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000802",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000031",
      "items": "[88F245C1,1EBC5379,17B2D2B2,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000803",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[DC16E9E8,DB301E72,EB58F892]"
    },
    {
      "id": "ORDR-0-0000804",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000003",
      "items": "[CCD70D1D,E80AAE4A,E80AAE4A,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000805",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[DC16E9E8,7B415250,8607492C]"
    },
    {
      "id": "ORDR-0-0000806",
      "status": "Hold",
      "name": "BioVital Supplies",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[B688BAF3,66AB5D2E,E491B04D]"
    },
    {
      "id": "ORDR-0-0000807",
      "status": "Accepted",
      "name": "LifeCare Logistics",
      "requestedBy": "Kevin Crawford",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000029",
      "items": "[42A1729B,E0A5E11A]"
    },
    {
      "id": "ORDR-0-0000808",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011",
      "items": "[A0E7F6C1,330FF32E,CDA15978]"
    },
    {
      "id": "ORDR-0-0000809",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000001, CNTRCT-0000008",
      "items": "[7A8CC6BD,14968630]"
    },
    {
      "id": "ORDR-0-0000810",
      "status": "Disrupted",
      "name": "CarePoint Technologies",
      "requestedBy": "Michael Hall",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000015",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,7A8CC6BD,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000811",
      "status": "Disrupted",
      "name": "BioVital Supplies",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000001, CNTRCT-0000029",
      "items": "[F04529EA,11102F48,F2EF4027,4FF22345]"
    },
    {
      "id": "ORDR-0-0000812",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7B415249,9C01A6AA]"
    },
    {
      "id": "ORDR-0-0000813",
      "status": "Filled",
      "name": "PharmaLogix International",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000030",
      "items": "[E0A95BBC,42A1729B,11660B2E]"
    },
    {
      "id": "ORDR-0-0000814",
      "status": "Hold",
      "name": "VitalityGear Solutions",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,02A8E857,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000815",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7D206E43,74B98558,E491B04D]"
    },
    {
      "id": "ORDR-0-0000816",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[79A6C1CA,C36BF120,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000817",
      "status": "Hold",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000004",
      "items": "[4CD72DAE,85ECBAF6,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000818",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[C36BF119,3D26994F,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000819",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[C36BF119,44804BD5,8607492C]"
    },
    {
      "id": "ORDR-0-0000820",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000",
      "items": "[7B415249,4E1CFAD10,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000821",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000010",
      "items": "[D33377F5,0ED43D84,EB58F892]"
    },
    {
      "id": "ORDR-0-0000822",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[F2EF4025,9DD6903A,11102F49]"
    },
    {
      "id": "ORDR-0-0000823",
      "status": "Disrupted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Emily Graves",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,9DD6903A,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000824",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7B415249,9EAD0C20,F04529EA]"
    },
    {
      "id": "ORDR-0-0000825",
      "status": "Pending",
      "name": "HealthWave Technologies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001",
      "items": "[200EA71C,D33377F6,F2EF4027,ACF7D73A,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000826",
      "status": "Hold",
      "name": "HealthStream Supplies",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[19A12B20,14968630,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000827",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000, CNTRCT-0000006",
      "items": "[B21A9DE2,74B98558,E491B04D]"
    },
    {
      "id": "ORDR-0-0000828",
      "status": "Pending",
      "name": "VitalSigns Supplies",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[B21A9DE2,1EBC5379,8607492C,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000829",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000025",
      "items": "[4E1CFAD9,4E1CFAD10,E491B04D]"
    },
    {
      "id": "ORDR-0-0000830",
      "status": "Accepted",
      "name": "MediSupply Chain",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[88F245C1,E0A5E11A,C36BF121]"
    },
    {
      "id": "ORDR-0-0000831",
      "status": "Hold",
      "name": "WellnessWare Group",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000004",
      "items": "[ACF7D73A,F55885BD,93D87235]"
    },
    {
      "id": "ORDR-0-0000832",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000008, CNTRCT-0000011",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000833",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,BAF9CD1A,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000834",
      "status": "Filled",
      "name": "ProHealth Systems",
      "requestedBy": "Christina Horton",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[88F245C1,DB07BD67]"
    },
    {
      "id": "ORDR-0-0000835",
      "status": "Disrupted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000026",
      "items": "[CCD70D1D,BAF9CD1A,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000836",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,BAF9CD1A,D8F6DF56]"
    },
    {
      "id": "ORDR-0-0000837",
      "status": "Filled",
      "name": "BioVital Supplies",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000012",
      "items": "[DC16E9E8,DB301E72]"
    },
    {
      "id": "ORDR-0-0000838",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 1,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000022",
      "items": "[542BBC0E]"
    },
    {
      "id": "ORDR-0-0000839",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Tammy Kim",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 4,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000000, CNTRCT-0000005, CNTRCT-0000027",
      "items": "[049BCC88,E80AAE4A,DF6676C11,ACF7D73A,DB301E75]"
    },
    {
      "id": "ORDR-0-0000840",
      "status": "Accepted",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000003, CNTRCT-0000011",
      "items": "[5EDCDAE3,22633EF8,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000841",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[BAF9CD1A,7B415250,3D26994F]"
    },
    {
      "id": "ORDR-0-0000842",
      "status": "Filled",
      "name": "VitalCare Distribution",
      "requestedBy": "Lisa Wheeler",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001",
      "items": "[8164C892,E80AAE4A,E491B04D]"
    },
    {
      "id": "ORDR-0-0000843",
      "status": "Filled",
      "name": "CareFusion Services",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4E1CFAD9,C36BF120,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000844",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[19A12B20,9DD6903A,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000845",
      "status": "Pending",
      "name": "BioHealth Systems",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[ACF7D73A,66AB5D2E,2C1769CF,DB301E75]"
    },
    {
      "id": "ORDR-0-0000846",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "",
      "items": "[4E1CFAD9,E0A5E11A,099FDC51,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000847",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004, CNTRCT-0000011",
      "items": "[5EDCDAE3,A0E7F6C2,F04529EA]"
    },
    {
      "id": "ORDR-0-0000848",
      "status": "Filled",
      "name": "WellnessWare Group",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 1,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000",
      "items": "[D33377F5]"
    },
    {
      "id": "ORDR-0-0000849",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000020",
      "items": "[542BBC0E,7B415250,11102F49,D8F6DF58]"
    },
    {
      "id": "ORDR-0-0000850",
      "status": "Accepted",
      "name": "CarePoint Technologies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000012",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000851",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Karen Wright",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7A8CC6BD,BAF9CD1A,B688BAF5]"
    },
    {
      "id": "ORDR-0-0000852",
      "status": "Disrupted",
      "name": "CareBridge Equipment",
      "requestedBy": "Karen Wright",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[7D206E43,B688BAF4,5EDCDAE5,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000853",
      "status": "Disrupted",
      "name": "BioCare Distribution",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001",
      "items": "[7D206E43,F36679BC,E491B04D]"
    },
    {
      "id": "ORDR-0-0000854",
      "status": "Disrupted",
      "name": "MediFlow Systems",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000000, CNTRCT-0000001, CNTRCT-0000004, CNTRCT-0000024",
      "items": "[4E1CFAD9,330FF32E,74B98559]"
    },
    {
      "id": "ORDR-0-0000855",
      "status": "Disrupted",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DC16E9E8,02A8E857,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000856",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[BAF9CD1A,7B415250]"
    },
    {
      "id": "ORDR-0-0000857",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,66AB5D2E,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000858",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Scott Dean",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005",
      "items": "[DF6676C9,02A8E857,8607492C]"
    },
    {
      "id": "ORDR-0-0000859",
      "status": "Filled",
      "name": "CureCraft Technologies",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000001",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000860",
      "status": "Filled",
      "name": "MediSupply Chain",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "",
      "items": "[D33377F5,02A8E857,E491B04D,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000861",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Virginia Lin",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[88F245C1,A0E7F6C2]"
    },
    {
      "id": "ORDR-0-0000862",
      "status": "Pending",
      "name": "CarePoint Technologies",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 0,
      "contract": "",
      "items": "[85ECBAF5,2C1769CF,7A8CC6BD,DB301E75]"
    },
    {
      "id": "ORDR-0-0000863",
      "status": "Filled",
      "name": "PurePath Equipment",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000000, CNTRCT-0000015, CNTRCT-0000022",
      "items": "[7B415249,41097027,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000864",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000031",
      "items": "[E491B04D,E0A5E11A,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000865",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Ann Perry",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008, CNTRCT-0000027",
      "items": "[DB07BD66,E80AAE4A,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000866",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E491B04D,B688BAF4,F04529EA]"
    },
    {
      "id": "ORDR-0-0000867",
      "status": "Accepted",
      "name": "CareBridge Equipment",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[DC16E9E8,44804BD5,F2EF4027,D9345863]"
    },
    {
      "id": "ORDR-0-0000868",
      "status": "Pending",
      "name": "WellnessWare Group",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000024",
      "items": "[542BBC0E,049BCC89,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000869",
      "status": "Accepted",
      "name": "BioMedix International",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[BAF9CD1A,D8F6DF55,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000870",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011, CNTRCT-0000024",
      "items": "[7B415249,1EBC5379,B688BAF5]"
    },
    {
      "id": "ORDR-0-0000871",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[11660B2E,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000872",
      "status": "Hold",
      "name": "HealthStream Supplies",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005",
      "items": "[0ED43D83,F55885BD,F36679BC]"
    },
    {
      "id": "ORDR-0-0000873",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,85ECBAF6]"
    },
    {
      "id": "ORDR-0-0000874",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Mark Wilson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000019, CNTRCT-0000029",
      "items": "[7B415249,66AB5D2E,F04529EA]"
    },
    {
      "id": "ORDR-0-0000875",
      "status": "Accepted",
      "name": "HealthCraft Logistics",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000010",
      "items": "[D9345860,E80AAE4A,F55885BD]"
    },
    {
      "id": "ORDR-0-0000876",
      "status": "Pending",
      "name": "BioHealth Systems",
      "requestedBy": "Michele Lopez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000006",
      "items": "[5EDCDAE3,88F245C2,3D26994F]"
    },
    {
      "id": "ORDR-0-0000877",
      "status": "Accepted",
      "name": "MedixSupply Chain",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000016, CNTRCT-0000022",
      "items": "[5EDCDAE3,B688BAF4,3D26994F]"
    },
    {
      "id": "ORDR-0-0000878",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000018, CNTRCT-0000019",
      "items": "[542BBC0E,42A1729B,ACF7D73A,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000879",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000003",
      "items": "[7A8CC6BD,E80AAE4A,F2EF4027,DB301E75]"
    },
    {
      "id": "ORDR-0-0000880",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005, CNTRCT-0000006, CNTRCT-0000019, CNTRCT-0000026",
      "items": "[E0A95BBC,F36679BC]"
    },
    {
      "id": "ORDR-0-0000881",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Michelle Bowen",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,4E1CFAD10,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000882",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000008, CNTRCT-0000024",
      "items": "[ACF7D73A,F36679BC,17B2D2B2,7A8CC6BD,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000883",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Mary Durham",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7A8CC6BD,5EDCDAE5]"
    },
    {
      "id": "ORDR-0-0000884",
      "status": "Accepted",
      "name": "HealthHorizon Partners",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[542BBC0E,DC16E9E9,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000885",
      "status": "Pending",
      "name": "HealthSupply Innovations",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 2,
      "contract": "",
      "items": "[44804BD4,66AB5D2E,DB07BD68,5EDCDAE6,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000886",
      "status": "Hold",
      "name": "PharmaPoint International",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[5EDCDAE3,DB301E72,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000887",
      "status": "Disrupted",
      "name": "HealthWave Technologies",
      "requestedBy": "Todd Bailey",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[19A12B20,A0E7F6C2,F2EF4027,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000888",
      "status": "Disrupted",
      "name": "CarePoint Technologies",
      "requestedBy": "Kimberly Parker",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000007",
      "items": "[7A8CC6BD,3D26994F,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000889",
      "status": "Hold",
      "name": "PharmaLogix International",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000001, CNTRCT-0000003",
      "items": "[D9345860,4E1CFAD10,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000890",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[7A8CC6BD,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000891",
      "status": "Pending",
      "name": "CareBridge Equipment",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[4FF22342,DB301E72,B688BAF5]"
    },
    {
      "id": "ORDR-0-0000892",
      "status": "Filled",
      "name": "HealthAxis Supplies",
      "requestedBy": "Emily Graves",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E0A95BBC,C36BF120,C36BF121]"
    },
    {
      "id": "ORDR-0-0000893",
      "status": "Disrupted",
      "name": "LifeLine Supplies",
      "requestedBy": "Emily Graves",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "CNTRCT-0000004, CNTRCT-0000015",
      "items": "[E0A95BBC,049BCC89,DF6676C11,D8F6DF58,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000894",
      "status": "Accepted",
      "name": "CureTech Innovations",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 5,
      "contract": "",
      "items": "[0ED43D83,BAF9CD1A,BD23BA18,5EDCDAE6,D33377F9]"
    },
    {
      "id": "ORDR-0-0000895",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[44804BD4,DB301E72,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000896",
      "status": "Accepted",
      "name": "BioHealth Systems",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000005",
      "items": "[F2EF4025,41097027,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000897",
      "status": "Hold",
      "name": "CareFusion Services",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[14968630,A0E7F6C2,C36BF121]"
    },
    {
      "id": "ORDR-0-0000898",
      "status": "Hold",
      "name": "WellnessWorks Distribution",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7B415249,42A1729B,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000899",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008, CNTRCT-0000014, CNTRCT-0000024",
      "items": "[7B415249,E80AAE4A,74B98559]"
    },
    {
      "id": "ORDR-0-0000900",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000000, CNTRCT-0000029",
      "items": "[C36BF119,E491B04D]"
    },
    {
      "id": "ORDR-0-0000901",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Arthur Wilson",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[7D206E43,BAF9CD1A,CB6FF511,D9345863]"
    },
    {
      "id": "ORDR-0-0000902",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F2EF4025,1EBC5379,11102F49]"
    },
    {
      "id": "ORDR-0-0000903",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011",
      "items": "[E491B04D,DC16E9E9,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000904",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003",
      "items": "[7A8CC6BD,7B500D6B,C36BF121]"
    },
    {
      "id": "ORDR-0-0000905",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000006",
      "items": "[A0E7F6C1,E0A5E11A,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000906",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000021, CNTRCT-0000029",
      "items": "[E80AAE4A,D8F6DF55,542BBC0E]"
    },
    {
      "id": "ORDR-0-0000907",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000031",
      "items": "[049BCC88,7B500D6B,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000908",
      "status": "Disrupted",
      "name": "HealthWave Technologies",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[D8F6DF54,958C4CED,F55885BD]"
    },
    {
      "id": "ORDR-0-0000909",
      "status": "Filled",
      "name": "MediFlow Systems",
      "requestedBy": "Mary Durham",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000000",
      "items": "[200EA71C,049BCC89,2C1769CF,C36BF122]"
    },
    {
      "id": "ORDR-0-0000910",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[7D206E43,E80AAE4A,F55885BD]"
    },
    {
      "id": "ORDR-0-0000911",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000012, CNTRCT-0000019",
      "items": "[8164C892,E0A5E11A,93D87235,DB301E75]"
    },
    {
      "id": "ORDR-0-0000912",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000010",
      "items": "[7B415249,E80AAE4A,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000913",
      "status": "Disrupted",
      "name": "HealthSync Distributors",
      "requestedBy": "Philip Brown",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000012",
      "items": "[58D36D79,F36679BC]"
    },
    {
      "id": "ORDR-0-0000914",
      "status": "Hold",
      "name": "VitalCare Distribution",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000028",
      "items": "[4CD72DAE,22633EF8,E491B04D]"
    },
    {
      "id": "ORDR-0-0000915",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[200EA71C,D9345861,E491B04D,C36BF122]"
    },
    {
      "id": "ORDR-0-0000916",
      "status": "Pending",
      "name": "MedPulse Supply Network",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[F2EF4025,BAF9CD1A,74B98559]"
    },
    {
      "id": "ORDR-0-0000917",
      "status": "Pending",
      "name": "CareBridge Equipment",
      "requestedBy": "Mercedes Simmons",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[66AB5D2E,02A8E857]"
    },
    {
      "id": "ORDR-0-0000918",
      "status": "Hold",
      "name": "MedixSupply Chain",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000003, CNTRCT-0000012",
      "items": "[F2EF4025,DB07BD67]"
    },
    {
      "id": "ORDR-0-0000919",
      "status": "Filled",
      "name": "HealthSync Distributors",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000026",
      "items": "[D33377F5,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000920",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Brandon Smith",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[CCD70D1D,3D26994F,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000921",
      "status": "Accepted",
      "name": "HealthSupply Innovations",
      "requestedBy": "Nicole Evans",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[7B415249,DB301E72,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000922",
      "status": "Filled",
      "name": "LifeLine Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 5,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000000, CNTRCT-0000013, CNTRCT-0000029",
      "items": "[4E1CFAD9,41097027,ACF7D73A,D33377F9,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000923",
      "status": "Pending",
      "name": "HealthSupply Innovations",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[14968630,DB301E72,8607492C]"
    },
    {
      "id": "ORDR-0-0000924",
      "status": "Filled",
      "name": "VitalNet Distribution",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000005",
      "items": "[F2EF4025,1EBC5379,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000925",
      "status": "Disrupted",
      "name": "BioHealth Systems",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000013, CNTRCT-0000024",
      "items": "[7B415249,330FF32E,542BBC0E,4FF22345]"
    },
    {
      "id": "ORDR-0-0000926",
      "status": "Pending",
      "name": "MediFlow Systems",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[19A12B20,05BD5A7D]"
    },
    {
      "id": "ORDR-0-0000927",
      "status": "Hold",
      "name": "BioVital Supplies",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[9FC8D91A,41097027,9C01A6AA]"
    },
    {
      "id": "ORDR-0-0000928",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[79A6C1CA,D9345861]"
    },
    {
      "id": "ORDR-0-0000929",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Stacy Blackburn",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[DC16E9E8,DB301E72]"
    },
    {
      "id": "ORDR-0-0000930",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[048701CE,88F245C2,C36BF121]"
    },
    {
      "id": "ORDR-0-0000931",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000001, CNTRCT-0000030",
      "items": "[19A12B20,7B415250,5EDCDAE5,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000932",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Kyle Wilkerson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[F2EF4025,A0E7F6C2,BD23BA18]"
    },
    {
      "id": "ORDR-0-0000933",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "David Mcdaniel",
      "itemsTotal": 1,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 1,
      "contract": "",
      "items": "[0ED43D83]"
    },
    {
      "id": "ORDR-0-0000934",
      "status": "Pending",
      "name": "BioVital Supplies",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[B21A9DE2,11102F48]"
    },
    {
      "id": "ORDR-0-0000935",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9,E80AAE4A,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000936",
      "status": "Hold",
      "name": "HealthSync Distributors",
      "requestedBy": "Mark Wilson",
      "itemsTotal": 5,
      "location": "London Hospital",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "",
      "items": "[44804BD4,F55885BD,11660B2E,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000937",
      "status": "Pending",
      "name": "PurePath Equipment",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000031",
      "items": "[E491B04D,74B98558,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000938",
      "status": "Accepted",
      "name": "PharmaLogix International",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000010",
      "items": "[F04529EA,F55885BD,CD9A49D2]"
    },
    {
      "id": "ORDR-0-0000939",
      "status": "Hold",
      "name": "MediFlow Systems",
      "requestedBy": "Dylan Morales",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[4E1CFAD9,74B98558,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000940",
      "status": "Filled",
      "name": "BioHealth Systems",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000018, CNTRCT-0000021",
      "items": "[DC16E9E8,1EBC5379,E491B04D]"
    },
    {
      "id": "ORDR-0-0000941",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Dr. Michael Rojas",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[D8F6DF54,14968630]"
    },
    {
      "id": "ORDR-0-0000942",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Tina Henderson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[D8F6DF54,88F245C2,F55885BD]"
    },
    {
      "id": "ORDR-0-0000943",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E80AAE4A,F2EF4027,099FDC51]"
    },
    {
      "id": "ORDR-0-0000944",
      "status": "Filled",
      "name": "Medique Innovations",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[44804BD4,22633EF8,14968630,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000945",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Philip Brown",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000028",
      "items": "[049BCC88,8164C893,BD2DB5FE]"
    },
    {
      "id": "ORDR-0-0000946",
      "status": "Disrupted",
      "name": "PharmaLogix International",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000030",
      "items": "[7A8CC6BD,02A8E857,CDA15978]"
    },
    {
      "id": "ORDR-0-0000947",
      "status": "Accepted",
      "name": "HealthWave Technologies",
      "requestedBy": "Joshua Patel MD",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[E491B04D,44804BD5,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000948",
      "status": "Disrupted",
      "name": "Medique Innovations",
      "requestedBy": "Sarah Martin",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000000",
      "items": "[DC16E9E8,C36BF120,74B98559]"
    },
    {
      "id": "ORDR-0-0000949",
      "status": "Accepted",
      "name": "VitalityGear Solutions",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000011",
      "items": "[542BBC0E,02A8E857,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000950",
      "status": "Accepted",
      "name": "MediFlow Systems",
      "requestedBy": "Michael Hall",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[CCD70D1D,D9345861]"
    },
    {
      "id": "ORDR-0-0000951",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Michael Hall",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000008",
      "items": "[7B415249,E80AAE4A,F2EF4027,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000952",
      "status": "Filled",
      "name": "HealthCraft Logistics",
      "requestedBy": "John Miller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[ACF7D73A,3D26994F,F04529EA]"
    },
    {
      "id": "ORDR-0-0000953",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Karen Wright",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "",
      "items": "[CCD70D1D,F55885BD,CDA15978,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000954",
      "status": "Filled",
      "name": "LifeCare Logistics",
      "requestedBy": "Clayton Clark",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[0ED43D83,9DD6903A,CDA15978]"
    },
    {
      "id": "ORDR-0-0000955",
      "status": "Pending",
      "name": "BioMedix International",
      "requestedBy": "Douglas Thomas",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000005",
      "items": "[42A1729B,41097027,2C1769CF,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000956",
      "status": "Accepted",
      "name": "VitalCare Distribution",
      "requestedBy": "Roger Graham",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9,BAF9CD1A,E80AAE4A]"
    },
    {
      "id": "ORDR-0-0000957",
      "status": "Pending",
      "name": "LifeLine Supplies",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[B688BAF3,E80AAE4A,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000958",
      "status": "Pending",
      "name": "Medique Innovations",
      "requestedBy": "Marvin Barnes",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[88F245C1,B688BAF4,7A8CC6BD]"
    },
    {
      "id": "ORDR-0-0000959",
      "status": "Hold",
      "name": "HealthCraft Logistics",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000007",
      "items": "[200EA71C,A0E7F6C2,3D26994F]"
    },
    {
      "id": "ORDR-0-0000960",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Chris Harris",
      "itemsTotal": 2,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 2,
      "contract": "",
      "items": "[049BCC88,599EA2B4]"
    },
    {
      "id": "ORDR-0-0000961",
      "status": "Accepted",
      "name": "Medique Innovations",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004",
      "items": "[42A1729B,CDA15977,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000962",
      "status": "Pending",
      "name": "VitalNet Distribution",
      "requestedBy": "Michael Hall",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 4,
      "contract": "CNTRCT-0000013",
      "items": "[E0A95BBC,74B98558,DB301E73,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000963",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Christina Munoz",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000003",
      "items": "[0AB87239,BAF9CD1A,C36BF121]"
    },
    {
      "id": "ORDR-0-0000964",
      "status": "Accepted",
      "name": "CareBridge Equipment",
      "requestedBy": "Scott Dean",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E0A95BBC,F36679BC,E80AAE4A,D8F6DF58]"
    },
    {
      "id": "ORDR-0-0000965",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000007",
      "items": "[B688BAF3,330FF32E,8607492C]"
    },
    {
      "id": "ORDR-0-0000966",
      "status": "Disrupted",
      "name": "ProHealth Systems",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004",
      "items": "[D8F6DF54,065DB72B,79A6C1CA]"
    },
    {
      "id": "ORDR-0-0000967",
      "status": "Filled",
      "name": "HealthAxis Supplies",
      "requestedBy": "Stacey Johnson",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000015",
      "items": "[7B415249,14968630,DB07BD68]"
    },
    {
      "id": "ORDR-0-0000968",
      "status": "Filled",
      "name": "MedPulse Supply Network",
      "requestedBy": "Philip Brown",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4FF22342,41097027,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000969",
      "status": "Disrupted",
      "name": "VitalCare Distribution",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[9FC8D91A,88F245C2,9DD6903A]"
    },
    {
      "id": "ORDR-0-0000970",
      "status": "Pending",
      "name": "PharmaLogix International",
      "requestedBy": "Michael Hall",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "",
      "items": "[E491B04D,11102F48,CCD70D1D]"
    },
    {
      "id": "ORDR-0-0000971",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Mary Durham",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000002",
      "items": "[9FC8D91A,E0A5E11A,C36BF121]"
    },
    {
      "id": "ORDR-0-0000972",
      "status": "Pending",
      "name": "PharmaPoint International",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 4,
      "leftQuantity": 2,
      "contract": "CNTRCT-0000007, CNTRCT-0000013",
      "items": "[DC16E9E8,D9345861,2C1769CF,ACF7D73A,DB301E75,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000973",
      "status": "Pending",
      "name": "HealthSync Distributors",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 6,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 1,
      "contract": "",
      "items": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DC16E9E12,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000974",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Trevor Miller",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000004",
      "items": "[542BBC0E,E80AAE4A,CDA15978]"
    },
    {
      "id": "ORDR-0-0000975",
      "status": "Accepted",
      "name": "HealthStream Supplies",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 2,
      "contract": "",
      "items": "[5EDCDAE3,A0E7F6C2,C36BF121,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000976",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Melissa Buck",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000008",
      "items": "[F2EF4025,41097027]"
    },
    {
      "id": "ORDR-0-0000977",
      "status": "Accepted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Roger Graham",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E491B04D,F55885BD]"
    },
    {
      "id": "ORDR-0-0000978",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Michael Hall",
      "itemsTotal": 4,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[4CD72DAE,BAF9CD1A,DF6676C11,4E1CFAD13]"
    },
    {
      "id": "ORDR-0-0000979",
      "status": "Filled",
      "name": "MedixSupply Chain",
      "requestedBy": "Jacob Mueller",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000029",
      "items": "[F04529EA,42A1729B,8607492C]"
    },
    {
      "id": "ORDR-0-0000980",
      "status": "Filled",
      "name": "HealthWave Technologies",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[7A8CC6BD,9EAD0C20,F2EF4027,099FDC51]"
    },
    {
      "id": "ORDR-0-0000981",
      "status": "Filled",
      "name": "LifeCare Logistics",
      "requestedBy": "Shawn Taylor",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000016",
      "items": "[E80AAE4A,F36679BC,F2EF4027]"
    },
    {
      "id": "ORDR-0-0000982",
      "status": "Filled",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Ann Fischer",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000006",
      "items": "[7B415249,DC16E9E9,2C1769CF]"
    },
    {
      "id": "ORDR-0-0000983",
      "status": "Pending",
      "name": "CureTech Innovations",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 4,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 3,
      "contract": "",
      "items": "[44804BD4,42A1729B,542BBC0E,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000984",
      "status": "Disrupted",
      "name": "MedPulse Supply Network",
      "requestedBy": "Amy Davis",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 1,
      "contract": "",
      "items": "[E0A95BBC,D9345861,E491B04D]"
    },
    {
      "id": "ORDR-0-0000985",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Chelsea Martinez",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000011",
      "items": "[88F245C1,DB301E72,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000986",
      "status": "Hold",
      "name": "HealthStream Supplies",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 2,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 1,
      "leftQuantity": 1,
      "contract": "",
      "items": "[200EA71C,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000987",
      "status": "Accepted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Mary Khan",
      "itemsTotal": 2,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 2,
      "leftQuantity": 0,
      "contract": "",
      "items": "[19A12B20,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000988",
      "status": "Pending",
      "name": "ProHealth Systems",
      "requestedBy": "Matthew Robinson",
      "itemsTotal": 5,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 5,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000001",
      "items": "[049BCC88,E80AAE4A,F36679BC,099FDC51,DB301E75]"
    },
    {
      "id": "ORDR-0-0000989",
      "status": "Disrupted",
      "name": "MedixSupply Chain",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000021",
      "items": "[CCD70D1D,D33377F6,ACF7D73A]"
    },
    {
      "id": "ORDR-0-0000990",
      "status": "Hold",
      "name": "CureCraft Technologies",
      "requestedBy": "Bobby Stewart",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 0,
      "contract": "CNTRCT-0000004, CNTRCT-0000005",
      "items": "[7B415249,DC16E9E9,7B500D6B]"
    },
    {
      "id": "ORDR-0-0000991",
      "status": "Hold",
      "name": "BioHealth Systems",
      "requestedBy": "Carly Nelson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[DB07BD66,0ED43D84,17B2D2B2]"
    },
    {
      "id": "ORDR-0-0000992",
      "status": "Pending",
      "name": "MedixSupply Chain",
      "requestedBy": "Kristen Sanders",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000008",
      "items": "[58D36D79,41097027,F1C1E748]"
    },
    {
      "id": "ORDR-0-0000993",
      "status": "Accepted",
      "name": "HealthSync Distributors",
      "requestedBy": "Beth Lopez",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000008",
      "items": "[049BCC88,74B98558,E491B04D,DC16E9E12]"
    },
    {
      "id": "ORDR-0-0000994",
      "status": "Filled",
      "name": "CureCraft Technologies",
      "requestedBy": "Chris Harris",
      "itemsTotal": 3,
      "location": "London Hospital",
      "receivedQuantity": 1,
      "leftQuantity": 2,
      "contract": "",
      "items": "[5EDCDAE3,BAF9CD1A,DF6676C11]"
    },
    {
      "id": "ORDR-0-0000995",
      "status": "Disrupted",
      "name": "PharmaSphere Equipment",
      "requestedBy": "Roger Graham",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "CNTRCT-0000020",
      "items": "[9FC8D91A,02A8E857,CB6FF511,DC16E9E11]"
    },
    {
      "id": "ORDR-0-0000996",
      "status": "Accepted",
      "name": "HealthHorizon Partners",
      "requestedBy": "Mary Khan",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "",
      "items": "[85ECBAF5,DC16E9E9,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000997",
      "status": "Accepted",
      "name": "BioVital Supplies",
      "requestedBy": "Debbie Newman",
      "itemsTotal": 4,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 3,
      "leftQuantity": 1,
      "contract": "",
      "items": "[66AB5D2E,1EBC5379,E491B04D,5EDCDAE6]"
    },
    {
      "id": "ORDR-0-0000998",
      "status": "Pending",
      "name": "HealthCraft Logistics",
      "requestedBy": "Mr. Michael Wells",
      "itemsTotal": 3,
      "location": "Portland Place Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000028",
      "items": "[7A8CC6BD,A0E7F6C2,CB6FF511]"
    },
    {
      "id": "ORDR-0-0000999",
      "status": "Accepted",
      "name": "LifeLine Supplies",
      "requestedBy": "Amy Anderson",
      "itemsTotal": 3,
      "location": "Moorgate Outpatient Centre",
      "receivedQuantity": 0,
      "leftQuantity": 3,
      "contract": "CNTRCT-0000003",
      "items": "[9FC8D91A,F55885BD,DF6676C11]"
    }
  ]