import { ReactElement } from "react";
import { Popover, PopoverPosition, Rect } from "react-tiny-popover";

const SearchPopover = (props: {
    ref: React.Ref<HTMLElement>,
    isOpen: boolean,
    positions: PopoverPosition[],
    content: (rect: Rect) => ReactElement,
    children: ReactElement
}) => {
    return (
        <Popover
            ref={props.ref}
            containerStyle={{ zIndex: '500' }}
            isOpen={props.isOpen}
            positions={['bottom']}
            content={({ position, childRect, popoverRect }) => (
                <div
                    hidden={!props.isOpen}
                    style={{ boxShadow: '0px 38px 90px 0px #00000040; 0px 0px 2px 0px #0000000D; 0px 0px 1px 0px #00000099' }}
                    className="relative mt-4 bg-dialog backdrop-blur-2xl rounded-md border border-neutral-2"
                >
                    {props.content(childRect)}

                    <div className="absolute h-4 w-4 mx-auto left-1/2 top-[-8px]  rotate-45 transform border-t border-l border-neutral-2 bg-dialog z-[501]"></div>
                </div>
            )}
        >
            {props.children}
        </Popover>
    )
}

export default SearchPopover