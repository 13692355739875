import { useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import Toggle from "../toggle/toggle"
import { People, mockSurgeons } from "../../model/people/people"
import Input from "../input/input"

const PreferenceDialog = (props: { onSubmit: () => void }) => {
    const [currentPage, setCurrentPage] = useState('overview')

    const [length, setLength] = useState('indefinite')
    const [elapsed, setElapsed] = useState('delete')

    const [selectedItem, setSelectedItem] = useState<People>()

    return (
        <Dialog>
            <div className={`flex flex-col relative w-[800px] h-[540px] ${currentPage === "overview" ? "bg-[url('/public/images/backgrounds/bg-gradient-dialog.svg')]" : ""} bg-no-repeat bg-top rounded-md`}>

                <div style={{}} className="flex flex-col p-5 mx-[120px]">
                    {currentPage === 'overview' && (
                        <div className="mx-[60px]">
                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[20px]">
                                <p className="font-large-title font-neutral-6 font-bold">Add Preference</p>
                                <p className="font-body font-neutral-5">You are about to specify a surgeon preference. The preference will also contain the surgery defaults, that you can change:</p>
                            </div>

                            <div className="flex flex-col gap-3">
                                <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                    <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                        <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-medkit.svg`}></img>
                                    </div>

                                    <div>
                                        <p className="font-subheadline font-semibold">16 default items</p>
                                        <p className="font-subheadline text-neutral-5">All item required to perform an Appendectomy</p>
                                    </div>

                                    <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                </div>

                                <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                    <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                        <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-menu-white.svg`}></img>
                                    </div>

                                    <div>
                                        <p className="font-subheadline font-semibold">Indefinite type</p>
                                        <p className="font-subheadline text-neutral-5">The preference will be used indefinitedly</p>
                                    </div>

                                    <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                </div>
                            </div>
                        </div>
                    )}

                    {currentPage === 'surgeon' && (
                        <>
                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px] mx-[40px]">
                                <p className="font-large-title font-neutral-6 font-bold">Specify Surgeon</p>
                                <p className="font-title-3 text-neutral-5">Specify what surgeon you’d like to apply the preference. You can browse your team or enter a new surgeon.</p>
                            </div>

                            <div className="flex flex-col gap-2.5 mx-[40px] overflow-y-hidden h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md pt-2.5">
                                <div className="px-2.5">
                                    <div className="flex items-center w-full font-body placeholder:text-neutral-4 focus:outline-none text-neutral-6 bg-transparent p-2 rounded-md border border-neutral-2">
                                        <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                                        <input className="w-full bg-transparent" type="text" placeholder="Search"></input>
                                    </div>
                                </div>



                                <div className="flex flex-col gap-2.5 overflow-y-auto px-2.5 pb-2.5">
                                    {mockSurgeons.filter((item) => selectedItem ? item === selectedItem : true).map((item) => {
                                        return (
                                            <div className="flex gap-2 bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                                <div className="w-[28px] h-[28px] border=[0.5px] p-[1px] border-neutral-2 bg-white rounded-[2px]">
                                                    <div className="w-full h-full border-[0.5px] border-neutral-1 bg-neutral-1 rounded-[2px] shadow-inner">
                                                        <img className="w-full p-[1px]" src={'/images/icons/ic-mock-item.svg'}></img>
                                                    </div>
                                                </div>

                                                <div>
                                                    <p className="font-body">
                                                        {item.prefix && (
                                                            <span>{item.prefix} </span>
                                                        )}

                                                        <span>{item.name} </span>
                                                        <span>{item.surname}</span>
                                                    </p>
                                                    <p className="font-subheadline text-neutral-5">{item.status}</p>
                                                </div>

                                                <div className="flex items-center ml-auto">
                                                    <PrimaryButton
                                                        title={item === selectedItem ? "Change" : "Select"}
                                                        type={item === selectedItem ? "white" : "gray"}
                                                        size="lg"
                                                        onClick={() => {
                                                            setSelectedItem(item)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="flex flex-col gap-1.5 border border-neutral-2 rounded-md bg-white p-1.5">
                                        <div className="flex p-1.5">
                                            <p className="font-body">Name</p>
                                            <p className="font-body text-neutral-5 ml-auto">{selectedItem?.name}</p>
                                        </div>

                                        <div className="h-[1px] bg-neutral-2"></div>

                                        <div className="flex p-1.5">
                                            <p className="font-body">Surname</p>
                                            <p className="font-body text-neutral-5 ml-auto">{selectedItem?.surname}</p>
                                        </div>

                                        <div className="h-[1px] bg-neutral-2"></div>

                                        <div className="flex p-1.5">
                                            <p className="font-body">Location</p>
                                            <p className="font-body text-neutral-5 ml-auto">{selectedItem?.location}</p>
                                        </div>

                                        <div className="h-[1px] bg-neutral-2"></div>

                                        <div className="flex p-1.5">
                                            <p className="font-body">Team</p>
                                            <p className="font-body text-neutral-5 ml-auto">{selectedItem?.team}</p>
                                        </div>

                                        <div className="h-[1px] bg-neutral-2"></div>

                                        <div className="flex p-1.5">
                                            <p className="font-body">Status</p>
                                            <p className="font-body text-neutral-5 ml-auto">{selectedItem?.status}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {currentPage === 'preference' && (
                        <>
                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px] mx-[40px]">
                                <p className="font-large-title font-neutral-6 font-bold">Preference Length</p>
                                <p className="font-title-3 text-neutral-5">Specify how long the preference should be available. In the case that is temporary, the preference will be deleted once the time is elapsed.</p>
                            </div>

                            <div className="flex flex-col gap-2 mx-[100px]">
                                <Input
                                    type="select"
                                    label="Length"
                                    value={length}
                                    options={[
                                        {
                                            label: "Indefinite",
                                            value: "indefinite"
                                        }
                                    ]}
                                    onChange={(v) => setLength(v)}
                                />

                                <Input
                                    type="select"
                                    label="Once elapsed"
                                    value={elapsed}
                                    options={[
                                        {
                                            label: "Delete Preference",
                                            value: "delete"
                                        },
                                        {
                                            label: "Keep Preference",
                                            value: "keep"
                                        },
                                        {
                                            label: "Notify Followers & Keep Preference",
                                            value: "notify"
                                        },
                                    ]}
                                    onChange={(v) => setElapsed(v)}
                                />

                                <Input
                                    type="textarea"
                                    label="Additional Information (optional)"
                                    placeholder="Any other notes"
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className="mt-auto h-[1px] bg-neutral-2"></div>

                <div className="flex px-5 h-[68px] items-center">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        size="xl"
                        onClick={() => props.onSubmit()}
                    />

                    <div className="flex gap-3 ml-auto">
                        {currentPage === 'overview' && (
                            <PrimaryButton
                                title="Specify Surgeon"
                                type="white"
                                size="xl"
                                onClick={() => {
                                    setCurrentPage('surgeon')
                                }}
                            />
                        )}

                        {currentPage === 'preference' && (
                            <PrimaryButton
                                title="Back"
                                type="gray"
                                size="xl"
                                onClick={() => {
                                    setCurrentPage("surgeon")
                                }}
                            />
                        )}

                        {currentPage !== 'overview' && (
                            <PrimaryButton
                                title={currentPage === 'surgeon' ? "Preference Length" : "Create Preference"}
                                type={currentPage === "preference" ? "primary" : "white"}
                                disabled={!selectedItem}
                                size="xl"
                                onClick={() => {
                                    switch (currentPage) {
                                        case 'surgeon':
                                            setCurrentPage('preference')
                                            break
                                        case 'preference':
                                            props.onSubmit()
                                            break
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default PreferenceDialog