import { useState, useEffect, useRef, useContext } from "react"
import { onSnapshot, collection, doc, Timestamp, setDoc, updateDoc, arrayRemove, arrayUnion } from "firebase/firestore"
import { auth, storage } from "../../firebase/firebase"
import { firestore } from "../../firebase/firebase"
import sanitizeHtml from "sanitize-html"
import uuid from "react-uuid"
import Hotkeys from 'react-hot-keys';
import { useLocation, useNavigate } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { Popover, Rect } from "react-tiny-popover"
import { EntityContext, UserContext } from "../../context/globalContext"

import { Chatroom, ChatMessage, Poll } from "../../model/chat/chat"
import { User } from "../../model/user/user"

import ContentEditable from "react-contenteditable"
import Tag from "../tag/tag"
import Chat from "../chat/chat"
import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import CreatePollDialog from "../createPollDialog/createPollDialog"
import ProfilePicture from "../profilePicture/profilePicture"
import ProfileDialog from "../profileDialog/profileDialog"
import Input from "../input/input"
import ListItemRow from "../listItemRow/listItemRow"
import SearchPopover from "../searchPopover/searchPopover"
import { mockProcedures } from "../../model/procedure/procedure"
import { Vendor, mockVendors } from "../../model/vendor/vendor"
import { mockOrders } from "../../model/order/order"
import { mockContracts } from "../../model/contract/contract"
import { Notification } from "../../model/notification/notification"
import ChatPage from "./chatPage"
// import Popover from "../popover/popover"

const ChatDetailPanel = (props: { chatType: string, chatroom: Chatroom, chatUserId: string, onDismiss: () => void }) => {
    const [tagInput, setTagInput] = useState('')
    const [entitySearch, setEntitySearch] = useState('')
    const [tags, setTags] = useState<{
        label: string,
        value: string
    }[]>([])
    const [entities, setEntities] = useState<{
        id: string,
        name: string,
        type: string
    }[]>([])

    const [messages, setMessages] = useState<ChatMessage[]>()

    const [open, setOpen] = useState(false)
    const [titleDialog, setTitleDialog] = useState(false)
    const [leaveDialog, setLeaveDialog] = useState(false)
    const [statusPopover, setStatusPopover] = useState(false)
    const [entityPopover, setEntityPopover] = useState(false)
    const [content, setContent] = useState("")
    const [title, setTitle] = useState("")
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })

    const dropdownRef = useRef<HTMLInputElement>(null)
    const tagInputRef = useRef<HTMLInputElement>(null)
    const statusPopoverRef = useRef<HTMLDivElement>(null)
    const entityPopoverRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()
    const location = useLocation()
    const userContext = useContext(UserContext)
    const entityContext = useContext(EntityContext)

    useEffect(() => {
        const updateCursorPosition = (event: MouseEvent) => {
            setCursorPosition({ x: event.clientX, y: event.clientY });
        };

        document.addEventListener('mousemove', updateCursorPosition);

        return () => {
            document.removeEventListener('mousemove', updateCursorPosition);
        };
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!dropdownRef.current?.contains(event.target as Node)) {
                setTagInput('')
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    useEffect(() => {
        const participants = props.chatroom.participants

        const participantNames = participants.map((participant) => {
            return {
                label: userContext?.users.find((v) => v.id === participant)?.name?.split(' ')[0] || '',
                value: participant
            }
        })

        setTags(participantNames)
        setTitle(props.chatroom.title)

        const medicalItems = entityContext?.medicalItems || []
        const procedures = mockProcedures
        const vendors = mockVendors
        const orders = mockOrders

        const combinedEntities: {
            id: string,
            name: string,
            type: string
        }[] = [
                ...medicalItems.map((item) => {
                    return {
                        id: item.name,
                        name: item.name,
                        type: 'item'
                    }
                }),
                ...procedures.map((item) => {
                    return {
                        id: item.name,
                        name: item.name,
                        type: 'scene'
                    }
                }),
                ...vendors.map((item) => {
                    return {
                        id: item.name,
                        name: item.name,
                        type: 'vendor'
                    }
                }),
                ...orders.map((item) => {
                    return {
                        id: item.name,
                        name: item.name,
                        type: 'orders'
                    }
                })
            ]

        setEntities(combinedEntities)
    }, [])

    useEffect(() => {
        const unsub = onSnapshot(collection(firestore, 'chats', props.chatroom.id, 'messages'), (snapshot) => {
            const docs = snapshot.docs

            const messages = docs.map((doc) => {
                return doc.data() as ChatMessage
            })

            const sortedMessages = messages.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds)

            setMessages(sortedMessages)
        })

        return () => unsub()
    }, [])

    useEffect(() => {
        setOpen(true)

        return () => {
            setOpen(false)
        }
    }, [])

    const sendMessage = async (text: string, type: string = 'text', reference?: string, referenceType?: string) => {
        const messageId = uuid()
        let message: ChatMessage = {
            id: messageId,
            message: text,
            type: type,
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            readBy: []
        }

        if (reference) {
            message.reference = reference
        }

        if (referenceType) {
            message.referenceType = referenceType
        }

        setContent("")

        await setDoc(doc(firestore, 'chats', props.chatroom.id, 'messages', messageId), message)
        await updateDoc(doc(firestore, 'chats', props.chatroom.id), {
            lastMessage: message
        })

        const notification: Notification = {
            id: uuid(),
            title: '1 new message',
            subtitle: message.message,
            time: Timestamp.now(),
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            recipients: props.chatroom.participants.filter((v) => v !== auth.currentUser?.uid),
            seenBy: [],
            type: 'message'
        }

        await setDoc(doc(firestore, 'notifications', notification.id), notification)
    }

    const addParticipant = async (user: User) => {
        try {
            setTagInput('')
            setTags([...tags, {
                label: user.name.split(' ')[0],
                value: user.id
            }])

            await updateDoc(doc(firestore, 'chats', props.chatroom.id), {
                participants: arrayUnion(user.id)
            })

            await sendMessage(`${user.name.split(' ')[0]} joined the conversation`, 'join')
        } catch {

        }
    }

    const removeParticipant = async (tag: { label: string, value: string }) => {
        try {
            setTags([...tags].filter((item) => item !== tag))

            await updateDoc(doc(firestore, 'chats', props.chatroom.id), {
                participants: arrayRemove(tag.value)
            })

            await sendMessage(`${tag.label} left the conversation`, 'leave')
        } catch {

        }
    }

    const updateChatroom = async (value: Record<string, any>) => {
        await updateDoc(doc(firestore, 'chats', props.chatroom.id), value)
    }

    const addEntity = async (entity: { id: string, name: string, type: string }) => {
        setEntityPopover(false)

        await updateDoc(doc(firestore, 'chats', props.chatroom.id), {
            entities: arrayUnion({ id: entity.id, type: entity.type })
        })
    }

    const getVendorFillRate = (vendor: Vendor): number => {
        const contracts = mockContracts.filter((contract) => vendor.contracts.toArray().includes(contract.id))
        const fillRates = contracts.map((v) => v.fillRate).reduce((a, c) => a + c, 0)

        if (contracts.length === 0) {
            return 0
        }

        return fillRates / contracts.length
    }

    const checkIfCursorInBounds = (cursor: { x: number, y: number }, popoverRect: Rect): boolean => {
        const rect = popoverRect
        const isInBounds =
            cursor.x >= rect.left &&
            cursor.x <= rect.right &&
            cursor.y >= rect.top &&
            cursor.y <= rect.bottom

        return isInBounds
    }

    return (
        <>
            <div style={{ width: open ? '400px' : '0px', pointerEvents: open ? 'all' : 'none' }} className={`absolute overflow-hidden top-0 right-0 flex flex-col h-screen bg-material-ultra border-l border-neutral-2 transition-width ease-in-out duration-300 z-[300]`}>
                <div className="flex flex-col px-2.5 py-1 gap-2.5 backdrop-blur-2xl">
                    <div className="cursor-pointer flex gap-2 items-center">
                        <div className="py-4 px-1">
                            <img onClick={() => {
                                setOpen(false)
                                setTimeout(() => {
                                    props.onDismiss()
                                }, 300)
                            }} className="h-[12px] w-[12px]" src={`/images/icons/ic-chevron-left.svg`}></img>
                        </div>

                        <div className={`h-[20px] min-w-[20px] gradient-teal rounded-md flex items-center justify-center`}>
                            <img className="h-[12px] w-[12px]" src={`/images/icons/ic-square-dashed.svg`}></img>
                        </div>

                        <div className="my-2">
                            <p className="font-body">{props.chatroom.title}</p>

                            <div className="flex gap-1 items-center">
                                <div className="w-[8px] h-[8px] bg-success rounded-full"></div>
                                <p className="font-subheadline text-neutral-5">Active</p>
                            </div>
                        </div>

                        <Popover
                            ref={statusPopoverRef}
                            containerStyle={{ zIndex: '500' }}
                            isOpen={statusPopover}
                            positions={['bottom', 'right']}
                            content={({ position, childRect, popoverRect }) => (
                                <div
                                    onMouseLeave={(v) => { if (!checkIfCursorInBounds({ x: v.clientX, y: v.clientY }, popoverRect)) { setStatusPopover(false) } }}
                                    style={{ boxShadow: '0px 38px 90px 0px #00000040; 0px 0px 2px 0px #0000000D; 0px 0px 1px 0px #00000099' }}
                                    className="relative mt-4 bg-dialog backdrop-blur-2xl rounded-md border border-neutral-2"
                                >
                                    <div className="flex flex-col p-4 w-[372px] max-h-[750px] overflow-y-scroll">
                                        <p className="font-title-2 font-bold text-center">{props.chatroom.title}</p>

                                        <div className="flex gap-[60px] my-5 mx-auto">
                                            <div onClick={() => {
                                                updateChatroom({ resolved: true })
                                                props.onDismiss()
                                            }} className="flex flex-col gap-1 items-center cursor-pointer">
                                                <div className="flex items-center justify-center w-[28px] h-[28px] bg-[#0F62FE] rounded-full">
                                                    <img className="h-[12px] w-[12px]" src="/images/icons/ic-checkmark.svg" />
                                                </div>

                                                <p className="font-body text-[#0F62FE]">Resolve</p>
                                            </div>

                                            <div onClick={() => setTitleDialog(true)} className="flex flex-col gap-1 items-center cursor-pointer">
                                                <div className="flex items-center justify-center w-[28px] h-[28px] bg-neutral-2 rounded-full">
                                                    <img className="h-[12px] w-[12px]" src="/images/icons/ic-pencil-blue.svg" />
                                                </div>

                                                <p className="font-body text-[#0F62FE]">Edit</p>
                                            </div>
                                        </div>

                                        <p className="font-subheadline text-neutral-5 py-2 border-t border-neutral-2">Ending the conversation marks it as complete and removes the need for participant input, effectively disbanding the group.</p>

                                        <p onClick={() => setLeaveDialog(true)} className="border-b border-neutral-2 font-subheadline text-critical mt-8 pb-2 cursor-pointer">{props.chatroom.participants.length === 1 ? 'Disband Conversation' : 'Leave Conversation'}</p>

                                        <div className="flex flex-col gap-2 mt-9">
                                            <p className="font-body">Entity</p>

                                            {props.chatroom.entities.map((entity) => {
                                                const item = entityContext?.medicalItems.find((v) => v.name === entity.id)
                                                const scene = mockProcedures.find((v) => v.name === entity.id)
                                                const vendor = mockVendors.find((v) => v.name === entity.id)
                                                const order = mockOrders.find((v) => v.name === entity.id)

                                                return (
                                                    <>
                                                        {item && (
                                                            <ListItemRow
                                                                key={uuid()}
                                                                title={item?.name || ''}
                                                                subtitle={item?.disruption === "None" ? item.status : item?.disruption}
                                                                icon={{
                                                                    icon: 'ic-warning-triangle.svg',
                                                                    gradient: 'orange'
                                                                }}
                                                                arrowLabel={(undefined || item?.assignee !== "None") && item?.disruption !== "None" ? item?.assignee === "None" ? undefined : item?.assignee : ""}
                                                                arrowRight={true}
                                                                onArrowClick={() => {
                                                                    navigate(`../dashboard/inventory/${item?.id}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                                }}
                                                            >
                                                                {!undefined && item?.disruption !== "None" && !item?.assignee && (
                                                                    <div className="z-[100]">
                                                                        <PrimaryButton
                                                                            title="Assign"
                                                                            type="light-ghost"
                                                                            onClick={() => {
                                                                                navigate(`../dashboard/inventory/${item?.id}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </ListItemRow>
                                                        )}

                                                        {scene && (
                                                            <ListItemRow
                                                                title={scene.name}
                                                                subtitle={`${scene.items} items • £${scene.cost}`}
                                                                arrowRight={true}
                                                                hideBorder={true}
                                                                onArrowClick={() => {
                                                                    navigate(`../dashboard/procedure/${scene.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                                }}
                                                            />
                                                        )}

                                                        {vendor && (
                                                            <ListItemRow
                                                                title={vendor.name}
                                                                subtitle={`${getVendorFillRate(vendor).toFixed(2)}% on time in full`}
                                                                icon={{
                                                                    icon: getVendorFillRate(vendor) <= 55 ? 'ic-warning-circle-fill.svg' : getVendorFillRate(vendor) <= 70 ? 'ic-warning-triangle.svg' : 'ic-checkmark.svg',
                                                                    gradient: getVendorFillRate(vendor) <= 55 ? 'red' : getVendorFillRate(vendor) <= 70 ? 'orange' : 'light-grey'
                                                                }}
                                                                arrowLabel={`${vendor.contracts.toArray().length} contracts`}
                                                                arrowRight={true}
                                                                onArrowClick={() => navigate(`../dashboard/vendor/${vendor.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })}
                                                            />
                                                        )}

                                                        {order && (
                                                            <ListItemRow
                                                                title={order.name}
                                                                subtitle={`${order.itemsTotal} Items`}
                                                                icon={order.status === 'Disrupted' ? {
                                                                    icon: 'ic-info-circle-fill-white.svg',
                                                                    gradient: 'red'
                                                                } : order.status === 'Fill' ? {
                                                                    icon: 'ic-warning-triangle.svg',
                                                                    gradient: 'orange'
                                                                } : {
                                                                    icon: 'ic-checkmark.svg',
                                                                    gradient: 'blue'
                                                                }}
                                                                arrowLabel={order.location}
                                                                arrowRight={true}
                                                            />
                                                        )}
                                                    </>

                                                )
                                            })}

                                            {props.chatroom.entities.length < 5 && (
                                                <SearchPopover
                                                    ref={entityPopoverRef}
                                                    isOpen={entityPopover}
                                                    positions={['bottom']}
                                                    content={(searchPopoverRect) => (
                                                        <div onMouseLeave={(v) => { if (!checkIfCursorInBounds({ x: v.clientX, y: v.clientY }, searchPopoverRect)) { setEntityPopover(false) } }}>
                                                            <div className="flex flex-col p-4 w-[340px] gap-1.5">
                                                                <p className="font-body text-neutral-5">{`Link entity to "${props.chatroom.title}"`}</p>

                                                                <Input
                                                                    autoFocus={true}
                                                                    type="tooltip"
                                                                    placeholder="Search..."
                                                                    value={entitySearch}
                                                                    onChange={(v) => setEntitySearch(v)}
                                                                />

                                                                <div className="flex flex-col gap-0.5">
                                                                    {entities.filter((v) => v.name.toLowerCase().includes(entitySearch.toLowerCase()) || !entitySearch).splice(0, 5).map((item, index) => {
                                                                        return (
                                                                            <div key={index} onClick={() => addEntity(item)} className="cursor-pointer group flex gap-1.5 py-1 px-2 items-center hover:bg-focused-selected rounded-md">
                                                                                {item.type === 'item' && (
                                                                                    <>
                                                                                        <img className="w-[14px] h-[14px] fill-black group-hover:hidden" src="/images/icons/ic-shipping-box-black.svg" />
                                                                                        <img className="w-[14px] h-[14px] fill-black hidden group-hover:block" src="/images/icons/ic-shipping-box-white.svg" />
                                                                                    </>
                                                                                )}

                                                                                {item.type === 'scene' && (
                                                                                    <>
                                                                                        <img className="w-[14px] h-[14px] fill-black group-hover:hidden" src="/images/icons/ic-scenes.svg" />
                                                                                        <img className="w-[14px] h-[14px] fill-black hidden group-hover:block" src="/images/icons/ic-scenes-white.svg" />
                                                                                    </>
                                                                                )}

                                                                                {item.type === 'vendor' && (
                                                                                    <>
                                                                                        <img className="w-[14px] h-[14px] fill-black group-hover:hidden" src="/images/icons/ic-credit-card.svg" />
                                                                                        <img className="w-[14px] h-[14px] fill-black hidden group-hover:block" src="/images/icons/ic-credit-card-white.svg" />
                                                                                    </>
                                                                                )}

                                                                                {item.type === 'order' && (
                                                                                    <>
                                                                                        <img className="w-[14px] h-[14px] fill-black group-hover:hidden" src="/images/icons/ic-shipping-box-black.svg" />
                                                                                        <img className="w-[14px] h-[14px] fill-black hidden group-hover:block" src="/images/icons/ic-shipping-box-white.svg" />
                                                                                    </>
                                                                                )}

                                                                                <p className="font-body font-semibold w-full text-ellipsis line-clamp-1 group-hover:text-white">{item.name}</p>

                                                                                <p className="font-body text-neutral-5 group-hover:text-white">{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</p>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>

                                                            <div className="absolute h-4 w-4 mx-auto left-1/2 top-[-8px]  rotate-45 transform border-t border-l border-neutral-2 bg-dialog z-[501]"></div>
                                                        </div>
                                                    )}
                                                >

                                                    <div className="flex gap-2 items-center cursor-pointer">
                                                        <div className="h-[32px] w-[36px] rounded-full flex items-center justify-center bg-neutral-2">
                                                            <img className="w-[10px] h-[10px]" src="/images/icons/ic-plus-blue.svg" />
                                                        </div>


                                                        <p className="font-body text-[#0F62FE] w-full" onClick={() => { setEntityPopover(!entityPopover) }}>Link Entity</p>

                                                    </div>
                                                </SearchPopover>
                                            )}
                                        </div>

                                        <div className="flex flex-col gap-2 mt-9">
                                            <p className="font-body">Members</p>

                                            {props.chatroom.participants.map((participant) => {
                                                const user = userContext?.users.find((v) => v.id === participant)

                                                return (
                                                    <div className="flex gap-2 items-center">
                                                        {user && (
                                                            <>
                                                                <ProfilePicture user={user} size={32} />

                                                                <p className="font-body">{user.name}</p>
                                                            </>
                                                        )}
                                                    </div>
                                                )
                                            })}

                                            <div onClick={() => {
                                                setStatusPopover(false)
                                                tagInputRef.current?.focus()
                                            }} className="flex gap-2 items-center cursor-pointer">
                                                <div className="h-[32px] w-[32px] rounded-full flex items-center justify-center bg-neutral-2">
                                                    <img className="w-[10px] h-[10px]" src="/images/icons/ic-plus-blue.svg" />
                                                </div>

                                                <p className="font-body text-[#0F62FE]">Add Member</p>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-2 mt-9">
                                            <p className="font-body">Attachments</p>

                                            <div className="flex gap-2 overflow-x-scroll w-full whitespace-nowrap">
                                                {messages?.filter((v) => v.type === 'attachment' && ['.png', '.jpg', 'jpeg'].some((t) => v.referenceType?.includes(t))).map((message) => {

                                                    const user = userContext?.users.find((v) => v.id === message.createdBy)
                                                    return (
                                                        <div className="relative min-w-[90px] min-h-[90px] h-[90px] w-[90px] rounded-lg">
                                                            <img className="w-full h-full rounded-lg" src={message.reference || ''} />

                                                            {user && (
                                                                <div className="absolute right-2 top-2">
                                                                    <ProfilePicture
                                                                        user={user}
                                                                        size={30}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="absolute h-4 w-4 right-[8px] top-[-8px]  rotate-45 transform border-t border-l border-neutral-2 bg-dialog z-[501]"></div>
                                </div>
                            )}
                        >
                            <div
                                className="ml-auto"
                                onClick={() => { setStatusPopover(!statusPopover); setEntityPopover(false) }}
                            >
                                <img className="w-[16px] h-[16px]" src="/images/icons/ic-info-circle-black.svg" />
                            </div>
                        </Popover>

                    </div>
                </div>

                {true && (
                    <div className="flex flex-col px-4 py-1 gap-2.5 bg-white/50 border-t border-b border-neutral-2 backdrop-blur-2xl">
                        <div className="relative cursor-pointer flex gap-2 my-2 items-center h-[24px]">
                            <p className="font-body text-neutral-5">To:</p>

                            {tags.filter((v) => v.value !== auth.currentUser?.uid).map((tag, index) => {
                                return (
                                    <Tag
                                        title={tag.label}
                                        background="#5FABFF"
                                        onDismiss={() => {
                                            removeParticipant(tag)
                                        }}
                                    />
                                )
                            })}

                            <input ref={tagInputRef} value={tagInput} onChange={(e) => setTagInput(e.target.value)} className="font-body focus:outline-none bg-transparent"></input>

                            {tagInput && (
                                <div ref={dropdownRef} className="max-h-[300px] overflow-y-auto w-min absolute left-6 top-5 z-[300] origin-top-left rounded-md bg-material-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none backdrop-blur-2xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                                    <div className="p-1" role="none">
                                        {(userContext?.users || []).filter((option) => (option.name || '').toLowerCase().includes(tagInput.toLowerCase()) || tagInput === '').splice(0, 5).map((option) => {
                                            return (
                                                <div onClick={() => {
                                                    addParticipant(option)
                                                }} className="group cursor-pointer flex gap-2 w-full p-1 hover:bg-primary-4 rounded-md items-center">
                                                    {option.photoURL ?
                                                        <img className="w-[24px] h-[24px] rounded-full" src={option?.photoURL}></img>
                                                        :
                                                        <div className="w-[24px] h-[24px] gradient-light-grey rounded-full text-white text-center">
                                                            <p className="font-body mt-[4px]">{option.name[0]}</p>
                                                        </div>
                                                    }

                                                    <div>
                                                        <p className="font-body whitespace-nowrap group-hover:text-white">{option.name}</p>
                                                        <p className="font-subheadline whitespace-nowrap text-neutral-5 group-hover:text-white/50">{option.email}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <ChatPage
                    chatroom={props.chatroom}
                />
            </div>

            {titleDialog && (
                <Dialog>
                    <div className="relative p-5 w-[330px]">
                        <div className="flex">
                            <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                                <img className="h-[36px] w-[36px] gradient-light-grey p-1.5 rounded-full" src={'/images/icons/ic-pencil-lines-white.svg'}></img>
                            </div>

                        </div>

                        <div className="flex flex-col gap-[10px] my-[20px]">
                            <p className="font-body font-neutral-6 font-semibold text-center">Change Title</p>
                            <p className="font-subheadline font-neutral-2 text-center">You can change the conversation title to reflect its topic more precisely.</p>

                            <Input
                                type='text'
                                placeholder="Write title..."
                                label="Title"
                                value={title}
                                onChange={(v) => setTitle(v)}
                            />
                        </div>

                        <div className="flex gap-[10px]">
                            <PrimaryButton
                                title="Dismiss"
                                type="gray"
                                size="xl"
                                onClick={() => setTitleDialog(false)}
                            />

                            <div className="ml-auto">
                                <PrimaryButton
                                    title="Update Title"
                                    type="primary"
                                    size="xl"
                                    disabled={!title}
                                    onClick={() => {
                                        setTitleDialog(false)
                                        updateChatroom({ title: title })
                                    }}
                                />
                            </div>
                        </div>

                        <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
                    </div>
                </Dialog>
            )}

            {leaveDialog && (
                <Dialog>
                    <div className="relative p-5 w-[330px]">
                        <div className="flex">
                            <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                                <div className="flex items-center justify-center h-[44px] w-[44px] gradient-light-grey rounded-full">
                                    <img className="h-[26px] w-[26px]" src={'/images/icons/ic-door-walk.svg'}></img>
                                </div>

                            </div>

                        </div>

                        <div className="flex flex-col gap-[10px] my-[20px]">
                            {props.chatroom.participants.length === 1 ?
                                <>
                                    <p className="font-body font-neutral-6 font-semibold text-center">Disband Conversation?</p>
                                    <p className="font-subheadline font-neutral-2 text-center">Disbanding the group will permanently revoke all members' access to the conversation.</p>
                                </>
                                :
                                <>
                                    <p className="font-body font-neutral-6 font-semibold text-center">Leave Conversation?</p>
                                    <p className="font-subheadline font-neutral-2 text-center">You will not be able to view conversation anymore and only participants can invite you back.</p>
                                </>
                            }

                        </div>

                        {props.chatroom.participants.length === 1 ?
                            <div className="flex gap-2">
                                <PrimaryButton
                                    title="Not Now"
                                    type="gray"
                                    size="xl"
                                    maxWidth={true}
                                    onClick={() => setLeaveDialog(false)}
                                />

                                <PrimaryButton
                                    title="Yes, Disband"
                                    type="primary"
                                    size="xl"
                                    disabled={!title}
                                    maxWidth={true}
                                    onClick={() => {
                                        const tag = tags.find((v) => v.value === auth.currentUser?.uid)

                                        if (tag) {
                                            removeParticipant(tag)
                                            setLeaveDialog(false)
                                            props.onDismiss()
                                        }
                                    }}
                                />
                            </div>
                            :
                            <div className="flex flex-col gap-2">
                                <PrimaryButton
                                    title="Yes, Leave Conversation"
                                    type="primary"
                                    size="xl"
                                    disabled={!title}
                                    maxWidth={true}
                                    onClick={() => {
                                        const tag = tags.find((v) => v.value === auth.currentUser?.uid)

                                        if (tag) {
                                            removeParticipant(tag)
                                            setLeaveDialog(false)
                                            props.onDismiss()
                                        }
                                    }}
                                />

                                <PrimaryButton
                                    title="Not Now"
                                    type="gray"
                                    size="xl"
                                    maxWidth={true}
                                    onClick={() => setLeaveDialog(false)}
                                />
                            </div>
                        }


                        <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
                    </div>
                </Dialog>
            )}
        </>
    )
}

export default ChatDetailPanel