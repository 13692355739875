import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import CreateAccountDialog from "../../components/createAccountDialog/createAccountDialog"

const Onboarding = () => {
    const [showDialog, setShowDialog] = useState(false)
    const [showLoading, setShowLoading] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            setShowDialog(true)
            setShowLoading(false)
        }, 1500)
    }, [])


    return (
        <div className="relative h-screen bg-repeat flex flex-col bg-[url('/public/images/backgrounds/bg-gradient-login.svg')]">
            <iframe className="absolute w-screen h-screen" src="/images/backgrounds/bg-animation.html"></iframe>

            {showDialog && (
                <CreateAccountDialog
                    onSubmit={() => {
                        setShowDialog(false)
                        setShowLoading(true)

                        setTimeout(() => {
                            navigate('/login/sleep', { replace: true })
                        }, 1500)
                    }}
                />
            )}

            {showLoading && (
                <div className="absolute w-screen h-screen bg-white/70 z-[1] flex flex-col justify-center items-center">
                    <img className="h-[28px] w-[28px]" src={'/images/icons/ic-loading-ava-animated.svg'}></img>

                    <p className="font-body font-medium mt-10">A V A</p>
                </div>
            )}
        </div>
    )
}

export default Onboarding