const PrimaryButton = (props: {
    type: string,
    title: string,
    icon?: string,
    size?: string,
    maxWidth?: boolean,
    disabled?: boolean,
    iconSize?: number,
    leftIcon?: string,
    onClick?: () => void
}) => {
    const getButtonStyle = (type: string): string => {
        switch (type) {
            case "ghost":
                return "bg-transparent"
            case "light-ghost":
                return "bg-primary-2/20"
            case "white":
                return "bg-white"
            case "gray":
                return "bg-neutral-2"
            case "primary":
                return `bg-gradient-to-t from-primary-4 to-primary-5`
            case "green":
                return `gradient-green`
            case "critical":
                return 'bg-critical'
            default:
                return `bg-gradient-to-t from-primary-4 to-primary-5`
        }
    }

    const getButtonShadowStyle = (type: string): string => {
        switch (type) {
            case "white":
                return "0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)"
            case "primary":
                return `0px 0px 0px 0.5px rgba(15, 98, 254, 0.12), 0px 1px 2.5px 0px rgba(15, 98, 254, 0.25)`
            default:
                return ``
        }
    }

    const getButtonTextStyle = (type: string, disabled: boolean): string => {
        if (disabled) {
            switch (type) {
                case "ghost":
                    return "text-neutral-5"
                case "light-ghost":
                    return "text-primary-4"
                case "primary":
                    return `text-white`
                case "green":
                    return `text-white`
                case "critical":
                    return `text-white`
                default:
                    return `text-neutral-5`
            }
        } else {
            switch (type) {
                case "ghost":
                    return "text-neutral-5"
                case "light-ghost":
                    return "text-primary-4"
                case "primary":
                    return `text-white`
                case "green":
                    return `text-white`
                case "critical":
                    return `text-white`
                default:
                    return `text-black`
            }
        }
    }

    const getButtonHeight = (size: string): number => {
        switch (size) {
            case "sm":
                return 22
            case "md":
                return 28
            case "lg":
                return 30
            case "xl":
                return 36
            default:
                return 28
        }
    }

    const getButtonPadding = (size: string): { l: number, r: number, t: number, b: number } => {
        switch (size) {
            case "sm":
                return { l: props.icon ? 16 : 8, r: 8, t: 8, b: 8 }
            case "md":
                return { l: props.icon ? 16 : 12, r: 12, t: 8, b: 8 }
            case "lg":
                return { l: props.icon ? 16 : 12, r: 12, t: 8, b: 8 }
            case "xl":
                return { l: props.icon ? 16 : 12, r: 12, t: 8, b: 8 }
            default:
                return { l: props.icon ? 16 : 12, r: 12, t: 8, b: 8 }
        }
    }

    return (
        <div style={{
            height: `${getButtonHeight(props.size || 'lg')}px`,
            opacity: props.disabled ? '0.4' : '1',
            boxShadow: getButtonShadowStyle(props.type),
            paddingLeft: getButtonPadding(props.size || 'lg').l,
            paddingRight: getButtonPadding(props.size || 'lg').r,
            paddingBottom: getButtonPadding(props.size || 'lg').b,
            paddingTop: getButtonPadding(props.size || 'lg').t,
        }}
            onClick={(e) => {
                e.stopPropagation()

                if (!props.disabled) {
                    props.onClick?.()
                }
            }} className={`${props.maxWidth ? 'w-full' : 'w-min'} flex ${getButtonStyle(props.type)} items-center gap-2 rounded-md cursor-pointer justify-center`}>
            {props.leftIcon && (
                <img style={{ height: `${props.iconSize || 16}px`, width: `${props.iconSize || 16}px` }} className="" src={`/images/icons/${props.leftIcon}`}></img>
            )}

            <p className={`text-center font-body whitespace-nowrap ${getButtonTextStyle(props.type, props.disabled || false)}`}>{props.title}</p>

            {props.icon && (
                <img style={{ height: `${props.iconSize || 16}px`, width: `${props.iconSize || 16}px` }} className="mr-2" src={`/images/icons/${props.icon}`}></img>
            )}
        </div>
    )
}

export default PrimaryButton