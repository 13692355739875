import { useState, useEffect, useContext, useRef } from "react"
import { Chatroom, Poll } from "../../model/chat/chat"
import { Timestamp, arrayRemove, arrayUnion, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { auth, firestore } from "../../firebase/firebase";
import Checkbox from "../checkbox/checkbox";
import { UserContext } from "../../context/globalContext";

const PollMessage = (props: { pollId: string, chatroom: Chatroom }) => {
    const [poll, setPoll] = useState<Poll | null>(null)

    const [approved, setApproved] = useState(false)
    const [rejected, setRejected] = useState(false)

    const [popup, setPopup] = useState('')

    const [popupHeight, setPopupHeight] = useState(0)

    const userContext = useContext(UserContext)

    const popupRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (auth.currentUser) {
            setRejected(poll?.rejectedBy.includes(auth.currentUser?.uid) || false)
            setApproved(poll?.approvedBy.includes(auth.currentUser?.uid) || false)
        }

    }, [poll])

    useEffect(() => {
        const unsub = onSnapshot(doc(firestore, 'chats', props.chatroom.id, 'polls', props.pollId), (doc) => {
            setPoll(doc.data() as Poll)
        })

        return () => unsub()
    }, [])

    const vote = async (approve: boolean) => {
        if (!auth.currentUser) { return }

        let data = {}

        if (approve) {
            data = {
                approvedBy: arrayUnion(auth.currentUser.uid),
                rejectedBy: arrayRemove(auth.currentUser.uid),
            }
        } else {
            data = {
                approvedBy: arrayRemove(auth.currentUser.uid),
                rejectedBy: arrayUnion(auth.currentUser.uid),
            }
        }

        updateDoc(doc(firestore, 'chats', props.chatroom.id, 'polls', props.pollId), data)
        updateDoc(doc(firestore, 'chats', props.chatroom.id), { answeredPoll: true })

        props.chatroom.entities.forEach((entity) => {
            if (entity.type === 'item') {
                updateDoc(doc(firestore, 'items', entity.id), {
                    lastUpdated: Timestamp.now(),
                    lastUpdatedBy: auth.currentUser?.uid
                })
            }
        })
    }

    return (
        <>
            <div className="relative bg-[#DCDCDE] flex flex-col gap-1 rounded-md w-full p-2.5">
                <p className="font-title-3 font-semibold">{poll?.question}</p>

                <div className="flex gap-1 items-center">
                    <img className="w-[14px] h-[14px]" src='/images/icons/ic-checkmark-circle-gray.svg' />
                    <p className="font-callout font-semibold text-neutral-5">Select one</p>
                </div>

                <div className="flex gap-2 w-full items-center">
                    <div className="w-5">
                        <Checkbox value={approved} onClick={() => vote(true)} />
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                        <div className="flex w-full">
                            <p className="font-body text-neutral-5">Approve</p>
                            <p onMouseEnter={() => setPopup('approved')} onMouseLeave={() => setPopup('')} className="font-body text-neutral-5 ml-auto">{poll?.approvedBy.length || 0}</p>
                        </div>

                        <div className="h-1 bg-neutral-3 rounded-full w-full">
                            <div style={{ width: poll?.approvedBy.length === 0 && poll.rejectedBy.length === 0 ? 0 : `${(poll?.approvedBy.length || 0) / ((poll?.approvedBy.length || 0) + (poll?.rejectedBy.length || 0)) * 100}%` }} className="h-1 bg-[#0F62FE] rounded-full w-full">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex gap-2 w-full items-center">
                    <div className="w-5">
                        <Checkbox value={rejected} onClick={() => vote(false)} />
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                        <div className="flex w-full">
                            <p className="font-body text-neutral-5">Reject</p>
                            <p onMouseEnter={() => setPopup('rejected')} onMouseLeave={() => setPopup('')} className="font-body text-neutral-5 ml-auto">{poll?.rejectedBy.length || 0}</p>
                        </div>

                        <div className="h-1 bg-neutral-3 rounded-full w-full">
                            <div style={{ width: poll?.rejectedBy.length === 0 && poll.rejectedBy.length === 0 ? 0 : `${(poll?.rejectedBy.length || 0) / ((poll?.approvedBy.length || 0) + (poll?.rejectedBy.length || 0)) * 100}%` }} className="h-1 bg-[#0F62FE] rounded-full w-full">

                            </div>
                        </div>
                    </div>
                </div>

                {popup && (
                    <div ref={popupRef} style={{ top: `-${popupRef.current?.getBoundingClientRect().height}px` }} className="max-h-[300px] right-1 overflow-y-auto origin-bottom-left w-min absolute z-[300] rounded-md bg-material-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none backdrop-blur-2xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                        <div className="p-1" role="none">
                            {userContext?.users.filter((option) => popup === 'approved' ? poll?.approvedBy.includes(option.id) : poll?.rejectedBy.includes(option.id)).map((option) => {
                                return (
                                    <div className="group cursor-pointer flex gap-2 w-full p-1 hover:bg-primary-4 rounded-md items-center">
                                        {option.photoURL ?
                                            <img className="w-[24px] h-[24px] rounded-full" src={option?.photoURL}></img>
                                            :
                                            <div className="w-[24px] h-[24px] gradient-light-grey rounded-full text-white text-center">
                                                <p className="font-body mt-[4px]">{option.name[0]}</p>
                                            </div>
                                        }

                                        <div>
                                            <p className="font-body whitespace-nowrap group-hover:text-white">{option.name}</p>
                                            <p className="font-subheadline whitespace-nowrap text-neutral-5 group-hover:text-white/50">{option.email}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default PollMessage