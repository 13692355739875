import { useState, useEffect, useContext, useRef } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { MedicalItem } from "../../model/medicalItem/medicalItem"
import { mockSurgeons } from "../../model/people/people"
import { set, ref, onValue, remove, update } from "firebase/database"
import { auth, database, firestore, functions } from "../../firebase/firebase"

import BarChart from "../../components/barChart/barChart"
import Toggle from "../../components/toggle/toggle"
import SidebarNavigationLink from "../../components/sidebarNavigationLink/sidebarNavigationLink"
import SearchBar from "../../components/searchBar/searchBar"
import PrimaryButton from "../../components/primaryButton/primaryButton"
import Select from "../../components/select/select"
import ListItemRow from "../../components/listItemRow/listItemRow"
import StackedListBar from "../../components/stackedListBar/stackedListBar"
import Tag from "../../components/tag/tag"
import SearchPopover from "../../components/searchPopover/searchPopover"
import { Procedure, mockProcedures } from "../../model/procedure/procedure"
import Dialog from "../../components/dialog/dialog"
import SubscribeDialog from "../../components/subscribeDialog/subscribeDialog"
import SubstituteDialog from "../../components/substituteDialog/substituteDialog"
import DatePickerDialog from "../../components/datePickerDialog/datePickerDialog"
import DisruptedDialog from "../../components/disruptedDialog/disruptedDialog"
import Input from "../../components/input/input"
import uuid from "react-uuid"
import { mockSpecialties } from "../../model/specialty/specialty"
import SidePanel from "../../components/sidePanel/sidePanel"
import { Order, mockOrders } from "../../model/order/order"
import { Vendor, mockVendors } from "../../model/vendor/vendor"
import { ChatContext, CursotChatContext, EntityContext, UserContext } from "../../context/globalContext"
import { useCookies } from "react-cookie"
import DisableDialog from "../../components/disableDialog/disableDialog"
import { Substitute } from "../../model/substitute/substitute"

import '../../utils/string'
import { mockPreferences } from "../../model/preference/preference"
import { mockMedicalItemUsages } from "../../model/medicalItem/medicalItemUsage"
import { getPastSixWeeksStartDates } from "../../utils/date"
import { mockMedicalItemOrders } from "../../model/medicalItem/medicalItemOrder"
import { mockMedicalItemDisruptions } from "../../model/medicalItem/medicalItemDisruption"
import { mockContracts } from "../../model/contract/contract"
import { Timestamp, arrayRemove, arrayUnion, collection, doc, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore"
import { Disruption } from "../../model/disruption/disruption"
import { Notification } from "../../model/notification/notification"
import { Chatroom } from "../../model/chat/chat"
import ProfilePicture from "../../components/profilePicture/profilePicture"
import { Rect } from "react-tiny-popover"
import moment from "moment"
import UseSubstituteDialog from "../../components/useSubstituteDialog/useSubstituteDialog"
import { httpsCallable } from "firebase/functions"

const InventoryItem = () => {
    const { id } = useParams()

    const [currentPage, setCurrentPage] = useState('')

    const [medicalItem, setMedicalItem] = useState<MedicalItem>({
        "id": "",
        "name": "",
        "status": "",
        "category": "",
        "disruption": "",
        "unspsc": 0,
        "assignee": "",
        "team": "",
        "tags": [],
        "manufacturer": "Johnson & Johnson",
        "manufacturerID": "VICRYL-6",
        "erpID": "9876543J",
        "price": 500,
        "amount": 2,
        "unitOfMeasure": "Box",
        "procedures": "PR-011, PR-014, PR-029, PR-030",
        "vendor": "VNDR-0000324",
        "orders": "[ORDR-0-0000013,ORDR-1-0000055,ORDR-2-0000020,ORDR-3-0000020,ORDR-4-0000022,ORDR-5-0000014,ORDR-6-0000017,ORDR-7-0000038,ORDR-8-0000042,ORDR-9-0000018,ORDR-10-0000024,ORDR-11-0000057,ORDR-12-0000052,ORDR-13-0000046,ORDR-14-0000035,ORDR-15-0000031,ORDR-16-0000047,ORDR-17-0000042,ORDR-18-0000020]"
    })

    const [tagSearch, setTagSearch] = useState('')

    const [selectedSubstituteId, setSelectedSubstituteId] = useState<string>()
    const [selectedProcedure, setSelectedProcedure] = useState<Procedure>()
    const [selectedOrder, setSelectedOrder] = useState<Order>()
    const [selctedVendor, setSelectedVendor] = useState<Vendor>()
    const [chats, setChats] = useState<Chatroom[]>([])
    const [assigneeText, setAssigneeText] = useState<string | undefined>()

    const [existingTags, setExistingTags] = useState<string[]>([])

    const [activeDialog, setActiveDialog] = useState(false)
    const [subscribeDialog, setSubscribeDialog] = useState(false)
    const [substituteDialog, setSubstituteDialog] = useState(false)
    const [disruptedDialog, setDisruptedDialog] = useState(false)
    const [useSubstituteDialog, setUseSubstituteDialog] = useState(false)
    const [tagPopover, setTagPopover] = useState(false)

    const [cursors, setCursors] = useState<{ [uid: string]: { mouseX: number, mouseY: number, uid: string, username: string, currentPage: string, photoURL?: string } }>()

    const userContext = useContext(UserContext)
    const chatContext = useContext(ChatContext)
    const entityContext = useContext(EntityContext)
    const cursorChatContext = useContext(CursotChatContext)

    const location = useLocation()
    const navigate = useNavigate()

    const orders = mockOrders.filter((v) => medicalItem?.orders.toArray().includes(v.id))
    const vendors = mockVendors.filter((v) => v.id === medicalItem?.vendor)
    const disruption = entityContext?.disruptions.find((v) => v.entity === medicalItem.id)
    const lastUpdatedUser = userContext?.users.find((v) => v.id === medicalItem.lastUpdatedBy)
    const selectedSubstitute = entityContext?.substitutes.find((v) => v.id === selectedSubstituteId)
    const usedSubstitute = entityContext?.substitutes.find((v) => v.primaryItemID === medicalItem.id && v.status === 'Used')
    const previousLocation = location.state?.previousLocationPathName.split('/').at(-1) || ''

    const tagPopoverRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (disruption) {
            setAssigneeText(disruption.assignee ? `${userContext?.users.find((v) => v.id === disruption.assignee)?.name} (${userContext?.users.find((v) => v.id === disruption.assignee)?.team})` : '')
        }
    }, [disruption, userContext?.users])

    useEffect(() => {
        const item = entityContext?.medicalItems.find((v) => v.id === id)

        if (item) {
            setMedicalItem(item)
        }
    }, [entityContext?.medicalItems, id])

    useEffect(() => {
        const proceduresTags = mockProcedures.map((v) => v.category?.toArray() || []).flatMap((v) => v)
        const vendorsTags = mockVendors.map((v) => v.tag?.toArray() || []).flatMap((v) => v)

        const set: Set<string> = new Set([...medicalItem?.tags || [], ...proceduresTags, ...vendorsTags]);

        setExistingTags(Array.from(set))
    }, [entityContext?.medicalItems])

    useEffect(() => {
        setCurrentPage(location.hash)
    }, [location])

    useEffect(() => {
        document.title = `Inventory — ${medicalItem.name}`
    }, [medicalItem.name])

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (auth.currentUser) {
                set(ref(database, 'cursorChat/' + medicalItem.id + '/' + (auth.currentUser?.uid || '')), {
                    username: userContext?.user?.name || 'Guest',
                    uid: auth.currentUser?.uid || '',
                    mouseX: event.clientX / window.innerWidth,
                    mouseY: event.clientY / window.innerHeight,
                    currentPage: location.hash,
                    photoURL: userContext?.user?.photoURL || null
                })
            }
        }

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            if (auth.currentUser) {
                remove(ref(database, 'cursorChat/' + medicalItem.id + '/' + (auth.currentUser?.uid || '')))
            }

            window.removeEventListener(
                'mousemove',
                handleMouseMove
            );
        };
    }, [location.hash, auth.currentUser?.uid])

    useEffect(() => {
        const beforeUnload = () => {
            if (auth.currentUser) {
                remove(ref(database, 'cursorChat/' + medicalItem.id + '/' + (auth.currentUser?.uid || '')))
            }
        }

        const handleMouseOut = (event: MouseEvent) => {
            if (!event.relatedTarget) {
                if (auth.currentUser) {
                    remove(ref(database, 'cursorChat/' + medicalItem.id + '/' + (auth.currentUser?.uid || '')))
                }
            }
        }

        window.addEventListener('mouseout', handleMouseOut)

        window.addEventListener("beforeunload", beforeUnload)
        return () => {
            window.removeEventListener("beforeunload", beforeUnload)
            window.removeEventListener('mouseout', handleMouseOut)
        }
    }, [auth.currentUser?.uid])

    useEffect(() => {
        if (medicalItem.id) {
            const query = ref(database, `cursorChat/${medicalItem.id}`);
            return onValue(query, (snapshot) => {
                const data = snapshot.val();

                if (snapshot.exists()) {
                    setCursors(data)
                    cursorChatContext?.setCursors({ ...cursorChatContext.cursors, [location.pathname]: Object.values(data) })
                }
            })
        }
    }, [medicalItem])

    useEffect(() => {
        const q = query(collection(firestore, "chats"),
            where('entityIds', 'array-contains', medicalItem.id),
            where('resolved', '==', false))

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)
            setChats(chats)
        })

        return () => unsub()
    }, [medicalItem.id])

    useEffect(() => {
        const startTime = Date.now();

        return () => {
            const path = location.pathname + location.hash
            var storedData: { [path: string]: { timeSpent: number, clicks: number, title: string, type: string } } = JSON.parse(localStorage.getItem('recents') || '{}') || {}

            const endTime = Date.now()
            const totalTimeSpent = endTime - startTime

            if (storedData[path] === undefined) {
                storedData[path] = { timeSpent: 0, clicks: 0, title: '', type: 'item' }
            }

            storedData[path].timeSpent = (storedData[path]?.timeSpent || 0) + totalTimeSpent
            storedData[path].title = medicalItem.name
            localStorage.setItem('recents', JSON.stringify(storedData))
        }
    }, [])

    useEffect(() => {
        const handleClick = () => {
            const path = location.pathname + location.hash
            var storedData: { [path: string]: { timeSpent: number, clicks: number, title: string, type: string } } = JSON.parse(localStorage.getItem('recents') || '{}') || {}

            if (storedData[path] === undefined) {
                storedData[path] = { timeSpent: 0, clicks: 0, title: '', type: 'item' }
            }

            storedData[path].clicks += 1
            storedData[path].title = medicalItem.name
            localStorage.setItem('recents', JSON.stringify(storedData));
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [])

    const createDisruption = async (assignee: string, type: string, resolveBy: Date | undefined) => {
        const chatroom: Chatroom = {
            id: uuid(),
            title: 'Resolve Disruption',
            type: 'entityDisruption',
            resolved: false,
            participants: [auth.currentUser?.uid || '', userContext?.users?.find((v) => v.id === assignee)?.id || ''].filter((v) => v),
            entities: medicalItem.id ? [{
                id: medicalItem.id,
                type: 'item'
            }] : [],
            entityIds: [medicalItem.id],
            hasEntity: true,
            hasPoll: false,
            answeredPoll: false
        }

        let disruption: Disruption = {
            id: uuid(),
            resolved: false,
            type: type,
            assignee: assignee,
            entity: medicalItem.id,
            resolveBy: null,
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            chatRef: chatroom.id
        }

        if (resolveBy) {
            disruption.resolveBy = Timestamp.fromDate(resolveBy)
        }

        const disruptionNotification: Notification = {
            id: uuid(),
            title: 'Disruption Conversation created',
            subtitle: 'Resolve disruption in the conversation to speed up the process.',
            time: Timestamp.now(),
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            recipients: userContext?.users.map((v) => v.id) || [],
            seenBy: [],
            type: 'disruptionCreated'
        }

        const disruptionAssignedNotification: Notification = {
            id: uuid(),
            title: 'New assignment',
            subtitle: `${userContext?.user?.name.split(' ')[0]} has assigned you a Disruption. Follow up in conversation.`,
            time: Timestamp.now(),
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            recipients: [assignee],
            seenBy: [],
            type: 'disruptionAssigned'
        }

        await setDoc(doc(firestore, 'disruptions', medicalItem.id), disruption)
        await setDoc(doc(firestore, 'chats', chatroom.id), chatroom)
        await setDoc(doc(firestore, 'notifications', disruptionNotification.id), disruptionNotification)
        await setDoc(doc(firestore, 'notifications', disruptionAssignedNotification.id), disruptionAssignedNotification)

        updateItem({
            lastUpdated: Timestamp.now(),
            lastUpdatedBy: auth.currentUser?.uid
        })

        medicalItem.subscribed?.health.disrupted.forEach((userId) => {
            const user = userContext?.users.find((v) => v.id === userId)

            if (user) {
                httpsCallable(functions, 'sendMail')({
                    to: user.email,
                    subject: 'Health Alert',
                    text: `${medicalItem.name} is now disrupted`
                })
            }
        })
    }

    const createSubstitute = async (item: MedicalItem | undefined) => {
        const chatroom: Chatroom = {
            id: uuid(),
            title: 'Request Substitute',
            type: 'entitySubstitute',
            resolved: false,
            participants: [auth.currentUser?.uid || ''].filter((v) => v),
            entities: medicalItem.id ? [{
                id: medicalItem.id,
                type: 'item'
            }] : [],
            entityIds: [medicalItem.id],
            hasEntity: true,
            hasPoll: false,
            answeredPoll: false
        }

        const substitute: Substitute = {
            id: uuid(),
            primaryItemID: medicalItem.id,
            substituteItemID: item?.id || '',
            requestedBy: userContext?.user?.id || '',
            substitutionLengthHours: 0,
            vendor: "",
            status: "Requested",
            substituteStatus: "",
            chatRef: chatroom.id,
        }

        const notification: Notification = {
            id: uuid(),
            title: 'Substitute request',
            subtitle: 'A conversation is now created for this substitute request. Add people and polls',
            time: Timestamp.now(),
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            recipients: userContext?.users.map((v) => v.id) || [],
            seenBy: [],
            type: 'substituteRequest'
        }

        setSubstituteDialog(false)

        await setDoc(doc(firestore, 'substitutes', substitute.id), substitute)
        await setDoc(doc(firestore, 'chats', chatroom.id), chatroom)
        await setDoc(doc(firestore, 'notifications', notification.id), notification)

        updateItem({
            lastUpdated: Timestamp.now(),
            lastUpdatedBy: auth.currentUser?.uid
        })

        medicalItem.subscribed?.substitute.newRequest.forEach((userId) => {
            const user = userContext?.users.find((v) => v.id === userId)

            if (user) {
                httpsCallable(functions, 'sendMail')({
                    to: user.email,
                    subject: 'Substitute Alert',
                    text: `${medicalItem.name} has a new substitute request`
                })
            }
        })
    }

    const updateSubstitute = async (substituteId: string, value: Record<string, any>) => {
        await updateDoc(doc(firestore, 'substitutes', substituteId), value)
    }

    const updateDisruption = async (value: Record<string, any>) => {
        await updateDoc(doc(firestore, 'disruptions', medicalItem.id), value)
    }

    const updateItem = async (value: Record<string, any>) => {
        await updateDoc(doc(firestore, 'items', medicalItem.id), value)
    }

    const getMonthNameAfterHours = (addedHours: number): string => {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + addedHours * 3600 * 1000);
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return monthNames[futureDate.getMonth()];
    }

    const getVendorFillRate = (vendor: Vendor): number => {
        const contracts = mockContracts.filter((contract) => vendor.contracts.toArray().includes(contract.id))
        const fillRates = contracts.map((v) => v.fillRate).reduce((a, c) => a + c, 0)

        if (contracts.length === 0) {
            return 0
        }

        return fillRates / contracts.length
    }

    const checkIfCursorInBounds = (cursor: { x: number, y: number }, popoverRect: Rect): boolean => {
        const rect = popoverRect
        const isInBounds =
            cursor.x >= rect.left &&
            cursor.x <= rect.right &&
            cursor.y >= rect.top &&
            cursor.y <= rect.bottom

        return isInBounds
    }

    const checkIfUserSubscribed = (): boolean => {
        if (!auth.currentUser?.uid) { return false }

        if (medicalItem.subscribed?.health.assigned.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.health.disabled.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.health.disrupted.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.health.enabled.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.health.stable.includes(auth.currentUser?.uid || '')) { return true }

        if (medicalItem.subscribed?.substitute.accepted.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.substitute.begin.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.substitute.end.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.substitute.newRequest.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.substitute.rejected.includes(auth.currentUser?.uid || '')) { return true }

        if (medicalItem.subscribed?.order.accepted.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.order.disrupted.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.order.filled.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.order.hold.includes(auth.currentUser?.uid || '')) { return true }

        if (medicalItem.subscribed?.conversation.new.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.conversation.linkedEntity.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.conversation.poll.includes(auth.currentUser?.uid || '')) { return true }
        if (medicalItem.subscribed?.conversation.resolved.includes(auth.currentUser?.uid || '')) { return true }

        return false
    }

    const checkIfUserSubscribedToAll = (): boolean => {
        if (!auth.currentUser?.uid) { return false }

        if (
            medicalItem.subscribed?.health.assigned.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.health.disrupted.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.health.enabled.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.health.disabled.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.health.stable.includes(auth.currentUser?.uid || '') &&

            medicalItem.subscribed?.substitute.accepted.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.substitute.begin.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.substitute.end.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.substitute.newRequest.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.substitute.rejected.includes(auth.currentUser?.uid || '') &&

            medicalItem.subscribed?.order.accepted.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.order.disrupted.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.order.filled.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.order.hold.includes(auth.currentUser?.uid || '') &&

            medicalItem.subscribed?.conversation.new.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.conversation.linkedEntity.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.conversation.poll.includes(auth.currentUser?.uid || '') &&
            medicalItem.subscribed?.conversation.resolved.includes(auth.currentUser?.uid || '')
        ) {
            return true
        }

        return false
    }

    return (
        <>
            <div className="relative flex mt-4 pt-2 px-4 pb-4">
                <div className="w-[265px] top-[80px] bottom-4 fixed flex flex-col border border-neutral-2 bg-neutral-1 rounded-md">
                    <div onClick={() => navigate(-1)} className="cursor-pointer flex items-center gap-3 p-2">
                        <img className="h-[12px] cursor-pointer" src={'/images/icons/ic-chevron-left.svg'}></img>
                        <p className="font-title-3 font-semibold">{previousLocation.charAt(0).toUpperCase() + previousLocation.slice(1)}</p>
                    </div>

                    <div className="h-[1px] bg-neutral-2"></div>

                    <div className="flex p-2">
                        <div className="flex flex-col gap-1">
                            <p className="font-body line-clamp-1 text-ellipsis">{medicalItem.name}</p>

                            <div className="flex gap-2 items-center">
                                <div className="w-[10px] h-[10px] bg-success rounded-full"></div>
                                <p className="font-body text-neutral-5">Active</p>
                            </div>
                        </div>

                        <div className="ml-auto flex items-center">
                            <Toggle
                                value={medicalItem.status === 'Active'}
                                onChange={(v) => {
                                    if (medicalItem.status === 'Active') {
                                        setActiveDialog(true)
                                    } else {
                                        updateItem({
                                            status: 'Active',
                                            lastUpdated: Timestamp.now(),
                                            lastUpdatedBy: auth.currentUser?.uid
                                        })

                                        medicalItem.subscribed?.health.enabled.forEach((userId) => {
                                            const user = userContext?.users.find((v) => v.id === userId)

                                            if (user) {
                                                httpsCallable(functions, 'sendMail')({
                                                    to: user.email,
                                                    subject: 'Health Alert',
                                                    text: `${medicalItem.name} can now be used in the hospital`
                                                })
                                            }
                                        })
                                    }
                                }} />
                        </div>
                    </div>

                    <div className="h-[1px] bg-neutral-2"></div>

                    <div className="flex flex-col gap-1 p-2">
                        <SidebarNavigationLink
                            title="Overview"
                            icon="ic-gauge.svg"
                            hashLink="#overview"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Substitutes"
                            icon="ic-arrow-left-arrow-right.svg"
                            hashLink="#substitutes"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Procedures"
                            icon="ic-menu.svg"
                            hashLink="#procedures"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Utilisation"
                            icon="ic-chart-xyaxis-line.svg"
                            hashLink="#utilisation"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Orders"
                            icon="ic-shipping-box.svg"
                            hashLink="#orders"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Vendors"
                            icon="ic-credit-card.svg"
                            hashLink="#vendors"
                            currentHashLink={currentPage}
                        />
                    </div>

                    {lastUpdatedUser && (
                        <div className="flex mt-auto p-2 gap-2 items-center">
                            <img className="h-[26px] w=[26px] p-1.5 bg-gradient-to-t from-neutral-4 to-neutral-3 rounded-md" src={'/images/icons/ic-pencil.svg'}></img>

                            <div className="flex flex-col justify-start">
                                <p className="font-body">{lastUpdatedUser.name}</p>
                                <p className="font-subheadline text-neutral-5">{`Edited ${moment(medicalItem.lastUpdated?.toDate()).fromNow()}`}</p>
                            </div>
                        </div>
                    )}

                </div>

                <div className="ml-[265px] flex-grow">
                    <div className="w-[825px] items-start mx-auto">
                        {currentPage === '#overview' && (
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <div className="flex gap-3 items-center">
                                        <div className="w-[28px] h-[28px] border=[0.5px] p-[1px] border-neutral-2 bg-white rounded-[2px]">
                                            <div className="w-full h-full border-[0.5px] border-neutral-1 bg-neutral-1 rounded-[2px] shadow-inner">
                                                <img className="w-full p-[1px]" src={'/images/icons/ic-mock-item.svg'}></img>
                                            </div>
                                        </div>

                                        <p className="font-large-title font-bold">{medicalItem?.name}</p>
                                    </div>

                                    <div className="flex gap-1 items-center">
                                        {medicalItem?.tags?.map((tag) => {
                                            return (
                                                <Tag title={tag}></Tag>
                                            )
                                        })}

                                        <SearchPopover
                                            ref={tagPopoverRef}
                                            isOpen={tagPopover}
                                            positions={['bottom']}
                                            content={(popoverRect) => (
                                                <div onMouseLeave={(v) => { if (!checkIfCursorInBounds({ x: v.clientX, y: v.clientY }, popoverRect)) { setTagPopover(false) } }}>
                                                    <div className="flex flex-col p-4 w-[340px] gap-1.5">
                                                        <p className="font-body text-neutral-5">{`Assign tags to scene ”${medicalItem.name}”`}</p>

                                                        <Input
                                                            autoFocus={true}
                                                            type="tooltip"
                                                            placeholder="Search..."
                                                            value={tagSearch}
                                                            onChange={(v) => setTagSearch(v)}
                                                        />

                                                        <div className="flex flex-col gap-0.5">
                                                            {existingTags.filter((v) => v.toLowerCase().includes(tagSearch.toLowerCase()) || !tagSearch).splice(0, 5).map((item, index) => {
                                                                return (
                                                                    <div onClick={() => {
                                                                        updateItem({
                                                                            tags: arrayUnion(item),
                                                                            lastUpdated: Timestamp.now(),
                                                                            lastUpdatedBy: auth.currentUser?.uid
                                                                        })

                                                                        setTagPopover(false)
                                                                    }} key={index} className="cursor-pointer group flex gap-1.5 py-1 px-2 items-center hover:bg-focused-selected rounded-md">

                                                                        <p className="font-body font-semibold w-full text-ellipsis line-clamp-1 group-hover:text-white">{item}</p>
                                                                    </div>
                                                                )
                                                            })}

                                                            {existingTags.filter((v) => v.toLowerCase().includes(tagSearch.toLowerCase()) || !tagSearch).length === 0 && (
                                                                <div onClick={() => {
                                                                    updateItem({
                                                                        tags: arrayUnion(tagSearch),
                                                                        lastUpdated: Timestamp.now(),
                                                                        lastUpdatedBy: auth.currentUser?.uid
                                                                    })

                                                                    setTagPopover(false)
                                                                }} className="cursor-pointer group flex gap-1.5 py-1 px-2 items-center hover:bg-focused-selected rounded-md">

                                                                    <p className="font-body font-semibold w-full text-ellipsis line-clamp-1 group-hover:text-white">{`${tagSearch} (New)`}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="absolute h-4 w-4 mx-auto left-1/2 top-[-8px]  rotate-45 transform border-t border-l border-neutral-2 bg-dialog z-[501]"></div>
                                                </div>
                                            )
                                            }

                                        >

                                            <div onClick={() => setTagPopover(!tagPopover)} className="cursor-pointer font-subheadline font-bold text-primary-4">
                                                Add Tag
                                            </div>
                                        </SearchPopover>
                                    </div>


                                </div>

                                {(!disruption || disruption.resolved) && (
                                    <div className="sticky top-0 z-[100] flex p-2 w-full bg-white border-neutral-2 border rounded-md gap-2">
                                        <PrimaryButton
                                            title="Mark as Disrupted"
                                            type="primary"
                                            size="xl"
                                            icon="ic-bolt.svg"
                                            onClick={() => setDisruptedDialog(true)}
                                        />

                                        {checkIfUserSubscribed() ?
                                            <Select
                                                size="xl"
                                                options={[
                                                    {
                                                        label: 'All',
                                                        value: 'all',
                                                        icon: 'ic-bell-waves-fill.svg'
                                                    },
                                                    {
                                                        label: 'Personalised',
                                                        value: 'personalised',
                                                        icon: 'ic-bell.svg'
                                                    },
                                                    {
                                                        label: 'Unsubscribe',
                                                        value: 'unsubscribe',
                                                        icon: 'ic-minus.svg'
                                                    },
                                                ]}
                                                selectedOption={'subscribe'}
                                                label="Subscribed"
                                                onChange={(value) => {

                                                }}
                                            />
                                            :
                                            <PrimaryButton
                                                title="Subscribe"
                                                type="gray"
                                                size="xl"
                                                onClick={() => {
                                                    setSubscribeDialog(true)
                                                }}
                                            />
                                        }

                                        <div className="flex ml-auto mr-4 items-center gap-[40px]">
                                            <div>
                                                <p className="font-callout font-semibold">{medicalItem?.manufacturer}</p>
                                                <p className="font-subheadline text-neutral-5">Manufacturer</p>
                                            </div>

                                            <div>
                                                <p className="font-callout font-semibold">{medicalItem?.manufacturerID}</p>
                                                <p className="font-subheadline text-neutral-5">MFG Item ID</p>
                                            </div>

                                            <div>
                                                <p className="font-callout font-semibold">{medicalItem?.erpID}</p>
                                                <p className="font-subheadline text-neutral-5">ERP ID</p>
                                            </div>

                                            <div>
                                                <p className="font-callout font-semibold">{`${medicalItem?.price}/${medicalItem?.unitOfMeasure}`}</p>
                                                <p className="font-subheadline text-neutral-5">Price</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {(disruption && !disruption.resolved) && (
                                    <div className="sticky top-0 z-[100]">
                                        <div style={{ boxShadow: '0px 0px 48px 9px rgba(255, 102, 69, 0.20), 0px 0px 16px 0px rgba(255, 102, 69, 0.18)' }} className="flex p-2 w-full bg-white border-neutral-2 border rounded-md gap-2">
                                            <PrimaryButton
                                                title="Mark as Resolved"
                                                type="critical"
                                                size="xl"
                                                onClick={() => {
                                                    updateDisruption({
                                                        resolved: true
                                                    })

                                                    medicalItem.subscribed?.health.stable.forEach((userId) => {
                                                        const user = userContext?.users.find((v) => v.id === userId)

                                                        if (user) {
                                                            httpsCallable(functions, 'sendMail')({
                                                                to: user.email,
                                                                subject: 'Health Alert',
                                                                text: `${medicalItem.name} is no longer disrupted`
                                                            })
                                                        }
                                                    })
                                                }}
                                            />

                                            {checkIfUserSubscribed() ?
                                                <Select
                                                    size="xl"
                                                    type="ghost"
                                                    options={[
                                                        {
                                                            label: 'All',
                                                            value: 'all',
                                                            icon: 'ic-bell-waves-fill.svg'
                                                        },
                                                        {
                                                            label: 'Personalised',
                                                            value: 'personalised',
                                                            icon: 'ic-bell.svg'
                                                        },
                                                        {
                                                            label: 'Unsubscribe',
                                                            value: 'unsubscribe',
                                                            icon: 'ic-minus.svg'
                                                        },
                                                    ]}
                                                    selectedOption={checkIfUserSubscribedToAll() ? 'all' : 'personalised'}
                                                    label="Subscribed"
                                                    onChange={(value) => {
                                                        if (value === 'all') {
                                                            updateItem({
                                                                subscribed: {
                                                                    health: {
                                                                        disrupted: arrayUnion(auth.currentUser?.uid),
                                                                        stable: arrayUnion(auth.currentUser?.uid),
                                                                        assigned: arrayUnion(auth.currentUser?.uid),
                                                                        disabled: arrayUnion(auth.currentUser?.uid),
                                                                        enabled: arrayUnion(auth.currentUser?.uid),
                                                                    },
                                                                    substitute: {
                                                                        newRequest: arrayUnion(auth.currentUser?.uid),
                                                                        accepted: arrayUnion(auth.currentUser?.uid),
                                                                        rejected: arrayUnion(auth.currentUser?.uid),
                                                                        begin: arrayUnion(auth.currentUser?.uid),
                                                                        end: arrayUnion(auth.currentUser?.uid),
                                                                    },
                                                                    order: {
                                                                        accepted: arrayUnion(auth.currentUser?.uid),
                                                                        filled: arrayUnion(auth.currentUser?.uid),
                                                                        hold: arrayUnion(auth.currentUser?.uid),
                                                                        disrupted: arrayUnion(auth.currentUser?.uid),
                                                                    },
                                                                    conversation: {
                                                                        new: arrayUnion(auth.currentUser?.uid),
                                                                        poll: arrayUnion(auth.currentUser?.uid),
                                                                        resolved: arrayUnion(auth.currentUser?.uid),
                                                                        linkedEntity: arrayUnion(auth.currentUser?.uid),
                                                                    }
                                                                }
                                                            })
                                                        } else if (value === 'unsubscribe') {
                                                            updateItem({
                                                                subscribed: {
                                                                    health: {
                                                                        disrupted: arrayRemove(auth.currentUser?.uid),
                                                                        stable: arrayRemove(auth.currentUser?.uid),
                                                                        assigned: arrayRemove(auth.currentUser?.uid),
                                                                        disabled: arrayRemove(auth.currentUser?.uid),
                                                                        enabled: arrayRemove(auth.currentUser?.uid),
                                                                    },
                                                                    substitute: {
                                                                        newRequest: arrayRemove(auth.currentUser?.uid),
                                                                        accepted: arrayRemove(auth.currentUser?.uid),
                                                                        rejected: arrayRemove(auth.currentUser?.uid),
                                                                        begin: arrayRemove(auth.currentUser?.uid),
                                                                        end: arrayRemove(auth.currentUser?.uid),
                                                                    },
                                                                    order: {
                                                                        accepted: arrayRemove(auth.currentUser?.uid),
                                                                        filled: arrayRemove(auth.currentUser?.uid),
                                                                        hold: arrayRemove(auth.currentUser?.uid),
                                                                        disrupted: arrayRemove(auth.currentUser?.uid),
                                                                    },
                                                                    conversation: {
                                                                        new: arrayRemove(auth.currentUser?.uid),
                                                                        poll: arrayRemove(auth.currentUser?.uid),
                                                                        resolved: arrayRemove(auth.currentUser?.uid),
                                                                        linkedEntity: arrayRemove(auth.currentUser?.uid),
                                                                    }
                                                                }
                                                            })
                                                        } else {
                                                            setSubscribeDialog(true)
                                                        }
                                                    }}
                                                />
                                                :
                                                <PrimaryButton
                                                    title="Subscribe"
                                                    type="ghost"
                                                    size="xl"
                                                    onClick={() => {
                                                        setSubscribeDialog(true)
                                                    }}
                                                />
                                            }

                                            <div className="ml-auto flex items-center gap-2">
                                                <div className="w-[150px]">
                                                    <Input
                                                        type="search"
                                                        options={userContext?.users?.map((user) => {
                                                            return {
                                                                label: `${user.name}`,
                                                                value: user.id,
                                                                subtitle: user.team
                                                            }
                                                        })}
                                                        withBorder={true}
                                                        value={assigneeText}
                                                        onChange={(v) => setAssigneeText(v)}
                                                        onSearchChange={(v) => setAssigneeText(v)}
                                                        onOptionChange={(v) => {
                                                            setAssigneeText(`${v.label} (${v.subtitle})`)
                                                            updateDisruption({
                                                                assignee: v.value
                                                            })

                                                            medicalItem.subscribed?.health.assigned.forEach((userId) => {
                                                                const user = userContext?.users.find((v) => v.id === userId)

                                                                if (user) {
                                                                    httpsCallable(functions, 'sendMail')({
                                                                        to: user.email,
                                                                        subject: 'Health Alert',
                                                                        text: `${medicalItem.name} is now assigned to ${userContext?.users.find((u) => u.id === v.value)?.name}`
                                                                    })
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <Input
                                                    type="select"
                                                    options={[
                                                        {
                                                            label: "Backorder",
                                                            value: "backorder"
                                                        },
                                                        {
                                                            label: "Discontinued",
                                                            value: "discontinued"
                                                        },
                                                        {
                                                            label: "Recall",
                                                            value: "recall"
                                                        },
                                                        {
                                                            label: "Rejected",
                                                            value: "rejected"
                                                        },
                                                    ]}
                                                    withBorder={true}
                                                    value={disruption?.type || ''}
                                                    onChange={(v) => {
                                                        updateDisruption({
                                                            type: v
                                                        })
                                                    }}
                                                />

                                                <Input
                                                    type="date"
                                                    withBorder={true}
                                                    dateValue={disruption.resolveBy?.toDate() || new Date()}
                                                    onDateChange={(v) => {
                                                        updateDisruption({
                                                            resolveBy: v
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex border-b border-l border-r border-neutral-2 rounded-b-md2">
                                            <div className="flex gap-2 p-2">
                                                <p className="font-caption-1 text-neutral-5">MANUFACTURER</p>
                                                <p className="font-caption-1 text-neutral-6">{medicalItem.manufacturer}</p>
                                            </div>

                                            <div className="ml-auto bg-neutral-2 w-[1px] h-[29px]"></div>

                                            <div className="flex gap-2 p-2">
                                                <p className="font-caption-1 text-neutral-5">MANUFACTURER ITEM ID</p>
                                                <p className="font-caption-1 text-neutral-6">{medicalItem.manufacturerID}</p>
                                            </div>

                                            <div className="bg-neutral-2 w-[1px] h-[29px]"></div>

                                            <div className="flex gap-2 p-2">
                                                <p className="font-caption-1 text-neutral-5">ERP ID</p>
                                                <p className="font-caption-1 text-neutral-6">{medicalItem.erpID}</p>
                                            </div>

                                            <div className="bg-neutral-2 w-[1px] h-[29px]"></div>

                                            <div className="flex gap-2 p-2">
                                                <p className="font-caption-1 text-neutral-5">PRICE</p>
                                                <p className="font-caption-1 text-neutral-6">{`${medicalItem?.price}/${medicalItem?.unitOfMeasure}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {usedSubstitute && (
                                    <div className="flex p-3 w-full bg-white border-neutral-2 border rounded-md gap-2">
                                        <img className="h-[26px] w=[26px] p-1.5 gradient-blue rounded-md" src={'/images/icons/ic-info-circle-fill-white.svg'}></img>

                                        <div>
                                            <p className="font-body">Using Substitute <span className="text-neutral-5">{`— ${usedSubstitute.substituteUntil ? moment(usedSubstitute.substituteUntil?.toDate()).fromNow(false) + ' left' : 'Indefinite'}`}</span></p>
                                            <p className="font-subheadline text-neutral-5">{`This item is currently being substituted by ${entityContext?.medicalItems.find((v) => v.id === usedSubstitute.substituteItemID)?.name}`}</p>
                                        </div>

                                        <div className="p-2 bg-neutral-2 rounded-md font-body ml-auto">
                                            View Item
                                        </div>
                                    </div>
                                )}

                                <div className="flex p-3 w-full bg-white border-neutral-2 border rounded-md gap-2">
                                    <img className="h-[26px] w=[26px] p-1.5 bg-gradient-to-t from-caution to-danger rounded-md" src={'/images/icons/ic-warning-triangle-fill.svg'}></img>


                                    <div>
                                        <p className="font-body">Booked Surgery <span className="text-neutral-5">— 7 days left</span></p>
                                        <p className="font-subheadline text-neutral-5">Making any changes to this item will affect Dr. Alita Williams Appendectomy.</p>
                                    </div>

                                    <div className="p-2 bg-neutral-2 rounded-md font-body ml-auto">
                                        View Surgery
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Usage</p>
                                                <p className="font-subheadline text-neutral-5">Updated</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: mockMedicalItemUsages.find((v) => v.id === medicalItem.id)?.weeklyUsage.slice(-6) || [],
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: mockMedicalItemUsages.find((v) => v.id === medicalItem.id)?.weeklyWasted.slice(-6) || [],
                                                        color: 'info'
                                                    }
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Used",
                                                        subtitle: `${mockMedicalItemUsages.find((v) => v.id === medicalItem.id)?.weeklyUsage.slice(-6).reduce((a, b) => a + b, 0)} units`,
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        title: "Wasted",
                                                        subtitle: `${mockMedicalItemUsages.find((v) => v.id === medicalItem.id)?.weeklyWasted.slice(-6).reduce((a, b) => a + b, 0)} units`,
                                                        color: "info"
                                                    }
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Conversations</p>
                                                <p className="font-subheadline text-neutral-5">{`${chats.length} new`}</p>
                                            </div>
                                        </div>

                                        {chats.length > 0 ?
                                            <div className="rounded-md border border-neutral-2 mt-2 overflow-y-auto">
                                                {chats.map((chat, index) => {
                                                    return (
                                                        <>
                                                            <div onClick={() => {
                                                                chatContext?.setChatPanelOpen(chat.id)
                                                            }} className="flex p-2 gap-2 items-center pl-4 cursor-pointer">
                                                                {disruption?.resolved !== false ?
                                                                    <div className={`h-[26px] min-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                                                        <img className="h-[16px] w-[16px]" src={`/images/icons/ic-circle-dashed.svg`}></img>
                                                                    </div>
                                                                    :
                                                                    <div className={`h-[26px] min-w-[26px] gradient-orange rounded-md flex items-center justify-center`}>
                                                                        <img className="h-[16px] w-[16px]" src={`/images/icons/ic-warning-triangle.svg`}></img>
                                                                    </div>
                                                                }

                                                                <div>
                                                                    <p className="font-body w-full">{chat.title}</p>
                                                                </div>

                                                                <div style={{ transform: `translate(${8 * (chat.participants.length >= 3 ? 2 : chat.participants.length - 1)}px)` }} className="flex gap-0 ml-auto">
                                                                    {chat.participants.slice(0, chat.participants.length > 3 ? 2 : 3).map((participant, index) => {
                                                                        const user = userContext?.users.find((v) => v.id === participant)

                                                                        return (
                                                                            <div
                                                                                style={{ transform: `translate(-${8 * index}px)` }}
                                                                                className={`bg-white p-[1px] rounded-full z-[${index}]`}>
                                                                                {user ?
                                                                                    <ProfilePicture
                                                                                        user={user}
                                                                                        size={30}
                                                                                    />
                                                                                    :
                                                                                    <div className="w-[30px] h-[30px] min-w-[30px] min-h-[30px] gradient-light-grey rounded-full"></div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })}

                                                                    {chat.participants.length > 3 && (
                                                                        <div
                                                                            style={{ transform: `translate(-${16}px)` }}
                                                                            className={`bg-white p-[1px] rounded-full z-[${2}]`}>
                                                                            <div style={{ width: `30px`, height: `30px`, minWidth: `30px`, minHeight: `30px` }} className="flex items-center justify-center gradient-light-grey rounded-full text-white text-center">
                                                                                <p style={{ fontSize: `${30 * 0.5}px` }} className="">{`+${chat.participants.length - 2}`}</p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <img className="h-[12px] w-[12px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                                            </div>

                                                            {index !== chats.length - 1 && (
                                                                <div className="h-[1px] bg-neutral-2 mx-2"></div>
                                                            )}

                                                        </>
                                                    )
                                                })}
                                            </div>
                                            :
                                            <div className="flex flex-col gap-2 my-auto">
                                                <img className="w-[100px] mx-auto" src="/images/icons/ic-conversation-inbox.png" />

                                                <div className="flex flex-col gap-0.5 text-center">
                                                    <p className="font-body">Conversations</p>
                                                    <p className="font-subheadline text-neutral-5">No available conversations</p>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>

                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Orders</p>
                                                <p className="font-subheadline text-neutral-5">{`${mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyAccepted.slice(-6).reduce((a, b) => a + b, 0)} this month`}</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyAccepted.slice(-6) || [],
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyRejected.slice(-6) || [],
                                                        color: 'critical'
                                                    },
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyHold.slice(-6) || [],
                                                        color: 'info'
                                                    },
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyBackordered.slice(-6) || [],
                                                        color: 'caution'
                                                    },
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Accepted",
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        title: "Rejected",
                                                        color: "critical"
                                                    },
                                                    {
                                                        title: "Hold",
                                                        color: "info"
                                                    },
                                                    {
                                                        title: "Backordered",
                                                        color: "caution"
                                                    }
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Disruptions</p>
                                                <p className="font-subheadline text-neutral-5">{`Avg. ${((mockMedicalItemDisruptions.find((v) => v.id === medicalItem.id)?.weeklyDisrupted.slice(-6).reduce((a, b) => a + b, 0) || 0) / 6).toFixed(2)}/month`}</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: mockMedicalItemDisruptions.find((v) => v.id === medicalItem.id)?.weeklyDisrupted.slice(-6) || [],
                                                        color: 'teal'
                                                    }
                                                ]}
                                                breakpoints={[0, 2, 4, 5, 8]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Disrupted",
                                                        color: 'teal'
                                                    }
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Substitutes</p>
                                                <p className="font-subheadline text-neutral-5">3 available</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: [75, 100, 125, 165, 175, 65],
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: [10, 20, 30, 40, 50, 10],
                                                        color: 'info'
                                                    }
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']}
                                                legends={[
                                                    {
                                                        title: "Accepted",
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        title: "Rejected",
                                                        color: "critical"
                                                    },
                                                    {
                                                        title: "Hold",
                                                        color: "info"
                                                    },
                                                    {
                                                        title: "Backordered",
                                                        color: "caution"
                                                    }
                                                ]}
                                                selectedLabel={'Jul'}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Vendors</p>
                                                <p className="font-subheadline text-neutral-5">3 on contract</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: [75, 100, 125, 165, 175, 65],
                                                        color: 'teal'
                                                    }
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']}
                                                legends={[
                                                    {
                                                        title: "Disrupted",
                                                        color: 'teal'
                                                    }
                                                ]}
                                                selectedLabel={'Jul'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#substitutes' && (
                            <div>
                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Status',
                                                    value: 'status',
                                                }
                                            ]}
                                            selectedOption="status"
                                        />

                                        <PrimaryButton
                                            type="primary"
                                            title="Request Substitute"
                                            icon="ic-plus-circle-white.svg"
                                            onClick={() => setSubstituteDialog(true)}
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 180px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {entityContext?.substitutes?.filter((v) => v.primaryItemID === medicalItem.id && v.status !== 'Requested' && v.substituteItemID).map((item) => {
                                            const medicalItem = entityContext?.medicalItems.find((v) => v.id === item.substituteItemID)

                                            return (
                                                <ListItemRow
                                                    title={medicalItem?.name || ''}
                                                    subtitle={`Requested by ${item.requestedBy === auth.currentUser?.uid ? 'You' : userContext?.users.find((v) => v.id === item.requestedBy)?.name}`}
                                                    subtitleHTML={item.status === 'Used' ?
                                                        <div className="flex gap-1 items-center">
                                                            <div className="w-[6px] h-[6px] bg-success rounded-full"></div>
                                                            <p className="font-subheadline text-neutral-5 line-clamp-1">Currently used as Substitute</p>
                                                        </div>
                                                        : ''}
                                                    icon={item.status === 'Used' ? {
                                                        icon: 'ic-square-dashed-white.svg',
                                                        gradient: 'blue'
                                                    } : {
                                                        icon: 'ic-checkmark.svg',
                                                        gradient: 'green'
                                                    }}
                                                    arrowLabel={item.substituteUntil ? `Expires ${moment(item.substituteUntil?.toDate()).fromNow()}` : 'Indefinite'}
                                                    arrowRight={true}
                                                    onArrowClick={() => setSelectedSubstituteId(item.id)}
                                                />
                                            )
                                        })}

                                        {(entityContext?.substitutes?.filter((v) => v.primaryItemID === medicalItem.id && v.status === 'Requested' && v.substituteItemID).length || 0) > 0 && (
                                            <div className="h-[21px]">
                                                <div className="h-[1px] bg-neutral-2 mt-[10px]"></div>
                                            </div>
                                        )}

                                        {entityContext?.substitutes?.filter((v) => v.primaryItemID === medicalItem.id && v.status === 'Requested' && v.substituteItemID).map((item) => {
                                            return (
                                                <ListItemRow
                                                    title={entityContext?.medicalItems.find((v) => v.id === item.substituteItemID)?.name || ''}
                                                    subtitle={`Requested by ${item.requestedBy === auth.currentUser?.uid ? 'You' : userContext?.users.find((v) => v.id === item.requestedBy)?.name}`}
                                                    icon={{
                                                        icon: 'ic-circle-dashed.svg',
                                                        gradient: 'light-grey'
                                                    }}
                                                    arrowLabel={item.substituteUntil ? `Expires ${moment(item.substituteUntil?.toDate()).fromNow()}` : 'Indefinite'}
                                                    arrowRight={true}
                                                    onArrowClick={() => setSelectedSubstituteId(item.id)}
                                                />
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#procedures' && (
                            <div>
                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Surgeon',
                                                    value: 'surgeon',
                                                }
                                            ]}
                                            selectedOption="surgeon"
                                        />

                                        <PrimaryButton
                                            type="light-ghost"
                                            title="Add Item"
                                        />

                                        <PrimaryButton
                                            type="primary"
                                            title="Add Preference"
                                            icon="ic-plus-circle-white.svg"
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 180px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {mockProcedures.filter((v) => v.requiredItems.includes(medicalItem.id)).sort().map((procedure) => {
                                            return (
                                                <ListItemRow
                                                    title={procedure.name}
                                                    arrowRight={true}
                                                    onArrowClick={() => {
                                                        setSelectedProcedure(procedure)
                                                        navigate(`../dashboard/procedure/${procedure.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#utilisation' && (
                            <div className="flex flex-col gap-4">
                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body font-semibold">Quantity</p>
                                                <p className="font-subheadline text-neutral-5">Updated</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: [75, 100, 125, 165, 175, 65],
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: [10, 20, 30, 40, 50, 10],
                                                        color: 'info'
                                                    }
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']}
                                                legends={[
                                                    {
                                                        title: "Used",
                                                        color: 'neutral-4',
                                                        subtitle: '810 units'
                                                    },
                                                    {
                                                        title: "Wasted",
                                                        color: "info",
                                                        subtitle: '110 units'
                                                    },
                                                ]}
                                                selectedLabel={'Jul'}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body font-semibold">Scenes</p>
                                                <p className="font-subheadline text-neutral-5">Updated</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: [75, 100, 125, 165, 175, 65],
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: [20, 30, 40, 50, 60, 20],
                                                        color: 'mint'
                                                    },
                                                    {
                                                        data: [15, 25, 35, 45, 55, 15],
                                                        color: 'caution'
                                                    },

                                                    {
                                                        data: [10, 20, 30, 40, 50, 10],
                                                        color: 'info'
                                                    },
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']}
                                                legends={[
                                                    {
                                                        title: "Surgery",
                                                        color: 'neutral-4',
                                                        subtitle: '102 units'
                                                    },
                                                    {
                                                        title: "Emergency",
                                                        color: "info",
                                                        subtitle: '14 units'
                                                    },
                                                    {
                                                        title: "Radiology",
                                                        color: "caution",
                                                        subtitle: '142 units'
                                                    },
                                                    {
                                                        title: "Intensive Care",
                                                        color: "mint",
                                                        subtitle: '225 units'
                                                    }
                                                ]}
                                                selectedLabel={'Jul'}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Orders</p>
                                                <p className="font-subheadline text-neutral-5">{`${mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyAccepted.slice(-6).reduce((a, b) => a + b, 0)} this month`}</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyAccepted.slice(-6) || [],
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyRejected.slice(-6) || [],
                                                        color: 'critical'
                                                    },
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyHold.slice(-6) || [],
                                                        color: 'info'
                                                    },
                                                    {
                                                        data: mockMedicalItemOrders.find((v) => v.id === medicalItem.id)?.weeklyBackordered.slice(-6) || [],
                                                        color: 'caution'
                                                    },
                                                ]}
                                                breakpoints={[0, 50, 100, 150, 200]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Accepted",
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        title: "Rejected",
                                                        color: "critical"
                                                    },
                                                    {
                                                        title: "Hold",
                                                        color: "info"
                                                    },
                                                    {
                                                        title: "Backordered",
                                                        color: "caution"
                                                    }
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
                                        <div className="flex items-center">
                                            <div>
                                                <p className="font-body">Disruptions</p>
                                                <p className="font-subheadline text-neutral-5">{`Avg. ${((mockMedicalItemDisruptions.find((v) => v.id === medicalItem.id)?.weeklyDisrupted.slice(-6).reduce((a, b) => a + b, 0) || 0) / 6).toFixed(2)}/month`}</p>
                                            </div>

                                            <div className="flex gap-3 ml-auto">
                                                <p className="font-body text-neutral-5">View All</p>
                                                <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                            </div>
                                        </div>

                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: mockMedicalItemDisruptions.find((v) => v.id === medicalItem.id)?.weeklyDisrupted.slice(-6) || [],
                                                        color: 'teal'
                                                    }
                                                ]}
                                                breakpoints={[0, 2, 4, 5, 8]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Disrupted",
                                                        color: 'teal'
                                                    }
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#orders' && (
                            <div className="flex flex-col gap-4">
                                <StackedListBar
                                    title="Orders"
                                    subtitle={`${orders.filter((v) => v.status === 'Pending').length} of ${orders.length} in progress`}
                                    datasets={[
                                        {
                                            title: 'Accepted',
                                            count: orders.filter((v) => v.status === 'Accepted').length,
                                            color: 'success',

                                        },
                                        {
                                            title: 'Filled',
                                            count: orders.filter((v) => v.status === 'Filled').length,
                                            color: 'info',

                                        },
                                        {
                                            title: 'Hold',
                                            count: orders.filter((v) => v.status === 'Hold').length,
                                            color: 'caution',
                                        },
                                        {
                                            title: 'Disrupted',
                                            count: orders.filter((v) => v.status === 'Disrupted').length,
                                            color: 'critical',
                                        },
                                        {
                                            title: `${orders.filter((v) => v.status === 'Pending').length} Pending`,
                                            count: orders.filter((v) => v.status === 'Pending').length,
                                            color: '',
                                            showLabel: true
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Status',
                                                    value: 'status',
                                                }
                                            ]}
                                            selectedOption="status"
                                        />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Location',
                                                    value: 'location',
                                                }
                                            ]}
                                            selectedOption="location"
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 180px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {orders.map((order) => {
                                            return (
                                                <ListItemRow
                                                    title={order.name}
                                                    subtitle={`${order.itemsTotal} Items`}
                                                    icon={order.status === 'Disrupted' ? {
                                                        icon: 'ic-info-circle-fill-white.svg',
                                                        gradient: 'red'
                                                    } : order.status === 'Fill' ? {
                                                        icon: 'ic-warning-triangle.svg',
                                                        gradient: 'orange'
                                                    } : {
                                                        icon: 'ic-checkmark.svg',
                                                        gradient: 'blue'
                                                    }}
                                                    arrowLabel={order.location}
                                                    arrowRight={true}
                                                    onArrowClick={() => setSelectedOrder(order)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#vendors' && (
                            <div className="flex flex-col gap-4">
                                <div className="flex gap-5">
                                    <div>
                                        <p className="font-body">On Contract</p>
                                        <p className="font-large-title font-bold">{`${vendors.filter((v) => v.status === 'active').length} of ${vendors.length}`}</p>
                                    </div>

                                    <div>
                                        <p className="font-body">Fill Rate</p>
                                        <p className="font-large-title font-bold">{`${vendors.length > 0 ? (vendors.map((v) => getVendorFillRate(v)).reduce((a, b) => a + b, 0) / vendors.length).toFixed(2) : 0}%`}</p>
                                    </div>

                                    <div>
                                        <p className="font-body">Issues</p>
                                        <p className="font-large-title font-bold">{`${vendors.length > 0 ? (vendors.filter((v) => v.status !== 'active').length / vendors.length * 100).toFixed(0) : 0}%`}</p>
                                    </div>
                                </div>

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Status',
                                                    value: 'status',
                                                }
                                            ]}
                                            selectedOption="status"
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 180px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {vendors.map((vendor) => {
                                            return (
                                                <ListItemRow
                                                    title={vendor.name}
                                                    subtitle={`Spent £${vendor.spendWeekly.reduce((a, c) => a + c, 0)} so far`}
                                                    icon={{
                                                        icon: 'ic-checkmark.svg',
                                                        gradient: 'light-grey'
                                                    }}
                                                    arrowLabel={`${getVendorFillRate(vendor).toFixed(0)}% Fill`}
                                                    arrowRight={true}
                                                    onArrowClick={() => setSelectedVendor(vendor)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {activeDialog && (
                    <DisableDialog
                        onSubmit={() => {
                            updateItem({
                                status: 'Inactive',
                                lastUpdated: Timestamp.now(),
                                lastUpdatedBy: auth.currentUser?.uid
                            })

                            medicalItem.subscribed?.health.disabled.forEach((userId) => {
                                const user = userContext?.users.find((v) => v.id === userId)

                                if (user) {
                                    httpsCallable(functions, 'sendMail')({
                                        to: user.email,
                                        subject: 'Health Alert',
                                        text: `${medicalItem.name} is longer used in the hospital`
                                    })
                                }
                            })

                            setActiveDialog(false)
                        }}
                        onDismiss={() => setActiveDialog(false)}
                    />
                )}

                {subscribeDialog && (
                    <SubscribeDialog
                        onSubmit={(subscribed) => {
                            setSubscribeDialog(false)

                            updateItem({
                                subscribed: {
                                    health: {
                                        disrupted: subscribed.health.disrupted ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        stable: subscribed.health.stable ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        assigned: subscribed.health.assigned ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        disabled: subscribed.health.disabled ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        enabled: subscribed.health.enabled ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                    },
                                    substitute: {
                                        newRequest: subscribed.substitute.newRequest ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        accepted: subscribed.substitute.accepted ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        rejected: subscribed.substitute.rejected ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        begin: subscribed.substitute.begin ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        end: subscribed.substitute.end ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                    },
                                    order: {
                                        accepted: subscribed.order.accepted ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        filled: subscribed.order.filled ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        hold: subscribed.order.hold ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        disrupted: subscribed.order.disrupted ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                    },
                                    conversation: {
                                        new: subscribed.conversation.new ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        poll: subscribed.conversation.poll ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        resolved: subscribed.conversation.resolved ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                        linkedEntity: subscribed.conversation.linkedEntity ? arrayUnion(auth.currentUser?.uid) : arrayRemove(auth.currentUser?.uid),
                                    }
                                }
                            })
                        }}

                        onDismiss={() => {
                            setSubscribeDialog(false)
                        }}
                    />
                )}

                {substituteDialog && (
                    <SubstituteDialog
                        onSubmit={(item) => createSubstitute(item)}
                    />
                )}

                {disruptedDialog && (
                    <DisruptedDialog
                        onDismiss={() => {
                            setDisruptedDialog(false)
                        }}

                        onSubmit={(a, r, d) => {
                            setDisruptedDialog(false)
                            createDisruption(a || '', r, d)
                        }}
                    />
                )}

                {selectedSubstitute && useSubstituteDialog && (
                    <UseSubstituteDialog
                        onDismiss={() => setUseSubstituteDialog(false)}
                        onSubmit={(date) => {
                            setUseSubstituteDialog(false)
                            updateSubstitute(selectedSubstitute.id, {
                                substituteUntil: date || null,
                                status: 'Used',
                                approvedBy: auth.currentUser?.uid
                            })

                            medicalItem.subscribed?.substitute.begin.forEach((userId) => {
                                const user = userContext?.users.find((v) => v.id === userId)

                                if (user) {
                                    httpsCallable(functions, 'sendMail')({
                                        to: user.email,
                                        subject: 'Substitute Alert',
                                        text: `Substitute is currently being used as substitute`
                                    })
                                }
                            })

                            const notification: Notification = {
                                id: uuid(),
                                title: 'Substitute set',
                                subtitle: `You set the item as substitute. Substitution will expire ${moment(date).fromNow()}.`,
                                time: Timestamp.now(),
                                createdAt: Timestamp.now(),
                                createdBy: auth.currentUser?.uid || '',
                                recipients: [auth.currentUser?.uid || ''],
                                seenBy: [],
                                type: 'substituteUsed'
                            }

                            setDoc(doc(firestore, 'notifications', notification.id), notification)
                        }}
                    />
                )}
            </div>

            <div className="absolute top-0 h-screen w-screen pointer-events-none">
                {Object.entries(cursors || {}).filter((value) => value[1].uid !== auth.currentUser?.uid && value[1].currentPage === currentPage).map((value, key) => {
                    const styles = {
                        transform: `translate(${value[1].mouseX * window.innerWidth - 8}px, ${value[1].mouseY * window.innerHeight - 8}px)`
                    }

                    const nameStyles = {
                        transform: `translate(${value[1].mouseX * window.innerWidth + 8}px, ${value[1].mouseY * window.innerHeight + 16}px)`
                    };

                    return (
                        <>
                            <img style={styles} className="absolute h-[28px] z-[100]" src={'/images/icons/ic-cursor.svg'}></img>
                            <p style={nameStyles} className="absolute font-caption-1 font-semibold text-white bg-purple rounded-md py-1 px-1.5 z-[300]">{value[1].username || 'Guest'}</p>
                        </>
                    )
                })}
            </div>

            {selectedSubstitute && (
                <SidePanel
                    title={entityContext?.medicalItems.find((v) => v.id === selectedSubstitute.substituteItemID)?.name || ''}
                    titleLabel="Item"
                    icon="ic-shipping-box-black.svg"
                    iconGradient="teal"
                    chatRoomIds={[selectedSubstitute.chatRef]}
                    onDismiss={() => setSelectedSubstituteId(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Details</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Requested By</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedSubstitute.requestedBy}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Substitution Length</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedSubstitute.substituteUntil ? moment(selectedSubstitute.substituteUntil?.toDate()).fromNow(true) : 'Indefinite'}</p>
                                    </div>

                                    {selectedSubstitute.status === 'Used' && selectedSubstitute.approvedBy && (
                                        <>
                                            <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                            <div className="flex p-2.5">
                                                <p className="font-body">Approved By</p>
                                                <p className="font-body text-neutral-5 ml-auto">{userContext?.users.find((v) => v.id === selectedSubstitute.approvedBy)?.name || ''}</p>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <p className="font-body font-semibold px-2">Manufacturer</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Name</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedSubstitute?.manufacturer}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Item ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedSubstitute?.manufacturerItemID}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Vendor</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Name</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedSubstitute.vendor}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Item ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedSubstitute.vendorItemID}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full">
                            <PrimaryButton
                                title="View Item"
                                type="ghost"
                                size="xl"
                                icon="ic-arrow-top-right-grey.svg"
                                iconSize={10}
                            />

                            {selectedSubstitute.status === 'Requested' && (
                                <PrimaryButton
                                    title="Approve Substitute"
                                    type="green"
                                    size="xl"
                                    leftIcon="ic-checkmark-circle-fill.svg"
                                    maxWidth={true}
                                    onClick={() => {
                                        updateSubstitute(selectedSubstitute.id, {
                                            status: 'Active'
                                        })

                                        medicalItem.subscribed?.substitute.accepted.forEach((userId) => {
                                            const user = userContext?.users.find((v) => v.id === userId)

                                            if (user) {
                                                httpsCallable(functions, 'sendMail')({
                                                    to: user.email,
                                                    subject: 'Substitute Alert',
                                                    text: `${medicalItem.name} is accepted`
                                                })
                                            }
                                        })
                                    }}
                                />
                            )}

                            {selectedSubstitute.status === 'Active' && (
                                <PrimaryButton
                                    title="Use as Substitute"
                                    type="primary"
                                    size="xl"
                                    maxWidth={true}
                                    onClick={() => setUseSubstituteDialog(true)}
                                />
                            )}

                            {selectedSubstitute.status === 'Used' && (
                                <PrimaryButton
                                    title="Stop Substitution"
                                    type="critical"
                                    icon="ic-square-white.svg"
                                    iconSize={12}
                                    size="xl"
                                    maxWidth={true}
                                    onClick={() => {
                                        updateSubstitute(selectedSubstitute.id, {
                                            status: 'Active'
                                        })

                                        medicalItem.subscribed?.substitute.end.forEach((userId) => {
                                            const user = userContext?.users.find((v) => v.id === userId)

                                            if (user) {
                                                httpsCallable(functions, 'sendMail')({
                                                    to: user.email,
                                                    subject: 'Substitute Alert',
                                                    text: `Substitute is no longer used as substitute`
                                                })
                                            }
                                        })
                                    }}
                                />
                            )}
                        </div>

                    }
                />
            )}

            {selectedProcedure && (
                <SidePanel
                    title={selectedProcedure?.name || ''}
                    titleLabel="Scene"
                    icon="ic-scenes-white.svg"
                    iconGradient="purple"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedProcedure(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Information</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedProcedure?.id}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Category</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedProcedure?.category}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Location</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedProcedure?.location}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Resources & Cost</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Avg. Cost</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedProcedure?.cost}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Surgeons</p>
                                        <p className="font-body text-neutral-5 ml-auto">{`${selectedProcedure?.surgeons} performing`}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Items</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedProcedure?.items}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Preferences</p>
                                        <p className="font-body text-neutral-5 ml-auto">{mockPreferences.filter((v) => v.id === selectedProcedure.id).length}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Orders</p>

                                <div className="rounded-[5px] h-[250px] border border-neutral-2 bg-card p-2">
                                    <BarChart
                                        datasets={[
                                            {
                                                data: [75, 100, 125, 165, 175, 65],
                                                color: 'neutral-4'
                                            },
                                            {
                                                data: [20, 30, 40, 50, 60, 20],
                                                color: 'mint'
                                            },
                                            {
                                                data: [15, 25, 35, 45, 55, 15],
                                                color: 'caution'
                                            },

                                            {
                                                data: [10, 20, 30, 40, 50, 10],
                                                color: 'info'
                                            },
                                        ]}
                                        breakpoints={[0, 50, 100, 150, 200]}
                                        labels={['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']}
                                        legends={[
                                            {
                                                title: "Surgery",
                                                color: 'neutral-4',
                                                subtitle: '102 units'
                                            },
                                            {
                                                title: "Emergency",
                                                color: "info",
                                                subtitle: '14 units'
                                            },
                                            {
                                                title: "Radiology",
                                                color: "caution",
                                                subtitle: '142 units'
                                            },
                                            {
                                                title: "Intensive Care",
                                                color: "mint",
                                                subtitle: '225 units'
                                            }
                                        ]}
                                        selectedLabel={'Jul'}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full">
                            <PrimaryButton
                                title="View Procedure"
                                type="ghost"
                                size="xl"
                                icon="ic-arrow-top-right-grey.svg"
                                iconSize={10}
                                onClick={() =>
                                    navigate(`../dashboard/procedure/${selectedProcedure.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                }
                            />

                            <PrimaryButton
                                title="Add Preference"
                                type="primary"
                                size="xl"
                                icon="ic-plus-circle-fill-white.svg"
                                maxWidth={true}
                                onClick={() =>
                                    navigate(`../dashboard/procedure/${selectedProcedure.name}#addpreference`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                }
                            />
                        </div>

                    }
                />
            )}

            {selectedOrder && (
                <SidePanel
                    title={selectedOrder?.name || ''}
                    titleLabel="Order"
                    icon="ic-box-truck-black.svg"
                    iconGradient="yellow"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedOrder(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Overview</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder.id}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="pt-2">
                                        <div style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.13) inset' }} className="flex w-full h-[21px] gap-[2px] bg-neutral-1 rounded-md">
                                            <div style={{ width: `${selectedOrder.receivedQuantity / selectedOrder.itemsTotal * 100}%` }} className={`bg-info rounded-s-md`}>

                                            </div>

                                            <div style={{ width: `${selectedOrder.leftQuantity / selectedOrder.itemsTotal * 100}%` }} className="font-caption-2 text-neutral-5 text-center my-auto">
                                                {`${selectedOrder.leftQuantity} left`}
                                            </div>


                                        </div>

                                        <div className="flex py-1 gap-2">
                                            <div className="flex items-center font-caption-1 gap-1.5">
                                                <div className={`h-2 w-2 bg-info rounded-full`}>

                                                </div>

                                                <div>
                                                    <p className="font-caption-1 text-neutral-5">Received</p>
                                                    <p className="font-caption-1 text-neutral-6">{selectedOrder.receivedQuantity}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Location</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Branch</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder?.location}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Financial</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5 items-center">
                                        <p className="font-body">Items</p>
                                        <p className="font-body text-neutral-5 ml-auto">

                                            <PrimaryButton
                                                title="View All"
                                                type="white"
                                                size="md"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full">
                            <PrimaryButton
                                title="Call Supplier"
                                type="primary"
                                size="xl"
                                icon="ic-phone-white.svg"
                                iconSize={12}
                                maxWidth={true}
                            />
                        </div>

                    }
                />
            )}

            {selctedVendor && (
                <SidePanel
                    title={selctedVendor?.name || ''}
                    titleLabel="Vendor"
                    icon="ic-credit-card-white.svg"
                    iconGradient="pink"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedVendor(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Performance</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Missed orders</p>
                                        <p className="font-body text-neutral-5 ml-auto">0</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Delayed orders</p>
                                        <p className="font-body text-neutral-5 ml-auto">2%</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Product quality</p>
                                        <p className="font-body text-neutral-5 ml-auto">4%</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Invoice discrepancies</p>
                                        <p className="font-body text-neutral-5 ml-auto">12%</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Accuracy</p>
                                        <p className="font-body text-neutral-5 ml-auto">4%</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Representative</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Sales</p>
                                        <p className="font-body text-neutral-5 ml-auto">Jenna Spencer</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Phone number</p>
                                        <p className="font-body text-neutral-5 ml-auto">+44 7777 800 900</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Contract</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Value</p>
                                        <p className="font-body text-neutral-5 ml-auto">£ 80k</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Ends in</p>
                                        <p className="font-body text-neutral-5 ml-auto">3 months</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Payment schedule</p>
                                        <p className="font-body text-neutral-5 ml-auto">Bi-weekly</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5 items-center">
                                        <p className="font-body">PDF Contract</p>
                                        <p className="font-body text-neutral-5 ml-auto">

                                            <PrimaryButton
                                                title="View"
                                                type="white"
                                                size="md"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full">
                            <PrimaryButton
                                title="Call Representative"
                                type="primary"
                                size="xl"
                                icon="ic-phone-white.svg"
                                iconSize={12}
                                maxWidth={true}
                            />
                        </div>

                    }
                />
            )}
        </>
    )
}

export default InventoryItem