import { useState, useRef, useEffect } from "react";
import DatePickerDialog from "../datePickerDialog/datePickerDialog";

const Input = (props: {
    type: string,
    label?: string,
    placeholder?: string,
    options?: {
        label: string,
        value: string,
        icon?: string,
        subtitle?: string
    }[],
    value?: string,
    dateValue?: Date,
    withBorder?: boolean,
    autoFocus?: boolean,
    onChange?: (value: string) => void,
    onDateChange?: (date: Date | undefined) => void,
    onSearchChange?: (value: string) => void,
    onOptionChange?: (option: {
        label: string,
        value: string,
        icon?: string,
        subtitle?: string
    }) => void,
}) => {
    const [dropdownShown, setDropdownShown] = useState(false)
    const [focused, setFocused] = useState(false)
    const [dateDialog, setDateDialog] = useState(false)

    const dropdownRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!dropdownRef.current?.contains(event.target as Node)) {
                setDropdownShown(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    return (
        <>
            {props.type === 'tooltip' ?
                <div
                    style={{
                        boxShadow: true ? '0px 0px 0px 3px rgba(15, 98, 254, 0.50), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)' : '0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)',
                    }}
                    className="relative font-body flex gap-1 pl-3 rounded-md bg-neutral-2">
                    <input value={props.value} autoFocus onChange={(e) => props.onChange?.(e.target.value)} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} className="w-full focus:outline-none rounded-md bg-transparent" type="text" placeholder={props.placeholder}></input>

                    <img onClick={() => props.onChange?.('')} className="w-[12px] h-[12px] absolute right-1 top-0.5 cursor-pointer" src="/images/icons/ic-xmark-circle.svg" />
                </div>
                :
                <div className="relative flex flex-col gap-1">
                    {props.label && (
                        <p className="font-subheadline text-neutral-5">{props.label}</p>
                    )}

                    <div
                        style={{
                            borderWidth: props.withBorder ? '1px' : '0.5px',
                            boxShadow: props.withBorder ? '' : focused || dropdownShown ? '0px 0px 0px 3px rgba(15, 98, 254, 0.50), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)' : '0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)',
                            backgroundColor: props.type === 'select' ? 'white' : 'white'
                        }}
                        className="font-body placeholder:text-neutral-4 text-neutral-6 border-neutral-2 rounded-md pl-3 pr-1 py-1">

                        {props.type === "select" && (
                            <div onClick={() => setDropdownShown(!dropdownShown)} className="cursor-arrow flex items-center">
                                <p className="w-full pr-2">{props.options?.find((option) => option.value === props.value)?.label}</p>

                                {dropdownShown ?
                                    <img className="rounded-md p-1.5 bg-primary-4/10 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down-blue.svg`}></img>
                                    :
                                    <img className="rounded-md p-1.5 bg-neutral-2 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down.svg`}></img>
                                }
                            </div>
                        )}

                        {props.type === "date" && (
                            <div onClick={() => setDateDialog(!dateDialog)} className="cursor-pointer flex items-center">
                                {props.dateValue ?
                                    <p className="w-full pr-2">{props.dateValue?.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) || 'Indefinite'}</p>
                                    :
                                    <p className="w-full pr-2">{'Indefinite'}</p>
                                }


                                {dropdownShown ?
                                    <img className="rounded-md p-1.5 bg-primary-4/10 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down-blue.svg`}></img>
                                    :
                                    <img className="rounded-md p-1.5 bg-neutral-2 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down.svg`}></img>
                                }
                            </div>
                        )}

                        {props.type === "text" && (
                            <div className="">
                                <input value={props.value} onChange={(e) => props.onChange?.(e.target.value)} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} className="w-full focus:outline-none rounded-md py-1" type="text" placeholder={props.placeholder}></input>
                            </div>
                        )}

                        {props.type === "password" && (
                            <div className="">
                                <input value={props.value} onChange={(e) => props.onChange?.(e.target.value)} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} className="w-full focus:outline-none rounded-md py-1" type="password" placeholder={props.placeholder}></input>
                            </div>
                        )}

                        {props.type === "search" && (
                            <div className="flex gap-2 items-center">
                                <img className="h-[14px] w-[14px]" src={`/images/icons/ic-magnifying-glass${props.value ? '-black' : ''}.svg`}></img>

                                <input value={props.value} onChange={(v) => props.onSearchChange?.(v.target.value)} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} className="w-full focus:outline-none rounded-md py-1" type="text" placeholder={props.placeholder}></input>

                                {props.value && (
                                    <img onClick={() => props.onSearchChange?.('')} className="cursor-pointer h-[14px] w-[14px] mr-1" src={`/images/icons/ic-xmark-circle-grey.svg`}></img>
                                )}
                            </div>
                        )}

                        {props.type === "textarea" && (
                            <div className="">
                                <textarea onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} className="w-full focus:outline-none rounded-md py-1 pr-1" rows={3} placeholder={props.placeholder}></textarea>
                            </div>
                        )}
                    </div>

                    {dropdownShown && (
                        <div ref={dropdownRef} style={{ top: props.label ? '60px' : '40px' }} className="max-h-[300px] overflow-y-auto min-w-full w-max absolute left-0 z-[300] origin-top-left rounded-md bg-material-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none backdrop-blur-xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                            <div className="p-1" role="none">
                                {(props.options || []).map((option) => {
                                    return (
                                        <div onClick={() => {
                                            props.onChange?.(option.value)
                                            setDropdownShown(false)
                                            props.onOptionChange?.(option)
                                        }} className="cursor-pointer flex gap-1 w-full p-1 hover:bg-primary-4 rounded-md hover:text-white items-center">
                                            {option.icon && (
                                                <img className="h-[14px] w-[14px]" src={`/images/icons/${option.icon}`}></img>
                                            )}

                                            <p className="font-body whitespace-nowrap">{option.label}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                    {props.type === "search" && props.value && (props.options || []).filter((option) => option.label.toLowerCase().includes((props.value || '').toLowerCase())).length > 0 && (
                        <div ref={dropdownRef} className="max-h-[300px] overflow-y-auto min-w-full w-max absolute left-0 z-[300] mt-14 origin-top-left rounded-md bg-material-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none backdrop-blur-2xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                            <div className="p-1" role="none">
                                {(props.options || []).filter((option) => option.label.toLowerCase().includes((props.value || '').toLowerCase()) || props.value === '').splice(0, 5).map((option) => {
                                    return (
                                        <div onClick={() => {
                                            props.onChange?.(option.value)
                                            setDropdownShown(false)
                                            props.onSearchChange?.(option.value)
                                            props.onOptionChange?.(option)
                                        }} className="group cursor-pointer flex gap-1 w-full p-1 hover:bg-primary-4 rounded-md group-hover:text-white items-center">
                                            {option.icon && (
                                                <img className="h-[14px] w-[14px]" src={`/images/icons/${option.icon}`}></img>
                                            )}

                                            <p className="font-body whitespace-nowrap group-hover:text-white">{option.label}</p>

                                            <p className="ml-auto font-body text-neutral-5 whitespace-nowrap group-hover:text-white">{option.subtitle}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}

                    {dateDialog && (
                        <DatePickerDialog
                            onDismiss={() => setDateDialog(false)}

                            onRemove={() => {
                                props.onDateChange?.(undefined)
                                setDateDialog(false)
                            }}

                            onSelect={(v) => {
                                props.onDateChange?.(v)
                                setDateDialog(false)
                            }}
                        />
                    )}
                </div>
            }
        </>
    )
}

export default Input