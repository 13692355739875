import { ReactNode, useState, useRef, useEffect } from "react"

const Select = (props: {
    size: string,
    options: {
        label: string,
        value: string,
        icon?: string,

    }[],
    type?: string
    selectedOption?: string,
    label?: string,
    multiselect?: boolean,
    selectedOptions?: string[],
    onChange?: (value: string) => void,
}) => {
    const [dropdownShown, setDropdownShown] = useState(false)
    const [position, setPosition] = useState({ top: 0, left: 0 })

    const ref = useRef<HTMLInputElement>(null)
    const dropdownRect = ref.current?.getBoundingClientRect()

    const getButtonHeight = (size: string): number => {
        switch (size) {
            case "sm":
                return 22
            case "md":
                return 28
            case "lg":
                return 30
            case "xl":
                return 36
            default:
                return 28
        }
    }

    const getButtonStyle = (type: string): string => {
        switch (type) {
            case "ghost":
                return "bg-transparent"
            case "light-ghost":
                return "bg-primary-2/20"
            case "white":
                return "bg-white"
            case "gray":
                return "bg-neutral-2"
            case "primary":
                return `bg-gradient-to-t from-primary-4 to-primary-5`
            default:
                return "bg-neutral-2"
        }
    }

    const getButtonTextStyle = (type: string): string => {
        switch (type) {
            case "ghost":
                return "text-neutral-5"
            case "light-ghost":
                return "text-primary-4"
            case "primary":
                return `text-white`
            default:
                return `text-black`
        }
    }

    const getButtonShadowStyle = (type: string): string => {
        switch (type) {
            case "white":
                return "0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)"
            case "primary":
                return `0px 0px 0px 0.5px rgba(15, 98, 254, 0.12), 0px 1px 2.5px 0px rgba(15, 98, 254, 0.25)`
            default:
                return ``
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!ref.current?.contains(event.target as Node)) {
                setDropdownShown(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    useEffect(() => {
        if (!dropdownShown) { return }
        if (!ref.current) { return }


        const rect = ref.current.getBoundingClientRect()
        const isOutsideRight = rect.right > window.innerWidth

        if (isOutsideRight) {
            const difference = window.innerWidth - rect.right
            setPosition(prevPosition => ({ ...prevPosition, top: prevPosition.top, left: difference }));
        }

        if (!isOutsideRight) {
            const difference = window.innerWidth - (rect.right - position.left)

            if (difference > 0) {
                setPosition({ top: 0, left: 0 })
            }
        }
    }, [dropdownShown])

    return (
        <div className="relative inline-block text-left w-fit ">
            <div onClick={() => setDropdownShown(true)} style={{ height: `${getButtonHeight(props.size)}px`, padding: '6px 8px', boxShadow: getButtonShadowStyle(props.type || '') }} className={` ${getButtonStyle(props.type || '')} flex font-body text-black bg-neutral-2 rounded-md items-center cursor-arrow`}>
                <div className="relative flex gap-1 items-center my-auto">
                    {props.options.find((option) => option.value === props.selectedOption)?.icon && (
                        <img className="h-[14px] w-[14px]" src={`/images/icons/${props.options.find((option) => option.value === props.selectedOption)?.icon}`}></img>
                    )}

                    {props.label ?
                        <p className={`${getButtonTextStyle(props.type || '')} pr-[18px] whitespace-nowrap`}>{props.label}</p>
                        :
                        <p className={`${getButtonTextStyle(props.type || '')} pr-[18px] whitespace-nowrap`}>{props.options.find((option) => option.value === props.selectedOption)?.label}</p>
                    }


                    <div className={`${getButtonTextStyle(props.type || '')} absolute right-0 w-[12px]`}>
                        <svg className="fill-current" width="12" height="7" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.13477 6.80029C5.97396 6.80029 5.83008 6.73893 5.70312 6.61621L0.790039 1.58887C0.735026 1.53385 0.692708 1.47249 0.663086 1.40479C0.633464 1.33285 0.618652 1.25667 0.618652 1.17627C0.618652 1.06624 0.644043 0.966797 0.694824 0.87793C0.745605 0.789062 0.813314 0.719238 0.897949 0.668457C0.986816 0.617676 1.08626 0.592285 1.19629 0.592285C1.3571 0.592285 1.49463 0.647298 1.60889 0.757324L6.47119 5.72754H5.79199L10.6543 0.757324C10.7728 0.647298 10.9103 0.592285 11.0669 0.592285C11.1769 0.592285 11.2743 0.617676 11.3589 0.668457C11.4478 0.719238 11.5176 0.789062 11.5684 0.87793C11.6191 0.966797 11.6445 1.06624 11.6445 1.17627C11.6445 1.33285 11.5874 1.46826 11.4731 1.58252L6.56006 6.61621C6.50505 6.67546 6.43945 6.72201 6.36328 6.75586C6.29134 6.78548 6.21517 6.80029 6.13477 6.80029Z" />
                        </svg>
                    </div>
                </div>
            </div>

            {dropdownShown && (
                <div ref={ref} style={{ top: ``, left: `${position.left}px`, boxShadow: 'box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.1); 0px 0px 2px 0px rgba(0, 0, 0, 0.3); 0px 0px 1px 0px rgba(255, 255, 255, 0.1) inset;' }} className="w-max absolute left-0 z-[300] mt-2 origin-top-left rounded-md bg-white/60 ring-1 ring-black ring-opacity-5 focus:outline-none backdrop-blur-2xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                    <div className="p-1" role="none">
                        {props.options.map((option, index) => {
                            return (
                                <div key={index} onClick={() => {
                                    props.onChange?.(option.value)
                                    setDropdownShown(false)
                                }} className="group cursor-pointer flex gap-1 w-full p-1 hover:bg-primary-4 rounded-md hover:text-white items-center">
                                    {props.multiselect && (
                                        props.selectedOptions?.includes(option.value) ?
                                            <>
                                                <img className="h-[12px] w-[12px] mr-0.5 group-hover:hidden" src='/images/icons/ic-checkmark-blue.svg'></img>
                                                <img className="h-[12px] w-[12px] mr-0.5 hidden group-hover:block" src='/images/icons/ic-checkmark.svg'></img>
                                            </>
                                            :
                                            <div className="w-[12px] mr-0.5"></div>
                                    )}

                                    {option.icon && (
                                        <img className="h-[14px] w-[14px]" src={`/images/icons/${option.icon}`}></img>
                                    )}

                                    <p className="font-body whitespace-nowrap">{option.label}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Select