import { useState, useEffect, useContext } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie"

import uuid from "react-uuid"

import ListItemRow from "../../components/listItemRow/listItemRow"
import Select from "../../components/select/select"
import StackedListBar from "../../components/stackedListBar/stackedListBar"
import PrimaryButton from "../../components/primaryButton/primaryButton"
import SearchBar from "../../components/searchBar/searchBar"
import SidebarNavigationLink from "../../components/sidebarNavigationLink/sidebarNavigationLink"
import Accordion from "../../components/accordion/accordion"
import CreateItemDialog from "../../components/createItemDialog/createItemDialog"
import DisruptedDialog from "../../components/disruptedDialog/disruptedDialog"
import SidePanel from "../../components/sidePanel/sidePanel"

import { Vendor, mockVendors } from "../../model/vendor/vendor"
import { People, mockSurgeons } from "../../model/people/people"
import { MedicalItem } from "../../model/medicalItem/medicalItem"
import { Procedure, mockProcedures } from "../../model/procedure/procedure"
import { mockContracts } from "../../model/contract/contract"
import { mockPreferences } from "../../model/preference/preference"
import { Order, mockOrders } from "../../model/order/order"
import { locations } from "../../model/location/location"
import { ChatContext, CursotChatContext, EntityContext, LocationContext, TeamContext, UserContext } from "../../context/globalContext"
import { Notification } from "../../model/notification/notification"
import { Timestamp, doc, setDoc, updateDoc } from "firebase/firestore"
import { auth, firestore } from "../../firebase/firebase"

const Inventory = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['displayName', 'uid']);

    const [currentPage, setCurrentPage] = useState('')
    const [selectedItemStatus, setSelectedItemStatus] = useState('all')
    const [selectedItemAssigneeStatus, setSelectedItemAssigneeStatus] = useState<string[]>([])
    const [vendorStatus, setVendorStatus] = useState<string[]>(['all'])
    const [selectedPeopleTeam, setSelectedPeopleTeam] = useState('All')
    const [selectedPeopleStatus, setSelectedPeopleStatus] = useState<string[]>([])
    const [selectedSceneStatus, setSelectedSceneStatus] = useState('All')

    const [selectedOrderLocation, setSelectedOrderLocation] = useState('')
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('All')

    const [searchItem, setSearchItem] = useState('')
    const [searchScene, setSearchScene] = useState('')
    const [searchVendor, setSearchVendor] = useState('')
    const [searchPeople, setSearchPeople] = useState('')
    const [searchOrder, setSearchOrder] = useState('')

    // const [personalisedItems, setPersonalisedItems] = useState<MedicalItem[]>([])

    const [selectedOrder, setSelectedOrder] = useState<Order>()

    const [disruptedDialog, setDisruptedDialog] = useState('')

    const [createItemDialog, setCreateItemDialog] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    const entityContext = useContext(EntityContext)
    const userContext = useContext(UserContext)
    const locationContext = useContext(LocationContext)
    const teamContext = useContext(TeamContext)

    const personalisedItems = entityContext?.medicalItems.filter((item) => {
        if (userContext?.user?.personalisedView) {
            if (item.team !== userContext.user.team) { return false }
            if (item.location !== userContext.user.location) { return false }
        }

        if (locationContext?.selectedLocation && locationContext.selectedLocation !== 'all') {
            if (item.location !== locationContext.selectedLocation) { return false }
        }

        if (teamContext?.selectedTeam && teamContext.selectedTeam !== 'all') {
            if (item.team !== teamContext.selectedTeam) { return false }
        }

        return true
    }) || []

    const personalisedProcedures = mockProcedures.filter((item) => {
        if (userContext?.user?.personalisedView) {
            if (item.location !== userContext.user.location) { return false }
        }

        if (locationContext?.selectedLocation && locationContext.selectedLocation !== 'all') {
            if (item.location !== locationContext.selectedLocation) { return false }
        }

        return true
    }) || []

    const personalisedPeoples = mockSurgeons.filter((item) => {
        if (userContext?.user?.personalisedView) {
            if (item.team !== userContext.user.team) { return false }
            if (item.location !== userContext.user.location) { return false }
        }

        if (locationContext?.selectedLocation && locationContext.selectedLocation !== 'all') {
            if (item.location !== locationContext.selectedLocation) { return false }
        }

        if (teamContext?.selectedTeam && teamContext.selectedTeam !== 'all') {
            if (item.team !== teamContext.selectedTeam) { return false }
        }

        return true
    }) || []

    const personalisedOrders = mockOrders.filter((item) => {
        if (userContext?.user?.personalisedView) {
            if (item.location !== userContext.user.location) { return false }
        }

        if (locationContext?.selectedLocation && locationContext.selectedLocation !== 'all') {
            if (item.location !== locationContext.selectedLocation) { return false }
        }

        return true
    }) || []

    const procedureCategories = Array.from(new Set(personalisedProcedures.map(item => item.category)))

    useEffect(() => {
        setCurrentPage(location.hash)
    }, [location])

    useEffect(() => {
        if (location.hash === '') {
            navigate('#items')
        }

        document.title = 'Inventory'
    }, [])

    const getVendorFillRate = (vendor: Vendor): number => {
        const contracts = mockContracts.filter((contract) => vendor.contracts.toArray().includes(contract.id))
        const fillRates = contracts.map((v) => v.fillRate).reduce((a, c) => a + c, 0)

        if (contracts.length === 0) {
            return 0
        }

        return fillRates / contracts.length
    }

    const filterItem = (item: MedicalItem): boolean => {
        const disruption = entityContext?.disruptions.find((v) => v.entity === item.id)

        if (selectedItemStatus === 'disrupted' && !disruption) {
            return false
        }

        if (selectedItemStatus === 'stable' && disruption) {
            return false
        }

        const assigned: boolean = (selectedItemAssigneeStatus.includes('Assigned') && (disruption?.assignee.length || 0) > 0)
        const unassigned: boolean = (selectedItemAssigneeStatus.includes('Unassigned') && !((disruption?.assignee.length || 0) > 0))

        return (item.name.toLowerCase().includes(searchItem.toLowerCase()) ||
            item.assignee?.toLowerCase().includes(searchItem.toLowerCase()) ||
            item.id?.toLowerCase().includes(searchItem.toLowerCase()) ||
            !searchItem) && (assigned || unassigned || selectedItemAssigneeStatus.length === 0 || selectedItemAssigneeStatus.includes('All'))

    }

    const filterScene = (scene: Procedure): boolean => {
        return scene.name.toLowerCase().includes(searchScene.toLowerCase()) ||
            mockPreferences.some((v) => v.id === scene.id && v.surgeon.toLowerCase().includes(searchScene.toLowerCase())) ||
            !searchScene
    }

    const filterVendor = (vendor: Vendor): boolean => {
        return vendor.name.toLowerCase().includes(searchVendor.toLowerCase()) ||
            !searchVendor
    }

    const filterPeople = (people: People): boolean => {
        return (`${people.name.toLowerCase()} ${people.surname.toLowerCase()}`.includes(searchPeople.toLowerCase()) ||
            people.team.toLowerCase().includes(searchPeople.toLowerCase()) ||
            !searchPeople) &&
            (selectedPeopleStatus.includes(people.status) || selectedPeopleStatus.includes('All') || selectedPeopleStatus.length === 0)
    }

    const filterOrder = (order: Order): boolean => {
        return (order.id.toLowerCase().includes(searchOrder.toLowerCase()) ||
            order.name.toLowerCase().includes(searchOrder.toLowerCase()) ||
            !searchOrder) &&
            (order.status === selectedOrderStatus || selectedOrderStatus === 'All') &&
            (order.location === selectedOrderLocation || selectedOrderLocation === '')
    }

    const createDisruption = async (id: string, assignee: string, type: string, resolveBy: Date | undefined) => {
        let disruption = entityContext?.disruptions.find((v) => v.entity === id)

        if (!disruption) { return }

        disruption.assignee = assignee
        disruption.type = type

        if (resolveBy) {
            disruption.resolveBy = Timestamp.fromDate(resolveBy)
        }

        const disruptionAssignedNotification: Notification = {
            id: uuid(),
            title: 'New assignment',
            subtitle: `${userContext?.user?.name.split(' ')[0]} has assigned you a Disruption. Follow up in conversation.`,
            time: Timestamp.now(),
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            recipients: [assignee],
            seenBy: [],
            type: 'disruptionAssigned'
        }

        await setDoc(doc(firestore, 'disruptions', disruption.entity), disruption, { merge: true })
        await setDoc(doc(firestore, 'notifications', disruptionAssignedNotification.id), disruptionAssignedNotification)

        updateDoc(doc(firestore, 'items', disruption.entity), {
            lastUpdated: Timestamp.now(),
            lastUpdatedBy: auth.currentUser?.uid
        })
    }

    return (
        <>
            <div className="relative flex mt-8 pb-4">
                <div className="flex mx-auto gap-4">
                    <div className="fixed flex flex-col gap-1 w-[170px]">
                        <SidebarNavigationLink
                            title="Items"
                            icon="ic-shipping-box.svg"
                            hashLink="#items"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Scenes"
                            icon="ic-scenes.svg"
                            hashLink="#scenes"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Vendors"
                            icon="ic-credit-card.svg"
                            hashLink="#vendors"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="People"
                            icon="ic-profile.svg"
                            hashLink="#people"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Orders"
                            icon="ic-box-truck-blue.svg"
                            hashLink="#orders"
                            currentHashLink={currentPage}
                        />
                    </div>

                    <div className="ml-[186px] flex flex-col gap-2">
                        {currentPage === '#items' && (
                            <>
                                <StackedListBar
                                    title="Disruptions"
                                    subtitle={`${personalisedItems.filter((item) => entityContext?.disruptions.find((v) => v.entity === item.id && !v.resolved)).length} of ${personalisedItems.length} disrupted`}
                                    datasets={[
                                        {
                                            title: 'Unassigned',
                                            count: entityContext?.disruptions.filter((v) => personalisedItems.some((i) => i.id === v.entity) && !v.resolved && !v.assignee).length || 0,
                                            color: 'primary-4',

                                        },
                                        {
                                            title: 'In progress',
                                            count: entityContext?.disruptions.filter((v) => personalisedItems.some((i) => i.id === v.entity) && !v.resolved && v.assignee).length || 0,
                                            color: 'caution',

                                        },
                                        {
                                            title: `${personalisedItems.length - personalisedItems.filter((item) => entityContext?.disruptions.find((v) => v.entity === item.id && !v.resolved)).length} resolved`,
                                            count: personalisedItems.length - personalisedItems.filter((item) => entityContext?.disruptions.find((v) => v.entity === item.id && !v.resolved)).length,
                                            color: '',
                                            showLabel: true,
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar onChange={(v) => setSearchItem(v)} />

                                        <Select
                                            size="lg"
                                            multiselect={true}
                                            options={[
                                                {
                                                    label: 'Assigned',
                                                    value: 'Assigned',
                                                },
                                                {
                                                    label: 'Unassigned',
                                                    value: 'Unassigned',
                                                },
                                            ]}
                                            label="Assign Status"
                                            selectedOptions={selectedItemAssigneeStatus}
                                            onChange={(v) => {
                                                if (selectedItemAssigneeStatus.includes(v)) {
                                                    setSelectedItemAssigneeStatus(selectedItemAssigneeStatus.filter((s) => s !== v))
                                                } else {
                                                    setSelectedItemAssigneeStatus([...selectedItemAssigneeStatus, v])
                                                }
                                            }}
                                        />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Assignee',
                                                    value: 'all',
                                                },
                                                {
                                                    label: 'Disrupted',
                                                    value: 'disrupted',
                                                },
                                                {
                                                    label: 'Stable',
                                                    value: 'stable',
                                                },
                                            ]}
                                            selectedOption={selectedItemStatus}
                                            onChange={(v) => setSelectedItemStatus(v)}
                                        />

                                        <PrimaryButton
                                            type="primary"
                                            title="Create Item"
                                            icon="ic-plus-circle-white.svg"
                                            onClick={() => setCreateItemDialog(true)}
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 275px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {personalisedItems
                                            .filter((item) => {
                                                const disruption = entityContext?.disruptions.find((v) => v.entity === item.id)

                                                return disruption && !disruption.resolved
                                            })
                                            .filter((v) => filterItem(v))
                                            .map((item) => {
                                                const disruption = entityContext?.disruptions.find((v) => v.entity === item.id)
                                                const assignee = userContext?.users.find((v) => v.id === disruption?.assignee)

                                                return (
                                                    <ListItemRow
                                                        key={uuid()}
                                                        title={item.name}
                                                        subtitle={disruption ? disruption.type.charAt(0).toUpperCase() + disruption.type.slice(1) : item.status}
                                                        icon={{
                                                            icon: 'ic-warning-triangle.svg',
                                                            gradient: 'orange'
                                                        }}
                                                        arrowLabel={assignee?.name}
                                                        arrowRight={true}
                                                        onArrowClick={() => {
                                                            navigate(`${item.id}#overview`, { state: { previousLocationPathName: location.pathname } })
                                                        }}
                                                    >
                                                        {!assignee && (
                                                            <div className="z-[100]">
                                                                <PrimaryButton
                                                                    title="Assign"
                                                                    type="light-ghost"
                                                                    onClick={() => {
                                                                        setDisruptedDialog(item.id)
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </ListItemRow>
                                                )
                                            })
                                        }

                                        {(personalisedItems
                                            .filter((item) => {
                                                const disruption = entityContext?.disruptions.find((v) => v.entity === item.id)
                                                return disruption && !disruption.resolved
                                            })
                                            .filter((v) => filterItem(v)).length || 0) > 0 && (
                                                <div className="min-h-[1px] bg-neutral-2"></div>
                                            )
                                        }

                                        {personalisedItems
                                            .filter((item) => {
                                                const disruption = entityContext?.disruptions.find((v) => v.entity === item.id)

                                                return !disruption
                                            })
                                            .filter((v) => filterItem(v))
                                            .map((item) => {
                                                return (
                                                    <ListItemRow
                                                        key={uuid()}
                                                        title={item.name}
                                                        subtitle={item.status}
                                                        icon={{
                                                            icon: 'ic-circle-dashed.svg',
                                                            gradient: 'blue'
                                                        }}
                                                        arrowRight={true}
                                                        onArrowClick={() => {
                                                            navigate(`${item.id}#overview`, { state: { previousLocationPathName: location.pathname } })
                                                        }}
                                                    />
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </>
                        )}

                        {currentPage === '#scenes' && (
                            <>
                                <StackedListBar
                                    title="Scenes"
                                    subtitle={`${personalisedProcedures.length} available`}
                                    datasets={[
                                        {
                                            title: 'Surgeries',
                                            count: personalisedProcedures.filter((item) => item.category === "Surgeries").length,
                                            color: 'primary-4',

                                        },
                                        {
                                            title: 'Emergency Room',
                                            count: personalisedProcedures.filter((item) => item.category === "Emergency room").length,
                                            color: 'caution',

                                        },
                                        {
                                            title: 'Intensive Care Unit',
                                            count: personalisedProcedures.filter((item) => item.category === "Intensive care unit").length,
                                            color: 'mint',
                                        },
                                        {
                                            title: 'Delivery Room',
                                            count: personalisedProcedures.filter((item) => item.category === "Delivery room").length,
                                            color: 'success',

                                        },
                                        {
                                            title: 'Laboratory',
                                            count: personalisedProcedures.filter((item) => item.category === "Laboratory").length,
                                            color: 'purple',

                                        },
                                    ]}
                                />
                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="z-[100] flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar onChange={(v) => setSearchScene(v)} />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Status',
                                                    value: 'All',
                                                },
                                                {
                                                    label: 'Active',
                                                    value: 'Active',
                                                },
                                                {
                                                    label: 'Inactive',
                                                    value: 'Inactive',
                                                },
                                            ]}
                                            selectedOption={selectedSceneStatus}
                                            onChange={(v) => setSelectedSceneStatus(v)}
                                        />

                                        <PrimaryButton
                                            type="primary"
                                            title="Create Scene"
                                            icon="ic-plus-circle-white.svg"

                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 275px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {procedureCategories.map((category) => {
                                            const procedures = personalisedProcedures.filter((procedure) => procedure.category === category)

                                            return (
                                                <Accordion
                                                    title={category}
                                                    subtitle={`${procedures.filter((procedure) => {
                                                        if (selectedSceneStatus === "Inactive") {
                                                            return procedure.status === "FALSE"
                                                        } else if (selectedSceneStatus === "Active") {
                                                            return procedure.status === "TRUE"
                                                        } else {
                                                            return true
                                                        }
                                                    }).filter((v) => filterScene(v)).length} scenes`}

                                                    arrowLabel={`${procedures.filter((procedure) => {
                                                        if (selectedSceneStatus === "Inactive") {
                                                            return procedure.status === "FALSE"
                                                        } else if (selectedSceneStatus === "Active") {
                                                            return procedure.status === "TRUE"
                                                        } else {
                                                            return true
                                                        }
                                                    }).filter((v) => filterScene(v)).length} procedures`}
                                                >
                                                    {procedures.filter((procedure) => {
                                                        if (selectedSceneStatus === "Inactive") {
                                                            return procedure.status === "FALSE"
                                                        } else if (selectedSceneStatus === "Active") {
                                                            return procedure.status === "TRUE"
                                                        } else {
                                                            return true
                                                        }
                                                    }).filter((v) => filterScene(v)).map((procedure, index) => {
                                                        return (
                                                            <>

                                                                <ListItemRow
                                                                    title={procedure.name}
                                                                    subtitle={`${procedure.items} items • £${procedure.cost}`}
                                                                    arrowRight={true}
                                                                    hideBorder={true}
                                                                    onArrowClick={() => {
                                                                        navigate(`../dashboard/procedure/${procedure.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })
                                                                    }}
                                                                />

                                                                {index !== procedures.filter((procedure) => {
                                                                    if (selectedSceneStatus === "Inactive") {
                                                                        return procedure.status === "FALSE"
                                                                    } else if (selectedSceneStatus === "Active") {
                                                                        return procedure.status === "TRUE"
                                                                    } else {
                                                                        return true
                                                                    }
                                                                }).length - 1 && (
                                                                        <div className="h-[1px] bg-neutral-2 mx-2"></div>
                                                                    )}
                                                            </>
                                                        )
                                                    })}
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </div>
                            </>
                        )}

                        {currentPage === '#vendors' && (
                            <>
                                <StackedListBar
                                    title="Vendors"
                                    subtitle={`${mockVendors.filter((vendor) => getVendorFillRate(vendor) <= 70).length} of ${mockVendors.length} needs attention`}
                                    datasets={[
                                        {
                                            title: 'At risk',
                                            count: mockVendors.filter((vendor) => getVendorFillRate(vendor) <= 55).length,
                                            color: 'critical',

                                        },
                                        {
                                            title: 'Underperforming',
                                            count: mockVendors.filter((vendor) => getVendorFillRate(vendor) <= 70 && getVendorFillRate(vendor) > 55).length,
                                            color: 'caution',

                                        },
                                        {
                                            title: `${mockVendors.filter((vendor) => getVendorFillRate(vendor) > 70).length} performing well`,
                                            count: mockVendors.filter((vendor) => getVendorFillRate(vendor) > 70).length,
                                            color: '',
                                            showLabel: true,
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar onChange={(v) => setSearchVendor(v)} />

                                        <Select
                                            size="lg"
                                            multiselect={true}
                                            label="Contract Status"
                                            options={[
                                                {
                                                    label: 'Active',
                                                    value: 'active',
                                                },
                                                {
                                                    label: 'Inactive',
                                                    value: 'inactive',
                                                },
                                            ]}
                                            selectedOptions={vendorStatus}
                                            onChange={(v) => {
                                                if (vendorStatus.includes(v)) {
                                                    setVendorStatus(vendorStatus.filter((s) => s !== v))
                                                } else {
                                                    setVendorStatus([...vendorStatus, v])
                                                }
                                            }}
                                        />

                                        <PrimaryButton
                                            type="primary"
                                            title="Onboard Vendor"
                                            icon="ic-plus-circle-white.svg"

                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 275px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {mockVendors.filter((vendor) => getVendorFillRate(vendor) <= 70).filter((vendor) => {
                                            return vendorStatus.includes(vendor.status) || vendorStatus.includes('all')
                                        })
                                            .filter((v) => filterVendor(v))
                                            .map((vendor) => {
                                                return (
                                                    <ListItemRow
                                                        title={vendor.name}
                                                        subtitle={`${getVendorFillRate(vendor).toFixed(2)}% on time in full`}
                                                        icon={{
                                                            icon: getVendorFillRate(vendor) <= 55 ? 'ic-warning-circle-fill.svg' : getVendorFillRate(vendor) <= 70 ? 'ic-warning-triangle.svg' : 'ic-checkmark.svg',
                                                            gradient: getVendorFillRate(vendor) <= 55 ? 'red' : getVendorFillRate(vendor) <= 70 ? 'orange' : 'light-grey'
                                                        }}
                                                        arrowLabel={`${vendor.contracts.toArray().length} contracts`}
                                                        arrowRight={true}
                                                        onArrowClick={() => navigate(`../dashboard/vendor/${vendor.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })}
                                                    />
                                                )
                                            })}

                                        <div className="h-[21px]">
                                            <div className="h-[1px] bg-neutral-2 mt-[10px]"></div>
                                        </div>

                                        {mockVendors.filter((vendor) => getVendorFillRate(vendor) > 70).filter((vendor) => {
                                            return vendorStatus.includes(vendor.status) || vendorStatus.includes('all')
                                        })
                                            .filter((v) => filterVendor(v))
                                            .map((vendor) => {
                                                return (
                                                    <ListItemRow
                                                        title={vendor.name}
                                                        subtitle={`${getVendorFillRate(vendor).toFixed(2)}% on time in full`}
                                                        icon={{
                                                            icon: getVendorFillRate(vendor) <= 55 ? 'ic-warning-circle-fill.svg' : getVendorFillRate(vendor) <= 70 ? 'ic-warning-triangle.svg' : 'ic-checkmark.svg',
                                                            gradient: getVendorFillRate(vendor) <= 55 ? 'red' : getVendorFillRate(vendor) <= 70 ? 'orange' : 'light-grey'
                                                        }}
                                                        arrowLabel={`${vendor.contracts.toArray().length} contracts`}
                                                        arrowRight={true}
                                                        onArrowClick={() => navigate(`../dashboard/vendor/${vendor.name}#overview`, { relative: "route", state: { previousLocationPathName: location.pathname } })}
                                                    />
                                                )
                                            })}
                                    </div>
                                </div>
                            </>
                        )}

                        {currentPage === '#people' && (
                            <>
                                <StackedListBar
                                    title="People"
                                    subtitle={`${personalisedPeoples.length} accounts`}
                                    datasets={[
                                        {
                                            title: 'Clinical',
                                            count: personalisedPeoples.filter((item) => item.team === "Clinical").length,
                                            color: 'primary-4',

                                        },
                                        {
                                            title: 'External',
                                            count: personalisedPeoples.filter((item) => item.team === "External").length,
                                            color: 'caution',

                                        },
                                        {
                                            title: 'IT',
                                            count: personalisedPeoples.filter((item) => item.team === "IT").length,
                                            color: 'mint',
                                        },
                                        {
                                            title: 'Logistics',
                                            count: personalisedPeoples.filter((item) => item.team === "Logistics").length,
                                            color: 'purple',

                                        },
                                        {
                                            title: 'Procurement',
                                            count: personalisedPeoples.filter((item) => item.team === "Procurement").length,
                                            color: 'success',
                                        },
                                        {
                                            title: 'Sustainability',
                                            count: personalisedPeoples.filter((item) => item.team === "Sustainability").length,
                                            color: 'teal',
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar onChange={(v) => setSearchPeople(v)} />

                                        <Select
                                            size="lg"
                                            multiselect={true}
                                            label="Account Status"
                                            options={[
                                                {
                                                    label: 'Active',
                                                    value: 'Active',
                                                },
                                                {
                                                    label: 'Inactive',
                                                    value: 'Inactive',
                                                },
                                            ]}
                                            selectedOptions={selectedPeopleStatus}
                                            onChange={(v) => {
                                                if (selectedPeopleStatus.includes(v)) {
                                                    setSelectedPeopleStatus(selectedPeopleStatus.filter((s) => s !== v))
                                                } else {
                                                    setSelectedPeopleStatus([...selectedPeopleStatus, v])
                                                }
                                            }}
                                        />

                                        <PrimaryButton
                                            type="primary"
                                            title="Create Person"
                                            icon="ic-plus-circle-white.svg"

                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 275px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {personalisedPeoples.filter((people) => people.status === "Active").filter((people) => {
                                            if (selectedPeopleTeam === "All") {
                                                return true
                                            } else {
                                                return selectedPeopleTeam === people.team
                                            }
                                        }).filter((people) => {
                                            return filterPeople(people)
                                        }).map((people) => {
                                            return (
                                                <ListItemRow
                                                    title={`${people.name} ${people.surname}`}
                                                    subtitle={`Access to ${people.accessApps} apps`}
                                                    arrowRight={true}
                                                />
                                            )
                                        })}

                                        <div className="h-[34px] flex items-end">
                                            <p className="ml-2 font-caption-1 font-semibold text-neutral-5">Disabled</p>
                                        </div>

                                        {personalisedPeoples.filter((people) => people.status === "Inactive").filter((people) => {
                                            if (selectedPeopleTeam === "All") {
                                                return true
                                            } else {
                                                return selectedPeopleTeam === people.team
                                            }
                                        }).filter((people) => {
                                            return filterPeople(people)
                                        }).map((people) => {
                                            return (
                                                <ListItemRow
                                                    title={people.name}
                                                    subtitle={'Access disabled'}
                                                    icon={{
                                                        icon: 'ic-minus-circle.svg',
                                                        gradient: 'light-grey'
                                                    }}
                                                    arrowRight={true}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </>
                        )}

                        {currentPage === '#orders' && (
                            <div className="flex flex-col gap-4">
                                <StackedListBar
                                    title="Orders"
                                    subtitle={`${personalisedOrders.filter((v) => v.status === 'Pending').length} of ${personalisedOrders.length} in progress`}
                                    datasets={[
                                        {
                                            title: 'Accepted',
                                            count: personalisedOrders.filter((v) => v.status === 'Accepted').length,
                                            color: 'neutral-5',

                                        },
                                        {
                                            title: 'Filled',
                                            count: personalisedOrders.filter((v) => v.status === 'Filled').length,
                                            color: 'caution',

                                        },
                                        {
                                            title: 'Hold',
                                            count: personalisedOrders.filter((v) => v.status === 'Hold').length,
                                            color: 'brown',
                                        },
                                        {
                                            title: 'Disrupted',
                                            count: personalisedOrders.filter((v) => v.status === 'Disrupted').length,
                                            color: 'critical',
                                        },
                                        {
                                            title: `${personalisedOrders.filter((v) => v.status === 'Pending').length} Pending`,
                                            count: personalisedOrders.filter((v) => v.status === 'Pending').length,
                                            color: '',
                                            showLabel: true
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar onChange={(v) => setSearchOrder(v)} />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'All',
                                                    value: 'All',
                                                },
                                                {
                                                    label: 'Accepted',
                                                    value: 'Accepted',
                                                },
                                                {
                                                    label: 'Filled',
                                                    value: 'Filled',
                                                },
                                                {
                                                    label: 'Hold',
                                                    value: 'Hold',
                                                },
                                                {
                                                    label: 'Disrupted',
                                                    value: 'Disrupted',
                                                },
                                            ]}
                                            selectedOption={selectedOrderStatus}
                                            onChange={(v) => setSelectedOrderStatus(v)}
                                        />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Location',
                                                    value: '',
                                                },
                                                ...locations.map((location) => {
                                                    return {
                                                        label: location,
                                                        value: location
                                                    }
                                                })
                                            ]}
                                            selectedOption={selectedOrderLocation}
                                            onChange={(v) => setSelectedOrderLocation(v)}
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 180px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {personalisedOrders.filter((v) => filterOrder(v)).map((order) => {
                                            return (
                                                <ListItemRow
                                                    title={order.name}
                                                    subtitle={`${order.itemsTotal} Items`}
                                                    icon={order.status === 'Disrupted' ? {
                                                        icon: 'ic-info-circle-fill-white.svg',
                                                        gradient: 'red'
                                                    } : order.status === 'Fill' ? {
                                                        icon: 'ic-warning-triangle.svg',
                                                        gradient: 'orange'
                                                    } : {
                                                        icon: 'ic-checkmark.svg',
                                                        gradient: 'blue'
                                                    }}
                                                    arrowLabel={order.location}
                                                    arrowRight={true}
                                                    onArrowClick={() => setSelectedOrder(order)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {createItemDialog && (
                    <CreateItemDialog
                        onSubmit={() => {
                            setCreateItemDialog(false)
                        }}
                    />
                )}

                {disruptedDialog && (
                    <DisruptedDialog
                        onDismiss={() => {
                            setDisruptedDialog('')
                        }}

                        onSubmit={(a, r, d) => {
                            createDisruption(disruptedDialog, a || '', r, d)
                            setDisruptedDialog('')
                        }}
                    />
                )}
            </div>

            {selectedOrder && (
                <SidePanel
                    title={selectedOrder?.name || ''}
                    titleLabel="Order"
                    icon="ic-box-truck-black.svg"
                    iconGradient="yellow"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedOrder(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Overview</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder.id}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="pt-2">
                                        <div style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.13) inset' }} className="flex w-full h-[21px] gap-[2px] bg-neutral-1 rounded-md">
                                            <div style={{ width: `${selectedOrder.receivedQuantity / selectedOrder.itemsTotal * 100}%` }} className={`bg-info rounded-s-md`}>

                                            </div>

                                            <div style={{ width: `${selectedOrder.leftQuantity / selectedOrder.itemsTotal * 100}%` }} className="font-caption-2 text-neutral-5 text-center my-auto">
                                                {`${selectedOrder.leftQuantity} left`}
                                            </div>


                                        </div>

                                        <div className="flex py-1 gap-2">
                                            <div className="flex items-center font-caption-1 gap-1.5">
                                                <div className={`h-2 w-2 bg-info rounded-full`}>

                                                </div>

                                                <div>
                                                    <p className="font-caption-1 text-neutral-5">Received</p>
                                                    <p className="font-caption-1 text-neutral-6">{selectedOrder.receivedQuantity}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Location</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Branch</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder?.location}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Financial</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5 items-center">
                                        <p className="font-body">Items</p>
                                        <p className="font-body text-neutral-5 ml-auto">

                                            <PrimaryButton
                                                title="View All"
                                                type="white"
                                                size="md"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full">
                            <PrimaryButton
                                title="Call Supplier"
                                type="primary"
                                size="xl"
                                icon="ic-phone-white.svg"
                                iconSize={12}
                                maxWidth={true}
                            />
                        </div>

                    }
                />
            )}
        </>
    )
}

export default Inventory