import { Timestamp } from 'firebase/firestore'
import '../../utils/string.ts'

export interface Vendor {
  id: string
  name: string 
  tag: string
  status: string
  contactName: string // Person's name
  industryRating: number 
  procedures: string // List of procedures affected
  contracts: string // List of contracts of the vendor
  orders: string // List of orders of the vendor
  spendWeekly: number[] // Chart data
  orderRejected: number[] // Chart data
  orderAccepted: number[] // Chart data
  orderHold: number[] // Chart data
  orderBackordered: number[] // Chart data
  issueIncomplete: number[] // Chart data
  issueQuality: number[] // Chart data
  issueDelay: number[] // Chart data
  issueQuantity: number[] // Chart data
  lastUpdated?: Timestamp
  lastUpdatedBy?: string
}

export const mockVendors: Vendor[] = [
  {
    "id": "VNDR-0000000",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Facilities Management, Laboratory Supplies",
    "contactName": "Matthew Charles",
    "industryRating": 3.1,
    "procedures": "[PR-039,PR-032,PR-066,PR-037,PR-070,PR-047,PR-068,PR-066,PR-072,PR-046]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000063,CNTRCT-0000049]",
    "orders": "[ORDR-0-0000013,ORDR-1-0000055,ORDR-2-0000020,ORDR-3-0000020,ORDR-4-0000022,ORDR-5-0000014,ORDR-6-0000017,ORDR-7-0000038,ORDR-8-0000042,ORDR-9-0000018,ORDR-10-0000024,ORDR-11-0000057,ORDR-12-0000052,ORDR-13-0000046,ORDR-14-0000035,ORDR-15-0000031,ORDR-16-0000047,ORDR-17-0000042,ORDR-18-0000020]",
    "spendWeekly": [
      3064,
      2908,
      2983,
      2902,
      2903,
      2905,
      2927,
      2984,
      3100,
      2998,
      3093,
      3095,
      2942,
      2901,
      2932,
      2901,
      3098,
      3060,
      2954,
      3098,
      3100,
      2950,
      2989,
      2934,
      3069,
      3045,
      2921,
      2991,
      2901,
      2915,
      2990,
      2918,
      3099,
      2997,
      3084,
      2997,
      3100,
      2984,
      3099,
      2955,
      3043,
      3004,
      3097,
      2901,
      3046,
      2914,
      2901,
      3062,
      2976,
      2975,
      2914,
      2938
    ],
    "orderRejected": [
      2938,
      16,
      17,
      14,
      2,
      2,
      4,
      0,
      10,
      11,
      5,
      1,
      13,
      18,
      19,
      4,
      12,
      0,
      3,
      10,
      13,
      19,
      4,
      19,
      19,
      15,
      9,
      0,
      4,
      5,
      18,
      19,
      18,
      19,
      0,
      4,
      11,
      19,
      18,
      0,
      10,
      19,
      1,
      0,
      9,
      12,
      5,
      4,
      0,
      2,
      6,
      10,
      11
    ],
    "orderAccepted": [
      0,
      8,
      1,
      6,
      4,
      10,
      2,
      6,
      0,
      10,
      6,
      8,
      8,
      11,
      3,
      11,
      3,
      10,
      9,
      5,
      0,
      1,
      10,
      1,
      9,
      11,
      11,
      11,
      4,
      8,
      7,
      6,
      0,
      1,
      9,
      9,
      2,
      0,
      11,
      10,
      2,
      9,
      10,
      11,
      5,
      7,
      3,
      11,
      8,
      11,
      2,
      10
    ],
    "orderHold": [
      3,
      3,
      2,
      0,
      3,
      1,
      0,
      3,
      2,
      2,
      4,
      0,
      1,
      2,
      0,
      1,
      2,
      1,
      4,
      0,
      1,
      1,
      2,
      4,
      0,
      0,
      1,
      2,
      4,
      2,
      1,
      1,
      0,
      4,
      1,
      2,
      1,
      4,
      4,
      3,
      2,
      3,
      3,
      4,
      0,
      1,
      4,
      2,
      0,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      0,
      0,
      2,
      0,
      1,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      4,
      4,
      3,
      3,
      3,
      4,
      1,
      1,
      1,
      1,
      4,
      0,
      0,
      3,
      2,
      1,
      0,
      0,
      0,
      4,
      4,
      0,
      1,
      1,
      1,
      4,
      4,
      2,
      3,
      0,
      1,
      0,
      4,
      2,
      2,
      1,
      0,
      0,
      0,
      3,
      1,
      4,
      4,
      4,
      4,
      4,
      3,
      4,
      1,
      3
    ],
    "issueDelay": [
      0,
      0,
      1,
      1,
      1,
      4,
      4,
      0,
      1,
      1,
      0,
      3,
      3,
      4,
      4,
      2,
      4,
      1,
      1,
      2,
      2,
      2,
      2,
      4,
      1,
      2,
      4,
      0,
      3,
      4,
      2,
      3,
      4,
      0,
      4,
      0,
      0,
      3,
      4,
      2,
      0,
      3,
      4,
      2,
      1,
      3,
      0,
      2,
      1,
      0,
      0,
      4
    ],
    "issueQuantity": [
      1,
      4,
      2,
      2,
      3,
      4,
      1,
      0,
      3,
      1,
      3,
      0,
      0,
      1,
      4,
      4,
      0,
      2,
      3,
      3,
      3,
      1,
      3,
      4,
      1,
      4,
      3,
      0,
      4,
      4,
      2,
      0,
      4,
      4,
      0,
      2,
      1,
      1,
      2,
      4,
      4,
      3,
      3,
      2,
      1,
      0,
      1,
      2,
      0,
      3,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000001",
    "status": "active",
    "name": "HealthAxis Supplies",
    "tag": "Facilities Management, Imaging",
    "contactName": "Stephanie Anthony",
    "industryRating": 2.8,
    "procedures": "[PR-052,PR-067,PR-047,PR-031,PR-069,PR-013,PR-049,PR-066,PR-064,PR-073]",
    "contracts": "[CNTRCT-0000011,CNTRCT-0000036,CNTRCT-0000049]",
    "orders": "[ORDR-0-0000036,ORDR-1-0000059,ORDR-2-0000056,ORDR-3-0000044,ORDR-4-0000016,ORDR-5-0000019,ORDR-6-0000010,ORDR-7-0000032,ORDR-8-0000030,ORDR-9-0000020,ORDR-10-0000032,ORDR-11-0000055,ORDR-12-0000046,ORDR-13-0000044,ORDR-14-0000060,ORDR-15-0000052,ORDR-16-0000037,ORDR-17-0000064,ORDR-18-0000051]",
    "spendWeekly": [
      3004,
      3065,
      2926,
      2938,
      3045,
      3008,
      3100,
      2997,
      2925,
      2921,
      3100,
      3026,
      3053,
      3046,
      3073,
      2972,
      2997,
      3008,
      3099,
      3002,
      3100,
      3073,
      2909,
      2941,
      3095,
      3067,
      3056,
      3071,
      2901,
      2964,
      3060,
      2937,
      3006,
      2959,
      2999,
      3019,
      3090,
      2948,
      3023,
      3026,
      3001,
      2946,
      3076,
      3091,
      2902,
      2994,
      2901,
      2975,
      2994,
      2905,
      2923,
      3097
    ],
    "orderRejected": [
      3097,
      16,
      4,
      4,
      13,
      0,
      14,
      19,
      7,
      10,
      6,
      17,
      15,
      7,
      15,
      17,
      7,
      0,
      1,
      17,
      7,
      18,
      9,
      19,
      16,
      18,
      19,
      5,
      9,
      6,
      15,
      11,
      18,
      19,
      10,
      0,
      8,
      7,
      18,
      2,
      16,
      8,
      14,
      0,
      16,
      0,
      1,
      8,
      19,
      13,
      9,
      19,
      12
    ],
    "orderAccepted": [
      6,
      5,
      0,
      0,
      5,
      11,
      7,
      9,
      4,
      1,
      5,
      9,
      5,
      9,
      11,
      11,
      0,
      8,
      3,
      11,
      4,
      0,
      0,
      7,
      4,
      8,
      0,
      11,
      5,
      9,
      8,
      2,
      0,
      10,
      5,
      3,
      9,
      0,
      7,
      4,
      7,
      4,
      3,
      2,
      10,
      5,
      6,
      9,
      0,
      10,
      3,
      2
    ],
    "orderHold": [
      0,
      0,
      2,
      3,
      2,
      1,
      4,
      2,
      1,
      0,
      2,
      0,
      2,
      4,
      0,
      0,
      4,
      4,
      1,
      1,
      2,
      4,
      2,
      1,
      1,
      4,
      1,
      2,
      2,
      0,
      0,
      4,
      2,
      3,
      0,
      2,
      2,
      0,
      4,
      0,
      0,
      0,
      1,
      3,
      0,
      4,
      2,
      3,
      2,
      2,
      4,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      0,
      1,
      0,
      0,
      2,
      0,
      0,
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      1,
      0,
      0,
      2,
      0,
      0,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      4,
      4,
      2,
      3,
      4,
      3,
      1,
      3,
      2,
      3,
      3,
      4,
      0,
      2,
      1,
      1,
      0,
      3,
      3,
      3,
      4,
      0,
      0,
      4,
      0,
      1,
      2,
      0,
      4,
      2,
      4,
      4,
      2,
      0,
      1,
      1,
      4,
      0,
      1,
      2,
      0,
      3,
      3,
      1,
      4,
      3,
      3,
      2,
      3,
      0,
      0,
      2
    ],
    "issueDelay": [
      2,
      0,
      4,
      4,
      0,
      3,
      0,
      2,
      1,
      2,
      3,
      1,
      4,
      3,
      0,
      2,
      1,
      0,
      0,
      4,
      4,
      0,
      3,
      0,
      0,
      2,
      2,
      2,
      4,
      0,
      4,
      4,
      0,
      1,
      4,
      1,
      3,
      3,
      4,
      0,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      0,
      3,
      2,
      1,
      2
    ],
    "issueQuantity": [
      4,
      2,
      1,
      3,
      4,
      2,
      3,
      3,
      2,
      2,
      0,
      4,
      2,
      2,
      2,
      3,
      2,
      1,
      0,
      3,
      2,
      3,
      4,
      4,
      0,
      1,
      4,
      0,
      2,
      4,
      3,
      4,
      2,
      4,
      1,
      4,
      4,
      0,
      1,
      2,
      4,
      2,
      4,
      1,
      3,
      2,
      4,
      1,
      1,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000002",
    "status": "active",
    "name": "CureTech Innovations",
    "tag": "Nutrition",
    "contactName": "Jennifer Carey",
    "industryRating": 2.1,
    "procedures": "[PR-051,PR-056,PR-062,PR-067,PR-058,PR-054,PR-054,PR-066,PR-074,PR-074]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000056,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000062,ORDR-1-0000037,ORDR-2-0000003,ORDR-3-0000024,ORDR-4-0000063,ORDR-5-0000016,ORDR-6-0000015,ORDR-7-0000036,ORDR-8-0000022,ORDR-9-0000022,ORDR-10-0000025,ORDR-11-0000027,ORDR-12-0000036,ORDR-13-0000028,ORDR-14-0000061,ORDR-15-0000070,ORDR-16-0000031,ORDR-17-0000048,ORDR-18-0000042]",
    "spendWeekly": [
      3071,
      3082,
      2906,
      2964,
      2962,
      3004,
      3048,
      3032,
      2969,
      3099,
      3016,
      2952,
      2914,
      2987,
      3091,
      2901,
      2903,
      2949,
      2945,
      3097,
      3068,
      3073,
      2900,
      2985,
      3095,
      3093,
      3012,
      3067,
      3032,
      2992,
      2909,
      3097,
      2955,
      2970,
      2913,
      2924,
      2907,
      3030,
      3020,
      2936,
      3049,
      2900,
      2998,
      3091,
      2970,
      3071,
      2913,
      2906,
      3020,
      2943,
      2976,
      3093
    ],
    "orderRejected": [
      3093,
      18,
      19,
      16,
      2,
      19,
      7,
      0,
      0,
      17,
      7,
      19,
      11,
      18,
      13,
      0,
      17,
      1,
      8,
      10,
      1,
      9,
      0,
      19,
      3,
      14,
      15,
      0,
      19,
      14,
      19,
      16,
      7,
      19,
      0,
      9,
      0,
      16,
      10,
      12,
      9,
      19,
      7,
      0,
      19,
      1,
      5,
      15,
      19,
      15,
      1,
      12,
      18
    ],
    "orderAccepted": [
      6,
      1,
      0,
      1,
      11,
      3,
      7,
      0,
      0,
      0,
      11,
      9,
      1,
      6,
      9,
      10,
      4,
      4,
      11,
      4,
      5,
      8,
      7,
      0,
      4,
      11,
      8,
      10,
      9,
      9,
      9,
      10,
      0,
      3,
      0,
      7,
      10,
      4,
      7,
      3,
      2,
      7,
      8,
      1,
      4,
      6,
      6,
      9,
      10,
      4,
      5,
      6
    ],
    "orderHold": [
      2,
      0,
      4,
      1,
      3,
      3,
      4,
      2,
      2,
      0,
      3,
      0,
      0,
      3,
      0,
      2,
      3,
      1,
      1,
      3,
      0,
      3,
      0,
      4,
      2,
      0,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      0,
      3,
      4,
      3,
      1,
      0,
      2,
      2,
      4,
      3,
      4,
      4,
      2,
      0,
      1,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1
    ],
    "issueQuality": [
      1,
      0,
      3,
      4,
      4,
      4,
      3,
      3,
      0,
      4,
      2,
      1,
      4,
      4,
      0,
      1,
      3,
      4,
      1,
      2,
      0,
      4,
      3,
      3,
      1,
      4,
      4,
      1,
      3,
      4,
      2,
      2,
      3,
      0,
      0,
      4,
      2,
      3,
      1,
      4,
      0,
      0,
      1,
      3,
      2,
      2,
      2,
      3,
      4,
      3,
      1,
      4
    ],
    "issueDelay": [
      0,
      3,
      4,
      4,
      2,
      2,
      0,
      4,
      0,
      2,
      1,
      2,
      2,
      1,
      4,
      4,
      3,
      0,
      3,
      1,
      2,
      2,
      2,
      0,
      0,
      0,
      3,
      1,
      2,
      4,
      1,
      1,
      2,
      0,
      4,
      0,
      0,
      4,
      1,
      4,
      2,
      1,
      4,
      1,
      3,
      0,
      0,
      0,
      2,
      3,
      3,
      2
    ],
    "issueQuantity": [
      2,
      4,
      4,
      0,
      1,
      0,
      1,
      2,
      2,
      1,
      3,
      1,
      0,
      4,
      3,
      2,
      3,
      4,
      0,
      4,
      2,
      1,
      1,
      4,
      0,
      0,
      0,
      1,
      4,
      1,
      4,
      2,
      2,
      2,
      2,
      4,
      4,
      0,
      3,
      4,
      4,
      4,
      3,
      0,
      4,
      2,
      4,
      2,
      0,
      0,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000003",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Equipment",
    "contactName": "Jennifer Harris",
    "industryRating": 2.7,
    "procedures": "[PR-017,PR-065,PR-054,PR-060,PR-070,PR-065,PR-064,PR-073,PR-073,PR-073]",
    "contracts": "[CNTRCT-0000011,CNTRCT-0000039,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000060,ORDR-1-0000055,ORDR-2-0000015,ORDR-3-0000060,ORDR-4-0000020,ORDR-5-0000054,ORDR-6-0000065,ORDR-7-0000042,ORDR-8-0000065,ORDR-9-0000036,ORDR-10-0000049,ORDR-11-0000033,ORDR-12-0000026,ORDR-13-0000077,ORDR-14-0000036,ORDR-15-0000014,ORDR-16-0000037,ORDR-17-0000042,ORDR-18-0000028]",
    "spendWeekly": [
      2936,
      3082,
      2922,
      2991,
      3010,
      3055,
      3029,
      3021,
      2984,
      2996,
      3100,
      2954,
      3002,
      2949,
      2985,
      2901,
      3016,
      3060,
      2939,
      3081,
      3013,
      3049,
      2900,
      2967,
      2958,
      3063,
      3062,
      3035,
      2923,
      2936,
      2903,
      2992,
      3090,
      3071,
      3095,
      2978,
      2907,
      3044,
      2964,
      2909,
      2976,
      2943,
      3041,
      3066,
      2902,
      2901,
      3058,
      3012,
      3081,
      3006,
      3095,
      3047
    ],
    "orderRejected": [
      3047,
      19,
      19,
      0,
      12,
      12,
      9,
      4,
      1,
      19,
      9,
      13,
      7,
      18,
      16,
      17,
      15,
      6,
      7,
      13,
      6,
      8,
      3,
      19,
      19,
      3,
      14,
      8,
      10,
      19,
      12,
      17,
      19,
      6,
      17,
      1,
      19,
      5,
      19,
      0,
      12,
      16,
      9,
      12,
      7,
      18,
      0,
      16,
      10,
      19,
      6,
      0,
      18
    ],
    "orderAccepted": [
      0,
      11,
      1,
      0,
      0,
      11,
      11,
      5,
      3,
      6,
      2,
      1,
      4,
      8,
      11,
      5,
      0,
      10,
      6,
      2,
      7,
      0,
      11,
      5,
      3,
      5,
      1,
      11,
      0,
      4,
      7,
      7,
      2,
      1,
      0,
      6,
      11,
      1,
      3,
      0,
      0,
      7,
      7,
      5,
      11,
      11,
      3,
      11,
      2,
      11,
      4,
      11
    ],
    "orderHold": [
      1,
      2,
      2,
      2,
      4,
      4,
      0,
      2,
      0,
      2,
      2,
      0,
      3,
      0,
      1,
      0,
      3,
      0,
      4,
      0,
      2,
      3,
      3,
      0,
      0,
      2,
      2,
      2,
      3,
      1,
      1,
      4,
      2,
      2,
      1,
      4,
      4,
      2,
      2,
      4,
      3,
      0,
      4,
      3,
      4,
      1,
      2,
      1,
      0,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      1,
      0,
      0,
      2,
      2,
      2,
      2,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      1,
      0,
      0,
      4,
      2,
      4,
      0,
      3,
      0,
      1,
      2,
      0,
      4,
      0,
      2,
      1,
      4,
      2,
      3,
      4,
      2,
      3,
      1,
      2,
      0,
      3,
      3,
      0,
      4,
      4,
      1,
      3,
      1,
      0,
      1,
      0,
      2,
      0,
      0,
      4,
      0,
      1,
      4,
      4,
      2,
      2,
      4,
      0,
      2,
      3,
      0,
      4
    ],
    "issueDelay": [
      2,
      0,
      1,
      4,
      2,
      1,
      4,
      2,
      2,
      0,
      0,
      4,
      4,
      4,
      0,
      3,
      0,
      1,
      0,
      3,
      2,
      3,
      0,
      3,
      0,
      1,
      0,
      2,
      3,
      4,
      4,
      2,
      0,
      0,
      1,
      0,
      3,
      4,
      3,
      3,
      4,
      0,
      0,
      1,
      2,
      0,
      0,
      0,
      4,
      0,
      0,
      4
    ],
    "issueQuantity": [
      4,
      4,
      2,
      0,
      3,
      0,
      2,
      1,
      3,
      1,
      2,
      2,
      0,
      0,
      3,
      2,
      0,
      3,
      0,
      4,
      0,
      1,
      2,
      3,
      1,
      2,
      0,
      2,
      2,
      2,
      4,
      3,
      4,
      1,
      0,
      4,
      4,
      0,
      0,
      2,
      4,
      1,
      4,
      0,
      2,
      3,
      4,
      4,
      2,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000004",
    "status": "active",
    "name": "VitalityGear Solutions",
    "tag": "Medical Supplies, Facilities Management, Imaging",
    "contactName": "Luis Mccarthy",
    "industryRating": 2.4,
    "procedures": "[PR-056,PR-066,PR-055,PR-066,PR-022,PR-070,PR-070,PR-066,PR-064,PR-074]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000056,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000051,ORDR-1-0000013,ORDR-2-0000001,ORDR-3-0000027,ORDR-4-0000063,ORDR-5-0000066,ORDR-6-0000051,ORDR-7-0000027,ORDR-8-0000020,ORDR-9-0000020,ORDR-10-0000017,ORDR-11-0000075,ORDR-12-0000037,ORDR-13-0000050,ORDR-14-0000013,ORDR-15-0000036,ORDR-16-0000062,ORDR-17-0000044,ORDR-18-0000033]",
    "spendWeekly": [
      3071,
      3082,
      2906,
      2939,
      3001,
      2905,
      3086,
      3070,
      2956,
      2920,
      3074,
      3004,
      2928,
      3065,
      3003,
      3019,
      3022,
      3099,
      2900,
      3044,
      3092,
      2992,
      2903,
      3097,
      3042,
      3096,
      2971,
      2997,
      2995,
      2902,
      2917,
      3097,
      2950,
      3010,
      3095,
      2904,
      3009,
      3000,
      3001,
      2901,
      2965,
      3000,
      3064,
      3091,
      2902,
      2997,
      3070,
      2946,
      3097,
      2937,
      3070,
      3006
    ],
    "orderRejected": [
      3006,
      19,
      4,
      15,
      8,
      2,
      4,
      9,
      19,
      17,
      19,
      19,
      9,
      17,
      17,
      12,
      19,
      0,
      4,
      6,
      2,
      17,
      16,
      16,
      18,
      10,
      8,
      9,
      8,
      19,
      11,
      19,
      4,
      17,
      19,
      19,
      3,
      13,
      14,
      0,
      0,
      8,
      19,
      0,
      8,
      4,
      8,
      18,
      19,
      17,
      16,
      1,
      17
    ],
    "orderAccepted": [
      7,
      9,
      0,
      10,
      2,
      1,
      0,
      5,
      0,
      0,
      6,
      4,
      2,
      5,
      11,
      10,
      6,
      0,
      5,
      1,
      4,
      3,
      4,
      11,
      4,
      4,
      0,
      11,
      4,
      6,
      4,
      2,
      0,
      7,
      2,
      11,
      0,
      9,
      11,
      4,
      0,
      6,
      10,
      11,
      10,
      8,
      0,
      11,
      3,
      3,
      1,
      11
    ],
    "orderHold": [
      1,
      0,
      3,
      2,
      3,
      2,
      4,
      4,
      1,
      1,
      4,
      0,
      0,
      4,
      1,
      1,
      0,
      2,
      2,
      0,
      3,
      0,
      0,
      2,
      2,
      4,
      0,
      2,
      4,
      2,
      4,
      0,
      2,
      2,
      0,
      2,
      0,
      0,
      4,
      2,
      0,
      1,
      1,
      0,
      3,
      3,
      1,
      4,
      2,
      2,
      2,
      0
    ],
    "orderBackordered": [
      0,
      1,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      0,
      0,
      2,
      3,
      2,
      4,
      4,
      0,
      0,
      2,
      2,
      1,
      2,
      3,
      2,
      0,
      0,
      2,
      0,
      4,
      1,
      4,
      3,
      0,
      2,
      4,
      3,
      4,
      0,
      1,
      3,
      4,
      3,
      0,
      0,
      3,
      0,
      3,
      4,
      4,
      3,
      4,
      0,
      0,
      2,
      4,
      4,
      0
    ],
    "issueDelay": [
      3,
      4,
      3,
      0,
      1,
      2,
      4,
      1,
      0,
      4,
      3,
      1,
      4,
      0,
      1,
      2,
      2,
      0,
      2,
      4,
      3,
      2,
      0,
      4,
      0,
      0,
      2,
      0,
      4,
      2,
      2,
      3,
      0,
      1,
      2,
      1,
      2,
      3,
      4,
      4,
      0,
      0,
      3,
      1,
      1,
      0,
      4,
      2,
      4,
      1,
      1,
      4
    ],
    "issueQuantity": [
      0,
      2,
      1,
      0,
      0,
      0,
      0,
      2,
      0,
      3,
      3,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      3,
      4,
      2,
      4,
      0,
      0,
      0,
      4,
      2,
      3,
      2,
      1,
      1,
      3,
      4,
      3,
      2,
      4,
      4,
      0,
      2,
      4,
      4,
      4,
      1,
      2,
      3,
      3,
      1,
      0,
      0,
      4,
      1,
      2
    ]
  },
  {
    "id": "VNDR-0000005",
    "status": "active",
    "name": "MedLogistics Partners",
    "tag": "Laboratory Supplies, Surgical Instruments",
    "contactName": "Helen Ponce",
    "industryRating": 3.1,
    "procedures": "[PR-053,PR-063,PR-055,PR-009,PR-056,PR-044,PR-058,PR-061,PR-070,PR-074]",
    "contracts": "[CNTRCT-0000033,CNTRCT-0000066,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000044,ORDR-1-0000048,ORDR-2-0000036,ORDR-3-0000061,ORDR-4-0000053,ORDR-5-0000026,ORDR-6-0000014,ORDR-7-0000038,ORDR-8-0000064,ORDR-9-0000068,ORDR-10-0000071,ORDR-11-0000039,ORDR-12-0000047,ORDR-13-0000025,ORDR-14-0000056,ORDR-15-0000052,ORDR-16-0000051,ORDR-17-0000023,ORDR-18-0000023]",
    "spendWeekly": [
      2957,
      2902,
      2906,
      2928,
      2903,
      2985,
      3065,
      3020,
      3011,
      3026,
      3038,
      3095,
      3028,
      3078,
      3091,
      3003,
      2994,
      3061,
      3000,
      3068,
      3100,
      2945,
      3097,
      3005,
      2997,
      2971,
      2960,
      2901,
      2913,
      2911,
      2978,
      2999,
      3033,
      3022,
      3032,
      3035,
      3017,
      3062,
      3079,
      3001,
      2901,
      3002,
      2902,
      3091,
      2902,
      2956,
      3018,
      2977,
      3061,
      2905,
      3055,
      2918
    ],
    "orderRejected": [
      2918,
      5,
      0,
      7,
      0,
      16,
      8,
      11,
      14,
      14,
      0,
      12,
      19,
      9,
      19,
      13,
      17,
      8,
      3,
      19,
      19,
      19,
      18,
      14,
      11,
      8,
      5,
      11,
      14,
      10,
      10,
      6,
      9,
      19,
      8,
      19,
      0,
      14,
      18,
      3,
      0,
      14,
      16,
      7,
      15,
      3,
      0,
      0,
      19,
      9,
      15,
      3,
      19
    ],
    "orderAccepted": [
      4,
      9,
      9,
      10,
      7,
      11,
      3,
      3,
      5,
      5,
      8,
      0,
      5,
      8,
      11,
      5,
      0,
      10,
      3,
      2,
      7,
      7,
      3,
      3,
      11,
      7,
      0,
      5,
      11,
      8,
      7,
      9,
      0,
      0,
      4,
      11,
      10,
      1,
      8,
      5,
      11,
      10,
      11,
      2,
      6,
      10,
      5,
      8,
      7,
      11,
      0,
      0
    ],
    "orderHold": [
      4,
      2,
      3,
      2,
      0,
      0,
      4,
      4,
      3,
      0,
      3,
      2,
      1,
      2,
      1,
      3,
      3,
      0,
      4,
      1,
      2,
      1,
      1,
      3,
      0,
      2,
      3,
      0,
      4,
      1,
      2,
      3,
      2,
      2,
      0,
      3,
      2,
      3,
      2,
      4,
      3,
      1,
      4,
      2,
      0,
      2,
      0,
      1,
      0,
      4,
      4,
      1
    ],
    "orderBackordered": [
      1,
      1,
      1,
      2,
      2,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      2,
      0,
      2,
      2,
      0,
      0,
      1,
      0,
      0,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      2,
      2,
      0,
      2,
      0,
      2,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      3,
      2,
      4,
      2,
      0,
      0,
      3,
      3,
      4,
      1,
      4,
      4,
      0,
      0,
      0,
      1,
      3,
      0,
      4,
      1,
      0,
      4,
      3,
      0,
      2,
      1,
      2,
      4,
      0,
      2,
      4,
      4,
      0,
      2,
      1,
      3,
      4,
      3,
      1,
      0,
      0,
      3,
      4,
      4,
      2,
      1,
      2,
      1,
      1,
      3,
      0
    ],
    "issueDelay": [
      4,
      4,
      4,
      3,
      0,
      0,
      0,
      4,
      0,
      2,
      2,
      0,
      4,
      1,
      3,
      2,
      4,
      1,
      4,
      3,
      3,
      2,
      3,
      0,
      1,
      2,
      4,
      4,
      1,
      0,
      4,
      4,
      4,
      0,
      1,
      3,
      4,
      4,
      2,
      0,
      2,
      4,
      1,
      2,
      4,
      2,
      0,
      1,
      4,
      1,
      0,
      4
    ],
    "issueQuantity": [
      4,
      2,
      3,
      1,
      1,
      3,
      4,
      3,
      4,
      1,
      3,
      1,
      0,
      2,
      3,
      0,
      3,
      3,
      3,
      4,
      3,
      1,
      3,
      0,
      2,
      4,
      3,
      1,
      0,
      2,
      2,
      2,
      3,
      1,
      2,
      3,
      3,
      0,
      1,
      3,
      0,
      4,
      2,
      0,
      3,
      2,
      3,
      0,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000006",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "Imaging, PPE, IT Services",
    "contactName": "Jake Goodwin",
    "industryRating": 2.9,
    "procedures": "[PR-045,PR-067,PR-066,PR-032,PR-042,PR-058,PR-070,PR-027,PR-073,PR-059]",
    "contracts": "[CNTRCT-0000065,CNTRCT-0000052,CNTRCT-0000051]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000027,ORDR-2-0000016,ORDR-3-0000029,ORDR-4-0000025,ORDR-5-0000017,ORDR-6-0000060,ORDR-7-0000037,ORDR-8-0000022,ORDR-9-0000042,ORDR-10-0000055,ORDR-11-0000012,ORDR-12-0000051,ORDR-13-0000023,ORDR-14-0000033,ORDR-15-0000032,ORDR-16-0000037,ORDR-17-0000035,ORDR-18-0000073]",
    "spendWeekly": [
      2901,
      3007,
      3026,
      3010,
      3020,
      3035,
      2917,
      3076,
      2902,
      3028,
      2973,
      3093,
      2910,
      2962,
      3030,
      2966,
      3006,
      3099,
      2979,
      3098,
      3028,
      2987,
      3027,
      2903,
      2914,
      3096,
      2938,
      3096,
      3059,
      2974,
      2960,
      2960,
      3050,
      2984,
      3064,
      2986,
      3037,
      3041,
      2906,
      3008,
      2995,
      3000,
      3096,
      3085,
      3026,
      2901,
      2977,
      3077,
      2922,
      3023,
      3050,
      3085
    ],
    "orderRejected": [
      3085,
      13,
      8,
      7,
      2,
      19,
      7,
      5,
      12,
      17,
      7,
      6,
      17,
      18,
      16,
      4,
      19,
      16,
      10,
      3,
      2,
      10,
      18,
      19,
      5,
      10,
      4,
      9,
      17,
      10,
      5,
      19,
      16,
      18,
      9,
      19,
      3,
      18,
      5,
      2,
      2,
      16,
      19,
      0,
      0,
      8,
      8,
      13,
      18,
      7,
      5,
      14,
      4
    ],
    "orderAccepted": [
      11,
      8,
      4,
      5,
      9,
      9,
      1,
      4,
      7,
      7,
      8,
      9,
      8,
      1,
      9,
      11,
      3,
      7,
      4,
      6,
      3,
      6,
      7,
      5,
      10,
      9,
      2,
      9,
      1,
      7,
      4,
      6,
      0,
      0,
      1,
      3,
      7,
      7,
      11,
      7,
      3,
      9,
      0,
      11,
      2,
      8,
      11,
      5,
      5,
      5,
      11,
      4
    ],
    "orderHold": [
      2,
      3,
      0,
      2,
      0,
      3,
      4,
      2,
      2,
      3,
      2,
      2,
      3,
      3,
      3,
      3,
      4,
      0,
      4,
      2,
      0,
      3,
      3,
      3,
      1,
      3,
      4,
      2,
      4,
      2,
      2,
      2,
      4,
      1,
      0,
      2,
      4,
      2,
      2,
      3,
      0,
      0,
      0,
      3,
      2,
      2,
      2,
      3,
      0,
      4,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      2,
      2,
      0,
      0,
      2,
      0,
      0,
      1,
      0,
      2,
      2,
      2,
      0,
      2,
      2,
      0,
      2,
      0,
      0,
      0,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      1,
      0,
      0,
      2,
      4,
      0,
      0,
      3,
      4,
      0,
      3,
      1,
      0,
      2,
      0,
      3,
      2,
      0,
      2,
      0,
      2,
      4,
      3,
      4,
      4,
      1,
      4,
      3,
      1,
      2,
      2,
      3,
      4,
      0,
      3,
      3,
      4,
      4,
      1,
      0,
      4,
      4,
      3,
      0,
      2,
      1,
      2,
      4,
      0,
      3
    ],
    "issueDelay": [
      2,
      4,
      0,
      4,
      4,
      4,
      1,
      2,
      2,
      2,
      0,
      2,
      4,
      0,
      1,
      2,
      0,
      2,
      2,
      0,
      1,
      2,
      0,
      2,
      0,
      0,
      1,
      4,
      3,
      3,
      3,
      2,
      4,
      0,
      4,
      3,
      2,
      1,
      1,
      4,
      4,
      0,
      0,
      0,
      4,
      3,
      3,
      1,
      4,
      0,
      3,
      2
    ],
    "issueQuantity": [
      4,
      0,
      3,
      0,
      0,
      2,
      1,
      1,
      3,
      0,
      4,
      1,
      1,
      2,
      4,
      1,
      4,
      0,
      1,
      3,
      4,
      3,
      3,
      2,
      0,
      3,
      2,
      3,
      1,
      1,
      0,
      0,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      4,
      4,
      1,
      0,
      2,
      2,
      2,
      1,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000007",
    "status": "inactive",
    "name": "HealthWave Technologies",
    "tag": "Nutrition, Imaging, Laboratory Supplies",
    "contactName": "Laura Thomas",
    "industryRating": 2.1,
    "procedures": "[PR-016,PR-050,PR-064,PR-031,PR-061,PR-049,PR-049,PR-055,PR-046,PR-040]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000049,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000010,ORDR-1-0000062,ORDR-2-0000050,ORDR-3-0000058,ORDR-4-0000059,ORDR-5-0000046,ORDR-6-0000047,ORDR-7-0000048,ORDR-8-0000064,ORDR-9-0000038,ORDR-10-0000072,ORDR-11-0000032,ORDR-12-0000061,ORDR-13-0000016,ORDR-14-0000024,ORDR-15-0000071,ORDR-16-0000028,ORDR-17-0000073,ORDR-18-0000061]",
    "spendWeekly": [
      3097,
      2937,
      3048,
      3042,
      2903,
      2905,
      2978,
      2938,
      3100,
      2956,
      3100,
      3028,
      2946,
      2921,
      3091,
      2959,
      3057,
      3063,
      3006,
      2984,
      2993,
      3000,
      2961,
      3097,
      3007,
      2980,
      3020,
      3033,
      2906,
      2973,
      2967,
      3067,
      3058,
      2986,
      3036,
      2925,
      3034,
      2941,
      3099,
      3025,
      2901,
      2993,
      3013,
      3032,
      2958,
      3080,
      3099,
      2942,
      3100,
      2960,
      2949,
      3076
    ],
    "orderRejected": [
      3076,
      16,
      10,
      5,
      12,
      0,
      3,
      13,
      19,
      0,
      0,
      9,
      19,
      18,
      11,
      19,
      19,
      0,
      8,
      4,
      3,
      19,
      0,
      19,
      19,
      0,
      0,
      6,
      0,
      10,
      19,
      9,
      0,
      19,
      16,
      16,
      19,
      0,
      7,
      0,
      19,
      19,
      14,
      0,
      19,
      0,
      9,
      11,
      9,
      8,
      14,
      10,
      2
    ],
    "orderAccepted": [
      0,
      11,
      7,
      11,
      7,
      9,
      11,
      4,
      0,
      1,
      3,
      0,
      11,
      5,
      7,
      5,
      8,
      5,
      2,
      1,
      0,
      11,
      11,
      6,
      11,
      3,
      0,
      11,
      9,
      10,
      8,
      7,
      0,
      0,
      11,
      4,
      0,
      7,
      11,
      4,
      6,
      11,
      11,
      0,
      7,
      8,
      6,
      11,
      0,
      6,
      2,
      10
    ],
    "orderHold": [
      2,
      2,
      1,
      4,
      2,
      2,
      1,
      3,
      1,
      2,
      4,
      0,
      0,
      4,
      2,
      1,
      2,
      1,
      3,
      3,
      3,
      3,
      4,
      3,
      3,
      0,
      2,
      1,
      3,
      1,
      0,
      4,
      0,
      2,
      0,
      2,
      3,
      4,
      4,
      3,
      1,
      2,
      2,
      3,
      4,
      3,
      1,
      1,
      0,
      0,
      2,
      1
    ],
    "orderBackordered": [
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      1,
      4,
      2,
      4,
      2,
      3,
      0,
      4,
      0,
      1,
      2,
      0,
      2,
      2,
      2,
      4,
      0,
      0,
      2,
      1,
      0,
      1,
      3,
      1,
      3,
      1,
      4,
      4,
      4,
      0,
      0,
      2,
      1,
      2,
      3,
      3,
      0,
      0,
      3,
      3,
      0,
      2,
      2,
      0,
      4,
      3,
      4,
      4
    ],
    "issueDelay": [
      0,
      2,
      4,
      2,
      1,
      1,
      4,
      0,
      2,
      1,
      1,
      0,
      2,
      3,
      0,
      2,
      4,
      0,
      2,
      2,
      1,
      4,
      4,
      4,
      0,
      2,
      4,
      1,
      2,
      4,
      4,
      1,
      4,
      2,
      4,
      2,
      1,
      4,
      4,
      4,
      1,
      0,
      1,
      0,
      4,
      1,
      0,
      0,
      0,
      0,
      0,
      1
    ],
    "issueQuantity": [
      3,
      0,
      3,
      1,
      4,
      3,
      4,
      4,
      0,
      4,
      3,
      3,
      0,
      1,
      4,
      1,
      2,
      3,
      0,
      3,
      0,
      0,
      3,
      4,
      1,
      0,
      0,
      0,
      4,
      4,
      3,
      4,
      1,
      0,
      1,
      4,
      3,
      1,
      3,
      3,
      1,
      2,
      4,
      1,
      4,
      0,
      0,
      3,
      0,
      4,
      1,
      3
    ]
  },
  {
    "id": "VNDR-0000008",
    "status": "active",
    "name": "Medique Innovations",
    "tag": "Facilities Management, Surgical Instruments, Equipment",
    "contactName": "Douglas Ruiz",
    "industryRating": 3,
    "procedures": "[PR-054,PR-032,PR-059,PR-065,PR-063,PR-050,PR-068,PR-039,PR-056,PR-027]",
    "contracts": "[CNTRCT-0000021,CNTRCT-0000061,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000052,ORDR-1-0000054,ORDR-2-0000034,ORDR-3-0000024,ORDR-4-0000016,ORDR-5-0000056,ORDR-6-0000062,ORDR-7-0000059,ORDR-8-0000017,ORDR-9-0000054,ORDR-10-0000034,ORDR-11-0000029,ORDR-12-0000011,ORDR-13-0000027,ORDR-14-0000025,ORDR-15-0000037,ORDR-16-0000050,ORDR-17-0000039,ORDR-18-0000017]",
    "spendWeekly": [
      2901,
      2963,
      2946,
      2952,
      2984,
      2905,
      2999,
      3098,
      3100,
      3041,
      2945,
      2963,
      2924,
      2901,
      3025,
      3097,
      3052,
      3098,
      2900,
      3098,
      2966,
      3092,
      2929,
      2903,
      3001,
      2951,
      2909,
      2969,
      2958,
      2963,
      2903,
      2901,
      3060,
      2973,
      2978,
      3039,
      3084,
      2907,
      3001,
      3069,
      2988,
      3042,
      3069,
      3091,
      3041,
      3012,
      2901,
      2900,
      2923,
      2905,
      3036,
      2937
    ],
    "orderRejected": [
      2937,
      3,
      8,
      8,
      7,
      12,
      15,
      8,
      0,
      0,
      3,
      13,
      19,
      5,
      14,
      13,
      19,
      7,
      5,
      16,
      19,
      16,
      13,
      16,
      13,
      11,
      1,
      8,
      10,
      19,
      12,
      19,
      11,
      19,
      5,
      14,
      19,
      2,
      19,
      15,
      19,
      19,
      4,
      18,
      1,
      0,
      14,
      0,
      12,
      15,
      7,
      8,
      6
    ],
    "orderAccepted": [
      5,
      0,
      5,
      11,
      5,
      11,
      9,
      0,
      3,
      2,
      10,
      0,
      6,
      11,
      11,
      10,
      0,
      10,
      4,
      3,
      1,
      8,
      9,
      2,
      11,
      10,
      7,
      0,
      10,
      7,
      0,
      4,
      2,
      2,
      11,
      8,
      0,
      6,
      5,
      1,
      9,
      5,
      11,
      5,
      2,
      3,
      11,
      11,
      11,
      4,
      3,
      10
    ],
    "orderHold": [
      2,
      1,
      4,
      4,
      2,
      1,
      4,
      4,
      2,
      0,
      1,
      1,
      0,
      4,
      0,
      4,
      4,
      0,
      3,
      3,
      2,
      4,
      4,
      2,
      4,
      0,
      4,
      2,
      3,
      1,
      3,
      4,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      4,
      0,
      2,
      2,
      4,
      1,
      1,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      0,
      0,
      0,
      2,
      0,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      2,
      0,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1
    ],
    "issueQuality": [
      0,
      0,
      2,
      3,
      0,
      4,
      0,
      4,
      1,
      4,
      2,
      0,
      4,
      2,
      1,
      0,
      4,
      4,
      2,
      2,
      0,
      3,
      4,
      1,
      1,
      1,
      2,
      0,
      2,
      3,
      4,
      4,
      0,
      0,
      0,
      4,
      1,
      0,
      3,
      4,
      0,
      1,
      1,
      4,
      0,
      1,
      4,
      2,
      1,
      3,
      1,
      4
    ],
    "issueDelay": [
      0,
      1,
      4,
      4,
      2,
      4,
      2,
      0,
      3,
      2,
      0,
      3,
      3,
      4,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      3,
      4,
      1,
      0,
      0,
      3,
      2,
      4,
      3,
      4,
      1,
      4,
      1,
      4,
      2,
      0,
      0,
      2,
      2,
      4,
      0,
      4,
      2,
      0,
      1,
      2,
      3,
      2,
      3
    ],
    "issueQuantity": [
      3,
      3,
      3,
      2,
      4,
      0,
      0,
      0,
      3,
      0,
      0,
      2,
      1,
      0,
      4,
      3,
      3,
      4,
      0,
      4,
      2,
      2,
      0,
      2,
      0,
      4,
      4,
      1,
      3,
      1,
      3,
      2,
      3,
      4,
      0,
      3,
      2,
      0,
      1,
      3,
      4,
      1,
      0,
      2,
      4,
      0,
      3,
      1,
      0,
      2,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000009",
    "status": "inactive",
    "name": "VitalSigns Supplies",
    "tag": "Imaging",
    "contactName": "Kristi Reyes",
    "industryRating": 2.8,
    "procedures": "[PR-054,PR-050,PR-059,PR-064,PR-046,PR-047,PR-068,PR-073,PR-070,PR-027]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000050,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000017,ORDR-1-0000007,ORDR-2-0000043,ORDR-3-0000017,ORDR-4-0000046,ORDR-5-0000050,ORDR-6-0000012,ORDR-7-0000068,ORDR-8-0000019,ORDR-9-0000021,ORDR-10-0000031,ORDR-11-0000023,ORDR-12-0000022,ORDR-13-0000025,ORDR-14-0000059,ORDR-15-0000065,ORDR-16-0000037,ORDR-17-0000075,ORDR-18-0000049]",
    "spendWeekly": [
      3020,
      2949,
      2996,
      2902,
      2937,
      2905,
      2905,
      3044,
      3100,
      2958,
      2974,
      2904,
      3051,
      2901,
      3075,
      2901,
      3072,
      3093,
      3099,
      3098,
      2983,
      3097,
      2952,
      2983,
      2917,
      2983,
      3009,
      3026,
      3007,
      2961,
      2903,
      2982,
      2981,
      2965,
      3055,
      3089,
      3100,
      2970,
      2984,
      3061,
      2941,
      2955,
      3050,
      2901,
      3055,
      2911,
      3003,
      3100,
      3074,
      3053,
      2905,
      2944
    ],
    "orderRejected": [
      2944,
      12,
      8,
      12,
      4,
      16,
      8,
      12,
      11,
      16,
      2,
      0,
      12,
      12,
      4,
      12,
      17,
      5,
      12,
      0,
      10,
      3,
      7,
      9,
      11,
      4,
      15,
      5,
      18,
      19,
      4,
      7,
      19,
      19,
      4,
      10,
      8,
      19,
      10,
      10,
      3,
      19,
      9,
      10,
      8,
      16,
      0,
      10,
      0,
      8,
      9,
      8,
      15
    ],
    "orderAccepted": [
      3,
      11,
      10,
      6,
      1,
      5,
      11,
      5,
      5,
      7,
      2,
      6,
      2,
      0,
      1,
      8,
      3,
      8,
      4,
      11,
      4,
      11,
      11,
      1,
      11,
      1,
      0,
      7,
      0,
      4,
      11,
      10,
      0,
      0,
      8,
      11,
      0,
      2,
      9,
      0,
      11,
      11,
      10,
      0,
      5,
      3,
      11,
      5,
      1,
      4,
      2,
      3
    ],
    "orderHold": [
      3,
      3,
      1,
      1,
      2,
      2,
      3,
      0,
      2,
      0,
      1,
      2,
      4,
      1,
      0,
      2,
      2,
      0,
      1,
      3,
      2,
      3,
      1,
      4,
      1,
      1,
      2,
      3,
      4,
      0,
      0,
      4,
      4,
      0,
      0,
      2,
      4,
      4,
      4,
      1,
      4,
      1,
      1,
      2,
      0,
      4,
      1,
      3,
      0,
      0,
      3,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      0,
      2,
      0,
      0,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      0,
      0,
      2,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      1,
      3,
      3,
      0,
      0,
      0,
      4,
      3,
      4,
      1,
      1,
      4,
      1,
      0,
      3,
      1,
      3,
      4,
      3,
      0,
      4,
      1,
      4,
      1,
      0,
      2,
      2,
      4,
      0,
      3,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      4,
      0,
      2,
      0,
      4,
      1,
      3,
      0,
      2,
      4,
      0,
      2,
      4
    ],
    "issueDelay": [
      2,
      0,
      1,
      2,
      0,
      4,
      4,
      0,
      3,
      0,
      0,
      4,
      4,
      2,
      1,
      3,
      4,
      0,
      0,
      3,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      3,
      1,
      2,
      4,
      4,
      4,
      1,
      2,
      1,
      0,
      3,
      2,
      1,
      1,
      4,
      0,
      2,
      3,
      4,
      1,
      3,
      3,
      0,
      0,
      3
    ],
    "issueQuantity": [
      3,
      1,
      3,
      0,
      4,
      4,
      0,
      0,
      2,
      3,
      4,
      1,
      1,
      0,
      4,
      4,
      2,
      1,
      4,
      4,
      3,
      2,
      2,
      0,
      1,
      0,
      1,
      2,
      0,
      2,
      4,
      3,
      4,
      0,
      2,
      1,
      3,
      0,
      0,
      4,
      1,
      0,
      4,
      1,
      1,
      1,
      3,
      3,
      0,
      1,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000010",
    "status": "active",
    "name": "MedPulse Supply Network",
    "tag": "Laboratory Supplies, Equipment",
    "contactName": "Douglas Ruiz",
    "industryRating": 4.2,
    "procedures": "[PR-066,PR-057,PR-064,PR-009,PR-048,PR-061,PR-069,PR-034,PR-072,PR-067]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000060,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000002,ORDR-1-0000043,ORDR-2-0000001,ORDR-3-0000034,ORDR-4-0000003,ORDR-5-0000052,ORDR-6-0000057,ORDR-7-0000021,ORDR-8-0000038,ORDR-9-0000064,ORDR-10-0000056,ORDR-11-0000017,ORDR-12-0000045,ORDR-13-0000054,ORDR-14-0000045,ORDR-15-0000074,ORDR-16-0000066,ORDR-17-0000031,ORDR-18-0000076]",
    "spendWeekly": [
      2919,
      3042,
      2906,
      2934,
      2921,
      2905,
      3021,
      3054,
      3097,
      2902,
      2945,
      2984,
      2997,
      3094,
      3091,
      3025,
      2982,
      2998,
      3026,
      2980,
      3100,
      2919,
      3076,
      3002,
      3045,
      2973,
      2978,
      2967,
      2965,
      2902,
      2903,
      3019,
      3010,
      3039,
      3029,
      2986,
      2942,
      2915,
      2988,
      2901,
      2922,
      3037,
      3082,
      3076,
      2902,
      2901,
      3099,
      2900,
      3100,
      2976,
      2918,
      3097
    ],
    "orderRejected": [
      3097,
      11,
      0,
      10,
      14,
      18,
      0,
      0,
      15,
      8,
      5,
      13,
      11,
      18,
      3,
      19,
      18,
      0,
      10,
      0,
      3,
      17,
      6,
      12,
      19,
      19,
      19,
      6,
      15,
      19,
      14,
      16,
      6,
      14,
      19,
      6,
      6,
      0,
      10,
      4,
      11,
      19,
      4,
      0,
      1,
      0,
      0,
      16,
      14,
      19,
      11,
      17,
      19
    ],
    "orderAccepted": [
      11,
      10,
      1,
      6,
      7,
      7,
      8,
      5,
      8,
      5,
      10,
      8,
      2,
      0,
      11,
      9,
      8,
      10,
      0,
      6,
      5,
      0,
      1,
      6,
      11,
      11,
      0,
      3,
      0,
      2,
      0,
      8,
      0,
      0,
      0,
      11,
      6,
      3,
      11,
      5,
      3,
      3,
      6,
      7,
      2,
      2,
      1,
      11,
      1,
      8,
      0,
      3
    ],
    "orderHold": [
      3,
      1,
      1,
      1,
      4,
      1,
      4,
      2,
      3,
      1,
      4,
      1,
      1,
      1,
      2,
      1,
      0,
      3,
      4,
      1,
      0,
      3,
      0,
      0,
      1,
      4,
      4,
      2,
      1,
      0,
      3,
      2,
      0,
      0,
      1,
      2,
      3,
      0,
      0,
      3,
      2,
      0,
      2,
      2,
      3,
      3,
      0,
      0,
      2,
      4,
      0,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      1,
      4,
      1,
      3,
      0,
      2,
      0,
      3,
      1,
      4,
      3,
      2,
      3,
      3,
      4,
      3,
      1,
      3,
      1,
      0,
      1,
      2,
      0,
      2,
      0,
      2,
      4,
      0,
      3,
      4,
      2,
      2,
      0,
      0,
      0,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      4,
      0,
      2,
      4,
      3,
      0,
      0,
      3
    ],
    "issueDelay": [
      0,
      3,
      4,
      4,
      0,
      2,
      1,
      0,
      1,
      1,
      2,
      3,
      2,
      4,
      1,
      2,
      0,
      2,
      1,
      2,
      3,
      4,
      3,
      3,
      0,
      2,
      0,
      0,
      1,
      3,
      3,
      0,
      3,
      1,
      2,
      1,
      2,
      3,
      1,
      4,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      2,
      4,
      3,
      4,
      3
    ],
    "issueQuantity": [
      1,
      0,
      1,
      0,
      0,
      2,
      4,
      2,
      3,
      0,
      4,
      4,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      4,
      4,
      4,
      0,
      0,
      0,
      4,
      3,
      2,
      0,
      3,
      2,
      4,
      4,
      1,
      3,
      4,
      3,
      1,
      3,
      3,
      1,
      4,
      4,
      1,
      2,
      4,
      4,
      0,
      0,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000011",
    "status": "active",
    "name": "BioVital Supplies",
    "tag": "Surgical Instruments, Pharmaceuticals",
    "contactName": "Albert Flowers",
    "industryRating": 4.6,
    "procedures": "[PR-066,PR-032,PR-030,PR-037,PR-066,PR-070,PR-052,PR-061,PR-071,PR-067]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000050,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000041,ORDR-2-0000062,ORDR-3-0000064,ORDR-4-0000040,ORDR-5-0000039,ORDR-6-0000066,ORDR-7-0000020,ORDR-8-0000061,ORDR-9-0000012,ORDR-10-0000035,ORDR-11-0000023,ORDR-12-0000065,ORDR-13-0000070,ORDR-14-0000042,ORDR-15-0000074,ORDR-16-0000027,ORDR-17-0000028,ORDR-18-0000028]",
    "spendWeekly": [
      3093,
      3035,
      2975,
      3085,
      2903,
      2905,
      3100,
      3069,
      3022,
      2995,
      2940,
      3004,
      2995,
      3045,
      3091,
      2901,
      2990,
      3099,
      2900,
      2982,
      3058,
      2920,
      3057,
      2963,
      3078,
      3042,
      2909,
      2994,
      2956,
      3060,
      3038,
      3057,
      3015,
      3005,
      3095,
      3051,
      3073,
      3036,
      2981,
      3099,
      3012,
      2953,
      3042,
      3091,
      2952,
      2948,
      3089,
      2970,
      3100,
      3068,
      3014,
      2901
    ],
    "orderRejected": [
      2901,
      12,
      19,
      7,
      1,
      5,
      15,
      17,
      16,
      13,
      6,
      16,
      18,
      18,
      11,
      16,
      19,
      19,
      15,
      2,
      12,
      19,
      15,
      14,
      17,
      7,
      14,
      6,
      4,
      10,
      15,
      9,
      0,
      15,
      19,
      6,
      19,
      11,
      1,
      0,
      13,
      19,
      9,
      10,
      6,
      2,
      0,
      12,
      14,
      6,
      19,
      14,
      14
    ],
    "orderAccepted": [
      6,
      9,
      11,
      5,
      3,
      6,
      6,
      4,
      3,
      3,
      0,
      5,
      10,
      9,
      11,
      11,
      1,
      4,
      3,
      0,
      7,
      11,
      4,
      6,
      9,
      10,
      11,
      11,
      9,
      8,
      7,
      11,
      3,
      4,
      10,
      11,
      7,
      1,
      11,
      4,
      0,
      11,
      10,
      1,
      1,
      10,
      4,
      6,
      5,
      0,
      3,
      2
    ],
    "orderHold": [
      2,
      0,
      4,
      4,
      0,
      2,
      2,
      4,
      3,
      4,
      0,
      2,
      3,
      4,
      0,
      3,
      3,
      0,
      3,
      3,
      4,
      4,
      2,
      2,
      1,
      4,
      2,
      1,
      3,
      0,
      1,
      4,
      0,
      0,
      2,
      0,
      2,
      4,
      1,
      2,
      3,
      1,
      3,
      4,
      2,
      4,
      2,
      2,
      0,
      1,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      1,
      0,
      0,
      2,
      2,
      2,
      1,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      0,
      2,
      1,
      4,
      2,
      4,
      0,
      1,
      1,
      2,
      4,
      3,
      4,
      2,
      1,
      2,
      2,
      2,
      3,
      2,
      0,
      1,
      3,
      2,
      1,
      0,
      4,
      1,
      3,
      1,
      2,
      4,
      4,
      0,
      0,
      3,
      2,
      4,
      0,
      1,
      4,
      2,
      4,
      3,
      2,
      0,
      3,
      3,
      2,
      2,
      3,
      4
    ],
    "issueDelay": [
      1,
      0,
      4,
      4,
      0,
      4,
      4,
      2,
      1,
      1,
      0,
      1,
      4,
      4,
      1,
      3,
      2,
      1,
      0,
      2,
      2,
      3,
      0,
      4,
      2,
      1,
      4,
      1,
      0,
      4,
      1,
      2,
      2,
      0,
      0,
      0,
      3,
      4,
      4,
      1,
      2,
      3,
      0,
      3,
      4,
      3,
      0,
      1,
      0,
      2,
      0,
      3
    ],
    "issueQuantity": [
      2,
      0,
      2,
      0,
      1,
      3,
      3,
      0,
      3,
      1,
      1,
      1,
      3,
      3,
      3,
      2,
      1,
      2,
      2,
      2,
      2,
      4,
      3,
      1,
      0,
      1,
      3,
      3,
      2,
      4,
      2,
      1,
      4,
      4,
      0,
      2,
      4,
      2,
      0,
      4,
      2,
      3,
      4,
      1,
      4,
      1,
      2,
      1,
      0,
      3,
      2,
      4
    ]
  },
  {
    "id": "VNDR-0000012",
    "status": "inactive",
    "name": "VitalCare Distribution",
    "tag": "Pharmaceuticals, Laboratory Supplies, Medical Supplies",
    "contactName": "Jennifer Coleman",
    "industryRating": 2.6,
    "procedures": "[PR-059,PR-063,PR-056,PR-052,PR-033,PR-036,PR-045,PR-051,PR-060,PR-071]",
    "contracts": "[CNTRCT-0000035,CNTRCT-0000058,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000029,ORDR-1-0000014,ORDR-2-0000007,ORDR-3-0000033,ORDR-4-0000045,ORDR-5-0000054,ORDR-6-0000013,ORDR-7-0000040,ORDR-8-0000019,ORDR-9-0000066,ORDR-10-0000064,ORDR-11-0000060,ORDR-12-0000028,ORDR-13-0000015,ORDR-14-0000018,ORDR-15-0000043,ORDR-16-0000051,ORDR-17-0000040,ORDR-18-0000077]",
    "spendWeekly": [
      3049,
      2974,
      2906,
      3034,
      3063,
      3097,
      2905,
      2951,
      2912,
      2918,
      2992,
      3074,
      2944,
      2950,
      3091,
      2915,
      2952,
      3082,
      3043,
      3098,
      2951,
      3063,
      3029,
      2994,
      2962,
      3085,
      2962,
      3082,
      2905,
      3020,
      2971,
      3010,
      3016,
      2979,
      3083,
      2933,
      2907,
      2902,
      3000,
      2967,
      3002,
      2987,
      2984,
      2939,
      3058,
      2901,
      2901,
      3023,
      2987,
      3059,
      2942,
      2931
    ],
    "orderRejected": [
      2931,
      10,
      14,
      13,
      5,
      10,
      17,
      19,
      19,
      19,
      0,
      4,
      15,
      7,
      17,
      15,
      16,
      0,
      0,
      0,
      9,
      8,
      5,
      19,
      14,
      15,
      1,
      16,
      8,
      19,
      12,
      13,
      6,
      19,
      19,
      17,
      3,
      15,
      4,
      10,
      15,
      16,
      0,
      3,
      19,
      7,
      4,
      9,
      0,
      15,
      17,
      6,
      13
    ],
    "orderAccepted": [
      7,
      5,
      0,
      5,
      5,
      2,
      8,
      5,
      0,
      3,
      10,
      5,
      2,
      7,
      1,
      4,
      0,
      4,
      3,
      10,
      10,
      10,
      6,
      3,
      10,
      2,
      3,
      9,
      1,
      3,
      6,
      11,
      0,
      4,
      7,
      9,
      4,
      6,
      11,
      7,
      11,
      10,
      7,
      0,
      9,
      11,
      6,
      6,
      6,
      5,
      0,
      3
    ],
    "orderHold": [
      0,
      0,
      3,
      0,
      4,
      2,
      2,
      0,
      0,
      4,
      1,
      1,
      1,
      1,
      0,
      1,
      3,
      1,
      1,
      4,
      3,
      3,
      1,
      1,
      0,
      0,
      1,
      0,
      4,
      0,
      0,
      4,
      4,
      1,
      1,
      3,
      1,
      1,
      3,
      3,
      1,
      2,
      3,
      3,
      1,
      1,
      0,
      1,
      3,
      0,
      2,
      0
    ],
    "orderBackordered": [
      0,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      0,
      0,
      0,
      1,
      1,
      0,
      2,
      0,
      0,
      0,
      1,
      2,
      0,
      0,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      2,
      1,
      0,
      0,
      1,
      3,
      4,
      4,
      3,
      2,
      4,
      2,
      0,
      3,
      3,
      3,
      2,
      2,
      1,
      1,
      4,
      3,
      1,
      0,
      2,
      0,
      2,
      0,
      4,
      0,
      4,
      1,
      1,
      1,
      0,
      0,
      1,
      4,
      1,
      2,
      2,
      3,
      4,
      4,
      0,
      0,
      4,
      4,
      0,
      4
    ],
    "issueDelay": [
      0,
      1,
      1,
      1,
      0,
      3,
      3,
      4,
      0,
      3,
      0,
      1,
      3,
      1,
      4,
      0,
      4,
      1,
      4,
      0,
      4,
      4,
      0,
      0,
      1,
      2,
      4,
      2,
      3,
      1,
      3,
      4,
      4,
      1,
      2,
      0,
      0,
      0,
      3,
      4,
      2,
      4,
      2,
      1,
      4,
      2,
      0,
      3,
      3,
      1,
      0,
      4
    ],
    "issueQuantity": [
      3,
      3,
      2,
      0,
      0,
      1,
      1,
      1,
      4,
      2,
      1,
      2,
      1,
      2,
      3,
      0,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      2,
      2,
      4,
      4,
      4,
      1,
      3,
      0,
      2,
      4,
      4,
      1,
      0,
      0,
      1,
      3,
      3,
      0,
      3,
      0,
      3,
      3,
      4,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000013",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "Equipment, Facilities Management",
    "contactName": "Crystal Ryan",
    "industryRating": 3.8,
    "procedures": "[PR-062,PR-061,PR-039,PR-066,PR-053,PR-057,PR-021,PR-071,PR-045,PR-065]",
    "contracts": "[CNTRCT-0000045,CNTRCT-0000049,CNTRCT-0000033]",
    "orders": "[ORDR-0-0000047,ORDR-1-0000006,ORDR-2-0000036,ORDR-3-0000005,ORDR-4-0000037,ORDR-5-0000018,ORDR-6-0000035,ORDR-7-0000062,ORDR-8-0000050,ORDR-9-0000016,ORDR-10-0000010,ORDR-11-0000010,ORDR-12-0000023,ORDR-13-0000057,ORDR-14-0000046,ORDR-15-0000072,ORDR-16-0000038,ORDR-17-0000037,ORDR-18-0000056]",
    "spendWeekly": [
      2901,
      2963,
      2974,
      2964,
      2903,
      3022,
      2905,
      3028,
      3012,
      2902,
      2999,
      3078,
      2964,
      2901,
      3082,
      2928,
      3083,
      3099,
      2900,
      3074,
      2967,
      3053,
      2900,
      2982,
      3083,
      2911,
      2996,
      2989,
      2987,
      2987,
      2903,
      3097,
      3018,
      2904,
      3069,
      2989,
      2907,
      2948,
      3099,
      2941,
      3010,
      2967,
      3056,
      3069,
      2902,
      2955,
      3063,
      2900,
      3038,
      2964,
      2943,
      3010
    ],
    "orderRejected": [
      3010,
      10,
      0,
      1,
      3,
      19,
      10,
      19,
      19,
      15,
      0,
      9,
      16,
      9,
      11,
      12,
      11,
      0,
      0,
      11,
      17,
      17,
      6,
      11,
      5,
      5,
      0,
      15,
      1,
      19,
      19,
      19,
      13,
      19,
      17,
      18,
      2,
      19,
      8,
      10,
      14,
      19,
      11,
      0,
      19,
      13,
      6,
      12,
      10,
      14,
      0,
      17,
      14
    ],
    "orderAccepted": [
      4,
      3,
      5,
      4,
      6,
      11,
      6,
      0,
      3,
      0,
      4,
      6,
      11,
      8,
      6,
      11,
      6,
      7,
      8,
      6,
      4,
      11,
      5,
      3,
      9,
      7,
      0,
      9,
      11,
      3,
      4,
      9,
      0,
      5,
      3,
      11,
      11,
      0,
      7,
      4,
      0,
      11,
      11,
      0,
      0,
      9,
      0,
      7,
      3,
      2,
      2,
      2
    ],
    "orderHold": [
      1,
      0,
      1,
      4,
      3,
      4,
      4,
      1,
      4,
      1,
      1,
      2,
      1,
      1,
      4,
      0,
      2,
      3,
      4,
      1,
      2,
      4,
      2,
      3,
      2,
      4,
      4,
      0,
      3,
      2,
      0,
      3,
      0,
      0,
      0,
      2,
      2,
      0,
      0,
      4,
      1,
      3,
      1,
      4,
      1,
      2,
      1,
      0,
      2,
      0,
      0,
      1
    ],
    "orderBackordered": [
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      0,
      2,
      2,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      1,
      1,
      4,
      3,
      2,
      3,
      2,
      1,
      0,
      4,
      2,
      0,
      1,
      3,
      3,
      4,
      3,
      0,
      0,
      2,
      2,
      2,
      0,
      2,
      4,
      1,
      1,
      3,
      0,
      1,
      3,
      2,
      1,
      3,
      3,
      4,
      2,
      2,
      3,
      4,
      4,
      2,
      4,
      0,
      3,
      4,
      0,
      1
    ],
    "issueDelay": [
      3,
      4,
      4,
      4,
      0,
      4,
      4,
      1,
      0,
      3,
      2,
      4,
      4,
      1,
      4,
      0,
      1,
      0,
      4,
      0,
      2,
      1,
      0,
      0,
      0,
      2,
      4,
      3,
      0,
      4,
      4,
      2,
      4,
      1,
      4,
      4,
      0,
      2,
      2,
      0,
      0,
      4,
      0,
      1,
      3,
      1,
      0,
      4,
      3,
      0,
      1,
      4
    ],
    "issueQuantity": [
      3,
      0,
      0,
      2,
      0,
      2,
      4,
      0,
      1,
      2,
      4,
      3,
      3,
      2,
      4,
      0,
      2,
      4,
      2,
      2,
      2,
      3,
      2,
      1,
      1,
      3,
      1,
      2,
      4,
      3,
      4,
      4,
      4,
      0,
      1,
      2,
      4,
      0,
      0,
      4,
      3,
      4,
      2,
      2,
      4,
      2,
      4,
      3,
      0,
      0,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000014",
    "status": "inactive",
    "name": "CareBridge Equipment",
    "tag": "Imaging",
    "contactName": "Jennifer Harris",
    "industryRating": 2.1,
    "procedures": "[PR-061,PR-056,PR-061,PR-062,PR-055,PR-054,PR-058,PR-069,PR-035,PR-062]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000044,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000006,ORDR-1-0000020,ORDR-2-0000065,ORDR-3-0000059,ORDR-4-0000028,ORDR-5-0000038,ORDR-6-0000041,ORDR-7-0000017,ORDR-8-0000038,ORDR-9-0000038,ORDR-10-0000025,ORDR-11-0000017,ORDR-12-0000044,ORDR-13-0000045,ORDR-14-0000024,ORDR-15-0000018,ORDR-16-0000054,ORDR-17-0000026,ORDR-18-0000040]",
    "spendWeekly": [
      3060,
      2987,
      2906,
      3042,
      3082,
      3048,
      3100,
      3098,
      2976,
      2902,
      2909,
      3039,
      3086,
      3092,
      2981,
      3097,
      3089,
      3042,
      3085,
      2909,
      3100,
      2970,
      3097,
      3003,
      2974,
      3096,
      3095,
      2949,
      2958,
      2942,
      2965,
      3097,
      3014,
      2904,
      3060,
      2981,
      3044,
      2977,
      2906,
      3068,
      2901,
      2934,
      3034,
      3013,
      2997,
      3085,
      3099,
      2900,
      2994,
      2964,
      2905,
      3014
    ],
    "orderRejected": [
      3014,
      18,
      2,
      16,
      3,
      15,
      0,
      0,
      0,
      11,
      11,
      7,
      15,
      16,
      11,
      6,
      18,
      12,
      0,
      0,
      0,
      19,
      16,
      6,
      12,
      8,
      7,
      0,
      19,
      12,
      16,
      19,
      13,
      18,
      19,
      2,
      11,
      2,
      13,
      0,
      5,
      19,
      4,
      0,
      0,
      14,
      0,
      13,
      14,
      12,
      11,
      5,
      9
    ],
    "orderAccepted": [
      5,
      11,
      5,
      3,
      9,
      1,
      0,
      11,
      8,
      4,
      9,
      11,
      8,
      11,
      10,
      5,
      4,
      8,
      3,
      5,
      5,
      4,
      7,
      3,
      11,
      11,
      0,
      4,
      4,
      6,
      11,
      7,
      0,
      2,
      2,
      11,
      6,
      11,
      11,
      4,
      0,
      0,
      3,
      4,
      0,
      11,
      4,
      2,
      0,
      3,
      3,
      8
    ],
    "orderHold": [
      3,
      2,
      3,
      4,
      2,
      1,
      2,
      2,
      4,
      4,
      3,
      0,
      1,
      1,
      1,
      4,
      0,
      0,
      4,
      1,
      2,
      4,
      0,
      4,
      2,
      4,
      3,
      1,
      1,
      0,
      2,
      4,
      4,
      0,
      0,
      1,
      0,
      0,
      2,
      4,
      4,
      0,
      3,
      3,
      4,
      3,
      0,
      4,
      1,
      1,
      3,
      1
    ],
    "orderBackordered": [
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      3,
      4,
      1,
      3,
      1,
      0,
      0,
      4,
      4,
      3,
      3,
      1,
      4,
      2,
      0,
      2,
      4,
      2,
      2,
      2,
      2,
      1,
      4,
      1,
      0,
      4,
      0,
      0,
      3,
      2,
      2,
      2,
      4,
      1,
      0,
      2,
      3,
      4,
      3,
      1,
      1,
      0,
      0,
      4,
      0,
      4,
      2,
      1,
      0,
      0,
      0,
      4
    ],
    "issueDelay": [
      0,
      0,
      3,
      4,
      0,
      2,
      1,
      2,
      2,
      1,
      3,
      3,
      4,
      4,
      2,
      3,
      4,
      1,
      2,
      2,
      4,
      2,
      0,
      1,
      0,
      4,
      3,
      1,
      0,
      4,
      0,
      2,
      0,
      1,
      2,
      1,
      1,
      4,
      4,
      3,
      0,
      3,
      1,
      4,
      2,
      1,
      1,
      2,
      4,
      3,
      1,
      0
    ],
    "issueQuantity": [
      3,
      2,
      0,
      1,
      1,
      2,
      4,
      1,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      3,
      0,
      0,
      0,
      4,
      1,
      4,
      3,
      4,
      0,
      2,
      2,
      3,
      0,
      2,
      4,
      1,
      2,
      0,
      0,
      2,
      4,
      1,
      2,
      3,
      2,
      4,
      4,
      1,
      1,
      4,
      4,
      3,
      0,
      4,
      0,
      4
    ]
  },
  {
    "id": "VNDR-0000015",
    "status": "inactive",
    "name": "Medique Innovations",
    "tag": "IT Services, Imaging, Medical Supplies",
    "contactName": "Samantha Patel",
    "industryRating": 2.6,
    "procedures": "[PR-052,PR-022,PR-061,PR-067,PR-022,PR-069,PR-030,PR-072,PR-068,PR-071]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000016,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000025,ORDR-1-0000049,ORDR-2-0000047,ORDR-3-0000009,ORDR-4-0000011,ORDR-5-0000053,ORDR-6-0000033,ORDR-7-0000021,ORDR-8-0000044,ORDR-9-0000022,ORDR-10-0000022,ORDR-11-0000060,ORDR-12-0000072,ORDR-13-0000047,ORDR-14-0000038,ORDR-15-0000077,ORDR-16-0000015,ORDR-17-0000035,ORDR-18-0000029]",
    "spendWeekly": [
      2928,
      3079,
      2946,
      3037,
      2903,
      2929,
      2905,
      3098,
      3018,
      2910,
      3010,
      2906,
      2909,
      2901,
      3017,
      2901,
      3098,
      2901,
      3054,
      3098,
      3015,
      3097,
      2974,
      2971,
      2999,
      3096,
      3017,
      3006,
      2909,
      3019,
      2958,
      3000,
      3073,
      2904,
      2913,
      2914,
      2924,
      3009,
      3032,
      3086,
      3077,
      2900,
      3001,
      3091,
      2945,
      2901,
      3054,
      2991,
      2949,
      2905,
      2926,
      2985
    ],
    "orderRejected": [
      2985,
      13,
      5,
      4,
      4,
      4,
      19,
      12,
      17,
      19,
      19,
      13,
      19,
      17,
      14,
      7,
      11,
      17,
      0,
      19,
      14,
      19,
      8,
      10,
      16,
      15,
      2,
      7,
      11,
      14,
      19,
      9,
      19,
      18,
      12,
      15,
      9,
      12,
      19,
      1,
      10,
      19,
      12,
      1,
      6,
      1,
      2,
      9,
      7,
      3,
      0,
      10,
      19
    ],
    "orderAccepted": [
      3,
      2,
      2,
      3,
      6,
      10,
      11,
      8,
      0,
      3,
      6,
      1,
      8,
      11,
      7,
      6,
      8,
      8,
      0,
      11,
      8,
      9,
      11,
      6,
      4,
      8,
      0,
      11,
      6,
      9,
      5,
      11,
      0,
      6,
      8,
      11,
      11,
      0,
      11,
      11,
      1,
      0,
      7,
      11,
      6,
      0,
      0,
      11,
      0,
      8,
      4,
      8
    ],
    "orderHold": [
      1,
      0,
      4,
      0,
      2,
      3,
      4,
      4,
      3,
      4,
      1,
      0,
      3,
      2,
      0,
      1,
      2,
      0,
      4,
      2,
      0,
      1,
      1,
      4,
      0,
      4,
      4,
      3,
      0,
      0,
      2,
      3,
      4,
      2,
      1,
      4,
      2,
      2,
      3,
      0,
      0,
      1,
      2,
      3,
      2,
      2,
      0,
      0,
      1,
      1,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      0,
      0,
      1,
      2,
      0,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      0,
      3,
      1,
      1,
      0,
      3,
      0,
      2,
      1,
      2,
      3,
      0,
      2,
      2,
      0,
      4,
      2,
      3,
      2,
      2,
      3,
      3,
      3,
      3,
      0,
      4,
      0,
      3,
      4,
      3,
      3,
      2,
      4,
      2,
      0,
      2,
      3,
      1,
      0,
      4,
      2,
      0,
      1,
      0,
      1,
      4,
      0,
      1,
      1,
      4,
      0,
      4
    ],
    "issueDelay": [
      1,
      3,
      4,
      2,
      0,
      4,
      2,
      0,
      2,
      1,
      0,
      0,
      3,
      4,
      2,
      4,
      0,
      0,
      0,
      4,
      2,
      2,
      2,
      2,
      0,
      2,
      3,
      1,
      2,
      4,
      1,
      3,
      2,
      3,
      4,
      4,
      0,
      4,
      1,
      0,
      0,
      3,
      1,
      2,
      1,
      1,
      4,
      1,
      0,
      2,
      3,
      4
    ],
    "issueQuantity": [
      0,
      4,
      2,
      2,
      2,
      4,
      3,
      1,
      2,
      0,
      4,
      0,
      1,
      4,
      4,
      2,
      3,
      1,
      1,
      4,
      3,
      4,
      2,
      4,
      0,
      3,
      2,
      0,
      4,
      2,
      4,
      2,
      4,
      4,
      1,
      3,
      4,
      0,
      0,
      4,
      4,
      4,
      4,
      2,
      0,
      1,
      4,
      4,
      0,
      4,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000016",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "Nutrition",
    "contactName": "Jeremy Becker",
    "industryRating": 2.1,
    "procedures": "[PR-043,PR-024,PR-034,PR-016,PR-047,PR-036,PR-058,PR-072,PR-071,PR-059]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000042,CNTRCT-0000020]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000044,ORDR-2-0000016,ORDR-3-0000038,ORDR-4-0000023,ORDR-5-0000012,ORDR-6-0000066,ORDR-7-0000052,ORDR-8-0000023,ORDR-9-0000056,ORDR-10-0000046,ORDR-11-0000070,ORDR-12-0000019,ORDR-13-0000053,ORDR-14-0000078,ORDR-15-0000052,ORDR-16-0000051,ORDR-17-0000020,ORDR-18-0000057]",
    "spendWeekly": [
      2949,
      2970,
      3071,
      2902,
      2903,
      2905,
      3002,
      3062,
      2902,
      2902,
      3044,
      2904,
      2982,
      3034,
      2996,
      2901,
      3098,
      3053,
      3081,
      3012,
      2903,
      3085,
      3057,
      2925,
      2906,
      3096,
      3095,
      2901,
      2972,
      2929,
      3014,
      2994,
      3032,
      3033,
      2969,
      2904,
      3055,
      2906,
      2983,
      2951,
      2995,
      2965,
      3073,
      2966,
      3011,
      2901,
      3006,
      3034,
      2950,
      2908,
      2910,
      2944
    ],
    "orderRejected": [
      2944,
      19,
      3,
      7,
      13,
      13,
      0,
      8,
      19,
      6,
      5,
      7,
      8,
      4,
      11,
      7,
      19,
      0,
      1,
      0,
      0,
      7,
      4,
      10,
      10,
      0,
      8,
      0,
      15,
      0,
      19,
      13,
      9,
      19,
      19,
      9,
      14,
      5,
      13,
      5,
      19,
      10,
      18,
      3,
      0,
      1,
      0,
      1,
      0,
      3,
      13,
      13,
      0
    ],
    "orderAccepted": [
      4,
      11,
      9,
      8,
      5,
      11,
      5,
      6,
      0,
      11,
      0,
      2,
      0,
      10,
      8,
      0,
      0,
      5,
      7,
      3,
      5,
      2,
      4,
      9,
      10,
      11,
      0,
      11,
      3,
      11,
      10,
      6,
      0,
      6,
      9,
      6,
      7,
      0,
      1,
      11,
      11,
      11,
      4,
      11,
      6,
      5,
      7,
      3,
      3,
      11,
      3,
      6
    ],
    "orderHold": [
      2,
      0,
      2,
      3,
      2,
      0,
      4,
      0,
      1,
      4,
      2,
      0,
      1,
      2,
      0,
      1,
      2,
      0,
      4,
      3,
      2,
      4,
      4,
      3,
      0,
      4,
      4,
      3,
      4,
      0,
      2,
      4,
      3,
      4,
      0,
      2,
      3,
      2,
      3,
      1,
      3,
      0,
      1,
      4,
      3,
      2,
      0,
      0,
      1,
      1,
      2,
      3
    ],
    "orderBackordered": [
      3,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      0,
      0,
      0,
      2,
      0,
      2,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      2,
      2,
      2,
      2,
      0,
      4,
      0,
      2,
      4,
      2,
      1,
      4,
      3,
      1,
      0,
      3,
      1,
      1,
      1,
      3,
      4,
      2,
      3,
      2,
      0,
      0,
      4,
      4,
      1,
      3,
      0,
      2,
      4,
      0,
      2,
      2,
      0,
      2,
      0,
      1,
      3,
      4,
      0,
      2,
      3,
      2,
      4,
      4,
      2,
      4
    ],
    "issueDelay": [
      0,
      3,
      0,
      4,
      3,
      4,
      4,
      0,
      1,
      1,
      3,
      4,
      3,
      0,
      2,
      2,
      4,
      2,
      2,
      0,
      4,
      2,
      2,
      4,
      4,
      4,
      2,
      1,
      1,
      4,
      4,
      4,
      1,
      3,
      2,
      1,
      1,
      4,
      3,
      1,
      2,
      4,
      0,
      3,
      4,
      0,
      2,
      3,
      0,
      0,
      0,
      1
    ],
    "issueQuantity": [
      3,
      3,
      1,
      0,
      4,
      4,
      0,
      2,
      0,
      3,
      3,
      2,
      2,
      4,
      3,
      4,
      0,
      4,
      2,
      4,
      1,
      0,
      2,
      3,
      0,
      2,
      0,
      3,
      1,
      4,
      4,
      4,
      3,
      1,
      2,
      0,
      4,
      0,
      1,
      4,
      4,
      4,
      2,
      0,
      1,
      1,
      4,
      4,
      3,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000017",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Medical Supplies, Facilities Management, Imaging",
    "contactName": "Justin Molina",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-050,PR-057,PR-069,PR-022,PR-054,PR-065,PR-070,PR-064,PR-073]",
    "contracts": "[CNTRCT-0000060,CNTRCT-0000053,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000004,ORDR-1-0000015,ORDR-2-0000013,ORDR-3-0000011,ORDR-4-0000049,ORDR-5-0000047,ORDR-6-0000024,ORDR-7-0000009,ORDR-8-0000010,ORDR-9-0000053,ORDR-10-0000028,ORDR-11-0000024,ORDR-12-0000021,ORDR-13-0000016,ORDR-14-0000059,ORDR-15-0000063,ORDR-16-0000019,ORDR-17-0000032,ORDR-18-0000027]",
    "spendWeekly": [
      3063,
      3081,
      2968,
      2902,
      2903,
      3097,
      3100,
      3035,
      3075,
      3039,
      3048,
      3027,
      2922,
      2991,
      3074,
      2901,
      2974,
      2968,
      2933,
      2909,
      3031,
      3079,
      2975,
      2945,
      3095,
      3039,
      2957,
      2982,
      2915,
      2902,
      3068,
      3051,
      3091,
      3025,
      2970,
      3003,
      2907,
      3050,
      3049,
      3028,
      3099,
      2991,
      3097,
      3057,
      2902,
      2901,
      3076,
      2957,
      3015,
      3003,
      2972,
      3067
    ],
    "orderRejected": [
      3067,
      14,
      9,
      8,
      16,
      9,
      3,
      19,
      19,
      5,
      0,
      19,
      16,
      16,
      18,
      0,
      18,
      0,
      5,
      7,
      13,
      9,
      4,
      14,
      19,
      7,
      15,
      5,
      4,
      18,
      2,
      0,
      8,
      19,
      10,
      10,
      12,
      5,
      19,
      8,
      15,
      10,
      0,
      0,
      19,
      12,
      15,
      3,
      17,
      8,
      15,
      14,
      19
    ],
    "orderAccepted": [
      0,
      11,
      1,
      7,
      6,
      7,
      7,
      7,
      0,
      2,
      1,
      7,
      11,
      2,
      7,
      0,
      1,
      5,
      2,
      1,
      0,
      6,
      2,
      10,
      6,
      10,
      5,
      9,
      11,
      11,
      11,
      9,
      0,
      7,
      11,
      8,
      1,
      11,
      11,
      1,
      0,
      11,
      11,
      3,
      10,
      11,
      6,
      9,
      11,
      3,
      3,
      1
    ],
    "orderHold": [
      0,
      1,
      1,
      1,
      1,
      0,
      4,
      3,
      0,
      1,
      4,
      0,
      0,
      2,
      0,
      3,
      3,
      0,
      0,
      1,
      3,
      3,
      1,
      2,
      0,
      4,
      1,
      2,
      4,
      3,
      4,
      4,
      2,
      3,
      0,
      2,
      0,
      2,
      4,
      4,
      0,
      1,
      1,
      4,
      1,
      2,
      0,
      3,
      0,
      1,
      4,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      0,
      0,
      2,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      1,
      1,
      3,
      2,
      0,
      2,
      1,
      3,
      3,
      3,
      4,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      4,
      1,
      0,
      3,
      2,
      0,
      3,
      2,
      3,
      1,
      0,
      4,
      3,
      4,
      0,
      0,
      2,
      1,
      1,
      2,
      2,
      0,
      3,
      3,
      2,
      2,
      4,
      0,
      1,
      4,
      2,
      2,
      1
    ],
    "issueDelay": [
      0,
      2,
      3,
      2,
      4,
      3,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      4,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      4,
      3,
      2,
      3,
      3,
      4,
      1,
      3,
      4,
      2,
      0,
      3,
      4,
      0,
      4,
      3,
      3,
      2,
      0,
      4,
      2,
      1,
      4,
      0,
      1,
      3,
      1,
      3,
      3
    ],
    "issueQuantity": [
      0,
      3,
      4,
      2,
      4,
      0,
      0,
      2,
      4,
      0,
      2,
      1,
      3,
      1,
      3,
      2,
      0,
      2,
      3,
      4,
      0,
      1,
      2,
      3,
      4,
      3,
      2,
      0,
      4,
      2,
      2,
      0,
      1,
      3,
      2,
      2,
      4,
      0,
      3,
      4,
      3,
      2,
      4,
      0,
      3,
      2,
      4,
      1,
      0,
      2,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000018",
    "status": "active",
    "name": "HealthHorizon Partners",
    "tag": "Facilities Management, Equipment",
    "contactName": "Laura Thomas",
    "industryRating": 2.1,
    "procedures": "[PR-039,PR-064,PR-045,PR-032,PR-033,PR-057,PR-058,PR-041,PR-056,PR-075]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000042,CNTRCT-0000054]",
    "orders": "[ORDR-0-0000036,ORDR-1-0000015,ORDR-2-0000034,ORDR-3-0000029,ORDR-4-0000039,ORDR-5-0000005,ORDR-6-0000009,ORDR-7-0000057,ORDR-8-0000043,ORDR-9-0000033,ORDR-10-0000046,ORDR-11-0000054,ORDR-12-0000031,ORDR-13-0000012,ORDR-14-0000037,ORDR-15-0000041,ORDR-16-0000069,ORDR-17-0000031,ORDR-18-0000071]",
    "spendWeekly": [
      3043,
      2981,
      2992,
      3076,
      2903,
      2905,
      3100,
      3097,
      2973,
      2995,
      3004,
      2904,
      3005,
      3052,
      3091,
      3096,
      2903,
      3099,
      2984,
      3003,
      3076,
      2997,
      3097,
      3043,
      3025,
      3094,
      2909,
      2901,
      3022,
      2958,
      2989,
      3070,
      3064,
      3065,
      2934,
      3100,
      2973,
      2919,
      2933,
      2994,
      3070,
      3100,
      2992,
      3056,
      2959,
      2901,
      2972,
      2943,
      3045,
      2967,
      2957,
      2977
    ],
    "orderRejected": [
      2977,
      19,
      7,
      13,
      0,
      11,
      9,
      14,
      11,
      1,
      18,
      4,
      7,
      18,
      6,
      15,
      17,
      15,
      3,
      4,
      4,
      18,
      12,
      8,
      1,
      12,
      6,
      11,
      9,
      15,
      9,
      9,
      1,
      10,
      19,
      0,
      12,
      9,
      6,
      2,
      8,
      10,
      13,
      0,
      0,
      4,
      3,
      13,
      19,
      14,
      17,
      19,
      19
    ],
    "orderAccepted": [
      10,
      4,
      4,
      5,
      0,
      8,
      4,
      0,
      1,
      2,
      5,
      0,
      10,
      2,
      0,
      3,
      0,
      7,
      0,
      0,
      0,
      3,
      0,
      5,
      6,
      4,
      0,
      3,
      1,
      7,
      0,
      4,
      0,
      8,
      8,
      6,
      2,
      0,
      11,
      10,
      8,
      11,
      11,
      2,
      8,
      10,
      4,
      6,
      6,
      5,
      0,
      11
    ],
    "orderHold": [
      2,
      4,
      3,
      2,
      3,
      1,
      1,
      4,
      3,
      1,
      1,
      0,
      1,
      2,
      0,
      2,
      0,
      0,
      2,
      0,
      2,
      2,
      0,
      4,
      2,
      3,
      0,
      4,
      4,
      1,
      0,
      3,
      2,
      0,
      0,
      3,
      1,
      3,
      3,
      1,
      1,
      0,
      4,
      0,
      2,
      3,
      2,
      3,
      3,
      2,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      1,
      0,
      3,
      4,
      0,
      4,
      0,
      2,
      2,
      0,
      3,
      0,
      1,
      1,
      1,
      2,
      1,
      4,
      2,
      4,
      0,
      1,
      4,
      2,
      0,
      2,
      3,
      1,
      0,
      4,
      2,
      2,
      1,
      4,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      3,
      1,
      3,
      0,
      1,
      1,
      3,
      3,
      3,
      2,
      3
    ],
    "issueDelay": [
      3,
      3,
      2,
      3,
      2,
      3,
      4,
      0,
      0,
      1,
      3,
      0,
      2,
      3,
      0,
      1,
      3,
      1,
      0,
      4,
      1,
      2,
      0,
      1,
      3,
      3,
      3,
      0,
      2,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      2,
      0,
      0,
      4,
      3,
      2,
      3,
      2,
      4,
      4,
      2,
      4,
      2
    ],
    "issueQuantity": [
      0,
      2,
      2,
      3,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      4,
      0,
      0,
      3,
      1,
      1,
      0,
      0,
      4,
      0,
      4,
      0,
      4,
      4,
      3,
      4,
      4,
      2,
      2,
      3,
      0,
      4,
      1,
      0,
      4,
      4,
      1,
      1,
      1,
      4,
      2,
      3,
      1,
      3,
      1,
      3,
      2,
      3,
      2,
      0,
      4
    ]
  },
  {
    "id": "VNDR-0000019",
    "status": "inactive",
    "name": "BioMedix International",
    "tag": "Imaging, PPE, IT Services",
    "contactName": "Carlos Flores",
    "industryRating": 3.8,
    "procedures": "[PR-038,PR-056,PR-040,PR-034,PR-012,PR-049,PR-055,PR-064,PR-074,PR-048]",
    "contracts": "[CNTRCT-0000010,CNTRCT-0000065,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000022,ORDR-1-0000008,ORDR-2-0000022,ORDR-3-0000019,ORDR-4-0000057,ORDR-5-0000065,ORDR-6-0000066,ORDR-7-0000054,ORDR-8-0000034,ORDR-9-0000041,ORDR-10-0000021,ORDR-11-0000038,ORDR-12-0000037,ORDR-13-0000046,ORDR-14-0000072,ORDR-15-0000052,ORDR-16-0000047,ORDR-17-0000042,ORDR-18-0000063]",
    "spendWeekly": [
      2916,
      3046,
      2907,
      2902,
      2917,
      2969,
      2978,
      2990,
      2989,
      3032,
      2946,
      3058,
      2994,
      2927,
      3037,
      3000,
      2954,
      3087,
      2900,
      3069,
      3005,
      3045,
      2950,
      2952,
      2980,
      3096,
      3017,
      2959,
      3032,
      3031,
      3027,
      3014,
      2987,
      3041,
      3052,
      3086,
      2907,
      3037,
      3022,
      3017,
      2944,
      3053,
      3073,
      2939,
      2945,
      2977,
      2983,
      2979,
      2927,
      3018,
      2940,
      3050
    ],
    "orderRejected": [
      3050,
      0,
      4,
      0,
      12,
      7,
      0,
      5,
      13,
      14,
      9,
      4,
      16,
      5,
      6,
      12,
      15,
      9,
      1,
      8,
      12,
      14,
      2,
      13,
      8,
      7,
      3,
      18,
      14,
      18,
      9,
      6,
      12,
      7,
      10,
      8,
      17,
      8,
      14,
      5,
      12,
      12,
      1,
      5,
      6,
      3,
      13,
      6,
      12,
      0,
      19,
      10,
      19
    ],
    "orderAccepted": [
      3,
      11,
      5,
      4,
      2,
      10,
      11,
      1,
      5,
      10,
      0,
      11,
      4,
      0,
      11,
      6,
      8,
      8,
      0,
      9,
      0,
      6,
      7,
      8,
      8,
      6,
      0,
      9,
      7,
      6,
      11,
      8,
      10,
      0,
      2,
      11,
      11,
      3,
      10,
      8,
      8,
      7,
      2,
      4,
      3,
      9,
      10,
      6,
      10,
      4,
      3,
      2
    ],
    "orderHold": [
      2,
      0,
      3,
      2,
      3,
      3,
      0,
      0,
      2,
      4,
      2,
      1,
      1,
      0,
      2,
      3,
      2,
      0,
      1,
      0,
      1,
      3,
      3,
      1,
      2,
      4,
      1,
      2,
      2,
      1,
      3,
      3,
      3,
      2,
      0,
      2,
      1,
      4,
      1,
      0,
      2,
      1,
      4,
      4,
      4,
      1,
      0,
      1,
      2,
      0,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      0,
      2,
      0,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      2,
      2,
      2,
      0,
      0,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1
    ],
    "issueQuality": [
      3,
      1,
      3,
      3,
      2,
      1,
      1,
      1,
      3,
      0,
      1,
      4,
      3,
      2,
      0,
      1,
      0,
      0,
      2,
      2,
      1,
      0,
      3,
      1,
      3,
      1,
      4,
      2,
      3,
      3,
      4,
      4,
      3,
      3,
      1,
      2,
      2,
      3,
      1,
      4,
      4,
      0,
      1,
      2,
      0,
      0,
      0,
      1,
      4,
      3,
      1,
      3
    ],
    "issueDelay": [
      0,
      1,
      4,
      0,
      2,
      4,
      2,
      0,
      0,
      3,
      1,
      1,
      4,
      0,
      4,
      3,
      2,
      2,
      1,
      3,
      3,
      1,
      0,
      2,
      1,
      4,
      2,
      2,
      0,
      0,
      3,
      3,
      4,
      0,
      1,
      0,
      3,
      1,
      2,
      0,
      4,
      0,
      4,
      2,
      4,
      1,
      2,
      0,
      1,
      0,
      0,
      1
    ],
    "issueQuantity": [
      0,
      4,
      0,
      0,
      1,
      0,
      0,
      3,
      3,
      0,
      2,
      4,
      3,
      1,
      4,
      4,
      0,
      2,
      3,
      4,
      3,
      1,
      0,
      3,
      1,
      4,
      3,
      3,
      2,
      4,
      1,
      4,
      1,
      1,
      1,
      2,
      4,
      0,
      1,
      4,
      3,
      4,
      3,
      1,
      1,
      2,
      1,
      0,
      3,
      3,
      1,
      1
    ]
  },
  {
    "id": "VNDR-0000020",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Medical Supplies",
    "contactName": "Gary Wilson",
    "industryRating": 4.1,
    "procedures": "[PR-055,PR-065,PR-040,PR-066,PR-061,PR-049,PR-010,PR-061,PR-070,PR-025]",
    "contracts": "[CNTRCT-0000064,CNTRCT-0000036,CNTRCT-0000046]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000025,ORDR-2-0000005,ORDR-3-0000057,ORDR-4-0000019,ORDR-5-0000051,ORDR-6-0000063,ORDR-7-0000006,ORDR-8-0000020,ORDR-9-0000056,ORDR-10-0000066,ORDR-11-0000058,ORDR-12-0000073,ORDR-13-0000070,ORDR-14-0000043,ORDR-15-0000014,ORDR-16-0000057,ORDR-17-0000030,ORDR-18-0000080]",
    "spendWeekly": [
      2978,
      3075,
      2967,
      3029,
      2936,
      2905,
      3028,
      3016,
      2976,
      3025,
      2901,
      3037,
      2915,
      3094,
      3091,
      2980,
      2992,
      2900,
      2900,
      3098,
      2942,
      2967,
      2940,
      3070,
      3043,
      3007,
      2909,
      2901,
      2901,
      2909,
      2983,
      2998,
      3098,
      3077,
      3075,
      2923,
      3009,
      3090,
      3013,
      3041,
      2954,
      3045,
      3097,
      3009,
      3076,
      3040,
      3024,
      2932,
      3016,
      3002,
      2988,
      3034
    ],
    "orderRejected": [
      3034,
      11,
      3,
      7,
      17,
      16,
      19,
      7,
      0,
      10,
      11,
      7,
      1,
      4,
      17,
      19,
      16,
      0,
      8,
      0,
      7,
      16,
      18,
      19,
      10,
      8,
      4,
      0,
      19,
      1,
      16,
      15,
      0,
      10,
      12,
      17,
      9,
      6,
      16,
      13,
      6,
      7,
      0,
      0,
      8,
      10,
      9,
      2,
      0,
      19,
      11,
      3,
      14
    ],
    "orderAccepted": [
      9,
      0,
      9,
      11,
      2,
      9,
      6,
      6,
      6,
      4,
      2,
      4,
      11,
      11,
      11,
      11,
      0,
      10,
      8,
      3,
      6,
      0,
      11,
      0,
      1,
      3,
      9,
      11,
      6,
      0,
      0,
      2,
      0,
      5,
      2,
      4,
      9,
      0,
      10,
      6,
      0,
      10,
      3,
      8,
      11,
      0,
      8,
      11,
      3,
      3,
      10,
      3
    ],
    "orderHold": [
      0,
      1,
      0,
      0,
      3,
      4,
      4,
      2,
      3,
      4,
      3,
      1,
      1,
      4,
      2,
      4,
      4,
      1,
      4,
      1,
      3,
      2,
      2,
      4,
      2,
      4,
      2,
      2,
      2,
      0,
      2,
      4,
      2,
      3,
      0,
      2,
      3,
      0,
      3,
      2,
      2,
      3,
      3,
      0,
      1,
      1,
      4,
      2,
      3,
      1,
      1,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      0,
      2,
      0,
      1,
      0,
      2,
      0,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      3,
      4,
      0,
      4,
      0,
      3,
      4,
      4,
      3,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      3,
      4,
      0,
      2,
      0,
      0,
      2,
      2,
      2,
      3,
      4,
      1,
      4,
      3,
      0,
      4,
      3,
      2,
      1,
      0,
      3,
      0,
      0,
      4,
      3,
      3,
      0,
      3,
      4,
      0,
      0,
      0,
      4
    ],
    "issueDelay": [
      0,
      2,
      2,
      4,
      0,
      4,
      4,
      3,
      2,
      3,
      4,
      4,
      2,
      4,
      0,
      2,
      0,
      0,
      2,
      4,
      0,
      1,
      2,
      3,
      0,
      2,
      0,
      1,
      2,
      2,
      3,
      0,
      4,
      4,
      4,
      4,
      3,
      4,
      2,
      1,
      0,
      2,
      0,
      0,
      0,
      4,
      3,
      3,
      4,
      1,
      0,
      4
    ],
    "issueQuantity": [
      4,
      0,
      3,
      1,
      3,
      0,
      4,
      4,
      2,
      0,
      3,
      1,
      1,
      0,
      0,
      2,
      2,
      0,
      4,
      4,
      2,
      1,
      2,
      4,
      3,
      3,
      4,
      1,
      4,
      2,
      1,
      3,
      4,
      4,
      0,
      2,
      4,
      1,
      2,
      4,
      3,
      3,
      4,
      2,
      2,
      0,
      2,
      2,
      0,
      4,
      3,
      0
    ]
  },
  {
    "id": "VNDR-0000021",
    "status": "inactive",
    "name": "VitalityGear Solutions",
    "tag": "PPE",
    "contactName": "Phillip Contreras",
    "industryRating": 2.5,
    "procedures": "[PR-053,PR-035,PR-027,PR-065,PR-056,PR-057,PR-021,PR-064,PR-034,PR-037]",
    "contracts": "[CNTRCT-0000048,CNTRCT-0000059,CNTRCT-0000020]",
    "orders": "[ORDR-0-0000029,ORDR-1-0000059,ORDR-2-0000045,ORDR-3-0000001,ORDR-4-0000009,ORDR-5-0000065,ORDR-6-0000048,ORDR-7-0000017,ORDR-8-0000027,ORDR-9-0000032,ORDR-10-0000060,ORDR-11-0000012,ORDR-12-0000045,ORDR-13-0000071,ORDR-14-0000064,ORDR-15-0000068,ORDR-16-0000022,ORDR-17-0000030,ORDR-18-0000066]",
    "spendWeekly": [
      2946,
      3082,
      2906,
      2902,
      2995,
      3060,
      3100,
      3004,
      2911,
      3047,
      3083,
      2904,
      2976,
      3082,
      2980,
      2901,
      2927,
      2900,
      2967,
      3098,
      2916,
      3097,
      2946,
      3035,
      2946,
      3092,
      3013,
      3033,
      2913,
      3077,
      3076,
      2998,
      3099,
      3081,
      3003,
      3038,
      3055,
      2935,
      2906,
      3034,
      2954,
      3093,
      3003,
      2993,
      3035,
      2965,
      3069,
      2900,
      3100,
      3082,
      3014,
      2973
    ],
    "orderRejected": [
      2973,
      8,
      6,
      8,
      8,
      12,
      19,
      0,
      17,
      11,
      0,
      0,
      15,
      4,
      8,
      14,
      19,
      8,
      4,
      8,
      13,
      7,
      1,
      19,
      7,
      15,
      0,
      5,
      19,
      3,
      6,
      12,
      19,
      12,
      0,
      15,
      1,
      18,
      15,
      14,
      19,
      19,
      11,
      7,
      4,
      6,
      16,
      8,
      11,
      19,
      8,
      5,
      18
    ],
    "orderAccepted": [
      1,
      11,
      4,
      11,
      8,
      2,
      7,
      0,
      1,
      5,
      0,
      8,
      11,
      11,
      11,
      11,
      5,
      11,
      7,
      3,
      4,
      11,
      10,
      6,
      11,
      1,
      2,
      8,
      7,
      5,
      7,
      0,
      0,
      9,
      6,
      7,
      7,
      2,
      9,
      5,
      11,
      11,
      7,
      1,
      9,
      11,
      7,
      8,
      6,
      10,
      9,
      11
    ],
    "orderHold": [
      3,
      0,
      3,
      2,
      2,
      3,
      4,
      1,
      0,
      3,
      2,
      0,
      3,
      2,
      0,
      4,
      4,
      0,
      4,
      1,
      3,
      4,
      3,
      2,
      4,
      3,
      3,
      1,
      2,
      0,
      4,
      4,
      3,
      1,
      0,
      1,
      4,
      0,
      2,
      1,
      2,
      3,
      0,
      4,
      2,
      0,
      0,
      0,
      1,
      2,
      1,
      3
    ],
    "orderBackordered": [
      3,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      0,
      2,
      2,
      0,
      2,
      2,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      2,
      0,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      2,
      1,
      2,
      4,
      2,
      4,
      1,
      1,
      1,
      4,
      4,
      2,
      1,
      0,
      1,
      3,
      1,
      2,
      2,
      1,
      3,
      3,
      4,
      0,
      2,
      0,
      4,
      1,
      1,
      3,
      2,
      0,
      0,
      3,
      0,
      0,
      4,
      1,
      2,
      1,
      1,
      2,
      3,
      0,
      0,
      3,
      3,
      1,
      1,
      4
    ],
    "issueDelay": [
      0,
      1,
      4,
      4,
      2,
      1,
      3,
      4,
      2,
      4,
      1,
      4,
      4,
      4,
      4,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      2,
      1,
      2,
      4,
      4,
      2,
      3,
      4,
      1,
      0,
      2,
      4,
      0,
      4,
      0,
      0,
      3,
      4,
      1,
      0,
      0,
      4,
      0,
      0,
      2
    ],
    "issueQuantity": [
      3,
      3,
      4,
      0,
      3,
      1,
      1,
      2,
      2,
      3,
      2,
      3,
      1,
      1,
      2,
      1,
      2,
      4,
      2,
      4,
      4,
      0,
      0,
      4,
      2,
      1,
      3,
      3,
      2,
      1,
      0,
      3,
      4,
      2,
      3,
      3,
      4,
      1,
      0,
      4,
      3,
      2,
      3,
      2,
      0,
      0,
      3,
      1,
      3,
      4,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000022",
    "status": "active",
    "name": "CureCraft Technologies",
    "tag": "PPE, Imaging, Nutrition",
    "contactName": "Michael Montgomery",
    "industryRating": 2.4,
    "procedures": "[PR-037,PR-055,PR-039,PR-032,PR-056,PR-070,PR-043,PR-069,PR-065,PR-037]",
    "contracts": "[CNTRCT-0000040,CNTRCT-0000016,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000016,ORDR-1-0000015,ORDR-2-0000035,ORDR-3-0000033,ORDR-4-0000010,ORDR-5-0000036,ORDR-6-0000069,ORDR-7-0000055,ORDR-8-0000057,ORDR-9-0000022,ORDR-10-0000019,ORDR-11-0000070,ORDR-12-0000044,ORDR-13-0000076,ORDR-14-0000075,ORDR-15-0000060,ORDR-16-0000051,ORDR-17-0000080,ORDR-18-0000023]",
    "spendWeekly": [
      3097,
      3082,
      3001,
      2956,
      3023,
      2905,
      3022,
      3015,
      3100,
      2978,
      2943,
      2973,
      3048,
      2913,
      3091,
      2993,
      2995,
      2979,
      2986,
      3098,
      3004,
      3021,
      3013,
      2942,
      2918,
      2988,
      2958,
      2936,
      2953,
      2932,
      3028,
      3047,
      2957,
      2937,
      2929,
      2999,
      2907,
      2966,
      2992,
      3085,
      2985,
      3012,
      2984,
      3091,
      3100,
      3099,
      3086,
      2933,
      2901,
      2986,
      2944,
      2901
    ],
    "orderRejected": [
      2901,
      17,
      5,
      15,
      6,
      19,
      11,
      12,
      0,
      5,
      2,
      1,
      5,
      9,
      16,
      12,
      19,
      18,
      8,
      11,
      0,
      12,
      9,
      2,
      3,
      11,
      13,
      0,
      19,
      9,
      5,
      13,
      13,
      17,
      17,
      0,
      1,
      15,
      1,
      11,
      5,
      15,
      18,
      0,
      11,
      2,
      9,
      17,
      4,
      17,
      3,
      18,
      19
    ],
    "orderAccepted": [
      9,
      6,
      9,
      10,
      10,
      1,
      4,
      5,
      10,
      10,
      6,
      7,
      6,
      6,
      2,
      11,
      0,
      2,
      6,
      1,
      2,
      2,
      10,
      11,
      11,
      10,
      9,
      7,
      2,
      0,
      10,
      8,
      8,
      0,
      4,
      11,
      11,
      0,
      11,
      4,
      6,
      10,
      4,
      9,
      8,
      8,
      2,
      4,
      11,
      7,
      5,
      0
    ],
    "orderHold": [
      0,
      1,
      1,
      2,
      2,
      4,
      4,
      0,
      3,
      4,
      1,
      2,
      3,
      2,
      0,
      3,
      4,
      0,
      4,
      1,
      2,
      4,
      2,
      4,
      2,
      2,
      3,
      2,
      4,
      3,
      1,
      4,
      2,
      1,
      4,
      2,
      4,
      0,
      0,
      4,
      2,
      2,
      4,
      2,
      2,
      4,
      1,
      2,
      3,
      2,
      3,
      1
    ],
    "orderBackordered": [
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ],
    "issueQuality": [
      1,
      2,
      0,
      4,
      0,
      0,
      1,
      4,
      3,
      2,
      2,
      2,
      4,
      2,
      2,
      1,
      3,
      2,
      2,
      2,
      3,
      2,
      3,
      3,
      3,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      3,
      0,
      4,
      2,
      1,
      1,
      4,
      2,
      0,
      2,
      0,
      3,
      1,
      0,
      2,
      0,
      4,
      2,
      2,
      3
    ],
    "issueDelay": [
      0,
      4,
      4,
      1,
      0,
      2,
      3,
      2,
      2,
      1,
      3,
      2,
      4,
      1,
      3,
      1,
      2,
      3,
      2,
      3,
      0,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      3,
      2,
      1,
      3,
      0,
      4,
      4,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      4,
      0,
      4
    ],
    "issueQuantity": [
      4,
      2,
      3,
      3,
      3,
      4,
      1,
      4,
      3,
      3,
      1,
      2,
      0,
      1,
      3,
      4,
      1,
      3,
      1,
      4,
      4,
      0,
      2,
      4,
      0,
      4,
      2,
      2,
      4,
      2,
      3,
      1,
      1,
      0,
      1,
      3,
      4,
      0,
      2,
      4,
      2,
      1,
      2,
      0,
      3,
      0,
      4,
      3,
      1,
      3,
      1,
      2
    ]
  },
  {
    "id": "VNDR-0000023",
    "status": "inactive",
    "name": "BioHealth Systems",
    "tag": "Equipment, Pharmaceuticals",
    "contactName": "Albert Flowers",
    "industryRating": 2.9,
    "procedures": "[PR-066,PR-065,PR-030,PR-059,PR-064,PR-061,PR-069,PR-063,PR-073,PR-071]",
    "contracts": "[CNTRCT-0000044,CNTRCT-0000064,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000027,ORDR-1-0000027,ORDR-2-0000064,ORDR-3-0000017,ORDR-4-0000053,ORDR-5-0000035,ORDR-6-0000026,ORDR-7-0000032,ORDR-8-0000051,ORDR-9-0000038,ORDR-10-0000066,ORDR-11-0000023,ORDR-12-0000018,ORDR-13-0000059,ORDR-14-0000071,ORDR-15-0000041,ORDR-16-0000061,ORDR-17-0000069,ORDR-18-0000040]",
    "spendWeekly": [
      2924,
      3082,
      2906,
      2982,
      2993,
      2905,
      3100,
      3098,
      2980,
      2902,
      3011,
      3000,
      2936,
      2952,
      3091,
      3002,
      2903,
      3099,
      2910,
      3098,
      3100,
      2903,
      3006,
      3082,
      3026,
      3096,
      2934,
      2907,
      2933,
      2902,
      2927,
      3019,
      3082,
      3093,
      3027,
      2991,
      3039,
      2902,
      3025,
      3087,
      3008,
      2957,
      3060,
      2991,
      2902,
      2943,
      2901,
      2900,
      3100,
      2905,
      2905,
      3006
    ],
    "orderRejected": [
      3006,
      19,
      10,
      3,
      16,
      19,
      19,
      19,
      2,
      19,
      0,
      4,
      13,
      2,
      19,
      18,
      0,
      0,
      0,
      0,
      12,
      12,
      0,
      18,
      3,
      17,
      4,
      0,
      7,
      19,
      0,
      19,
      1,
      17,
      6,
      6,
      13,
      8,
      14,
      19,
      19,
      18,
      13,
      0,
      16,
      16,
      0,
      14,
      13,
      8,
      2,
      13,
      2
    ],
    "orderAccepted": [
      11,
      5,
      11,
      4,
      2,
      11,
      8,
      5,
      3,
      0,
      2,
      4,
      11,
      5,
      11,
      10,
      0,
      0,
      8,
      9,
      1,
      0,
      7,
      0,
      0,
      0,
      0,
      11,
      1,
      11,
      8,
      5,
      0,
      1,
      11,
      8,
      4,
      0,
      11,
      0,
      0,
      0,
      10,
      7,
      11,
      11,
      5,
      11,
      0,
      1,
      6,
      0
    ],
    "orderHold": [
      1,
      4,
      1,
      2,
      1,
      3,
      2,
      2,
      1,
      3,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      1,
      4,
      4,
      0,
      1,
      3,
      4,
      1,
      0,
      2,
      1,
      4,
      1,
      0,
      3,
      0,
      0,
      0,
      3,
      4,
      2,
      0,
      2,
      3,
      0,
      2,
      4,
      2,
      2,
      2,
      4,
      0,
      1,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      0,
      0,
      3,
      3,
      4,
      3,
      0,
      4,
      3,
      3,
      2,
      0,
      3,
      1,
      0,
      0,
      4,
      2,
      3,
      4,
      1,
      1,
      1,
      4,
      2,
      3,
      4,
      0,
      4,
      2,
      0,
      2,
      1,
      1,
      2,
      4,
      0,
      2,
      0,
      3,
      3,
      4,
      4,
      4,
      0,
      4,
      2,
      0,
      3,
      3,
      2,
      4
    ],
    "issueDelay": [
      0,
      2,
      4,
      4,
      0,
      4,
      1,
      3,
      2,
      3,
      0,
      2,
      4,
      3,
      0,
      4,
      4,
      0,
      2,
      0,
      4,
      2,
      0,
      4,
      0,
      2,
      4,
      0,
      4,
      4,
      4,
      3,
      4,
      0,
      4,
      0,
      0,
      3,
      1,
      2,
      4,
      1,
      0,
      0,
      4,
      3,
      0,
      0,
      1,
      0,
      0,
      4
    ],
    "issueQuantity": [
      1,
      0,
      4,
      0,
      4,
      2,
      4,
      2,
      3,
      1,
      4,
      3,
      0,
      1,
      1,
      2,
      4,
      3,
      0,
      4,
      2,
      2,
      2,
      4,
      2,
      4,
      4,
      1,
      4,
      3,
      3,
      0,
      0,
      4,
      3,
      2,
      4,
      2,
      1,
      2,
      1,
      0,
      4,
      0,
      4,
      1,
      2,
      3,
      0,
      4,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000024",
    "status": "active",
    "name": "WellnessWorks Distribution",
    "tag": "Nutrition",
    "contactName": "Christopher Gonzalez",
    "industryRating": 3.6,
    "procedures": "[PR-062,PR-048,PR-040,PR-062,PR-041,PR-053,PR-059,PR-072,PR-056,PR-020]",
    "contracts": "[CNTRCT-0000054,CNTRCT-0000055,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000021,ORDR-1-0000024,ORDR-2-0000007,ORDR-3-0000010,ORDR-4-0000045,ORDR-5-0000014,ORDR-6-0000009,ORDR-7-0000064,ORDR-8-0000016,ORDR-9-0000064,ORDR-10-0000013,ORDR-11-0000024,ORDR-12-0000011,ORDR-13-0000022,ORDR-14-0000067,ORDR-15-0000056,ORDR-16-0000036,ORDR-17-0000062,ORDR-18-0000035]",
    "spendWeekly": [
      2991,
      3018,
      2950,
      3007,
      3025,
      2938,
      2996,
      3029,
      3063,
      2976,
      2966,
      3026,
      3008,
      3049,
      3091,
      2901,
      2903,
      3099,
      3022,
      2909,
      3100,
      3038,
      3004,
      2947,
      2996,
      3038,
      2993,
      2922,
      3048,
      2984,
      3029,
      2962,
      2984,
      3007,
      2932,
      2967,
      2971,
      2947,
      3078,
      2901,
      3031,
      2908,
      2964,
      3091,
      3012,
      3009,
      3099,
      2900,
      2981,
      2962,
      3004,
      3018
    ],
    "orderRejected": [
      3018,
      8,
      0,
      16,
      19,
      19,
      1,
      15,
      12,
      17,
      5,
      6,
      10,
      18,
      7,
      17,
      12,
      10,
      1,
      12,
      10,
      0,
      0,
      15,
      7,
      6,
      5,
      9,
      18,
      13,
      17,
      19,
      7,
      9,
      9,
      0,
      0,
      11,
      13,
      5,
      17,
      13,
      19,
      0,
      16,
      12,
      2,
      12,
      19,
      19,
      0,
      0,
      16
    ],
    "orderAccepted": [
      8,
      11,
      0,
      0,
      11,
      8,
      4,
      3,
      6,
      3,
      7,
      11,
      4,
      0,
      4,
      2,
      7,
      11,
      5,
      3,
      1,
      0,
      6,
      1,
      8,
      5,
      0,
      6,
      0,
      4,
      1,
      4,
      0,
      1,
      3,
      7,
      4,
      1,
      11,
      0,
      4,
      8,
      8,
      7,
      6,
      10,
      0,
      11,
      5,
      9,
      0,
      0
    ],
    "orderHold": [
      2,
      2,
      3,
      3,
      1,
      1,
      4,
      1,
      4,
      2,
      2,
      4,
      2,
      3,
      1,
      0,
      4,
      1,
      4,
      2,
      3,
      0,
      3,
      4,
      1,
      4,
      2,
      3,
      3,
      2,
      0,
      2,
      2,
      3,
      0,
      3,
      2,
      0,
      2,
      1,
      0,
      2,
      2,
      3,
      4,
      2,
      4,
      1,
      1,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      0,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      0,
      0,
      2,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      2,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      1,
      4,
      2,
      0,
      2,
      0,
      2,
      4,
      2,
      3,
      0,
      0,
      2,
      4,
      0,
      0,
      2,
      2,
      4,
      0,
      2,
      0,
      1,
      4,
      1,
      2,
      2,
      4,
      2,
      0,
      2,
      3,
      0,
      2,
      4,
      4,
      0,
      4,
      4,
      1,
      2,
      3,
      3,
      2,
      2,
      2,
      4,
      2,
      0,
      2,
      3
    ],
    "issueDelay": [
      2,
      2,
      2,
      3,
      0,
      3,
      4,
      1,
      3,
      0,
      2,
      2,
      2,
      3,
      3,
      0,
      1,
      4,
      1,
      1,
      0,
      4,
      0,
      0,
      3,
      4,
      1,
      2,
      2,
      4,
      3,
      1,
      1,
      1,
      0,
      3,
      0,
      4,
      4,
      1,
      3,
      0,
      1,
      2,
      0,
      0,
      1,
      4,
      4,
      0,
      0,
      0
    ],
    "issueQuantity": [
      3,
      0,
      2,
      0,
      1,
      0,
      1,
      4,
      3,
      2,
      4,
      4,
      0,
      2,
      4,
      1,
      2,
      3,
      0,
      4,
      3,
      3,
      3,
      1,
      0,
      0,
      2,
      0,
      2,
      4,
      3,
      1,
      2,
      1,
      1,
      4,
      4,
      0,
      0,
      1,
      3,
      3,
      1,
      3,
      3,
      1,
      3,
      1,
      1,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000025",
    "status": "inactive",
    "name": "HealthStream Supplies",
    "tag": "Facilities Management, Pharmaceuticals, Surgical Instruments",
    "contactName": "Amanda Glenn",
    "industryRating": 3.7,
    "procedures": "[PR-056,PR-065,PR-062,PR-066,PR-065,PR-036,PR-059,PR-063,PR-061,PR-048]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000065,CNTRCT-0000049]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000048,ORDR-2-0000058,ORDR-3-0000003,ORDR-4-0000036,ORDR-5-0000028,ORDR-6-0000041,ORDR-7-0000006,ORDR-8-0000056,ORDR-9-0000043,ORDR-10-0000033,ORDR-11-0000037,ORDR-12-0000067,ORDR-13-0000064,ORDR-14-0000063,ORDR-15-0000062,ORDR-16-0000045,ORDR-17-0000030,ORDR-18-0000049]",
    "spendWeekly": [
      3075,
      3022,
      2914,
      2943,
      3012,
      3023,
      2905,
      2946,
      3067,
      2941,
      3034,
      2954,
      2975,
      3013,
      3056,
      2901,
      2995,
      2900,
      2996,
      3087,
      2903,
      2957,
      3075,
      3095,
      3001,
      3096,
      3011,
      3096,
      2970,
      2902,
      2988,
      3012,
      2958,
      3044,
      3054,
      2904,
      2976,
      2973,
      3013,
      3042,
      2912,
      2981,
      3053,
      2974,
      2902,
      2901,
      2998,
      3005,
      3035,
      2957,
      2995,
      2956
    ],
    "orderRejected": [
      2956,
      19,
      8,
      19,
      14,
      10,
      9,
      13,
      6,
      19,
      14,
      17,
      9,
      11,
      6,
      8,
      14,
      1,
      10,
      8,
      0,
      10,
      13,
      11,
      9,
      5,
      15,
      11,
      17,
      19,
      1,
      18,
      0,
      5,
      12,
      16,
      0,
      19,
      17,
      7,
      5,
      19,
      19,
      0,
      18,
      4,
      1,
      13,
      10,
      6,
      13,
      15,
      18
    ],
    "orderAccepted": [
      10,
      3,
      4,
      2,
      10,
      2,
      11,
      7,
      0,
      0,
      4,
      3,
      8,
      11,
      11,
      11,
      3,
      5,
      2,
      9,
      8,
      1,
      8,
      11,
      7,
      5,
      4,
      8,
      10,
      4,
      11,
      4,
      0,
      8,
      8,
      4,
      2,
      5,
      11,
      0,
      0,
      6,
      9,
      4,
      4,
      9,
      4,
      11,
      4,
      0,
      4,
      8
    ],
    "orderHold": [
      2,
      0,
      2,
      3,
      3,
      4,
      3,
      3,
      0,
      4,
      2,
      0,
      1,
      4,
      0,
      2,
      1,
      2,
      4,
      4,
      2,
      3,
      3,
      3,
      0,
      3,
      3,
      2,
      4,
      1,
      2,
      1,
      3,
      3,
      0,
      3,
      4,
      2,
      2,
      3,
      3,
      3,
      0,
      4,
      2,
      4,
      0,
      2,
      4,
      0,
      3,
      0
    ],
    "orderBackordered": [
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      1,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      0,
      1,
      4,
      4,
      0,
      3,
      0,
      2,
      0,
      2,
      4,
      4,
      3,
      0,
      0,
      1,
      4,
      4,
      1,
      0,
      0,
      0,
      2,
      2,
      0,
      1,
      4,
      0,
      4,
      3,
      0,
      4,
      0,
      0,
      0,
      4,
      1,
      0,
      0,
      4,
      0,
      3,
      0,
      3,
      3,
      4,
      3,
      0,
      4,
      4,
      3,
      2
    ],
    "issueDelay": [
      0,
      1,
      4,
      4,
      0,
      1,
      2,
      4,
      0,
      0,
      3,
      2,
      4,
      2,
      0,
      0,
      4,
      0,
      1,
      0,
      4,
      3,
      3,
      2,
      1,
      0,
      1,
      0,
      4,
      2,
      3,
      4,
      3,
      2,
      4,
      1,
      4,
      0,
      1,
      3,
      3,
      4,
      2,
      3,
      0,
      3,
      1,
      2,
      0,
      2,
      3,
      0
    ],
    "issueQuantity": [
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      2,
      0,
      3,
      3,
      2,
      0,
      3,
      3,
      1,
      2,
      4,
      3,
      4,
      0,
      0,
      1,
      2,
      2,
      0,
      2,
      0,
      0,
      1,
      2,
      2,
      0,
      2,
      3,
      2,
      4,
      0,
      3,
      4,
      4,
      1,
      2,
      2,
      2,
      1,
      4,
      2,
      3,
      0,
      2,
      0
    ]
  },
  {
    "id": "VNDR-0000026",
    "status": "inactive",
    "name": "LifeLine Supplies",
    "tag": "Facilities Management, Laboratory Supplies",
    "contactName": "Amanda Glenn",
    "industryRating": 2.9,
    "procedures": "[PR-051,PR-066,PR-056,PR-009,PR-069,PR-050,PR-052,PR-036,PR-036,PR-040]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000053,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000065,ORDR-1-0000030,ORDR-2-0000049,ORDR-3-0000038,ORDR-4-0000066,ORDR-5-0000022,ORDR-6-0000017,ORDR-7-0000070,ORDR-8-0000015,ORDR-9-0000071,ORDR-10-0000064,ORDR-11-0000021,ORDR-12-0000033,ORDR-13-0000033,ORDR-14-0000062,ORDR-15-0000036,ORDR-16-0000073,ORDR-17-0000019,ORDR-18-0000025]",
    "spendWeekly": [
      2938,
      2964,
      2917,
      3097,
      2994,
      2905,
      3100,
      3098,
      2939,
      2989,
      2980,
      2987,
      2941,
      3072,
      3022,
      2901,
      3055,
      3099,
      2900,
      2968,
      3100,
      3027,
      3059,
      3058,
      2960,
      3007,
      2909,
      2961,
      2901,
      3051,
      3058,
      3097,
      3071,
      2989,
      3007,
      2966,
      2969,
      3010,
      3099,
      3016,
      3004,
      2931,
      3049,
      2937,
      2934,
      3031,
      2974,
      2936,
      3097,
      2905,
      3040,
      3076
    ],
    "orderRejected": [
      3076,
      5,
      19,
      6,
      8,
      15,
      1,
      13,
      12,
      15,
      0,
      10,
      0,
      12,
      9,
      4,
      6,
      3,
      1,
      9,
      19,
      19,
      7,
      10,
      14,
      0,
      0,
      2,
      5,
      19,
      0,
      17,
      0,
      17,
      11,
      2,
      12,
      2,
      19,
      10,
      9,
      19,
      11,
      0,
      0,
      1,
      0,
      13,
      18,
      16,
      5,
      11,
      15
    ],
    "orderAccepted": [
      5,
      11,
      10,
      7,
      2,
      11,
      0,
      8,
      4,
      2,
      6,
      4,
      6,
      9,
      11,
      9,
      8,
      0,
      9,
      6,
      0,
      4,
      11,
      8,
      3,
      3,
      0,
      8,
      0,
      8,
      10,
      4,
      0,
      4,
      0,
      10,
      2,
      3,
      7,
      10,
      1,
      9,
      11,
      4,
      11,
      11,
      11,
      0,
      0,
      9,
      2,
      11
    ],
    "orderHold": [
      1,
      0,
      2,
      2,
      0,
      1,
      3,
      0,
      1,
      0,
      0,
      0,
      3,
      4,
      1,
      1,
      3,
      0,
      3,
      2,
      0,
      3,
      4,
      3,
      0,
      2,
      3,
      0,
      2,
      0,
      4,
      4,
      3,
      0,
      0,
      3,
      3,
      2,
      3,
      4,
      3,
      2,
      4,
      4,
      2,
      2,
      0,
      3,
      0,
      1,
      2,
      0
    ],
    "orderBackordered": [
      0,
      1,
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      0,
      4,
      1,
      4,
      3,
      2,
      1,
      2,
      4,
      4,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      4,
      0,
      2,
      2,
      0,
      2,
      0,
      4,
      2,
      4,
      2,
      3,
      2,
      3,
      2,
      4,
      3,
      2,
      4,
      1,
      2,
      2,
      3,
      2,
      3,
      4,
      2,
      2,
      1,
      0,
      3,
      2,
      0
    ],
    "issueDelay": [
      2,
      1,
      4,
      4,
      4,
      0,
      2,
      0,
      0,
      1,
      2,
      4,
      1,
      4,
      1,
      3,
      4,
      2,
      1,
      0,
      4,
      3,
      0,
      2,
      1,
      3,
      4,
      2,
      1,
      4,
      2,
      2,
      4,
      0,
      4,
      4,
      2,
      4,
      0,
      4,
      1,
      1,
      2,
      2,
      3,
      3,
      0,
      1,
      1,
      1,
      0,
      4
    ],
    "issueQuantity": [
      4,
      1,
      0,
      2,
      4,
      4,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      1,
      4,
      1,
      2,
      2,
      4,
      0,
      0,
      3,
      0,
      0,
      4,
      3,
      1,
      3,
      2,
      1,
      1,
      1,
      4,
      2,
      2,
      3,
      2,
      1,
      4,
      4,
      2,
      4,
      1,
      4,
      1,
      2,
      0,
      0,
      3,
      0,
      2
    ]
  },
  {
    "id": "VNDR-0000027",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Imaging",
    "contactName": "Jeremy Becker",
    "industryRating": 2.9,
    "procedures": "[PR-066,PR-065,PR-039,PR-065,PR-069,PR-070,PR-055,PR-073,PR-073,PR-046]",
    "contracts": "[CNTRCT-0000030,CNTRCT-0000035,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000039,ORDR-1-0000049,ORDR-2-0000040,ORDR-3-0000066,ORDR-4-0000037,ORDR-5-0000034,ORDR-6-0000057,ORDR-7-0000059,ORDR-8-0000031,ORDR-9-0000016,ORDR-10-0000021,ORDR-11-0000059,ORDR-12-0000018,ORDR-13-0000032,ORDR-14-0000047,ORDR-15-0000038,ORDR-16-0000051,ORDR-17-0000071,ORDR-18-0000022]",
    "spendWeekly": [
      3008,
      3082,
      2990,
      2950,
      3095,
      2982,
      2974,
      2951,
      2903,
      2902,
      3100,
      2946,
      2909,
      3094,
      2903,
      2901,
      3003,
      3099,
      2900,
      3098,
      3099,
      3097,
      2919,
      2945,
      3087,
      3093,
      3021,
      2970,
      2901,
      3049,
      2908,
      3097,
      3044,
      3019,
      3029,
      3067,
      2996,
      3037,
      3099,
      2982,
      2910,
      2941,
      3089,
      2963,
      3019,
      2901,
      2901,
      2937,
      3100,
      2936,
      3095,
      3097
    ],
    "orderRejected": [
      3097,
      9,
      16,
      18,
      15,
      19,
      3,
      0,
      0,
      19,
      3,
      4,
      3,
      8,
      17,
      16,
      8,
      0,
      11,
      11,
      15,
      19,
      18,
      17,
      19,
      11,
      0,
      10,
      16,
      19,
      10,
      19,
      19,
      19,
      3,
      8,
      5,
      2,
      14,
      0,
      14,
      19,
      4,
      0,
      7,
      19,
      6,
      18,
      0,
      17,
      5,
      18,
      13
    ],
    "orderAccepted": [
      7,
      1,
      6,
      4,
      0,
      4,
      1,
      11,
      6,
      0,
      6,
      8,
      5,
      5,
      11,
      3,
      10,
      6,
      7,
      3,
      8,
      3,
      5,
      0,
      10,
      2,
      0,
      4,
      0,
      11,
      10,
      4,
      0,
      5,
      3,
      8,
      10,
      1,
      7,
      2,
      10,
      8,
      10,
      9,
      0,
      11,
      3,
      3,
      1,
      7,
      4,
      1
    ],
    "orderHold": [
      1,
      1,
      3,
      0,
      3,
      0,
      2,
      0,
      4,
      2,
      3,
      2,
      1,
      4,
      1,
      2,
      3,
      0,
      4,
      4,
      2,
      3,
      3,
      0,
      0,
      1,
      3,
      2,
      0,
      1,
      2,
      3,
      0,
      3,
      3,
      2,
      4,
      0,
      3,
      1,
      1,
      0,
      3,
      1,
      4,
      1,
      1,
      0,
      0,
      1,
      2,
      2
    ],
    "orderBackordered": [
      2,
      2,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      1,
      2,
      0,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      0,
      4,
      0,
      3,
      2,
      2,
      2,
      0,
      4,
      0,
      4,
      4,
      4,
      1,
      0,
      3,
      0,
      0,
      3,
      0,
      0,
      0,
      1,
      0,
      4,
      4,
      3,
      4,
      1,
      2,
      1,
      4,
      1,
      0,
      3,
      4,
      0,
      4,
      4,
      0,
      2,
      3,
      3,
      3,
      2,
      0,
      0,
      2,
      4,
      0,
      4
    ],
    "issueDelay": [
      3,
      3,
      4,
      4,
      2,
      1,
      2,
      2,
      0,
      4,
      0,
      2,
      2,
      4,
      2,
      0,
      3,
      1,
      4,
      4,
      0,
      2,
      3,
      3,
      3,
      4,
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      4,
      1,
      0,
      4,
      3,
      4,
      1,
      0,
      0,
      2,
      4,
      0,
      0,
      1,
      3,
      0,
      4,
      4
    ],
    "issueQuantity": [
      2,
      2,
      0,
      0,
      3,
      0,
      0,
      0,
      0,
      3,
      1,
      4,
      1,
      3,
      0,
      4,
      2,
      0,
      1,
      2,
      3,
      0,
      2,
      2,
      0,
      3,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      1,
      4,
      2,
      2,
      0,
      1,
      4,
      4,
      4,
      4,
      2,
      4,
      1,
      3,
      0,
      0,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000028",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "Nutrition, Imaging, Laboratory Supplies",
    "contactName": "Aaron Howard",
    "industryRating": 2.1,
    "procedures": "[PR-051,PR-061,PR-063,PR-059,PR-064,PR-036,PR-068,PR-039,PR-045,PR-046]",
    "contracts": "[CNTRCT-0000022,CNTRCT-0000016,CNTRCT-0000051]",
    "orders": "[ORDR-0-0000003,ORDR-1-0000040,ORDR-2-0000011,ORDR-3-0000036,ORDR-4-0000031,ORDR-5-0000016,ORDR-6-0000041,ORDR-7-0000010,ORDR-8-0000067,ORDR-9-0000058,ORDR-10-0000039,ORDR-11-0000045,ORDR-12-0000029,ORDR-13-0000032,ORDR-14-0000013,ORDR-15-0000026,ORDR-16-0000017,ORDR-17-0000070,ORDR-18-0000081]",
    "spendWeekly": [
      2914,
      2930,
      3016,
      2902,
      2973,
      2921,
      2951,
      2995,
      2902,
      2905,
      3087,
      3024,
      2943,
      2924,
      3091,
      2972,
      2986,
      2980,
      3004,
      3041,
      2903,
      2958,
      3034,
      2995,
      2973,
      3037,
      3027,
      3047,
      3058,
      2997,
      3057,
      3022,
      3079,
      2960,
      3026,
      2942,
      2981,
      3043,
      2906,
      3021,
      2901,
      2938,
      2906,
      3091,
      3021,
      3004,
      3065,
      2900,
      3022,
      3048,
      2915,
      2949
    ],
    "orderRejected": [
      2949,
      16,
      7,
      0,
      0,
      18,
      7,
      11,
      15,
      18,
      3,
      19,
      8,
      2,
      11,
      10,
      19,
      8,
      1,
      12,
      4,
      14,
      1,
      18,
      7,
      18,
      10,
      8,
      12,
      13,
      19,
      11,
      19,
      18,
      15,
      19,
      0,
      1,
      17,
      8,
      9,
      17,
      1,
      0,
      7,
      14,
      0,
      10,
      9,
      15,
      11,
      3,
      19
    ],
    "orderAccepted": [
      9,
      11,
      7,
      6,
      11,
      3,
      9,
      5,
      1,
      6,
      2,
      11,
      1,
      9,
      11,
      3,
      7,
      10,
      0,
      10,
      3,
      6,
      0,
      3,
      11,
      6,
      0,
      11,
      5,
      6,
      9,
      11,
      2,
      0,
      4,
      10,
      11,
      0,
      11,
      8,
      10,
      0,
      1,
      3,
      1,
      8,
      0,
      7,
      5,
      9,
      9,
      11
    ],
    "orderHold": [
      3,
      2,
      4,
      0,
      3,
      2,
      0,
      0,
      3,
      2,
      1,
      0,
      1,
      0,
      0,
      4,
      3,
      0,
      2,
      1,
      2,
      4,
      1,
      1,
      0,
      4,
      3,
      1,
      1,
      0,
      0,
      1,
      2,
      3,
      0,
      2,
      3,
      3,
      0,
      1,
      2,
      3,
      1,
      3,
      2,
      2,
      0,
      1,
      0,
      1,
      0,
      0
    ],
    "orderBackordered": [
      0,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      1,
      0,
      1,
      0,
      0,
      3,
      3,
      4,
      2,
      4,
      2,
      4,
      3,
      0,
      4,
      1,
      3,
      3,
      0,
      0,
      1,
      2,
      4,
      3,
      4,
      2,
      0,
      3,
      2,
      2,
      0,
      2,
      2,
      0,
      4,
      1,
      1,
      0,
      4,
      2,
      0,
      4,
      1,
      2,
      4,
      0,
      3,
      4,
      0,
      0,
      4
    ],
    "issueDelay": [
      0,
      3,
      2,
      1,
      0,
      4,
      1,
      3,
      2,
      4,
      1,
      0,
      4,
      1,
      4,
      2,
      4,
      0,
      2,
      3,
      2,
      0,
      0,
      0,
      0,
      0,
      3,
      0,
      2,
      2,
      0,
      3,
      2,
      0,
      0,
      0,
      0,
      3,
      1,
      0,
      2,
      4,
      1,
      1,
      2,
      3,
      1,
      4,
      4,
      0,
      3,
      3
    ],
    "issueQuantity": [
      2,
      0,
      0,
      0,
      3,
      0,
      0,
      0,
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      4,
      3,
      4,
      0,
      1,
      2,
      2,
      4,
      2,
      1,
      3,
      1,
      2,
      0,
      0,
      4,
      3,
      3,
      4,
      2,
      2,
      2,
      0,
      1,
      4,
      4,
      4,
      4,
      0,
      1,
      4,
      2,
      4,
      3,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000029",
    "status": "inactive",
    "name": "VitalNet Distribution",
    "tag": "Laboratory Supplies, Pharmaceuticals",
    "contactName": "Bonnie Jones",
    "industryRating": 3.4,
    "procedures": "[PR-041,PR-031,PR-060,PR-050,PR-042,PR-065,PR-054,PR-051,PR-056,PR-025]",
    "contracts": "[CNTRCT-0000043,CNTRCT-0000061,CNTRCT-0000059]",
    "orders": "[ORDR-0-0000015,ORDR-1-0000037,ORDR-2-0000054,ORDR-3-0000003,ORDR-4-0000059,ORDR-5-0000048,ORDR-6-0000031,ORDR-7-0000014,ORDR-8-0000018,ORDR-9-0000061,ORDR-10-0000057,ORDR-11-0000053,ORDR-12-0000069,ORDR-13-0000025,ORDR-14-0000070,ORDR-15-0000022,ORDR-16-0000015,ORDR-17-0000056,ORDR-18-0000075]",
    "spendWeekly": [
      3061,
      2964,
      2906,
      2902,
      3007,
      2936,
      2934,
      3027,
      2932,
      3066,
      3005,
      2970,
      2958,
      3055,
      3023,
      2915,
      2983,
      3077,
      2900,
      3006,
      2938,
      3004,
      2900,
      2916,
      2906,
      2956,
      3024,
      2996,
      2951,
      2906,
      2918,
      3007,
      3035,
      3093,
      3003,
      2904,
      3007,
      3097,
      3022,
      3084,
      3030,
      2985,
      3058,
      2945,
      2917,
      2901,
      2927,
      3047,
      3070,
      2905,
      2987,
      3023
    ],
    "orderRejected": [
      3023,
      1,
      7,
      13,
      13,
      19,
      3,
      10,
      10,
      14,
      12,
      12,
      19,
      18,
      8,
      19,
      8,
      7,
      5,
      1,
      4,
      16,
      6,
      19,
      19,
      16,
      18,
      0,
      18,
      19,
      12,
      19,
      19,
      19,
      0,
      0,
      6,
      17,
      16,
      0,
      9,
      10,
      19,
      13,
      18,
      0,
      16,
      18,
      7,
      4,
      12,
      19,
      4
    ],
    "orderAccepted": [
      9,
      11,
      2,
      11,
      8,
      3,
      11,
      2,
      4,
      6,
      8,
      7,
      0,
      6,
      3,
      3,
      3,
      0,
      8,
      6,
      5,
      10,
      5,
      11,
      8,
      10,
      0,
      6,
      7,
      5,
      9,
      3,
      0,
      3,
      9,
      9,
      8,
      0,
      11,
      6,
      2,
      10,
      7,
      0,
      4,
      9,
      11,
      11,
      1,
      8,
      0,
      6
    ],
    "orderHold": [
      0,
      3,
      1,
      1,
      4,
      3,
      1,
      2,
      4,
      1,
      4,
      0,
      0,
      2,
      0,
      1,
      4,
      3,
      0,
      1,
      2,
      2,
      3,
      4,
      2,
      2,
      0,
      3,
      2,
      1,
      1,
      4,
      0,
      3,
      3,
      2,
      3,
      0,
      3,
      1,
      3,
      3,
      0,
      3,
      1,
      1,
      1,
      3,
      1,
      3,
      2,
      1
    ],
    "orderBackordered": [
      1,
      0,
      0,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      1,
      4,
      4,
      4,
      0,
      2,
      0,
      1,
      1,
      0,
      4,
      4,
      0,
      3,
      3,
      2,
      4,
      3,
      0,
      3,
      2,
      1,
      3,
      1,
      0,
      1,
      3,
      4,
      3,
      0,
      1,
      2,
      3,
      0,
      3,
      4,
      4,
      0,
      0,
      2,
      0,
      3,
      1,
      2,
      0,
      1,
      0,
      0,
      4,
      3,
      0,
      0
    ],
    "issueDelay": [
      3,
      2,
      4,
      3,
      1,
      2,
      0,
      1,
      3,
      4,
      4,
      1,
      4,
      0,
      3,
      0,
      1,
      0,
      2,
      0,
      0,
      1,
      0,
      4,
      2,
      0,
      0,
      3,
      0,
      1,
      2,
      1,
      1,
      4,
      3,
      4,
      1,
      2,
      4,
      0,
      2,
      1,
      3,
      0,
      3,
      2,
      2,
      2,
      3,
      3,
      3,
      1
    ],
    "issueQuantity": [
      4,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      3,
      3,
      0,
      4,
      1,
      0,
      0,
      3,
      3,
      1,
      2,
      3,
      2,
      4,
      0,
      1,
      0,
      4,
      4,
      3,
      1,
      4,
      3,
      2,
      2,
      2,
      3,
      3,
      1,
      0,
      4,
      4,
      4,
      3,
      4,
      0,
      4,
      0,
      1,
      0,
      0,
      0,
      2,
      3
    ]
  },
  {
    "id": "VNDR-0000030",
    "status": "inactive",
    "name": "CureCraft Technologies",
    "tag": "Imaging, PPE, IT Services",
    "contactName": "Kaitlyn Hernandez",
    "industryRating": 4.8,
    "procedures": "[PR-055,PR-049,PR-060,PR-009,PR-047,PR-071,PR-043,PR-068,PR-063,PR-020]",
    "contracts": "[CNTRCT-0000013,CNTRCT-0000049,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000002,ORDR-2-0000009,ORDR-3-0000023,ORDR-4-0000002,ORDR-5-0000038,ORDR-6-0000022,ORDR-7-0000009,ORDR-8-0000026,ORDR-9-0000029,ORDR-10-0000030,ORDR-11-0000036,ORDR-12-0000057,ORDR-13-0000074,ORDR-14-0000042,ORDR-15-0000034,ORDR-16-0000063,ORDR-17-0000062,ORDR-18-0000031]",
    "spendWeekly": [
      2901,
      2988,
      2980,
      2991,
      3014,
      2941,
      3035,
      2919,
      3047,
      3036,
      3100,
      2904,
      2909,
      3051,
      3091,
      3006,
      3012,
      2900,
      3033,
      3059,
      2988,
      3038,
      3056,
      2903,
      2956,
      3074,
      3093,
      3055,
      2943,
      3019,
      3021,
      2955,
      3000,
      2990,
      2922,
      3047,
      3039,
      3097,
      2942,
      3054,
      3018,
      2900,
      2920,
      2965,
      3047,
      3038,
      3047,
      3047,
      3000,
      2991,
      2982,
      3097
    ],
    "orderRejected": [
      3097,
      10,
      3,
      15,
      11,
      19,
      6,
      9,
      3,
      11,
      15,
      5,
      19,
      5,
      13,
      12,
      7,
      0,
      2,
      9,
      5,
      11,
      8,
      11,
      19,
      14,
      8,
      19,
      15,
      9,
      4,
      17,
      19,
      1,
      19,
      15,
      5,
      0,
      19,
      6,
      15,
      9,
      14,
      5,
      12,
      2,
      0,
      18,
      17,
      16,
      2,
      6,
      19
    ],
    "orderAccepted": [
      11,
      9,
      9,
      3,
      3,
      2,
      10,
      3,
      3,
      0,
      7,
      3,
      8,
      4,
      6,
      5,
      4,
      8,
      3,
      8,
      8,
      6,
      2,
      4,
      6,
      6,
      3,
      8,
      7,
      0,
      11,
      11,
      4,
      7,
      0,
      8,
      11,
      9,
      6,
      4,
      4,
      9,
      6,
      7,
      0,
      8,
      0,
      10,
      8,
      5,
      5,
      2
    ],
    "orderHold": [
      3,
      4,
      2,
      2,
      3,
      2,
      3,
      2,
      3,
      3,
      1,
      2,
      2,
      4,
      0,
      3,
      1,
      0,
      2,
      3,
      2,
      3,
      0,
      1,
      0,
      4,
      1,
      2,
      2,
      1,
      2,
      4,
      1,
      4,
      4,
      2,
      2,
      3,
      3,
      4,
      0,
      2,
      0,
      2,
      1,
      2,
      0,
      4,
      1,
      1,
      4,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      2,
      3,
      0,
      4,
      1,
      1,
      2,
      1,
      0,
      1,
      3,
      1,
      4,
      3,
      2,
      1,
      1,
      3,
      0,
      4,
      0,
      0,
      1,
      2,
      2,
      4,
      2,
      2,
      4,
      1,
      2,
      3,
      2,
      4,
      2,
      0,
      1,
      0,
      3,
      4,
      0,
      1,
      4,
      1,
      1,
      0,
      3,
      4,
      3,
      0,
      0,
      4
    ],
    "issueDelay": [
      0,
      1,
      0,
      4,
      1,
      2,
      3,
      0,
      2,
      3,
      0,
      0,
      4,
      3,
      0,
      0,
      1,
      0,
      3,
      3,
      3,
      0,
      1,
      2,
      2,
      3,
      4,
      2,
      1,
      1,
      3,
      1,
      0,
      2,
      2,
      2,
      3,
      4,
      1,
      0,
      0,
      0,
      2,
      3,
      4,
      4,
      1,
      3,
      4,
      1,
      1,
      3
    ],
    "issueQuantity": [
      2,
      1,
      2,
      2,
      4,
      1,
      3,
      1,
      0,
      0,
      4,
      2,
      1,
      2,
      4,
      1,
      2,
      4,
      2,
      4,
      1,
      4,
      4,
      4,
      1,
      1,
      2,
      2,
      4,
      2,
      2,
      2,
      1,
      0,
      0,
      2,
      1,
      0,
      2,
      4,
      1,
      2,
      0,
      0,
      3,
      1,
      4,
      4,
      0,
      2,
      0,
      1
    ]
  },
  {
    "id": "VNDR-0000031",
    "status": "inactive",
    "name": "CureTech Innovations",
    "tag": "Equipment, Surgical Instruments, IT Services",
    "contactName": "Nicholas Davis",
    "industryRating": 3.9,
    "procedures": "[PR-066,PR-048,PR-068,PR-050,PR-065,PR-059,PR-067,PR-070,PR-027,PR-046]",
    "contracts": "[CNTRCT-0000012,CNTRCT-0000061,CNTRCT-0000036]",
    "orders": "[ORDR-0-0000002,ORDR-1-0000048,ORDR-2-0000041,ORDR-3-0000004,ORDR-4-0000058,ORDR-5-0000055,ORDR-6-0000008,ORDR-7-0000036,ORDR-8-0000029,ORDR-9-0000053,ORDR-10-0000021,ORDR-11-0000025,ORDR-12-0000043,ORDR-13-0000026,ORDR-14-0000071,ORDR-15-0000014,ORDR-16-0000039,ORDR-17-0000038,ORDR-18-0000024]",
    "spendWeekly": [
      3051,
      3082,
      2906,
      2973,
      2952,
      3033,
      3032,
      3004,
      2950,
      2947,
      3100,
      3020,
      3050,
      2987,
      3026,
      2943,
      2936,
      3098,
      3022,
      2984,
      3041,
      3049,
      2940,
      2933,
      2925,
      3050,
      2978,
      2964,
      2957,
      2998,
      2903,
      2999,
      3003,
      3077,
      3072,
      3091,
      3025,
      3044,
      2942,
      3099,
      2997,
      2904,
      3081,
      3077,
      3045,
      2922,
      3070,
      2962,
      3100,
      2922,
      3021,
      2901
    ],
    "orderRejected": [
      2901,
      6,
      5,
      5,
      3,
      3,
      15,
      13,
      4,
      5,
      1,
      6,
      4,
      18,
      10,
      19,
      12,
      5,
      3,
      4,
      0,
      19,
      1,
      11,
      19,
      4,
      19,
      0,
      19,
      19,
      13,
      13,
      16,
      14,
      11,
      14,
      12,
      7,
      18,
      1,
      16,
      4,
      11,
      15,
      6,
      4,
      5,
      15,
      15,
      9,
      0,
      17,
      10
    ],
    "orderAccepted": [
      0,
      7,
      7,
      5,
      3,
      3,
      11,
      7,
      8,
      8,
      4,
      0,
      3,
      9,
      11,
      6,
      11,
      8,
      5,
      6,
      4,
      0,
      9,
      11,
      4,
      6,
      0,
      1,
      0,
      6,
      11,
      11,
      4,
      4,
      8,
      11,
      2,
      4,
      5,
      3,
      3,
      4,
      5,
      0,
      4,
      11,
      9,
      9,
      11,
      2,
      0,
      0
    ],
    "orderHold": [
      2,
      0,
      0,
      2,
      3,
      4,
      1,
      3,
      1,
      4,
      4,
      0,
      3,
      4,
      3,
      0,
      2,
      3,
      3,
      1,
      2,
      1,
      0,
      4,
      0,
      2,
      2,
      3,
      2,
      0,
      3,
      4,
      2,
      0,
      2,
      2,
      4,
      4,
      2,
      2,
      2,
      4,
      0,
      4,
      2,
      2,
      4,
      4,
      0,
      2,
      1,
      3
    ],
    "orderBackordered": [
      3,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      4,
      2,
      4,
      3,
      0,
      4,
      3,
      4,
      1,
      1,
      3,
      2,
      4,
      1,
      0,
      2,
      1,
      3,
      2,
      4,
      2,
      0,
      2,
      2,
      3,
      2,
      0,
      2,
      4,
      3,
      3,
      3,
      3,
      0,
      0,
      4,
      3,
      2,
      3,
      1,
      2,
      1,
      0,
      4,
      0,
      3,
      4,
      1,
      0,
      0,
      0,
      3
    ],
    "issueDelay": [
      0,
      2,
      4,
      4,
      0,
      0,
      1,
      0,
      2,
      3,
      2,
      2,
      4,
      2,
      3,
      3,
      1,
      2,
      3,
      0,
      0,
      3,
      3,
      3,
      4,
      4,
      2,
      1,
      4,
      0,
      3,
      1,
      4,
      2,
      4,
      2,
      0,
      4,
      4,
      3,
      1,
      1,
      1,
      0,
      4,
      4,
      0,
      1,
      0,
      0,
      2,
      2
    ],
    "issueQuantity": [
      1,
      1,
      2,
      0,
      2,
      1,
      4,
      1,
      0,
      0,
      2,
      2,
      0,
      0,
      3,
      1,
      2,
      4,
      0,
      4,
      0,
      2,
      4,
      2,
      0,
      4,
      2,
      4,
      1,
      4,
      2,
      1,
      2,
      4,
      0,
      3,
      2,
      1,
      2,
      3,
      4,
      2,
      4,
      1,
      4,
      1,
      2,
      4,
      0,
      1,
      0,
      3
    ]
  },
  {
    "id": "VNDR-0000032",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Nutrition, Imaging, Equipment",
    "contactName": "Dylan Weaver",
    "industryRating": 3.8,
    "procedures": "[PR-066,PR-035,PR-054,PR-056,PR-053,PR-049,PR-072,PR-031,PR-071,PR-071]",
    "contracts": "[CNTRCT-0000064,CNTRCT-0000055,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000065,ORDR-2-0000019,ORDR-3-0000005,ORDR-4-0000022,ORDR-5-0000011,ORDR-6-0000014,ORDR-7-0000022,ORDR-8-0000012,ORDR-9-0000026,ORDR-10-0000024,ORDR-11-0000043,ORDR-12-0000057,ORDR-13-0000026,ORDR-14-0000070,ORDR-15-0000023,ORDR-16-0000019,ORDR-17-0000034,ORDR-18-0000030]",
    "spendWeekly": [
      3056,
      3057,
      2927,
      2995,
      2965,
      2941,
      2980,
      3019,
      2904,
      3056,
      3027,
      2904,
      2982,
      3006,
      2972,
      2901,
      3047,
      3099,
      2915,
      2969,
      3020,
      2988,
      3007,
      3097,
      3080,
      3096,
      2936,
      3029,
      2901,
      2978,
      2981,
      2962,
      3016,
      3038,
      2988,
      2904,
      2949,
      3015,
      3099,
      3099,
      2977,
      2985,
      3097,
      2985,
      2946,
      2945,
      3099,
      2976,
      2933,
      2972,
      2905,
      2956
    ],
    "orderRejected": [
      2956,
      11,
      4,
      9,
      15,
      19,
      6,
      19,
      19,
      13,
      7,
      0,
      0,
      18,
      19,
      2,
      19,
      0,
      0,
      4,
      8,
      11,
      11,
      0,
      11,
      0,
      4,
      8,
      19,
      0,
      14,
      10,
      0,
      18,
      9,
      11,
      17,
      19,
      19,
      0,
      16,
      14,
      11,
      0,
      19,
      6,
      0,
      16,
      18,
      9,
      5,
      1,
      13
    ],
    "orderAccepted": [
      2,
      6,
      9,
      10,
      9,
      11,
      3,
      11,
      6,
      10,
      9,
      0,
      6,
      7,
      5,
      11,
      5,
      6,
      4,
      5,
      11,
      10,
      8,
      10,
      11,
      5,
      11,
      4,
      7,
      6,
      10,
      1,
      6,
      2,
      8,
      7,
      5,
      1,
      9,
      11,
      0,
      9,
      8,
      7,
      11,
      11,
      0,
      11,
      0,
      6,
      0,
      4
    ],
    "orderHold": [
      0,
      1,
      0,
      1,
      3,
      1,
      3,
      2,
      1,
      0,
      0,
      2,
      3,
      0,
      1,
      2,
      3,
      0,
      2,
      2,
      3,
      4,
      4,
      4,
      0,
      3,
      2,
      3,
      3,
      1,
      4,
      3,
      0,
      1,
      1,
      3,
      1,
      3,
      4,
      4,
      2,
      3,
      1,
      4,
      4,
      3,
      0,
      2,
      2,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      2,
      2,
      2,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      1,
      0,
      4,
      4,
      2,
      3,
      3,
      4,
      1,
      1,
      4,
      3,
      1,
      2,
      0,
      1,
      2,
      3,
      4,
      0,
      1,
      1,
      3,
      4,
      0,
      0,
      0,
      2,
      4,
      1,
      0,
      4,
      1,
      2,
      0,
      0,
      1,
      1,
      3,
      1,
      0,
      2,
      2,
      1,
      0,
      4,
      1,
      0,
      4,
      4,
      2,
      2
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      1,
      3,
      3,
      2,
      3,
      2,
      0,
      2,
      1,
      4,
      0,
      0,
      3,
      2,
      0,
      1,
      2,
      1,
      0,
      1,
      4,
      2,
      3,
      2,
      4,
      4,
      3,
      1,
      2,
      1,
      4,
      4,
      0,
      4,
      1,
      3,
      0,
      1,
      4,
      2,
      4,
      0,
      1,
      3,
      0,
      2,
      0,
      3
    ],
    "issueQuantity": [
      3,
      1,
      4,
      0,
      2,
      4,
      2,
      2,
      0,
      1,
      0,
      2,
      0,
      2,
      4,
      4,
      1,
      3,
      4,
      4,
      0,
      4,
      4,
      3,
      4,
      2,
      3,
      3,
      4,
      2,
      3,
      0,
      4,
      1,
      3,
      3,
      4,
      0,
      1,
      2,
      1,
      3,
      2,
      0,
      4,
      1,
      0,
      4,
      3,
      3,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000033",
    "status": "active",
    "name": "MediFlow Systems",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Kimberly Griffin",
    "industryRating": 4.4,
    "procedures": "[PR-043,PR-064,PR-038,PR-069,PR-064,PR-069,PR-017,PR-066,PR-047,PR-056]",
    "contracts": "[CNTRCT-0000054,CNTRCT-0000060,CNTRCT-0000058]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000042,ORDR-2-0000026,ORDR-3-0000014,ORDR-4-0000051,ORDR-5-0000047,ORDR-6-0000015,ORDR-7-0000019,ORDR-8-0000053,ORDR-9-0000033,ORDR-10-0000066,ORDR-11-0000043,ORDR-12-0000041,ORDR-13-0000012,ORDR-14-0000015,ORDR-15-0000058,ORDR-16-0000015,ORDR-17-0000031,ORDR-18-0000060]",
    "spendWeekly": [
      3001,
      2914,
      2941,
      2902,
      3038,
      3010,
      2905,
      3098,
      3091,
      2953,
      2936,
      3086,
      2923,
      2959,
      3091,
      3071,
      3063,
      3077,
      2941,
      3024,
      3094,
      3033,
      2969,
      2996,
      3079,
      3058,
      3024,
      2901,
      3069,
      2927,
      2978,
      3019,
      3039,
      2904,
      2964,
      2945,
      3019,
      2913,
      2923,
      3020,
      2981,
      3013,
      2996,
      3082,
      2902,
      2901,
      3077,
      2960,
      3086,
      3073,
      3037,
      2901
    ],
    "orderRejected": [
      2901,
      11,
      8,
      13,
      0,
      14,
      13,
      19,
      19,
      11,
      0,
      9,
      8,
      5,
      9,
      13,
      19,
      16,
      0,
      11,
      14,
      9,
      8,
      14,
      19,
      6,
      15,
      6,
      18,
      19,
      7,
      19,
      17,
      19,
      18,
      19,
      12,
      12,
      16,
      0,
      13,
      19,
      0,
      0,
      0,
      14,
      10,
      14,
      19,
      15,
      11,
      14,
      8
    ],
    "orderAccepted": [
      3,
      11,
      6,
      11,
      2,
      8,
      5,
      0,
      4,
      1,
      3,
      0,
      4,
      11,
      11,
      2,
      0,
      8,
      2,
      4,
      8,
      6,
      9,
      0,
      11,
      2,
      0,
      2,
      5,
      2,
      6,
      11,
      0,
      0,
      10,
      5,
      4,
      1,
      0,
      2,
      9,
      11,
      11,
      2,
      9,
      11,
      0,
      11,
      5,
      8,
      9,
      3
    ],
    "orderHold": [
      1,
      4,
      4,
      4,
      3,
      3,
      2,
      3,
      4,
      4,
      2,
      2,
      3,
      0,
      2,
      1,
      2,
      0,
      2,
      2,
      3,
      1,
      0,
      4,
      1,
      4,
      3,
      2,
      3,
      0,
      4,
      4,
      2,
      3,
      0,
      2,
      4,
      0,
      2,
      2,
      1,
      4,
      1,
      4,
      1,
      3,
      0,
      0,
      1,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      2,
      0,
      2,
      2,
      2,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      0,
      0,
      2,
      1,
      0,
      0,
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      1,
      0,
      4,
      4,
      0,
      3,
      0,
      4,
      2,
      2,
      2,
      0,
      4,
      0,
      0,
      2,
      1,
      2,
      3,
      1,
      2,
      1,
      0,
      3,
      3,
      4,
      2,
      2,
      4,
      0,
      1,
      1,
      2,
      3,
      0,
      4,
      1,
      3,
      3,
      4,
      0,
      2,
      1,
      2,
      1,
      3,
      3,
      4,
      2,
      2,
      1,
      3
    ],
    "issueDelay": [
      0,
      4,
      4,
      4,
      3,
      4,
      4,
      1,
      0,
      3,
      3,
      2,
      4,
      4,
      4,
      3,
      0,
      0,
      3,
      0,
      0,
      3,
      1,
      2,
      0,
      1,
      4,
      1,
      4,
      2,
      2,
      2,
      3,
      0,
      4,
      2,
      0,
      1,
      4,
      0,
      3,
      3,
      2,
      0,
      1,
      3,
      0,
      4,
      4,
      1,
      0,
      3
    ],
    "issueQuantity": [
      4,
      1,
      0,
      1,
      2,
      3,
      4,
      3,
      3,
      3,
      2,
      2,
      3,
      0,
      3,
      3,
      2,
      4,
      0,
      1,
      2,
      3,
      4,
      1,
      3,
      2,
      1,
      0,
      2,
      3,
      2,
      0,
      2,
      4,
      0,
      4,
      4,
      0,
      1,
      2,
      4,
      3,
      4,
      1,
      3,
      1,
      2,
      2,
      1,
      3,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000034",
    "status": "active",
    "name": "CarePoint Technologies",
    "tag": "Equipment, IT Services",
    "contactName": "Courtney Gomez",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-018,PR-060,PR-064,PR-053,PR-054,PR-049,PR-031,PR-071,PR-056]",
    "contracts": "[CNTRCT-0000030,CNTRCT-0000047,CNTRCT-0000030]",
    "orders": "[ORDR-0-0000030,ORDR-1-0000033,ORDR-2-0000016,ORDR-3-0000064,ORDR-4-0000012,ORDR-5-0000051,ORDR-6-0000017,ORDR-7-0000065,ORDR-8-0000021,ORDR-9-0000035,ORDR-10-0000019,ORDR-11-0000022,ORDR-12-0000070,ORDR-13-0000072,ORDR-14-0000028,ORDR-15-0000044,ORDR-16-0000019,ORDR-17-0000075,ORDR-18-0000061]",
    "spendWeekly": [
      2908,
      3026,
      3027,
      2902,
      2903,
      3061,
      3100,
      3016,
      3092,
      3075,
      2901,
      2956,
      3032,
      3036,
      3078,
      2901,
      3061,
      3099,
      2900,
      3098,
      2953,
      2999,
      2962,
      2994,
      2922,
      3016,
      2932,
      2967,
      2947,
      3026,
      3034,
      2984,
      3088,
      3050,
      3027,
      2924,
      2982,
      2946,
      3032,
      3067,
      2901,
      2970,
      3026,
      3091,
      2912,
      2901,
      2901,
      2900,
      3068,
      2905,
      3014,
      2901
    ],
    "orderRejected": [
      2901,
      19,
      19,
      1,
      8,
      11,
      10,
      12,
      16,
      19,
      0,
      12,
      1,
      18,
      0,
      14,
      8,
      0,
      3,
      19,
      4,
      9,
      0,
      10,
      11,
      16,
      8,
      4,
      8,
      19,
      19,
      0,
      19,
      19,
      10,
      0,
      11,
      19,
      19,
      19,
      19,
      3,
      17,
      17,
      15,
      1,
      0,
      7,
      15,
      0,
      0,
      8,
      19
    ],
    "orderAccepted": [
      0,
      8,
      1,
      3,
      0,
      11,
      11,
      5,
      0,
      6,
      4,
      4,
      0,
      4,
      11,
      5,
      0,
      8,
      2,
      3,
      3,
      9,
      11,
      10,
      7,
      10,
      0,
      5,
      2,
      6,
      7,
      8,
      0,
      4,
      2,
      2,
      11,
      1,
      2,
      9,
      9,
      11,
      11,
      1,
      11,
      11,
      6,
      10,
      0,
      7,
      6,
      4
    ],
    "orderHold": [
      2,
      0,
      0,
      1,
      4,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      4,
      2,
      0,
      2,
      3,
      2,
      4,
      1,
      4,
      3,
      2,
      3,
      4,
      2,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      3,
      2,
      4,
      4,
      1,
      3,
      2,
      0,
      1,
      3,
      4,
      1,
      4,
      0,
      0,
      0,
      2,
      2,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      0,
      2,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      0,
      3,
      3,
      0,
      2,
      1,
      4,
      3,
      2,
      1,
      3,
      0,
      2,
      0,
      3,
      4,
      2,
      1,
      4,
      0,
      4,
      0,
      4,
      4,
      2,
      3,
      0,
      4,
      0,
      2,
      4,
      2,
      4,
      0,
      3,
      0,
      0,
      3,
      4,
      0,
      2,
      4,
      2,
      1,
      2,
      1,
      3,
      4,
      2,
      3,
      4
    ],
    "issueDelay": [
      2,
      1,
      4,
      4,
      1,
      4,
      3,
      3,
      2,
      4,
      0,
      1,
      3,
      2,
      4,
      2,
      2,
      0,
      1,
      1,
      0,
      4,
      2,
      3,
      0,
      4,
      4,
      1,
      1,
      0,
      4,
      4,
      2,
      0,
      2,
      2,
      0,
      3,
      2,
      2,
      4,
      2,
      1,
      0,
      3,
      1,
      0,
      0,
      1,
      0,
      0,
      4
    ],
    "issueQuantity": [
      0,
      3,
      3,
      0,
      3,
      2,
      1,
      1,
      2,
      3,
      4,
      1,
      1,
      0,
      0,
      0,
      2,
      3,
      3,
      3,
      3,
      4,
      0,
      1,
      2,
      4,
      4,
      1,
      0,
      4,
      1,
      4,
      0,
      4,
      2,
      2,
      4,
      2,
      0,
      0,
      3,
      2,
      3,
      1,
      3,
      0,
      4,
      4,
      4,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000035",
    "status": "active",
    "name": "PharmaLogix International",
    "tag": "IT Services",
    "contactName": "Courtney Gomez",
    "industryRating": 2.7,
    "procedures": "[PR-044,PR-049,PR-068,PR-055,PR-059,PR-054,PR-066,PR-052,PR-052,PR-054]",
    "contracts": "[CNTRCT-0000037,CNTRCT-0000064,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000061,ORDR-1-0000060,ORDR-2-0000060,ORDR-3-0000020,ORDR-4-0000017,ORDR-5-0000063,ORDR-6-0000025,ORDR-7-0000062,ORDR-8-0000068,ORDR-9-0000018,ORDR-10-0000024,ORDR-11-0000025,ORDR-12-0000018,ORDR-13-0000059,ORDR-14-0000017,ORDR-15-0000024,ORDR-16-0000030,ORDR-17-0000032,ORDR-18-0000020]",
    "spendWeekly": [
      3097,
      2986,
      2927,
      2976,
      2961,
      2905,
      3063,
      3098,
      2902,
      3037,
      3001,
      3003,
      3003,
      2901,
      3091,
      2901,
      2903,
      2992,
      2954,
      2913,
      3100,
      3086,
      2968,
      3097,
      3040,
      3096,
      2909,
      3025,
      3034,
      2902,
      3019,
      3040,
      2900,
      2943,
      3013,
      2916,
      3063,
      2902,
      3093,
      3016,
      2971,
      2957,
      3022,
      3053,
      2955,
      3012,
      3019,
      2923,
      3014,
      2910,
      2970,
      2901
    ],
    "orderRejected": [
      2901,
      2,
      9,
      10,
      19,
      10,
      0,
      9,
      1,
      18,
      16,
      7,
      3,
      17,
      12,
      14,
      16,
      0,
      11,
      0,
      10,
      8,
      12,
      19,
      5,
      5,
      0,
      0,
      10,
      4,
      15,
      13,
      0,
      9,
      10,
      11,
      8,
      11,
      17,
      6,
      2,
      19,
      14,
      6,
      19,
      4,
      0,
      10,
      17,
      1,
      6,
      5,
      19
    ],
    "orderAccepted": [
      11,
      3,
      1,
      6,
      10,
      0,
      6,
      0,
      2,
      3,
      3,
      4,
      11,
      11,
      11,
      0,
      4,
      2,
      6,
      6,
      6,
      7,
      4,
      7,
      1,
      11,
      10,
      4,
      0,
      8,
      8,
      5,
      1,
      0,
      5,
      0,
      10,
      8,
      11,
      6,
      0,
      6,
      7,
      0,
      4,
      11,
      5,
      11,
      1,
      6,
      5,
      6
    ],
    "orderHold": [
      2,
      0,
      1,
      3,
      0,
      3,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      3,
      1,
      3,
      0,
      3,
      4,
      0,
      0,
      2,
      2,
      4,
      2,
      4,
      3,
      2,
      4,
      0,
      3,
      3,
      0,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      3,
      2,
      2,
      2,
      2,
      3,
      0,
      1,
      0,
      2,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      2,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1
    ],
    "issueQuality": [
      0,
      0,
      3,
      4,
      4,
      3,
      0,
      3,
      0,
      0,
      0,
      4,
      0,
      3,
      4,
      0,
      4,
      2,
      0,
      1,
      0,
      0,
      2,
      1,
      2,
      3,
      2,
      2,
      4,
      3,
      1,
      4,
      0,
      2,
      3,
      1,
      1,
      0,
      2,
      3,
      1,
      2,
      0,
      4,
      0,
      4,
      3,
      1,
      3,
      0,
      3,
      2
    ],
    "issueDelay": [
      2,
      4,
      3,
      0,
      1,
      2,
      2,
      3,
      2,
      4,
      2,
      3,
      1,
      4,
      0,
      0,
      4,
      0,
      2,
      1,
      3,
      2,
      2,
      4,
      4,
      0,
      4,
      0,
      4,
      1,
      1,
      2,
      3,
      0,
      4,
      4,
      1,
      0,
      4,
      2,
      2,
      0,
      4,
      1,
      4,
      3,
      0,
      0,
      2,
      3,
      0,
      3
    ],
    "issueQuantity": [
      4,
      3,
      4,
      2,
      1,
      3,
      2,
      4,
      0,
      2,
      4,
      0,
      0,
      3,
      0,
      3,
      3,
      2,
      4,
      3,
      2,
      1,
      3,
      2,
      2,
      2,
      3,
      4,
      4,
      0,
      0,
      3,
      2,
      2,
      1,
      0,
      4,
      0,
      1,
      2,
      4,
      3,
      4,
      1,
      4,
      4,
      4,
      4,
      0,
      3,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000036",
    "status": "active",
    "name": "BioVital Supplies",
    "tag": "Equipment, Facilities Management",
    "contactName": "Nicole Wallace",
    "industryRating": 3.8,
    "procedures": "[PR-056,PR-055,PR-029,PR-045,PR-056,PR-068,PR-030,PR-045,PR-071,PR-039]",
    "contracts": "[CNTRCT-0000039,CNTRCT-0000047,CNTRCT-0000049]",
    "orders": "[ORDR-0-0000060,ORDR-1-0000065,ORDR-2-0000031,ORDR-3-0000035,ORDR-4-0000004,ORDR-5-0000027,ORDR-6-0000048,ORDR-7-0000039,ORDR-8-0000053,ORDR-9-0000008,ORDR-10-0000022,ORDR-11-0000063,ORDR-12-0000074,ORDR-13-0000054,ORDR-14-0000068,ORDR-15-0000070,ORDR-16-0000061,ORDR-17-0000020,ORDR-18-0000056]",
    "spendWeekly": [
      2965,
      3016,
      2906,
      3046,
      2959,
      2905,
      3100,
      2993,
      3079,
      2959,
      3082,
      2904,
      2945,
      2990,
      2903,
      2946,
      2903,
      3026,
      3005,
      2947,
      3100,
      2994,
      2949,
      3041,
      3095,
      2945,
      3030,
      3045,
      3029,
      3030,
      2985,
      3007,
      3039,
      2933,
      3019,
      2971,
      2972,
      3018,
      3045,
      3077,
      3053,
      2945,
      3041,
      3041,
      2970,
      2901,
      2986,
      2925,
      2945,
      2914,
      3013,
      3097
    ],
    "orderRejected": [
      3097,
      19,
      13,
      12,
      19,
      19,
      0,
      5,
      2,
      15,
      15,
      0,
      14,
      18,
      9,
      14,
      19,
      6,
      12,
      0,
      4,
      15,
      12,
      8,
      10,
      10,
      1,
      0,
      13,
      10,
      13,
      17,
      6,
      17,
      11,
      15,
      0,
      0,
      19,
      14,
      19,
      16,
      19,
      10,
      0,
      11,
      9,
      0,
      9,
      8,
      4,
      13,
      7
    ],
    "orderAccepted": [
      5,
      0,
      3,
      7,
      1,
      2,
      7,
      7,
      0,
      11,
      8,
      1,
      11,
      8,
      10,
      4,
      10,
      10,
      0,
      3,
      0,
      5,
      8,
      5,
      8,
      11,
      0,
      11,
      6,
      0,
      7,
      7,
      0,
      0,
      3,
      11,
      6,
      11,
      11,
      0,
      1,
      11,
      10,
      4,
      2,
      4,
      4,
      11,
      6,
      0,
      0,
      5
    ],
    "orderHold": [
      2,
      1,
      3,
      2,
      1,
      2,
      4,
      2,
      3,
      2,
      4,
      2,
      2,
      2,
      2,
      3,
      2,
      2,
      4,
      1,
      0,
      3,
      3,
      1,
      1,
      1,
      3,
      1,
      4,
      3,
      2,
      1,
      2,
      4,
      0,
      2,
      0,
      4,
      4,
      1,
      0,
      0,
      0,
      0,
      2,
      1,
      3,
      2,
      4,
      0,
      3,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      2,
      0,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      0,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      4,
      2,
      0,
      3,
      4,
      1,
      2,
      2,
      4,
      2,
      4,
      2,
      0,
      3,
      1,
      2,
      0,
      1,
      1,
      3,
      3,
      3,
      3,
      2,
      1,
      0,
      2,
      3,
      0,
      1,
      4,
      0,
      0,
      0,
      2,
      1,
      2,
      4,
      2,
      4,
      4,
      1,
      4
    ],
    "issueDelay": [
      0,
      4,
      3,
      4,
      1,
      3,
      2,
      1,
      4,
      1,
      2,
      2,
      1,
      0,
      4,
      0,
      3,
      0,
      1,
      3,
      4,
      1,
      0,
      2,
      0,
      3,
      2,
      2,
      2,
      4,
      0,
      0,
      1,
      2,
      3,
      0,
      0,
      3,
      2,
      2,
      0,
      4,
      4,
      2,
      2,
      1,
      2,
      1,
      1,
      4,
      2,
      3
    ],
    "issueQuantity": [
      3,
      3,
      0,
      2,
      3,
      3,
      0,
      4,
      0,
      2,
      3,
      0,
      0,
      3,
      3,
      1,
      3,
      4,
      3,
      1,
      0,
      1,
      1,
      3,
      0,
      4,
      1,
      3,
      2,
      3,
      4,
      4,
      4,
      4,
      4,
      3,
      4,
      1,
      1,
      2,
      4,
      3,
      1,
      0,
      4,
      2,
      2,
      0,
      0,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000037",
    "status": "active",
    "name": "BioVital Supplies",
    "tag": "Imaging",
    "contactName": "Luis Mccarthy",
    "industryRating": 3.5,
    "procedures": "[PR-055,PR-056,PR-060,PR-065,PR-056,PR-064,PR-036,PR-072,PR-071,PR-074]",
    "contracts": "[CNTRCT-0000040,CNTRCT-0000049,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000030,ORDR-1-0000047,ORDR-2-0000065,ORDR-3-0000029,ORDR-4-0000052,ORDR-5-0000061,ORDR-6-0000028,ORDR-7-0000016,ORDR-8-0000038,ORDR-9-0000048,ORDR-10-0000017,ORDR-11-0000041,ORDR-12-0000023,ORDR-13-0000014,ORDR-14-0000057,ORDR-15-0000040,ORDR-16-0000050,ORDR-17-0000027,ORDR-18-0000052]",
    "spendWeekly": [
      3086,
      3082,
      3055,
      2902,
      2904,
      2982,
      3100,
      3098,
      3045,
      2986,
      3073,
      2904,
      3021,
      3011,
      3091,
      3009,
      2970,
      3099,
      2900,
      3011,
      3100,
      3029,
      2930,
      3062,
      2938,
      3096,
      2975,
      3038,
      2913,
      2980,
      3022,
      2979,
      2941,
      3038,
      3028,
      2904,
      2928,
      3097,
      2918,
      3065,
      2933,
      2968,
      3097,
      3064,
      3022,
      3022,
      3046,
      2904,
      3100,
      2956,
      3071,
      2901
    ],
    "orderRejected": [
      2901,
      19,
      6,
      4,
      14,
      15,
      18,
      9,
      19,
      19,
      6,
      19,
      16,
      17,
      8,
      13,
      19,
      6,
      11,
      9,
      17,
      15,
      4,
      11,
      9,
      19,
      0,
      0,
      12,
      14,
      13,
      11,
      19,
      9,
      7,
      1,
      7,
      0,
      9,
      4,
      19,
      19,
      14,
      9,
      14,
      2,
      2,
      14,
      19,
      19,
      7,
      0,
      19
    ],
    "orderAccepted": [
      11,
      7,
      5,
      6,
      1,
      3,
      7,
      1,
      1,
      3,
      7,
      7,
      11,
      0,
      8,
      8,
      0,
      7,
      5,
      2,
      0,
      7,
      11,
      8,
      6,
      4,
      0,
      8,
      6,
      11,
      0,
      9,
      0,
      3,
      8,
      1,
      11,
      2,
      11,
      3,
      6,
      11,
      11,
      3,
      5,
      9,
      2,
      6,
      7,
      8,
      0,
      8
    ],
    "orderHold": [
      3,
      4,
      4,
      2,
      1,
      4,
      2,
      2,
      2,
      1,
      4,
      0,
      2,
      4,
      0,
      0,
      2,
      3,
      0,
      1,
      3,
      2,
      1,
      4,
      0,
      2,
      0,
      2,
      2,
      0,
      2,
      4,
      0,
      1,
      4,
      2,
      2,
      0,
      1,
      4,
      3,
      0,
      0,
      1,
      3,
      3,
      2,
      1,
      0,
      0,
      2,
      0
    ],
    "orderBackordered": [
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      0,
      4,
      0,
      4,
      0,
      4,
      4,
      4,
      0,
      1,
      2,
      0,
      1,
      1,
      4,
      1,
      0,
      4,
      2,
      0,
      3,
      2,
      4,
      2,
      4,
      0,
      1,
      0,
      4,
      2,
      1,
      0,
      2,
      4,
      0,
      2,
      0,
      4,
      0,
      4,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      3,
      3,
      2
    ],
    "issueDelay": [
      1,
      4,
      4,
      2,
      1,
      2,
      0,
      0,
      1,
      3,
      1,
      3,
      2,
      2,
      4,
      2,
      4,
      0,
      0,
      2,
      2,
      2,
      0,
      3,
      0,
      1,
      3,
      0,
      1,
      4,
      2,
      2,
      3,
      0,
      4,
      1,
      0,
      1,
      1,
      4,
      1,
      0,
      2,
      1,
      4,
      0,
      0,
      0,
      1,
      2,
      0,
      3
    ],
    "issueQuantity": [
      0,
      0,
      1,
      0,
      3,
      1,
      4,
      3,
      4,
      2,
      3,
      4,
      0,
      0,
      1,
      2,
      1,
      2,
      0,
      4,
      0,
      4,
      2,
      3,
      1,
      1,
      3,
      1,
      2,
      2,
      4,
      1,
      4,
      1,
      2,
      3,
      4,
      1,
      0,
      3,
      3,
      2,
      3,
      2,
      2,
      1,
      2,
      3,
      3,
      3,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000038",
    "status": "active",
    "name": "CarePoint Technologies",
    "tag": "Surgical Instruments, Pharmaceuticals",
    "contactName": "Jennifer Harris",
    "industryRating": 3.7,
    "procedures": "[PR-047,PR-026,PR-061,PR-066,PR-059,PR-070,PR-064,PR-039,PR-073,PR-060]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000041,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000011,ORDR-2-0000064,ORDR-3-0000043,ORDR-4-0000060,ORDR-5-0000058,ORDR-6-0000023,ORDR-7-0000046,ORDR-8-0000039,ORDR-9-0000039,ORDR-10-0000033,ORDR-11-0000066,ORDR-12-0000041,ORDR-13-0000016,ORDR-14-0000074,ORDR-15-0000024,ORDR-16-0000049,ORDR-17-0000074,ORDR-18-0000029]",
    "spendWeekly": [
      3033,
      2937,
      2923,
      2935,
      2987,
      2905,
      2913,
      2907,
      3001,
      3018,
      3100,
      2981,
      2952,
      2988,
      2979,
      2901,
      2973,
      3001,
      3008,
      3022,
      3040,
      2990,
      2900,
      3038,
      2906,
      2950,
      3095,
      2901,
      2996,
      2902,
      3018,
      2960,
      2941,
      3018,
      2913,
      2904,
      2907,
      3028,
      2944,
      2973,
      2928,
      2908,
      2935,
      2974,
      2956,
      2901,
      3024,
      3047,
      3007,
      3022,
      2989,
      3029
    ],
    "orderRejected": [
      3029,
      16,
      1,
      2,
      12,
      19,
      6,
      5,
      15,
      19,
      18,
      10,
      6,
      8,
      0,
      16,
      17,
      1,
      2,
      0,
      0,
      8,
      5,
      17,
      16,
      9,
      17,
      19,
      18,
      13,
      12,
      12,
      19,
      17,
      19,
      16,
      0,
      6,
      19,
      2,
      9,
      5,
      3,
      13,
      15,
      5,
      15,
      16,
      9,
      12,
      0,
      19,
      19
    ],
    "orderAccepted": [
      9,
      4,
      3,
      9,
      5,
      0,
      5,
      9,
      10,
      8,
      1,
      6,
      0,
      0,
      8,
      1,
      8,
      11,
      9,
      5,
      11,
      5,
      6,
      1,
      6,
      6,
      0,
      8,
      5,
      0,
      11,
      6,
      4,
      0,
      0,
      8,
      0,
      5,
      9,
      3,
      11,
      11,
      5,
      1,
      0,
      11,
      5,
      5,
      6,
      2,
      6,
      8
    ],
    "orderHold": [
      0,
      2,
      2,
      0,
      2,
      2,
      2,
      2,
      3,
      1,
      3,
      1,
      1,
      4,
      2,
      3,
      4,
      4,
      4,
      3,
      3,
      2,
      0,
      4,
      4,
      3,
      3,
      2,
      4,
      4,
      2,
      0,
      4,
      0,
      1,
      2,
      2,
      4,
      3,
      4,
      3,
      0,
      1,
      4,
      1,
      1,
      1,
      0,
      4,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      0,
      2,
      0,
      0,
      2,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      1,
      3,
      2,
      2,
      1,
      3,
      1,
      1,
      2,
      1,
      4,
      0,
      0,
      3,
      0,
      3,
      1,
      2,
      0,
      2,
      2,
      3,
      3,
      4,
      3,
      1,
      4,
      3,
      2,
      3,
      1,
      4,
      2,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      2,
      4,
      3,
      4,
      4,
      0,
      3,
      3,
      0,
      4
    ],
    "issueDelay": [
      4,
      0,
      0,
      4,
      1,
      4,
      1,
      2,
      2,
      4,
      1,
      0,
      2,
      0,
      0,
      3,
      1,
      1,
      3,
      3,
      2,
      2,
      1,
      3,
      0,
      2,
      0,
      2,
      2,
      2,
      3,
      1,
      1,
      3,
      0,
      4,
      0,
      4,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      0,
      4,
      4,
      2,
      3,
      3
    ],
    "issueQuantity": [
      2,
      4,
      3,
      4,
      3,
      0,
      3,
      2,
      0,
      4,
      1,
      2,
      1,
      0,
      4,
      4,
      4,
      3,
      2,
      2,
      2,
      3,
      3,
      2,
      3,
      2,
      2,
      4,
      2,
      3,
      1,
      3,
      1,
      3,
      2,
      4,
      2,
      2,
      2,
      3,
      0,
      3,
      1,
      0,
      1,
      2,
      1,
      4,
      3,
      2,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000039",
    "status": "inactive",
    "name": "PharmaSphere Equipment",
    "tag": "IT Services",
    "contactName": "Michael Montgomery",
    "industryRating": 3.9,
    "procedures": "[PR-061,PR-065,PR-038,PR-065,PR-056,PR-057,PR-055,PR-043,PR-068,PR-056]",
    "contracts": "[CNTRCT-0000039,CNTRCT-0000042,CNTRCT-0000041]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000028,ORDR-2-0000026,ORDR-3-0000021,ORDR-4-0000008,ORDR-5-0000017,ORDR-6-0000041,ORDR-7-0000028,ORDR-8-0000050,ORDR-9-0000010,ORDR-10-0000019,ORDR-11-0000027,ORDR-12-0000015,ORDR-13-0000027,ORDR-14-0000017,ORDR-15-0000071,ORDR-16-0000019,ORDR-17-0000023,ORDR-18-0000058]",
    "spendWeekly": [
      3097,
      3082,
      2946,
      2935,
      2903,
      2997,
      2955,
      2994,
      3100,
      3059,
      3066,
      3055,
      2961,
      3003,
      2944,
      2906,
      2903,
      3099,
      3099,
      2909,
      3069,
      3097,
      3080,
      2935,
      3039,
      3070,
      3009,
      3005,
      2950,
      3097,
      2985,
      2954,
      3038,
      2953,
      2985,
      3029,
      2937,
      3030,
      2956,
      3054,
      2911,
      3041,
      3097,
      3054,
      3056,
      2940,
      3071,
      2900,
      3000,
      3022,
      2905,
      2939
    ],
    "orderRejected": [
      2939,
      7,
      6,
      16,
      19,
      13,
      0,
      12,
      13,
      15,
      6,
      1,
      13,
      10,
      11,
      0,
      19,
      1,
      0,
      9,
      12,
      16,
      16,
      18,
      19,
      14,
      2,
      1,
      19,
      6,
      19,
      13,
      19,
      19,
      19,
      17,
      19,
      11,
      6,
      11,
      3,
      10,
      3,
      0,
      12,
      14,
      4,
      6,
      17,
      3,
      0,
      0,
      19
    ],
    "orderAccepted": [
      10,
      8,
      1,
      11,
      11,
      5,
      2,
      6,
      2,
      2,
      2,
      11,
      6,
      0,
      0,
      5,
      2,
      7,
      6,
      6,
      7,
      0,
      3,
      1,
      8,
      6,
      0,
      5,
      0,
      2,
      5,
      7,
      0,
      8,
      3,
      11,
      9,
      9,
      11,
      11,
      5,
      11,
      11,
      0,
      3,
      4,
      5,
      10,
      9,
      4,
      0,
      0
    ],
    "orderHold": [
      0,
      2,
      4,
      0,
      0,
      2,
      3,
      1,
      1,
      2,
      1,
      1,
      3,
      2,
      4,
      4,
      2,
      1,
      0,
      0,
      2,
      4,
      2,
      4,
      0,
      2,
      3,
      2,
      3,
      3,
      3,
      4,
      3,
      2,
      0,
      2,
      4,
      0,
      4,
      4,
      1,
      0,
      3,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      0,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      0,
      0,
      0,
      2,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      3,
      0,
      1,
      2,
      3,
      3,
      4,
      1,
      4,
      2,
      1,
      3,
      0,
      4,
      2,
      2,
      0,
      0,
      3,
      3,
      1,
      0,
      1,
      2,
      3,
      4,
      4,
      2,
      2,
      3,
      3,
      1,
      2,
      1,
      2,
      4,
      0,
      2,
      1,
      3,
      1,
      3,
      0,
      4,
      4,
      2,
      0,
      0
    ],
    "issueDelay": [
      1,
      0,
      2,
      4,
      0,
      4,
      3,
      1,
      1,
      2,
      0,
      2,
      1,
      3,
      0,
      1,
      4,
      1,
      4,
      4,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      3,
      4,
      1,
      3,
      2,
      4,
      1,
      0,
      3,
      1,
      3,
      3,
      2,
      1,
      1,
      4,
      2,
      3,
      0,
      4,
      0,
      3,
      4,
      3,
      1
    ],
    "issueQuantity": [
      3,
      0,
      1,
      1,
      2,
      3,
      0,
      1,
      0,
      3,
      1,
      2,
      3,
      2,
      1,
      1,
      3,
      0,
      2,
      4,
      1,
      2,
      0,
      0,
      2,
      4,
      0,
      3,
      2,
      1,
      3,
      0,
      4,
      1,
      0,
      0,
      3,
      0,
      0,
      1,
      4,
      4,
      4,
      3,
      0,
      2,
      4,
      3,
      2,
      4,
      3,
      0
    ]
  },
  {
    "id": "VNDR-0000040",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Nutrition, Imaging, Laboratory Supplies",
    "contactName": "Julie Sanders",
    "industryRating": 2.8,
    "procedures": "[PR-060,PR-063,PR-055,PR-050,PR-045,PR-059,PR-034,PR-072,PR-056,PR-051]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000051,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000012,ORDR-1-0000013,ORDR-2-0000013,ORDR-3-0000037,ORDR-4-0000058,ORDR-5-0000019,ORDR-6-0000027,ORDR-7-0000063,ORDR-8-0000054,ORDR-9-0000064,ORDR-10-0000035,ORDR-11-0000074,ORDR-12-0000064,ORDR-13-0000041,ORDR-14-0000049,ORDR-15-0000038,ORDR-16-0000050,ORDR-17-0000079,ORDR-18-0000082]",
    "spendWeekly": [
      2948,
      2913,
      3065,
      2955,
      2903,
      3002,
      3074,
      3098,
      2930,
      3032,
      3000,
      2904,
      2909,
      3094,
      3091,
      3031,
      3079,
      3043,
      2952,
      3098,
      3078,
      2985,
      2990,
      2903,
      2916,
      3085,
      3024,
      3096,
      2957,
      2904,
      2903,
      3022,
      3049,
      3093,
      3012,
      2993,
      3084,
      3097,
      3099,
      3099,
      3045,
      2930,
      3005,
      2989,
      2956,
      2901,
      3099,
      2963,
      3083,
      2968,
      2980,
      2998
    ],
    "orderRejected": [
      2998,
      14,
      15,
      3,
      17,
      19,
      14,
      12,
      16,
      11,
      0,
      13,
      7,
      14,
      15,
      15,
      18,
      0,
      0,
      19,
      16,
      19,
      6,
      10,
      19,
      7,
      9,
      0,
      0,
      19,
      17,
      19,
      0,
      19,
      15,
      12,
      19,
      19,
      19,
      0,
      6,
      19,
      6,
      0,
      10,
      12,
      16,
      12,
      5,
      19,
      3,
      4,
      9
    ],
    "orderAccepted": [
      0,
      2,
      5,
      3,
      5,
      3,
      11,
      3,
      0,
      3,
      11,
      0,
      11,
      10,
      11,
      11,
      6,
      6,
      2,
      2,
      6,
      1,
      7,
      7,
      0,
      4,
      10,
      9,
      1,
      4,
      0,
      0,
      0,
      0,
      10,
      6,
      8,
      2,
      11,
      6,
      2,
      1,
      5,
      8,
      2,
      1,
      1,
      11,
      4,
      11,
      8,
      11
    ],
    "orderHold": [
      3,
      3,
      3,
      3,
      0,
      1,
      4,
      3,
      4,
      0,
      4,
      0,
      4,
      2,
      2,
      3,
      1,
      2,
      4,
      0,
      3,
      2,
      2,
      4,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      3,
      0,
      3,
      3,
      4,
      4,
      2,
      3,
      2,
      2,
      3,
      0,
      4,
      0,
      0,
      0,
      3,
      4,
      2,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      0,
      2,
      2,
      2,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      0,
      0,
      0,
      2,
      0,
      3,
      2,
      4,
      1,
      4,
      4,
      1,
      2,
      3,
      0,
      1,
      4,
      2,
      4,
      4,
      3,
      0,
      2,
      4,
      0,
      3,
      3,
      2,
      4,
      3,
      3,
      3,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      4,
      0,
      4,
      3,
      0,
      0,
      2,
      0,
      4
    ],
    "issueDelay": [
      1,
      3,
      2,
      4,
      0,
      2,
      2,
      2,
      1,
      2,
      2,
      0,
      4,
      4,
      2,
      4,
      0,
      1,
      2,
      4,
      2,
      2,
      4,
      4,
      1,
      1,
      3,
      0,
      2,
      4,
      4,
      1,
      4,
      0,
      4,
      1,
      1,
      4,
      4,
      1,
      3,
      0,
      0,
      0,
      4,
      0,
      0,
      0,
      0,
      1,
      0,
      4
    ],
    "issueQuantity": [
      3,
      0,
      3,
      0,
      4,
      3,
      4,
      4,
      3,
      0,
      0,
      4,
      0,
      1,
      3,
      2,
      0,
      0,
      0,
      4,
      0,
      2,
      0,
      3,
      3,
      4,
      2,
      1,
      0,
      2,
      4,
      4,
      2,
      2,
      2,
      4,
      2,
      0,
      4,
      4,
      2,
      4,
      4,
      0,
      4,
      0,
      4,
      4,
      0,
      4,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000041",
    "status": "inactive",
    "name": "CureTech Innovations",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Ralph Valdez",
    "industryRating": 2.1,
    "procedures": "[PR-044,PR-049,PR-056,PR-049,PR-061,PR-065,PR-054,PR-070,PR-046,PR-035]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000042,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000036,ORDR-1-0000014,ORDR-2-0000006,ORDR-3-0000020,ORDR-4-0000044,ORDR-5-0000059,ORDR-6-0000014,ORDR-7-0000042,ORDR-8-0000012,ORDR-9-0000040,ORDR-10-0000066,ORDR-11-0000012,ORDR-12-0000071,ORDR-13-0000074,ORDR-14-0000027,ORDR-15-0000070,ORDR-16-0000033,ORDR-17-0000022,ORDR-18-0000067]",
    "spendWeekly": [
      3060,
      3007,
      2906,
      3047,
      3056,
      2905,
      2965,
      2903,
      3078,
      2970,
      2959,
      2955,
      2923,
      2901,
      2903,
      3032,
      3037,
      3099,
      2929,
      2946,
      2949,
      3014,
      2900,
      2969,
      2919,
      3088,
      2920,
      2930,
      2955,
      2993,
      2973,
      2986,
      3061,
      3044,
      2989,
      3024,
      2951,
      2935,
      3057,
      3099,
      2974,
      2994,
      3097,
      2951,
      2928,
      2942,
      2973,
      2952,
      3100,
      2905,
      2959,
      2901
    ],
    "orderRejected": [
      2901,
      0,
      18,
      0,
      8,
      12,
      0,
      5,
      11,
      10,
      1,
      12,
      5,
      18,
      8,
      7,
      14,
      4,
      0,
      9,
      7,
      19,
      5,
      16,
      14,
      14,
      15,
      0,
      14,
      19,
      5,
      19,
      19,
      14,
      7,
      12,
      0,
      14,
      9,
      15,
      19,
      19,
      16,
      0,
      6,
      0,
      9,
      17,
      12,
      17,
      8,
      3,
      19
    ],
    "orderAccepted": [
      10,
      9,
      4,
      10,
      2,
      11,
      11,
      5,
      1,
      4,
      5,
      4,
      7,
      7,
      11,
      11,
      1,
      3,
      0,
      5,
      1,
      6,
      8,
      7,
      8,
      1,
      0,
      1,
      4,
      6,
      3,
      3,
      0,
      8,
      9,
      9,
      4,
      0,
      9,
      1,
      1,
      0,
      5,
      5,
      11,
      2,
      6,
      11,
      0,
      0,
      5,
      11
    ],
    "orderHold": [
      2,
      4,
      0,
      1,
      1,
      1,
      2,
      4,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      4,
      2,
      3,
      2,
      2,
      0,
      3,
      4,
      3,
      2,
      3,
      2,
      2,
      3,
      0,
      0,
      2,
      1,
      4,
      4,
      3,
      4,
      1,
      2,
      4,
      1,
      1,
      1,
      4,
      3,
      3,
      2,
      3,
      0,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      0,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1
    ],
    "issueQuality": [
      0,
      0,
      2,
      4,
      0,
      4,
      0,
      4,
      0,
      4,
      4,
      1,
      3,
      1,
      0,
      4,
      4,
      0,
      1,
      4,
      0,
      2,
      3,
      1,
      2,
      1,
      2,
      2,
      4,
      0,
      2,
      4,
      0,
      0,
      3,
      2,
      0,
      0,
      2,
      3,
      0,
      0,
      1,
      4,
      3,
      2,
      4,
      1,
      4,
      4,
      0,
      1
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      0,
      4,
      4,
      0,
      3,
      3,
      0,
      3,
      0,
      4,
      3,
      1,
      4,
      2,
      2,
      4,
      2,
      2,
      0,
      2,
      2,
      4,
      3,
      0,
      1,
      2,
      4,
      4,
      3,
      2,
      4,
      1,
      0,
      4,
      0,
      4,
      0,
      0,
      1,
      0,
      4,
      1,
      2,
      2,
      3,
      3,
      3,
      1
    ],
    "issueQuantity": [
      3,
      3,
      3,
      1,
      1,
      0,
      2,
      1,
      4,
      2,
      4,
      3,
      0,
      1,
      2,
      3,
      0,
      1,
      1,
      4,
      0,
      1,
      1,
      4,
      0,
      4,
      4,
      0,
      0,
      2,
      1,
      2,
      4,
      2,
      2,
      1,
      2,
      4,
      0,
      4,
      2,
      4,
      3,
      0,
      4,
      3,
      4,
      4,
      0,
      4,
      1,
      0
    ]
  },
  {
    "id": "VNDR-0000042",
    "status": "active",
    "name": "BioCare Distribution",
    "tag": "Laboratory Supplies, Medical Supplies",
    "contactName": "Aaron Stevenson",
    "industryRating": 2.9,
    "procedures": "[PR-039,PR-066,PR-027,PR-065,PR-064,PR-055,PR-063,PR-027,PR-049,PR-071]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000040,CNTRCT-0000009]",
    "orders": "[ORDR-0-0000037,ORDR-1-0000008,ORDR-2-0000044,ORDR-3-0000027,ORDR-4-0000010,ORDR-5-0000059,ORDR-6-0000053,ORDR-7-0000017,ORDR-8-0000043,ORDR-9-0000070,ORDR-10-0000050,ORDR-11-0000013,ORDR-12-0000027,ORDR-13-0000061,ORDR-14-0000038,ORDR-15-0000070,ORDR-16-0000046,ORDR-17-0000057,ORDR-18-0000034]",
    "spendWeekly": [
      3025,
      3019,
      2906,
      2943,
      3015,
      2905,
      2979,
      3098,
      3000,
      2902,
      3024,
      2964,
      2949,
      3008,
      3091,
      2980,
      2980,
      3045,
      2977,
      2909,
      3069,
      3019,
      3028,
      3019,
      3095,
      3096,
      2919,
      3028,
      2925,
      3064,
      2903,
      2901,
      3016,
      3017,
      2976,
      2909,
      3062,
      3024,
      3086,
      3065,
      2901,
      2955,
      2987,
      3091,
      2902,
      2901,
      2901,
      2900,
      3032,
      2905,
      2932,
      3066
    ],
    "orderRejected": [
      3066,
      19,
      0,
      12,
      8,
      0,
      14,
      10,
      7,
      19,
      16,
      11,
      7,
      0,
      13,
      19,
      19,
      10,
      0,
      18,
      19,
      18,
      11,
      16,
      12,
      6,
      3,
      10,
      5,
      19,
      2,
      11,
      0,
      19,
      19,
      13,
      0,
      12,
      12,
      3,
      19,
      14,
      19,
      0,
      7,
      0,
      3,
      12,
      0,
      11,
      8,
      9,
      8
    ],
    "orderAccepted": [
      8,
      1,
      1,
      4,
      5,
      7,
      4,
      1,
      0,
      5,
      0,
      11,
      9,
      4,
      11,
      10,
      0,
      6,
      7,
      6,
      7,
      4,
      0,
      7,
      9,
      6,
      0,
      5,
      6,
      11,
      0,
      10,
      0,
      6,
      1,
      10,
      7,
      4,
      11,
      6,
      5,
      10,
      11,
      8,
      11,
      3,
      0,
      9,
      5,
      9,
      5,
      10
    ],
    "orderHold": [
      2,
      1,
      2,
      4,
      2,
      0,
      4,
      4,
      1,
      0,
      0,
      1,
      1,
      4,
      1,
      3,
      0,
      0,
      4,
      2,
      4,
      2,
      0,
      4,
      1,
      0,
      1,
      1,
      3,
      0,
      0,
      2,
      1,
      4,
      3,
      4,
      4,
      3,
      2,
      1,
      0,
      0,
      0,
      0,
      2,
      4,
      4,
      4,
      0,
      1,
      0,
      4
    ],
    "orderBackordered": [
      4,
      0,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      0,
      0,
      1,
      2,
      1,
      0,
      1,
      1,
      0,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      2,
      1,
      2,
      1,
      0,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      3,
      4,
      0,
      1,
      2,
      0,
      3,
      2,
      0,
      1,
      0,
      0,
      2,
      1,
      2,
      4,
      1,
      0,
      1,
      1,
      0,
      4,
      0,
      2,
      1,
      4,
      3,
      4,
      3,
      0,
      3,
      3,
      2,
      3,
      3,
      4,
      1,
      1,
      1,
      3,
      4,
      2,
      1,
      2,
      4,
      0,
      2,
      0
    ],
    "issueDelay": [
      4,
      2,
      4,
      1,
      0,
      2,
      2,
      3,
      0,
      4,
      0,
      0,
      4,
      0,
      0,
      1,
      2,
      0,
      3,
      3,
      1,
      0,
      2,
      2,
      4,
      0,
      4,
      1,
      0,
      3,
      4,
      4,
      2,
      2,
      3,
      1,
      3,
      4,
      1,
      4,
      0,
      1,
      4,
      0,
      4,
      0,
      4,
      1,
      2,
      0,
      0,
      3
    ],
    "issueQuantity": [
      3,
      1,
      2,
      0,
      2,
      0,
      3,
      1,
      0,
      0,
      2,
      2,
      0,
      4,
      2,
      4,
      3,
      2,
      3,
      4,
      3,
      3,
      2,
      3,
      2,
      4,
      2,
      4,
      4,
      1,
      3,
      2,
      0,
      2,
      3,
      2,
      4,
      0,
      4,
      4,
      4,
      4,
      2,
      3,
      4,
      2,
      3,
      2,
      1,
      2,
      2,
      0
    ]
  },
  {
    "id": "VNDR-0000043",
    "status": "active",
    "name": "HealthCraft Logistics",
    "tag": "Pharmaceuticals, Laboratory Supplies, Medical Supplies",
    "contactName": "Ashley Anderson",
    "industryRating": 2.1,
    "procedures": "[PR-051,PR-022,PR-066,PR-032,PR-047,PR-057,PR-054,PR-072,PR-063,PR-025]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000052,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000029,ORDR-1-0000028,ORDR-2-0000007,ORDR-3-0000023,ORDR-4-0000054,ORDR-5-0000009,ORDR-6-0000038,ORDR-7-0000065,ORDR-8-0000053,ORDR-9-0000037,ORDR-10-0000024,ORDR-11-0000035,ORDR-12-0000040,ORDR-13-0000057,ORDR-14-0000055,ORDR-15-0000046,ORDR-16-0000065,ORDR-17-0000022,ORDR-18-0000041]",
    "spendWeekly": [
      2990,
      3013,
      2950,
      3048,
      3090,
      3028,
      3065,
      3039,
      3060,
      2978,
      3100,
      2904,
      2909,
      2982,
      3063,
      2915,
      2903,
      3068,
      3010,
      2963,
      3100,
      3077,
      3027,
      3027,
      2989,
      3034,
      3062,
      2901,
      3068,
      2955,
      2937,
      2952,
      3003,
      3076,
      3007,
      2998,
      2974,
      3097,
      3018,
      2962,
      2901,
      3051,
      2960,
      3091,
      2917,
      2931,
      3080,
      2925,
      3017,
      2999,
      3028,
      3015
    ],
    "orderRejected": [
      3015,
      10,
      2,
      13,
      8,
      12,
      18,
      11,
      12,
      19,
      7,
      19,
      5,
      18,
      13,
      18,
      16,
      0,
      7,
      19,
      2,
      17,
      0,
      13,
      19,
      4,
      19,
      0,
      13,
      14,
      0,
      15,
      17,
      13,
      15,
      2,
      10,
      9,
      19,
      12,
      19,
      14,
      16,
      7,
      9,
      11,
      5,
      13,
      12,
      15,
      8,
      13,
      10
    ],
    "orderAccepted": [
      5,
      11,
      8,
      0,
      4,
      11,
      9,
      5,
      1,
      8,
      2,
      11,
      10,
      5,
      9,
      5,
      1,
      0,
      11,
      6,
      2,
      0,
      8,
      8,
      10,
      7,
      0,
      11,
      0,
      6,
      8,
      10,
      9,
      5,
      6,
      10,
      6,
      6,
      9,
      4,
      11,
      1,
      10,
      1,
      1,
      11,
      0,
      10,
      9,
      4,
      0,
      0
    ],
    "orderHold": [
      2,
      2,
      1,
      2,
      3,
      4,
      2,
      1,
      3,
      3,
      3,
      3,
      3,
      2,
      1,
      0,
      4,
      0,
      0,
      2,
      2,
      0,
      0,
      4,
      0,
      1,
      0,
      2,
      4,
      0,
      2,
      4,
      4,
      0,
      4,
      0,
      3,
      2,
      1,
      0,
      0,
      0,
      0,
      3,
      0,
      0,
      2,
      3,
      2,
      0,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      0,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      3,
      4,
      2,
      1,
      0,
      1,
      3,
      3,
      2,
      1,
      3,
      1,
      1,
      0,
      4,
      1,
      2,
      4,
      1,
      2,
      0,
      4,
      3,
      2,
      1,
      2,
      4,
      0,
      1,
      1,
      4,
      0,
      0,
      1,
      4,
      0,
      3,
      2,
      0,
      4,
      0,
      4,
      0,
      1,
      2,
      2,
      4,
      3,
      0,
      3
    ],
    "issueDelay": [
      3,
      0,
      4,
      4,
      0,
      2,
      4,
      0,
      2,
      2,
      0,
      0,
      3,
      4,
      0,
      2,
      1,
      0,
      4,
      3,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      4,
      1,
      3,
      3,
      4,
      1,
      2,
      4,
      0,
      2,
      0,
      1,
      1,
      1,
      3,
      2,
      0,
      2,
      0,
      4,
      0,
      2
    ],
    "issueQuantity": [
      2,
      4,
      3,
      2,
      2,
      2,
      1,
      0,
      4,
      2,
      3,
      1,
      0,
      0,
      1,
      3,
      1,
      4,
      0,
      4,
      0,
      0,
      2,
      2,
      0,
      3,
      3,
      4,
      1,
      3,
      1,
      0,
      2,
      1,
      0,
      3,
      3,
      1,
      3,
      4,
      1,
      0,
      3,
      1,
      3,
      1,
      0,
      0,
      0,
      0,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000044",
    "status": "active",
    "name": "VitalCare Distribution",
    "tag": "PPE",
    "contactName": "Jennifer Coleman",
    "industryRating": 4.5,
    "procedures": "[PR-053,PR-065,PR-066,PR-045,PR-059,PR-054,PR-058,PR-041,PR-071,PR-051]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000058,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000062,ORDR-1-0000034,ORDR-2-0000060,ORDR-3-0000058,ORDR-4-0000039,ORDR-5-0000005,ORDR-6-0000066,ORDR-7-0000017,ORDR-8-0000034,ORDR-9-0000010,ORDR-10-0000019,ORDR-11-0000046,ORDR-12-0000014,ORDR-13-0000058,ORDR-14-0000058,ORDR-15-0000018,ORDR-16-0000037,ORDR-17-0000075,ORDR-18-0000018]",
    "spendWeekly": [
      3097,
      3055,
      2951,
      3004,
      2903,
      2905,
      3100,
      3062,
      2964,
      3099,
      3027,
      2994,
      3100,
      3049,
      2983,
      2901,
      2986,
      3099,
      2930,
      3025,
      3086,
      3033,
      2936,
      2996,
      3095,
      3093,
      3016,
      2936,
      2966,
      2953,
      3096,
      3077,
      2922,
      2904,
      2981,
      2929,
      2970,
      2949,
      3099,
      3004,
      3039,
      3100,
      3097,
      3004,
      2911,
      3011,
      3047,
      2900,
      3035,
      2906,
      3010,
      2950
    ],
    "orderRejected": [
      2950,
      10,
      8,
      10,
      15,
      19,
      3,
      3,
      17,
      16,
      11,
      2,
      6,
      5,
      18,
      9,
      14,
      0,
      4,
      4,
      6,
      9,
      0,
      12,
      11,
      15,
      3,
      1,
      17,
      0,
      0,
      17,
      7,
      13,
      10,
      19,
      19,
      18,
      19,
      15,
      0,
      19,
      1,
      0,
      12,
      7,
      7,
      0,
      14,
      12,
      8,
      16,
      16
    ],
    "orderAccepted": [
      11,
      10,
      7,
      10,
      1,
      6,
      7,
      11,
      0,
      0,
      0,
      7,
      1,
      6,
      11,
      11,
      2,
      6,
      11,
      5,
      11,
      11,
      7,
      0,
      1,
      2,
      7,
      8,
      7,
      0,
      10,
      9,
      0,
      1,
      8,
      10,
      4,
      3,
      11,
      5,
      0,
      11,
      7,
      4,
      8,
      9,
      3,
      11,
      3,
      6,
      0,
      10
    ],
    "orderHold": [
      2,
      1,
      3,
      3,
      1,
      0,
      3,
      1,
      0,
      0,
      1,
      0,
      0,
      3,
      0,
      2,
      1,
      0,
      1,
      2,
      0,
      4,
      1,
      2,
      0,
      2,
      4,
      1,
      4,
      1,
      2,
      3,
      0,
      1,
      4,
      2,
      3,
      2,
      3,
      1,
      0,
      0,
      4,
      2,
      2,
      3,
      1,
      0,
      0,
      1,
      4,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      2,
      4,
      4,
      2,
      2,
      1,
      3,
      4,
      1,
      3,
      0,
      1,
      3,
      0,
      0,
      3,
      3,
      0,
      3,
      1,
      0,
      3,
      3,
      1,
      0,
      4,
      1,
      3,
      4,
      3,
      2,
      2,
      0,
      2,
      3,
      0,
      3,
      2,
      0,
      3,
      4,
      2,
      3,
      0,
      4,
      3,
      2,
      4,
      0,
      3,
      4
    ],
    "issueDelay": [
      2,
      4,
      4,
      3,
      4,
      4,
      1,
      0,
      2,
      4,
      0,
      0,
      4,
      2,
      0,
      0,
      4,
      0,
      1,
      2,
      2,
      0,
      1,
      0,
      0,
      0,
      2,
      2,
      4,
      2,
      1,
      4,
      2,
      3,
      3,
      1,
      0,
      4,
      4,
      2,
      0,
      0,
      4,
      2,
      2,
      3,
      1,
      0,
      0,
      4,
      1,
      2
    ],
    "issueQuantity": [
      4,
      1,
      4,
      0,
      4,
      4,
      2,
      0,
      0,
      3,
      4,
      3,
      0,
      2,
      1,
      2,
      1,
      2,
      0,
      4,
      1,
      0,
      2,
      4,
      2,
      4,
      4,
      2,
      3,
      2,
      2,
      0,
      2,
      1,
      2,
      3,
      4,
      1,
      1,
      4,
      2,
      0,
      3,
      0,
      3,
      0,
      2,
      1,
      2,
      2,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000045",
    "status": "inactive",
    "name": "HealthWave Technologies",
    "tag": "Facilities Management, Surgical Instruments, Equipment",
    "contactName": "Gary Wilson",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-065,PR-045,PR-051,PR-054,PR-053,PR-063,PR-055,PR-055,PR-062]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000027,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000017,ORDR-2-0000034,ORDR-3-0000008,ORDR-4-0000025,ORDR-5-0000020,ORDR-6-0000052,ORDR-7-0000053,ORDR-8-0000063,ORDR-9-0000011,ORDR-10-0000010,ORDR-11-0000015,ORDR-12-0000067,ORDR-13-0000034,ORDR-14-0000077,ORDR-15-0000049,ORDR-16-0000079,ORDR-17-0000068,ORDR-18-0000029]",
    "spendWeekly": [
      3045,
      3034,
      2939,
      3070,
      3021,
      2905,
      2973,
      3098,
      2964,
      2934,
      3100,
      2904,
      2909,
      3045,
      2963,
      2901,
      3054,
      3093,
      3099,
      2964,
      2987,
      3079,
      3092,
      2944,
      3095,
      3090,
      3095,
      3016,
      3074,
      3060,
      2937,
      3005,
      3074,
      2971,
      3010,
      2967,
      2907,
      2902,
      2906,
      2971,
      3017,
      2955,
      2921,
      3091,
      2958,
      2901,
      3093,
      2900,
      3100,
      2958,
      3032,
      3097
    ],
    "orderRejected": [
      3097,
      19,
      3,
      1,
      6,
      12,
      16,
      16,
      19,
      19,
      17,
      15,
      8,
      4,
      16,
      5,
      19,
      0,
      8,
      19,
      4,
      0,
      16,
      15,
      6,
      4,
      2,
      9,
      19,
      19,
      17,
      19,
      15,
      19,
      19,
      14,
      0,
      0,
      8,
      18,
      8,
      19,
      6,
      0,
      10,
      14,
      0,
      12,
      13,
      19,
      9,
      0,
      19
    ],
    "orderAccepted": [
      11,
      0,
      0,
      1,
      3,
      3,
      0,
      4,
      4,
      0,
      8,
      3,
      5,
      5,
      8,
      0,
      3,
      11,
      4,
      10,
      8,
      0,
      0,
      6,
      2,
      11,
      0,
      1,
      1,
      6,
      10,
      11,
      0,
      0,
      6,
      11,
      8,
      5,
      10,
      6,
      0,
      11,
      11,
      0,
      0,
      11,
      3,
      10,
      8,
      7,
      3,
      0
    ],
    "orderHold": [
      0,
      1,
      4,
      0,
      4,
      1,
      2,
      0,
      1,
      4,
      3,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      4,
      1,
      1,
      2,
      0,
      3,
      0,
      0,
      3,
      1,
      3,
      0,
      2,
      0,
      4,
      4,
      0,
      0,
      0,
      3,
      0,
      0,
      0,
      0,
      3,
      4,
      3,
      4,
      2,
      1,
      0,
      1,
      0,
      1
    ],
    "orderBackordered": [
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      2,
      1,
      2,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      2,
      0,
      4,
      4,
      0,
      3,
      0,
      4,
      3,
      4,
      2,
      0,
      4,
      1,
      1,
      2,
      4,
      0,
      3,
      0,
      1,
      2,
      4,
      3,
      2,
      4,
      2,
      0,
      4,
      3,
      2,
      3,
      2,
      4,
      0,
      3,
      0,
      1,
      2,
      3,
      0,
      1,
      2,
      2,
      4,
      4,
      0,
      1,
      4,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      4,
      2,
      4,
      1,
      2,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      4,
      0,
      3,
      3,
      1,
      2,
      1,
      3,
      4,
      2,
      2,
      0,
      4,
      3,
      0,
      3,
      4,
      4,
      0,
      2,
      0,
      2,
      0,
      0,
      4,
      2,
      0,
      2,
      4,
      0,
      0,
      1,
      0,
      0,
      2,
      4,
      1,
      0,
      4
    ],
    "issueQuantity": [
      1,
      4,
      0,
      0,
      3,
      2,
      3,
      1,
      2,
      2,
      3,
      1,
      0,
      3,
      0,
      0,
      1,
      2,
      4,
      2,
      0,
      4,
      1,
      1,
      1,
      1,
      0,
      0,
      2,
      2,
      1,
      4,
      1,
      1,
      2,
      1,
      4,
      0,
      4,
      2,
      4,
      3,
      2,
      1,
      4,
      2,
      4,
      3,
      2,
      4,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000046",
    "status": "active",
    "name": "LifeLine Supplies",
    "tag": "IT Services, Surgical Instruments",
    "contactName": "Brian Baird",
    "industryRating": 4.1,
    "procedures": "[PR-044,PR-026,PR-022,PR-019,PR-046,PR-057,PR-054,PR-070,PR-065,PR-073]",
    "contracts": "[CNTRCT-0000045,CNTRCT-0000060,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000026,ORDR-1-0000012,ORDR-2-0000027,ORDR-3-0000012,ORDR-4-0000031,ORDR-5-0000044,ORDR-6-0000016,ORDR-7-0000065,ORDR-8-0000041,ORDR-9-0000011,ORDR-10-0000057,ORDR-11-0000067,ORDR-12-0000025,ORDR-13-0000054,ORDR-14-0000029,ORDR-15-0000053,ORDR-16-0000019,ORDR-17-0000025,ORDR-18-0000047]",
    "spendWeekly": [
      3097,
      2976,
      2911,
      2902,
      2961,
      3070,
      3041,
      3001,
      2932,
      2936,
      3047,
      2954,
      2931,
      2970,
      2961,
      2953,
      2944,
      3094,
      2993,
      2953,
      3026,
      3097,
      2903,
      2972,
      2924,
      3020,
      3063,
      2951,
      2997,
      3067,
      2981,
      2967,
      2989,
      2904,
      2939,
      2973,
      2908,
      2999,
      2906,
      2937,
      3076,
      2957,
      2983,
      3022,
      3073,
      2944,
      3005,
      2954,
      3034,
      2957,
      3017,
      2991
    ],
    "orderRejected": [
      2991,
      16,
      1,
      12,
      19,
      4,
      0,
      10,
      15,
      8,
      6,
      2,
      19,
      9,
      17,
      19,
      19,
      0,
      0,
      0,
      15,
      11,
      0,
      15,
      11,
      19,
      19,
      7,
      17,
      1,
      19,
      19,
      19,
      9,
      11,
      12,
      15,
      6,
      1,
      4,
      19,
      16,
      19,
      13,
      9,
      4,
      8,
      14,
      13,
      19,
      0,
      11,
      14
    ],
    "orderAccepted": [
      10,
      9,
      7,
      5,
      6,
      3,
      11,
      0,
      4,
      1,
      0,
      3,
      9,
      3,
      11,
      6,
      6,
      11,
      5,
      1,
      0,
      0,
      11,
      4,
      11,
      7,
      4,
      7,
      0,
      0,
      5,
      8,
      2,
      3,
      8,
      11,
      2,
      6,
      11,
      0,
      5,
      11,
      11,
      0,
      2,
      11,
      2,
      11,
      9,
      6,
      4,
      1
    ],
    "orderHold": [
      3,
      2,
      2,
      2,
      4,
      0,
      3,
      0,
      1,
      4,
      2,
      0,
      0,
      2,
      0,
      0,
      2,
      0,
      0,
      2,
      2,
      2,
      1,
      4,
      3,
      3,
      2,
      2,
      2,
      4,
      2,
      4,
      0,
      4,
      4,
      2,
      0,
      3,
      1,
      3,
      2,
      0,
      1,
      4,
      2,
      3,
      0,
      3,
      0,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      1,
      0,
      2,
      0,
      0,
      1,
      1,
      1,
      2,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      0,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      0,
      0,
      0,
      1,
      2,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      4,
      2,
      4,
      4,
      1,
      0,
      1,
      3,
      1,
      4,
      1,
      0,
      2,
      0,
      4,
      2,
      4,
      4,
      0,
      4,
      0,
      1,
      1,
      2,
      1,
      4,
      2,
      1,
      3,
      1,
      4,
      3,
      3,
      0,
      2,
      2,
      2,
      1,
      3,
      0,
      0,
      2,
      0,
      3,
      3,
      0,
      0,
      4,
      4,
      1,
      0,
      3
    ],
    "issueDelay": [
      3,
      3,
      4,
      2,
      2,
      1,
      2,
      1,
      2,
      4,
      0,
      0,
      3,
      0,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      1,
      3,
      3,
      0,
      1,
      0,
      3,
      3,
      3,
      3,
      3,
      0,
      1,
      3,
      3,
      0,
      0,
      4,
      1,
      3,
      0,
      0,
      2,
      1,
      0,
      0,
      0,
      4,
      4,
      3,
      3
    ],
    "issueQuantity": [
      4,
      3,
      4,
      0,
      3,
      4,
      1,
      4,
      0,
      1,
      2,
      1,
      3,
      3,
      3,
      1,
      3,
      2,
      0,
      4,
      2,
      2,
      0,
      4,
      0,
      0,
      2,
      4,
      4,
      3,
      4,
      4,
      3,
      4,
      3,
      4,
      4,
      1,
      2,
      4,
      1,
      3,
      2,
      3,
      4,
      1,
      2,
      1,
      0,
      2,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000047",
    "status": "active",
    "name": "HealthCraft Logistics",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Jennifer Yoder ​",
    "industryRating": 2.1,
    "procedures": "[PR-054,PR-064,PR-038,PR-032,PR-022,PR-065,PR-058,PR-050,PR-063,PR-070]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000034,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000059,ORDR-1-0000037,ORDR-2-0000008,ORDR-3-0000023,ORDR-4-0000015,ORDR-5-0000035,ORDR-6-0000040,ORDR-7-0000049,ORDR-8-0000033,ORDR-9-0000021,ORDR-10-0000017,ORDR-11-0000028,ORDR-12-0000019,ORDR-13-0000035,ORDR-14-0000049,ORDR-15-0000027,ORDR-16-0000018,ORDR-17-0000039,ORDR-18-0000064]",
    "spendWeekly": [
      3003,
      3035,
      2906,
      2912,
      3012,
      3007,
      3053,
      2999,
      2945,
      3042,
      2995,
      3095,
      3100,
      2986,
      3003,
      2901,
      3041,
      3099,
      2900,
      2909,
      3100,
      3070,
      3093,
      3007,
      3051,
      3096,
      2921,
      2901,
      2901,
      3053,
      3026,
      3053,
      3089,
      3036,
      2963,
      3083,
      3037,
      2918,
      3027,
      3099,
      2901,
      3064,
      3097,
      2999,
      3037,
      2901,
      2967,
      2938,
      3100,
      2998,
      2905,
      2901
    ],
    "orderRejected": [
      2901,
      19,
      19,
      17,
      1,
      15,
      0,
      9,
      4,
      8,
      13,
      0,
      2,
      7,
      15,
      11,
      13,
      7,
      0,
      10,
      19,
      19,
      5,
      18,
      14,
      3,
      13,
      0,
      13,
      19,
      17,
      15,
      19,
      19,
      4,
      17,
      11,
      14,
      8,
      4,
      0,
      19,
      2,
      0,
      0,
      17,
      5,
      6,
      17,
      11,
      7,
      9,
      6
    ],
    "orderAccepted": [
      11,
      11,
      5,
      11,
      3,
      4,
      8,
      11,
      2,
      0,
      3,
      0,
      4,
      11,
      11,
      11,
      11,
      6,
      11,
      2,
      0,
      0,
      11,
      1,
      5,
      9,
      1,
      11,
      6,
      7,
      0,
      0,
      0,
      0,
      3,
      11,
      0,
      6,
      10,
      3,
      0,
      0,
      10,
      11,
      3,
      9,
      3,
      11,
      0,
      0,
      5,
      4
    ],
    "orderHold": [
      1,
      0,
      4,
      4,
      3,
      2,
      1,
      4,
      3,
      0,
      0,
      4,
      3,
      4,
      1,
      2,
      4,
      0,
      4,
      2,
      1,
      3,
      4,
      4,
      4,
      1,
      3,
      2,
      3,
      0,
      0,
      2,
      0,
      2,
      0,
      0,
      4,
      0,
      4,
      1,
      3,
      3,
      3,
      2,
      2,
      1,
      0,
      0,
      4,
      0,
      1,
      3
    ],
    "orderBackordered": [
      3,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      1,
      0,
      4,
      4,
      0,
      4,
      0,
      3,
      0,
      3,
      4,
      0,
      2,
      2,
      0,
      4,
      1,
      2,
      3,
      0,
      0,
      1,
      2,
      0,
      0,
      4,
      3,
      1,
      4,
      4,
      2,
      1,
      0,
      1,
      2,
      3,
      3,
      0,
      4,
      0,
      0,
      0,
      4,
      4,
      2,
      2,
      2,
      2,
      3,
      4,
      2,
      4
    ],
    "issueDelay": [
      3,
      2,
      1,
      2,
      1,
      3,
      3,
      0,
      4,
      1,
      0,
      4,
      0,
      4,
      0,
      2,
      0,
      0,
      0,
      0,
      4,
      1,
      0,
      3,
      1,
      2,
      2,
      2,
      4,
      0,
      0,
      1,
      4,
      1,
      4,
      4,
      3,
      0,
      4,
      3,
      0,
      2,
      4,
      2,
      3,
      2,
      3,
      4,
      0,
      4,
      4,
      0
    ],
    "issueQuantity": [
      4,
      0,
      3,
      3,
      3,
      3,
      3,
      1,
      2,
      3,
      3,
      3,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      4,
      1,
      4,
      2,
      2,
      0,
      4,
      3,
      1,
      2,
      4,
      0,
      0,
      4,
      1,
      0,
      2,
      4,
      2,
      0,
      4,
      4,
      3,
      4,
      1,
      1,
      0,
      1,
      0,
      0,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000048",
    "status": "inactive",
    "name": "LifeCare Logistics",
    "tag": "Nutrition",
    "contactName": "Michael Alexander",
    "industryRating": 4.8,
    "procedures": "[PR-016,PR-022,PR-021,PR-031,PR-042,PR-054,PR-058,PR-041,PR-070,PR-072]",
    "contracts": "[CNTRCT-0000059,CNTRCT-0000052,CNTRCT-0000017]",
    "orders": "[ORDR-0-0000019,ORDR-1-0000012,ORDR-2-0000049,ORDR-3-0000045,ORDR-4-0000008,ORDR-5-0000009,ORDR-6-0000045,ORDR-7-0000068,ORDR-8-0000037,ORDR-9-0000050,ORDR-10-0000042,ORDR-11-0000066,ORDR-12-0000031,ORDR-13-0000020,ORDR-14-0000049,ORDR-15-0000059,ORDR-16-0000067,ORDR-17-0000038,ORDR-18-0000039]",
    "spendWeekly": [
      3008,
      3047,
      2906,
      3070,
      3000,
      3073,
      2905,
      2989,
      3041,
      3008,
      2981,
      2962,
      3040,
      3004,
      3091,
      2983,
      2930,
      3077,
      3096,
      2931,
      3097,
      3069,
      3097,
      2997,
      3051,
      2901,
      2976,
      2943,
      2965,
      3097,
      2975,
      2920,
      3024,
      2904,
      3004,
      3015,
      2950,
      2959,
      3014,
      2977,
      2987,
      3037,
      3040,
      3019,
      2944,
      2901,
      3057,
      2900,
      2941,
      3022,
      2905,
      2905
    ],
    "orderRejected": [
      2905,
      10,
      2,
      11,
      16,
      17,
      16,
      7,
      8,
      0,
      10,
      0,
      13,
      2,
      7,
      8,
      13,
      15,
      2,
      12,
      9,
      12,
      2,
      7,
      13,
      19,
      4,
      6,
      10,
      8,
      19,
      19,
      17,
      14,
      19,
      7,
      12,
      10,
      7,
      19,
      19,
      14,
      1,
      15,
      0,
      0,
      0,
      5,
      16,
      12,
      6,
      9,
      19
    ],
    "orderAccepted": [
      11,
      7,
      0,
      4,
      5,
      10,
      11,
      4,
      6,
      11,
      7,
      3,
      11,
      10,
      4,
      2,
      8,
      11,
      0,
      7,
      2,
      8,
      2,
      2,
      11,
      11,
      0,
      2,
      8,
      0,
      9,
      9,
      3,
      6,
      3,
      11,
      6,
      1,
      11,
      0,
      10,
      11,
      11,
      1,
      4,
      7,
      7,
      4,
      9,
      0,
      0,
      4
    ],
    "orderHold": [
      2,
      2,
      3,
      2,
      4,
      4,
      4,
      3,
      0,
      2,
      2,
      0,
      4,
      1,
      2,
      4,
      4,
      3,
      1,
      4,
      2,
      2,
      2,
      4,
      1,
      3,
      4,
      4,
      1,
      2,
      1,
      4,
      4,
      0,
      4,
      4,
      1,
      1,
      2,
      4,
      0,
      0,
      3,
      4,
      2,
      4,
      4,
      1,
      2,
      0,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      2,
      1,
      0,
      2,
      0,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      2,
      2,
      0,
      0,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      3,
      0,
      3,
      4,
      1,
      0,
      0,
      3,
      2,
      2,
      0,
      0,
      2,
      2,
      1,
      4,
      1,
      4,
      4,
      4,
      4,
      3,
      4,
      4,
      1,
      3,
      2,
      2,
      2,
      2,
      2,
      0,
      4,
      3,
      2,
      3,
      0,
      2,
      4,
      0,
      0,
      2,
      4,
      4,
      0,
      0,
      4,
      1,
      0,
      3,
      0,
      2
    ],
    "issueDelay": [
      4,
      2,
      3,
      4,
      2,
      4,
      4,
      0,
      2,
      2,
      2,
      4,
      4,
      4,
      0,
      3,
      0,
      2,
      2,
      0,
      1,
      2,
      0,
      3,
      1,
      1,
      0,
      2,
      3,
      1,
      4,
      4,
      2,
      2,
      2,
      1,
      1,
      3,
      0,
      3,
      1,
      1,
      4,
      0,
      2,
      3,
      1,
      2,
      0,
      4,
      2,
      2
    ],
    "issueQuantity": [
      4,
      1,
      1,
      3,
      4,
      4,
      4,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      4,
      2,
      1,
      4,
      2,
      4,
      0,
      2,
      3,
      4,
      0,
      0,
      3,
      3,
      2,
      2,
      2,
      0,
      4,
      1,
      0,
      3,
      4,
      0,
      3,
      0,
      3,
      4,
      2,
      0,
      1,
      0,
      4,
      3,
      1,
      3,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000049",
    "status": "inactive",
    "name": "MedLogistics Partners",
    "tag": "Surgical Instruments, Pharmaceuticals",
    "contactName": "Bryan Lang",
    "industryRating": 4.2,
    "procedures": "[PR-038,PR-022,PR-021,PR-031,PR-047,PR-028,PR-053,PR-073,PR-070,PR-041]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000064,CNTRCT-0000061]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000029,ORDR-2-0000022,ORDR-3-0000002,ORDR-4-0000049,ORDR-5-0000068,ORDR-6-0000038,ORDR-7-0000016,ORDR-8-0000023,ORDR-9-0000026,ORDR-10-0000027,ORDR-11-0000045,ORDR-12-0000076,ORDR-13-0000019,ORDR-14-0000073,ORDR-15-0000015,ORDR-16-0000079,ORDR-17-0000070,ORDR-18-0000019]",
    "spendWeekly": [
      2920,
      3046,
      2990,
      3017,
      3040,
      2923,
      2905,
      3031,
      2950,
      2940,
      2984,
      2925,
      2934,
      3083,
      3091,
      3022,
      3075,
      3053,
      2963,
      3033,
      3075,
      3055,
      2935,
      3096,
      2940,
      2960,
      3075,
      2979,
      3053,
      2921,
      2903,
      3049,
      3010,
      3036,
      2913,
      3014,
      2966,
      2985,
      2906,
      2928,
      2994,
      2943,
      2990,
      3018,
      2902,
      3012,
      3004,
      3086,
      2988,
      3047,
      3011,
      3075
    ],
    "orderRejected": [
      3075,
      5,
      5,
      4,
      4,
      14,
      2,
      11,
      3,
      11,
      17,
      12,
      2,
      0,
      7,
      13,
      10,
      5,
      0,
      12,
      0,
      6,
      16,
      19,
      8,
      0,
      15,
      7,
      13,
      15,
      10,
      3,
      15,
      6,
      19,
      0,
      10,
      8,
      16,
      0,
      13,
      8,
      13,
      5,
      13,
      3,
      0,
      3,
      6,
      9,
      14,
      14,
      18
    ],
    "orderAccepted": [
      4,
      3,
      6,
      7,
      5,
      5,
      9,
      8,
      2,
      4,
      8,
      8,
      1,
      5,
      1,
      5,
      11,
      11,
      7,
      2,
      3,
      6,
      6,
      5,
      4,
      6,
      0,
      6,
      7,
      6,
      9,
      11,
      1,
      1,
      6,
      11,
      10,
      5,
      11,
      0,
      6,
      6,
      8,
      1,
      1,
      4,
      6,
      5,
      9,
      0,
      7,
      5
    ],
    "orderHold": [
      2,
      4,
      2,
      2,
      2,
      2,
      4,
      4,
      3,
      4,
      1,
      1,
      3,
      3,
      1,
      0,
      4,
      0,
      3,
      0,
      1,
      3,
      1,
      1,
      1,
      3,
      1,
      2,
      1,
      0,
      0,
      1,
      4,
      0,
      2,
      2,
      0,
      3,
      3,
      4,
      0,
      2,
      2,
      2,
      2,
      2,
      4,
      1,
      3,
      0,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      0,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      0,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      1,
      3,
      2,
      3,
      3,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      4,
      0,
      4,
      1,
      4,
      3,
      3,
      0,
      3,
      1,
      0,
      2,
      3,
      2,
      1,
      1,
      3,
      4,
      3,
      2,
      0,
      4,
      0,
      0,
      4,
      4,
      0,
      2,
      2,
      4,
      0,
      0,
      2,
      4,
      0,
      2,
      0,
      2
    ],
    "issueDelay": [
      2,
      4,
      1,
      2,
      0,
      2,
      0,
      3,
      0,
      1,
      0,
      3,
      4,
      1,
      2,
      4,
      4,
      2,
      1,
      2,
      2,
      2,
      3,
      3,
      0,
      2,
      2,
      1,
      0,
      3,
      2,
      2,
      0,
      0,
      4,
      1,
      1,
      4,
      0,
      4,
      2,
      1,
      0,
      1,
      4,
      2,
      3,
      4,
      3,
      3,
      3,
      3
    ],
    "issueQuantity": [
      1,
      3,
      0,
      1,
      4,
      4,
      2,
      2,
      0,
      0,
      4,
      2,
      0,
      2,
      3,
      2,
      2,
      3,
      1,
      4,
      0,
      4,
      2,
      4,
      3,
      1,
      3,
      4,
      3,
      1,
      4,
      2,
      1,
      0,
      2,
      2,
      4,
      1,
      3,
      1,
      3,
      2,
      0,
      2,
      1,
      3,
      1,
      2,
      1,
      1,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000050",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "IT Services",
    "contactName": "Julie Sanders",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-056,PR-068,PR-067,PR-045,PR-053,PR-058,PR-070,PR-073,PR-048]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000016,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000024,ORDR-1-0000009,ORDR-2-0000014,ORDR-3-0000058,ORDR-4-0000015,ORDR-5-0000050,ORDR-6-0000008,ORDR-7-0000023,ORDR-8-0000059,ORDR-9-0000024,ORDR-10-0000019,ORDR-11-0000070,ORDR-12-0000071,ORDR-13-0000012,ORDR-14-0000055,ORDR-15-0000066,ORDR-16-0000022,ORDR-17-0000030,ORDR-18-0000054]",
    "spendWeekly": [
      3097,
      2902,
      3000,
      3037,
      3095,
      3049,
      2990,
      2903,
      2988,
      2911,
      3032,
      2959,
      2909,
      3021,
      3091,
      2901,
      3037,
      3099,
      2900,
      2909,
      3022,
      3068,
      3097,
      2957,
      3018,
      3086,
      2995,
      2978,
      2901,
      3065,
      2903,
      3043,
      3064,
      2904,
      3006,
      2990,
      3100,
      3009,
      2973,
      2973,
      2974,
      2984,
      3097,
      2901,
      2920,
      2901,
      2916,
      2900,
      2925,
      3050,
      3034,
      2901
    ],
    "orderRejected": [
      2901,
      10,
      9,
      12,
      12,
      0,
      8,
      18,
      15,
      10,
      5,
      19,
      14,
      15,
      17,
      6,
      19,
      0,
      11,
      18,
      17,
      19,
      1,
      17,
      13,
      4,
      3,
      0,
      19,
      19,
      4,
      12,
      9,
      19,
      8,
      6,
      11,
      16,
      16,
      0,
      14,
      19,
      11,
      0,
      19,
      19,
      6,
      10,
      19,
      17,
      10,
      0,
      19
    ],
    "orderAccepted": [
      8,
      11,
      7,
      0,
      1,
      10,
      0,
      6,
      0,
      1,
      2,
      8,
      10,
      3,
      7,
      3,
      0,
      0,
      7,
      0,
      7,
      4,
      0,
      0,
      3,
      4,
      7,
      7,
      6,
      11,
      11,
      1,
      2,
      11,
      9,
      4,
      7,
      0,
      2,
      11,
      0,
      11,
      11,
      6,
      9,
      11,
      7,
      9,
      3,
      3,
      5,
      6
    ],
    "orderHold": [
      2,
      4,
      4,
      4,
      1,
      1,
      4,
      2,
      3,
      4,
      2,
      2,
      1,
      2,
      0,
      1,
      4,
      0,
      4,
      4,
      1,
      3,
      2,
      2,
      0,
      1,
      2,
      2,
      3,
      2,
      2,
      0,
      1,
      0,
      0,
      1,
      3,
      4,
      3,
      1,
      4,
      0,
      2,
      3,
      3,
      3,
      0,
      0,
      2,
      1,
      3,
      3
    ],
    "orderBackordered": [
      3,
      0,
      2,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      0,
      2,
      2,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      1,
      0,
      4,
      4,
      0,
      3,
      4,
      3,
      1,
      1,
      4,
      0,
      4,
      2,
      1,
      0,
      4,
      2,
      1,
      4,
      0,
      0,
      2,
      4,
      0,
      4,
      4,
      2,
      4,
      4,
      1,
      3,
      0,
      4,
      1,
      0,
      3,
      4,
      1,
      4,
      0,
      0,
      4,
      4,
      2,
      4,
      0,
      0,
      4,
      4,
      0,
      4
    ],
    "issueDelay": [
      0,
      1,
      1,
      4,
      0,
      0,
      3,
      2,
      1,
      2,
      0,
      0,
      3,
      4,
      2,
      0,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      0,
      3,
      4,
      4,
      1,
      0,
      0,
      0,
      3,
      4,
      0,
      4,
      4,
      1,
      2,
      4,
      4,
      3,
      2,
      2,
      0,
      4,
      2,
      0,
      0,
      4,
      3,
      0,
      4
    ],
    "issueQuantity": [
      4,
      3,
      4,
      0,
      0,
      0,
      2,
      1,
      0,
      3,
      4,
      3,
      2,
      2,
      4,
      3,
      0,
      0,
      3,
      3,
      0,
      4,
      0,
      0,
      2,
      4,
      1,
      1,
      4,
      4,
      4,
      3,
      3,
      0,
      0,
      3,
      4,
      0,
      0,
      4,
      4,
      3,
      0,
      2,
      4,
      1,
      3,
      3,
      0,
      3,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000051",
    "status": "active",
    "name": "HealthSupply Innovations",
    "tag": "IT Services, Imaging, Medical Supplies",
    "contactName": "Deborah Cox",
    "industryRating": 4.8,
    "procedures": "[PR-047,PR-054,PR-021,PR-035,PR-070,PR-070,PR-052,PR-051,PR-073,PR-073]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000059,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000045,ORDR-1-0000008,ORDR-2-0000052,ORDR-3-0000008,ORDR-4-0000046,ORDR-5-0000026,ORDR-6-0000021,ORDR-7-0000034,ORDR-8-0000031,ORDR-9-0000045,ORDR-10-0000046,ORDR-11-0000071,ORDR-12-0000058,ORDR-13-0000025,ORDR-14-0000051,ORDR-15-0000032,ORDR-16-0000026,ORDR-17-0000021,ORDR-18-0000035]",
    "spendWeekly": [
      3036,
      3013,
      3021,
      2982,
      2911,
      3065,
      3021,
      3067,
      2995,
      2902,
      3031,
      2967,
      2965,
      2952,
      3076,
      3059,
      2978,
      3055,
      3099,
      3048,
      3100,
      2903,
      2928,
      2988,
      3070,
      3096,
      3011,
      2913,
      3089,
      2917,
      3052,
      2942,
      3037,
      2904,
      2913,
      2973,
      3003,
      2902,
      3052,
      3022,
      2973,
      3015,
      2939,
      3020,
      2902,
      2954,
      3059,
      2983,
      3100,
      3017,
      2905,
      2913
    ],
    "orderRejected": [
      2913,
      18,
      6,
      1,
      4,
      7,
      0,
      5,
      5,
      12,
      12,
      13,
      7,
      14,
      0,
      17,
      8,
      9,
      0,
      11,
      3,
      18,
      0,
      14,
      17,
      13,
      15,
      15,
      18,
      19,
      13,
      2,
      16,
      14,
      11,
      6,
      4,
      0,
      9,
      4,
      15,
      15,
      5,
      7,
      4,
      1,
      8,
      8,
      9,
      15,
      18,
      11,
      16
    ],
    "orderAccepted": [
      4,
      5,
      5,
      11,
      1,
      4,
      11,
      6,
      0,
      10,
      7,
      11,
      11,
      11,
      6,
      1,
      6,
      3,
      0,
      4,
      2,
      9,
      1,
      6,
      5,
      11,
      0,
      2,
      0,
      0,
      8,
      3,
      0,
      0,
      5,
      9,
      6,
      3,
      11,
      9,
      11,
      10,
      10,
      2,
      4,
      4,
      0,
      11,
      0,
      10,
      0,
      3
    ],
    "orderHold": [
      2,
      2,
      1,
      2,
      1,
      3,
      1,
      4,
      3,
      2,
      2,
      0,
      0,
      2,
      1,
      4,
      1,
      0,
      3,
      2,
      0,
      3,
      0,
      1,
      3,
      3,
      3,
      1,
      4,
      0,
      0,
      1,
      4,
      0,
      0,
      2,
      4,
      4,
      2,
      0,
      0,
      0,
      3,
      4,
      2,
      3,
      3,
      2,
      3,
      0,
      4,
      4
    ],
    "orderBackordered": [
      4,
      2,
      2,
      0,
      2,
      2,
      2,
      0,
      1,
      1,
      0,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      0,
      2,
      1,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      1,
      0,
      0,
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      0,
      0,
      4,
      2,
      2,
      2,
      0,
      3,
      2,
      2,
      2,
      2,
      4,
      0,
      0,
      4,
      4,
      3,
      4,
      4,
      0,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      3,
      2,
      2,
      4,
      4,
      1,
      1,
      4,
      2,
      1,
      3,
      3,
      0,
      1,
      1,
      0,
      3,
      2,
      4,
      3,
      1,
      4,
      3,
      1
    ],
    "issueDelay": [
      2,
      3,
      4,
      4,
      1,
      2,
      4,
      3,
      1,
      1,
      3,
      1,
      3,
      3,
      0,
      3,
      2,
      4,
      3,
      2,
      4,
      2,
      2,
      2,
      1,
      4,
      4,
      1,
      1,
      3,
      3,
      2,
      1,
      3,
      4,
      0,
      0,
      4,
      3,
      2,
      0,
      0,
      4,
      4,
      2,
      2,
      0,
      3,
      2,
      2,
      0,
      3
    ],
    "issueQuantity": [
      3,
      0,
      1,
      2,
      3,
      4,
      4,
      4,
      0,
      3,
      3,
      1,
      3,
      1,
      1,
      4,
      1,
      4,
      2,
      2,
      0,
      1,
      2,
      3,
      4,
      0,
      3,
      2,
      0,
      4,
      3,
      2,
      4,
      4,
      3,
      3,
      4,
      2,
      0,
      3,
      3,
      1,
      2,
      2,
      2,
      3,
      2,
      3,
      3,
      2,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000052",
    "status": "inactive",
    "name": "HealthCraft Logistics",
    "tag": "PPE, Imaging",
    "contactName": "Levi White",
    "industryRating": 4.8,
    "procedures": "[PR-050,PR-049,PR-064,PR-038,PR-052,PR-018,PR-064,PR-049,PR-045,PR-072]",
    "contracts": "[CNTRCT-0000010,CNTRCT-0000035,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000062,ORDR-1-0000009,ORDR-2-0000004,ORDR-3-0000028,ORDR-4-0000022,ORDR-5-0000055,ORDR-6-0000015,ORDR-7-0000065,ORDR-8-0000011,ORDR-9-0000067,ORDR-10-0000046,ORDR-11-0000070,ORDR-12-0000025,ORDR-13-0000046,ORDR-14-0000052,ORDR-15-0000054,ORDR-16-0000041,ORDR-17-0000060,ORDR-18-0000061]",
    "spendWeekly": [
      2952,
      2938,
      3090,
      3024,
      3066,
      3097,
      3100,
      3043,
      3034,
      2912,
      2913,
      2904,
      2957,
      2939,
      3051,
      3065,
      3098,
      2933,
      2911,
      2993,
      3042,
      3058,
      2905,
      3045,
      2968,
      3096,
      2961,
      3096,
      2901,
      2955,
      3049,
      2941,
      3077,
      3053,
      2913,
      2904,
      3100,
      3044,
      3023,
      2996,
      2996,
      3031,
      3047,
      3008,
      2902,
      2901,
      2995,
      3032,
      2901,
      2974,
      2910,
      2947
    ],
    "orderRejected": [
      2947,
      16,
      19,
      1,
      15,
      7,
      12,
      4,
      16,
      7,
      0,
      19,
      16,
      12,
      13,
      10,
      19,
      0,
      0,
      19,
      13,
      12,
      6,
      9,
      18,
      19,
      14,
      0,
      19,
      0,
      4,
      10,
      0,
      15,
      8,
      19,
      12,
      6,
      19,
      9,
      17,
      19,
      0,
      2,
      2,
      3,
      8,
      8,
      19,
      19,
      1,
      0,
      4
    ],
    "orderAccepted": [
      4,
      11,
      0,
      11,
      3,
      9,
      9,
      11,
      0,
      5,
      11,
      0,
      3,
      10,
      10,
      11,
      0,
      7,
      3,
      6,
      11,
      11,
      8,
      5,
      9,
      8,
      0,
      8,
      11,
      3,
      11,
      1,
      0,
      10,
      11,
      3,
      8,
      0,
      11,
      8,
      7,
      8,
      6,
      10,
      3,
      11,
      6,
      11,
      5,
      10,
      0,
      6
    ],
    "orderHold": [
      4,
      3,
      2,
      2,
      4,
      2,
      4,
      3,
      3,
      3,
      3,
      0,
      1,
      4,
      0,
      3,
      1,
      0,
      4,
      0,
      1,
      4,
      2,
      4,
      1,
      2,
      4,
      2,
      4,
      1,
      2,
      4,
      2,
      4,
      0,
      1,
      0,
      0,
      4,
      2,
      4,
      1,
      1,
      4,
      3,
      3,
      0,
      1,
      0,
      1,
      0,
      0
    ],
    "orderBackordered": [
      0,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      4,
      4,
      0,
      2,
      1,
      2,
      4,
      4,
      1,
      3,
      2,
      4,
      0,
      2,
      1,
      2,
      3,
      2,
      0,
      1,
      3,
      2,
      0,
      3,
      2,
      0,
      4,
      0,
      1,
      4,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      3,
      1,
      0,
      0,
      0,
      4,
      4,
      2,
      4
    ],
    "issueDelay": [
      1,
      1,
      4,
      2,
      4,
      4,
      4,
      0,
      3,
      4,
      2,
      0,
      3,
      0,
      2,
      4,
      0,
      0,
      0,
      0,
      1,
      0,
      2,
      4,
      1,
      0,
      4,
      0,
      4,
      4,
      1,
      1,
      2,
      1,
      4,
      2,
      1,
      4,
      4,
      4,
      2,
      0,
      3,
      0,
      4,
      1,
      0,
      0,
      4,
      3,
      2,
      4
    ],
    "issueQuantity": [
      4,
      2,
      4,
      1,
      4,
      3,
      1,
      4,
      3,
      1,
      0,
      2,
      0,
      2,
      1,
      3,
      1,
      4,
      0,
      4,
      1,
      1,
      0,
      3,
      3,
      3,
      4,
      0,
      4,
      0,
      0,
      0,
      4,
      2,
      0,
      3,
      4,
      0,
      3,
      1,
      2,
      2,
      4,
      0,
      4,
      0,
      4,
      3,
      0,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000053",
    "status": "active",
    "name": "HealthStream Supplies",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Aaron Stevenson",
    "industryRating": 4.5,
    "procedures": "[PR-052,PR-055,PR-055,PR-037,PR-024,PR-071,PR-053,PR-061,PR-057,PR-062]",
    "contracts": "[CNTRCT-0000033,CNTRCT-0000044,CNTRCT-0000009]",
    "orders": "[ORDR-0-0000014,ORDR-1-0000030,ORDR-2-0000015,ORDR-3-0000028,ORDR-4-0000060,ORDR-5-0000019,ORDR-6-0000070,ORDR-7-0000069,ORDR-8-0000028,ORDR-9-0000008,ORDR-10-0000021,ORDR-11-0000031,ORDR-12-0000036,ORDR-13-0000065,ORDR-14-0000071,ORDR-15-0000052,ORDR-16-0000051,ORDR-17-0000039,ORDR-18-0000044]",
    "spendWeekly": [
      2901,
      3012,
      2959,
      2902,
      3033,
      2935,
      3064,
      3052,
      3010,
      2937,
      2962,
      2982,
      3100,
      2960,
      3091,
      2903,
      3098,
      2994,
      2958,
      2909,
      3050,
      2903,
      2986,
      3067,
      2965,
      3096,
      3095,
      2973,
      3004,
      2982,
      2955,
      3019,
      2959,
      2912,
      2913,
      2904,
      2963,
      2951,
      2906,
      2939,
      2948,
      2937,
      3097,
      3091,
      2902,
      2901,
      3099,
      3070,
      3100,
      2985,
      2905,
      2959
    ],
    "orderRejected": [
      2959,
      17,
      19,
      0,
      19,
      19,
      5,
      19,
      19,
      15,
      11,
      19,
      12,
      16,
      10,
      19,
      11,
      19,
      0,
      14,
      14,
      19,
      0,
      9,
      14,
      12,
      10,
      5,
      19,
      0,
      19,
      13,
      8,
      16,
      16,
      7,
      11,
      3,
      12,
      11,
      13,
      19,
      1,
      0,
      0,
      8,
      3,
      18,
      17,
      17,
      9,
      6,
      16
    ],
    "orderAccepted": [
      7,
      4,
      0,
      2,
      7,
      3,
      9,
      1,
      2,
      8,
      11,
      8,
      4,
      11,
      11,
      7,
      0,
      7,
      3,
      6,
      5,
      0,
      11,
      0,
      6,
      9,
      0,
      6,
      9,
      4,
      0,
      2,
      0,
      1,
      11,
      5,
      11,
      1,
      11,
      8,
      0,
      1,
      8,
      6,
      2,
      11,
      1,
      11,
      1,
      11,
      7,
      10
    ],
    "orderHold": [
      2,
      3,
      3,
      4,
      3,
      3,
      3,
      1,
      1,
      3,
      2,
      4,
      0,
      4,
      0,
      1,
      4,
      1,
      2,
      3,
      4,
      1,
      0,
      4,
      3,
      4,
      4,
      4,
      4,
      0,
      0,
      4,
      4,
      2,
      1,
      3,
      4,
      2,
      2,
      2,
      3,
      0,
      2,
      4,
      2,
      3,
      2,
      0,
      2,
      1,
      1,
      3
    ],
    "orderBackordered": [
      3,
      2,
      1,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      2,
      2,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      2,
      0,
      2,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      0,
      3,
      4,
      0,
      3,
      1,
      4,
      0,
      4,
      4,
      1,
      2,
      3,
      0,
      2,
      1,
      3,
      3,
      4,
      0,
      2,
      0,
      3,
      0,
      4,
      4,
      1,
      3,
      0,
      0,
      2,
      0,
      1,
      0,
      4,
      1,
      0,
      0,
      4,
      0,
      0,
      3,
      2,
      0,
      3,
      0,
      0,
      4,
      4,
      4,
      0
    ],
    "issueDelay": [
      4,
      4,
      1,
      3,
      1,
      2,
      1,
      0,
      1,
      4,
      0,
      1,
      4,
      3,
      0,
      3,
      0,
      0,
      0,
      1,
      3,
      2,
      1,
      4,
      0,
      1,
      4,
      1,
      0,
      4,
      4,
      1,
      1,
      0,
      3,
      4,
      2,
      4,
      0,
      2,
      2,
      0,
      0,
      0,
      2,
      0,
      4,
      0,
      4,
      3,
      4,
      1
    ],
    "issueQuantity": [
      4,
      4,
      1,
      0,
      3,
      3,
      4,
      0,
      0,
      2,
      3,
      1,
      0,
      1,
      4,
      4,
      2,
      3,
      0,
      4,
      0,
      4,
      0,
      3,
      0,
      0,
      0,
      2,
      3,
      0,
      0,
      3,
      2,
      2,
      1,
      2,
      4,
      0,
      1,
      4,
      1,
      4,
      2,
      2,
      4,
      1,
      4,
      1,
      0,
      4,
      3,
      0
    ]
  },
  {
    "id": "VNDR-0000054",
    "status": "inactive",
    "name": "PharmaLogix International",
    "tag": "Facilities Management, Equipment",
    "contactName": "Jennifer Carey",
    "industryRating": 2.1,
    "procedures": "[PR-054,PR-032,PR-055,PR-032,PR-053,PR-026,PR-034,PR-066,PR-052,PR-062]",
    "contracts": "[CNTRCT-0000035,CNTRCT-0000040,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000028,ORDR-1-0000036,ORDR-2-0000008,ORDR-3-0000011,ORDR-4-0000020,ORDR-5-0000046,ORDR-6-0000021,ORDR-7-0000030,ORDR-8-0000010,ORDR-9-0000030,ORDR-10-0000041,ORDR-11-0000039,ORDR-12-0000029,ORDR-13-0000065,ORDR-14-0000027,ORDR-15-0000018,ORDR-16-0000057,ORDR-17-0000046,ORDR-18-0000075]",
    "spendWeekly": [
      3081,
      2902,
      2972,
      2961,
      2984,
      2909,
      3044,
      3011,
      2902,
      2907,
      3044,
      2968,
      2996,
      2910,
      3080,
      2901,
      2946,
      3099,
      2987,
      2909,
      3086,
      3020,
      3055,
      2903,
      3036,
      3088,
      3095,
      2901,
      3012,
      2994,
      2987,
      3014,
      3030,
      2981,
      3035,
      3100,
      2958,
      2992,
      3029,
      3055,
      2901,
      2900,
      3059,
      3007,
      2958,
      2901,
      3099,
      2900,
      3008,
      2905,
      2925,
      3037
    ],
    "orderRejected": [
      3037,
      2,
      5,
      7,
      0,
      8,
      4,
      12,
      7,
      9,
      14,
      12,
      19,
      6,
      11,
      8,
      17,
      6,
      8,
      1,
      6,
      19,
      18,
      19,
      14,
      15,
      0,
      7,
      11,
      18,
      19,
      1,
      0,
      2,
      19,
      19,
      0,
      14,
      9,
      0,
      0,
      17,
      5,
      0,
      5,
      13,
      5,
      18,
      19,
      14,
      0,
      0,
      18
    ],
    "orderAccepted": [
      11,
      2,
      2,
      7,
      10,
      0,
      9,
      10,
      0,
      0,
      9,
      1,
      0,
      9,
      6,
      1,
      10,
      2,
      0,
      2,
      5,
      3,
      0,
      11,
      9,
      11,
      4,
      3,
      0,
      10,
      8,
      11,
      0,
      0,
      5,
      8,
      8,
      0,
      11,
      10,
      0,
      8,
      11,
      3,
      4,
      8,
      2,
      8,
      3,
      11,
      3,
      7
    ],
    "orderHold": [
      2,
      2,
      4,
      1,
      3,
      0,
      3,
      4,
      2,
      2,
      1,
      0,
      0,
      2,
      3,
      0,
      1,
      0,
      4,
      2,
      4,
      1,
      1,
      4,
      0,
      4,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      2,
      0,
      2,
      0,
      3,
      4,
      4,
      4,
      0,
      4,
      1,
      3,
      3,
      2,
      2,
      0,
      1,
      0,
      2
    ],
    "orderBackordered": [
      2,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      2,
      0,
      1,
      0,
      2,
      0,
      1,
      2,
      0,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      2,
      4,
      2,
      4,
      3,
      4,
      1,
      4,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      2,
      4,
      0,
      1,
      2,
      4,
      3,
      4,
      0,
      4,
      3,
      4,
      2,
      1,
      0,
      2,
      0,
      3,
      3,
      4,
      3,
      0,
      0,
      1,
      4,
      2,
      4,
      4,
      0,
      4,
      4,
      2,
      0,
      4
    ],
    "issueDelay": [
      0,
      1,
      0,
      3,
      3,
      3,
      2,
      3,
      1,
      1,
      4,
      1,
      3,
      0,
      0,
      0,
      3,
      4,
      2,
      2,
      3,
      1,
      0,
      0,
      0,
      4,
      4,
      2,
      1,
      1,
      3,
      0,
      4,
      2,
      4,
      0,
      1,
      4,
      4,
      4,
      0,
      1,
      3,
      3,
      3,
      0,
      2,
      2,
      4,
      4,
      0,
      3
    ],
    "issueQuantity": [
      2,
      2,
      0,
      1,
      3,
      3,
      0,
      2,
      1,
      2,
      2,
      0,
      0,
      3,
      1,
      1,
      0,
      2,
      0,
      4,
      0,
      4,
      4,
      2,
      3,
      2,
      0,
      3,
      3,
      1,
      2,
      0,
      4,
      2,
      0,
      3,
      4,
      3,
      2,
      4,
      4,
      4,
      3,
      2,
      1,
      4,
      2,
      4,
      0,
      1,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000055",
    "status": "inactive",
    "name": "WellnessWare Group",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Alvin Hatfield",
    "industryRating": 3.6,
    "procedures": "[PR-047,PR-067,PR-068,PR-067,PR-029,PR-064,PR-036,PR-039,PR-038,PR-065]",
    "contracts": "[CNTRCT-0000065,CNTRCT-0000066,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000012,ORDR-1-0000030,ORDR-2-0000037,ORDR-3-0000031,ORDR-4-0000032,ORDR-5-0000020,ORDR-6-0000033,ORDR-7-0000008,ORDR-8-0000029,ORDR-9-0000051,ORDR-10-0000020,ORDR-11-0000015,ORDR-12-0000033,ORDR-13-0000032,ORDR-14-0000069,ORDR-15-0000044,ORDR-16-0000068,ORDR-17-0000063,ORDR-18-0000027]",
    "spendWeekly": [
      3011,
      2973,
      3057,
      2971,
      3004,
      2905,
      3050,
      3040,
      2910,
      2902,
      2937,
      2904,
      2959,
      2901,
      3091,
      2901,
      2927,
      3099,
      3003,
      2973,
      2979,
      3071,
      2956,
      3081,
      3081,
      3025,
      2911,
      3081,
      2902,
      2987,
      2935,
      3033,
      3014,
      2916,
      3075,
      2904,
      2915,
      2934,
      3022,
      2973,
      3011,
      2918,
      2959,
      3041,
      3093,
      2901,
      3042,
      2930,
      3087,
      2997,
      3026,
      3097
    ],
    "orderRejected": [
      3097,
      15,
      16,
      3,
      3,
      0,
      5,
      18,
      2,
      19,
      6,
      19,
      18,
      11,
      12,
      19,
      19,
      11,
      3,
      16,
      4,
      0,
      11,
      8,
      13,
      6,
      10,
      14,
      19,
      19,
      8,
      2,
      0,
      17,
      17,
      2,
      2,
      15,
      14,
      0,
      19,
      10,
      11,
      4,
      19,
      4,
      0,
      14,
      19,
      19,
      16,
      6,
      4
    ],
    "orderAccepted": [
      6,
      7,
      0,
      0,
      4,
      8,
      2,
      7,
      4,
      1,
      9,
      6,
      4,
      6,
      4,
      5,
      8,
      1,
      5,
      11,
      3,
      8,
      3,
      2,
      6,
      2,
      8,
      11,
      4,
      8,
      7,
      8,
      7,
      4,
      4,
      11,
      8,
      0,
      10,
      2,
      1,
      10,
      2,
      1,
      4,
      4,
      2,
      7,
      6,
      6,
      2,
      2
    ],
    "orderHold": [
      2,
      1,
      4,
      1,
      1,
      4,
      3,
      1,
      2,
      1,
      0,
      2,
      3,
      2,
      0,
      3,
      2,
      2,
      4,
      3,
      4,
      1,
      3,
      4,
      0,
      2,
      4,
      2,
      4,
      3,
      2,
      2,
      0,
      2,
      0,
      3,
      2,
      1,
      0,
      2,
      0,
      1,
      4,
      4,
      2,
      2,
      2,
      1,
      0,
      3,
      3,
      1
    ],
    "orderBackordered": [
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      0,
      0,
      0,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      0,
      2,
      0,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      2,
      0,
      2,
      4,
      3,
      1,
      1,
      0,
      3,
      4,
      1,
      1,
      4,
      2,
      1,
      2,
      4,
      1,
      2,
      4,
      0,
      4,
      1,
      1,
      1,
      3,
      0,
      3,
      1,
      1,
      3,
      2,
      4,
      3,
      3,
      2,
      1,
      1,
      3,
      3,
      2,
      0,
      2,
      2,
      1,
      2,
      0,
      3,
      4,
      4,
      4,
      4
    ],
    "issueDelay": [
      2,
      1,
      4,
      2,
      0,
      0,
      4,
      2,
      2,
      1,
      1,
      0,
      3,
      0,
      2,
      2,
      4,
      1,
      2,
      3,
      4,
      2,
      0,
      2,
      4,
      1,
      3,
      2,
      0,
      3,
      3,
      4,
      4,
      1,
      2,
      1,
      1,
      0,
      2,
      4,
      2,
      2,
      4,
      2,
      3,
      3,
      0,
      0,
      2,
      2,
      3,
      0
    ],
    "issueQuantity": [
      1,
      2,
      1,
      4,
      1,
      4,
      1,
      2,
      0,
      4,
      3,
      2,
      0,
      2,
      3,
      1,
      3,
      4,
      0,
      4,
      0,
      2,
      4,
      1,
      2,
      1,
      0,
      3,
      4,
      3,
      1,
      4,
      0,
      4,
      0,
      3,
      4,
      4,
      0,
      3,
      3,
      3,
      3,
      0,
      3,
      1,
      0,
      4,
      4,
      0,
      1,
      3
    ]
  },
  {
    "id": "VNDR-0000056",
    "status": "active",
    "name": "HealthCraft Logistics",
    "tag": "IT Services, Equipment",
    "contactName": "Bryan Lang",
    "industryRating": 2.7,
    "procedures": "[PR-066,PR-066,PR-060,PR-061,PR-056,PR-065,PR-053,PR-061,PR-071,PR-025]",
    "contracts": "[CNTRCT-0000041,CNTRCT-0000053,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000051,ORDR-1-0000052,ORDR-2-0000020,ORDR-3-0000037,ORDR-4-0000023,ORDR-5-0000065,ORDR-6-0000055,ORDR-7-0000011,ORDR-8-0000042,ORDR-9-0000038,ORDR-10-0000010,ORDR-11-0000048,ORDR-12-0000015,ORDR-13-0000054,ORDR-14-0000056,ORDR-15-0000078,ORDR-16-0000016,ORDR-17-0000060,ORDR-18-0000056]",
    "spendWeekly": [
      2996,
      3014,
      2959,
      3010,
      2918,
      2927,
      3100,
      3031,
      2912,
      3046,
      2977,
      2974,
      3013,
      2948,
      3091,
      3097,
      3010,
      2909,
      2979,
      2970,
      2912,
      2983,
      2974,
      2924,
      2955,
      2997,
      3095,
      3024,
      3037,
      2927,
      2903,
      2968,
      3004,
      3025,
      3040,
      2952,
      2985,
      3086,
      3010,
      3016,
      3021,
      2992,
      3097,
      3091,
      2992,
      2997,
      3011,
      2900,
      2941,
      2964,
      3042,
      3015
    ],
    "orderRejected": [
      3015,
      16,
      10,
      3,
      17,
      19,
      13,
      0,
      10,
      18,
      10,
      6,
      16,
      17,
      19,
      12,
      16,
      4,
      0,
      0,
      8,
      19,
      7,
      16,
      18,
      19,
      5,
      4,
      6,
      12,
      9,
      19,
      19,
      19,
      14,
      3,
      14,
      7,
      17,
      14,
      0,
      12,
      11,
      7,
      1,
      15,
      6,
      15,
      19,
      4,
      9,
      11,
      17
    ],
    "orderAccepted": [
      6,
      0,
      3,
      4,
      8,
      5,
      2,
      4,
      0,
      2,
      11,
      6,
      5,
      11,
      8,
      7,
      10,
      7,
      3,
      0,
      3,
      5,
      5,
      0,
      3,
      11,
      3,
      11,
      9,
      6,
      4,
      0,
      0,
      4,
      3,
      6,
      11,
      5,
      11,
      5,
      1,
      9,
      4,
      10,
      0,
      9,
      6,
      11,
      0,
      7,
      0,
      4
    ],
    "orderHold": [
      2,
      2,
      3,
      4,
      3,
      3,
      4,
      1,
      2,
      1,
      3,
      0,
      2,
      3,
      2,
      2,
      4,
      3,
      4,
      3,
      1,
      1,
      0,
      4,
      2,
      2,
      1,
      2,
      4,
      3,
      0,
      3,
      4,
      4,
      4,
      4,
      4,
      0,
      3,
      0,
      0,
      0,
      0,
      1,
      4,
      4,
      2,
      2,
      0,
      4,
      0,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      2,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1
    ],
    "issueQuality": [
      3,
      1,
      0,
      1,
      4,
      1,
      0,
      4,
      0,
      1,
      2,
      3,
      4,
      0,
      0,
      0,
      0,
      4,
      0,
      3,
      3,
      1,
      0,
      3,
      0,
      3,
      1,
      2,
      4,
      2,
      2,
      4,
      0,
      2,
      1,
      3,
      3,
      1,
      3,
      4,
      0,
      3,
      1,
      3,
      2,
      1,
      4,
      3,
      2,
      4,
      0,
      4
    ],
    "issueDelay": [
      1,
      4,
      1,
      2,
      2,
      3,
      1,
      2,
      2,
      4,
      4,
      1,
      4,
      1,
      4,
      4,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      4,
      0,
      2,
      0,
      0,
      4,
      4,
      2,
      0,
      0,
      0,
      3,
      1,
      0,
      4,
      3,
      4,
      3,
      0,
      1,
      4,
      4,
      2,
      1,
      2,
      3,
      0,
      3,
      0
    ],
    "issueQuantity": [
      3,
      0,
      0,
      0,
      4,
      0,
      3,
      2,
      3,
      2,
      0,
      4,
      3,
      2,
      3,
      4,
      4,
      2,
      0,
      4,
      1,
      1,
      2,
      0,
      4,
      4,
      2,
      2,
      3,
      4,
      1,
      3,
      4,
      1,
      1,
      4,
      4,
      0,
      4,
      1,
      4,
      3,
      1,
      0,
      2,
      1,
      4,
      2,
      2,
      3,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000057",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Imaging, PPE, IT Services",
    "contactName": "Jennifer Coleman",
    "industryRating": 3.2,
    "procedures": "[PR-059,PR-065,PR-055,PR-066,PR-046,PR-049,PR-064,PR-064,PR-037,PR-063]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000048,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000025,ORDR-1-0000059,ORDR-2-0000026,ORDR-3-0000047,ORDR-4-0000061,ORDR-5-0000062,ORDR-6-0000021,ORDR-7-0000041,ORDR-8-0000022,ORDR-9-0000060,ORDR-10-0000019,ORDR-11-0000036,ORDR-12-0000014,ORDR-13-0000015,ORDR-14-0000026,ORDR-15-0000053,ORDR-16-0000063,ORDR-17-0000024,ORDR-18-0000075]",
    "spendWeekly": [
      2971,
      3037,
      3019,
      3088,
      2906,
      3062,
      3015,
      3046,
      2902,
      2915,
      3071,
      3016,
      3007,
      3094,
      2961,
      2935,
      2961,
      3099,
      3046,
      2915,
      3100,
      3022,
      3097,
      3097,
      3011,
      3086,
      3032,
      2940,
      3034,
      2973,
      2922,
      2990,
      3045,
      2955,
      3055,
      2934,
      2907,
      2902,
      3059,
      2956,
      3044,
      2930,
      3097,
      3091,
      2933,
      2901,
      2998,
      2900,
      3019,
      2919,
      2905,
      2973
    ],
    "orderRejected": [
      2973,
      13,
      12,
      4,
      0,
      19,
      3,
      11,
      12,
      19,
      13,
      13,
      19,
      9,
      14,
      13,
      19,
      19,
      8,
      6,
      0,
      17,
      3,
      6,
      6,
      8,
      18,
      0,
      7,
      19,
      17,
      6,
      0,
      15,
      11,
      18,
      0,
      7,
      6,
      15,
      8,
      15,
      19,
      0,
      0,
      1,
      0,
      18,
      3,
      15,
      0,
      19,
      16
    ],
    "orderAccepted": [
      11,
      9,
      6,
      6,
      3,
      11,
      8,
      9,
      11,
      2,
      0,
      7,
      0,
      5,
      6,
      5,
      5,
      0,
      4,
      0,
      2,
      0,
      2,
      9,
      7,
      11,
      0,
      11,
      3,
      10,
      11,
      10,
      0,
      0,
      2,
      11,
      5,
      0,
      11,
      0,
      0,
      5,
      8,
      5,
      3,
      11,
      0,
      0,
      0,
      3,
      5,
      10
    ],
    "orderHold": [
      0,
      2,
      4,
      2,
      4,
      3,
      4,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      1,
      4,
      1,
      2,
      4,
      0,
      4,
      0,
      3,
      1,
      2,
      4,
      0,
      2,
      1,
      1,
      0,
      0,
      4,
      4,
      2,
      1,
      0,
      4,
      0,
      2,
      3,
      2,
      3,
      0,
      2,
      0,
      2,
      1,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      0,
      0,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      2,
      4,
      2,
      4,
      0,
      4,
      0,
      3,
      3,
      0,
      4,
      3,
      0,
      4,
      2,
      3,
      0,
      4,
      4,
      0,
      0,
      3,
      2,
      4,
      1,
      0,
      4,
      3,
      0,
      1,
      1,
      0,
      1,
      2,
      4,
      3,
      4,
      2,
      1,
      1,
      2,
      2,
      3,
      3,
      3,
      0,
      3,
      2,
      3,
      0
    ],
    "issueDelay": [
      1,
      1,
      2,
      4,
      0,
      2,
      4,
      3,
      0,
      2,
      1,
      4,
      0,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      1,
      4,
      0,
      0,
      0,
      3,
      4,
      3,
      0,
      4,
      2,
      1,
      4,
      1,
      2,
      1,
      0,
      3,
      2,
      4,
      0,
      4,
      2,
      0,
      4,
      1,
      0,
      2,
      4,
      1,
      4,
      3
    ],
    "issueQuantity": [
      2,
      0,
      3,
      2,
      2,
      0,
      4,
      1,
      0,
      4,
      1,
      2,
      0,
      2,
      3,
      4,
      3,
      2,
      0,
      4,
      1,
      4,
      3,
      1,
      1,
      4,
      1,
      2,
      3,
      4,
      2,
      3,
      4,
      1,
      0,
      4,
      4,
      1,
      0,
      2,
      4,
      4,
      2,
      3,
      2,
      0,
      2,
      1,
      0,
      2,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000058",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Laboratory Supplies, Surgical Instruments",
    "contactName": "Christopher Gonzalez",
    "industryRating": 3,
    "procedures": "[PR-060,PR-067,PR-038,PR-049,PR-061,PR-054,PR-062,PR-021,PR-063,PR-065]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000066,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000010,ORDR-1-0000022,ORDR-2-0000035,ORDR-3-0000030,ORDR-4-0000031,ORDR-5-0000065,ORDR-6-0000017,ORDR-7-0000026,ORDR-8-0000067,ORDR-9-0000073,ORDR-10-0000049,ORDR-11-0000015,ORDR-12-0000059,ORDR-13-0000016,ORDR-14-0000043,ORDR-15-0000048,ORDR-16-0000041,ORDR-17-0000026,ORDR-18-0000031]",
    "spendWeekly": [
      2936,
      3082,
      2906,
      3097,
      3035,
      2905,
      3025,
      2942,
      3079,
      3015,
      3065,
      2904,
      3005,
      2901,
      3091,
      2914,
      3006,
      3086,
      2900,
      3012,
      2920,
      3047,
      3022,
      2938,
      2953,
      3096,
      2948,
      2999,
      2926,
      3009,
      2933,
      2901,
      3036,
      2904,
      2977,
      3007,
      2916,
      2902,
      3005,
      2935,
      2996,
      3100,
      3097,
      3063,
      2902,
      2901,
      2906,
      3085,
      3016,
      3041,
      2958,
      3024
    ],
    "orderRejected": [
      3024,
      19,
      19,
      5,
      7,
      17,
      8,
      3,
      0,
      17,
      5,
      9,
      16,
      9,
      5,
      19,
      18,
      1,
      0,
      1,
      0,
      2,
      9,
      7,
      0,
      19,
      8,
      10,
      9,
      2,
      9,
      18,
      6,
      12,
      9,
      9,
      0,
      19,
      19,
      9,
      5,
      17,
      7,
      4,
      6,
      12,
      7,
      9,
      11,
      6,
      14,
      0,
      17
    ],
    "orderAccepted": [
      4,
      11,
      4,
      3,
      4,
      11,
      9,
      11,
      3,
      8,
      6,
      1,
      0,
      0,
      8,
      8,
      6,
      9,
      0,
      5,
      0,
      10,
      11,
      3,
      9,
      11,
      0,
      7,
      3,
      11,
      11,
      11,
      0,
      0,
      0,
      7,
      5,
      10,
      11,
      0,
      1,
      11,
      1,
      3,
      6,
      10,
      4,
      11,
      1,
      7,
      5,
      11
    ],
    "orderHold": [
      0,
      2,
      3,
      0,
      4,
      3,
      0,
      3,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      4,
      4,
      0,
      1,
      3,
      2,
      3,
      4,
      2,
      0,
      2,
      4,
      2,
      1,
      0,
      2,
      4,
      1,
      3,
      0,
      3,
      0,
      0,
      4,
      0,
      3,
      0,
      4,
      4,
      2,
      3,
      2,
      3,
      0,
      0,
      2,
      0
    ],
    "orderBackordered": [
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      2,
      0,
      0,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      0,
      0,
      3,
      3,
      1,
      4,
      0,
      3,
      0,
      4,
      4,
      0,
      4,
      1,
      1,
      4,
      0,
      1,
      0,
      3,
      0,
      0,
      3,
      0,
      3,
      2,
      0,
      0,
      2,
      1,
      2,
      3,
      0,
      0,
      3,
      1,
      1,
      0,
      3,
      4,
      0,
      1,
      4,
      1,
      1,
      2,
      2,
      1,
      3,
      3,
      2,
      4
    ],
    "issueDelay": [
      0,
      0,
      2,
      2,
      3,
      4,
      3,
      4,
      2,
      1,
      3,
      2,
      1,
      0,
      4,
      3,
      2,
      2,
      1,
      4,
      2,
      1,
      0,
      0,
      3,
      4,
      2,
      3,
      4,
      4,
      4,
      2,
      1,
      1,
      2,
      2,
      0,
      3,
      2,
      1,
      3,
      0,
      1,
      0,
      4,
      0,
      3,
      1,
      4,
      0,
      2,
      3
    ],
    "issueQuantity": [
      2,
      1,
      4,
      0,
      2,
      2,
      0,
      0,
      0,
      4,
      3,
      0,
      0,
      2,
      1,
      4,
      0,
      1,
      2,
      1,
      0,
      2,
      0,
      4,
      4,
      1,
      3,
      2,
      4,
      4,
      0,
      2,
      1,
      4,
      0,
      3,
      4,
      0,
      0,
      3,
      3,
      2,
      3,
      0,
      3,
      0,
      2,
      1,
      2,
      1,
      1,
      1
    ]
  },
  {
    "id": "VNDR-0000059",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Imaging",
    "contactName": "Julie Sanders",
    "industryRating": 4.2,
    "procedures": "[PR-023,PR-065,PR-042,PR-066,PR-064,PR-059,PR-054,PR-031,PR-074,PR-072]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000059,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000043,ORDR-1-0000058,ORDR-2-0000049,ORDR-3-0000031,ORDR-4-0000022,ORDR-5-0000037,ORDR-6-0000042,ORDR-7-0000064,ORDR-8-0000044,ORDR-9-0000008,ORDR-10-0000021,ORDR-11-0000063,ORDR-12-0000014,ORDR-13-0000014,ORDR-14-0000054,ORDR-15-0000014,ORDR-16-0000075,ORDR-17-0000047,ORDR-18-0000063]",
    "spendWeekly": [
      3071,
      3071,
      2906,
      2939,
      2992,
      3047,
      3044,
      2978,
      2902,
      3038,
      3077,
      3088,
      3071,
      3003,
      3008,
      2901,
      2936,
      3099,
      2900,
      2964,
      3047,
      2977,
      3059,
      3015,
      3095,
      3096,
      2943,
      3075,
      2921,
      3028,
      3038,
      3013,
      2981,
      2986,
      2964,
      2943,
      2927,
      3092,
      3099,
      2934,
      2988,
      2939,
      2997,
      3091,
      2979,
      2901,
      2988,
      2900,
      3100,
      2905,
      2989,
      3057
    ],
    "orderRejected": [
      3057,
      19,
      10,
      13,
      10,
      13,
      8,
      18,
      16,
      15,
      0,
      8,
      13,
      18,
      8,
      19,
      10,
      6,
      4,
      13,
      11,
      19,
      6,
      17,
      16,
      11,
      15,
      13,
      19,
      12,
      19,
      13,
      4,
      19,
      14,
      18,
      2,
      12,
      8,
      9,
      17,
      11,
      1,
      0,
      7,
      13,
      3,
      12,
      17,
      16,
      10,
      3,
      19
    ],
    "orderAccepted": [
      8,
      0,
      4,
      11,
      1,
      2,
      11,
      4,
      7,
      0,
      5,
      4,
      11,
      3,
      9,
      0,
      4,
      0,
      3,
      2,
      7,
      3,
      7,
      0,
      8,
      2,
      0,
      11,
      4,
      6,
      0,
      11,
      0,
      0,
      0,
      9,
      4,
      9,
      9,
      6,
      4,
      11,
      6,
      0,
      3,
      4,
      0,
      11,
      2,
      8,
      7,
      0
    ],
    "orderHold": [
      3,
      1,
      2,
      4,
      4,
      1,
      2,
      2,
      3,
      0,
      1,
      3,
      0,
      1,
      4,
      0,
      2,
      1,
      4,
      2,
      4,
      3,
      2,
      3,
      2,
      0,
      4,
      4,
      4,
      0,
      0,
      2,
      1,
      4,
      4,
      1,
      4,
      2,
      3,
      3,
      2,
      0,
      3,
      2,
      2,
      4,
      1,
      0,
      0,
      2,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      0,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      2,
      0,
      0,
      2,
      2,
      1,
      2,
      2,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      0,
      4,
      3,
      3,
      4,
      1,
      3,
      0,
      1,
      0,
      4,
      0,
      0,
      1,
      2,
      2,
      3,
      0,
      0,
      2,
      4,
      1,
      2,
      4,
      0,
      4,
      0,
      2,
      4,
      1,
      1,
      0,
      0,
      4,
      1,
      1,
      4,
      0,
      0,
      2,
      0,
      2,
      4,
      2,
      3,
      2,
      2,
      0,
      3
    ],
    "issueDelay": [
      3,
      0,
      4,
      4,
      1,
      2,
      0,
      3,
      2,
      0,
      2,
      1,
      4,
      3,
      2,
      2,
      3,
      0,
      4,
      4,
      0,
      0,
      4,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      3,
      0,
      4,
      2,
      2,
      3,
      0,
      4,
      4,
      2,
      4,
      0,
      2,
      1,
      3,
      1,
      1,
      4,
      4,
      0,
      3,
      2
    ],
    "issueQuantity": [
      3,
      2,
      1,
      1,
      4,
      0,
      3,
      2,
      3,
      3,
      4,
      2,
      0,
      0,
      3,
      0,
      2,
      2,
      0,
      1,
      2,
      4,
      0,
      3,
      0,
      3,
      3,
      2,
      4,
      1,
      3,
      1,
      1,
      1,
      1,
      4,
      3,
      0,
      3,
      3,
      4,
      4,
      4,
      0,
      2,
      2,
      1,
      2,
      0,
      4,
      0,
      4
    ]
  },
  {
    "id": "VNDR-0000060",
    "status": "active",
    "name": "ProHealth Systems",
    "tag": "Laboratory Supplies",
    "contactName": "Kimberly Griffin",
    "industryRating": 4.4,
    "procedures": "[PR-066,PR-056,PR-045,PR-048,PR-056,PR-055,PR-021,PR-068,PR-056,PR-054]",
    "contracts": "[CNTRCT-0000064,CNTRCT-0000050,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000065,ORDR-1-0000037,ORDR-2-0000005,ORDR-3-0000044,ORDR-4-0000014,ORDR-5-0000026,ORDR-6-0000015,ORDR-7-0000062,ORDR-8-0000045,ORDR-9-0000029,ORDR-10-0000017,ORDR-11-0000036,ORDR-12-0000026,ORDR-13-0000060,ORDR-14-0000034,ORDR-15-0000050,ORDR-16-0000044,ORDR-17-0000064,ORDR-18-0000033]",
    "spendWeekly": [
      2902,
      3082,
      2906,
      2913,
      2950,
      3052,
      3064,
      3098,
      2987,
      3000,
      3044,
      2954,
      3015,
      3094,
      2976,
      3002,
      2994,
      3099,
      2943,
      2924,
      2936,
      2956,
      3097,
      3021,
      3041,
      3096,
      2957,
      2901,
      2901,
      3038,
      2903,
      2937,
      2931,
      2988,
      3095,
      2904,
      2969,
      2937,
      3022,
      2912,
      2901,
      3030,
      2994,
      3091,
      2956,
      2901,
      2972,
      2900,
      2917,
      2905,
      2905,
      2955
    ],
    "orderRejected": [
      2955,
      19,
      0,
      0,
      0,
      19,
      11,
      6,
      19,
      19,
      13,
      12,
      19,
      18,
      6,
      16,
      19,
      2,
      0,
      0,
      5,
      8,
      6,
      15,
      18,
      11,
      10,
      19,
      12,
      19,
      11,
      19,
      19,
      14,
      19,
      18,
      10,
      12,
      6,
      12,
      12,
      13,
      12,
      15,
      4,
      0,
      0,
      18,
      5,
      7,
      0,
      19,
      14
    ],
    "orderAccepted": [
      5,
      5,
      0,
      7,
      0,
      1,
      4,
      3,
      11,
      4,
      6,
      3,
      4,
      11,
      11,
      11,
      0,
      11,
      0,
      2,
      7,
      6,
      2,
      9,
      11,
      8,
      0,
      0,
      6,
      5,
      11,
      5,
      11,
      4,
      10,
      8,
      7,
      3,
      10,
      4,
      0,
      11,
      5,
      1,
      0,
      9,
      0,
      11,
      8,
      9,
      2,
      11
    ],
    "orderHold": [
      4,
      0,
      4,
      2,
      0,
      4,
      1,
      4,
      1,
      2,
      0,
      1,
      2,
      3,
      4,
      4,
      1,
      2,
      0,
      0,
      0,
      3,
      2,
      4,
      1,
      3,
      0,
      3,
      4,
      2,
      0,
      4,
      1,
      3,
      2,
      1,
      4,
      2,
      3,
      2,
      2,
      0,
      1,
      4,
      4,
      3,
      0,
      4,
      2,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      0,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      3,
      2,
      0,
      2,
      4,
      4,
      4,
      2,
      2,
      3,
      2,
      3,
      0,
      4,
      0,
      2,
      4,
      4,
      0,
      4,
      1,
      0,
      2,
      0,
      2,
      4,
      0,
      0,
      3,
      3,
      0,
      4,
      1,
      0,
      0,
      2,
      1,
      2,
      0,
      4,
      4,
      4,
      2,
      2,
      4,
      4,
      1,
      2,
      1
    ],
    "issueDelay": [
      2,
      3,
      1,
      1,
      0,
      4,
      4,
      0,
      0,
      2,
      4,
      4,
      4,
      1,
      1,
      3,
      0,
      0,
      1,
      4,
      0,
      1,
      0,
      3,
      0,
      0,
      3,
      3,
      2,
      4,
      4,
      4,
      2,
      2,
      3,
      3,
      4,
      4,
      0,
      0,
      0,
      2,
      0,
      0,
      4,
      2,
      0,
      2,
      4,
      1,
      2,
      4
    ],
    "issueQuantity": [
      2,
      3,
      1,
      1,
      4,
      2,
      0,
      2,
      3,
      2,
      1,
      2,
      0,
      1,
      4,
      0,
      3,
      2,
      0,
      1,
      3,
      2,
      0,
      0,
      0,
      1,
      1,
      3,
      3,
      0,
      1,
      4,
      4,
      2,
      0,
      4,
      4,
      0,
      0,
      0,
      3,
      3,
      0,
      2,
      3,
      2,
      4,
      3,
      0,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000061",
    "status": "inactive",
    "name": "WellnessWare Group",
    "tag": "Facilities Management, Surgical Instruments, Equipment",
    "contactName": "Kevin Carroll",
    "industryRating": 3.6,
    "procedures": "[PR-036,PR-042,PR-068,PR-031,PR-062,PR-055,PR-065,PR-070,PR-062,PR-062]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000016,CNTRCT-0000020]",
    "orders": "[ORDR-0-0000056,ORDR-1-0000057,ORDR-2-0000034,ORDR-3-0000030,ORDR-4-0000043,ORDR-5-0000004,ORDR-6-0000053,ORDR-7-0000058,ORDR-8-0000034,ORDR-9-0000021,ORDR-10-0000012,ORDR-11-0000045,ORDR-12-0000071,ORDR-13-0000045,ORDR-14-0000023,ORDR-15-0000060,ORDR-16-0000075,ORDR-17-0000035,ORDR-18-0000070]",
    "spendWeekly": [
      2969,
      2968,
      3042,
      2902,
      3067,
      2934,
      3100,
      3098,
      3002,
      3008,
      2930,
      3068,
      2931,
      2909,
      3091,
      2901,
      2979,
      3068,
      2993,
      3093,
      3015,
      3097,
      3047,
      2979,
      3010,
      3017,
      3032,
      3052,
      2983,
      3037,
      2926,
      2942,
      2931,
      2908,
      2992,
      2904,
      2907,
      3097,
      2906,
      3099,
      3034,
      2900,
      3097,
      2922,
      3009,
      3041,
      3039,
      2900,
      3084,
      2954,
      3094,
      3085
    ],
    "orderRejected": [
      3085,
      9,
      5,
      3,
      18,
      19,
      12,
      8,
      7,
      14,
      0,
      3,
      4,
      9,
      13,
      10,
      19,
      0,
      4,
      17,
      15,
      11,
      0,
      11,
      7,
      11,
      13,
      4,
      19,
      5,
      13,
      19,
      19,
      19,
      0,
      13,
      12,
      5,
      17,
      7,
      13,
      19,
      10,
      0,
      19,
      19,
      4,
      16,
      3,
      19,
      0,
      7,
      10
    ],
    "orderAccepted": [
      5,
      6,
      10,
      2,
      8,
      7,
      11,
      9,
      8,
      11,
      7,
      5,
      4,
      1,
      9,
      9,
      0,
      3,
      9,
      11,
      11,
      0,
      8,
      1,
      11,
      0,
      0,
      4,
      7,
      0,
      11,
      8,
      0,
      0,
      6,
      6,
      7,
      0,
      10,
      11,
      1,
      2,
      2,
      8,
      6,
      11,
      7,
      11,
      3,
      11,
      3,
      2
    ],
    "orderHold": [
      2,
      4,
      3,
      3,
      3,
      0,
      2,
      0,
      1,
      4,
      4,
      1,
      1,
      3,
      2,
      2,
      4,
      0,
      4,
      4,
      0,
      1,
      2,
      4,
      1,
      4,
      1,
      1,
      4,
      2,
      2,
      4,
      3,
      4,
      1,
      1,
      2,
      4,
      2,
      4,
      2,
      0,
      2,
      4,
      2,
      4,
      0,
      2,
      2,
      0,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      2,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      0,
      2,
      3,
      4,
      3,
      2,
      4,
      4,
      4,
      0,
      4,
      0,
      1,
      0,
      4,
      1,
      3,
      3,
      2,
      3,
      3,
      0,
      4,
      1,
      4,
      0,
      2,
      4,
      2,
      2,
      1,
      3,
      2,
      1,
      3,
      0,
      0,
      1,
      2,
      1,
      3,
      0,
      0,
      2,
      2,
      0,
      1,
      3,
      0,
      1,
      4
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      0,
      3,
      0,
      1,
      1,
      4,
      0,
      1,
      3,
      4,
      3,
      2,
      1,
      0,
      4,
      1,
      1,
      1,
      3,
      1,
      0,
      3,
      2,
      4,
      2,
      4,
      2,
      2,
      2,
      0,
      4,
      2,
      1,
      3,
      3,
      4,
      3,
      1,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      0,
      0,
      3
    ],
    "issueQuantity": [
      4,
      2,
      3,
      0,
      4,
      4,
      2,
      3,
      3,
      4,
      2,
      4,
      2,
      2,
      3,
      2,
      2,
      4,
      1,
      4,
      0,
      0,
      1,
      4,
      0,
      0,
      0,
      0,
      2,
      3,
      1,
      2,
      2,
      4,
      3,
      3,
      4,
      3,
      3,
      4,
      3,
      0,
      4,
      0,
      1,
      1,
      3,
      3,
      2,
      4,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000062",
    "status": "active",
    "name": "LifeCare Logistics",
    "tag": "Equipment, Facilities Management",
    "contactName": "Steven Wallace",
    "industryRating": 4,
    "procedures": "[PR-060,PR-026,PR-056,PR-067,PR-064,PR-070,PR-059,PR-070,PR-073,PR-062]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000053,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000052,ORDR-1-0000049,ORDR-2-0000033,ORDR-3-0000035,ORDR-4-0000055,ORDR-5-0000057,ORDR-6-0000018,ORDR-7-0000061,ORDR-8-0000009,ORDR-9-0000034,ORDR-10-0000046,ORDR-11-0000050,ORDR-12-0000052,ORDR-13-0000054,ORDR-14-0000053,ORDR-15-0000073,ORDR-16-0000023,ORDR-17-0000024,ORDR-18-0000040]",
    "spendWeekly": [
      3001,
      2980,
      2999,
      3012,
      2986,
      3007,
      2969,
      3069,
      3099,
      2985,
      2997,
      3013,
      2940,
      2902,
      2964,
      2913,
      3046,
      2945,
      2986,
      2976,
      3074,
      3042,
      3044,
      3093,
      2937,
      2901,
      3015,
      3013,
      2930,
      2905,
      2903,
      3060,
      3099,
      2970,
      3037,
      2964,
      2912,
      3066,
      3099,
      3099,
      3040,
      3027,
      3006,
      2977,
      2911,
      2905,
      3099,
      3016,
      2901,
      2905,
      2933,
      3036
    ],
    "orderRejected": [
      3036,
      8,
      9,
      6,
      17,
      7,
      0,
      10,
      7,
      1,
      0,
      19,
      17,
      11,
      16,
      19,
      5,
      7,
      0,
      4,
      6,
      19,
      0,
      0,
      13,
      0,
      8,
      0,
      13,
      12,
      18,
      13,
      13,
      19,
      19,
      19,
      6,
      8,
      17,
      0,
      8,
      18,
      13,
      5,
      9,
      14,
      1,
      6,
      12,
      0,
      7,
      16,
      18
    ],
    "orderAccepted": [
      4,
      11,
      4,
      5,
      6,
      11,
      10,
      2,
      6,
      4,
      5,
      0,
      2,
      2,
      2,
      8,
      0,
      4,
      8,
      11,
      9,
      8,
      6,
      6,
      6,
      3,
      4,
      4,
      7,
      0,
      10,
      10,
      3,
      3,
      6,
      8,
      8,
      4,
      5,
      4,
      3,
      3,
      3,
      0,
      1,
      9,
      7,
      5,
      8,
      9,
      4,
      0
    ],
    "orderHold": [
      3,
      3,
      2,
      0,
      2,
      4,
      3,
      4,
      4,
      1,
      1,
      3,
      4,
      4,
      4,
      0,
      3,
      0,
      0,
      1,
      3,
      4,
      4,
      4,
      2,
      4,
      3,
      2,
      1,
      0,
      2,
      4,
      4,
      2,
      2,
      0,
      2,
      4,
      4,
      1,
      4,
      3,
      2,
      3,
      0,
      4,
      2,
      0,
      1,
      2,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      0,
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      1,
      3,
      0,
      4,
      0,
      0,
      3,
      0,
      2,
      0,
      4,
      2,
      2,
      2,
      1,
      0,
      0,
      2,
      4,
      4,
      3,
      1,
      1,
      2,
      2,
      4,
      2,
      2,
      4,
      1,
      4,
      3,
      1,
      1,
      3,
      1,
      4,
      2,
      4,
      3,
      0,
      0,
      0,
      3,
      2,
      0,
      2,
      0,
      2,
      2,
      0,
      2
    ],
    "issueDelay": [
      0,
      2,
      1,
      4,
      0,
      2,
      2,
      2,
      0,
      0,
      4,
      3,
      3,
      2,
      3,
      2,
      1,
      1,
      0,
      2,
      4,
      1,
      1,
      2,
      1,
      2,
      0,
      4,
      0,
      1,
      3,
      3,
      1,
      2,
      4,
      2,
      1,
      4,
      3,
      2,
      0,
      1,
      1,
      0,
      3,
      2,
      0,
      4,
      2,
      0,
      0,
      3
    ],
    "issueQuantity": [
      3,
      4,
      2,
      0,
      3,
      4,
      3,
      3,
      4,
      1,
      3,
      3,
      3,
      2,
      4,
      4,
      0,
      4,
      0,
      4,
      0,
      4,
      4,
      0,
      0,
      3,
      2,
      2,
      0,
      2,
      1,
      4,
      2,
      1,
      1,
      3,
      3,
      0,
      3,
      3,
      1,
      1,
      4,
      0,
      2,
      3,
      4,
      1,
      0,
      3,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000063",
    "status": "active",
    "name": "VitalCare Distribution",
    "tag": "Laboratory Supplies, Equipment",
    "contactName": "Aaron Howard",
    "industryRating": 4.6,
    "procedures": "[PR-051,PR-054,PR-062,PR-066,PR-063,PR-054,PR-045,PR-054,PR-063,PR-070]",
    "contracts": "[CNTRCT-0000042,CNTRCT-0000059,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000044,ORDR-2-0000058,ORDR-3-0000018,ORDR-4-0000021,ORDR-5-0000018,ORDR-6-0000020,ORDR-7-0000066,ORDR-8-0000025,ORDR-9-0000022,ORDR-10-0000027,ORDR-11-0000029,ORDR-12-0000019,ORDR-13-0000023,ORDR-14-0000041,ORDR-15-0000075,ORDR-16-0000023,ORDR-17-0000023,ORDR-18-0000075]",
    "spendWeekly": [
      3056,
      2989,
      2953,
      2987,
      2903,
      3044,
      3100,
      3063,
      3100,
      3011,
      3022,
      2963,
      2947,
      2999,
      3057,
      3085,
      3054,
      3011,
      2990,
      3049,
      2945,
      2968,
      2925,
      3077,
      2990,
      3044,
      2999,
      3054,
      2922,
      2944,
      2965,
      2901,
      2936,
      3093,
      3035,
      2915,
      3032,
      2977,
      2989,
      2988,
      2957,
      3041,
      3097,
      3091,
      2902,
      2901,
      3099,
      2900,
      3001,
      2905,
      2914,
      3084
    ],
    "orderRejected": [
      3084,
      19,
      1,
      2,
      0,
      12,
      0,
      10,
      12,
      15,
      14,
      14,
      18,
      7,
      13,
      17,
      14,
      0,
      16,
      6,
      8,
      3,
      3,
      19,
      8,
      6,
      13,
      5,
      11,
      12,
      10,
      4,
      14,
      16,
      14,
      10,
      9,
      0,
      8,
      13,
      19,
      19,
      18,
      19,
      2,
      0,
      3,
      8,
      16,
      17,
      0,
      5,
      15
    ],
    "orderAccepted": [
      7,
      7,
      8,
      11,
      0,
      10,
      11,
      3,
      7,
      7,
      5,
      0,
      4,
      6,
      11,
      6,
      0,
      6,
      3,
      2,
      2,
      0,
      9,
      0,
      9,
      6,
      3,
      3,
      9,
      10,
      0,
      5,
      2,
      8,
      11,
      8,
      7,
      3,
      11,
      0,
      3,
      10,
      6,
      5,
      5,
      8,
      0,
      11,
      3,
      8,
      5,
      9
    ],
    "orderHold": [
      1,
      4,
      4,
      4,
      0,
      4,
      4,
      2,
      1,
      4,
      1,
      2,
      2,
      4,
      0,
      3,
      4,
      1,
      1,
      3,
      4,
      3,
      1,
      2,
      2,
      2,
      3,
      1,
      0,
      0,
      2,
      4,
      3,
      3,
      4,
      2,
      2,
      2,
      4,
      4,
      4,
      0,
      1,
      4,
      4,
      1,
      0,
      4,
      0,
      2,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1
    ],
    "issueQuality": [
      0,
      0,
      4,
      4,
      0,
      4,
      1,
      4,
      2,
      0,
      0,
      1,
      2,
      2,
      2,
      2,
      4,
      3,
      2,
      3,
      3,
      2,
      3,
      4,
      1,
      2,
      4,
      1,
      4,
      0,
      0,
      4,
      0,
      0,
      0,
      0,
      3,
      2,
      2,
      3,
      1,
      3,
      4,
      4,
      2,
      0,
      1,
      1,
      2,
      2,
      0,
      3
    ],
    "issueDelay": [
      3,
      2,
      2,
      4,
      0,
      2,
      4,
      1,
      0,
      1,
      2,
      3,
      4,
      4,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      1,
      3,
      4,
      0,
      0,
      2,
      1,
      1,
      4,
      4,
      2,
      0,
      0,
      2,
      4,
      4,
      3,
      1,
      2,
      0,
      0,
      1,
      1,
      4,
      4,
      0,
      4,
      1,
      2,
      3,
      4
    ],
    "issueQuantity": [
      4,
      0,
      3,
      0,
      4,
      3,
      0,
      1,
      0,
      2,
      2,
      2,
      0,
      0,
      4,
      1,
      3,
      3,
      0,
      3,
      0,
      4,
      0,
      2,
      3,
      0,
      4,
      3,
      4,
      1,
      4,
      1,
      1,
      1,
      1,
      3,
      4,
      1,
      2,
      3,
      4,
      0,
      2,
      0,
      3,
      2,
      3,
      4,
      0,
      4,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000064",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "Laboratory Supplies",
    "contactName": "Amanda Glenn",
    "industryRating": 4.3,
    "procedures": "[PR-066,PR-032,PR-068,PR-059,PR-054,PR-053,PR-054,PR-045,PR-044,PR-035]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000065,CNTRCT-0000033]",
    "orders": "[ORDR-0-0000015,ORDR-1-0000041,ORDR-2-0000009,ORDR-3-0000024,ORDR-4-0000039,ORDR-5-0000030,ORDR-6-0000038,ORDR-7-0000036,ORDR-8-0000045,ORDR-9-0000010,ORDR-10-0000034,ORDR-11-0000025,ORDR-12-0000049,ORDR-13-0000037,ORDR-14-0000013,ORDR-15-0000079,ORDR-16-0000035,ORDR-17-0000017,ORDR-18-0000054]",
    "spendWeekly": [
      2992,
      3058,
      2960,
      3097,
      3087,
      2964,
      3086,
      2967,
      2989,
      3012,
      3077,
      3007,
      2930,
      2974,
      3052,
      3025,
      2903,
      3045,
      2930,
      2993,
      3071,
      3097,
      3032,
      3024,
      2906,
      2972,
      2928,
      2919,
      3053,
      3075,
      2957,
      2954,
      2998,
      2904,
      2913,
      3010,
      2907,
      2926,
      3096,
      3081,
      2967,
      3079,
      2968,
      3089,
      2954,
      3036,
      3069,
      2900,
      3100,
      2906,
      3037,
      3076
    ],
    "orderRejected": [
      3076,
      0,
      4,
      4,
      13,
      7,
      16,
      9,
      12,
      7,
      0,
      19,
      3,
      4,
      10,
      12,
      0,
      11,
      11,
      0,
      1,
      9,
      9,
      19,
      10,
      10,
      12,
      8,
      19,
      19,
      19,
      19,
      11,
      19,
      18,
      16,
      0,
      13,
      19,
      7,
      0,
      17,
      10,
      1,
      6,
      5,
      9,
      9,
      19,
      4,
      18,
      15,
      2
    ],
    "orderAccepted": [
      7,
      1,
      7,
      7,
      1,
      9,
      6,
      3,
      7,
      8,
      3,
      0,
      2,
      10,
      10,
      11,
      4,
      8,
      9,
      2,
      1,
      5,
      7,
      0,
      7,
      6,
      3,
      8,
      7,
      6,
      8,
      11,
      6,
      0,
      4,
      9,
      6,
      1,
      6,
      5,
      0,
      1,
      1,
      11,
      0,
      4,
      1,
      4,
      4,
      5,
      6,
      4
    ],
    "orderHold": [
      1,
      2,
      3,
      3,
      0,
      4,
      4,
      2,
      0,
      0,
      3,
      1,
      4,
      1,
      2,
      4,
      1,
      1,
      2,
      2,
      2,
      4,
      0,
      1,
      4,
      3,
      2,
      4,
      1,
      1,
      4,
      4,
      1,
      1,
      0,
      4,
      3,
      2,
      1,
      2,
      2,
      0,
      3,
      4,
      4,
      2,
      3,
      4,
      0,
      4,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      0,
      0,
      2,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      0,
      0,
      0,
      2,
      1,
      1,
      0,
      1,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0
    ],
    "issueQuality": [
      4,
      2,
      0,
      4,
      4,
      1,
      2,
      4,
      0,
      3,
      3,
      0,
      4,
      2,
      0,
      3,
      0,
      0,
      0,
      3,
      0,
      0,
      4,
      4,
      4,
      2,
      2,
      1,
      2,
      2,
      0,
      3,
      0,
      2,
      0,
      2,
      1,
      2,
      1,
      4,
      2,
      2,
      2,
      2,
      0,
      3,
      0,
      4,
      3,
      3,
      1,
      4
    ],
    "issueDelay": [
      2,
      0,
      0,
      4,
      0,
      4,
      0,
      1,
      0,
      1,
      4,
      2,
      3,
      4,
      3,
      4,
      2,
      0,
      1,
      4,
      2,
      2,
      2,
      0,
      3,
      1,
      3,
      0,
      2,
      2,
      3,
      2,
      4,
      0,
      1,
      2,
      3,
      4,
      4,
      4,
      0,
      0,
      2,
      2,
      4,
      4,
      1,
      3,
      3,
      1,
      3,
      0
    ],
    "issueQuantity": [
      4,
      3,
      1,
      1,
      4,
      0,
      4,
      0,
      3,
      3,
      2,
      1,
      0,
      4,
      3,
      3,
      0,
      2,
      2,
      4,
      3,
      2,
      3,
      2,
      2,
      4,
      3,
      4,
      1,
      2,
      4,
      1,
      4,
      4,
      3,
      4,
      3,
      0,
      0,
      4,
      0,
      4,
      3,
      1,
      4,
      1,
      3,
      4,
      1,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000065",
    "status": "inactive",
    "name": "BioVital Supplies",
    "tag": "Nutrition, Imaging, Laboratory Supplies",
    "contactName": "Aaron Howard",
    "industryRating": 3.9,
    "procedures": "[PR-066,PR-050,PR-037,PR-038,PR-064,PR-013,PR-017,PR-031,PR-061,PR-061]",
    "contracts": "[CNTRCT-0000023,CNTRCT-0000061,CNTRCT-0000059]",
    "orders": "[ORDR-0-0000040,ORDR-1-0000019,ORDR-2-0000063,ORDR-3-0000018,ORDR-4-0000020,ORDR-5-0000050,ORDR-6-0000011,ORDR-7-0000017,ORDR-8-0000055,ORDR-9-0000064,ORDR-10-0000059,ORDR-11-0000050,ORDR-12-0000015,ORDR-13-0000027,ORDR-14-0000044,ORDR-15-0000063,ORDR-16-0000049,ORDR-17-0000060,ORDR-18-0000059]",
    "spendWeekly": [
      2918,
      2904,
      2906,
      3006,
      2903,
      3056,
      2952,
      3098,
      2937,
      2930,
      3058,
      2965,
      2937,
      3011,
      3070,
      2906,
      3032,
      3071,
      2900,
      3080,
      2961,
      3022,
      2900,
      2998,
      2990,
      3039,
      2950,
      3080,
      2957,
      2981,
      3064,
      2946,
      2959,
      3073,
      2946,
      2924,
      2997,
      3032,
      2913,
      2972,
      3049,
      3035,
      3025,
      3091,
      3001,
      2901,
      3009,
      2958,
      3086,
      2970,
      3085,
      2952
    ],
    "orderRejected": [
      2952,
      5,
      16,
      6,
      19,
      1,
      13,
      13,
      19,
      6,
      10,
      13,
      17,
      11,
      10,
      9,
      19,
      5,
      14,
      16,
      12,
      13,
      15,
      19,
      13,
      2,
      2,
      4,
      0,
      7,
      9,
      15,
      0,
      12,
      14,
      15,
      17,
      7,
      13,
      10,
      19,
      16,
      19,
      3,
      0,
      4,
      9,
      6,
      19,
      15,
      14,
      19,
      17
    ],
    "orderAccepted": [
      7,
      0,
      7,
      11,
      11,
      4,
      2,
      4,
      0,
      0,
      5,
      3,
      11,
      8,
      6,
      4,
      8,
      7,
      3,
      3,
      0,
      3,
      11,
      3,
      9,
      5,
      0,
      5,
      5,
      9,
      4,
      5,
      1,
      0,
      6,
      7,
      10,
      2,
      10,
      3,
      5,
      11,
      11,
      2,
      11,
      9,
      8,
      11,
      6,
      3,
      6,
      0
    ],
    "orderHold": [
      1,
      0,
      1,
      3,
      2,
      3,
      4,
      1,
      2,
      2,
      3,
      2,
      2,
      0,
      2,
      0,
      2,
      0,
      4,
      2,
      4,
      0,
      4,
      4,
      3,
      4,
      2,
      2,
      4,
      1,
      0,
      4,
      3,
      0,
      0,
      2,
      3,
      2,
      1,
      4,
      1,
      3,
      0,
      4,
      4,
      3,
      3,
      2,
      0,
      1,
      0,
      3
    ],
    "orderBackordered": [
      3,
      2,
      1,
      0,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      2,
      0,
      1,
      2,
      0,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      0,
      2,
      1,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      1,
      2,
      2,
      2,
      3,
      1,
      2,
      2,
      4,
      0,
      1,
      3,
      0,
      1,
      1,
      2,
      0,
      2,
      2,
      4,
      1,
      2,
      2,
      2,
      1,
      0,
      2,
      3,
      4,
      1,
      4,
      3,
      3,
      0,
      1,
      4,
      2,
      0,
      0,
      3,
      3,
      0,
      2,
      4,
      2,
      0,
      1,
      2,
      3,
      1,
      2,
      4
    ],
    "issueDelay": [
      0,
      0,
      0,
      2,
      3,
      1,
      1,
      1,
      1,
      1,
      3,
      2,
      4,
      4,
      4,
      3,
      2,
      0,
      3,
      0,
      2,
      3,
      4,
      4,
      3,
      3,
      1,
      1,
      3,
      2,
      3,
      4,
      4,
      0,
      4,
      4,
      2,
      0,
      2,
      2,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      4,
      1,
      1,
      1,
      0
    ],
    "issueQuantity": [
      4,
      0,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      4,
      2,
      2,
      2,
      1,
      4,
      2,
      3,
      0,
      1,
      4,
      4,
      4,
      2,
      1,
      3,
      4,
      1,
      0,
      1,
      1,
      2,
      2,
      3,
      4,
      0,
      3,
      3,
      3,
      3,
      4,
      0,
      4,
      3,
      2,
      4,
      4,
      3,
      4,
      4,
      2,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000066",
    "status": "inactive",
    "name": "MediFlow Systems",
    "tag": "Facilities Management, Surgical Instruments, Equipment",
    "contactName": "Michael Montgomery",
    "industryRating": 3.6,
    "procedures": "[PR-060,PR-061,PR-061,PR-066,PR-029,PR-071,PR-052,PR-021,PR-047,PR-046]",
    "contracts": "[CNTRCT-0000011,CNTRCT-0000064,CNTRCT-0000009]",
    "orders": "[ORDR-0-0000058,ORDR-1-0000057,ORDR-2-0000062,ORDR-3-0000048,ORDR-4-0000056,ORDR-5-0000027,ORDR-6-0000016,ORDR-7-0000066,ORDR-8-0000063,ORDR-9-0000027,ORDR-10-0000069,ORDR-11-0000058,ORDR-12-0000045,ORDR-13-0000026,ORDR-14-0000031,ORDR-15-0000056,ORDR-16-0000079,ORDR-17-0000075,ORDR-18-0000064]",
    "spendWeekly": [
      2970,
      3069,
      2906,
      3047,
      3032,
      2906,
      2982,
      3098,
      3043,
      3018,
      2982,
      2904,
      2946,
      3094,
      3091,
      3097,
      2965,
      3099,
      3050,
      3044,
      3100,
      3082,
      3068,
      2932,
      2983,
      3096,
      3025,
      2917,
      2984,
      2986,
      2903,
      2913,
      3042,
      2978,
      3021,
      3014,
      2957,
      3009,
      2975,
      3020,
      2945,
      3013,
      3023,
      3091,
      2902,
      2901,
      3076,
      2968,
      2945,
      3097,
      2970,
      3035
    ],
    "orderRejected": [
      3035,
      4,
      19,
      0,
      0,
      11,
      1,
      19,
      19,
      0,
      1,
      5,
      14,
      3,
      1,
      19,
      8,
      4,
      0,
      8,
      5,
      14,
      4,
      13,
      0,
      18,
      19,
      2,
      9,
      17,
      9,
      13,
      15,
      19,
      16,
      0,
      12,
      0,
      4,
      19,
      14,
      10,
      19,
      1,
      10,
      0,
      4,
      14,
      3,
      15,
      16,
      3,
      19
    ],
    "orderAccepted": [
      2,
      7,
      10,
      11,
      2,
      11,
      11,
      9,
      8,
      0,
      2,
      1,
      10,
      2,
      9,
      11,
      3,
      5,
      0,
      0,
      8,
      8,
      5,
      2,
      8,
      9,
      0,
      6,
      0,
      3,
      7,
      9,
      0,
      0,
      0,
      11,
      1,
      2,
      11,
      0,
      6,
      0,
      3,
      3,
      1,
      3,
      3,
      3,
      4,
      8,
      9,
      6
    ],
    "orderHold": [
      4,
      2,
      4,
      4,
      2,
      4,
      4,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      2,
      4,
      2,
      0,
      2,
      3,
      2,
      4,
      4,
      3,
      0,
      0,
      0,
      2,
      2,
      0,
      3,
      1,
      1,
      0,
      4,
      3,
      4,
      4,
      3,
      2,
      3,
      0,
      4,
      4,
      2,
      4,
      0,
      2,
      0,
      0,
      1,
      3
    ],
    "orderBackordered": [
      3,
      1,
      0,
      0,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      2,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      0,
      3,
      2,
      3,
      3,
      4,
      0,
      3,
      4,
      0,
      4,
      3,
      2,
      4,
      1,
      3,
      4,
      4,
      4,
      2,
      4,
      3,
      1,
      3,
      3,
      0,
      4,
      2,
      2,
      4,
      3,
      0,
      4,
      2,
      0,
      0,
      4,
      3,
      3,
      0,
      2,
      4,
      0,
      0,
      0,
      0,
      4,
      2,
      0,
      4
    ],
    "issueDelay": [
      2,
      1,
      2,
      3,
      0,
      3,
      2,
      0,
      0,
      2,
      2,
      4,
      1,
      3,
      4,
      1,
      3,
      1,
      3,
      1,
      0,
      3,
      0,
      0,
      0,
      2,
      4,
      1,
      4,
      1,
      3,
      3,
      4,
      2,
      4,
      1,
      4,
      4,
      1,
      4,
      1,
      4,
      1,
      0,
      0,
      2,
      4,
      2,
      4,
      1,
      2,
      3
    ],
    "issueQuantity": [
      2,
      1,
      2,
      0,
      4,
      4,
      3,
      2,
      2,
      4,
      4,
      2,
      1,
      1,
      2,
      4,
      2,
      2,
      1,
      1,
      4,
      4,
      1,
      1,
      0,
      4,
      2,
      3,
      2,
      4,
      2,
      4,
      4,
      4,
      0,
      4,
      2,
      2,
      0,
      2,
      3,
      4,
      4,
      0,
      3,
      2,
      3,
      4,
      1,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000067",
    "status": "inactive",
    "name": "CarePoint Technologies",
    "tag": "Laboratory Supplies, Pharmaceuticals",
    "contactName": "Kevin Carroll",
    "industryRating": 4.6,
    "procedures": "[PR-060,PR-055,PR-055,PR-044,PR-052,PR-013,PR-067,PR-031,PR-055,PR-027]",
    "contracts": "[CNTRCT-0000035,CNTRCT-0000034,CNTRCT-0000065]",
    "orders": "[ORDR-0-0000043,ORDR-1-0000015,ORDR-2-0000027,ORDR-3-0000018,ORDR-4-0000048,ORDR-5-0000036,ORDR-6-0000031,ORDR-7-0000011,ORDR-8-0000036,ORDR-9-0000065,ORDR-10-0000014,ORDR-11-0000045,ORDR-12-0000034,ORDR-13-0000025,ORDR-14-0000027,ORDR-15-0000052,ORDR-16-0000025,ORDR-17-0000031,ORDR-18-0000033]",
    "spendWeekly": [
      2994,
      2949,
      2963,
      2979,
      2903,
      2905,
      3017,
      2918,
      3100,
      2950,
      2966,
      2958,
      3017,
      3008,
      3077,
      2901,
      3069,
      3003,
      2936,
      3098,
      2995,
      3049,
      3058,
      3046,
      2968,
      2984,
      3041,
      2913,
      2909,
      2986,
      3029,
      2924,
      3044,
      2904,
      2913,
      3002,
      3070,
      3097,
      2966,
      3061,
      3008,
      3021,
      3097,
      3080,
      2902,
      2901,
      2933,
      2936,
      3076,
      2905,
      3013,
      3076
    ],
    "orderRejected": [
      3076,
      9,
      3,
      3,
      11,
      19,
      7,
      9,
      0,
      18,
      2,
      0,
      19,
      7,
      14,
      12,
      19,
      7,
      8,
      12,
      14,
      0,
      16,
      19,
      0,
      19,
      0,
      0,
      9,
      0,
      18,
      2,
      13,
      19,
      4,
      12,
      0,
      2,
      14,
      19,
      19,
      4,
      9,
      9,
      12,
      6,
      19,
      7,
      0,
      0,
      9,
      19,
      2
    ],
    "orderAccepted": [
      0,
      11,
      0,
      6,
      0,
      4,
      9,
      0,
      0,
      11,
      6,
      8,
      6,
      7,
      11,
      10,
      0,
      4,
      6,
      0,
      0,
      4,
      4,
      2,
      10,
      3,
      0,
      5,
      4,
      10,
      9,
      5,
      0,
      5,
      7,
      6,
      11,
      6,
      11,
      7,
      0,
      11,
      5,
      11,
      6,
      5,
      0,
      11,
      11,
      6,
      4,
      11
    ],
    "orderHold": [
      0,
      2,
      4,
      1,
      1,
      4,
      4,
      1,
      0,
      0,
      2,
      2,
      0,
      2,
      0,
      4,
      4,
      3,
      1,
      3,
      2,
      3,
      4,
      4,
      1,
      1,
      0,
      3,
      1,
      4,
      4,
      4,
      4,
      2,
      2,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      0,
      1,
      4,
      2,
      2,
      2,
      0,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      2,
      0,
      1,
      3,
      3,
      4,
      2,
      4,
      1,
      4,
      4,
      0,
      2,
      2,
      0,
      3,
      4,
      3,
      3,
      2,
      3,
      1,
      0,
      0,
      4,
      0,
      1,
      2,
      4,
      3,
      1,
      2,
      2,
      1,
      1,
      3,
      2,
      0,
      0,
      2,
      0,
      2,
      0,
      4,
      2,
      1,
      2,
      1,
      3,
      0,
      1,
      2
    ],
    "issueDelay": [
      0,
      2,
      4,
      2,
      0,
      4,
      4,
      3,
      0,
      2,
      0,
      0,
      2,
      3,
      0,
      2,
      0,
      0,
      1,
      4,
      0,
      0,
      0,
      2,
      3,
      1,
      1,
      2,
      2,
      3,
      2,
      4,
      3,
      1,
      3,
      0,
      0,
      4,
      3,
      0,
      0,
      0,
      1,
      2,
      1,
      0,
      2,
      0,
      3,
      4,
      1,
      4
    ],
    "issueQuantity": [
      1,
      2,
      4,
      2,
      4,
      1,
      1,
      0,
      2,
      3,
      1,
      4,
      0,
      3,
      3,
      1,
      2,
      4,
      3,
      4,
      1,
      2,
      2,
      4,
      0,
      4,
      3,
      0,
      4,
      1,
      2,
      2,
      1,
      4,
      2,
      3,
      4,
      0,
      0,
      0,
      3,
      1,
      3,
      0,
      4,
      0,
      3,
      0,
      2,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000068",
    "status": "inactive",
    "name": "VitalSigns Supplies",
    "tag": "Facilities Management, Equipment",
    "contactName": "Kaitlyn Hernandez",
    "industryRating": 4.3,
    "procedures": "[PR-041,PR-044,PR-068,PR-038,PR-067,PR-050,PR-068,PR-045,PR-071,PR-073]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000034,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000023,ORDR-1-0000033,ORDR-2-0000025,ORDR-3-0000037,ORDR-4-0000002,ORDR-5-0000038,ORDR-6-0000039,ORDR-7-0000040,ORDR-8-0000033,ORDR-9-0000055,ORDR-10-0000057,ORDR-11-0000014,ORDR-12-0000030,ORDR-13-0000050,ORDR-14-0000028,ORDR-15-0000033,ORDR-16-0000037,ORDR-17-0000056,ORDR-18-0000081]",
    "spendWeekly": [
      2937,
      3048,
      2906,
      3068,
      2903,
      3029,
      2981,
      2946,
      3071,
      2975,
      2935,
      2960,
      3065,
      2950,
      3085,
      2944,
      2931,
      3077,
      3039,
      3062,
      3067,
      3064,
      3005,
      3039,
      3010,
      3038,
      3008,
      2901,
      3009,
      3088,
      2910,
      3043,
      3073,
      3022,
      2999,
      3041,
      2912,
      2963,
      2980,
      3054,
      2960,
      3058,
      2917,
      3036,
      2965,
      2901,
      2925,
      2942,
      3097,
      3067,
      2957,
      3097
    ],
    "orderRejected": [
      3097,
      7,
      11,
      7,
      7,
      10,
      6,
      12,
      4,
      0,
      16,
      1,
      5,
      10,
      11,
      14,
      18,
      6,
      13,
      11,
      7,
      12,
      2,
      15,
      10,
      3,
      3,
      4,
      8,
      11,
      15,
      14,
      13,
      10,
      14,
      13,
      5,
      11,
      5,
      3,
      3,
      5,
      4,
      7,
      15,
      11,
      5,
      1,
      0,
      13,
      8,
      6,
      11
    ],
    "orderAccepted": [
      9,
      7,
      6,
      4,
      0,
      10,
      3,
      1,
      0,
      3,
      7,
      2,
      11,
      1,
      9,
      0,
      8,
      3,
      1,
      9,
      0,
      0,
      0,
      0,
      9,
      2,
      0,
      3,
      0,
      7,
      3,
      6,
      1,
      3,
      6,
      5,
      10,
      0,
      10,
      1,
      2,
      11,
      9,
      0,
      0,
      2,
      5,
      9,
      5,
      9,
      6,
      8
    ],
    "orderHold": [
      1,
      3,
      2,
      3,
      3,
      2,
      2,
      3,
      1,
      3,
      2,
      0,
      3,
      3,
      0,
      4,
      2,
      0,
      1,
      3,
      2,
      4,
      4,
      4,
      0,
      4,
      2,
      2,
      3,
      3,
      0,
      3,
      1,
      2,
      4,
      2,
      3,
      0,
      4,
      2,
      0,
      1,
      2,
      4,
      2,
      0,
      3,
      2,
      0,
      0,
      0,
      4
    ],
    "orderBackordered": [
      4,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      4,
      3,
      3,
      1,
      2,
      0,
      2,
      4,
      2,
      1,
      4,
      3,
      2,
      1,
      3,
      3,
      2,
      4,
      1,
      2,
      4,
      0,
      2,
      4,
      0,
      0,
      2,
      2,
      3,
      1,
      1,
      1,
      3,
      2,
      3,
      0,
      4,
      4,
      4,
      1,
      1,
      3,
      4,
      1,
      4,
      1,
      0,
      4,
      1,
      4
    ],
    "issueDelay": [
      2,
      3,
      3,
      1,
      3,
      4,
      3,
      2,
      2,
      2,
      3,
      1,
      4,
      1,
      0,
      1,
      3,
      0,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      0,
      0,
      3,
      0,
      4,
      1,
      3,
      4,
      3,
      1,
      1,
      4,
      4,
      2,
      3,
      0,
      1,
      1,
      4,
      1,
      1,
      0,
      4,
      4,
      1,
      3
    ],
    "issueQuantity": [
      2,
      2,
      1,
      1,
      4,
      3,
      4,
      1,
      3,
      1,
      3,
      1,
      3,
      3,
      3,
      3,
      2,
      1,
      1,
      2,
      3,
      4,
      0,
      4,
      2,
      1,
      4,
      1,
      4,
      2,
      2,
      0,
      0,
      0,
      1,
      4,
      0,
      3,
      1,
      3,
      4,
      2,
      4,
      0,
      3,
      3,
      3,
      3,
      2,
      3,
      0,
      4
    ]
  },
  {
    "id": "VNDR-0000069",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Nutrition, Imaging, Laboratory Supplies",
    "contactName": "Darrell Patterson",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-038,PR-068,PR-059,PR-069,PR-071,PR-043,PR-072,PR-068,PR-074]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000034,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000027,ORDR-1-0000015,ORDR-2-0000016,ORDR-3-0000024,ORDR-4-0000053,ORDR-5-0000064,ORDR-6-0000069,ORDR-7-0000028,ORDR-8-0000032,ORDR-9-0000019,ORDR-10-0000009,ORDR-11-0000058,ORDR-12-0000023,ORDR-13-0000017,ORDR-14-0000073,ORDR-15-0000072,ORDR-16-0000042,ORDR-17-0000039,ORDR-18-0000031]",
    "spendWeekly": [
      2979,
      2932,
      3097,
      2923,
      2976,
      2905,
      3082,
      3098,
      3100,
      2946,
      3000,
      2968,
      2909,
      3094,
      3018,
      3055,
      3012,
      2985,
      2900,
      3098,
      2972,
      3009,
      3087,
      3097,
      2906,
      3096,
      3017,
      3096,
      2901,
      2917,
      2903,
      2901,
      2900,
      3029,
      2967,
      2904,
      2943,
      3097,
      3009,
      3046,
      3040,
      2988,
      3097,
      3091,
      2988,
      3068,
      3099,
      2927,
      3014,
      2905,
      2913,
      2986
    ],
    "orderRejected": [
      2986,
      19,
      13,
      8,
      0,
      12,
      12,
      11,
      18,
      9,
      3,
      4,
      14,
      18,
      19,
      5,
      15,
      19,
      0,
      19,
      16,
      15,
      0,
      13,
      14,
      12,
      2,
      6,
      15,
      17,
      12,
      19,
      3,
      6,
      3,
      0,
      9,
      18,
      6,
      0,
      9,
      19,
      9,
      0,
      15,
      0,
      10,
      18,
      0,
      18,
      10,
      5,
      10
    ],
    "orderAccepted": [
      8,
      11,
      5,
      11,
      10,
      7,
      7,
      10,
      3,
      5,
      6,
      2,
      6,
      8,
      11,
      11,
      7,
      5,
      10,
      6,
      0,
      9,
      11,
      7,
      6,
      2,
      7,
      6,
      11,
      7,
      6,
      1,
      0,
      5,
      4,
      9,
      11,
      0,
      11,
      10,
      1,
      0,
      11,
      11,
      6,
      8,
      1,
      9,
      1,
      11,
      10,
      8
    ],
    "orderHold": [
      1,
      2,
      0,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      3,
      0,
      3,
      2,
      0,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      3,
      2,
      2,
      0,
      0,
      0,
      4,
      0,
      3,
      3,
      4,
      4,
      2,
      3,
      1,
      1,
      4,
      3,
      4,
      2,
      0,
      2,
      2,
      3,
      0,
      3,
      0,
      2,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1
    ],
    "issueQuality": [
      2,
      0,
      0,
      4,
      0,
      4,
      1,
      4,
      4,
      1,
      0,
      1,
      0,
      1,
      3,
      2,
      4,
      3,
      4,
      3,
      2,
      3,
      2,
      3,
      2,
      2,
      0,
      0,
      3,
      3,
      2,
      3,
      3,
      3,
      3,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      4,
      3,
      1,
      3,
      0,
      4,
      0,
      0,
      2
    ],
    "issueDelay": [
      2,
      3,
      2,
      4,
      0,
      2,
      4,
      0,
      3,
      1,
      0,
      1,
      4,
      3,
      2,
      4,
      0,
      1,
      1,
      4,
      1,
      1,
      2,
      4,
      0,
      0,
      4,
      0,
      3,
      3,
      4,
      4,
      2,
      2,
      2,
      0,
      0,
      3,
      3,
      1,
      2,
      0,
      2,
      0,
      4,
      0,
      0,
      1,
      3,
      2,
      1,
      4
    ],
    "issueQuantity": [
      4,
      2,
      3,
      0,
      2,
      2,
      0,
      3,
      4,
      1,
      2,
      4,
      0,
      1,
      2,
      2,
      2,
      0,
      0,
      4,
      3,
      0,
      4,
      4,
      2,
      4,
      3,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      3,
      4,
      3,
      0,
      0,
      3,
      3,
      4,
      4,
      1,
      2,
      4,
      1,
      4,
      0,
      2,
      0,
      2
    ]
  },
  {
    "id": "VNDR-0000070",
    "status": "active",
    "name": "BioVital Supplies",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Richard Mckinney",
    "industryRating": 3.3,
    "procedures": "[PR-055,PR-046,PR-049,PR-055,PR-064,PR-071,PR-053,PR-061,PR-072,PR-041]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000055,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000008,ORDR-1-0000031,ORDR-2-0000037,ORDR-3-0000005,ORDR-4-0000013,ORDR-5-0000030,ORDR-6-0000012,ORDR-7-0000045,ORDR-8-0000067,ORDR-9-0000042,ORDR-10-0000032,ORDR-11-0000013,ORDR-12-0000028,ORDR-13-0000069,ORDR-14-0000016,ORDR-15-0000027,ORDR-16-0000061,ORDR-17-0000060,ORDR-18-0000050]",
    "spendWeekly": [
      2941,
      2931,
      2965,
      2989,
      2994,
      3053,
      3093,
      2975,
      2976,
      2935,
      3097,
      3095,
      3100,
      2903,
      3068,
      3033,
      3053,
      3099,
      3006,
      3098,
      3092,
      2908,
      2937,
      3088,
      2977,
      3032,
      3001,
      2926,
      3096,
      2903,
      3021,
      2942,
      3000,
      3015,
      3025,
      2950,
      2998,
      2982,
      3004,
      3040,
      3027,
      2980,
      3084,
      3052,
      3019,
      2946,
      2944,
      2906,
      3025,
      3000,
      2946,
      2933
    ],
    "orderRejected": [
      2933,
      19,
      1,
      11,
      19,
      19,
      12,
      19,
      14,
      11,
      5,
      5,
      19,
      3,
      13,
      11,
      19,
      16,
      2,
      0,
      9,
      19,
      4,
      13,
      2,
      18,
      0,
      0,
      6,
      11,
      8,
      7,
      6,
      11,
      18,
      8,
      19,
      9,
      14,
      6,
      7,
      19,
      16,
      0,
      19,
      0,
      0,
      17,
      5,
      0,
      9,
      19,
      14
    ],
    "orderAccepted": [
      2,
      7,
      8,
      10,
      5,
      11,
      6,
      0,
      10,
      8,
      3,
      10,
      9,
      4,
      11,
      11,
      0,
      3,
      3,
      0,
      0,
      11,
      5,
      7,
      4,
      11,
      0,
      10,
      2,
      8,
      2,
      5,
      0,
      0,
      7,
      3,
      6,
      0,
      8,
      4,
      3,
      11,
      9,
      7,
      10,
      5,
      6,
      9,
      2,
      7,
      3,
      11
    ],
    "orderHold": [
      3,
      3,
      2,
      3,
      0,
      1,
      3,
      4,
      2,
      0,
      1,
      2,
      0,
      4,
      1,
      1,
      3,
      0,
      3,
      3,
      2,
      0,
      4,
      4,
      1,
      3,
      0,
      1,
      4,
      1,
      2,
      4,
      1,
      0,
      0,
      3,
      4,
      1,
      2,
      2,
      4,
      0,
      3,
      3,
      1,
      3,
      1,
      4,
      0,
      1,
      3,
      2
    ],
    "orderBackordered": [
      2,
      0,
      1,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      2,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      2,
      2,
      0,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      1,
      3,
      0,
      2,
      2,
      2,
      0,
      3,
      3,
      2,
      0,
      2,
      2,
      0,
      4,
      3,
      2,
      4,
      3,
      0,
      2,
      3,
      2,
      4,
      1,
      1,
      4,
      3,
      1,
      3,
      0,
      1,
      3,
      3,
      4,
      0,
      0,
      3,
      4,
      0,
      0,
      4,
      2,
      4,
      0,
      4,
      3,
      4,
      4,
      4
    ],
    "issueDelay": [
      1,
      1,
      4,
      2,
      2,
      2,
      2,
      4,
      2,
      4,
      0,
      2,
      4,
      0,
      3,
      0,
      1,
      0,
      1,
      2,
      4,
      0,
      0,
      2,
      1,
      2,
      4,
      0,
      0,
      3,
      1,
      2,
      1,
      0,
      4,
      4,
      1,
      3,
      4,
      1,
      2,
      2,
      4,
      0,
      4,
      3,
      0,
      0,
      4,
      3,
      1,
      0
    ],
    "issueQuantity": [
      3,
      0,
      0,
      3,
      2,
      3,
      4,
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      2,
      2,
      3,
      0,
      2,
      4,
      0,
      2,
      2,
      2,
      0,
      3,
      0,
      2,
      3,
      2,
      1,
      4,
      4,
      4,
      0,
      4,
      3,
      0,
      0,
      4,
      0,
      4,
      4,
      2,
      3,
      1,
      0,
      0,
      0,
      1,
      2,
      3
    ]
  },
  {
    "id": "VNDR-0000071",
    "status": "active",
    "name": "ProHealth Systems",
    "tag": "Imaging",
    "contactName": "Ashley Anderson",
    "industryRating": 2.6,
    "procedures": "[PR-049,PR-047,PR-029,PR-050,PR-043,PR-064,PR-021,PR-070,PR-066,PR-063]",
    "contracts": "[CNTRCT-0000037,CNTRCT-0000062,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000013,ORDR-1-0000034,ORDR-2-0000034,ORDR-3-0000006,ORDR-4-0000016,ORDR-5-0000011,ORDR-6-0000029,ORDR-7-0000038,ORDR-8-0000029,ORDR-9-0000035,ORDR-10-0000026,ORDR-11-0000020,ORDR-12-0000017,ORDR-13-0000065,ORDR-14-0000053,ORDR-15-0000015,ORDR-16-0000015,ORDR-17-0000069,ORDR-18-0000035]",
    "spendWeekly": [
      2959,
      2989,
      3003,
      3017,
      3046,
      3017,
      3100,
      2907,
      3031,
      2951,
      2941,
      2978,
      3095,
      2901,
      3014,
      2909,
      3012,
      3074,
      2900,
      3098,
      2981,
      3097,
      2981,
      3004,
      2936,
      3096,
      2912,
      2979,
      2901,
      2924,
      3052,
      2943,
      3073,
      2904,
      2983,
      2911,
      3084,
      3003,
      3008,
      3001,
      2924,
      2900,
      3097,
      3036,
      3100,
      2994,
      2910,
      3029,
      3046,
      2905,
      3052,
      2983
    ],
    "orderRejected": [
      2983,
      4,
      18,
      10,
      15,
      15,
      17,
      8,
      3,
      14,
      2,
      17,
      16,
      15,
      16,
      7,
      19,
      11,
      12,
      10,
      1,
      19,
      5,
      19,
      15,
      11,
      0,
      0,
      19,
      5,
      0,
      10,
      7,
      14,
      9,
      12,
      2,
      15,
      15,
      0,
      0,
      19,
      19,
      0,
      18,
      9,
      5,
      13,
      19,
      6,
      9,
      0,
      12
    ],
    "orderAccepted": [
      0,
      9,
      1,
      6,
      7,
      9,
      9,
      5,
      0,
      1,
      4,
      3,
      5,
      1,
      11,
      10,
      1,
      7,
      2,
      3,
      5,
      8,
      11,
      9,
      6,
      5,
      0,
      8,
      2,
      5,
      11,
      0,
      5,
      0,
      3,
      2,
      5,
      0,
      11,
      0,
      1,
      11,
      4,
      6,
      8,
      2,
      8,
      9,
      8,
      5,
      0,
      6
    ],
    "orderHold": [
      3,
      0,
      2,
      0,
      2,
      1,
      4,
      4,
      0,
      1,
      3,
      0,
      4,
      4,
      1,
      2,
      4,
      0,
      4,
      1,
      1,
      3,
      4,
      4,
      1,
      2,
      4,
      4,
      2,
      1,
      4,
      4,
      0,
      4,
      0,
      1,
      3,
      0,
      3,
      3,
      1,
      3,
      3,
      4,
      1,
      3,
      2,
      2,
      0,
      2,
      2,
      0
    ],
    "orderBackordered": [
      0,
      2,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      2,
      2,
      0,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      4,
      2,
      3,
      3,
      1,
      3,
      0,
      4,
      2,
      3,
      1,
      4,
      2,
      1,
      1,
      3,
      4,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      2,
      1,
      3,
      4,
      2,
      4,
      3,
      0,
      3,
      4,
      1,
      2,
      1,
      4,
      2,
      0,
      0,
      3,
      1,
      2,
      0,
      1,
      2,
      4,
      2,
      4
    ],
    "issueDelay": [
      1,
      1,
      4,
      0,
      2,
      1,
      4,
      4,
      1,
      1,
      4,
      3,
      1,
      0,
      3,
      4,
      1,
      2,
      0,
      3,
      2,
      1,
      0,
      3,
      4,
      4,
      2,
      0,
      2,
      1,
      1,
      4,
      4,
      2,
      4,
      2,
      0,
      3,
      4,
      4,
      1,
      0,
      0,
      1,
      4,
      0,
      0,
      0,
      0,
      0,
      0,
      3
    ],
    "issueQuantity": [
      2,
      3,
      2,
      2,
      2,
      0,
      2,
      4,
      0,
      1,
      1,
      3,
      3,
      4,
      1,
      4,
      2,
      2,
      0,
      4,
      1,
      0,
      0,
      3,
      3,
      1,
      3,
      0,
      4,
      3,
      3,
      1,
      4,
      2,
      1,
      4,
      4,
      0,
      0,
      4,
      4,
      1,
      4,
      0,
      3,
      0,
      3,
      1,
      0,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000072",
    "status": "active",
    "name": "PharmaLogix International",
    "tag": "Facilities Management, Equipment",
    "contactName": "Gary Wilson",
    "industryRating": 2.1,
    "procedures": "[PR-052,PR-065,PR-045,PR-049,PR-056,PR-038,PR-061,PR-045,PR-055,PR-065]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000035,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000031,ORDR-1-0000049,ORDR-2-0000008,ORDR-3-0000001,ORDR-4-0000063,ORDR-5-0000062,ORDR-6-0000027,ORDR-7-0000029,ORDR-8-0000052,ORDR-9-0000009,ORDR-10-0000065,ORDR-11-0000018,ORDR-12-0000029,ORDR-13-0000037,ORDR-14-0000061,ORDR-15-0000049,ORDR-16-0000026,ORDR-17-0000024,ORDR-18-0000050]",
    "spendWeekly": [
      2921,
      3064,
      3040,
      2932,
      2972,
      3082,
      3100,
      3098,
      3058,
      2985,
      3069,
      2975,
      2931,
      3094,
      3002,
      2901,
      2977,
      3040,
      2900,
      3013,
      3100,
      2972,
      2901,
      3027,
      3095,
      3096,
      3054,
      2985,
      2939,
      2902,
      2903,
      3017,
      3099,
      3057,
      3021,
      2904,
      3100,
      3000,
      3099,
      3009,
      3038,
      2953,
      3097,
      3091,
      3033,
      2901,
      3099,
      3100,
      2992,
      2905,
      3017,
      2987
    ],
    "orderRejected": [
      2987,
      17,
      10,
      2,
      16,
      19,
      14,
      19,
      0,
      5,
      16,
      12,
      11,
      15,
      18,
      17,
      19,
      0,
      19,
      15,
      15,
      19,
      7,
      9,
      19,
      8,
      15,
      1,
      13,
      17,
      16,
      19,
      0,
      19,
      19,
      19,
      10,
      19,
      19,
      0,
      11,
      19,
      14,
      9,
      0,
      15,
      10,
      9,
      11,
      17,
      0,
      12,
      9
    ],
    "orderAccepted": [
      3,
      11,
      11,
      5,
      0,
      8,
      8,
      4,
      1,
      7,
      5,
      0,
      11,
      8,
      7,
      11,
      9,
      0,
      9,
      7,
      11,
      0,
      6,
      9,
      11,
      10,
      10,
      11,
      9,
      2,
      7,
      5,
      0,
      0,
      9,
      11,
      6,
      10,
      11,
      7,
      8,
      6,
      11,
      11,
      10,
      11,
      5,
      11,
      0,
      11,
      2,
      5
    ],
    "orderHold": [
      2,
      2,
      3,
      3,
      2,
      0,
      3,
      1,
      0,
      2,
      3,
      0,
      0,
      3,
      0,
      0,
      1,
      0,
      4,
      1,
      3,
      2,
      1,
      4,
      0,
      2,
      1,
      1,
      4,
      0,
      1,
      4,
      0,
      2,
      0,
      0,
      0,
      0,
      3,
      3,
      2,
      4,
      2,
      4,
      2,
      2,
      1,
      4,
      0,
      1,
      1,
      0
    ],
    "orderBackordered": [
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      0,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      4,
      0,
      2,
      4,
      1,
      1,
      3,
      2,
      0,
      0,
      2,
      4,
      3,
      0,
      1,
      0,
      2,
      0,
      3,
      2,
      2,
      3,
      4,
      1,
      3,
      3,
      0,
      0,
      2,
      3,
      0,
      0,
      4,
      1,
      0,
      2,
      3,
      4,
      3,
      3,
      1,
      0,
      3,
      4,
      4
    ],
    "issueDelay": [
      2,
      3,
      2,
      4,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      4,
      0,
      2,
      3,
      2,
      0,
      0,
      4,
      2,
      0,
      1,
      4,
      0,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      3,
      4,
      2,
      2,
      4,
      4,
      2,
      3,
      0,
      1,
      1,
      3,
      3,
      0,
      1,
      3,
      2,
      0,
      3
    ],
    "issueQuantity": [
      3,
      2,
      4,
      1,
      4,
      4,
      1,
      3,
      2,
      1,
      0,
      0,
      0,
      2,
      0,
      4,
      0,
      0,
      0,
      3,
      1,
      3,
      2,
      0,
      2,
      3,
      2,
      4,
      3,
      0,
      2,
      2,
      3,
      4,
      0,
      1,
      4,
      2,
      0,
      4,
      4,
      3,
      4,
      0,
      2,
      4,
      2,
      2,
      0,
      2,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000073",
    "status": "active",
    "name": "HealthCraft Logistics",
    "tag": "Equipment, Facilities Management",
    "contactName": "Justin Molina",
    "industryRating": 3.6,
    "procedures": "[PR-063,PR-032,PR-068,PR-069,PR-046,PR-036,PR-067,PR-058,PR-020,PR-051]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000060,CNTRCT-0000026]",
    "orders": "[ORDR-0-0000065,ORDR-1-0000029,ORDR-2-0000034,ORDR-3-0000011,ORDR-4-0000012,ORDR-5-0000051,ORDR-6-0000038,ORDR-7-0000066,ORDR-8-0000017,ORDR-9-0000032,ORDR-10-0000041,ORDR-11-0000039,ORDR-12-0000068,ORDR-13-0000015,ORDR-14-0000073,ORDR-15-0000059,ORDR-16-0000064,ORDR-17-0000019,ORDR-18-0000082]",
    "spendWeekly": [
      2901,
      3061,
      2962,
      2968,
      3056,
      2905,
      3062,
      3039,
      3100,
      3050,
      3012,
      3095,
      3039,
      2967,
      2939,
      2938,
      3008,
      3013,
      2986,
      3098,
      3011,
      2903,
      3077,
      2990,
      3011,
      2989,
      2957,
      3088,
      2901,
      2999,
      2906,
      2901,
      3075,
      3037,
      2954,
      3100,
      3075,
      3097,
      3007,
      3053,
      3029,
      3058,
      3097,
      3003,
      3024,
      2901,
      3001,
      3000,
      3030,
      3008,
      2935,
      3094
    ],
    "orderRejected": [
      3094,
      19,
      19,
      10,
      19,
      19,
      2,
      19,
      0,
      8,
      10,
      0,
      0,
      2,
      11,
      12,
      16,
      19,
      8,
      2,
      9,
      14,
      12,
      14,
      19,
      17,
      10,
      4,
      18,
      8,
      9,
      19,
      14,
      19,
      12,
      7,
      10,
      11,
      8,
      4,
      11,
      19,
      1,
      10,
      6,
      6,
      0,
      0,
      13,
      19,
      6,
      2,
      2
    ],
    "orderAccepted": [
      2,
      8,
      2,
      0,
      7,
      7,
      1,
      9,
      5,
      8,
      3,
      5,
      10,
      6,
      10,
      9,
      0,
      4,
      5,
      9,
      7,
      0,
      6,
      0,
      11,
      9,
      0,
      8,
      1,
      0,
      5,
      2,
      2,
      4,
      4,
      11,
      11,
      2,
      3,
      7,
      8,
      11,
      4,
      10,
      8,
      2,
      2,
      11,
      5,
      7,
      8,
      3
    ],
    "orderHold": [
      2,
      1,
      2,
      3,
      0,
      4,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      3,
      4,
      4,
      0,
      4,
      2,
      4,
      4,
      4,
      3,
      3,
      2,
      4,
      2,
      0,
      3,
      3,
      0,
      0,
      0,
      4,
      4,
      0,
      1,
      2,
      3,
      2,
      4,
      4,
      0,
      2,
      4,
      0,
      0,
      2,
      3,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      0,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      0,
      0,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      2,
      1,
      2,
      4,
      2,
      2,
      2,
      3,
      2,
      0,
      4,
      0,
      1,
      3,
      0,
      2,
      3,
      0,
      2,
      4,
      0,
      0,
      0,
      4,
      2,
      2,
      2,
      2,
      4,
      3,
      2,
      3,
      0,
      0,
      2,
      0,
      4,
      4,
      2,
      1,
      0,
      2,
      1,
      4,
      0,
      0,
      0,
      4,
      3,
      0,
      0,
      2
    ],
    "issueDelay": [
      3,
      0,
      4,
      4,
      2,
      4,
      2,
      1,
      1,
      2,
      1,
      4,
      4,
      0,
      0,
      0,
      3,
      0,
      1,
      2,
      2,
      0,
      3,
      2,
      3,
      1,
      0,
      2,
      2,
      1,
      3,
      1,
      3,
      0,
      4,
      4,
      4,
      2,
      3,
      1,
      0,
      1,
      0,
      0,
      4,
      2,
      2,
      0,
      3,
      2,
      0,
      0
    ],
    "issueQuantity": [
      4,
      1,
      0,
      2,
      2,
      4,
      0,
      2,
      1,
      1,
      1,
      4,
      1,
      0,
      4,
      3,
      3,
      4,
      1,
      0,
      1,
      3,
      4,
      3,
      4,
      1,
      2,
      1,
      3,
      1,
      1,
      0,
      4,
      0,
      0,
      3,
      4,
      0,
      0,
      2,
      4,
      1,
      4,
      3,
      1,
      2,
      4,
      1,
      0,
      4,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000074",
    "status": "inactive",
    "name": "HealthAxis Supplies",
    "tag": "Equipment, IT Services",
    "contactName": "Michael Alexander",
    "industryRating": 3.3,
    "procedures": "[PR-061,PR-043,PR-059,PR-045,PR-054,PR-068,PR-056,PR-021,PR-066,PR-046]",
    "contracts": "[CNTRCT-0000013,CNTRCT-0000044,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000037,ORDR-2-0000020,ORDR-3-0000056,ORDR-4-0000033,ORDR-5-0000004,ORDR-6-0000016,ORDR-7-0000070,ORDR-8-0000016,ORDR-9-0000068,ORDR-10-0000062,ORDR-11-0000051,ORDR-12-0000064,ORDR-13-0000070,ORDR-14-0000077,ORDR-15-0000047,ORDR-16-0000024,ORDR-17-0000028,ORDR-18-0000051]",
    "spendWeekly": [
      2986,
      3037,
      3003,
      2902,
      2903,
      2999,
      2905,
      3034,
      2991,
      3022,
      3031,
      2984,
      3100,
      3074,
      2903,
      3016,
      2995,
      3078,
      3022,
      3055,
      3066,
      3072,
      2971,
      3097,
      2973,
      2956,
      2940,
      3066,
      2928,
      2936,
      3031,
      2913,
      3007,
      2911,
      2913,
      3018,
      2992,
      3032,
      3007,
      2973,
      2940,
      2979,
      2949,
      2949,
      3045,
      2989,
      3020,
      2936,
      2933,
      2960,
      2945,
      2901
    ],
    "orderRejected": [
      2901,
      11,
      11,
      11,
      2,
      0,
      5,
      2,
      12,
      12,
      17,
      3,
      10,
      18,
      4,
      19,
      19,
      16,
      13,
      3,
      15,
      18,
      14,
      19,
      19,
      7,
      12,
      17,
      6,
      8,
      19,
      13,
      16,
      18,
      18,
      14,
      11,
      6,
      15,
      8,
      2,
      19,
      9,
      18,
      7,
      7,
      8,
      18,
      5,
      9,
      2,
      11,
      19
    ],
    "orderAccepted": [
      8,
      11,
      9,
      9,
      4,
      0,
      9,
      0,
      2,
      6,
      0,
      9,
      2,
      5,
      6,
      10,
      3,
      2,
      4,
      4,
      5,
      8,
      5,
      11,
      10,
      11,
      0,
      6,
      6,
      8,
      8,
      4,
      0,
      1,
      8,
      10,
      11,
      6,
      8,
      4,
      4,
      11,
      2,
      3,
      11,
      5,
      0,
      11,
      3,
      0,
      3,
      10
    ],
    "orderHold": [
      1,
      2,
      2,
      1,
      4,
      2,
      4,
      4,
      4,
      1,
      2,
      0,
      3,
      2,
      0,
      0,
      1,
      1,
      2,
      3,
      2,
      3,
      4,
      2,
      2,
      0,
      0,
      2,
      4,
      1,
      0,
      4,
      2,
      0,
      1,
      4,
      2,
      4,
      3,
      2,
      4,
      3,
      4,
      4,
      2,
      1,
      2,
      3,
      3,
      1,
      3,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      2,
      1,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      4,
      0,
      4,
      0,
      2,
      0,
      0,
      0,
      2,
      1,
      3,
      2,
      1,
      1,
      0,
      3,
      3,
      3,
      1,
      1,
      4,
      4,
      1,
      1,
      0,
      3,
      2,
      4,
      0,
      2,
      2,
      0,
      0,
      0,
      1,
      1,
      2,
      0,
      4,
      0,
      0,
      4,
      2,
      0,
      0,
      3,
      4,
      0,
      4,
      2,
      2
    ],
    "issueDelay": [
      1,
      0,
      3,
      1,
      4,
      1,
      2,
      2,
      2,
      2,
      3,
      3,
      3,
      3,
      4,
      2,
      2,
      4,
      4,
      2,
      0,
      2,
      0,
      1,
      2,
      4,
      3,
      4,
      0,
      4,
      2,
      2,
      2,
      2,
      3,
      2,
      2,
      3,
      4,
      1,
      0,
      3,
      3,
      1,
      4,
      3,
      2,
      2,
      4,
      2,
      0,
      4
    ],
    "issueQuantity": [
      2,
      3,
      3,
      4,
      0,
      4,
      1,
      0,
      3,
      0,
      2,
      2,
      3,
      1,
      2,
      3,
      0,
      3,
      2,
      3,
      0,
      4,
      0,
      3,
      3,
      3,
      3,
      4,
      4,
      0,
      2,
      4,
      4,
      1,
      2,
      4,
      2,
      2,
      1,
      1,
      1,
      3,
      4,
      4,
      1,
      3,
      3,
      4,
      1,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000075",
    "status": "active",
    "name": "ProHealth Systems",
    "tag": "PPE",
    "contactName": "Jennifer Harris",
    "industryRating": 2.8,
    "procedures": "[PR-062,PR-022,PR-042,PR-059,PR-055,PR-065,PR-068,PR-049,PR-073,PR-072]",
    "contracts": "[CNTRCT-0000045,CNTRCT-0000060,CNTRCT-0000049]",
    "orders": "[ORDR-0-0000012,ORDR-1-0000011,ORDR-2-0000055,ORDR-3-0000046,ORDR-4-0000025,ORDR-5-0000011,ORDR-6-0000041,ORDR-7-0000025,ORDR-8-0000026,ORDR-9-0000035,ORDR-10-0000047,ORDR-11-0000060,ORDR-12-0000066,ORDR-13-0000049,ORDR-14-0000041,ORDR-15-0000032,ORDR-16-0000047,ORDR-17-0000039,ORDR-18-0000070]",
    "spendWeekly": [
      3086,
      3045,
      3051,
      2909,
      2967,
      3080,
      3052,
      2927,
      3016,
      3022,
      3022,
      3085,
      2909,
      2956,
      2959,
      2901,
      2922,
      3003,
      2944,
      3092,
      3097,
      3045,
      2982,
      2975,
      2944,
      3021,
      2970,
      2901,
      2949,
      2902,
      2929,
      2933,
      3040,
      3007,
      2944,
      2996,
      2967,
      3086,
      3001,
      3051,
      3042,
      3059,
      3074,
      2971,
      2968,
      2901,
      2979,
      2976,
      2973,
      2937,
      3075,
      3086
    ],
    "orderRejected": [
      3086,
      15,
      16,
      11,
      9,
      16,
      6,
      16,
      15,
      19,
      0,
      10,
      13,
      9,
      12,
      16,
      9,
      0,
      1,
      7,
      1,
      10,
      6,
      19,
      19,
      8,
      5,
      6,
      17,
      10,
      12,
      4,
      13,
      13,
      11,
      3,
      8,
      15,
      14,
      0,
      12,
      19,
      10,
      6,
      15,
      17,
      10,
      2,
      19,
      11,
      9,
      1,
      16
    ],
    "orderAccepted": [
      7,
      11,
      10,
      4,
      5,
      11,
      5,
      6,
      9,
      0,
      1,
      11,
      11,
      5,
      7,
      11,
      0,
      7,
      7,
      6,
      6,
      8,
      1,
      2,
      4,
      5,
      8,
      3,
      11,
      7,
      11,
      10,
      0,
      3,
      2,
      10,
      1,
      10,
      1,
      10,
      9,
      11,
      6,
      0,
      3,
      6,
      9,
      1,
      0,
      11,
      5,
      1
    ],
    "orderHold": [
      2,
      3,
      3,
      3,
      1,
      4,
      3,
      2,
      0,
      0,
      2,
      0,
      0,
      2,
      0,
      2,
      3,
      0,
      4,
      2,
      3,
      2,
      0,
      4,
      1,
      4,
      0,
      0,
      2,
      2,
      2,
      3,
      1,
      0,
      0,
      2,
      3,
      2,
      2,
      1,
      1,
      3,
      3,
      3,
      2,
      1,
      0,
      1,
      1,
      2,
      2,
      2
    ],
    "orderBackordered": [
      2,
      0,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      3,
      4,
      2,
      3,
      1,
      4,
      1,
      4,
      4,
      1,
      2,
      1,
      4,
      1,
      0,
      4,
      0,
      1,
      2,
      3,
      0,
      0,
      2,
      3,
      1,
      3,
      4,
      3,
      4,
      2,
      2,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      3,
      1,
      2,
      0,
      2,
      3,
      2,
      0,
      1,
      1,
      0,
      0,
      0,
      2
    ],
    "issueDelay": [
      0,
      2,
      3,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      3,
      4,
      4,
      0,
      2,
      0,
      0,
      3,
      2,
      0,
      2,
      0,
      0,
      2,
      2,
      2,
      2,
      1,
      3,
      4,
      0,
      2,
      2,
      1,
      4,
      3,
      4,
      0,
      2,
      4,
      0,
      0,
      4,
      4,
      1,
      4,
      4,
      0,
      1,
      2
    ],
    "issueQuantity": [
      4,
      0,
      4,
      2,
      4,
      2,
      1,
      1,
      3,
      3,
      0,
      2,
      0,
      3,
      4,
      1,
      2,
      4,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      3,
      1,
      4,
      2,
      4,
      3,
      2,
      2,
      3,
      2,
      3,
      1,
      1,
      1,
      3,
      2,
      4,
      2,
      0,
      4,
      3,
      0,
      1,
      0,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000076",
    "status": "inactive",
    "name": "MediFlow Systems",
    "tag": "PPE",
    "contactName": "Albert Flowers",
    "industryRating": 3.7,
    "procedures": "[PR-059,PR-063,PR-060,PR-037,PR-068,PR-061,PR-067,PR-057,PR-070,PR-068]",
    "contracts": "[CNTRCT-0000055,CNTRCT-0000053,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000005,ORDR-1-0000020,ORDR-2-0000054,ORDR-3-0000035,ORDR-4-0000034,ORDR-5-0000045,ORDR-6-0000038,ORDR-7-0000036,ORDR-8-0000019,ORDR-9-0000034,ORDR-10-0000060,ORDR-11-0000054,ORDR-12-0000057,ORDR-13-0000032,ORDR-14-0000059,ORDR-15-0000049,ORDR-16-0000015,ORDR-17-0000036,ORDR-18-0000081]",
    "spendWeekly": [
      3097,
      2990,
      2959,
      2984,
      3044,
      2905,
      3081,
      2931,
      2902,
      2988,
      3100,
      2904,
      2909,
      2927,
      3091,
      2901,
      2999,
      3099,
      2923,
      3098,
      3061,
      3083,
      2920,
      2934,
      2956,
      3087,
      3095,
      3096,
      2963,
      3011,
      2903,
      2901,
      3063,
      2973,
      2913,
      2904,
      2922,
      3097,
      3041,
      2908,
      2993,
      2951,
      2969,
      3091,
      2902,
      2971,
      3005,
      2962,
      3072,
      2905,
      2980,
      2983
    ],
    "orderRejected": [
      2983,
      16,
      10,
      11,
      7,
      19,
      19,
      11,
      11,
      15,
      2,
      13,
      19,
      4,
      6,
      8,
      19,
      0,
      13,
      9,
      6,
      11,
      11,
      9,
      3,
      14,
      9,
      0,
      18,
      3,
      0,
      18,
      0,
      16,
      14,
      14,
      11,
      19,
      18,
      13,
      10,
      19,
      11,
      0,
      9,
      15,
      0,
      17,
      19,
      14,
      19,
      5,
      1
    ],
    "orderAccepted": [
      11,
      8,
      1,
      2,
      1,
      6,
      3,
      11,
      0,
      0,
      11,
      10,
      0,
      1,
      11,
      11,
      4,
      4,
      8,
      11,
      11,
      7,
      6,
      5,
      0,
      9,
      0,
      11,
      11,
      5,
      6,
      11,
      4,
      3,
      9,
      5,
      11,
      3,
      10,
      6,
      0,
      9,
      5,
      4,
      7,
      9,
      0,
      11,
      7,
      2,
      0,
      11
    ],
    "orderHold": [
      3,
      2,
      3,
      3,
      4,
      4,
      1,
      3,
      0,
      4,
      1,
      0,
      0,
      1,
      0,
      1,
      3,
      1,
      1,
      2,
      0,
      4,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      0,
      2,
      4,
      1,
      3,
      0,
      2,
      1,
      1,
      3,
      2,
      3,
      0,
      3,
      4,
      3,
      4,
      4,
      1,
      0,
      0,
      2,
      1
    ],
    "orderBackordered": [
      1,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      0,
      0,
      2,
      0,
      2,
      0,
      0,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      4,
      2,
      1,
      2,
      1,
      3,
      1,
      1,
      1,
      4,
      0,
      2,
      1,
      3,
      2,
      3,
      1,
      4,
      4,
      4,
      1,
      0,
      4,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      4,
      4,
      3,
      0,
      4,
      4,
      2,
      3,
      2,
      2,
      4,
      1,
      3,
      4
    ],
    "issueDelay": [
      0,
      4,
      2,
      4,
      3,
      4,
      4,
      2,
      1,
      3,
      2,
      0,
      4,
      0,
      2,
      4,
      2,
      0,
      2,
      4,
      4,
      1,
      2,
      1,
      1,
      0,
      4,
      0,
      4,
      4,
      4,
      4,
      1,
      2,
      0,
      0,
      1,
      3,
      4,
      3,
      4,
      0,
      4,
      2,
      4,
      0,
      2,
      0,
      4,
      4,
      1,
      2
    ],
    "issueQuantity": [
      3,
      4,
      3,
      0,
      0,
      2,
      0,
      0,
      0,
      3,
      0,
      2,
      0,
      1,
      4,
      1,
      2,
      2,
      0,
      2,
      0,
      0,
      0,
      2,
      2,
      1,
      3,
      2,
      4,
      3,
      2,
      4,
      2,
      4,
      0,
      4,
      4,
      0,
      3,
      4,
      2,
      3,
      4,
      2,
      4,
      2,
      3,
      2,
      3,
      2,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000077",
    "status": "inactive",
    "name": "CarePoint Technologies",
    "tag": "Nutrition, Imaging, Equipment",
    "contactName": "Duane Smith",
    "industryRating": 2.5,
    "procedures": "[PR-061,PR-021,PR-068,PR-039,PR-070,PR-033,PR-068,PR-051,PR-069,PR-046]",
    "contracts": "[CNTRCT-0000064,CNTRCT-0000034,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000037,ORDR-1-0000054,ORDR-2-0000013,ORDR-3-0000014,ORDR-4-0000037,ORDR-5-0000061,ORDR-6-0000024,ORDR-7-0000017,ORDR-8-0000034,ORDR-9-0000029,ORDR-10-0000066,ORDR-11-0000056,ORDR-12-0000070,ORDR-13-0000042,ORDR-14-0000046,ORDR-15-0000037,ORDR-16-0000036,ORDR-17-0000050,ORDR-18-0000040]",
    "spendWeekly": [
      3097,
      2932,
      2976,
      3069,
      2960,
      3085,
      3100,
      3018,
      2966,
      2934,
      3061,
      2934,
      2969,
      3062,
      3091,
      3069,
      3080,
      3099,
      2928,
      2909,
      2955,
      3007,
      2900,
      2992,
      3014,
      3075,
      2909,
      2902,
      2901,
      2902,
      3096,
      3049,
      3061,
      3069,
      2913,
      3100,
      3007,
      2902,
      2998,
      3097,
      2961,
      3086,
      3097,
      3091,
      2995,
      2901,
      3066,
      2959,
      3098,
      2943,
      2905,
      2970
    ],
    "orderRejected": [
      2970,
      1,
      6,
      15,
      9,
      8,
      2,
      19,
      19,
      3,
      3,
      1,
      19,
      18,
      19,
      17,
      12,
      4,
      0,
      8,
      12,
      19,
      11,
      13,
      16,
      3,
      3,
      7,
      0,
      18,
      8,
      14,
      3,
      18,
      9,
      19,
      16,
      8,
      19,
      11,
      19,
      19,
      8,
      0,
      7,
      0,
      0,
      11,
      14,
      12,
      19,
      5,
      19
    ],
    "orderAccepted": [
      7,
      11,
      11,
      11,
      0,
      11,
      6,
      5,
      1,
      10,
      5,
      2,
      11,
      2,
      1,
      11,
      0,
      6,
      0,
      1,
      4,
      9,
      0,
      7,
      9,
      5,
      0,
      11,
      8,
      9,
      11,
      6,
      0,
      4,
      11,
      5,
      0,
      0,
      11,
      8,
      0,
      9,
      11,
      11,
      9,
      11,
      0,
      11,
      0,
      5,
      1,
      11
    ],
    "orderHold": [
      1,
      0,
      0,
      2,
      2,
      2,
      2,
      4,
      0,
      0,
      1,
      0,
      0,
      2,
      2,
      4,
      0,
      0,
      2,
      0,
      2,
      1,
      4,
      3,
      0,
      2,
      2,
      0,
      3,
      1,
      1,
      4,
      2,
      1,
      1,
      2,
      1,
      0,
      3,
      3,
      1,
      1,
      4,
      4,
      2,
      4,
      0,
      3,
      2,
      0,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      2,
      2,
      4,
      4,
      0,
      3,
      0,
      4,
      3,
      3,
      4,
      1,
      1,
      1,
      0,
      0,
      4,
      4,
      2,
      4,
      2,
      0,
      4,
      2,
      0,
      3,
      1,
      0,
      2,
      0,
      3,
      3,
      2,
      3,
      2,
      0,
      1,
      0,
      2,
      0,
      0,
      3,
      3,
      2,
      2,
      0,
      4,
      2,
      2,
      2,
      4,
      4
    ],
    "issueDelay": [
      0,
      3,
      4,
      4,
      1,
      3,
      3,
      2,
      2,
      4,
      0,
      1,
      1,
      3,
      3,
      3,
      0,
      2,
      2,
      2,
      0,
      3,
      1,
      4,
      0,
      4,
      3,
      4,
      1,
      3,
      2,
      3,
      2,
      1,
      3,
      2,
      0,
      2,
      4,
      2,
      0,
      0,
      3,
      2,
      3,
      2,
      3,
      0,
      3,
      0,
      4,
      3
    ],
    "issueQuantity": [
      4,
      2,
      3,
      1,
      1,
      2,
      1,
      0,
      3,
      0,
      1,
      4,
      0,
      1,
      2,
      2,
      3,
      1,
      0,
      2,
      3,
      1,
      0,
      3,
      3,
      3,
      3,
      2,
      2,
      3,
      4,
      2,
      4,
      2,
      2,
      3,
      1,
      0,
      3,
      2,
      0,
      4,
      4,
      2,
      4,
      1,
      0,
      3,
      1,
      2,
      1,
      2
    ]
  },
  {
    "id": "VNDR-0000078",
    "status": "active",
    "name": "BioHealth Systems",
    "tag": "Equipment, Facilities Management",
    "contactName": "Jennifer Harris",
    "industryRating": 3.1,
    "procedures": "[PR-060,PR-050,PR-068,PR-050,PR-056,PR-058,PR-054,PR-039,PR-055,PR-027]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000034,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000049,ORDR-1-0000012,ORDR-2-0000041,ORDR-3-0000034,ORDR-4-0000030,ORDR-5-0000030,ORDR-6-0000042,ORDR-7-0000071,ORDR-8-0000018,ORDR-9-0000030,ORDR-10-0000045,ORDR-11-0000054,ORDR-12-0000020,ORDR-13-0000065,ORDR-14-0000037,ORDR-15-0000039,ORDR-16-0000048,ORDR-17-0000065,ORDR-18-0000063]",
    "spendWeekly": [
      2972,
      2980,
      2906,
      3053,
      2903,
      2905,
      3098,
      2989,
      3022,
      2944,
      3020,
      2934,
      3035,
      2965,
      3001,
      2951,
      3060,
      3099,
      2900,
      3042,
      3047,
      3008,
      3097,
      3094,
      2951,
      3054,
      3062,
      2980,
      2993,
      2996,
      2986,
      3051,
      2968,
      2904,
      3048,
      3014,
      2996,
      2959,
      2956,
      3036,
      2967,
      2984,
      3067,
      3091,
      2945,
      2941,
      3042,
      2900,
      2901,
      2913,
      2967,
      2949
    ],
    "orderRejected": [
      2949,
      16,
      19,
      6,
      0,
      15,
      0,
      10,
      15,
      19,
      9,
      10,
      1,
      4,
      11,
      16,
      15,
      7,
      6,
      13,
      2,
      13,
      2,
      10,
      19,
      0,
      9,
      16,
      11,
      13,
      19,
      0,
      19,
      15,
      19,
      0,
      10,
      11,
      19,
      10,
      12,
      19,
      13,
      0,
      2,
      13,
      0,
      8,
      19,
      0,
      13,
      0,
      19
    ],
    "orderAccepted": [
      11,
      6,
      11,
      11,
      0,
      0,
      5,
      11,
      7,
      4,
      0,
      0,
      3,
      5,
      11,
      6,
      7,
      11,
      0,
      7,
      6,
      3,
      6,
      7,
      4,
      0,
      0,
      2,
      3,
      7,
      9,
      11,
      1,
      3,
      5,
      11,
      0,
      6,
      11,
      0,
      4,
      11,
      11,
      2,
      4,
      4,
      6,
      4,
      4,
      4,
      0,
      6
    ],
    "orderHold": [
      1,
      0,
      3,
      2,
      2,
      0,
      1,
      3,
      4,
      1,
      4,
      0,
      2,
      3,
      1,
      2,
      2,
      0,
      4,
      4,
      2,
      4,
      3,
      3,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      4,
      2,
      0,
      1,
      0,
      1,
      2,
      3,
      3,
      2,
      0,
      4,
      3,
      1,
      3,
      0,
      4,
      1,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      0,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      1,
      3,
      4,
      1,
      2,
      0,
      4,
      3,
      4,
      0,
      1,
      4,
      1,
      0,
      2,
      4,
      4,
      0,
      4,
      1,
      3,
      2,
      3,
      0,
      4,
      2,
      1,
      3,
      4,
      4,
      4,
      0,
      1,
      2,
      0,
      1,
      1,
      3,
      3,
      0,
      0,
      2,
      4,
      2,
      1,
      0,
      1,
      1,
      0,
      2,
      4
    ],
    "issueDelay": [
      1,
      2,
      0,
      0,
      0,
      4,
      4,
      0,
      0,
      4,
      4,
      1,
      4,
      3,
      0,
      3,
      2,
      0,
      0,
      4,
      0,
      0,
      2,
      2,
      3,
      4,
      4,
      0,
      4,
      1,
      0,
      0,
      1,
      0,
      4,
      4,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      4
    ],
    "issueQuantity": [
      1,
      0,
      1,
      1,
      0,
      1,
      4,
      2,
      3,
      4,
      2,
      2,
      0,
      2,
      0,
      0,
      1,
      2,
      3,
      3,
      0,
      4,
      4,
      2,
      0,
      3,
      1,
      0,
      4,
      2,
      1,
      3,
      0,
      1,
      0,
      4,
      4,
      3,
      3,
      2,
      3,
      0,
      4,
      2,
      0,
      0,
      0,
      0,
      0,
      2,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000079",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "Facilities Management, Equipment",
    "contactName": "Courtney Gomez",
    "industryRating": 4.5,
    "procedures": "[PR-066,PR-032,PR-021,PR-045,PR-068,PR-055,PR-049,PR-063,PR-071,PR-071]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000040,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000059,ORDR-2-0000029,ORDR-3-0000034,ORDR-4-0000064,ORDR-5-0000050,ORDR-6-0000035,ORDR-7-0000064,ORDR-8-0000068,ORDR-9-0000036,ORDR-10-0000067,ORDR-11-0000034,ORDR-12-0000015,ORDR-13-0000058,ORDR-14-0000071,ORDR-15-0000067,ORDR-16-0000024,ORDR-17-0000018,ORDR-18-0000033]",
    "spendWeekly": [
      3009,
      3082,
      3003,
      2918,
      3013,
      2905,
      2952,
      2903,
      2924,
      3019,
      3100,
      2998,
      2945,
      2931,
      2942,
      2947,
      2983,
      3099,
      2999,
      3007,
      2999,
      2996,
      3027,
      2926,
      3069,
      3078,
      2968,
      3055,
      3056,
      2956,
      3080,
      2914,
      2968,
      2904,
      2913,
      2904,
      3029,
      3047,
      3009,
      2901,
      3013,
      2900,
      3046,
      3089,
      3035,
      2993,
      2901,
      3086,
      3042,
      2925,
      2961,
      2984
    ],
    "orderRejected": [
      2984,
      5,
      11,
      9,
      17,
      19,
      4,
      9,
      11,
      9,
      9,
      2,
      5,
      5,
      11,
      9,
      13,
      9,
      10,
      0,
      7,
      14,
      2,
      13,
      16,
      10,
      14,
      19,
      18,
      11,
      16,
      15,
      13,
      12,
      12,
      7,
      0,
      8,
      4,
      5,
      16,
      19,
      19,
      3,
      6,
      7,
      16,
      5,
      1,
      13,
      4,
      5,
      14
    ],
    "orderAccepted": [
      10,
      4,
      0,
      4,
      11,
      9,
      10,
      6,
      6,
      6,
      4,
      6,
      0,
      11,
      6,
      4,
      0,
      9,
      5,
      6,
      3,
      0,
      1,
      8,
      10,
      9,
      0,
      5,
      8,
      7,
      11,
      9,
      2,
      0,
      5,
      11,
      4,
      3,
      8,
      5,
      7,
      8,
      6,
      0,
      9,
      7,
      4,
      11,
      3,
      4,
      0,
      11
    ],
    "orderHold": [
      4,
      0,
      3,
      0,
      4,
      2,
      2,
      2,
      3,
      4,
      2,
      2,
      1,
      3,
      1,
      3,
      3,
      4,
      0,
      2,
      2,
      1,
      1,
      3,
      3,
      4,
      2,
      4,
      4,
      4,
      4,
      4,
      0,
      4,
      0,
      2,
      1,
      3,
      4,
      0,
      0,
      0,
      1,
      1,
      2,
      4,
      0,
      2,
      3,
      1,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      0,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      3,
      4,
      0,
      4,
      3,
      2,
      0,
      1,
      4,
      0,
      2,
      3,
      3,
      3,
      2,
      2,
      3,
      4,
      2,
      0,
      1,
      4,
      0,
      0,
      4,
      2,
      1,
      2,
      0,
      4,
      3,
      0,
      2,
      0,
      3,
      2,
      3,
      4,
      3,
      1,
      1,
      4,
      3,
      2,
      2,
      1,
      4,
      4,
      2,
      4
    ],
    "issueDelay": [
      2,
      1,
      1,
      3,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      3,
      2,
      4,
      1,
      2,
      1,
      4,
      0,
      3,
      2,
      0,
      3,
      1,
      4,
      4,
      4,
      3,
      1,
      4,
      4,
      0,
      4,
      0,
      4,
      0,
      3,
      0,
      1,
      0,
      4,
      3,
      1,
      1,
      1,
      4,
      4,
      1,
      2
    ],
    "issueQuantity": [
      2,
      3,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      0,
      2,
      2,
      0,
      0,
      4,
      1,
      4,
      3,
      4,
      2,
      1,
      3,
      0,
      2,
      3,
      1,
      0,
      0,
      1,
      2,
      4,
      1,
      4,
      4,
      2,
      4,
      2,
      0,
      0,
      3,
      3,
      3,
      1,
      0,
      4,
      0,
      4,
      2,
      1,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000080",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Equipment, Pharmaceuticals",
    "contactName": "Aaron Howard",
    "industryRating": 2.6,
    "procedures": "[PR-051,PR-066,PR-043,PR-066,PR-065,PR-049,PR-045,PR-052,PR-052,PR-027]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000051,CNTRCT-0000052]",
    "orders": "[ORDR-0-0000027,ORDR-1-0000048,ORDR-2-0000054,ORDR-3-0000012,ORDR-4-0000057,ORDR-5-0000038,ORDR-6-0000009,ORDR-7-0000062,ORDR-8-0000060,ORDR-9-0000029,ORDR-10-0000020,ORDR-11-0000034,ORDR-12-0000047,ORDR-13-0000038,ORDR-14-0000057,ORDR-15-0000031,ORDR-16-0000026,ORDR-17-0000034,ORDR-18-0000081]",
    "spendWeekly": [
      3097,
      2981,
      2906,
      2937,
      3025,
      2905,
      2979,
      2933,
      2902,
      2902,
      2904,
      3095,
      2915,
      2993,
      3091,
      3041,
      2927,
      2940,
      2985,
      2909,
      3100,
      2903,
      2938,
      2931,
      3095,
      3096,
      2947,
      2939,
      3029,
      2962,
      3096,
      3065,
      3099,
      2981,
      3060,
      2910,
      2971,
      3073,
      3041,
      2970,
      3083,
      3094,
      3027,
      3018,
      2934,
      2901,
      3099,
      2900,
      3100,
      3052,
      2905,
      2996
    ],
    "orderRejected": [
      2996,
      19,
      4,
      17,
      18,
      9,
      8,
      5,
      0,
      0,
      1,
      0,
      18,
      3,
      10,
      7,
      15,
      0,
      7,
      7,
      11,
      17,
      4,
      14,
      13,
      3,
      1,
      0,
      17,
      0,
      11,
      0,
      6,
      10,
      15,
      19,
      9,
      10,
      7,
      5,
      4,
      19,
      19,
      0,
      6,
      0,
      5,
      11,
      4,
      2,
      10,
      19,
      19
    ],
    "orderAccepted": [
      1,
      10,
      6,
      7,
      1,
      3,
      4,
      5,
      4,
      4,
      0,
      8,
      11,
      9,
      11,
      9,
      8,
      4,
      0,
      0,
      1,
      3,
      0,
      3,
      3,
      11,
      10,
      9,
      11,
      2,
      5,
      6,
      2,
      10,
      5,
      11,
      9,
      2,
      11,
      6,
      0,
      8,
      4,
      7,
      1,
      10,
      0,
      9,
      3,
      3,
      2,
      6
    ],
    "orderHold": [
      2,
      2,
      3,
      4,
      0,
      0,
      4,
      2,
      0,
      3,
      1,
      0,
      0,
      3,
      2,
      3,
      4,
      0,
      3,
      4,
      1,
      3,
      1,
      4,
      0,
      4,
      4,
      2,
      4,
      3,
      4,
      4,
      0,
      0,
      0,
      0,
      3,
      1,
      4,
      0,
      3,
      0,
      3,
      4,
      2,
      1,
      0,
      3,
      1,
      1,
      0,
      1
    ],
    "orderBackordered": [
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      4,
      2,
      1,
      4,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      2,
      1,
      0,
      4,
      3,
      0,
      3,
      2,
      1,
      1,
      1,
      1,
      2,
      4,
      2,
      3,
      3,
      4,
      4,
      0,
      2,
      4,
      4,
      2,
      1,
      3,
      1,
      4,
      4,
      3
    ],
    "issueDelay": [
      0,
      3,
      4,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      0,
      0,
      3,
      1,
      0,
      4,
      2,
      3,
      1,
      4,
      2,
      3,
      0,
      0,
      4,
      1,
      0,
      3,
      1,
      0,
      4,
      3,
      4,
      2,
      0,
      3,
      2,
      1,
      4,
      0,
      0,
      2,
      4,
      3,
      0
    ],
    "issueQuantity": [
      4,
      0,
      4,
      0,
      4,
      1,
      3,
      3,
      0,
      3,
      2,
      1,
      0,
      3,
      2,
      2,
      3,
      1,
      2,
      4,
      0,
      0,
      4,
      4,
      0,
      4,
      3,
      0,
      4,
      2,
      1,
      2,
      4,
      4,
      0,
      4,
      0,
      0,
      3,
      4,
      2,
      4,
      4,
      0,
      3,
      3,
      1,
      0,
      2,
      0,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000081",
    "status": "inactive",
    "name": "VitalCare Distribution",
    "tag": "IT Services, Equipment",
    "contactName": "Alvin Hatfield",
    "industryRating": 3,
    "procedures": "[PR-044,PR-038,PR-045,PR-049,PR-058,PR-066,PR-070,PR-061,PR-073,PR-072]",
    "contracts": "[CNTRCT-0000055,CNTRCT-0000052,CNTRCT-0000009]",
    "orders": "[ORDR-0-0000021,ORDR-1-0000034,ORDR-2-0000027,ORDR-3-0000026,ORDR-4-0000014,ORDR-5-0000023,ORDR-6-0000042,ORDR-7-0000044,ORDR-8-0000043,ORDR-9-0000013,ORDR-10-0000063,ORDR-11-0000042,ORDR-12-0000071,ORDR-13-0000070,ORDR-14-0000015,ORDR-15-0000065,ORDR-16-0000039,ORDR-17-0000054,ORDR-18-0000035]",
    "spendWeekly": [
      3048,
      3012,
      3025,
      2902,
      2975,
      2905,
      3095,
      3098,
      2902,
      3099,
      3030,
      2995,
      2943,
      3094,
      3090,
      2909,
      2995,
      2942,
      2928,
      3084,
      3067,
      3083,
      2982,
      3097,
      3015,
      3037,
      2967,
      3045,
      2909,
      3038,
      3031,
      3046,
      2900,
      3069,
      3027,
      2925,
      2994,
      2949,
      2906,
      2999,
      3099,
      3042,
      2929,
      3069,
      2993,
      2918,
      3099,
      2936,
      3097,
      2990,
      2927,
      2959
    ],
    "orderRejected": [
      2959,
      19,
      8,
      2,
      9,
      9,
      8,
      17,
      16,
      9,
      0,
      11,
      17,
      0,
      4,
      3,
      10,
      12,
      8,
      10,
      18,
      0,
      9,
      7,
      2,
      16,
      14,
      2,
      8,
      0,
      19,
      10,
      19,
      19,
      9,
      12,
      0,
      9,
      13,
      19,
      13,
      12,
      1,
      13,
      17,
      1,
      8,
      18,
      19,
      5,
      0,
      13,
      6
    ],
    "orderAccepted": [
      8,
      9,
      0,
      11,
      5,
      3,
      7,
      8,
      0,
      3,
      0,
      2,
      0,
      2,
      3,
      11,
      0,
      7,
      4,
      0,
      7,
      1,
      4,
      5,
      10,
      9,
      6,
      11,
      5,
      6,
      9,
      5,
      3,
      5,
      11,
      7,
      11,
      0,
      11,
      8,
      4,
      11,
      11,
      4,
      2,
      11,
      5,
      2,
      3,
      6,
      8,
      10
    ],
    "orderHold": [
      2,
      3,
      3,
      2,
      2,
      2,
      4,
      2,
      1,
      2,
      0,
      0,
      3,
      3,
      1,
      0,
      4,
      0,
      3,
      4,
      4,
      2,
      1,
      4,
      1,
      4,
      1,
      4,
      2,
      2,
      2,
      3,
      4,
      4,
      2,
      2,
      4,
      3,
      2,
      4,
      3,
      0,
      2,
      4,
      0,
      1,
      0,
      4,
      1,
      2,
      3,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      2,
      0,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1
    ],
    "issueQuality": [
      0,
      0,
      0,
      3,
      4,
      1,
      0,
      4,
      0,
      0,
      0,
      2,
      4,
      2,
      0,
      3,
      1,
      0,
      1,
      0,
      0,
      3,
      1,
      3,
      4,
      2,
      1,
      2,
      4,
      4,
      0,
      3,
      2,
      0,
      0,
      4,
      0,
      1,
      2,
      3,
      0,
      2,
      3,
      1,
      4,
      1,
      1,
      0,
      2,
      0,
      4,
      4
    ],
    "issueDelay": [
      4,
      4,
      1,
      0,
      3,
      0,
      4,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      4,
      1,
      1,
      0,
      2,
      3,
      1,
      0,
      0,
      4,
      1,
      0,
      4,
      0,
      2,
      2,
      4,
      1,
      3,
      4,
      2,
      3,
      4,
      4,
      1,
      2,
      3,
      0,
      2,
      3,
      4,
      0,
      1,
      0,
      2,
      0,
      1,
      2
    ],
    "issueQuantity": [
      4,
      0,
      4,
      0,
      3,
      3,
      3,
      2,
      3,
      3,
      0,
      4,
      2,
      2,
      3,
      1,
      3,
      1,
      1,
      4,
      0,
      1,
      2,
      3,
      1,
      0,
      0,
      3,
      2,
      1,
      3,
      1,
      4,
      1,
      0,
      2,
      4,
      0,
      3,
      4,
      4,
      4,
      4,
      1,
      1,
      4,
      4,
      3,
      2,
      4,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000082",
    "status": "inactive",
    "name": "PharmaLogix International",
    "tag": "PPE",
    "contactName": "Taylor Gutierrez",
    "industryRating": 3.4,
    "procedures": "[PR-061,PR-057,PR-059,PR-066,PR-064,PR-069,PR-068,PR-031,PR-074,PR-071]",
    "contracts": "[CNTRCT-0000011,CNTRCT-0000059,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000042,ORDR-1-0000001,ORDR-2-0000017,ORDR-3-0000005,ORDR-4-0000034,ORDR-5-0000014,ORDR-6-0000035,ORDR-7-0000057,ORDR-8-0000027,ORDR-9-0000055,ORDR-10-0000021,ORDR-11-0000044,ORDR-12-0000011,ORDR-13-0000065,ORDR-14-0000045,ORDR-15-0000025,ORDR-16-0000045,ORDR-17-0000029,ORDR-18-0000043]",
    "spendWeekly": [
      3097,
      3082,
      2929,
      2956,
      2993,
      3021,
      3093,
      3098,
      2902,
      3089,
      3046,
      2982,
      2954,
      2988,
      3062,
      2901,
      3037,
      2900,
      2924,
      3013,
      3100,
      3097,
      2962,
      2913,
      2994,
      3091,
      2921,
      3091,
      3015,
      2955,
      3093,
      3024,
      3013,
      2980,
      2926,
      2904,
      2907,
      3077,
      2938,
      3038,
      3019,
      3012,
      3068,
      3028,
      3053,
      3083,
      3099,
      2986,
      2942,
      2960,
      2921,
      3010
    ],
    "orderRejected": [
      3010,
      7,
      6,
      12,
      16,
      14,
      0,
      2,
      18,
      8,
      10,
      1,
      9,
      7,
      19,
      10,
      19,
      0,
      0,
      0,
      3,
      12,
      8,
      0,
      19,
      19,
      1,
      6,
      16,
      0,
      19,
      19,
      19,
      19,
      15,
      17,
      0,
      12,
      16,
      19,
      3,
      19,
      8,
      5,
      4,
      12,
      14,
      18,
      13,
      2,
      16,
      1,
      11
    ],
    "orderAccepted": [
      11,
      0,
      0,
      11,
      0,
      8,
      4,
      11,
      0,
      0,
      0,
      0,
      11,
      7,
      0,
      11,
      0,
      11,
      3,
      10,
      5,
      3,
      11,
      7,
      7,
      9,
      11,
      8,
      5,
      0,
      11,
      5,
      0,
      1,
      4,
      10,
      6,
      10,
      0,
      4,
      0,
      5,
      11,
      7,
      4,
      8,
      0,
      11,
      11,
      4,
      1,
      11
    ],
    "orderHold": [
      3,
      0,
      3,
      1,
      4,
      1,
      2,
      1,
      2,
      2,
      3,
      1,
      1,
      4,
      0,
      4,
      2,
      4,
      0,
      4,
      3,
      0,
      3,
      4,
      1,
      4,
      4,
      4,
      2,
      0,
      3,
      4,
      0,
      3,
      0,
      1,
      0,
      4,
      3,
      4,
      4,
      0,
      1,
      3,
      2,
      3,
      1,
      3,
      1,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      0,
      1,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      2,
      0,
      4,
      4,
      0,
      1,
      2,
      4,
      4,
      1,
      3,
      0,
      0,
      1,
      0,
      2,
      3,
      3,
      4,
      0,
      0,
      2,
      4,
      4,
      0,
      4,
      2,
      2,
      4,
      4,
      4,
      3,
      1,
      0,
      0,
      0,
      3,
      0,
      3,
      2,
      0,
      0,
      3,
      3,
      1,
      0,
      2,
      0,
      4,
      2,
      1,
      4
    ],
    "issueDelay": [
      2,
      3,
      4,
      2,
      0,
      3,
      1,
      0,
      0,
      1,
      0,
      4,
      2,
      0,
      3,
      2,
      4,
      0,
      1,
      4,
      0,
      3,
      0,
      4,
      0,
      0,
      0,
      2,
      2,
      4,
      2,
      4,
      4,
      0,
      4,
      2,
      2,
      3,
      4,
      3,
      0,
      0,
      2,
      0,
      2,
      0,
      0,
      1,
      3,
      4,
      3,
      2
    ],
    "issueQuantity": [
      4,
      2,
      2,
      0,
      1,
      3,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      3,
      2,
      2,
      1,
      3,
      3,
      4,
      1,
      2,
      0,
      4,
      1,
      1,
      4,
      0,
      3,
      0,
      4,
      0,
      4,
      0,
      0,
      3,
      4,
      0,
      4,
      4,
      2,
      4,
      3,
      1,
      3,
      0,
      4,
      0,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000083",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Laboratory Supplies, Pharmaceuticals",
    "contactName": "Deborah Cox",
    "industryRating": 2.2,
    "procedures": "[PR-059,PR-042,PR-043,PR-067,PR-065,PR-050,PR-034,PR-039,PR-037,PR-074]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000034,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000038,ORDR-1-0000028,ORDR-2-0000001,ORDR-3-0000018,ORDR-4-0000059,ORDR-5-0000019,ORDR-6-0000064,ORDR-7-0000066,ORDR-8-0000021,ORDR-9-0000070,ORDR-10-0000054,ORDR-11-0000042,ORDR-12-0000032,ORDR-13-0000068,ORDR-14-0000060,ORDR-15-0000040,ORDR-16-0000076,ORDR-17-0000032,ORDR-18-0000062]",
    "spendWeekly": [
      2913,
      3021,
      2948,
      3068,
      2903,
      3078,
      3100,
      2964,
      3016,
      3012,
      3076,
      3050,
      3012,
      2983,
      3091,
      3012,
      2903,
      3004,
      2969,
      3098,
      3030,
      3059,
      2953,
      2965,
      2978,
      3056,
      3006,
      3011,
      3016,
      2954,
      3068,
      3025,
      3031,
      2904,
      3044,
      3034,
      2972,
      3014,
      2965,
      3064,
      3034,
      2900,
      3050,
      3033,
      3025,
      3040,
      3038,
      2909,
      2994,
      2905,
      3095,
      3097
    ],
    "orderRejected": [
      3097,
      12,
      19,
      0,
      12,
      3,
      9,
      12,
      7,
      19,
      13,
      4,
      7,
      18,
      16,
      2,
      8,
      10,
      4,
      7,
      6,
      1,
      1,
      16,
      17,
      14,
      10,
      6,
      19,
      8,
      3,
      18,
      19,
      19,
      19,
      2,
      0,
      19,
      11,
      10,
      6,
      17,
      2,
      14,
      10,
      19,
      0,
      5,
      19,
      13,
      7,
      4,
      17
    ],
    "orderAccepted": [
      5,
      11,
      0,
      3,
      5,
      5,
      10,
      5,
      0,
      8,
      11,
      1,
      9,
      0,
      11,
      7,
      1,
      11,
      0,
      9,
      7,
      8,
      4,
      3,
      4,
      8,
      0,
      8,
      6,
      4,
      10,
      11,
      0,
      1,
      7,
      11,
      8,
      4,
      1,
      0,
      5,
      11,
      1,
      0,
      8,
      8,
      9,
      11,
      2,
      0,
      3,
      4
    ],
    "orderHold": [
      2,
      0,
      0,
      2,
      2,
      3,
      4,
      0,
      0,
      4,
      0,
      1,
      3,
      1,
      0,
      3,
      3,
      1,
      2,
      4,
      2,
      4,
      2,
      3,
      1,
      3,
      4,
      2,
      0,
      0,
      1,
      4,
      3,
      2,
      0,
      2,
      4,
      0,
      2,
      2,
      0,
      2,
      2,
      1,
      1,
      4,
      1,
      0,
      1,
      3,
      1,
      4
    ],
    "orderBackordered": [
      4,
      0,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      2,
      2,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      0,
      2,
      0,
      0,
      2,
      0,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      3,
      4,
      1,
      3,
      4,
      0,
      4,
      0,
      3,
      4,
      1,
      0,
      1,
      2,
      3,
      2,
      2,
      3,
      2,
      0,
      4,
      4,
      3,
      0,
      4,
      0,
      2,
      0,
      2,
      4,
      2,
      2,
      4,
      1,
      0,
      2,
      0,
      0,
      4,
      4,
      4,
      0,
      4,
      3,
      2,
      4,
      0,
      4,
      3,
      2,
      1,
      4
    ],
    "issueDelay": [
      2,
      2,
      4,
      2,
      3,
      4,
      1,
      0,
      2,
      4,
      4,
      2,
      3,
      0,
      3,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      1,
      4,
      1,
      2,
      3,
      2,
      1,
      3,
      2,
      0,
      1,
      4,
      4,
      3,
      0,
      3,
      0,
      4,
      3,
      0,
      0,
      4,
      2,
      0,
      2
    ],
    "issueQuantity": [
      0,
      0,
      3,
      0,
      2,
      1,
      0,
      0,
      4,
      0,
      4,
      2,
      0,
      1,
      2,
      0,
      3,
      2,
      3,
      4,
      0,
      0,
      0,
      1,
      3,
      3,
      4,
      2,
      4,
      0,
      2,
      4,
      2,
      2,
      0,
      2,
      4,
      0,
      1,
      4,
      4,
      1,
      4,
      0,
      1,
      0,
      3,
      1,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000084",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Nutrition",
    "contactName": "Jennifer Yoder ​",
    "industryRating": 2.3,
    "procedures": "[PR-016,PR-049,PR-055,PR-044,PR-056,PR-071,PR-054,PR-061,PR-065,PR-074]",
    "contracts": "[CNTRCT-0000026,CNTRCT-0000053,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000060,ORDR-1-0000061,ORDR-2-0000058,ORDR-3-0000004,ORDR-4-0000014,ORDR-5-0000006,ORDR-6-0000017,ORDR-7-0000062,ORDR-8-0000012,ORDR-9-0000049,ORDR-10-0000053,ORDR-11-0000066,ORDR-12-0000031,ORDR-13-0000072,ORDR-14-0000072,ORDR-15-0000070,ORDR-16-0000022,ORDR-17-0000062,ORDR-18-0000082]",
    "spendWeekly": [
      2941,
      3032,
      3007,
      2931,
      2965,
      2905,
      2987,
      2952,
      3010,
      2968,
      3100,
      3009,
      2960,
      3056,
      2922,
      3097,
      2965,
      3099,
      2900,
      3014,
      3007,
      3073,
      3054,
      2965,
      3023,
      3062,
      3018,
      2901,
      2901,
      3025,
      2998,
      2939,
      3099,
      2998,
      3014,
      3100,
      2980,
      2902,
      3084,
      2935,
      2901,
      2900,
      2975,
      2958,
      2902,
      2901,
      2901,
      3067,
      2973,
      2923,
      3027,
      2901
    ],
    "orderRejected": [
      2901,
      19,
      19,
      3,
      9,
      19,
      9,
      13,
      10,
      19,
      5,
      0,
      7,
      18,
      3,
      17,
      19,
      15,
      3,
      13,
      6,
      19,
      0,
      14,
      14,
      19,
      4,
      0,
      13,
      1,
      10,
      11,
      19,
      11,
      19,
      5,
      1,
      17,
      11,
      11,
      19,
      19,
      10,
      19,
      15,
      5,
      0,
      8,
      19,
      19,
      5,
      12,
      19
    ],
    "orderAccepted": [
      2,
      11,
      6,
      1,
      11,
      5,
      11,
      0,
      1,
      7,
      4,
      7,
      3,
      0,
      9,
      9,
      0,
      8,
      7,
      9,
      11,
      0,
      4,
      4,
      8,
      11,
      0,
      4,
      4,
      8,
      6,
      11,
      1,
      1,
      5,
      11,
      7,
      1,
      3,
      6,
      3,
      11,
      8,
      0,
      0,
      11,
      4,
      11,
      3,
      8,
      0,
      2
    ],
    "orderHold": [
      1,
      0,
      2,
      2,
      0,
      4,
      4,
      3,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      0,
      2,
      0,
      4,
      4,
      4,
      0,
      2,
      0,
      2,
      4,
      1,
      1,
      4,
      4,
      0,
      0,
      4,
      4,
      2,
      3,
      0,
      1,
      0,
      1,
      3,
      2,
      4,
      4,
      0,
      0,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      0,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      1,
      0,
      3,
      3,
      4,
      1,
      0,
      1,
      4,
      2,
      3,
      2,
      4,
      2,
      3,
      0,
      4,
      1,
      3,
      4,
      0,
      2,
      4,
      4,
      4,
      2,
      3,
      3,
      4,
      0,
      4,
      3,
      3,
      2,
      1,
      0,
      0,
      0,
      2,
      3,
      1,
      0,
      0,
      4,
      2,
      2,
      4,
      1,
      4,
      4,
      3,
      4
    ],
    "issueDelay": [
      2,
      2,
      3,
      4,
      4,
      4,
      4,
      4,
      1,
      3,
      2,
      4,
      3,
      2,
      4,
      1,
      0,
      0,
      2,
      0,
      4,
      2,
      0,
      2,
      3,
      1,
      4,
      2,
      2,
      4,
      2,
      2,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      3,
      0,
      0,
      4,
      0,
      3,
      0,
      0,
      0,
      2,
      0,
      2,
      3
    ],
    "issueQuantity": [
      4,
      2,
      1,
      2,
      3,
      1,
      0,
      0,
      2,
      0,
      4,
      1,
      2,
      2,
      4,
      0,
      4,
      3,
      0,
      4,
      3,
      3,
      2,
      0,
      3,
      1,
      1,
      3,
      3,
      4,
      0,
      4,
      3,
      1,
      0,
      4,
      2,
      2,
      0,
      3,
      4,
      4,
      4,
      0,
      4,
      0,
      4,
      4,
      2,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000085",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "Facilities Management, Equipment",
    "contactName": "Stephanie Anthony",
    "industryRating": 2.8,
    "procedures": "[PR-044,PR-064,PR-068,PR-062,PR-056,PR-061,PR-060,PR-070,PR-073,PR-075]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000064,CNTRCT-0000061]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000057,ORDR-2-0000043,ORDR-3-0000048,ORDR-4-0000015,ORDR-5-0000060,ORDR-6-0000018,ORDR-7-0000054,ORDR-8-0000049,ORDR-9-0000058,ORDR-10-0000073,ORDR-11-0000033,ORDR-12-0000076,ORDR-13-0000038,ORDR-14-0000055,ORDR-15-0000054,ORDR-16-0000028,ORDR-17-0000026,ORDR-18-0000077]",
    "spendWeekly": [
      3088,
      3082,
      2906,
      2902,
      2970,
      2905,
      3100,
      2988,
      2902,
      2962,
      3070,
      2965,
      2954,
      3094,
      3080,
      2910,
      2945,
      3099,
      3023,
      2909,
      3016,
      3090,
      3087,
      2986,
      3067,
      3096,
      3095,
      2909,
      2994,
      3048,
      2961,
      3007,
      3002,
      2957,
      2913,
      2904,
      3005,
      3038,
      3019,
      3023,
      3013,
      2900,
      3059,
      2966,
      3100,
      2901,
      3056,
      2900,
      3100,
      3041,
      2905,
      3097
    ],
    "orderRejected": [
      3097,
      19,
      7,
      6,
      5,
      19,
      0,
      11,
      0,
      15,
      6,
      4,
      4,
      13,
      3,
      17,
      10,
      0,
      15,
      10,
      9,
      19,
      10,
      17,
      6,
      19,
      19,
      1,
      19,
      14,
      19,
      6,
      19,
      19,
      10,
      0,
      0,
      12,
      6,
      4,
      19,
      19,
      5,
      3,
      19,
      0,
      16,
      17,
      0,
      14,
      7,
      18,
      10
    ],
    "orderAccepted": [
      11,
      11,
      0,
      0,
      4,
      3,
      11,
      6,
      3,
      10,
      2,
      7,
      7,
      5,
      11,
      10,
      0,
      6,
      4,
      10,
      1,
      0,
      10,
      7,
      11,
      10,
      0,
      8,
      0,
      1,
      8,
      4,
      0,
      0,
      0,
      5,
      0,
      0,
      11,
      2,
      11,
      9,
      11,
      11,
      0,
      11,
      0,
      10,
      11,
      11,
      0,
      4
    ],
    "orderHold": [
      2,
      0,
      4,
      0,
      3,
      0,
      2,
      1,
      4,
      0,
      4,
      0,
      1,
      3,
      4,
      4,
      2,
      4,
      3,
      4,
      4,
      1,
      0,
      4,
      2,
      1,
      0,
      2,
      4,
      3,
      0,
      4,
      0,
      0,
      4,
      0,
      1,
      2,
      4,
      1,
      1,
      0,
      2,
      1,
      3,
      4,
      1,
      2,
      0,
      0,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      2,
      1,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      2,
      0,
      1,
      1,
      4,
      0,
      4,
      2,
      3,
      0,
      2,
      0,
      1,
      0,
      2,
      2,
      0,
      0,
      2,
      0,
      4,
      2,
      4,
      4,
      0,
      4,
      2,
      0,
      1,
      3,
      0,
      0,
      2,
      0,
      0,
      4,
      3,
      0,
      0,
      0,
      2,
      4,
      3,
      0,
      1,
      4,
      2,
      1,
      4
    ],
    "issueDelay": [
      2,
      0,
      4,
      4,
      2,
      3,
      1,
      0,
      4,
      2,
      1,
      0,
      2,
      3,
      4,
      0,
      4,
      1,
      4,
      4,
      3,
      0,
      3,
      3,
      0,
      2,
      0,
      0,
      2,
      0,
      3,
      2,
      1,
      2,
      2,
      2,
      0,
      4,
      4,
      2,
      0,
      0,
      4,
      1,
      4,
      0,
      0,
      2,
      4,
      0,
      0,
      2
    ],
    "issueQuantity": [
      4,
      2,
      4,
      2,
      4,
      2,
      2,
      0,
      2,
      4,
      2,
      1,
      1,
      2,
      0,
      4,
      3,
      3,
      0,
      4,
      2,
      0,
      2,
      1,
      0,
      4,
      4,
      4,
      3,
      2,
      2,
      2,
      2,
      2,
      4,
      3,
      0,
      3,
      0,
      2,
      3,
      4,
      4,
      1,
      4,
      2,
      3,
      2,
      0,
      4,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000086",
    "status": "active",
    "name": "BioVital Supplies",
    "tag": "IT Services",
    "contactName": "Darryl Richards",
    "industryRating": 4.8,
    "procedures": "[PR-016,PR-063,PR-068,PR-009,PR-046,PR-036,PR-068,PR-021,PR-046,PR-075]",
    "contracts": "[CNTRCT-0000022,CNTRCT-0000052,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000037,ORDR-1-0000010,ORDR-2-0000045,ORDR-3-0000060,ORDR-4-0000062,ORDR-5-0000014,ORDR-6-0000016,ORDR-7-0000040,ORDR-8-0000012,ORDR-9-0000009,ORDR-10-0000051,ORDR-11-0000030,ORDR-12-0000056,ORDR-13-0000059,ORDR-14-0000055,ORDR-15-0000077,ORDR-16-0000016,ORDR-17-0000032,ORDR-18-0000058]",
    "spendWeekly": [
      3028,
      3082,
      2906,
      3031,
      2903,
      2905,
      3074,
      3044,
      2946,
      3028,
      2995,
      2989,
      2963,
      3057,
      3035,
      2901,
      3030,
      3099,
      3015,
      3092,
      3100,
      2903,
      3025,
      2999,
      2932,
      3096,
      2909,
      2963,
      2901,
      3096,
      3096,
      3031,
      3049,
      3088,
      2970,
      3100,
      2993,
      3091,
      2994,
      3047,
      2991,
      2988,
      3031,
      2997,
      3058,
      2924,
      3023,
      2976,
      3100,
      3035,
      3000,
      2987
    ],
    "orderRejected": [
      2987,
      9,
      9,
      16,
      16,
      5,
      6,
      14,
      8,
      11,
      0,
      5,
      17,
      7,
      12,
      13,
      19,
      0,
      5,
      13,
      18,
      15,
      18,
      17,
      13,
      9,
      6,
      0,
      0,
      10,
      12,
      9,
      9,
      19,
      0,
      9,
      13,
      8,
      8,
      4,
      19,
      19,
      19,
      0,
      13,
      3,
      6,
      3,
      0,
      17,
      0,
      16,
      16
    ],
    "orderAccepted": [
      8,
      5,
      10,
      11,
      1,
      11,
      11,
      7,
      8,
      4,
      4,
      1,
      11,
      7,
      9,
      11,
      2,
      0,
      3,
      1,
      0,
      9,
      9,
      0,
      8,
      0,
      11,
      11,
      8,
      11,
      8,
      0,
      0,
      7,
      6,
      9,
      7,
      2,
      6,
      0,
      4,
      11,
      11,
      0,
      11,
      9,
      5,
      11,
      8,
      0,
      4,
      5
    ],
    "orderHold": [
      2,
      3,
      2,
      4,
      2,
      1,
      3,
      3,
      2,
      0,
      0,
      2,
      3,
      4,
      0,
      2,
      2,
      0,
      4,
      2,
      1,
      3,
      2,
      0,
      0,
      3,
      1,
      2,
      4,
      1,
      3,
      4,
      0,
      1,
      2,
      0,
      4,
      4,
      3,
      2,
      4,
      2,
      2,
      0,
      2,
      2,
      2,
      0,
      0,
      2,
      2,
      3
    ],
    "orderBackordered": [
      3,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      3,
      4,
      2,
      4,
      1,
      3,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      0,
      2,
      3,
      2,
      4,
      0,
      0,
      0,
      2,
      1,
      1,
      4,
      0,
      4,
      0,
      3,
      4,
      0,
      0,
      2,
      2,
      4,
      0,
      0,
      0,
      0,
      4,
      4,
      4,
      3,
      0,
      2,
      2,
      0,
      0,
      2,
      3
    ],
    "issueDelay": [
      2,
      0,
      4,
      4,
      1,
      3,
      4,
      0,
      2,
      1,
      1,
      4,
      0,
      3,
      0,
      1,
      4,
      1,
      2,
      1,
      3,
      2,
      0,
      2,
      1,
      2,
      3,
      1,
      2,
      0,
      1,
      0,
      2,
      1,
      4,
      2,
      3,
      4,
      4,
      1,
      0,
      1,
      0,
      2,
      3,
      0,
      1,
      0,
      0,
      4,
      4,
      4
    ],
    "issueQuantity": [
      4,
      3,
      2,
      0,
      4,
      2,
      2,
      1,
      2,
      3,
      4,
      2,
      2,
      2,
      3,
      2,
      2,
      0,
      4,
      2,
      1,
      0,
      0,
      4,
      1,
      4,
      3,
      0,
      4,
      2,
      4,
      2,
      4,
      2,
      1,
      1,
      4,
      0,
      4,
      4,
      4,
      0,
      4,
      1,
      4,
      0,
      2,
      0,
      0,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000087",
    "status": "inactive",
    "name": "LifeCare Logistics",
    "tag": "Facilities Management, Imaging",
    "contactName": "Aaron Stevenson",
    "industryRating": 2.3,
    "procedures": "[PR-043,PR-067,PR-063,PR-055,PR-069,PR-066,PR-055,PR-054,PR-073,PR-075]",
    "contracts": "[CNTRCT-0000026,CNTRCT-0000056,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000038,ORDR-1-0000002,ORDR-2-0000012,ORDR-3-0000025,ORDR-4-0000057,ORDR-5-0000023,ORDR-6-0000014,ORDR-7-0000048,ORDR-8-0000037,ORDR-9-0000035,ORDR-10-0000067,ORDR-11-0000024,ORDR-12-0000039,ORDR-13-0000012,ORDR-14-0000017,ORDR-15-0000030,ORDR-16-0000074,ORDR-17-0000060,ORDR-18-0000054]",
    "spendWeekly": [
      2946,
      2965,
      2979,
      2936,
      3095,
      3097,
      3017,
      3098,
      3040,
      2902,
      3001,
      2936,
      2979,
      3086,
      3091,
      3066,
      3023,
      3082,
      3063,
      3012,
      3019,
      3010,
      2998,
      3042,
      3024,
      3096,
      2995,
      2989,
      2956,
      2984,
      2920,
      3097,
      2977,
      2992,
      3072,
      2989,
      3029,
      3074,
      2919,
      2961,
      3073,
      3024,
      3015,
      3084,
      2902,
      2988,
      3099,
      3100,
      3100,
      3097,
      2933,
      3097
    ],
    "orderRejected": [
      3097,
      12,
      13,
      0,
      16,
      7,
      19,
      19,
      19,
      10,
      1,
      11,
      5,
      18,
      12,
      19,
      10,
      12,
      9,
      3,
      7,
      19,
      6,
      16,
      10,
      17,
      18,
      0,
      6,
      19,
      19,
      19,
      19,
      9,
      18,
      17,
      19,
      6,
      11,
      0,
      16,
      19,
      7,
      0,
      3,
      9,
      5,
      5,
      10,
      9,
      15,
      16,
      19
    ],
    "orderAccepted": [
      0,
      8,
      3,
      7,
      2,
      11,
      4,
      0,
      4,
      1,
      0,
      9,
      9,
      11,
      11,
      5,
      1,
      3,
      3,
      0,
      0,
      11,
      6,
      9,
      3,
      11,
      0,
      7,
      1,
      0,
      1,
      1,
      10,
      0,
      8,
      10,
      8,
      4,
      11,
      8,
      10,
      7,
      7,
      3,
      4,
      9,
      0,
      10,
      6,
      6,
      11,
      5
    ],
    "orderHold": [
      0,
      4,
      3,
      4,
      2,
      4,
      1,
      0,
      4,
      4,
      3,
      1,
      0,
      3,
      0,
      2,
      2,
      2,
      4,
      4,
      1,
      0,
      2,
      0,
      4,
      3,
      0,
      0,
      4,
      3,
      1,
      2,
      1,
      3,
      3,
      2,
      4,
      3,
      1,
      4,
      4,
      0,
      4,
      2,
      4,
      4,
      1,
      3,
      2,
      0,
      3,
      0
    ],
    "orderBackordered": [
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      2,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      0,
      2,
      0,
      0,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      0,
      1,
      4,
      4,
      0,
      4,
      0,
      2,
      1,
      0,
      4,
      0,
      0,
      3,
      0,
      1,
      4,
      4,
      0,
      1,
      3,
      4,
      2,
      2,
      2,
      3,
      4,
      1,
      4,
      4,
      1,
      0,
      1,
      3,
      0,
      0,
      3,
      4,
      0,
      4,
      3,
      0,
      1,
      4,
      4,
      1,
      0,
      1,
      0,
      3
    ],
    "issueDelay": [
      3,
      3,
      4,
      4,
      3,
      4,
      3,
      4,
      0,
      2,
      0,
      1,
      4,
      3,
      1,
      3,
      0,
      0,
      0,
      3,
      4,
      0,
      2,
      4,
      0,
      0,
      3,
      0,
      2,
      2,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      4,
      2,
      2,
      1,
      1,
      1,
      2,
      3,
      0,
      1,
      0,
      3,
      0,
      0,
      4
    ],
    "issueQuantity": [
      2,
      1,
      0,
      0,
      4,
      4,
      2,
      1,
      2,
      0,
      1,
      2,
      0,
      0,
      4,
      0,
      4,
      0,
      0,
      4,
      3,
      4,
      1,
      1,
      3,
      0,
      3,
      3,
      3,
      4,
      3,
      0,
      4,
      4,
      0,
      3,
      4,
      1,
      2,
      4,
      4,
      3,
      3,
      2,
      3,
      4,
      4,
      1,
      0,
      1,
      2,
      4
    ]
  },
  {
    "id": "VNDR-0000088",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Medical Supplies, PPE",
    "contactName": "Bryan Lang",
    "industryRating": 3.2,
    "procedures": "[PR-023,PR-055,PR-068,PR-059,PR-063,PR-061,PR-072,PR-041,PR-054,PR-048]",
    "contracts": "[CNTRCT-0000003,CNTRCT-0000049,CNTRCT-0000055]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000049,ORDR-2-0000023,ORDR-3-0000037,ORDR-4-0000011,ORDR-5-0000042,ORDR-6-0000024,ORDR-7-0000062,ORDR-8-0000064,ORDR-9-0000054,ORDR-10-0000021,ORDR-11-0000071,ORDR-12-0000066,ORDR-13-0000015,ORDR-14-0000059,ORDR-15-0000074,ORDR-16-0000048,ORDR-17-0000016,ORDR-18-0000073]",
    "spendWeekly": [
      2946,
      2951,
      2906,
      3038,
      3095,
      2905,
      2955,
      3098,
      3100,
      2902,
      3100,
      3095,
      2912,
      3050,
      3091,
      2918,
      3026,
      3094,
      2969,
      2909,
      2971,
      3059,
      3097,
      2923,
      3095,
      3096,
      3050,
      2925,
      3027,
      3050,
      3004,
      3073,
      3006,
      2987,
      3095,
      2980,
      2998,
      3055,
      3099,
      2927,
      2972,
      2962,
      3097,
      3069,
      2902,
      2901,
      2918,
      3100,
      2952,
      3084,
      3029,
      3016
    ],
    "orderRejected": [
      3016,
      19,
      10,
      8,
      15,
      15,
      0,
      8,
      19,
      16,
      6,
      1,
      17,
      8,
      13,
      19,
      12,
      14,
      9,
      5,
      3,
      0,
      1,
      19,
      0,
      0,
      18,
      5,
      14,
      19,
      7,
      19,
      11,
      19,
      17,
      11,
      2,
      0,
      19,
      19,
      7,
      9,
      18,
      2,
      0,
      17,
      5,
      9,
      0,
      12,
      19,
      10,
      1
    ],
    "orderAccepted": [
      0,
      11,
      4,
      6,
      7,
      7,
      11,
      4,
      5,
      0,
      0,
      10,
      0,
      6,
      0,
      1,
      1,
      9,
      4,
      8,
      5,
      5,
      11,
      0,
      5,
      11,
      0,
      9,
      2,
      6,
      7,
      11,
      2,
      0,
      7,
      10,
      9,
      10,
      7,
      0,
      2,
      8,
      6,
      4,
      5,
      10,
      9,
      11,
      0,
      4,
      3,
      0
    ],
    "orderHold": [
      2,
      1,
      1,
      4,
      0,
      0,
      1,
      0,
      1,
      2,
      2,
      2,
      0,
      1,
      0,
      4,
      4,
      0,
      3,
      4,
      4,
      0,
      2,
      1,
      1,
      4,
      1,
      3,
      2,
      0,
      1,
      2,
      4,
      0,
      1,
      2,
      0,
      3,
      2,
      0,
      0,
      1,
      4,
      1,
      2,
      2,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "orderBackordered": [
      0,
      0,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      0,
      2,
      0,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0
    ],
    "issueQuality": [
      4,
      3,
      4,
      4,
      2,
      0,
      0,
      2,
      4,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      3,
      3,
      3,
      3,
      0,
      0,
      3,
      1,
      4,
      3,
      3,
      2,
      4,
      0,
      3,
      0,
      2,
      2,
      4,
      1,
      1,
      0,
      0,
      4,
      4,
      4,
      4,
      4,
      0,
      0,
      3,
      4,
      2,
      3,
      2,
      0
    ],
    "issueDelay": [
      3,
      2,
      0,
      4,
      1,
      2,
      0,
      3,
      0,
      0,
      3,
      4,
      4,
      3,
      0,
      2,
      4,
      1,
      3,
      2,
      1,
      4,
      0,
      0,
      0,
      0,
      0,
      4,
      1,
      2,
      4,
      4,
      3,
      1,
      0,
      3,
      2,
      4,
      0,
      4,
      3,
      0,
      2,
      1,
      1,
      0,
      1,
      3,
      3,
      1,
      1,
      3
    ],
    "issueQuantity": [
      4,
      0,
      0,
      0,
      4,
      2,
      0,
      1,
      4,
      3,
      4,
      0,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      4,
      3,
      3,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      4,
      1,
      2,
      4,
      1,
      3,
      3,
      4,
      2,
      3,
      4,
      4,
      2,
      4,
      1,
      2,
      0,
      4,
      2,
      2,
      4,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000089",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "PPE",
    "contactName": "Kristy Boyer",
    "industryRating": 4.4,
    "procedures": "[PR-060,PR-040,PR-068,PR-055,PR-064,PR-064,PR-055,PR-069,PR-074,PR-041]",
    "contracts": "[CNTRCT-0000055,CNTRCT-0000027,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000031,ORDR-1-0000016,ORDR-2-0000036,ORDR-3-0000012,ORDR-4-0000016,ORDR-5-0000023,ORDR-6-0000055,ORDR-7-0000031,ORDR-8-0000050,ORDR-9-0000017,ORDR-10-0000060,ORDR-11-0000068,ORDR-12-0000059,ORDR-13-0000058,ORDR-14-0000052,ORDR-15-0000026,ORDR-16-0000065,ORDR-17-0000030,ORDR-18-0000024]",
    "spendWeekly": [
      3097,
      3082,
      3016,
      2977,
      2927,
      2927,
      2939,
      2903,
      3100,
      3028,
      3046,
      2904,
      2909,
      3094,
      3046,
      3015,
      2903,
      3099,
      2997,
      2946,
      3100,
      3097,
      3072,
      2903,
      3095,
      2937,
      2986,
      2959,
      3041,
      2994,
      3096,
      3082,
      3017,
      2978,
      3050,
      2904,
      3085,
      2987,
      3099,
      2908,
      2952,
      3083,
      3085,
      2998,
      2902,
      3024,
      2933,
      2972,
      2952,
      3003,
      3011,
      3016
    ],
    "orderRejected": [
      3016,
      8,
      12,
      19,
      6,
      19,
      3,
      16,
      11,
      14,
      12,
      11,
      8,
      0,
      8,
      17,
      18,
      0,
      4,
      0,
      19,
      19,
      12,
      19,
      19,
      14,
      10,
      0,
      14,
      13,
      15,
      19,
      16,
      16,
      16,
      19,
      11,
      19,
      12,
      3,
      0,
      15,
      0,
      3,
      19,
      8,
      19,
      8,
      8,
      1,
      0,
      18,
      15
    ],
    "orderAccepted": [
      5,
      11,
      2,
      4,
      10,
      5,
      9,
      4,
      0,
      0,
      5,
      6,
      6,
      6,
      8,
      11,
      9,
      0,
      1,
      2,
      3,
      0,
      0,
      0,
      7,
      8,
      0,
      11,
      6,
      5,
      9,
      5,
      0,
      6,
      0,
      11,
      0,
      5,
      11,
      9,
      0,
      11,
      9,
      11,
      0,
      3,
      5,
      11,
      4,
      1,
      2,
      5
    ],
    "orderHold": [
      1,
      2,
      4,
      0,
      4,
      3,
      4,
      3,
      0,
      4,
      3,
      0,
      3,
      3,
      4,
      2,
      0,
      0,
      0,
      1,
      3,
      2,
      4,
      4,
      0,
      4,
      4,
      3,
      2,
      4,
      4,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      4,
      0,
      0,
      1,
      4,
      3,
      2,
      2,
      2,
      1,
      1,
      0,
      3,
      3
    ],
    "orderBackordered": [
      3,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      2,
      4,
      4,
      0,
      0,
      1,
      1,
      0,
      4,
      3,
      0,
      4,
      1,
      0,
      3,
      3,
      3,
      0,
      0,
      2,
      0,
      3,
      3,
      2,
      0,
      4,
      4,
      4,
      3,
      2,
      1,
      0,
      1,
      4,
      4,
      2,
      0,
      0,
      4,
      2,
      0,
      0,
      4,
      4,
      2,
      0,
      1,
      4,
      2,
      0,
      2
    ],
    "issueDelay": [
      2,
      3,
      3,
      4,
      0,
      3,
      2,
      1,
      1,
      0,
      0,
      3,
      4,
      2,
      0,
      0,
      4,
      0,
      0,
      0,
      4,
      2,
      0,
      1,
      0,
      0,
      0,
      3,
      2,
      0,
      4,
      2,
      4,
      4,
      3,
      4,
      0,
      4,
      3,
      2,
      2,
      0,
      1,
      3,
      3,
      0,
      0,
      0,
      2,
      4,
      2,
      4
    ],
    "issueQuantity": [
      2,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      3,
      0,
      3,
      0,
      3,
      4,
      2,
      2,
      4,
      0,
      4,
      1,
      3,
      2,
      1,
      3,
      4,
      1,
      3,
      2,
      4,
      2,
      0,
      4,
      4,
      0,
      4,
      4,
      0,
      4,
      2,
      4,
      0,
      4,
      0,
      4,
      2,
      3,
      2,
      0,
      0,
      0,
      0
    ]
  },
  {
    "id": "VNDR-0000090",
    "status": "inactive",
    "name": "CarePoint Technologies",
    "tag": "Nutrition, Imaging, Equipment",
    "contactName": "Darryl Richards",
    "industryRating": 2.1,
    "procedures": "[PR-024,PR-057,PR-043,PR-064,PR-063,PR-047,PR-055,PR-021,PR-063,PR-074]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000027,CNTRCT-0000051]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000038,ORDR-2-0000057,ORDR-3-0000011,ORDR-4-0000004,ORDR-5-0000015,ORDR-6-0000053,ORDR-7-0000053,ORDR-8-0000029,ORDR-9-0000039,ORDR-10-0000056,ORDR-11-0000042,ORDR-12-0000011,ORDR-13-0000043,ORDR-14-0000039,ORDR-15-0000038,ORDR-16-0000061,ORDR-17-0000056,ORDR-18-0000077]",
    "spendWeekly": [
      2901,
      2998,
      2927,
      3040,
      3067,
      2906,
      3055,
      2971,
      2993,
      3001,
      2977,
      3072,
      2909,
      3094,
      3091,
      2901,
      2992,
      2900,
      2937,
      2998,
      3007,
      3019,
      2900,
      2957,
      3095,
      3096,
      3092,
      3096,
      2989,
      2971,
      2979,
      2957,
      3054,
      3028,
      3095,
      3087,
      2959,
      3040,
      3038,
      3008,
      2971,
      2926,
      3021,
      2974,
      2969,
      2901,
      3099,
      3005,
      3100,
      3041,
      3095,
      3097
    ],
    "orderRejected": [
      3097,
      11,
      19,
      0,
      0,
      19,
      13,
      8,
      12,
      18,
      0,
      3,
      17,
      7,
      12,
      9,
      12,
      10,
      8,
      19,
      17,
      4,
      10,
      15,
      7,
      0,
      0,
      3,
      19,
      16,
      0,
      19,
      0,
      5,
      13,
      19,
      8,
      0,
      16,
      0,
      7,
      18,
      4,
      1,
      5,
      19,
      14,
      12,
      17,
      7,
      16,
      10,
      0
    ],
    "orderAccepted": [
      8,
      11,
      2,
      9,
      0,
      0,
      5,
      8,
      0,
      5,
      7,
      0,
      6,
      11,
      9,
      8,
      0,
      6,
      4,
      5,
      3,
      7,
      5,
      3,
      6,
      7,
      0,
      11,
      5,
      5,
      3,
      4,
      0,
      9,
      9,
      10,
      11,
      4,
      11,
      0,
      0,
      10,
      8,
      8,
      4,
      4,
      3,
      11,
      4,
      5,
      0,
      1
    ],
    "orderHold": [
      4,
      0,
      2,
      0,
      3,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      4,
      0,
      3,
      0,
      4,
      2,
      2,
      4,
      1,
      1,
      4,
      2,
      3,
      1,
      0,
      4,
      2,
      0,
      0,
      3,
      3,
      0,
      4,
      0,
      0,
      2,
      2,
      2,
      3,
      2,
      0,
      4,
      0,
      0,
      2,
      0
    ],
    "orderBackordered": [
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      0,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      0,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1
    ],
    "issueQuality": [
      4,
      3,
      2,
      1,
      0,
      4,
      1,
      2,
      4,
      3,
      0,
      1,
      3,
      2,
      2,
      2,
      2,
      1,
      2,
      3,
      3,
      0,
      0,
      2,
      2,
      2,
      3,
      0,
      3,
      0,
      4,
      1,
      4,
      3,
      1,
      1,
      1,
      1,
      3,
      2,
      3,
      1,
      2,
      4,
      4,
      3,
      0,
      3,
      2,
      4,
      2,
      4
    ],
    "issueDelay": [
      3,
      3,
      0,
      4,
      1,
      4,
      2,
      2,
      1,
      1,
      0,
      3,
      2,
      0,
      3,
      1,
      0,
      0,
      4,
      2,
      1,
      0,
      3,
      4,
      0,
      0,
      4,
      1,
      3,
      4,
      3,
      4,
      2,
      1,
      4,
      0,
      0,
      3,
      1,
      3,
      4,
      0,
      0,
      1,
      3,
      4,
      4,
      0,
      4,
      0,
      1,
      1
    ],
    "issueQuantity": [
      4,
      0,
      3,
      0,
      4,
      0,
      4,
      2,
      0,
      0,
      4,
      2,
      0,
      2,
      4,
      1,
      2,
      3,
      0,
      4,
      2,
      3,
      3,
      4,
      2,
      2,
      0,
      1,
      4,
      1,
      2,
      3,
      0,
      0,
      1,
      4,
      2,
      0,
      1,
      4,
      4,
      3,
      3,
      2,
      4,
      1,
      4,
      0,
      1,
      3,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000091",
    "status": "active",
    "name": "BioVital Supplies",
    "tag": "Equipment",
    "contactName": "Deborah Cox",
    "industryRating": 4.2,
    "procedures": "[PR-039,PR-022,PR-055,PR-049,PR-070,PR-063,PR-058,PR-070,PR-074,PR-046]",
    "contracts": "[CNTRCT-0000043,CNTRCT-0000044,CNTRCT-0000046]",
    "orders": "[ORDR-0-0000043,ORDR-1-0000001,ORDR-2-0000013,ORDR-3-0000029,ORDR-4-0000016,ORDR-5-0000011,ORDR-6-0000013,ORDR-7-0000054,ORDR-8-0000020,ORDR-9-0000015,ORDR-10-0000044,ORDR-11-0000039,ORDR-12-0000019,ORDR-13-0000035,ORDR-14-0000051,ORDR-15-0000074,ORDR-16-0000040,ORDR-17-0000068,ORDR-18-0000041]",
    "spendWeekly": [
      2973,
      2987,
      2926,
      3026,
      3013,
      3082,
      3036,
      2903,
      2994,
      3042,
      2993,
      3017,
      2909,
      3021,
      3003,
      2918,
      3008,
      3066,
      3076,
      3098,
      3100,
      3087,
      2995,
      3055,
      2906,
      2911,
      3026,
      3085,
      3026,
      2934,
      3046,
      2901,
      2926,
      2983,
      2989,
      2904,
      2988,
      3037,
      3073,
      2912,
      3030,
      3086,
      2997,
      3085,
      2948,
      2930,
      2998,
      2935,
      2904,
      2963,
      2905,
      3097
    ],
    "orderRejected": [
      3097,
      10,
      3,
      10,
      19,
      16,
      16,
      7,
      18,
      9,
      4,
      17,
      13,
      18,
      19,
      11,
      7,
      8,
      16,
      10,
      6,
      10,
      12,
      16,
      18,
      10,
      11,
      3,
      19,
      6,
      4,
      19,
      12,
      19,
      6,
      16,
      11,
      7,
      17,
      4,
      11,
      19,
      1,
      2,
      16,
      3,
      12,
      6,
      17,
      18,
      8,
      10,
      19
    ],
    "orderAccepted": [
      6,
      10,
      4,
      11,
      7,
      11,
      0,
      7,
      0,
      9,
      7,
      5,
      3,
      11,
      9,
      3,
      1,
      1,
      3,
      2,
      11,
      0,
      4,
      4,
      0,
      11,
      3,
      7,
      7,
      0,
      10,
      8,
      0,
      4,
      6,
      4,
      8,
      0,
      9,
      11,
      1,
      11,
      2,
      4,
      0,
      11,
      11,
      10,
      4,
      7,
      0,
      5
    ],
    "orderHold": [
      2,
      1,
      3,
      1,
      3,
      2,
      2,
      0,
      2,
      2,
      4,
      1,
      1,
      3,
      2,
      1,
      4,
      0,
      1,
      0,
      2,
      1,
      4,
      4,
      3,
      0,
      2,
      1,
      2,
      2,
      2,
      4,
      1,
      3,
      2,
      2,
      4,
      0,
      3,
      4,
      0,
      0,
      3,
      1,
      0,
      4,
      0,
      1,
      0,
      1,
      0,
      4
    ],
    "orderBackordered": [
      4,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      0,
      2,
      0,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      4,
      0,
      1,
      4,
      3,
      3,
      3,
      0,
      4,
      1,
      2,
      4,
      0,
      1,
      0,
      0,
      3,
      1,
      3,
      0,
      0,
      3,
      2,
      0,
      4,
      1,
      4,
      2,
      1,
      2,
      4,
      1,
      2,
      2,
      0,
      2,
      0,
      4,
      4,
      2,
      4,
      0,
      1,
      1,
      1,
      2,
      4,
      1,
      2,
      2,
      0
    ],
    "issueDelay": [
      2,
      1,
      4,
      4,
      2,
      2,
      1,
      2,
      0,
      4,
      0,
      2,
      3,
      0,
      4,
      1,
      4,
      0,
      1,
      4,
      0,
      0,
      0,
      3,
      4,
      4,
      2,
      4,
      3,
      1,
      0,
      4,
      1,
      4,
      2,
      2,
      0,
      1,
      4,
      0,
      0,
      0,
      0,
      1,
      4,
      2,
      1,
      0,
      4,
      0,
      0,
      0
    ],
    "issueQuantity": [
      4,
      3,
      3,
      0,
      3,
      3,
      4,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      3,
      3,
      2,
      2,
      1,
      3,
      2,
      0,
      3,
      2,
      2,
      3,
      0,
      2,
      4,
      0,
      2,
      3,
      2,
      3,
      3,
      3,
      4,
      0,
      0,
      4,
      3,
      3,
      4,
      4,
      4,
      0,
      3,
      1,
      2,
      3,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000092",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Equipment, Surgical Instruments, IT Services",
    "contactName": "Jake Goodwin",
    "industryRating": 3,
    "procedures": "[PR-055,PR-046,PR-055,PR-055,PR-064,PR-066,PR-010,PR-041,PR-047,PR-027]",
    "contracts": "[CNTRCT-0000032,CNTRCT-0000055,CNTRCT-0000059]",
    "orders": "[ORDR-0-0000004,ORDR-1-0000046,ORDR-2-0000025,ORDR-3-0000065,ORDR-4-0000003,ORDR-5-0000012,ORDR-6-0000032,ORDR-7-0000046,ORDR-8-0000020,ORDR-9-0000063,ORDR-10-0000024,ORDR-11-0000068,ORDR-12-0000031,ORDR-13-0000019,ORDR-14-0000065,ORDR-15-0000018,ORDR-16-0000026,ORDR-17-0000025,ORDR-18-0000021]",
    "spendWeekly": [
      3027,
      2929,
      2937,
      2955,
      3090,
      3039,
      2906,
      3053,
      3100,
      2902,
      3100,
      3014,
      3079,
      3025,
      3091,
      2914,
      2992,
      3067,
      3034,
      2996,
      3100,
      3005,
      2985,
      2963,
      3018,
      3055,
      2954,
      3055,
      3096,
      2902,
      2984,
      2949,
      2929,
      2981,
      2977,
      3005,
      3013,
      3030,
      3069,
      2972,
      3084,
      2995,
      3076,
      3009,
      2902,
      3013,
      3022,
      3081,
      2997,
      2969,
      3023,
      3014
    ],
    "orderRejected": [
      3014,
      17,
      3,
      19,
      17,
      7,
      0,
      4,
      10,
      11,
      10,
      5,
      6,
      9,
      11,
      19,
      14,
      7,
      2,
      7,
      9,
      19,
      18,
      19,
      13,
      4,
      14,
      11,
      15,
      17,
      18,
      15,
      7,
      16,
      16,
      19,
      9,
      0,
      5,
      0,
      5,
      15,
      19,
      0,
      6,
      2,
      2,
      18,
      5,
      16,
      6,
      9,
      1
    ],
    "orderAccepted": [
      11,
      5,
      7,
      8,
      8,
      4,
      11,
      1,
      4,
      7,
      2,
      7,
      2,
      5,
      11,
      7,
      11,
      0,
      0,
      6,
      3,
      0,
      0,
      2,
      0,
      7,
      7,
      11,
      8,
      6,
      10,
      11,
      1,
      0,
      6,
      11,
      1,
      9,
      11,
      6,
      7,
      3,
      0,
      3,
      1,
      2,
      11,
      8,
      4,
      0,
      4,
      11
    ],
    "orderHold": [
      0,
      3,
      0,
      3,
      4,
      2,
      0,
      4,
      4,
      1,
      3,
      0,
      1,
      2,
      1,
      4,
      3,
      1,
      3,
      3,
      0,
      2,
      2,
      2,
      2,
      4,
      4,
      4,
      2,
      2,
      2,
      2,
      1,
      0,
      0,
      4,
      1,
      1,
      4,
      1,
      3,
      0,
      4,
      4,
      2,
      4,
      0,
      2,
      1,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      4,
      2,
      2,
      2,
      3,
      3,
      1,
      4,
      0,
      2,
      0,
      0,
      3,
      3,
      0,
      1,
      1,
      0,
      2,
      3,
      1,
      2,
      3,
      0,
      4,
      2,
      0,
      3,
      2,
      3,
      3,
      3,
      3,
      3,
      4,
      3,
      2,
      1,
      3,
      4,
      0,
      4,
      0,
      4,
      0,
      1,
      1,
      1,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      4,
      3,
      4,
      0,
      3,
      4,
      2,
      1,
      2,
      1,
      1,
      4,
      3,
      3,
      2,
      2,
      1,
      0,
      2,
      2,
      2,
      3,
      1,
      0,
      4,
      4,
      1,
      2,
      4,
      3,
      1,
      2,
      1,
      2,
      2,
      0,
      3,
      4,
      1,
      1,
      3,
      0,
      3,
      3,
      0,
      0,
      4,
      3,
      4,
      3,
      4
    ],
    "issueQuantity": [
      2,
      2,
      2,
      3,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      3,
      4,
      2,
      0,
      1,
      0,
      1,
      1,
      3,
      2,
      0,
      1,
      4,
      2,
      0,
      1,
      1,
      2,
      0,
      4,
      0,
      0,
      1,
      2,
      2,
      4,
      3,
      4,
      3,
      0,
      3,
      4,
      1,
      2,
      3,
      0,
      0,
      3,
      4
    ]
  },
  {
    "id": "VNDR-0000093",
    "status": "inactive",
    "name": "PharmaLogix International",
    "tag": "Facilities Management, Equipment",
    "contactName": "Dawn Krueger",
    "industryRating": 2.1,
    "procedures": "[PR-047,PR-065,PR-040,PR-035,PR-041,PR-050,PR-017,PR-072,PR-037,PR-046]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000064,CNTRCT-0000033]",
    "orders": "[ORDR-0-0000037,ORDR-1-0000024,ORDR-2-0000013,ORDR-3-0000016,ORDR-4-0000045,ORDR-5-0000030,ORDR-6-0000026,ORDR-7-0000017,ORDR-8-0000021,ORDR-9-0000018,ORDR-10-0000045,ORDR-11-0000047,ORDR-12-0000030,ORDR-13-0000056,ORDR-14-0000069,ORDR-15-0000018,ORDR-16-0000036,ORDR-17-0000037,ORDR-18-0000024]",
    "spendWeekly": [
      3042,
      2933,
      2957,
      2965,
      2998,
      2905,
      3064,
      3076,
      2951,
      2995,
      2990,
      2904,
      2928,
      2909,
      3011,
      2966,
      2907,
      3025,
      2900,
      2978,
      3001,
      2927,
      3047,
      3008,
      2906,
      2933,
      2941,
      2990,
      3040,
      2904,
      3022,
      2930,
      3016,
      3088,
      3046,
      2904,
      2909,
      3034,
      2938,
      2949,
      2941,
      2957,
      3081,
      3091,
      3007,
      2990,
      2987,
      3024,
      2930,
      2905,
      2905,
      3097
    ],
    "orderRejected": [
      3097,
      15,
      1,
      6,
      6,
      19,
      11,
      17,
      10,
      13,
      9,
      15,
      7,
      0,
      14,
      18,
      18,
      2,
      8,
      0,
      1,
      11,
      0,
      19,
      6,
      0,
      7,
      0,
      19,
      18,
      9,
      19,
      1,
      9,
      19,
      13,
      9,
      7,
      7,
      11,
      0,
      10,
      1,
      2,
      8,
      4,
      8,
      18,
      13,
      11,
      8,
      19,
      15
    ],
    "orderAccepted": [
      5,
      8,
      1,
      3,
      7,
      5,
      3,
      11,
      7,
      2,
      6,
      11,
      0,
      3,
      11,
      0,
      9,
      1,
      5,
      8,
      2,
      5,
      2,
      3,
      7,
      6,
      3,
      9,
      11,
      2,
      10,
      11,
      5,
      1,
      2,
      6,
      7,
      0,
      4,
      11,
      2,
      0,
      4,
      3,
      6,
      11,
      0,
      4,
      6,
      10,
      5,
      6
    ],
    "orderHold": [
      3,
      0,
      3,
      4,
      4,
      0,
      3,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      4,
      1,
      4,
      0,
      1,
      1,
      2,
      2,
      4,
      4,
      0,
      2,
      1,
      2,
      3,
      2,
      3,
      1,
      0,
      0,
      0,
      4,
      0,
      1,
      0,
      1,
      3,
      1,
      3,
      3,
      4,
      3,
      0,
      0,
      0,
      1,
      0,
      4
    ],
    "orderBackordered": [
      4,
      2,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      2,
      0,
      1,
      2,
      2,
      4,
      0,
      4,
      3,
      4,
      2,
      1,
      1,
      0,
      3,
      2,
      2,
      4,
      0,
      3,
      2,
      1,
      1,
      4,
      3,
      2,
      4,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      3,
      1,
      0,
      1,
      3,
      1,
      2,
      1,
      4,
      4,
      0,
      0,
      4
    ],
    "issueDelay": [
      2,
      4,
      4,
      1,
      0,
      2,
      0,
      4,
      0,
      1,
      3,
      3,
      4,
      4,
      4,
      1,
      3,
      0,
      0,
      3,
      4,
      0,
      1,
      4,
      2,
      0,
      3,
      0,
      1,
      4,
      3,
      3,
      0,
      2,
      2,
      4,
      0,
      2,
      1,
      4,
      1,
      1,
      0,
      4,
      1,
      4,
      1,
      1,
      4,
      2,
      0,
      4
    ],
    "issueQuantity": [
      3,
      0,
      1,
      0,
      1,
      2,
      1,
      0,
      4,
      1,
      2,
      4,
      1,
      1,
      2,
      1,
      4,
      2,
      0,
      4,
      4,
      2,
      0,
      1,
      4,
      3,
      1,
      0,
      3,
      1,
      1,
      1,
      3,
      3,
      0,
      4,
      4,
      0,
      0,
      3,
      3,
      4,
      4,
      3,
      3,
      0,
      4,
      2,
      0,
      1,
      2,
      0
    ]
  },
  {
    "id": "VNDR-0000094",
    "status": "inactive",
    "name": "VitalityGear Solutions",
    "tag": "Equipment",
    "contactName": "Carlos Flores",
    "industryRating": 2.1,
    "procedures": "[PR-054,PR-045,PR-030,PR-064,PR-061,PR-049,PR-055,PR-031,PR-061,PR-074]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000044,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000063,ORDR-1-0000051,ORDR-2-0000047,ORDR-3-0000014,ORDR-4-0000048,ORDR-5-0000051,ORDR-6-0000043,ORDR-7-0000015,ORDR-8-0000057,ORDR-9-0000043,ORDR-10-0000025,ORDR-11-0000044,ORDR-12-0000014,ORDR-13-0000069,ORDR-14-0000030,ORDR-15-0000074,ORDR-16-0000036,ORDR-17-0000080,ORDR-18-0000081]",
    "spendWeekly": [
      3097,
      3027,
      2906,
      2996,
      3063,
      2957,
      2983,
      2903,
      3068,
      2934,
      3100,
      2968,
      2967,
      2967,
      3026,
      3020,
      2968,
      3095,
      2917,
      2909,
      3100,
      3050,
      3058,
      2903,
      3095,
      2991,
      2933,
      2953,
      2986,
      2902,
      2975,
      3012,
      3066,
      2904,
      3086,
      3051,
      2928,
      2902,
      3099,
      2901,
      3023,
      3074,
      3097,
      3038,
      2902,
      2933,
      2944,
      2900,
      3066,
      2942,
      2905,
      2936
    ],
    "orderRejected": [
      2936,
      7,
      5,
      19,
      18,
      14,
      4,
      9,
      5,
      19,
      10,
      17,
      6,
      14,
      2,
      12,
      15,
      8,
      9,
      5,
      0,
      12,
      4,
      18,
      13,
      13,
      14,
      0,
      9,
      15,
      5,
      15,
      13,
      16,
      15,
      0,
      7,
      1,
      9,
      3,
      12,
      19,
      3,
      0,
      18,
      9,
      0,
      18,
      19,
      0,
      4,
      4,
      19
    ],
    "orderAccepted": [
      10,
      11,
      1,
      0,
      0,
      8,
      8,
      5,
      0,
      0,
      7,
      6,
      3,
      3,
      11,
      10,
      0,
      4,
      8,
      3,
      0,
      3,
      8,
      6,
      11,
      11,
      0,
      11,
      4,
      6,
      11,
      3,
      0,
      5,
      0,
      11,
      2,
      1,
      8,
      4,
      0,
      10,
      7,
      3,
      4,
      11,
      0,
      5,
      0,
      5,
      1,
      7
    ],
    "orderHold": [
      2,
      0,
      2,
      0,
      4,
      3,
      4,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      4,
      0,
      4,
      0,
      1,
      1,
      4,
      4,
      4,
      4,
      0,
      3,
      3,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      3,
      2,
      2,
      4,
      4,
      3,
      2,
      3,
      2,
      2,
      0,
      1,
      1,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      0,
      0,
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      3,
      3,
      1,
      4,
      1,
      2,
      4,
      4,
      3,
      4,
      2,
      1,
      4,
      1,
      2,
      3,
      2,
      4,
      1,
      0,
      4,
      4,
      0,
      3,
      3,
      1,
      2,
      2,
      1,
      0,
      1,
      0,
      3,
      3,
      2,
      2,
      2,
      4,
      1,
      0,
      4,
      4,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      4
    ],
    "issueDelay": [
      3,
      2,
      4,
      4,
      1,
      2,
      4,
      1,
      2,
      2,
      0,
      2,
      4,
      3,
      1,
      2,
      2,
      0,
      0,
      1,
      1,
      4,
      0,
      0,
      0,
      4,
      3,
      0,
      3,
      2,
      4,
      3,
      4,
      1,
      1,
      4,
      0,
      0,
      4,
      4,
      1,
      0,
      4,
      4,
      4,
      1,
      1,
      3,
      4,
      3,
      4,
      3
    ],
    "issueQuantity": [
      3,
      0,
      2,
      3,
      0,
      1,
      2,
      3,
      4,
      4,
      4,
      1,
      0,
      1,
      1,
      4,
      2,
      2,
      0,
      4,
      1,
      4,
      3,
      0,
      2,
      4,
      1,
      0,
      4,
      4,
      0,
      4,
      4,
      0,
      1,
      3,
      4,
      0,
      2,
      1,
      4,
      2,
      4,
      2,
      3,
      1,
      0,
      0,
      0,
      0,
      0,
      2
    ]
  },
  {
    "id": "VNDR-0000095",
    "status": "inactive",
    "name": "PharmaLogix International",
    "tag": "Equipment, PPE, Nutrition",
    "contactName": "Helen Ponce",
    "industryRating": 2.5,
    "procedures": "[PR-041,PR-045,PR-057,PR-064,PR-069,PR-013,PR-017,PR-062,PR-073,PR-041]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000030,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000024,ORDR-1-0000063,ORDR-2-0000017,ORDR-3-0000036,ORDR-4-0000034,ORDR-5-0000030,ORDR-6-0000041,ORDR-7-0000063,ORDR-8-0000014,ORDR-9-0000043,ORDR-10-0000033,ORDR-11-0000014,ORDR-12-0000043,ORDR-13-0000016,ORDR-14-0000035,ORDR-15-0000047,ORDR-16-0000015,ORDR-17-0000078,ORDR-18-0000026]",
    "spendWeekly": [
      3028,
      2960,
      3037,
      2902,
      2903,
      2905,
      3100,
      3065,
      3073,
      3007,
      3092,
      3095,
      3068,
      2939,
      2939,
      2945,
      2932,
      3093,
      3061,
      2983,
      3096,
      3079,
      3001,
      3005,
      2964,
      3096,
      2978,
      3031,
      2934,
      2945,
      3031,
      3006,
      2973,
      3072,
      3043,
      2926,
      2907,
      3070,
      2977,
      3052,
      3096,
      3026,
      2960,
      3042,
      2977,
      2976,
      3055,
      2946,
      2987,
      2955,
      2907,
      2930
    ],
    "orderRejected": [
      2930,
      10,
      9,
      4,
      8,
      11,
      6,
      7,
      19,
      12,
      6,
      12,
      17,
      13,
      11,
      19,
      19,
      0,
      10,
      4,
      13,
      13,
      13,
      19,
      6,
      10,
      3,
      13,
      16,
      18,
      14,
      3,
      7,
      19,
      17,
      18,
      3,
      13,
      3,
      6,
      18,
      9,
      0,
      6,
      6,
      3,
      15,
      5,
      17,
      15,
      18,
      19,
      14
    ],
    "orderAccepted": [
      3,
      3,
      7,
      10,
      5,
      1,
      9,
      1,
      10,
      7,
      7,
      2,
      5,
      9,
      11,
      5,
      11,
      10,
      8,
      8,
      7,
      4,
      1,
      10,
      6,
      2,
      5,
      3,
      6,
      4,
      9,
      6,
      0,
      1,
      8,
      9,
      9,
      4,
      11,
      11,
      6,
      11,
      3,
      6,
      0,
      11,
      8,
      11,
      11,
      7,
      0,
      6
    ],
    "orderHold": [
      2,
      0,
      1,
      4,
      0,
      2,
      3,
      4,
      4,
      4,
      1,
      4,
      1,
      4,
      3,
      2,
      1,
      1,
      3,
      4,
      3,
      1,
      0,
      4,
      0,
      4,
      1,
      3,
      2,
      0,
      4,
      4,
      4,
      4,
      0,
      0,
      4,
      2,
      3,
      4,
      2,
      2,
      4,
      3,
      2,
      1,
      4,
      2,
      2,
      2,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      0,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      2,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      1,
      0,
      0,
      0,
      0,
      4,
      0,
      2,
      0,
      0,
      1,
      1,
      0,
      3,
      0,
      2,
      4,
      0,
      0,
      2,
      1,
      4,
      0,
      3,
      0,
      2,
      2,
      4,
      0,
      4,
      2,
      2,
      0,
      4,
      2,
      0,
      0,
      0,
      2,
      1,
      1,
      3,
      3,
      1,
      3,
      1,
      3,
      3,
      4,
      4
    ],
    "issueDelay": [
      0,
      2,
      4,
      4,
      4,
      3,
      2,
      0,
      1,
      0,
      4,
      3,
      3,
      2,
      0,
      1,
      0,
      2,
      4,
      4,
      0,
      0,
      2,
      1,
      1,
      0,
      3,
      2,
      0,
      3,
      1,
      2,
      4,
      2,
      4,
      3,
      4,
      3,
      2,
      0,
      3,
      1,
      2,
      1,
      4,
      2,
      0,
      2,
      0,
      0,
      4,
      4
    ],
    "issueQuantity": [
      3,
      2,
      0,
      1,
      1,
      3,
      2,
      2,
      1,
      3,
      4,
      0,
      1,
      4,
      4,
      0,
      1,
      2,
      0,
      2,
      0,
      2,
      2,
      1,
      3,
      4,
      0,
      2,
      2,
      0,
      1,
      1,
      3,
      2,
      1,
      3,
      0,
      1,
      1,
      4,
      3,
      3,
      4,
      2,
      1,
      4,
      1,
      3,
      2,
      3,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000096",
    "status": "active",
    "name": "PharmaLogix International",
    "tag": "Medical Supplies, PPE",
    "contactName": "Brian Baird",
    "industryRating": 3,
    "procedures": "[PR-066,PR-066,PR-055,PR-048,PR-030,PR-071,PR-052,PR-045,PR-070,PR-048]",
    "contracts": "[CNTRCT-0000013,CNTRCT-0000036,CNTRCT-0000049]",
    "orders": "[ORDR-0-0000013,ORDR-1-0000045,ORDR-2-0000045,ORDR-3-0000016,ORDR-4-0000051,ORDR-5-0000038,ORDR-6-0000024,ORDR-7-0000010,ORDR-8-0000069,ORDR-9-0000069,ORDR-10-0000048,ORDR-11-0000045,ORDR-12-0000037,ORDR-13-0000029,ORDR-14-0000067,ORDR-15-0000035,ORDR-16-0000050,ORDR-17-0000074,ORDR-18-0000044]",
    "spendWeekly": [
      2992,
      2924,
      2908,
      2913,
      2903,
      3008,
      2932,
      3065,
      2994,
      3028,
      3089,
      3004,
      2955,
      3025,
      3091,
      2961,
      2951,
      3099,
      2900,
      3098,
      2903,
      3007,
      2903,
      3069,
      2971,
      3055,
      3078,
      2901,
      2956,
      2977,
      3019,
      3018,
      3091,
      2974,
      3092,
      2960,
      2907,
      3022,
      2906,
      3084,
      2973,
      2982,
      3062,
      3026,
      3043,
      3014,
      3052,
      2900,
      2950,
      2929,
      3060,
      2968
    ],
    "orderRejected": [
      2968,
      14,
      4,
      0,
      0,
      19,
      19,
      9,
      7,
      19,
      9,
      6,
      6,
      0,
      3,
      10,
      19,
      19,
      1,
      9,
      9,
      0,
      12,
      2,
      0,
      1,
      0,
      6,
      13,
      11,
      13,
      19,
      19,
      11,
      16,
      9,
      1,
      19,
      1,
      8,
      19,
      19,
      15,
      14,
      19,
      16,
      0,
      13,
      15,
      19,
      7,
      10,
      19
    ],
    "orderAccepted": [
      4,
      10,
      4,
      9,
      11,
      10,
      2,
      0,
      3,
      4,
      1,
      9,
      11,
      1,
      1,
      11,
      7,
      11,
      8,
      11,
      8,
      5,
      4,
      3,
      11,
      7,
      0,
      9,
      0,
      2,
      0,
      3,
      5,
      2,
      3,
      11,
      10,
      2,
      11,
      0,
      3,
      11,
      5,
      9,
      2,
      11,
      0,
      4,
      0,
      4,
      3,
      4
    ],
    "orderHold": [
      2,
      3,
      4,
      2,
      0,
      4,
      4,
      1,
      3,
      4,
      2,
      0,
      4,
      1,
      0,
      2,
      2,
      4,
      4,
      4,
      0,
      4,
      4,
      3,
      0,
      4,
      0,
      2,
      2,
      0,
      0,
      4,
      1,
      0,
      0,
      2,
      4,
      3,
      0,
      1,
      2,
      1,
      2,
      4,
      4,
      1,
      1,
      2,
      2,
      1,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      2,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      3,
      0,
      2,
      0,
      4,
      1,
      4,
      1,
      4,
      4,
      0,
      0,
      2,
      2,
      2,
      1,
      4,
      3,
      4,
      0,
      4,
      2,
      4,
      0,
      4,
      0,
      2,
      2,
      3,
      2,
      2,
      4,
      0,
      3,
      1,
      4,
      4,
      0,
      4,
      4,
      3,
      4,
      0,
      1,
      2,
      3,
      2,
      4
    ],
    "issueDelay": [
      2,
      4,
      4,
      4,
      2,
      4,
      2,
      2,
      3,
      3,
      0,
      4,
      4,
      1,
      0,
      2,
      4,
      4,
      3,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      4,
      2,
      0,
      4,
      1,
      4,
      1,
      1,
      2,
      4,
      2,
      2,
      2,
      1,
      1,
      4,
      3,
      1,
      4,
      2,
      0,
      1,
      3,
      0,
      0,
      3
    ],
    "issueQuantity": [
      2,
      1,
      3,
      3,
      4,
      3,
      0,
      0,
      3,
      3,
      1,
      2,
      0,
      2,
      4,
      1,
      4,
      3,
      2,
      3,
      3,
      4,
      1,
      4,
      2,
      3,
      1,
      4,
      2,
      4,
      1,
      4,
      1,
      2,
      0,
      3,
      4,
      0,
      2,
      4,
      4,
      1,
      4,
      0,
      0,
      3,
      3,
      1,
      0,
      4,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000097",
    "status": "active",
    "name": "PharmaSphere Equipment",
    "tag": "Laboratory Supplies, Equipment",
    "contactName": "Melissa Jefferson",
    "industryRating": 2.5,
    "procedures": "[PR-023,PR-065,PR-040,PR-062,PR-064,PR-069,PR-036,PR-063,PR-036,PR-072]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000050,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000031,ORDR-1-0000037,ORDR-2-0000020,ORDR-3-0000041,ORDR-4-0000003,ORDR-5-0000040,ORDR-6-0000046,ORDR-7-0000022,ORDR-8-0000029,ORDR-9-0000027,ORDR-10-0000046,ORDR-11-0000026,ORDR-12-0000037,ORDR-13-0000066,ORDR-14-0000024,ORDR-15-0000023,ORDR-16-0000026,ORDR-17-0000040,ORDR-18-0000058]",
    "spendWeekly": [
      2901,
      3055,
      2906,
      2974,
      3019,
      2905,
      3100,
      3098,
      2952,
      3012,
      3100,
      2969,
      2967,
      2952,
      3091,
      3097,
      2962,
      2900,
      2927,
      2909,
      3073,
      3097,
      2900,
      3097,
      2973,
      2973,
      3039,
      2941,
      3096,
      2902,
      2972,
      3028,
      2945,
      3049,
      3042,
      2904,
      2915,
      3057,
      3099,
      2982,
      2971,
      2961,
      3032,
      3065,
      2977,
      2901,
      3099,
      2900,
      3041,
      2982,
      3095,
      3082
    ],
    "orderRejected": [
      3082,
      10,
      5,
      9,
      15,
      15,
      3,
      1,
      7,
      10,
      6,
      8,
      18,
      7,
      12,
      16,
      17,
      0,
      0,
      8,
      0,
      5,
      0,
      12,
      19,
      0,
      12,
      0,
      19,
      19,
      11,
      17,
      14,
      19,
      19,
      3,
      0,
      18,
      19,
      3,
      8,
      19,
      13,
      0,
      17,
      18,
      0,
      18,
      17,
      6,
      17,
      6,
      11
    ],
    "orderAccepted": [
      8,
      11,
      6,
      11,
      5,
      6,
      6,
      10,
      5,
      0,
      0,
      0,
      4,
      2,
      11,
      11,
      5,
      0,
      0,
      8,
      0,
      0,
      1,
      7,
      7,
      1,
      0,
      11,
      7,
      0,
      8,
      4,
      0,
      2,
      0,
      11,
      6,
      8,
      7,
      3,
      3,
      11,
      4,
      11,
      4,
      11,
      2,
      0,
      11,
      6,
      4,
      5
    ],
    "orderHold": [
      0,
      0,
      3,
      1,
      4,
      0,
      4,
      3,
      4,
      1,
      4,
      2,
      1,
      4,
      0,
      2,
      3,
      0,
      4,
      2,
      0,
      4,
      3,
      2,
      4,
      4,
      4,
      2,
      4,
      0,
      2,
      3,
      3,
      0,
      0,
      3,
      4,
      0,
      2,
      1,
      1,
      0,
      2,
      0,
      2,
      3,
      0,
      1,
      1,
      3,
      0,
      0
    ],
    "orderBackordered": [
      0,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      2,
      0,
      0,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      2,
      0,
      0,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      4,
      1,
      4,
      3,
      0,
      0,
      1,
      0,
      1,
      4,
      3,
      3,
      4,
      3,
      2,
      0,
      1,
      3,
      1,
      3,
      1,
      2,
      3,
      2,
      1,
      4,
      2,
      0,
      4,
      4,
      2,
      3,
      0,
      0,
      1,
      2,
      0,
      4,
      4,
      4,
      0,
      1,
      2,
      4,
      0,
      3,
      2,
      3,
      4,
      2,
      0,
      4
    ],
    "issueDelay": [
      2,
      4,
      4,
      4,
      2,
      0,
      2,
      2,
      0,
      3,
      4,
      0,
      4,
      0,
      4,
      3,
      2,
      0,
      0,
      3,
      0,
      1,
      0,
      3,
      2,
      0,
      3,
      0,
      4,
      0,
      1,
      4,
      0,
      0,
      4,
      3,
      1,
      2,
      4,
      4,
      0,
      0,
      4,
      2,
      4,
      2,
      0,
      0,
      4,
      0,
      1,
      0
    ],
    "issueQuantity": [
      2,
      0,
      2,
      2,
      1,
      0,
      4,
      3,
      4,
      2,
      4,
      0,
      0,
      2,
      1,
      0,
      2,
      0,
      1,
      4,
      2,
      4,
      1,
      3,
      3,
      4,
      0,
      3,
      4,
      0,
      0,
      1,
      4,
      1,
      0,
      4,
      0,
      0,
      1,
      4,
      4,
      4,
      3,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000098",
    "status": "active",
    "name": "HealthWave Technologies",
    "tag": "Imaging",
    "contactName": "Jennifer Mccoy",
    "industryRating": 2.5,
    "procedures": "[PR-061,PR-056,PR-060,PR-062,PR-052,PR-070,PR-069,PR-060,PR-044,PR-068]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000056,CNTRCT-0000025]",
    "orders": "[ORDR-0-0000063,ORDR-1-0000031,ORDR-2-0000018,ORDR-3-0000015,ORDR-4-0000007,ORDR-5-0000020,ORDR-6-0000021,ORDR-7-0000064,ORDR-8-0000010,ORDR-9-0000033,ORDR-10-0000044,ORDR-11-0000041,ORDR-12-0000043,ORDR-13-0000028,ORDR-14-0000023,ORDR-15-0000032,ORDR-16-0000079,ORDR-17-0000018,ORDR-18-0000024]",
    "spendWeekly": [
      2945,
      2993,
      2906,
      2902,
      2903,
      3025,
      3065,
      2926,
      3002,
      2980,
      3087,
      3006,
      2940,
      3054,
      3017,
      2928,
      2970,
      3092,
      2922,
      3044,
      3068,
      3043,
      3095,
      3063,
      3011,
      2977,
      3095,
      2901,
      3081,
      2902,
      3022,
      3015,
      2980,
      2980,
      2973,
      2908,
      2907,
      2947,
      2943,
      3036,
      3003,
      2967,
      3065,
      3042,
      3016,
      2901,
      3099,
      2934,
      2970,
      2987,
      3094,
      3012
    ],
    "orderRejected": [
      3012,
      10,
      0,
      12,
      1,
      19,
      0,
      13,
      15,
      4,
      14,
      7,
      10,
      12,
      18,
      13,
      11,
      1,
      12,
      1,
      1,
      4,
      4,
      13,
      15,
      19,
      3,
      10,
      19,
      19,
      19,
      14,
      18,
      15,
      12,
      15,
      4,
      2,
      17,
      10,
      0,
      9,
      11,
      4,
      0,
      4,
      19,
      0,
      6,
      6,
      13,
      17,
      19
    ],
    "orderAccepted": [
      7,
      3,
      3,
      4,
      9,
      7,
      8,
      6,
      7,
      3,
      11,
      10,
      10,
      7,
      10,
      11,
      2,
      11,
      0,
      4,
      11,
      5,
      9,
      0,
      10,
      11,
      0,
      1,
      9,
      3,
      2,
      9,
      0,
      4,
      0,
      2,
      6,
      7,
      9,
      10,
      4,
      11,
      11,
      0,
      8,
      10,
      2,
      5,
      4,
      9,
      0,
      11
    ],
    "orderHold": [
      2,
      3,
      3,
      1,
      2,
      3,
      0,
      2,
      2,
      4,
      1,
      1,
      0,
      0,
      1,
      3,
      4,
      3,
      0,
      1,
      2,
      1,
      2,
      1,
      2,
      4,
      3,
      2,
      3,
      1,
      2,
      4,
      4,
      2,
      2,
      2,
      3,
      3,
      4,
      2,
      4,
      2,
      4,
      0,
      2,
      2,
      2,
      3,
      1,
      0,
      3,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      2,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      2,
      0,
      0,
      2,
      0,
      2,
      1,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      0,
      1,
      4,
      0,
      0,
      2,
      3,
      0,
      0,
      2,
      2,
      2,
      3,
      0,
      3,
      0,
      0,
      1,
      3,
      4,
      0,
      2,
      3,
      3,
      2,
      0,
      2,
      2,
      4,
      2,
      4,
      0,
      0,
      1,
      0,
      1,
      0,
      4,
      0,
      1,
      2,
      1,
      3,
      3,
      1,
      4,
      4,
      4,
      0,
      2,
      0
    ],
    "issueDelay": [
      4,
      0,
      4,
      2,
      2,
      4,
      4,
      1,
      1,
      1,
      2,
      1,
      2,
      4,
      0,
      2,
      1,
      3,
      0,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      4,
      1,
      1,
      4,
      3,
      4,
      0,
      4,
      3,
      0,
      1,
      0,
      3,
      4,
      4,
      3,
      4,
      0,
      3,
      0,
      2,
      3
    ],
    "issueQuantity": [
      2,
      4,
      1,
      1,
      3,
      4,
      2,
      2,
      3,
      0,
      2,
      2,
      0,
      3,
      2,
      4,
      1,
      2,
      2,
      3,
      1,
      3,
      3,
      0,
      2,
      4,
      0,
      1,
      1,
      0,
      2,
      4,
      1,
      4,
      2,
      4,
      4,
      2,
      0,
      1,
      1,
      2,
      3,
      2,
      4,
      0,
      4,
      2,
      0,
      3,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000099",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "Medical Supplies, Facilities Management, Imaging",
    "contactName": "Amanda Glenn",
    "industryRating": 3.1,
    "procedures": "[PR-044,PR-054,PR-047,PR-069,PR-069,PR-069,PR-069,PR-061,PR-055,PR-072]",
    "contracts": "[CNTRCT-0000039,CNTRCT-0000058,CNTRCT-0000051]",
    "orders": "[ORDR-0-0000045,ORDR-1-0000013,ORDR-2-0000034,ORDR-3-0000020,ORDR-4-0000007,ORDR-5-0000068,ORDR-6-0000039,ORDR-7-0000034,ORDR-8-0000060,ORDR-9-0000019,ORDR-10-0000042,ORDR-11-0000039,ORDR-12-0000066,ORDR-13-0000058,ORDR-14-0000024,ORDR-15-0000078,ORDR-16-0000028,ORDR-17-0000041,ORDR-18-0000038]",
    "spendWeekly": [
      2954,
      3068,
      2967,
      2967,
      2925,
      2938,
      3033,
      3098,
      3100,
      3099,
      2901,
      3035,
      2951,
      3033,
      3091,
      2932,
      3001,
      3099,
      2900,
      3032,
      2970,
      3021,
      3012,
      2933,
      3045,
      3054,
      2985,
      2901,
      2901,
      2928,
      2991,
      2931,
      3068,
      3032,
      2971,
      2994,
      3097,
      2948,
      3062,
      3085,
      3019,
      3006,
      3057,
      2962,
      3080,
      2986,
      3022,
      2967,
      3100,
      3091,
      2925,
      2980
    ],
    "orderRejected": [
      2980,
      19,
      10,
      11,
      2,
      15,
      19,
      0,
      14,
      10,
      0,
      4,
      10,
      12,
      16,
      2,
      17,
      4,
      17,
      15,
      14,
      19,
      0,
      16,
      19,
      13,
      0,
      0,
      15,
      19,
      10,
      15,
      4,
      19,
      1,
      0,
      2,
      13,
      10,
      0,
      19,
      19,
      2,
      0,
      17,
      15,
      0,
      8,
      0,
      19,
      5,
      19,
      18
    ],
    "orderAccepted": [
      4,
      6,
      11,
      7,
      2,
      11,
      11,
      4,
      0,
      11,
      5,
      10,
      11,
      11,
      6,
      11,
      1,
      0,
      6,
      0,
      0,
      11,
      11,
      0,
      11,
      5,
      11,
      8,
      5,
      7,
      7,
      0,
      0,
      0,
      10,
      11,
      1,
      0,
      11,
      6,
      11,
      9,
      10,
      5,
      11,
      10,
      8,
      11,
      1,
      0,
      0,
      3
    ],
    "orderHold": [
      4,
      4,
      4,
      2,
      3,
      4,
      3,
      4,
      3,
      0,
      3,
      0,
      4,
      3,
      0,
      0,
      3,
      0,
      4,
      4,
      4,
      0,
      4,
      2,
      4,
      0,
      1,
      1,
      3,
      1,
      4,
      4,
      0,
      3,
      1,
      4,
      4,
      4,
      4,
      3,
      3,
      1,
      4,
      4,
      0,
      4,
      1,
      0,
      0,
      2,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      0,
      2,
      0,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      0,
      0,
      4,
      3,
      2,
      4,
      0,
      4,
      3,
      2,
      2,
      0,
      3,
      0,
      0,
      1,
      4,
      2,
      2,
      0,
      1,
      3,
      2,
      2,
      1,
      3,
      0,
      0,
      4,
      1,
      1,
      4,
      3,
      1,
      3,
      4,
      0,
      1,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      4,
      1,
      2,
      4
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      0,
      3,
      2,
      0,
      2,
      2,
      2,
      1,
      1,
      4,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      0,
      3,
      0,
      2,
      4,
      3,
      0,
      3,
      0,
      0,
      4,
      4,
      4,
      1,
      2,
      3,
      3,
      2,
      3,
      1,
      0,
      1,
      0,
      1,
      2
    ],
    "issueQuantity": [
      3,
      1,
      4,
      0,
      4,
      4,
      0,
      0,
      1,
      0,
      3,
      4,
      1,
      2,
      1,
      4,
      3,
      0,
      1,
      3,
      2,
      2,
      2,
      4,
      2,
      4,
      4,
      0,
      2,
      4,
      1,
      2,
      3,
      4,
      3,
      3,
      4,
      0,
      0,
      4,
      4,
      2,
      4,
      0,
      1,
      0,
      4,
      4,
      2,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000100",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "IT Services, Surgical Instruments",
    "contactName": "Kaitlyn Hernandez",
    "industryRating": 2.1,
    "procedures": "[PR-017,PR-065,PR-034,PR-056,PR-068,PR-070,PR-069,PR-059,PR-074,PR-067]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000042,CNTRCT-0000041]",
    "orders": "[ORDR-0-0000038,ORDR-1-0000065,ORDR-2-0000061,ORDR-3-0000032,ORDR-4-0000008,ORDR-5-0000062,ORDR-6-0000054,ORDR-7-0000068,ORDR-8-0000049,ORDR-9-0000029,ORDR-10-0000042,ORDR-11-0000028,ORDR-12-0000017,ORDR-13-0000053,ORDR-14-0000020,ORDR-15-0000050,ORDR-16-0000058,ORDR-17-0000060,ORDR-18-0000039]",
    "spendWeekly": [
      2999,
      3025,
      3008,
      2927,
      2969,
      3084,
      3035,
      2915,
      2902,
      2990,
      3051,
      3080,
      2909,
      3091,
      2980,
      3007,
      2928,
      3001,
      3007,
      3098,
      3100,
      3097,
      2932,
      3049,
      2984,
      2901,
      2950,
      2966,
      3051,
      3085,
      3047,
      3004,
      3010,
      3043,
      2985,
      2927,
      2907,
      3032,
      3091,
      2938,
      3023,
      3080,
      3029,
      2995,
      2907,
      3046,
      2969,
      3019,
      2942,
      2949,
      3042,
      3097
    ],
    "orderRejected": [
      3097,
      4,
      8,
      15,
      11,
      19,
      9,
      15,
      10,
      19,
      4,
      9,
      15,
      18,
      1,
      14,
      19,
      16,
      6,
      2,
      0,
      19,
      10,
      19,
      19,
      4,
      0,
      0,
      16,
      17,
      5,
      18,
      19,
      19,
      0,
      4,
      0,
      12,
      15,
      7,
      0,
      15,
      11,
      1,
      13,
      2,
      10,
      13,
      16,
      10,
      0,
      17,
      19
    ],
    "orderAccepted": [
      0,
      1,
      1,
      7,
      4,
      11,
      11,
      7,
      1,
      8,
      1,
      0,
      11,
      7,
      7,
      8,
      4,
      3,
      4,
      7,
      2,
      11,
      5,
      0,
      0,
      0,
      1,
      9,
      6,
      10,
      9,
      2,
      0,
      1,
      5,
      7,
      0,
      0,
      11,
      7,
      4,
      3,
      8,
      0,
      0,
      10,
      8,
      11,
      4,
      3,
      9,
      0
    ],
    "orderHold": [
      2,
      2,
      3,
      1,
      3,
      4,
      2,
      2,
      4,
      3,
      2,
      1,
      0,
      4,
      3,
      2,
      3,
      1,
      4,
      4,
      2,
      2,
      1,
      2,
      0,
      2,
      3,
      1,
      4,
      1,
      2,
      0,
      4,
      3,
      0,
      4,
      3,
      2,
      0,
      4,
      0,
      3,
      0,
      3,
      3,
      3,
      2,
      0,
      2,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      2,
      2,
      0,
      0,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0
    ],
    "issueQuality": [
      0,
      4,
      0,
      1,
      0,
      4,
      0,
      3,
      1,
      3,
      1,
      4,
      2,
      1,
      0,
      1,
      4,
      3,
      2,
      4,
      4,
      3,
      0,
      1,
      0,
      4,
      4,
      1,
      2,
      4,
      4,
      4,
      3,
      3,
      2,
      4,
      2,
      0,
      3,
      2,
      0,
      2,
      3,
      0,
      2,
      2,
      4,
      0,
      1,
      4,
      2,
      2
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      1,
      4,
      1,
      2,
      0,
      4,
      2,
      2,
      3,
      0,
      4,
      4,
      2,
      0,
      2,
      3,
      4,
      1,
      2,
      2,
      1,
      2,
      1,
      3,
      2,
      0,
      2,
      0,
      1,
      2,
      4,
      3,
      1,
      2,
      4,
      3,
      4,
      4,
      2,
      2,
      4,
      0,
      0,
      2,
      1,
      0,
      3,
      4
    ],
    "issueQuantity": [
      0,
      4,
      2,
      2,
      4,
      0,
      4,
      3,
      3,
      1,
      3,
      2,
      0,
      2,
      1,
      4,
      2,
      3,
      3,
      4,
      0,
      2,
      2,
      4,
      2,
      3,
      2,
      0,
      4,
      4,
      4,
      4,
      2,
      2,
      3,
      3,
      4,
      3,
      3,
      2,
      2,
      3,
      2,
      1,
      4,
      0,
      2,
      3,
      0,
      0,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000101",
    "status": "inactive",
    "name": "LifeLine Supplies",
    "tag": "Equipment",
    "contactName": "Laura Thomas",
    "industryRating": 2.1,
    "procedures": "[PR-061,PR-057,PR-043,PR-032,PR-066,PR-070,PR-068,PR-064,PR-061,PR-040]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000064,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000005,ORDR-1-0000013,ORDR-2-0000035,ORDR-3-0000062,ORDR-4-0000055,ORDR-5-0000064,ORDR-6-0000062,ORDR-7-0000064,ORDR-8-0000064,ORDR-9-0000041,ORDR-10-0000035,ORDR-11-0000068,ORDR-12-0000046,ORDR-13-0000041,ORDR-14-0000057,ORDR-15-0000036,ORDR-16-0000073,ORDR-17-0000076,ORDR-18-0000052]",
    "spendWeekly": [
      2901,
      3032,
      2960,
      2963,
      3095,
      2905,
      3050,
      3098,
      2905,
      2964,
      3100,
      2904,
      2909,
      2928,
      2943,
      2901,
      2910,
      3099,
      2985,
      2909,
      3100,
      3023,
      2975,
      3025,
      3044,
      3096,
      2961,
      3035,
      2947,
      2998,
      3018,
      3097,
      3072,
      3093,
      3095,
      3083,
      2964,
      2902,
      3046,
      3017,
      2961,
      3020,
      3097,
      2953,
      2902,
      2912,
      3087,
      2900,
      3100,
      3097,
      3012,
      2976
    ],
    "orderRejected": [
      2976,
      19,
      11,
      14,
      19,
      19,
      15,
      17,
      12,
      19,
      0,
      13,
      0,
      8,
      5,
      19,
      9,
      0,
      0,
      1,
      1,
      19,
      0,
      17,
      19,
      2,
      6,
      0,
      5,
      19,
      10,
      12,
      0,
      19,
      0,
      0,
      9,
      9,
      15,
      5,
      12,
      16,
      11,
      0,
      8,
      9,
      2,
      18,
      16,
      16,
      3,
      1,
      0
    ],
    "orderAccepted": [
      1,
      7,
      2,
      2,
      3,
      11,
      4,
      5,
      3,
      0,
      1,
      8,
      5,
      8,
      10,
      9,
      0,
      5,
      7,
      2,
      0,
      3,
      6,
      5,
      0,
      0,
      0,
      9,
      0,
      5,
      1,
      3,
      2,
      0,
      2,
      11,
      3,
      0,
      9,
      6,
      0,
      8,
      11,
      1,
      11,
      11,
      5,
      11,
      0,
      7,
      2,
      0
    ],
    "orderHold": [
      1,
      3,
      1,
      1,
      4,
      0,
      1,
      2,
      1,
      0,
      3,
      4,
      0,
      4,
      4,
      0,
      3,
      0,
      4,
      3,
      2,
      2,
      4,
      2,
      1,
      3,
      0,
      1,
      4,
      0,
      3,
      4,
      0,
      3,
      0,
      2,
      2,
      0,
      3,
      3,
      1,
      0,
      0,
      2,
      2,
      0,
      0,
      1,
      1,
      0,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      1,
      4,
      4,
      3,
      4,
      3,
      4,
      1,
      3,
      1,
      0,
      0,
      2,
      1,
      4,
      2,
      1,
      4,
      0,
      0,
      1,
      1,
      0,
      4,
      0,
      0,
      4,
      4,
      2,
      4,
      4,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      4,
      0,
      4,
      3,
      0,
      3,
      3,
      4,
      0,
      0,
      0
    ],
    "issueDelay": [
      3,
      1,
      4,
      1,
      0,
      4,
      4,
      0,
      2,
      1,
      0,
      4,
      0,
      4,
      0,
      2,
      3,
      0,
      0,
      0,
      3,
      2,
      0,
      3,
      0,
      2,
      3,
      0,
      4,
      1,
      4,
      2,
      4,
      1,
      2,
      1,
      0,
      2,
      2,
      4,
      2,
      0,
      0,
      1,
      0,
      1,
      4,
      0,
      0,
      1,
      4,
      0
    ],
    "issueQuantity": [
      4,
      2,
      2,
      0,
      2,
      2,
      3,
      0,
      1,
      4,
      3,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      1,
      4,
      2,
      2,
      3,
      1,
      0,
      2,
      2,
      0,
      3,
      3,
      1,
      0,
      3,
      2,
      1,
      2,
      4,
      2,
      1,
      4,
      4,
      3,
      4,
      2,
      4,
      0,
      2,
      0,
      0,
      4,
      2,
      3
    ]
  },
  {
    "id": "VNDR-0000102",
    "status": "active",
    "name": "MedPulse Supply Network",
    "tag": "Imaging, Pharmaceuticals, Facilities Management",
    "contactName": "Lori Potts",
    "industryRating": 2.3,
    "procedures": "[PR-039,PR-064,PR-050,PR-048,PR-045,PR-036,PR-043,PR-051,PR-071,PR-059]",
    "contracts": "[CNTRCT-0000033,CNTRCT-0000044,CNTRCT-0000017]",
    "orders": "[ORDR-0-0000050,ORDR-1-0000065,ORDR-2-0000042,ORDR-3-0000057,ORDR-4-0000015,ORDR-5-0000012,ORDR-6-0000065,ORDR-7-0000015,ORDR-8-0000068,ORDR-9-0000065,ORDR-10-0000021,ORDR-11-0000037,ORDR-12-0000054,ORDR-13-0000060,ORDR-14-0000024,ORDR-15-0000037,ORDR-16-0000067,ORDR-17-0000032,ORDR-18-0000057]",
    "spendWeekly": [
      2979,
      3036,
      2906,
      3042,
      3060,
      2969,
      3014,
      3026,
      3044,
      3042,
      3025,
      3074,
      2977,
      2973,
      3064,
      2975,
      3054,
      3099,
      2900,
      2972,
      3083,
      3065,
      2960,
      3051,
      3036,
      2997,
      2988,
      2988,
      3096,
      2975,
      2903,
      2942,
      3010,
      3037,
      2915,
      3026,
      3095,
      3004,
      3071,
      3005,
      3033,
      2972,
      2967,
      3072,
      2941,
      2901,
      2966,
      2926,
      2968,
      2985,
      2951,
      3033
    ],
    "orderRejected": [
      3033,
      17,
      5,
      17,
      16,
      7,
      9,
      17,
      8,
      7,
      2,
      0,
      0,
      10,
      9,
      10,
      18,
      3,
      0,
      0,
      0,
      2,
      10,
      9,
      6,
      4,
      3,
      3,
      16,
      16,
      0,
      13,
      14,
      19,
      13,
      16,
      11,
      5,
      13,
      8,
      0,
      8,
      0,
      12,
      8,
      14,
      12,
      5,
      14,
      14,
      0,
      8,
      1
    ],
    "orderAccepted": [
      9,
      10,
      7,
      8,
      4,
      9,
      11,
      1,
      3,
      9,
      0,
      2,
      9,
      2,
      1,
      1,
      6,
      1,
      0,
      7,
      4,
      11,
      0,
      5,
      5,
      7,
      0,
      11,
      10,
      5,
      11,
      11,
      1,
      0,
      10,
      8,
      1,
      1,
      9,
      2,
      6,
      11,
      2,
      2,
      5,
      6,
      5,
      6,
      5,
      4,
      6,
      3
    ],
    "orderHold": [
      1,
      0,
      0,
      4,
      3,
      1,
      4,
      4,
      3,
      3,
      0,
      2,
      0,
      2,
      2,
      3,
      2,
      2,
      2,
      1,
      3,
      4,
      4,
      3,
      4,
      4,
      1,
      2,
      4,
      0,
      2,
      3,
      2,
      0,
      0,
      2,
      2,
      4,
      2,
      0,
      3,
      1,
      3,
      1,
      2,
      4,
      2,
      0,
      1,
      3,
      4,
      4
    ],
    "orderBackordered": [
      4,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      2,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      3,
      2,
      2,
      0,
      4,
      1,
      1,
      4,
      3,
      3,
      3,
      4,
      3,
      0,
      0,
      4,
      3,
      2,
      4,
      1,
      2,
      1,
      2,
      2,
      4,
      4,
      0,
      2,
      2,
      2,
      3,
      2,
      1,
      3,
      3,
      2,
      3,
      3,
      4,
      0,
      0,
      4,
      3,
      4,
      3,
      4,
      4,
      3,
      4,
      1,
      1
    ],
    "issueDelay": [
      2,
      3,
      3,
      3,
      0,
      4,
      1,
      0,
      0,
      2,
      4,
      0,
      2,
      2,
      3,
      3,
      2,
      0,
      2,
      0,
      1,
      0,
      2,
      4,
      2,
      2,
      0,
      0,
      4,
      2,
      2,
      1,
      4,
      3,
      4,
      2,
      1,
      0,
      4,
      2,
      1,
      1,
      4,
      0,
      3,
      4,
      0,
      3,
      1,
      4,
      3,
      3
    ],
    "issueQuantity": [
      2,
      1,
      2,
      0,
      4,
      3,
      4,
      2,
      3,
      0,
      1,
      1,
      3,
      3,
      2,
      1,
      2,
      4,
      3,
      2,
      3,
      2,
      3,
      2,
      4,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      4,
      0,
      0,
      0,
      4,
      2,
      4,
      4,
      0,
      1,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000103",
    "status": "inactive",
    "name": "VitalityGear Solutions",
    "tag": "PPE",
    "contactName": "Nicholas Davis",
    "industryRating": 4.8,
    "procedures": "[PR-060,PR-067,PR-062,PR-065,PR-048,PR-060,PR-021,PR-046,PR-073,PR-025]",
    "contracts": "[CNTRCT-0000042,CNTRCT-0000047,CNTRCT-0000058]",
    "orders": "[ORDR-0-0000026,ORDR-1-0000018,ORDR-2-0000004,ORDR-3-0000061,ORDR-4-0000017,ORDR-5-0000048,ORDR-6-0000032,ORDR-7-0000016,ORDR-8-0000031,ORDR-9-0000053,ORDR-10-0000020,ORDR-11-0000023,ORDR-12-0000065,ORDR-13-0000044,ORDR-14-0000069,ORDR-15-0000041,ORDR-16-0000075,ORDR-17-0000080,ORDR-18-0000032]",
    "spendWeekly": [
      2916,
      3082,
      2986,
      2902,
      2978,
      2972,
      3008,
      3098,
      2969,
      2993,
      2995,
      2904,
      3050,
      3090,
      3021,
      3020,
      3033,
      3037,
      3099,
      3075,
      3097,
      2921,
      3043,
      2903,
      2908,
      3096,
      3095,
      3053,
      2901,
      3036,
      2946,
      3053,
      2982,
      2954,
      2913,
      2904,
      2980,
      2995,
      3009,
      3039,
      2960,
      2942,
      3097,
      3091,
      3100,
      2984,
      3024,
      2956,
      2901,
      2916,
      2905,
      3039
    ],
    "orderRejected": [
      3039,
      11,
      12,
      0,
      6,
      19,
      8,
      5,
      11,
      14,
      2,
      15,
      19,
      12,
      16,
      17,
      13,
      0,
      3,
      9,
      3,
      9,
      6,
      17,
      0,
      19,
      4,
      7,
      17,
      0,
      6,
      5,
      19,
      16,
      10,
      0,
      9,
      0,
      19,
      11,
      19,
      10,
      5,
      19,
      4,
      0,
      12,
      9,
      19,
      5,
      17,
      5,
      19
    ],
    "orderAccepted": [
      0,
      10,
      0,
      3,
      11,
      9,
      10,
      4,
      1,
      6,
      10,
      11,
      7,
      8,
      6,
      7,
      1,
      10,
      3,
      4,
      11,
      0,
      11,
      6,
      2,
      8,
      2,
      8,
      11,
      0,
      0,
      4,
      1,
      0,
      0,
      6,
      10,
      0,
      10,
      1,
      4,
      11,
      11,
      7,
      0,
      0,
      6,
      6,
      4,
      4,
      4,
      8
    ],
    "orderHold": [
      3,
      3,
      4,
      3,
      2,
      1,
      2,
      0,
      1,
      3,
      1,
      1,
      2,
      4,
      1,
      2,
      3,
      0,
      4,
      2,
      1,
      4,
      1,
      4,
      0,
      2,
      2,
      4,
      0,
      0,
      4,
      4,
      0,
      4,
      0,
      2,
      3,
      2,
      2,
      2,
      3,
      0,
      4,
      4,
      2,
      2,
      2,
      4,
      2,
      1,
      4,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      0,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      4,
      0,
      0,
      4,
      0,
      2,
      2,
      0,
      1,
      0,
      3,
      1,
      3,
      3,
      4,
      3,
      3,
      1,
      2,
      0,
      0,
      4,
      3,
      2,
      4,
      0,
      0,
      1,
      0,
      2,
      0,
      0,
      3,
      0,
      0,
      1,
      3,
      2,
      0,
      2,
      3,
      3,
      3,
      0,
      4
    ],
    "issueDelay": [
      0,
      1,
      3,
      2,
      4,
      4,
      0,
      4,
      2,
      1,
      1,
      0,
      3,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      4,
      0,
      2,
      1,
      3,
      0,
      1,
      0,
      1,
      4,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      4,
      1,
      2,
      0,
      2,
      2,
      2,
      4,
      0,
      2,
      2,
      0,
      0,
      0,
      2
    ],
    "issueQuantity": [
      4,
      4,
      3,
      1,
      4,
      1,
      2,
      0,
      2,
      2,
      4,
      1,
      0,
      2,
      3,
      1,
      2,
      0,
      0,
      2,
      4,
      4,
      2,
      1,
      3,
      1,
      1,
      4,
      2,
      0,
      2,
      4,
      3,
      2,
      2,
      3,
      3,
      1,
      0,
      1,
      4,
      4,
      4,
      1,
      1,
      0,
      3,
      2,
      3,
      4,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000104",
    "status": "inactive",
    "name": "PharmaSphere Equipment",
    "tag": "Medical Supplies, PPE",
    "contactName": "Julie Sanders",
    "industryRating": 4.4,
    "procedures": "[PR-066,PR-057,PR-050,PR-068,PR-056,PR-064,PR-058,PR-041,PR-072,PR-044]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000049,CNTRCT-0000051]",
    "orders": "[ORDR-0-0000032,ORDR-1-0000032,ORDR-2-0000016,ORDR-3-0000058,ORDR-4-0000010,ORDR-5-0000062,ORDR-6-0000033,ORDR-7-0000009,ORDR-8-0000010,ORDR-9-0000033,ORDR-10-0000025,ORDR-11-0000032,ORDR-12-0000031,ORDR-13-0000041,ORDR-14-0000060,ORDR-15-0000040,ORDR-16-0000019,ORDR-17-0000032,ORDR-18-0000044]",
    "spendWeekly": [
      2919,
      3082,
      2930,
      2990,
      2984,
      2905,
      3045,
      2969,
      2992,
      2974,
      3068,
      2922,
      2909,
      2994,
      3083,
      3097,
      2944,
      3099,
      3062,
      3063,
      3029,
      2972,
      3033,
      2976,
      3070,
      3009,
      2987,
      2901,
      2981,
      3066,
      2996,
      2901,
      2928,
      2904,
      3056,
      3040,
      2942,
      2922,
      3099,
      2965,
      3072,
      2939,
      2959,
      3091,
      3034,
      2901,
      2901,
      3033,
      2913,
      2905,
      3003,
      2966
    ],
    "orderRejected": [
      2966,
      5,
      2,
      9,
      12,
      19,
      8,
      6,
      15,
      7,
      4,
      19,
      19,
      18,
      11,
      19,
      19,
      10,
      17,
      0,
      6,
      16,
      7,
      19,
      19,
      9,
      7,
      11,
      18,
      16,
      1,
      13,
      5,
      16,
      19,
      8,
      18,
      6,
      14,
      7,
      1,
      16,
      19,
      0,
      19,
      9,
      0,
      4,
      18,
      8,
      0,
      7,
      18
    ],
    "orderAccepted": [
      7,
      1,
      5,
      0,
      8,
      11,
      11,
      5,
      10,
      0,
      11,
      6,
      7,
      6,
      11,
      11,
      9,
      5,
      0,
      3,
      1,
      8,
      0,
      0,
      3,
      3,
      0,
      6,
      9,
      6,
      4,
      11,
      0,
      2,
      5,
      11,
      8,
      1,
      11,
      8,
      3,
      11,
      11,
      2,
      0,
      7,
      7,
      3,
      8,
      11,
      8,
      8
    ],
    "orderHold": [
      2,
      1,
      2,
      3,
      2,
      1,
      4,
      3,
      4,
      1,
      3,
      2,
      0,
      4,
      3,
      2,
      2,
      2,
      4,
      1,
      2,
      3,
      3,
      1,
      0,
      4,
      1,
      2,
      3,
      1,
      0,
      2,
      2,
      0,
      3,
      4,
      4,
      4,
      2,
      4,
      3,
      0,
      1,
      4,
      4,
      2,
      2,
      4,
      1,
      1,
      4,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      2,
      0,
      0,
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      2,
      2,
      1,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      1,
      0,
      0,
      0,
      3,
      2,
      1,
      1,
      4,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      4,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      0,
      3,
      0,
      3,
      4,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      4,
      1,
      0,
      1,
      4,
      0,
      2,
      0,
      4,
      1,
      2,
      0,
      4
    ],
    "issueDelay": [
      0,
      2,
      1,
      3,
      1,
      0,
      1,
      1,
      2,
      4,
      3,
      0,
      4,
      2,
      1,
      0,
      2,
      0,
      4,
      1,
      2,
      0,
      2,
      0,
      2,
      2,
      4,
      2,
      4,
      1,
      3,
      4,
      2,
      2,
      4,
      2,
      0,
      2,
      1,
      4,
      0,
      0,
      2,
      1,
      4,
      0,
      0,
      4,
      4,
      0,
      3,
      4
    ],
    "issueQuantity": [
      3,
      0,
      3,
      0,
      3,
      2,
      1,
      2,
      1,
      2,
      2,
      3,
      0,
      2,
      3,
      1,
      2,
      4,
      0,
      3,
      4,
      4,
      0,
      0,
      0,
      4,
      3,
      4,
      4,
      2,
      1,
      2,
      1,
      2,
      0,
      3,
      3,
      0,
      0,
      3,
      1,
      3,
      1,
      3,
      4,
      0,
      2,
      3,
      0,
      2,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000105",
    "status": "active",
    "name": "HealthWave Technologies",
    "tag": "Nutrition",
    "contactName": "Darrell Patterson",
    "industryRating": 3.2,
    "procedures": "[PR-055,PR-056,PR-063,PR-066,PR-048,PR-071,PR-068,PR-050,PR-061,PR-070]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000064,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000047,ORDR-1-0000006,ORDR-2-0000044,ORDR-3-0000023,ORDR-4-0000008,ORDR-5-0000041,ORDR-6-0000013,ORDR-7-0000033,ORDR-8-0000037,ORDR-9-0000051,ORDR-10-0000021,ORDR-11-0000023,ORDR-12-0000076,ORDR-13-0000019,ORDR-14-0000063,ORDR-15-0000056,ORDR-16-0000051,ORDR-17-0000040,ORDR-18-0000051]",
    "spendWeekly": [
      2930,
      3082,
      2906,
      2902,
      2903,
      3001,
      2909,
      2954,
      2923,
      2926,
      3076,
      2965,
      2921,
      2990,
      2908,
      2902,
      3040,
      2900,
      2900,
      3037,
      3100,
      3047,
      2900,
      3049,
      3056,
      3096,
      2909,
      3013,
      2901,
      2903,
      3096,
      2981,
      2926,
      3059,
      3090,
      2904,
      2991,
      2945,
      3012,
      3022,
      3029,
      2979,
      3097,
      3053,
      3051,
      2990,
      2901,
      3027,
      2957,
      2905,
      2963,
      3023
    ],
    "orderRejected": [
      3023,
      19,
      19,
      1,
      12,
      19,
      0,
      10,
      2,
      19,
      18,
      0,
      10,
      0,
      6,
      19,
      7,
      0,
      4,
      0,
      6,
      10,
      10,
      10,
      10,
      19,
      3,
      3,
      5,
      5,
      10,
      19,
      12,
      19,
      17,
      10,
      10,
      15,
      19,
      19,
      7,
      11,
      19,
      4,
      0,
      13,
      1,
      0,
      10,
      0,
      7,
      19,
      6
    ],
    "orderAccepted": [
      7,
      11,
      3,
      5,
      0,
      8,
      11,
      10,
      2,
      0,
      8,
      3,
      0,
      6,
      9,
      11,
      7,
      8,
      8,
      2,
      3,
      4,
      11,
      3,
      0,
      2,
      0,
      11,
      11,
      4,
      11,
      6,
      0,
      3,
      4,
      0,
      4,
      4,
      8,
      3,
      0,
      11,
      8,
      11,
      8,
      5,
      0,
      11,
      0,
      5,
      4,
      4
    ],
    "orderHold": [
      3,
      0,
      2,
      0,
      4,
      0,
      4,
      2,
      1,
      0,
      0,
      1,
      1,
      2,
      0,
      4,
      4,
      4,
      4,
      2,
      2,
      3,
      4,
      0,
      0,
      4,
      3,
      1,
      4,
      0,
      0,
      3,
      0,
      0,
      1,
      1,
      2,
      0,
      4,
      0,
      4,
      2,
      3,
      1,
      2,
      4,
      0,
      3,
      0,
      3,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      0,
      2,
      4,
      4,
      0,
      0,
      2,
      0,
      2,
      3,
      1,
      4,
      2,
      0,
      0,
      0,
      3,
      0,
      1,
      0,
      0,
      4,
      2,
      0,
      3,
      4,
      2,
      4,
      4,
      1,
      4,
      0,
      0,
      1,
      0,
      3,
      1,
      4,
      4,
      1,
      3,
      2,
      3,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      4
    ],
    "issueDelay": [
      1,
      3,
      4,
      0,
      2,
      4,
      4,
      2,
      4,
      2,
      0,
      3,
      4,
      4,
      3,
      2,
      4,
      0,
      1,
      2,
      1,
      1,
      0,
      0,
      3,
      0,
      0,
      3,
      3,
      3,
      3,
      1,
      3,
      3,
      0,
      4,
      0,
      3,
      4,
      0,
      4,
      0,
      1,
      3,
      3,
      0,
      2,
      0,
      4,
      3,
      4,
      4
    ],
    "issueQuantity": [
      2,
      0,
      0,
      0,
      2,
      2,
      2,
      0,
      1,
      2,
      4,
      3,
      0,
      3,
      4,
      1,
      2,
      3,
      0,
      4,
      0,
      0,
      2,
      2,
      1,
      2,
      1,
      4,
      3,
      0,
      0,
      4,
      4,
      1,
      1,
      3,
      4,
      0,
      1,
      4,
      4,
      0,
      4,
      3,
      0,
      0,
      4,
      0,
      0,
      3,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000106",
    "status": "active",
    "name": "HealthStream Supplies",
    "tag": "PPE, Imaging, Nutrition",
    "contactName": "Helen Ponce",
    "industryRating": 2.1,
    "procedures": "[PR-062,PR-065,PR-029,PR-040,PR-029,PR-051,PR-064,PR-063,PR-070,PR-070]",
    "contracts": "[CNTRCT-0000040,CNTRCT-0000040,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000011,ORDR-1-0000008,ORDR-2-0000042,ORDR-3-0000023,ORDR-4-0000058,ORDR-5-0000007,ORDR-6-0000009,ORDR-7-0000022,ORDR-8-0000018,ORDR-9-0000017,ORDR-10-0000035,ORDR-11-0000029,ORDR-12-0000021,ORDR-13-0000037,ORDR-14-0000045,ORDR-15-0000037,ORDR-16-0000055,ORDR-17-0000025,ORDR-18-0000043]",
    "spendWeekly": [
      2991,
      3017,
      3044,
      2947,
      3047,
      2932,
      2963,
      2933,
      3018,
      3032,
      3100,
      2904,
      2914,
      3046,
      2999,
      2955,
      2997,
      3074,
      2900,
      3028,
      2930,
      2951,
      2993,
      2951,
      2906,
      3017,
      2909,
      3050,
      3070,
      2917,
      2980,
      3071,
      2919,
      3046,
      2998,
      3052,
      2966,
      3042,
      2906,
      2921,
      3054,
      3056,
      3042,
      3091,
      2912,
      2937,
      2981,
      3049,
      2925,
      3059,
      2973,
      2954
    ],
    "orderRejected": [
      2954,
      5,
      11,
      0,
      18,
      15,
      10,
      3,
      16,
      13,
      10,
      8,
      16,
      9,
      9,
      9,
      4,
      17,
      0,
      8,
      16,
      18,
      11,
      8,
      3,
      13,
      0,
      11,
      9,
      18,
      13,
      13,
      7,
      2,
      8,
      11,
      3,
      16,
      12,
      5,
      1,
      8,
      16,
      4,
      1,
      4,
      10,
      4,
      2,
      6,
      8,
      11,
      17
    ],
    "orderAccepted": [
      0,
      11,
      9,
      11,
      10,
      6,
      2,
      6,
      1,
      1,
      7,
      2,
      1,
      11,
      7,
      11,
      3,
      6,
      5,
      4,
      4,
      5,
      6,
      5,
      4,
      11,
      2,
      7,
      4,
      8,
      11,
      7,
      1,
      5,
      10,
      4,
      6,
      2,
      11,
      2,
      8,
      0,
      9,
      4,
      2,
      6,
      10,
      8,
      2,
      11,
      1,
      11
    ],
    "orderHold": [
      2,
      4,
      2,
      3,
      1,
      2,
      2,
      3,
      3,
      1,
      2,
      0,
      2,
      2,
      0,
      3,
      3,
      2,
      1,
      3,
      3,
      0,
      2,
      3,
      0,
      3,
      3,
      2,
      4,
      4,
      2,
      2,
      2,
      1,
      1,
      4,
      4,
      3,
      4,
      2,
      0,
      4,
      4,
      2,
      2,
      4,
      1,
      3,
      3,
      1,
      2,
      1
    ],
    "orderBackordered": [
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      0,
      3,
      2,
      3,
      0,
      1,
      0,
      3,
      4,
      1,
      0,
      1,
      1,
      3,
      0,
      2,
      4,
      2,
      2,
      4,
      4,
      2,
      1,
      2,
      2,
      4,
      3,
      1,
      0,
      2,
      0,
      2,
      2,
      1,
      2,
      2,
      2,
      1,
      4,
      2,
      1,
      1,
      1,
      2,
      4,
      2,
      2,
      2,
      4
    ],
    "issueDelay": [
      2,
      2,
      4,
      0,
      4,
      4,
      3,
      2,
      1,
      4,
      3,
      1,
      3,
      0,
      4,
      1,
      1,
      0,
      0,
      3,
      2,
      1,
      1,
      1,
      3,
      1,
      2,
      3,
      2,
      2,
      4,
      2,
      1,
      2,
      3,
      1,
      2,
      2,
      4,
      4,
      2,
      1,
      3,
      3,
      1,
      3,
      1,
      1,
      4,
      3,
      0,
      0
    ],
    "issueQuantity": [
      4,
      0,
      4,
      0,
      2,
      4,
      3,
      0,
      2,
      2,
      2,
      0,
      1,
      3,
      1,
      4,
      2,
      0,
      0,
      4,
      3,
      2,
      0,
      4,
      1,
      2,
      0,
      0,
      3,
      3,
      2,
      0,
      4,
      1,
      0,
      4,
      3,
      0,
      0,
      4,
      3,
      4,
      3,
      0,
      4,
      4,
      2,
      4,
      0,
      0,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000107",
    "status": "inactive",
    "name": "LifeLine Supplies",
    "tag": "Imaging, PPE, IT Services",
    "contactName": "Ralph Valdez",
    "industryRating": 4.8,
    "procedures": "[PR-052,PR-032,PR-048,PR-067,PR-029,PR-071,PR-060,PR-061,PR-073,PR-053]",
    "contracts": "[CNTRCT-0000012,CNTRCT-0000059,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000037,ORDR-1-0000031,ORDR-2-0000020,ORDR-3-0000053,ORDR-4-0000061,ORDR-5-0000047,ORDR-6-0000026,ORDR-7-0000032,ORDR-8-0000029,ORDR-9-0000008,ORDR-10-0000036,ORDR-11-0000024,ORDR-12-0000075,ORDR-13-0000060,ORDR-14-0000026,ORDR-15-0000038,ORDR-16-0000061,ORDR-17-0000039,ORDR-18-0000018]",
    "spendWeekly": [
      3053,
      2977,
      2953,
      2921,
      3008,
      3006,
      3075,
      3098,
      3022,
      2902,
      3006,
      2944,
      3100,
      2992,
      3091,
      3028,
      3068,
      3099,
      2900,
      2977,
      2989,
      2946,
      3013,
      3097,
      3046,
      3096,
      2958,
      3014,
      2921,
      3097,
      2940,
      2931,
      2931,
      3021,
      3005,
      3008,
      3019,
      3014,
      2906,
      3051,
      3040,
      2946,
      3020,
      3062,
      2942,
      2901,
      2957,
      2913,
      3100,
      2988,
      2953,
      3016
    ],
    "orderRejected": [
      3016,
      19,
      9,
      0,
      8,
      12,
      19,
      12,
      18,
      17,
      0,
      7,
      13,
      16,
      4,
      0,
      19,
      8,
      0,
      12,
      16,
      16,
      15,
      9,
      0,
      13,
      0,
      2,
      17,
      11,
      9,
      6,
      0,
      8,
      19,
      19,
      8,
      6,
      12,
      7,
      18,
      19,
      12,
      0,
      2,
      6,
      0,
      18,
      17,
      5,
      19,
      0,
      7
    ],
    "orderAccepted": [
      4,
      11,
      4,
      7,
      4,
      6,
      6,
      0,
      3,
      0,
      6,
      5,
      11,
      8,
      4,
      6,
      0,
      4,
      9,
      6,
      0,
      0,
      6,
      6,
      11,
      8,
      0,
      5,
      0,
      2,
      0,
      4,
      0,
      2,
      5,
      9,
      9,
      11,
      11,
      3,
      8,
      8,
      10,
      1,
      7,
      9,
      8,
      11,
      0,
      9,
      6,
      3
    ],
    "orderHold": [
      4,
      1,
      2,
      3,
      4,
      3,
      1,
      2,
      4,
      4,
      0,
      1,
      2,
      4,
      0,
      1,
      4,
      0,
      0,
      3,
      4,
      1,
      1,
      4,
      0,
      4,
      1,
      4,
      0,
      0,
      1,
      4,
      2,
      1,
      0,
      2,
      4,
      2,
      1,
      1,
      2,
      0,
      0,
      4,
      3,
      3,
      1,
      4,
      1,
      1,
      2,
      3
    ],
    "orderBackordered": [
      3,
      0,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      0,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      0,
      2,
      2,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      1,
      0,
      4,
      3,
      0,
      4,
      0,
      2,
      1,
      3,
      1,
      1,
      1,
      0,
      0,
      0,
      2,
      3,
      2,
      4,
      4,
      0,
      3,
      4,
      3,
      1,
      4,
      0,
      4,
      0,
      1,
      0,
      3,
      1,
      0,
      2,
      1,
      2,
      4,
      4,
      0,
      1,
      2,
      2,
      4,
      2,
      2,
      0,
      1,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      2,
      3,
      4,
      0,
      3,
      4,
      2,
      2,
      2,
      2,
      1,
      4,
      2,
      2,
      4,
      1,
      1,
      2,
      2,
      0,
      1,
      2,
      4,
      0,
      1,
      4,
      0,
      4,
      4,
      4,
      2,
      4,
      1,
      4,
      4,
      0,
      4,
      1,
      4,
      2,
      0,
      0,
      0,
      4,
      1,
      4,
      0,
      3,
      0,
      3,
      3
    ],
    "issueQuantity": [
      1,
      0,
      3,
      0,
      2,
      3,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      3,
      4,
      4,
      0,
      0,
      3,
      0,
      3,
      1,
      2,
      2,
      0,
      1,
      1,
      4,
      1,
      4,
      0,
      4,
      2,
      1,
      4,
      4,
      0,
      1,
      4,
      4,
      3,
      3,
      2,
      1,
      3,
      1,
      4,
      1,
      4,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000108",
    "status": "inactive",
    "name": "VitalSigns Supplies",
    "tag": "Laboratory Supplies, Pharmaceuticals",
    "contactName": "Melissa Torres",
    "industryRating": 3.1,
    "procedures": "[PR-024,PR-048,PR-045,PR-044,PR-042,PR-051,PR-043,PR-064,PR-038,PR-072]",
    "contracts": "[CNTRCT-0000035,CNTRCT-0000027,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000024,ORDR-1-0000065,ORDR-2-0000009,ORDR-3-0000045,ORDR-4-0000061,ORDR-5-0000043,ORDR-6-0000005,ORDR-7-0000063,ORDR-8-0000021,ORDR-9-0000065,ORDR-10-0000035,ORDR-11-0000021,ORDR-12-0000069,ORDR-13-0000035,ORDR-14-0000064,ORDR-15-0000037,ORDR-16-0000059,ORDR-17-0000080,ORDR-18-0000051]",
    "spendWeekly": [
      2914,
      3082,
      2970,
      3049,
      3012,
      2985,
      3100,
      3002,
      3049,
      3031,
      2973,
      2984,
      2909,
      3094,
      3014,
      2901,
      3017,
      3074,
      2943,
      3026,
      3056,
      2977,
      3022,
      2928,
      3052,
      3009,
      3034,
      2957,
      2901,
      3050,
      2997,
      3030,
      3079,
      2984,
      2976,
      2929,
      2954,
      3097,
      2906,
      3062,
      3000,
      2983,
      3097,
      3026,
      3027,
      2964,
      3099,
      2926,
      2988,
      2910,
      3018,
      3097
    ],
    "orderRejected": [
      3097,
      15,
      6,
      2,
      16,
      16,
      4,
      12,
      1,
      14,
      4,
      9,
      19,
      7,
      13,
      16,
      17,
      5,
      1,
      6,
      16,
      3,
      7,
      19,
      19,
      14,
      4,
      4,
      19,
      19,
      9,
      7,
      7,
      11,
      0,
      12,
      0,
      2,
      4,
      19,
      8,
      9,
      19,
      5,
      8,
      2,
      19,
      16,
      3,
      19,
      0,
      4,
      9
    ],
    "orderAccepted": [
      10,
      3,
      2,
      0,
      11,
      11,
      10,
      11,
      11,
      5,
      6,
      11,
      2,
      7,
      11,
      11,
      0,
      6,
      1,
      8,
      11,
      3,
      11,
      7,
      6,
      4,
      7,
      8,
      6,
      3,
      9,
      4,
      0,
      0,
      4,
      11,
      6,
      10,
      11,
      6,
      0,
      10,
      11,
      5,
      10,
      6,
      9,
      7,
      0,
      7,
      9,
      5
    ],
    "orderHold": [
      2,
      2,
      3,
      1,
      2,
      0,
      3,
      2,
      1,
      1,
      3,
      0,
      1,
      3,
      0,
      2,
      4,
      0,
      3,
      2,
      0,
      4,
      1,
      2,
      0,
      1,
      4,
      2,
      4,
      1,
      2,
      3,
      1,
      3,
      2,
      2,
      3,
      4,
      1,
      1,
      1,
      0,
      2,
      4,
      2,
      4,
      0,
      3,
      1,
      0,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      0,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      1,
      1,
      4,
      3,
      0,
      3,
      0,
      4,
      4,
      0,
      4,
      3,
      0,
      2,
      0,
      3,
      4,
      0,
      2,
      4,
      0,
      2,
      1,
      1,
      0,
      4,
      0,
      0,
      4,
      3,
      0,
      2,
      0,
      1,
      1,
      4,
      3,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      4,
      1,
      3,
      0,
      4,
      2,
      2,
      3
    ],
    "issueDelay": [
      1,
      1,
      4,
      0,
      0,
      0,
      3,
      1,
      0,
      0,
      3,
      0,
      1,
      4,
      0,
      2,
      1,
      0,
      3,
      2,
      2,
      0,
      0,
      2,
      1,
      3,
      3,
      2,
      4,
      3,
      3,
      2,
      2,
      4,
      4,
      1,
      0,
      4,
      3,
      3,
      1,
      0,
      0,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      0,
      2
    ],
    "issueQuantity": [
      3,
      0,
      4,
      0,
      4,
      3,
      2,
      3,
      0,
      1,
      4,
      2,
      0,
      0,
      0,
      2,
      3,
      4,
      0,
      3,
      0,
      0,
      0,
      3,
      2,
      0,
      0,
      1,
      4,
      4,
      3,
      2,
      0,
      4,
      1,
      0,
      4,
      0,
      3,
      2,
      1,
      1,
      1,
      2,
      3,
      0,
      3,
      4,
      0,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000109",
    "status": "inactive",
    "name": "LifeLine Supplies",
    "tag": "Laboratory Supplies, Pharmaceuticals",
    "contactName": "Jennifer Carey",
    "industryRating": 2.4,
    "procedures": "[PR-066,PR-067,PR-047,PR-062,PR-047,PR-070,PR-068,PR-061,PR-071,PR-059]",
    "contracts": "[CNTRCT-0000040,CNTRCT-0000060,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000009,ORDR-1-0000024,ORDR-2-0000039,ORDR-3-0000012,ORDR-4-0000057,ORDR-5-0000048,ORDR-6-0000018,ORDR-7-0000032,ORDR-8-0000061,ORDR-9-0000045,ORDR-10-0000045,ORDR-11-0000014,ORDR-12-0000065,ORDR-13-0000012,ORDR-14-0000017,ORDR-15-0000025,ORDR-16-0000035,ORDR-17-0000036,ORDR-18-0000082]",
    "spendWeekly": [
      2996,
      3082,
      2962,
      2902,
      2915,
      2905,
      3040,
      3098,
      2902,
      2911,
      2964,
      2904,
      2909,
      3094,
      3060,
      2901,
      3098,
      3099,
      2936,
      3098,
      3082,
      3013,
      2900,
      2992,
      2998,
      3096,
      3075,
      3041,
      2901,
      3010,
      2949,
      3012,
      3085,
      3047,
      3034,
      2904,
      3100,
      3021,
      2976,
      2952,
      2941,
      2933,
      2945,
      3055,
      2902,
      2901,
      3099,
      2900,
      3068,
      2905,
      2931,
      2956
    ],
    "orderRejected": [
      2956,
      19,
      19,
      1,
      17,
      11,
      8,
      19,
      13,
      19,
      6,
      19,
      9,
      4,
      17,
      11,
      10,
      0,
      0,
      3,
      0,
      0,
      3,
      19,
      19,
      7,
      12,
      0,
      17,
      19,
      5,
      14,
      19,
      19,
      10,
      13,
      7,
      19,
      13,
      12,
      8,
      19,
      5,
      8,
      19,
      11,
      0,
      13,
      19,
      0,
      13,
      19,
      7
    ],
    "orderAccepted": [
      10,
      11,
      0,
      4,
      2,
      4,
      2,
      11,
      2,
      0,
      4,
      4,
      3,
      10,
      11,
      2,
      0,
      1,
      2,
      11,
      11,
      3,
      7,
      11,
      10,
      7,
      0,
      9,
      2,
      4,
      11,
      4,
      0,
      9,
      11,
      11,
      10,
      0,
      11,
      5,
      2,
      4,
      7,
      11,
      3,
      11,
      0,
      11,
      5,
      6,
      1,
      0
    ],
    "orderHold": [
      4,
      0,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      3,
      3,
      2,
      0,
      3,
      0,
      0,
      3,
      1,
      4,
      3,
      2,
      2,
      4,
      4,
      2,
      2,
      0,
      0,
      1,
      0,
      4,
      4,
      4,
      3,
      0,
      2,
      0,
      0,
      4,
      2,
      0,
      0,
      0,
      4,
      1,
      4,
      1,
      4,
      2,
      0,
      1,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      2,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      0,
      0,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      3,
      4,
      2,
      3,
      2,
      4,
      4,
      4,
      4,
      1,
      3,
      2,
      1,
      3,
      4,
      2,
      1,
      1,
      0,
      2,
      3,
      2,
      0,
      4,
      4,
      0,
      4,
      3,
      0,
      4,
      2,
      0,
      0,
      1,
      2,
      0,
      1,
      2,
      0,
      2,
      0,
      4,
      0,
      3,
      0,
      0,
      4,
      0,
      2,
      4
    ],
    "issueDelay": [
      2,
      0,
      4,
      4,
      1,
      2,
      4,
      0,
      3,
      3,
      0,
      2,
      3,
      4,
      0,
      2,
      2,
      0,
      2,
      4,
      0,
      3,
      0,
      3,
      0,
      0,
      1,
      3,
      4,
      3,
      3,
      1,
      4,
      0,
      4,
      2,
      2,
      3,
      4,
      2,
      0,
      3,
      4,
      1,
      0,
      1,
      0,
      1,
      0,
      2,
      0,
      3
    ],
    "issueQuantity": [
      3,
      0,
      0,
      0,
      3,
      1,
      0,
      0,
      0,
      2,
      1,
      3,
      0,
      1,
      4,
      0,
      3,
      1,
      4,
      4,
      0,
      4,
      1,
      1,
      1,
      4,
      3,
      0,
      4,
      1,
      4,
      3,
      0,
      4,
      1,
      2,
      4,
      0,
      1,
      4,
      4,
      2,
      4,
      0,
      4,
      1,
      4,
      0,
      3,
      4,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000110",
    "status": "active",
    "name": "MediSupply Chain",
    "tag": "Imaging",
    "contactName": "Bryan Lang",
    "industryRating": 4.4,
    "procedures": "[PR-051,PR-007,PR-038,PR-065,PR-026,PR-065,PR-053,PR-046,PR-063,PR-075]",
    "contracts": "[CNTRCT-0000032,CNTRCT-0000042,CNTRCT-0000033]",
    "orders": "[ORDR-0-0000063,ORDR-1-0000006,ORDR-2-0000052,ORDR-3-0000017,ORDR-4-0000022,ORDR-5-0000009,ORDR-6-0000014,ORDR-7-0000055,ORDR-8-0000050,ORDR-9-0000039,ORDR-10-0000066,ORDR-11-0000023,ORDR-12-0000049,ORDR-13-0000063,ORDR-14-0000047,ORDR-15-0000070,ORDR-16-0000060,ORDR-17-0000043,ORDR-18-0000077]",
    "spendWeekly": [
      2920,
      3027,
      3027,
      3086,
      2914,
      3044,
      2944,
      3098,
      3037,
      3007,
      3086,
      3095,
      3008,
      3094,
      3091,
      3005,
      2973,
      3099,
      2900,
      3098,
      3070,
      3062,
      3097,
      3080,
      3031,
      3096,
      3049,
      3082,
      2980,
      3097,
      3096,
      2996,
      2974,
      3028,
      3026,
      2932,
      3019,
      3097,
      3048,
      3025,
      3016,
      3003,
      3087,
      3023,
      3100,
      2901,
      3031,
      2977,
      2973,
      2905,
      2935,
      3000
    ],
    "orderRejected": [
      3000,
      19,
      18,
      19,
      0,
      18,
      15,
      12,
      10,
      17,
      0,
      10,
      8,
      18,
      12,
      6,
      18,
      9,
      10,
      15,
      19,
      17,
      17,
      11,
      19,
      19,
      0,
      2,
      13,
      4,
      11,
      12,
      15,
      16,
      10,
      1,
      3,
      17,
      17,
      19,
      19,
      17,
      3,
      6,
      0,
      9,
      1,
      16,
      14,
      10,
      17,
      15,
      19
    ],
    "orderAccepted": [
      9,
      8,
      1,
      11,
      6,
      6,
      2,
      5,
      1,
      6,
      4,
      5,
      9,
      6,
      7,
      11,
      4,
      5,
      1,
      6,
      6,
      0,
      4,
      0,
      7,
      7,
      2,
      11,
      1,
      8,
      3,
      7,
      0,
      2,
      2,
      10,
      7,
      10,
      11,
      9,
      2,
      9,
      5,
      5,
      5,
      9,
      7,
      11,
      0,
      10,
      6,
      3
    ],
    "orderHold": [
      0,
      3,
      1,
      0,
      0,
      0,
      3,
      4,
      2,
      4,
      4,
      0,
      0,
      4,
      0,
      2,
      3,
      0,
      4,
      3,
      1,
      4,
      0,
      2,
      0,
      0,
      4,
      1,
      3,
      1,
      2,
      4,
      0,
      1,
      1,
      4,
      4,
      1,
      3,
      4,
      2,
      0,
      4,
      3,
      3,
      2,
      1,
      3,
      1,
      0,
      2,
      1
    ],
    "orderBackordered": [
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      0,
      0,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      0,
      0,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      1,
      4,
      3,
      2,
      0,
      4,
      2,
      2,
      1,
      0,
      1,
      3,
      4,
      0,
      1,
      0,
      3,
      0,
      4,
      2,
      4,
      3,
      3,
      2,
      4,
      0,
      1,
      2,
      3,
      2,
      2,
      3,
      3,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      3,
      4,
      0,
      1,
      3,
      2,
      0,
      4,
      3,
      1,
      0
    ],
    "issueDelay": [
      3,
      3,
      4,
      3,
      4,
      4,
      0,
      3,
      3,
      1,
      0,
      1,
      3,
      1,
      3,
      0,
      2,
      2,
      2,
      3,
      4,
      1,
      0,
      4,
      4,
      0,
      4,
      2,
      2,
      3,
      1,
      2,
      4,
      3,
      4,
      0,
      0,
      4,
      2,
      0,
      4,
      2,
      1,
      3,
      4,
      3,
      4,
      1,
      3,
      0,
      4,
      0
    ],
    "issueQuantity": [
      0,
      1,
      4,
      2,
      4,
      4,
      2,
      0,
      3,
      2,
      2,
      1,
      0,
      3,
      2,
      4,
      2,
      2,
      0,
      4,
      3,
      3,
      2,
      4,
      4,
      3,
      3,
      3,
      1,
      0,
      4,
      3,
      2,
      2,
      2,
      4,
      0,
      0,
      0,
      4,
      4,
      2,
      3,
      0,
      4,
      4,
      2,
      4,
      1,
      3,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000111",
    "status": "active",
    "name": "WellnessWare Group",
    "tag": "Facilities Management, Pharmaceuticals, Surgical Instruments",
    "contactName": "Steven Wallace",
    "industryRating": 3.1,
    "procedures": "[PR-039,PR-040,PR-062,PR-061,PR-069,PR-059,PR-059,PR-050,PR-071,PR-067]",
    "contracts": "[CNTRCT-0000065,CNTRCT-0000061,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000014,ORDR-2-0000016,ORDR-3-0000044,ORDR-4-0000039,ORDR-5-0000004,ORDR-6-0000015,ORDR-7-0000027,ORDR-8-0000059,ORDR-9-0000068,ORDR-10-0000066,ORDR-11-0000038,ORDR-12-0000058,ORDR-13-0000024,ORDR-14-0000016,ORDR-15-0000072,ORDR-16-0000073,ORDR-17-0000051,ORDR-18-0000028]",
    "spendWeekly": [
      2937,
      3082,
      2973,
      2927,
      2927,
      2905,
      3046,
      3093,
      2942,
      3000,
      3034,
      3007,
      2973,
      2928,
      3062,
      2901,
      2966,
      3017,
      3057,
      3038,
      3055,
      3097,
      3077,
      3037,
      3071,
      3096,
      2982,
      3052,
      3023,
      2922,
      2945,
      2982,
      3002,
      3019,
      3095,
      2972,
      3100,
      3082,
      2920,
      3043,
      3037,
      3022,
      3024,
      3085,
      2902,
      2901,
      2942,
      3095,
      2960,
      2905,
      2989,
      2944
    ],
    "orderRejected": [
      2944,
      17,
      2,
      11,
      12,
      16,
      16,
      9,
      0,
      6,
      17,
      0,
      7,
      7,
      13,
      11,
      9,
      17,
      2,
      11,
      15,
      6,
      18,
      14,
      2,
      12,
      2,
      6,
      14,
      8,
      8,
      18,
      0,
      2,
      17,
      8,
      15,
      16,
      17,
      0,
      14,
      13,
      12,
      8,
      0,
      9,
      2,
      9,
      7,
      0,
      10,
      11,
      7
    ],
    "orderAccepted": [
      5,
      7,
      3,
      9,
      8,
      10,
      10,
      4,
      7,
      11,
      8,
      0,
      7,
      6,
      6,
      11,
      6,
      6,
      4,
      8,
      9,
      8,
      10,
      2,
      8,
      11,
      2,
      11,
      10,
      5,
      4,
      4,
      2,
      0,
      10,
      11,
      6,
      2,
      10,
      5,
      0,
      8,
      10,
      11,
      0,
      0,
      7,
      8,
      0,
      7,
      3,
      2
    ],
    "orderHold": [
      2,
      1,
      4,
      2,
      1,
      1,
      4,
      4,
      4,
      0,
      0,
      0,
      1,
      3,
      1,
      4,
      4,
      3,
      3,
      2,
      2,
      3,
      1,
      4,
      1,
      3,
      4,
      1,
      4,
      0,
      0,
      4,
      1,
      4,
      1,
      3,
      0,
      3,
      3,
      0,
      3,
      1,
      4,
      1,
      2,
      4,
      1,
      4,
      2,
      2,
      4,
      2
    ],
    "orderBackordered": [
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      1,
      0,
      2,
      0,
      0,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      3,
      4,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      3,
      4,
      4,
      1,
      0,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      2,
      2,
      3,
      4,
      2,
      1,
      2,
      0,
      1,
      1,
      4,
      3,
      0,
      1,
      2,
      2,
      0,
      4,
      4,
      3,
      0,
      0,
      1,
      3,
      4,
      1,
      4
    ],
    "issueDelay": [
      2,
      3,
      4,
      2,
      4,
      2,
      2,
      2,
      0,
      0,
      2,
      0,
      4,
      0,
      0,
      4,
      1,
      2,
      1,
      3,
      2,
      1,
      3,
      2,
      3,
      2,
      4,
      1,
      1,
      1,
      3,
      1,
      1,
      3,
      1,
      0,
      3,
      3,
      3,
      1,
      1,
      4,
      4,
      2,
      3,
      2,
      2,
      0,
      1,
      3,
      4,
      3
    ],
    "issueQuantity": [
      3,
      0,
      2,
      1,
      2,
      2,
      4,
      1,
      2,
      2,
      1,
      2,
      1,
      3,
      3,
      4,
      3,
      0,
      0,
      4,
      2,
      4,
      0,
      2,
      2,
      0,
      1,
      3,
      4,
      0,
      2,
      4,
      4,
      1,
      2,
      1,
      4,
      3,
      1,
      2,
      3,
      2,
      0,
      3,
      1,
      2,
      0,
      1,
      3,
      2,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000112",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Surgical Instruments, Pharmaceuticals",
    "contactName": "Douglas Ruiz",
    "industryRating": 3.1,
    "procedures": "[PR-066,PR-049,PR-060,PR-016,PR-056,PR-049,PR-067,PR-070,PR-037,PR-069]",
    "contracts": "[CNTRCT-0000045,CNTRCT-0000034,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000006,ORDR-2-0000035,ORDR-3-0000022,ORDR-4-0000043,ORDR-5-0000059,ORDR-6-0000005,ORDR-7-0000008,ORDR-8-0000055,ORDR-9-0000029,ORDR-10-0000024,ORDR-11-0000035,ORDR-12-0000053,ORDR-13-0000024,ORDR-14-0000078,ORDR-15-0000044,ORDR-16-0000018,ORDR-17-0000019,ORDR-18-0000082]",
    "spendWeekly": [
      2954,
      2996,
      2956,
      2946,
      2951,
      2905,
      3076,
      2920,
      2987,
      3068,
      2979,
      2966,
      3013,
      3078,
      3027,
      2917,
      3098,
      3014,
      2959,
      3083,
      3026,
      3095,
      2942,
      3033,
      3012,
      2923,
      3070,
      2951,
      2901,
      3089,
      2954,
      2987,
      3099,
      2923,
      3040,
      2911,
      3100,
      3068,
      2962,
      2984,
      3033,
      2938,
      3086,
      2913,
      2917,
      2901,
      2992,
      3008,
      2957,
      2950,
      3062,
      3033
    ],
    "orderRejected": [
      3033,
      17,
      15,
      4,
      5,
      6,
      2,
      9,
      6,
      14,
      5,
      18,
      15,
      12,
      17,
      8,
      6,
      5,
      13,
      4,
      4,
      13,
      9,
      19,
      9,
      12,
      4,
      1,
      19,
      15,
      11,
      9,
      8,
      19,
      18,
      12,
      8,
      19,
      11,
      0,
      8,
      19,
      12,
      0,
      19,
      9,
      11,
      13,
      3,
      13,
      6,
      9,
      7
    ],
    "orderAccepted": [
      10,
      5,
      4,
      7,
      4,
      2,
      8,
      11,
      0,
      1,
      9,
      0,
      1,
      3,
      11,
      9,
      8,
      8,
      11,
      9,
      4,
      2,
      11,
      4,
      4,
      4,
      0,
      4,
      0,
      8,
      11,
      2,
      0,
      3,
      1,
      7,
      8,
      11,
      3,
      11,
      7,
      11,
      6,
      6,
      7,
      5,
      10,
      5,
      4,
      4,
      5,
      1
    ],
    "orderHold": [
      3,
      0,
      1,
      2,
      4,
      2,
      4,
      3,
      4,
      1,
      4,
      2,
      0,
      4,
      2,
      3,
      0,
      3,
      4,
      0,
      4,
      4,
      0,
      4,
      4,
      0,
      0,
      2,
      0,
      2,
      0,
      4,
      1,
      4,
      2,
      2,
      2,
      2,
      2,
      4,
      0,
      4,
      3,
      2,
      0,
      1,
      0,
      3,
      0,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      0,
      0,
      2,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      0,
      2,
      2,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      4,
      4,
      3,
      3,
      3,
      4,
      3,
      0,
      4,
      2,
      4,
      4,
      2,
      1,
      2,
      4,
      3,
      2,
      0,
      0,
      0,
      2,
      0,
      0,
      2,
      4,
      0,
      2,
      2,
      1,
      4,
      4,
      4,
      2,
      2,
      0,
      0,
      2,
      1,
      2,
      0,
      3,
      3,
      0,
      4,
      1,
      0,
      4,
      4,
      4,
      4
    ],
    "issueDelay": [
      0,
      0,
      1,
      4,
      0,
      3,
      2,
      0,
      0,
      1,
      2,
      0,
      2,
      4,
      4,
      1,
      2,
      0,
      2,
      0,
      4,
      0,
      2,
      2,
      4,
      2,
      2,
      1,
      3,
      0,
      4,
      3,
      0,
      2,
      4,
      4,
      1,
      3,
      4,
      3,
      4,
      0,
      2,
      3,
      2,
      3,
      0,
      0,
      4,
      3,
      2,
      3
    ],
    "issueQuantity": [
      3,
      3,
      4,
      0,
      4,
      3,
      1,
      1,
      3,
      0,
      2,
      1,
      3,
      3,
      3,
      0,
      1,
      4,
      0,
      4,
      3,
      2,
      0,
      3,
      3,
      0,
      4,
      2,
      1,
      2,
      4,
      3,
      0,
      0,
      2,
      4,
      4,
      1,
      1,
      4,
      0,
      2,
      4,
      2,
      4,
      0,
      3,
      0,
      4,
      0,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000113",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "PPE",
    "contactName": "Justin Molina",
    "industryRating": 2.1,
    "procedures": "[PR-051,PR-057,PR-060,PR-047,PR-059,PR-060,PR-072,PR-071,PR-071,PR-074]",
    "contracts": "[CNTRCT-0000013,CNTRCT-0000052,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000065,ORDR-1-0000010,ORDR-2-0000048,ORDR-3-0000045,ORDR-4-0000026,ORDR-5-0000039,ORDR-6-0000034,ORDR-7-0000022,ORDR-8-0000028,ORDR-9-0000029,ORDR-10-0000035,ORDR-11-0000010,ORDR-12-0000030,ORDR-13-0000069,ORDR-14-0000038,ORDR-15-0000061,ORDR-16-0000045,ORDR-17-0000043,ORDR-18-0000030]",
    "spendWeekly": [
      2996,
      3038,
      2906,
      2999,
      3016,
      3013,
      2996,
      3044,
      2936,
      3099,
      3058,
      3087,
      2909,
      3094,
      2971,
      3028,
      2956,
      3019,
      3072,
      3098,
      3055,
      3097,
      2957,
      3014,
      2996,
      3049,
      3095,
      2967,
      3096,
      2949,
      2987,
      2958,
      3094,
      3012,
      3003,
      2904,
      2969,
      2984,
      3082,
      3074,
      3021,
      2984,
      3065,
      2981,
      2988,
      2901,
      3025,
      2905,
      3018,
      2905,
      3075,
      3013
    ],
    "orderRejected": [
      3013,
      19,
      4,
      15,
      0,
      19,
      6,
      10,
      3,
      8,
      19,
      5,
      13,
      4,
      17,
      8,
      11,
      0,
      10,
      9,
      1,
      14,
      18,
      15,
      19,
      0,
      5,
      0,
      19,
      16,
      8,
      15,
      14,
      9,
      13,
      15,
      0,
      19,
      15,
      3,
      6,
      19,
      4,
      8,
      2,
      6,
      14,
      18,
      18,
      0,
      8,
      16,
      19
    ],
    "orderAccepted": [
      8,
      0,
      3,
      6,
      4,
      11,
      3,
      3,
      5,
      8,
      5,
      5,
      11,
      9,
      8,
      8,
      10,
      2,
      0,
      11,
      11,
      0,
      4,
      8,
      3,
      7,
      1,
      8,
      7,
      0,
      11,
      6,
      0,
      0,
      0,
      2,
      6,
      6,
      9,
      0,
      0,
      11,
      11,
      11,
      1,
      9,
      0,
      11,
      7,
      5,
      1,
      3
    ],
    "orderHold": [
      2,
      3,
      3,
      2,
      1,
      2,
      4,
      3,
      0,
      2,
      1,
      0,
      2,
      3,
      2,
      1,
      0,
      0,
      3,
      3,
      3,
      1,
      4,
      3,
      0,
      1,
      4,
      1,
      4,
      0,
      4,
      4,
      3,
      4,
      0,
      2,
      3,
      0,
      3,
      1,
      1,
      0,
      0,
      2,
      4,
      4,
      0,
      3,
      1,
      0,
      4,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      2,
      2,
      4,
      3,
      3,
      0,
      2,
      3,
      3,
      2,
      4,
      4,
      4,
      2,
      0,
      3,
      0,
      3,
      2,
      3,
      2,
      0,
      4,
      4,
      0,
      4,
      2,
      4,
      4,
      3,
      0,
      3,
      4,
      4,
      0,
      2,
      4,
      0,
      4,
      2,
      3,
      0,
      4,
      2,
      3,
      4,
      0,
      4,
      2,
      0,
      4,
      4
    ],
    "issueDelay": [
      0,
      4,
      4,
      1,
      3,
      2,
      2,
      1,
      2,
      0,
      0,
      3,
      4,
      3,
      0,
      3,
      1,
      0,
      2,
      2,
      0,
      0,
      1,
      2,
      0,
      0,
      3,
      3,
      3,
      1,
      4,
      2,
      2,
      0,
      4,
      1,
      1,
      2,
      4,
      0,
      1,
      3,
      1,
      0,
      4,
      0,
      4,
      4,
      3,
      2,
      1,
      4
    ],
    "issueQuantity": [
      2,
      2,
      2,
      0,
      0,
      1,
      0,
      2,
      3,
      0,
      4,
      3,
      1,
      2,
      3,
      3,
      2,
      0,
      3,
      4,
      1,
      4,
      2,
      2,
      0,
      2,
      1,
      4,
      4,
      3,
      1,
      4,
      4,
      1,
      3,
      4,
      1,
      0,
      0,
      4,
      4,
      3,
      2,
      0,
      4,
      0,
      3,
      0,
      0,
      4,
      3,
      0
    ]
  },
  {
    "id": "VNDR-0000114",
    "status": "inactive",
    "name": "ProHealth Systems",
    "tag": "Medical Supplies, PPE",
    "contactName": "Bryan Lang",
    "industryRating": 4.7,
    "procedures": "[PR-047,PR-048,PR-025,PR-019,PR-056,PR-026,PR-053,PR-064,PR-063,PR-062]",
    "contracts": "[CNTRCT-0000055,CNTRCT-0000060,CNTRCT-0000025]",
    "orders": "[ORDR-0-0000024,ORDR-1-0000053,ORDR-2-0000040,ORDR-3-0000059,ORDR-4-0000059,ORDR-5-0000048,ORDR-6-0000027,ORDR-7-0000009,ORDR-8-0000020,ORDR-9-0000029,ORDR-10-0000037,ORDR-11-0000074,ORDR-12-0000056,ORDR-13-0000040,ORDR-14-0000047,ORDR-15-0000047,ORDR-16-0000027,ORDR-17-0000034,ORDR-18-0000017]",
    "spendWeekly": [
      3050,
      3065,
      3082,
      3029,
      3007,
      2905,
      2931,
      3098,
      3020,
      3060,
      3065,
      2987,
      2909,
      2985,
      3007,
      2982,
      3062,
      3098,
      2996,
      3098,
      3043,
      3075,
      3097,
      3096,
      3056,
      2954,
      3013,
      3082,
      2958,
      3076,
      3012,
      2908,
      2951,
      2990,
      2913,
      2904,
      2980,
      2924,
      2906,
      2903,
      2901,
      3082,
      3006,
      3091,
      2910,
      2901,
      3046,
      3039,
      3013,
      2977,
      2905,
      3047
    ],
    "orderRejected": [
      3047,
      18,
      3,
      15,
      16,
      17,
      7,
      13,
      16,
      0,
      18,
      10,
      9,
      5,
      12,
      8,
      19,
      3,
      0,
      9,
      1,
      0,
      3,
      19,
      18,
      11,
      6,
      8,
      11,
      0,
      11,
      10,
      17,
      19,
      19,
      10,
      9,
      4,
      14,
      2,
      19,
      19,
      19,
      5,
      0,
      3,
      5,
      3,
      14,
      6,
      14,
      10,
      19
    ],
    "orderAccepted": [
      9,
      4,
      6,
      4,
      2,
      3,
      2,
      0,
      2,
      2,
      0,
      0,
      11,
      11,
      10,
      11,
      2,
      7,
      6,
      3,
      6,
      6,
      0,
      10,
      5,
      3,
      5,
      3,
      10,
      6,
      10,
      6,
      0,
      6,
      1,
      11,
      6,
      9,
      7,
      10,
      3,
      11,
      4,
      4,
      7,
      11,
      4,
      6,
      9,
      6,
      3,
      5
    ],
    "orderHold": [
      0,
      3,
      0,
      4,
      2,
      3,
      4,
      3,
      3,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      3,
      1,
      2,
      2,
      4,
      4,
      0,
      0,
      3,
      3,
      4,
      0,
      3,
      3,
      2,
      4,
      0,
      2,
      3,
      1,
      2,
      2,
      4,
      3,
      2,
      4,
      2,
      4,
      1,
      2,
      3,
      0,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      2,
      2,
      0,
      0,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      0,
      0,
      2,
      2,
      2,
      0,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      3,
      3,
      4,
      0,
      4,
      1,
      1,
      1,
      3,
      3,
      1,
      4,
      0,
      2,
      3,
      0,
      2,
      1,
      2,
      2,
      0,
      4,
      2,
      1,
      0,
      2,
      2,
      4,
      4,
      2,
      2,
      2,
      1,
      4,
      3,
      2,
      0,
      3,
      4,
      3,
      1,
      1,
      4,
      0,
      0,
      0,
      3,
      2,
      0,
      2,
      1
    ],
    "issueDelay": [
      4,
      3,
      2,
      1,
      0,
      4,
      1,
      1,
      2,
      1,
      3,
      2,
      4,
      4,
      1,
      3,
      1,
      0,
      3,
      1,
      0,
      1,
      3,
      1,
      1,
      0,
      2,
      0,
      2,
      4,
      4,
      0,
      2,
      0,
      1,
      2,
      4,
      4,
      3,
      3,
      0,
      0,
      1,
      0,
      4,
      2,
      1,
      4,
      0,
      4,
      0,
      2
    ],
    "issueQuantity": [
      4,
      3,
      3,
      2,
      4,
      4,
      2,
      0,
      4,
      4,
      2,
      1,
      3,
      0,
      4,
      1,
      1,
      2,
      3,
      4,
      0,
      2,
      2,
      4,
      3,
      0,
      0,
      3,
      2,
      1,
      4,
      0,
      1,
      3,
      0,
      2,
      4,
      0,
      1,
      4,
      0,
      2,
      2,
      0,
      4,
      1,
      2,
      2,
      3,
      4,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000115",
    "status": "inactive",
    "name": "HealthCraft Logistics",
    "tag": "Facilities Management, Equipment",
    "contactName": "Gary Wilson",
    "industryRating": 3.5,
    "procedures": "[PR-060,PR-032,PR-064,PR-067,PR-056,PR-061,PR-059,PR-063,PR-046,PR-074]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000035,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000055,ORDR-1-0000001,ORDR-2-0000030,ORDR-3-0000008,ORDR-4-0000061,ORDR-5-0000068,ORDR-6-0000051,ORDR-7-0000062,ORDR-8-0000018,ORDR-9-0000035,ORDR-10-0000069,ORDR-11-0000020,ORDR-12-0000059,ORDR-13-0000041,ORDR-14-0000036,ORDR-15-0000058,ORDR-16-0000051,ORDR-17-0000062,ORDR-18-0000075]",
    "spendWeekly": [
      2937,
      2979,
      2981,
      2992,
      2903,
      2905,
      3040,
      3074,
      2960,
      3003,
      3004,
      3060,
      2909,
      3015,
      3091,
      2944,
      3067,
      2900,
      2999,
      3012,
      3100,
      3031,
      3022,
      2997,
      3058,
      3096,
      2958,
      2916,
      2930,
      2908,
      3069,
      2962,
      3012,
      3056,
      3095,
      2944,
      2982,
      2946,
      3096,
      2980,
      3004,
      2987,
      3097,
      3025,
      2946,
      2901,
      3006,
      2900,
      3059,
      3097,
      2919,
      2923
    ],
    "orderRejected": [
      2923,
      19,
      14,
      11,
      19,
      5,
      19,
      13,
      2,
      2,
      18,
      17,
      15,
      10,
      19,
      16,
      19,
      6,
      7,
      5,
      17,
      19,
      1,
      14,
      16,
      4,
      5,
      0,
      11,
      19,
      3,
      16,
      0,
      19,
      0,
      9,
      3,
      16,
      19,
      0,
      15,
      19,
      7,
      0,
      9,
      15,
      19,
      0,
      0,
      7,
      0,
      19,
      16
    ],
    "orderAccepted": [
      6,
      3,
      8,
      10,
      2,
      5,
      8,
      3,
      7,
      6,
      0,
      0,
      4,
      11,
      9,
      10,
      9,
      0,
      0,
      0,
      5,
      1,
      11,
      6,
      6,
      1,
      10,
      10,
      11,
      11,
      8,
      4,
      8,
      11,
      11,
      6,
      0,
      9,
      11,
      0,
      3,
      5,
      5,
      9,
      3,
      9,
      10,
      11,
      6,
      0,
      4,
      9
    ],
    "orderHold": [
      1,
      3,
      0,
      1,
      2,
      1,
      1,
      3,
      2,
      2,
      3,
      2,
      1,
      4,
      0,
      0,
      1,
      2,
      4,
      3,
      2,
      2,
      3,
      4,
      0,
      4,
      3,
      1,
      4,
      1,
      4,
      4,
      4,
      1,
      1,
      2,
      0,
      0,
      3,
      1,
      2,
      2,
      3,
      0,
      2,
      3,
      0,
      2,
      4,
      0,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      1,
      1,
      4,
      4,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      0,
      1,
      3,
      0,
      4,
      2,
      1,
      2,
      2,
      0,
      3,
      0,
      3,
      2,
      1,
      4,
      1,
      2,
      3,
      1,
      0,
      1,
      2,
      0,
      0,
      3,
      0,
      2,
      3,
      0,
      4,
      4,
      3,
      3,
      4,
      2,
      4,
      2,
      4
    ],
    "issueDelay": [
      2,
      0,
      4,
      3,
      2,
      1,
      4,
      0,
      1,
      0,
      0,
      3,
      2,
      4,
      2,
      4,
      1,
      0,
      0,
      2,
      2,
      0,
      0,
      4,
      3,
      2,
      1,
      1,
      2,
      0,
      1,
      3,
      2,
      0,
      4,
      1,
      2,
      3,
      4,
      3,
      1,
      0,
      0,
      1,
      0,
      4,
      0,
      0,
      2,
      1,
      0,
      1
    ],
    "issueQuantity": [
      2,
      0,
      2,
      0,
      4,
      0,
      4,
      3,
      2,
      2,
      4,
      1,
      0,
      3,
      0,
      2,
      2,
      0,
      4,
      2,
      1,
      2,
      3,
      1,
      2,
      3,
      4,
      0,
      3,
      2,
      2,
      0,
      2,
      0,
      3,
      3,
      1,
      0,
      3,
      2,
      4,
      4,
      3,
      2,
      4,
      0,
      1,
      0,
      0,
      2,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000116",
    "status": "inactive",
    "name": "LifeLine Supplies",
    "tag": "Equipment, PPE, Nutrition",
    "contactName": "Taylor Gutierrez",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-063,PR-067,PR-040,PR-069,PR-069,PR-072,PR-054,PR-074,PR-054]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000039,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000036,ORDR-2-0000004,ORDR-3-0000011,ORDR-4-0000037,ORDR-5-0000033,ORDR-6-0000020,ORDR-7-0000036,ORDR-8-0000040,ORDR-9-0000038,ORDR-10-0000022,ORDR-11-0000066,ORDR-12-0000073,ORDR-13-0000052,ORDR-14-0000014,ORDR-15-0000038,ORDR-16-0000031,ORDR-17-0000040,ORDR-18-0000018]",
    "spendWeekly": [
      3010,
      3045,
      2906,
      3039,
      2933,
      2905,
      3062,
      3081,
      3010,
      2949,
      2930,
      3009,
      2961,
      3013,
      2985,
      2939,
      3006,
      3000,
      2966,
      2949,
      2961,
      3022,
      3057,
      2904,
      3046,
      3096,
      3095,
      2969,
      3025,
      2975,
      2903,
      2988,
      3040,
      2985,
      2980,
      2904,
      2935,
      2902,
      3099,
      3006,
      2901,
      2996,
      3058,
      3091,
      2920,
      3052,
      3099,
      3031,
      3081,
      2905,
      3022,
      2939
    ],
    "orderRejected": [
      2939,
      6,
      1,
      2,
      4,
      19,
      11,
      8,
      0,
      12,
      4,
      10,
      15,
      12,
      13,
      7,
      10,
      11,
      7,
      15,
      10,
      15,
      4,
      6,
      10,
      1,
      13,
      11,
      19,
      9,
      7,
      19,
      15,
      12,
      19,
      5,
      0,
      0,
      19,
      5,
      7,
      19,
      16,
      9,
      1,
      0,
      14,
      14,
      16,
      16,
      19,
      5,
      4
    ],
    "orderAccepted": [
      8,
      9,
      3,
      10,
      3,
      4,
      5,
      3,
      1,
      4,
      11,
      6,
      1,
      8,
      10,
      11,
      8,
      11,
      6,
      4,
      11,
      0,
      11,
      5,
      11,
      4,
      0,
      9,
      0,
      5,
      5,
      8,
      0,
      0,
      4,
      10,
      0,
      2,
      3,
      0,
      7,
      11,
      4,
      4,
      1,
      0,
      3,
      11,
      0,
      8,
      2,
      7
    ],
    "orderHold": [
      1,
      3,
      4,
      4,
      2,
      2,
      1,
      3,
      2,
      0,
      3,
      3,
      3,
      4,
      0,
      0,
      3,
      0,
      4,
      1,
      2,
      2,
      2,
      4,
      4,
      3,
      4,
      3,
      0,
      1,
      1,
      3,
      4,
      4,
      0,
      4,
      0,
      1,
      0,
      4,
      3,
      0,
      4,
      1,
      2,
      3,
      1,
      2,
      1,
      1,
      0,
      2
    ],
    "orderBackordered": [
      2,
      0,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      2,
      2,
      2,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      4,
      4,
      4,
      0,
      0,
      1,
      4,
      4,
      3,
      3,
      3,
      4,
      2,
      2,
      0,
      1,
      3,
      3,
      0,
      0,
      0,
      0,
      4,
      1,
      3,
      0,
      2,
      2,
      1,
      0,
      2,
      0,
      0,
      1,
      4,
      0,
      0,
      2,
      3,
      0,
      2,
      2,
      4,
      2,
      4,
      1,
      4,
      4,
      4,
      1,
      3
    ],
    "issueDelay": [
      1,
      1,
      2,
      1,
      3,
      2,
      0,
      0,
      0,
      4,
      1,
      4,
      0,
      2,
      4,
      2,
      2,
      0,
      2,
      4,
      0,
      0,
      3,
      1,
      0,
      3,
      3,
      3,
      2,
      4,
      3,
      4,
      4,
      2,
      4,
      4,
      0,
      1,
      2,
      4,
      1,
      2,
      4,
      4,
      4,
      3,
      1,
      2,
      4,
      2,
      3,
      0
    ],
    "issueQuantity": [
      4,
      0,
      0,
      3,
      4,
      2,
      2,
      3,
      0,
      0,
      0,
      0,
      0,
      3,
      4,
      0,
      3,
      4,
      0,
      4,
      4,
      3,
      3,
      3,
      0,
      4,
      0,
      1,
      2,
      2,
      2,
      4,
      3,
      3,
      1,
      4,
      4,
      0,
      2,
      3,
      1,
      4,
      3,
      3,
      4,
      0,
      3,
      4,
      0,
      4,
      3,
      0
    ]
  },
  {
    "id": "VNDR-0000117",
    "status": "active",
    "name": "BioVital Supplies",
    "tag": "Equipment, Surgical Instruments, IT Services",
    "contactName": "Gary Wilson",
    "industryRating": 2.5,
    "procedures": "[PR-066,PR-064,PR-029,PR-066,PR-066,PR-061,PR-068,PR-072,PR-057,PR-048]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000052,CNTRCT-0000020]",
    "orders": "[ORDR-0-0000030,ORDR-1-0000014,ORDR-2-0000011,ORDR-3-0000028,ORDR-4-0000015,ORDR-5-0000025,ORDR-6-0000035,ORDR-7-0000055,ORDR-8-0000061,ORDR-9-0000064,ORDR-10-0000036,ORDR-11-0000060,ORDR-12-0000042,ORDR-13-0000056,ORDR-14-0000037,ORDR-15-0000079,ORDR-16-0000037,ORDR-17-0000032,ORDR-18-0000034]",
    "spendWeekly": [
      3097,
      3069,
      3015,
      3003,
      2978,
      2905,
      2976,
      3045,
      3100,
      2907,
      3061,
      3086,
      2988,
      3082,
      3091,
      2901,
      2903,
      3045,
      3068,
      3098,
      3052,
      3029,
      3080,
      2979,
      2970,
      2926,
      3056,
      3039,
      2965,
      3038,
      2961,
      3010,
      3099,
      2904,
      3009,
      2971,
      2977,
      2993,
      2970,
      2974,
      3036,
      2954,
      3054,
      3064,
      3090,
      2901,
      3057,
      2911,
      3003,
      2983,
      2905,
      3097
    ],
    "orderRejected": [
      3097,
      13,
      10,
      19,
      0,
      3,
      0,
      12,
      19,
      19,
      10,
      3,
      0,
      0,
      19,
      12,
      15,
      9,
      4,
      4,
      7,
      12,
      14,
      15,
      10,
      14,
      0,
      0,
      4,
      15,
      15,
      17,
      0,
      19,
      15,
      9,
      0,
      14,
      1,
      0,
      13,
      19,
      5,
      0,
      8,
      13,
      0,
      18,
      19,
      11,
      3,
      0,
      16
    ],
    "orderAccepted": [
      11,
      6,
      0,
      0,
      6,
      4,
      9,
      11,
      1,
      0,
      6,
      8,
      7,
      0,
      10,
      4,
      0,
      7,
      2,
      11,
      11,
      0,
      10,
      0,
      11,
      4,
      8,
      1,
      3,
      5,
      8,
      4,
      0,
      2,
      0,
      11,
      11,
      2,
      11,
      8,
      0,
      7,
      6,
      8,
      1,
      10,
      10,
      11,
      4,
      8,
      5,
      0
    ],
    "orderHold": [
      3,
      0,
      3,
      0,
      0,
      4,
      3,
      0,
      4,
      4,
      2,
      1,
      2,
      3,
      1,
      2,
      4,
      1,
      4,
      0,
      2,
      4,
      4,
      4,
      1,
      4,
      2,
      1,
      4,
      1,
      2,
      2,
      4,
      0,
      1,
      0,
      1,
      2,
      0,
      2,
      3,
      0,
      0,
      3,
      0,
      2,
      1,
      0,
      0,
      2,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      0,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      1,
      4,
      0,
      2,
      2,
      4,
      4,
      2,
      2,
      1,
      0,
      4,
      2,
      3,
      2,
      3,
      2,
      1,
      0,
      4,
      3,
      2,
      2,
      4,
      4,
      0,
      4,
      0,
      4,
      3,
      3,
      3,
      0,
      0,
      1,
      2,
      3,
      3,
      3,
      0,
      2,
      4,
      1,
      0,
      3,
      1,
      4,
      1,
      1,
      4
    ],
    "issueDelay": [
      1,
      1,
      4,
      4,
      0,
      1,
      2,
      4,
      2,
      0,
      1,
      2,
      4,
      1,
      1,
      0,
      3,
      0,
      4,
      3,
      1,
      4,
      1,
      0,
      0,
      0,
      1,
      2,
      0,
      4,
      4,
      1,
      4,
      0,
      1,
      2,
      3,
      3,
      4,
      0,
      0,
      0,
      1,
      0,
      2,
      2,
      0,
      0,
      3,
      4,
      1,
      3
    ],
    "issueQuantity": [
      4,
      4,
      2,
      1,
      3,
      3,
      0,
      2,
      0,
      1,
      4,
      2,
      4,
      2,
      4,
      2,
      3,
      4,
      1,
      0,
      4,
      4,
      0,
      2,
      0,
      1,
      3,
      3,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      4,
      1,
      0,
      2,
      4,
      4,
      3,
      4,
      2,
      4,
      1,
      4,
      2,
      2,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000118",
    "status": "active",
    "name": "MediFlow Systems",
    "tag": "Imaging, PPE, IT Services",
    "contactName": "Kristy Boyer",
    "industryRating": 3,
    "procedures": "[PR-024,PR-021,PR-068,PR-064,PR-026,PR-044,PR-059,PR-061,PR-071,PR-037]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000048,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000043,ORDR-2-0000045,ORDR-3-0000004,ORDR-4-0000001,ORDR-5-0000027,ORDR-6-0000061,ORDR-7-0000013,ORDR-8-0000055,ORDR-9-0000032,ORDR-10-0000035,ORDR-11-0000034,ORDR-12-0000049,ORDR-13-0000012,ORDR-14-0000039,ORDR-15-0000047,ORDR-16-0000062,ORDR-17-0000048,ORDR-18-0000038]",
    "spendWeekly": [
      3011,
      2918,
      2906,
      3005,
      2903,
      3071,
      3095,
      3098,
      3033,
      3007,
      3026,
      2967,
      3060,
      3067,
      2967,
      2901,
      2972,
      2900,
      2981,
      3045,
      2963,
      3087,
      3048,
      2903,
      3034,
      3096,
      2974,
      3004,
      2973,
      3000,
      2903,
      2934,
      3078,
      2904,
      3095,
      3034,
      2948,
      3095,
      2930,
      3016,
      2982,
      2900,
      2989,
      3091,
      3080,
      2901,
      3064,
      3004,
      3003,
      2933,
      2905,
      3026
    ],
    "orderRejected": [
      3026,
      14,
      4,
      9,
      0,
      14,
      5,
      19,
      11,
      7,
      6,
      17,
      18,
      16,
      10,
      12,
      12,
      10,
      8,
      1,
      19,
      15,
      1,
      9,
      19,
      1,
      0,
      8,
      6,
      15,
      5,
      15,
      4,
      10,
      17,
      7,
      6,
      19,
      10,
      0,
      3,
      19,
      8,
      13,
      1,
      3,
      0,
      15,
      2,
      4,
      15,
      4,
      11
    ],
    "orderAccepted": [
      4,
      5,
      8,
      6,
      0,
      4,
      5,
      8,
      0,
      6,
      5,
      0,
      4,
      6,
      11,
      9,
      0,
      11,
      0,
      8,
      7,
      4,
      5,
      1,
      10,
      6,
      2,
      4,
      7,
      2,
      4,
      11,
      3,
      4,
      5,
      11,
      4,
      8,
      11,
      6,
      1,
      5,
      10,
      7,
      1,
      3,
      0,
      10,
      0,
      5,
      8,
      9
    ],
    "orderHold": [
      2,
      3,
      4,
      4,
      3,
      0,
      2,
      3,
      0,
      0,
      1,
      0,
      3,
      0,
      1,
      3,
      4,
      0,
      4,
      3,
      3,
      4,
      1,
      4,
      0,
      0,
      0,
      3,
      0,
      0,
      1,
      4,
      0,
      0,
      1,
      4,
      3,
      2,
      4,
      2,
      3,
      4,
      0,
      3,
      4,
      4,
      0,
      4,
      4,
      1,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      2,
      0,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      0,
      2,
      0,
      4,
      1,
      2,
      0,
      0,
      2,
      2,
      4,
      1,
      2,
      2,
      0,
      1,
      3,
      2,
      2,
      3,
      1,
      1,
      4,
      4,
      3,
      4,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      0,
      0,
      0,
      2,
      1,
      0,
      0,
      0,
      0,
      4,
      3,
      4,
      2,
      0,
      1,
      4,
      0,
      2,
      4
    ],
    "issueDelay": [
      2,
      3,
      2,
      0,
      0,
      4,
      1,
      0,
      1,
      4,
      2,
      3,
      4,
      4,
      3,
      2,
      4,
      0,
      3,
      1,
      1,
      2,
      1,
      0,
      0,
      3,
      4,
      4,
      0,
      3,
      2,
      2,
      1,
      0,
      4,
      1,
      0,
      3,
      4,
      0,
      3,
      4,
      3,
      1,
      3,
      2,
      1,
      4,
      4,
      0,
      1,
      4
    ],
    "issueQuantity": [
      3,
      3,
      3,
      3,
      0,
      2,
      0,
      0,
      1,
      4,
      3,
      4,
      1,
      2,
      4,
      0,
      3,
      3,
      3,
      3,
      1,
      4,
      4,
      1,
      0,
      2,
      2,
      1,
      2,
      2,
      4,
      3,
      3,
      4,
      1,
      3,
      3,
      3,
      0,
      4,
      3,
      2,
      3,
      1,
      1,
      4,
      1,
      2,
      1,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000119",
    "status": "active",
    "name": "PharmaLogix International",
    "tag": "Laboratory Supplies, Equipment",
    "contactName": "Justin Molina",
    "industryRating": 4.8,
    "procedures": "[PR-052,PR-066,PR-038,PR-044,PR-053,PR-036,PR-072,PR-070,PR-071,PR-075]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000057,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000060,ORDR-1-0000013,ORDR-2-0000010,ORDR-3-0000058,ORDR-4-0000061,ORDR-5-0000034,ORDR-6-0000047,ORDR-7-0000021,ORDR-8-0000032,ORDR-9-0000011,ORDR-10-0000064,ORDR-11-0000018,ORDR-12-0000025,ORDR-13-0000065,ORDR-14-0000013,ORDR-15-0000015,ORDR-16-0000027,ORDR-17-0000055,ORDR-18-0000019]",
    "spendWeekly": [
      2901,
      3021,
      2906,
      3049,
      2903,
      3046,
      3100,
      3001,
      2971,
      2947,
      2986,
      3005,
      2909,
      3012,
      3022,
      3033,
      3091,
      3065,
      2961,
      3098,
      3100,
      2981,
      3014,
      2995,
      3005,
      3096,
      2966,
      2901,
      2901,
      2908,
      2903,
      2909,
      3015,
      3014,
      2967,
      3080,
      2985,
      3097,
      2906,
      2916,
      2901,
      2965,
      3004,
      3047,
      2949,
      2901,
      2998,
      3047,
      2980,
      3019,
      3091,
      3070
    ],
    "orderRejected": [
      3070,
      11,
      0,
      2,
      12,
      16,
      6,
      17,
      0,
      5,
      1,
      6,
      19,
      11,
      10,
      19,
      19,
      0,
      6,
      9,
      17,
      19,
      7,
      16,
      19,
      19,
      19,
      0,
      6,
      4,
      2,
      8,
      9,
      19,
      12,
      17,
      11,
      4,
      16,
      5,
      19,
      18,
      19,
      11,
      0,
      4,
      7,
      4,
      17,
      4,
      19,
      5,
      16
    ],
    "orderAccepted": [
      2,
      6,
      6,
      0,
      4,
      11,
      4,
      5,
      4,
      11,
      4,
      5,
      1,
      0,
      11,
      11,
      0,
      0,
      7,
      4,
      0,
      0,
      9,
      4,
      6,
      10,
      0,
      11,
      6,
      11,
      5,
      7,
      0,
      4,
      6,
      11,
      5,
      0,
      11,
      4,
      8,
      4,
      4,
      5,
      1,
      9,
      0,
      9,
      8,
      6,
      0,
      5
    ],
    "orderHold": [
      0,
      1,
      3,
      4,
      3,
      0,
      4,
      4,
      4,
      3,
      2,
      0,
      2,
      3,
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      0,
      4,
      4,
      0,
      2,
      0,
      1,
      4,
      1,
      0,
      2,
      4,
      2,
      2,
      2,
      2,
      0,
      3,
      4,
      3,
      0,
      0,
      3,
      2,
      3,
      1,
      3,
      1,
      1,
      0,
      1
    ],
    "orderBackordered": [
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      2,
      2,
      0,
      1,
      1,
      0,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      4,
      4,
      1,
      2,
      0,
      1,
      0,
      4,
      1,
      1,
      0,
      2,
      2,
      3,
      0,
      4,
      0,
      0,
      0,
      0,
      1,
      3,
      1,
      2,
      3,
      3,
      3,
      2,
      4,
      0,
      1,
      0,
      4,
      0,
      1,
      0,
      1,
      3,
      1,
      4,
      0,
      0,
      3,
      0,
      1,
      4,
      0,
      4
    ],
    "issueDelay": [
      3,
      2,
      3,
      4,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      3,
      1,
      0,
      3,
      4,
      0,
      4,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      4,
      4,
      4,
      2,
      3,
      0,
      2,
      1,
      4,
      3,
      4,
      4,
      4,
      2,
      3,
      1,
      0,
      0,
      4,
      1,
      4,
      2,
      4,
      0,
      0,
      4
    ],
    "issueQuantity": [
      4,
      3,
      2,
      0,
      3,
      2,
      1,
      0,
      0,
      4,
      1,
      3,
      1,
      2,
      4,
      2,
      3,
      1,
      0,
      4,
      0,
      3,
      0,
      1,
      2,
      3,
      4,
      4,
      2,
      4,
      3,
      1,
      0,
      4,
      1,
      3,
      4,
      1,
      4,
      4,
      2,
      3,
      4,
      2,
      4,
      2,
      1,
      0,
      3,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000120",
    "status": "inactive",
    "name": "WellnessWorks Distribution",
    "tag": "Nutrition",
    "contactName": "Ashley Anderson",
    "industryRating": 2.1,
    "procedures": "[PR-063,PR-066,PR-037,PR-069,PR-058,PR-069,PR-051,PR-051,PR-055,PR-074]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000066,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000050,ORDR-1-0000041,ORDR-2-0000044,ORDR-3-0000003,ORDR-4-0000017,ORDR-5-0000019,ORDR-6-0000012,ORDR-7-0000070,ORDR-8-0000008,ORDR-9-0000028,ORDR-10-0000042,ORDR-11-0000058,ORDR-12-0000011,ORDR-13-0000036,ORDR-14-0000018,ORDR-15-0000041,ORDR-16-0000060,ORDR-17-0000060,ORDR-18-0000065]",
    "spendWeekly": [
      2980,
      2928,
      3016,
      2902,
      2982,
      3001,
      3062,
      3031,
      3005,
      3071,
      2910,
      3056,
      2999,
      3035,
      3091,
      2977,
      3067,
      2971,
      2900,
      3098,
      2908,
      2921,
      2938,
      2930,
      2929,
      2971,
      2909,
      2921,
      3084,
      2983,
      2983,
      2956,
      3053,
      3059,
      2961,
      3011,
      3059,
      3097,
      2955,
      3006,
      3036,
      3046,
      2932,
      3032,
      2986,
      3099,
      2931,
      2957,
      3011,
      2905,
      3068,
      3061
    ],
    "orderRejected": [
      3061,
      12,
      2,
      19,
      15,
      19,
      0,
      4,
      11,
      18,
      0,
      6,
      14,
      18,
      19,
      16,
      15,
      10,
      5,
      6,
      11,
      19,
      0,
      19,
      5,
      3,
      14,
      0,
      17,
      17,
      4,
      19,
      14,
      18,
      10,
      15,
      6,
      8,
      11,
      3,
      0,
      18,
      9,
      0,
      0,
      4,
      16,
      0,
      19,
      15,
      5,
      7,
      6
    ],
    "orderAccepted": [
      0,
      11,
      3,
      11,
      9,
      11,
      6,
      4,
      0,
      3,
      10,
      5,
      10,
      10,
      11,
      11,
      8,
      9,
      11,
      2,
      0,
      6,
      7,
      0,
      11,
      11,
      0,
      11,
      2,
      9,
      7,
      4,
      0,
      3,
      6,
      4,
      4,
      0,
      9,
      7,
      11,
      7,
      0,
      10,
      0,
      11,
      9,
      8,
      11,
      5,
      7,
      2
    ],
    "orderHold": [
      0,
      1,
      3,
      4,
      0,
      0,
      3,
      3,
      4,
      0,
      4,
      2,
      1,
      4,
      0,
      2,
      4,
      2,
      3,
      2,
      1,
      0,
      0,
      1,
      0,
      3,
      3,
      1,
      3,
      3,
      4,
      4,
      2,
      1,
      2,
      2,
      4,
      0,
      0,
      0,
      3,
      0,
      2,
      1,
      2,
      4,
      4,
      1,
      0,
      1,
      4,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      3,
      1,
      0,
      0,
      1,
      1,
      1,
      4,
      2,
      0,
      1,
      1,
      3,
      2,
      0,
      0,
      4,
      3,
      2,
      4,
      3,
      0,
      3,
      1,
      3,
      4,
      3,
      0,
      4,
      1,
      3,
      4,
      0,
      0,
      4,
      3,
      2,
      2,
      3,
      3,
      1,
      1,
      0,
      3,
      1,
      4,
      2,
      1,
      2,
      1,
      1,
      3
    ],
    "issueDelay": [
      3,
      3,
      4,
      3,
      3,
      1,
      0,
      2,
      0,
      2,
      1,
      4,
      4,
      0,
      0,
      4,
      4,
      3,
      3,
      3,
      1,
      1,
      2,
      4,
      0,
      3,
      1,
      2,
      3,
      0,
      3,
      1,
      0,
      2,
      4,
      0,
      1,
      4,
      2,
      3,
      3,
      1,
      0,
      3,
      4,
      4,
      0,
      1,
      0,
      0,
      2,
      3
    ],
    "issueQuantity": [
      3,
      1,
      2,
      1,
      4,
      2,
      3,
      1,
      1,
      0,
      2,
      1,
      0,
      2,
      4,
      4,
      2,
      4,
      0,
      4,
      3,
      4,
      3,
      0,
      3,
      4,
      2,
      0,
      4,
      2,
      4,
      3,
      4,
      4,
      1,
      4,
      3,
      2,
      1,
      4,
      1,
      2,
      2,
      1,
      4,
      1,
      0,
      0,
      0,
      3,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000121",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Equipment, Surgical Instruments, IT Services",
    "contactName": "Michael Montgomery",
    "industryRating": 3.6,
    "procedures": "[PR-016,PR-056,PR-060,PR-031,PR-045,PR-053,PR-068,PR-039,PR-045,PR-071]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000055,CNTRCT-0000041]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000045,ORDR-2-0000017,ORDR-3-0000058,ORDR-4-0000044,ORDR-5-0000023,ORDR-6-0000023,ORDR-7-0000037,ORDR-8-0000031,ORDR-9-0000041,ORDR-10-0000034,ORDR-11-0000020,ORDR-12-0000017,ORDR-13-0000039,ORDR-14-0000076,ORDR-15-0000037,ORDR-16-0000017,ORDR-17-0000024,ORDR-18-0000033]",
    "spendWeekly": [
      2935,
      3018,
      2906,
      2953,
      2977,
      2905,
      2918,
      2913,
      2950,
      3015,
      3041,
      3032,
      2937,
      3042,
      2944,
      2901,
      3033,
      3099,
      2928,
      3087,
      2980,
      3012,
      3039,
      3022,
      3095,
      3021,
      2909,
      2973,
      2953,
      2990,
      3053,
      3062,
      3039,
      3046,
      2928,
      3026,
      3100,
      2902,
      2906,
      2982,
      2901,
      3007,
      2991,
      2936,
      2912,
      2901,
      2901,
      2937,
      2998,
      2987,
      2999,
      2993
    ],
    "orderRejected": [
      2993,
      19,
      7,
      8,
      7,
      15,
      8,
      12,
      15,
      9,
      7,
      0,
      2,
      8,
      10,
      1,
      19,
      5,
      3,
      7,
      12,
      19,
      9,
      19,
      6,
      7,
      0,
      0,
      5,
      17,
      6,
      19,
      14,
      19,
      1,
      0,
      8,
      12,
      8,
      14,
      16,
      14,
      2,
      3,
      19,
      13,
      0,
      15,
      12,
      15,
      14,
      12,
      12
    ],
    "orderAccepted": [
      4,
      4,
      2,
      9,
      6,
      11,
      11,
      8,
      7,
      2,
      4,
      7,
      5,
      10,
      4,
      11,
      0,
      6,
      4,
      5,
      0,
      10,
      1,
      8,
      7,
      8,
      0,
      8,
      6,
      5,
      8,
      6,
      0,
      8,
      6,
      9,
      3,
      8,
      11,
      0,
      3,
      10,
      3,
      0,
      0,
      10,
      0,
      11,
      0,
      9,
      0,
      8
    ],
    "orderHold": [
      2,
      1,
      2,
      1,
      3,
      0,
      2,
      3,
      4,
      1,
      1,
      1,
      3,
      2,
      1,
      2,
      3,
      1,
      4,
      2,
      4,
      3,
      2,
      3,
      0,
      3,
      4,
      0,
      4,
      1,
      2,
      4,
      1,
      0,
      2,
      2,
      2,
      0,
      3,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      2,
      4,
      0,
      0,
      1,
      4,
      2,
      1,
      1,
      2,
      1,
      3,
      1,
      3,
      2,
      4,
      0,
      0,
      2,
      1,
      4,
      2,
      4,
      0,
      1,
      4,
      1,
      4,
      2,
      0,
      3,
      2,
      0,
      1,
      2,
      4,
      0,
      2,
      1,
      2,
      4,
      4,
      3,
      0,
      4,
      3,
      0,
      0
    ],
    "issueDelay": [
      3,
      2,
      4,
      4,
      1,
      3,
      4,
      2,
      1,
      1,
      0,
      2,
      2,
      0,
      3,
      0,
      1,
      0,
      3,
      1,
      2,
      2,
      2,
      3,
      3,
      3,
      4,
      1,
      0,
      1,
      3,
      3,
      1,
      2,
      3,
      1,
      0,
      2,
      0,
      3,
      2,
      4,
      2,
      1,
      0,
      0,
      2,
      0,
      1,
      0,
      3,
      2
    ],
    "issueQuantity": [
      2,
      0,
      2,
      0,
      2,
      2,
      1,
      1,
      0,
      3,
      2,
      2,
      1,
      2,
      0,
      3,
      1,
      4,
      2,
      4,
      0,
      0,
      1,
      4,
      2,
      3,
      0,
      1,
      4,
      2,
      4,
      3,
      1,
      2,
      4,
      0,
      3,
      0,
      3,
      4,
      4,
      3,
      4,
      0,
      1,
      1,
      4,
      1,
      3,
      1,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000122",
    "status": "active",
    "name": "VitalCare Distribution",
    "tag": "Equipment, Surgical Instruments, IT Services",
    "contactName": "Justin Molina",
    "industryRating": 3.7,
    "procedures": "[PR-060,PR-055,PR-013,PR-031,PR-056,PR-051,PR-063,PR-021,PR-073,PR-053]",
    "contracts": "[CNTRCT-0000065,CNTRCT-0000064,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000039,ORDR-1-0000062,ORDR-2-0000051,ORDR-3-0000015,ORDR-4-0000014,ORDR-5-0000030,ORDR-6-0000049,ORDR-7-0000023,ORDR-8-0000019,ORDR-9-0000071,ORDR-10-0000073,ORDR-11-0000031,ORDR-12-0000060,ORDR-13-0000012,ORDR-14-0000051,ORDR-15-0000045,ORDR-16-0000073,ORDR-17-0000026,ORDR-18-0000068]",
    "spendWeekly": [
      2956,
      3031,
      3081,
      2963,
      2903,
      3092,
      3065,
      3061,
      2918,
      2998,
      2901,
      3046,
      2909,
      2910,
      3044,
      2901,
      2903,
      3093,
      2995,
      2909,
      2985,
      3083,
      3013,
      2918,
      3095,
      3096,
      2971,
      2991,
      2971,
      3017,
      3054,
      2984,
      3004,
      2904,
      3078,
      3097,
      3100,
      2902,
      3099,
      3021,
      2974,
      3020,
      2998,
      3091,
      2992,
      2930,
      2941,
      2900,
      3100,
      2905,
      3038,
      2974
    ],
    "orderRejected": [
      2974,
      4,
      2,
      6,
      7,
      10,
      10,
      10,
      9,
      19,
      3,
      10,
      16,
      17,
      19,
      9,
      19,
      7,
      13,
      18,
      13,
      4,
      0,
      16,
      10,
      10,
      0,
      5,
      18,
      12,
      14,
      19,
      1,
      19,
      5,
      12,
      14,
      19,
      12,
      6,
      8,
      19,
      15,
      2,
      8,
      0,
      18,
      14,
      8,
      10,
      0,
      11,
      19
    ],
    "orderAccepted": [
      1,
      11,
      2,
      7,
      8,
      6,
      8,
      3,
      0,
      11,
      0,
      9,
      9,
      1,
      8,
      9,
      0,
      6,
      5,
      6,
      3,
      0,
      4,
      2,
      11,
      11,
      3,
      11,
      9,
      9,
      9,
      1,
      10,
      9,
      3,
      11,
      1,
      4,
      11,
      6,
      1,
      9,
      6,
      5,
      8,
      1,
      4,
      8,
      5,
      7,
      2,
      6
    ],
    "orderHold": [
      2,
      0,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      4,
      0,
      0,
      3,
      2,
      2,
      3,
      2,
      1,
      3,
      0,
      0,
      3,
      4,
      0,
      1,
      2,
      2,
      4,
      4,
      1,
      4,
      0,
      1,
      4,
      3,
      4,
      1,
      1,
      1,
      2,
      1,
      4,
      4,
      2,
      4,
      0,
      0,
      0,
      0,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      4,
      2,
      0,
      0,
      2,
      2,
      4,
      1,
      1,
      2,
      0,
      2,
      4,
      4,
      2,
      4,
      2,
      2,
      3,
      2,
      3,
      4,
      2,
      0,
      4,
      4,
      3,
      1,
      2,
      1,
      3,
      1,
      1,
      1,
      0,
      1,
      3,
      1,
      0,
      3,
      0,
      0,
      1,
      1,
      3,
      0,
      0,
      3
    ],
    "issueDelay": [
      2,
      4,
      3,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      3,
      3,
      4,
      0,
      2,
      2,
      0,
      0,
      0,
      4,
      4,
      2,
      4,
      2,
      2,
      2,
      2,
      3,
      2,
      3,
      3,
      2,
      3,
      4,
      1,
      0,
      4,
      4,
      4,
      0,
      0,
      0,
      2,
      2,
      1,
      3,
      0,
      4,
      4,
      0,
      2
    ],
    "issueQuantity": [
      3,
      1,
      4,
      2,
      2,
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      3,
      2,
      3,
      4,
      0,
      2,
      0,
      0,
      0,
      0,
      0,
      1,
      3,
      0,
      4,
      1,
      2,
      4,
      3,
      3,
      1,
      4,
      4,
      0,
      1,
      1,
      4,
      1,
      1,
      2,
      4,
      0,
      2,
      2,
      0,
      4,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000123",
    "status": "active",
    "name": "CareFusion Services",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Kevin Carroll",
    "industryRating": 4.8,
    "procedures": "[PR-039,PR-066,PR-038,PR-055,PR-022,PR-036,PR-049,PR-057,PR-061,PR-056]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000056,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000037,ORDR-1-0000050,ORDR-2-0000062,ORDR-3-0000008,ORDR-4-0000023,ORDR-5-0000061,ORDR-6-0000057,ORDR-7-0000013,ORDR-8-0000039,ORDR-9-0000046,ORDR-10-0000021,ORDR-11-0000063,ORDR-12-0000068,ORDR-13-0000038,ORDR-14-0000062,ORDR-15-0000045,ORDR-16-0000066,ORDR-17-0000060,ORDR-18-0000029]",
    "spendWeekly": [
      3097,
      2942,
      2906,
      3097,
      2903,
      3067,
      3043,
      2975,
      2928,
      2981,
      3015,
      3089,
      2979,
      2901,
      3091,
      2975,
      3068,
      3099,
      3050,
      3000,
      3041,
      3054,
      3086,
      3077,
      3095,
      2922,
      2909,
      2901,
      2971,
      3000,
      2995,
      3078,
      3068,
      2986,
      3049,
      3063,
      3100,
      2911,
      3083,
      2947,
      2967,
      3013,
      2949,
      3091,
      2983,
      2901,
      3018,
      2900,
      3100,
      2984,
      3032,
      2911
    ],
    "orderRejected": [
      2911,
      4,
      8,
      15,
      16,
      8,
      0,
      12,
      12,
      11,
      4,
      15,
      7,
      17,
      12,
      11,
      0,
      5,
      0,
      15,
      10,
      19,
      14,
      12,
      19,
      2,
      3,
      2,
      14,
      19,
      9,
      15,
      9,
      19,
      13,
      19,
      8,
      14,
      9,
      0,
      16,
      7,
      9,
      0,
      3,
      0,
      7,
      6,
      11,
      13,
      0,
      8,
      19
    ],
    "orderAccepted": [
      8,
      1,
      5,
      0,
      3,
      5,
      4,
      11,
      0,
      7,
      8,
      6,
      5,
      7,
      11,
      4,
      5,
      0,
      4,
      5,
      0,
      6,
      2,
      3,
      4,
      6,
      0,
      5,
      1,
      7,
      5,
      8,
      2,
      0,
      11,
      9,
      0,
      6,
      10,
      8,
      5,
      9,
      11,
      1,
      1,
      9,
      4,
      11,
      5,
      0,
      0,
      0
    ],
    "orderHold": [
      0,
      0,
      1,
      2,
      2,
      0,
      1,
      3,
      2,
      1,
      3,
      1,
      0,
      1,
      2,
      0,
      3,
      0,
      4,
      0,
      2,
      4,
      1,
      4,
      1,
      0,
      4,
      2,
      2,
      2,
      2,
      0,
      1,
      3,
      0,
      2,
      4,
      1,
      3,
      2,
      0,
      0,
      0,
      3,
      0,
      2,
      3,
      3,
      1,
      1,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      2,
      0,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      0,
      1,
      1,
      2,
      0,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1
    ],
    "issueQuality": [
      2,
      2,
      4,
      4,
      2,
      3,
      0,
      1,
      1,
      3,
      1,
      0,
      2,
      1,
      4,
      2,
      4,
      2,
      0,
      4,
      4,
      0,
      4,
      3,
      2,
      2,
      0,
      0,
      2,
      2,
      4,
      2,
      1,
      2,
      0,
      4,
      4,
      4,
      0,
      4,
      0,
      1,
      1,
      3,
      1,
      2,
      3,
      1,
      2,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      0,
      2,
      4,
      2,
      2,
      2,
      0,
      0,
      2,
      2,
      1,
      3,
      2,
      3,
      2,
      1,
      0,
      3,
      0,
      4,
      2,
      0,
      3,
      1,
      4,
      3,
      0,
      2,
      3,
      2,
      1,
      3,
      0,
      2,
      2,
      3,
      3,
      2,
      2,
      0,
      3,
      4,
      0,
      4,
      3,
      3,
      3,
      3,
      0,
      0,
      4
    ],
    "issueQuantity": [
      1,
      2,
      2,
      4,
      1,
      4,
      2,
      3,
      3,
      2,
      1,
      4,
      0,
      2,
      3,
      4,
      2,
      2,
      1,
      4,
      1,
      2,
      3,
      3,
      2,
      4,
      4,
      3,
      4,
      4,
      2,
      0,
      2,
      2,
      0,
      4,
      0,
      3,
      2,
      1,
      0,
      3,
      4,
      2,
      4,
      0,
      0,
      1,
      0,
      3,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000124",
    "status": "active",
    "name": "Medique Innovations",
    "tag": "Laboratory Supplies",
    "contactName": "Richard Mckinney",
    "industryRating": 3.6,
    "procedures": "[PR-037,PR-040,PR-038,PR-062,PR-043,PR-071,PR-054,PR-063,PR-070,PR-020]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000055,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000042,ORDR-1-0000001,ORDR-2-0000030,ORDR-3-0000058,ORDR-4-0000040,ORDR-5-0000054,ORDR-6-0000067,ORDR-7-0000014,ORDR-8-0000065,ORDR-9-0000010,ORDR-10-0000066,ORDR-11-0000022,ORDR-12-0000017,ORDR-13-0000015,ORDR-14-0000042,ORDR-15-0000018,ORDR-16-0000038,ORDR-17-0000062,ORDR-18-0000030]",
    "spendWeekly": [
      2950,
      2905,
      2906,
      2924,
      2911,
      3040,
      3063,
      3017,
      2995,
      3010,
      3021,
      3023,
      3085,
      3092,
      3091,
      2901,
      3047,
      3099,
      3011,
      3067,
      3048,
      2995,
      3097,
      2935,
      2949,
      3040,
      3095,
      3050,
      2933,
      3001,
      3021,
      3020,
      3001,
      2969,
      2984,
      2969,
      2915,
      2925,
      2990,
      2902,
      3006,
      2947,
      3003,
      2975,
      2904,
      2921,
      2995,
      2900,
      3011,
      2964,
      3022,
      3052
    ],
    "orderRejected": [
      3052,
      19,
      16,
      9,
      0,
      11,
      18,
      0,
      19,
      11,
      14,
      3,
      8,
      16,
      19,
      9,
      11,
      8,
      11,
      19,
      5,
      12,
      7,
      14,
      15,
      13,
      0,
      8,
      18,
      17,
      15,
      17,
      10,
      5,
      15,
      6,
      19,
      10,
      9,
      7,
      8,
      19,
      16,
      0,
      7,
      5,
      3,
      18,
      0,
      13,
      18,
      14,
      3
    ],
    "orderAccepted": [
      4,
      4,
      6,
      6,
      6,
      9,
      11,
      6,
      0,
      1,
      4,
      7,
      5,
      0,
      2,
      3,
      7,
      10,
      6,
      7,
      10,
      11,
      5,
      4,
      5,
      11,
      1,
      5,
      9,
      6,
      1,
      10,
      3,
      0,
      0,
      9,
      11,
      2,
      11,
      10,
      1,
      8,
      7,
      1,
      0,
      11,
      8,
      3,
      2,
      3,
      0,
      8
    ],
    "orderHold": [
      1,
      1,
      2,
      4,
      4,
      1,
      0,
      0,
      2,
      2,
      4,
      2,
      1,
      3,
      0,
      2,
      1,
      0,
      3,
      2,
      2,
      4,
      2,
      3,
      0,
      3,
      2,
      2,
      1,
      1,
      3,
      3,
      1,
      0,
      4,
      1,
      3,
      1,
      4,
      4,
      1,
      0,
      4,
      2,
      2,
      3,
      2,
      2,
      0,
      0,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      0,
      0,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      2,
      4,
      2,
      0,
      2,
      3,
      4,
      2,
      1,
      0,
      3,
      2,
      0,
      2,
      1,
      2,
      2,
      2,
      3,
      2,
      3,
      2,
      3,
      0,
      4,
      4,
      0,
      1,
      0,
      2,
      0,
      4,
      1,
      0,
      0,
      1,
      0,
      1,
      2,
      1,
      3,
      3,
      3,
      1,
      0,
      1,
      1,
      4,
      2,
      0,
      3
    ],
    "issueDelay": [
      1,
      4,
      0,
      1,
      0,
      4,
      2,
      0,
      2,
      2,
      2,
      0,
      2,
      1,
      2,
      4,
      2,
      0,
      3,
      4,
      1,
      2,
      2,
      2,
      2,
      1,
      4,
      0,
      0,
      4,
      2,
      3,
      2,
      2,
      4,
      1,
      1,
      3,
      4,
      1,
      1,
      0,
      0,
      3,
      2,
      3,
      0,
      0,
      3,
      0,
      2,
      3
    ],
    "issueQuantity": [
      4,
      0,
      2,
      0,
      2,
      4,
      3,
      0,
      0,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      4,
      0,
      3,
      1,
      0,
      0,
      3,
      0,
      0,
      4,
      2,
      2,
      2,
      4,
      4,
      0,
      0,
      3,
      4,
      3,
      0,
      4,
      4,
      1,
      3,
      3,
      2,
      4,
      3,
      3,
      3,
      1,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000125",
    "status": "active",
    "name": "BioHealth Systems",
    "tag": "Imaging, Pharmaceuticals, Facilities Management",
    "contactName": "Kimberly Griffin",
    "industryRating": 4.4,
    "procedures": "[PR-053,PR-061,PR-066,PR-064,PR-070,PR-070,PR-067,PR-070,PR-070,PR-074]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000016,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000049,ORDR-1-0000020,ORDR-2-0000025,ORDR-3-0000034,ORDR-4-0000040,ORDR-5-0000038,ORDR-6-0000009,ORDR-7-0000067,ORDR-8-0000051,ORDR-9-0000066,ORDR-10-0000028,ORDR-11-0000066,ORDR-12-0000075,ORDR-13-0000059,ORDR-14-0000017,ORDR-15-0000058,ORDR-16-0000045,ORDR-17-0000078,ORDR-18-0000080]",
    "spendWeekly": [
      3034,
      2950,
      2914,
      3007,
      2949,
      3082,
      3067,
      3098,
      2993,
      2941,
      2950,
      3004,
      2985,
      3012,
      3091,
      2914,
      2955,
      3099,
      2986,
      2909,
      2903,
      3063,
      2976,
      2930,
      3013,
      3096,
      3003,
      2911,
      3037,
      2924,
      3067,
      3015,
      3071,
      3093,
      3095,
      3019,
      2975,
      3005,
      2997,
      3099,
      2901,
      2952,
      3097,
      2985,
      2902,
      2901,
      2979,
      2982,
      3038,
      3097,
      2905,
      3063
    ],
    "orderRejected": [
      3063,
      19,
      15,
      10,
      19,
      13,
      9,
      5,
      19,
      8,
      0,
      8,
      9,
      5,
      8,
      19,
      9,
      15,
      15,
      5,
      0,
      19,
      6,
      12,
      9,
      12,
      5,
      0,
      14,
      19,
      12,
      15,
      9,
      19,
      17,
      19,
      0,
      13,
      19,
      6,
      0,
      19,
      3,
      0,
      7,
      6,
      3,
      12,
      0,
      7,
      19,
      14,
      0
    ],
    "orderAccepted": [
      9,
      11,
      0,
      9,
      8,
      2,
      10,
      4,
      2,
      3,
      0,
      0,
      2,
      11,
      9,
      5,
      0,
      2,
      0,
      5,
      3,
      7,
      3,
      4,
      8,
      11,
      1,
      11,
      5,
      8,
      8,
      9,
      0,
      0,
      10,
      8,
      1,
      3,
      8,
      9,
      11,
      6,
      7,
      4,
      7,
      9,
      8,
      5,
      6,
      9,
      7,
      8
    ],
    "orderHold": [
      0,
      2,
      4,
      0,
      4,
      2,
      2,
      4,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      3,
      2,
      0,
      1,
      0,
      0,
      4,
      3,
      3,
      2,
      1,
      1,
      0,
      3,
      3,
      4,
      1,
      1,
      0,
      0,
      1,
      4,
      2,
      4,
      1,
      2,
      3,
      1,
      3,
      0,
      4,
      0,
      2,
      3,
      0,
      0,
      0
    ],
    "orderBackordered": [
      0,
      2,
      1,
      0,
      0,
      0,
      1,
      2,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      3,
      2,
      0,
      3,
      4,
      1,
      2,
      4,
      2,
      3,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      3,
      4,
      3,
      3,
      4,
      2,
      2,
      3,
      2,
      4,
      1,
      0,
      4,
      1,
      1,
      0,
      3,
      4,
      3,
      4,
      1,
      3,
      2,
      4,
      4,
      3,
      2,
      4,
      3,
      2
    ],
    "issueDelay": [
      2,
      1,
      4,
      1,
      0,
      4,
      1,
      2,
      0,
      3,
      0,
      4,
      3,
      3,
      3,
      1,
      1,
      1,
      2,
      0,
      2,
      4,
      2,
      2,
      0,
      2,
      3,
      0,
      3,
      0,
      3,
      4,
      4,
      1,
      2,
      2,
      1,
      4,
      2,
      4,
      4,
      1,
      4,
      4,
      4,
      3,
      0,
      0,
      4,
      2,
      1,
      2
    ],
    "issueQuantity": [
      0,
      2,
      1,
      0,
      3,
      3,
      1,
      2,
      4,
      2,
      2,
      4,
      0,
      1,
      4,
      1,
      1,
      2,
      0,
      4,
      1,
      3,
      4,
      0,
      0,
      2,
      2,
      3,
      4,
      4,
      4,
      3,
      3,
      1,
      1,
      3,
      4,
      0,
      0,
      2,
      4,
      3,
      4,
      0,
      3,
      3,
      4,
      0,
      1,
      2,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000126",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "Nutrition, Imaging, Equipment",
    "contactName": "Nicholas Davis",
    "industryRating": 3,
    "procedures": "[PR-066,PR-065,PR-055,PR-040,PR-044,PR-039,PR-056,PR-055,PR-072,PR-051]",
    "contracts": "[CNTRCT-0000026,CNTRCT-0000066,CNTRCT-0000017]",
    "orders": "[ORDR-0-0000051,ORDR-1-0000035,ORDR-2-0000058,ORDR-3-0000066,ORDR-4-0000055,ORDR-5-0000067,ORDR-6-0000010,ORDR-7-0000039,ORDR-8-0000054,ORDR-9-0000045,ORDR-10-0000053,ORDR-11-0000057,ORDR-12-0000044,ORDR-13-0000025,ORDR-14-0000021,ORDR-15-0000042,ORDR-16-0000032,ORDR-17-0000020,ORDR-18-0000019]",
    "spendWeekly": [
      2928,
      3082,
      2937,
      2932,
      2903,
      2905,
      3018,
      2987,
      2922,
      3099,
      3100,
      2978,
      3002,
      3094,
      2987,
      2901,
      3012,
      3028,
      2951,
      3036,
      3100,
      3097,
      2900,
      2906,
      2914,
      3081,
      3092,
      3078,
      3025,
      2981,
      2936,
      2992,
      2900,
      2961,
      3034,
      2904,
      2907,
      2995,
      2906,
      2978,
      3099,
      2986,
      3085,
      3039,
      2902,
      2955,
      3099,
      2991,
      2978,
      2983,
      2972,
      3087
    ],
    "orderRejected": [
      3087,
      11,
      6,
      7,
      18,
      19,
      4,
      18,
      16,
      16,
      13,
      5,
      10,
      12,
      16,
      13,
      19,
      6,
      0,
      0,
      0,
      0,
      8,
      8,
      6,
      9,
      8,
      9,
      15,
      8,
      19,
      13,
      19,
      10,
      19,
      11,
      0,
      15,
      16,
      7,
      9,
      15,
      13,
      14,
      11,
      9,
      8,
      18,
      12,
      2,
      11,
      16,
      19
    ],
    "orderAccepted": [
      6,
      7,
      5,
      0,
      3,
      0,
      11,
      8,
      10,
      0,
      7,
      10,
      3,
      0,
      6,
      6,
      6,
      9,
      9,
      10,
      5,
      9,
      0,
      10,
      5,
      7,
      0,
      7,
      9,
      0,
      9,
      11,
      0,
      0,
      1,
      5,
      7,
      10,
      9,
      6,
      5,
      11,
      0,
      1,
      4,
      5,
      0,
      7,
      11,
      10,
      0,
      3
    ],
    "orderHold": [
      2,
      0,
      0,
      2,
      3,
      4,
      3,
      2,
      2,
      3,
      2,
      1,
      2,
      4,
      0,
      1,
      2,
      1,
      3,
      1,
      0,
      1,
      0,
      4,
      0,
      4,
      1,
      2,
      1,
      4,
      4,
      3,
      0,
      2,
      1,
      3,
      0,
      4,
      4,
      3,
      4,
      0,
      0,
      1,
      2,
      4,
      0,
      4,
      0,
      1,
      2,
      1
    ],
    "orderBackordered": [
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      0,
      0,
      1,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      2,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      1,
      3,
      3,
      0,
      1,
      1,
      2,
      3,
      3,
      4,
      1,
      4,
      4,
      2,
      3,
      0,
      2,
      2,
      1,
      4,
      1,
      1,
      4,
      2,
      4,
      3,
      4,
      4,
      2,
      3,
      2,
      4,
      3,
      1,
      0,
      2,
      1,
      0,
      4,
      0,
      0,
      1,
      2,
      4,
      4,
      0,
      4,
      4,
      3,
      1,
      4
    ],
    "issueDelay": [
      0,
      4,
      4,
      0,
      1,
      2,
      4,
      3,
      3,
      1,
      4,
      2,
      3,
      2,
      0,
      3,
      2,
      0,
      3,
      3,
      0,
      0,
      0,
      3,
      0,
      3,
      4,
      2,
      4,
      3,
      4,
      4,
      0,
      0,
      0,
      2,
      0,
      2,
      1,
      0,
      4,
      1,
      2,
      0,
      0,
      3,
      2,
      0,
      4,
      4,
      1,
      3
    ],
    "issueQuantity": [
      1,
      3,
      3,
      0,
      2,
      4,
      1,
      0,
      3,
      1,
      2,
      4,
      0,
      2,
      0,
      3,
      3,
      3,
      1,
      4,
      4,
      3,
      2,
      3,
      2,
      0,
      2,
      3,
      4,
      3,
      3,
      1,
      1,
      2,
      3,
      3,
      4,
      0,
      1,
      4,
      0,
      1,
      1,
      3,
      1,
      0,
      2,
      0,
      3,
      3,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000127",
    "status": "active",
    "name": "HealthWave Technologies",
    "tag": "IT Services",
    "contactName": "Julie Sanders",
    "industryRating": 3.3,
    "procedures": "[PR-047,PR-043,PR-049,PR-062,PR-069,PR-058,PR-030,PR-052,PR-073,PR-065]",
    "contracts": "[CNTRCT-0000012,CNTRCT-0000030,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000032,ORDR-1-0000063,ORDR-2-0000034,ORDR-3-0000059,ORDR-4-0000039,ORDR-5-0000016,ORDR-6-0000027,ORDR-7-0000063,ORDR-8-0000052,ORDR-9-0000012,ORDR-10-0000055,ORDR-11-0000032,ORDR-12-0000064,ORDR-13-0000038,ORDR-14-0000036,ORDR-15-0000047,ORDR-16-0000054,ORDR-17-0000023,ORDR-18-0000027]",
    "spendWeekly": [
      2901,
      2902,
      2906,
      2957,
      2903,
      2986,
      3078,
      3098,
      2902,
      2902,
      3004,
      2966,
      2909,
      2980,
      3077,
      2901,
      3036,
      3099,
      2921,
      3085,
      3009,
      3071,
      2971,
      2937,
      2908,
      3049,
      3095,
      2901,
      3048,
      3004,
      2932,
      3003,
      3087,
      2988,
      3073,
      2904,
      2965,
      2925,
      2995,
      2901,
      3012,
      3038,
      3094,
      3050,
      2902,
      3028,
      2991,
      3035,
      2901,
      2993,
      2957,
      3005
    ],
    "orderRejected": [
      3005,
      9,
      19,
      3,
      0,
      15,
      19,
      1,
      16,
      19,
      11,
      19,
      9,
      4,
      17,
      3,
      14,
      5,
      0,
      17,
      0,
      19,
      11,
      11,
      9,
      1,
      6,
      0,
      19,
      5,
      19,
      7,
      6,
      19,
      19,
      10,
      4,
      7,
      19,
      7,
      6,
      13,
      6,
      0,
      4,
      8,
      5,
      5,
      0,
      10,
      15,
      1,
      13
    ],
    "orderAccepted": [
      5,
      11,
      2,
      10,
      1,
      3,
      0,
      8,
      1,
      6,
      3,
      2,
      1,
      11,
      11,
      11,
      3,
      5,
      0,
      6,
      9,
      9,
      11,
      3,
      4,
      7,
      0,
      11,
      11,
      6,
      8,
      9,
      0,
      0,
      11,
      7,
      2,
      3,
      11,
      11,
      5,
      5,
      8,
      5,
      0,
      7,
      0,
      5,
      5,
      11,
      1,
      5
    ],
    "orderHold": [
      3,
      1,
      4,
      1,
      2,
      1,
      4,
      4,
      3,
      0,
      3,
      0,
      0,
      3,
      4,
      0,
      4,
      0,
      4,
      3,
      0,
      4,
      4,
      3,
      0,
      1,
      0,
      2,
      4,
      0,
      3,
      4,
      4,
      2,
      0,
      2,
      0,
      4,
      4,
      0,
      2,
      1,
      4,
      3,
      2,
      3,
      1,
      0,
      0,
      1,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      0,
      0,
      4,
      3,
      0,
      4,
      1,
      2,
      2,
      4,
      0,
      2,
      0,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      0,
      2,
      3,
      2,
      0,
      4,
      3,
      2,
      3,
      4,
      2,
      3,
      0,
      0,
      3,
      3,
      1,
      1,
      2,
      4,
      0,
      3,
      0,
      3,
      4,
      2,
      0,
      1,
      1,
      0,
      3,
      4
    ],
    "issueDelay": [
      2,
      1,
      3,
      1,
      2,
      1,
      4,
      0,
      1,
      3,
      4,
      4,
      3,
      0,
      4,
      4,
      1,
      0,
      1,
      1,
      1,
      0,
      4,
      3,
      0,
      4,
      4,
      0,
      3,
      4,
      4,
      2,
      2,
      0,
      3,
      4,
      2,
      3,
      4,
      4,
      2,
      4,
      4,
      4,
      4,
      4,
      0,
      0,
      0,
      0,
      0,
      2
    ],
    "issueQuantity": [
      0,
      1,
      2,
      0,
      3,
      1,
      4,
      2,
      2,
      0,
      4,
      2,
      0,
      3,
      0,
      1,
      2,
      1,
      0,
      4,
      3,
      3,
      0,
      3,
      2,
      4,
      1,
      0,
      4,
      1,
      0,
      4,
      4,
      3,
      0,
      3,
      4,
      0,
      2,
      4,
      3,
      4,
      4,
      3,
      0,
      4,
      3,
      0,
      0,
      2,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000128",
    "status": "active",
    "name": "WellnessWare Group",
    "tag": "Imaging",
    "contactName": "Robert Adams",
    "industryRating": 3.4,
    "procedures": "[PR-055,PR-063,PR-021,PR-045,PR-065,PR-018,PR-045,PR-062,PR-063,PR-072]",
    "contracts": "[CNTRCT-0000059,CNTRCT-0000055,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000018,ORDR-2-0000022,ORDR-3-0000012,ORDR-4-0000025,ORDR-5-0000034,ORDR-6-0000018,ORDR-7-0000044,ORDR-8-0000054,ORDR-9-0000008,ORDR-10-0000025,ORDR-11-0000060,ORDR-12-0000049,ORDR-13-0000036,ORDR-14-0000032,ORDR-15-0000060,ORDR-16-0000066,ORDR-17-0000076,ORDR-18-0000050]",
    "spendWeekly": [
      3009,
      2910,
      2978,
      2924,
      2971,
      3056,
      2967,
      3098,
      3076,
      2977,
      3100,
      3044,
      2932,
      2901,
      3091,
      2944,
      2993,
      2985,
      3043,
      2972,
      2996,
      3097,
      3024,
      2913,
      3095,
      3096,
      3001,
      2938,
      3082,
      3059,
      2926,
      3097,
      3099,
      2904,
      3085,
      3057,
      2958,
      3015,
      2906,
      2996,
      3099,
      3048,
      2944,
      2991,
      2927,
      2901,
      2990,
      2956,
      3100,
      3077,
      2957,
      2985
    ],
    "orderRejected": [
      2985,
      11,
      3,
      9,
      0,
      10,
      13,
      6,
      19,
      17,
      9,
      19,
      19,
      9,
      10,
      16,
      16,
      4,
      9,
      17,
      6,
      11,
      3,
      19,
      12,
      14,
      4,
      0,
      10,
      10,
      13,
      12,
      19,
      11,
      2,
      19,
      0,
      9,
      9,
      9,
      3,
      16,
      18,
      9,
      5,
      10,
      0,
      12,
      18,
      19,
      8,
      15,
      19
    ],
    "orderAccepted": [
      6,
      6,
      0,
      10,
      7,
      11,
      7,
      5,
      1,
      6,
      8,
      6,
      0,
      7,
      11,
      0,
      11,
      10,
      3,
      0,
      1,
      11,
      4,
      0,
      11,
      11,
      0,
      11,
      5,
      2,
      8,
      5,
      0,
      0,
      0,
      11,
      8,
      7,
      10,
      5,
      7,
      7,
      4,
      4,
      0,
      8,
      0,
      11,
      1,
      7,
      0,
      5
    ],
    "orderHold": [
      0,
      3,
      2,
      1,
      2,
      3,
      0,
      2,
      0,
      4,
      0,
      0,
      0,
      3,
      0,
      2,
      4,
      0,
      2,
      1,
      0,
      4,
      1,
      4,
      2,
      2,
      4,
      2,
      2,
      4,
      1,
      4,
      4,
      1,
      0,
      1,
      1,
      0,
      4,
      0,
      0,
      4,
      4,
      2,
      1,
      4,
      3,
      2,
      0,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      0,
      4,
      3,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      4,
      1,
      0,
      2,
      2,
      0,
      2,
      3,
      4,
      4,
      3,
      0,
      2,
      4,
      4,
      0,
      0,
      2,
      1,
      3,
      1,
      3,
      2,
      4,
      4,
      4,
      0,
      4,
      4,
      4,
      1,
      4,
      1,
      2,
      3,
      0,
      2
    ],
    "issueDelay": [
      4,
      3,
      3,
      1,
      2,
      4,
      1,
      0,
      3,
      0,
      3,
      2,
      4,
      4,
      4,
      1,
      4,
      2,
      1,
      1,
      4,
      4,
      0,
      0,
      0,
      1,
      1,
      0,
      3,
      2,
      4,
      4,
      4,
      2,
      2,
      1,
      1,
      4,
      4,
      1,
      2,
      0,
      4,
      2,
      3,
      1,
      0,
      4,
      4,
      0,
      3,
      4
    ],
    "issueQuantity": [
      4,
      0,
      2,
      0,
      1,
      3,
      2,
      2,
      2,
      3,
      2,
      0,
      1,
      3,
      2,
      4,
      0,
      3,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      2,
      1,
      2,
      0,
      4,
      0,
      0,
      3,
      4,
      0,
      4,
      4,
      0,
      3,
      4,
      4,
      0,
      4,
      0,
      1,
      3,
      3,
      4,
      0,
      3,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000129",
    "status": "active",
    "name": "MedPulse Supply Network",
    "tag": "Imaging",
    "contactName": "Jennifer Harris",
    "industryRating": 3.8,
    "procedures": "[PR-016,PR-044,PR-061,PR-055,PR-063,PR-051,PR-061,PR-045,PR-056,PR-061]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000021,CNTRCT-0000065]",
    "orders": "[ORDR-0-0000032,ORDR-1-0000061,ORDR-2-0000062,ORDR-3-0000035,ORDR-4-0000017,ORDR-5-0000004,ORDR-6-0000032,ORDR-7-0000053,ORDR-8-0000033,ORDR-9-0000027,ORDR-10-0000028,ORDR-11-0000052,ORDR-12-0000014,ORDR-13-0000037,ORDR-14-0000023,ORDR-15-0000018,ORDR-16-0000015,ORDR-17-0000046,ORDR-18-0000049]",
    "spendWeekly": [
      3079,
      3082,
      2950,
      2902,
      3046,
      2905,
      3072,
      3098,
      3100,
      2915,
      3008,
      2945,
      2966,
      2996,
      3024,
      3094,
      3007,
      3032,
      2900,
      3004,
      3100,
      3091,
      3071,
      2991,
      3070,
      2901,
      3044,
      2901,
      3096,
      2902,
      2903,
      3091,
      2962,
      3030,
      3035,
      2926,
      2922,
      2960,
      3044,
      3059,
      3061,
      2973,
      2989,
      3033,
      2902,
      2901,
      3014,
      2900,
      2922,
      2912,
      2963,
      2901
    ],
    "orderRejected": [
      2901,
      12,
      9,
      13,
      8,
      19,
      11,
      2,
      0,
      4,
      19,
      11,
      18,
      18,
      12,
      15,
      19,
      6,
      10,
      11,
      13,
      19,
      9,
      8,
      13,
      12,
      14,
      2,
      17,
      19,
      13,
      19,
      9,
      8,
      19,
      11,
      4,
      15,
      5,
      13,
      11,
      16,
      11,
      0,
      5,
      6,
      5,
      9,
      12,
      19,
      0,
      19,
      14
    ],
    "orderAccepted": [
      9,
      0,
      8,
      11,
      7,
      3,
      10,
      0,
      10,
      4,
      11,
      1,
      0,
      0,
      11,
      4,
      2,
      6,
      6,
      4,
      5,
      4,
      11,
      5,
      11,
      10,
      4,
      0,
      6,
      0,
      7,
      8,
      1,
      0,
      3,
      10,
      7,
      10,
      1,
      9,
      1,
      5,
      11,
      4,
      3,
      11,
      1,
      8,
      8,
      7,
      6,
      4
    ],
    "orderHold": [
      1,
      3,
      2,
      4,
      3,
      2,
      1,
      1,
      0,
      2,
      4,
      0,
      1,
      0,
      0,
      0,
      1,
      3,
      4,
      2,
      2,
      0,
      2,
      3,
      2,
      2,
      4,
      2,
      4,
      0,
      4,
      4,
      0,
      0,
      0,
      3,
      1,
      0,
      0,
      2,
      3,
      0,
      4,
      3,
      4,
      4,
      1,
      3,
      0,
      2,
      3,
      3
    ],
    "orderBackordered": [
      3,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      0,
      2,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      4,
      3,
      0,
      3,
      1,
      3,
      0,
      4,
      4,
      2,
      4,
      0,
      4,
      0,
      3,
      3,
      2,
      4,
      0,
      1,
      3,
      2,
      1,
      3,
      3,
      2,
      4,
      1,
      0,
      4,
      2,
      0,
      1,
      1,
      0,
      0,
      4,
      0,
      0,
      1,
      1,
      4,
      0,
      0,
      2,
      3,
      4,
      2,
      0,
      4
    ],
    "issueDelay": [
      0,
      3,
      2,
      4,
      2,
      3,
      1,
      0,
      2,
      1,
      3,
      1,
      4,
      3,
      0,
      2,
      2,
      2,
      1,
      2,
      0,
      2,
      4,
      3,
      0,
      4,
      0,
      0,
      1,
      3,
      4,
      4,
      0,
      1,
      4,
      1,
      2,
      3,
      1,
      4,
      0,
      1,
      0,
      0,
      2,
      3,
      0,
      4,
      2,
      1,
      3,
      3
    ],
    "issueQuantity": [
      4,
      1,
      2,
      1,
      0,
      0,
      4,
      1,
      4,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      3,
      2,
      2,
      0,
      2,
      3,
      3,
      3,
      4,
      3,
      1,
      2,
      0,
      4,
      3,
      0,
      2,
      1,
      0,
      2,
      4,
      4,
      4,
      2,
      0,
      4,
      4,
      4,
      4,
      1,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000130",
    "status": "active",
    "name": "CarePoint Technologies",
    "tag": "Equipment, Surgical Instruments, IT Services",
    "contactName": "Luis Mccarthy",
    "industryRating": 3.1,
    "procedures": "[PR-038,PR-046,PR-068,PR-067,PR-064,PR-070,PR-034,PR-061,PR-074,PR-040]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000058,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000012,ORDR-1-0000024,ORDR-2-0000032,ORDR-3-0000046,ORDR-4-0000050,ORDR-5-0000009,ORDR-6-0000064,ORDR-7-0000050,ORDR-8-0000033,ORDR-9-0000024,ORDR-10-0000068,ORDR-11-0000071,ORDR-12-0000058,ORDR-13-0000024,ORDR-14-0000036,ORDR-15-0000071,ORDR-16-0000028,ORDR-17-0000066,ORDR-18-0000036]",
    "spendWeekly": [
      3005,
      3043,
      2975,
      3079,
      2980,
      2905,
      3032,
      3045,
      2902,
      2919,
      2982,
      3030,
      2909,
      2970,
      3091,
      2901,
      2907,
      2945,
      2995,
      3006,
      2955,
      3097,
      3000,
      3097,
      3073,
      2926,
      3004,
      3028,
      3046,
      2949,
      2903,
      2998,
      3044,
      2994,
      3094,
      3061,
      3095,
      2944,
      3091,
      2993,
      2952,
      2957,
      3037,
      3091,
      2987,
      2901,
      3024,
      3004,
      3100,
      2905,
      2905,
      2941
    ],
    "orderRejected": [
      2941,
      13,
      6,
      18,
      0,
      19,
      9,
      15,
      14,
      19,
      19,
      2,
      8,
      0,
      5,
      10,
      13,
      3,
      8,
      2,
      16,
      12,
      12,
      7,
      16,
      0,
      0,
      10,
      19,
      16,
      15,
      12,
      1,
      19,
      13,
      5,
      13,
      19,
      19,
      1,
      10,
      19,
      12,
      1,
      9,
      13,
      9,
      5,
      0,
      9,
      10,
      1,
      12
    ],
    "orderAccepted": [
      2,
      5,
      4,
      4,
      6,
      7,
      6,
      5,
      7,
      3,
      3,
      0,
      4,
      10,
      7,
      7,
      4,
      0,
      0,
      8,
      4,
      0,
      3,
      1,
      6,
      11,
      7,
      11,
      5,
      11,
      4,
      11,
      1,
      3,
      7,
      11,
      11,
      6,
      11,
      7,
      3,
      8,
      3,
      2,
      0,
      7,
      9,
      8,
      5,
      9,
      7,
      6
    ],
    "orderHold": [
      1,
      0,
      4,
      1,
      4,
      4,
      3,
      4,
      4,
      4,
      2,
      1,
      4,
      2,
      2,
      2,
      2,
      0,
      4,
      2,
      4,
      4,
      3,
      2,
      0,
      3,
      3,
      0,
      2,
      0,
      2,
      1,
      4,
      3,
      0,
      2,
      3,
      4,
      2,
      2,
      0,
      2,
      0,
      4,
      3,
      4,
      0,
      0,
      0,
      1,
      0,
      1
    ],
    "orderBackordered": [
      1,
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      0,
      2,
      2,
      2,
      0,
      0,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      3,
      0,
      2,
      4,
      2,
      3,
      2,
      2,
      4,
      4,
      2,
      0,
      3,
      2,
      0,
      0,
      3,
      4,
      0,
      2,
      0,
      1,
      3,
      1,
      0,
      4,
      2,
      1,
      2,
      4,
      3,
      3,
      1,
      3,
      1,
      4,
      0,
      0,
      0,
      4,
      1,
      2,
      2,
      3,
      1,
      4,
      0,
      1,
      4,
      1,
      2,
      4
    ],
    "issueDelay": [
      2,
      2,
      4,
      0,
      0,
      1,
      2,
      0,
      2,
      1,
      4,
      2,
      1,
      0,
      2,
      4,
      2,
      1,
      3,
      1,
      2,
      3,
      4,
      0,
      3,
      2,
      4,
      1,
      2,
      0,
      4,
      1,
      0,
      4,
      1,
      2,
      0,
      2,
      2,
      3,
      4,
      4,
      4,
      3,
      2,
      4,
      1,
      0,
      3,
      0,
      0,
      2
    ],
    "issueQuantity": [
      1,
      4,
      4,
      2,
      3,
      2,
      0,
      2,
      2,
      0,
      2,
      0,
      0,
      4,
      2,
      2,
      2,
      3,
      1,
      3,
      4,
      0,
      3,
      4,
      3,
      3,
      2,
      4,
      1,
      0,
      4,
      3,
      0,
      2,
      0,
      1,
      3,
      3,
      1,
      2,
      4,
      2,
      3,
      3,
      0,
      3,
      0,
      4,
      3,
      2,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000131",
    "status": "active",
    "name": "LifeLine Supplies",
    "tag": "Surgical Instruments, Pharmaceuticals",
    "contactName": "Aaron Howard",
    "industryRating": 3,
    "procedures": "[PR-060,PR-065,PR-049,PR-066,PR-033,PR-061,PR-052,PR-061,PR-064,PR-062]",
    "contracts": "[CNTRCT-0000042,CNTRCT-0000064,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000020,ORDR-1-0000036,ORDR-2-0000044,ORDR-3-0000043,ORDR-4-0000034,ORDR-5-0000009,ORDR-6-0000067,ORDR-7-0000032,ORDR-8-0000065,ORDR-9-0000071,ORDR-10-0000055,ORDR-11-0000058,ORDR-12-0000032,ORDR-13-0000037,ORDR-14-0000020,ORDR-15-0000036,ORDR-16-0000057,ORDR-17-0000051,ORDR-18-0000074]",
    "spendWeekly": [
      3068,
      3002,
      2906,
      2907,
      2975,
      2933,
      2992,
      2908,
      2930,
      2902,
      3100,
      3010,
      3001,
      3058,
      3047,
      2919,
      2966,
      3099,
      2958,
      3062,
      3100,
      3064,
      3007,
      2903,
      3095,
      3075,
      2969,
      3014,
      2937,
      2902,
      2926,
      2957,
      3075,
      2904,
      3092,
      3100,
      2967,
      2948,
      3099,
      2961,
      3029,
      2900,
      3051,
      2985,
      3060,
      2974,
      2901,
      2900,
      2992,
      2921,
      2965,
      2985
    ],
    "orderRejected": [
      2985,
      19,
      13,
      16,
      12,
      19,
      0,
      19,
      3,
      14,
      0,
      5,
      13,
      15,
      9,
      11,
      7,
      0,
      0,
      0,
      0,
      11,
      5,
      15,
      6,
      18,
      15,
      0,
      15,
      19,
      5,
      19,
      19,
      16,
      5,
      0,
      0,
      12,
      11,
      10,
      0,
      19,
      7,
      0,
      19,
      7,
      0,
      9,
      0,
      15,
      11,
      11,
      3
    ],
    "orderAccepted": [
      11,
      8,
      1,
      3,
      8,
      11,
      2,
      6,
      4,
      0,
      6,
      7,
      1,
      0,
      11,
      11,
      0,
      8,
      10,
      1,
      0,
      1,
      0,
      4,
      0,
      7,
      0,
      11,
      11,
      6,
      9,
      11,
      0,
      0,
      3,
      9,
      7,
      0,
      11,
      2,
      0,
      11,
      9,
      3,
      9,
      8,
      0,
      2,
      4,
      5,
      6,
      10
    ],
    "orderHold": [
      0,
      2,
      2,
      1,
      1,
      0,
      3,
      1,
      0,
      0,
      4,
      1,
      1,
      2,
      3,
      3,
      2,
      0,
      4,
      0,
      0,
      4,
      4,
      0,
      1,
      2,
      2,
      2,
      3,
      3,
      2,
      4,
      0,
      0,
      0,
      2,
      4,
      0,
      3,
      2,
      3,
      0,
      1,
      3,
      0,
      3,
      0,
      1,
      1,
      2,
      4,
      2
    ],
    "orderBackordered": [
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      1,
      3,
      2,
      0,
      3,
      1,
      4,
      2,
      2,
      3,
      0,
      1,
      1,
      1,
      3,
      4,
      2,
      1,
      4,
      1,
      1,
      0,
      4,
      0,
      4,
      4,
      3,
      4,
      2,
      3,
      0,
      1,
      4,
      3,
      2,
      3,
      4,
      2,
      3,
      3,
      4,
      1,
      4,
      2,
      3,
      3,
      0,
      1,
      0,
      0,
      4
    ],
    "issueDelay": [
      0,
      1,
      4,
      4,
      2,
      4,
      0,
      3,
      2,
      3,
      0,
      4,
      4,
      1,
      0,
      0,
      4,
      1,
      1,
      4,
      2,
      1,
      0,
      2,
      0,
      4,
      4,
      0,
      2,
      3,
      3,
      4,
      1,
      1,
      0,
      4,
      0,
      0,
      4,
      1,
      0,
      1,
      0,
      1,
      3,
      0,
      1,
      0,
      3,
      1,
      4,
      3
    ],
    "issueQuantity": [
      3,
      0,
      0,
      1,
      0,
      0,
      4,
      3,
      4,
      2,
      1,
      3,
      0,
      0,
      2,
      2,
      2,
      2,
      0,
      1,
      1,
      1,
      4,
      2,
      1,
      4,
      1,
      0,
      3,
      4,
      3,
      4,
      3,
      3,
      0,
      4,
      4,
      0,
      1,
      4,
      4,
      3,
      4,
      0,
      4,
      0,
      0,
      0,
      0,
      2,
      1,
      3
    ]
  },
  {
    "id": "VNDR-0000132",
    "status": "inactive",
    "name": "CareBridge Equipment",
    "tag": "Equipment, IT Services",
    "contactName": "Samantha Patel",
    "industryRating": 3.8,
    "procedures": "[PR-016,PR-061,PR-060,PR-052,PR-012,PR-069,PR-036,PR-046,PR-052,PR-048]",
    "contracts": "[CNTRCT-0000030,CNTRCT-0000062,CNTRCT-0000033]",
    "orders": "[ORDR-0-0000032,ORDR-1-0000030,ORDR-2-0000066,ORDR-3-0000046,ORDR-4-0000039,ORDR-5-0000010,ORDR-6-0000040,ORDR-7-0000050,ORDR-8-0000015,ORDR-9-0000023,ORDR-10-0000028,ORDR-11-0000028,ORDR-12-0000037,ORDR-13-0000058,ORDR-14-0000023,ORDR-15-0000051,ORDR-16-0000027,ORDR-17-0000038,ORDR-18-0000066]",
    "spendWeekly": [
      2915,
      2988,
      3013,
      2914,
      2903,
      2942,
      3067,
      3006,
      2963,
      2902,
      3054,
      2968,
      2979,
      3078,
      2997,
      2947,
      2979,
      3039,
      3099,
      3040,
      3054,
      3078,
      3036,
      2906,
      3075,
      3078,
      3045,
      3016,
      3044,
      3046,
      3033,
      3094,
      3021,
      3000,
      2925,
      2997,
      2989,
      2966,
      2941,
      2966,
      2936,
      3023,
      3051,
      3006,
      3076,
      2987,
      3033,
      3032,
      3063,
      2930,
      3078,
      3053
    ],
    "orderRejected": [
      3053,
      19,
      10,
      10,
      8,
      13,
      12,
      15,
      0,
      14,
      7,
      8,
      16,
      18,
      18,
      11,
      9,
      11,
      5,
      14,
      12,
      15,
      15,
      6,
      5,
      5,
      6,
      16,
      4,
      7,
      6,
      18,
      15,
      6,
      11,
      15,
      5,
      16,
      11,
      17,
      15,
      10,
      15,
      5,
      3,
      12,
      16,
      11,
      3,
      16,
      9,
      14,
      19
    ],
    "orderAccepted": [
      1,
      0,
      2,
      3,
      4,
      5,
      4,
      5,
      8,
      11,
      8,
      7,
      3,
      1,
      4,
      8,
      9,
      11,
      6,
      6,
      3,
      7,
      0,
      7,
      11,
      11,
      0,
      1,
      1,
      5,
      8,
      3,
      0,
      8,
      0,
      4,
      7,
      3,
      11,
      1,
      10,
      11,
      8,
      1,
      2,
      11,
      0,
      4,
      5,
      8,
      3,
      9
    ],
    "orderHold": [
      1,
      0,
      3,
      2,
      3,
      2,
      4,
      1,
      3,
      0,
      2,
      2,
      0,
      3,
      1,
      3,
      3,
      1,
      1,
      0,
      2,
      4,
      4,
      2,
      0,
      1,
      3,
      2,
      1,
      1,
      3,
      2,
      4,
      1,
      0,
      0,
      4,
      1,
      1,
      0,
      3,
      0,
      1,
      0,
      2,
      2,
      0,
      4,
      0,
      2,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      0,
      0,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      2,
      0,
      0,
      2,
      1,
      0,
      0,
      0,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      0,
      0,
      2,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      2,
      1,
      2,
      4,
      0,
      0,
      3,
      4,
      4,
      1,
      4,
      3,
      4,
      0,
      3,
      2,
      3,
      3,
      2,
      4,
      1,
      3,
      3,
      4,
      4,
      3,
      2,
      0,
      4,
      2,
      2,
      0,
      4,
      3,
      1,
      1,
      3,
      2,
      4,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      4,
      4,
      3,
      4,
      1,
      2
    ],
    "issueDelay": [
      1,
      0,
      0,
      0,
      4,
      4,
      0,
      3,
      0,
      0,
      4,
      0,
      2,
      1,
      0,
      2,
      4,
      3,
      2,
      4,
      1,
      1,
      0,
      2,
      0,
      4,
      2,
      3,
      2,
      0,
      4,
      2,
      2,
      2,
      2,
      3,
      0,
      4,
      1,
      0,
      0,
      3,
      2,
      3,
      2,
      0,
      3,
      0,
      2,
      3,
      3,
      1
    ],
    "issueQuantity": [
      3,
      4,
      0,
      1,
      4,
      1,
      3,
      4,
      3,
      0,
      4,
      2,
      0,
      0,
      2,
      1,
      1,
      4,
      2,
      3,
      1,
      2,
      3,
      2,
      0,
      2,
      3,
      4,
      2,
      4,
      1,
      2,
      3,
      1,
      2,
      3,
      1,
      0,
      3,
      3,
      3,
      3,
      1,
      2,
      1,
      2,
      0,
      1,
      2,
      3,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000133",
    "status": "active",
    "name": "BioCare Distribution",
    "tag": "Laboratory Supplies, Surgical Instruments",
    "contactName": "Julie Sanders",
    "industryRating": 3.7,
    "procedures": "[PR-054,PR-046,PR-056,PR-062,PR-053,PR-065,PR-049,PR-069,PR-044,PR-035]",
    "contracts": "[CNTRCT-0000040,CNTRCT-0000060,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000059,ORDR-2-0000050,ORDR-3-0000062,ORDR-4-0000043,ORDR-5-0000008,ORDR-6-0000032,ORDR-7-0000032,ORDR-8-0000052,ORDR-9-0000068,ORDR-10-0000042,ORDR-11-0000060,ORDR-12-0000071,ORDR-13-0000041,ORDR-14-0000074,ORDR-15-0000048,ORDR-16-0000037,ORDR-17-0000079,ORDR-18-0000071]",
    "spendWeekly": [
      2962,
      2914,
      2996,
      3079,
      2903,
      2905,
      3095,
      3098,
      3020,
      2902,
      2955,
      2904,
      3097,
      3043,
      3091,
      2966,
      2996,
      3013,
      2900,
      3058,
      3100,
      2984,
      3006,
      3020,
      3095,
      3096,
      3095,
      2977,
      2901,
      2954,
      3096,
      3062,
      3086,
      3016,
      2958,
      2932,
      2945,
      2916,
      2991,
      3075,
      3078,
      2900,
      3056,
      3091,
      2997,
      2916,
      2908,
      2900,
      3015,
      2905,
      2977,
      2988
    ],
    "orderRejected": [
      2988,
      16,
      8,
      5,
      12,
      19,
      0,
      1,
      16,
      19,
      0,
      3,
      6,
      11,
      13,
      6,
      17,
      0,
      3,
      13,
      9,
      19,
      18,
      12,
      9,
      19,
      9,
      0,
      5,
      18,
      12,
      16,
      19,
      14,
      13,
      14,
      10,
      8,
      19,
      10,
      9,
      19,
      19,
      0,
      0,
      19,
      0,
      9,
      16,
      8,
      16,
      0,
      16
    ],
    "orderAccepted": [
      4,
      10,
      0,
      11,
      7,
      6,
      11,
      4,
      0,
      6,
      11,
      9,
      9,
      5,
      5,
      11,
      0,
      7,
      4,
      1,
      10,
      1,
      11,
      0,
      0,
      7,
      0,
      11,
      4,
      11,
      0,
      0,
      0,
      11,
      0,
      4,
      3,
      7,
      11,
      2,
      11,
      11,
      11,
      2,
      5,
      7,
      0,
      8,
      0,
      10,
      10,
      4
    ],
    "orderHold": [
      3,
      4,
      4,
      0,
      3,
      2,
      3,
      2,
      3,
      1,
      3,
      0,
      0,
      3,
      1,
      4,
      4,
      3,
      3,
      2,
      2,
      4,
      0,
      4,
      0,
      4,
      2,
      2,
      0,
      0,
      2,
      4,
      4,
      1,
      0,
      0,
      4,
      0,
      4,
      0,
      1,
      0,
      1,
      2,
      2,
      3,
      0,
      0,
      0,
      2,
      1,
      2
    ],
    "orderBackordered": [
      2,
      0,
      0,
      0,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      0,
      1,
      0,
      1,
      0,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      0,
      3,
      4,
      1,
      0,
      4,
      3,
      3,
      1,
      0,
      0,
      2,
      0,
      1,
      1,
      4,
      2,
      1,
      1,
      2,
      2,
      3,
      0,
      1,
      4,
      2,
      4,
      4,
      4,
      1,
      3,
      0,
      2,
      3,
      0,
      0,
      0,
      1,
      1,
      3,
      2,
      2,
      2,
      4,
      3,
      1,
      3,
      4,
      2,
      4
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      4,
      4,
      4,
      3,
      1,
      2,
      0,
      3,
      4,
      3,
      0,
      4,
      4,
      3,
      1,
      4,
      4,
      3,
      0,
      3,
      0,
      0,
      3,
      1,
      2,
      3,
      1,
      4,
      1,
      1,
      4,
      0,
      0,
      2,
      4,
      0,
      0,
      1,
      3,
      4,
      4,
      0,
      1,
      0,
      2,
      0,
      4,
      4
    ],
    "issueQuantity": [
      0,
      2,
      2,
      0,
      4,
      4,
      1,
      3,
      3,
      2,
      4,
      2,
      0,
      3,
      2,
      1,
      4,
      3,
      0,
      4,
      3,
      3,
      0,
      2,
      0,
      4,
      3,
      2,
      3,
      0,
      3,
      2,
      4,
      4,
      2,
      4,
      4,
      0,
      1,
      4,
      4,
      2,
      4,
      1,
      4,
      2,
      4,
      0,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000134",
    "status": "active",
    "name": "MediFlow Systems",
    "tag": "Medical Supplies, Facilities Management, Imaging",
    "contactName": "Helen Ponce",
    "industryRating": 3.4,
    "procedures": "[PR-053,PR-035,PR-038,PR-067,PR-056,PR-036,PR-030,PR-028,PR-055,PR-035]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000050,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000049,ORDR-1-0000059,ORDR-2-0000020,ORDR-3-0000026,ORDR-4-0000055,ORDR-5-0000025,ORDR-6-0000016,ORDR-7-0000034,ORDR-8-0000042,ORDR-9-0000027,ORDR-10-0000043,ORDR-11-0000025,ORDR-12-0000059,ORDR-13-0000072,ORDR-14-0000076,ORDR-15-0000033,ORDR-16-0000071,ORDR-17-0000078,ORDR-18-0000053]",
    "spendWeekly": [
      2993,
      3082,
      2967,
      3035,
      2992,
      3050,
      2992,
      2978,
      3009,
      2902,
      2977,
      2949,
      2909,
      2931,
      2986,
      2901,
      3022,
      3047,
      2904,
      3098,
      2979,
      3005,
      3006,
      2975,
      3091,
      3001,
      3016,
      2977,
      2940,
      2952,
      3008,
      2928,
      2972,
      2904,
      3020,
      3061,
      2907,
      3015,
      2906,
      3091,
      3099,
      2920,
      3097,
      3045,
      3032,
      3018,
      2901,
      2958,
      2901,
      3011,
      3095,
      2994
    ],
    "orderRejected": [
      2994,
      19,
      2,
      5,
      7,
      13,
      9,
      5,
      19,
      19,
      15,
      19,
      9,
      15,
      11,
      16,
      2,
      12,
      17,
      10,
      9,
      19,
      8,
      14,
      14,
      19,
      3,
      0,
      18,
      19,
      8,
      13,
      9,
      10,
      8,
      13,
      7,
      9,
      11,
      19,
      15,
      18,
      16,
      4,
      9,
      0,
      19,
      4,
      18,
      19,
      7,
      7,
      19
    ],
    "orderAccepted": [
      9,
      4,
      0,
      10,
      7,
      9,
      11,
      5,
      3,
      4,
      10,
      6,
      0,
      6,
      11,
      9,
      0,
      8,
      2,
      7,
      0,
      9,
      1,
      0,
      8,
      6,
      0,
      9,
      3,
      6,
      11,
      3,
      0,
      3,
      0,
      0,
      0,
      3,
      10,
      6,
      9,
      8,
      11,
      8,
      11,
      3,
      8,
      8,
      5,
      5,
      1,
      8
    ],
    "orderHold": [
      2,
      4,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      4,
      3,
      0,
      2,
      2,
      1,
      1,
      3,
      0,
      4,
      2,
      2,
      2,
      2,
      4,
      0,
      0,
      0,
      3,
      2,
      4,
      1,
      4,
      2,
      2,
      0,
      1,
      2,
      0,
      3,
      2,
      0,
      3,
      0,
      3,
      3,
      2,
      0,
      3,
      2,
      0,
      3,
      2
    ],
    "orderBackordered": [
      2,
      0,
      2,
      0,
      2,
      2,
      2,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      1,
      3,
      3,
      2,
      2,
      0,
      4,
      4,
      3,
      1,
      4,
      1,
      2,
      0,
      3,
      1,
      4,
      3,
      3,
      2,
      1,
      0,
      4,
      0,
      3,
      4,
      2,
      4,
      2,
      1,
      0,
      4,
      0,
      2,
      2,
      2,
      0,
      2,
      2,
      3,
      2,
      1,
      3,
      4,
      2,
      2,
      0,
      2,
      3,
      1,
      3
    ],
    "issueDelay": [
      2,
      1,
      4,
      3,
      4,
      4,
      4,
      2,
      2,
      0,
      0,
      4,
      3,
      4,
      4,
      1,
      3,
      2,
      0,
      0,
      1,
      2,
      1,
      0,
      0,
      4,
      3,
      3,
      2,
      3,
      3,
      4,
      4,
      2,
      1,
      3,
      0,
      3,
      1,
      0,
      2,
      2,
      0,
      2,
      4,
      2,
      1,
      1,
      3,
      0,
      3,
      3
    ],
    "issueQuantity": [
      4,
      4,
      3,
      0,
      2,
      2,
      2,
      0,
      0,
      2,
      0,
      1,
      2,
      3,
      4,
      4,
      1,
      4,
      0,
      4,
      2,
      2,
      2,
      3,
      1,
      2,
      0,
      0,
      4,
      3,
      2,
      2,
      1,
      0,
      1,
      2,
      3,
      0,
      2,
      1,
      4,
      1,
      4,
      2,
      4,
      0,
      4,
      0,
      0,
      3,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000135",
    "status": "active",
    "name": "WellnessWorks Distribution",
    "tag": "PPE",
    "contactName": "Douglas Ruiz",
    "industryRating": 3.5,
    "procedures": "[PR-039,PR-067,PR-047,PR-038,PR-070,PR-036,PR-030,PR-071,PR-056,PR-027]",
    "contracts": "[CNTRCT-0000048,CNTRCT-0000066,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000021,ORDR-1-0000047,ORDR-2-0000009,ORDR-3-0000015,ORDR-4-0000023,ORDR-5-0000008,ORDR-6-0000045,ORDR-7-0000050,ORDR-8-0000028,ORDR-9-0000032,ORDR-10-0000013,ORDR-11-0000060,ORDR-12-0000024,ORDR-13-0000050,ORDR-14-0000063,ORDR-15-0000033,ORDR-16-0000063,ORDR-17-0000031,ORDR-18-0000025]",
    "spendWeekly": [
      2928,
      2968,
      3054,
      2902,
      3045,
      3030,
      3018,
      3098,
      2902,
      2993,
      3001,
      3002,
      2924,
      2963,
      3076,
      2915,
      3000,
      3009,
      2900,
      3098,
      3099,
      3045,
      2984,
      3032,
      2906,
      3028,
      3025,
      2974,
      2995,
      2978,
      2909,
      2918,
      2900,
      3073,
      2968,
      2948,
      3056,
      3097,
      2966,
      2953,
      3045,
      3047,
      3067,
      2980,
      2902,
      2913,
      2931,
      2978,
      2999,
      3069,
      2985,
      2901
    ],
    "orderRejected": [
      2901,
      14,
      12,
      13,
      18,
      19,
      0,
      15,
      18,
      19,
      9,
      6,
      16,
      0,
      11,
      9,
      19,
      15,
      13,
      4,
      8,
      0,
      15,
      15,
      7,
      10,
      16,
      0,
      16,
      2,
      9,
      6,
      6,
      16,
      11,
      13,
      18,
      10,
      15,
      8,
      8,
      8,
      14,
      5,
      0,
      18,
      10,
      0,
      10,
      3,
      7,
      6,
      10
    ],
    "orderAccepted": [
      11,
      4,
      2,
      5,
      8,
      4,
      1,
      6,
      0,
      2,
      4,
      3,
      4,
      6,
      2,
      10,
      0,
      2,
      10,
      6,
      4,
      6,
      5,
      6,
      7,
      10,
      0,
      5,
      8,
      2,
      7,
      5,
      0,
      7,
      7,
      8,
      4,
      5,
      6,
      10,
      0,
      11,
      5,
      11,
      3,
      11,
      9,
      10,
      0,
      7,
      3,
      6
    ],
    "orderHold": [
      3,
      3,
      2,
      1,
      3,
      3,
      3,
      1,
      2,
      1,
      1,
      2,
      0,
      4,
      1,
      3,
      3,
      3,
      2,
      2,
      1,
      3,
      1,
      4,
      0,
      3,
      2,
      1,
      4,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      4,
      1,
      1,
      0,
      1,
      0,
      1,
      3,
      0,
      3,
      3,
      1,
      3,
      4
    ],
    "orderBackordered": [
      4,
      0,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      2,
      0,
      3,
      4,
      0,
      0,
      1,
      3,
      2,
      3,
      2,
      3,
      4,
      2,
      1,
      0,
      0,
      3,
      2,
      0,
      3,
      0,
      1,
      4,
      1,
      3,
      4,
      1,
      4,
      2,
      3,
      2,
      3,
      3,
      0,
      0,
      3,
      0,
      0,
      4,
      3,
      1,
      4,
      4,
      3,
      2,
      3,
      0,
      4,
      1,
      2,
      3
    ],
    "issueDelay": [
      0,
      2,
      1,
      3,
      4,
      2,
      4,
      2,
      3,
      1,
      0,
      2,
      3,
      0,
      4,
      3,
      2,
      0,
      0,
      4,
      0,
      2,
      2,
      0,
      3,
      2,
      0,
      4,
      0,
      3,
      3,
      2,
      4,
      1,
      3,
      4,
      2,
      1,
      3,
      4,
      3,
      1,
      0,
      3,
      4,
      0,
      0,
      2,
      2,
      3,
      3,
      0
    ],
    "issueQuantity": [
      4,
      1,
      2,
      1,
      3,
      1,
      2,
      0,
      0,
      2,
      4,
      1,
      2,
      2,
      3,
      0,
      2,
      1,
      4,
      3,
      1,
      4,
      1,
      3,
      0,
      1,
      4,
      1,
      4,
      2,
      0,
      2,
      3,
      1,
      0,
      1,
      4,
      0,
      2,
      2,
      3,
      4,
      4,
      1,
      4,
      0,
      4,
      0,
      0,
      4,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000136",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Equipment, PPE, Nutrition",
    "contactName": "Melissa Torres",
    "industryRating": 2.9,
    "procedures": "[PR-047,PR-065,PR-047,PR-038,PR-064,PR-070,PR-064,PR-072,PR-071,PR-071]",
    "contracts": "[CNTRCT-0000003,CNTRCT-0000049,CNTRCT-0000046]",
    "orders": "[ORDR-0-0000038,ORDR-1-0000045,ORDR-2-0000050,ORDR-3-0000033,ORDR-4-0000037,ORDR-5-0000019,ORDR-6-0000043,ORDR-7-0000062,ORDR-8-0000017,ORDR-9-0000065,ORDR-10-0000019,ORDR-11-0000074,ORDR-12-0000022,ORDR-13-0000028,ORDR-14-0000044,ORDR-15-0000054,ORDR-16-0000034,ORDR-17-0000029,ORDR-18-0000039]",
    "spendWeekly": [
      2996,
      2988,
      3049,
      3008,
      2903,
      3035,
      2925,
      3011,
      2902,
      3027,
      3000,
      3018,
      3007,
      2956,
      2903,
      2920,
      2903,
      2982,
      2900,
      3025,
      3100,
      2996,
      2948,
      3097,
      3049,
      3068,
      2909,
      3031,
      2958,
      2981,
      2975,
      3097,
      3067,
      3059,
      2996,
      3034,
      3100,
      3032,
      3099,
      3099,
      2901,
      2980,
      2947,
      2946,
      2966,
      2901,
      3062,
      2974,
      3028,
      2905,
      3052,
      2988
    ],
    "orderRejected": [
      2988,
      19,
      6,
      8,
      11,
      19,
      17,
      19,
      0,
      19,
      0,
      6,
      8,
      11,
      12,
      0,
      17,
      11,
      0,
      14,
      14,
      18,
      0,
      7,
      19,
      6,
      2,
      0,
      16,
      16,
      4,
      17,
      19,
      19,
      0,
      0,
      8,
      11,
      18,
      8,
      6,
      19,
      0,
      5,
      13,
      8,
      8,
      9,
      11,
      18,
      0,
      4,
      19
    ],
    "orderAccepted": [
      6,
      5,
      1,
      11,
      2,
      6,
      11,
      0,
      0,
      0,
      5,
      0,
      0,
      6,
      8,
      10,
      0,
      4,
      1,
      0,
      4,
      0,
      11,
      1,
      11,
      11,
      0,
      11,
      5,
      10,
      6,
      2,
      0,
      6,
      6,
      4,
      4,
      0,
      9,
      1,
      7,
      5,
      8,
      6,
      6,
      4,
      4,
      11,
      4,
      0,
      4,
      11
    ],
    "orderHold": [
      2,
      0,
      3,
      0,
      3,
      3,
      1,
      1,
      1,
      2,
      3,
      0,
      4,
      2,
      0,
      0,
      4,
      1,
      3,
      0,
      1,
      4,
      2,
      4,
      0,
      3,
      2,
      2,
      1,
      0,
      0,
      4,
      0,
      4,
      0,
      2,
      0,
      0,
      2,
      4,
      2,
      1,
      0,
      3,
      2,
      2,
      1,
      0,
      0,
      1,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      1,
      2,
      2,
      2,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      0,
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      4,
      4,
      2,
      2,
      0,
      0,
      4,
      2,
      4,
      2,
      3,
      2,
      4,
      0,
      4,
      0,
      3,
      3,
      2,
      3,
      3,
      1,
      0,
      1,
      1,
      4,
      1,
      0,
      0,
      4,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      4,
      0,
      4
    ],
    "issueDelay": [
      0,
      3,
      3,
      4,
      3,
      0,
      2,
      0,
      2,
      3,
      2,
      4,
      0,
      2,
      3,
      0,
      4,
      0,
      1,
      2,
      4,
      0,
      0,
      4,
      3,
      1,
      2,
      0,
      1,
      4,
      0,
      2,
      1,
      4,
      4,
      1,
      0,
      3,
      4,
      3,
      0,
      3,
      4,
      1,
      4,
      1,
      1,
      0,
      4,
      1,
      2,
      3
    ],
    "issueQuantity": [
      4,
      3,
      4,
      2,
      2,
      0,
      0,
      4,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      2,
      1,
      3,
      4,
      3,
      1,
      2,
      1,
      1,
      2,
      4,
      2,
      2,
      4,
      1,
      3,
      4,
      4,
      3,
      0,
      3,
      4,
      0,
      1,
      4,
      4,
      4,
      3,
      0,
      4,
      1,
      3,
      3,
      0,
      4,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000137",
    "status": "inactive",
    "name": "HealthWave Technologies",
    "tag": "Equipment, Surgical Instruments, IT Services",
    "contactName": "Chris Richard",
    "industryRating": 2.2,
    "procedures": "[PR-054,PR-066,PR-048,PR-068,PR-066,PR-063,PR-068,PR-046,PR-044,PR-040]",
    "contracts": "[CNTRCT-0000021,CNTRCT-0000058,CNTRCT-0000026]",
    "orders": "[ORDR-0-0000054,ORDR-1-0000065,ORDR-2-0000059,ORDR-3-0000048,ORDR-4-0000058,ORDR-5-0000044,ORDR-6-0000021,ORDR-7-0000019,ORDR-8-0000042,ORDR-9-0000046,ORDR-10-0000009,ORDR-11-0000044,ORDR-12-0000018,ORDR-13-0000028,ORDR-14-0000068,ORDR-15-0000049,ORDR-16-0000023,ORDR-17-0000078,ORDR-18-0000027]",
    "spendWeekly": [
      2988,
      3036,
      2945,
      2902,
      2958,
      3052,
      2988,
      3098,
      2902,
      3039,
      3037,
      2995,
      3100,
      3094,
      2948,
      2945,
      2930,
      3099,
      3033,
      3022,
      3100,
      3097,
      3066,
      2968,
      2989,
      3039,
      3079,
      2957,
      2901,
      2990,
      3094,
      3056,
      2991,
      2969,
      2985,
      2922,
      2981,
      2973,
      2906,
      2960,
      3079,
      3001,
      2979,
      3091,
      3054,
      3099,
      3064,
      2947,
      2901,
      2907,
      2931,
      2906
    ],
    "orderRejected": [
      2906,
      12,
      11,
      8,
      6,
      19,
      4,
      15,
      19,
      18,
      19,
      11,
      4,
      2,
      19,
      3,
      6,
      10,
      8,
      0,
      8,
      4,
      13,
      8,
      15,
      19,
      0,
      11,
      16,
      6,
      12,
      2,
      19,
      19,
      19,
      19,
      15,
      7,
      19,
      19,
      0,
      11,
      7,
      18,
      10,
      0,
      11,
      2,
      3,
      0,
      7,
      1,
      16
    ],
    "orderAccepted": [
      11,
      6,
      7,
      11,
      7,
      9,
      9,
      10,
      2,
      2,
      0,
      8,
      0,
      4,
      6,
      9,
      3,
      11,
      8,
      7,
      9,
      7,
      8,
      0,
      10,
      11,
      0,
      2,
      7,
      3,
      6,
      11,
      0,
      1,
      4,
      7,
      0,
      6,
      11,
      0,
      11,
      5,
      11,
      2,
      6,
      9,
      0,
      9,
      2,
      3,
      4,
      8
    ],
    "orderHold": [
      3,
      2,
      4,
      2,
      1,
      1,
      3,
      0,
      1,
      1,
      0,
      0,
      2,
      3,
      2,
      4,
      0,
      4,
      1,
      2,
      2,
      2,
      0,
      4,
      0,
      3,
      1,
      4,
      2,
      0,
      0,
      4,
      2,
      4,
      0,
      2,
      1,
      1,
      2,
      2,
      4,
      0,
      3,
      2,
      2,
      2,
      2,
      4,
      3,
      3,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      2,
      1,
      3,
      2,
      3,
      0,
      0,
      4,
      2,
      1,
      3,
      2,
      0,
      0,
      0,
      2,
      2,
      2,
      2,
      3,
      2,
      0,
      4,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      4,
      2,
      0,
      2,
      3,
      1,
      4,
      2,
      0,
      2,
      1,
      0,
      4,
      2,
      1,
      0,
      4,
      4,
      4,
      0,
      2,
      3
    ],
    "issueDelay": [
      1,
      4,
      1,
      2,
      4,
      4,
      4,
      0,
      0,
      1,
      2,
      3,
      3,
      3,
      0,
      0,
      2,
      0,
      0,
      2,
      1,
      0,
      0,
      2,
      2,
      4,
      4,
      3,
      0,
      4,
      3,
      1,
      2,
      1,
      2,
      4,
      1,
      4,
      1,
      1,
      0,
      3,
      1,
      3,
      4,
      0,
      1,
      0,
      2,
      4,
      0,
      2
    ],
    "issueQuantity": [
      2,
      1,
      2,
      1,
      3,
      4,
      4,
      1,
      0,
      0,
      2,
      2,
      0,
      2,
      0,
      4,
      2,
      4,
      0,
      0,
      3,
      4,
      1,
      2,
      0,
      3,
      4,
      4,
      4,
      4,
      4,
      4,
      4,
      1,
      1,
      3,
      4,
      1,
      2,
      0,
      2,
      0,
      2,
      0,
      0,
      0,
      4,
      1,
      0,
      4,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000138",
    "status": "active",
    "name": "HealthWave Technologies",
    "tag": "Equipment, Pharmaceuticals",
    "contactName": "Chris Richard",
    "industryRating": 3.2,
    "procedures": "[PR-061,PR-055,PR-064,PR-055,PR-043,PR-061,PR-056,PR-050,PR-063,PR-075]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000066,CNTRCT-0000025]",
    "orders": "[ORDR-0-0000058,ORDR-1-0000008,ORDR-2-0000059,ORDR-3-0000019,ORDR-4-0000024,ORDR-5-0000058,ORDR-6-0000055,ORDR-7-0000057,ORDR-8-0000071,ORDR-9-0000008,ORDR-10-0000030,ORDR-11-0000062,ORDR-12-0000017,ORDR-13-0000019,ORDR-14-0000057,ORDR-15-0000043,ORDR-16-0000079,ORDR-17-0000027,ORDR-18-0000076]",
    "spendWeekly": [
      3097,
      3038,
      2914,
      2913,
      2991,
      2990,
      3030,
      2919,
      2998,
      2987,
      2944,
      2904,
      2909,
      2984,
      2998,
      2901,
      2930,
      3082,
      2985,
      3098,
      2929,
      3025,
      2977,
      3079,
      3000,
      2901,
      2962,
      3075,
      2991,
      3030,
      2990,
      3097,
      3067,
      2985,
      3095,
      2904,
      3083,
      2969,
      3078,
      3097,
      3012,
      3014,
      3063,
      2916,
      2950,
      2955,
      2938,
      2900,
      2980,
      2992,
      2959,
      3000
    ],
    "orderRejected": [
      3000,
      13,
      11,
      5,
      5,
      17,
      9,
      18,
      14,
      19,
      8,
      9,
      18,
      18,
      6,
      17,
      13,
      8,
      9,
      0,
      0,
      3,
      0,
      6,
      13,
      7,
      14,
      7,
      19,
      19,
      10,
      17,
      6,
      19,
      9,
      18,
      9,
      16,
      11,
      14,
      11,
      19,
      9,
      0,
      15,
      8,
      14,
      16,
      16,
      0,
      0,
      8,
      13
    ],
    "orderAccepted": [
      9,
      9,
      8,
      6,
      6,
      3,
      8,
      8,
      0,
      1,
      6,
      6,
      3,
      6,
      11,
      3,
      0,
      2,
      5,
      8,
      0,
      10,
      5,
      11,
      10,
      9,
      0,
      4,
      0,
      0,
      11,
      4,
      9,
      2,
      9,
      11,
      5,
      2,
      0,
      1,
      0,
      11,
      3,
      5,
      5,
      11,
      5,
      11,
      0,
      7,
      6,
      2
    ],
    "orderHold": [
      2,
      0,
      1,
      1,
      3,
      4,
      1,
      0,
      4,
      2,
      0,
      2,
      3,
      0,
      0,
      4,
      4,
      3,
      4,
      2,
      2,
      0,
      1,
      3,
      4,
      2,
      3,
      2,
      4,
      4,
      0,
      1,
      2,
      0,
      0,
      1,
      0,
      2,
      2,
      1,
      3,
      4,
      2,
      1,
      2,
      1,
      2,
      4,
      4,
      0,
      1,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      2,
      0,
      2,
      1,
      1,
      0,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      2,
      4,
      4,
      0,
      1,
      0,
      2,
      2,
      4,
      3,
      1,
      3,
      2,
      2,
      2,
      4,
      1,
      2,
      3,
      2,
      0,
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      1,
      4,
      4,
      4,
      0,
      2,
      4,
      0,
      0,
      4,
      4,
      2,
      3,
      2,
      4,
      4,
      4,
      3,
      1,
      4,
      4,
      1,
      4
    ],
    "issueDelay": [
      2,
      0,
      4,
      3,
      2,
      2,
      4,
      3,
      2,
      1,
      3,
      2,
      3,
      0,
      3,
      3,
      1,
      0,
      0,
      2,
      3,
      3,
      4,
      3,
      2,
      4,
      4,
      0,
      3,
      2,
      2,
      2,
      4,
      0,
      4,
      4,
      0,
      0,
      2,
      3,
      2,
      1,
      4,
      1,
      3,
      0,
      0,
      0,
      0,
      0,
      1,
      3
    ],
    "issueQuantity": [
      1,
      1,
      2,
      3,
      4,
      4,
      1,
      1,
      3,
      4,
      3,
      1,
      2,
      2,
      2,
      4,
      2,
      3,
      4,
      2,
      0,
      0,
      1,
      4,
      0,
      4,
      2,
      2,
      4,
      2,
      1,
      2,
      2,
      4,
      0,
      3,
      4,
      1,
      0,
      3,
      0,
      1,
      3,
      0,
      2,
      0,
      2,
      1,
      0,
      1,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000139",
    "status": "inactive",
    "name": "CureCraft Technologies",
    "tag": "Facilities Management, Laboratory Supplies",
    "contactName": "Michelle Peck",
    "industryRating": 3.8,
    "procedures": "[PR-044,PR-067,PR-067,PR-061,PR-045,PR-026,PR-072,PR-054,PR-034,PR-037]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000016,CNTRCT-0000017]",
    "orders": "[ORDR-0-0000005,ORDR-1-0000045,ORDR-2-0000024,ORDR-3-0000011,ORDR-4-0000050,ORDR-5-0000023,ORDR-6-0000022,ORDR-7-0000022,ORDR-8-0000032,ORDR-9-0000065,ORDR-10-0000012,ORDR-11-0000045,ORDR-12-0000037,ORDR-13-0000012,ORDR-14-0000015,ORDR-15-0000036,ORDR-16-0000046,ORDR-17-0000048,ORDR-18-0000032]",
    "spendWeekly": [
      2941,
      2966,
      2955,
      2902,
      3009,
      2905,
      2905,
      3022,
      3099,
      2934,
      3100,
      2941,
      3012,
      3055,
      3032,
      2907,
      3046,
      3001,
      3070,
      3079,
      3100,
      3097,
      2900,
      3019,
      2979,
      3017,
      2956,
      2969,
      3015,
      2902,
      3045,
      3031,
      2986,
      2905,
      3069,
      3042,
      3027,
      2917,
      2971,
      3090,
      2993,
      3019,
      3077,
      3041,
      2903,
      2923,
      2910,
      3019,
      2988,
      2905,
      2948,
      2901
    ],
    "orderRejected": [
      2901,
      5,
      8,
      9,
      2,
      15,
      0,
      19,
      7,
      11,
      9,
      2,
      6,
      9,
      10,
      15,
      3,
      8,
      0,
      9,
      0,
      12,
      3,
      13,
      19,
      12,
      19,
      1,
      14,
      17,
      17,
      19,
      19,
      19,
      12,
      10,
      17,
      1,
      19,
      19,
      19,
      10,
      2,
      0,
      8,
      2,
      4,
      13,
      7,
      8,
      8,
      6,
      19
    ],
    "orderAccepted": [
      11,
      4,
      6,
      11,
      4,
      4,
      6,
      1,
      2,
      0,
      6,
      10,
      3,
      8,
      11,
      9,
      5,
      0,
      2,
      6,
      6,
      8,
      11,
      0,
      4,
      0,
      0,
      7,
      0,
      2,
      7,
      4,
      0,
      1,
      0,
      11,
      8,
      5,
      10,
      0,
      0,
      6,
      4,
      5,
      3,
      4,
      7,
      3,
      4,
      4,
      0,
      3
    ],
    "orderHold": [
      2,
      3,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      2,
      3,
      2,
      0,
      3,
      0,
      2,
      4,
      1,
      3,
      1,
      4,
      0,
      1,
      3,
      2,
      2,
      3,
      0,
      4,
      0,
      2,
      3,
      0,
      4,
      2,
      1,
      1,
      4,
      2,
      4,
      4,
      2,
      3,
      0,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      2,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      0,
      2,
      0,
      2,
      0,
      4,
      3,
      4,
      0,
      4,
      2,
      0,
      4,
      0,
      0,
      0,
      4,
      3,
      0,
      4,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      4,
      3,
      1,
      2,
      2,
      2,
      0,
      1,
      1,
      1,
      3,
      0,
      0,
      1,
      4,
      3,
      1,
      2,
      4,
      4,
      1,
      0,
      1,
      4
    ],
    "issueDelay": [
      2,
      2,
      4,
      2,
      4,
      4,
      3,
      0,
      1,
      1,
      0,
      3,
      4,
      2,
      0,
      1,
      1,
      0,
      2,
      3,
      0,
      2,
      0,
      3,
      1,
      4,
      0,
      0,
      0,
      0,
      3,
      4,
      2,
      3,
      1,
      3,
      2,
      4,
      2,
      0,
      0,
      1,
      3,
      1,
      4,
      2,
      1,
      4,
      4,
      3,
      4,
      1
    ],
    "issueQuantity": [
      4,
      0,
      1,
      1,
      4,
      1,
      2,
      2,
      4,
      1,
      4,
      4,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      4,
      0,
      1,
      3,
      2,
      2,
      3,
      2,
      0,
      1,
      0,
      0,
      3,
      4,
      4,
      3,
      3,
      1,
      2,
      4,
      4,
      3,
      4,
      3,
      0,
      2,
      1,
      0,
      1,
      1,
      3,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000140",
    "status": "active",
    "name": "MediSupply Chain",
    "tag": "Facilities Management, Imaging",
    "contactName": "Bryan Lang",
    "industryRating": 2.6,
    "procedures": "[PR-064,PR-018,PR-038,PR-051,PR-056,PR-047,PR-045,PR-064,PR-065,PR-075]",
    "contracts": "[CNTRCT-0000022,CNTRCT-0000016,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000041,ORDR-1-0000011,ORDR-2-0000022,ORDR-3-0000036,ORDR-4-0000023,ORDR-5-0000005,ORDR-6-0000008,ORDR-7-0000016,ORDR-8-0000054,ORDR-9-0000045,ORDR-10-0000017,ORDR-11-0000039,ORDR-12-0000013,ORDR-13-0000031,ORDR-14-0000045,ORDR-15-0000079,ORDR-16-0000070,ORDR-17-0000046,ORDR-18-0000078]",
    "spendWeekly": [
      2901,
      2948,
      3006,
      3006,
      2997,
      3052,
      2917,
      2946,
      2994,
      3078,
      3032,
      2924,
      2946,
      3084,
      2960,
      2955,
      2903,
      3027,
      3073,
      3098,
      2903,
      3064,
      3035,
      2947,
      3038,
      3031,
      3095,
      2992,
      2940,
      3097,
      2903,
      2983,
      3099,
      3003,
      2982,
      2996,
      2910,
      2995,
      2911,
      3099,
      2922,
      2969,
      3082,
      3091,
      2927,
      2901,
      2909,
      2915,
      3058,
      3013,
      3095,
      3014
    ],
    "orderRejected": [
      3014,
      13,
      6,
      0,
      0,
      12,
      12,
      1,
      3,
      19,
      0,
      7,
      18,
      15,
      10,
      7,
      6,
      15,
      18,
      17,
      5,
      8,
      7,
      19,
      17,
      19,
      4,
      7,
      12,
      9,
      19,
      18,
      19,
      19,
      19,
      5,
      0,
      5,
      15,
      1,
      11,
      2,
      4,
      10,
      10,
      13,
      1,
      18,
      10,
      4,
      2,
      12,
      18
    ],
    "orderAccepted": [
      4,
      5,
      2,
      0,
      7,
      9,
      10,
      0,
      5,
      2,
      3,
      4,
      6,
      5,
      4,
      4,
      0,
      8,
      10,
      8,
      6,
      1,
      4,
      0,
      8,
      9,
      0,
      1,
      0,
      4,
      9,
      4,
      3,
      4,
      0,
      11,
      5,
      2,
      11,
      2,
      7,
      10,
      6,
      5,
      0,
      9,
      0,
      4,
      1,
      1,
      0,
      4
    ],
    "orderHold": [
      2,
      0,
      3,
      0,
      0,
      4,
      4,
      3,
      4,
      1,
      3,
      0,
      2,
      3,
      2,
      4,
      2,
      3,
      3,
      1,
      4,
      2,
      0,
      4,
      0,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      0,
      0,
      4,
      4,
      3,
      2,
      0,
      1,
      2,
      0,
      4,
      0,
      0,
      4,
      1,
      3,
      4,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      2,
      2,
      0,
      0,
      2,
      1,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      0,
      0,
      3,
      4,
      2,
      3,
      1,
      1,
      1,
      2,
      0,
      1,
      4,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      3,
      1,
      1,
      4,
      2,
      4,
      0,
      2,
      4,
      2,
      1,
      1,
      1,
      4,
      0,
      3,
      2,
      2,
      1,
      4,
      0,
      3,
      2,
      3,
      1,
      0,
      0,
      0,
      3,
      4,
      0,
      4
    ],
    "issueDelay": [
      1,
      4,
      2,
      4,
      2,
      2,
      2,
      4,
      0,
      4,
      1,
      2,
      3,
      3,
      1,
      2,
      1,
      0,
      4,
      3,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      4,
      3,
      0,
      0,
      1,
      3,
      1,
      3,
      4,
      1,
      1,
      0,
      4,
      1,
      0,
      3,
      2,
      0,
      1,
      4,
      0,
      4,
      2
    ],
    "issueQuantity": [
      4,
      0,
      2,
      1,
      4,
      3,
      4,
      2,
      2,
      0,
      0,
      1,
      0,
      3,
      3,
      2,
      2,
      3,
      3,
      4,
      0,
      4,
      0,
      1,
      0,
      4,
      2,
      4,
      0,
      1,
      2,
      3,
      3,
      4,
      2,
      3,
      4,
      0,
      2,
      1,
      3,
      2,
      2,
      2,
      1,
      1,
      4,
      4,
      2,
      1,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000141",
    "status": "active",
    "name": "HealthWave Technologies",
    "tag": "Equipment, Facilities Management",
    "contactName": "Amanda Glenn",
    "industryRating": 2.1,
    "procedures": "[PR-054,PR-065,PR-067,PR-066,PR-058,PR-070,PR-053,PR-046,PR-073,PR-059]",
    "contracts": "[CNTRCT-0000062,CNTRCT-0000056,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000064,ORDR-1-0000008,ORDR-2-0000009,ORDR-3-0000015,ORDR-4-0000057,ORDR-5-0000067,ORDR-6-0000029,ORDR-7-0000021,ORDR-8-0000011,ORDR-9-0000011,ORDR-10-0000039,ORDR-11-0000071,ORDR-12-0000075,ORDR-13-0000020,ORDR-14-0000073,ORDR-15-0000078,ORDR-16-0000060,ORDR-17-0000074,ORDR-18-0000051]",
    "spendWeekly": [
      3097,
      3082,
      2932,
      2933,
      2980,
      3097,
      3100,
      3098,
      2902,
      2952,
      3065,
      3062,
      3028,
      2945,
      3091,
      3080,
      2903,
      3099,
      2900,
      2909,
      3100,
      2974,
      3092,
      3097,
      3045,
      3084,
      2918,
      2901,
      2901,
      2912,
      3034,
      3030,
      2900,
      3089,
      2971,
      2924,
      2996,
      2920,
      3044,
      2901,
      2972,
      2977,
      3097,
      3091,
      2996,
      2901,
      3099,
      3014,
      3001,
      2906,
      3007,
      2969
    ],
    "orderRejected": [
      2969,
      19,
      19,
      8,
      13,
      6,
      0,
      14,
      1,
      11,
      10,
      11,
      9,
      18,
      10,
      17,
      19,
      0,
      8,
      0,
      0,
      19,
      16,
      12,
      19,
      1,
      12,
      0,
      9,
      19,
      18,
      12,
      0,
      14,
      19,
      7,
      15,
      10,
      11,
      0,
      15,
      14,
      19,
      0,
      3,
      3,
      0,
      16,
      19,
      1,
      19,
      0,
      19
    ],
    "orderAccepted": [
      11,
      9,
      4,
      8,
      5,
      11,
      8,
      4,
      0,
      5,
      2,
      7,
      10,
      3,
      11,
      8,
      1,
      4,
      3,
      5,
      0,
      0,
      8,
      11,
      1,
      9,
      0,
      11,
      8,
      11,
      8,
      11,
      0,
      10,
      8,
      11,
      4,
      3,
      11,
      5,
      1,
      11,
      11,
      4,
      3,
      7,
      9,
      9,
      5,
      5,
      1,
      10
    ],
    "orderHold": [
      2,
      4,
      0,
      0,
      3,
      1,
      0,
      4,
      2,
      1,
      1,
      2,
      0,
      4,
      0,
      0,
      0,
      1,
      2,
      4,
      3,
      3,
      1,
      3,
      0,
      2,
      1,
      2,
      4,
      0,
      3,
      3,
      0,
      0,
      1,
      2,
      3,
      4,
      4,
      3,
      2,
      0,
      3,
      4,
      2,
      2,
      2,
      4,
      0,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      0,
      0,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      1,
      0,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      3,
      4,
      2,
      3,
      0,
      4,
      0,
      2,
      2,
      3,
      4,
      0,
      1,
      0,
      4,
      2,
      1,
      3,
      0,
      0,
      3,
      0,
      1,
      4,
      3,
      0,
      4,
      2,
      0,
      4,
      0,
      1,
      0,
      1,
      4,
      4,
      4,
      1,
      0,
      2,
      2,
      3,
      0,
      1,
      0,
      3,
      3,
      1,
      0,
      2
    ],
    "issueDelay": [
      0,
      1,
      4,
      4,
      1,
      4,
      1,
      3,
      2,
      3,
      3,
      2,
      2,
      1,
      0,
      0,
      4,
      1,
      0,
      4,
      0,
      2,
      0,
      2,
      1,
      4,
      1,
      0,
      0,
      2,
      2,
      1,
      3,
      2,
      1,
      4,
      3,
      3,
      3,
      3,
      4,
      4,
      2,
      0,
      4,
      0,
      4,
      0,
      4,
      0,
      0,
      3
    ],
    "issueQuantity": [
      3,
      2,
      1,
      0,
      1,
      0,
      2,
      0,
      0,
      3,
      0,
      3,
      0,
      1,
      4,
      1,
      2,
      1,
      2,
      4,
      2,
      4,
      4,
      3,
      3,
      4,
      0,
      2,
      4,
      4,
      4,
      3,
      4,
      4,
      0,
      3,
      4,
      1,
      2,
      2,
      0,
      4,
      4,
      2,
      4,
      1,
      1,
      2,
      0,
      4,
      0,
      1
    ]
  },
  {
    "id": "VNDR-0000142",
    "status": "inactive",
    "name": "CarePoint Technologies",
    "tag": "Surgical Instruments, Laboratory Supplies, Facilities Management",
    "contactName": "Crystal Ryan",
    "industryRating": 3.8,
    "procedures": "[PR-063,PR-065,PR-062,PR-067,PR-068,PR-069,PR-068,PR-043,PR-052,PR-020]",
    "contracts": "[CNTRCT-0000030,CNTRCT-0000064,CNTRCT-0000059]",
    "orders": "[ORDR-0-0000022,ORDR-1-0000008,ORDR-2-0000035,ORDR-3-0000062,ORDR-4-0000005,ORDR-5-0000007,ORDR-6-0000009,ORDR-7-0000025,ORDR-8-0000014,ORDR-9-0000062,ORDR-10-0000009,ORDR-11-0000063,ORDR-12-0000033,ORDR-13-0000061,ORDR-14-0000050,ORDR-15-0000051,ORDR-16-0000016,ORDR-17-0000049,ORDR-18-0000075]",
    "spendWeekly": [
      3025,
      3074,
      3024,
      2931,
      3016,
      2905,
      3013,
      2997,
      3004,
      2943,
      2975,
      2980,
      3026,
      3019,
      3080,
      2961,
      3091,
      3099,
      2918,
      3031,
      2903,
      3037,
      3056,
      3026,
      2915,
      3096,
      3091,
      3045,
      2995,
      2968,
      2903,
      3015,
      2912,
      3036,
      2969,
      2904,
      2944,
      2938,
      3099,
      2957,
      2901,
      2924,
      3005,
      3022,
      2902,
      2948,
      3055,
      2986,
      3094,
      3066,
      3073,
      2901
    ],
    "orderRejected": [
      2901,
      13,
      1,
      3,
      11,
      19,
      2,
      0,
      19,
      19,
      8,
      19,
      8,
      16,
      14,
      14,
      19,
      9,
      1,
      9,
      0,
      17,
      7,
      6,
      3,
      1,
      16,
      0,
      19,
      14,
      19,
      11,
      19,
      6,
      19,
      9,
      8,
      14,
      19,
      19,
      9,
      18,
      0,
      15,
      19,
      15,
      3,
      18,
      13,
      0,
      11,
      0,
      7
    ],
    "orderAccepted": [
      9,
      11,
      11,
      7,
      6,
      3,
      9,
      2,
      1,
      0,
      11,
      6,
      0,
      11,
      9,
      0,
      0,
      11,
      2,
      10,
      7,
      3,
      10,
      10,
      7,
      1,
      0,
      8,
      2,
      6,
      11,
      4,
      0,
      0,
      11,
      10,
      10,
      0,
      10,
      0,
      5,
      11,
      4,
      3,
      0,
      11,
      6,
      6,
      0,
      11,
      7,
      9
    ],
    "orderHold": [
      3,
      3,
      3,
      2,
      4,
      2,
      4,
      3,
      3,
      4,
      1,
      2,
      0,
      3,
      1,
      0,
      4,
      1,
      4,
      3,
      4,
      1,
      3,
      4,
      0,
      4,
      0,
      4,
      4,
      0,
      0,
      0,
      2,
      3,
      0,
      3,
      3,
      2,
      0,
      1,
      1,
      0,
      2,
      3,
      3,
      4,
      0,
      2,
      2,
      1,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      0,
      2,
      2,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      2,
      0,
      4,
      3,
      0,
      4,
      0,
      2,
      4,
      2,
      3,
      2,
      4,
      2,
      0,
      0,
      2,
      0,
      0,
      1,
      4,
      1,
      1,
      3,
      2,
      3,
      4,
      0,
      2,
      0,
      4,
      3,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      3,
      0,
      0,
      2,
      3,
      3,
      4,
      3,
      4,
      4,
      4,
      0,
      4
    ],
    "issueDelay": [
      0,
      4,
      1,
      4,
      1,
      2,
      2,
      0,
      2,
      2,
      4,
      1,
      4,
      0,
      3,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      3,
      2,
      0,
      2,
      2,
      1,
      3,
      2,
      2,
      4,
      0,
      0,
      4,
      4,
      0,
      2,
      3,
      2,
      4,
      2,
      2,
      3,
      3,
      1,
      0,
      0,
      3,
      0,
      1,
      3
    ],
    "issueQuantity": [
      4,
      0,
      2,
      0,
      3,
      2,
      4,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      4,
      3,
      1,
      1,
      3,
      2,
      2,
      4,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      4,
      2,
      4,
      3,
      1,
      1,
      2,
      4,
      2,
      0,
      3,
      3,
      3,
      0,
      2,
      4,
      0,
      4,
      4,
      3,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000143",
    "status": "inactive",
    "name": "PurePath Equipment",
    "tag": "Equipment",
    "contactName": "Richard Mckinney",
    "industryRating": 3.8,
    "procedures": "[PR-055,PR-045,PR-029,PR-060,PR-061,PR-064,PR-058,PR-055,PR-071,PR-070]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000064,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000065,ORDR-1-0000033,ORDR-2-0000041,ORDR-3-0000022,ORDR-4-0000031,ORDR-5-0000017,ORDR-6-0000049,ORDR-7-0000006,ORDR-8-0000067,ORDR-9-0000018,ORDR-10-0000037,ORDR-11-0000067,ORDR-12-0000058,ORDR-13-0000041,ORDR-14-0000021,ORDR-15-0000051,ORDR-16-0000024,ORDR-17-0000049,ORDR-18-0000078]",
    "spendWeekly": [
      2987,
      3080,
      2909,
      2944,
      2903,
      2922,
      2905,
      2903,
      2902,
      2912,
      3013,
      2994,
      2909,
      3042,
      3001,
      2936,
      2903,
      3062,
      3001,
      3097,
      3100,
      3004,
      3065,
      2916,
      2943,
      2982,
      2909,
      2901,
      2901,
      2920,
      2922,
      3080,
      3099,
      2928,
      3080,
      2904,
      2990,
      2934,
      3099,
      3021,
      3099,
      2900,
      3097,
      2901,
      2982,
      3055,
      2997,
      2967,
      3083,
      2905,
      3073,
      2903
    ],
    "orderRejected": [
      2903,
      11,
      16,
      6,
      19,
      18,
      4,
      14,
      0,
      19,
      12,
      6,
      17,
      11,
      10,
      7,
      19,
      0,
      0,
      1,
      5,
      19,
      0,
      8,
      14,
      6,
      1,
      0,
      12,
      10,
      3,
      7,
      19,
      19,
      15,
      9,
      2,
      19,
      19,
      0,
      9,
      19,
      19,
      0,
      19,
      18,
      6,
      18,
      7,
      0,
      0,
      5,
      12
    ],
    "orderAccepted": [
      9,
      11,
      5,
      3,
      6,
      10,
      5,
      4,
      10,
      0,
      0,
      8,
      11,
      3,
      4,
      9,
      0,
      5,
      5,
      6,
      0,
      0,
      8,
      9,
      5,
      11,
      0,
      11,
      7,
      11,
      10,
      6,
      0,
      3,
      9,
      7,
      8,
      5,
      4,
      5,
      0,
      11,
      10,
      2,
      11,
      8,
      9,
      6,
      0,
      7,
      5,
      11
    ],
    "orderHold": [
      1,
      3,
      0,
      1,
      3,
      1,
      2,
      0,
      4,
      2,
      1,
      1,
      1,
      3,
      4,
      0,
      3,
      4,
      4,
      2,
      0,
      4,
      1,
      4,
      2,
      4,
      0,
      4,
      3,
      0,
      0,
      2,
      3,
      2,
      2,
      1,
      3,
      3,
      3,
      2,
      4,
      0,
      0,
      4,
      2,
      4,
      1,
      1,
      0,
      2,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      0,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      3,
      3,
      4,
      3,
      3,
      3,
      2,
      0,
      1,
      2,
      2,
      1,
      4,
      0,
      1,
      1,
      1,
      0,
      1,
      4,
      1,
      0,
      4,
      3,
      1,
      0,
      3,
      2,
      3,
      2,
      2,
      3,
      4,
      1,
      2,
      4,
      4,
      0,
      1,
      2,
      2,
      0,
      0,
      2,
      0,
      4,
      4,
      0,
      4,
      2,
      3,
      4
    ],
    "issueDelay": [
      2,
      0,
      4,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      0,
      0,
      4,
      4,
      0,
      2,
      1,
      0,
      1,
      4,
      4,
      1,
      2,
      2,
      4,
      1,
      4,
      2,
      4,
      2,
      3,
      2,
      0,
      3,
      4,
      4,
      0,
      4,
      4,
      3,
      0,
      2,
      2,
      3,
      4,
      0,
      0,
      0,
      2,
      0,
      3,
      1
    ],
    "issueQuantity": [
      1,
      0,
      1,
      1,
      3,
      0,
      2,
      1,
      2,
      3,
      2,
      1,
      0,
      2,
      0,
      4,
      4,
      1,
      0,
      3,
      0,
      3,
      3,
      4,
      0,
      4,
      0,
      0,
      4,
      3,
      3,
      4,
      1,
      4,
      1,
      4,
      4,
      0,
      1,
      4,
      4,
      0,
      4,
      4,
      3,
      0,
      2,
      3,
      0,
      3,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000144",
    "status": "active",
    "name": "LifeLine Supplies",
    "tag": "Equipment",
    "contactName": "Taylor Gutierrez",
    "industryRating": 3.3,
    "procedures": "[PR-048,PR-035,PR-027,PR-069,PR-029,PR-058,PR-068,PR-057,PR-054,PR-041]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000056,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000015,ORDR-2-0000024,ORDR-3-0000031,ORDR-4-0000004,ORDR-5-0000068,ORDR-6-0000035,ORDR-7-0000022,ORDR-8-0000038,ORDR-9-0000056,ORDR-10-0000009,ORDR-11-0000017,ORDR-12-0000047,ORDR-13-0000051,ORDR-14-0000048,ORDR-15-0000057,ORDR-16-0000071,ORDR-17-0000021,ORDR-18-0000071]",
    "spendWeekly": [
      2948,
      3082,
      2980,
      2902,
      2903,
      2998,
      2911,
      3057,
      3013,
      2980,
      3030,
      3095,
      3003,
      3061,
      3089,
      2915,
      2913,
      3054,
      3064,
      2953,
      3080,
      3097,
      3045,
      2906,
      3095,
      2901,
      2983,
      2999,
      2938,
      3077,
      3062,
      3026,
      2994,
      2909,
      2913,
      3048,
      3025,
      3034,
      3099,
      3079,
      3014,
      2970,
      2950,
      3004,
      2999,
      2901,
      3041,
      2950,
      3100,
      2905,
      2905,
      3026
    ],
    "orderRejected": [
      3026,
      7,
      7,
      16,
      17,
      14,
      8,
      10,
      15,
      8,
      16,
      7,
      19,
      7,
      5,
      12,
      8,
      0,
      11,
      17,
      10,
      19,
      9,
      19,
      13,
      15,
      12,
      10,
      14,
      19,
      4,
      19,
      4,
      19,
      12,
      13,
      18,
      10,
      19,
      13,
      0,
      19,
      7,
      1,
      10,
      11,
      9,
      7,
      0,
      18,
      14,
      12,
      11
    ],
    "orderAccepted": [
      1,
      0,
      2,
      3,
      7,
      9,
      11,
      0,
      7,
      0,
      3,
      1,
      3,
      1,
      8,
      10,
      5,
      0,
      4,
      3,
      0,
      8,
      4,
      0,
      10,
      11,
      1,
      11,
      10,
      0,
      1,
      11,
      0,
      1,
      6,
      10,
      8,
      0,
      11,
      0,
      8,
      9,
      10,
      0,
      0,
      11,
      6,
      11,
      4,
      7,
      0,
      7
    ],
    "orderHold": [
      2,
      1,
      1,
      2,
      2,
      0,
      2,
      4,
      4,
      1,
      4,
      3,
      1,
      4,
      0,
      0,
      2,
      1,
      4,
      4,
      3,
      0,
      0,
      4,
      1,
      3,
      3,
      1,
      4,
      3,
      2,
      4,
      0,
      4,
      2,
      4,
      4,
      2,
      4,
      3,
      3,
      2,
      4,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      1
    ],
    "orderBackordered": [
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      2,
      1,
      0,
      1,
      2,
      2,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1
    ],
    "issueQuality": [
      3,
      2,
      4,
      0,
      4,
      0,
      0,
      1,
      0,
      2,
      2,
      3,
      4,
      1,
      0,
      0,
      2,
      2,
      2,
      4,
      1,
      0,
      1,
      1,
      3,
      4,
      1,
      2,
      1,
      4,
      3,
      1,
      2,
      2,
      4,
      4,
      4,
      3,
      2,
      2,
      0,
      2,
      0,
      4,
      2,
      4,
      0,
      4,
      1,
      4,
      0,
      1
    ],
    "issueDelay": [
      0,
      2,
      4,
      0,
      0,
      1,
      0,
      2,
      0,
      2,
      2,
      0,
      4,
      2,
      2,
      2,
      0,
      0,
      0,
      3,
      1,
      1,
      3,
      2,
      4,
      1,
      4,
      1,
      4,
      2,
      1,
      1,
      1,
      3,
      3,
      3,
      0,
      4,
      2,
      0,
      0,
      0,
      0,
      1,
      2,
      3,
      0,
      0,
      4,
      0,
      1,
      0
    ],
    "issueQuantity": [
      2,
      1,
      3,
      0,
      4,
      3,
      4,
      4,
      1,
      1,
      2,
      0,
      0,
      3,
      2,
      2,
      2,
      2,
      0,
      4,
      1,
      3,
      3,
      4,
      4,
      3,
      2,
      4,
      4,
      0,
      4,
      0,
      3,
      2,
      4,
      3,
      0,
      0,
      3,
      2,
      1,
      3,
      4,
      2,
      4,
      3,
      0,
      1,
      2,
      0,
      0,
      1
    ]
  },
  {
    "id": "VNDR-0000145",
    "status": "active",
    "name": "ProHealth Systems",
    "tag": "Nutrition, Imaging, Equipment",
    "contactName": "Aaron Howard",
    "industryRating": 4.1,
    "procedures": "[PR-066,PR-057,PR-038,PR-067,PR-033,PR-018,PR-063,PR-039,PR-073,PR-040]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000053,CNTRCT-0000064]",
    "orders": "[ORDR-0-0000015,ORDR-1-0000048,ORDR-2-0000040,ORDR-3-0000013,ORDR-4-0000002,ORDR-5-0000044,ORDR-6-0000056,ORDR-7-0000018,ORDR-8-0000065,ORDR-9-0000035,ORDR-10-0000023,ORDR-11-0000058,ORDR-12-0000015,ORDR-13-0000060,ORDR-14-0000029,ORDR-15-0000043,ORDR-16-0000079,ORDR-17-0000044,ORDR-18-0000025]",
    "spendWeekly": [
      2954,
      3082,
      2995,
      2977,
      2903,
      2979,
      2906,
      2968,
      2930,
      3085,
      2912,
      2964,
      2998,
      3074,
      2968,
      2963,
      3046,
      3033,
      2958,
      3098,
      3055,
      3097,
      3053,
      2903,
      3009,
      3062,
      2943,
      3000,
      2916,
      2902,
      2958,
      2953,
      2960,
      2921,
      2913,
      2922,
      2916,
      2960,
      3041,
      3006,
      3003,
      2941,
      3088,
      3074,
      3076,
      2936,
      2936,
      2920,
      2965,
      2905,
      3008,
      2918
    ],
    "orderRejected": [
      2918,
      4,
      4,
      9,
      6,
      19,
      0,
      0,
      2,
      15,
      19,
      1,
      14,
      8,
      6,
      3,
      18,
      3,
      1,
      12,
      5,
      13,
      12,
      19,
      19,
      17,
      0,
      4,
      16,
      5,
      19,
      13,
      19,
      18,
      7,
      10,
      1,
      19,
      15,
      0,
      0,
      7,
      7,
      5,
      7,
      5,
      9,
      17,
      10,
      19,
      1,
      2,
      13
    ],
    "orderAccepted": [
      7,
      5,
      3,
      3,
      11,
      7,
      0,
      5,
      4,
      11,
      11,
      5,
      11,
      8,
      6,
      11,
      0,
      11,
      2,
      1,
      0,
      1,
      0,
      0,
      6,
      4,
      0,
      2,
      7,
      8,
      9,
      4,
      0,
      8,
      3,
      7,
      3,
      0,
      3,
      8,
      7,
      11,
      6,
      7,
      0,
      8,
      7,
      11,
      1,
      10,
      5,
      1
    ],
    "orderHold": [
      3,
      3,
      3,
      0,
      3,
      1,
      3,
      1,
      0,
      3,
      1,
      1,
      3,
      3,
      0,
      3,
      4,
      1,
      4,
      0,
      2,
      3,
      3,
      4,
      1,
      2,
      2,
      3,
      3,
      2,
      0,
      3,
      3,
      2,
      3,
      3,
      4,
      0,
      2,
      3,
      3,
      0,
      0,
      2,
      2,
      4,
      0,
      3,
      3,
      0,
      2,
      3
    ],
    "orderBackordered": [
      3,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      1,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      3,
      1,
      1,
      4,
      0,
      0,
      4,
      4,
      4,
      2,
      4,
      3,
      2,
      0,
      1,
      4,
      0,
      4,
      2,
      2,
      0,
      4,
      2,
      4,
      4,
      3,
      2,
      4,
      0,
      4,
      2,
      1,
      4,
      4,
      3,
      1,
      4,
      0,
      4,
      4,
      1,
      2,
      2,
      4,
      3,
      1,
      2,
      0,
      3,
      2,
      0,
      1
    ],
    "issueDelay": [
      1,
      0,
      3,
      4,
      3,
      4,
      2,
      2,
      3,
      4,
      3,
      3,
      4,
      3,
      3,
      1,
      4,
      2,
      1,
      3,
      2,
      3,
      2,
      4,
      3,
      1,
      3,
      4,
      0,
      1,
      4,
      4,
      0,
      3,
      3,
      3,
      0,
      4,
      4,
      1,
      0,
      1,
      1,
      2,
      0,
      3,
      4,
      1,
      4,
      4,
      2,
      2
    ],
    "issueQuantity": [
      4,
      2,
      1,
      4,
      4,
      0,
      2,
      2,
      3,
      1,
      3,
      1,
      1,
      1,
      4,
      4,
      0,
      1,
      4,
      4,
      4,
      1,
      2,
      2,
      2,
      4,
      3,
      3,
      0,
      1,
      0,
      1,
      4,
      4,
      2,
      4,
      4,
      1,
      0,
      1,
      2,
      2,
      0,
      4,
      2,
      0,
      4,
      2,
      0,
      4,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000146",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Medical Supplies, Facilities Management, Imaging",
    "contactName": "Taylor Gutierrez",
    "industryRating": 3.1,
    "procedures": "[PR-061,PR-032,PR-013,PR-069,PR-041,PR-069,PR-064,PR-072,PR-063,PR-044]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000060,CNTRCT-0000058]",
    "orders": "[ORDR-0-0000060,ORDR-1-0000043,ORDR-2-0000015,ORDR-3-0000047,ORDR-4-0000054,ORDR-5-0000030,ORDR-6-0000060,ORDR-7-0000051,ORDR-8-0000024,ORDR-9-0000032,ORDR-10-0000038,ORDR-11-0000075,ORDR-12-0000019,ORDR-13-0000032,ORDR-14-0000046,ORDR-15-0000057,ORDR-16-0000069,ORDR-17-0000040,ORDR-18-0000075]",
    "spendWeekly": [
      3023,
      3076,
      2910,
      3089,
      2946,
      2905,
      3057,
      3014,
      2989,
      2957,
      3029,
      2906,
      3010,
      2937,
      2990,
      2901,
      2953,
      3099,
      2986,
      3098,
      3069,
      2995,
      3097,
      3077,
      2978,
      3096,
      2916,
      2991,
      2944,
      3056,
      2990,
      3097,
      3009,
      2979,
      2947,
      2965,
      3020,
      2902,
      3054,
      3049,
      2976,
      2900,
      3097,
      3004,
      3018,
      2923,
      3049,
      2984,
      3081,
      2905,
      2975,
      2953
    ],
    "orderRejected": [
      2953,
      15,
      3,
      0,
      0,
      19,
      12,
      5,
      15,
      19,
      12,
      11,
      17,
      7,
      19,
      0,
      6,
      12,
      16,
      0,
      8,
      19,
      2,
      9,
      11,
      7,
      10,
      0,
      19,
      9,
      13,
      11,
      18,
      12,
      9,
      11,
      10,
      7,
      10,
      5,
      11,
      13,
      7,
      1,
      9,
      14,
      3,
      16,
      19,
      5,
      11,
      8,
      16
    ],
    "orderAccepted": [
      11,
      11,
      5,
      11,
      4,
      9,
      5,
      4,
      1,
      5,
      9,
      2,
      2,
      8,
      3,
      3,
      0,
      5,
      2,
      1,
      4,
      5,
      5,
      0,
      11,
      9,
      0,
      11,
      6,
      9,
      11,
      10,
      1,
      0,
      4,
      11,
      6,
      0,
      8,
      0,
      4,
      8,
      2,
      6,
      6,
      11,
      3,
      5,
      4,
      4,
      2,
      5
    ],
    "orderHold": [
      3,
      0,
      4,
      0,
      0,
      2,
      2,
      4,
      3,
      4,
      0,
      2,
      1,
      3,
      3,
      1,
      1,
      0,
      4,
      1,
      0,
      3,
      4,
      2,
      0,
      2,
      0,
      1,
      2,
      0,
      3,
      0,
      3,
      2,
      0,
      2,
      3,
      3,
      2,
      0,
      3,
      1,
      0,
      1,
      2,
      3,
      1,
      2,
      2,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      0,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      0,
      1,
      1,
      3,
      1,
      4,
      2,
      0,
      2,
      3,
      0,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      3,
      1,
      2,
      3,
      1,
      1,
      4,
      3,
      0,
      1,
      2,
      1,
      4,
      4,
      0,
      1,
      3,
      3,
      0,
      0,
      1,
      3,
      2,
      2,
      0,
      4,
      4,
      4,
      2,
      4
    ],
    "issueDelay": [
      2,
      4,
      3,
      0,
      4,
      2,
      2,
      4,
      0,
      4,
      0,
      3,
      4,
      4,
      1,
      1,
      4,
      0,
      0,
      0,
      2,
      1,
      0,
      0,
      1,
      2,
      4,
      0,
      2,
      1,
      0,
      1,
      4,
      1,
      4,
      1,
      0,
      0,
      4,
      2,
      0,
      4,
      3,
      4,
      4,
      0,
      0,
      3,
      3,
      4,
      4,
      2
    ],
    "issueQuantity": [
      1,
      2,
      4,
      2,
      0,
      1,
      2,
      0,
      1,
      0,
      2,
      0,
      3,
      0,
      4,
      2,
      4,
      1,
      0,
      4,
      3,
      3,
      4,
      0,
      1,
      2,
      3,
      4,
      1,
      4,
      0,
      4,
      4,
      0,
      0,
      3,
      4,
      1,
      1,
      3,
      4,
      3,
      4,
      1,
      3,
      2,
      2,
      2,
      1,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000147",
    "status": "inactive",
    "name": "CareBridge Equipment",
    "tag": "Laboratory Supplies, Equipment",
    "contactName": "David Bird",
    "industryRating": 2.8,
    "procedures": "[PR-017,PR-035,PR-057,PR-066,PR-053,PR-059,PR-030,PR-070,PR-053,PR-027]",
    "contracts": "[CNTRCT-0000065,CNTRCT-0000063,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000013,ORDR-1-0000046,ORDR-2-0000031,ORDR-3-0000048,ORDR-4-0000061,ORDR-5-0000051,ORDR-6-0000008,ORDR-7-0000025,ORDR-8-0000017,ORDR-9-0000038,ORDR-10-0000067,ORDR-11-0000018,ORDR-12-0000057,ORDR-13-0000074,ORDR-14-0000069,ORDR-15-0000050,ORDR-16-0000075,ORDR-17-0000080,ORDR-18-0000028]",
    "spendWeekly": [
      2901,
      2946,
      2906,
      2936,
      3002,
      2956,
      3008,
      2982,
      2902,
      3003,
      3031,
      2908,
      3033,
      3000,
      3082,
      2901,
      2989,
      3099,
      2900,
      2993,
      3098,
      3097,
      2917,
      2903,
      3045,
      3014,
      2969,
      2979,
      2968,
      2977,
      3025,
      3097,
      2999,
      3025,
      3083,
      2918,
      2977,
      2975,
      2948,
      2951,
      2968,
      2981,
      3058,
      2943,
      2903,
      2901,
      2934,
      2900,
      3100,
      2905,
      2993,
      2926
    ],
    "orderRejected": [
      2926,
      19,
      8,
      6,
      14,
      19,
      13,
      14,
      14,
      19,
      0,
      19,
      13,
      18,
      3,
      19,
      9,
      5,
      8,
      19,
      14,
      19,
      5,
      13,
      12,
      15,
      19,
      3,
      19,
      19,
      18,
      19,
      13,
      19,
      0,
      15,
      0,
      4,
      19,
      5,
      16,
      18,
      6,
      0,
      9,
      15,
      15,
      4,
      15,
      16,
      0,
      13,
      19
    ],
    "orderAccepted": [
      0,
      1,
      7,
      9,
      3,
      5,
      11,
      10,
      4,
      9,
      7,
      0,
      1,
      11,
      11,
      7,
      3,
      7,
      4,
      0,
      3,
      5,
      11,
      6,
      5,
      3,
      0,
      11,
      0,
      0,
      11,
      9,
      0,
      0,
      5,
      11,
      3,
      0,
      0,
      2,
      10,
      0,
      11,
      5,
      5,
      10,
      0,
      11,
      0,
      11,
      6,
      3
    ],
    "orderHold": [
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      4,
      2,
      0,
      4,
      4,
      1,
      4,
      2,
      0,
      4,
      0,
      3,
      1,
      0,
      4,
      0,
      0,
      1,
      2,
      4,
      2,
      3,
      0,
      2,
      2,
      3,
      3,
      1,
      1,
      3,
      3,
      3,
      4,
      0,
      4,
      1,
      0,
      0,
      2,
      4,
      0,
      0,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      1,
      4,
      3,
      2,
      2,
      0,
      4,
      0,
      3,
      2,
      2,
      4,
      2,
      1,
      2,
      4,
      1,
      3,
      3,
      1,
      0,
      0,
      2,
      1,
      1,
      2,
      0,
      4,
      4,
      2,
      1,
      2,
      0,
      1,
      4,
      0,
      0,
      0,
      3,
      0,
      4,
      1,
      1,
      4,
      4,
      2,
      0,
      3,
      3,
      0,
      1
    ],
    "issueDelay": [
      3,
      3,
      4,
      4,
      0,
      2,
      4,
      0,
      0,
      0,
      2,
      2,
      4,
      2,
      1,
      4,
      3,
      0,
      0,
      0,
      4,
      0,
      0,
      3,
      1,
      4,
      4,
      0,
      1,
      4,
      4,
      2,
      2,
      1,
      4,
      4,
      0,
      2,
      0,
      3,
      4,
      3,
      1,
      0,
      3,
      1,
      1,
      2,
      2,
      0,
      3,
      2
    ],
    "issueQuantity": [
      4,
      0,
      2,
      0,
      2,
      1,
      4,
      0,
      0,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      3,
      1,
      0,
      3,
      1,
      0,
      0,
      2,
      2,
      4,
      3,
      0,
      1,
      4,
      1,
      0,
      4,
      4,
      2,
      0,
      3,
      1,
      2,
      2,
      3,
      3,
      3,
      0,
      3,
      2,
      4,
      0,
      0,
      4,
      1,
      4
    ]
  },
  {
    "id": "VNDR-0000148",
    "status": "active",
    "name": "WellnessWare Group",
    "tag": "Facilities Management, Equipment",
    "contactName": "Scott Krueger",
    "industryRating": 4.4,
    "procedures": "[PR-041,PR-031,PR-049,PR-065,PR-009,PR-070,PR-067,PR-064,PR-055,PR-071]",
    "contracts": "[CNTRCT-0000049,CNTRCT-0000061,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000022,ORDR-1-0000051,ORDR-2-0000005,ORDR-3-0000020,ORDR-4-0000029,ORDR-5-0000064,ORDR-6-0000052,ORDR-7-0000051,ORDR-8-0000026,ORDR-9-0000020,ORDR-10-0000046,ORDR-11-0000022,ORDR-12-0000046,ORDR-13-0000058,ORDR-14-0000075,ORDR-15-0000070,ORDR-16-0000031,ORDR-17-0000077,ORDR-18-0000028]",
    "spendWeekly": [
      2987,
      2934,
      2917,
      2943,
      2946,
      3029,
      2962,
      3016,
      2902,
      2902,
      2932,
      3007,
      2909,
      2944,
      2953,
      2901,
      2979,
      3000,
      2981,
      3021,
      2917,
      3084,
      2960,
      2903,
      2906,
      3047,
      2909,
      3062,
      2932,
      2956,
      2990,
      3066,
      3031,
      2977,
      3061,
      2926,
      2995,
      2956,
      3014,
      2995,
      2901,
      2956,
      2990,
      2901,
      2952,
      2950,
      3072,
      2900,
      3100,
      3030,
      3062,
      3031
    ],
    "orderRejected": [
      3031,
      19,
      11,
      7,
      11,
      17,
      18,
      18,
      19,
      17,
      0,
      5,
      19,
      8,
      5,
      16,
      14,
      0,
      0,
      7,
      0,
      12,
      11,
      17,
      12,
      13,
      12,
      0,
      19,
      12,
      12,
      9,
      19,
      11,
      0,
      16,
      5,
      19,
      15,
      0,
      0,
      13,
      14,
      0,
      14,
      10,
      0,
      8,
      8,
      2,
      13,
      2,
      15
    ],
    "orderAccepted": [
      3,
      11,
      5,
      9,
      6,
      4,
      11,
      6,
      10,
      0,
      10,
      8,
      4,
      5,
      7,
      6,
      0,
      10,
      7,
      3,
      6,
      9,
      11,
      6,
      3,
      6,
      4,
      9,
      5,
      10,
      6,
      11,
      0,
      0,
      11,
      11,
      7,
      1,
      10,
      6,
      7,
      11,
      1,
      1,
      5,
      11,
      3,
      11,
      4,
      0,
      2,
      5
    ],
    "orderHold": [
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      2,
      4,
      1,
      3,
      3,
      1,
      3,
      3,
      3,
      2,
      2,
      4,
      3,
      2,
      4,
      4,
      4,
      1,
      4,
      0,
      3,
      4,
      0,
      3,
      0,
      1,
      2,
      3,
      2,
      4,
      4,
      2,
      0,
      4,
      2,
      1,
      2,
      2,
      2,
      2,
      2,
      0,
      1,
      1,
      3
    ],
    "orderBackordered": [
      3,
      2,
      1,
      0,
      0,
      0,
      0,
      2,
      2,
      2,
      0,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      0,
      0,
      0,
      0,
      3,
      0,
      3,
      4,
      0,
      2,
      2,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      3,
      4,
      0,
      0,
      3,
      4,
      1,
      1,
      1,
      2,
      2,
      1,
      4,
      0,
      1,
      4,
      2,
      1,
      0,
      2,
      4,
      1,
      0,
      0,
      2,
      3,
      2,
      0,
      4,
      2,
      4,
      0,
      2
    ],
    "issueDelay": [
      2,
      3,
      4,
      2,
      0,
      3,
      3,
      4,
      2,
      2,
      1,
      2,
      4,
      0,
      1,
      0,
      3,
      3,
      2,
      2,
      2,
      2,
      0,
      1,
      3,
      4,
      2,
      1,
      1,
      2,
      0,
      4,
      4,
      2,
      2,
      0,
      0,
      4,
      4,
      4,
      3,
      3,
      2,
      3,
      3,
      0,
      0,
      0,
      3,
      3,
      3,
      0
    ],
    "issueQuantity": [
      2,
      1,
      2,
      0,
      3,
      4,
      4,
      0,
      2,
      0,
      4,
      3,
      0,
      2,
      3,
      2,
      4,
      1,
      3,
      0,
      4,
      2,
      2,
      4,
      0,
      2,
      2,
      2,
      1,
      4,
      2,
      4,
      2,
      2,
      0,
      4,
      4,
      1,
      0,
      2,
      2,
      3,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      4,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000149",
    "status": "active",
    "name": "BioMedix International",
    "tag": "Facilities Management, Laboratory Supplies",
    "contactName": "Luis Mccarthy",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-050,PR-038,PR-062,PR-056,PR-071,PR-065,PR-041,PR-073,PR-071]",
    "contracts": "[CNTRCT-0000064,CNTRCT-0000064,CNTRCT-0000027]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000014,ORDR-2-0000009,ORDR-3-0000016,ORDR-4-0000028,ORDR-5-0000037,ORDR-6-0000051,ORDR-7-0000016,ORDR-8-0000013,ORDR-9-0000040,ORDR-10-0000009,ORDR-11-0000071,ORDR-12-0000044,ORDR-13-0000039,ORDR-14-0000077,ORDR-15-0000038,ORDR-16-0000059,ORDR-17-0000030,ORDR-18-0000050]",
    "spendWeekly": [
      2944,
      3015,
      2934,
      2916,
      2998,
      2911,
      3023,
      3098,
      2961,
      3099,
      2982,
      3036,
      2909,
      3094,
      2916,
      2901,
      3049,
      2977,
      2963,
      3014,
      3100,
      3097,
      2904,
      2951,
      3095,
      3096,
      2909,
      2987,
      2901,
      3032,
      2903,
      3085,
      3073,
      3035,
      3095,
      2914,
      2907,
      2974,
      3099,
      3099,
      3046,
      2947,
      3041,
      2980,
      2935,
      2942,
      3077,
      2900,
      2999,
      3025,
      3049,
      3089
    ],
    "orderRejected": [
      3089,
      19,
      6,
      6,
      19,
      19,
      7,
      9,
      6,
      19,
      16,
      9,
      5,
      6,
      19,
      8,
      14,
      0,
      8,
      9,
      19,
      19,
      4,
      11,
      19,
      7,
      10,
      0,
      14,
      2,
      12,
      19,
      8,
      17,
      10,
      12,
      0,
      10,
      19,
      15,
      3,
      19,
      2,
      0,
      5,
      6,
      11,
      18,
      19,
      19,
      2,
      17,
      19
    ],
    "orderAccepted": [
      9,
      4,
      4,
      4,
      6,
      3,
      11,
      5,
      8,
      0,
      9,
      1,
      6,
      7,
      11,
      11,
      6,
      5,
      0,
      7,
      0,
      0,
      4,
      1,
      4,
      11,
      4,
      11,
      7,
      7,
      8,
      1,
      0,
      0,
      0,
      9,
      11,
      0,
      8,
      5,
      0,
      4,
      9,
      11,
      11,
      2,
      4,
      11,
      0,
      11,
      5,
      8
    ],
    "orderHold": [
      2,
      4,
      3,
      2,
      2,
      0,
      2,
      4,
      1,
      1,
      4,
      3,
      0,
      3,
      3,
      1,
      2,
      0,
      4,
      3,
      0,
      2,
      0,
      4,
      1,
      0,
      1,
      2,
      4,
      0,
      1,
      4,
      4,
      1,
      2,
      4,
      0,
      0,
      4,
      0,
      1,
      0,
      3,
      2,
      3,
      4,
      2,
      1,
      0,
      3,
      4,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      0,
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      1,
      0,
      2,
      3,
      1,
      4,
      4,
      4,
      3,
      2,
      3,
      0,
      0,
      0,
      0,
      1,
      1,
      4,
      3,
      0,
      3,
      2,
      0,
      4,
      2,
      1,
      3,
      2,
      0,
      1,
      0,
      1,
      2,
      2,
      0,
      0,
      3,
      4,
      1,
      4,
      0,
      2,
      3,
      4,
      0,
      2,
      2,
      3,
      0,
      4
    ],
    "issueDelay": [
      0,
      0,
      3,
      3,
      1,
      3,
      0,
      0,
      2,
      2,
      0,
      2,
      4,
      4,
      3,
      1,
      1,
      0,
      1,
      3,
      2,
      1,
      1,
      1,
      0,
      2,
      3,
      2,
      2,
      4,
      4,
      4,
      2,
      2,
      2,
      1,
      0,
      4,
      2,
      4,
      0,
      0,
      2,
      0,
      0,
      0,
      0,
      0,
      4,
      3,
      0,
      4
    ],
    "issueQuantity": [
      4,
      0,
      2,
      0,
      4,
      1,
      4,
      0,
      0,
      0,
      3,
      1,
      0,
      1,
      1,
      3,
      4,
      0,
      0,
      4,
      1,
      3,
      2,
      4,
      1,
      4,
      0,
      0,
      4,
      0,
      2,
      1,
      4,
      1,
      3,
      2,
      4,
      0,
      4,
      4,
      4,
      3,
      2,
      1,
      3,
      0,
      4,
      0,
      0,
      4,
      2,
      0
    ]
  },
  {
    "id": "VNDR-0000150",
    "status": "active",
    "name": "PharmaSphere Equipment",
    "tag": "PPE, Medical Supplies, Facilities Management",
    "contactName": "Brian Baird",
    "industryRating": 4.8,
    "procedures": "[PR-039,PR-050,PR-068,PR-065,PR-054,PR-071,PR-072,PR-069,PR-064,PR-037]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000053,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000015,ORDR-2-0000031,ORDR-3-0000009,ORDR-4-0000008,ORDR-5-0000040,ORDR-6-0000065,ORDR-7-0000019,ORDR-8-0000044,ORDR-9-0000039,ORDR-10-0000019,ORDR-11-0000019,ORDR-12-0000022,ORDR-13-0000037,ORDR-14-0000035,ORDR-15-0000060,ORDR-16-0000024,ORDR-17-0000023,ORDR-18-0000052]",
    "spendWeekly": [
      2942,
      2943,
      2941,
      2902,
      3049,
      2905,
      2905,
      3098,
      3100,
      2924,
      3055,
      2957,
      3012,
      2904,
      3091,
      2922,
      2954,
      3099,
      3051,
      2968,
      3068,
      3058,
      3083,
      3058,
      2906,
      2901,
      2972,
      2937,
      3096,
      2962,
      3031,
      2901,
      3048,
      2905,
      3043,
      2972,
      2955,
      3069,
      3000,
      2925,
      2984,
      3082,
      3042,
      3091,
      2925,
      2901,
      3050,
      2967,
      3038,
      3097,
      2982,
      2932
    ],
    "orderRejected": [
      2932,
      6,
      17,
      5,
      14,
      12,
      0,
      19,
      13,
      6,
      14,
      11,
      17,
      5,
      9,
      17,
      19,
      8,
      10,
      0,
      8,
      0,
      3,
      15,
      12,
      7,
      8,
      11,
      19,
      6,
      19,
      18,
      2,
      12,
      19,
      15,
      12,
      6,
      19,
      14,
      5,
      17,
      0,
      7,
      0,
      13,
      0,
      6,
      6,
      12,
      12,
      1,
      0
    ],
    "orderAccepted": [
      10,
      9,
      5,
      11,
      7,
      3,
      11,
      9,
      4,
      4,
      6,
      5,
      5,
      8,
      2,
      2,
      7,
      7,
      6,
      4,
      11,
      0,
      10,
      4,
      11,
      11,
      2,
      11,
      5,
      3,
      5,
      6,
      0,
      1,
      7,
      2,
      11,
      6,
      10,
      11,
      2,
      9,
      7,
      6,
      0,
      3,
      0,
      11,
      8,
      9,
      3,
      7
    ],
    "orderHold": [
      1,
      0,
      0,
      2,
      1,
      2,
      4,
      3,
      4,
      2,
      4,
      2,
      0,
      1,
      2,
      1,
      4,
      2,
      1,
      3,
      4,
      1,
      1,
      4,
      2,
      4,
      4,
      0,
      2,
      0,
      1,
      3,
      4,
      0,
      1,
      2,
      1,
      4,
      4,
      3,
      1,
      0,
      4,
      3,
      2,
      3,
      2,
      3,
      1,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      2,
      2,
      0,
      2,
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      0,
      0,
      0,
      2,
      3,
      2,
      2,
      0,
      2,
      4,
      0,
      4,
      0,
      2,
      2,
      3,
      0,
      3,
      4,
      4,
      0,
      4,
      0,
      0,
      4,
      3,
      2,
      3,
      2,
      1,
      0,
      0,
      0,
      1,
      3,
      4,
      0,
      2,
      1,
      2,
      1,
      1,
      3,
      4,
      4,
      0,
      1,
      4
    ],
    "issueDelay": [
      2,
      1,
      1,
      4,
      0,
      3,
      2,
      0,
      1,
      2,
      2,
      4,
      4,
      4,
      3,
      1,
      4,
      0,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      4,
      2,
      0,
      2,
      3,
      4,
      4,
      1,
      3,
      2,
      2,
      0,
      4,
      1,
      0,
      0,
      4,
      1,
      2,
      3,
      1,
      2,
      1,
      0,
      0,
      4
    ],
    "issueQuantity": [
      3,
      0,
      1,
      3,
      2,
      4,
      4,
      3,
      0,
      2,
      3,
      0,
      0,
      4,
      4,
      2,
      1,
      4,
      1,
      4,
      4,
      3,
      4,
      0,
      3,
      0,
      3,
      2,
      4,
      1,
      0,
      2,
      4,
      3,
      0,
      4,
      4,
      0,
      3,
      3,
      4,
      4,
      3,
      2,
      4,
      2,
      4,
      2,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000151",
    "status": "active",
    "name": "HealthCraft Logistics",
    "tag": "Equipment, IT Services",
    "contactName": "Courtney Gomez",
    "industryRating": 3.3,
    "procedures": "[PR-066,PR-065,PR-068,PR-064,PR-069,PR-070,PR-052,PR-072,PR-072,PR-048]",
    "contracts": "[CNTRCT-0000040,CNTRCT-0000062,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000047,ORDR-1-0000039,ORDR-2-0000046,ORDR-3-0000001,ORDR-4-0000037,ORDR-5-0000064,ORDR-6-0000019,ORDR-7-0000010,ORDR-8-0000009,ORDR-9-0000073,ORDR-10-0000073,ORDR-11-0000050,ORDR-12-0000011,ORDR-13-0000051,ORDR-14-0000071,ORDR-15-0000014,ORDR-16-0000071,ORDR-17-0000059,ORDR-18-0000035]",
    "spendWeekly": [
      2982,
      3025,
      3002,
      2925,
      3095,
      2905,
      3023,
      3075,
      2980,
      3079,
      2948,
      2969,
      2945,
      3012,
      2909,
      2901,
      2903,
      3038,
      2900,
      3098,
      2966,
      3039,
      3097,
      2903,
      2923,
      3095,
      3061,
      2944,
      3044,
      3017,
      3096,
      3097,
      2995,
      2983,
      3045,
      2971,
      2917,
      3001,
      2906,
      3032,
      3069,
      2903,
      3039,
      3059,
      2976,
      2982,
      2994,
      2900,
      3100,
      2905,
      3002,
      3093
    ],
    "orderRejected": [
      3093,
      4,
      18,
      15,
      11,
      19,
      3,
      0,
      19,
      19,
      2,
      10,
      3,
      11,
      7,
      16,
      19,
      10,
      5,
      0,
      0,
      6,
      12,
      19,
      15,
      10,
      0,
      5,
      19,
      19,
      18,
      19,
      19,
      19,
      13,
      13,
      3,
      11,
      2,
      10,
      0,
      16,
      5,
      3,
      1,
      16,
      0,
      18,
      14,
      19,
      0,
      14,
      5
    ],
    "orderAccepted": [
      11,
      6,
      1,
      11,
      0,
      3,
      2,
      4,
      4,
      3,
      6,
      7,
      3,
      8,
      0,
      11,
      3,
      6,
      6,
      11,
      10,
      0,
      7,
      5,
      7,
      8,
      8,
      2,
      6,
      1,
      11,
      8,
      0,
      0,
      0,
      5,
      5,
      1,
      11,
      6,
      0,
      7,
      8,
      3,
      0,
      4,
      8,
      6,
      0,
      8,
      0,
      4
    ],
    "orderHold": [
      0,
      0,
      1,
      0,
      4,
      4,
      0,
      0,
      2,
      4,
      0,
      0,
      1,
      2,
      0,
      2,
      4,
      3,
      4,
      0,
      0,
      3,
      2,
      4,
      2,
      4,
      3,
      1,
      4,
      0,
      0,
      3,
      1,
      4,
      0,
      2,
      4,
      2,
      2,
      1,
      0,
      3,
      4,
      4,
      2,
      4,
      2,
      1,
      0,
      4,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      2,
      1,
      2,
      1,
      2,
      2,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      0,
      0,
      1,
      2,
      0,
      0,
      0,
      0,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1
    ],
    "issueQuality": [
      2,
      1,
      2,
      4,
      2,
      4,
      2,
      3,
      4,
      2,
      3,
      0,
      4,
      3,
      0,
      4,
      3,
      2,
      2,
      0,
      1,
      1,
      2,
      3,
      4,
      0,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      3,
      1,
      4,
      0,
      0,
      0,
      1,
      2,
      2,
      4,
      0,
      2,
      4,
      2,
      2,
      4,
      3,
      1,
      4
    ],
    "issueDelay": [
      1,
      2,
      1,
      4,
      0,
      3,
      1,
      1,
      1,
      2,
      2,
      2,
      3,
      0,
      3,
      2,
      4,
      3,
      2,
      1,
      1,
      4,
      0,
      0,
      1,
      4,
      2,
      2,
      3,
      4,
      0,
      3,
      4,
      1,
      2,
      3,
      0,
      1,
      3,
      4,
      4,
      1,
      1,
      0,
      4,
      1,
      0,
      2,
      4,
      2,
      2,
      2
    ],
    "issueQuantity": [
      0,
      0,
      1,
      0,
      0,
      2,
      1,
      0,
      4,
      0,
      0,
      1,
      0,
      1,
      2,
      3,
      1,
      4,
      0,
      1,
      2,
      2,
      3,
      3,
      0,
      1,
      0,
      0,
      3,
      3,
      1,
      3,
      1,
      4,
      1,
      0,
      4,
      0,
      0,
      4,
      2,
      3,
      2,
      0,
      1,
      1,
      4,
      4,
      3,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000152",
    "status": "active",
    "name": "HealthStream Supplies",
    "tag": "Imaging",
    "contactName": "Jennifer Yoder ​",
    "industryRating": 2.3,
    "procedures": "[PR-023,PR-044,PR-063,PR-039,PR-056,PR-064,PR-055,PR-062,PR-069,PR-074]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000046,CNTRCT-0000059]",
    "orders": "[ORDR-0-0000054,ORDR-1-0000013,ORDR-2-0000030,ORDR-3-0000038,ORDR-4-0000009,ORDR-5-0000026,ORDR-6-0000051,ORDR-7-0000039,ORDR-8-0000045,ORDR-9-0000071,ORDR-10-0000022,ORDR-11-0000010,ORDR-12-0000037,ORDR-13-0000035,ORDR-14-0000013,ORDR-15-0000060,ORDR-16-0000054,ORDR-17-0000081,ORDR-18-0000025]",
    "spendWeekly": [
      2980,
      3078,
      2953,
      2994,
      2952,
      3062,
      2940,
      3046,
      2938,
      2999,
      3036,
      2975,
      2957,
      2901,
      3070,
      2908,
      2908,
      3097,
      2945,
      3042,
      3059,
      3085,
      2914,
      2964,
      2974,
      3008,
      2993,
      3096,
      2916,
      2902,
      2903,
      3016,
      2917,
      3047,
      3060,
      2904,
      2976,
      3027,
      2947,
      3058,
      2941,
      2990,
      3087,
      3055,
      2902,
      2984,
      2995,
      3099,
      2977,
      2905,
      3092,
      2997
    ],
    "orderRejected": [
      2997,
      4,
      0,
      6,
      16,
      14,
      16,
      11,
      5,
      10,
      11,
      19,
      15,
      11,
      6,
      16,
      19,
      7,
      17,
      19,
      16,
      10,
      3,
      16,
      7,
      3,
      3,
      3,
      5,
      16,
      5,
      19,
      6,
      8,
      5,
      2,
      5,
      18,
      11,
      8,
      14,
      13,
      12,
      0,
      7,
      11,
      15,
      10,
      17,
      9,
      15,
      7,
      7
    ],
    "orderAccepted": [
      5,
      4,
      3,
      6,
      5,
      4,
      10,
      6,
      5,
      6,
      11,
      6,
      8,
      9,
      11,
      4,
      0,
      4,
      9,
      9,
      2,
      0,
      5,
      10,
      8,
      10,
      5,
      11,
      8,
      10,
      9,
      8,
      3,
      4,
      0,
      9,
      10,
      0,
      8,
      3,
      0,
      11,
      11,
      6,
      8,
      5,
      2,
      4,
      2,
      7,
      4,
      9
    ],
    "orderHold": [
      3,
      3,
      2,
      2,
      1,
      1,
      0,
      0,
      3,
      4,
      1,
      3,
      1,
      1,
      2,
      2,
      2,
      4,
      3,
      2,
      2,
      1,
      3,
      3,
      0,
      4,
      2,
      0,
      3,
      1,
      1,
      2,
      3,
      4,
      0,
      2,
      1,
      0,
      1,
      4,
      2,
      3,
      3,
      4,
      2,
      0,
      4,
      3,
      3,
      4,
      1,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      0,
      0,
      2,
      0,
      0,
      1,
      2,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      2,
      4,
      2,
      0,
      3,
      4,
      3,
      3,
      3,
      3,
      4,
      0,
      2,
      2,
      1,
      4,
      3,
      2,
      4,
      2,
      2,
      0,
      1,
      2,
      0,
      3,
      2,
      4,
      2,
      4,
      2,
      4,
      0,
      2,
      4,
      1,
      1,
      3,
      4,
      1,
      3,
      3,
      1,
      2,
      1,
      3,
      0,
      4,
      1,
      0,
      3
    ],
    "issueDelay": [
      0,
      0,
      4,
      1,
      3,
      2,
      2,
      2,
      0,
      3,
      0,
      3,
      4,
      3,
      0,
      1,
      4,
      1,
      3,
      2,
      1,
      4,
      4,
      3,
      4,
      4,
      2,
      0,
      0,
      4,
      3,
      1,
      0,
      2,
      4,
      1,
      1,
      4,
      0,
      4,
      3,
      0,
      2,
      0,
      4,
      0,
      1,
      0,
      1,
      2,
      0,
      4
    ],
    "issueQuantity": [
      2,
      2,
      0,
      0,
      3,
      0,
      2,
      0,
      0,
      1,
      2,
      2,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      3,
      2,
      3,
      3,
      3,
      0,
      3,
      1,
      2,
      2,
      2,
      0,
      4,
      4,
      2,
      0,
      3,
      4,
      1,
      1,
      4,
      4,
      1,
      2,
      4,
      0,
      2,
      4,
      3,
      4,
      4,
      2,
      4
    ]
  },
  {
    "id": "VNDR-0000153",
    "status": "active",
    "name": "PharmaSphere Equipment",
    "tag": "Imaging, IT Services, Laboratory Supplies",
    "contactName": "Robert Adams",
    "industryRating": 3,
    "procedures": "[PR-039,PR-055,PR-029,PR-060,PR-029,PR-036,PR-021,PR-055,PR-020,PR-054]",
    "contracts": "[CNTRCT-0000065,CNTRCT-0000061,CNTRCT-0000065]",
    "orders": "[ORDR-0-0000049,ORDR-1-0000065,ORDR-2-0000023,ORDR-3-0000055,ORDR-4-0000045,ORDR-5-0000037,ORDR-6-0000053,ORDR-7-0000006,ORDR-8-0000071,ORDR-9-0000056,ORDR-10-0000014,ORDR-11-0000066,ORDR-12-0000030,ORDR-13-0000014,ORDR-14-0000036,ORDR-15-0000031,ORDR-16-0000034,ORDR-17-0000072,ORDR-18-0000077]",
    "spendWeekly": [
      3001,
      3032,
      2975,
      3008,
      3088,
      3007,
      3013,
      2921,
      2933,
      2991,
      2967,
      3040,
      2930,
      3061,
      3066,
      2917,
      3068,
      3057,
      2900,
      3006,
      3003,
      3001,
      2913,
      2903,
      3016,
      3096,
      2909,
      3067,
      2901,
      2954,
      2903,
      3008,
      2995,
      3040,
      3042,
      2904,
      3001,
      3073,
      2975,
      3098,
      2901,
      2991,
      2984,
      2966,
      3100,
      2946,
      3055,
      2900,
      3100,
      3074,
      3013,
      3007
    ],
    "orderRejected": [
      3007,
      17,
      5,
      8,
      16,
      19,
      11,
      11,
      13,
      16,
      19,
      10,
      17,
      18,
      14,
      7,
      19,
      5,
      19,
      13,
      9,
      19,
      0,
      11,
      19,
      19,
      10,
      0,
      19,
      19,
      5,
      19,
      19,
      11,
      17,
      15,
      0,
      17,
      16,
      5,
      13,
      14,
      4,
      14,
      7,
      3,
      14,
      17,
      0,
      18,
      6,
      2,
      11
    ],
    "orderAccepted": [
      9,
      4,
      0,
      4,
      11,
      2,
      7,
      0,
      2,
      5,
      10,
      4,
      5,
      3,
      11,
      9,
      0,
      9,
      8,
      8,
      0,
      5,
      8,
      4,
      11,
      2,
      0,
      11,
      7,
      7,
      10,
      3,
      2,
      11,
      2,
      7,
      11,
      0,
      11,
      9,
      4,
      11,
      11,
      11,
      10,
      6,
      4,
      9,
      5,
      8,
      1,
      10
    ],
    "orderHold": [
      2,
      1,
      2,
      0,
      3,
      3,
      1,
      3,
      0,
      1,
      3,
      0,
      3,
      0,
      2,
      0,
      3,
      2,
      3,
      3,
      2,
      1,
      2,
      4,
      4,
      0,
      3,
      0,
      2,
      3,
      0,
      2,
      2,
      3,
      2,
      2,
      3,
      0,
      1,
      3,
      0,
      1,
      3,
      4,
      3,
      4,
      0,
      2,
      1,
      1,
      0,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      2,
      1,
      0,
      0,
      0,
      2,
      1,
      2,
      1,
      0,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      4,
      3,
      3,
      4,
      2,
      3,
      4,
      2,
      2,
      1,
      4,
      2,
      0,
      2,
      4,
      3,
      1,
      3,
      4,
      1,
      1,
      2,
      3,
      2,
      4,
      2,
      3,
      0,
      0,
      3,
      1,
      1,
      1,
      4,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      4,
      1,
      2,
      2,
      2,
      4,
      3,
      0,
      4
    ],
    "issueDelay": [
      2,
      1,
      4,
      0,
      1,
      4,
      3,
      0,
      0,
      0,
      0,
      3,
      2,
      4,
      2,
      3,
      0,
      2,
      0,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      0,
      4,
      2,
      4,
      3,
      4,
      1,
      2,
      3,
      2,
      0,
      3,
      0,
      2,
      2,
      1,
      3,
      1,
      4,
      1,
      0,
      3,
      4,
      0,
      3,
      3
    ],
    "issueQuantity": [
      3,
      0,
      1,
      0,
      3,
      2,
      0,
      1,
      2,
      2,
      2,
      3,
      1,
      2,
      0,
      2,
      2,
      1,
      0,
      3,
      2,
      2,
      0,
      2,
      2,
      1,
      0,
      0,
      2,
      2,
      1,
      2,
      4,
      2,
      3,
      4,
      3,
      0,
      0,
      1,
      4,
      2,
      0,
      1,
      4,
      0,
      3,
      3,
      2,
      4,
      1,
      2
    ]
  },
  {
    "id": "VNDR-0000154",
    "status": "active",
    "name": "Medique Innovations",
    "tag": "Medical Supplies, IT Services",
    "contactName": "Chris Richard",
    "industryRating": 2.4,
    "procedures": "[PR-066,PR-061,PR-068,PR-064,PR-070,PR-039,PR-054,PR-061,PR-074,PR-074]",
    "contracts": "[CNTRCT-0000029,CNTRCT-0000044,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000047,ORDR-1-0000043,ORDR-2-0000002,ORDR-3-0000060,ORDR-4-0000003,ORDR-5-0000013,ORDR-6-0000005,ORDR-7-0000053,ORDR-8-0000064,ORDR-9-0000037,ORDR-10-0000058,ORDR-11-0000010,ORDR-12-0000037,ORDR-13-0000012,ORDR-14-0000058,ORDR-15-0000039,ORDR-16-0000015,ORDR-17-0000023,ORDR-18-0000074]",
    "spendWeekly": [
      3065,
      3023,
      2906,
      2960,
      2965,
      2905,
      3096,
      3098,
      3100,
      3099,
      3077,
      2988,
      2947,
      2979,
      2937,
      2910,
      3053,
      3099,
      3045,
      3098,
      2903,
      3097,
      3063,
      3026,
      3095,
      3041,
      3079,
      2974,
      3011,
      3097,
      2903,
      3035,
      3061,
      2978,
      2992,
      2929,
      3095,
      3013,
      2906,
      3075,
      3067,
      2975,
      3065,
      2994,
      3009,
      2901,
      3099,
      2900,
      2901,
      2968,
      2905,
      2949
    ],
    "orderRejected": [
      2949,
      19,
      9,
      1,
      3,
      19,
      10,
      15,
      19,
      18,
      2,
      4,
      1,
      3,
      7,
      17,
      19,
      18,
      4,
      0,
      2,
      14,
      18,
      4,
      6,
      3,
      0,
      0,
      4,
      19,
      19,
      5,
      13,
      19,
      19,
      8,
      0,
      16,
      19,
      19,
      19,
      19,
      15,
      19,
      2,
      18,
      0,
      7,
      2,
      0,
      0,
      9,
      17
    ],
    "orderAccepted": [
      9,
      8,
      11,
      3,
      8,
      11,
      11,
      10,
      0,
      6,
      0,
      8,
      4,
      11,
      9,
      7,
      11,
      5,
      2,
      11,
      11,
      11,
      3,
      4,
      11,
      7,
      0,
      0,
      0,
      2,
      11,
      5,
      0,
      0,
      11,
      10,
      2,
      8,
      11,
      9,
      11,
      4,
      11,
      0,
      0,
      6,
      0,
      11,
      3,
      10,
      2,
      0
    ],
    "orderHold": [
      1,
      0,
      0,
      3,
      0,
      2,
      4,
      2,
      4,
      2,
      1,
      0,
      1,
      4,
      0,
      2,
      3,
      0,
      4,
      3,
      3,
      1,
      4,
      4,
      0,
      1,
      3,
      4,
      4,
      0,
      0,
      3,
      4,
      3,
      0,
      4,
      4,
      4,
      4,
      4,
      4,
      0,
      3,
      4,
      2,
      3,
      0,
      4,
      1,
      1,
      0,
      2
    ],
    "orderBackordered": [
      2,
      0,
      2,
      1,
      1,
      2,
      0,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      0,
      1,
      2,
      4,
      2,
      0,
      0,
      2,
      4,
      3,
      2,
      4,
      3,
      4,
      0,
      4,
      1,
      3,
      0,
      3,
      2,
      4,
      3,
      3,
      0,
      4,
      0,
      2,
      3,
      0,
      2,
      4,
      4,
      0,
      2,
      0,
      0,
      0,
      2,
      3,
      0,
      0,
      2,
      3,
      0,
      0,
      1,
      4,
      3,
      4,
      3,
      4
    ],
    "issueDelay": [
      2,
      4,
      4,
      1,
      4,
      2,
      4,
      4,
      0,
      4,
      0,
      0,
      4,
      2,
      0,
      0,
      4,
      0,
      4,
      1,
      2,
      0,
      2,
      2,
      3,
      1,
      2,
      1,
      2,
      1,
      3,
      0,
      4,
      1,
      4,
      4,
      2,
      4,
      0,
      0,
      0,
      2,
      2,
      4,
      4,
      2,
      1,
      0,
      0,
      1,
      1,
      3
    ],
    "issueQuantity": [
      4,
      4,
      1,
      1,
      2,
      4,
      1,
      0,
      3,
      0,
      4,
      1,
      1,
      0,
      4,
      1,
      3,
      4,
      4,
      4,
      0,
      4,
      2,
      4,
      0,
      0,
      1,
      2,
      4,
      0,
      1,
      4,
      1,
      0,
      2,
      2,
      0,
      3,
      0,
      0,
      4,
      1,
      4,
      2,
      3,
      1,
      4,
      4,
      3,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000155",
    "status": "active",
    "name": "HealthCraft Logistics",
    "tag": "Surgical Instruments, Pharmaceuticals",
    "contactName": "Julie Sanders",
    "industryRating": 4.1,
    "procedures": "[PR-064,PR-018,PR-061,PR-035,PR-061,PR-051,PR-059,PR-051,PR-061,PR-061]",
    "contracts": "[CNTRCT-0000033,CNTRCT-0000048,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000026,ORDR-1-0000037,ORDR-2-0000001,ORDR-3-0000006,ORDR-4-0000032,ORDR-5-0000039,ORDR-6-0000032,ORDR-7-0000031,ORDR-8-0000018,ORDR-9-0000053,ORDR-10-0000066,ORDR-11-0000038,ORDR-12-0000058,ORDR-13-0000026,ORDR-14-0000020,ORDR-15-0000079,ORDR-16-0000028,ORDR-17-0000031,ORDR-18-0000063]",
    "spendWeekly": [
      3009,
      3012,
      2959,
      3036,
      2912,
      3009,
      3013,
      2965,
      3052,
      3003,
      2902,
      2958,
      3036,
      3049,
      3012,
      2901,
      3069,
      3075,
      2900,
      3051,
      2903,
      3082,
      3086,
      2931,
      2994,
      2966,
      3049,
      3096,
      2937,
      2914,
      3076,
      2990,
      3033,
      2956,
      3004,
      2965,
      2947,
      3009,
      2906,
      3082,
      3016,
      2963,
      3088,
      3091,
      3040,
      2901,
      2934,
      3034,
      3031,
      2905,
      2982,
      3097
    ],
    "orderRejected": [
      3097,
      10,
      3,
      0,
      7,
      19,
      9,
      5,
      17,
      12,
      5,
      17,
      7,
      7,
      3,
      5,
      14,
      4,
      16,
      19,
      6,
      19,
      3,
      16,
      12,
      19,
      0,
      10,
      14,
      7,
      5,
      16,
      15,
      0,
      11,
      3,
      6,
      14,
      11,
      19,
      19,
      7,
      10,
      4,
      8,
      0,
      4,
      4,
      0,
      19,
      0,
      8,
      19
    ],
    "orderAccepted": [
      10,
      4,
      5,
      11,
      0,
      4,
      11,
      2,
      0,
      6,
      10,
      1,
      7,
      8,
      9,
      9,
      8,
      11,
      0,
      4,
      3,
      1,
      10,
      3,
      9,
      1,
      0,
      3,
      7,
      10,
      11,
      7,
      7,
      2,
      9,
      3,
      9,
      6,
      7,
      11,
      4,
      11,
      5,
      6,
      0,
      6,
      6,
      11,
      1,
      10,
      0,
      0
    ],
    "orderHold": [
      2,
      3,
      4,
      2,
      4,
      0,
      2,
      3,
      0,
      2,
      4,
      0,
      0,
      0,
      0,
      4,
      3,
      2,
      4,
      1,
      3,
      4,
      4,
      4,
      2,
      2,
      4,
      2,
      1,
      0,
      0,
      4,
      4,
      3,
      2,
      2,
      2,
      1,
      4,
      1,
      0,
      2,
      3,
      3,
      2,
      4,
      1,
      0,
      4,
      1,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      0,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      2,
      0,
      2,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      1,
      2,
      3,
      4,
      0,
      4,
      0,
      2,
      4,
      3,
      0,
      0,
      4,
      2,
      0,
      2,
      1,
      3,
      0,
      0,
      0,
      0,
      3,
      3,
      0,
      1,
      2,
      1,
      2,
      0,
      0,
      0,
      1,
      3,
      4,
      0,
      1,
      1,
      0,
      3,
      1,
      0,
      2,
      1,
      2,
      0,
      2,
      1,
      3,
      1,
      0,
      4
    ],
    "issueDelay": [
      3,
      0,
      3,
      0,
      2,
      4,
      3,
      0,
      3,
      1,
      2,
      0,
      3,
      3,
      3,
      3,
      3,
      2,
      4,
      1,
      3,
      1,
      4,
      3,
      0,
      3,
      1,
      2,
      2,
      4,
      4,
      2,
      3,
      4,
      0,
      1,
      0,
      4,
      0,
      0,
      1,
      2,
      3,
      2,
      3,
      3,
      1,
      3,
      4,
      1,
      2,
      3
    ],
    "issueQuantity": [
      1,
      1,
      1,
      0,
      3,
      3,
      0,
      1,
      3,
      3,
      3,
      2,
      1,
      2,
      0,
      4,
      2,
      1,
      0,
      1,
      3,
      1,
      1,
      2,
      0,
      1,
      2,
      0,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      0,
      4,
      0,
      1,
      4,
      2,
      1,
      0,
      2,
      4,
      2,
      4,
      3,
      4,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000156",
    "status": "inactive",
    "name": "BioHealth Systems",
    "tag": "Laboratory Supplies, Surgical Instruments",
    "contactName": "Jennifer Harris",
    "industryRating": 3.6,
    "procedures": "[PR-052,PR-018,PR-064,PR-048,PR-047,PR-054,PR-069,PR-066,PR-037,PR-059]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000058,CNTRCT-0000020]",
    "orders": "[ORDR-0-0000042,ORDR-1-0000064,ORDR-2-0000005,ORDR-3-0000031,ORDR-4-0000005,ORDR-5-0000006,ORDR-6-0000019,ORDR-7-0000059,ORDR-8-0000025,ORDR-9-0000048,ORDR-10-0000030,ORDR-11-0000013,ORDR-12-0000014,ORDR-13-0000058,ORDR-14-0000033,ORDR-15-0000044,ORDR-16-0000072,ORDR-17-0000034,ORDR-18-0000027]",
    "spendWeekly": [
      3002,
      3063,
      3033,
      3039,
      3025,
      2905,
      3031,
      2921,
      2902,
      2926,
      2984,
      2950,
      2935,
      3052,
      2960,
      2948,
      3008,
      2958,
      3045,
      3009,
      3055,
      2949,
      2948,
      2935,
      3095,
      3096,
      2982,
      2907,
      3066,
      2982,
      3015,
      3062,
      2998,
      2922,
      3094,
      2944,
      3012,
      3065,
      2995,
      2935,
      2902,
      3037,
      3092,
      3014,
      2926,
      2901,
      2948,
      2932,
      3006,
      2948,
      2981,
      2923
    ],
    "orderRejected": [
      2923,
      12,
      1,
      14,
      1,
      15,
      7,
      9,
      0,
      19,
      19,
      9,
      19,
      7,
      13,
      19,
      19,
      0,
      12,
      13,
      4,
      19,
      15,
      12,
      16,
      8,
      12,
      0,
      14,
      18,
      4,
      13,
      13,
      3,
      15,
      16,
      10,
      19,
      3,
      5,
      0,
      15,
      18,
      14,
      11,
      3,
      7,
      8,
      18,
      0,
      12,
      7,
      19
    ],
    "orderAccepted": [
      6,
      6,
      0,
      5,
      3,
      11,
      1,
      1,
      0,
      5,
      7,
      2,
      0,
      10,
      11,
      10,
      11,
      0,
      8,
      9,
      7,
      5,
      6,
      9,
      2,
      3,
      2,
      6,
      6,
      4,
      11,
      11,
      0,
      5,
      5,
      11,
      0,
      5,
      9,
      3,
      4,
      8,
      10,
      2,
      0,
      11,
      5,
      3,
      0,
      9,
      1,
      3
    ],
    "orderHold": [
      2,
      2,
      2,
      3,
      3,
      1,
      3,
      2,
      4,
      2,
      2,
      1,
      0,
      4,
      0,
      1,
      1,
      0,
      3,
      1,
      4,
      1,
      3,
      4,
      0,
      3,
      2,
      4,
      2,
      0,
      0,
      4,
      1,
      1,
      1,
      1,
      1,
      3,
      4,
      0,
      1,
      3,
      4,
      2,
      4,
      4,
      0,
      4,
      2,
      1,
      4,
      1
    ],
    "orderBackordered": [
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      2,
      1,
      2,
      1,
      0,
      2,
      2,
      0,
      0,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      0,
      4,
      0,
      0,
      0,
      3,
      2,
      2,
      1,
      0,
      4,
      1,
      4,
      2,
      3,
      4,
      0,
      2,
      0,
      1,
      1,
      2,
      4,
      0,
      4,
      4,
      4,
      0,
      2,
      0,
      2,
      2,
      3,
      1,
      0,
      3,
      0,
      0,
      3,
      4,
      2,
      4,
      2,
      3,
      1,
      4,
      4,
      4
    ],
    "issueDelay": [
      0,
      3,
      4,
      0,
      3,
      2,
      4,
      2,
      2,
      1,
      3,
      1,
      1,
      0,
      2,
      2,
      4,
      0,
      0,
      3,
      1,
      1,
      3,
      4,
      1,
      1,
      4,
      2,
      1,
      3,
      1,
      4,
      0,
      2,
      3,
      1,
      2,
      1,
      1,
      1,
      0,
      4,
      2,
      4,
      3,
      1,
      2,
      0,
      0,
      4,
      1,
      0
    ],
    "issueQuantity": [
      2,
      3,
      4,
      0,
      0,
      2,
      1,
      0,
      0,
      3,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      4,
      4,
      1,
      2,
      4,
      1,
      2,
      1,
      2,
      3,
      4,
      2,
      2,
      4,
      4,
      0,
      0,
      3,
      4,
      0,
      2,
      4,
      3,
      2,
      3,
      4,
      4,
      3,
      1,
      2,
      3,
      1,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000157",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Nutrition, Imaging, Laboratory Supplies",
    "contactName": "Kristy Boyer",
    "industryRating": 2.1,
    "procedures": "[PR-044,PR-050,PR-057,PR-056,PR-009,PR-070,PR-055,PR-066,PR-038,PR-067]",
    "contracts": "[CNTRCT-0000022,CNTRCT-0000048,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000019,ORDR-2-0000052,ORDR-3-0000062,ORDR-4-0000001,ORDR-5-0000010,ORDR-6-0000045,ORDR-7-0000010,ORDR-8-0000044,ORDR-9-0000046,ORDR-10-0000031,ORDR-11-0000041,ORDR-12-0000037,ORDR-13-0000024,ORDR-14-0000057,ORDR-15-0000024,ORDR-16-0000030,ORDR-17-0000049,ORDR-18-0000077]",
    "spendWeekly": [
      3003,
      3062,
      2942,
      2987,
      3058,
      3031,
      2940,
      3006,
      2995,
      2920,
      3011,
      3095,
      2922,
      3094,
      3017,
      2901,
      3034,
      3065,
      3072,
      3059,
      3047,
      3027,
      2900,
      3069,
      3044,
      3036,
      2942,
      2902,
      2901,
      2947,
      2937,
      3026,
      2934,
      3014,
      3095,
      2959,
      2967,
      3097,
      3037,
      3036,
      2901,
      2932,
      3097,
      2905,
      2986,
      2996,
      3057,
      3030,
      3014,
      3015,
      3023,
      3049
    ],
    "orderRejected": [
      3049,
      7,
      19,
      0,
      9,
      8,
      18,
      13,
      0,
      19,
      17,
      11,
      13,
      13,
      11,
      11,
      15,
      14,
      10,
      6,
      6,
      8,
      10,
      11,
      3,
      4,
      9,
      17,
      14,
      19,
      6,
      19,
      19,
      8,
      15,
      3,
      6,
      0,
      17,
      9,
      1,
      16,
      0,
      0,
      6,
      10,
      5,
      8,
      7,
      11,
      4,
      5,
      19
    ],
    "orderAccepted": [
      0,
      7,
      7,
      2,
      0,
      9,
      9,
      11,
      7,
      0,
      0,
      3,
      6,
      10,
      7,
      2,
      1,
      10,
      6,
      4,
      11,
      8,
      3,
      4,
      0,
      6,
      0,
      7,
      4,
      11,
      11,
      11,
      1,
      4,
      0,
      11,
      4,
      10,
      7,
      5,
      0,
      4,
      10,
      7,
      8,
      11,
      3,
      3,
      0,
      9,
      2,
      9
    ],
    "orderHold": [
      1,
      1,
      3,
      1,
      0,
      2,
      3,
      2,
      0,
      0,
      4,
      2,
      0,
      0,
      3,
      1,
      2,
      0,
      4,
      2,
      3,
      4,
      4,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      0,
      1,
      1,
      3,
      4,
      2,
      4,
      2,
      4,
      4,
      1,
      0,
      3,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      3,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      0,
      2,
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      2,
      2,
      2,
      2,
      0,
      0,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      3,
      0,
      0,
      4,
      2,
      1,
      2,
      4,
      2,
      2,
      2,
      4,
      4,
      1,
      2,
      1,
      4,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      1,
      4,
      4,
      2,
      1,
      4,
      2,
      2,
      2,
      0,
      1,
      4,
      2,
      0,
      4,
      4,
      2,
      4,
      2,
      2,
      3,
      1,
      3,
      3,
      3
    ],
    "issueDelay": [
      4,
      2,
      1,
      4,
      4,
      3,
      4,
      1,
      0,
      0,
      0,
      1,
      2,
      2,
      4,
      3,
      4,
      4,
      2,
      2,
      1,
      1,
      1,
      3,
      0,
      3,
      2,
      0,
      3,
      4,
      0,
      1,
      0,
      3,
      2,
      2,
      2,
      4,
      1,
      1,
      1,
      2,
      3,
      2,
      3,
      3,
      4,
      0,
      4,
      1,
      0,
      0
    ],
    "issueQuantity": [
      4,
      1,
      1,
      0,
      3,
      3,
      1,
      2,
      1,
      4,
      1,
      1,
      2,
      0,
      2,
      1,
      4,
      1,
      4,
      4,
      0,
      1,
      3,
      3,
      0,
      2,
      0,
      3,
      4,
      3,
      1,
      0,
      4,
      0,
      3,
      2,
      4,
      1,
      0,
      4,
      4,
      1,
      4,
      0,
      0,
      3,
      2,
      2,
      1,
      3,
      2,
      3
    ]
  },
  {
    "id": "VNDR-0000158",
    "status": "active",
    "name": "PharmaSphere Equipment",
    "tag": "Facilities Management, Imaging",
    "contactName": "Christopher Gonzalez",
    "industryRating": 4.7,
    "procedures": "[PR-047,PR-031,PR-049,PR-055,PR-046,PR-049,PR-049,PR-041,PR-036,PR-056]",
    "contracts": "[CNTRCT-0000030,CNTRCT-0000057,CNTRCT-0000025]",
    "orders": "[ORDR-0-0000003,ORDR-1-0000057,ORDR-2-0000014,ORDR-3-0000061,ORDR-4-0000065,ORDR-5-0000038,ORDR-6-0000005,ORDR-7-0000023,ORDR-8-0000007,ORDR-9-0000019,ORDR-10-0000031,ORDR-11-0000017,ORDR-12-0000068,ORDR-13-0000049,ORDR-14-0000076,ORDR-15-0000037,ORDR-16-0000073,ORDR-17-0000037,ORDR-18-0000050]",
    "spendWeekly": [
      2967,
      3001,
      2906,
      2902,
      2977,
      3030,
      3091,
      3098,
      2972,
      2922,
      2999,
      3095,
      3017,
      2971,
      3091,
      3018,
      3021,
      2900,
      2935,
      3007,
      3065,
      2903,
      2906,
      2989,
      3053,
      3096,
      3089,
      2977,
      3026,
      2922,
      2964,
      2971,
      2995,
      3026,
      2913,
      3020,
      2995,
      2979,
      2906,
      3025,
      3058,
      3023,
      3075,
      3029,
      2951,
      2946,
      3099,
      2900,
      2934,
      3008,
      3044,
      3097
    ],
    "orderRejected": [
      3097,
      15,
      18,
      10,
      11,
      19,
      0,
      19,
      10,
      0,
      0,
      0,
      13,
      7,
      15,
      14,
      7,
      17,
      0,
      4,
      6,
      14,
      5,
      8,
      19,
      4,
      14,
      15,
      2,
      17,
      12,
      19,
      19,
      10,
      9,
      18,
      11,
      3,
      17,
      5,
      7,
      12,
      7,
      1,
      0,
      2,
      7,
      5,
      6,
      18,
      14,
      19,
      13
    ],
    "orderAccepted": [
      11,
      2,
      2,
      9,
      0,
      8,
      6,
      0,
      11,
      8,
      11,
      1,
      11,
      1,
      7,
      7,
      2,
      11,
      0,
      8,
      5,
      11,
      0,
      4,
      0,
      4,
      0,
      2,
      8,
      4,
      9,
      6,
      0,
      0,
      5,
      11,
      0,
      2,
      10,
      7,
      0,
      8,
      5,
      11,
      8,
      10,
      0,
      11,
      0,
      3,
      7,
      8
    ],
    "orderHold": [
      2,
      2,
      1,
      4,
      4,
      0,
      2,
      2,
      2,
      4,
      1,
      1,
      1,
      1,
      3,
      4,
      2,
      3,
      3,
      3,
      1,
      3,
      1,
      4,
      0,
      2,
      4,
      0,
      3,
      1,
      0,
      3,
      0,
      4,
      0,
      4,
      4,
      1,
      2,
      1,
      3,
      2,
      4,
      2,
      2,
      4,
      0,
      3,
      0,
      0,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      0,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      2,
      4,
      3,
      1,
      0,
      0,
      0,
      4,
      2,
      0,
      1,
      0,
      3,
      0,
      0,
      1,
      2,
      4,
      1,
      0,
      4,
      1,
      0,
      3,
      2,
      2,
      2,
      1,
      4,
      0,
      2,
      2,
      3,
      4,
      3,
      1,
      3,
      0,
      0,
      2,
      4,
      3,
      4,
      0,
      2,
      0,
      4,
      0,
      0,
      1,
      2,
      4
    ],
    "issueDelay": [
      0,
      4,
      2,
      4,
      3,
      4,
      0,
      0,
      1,
      4,
      2,
      0,
      3,
      4,
      0,
      2,
      0,
      1,
      4,
      4,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      3,
      4,
      2,
      0,
      2,
      2,
      0,
      4,
      3,
      2,
      4,
      3,
      0,
      0,
      4,
      4,
      3,
      0,
      2,
      1,
      4,
      2,
      2,
      4,
      4
    ],
    "issueQuantity": [
      2,
      0,
      1,
      2,
      4,
      4,
      2,
      0,
      3,
      2,
      4,
      2,
      2,
      1,
      4,
      4,
      4,
      1,
      3,
      4,
      0,
      1,
      0,
      4,
      2,
      2,
      1,
      4,
      2,
      1,
      2,
      0,
      0,
      3,
      4,
      3,
      2,
      0,
      0,
      4,
      0,
      3,
      2,
      2,
      0,
      3,
      2,
      3,
      0,
      4,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000159",
    "status": "inactive",
    "name": "MediFlow Systems",
    "tag": "PPE, Imaging",
    "contactName": "Kristi Reyes",
    "industryRating": 4.4,
    "procedures": "[PR-049,PR-047,PR-040,PR-061,PR-064,PR-036,PR-021,PR-062,PR-070,PR-062]",
    "contracts": "[CNTRCT-0000032,CNTRCT-0000049,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000024,ORDR-1-0000061,ORDR-2-0000003,ORDR-3-0000016,ORDR-4-0000018,ORDR-5-0000048,ORDR-6-0000063,ORDR-7-0000062,ORDR-8-0000033,ORDR-9-0000073,ORDR-10-0000055,ORDR-11-0000052,ORDR-12-0000046,ORDR-13-0000047,ORDR-14-0000015,ORDR-15-0000049,ORDR-16-0000071,ORDR-17-0000080,ORDR-18-0000034]",
    "spendWeekly": [
      3038,
      3004,
      2906,
      2967,
      2903,
      2953,
      2958,
      2968,
      3029,
      3026,
      3062,
      3075,
      2909,
      3027,
      3090,
      3059,
      2982,
      2994,
      2934,
      3075,
      3100,
      3005,
      3052,
      3041,
      3071,
      2995,
      3031,
      2901,
      2901,
      3009,
      2944,
      3026,
      3007,
      2972,
      2982,
      2999,
      2961,
      3062,
      2930,
      3099,
      3018,
      3027,
      3097,
      3084,
      3010,
      2904,
      2911,
      2910,
      3030,
      2981,
      3028,
      3019
    ],
    "orderRejected": [
      3019,
      19,
      8,
      7,
      15,
      19,
      19,
      8,
      13,
      17,
      16,
      0,
      17,
      14,
      13,
      3,
      19,
      0,
      0,
      13,
      16,
      0,
      9,
      13,
      11,
      19,
      13,
      10,
      19,
      9,
      4,
      16,
      16,
      17,
      12,
      10,
      11,
      8,
      12,
      11,
      16,
      9,
      8,
      15,
      0,
      5,
      0,
      2,
      19,
      18,
      0,
      9,
      19
    ],
    "orderAccepted": [
      2,
      5,
      6,
      3,
      6,
      10,
      11,
      0,
      1,
      0,
      9,
      6,
      9,
      0,
      10,
      9,
      0,
      8,
      3,
      3,
      1,
      6,
      11,
      3,
      11,
      4,
      0,
      6,
      4,
      8,
      9,
      9,
      1,
      1,
      0,
      3,
      7,
      1,
      6,
      0,
      3,
      11,
      11,
      5,
      1,
      0,
      2,
      2,
      7,
      8,
      5,
      7
    ],
    "orderHold": [
      2,
      0,
      2,
      3,
      1,
      4,
      4,
      4,
      0,
      0,
      2,
      1,
      1,
      0,
      0,
      3,
      2,
      0,
      4,
      1,
      1,
      4,
      1,
      4,
      1,
      3,
      2,
      0,
      2,
      4,
      1,
      4,
      0,
      3,
      1,
      4,
      2,
      0,
      4,
      2,
      0,
      0,
      3,
      0,
      2,
      3,
      2,
      2,
      0,
      2,
      2,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      2,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      4,
      4,
      3,
      0,
      3,
      3,
      2,
      4,
      4,
      2,
      4,
      1,
      1,
      0,
      1,
      4,
      0,
      3,
      1,
      2,
      2,
      4,
      3,
      3,
      0,
      4,
      4,
      3,
      2,
      2,
      1,
      2,
      1,
      1,
      3,
      2,
      1,
      4,
      2,
      3,
      4,
      4,
      4,
      4,
      2,
      2,
      3,
      0,
      0,
      1
    ],
    "issueDelay": [
      1,
      2,
      2,
      3,
      1,
      4,
      4,
      1,
      0,
      3,
      0,
      2,
      0,
      4,
      0,
      0,
      2,
      3,
      0,
      2,
      2,
      0,
      0,
      0,
      0,
      2,
      2,
      1,
      2,
      2,
      2,
      2,
      4,
      2,
      2,
      4,
      0,
      4,
      2,
      2,
      3,
      0,
      0,
      0,
      1,
      0,
      2,
      0,
      2,
      0,
      1,
      3
    ],
    "issueQuantity": [
      2,
      0,
      0,
      1,
      4,
      3,
      4,
      0,
      3,
      1,
      0,
      2,
      0,
      0,
      1,
      4,
      4,
      1,
      3,
      1,
      0,
      4,
      4,
      4,
      2,
      3,
      1,
      0,
      3,
      3,
      0,
      1,
      2,
      4,
      4,
      3,
      2,
      2,
      3,
      4,
      4,
      3,
      3,
      2,
      4,
      0,
      2,
      1,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000160",
    "status": "active",
    "name": "MediFlow Systems",
    "tag": "Equipment, PPE, Nutrition",
    "contactName": "Gary Wilson",
    "industryRating": 2.9,
    "procedures": "[PR-052,PR-067,PR-048,PR-044,PR-057,PR-058,PR-063,PR-061,PR-071,PR-025]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000048,CNTRCT-0000058]",
    "orders": "[ORDR-0-0000060,ORDR-1-0000052,ORDR-2-0000062,ORDR-3-0000016,ORDR-4-0000033,ORDR-5-0000046,ORDR-6-0000025,ORDR-7-0000022,ORDR-8-0000031,ORDR-9-0000046,ORDR-10-0000057,ORDR-11-0000012,ORDR-12-0000035,ORDR-13-0000032,ORDR-14-0000041,ORDR-15-0000078,ORDR-16-0000015,ORDR-17-0000051,ORDR-18-0000020]",
    "spendWeekly": [
      3004,
      3022,
      2906,
      2902,
      2967,
      3011,
      3082,
      2917,
      2964,
      3031,
      2962,
      2921,
      2994,
      3094,
      2995,
      2901,
      2974,
      3099,
      2933,
      3019,
      2986,
      3097,
      3063,
      3019,
      3095,
      3072,
      3095,
      3063,
      2987,
      3048,
      3054,
      3071,
      3076,
      2965,
      3026,
      2904,
      2929,
      3052,
      3006,
      3078,
      3011,
      3049,
      3028,
      3081,
      2963,
      2901,
      3019,
      2900,
      3095,
      2905,
      2927,
      3097
    ],
    "orderRejected": [
      3097,
      6,
      8,
      0,
      5,
      19,
      0,
      0,
      13,
      7,
      0,
      3,
      10,
      18,
      1,
      1,
      2,
      0,
      0,
      12,
      19,
      11,
      13,
      9,
      5,
      19,
      0,
      17,
      19,
      19,
      16,
      19,
      19,
      14,
      19,
      19,
      0,
      18,
      19,
      19,
      16,
      12,
      3,
      15,
      11,
      6,
      7,
      10,
      0,
      7,
      3,
      14,
      15
    ],
    "orderAccepted": [
      11,
      11,
      5,
      5,
      3,
      6,
      7,
      11,
      0,
      6,
      5,
      6,
      6,
      11,
      10,
      6,
      8,
      11,
      0,
      7,
      11,
      8,
      11,
      6,
      7,
      5,
      0,
      3,
      3,
      2,
      9,
      0,
      0,
      0,
      8,
      6,
      6,
      1,
      8,
      5,
      9,
      8,
      5,
      7,
      5,
      8,
      3,
      0,
      0,
      3,
      5,
      0
    ],
    "orderHold": [
      1,
      0,
      4,
      0,
      1,
      2,
      3,
      1,
      0,
      2,
      4,
      0,
      4,
      2,
      2,
      4,
      3,
      3,
      2,
      2,
      1,
      4,
      1,
      2,
      4,
      3,
      3,
      4,
      1,
      2,
      0,
      4,
      4,
      4,
      1,
      2,
      3,
      3,
      4,
      2,
      0,
      0,
      4,
      4,
      2,
      4,
      3,
      0,
      1,
      2,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      0,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      0,
      2,
      4,
      2,
      0,
      3,
      4,
      3,
      4,
      2,
      3,
      2,
      2,
      0,
      3,
      0,
      2,
      2,
      2,
      1,
      1,
      2,
      3,
      2,
      2,
      0,
      1,
      4,
      2,
      0,
      4,
      1,
      3,
      0,
      3,
      0,
      1,
      4,
      4,
      0,
      0,
      2,
      2,
      3,
      4,
      0,
      4,
      3,
      4,
      4,
      4
    ],
    "issueDelay": [
      0,
      0,
      4,
      3,
      2,
      4,
      2,
      0,
      0,
      0,
      0,
      2,
      1,
      2,
      0,
      1,
      2,
      0,
      0,
      4,
      1,
      0,
      0,
      2,
      1,
      2,
      2,
      2,
      2,
      0,
      2,
      2,
      4,
      3,
      3,
      3,
      0,
      4,
      0,
      1,
      2,
      0,
      3,
      3,
      1,
      0,
      0,
      3,
      3,
      0,
      3,
      3
    ],
    "issueQuantity": [
      3,
      0,
      2,
      0,
      4,
      4,
      1,
      0,
      0,
      0,
      4,
      1,
      0,
      1,
      0,
      1,
      0,
      3,
      0,
      4,
      1,
      0,
      3,
      2,
      2,
      4,
      0,
      1,
      1,
      4,
      2,
      0,
      3,
      1,
      4,
      0,
      4,
      0,
      0,
      4,
      2,
      0,
      2,
      0,
      0,
      0,
      4,
      3,
      1,
      1,
      2,
      2
    ]
  },
  {
    "id": "VNDR-0000161",
    "status": "inactive",
    "name": "PharmaSphere Equipment",
    "tag": "Facilities Management, Equipment",
    "contactName": "Kevin Carroll",
    "industryRating": 2.1,
    "procedures": "[PR-053,PR-065,PR-060,PR-064,PR-056,PR-053,PR-063,PR-057,PR-065,PR-061]",
    "contracts": "[CNTRCT-0000054,CNTRCT-0000046,CNTRCT-0000009]",
    "orders": "[ORDR-0-0000037,ORDR-1-0000017,ORDR-2-0000015,ORDR-3-0000053,ORDR-4-0000005,ORDR-5-0000010,ORDR-6-0000038,ORDR-7-0000057,ORDR-8-0000061,ORDR-9-0000020,ORDR-10-0000046,ORDR-11-0000016,ORDR-12-0000044,ORDR-13-0000049,ORDR-14-0000047,ORDR-15-0000039,ORDR-16-0000050,ORDR-17-0000051,ORDR-18-0000058]",
    "spendWeekly": [
      2910,
      3001,
      2965,
      2978,
      2903,
      2907,
      3100,
      2989,
      3065,
      3011,
      2974,
      3004,
      2990,
      3094,
      2996,
      2906,
      3098,
      3099,
      3011,
      3010,
      3100,
      2951,
      2918,
      2962,
      3095,
      3048,
      3060,
      2970,
      2901,
      3097,
      3014,
      3097,
      3039,
      2991,
      3095,
      2965,
      2971,
      2949,
      2962,
      3057,
      3012,
      2900,
      3097,
      3031,
      2912,
      2901,
      3099,
      2932,
      3023,
      2934,
      2938,
      2910
    ],
    "orderRejected": [
      2910,
      19,
      6,
      7,
      8,
      15,
      11,
      14,
      8,
      0,
      16,
      7,
      19,
      18,
      16,
      19,
      19,
      13,
      5,
      5,
      19,
      19,
      6,
      5,
      10,
      2,
      4,
      15,
      8,
      17,
      19,
      19,
      17,
      16,
      19,
      11,
      19,
      18,
      19,
      1,
      15,
      12,
      6,
      18,
      2,
      6,
      8,
      10,
      4,
      19,
      7,
      12,
      14
    ],
    "orderAccepted": [
      0,
      8,
      10,
      6,
      4,
      9,
      8,
      2,
      4,
      1,
      8,
      6,
      2,
      11,
      1,
      8,
      7,
      8,
      9,
      7,
      5,
      3,
      7,
      1,
      4,
      6,
      0,
      11,
      2,
      0,
      0,
      6,
      6,
      6,
      5,
      10,
      9,
      11,
      11,
      0,
      0,
      6,
      3,
      2,
      0,
      8,
      7,
      6,
      3,
      5,
      0,
      4
    ],
    "orderHold": [
      2,
      2,
      2,
      2,
      4,
      3,
      1,
      3,
      0,
      4,
      2,
      0,
      3,
      4,
      3,
      1,
      0,
      3,
      4,
      3,
      4,
      4,
      2,
      3,
      0,
      4,
      1,
      1,
      4,
      0,
      2,
      4,
      1,
      1,
      1,
      2,
      1,
      4,
      4,
      4,
      0,
      4,
      4,
      4,
      4,
      0,
      3,
      4,
      1,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      0,
      0,
      1,
      2,
      0,
      0,
      0,
      0,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      2,
      2,
      2,
      2,
      0,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      0,
      0,
      4,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      4,
      0,
      2,
      4,
      0,
      1,
      3,
      2,
      1,
      2,
      2,
      2,
      3,
      3,
      0,
      4,
      3,
      1,
      3,
      3,
      4,
      4,
      2,
      2,
      0,
      4,
      1,
      3,
      3,
      4,
      0,
      2,
      3,
      3,
      1,
      2,
      4,
      1,
      1,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      0,
      1,
      4,
      1,
      3,
      1,
      2,
      0,
      0,
      2,
      3,
      3,
      4,
      4,
      3,
      4,
      0,
      2,
      1,
      1,
      0,
      1,
      4,
      0,
      4,
      4,
      0,
      3,
      0,
      2,
      4,
      2,
      3,
      3,
      2,
      0,
      0,
      4,
      0,
      3,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      4,
      2,
      3,
      3
    ],
    "issueQuantity": [
      1,
      1,
      3,
      1,
      3,
      3,
      1,
      4,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      0,
      3,
      1,
      4,
      3,
      1,
      2,
      3,
      0,
      4,
      2,
      2,
      3,
      3,
      4,
      3,
      2,
      2,
      3,
      4,
      4,
      3,
      1,
      3,
      2,
      0,
      3,
      2,
      3,
      2,
      2,
      3,
      2,
      3
    ]
  },
  {
    "id": "VNDR-0000162",
    "status": "active",
    "name": "Medique Innovations",
    "tag": "Laboratory Supplies, Equipment",
    "contactName": "Dylan Weaver",
    "industryRating": 3,
    "procedures": "[PR-061,PR-056,PR-068,PR-049,PR-043,PR-018,PR-058,PR-072,PR-052,PR-063]",
    "contracts": "[CNTRCT-0000039,CNTRCT-0000063,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000019,ORDR-2-0000048,ORDR-3-0000031,ORDR-4-0000006,ORDR-5-0000038,ORDR-6-0000062,ORDR-7-0000017,ORDR-8-0000037,ORDR-9-0000068,ORDR-10-0000023,ORDR-11-0000031,ORDR-12-0000058,ORDR-13-0000020,ORDR-14-0000034,ORDR-15-0000040,ORDR-16-0000075,ORDR-17-0000024,ORDR-18-0000053]",
    "spendWeekly": [
      3044,
      3017,
      2931,
      3086,
      2990,
      2949,
      3100,
      3000,
      3038,
      3013,
      2925,
      2978,
      2909,
      3094,
      2964,
      2901,
      3048,
      3047,
      2900,
      3098,
      3052,
      3011,
      3054,
      2973,
      3095,
      2970,
      2973,
      3087,
      3026,
      2968,
      2997,
      3079,
      2936,
      2911,
      3000,
      2922,
      2954,
      2989,
      2914,
      3097,
      2901,
      2958,
      3097,
      3028,
      3063,
      2901,
      2986,
      2900,
      3013,
      3009,
      3048,
      3063
    ],
    "orderRejected": [
      3063,
      19,
      17,
      2,
      5,
      16,
      2,
      2,
      18,
      8,
      4,
      9,
      3,
      3,
      19,
      13,
      19,
      7,
      4,
      3,
      6,
      19,
      0,
      14,
      2,
      14,
      0,
      0,
      19,
      19,
      7,
      19,
      14,
      12,
      19,
      19,
      7,
      12,
      18,
      19,
      0,
      19,
      10,
      3,
      12,
      17,
      1,
      18,
      0,
      5,
      11,
      11,
      4
    ],
    "orderAccepted": [
      4,
      9,
      6,
      7,
      6,
      5,
      7,
      0,
      0,
      4,
      6,
      11,
      9,
      11,
      11,
      11,
      0,
      0,
      6,
      11,
      4,
      7,
      7,
      3,
      4,
      3,
      9,
      4,
      5,
      5,
      10,
      8,
      0,
      0,
      10,
      2,
      9,
      8,
      1,
      2,
      3,
      11,
      5,
      0,
      6,
      11,
      11,
      5,
      10,
      11,
      3,
      1
    ],
    "orderHold": [
      4,
      0,
      2,
      4,
      0,
      1,
      3,
      2,
      1,
      0,
      1,
      0,
      1,
      4,
      1,
      1,
      4,
      0,
      4,
      3,
      2,
      4,
      4,
      4,
      3,
      1,
      4,
      4,
      4,
      0,
      0,
      4,
      3,
      3,
      2,
      4,
      2,
      4,
      2,
      4,
      1,
      1,
      4,
      4,
      1,
      2,
      4,
      4,
      0,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      0,
      2,
      0,
      2,
      0,
      0,
      0,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      2,
      0,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      4,
      2,
      4,
      4,
      0,
      1,
      3,
      4,
      4,
      4,
      3,
      4,
      4,
      4,
      0,
      4,
      1,
      0,
      2,
      0,
      3,
      3,
      4,
      4,
      4,
      3,
      4,
      2,
      4,
      1,
      2,
      1,
      1,
      3,
      0,
      3,
      1,
      0,
      3,
      4,
      0,
      0,
      4,
      2,
      0,
      0,
      0,
      0,
      3,
      4,
      0,
      4
    ],
    "issueDelay": [
      0,
      3,
      4,
      4,
      0,
      4,
      2,
      0,
      1,
      2,
      3,
      1,
      2,
      2,
      3,
      3,
      4,
      0,
      4,
      1,
      4,
      1,
      4,
      0,
      2,
      4,
      4,
      3,
      4,
      1,
      2,
      3,
      2,
      1,
      4,
      0,
      2,
      3,
      2,
      2,
      3,
      2,
      2,
      1,
      3,
      2,
      0,
      0,
      2,
      0,
      2,
      4
    ],
    "issueQuantity": [
      4,
      1,
      2,
      0,
      1,
      3,
      1,
      1,
      2,
      2,
      4,
      2,
      3,
      2,
      4,
      1,
      1,
      2,
      0,
      4,
      2,
      0,
      2,
      3,
      1,
      0,
      2,
      2,
      4,
      1,
      3,
      2,
      2,
      0,
      0,
      4,
      3,
      1,
      1,
      3,
      2,
      1,
      4,
      0,
      3,
      1,
      4,
      2,
      2,
      3,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000163",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "Laboratory Supplies, Pharmaceuticals",
    "contactName": "Jennifer Harris",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-049,PR-038,PR-037,PR-026,PR-070,PR-034,PR-043,PR-045,PR-027]",
    "contracts": "[CNTRCT-0000039,CNTRCT-0000016,CNTRCT-0000009]",
    "orders": "[ORDR-0-0000006,ORDR-1-0000041,ORDR-2-0000031,ORDR-3-0000050,ORDR-4-0000058,ORDR-5-0000016,ORDR-6-0000033,ORDR-7-0000054,ORDR-8-0000064,ORDR-9-0000011,ORDR-10-0000012,ORDR-11-0000051,ORDR-12-0000054,ORDR-13-0000022,ORDR-14-0000022,ORDR-15-0000034,ORDR-16-0000017,ORDR-17-0000077,ORDR-18-0000059]",
    "spendWeekly": [
      3097,
      3021,
      2906,
      2902,
      2915,
      3040,
      3063,
      3098,
      2980,
      3082,
      3100,
      2923,
      2938,
      2993,
      2911,
      2975,
      2927,
      3099,
      3033,
      3047,
      3100,
      3097,
      3071,
      2977,
      3095,
      2917,
      3068,
      3058,
      2901,
      2935,
      3096,
      3081,
      3078,
      2979,
      2995,
      2904,
      2937,
      2966,
      3098,
      2901,
      3011,
      2946,
      3097,
      2990,
      2960,
      2946,
      3099,
      2934,
      3023,
      2910,
      3019,
      3037
    ],
    "orderRejected": [
      3037,
      18,
      19,
      12,
      15,
      9,
      3,
      10,
      19,
      19,
      14,
      19,
      6,
      18,
      19,
      15,
      10,
      4,
      0,
      5,
      0,
      16,
      13,
      1,
      11,
      14,
      19,
      6,
      14,
      14,
      19,
      12,
      19,
      19,
      19,
      15,
      19,
      16,
      15,
      0,
      14,
      7,
      12,
      11,
      19,
      11,
      0,
      18,
      13,
      9,
      10,
      8,
      19
    ],
    "orderAccepted": [
      6,
      9,
      2,
      0,
      8,
      11,
      7,
      11,
      3,
      0,
      0,
      8,
      1,
      5,
      4,
      2,
      4,
      8,
      0,
      9,
      8,
      0,
      1,
      5,
      1,
      5,
      0,
      11,
      5,
      10,
      11,
      10,
      0,
      10,
      2,
      7,
      11,
      9,
      9,
      7,
      0,
      9,
      3,
      11,
      0,
      10,
      0,
      4,
      7,
      3,
      0,
      10
    ],
    "orderHold": [
      2,
      2,
      4,
      1,
      3,
      2,
      4,
      2,
      3,
      4,
      4,
      0,
      1,
      0,
      2,
      0,
      1,
      4,
      4,
      2,
      2,
      4,
      0,
      1,
      0,
      4,
      3,
      1,
      4,
      0,
      4,
      4,
      3,
      1,
      0,
      1,
      1,
      1,
      2,
      4,
      4,
      0,
      3,
      3,
      2,
      3,
      1,
      2,
      0,
      1,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      0,
      0,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      4,
      0,
      3,
      3,
      0,
      1,
      4,
      2,
      4,
      4,
      1,
      1,
      3,
      2,
      3,
      2,
      0,
      4,
      3,
      3,
      2,
      4,
      3,
      3,
      3,
      2,
      2,
      4,
      3,
      3,
      0,
      1,
      3,
      2,
      2,
      4,
      0,
      0,
      2,
      3,
      0,
      4,
      2,
      3,
      4,
      1,
      0,
      4
    ],
    "issueDelay": [
      2,
      2,
      4,
      3,
      2,
      3,
      2,
      3,
      1,
      4,
      0,
      0,
      4,
      3,
      0,
      4,
      4,
      0,
      3,
      4,
      1,
      0,
      0,
      4,
      0,
      4,
      2,
      3,
      4,
      4,
      3,
      2,
      2,
      1,
      3,
      2,
      0,
      2,
      4,
      3,
      3,
      2,
      2,
      0,
      4,
      0,
      2,
      2,
      4,
      1,
      4,
      4
    ],
    "issueQuantity": [
      0,
      1,
      0,
      3,
      3,
      3,
      4,
      3,
      2,
      1,
      0,
      3,
      0,
      1,
      4,
      3,
      2,
      2,
      1,
      4,
      3,
      4,
      3,
      0,
      0,
      4,
      0,
      1,
      4,
      2,
      1,
      2,
      2,
      1,
      0,
      3,
      4,
      0,
      1,
      2,
      4,
      2,
      4,
      2,
      3,
      2,
      3,
      4,
      2,
      4,
      1,
      2
    ]
  },
  {
    "id": "VNDR-0000164",
    "status": "active",
    "name": "HealthHorizon Partners",
    "tag": "PPE, Medical Supplies, Facilities Management",
    "contactName": "Lori Potts",
    "industryRating": 4,
    "procedures": "[PR-061,PR-065,PR-068,PR-066,PR-063,PR-018,PR-068,PR-041,PR-064,PR-040]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000060,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000031,ORDR-1-0000062,ORDR-2-0000032,ORDR-3-0000037,ORDR-4-0000066,ORDR-5-0000041,ORDR-6-0000023,ORDR-7-0000021,ORDR-8-0000015,ORDR-9-0000028,ORDR-10-0000044,ORDR-11-0000052,ORDR-12-0000044,ORDR-13-0000038,ORDR-14-0000077,ORDR-15-0000022,ORDR-16-0000049,ORDR-17-0000057,ORDR-18-0000070]",
    "spendWeekly": [
      3039,
      2902,
      2988,
      3059,
      3095,
      2991,
      2905,
      3012,
      3100,
      2922,
      3100,
      2928,
      2909,
      3094,
      3091,
      2901,
      3026,
      3099,
      3099,
      3050,
      3045,
      3034,
      3097,
      2903,
      3012,
      2974,
      3032,
      2949,
      2959,
      2953,
      2903,
      2981,
      3099,
      2904,
      2938,
      3006,
      2947,
      2919,
      3067,
      3046,
      3035,
      2960,
      3093,
      3091,
      2968,
      2901,
      2921,
      2985,
      2901,
      2939,
      2908,
      3035
    ],
    "orderRejected": [
      3035,
      2,
      19,
      8,
      1,
      10,
      4,
      11,
      19,
      10,
      7,
      14,
      19,
      18,
      17,
      18,
      18,
      0,
      0,
      2,
      7,
      19,
      16,
      17,
      5,
      15,
      7,
      0,
      12,
      19,
      3,
      14,
      0,
      19,
      13,
      14,
      9,
      6,
      16,
      1,
      17,
      11,
      7,
      0,
      13,
      12,
      6,
      5,
      5,
      5,
      6,
      3,
      19
    ],
    "orderAccepted": [
      1,
      3,
      1,
      1,
      11,
      11,
      10,
      5,
      3,
      7,
      11,
      10,
      5,
      8,
      11,
      7,
      11,
      8,
      2,
      4,
      8,
      0,
      0,
      0,
      5,
      8,
      6,
      6,
      6,
      9,
      3,
      11,
      0,
      0,
      5,
      11,
      5,
      1,
      10,
      11,
      3,
      8,
      11,
      10,
      7,
      3,
      7,
      1,
      4,
      1,
      0,
      5
    ],
    "orderHold": [
      2,
      3,
      4,
      2,
      4,
      1,
      2,
      2,
      3,
      3,
      1,
      4,
      0,
      3,
      0,
      0,
      0,
      2,
      4,
      1,
      2,
      4,
      2,
      2,
      0,
      1,
      1,
      1,
      3,
      0,
      3,
      2,
      2,
      4,
      0,
      2,
      2,
      3,
      0,
      2,
      3,
      4,
      4,
      4,
      4,
      4,
      2,
      0,
      0,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      1,
      3,
      2,
      4,
      0,
      4,
      1,
      4,
      0,
      3,
      4,
      3,
      4,
      1,
      0,
      1,
      4,
      3,
      2,
      3,
      0,
      3,
      2,
      4,
      0,
      2,
      0,
      1,
      4,
      4,
      1,
      3,
      1,
      0,
      2,
      0,
      3,
      3,
      4,
      2,
      3,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      3,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      2,
      4,
      4,
      1,
      0,
      2,
      2,
      1,
      4,
      2,
      2,
      4,
      3,
      0,
      2,
      2,
      0,
      2,
      0,
      2,
      2,
      0,
      2,
      3,
      4,
      3,
      3,
      1,
      3,
      4,
      2,
      2,
      0,
      4,
      0,
      0,
      4,
      2,
      4,
      2,
      1,
      0,
      2,
      2,
      0,
      0,
      4,
      3,
      0,
      0,
      4
    ],
    "issueQuantity": [
      3,
      2,
      1,
      0,
      1,
      2,
      1,
      4,
      2,
      1,
      1,
      4,
      0,
      4,
      4,
      4,
      0,
      3,
      2,
      4,
      1,
      4,
      0,
      1,
      0,
      0,
      0,
      1,
      4,
      4,
      3,
      0,
      2,
      1,
      0,
      4,
      3,
      0,
      3,
      0,
      4,
      3,
      4,
      1,
      4,
      0,
      4,
      2,
      0,
      3,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000165",
    "status": "active",
    "name": "HealthSupply Innovations",
    "tag": "Facilities Management, Equipment",
    "contactName": "Anthony Young",
    "industryRating": 3.9,
    "procedures": "[PR-044,PR-065,PR-038,PR-066,PR-064,PR-026,PR-061,PR-039,PR-063,PR-059]",
    "contracts": "[CNTRCT-0000059,CNTRCT-0000063,CNTRCT-0000008]",
    "orders": "[ORDR-0-0000045,ORDR-1-0000021,ORDR-2-0000017,ORDR-3-0000003,ORDR-4-0000047,ORDR-5-0000007,ORDR-6-0000046,ORDR-7-0000025,ORDR-8-0000061,ORDR-9-0000021,ORDR-10-0000071,ORDR-11-0000059,ORDR-12-0000029,ORDR-13-0000019,ORDR-14-0000050,ORDR-15-0000066,ORDR-16-0000028,ORDR-17-0000035,ORDR-18-0000074]",
    "spendWeekly": [
      3014,
      2925,
      2906,
      2923,
      2972,
      3011,
      3013,
      2994,
      2978,
      2918,
      3012,
      2923,
      2981,
      2908,
      3091,
      2991,
      2956,
      3099,
      2998,
      2945,
      3075,
      3097,
      2900,
      3044,
      2954,
      2998,
      2991,
      2901,
      2983,
      3027,
      3063,
      2901,
      3007,
      2995,
      3003,
      3053,
      2917,
      3052,
      2967,
      3094,
      3096,
      3006,
      3074,
      3048,
      3069,
      3099,
      3003,
      2900,
      2961,
      2959,
      3021,
      3005
    ],
    "orderRejected": [
      3005,
      15,
      19,
      4,
      19,
      18,
      0,
      11,
      19,
      16,
      11,
      2,
      3,
      13,
      0,
      16,
      7,
      4,
      13,
      12,
      5,
      15,
      6,
      14,
      19,
      9,
      13,
      5,
      19,
      7,
      9,
      16,
      15,
      19,
      19,
      7,
      5,
      19,
      14,
      19,
      0,
      19,
      17,
      14,
      19,
      6,
      0,
      9,
      10,
      0,
      8,
      19,
      13
    ],
    "orderAccepted": [
      6,
      11,
      7,
      1,
      10,
      5,
      11,
      8,
      5,
      0,
      11,
      11,
      10,
      3,
      6,
      6,
      0,
      1,
      7,
      11,
      8,
      8,
      7,
      5,
      9,
      6,
      0,
      8,
      0,
      2,
      7,
      6,
      3,
      5,
      7,
      5,
      9,
      7,
      11,
      6,
      7,
      11,
      8,
      0,
      3,
      10,
      11,
      3,
      7,
      3,
      0,
      8
    ],
    "orderHold": [
      3,
      2,
      1,
      0,
      4,
      3,
      3,
      1,
      4,
      0,
      3,
      2,
      1,
      1,
      2,
      3,
      2,
      4,
      4,
      4,
      2,
      4,
      2,
      4,
      4,
      4,
      4,
      0,
      3,
      2,
      2,
      4,
      4,
      0,
      1,
      3,
      3,
      0,
      0,
      2,
      1,
      0,
      4,
      4,
      2,
      1,
      1,
      3,
      0,
      1,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      0,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      0,
      0,
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      4,
      0,
      2,
      2,
      3,
      0,
      1,
      4,
      1,
      2,
      2,
      1,
      3,
      1,
      0,
      0,
      4,
      1,
      2,
      3,
      2,
      3,
      1,
      1,
      4,
      2,
      1,
      1,
      4,
      0,
      3,
      1,
      1,
      4,
      0,
      4,
      1,
      4,
      3,
      3,
      2,
      1,
      1,
      2,
      0,
      3,
      0,
      0,
      4,
      1,
      1,
      4
    ],
    "issueDelay": [
      3,
      2,
      1,
      4,
      3,
      4,
      3,
      2,
      2,
      4,
      2,
      1,
      4,
      3,
      3,
      1,
      2,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      3,
      1,
      1,
      2,
      4,
      4,
      4,
      3,
      3,
      3,
      0,
      4,
      3,
      4,
      0,
      0,
      0,
      2,
      4,
      0,
      0,
      0,
      2,
      0,
      0,
      3
    ],
    "issueQuantity": [
      4,
      0,
      4,
      1,
      2,
      0,
      3,
      0,
      3,
      1,
      3,
      0,
      0,
      3,
      3,
      1,
      2,
      4,
      0,
      4,
      3,
      2,
      2,
      0,
      0,
      4,
      3,
      4,
      4,
      4,
      0,
      2,
      4,
      2,
      1,
      4,
      0,
      3,
      0,
      1,
      0,
      3,
      4,
      0,
      3,
      2,
      1,
      0,
      0,
      1,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000166",
    "status": "active",
    "name": "PharmaPoint International",
    "tag": "Imaging",
    "contactName": "Melissa Jefferson",
    "industryRating": 2.9,
    "procedures": "[PR-061,PR-055,PR-038,PR-060,PR-053,PR-066,PR-060,PR-063,PR-071,PR-059]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000066,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000035,ORDR-1-0000031,ORDR-2-0000004,ORDR-3-0000005,ORDR-4-0000004,ORDR-5-0000060,ORDR-6-0000018,ORDR-7-0000016,ORDR-8-0000058,ORDR-9-0000018,ORDR-10-0000057,ORDR-11-0000056,ORDR-12-0000021,ORDR-13-0000058,ORDR-14-0000014,ORDR-15-0000045,ORDR-16-0000076,ORDR-17-0000061,ORDR-18-0000048]",
    "spendWeekly": [
      3042,
      3082,
      2923,
      3002,
      2903,
      2967,
      3051,
      2903,
      2991,
      3025,
      3061,
      3001,
      3025,
      3065,
      2992,
      2901,
      3002,
      3003,
      2973,
      2929,
      3045,
      3022,
      3073,
      2937,
      3001,
      3053,
      3095,
      3038,
      2901,
      3097,
      2992,
      2991,
      2987,
      2904,
      2913,
      2998,
      2956,
      3070,
      3016,
      3030,
      2913,
      2958,
      3097,
      2901,
      3100,
      2931,
      3093,
      2900,
      2959,
      2905,
      2971,
      3006
    ],
    "orderRejected": [
      3006,
      10,
      11,
      4,
      19,
      12,
      8,
      17,
      11,
      18,
      15,
      18,
      13,
      12,
      19,
      0,
      12,
      2,
      12,
      18,
      2,
      19,
      14,
      13,
      14,
      19,
      0,
      3,
      9,
      9,
      5,
      11,
      12,
      12,
      19,
      4,
      6,
      15,
      19,
      16,
      9,
      17,
      11,
      18,
      17,
      12,
      5,
      9,
      0,
      16,
      0,
      0,
      0
    ],
    "orderAccepted": [
      7,
      2,
      6,
      7,
      0,
      6,
      9,
      9,
      5,
      3,
      10,
      0,
      5,
      3,
      5,
      4,
      0,
      8,
      2,
      7,
      8,
      9,
      8,
      8,
      9,
      3,
      0,
      8,
      7,
      5,
      6,
      11,
      9,
      0,
      4,
      4,
      10,
      9,
      9,
      1,
      5,
      11,
      11,
      0,
      0,
      6,
      4,
      10,
      11,
      1,
      1,
      1
    ],
    "orderHold": [
      1,
      3,
      2,
      3,
      3,
      2,
      0,
      2,
      2,
      3,
      4,
      1,
      0,
      3,
      1,
      3,
      1,
      4,
      4,
      2,
      0,
      4,
      1,
      1,
      4,
      2,
      4,
      4,
      4,
      3,
      0,
      4,
      1,
      1,
      4,
      4,
      0,
      0,
      1,
      1,
      3,
      2,
      2,
      0,
      3,
      4,
      3,
      2,
      1,
      0,
      4,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      2,
      1,
      1,
      2,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      0,
      4,
      3,
      4,
      0,
      1,
      1,
      0,
      4,
      3,
      2,
      3,
      2,
      2,
      0,
      0,
      3,
      4,
      1,
      3,
      3,
      0,
      4,
      1,
      1,
      0,
      4,
      1,
      2,
      0,
      1,
      3,
      0,
      0,
      2,
      1,
      2,
      3,
      2,
      4,
      1,
      0,
      0,
      3,
      2,
      4,
      4,
      4,
      1,
      3,
      1,
      2
    ],
    "issueDelay": [
      0,
      3,
      4,
      0,
      1,
      3,
      4,
      2,
      2,
      3,
      4,
      0,
      1,
      3,
      2,
      2,
      4,
      0,
      3,
      4,
      3,
      1,
      0,
      1,
      1,
      1,
      4,
      3,
      1,
      2,
      3,
      3,
      2,
      1,
      2,
      3,
      0,
      2,
      4,
      3,
      0,
      0,
      1,
      1,
      4,
      0,
      0,
      2,
      2,
      0,
      0,
      3
    ],
    "issueQuantity": [
      2,
      0,
      4,
      0,
      3,
      3,
      0,
      1,
      4,
      0,
      0,
      2,
      0,
      2,
      2,
      2,
      2,
      3,
      0,
      4,
      1,
      1,
      0,
      1,
      0,
      1,
      4,
      4,
      3,
      1,
      3,
      4,
      0,
      3,
      1,
      3,
      2,
      0,
      0,
      3,
      4,
      3,
      0,
      0,
      4,
      0,
      4,
      3,
      0,
      0,
      1,
      1
    ]
  },
  {
    "id": "VNDR-0000167",
    "status": "inactive",
    "name": "HealthWave Technologies",
    "tag": "Equipment",
    "contactName": "Carlos Flores",
    "industryRating": 3.9,
    "procedures": "[PR-016,PR-031,PR-043,PR-044,PR-054,PR-049,PR-055,PR-071,PR-045,PR-074]",
    "contracts": "[CNTRCT-0000033,CNTRCT-0000058,CNTRCT-0000037]",
    "orders": "[ORDR-0-0000016,ORDR-1-0000058,ORDR-2-0000058,ORDR-3-0000034,ORDR-4-0000003,ORDR-5-0000042,ORDR-6-0000036,ORDR-7-0000030,ORDR-8-0000015,ORDR-9-0000016,ORDR-10-0000053,ORDR-11-0000063,ORDR-12-0000049,ORDR-13-0000014,ORDR-14-0000037,ORDR-15-0000037,ORDR-16-0000015,ORDR-17-0000048,ORDR-18-0000062]",
    "spendWeekly": [
      3034,
      3082,
      2932,
      2911,
      2903,
      2905,
      2946,
      3063,
      2903,
      2902,
      2965,
      2904,
      3000,
      2946,
      3091,
      2901,
      2995,
      3006,
      2962,
      2945,
      3100,
      3072,
      3097,
      3097,
      2926,
      3018,
      3002,
      2901,
      2907,
      2913,
      3032,
      3097,
      3005,
      2925,
      2992,
      3034,
      2910,
      3088,
      2929,
      3054,
      3051,
      2923,
      3097,
      3091,
      2902,
      2901,
      2901,
      3021,
      2992,
      2905,
      2905,
      3097
    ],
    "orderRejected": [
      3097,
      14,
      6,
      5,
      19,
      16,
      0,
      12,
      19,
      11,
      9,
      5,
      9,
      9,
      7,
      15,
      19,
      0,
      0,
      0,
      13,
      12,
      7,
      9,
      11,
      12,
      15,
      0,
      9,
      19,
      19,
      16,
      13,
      19,
      18,
      0,
      10,
      7,
      12,
      12,
      14,
      13,
      4,
      0,
      19,
      11,
      0,
      16,
      1,
      11,
      11,
      11,
      19
    ],
    "orderAccepted": [
      11,
      0,
      6,
      7,
      7,
      11,
      10,
      11,
      0,
      6,
      10,
      4,
      0,
      3,
      11,
      10,
      4,
      10,
      11,
      8,
      11,
      9,
      8,
      1,
      3,
      8,
      0,
      3,
      7,
      7,
      5,
      11,
      0,
      7,
      5,
      2,
      7,
      0,
      9,
      4,
      7,
      11,
      11,
      0,
      4,
      3,
      0,
      5,
      6,
      9,
      0,
      4
    ],
    "orderHold": [
      2,
      0,
      2,
      2,
      4,
      1,
      4,
      3,
      4,
      4,
      3,
      0,
      3,
      0,
      1,
      4,
      1,
      4,
      4,
      4,
      2,
      4,
      4,
      2,
      0,
      4,
      0,
      2,
      3,
      0,
      2,
      1,
      3,
      1,
      0,
      2,
      2,
      4,
      4,
      4,
      0,
      0,
      4,
      1,
      2,
      4,
      1,
      2,
      1,
      4,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      0,
      0,
      1,
      0,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      0,
      2,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      0,
      0,
      2,
      3,
      2,
      0,
      0,
      3,
      3,
      4,
      2,
      1,
      1,
      2,
      2,
      2,
      4,
      3,
      1,
      4,
      2,
      4,
      4,
      3,
      0,
      3,
      0,
      2,
      3,
      1,
      0,
      4,
      2,
      0,
      1,
      2,
      0,
      1,
      3,
      3,
      0,
      1,
      0,
      4,
      2,
      4,
      2,
      3,
      4,
      1,
      0,
      2
    ],
    "issueDelay": [
      2,
      3,
      2,
      1,
      0,
      3,
      2,
      1,
      1,
      2,
      3,
      1,
      4,
      0,
      0,
      4,
      0,
      1,
      0,
      0,
      3,
      4,
      0,
      2,
      2,
      1,
      2,
      0,
      2,
      4,
      4,
      3,
      1,
      0,
      4,
      2,
      0,
      4,
      3,
      4,
      0,
      4,
      2,
      1,
      2,
      3,
      2,
      2,
      2,
      0,
      0,
      4
    ],
    "issueQuantity": [
      1,
      3,
      0,
      2,
      1,
      4,
      4,
      1,
      3,
      0,
      4,
      0,
      0,
      0,
      0,
      3,
      2,
      4,
      3,
      4,
      4,
      0,
      1,
      0,
      2,
      4,
      2,
      3,
      3,
      0,
      1,
      4,
      3,
      4,
      2,
      2,
      4,
      2,
      2,
      2,
      2,
      4,
      3,
      1,
      4,
      0,
      3,
      3,
      0,
      1,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000168",
    "status": "active",
    "name": "CarePoint Technologies",
    "tag": "PPE",
    "contactName": "Melissa Torres",
    "industryRating": 2.1,
    "procedures": "[PR-064,PR-065,PR-049,PR-045,PR-055,PR-057,PR-060,PR-057,PR-052,PR-063]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000066,CNTRCT-0000051]",
    "orders": "[ORDR-0-0000008,ORDR-1-0000020,ORDR-2-0000029,ORDR-3-0000030,ORDR-4-0000023,ORDR-5-0000028,ORDR-6-0000047,ORDR-7-0000037,ORDR-8-0000065,ORDR-9-0000067,ORDR-10-0000035,ORDR-11-0000032,ORDR-12-0000027,ORDR-13-0000071,ORDR-14-0000073,ORDR-15-0000038,ORDR-16-0000025,ORDR-17-0000036,ORDR-18-0000047]",
    "spendWeekly": [
      3067,
      3046,
      2985,
      2902,
      2984,
      2905,
      2950,
      3062,
      2985,
      2990,
      3092,
      2904,
      2909,
      2999,
      2974,
      2936,
      2928,
      3099,
      2993,
      3079,
      3031,
      3097,
      3015,
      2903,
      3017,
      3096,
      2995,
      2999,
      3030,
      3015,
      2924,
      3007,
      3054,
      2915,
      3023,
      3099,
      3038,
      3001,
      3032,
      3044,
      2984,
      3054,
      3001,
      2976,
      2938,
      2901,
      2901,
      2900,
      2932,
      3002,
      2974,
      2901
    ],
    "orderRejected": [
      2901,
      10,
      4,
      9,
      0,
      19,
      0,
      6,
      15,
      19,
      0,
      7,
      10,
      18,
      9,
      19,
      15,
      0,
      5,
      10,
      19,
      11,
      5,
      19,
      8,
      16,
      0,
      8,
      17,
      3,
      16,
      0,
      3,
      6,
      10,
      9,
      4,
      15,
      10,
      0,
      19,
      18,
      19,
      7,
      2,
      8,
      0,
      12,
      9,
      0,
      16,
      4,
      10
    ],
    "orderAccepted": [
      6,
      11,
      8,
      11,
      0,
      1,
      5,
      4,
      2,
      7,
      0,
      11,
      11,
      7,
      11,
      5,
      0,
      8,
      2,
      2,
      1,
      0,
      6,
      2,
      11,
      11,
      0,
      6,
      1,
      2,
      10,
      11,
      0,
      0,
      3,
      5,
      8,
      3,
      11,
      0,
      0,
      7,
      11,
      7,
      6,
      9,
      8,
      8,
      2,
      8,
      4,
      0
    ],
    "orderHold": [
      2,
      1,
      2,
      1,
      3,
      2,
      2,
      4,
      4,
      2,
      4,
      1,
      0,
      1,
      1,
      4,
      2,
      2,
      1,
      4,
      2,
      3,
      4,
      0,
      0,
      3,
      4,
      0,
      3,
      0,
      0,
      3,
      1,
      2,
      0,
      2,
      0,
      3,
      3,
      0,
      1,
      3,
      0,
      4,
      0,
      0,
      0,
      3,
      4,
      0,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      0,
      0,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      0,
      0,
      1,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      4,
      3,
      0,
      0,
      0,
      1,
      1,
      4,
      0,
      1,
      4,
      3,
      0,
      2,
      0,
      3,
      1,
      1,
      0,
      0,
      3,
      4,
      2,
      1,
      0,
      1,
      4,
      1,
      2,
      4,
      4,
      0,
      4,
      1,
      2,
      0,
      1,
      4,
      4,
      1,
      0,
      2,
      2,
      3,
      3,
      2,
      1,
      1,
      2,
      4
    ],
    "issueDelay": [
      2,
      0,
      1,
      2,
      1,
      1,
      4,
      0,
      2,
      3,
      0,
      4,
      4,
      2,
      3,
      0,
      2,
      0,
      2,
      2,
      3,
      1,
      1,
      1,
      0,
      1,
      4,
      0,
      0,
      4,
      4,
      1,
      2,
      0,
      3,
      4,
      1,
      2,
      2,
      3,
      1,
      4,
      4,
      1,
      4,
      3,
      3,
      0,
      0,
      2,
      0,
      3
    ],
    "issueQuantity": [
      1,
      0,
      4,
      0,
      4,
      1,
      1,
      0,
      0,
      4,
      4,
      0,
      1,
      3,
      2,
      1,
      0,
      4,
      3,
      4,
      3,
      3,
      3,
      1,
      0,
      3,
      2,
      3,
      0,
      4,
      4,
      1,
      4,
      2,
      0,
      2,
      3,
      0,
      1,
      2,
      3,
      1,
      4,
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000169",
    "status": "inactive",
    "name": "PharmaLogix International",
    "tag": "Equipment",
    "contactName": "Steven Wallace",
    "industryRating": 3.5,
    "procedures": "[PR-043,PR-040,PR-054,PR-062,PR-062,PR-069,PR-049,PR-064,PR-073,PR-071]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000036,CNTRCT-0000058]",
    "orders": "[ORDR-0-0000043,ORDR-1-0000015,ORDR-2-0000013,ORDR-3-0000027,ORDR-4-0000009,ORDR-5-0000047,ORDR-6-0000015,ORDR-7-0000033,ORDR-8-0000016,ORDR-9-0000064,ORDR-10-0000046,ORDR-11-0000025,ORDR-12-0000071,ORDR-13-0000048,ORDR-14-0000059,ORDR-15-0000028,ORDR-16-0000064,ORDR-17-0000059,ORDR-18-0000050]",
    "spendWeekly": [
      2982,
      2902,
      2906,
      3019,
      2903,
      3042,
      3100,
      3098,
      3026,
      2931,
      3100,
      3041,
      2924,
      2974,
      3073,
      2988,
      3038,
      3099,
      2959,
      2909,
      3094,
      3011,
      3001,
      3017,
      2906,
      3067,
      2909,
      2973,
      2901,
      2909,
      3011,
      3034,
      3032,
      3012,
      3026,
      3026,
      3100,
      3025,
      3099,
      2935,
      2901,
      2961,
      3033,
      3091,
      2937,
      3099,
      2981,
      2926,
      2963,
      2928,
      2945,
      3067
    ],
    "orderRejected": [
      3067,
      7,
      8,
      9,
      5,
      0,
      6,
      16,
      19,
      6,
      0,
      5,
      19,
      13,
      7,
      16,
      19,
      3,
      0,
      0,
      14,
      17,
      7,
      19,
      12,
      6,
      2,
      0,
      6,
      11,
      9,
      19,
      6,
      17,
      14,
      11,
      17,
      5,
      12,
      14,
      8,
      18,
      19,
      0,
      18,
      2,
      6,
      3,
      3,
      13,
      14,
      19,
      17
    ],
    "orderAccepted": [
      6,
      9,
      0,
      11,
      11,
      7,
      5,
      3,
      4,
      10,
      8,
      6,
      10,
      5,
      8,
      11,
      7,
      6,
      0,
      0,
      6,
      11,
      9,
      0,
      0,
      11,
      0,
      7,
      4,
      11,
      0,
      11,
      0,
      7,
      1,
      7,
      11,
      0,
      11,
      0,
      0,
      11,
      4,
      6,
      10,
      10,
      6,
      6,
      0,
      8,
      7,
      11
    ],
    "orderHold": [
      0,
      0,
      2,
      4,
      4,
      0,
      3,
      0,
      2,
      4,
      0,
      0,
      0,
      2,
      0,
      4,
      0,
      0,
      4,
      0,
      1,
      2,
      2,
      3,
      0,
      3,
      0,
      2,
      4,
      0,
      2,
      0,
      0,
      4,
      0,
      1,
      4,
      2,
      3,
      4,
      1,
      2,
      4,
      2,
      2,
      2,
      2,
      2,
      0,
      2,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      0,
      1,
      0,
      2,
      4,
      2,
      2,
      4,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      4,
      3,
      1,
      3,
      2,
      0,
      4,
      2,
      2,
      0,
      2,
      1,
      3,
      3,
      2,
      3,
      0,
      3,
      3,
      0,
      2,
      4,
      0,
      4,
      2,
      3,
      0,
      4,
      1,
      4,
      4,
      1,
      0,
      2,
      0,
      3
    ],
    "issueDelay": [
      0,
      3,
      4,
      4,
      1,
      4,
      1,
      0,
      1,
      2,
      1,
      3,
      3,
      4,
      0,
      3,
      2,
      0,
      1,
      4,
      0,
      2,
      0,
      3,
      1,
      2,
      3,
      3,
      0,
      3,
      2,
      3,
      1,
      2,
      4,
      2,
      3,
      4,
      3,
      1,
      0,
      1,
      1,
      4,
      3,
      3,
      1,
      0,
      4,
      3,
      2,
      1
    ],
    "issueQuantity": [
      0,
      0,
      1,
      0,
      4,
      3,
      0,
      2,
      3,
      0,
      2,
      0,
      2,
      1,
      2,
      4,
      0,
      0,
      2,
      1,
      3,
      1,
      1,
      1,
      1,
      4,
      4,
      3,
      4,
      4,
      2,
      3,
      4,
      1,
      1,
      3,
      3,
      0,
      1,
      3,
      3,
      4,
      1,
      0,
      4,
      4,
      3,
      3,
      0,
      4,
      2,
      3
    ]
  },
  {
    "id": "VNDR-0000170",
    "status": "active",
    "name": "MedPulse Supply Network",
    "tag": "Equipment, IT Services",
    "contactName": "Bonnie Jones",
    "industryRating": 4.5,
    "procedures": "[PR-051,PR-067,PR-030,PR-055,PR-029,PR-050,PR-067,PR-065,PR-071,PR-071]",
    "contracts": "[CNTRCT-0000029,CNTRCT-0000053,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000059,ORDR-1-0000044,ORDR-2-0000005,ORDR-3-0000035,ORDR-4-0000026,ORDR-5-0000047,ORDR-6-0000015,ORDR-7-0000058,ORDR-8-0000055,ORDR-9-0000015,ORDR-10-0000052,ORDR-11-0000054,ORDR-12-0000033,ORDR-13-0000046,ORDR-14-0000049,ORDR-15-0000021,ORDR-16-0000047,ORDR-17-0000031,ORDR-18-0000071]",
    "spendWeekly": [
      2977,
      2930,
      2942,
      2960,
      2903,
      2926,
      3036,
      3057,
      3062,
      2963,
      2974,
      2965,
      2991,
      2944,
      3024,
      2918,
      2972,
      2921,
      3061,
      3098,
      3058,
      3029,
      2900,
      2992,
      2942,
      2998,
      3029,
      2995,
      2901,
      2955,
      2969,
      3097,
      3099,
      3040,
      2915,
      2967,
      2907,
      3006,
      2909,
      3037,
      3082,
      2953,
      3097,
      2952,
      3011,
      2954,
      3099,
      2945,
      2979,
      2987,
      2948,
      3097
    ],
    "orderRejected": [
      3097,
      19,
      14,
      4,
      14,
      19,
      3,
      15,
      10,
      19,
      0,
      2,
      9,
      8,
      12,
      13,
      2,
      7,
      9,
      6,
      12,
      19,
      3,
      10,
      17,
      9,
      16,
      0,
      0,
      13,
      15,
      2,
      19,
      19,
      3,
      15,
      4,
      8,
      15,
      2,
      13,
      19,
      4,
      8,
      4,
      11,
      12,
      14,
      19,
      12,
      7,
      9,
      14
    ],
    "orderAccepted": [
      5,
      7,
      0,
      7,
      0,
      9,
      11,
      1,
      3,
      0,
      3,
      1,
      11,
      9,
      9,
      11,
      3,
      1,
      4,
      3,
      5,
      7,
      11,
      0,
      6,
      9,
      0,
      4,
      0,
      4,
      10,
      3,
      0,
      0,
      11,
      11,
      9,
      0,
      4,
      5,
      11,
      10,
      3,
      9,
      10,
      3,
      2,
      11,
      6,
      6,
      4,
      1
    ],
    "orderHold": [
      2,
      3,
      3,
      0,
      2,
      0,
      3,
      4,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      2,
      0,
      3,
      4,
      1,
      1,
      2,
      3,
      0,
      4,
      4,
      2,
      4,
      2,
      2,
      4,
      0,
      0,
      4,
      2,
      4,
      4,
      4,
      1,
      1,
      0,
      2,
      1,
      2,
      4,
      3,
      4,
      0,
      2,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      2,
      0,
      2,
      0,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0
    ],
    "issueQuality": [
      2,
      0,
      4,
      1,
      1,
      2,
      2,
      3,
      2,
      4,
      1,
      4,
      2,
      0,
      1,
      3,
      4,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      0,
      1,
      3,
      1,
      3,
      0,
      0,
      2,
      1,
      0,
      3,
      3,
      2,
      2,
      0,
      0,
      1,
      4,
      1,
      2,
      1,
      1,
      3,
      4,
      2,
      4,
      1,
      2
    ],
    "issueDelay": [
      0,
      3,
      1,
      4,
      2,
      4,
      3,
      0,
      2,
      2,
      1,
      3,
      4,
      4,
      2,
      0,
      1,
      0,
      4,
      2,
      1,
      1,
      0,
      4,
      0,
      4,
      3,
      1,
      0,
      4,
      2,
      1,
      4,
      2,
      3,
      3,
      1,
      3,
      1,
      0,
      1,
      1,
      2,
      1,
      4,
      3,
      2,
      0,
      4,
      1,
      0,
      4
    ],
    "issueQuantity": [
      4,
      3,
      1,
      1,
      3,
      2,
      1,
      4,
      3,
      0,
      1,
      2,
      1,
      2,
      4,
      4,
      2,
      2,
      4,
      4,
      0,
      3,
      2,
      0,
      3,
      2,
      0,
      1,
      1,
      4,
      2,
      1,
      4,
      2,
      2,
      4,
      0,
      1,
      2,
      4,
      4,
      3,
      4,
      2,
      4,
      3,
      3,
      0,
      0,
      4,
      1,
      2
    ]
  },
  {
    "id": "VNDR-0000171",
    "status": "inactive",
    "name": "CareBridge Equipment",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Kaitlyn Hernandez",
    "industryRating": 4.8,
    "procedures": "[PR-059,PR-024,PR-067,PR-009,PR-063,PR-061,PR-030,PR-051,PR-056,PR-073]",
    "contracts": "[CNTRCT-0000012,CNTRCT-0000066,CNTRCT-0000041]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000016,ORDR-2-0000026,ORDR-3-0000018,ORDR-4-0000045,ORDR-5-0000015,ORDR-6-0000050,ORDR-7-0000021,ORDR-8-0000047,ORDR-9-0000072,ORDR-10-0000064,ORDR-11-0000038,ORDR-12-0000065,ORDR-13-0000066,ORDR-14-0000046,ORDR-15-0000049,ORDR-16-0000023,ORDR-17-0000024,ORDR-18-0000050]",
    "spendWeekly": [
      2901,
      2926,
      2906,
      3058,
      3027,
      3097,
      3015,
      2983,
      2953,
      2922,
      2911,
      2916,
      2965,
      2915,
      2986,
      2937,
      3098,
      3063,
      2999,
      2977,
      2975,
      2970,
      2996,
      2997,
      2924,
      3067,
      3014,
      2901,
      3035,
      2960,
      3017,
      2995,
      2981,
      2984,
      2990,
      2981,
      2915,
      2932,
      2906,
      3005,
      3055,
      2975,
      3071,
      2964,
      3100,
      2930,
      3099,
      2900,
      2929,
      2905,
      2905,
      3002
    ],
    "orderRejected": [
      3002,
      0,
      4,
      13,
      10,
      15,
      9,
      13,
      13,
      7,
      4,
      19,
      19,
      5,
      14,
      18,
      12,
      13,
      0,
      9,
      2,
      5,
      15,
      19,
      19,
      19,
      16,
      0,
      18,
      16,
      6,
      19,
      18,
      19,
      11,
      14,
      2,
      6,
      5,
      19,
      12,
      9,
      11,
      0,
      16,
      0,
      0,
      9,
      14,
      16,
      10,
      13,
      14
    ],
    "orderAccepted": [
      11,
      3,
      5,
      11,
      11,
      4,
      11,
      4,
      2,
      4,
      11,
      5,
      10,
      4,
      2,
      9,
      2,
      11,
      3,
      0,
      6,
      10,
      4,
      2,
      10,
      11,
      5,
      4,
      0,
      2,
      0,
      3,
      0,
      2,
      0,
      8,
      5,
      0,
      8,
      5,
      5,
      11,
      11,
      0,
      5,
      0,
      0,
      8,
      5,
      9,
      8,
      4
    ],
    "orderHold": [
      2,
      3,
      1,
      3,
      0,
      4,
      4,
      0,
      2,
      4,
      1,
      0,
      2,
      3,
      1,
      2,
      1,
      3,
      3,
      0,
      0,
      0,
      4,
      3,
      2,
      3,
      3,
      2,
      4,
      0,
      2,
      4,
      3,
      2,
      0,
      4,
      4,
      0,
      3,
      1,
      2,
      0,
      4,
      1,
      0,
      3,
      1,
      2,
      0,
      2,
      1,
      4
    ],
    "orderBackordered": [
      4,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      0,
      0,
      2,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      2,
      0,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      3,
      2,
      3,
      2,
      4,
      1,
      3,
      0,
      1,
      3,
      3,
      1,
      1,
      1,
      2,
      4,
      0,
      1,
      4,
      1,
      0,
      3,
      3,
      1,
      3,
      1,
      2,
      4,
      1,
      4,
      1,
      3,
      4,
      4,
      3,
      4,
      3,
      4,
      1,
      0,
      0,
      1,
      2,
      3,
      0,
      4,
      4,
      0,
      1,
      0,
      3
    ],
    "issueDelay": [
      3,
      1,
      3,
      4,
      0,
      4,
      1,
      1,
      2,
      4,
      3,
      0,
      4,
      1,
      3,
      0,
      0,
      3,
      3,
      0,
      0,
      2,
      1,
      3,
      2,
      2,
      3,
      4,
      1,
      4,
      4,
      1,
      4,
      2,
      3,
      2,
      3,
      2,
      4,
      0,
      0,
      3,
      1,
      0,
      4,
      2,
      0,
      4,
      3,
      3,
      0,
      1
    ],
    "issueQuantity": [
      3,
      0,
      1,
      3,
      4,
      4,
      4,
      1,
      1,
      2,
      2,
      2,
      0,
      2,
      4,
      4,
      0,
      3,
      1,
      3,
      0,
      4,
      2,
      2,
      0,
      2,
      2,
      0,
      4,
      0,
      2,
      0,
      4,
      4,
      2,
      1,
      4,
      0,
      0,
      4,
      2,
      3,
      3,
      2,
      3,
      0,
      1,
      0,
      1,
      3,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000172",
    "status": "active",
    "name": "HealthAxis Supplies",
    "tag": "Facilities Management, Equipment",
    "contactName": "Deborah Moore",
    "industryRating": 2.8,
    "procedures": "[PR-023,PR-050,PR-060,PR-067,PR-062,PR-049,PR-069,PR-063,PR-020,PR-067]",
    "contracts": "[CNTRCT-0000041,CNTRCT-0000039,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000013,ORDR-1-0000047,ORDR-2-0000018,ORDR-3-0000014,ORDR-4-0000014,ORDR-5-0000021,ORDR-6-0000061,ORDR-7-0000043,ORDR-8-0000056,ORDR-9-0000058,ORDR-10-0000044,ORDR-11-0000035,ORDR-12-0000035,ORDR-13-0000049,ORDR-14-0000021,ORDR-15-0000048,ORDR-16-0000015,ORDR-17-0000073,ORDR-18-0000036]",
    "spendWeekly": [
      2962,
      2994,
      2995,
      3021,
      2973,
      3052,
      3020,
      3098,
      2981,
      2943,
      3045,
      3095,
      3042,
      3006,
      3091,
      2915,
      2903,
      3041,
      3049,
      2981,
      2920,
      3081,
      3037,
      2904,
      3095,
      3096,
      3082,
      2936,
      3039,
      3015,
      2968,
      3054,
      2938,
      3021,
      3025,
      3100,
      2954,
      2932,
      2959,
      2999,
      2978,
      2907,
      2941,
      3091,
      3000,
      2901,
      3055,
      2983,
      2967,
      3064,
      2905,
      3097
    ],
    "orderRejected": [
      3097,
      19,
      18,
      1,
      0,
      11,
      2,
      7,
      19,
      16,
      4,
      19,
      11,
      5,
      13,
      16,
      10,
      6,
      19,
      11,
      19,
      17,
      4,
      16,
      8,
      11,
      9,
      6,
      2,
      8,
      8,
      16,
      5,
      11,
      19,
      19,
      7,
      9,
      15,
      1,
      10,
      18,
      10,
      3,
      0,
      4,
      0,
      6,
      19,
      15,
      11,
      13,
      14
    ],
    "orderAccepted": [
      8,
      10,
      5,
      11,
      3,
      2,
      5,
      0,
      5,
      2,
      1,
      10,
      11,
      4,
      9,
      5,
      4,
      11,
      3,
      7,
      5,
      5,
      2,
      0,
      11,
      2,
      2,
      4,
      3,
      7,
      4,
      9,
      0,
      0,
      5,
      11,
      10,
      4,
      9,
      11,
      0,
      11,
      4,
      0,
      0,
      4,
      6,
      11,
      9,
      10,
      4,
      0
    ],
    "orderHold": [
      4,
      2,
      4,
      4,
      3,
      2,
      2,
      2,
      0,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      3,
      0,
      3,
      3,
      1,
      4,
      4,
      4,
      0,
      3,
      2,
      4,
      2,
      0,
      1,
      4,
      0,
      0,
      0,
      3,
      2,
      1,
      1,
      1,
      1,
      4,
      4,
      3,
      2,
      4,
      1,
      3,
      0,
      0,
      0,
      4
    ],
    "orderBackordered": [
      4,
      2,
      1,
      0,
      0,
      2,
      0,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      1,
      4,
      2,
      0,
      0,
      2,
      3,
      2,
      1,
      2,
      2,
      4,
      0,
      3,
      2,
      3,
      1,
      4,
      0,
      0,
      3,
      4,
      2,
      4,
      4,
      3,
      0,
      3,
      2,
      0,
      1,
      1,
      0,
      3,
      0,
      2,
      3,
      3,
      4,
      0,
      3,
      2,
      4,
      0,
      0,
      4,
      1,
      1,
      1,
      3
    ],
    "issueDelay": [
      2,
      4,
      0,
      4,
      0,
      2,
      4,
      0,
      2,
      1,
      0,
      2,
      3,
      2,
      2,
      0,
      0,
      1,
      4,
      1,
      4,
      1,
      0,
      0,
      2,
      4,
      3,
      3,
      2,
      4,
      4,
      4,
      3,
      0,
      4,
      2,
      4,
      4,
      3,
      1,
      2,
      3,
      3,
      1,
      1,
      1,
      1,
      4,
      4,
      0,
      1,
      4
    ],
    "issueQuantity": [
      3,
      0,
      1,
      2,
      4,
      1,
      0,
      0,
      4,
      2,
      2,
      1,
      1,
      2,
      4,
      0,
      2,
      2,
      2,
      4,
      1,
      4,
      0,
      3,
      0,
      2,
      0,
      4,
      3,
      0,
      4,
      1,
      2,
      4,
      0,
      3,
      4,
      0,
      1,
      4,
      3,
      3,
      2,
      0,
      1,
      3,
      4,
      2,
      1,
      4,
      4,
      1
    ]
  },
  {
    "id": "VNDR-0000173",
    "status": "inactive",
    "name": "LifeLine Supplies",
    "tag": "Imaging",
    "contactName": "Melissa Jefferson",
    "industryRating": 2.5,
    "procedures": "[PR-066,PR-055,PR-068,PR-050,PR-061,PR-057,PR-072,PR-049,PR-037,PR-044]",
    "contracts": "[CNTRCT-0000042,CNTRCT-0000042,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000066,ORDR-1-0000022,ORDR-2-0000054,ORDR-3-0000061,ORDR-4-0000008,ORDR-5-0000007,ORDR-6-0000032,ORDR-7-0000065,ORDR-8-0000049,ORDR-9-0000032,ORDR-10-0000012,ORDR-11-0000041,ORDR-12-0000018,ORDR-13-0000034,ORDR-14-0000072,ORDR-15-0000078,ORDR-16-0000057,ORDR-17-0000032,ORDR-18-0000061]",
    "spendWeekly": [
      3012,
      3078,
      3016,
      3010,
      3075,
      3022,
      2905,
      2967,
      3031,
      3029,
      2969,
      2974,
      2952,
      3085,
      3091,
      2944,
      3086,
      2995,
      2964,
      3098,
      3035,
      3003,
      2900,
      2959,
      2964,
      3030,
      3016,
      2941,
      2911,
      2907,
      2956,
      3049,
      2990,
      3014,
      3010,
      2939,
      2999,
      3013,
      2993,
      3085,
      2933,
      3000,
      3097,
      2936,
      2946,
      2901,
      3071,
      2934,
      3023,
      3082,
      3014,
      3058
    ],
    "orderRejected": [
      3058,
      19,
      14,
      6,
      5,
      14,
      0,
      4,
      8,
      0,
      6,
      3,
      7,
      4,
      19,
      17,
      17,
      1,
      12,
      3,
      0,
      19,
      0,
      19,
      15,
      17,
      11,
      0,
      12,
      19,
      3,
      19,
      14,
      15,
      12,
      12,
      8,
      0,
      17,
      7,
      0,
      19,
      15,
      0,
      0,
      1,
      3,
      18,
      5,
      14,
      9,
      15,
      19
    ],
    "orderAccepted": [
      9,
      1,
      1,
      11,
      6,
      9,
      11,
      1,
      7,
      1,
      4,
      1,
      3,
      8,
      11,
      11,
      4,
      8,
      2,
      5,
      2,
      11,
      10,
      0,
      5,
      7,
      7,
      5,
      3,
      11,
      11,
      7,
      0,
      4,
      0,
      1,
      5,
      7,
      11,
      6,
      1,
      0,
      1,
      11,
      7,
      8,
      11,
      11,
      2,
      6,
      11,
      11
    ],
    "orderHold": [
      1,
      4,
      3,
      0,
      4,
      4,
      3,
      4,
      0,
      2,
      2,
      1,
      1,
      0,
      0,
      3,
      2,
      0,
      4,
      0,
      0,
      4,
      4,
      0,
      0,
      1,
      3,
      2,
      1,
      0,
      2,
      3,
      0,
      1,
      0,
      2,
      4,
      4,
      3,
      2,
      4,
      1,
      4,
      4,
      3,
      4,
      0,
      0,
      0,
      3,
      4,
      3
    ],
    "orderBackordered": [
      3,
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      2,
      0,
      1,
      2,
      3,
      1,
      1,
      4,
      0,
      4,
      2,
      1,
      4,
      2,
      1,
      1,
      0,
      3,
      2,
      1,
      1,
      0,
      0,
      4,
      0,
      4,
      4,
      1,
      4,
      2,
      2,
      3,
      3,
      0,
      1,
      0,
      0,
      1,
      4,
      2,
      0,
      1,
      1,
      4,
      1,
      0,
      4,
      1,
      1,
      3,
      2,
      4
    ],
    "issueDelay": [
      0,
      2,
      2,
      4,
      2,
      1,
      2,
      4,
      1,
      4,
      2,
      3,
      2,
      2,
      1,
      2,
      3,
      0,
      1,
      2,
      4,
      0,
      0,
      2,
      0,
      4,
      1,
      1,
      2,
      4,
      3,
      1,
      1,
      2,
      3,
      1,
      2,
      2,
      2,
      1,
      0,
      3,
      3,
      2,
      3,
      1,
      1,
      1,
      3,
      0,
      0,
      4
    ],
    "issueQuantity": [
      4,
      1,
      3,
      2,
      2,
      2,
      4,
      2,
      2,
      0,
      3,
      2,
      3,
      1,
      4,
      2,
      2,
      0,
      2,
      3,
      3,
      3,
      0,
      4,
      3,
      4,
      3,
      0,
      4,
      4,
      1,
      4,
      4,
      1,
      2,
      4,
      4,
      0,
      1,
      4,
      4,
      4,
      4,
      1,
      3,
      0,
      4,
      4,
      0,
      2,
      1,
      0
    ]
  },
  {
    "id": "VNDR-0000174",
    "status": "active",
    "name": "CureTech Innovations",
    "tag": "PPE, Imaging, Nutrition",
    "contactName": "Ebony Rogers",
    "industryRating": 4.6,
    "procedures": "[PR-023,PR-065,PR-068,PR-055,PR-070,PR-069,PR-059,PR-021,PR-065,PR-068]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000044,CNTRCT-0000038]",
    "orders": "[ORDR-0-0000052,ORDR-1-0000029,ORDR-2-0000059,ORDR-3-0000014,ORDR-4-0000024,ORDR-5-0000033,ORDR-6-0000022,ORDR-7-0000068,ORDR-8-0000025,ORDR-9-0000070,ORDR-10-0000042,ORDR-11-0000023,ORDR-12-0000072,ORDR-13-0000070,ORDR-14-0000035,ORDR-15-0000070,ORDR-16-0000049,ORDR-17-0000028,ORDR-18-0000061]",
    "spendWeekly": [
      3051,
      2978,
      2974,
      2902,
      2903,
      3047,
      2917,
      3091,
      2935,
      2994,
      3038,
      3095,
      2946,
      2939,
      3054,
      2948,
      2948,
      3065,
      3063,
      3051,
      2942,
      2973,
      2968,
      3072,
      3033,
      3087,
      3007,
      3051,
      3040,
      3030,
      2908,
      3049,
      3055,
      2904,
      3020,
      2972,
      3064,
      2902,
      3014,
      2996,
      3080,
      2937,
      3081,
      3091,
      2943,
      2919,
      3053,
      3011,
      3063,
      2905,
      2955,
      2969
    ],
    "orderRejected": [
      2969,
      18,
      13,
      7,
      7,
      19,
      8,
      1,
      15,
      15,
      0,
      0,
      10,
      13,
      9,
      5,
      10,
      19,
      10,
      3,
      0,
      4,
      6,
      19,
      18,
      11,
      15,
      18,
      8,
      15,
      14,
      10,
      5,
      9,
      12,
      10,
      6,
      5,
      18,
      0,
      11,
      19,
      5,
      3,
      4,
      14,
      11,
      9,
      5,
      7,
      18,
      8,
      0
    ],
    "orderAccepted": [
      7,
      3,
      4,
      3,
      6,
      3,
      9,
      9,
      0,
      6,
      11,
      11,
      10,
      6,
      11,
      0,
      9,
      10,
      6,
      8,
      11,
      5,
      5,
      8,
      7,
      10,
      0,
      5,
      1,
      4,
      8,
      11,
      0,
      2,
      10,
      9,
      9,
      6,
      5,
      4,
      11,
      11,
      8,
      11,
      2,
      4,
      0,
      11,
      0,
      2,
      1,
      6
    ],
    "orderHold": [
      1,
      0,
      3,
      3,
      0,
      2,
      2,
      2,
      4,
      1,
      1,
      3,
      2,
      4,
      1,
      2,
      1,
      1,
      3,
      2,
      2,
      1,
      0,
      2,
      2,
      4,
      3,
      1,
      4,
      0,
      0,
      3,
      3,
      3,
      0,
      4,
      3,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      3,
      4,
      0,
      0,
      0,
      1,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      2,
      0,
      3,
      4,
      2,
      2,
      4,
      4,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      0,
      0,
      2,
      4,
      4,
      3,
      1,
      1,
      3,
      3,
      1,
      4,
      1,
      1,
      0,
      4,
      1,
      4,
      0,
      3,
      2,
      3,
      4,
      0,
      4,
      3,
      0,
      1,
      2,
      4,
      4,
      1,
      1,
      3,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      4,
      2,
      4,
      0,
      4,
      0,
      2,
      4,
      2,
      4,
      3,
      4,
      3,
      2,
      3,
      1,
      2,
      2,
      2,
      0,
      0,
      3,
      2,
      1,
      0,
      0,
      4,
      2,
      4,
      3,
      3,
      2,
      2,
      2,
      3,
      1,
      4,
      3,
      0,
      0,
      0,
      4,
      3,
      1,
      0,
      2,
      4,
      2,
      1,
      3,
      0
    ],
    "issueQuantity": [
      3,
      3,
      1,
      0,
      4,
      4,
      0,
      3,
      4,
      1,
      0,
      1,
      3,
      3,
      4,
      1,
      3,
      1,
      4,
      3,
      3,
      3,
      1,
      4,
      1,
      4,
      0,
      1,
      2,
      1,
      3,
      3,
      4,
      3,
      3,
      3,
      2,
      0,
      0,
      4,
      0,
      2,
      3,
      0,
      4,
      0,
      4,
      3,
      2,
      3,
      3,
      4
    ]
  },
  {
    "id": "VNDR-0000175",
    "status": "inactive",
    "name": "PharmaLogix International",
    "tag": "Laboratory Supplies, Equipment",
    "contactName": "Dylan Weaver",
    "industryRating": 2.1,
    "procedures": "[PR-041,PR-040,PR-030,PR-048,PR-068,PR-036,PR-049,PR-064,PR-055,PR-070]",
    "contracts": "[CNTRCT-0000044,CNTRCT-0000055,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000059,ORDR-1-0000025,ORDR-2-0000033,ORDR-3-0000051,ORDR-4-0000057,ORDR-5-0000005,ORDR-6-0000023,ORDR-7-0000056,ORDR-8-0000014,ORDR-9-0000045,ORDR-10-0000052,ORDR-11-0000016,ORDR-12-0000070,ORDR-13-0000047,ORDR-14-0000069,ORDR-15-0000048,ORDR-16-0000079,ORDR-17-0000062,ORDR-18-0000037]",
    "spendWeekly": [
      2901,
      2957,
      2926,
      2902,
      2903,
      2905,
      2905,
      3059,
      3011,
      2903,
      3036,
      2971,
      2974,
      3090,
      2961,
      2901,
      2929,
      3039,
      3056,
      3098,
      2906,
      3055,
      3032,
      2959,
      2969,
      3096,
      3095,
      3054,
      3035,
      2939,
      3069,
      2915,
      3010,
      2966,
      3023,
      2913,
      2907,
      3073,
      2993,
      3023,
      3093,
      3050,
      2924,
      2984,
      2979,
      2982,
      3005,
      2968,
      2956,
      2905,
      2912,
      3058
    ],
    "orderRejected": [
      3058,
      10,
      14,
      10,
      0,
      17,
      1,
      16,
      19,
      19,
      5,
      11,
      14,
      11,
      15,
      7,
      0,
      7,
      1,
      0,
      0,
      2,
      15,
      9,
      18,
      0,
      1,
      0,
      15,
      16,
      14,
      15,
      11,
      13,
      9,
      18,
      6,
      10,
      9,
      2,
      16,
      15,
      8,
      1,
      15,
      15,
      4,
      7,
      16,
      13,
      3,
      6,
      14
    ],
    "orderAccepted": [
      9,
      10,
      2,
      11,
      3,
      9,
      6,
      6,
      3,
      5,
      0,
      11,
      10,
      6,
      11,
      4,
      11,
      5,
      7,
      7,
      11,
      11,
      2,
      3,
      6,
      3,
      8,
      5,
      4,
      8,
      6,
      5,
      0,
      0,
      7,
      11,
      0,
      0,
      11,
      7,
      1,
      8,
      6,
      11,
      5,
      10,
      11,
      8,
      4,
      7,
      3,
      11
    ],
    "orderHold": [
      1,
      3,
      3,
      1,
      4,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      3,
      3,
      0,
      4,
      2,
      0,
      0,
      4,
      2,
      4,
      0,
      4,
      0,
      3,
      1,
      4,
      4,
      3,
      0,
      2,
      1,
      2,
      2,
      2,
      0,
      3,
      3,
      4,
      0,
      4,
      3,
      3,
      0,
      4,
      0,
      2,
      1,
      0,
      0,
      4
    ],
    "orderBackordered": [
      4,
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      2,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      0,
      0,
      2,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      1,
      1,
      0,
      3,
      1,
      1,
      4,
      3,
      2,
      2,
      1,
      3,
      2,
      2,
      1,
      4,
      3,
      1,
      1,
      3,
      0,
      2,
      1,
      2,
      2,
      4,
      0,
      2,
      3,
      0,
      3,
      2,
      3,
      2,
      1,
      0,
      2,
      3,
      3,
      3,
      4,
      3,
      1,
      2,
      2,
      1,
      1,
      4,
      0,
      4,
      1,
      2
    ],
    "issueDelay": [
      2,
      1,
      0,
      4,
      0,
      3,
      1,
      2,
      2,
      4,
      0,
      3,
      2,
      2,
      4,
      1,
      2,
      1,
      1,
      3,
      1,
      3,
      0,
      0,
      0,
      1,
      1,
      2,
      2,
      4,
      4,
      2,
      4,
      3,
      0,
      0,
      0,
      3,
      4,
      0,
      2,
      2,
      0,
      0,
      2,
      2,
      0,
      0,
      4,
      0,
      3,
      1
    ],
    "issueQuantity": [
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      4,
      0,
      3,
      0,
      3,
      3,
      1,
      2,
      3,
      2,
      1,
      4,
      3,
      2,
      3,
      0,
      3,
      0,
      1,
      0,
      4,
      2,
      3,
      2,
      3,
      2,
      2,
      4,
      0,
      2,
      4,
      3,
      1,
      4,
      2,
      1,
      1,
      1,
      1,
      2,
      4,
      2,
      4
    ]
  },
  {
    "id": "VNDR-0000176",
    "status": "inactive",
    "name": "BioHealth Systems",
    "tag": "PPE",
    "contactName": "Darrell Patterson",
    "industryRating": 2.4,
    "procedures": "[PR-049,PR-049,PR-054,PR-069,PR-066,PR-059,PR-045,PR-052,PR-070,PR-040]",
    "contracts": "[CNTRCT-0000012,CNTRCT-0000040,CNTRCT-0000051]",
    "orders": "[ORDR-0-0000046,ORDR-1-0000047,ORDR-2-0000017,ORDR-3-0000005,ORDR-4-0000025,ORDR-5-0000016,ORDR-6-0000013,ORDR-7-0000010,ORDR-8-0000051,ORDR-9-0000065,ORDR-10-0000012,ORDR-11-0000073,ORDR-12-0000043,ORDR-13-0000019,ORDR-14-0000016,ORDR-15-0000072,ORDR-16-0000021,ORDR-17-0000049,ORDR-18-0000062]",
    "spendWeekly": [
      2956,
      3081,
      2906,
      3040,
      2903,
      3020,
      2988,
      3056,
      3100,
      2985,
      2940,
      3047,
      2982,
      3007,
      3034,
      2921,
      2920,
      2964,
      3099,
      2937,
      3067,
      3025,
      2994,
      2948,
      2980,
      2992,
      3095,
      2936,
      2901,
      2902,
      2926,
      2950,
      3027,
      3004,
      2913,
      2968,
      3011,
      3097,
      3025,
      3099,
      2957,
      3040,
      3054,
      3083,
      2943,
      3047,
      2901,
      3013,
      2937,
      2931,
      2905,
      3052
    ],
    "orderRejected": [
      3052,
      14,
      5,
      0,
      19,
      17,
      5,
      12,
      2,
      13,
      19,
      2,
      7,
      10,
      12,
      7,
      12,
      6,
      10,
      7,
      3,
      15,
      6,
      9,
      18,
      17,
      4,
      0,
      14,
      7,
      7,
      19,
      3,
      19,
      19,
      10,
      13,
      1,
      19,
      0,
      13,
      5,
      9,
      0,
      12,
      9,
      8,
      16,
      14,
      2,
      6,
      18,
      19
    ],
    "orderAccepted": [
      2,
      6,
      1,
      1,
      8,
      11,
      5,
      9,
      0,
      11,
      9,
      5,
      5,
      1,
      5,
      5,
      0,
      7,
      4,
      10,
      4,
      4,
      4,
      3,
      8,
      11,
      0,
      11,
      10,
      5,
      9,
      10,
      0,
      4,
      4,
      5,
      2,
      5,
      9,
      10,
      3,
      11,
      4,
      4,
      0,
      2,
      8,
      11,
      5,
      11,
      2,
      7
    ],
    "orderHold": [
      1,
      2,
      3,
      2,
      3,
      2,
      4,
      2,
      3,
      0,
      1,
      0,
      2,
      1,
      0,
      1,
      4,
      0,
      4,
      0,
      2,
      4,
      4,
      4,
      0,
      2,
      4,
      2,
      3,
      0,
      2,
      2,
      1,
      0,
      3,
      2,
      4,
      0,
      3,
      2,
      2,
      3,
      2,
      3,
      1,
      0,
      0,
      3,
      0,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      0,
      0,
      0,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      0,
      2,
      3,
      4,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      4,
      0,
      1,
      2,
      1,
      0,
      4,
      2,
      3,
      1,
      0,
      4,
      4,
      2,
      2,
      0,
      2,
      4,
      3,
      1,
      1,
      2,
      1,
      3,
      0,
      4,
      1,
      4,
      4,
      0,
      2,
      2,
      3,
      0,
      2,
      4,
      2,
      1,
      2,
      0,
      4
    ],
    "issueDelay": [
      0,
      0,
      2,
      4,
      4,
      3,
      4,
      1,
      1,
      4,
      4,
      3,
      4,
      0,
      0,
      2,
      1,
      0,
      2,
      0,
      4,
      1,
      0,
      3,
      2,
      2,
      2,
      0,
      0,
      2,
      4,
      1,
      3,
      2,
      2,
      2,
      1,
      2,
      4,
      1,
      1,
      1,
      3,
      3,
      3,
      3,
      3,
      2,
      4,
      0,
      3,
      0
    ],
    "issueQuantity": [
      3,
      3,
      2,
      2,
      3,
      2,
      4,
      1,
      4,
      0,
      3,
      2,
      2,
      2,
      4,
      1,
      3,
      4,
      2,
      4,
      0,
      3,
      0,
      0,
      0,
      3,
      2,
      0,
      4,
      1,
      4,
      2,
      1,
      2,
      0,
      1,
      4,
      1,
      2,
      3,
      3,
      2,
      1,
      2,
      4,
      0,
      3,
      3,
      0,
      2,
      2,
      4
    ]
  },
  {
    "id": "VNDR-0000177",
    "status": "inactive",
    "name": "CareFusion Services",
    "tag": "Imaging",
    "contactName": "Jennifer Mccoy",
    "industryRating": 2.2,
    "procedures": "[PR-016,PR-026,PR-047,PR-038,PR-045,PR-057,PR-043,PR-041,PR-070,PR-070]",
    "contracts": "[CNTRCT-0000042,CNTRCT-0000053,CNTRCT-0000055]",
    "orders": "[ORDR-0-0000050,ORDR-1-0000018,ORDR-2-0000024,ORDR-3-0000031,ORDR-4-0000021,ORDR-5-0000028,ORDR-6-0000049,ORDR-7-0000034,ORDR-8-0000037,ORDR-9-0000054,ORDR-10-0000019,ORDR-11-0000013,ORDR-12-0000044,ORDR-13-0000042,ORDR-14-0000013,ORDR-15-0000044,ORDR-16-0000073,ORDR-17-0000020,ORDR-18-0000066]",
    "spendWeekly": [
      3071,
      3021,
      2957,
      2902,
      2918,
      3024,
      3100,
      2982,
      3058,
      3065,
      2901,
      3025,
      3043,
      2973,
      3091,
      2953,
      3098,
      3099,
      2900,
      3098,
      3008,
      2931,
      2906,
      2939,
      3045,
      3037,
      2909,
      2925,
      2910,
      2905,
      3040,
      3017,
      2900,
      2994,
      2913,
      2948,
      3043,
      2998,
      2914,
      3056,
      3089,
      2956,
      2999,
      2919,
      3067,
      3018,
      3039,
      2927,
      3013,
      2922,
      2923,
      2999
    ],
    "orderRejected": [
      2999,
      16,
      3,
      10,
      4,
      18,
      7,
      14,
      11,
      18,
      10,
      6,
      18,
      18,
      19,
      11,
      19,
      10,
      7,
      3,
      13,
      19,
      9,
      10,
      12,
      19,
      11,
      0,
      0,
      17,
      6,
      6,
      11,
      5,
      15,
      12,
      13,
      19,
      8,
      15,
      14,
      15,
      12,
      0,
      5,
      5,
      5,
      18,
      13,
      19,
      10,
      0,
      14
    ],
    "orderAccepted": [
      5,
      2,
      10,
      11,
      11,
      11,
      8,
      0,
      0,
      4,
      0,
      11,
      9,
      4,
      9,
      11,
      0,
      2,
      5,
      0,
      1,
      8,
      2,
      4,
      6,
      9,
      5,
      7,
      8,
      11,
      9,
      1,
      5,
      1,
      0,
      10,
      8,
      0,
      11,
      1,
      11,
      10,
      7,
      2,
      7,
      7,
      7,
      9,
      5,
      9,
      4,
      7
    ],
    "orderHold": [
      3,
      2,
      2,
      4,
      0,
      0,
      2,
      2,
      4,
      0,
      0,
      0,
      4,
      2,
      0,
      3,
      3,
      2,
      4,
      4,
      2,
      1,
      4,
      3,
      2,
      2,
      2,
      1,
      3,
      0,
      1,
      4,
      2,
      2,
      4,
      2,
      3,
      0,
      0,
      3,
      0,
      2,
      3,
      4,
      1,
      4,
      2,
      0,
      3,
      3,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      0,
      2,
      0,
      0,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      0,
      1,
      2,
      4,
      2,
      0,
      0,
      4,
      1,
      1,
      3,
      0,
      3,
      2,
      0,
      2,
      3,
      3,
      1,
      1,
      2,
      4,
      3,
      3,
      4,
      3,
      4,
      1,
      4,
      2,
      3,
      0,
      4,
      0,
      2,
      3,
      0,
      1,
      4,
      4,
      3,
      1,
      4,
      4,
      1,
      0,
      3,
      0,
      4,
      0,
      0,
      4
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      1,
      1,
      1,
      2,
      1,
      2,
      0,
      3,
      4,
      1,
      4,
      1,
      3,
      4,
      2,
      4,
      1,
      3,
      0,
      3,
      3,
      0,
      3,
      1,
      0,
      3,
      3,
      4,
      3,
      0,
      2,
      0,
      1,
      2,
      4,
      4,
      2,
      0,
      2,
      1,
      4,
      1,
      1,
      0,
      0,
      1,
      1,
      3
    ],
    "issueQuantity": [
      3,
      0,
      3,
      2,
      3,
      1,
      2,
      0,
      1,
      0,
      3,
      3,
      1,
      1,
      4,
      1,
      3,
      4,
      0,
      3,
      3,
      4,
      0,
      2,
      0,
      2,
      4,
      2,
      4,
      0,
      1,
      3,
      1,
      4,
      0,
      3,
      3,
      1,
      0,
      4,
      2,
      4,
      4,
      2,
      4,
      3,
      3,
      3,
      1,
      2,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000178",
    "status": "inactive",
    "name": "WellnessWorks Distribution",
    "tag": "Imaging",
    "contactName": "Helen Ponce",
    "industryRating": 2.1,
    "procedures": "[PR-059,PR-047,PR-068,PR-067,PR-061,PR-027,PR-070,PR-046,PR-060,PR-041]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000039,CNTRCT-0000032]",
    "orders": "[ORDR-0-0000027,ORDR-1-0000035,ORDR-2-0000065,ORDR-3-0000005,ORDR-4-0000065,ORDR-5-0000013,ORDR-6-0000020,ORDR-7-0000066,ORDR-8-0000064,ORDR-9-0000068,ORDR-10-0000056,ORDR-11-0000020,ORDR-12-0000056,ORDR-13-0000077,ORDR-14-0000043,ORDR-15-0000048,ORDR-16-0000018,ORDR-17-0000030,ORDR-18-0000031]",
    "spendWeekly": [
      2957,
      3033,
      3015,
      3025,
      3001,
      2915,
      3003,
      2935,
      3022,
      2988,
      3093,
      2972,
      2922,
      2901,
      2927,
      2901,
      2910,
      3099,
      3040,
      2932,
      3097,
      3058,
      2949,
      3006,
      2981,
      3092,
      2918,
      3025,
      3029,
      2902,
      3012,
      2935,
      2969,
      3000,
      3046,
      2961,
      3080,
      3032,
      3034,
      3046,
      3019,
      3018,
      3097,
      3089,
      3100,
      2919,
      3059,
      2971,
      3033,
      3061,
      2968,
      2901
    ],
    "orderRejected": [
      2901,
      15,
      18,
      14,
      19,
      3,
      0,
      10,
      19,
      19,
      14,
      3,
      13,
      5,
      14,
      19,
      18,
      12,
      1,
      3,
      9,
      11,
      16,
      12,
      12,
      5,
      15,
      11,
      5,
      5,
      8,
      3,
      0,
      11,
      13,
      16,
      11,
      11,
      15,
      2,
      11,
      19,
      6,
      4,
      2,
      3,
      12,
      10,
      0,
      3,
      12,
      3,
      17
    ],
    "orderAccepted": [
      2,
      8,
      4,
      5,
      5,
      11,
      11,
      0,
      8,
      4,
      0,
      6,
      2,
      7,
      6,
      8,
      0,
      6,
      0,
      2,
      2,
      7,
      5,
      7,
      4,
      2,
      4,
      7,
      1,
      10,
      11,
      4,
      0,
      4,
      0,
      7,
      8,
      1,
      7,
      6,
      5,
      7,
      8,
      1,
      10,
      8,
      6,
      2,
      6,
      10,
      3,
      8
    ],
    "orderHold": [
      3,
      2,
      4,
      2,
      3,
      3,
      3,
      0,
      3,
      0,
      1,
      1,
      2,
      2,
      2,
      3,
      2,
      4,
      4,
      1,
      0,
      2,
      1,
      3,
      2,
      4,
      1,
      0,
      3,
      2,
      1,
      2,
      2,
      1,
      2,
      4,
      3,
      3,
      2,
      2,
      0,
      3,
      4,
      3,
      2,
      1,
      0,
      3,
      1,
      0,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      0,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      2,
      2,
      2,
      2,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      3,
      0,
      2,
      1,
      1,
      1,
      3,
      4,
      0,
      1,
      4,
      0,
      4,
      1,
      0,
      0,
      2,
      2,
      3,
      3,
      2,
      0,
      2,
      2,
      1,
      1,
      2,
      0,
      4,
      0,
      4,
      3,
      0,
      0,
      4,
      1,
      4,
      2,
      3,
      4,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      4,
      1,
      1,
      0,
      2
    ],
    "issueDelay": [
      1,
      2,
      2,
      3,
      3,
      4,
      4,
      1,
      2,
      0,
      3,
      1,
      2,
      4,
      1,
      2,
      2,
      4,
      1,
      0,
      4,
      2,
      2,
      2,
      0,
      4,
      1,
      2,
      3,
      0,
      3,
      2,
      1,
      3,
      3,
      0,
      0,
      4,
      4,
      4,
      4,
      2,
      1,
      2,
      4,
      1,
      3,
      0,
      2,
      0,
      1,
      2
    ],
    "issueQuantity": [
      2,
      1,
      2,
      3,
      1,
      0,
      0,
      2,
      3,
      3,
      1,
      2,
      0,
      3,
      3,
      1,
      0,
      2,
      4,
      3,
      0,
      1,
      1,
      0,
      0,
      3,
      2,
      1,
      1,
      3,
      4,
      0,
      4,
      4,
      0,
      3,
      3,
      0,
      0,
      3,
      0,
      3,
      2,
      4,
      0,
      0,
      0,
      4,
      3,
      3,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000179",
    "status": "inactive",
    "name": "LifeLine Supplies",
    "tag": "Imaging",
    "contactName": "Luis Mccarthy",
    "industryRating": 2.4,
    "procedures": "[PR-043,PR-064,PR-055,PR-068,PR-024,PR-061,PR-056,PR-073,PR-057,PR-069]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000062,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000047,ORDR-1-0000065,ORDR-2-0000014,ORDR-3-0000022,ORDR-4-0000059,ORDR-5-0000039,ORDR-6-0000049,ORDR-7-0000006,ORDR-8-0000049,ORDR-9-0000023,ORDR-10-0000030,ORDR-11-0000033,ORDR-12-0000063,ORDR-13-0000053,ORDR-14-0000029,ORDR-15-0000028,ORDR-16-0000032,ORDR-17-0000037,ORDR-18-0000030]",
    "spendWeekly": [
      3078,
      2977,
      2906,
      2972,
      2966,
      2905,
      3089,
      3098,
      2902,
      2970,
      2946,
      3005,
      2909,
      2979,
      3042,
      2959,
      3054,
      2970,
      2994,
      3065,
      3100,
      3076,
      3056,
      3097,
      3010,
      3019,
      2909,
      3096,
      2942,
      2911,
      3035,
      3062,
      2904,
      3059,
      2913,
      2904,
      3100,
      3090,
      3049,
      2950,
      3057,
      3016,
      3056,
      3059,
      3023,
      3034,
      3018,
      3010,
      2998,
      2934,
      2919,
      2925
    ],
    "orderRejected": [
      2925,
      14,
      1,
      19,
      15,
      10,
      9,
      19,
      18,
      17,
      19,
      14,
      13,
      7,
      9,
      17,
      17,
      0,
      8,
      0,
      18,
      6,
      4,
      18,
      19,
      0,
      2,
      0,
      5,
      10,
      15,
      13,
      0,
      14,
      10,
      9,
      8,
      3,
      5,
      16,
      18,
      16,
      19,
      0,
      17,
      8,
      8,
      18,
      6,
      9,
      12,
      5,
      14
    ],
    "orderAccepted": [
      0,
      7,
      5,
      11,
      9,
      8,
      1,
      3,
      2,
      0,
      3,
      0,
      8,
      8,
      1,
      11,
      0,
      8,
      4,
      1,
      0,
      3,
      8,
      9,
      7,
      6,
      5,
      11,
      8,
      4,
      9,
      3,
      0,
      8,
      11,
      5,
      5,
      0,
      11,
      10,
      5,
      11,
      11,
      0,
      11,
      11,
      6,
      7,
      0,
      10,
      0,
      0
    ],
    "orderHold": [
      4,
      0,
      0,
      3,
      0,
      2,
      3,
      1,
      4,
      1,
      0,
      2,
      0,
      3,
      0,
      2,
      2,
      0,
      4,
      2,
      1,
      1,
      4,
      4,
      2,
      4,
      3,
      4,
      2,
      2,
      4,
      4,
      0,
      4,
      4,
      2,
      3,
      0,
      3,
      2,
      4,
      1,
      3,
      0,
      3,
      4,
      4,
      3,
      0,
      2,
      2,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      2,
      3,
      4,
      2,
      2,
      3,
      4,
      1,
      3,
      1,
      3,
      1,
      1,
      2,
      0,
      1,
      3,
      2,
      1,
      2,
      0,
      4,
      1,
      0,
      4,
      3,
      0,
      2,
      4,
      4,
      3,
      3,
      1,
      4,
      1,
      4,
      0,
      2,
      4,
      1,
      3,
      0,
      4,
      0,
      2,
      1,
      4,
      1,
      2,
      4,
      2
    ],
    "issueDelay": [
      3,
      0,
      4,
      3,
      4,
      3,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      2,
      2,
      0,
      0,
      4,
      0,
      4,
      2,
      3,
      4,
      1,
      3,
      2,
      2,
      3,
      3,
      1,
      0,
      1,
      4,
      4,
      4,
      4,
      4,
      1,
      0,
      0,
      3,
      3,
      3,
      2,
      2,
      0,
      1,
      4,
      0,
      3
    ],
    "issueQuantity": [
      4,
      2,
      2,
      0,
      4,
      4,
      0,
      4,
      3,
      2,
      0,
      1,
      2,
      3,
      0,
      1,
      3,
      0,
      2,
      2,
      1,
      2,
      2,
      4,
      2,
      3,
      4,
      0,
      2,
      2,
      2,
      3,
      0,
      2,
      4,
      3,
      3,
      0,
      0,
      4,
      4,
      2,
      2,
      2,
      4,
      0,
      2,
      0,
      2,
      3,
      3,
      0
    ]
  },
  {
    "id": "VNDR-0000180",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Equipment",
    "contactName": "Laura Thomas",
    "industryRating": 2.7,
    "procedures": "[PR-044,PR-054,PR-057,PR-040,PR-069,PR-028,PR-050,PR-064,PR-074,PR-046]",
    "contracts": "[CNTRCT-0000003,CNTRCT-0000036,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000035,ORDR-1-0000046,ORDR-2-0000004,ORDR-3-0000053,ORDR-4-0000046,ORDR-5-0000039,ORDR-6-0000034,ORDR-7-0000061,ORDR-8-0000040,ORDR-9-0000022,ORDR-10-0000074,ORDR-11-0000017,ORDR-12-0000031,ORDR-13-0000027,ORDR-14-0000048,ORDR-15-0000033,ORDR-16-0000037,ORDR-17-0000044,ORDR-18-0000024]",
    "spendWeekly": [
      2935,
      3027,
      2940,
      2943,
      2952,
      2916,
      3100,
      2903,
      3037,
      3099,
      2979,
      3002,
      2990,
      3094,
      3070,
      2906,
      2903,
      3085,
      2946,
      3098,
      3100,
      3097,
      3007,
      3008,
      3087,
      3066,
      3079,
      2970,
      2948,
      3031,
      3096,
      3020,
      3061,
      3015,
      3059,
      2966,
      2929,
      2902,
      2998,
      3058,
      2901,
      2978,
      3097,
      3091,
      3041,
      2901,
      2907,
      3007,
      2954,
      2905,
      3038,
      3097
    ],
    "orderRejected": [
      3097,
      7,
      10,
      9,
      0,
      19,
      0,
      8,
      12,
      16,
      3,
      0,
      19,
      18,
      13,
      6,
      7,
      10,
      11,
      3,
      6,
      18,
      3,
      10,
      3,
      19,
      0,
      0,
      15,
      19,
      16,
      11,
      14,
      14,
      9,
      16,
      0,
      6,
      19,
      14,
      18,
      9,
      6,
      11,
      0,
      1,
      7,
      0,
      12,
      6,
      6,
      17,
      7
    ],
    "orderAccepted": [
      8,
      10,
      1,
      9,
      7,
      2,
      7,
      6,
      0,
      5,
      0,
      0,
      0,
      11,
      6,
      4,
      11,
      8,
      4,
      4,
      7,
      5,
      6,
      3,
      0,
      7,
      0,
      11,
      0,
      3,
      8,
      11,
      0,
      0,
      7,
      10,
      0,
      6,
      10,
      11,
      4,
      8,
      11,
      1,
      0,
      11,
      11,
      11,
      11,
      8,
      0,
      3
    ],
    "orderHold": [
      0,
      1,
      0,
      0,
      3,
      0,
      4,
      4,
      0,
      4,
      4,
      1,
      3,
      2,
      3,
      2,
      4,
      0,
      1,
      3,
      4,
      2,
      1,
      0,
      0,
      3,
      2,
      1,
      4,
      1,
      1,
      4,
      4,
      2,
      0,
      2,
      4,
      1,
      3,
      2,
      2,
      1,
      4,
      2,
      2,
      0,
      3,
      1,
      0,
      2,
      0,
      3
    ],
    "orderBackordered": [
      3,
      0,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      2,
      2,
      0,
      0,
      2,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      0,
      2,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      0,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      1,
      2,
      4,
      0,
      3,
      0,
      3,
      2,
      4,
      3,
      3,
      3,
      4,
      4,
      1,
      0,
      1,
      2,
      2,
      4,
      0,
      2,
      4,
      4,
      1,
      0,
      0,
      2,
      4,
      3,
      0,
      4,
      4,
      1,
      3,
      3,
      0,
      1,
      4,
      1,
      0,
      0,
      2,
      0,
      4,
      0,
      2,
      4,
      3,
      2,
      4,
      2
    ],
    "issueDelay": [
      3,
      4,
      4,
      2,
      2,
      3,
      0,
      0,
      0,
      0,
      3,
      3,
      1,
      3,
      4,
      4,
      2,
      0,
      2,
      2,
      1,
      1,
      1,
      4,
      3,
      2,
      1,
      0,
      2,
      4,
      2,
      3,
      1,
      4,
      3,
      2,
      0,
      2,
      4,
      2,
      3,
      0,
      4,
      3,
      0,
      3,
      1,
      0,
      4,
      0,
      3,
      3
    ],
    "issueQuantity": [
      2,
      3,
      3,
      0,
      4,
      4,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      4,
      0,
      0,
      4,
      4,
      2,
      3,
      4,
      2,
      4,
      2,
      0,
      4,
      0,
      3,
      1,
      0,
      2,
      3,
      4,
      4,
      3,
      4,
      4,
      3,
      2,
      4,
      4,
      3,
      3,
      0,
      3,
      0,
      4,
      0,
      3,
      0,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000181",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "Facilities Management, Surgical Instruments, Equipment",
    "contactName": "Christine Miller",
    "industryRating": 2.1,
    "procedures": "[PR-047,PR-056,PR-068,PR-067,PR-022,PR-068,PR-058,PR-049,PR-057,PR-040]",
    "contracts": "[CNTRCT-0000048,CNTRCT-0000049,CNTRCT-0000036]",
    "orders": "[ORDR-0-0000025,ORDR-1-0000018,ORDR-2-0000043,ORDR-3-0000050,ORDR-4-0000017,ORDR-5-0000023,ORDR-6-0000013,ORDR-7-0000052,ORDR-8-0000022,ORDR-9-0000030,ORDR-10-0000055,ORDR-11-0000034,ORDR-12-0000020,ORDR-13-0000023,ORDR-14-0000028,ORDR-15-0000023,ORDR-16-0000027,ORDR-17-0000041,ORDR-18-0000077]",
    "spendWeekly": [
      2971,
      3060,
      2963,
      2957,
      2982,
      2946,
      3039,
      3074,
      3013,
      3062,
      3080,
      2904,
      2909,
      2986,
      2946,
      2943,
      3037,
      3012,
      2900,
      2989,
      3095,
      3062,
      2900,
      2903,
      2966,
      3047,
      2924,
      2901,
      2978,
      3001,
      2916,
      2975,
      3099,
      2909,
      2919,
      3067,
      3015,
      3082,
      3005,
      3048,
      3002,
      3016,
      2959,
      3014,
      3048,
      2901,
      3035,
      2959,
      2963,
      3079,
      3076,
      3054
    ],
    "orderRejected": [
      3054,
      9,
      4,
      14,
      17,
      19,
      5,
      13,
      0,
      7,
      5,
      7,
      11,
      0,
      17,
      18,
      19,
      6,
      0,
      6,
      16,
      18,
      14,
      19,
      19,
      13,
      2,
      0,
      14,
      13,
      15,
      15,
      19,
      19,
      5,
      17,
      8,
      13,
      12,
      1,
      11,
      19,
      6,
      19,
      3,
      8,
      5,
      17,
      19,
      8,
      7,
      13,
      18
    ],
    "orderAccepted": [
      1,
      5,
      2,
      5,
      0,
      0,
      5,
      2,
      3,
      5,
      0,
      7,
      11,
      6,
      11,
      5,
      0,
      9,
      2,
      2,
      2,
      0,
      0,
      3,
      7,
      2,
      0,
      9,
      7,
      3,
      11,
      8,
      0,
      0,
      6,
      6,
      9,
      4,
      5,
      2,
      10,
      10,
      11,
      11,
      0,
      10,
      9,
      11,
      11,
      6,
      0,
      6
    ],
    "orderHold": [
      4,
      1,
      3,
      2,
      4,
      2,
      3,
      2,
      3,
      2,
      3,
      0,
      1,
      1,
      0,
      3,
      0,
      0,
      3,
      0,
      2,
      2,
      1,
      4,
      0,
      0,
      0,
      0,
      4,
      0,
      2,
      2,
      4,
      2,
      3,
      1,
      0,
      2,
      3,
      4,
      0,
      0,
      2,
      4,
      4,
      1,
      1,
      2,
      0,
      0,
      2,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      2,
      2,
      2,
      2,
      0,
      1,
      0,
      1,
      0,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      2,
      0,
      0,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      0,
      2,
      0,
      0,
      0,
      2,
      1,
      0,
      2,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      4,
      4,
      0,
      1,
      2,
      2,
      1,
      4,
      4,
      0,
      0,
      0,
      1,
      1,
      4,
      3,
      2,
      0,
      0,
      2,
      3,
      2,
      2,
      4,
      4,
      2,
      3,
      0,
      3,
      4,
      0,
      1,
      4,
      3,
      1,
      0,
      2,
      4,
      1,
      1,
      1,
      4,
      4,
      1,
      3,
      1,
      0,
      4,
      1,
      4
    ],
    "issueDelay": [
      4,
      4,
      1,
      4,
      2,
      4,
      4,
      1,
      0,
      2,
      0,
      2,
      3,
      4,
      4,
      1,
      1,
      0,
      1,
      0,
      0,
      2,
      4,
      2,
      0,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      4,
      0,
      1,
      4,
      1,
      4,
      2,
      3,
      1,
      3,
      1,
      0,
      4,
      1,
      0,
      2,
      4,
      4,
      0,
      4
    ],
    "issueQuantity": [
      2,
      2,
      4,
      2,
      3,
      2,
      0,
      3,
      2,
      2,
      2,
      3,
      0,
      0,
      3,
      1,
      2,
      4,
      4,
      4,
      2,
      4,
      0,
      4,
      2,
      2,
      4,
      2,
      0,
      3,
      4,
      4,
      2,
      4,
      1,
      3,
      3,
      3,
      0,
      4,
      2,
      2,
      3,
      0,
      4,
      2,
      4,
      0,
      0,
      4,
      4,
      0
    ]
  },
  {
    "id": "VNDR-0000182",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Facilities Management, Equipment",
    "contactName": "Brian Baird",
    "industryRating": 2.1,
    "procedures": "[PR-061,PR-065,PR-049,PR-048,PR-009,PR-070,PR-049,PR-071,PR-072,PR-072]",
    "contracts": "[CNTRCT-0000059,CNTRCT-0000055,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000042,ORDR-1-0000066,ORDR-2-0000026,ORDR-3-0000014,ORDR-4-0000004,ORDR-5-0000055,ORDR-6-0000033,ORDR-7-0000006,ORDR-8-0000068,ORDR-9-0000050,ORDR-10-0000065,ORDR-11-0000039,ORDR-12-0000022,ORDR-13-0000042,ORDR-14-0000037,ORDR-15-0000062,ORDR-16-0000034,ORDR-17-0000025,ORDR-18-0000025]",
    "spendWeekly": [
      2974,
      2961,
      2956,
      3031,
      3005,
      2937,
      3100,
      2937,
      3000,
      2947,
      3022,
      2979,
      3073,
      2962,
      3069,
      2901,
      2903,
      3099,
      2900,
      3017,
      3008,
      2944,
      2915,
      3009,
      2984,
      3053,
      3007,
      3029,
      2989,
      2970,
      2903,
      2901,
      3017,
      2974,
      3021,
      3018,
      3001,
      3073,
      3050,
      3012,
      2937,
      3016,
      3097,
      3020,
      2902,
      2901,
      2919,
      2954,
      2997,
      2905,
      2973,
      2961
    ],
    "orderRejected": [
      2961,
      13,
      2,
      1,
      12,
      17,
      2,
      18,
      11,
      8,
      10,
      19,
      13,
      18,
      10,
      13,
      15,
      0,
      4,
      16,
      13,
      9,
      0,
      19,
      19,
      18,
      6,
      2,
      8,
      11,
      11,
      18,
      0,
      16,
      17,
      19,
      2,
      10,
      8,
      2,
      0,
      19,
      9,
      0,
      6,
      18,
      13,
      14,
      11,
      0,
      11,
      6,
      12
    ],
    "orderAccepted": [
      8,
      7,
      4,
      9,
      5,
      11,
      8,
      5,
      4,
      0,
      11,
      2,
      4,
      7,
      11,
      9,
      0,
      9,
      10,
      11,
      4,
      0,
      5,
      0,
      10,
      5,
      1,
      6,
      8,
      0,
      0,
      6,
      8,
      2,
      2,
      8,
      9,
      6,
      9,
      2,
      0,
      11,
      7,
      7,
      4,
      7,
      4,
      11,
      0,
      11,
      4,
      3
    ],
    "orderHold": [
      1,
      4,
      3,
      1,
      1,
      3,
      2,
      4,
      0,
      4,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      4,
      4,
      0,
      2,
      4,
      4,
      3,
      1,
      4,
      0,
      1,
      1,
      4,
      4,
      1,
      3,
      2,
      1,
      2,
      0,
      3,
      4,
      1,
      1,
      4,
      2,
      1,
      4,
      3,
      1,
      1,
      4,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      0,
      4,
      4,
      0,
      4,
      1,
      4,
      1,
      3,
      2,
      1,
      2,
      0,
      0,
      1,
      0,
      3,
      2,
      1,
      3,
      1,
      3,
      2,
      1,
      4,
      4,
      1,
      0,
      1,
      3,
      2,
      1,
      1,
      1,
      3,
      1,
      1,
      3,
      4,
      2,
      2,
      0,
      4,
      2,
      4,
      0,
      4,
      3,
      3,
      0,
      4
    ],
    "issueDelay": [
      3,
      2,
      0,
      3,
      0,
      3,
      4,
      2,
      4,
      2,
      2,
      0,
      3,
      0,
      2,
      2,
      3,
      1,
      2,
      0,
      3,
      2,
      3,
      4,
      1,
      0,
      3,
      0,
      1,
      4,
      2,
      2,
      4,
      1,
      2,
      4,
      4,
      4,
      2,
      2,
      0,
      0,
      0,
      0,
      4,
      0,
      0,
      3,
      1,
      1,
      3,
      2
    ],
    "issueQuantity": [
      4,
      2,
      2,
      0,
      3,
      3,
      1,
      0,
      1,
      2,
      4,
      2,
      0,
      1,
      4,
      4,
      1,
      1,
      0,
      3,
      2,
      3,
      0,
      3,
      2,
      3,
      1,
      0,
      3,
      0,
      4,
      2,
      2,
      1,
      0,
      3,
      4,
      0,
      0,
      2,
      0,
      2,
      4,
      0,
      4,
      2,
      3,
      0,
      1,
      3,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000183",
    "status": "inactive",
    "name": "HealthCraft Logistics",
    "tag": "Facilities Management, Pharmaceuticals, Surgical Instruments",
    "contactName": "Jennifer Harris",
    "industryRating": 3.7,
    "procedures": "[PR-041,PR-064,PR-022,PR-045,PR-030,PR-058,PR-067,PR-070,PR-064,PR-062]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000062,CNTRCT-0000045]",
    "orders": "[ORDR-0-0000048,ORDR-1-0000022,ORDR-2-0000056,ORDR-3-0000031,ORDR-4-0000025,ORDR-5-0000023,ORDR-6-0000024,ORDR-7-0000048,ORDR-8-0000037,ORDR-9-0000066,ORDR-10-0000053,ORDR-11-0000067,ORDR-12-0000074,ORDR-13-0000042,ORDR-14-0000044,ORDR-15-0000028,ORDR-16-0000028,ORDR-17-0000039,ORDR-18-0000028]",
    "spendWeekly": [
      3004,
      2949,
      2936,
      3038,
      2903,
      3070,
      3011,
      3098,
      2902,
      3003,
      3023,
      3095,
      2984,
      2952,
      3091,
      2911,
      3098,
      3028,
      2949,
      2957,
      3100,
      2946,
      3097,
      2995,
      3095,
      3059,
      2909,
      2954,
      2987,
      3005,
      3096,
      3055,
      3061,
      2962,
      2997,
      3093,
      2922,
      2902,
      2906,
      3070,
      3042,
      2965,
      3059,
      2901,
      2902,
      2901,
      3006,
      2900,
      3078,
      2905,
      2905,
      2901
    ],
    "orderRejected": [
      2901,
      19,
      19,
      18,
      15,
      8,
      5,
      14,
      19,
      14,
      1,
      1,
      19,
      15,
      19,
      3,
      19,
      7,
      0,
      3,
      14,
      0,
      18,
      14,
      0,
      11,
      0,
      5,
      14,
      19,
      6,
      6,
      19,
      19,
      2,
      12,
      13,
      18,
      1,
      8,
      17,
      19,
      13,
      1,
      19,
      19,
      0,
      13,
      4,
      2,
      9,
      0,
      19
    ],
    "orderAccepted": [
      11,
      7,
      5,
      11,
      1,
      10,
      11,
      0,
      5,
      2,
      1,
      11,
      5,
      2,
      8,
      11,
      2,
      6,
      8,
      0,
      2,
      0,
      8,
      0,
      9,
      9,
      8,
      11,
      0,
      11,
      11,
      11,
      0,
      0,
      7,
      7,
      0,
      7,
      11,
      4,
      0,
      8,
      10,
      5,
      0,
      9,
      1,
      8,
      0,
      0,
      5,
      3
    ],
    "orderHold": [
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      4,
      2,
      2,
      0,
      3,
      3,
      1,
      1,
      4,
      4,
      1,
      4,
      2,
      2,
      4,
      4,
      4,
      0,
      3,
      1,
      2,
      3,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      4,
      3,
      4,
      2,
      4,
      0,
      4,
      1,
      2,
      3,
      1,
      2,
      0,
      1,
      1,
      3
    ],
    "orderBackordered": [
      3,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      1,
      0,
      1,
      2,
      0,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      1,
      3,
      4,
      1,
      0,
      1,
      3,
      1,
      3,
      4,
      0,
      4,
      2,
      0,
      1,
      4,
      2,
      1,
      0,
      2,
      4,
      2,
      1,
      1,
      2,
      2,
      2,
      3,
      1,
      0,
      2,
      1,
      3,
      4,
      3,
      4,
      4,
      2,
      1,
      0,
      2,
      2,
      4,
      2,
      3,
      0,
      1,
      1,
      4,
      2,
      3
    ],
    "issueDelay": [
      0,
      1,
      4,
      4,
      2,
      3,
      4,
      2,
      0,
      2,
      0,
      2,
      2,
      2,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      3,
      0,
      2,
      2,
      3,
      4,
      0,
      2,
      3,
      2,
      4,
      4,
      0,
      2,
      3,
      0,
      1,
      0,
      2,
      1,
      4,
      4,
      0,
      2,
      1,
      2,
      0,
      2,
      2,
      4,
      3
    ],
    "issueQuantity": [
      2,
      0,
      4,
      2,
      2,
      2,
      4,
      0,
      0,
      2,
      0,
      2,
      1,
      2,
      4,
      2,
      4,
      2,
      1,
      1,
      0,
      2,
      2,
      4,
      2,
      4,
      2,
      1,
      1,
      4,
      3,
      2,
      4,
      3,
      0,
      2,
      4,
      3,
      2,
      4,
      3,
      2,
      4,
      1,
      2,
      0,
      0,
      3,
      0,
      2,
      3,
      1
    ]
  },
  {
    "id": "VNDR-0000184",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "PPE",
    "contactName": "Kimberly Griffin",
    "industryRating": 2.3,
    "procedures": "[PR-023,PR-061,PR-048,PR-067,PR-056,PR-049,PR-010,PR-031,PR-027,PR-073]",
    "contracts": "[CNTRCT-0000039,CNTRCT-0000064,CNTRCT-0000061]",
    "orders": "[ORDR-0-0000012,ORDR-1-0000058,ORDR-2-0000026,ORDR-3-0000021,ORDR-4-0000043,ORDR-5-0000054,ORDR-6-0000036,ORDR-7-0000009,ORDR-8-0000020,ORDR-9-0000016,ORDR-10-0000045,ORDR-11-0000060,ORDR-12-0000022,ORDR-13-0000068,ORDR-14-0000023,ORDR-15-0000050,ORDR-16-0000075,ORDR-17-0000064,ORDR-18-0000043]",
    "spendWeekly": [
      3064,
      2986,
      2942,
      2990,
      2903,
      3097,
      2972,
      2979,
      3074,
      2919,
      2993,
      2904,
      2943,
      3015,
      3091,
      2934,
      3001,
      3086,
      3010,
      3098,
      3027,
      3001,
      2994,
      2995,
      2997,
      3096,
      3079,
      2995,
      2907,
      2973,
      3080,
      2980,
      2982,
      2904,
      2992,
      3014,
      3000,
      2939,
      3033,
      2953,
      2947,
      2966,
      3018,
      2970,
      3000,
      3099,
      2988,
      3072,
      2917,
      2937,
      2917,
      2953
    ],
    "orderRejected": [
      2953,
      14,
      19,
      17,
      15,
      19,
      0,
      4,
      18,
      7,
      0,
      19,
      18,
      8,
      16,
      0,
      11,
      5,
      0,
      0,
      6,
      0,
      7,
      19,
      1,
      16,
      4,
      0,
      10,
      19,
      17,
      19,
      13,
      10,
      14,
      0,
      11,
      13,
      3,
      9,
      2,
      19,
      8,
      0,
      19,
      16,
      6,
      18,
      12,
      19,
      19,
      0,
      19
    ],
    "orderAccepted": [
      2,
      8,
      2,
      6,
      11,
      11,
      6,
      8,
      0,
      10,
      11,
      11,
      8,
      4,
      6,
      11,
      6,
      8,
      0,
      4,
      8,
      11,
      1,
      4,
      4,
      11,
      0,
      11,
      11,
      2,
      11,
      8,
      0,
      10,
      11,
      0,
      11,
      1,
      11,
      4,
      0,
      11,
      5,
      1,
      0,
      9,
      10,
      7,
      4,
      7,
      3,
      11
    ],
    "orderHold": [
      2,
      4,
      2,
      1,
      3,
      4,
      1,
      3,
      1,
      1,
      4,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      4,
      0,
      0,
      4,
      1,
      3,
      1,
      4,
      1,
      2,
      0,
      2,
      4,
      4,
      2,
      1,
      0,
      4,
      4,
      3,
      4,
      2,
      3,
      0,
      2,
      1,
      2,
      3,
      0,
      1,
      1,
      4,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      1,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      1,
      4,
      2,
      0,
      0,
      2,
      1,
      4,
      2,
      2,
      4,
      3,
      3,
      1,
      2,
      0,
      3,
      2,
      2,
      1,
      1,
      3,
      4,
      4,
      1,
      2,
      4,
      0,
      3,
      4,
      1,
      4,
      2,
      3,
      4,
      0,
      1,
      4,
      3,
      4,
      0,
      3,
      1,
      2,
      1,
      2,
      4,
      2,
      4,
      2,
      2,
      4
    ],
    "issueDelay": [
      0,
      1,
      4,
      4,
      0,
      2,
      1,
      2,
      2,
      2,
      4,
      0,
      4,
      2,
      4,
      4,
      2,
      0,
      2,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      1,
      1,
      3,
      4,
      4,
      4,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      3,
      2,
      0,
      2,
      2,
      2,
      0,
      0,
      0,
      4,
      3,
      2,
      1
    ],
    "issueQuantity": [
      0,
      2,
      3,
      0,
      2,
      4,
      4,
      3,
      4,
      2,
      2,
      2,
      3,
      1,
      2,
      3,
      4,
      4,
      0,
      2,
      2,
      0,
      2,
      1,
      3,
      3,
      1,
      0,
      4,
      4,
      2,
      0,
      3,
      3,
      0,
      4,
      4,
      0,
      0,
      4,
      2,
      1,
      4,
      1,
      4,
      0,
      2,
      0,
      0,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000185",
    "status": "active",
    "name": "PharmaLogix International",
    "tag": "IT Services",
    "contactName": "Kaitlyn Hernandez",
    "industryRating": 3.4,
    "procedures": "[PR-066,PR-046,PR-034,PR-062,PR-043,PR-066,PR-069,PR-041,PR-055,PR-059]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000036,CNTRCT-0000020]",
    "orders": "[ORDR-0-0000040,ORDR-1-0000036,ORDR-2-0000052,ORDR-3-0000053,ORDR-4-0000039,ORDR-5-0000027,ORDR-6-0000051,ORDR-7-0000027,ORDR-8-0000038,ORDR-9-0000055,ORDR-10-0000033,ORDR-11-0000014,ORDR-12-0000047,ORDR-13-0000036,ORDR-14-0000060,ORDR-15-0000066,ORDR-16-0000075,ORDR-17-0000025,ORDR-18-0000074]",
    "spendWeekly": [
      2901,
      2980,
      2993,
      2902,
      2998,
      3051,
      3100,
      3096,
      3100,
      2973,
      3031,
      3045,
      2973,
      3092,
      2956,
      2910,
      3098,
      2987,
      3006,
      3051,
      3069,
      3008,
      2900,
      2903,
      3092,
      3035,
      2944,
      2989,
      2976,
      2906,
      3090,
      3040,
      3028,
      3054,
      3030,
      2924,
      3100,
      2923,
      2906,
      3099,
      3064,
      2982,
      3097,
      2995,
      2917,
      2915,
      2961,
      2992,
      3057,
      3033,
      2905,
      2934
    ],
    "orderRejected": [
      2934,
      19,
      19,
      3,
      0,
      4,
      0,
      4,
      10,
      9,
      19,
      14,
      19,
      13,
      14,
      11,
      18,
      13,
      0,
      4,
      0,
      4,
      18,
      19,
      7,
      12,
      17,
      7,
      5,
      18,
      15,
      17,
      7,
      19,
      8,
      10,
      17,
      11,
      6,
      4,
      17,
      19,
      3,
      0,
      2,
      15,
      8,
      13,
      14,
      12,
      19,
      15,
      10
    ],
    "orderAccepted": [
      10,
      6,
      0,
      8,
      0,
      4,
      11,
      9,
      5,
      10,
      1,
      5,
      1,
      11,
      11,
      8,
      2,
      10,
      0,
      4,
      0,
      11,
      11,
      0,
      7,
      7,
      0,
      11,
      11,
      4,
      7,
      4,
      0,
      5,
      10,
      5,
      0,
      0,
      11,
      7,
      0,
      11,
      11,
      5,
      1,
      0,
      7,
      4,
      6,
      11,
      0,
      11
    ],
    "orderHold": [
      2,
      2,
      2,
      4,
      4,
      1,
      2,
      3,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      3,
      3,
      0,
      3,
      0,
      1,
      3,
      2,
      0,
      2,
      3,
      4,
      2,
      4,
      0,
      1,
      4,
      0,
      0,
      0,
      1,
      2,
      3,
      3,
      2,
      3,
      3,
      4,
      1,
      4,
      1,
      1,
      0,
      0,
      0,
      0,
      4
    ],
    "orderBackordered": [
      4,
      0,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      2,
      0,
      3,
      4,
      2,
      4,
      1,
      4,
      2,
      3,
      3,
      2,
      2,
      4,
      0,
      3,
      3,
      1,
      4,
      0,
      0,
      0,
      3,
      4,
      1,
      4,
      3,
      0,
      4,
      2,
      0,
      3,
      4,
      3,
      1,
      1,
      3,
      0,
      1,
      3,
      0,
      1,
      2,
      4,
      4,
      3,
      2,
      1,
      4,
      0,
      4,
      4
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      0,
      3,
      2,
      0,
      0,
      0,
      2,
      4,
      0,
      4,
      3,
      4,
      0,
      1,
      2,
      0,
      3,
      0,
      0,
      4,
      0,
      4,
      3,
      0,
      4,
      3,
      4,
      2,
      4,
      0,
      1,
      4,
      1,
      4,
      4,
      0,
      3,
      2,
      3,
      1,
      2,
      2,
      0,
      0,
      2,
      3,
      4,
      4
    ],
    "issueQuantity": [
      3,
      0,
      1,
      0,
      4,
      2,
      3,
      0,
      4,
      0,
      0,
      1,
      0,
      3,
      2,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      0,
      4,
      0,
      1,
      3,
      2,
      3,
      2,
      0,
      0,
      0,
      0,
      3,
      1,
      4,
      0,
      1,
      4,
      4,
      3,
      4,
      0,
      4,
      1,
      3,
      2,
      0,
      0,
      1,
      4
    ]
  },
  {
    "id": "VNDR-0000186",
    "status": "active",
    "name": "HealthSync Distributors",
    "tag": "Nutrition, Imaging, Equipment",
    "contactName": "Steven Wallace",
    "industryRating": 4.3,
    "procedures": "[PR-051,PR-065,PR-038,PR-064,PR-064,PR-036,PR-049,PR-070,PR-037,PR-027]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000059,CNTRCT-0000059]",
    "orders": "[ORDR-0-0000038,ORDR-1-0000023,ORDR-2-0000020,ORDR-3-0000014,ORDR-4-0000013,ORDR-5-0000023,ORDR-6-0000038,ORDR-7-0000068,ORDR-8-0000026,ORDR-9-0000030,ORDR-10-0000027,ORDR-11-0000020,ORDR-12-0000031,ORDR-13-0000041,ORDR-14-0000028,ORDR-15-0000033,ORDR-16-0000018,ORDR-17-0000034,ORDR-18-0000030]",
    "spendWeekly": [
      2932,
      2902,
      3036,
      3001,
      2965,
      3069,
      2977,
      2951,
      3043,
      2979,
      2938,
      3056,
      3094,
      2954,
      2949,
      2901,
      3037,
      3084,
      3005,
      2983,
      2991,
      2983,
      2900,
      2903,
      2906,
      2951,
      3017,
      2921,
      3030,
      2963,
      3003,
      2964,
      3099,
      2904,
      3033,
      2994,
      2907,
      2920,
      3061,
      2922,
      2944,
      3036,
      3097,
      3057,
      2966,
      2912,
      2936,
      3040,
      3002,
      2994,
      3039,
      3055
    ],
    "orderRejected": [
      3055,
      8,
      9,
      4,
      9,
      10,
      10,
      19,
      16,
      19,
      0,
      8,
      19,
      11,
      6,
      14,
      14,
      0,
      7,
      19,
      13,
      19,
      12,
      12,
      7,
      12,
      0,
      7,
      12,
      17,
      2,
      2,
      17,
      19,
      2,
      19,
      5,
      6,
      4,
      1,
      6,
      3,
      15,
      6,
      7,
      14,
      14,
      11,
      13,
      18,
      6,
      19,
      19
    ],
    "orderAccepted": [
      5,
      10,
      2,
      9,
      0,
      4,
      5,
      6,
      5,
      4,
      2,
      4,
      3,
      4,
      11,
      8,
      0,
      7,
      10,
      6,
      5,
      10,
      2,
      0,
      7,
      4,
      0,
      8,
      0,
      6,
      11,
      11,
      0,
      4,
      0,
      10,
      4,
      7,
      9,
      3,
      3,
      11,
      9,
      1,
      6,
      11,
      0,
      3,
      0,
      8,
      4,
      5
    ],
    "orderHold": [
      2,
      1,
      3,
      1,
      4,
      2,
      2,
      1,
      3,
      2,
      2,
      3,
      0,
      4,
      1,
      0,
      2,
      3,
      1,
      0,
      2,
      3,
      0,
      1,
      0,
      3,
      2,
      2,
      3,
      2,
      2,
      3,
      3,
      2,
      1,
      2,
      4,
      4,
      2,
      1,
      1,
      4,
      1,
      4,
      2,
      3,
      3,
      0,
      0,
      0,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      0,
      2,
      0,
      2,
      2,
      2,
      2,
      1,
      1,
      0,
      0,
      0,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      2,
      0,
      0,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0
    ],
    "issueQuality": [
      2,
      0,
      4,
      3,
      1,
      4,
      4,
      1,
      1,
      3,
      1,
      2,
      3,
      2,
      0,
      1,
      1,
      0,
      2,
      4,
      0,
      0,
      0,
      1,
      2,
      1,
      2,
      3,
      2,
      2,
      2,
      1,
      4,
      4,
      0,
      0,
      1,
      3,
      1,
      4,
      4,
      3,
      1,
      0,
      1,
      2,
      3,
      3,
      4,
      4,
      0,
      0
    ],
    "issueDelay": [
      2,
      2,
      3,
      2,
      0,
      3,
      4,
      3,
      2,
      3,
      1,
      1,
      1,
      0,
      4,
      1,
      2,
      1,
      3,
      1,
      1,
      3,
      1,
      0,
      0,
      2,
      2,
      2,
      1,
      4,
      2,
      4,
      4,
      3,
      4,
      1,
      0,
      4,
      1,
      4,
      1,
      0,
      2,
      1,
      4,
      0,
      0,
      1,
      3,
      1,
      0,
      3
    ],
    "issueQuantity": [
      3,
      2,
      1,
      0,
      1,
      1,
      3,
      1,
      0,
      1,
      3,
      0,
      0,
      0,
      3,
      4,
      3,
      4,
      3,
      4,
      0,
      4,
      0,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      3,
      2,
      1,
      1,
      1,
      3,
      4,
      1,
      0,
      0,
      1,
      2,
      1,
      0,
      3,
      4,
      2,
      3,
      1,
      2,
      3,
      3
    ]
  },
  {
    "id": "VNDR-0000187",
    "status": "active",
    "name": "MedPulse Supply Network",
    "tag": "Equipment",
    "contactName": "Jake Goodwin",
    "industryRating": 2.6,
    "procedures": "[PR-055,PR-038,PR-049,PR-009,PR-056,PR-049,PR-045,PR-062,PR-072,PR-074]",
    "contracts": "[CNTRCT-0000059,CNTRCT-0000044,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000057,ORDR-1-0000001,ORDR-2-0000049,ORDR-3-0000065,ORDR-4-0000006,ORDR-5-0000035,ORDR-6-0000044,ORDR-7-0000071,ORDR-8-0000011,ORDR-9-0000014,ORDR-10-0000056,ORDR-11-0000045,ORDR-12-0000014,ORDR-13-0000044,ORDR-14-0000048,ORDR-15-0000064,ORDR-16-0000048,ORDR-17-0000076,ORDR-18-0000025]",
    "spendWeekly": [
      2901,
      2993,
      2945,
      2902,
      2903,
      3097,
      3100,
      3090,
      2988,
      2980,
      3025,
      2940,
      2999,
      2901,
      3091,
      3064,
      2903,
      3099,
      2900,
      2918,
      3081,
      3097,
      2900,
      3004,
      3095,
      2995,
      2909,
      2969,
      3052,
      3019,
      2903,
      2982,
      3020,
      2987,
      3030,
      2969,
      2921,
      2902,
      2944,
      3036,
      2917,
      2900,
      3004,
      3091,
      2931,
      2985,
      2946,
      2900,
      2969,
      2937,
      2993,
      2968
    ],
    "orderRejected": [
      2968,
      19,
      16,
      3,
      8,
      15,
      9,
      9,
      8,
      19,
      0,
      19,
      12,
      18,
      19,
      12,
      19,
      2,
      0,
      19,
      17,
      14,
      0,
      17,
      0,
      19,
      0,
      0,
      19,
      19,
      19,
      19,
      16,
      19,
      8,
      15,
      0,
      2,
      6,
      19,
      16,
      19,
      5,
      0,
      10,
      5,
      0,
      18,
      12,
      19,
      4,
      17,
      13
    ],
    "orderAccepted": [
      4,
      9,
      0,
      9,
      1,
      2,
      11,
      3,
      3,
      7,
      6,
      7,
      8,
      6,
      11,
      11,
      0,
      8,
      5,
      2,
      0,
      0,
      10,
      4,
      8,
      10,
      0,
      8,
      5,
      4,
      9,
      10,
      0,
      3,
      0,
      11,
      9,
      0,
      11,
      9,
      8,
      4,
      11,
      0,
      11,
      8,
      3,
      7,
      8,
      10,
      4,
      11
    ],
    "orderHold": [
      2,
      1,
      4,
      2,
      0,
      4,
      4,
      1,
      0,
      2,
      3,
      0,
      2,
      3,
      0,
      1,
      3,
      1,
      2,
      1,
      0,
      1,
      2,
      4,
      0,
      3,
      3,
      0,
      4,
      1,
      3,
      4,
      4,
      2,
      0,
      4,
      2,
      0,
      0,
      4,
      0,
      1,
      4,
      2,
      2,
      4,
      4,
      1,
      0,
      4,
      2,
      0
    ],
    "orderBackordered": [
      0,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      3,
      0,
      2,
      3,
      0,
      3,
      0,
      4,
      0,
      3,
      1,
      1,
      1,
      2,
      3,
      0,
      0,
      0,
      2,
      0,
      0,
      0,
      4,
      2,
      4,
      4,
      4,
      2,
      4,
      0,
      2,
      1,
      4,
      0,
      0,
      4,
      1,
      2,
      4,
      4,
      2,
      3,
      1,
      3,
      4,
      4,
      3,
      4,
      4,
      2,
      0,
      4
    ],
    "issueDelay": [
      1,
      2,
      2,
      1,
      2,
      4,
      4,
      3,
      1,
      4,
      0,
      4,
      2,
      4,
      4,
      4,
      2,
      2,
      1,
      2,
      1,
      3,
      1,
      2,
      0,
      4,
      1,
      0,
      2,
      4,
      4,
      4,
      2,
      2,
      0,
      1,
      0,
      3,
      4,
      4,
      2,
      0,
      4,
      0,
      4,
      0,
      0,
      0,
      3,
      1,
      0,
      4
    ],
    "issueQuantity": [
      3,
      1,
      0,
      1,
      2,
      0,
      4,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      4,
      3,
      0,
      3,
      2,
      1,
      1,
      2,
      0,
      4,
      2,
      1,
      3,
      3,
      0,
      0,
      4,
      4,
      1,
      4,
      4,
      0,
      0,
      4,
      4,
      4,
      3,
      1,
      3,
      4,
      4,
      0,
      0,
      4,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000188",
    "status": "active",
    "name": "HealthWave Technologies",
    "tag": "Equipment",
    "contactName": "Jennifer Harris",
    "industryRating": 3,
    "procedures": "[PR-023,PR-042,PR-061,PR-069,PR-060,PR-044,PR-010,PR-051,PR-056,PR-072]",
    "contracts": "[CNTRCT-0000033,CNTRCT-0000030,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000025,ORDR-1-0000025,ORDR-2-0000036,ORDR-3-0000020,ORDR-4-0000045,ORDR-5-0000048,ORDR-6-0000016,ORDR-7-0000061,ORDR-8-0000037,ORDR-9-0000048,ORDR-10-0000034,ORDR-11-0000068,ORDR-12-0000070,ORDR-13-0000037,ORDR-14-0000040,ORDR-15-0000025,ORDR-16-0000015,ORDR-17-0000060,ORDR-18-0000033]",
    "spendWeekly": [
      3020,
      3075,
      2906,
      2930,
      2903,
      3033,
      3100,
      3012,
      2997,
      3060,
      3100,
      3009,
      2985,
      3023,
      3034,
      2935,
      3045,
      2900,
      2909,
      3030,
      3100,
      3064,
      2918,
      2964,
      2936,
      2965,
      3012,
      3061,
      2935,
      2980,
      3065,
      3023,
      2900,
      2996,
      3034,
      3100,
      2927,
      2976,
      2983,
      2955,
      2978,
      3035,
      3033,
      3016,
      3035,
      3035,
      2964,
      2974,
      3001,
      3093,
      2970,
      3097
    ],
    "orderRejected": [
      3097,
      17,
      6,
      11,
      2,
      11,
      12,
      3,
      10,
      1,
      12,
      1,
      12,
      10,
      12,
      18,
      8,
      6,
      17,
      18,
      3,
      18,
      0,
      9,
      16,
      18,
      6,
      18,
      16,
      9,
      13,
      18,
      19,
      9,
      8,
      8,
      4,
      16,
      12,
      17,
      3,
      15,
      10,
      12,
      3,
      5,
      3,
      9,
      14,
      18,
      14,
      14,
      15
    ],
    "orderAccepted": [
      10,
      3,
      11,
      10,
      0,
      3,
      9,
      2,
      0,
      7,
      6,
      6,
      5,
      9,
      7,
      11,
      0,
      11,
      7,
      11,
      11,
      0,
      11,
      11,
      7,
      5,
      1,
      1,
      2,
      8,
      9,
      4,
      2,
      2,
      1,
      2,
      7,
      1,
      8,
      8,
      2,
      5,
      7,
      4,
      7,
      10,
      0,
      3,
      11,
      7,
      2,
      10
    ],
    "orderHold": [
      2,
      0,
      0,
      0,
      3,
      2,
      1,
      4,
      2,
      3,
      3,
      2,
      3,
      0,
      0,
      2,
      3,
      0,
      0,
      4,
      3,
      4,
      2,
      3,
      1,
      4,
      1,
      4,
      4,
      1,
      0,
      4,
      0,
      4,
      4,
      1,
      1,
      2,
      2,
      2,
      3,
      0,
      3,
      0,
      3,
      4,
      3,
      4,
      1,
      1,
      3,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      0,
      0,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      2,
      3,
      3,
      2,
      0,
      2,
      1,
      2,
      4,
      3,
      2,
      4,
      4,
      3,
      1,
      3,
      3,
      0,
      3,
      4,
      3,
      1,
      3,
      2,
      2,
      3,
      2,
      0,
      3,
      4,
      2,
      0,
      0,
      0,
      4,
      4,
      2,
      0,
      3,
      1,
      0,
      1,
      4,
      3,
      0,
      0,
      4,
      4,
      3,
      2,
      2,
      3
    ],
    "issueDelay": [
      2,
      2,
      4,
      3,
      3,
      4,
      1,
      0,
      3,
      1,
      2,
      3,
      4,
      2,
      0,
      1,
      4,
      0,
      1,
      3,
      4,
      1,
      0,
      2,
      2,
      0,
      0,
      4,
      0,
      1,
      1,
      4,
      1,
      3,
      2,
      1,
      2,
      4,
      0,
      2,
      1,
      0,
      3,
      1,
      3,
      0,
      0,
      0,
      4,
      0,
      3,
      0
    ],
    "issueQuantity": [
      2,
      4,
      0,
      3,
      4,
      0,
      4,
      0,
      2,
      2,
      1,
      0,
      4,
      2,
      3,
      2,
      3,
      1,
      2,
      3,
      2,
      2,
      0,
      2,
      3,
      0,
      2,
      4,
      3,
      1,
      3,
      1,
      3,
      0,
      0,
      4,
      4,
      2,
      4,
      2,
      1,
      2,
      3,
      1,
      3,
      0,
      2,
      0,
      4,
      4,
      0,
      3
    ]
  },
  {
    "id": "VNDR-0000189",
    "status": "active",
    "name": "MediFlow Systems",
    "tag": "Medical Supplies, Facilities Management, Imaging",
    "contactName": "Jennifer Carey",
    "industryRating": 2.1,
    "procedures": "[PR-053,PR-061,PR-067,PR-032,PR-064,PR-049,PR-053,PR-051,PR-038,PR-069]",
    "contracts": "[CNTRCT-0000044,CNTRCT-0000030,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000045,ORDR-1-0000055,ORDR-2-0000058,ORDR-3-0000058,ORDR-4-0000001,ORDR-5-0000013,ORDR-6-0000030,ORDR-7-0000039,ORDR-8-0000040,ORDR-9-0000019,ORDR-10-0000017,ORDR-11-0000074,ORDR-12-0000033,ORDR-13-0000024,ORDR-14-0000058,ORDR-15-0000014,ORDR-16-0000022,ORDR-17-0000056,ORDR-18-0000021]",
    "spendWeekly": [
      2943,
      2934,
      3049,
      3033,
      2903,
      3047,
      2973,
      2957,
      2980,
      2977,
      2975,
      2922,
      2917,
      2996,
      3036,
      2941,
      2903,
      3082,
      2970,
      3098,
      3079,
      3095,
      3085,
      2903,
      2981,
      3029,
      3095,
      3008,
      2959,
      3045,
      3001,
      3097,
      3065,
      3024,
      3050,
      2947,
      3070,
      2906,
      3045,
      3086,
      3010,
      2901,
      3089,
      3062,
      2986,
      2922,
      3012,
      2920,
      3012,
      2998,
      2944,
      3084
    ],
    "orderRejected": [
      3084,
      5,
      10,
      4,
      0,
      12,
      17,
      9,
      19,
      15,
      10,
      7,
      17,
      9,
      7,
      8,
      3,
      18,
      16,
      6,
      4,
      19,
      0,
      14,
      0,
      14,
      0,
      0,
      3,
      14,
      19,
      19,
      19,
      19,
      6,
      16,
      3,
      4,
      11,
      4,
      16,
      15,
      17,
      0,
      4,
      11,
      0,
      14,
      0,
      19,
      8,
      19,
      14
    ],
    "orderAccepted": [
      7,
      10,
      5,
      7,
      9,
      11,
      9,
      0,
      0,
      2,
      0,
      11,
      10,
      9,
      11,
      3,
      7,
      6,
      7,
      3,
      6,
      0,
      3,
      3,
      7,
      10,
      0,
      11,
      0,
      0,
      6,
      0,
      0,
      0,
      0,
      11,
      1,
      7,
      9,
      9,
      0,
      5,
      11,
      3,
      8,
      7,
      4,
      7,
      0,
      6,
      6,
      7
    ],
    "orderHold": [
      0,
      4,
      3,
      3,
      3,
      1,
      1,
      0,
      2,
      3,
      0,
      0,
      1,
      4,
      1,
      4,
      3,
      0,
      4,
      4,
      2,
      1,
      2,
      4,
      1,
      1,
      4,
      3,
      2,
      2,
      2,
      2,
      1,
      3,
      2,
      2,
      2,
      0,
      2,
      0,
      0,
      3,
      1,
      4,
      2,
      0,
      1,
      2,
      0,
      0,
      0,
      2
    ],
    "orderBackordered": [
      2,
      1,
      2,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0
    ],
    "issueQuality": [
      3,
      4,
      3,
      0,
      3,
      2,
      2,
      1,
      1,
      3,
      0,
      2,
      4,
      0,
      1,
      3,
      1,
      3,
      2,
      4,
      4,
      4,
      1,
      1,
      4,
      0,
      2,
      2,
      4,
      2,
      3,
      2,
      4,
      3,
      3,
      3,
      2,
      0,
      0,
      1,
      4,
      0,
      2,
      1,
      4,
      4,
      3,
      2,
      1,
      4,
      0,
      4
    ],
    "issueDelay": [
      3,
      4,
      4,
      3,
      1,
      2,
      1,
      1,
      0,
      1,
      3,
      1,
      4,
      1,
      3,
      3,
      3,
      2,
      1,
      0,
      4,
      3,
      0,
      4,
      1,
      4,
      4,
      0,
      0,
      2,
      3,
      4,
      1,
      3,
      2,
      0,
      0,
      4,
      2,
      1,
      2,
      3,
      0,
      4,
      3,
      3,
      0,
      0,
      4,
      1,
      3,
      1
    ],
    "issueQuantity": [
      1,
      3,
      1,
      0,
      3,
      1,
      3,
      0,
      0,
      2,
      2,
      1,
      3,
      2,
      0,
      4,
      2,
      1,
      0,
      4,
      1,
      4,
      0,
      4,
      1,
      4,
      3,
      4,
      2,
      1,
      2,
      4,
      2,
      2,
      2,
      4,
      4,
      1,
      1,
      4,
      4,
      1,
      4,
      3,
      0,
      1,
      1,
      3,
      0,
      3,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000190",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Equipment",
    "contactName": "Jake Goodwin",
    "industryRating": 3.7,
    "procedures": "[PR-023,PR-049,PR-056,PR-059,PR-046,PR-065,PR-047,PR-065,PR-069,PR-068]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000048,CNTRCT-0000046]",
    "orders": "[ORDR-0-0000011,ORDR-1-0000059,ORDR-2-0000061,ORDR-3-0000066,ORDR-4-0000020,ORDR-5-0000009,ORDR-6-0000062,ORDR-7-0000070,ORDR-8-0000066,ORDR-9-0000054,ORDR-10-0000056,ORDR-11-0000010,ORDR-12-0000070,ORDR-13-0000069,ORDR-14-0000070,ORDR-15-0000030,ORDR-16-0000038,ORDR-17-0000049,ORDR-18-0000046]",
    "spendWeekly": [
      2992,
      3082,
      3043,
      3068,
      2994,
      3067,
      2905,
      2925,
      3010,
      2940,
      2901,
      3054,
      2973,
      2985,
      3091,
      2901,
      2992,
      3065,
      2900,
      3012,
      3100,
      3017,
      2980,
      3024,
      3091,
      3096,
      3007,
      2901,
      2966,
      2940,
      3019,
      2919,
      3061,
      2972,
      2932,
      2993,
      2916,
      3029,
      3021,
      2989,
      2901,
      3000,
      3052,
      3015,
      3054,
      2901,
      3018,
      3017,
      2913,
      2933,
      2913,
      3013
    ],
    "orderRejected": [
      3013,
      11,
      4,
      0,
      2,
      13,
      3,
      17,
      4,
      18,
      0,
      1,
      18,
      9,
      4,
      5,
      19,
      12,
      0,
      9,
      8,
      6,
      11,
      17,
      5,
      17,
      5,
      0,
      19,
      18,
      0,
      17,
      19,
      16,
      12,
      12,
      11,
      3,
      14,
      6,
      19,
      19,
      5,
      9,
      3,
      8,
      12,
      4,
      10,
      17,
      14,
      19,
      19
    ],
    "orderAccepted": [
      9,
      11,
      1,
      0,
      7,
      11,
      6,
      5,
      8,
      6,
      6,
      11,
      10,
      0,
      11,
      11,
      0,
      8,
      8,
      7,
      3,
      9,
      10,
      5,
      4,
      7,
      0,
      3,
      5,
      7,
      3,
      9,
      1,
      8,
      6,
      7,
      10,
      1,
      9,
      4,
      11,
      11,
      11,
      0,
      0,
      2,
      4,
      11,
      0,
      2,
      3,
      9
    ],
    "orderHold": [
      1,
      4,
      2,
      2,
      4,
      3,
      2,
      2,
      1,
      4,
      0,
      0,
      0,
      4,
      1,
      4,
      2,
      0,
      0,
      2,
      2,
      4,
      2,
      2,
      0,
      1,
      0,
      1,
      3,
      1,
      2,
      4,
      4,
      2,
      4,
      3,
      3,
      1,
      2,
      3,
      1,
      0,
      4,
      4,
      4,
      3,
      0,
      1,
      0,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      0,
      0,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      2,
      0,
      0,
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      0,
      0,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      1,
      0,
      1,
      4,
      1,
      1,
      2,
      4,
      2,
      2,
      1,
      2,
      4,
      1,
      1,
      1,
      4,
      4,
      2,
      2,
      0,
      0,
      2,
      4,
      3,
      3,
      4,
      0,
      1,
      4,
      1,
      4,
      4,
      3,
      0,
      1,
      2,
      0,
      2,
      1,
      3,
      3,
      2,
      4,
      2,
      4,
      3,
      2,
      3,
      4,
      0,
      4
    ],
    "issueDelay": [
      2,
      1,
      4,
      4,
      2,
      3,
      4,
      3,
      0,
      1,
      2,
      3,
      3,
      4,
      0,
      2,
      1,
      0,
      4,
      0,
      1,
      2,
      0,
      0,
      4,
      0,
      2,
      4,
      2,
      2,
      2,
      2,
      4,
      3,
      3,
      0,
      0,
      4,
      2,
      1,
      4,
      1,
      0,
      0,
      1,
      0,
      3,
      0,
      4,
      0,
      2,
      4
    ],
    "issueQuantity": [
      1,
      3,
      0,
      1,
      3,
      3,
      1,
      0,
      4,
      2,
      2,
      2,
      1,
      2,
      3,
      2,
      4,
      1,
      0,
      1,
      1,
      0,
      0,
      3,
      0,
      3,
      0,
      2,
      2,
      3,
      2,
      3,
      1,
      2,
      3,
      3,
      4,
      0,
      3,
      1,
      4,
      3,
      4,
      0,
      4,
      0,
      4,
      4,
      2,
      0,
      2,
      0
    ]
  },
  {
    "id": "VNDR-0000191",
    "status": "active",
    "name": "VitalCare Distribution",
    "tag": "PPE, Medical Supplies, Facilities Management",
    "contactName": "Steven Wallace",
    "industryRating": 3.3,
    "procedures": "[PR-036,PR-064,PR-042,PR-044,PR-056,PR-066,PR-053,PR-036,PR-057,PR-075]",
    "contracts": "[CNTRCT-0000061,CNTRCT-0000062,CNTRCT-0000066]",
    "orders": "[ORDR-0-0000018,ORDR-1-0000025,ORDR-2-0000020,ORDR-3-0000010,ORDR-4-0000037,ORDR-5-0000023,ORDR-6-0000016,ORDR-7-0000019,ORDR-8-0000010,ORDR-9-0000016,ORDR-10-0000026,ORDR-11-0000071,ORDR-12-0000062,ORDR-13-0000017,ORDR-14-0000055,ORDR-15-0000036,ORDR-16-0000067,ORDR-17-0000025,ORDR-18-0000065]",
    "spendWeekly": [
      3097,
      3030,
      3023,
      3041,
      2903,
      2905,
      2905,
      2903,
      2919,
      2919,
      3049,
      2994,
      3010,
      2929,
      3091,
      2976,
      2945,
      2945,
      2900,
      3098,
      3100,
      3003,
      2967,
      2981,
      2982,
      2901,
      2995,
      2969,
      3057,
      2974,
      2954,
      3039,
      3006,
      2960,
      3030,
      3100,
      3010,
      3076,
      2916,
      2901,
      3038,
      3030,
      2951,
      3000,
      2968,
      2901,
      2908,
      3042,
      3071,
      2914,
      2922,
      3057
    ],
    "orderRejected": [
      3057,
      8,
      0,
      14,
      12,
      13,
      0,
      9,
      18,
      19,
      19,
      19,
      13,
      4,
      12,
      11,
      16,
      11,
      6,
      0,
      9,
      9,
      9,
      9,
      16,
      17,
      9,
      8,
      9,
      15,
      19,
      17,
      19,
      13,
      19,
      18,
      3,
      13,
      11,
      0,
      3,
      13,
      11,
      0,
      0,
      8,
      0,
      18,
      19,
      0,
      8,
      7,
      19
    ],
    "orderAccepted": [
      3,
      8,
      5,
      10,
      4,
      10,
      3,
      4,
      0,
      0,
      4,
      1,
      8,
      5,
      10,
      11,
      10,
      9,
      1,
      6,
      4,
      10,
      5,
      0,
      3,
      8,
      0,
      9,
      6,
      11,
      9,
      6,
      2,
      0,
      0,
      11,
      3,
      6,
      11,
      1,
      0,
      11,
      4,
      1,
      0,
      11,
      7,
      2,
      0,
      4,
      2,
      11
    ],
    "orderHold": [
      0,
      0,
      1,
      0,
      4,
      4,
      3,
      3,
      0,
      2,
      0,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      3,
      1,
      1,
      4,
      4,
      1,
      0,
      1,
      0,
      3,
      1,
      2,
      2,
      3,
      2,
      0,
      0,
      3,
      1,
      3,
      3,
      1,
      3,
      3,
      1,
      3,
      2,
      3,
      2,
      3,
      1,
      1,
      2,
      4
    ],
    "orderBackordered": [
      4,
      2,
      0,
      1,
      2,
      0,
      0,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0
    ],
    "issueQuality": [
      4,
      1,
      3,
      4,
      1,
      2,
      3,
      1,
      0,
      4,
      2,
      1,
      2,
      1,
      2,
      4,
      1,
      4,
      2,
      3,
      3,
      1,
      3,
      1,
      1,
      0,
      3,
      2,
      4,
      0,
      0,
      1,
      1,
      3,
      1,
      4,
      3,
      3,
      0,
      4,
      4,
      1,
      4,
      3,
      1,
      2,
      0,
      4,
      2,
      2,
      0,
      4
    ],
    "issueDelay": [
      1,
      2,
      4,
      4,
      3,
      2,
      4,
      2,
      2,
      2,
      4,
      2,
      4,
      3,
      3,
      3,
      2,
      0,
      0,
      4,
      1,
      4,
      0,
      2,
      0,
      4,
      4,
      1,
      1,
      4,
      2,
      4,
      4,
      0,
      3,
      4,
      0,
      1,
      4,
      3,
      1,
      4,
      4,
      3,
      4,
      0,
      0,
      2,
      3,
      2,
      4,
      3
    ],
    "issueQuantity": [
      1,
      2,
      0,
      0,
      2,
      1,
      4,
      4,
      3,
      3,
      2,
      0,
      0,
      2,
      4,
      4,
      2,
      2,
      3,
      1,
      4,
      2,
      3,
      0,
      0,
      1,
      2,
      4,
      1,
      4,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      3,
      4,
      3,
      2,
      4,
      1,
      3,
      0,
      1,
      3,
      4,
      1,
      2,
      1
    ]
  },
  {
    "id": "VNDR-0000192",
    "status": "inactive",
    "name": "MedixSupply Chain",
    "tag": "Nutrition, Imaging, Equipment",
    "contactName": "Taylor Gutierrez",
    "industryRating": 2.1,
    "procedures": "[PR-062,PR-046,PR-029,PR-066,PR-009,PR-070,PR-056,PR-055,PR-035,PR-048]",
    "contracts": "[CNTRCT-0000054,CNTRCT-0000051,CNTRCT-0000060]",
    "orders": "[ORDR-0-0000053,ORDR-1-0000025,ORDR-2-0000051,ORDR-3-0000036,ORDR-4-0000009,ORDR-5-0000061,ORDR-6-0000019,ORDR-7-0000023,ORDR-8-0000029,ORDR-9-0000069,ORDR-10-0000057,ORDR-11-0000041,ORDR-12-0000075,ORDR-13-0000019,ORDR-14-0000036,ORDR-15-0000020,ORDR-16-0000023,ORDR-17-0000072,ORDR-18-0000047]",
    "spendWeekly": [
      3054,
      2988,
      2906,
      2956,
      2944,
      2932,
      3100,
      3098,
      3059,
      3057,
      3067,
      2990,
      3100,
      2901,
      2977,
      2923,
      2970,
      3099,
      3059,
      3021,
      2963,
      2987,
      3079,
      2903,
      2969,
      3090,
      3002,
      3081,
      2953,
      3002,
      2972,
      2911,
      3081,
      2904,
      3010,
      3046,
      2959,
      2902,
      2906,
      2994,
      2901,
      2986,
      2989,
      3091,
      3053,
      3001,
      2920,
      2900,
      2901,
      2905,
      2905,
      2901
    ],
    "orderRejected": [
      2901,
      19,
      12,
      6,
      3,
      19,
      0,
      7,
      9,
      12,
      13,
      15,
      17,
      7,
      16,
      1,
      18,
      3,
      14,
      9,
      2,
      3,
      8,
      4,
      19,
      19,
      0,
      11,
      18,
      16,
      11,
      19,
      15,
      19,
      8,
      0,
      9,
      17,
      19,
      10,
      12,
      9,
      2,
      1,
      2,
      10,
      6,
      3,
      19,
      11,
      9,
      3,
      0
    ],
    "orderAccepted": [
      11,
      11,
      2,
      7,
      6,
      8,
      9,
      4,
      0,
      0,
      9,
      2,
      4,
      9,
      10,
      2,
      6,
      8,
      8,
      4,
      7,
      0,
      2,
      6,
      4,
      4,
      0,
      9,
      8,
      0,
      2,
      11,
      1,
      3,
      8,
      7,
      5,
      7,
      4,
      0,
      0,
      10,
      3,
      4,
      0,
      6,
      0,
      8,
      2,
      5,
      4,
      5
    ],
    "orderHold": [
      2,
      0,
      3,
      1,
      4,
      1,
      1,
      3,
      0,
      2,
      0,
      0,
      0,
      3,
      0,
      4,
      3,
      2,
      3,
      4,
      2,
      3,
      2,
      4,
      0,
      2,
      4,
      2,
      4,
      0,
      1,
      2,
      1,
      0,
      0,
      2,
      4,
      4,
      4,
      4,
      2,
      2,
      1,
      0,
      0,
      1,
      3,
      4,
      0,
      1,
      1,
      4
    ],
    "orderBackordered": [
      4,
      1,
      0,
      2,
      0,
      0,
      0,
      0,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      2,
      1,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1
    ],
    "issueQuality": [
      4,
      0,
      3,
      3,
      3,
      0,
      1,
      4,
      2,
      2,
      4,
      0,
      4,
      2,
      0,
      3,
      2,
      3,
      3,
      3,
      1,
      0,
      4,
      3,
      3,
      4,
      0,
      2,
      4,
      1,
      3,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      2,
      4,
      3,
      2,
      1,
      2,
      2,
      3,
      1,
      4
    ],
    "issueDelay": [
      2,
      1,
      0,
      2,
      2,
      2,
      3,
      0,
      2,
      4,
      3,
      1,
      4,
      1,
      2,
      0,
      0,
      0,
      0,
      4,
      0,
      0,
      0,
      2,
      4,
      1,
      1,
      1,
      4,
      3,
      4,
      4,
      4,
      2,
      4,
      2,
      1,
      0,
      4,
      4,
      0,
      1,
      4,
      0,
      4,
      1,
      4,
      3,
      4,
      2,
      4,
      3
    ],
    "issueQuantity": [
      2,
      0,
      2,
      1,
      0,
      3,
      0,
      2,
      2,
      3,
      2,
      2,
      0,
      2,
      4,
      0,
      0,
      3,
      3,
      4,
      0,
      3,
      2,
      1,
      4,
      4,
      2,
      0,
      4,
      3,
      4,
      0,
      4,
      3,
      2,
      3,
      1,
      0,
      0,
      3,
      4,
      2,
      3,
      0,
      2,
      0,
      3,
      3,
      0,
      4,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000193",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "Nutrition, Imaging, Laboratory Supplies",
    "contactName": "Bryan Lang",
    "industryRating": 4.6,
    "procedures": "[PR-066,PR-044,PR-043,PR-060,PR-045,PR-061,PR-060,PR-045,PR-071,PR-070]",
    "contracts": "[CNTRCT-0000034,CNTRCT-0000057,CNTRCT-0000044]",
    "orders": "[ORDR-0-0000033,ORDR-1-0000020,ORDR-2-0000048,ORDR-3-0000039,ORDR-4-0000037,ORDR-5-0000027,ORDR-6-0000054,ORDR-7-0000064,ORDR-8-0000042,ORDR-9-0000036,ORDR-10-0000025,ORDR-11-0000060,ORDR-12-0000057,ORDR-13-0000069,ORDR-14-0000040,ORDR-15-0000078,ORDR-16-0000039,ORDR-17-0000045,ORDR-18-0000039]",
    "spendWeekly": [
      3072,
      2932,
      2947,
      2986,
      3029,
      2938,
      3052,
      2970,
      3077,
      2947,
      3100,
      2989,
      3100,
      3027,
      3083,
      2971,
      2903,
      3099,
      2944,
      3015,
      3095,
      3097,
      3034,
      2985,
      2981,
      2995,
      3050,
      3014,
      3042,
      2969,
      3016,
      2916,
      2921,
      2990,
      3084,
      2968,
      2907,
      2907,
      2974,
      2944,
      3069,
      3011,
      3097,
      3091,
      3007,
      2901,
      2999,
      2931,
      3018,
      3090,
      2905,
      2976
    ],
    "orderRejected": [
      2976,
      12,
      3,
      13,
      0,
      11,
      2,
      4,
      8,
      10,
      18,
      12,
      12,
      10,
      9,
      19,
      10,
      16,
      7,
      4,
      7,
      10,
      3,
      16,
      11,
      19,
      0,
      15,
      11,
      18,
      19,
      19,
      13,
      19,
      18,
      18,
      0,
      6,
      16,
      13,
      19,
      6,
      2,
      18,
      0,
      0,
      9,
      9,
      17,
      8,
      13,
      19,
      7
    ],
    "orderAccepted": [
      11,
      10,
      1,
      10,
      0,
      1,
      6,
      4,
      3,
      4,
      1,
      3,
      0,
      11,
      5,
      0,
      0,
      7,
      0,
      4,
      7,
      5,
      2,
      0,
      10,
      2,
      0,
      7,
      7,
      6,
      8,
      10,
      1,
      3,
      0,
      11,
      4,
      6,
      10,
      6,
      0,
      11,
      11,
      3,
      0,
      11,
      1,
      4,
      11,
      4,
      0,
      11
    ],
    "orderHold": [
      2,
      2,
      4,
      4,
      3,
      4,
      3,
      4,
      1,
      4,
      0,
      2,
      0,
      1,
      0,
      2,
      4,
      3,
      0,
      3,
      4,
      0,
      2,
      4,
      2,
      4,
      2,
      3,
      2,
      4,
      1,
      1,
      4,
      0,
      2,
      3,
      1,
      4,
      0,
      4,
      2,
      0,
      4,
      2,
      2,
      2,
      4,
      1,
      0,
      0,
      2,
      4
    ],
    "orderBackordered": [
      4,
      2,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      0,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      0,
      3,
      4,
      0,
      3,
      0,
      4,
      2,
      2,
      0,
      0,
      2,
      0,
      1,
      3,
      1,
      3,
      2,
      3,
      1,
      0,
      4,
      4,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      4,
      0,
      1,
      0,
      4,
      0,
      3,
      0,
      4,
      1,
      1,
      2,
      4,
      4,
      0,
      4,
      2,
      3,
      0,
      1,
      0
    ],
    "issueDelay": [
      3,
      4,
      0,
      1,
      0,
      4,
      3,
      2,
      1,
      2,
      2,
      0,
      2,
      3,
      4,
      2,
      4,
      0,
      3,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      3,
      3,
      2,
      2,
      2,
      1,
      4,
      4,
      1,
      3,
      4,
      1,
      1,
      0,
      1,
      3,
      2,
      2,
      0,
      4,
      4,
      4,
      0,
      2
    ],
    "issueQuantity": [
      4,
      3,
      1,
      1,
      1,
      3,
      2,
      1,
      3,
      2,
      4,
      0,
      1,
      3,
      3,
      4,
      1,
      4,
      0,
      3,
      4,
      3,
      3,
      2,
      2,
      0,
      0,
      2,
      4,
      2,
      1,
      3,
      3,
      3,
      1,
      4,
      4,
      2,
      0,
      2,
      3,
      4,
      1,
      0,
      3,
      4,
      4,
      2,
      3,
      1,
      4,
      4
    ]
  },
  {
    "id": "VNDR-0000194",
    "status": "inactive",
    "name": "VitalCare Distribution",
    "tag": "Equipment, Pharmaceuticals",
    "contactName": "Stacey Ford",
    "industryRating": 2.2,
    "procedures": "[PR-016,PR-046,PR-061,PR-056,PR-033,PR-050,PR-030,PR-062,PR-074,PR-074]",
    "contracts": "[CNTRCT-0000063,CNTRCT-0000062,CNTRCT-0000067]",
    "orders": "[ORDR-0-0000011,ORDR-1-0000020,ORDR-2-0000006,ORDR-3-0000027,ORDR-4-0000014,ORDR-5-0000060,ORDR-6-0000018,ORDR-7-0000066,ORDR-8-0000007,ORDR-9-0000028,ORDR-10-0000066,ORDR-11-0000025,ORDR-12-0000075,ORDR-13-0000049,ORDR-14-0000049,ORDR-15-0000048,ORDR-16-0000030,ORDR-17-0000047,ORDR-18-0000039]",
    "spendWeekly": [
      3021,
      3011,
      2906,
      2954,
      2947,
      2905,
      3100,
      2995,
      2902,
      2902,
      3100,
      2904,
      2986,
      3040,
      2903,
      2901,
      3098,
      3052,
      2926,
      3098,
      3011,
      3045,
      3004,
      2903,
      3067,
      3057,
      3022,
      3090,
      2974,
      2985,
      2903,
      2993,
      2968,
      2945,
      3046,
      3021,
      2945,
      3012,
      3035,
      2901,
      3041,
      2900,
      2964,
      3091,
      2902,
      2913,
      2992,
      2900,
      3100,
      2998,
      2959,
      3059
    ],
    "orderRejected": [
      3059,
      13,
      19,
      3,
      14,
      13,
      0,
      13,
      5,
      19,
      19,
      15,
      10,
      2,
      16,
      14,
      10,
      4,
      14,
      0,
      0,
      19,
      0,
      17,
      19,
      6,
      8,
      17,
      19,
      19,
      14,
      14,
      9,
      19,
      16,
      9,
      15,
      11,
      14,
      19,
      3,
      19,
      8,
      0,
      4,
      6,
      10,
      8,
      19,
      19,
      15,
      0,
      0
    ],
    "orderAccepted": [
      11,
      11,
      9,
      1,
      11,
      10,
      3,
      10,
      3,
      3,
      3,
      11,
      0,
      11,
      10,
      11,
      2,
      0,
      5,
      11,
      9,
      4,
      3,
      3,
      5,
      8,
      0,
      11,
      11,
      1,
      11,
      7,
      0,
      2,
      0,
      11,
      6,
      4,
      2,
      0,
      0,
      10,
      5,
      1,
      0,
      9,
      0,
      0,
      7,
      11,
      5,
      6
    ],
    "orderHold": [
      4,
      1,
      3,
      4,
      4,
      0,
      1,
      3,
      4,
      0,
      1,
      4,
      0,
      3,
      2,
      1,
      4,
      2,
      4,
      4,
      2,
      4,
      0,
      4,
      0,
      4,
      1,
      2,
      3,
      0,
      2,
      2,
      4,
      2,
      1,
      2,
      4,
      3,
      4,
      0,
      4,
      2,
      0,
      4,
      3,
      1,
      0,
      3,
      0,
      4,
      2,
      4
    ],
    "orderBackordered": [
      4,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0
    ],
    "issueQuality": [
      3,
      1,
      4,
      1,
      4,
      4,
      0,
      0,
      1,
      3,
      4,
      4,
      3,
      0,
      0,
      0,
      4,
      3,
      1,
      4,
      1,
      0,
      0,
      3,
      0,
      4,
      4,
      2,
      4,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      0,
      0,
      4,
      3,
      0,
      0,
      4,
      4,
      4,
      3,
      2,
      0,
      0,
      2,
      0,
      4
    ],
    "issueDelay": [
      0,
      0,
      2,
      3,
      4,
      0,
      4,
      0,
      1,
      4,
      4,
      0,
      4,
      3,
      0,
      3,
      4,
      0,
      2,
      1,
      2,
      0,
      4,
      2,
      0,
      1,
      3,
      1,
      4,
      4,
      2,
      1,
      4,
      2,
      2,
      1,
      2,
      3,
      4,
      4,
      0,
      4,
      0,
      2,
      4,
      1,
      0,
      0,
      4,
      0,
      1,
      3
    ],
    "issueQuantity": [
      4,
      2,
      0,
      0,
      4,
      1,
      2,
      0,
      3,
      3,
      2,
      1,
      0,
      0,
      4,
      1,
      3,
      4,
      0,
      1,
      0,
      3,
      2,
      2,
      2,
      4,
      0,
      3,
      3,
      0,
      1,
      4,
      3,
      1,
      0,
      4,
      4,
      3,
      4,
      4,
      4,
      3,
      1,
      3,
      2,
      0,
      1,
      0,
      0,
      0,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000195",
    "status": "active",
    "name": "LifeLine Supplies",
    "tag": "Equipment, PPE, Nutrition",
    "contactName": "Ashley Anderson",
    "industryRating": 2.1,
    "procedures": "[PR-066,PR-047,PR-047,PR-060,PR-053,PR-033,PR-030,PR-046,PR-020,PR-046]",
    "contracts": "[CNTRCT-0000057,CNTRCT-0000030,CNTRCT-0000043]",
    "orders": "[ORDR-0-0000034,ORDR-1-0000046,ORDR-2-0000034,ORDR-3-0000060,ORDR-4-0000048,ORDR-5-0000016,ORDR-6-0000051,ORDR-7-0000013,ORDR-8-0000035,ORDR-9-0000030,ORDR-10-0000059,ORDR-11-0000023,ORDR-12-0000016,ORDR-13-0000042,ORDR-14-0000029,ORDR-15-0000070,ORDR-16-0000038,ORDR-17-0000024,ORDR-18-0000028]",
    "spendWeekly": [
      3097,
      3015,
      2906,
      3017,
      3095,
      2988,
      2974,
      3000,
      3100,
      2971,
      3072,
      3075,
      2997,
      2996,
      3070,
      2904,
      3014,
      2978,
      2983,
      3098,
      3037,
      3097,
      3067,
      2903,
      3095,
      3021,
      2972,
      3075,
      2986,
      2930,
      2972,
      2967,
      3034,
      2904,
      3061,
      3011,
      2963,
      2902,
      3030,
      2929,
      2901,
      2980,
      2965,
      3012,
      3084,
      2907,
      3085,
      2900,
      2985,
      3045,
      2981,
      3007
    ],
    "orderRejected": [
      3007,
      13,
      15,
      13,
      5,
      13,
      19,
      10,
      11,
      19,
      0,
      7,
      11,
      16,
      18,
      18,
      13,
      0,
      11,
      1,
      3,
      16,
      9,
      14,
      19,
      4,
      14,
      0,
      16,
      18,
      2,
      14,
      8,
      19,
      13,
      4,
      13,
      11,
      5,
      0,
      2,
      19,
      13,
      0,
      10,
      13,
      7,
      18,
      10,
      12,
      1,
      12,
      12
    ],
    "orderAccepted": [
      3,
      6,
      7,
      9,
      5,
      6,
      4,
      8,
      0,
      0,
      6,
      5,
      5,
      5,
      5,
      11,
      1,
      0,
      5,
      5,
      0,
      2,
      2,
      9,
      9,
      10,
      5,
      6,
      2,
      6,
      10,
      5,
      1,
      0,
      4,
      11,
      1,
      3,
      6,
      0,
      0,
      11,
      11,
      2,
      4,
      8,
      8,
      11,
      9,
      4,
      0,
      0
    ],
    "orderHold": [
      2,
      0,
      3,
      1,
      1,
      2,
      2,
      1,
      3,
      2,
      1,
      3,
      3,
      3,
      0,
      0,
      1,
      0,
      2,
      2,
      2,
      2,
      0,
      0,
      4,
      2,
      2,
      0,
      4,
      0,
      0,
      3,
      3,
      0,
      0,
      4,
      1,
      0,
      0,
      2,
      4,
      2,
      4,
      3,
      0,
      4,
      2,
      0,
      0,
      0,
      0,
      3
    ],
    "orderBackordered": [
      3,
      0,
      2,
      1,
      0,
      2,
      1,
      0,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      2,
      0,
      0,
      0,
      1
    ],
    "issueIncomplete": [
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0
    ],
    "issueQuality": [
      3,
      1,
      4,
      4,
      0,
      4,
      4,
      3,
      3,
      4,
      3,
      3,
      4,
      2,
      3,
      3,
      2,
      3,
      0,
      4,
      1,
      0,
      2,
      2,
      3,
      3,
      4,
      2,
      3,
      1,
      1,
      0,
      2,
      2,
      0,
      4,
      4,
      2,
      2,
      2,
      1,
      0,
      3,
      2,
      3,
      2,
      3,
      3,
      3,
      3,
      0,
      3
    ],
    "issueDelay": [
      0,
      0,
      4,
      4,
      2,
      0,
      2,
      1,
      4,
      2,
      1,
      1,
      4,
      4,
      3,
      0,
      4,
      1,
      2,
      1,
      2,
      4,
      0,
      1,
      2,
      4,
      3,
      4,
      0,
      0,
      4,
      1,
      4,
      0,
      3,
      1,
      0,
      0,
      3,
      4,
      4,
      3,
      1,
      0,
      1,
      3,
      0,
      1,
      2,
      0,
      2,
      4
    ],
    "issueQuantity": [
      4,
      0,
      1,
      0,
      1,
      2,
      0,
      0,
      3,
      3,
      2,
      2,
      1,
      0,
      4,
      4,
      1,
      0,
      4,
      3,
      0,
      4,
      2,
      3,
      2,
      2,
      1,
      2,
      4,
      1,
      0,
      1,
      4,
      0,
      1,
      1,
      4,
      0,
      0,
      4,
      4,
      2,
      4,
      1,
      2,
      1,
      1,
      3,
      0,
      3,
      3,
      2
    ]
  },
  {
    "id": "VNDR-0000196",
    "status": "inactive",
    "name": "MedPulse Supply Network",
    "tag": "Laboratory Supplies, Medical Supplies",
    "contactName": "Julie Sanders",
    "industryRating": 4.5,
    "procedures": "[PR-066,PR-063,PR-043,PR-035,PR-052,PR-028,PR-065,PR-062,PR-060,PR-056]",
    "contracts": "[CNTRCT-0000020,CNTRCT-0000061,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000017,ORDR-1-0000045,ORDR-2-0000042,ORDR-3-0000034,ORDR-4-0000045,ORDR-5-0000008,ORDR-6-0000038,ORDR-7-0000053,ORDR-8-0000020,ORDR-9-0000051,ORDR-10-0000046,ORDR-11-0000036,ORDR-12-0000021,ORDR-13-0000063,ORDR-14-0000049,ORDR-15-0000077,ORDR-16-0000034,ORDR-17-0000018,ORDR-18-0000029]",
    "spendWeekly": [
      3012,
      2957,
      2917,
      3040,
      3029,
      3048,
      3031,
      2910,
      3064,
      2960,
      2976,
      3005,
      3009,
      2901,
      3058,
      2905,
      3073,
      3033,
      3046,
      3052,
      3100,
      3081,
      2900,
      2958,
      2950,
      3096,
      3092,
      3087,
      2951,
      3086,
      3051,
      2994,
      3054,
      3000,
      3085,
      2988,
      2982,
      2905,
      2973,
      3080,
      2924,
      2998,
      3087,
      3016,
      3067,
      2980,
      3099,
      2917,
      2974,
      2938,
      2978,
      3005
    ],
    "orderRejected": [
      3005,
      14,
      7,
      4,
      0,
      11,
      2,
      13,
      14,
      19,
      2,
      5,
      6,
      11,
      12,
      16,
      2,
      7,
      8,
      16,
      5,
      10,
      14,
      16,
      5,
      7,
      13,
      14,
      12,
      15,
      3,
      15,
      19,
      16,
      17,
      15,
      4,
      14,
      15,
      5,
      11,
      19,
      16,
      16,
      8,
      13,
      13,
      18,
      7,
      1,
      5,
      8,
      7
    ],
    "orderAccepted": [
      9,
      5,
      7,
      11,
      4,
      2,
      4,
      6,
      2,
      1,
      6,
      3,
      5,
      0,
      11,
      8,
      0,
      9,
      7,
      2,
      4,
      2,
      11,
      8,
      10,
      1,
      0,
      8,
      0,
      6,
      7,
      6,
      5,
      0,
      3,
      6,
      1,
      0,
      6,
      4,
      10,
      10,
      3,
      0,
      10,
      4,
      5,
      8,
      9,
      7,
      0,
      2
    ],
    "orderHold": [
      4,
      2,
      2,
      2,
      4,
      4,
      0,
      3,
      3,
      0,
      3,
      0,
      4,
      3,
      0,
      2,
      3,
      0,
      1,
      1,
      2,
      2,
      1,
      4,
      2,
      3,
      1,
      2,
      3,
      1,
      2,
      3,
      4,
      0,
      3,
      4,
      0,
      2,
      4,
      4,
      2,
      0,
      1,
      2,
      3,
      4,
      3,
      3,
      2,
      3,
      2,
      2
    ],
    "orderBackordered": [
      2,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      1,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      2,
      1,
      3,
      0,
      3,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      2,
      0,
      0,
      4,
      3,
      1,
      4,
      2,
      2,
      3,
      3,
      3,
      3,
      2,
      0,
      3,
      0,
      4,
      2,
      1,
      1,
      0,
      2,
      0,
      3,
      3,
      2,
      0,
      3,
      4,
      2,
      0,
      1,
      0,
      2,
      4,
      2,
      4,
      4
    ],
    "issueDelay": [
      0,
      1,
      3,
      2,
      3,
      2,
      1,
      2,
      3,
      4,
      2,
      2,
      4,
      0,
      1,
      4,
      2,
      0,
      3,
      4,
      3,
      0,
      0,
      2,
      0,
      3,
      4,
      2,
      0,
      1,
      1,
      4,
      4,
      3,
      3,
      4,
      3,
      3,
      1,
      0,
      1,
      2,
      0,
      4,
      1,
      3,
      2,
      2,
      2,
      4,
      2,
      3
    ],
    "issueQuantity": [
      4,
      3,
      0,
      3,
      4,
      4,
      1,
      0,
      2,
      3,
      3,
      2,
      4,
      3,
      4,
      1,
      2,
      4,
      3,
      4,
      0,
      1,
      3,
      4,
      0,
      2,
      2,
      1,
      2,
      4,
      0,
      2,
      2,
      0,
      2,
      3,
      3,
      3,
      1,
      4,
      3,
      3,
      2,
      0,
      3,
      1,
      2,
      0,
      1,
      2,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000197",
    "status": "inactive",
    "name": "CareBridge Equipment",
    "tag": "Equipment",
    "contactName": "Christopher Gonzalez",
    "industryRating": 4.5,
    "procedures": "[PR-016,PR-040,PR-044,PR-066,PR-045,PR-059,PR-052,PR-060,PR-046,PR-073]",
    "contracts": "[CNTRCT-0000065,CNTRCT-0000060,CNTRCT-0000055]",
    "orders": "[ORDR-0-0000018,ORDR-1-0000066,ORDR-2-0000016,ORDR-3-0000026,ORDR-4-0000012,ORDR-5-0000062,ORDR-6-0000013,ORDR-7-0000009,ORDR-8-0000071,ORDR-9-0000031,ORDR-10-0000034,ORDR-11-0000033,ORDR-12-0000046,ORDR-13-0000076,ORDR-14-0000017,ORDR-15-0000059,ORDR-16-0000026,ORDR-17-0000052,ORDR-18-0000041]",
    "spendWeekly": [
      2922,
      3051,
      3017,
      3079,
      2904,
      2905,
      3067,
      2962,
      2977,
      3012,
      3057,
      3012,
      2934,
      3039,
      3068,
      2913,
      3071,
      2973,
      2949,
      3098,
      2968,
      3097,
      2916,
      3012,
      3034,
      3096,
      2972,
      2960,
      2983,
      3043,
      2915,
      3020,
      3019,
      2988,
      3048,
      2968,
      2982,
      2902,
      2906,
      2987,
      2901,
      2932,
      3019,
      2992,
      3095,
      3020,
      3007,
      3100,
      3023,
      3025,
      2979,
      3046
    ],
    "orderRejected": [
      3046,
      15,
      6,
      14,
      0,
      16,
      2,
      6,
      9,
      12,
      9,
      16,
      17,
      1,
      6,
      11,
      7,
      19,
      12,
      16,
      0,
      6,
      18,
      19,
      19,
      19,
      19,
      6,
      13,
      19,
      18,
      8,
      17,
      19,
      0,
      1,
      7,
      11,
      19,
      19,
      14,
      3,
      5,
      14,
      5,
      2,
      7,
      10,
      12,
      14,
      15,
      11,
      5
    ],
    "orderAccepted": [
      11,
      4,
      8,
      7,
      6,
      4,
      5,
      3,
      5,
      0,
      4,
      8,
      0,
      9,
      5,
      7,
      6,
      10,
      2,
      8,
      4,
      9,
      8,
      0,
      6,
      9,
      0,
      9,
      0,
      3,
      10,
      11,
      0,
      2,
      9,
      4,
      8,
      0,
      11,
      5,
      11,
      11,
      10,
      5,
      0,
      0,
      8,
      6,
      3,
      7,
      0,
      6
    ],
    "orderHold": [
      2,
      0,
      1,
      3,
      2,
      4,
      3,
      4,
      3,
      1,
      0,
      2,
      3,
      4,
      0,
      2,
      2,
      3,
      2,
      0,
      2,
      2,
      0,
      2,
      2,
      2,
      3,
      1,
      2,
      2,
      0,
      3,
      4,
      0,
      0,
      0,
      3,
      0,
      4,
      2,
      0,
      3,
      3,
      4,
      3,
      2,
      3,
      4,
      2,
      1,
      0,
      4
    ],
    "orderBackordered": [
      4,
      1,
      2,
      1,
      2,
      0,
      0,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1
    ],
    "issueQuality": [
      4,
      0,
      2,
      1,
      2,
      3,
      4,
      1,
      0,
      3,
      4,
      1,
      1,
      2,
      1,
      0,
      0,
      3,
      2,
      0,
      0,
      2,
      4,
      2,
      1,
      1,
      2,
      2,
      1,
      4,
      2,
      4,
      3,
      4,
      1,
      2,
      2,
      2,
      0,
      4,
      0,
      3,
      4,
      4,
      4,
      2,
      0,
      2,
      1,
      2,
      1,
      4
    ],
    "issueDelay": [
      4,
      2,
      3,
      1,
      3,
      1,
      3,
      0,
      0,
      1,
      3,
      1,
      3,
      3,
      4,
      4,
      1,
      0,
      0,
      0,
      4,
      0,
      0,
      0,
      2,
      4,
      3,
      0,
      3,
      0,
      3,
      2,
      4,
      1,
      4,
      1,
      0,
      0,
      0,
      2,
      1,
      3,
      4,
      4,
      3,
      2,
      0,
      1,
      4,
      1,
      1,
      4
    ],
    "issueQuantity": [
      0,
      1,
      2,
      4,
      2,
      2,
      1,
      0,
      3,
      0,
      2,
      4,
      1,
      4,
      3,
      1,
      4,
      1,
      0,
      3,
      3,
      1,
      4,
      0,
      3,
      4,
      2,
      2,
      2,
      2,
      0,
      2,
      3,
      0,
      2,
      2,
      4,
      0,
      2,
      4,
      4,
      3,
      4,
      1,
      4,
      3,
      2,
      2,
      4,
      0,
      4,
      3
    ]
  },
  {
    "id": "VNDR-0000198",
    "status": "active",
    "name": "MedixSupply Chain",
    "tag": "Imaging",
    "contactName": "Jennifer Carey",
    "industryRating": 2.3,
    "procedures": "[PR-066,PR-045,PR-040,PR-052,PR-044,PR-070,PR-058,PR-054,PR-073,PR-071]",
    "contracts": "[CNTRCT-0000006,CNTRCT-0000035,CNTRCT-0000057]",
    "orders": "[ORDR-0-0000001,ORDR-1-0000013,ORDR-2-0000049,ORDR-3-0000021,ORDR-4-0000034,ORDR-5-0000034,ORDR-6-0000035,ORDR-7-0000035,ORDR-8-0000033,ORDR-9-0000011,ORDR-10-0000050,ORDR-11-0000072,ORDR-12-0000027,ORDR-13-0000053,ORDR-14-0000070,ORDR-15-0000051,ORDR-16-0000048,ORDR-17-0000035,ORDR-18-0000057]",
    "spendWeekly": [
      3011,
      2937,
      2961,
      2904,
      3012,
      2969,
      3051,
      3085,
      2986,
      2902,
      2901,
      3095,
      3039,
      3080,
      3050,
      2941,
      2972,
      2970,
      2969,
      3098,
      3063,
      3005,
      2974,
      2903,
      2937,
      2977,
      3049,
      3013,
      3085,
      3025,
      2903,
      3097,
      3037,
      2912,
      2974,
      3049,
      2949,
      3056,
      3068,
      2982,
      3036,
      2973,
      2976,
      3091,
      3028,
      2901,
      3022,
      2943,
      3036,
      3035,
      3057,
      3046
    ],
    "orderRejected": [
      3046,
      19,
      4,
      14,
      11,
      19,
      10,
      18,
      0,
      13,
      7,
      5,
      13,
      5,
      14,
      2,
      19,
      10,
      0,
      10,
      0,
      13,
      0,
      8,
      18,
      0,
      12,
      0,
      14,
      8,
      4,
      9,
      7,
      2,
      14,
      13,
      6,
      9,
      14,
      3,
      4,
      16,
      3,
      0,
      11,
      9,
      0,
      7,
      17,
      1,
      13,
      2,
      6
    ],
    "orderAccepted": [
      11,
      8,
      9,
      7,
      9,
      5,
      0,
      5,
      1,
      0,
      4,
      10,
      3,
      5,
      11,
      9,
      8,
      0,
      4,
      11,
      4,
      4,
      9,
      4,
      2,
      11,
      0,
      11,
      10,
      0,
      10,
      6,
      2,
      0,
      5,
      11,
      6,
      9,
      9,
      4,
      2,
      8,
      6,
      11,
      5,
      11,
      5,
      11,
      4,
      1,
      10,
      6
    ],
    "orderHold": [
      2,
      4,
      3,
      4,
      0,
      0,
      2,
      0,
      3,
      0,
      3,
      3,
      0,
      2,
      3,
      3,
      3,
      2,
      2,
      3,
      0,
      2,
      1,
      4,
      1,
      4,
      0,
      2,
      4,
      0,
      0,
      4,
      4,
      0,
      0,
      3,
      3,
      2,
      0,
      0,
      4,
      2,
      0,
      3,
      1,
      3,
      0,
      3,
      0,
      0,
      3,
      1
    ],
    "orderBackordered": [
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      0
    ],
    "issueIncomplete": [
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0
    ],
    "issueQuality": [
      4,
      1,
      2,
      3,
      0,
      3,
      2,
      4,
      2,
      1,
      2,
      4,
      1,
      2,
      1,
      2,
      4,
      0,
      3,
      4,
      4,
      1,
      0,
      2,
      1,
      4,
      3,
      0,
      4,
      4,
      3,
      1,
      2,
      0,
      1,
      4,
      0,
      1,
      0,
      4,
      2,
      0,
      0,
      3,
      1,
      2,
      3,
      0,
      3,
      0,
      1,
      4
    ],
    "issueDelay": [
      3,
      0,
      3,
      4,
      4,
      0,
      1,
      0,
      2,
      2,
      3,
      0,
      0,
      2,
      3,
      3,
      4,
      0,
      2,
      2,
      2,
      1,
      0,
      4,
      0,
      2,
      4,
      3,
      4,
      1,
      0,
      3,
      4,
      3,
      4,
      1,
      0,
      1,
      4,
      4,
      0,
      4,
      2,
      0,
      2,
      3,
      4,
      3,
      2,
      0,
      0,
      0
    ],
    "issueQuantity": [
      4,
      4,
      3,
      0,
      2,
      3,
      4,
      1,
      0,
      3,
      2,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      3,
      4,
      0,
      4,
      0,
      2,
      0,
      2,
      1,
      0,
      4,
      4,
      1,
      2,
      2,
      1,
      1,
      4,
      3,
      0,
      0,
      3,
      2,
      4,
      4,
      2,
      4,
      0,
      2,
      4,
      0,
      4,
      4,
      2
    ]
  },
  {
    "id": "VNDR-0000199",
    "status": "inactive",
    "name": "HealthSync Distributors",
    "tag": "Facilities Management, Laboratory Supplies, Surgical Instruments",
    "contactName": "Bonnie Jones",
    "industryRating": 4.2,
    "procedures": "[PR-051,PR-065,PR-038,PR-064,PR-053,PR-049,PR-072,PR-054,PR-063,PR-063]",
    "contracts": "[CNTRCT-0000021,CNTRCT-0000044,CNTRCT-0000063]",
    "orders": "[ORDR-0-0000009,ORDR-1-0000039,ORDR-2-0000012,ORDR-3-0000002,ORDR-4-0000017,ORDR-5-0000053,ORDR-6-0000028,ORDR-7-0000019,ORDR-8-0000033,ORDR-9-0000042,ORDR-10-0000058,ORDR-11-0000046,ORDR-12-0000051,ORDR-13-0000038,ORDR-14-0000070,ORDR-15-0000070,ORDR-16-0000058,ORDR-17-0000032,ORDR-18-0000017]",
    "spendWeekly": [
      2901,
      3000,
      3017,
      2963,
      2967,
      2905,
      3070,
      3098,
      3020,
      2952,
      3100,
      2952,
      2955,
      3094,
      3055,
      2901,
      3061,
      3039,
      2930,
      3098,
      3100,
      3097,
      2962,
      2968,
      2966,
      3096,
      2974,
      3081,
      2901,
      2989,
      3008,
      2993,
      3041,
      3034,
      2913,
      3087,
      2907,
      2915,
      2951,
      3068,
      2901,
      3025,
      3097,
      2970,
      3020,
      2983,
      3047,
      3010,
      3011,
      3006,
      3095,
      2901
    ],
    "orderRejected": [
      2901,
      14,
      19,
      0,
      0,
      9,
      19,
      7,
      14,
      6,
      0,
      5,
      9,
      8,
      16,
      2,
      19,
      5,
      3,
      18,
      16,
      0,
      6,
      7,
      4,
      0,
      7,
      17,
      4,
      2,
      9,
      6,
      2,
      7,
      0,
      19,
      11,
      17,
      14,
      4,
      19,
      14,
      13,
      19,
      7,
      12,
      0,
      3,
      17,
      19,
      2,
      12,
      14
    ],
    "orderAccepted": [
      5,
      11,
      7,
      11,
      0,
      5,
      11,
      8,
      0,
      7,
      3,
      0,
      7,
      9,
      10,
      10,
      0,
      4,
      1,
      1,
      6,
      5,
      11,
      2,
      10,
      5,
      8,
      6,
      5,
      4,
      8,
      4,
      0,
      3,
      10,
      2,
      11,
      0,
      11,
      3,
      0,
      7,
      11,
      3,
      10,
      5,
      7,
      7,
      0,
      6,
      4,
      11
    ],
    "orderHold": [
      4,
      2,
      2,
      4,
      3,
      1,
      1,
      0,
      1,
      4,
      2,
      0,
      4,
      2,
      0,
      1,
      4,
      0,
      1,
      0,
      2,
      4,
      4,
      3,
      0,
      2,
      4,
      1,
      2,
      0,
      3,
      4,
      2,
      0,
      2,
      2,
      2,
      3,
      4,
      2,
      3,
      4,
      4,
      2,
      2,
      3,
      0,
      3,
      0,
      0,
      1,
      3
    ],
    "orderBackordered": [
      3,
      1,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      2,
      0,
      1,
      2,
      0,
      2,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      2
    ],
    "issueIncomplete": [
      2,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0
    ],
    "issueQuality": [
      0,
      0,
      3,
      3,
      0,
      3,
      1,
      4,
      4,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      4,
      3,
      4,
      4,
      2,
      3,
      3,
      2,
      3,
      0,
      0,
      2,
      4,
      2,
      3,
      3,
      3,
      3,
      0,
      2,
      0,
      2,
      3,
      0,
      0,
      2,
      2,
      4,
      2,
      0,
      2,
      2,
      0,
      3,
      1,
      4
    ],
    "issueDelay": [
      3,
      3,
      3,
      4,
      2,
      3,
      4,
      2,
      1,
      0,
      0,
      4,
      0,
      2,
      2,
      3,
      4,
      0,
      3,
      1,
      4,
      0,
      3,
      0,
      4,
      4,
      4,
      3,
      2,
      3,
      2,
      2,
      4,
      2,
      2,
      0,
      3,
      3,
      4,
      0,
      2,
      0,
      0,
      0,
      4,
      0,
      2,
      0,
      2,
      0,
      0,
      4
    ],
    "issueQuantity": [
      0,
      0,
      4,
      0,
      3,
      1,
      1,
      3,
      0,
      4,
      0,
      1,
      0,
      4,
      1,
      1,
      3,
      4,
      0,
      2,
      2,
      4,
      3,
      3,
      0,
      2,
      4,
      1,
      2,
      0,
      2,
      0,
      4,
      2,
      0,
      3,
      4,
      1,
      0,
      4,
      4,
      3,
      4,
      0,
      1,
      3,
      4,
      4,
      2,
      4,
      3,
      3
    ]
  }
]