import { useContext, useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import { MedicalItem } from "../../model/medicalItem/medicalItem"
import { EntityContext } from "../../context/globalContext"

const SwapDialog = (props: { onSubmit: () => void }) => {
    const [selectedItem, setSelectedItem] = useState<MedicalItem>()

    const entityContext = useContext(EntityContext)

    return (
        <Dialog>
            <div className={`flex flex-col relative w-[800px] h-[540px] rounded-md`}>

                <div style={{}} className="flex flex-col p-5 mx-[120px]">
                    <>
                        <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                            <p className="font-large-title font-neutral-6 font-bold">Select Item</p>
                            <p className="font-title-3 text-neutral-5">Search for and select an item from the inventory that you would like to swap it with.</p>
                        </div>

                        <div className="flex flex-col gap-2.5 mx-[40px] overflow-y-hidden h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md pt-2.5">
                            <div className="px-2.5">
                                <div className="flex items-center w-full font-body placeholder:text-neutral-4 focus:outline-none text-neutral-6 bg-transparent p-2 rounded-md border border-neutral-2">
                                    <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                                    <input className="w-full bg-transparent" type="text" placeholder="Search"></input>
                                </div>
                            </div>

                            <div className="flex flex-col gap-2.5 overflow-y-auto px-2.5 pb-2.5">
                                {entityContext?.medicalItems.filter((item) => selectedItem ? item.manufacturerID === selectedItem.manufacturerID : true).map((item) => {
                                    return (
                                        <div className="flex gap-2 bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div className="w-[28px] h-[28px] border=[0.5px] p-[1px] border-neutral-2 bg-white rounded-[2px]">
                                                <div className="w-full h-full border-[0.5px] border-neutral-1 bg-neutral-1 rounded-[2px] shadow-inner">
                                                    <img className="w-full p-[1px]" src={'/images/icons/ic-mock-item.svg'}></img>
                                                </div>
                                            </div>

                                            <div>
                                                <p className="font-body">{item.name}</p>
                                                <p className="font-subheadline text-neutral-5">{item.status}</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <PrimaryButton
                                                    title={item === selectedItem ? "Change" : "Select"}
                                                    type={item === selectedItem ? "white" : "gray"}
                                                    size="lg"
                                                    onClick={() => {
                                                        setSelectedItem(item)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="flex flex-col gap-1.5 border border-neutral-2 rounded-md bg-white p-1.5">
                                    <div className="flex p-1.5">
                                        <p className="font-body">ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedItem?.erpID}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2"></div>

                                    <div className="flex p-1.5">
                                        <p className="font-body">Manufacturer</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedItem?.manufacturer}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2"></div>

                                    <div className="flex p-1.5">
                                        <p className="font-body">Stored At</p>
                                        <p className="font-body text-neutral-5 ml-auto">London Hospital Inventory</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2"></div>

                                    <div className="flex p-1.5">
                                        <p className="font-body">Available Units</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedItem?.amount}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2"></div>

                                    <div className="flex p-1.5">
                                        <p className="font-body">Status</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedItem?.status}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>

                <div className="mt-auto h-[1px] bg-neutral-2"></div>

                <div className="flex p-5 h-[70px]">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        onClick={() => {
                            props.onSubmit()
                        }}
                    />

                    <div className="flex gap-3 ml-auto">
                        <PrimaryButton
                            title={"Change Item"}
                            type="primary"
                            disabled={!selectedItem}
                            onClick={() => {
                                props.onSubmit()
                            }}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default SwapDialog