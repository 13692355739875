import { useState, useEffect, useContext } from "react"
import { auth } from "../../firebase/firebase"
import { User, signInWithEmailAndPassword } from "firebase/auth"
import { Link, useLocation, useNavigate } from "react-router-dom"
import PrimaryButton from "../../components/primaryButton/primaryButton"
import { useCookies } from "react-cookie"
import { UserContext } from "../../context/globalContext"

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [user, setUser] = useState<User | null>()

    const [cookies, setCookie, removeCookie] = useCookies(['refreshToken', 'email', 'displayName', 'uid']);

    const userContext = useContext(UserContext)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        document.title = 'Login'
    }, [])

    useEffect(() => {
        setUser(auth.currentUser)
    }, [])

    const login = () => {
        signInWithEmailAndPassword(auth, location.pathname === "/login/sleep" && cookies.refreshToken !== undefined ? cookies.email || '' : email, password)
            .then((response) => {
                setCookie('refreshToken', response.user.refreshToken, { path: '/' })
                setCookie('email', response.user.email, { path: '/' })
                setCookie('displayName', response.user.displayName, { path: '/' })
                setCookie('uid', response.user.displayName, { path: '/' })

                navigate('/dashboard')
            })
    }

    return (
        <div className="relative h-screen bg-repeat flex flex-col bg-[url('/public/images/backgrounds/bg-gradient-login.svg')]">
            <iframe className="absolute w-screen h-screen z-[1]" src="/images/backgrounds/bg-animation.html"></iframe>

            {location.pathname === "/login/sleep" && cookies.refreshToken ?
                <>
                    <div className="text-center pt-24 text-white/50 z-[2]">
                        <p className="font-title-1 font-bold">Cleveland Hospital</p>
                        <p className="text-8xl font-bold">{userContext?.user?.location || ''}</p>
                    </div>

                    <div className="text-center mt-auto mb-24 z-[2]">
                        <img className="mx-auto py-3" src={'/images/icons/ic-avatar.svg'} />

                        <p className="font-title-2 font-bold py-4 text-white">{cookies.displayName}</p>

                        <div className="flex flex-col gap-2 w-[160px] mx-auto">
                            <div className="flex border border-neutral-2 bg-white/50 rounded-full px-3.5 h-[32px]">
                                <input
                                    type="password"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            login()
                                        }
                                    }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-[107px] mr-2 font-body bg-transparent text-neutral-6 placeholder:text-neutral-5 focus:outline-none"
                                    placeholder="Enter Password"></input>

                                {password && (
                                    <img onClick={() => login()} className="cursor-pointer" src={'/images/icons/ic-arrow-right-circle.svg'} />
                                )}
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="text-center my-auto z-[2]">
                        <img className="mx-auto py-3" src={'/images/icons/ic-avatar.svg'} />

                        <p className="font-title-2 font-bold py-4 text-white">Log-in</p>

                        <div className="flex flex-col gap-2 w-[160px] mx-auto">
                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="h-[32px] font-body border border-neutral-2 bg-white/50 rounded-full px-3.5 py-1.5 text-neutral-6 placeholder:text-neutral-5 focus:outline-none" placeholder="Enter Email"></input>

                            <div className="flex border border-neutral-2 bg-white/50 rounded-full px-3.5 h-[32px]">
                                <input
                                    type="password"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            login()
                                        }
                                    }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-[107px] mr-2 font-body bg-transparent text-neutral-6 placeholder:text-neutral-5 focus:outline-none"
                                    placeholder="Enter Password"></input>

                                {password && (
                                    <img onClick={() => login()} className="cursor-pointer" src={'/images/icons/ic-arrow-right-circle.svg'} />
                                )}
                            </div>


                            <p style={{ textShadow: '0px 7px 22px rgba(0, 0, 0, 0.82), 0px 0px 6.5px rgba(0, 0, 0, 0.60), 0px 0px 2px rgba(0, 0, 0, 0.80)' }} className="font-body font-semibold mt-2 text-white text">Forgot Password</p>
                        </div>
                    </div>

                    <div className="absolute bottom-0 w-full z-[2] pb-[80px]">
                        <div className="mx-auto z-[2] w-[480px] h-[56px] flex bg-tooltip rounded-md border border-neutral-2 items-center p-3">
                            <div className={`h-[26px] min-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                <img className="h-[16px] w-[16px]" src={`/images/icons/ic-info-circle-fill-white.svg`}></img>
                            </div>

                            <div className="ml-3">
                                <p className="font-body">No account?</p>
                                <p className="font-subheadline text-neutral-5">Create an account using your company email now.</p>
                            </div>

                            <div className="ml-auto">
                                <Link to={'/onboarding'}>
                                    <PrimaryButton
                                        title="Create Account"
                                        type="white"
                                    />
                                </Link>

                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Login