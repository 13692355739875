import { useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import Input from "../input/input"
import { mockSpecialties } from "../../model/specialty/specialty"
import { mockVendors } from "../../model/vendor/vendor"
import { Vendor } from "../../model/vendor/vendor"
import Toggle from "../toggle/toggle"

const CreateItemDialog = (props: { onSubmit: () => void }) => {
    const [currentPage, setCurrentPage] = useState("details")

    const [specialty, setSpecialty] = useState("Cardiology")
    const [traits, setTraits] = useState<string[]>([])
    const [selectedItem, setSelectedItem] = useState<Vendor>()

    const selectTrait = (trait: string) => {
        if (traits.includes(trait)) {
            setTraits([...traits].filter((v) => v !== trait))
        } else {
            setTraits([...traits, trait])
        }
    }

    return (
        <Dialog>
            <div className={`flex flex-col relative w-[800px] h-[540px] rounded-md`}>

                <div className="flex flex-col p-5 mx-[120px]">
                    {currentPage === "details" && (
                        <>
                            <img className="h-[64px] mt-12 mr-1.5" src={'/images/icons/ic-list-bullet-rectangle-fill-gradient.svg'}></img>

                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                <p className="font-large-title font-neutral-6 font-bold">Item Details</p>
                            </div>

                            <div className="flex flex-col gap-2 mx-[100px]">
                                <Input
                                    type="text"
                                    label="Name"
                                    placeholder="Name"
                                />

                                <Input
                                    type="text"
                                    label="Short Description"
                                    placeholder="Short Description"
                                />

                                <Input
                                    type="select"
                                    label="Specialty"
                                    value={specialty}
                                    options={mockSpecialties.map((specialty) => {
                                        return (
                                            {
                                                label: specialty,
                                                value: specialty
                                            }
                                        )
                                    })}
                                    onChange={(v) => setSpecialty(v)}
                                />
                            </div>
                        </>
                    )}

                    {currentPage === "vendor" && (
                        <>
                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                <p className="font-large-title font-neutral-6 font-bold">Vendor Profile</p>
                                <p className="font-title-3 text-neutral-5">Specify what vendor you’d like to use to provide the item. You can browse your vendors or enter a brand new profile.</p>
                            </div>

                            <div className="flex flex-col gap-2.5 mx-[40px] overflow-y-hidden h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md pt-2.5">
                                <div className="px-2.5">
                                    <div className="flex items-center w-full font-body placeholder:text-neutral-4 focus:outline-none text-neutral-6 bg-transparent p-2 rounded-md border border-neutral-2">
                                        <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                                        <input className="w-full bg-transparent" type="text" placeholder="Search"></input>
                                    </div>
                                </div>



                                <div className="flex flex-col gap-2.5 overflow-y-auto px-2.5 pb-2.5">
                                    {mockVendors.filter((item) => selectedItem ? item === selectedItem : true).map((item) => {
                                        return (
                                            <div className="flex gap-2 bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                                <div className="w-[28px] h-[28px] border=[0.5px] p-[1px] border-neutral-2 bg-white rounded-[2px]">
                                                    <div className="w-full h-full border-[0.5px] border-neutral-1 bg-neutral-1 rounded-[2px] shadow-inner">
                                                        <img className="w-full p-[1px]" src={'/images/icons/ic-mock-item.svg'}></img>
                                                    </div>
                                                </div>

                                                <div>
                                                    <p className="font-body">{item.name}</p>
                                                    <p className="font-subheadline text-neutral-5">{item.contracts.length > 0 ? 'On Contract' : 'No Contract'}</p>
                                                </div>

                                                <div className="flex items-center ml-auto">
                                                    <PrimaryButton
                                                        title={item === selectedItem ? "Change" : "Select"}
                                                        type={item === selectedItem ? "white" : "gray"}
                                                        size="lg"
                                                        onClick={() => {
                                                            if (item === selectedItem) {
                                                                setSelectedItem(undefined)
                                                            } else {
                                                                setSelectedItem(item)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {selectedItem && (
                                        <>
                                            <div className="flex flex-col gap-1.5 border border-neutral-2 rounded-md bg-white p-1.5">
                                                <div className="flex p-1.5">
                                                    <p className="font-body">ID</p>
                                                    <p className="font-body text-neutral-5 ml-auto">{selectedItem?.name}</p>
                                                </div>

                                                <div className="h-[1px] bg-neutral-2"></div>

                                                <div className="flex p-1.5">
                                                    <p className="font-body">Status</p>
                                                    <p className="font-body text-neutral-5 ml-auto">{'Active'}</p>
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-1.5 border border-neutral-2 rounded-md bg-white p-1.5">
                                                <div className="flex p-1.5 items-center">
                                                    <div>
                                                        <p className="font-body">Request Quote</p>
                                                        <p className="font-subheadline text-neutral-5">Vendor will compile quote form</p>
                                                    </div>

                                                    <div className="ml-auto flex items-center">
                                                        <Toggle />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>
                        </>
                    )}

                    {currentPage === "unit" && (
                        <>
                            <img className="h-[48px] mt-12 mb-4 mr-1.5" src={'/images/icons/ic-ruler-gradient.svg'}></img>

                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                <p className="font-large-title font-neutral-6 font-bold">Unit of Measure</p>
                            </div>

                            <div className="flex flex-col gap-2 mx-[100px]">
                                <Input
                                    type="text"
                                    label="Stock"
                                    placeholder="Stock"
                                />

                                <Input
                                    type="text"
                                    label="Buy units"
                                    placeholder="Buy units"
                                />

                                <Input
                                    type="text"
                                    label="Conversion (# of each)"
                                    placeholder="Conversion (# of each)"
                                />
                            </div>
                        </>
                    )}

                    {currentPage === 'traits' && (
                        <>
                            <img className="h-[70px] mt-[30px]" src={`/images/icons/ic-clipboard-gradient.svg`}></img>

                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                <p className="font-large-title font-neutral-6 font-bold">Traits</p>
                                <p className="font-title-3 text-neutral-5">Item traits help define the item further to your healthcare system needs. You can select traits now to help other people pick the right item.</p>
                            </div>
                        </>
                    )}
                </div>

                {currentPage === 'traits' && (
                    <div className="flex gap-3 items-center justify-center">
                        <div onClick={() => selectTrait('implantable')} className="cursor-pointer relative w-[118px] h-[118px] flex flex-col items-center justify-center gap-3 bg-white rounded-md border-2 border-neutral-2">
                            <img className="h-[36px] w-[36px]" src={`/images/icons/ic-heart-text-fill-grey.svg`}></img>
                            <p className="font-body">Implantable</p>

                            {traits.includes('implantable') && (
                                <img className="absolute top-2 right-2 h-[20px] w-[20px]" src={`/images/icons/ic-checkmark-circle-fill-green.svg`}></img>
                            )}
                        </div>

                        <div onClick={() => selectTrait('reusable')} className="cursor-pointer relative w-[118px] h-[118px] flex flex-col items-center justify-center gap-3 bg-white rounded-md border-2 border-neutral-2">
                            <img className="h-[36px] w-[36px]" src={`/images/icons/ic-recycle-grey.svg`}></img>
                            <p className="font-body">Reusable</p>

                            {traits.includes('reusable') && (
                                <img className="absolute top-2 right-2 h-[20px] w-[20px]" src={`/images/icons/ic-checkmark-circle-fill-green.svg`}></img>
                            )}
                        </div>
                    </div>

                )}

                <div className="mt-auto h-[1px] bg-neutral-2"></div>

                <div className="flex px-5 h-[76px] items-center">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        size="xl"
                        onClick={() => props.onSubmit()}
                    />

                    <div className="flex gap-3 ml-auto">
                        {currentPage !== "details" && (
                            <PrimaryButton
                                title="Back"
                                type="gray"
                                size="xl"
                                onClick={() => {
                                    switch (currentPage) {
                                        case "vendor":
                                            setCurrentPage("details")
                                            break
                                        case "unit":
                                            setCurrentPage("vendor")
                                            break
                                    }
                                }}
                            />
                        )}

                        {currentPage === "details" && (
                            <PrimaryButton
                                title={"Continue"}
                                type="white"
                                size="xl"
                                onClick={() => setCurrentPage("vendor")}
                            />
                        )}

                        {currentPage === "vendor" && selectedItem && (
                            <PrimaryButton
                                title={"Continue"}
                                type="white"
                                size="xl"
                                onClick={() => setCurrentPage("unit")}
                            />
                        )}

                        {currentPage === "vendor" && !selectedItem && (
                            <PrimaryButton
                                title={"Skip"}
                                type="gray"
                                size="xl"
                                onClick={() => setCurrentPage("unit")}
                            />
                        )}

                        {currentPage === "unit" && (
                            <PrimaryButton
                                title={"Continue"}
                                type="white"
                                size="xl"
                                onClick={() => setCurrentPage("traits")}
                            />
                        )}

                        {currentPage === "traits" && (
                            <PrimaryButton
                                title={"Create Item"}
                                type="primary"
                                size="xl"
                                onClick={() => props.onSubmit()}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CreateItemDialog