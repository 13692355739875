const StackedListBar = (props: { title: string, subtitle: string, datasets: { title: string, count: number, color: string, showLabel?: boolean }[], showBorder?: boolean }) => {
    const totalCount = props.datasets.reduce((a, b) => a + b.count, 0)

    return (
        <div style={{ borderWidth: props.showBorder !== false ? '1px' : '' }} className="flex flex-col border-neutral-2 bg-page rounded-md p-2 max-w-[730px] h-[90px] bg">
            <div style={{ paddingTop: props.showBorder !== false ? '4px' : '' }} className="flex pb-1">
                <p className="font-body">{props.title}</p>
                <p className="font-body text-neutral-5 ml-auto">{props.subtitle}</p>
            </div>

            <div className="pt-1 pb-2">
                <div className="flex w-full h-[21px] gap-[2px] bg-neutral-1 rounded-md overflow-hidden">
                    {props.datasets.filter((v) => v.count > 0).map((dataset, index) => {
                        return (
                            dataset.showLabel ?
                                <div style={{ width: `${dataset.count / totalCount * 100}%` }} className="font-caption-2 text-neutral-5 text-center my-auto">
                                    {dataset.title}
                                </div>
                                :
                                <div style={{ width: `${dataset.count / totalCount * 100}%` }} className={`bg-${dataset.color} ${index === 0 ? 'rounded-s-md' : index === props.datasets.length - 1 ? 'rounded-e-md' : ''}`}>

                                </div>
                        )
                    })}
                </div>

                <div className="flex py-1 gap-2">
                    {props.datasets.filter((dataset) => !dataset.showLabel).map((dataset) => {
                        return (
                            <div className="flex items-center font-caption-1 gap-1.5">
                                <div className={`h-2 w-2 bg-${dataset.color} rounded-full`}>

                                </div>

                                <p>{dataset.title}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default StackedListBar