const Toggle = (props: { 
    value?: boolean, 
    disabled?: boolean,
    onChange?: (value: boolean) => void
 }) => {
    return (
        <label style={{opacity: props.disabled ? '0.4' : '1'}} className="relative inline-flex items-center cursor-pointer">
            <input disabled={props.disabled} onChange={() => props.onChange?.(!(props.value || false))} type="checkbox" checked={props.value || false} className="sr-only peer" />
            <div className="w-9 h-5 bg-neutral-2 peer-focus:outline-none peer-focus:ring-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-gradient-to-t peer-checked:from-primary-4 peer-checked:to-primary-5"></div>
        </label>
    )
}

export default Toggle