import { useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"

const DatePickerDialog = (props: { onSelect: (date: Date) => void, onRemove: () => void, onDismiss: () => void }) => {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>()
    const [preselectedDate, setPreselectedDate] = useState<number | undefined>()
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth())

    const datesInMonth = getDatesInMonth(selectedYear, selectedMonth);

    const selectedMonthYear = new Date(selectedYear, selectedMonth, 1);

    function getDatesInMonth(year: number, month: number) {
        const firstDayOfMonth = new Date(year, month, 1);
        const startDate = new Date(year, month, 1);
        const endDate = new Date(year, month + 1, 0);

        const dates = [];

        if (2 - firstDayOfMonth.getDay() > 0) {
            startDate.setDate(2 - firstDayOfMonth.getDay() - 7);
        } else {
            startDate.setDate(2 - firstDayOfMonth.getDay());
        }

        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            dates.push(new Date(date));
        }

        const totalDates = dates.length >= 35 ? 42 : 35

        const startDateMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1);
        const endDateMonth = new Date(year, month + 1, totalDates - dates.length);

        for (let date = startDateMonth; date <= endDateMonth; date.setDate(date.getDate() + 1)) {
            dates.push(new Date(date));
        }

        return dates;
    }

    function changeMonth(nextMonth: boolean) {
        if (nextMonth) {
            setSelectedMonth(selectedMonth + 1)
        } else {
            if (selectedMonth === 1) {
                setSelectedYear(selectedYear - 1)
            }

            setSelectedMonth(selectedMonth - 1)
        }
    }

    return (
        <Dialog>
            <div className="flex flex-col relative w-[390px] rounded-md bg-white">
                <div className="p-5">
                    <div className="flex flex-col gap-1 border border-neutral-2 bg-[#F7F7F7] rounded-md p-2">
                        <p className="font-body font-semibold">Set Length</p>
                        <p className="font-subheadline text-neutral-5">Define the date when the substitute will expire. Once the substitute is expired, AVA will run the action you defined.</p>

                        <div className="h-[1px] bg-neutral-2 my-1"></div>

                        <div>
                            <div className="flex p-1 items-center">
                                <p className="font-title-3 font-semibold">{selectedMonthYear.toLocaleDateString(undefined, { year: 'numeric', month: 'long' })}</p>

                                <div className="flex ml-auto gap-6 mr-2">
                                    <img onClick={() => changeMonth(false)} className="h-[12px] cursor-pointer" src={'/images/icons/ic-chevron-left-blue.svg'}></img>
                                    <img onClick={() => changeMonth(true)} className="h-[12px] cursor-pointer" src={'/images/icons/ic-chevron-right-blue.svg'}></img>
                                </div>
                            </div>

                            <div className="grid grid-cols-7 -mx-1.5 mt-2">
                                <p className="w-full h-4 font-callout text-neutral-5 text-center">MON</p>
                                <p className="w-full h-4 font-callout text-neutral-5 text-center">TUE</p>
                                <p className="w-full h-4 font-callout text-neutral-5 text-center">WED</p>
                                <p className="w-full h-4 font-callout text-neutral-5 text-center">THU</p>
                                <p className="w-full h-4 font-callout text-neutral-5 text-center">FRI</p>
                                <p className="w-full h-4 font-callout text-neutral-5 text-center">SAT</p>
                                <p className="w-full h-4 font-callout text-neutral-5 text-center">SUN</p>

                                {datesInMonth.map((date) => (
                                    <div className={`w-full h-10 flex font-body items-center justify-center p-1`}>
                                        <div onClick={() => {
                                            setSelectedDate(date)
                                            setPreselectedDate(undefined)
                                        }} className={`${date.getDate() === selectedDate?.getDate() && date.getMonth() === selectedDate?.getMonth() && date.getFullYear() === selectedDate?.getFullYear() ? 'text-white bg-primary-4 font-semibold' : ''} flex h-full w-full rounded-full items-center justify-center`}>
                                            {(date.getMonth() >= selectedMonth) && (
                                                <button
                                                    key={date.toISOString()}
                                                    className={`cursor-pointer ${date.getDate() === selectedDate?.getDate() && date.getMonth() === selectedDate?.getMonth() && date.getFullYear() === selectedDate?.getFullYear() ? 'text-white' : date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear() ? 'text-primary-4' : date < new Date() ? 'text-neutral-3' : ''}`}
                                                >
                                                    {date.getDate()}
                                                </button>
                                            )}
                                        </div>

                                    </div>

                                ))}
                            </div>
                        </div>

                        <div className="h-[1px] bg-neutral-2 my-1"></div>

                        <div className="flex items-center py-1">
                            <p className="font-title-3">Predefined Months</p>

                            <div className="flex ml-auto">
                                <button onClick={() => {
                                    setSelectedDate(new Date(new Date().getFullYear(), new Date().getMonth() + 3, new Date().getDate()))

                                    if (preselectedDate === 3) {
                                        setPreselectedDate(undefined)
                                    } else {
                                        setPreselectedDate(3)
                                    }
                                }} className={`cursor-pointer ${preselectedDate === 3 ? 'bg-primary-4 text-white font-semibold' : ''} p-2 rounded-full w-10 font-body`}>3</button>

                                <button onClick={() => {
                                    setSelectedDate(new Date(new Date().getFullYear(), new Date().getMonth() + 6, new Date().getDate()))

                                    if (preselectedDate === 6) {
                                        setPreselectedDate(undefined)
                                    } else {
                                        setPreselectedDate(6)
                                    }
                                }} className={`cursor-pointer ${preselectedDate === 6 ? 'bg-primary-4 text-white font-semibold' : ''} p-2 rounded-full w-10 font-body`}>6</button>

                                <button onClick={() => {
                                    setSelectedDate(new Date(new Date().getFullYear(), new Date().getMonth() + 9, new Date().getDate()))

                                    if (preselectedDate === 9) {
                                        setPreselectedDate(undefined)
                                    } else {
                                        setPreselectedDate(9)
                                    }
                                }} className={`cursor-pointer ${preselectedDate === 9 ? 'bg-primary-4 text-white font-semibold' : ''} p-2 rounded-full w-10 font-body`}>9</button>

                                <button onClick={() => {

                                    setSelectedDate(new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()))
                                    if (preselectedDate === 12) {
                                        setPreselectedDate(undefined)
                                    } else {
                                        setPreselectedDate(12)
                                    }
                                }} className={`cursor-pointer ${preselectedDate === 12 ? 'bg-primary-4 text-white font-semibold' : ''} p-2 rounded-full w-10 font-body`}>12</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-auto h-[1px] bg-neutral-2"></div>

                <div className="flex px-5 h-[68px] items-center">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        size="md"
                        onClick={() => props.onDismiss()}
                    />

                    <div className="flex ml-auto gap-2">
                        <PrimaryButton
                            title="Remove Length"
                            type="gray"
                            size="md"
                            onClick={() => props.onRemove()}
                        />
                        <PrimaryButton
                            title="Set Date"
                            type="primary"
                            size="md"
                            onClick={() => props.onSelect(selectedDate || new Date())}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default DatePickerDialog