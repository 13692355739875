import { Link, useLocation } from "react-router-dom"

const SidebarNavigationLink = (props: { title: string, icon: string, hashLink: string, currentHashLink: string, subtitle?: string }) => {
    const location = useLocation()

    return (
        <Link to={props.hashLink} replace={true} state={{ previousLocationPathName: location.state?.previousLocationPathName || location.pathname }}>
            <div className={`w-full flex font-body gap-1.5 ${props.currentHashLink === props.hashLink ? 'bg-neutral-3' : ''} py-1.5 px-2 rounded-md`}>
                <img className="h-[16px] w-[16px]" src={`/images/icons/${props.icon}`}></img>
                <p>{props.title}</p>
                <p className="ml-auto">{props.subtitle}</p>
            </div>
        </Link>
    )
}

export default SidebarNavigationLink