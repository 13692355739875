import { useState, useEffect } from "react"

import { ChatMessage, Chatroom } from "../../model/chat/chat"
import sanitizeHtml from "sanitize-html"
import PollMessage from "../poll/poll"
import { FullMetadata, getBlob, getMetadata, ref } from "firebase/storage"
import { storage } from "../../firebase/firebase"
import Markdown from "react-markdown"
import BarChart, { ChartData } from "../barChart/barChart"

const Chat = (props: { message: ChatMessage, isSend: boolean, withTail: boolean, chatroom?: Chatroom, onAttachmentClick?: (url: string, metadata: FullMetadata | null) => void }) => {
    const [metadata, setMetadata] = useState<FullMetadata | null>(null)
    const [graphData, setGraphData] = useState<{
        count: number,
        color: string,
        title: string,

    }[]>([])

    useEffect(() => {
        if (props.message.type === 'attachment' && props.message.reference) {
            const storageRef = ref(storage, props.message.reference)

            getMetadata(storageRef)
                .then((metadata) => {
                    setMetadata(metadata)
                })
        }

        if (props.message.type === 'graph') {
            const removeHashtag = props.message.message.slice(4).slice(0, -4)
            const splitString = removeHashtag.split(',').map((v) => v.replace(/\s+/g, ''))

            const data: {
                count: number,
                color: string,
                title: string,

            }[] = splitString.map((v) => {
                return ({
                    count: parseInt(v.split(':')[1]),
                    title: v.split(':')[0],
                    color: 'caution'
                })
            })

            setGraphData(data)
        }
    }, [props.message])

    const highlightMentions = (text: string) => {
        const sanitizeConf = {
            allowedTags: ["b", "i", "a", "p", "span"],
            allowedAttributes: { a: ["href"] }
        }

        const sanitizedText = sanitizeHtml(text, sanitizeConf)

        if (props.isSend) {
            const replacedText = sanitizedText
                .replace(/\*{1,2}(.*?)\*{1,2}/g, '<strong>$1</strong>')
                .replace(/@(\S+)/g, '<span>@<span class="text-white text-bold">$1</span></span>')

            return replacedText
        } else {
            const replacedText = sanitizedText
                .replace(/\*{1,2}(.*?)\*{1,2}/g, '<strong>$1</strong>')
                .replace(/@(\S+)/g, '<span>@<span class="text-[#0F62FE] text-bold">$1</span></span>')

            return replacedText
        }
    }

    const download = async () => {
        if (props.message.reference) {
            const fileRef = ref(storage, props.message.reference)
            const blob = await getBlob(fileRef)
            const blobUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = blobUrl
            link.download = `${metadata?.name}`
            link.click()
        }
    }

    const formatFileSize = (bytes: number): string => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const formatFileType = (type: string): string => {
        switch (type) {
            case 'application/pdf':
                return 'PDF Document'
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'Word Document'
            default: return type
        }
    }

    const roundToNextSignificant = (num: number) => {
        if (num <= 0) return 1
        const orderOfMagnitude = Math.ceil(Math.log10(num));
        return Math.pow(10, orderOfMagnitude);
    }

    const maxGraphNumber = roundToNextSignificant(Math.max(...graphData.map((v) => v.count)))

    return (
        <>
            {(props.message.type === 'leave' || props.message.type === 'join') && (
                <p className="font-footnote text-neutral-5 mx-auto"><strong>{props.message.message.split(' ')[0]}</strong> {props.message.message.split(' ').splice(1).join(' ')}</p>
            )}

            {props.message.type === 'poll' && (
                <div className="flex flex-col gap-2">
                    <p className="font-footnote text-neutral-5 mx-auto"><strong>{props.message.message.split(' ').splice(0, 2).join(' ')}</strong> {props.message.message.split(' ').splice(2).join(' ')}</p>

                    {props.message.reference && props.chatroom && (
                        <PollMessage pollId={props.message.reference} chatroom={props.chatroom} />
                    )}
                </div>
            )}

            {props.message.type === 'text' && (
                <div className="relative flex">
                    {/* <Markdown className={`font-body p-chat px-3 py-2 ${props.isSend ? `send ml-auto bg-[#0F62FE]` : `receive bg-[#E1E1E2]`}`}>{props.message.message}</Markdown> */}
                    <p className={`whitespace-pre-wrap font-body p-chat px-3 py-2 ${props.isSend ? `send ml-auto bg-[#0F62FE]` : `receive bg-[#E1E1E2]`}`} dangerouslySetInnerHTML={{ __html: highlightMentions(props.message.message) }}></p>

                    {props.isSend ?
                        <img className="absolute w-[10px] h-[12px] right-[-5px] bottom-[0px]" src="/images/icons/ic-chat-send-tail.svg" />
                        :
                        <img className="absolute w-[10px] h-[12px] left-[-5px] bottom-[0px]" src="/images/icons/ic-chat-receive-tail.svg" />
                    }
                </div>
            )}

            {props.message.type === 'attachment' && (
                <div className={`cursor-pointer relative w-[264px] font-body ${props.isSend ? 'ml-auto' : ''}`}>
                    {['image/png', 'image/jpg', 'image/jpeg'].includes(metadata?.contentType || '') ?
                        <img onClick={() => props.onAttachmentClick?.(props.message.reference || '', metadata)} className=" rounded-[16px]" src={props.message.reference || ''} />
                        :
                        <div onClick={() => download()} className={`flex gap-4 items-center h-[80px] font-body px-3 py-2 ${props.isSend ? 'bg-[#0F62FE]' : 'bg-[#E1E1E2]'} rounded-[16px]`}>
                            <div className="flex items-center my-1 h-full bg-[#3379FB] border border-white/10 rounded-lg p-1 text-white">
                                <p className="text-white text-center w-[40px] line-clamp-1 text-ellipsis">{metadata?.name.split('.').at(-1)?.toUpperCase()}</p>
                            </div>

                            <div className={`flex flex-col gap-0 ${props.isSend ? 'text-white' : ''}`}>
                                <p className="font-callout text-ellipsis line-clamp-1">{metadata?.name}</p>
                                <p className="font-footnote line-clamp-3 text-ellipsis whitespace-normal break-words hyphenate">{`${formatFileType(metadata?.contentType || '')} • ${formatFileSize(metadata?.size || 0)}`}</p>
                            </div>
                        </div>
                    }



                    {props.isSend ?
                        <img className="absolute w-[10px] h-[12px] right-[-5px] bottom-0" src="/images/icons/ic-chat-send-tail.svg" />
                        :
                        <img className="absolute w-[10px] h-[12px] left-[-5px] bottom-0" src="/images/icons/ic-chat-receive-tail.svg" />
                    }
                </div>
            )}

            {props.message.type === 'graph' && (
                <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md px-1 pr-3 py-3">
                    <BarChart
                        datasets={[{
                            data: graphData.map((v) => v.count),
                            color: graphData[0]?.color || 'info'
                        }]}
                        breakpoints={[0, maxGraphNumber * 0.25, maxGraphNumber * 0.5, maxGraphNumber * 0.75, maxGraphNumber]}
                        labels={graphData.map((v) => v.title)}
                        legends={[]}
                        // legends={graphData.map((data) => {
                        //     return (
                        //         {
                        //             title: data.title,
                        //             // subtitle: `${.find((v) => v.id === medicalItem.id)?.weeklyWasted.slice(-6).reduce((a, b) => a + b, 0)} units`,
                        //             color: data.color
                        //         }
                        //     )
                        // })}
                        selectedLabel=""
                    />
                </div>
            )}
        </>
    )
}

export default Chat