import { useState } from "react"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import Toggle from "../toggle/toggle"

const SubscribeDialog = (props: {
    onSubmit: (subscribed: {
        health: {
            disrupted: boolean
            stable: boolean
            assigned: boolean
            disabled: boolean
            enabled: boolean
        },
        substitute: {
            newRequest: boolean
            accepted: boolean
            rejected: boolean
            begin: boolean
            end: boolean
        }
        order: {
            accepted: boolean
            filled: boolean
            hold: boolean
            disrupted: boolean
        }
        conversation: {
            new: boolean
            poll: boolean
            resolved: boolean
            linkedEntity: boolean
        }
    }) => void,
    onDismiss: () => void
}) => {
    const [currentPage, setCurrentPage] = useState('overview')
    const [currentSelectPage, setCurrentSelectPage] = useState(0)
    const [selectedAspects, setSelectedAspects] = useState<{
        health: boolean
        substitute: boolean
        order: boolean
        conversation: boolean
    }>({
        health: false,
        substitute: false,
        order: false,
        conversation: false
    })
    const [healthAlert, setHealthAlert] = useState<{ [key: string]: boolean }>({})
    const [substituteAlert, setSubstituteAlert] = useState<{ [key: string]: boolean }>({})
    const [conversationAlert, setConversationAlert] = useState<{ [key: string]: boolean }>({})
    const [orderAlert, setOrderAlert] = useState<{ [key: string]: boolean }>({})

    const selectedAspectPages = Object.entries(selectedAspects).filter((v) => v[1]).map((v) => v[0])

    return (
        <Dialog>
            <div className={`flex flex-col relative w-[800px] h-[540px] ${currentPage === "overview" ? "bg-[url('/public/images/backgrounds/bg-gradient-dialog.svg')]" : ""} bg-no-repeat bg-top rounded-md`}>

                <div className="flex flex-col p-5 mx-[120px]">
                    {currentPage === 'overview' && (
                        <>
                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[20px]">
                                <p className="font-large-title font-neutral-6 font-bold">Subscribe for Updates</p>
                                <p className="font-body font-neutral-5">You are about to subscribe to the item to stay up-to-date with item changes. Item changes are counted as following:</p>
                            </div>

                            <div className="flex flex-col gap-3">
                                <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                    <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                        <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-honeycomb.svg`}></img>
                                    </div>

                                    <div>
                                        <p className="font-subheadline font-semibold">Item Aspects</p>
                                        <p className="font-subheadline text-neutral-5">Substitute, procedure, utilisation, order and vendor</p>
                                    </div>

                                    <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                </div>

                                <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                    <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                        <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-pencil-line.svg`}></img>
                                    </div>

                                    <div>
                                        <p className="font-subheadline font-semibold">Specify Triggers</p>
                                        <p className="font-subheadline text-neutral-5">Individual changes from a specific aspect, like orders</p>
                                    </div>

                                    <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                </div>

                                <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                    <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                        <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-bubble-dot.svg`}></img>
                                    </div>

                                    <div>
                                        <p className="font-subheadline font-semibold">Conversation</p>
                                        <p className="font-subheadline text-neutral-5">New messages and direct mentions</p>
                                    </div>

                                    <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                </div>

                                <div className="flex bg-white rounded-md px-4 py-3 gap-4 items-center">
                                    <div className={`h-[26px] min-w-[26px] max-w-[26px] gradient-blue rounded-md flex items-center justify-center`}>
                                        <img className=" h-[16px] w-[16px]" src={`/images/icons/ic-signature-white.svg`}></img>
                                    </div>

                                    <div>
                                        <p className="font-subheadline font-semibold">Contracts</p>
                                        <p className="font-subheadline text-neutral-5">Substitute notification will be sent to all vendors</p>
                                    </div>

                                    <img className="ml-auto h-[16px] w-[16px]" src={`/images/icons/ic-info-circle.svg`}></img>
                                </div>
                            </div>
                        </>
                    )}

                    {currentPage === 'aspects' && (
                        <>
                            <img className="h-[70px] mt-[30px]" src={`/images/icons/ic-bell-dot-gradient.svg`}></img>

                            <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                <p className="font-large-title font-neutral-6 font-bold">Item Aspects</p>
                                <p className="font-title-3 text-neutral-5">You are about to subscribe to the item to stay up-to-date with item changes. Item changes are counted as following:</p>
                            </div>
                        </>
                    )}

                    {currentPage !== 'overview' && currentPage !== 'aspects' && (
                        <>
                            {currentPage === 'health' && (
                                <>
                                    <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                        <p className="font-large-title font-neutral-6 font-bold">Health</p>
                                        <p className="font-title-3 text-neutral-5">Here are the causes for which you'll receive email alert. If any of the following is selected, an alert will be triggered:</p>
                                    </div>

                                    <div className="flex flex-col gap-2.5 overflow-y-auto h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md p-2.5">
                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Disrupted</p>
                                                <p className="font-subheadline text-neutral-5">Item is now disrupted</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={healthAlert['disrupted'] || false} onChange={() => setHealthAlert({ ...healthAlert, 'disrupted': !(healthAlert['disrupted'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Stable</p>
                                                <p className="font-subheadline text-neutral-5">Item is no longer disrupted</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={healthAlert['stable'] || false} onChange={() => setHealthAlert({ ...healthAlert, 'stable': !(healthAlert['stable'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Assigned</p>
                                                <p className="font-subheadline text-neutral-5">Item is now assigned to John Appleseed</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={healthAlert['assigned'] || false} onChange={() => setHealthAlert({ ...healthAlert, 'assigned': !(healthAlert['assigned'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Disabled</p>
                                                <p className="font-subheadline text-neutral-5">Item is no longer used in hospital</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={healthAlert['disabled'] || false} onChange={() => setHealthAlert({ ...healthAlert, 'disabled': !(healthAlert['disabled'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Enabled</p>
                                                <p className="font-subheadline text-neutral-5">Item can be now used in the hospital</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={healthAlert['enabled'] || false} onChange={() => setHealthAlert({ ...healthAlert, 'enabled': !(healthAlert['enabled'] || false) })} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentPage === 'substitute' && (
                                <>
                                    <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                        <p className="font-large-title font-neutral-6 font-bold">Substitute</p>
                                        <p className="font-title-3 text-neutral-5">Here are the causes for which you'll receive email alert. If any of the following is selected, an alert will be triggered:</p>
                                    </div>

                                    <div className="flex flex-col gap-2.5 overflow-y-auto h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md p-2.5">
                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">New Request</p>
                                                <p className="font-subheadline text-neutral-5">Item has new substitute request</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={substituteAlert['new'] || false} onChange={() => setSubstituteAlert({ ...substituteAlert, 'new': !(substituteAlert['new'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Accepted</p>
                                                <p className="font-subheadline text-neutral-5">Substitute request is accepted</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={substituteAlert['accepted'] || false} onChange={() => setSubstituteAlert({ ...substituteAlert, 'accepted': !(substituteAlert['accepted'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Rejected</p>
                                                <p className="font-subheadline text-neutral-5">Substitute request is rejected</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={substituteAlert['rejected'] || false} onChange={() => setSubstituteAlert({ ...substituteAlert, 'rejected': !(substituteAlert['rejected'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Begin Substitution</p>
                                                <p className="font-subheadline text-neutral-5">Substitute is currently being used as substitute</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={substituteAlert['begin'] || false} onChange={() => setSubstituteAlert({ ...substituteAlert, 'begin': !(substituteAlert['begin'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">End Substitution</p>
                                                <p className="font-subheadline text-neutral-5">Substitute is no longer used as substitute</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={substituteAlert['end'] || false} onChange={() => setSubstituteAlert({ ...substituteAlert, 'end': !(substituteAlert['end'] || false) })} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentPage === 'order' && (
                                <>
                                    <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                        <p className="font-large-title font-neutral-6 font-bold">Order</p>
                                        <p className="font-title-3 text-neutral-5">Here are the causes for which you'll receive email alert. If any of the following is selected, an alert will be triggered:</p>
                                    </div>

                                    <div className="flex flex-col gap-2.5 overflow-y-auto h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md p-2.5">
                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Accepted</p>
                                                <p className="font-subheadline text-neutral-5">Vendor accepted the order</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={orderAlert['accepted'] || false} onChange={() => setOrderAlert({ ...orderAlert, 'accepted': !(orderAlert['accepted'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Filled</p>
                                                <p className="font-subheadline text-neutral-5">Vendor filled the order</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={orderAlert['filled'] || false} onChange={() => setOrderAlert({ ...orderAlert, 'filled': !(orderAlert['filled'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Hold</p>
                                                <p className="font-subheadline text-neutral-5">Vendor put the order on hold</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={orderAlert['hold'] || false} onChange={() => setOrderAlert({ ...orderAlert, 'hold': !(orderAlert['hold'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Disrupted</p>
                                                <p className="font-subheadline text-neutral-5">Order is disrupted</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={orderAlert['disrupted'] || false} onChange={() => setOrderAlert({ ...orderAlert, 'disrupted': !(orderAlert['disrupted'] || false) })} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentPage === 'conversation' && (
                                <>
                                    <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                        <p className="font-large-title font-neutral-6 font-bold">Conversation</p>
                                        <p className="font-title-3 text-neutral-5">Here are the causes for which you'll receive email alert. If any of the following is selected, an alert will be triggered:</p>
                                    </div>

                                    <div className="flex flex-col gap-2.5 overflow-y-auto h-[270px] bg-[#F3F3F3] border-neutral-2 border rounded-md p-2.5">
                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">New</p>
                                                <p className="font-subheadline text-neutral-5">John Appleseed created a conversation</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={conversationAlert['new'] || false} onChange={() => setConversationAlert({ ...conversationAlert, 'new': !(conversationAlert['new'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Poll</p>
                                                <p className="font-subheadline text-neutral-5">John Appleseed created a poll</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={conversationAlert['poll'] || false} onChange={() => setConversationAlert({ ...conversationAlert, 'poll': !(conversationAlert['poll'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Resolved</p>
                                                <p className="font-subheadline text-neutral-5">John Appleseed resolved conversation</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={conversationAlert['resolved'] || false} onChange={() => setConversationAlert({ ...conversationAlert, 'resolved': !(conversationAlert['resolved'] || false) })} />
                                            </div>
                                        </div>

                                        <div className="flex bg-white rounded-md border border-neutral-2 px-3 py-2.5 items-center">
                                            <div>
                                                <p className="font-body">Linked Entity</p>
                                                <p className="font-subheadline text-neutral-5">John Appleseed linked an item in the conversation</p>
                                            </div>

                                            <div className="flex items-center ml-auto">
                                                <Toggle value={conversationAlert['linked'] || false} onChange={() => setConversationAlert({ ...conversationAlert, 'linked': !(conversationAlert['linked'] || false) })} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>

                {currentPage === 'aspects' && (
                    <div className="flex gap-3 items-center justify-center">
                        <div onClick={() => setSelectedAspects({ ...selectedAspects, health: !selectedAspects.health })} className="cursor-pointer relative w-[118px] h-[118px] flex flex-col items-center justify-center gap-3 bg-white rounded-md border-2 border-neutral-2">
                            <img className="h-[36px] w-[36px]" src={`/images/icons/ic-health-gray.svg`}></img>
                            <p className="font-body">Health</p>

                            {selectedAspects.health && (
                                <img className="absolute top-2 right-2 h-[20px] w-[20px]" src={`/images/icons/ic-checkmark-circle-fill-green.svg`}></img>
                            )}
                        </div>

                        <div onClick={() => setSelectedAspects({ ...selectedAspects, substitute: !selectedAspects.substitute })} className="cursor-pointer relative w-[118px] h-[118px] flex flex-col items-center justify-center gap-3 bg-white rounded-md border-2 border-neutral-2">
                            <img className="h-[36px] w-[36px]" src={`/images/icons/ic-eye-gray.svg`}></img>
                            <p className="font-body">Substitute</p>

                            {selectedAspects.substitute && (
                                <img className="absolute top-2 right-2 h-[20px] w-[20px]" src={`/images/icons/ic-checkmark-circle-fill-green.svg`}></img>
                            )}
                        </div>

                        <div onClick={() => setSelectedAspects({ ...selectedAspects, order: !selectedAspects.order })} className="cursor-pointer relative w-[118px] h-[118px] flex flex-col items-center justify-center gap-3 bg-white rounded-md border-2 border-neutral-2">
                            <img className="h-[36px] w-[36px]" src={`/images/icons/ic-credit-card-grey.svg`}></img>
                            <p className="font-body">Order</p>

                            {selectedAspects.order && (
                                <img className="absolute top-2 right-2 h-[20px] w-[20px]" src={`/images/icons/ic-checkmark-circle-fill-green.svg`}></img>
                            )}
                        </div>

                        <div onClick={() => setSelectedAspects({ ...selectedAspects, conversation: !selectedAspects.conversation })} className="cursor-pointer relative w-[118px] h-[118px] flex flex-col items-center justify-center gap-3 bg-white rounded-md border-2 border-neutral-2">
                            <img className="h-[36px] w-[36px]" src={`/images/icons/ic-message-bubble-badge-gray.svg`}></img>
                            <p className="font-body">Conversation</p>

                            {selectedAspects.conversation && (
                                <img className="absolute top-2 right-2 h-[20px] w-[20px]" src={`/images/icons/ic-checkmark-circle-fill-green.svg`}></img>
                            )}
                        </div>
                    </div>

                )}

                <div className="mt-auto h-[1px] bg-neutral-2"></div>

                <div className="flex p-5 h-[70px]">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        onClick={() => props.onDismiss()}
                    />

                    <div className="flex gap-3 ml-auto">
                        {currentPage === 'overview' && (
                            <PrimaryButton
                                title="Specify Aspects"
                                type="white"
                                onClick={() => {
                                    setCurrentPage('aspects')
                                }}
                            />
                        )}

                        {currentPage !== 'overview' && (
                            <>
                                <PrimaryButton
                                    title="Back"
                                    type="gray"
                                    onClick={() => {
                                        switch (currentPage) {
                                            case 'aspects':
                                                setCurrentPage('overview')
                                                break
                                            default:
                                                if (currentSelectPage === 0) {
                                                    setCurrentPage('aspects')
                                                } else {
                                                    setCurrentPage(selectedAspectPages[currentSelectPage - 1])
                                                    setCurrentSelectPage(currentSelectPage - 1)
                                                }
                                                break
                                        }
                                    }}
                                />

                                <PrimaryButton
                                    title={selectedAspectPages.length - 1 === currentSelectPage && currentPage !== 'aspects' ? "Subscribe" : "Continue"}
                                    type={selectedAspectPages.length - 1 === currentSelectPage && currentPage !== 'aspects' ? "primary" : "white"}
                                    disabled={!Object.values(selectedAspects).some((v) => v)}
                                    onClick={() => {
                                        switch (currentPage) {
                                            case 'aspects':
                                                setCurrentSelectPage(0)
                                                setCurrentPage(selectedAspectPages[0])
                                                break
                                            default:
                                                if (selectedAspectPages.length - 1 === currentSelectPage) {
                                                    props.onSubmit({
                                                        health: {
                                                            disrupted: healthAlert['disrupted'] || false,
                                                            stable: healthAlert['stable'] || false,
                                                            assigned: healthAlert['assigned'] || false,
                                                            disabled: healthAlert['disabled'] || false,
                                                            enabled: healthAlert['enabled'] || false,
                                                        },
                                                        substitute: {
                                                            newRequest: substituteAlert['new'] || false,
                                                            accepted: substituteAlert['accepted'] || false,
                                                            rejected: substituteAlert['rejected'] || false,
                                                            begin: substituteAlert['begin'] || false,
                                                            end: substituteAlert['end'] || false,
                                                        },
                                                        order: {
                                                            accepted: orderAlert['accepted'] || false,
                                                            filled: orderAlert['filled'] || false,
                                                            hold: orderAlert['hold'] || false,
                                                            disrupted: orderAlert['disrupted'] || false,
                                                        },
                                                        conversation: {
                                                            new: conversationAlert['new'] || false,
                                                            poll: conversationAlert['poll'] || false,
                                                            resolved: conversationAlert['resolved'] || false,
                                                            linkedEntity: conversationAlert['linked'] || false,
                                                        }
                                                    })
                                                } else {
                                                    setCurrentPage(selectedAspectPages[currentSelectPage + 1])
                                                    setCurrentSelectPage(currentSelectPage + 1)
                                                }

                                                break
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default SubscribeDialog