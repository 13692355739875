import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ hasAccess, redirect, children}: { hasAccess: boolean, redirect: string, children: JSX.Element }): JSX.Element => {
    if (!hasAccess) {
      return <Navigate to={redirect} replace />;
    }
  
    return children;
  };

  export default ProtectedRoute