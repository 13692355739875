export interface MedicalItemUsage {
    id: string
    weeklyUsage: number[],
    weeklyWasted: number[]
  }

export const mockMedicalItemUsages = [
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        8,
        42,
        97,
        78,
        0,
        7,
        140,
        94,
        116,
        19,
        78,
        91,
        198,
        29,
        135,
        114,
        70,
        188,
        15,
        197,
        134,
        4,
        138,
        195,
        84,
        1,
        109,
        17,
        151,
        53,
        166,
        60,
        57,
        199,
        65,
        35,
        198,
        57,
        199,
        0,
        113,
        197,
        173,
        11,
        124,
        150,
        0,
        33,
        74,
        132,
        197,
        61
      ],
      "weeklyWasted": [
        65,
        35,
        198,
        57,
        199,
        0,
        113,
        197,
        173,
        11,
        124,
        150,
        0,
        33,
        74,
        132,
        197,
        61,
        80,
        14,
        15,
        53,
        98,
        45,
        36,
        22,
        75,
        99,
        99,
        92,
        43,
        42,
        0,
        57,
        98,
        82,
        25,
        65,
        47,
        49,
        25,
        0,
        61,
        65,
        54,
        59,
        2,
        46,
        69,
        77,
        43,
        70,
        99,
        40,
        87,
        0,
        87,
        83,
        99,
        59,
        99,
        81,
        66,
        48,
        49,
        36,
        28,
        90,
        61,
        14
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        166,
        72,
        198,
        48,
        0,
        197,
        12,
        197,
        199,
        26,
        92,
        69,
        91,
        75,
        1,
        67,
        192,
        189,
        3,
        17,
        197,
        198,
        83,
        11,
        197,
        145,
        135,
        122,
        160,
        82,
        118,
        0,
        118,
        50,
        146,
        24,
        152,
        68,
        106,
        29,
        129,
        130,
        80,
        33,
        78,
        117,
        59,
        53,
        198,
        176,
        121,
        3
      ],
      "weeklyWasted": [
        146,
        24,
        152,
        68,
        106,
        29,
        129,
        130,
        80,
        33,
        78,
        117,
        59,
        53,
        198,
        176,
        121,
        3,
        1,
        3,
        91,
        34,
        98,
        98,
        7,
        38,
        91,
        10,
        88,
        47,
        73,
        92,
        57,
        19,
        0,
        36,
        23,
        8,
        2,
        0,
        61,
        0,
        64,
        66,
        73,
        26,
        2,
        40,
        42,
        84,
        1,
        98,
        86,
        31,
        56,
        41,
        50,
        99,
        0,
        61,
        85,
        1,
        46,
        58,
        99,
        84,
        47,
        99,
        99,
        33
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        118,
        2,
        97,
        5,
        11,
        97,
        3,
        81,
        2,
        40,
        199,
        125,
        104,
        81,
        199,
        3,
        192,
        80,
        197,
        54,
        55,
        102,
        177,
        89,
        66,
        112,
        108,
        1,
        0,
        121,
        99,
        58,
        1,
        73,
        198,
        190,
        143,
        199,
        10,
        33,
        0,
        117,
        0,
        0,
        46,
        196,
        147,
        19,
        134,
        199,
        40,
        18
      ],
      "weeklyWasted": [
        198,
        190,
        143,
        199,
        10,
        33,
        0,
        117,
        0,
        0,
        46,
        196,
        147,
        19,
        134,
        199,
        40,
        18,
        1,
        47,
        54,
        39,
        49,
        1,
        81,
        0,
        47,
        0,
        17,
        50,
        1,
        87,
        97,
        45,
        13,
        5,
        2,
        67,
        48,
        0,
        27,
        45,
        45,
        34,
        98,
        73,
        81,
        36,
        2,
        81,
        14,
        33,
        0,
        0,
        8,
        47,
        75,
        1,
        0,
        80,
        99,
        82,
        99,
        46,
        50,
        54,
        99,
        92,
        28,
        1
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        80,
        61,
        0,
        5,
        55,
        123,
        165,
        191,
        170,
        101,
        100,
        197,
        152,
        32,
        48,
        112,
        121,
        80,
        197,
        75,
        125,
        165,
        164,
        61,
        3,
        120,
        141,
        109,
        174,
        75,
        87,
        13,
        93,
        153,
        156,
        28,
        140,
        40,
        103,
        93,
        63,
        199,
        92,
        41,
        75,
        104,
        28,
        28,
        95,
        136,
        103,
        73
      ],
      "weeklyWasted": [
        156,
        28,
        140,
        40,
        103,
        93,
        63,
        199,
        92,
        41,
        75,
        104,
        28,
        28,
        95,
        136,
        103,
        73,
        1,
        3,
        18,
        21,
        98,
        45,
        54,
        0,
        63,
        51,
        81,
        79,
        53,
        67,
        39,
        52,
        0,
        99,
        9,
        99,
        2,
        9,
        20,
        0,
        71,
        53,
        23,
        46,
        44,
        1,
        54,
        78,
        43,
        98,
        0,
        13,
        94,
        51,
        48,
        99,
        99,
        52,
        0,
        76,
        61,
        41,
        16,
        36,
        65,
        99,
        44,
        37
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        197,
        2,
        117,
        28,
        44,
        1,
        97,
        154,
        2,
        95,
        100,
        33,
        123,
        27,
        1,
        172,
        125,
        44,
        18,
        91,
        59,
        69,
        103,
        71,
        197,
        106,
        118,
        112,
        0,
        100,
        0,
        0,
        2,
        120,
        97,
        199,
        117,
        48,
        82,
        0,
        62,
        0,
        82,
        0,
        1,
        157,
        36,
        0,
        198,
        140,
        65,
        3
      ],
      "weeklyWasted": [
        97,
        199,
        117,
        48,
        82,
        0,
        62,
        0,
        82,
        0,
        1,
        157,
        36,
        0,
        198,
        140,
        65,
        3,
        7,
        45,
        42,
        2,
        0,
        27,
        59,
        0,
        99,
        0,
        40,
        22,
        21,
        75,
        0,
        56,
        42,
        99,
        19,
        20,
        73,
        44,
        87,
        90,
        99,
        37,
        56,
        30,
        24,
        25,
        2,
        97,
        28,
        71,
        99,
        6,
        42,
        56,
        51,
        99,
        0,
        19,
        77,
        83,
        99,
        0,
        99,
        58,
        89,
        89,
        64,
        1
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        137,
        85,
        26,
        14,
        31,
        14,
        191,
        111,
        82,
        1,
        104,
        70,
        136,
        199,
        124,
        160,
        0,
        195,
        5,
        189,
        158,
        4,
        32,
        111,
        46,
        132,
        125,
        95,
        199,
        198,
        134,
        106,
        27,
        199,
        41,
        99,
        142,
        9,
        167,
        49,
        0,
        168,
        17,
        0,
        87,
        161,
        0,
        110,
        198,
        199,
        186,
        117
      ],
      "weeklyWasted": [
        41,
        99,
        142,
        9,
        167,
        49,
        0,
        168,
        17,
        0,
        87,
        161,
        0,
        110,
        198,
        199,
        186,
        117,
        96,
        1,
        0,
        66,
        82,
        86,
        0,
        0,
        99,
        0,
        0,
        49,
        60,
        71,
        33,
        51,
        57,
        25,
        60,
        66,
        9,
        40,
        47,
        20,
        64,
        88,
        59,
        41,
        29,
        56,
        94,
        79,
        60,
        66,
        20,
        12,
        60,
        90,
        82,
        97,
        40,
        0,
        94,
        99,
        76,
        4,
        96,
        87,
        0,
        39,
        43,
        13
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        75,
        6,
        125,
        111,
        72,
        11,
        108,
        38,
        2,
        127,
        199,
        100,
        180,
        163,
        192,
        141,
        40,
        63,
        161,
        40,
        147,
        85,
        107,
        13,
        59,
        97,
        3,
        110,
        0,
        198,
        0,
        67,
        110,
        121,
        198,
        0,
        131,
        5,
        71,
        91,
        122,
        0,
        59,
        0,
        1,
        57,
        67,
        23,
        126,
        107,
        2,
        122
      ],
      "weeklyWasted": [
        198,
        0,
        131,
        5,
        71,
        91,
        122,
        0,
        59,
        0,
        1,
        57,
        67,
        23,
        126,
        107,
        2,
        122,
        46,
        1,
        33,
        34,
        15,
        5,
        72,
        0,
        73,
        48,
        99,
        91,
        99,
        99,
        0,
        46,
        98,
        77,
        50,
        32,
        29,
        52,
        1,
        0,
        33,
        99,
        63,
        97,
        78,
        2,
        2,
        64,
        34,
        98,
        67,
        39,
        28,
        0,
        59,
        71,
        52,
        98,
        99,
        53,
        99,
        38,
        62,
        53,
        0,
        17,
        99,
        13
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        26,
        2,
        198,
        5,
        192,
        50,
        164,
        3,
        2,
        1,
        0,
        124,
        2,
        0,
        78,
        167,
        174,
        199,
        42,
        73,
        35,
        84,
        162,
        116,
        114,
        101,
        197,
        70,
        75,
        107,
        36,
        88,
        1,
        4,
        149,
        199,
        133,
        49,
        10,
        0,
        43,
        0,
        143,
        20,
        50,
        196,
        29,
        169,
        198,
        94,
        2,
        61
      ],
      "weeklyWasted": [
        149,
        199,
        133,
        49,
        10,
        0,
        43,
        0,
        143,
        20,
        50,
        196,
        29,
        169,
        198,
        94,
        2,
        61,
        99,
        16,
        23,
        5,
        85,
        42,
        89,
        73,
        79,
        83,
        46,
        1,
        54,
        60,
        60,
        98,
        73,
        64,
        99,
        99,
        45,
        16,
        97,
        29,
        29,
        99,
        98,
        66,
        55,
        38,
        2,
        60,
        50,
        98,
        99,
        87,
        66,
        55,
        99,
        35,
        12,
        32,
        35,
        35,
        99,
        99,
        99,
        98,
        66,
        8,
        48,
        41
      ]
    },
    {
      "id": "965102D9",
      "weeklyUsage": [
        64,
        2,
        109,
        88,
        95,
        197,
        118,
        11,
        93,
        1,
        74,
        38,
        198,
        161,
        199,
        54,
        192,
        151,
        3,
        197,
        171,
        53,
        93,
        47,
        3,
        56,
        95,
        1,
        54,
        78,
        3,
        153,
        62,
        122,
        148,
        199,
        72,
        172,
        10,
        144,
        0,
        199,
        0,
        63,
        1,
        141,
        192,
        37,
        198,
        16,
        52,
        3
      ],
      "weeklyWasted": [
        148,
        199,
        72,
        172,
        10,
        144,
        0,
        199,
        0,
        63,
        1,
        141,
        192,
        37,
        198,
        16,
        52,
        3,
        99,
        42,
        47,
        2,
        88,
        57,
        76,
        57,
        72,
        84,
        54,
        47,
        73,
        95,
        49,
        93,
        0,
        92,
        93,
        67,
        55,
        49,
        46,
        99,
        75,
        44,
        84,
        40,
        38,
        17,
        46,
        53,
        54,
        98,
        69,
        54,
        40,
        37,
        48,
        62,
        0,
        41,
        86,
        65,
        96,
        0,
        55,
        29,
        69,
        99,
        35,
        25
      ]
    },
    {
      "id": "3D26994F",
      "weeklyUsage": [
        160,
        66,
        15,
        60,
        0,
        192,
        44,
        181,
        69,
        32,
        97,
        41,
        118,
        153,
        197,
        3,
        45,
        178,
        3,
        12,
        197,
        31,
        16,
        78,
        154,
        34,
        184,
        50,
        53,
        105,
        199,
        0,
        5,
        4,
        80,
        135,
        98,
        18,
        131,
        0,
        102,
        0,
        118,
        34,
        117,
        121,
        0,
        61,
        95,
        125,
        8,
        39
      ],
      "weeklyWasted": [
        80,
        135,
        98,
        18,
        131,
        0,
        102,
        0,
        118,
        34,
        117,
        121,
        0,
        61,
        95,
        125,
        8,
        39,
        3,
        27,
        0,
        29,
        49,
        98,
        59,
        0,
        91,
        0,
        86,
        44,
        78,
        14,
        84,
        6,
        30,
        84,
        1,
        68,
        65,
        0,
        33,
        0,
        31,
        99,
        0,
        66,
        96,
        44,
        10,
        97,
        32,
        76,
        91,
        0,
        83,
        99,
        99,
        76,
        0,
        55,
        32,
        67,
        81,
        77,
        99,
        64,
        77,
        97,
        24,
        56
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        119,
        65,
        39,
        5,
        0,
        4,
        70,
        197,
        40,
        57,
        120,
        2,
        87,
        39,
        1,
        170,
        143,
        152,
        166,
        111,
        33,
        43,
        5,
        57,
        4,
        1,
        68,
        107,
        101,
        60,
        138,
        133,
        1,
        172,
        175,
        133,
        117,
        168,
        62,
        135,
        0,
        109,
        71,
        0,
        68,
        156,
        0,
        40,
        182,
        49,
        197,
        106
      ],
      "weeklyWasted": [
        175,
        133,
        117,
        168,
        62,
        135,
        0,
        109,
        71,
        0,
        68,
        156,
        0,
        40,
        182,
        49,
        197,
        106,
        1,
        24,
        22,
        2,
        98,
        98,
        99,
        31,
        28,
        17,
        66,
        86,
        72,
        41,
        49,
        52,
        20,
        99,
        99,
        99,
        2,
        91,
        23,
        1,
        28,
        99,
        53,
        44,
        57,
        32,
        9,
        97,
        40,
        97,
        33,
        8,
        61,
        64,
        38,
        85,
        39,
        49,
        99,
        74,
        95,
        7,
        92,
        98,
        71,
        86,
        39,
        1
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        117,
        2,
        77,
        19,
        198,
        126,
        127,
        54,
        2,
        1,
        199,
        86,
        198,
        91,
        197,
        3,
        192,
        199,
        115,
        2,
        54,
        21,
        81,
        65,
        197,
        100,
        9,
        83,
        36,
        138,
        90,
        41,
        32,
        76,
        81,
        174,
        78,
        179,
        11,
        81,
        95,
        199,
        53,
        0,
        1,
        137,
        142,
        56,
        198,
        176,
        130,
        46
      ],
      "weeklyWasted": [
        81,
        174,
        78,
        179,
        11,
        81,
        95,
        199,
        53,
        0,
        1,
        137,
        142,
        56,
        198,
        176,
        130,
        46,
        1,
        10,
        65,
        2,
        0,
        47,
        64,
        0,
        99,
        10,
        99,
        34,
        52,
        27,
        5,
        1,
        43,
        48,
        1,
        39,
        29,
        0,
        61,
        5,
        70,
        26,
        0,
        72,
        2,
        8,
        3,
        97,
        23,
        74,
        67,
        64,
        72,
        99,
        39,
        99,
        76,
        95,
        80,
        71,
        99,
        48,
        99,
        43,
        14,
        69,
        51,
        17
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        7,
        2,
        190,
        68,
        75,
        118,
        194,
        4,
        141,
        1,
        70,
        74,
        141,
        24,
        130,
        154,
        167,
        140,
        46,
        2,
        197,
        127,
        21,
        118,
        64,
        134,
        189,
        190,
        158,
        9,
        113,
        119,
        1,
        51,
        166,
        0,
        0,
        161,
        199,
        67,
        66,
        2,
        0,
        0,
        104,
        196,
        152,
        198,
        198,
        184,
        97,
        3
      ],
      "weeklyWasted": [
        166,
        0,
        0,
        161,
        199,
        67,
        66,
        2,
        0,
        0,
        104,
        196,
        152,
        198,
        198,
        184,
        97,
        3,
        24,
        3,
        69,
        2,
        88,
        30,
        22,
        0,
        75,
        54,
        73,
        50,
        18,
        3,
        0,
        39,
        0,
        54,
        4,
        29,
        76,
        87,
        97,
        63,
        41,
        99,
        63,
        43,
        99,
        82,
        40,
        86,
        26,
        98,
        57,
        99,
        65,
        41,
        0,
        91,
        15,
        73,
        99,
        99,
        45,
        40,
        99,
        70,
        69,
        99,
        49,
        1
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        199,
        101,
        143,
        5,
        45,
        54,
        139,
        49,
        69,
        28,
        39,
        126,
        99,
        59,
        118,
        78,
        192,
        21,
        3,
        98,
        187,
        20,
        148,
        127,
        3,
        198,
        176,
        165,
        199,
        30,
        36,
        196,
        1,
        4,
        86,
        83,
        59,
        5,
        198,
        127,
        119,
        35,
        62,
        165,
        1,
        4,
        23,
        135,
        152,
        56,
        93,
        24
      ],
      "weeklyWasted": [
        86,
        83,
        59,
        5,
        198,
        127,
        119,
        35,
        62,
        165,
        1,
        4,
        23,
        135,
        152,
        56,
        93,
        24,
        1,
        76,
        99,
        19,
        83,
        38,
        0,
        0,
        39,
        0,
        75,
        50,
        33,
        51,
        82,
        6,
        1,
        67,
        1,
        50,
        44,
        5,
        20,
        0,
        56,
        51,
        98,
        69,
        75,
        1,
        2,
        36,
        46,
        63,
        56,
        99,
        46,
        66,
        91,
        76,
        46,
        94,
        75,
        96,
        99,
        61,
        42,
        98,
        40,
        73,
        0,
        59
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        149,
        2,
        13,
        5,
        0,
        1,
        89,
        86,
        2,
        49,
        199,
        89,
        27,
        100,
        114,
        117,
        75,
        71,
        169,
        2,
        160,
        198,
        154,
        193,
        39,
        76,
        197,
        180,
        191,
        153,
        76,
        74,
        65,
        198,
        198,
        53,
        143,
        64,
        197,
        94,
        199,
        26,
        115,
        35,
        91,
        50,
        0,
        0,
        182,
        2,
        155,
        98
      ],
      "weeklyWasted": [
        198,
        53,
        143,
        64,
        197,
        94,
        199,
        26,
        115,
        35,
        91,
        50,
        0,
        0,
        182,
        2,
        155,
        98,
        43,
        1,
        26,
        2,
        59,
        1,
        68,
        7,
        79,
        35,
        34,
        27,
        1,
        99,
        0,
        59,
        64,
        82,
        26,
        81,
        73,
        0,
        30,
        0,
        87,
        44,
        22,
        40,
        96,
        53,
        37,
        93,
        63,
        98,
        99,
        0,
        61,
        65,
        46,
        54,
        0,
        48,
        99,
        60,
        51,
        62,
        38,
        98,
        99,
        46,
        44,
        44
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        193,
        65,
        198,
        74,
        127,
        1,
        3,
        128,
        139,
        1,
        136,
        127,
        133,
        86,
        160,
        163,
        87,
        169,
        3,
        85,
        155,
        36,
        142,
        173,
        197,
        97,
        3,
        77,
        173,
        195,
        83,
        37,
        51,
        166,
        4,
        165,
        195,
        60,
        151,
        63,
        73,
        100,
        0,
        23,
        54,
        57,
        25,
        80,
        198,
        199,
        19,
        62
      ],
      "weeklyWasted": [
        4,
        165,
        195,
        60,
        151,
        63,
        73,
        100,
        0,
        23,
        54,
        57,
        25,
        80,
        198,
        199,
        19,
        62,
        93,
        58,
        49,
        22,
        96,
        86,
        61,
        0,
        51,
        0,
        82,
        1,
        31,
        3,
        75,
        93,
        30,
        56,
        1,
        42,
        44,
        92,
        0,
        90,
        19,
        75,
        65,
        42,
        34,
        67,
        84,
        69,
        47,
        98,
        99,
        8,
        84,
        33,
        6,
        72,
        24,
        47,
        99,
        37,
        15,
        49,
        42,
        49,
        47,
        1,
        59,
        34
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        133,
        2,
        115,
        63,
        179,
        189,
        109,
        189,
        52,
        129,
        199,
        130,
        198,
        0,
        100,
        100,
        94,
        136,
        90,
        144,
        98,
        94,
        2,
        125,
        48,
        166,
        165,
        65,
        110,
        17,
        105,
        0,
        195,
        176,
        4,
        20,
        46,
        40,
        109,
        27,
        128,
        59,
        0,
        138,
        114,
        14,
        127,
        198,
        198,
        199,
        97,
        157
      ],
      "weeklyWasted": [
        4,
        20,
        46,
        40,
        109,
        27,
        128,
        59,
        0,
        138,
        114,
        14,
        127,
        198,
        198,
        199,
        97,
        157,
        42,
        1,
        93,
        5,
        98,
        40,
        99,
        0,
        26,
        40,
        97,
        46,
        28,
        14,
        75,
        1,
        98,
        99,
        48,
        0,
        65,
        0,
        26,
        88,
        0,
        80,
        98,
        61,
        82,
        36,
        19,
        97,
        43,
        98,
        99,
        69,
        14,
        60,
        44,
        1,
        33,
        5,
        12,
        25,
        22,
        35,
        87,
        92,
        15,
        99,
        15,
        1
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        162,
        2,
        198,
        74,
        0,
        110,
        3,
        90,
        2,
        62,
        199,
        90,
        114,
        0,
        199,
        149,
        173,
        22,
        71,
        197,
        126,
        166,
        17,
        68,
        197,
        61,
        167,
        22,
        199,
        17,
        134,
        103,
        89,
        75,
        83,
        0,
        16,
        199,
        143,
        74,
        0,
        140,
        143,
        44,
        1,
        134,
        155,
        89,
        198,
        15,
        124,
        3
      ],
      "weeklyWasted": [
        83,
        0,
        16,
        199,
        143,
        74,
        0,
        140,
        143,
        44,
        1,
        134,
        155,
        89,
        198,
        15,
        124,
        3,
        1,
        29,
        0,
        2,
        98,
        68,
        41,
        0,
        37,
        0,
        21,
        99,
        68,
        93,
        5,
        1,
        5,
        28,
        33,
        98,
        2,
        7,
        69,
        53,
        22,
        99,
        52,
        45,
        60,
        20,
        90,
        1,
        28,
        98,
        85,
        46,
        38,
        70,
        74,
        99,
        7,
        41,
        99,
        99,
        60,
        99,
        99,
        71,
        27,
        84,
        96,
        1
      ]
    },
    {
      "id": "11102F47",
      "weeklyUsage": [
        199,
        2,
        136,
        9,
        84,
        195,
        47,
        22,
        2,
        1,
        180,
        35,
        173,
        115,
        199,
        3,
        180,
        144,
        48,
        8,
        3,
        126,
        90,
        98,
        152,
        76,
        195,
        45,
        66,
        112,
        153,
        165,
        48,
        178,
        142,
        0,
        69,
        151,
        103,
        23,
        199,
        199,
        114,
        28,
        1,
        196,
        185,
        79,
        123,
        124,
        2,
        157
      ],
      "weeklyWasted": [
        142,
        0,
        69,
        151,
        103,
        23,
        199,
        199,
        114,
        28,
        1,
        196,
        185,
        79,
        123,
        124,
        2,
        157,
        12,
        1,
        18,
        39,
        51,
        1,
        99,
        10,
        12,
        35,
        21,
        15,
        1,
        3,
        34,
        1,
        52,
        9,
        78,
        89,
        9,
        39,
        97,
        18,
        71,
        63,
        37,
        83,
        96,
        58,
        2,
        97,
        57,
        74,
        34,
        99,
        45,
        42,
        27,
        36,
        11,
        74,
        0,
        80,
        99,
        98,
        66,
        95,
        82,
        99,
        11,
        99
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        199,
        93,
        158,
        135,
        0,
        125,
        26,
        123,
        119,
        103,
        101,
        2,
        74,
        0,
        199,
        21,
        160,
        107,
        83,
        186,
        197,
        145,
        167,
        22,
        3,
        100,
        173,
        1,
        0,
        23,
        63,
        4,
        33,
        70,
        193,
        131,
        48,
        186,
        10,
        30,
        34,
        65,
        42,
        0,
        176,
        196,
        117,
        70,
        198,
        117,
        70,
        80
      ],
      "weeklyWasted": [
        193,
        131,
        48,
        186,
        10,
        30,
        34,
        65,
        42,
        0,
        176,
        196,
        117,
        70,
        198,
        117,
        70,
        80,
        64,
        36,
        99,
        27,
        98,
        28,
        64,
        1,
        32,
        28,
        14,
        49,
        83,
        99,
        0,
        35,
        0,
        54,
        85,
        32,
        66,
        14,
        24,
        16,
        68,
        95,
        98,
        56,
        30,
        28,
        38,
        5,
        78,
        40,
        84,
        99,
        99,
        55,
        69,
        99,
        30,
        0,
        0,
        74,
        38,
        7,
        81,
        88,
        66,
        60,
        99,
        49
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        199,
        92,
        0,
        148,
        117,
        1,
        174,
        23,
        75,
        93,
        199,
        107,
        167,
        0,
        199,
        32,
        0,
        199,
        15,
        126,
        102,
        24,
        95,
        167,
        33,
        100,
        100,
        85,
        199,
        120,
        182,
        0,
        77,
        109,
        62,
        54,
        30,
        5,
        31,
        55,
        0,
        90,
        58,
        37,
        127,
        4,
        13,
        6,
        198,
        3,
        113,
        3
      ],
      "weeklyWasted": [
        62,
        54,
        30,
        5,
        31,
        55,
        0,
        90,
        58,
        37,
        127,
        4,
        13,
        6,
        198,
        3,
        113,
        3,
        99,
        69,
        67,
        2,
        77,
        32,
        99,
        0,
        41,
        33,
        99,
        18,
        34,
        53,
        0,
        6,
        85,
        99,
        64,
        0,
        99,
        0,
        0,
        88,
        37,
        57,
        65,
        32,
        65,
        17,
        2,
        95,
        74,
        98,
        68,
        53,
        83,
        64,
        94,
        9,
        54,
        47,
        97,
        73,
        55,
        47,
        83,
        98,
        68,
        99,
        99,
        32
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        68,
        127,
        185,
        12,
        130,
        16,
        96,
        3,
        2,
        22,
        36,
        2,
        175,
        90,
        64,
        173,
        120,
        88,
        113,
        115,
        66,
        37,
        141,
        109,
        3,
        128,
        178,
        1,
        16,
        198,
        58,
        65,
        1,
        196,
        142,
        132,
        116,
        184,
        19,
        124,
        42,
        152,
        47,
        0,
        87,
        35,
        57,
        98,
        158,
        62,
        85,
        108
      ],
      "weeklyWasted": [
        142,
        132,
        116,
        184,
        19,
        124,
        42,
        152,
        47,
        0,
        87,
        35,
        57,
        98,
        158,
        62,
        85,
        108,
        68,
        36,
        61,
        2,
        78,
        97,
        29,
        93,
        0,
        11,
        37,
        18,
        38,
        99,
        44,
        64,
        0,
        19,
        16,
        57,
        52,
        22,
        26,
        0,
        6,
        1,
        56,
        1,
        83,
        1,
        22,
        89,
        1,
        98,
        63,
        41,
        70,
        99,
        18,
        99,
        62,
        0,
        99,
        79,
        57,
        35,
        84,
        98,
        70,
        74,
        99,
        41
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        199,
        2,
        56,
        61,
        127,
        90,
        34,
        31,
        182,
        101,
        24,
        2,
        2,
        54,
        197,
        179,
        99,
        132,
        39,
        181,
        108,
        102,
        162,
        195,
        123,
        142,
        69,
        95,
        150,
        138,
        11,
        1,
        108,
        106,
        48,
        0,
        198,
        56,
        125,
        122,
        121,
        8,
        3,
        0,
        171,
        134,
        0,
        141,
        172,
        120,
        181,
        3
      ],
      "weeklyWasted": [
        48,
        0,
        198,
        56,
        125,
        122,
        121,
        8,
        3,
        0,
        171,
        134,
        0,
        141,
        172,
        120,
        181,
        3,
        72,
        14,
        99,
        31,
        98,
        43,
        0,
        38,
        54,
        65,
        86,
        99,
        35,
        63,
        0,
        85,
        56,
        71,
        26,
        28,
        15,
        82,
        0,
        0,
        22,
        38,
        0,
        67,
        29,
        66,
        10,
        88,
        37,
        16,
        61,
        71,
        99,
        97,
        56,
        99,
        0,
        34,
        99,
        65,
        80,
        0,
        27,
        63,
        25,
        91,
        35,
        39
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        199,
        2,
        0,
        195,
        156,
        51,
        105,
        154,
        139,
        32,
        122,
        2,
        5,
        57,
        191,
        26,
        179,
        125,
        128,
        186,
        197,
        54,
        49,
        151,
        185,
        80,
        181,
        1,
        199,
        24,
        35,
        172,
        83,
        4,
        126,
        66,
        198,
        102,
        67,
        45,
        10,
        43,
        0,
        50,
        1,
        14,
        0,
        93,
        198,
        199,
        133,
        77
      ],
      "weeklyWasted": [
        126,
        66,
        198,
        102,
        67,
        45,
        10,
        43,
        0,
        50,
        1,
        14,
        0,
        93,
        198,
        199,
        133,
        77,
        64,
        1,
        26,
        3,
        98,
        68,
        16,
        41,
        0,
        52,
        55,
        64,
        70,
        63,
        0,
        1,
        0,
        99,
        2,
        8,
        68,
        0,
        27,
        26,
        0,
        1,
        89,
        51,
        80,
        49,
        42,
        97,
        1,
        62,
        99,
        99,
        53,
        59,
        99,
        35,
        39,
        0,
        84,
        57,
        51,
        41,
        99,
        74,
        82,
        52,
        44,
        17
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        199,
        65,
        198,
        33,
        0,
        106,
        58,
        115,
        134,
        42,
        97,
        16,
        2,
        0,
        184,
        190,
        192,
        161,
        4,
        123,
        114,
        4,
        14,
        52,
        145,
        23,
        171,
        35,
        158,
        146,
        0,
        151,
        1,
        171,
        40,
        179,
        96,
        46,
        37,
        0,
        0,
        0,
        171,
        0,
        28,
        113,
        102,
        153,
        155,
        91,
        74,
        179
      ],
      "weeklyWasted": [
        40,
        179,
        96,
        46,
        37,
        0,
        0,
        0,
        171,
        0,
        28,
        113,
        102,
        153,
        155,
        91,
        74,
        179,
        25,
        63,
        21,
        84,
        62,
        98,
        50,
        24,
        42,
        13,
        24,
        66,
        16,
        82,
        52,
        1,
        42,
        54,
        85,
        37,
        82,
        36,
        48,
        0,
        82,
        1,
        26,
        1,
        63,
        80,
        2,
        33,
        1,
        98,
        49,
        0,
        69,
        85,
        26,
        99,
        0,
        98,
        37,
        99,
        99,
        21,
        98,
        98,
        99,
        56,
        78,
        68
      ]
    },
    {
      "id": "965102D9",
      "weeklyUsage": [
        170,
        2,
        137,
        195,
        118,
        197,
        31,
        195,
        199,
        82,
        199,
        72,
        187,
        0,
        87,
        3,
        164,
        135,
        140,
        28,
        107,
        86,
        16,
        146,
        97,
        198,
        125,
        120,
        43,
        0,
        66,
        0,
        158,
        139,
        4,
        0,
        148,
        39,
        10,
        199,
        148,
        146,
        105,
        14,
        29,
        169,
        143,
        66,
        198,
        184,
        33,
        83
      ],
      "weeklyWasted": [
        4,
        0,
        148,
        39,
        10,
        199,
        148,
        146,
        105,
        14,
        29,
        169,
        143,
        66,
        198,
        184,
        33,
        83,
        1,
        7,
        61,
        2,
        73,
        22,
        83,
        63,
        74,
        0,
        21,
        93,
        61,
        99,
        38,
        93,
        10,
        83,
        14,
        4,
        99,
        0,
        31,
        13,
        57,
        99,
        0,
        6,
        42,
        24,
        2,
        71,
        1,
        98,
        99,
        37,
        84,
        33,
        0,
        99,
        0,
        51,
        99,
        99,
        69,
        21,
        44,
        98,
        27,
        86,
        23,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        22,
        173,
        56,
        30,
        36,
        77,
        99,
        17,
        91,
        1,
        5,
        197,
        151,
        98,
        199,
        41,
        90,
        15,
        37,
        2,
        188,
        93,
        2,
        157,
        106,
        44,
        197,
        73,
        137,
        76,
        64,
        0,
        78,
        164,
        146,
        199,
        198,
        10,
        139,
        199,
        93,
        54,
        0,
        0,
        156,
        124,
        0,
        44,
        191,
        86,
        134,
        3
      ],
      "weeklyWasted": [
        146,
        199,
        198,
        10,
        139,
        199,
        93,
        54,
        0,
        0,
        156,
        124,
        0,
        44,
        191,
        86,
        134,
        3,
        38,
        40,
        57,
        2,
        61,
        63,
        59,
        21,
        99,
        0,
        99,
        93,
        61,
        72,
        56,
        17,
        37,
        99,
        8,
        62,
        2,
        13,
        41,
        0,
        70,
        85,
        64,
        51,
        98,
        27,
        6,
        88,
        1,
        98,
        65,
        0,
        46,
        99,
        39,
        99,
        14,
        95,
        97,
        60,
        50,
        53,
        25,
        57,
        44,
        99,
        99,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyUsage": [
        124,
        95,
        180,
        5,
        11,
        1,
        181,
        48,
        140,
        1,
        199,
        75,
        155,
        199,
        59,
        128,
        156,
        134,
        47,
        78,
        20,
        198,
        22,
        162,
        197,
        101,
        12,
        53,
        161,
        112,
        75,
        0,
        33,
        27,
        186,
        183,
        155,
        145,
        155,
        178,
        64,
        123,
        12,
        0,
        17,
        196,
        0,
        38,
        1,
        63,
        101,
        66
      ],
      "weeklyWasted": [
        186,
        183,
        155,
        145,
        155,
        178,
        64,
        123,
        12,
        0,
        17,
        196,
        0,
        38,
        1,
        63,
        101,
        66,
        1,
        1,
        99,
        2,
        45,
        44,
        59,
        6,
        88,
        50,
        99,
        92,
        44,
        33,
        88,
        39,
        65,
        66,
        20,
        91,
        2,
        35,
        41,
        7,
        69,
        99,
        59,
        97,
        81,
        42,
        2,
        97,
        58,
        35,
        94,
        14,
        78,
        60,
        58,
        1,
        0,
        0,
        43,
        42,
        48,
        29,
        99,
        81,
        85,
        82,
        47,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        199,
        107,
        172,
        76,
        34,
        152,
        110,
        121,
        106,
        1,
        99,
        197,
        180,
        0,
        125,
        53,
        162,
        52,
        35,
        65,
        75,
        118,
        17,
        80,
        72,
        198,
        167,
        179,
        56,
        86,
        69,
        49,
        197,
        152,
        81,
        67,
        0,
        5,
        82,
        0,
        199,
        55,
        0,
        44,
        1,
        196,
        55,
        35,
        155,
        146,
        169,
        3
      ],
      "weeklyWasted": [
        81,
        67,
        0,
        5,
        82,
        0,
        199,
        55,
        0,
        44,
        1,
        196,
        55,
        35,
        155,
        146,
        169,
        3,
        27,
        23,
        0,
        35,
        98,
        18,
        99,
        0,
        40,
        98,
        99,
        14,
        38,
        65,
        47,
        1,
        49,
        38,
        40,
        60,
        89,
        21,
        40,
        0,
        37,
        1,
        98,
        8,
        65,
        49,
        2,
        97,
        31,
        79,
        78,
        4,
        99,
        66,
        99,
        1,
        31,
        0,
        87,
        99,
        99,
        46,
        74,
        98,
        15,
        94,
        99,
        1
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyUsage": [
        199,
        155,
        189,
        41,
        85,
        60,
        46,
        29,
        5,
        1,
        117,
        2,
        198,
        147,
        21,
        3,
        192,
        199,
        107,
        4,
        176,
        4,
        140,
        23,
        136,
        78,
        155,
        47,
        6,
        59,
        33,
        65,
        34,
        199,
        198,
        199,
        110,
        141,
        199,
        63,
        199,
        0,
        177,
        34,
        44,
        17,
        103,
        162,
        198,
        114,
        89,
        16
      ],
      "weeklyWasted": [
        198,
        199,
        110,
        141,
        199,
        63,
        199,
        0,
        177,
        34,
        44,
        17,
        103,
        162,
        198,
        114,
        89,
        16,
        1,
        15,
        5,
        2,
        93,
        46,
        72,
        0,
        99,
        79,
        72,
        56,
        99,
        82,
        0,
        19,
        18,
        78,
        39,
        28,
        83,
        61,
        38,
        25,
        36,
        72,
        0,
        65,
        43,
        13,
        88,
        55,
        41,
        78,
        39,
        0,
        37,
        54,
        99,
        49,
        39,
        52,
        83,
        99,
        26,
        90,
        99,
        87,
        95,
        39,
        50,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        199,
        16,
        46,
        5,
        105,
        197,
        57,
        27,
        199,
        31,
        119,
        30,
        198,
        0,
        49,
        3,
        145,
        93,
        3,
        89,
        1,
        90,
        44,
        178,
        62,
        116,
        156,
        1,
        53,
        107,
        34,
        127,
        50,
        51,
        190,
        0,
        0,
        199,
        68,
        199,
        199,
        184,
        0,
        33,
        26,
        65,
        84,
        124,
        1,
        6,
        94,
        187
      ],
      "weeklyWasted": [
        190,
        0,
        0,
        199,
        68,
        199,
        199,
        184,
        0,
        33,
        26,
        65,
        84,
        124,
        1,
        6,
        94,
        187,
        1,
        1,
        0,
        12,
        83,
        1,
        53,
        0,
        38,
        64,
        64,
        69,
        64,
        67,
        4,
        12,
        57,
        99,
        35,
        34,
        69,
        0,
        0,
        0,
        85,
        99,
        45,
        27,
        62,
        65,
        2,
        97,
        1,
        98,
        78,
        60,
        74,
        62,
        43,
        19,
        39,
        59,
        72,
        99,
        99,
        32,
        43,
        98,
        59,
        71,
        42,
        1
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        199,
        79,
        29,
        46,
        77,
        151,
        194,
        133,
        68,
        1,
        199,
        2,
        161,
        0,
        151,
        3,
        192,
        152,
        197,
        51,
        35,
        4,
        84,
        1,
        3,
        132,
        120,
        130,
        107,
        62,
        95,
        38,
        197,
        4,
        103,
        74,
        157,
        89,
        10,
        5,
        0,
        28,
        0,
        32,
        53,
        4,
        31,
        0,
        132,
        101,
        66,
        3
      ],
      "weeklyWasted": [
        103,
        74,
        157,
        89,
        10,
        5,
        0,
        28,
        0,
        32,
        53,
        4,
        31,
        0,
        132,
        101,
        66,
        3,
        12,
        26,
        46,
        22,
        98,
        46,
        97,
        0,
        32,
        73,
        99,
        53,
        72,
        98,
        0,
        1,
        19,
        63,
        19,
        35,
        45,
        89,
        20,
        0,
        71,
        31,
        91,
        49,
        59,
        19,
        75,
        50,
        15,
        98,
        9,
        2,
        97,
        55,
        64,
        95,
        67,
        22,
        92,
        76,
        99,
        0,
        99,
        46,
        0,
        99,
        94,
        56
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        195,
        70,
        197,
        78,
        51,
        133,
        47,
        79,
        128,
        1,
        133,
        72,
        198,
        0,
        165,
        139,
        45,
        69,
        3,
        45,
        1,
        52,
        2,
        94,
        19,
        147,
        3,
        138,
        199,
        98,
        119,
        0,
        9,
        79,
        198,
        199,
        198,
        5,
        199,
        119,
        68,
        125,
        97,
        0,
        169,
        64,
        31,
        46,
        77,
        2,
        53,
        3
      ],
      "weeklyWasted": [
        198,
        199,
        198,
        5,
        199,
        119,
        68,
        125,
        97,
        0,
        169,
        64,
        31,
        46,
        77,
        2,
        53,
        3,
        87,
        1,
        81,
        2,
        35,
        74,
        99,
        0,
        62,
        30,
        88,
        71,
        43,
        63,
        79,
        8,
        55,
        99,
        16,
        2,
        73,
        38,
        53,
        0,
        22,
        99,
        36,
        97,
        58,
        30,
        30,
        86,
        59,
        83,
        69,
        0,
        99,
        39,
        0,
        27,
        77,
        0,
        73,
        11,
        36,
        69,
        61,
        98,
        46,
        67,
        99,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        182,
        2,
        121,
        29,
        65,
        47,
        168,
        167,
        2,
        47,
        50,
        33,
        146,
        0,
        141,
        84,
        192,
        31,
        197,
        60,
        170,
        134,
        127,
        31,
        181,
        198,
        175,
        119,
        123,
        106,
        153,
        108,
        1,
        163,
        173,
        199,
        0,
        137,
        172,
        19,
        190,
        0,
        0,
        155,
        49,
        14,
        74,
        89,
        196,
        2,
        28,
        97
      ],
      "weeklyWasted": [
        173,
        199,
        0,
        137,
        172,
        19,
        190,
        0,
        0,
        155,
        49,
        14,
        74,
        89,
        196,
        2,
        28,
        97,
        1,
        40,
        63,
        25,
        32,
        21,
        57,
        0,
        99,
        59,
        35,
        7,
        63,
        42,
        67,
        38,
        20,
        99,
        35,
        65,
        51,
        0,
        32,
        11,
        99,
        65,
        77,
        39,
        99,
        47,
        2,
        65,
        1,
        98,
        46,
        99,
        51,
        93,
        99,
        77,
        21,
        57,
        66,
        15,
        99,
        36,
        91,
        69,
        18,
        82,
        59,
        10
      ]
    },
    {
      "id": "7B415249",
      "weeklyUsage": [
        53,
        28,
        198,
        195,
        125,
        187,
        120,
        70,
        199,
        59,
        32,
        105,
        47,
        63,
        1,
        86,
        18,
        172,
        71,
        144,
        1,
        51,
        102,
        72,
        3,
        103,
        197,
        58,
        124,
        4,
        107,
        40,
        119,
        199,
        7,
        0,
        112,
        77,
        78,
        179,
        0,
        80,
        1,
        51,
        1,
        90,
        0,
        24,
        198,
        14,
        157,
        55
      ],
      "weeklyWasted": [
        7,
        0,
        112,
        77,
        78,
        179,
        0,
        80,
        1,
        51,
        1,
        90,
        0,
        24,
        198,
        14,
        157,
        55,
        57,
        14,
        16,
        3,
        98,
        81,
        68,
        9,
        96,
        67,
        99,
        88,
        66,
        93,
        16,
        93,
        43,
        68,
        43,
        49,
        40,
        0,
        0,
        0,
        16,
        99,
        0,
        13,
        2,
        1,
        2,
        87,
        12,
        78,
        99,
        27,
        66,
        77,
        44,
        5,
        6,
        98,
        43,
        99,
        64,
        44,
        14,
        98,
        47,
        75,
        39,
        38
      ]
    },
    {
      "id": "F55885BD",
      "weeklyUsage": [
        118,
        2,
        157,
        48,
        36,
        25,
        109,
        80,
        160,
        22,
        36,
        167,
        128,
        64,
        175,
        4,
        192,
        199,
        81,
        2,
        148,
        4,
        133,
        96,
        3,
        30,
        175,
        50,
        104,
        36,
        103,
        86,
        197,
        79,
        60,
        1,
        0,
        5,
        51,
        44,
        69,
        0,
        0,
        186,
        6,
        106,
        0,
        149,
        198,
        199,
        155,
        3
      ],
      "weeklyWasted": [
        60,
        1,
        0,
        5,
        51,
        44,
        69,
        0,
        0,
        186,
        6,
        106,
        0,
        149,
        198,
        199,
        155,
        3,
        6,
        81,
        0,
        2,
        59,
        70,
        73,
        47,
        8,
        42,
        32,
        72,
        66,
        62,
        3,
        25,
        76,
        64,
        23,
        48,
        2,
        0,
        0,
        88,
        19,
        99,
        56,
        29,
        45,
        32,
        65,
        1,
        65,
        83,
        99,
        23,
        99,
        0,
        64,
        47,
        79,
        18,
        91,
        99,
        5,
        47,
        99,
        98,
        99,
        48,
        48,
        36
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        173,
        101,
        0,
        140,
        103,
        156,
        194,
        3,
        2,
        6,
        32,
        54,
        170,
        71,
        162,
        198,
        192,
        194,
        26,
        130,
        197,
        4,
        28,
        43,
        121,
        1,
        120,
        1,
        105,
        0,
        95,
        196,
        29,
        53,
        100,
        0,
        198,
        5,
        157,
        178,
        100,
        95,
        130,
        32,
        1,
        138,
        103,
        189,
        198,
        103,
        53,
        66
      ],
      "weeklyWasted": [
        100,
        0,
        198,
        5,
        157,
        178,
        100,
        95,
        130,
        32,
        1,
        138,
        103,
        189,
        198,
        103,
        53,
        66,
        18,
        92,
        21,
        38,
        63,
        39,
        0,
        0,
        99,
        44,
        26,
        98,
        14,
        86,
        21,
        98,
        44,
        99,
        30,
        62,
        79,
        52,
        42,
        4,
        43,
        66,
        73,
        3,
        2,
        8,
        17,
        66,
        48,
        1,
        99,
        0,
        69,
        79,
        55,
        99,
        30,
        0,
        66,
        17,
        33,
        70,
        0,
        48,
        93,
        68,
        48,
        6
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        73,
        153,
        68,
        51,
        60,
        1,
        137,
        120,
        2,
        33,
        199,
        2,
        114,
        114,
        199,
        74,
        125,
        16,
        27,
        154,
        106,
        134,
        26,
        1,
        3,
        89,
        122,
        60,
        126,
        61,
        95,
        132,
        1,
        55,
        134,
        167,
        119,
        5,
        48,
        157,
        0,
        72,
        81,
        154,
        11,
        96,
        0,
        54,
        152,
        191,
        108,
        3
      ],
      "weeklyWasted": [
        134,
        167,
        119,
        5,
        48,
        157,
        0,
        72,
        81,
        154,
        11,
        96,
        0,
        54,
        152,
        191,
        108,
        3,
        1,
        38,
        26,
        2,
        98,
        98,
        4,
        0,
        61,
        57,
        60,
        64,
        99,
        73,
        89,
        21,
        0,
        74,
        68,
        17,
        2,
        27,
        0,
        0,
        84,
        89,
        66,
        46,
        48,
        30,
        4,
        65,
        11,
        98,
        28,
        57,
        99,
        96,
        49,
        70,
        29,
        87,
        63,
        42,
        64,
        91,
        93,
        0,
        94,
        99,
        81,
        22
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        117,
        27,
        69,
        195,
        18,
        1,
        25,
        3,
        2,
        1,
        51,
        120,
        54,
        0,
        46,
        85,
        192,
        199,
        3,
        161,
        192,
        4,
        197,
        171,
        3,
        88,
        197,
        47,
        174,
        135,
        94,
        144,
        1,
        65,
        14,
        63,
        122,
        46,
        146,
        96,
        4,
        55,
        104,
        54,
        1,
        160,
        0,
        87,
        159,
        34,
        16,
        68
      ],
      "weeklyWasted": [
        14,
        63,
        122,
        46,
        146,
        96,
        4,
        55,
        104,
        54,
        1,
        160,
        0,
        87,
        159,
        34,
        16,
        68,
        25,
        44,
        0,
        8,
        41,
        56,
        0,
        54,
        39,
        94,
        53,
        96,
        2,
        23,
        9,
        24,
        23,
        99,
        20,
        91,
        38,
        67,
        67,
        19,
        0,
        99,
        52,
        97,
        2,
        96,
        2,
        97,
        48,
        69,
        15,
        53,
        89,
        42,
        7,
        31,
        2,
        55,
        34,
        99,
        32,
        72,
        58,
        42,
        75,
        43,
        89,
        71
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        199,
        19,
        17,
        143,
        76,
        197,
        3,
        75,
        52,
        1,
        64,
        26,
        183,
        0,
        39,
        83,
        192,
        100,
        115,
        94,
        192,
        4,
        197,
        146,
        3,
        178,
        179,
        59,
        164,
        99,
        199,
        194,
        1,
        37,
        146,
        148,
        169,
        169,
        10,
        6,
        99,
        49,
        27,
        92,
        1,
        171,
        63,
        46,
        175,
        53,
        135,
        15
      ],
      "weeklyWasted": [
        146,
        148,
        169,
        169,
        10,
        6,
        99,
        49,
        27,
        92,
        1,
        171,
        63,
        46,
        175,
        53,
        135,
        15,
        99,
        19,
        61,
        34,
        98,
        1,
        27,
        70,
        60,
        75,
        22,
        72,
        58,
        99,
        65,
        49,
        38,
        56,
        99,
        53,
        2,
        82,
        5,
        17,
        63,
        99,
        61,
        75,
        16,
        14,
        2,
        55,
        49,
        72,
        28,
        92,
        26,
        58,
        92,
        1,
        24,
        35,
        99,
        86,
        62,
        3,
        42,
        42,
        0,
        1,
        30,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        199,
        170,
        65,
        98,
        70,
        197,
        194,
        197,
        2,
        45,
        199,
        66,
        181,
        56,
        199,
        150,
        192,
        1,
        184,
        162,
        197,
        198,
        197,
        136,
        3,
        183,
        102,
        8,
        99,
        114,
        57,
        10,
        29,
        91,
        187,
        121,
        198,
        93,
        17,
        179,
        71,
        133,
        5,
        151,
        1,
        170,
        34,
        28,
        118,
        37,
        123,
        3
      ],
      "weeklyWasted": [
        187,
        121,
        198,
        93,
        17,
        179,
        71,
        133,
        5,
        151,
        1,
        170,
        34,
        28,
        118,
        37,
        123,
        3,
        58,
        59,
        71,
        2,
        54,
        1,
        72,
        32,
        99,
        35,
        99,
        1,
        31,
        81,
        32,
        73,
        0,
        99,
        15,
        0,
        38,
        1,
        41,
        0,
        86,
        98,
        51,
        13,
        10,
        25,
        72,
        84,
        36,
        12,
        99,
        0,
        74,
        87,
        83,
        99,
        73,
        0,
        99,
        66,
        56,
        41,
        6,
        34,
        99,
        77,
        26,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        199,
        199,
        105,
        12,
        55,
        197,
        156,
        100,
        46,
        1,
        126,
        2,
        2,
        0,
        169,
        3,
        192,
        113,
        36,
        2,
        197,
        157,
        153,
        41,
        141,
        126,
        92,
        84,
        141,
        198,
        176,
        94,
        127,
        135,
        128,
        92,
        105,
        21,
        149,
        0,
        105,
        0,
        18,
        164,
        37,
        4,
        113,
        26,
        152,
        87,
        2,
        55
      ],
      "weeklyWasted": [
        128,
        92,
        105,
        21,
        149,
        0,
        105,
        0,
        18,
        164,
        37,
        4,
        113,
        26,
        152,
        87,
        2,
        55,
        16,
        22,
        0,
        31,
        49,
        57,
        82,
        24,
        88,
        99,
        99,
        50,
        49,
        49,
        73,
        21,
        27,
        64,
        60,
        2,
        47,
        52,
        97,
        15,
        89,
        8,
        92,
        24,
        99,
        43,
        99,
        72,
        36,
        98,
        91,
        1,
        73,
        78,
        99,
        42,
        64,
        23,
        68,
        70,
        16,
        29,
        43,
        70,
        0,
        87,
        47,
        15
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        199,
        17,
        50,
        195,
        112,
        70,
        181,
        28,
        2,
        37,
        199,
        93,
        198,
        0,
        84,
        57,
        190,
        83,
        79,
        2,
        78,
        189,
        2,
        129,
        149,
        143,
        187,
        1,
        127,
        107,
        78,
        163,
        78,
        85,
        4,
        66,
        100,
        199,
        95,
        101,
        118,
        0,
        167,
        0,
        7,
        48,
        38,
        92,
        184,
        154,
        99,
        3
      ],
      "weeklyWasted": [
        4,
        66,
        100,
        199,
        95,
        101,
        118,
        0,
        167,
        0,
        7,
        48,
        38,
        92,
        184,
        154,
        99,
        3,
        40,
        35,
        60,
        2,
        98,
        1,
        76,
        32,
        23,
        0,
        34,
        91,
        33,
        99,
        44,
        28,
        24,
        69,
        1,
        74,
        99,
        60,
        3,
        11,
        55,
        68,
        61,
        60,
        59,
        4,
        2,
        97,
        30,
        98,
        0,
        20,
        76,
        99,
        77,
        43,
        16,
        23,
        99,
        68,
        54,
        62,
        97,
        98,
        67,
        1,
        99,
        1
      ]
    },
    {
      "id": "3D26994F",
      "weeklyUsage": [
        98,
        67,
        0,
        5,
        0,
        104,
        30,
        3,
        169,
        1,
        46,
        197,
        143,
        0,
        199,
        165,
        164,
        199,
        182,
        158,
        1,
        98,
        45,
        60,
        17,
        198,
        166,
        31,
        143,
        0,
        199,
        94,
        166,
        100,
        4,
        0,
        198,
        78,
        65,
        70,
        102,
        21,
        8,
        93,
        1,
        138,
        170,
        167,
        198,
        2,
        20,
        109
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        78,
        65,
        70,
        102,
        21,
        8,
        93,
        1,
        138,
        170,
        167,
        198,
        2,
        20,
        109,
        99,
        6,
        0,
        2,
        98,
        56,
        0,
        0,
        52,
        34,
        96,
        91,
        84,
        99,
        62,
        27,
        38,
        66,
        1,
        63,
        49,
        19,
        20,
        0,
        34,
        99,
        59,
        53,
        99,
        18,
        13,
        79,
        36,
        87,
        69,
        74,
        54,
        50,
        0,
        38,
        0,
        19,
        71,
        99,
        19,
        35,
        43,
        98,
        75,
        55,
        85,
        30
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        167,
        199,
        87,
        88,
        131,
        98,
        60,
        189,
        91,
        9,
        131,
        2,
        149,
        137,
        140,
        138,
        192,
        64,
        49,
        2,
        168,
        4,
        149,
        92,
        3,
        165,
        76,
        1,
        177,
        109,
        92,
        0,
        1,
        76,
        139,
        0,
        148,
        5,
        199,
        131,
        57,
        0,
        6,
        0,
        1,
        81,
        0,
        0,
        135,
        177,
        62,
        122
      ],
      "weeklyWasted": [
        139,
        0,
        148,
        5,
        199,
        131,
        57,
        0,
        6,
        0,
        1,
        81,
        0,
        0,
        135,
        177,
        62,
        122,
        81,
        26,
        99,
        2,
        84,
        24,
        0,
        13,
        99,
        83,
        97,
        51,
        23,
        92,
        45,
        23,
        68,
        78,
        43,
        59,
        44,
        99,
        38,
        0,
        0,
        87,
        30,
        73,
        45,
        1,
        2,
        97,
        64,
        39,
        9,
        34,
        60,
        33,
        18,
        36,
        0,
        22,
        99,
        81,
        9,
        33,
        56,
        98,
        24,
        55,
        99,
        44
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        193,
        30,
        56,
        174,
        170,
        139,
        103,
        3,
        139,
        138,
        63,
        26,
        2,
        0,
        189,
        111,
        192,
        194,
        3,
        111,
        197,
        69,
        11,
        176,
        72,
        94,
        130,
        78,
        98,
        115,
        93,
        141,
        24,
        138,
        124,
        10,
        198,
        110,
        143,
        22,
        158,
        36,
        103,
        33,
        93,
        130,
        51,
        36,
        195,
        124,
        8,
        134
      ],
      "weeklyWasted": [
        124,
        10,
        198,
        110,
        143,
        22,
        158,
        36,
        103,
        33,
        93,
        130,
        51,
        36,
        195,
        124,
        8,
        134,
        81,
        1,
        50,
        45,
        98,
        98,
        10,
        52,
        0,
        74,
        99,
        42,
        16,
        54,
        91,
        22,
        21,
        84,
        23,
        94,
        19,
        89,
        0,
        9,
        74,
        63,
        98,
        37,
        25,
        9,
        43,
        97,
        88,
        91,
        75,
        67,
        59,
        53,
        49,
        97,
        52,
        0,
        84,
        92,
        2,
        0,
        42,
        93,
        91,
        94,
        99,
        3
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        156,
        86,
        183,
        113,
        0,
        30,
        6,
        20,
        24,
        1,
        59,
        144,
        174,
        0,
        146,
        56,
        192,
        132,
        77,
        63,
        197,
        30,
        2,
        145,
        42,
        98,
        197,
        76,
        132,
        119,
        50,
        188,
        38,
        60,
        63,
        185,
        104,
        129,
        13,
        39,
        0,
        90,
        54,
        0,
        22,
        4,
        0,
        149,
        198,
        127,
        40,
        25
      ],
      "weeklyWasted": [
        63,
        185,
        104,
        129,
        13,
        39,
        0,
        90,
        54,
        0,
        22,
        4,
        0,
        149,
        198,
        127,
        40,
        25,
        57,
        49,
        20,
        2,
        56,
        67,
        0,
        0,
        99,
        99,
        19,
        12,
        36,
        22,
        77,
        35,
        57,
        99,
        1,
        78,
        47,
        89,
        97,
        0,
        26,
        24,
        74,
        66,
        37,
        36,
        46,
        82,
        70,
        98,
        40,
        0,
        99,
        90,
        0,
        1,
        14,
        3,
        99,
        99,
        41,
        50,
        94,
        98,
        95,
        95,
        61,
        1
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        33,
        119,
        49,
        93,
        99,
        167,
        61,
        197,
        199,
        82,
        85,
        48,
        198,
        79,
        48,
        103,
        0,
        124,
        3,
        197,
        197,
        112,
        2,
        195,
        56,
        150,
        197,
        40,
        199,
        198,
        74,
        179,
        76,
        137,
        45,
        101,
        12,
        39,
        47,
        71,
        0,
        199,
        191,
        53,
        1,
        196,
        22,
        56,
        198,
        154,
        136,
        3
      ],
      "weeklyWasted": [
        45,
        101,
        12,
        39,
        47,
        71,
        0,
        199,
        191,
        53,
        1,
        196,
        22,
        56,
        198,
        154,
        136,
        3,
        21,
        1,
        19,
        42,
        34,
        87,
        16,
        23,
        99,
        0,
        63,
        87,
        75,
        99,
        29,
        14,
        6,
        99,
        56,
        95,
        2,
        31,
        0,
        99,
        15,
        78,
        67,
        64,
        57,
        1,
        39,
        77,
        37,
        45,
        56,
        54,
        39,
        53,
        58,
        99,
        69,
        0,
        59,
        43,
        56,
        52,
        45,
        80,
        24,
        39,
        64,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        137,
        99,
        56,
        5,
        115,
        67,
        38,
        3,
        117,
        18,
        182,
        134,
        198,
        199,
        199,
        127,
        94,
        199,
        123,
        70,
        1,
        18,
        70,
        132,
        3,
        11,
        126,
        197,
        113,
        198,
        6,
        196,
        101,
        125,
        198,
        98,
        101,
        199,
        134,
        19,
        0,
        3,
        104,
        68,
        33,
        4,
        0,
        0,
        198,
        84,
        175,
        165
      ],
      "weeklyWasted": [
        198,
        98,
        101,
        199,
        134,
        19,
        0,
        3,
        104,
        68,
        33,
        4,
        0,
        0,
        198,
        84,
        175,
        165,
        32,
        63,
        18,
        2,
        98,
        47,
        0,
        0,
        99,
        99,
        99,
        26,
        99,
        89,
        53,
        45,
        18,
        59,
        30,
        70,
        2,
        54,
        55,
        24,
        75,
        57,
        98,
        78,
        81,
        3,
        2,
        91,
        68,
        92,
        0,
        12,
        58,
        59,
        6,
        1,
        26,
        0,
        66,
        61,
        34,
        61,
        99,
        55,
        17,
        92,
        99,
        1
      ]
    },
    {
      "id": 9202537,
      "weeklyUsage": [
        195,
        184,
        198,
        36,
        198,
        111,
        95,
        3,
        82,
        83,
        103,
        2,
        36,
        0,
        1,
        179,
        192,
        154,
        44,
        197,
        197,
        4,
        76,
        98,
        140,
        75,
        183,
        62,
        137,
        142,
        87,
        145,
        1,
        65,
        4,
        23,
        150,
        49,
        32,
        52,
        94,
        0,
        199,
        157,
        52,
        4,
        106,
        153,
        198,
        21,
        55,
        11
      ],
      "weeklyWasted": [
        4,
        23,
        150,
        49,
        32,
        52,
        94,
        0,
        199,
        157,
        52,
        4,
        106,
        153,
        198,
        21,
        55,
        11,
        55,
        28,
        67,
        7,
        85,
        98,
        60,
        75,
        31,
        0,
        90,
        82,
        56,
        29,
        62,
        1,
        65,
        82,
        99,
        39,
        99,
        0,
        86,
        5,
        0,
        65,
        98,
        73,
        43,
        28,
        2,
        70,
        33,
        62,
        99,
        54,
        46,
        58,
        99,
        52,
        13,
        59,
        99,
        63,
        99,
        51,
        0,
        98,
        44,
        67,
        0,
        19
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        7,
        48,
        0,
        67,
        35,
        31,
        101,
        110,
        56,
        11,
        24,
        159,
        86,
        40,
        198,
        198,
        192,
        199,
        3,
        71,
        187,
        6,
        107,
        145,
        21,
        46,
        82,
        22,
        187,
        46,
        106,
        0,
        93,
        4,
        119,
        137,
        198,
        83,
        80,
        38,
        48,
        0,
        0,
        114,
        68,
        136,
        0,
        62,
        198,
        55,
        83,
        3
      ],
      "weeklyWasted": [
        119,
        137,
        198,
        83,
        80,
        38,
        48,
        0,
        0,
        114,
        68,
        136,
        0,
        62,
        198,
        55,
        83,
        3,
        99,
        14,
        35,
        25,
        76,
        73,
        14,
        22,
        77,
        99,
        55,
        57,
        38,
        16,
        64,
        36,
        42,
        28,
        55,
        10,
        45,
        0,
        78,
        0,
        0,
        99,
        59,
        64,
        48,
        34,
        2,
        97,
        83,
        93,
        99,
        44,
        88,
        62,
        77,
        92,
        8,
        25,
        71,
        61,
        56,
        13,
        95,
        98,
        44,
        70,
        16,
        99
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        199,
        4,
        183,
        5,
        32,
        112,
        194,
        3,
        99,
        1,
        80,
        50,
        2,
        63,
        199,
        3,
        192,
        127,
        3,
        2,
        52,
        14,
        190,
        75,
        194,
        198,
        174,
        1,
        91,
        128,
        14,
        196,
        57,
        4,
        86,
        163,
        26,
        5,
        175,
        35,
        25,
        66,
        185,
        4,
        151,
        164,
        86,
        5,
        194,
        91,
        8,
        3
      ],
      "weeklyWasted": [
        86,
        163,
        26,
        5,
        175,
        35,
        25,
        66,
        185,
        4,
        151,
        164,
        86,
        5,
        194,
        91,
        8,
        3,
        1,
        61,
        0,
        42,
        0,
        69,
        82,
        55,
        89,
        18,
        3,
        35,
        1,
        92,
        88,
        98,
        64,
        68,
        89,
        99,
        79,
        95,
        0,
        33,
        50,
        53,
        98,
        81,
        51,
        45,
        17,
        49,
        27,
        98,
        39,
        7,
        22,
        75,
        60,
        56,
        50,
        57,
        75,
        33,
        99,
        31,
        36,
        98,
        22,
        62,
        99,
        82
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        175,
        32,
        90,
        32,
        78,
        150,
        3,
        45,
        2,
        1,
        194,
        90,
        174,
        37,
        199,
        150,
        25,
        141,
        32,
        62,
        3,
        33,
        45,
        128,
        197,
        119,
        197,
        88,
        77,
        68,
        31,
        77,
        72,
        183,
        82,
        0,
        104,
        42,
        87,
        156,
        127,
        0,
        0,
        75,
        84,
        195,
        171,
        41,
        130,
        21,
        53,
        151
      ],
      "weeklyWasted": [
        82,
        0,
        104,
        42,
        87,
        156,
        127,
        0,
        0,
        75,
        84,
        195,
        171,
        41,
        130,
        21,
        53,
        151,
        20,
        1,
        99,
        9,
        87,
        43,
        84,
        0,
        91,
        0,
        99,
        70,
        11,
        99,
        56,
        41,
        68,
        72,
        40,
        20,
        76,
        0,
        49,
        13,
        64,
        99,
        98,
        1,
        56,
        14,
        2,
        50,
        38,
        98,
        99,
        71,
        47,
        12,
        50,
        48,
        31,
        35,
        51,
        13,
        65,
        96,
        44,
        90,
        71,
        29,
        99,
        24
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        139,
        111,
        138,
        195,
        147,
        15,
        109,
        13,
        111,
        1,
        0,
        183,
        82,
        0,
        158,
        3,
        147,
        110,
        154,
        5,
        139,
        79,
        2,
        25,
        93,
        85,
        55,
        43,
        18,
        2,
        0,
        0,
        30,
        42,
        198,
        0,
        18,
        199,
        42,
        93,
        0,
        53,
        20,
        0,
        89,
        108,
        168,
        198,
        198,
        61,
        2,
        70
      ],
      "weeklyWasted": [
        198,
        0,
        18,
        199,
        42,
        93,
        0,
        53,
        20,
        0,
        89,
        108,
        168,
        198,
        198,
        61,
        2,
        70,
        22,
        45,
        1,
        91,
        35,
        78,
        99,
        0,
        0,
        32,
        0,
        1,
        46,
        84,
        0,
        1,
        95,
        99,
        5,
        0,
        29,
        56,
        61,
        27,
        74,
        99,
        20,
        63,
        2,
        73,
        2,
        97,
        66,
        70,
        90,
        14,
        99,
        92,
        7,
        99,
        0,
        5,
        64,
        56,
        86,
        49,
        41,
        98,
        0,
        70,
        39,
        36
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        25,
        140,
        69,
        19,
        101,
        30,
        104,
        36,
        47,
        1,
        199,
        60,
        111,
        38,
        199,
        76,
        43,
        79,
        71,
        113,
        192,
        23,
        113,
        123,
        3,
        40,
        162,
        77,
        76,
        48,
        60,
        196,
        35,
        109,
        144,
        39,
        116,
        5,
        189,
        0,
        109,
        176,
        0,
        0,
        1,
        196,
        0,
        89,
        1,
        199,
        50,
        173
      ],
      "weeklyWasted": [
        144,
        39,
        116,
        5,
        189,
        0,
        109,
        176,
        0,
        0,
        1,
        196,
        0,
        89,
        1,
        199,
        50,
        173,
        1,
        1,
        13,
        23,
        24,
        71,
        32,
        9,
        90,
        25,
        99,
        97,
        41,
        48,
        61,
        98,
        5,
        62,
        1,
        45,
        2,
        63,
        0,
        75,
        27,
        43,
        54,
        97,
        2,
        27,
        99,
        22,
        37,
        51,
        71,
        75,
        12,
        37,
        42,
        99,
        99,
        25,
        99,
        99,
        0,
        50,
        60,
        98,
        43,
        56,
        74,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        199,
        86,
        0,
        5,
        10,
        59,
        181,
        3,
        2,
        63,
        199,
        197,
        128,
        87,
        199,
        98,
        192,
        54,
        69,
        185,
        129,
        4,
        110,
        58,
        23,
        67,
        187,
        9,
        0,
        136,
        17,
        0,
        1,
        111,
        38,
        48,
        107,
        144,
        22,
        37,
        0,
        147,
        100,
        20,
        6,
        196,
        116,
        38,
        122,
        150,
        80,
        156
      ],
      "weeklyWasted": [
        38,
        48,
        107,
        144,
        22,
        37,
        0,
        147,
        100,
        20,
        6,
        196,
        116,
        38,
        122,
        150,
        80,
        156,
        85,
        26,
        43,
        2,
        94,
        1,
        61,
        15,
        62,
        54,
        38,
        1,
        1,
        70,
        0,
        58,
        37,
        40,
        8,
        81,
        35,
        58,
        57,
        0,
        17,
        33,
        32,
        97,
        71,
        1,
        87,
        66,
        99,
        57,
        71,
        66,
        25,
        93,
        0,
        1,
        0,
        45,
        99,
        99,
        38,
        99,
        99,
        77,
        99,
        86,
        99,
        62
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        137,
        70,
        173,
        23,
        198,
        197,
        57,
        129,
        2,
        49,
        188,
        40,
        174,
        22,
        191,
        88,
        156,
        56,
        197,
        31,
        34,
        147,
        41,
        52,
        3,
        198,
        175,
        197,
        0,
        152,
        151,
        34,
        197,
        130,
        108,
        199,
        127,
        186,
        140,
        6,
        0,
        194,
        87,
        59,
        29,
        113,
        187,
        160,
        130,
        161,
        113,
        196
      ],
      "weeklyWasted": [
        108,
        199,
        127,
        186,
        140,
        6,
        0,
        194,
        87,
        59,
        29,
        113,
        187,
        160,
        130,
        161,
        113,
        196,
        3,
        3,
        42,
        2,
        98,
        19,
        25,
        0,
        99,
        99,
        99,
        3,
        59,
        66,
        55,
        1,
        0,
        50,
        1,
        35,
        53,
        30,
        67,
        11,
        48,
        99,
        48,
        70,
        54,
        7,
        22,
        30,
        31,
        79,
        20,
        0,
        14,
        76,
        9,
        99,
        17,
        0,
        99,
        78,
        67,
        99,
        79,
        41,
        99,
        89,
        99,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        55,
        101,
        52,
        5,
        73,
        134,
        84,
        3,
        74,
        29,
        167,
        109,
        153,
        12,
        155,
        191,
        134,
        119,
        34,
        187,
        5,
        4,
        2,
        84,
        90,
        144,
        166,
        1,
        157,
        37,
        45,
        0,
        56,
        38,
        32,
        24,
        198,
        5,
        152,
        0,
        67,
        184,
        67,
        0,
        43,
        166,
        0,
        0,
        80,
        52,
        2,
        68
      ],
      "weeklyWasted": [
        32,
        24,
        198,
        5,
        152,
        0,
        67,
        184,
        67,
        0,
        43,
        166,
        0,
        0,
        80,
        52,
        2,
        68,
        99,
        1,
        14,
        49,
        65,
        28,
        0,
        0,
        86,
        38,
        48,
        56,
        13,
        87,
        26,
        98,
        14,
        54,
        78,
        56,
        2,
        62,
        6,
        0,
        28,
        52,
        44,
        48,
        14,
        34,
        86,
        48,
        63,
        72,
        57,
        89,
        66,
        80,
        42,
        99,
        78,
        80,
        62,
        99,
        95,
        20,
        43,
        11,
        11,
        76,
        99,
        63
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        88,
        190,
        0,
        57,
        0,
        140,
        31,
        57,
        195,
        158,
        199,
        10,
        198,
        79,
        132,
        81,
        192,
        57,
        74,
        122,
        195,
        79,
        100,
        160,
        3,
        131,
        138,
        175,
        104,
        75,
        53,
        0,
        109,
        199,
        135,
        183,
        49,
        5,
        57,
        24,
        0,
        96,
        0,
        188,
        65,
        116,
        0,
        68,
        169,
        199,
        69,
        183
      ],
      "weeklyWasted": [
        135,
        183,
        49,
        5,
        57,
        24,
        0,
        96,
        0,
        188,
        65,
        116,
        0,
        68,
        169,
        199,
        69,
        183,
        94,
        33,
        99,
        2,
        56,
        39,
        34,
        45,
        72,
        99,
        90,
        1,
        99,
        87,
        83,
        48,
        18,
        8,
        98,
        66,
        79,
        64,
        95,
        91,
        55,
        52,
        41,
        37,
        43,
        37,
        2,
        76,
        57,
        98,
        26,
        9,
        76,
        38,
        45,
        99,
        36,
        39,
        99,
        88,
        99,
        15,
        49,
        92,
        99,
        99,
        26,
        1
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyUsage": [
        131,
        88,
        71,
        190,
        76,
        81,
        194,
        196,
        124,
        145,
        199,
        2,
        119,
        2,
        1,
        3,
        162,
        199,
        3,
        43,
        105,
        10,
        195,
        192,
        3,
        198,
        170,
        94,
        104,
        128,
        0,
        96,
        1,
        138,
        177,
        171,
        77,
        116,
        84,
        54,
        24,
        29,
        199,
        119,
        1,
        158,
        1,
        28,
        198,
        96,
        2,
        67
      ],
      "weeklyWasted": [
        177,
        171,
        77,
        116,
        84,
        54,
        24,
        29,
        199,
        119,
        1,
        158,
        1,
        28,
        198,
        96,
        2,
        67,
        36,
        71,
        27,
        55,
        75,
        49,
        83,
        30,
        99,
        99,
        38,
        18,
        52,
        95,
        66,
        98,
        63,
        61,
        68,
        54,
        92,
        58,
        56,
        34,
        52,
        16,
        65,
        41,
        76,
        1,
        70,
        97,
        19,
        98,
        22,
        0,
        73,
        82,
        3,
        87,
        56,
        76,
        59,
        99,
        86,
        92,
        74,
        38,
        61,
        99,
        99,
        58
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        199,
        98,
        185,
        85,
        198,
        133,
        133,
        20,
        99,
        1,
        193,
        52,
        198,
        0,
        10,
        28,
        192,
        199,
        3,
        5,
        173,
        198,
        160,
        28,
        101,
        46,
        159,
        164,
        54,
        77,
        199,
        168,
        1,
        4,
        109,
        117,
        136,
        125,
        10,
        0,
        142,
        0,
        94,
        0,
        199,
        179,
        100,
        109,
        198,
        199,
        153,
        42
      ],
      "weeklyWasted": [
        109,
        117,
        136,
        125,
        10,
        0,
        142,
        0,
        94,
        0,
        199,
        179,
        100,
        109,
        198,
        199,
        153,
        42,
        1,
        1,
        0,
        64,
        48,
        98,
        99,
        0,
        99,
        0,
        48,
        30,
        62,
        39,
        65,
        98,
        98,
        7,
        46,
        35,
        99,
        19,
        7,
        76,
        20,
        99,
        0,
        50,
        31,
        26,
        49,
        87,
        60,
        36,
        78,
        29,
        78,
        0,
        54,
        99,
        20,
        41,
        65,
        71,
        57,
        95,
        99,
        98,
        39,
        99,
        43,
        10
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        84,
        46,
        30,
        41,
        36,
        104,
        20,
        3,
        92,
        1,
        132,
        193,
        156,
        90,
        125,
        131,
        192,
        136,
        147,
        46,
        162,
        4,
        2,
        57,
        129,
        39,
        118,
        98,
        199,
        99,
        0,
        84,
        1,
        70,
        5,
        77,
        198,
        49,
        51,
        12,
        6,
        83,
        0,
        40,
        104,
        182,
        71,
        2,
        198,
        115,
        39,
        184
      ],
      "weeklyWasted": [
        5,
        77,
        198,
        49,
        51,
        12,
        6,
        83,
        0,
        40,
        104,
        182,
        71,
        2,
        198,
        115,
        39,
        184,
        68,
        35,
        98,
        2,
        69,
        40,
        99,
        17,
        99,
        30,
        30,
        36,
        64,
        99,
        33,
        6,
        97,
        32,
        1,
        78,
        55,
        68,
        4,
        52,
        50,
        99,
        54,
        74,
        2,
        25,
        95,
        74,
        99,
        98,
        47,
        70,
        99,
        85,
        99,
        1,
        49,
        67,
        99,
        99,
        69,
        33,
        78,
        49,
        99,
        60,
        99,
        29
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        199,
        2,
        86,
        89,
        131,
        197,
        164,
        3,
        73,
        122,
        125,
        2,
        97,
        0,
        131,
        83,
        192,
        109,
        166,
        2,
        109,
        124,
        127,
        195,
        182,
        89,
        121,
        124,
        121,
        113,
        169,
        114,
        154,
        165,
        47,
        78,
        73,
        5,
        107,
        21,
        51,
        199,
        199,
        125,
        136,
        121,
        87,
        54,
        154,
        155,
        2,
        11
      ],
      "weeklyWasted": [
        47,
        78,
        73,
        5,
        107,
        21,
        51,
        199,
        199,
        125,
        136,
        121,
        87,
        54,
        154,
        155,
        2,
        11,
        52,
        98,
        62,
        2,
        98,
        90,
        99,
        0,
        67,
        33,
        47,
        84,
        70,
        99,
        31,
        98,
        0,
        99,
        1,
        0,
        17,
        99,
        10,
        77,
        95,
        45,
        70,
        28,
        87,
        74,
        6,
        94,
        49,
        89,
        0,
        28,
        69,
        37,
        45,
        99,
        42,
        55,
        95,
        99,
        25,
        0,
        81,
        98,
        0,
        72,
        99,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        77,
        80,
        144,
        105,
        17,
        1,
        101,
        36,
        199,
        96,
        173,
        2,
        198,
        0,
        1,
        113,
        33,
        136,
        47,
        88,
        55,
        107,
        126,
        19,
        197,
        1,
        163,
        37,
        0,
        141,
        31,
        196,
        1,
        69,
        40,
        35,
        198,
        105,
        104,
        51,
        80,
        100,
        0,
        56,
        6,
        4,
        0,
        0,
        198,
        151,
        107,
        3
      ],
      "weeklyWasted": [
        40,
        35,
        198,
        105,
        104,
        51,
        80,
        100,
        0,
        56,
        6,
        4,
        0,
        0,
        198,
        151,
        107,
        3,
        7,
        4,
        1,
        54,
        98,
        95,
        29,
        0,
        60,
        0,
        42,
        50,
        71,
        84,
        0,
        98,
        26,
        92,
        13,
        38,
        45,
        15,
        32,
        60,
        0,
        22,
        81,
        40,
        41,
        44,
        10,
        97,
        30,
        30,
        99,
        19,
        99,
        63,
        0,
        83,
        27,
        0,
        99,
        27,
        97,
        64,
        37,
        98,
        92,
        23,
        99,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        186,
        22,
        102,
        5,
        110,
        103,
        3,
        59,
        126,
        54,
        101,
        2,
        198,
        197,
        1,
        121,
        150,
        1,
        80,
        103,
        70,
        134,
        111,
        192,
        136,
        57,
        176,
        74,
        199,
        81,
        114,
        157,
        121,
        4,
        144,
        199,
        124,
        163,
        45,
        89,
        0,
        57,
        0,
        9,
        1,
        75,
        33,
        27,
        1,
        131,
        174,
        72
      ],
      "weeklyWasted": [
        144,
        199,
        124,
        163,
        45,
        89,
        0,
        57,
        0,
        9,
        1,
        75,
        33,
        27,
        1,
        131,
        174,
        72,
        1,
        54,
        0,
        19,
        0,
        93,
        99,
        0,
        46,
        47,
        99,
        83,
        99,
        91,
        42,
        98,
        62,
        99,
        69,
        12,
        11,
        0,
        0,
        0,
        45,
        1,
        82,
        55,
        99,
        82,
        10,
        66,
        1,
        98,
        41,
        35,
        76,
        36,
        4,
        47,
        5,
        34,
        99,
        61,
        53,
        52,
        12,
        73,
        33,
        7,
        64,
        45
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        199,
        121,
        198,
        144,
        35,
        73,
        89,
        124,
        132,
        2,
        0,
        56,
        198,
        143,
        94,
        140,
        192,
        183,
        122,
        146,
        153,
        67,
        5,
        1,
        129,
        79,
        158,
        46,
        74,
        71,
        63,
        118,
        132,
        94,
        78,
        166,
        71,
        94,
        199,
        0,
        137,
        84,
        81,
        117,
        143,
        106,
        67,
        198,
        198,
        19,
        91,
        125
      ],
      "weeklyWasted": [
        78,
        166,
        71,
        94,
        199,
        0,
        137,
        84,
        81,
        117,
        143,
        106,
        67,
        198,
        198,
        19,
        91,
        125,
        96,
        61,
        85,
        2,
        98,
        17,
        39,
        24,
        45,
        56,
        64,
        1,
        52,
        74,
        8,
        1,
        0,
        32,
        26,
        66,
        49,
        0,
        63,
        53,
        35,
        50,
        63,
        79,
        37,
        88,
        91,
        21,
        78,
        98,
        99,
        65,
        99,
        41,
        93,
        99,
        3,
        82,
        99,
        84,
        99,
        0,
        67,
        67,
        48,
        77,
        30,
        14
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        199,
        12,
        129,
        60,
        96,
        90,
        145,
        53,
        2,
        47,
        164,
        80,
        57,
        0,
        199,
        3,
        192,
        169,
        197,
        197,
        189,
        82,
        72,
        177,
        158,
        152,
        166,
        29,
        66,
        122,
        195,
        40,
        1,
        4,
        198,
        81,
        112,
        85,
        11,
        40,
        125,
        0,
        6,
        24,
        1,
        164,
        0,
        0,
        198,
        86,
        2,
        3
      ],
      "weeklyWasted": [
        198,
        81,
        112,
        85,
        11,
        40,
        125,
        0,
        6,
        24,
        1,
        164,
        0,
        0,
        198,
        86,
        2,
        3,
        1,
        6,
        99,
        2,
        98,
        64,
        69,
        67,
        36,
        49,
        88,
        21,
        59,
        24,
        46,
        33,
        92,
        79,
        1,
        66,
        76,
        0,
        56,
        3,
        93,
        49,
        98,
        13,
        99,
        37,
        84,
        97,
        23,
        98,
        34,
        80,
        92,
        99,
        54,
        64,
        18,
        24,
        99,
        99,
        57,
        45,
        95,
        60,
        5,
        99,
        82,
        1
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        49,
        74,
        77,
        5,
        0,
        124,
        194,
        124,
        54,
        75,
        199,
        168,
        146,
        0,
        136,
        3,
        192,
        175,
        74,
        154,
        197,
        180,
        176,
        55,
        34,
        1,
        174,
        1,
        0,
        147,
        96,
        102,
        67,
        60,
        198,
        0,
        8,
        199,
        65,
        30,
        0,
        43,
        0,
        89,
        50,
        178,
        0,
        53,
        198,
        87,
        99,
        13
      ],
      "weeklyWasted": [
        198,
        0,
        8,
        199,
        65,
        30,
        0,
        43,
        0,
        89,
        50,
        178,
        0,
        53,
        198,
        87,
        99,
        13,
        42,
        1,
        43,
        94,
        36,
        5,
        71,
        13,
        20,
        48,
        7,
        29,
        59,
        57,
        0,
        1,
        37,
        72,
        41,
        84,
        2,
        0,
        38,
        0,
        65,
        99,
        82,
        8,
        99,
        69,
        93,
        16,
        90,
        50,
        99,
        58,
        42,
        98,
        53,
        1,
        0,
        25,
        97,
        99,
        99,
        35,
        94,
        98,
        51,
        1,
        46,
        17
      ]
    },
    {
      "id": "965102D9",
      "weeklyUsage": [
        145,
        57,
        59,
        70,
        0,
        152,
        174,
        3,
        112,
        1,
        34,
        2,
        81,
        0,
        183,
        64,
        191,
        97,
        31,
        147,
        66,
        139,
        74,
        151,
        3,
        112,
        135,
        1,
        122,
        85,
        0,
        0,
        1,
        94,
        198,
        17,
        139,
        95,
        14,
        0,
        131,
        43,
        86,
        170,
        79,
        186,
        46,
        0,
        193,
        101,
        31,
        3
      ],
      "weeklyWasted": [
        198,
        17,
        139,
        95,
        14,
        0,
        131,
        43,
        86,
        170,
        79,
        186,
        46,
        0,
        193,
        101,
        31,
        3,
        1,
        1,
        0,
        2,
        98,
        41,
        99,
        8,
        27,
        69,
        86,
        18,
        57,
        99,
        97,
        85,
        39,
        75,
        99,
        58,
        90,
        50,
        47,
        0,
        99,
        33,
        0,
        47,
        24,
        1,
        2,
        61,
        33,
        37,
        89,
        98,
        18,
        99,
        0,
        96,
        18,
        52,
        99,
        82,
        77,
        42,
        61,
        92,
        8,
        56,
        72,
        43
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyUsage": [
        123,
        66,
        198,
        82,
        77,
        145,
        143,
        114,
        111,
        1,
        12,
        150,
        47,
        16,
        199,
        3,
        192,
        38,
        151,
        47,
        180,
        101,
        78,
        41,
        3,
        1,
        162,
        162,
        114,
        0,
        118,
        0,
        71,
        137,
        193,
        82,
        101,
        91,
        10,
        4,
        199,
        23,
        0,
        0,
        110,
        111,
        85,
        193,
        198,
        199,
        4,
        196
      ],
      "weeklyWasted": [
        193,
        82,
        101,
        91,
        10,
        4,
        199,
        23,
        0,
        0,
        110,
        111,
        85,
        193,
        198,
        199,
        4,
        196,
        99,
        1,
        18,
        2,
        68,
        1,
        59,
        6,
        99,
        99,
        58,
        68,
        1,
        99,
        97,
        98,
        62,
        65,
        1,
        8,
        74,
        30,
        0,
        78,
        48,
        18,
        11,
        63,
        2,
        49,
        2,
        77,
        51,
        91,
        25,
        3,
        99,
        0,
        0,
        99,
        78,
        0,
        62,
        99,
        86,
        34,
        99,
        51,
        43,
        99,
        35,
        93
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        199,
        2,
        47,
        128,
        130,
        116,
        58,
        71,
        191,
        82,
        16,
        2,
        103,
        0,
        166,
        40,
        141,
        193,
        93,
        171,
        195,
        34,
        11,
        146,
        122,
        161,
        184,
        1,
        138,
        175,
        67,
        33,
        60,
        185,
        119,
        167,
        51,
        147,
        120,
        97,
        0,
        55,
        32,
        31,
        1,
        133,
        45,
        110,
        198,
        71,
        126,
        31
      ],
      "weeklyWasted": [
        119,
        167,
        51,
        147,
        120,
        97,
        0,
        55,
        32,
        31,
        1,
        133,
        45,
        110,
        198,
        71,
        126,
        31,
        99,
        60,
        5,
        3,
        56,
        14,
        73,
        44,
        26,
        0,
        74,
        73,
        42,
        99,
        97,
        80,
        90,
        77,
        43,
        74,
        81,
        61,
        27,
        42,
        99,
        87,
        31,
        45,
        87,
        25,
        47,
        50,
        40,
        98,
        96,
        11,
        77,
        59,
        44,
        99,
        44,
        8,
        31,
        77,
        99,
        0,
        92,
        44,
        94,
        58,
        21,
        63
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        7,
        80,
        0,
        18,
        0,
        1,
        90,
        27,
        179,
        194,
        199,
        2,
        91,
        65,
        166,
        136,
        115,
        173,
        3,
        168,
        197,
        19,
        98,
        26,
        126,
        147,
        148,
        1,
        199,
        159,
        107,
        80,
        107,
        4,
        65,
        0,
        150,
        108,
        199,
        197,
        0,
        0,
        87,
        68,
        93,
        109,
        117,
        0,
        198,
        128,
        170,
        14
      ],
      "weeklyWasted": [
        65,
        0,
        150,
        108,
        199,
        197,
        0,
        0,
        87,
        68,
        93,
        109,
        117,
        0,
        198,
        128,
        170,
        14,
        87,
        48,
        93,
        8,
        22,
        98,
        0,
        31,
        28,
        54,
        45,
        65,
        46,
        82,
        40,
        64,
        25,
        81,
        42,
        68,
        17,
        92,
        13,
        0,
        0,
        96,
        98,
        38,
        62,
        83,
        23,
        68,
        20,
        30,
        99,
        68,
        73,
        6,
        10,
        95,
        0,
        0,
        99,
        99,
        26,
        60,
        62,
        98,
        38,
        46,
        99,
        1
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        152,
        165,
        0,
        53,
        198,
        111,
        3,
        3,
        143,
        1,
        27,
        95,
        198,
        43,
        154,
        52,
        55,
        83,
        163,
        157,
        197,
        4,
        136,
        67,
        157,
        39,
        197,
        41,
        104,
        105,
        94,
        0,
        120,
        128,
        182,
        58,
        136,
        35,
        82,
        113,
        130,
        0,
        0,
        0,
        16,
        48,
        5,
        103,
        189,
        11,
        67,
        3
      ],
      "weeklyWasted": [
        182,
        58,
        136,
        35,
        82,
        113,
        130,
        0,
        0,
        0,
        16,
        48,
        5,
        103,
        189,
        11,
        67,
        3,
        87,
        1,
        52,
        2,
        98,
        65,
        99,
        0,
        99,
        99,
        99,
        17,
        11,
        28,
        27,
        98,
        35,
        37,
        43,
        99,
        65,
        10,
        13,
        0,
        19,
        50,
        26,
        49,
        2,
        68,
        64,
        97,
        99,
        70,
        99,
        8,
        26,
        73,
        70,
        79,
        20,
        42,
        61,
        99,
        99,
        0,
        73,
        56,
        0,
        92,
        0,
        1
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        199,
        17,
        87,
        23,
        0,
        135,
        40,
        14,
        148,
        55,
        58,
        36,
        95,
        0,
        199,
        101,
        181,
        171,
        36,
        50,
        110,
        5,
        197,
        97,
        3,
        99,
        197,
        30,
        184,
        129,
        54,
        0,
        1,
        50,
        4,
        0,
        174,
        5,
        114,
        36,
        63,
        163,
        28,
        74,
        1,
        45,
        195,
        120,
        97,
        29,
        139,
        3
      ],
      "weeklyWasted": [
        4,
        0,
        174,
        5,
        114,
        36,
        63,
        163,
        28,
        74,
        1,
        45,
        195,
        120,
        97,
        29,
        139,
        3,
        1,
        39,
        0,
        2,
        98,
        12,
        83,
        8,
        36,
        0,
        99,
        29,
        30,
        3,
        53,
        51,
        28,
        98,
        1,
        46,
        69,
        80,
        30,
        11,
        59,
        64,
        98,
        1,
        99,
        27,
        2,
        68,
        29,
        84,
        51,
        99,
        87,
        83,
        38,
        26,
        2,
        29,
        86,
        48,
        72,
        29,
        40,
        98,
        19,
        45,
        0,
        27
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyUsage": [
        73,
        126,
        0,
        139,
        75,
        89,
        87,
        143,
        193,
        61,
        61,
        5,
        150,
        109,
        132,
        110,
        150,
        199,
        66,
        31,
        159,
        198,
        46,
        125,
        3,
        114,
        146,
        39,
        152,
        146,
        80,
        54,
        47,
        10,
        13,
        133,
        198,
        42,
        62,
        124,
        0,
        0,
        90,
        88,
        70,
        90,
        0,
        108,
        198,
        35,
        121,
        139
      ],
      "weeklyWasted": [
        13,
        133,
        198,
        42,
        62,
        124,
        0,
        0,
        90,
        88,
        70,
        90,
        0,
        108,
        198,
        35,
        121,
        139,
        32,
        1,
        25,
        40,
        89,
        74,
        93,
        45,
        95,
        3,
        26,
        53,
        1,
        62,
        34,
        1,
        60,
        99,
        8,
        45,
        83,
        22,
        0,
        11,
        22,
        26,
        63,
        48,
        48,
        48,
        13,
        69,
        60,
        41,
        99,
        0,
        1,
        72,
        99,
        46,
        42,
        0,
        99,
        69,
        79,
        0,
        99,
        74,
        1,
        99,
        10,
        1
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyUsage": [
        199,
        65,
        171,
        5,
        16,
        1,
        177,
        45,
        80,
        1,
        163,
        49,
        124,
        136,
        199,
        131,
        150,
        159,
        3,
        27,
        159,
        4,
        2,
        45,
        104,
        1,
        197,
        18,
        38,
        142,
        23,
        101,
        1,
        170,
        123,
        102,
        44,
        7,
        101,
        28,
        0,
        0,
        40,
        0,
        18,
        161,
        95,
        138,
        122,
        128,
        75,
        196
      ],
      "weeklyWasted": [
        123,
        102,
        44,
        7,
        101,
        28,
        0,
        0,
        40,
        0,
        18,
        161,
        95,
        138,
        122,
        128,
        75,
        196,
        1,
        24,
        0,
        11,
        54,
        46,
        44,
        23,
        97,
        0,
        37,
        47,
        1,
        66,
        65,
        4,
        61,
        73,
        25,
        64,
        99,
        16,
        27,
        0,
        14,
        99,
        16,
        58,
        95,
        23,
        11,
        60,
        41,
        98,
        68,
        0,
        84,
        61,
        99,
        47,
        0,
        36,
        0,
        84,
        76,
        98,
        87,
        36,
        99,
        99,
        12,
        54
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        199,
        2,
        39,
        188,
        26,
        100,
        142,
        3,
        125,
        10,
        65,
        2,
        79,
        0,
        176,
        163,
        145,
        32,
        197,
        33,
        26,
        4,
        22,
        1,
        3,
        1,
        171,
        95,
        199,
        118,
        93,
        115,
        8,
        4,
        178,
        154,
        72,
        145,
        33,
        81,
        66,
        0,
        117,
        0,
        1,
        16,
        81,
        63,
        198,
        157,
        21,
        95
      ],
      "weeklyWasted": [
        178,
        154,
        72,
        145,
        33,
        81,
        66,
        0,
        117,
        0,
        1,
        16,
        81,
        63,
        198,
        157,
        21,
        95,
        37,
        1,
        99,
        59,
        98,
        98,
        19,
        14,
        3,
        0,
        34,
        83,
        69,
        99,
        0,
        32,
        0,
        74,
        22,
        0,
        99,
        0,
        0,
        7,
        65,
        75,
        98,
        86,
        38,
        54,
        10,
        44,
        1,
        71,
        22,
        9,
        50,
        41,
        70,
        6,
        30,
        69,
        28,
        69,
        99,
        61,
        1,
        23,
        73,
        10,
        88,
        1
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        92,
        115,
        93,
        98,
        110,
        192,
        194,
        43,
        189,
        1,
        61,
        49,
        71,
        33,
        35,
        61,
        181,
        173,
        165,
        47,
        39,
        122,
        197,
        195,
        188,
        198,
        197,
        6,
        168,
        55,
        100,
        0,
        1,
        112,
        198,
        6,
        105,
        38,
        10,
        127,
        47,
        199,
        9,
        198,
        76,
        172,
        0,
        55,
        175,
        159,
        67,
        3
      ],
      "weeklyWasted": [
        198,
        6,
        105,
        38,
        10,
        127,
        47,
        199,
        9,
        198,
        76,
        172,
        0,
        55,
        175,
        159,
        67,
        3,
        16,
        4,
        3,
        48,
        98,
        59,
        56,
        70,
        8,
        38,
        99,
        99,
        71,
        83,
        26,
        8,
        40,
        50,
        39,
        96,
        99,
        9,
        0,
        99,
        96,
        99,
        95,
        41,
        98,
        1,
        15,
        97,
        42,
        73,
        66,
        99,
        89,
        99,
        99,
        1,
        0,
        98,
        99,
        99,
        48,
        0,
        63,
        82,
        0,
        97,
        75,
        38
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        147,
        124,
        190,
        5,
        68,
        15,
        194,
        39,
        199,
        1,
        35,
        66,
        198,
        0,
        107,
        36,
        192,
        171,
        144,
        78,
        187,
        11,
        82,
        156,
        3,
        90,
        58,
        118,
        191,
        88,
        0,
        41,
        138,
        156,
        198,
        196,
        136,
        185,
        108,
        28,
        141,
        54,
        0,
        27,
        1,
        116,
        61,
        0,
        198,
        2,
        180,
        114
      ],
      "weeklyWasted": [
        198,
        196,
        136,
        185,
        108,
        28,
        141,
        54,
        0,
        27,
        1,
        116,
        61,
        0,
        198,
        2,
        180,
        114,
        33,
        32,
        91,
        6,
        98,
        70,
        28,
        99,
        60,
        75,
        87,
        92,
        99,
        99,
        41,
        31,
        21,
        99,
        38,
        69,
        12,
        77,
        18,
        95,
        0,
        26,
        20,
        88,
        2,
        38,
        2,
        48,
        75,
        47,
        64,
        96,
        80,
        99,
        90,
        86,
        39,
        20,
        99,
        99,
        44,
        71,
        90,
        92,
        99,
        94,
        6,
        38
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        39,
        88,
        198,
        36,
        0,
        1,
        194,
        197,
        2,
        126,
        1,
        164,
        120,
        133,
        123,
        26,
        192,
        199,
        189,
        2,
        95,
        8,
        152,
        60,
        108,
        1,
        111,
        30,
        89,
        198,
        199,
        0,
        1,
        89,
        198,
        188,
        0,
        177,
        16,
        0,
        0,
        0,
        0,
        0,
        1,
        196,
        0,
        0,
        198,
        187,
        192,
        3
      ],
      "weeklyWasted": [
        198,
        188,
        0,
        177,
        16,
        0,
        0,
        0,
        0,
        0,
        1,
        196,
        0,
        0,
        198,
        187,
        192,
        3,
        73,
        44,
        16,
        2,
        40,
        65,
        71,
        1,
        99,
        80,
        71,
        12,
        78,
        70,
        0,
        49,
        62,
        1,
        20,
        65,
        49,
        26,
        35,
        0,
        0,
        78,
        52,
        79,
        56,
        77,
        14,
        66,
        76,
        97,
        20,
        37,
        61,
        65,
        17,
        99,
        27,
        80,
        99,
        81,
        28,
        51,
        99,
        62,
        97,
        99,
        41,
        96
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        165,
        2,
        15,
        5,
        48,
        151,
        37,
        142,
        70,
        1,
        34,
        126,
        185,
        144,
        199,
        3,
        192,
        195,
        111,
        2,
        104,
        112,
        120,
        125,
        119,
        73,
        160,
        1,
        111,
        198,
        183,
        43,
        88,
        143,
        4,
        44,
        40,
        5,
        34,
        103,
        199,
        0,
        0,
        0,
        40,
        96,
        127,
        54,
        196,
        20,
        83,
        99
      ],
      "weeklyWasted": [
        4,
        44,
        40,
        5,
        34,
        103,
        199,
        0,
        0,
        0,
        40,
        96,
        127,
        54,
        196,
        20,
        83,
        99,
        50,
        15,
        99,
        2,
        98,
        1,
        84,
        0,
        13,
        5,
        54,
        17,
        17,
        88,
        0,
        1,
        41,
        59,
        48,
        33,
        77,
        0,
        53,
        0,
        54,
        36,
        98,
        1,
        95,
        70,
        12,
        97,
        36,
        98,
        5,
        62,
        71,
        53,
        71,
        1,
        0,
        33,
        80,
        99,
        99,
        53,
        99,
        98,
        1,
        60,
        42,
        31
      ]
    },
    {
      "id": "958C4CED",
      "weeklyUsage": [
        199,
        123,
        70,
        105,
        198,
        7,
        23,
        101,
        2,
        133,
        191,
        2,
        21,
        64,
        96,
        98,
        171,
        86,
        3,
        109,
        88,
        4,
        176,
        183,
        3,
        146,
        158,
        111,
        199,
        94,
        40,
        150,
        81,
        4,
        198,
        139,
        198,
        35,
        169,
        0,
        0,
        138,
        69,
        57,
        28,
        112,
        105,
        0,
        198,
        133,
        2,
        36
      ],
      "weeklyWasted": [
        198,
        139,
        198,
        35,
        169,
        0,
        0,
        138,
        69,
        57,
        28,
        112,
        105,
        0,
        198,
        133,
        2,
        36,
        1,
        19,
        99,
        57,
        36,
        92,
        98,
        0,
        54,
        33,
        70,
        28,
        65,
        64,
        77,
        42,
        10,
        83,
        69,
        23,
        8,
        54,
        97,
        0,
        77,
        30,
        71,
        60,
        40,
        14,
        97,
        1,
        48,
        74,
        48,
        51,
        47,
        37,
        68,
        28,
        71,
        9,
        84,
        61,
        34,
        16,
        69,
        63,
        25,
        46,
        89,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyUsage": [
        68,
        176,
        0,
        149,
        110,
        152,
        154,
        3,
        2,
        1,
        134,
        71,
        52,
        199,
        92,
        3,
        192,
        185,
        64,
        32,
        1,
        79,
        2,
        168,
        178,
        144,
        197,
        120,
        199,
        148,
        51,
        73,
        152,
        160,
        62,
        37,
        105,
        5,
        146,
        101,
        101,
        62,
        199,
        0,
        1,
        196,
        47,
        180,
        198,
        142,
        2,
        185
      ],
      "weeklyWasted": [
        62,
        37,
        105,
        5,
        146,
        101,
        101,
        62,
        199,
        0,
        1,
        196,
        47,
        180,
        198,
        142,
        2,
        185,
        49,
        7,
        54,
        61,
        42,
        81,
        18,
        0,
        99,
        57,
        99,
        84,
        54,
        71,
        8,
        62,
        98,
        85,
        17,
        78,
        37,
        84,
        54,
        21,
        99,
        72,
        98,
        58,
        45,
        1,
        15,
        97,
        66,
        96,
        99,
        39,
        64,
        78,
        22,
        42,
        26,
        0,
        52,
        81,
        86,
        17,
        79,
        85,
        67,
        99,
        39,
        13
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        87,
        120,
        57,
        49,
        76,
        83,
        3,
        140,
        2,
        42,
        179,
        107,
        198,
        0,
        199,
        116,
        94,
        158,
        59,
        12,
        140,
        95,
        39,
        120,
        57,
        56,
        178,
        66,
        57,
        79,
        0,
        69,
        197,
        108,
        9,
        46,
        188,
        79,
        95,
        152,
        0,
        2,
        0,
        59,
        43,
        125,
        133,
        135,
        198,
        49,
        10,
        160
      ],
      "weeklyWasted": [
        9,
        46,
        188,
        79,
        95,
        152,
        0,
        2,
        0,
        59,
        43,
        125,
        133,
        135,
        198,
        49,
        10,
        160,
        1,
        4,
        86,
        83,
        69,
        22,
        71,
        1,
        93,
        49,
        18,
        59,
        1,
        99,
        54,
        29,
        57,
        57,
        51,
        97,
        2,
        44,
        17,
        0,
        39,
        56,
        85,
        97,
        2,
        50,
        2,
        18,
        45,
        46,
        86,
        30,
        45,
        60,
        9,
        99,
        85,
        7,
        17,
        99,
        99,
        93,
        99,
        98,
        85,
        81,
        30,
        5
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        85,
        2,
        198,
        144,
        139,
        175,
        52,
        35,
        41,
        1,
        132,
        28,
        122,
        44,
        157,
        3,
        11,
        103,
        167,
        197,
        162,
        25,
        2,
        121,
        38,
        198,
        169,
        1,
        199,
        52,
        70,
        63,
        72,
        199,
        198,
        64,
        52,
        86,
        126,
        91,
        93,
        125,
        75,
        49,
        61,
        187,
        98,
        92,
        198,
        121,
        100,
        88
      ],
      "weeklyWasted": [
        198,
        64,
        52,
        86,
        126,
        91,
        93,
        125,
        75,
        49,
        61,
        187,
        98,
        92,
        198,
        121,
        100,
        88,
        47,
        3,
        5,
        40,
        24,
        57,
        67,
        0,
        27,
        33,
        60,
        85,
        99,
        86,
        33,
        43,
        0,
        2,
        36,
        99,
        43,
        57,
        0,
        79,
        0,
        74,
        98,
        47,
        99,
        50,
        2,
        70,
        43,
        98,
        25,
        27,
        30,
        40,
        0,
        86,
        3,
        62,
        72,
        58,
        80,
        60,
        66,
        98,
        0,
        65,
        27,
        56
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        125,
        2,
        48,
        41,
        106,
        171,
        33,
        3,
        53,
        35,
        73,
        32,
        196,
        0,
        199,
        3,
        175,
        110,
        64,
        197,
        102,
        150,
        47,
        91,
        3,
        197,
        197,
        40,
        51,
        72,
        16,
        59,
        1,
        163,
        4,
        105,
        100,
        112,
        96,
        3,
        192,
        82,
        78,
        23,
        78,
        196,
        181,
        85,
        66,
        182,
        2,
        3
      ],
      "weeklyWasted": [
        4,
        105,
        100,
        112,
        96,
        3,
        192,
        82,
        78,
        23,
        78,
        196,
        181,
        85,
        66,
        182,
        2,
        3,
        67,
        1,
        12,
        2,
        98,
        34,
        99,
        0,
        9,
        68,
        38,
        1,
        31,
        49,
        2,
        1,
        63,
        1,
        27,
        44,
        48,
        28,
        97,
        58,
        0,
        7,
        52,
        57,
        90,
        9,
        3,
        97,
        1,
        98,
        72,
        40,
        80,
        37,
        83,
        78,
        55,
        0,
        99,
        99,
        0,
        39,
        99,
        76,
        99,
        55,
        38,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        87,
        158,
        137,
        135,
        57,
        95,
        88,
        61,
        106,
        85,
        175,
        75,
        143,
        101,
        15,
        3,
        122,
        124,
        3,
        193,
        130,
        90,
        68,
        66,
        94,
        198,
        152,
        31,
        47,
        193,
        104,
        48,
        104,
        196,
        46,
        186,
        47,
        5,
        17,
        101,
        39,
        138,
        90,
        198,
        1,
        196,
        92,
        99,
        196,
        31,
        79,
        3
      ],
      "weeklyWasted": [
        46,
        186,
        47,
        5,
        17,
        101,
        39,
        138,
        90,
        198,
        1,
        196,
        92,
        99,
        196,
        31,
        79,
        3,
        64,
        44,
        49,
        7,
        55,
        11,
        27,
        42,
        56,
        67,
        0,
        51,
        64,
        62,
        92,
        1,
        14,
        61,
        27,
        20,
        14,
        10,
        15,
        30,
        0,
        1,
        75,
        21,
        10,
        1,
        2,
        69,
        68,
        78,
        99,
        57,
        1,
        8,
        76,
        39,
        0,
        0,
        77,
        56,
        56,
        5,
        46,
        33,
        20,
        90,
        99,
        58
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        126,
        123,
        0,
        195,
        69,
        197,
        194,
        42,
        199,
        21,
        120,
        2,
        28,
        37,
        17,
        190,
        68,
        1,
        44,
        166,
        32,
        86,
        196,
        160,
        148,
        157,
        124,
        1,
        0,
        145,
        45,
        110,
        18,
        4,
        119,
        199,
        198,
        40,
        10,
        199,
        128,
        22,
        0,
        54,
        47,
        167,
        0,
        37,
        198,
        125,
        80,
        108
      ],
      "weeklyWasted": [
        119,
        199,
        198,
        40,
        10,
        199,
        128,
        22,
        0,
        54,
        47,
        167,
        0,
        37,
        198,
        125,
        80,
        108,
        73,
        4,
        99,
        50,
        98,
        98,
        11,
        0,
        72,
        69,
        56,
        36,
        32,
        93,
        51,
        98,
        41,
        56,
        3,
        29,
        58,
        96,
        35,
        21,
        18,
        51,
        98,
        60,
        53,
        50,
        99,
        1,
        3,
        9,
        73,
        25,
        56,
        71,
        33,
        91,
        0,
        78,
        0,
        1,
        15,
        30,
        33,
        98,
        46,
        65,
        99,
        59
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        192,
        79,
        116,
        45,
        32,
        4,
        62,
        74,
        107,
        80,
        85,
        154,
        188,
        115,
        199,
        198,
        117,
        101,
        61,
        2,
        173,
        154,
        163,
        42,
        113,
        135,
        197,
        34,
        138,
        110,
        121,
        161,
        141,
        4,
        163,
        48,
        198,
        5,
        180,
        199,
        0,
        30,
        55,
        0,
        118,
        163,
        195,
        0,
        198,
        164,
        192,
        23
      ],
      "weeklyWasted": [
        163,
        48,
        198,
        5,
        180,
        199,
        0,
        30,
        55,
        0,
        118,
        163,
        195,
        0,
        198,
        164,
        192,
        23,
        33,
        13,
        17,
        2,
        0,
        43,
        81,
        0,
        61,
        0,
        99,
        28,
        5,
        62,
        0,
        12,
        52,
        61,
        1,
        61,
        48,
        0,
        50,
        69,
        99,
        47,
        70,
        4,
        81,
        1,
        2,
        97,
        25,
        90,
        99,
        56,
        99,
        58,
        99,
        81,
        0,
        0,
        79,
        99,
        65,
        95,
        28,
        98,
        50,
        50,
        80,
        3
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        51,
        106,
        0,
        23,
        103,
        71,
        59,
        73,
        2,
        7,
        199,
        67,
        10,
        0,
        166,
        4,
        192,
        88,
        106,
        16,
        197,
        10,
        197,
        116,
        3,
        10,
        183,
        122,
        53,
        82,
        63,
        0,
        31,
        85,
        98,
        158,
        46,
        137,
        10,
        46,
        130,
        0,
        80,
        10,
        1,
        196,
        0,
        21,
        130,
        153,
        40,
        168
      ],
      "weeklyWasted": [
        98,
        158,
        46,
        137,
        10,
        46,
        130,
        0,
        80,
        10,
        1,
        196,
        0,
        21,
        130,
        153,
        40,
        168,
        37,
        9,
        13,
        18,
        93,
        1,
        99,
        12,
        99,
        14,
        99,
        55,
        1,
        24,
        32,
        81,
        67,
        35,
        39,
        27,
        53,
        0,
        97,
        1,
        0,
        99,
        54,
        97,
        42,
        87,
        77,
        7,
        99,
        98,
        31,
        35,
        99,
        77,
        56,
        51,
        3,
        0,
        57,
        99,
        99,
        14,
        99,
        98,
        99,
        45,
        99,
        1
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        95,
        2,
        163,
        5,
        54,
        197,
        156,
        144,
        130,
        1,
        116,
        126,
        195,
        74,
        168,
        3,
        155,
        93,
        168,
        13,
        90,
        161,
        99,
        174,
        3,
        198,
        173,
        30,
        134,
        80,
        112,
        47,
        154,
        140,
        158,
        96,
        54,
        134,
        103,
        169,
        35,
        163,
        0,
        48,
        17,
        32,
        0,
        27,
        198,
        157,
        197,
        99
      ],
      "weeklyWasted": [
        158,
        96,
        54,
        134,
        103,
        169,
        35,
        163,
        0,
        48,
        17,
        32,
        0,
        27,
        198,
        157,
        197,
        99,
        45,
        51,
        14,
        2,
        98,
        32,
        21,
        0,
        15,
        74,
        91,
        47,
        44,
        99,
        58,
        33,
        50,
        36,
        1,
        99,
        2,
        0,
        30,
        21,
        40,
        13,
        98,
        75,
        88,
        58,
        28,
        75,
        34,
        98,
        24,
        43,
        28,
        82,
        63,
        68,
        0,
        0,
        87,
        99,
        36,
        57,
        65,
        68,
        17,
        99,
        83,
        53
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyUsage": [
        105,
        98,
        42,
        34,
        0,
        24,
        150,
        110,
        161,
        62,
        0,
        79,
        96,
        0,
        121,
        134,
        98,
        147,
        66,
        136,
        13,
        99,
        159,
        195,
        3,
        198,
        197,
        1,
        199,
        108,
        6,
        170,
        64,
        199,
        4,
        0,
        173,
        78,
        10,
        115,
        167,
        91,
        110,
        84,
        13,
        196,
        79,
        33,
        198,
        62,
        94,
        98
      ],
      "weeklyWasted": [
        4,
        0,
        173,
        78,
        10,
        115,
        167,
        91,
        110,
        84,
        13,
        196,
        79,
        33,
        198,
        62,
        94,
        98,
        1,
        9,
        51,
        2,
        98,
        29,
        34,
        69,
        70,
        55,
        0,
        20,
        75,
        99,
        55,
        1,
        60,
        99,
        15,
        99,
        2,
        66,
        70,
        67,
        0,
        34,
        92,
        54,
        54,
        1,
        52,
        97,
        92,
        98,
        99,
        69,
        51,
        60,
        39,
        39,
        37,
        79,
        84,
        74,
        50,
        20,
        99,
        50,
        57,
        76,
        51,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        55,
        86,
        198,
        91,
        38,
        125,
        36,
        3,
        180,
        122,
        75,
        2,
        192,
        178,
        1,
        105,
        75,
        128,
        3,
        130,
        92,
        4,
        4,
        151,
        126,
        59,
        134,
        62,
        151,
        198,
        10,
        1,
        63,
        125,
        61,
        45,
        147,
        5,
        48,
        0,
        158,
        15,
        157,
        81,
        1,
        167,
        56,
        109,
        97,
        148,
        14,
        196
      ],
      "weeklyWasted": [
        61,
        45,
        147,
        5,
        48,
        0,
        158,
        15,
        157,
        81,
        1,
        167,
        56,
        109,
        97,
        148,
        14,
        196,
        1,
        1,
        66,
        36,
        39,
        1,
        99,
        0,
        99,
        93,
        18,
        38,
        47,
        95,
        18,
        98,
        31,
        75,
        99,
        91,
        27,
        87,
        53,
        10,
        37,
        99,
        64,
        62,
        35,
        1,
        2,
        47,
        53,
        98,
        99,
        34,
        26,
        39,
        57,
        1,
        64,
        71,
        11,
        99,
        99,
        83,
        72,
        98,
        6,
        99,
        51,
        1
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        171,
        81,
        0,
        25,
        39,
        176,
        8,
        157,
        119,
        126,
        193,
        9,
        110,
        126,
        116,
        105,
        191,
        100,
        197,
        133,
        150,
        70,
        82,
        44,
        129,
        174,
        57,
        38,
        116,
        25,
        10,
        133,
        197,
        134,
        103,
        45,
        108,
        145,
        42,
        136,
        0,
        170,
        0,
        163,
        69,
        147,
        40,
        107,
        198,
        155,
        175,
        154
      ],
      "weeklyWasted": [
        103,
        45,
        108,
        145,
        42,
        136,
        0,
        170,
        0,
        163,
        69,
        147,
        40,
        107,
        198,
        155,
        175,
        154,
        28,
        71,
        41,
        96,
        59,
        70,
        99,
        0,
        75,
        43,
        99,
        45,
        40,
        3,
        97,
        98,
        10,
        99,
        40,
        0,
        60,
        55,
        8,
        0,
        34,
        62,
        35,
        60,
        9,
        60,
        60,
        82,
        1,
        58,
        86,
        14,
        16,
        70,
        0,
        24,
        88,
        0,
        99,
        58,
        0,
        46,
        47,
        98,
        39,
        39,
        99,
        1
      ]
    },
    {
      "id": "11102F47",
      "weeklyUsage": [
        25,
        97,
        51,
        13,
        58,
        1,
        23,
        63,
        2,
        97,
        70,
        50,
        156,
        56,
        1,
        51,
        177,
        176,
        45,
        38,
        27,
        4,
        64,
        151,
        60,
        198,
        162,
        71,
        158,
        189,
        0,
        19,
        1,
        83,
        73,
        78,
        156,
        37,
        181,
        87,
        105,
        32,
        28,
        0,
        56,
        196,
        37,
        108,
        155,
        2,
        2,
        89
      ],
      "weeklyWasted": [
        73,
        78,
        156,
        37,
        181,
        87,
        105,
        32,
        28,
        0,
        56,
        196,
        37,
        108,
        155,
        2,
        2,
        89,
        75,
        1,
        3,
        2,
        98,
        93,
        68,
        0,
        67,
        59,
        33,
        1,
        35,
        52,
        50,
        1,
        42,
        66,
        68,
        76,
        2,
        99,
        36,
        21,
        23,
        99,
        46,
        51,
        68,
        1,
        80,
        80,
        99,
        63,
        27,
        11,
        49,
        44,
        84,
        12,
        18,
        93,
        99,
        99,
        84,
        0,
        99,
        98,
        51,
        30,
        47,
        42
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        75,
        2,
        198,
        31,
        198,
        197,
        25,
        3,
        152,
        103,
        199,
        127,
        2,
        10,
        119,
        153,
        125,
        199,
        83,
        104,
        197,
        4,
        155,
        108,
        11,
        1,
        191,
        26,
        0,
        95,
        199,
        68,
        68,
        54,
        4,
        0,
        198,
        199,
        70,
        154,
        0,
        163,
        7,
        30,
        155,
        145,
        195,
        115,
        198,
        50,
        2,
        176
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        199,
        70,
        154,
        0,
        163,
        7,
        30,
        155,
        145,
        195,
        115,
        198,
        50,
        2,
        176,
        57,
        19,
        15,
        30,
        6,
        71,
        28,
        24,
        14,
        21,
        91,
        99,
        99,
        67,
        0,
        1,
        98,
        51,
        1,
        34,
        23,
        44,
        96,
        20,
        30,
        99,
        87,
        72,
        38,
        3,
        25,
        83,
        31,
        69,
        76,
        58,
        83,
        73,
        0,
        1,
        0,
        1,
        99,
        99,
        0,
        99,
        99,
        98,
        99,
        42,
        27,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyUsage": [
        119,
        191,
        54,
        121,
        191,
        33,
        101,
        54,
        165,
        1,
        194,
        38,
        162,
        113,
        153,
        95,
        192,
        199,
        3,
        58,
        101,
        20,
        175,
        126,
        43,
        167,
        197,
        1,
        0,
        132,
        104,
        66,
        16,
        125,
        66,
        104,
        0,
        100,
        79,
        121,
        51,
        105,
        184,
        175,
        1,
        95,
        27,
        71,
        198,
        114,
        81,
        62
      ],
      "weeklyWasted": [
        66,
        104,
        0,
        100,
        79,
        121,
        51,
        105,
        184,
        175,
        1,
        95,
        27,
        71,
        198,
        114,
        81,
        62,
        26,
        50,
        15,
        48,
        98,
        7,
        99,
        67,
        82,
        99,
        81,
        22,
        10,
        76,
        93,
        27,
        49,
        37,
        62,
        60,
        53,
        32,
        28,
        0,
        23,
        55,
        37,
        69,
        99,
        9,
        74,
        65,
        99,
        98,
        76,
        49,
        7,
        0,
        97,
        69,
        39,
        18,
        28,
        75,
        99,
        26,
        99,
        98,
        9,
        65,
        65,
        71
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        199,
        192,
        130,
        23,
        71,
        148,
        110,
        81,
        76,
        78,
        199,
        119,
        146,
        0,
        133,
        31,
        161,
        110,
        45,
        143,
        136,
        129,
        45,
        119,
        51,
        152,
        110,
        1,
        103,
        49,
        52,
        0,
        107,
        82,
        96,
        79,
        198,
        5,
        10,
        76,
        42,
        39,
        0,
        0,
        1,
        157,
        164,
        83,
        90,
        2,
        178,
        173
      ],
      "weeklyWasted": [
        96,
        79,
        198,
        5,
        10,
        76,
        42,
        39,
        0,
        0,
        1,
        157,
        164,
        83,
        90,
        2,
        178,
        173,
        47,
        78,
        39,
        54,
        98,
        56,
        1,
        0,
        68,
        36,
        99,
        49,
        67,
        71,
        81,
        18,
        98,
        83,
        99,
        76,
        64,
        17,
        12,
        0,
        0,
        64,
        36,
        54,
        99,
        6,
        16,
        31,
        1,
        79,
        55,
        24,
        48,
        54,
        61,
        64,
        11,
        74,
        58,
        99,
        39,
        85,
        77,
        47,
        42,
        72,
        99,
        1
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyUsage": [
        101,
        34,
        52,
        136,
        19,
        93,
        194,
        46,
        195,
        1,
        36,
        58,
        198,
        79,
        49,
        86,
        141,
        173,
        95,
        177,
        34,
        4,
        64,
        141,
        3,
        10,
        135,
        162,
        199,
        25,
        199,
        59,
        23,
        199,
        14,
        157,
        102,
        41,
        10,
        78,
        70,
        97,
        88,
        87,
        94,
        98,
        0,
        99,
        185,
        151,
        116,
        3
      ],
      "weeklyWasted": [
        14,
        157,
        102,
        41,
        10,
        78,
        70,
        97,
        88,
        87,
        94,
        98,
        0,
        99,
        185,
        151,
        116,
        3,
        49,
        1,
        38,
        2,
        98,
        28,
        30,
        58,
        40,
        20,
        40,
        1,
        64,
        87,
        33,
        12,
        19,
        69,
        22,
        55,
        54,
        43,
        3,
        34,
        0,
        90,
        0,
        1,
        25,
        4,
        64,
        97,
        99,
        17,
        30,
        9,
        59,
        16,
        64,
        32,
        65,
        31,
        99,
        66,
        56,
        16,
        99,
        98,
        93,
        96,
        28,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyUsage": [
        199,
        122,
        191,
        34,
        198,
        197,
        192,
        131,
        23,
        198,
        90,
        35,
        173,
        0,
        119,
        66,
        192,
        199,
        95,
        4,
        197,
        73,
        177,
        188,
        157,
        109,
        168,
        100,
        199,
        127,
        5,
        80,
        92,
        199,
        88,
        147,
        198,
        21,
        55,
        0,
        74,
        0,
        80,
        72,
        40,
        4,
        52,
        198,
        119,
        145,
        2,
        167
      ],
      "weeklyWasted": [
        88,
        147,
        198,
        21,
        55,
        0,
        74,
        0,
        80,
        72,
        40,
        4,
        52,
        198,
        119,
        145,
        2,
        167,
        47,
        1,
        67,
        2,
        82,
        63,
        73,
        0,
        43,
        24,
        37,
        45,
        20,
        15,
        72,
        50,
        50,
        77,
        13,
        0,
        4,
        11,
        59,
        27,
        38,
        97,
        63,
        6,
        59,
        13,
        10,
        86,
        62,
        8,
        74,
        63,
        60,
        59,
        58,
        99,
        72,
        6,
        98,
        28,
        99,
        54,
        56,
        69,
        50,
        99,
        52,
        38
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        25,
        2,
        93,
        5,
        0,
        197,
        194,
        89,
        103,
        33,
        66,
        119,
        91,
        0,
        199,
        15,
        157,
        180,
        3,
        44,
        47,
        102,
        140,
        42,
        134,
        198,
        194,
        61,
        199,
        141,
        36,
        0,
        149,
        96,
        107,
        87,
        3,
        5,
        74,
        133,
        151,
        154,
        156,
        36,
        130,
        123,
        117,
        129,
        198,
        82,
        87,
        18
      ],
      "weeklyWasted": [
        107,
        87,
        3,
        5,
        74,
        133,
        151,
        154,
        156,
        36,
        130,
        123,
        117,
        129,
        198,
        82,
        87,
        18,
        99,
        1,
        5,
        10,
        80,
        51,
        85,
        0,
        73,
        47,
        46,
        61,
        65,
        97,
        61,
        1,
        98,
        32,
        35,
        15,
        46,
        61,
        97,
        70,
        64,
        99,
        55,
        1,
        65,
        16,
        38,
        97,
        28,
        58,
        99,
        13,
        48,
        92,
        40,
        99,
        0,
        0,
        61,
        99,
        99,
        46,
        0,
        16,
        76,
        99,
        40,
        26
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        33,
        76,
        198,
        59,
        106,
        1,
        194,
        121,
        2,
        19,
        180,
        120,
        170,
        143,
        199,
        54,
        192,
        184,
        72,
        2,
        47,
        198,
        47,
        131,
        3,
        54,
        107,
        70,
        0,
        106,
        120,
        169,
        148,
        199,
        56,
        199,
        13,
        113,
        89,
        178,
        66,
        26,
        0,
        18,
        199,
        196,
        88,
        99,
        79,
        100,
        134,
        46
      ],
      "weeklyWasted": [
        56,
        199,
        13,
        113,
        89,
        178,
        66,
        26,
        0,
        18,
        199,
        196,
        88,
        99,
        79,
        100,
        134,
        46,
        45,
        1,
        60,
        12,
        63,
        12,
        99,
        9,
        99,
        0,
        99,
        18,
        1,
        49,
        43,
        13,
        60,
        67,
        53,
        64,
        43,
        0,
        39,
        23,
        59,
        99,
        0,
        42,
        22,
        39,
        2,
        97,
        1,
        98,
        50,
        0,
        81,
        20,
        22,
        30,
        59,
        0,
        99,
        48,
        99,
        45,
        99,
        98,
        99,
        52,
        34,
        99
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyUsage": [
        162,
        25,
        0,
        117,
        140,
        123,
        184,
        3,
        2,
        1,
        0,
        2,
        198,
        31,
        1,
        167,
        192,
        140,
        148,
        71,
        39,
        134,
        82,
        195,
        197,
        198,
        197,
        55,
        174,
        138,
        0,
        47,
        78,
        4,
        4,
        0,
        73,
        70,
        121,
        97,
        199,
        0,
        127,
        17,
        1,
        6,
        113,
        0,
        38,
        159,
        2,
        70
      ],
      "weeklyWasted": [
        4,
        0,
        73,
        70,
        121,
        97,
        199,
        0,
        127,
        17,
        1,
        6,
        113,
        0,
        38,
        159,
        2,
        70,
        16,
        11,
        0,
        14,
        91,
        1,
        99,
        43,
        71,
        54,
        3,
        69,
        95,
        41,
        34,
        85,
        22,
        99,
        74,
        0,
        66,
        0,
        0,
        29,
        24,
        53,
        78,
        54,
        38,
        1,
        7,
        97,
        1,
        45,
        67,
        40,
        11,
        0,
        99,
        15,
        18,
        67,
        73,
        84,
        97,
        39,
        60,
        98,
        25,
        72,
        77,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        123,
        2,
        0,
        37,
        0,
        83,
        113,
        45,
        199,
        1,
        177,
        101,
        113,
        0,
        199,
        187,
        179,
        192,
        94,
        8,
        197,
        59,
        118,
        81,
        3,
        117,
        197,
        44,
        199,
        45,
        73,
        0,
        1,
        158,
        68,
        97,
        115,
        64,
        31,
        105,
        52,
        0,
        129,
        50,
        35,
        167,
        0,
        63,
        195,
        2,
        119,
        3
      ],
      "weeklyWasted": [
        68,
        97,
        115,
        64,
        31,
        105,
        52,
        0,
        129,
        50,
        35,
        167,
        0,
        63,
        195,
        2,
        119,
        3,
        61,
        79,
        4,
        10,
        83,
        98,
        0,
        0,
        55,
        51,
        54,
        88,
        99,
        26,
        61,
        98,
        0,
        79,
        8,
        38,
        48,
        15,
        2,
        64,
        0,
        52,
        57,
        1,
        64,
        37,
        2,
        97,
        1,
        90,
        98,
        70,
        39,
        66,
        70,
        98,
        0,
        38,
        99,
        47,
        84,
        48,
        99,
        37,
        58,
        89,
        38,
        45
      ]
    },
    {
      "id": 9202537,
      "weeklyUsage": [
        11,
        2,
        108,
        136,
        26,
        92,
        57,
        34,
        56,
        1,
        111,
        107,
        176,
        68,
        199,
        3,
        173,
        147,
        3,
        130,
        48,
        57,
        146,
        102,
        139,
        107,
        105,
        31,
        199,
        198,
        0,
        0,
        1,
        50,
        75,
        43,
        198,
        84,
        51,
        125,
        175,
        6,
        0,
        0,
        83,
        56,
        0,
        71,
        135,
        139,
        2,
        25
      ],
      "weeklyWasted": [
        75,
        43,
        198,
        84,
        51,
        125,
        175,
        6,
        0,
        0,
        83,
        56,
        0,
        71,
        135,
        139,
        2,
        25,
        30,
        25,
        35,
        43,
        94,
        25,
        99,
        0,
        88,
        70,
        14,
        42,
        1,
        59,
        29,
        33,
        82,
        48,
        28,
        65,
        72,
        0,
        97,
        0,
        37,
        89,
        54,
        59,
        96,
        25,
        21,
        63,
        51,
        75,
        35,
        31,
        32,
        99,
        38,
        46,
        1,
        60,
        95,
        99,
        49,
        34,
        99,
        14,
        10,
        43,
        48,
        48
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        199,
        22,
        12,
        67,
        95,
        197,
        3,
        14,
        75,
        1,
        71,
        110,
        88,
        152,
        199,
        127,
        185,
        168,
        75,
        178,
        197,
        195,
        113,
        195,
        110,
        155,
        145,
        96,
        102,
        68,
        14,
        0,
        197,
        4,
        158,
        65,
        126,
        19,
        199,
        139,
        65,
        60,
        94,
        194,
        63,
        170,
        145,
        62,
        198,
        104,
        179,
        140
      ],
      "weeklyWasted": [
        158,
        65,
        126,
        19,
        199,
        139,
        65,
        60,
        94,
        194,
        63,
        170,
        145,
        62,
        198,
        104,
        179,
        140,
        79,
        91,
        45,
        2,
        98,
        38,
        85,
        0,
        62,
        0,
        55,
        99,
        67,
        35,
        81,
        63,
        0,
        59,
        30,
        21,
        2,
        0,
        44,
        6,
        4,
        60,
        58,
        77,
        75,
        26,
        28,
        97,
        1,
        98,
        92,
        0,
        77,
        69,
        38,
        32,
        57,
        24,
        59,
        99,
        99,
        46,
        99,
        90,
        42,
        51,
        99,
        1
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        199,
        79,
        191,
        17,
        0,
        156,
        3,
        188,
        64,
        18,
        199,
        2,
        95,
        64,
        54,
        105,
        166,
        199,
        3,
        2,
        82,
        51,
        2,
        144,
        136,
        160,
        197,
        42,
        80,
        66,
        112,
        52,
        27,
        113,
        198,
        199,
        127,
        199,
        172,
        0,
        1,
        37,
        155,
        40,
        1,
        108,
        92,
        55,
        174,
        161,
        89,
        7
      ],
      "weeklyWasted": [
        198,
        199,
        127,
        199,
        172,
        0,
        1,
        37,
        155,
        40,
        1,
        108,
        92,
        55,
        174,
        161,
        89,
        7,
        91,
        57,
        0,
        2,
        98,
        1,
        41,
        0,
        92,
        53,
        71,
        67,
        33,
        84,
        62,
        98,
        72,
        11,
        99,
        74,
        64,
        0,
        8,
        0,
        70,
        99,
        55,
        78,
        99,
        13,
        2,
        60,
        23,
        98,
        43,
        0,
        99,
        37,
        90,
        1,
        0,
        98,
        88,
        99,
        42,
        72,
        44,
        98,
        70,
        1,
        32,
        21
      ]
    },
    {
      "id": "958C4CED",
      "weeklyUsage": [
        100,
        24,
        54,
        5,
        0,
        127,
        125,
        27,
        63,
        1,
        124,
        2,
        114,
        47,
        159,
        186,
        192,
        167,
        41,
        109,
        63,
        4,
        2,
        158,
        166,
        1,
        138,
        1,
        199,
        13,
        76,
        0,
        142,
        45,
        101,
        0,
        65,
        199,
        169,
        0,
        9,
        81,
        25,
        94,
        1,
        150,
        51,
        155,
        114,
        193,
        152,
        35
      ],
      "weeklyWasted": [
        101,
        0,
        65,
        199,
        169,
        0,
        9,
        81,
        25,
        94,
        1,
        150,
        51,
        155,
        114,
        193,
        152,
        35,
        86,
        98,
        38,
        2,
        49,
        83,
        99,
        5,
        33,
        85,
        54,
        50,
        99,
        3,
        41,
        33,
        77,
        99,
        83,
        61,
        43,
        0,
        52,
        31,
        0,
        99,
        29,
        21,
        2,
        43,
        66,
        86,
        35,
        91,
        75,
        3,
        70,
        81,
        20,
        27,
        27,
        38,
        99,
        72,
        27,
        14,
        78,
        98,
        61,
        83,
        19,
        12
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        58,
        110,
        128,
        31,
        0,
        66,
        69,
        197,
        2,
        89,
        191,
        2,
        182,
        0,
        121,
        103,
        122,
        199,
        161,
        2,
        147,
        4,
        43,
        1,
        87,
        101,
        148,
        44,
        137,
        198,
        109,
        0,
        63,
        196,
        93,
        41,
        198,
        192,
        199,
        69,
        0,
        88,
        43,
        0,
        101,
        26,
        17,
        175,
        198,
        199,
        62,
        112
      ],
      "weeklyWasted": [
        93,
        41,
        198,
        192,
        199,
        69,
        0,
        88,
        43,
        0,
        101,
        26,
        17,
        175,
        198,
        199,
        62,
        112,
        1,
        23,
        52,
        16,
        86,
        84,
        4,
        0,
        99,
        38,
        37,
        65,
        99,
        68,
        1,
        1,
        57,
        54,
        24,
        99,
        29,
        99,
        53,
        19,
        62,
        99,
        35,
        21,
        76,
        43,
        14,
        67,
        33,
        98,
        88,
        21,
        99,
        57,
        13,
        88,
        0,
        37,
        45,
        60,
        40,
        35,
        68,
        98,
        99,
        93,
        42,
        1
      ]
    },
    {
      "id": "F04529EA",
      "weeklyUsage": [
        129,
        107,
        164,
        13,
        2,
        90,
        47,
        3,
        144,
        181,
        0,
        119,
        133,
        0,
        95,
        132,
        0,
        93,
        158,
        123,
        122,
        18,
        59,
        154,
        90,
        131,
        139,
        100,
        199,
        116,
        104,
        109,
        45,
        199,
        119,
        0,
        198,
        58,
        119,
        87,
        83,
        0,
        120,
        139,
        52,
        102,
        14,
        6,
        198,
        51,
        197,
        181
      ],
      "weeklyWasted": [
        119,
        0,
        198,
        58,
        119,
        87,
        83,
        0,
        120,
        139,
        52,
        102,
        14,
        6,
        198,
        51,
        197,
        181,
        1,
        1,
        58,
        38,
        98,
        98,
        19,
        10,
        86,
        58,
        99,
        98,
        71,
        43,
        0,
        41,
        48,
        99,
        39,
        99,
        2,
        85,
        4,
        0,
        87,
        99,
        74,
        59,
        56,
        60,
        2,
        97,
        1,
        98,
        99,
        15,
        6,
        99,
        99,
        41,
        0,
        66,
        79,
        44,
        11,
        71,
        9,
        93,
        0,
        19,
        52,
        9
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        162,
        78,
        148,
        22,
        152,
        70,
        194,
        197,
        54,
        1,
        81,
        101,
        175,
        199,
        199,
        3,
        133,
        82,
        153,
        58,
        197,
        33,
        197,
        60,
        3,
        75,
        179,
        145,
        198,
        198,
        199,
        151,
        197,
        199,
        10,
        188,
        162,
        179,
        12,
        36,
        72,
        197,
        0,
        50,
        55,
        94,
        67,
        43,
        198,
        115,
        161,
        25
      ],
      "weeklyWasted": [
        10,
        188,
        162,
        179,
        12,
        36,
        72,
        197,
        0,
        50,
        55,
        94,
        67,
        43,
        198,
        115,
        161,
        25,
        57,
        49,
        1,
        2,
        98,
        52,
        99,
        0,
        99,
        55,
        89,
        75,
        20,
        95,
        92,
        58,
        78,
        99,
        45,
        21,
        52,
        91,
        67,
        0,
        24,
        67,
        82,
        80,
        99,
        8,
        17,
        53,
        34,
        75,
        5,
        0,
        65,
        88,
        94,
        98,
        26,
        0,
        99,
        73,
        99,
        54,
        99,
        80,
        48,
        99,
        32,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        155,
        65,
        174,
        5,
        0,
        133,
        78,
        108,
        77,
        20,
        199,
        91,
        198,
        32,
        15,
        73,
        192,
        150,
        36,
        197,
        1,
        61,
        118,
        1,
        32,
        37,
        112,
        111,
        73,
        166,
        199,
        71,
        1,
        75,
        98,
        135,
        123,
        35,
        35,
        47,
        135,
        163,
        0,
        59,
        56,
        157,
        51,
        56,
        6,
        64,
        90,
        3
      ],
      "weeklyWasted": [
        98,
        135,
        123,
        35,
        35,
        47,
        135,
        163,
        0,
        59,
        56,
        157,
        51,
        56,
        6,
        64,
        90,
        3,
        45,
        1,
        41,
        48,
        98,
        9,
        99,
        0,
        57,
        79,
        26,
        1,
        53,
        32,
        92,
        42,
        13,
        97,
        1,
        57,
        5,
        1,
        20,
        15,
        56,
        96,
        67,
        33,
        69,
        6,
        59,
        76,
        24,
        70,
        99,
        29,
        85,
        99,
        99,
        95,
        46,
        41,
        99,
        47,
        99,
        77,
        99,
        87,
        41,
        33,
        40,
        1
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        199,
        129,
        0,
        144,
        0,
        16,
        126,
        192,
        146,
        107,
        9,
        91,
        173,
        0,
        199,
        69,
        175,
        26,
        17,
        197,
        106,
        57,
        154,
        49,
        81,
        182,
        104,
        60,
        139,
        198,
        69,
        2,
        123,
        177,
        198,
        196,
        55,
        62,
        120,
        102,
        51,
        199,
        0,
        198,
        1,
        40,
        40,
        0,
        188,
        2,
        157,
        3
      ],
      "weeklyWasted": [
        198,
        196,
        55,
        62,
        120,
        102,
        51,
        199,
        0,
        198,
        1,
        40,
        40,
        0,
        188,
        2,
        157,
        3,
        19,
        1,
        24,
        45,
        68,
        75,
        25,
        58,
        58,
        28,
        20,
        83,
        68,
        99,
        35,
        33,
        50,
        62,
        97,
        26,
        2,
        46,
        34,
        24,
        57,
        99,
        71,
        81,
        65,
        24,
        12,
        75,
        99,
        98,
        99,
        46,
        17,
        99,
        91,
        1,
        58,
        0,
        21,
        66,
        55,
        39,
        49,
        34,
        34,
        99,
        5,
        49
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        123,
        79,
        91,
        71,
        105,
        18,
        191,
        3,
        2,
        1,
        41,
        120,
        198,
        114,
        125,
        120,
        137,
        161,
        176,
        2,
        84,
        121,
        78,
        113,
        148,
        38,
        42,
        130,
        18,
        147,
        199,
        142,
        103,
        73,
        5,
        199,
        112,
        59,
        129,
        0,
        119,
        84,
        97,
        20,
        123,
        196,
        5,
        152,
        140,
        124,
        2,
        3
      ],
      "weeklyWasted": [
        5,
        199,
        112,
        59,
        129,
        0,
        119,
        84,
        97,
        20,
        123,
        196,
        5,
        152,
        140,
        124,
        2,
        3,
        49,
        11,
        12,
        29,
        44,
        98,
        69,
        0,
        72,
        16,
        25,
        34,
        32,
        99,
        0,
        41,
        5,
        82,
        28,
        0,
        99,
        0,
        72,
        99,
        26,
        80,
        24,
        44,
        15,
        30,
        26,
        37,
        28,
        98,
        73,
        64,
        66,
        85,
        26,
        51,
        99,
        0,
        99,
        4,
        99,
        79,
        99,
        66,
        53,
        68,
        61,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        173,
        65,
        0,
        5,
        198,
        53,
        3,
        44,
        45,
        128,
        199,
        49,
        79,
        199,
        80,
        83,
        131,
        1,
        34,
        2,
        159,
        4,
        58,
        146,
        151,
        87,
        181,
        81,
        0,
        111,
        199,
        148,
        90,
        4,
        4,
        0,
        85,
        5,
        105,
        47,
        12,
        157,
        4,
        75,
        42,
        69,
        60,
        0,
        46,
        143,
        14,
        184
      ],
      "weeklyWasted": [
        4,
        0,
        85,
        5,
        105,
        47,
        12,
        157,
        4,
        75,
        42,
        69,
        60,
        0,
        46,
        143,
        14,
        184,
        39,
        1,
        59,
        2,
        96,
        48,
        6,
        0,
        99,
        81,
        19,
        1,
        13,
        45,
        71,
        22,
        30,
        2,
        33,
        82,
        2,
        25,
        23,
        12,
        67,
        99,
        62,
        97,
        30,
        7,
        50,
        25,
        59,
        45,
        12,
        67,
        88,
        28,
        13,
        76,
        41,
        0,
        99,
        99,
        24,
        99,
        77,
        98,
        53,
        28,
        99,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        94,
        25,
        0,
        31,
        39,
        1,
        192,
        3,
        128,
        7,
        34,
        62,
        82,
        152,
        199,
        185,
        81,
        158,
        57,
        197,
        131,
        112,
        5,
        116,
        46,
        123,
        124,
        50,
        7,
        147,
        31,
        37,
        1,
        165,
        96,
        126,
        181,
        5,
        149,
        83,
        20,
        3,
        107,
        103,
        1,
        196,
        15,
        50,
        198,
        166,
        125,
        3
      ],
      "weeklyWasted": [
        96,
        126,
        181,
        5,
        149,
        83,
        20,
        3,
        107,
        103,
        1,
        196,
        15,
        50,
        198,
        166,
        125,
        3,
        99,
        7,
        99,
        54,
        87,
        53,
        61,
        54,
        39,
        96,
        99,
        51,
        48,
        14,
        72,
        34,
        28,
        99,
        43,
        88,
        31,
        91,
        97,
        0,
        0,
        30,
        98,
        64,
        81,
        91,
        24,
        23,
        56,
        64,
        99,
        42,
        28,
        99,
        30,
        33,
        0,
        0,
        31,
        99,
        71,
        99,
        38,
        98,
        99,
        83,
        99,
        1
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        142,
        69,
        95,
        5,
        31,
        150,
        3,
        3,
        113,
        96,
        92,
        108,
        178,
        73,
        133,
        79,
        192,
        148,
        104,
        78,
        69,
        4,
        60,
        121,
        30,
        72,
        197,
        1,
        0,
        113,
        87,
        0,
        61,
        132,
        11,
        74,
        38,
        132,
        135,
        63,
        15,
        17,
        74,
        58,
        82,
        142,
        3,
        159,
        88,
        32,
        33,
        98
      ],
      "weeklyWasted": [
        11,
        74,
        38,
        132,
        135,
        63,
        15,
        17,
        74,
        58,
        82,
        142,
        3,
        159,
        88,
        32,
        33,
        98,
        75,
        30,
        28,
        66,
        36,
        1,
        80,
        14,
        50,
        83,
        82,
        2,
        30,
        88,
        1,
        27,
        21,
        60,
        40,
        67,
        76,
        0,
        64,
        0,
        55,
        49,
        98,
        70,
        51,
        33,
        2,
        86,
        17,
        98,
        0,
        36,
        30,
        32,
        25,
        85,
        18,
        0,
        76,
        71,
        99,
        14,
        99,
        98,
        24,
        99,
        95,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        86,
        109,
        56,
        38,
        137,
        116,
        194,
        93,
        199,
        53,
        138,
        123,
        198,
        68,
        15,
        8,
        74,
        157,
        54,
        197,
        160,
        40,
        2,
        195,
        3,
        19,
        155,
        104,
        199,
        104,
        91,
        70,
        125,
        199,
        103,
        8,
        83,
        5,
        112,
        121,
        123,
        125,
        0,
        0,
        35,
        196,
        42,
        55,
        29,
        199,
        197,
        104
      ],
      "weeklyWasted": [
        103,
        8,
        83,
        5,
        112,
        121,
        123,
        125,
        0,
        0,
        35,
        196,
        42,
        55,
        29,
        199,
        197,
        104,
        99,
        11,
        99,
        2,
        67,
        43,
        77,
        14,
        70,
        99,
        52,
        40,
        15,
        9,
        0,
        18,
        71,
        99,
        28,
        35,
        55,
        89,
        0,
        23,
        38,
        45,
        0,
        14,
        2,
        54,
        57,
        54,
        77,
        55,
        89,
        13,
        55,
        61,
        12,
        1,
        46,
        95,
        93,
        70,
        74,
        0,
        49,
        31,
        27,
        63,
        55,
        53
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        163,
        2,
        198,
        22,
        84,
        60,
        134,
        58,
        2,
        1,
        199,
        71,
        123,
        196,
        123,
        75,
        192,
        174,
        62,
        2,
        48,
        130,
        143,
        38,
        90,
        87,
        58,
        43,
        53,
        198,
        102,
        77,
        106,
        162,
        193,
        199,
        177,
        171,
        126,
        112,
        158,
        154,
        0,
        181,
        199,
        170,
        195,
        58,
        157,
        34,
        131,
        54
      ],
      "weeklyWasted": [
        193,
        199,
        177,
        171,
        126,
        112,
        158,
        154,
        0,
        181,
        199,
        170,
        195,
        58,
        157,
        34,
        131,
        54,
        1,
        39,
        92,
        25,
        65,
        27,
        74,
        35,
        99,
        0,
        25,
        4,
        1,
        65,
        88,
        75,
        71,
        26,
        44,
        81,
        57,
        5,
        92,
        10,
        45,
        77,
        38,
        40,
        2,
        85,
        2,
        83,
        1,
        51,
        99,
        0,
        78,
        99,
        0,
        57,
        0,
        33,
        99,
        46,
        99,
        99,
        40,
        98,
        99,
        99,
        61,
        62
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        199,
        57,
        198,
        135,
        128,
        197,
        37,
        117,
        154,
        173,
        37,
        197,
        191,
        24,
        111,
        67,
        139,
        182,
        73,
        197,
        100,
        35,
        67,
        105,
        129,
        1,
        180,
        97,
        106,
        198,
        195,
        173,
        132,
        4,
        4,
        81,
        94,
        86,
        17,
        59,
        27,
        119,
        0,
        26,
        15,
        129,
        156,
        68,
        198,
        199,
        176,
        3
      ],
      "weeklyWasted": [
        4,
        81,
        94,
        86,
        17,
        59,
        27,
        119,
        0,
        26,
        15,
        129,
        156,
        68,
        198,
        199,
        176,
        3,
        6,
        17,
        0,
        57,
        45,
        27,
        65,
        3,
        70,
        45,
        99,
        97,
        24,
        99,
        33,
        1,
        46,
        94,
        7,
        14,
        76,
        38,
        0,
        33,
        22,
        99,
        28,
        20,
        16,
        96,
        11,
        35,
        28,
        97,
        99,
        15,
        64,
        56,
        0,
        49,
        0,
        20,
        68,
        85,
        75,
        35,
        99,
        98,
        99,
        6,
        79,
        65
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        199,
        87,
        49,
        84,
        17,
        197,
        114,
        51,
        102,
        1,
        27,
        33,
        98,
        48,
        68,
        62,
        106,
        92,
        106,
        2,
        1,
        4,
        134,
        1,
        3,
        129,
        164,
        110,
        199,
        155,
        28,
        102,
        1,
        46,
        15,
        77,
        198,
        105,
        175,
        71,
        1,
        0,
        199,
        39,
        1,
        130,
        0,
        124,
        198,
        117,
        34,
        67
      ],
      "weeklyWasted": [
        15,
        77,
        198,
        105,
        175,
        71,
        1,
        0,
        199,
        39,
        1,
        130,
        0,
        124,
        198,
        117,
        34,
        67,
        84,
        12,
        0,
        39,
        98,
        80,
        2,
        0,
        54,
        99,
        51,
        99,
        46,
        84,
        34,
        1,
        35,
        99,
        27,
        59,
        42,
        26,
        55,
        0,
        14,
        48,
        98,
        97,
        90,
        3,
        13,
        72,
        29,
        44,
        30,
        21,
        99,
        0,
        72,
        99,
        0,
        98,
        99,
        86,
        99,
        99,
        0,
        35,
        99,
        1,
        99,
        12
      ]
    },
    {
      "id": "FB70040E",
      "weeklyUsage": [
        75,
        38,
        70,
        5,
        78,
        109,
        179,
        99,
        117,
        32,
        185,
        52,
        154,
        135,
        199,
        121,
        192,
        181,
        54,
        67,
        197,
        90,
        2,
        29,
        3,
        63,
        146,
        197,
        166,
        103,
        199,
        67,
        132,
        137,
        177,
        13,
        0,
        62,
        140,
        21,
        37,
        103,
        0,
        0,
        47,
        112,
        0,
        130,
        198,
        199,
        2,
        29
      ],
      "weeklyWasted": [
        177,
        13,
        0,
        62,
        140,
        21,
        37,
        103,
        0,
        0,
        47,
        112,
        0,
        130,
        198,
        199,
        2,
        29,
        18,
        17,
        22,
        51,
        64,
        74,
        99,
        0,
        62,
        15,
        99,
        1,
        53,
        99,
        26,
        37,
        0,
        41,
        1,
        32,
        61,
        40,
        88,
        47,
        51,
        55,
        98,
        73,
        99,
        66,
        80,
        14,
        80,
        98,
        8,
        27,
        40,
        0,
        57,
        41,
        66,
        0,
        90,
        54,
        72,
        69,
        99,
        78,
        75,
        99,
        48,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        197,
        109,
        0,
        12,
        9,
        1,
        21,
        125,
        124,
        1,
        2,
        54,
        102,
        31,
        143,
        160,
        39,
        59,
        123,
        62,
        164,
        36,
        140,
        120,
        102,
        181,
        194,
        62,
        199,
        78,
        126,
        0,
        100,
        62,
        60,
        6,
        156,
        80,
        199,
        70,
        44,
        0,
        0,
        17,
        10,
        185,
        0,
        51,
        198,
        2,
        182,
        3
      ],
      "weeklyWasted": [
        60,
        6,
        156,
        80,
        199,
        70,
        44,
        0,
        0,
        17,
        10,
        185,
        0,
        51,
        198,
        2,
        182,
        3,
        51,
        1,
        16,
        2,
        98,
        43,
        48,
        18,
        3,
        89,
        99,
        99,
        65,
        54,
        0,
        1,
        21,
        77,
        34,
        60,
        43,
        65,
        0,
        0,
        22,
        74,
        38,
        28,
        94,
        52,
        2,
        29,
        1,
        98,
        0,
        56,
        99,
        0,
        99,
        86,
        3,
        17,
        73,
        99,
        8,
        19,
        47,
        72,
        30,
        1,
        40,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        54,
        73,
        44,
        142,
        51,
        74,
        5,
        3,
        131,
        45,
        50,
        56,
        110,
        0,
        159,
        171,
        192,
        175,
        155,
        2,
        38,
        4,
        17,
        57,
        123,
        1,
        157,
        1,
        79,
        150,
        62,
        196,
        1,
        48,
        89,
        50,
        198,
        194,
        111,
        42,
        109,
        0,
        158,
        0,
        14,
        165,
        37,
        0,
        91,
        128,
        100,
        69
      ],
      "weeklyWasted": [
        89,
        50,
        198,
        194,
        111,
        42,
        109,
        0,
        158,
        0,
        14,
        165,
        37,
        0,
        91,
        128,
        100,
        69,
        85,
        77,
        0,
        94,
        98,
        68,
        27,
        0,
        26,
        99,
        78,
        99,
        69,
        3,
        73,
        31,
        48,
        51,
        43,
        13,
        45,
        0,
        19,
        22,
        92,
        73,
        98,
        53,
        65,
        12,
        56,
        65,
        37,
        98,
        99,
        36,
        54,
        40,
        26,
        92,
        46,
        98,
        99,
        79,
        99,
        13,
        89,
        57,
        33,
        26,
        99,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        190,
        126,
        102,
        5,
        0,
        178,
        76,
        109,
        2,
        1,
        0,
        191,
        146,
        111,
        185,
        120,
        192,
        199,
        3,
        139,
        171,
        198,
        12,
        116,
        14,
        66,
        185,
        69,
        0,
        11,
        85,
        101,
        40,
        123,
        11,
        125,
        70,
        199,
        199,
        169,
        76,
        116,
        0,
        3,
        1,
        49,
        148,
        187,
        198,
        2,
        74,
        80
      ],
      "weeklyWasted": [
        11,
        125,
        70,
        199,
        199,
        169,
        76,
        116,
        0,
        3,
        1,
        49,
        148,
        187,
        198,
        2,
        74,
        80,
        8,
        1,
        0,
        13,
        98,
        1,
        53,
        6,
        49,
        0,
        74,
        53,
        48,
        99,
        76,
        21,
        26,
        1,
        30,
        27,
        84,
        0,
        51,
        0,
        99,
        91,
        88,
        58,
        2,
        57,
        99,
        18,
        50,
        86,
        99,
        75,
        71,
        88,
        84,
        99,
        38,
        0,
        48,
        52,
        99,
        41,
        99,
        98,
        0,
        83,
        45,
        1
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        178,
        56,
        179,
        5,
        113,
        119,
        148,
        3,
        116,
        139,
        0,
        92,
        137,
        71,
        199,
        153,
        192,
        73,
        111,
        42,
        197,
        65,
        59,
        18,
        17,
        150,
        190,
        197,
        199,
        179,
        186,
        100,
        64,
        66,
        82,
        87,
        130,
        135,
        155,
        77,
        0,
        39,
        198,
        117,
        62,
        4,
        98,
        156,
        195,
        2,
        56,
        19
      ],
      "weeklyWasted": [
        82,
        87,
        130,
        135,
        155,
        77,
        0,
        39,
        198,
        117,
        62,
        4,
        98,
        156,
        195,
        2,
        56,
        19,
        78,
        1,
        23,
        2,
        94,
        60,
        22,
        45,
        29,
        0,
        39,
        49,
        61,
        88,
        97,
        7,
        10,
        39,
        2,
        25,
        73,
        21,
        0,
        3,
        35,
        69,
        98,
        40,
        99,
        79,
        67,
        1,
        84,
        97,
        5,
        66,
        51,
        3,
        76,
        99,
        37,
        1,
        65,
        80,
        91,
        99,
        28,
        98,
        99,
        44,
        40,
        35
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        170,
        73,
        167,
        5,
        198,
        39,
        194,
        25,
        163,
        1,
        148,
        33,
        105,
        97,
        14,
        82,
        82,
        168,
        3,
        53,
        59,
        198,
        94,
        163,
        145,
        173,
        84,
        55,
        0,
        132,
        81,
        196,
        98,
        108,
        198,
        17,
        0,
        199,
        37,
        0,
        0,
        50,
        85,
        0,
        57,
        187,
        82,
        119,
        159,
        59,
        6,
        17
      ],
      "weeklyWasted": [
        198,
        17,
        0,
        199,
        37,
        0,
        0,
        50,
        85,
        0,
        57,
        187,
        82,
        119,
        159,
        59,
        6,
        17,
        83,
        82,
        30,
        46,
        34,
        77,
        99,
        50,
        99,
        0,
        48,
        45,
        99,
        93,
        9,
        98,
        31,
        61,
        86,
        60,
        66,
        0,
        25,
        25,
        81,
        43,
        53,
        38,
        63,
        60,
        46,
        97,
        51,
        98,
        0,
        47,
        99,
        87,
        4,
        17,
        97,
        13,
        0,
        99,
        76,
        13,
        99,
        84,
        53,
        37,
        59,
        56
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        91,
        60,
        105,
        22,
        72,
        63,
        67,
        11,
        177,
        127,
        14,
        127,
        2,
        142,
        142,
        3,
        55,
        181,
        60,
        197,
        119,
        132,
        171,
        195,
        3,
        138,
        156,
        49,
        158,
        150,
        14,
        116,
        82,
        194,
        4,
        0,
        198,
        5,
        140,
        160,
        111,
        4,
        199,
        68,
        199,
        175,
        1,
        112,
        198,
        50,
        73,
        35
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        5,
        140,
        160,
        111,
        4,
        199,
        68,
        199,
        175,
        1,
        112,
        198,
        50,
        73,
        35,
        49,
        28,
        29,
        26,
        98,
        8,
        99,
        4,
        89,
        39,
        59,
        34,
        99,
        99,
        82,
        98,
        79,
        75,
        29,
        13,
        36,
        0,
        5,
        39,
        62,
        48,
        98,
        97,
        80,
        1,
        13,
        97,
        90,
        61,
        99,
        3,
        99,
        42,
        26,
        99,
        2,
        98,
        24,
        52,
        65,
        25,
        48,
        25,
        0,
        1,
        76,
        99
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        199,
        66,
        181,
        13,
        85,
        135,
        14,
        110,
        145,
        69,
        199,
        159,
        198,
        159,
        67,
        37,
        0,
        199,
        197,
        51,
        111,
        15,
        55,
        187,
        197,
        64,
        15,
        1,
        85,
        6,
        100,
        0,
        139,
        69,
        138,
        0,
        198,
        84,
        10,
        51,
        0,
        95,
        50,
        0,
        47,
        116,
        13,
        100,
        198,
        199,
        167,
        141
      ],
      "weeklyWasted": [
        138,
        0,
        198,
        84,
        10,
        51,
        0,
        95,
        50,
        0,
        47,
        116,
        13,
        100,
        198,
        199,
        167,
        141,
        55,
        98,
        19,
        93,
        98,
        98,
        37,
        8,
        79,
        0,
        99,
        64,
        41,
        90,
        43,
        96,
        76,
        84,
        4,
        18,
        57,
        0,
        53,
        18,
        15,
        86,
        62,
        47,
        99,
        83,
        8,
        72,
        28,
        88,
        65,
        99,
        14,
        0,
        22,
        99,
        4,
        62,
        99,
        10,
        51,
        27,
        99,
        98,
        25,
        36,
        34,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        180,
        142,
        56,
        97,
        120,
        101,
        92,
        49,
        199,
        104,
        35,
        95,
        169,
        0,
        195,
        178,
        192,
        150,
        92,
        65,
        135,
        43,
        5,
        84,
        3,
        198,
        192,
        1,
        134,
        54,
        112,
        0,
        20,
        125,
        75,
        199,
        179,
        101,
        26,
        97,
        0,
        0,
        152,
        0,
        1,
        157,
        139,
        105,
        131,
        2,
        74,
        72
      ],
      "weeklyWasted": [
        75,
        199,
        179,
        101,
        26,
        97,
        0,
        0,
        152,
        0,
        1,
        157,
        139,
        105,
        131,
        2,
        74,
        72,
        85,
        31,
        16,
        95,
        74,
        9,
        50,
        9,
        38,
        21,
        0,
        97,
        56,
        89,
        87,
        58,
        12,
        25,
        99,
        72,
        53,
        0,
        54,
        8,
        28,
        38,
        76,
        51,
        99,
        48,
        2,
        52,
        38,
        72,
        18,
        17,
        60,
        39,
        8,
        1,
        39,
        90,
        32,
        99,
        99,
        95,
        68,
        91,
        99,
        7,
        25,
        75
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        199,
        81,
        0,
        101,
        0,
        109,
        35,
        3,
        142,
        1,
        177,
        72,
        9,
        0,
        103,
        71,
        192,
        186,
        143,
        98,
        78,
        4,
        2,
        195,
        175,
        79,
        189,
        1,
        126,
        74,
        199,
        0,
        63,
        106,
        99,
        0,
        86,
        152,
        180,
        71,
        17,
        88,
        112,
        13,
        1,
        19,
        31,
        81,
        191,
        139,
        2,
        101
      ],
      "weeklyWasted": [
        99,
        0,
        86,
        152,
        180,
        71,
        17,
        88,
        112,
        13,
        1,
        19,
        31,
        81,
        191,
        139,
        2,
        101,
        47,
        11,
        30,
        5,
        0,
        55,
        96,
        0,
        6,
        99,
        26,
        62,
        45,
        12,
        0,
        26,
        57,
        1,
        1,
        13,
        2,
        0,
        54,
        0,
        99,
        82,
        70,
        37,
        41,
        52,
        56,
        1,
        49,
        98,
        76,
        17,
        55,
        21,
        41,
        96,
        0,
        77,
        8,
        99,
        41,
        99,
        99,
        98,
        55,
        66,
        33,
        1
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        199,
        115,
        99,
        59,
        0,
        154,
        95,
        3,
        199,
        199,
        139,
        107,
        52,
        0,
        115,
        172,
        168,
        1,
        3,
        156,
        23,
        43,
        2,
        79,
        197,
        151,
        69,
        1,
        0,
        107,
        19,
        0,
        1,
        4,
        68,
        199,
        159,
        170,
        146,
        42,
        0,
        91,
        72,
        13,
        111,
        17,
        195,
        39,
        198,
        64,
        2,
        139
      ],
      "weeklyWasted": [
        68,
        199,
        159,
        170,
        146,
        42,
        0,
        91,
        72,
        13,
        111,
        17,
        195,
        39,
        198,
        64,
        2,
        139,
        99,
        36,
        63,
        2,
        51,
        28,
        72,
        0,
        99,
        47,
        42,
        37,
        49,
        36,
        66,
        14,
        36,
        66,
        32,
        84,
        41,
        34,
        0,
        0,
        0,
        1,
        98,
        97,
        2,
        38,
        99,
        87,
        1,
        98,
        76,
        0,
        56,
        35,
        39,
        99,
        26,
        0,
        99,
        76,
        37,
        32,
        98,
        60,
        89,
        36,
        99,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        199,
        75,
        95,
        14,
        51,
        197,
        39,
        3,
        10,
        1,
        199,
        90,
        165,
        0,
        173,
        89,
        192,
        60,
        197,
        71,
        1,
        31,
        88,
        97,
        98,
        198,
        78,
        1,
        180,
        189,
        118,
        77,
        1,
        32,
        166,
        127,
        62,
        199,
        146,
        68,
        133,
        54,
        33,
        38,
        28,
        105,
        150,
        100,
        51,
        87,
        89,
        195
      ],
      "weeklyWasted": [
        166,
        127,
        62,
        199,
        146,
        68,
        133,
        54,
        33,
        38,
        28,
        105,
        150,
        100,
        51,
        87,
        89,
        195,
        1,
        64,
        38,
        23,
        98,
        79,
        60,
        0,
        90,
        90,
        71,
        9,
        52,
        99,
        87,
        98,
        46,
        59,
        88,
        50,
        71,
        46,
        0,
        0,
        56,
        25,
        93,
        26,
        87,
        67,
        17,
        18,
        1,
        81,
        59,
        96,
        59,
        64,
        0,
        77,
        64,
        81,
        5,
        57,
        79,
        78,
        99,
        61,
        88,
        41,
        0,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        161,
        167,
        2,
        5,
        47,
        1,
        194,
        97,
        2,
        1,
        152,
        197,
        158,
        47,
        175,
        195,
        167,
        16,
        143,
        87,
        174,
        31,
        65,
        39,
        3,
        75,
        140,
        21,
        199,
        96,
        115,
        0,
        1,
        4,
        140,
        199,
        148,
        95,
        58,
        124,
        57,
        41,
        4,
        0,
        105,
        189,
        0,
        15,
        198,
        127,
        170,
        3
      ],
      "weeklyWasted": [
        140,
        199,
        148,
        95,
        58,
        124,
        57,
        41,
        4,
        0,
        105,
        189,
        0,
        15,
        198,
        127,
        170,
        3,
        1,
        2,
        28,
        7,
        14,
        98,
        17,
        46,
        8,
        30,
        99,
        1,
        41,
        99,
        38,
        1,
        0,
        1,
        9,
        74,
        99,
        0,
        31,
        58,
        55,
        59,
        26,
        17,
        2,
        25,
        9,
        40,
        28,
        98,
        55,
        74,
        88,
        66,
        92,
        22,
        91,
        0,
        99,
        99,
        99,
        52,
        99,
        64,
        99,
        99,
        85,
        31
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        11,
        72,
        91,
        15,
        17,
        1,
        67,
        30,
        143,
        1,
        199,
        27,
        26,
        0,
        1,
        83,
        0,
        130,
        3,
        117,
        65,
        198,
        157,
        146,
        91,
        36,
        194,
        197,
        93,
        110,
        63,
        54,
        160,
        88,
        4,
        117,
        55,
        108,
        150,
        58,
        198,
        62,
        32,
        8,
        65,
        138,
        0,
        57,
        125,
        98,
        85,
        80
      ],
      "weeklyWasted": [
        4,
        117,
        55,
        108,
        150,
        58,
        198,
        62,
        32,
        8,
        65,
        138,
        0,
        57,
        125,
        98,
        85,
        80,
        53,
        1,
        0,
        2,
        98,
        98,
        99,
        0,
        99,
        0,
        99,
        99,
        31,
        89,
        3,
        8,
        61,
        75,
        1,
        47,
        2,
        0,
        41,
        1,
        25,
        99,
        0,
        75,
        2,
        35,
        47,
        97,
        42,
        98,
        51,
        33,
        99,
        62,
        55,
        26,
        16,
        46,
        37,
        68,
        69,
        81,
        63,
        98,
        99,
        1,
        27,
        39
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        26,
        73,
        100,
        122,
        74,
        150,
        191,
        37,
        199,
        1,
        138,
        38,
        33,
        58,
        1,
        3,
        192,
        68,
        197,
        2,
        76,
        136,
        84,
        69,
        105,
        49,
        188,
        1,
        51,
        38,
        76,
        0,
        1,
        184,
        124,
        50,
        0,
        92,
        79,
        17,
        118,
        11,
        125,
        166,
        1,
        22,
        0,
        130,
        116,
        109,
        2,
        3
      ],
      "weeklyWasted": [
        124,
        50,
        0,
        92,
        79,
        17,
        118,
        11,
        125,
        166,
        1,
        22,
        0,
        130,
        116,
        109,
        2,
        3,
        20,
        1,
        11,
        52,
        49,
        5,
        62,
        42,
        19,
        64,
        32,
        42,
        99,
        70,
        0,
        1,
        4,
        61,
        1,
        10,
        84,
        0,
        71,
        0,
        99,
        39,
        73,
        97,
        99,
        31,
        58,
        67,
        37,
        77,
        99,
        48,
        99,
        54,
        75,
        1,
        8,
        98,
        99,
        99,
        99,
        37,
        99,
        0,
        99,
        98,
        41,
        1
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        61,
        58,
        81,
        5,
        0,
        139,
        54,
        48,
        2,
        122,
        199,
        187,
        167,
        88,
        95,
        102,
        146,
        107,
        52,
        112,
        44,
        53,
        2,
        195,
        146,
        198,
        99,
        1,
        108,
        25,
        101,
        54,
        174,
        53,
        107,
        47,
        121,
        77,
        139,
        0,
        2,
        178,
        0,
        172,
        1,
        126,
        96,
        122,
        118,
        199,
        87,
        196
      ],
      "weeklyWasted": [
        107,
        47,
        121,
        77,
        139,
        0,
        2,
        178,
        0,
        172,
        1,
        126,
        96,
        122,
        118,
        199,
        87,
        196,
        1,
        5,
        38,
        58,
        91,
        1,
        99,
        0,
        53,
        0,
        24,
        99,
        67,
        64,
        45,
        37,
        56,
        19,
        36,
        48,
        38,
        34,
        40,
        0,
        13,
        75,
        76,
        27,
        32,
        64,
        34,
        81,
        37,
        81,
        99,
        91,
        64,
        12,
        42,
        18,
        0,
        77,
        16,
        1,
        19,
        46,
        0,
        98,
        69,
        70,
        39,
        20
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyUsage": [
        199,
        127,
        0,
        5,
        88,
        1,
        194,
        90,
        166,
        199,
        199,
        64,
        105,
        44,
        199,
        47,
        192,
        171,
        3,
        172,
        197,
        4,
        128,
        163,
        3,
        166,
        174,
        1,
        111,
        93,
        82,
        73,
        1,
        142,
        4,
        36,
        104,
        148,
        86,
        83,
        0,
        163,
        110,
        116,
        77,
        67,
        0,
        158,
        192,
        118,
        64,
        92
      ],
      "weeklyWasted": [
        4,
        36,
        104,
        148,
        86,
        83,
        0,
        163,
        110,
        116,
        77,
        67,
        0,
        158,
        192,
        118,
        64,
        92,
        88,
        14,
        77,
        20,
        98,
        37,
        77,
        79,
        6,
        61,
        59,
        67,
        66,
        19,
        45,
        31,
        17,
        56,
        31,
        88,
        35,
        54,
        10,
        7,
        0,
        69,
        98,
        78,
        98,
        26,
        6,
        52,
        1,
        45,
        99,
        38,
        59,
        18,
        99,
        86,
        18,
        4,
        99,
        37,
        27,
        49,
        30,
        98,
        97,
        97,
        48,
        2
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        140,
        2,
        144,
        5,
        39,
        133,
        32,
        48,
        2,
        126,
        74,
        87,
        148,
        199,
        103,
        155,
        163,
        199,
        98,
        76,
        153,
        10,
        2,
        24,
        22,
        164,
        197,
        1,
        101,
        121,
        13,
        0,
        1,
        57,
        198,
        199,
        45,
        5,
        80,
        132,
        0,
        199,
        136,
        0,
        1,
        186,
        94,
        0,
        198,
        124,
        80,
        3
      ],
      "weeklyWasted": [
        198,
        199,
        45,
        5,
        80,
        132,
        0,
        199,
        136,
        0,
        1,
        186,
        94,
        0,
        198,
        124,
        80,
        3,
        1,
        22,
        37,
        2,
        98,
        47,
        99,
        24,
        16,
        48,
        48,
        1,
        3,
        80,
        73,
        53,
        95,
        1,
        44,
        67,
        2,
        4,
        60,
        37,
        18,
        99,
        46,
        97,
        2,
        12,
        29,
        97,
        1,
        46,
        19,
        31,
        99,
        80,
        99,
        75,
        0,
        10,
        99,
        23,
        39,
        99,
        74,
        88,
        16,
        72,
        17,
        23
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        95,
        2,
        198,
        195,
        153,
        50,
        194,
        138,
        16,
        5,
        54,
        84,
        198,
        74,
        40,
        80,
        141,
        25,
        101,
        2,
        119,
        4,
        162,
        1,
        3,
        56,
        78,
        75,
        27,
        113,
        103,
        114,
        46,
        101,
        161,
        176,
        0,
        5,
        199,
        99,
        96,
        14,
        171,
        45,
        107,
        82,
        22,
        35,
        124,
        199,
        94,
        18
      ],
      "weeklyWasted": [
        161,
        176,
        0,
        5,
        199,
        99,
        96,
        14,
        171,
        45,
        107,
        82,
        22,
        35,
        124,
        199,
        94,
        18,
        39,
        25,
        84,
        2,
        91,
        68,
        11,
        0,
        99,
        66,
        73,
        6,
        1,
        99,
        27,
        1,
        39,
        31,
        3,
        55,
        42,
        89,
        97,
        0,
        92,
        84,
        10,
        69,
        72,
        3,
        85,
        40,
        45,
        98,
        14,
        0,
        99,
        89,
        22,
        1,
        15,
        58,
        70,
        34,
        70,
        5,
        99,
        94,
        99,
        99,
        50,
        16
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        7,
        114,
        111,
        69,
        0,
        1,
        107,
        197,
        93,
        1,
        199,
        123,
        131,
        90,
        139,
        89,
        164,
        154,
        107,
        94,
        88,
        120,
        2,
        97,
        150,
        1,
        144,
        26,
        109,
        105,
        39,
        112,
        1,
        160,
        80,
        0,
        106,
        5,
        60,
        127,
        174,
        0,
        79,
        68,
        40,
        196,
        0,
        81,
        183,
        52,
        110,
        74
      ],
      "weeklyWasted": [
        80,
        0,
        106,
        5,
        60,
        127,
        174,
        0,
        79,
        68,
        40,
        196,
        0,
        81,
        183,
        52,
        110,
        74,
        43,
        20,
        99,
        2,
        86,
        72,
        50,
        35,
        51,
        86,
        86,
        93,
        64,
        96,
        6,
        1,
        62,
        65,
        27,
        33,
        99,
        0,
        36,
        26,
        31,
        87,
        0,
        18,
        82,
        96,
        7,
        74,
        70,
        86,
        99,
        0,
        65,
        82,
        99,
        1,
        10,
        37,
        99,
        99,
        88,
        42,
        37,
        29,
        71,
        99,
        8,
        1
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyUsage": [
        70,
        75,
        170,
        41,
        198,
        3,
        103,
        3,
        130,
        34,
        16,
        47,
        35,
        18,
        122,
        3,
        192,
        190,
        153,
        162,
        159,
        4,
        73,
        113,
        3,
        79,
        175,
        7,
        87,
        124,
        46,
        147,
        1,
        72,
        93,
        0,
        143,
        78,
        53,
        64,
        0,
        15,
        96,
        0,
        141,
        156,
        0,
        177,
        198,
        171,
        84,
        42
      ],
      "weeklyWasted": [
        93,
        0,
        143,
        78,
        53,
        64,
        0,
        15,
        96,
        0,
        141,
        156,
        0,
        177,
        198,
        171,
        84,
        42,
        56,
        31,
        28,
        43,
        64,
        35,
        17,
        69,
        2,
        11,
        0,
        60,
        47,
        53,
        0,
        1,
        92,
        70,
        64,
        65,
        2,
        0,
        14,
        0,
        94,
        32,
        98,
        68,
        55,
        16,
        2,
        42,
        1,
        96,
        92,
        21,
        28,
        75,
        34,
        1,
        7,
        46,
        99,
        99,
        98,
        80,
        99,
        87,
        96,
        1,
        50,
        17
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        95,
        2,
        72,
        121,
        73,
        94,
        12,
        3,
        199,
        79,
        182,
        44,
        43,
        83,
        117,
        183,
        39,
        173,
        34,
        130,
        119,
        4,
        50,
        151,
        86,
        1,
        57,
        57,
        107,
        63,
        55,
        22,
        137,
        134,
        4,
        0,
        143,
        5,
        10,
        74,
        2,
        135,
        98,
        33,
        1,
        177,
        0,
        98,
        124,
        71,
        64,
        134
      ],
      "weeklyWasted": [
        4,
        0,
        143,
        5,
        10,
        74,
        2,
        135,
        98,
        33,
        1,
        177,
        0,
        98,
        124,
        71,
        64,
        134,
        1,
        6,
        13,
        41,
        98,
        98,
        80,
        0,
        47,
        0,
        15,
        64,
        18,
        66,
        65,
        19,
        15,
        77,
        24,
        13,
        2,
        67,
        0,
        5,
        22,
        67,
        80,
        94,
        23,
        34,
        2,
        84,
        1,
        49,
        98,
        7,
        17,
        0,
        99,
        51,
        13,
        67,
        75,
        99,
        69,
        6,
        39,
        33,
        38,
        84,
        6,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        199,
        59,
        37,
        59,
        0,
        197,
        133,
        3,
        2,
        46,
        60,
        2,
        163,
        75,
        180,
        74,
        192,
        181,
        54,
        2,
        159,
        4,
        166,
        127,
        33,
        195,
        169,
        39,
        96,
        72,
        56,
        0,
        102,
        170,
        150,
        0,
        114,
        54,
        199,
        61,
        0,
        7,
        110,
        112,
        11,
        137,
        73,
        52,
        198,
        93,
        122,
        31
      ],
      "weeklyWasted": [
        150,
        0,
        114,
        54,
        199,
        61,
        0,
        7,
        110,
        112,
        11,
        137,
        73,
        52,
        198,
        93,
        122,
        31,
        63,
        54,
        43,
        2,
        66,
        23,
        21,
        0,
        99,
        56,
        99,
        1,
        35,
        81,
        0,
        43,
        49,
        99,
        47,
        65,
        2,
        89,
        54,
        0,
        4,
        99,
        63,
        57,
        99,
        54,
        99,
        43,
        3,
        98,
        46,
        0,
        66,
        43,
        99,
        18,
        0,
        53,
        99,
        85,
        5,
        18,
        0,
        7,
        39,
        88,
        34,
        3
      ]
    },
    {
      "id": "965102D9",
      "weeklyUsage": [
        199,
        2,
        48,
        5,
        0,
        1,
        123,
        3,
        199,
        1,
        199,
        73,
        119,
        59,
        149,
        3,
        67,
        174,
        193,
        2,
        167,
        14,
        197,
        124,
        163,
        64,
        109,
        78,
        45,
        35,
        7,
        59,
        23,
        76,
        112,
        153,
        198,
        41,
        146,
        118,
        22,
        67,
        81,
        0,
        133,
        192,
        17,
        100,
        198,
        79,
        16,
        196
      ],
      "weeklyWasted": [
        112,
        153,
        198,
        41,
        146,
        118,
        22,
        67,
        81,
        0,
        133,
        192,
        17,
        100,
        198,
        79,
        16,
        196,
        22,
        20,
        31,
        15,
        68,
        55,
        18,
        0,
        88,
        0,
        2,
        36,
        55,
        81,
        32,
        97,
        11,
        78,
        32,
        55,
        2,
        35,
        52,
        70,
        86,
        90,
        98,
        46,
        32,
        25,
        2,
        89,
        1,
        84,
        38,
        6,
        99,
        92,
        81,
        86,
        22,
        98,
        58,
        86,
        88,
        11,
        99,
        82,
        99,
        17,
        44,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        116,
        25,
        0,
        5,
        7,
        135,
        94,
        197,
        106,
        62,
        4,
        124,
        198,
        37,
        39,
        106,
        159,
        19,
        197,
        58,
        86,
        87,
        112,
        1,
        3,
        160,
        185,
        1,
        167,
        103,
        67,
        0,
        68,
        4,
        198,
        80,
        79,
        38,
        192,
        120,
        0,
        152,
        199,
        198,
        89,
        85,
        19,
        2,
        198,
        150,
        197,
        157
      ],
      "weeklyWasted": [
        198,
        80,
        79,
        38,
        192,
        120,
        0,
        152,
        199,
        198,
        89,
        85,
        19,
        2,
        198,
        150,
        197,
        157,
        15,
        15,
        0,
        12,
        98,
        34,
        18,
        0,
        99,
        82,
        99,
        81,
        55,
        38,
        10,
        1,
        34,
        39,
        71,
        75,
        31,
        0,
        10,
        17,
        46,
        98,
        77,
        57,
        12,
        21,
        17,
        45,
        58,
        85,
        78,
        18,
        62,
        80,
        99,
        99,
        49,
        0,
        99,
        76,
        99,
        87,
        55,
        98,
        47,
        99,
        49,
        2
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        154,
        5,
        198,
        149,
        167,
        66,
        194,
        3,
        99,
        199,
        23,
        59,
        2,
        72,
        178,
        59,
        192,
        199,
        157,
        115,
        121,
        38,
        115,
        155,
        63,
        140,
        187,
        152,
        188,
        156,
        127,
        0,
        1,
        199,
        170,
        54,
        130,
        127,
        148,
        6,
        0,
        132,
        7,
        55,
        187,
        47,
        145,
        119,
        198,
        122,
        2,
        120
      ],
      "weeklyWasted": [
        170,
        54,
        130,
        127,
        148,
        6,
        0,
        132,
        7,
        55,
        187,
        47,
        145,
        119,
        198,
        122,
        2,
        120,
        63,
        1,
        99,
        69,
        98,
        1,
        7,
        0,
        0,
        62,
        43,
        46,
        1,
        44,
        17,
        14,
        48,
        1,
        1,
        48,
        75,
        21,
        97,
        11,
        99,
        11,
        80,
        55,
        99,
        15,
        65,
        97,
        69,
        1,
        65,
        77,
        36,
        87,
        87,
        99,
        0,
        23,
        99,
        84,
        99,
        18,
        88,
        75,
        80,
        96,
        99,
        9
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        192,
        90,
        49,
        74,
        198,
        105,
        158,
        54,
        2,
        132,
        62,
        80,
        198,
        86,
        1,
        22,
        191,
        179,
        77,
        9,
        40,
        4,
        24,
        193,
        171,
        171,
        197,
        96,
        73,
        198,
        115,
        94,
        197,
        51,
        151,
        109,
        84,
        98,
        153,
        20,
        10,
        20,
        128,
        6,
        1,
        196,
        15,
        79,
        163,
        2,
        51,
        48
      ],
      "weeklyWasted": [
        151,
        109,
        84,
        98,
        153,
        20,
        10,
        20,
        128,
        6,
        1,
        196,
        15,
        79,
        163,
        2,
        51,
        48,
        42,
        32,
        99,
        34,
        57,
        98,
        20,
        28,
        65,
        99,
        99,
        24,
        79,
        68,
        55,
        23,
        11,
        99,
        88,
        86,
        40,
        74,
        47,
        0,
        59,
        74,
        57,
        97,
        85,
        40,
        2,
        83,
        30,
        98,
        39,
        25,
        19,
        42,
        31,
        28,
        4,
        0,
        14,
        77,
        66,
        32,
        59,
        53,
        0,
        84,
        56,
        27
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        7,
        78,
        57,
        5,
        69,
        20,
        86,
        3,
        2,
        1,
        166,
        81,
        184,
        143,
        162,
        3,
        165,
        171,
        53,
        2,
        107,
        4,
        138,
        176,
        141,
        35,
        154,
        197,
        95,
        74,
        114,
        164,
        102,
        81,
        4,
        0,
        0,
        199,
        51,
        0,
        0,
        187,
        50,
        28,
        1,
        102,
        76,
        76,
        154,
        199,
        120,
        105
      ],
      "weeklyWasted": [
        4,
        0,
        0,
        199,
        51,
        0,
        0,
        187,
        50,
        28,
        1,
        102,
        76,
        76,
        154,
        199,
        120,
        105,
        10,
        29,
        87,
        2,
        12,
        65,
        80,
        0,
        24,
        61,
        99,
        1,
        95,
        3,
        44,
        1,
        69,
        76,
        6,
        63,
        99,
        46,
        97,
        83,
        18,
        99,
        71,
        36,
        99,
        21,
        51,
        97,
        21,
        90,
        46,
        2,
        99,
        44,
        5,
        1,
        15,
        52,
        99,
        55,
        74,
        99,
        99,
        98,
        72,
        18,
        86,
        15
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        74,
        87,
        99,
        5,
        198,
        105,
        119,
        48,
        96,
        75,
        199,
        54,
        22,
        0,
        185,
        198,
        192,
        94,
        94,
        197,
        77,
        198,
        56,
        73,
        162,
        111,
        154,
        1,
        0,
        172,
        0,
        196,
        54,
        49,
        142,
        189,
        84,
        63,
        12,
        141,
        197,
        74,
        55,
        0,
        65,
        70,
        0,
        17,
        198,
        183,
        145,
        3
      ],
      "weeklyWasted": [
        142,
        189,
        84,
        63,
        12,
        141,
        197,
        74,
        55,
        0,
        65,
        70,
        0,
        17,
        198,
        183,
        145,
        3,
        4,
        1,
        40,
        21,
        98,
        47,
        84,
        1,
        99,
        50,
        18,
        22,
        29,
        63,
        59,
        72,
        34,
        64,
        35,
        75,
        99,
        0,
        51,
        55,
        28,
        85,
        56,
        91,
        36,
        46,
        71,
        97,
        76,
        59,
        69,
        45,
        27,
        43,
        45,
        60,
        70,
        0,
        99,
        22,
        17,
        42,
        45,
        98,
        99,
        88,
        58,
        2
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyUsage": [
        199,
        2,
        198,
        86,
        121,
        159,
        194,
        160,
        46,
        109,
        199,
        58,
        110,
        57,
        199,
        87,
        166,
        145,
        77,
        49,
        129,
        133,
        117,
        1,
        3,
        67,
        151,
        188,
        155,
        151,
        107,
        160,
        144,
        92,
        151,
        56,
        55,
        96,
        199,
        0,
        0,
        199,
        96,
        137,
        83,
        110,
        0,
        181,
        198,
        185,
        54,
        3
      ],
      "weeklyWasted": [
        151,
        56,
        55,
        96,
        199,
        0,
        0,
        199,
        96,
        137,
        83,
        110,
        0,
        181,
        198,
        185,
        54,
        3,
        1,
        10,
        0,
        4,
        94,
        44,
        99,
        24,
        79,
        85,
        99,
        70,
        46,
        99,
        55,
        34,
        14,
        16,
        37,
        74,
        88,
        37,
        75,
        0,
        23,
        99,
        98,
        97,
        91,
        56,
        55,
        85,
        29,
        88,
        28,
        54,
        21,
        35,
        99,
        67,
        34,
        0,
        99,
        85,
        51,
        56,
        90,
        98,
        99,
        91,
        89,
        11
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        199,
        2,
        48,
        89,
        33,
        162,
        3,
        197,
        108,
        1,
        153,
        75,
        2,
        0,
        199,
        59,
        177,
        199,
        126,
        197,
        139,
        198,
        137,
        1,
        57,
        79,
        173,
        71,
        175,
        152,
        0,
        168,
        1,
        30,
        32,
        28,
        162,
        126,
        142,
        91,
        116,
        0,
        34,
        53,
        80,
        196,
        29,
        54,
        198,
        143,
        2,
        152
      ],
      "weeklyWasted": [
        32,
        28,
        162,
        126,
        142,
        91,
        116,
        0,
        34,
        53,
        80,
        196,
        29,
        54,
        198,
        143,
        2,
        152,
        17,
        1,
        0,
        77,
        98,
        1,
        89,
        95,
        76,
        43,
        50,
        58,
        76,
        64,
        83,
        1,
        0,
        98,
        99,
        99,
        50,
        49,
        53,
        64,
        41,
        77,
        98,
        77,
        42,
        17,
        2,
        63,
        1,
        74,
        99,
        50,
        48,
        0,
        64,
        41,
        20,
        77,
        43,
        82,
        71,
        17,
        99,
        71,
        17,
        64,
        56,
        70
      ]
    },
    {
      "id": "11102F47",
      "weeklyUsage": [
        54,
        2,
        87,
        5,
        0,
        5,
        194,
        197,
        63,
        1,
        118,
        129,
        198,
        131,
        135,
        3,
        178,
        72,
        197,
        169,
        12,
        4,
        140,
        76,
        3,
        126,
        109,
        34,
        199,
        156,
        181,
        80,
        29,
        137,
        196,
        123,
        60,
        85,
        199,
        83,
        0,
        52,
        0,
        155,
        9,
        4,
        42,
        18,
        187,
        199,
        136,
        39
      ],
      "weeklyWasted": [
        196,
        123,
        60,
        85,
        199,
        83,
        0,
        52,
        0,
        155,
        9,
        4,
        42,
        18,
        187,
        199,
        136,
        39,
        18,
        13,
        26,
        2,
        98,
        53,
        73,
        0,
        99,
        93,
        99,
        44,
        58,
        97,
        0,
        53,
        0,
        62,
        16,
        87,
        2,
        99,
        48,
        3,
        15,
        39,
        98,
        75,
        30,
        6,
        76,
        74,
        99,
        97,
        70,
        10,
        36,
        57,
        5,
        1,
        81,
        0,
        94,
        73,
        42,
        45,
        57,
        92,
        45,
        99,
        61,
        21
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        183,
        115,
        127,
        25,
        137,
        45,
        130,
        3,
        158,
        46,
        199,
        109,
        174,
        0,
        62,
        116,
        192,
        180,
        3,
        42,
        20,
        4,
        38,
        39,
        3,
        198,
        155,
        71,
        129,
        81,
        199,
        36,
        3,
        76,
        4,
        0,
        198,
        151,
        132,
        69,
        199,
        0,
        102,
        0,
        20,
        196,
        50,
        69,
        127,
        2,
        59,
        159
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        151,
        132,
        69,
        199,
        0,
        102,
        0,
        20,
        196,
        50,
        69,
        127,
        2,
        59,
        159,
        69,
        42,
        99,
        2,
        45,
        37,
        0,
        0,
        94,
        70,
        83,
        41,
        45,
        85,
        89,
        98,
        44,
        72,
        28,
        14,
        71,
        70,
        0,
        64,
        57,
        18,
        76,
        15,
        54,
        83,
        2,
        94,
        28,
        95,
        99,
        7,
        99,
        27,
        26,
        99,
        26,
        47,
        90,
        99,
        3,
        70,
        99,
        71,
        90,
        98,
        57,
        11
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        157,
        163,
        3,
        61,
        98,
        4,
        3,
        18,
        99,
        150,
        82,
        2,
        186,
        114,
        170,
        92,
        192,
        199,
        33,
        86,
        64,
        55,
        2,
        195,
        61,
        1,
        179,
        1,
        9,
        52,
        96,
        10,
        1,
        121,
        84,
        158,
        13,
        102,
        152,
        68,
        110,
        8,
        0,
        100,
        40,
        97,
        117,
        126,
        198,
        2,
        94,
        18
      ],
      "weeklyWasted": [
        84,
        158,
        13,
        102,
        152,
        68,
        110,
        8,
        0,
        100,
        40,
        97,
        117,
        126,
        198,
        2,
        94,
        18,
        1,
        45,
        99,
        30,
        90,
        38,
        74,
        0,
        42,
        52,
        92,
        1,
        14,
        75,
        89,
        57,
        98,
        79,
        61,
        57,
        34,
        34,
        38,
        28,
        50,
        24,
        98,
        65,
        73,
        32,
        2,
        71,
        1,
        91,
        95,
        60,
        50,
        69,
        99,
        65,
        2,
        94,
        0,
        50,
        0,
        3,
        81,
        98,
        37,
        65,
        99,
        73
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        183,
        103,
        48,
        64,
        198,
        34,
        194,
        168,
        2,
        100,
        0,
        2,
        142,
        0,
        116,
        3,
        147,
        152,
        197,
        79,
        159,
        198,
        12,
        171,
        91,
        125,
        197,
        31,
        199,
        123,
        57,
        20,
        1,
        199,
        9,
        17,
        86,
        86,
        199,
        8,
        150,
        26,
        191,
        69,
        40,
        77,
        0,
        198,
        198,
        183,
        160,
        109
      ],
      "weeklyWasted": [
        9,
        17,
        86,
        86,
        199,
        8,
        150,
        26,
        191,
        69,
        40,
        77,
        0,
        198,
        198,
        183,
        160,
        109,
        99,
        23,
        29,
        2,
        74,
        34,
        99,
        61,
        42,
        99,
        99,
        59,
        55,
        55,
        0,
        45,
        0,
        81,
        7,
        23,
        46,
        0,
        81,
        33,
        56,
        98,
        98,
        97,
        99,
        21,
        2,
        97,
        1,
        49,
        99,
        61,
        79,
        0,
        61,
        99,
        52,
        79,
        99,
        83,
        70,
        0,
        99,
        98,
        33,
        76,
        0,
        42
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        76,
        2,
        32,
        165,
        109,
        197,
        61,
        65,
        95,
        46,
        125,
        2,
        139,
        0,
        51,
        97,
        144,
        199,
        53,
        74,
        121,
        4,
        121,
        153,
        126,
        162,
        119,
        42,
        52,
        67,
        41,
        37,
        24,
        10,
        132,
        0,
        109,
        38,
        10,
        0,
        199,
        36,
        0,
        75,
        83,
        147,
        99,
        151,
        126,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        132,
        0,
        109,
        38,
        10,
        0,
        199,
        36,
        0,
        75,
        83,
        147,
        99,
        151,
        126,
        199,
        2,
        3,
        1,
        36,
        70,
        43,
        40,
        1,
        99,
        0,
        64,
        57,
        99,
        61,
        99,
        75,
        29,
        98,
        70,
        64,
        25,
        13,
        4,
        99,
        16,
        87,
        21,
        78,
        73,
        26,
        91,
        32,
        2,
        57,
        1,
        88,
        79,
        51,
        15,
        0,
        99,
        76,
        0,
        84,
        59,
        34,
        66,
        58,
        92,
        98,
        0,
        99,
        99,
        1
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        102,
        81,
        41,
        195,
        55,
        197,
        12,
        54,
        2,
        1,
        82,
        117,
        128,
        127,
        73,
        3,
        118,
        127,
        3,
        79,
        189,
        4,
        106,
        134,
        68,
        61,
        122,
        42,
        191,
        141,
        123,
        80,
        88,
        59,
        70,
        64,
        143,
        73,
        97,
        69,
        0,
        0,
        2,
        29,
        1,
        105,
        27,
        53,
        198,
        199,
        2,
        78
      ],
      "weeklyWasted": [
        70,
        64,
        143,
        73,
        97,
        69,
        0,
        0,
        2,
        29,
        1,
        105,
        27,
        53,
        198,
        199,
        2,
        78,
        42,
        7,
        43,
        12,
        95,
        1,
        57,
        11,
        84,
        99,
        85,
        46,
        2,
        83,
        52,
        63,
        68,
        90,
        25,
        60,
        2,
        73,
        14,
        24,
        70,
        43,
        98,
        77,
        71,
        32,
        2,
        37,
        74,
        48,
        57,
        17,
        35,
        53,
        0,
        21,
        12,
        0,
        60,
        99,
        40,
        62,
        99,
        67,
        0,
        97,
        94,
        16
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        21,
        198,
        194,
        195,
        102,
        52,
        174,
        5,
        2,
        1,
        0,
        19,
        59,
        171,
        199,
        198,
        192,
        67,
        141,
        134,
        117,
        44,
        41,
        1,
        195,
        104,
        98,
        39,
        199,
        11,
        62,
        0,
        96,
        102,
        125,
        156,
        54,
        95,
        34,
        52,
        101,
        0,
        110,
        0,
        1,
        172,
        168,
        46,
        198,
        149,
        97,
        3
      ],
      "weeklyWasted": [
        125,
        156,
        54,
        95,
        34,
        52,
        101,
        0,
        110,
        0,
        1,
        172,
        168,
        46,
        198,
        149,
        97,
        3,
        44,
        1,
        59,
        26,
        98,
        98,
        64,
        26,
        68,
        74,
        99,
        47,
        99,
        3,
        68,
        33,
        67,
        86,
        40,
        45,
        88,
        99,
        0,
        25,
        18,
        61,
        70,
        5,
        27,
        36,
        49,
        97,
        39,
        40,
        86,
        0,
        67,
        81,
        14,
        51,
        90,
        0,
        99,
        27,
        0,
        32,
        0,
        93,
        0,
        96,
        99,
        1
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        155,
        123,
        83,
        30,
        0,
        59,
        194,
        92,
        13,
        1,
        199,
        52,
        168,
        146,
        136,
        155,
        52,
        97,
        48,
        183,
        43,
        4,
        2,
        73,
        87,
        139,
        197,
        46,
        199,
        47,
        79,
        196,
        1,
        4,
        107,
        170,
        95,
        155,
        10,
        55,
        10,
        179,
        94,
        15,
        21,
        196,
        30,
        0,
        185,
        45,
        162,
        3
      ],
      "weeklyWasted": [
        107,
        170,
        95,
        155,
        10,
        55,
        10,
        179,
        94,
        15,
        21,
        196,
        30,
        0,
        185,
        45,
        162,
        3,
        20,
        3,
        74,
        42,
        98,
        86,
        0,
        43,
        66,
        36,
        99,
        65,
        99,
        99,
        75,
        11,
        17,
        62,
        90,
        59,
        99,
        45,
        8,
        29,
        58,
        1,
        52,
        1,
        83,
        1,
        2,
        64,
        1,
        51,
        91,
        90,
        73,
        68,
        44,
        77,
        27,
        0,
        99,
        62,
        59,
        92,
        66,
        59,
        0,
        90,
        59,
        3
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        161,
        2,
        154,
        5,
        140,
        145,
        157,
        53,
        191,
        1,
        28,
        2,
        135,
        199,
        147,
        127,
        192,
        129,
        3,
        2,
        161,
        94,
        153,
        175,
        120,
        198,
        150,
        1,
        199,
        74,
        149,
        122,
        67,
        199,
        189,
        199,
        64,
        18,
        162,
        0,
        86,
        176,
        0,
        100,
        2,
        87,
        0,
        102,
        122,
        145,
        9,
        122
      ],
      "weeklyWasted": [
        189,
        199,
        64,
        18,
        162,
        0,
        86,
        176,
        0,
        100,
        2,
        87,
        0,
        102,
        122,
        145,
        9,
        122,
        60,
        85,
        99,
        2,
        0,
        35,
        77,
        52,
        99,
        65,
        59,
        77,
        29,
        4,
        97,
        58,
        29,
        84,
        25,
        70,
        99,
        26,
        18,
        50,
        89,
        38,
        64,
        84,
        99,
        67,
        62,
        84,
        1,
        74,
        46,
        0,
        99,
        49,
        60,
        70,
        0,
        44,
        79,
        71,
        48,
        65,
        99,
        54,
        40,
        99,
        77,
        34
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        65,
        186,
        20,
        5,
        35,
        190,
        148,
        95,
        2,
        1,
        87,
        2,
        198,
        74,
        163,
        3,
        192,
        12,
        96,
        158,
        173,
        4,
        63,
        46,
        102,
        63,
        23,
        31,
        81,
        141,
        130,
        140,
        97,
        119,
        64,
        37,
        66,
        158,
        80,
        0,
        77,
        155,
        0,
        101,
        29,
        153,
        0,
        30,
        198,
        181,
        119,
        79
      ],
      "weeklyWasted": [
        64,
        37,
        66,
        158,
        80,
        0,
        77,
        155,
        0,
        101,
        29,
        153,
        0,
        30,
        198,
        181,
        119,
        79,
        47,
        73,
        22,
        2,
        35,
        42,
        99,
        23,
        0,
        62,
        49,
        62,
        73,
        3,
        18,
        98,
        31,
        99,
        14,
        81,
        80,
        67,
        32,
        99,
        40,
        72,
        20,
        45,
        39,
        23,
        54,
        51,
        55,
        98,
        0,
        83,
        55,
        81,
        14,
        78,
        74,
        0,
        96,
        99,
        24,
        44,
        38,
        98,
        21,
        99,
        36,
        45
      ]
    },
    {
      "id": "958C4CED",
      "weeklyUsage": [
        199,
        2,
        198,
        195,
        15,
        194,
        194,
        42,
        180,
        85,
        0,
        124,
        2,
        0,
        1,
        87,
        190,
        158,
        85,
        142,
        85,
        36,
        175,
        195,
        7,
        177,
        121,
        32,
        198,
        0,
        199,
        47,
        54,
        63,
        8,
        144,
        112,
        5,
        199,
        26,
        123,
        69,
        199,
        82,
        75,
        169,
        120,
        198,
        198,
        84,
        119,
        10
      ],
      "weeklyWasted": [
        8,
        144,
        112,
        5,
        199,
        26,
        123,
        69,
        199,
        82,
        75,
        169,
        120,
        198,
        198,
        84,
        119,
        10,
        90,
        40,
        92,
        34,
        98,
        75,
        16,
        33,
        98,
        40,
        21,
        99,
        1,
        90,
        36,
        53,
        29,
        22,
        16,
        17,
        74,
        28,
        0,
        0,
        71,
        97,
        63,
        62,
        30,
        56,
        2,
        1,
        1,
        90,
        99,
        48,
        91,
        72,
        61,
        99,
        35,
        22,
        99,
        99,
        47,
        58,
        0,
        71,
        99,
        84,
        99,
        59
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        98,
        6,
        60,
        107,
        118,
        79,
        194,
        3,
        176,
        35,
        0,
        65,
        174,
        115,
        143,
        134,
        192,
        148,
        104,
        2,
        95,
        4,
        88,
        195,
        13,
        89,
        181,
        1,
        179,
        198,
        13,
        14,
        63,
        166,
        198,
        0,
        58,
        148,
        19,
        0,
        83,
        51,
        0,
        94,
        21,
        196,
        70,
        14,
        192,
        47,
        98,
        3
      ],
      "weeklyWasted": [
        198,
        0,
        58,
        148,
        19,
        0,
        83,
        51,
        0,
        94,
        21,
        196,
        70,
        14,
        192,
        47,
        98,
        3,
        81,
        1,
        13,
        44,
        75,
        52,
        84,
        0,
        99,
        89,
        48,
        65,
        99,
        3,
        67,
        18,
        0,
        99,
        41,
        31,
        45,
        21,
        0,
        1,
        0,
        99,
        51,
        67,
        99,
        1,
        2,
        66,
        53,
        98,
        55,
        4,
        66,
        69,
        99,
        82,
        46,
        98,
        93,
        82,
        83,
        74,
        7,
        98,
        42,
        6,
        67,
        10
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyUsage": [
        85,
        94,
        198,
        5,
        0,
        54,
        71,
        197,
        2,
        1,
        199,
        195,
        169,
        51,
        199,
        198,
        120,
        92,
        51,
        2,
        197,
        18,
        2,
        65,
        80,
        47,
        177,
        1,
        190,
        20,
        187,
        144,
        172,
        179,
        198,
        47,
        48,
        49,
        138,
        0,
        0,
        24,
        0,
        50,
        1,
        151,
        24,
        121,
        165,
        154,
        141,
        139
      ],
      "weeklyWasted": [
        198,
        47,
        48,
        49,
        138,
        0,
        0,
        24,
        0,
        50,
        1,
        151,
        24,
        121,
        165,
        154,
        141,
        139,
        1,
        16,
        0,
        2,
        33,
        44,
        52,
        48,
        99,
        47,
        99,
        62,
        60,
        72,
        60,
        32,
        42,
        82,
        15,
        73,
        2,
        0,
        97,
        94,
        0,
        72,
        48,
        97,
        43,
        51,
        2,
        97,
        29,
        98,
        99,
        23,
        53,
        0,
        65,
        99,
        27,
        20,
        84,
        98,
        99,
        82,
        0,
        98,
        85,
        75,
        99,
        64
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        55,
        2,
        74,
        9,
        177,
        197,
        179,
        111,
        172,
        1,
        133,
        120,
        53,
        77,
        151,
        77,
        192,
        135,
        3,
        18,
        197,
        82,
        137,
        114,
        24,
        198,
        76,
        60,
        125,
        82,
        0,
        102,
        124,
        59,
        111,
        61,
        72,
        56,
        149,
        100,
        81,
        174,
        0,
        29,
        11,
        196,
        168,
        30,
        192,
        149,
        4,
        143
      ],
      "weeklyWasted": [
        111,
        61,
        72,
        56,
        149,
        100,
        81,
        174,
        0,
        29,
        11,
        196,
        168,
        30,
        192,
        149,
        4,
        143,
        51,
        22,
        16,
        2,
        28,
        46,
        99,
        0,
        74,
        61,
        72,
        66,
        17,
        47,
        62,
        56,
        45,
        99,
        1,
        36,
        14,
        0,
        16,
        0,
        42,
        92,
        60,
        67,
        39,
        44,
        2,
        66,
        29,
        66,
        77,
        23,
        58,
        67,
        32,
        28,
        39,
        80,
        99,
        73,
        0,
        99,
        28,
        30,
        99,
        86,
        99,
        57
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        199,
        95,
        92,
        134,
        0,
        103,
        19,
        129,
        92,
        1,
        167,
        67,
        60,
        0,
        1,
        114,
        192,
        99,
        74,
        197,
        77,
        198,
        117,
        151,
        41,
        115,
        186,
        104,
        199,
        0,
        188,
        0,
        13,
        4,
        60,
        46,
        78,
        190,
        42,
        24,
        98,
        43,
        42,
        102,
        1,
        196,
        44,
        5,
        45,
        156,
        197,
        79
      ],
      "weeklyWasted": [
        60,
        46,
        78,
        190,
        42,
        24,
        98,
        43,
        42,
        102,
        1,
        196,
        44,
        5,
        45,
        156,
        197,
        79,
        76,
        40,
        42,
        2,
        90,
        52,
        0,
        21,
        76,
        99,
        93,
        28,
        74,
        99,
        92,
        87,
        33,
        81,
        1,
        70,
        2,
        0,
        0,
        0,
        13,
        19,
        0,
        76,
        75,
        1,
        2,
        19,
        99,
        98,
        2,
        45,
        67,
        99,
        0,
        71,
        10,
        81,
        33,
        99,
        47,
        33,
        14,
        98,
        15,
        93,
        41,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        114,
        83,
        103,
        92,
        46,
        99,
        194,
        125,
        48,
        198,
        199,
        79,
        175,
        193,
        21,
        3,
        192,
        109,
        36,
        101,
        93,
        4,
        108,
        113,
        139,
        40,
        20,
        33,
        51,
        191,
        51,
        8,
        1,
        10,
        170,
        27,
        198,
        59,
        199,
        100,
        107,
        13,
        81,
        71,
        140,
        196,
        21,
        133,
        60,
        112,
        2,
        75
      ],
      "weeklyWasted": [
        170,
        27,
        198,
        59,
        199,
        100,
        107,
        13,
        81,
        71,
        140,
        196,
        21,
        133,
        60,
        112,
        2,
        75,
        1,
        1,
        0,
        41,
        41,
        22,
        46,
        0,
        83,
        57,
        99,
        1,
        19,
        99,
        26,
        55,
        67,
        88,
        99,
        65,
        74,
        37,
        29,
        0,
        60,
        99,
        98,
        11,
        2,
        1,
        85,
        97,
        38,
        98,
        38,
        40,
        43,
        62,
        99,
        3,
        63,
        17,
        99,
        61,
        70,
        40,
        0,
        21,
        48,
        5,
        99,
        86
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        184,
        153,
        58,
        172,
        164,
        179,
        64,
        3,
        2,
        1,
        12,
        34,
        107,
        199,
        16,
        165,
        192,
        61,
        104,
        121,
        95,
        198,
        117,
        12,
        174,
        198,
        113,
        38,
        0,
        135,
        43,
        9,
        57,
        29,
        198,
        114,
        151,
        127,
        182,
        199,
        106,
        1,
        17,
        6,
        1,
        194,
        104,
        86,
        198,
        181,
        2,
        196
      ],
      "weeklyWasted": [
        198,
        114,
        151,
        127,
        182,
        199,
        106,
        1,
        17,
        6,
        1,
        194,
        104,
        86,
        198,
        181,
        2,
        196,
        74,
        2,
        2,
        2,
        42,
        21,
        0,
        0,
        99,
        68,
        56,
        33,
        21,
        81,
        85,
        9,
        3,
        47,
        13,
        32,
        31,
        86,
        31,
        33,
        74,
        59,
        79,
        79,
        42,
        6,
        99,
        70,
        99,
        59,
        39,
        32,
        62,
        80,
        80,
        99,
        0,
        0,
        37,
        99,
        24,
        38,
        89,
        98,
        41,
        93,
        27,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        114,
        72,
        53,
        107,
        0,
        183,
        194,
        44,
        2,
        15,
        90,
        106,
        198,
        10,
        159,
        6,
        123,
        199,
        87,
        171,
        76,
        108,
        21,
        82,
        46,
        150,
        141,
        1,
        199,
        89,
        13,
        102,
        114,
        163,
        4,
        35,
        155,
        5,
        118,
        34,
        84,
        123,
        0,
        35,
        32,
        196,
        102,
        129,
        198,
        121,
        11,
        69
      ],
      "weeklyWasted": [
        4,
        35,
        155,
        5,
        118,
        34,
        84,
        123,
        0,
        35,
        32,
        196,
        102,
        129,
        198,
        121,
        11,
        69,
        99,
        29,
        99,
        65,
        49,
        1,
        84,
        0,
        90,
        21,
        0,
        25,
        95,
        85,
        69,
        31,
        0,
        59,
        1,
        53,
        64,
        23,
        84,
        99,
        23,
        1,
        65,
        65,
        14,
        3,
        17,
        53,
        36,
        73,
        99,
        0,
        64,
        72,
        37,
        99,
        16,
        15,
        99,
        18,
        20,
        65,
        99,
        64,
        72,
        78,
        43,
        30
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        158,
        2,
        0,
        195,
        118,
        42,
        194,
        197,
        2,
        1,
        188,
        142,
        124,
        102,
        164,
        3,
        180,
        147,
        77,
        71,
        129,
        198,
        2,
        40,
        3,
        198,
        189,
        1,
        153,
        128,
        119,
        36,
        141,
        69,
        120,
        126,
        121,
        32,
        137,
        0,
        17,
        90,
        22,
        141,
        87,
        196,
        0,
        32,
        198,
        188,
        186,
        53
      ],
      "weeklyWasted": [
        120,
        126,
        121,
        32,
        137,
        0,
        17,
        90,
        22,
        141,
        87,
        196,
        0,
        32,
        198,
        188,
        186,
        53,
        78,
        27,
        7,
        2,
        58,
        96,
        98,
        0,
        90,
        55,
        99,
        18,
        30,
        78,
        52,
        31,
        19,
        71,
        2,
        29,
        97,
        56,
        45,
        0,
        0,
        18,
        98,
        27,
        99,
        44,
        2,
        97,
        1,
        98,
        56,
        19,
        82,
        49,
        56,
        99,
        0,
        0,
        35,
        45,
        92,
        78,
        37,
        98,
        98,
        99,
        99,
        38
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        173,
        19,
        70,
        14,
        160,
        1,
        193,
        9,
        88,
        23,
        199,
        56,
        170,
        0,
        107,
        198,
        192,
        119,
        126,
        23,
        71,
        53,
        54,
        1,
        132,
        105,
        106,
        68,
        176,
        115,
        87,
        56,
        87,
        42,
        4,
        153,
        172,
        127,
        57,
        126,
        110,
        80,
        0,
        70,
        1,
        129,
        116,
        0,
        198,
        198,
        2,
        63
      ],
      "weeklyWasted": [
        4,
        153,
        172,
        127,
        57,
        126,
        110,
        80,
        0,
        70,
        1,
        129,
        116,
        0,
        198,
        198,
        2,
        63,
        66,
        5,
        0,
        2,
        98,
        67,
        69,
        15,
        54,
        46,
        99,
        4,
        76,
        89,
        74,
        98,
        11,
        99,
        46,
        16,
        77,
        95,
        21,
        47,
        36,
        1,
        75,
        30,
        14,
        29,
        85,
        1,
        43,
        78,
        71,
        50,
        45,
        75,
        53,
        89,
        25,
        34,
        60,
        65,
        48,
        24,
        34,
        37,
        34,
        98,
        97,
        27
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        199,
        91,
        174,
        98,
        96,
        121,
        194,
        68,
        86,
        1,
        136,
        151,
        176,
        28,
        199,
        74,
        192,
        141,
        163,
        13,
        81,
        100,
        97,
        68,
        3,
        198,
        197,
        85,
        195,
        145,
        129,
        0,
        119,
        42,
        72,
        188,
        37,
        87,
        37,
        62,
        199,
        199,
        114,
        0,
        1,
        132,
        0,
        177,
        1,
        81,
        94,
        3
      ],
      "weeklyWasted": [
        72,
        188,
        37,
        87,
        37,
        62,
        199,
        199,
        114,
        0,
        1,
        132,
        0,
        177,
        1,
        81,
        94,
        3,
        10,
        56,
        0,
        2,
        62,
        64,
        64,
        45,
        14,
        59,
        53,
        46,
        41,
        68,
        65,
        1,
        20,
        31,
        25,
        71,
        41,
        25,
        54,
        2,
        90,
        78,
        22,
        97,
        19,
        1,
        24,
        56,
        45,
        98,
        80,
        41,
        19,
        99,
        97,
        99,
        70,
        0,
        63,
        99,
        71,
        75,
        99,
        38,
        37,
        91,
        41,
        37
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        130,
        186,
        63,
        71,
        39,
        17,
        91,
        41,
        56,
        1,
        63,
        102,
        136,
        79,
        118,
        161,
        192,
        141,
        137,
        182,
        137,
        4,
        129,
        178,
        85,
        122,
        146,
        161,
        164,
        120,
        199,
        84,
        147,
        26,
        198,
        0,
        198,
        160,
        192,
        199,
        64,
        0,
        81,
        70,
        25,
        167,
        18,
        104,
        60,
        2,
        132,
        121
      ],
      "weeklyWasted": [
        198,
        0,
        198,
        160,
        192,
        199,
        64,
        0,
        81,
        70,
        25,
        167,
        18,
        104,
        60,
        2,
        132,
        121,
        82,
        14,
        40,
        32,
        98,
        81,
        0,
        41,
        99,
        61,
        78,
        76,
        68,
        99,
        14,
        39,
        62,
        60,
        41,
        49,
        85,
        0,
        31,
        45,
        0,
        99,
        6,
        22,
        97,
        17,
        2,
        97,
        19,
        45,
        99,
        0,
        62,
        41,
        0,
        50,
        0,
        24,
        23,
        99,
        99,
        49,
        87,
        98,
        99,
        9,
        24,
        1
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        119,
        2,
        157,
        48,
        0,
        134,
        95,
        167,
        174,
        20,
        199,
        155,
        144,
        65,
        199,
        85,
        162,
        199,
        42,
        153,
        197,
        90,
        2,
        159,
        71,
        80,
        25,
        40,
        129,
        44,
        47,
        0,
        197,
        103,
        163,
        0,
        72,
        142,
        82,
        42,
        152,
        4,
        0,
        198,
        66,
        122,
        0,
        56,
        137,
        199,
        134,
        20
      ],
      "weeklyWasted": [
        163,
        0,
        72,
        142,
        82,
        42,
        152,
        4,
        0,
        198,
        66,
        122,
        0,
        56,
        137,
        199,
        134,
        20,
        24,
        5,
        54,
        2,
        98,
        41,
        39,
        46,
        74,
        98,
        0,
        52,
        51,
        79,
        0,
        1,
        55,
        14,
        37,
        77,
        2,
        0,
        71,
        7,
        51,
        66,
        0,
        1,
        12,
        81,
        2,
        61,
        54,
        88,
        79,
        34,
        64,
        43,
        70,
        46,
        0,
        13,
        98,
        99,
        9,
        99,
        99,
        98,
        40,
        99,
        75,
        1
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        54,
        42,
        147,
        73,
        88,
        1,
        3,
        16,
        39,
        78,
        39,
        90,
        2,
        172,
        199,
        64,
        91,
        126,
        48,
        22,
        197,
        74,
        2,
        179,
        197,
        61,
        186,
        1,
        109,
        87,
        195,
        77,
        1,
        96,
        107,
        0,
        143,
        5,
        153,
        199,
        30,
        14,
        199,
        188,
        3,
        196,
        126,
        23,
        198,
        91,
        33,
        43
      ],
      "weeklyWasted": [
        107,
        0,
        143,
        5,
        153,
        199,
        30,
        14,
        199,
        188,
        3,
        196,
        126,
        23,
        198,
        91,
        33,
        43,
        1,
        17,
        34,
        10,
        98,
        51,
        51,
        77,
        12,
        28,
        0,
        91,
        63,
        46,
        0,
        7,
        64,
        47,
        99,
        44,
        51,
        20,
        23,
        0,
        9,
        99,
        98,
        52,
        78,
        96,
        2,
        82,
        60,
        86,
        99,
        36,
        13,
        22,
        99,
        1,
        0,
        0,
        99,
        99,
        72,
        77,
        28,
        98,
        69,
        76,
        28,
        48
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        7,
        2,
        163,
        67,
        146,
        151,
        89,
        3,
        199,
        41,
        106,
        64,
        164,
        0,
        193,
        138,
        192,
        199,
        197,
        190,
        20,
        32,
        2,
        88,
        3,
        36,
        187,
        46,
        110,
        46,
        199,
        0,
        120,
        33,
        4,
        108,
        195,
        52,
        65,
        12,
        15,
        154,
        112,
        73,
        1,
        150,
        150,
        141,
        80,
        181,
        91,
        3
      ],
      "weeklyWasted": [
        4,
        108,
        195,
        52,
        65,
        12,
        15,
        154,
        112,
        73,
        1,
        150,
        150,
        141,
        80,
        181,
        91,
        3,
        63,
        2,
        0,
        54,
        98,
        25,
        41,
        7,
        56,
        41,
        66,
        41,
        99,
        99,
        28,
        43,
        28,
        1,
        43,
        0,
        10,
        0,
        85,
        77,
        35,
        91,
        37,
        90,
        99,
        1,
        2,
        1,
        59,
        24,
        99,
        77,
        99,
        0,
        10,
        37,
        27,
        34,
        88,
        79,
        31,
        71,
        94,
        76,
        99,
        28,
        31,
        22
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        187,
        73,
        198,
        195,
        12,
        4,
        7,
        137,
        111,
        1,
        85,
        170,
        14,
        98,
        75,
        3,
        28,
        1,
        162,
        20,
        140,
        198,
        104,
        67,
        48,
        142,
        197,
        1,
        116,
        61,
        119,
        64,
        52,
        142,
        126,
        117,
        176,
        5,
        183,
        89,
        162,
        18,
        16,
        0,
        92,
        119,
        59,
        75,
        183,
        13,
        137,
        19
      ],
      "weeklyWasted": [
        126,
        117,
        176,
        5,
        183,
        89,
        162,
        18,
        16,
        0,
        92,
        119,
        59,
        75,
        183,
        13,
        137,
        19,
        1,
        1,
        19,
        2,
        80,
        60,
        0,
        0,
        99,
        29,
        99,
        99,
        7,
        22,
        41,
        94,
        65,
        59,
        14,
        12,
        42,
        0,
        16,
        0,
        57,
        60,
        59,
        97,
        2,
        58,
        2,
        95,
        29,
        44,
        20,
        3,
        99,
        99,
        4,
        37,
        27,
        26,
        8,
        83,
        68,
        73,
        99,
        31,
        46,
        68,
        88,
        1
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        199,
        2,
        50,
        195,
        0,
        17,
        91,
        197,
        68,
        1,
        119,
        127,
        56,
        32,
        129,
        162,
        192,
        111,
        3,
        49,
        103,
        133,
        21,
        195,
        3,
        41,
        197,
        95,
        121,
        49,
        0,
        130,
        1,
        52,
        33,
        142,
        108,
        50,
        10,
        0,
        0,
        0,
        0,
        47,
        90,
        112,
        37,
        159,
        107,
        133,
        48,
        3
      ],
      "weeklyWasted": [
        33,
        142,
        108,
        50,
        10,
        0,
        0,
        0,
        0,
        47,
        90,
        112,
        37,
        159,
        107,
        133,
        48,
        3,
        63,
        41,
        17,
        96,
        95,
        21,
        99,
        0,
        99,
        42,
        65,
        72,
        7,
        3,
        97,
        98,
        70,
        92,
        46,
        0,
        34,
        0,
        0,
        0,
        25,
        99,
        98,
        82,
        69,
        70,
        15,
        54,
        69,
        98,
        52,
        0,
        62,
        37,
        71,
        17,
        69,
        39,
        99,
        39,
        0,
        99,
        99,
        98,
        81,
        1,
        42,
        44
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        63,
        3,
        96,
        151,
        78,
        143,
        185,
        136,
        2,
        11,
        199,
        186,
        198,
        98,
        124,
        130,
        108,
        5,
        104,
        78,
        18,
        32,
        130,
        1,
        3,
        128,
        56,
        71,
        101,
        138,
        134,
        8,
        121,
        114,
        189,
        160,
        154,
        39,
        10,
        92,
        166,
        12,
        39,
        0,
        197,
        196,
        43,
        38,
        167,
        199,
        51,
        94
      ],
      "weeklyWasted": [
        189,
        160,
        154,
        39,
        10,
        92,
        166,
        12,
        39,
        0,
        197,
        196,
        43,
        38,
        167,
        199,
        51,
        94,
        89,
        1,
        52,
        57,
        27,
        1,
        71,
        0,
        99,
        53,
        26,
        66,
        7,
        72,
        0,
        19,
        43,
        65,
        92,
        12,
        50,
        22,
        97,
        0,
        0,
        48,
        51,
        72,
        2,
        9,
        43,
        65,
        60,
        98,
        45,
        50,
        70,
        42,
        99,
        56,
        58,
        52,
        99,
        69,
        99,
        21,
        59,
        79,
        64,
        99,
        99,
        27
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        199,
        2,
        135,
        69,
        96,
        150,
        93,
        3,
        199,
        141,
        0,
        27,
        155,
        68,
        199,
        35,
        132,
        192,
        17,
        2,
        1,
        115,
        113,
        70,
        119,
        93,
        178,
        58,
        199,
        89,
        108,
        122,
        108,
        171,
        4,
        101,
        80,
        90,
        10,
        88,
        55,
        53,
        127,
        0,
        1,
        40,
        62,
        66,
        170,
        2,
        127,
        113
      ],
      "weeklyWasted": [
        4,
        101,
        80,
        90,
        10,
        88,
        55,
        53,
        127,
        0,
        1,
        40,
        62,
        66,
        170,
        2,
        127,
        113,
        1,
        19,
        5,
        45,
        98,
        36,
        99,
        55,
        33,
        46,
        82,
        99,
        55,
        79,
        80,
        1,
        49,
        59,
        79,
        27,
        99,
        0,
        3,
        12,
        99,
        60,
        98,
        16,
        44,
        10,
        2,
        81,
        30,
        98,
        99,
        53,
        80,
        54,
        80,
        99,
        24,
        11,
        50,
        99,
        77,
        56,
        62,
        37,
        51,
        54,
        68,
        94
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        161,
        102,
        0,
        5,
        75,
        197,
        3,
        147,
        158,
        116,
        51,
        31,
        154,
        43,
        178,
        198,
        112,
        93,
        3,
        92,
        141,
        4,
        20,
        195,
        3,
        198,
        173,
        82,
        86,
        133,
        20,
        45,
        64,
        115,
        124,
        197,
        162,
        5,
        79,
        170,
        0,
        0,
        66,
        0,
        10,
        106,
        0,
        0,
        187,
        199,
        126,
        196
      ],
      "weeklyWasted": [
        124,
        197,
        162,
        5,
        79,
        170,
        0,
        0,
        66,
        0,
        10,
        106,
        0,
        0,
        187,
        199,
        126,
        196,
        26,
        21,
        99,
        49,
        81,
        90,
        62,
        0,
        99,
        28,
        56,
        62,
        99,
        92,
        97,
        46,
        61,
        44,
        6,
        56,
        44,
        0,
        37,
        0,
        58,
        30,
        52,
        97,
        84,
        1,
        50,
        1,
        99,
        98,
        64,
        10,
        62,
        44,
        68,
        83,
        42,
        77,
        47,
        52,
        46,
        13,
        56,
        98,
        62,
        14,
        36,
        1
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        104,
        2,
        56,
        5,
        25,
        191,
        98,
        12,
        199,
        83,
        199,
        104,
        194,
        18,
        1,
        109,
        192,
        166,
        50,
        151,
        1,
        118,
        121,
        195,
        97,
        153,
        118,
        120,
        199,
        7,
        26,
        119,
        116,
        101,
        4,
        0,
        119,
        108,
        10,
        65,
        86,
        0,
        99,
        35,
        1,
        196,
        110,
        148,
        87,
        199,
        8,
        196
      ],
      "weeklyWasted": [
        4,
        0,
        119,
        108,
        10,
        65,
        86,
        0,
        99,
        35,
        1,
        196,
        110,
        148,
        87,
        199,
        8,
        196,
        52,
        1,
        72,
        96,
        41,
        55,
        99,
        0,
        75,
        99,
        40,
        36,
        99,
        99,
        66,
        39,
        98,
        73,
        68,
        53,
        49,
        78,
        47,
        58,
        16,
        52,
        43,
        50,
        33,
        6,
        43,
        82,
        83,
        98,
        99,
        43,
        70,
        30,
        19,
        39,
        35,
        29,
        0,
        26,
        30,
        99,
        66,
        98,
        62,
        99,
        10,
        1
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        24,
        2,
        98,
        118,
        65,
        54,
        83,
        3,
        3,
        39,
        0,
        184,
        36,
        55,
        113,
        112,
        192,
        149,
        197,
        174,
        167,
        198,
        75,
        1,
        176,
        93,
        182,
        34,
        113,
        198,
        42,
        0,
        24,
        186,
        198,
        199,
        29,
        137,
        150,
        0,
        21,
        98,
        56,
        177,
        42,
        164,
        54,
        66,
        198,
        94,
        95,
        97
      ],
      "weeklyWasted": [
        198,
        199,
        29,
        137,
        150,
        0,
        21,
        98,
        56,
        177,
        42,
        164,
        54,
        66,
        198,
        94,
        95,
        97,
        71,
        25,
        42,
        4,
        66,
        1,
        99,
        0,
        93,
        75,
        99,
        21,
        1,
        99,
        51,
        70,
        22,
        36,
        1,
        99,
        89,
        40,
        62,
        2,
        0,
        1,
        98,
        83,
        98,
        31,
        60,
        97,
        73,
        98,
        99,
        35,
        57,
        99,
        74,
        95,
        0,
        52,
        12,
        74,
        63,
        86,
        94,
        98,
        22,
        99,
        23,
        36
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyUsage": [
        48,
        2,
        152,
        51,
        55,
        197,
        194,
        3,
        145,
        6,
        84,
        2,
        128,
        77,
        178,
        9,
        192,
        183,
        81,
        65,
        158,
        50,
        2,
        135,
        3,
        1,
        84,
        1,
        154,
        61,
        92,
        75,
        64,
        199,
        80,
        60,
        0,
        47,
        167,
        28,
        57,
        0,
        90,
        25,
        84,
        196,
        67,
        198,
        198,
        69,
        97,
        87
      ],
      "weeklyWasted": [
        80,
        60,
        0,
        47,
        167,
        28,
        57,
        0,
        90,
        25,
        84,
        196,
        67,
        198,
        198,
        69,
        97,
        87,
        12,
        13,
        21,
        2,
        40,
        98,
        99,
        24,
        99,
        0,
        68,
        64,
        99,
        3,
        79,
        2,
        14,
        99,
        61,
        36,
        34,
        99,
        46,
        0,
        55,
        99,
        0,
        28,
        28,
        15,
        2,
        97,
        28,
        98,
        99,
        0,
        99,
        94,
        33,
        95,
        99,
        52,
        72,
        34,
        99,
        99,
        99,
        88,
        33,
        99,
        99,
        60
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        171,
        2,
        74,
        131,
        20,
        123,
        150,
        149,
        105,
        1,
        169,
        2,
        36,
        52,
        152,
        87,
        160,
        83,
        139,
        152,
        119,
        4,
        94,
        86,
        3,
        198,
        99,
        69,
        199,
        138,
        199,
        0,
        78,
        182,
        40,
        99,
        182,
        107,
        39,
        108,
        32,
        199,
        14,
        0,
        67,
        4,
        12,
        0,
        198,
        143,
        146,
        123
      ],
      "weeklyWasted": [
        40,
        99,
        182,
        107,
        39,
        108,
        32,
        199,
        14,
        0,
        67,
        4,
        12,
        0,
        198,
        143,
        146,
        123,
        81,
        22,
        58,
        2,
        98,
        1,
        32,
        47,
        69,
        7,
        34,
        1,
        67,
        99,
        55,
        1,
        26,
        15,
        17,
        62,
        48,
        78,
        3,
        63,
        0,
        22,
        31,
        1,
        38,
        1,
        81,
        59,
        17,
        72,
        35,
        0,
        48,
        38,
        46,
        98,
        16,
        0,
        87,
        85,
        18,
        17,
        99,
        45,
        46,
        99,
        61,
        65
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        199,
        152,
        0,
        5,
        0,
        45,
        142,
        3,
        2,
        1,
        199,
        197,
        149,
        119,
        102,
        198,
        91,
        94,
        75,
        99,
        147,
        4,
        96,
        66,
        5,
        198,
        150,
        1,
        124,
        198,
        104,
        0,
        1,
        4,
        4,
        194,
        198,
        46,
        65,
        27,
        8,
        17,
        31,
        88,
        30,
        129,
        0,
        15,
        176,
        130,
        51,
        29
      ],
      "weeklyWasted": [
        4,
        194,
        198,
        46,
        65,
        27,
        8,
        17,
        31,
        88,
        30,
        129,
        0,
        15,
        176,
        130,
        51,
        29,
        43,
        51,
        99,
        10,
        98,
        44,
        64,
        25,
        82,
        0,
        25,
        1,
        1,
        92,
        71,
        98,
        4,
        99,
        99,
        87,
        2,
        99,
        40,
        0,
        0,
        43,
        73,
        68,
        79,
        1,
        2,
        75,
        1,
        98,
        0,
        38,
        68,
        77,
        17,
        34,
        1,
        20,
        48,
        71,
        40,
        35,
        99,
        73,
        46,
        96,
        30,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        199,
        133,
        0,
        11,
        116,
        197,
        32,
        11,
        4,
        166,
        134,
        57,
        2,
        71,
        199,
        187,
        81,
        199,
        34,
        2,
        11,
        42,
        2,
        111,
        111,
        171,
        189,
        127,
        32,
        141,
        6,
        181,
        31,
        86,
        149,
        22,
        186,
        126,
        113,
        100,
        41,
        51,
        143,
        0,
        150,
        162,
        13,
        56,
        19,
        2,
        115,
        180
      ],
      "weeklyWasted": [
        149,
        22,
        186,
        126,
        113,
        100,
        41,
        51,
        143,
        0,
        150,
        162,
        13,
        56,
        19,
        2,
        115,
        180,
        63,
        22,
        0,
        23,
        57,
        72,
        0,
        0,
        28,
        86,
        41,
        76,
        94,
        62,
        11,
        6,
        42,
        26,
        5,
        99,
        78,
        5,
        23,
        1,
        50,
        99,
        76,
        49,
        99,
        1,
        30,
        97,
        1,
        68,
        12,
        3,
        67,
        48,
        18,
        64,
        59,
        98,
        17,
        74,
        79,
        0,
        0,
        98,
        90,
        26,
        52,
        2
      ]
    },
    {
      "id": "4FF22342",
      "weeklyUsage": [
        142,
        127,
        27,
        12,
        0,
        1,
        62,
        100,
        33,
        125,
        169,
        71,
        198,
        42,
        68,
        166,
        192,
        58,
        100,
        2,
        70,
        4,
        43,
        149,
        78,
        112,
        197,
        73,
        112,
        28,
        47,
        21,
        131,
        170,
        117,
        110,
        188,
        8,
        139,
        0,
        0,
        35,
        109,
        0,
        1,
        85,
        0,
        49,
        184,
        5,
        30,
        183
      ],
      "weeklyWasted": [
        117,
        110,
        188,
        8,
        139,
        0,
        0,
        35,
        109,
        0,
        1,
        85,
        0,
        49,
        184,
        5,
        30,
        183,
        51,
        1,
        66,
        8,
        98,
        54,
        0,
        8,
        51,
        43,
        70,
        75,
        99,
        15,
        10,
        94,
        42,
        76,
        8,
        67,
        2,
        49,
        66,
        21,
        44,
        16,
        67,
        97,
        78,
        78,
        2,
        56,
        31,
        98,
        99,
        56,
        83,
        33,
        99,
        93,
        10,
        67,
        68,
        99,
        44,
        66,
        37,
        36,
        29,
        79,
        43,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        138,
        198,
        162,
        130,
        148,
        197,
        11,
        85,
        81,
        199,
        180,
        80,
        198,
        81,
        62,
        100,
        88,
        20,
        47,
        197,
        153,
        79,
        160,
        100,
        3,
        35,
        119,
        1,
        5,
        45,
        84,
        0,
        73,
        109,
        4,
        82,
        66,
        187,
        10,
        163,
        51,
        159,
        0,
        115,
        90,
        178,
        21,
        166,
        198,
        164,
        112,
        3
      ],
      "weeklyWasted": [
        4,
        82,
        66,
        187,
        10,
        163,
        51,
        159,
        0,
        115,
        90,
        178,
        21,
        166,
        198,
        164,
        112,
        3,
        59,
        98,
        99,
        12,
        37,
        24,
        47,
        55,
        63,
        7,
        53,
        99,
        67,
        40,
        31,
        1,
        49,
        23,
        11,
        24,
        2,
        0,
        2,
        29,
        36,
        49,
        71,
        59,
        80,
        1,
        2,
        22,
        60,
        98,
        99,
        25,
        76,
        42,
        13,
        79,
        73,
        38,
        64,
        99,
        0,
        34,
        71,
        57,
        65,
        46,
        48,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        109,
        154,
        178,
        106,
        75,
        141,
        99,
        30,
        89,
        123,
        0,
        125,
        137,
        0,
        48,
        101,
        192,
        137,
        61,
        69,
        5,
        198,
        169,
        29,
        3,
        198,
        176,
        19,
        199,
        118,
        193,
        0,
        121,
        146,
        15,
        184,
        160,
        185,
        123,
        199,
        0,
        50,
        62,
        198,
        29,
        78,
        0,
        175,
        198,
        75,
        63,
        3
      ],
      "weeklyWasted": [
        15,
        184,
        160,
        185,
        123,
        199,
        0,
        50,
        62,
        198,
        29,
        78,
        0,
        175,
        198,
        75,
        63,
        3,
        99,
        98,
        47,
        2,
        98,
        1,
        46,
        55,
        99,
        88,
        0,
        31,
        44,
        59,
        97,
        55,
        52,
        81,
        99,
        99,
        53,
        34,
        1,
        9,
        79,
        64,
        53,
        65,
        27,
        52,
        2,
        61,
        36,
        81,
        39,
        45,
        17,
        36,
        50,
        81,
        64,
        0,
        99,
        58,
        62,
        80,
        99,
        91,
        37,
        99,
        34,
        37
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        189,
        127,
        0,
        5,
        144,
        101,
        191,
        3,
        165,
        137,
        89,
        84,
        124,
        60,
        109,
        112,
        181,
        1,
        3,
        88,
        176,
        152,
        33,
        1,
        3,
        113,
        49,
        90,
        60,
        63,
        38,
        0,
        37,
        4,
        194,
        85,
        131,
        181,
        82,
        73,
        55,
        116,
        40,
        129,
        103,
        163,
        25,
        69,
        1,
        191,
        2,
        3
      ],
      "weeklyWasted": [
        194,
        85,
        131,
        181,
        82,
        73,
        55,
        116,
        40,
        129,
        103,
        163,
        25,
        69,
        1,
        191,
        2,
        3,
        3,
        98,
        18,
        13,
        1,
        55,
        42,
        0,
        92,
        12,
        25,
        1,
        99,
        94,
        12,
        40,
        5,
        46,
        6,
        50,
        2,
        8,
        29,
        0,
        84,
        70,
        65,
        80,
        30,
        1,
        90,
        90,
        74,
        98,
        47,
        99,
        64,
        85,
        6,
        99,
        99,
        0,
        74,
        71,
        99,
        99,
        99,
        3,
        74,
        13,
        99,
        1
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        199,
        41,
        0,
        114,
        0,
        47,
        126,
        197,
        116,
        37,
        199,
        2,
        191,
        0,
        26,
        151,
        74,
        164,
        166,
        147,
        98,
        19,
        15,
        184,
        173,
        67,
        140,
        1,
        110,
        20,
        0,
        61,
        185,
        199,
        193,
        0,
        54,
        100,
        47,
        5,
        124,
        40,
        74,
        47,
        23,
        88,
        0,
        101,
        95,
        199,
        129,
        17
      ],
      "weeklyWasted": [
        193,
        0,
        54,
        100,
        47,
        5,
        124,
        40,
        74,
        47,
        23,
        88,
        0,
        101,
        95,
        199,
        129,
        17,
        56,
        23,
        20,
        2,
        98,
        81,
        73,
        5,
        86,
        96,
        62,
        79,
        65,
        99,
        13,
        8,
        59,
        61,
        1,
        0,
        3,
        60,
        51,
        0,
        0,
        98,
        45,
        81,
        35,
        1,
        20,
        76,
        70,
        89,
        71,
        42,
        53,
        72,
        16,
        42,
        57,
        88,
        99,
        81,
        66,
        46,
        99,
        98,
        41,
        85,
        99,
        8
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        119,
        76,
        42,
        117,
        0,
        189,
        12,
        45,
        199,
        1,
        132,
        120,
        120,
        84,
        199,
        85,
        135,
        199,
        3,
        126,
        52,
        4,
        2,
        15,
        197,
        78,
        163,
        143,
        199,
        157,
        199,
        71,
        109,
        199,
        18,
        167,
        148,
        70,
        149,
        15,
        0,
        112,
        33,
        0,
        185,
        4,
        20,
        198,
        198,
        199,
        187,
        47
      ],
      "weeklyWasted": [
        18,
        167,
        148,
        70,
        149,
        15,
        0,
        112,
        33,
        0,
        185,
        4,
        20,
        198,
        198,
        199,
        187,
        47,
        1,
        11,
        21,
        26,
        86,
        43,
        54,
        32,
        25,
        0,
        99,
        71,
        6,
        47,
        71,
        93,
        48,
        49,
        15,
        87,
        45,
        39,
        29,
        0,
        19,
        59,
        93,
        39,
        2,
        94,
        2,
        45,
        1,
        98,
        58,
        0,
        13,
        21,
        4,
        1,
        99,
        0,
        71,
        91,
        99,
        99,
        39,
        98,
        82,
        31,
        82,
        9
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        86,
        69,
        198,
        35,
        50,
        89,
        25,
        77,
        4,
        43,
        26,
        184,
        113,
        0,
        105,
        184,
        192,
        156,
        197,
        171,
        61,
        94,
        75,
        148,
        197,
        198,
        157,
        70,
        55,
        30,
        166,
        50,
        120,
        4,
        116,
        95,
        11,
        160,
        10,
        12,
        39,
        75,
        18,
        0,
        174,
        167,
        50,
        1,
        198,
        103,
        115,
        159
      ],
      "weeklyWasted": [
        116,
        95,
        11,
        160,
        10,
        12,
        39,
        75,
        18,
        0,
        174,
        167,
        50,
        1,
        198,
        103,
        115,
        159,
        80,
        35,
        39,
        2,
        88,
        26,
        61,
        0,
        72,
        99,
        99,
        31,
        34,
        87,
        43,
        16,
        98,
        1,
        1,
        44,
        72,
        0,
        75,
        0,
        0,
        41,
        98,
        65,
        54,
        5,
        26,
        97,
        70,
        78,
        15,
        83,
        82,
        70,
        62,
        99,
        11,
        0,
        99,
        99,
        80,
        52,
        72,
        61,
        46,
        15,
        64,
        38
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        130,
        15,
        66,
        14,
        78,
        3,
        29,
        8,
        152,
        37,
        84,
        2,
        55,
        78,
        85,
        164,
        162,
        199,
        188,
        2,
        165,
        4,
        197,
        195,
        3,
        1,
        165,
        1,
        199,
        130,
        199,
        54,
        1,
        71,
        177,
        133,
        118,
        199,
        142,
        14,
        30,
        0,
        140,
        42,
        1,
        4,
        128,
        120,
        147,
        15,
        38,
        76
      ],
      "weeklyWasted": [
        177,
        133,
        118,
        199,
        142,
        14,
        30,
        0,
        140,
        42,
        1,
        4,
        128,
        120,
        147,
        15,
        38,
        76,
        39,
        98,
        19,
        53,
        34,
        75,
        56,
        47,
        45,
        93,
        99,
        39,
        32,
        3,
        37,
        98,
        71,
        84,
        1,
        91,
        96,
        90,
        63,
        4,
        89,
        80,
        60,
        81,
        40,
        1,
        2,
        80,
        1,
        98,
        20,
        52,
        73,
        95,
        75,
        49,
        21,
        43,
        30,
        64,
        79,
        0,
        74,
        43,
        11,
        98,
        34,
        13
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        104,
        15,
        67,
        94,
        70,
        47,
        3,
        28,
        2,
        1,
        199,
        61,
        59,
        181,
        167,
        3,
        192,
        42,
        138,
        105,
        1,
        76,
        2,
        195,
        3,
        198,
        197,
        23,
        199,
        61,
        104,
        61,
        1,
        67,
        95,
        106,
        198,
        40,
        10,
        103,
        33,
        0,
        67,
        0,
        112,
        148,
        0,
        67,
        198,
        89,
        170,
        8
      ],
      "weeklyWasted": [
        95,
        106,
        198,
        40,
        10,
        103,
        33,
        0,
        67,
        0,
        112,
        148,
        0,
        67,
        198,
        89,
        170,
        8,
        4,
        1,
        0,
        2,
        48,
        98,
        0,
        41,
        41,
        60,
        89,
        68,
        9,
        99,
        97,
        13,
        77,
        60,
        14,
        99,
        99,
        10,
        0,
        0,
        12,
        15,
        72,
        65,
        62,
        27,
        7,
        50,
        70,
        98,
        48,
        0,
        99,
        81,
        0,
        52,
        37,
        75,
        99,
        57,
        45,
        99,
        30,
        98,
        73,
        86,
        49,
        62
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        146,
        190,
        175,
        127,
        20,
        81,
        194,
        80,
        160,
        1,
        59,
        87,
        176,
        7,
        199,
        33,
        192,
        65,
        151,
        85,
        197,
        4,
        25,
        143,
        3,
        22,
        93,
        50,
        148,
        137,
        167,
        0,
        25,
        121,
        164,
        199,
        198,
        5,
        55,
        94,
        97,
        125,
        86,
        53,
        136,
        196,
        0,
        28,
        198,
        90,
        164,
        31
      ],
      "weeklyWasted": [
        164,
        199,
        198,
        5,
        55,
        94,
        97,
        125,
        86,
        53,
        136,
        196,
        0,
        28,
        198,
        90,
        164,
        31,
        99,
        34,
        26,
        2,
        68,
        2,
        99,
        37,
        42,
        99,
        99,
        75,
        99,
        94,
        12,
        98,
        60,
        59,
        16,
        39,
        61,
        72,
        44,
        64,
        0,
        91,
        50,
        97,
        54,
        9,
        26,
        77,
        36,
        98,
        67,
        36,
        83,
        99,
        20,
        60,
        65,
        0,
        55,
        77,
        97,
        28,
        99,
        65,
        71,
        57,
        26,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        109,
        136,
        146,
        88,
        1,
        176,
        47,
        24,
        105,
        31,
        142,
        109,
        140,
        15,
        1,
        153,
        192,
        1,
        41,
        84,
        145,
        46,
        82,
        195,
        3,
        1,
        107,
        7,
        159,
        198,
        93,
        113,
        69,
        75,
        158,
        197,
        139,
        75,
        29,
        128,
        157,
        35,
        169,
        103,
        1,
        181,
        105,
        106,
        59,
        48,
        25,
        3
      ],
      "weeklyWasted": [
        158,
        197,
        139,
        75,
        29,
        128,
        157,
        35,
        169,
        103,
        1,
        181,
        105,
        106,
        59,
        48,
        25,
        3,
        96,
        60,
        51,
        59,
        77,
        1,
        0,
        40,
        72,
        61,
        73,
        99,
        40,
        99,
        97,
        60,
        67,
        66,
        99,
        67,
        2,
        0,
        0,
        25,
        44,
        60,
        46,
        81,
        16,
        28,
        2,
        87,
        1,
        98,
        74,
        57,
        65,
        37,
        0,
        67,
        51,
        98,
        47,
        81,
        0,
        45,
        48,
        98,
        49,
        77,
        99,
        42
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        156,
        36,
        198,
        98,
        48,
        109,
        194,
        10,
        165,
        13,
        93,
        78,
        141,
        17,
        199,
        3,
        192,
        167,
        110,
        2,
        160,
        4,
        40,
        127,
        107,
        53,
        87,
        148,
        22,
        16,
        17,
        0,
        91,
        169,
        114,
        52,
        165,
        5,
        151,
        110,
        162,
        0,
        92,
        0,
        1,
        155,
        0,
        159,
        194,
        47,
        110,
        3
      ],
      "weeklyWasted": [
        114,
        52,
        165,
        5,
        151,
        110,
        162,
        0,
        92,
        0,
        1,
        155,
        0,
        159,
        194,
        47,
        110,
        3,
        70,
        76,
        26,
        54,
        28,
        60,
        99,
        46,
        19,
        24,
        99,
        38,
        2,
        99,
        59,
        66,
        49,
        77,
        12,
        0,
        99,
        98,
        58,
        17,
        0,
        86,
        64,
        97,
        43,
        14,
        49,
        69,
        99,
        45,
        51,
        44,
        51,
        42,
        30,
        99,
        53,
        0,
        99,
        71,
        76,
        38,
        58,
        72,
        99,
        93,
        16,
        21
      ]
    },
    {
      "id": "3D26994F",
      "weeklyUsage": [
        182,
        58,
        169,
        184,
        77,
        94,
        117,
        131,
        2,
        1,
        93,
        27,
        186,
        0,
        199,
        176,
        192,
        199,
        96,
        64,
        88,
        70,
        64,
        65,
        23,
        153,
        162,
        63,
        80,
        68,
        30,
        38,
        51,
        47,
        4,
        199,
        87,
        66,
        10,
        0,
        95,
        111,
        65,
        11,
        27,
        196,
        110,
        42,
        90,
        138,
        55,
        3
      ],
      "weeklyWasted": [
        4,
        199,
        87,
        66,
        10,
        0,
        95,
        111,
        65,
        11,
        27,
        196,
        110,
        42,
        90,
        138,
        55,
        3,
        61,
        1,
        0,
        2,
        80,
        61,
        97,
        0,
        46,
        0,
        99,
        67,
        16,
        13,
        77,
        50,
        0,
        79,
        1,
        73,
        71,
        0,
        97,
        23,
        66,
        1,
        77,
        9,
        86,
        25,
        2,
        59,
        1,
        98,
        36,
        0,
        50,
        45,
        64,
        99,
        22,
        20,
        87,
        58,
        62,
        71,
        40,
        19,
        53,
        99,
        51,
        26
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        149,
        20,
        64,
        77,
        161,
        152,
        48,
        102,
        49,
        128,
        71,
        24,
        158,
        112,
        122,
        46,
        192,
        199,
        56,
        189,
        1,
        82,
        2,
        44,
        118,
        153,
        102,
        104,
        49,
        59,
        125,
        86,
        89,
        4,
        31,
        53,
        141,
        121,
        61,
        165,
        187,
        14,
        0,
        0,
        1,
        126,
        36,
        147,
        141,
        160,
        85,
        180
      ],
      "weeklyWasted": [
        31,
        53,
        141,
        121,
        61,
        165,
        187,
        14,
        0,
        0,
        1,
        126,
        36,
        147,
        141,
        160,
        85,
        180,
        1,
        1,
        4,
        2,
        88,
        93,
        35,
        0,
        90,
        16,
        99,
        28,
        29,
        99,
        72,
        1,
        0,
        99,
        1,
        29,
        99,
        47,
        67,
        7,
        99,
        99,
        39,
        11,
        55,
        21,
        99,
        53,
        70,
        91,
        99,
        50,
        49,
        75,
        90,
        99,
        0,
        0,
        29,
        71,
        99,
        6,
        99,
        98,
        42,
        99,
        99,
        23
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyUsage": [
        135,
        102,
        46,
        95,
        20,
        197,
        143,
        36,
        81,
        1,
        154,
        67,
        175,
        128,
        131,
        3,
        128,
        91,
        3,
        182,
        153,
        198,
        2,
        126,
        104,
        198,
        89,
        1,
        199,
        70,
        96,
        86,
        196,
        98,
        95,
        54,
        50,
        43,
        188,
        97,
        79,
        116,
        159,
        29,
        1,
        196,
        84,
        0,
        198,
        156,
        111,
        66
      ],
      "weeklyWasted": [
        95,
        54,
        50,
        43,
        188,
        97,
        79,
        116,
        159,
        29,
        1,
        196,
        84,
        0,
        198,
        156,
        111,
        66,
        50,
        34,
        99,
        2,
        98,
        98,
        0,
        35,
        84,
        68,
        99,
        40,
        60,
        85,
        0,
        1,
        12,
        75,
        19,
        64,
        76,
        77,
        27,
        34,
        82,
        18,
        98,
        14,
        2,
        96,
        10,
        54,
        38,
        98,
        55,
        57,
        99,
        69,
        63,
        74,
        22,
        0,
        99,
        99,
        15,
        28,
        69,
        59,
        29,
        87,
        41,
        1
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        65,
        108,
        99,
        55,
        0,
        110,
        94,
        4,
        188,
        1,
        199,
        2,
        186,
        44,
        146,
        48,
        11,
        199,
        20,
        80,
        108,
        68,
        73,
        107,
        3,
        19,
        122,
        107,
        112,
        45,
        103,
        0,
        1,
        124,
        93,
        0,
        83,
        5,
        67,
        92,
        44,
        0,
        89,
        0,
        83,
        196,
        0,
        106,
        198,
        199,
        154,
        116
      ],
      "weeklyWasted": [
        93,
        0,
        83,
        5,
        67,
        92,
        44,
        0,
        89,
        0,
        83,
        196,
        0,
        106,
        198,
        199,
        154,
        116,
        72,
        1,
        21,
        2,
        79,
        11,
        13,
        91,
        92,
        83,
        75,
        79,
        1,
        93,
        0,
        31,
        59,
        80,
        28,
        80,
        42,
        44,
        0,
        18,
        21,
        60,
        0,
        97,
        2,
        40,
        2,
        67,
        99,
        98,
        16,
        18,
        6,
        43,
        61,
        39,
        50,
        18,
        99,
        99,
        94,
        59,
        99,
        67,
        41,
        91,
        77,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        199,
        85,
        82,
        46,
        16,
        118,
        194,
        3,
        30,
        1,
        1,
        197,
        198,
        199,
        145,
        77,
        192,
        181,
        46,
        2,
        197,
        198,
        177,
        55,
        3,
        173,
        89,
        74,
        41,
        144,
        0,
        9,
        71,
        198,
        167,
        107,
        198,
        19,
        75,
        0,
        83,
        0,
        160,
        122,
        37,
        134,
        0,
        76,
        198,
        97,
        197,
        85
      ],
      "weeklyWasted": [
        167,
        107,
        198,
        19,
        75,
        0,
        83,
        0,
        160,
        122,
        37,
        134,
        0,
        76,
        198,
        97,
        197,
        85,
        28,
        96,
        57,
        12,
        0,
        43,
        48,
        0,
        96,
        62,
        26,
        66,
        53,
        99,
        73,
        98,
        15,
        27,
        9,
        14,
        28,
        58,
        21,
        19,
        0,
        56,
        98,
        81,
        99,
        52,
        2,
        97,
        55,
        35,
        98,
        4,
        55,
        99,
        47,
        99,
        62,
        96,
        85,
        99,
        53,
        41,
        95,
        98,
        73,
        81,
        0,
        2
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        199,
        157,
        0,
        29,
        198,
        197,
        46,
        3,
        106,
        71,
        148,
        2,
        125,
        102,
        153,
        192,
        120,
        199,
        59,
        185,
        171,
        176,
        114,
        12,
        86,
        137,
        177,
        88,
        135,
        50,
        60,
        100,
        35,
        79,
        123,
        151,
        159,
        41,
        78,
        156,
        166,
        10,
        14,
        142,
        37,
        120,
        60,
        67,
        183,
        110,
        197,
        126
      ],
      "weeklyWasted": [
        123,
        151,
        159,
        41,
        78,
        156,
        166,
        10,
        14,
        142,
        37,
        120,
        60,
        67,
        183,
        110,
        197,
        126,
        28,
        23,
        31,
        29,
        83,
        72,
        95,
        9,
        60,
        19,
        44,
        53,
        60,
        99,
        97,
        71,
        0,
        73,
        89,
        34,
        34,
        98,
        65,
        18,
        79,
        82,
        98,
        83,
        3,
        54,
        2,
        24,
        37,
        24,
        99,
        52,
        44,
        0,
        49,
        95,
        25,
        0,
        75,
        1,
        31,
        30,
        80,
        98,
        54,
        94,
        33,
        22
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        59,
        87,
        50,
        82,
        151,
        130,
        13,
        63,
        199,
        199,
        126,
        2,
        198,
        4,
        129,
        144,
        163,
        199,
        197,
        125,
        175,
        198,
        93,
        172,
        174,
        198,
        110,
        87,
        118,
        67,
        34,
        76,
        1,
        136,
        141,
        188,
        198,
        112,
        10,
        53,
        120,
        36,
        199,
        4,
        1,
        119,
        191,
        43,
        82,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        141,
        188,
        198,
        112,
        10,
        53,
        120,
        36,
        199,
        4,
        1,
        119,
        191,
        43,
        82,
        199,
        2,
        3,
        94,
        1,
        54,
        2,
        56,
        93,
        99,
        0,
        99,
        70,
        89,
        99,
        99,
        60,
        75,
        8,
        50,
        80,
        88,
        13,
        88,
        0,
        0,
        24,
        80,
        42,
        67,
        49,
        77,
        1,
        2,
        85,
        44,
        98,
        97,
        62,
        88,
        43,
        0,
        99,
        66,
        63,
        97,
        60,
        75,
        0,
        69,
        98,
        47,
        73,
        99,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        111,
        2,
        60,
        5,
        198,
        1,
        87,
        109,
        2,
        1,
        103,
        83,
        198,
        157,
        187,
        155,
        46,
        164,
        3,
        128,
        173,
        70,
        64,
        58,
        7,
        1,
        56,
        81,
        133,
        141,
        95,
        120,
        85,
        45,
        28,
        38,
        51,
        43,
        171,
        134,
        0,
        181,
        0,
        145,
        99,
        148,
        20,
        61,
        172,
        122,
        183,
        78
      ],
      "weeklyWasted": [
        28,
        38,
        51,
        43,
        171,
        134,
        0,
        181,
        0,
        145,
        99,
        148,
        20,
        61,
        172,
        122,
        183,
        78,
        52,
        21,
        19,
        7,
        98,
        37,
        45,
        0,
        61,
        20,
        39,
        1,
        99,
        54,
        0,
        1,
        0,
        19,
        94,
        22,
        2,
        0,
        61,
        38,
        99,
        71,
        72,
        95,
        35,
        57,
        61,
        49,
        30,
        94,
        79,
        19,
        99,
        50,
        99,
        65,
        4,
        19,
        99,
        49,
        29,
        57,
        99,
        98,
        83,
        1,
        99,
        59
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        199,
        4,
        175,
        5,
        45,
        54,
        28,
        72,
        165,
        31,
        41,
        2,
        48,
        158,
        47,
        198,
        150,
        157,
        162,
        50,
        81,
        160,
        2,
        143,
        125,
        198,
        76,
        34,
        52,
        83,
        105,
        77,
        21,
        136,
        198,
        101,
        125,
        199,
        10,
        9,
        64,
        17,
        0,
        172,
        1,
        127,
        177,
        0,
        198,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        198,
        101,
        125,
        199,
        10,
        9,
        64,
        17,
        0,
        172,
        1,
        127,
        177,
        0,
        198,
        199,
        2,
        3,
        70,
        12,
        92,
        2,
        98,
        1,
        49,
        15,
        87,
        99,
        90,
        35,
        51,
        70,
        18,
        39,
        43,
        74,
        24,
        0,
        45,
        61,
        4,
        58,
        44,
        71,
        70,
        97,
        58,
        1,
        99,
        97,
        8,
        2,
        99,
        84,
        29,
        54,
        99,
        1,
        0,
        62,
        49,
        49,
        32,
        54,
        70,
        48,
        60,
        80,
        99,
        89
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        169,
        53,
        0,
        5,
        55,
        61,
        28,
        20,
        44,
        5,
        110,
        20,
        198,
        0,
        100,
        137,
        148,
        185,
        161,
        34,
        1,
        46,
        2,
        147,
        110,
        75,
        150,
        28,
        199,
        3,
        111,
        68,
        1,
        199,
        90,
        0,
        133,
        34,
        126,
        0,
        35,
        0,
        68,
        22,
        1,
        113,
        0,
        5,
        147,
        199,
        2,
        55
      ],
      "weeklyWasted": [
        90,
        0,
        133,
        34,
        126,
        0,
        35,
        0,
        68,
        22,
        1,
        113,
        0,
        5,
        147,
        199,
        2,
        55,
        82,
        1,
        99,
        2,
        98,
        1,
        19,
        0,
        75,
        68,
        99,
        49,
        64,
        5,
        18,
        98,
        21,
        85,
        8,
        66,
        99,
        59,
        37,
        42,
        42,
        51,
        64,
        93,
        86,
        33,
        2,
        75,
        1,
        98,
        4,
        71,
        58,
        39,
        61,
        1,
        55,
        3,
        74,
        99,
        39,
        0,
        45,
        98,
        49,
        99,
        26,
        1
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        199,
        80,
        19,
        38,
        12,
        69,
        194,
        40,
        133,
        32,
        75,
        80,
        198,
        106,
        162,
        3,
        0,
        199,
        135,
        131,
        60,
        4,
        57,
        90,
        197,
        45,
        197,
        65,
        138,
        13,
        14,
        0,
        116,
        199,
        4,
        93,
        24,
        5,
        10,
        22,
        54,
        199,
        0,
        0,
        48,
        112,
        32,
        112,
        198,
        2,
        192,
        54
      ],
      "weeklyWasted": [
        4,
        93,
        24,
        5,
        10,
        22,
        54,
        199,
        0,
        0,
        48,
        112,
        32,
        112,
        198,
        2,
        192,
        54,
        25,
        15,
        62,
        23,
        98,
        46,
        99,
        0,
        6,
        0,
        99,
        1,
        75,
        86,
        33,
        36,
        39,
        71,
        26,
        56,
        22,
        69,
        87,
        18,
        58,
        29,
        67,
        44,
        66,
        43,
        48,
        97,
        31,
        81,
        69,
        9,
        55,
        63,
        40,
        98,
        44,
        0,
        97,
        67,
        70,
        67,
        0,
        87,
        0,
        99,
        59,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        180,
        158,
        198,
        98,
        114,
        102,
        194,
        3,
        73,
        16,
        52,
        33,
        33,
        25,
        199,
        85,
        151,
        199,
        3,
        197,
        175,
        104,
        137,
        154,
        3,
        34,
        40,
        67,
        139,
        77,
        33,
        87,
        34,
        149,
        148,
        124,
        124,
        123,
        138,
        0,
        0,
        8,
        0,
        198,
        1,
        128,
        87,
        186,
        198,
        60,
        31,
        148
      ],
      "weeklyWasted": [
        148,
        124,
        124,
        123,
        138,
        0,
        0,
        8,
        0,
        198,
        1,
        128,
        87,
        186,
        198,
        60,
        31,
        148,
        21,
        2,
        63,
        67,
        94,
        53,
        99,
        89,
        80,
        24,
        60,
        55,
        99,
        12,
        92,
        79,
        20,
        76,
        2,
        0,
        7,
        88,
        34,
        65,
        44,
        99,
        98,
        56,
        53,
        67,
        27,
        37,
        56,
        21,
        99,
        31,
        83,
        65,
        76,
        66,
        77,
        36,
        70,
        99,
        46,
        0,
        30,
        98,
        58,
        83,
        0,
        40
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        43,
        16,
        90,
        38,
        0,
        98,
        63,
        103,
        98,
        1,
        107,
        44,
        2,
        65,
        113,
        163,
        192,
        38,
        3,
        197,
        146,
        119,
        113,
        134,
        3,
        57,
        197,
        38,
        62,
        109,
        112,
        185,
        105,
        122,
        161,
        199,
        0,
        25,
        90,
        151,
        72,
        28,
        0,
        0,
        39,
        91,
        38,
        50,
        165,
        193,
        72,
        3
      ],
      "weeklyWasted": [
        161,
        199,
        0,
        25,
        90,
        151,
        72,
        28,
        0,
        0,
        39,
        91,
        38,
        50,
        165,
        193,
        72,
        3,
        18,
        10,
        69,
        28,
        32,
        98,
        45,
        0,
        40,
        0,
        49,
        38,
        73,
        99,
        71,
        1,
        98,
        99,
        17,
        0,
        67,
        0,
        28,
        39,
        99,
        99,
        79,
        97,
        42,
        64,
        60,
        51,
        49,
        91,
        99,
        34,
        35,
        42,
        91,
        20,
        0,
        19,
        79,
        60,
        65,
        96,
        60,
        85,
        47,
        24,
        57,
        88
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        121,
        97,
        164,
        18,
        0,
        180,
        3,
        92,
        68,
        1,
        64,
        62,
        198,
        126,
        180,
        26,
        109,
        199,
        153,
        18,
        197,
        4,
        100,
        124,
        136,
        37,
        50,
        110,
        127,
        93,
        125,
        109,
        52,
        199,
        198,
        113,
        106,
        107,
        159,
        38,
        32,
        0,
        24,
        57,
        1,
        125,
        0,
        46,
        178,
        162,
        151,
        84
      ],
      "weeklyWasted": [
        198,
        113,
        106,
        107,
        159,
        38,
        32,
        0,
        24,
        57,
        1,
        125,
        0,
        46,
        178,
        162,
        151,
        84,
        1,
        3,
        16,
        27,
        25,
        83,
        67,
        39,
        40,
        11,
        99,
        1,
        32,
        67,
        97,
        77,
        56,
        95,
        52,
        63,
        89,
        76,
        66,
        0,
        99,
        99,
        98,
        97,
        2,
        15,
        5,
        23,
        54,
        88,
        99,
        39,
        55,
        69,
        46,
        1,
        0,
        6,
        27,
        65,
        99,
        99,
        83,
        71,
        16,
        80,
        70,
        46
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        175,
        164,
        109,
        5,
        118,
        197,
        122,
        3,
        84,
        36,
        133,
        116,
        161,
        24,
        175,
        3,
        188,
        135,
        3,
        57,
        197,
        13,
        132,
        161,
        3,
        130,
        42,
        29,
        0,
        173,
        27,
        0,
        25,
        82,
        180,
        189,
        198,
        191,
        190,
        63,
        81,
        94,
        95,
        120,
        10,
        196,
        75,
        3,
        11,
        93,
        122,
        35
      ],
      "weeklyWasted": [
        180,
        189,
        198,
        191,
        190,
        63,
        81,
        94,
        95,
        120,
        10,
        196,
        75,
        3,
        11,
        93,
        122,
        35,
        53,
        4,
        76,
        2,
        68,
        48,
        68,
        21,
        87,
        99,
        92,
        72,
        13,
        84,
        97,
        49,
        20,
        89,
        1,
        80,
        39,
        67,
        15,
        2,
        0,
        84,
        94,
        61,
        59,
        96,
        17,
        77,
        1,
        98,
        0,
        9,
        99,
        93,
        12,
        74,
        29,
        0,
        89,
        76,
        68,
        28,
        18,
        60,
        12,
        93,
        99,
        1
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyUsage": [
        7,
        80,
        162,
        73,
        0,
        140,
        87,
        39,
        72,
        3,
        198,
        61,
        198,
        199,
        1,
        198,
        132,
        173,
        146,
        95,
        14,
        68,
        86,
        195,
        3,
        33,
        165,
        101,
        117,
        129,
        74,
        0,
        197,
        181,
        198,
        199,
        140,
        35,
        163,
        125,
        0,
        0,
        170,
        0,
        66,
        61,
        146,
        198,
        185,
        96,
        171,
        37
      ],
      "weeklyWasted": [
        198,
        199,
        140,
        35,
        163,
        125,
        0,
        0,
        170,
        0,
        66,
        61,
        146,
        198,
        185,
        96,
        171,
        37,
        99,
        16,
        49,
        2,
        41,
        1,
        56,
        70,
        92,
        99,
        44,
        21,
        82,
        28,
        90,
        35,
        98,
        63,
        13,
        74,
        2,
        0,
        43,
        0,
        20,
        59,
        10,
        69,
        57,
        1,
        2,
        65,
        99,
        63,
        48,
        0,
        13,
        50,
        20,
        61,
        8,
        93,
        58,
        69,
        99,
        41,
        1,
        42,
        2,
        96,
        31,
        1
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyUsage": [
        80,
        2,
        0,
        195,
        145,
        95,
        186,
        80,
        2,
        1,
        0,
        70,
        148,
        154,
        199,
        58,
        51,
        1,
        197,
        64,
        197,
        143,
        144,
        71,
        27,
        147,
        181,
        1,
        160,
        121,
        122,
        31,
        16,
        4,
        161,
        26,
        198,
        63,
        83,
        133,
        40,
        0,
        0,
        0,
        77,
        17,
        0,
        15,
        198,
        191,
        51,
        60
      ],
      "weeklyWasted": [
        161,
        26,
        198,
        63,
        83,
        133,
        40,
        0,
        0,
        0,
        77,
        17,
        0,
        15,
        198,
        191,
        51,
        60,
        40,
        6,
        0,
        13,
        68,
        32,
        21,
        4,
        27,
        35,
        14,
        52,
        72,
        93,
        69,
        72,
        17,
        62,
        1,
        48,
        2,
        61,
        97,
        99,
        14,
        61,
        98,
        12,
        62,
        14,
        2,
        89,
        90,
        88,
        60,
        45,
        1,
        92,
        71,
        99,
        28,
        0,
        99,
        37,
        99,
        89,
        82,
        16,
        99,
        64,
        2,
        61
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        128,
        138,
        75,
        5,
        16,
        17,
        7,
        3,
        2,
        19,
        126,
        2,
        198,
        83,
        164,
        39,
        149,
        199,
        38,
        188,
        197,
        87,
        111,
        161,
        197,
        198,
        197,
        39,
        199,
        87,
        0,
        0,
        197,
        170,
        100,
        88,
        0,
        199,
        126,
        185,
        30,
        113,
        0,
        81,
        1,
        114,
        47,
        22,
        198,
        44,
        65,
        119
      ],
      "weeklyWasted": [
        100,
        88,
        0,
        199,
        126,
        185,
        30,
        113,
        0,
        81,
        1,
        114,
        47,
        22,
        198,
        44,
        65,
        119,
        92,
        54,
        44,
        2,
        53,
        33,
        87,
        50,
        47,
        83,
        99,
        36,
        53,
        85,
        97,
        5,
        54,
        58,
        1,
        89,
        76,
        27,
        56,
        11,
        49,
        50,
        98,
        97,
        61,
        1,
        2,
        67,
        78,
        88,
        64,
        9,
        18,
        65,
        34,
        21,
        0,
        0,
        65,
        99,
        73,
        69,
        87,
        85,
        0,
        92,
        53,
        1
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        118,
        28,
        198,
        5,
        0,
        34,
        194,
        164,
        89,
        1,
        19,
        21,
        67,
        44,
        102,
        129,
        126,
        199,
        54,
        85,
        197,
        128,
        174,
        108,
        35,
        198,
        197,
        1,
        199,
        128,
        75,
        0,
        26,
        56,
        68,
        23,
        198,
        5,
        27,
        184,
        111,
        33,
        68,
        47,
        112,
        112,
        12,
        0,
        198,
        141,
        85,
        121
      ],
      "weeklyWasted": [
        68,
        23,
        198,
        5,
        27,
        184,
        111,
        33,
        68,
        47,
        112,
        112,
        12,
        0,
        198,
        141,
        85,
        121,
        85,
        55,
        99,
        62,
        37,
        92,
        77,
        0,
        14,
        6,
        80,
        18,
        76,
        99,
        37,
        46,
        43,
        37,
        59,
        60,
        83,
        0,
        20,
        0,
        34,
        85,
        41,
        43,
        96,
        1,
        2,
        97,
        23,
        71,
        99,
        11,
        99,
        55,
        50,
        88,
        99,
        81,
        51,
        66,
        41,
        61,
        13,
        98,
        62,
        63,
        62,
        16
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        127,
        84,
        133,
        195,
        72,
        71,
        120,
        131,
        176,
        1,
        99,
        52,
        148,
        91,
        1,
        3,
        86,
        193,
        87,
        141,
        161,
        187,
        2,
        139,
        122,
        108,
        147,
        38,
        164,
        73,
        199,
        0,
        1,
        198,
        116,
        40,
        118,
        9,
        163,
        198,
        105,
        40,
        40,
        40,
        33,
        196,
        14,
        3,
        198,
        2,
        83,
        140
      ],
      "weeklyWasted": [
        116,
        40,
        118,
        9,
        163,
        198,
        105,
        40,
        40,
        40,
        33,
        196,
        14,
        3,
        198,
        2,
        83,
        140,
        53,
        6,
        95,
        2,
        98,
        88,
        44,
        0,
        82,
        46,
        98,
        34,
        80,
        99,
        50,
        7,
        19,
        76,
        10,
        51,
        85,
        34,
        11,
        4,
        43,
        84,
        0,
        28,
        84,
        77,
        2,
        87,
        1,
        95,
        86,
        30,
        49,
        80,
        99,
        62,
        88,
        0,
        46,
        41,
        99,
        3,
        69,
        54,
        40,
        99,
        40,
        81
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        28,
        94,
        99,
        79,
        53,
        136,
        29,
        194,
        48,
        51,
        199,
        130,
        108,
        72,
        125,
        56,
        76,
        87,
        60,
        79,
        197,
        17,
        192,
        134,
        3,
        1,
        140,
        109,
        199,
        136,
        42,
        29,
        2,
        64,
        102,
        123,
        77,
        171,
        10,
        80,
        0,
        94,
        64,
        35,
        127,
        98,
        37,
        16,
        198,
        95,
        97,
        96
      ],
      "weeklyWasted": [
        102,
        123,
        77,
        171,
        10,
        80,
        0,
        94,
        64,
        35,
        127,
        98,
        37,
        16,
        198,
        95,
        97,
        96,
        14,
        36,
        1,
        2,
        98,
        1,
        60,
        3,
        70,
        39,
        50,
        50,
        72,
        45,
        10,
        77,
        35,
        11,
        61,
        20,
        2,
        0,
        0,
        58,
        0,
        61,
        0,
        25,
        51,
        1,
        4,
        67,
        25,
        98,
        0,
        10,
        34,
        83,
        55,
        1,
        58,
        4,
        99,
        99,
        59,
        99,
        99,
        95,
        32,
        35,
        52,
        63
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        108,
        2,
        68,
        29,
        0,
        62,
        75,
        189,
        149,
        1,
        164,
        2,
        112,
        0,
        175,
        12,
        192,
        180,
        3,
        2,
        83,
        28,
        52,
        105,
        197,
        1,
        194,
        73,
        199,
        65,
        43,
        72,
        168,
        199,
        44,
        0,
        29,
        88,
        44,
        10,
        0,
        0,
        123,
        20,
        22,
        77,
        137,
        94,
        198,
        199,
        197,
        102
      ],
      "weeklyWasted": [
        44,
        0,
        29,
        88,
        44,
        10,
        0,
        0,
        123,
        20,
        22,
        77,
        137,
        94,
        198,
        199,
        197,
        102,
        69,
        98,
        8,
        2,
        90,
        76,
        62,
        1,
        74,
        99,
        56,
        73,
        71,
        58,
        72,
        42,
        79,
        69,
        24,
        73,
        2,
        13,
        0,
        0,
        22,
        99,
        12,
        33,
        37,
        86,
        2,
        97,
        40,
        96,
        66,
        10,
        82,
        75,
        4,
        46,
        10,
        0,
        99,
        84,
        60,
        99,
        99,
        98,
        99,
        57,
        42,
        65
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        199,
        2,
        25,
        5,
        0,
        132,
        135,
        35,
        171,
        103,
        198,
        32,
        111,
        16,
        199,
        171,
        192,
        35,
        81,
        47,
        37,
        4,
        2,
        137,
        3,
        1,
        77,
        107,
        96,
        0,
        97,
        51,
        67,
        76,
        25,
        172,
        133,
        159,
        38,
        0,
        72,
        0,
        0,
        0,
        3,
        189,
        71,
        104,
        12,
        74,
        81,
        162
      ],
      "weeklyWasted": [
        25,
        172,
        133,
        159,
        38,
        0,
        72,
        0,
        0,
        0,
        3,
        189,
        71,
        104,
        12,
        74,
        81,
        162,
        28,
        56,
        28,
        60,
        55,
        41,
        95,
        0,
        99,
        0,
        16,
        63,
        48,
        54,
        81,
        57,
        30,
        90,
        4,
        26,
        2,
        34,
        53,
        91,
        0,
        99,
        38,
        33,
        75,
        11,
        2,
        86,
        1,
        98,
        93,
        82,
        89,
        35,
        51,
        40,
        47,
        75,
        0,
        21,
        30,
        63,
        81,
        98,
        99,
        99,
        66,
        27
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        199,
        83,
        0,
        69,
        152,
        97,
        194,
        143,
        2,
        68,
        199,
        55,
        149,
        0,
        94,
        3,
        51,
        130,
        169,
        197,
        197,
        25,
        2,
        151,
        100,
        197,
        137,
        17,
        48,
        100,
        54,
        1,
        111,
        125,
        133,
        8,
        174,
        5,
        28,
        43,
        132,
        93,
        37,
        166,
        136,
        163,
        7,
        90,
        198,
        102,
        115,
        3
      ],
      "weeklyWasted": [
        133,
        8,
        174,
        5,
        28,
        43,
        132,
        93,
        37,
        166,
        136,
        163,
        7,
        90,
        198,
        102,
        115,
        3,
        5,
        4,
        0,
        43,
        97,
        4,
        99,
        0,
        49,
        0,
        44,
        20,
        71,
        90,
        11,
        57,
        84,
        65,
        36,
        46,
        52,
        56,
        0,
        20,
        19,
        26,
        39,
        27,
        88,
        81,
        51,
        30,
        52,
        22,
        80,
        27,
        16,
        65,
        61,
        60,
        42,
        52,
        99,
        80,
        99,
        37,
        58,
        98,
        49,
        44,
        56,
        73
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        181,
        2,
        25,
        141,
        93,
        70,
        3,
        164,
        20,
        1,
        152,
        94,
        134,
        74,
        109,
        76,
        120,
        199,
        197,
        2,
        108,
        49,
        158,
        164,
        18,
        198,
        88,
        113,
        158,
        25,
        101,
        109,
        167,
        62,
        198,
        58,
        119,
        97,
        10,
        0,
        0,
        64,
        3,
        0,
        1,
        156,
        51,
        30,
        72,
        63,
        114,
        26
      ],
      "weeklyWasted": [
        198,
        58,
        119,
        97,
        10,
        0,
        0,
        64,
        3,
        0,
        1,
        156,
        51,
        30,
        72,
        63,
        114,
        26,
        45,
        31,
        0,
        36,
        22,
        81,
        65,
        0,
        14,
        0,
        83,
        99,
        99,
        82,
        55,
        25,
        61,
        99,
        21,
        39,
        93,
        0,
        40,
        72,
        87,
        50,
        67,
        60,
        61,
        1,
        2,
        59,
        53,
        98,
        98,
        23,
        21,
        49,
        70,
        35,
        0,
        40,
        33,
        60,
        99,
        83,
        39,
        72,
        52,
        51,
        57,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        7,
        2,
        197,
        100,
        150,
        117,
        194,
        3,
        199,
        1,
        90,
        2,
        92,
        0,
        106,
        131,
        192,
        116,
        47,
        197,
        32,
        7,
        143,
        105,
        16,
        198,
        126,
        57,
        0,
        128,
        26,
        51,
        111,
        122,
        188,
        0,
        32,
        41,
        83,
        0,
        137,
        0,
        103,
        0,
        103,
        196,
        2,
        198,
        76,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        188,
        0,
        32,
        41,
        83,
        0,
        137,
        0,
        103,
        0,
        103,
        196,
        2,
        198,
        76,
        199,
        2,
        3,
        53,
        17,
        56,
        84,
        25,
        1,
        99,
        2,
        0,
        99,
        99,
        36,
        39,
        3,
        0,
        53,
        81,
        42,
        2,
        51,
        29,
        3,
        71,
        0,
        24,
        99,
        5,
        1,
        9,
        5,
        83,
        46,
        90,
        77,
        99,
        27,
        37,
        37,
        99,
        73,
        30,
        29,
        64,
        66,
        71,
        39,
        43,
        93,
        31,
        49,
        99,
        79
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        194,
        84,
        52,
        175,
        1,
        110,
        179,
        124,
        15,
        1,
        112,
        7,
        142,
        133,
        55,
        149,
        192,
        168,
        3,
        2,
        1,
        4,
        163,
        11,
        57,
        119,
        63,
        1,
        87,
        52,
        13,
        5,
        31,
        199,
        171,
        156,
        160,
        7,
        58,
        0,
        186,
        144,
        199,
        128,
        1,
        171,
        0,
        140,
        140,
        65,
        114,
        135
      ],
      "weeklyWasted": [
        171,
        156,
        160,
        7,
        58,
        0,
        186,
        144,
        199,
        128,
        1,
        171,
        0,
        140,
        140,
        65,
        114,
        135,
        1,
        16,
        21,
        30,
        14,
        52,
        26,
        0,
        98,
        86,
        51,
        21,
        38,
        99,
        97,
        26,
        0,
        82,
        44,
        24,
        99,
        38,
        89,
        99,
        56,
        51,
        40,
        68,
        94,
        25,
        83,
        86,
        29,
        98,
        99,
        55,
        99,
        0,
        49,
        26,
        0,
        0,
        83,
        67,
        99,
        0,
        99,
        98,
        35,
        99,
        78,
        1
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        199,
        65,
        154,
        89,
        48,
        94,
        120,
        110,
        150,
        1,
        87,
        22,
        149,
        0,
        173,
        3,
        192,
        199,
        195,
        61,
        197,
        8,
        145,
        164,
        106,
        114,
        51,
        9,
        39,
        76,
        163,
        132,
        197,
        151,
        130,
        0,
        148,
        5,
        89,
        87,
        199,
        94,
        0,
        0,
        22,
        137,
        40,
        86,
        94,
        144,
        122,
        22
      ],
      "weeklyWasted": [
        130,
        0,
        148,
        5,
        89,
        87,
        199,
        94,
        0,
        0,
        22,
        137,
        40,
        86,
        94,
        144,
        122,
        22,
        66,
        48,
        0,
        56,
        75,
        56,
        89,
        7,
        9,
        61,
        94,
        56,
        53,
        99,
        14,
        4,
        20,
        62,
        1,
        65,
        29,
        0,
        58,
        0,
        67,
        99,
        0,
        68,
        91,
        27,
        2,
        7,
        72,
        45,
        52,
        54,
        74,
        40,
        42,
        99,
        46,
        24,
        85,
        99,
        49,
        99,
        99,
        98,
        13,
        67,
        36,
        34
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        21,
        26,
        172,
        17,
        83,
        54,
        194,
        22,
        2,
        72,
        199,
        36,
        78,
        196,
        154,
        144,
        192,
        199,
        56,
        2,
        161,
        56,
        121,
        143,
        170,
        132,
        173,
        1,
        90,
        198,
        0,
        130,
        133,
        148,
        60,
        58,
        48,
        103,
        10,
        92,
        0,
        39,
        116,
        93,
        1,
        157,
        89,
        63,
        198,
        199,
        123,
        3
      ],
      "weeklyWasted": [
        60,
        58,
        48,
        103,
        10,
        92,
        0,
        39,
        116,
        93,
        1,
        157,
        89,
        63,
        198,
        199,
        123,
        3,
        35,
        55,
        75,
        55,
        0,
        98,
        99,
        11,
        86,
        32,
        42,
        51,
        16,
        3,
        93,
        98,
        98,
        74,
        65,
        84,
        66,
        81,
        50,
        0,
        37,
        35,
        44,
        1,
        2,
        76,
        14,
        81,
        1,
        61,
        21,
        0,
        7,
        99,
        8,
        53,
        0,
        42,
        99,
        62,
        85,
        93,
        0,
        57,
        21,
        84,
        99,
        34
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        199,
        2,
        198,
        114,
        0,
        1,
        175,
        105,
        152,
        1,
        199,
        2,
        157,
        46,
        69,
        3,
        57,
        168,
        57,
        14,
        197,
        4,
        17,
        49,
        110,
        54,
        29,
        119,
        144,
        189,
        166,
        126,
        75,
        155,
        4,
        0,
        198,
        5,
        59,
        84,
        0,
        120,
        0,
        0,
        144,
        132,
        75,
        0,
        198,
        199,
        99,
        196
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        5,
        59,
        84,
        0,
        120,
        0,
        0,
        144,
        132,
        75,
        0,
        198,
        199,
        99,
        196,
        66,
        19,
        80,
        2,
        98,
        60,
        69,
        0,
        81,
        21,
        99,
        1,
        36,
        99,
        25,
        27,
        15,
        46,
        18,
        99,
        20,
        2,
        82,
        20,
        42,
        1,
        3,
        53,
        9,
        20,
        2,
        95,
        23,
        92,
        37,
        12,
        35,
        44,
        6,
        99,
        41,
        39,
        99,
        67,
        64,
        27,
        99,
        57,
        74,
        41,
        71,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        116,
        167,
        102,
        12,
        127,
        95,
        194,
        53,
        2,
        8,
        46,
        21,
        35,
        199,
        34,
        157,
        147,
        111,
        23,
        116,
        128,
        129,
        94,
        67,
        115,
        109,
        193,
        1,
        132,
        147,
        29,
        40,
        1,
        135,
        53,
        84,
        0,
        125,
        199,
        21,
        77,
        0,
        43,
        55,
        99,
        196,
        37,
        94,
        134,
        100,
        2,
        27
      ],
      "weeklyWasted": [
        53,
        84,
        0,
        125,
        199,
        21,
        77,
        0,
        43,
        55,
        99,
        196,
        37,
        94,
        134,
        100,
        2,
        27,
        1,
        36,
        39,
        43,
        98,
        64,
        26,
        60,
        99,
        92,
        99,
        50,
        99,
        99,
        69,
        98,
        46,
        32,
        50,
        0,
        78,
        46,
        73,
        0,
        78,
        1,
        98,
        73,
        67,
        46,
        27,
        66,
        33,
        98,
        99,
        82,
        81,
        63,
        99,
        16,
        68,
        3,
        90,
        75,
        99,
        38,
        99,
        52,
        0,
        99,
        5,
        45
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        199,
        78,
        154,
        52,
        0,
        37,
        194,
        58,
        2,
        49,
        63,
        54,
        125,
        110,
        148,
        81,
        104,
        62,
        10,
        68,
        151,
        57,
        150,
        128,
        180,
        15,
        180,
        105,
        35,
        111,
        0,
        196,
        197,
        199,
        24,
        22,
        17,
        136,
        108,
        9,
        72,
        149,
        22,
        188,
        1,
        110,
        137,
        3,
        198,
        158,
        2,
        3
      ],
      "weeklyWasted": [
        24,
        22,
        17,
        136,
        108,
        9,
        72,
        149,
        22,
        188,
        1,
        110,
        137,
        3,
        198,
        158,
        2,
        3,
        15,
        4,
        73,
        51,
        60,
        52,
        83,
        1,
        59,
        72,
        76,
        99,
        57,
        99,
        47,
        98,
        37,
        48,
        95,
        51,
        27,
        99,
        64,
        9,
        99,
        99,
        98,
        59,
        44,
        1,
        88,
        13,
        83,
        94,
        72,
        45,
        1,
        0,
        65,
        83,
        19,
        84,
        44,
        99,
        13,
        54,
        25,
        38,
        0,
        74,
        0,
        45
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyUsage": [
        176,
        67,
        198,
        74,
        8,
        97,
        194,
        97,
        76,
        169,
        78,
        56,
        140,
        88,
        108,
        40,
        169,
        199,
        152,
        29,
        197,
        22,
        177,
        33,
        10,
        37,
        7,
        197,
        199,
        112,
        71,
        78,
        78,
        154,
        198,
        35,
        104,
        38,
        62,
        0,
        151,
        15,
        0,
        0,
        82,
        105,
        27,
        124,
        152,
        199,
        149,
        102
      ],
      "weeklyWasted": [
        198,
        35,
        104,
        38,
        62,
        0,
        151,
        15,
        0,
        0,
        82,
        105,
        27,
        124,
        152,
        199,
        149,
        102,
        1,
        6,
        42,
        75,
        98,
        35,
        99,
        21,
        74,
        57,
        99,
        59,
        25,
        87,
        9,
        38,
        9,
        22,
        69,
        7,
        56,
        99,
        77,
        11,
        24,
        33,
        0,
        74,
        45,
        96,
        36,
        88,
        41,
        76,
        99,
        0,
        74,
        68,
        60,
        1,
        0,
        44,
        69,
        98,
        99,
        48,
        99,
        98,
        62,
        61,
        76,
        57
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        63,
        78,
        0,
        92,
        139,
        71,
        194,
        45,
        48,
        92,
        34,
        2,
        20,
        11,
        106,
        20,
        192,
        1,
        197,
        197,
        146,
        6,
        173,
        19,
        3,
        198,
        70,
        113,
        135,
        30,
        152,
        0,
        1,
        115,
        130,
        73,
        87,
        85,
        143,
        83,
        0,
        0,
        128,
        0,
        53,
        196,
        33,
        0,
        186,
        48,
        2,
        4
      ],
      "weeklyWasted": [
        130,
        73,
        87,
        85,
        143,
        83,
        0,
        0,
        128,
        0,
        53,
        196,
        33,
        0,
        186,
        48,
        2,
        4,
        1,
        6,
        47,
        6,
        75,
        38,
        63,
        0,
        52,
        85,
        99,
        1,
        52,
        77,
        30,
        17,
        40,
        42,
        85,
        51,
        62,
        69,
        37,
        0,
        87,
        2,
        98,
        71,
        47,
        58,
        2,
        67,
        45,
        61,
        38,
        94,
        75,
        80,
        2,
        85,
        57,
        23,
        92,
        99,
        60,
        67,
        77,
        98,
        0,
        85,
        99,
        82
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        199,
        142,
        41,
        49,
        0,
        57,
        62,
        116,
        58,
        14,
        164,
        2,
        2,
        78,
        65,
        179,
        103,
        50,
        80,
        45,
        197,
        131,
        68,
        154,
        3,
        141,
        79,
        87,
        146,
        18,
        98,
        4,
        5,
        116,
        75,
        35,
        143,
        153,
        116,
        76,
        60,
        49,
        0,
        0,
        1,
        4,
        88,
        138,
        175,
        2,
        95,
        6
      ],
      "weeklyWasted": [
        75,
        35,
        143,
        153,
        116,
        76,
        60,
        49,
        0,
        0,
        1,
        4,
        88,
        138,
        175,
        2,
        95,
        6,
        26,
        88,
        89,
        2,
        98,
        68,
        75,
        76,
        43,
        26,
        0,
        47,
        26,
        58,
        63,
        28,
        91,
        28,
        1,
        99,
        57,
        52,
        0,
        15,
        33,
        18,
        72,
        97,
        66,
        19,
        36,
        97,
        79,
        98,
        77,
        32,
        82,
        40,
        28,
        12,
        30,
        92,
        7,
        76,
        52,
        0,
        87,
        69,
        29,
        68,
        53,
        63
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        182,
        67,
        0,
        194,
        11,
        63,
        93,
        118,
        2,
        5,
        122,
        2,
        2,
        0,
        199,
        3,
        192,
        175,
        64,
        75,
        94,
        67,
        2,
        154,
        3,
        112,
        170,
        141,
        199,
        0,
        177,
        0,
        1,
        199,
        4,
        0,
        0,
        199,
        15,
        0,
        11,
        0,
        0,
        79,
        46,
        4,
        0,
        120,
        198,
        133,
        57,
        124
      ],
      "weeklyWasted": [
        4,
        0,
        0,
        199,
        15,
        0,
        11,
        0,
        0,
        79,
        46,
        4,
        0,
        120,
        198,
        133,
        57,
        124,
        61,
        1,
        0,
        4,
        98,
        31,
        92,
        26,
        42,
        94,
        99,
        99,
        97,
        3,
        0,
        1,
        33,
        71,
        12,
        41,
        47,
        58,
        24,
        62,
        0,
        65,
        28,
        97,
        2,
        32,
        30,
        79,
        65,
        98,
        51,
        99,
        42,
        10,
        99,
        34,
        53,
        0,
        90,
        78,
        99,
        63,
        99,
        88,
        26,
        59,
        18,
        92
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        110,
        157,
        18,
        29,
        59,
        48,
        113,
        96,
        105,
        77,
        164,
        99,
        143,
        0,
        70,
        191,
        0,
        199,
        120,
        10,
        18,
        4,
        197,
        130,
        8,
        97,
        153,
        30,
        175,
        153,
        56,
        0,
        52,
        120,
        47,
        25,
        128,
        122,
        121,
        158,
        2,
        61,
        169,
        76,
        20,
        156,
        0,
        33,
        180,
        4,
        115,
        165
      ],
      "weeklyWasted": [
        47,
        25,
        128,
        122,
        121,
        158,
        2,
        61,
        169,
        76,
        20,
        156,
        0,
        33,
        180,
        4,
        115,
        165,
        9,
        25,
        53,
        52,
        98,
        98,
        97,
        33,
        41,
        0,
        81,
        20,
        40,
        46,
        23,
        98,
        60,
        72,
        67,
        51,
        66,
        0,
        76,
        0,
        29,
        99,
        14,
        97,
        88,
        27,
        5,
        97,
        52,
        66,
        95,
        37,
        66,
        99,
        48,
        16,
        19,
        0,
        65,
        58,
        73,
        99,
        42,
        48,
        44,
        99,
        99,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        145,
        6,
        0,
        48,
        0,
        101,
        59,
        6,
        195,
        43,
        27,
        85,
        198,
        0,
        49,
        82,
        170,
        47,
        197,
        3,
        83,
        102,
        2,
        195,
        3,
        159,
        172,
        112,
        67,
        138,
        38,
        44,
        31,
        4,
        193,
        167,
        188,
        199,
        10,
        13,
        2,
        74,
        74,
        72,
        97,
        186,
        0,
        32,
        1,
        174,
        163,
        3
      ],
      "weeklyWasted": [
        193,
        167,
        188,
        199,
        10,
        13,
        2,
        74,
        74,
        72,
        97,
        186,
        0,
        32,
        1,
        174,
        163,
        3,
        99,
        10,
        0,
        58,
        98,
        33,
        63,
        3,
        99,
        99,
        99,
        86,
        75,
        96,
        97,
        34,
        98,
        1,
        34,
        11,
        96,
        0,
        51,
        20,
        0,
        1,
        98,
        22,
        92,
        11,
        2,
        79,
        38,
        90,
        70,
        60,
        39,
        83,
        52,
        74,
        25,
        31,
        41,
        99,
        67,
        99,
        56,
        89,
        38,
        84,
        99,
        32
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        116,
        68,
        129,
        63,
        127,
        147,
        194,
        3,
        199,
        36,
        195,
        164,
        154,
        0,
        68,
        20,
        192,
        143,
        156,
        91,
        109,
        163,
        197,
        117,
        3,
        117,
        3,
        49,
        0,
        64,
        18,
        0,
        140,
        64,
        159,
        28,
        82,
        5,
        10,
        102,
        133,
        16,
        9,
        18,
        63,
        140,
        41,
        170,
        1,
        142,
        17,
        50
      ],
      "weeklyWasted": [
        159,
        28,
        82,
        5,
        10,
        102,
        133,
        16,
        9,
        18,
        63,
        140,
        41,
        170,
        1,
        142,
        17,
        50,
        99,
        10,
        48,
        26,
        63,
        64,
        82,
        0,
        35,
        0,
        66,
        67,
        12,
        86,
        0,
        30,
        91,
        81,
        1,
        47,
        97,
        0,
        49,
        0,
        43,
        48,
        98,
        36,
        99,
        2,
        2,
        97,
        75,
        70,
        80,
        48,
        46,
        55,
        65,
        99,
        83,
        50,
        68,
        50,
        22,
        48,
        99,
        78,
        90,
        64,
        99,
        16
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        147,
        149,
        198,
        5,
        0,
        163,
        31,
        8,
        176,
        64,
        199,
        174,
        130,
        0,
        1,
        87,
        183,
        120,
        3,
        2,
        1,
        198,
        2,
        119,
        139,
        198,
        178,
        75,
        199,
        189,
        38,
        126,
        116,
        4,
        87,
        0,
        164,
        35,
        165,
        72,
        96,
        54,
        199,
        198,
        2,
        124,
        0,
        28,
        175,
        2,
        2,
        196
      ],
      "weeklyWasted": [
        87,
        0,
        164,
        35,
        165,
        72,
        96,
        54,
        199,
        198,
        2,
        124,
        0,
        28,
        175,
        2,
        2,
        196,
        51,
        91,
        92,
        9,
        98,
        98,
        10,
        37,
        99,
        44,
        0,
        99,
        39,
        99,
        97,
        98,
        47,
        84,
        99,
        85,
        2,
        29,
        0,
        7,
        51,
        51,
        81,
        97,
        82,
        10,
        2,
        84,
        1,
        78,
        90,
        10,
        63,
        39,
        49,
        60,
        17,
        57,
        47,
        79,
        55,
        40,
        33,
        58,
        71,
        33,
        62,
        1
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        156,
        76,
        198,
        36,
        49,
        120,
        178,
        84,
        104,
        1,
        116,
        78,
        47,
        133,
        74,
        3,
        192,
        99,
        3,
        93,
        113,
        35,
        82,
        131,
        3,
        62,
        197,
        101,
        199,
        117,
        199,
        0,
        45,
        185,
        4,
        132,
        187,
        81,
        199,
        102,
        0,
        89,
        193,
        71,
        42,
        4,
        0,
        198,
        173,
        199,
        54,
        85
      ],
      "weeklyWasted": [
        4,
        132,
        187,
        81,
        199,
        102,
        0,
        89,
        193,
        71,
        42,
        4,
        0,
        198,
        173,
        199,
        54,
        85,
        22,
        1,
        9,
        38,
        98,
        41,
        4,
        0,
        76,
        20,
        74,
        57,
        32,
        99,
        38,
        88,
        98,
        99,
        15,
        47,
        2,
        35,
        53,
        0,
        41,
        5,
        56,
        15,
        85,
        1,
        2,
        97,
        53,
        78,
        99,
        43,
        65,
        37,
        99,
        83,
        78,
        0,
        89,
        66,
        72,
        50,
        46,
        98,
        41,
        39,
        29,
        29
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyUsage": [
        94,
        2,
        81,
        27,
        114,
        1,
        117,
        27,
        56,
        73,
        29,
        39,
        197,
        0,
        199,
        165,
        192,
        194,
        156,
        2,
        118,
        135,
        2,
        184,
        31,
        46,
        105,
        83,
        146,
        143,
        199,
        0,
        9,
        122,
        100,
        104,
        103,
        5,
        199,
        0,
        45,
        95,
        0,
        30,
        7,
        170,
        3,
        76,
        166,
        191,
        2,
        3
      ],
      "weeklyWasted": [
        100,
        104,
        103,
        5,
        199,
        0,
        45,
        95,
        0,
        30,
        7,
        170,
        3,
        76,
        166,
        191,
        2,
        3,
        1,
        1,
        28,
        31,
        58,
        82,
        71,
        0,
        0,
        28,
        93,
        28,
        59,
        3,
        0,
        35,
        81,
        99,
        7,
        38,
        86,
        43,
        52,
        49,
        20,
        64,
        71,
        63,
        99,
        69,
        24,
        97,
        30,
        98,
        26,
        40,
        16,
        0,
        99,
        47,
        2,
        53,
        99,
        17,
        23,
        59,
        99,
        59,
        0,
        57,
        99,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        64,
        31,
        0,
        76,
        198,
        134,
        194,
        132,
        2,
        82,
        60,
        41,
        198,
        0,
        124,
        70,
        192,
        99,
        43,
        197,
        17,
        4,
        72,
        147,
        3,
        72,
        110,
        1,
        137,
        61,
        85,
        61,
        186,
        150,
        81,
        0,
        0,
        7,
        159,
        87,
        31,
        0,
        159,
        48,
        1,
        178,
        0,
        56,
        185,
        108,
        50,
        144
      ],
      "weeklyWasted": [
        81,
        0,
        0,
        7,
        159,
        87,
        31,
        0,
        159,
        48,
        1,
        178,
        0,
        56,
        185,
        108,
        50,
        144,
        1,
        1,
        47,
        2,
        98,
        48,
        82,
        0,
        6,
        99,
        42,
        1,
        75,
        87,
        65,
        94,
        4,
        74,
        96,
        63,
        74,
        0,
        26,
        79,
        55,
        23,
        64,
        1,
        82,
        17,
        48,
        72,
        51,
        76,
        94,
        0,
        81,
        41,
        0,
        69,
        78,
        1,
        99,
        99,
        69,
        13,
        67,
        98,
        82,
        41,
        55,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        199,
        88,
        3,
        5,
        0,
        1,
        47,
        197,
        48,
        42,
        78,
        2,
        91,
        180,
        107,
        198,
        122,
        181,
        178,
        2,
        124,
        4,
        197,
        113,
        3,
        100,
        197,
        1,
        39,
        34,
        112,
        84,
        5,
        156,
        110,
        199,
        56,
        123,
        58,
        41,
        0,
        0,
        0,
        50,
        37,
        192,
        30,
        198,
        198,
        71,
        144,
        113
      ],
      "weeklyWasted": [
        110,
        199,
        56,
        123,
        58,
        41,
        0,
        0,
        0,
        50,
        37,
        192,
        30,
        198,
        198,
        71,
        144,
        113,
        38,
        45,
        44,
        40,
        89,
        74,
        99,
        0,
        45,
        0,
        68,
        82,
        1,
        73,
        91,
        98,
        68,
        72,
        29,
        3,
        2,
        44,
        94,
        0,
        60,
        99,
        34,
        65,
        68,
        1,
        11,
        85,
        1,
        71,
        29,
        55,
        1,
        39,
        99,
        54,
        6,
        44,
        64,
        54,
        70,
        63,
        95,
        11,
        28,
        18,
        99,
        60
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        199,
        70,
        159,
        33,
        61,
        1,
        156,
        92,
        85,
        6,
        99,
        65,
        137,
        0,
        173,
        3,
        104,
        175,
        150,
        106,
        3,
        94,
        38,
        85,
        98,
        117,
        197,
        1,
        144,
        126,
        9,
        196,
        1,
        192,
        4,
        40,
        7,
        142,
        68,
        9,
        96,
        0,
        13,
        101,
        1,
        7,
        66,
        132,
        198,
        100,
        151,
        107
      ],
      "weeklyWasted": [
        4,
        40,
        7,
        142,
        68,
        9,
        96,
        0,
        13,
        101,
        1,
        7,
        66,
        132,
        198,
        100,
        151,
        107,
        14,
        41,
        51,
        5,
        68,
        98,
        99,
        0,
        95,
        47,
        44,
        48,
        36,
        90,
        0,
        40,
        35,
        99,
        28,
        21,
        47,
        0,
        85,
        30,
        28,
        63,
        53,
        1,
        96,
        1,
        2,
        97,
        41,
        78,
        99,
        8,
        87,
        92,
        43,
        33,
        35,
        0,
        99,
        97,
        67,
        33,
        17,
        98,
        77,
        99,
        24,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        199,
        96,
        128,
        5,
        92,
        187,
        143,
        9,
        98,
        79,
        175,
        91,
        82,
        107,
        158,
        22,
        159,
        199,
        3,
        87,
        152,
        143,
        26,
        121,
        168,
        143,
        162,
        119,
        43,
        137,
        98,
        196,
        1,
        115,
        160,
        47,
        86,
        5,
        69,
        69,
        114,
        0,
        197,
        152,
        199,
        35,
        0,
        198,
        129,
        97,
        109,
        196
      ],
      "weeklyWasted": [
        160,
        47,
        86,
        5,
        69,
        69,
        114,
        0,
        197,
        152,
        199,
        35,
        0,
        198,
        129,
        97,
        109,
        196,
        40,
        20,
        0,
        13,
        58,
        68,
        48,
        8,
        73,
        0,
        40,
        15,
        37,
        83,
        32,
        37,
        18,
        77,
        76,
        38,
        79,
        0,
        14,
        27,
        43,
        83,
        72,
        77,
        65,
        77,
        2,
        59,
        1,
        98,
        96,
        37,
        69,
        40,
        69,
        17,
        35,
        88,
        35,
        73,
        43,
        76,
        38,
        86,
        99,
        51,
        75,
        1
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        38,
        84,
        27,
        5,
        137,
        73,
        134,
        43,
        2,
        1,
        177,
        2,
        35,
        40,
        147,
        96,
        72,
        74,
        3,
        52,
        77,
        134,
        2,
        46,
        3,
        157,
        169,
        93,
        21,
        96,
        11,
        150,
        54,
        43,
        119,
        97,
        115,
        5,
        160,
        71,
        43,
        0,
        142,
        0,
        35,
        26,
        29,
        110,
        188,
        12,
        2,
        173
      ],
      "weeklyWasted": [
        119,
        97,
        115,
        5,
        160,
        71,
        43,
        0,
        142,
        0,
        35,
        26,
        29,
        110,
        188,
        12,
        2,
        173,
        35,
        9,
        0,
        26,
        98,
        42,
        26,
        0,
        99,
        0,
        39,
        84,
        60,
        67,
        8,
        30,
        0,
        65,
        76,
        49,
        2,
        81,
        0,
        46,
        99,
        56,
        98,
        69,
        99,
        96,
        2,
        36,
        76,
        92,
        78,
        43,
        83,
        99,
        77,
        1,
        40,
        49,
        53,
        99,
        10,
        37,
        99,
        28,
        31,
        99,
        67,
        24
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        157,
        57,
        125,
        173,
        0,
        168,
        7,
        74,
        199,
        1,
        126,
        184,
        95,
        0,
        196,
        184,
        55,
        137,
        98,
        2,
        145,
        198,
        2,
        177,
        52,
        63,
        177,
        73,
        192,
        89,
        79,
        196,
        81,
        35,
        192,
        199,
        153,
        95,
        90,
        108,
        0,
        49,
        79,
        12,
        199,
        4,
        195,
        173,
        198,
        114,
        186,
        3
      ],
      "weeklyWasted": [
        192,
        199,
        153,
        95,
        90,
        108,
        0,
        49,
        79,
        12,
        199,
        4,
        195,
        173,
        198,
        114,
        186,
        3,
        91,
        3,
        30,
        30,
        98,
        13,
        92,
        0,
        70,
        21,
        19,
        99,
        53,
        54,
        18,
        68,
        98,
        71,
        23,
        44,
        14,
        0,
        0,
        0,
        13,
        1,
        98,
        78,
        78,
        25,
        2,
        73,
        1,
        74,
        84,
        9,
        46,
        0,
        43,
        99,
        10,
        8,
        99,
        63,
        0,
        99,
        92,
        98,
        99,
        6,
        99,
        1
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        199,
        2,
        154,
        72,
        154,
        136,
        178,
        3,
        65,
        1,
        199,
        2,
        115,
        91,
        176,
        3,
        192,
        199,
        46,
        24,
        197,
        26,
        94,
        1,
        5,
        1,
        95,
        85,
        28,
        74,
        0,
        122,
        98,
        5,
        4,
        49,
        23,
        154,
        181,
        0,
        23,
        122,
        0,
        21,
        1,
        158,
        113,
        89,
        194,
        199,
        110,
        76
      ],
      "weeklyWasted": [
        4,
        49,
        23,
        154,
        181,
        0,
        23,
        122,
        0,
        21,
        1,
        158,
        113,
        89,
        194,
        199,
        110,
        76,
        1,
        23,
        0,
        51,
        36,
        60,
        81,
        38,
        61,
        33,
        75,
        12,
        44,
        3,
        75,
        98,
        65,
        6,
        55,
        97,
        63,
        33,
        52,
        50,
        27,
        55,
        98,
        97,
        36,
        23,
        99,
        55,
        38,
        98,
        59,
        34,
        51,
        59,
        99,
        7,
        99,
        0,
        7,
        23,
        99,
        5,
        78,
        98,
        29,
        99,
        0,
        58
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        108,
        2,
        188,
        5,
        0,
        112,
        163,
        18,
        90,
        1,
        70,
        68,
        150,
        141,
        102,
        90,
        113,
        199,
        160,
        27,
        131,
        101,
        74,
        88,
        197,
        198,
        163,
        1,
        143,
        116,
        37,
        0,
        48,
        144,
        161,
        125,
        58,
        176,
        136,
        0,
        140,
        29,
        0,
        89,
        12,
        196,
        0,
        67,
        159,
        102,
        55,
        196
      ],
      "weeklyWasted": [
        161,
        125,
        58,
        176,
        136,
        0,
        140,
        29,
        0,
        89,
        12,
        196,
        0,
        67,
        159,
        102,
        55,
        196,
        13,
        1,
        31,
        21,
        56,
        1,
        62,
        0,
        99,
        19,
        57,
        4,
        49,
        99,
        87,
        98,
        38,
        62,
        18,
        43,
        87,
        36,
        63,
        43,
        80,
        40,
        82,
        88,
        58,
        9,
        2,
        78,
        1,
        83,
        99,
        70,
        2,
        65,
        99,
        63,
        0,
        21,
        59,
        50,
        78,
        37,
        99,
        87,
        69,
        99,
        18,
        26
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        66,
        2,
        198,
        144,
        0,
        153,
        23,
        114,
        162,
        1,
        199,
        2,
        194,
        0,
        153,
        12,
        192,
        178,
        98,
        2,
        164,
        198,
        151,
        36,
        165,
        198,
        52,
        55,
        0,
        147,
        110,
        171,
        170,
        58,
        179,
        91,
        100,
        161,
        108,
        37,
        63,
        181,
        0,
        93,
        1,
        155,
        159,
        173,
        198,
        199,
        197,
        140
      ],
      "weeklyWasted": [
        179,
        91,
        100,
        161,
        108,
        37,
        63,
        181,
        0,
        93,
        1,
        155,
        159,
        173,
        198,
        199,
        197,
        140,
        99,
        63,
        61,
        42,
        71,
        26,
        99,
        0,
        50,
        0,
        99,
        92,
        72,
        39,
        60,
        56,
        3,
        48,
        1,
        92,
        50,
        21,
        58,
        37,
        18,
        68,
        61,
        97,
        98,
        58,
        2,
        59,
        30,
        98,
        99,
        21,
        94,
        99,
        0,
        86,
        0,
        36,
        99,
        99,
        56,
        40,
        99,
        98,
        62,
        97,
        37,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        56,
        67,
        40,
        46,
        110,
        144,
        194,
        3,
        194,
        92,
        16,
        39,
        20,
        21,
        67,
        67,
        192,
        192,
        39,
        190,
        197,
        4,
        155,
        159,
        3,
        1,
        186,
        1,
        199,
        130,
        8,
        65,
        1,
        199,
        174,
        29,
        51,
        5,
        199,
        0,
        60,
        0,
        42,
        42,
        92,
        116,
        70,
        150,
        195,
        178,
        2,
        115
      ],
      "weeklyWasted": [
        174,
        29,
        51,
        5,
        199,
        0,
        60,
        0,
        42,
        42,
        92,
        116,
        70,
        150,
        195,
        178,
        2,
        115,
        37,
        1,
        34,
        65,
        49,
        98,
        71,
        96,
        0,
        40,
        70,
        25,
        5,
        58,
        16,
        41,
        63,
        85,
        38,
        54,
        66,
        78,
        0,
        0,
        99,
        60,
        72,
        78,
        99,
        72,
        32,
        62,
        30,
        66,
        81,
        51,
        99,
        37,
        99,
        32,
        58,
        72,
        0,
        53,
        64,
        18,
        35,
        98,
        46,
        31,
        32,
        97
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        127,
        58,
        198,
        100,
        34,
        197,
        24,
        3,
        74,
        58,
        89,
        176,
        165,
        119,
        154,
        3,
        192,
        27,
        3,
        2,
        197,
        4,
        176,
        1,
        185,
        16,
        197,
        33,
        182,
        100,
        25,
        0,
        103,
        72,
        4,
        193,
        198,
        112,
        137,
        69,
        84,
        0,
        0,
        0,
        1,
        4,
        75,
        0,
        198,
        146,
        18,
        61
      ],
      "weeklyWasted": [
        4,
        193,
        198,
        112,
        137,
        69,
        84,
        0,
        0,
        0,
        1,
        4,
        75,
        0,
        198,
        146,
        18,
        61,
        10,
        41,
        0,
        2,
        46,
        56,
        27,
        0,
        77,
        14,
        99,
        76,
        54,
        83,
        21,
        3,
        21,
        91,
        6,
        81,
        61,
        50,
        39,
        0,
        77,
        40,
        98,
        59,
        91,
        51,
        2,
        56,
        54,
        75,
        1,
        0,
        76,
        93,
        21,
        99,
        2,
        0,
        42,
        85,
        99,
        53,
        99,
        98,
        0,
        93,
        45,
        76
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        120,
        167,
        184,
        81,
        92,
        1,
        194,
        114,
        196,
        1,
        43,
        24,
        2,
        69,
        48,
        157,
        192,
        134,
        67,
        142,
        134,
        198,
        53,
        184,
        3,
        120,
        138,
        84,
        26,
        143,
        140,
        0,
        197,
        102,
        186,
        122,
        65,
        5,
        78,
        5,
        113,
        0,
        192,
        93,
        49,
        91,
        18,
        131,
        129,
        56,
        120,
        94
      ],
      "weeklyWasted": [
        186,
        122,
        65,
        5,
        78,
        5,
        113,
        0,
        192,
        93,
        49,
        91,
        18,
        131,
        129,
        56,
        120,
        94,
        83,
        65,
        99,
        44,
        98,
        74,
        99,
        68,
        53,
        69,
        69,
        27,
        51,
        79,
        33,
        86,
        57,
        70,
        58,
        70,
        72,
        0,
        0,
        37,
        27,
        14,
        73,
        33,
        31,
        96,
        5,
        79,
        1,
        98,
        83,
        53,
        51,
        85,
        47,
        35,
        39,
        61,
        5,
        99,
        76,
        0,
        42,
        58,
        5,
        99,
        48,
        50
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        126,
        2,
        158,
        99,
        133,
        100,
        163,
        10,
        134,
        80,
        17,
        113,
        172,
        103,
        199,
        3,
        140,
        178,
        31,
        49,
        32,
        7,
        22,
        1,
        22,
        73,
        152,
        84,
        127,
        122,
        135,
        85,
        1,
        163,
        4,
        0,
        151,
        46,
        75,
        0,
        117,
        63,
        48,
        12,
        49,
        196,
        102,
        198,
        74,
        170,
        2,
        8
      ],
      "weeklyWasted": [
        4,
        0,
        151,
        46,
        75,
        0,
        117,
        63,
        48,
        12,
        49,
        196,
        102,
        198,
        74,
        170,
        2,
        8,
        1,
        1,
        0,
        60,
        98,
        1,
        65,
        0,
        0,
        42,
        81,
        7,
        1,
        43,
        64,
        20,
        42,
        29,
        7,
        47,
        2,
        19,
        41,
        3,
        25,
        81,
        45,
        20,
        33,
        9,
        53,
        43,
        89,
        92,
        99,
        58,
        42,
        36,
        52,
        87,
        45,
        1,
        97,
        63,
        81,
        43,
        94,
        69,
        42,
        1,
        8,
        52
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        28,
        39,
        86,
        47,
        74,
        59,
        17,
        3,
        49,
        40,
        0,
        163,
        140,
        25,
        100,
        126,
        184,
        149,
        12,
        197,
        65,
        4,
        111,
        179,
        3,
        34,
        77,
        1,
        121,
        106,
        108,
        64,
        84,
        15,
        36,
        70,
        165,
        58,
        125,
        141,
        0,
        104,
        39,
        62,
        138,
        195,
        0,
        0,
        172,
        199,
        106,
        3
      ],
      "weeklyWasted": [
        36,
        70,
        165,
        58,
        125,
        141,
        0,
        104,
        39,
        62,
        138,
        195,
        0,
        0,
        172,
        199,
        106,
        3,
        79,
        93,
        22,
        18,
        98,
        33,
        0,
        0,
        76,
        50,
        0,
        1,
        33,
        99,
        0,
        98,
        32,
        52,
        89,
        25,
        90,
        0,
        67,
        48,
        57,
        85,
        98,
        23,
        49,
        1,
        47,
        97,
        50,
        98,
        57,
        56,
        75,
        50,
        0,
        20,
        33,
        41,
        99,
        99,
        31,
        30,
        44,
        98,
        35,
        84,
        55,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        185,
        87,
        109,
        5,
        106,
        197,
        25,
        48,
        27,
        1,
        120,
        25,
        165,
        49,
        199,
        81,
        135,
        172,
        29,
        68,
        84,
        28,
        40,
        63,
        117,
        1,
        197,
        26,
        57,
        78,
        121,
        176,
        1,
        148,
        123,
        75,
        142,
        170,
        164,
        39,
        99,
        75,
        0,
        81,
        1,
        180,
        0,
        94,
        49,
        63,
        165,
        137
      ],
      "weeklyWasted": [
        123,
        75,
        142,
        170,
        164,
        39,
        99,
        75,
        0,
        81,
        1,
        180,
        0,
        94,
        49,
        63,
        165,
        137,
        27,
        2,
        0,
        41,
        14,
        96,
        33,
        50,
        99,
        0,
        99,
        69,
        88,
        79,
        87,
        4,
        94,
        57,
        5,
        74,
        2,
        83,
        4,
        6,
        74,
        65,
        0,
        97,
        37,
        35,
        31,
        97,
        1,
        96,
        59,
        41,
        75,
        99,
        45,
        99,
        13,
        19,
        62,
        37,
        99,
        79,
        68,
        78,
        99,
        60,
        10,
        6
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        66,
        2,
        97,
        156,
        102,
        69,
        191,
        4,
        199,
        148,
        80,
        79,
        144,
        0,
        79,
        99,
        130,
        27,
        47,
        176,
        103,
        177,
        78,
        89,
        3,
        114,
        197,
        1,
        39,
        8,
        36,
        8,
        119,
        14,
        150,
        199,
        82,
        69,
        46,
        103,
        150,
        199,
        94,
        51,
        199,
        57,
        67,
        60,
        163,
        199,
        72,
        34
      ],
      "weeklyWasted": [
        150,
        199,
        82,
        69,
        46,
        103,
        150,
        199,
        94,
        51,
        199,
        57,
        67,
        60,
        163,
        199,
        72,
        34,
        16,
        27,
        24,
        72,
        98,
        56,
        44,
        0,
        54,
        32,
        19,
        88,
        78,
        86,
        4,
        47,
        6,
        1,
        50,
        9,
        40,
        0,
        9,
        80,
        34,
        99,
        57,
        1,
        2,
        14,
        26,
        76,
        9,
        98,
        67,
        55,
        2,
        74,
        26,
        99,
        63,
        56,
        99,
        10,
        56,
        7,
        34,
        77,
        99,
        87,
        37,
        78
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        24,
        2,
        0,
        56,
        28,
        64,
        188,
        197,
        199,
        112,
        60,
        2,
        8,
        78,
        169,
        186,
        89,
        199,
        112,
        20,
        197,
        92,
        120,
        195,
        35,
        1,
        3,
        149,
        141,
        5,
        45,
        68,
        87,
        199,
        57,
        39,
        198,
        5,
        153,
        115,
        56,
        50,
        0,
        56,
        87,
        112,
        0,
        124,
        191,
        199,
        197,
        127
      ],
      "weeklyWasted": [
        57,
        39,
        198,
        5,
        153,
        115,
        56,
        50,
        0,
        56,
        87,
        112,
        0,
        124,
        191,
        199,
        197,
        127,
        41,
        19,
        7,
        65,
        98,
        98,
        54,
        28,
        18,
        25,
        81,
        89,
        99,
        3,
        23,
        5,
        25,
        99,
        1,
        62,
        99,
        65,
        0,
        24,
        44,
        77,
        60,
        1,
        31,
        61,
        19,
        97,
        31,
        74,
        51,
        65,
        88,
        37,
        99,
        54,
        70,
        18,
        99,
        1,
        5,
        0,
        76,
        93,
        0,
        99,
        59,
        46
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        180,
        85,
        128,
        13,
        0,
        93,
        53,
        56,
        96,
        41,
        44,
        154,
        2,
        0,
        199,
        198,
        162,
        199,
        3,
        2,
        1,
        198,
        149,
        161,
        197,
        179,
        187,
        58,
        17,
        163,
        158,
        69,
        1,
        4,
        4,
        26,
        100,
        61,
        146,
        7,
        54,
        6,
        93,
        0,
        110,
        193,
        89,
        77,
        198,
        199,
        18,
        47
      ],
      "weeklyWasted": [
        4,
        26,
        100,
        61,
        146,
        7,
        54,
        6,
        93,
        0,
        110,
        193,
        89,
        77,
        198,
        199,
        18,
        47,
        48,
        46,
        10,
        96,
        23,
        55,
        33,
        10,
        41,
        36,
        15,
        74,
        1,
        63,
        47,
        82,
        56,
        32,
        63,
        0,
        2,
        68,
        30,
        1,
        37,
        82,
        64,
        68,
        79,
        28,
        3,
        64,
        18,
        98,
        50,
        85,
        55,
        72,
        99,
        99,
        0,
        0,
        93,
        89,
        99,
        76,
        48,
        89,
        65,
        85,
        99,
        31
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        185,
        84,
        98,
        159,
        0,
        129,
        117,
        3,
        137,
        70,
        101,
        67,
        93,
        42,
        199,
        3,
        192,
        148,
        78,
        177,
        115,
        46,
        165,
        113,
        145,
        72,
        197,
        67,
        199,
        144,
        144,
        161,
        100,
        167,
        175,
        66,
        157,
        5,
        164,
        0,
        17,
        0,
        7,
        0,
        1,
        78,
        37,
        39,
        160,
        80,
        43,
        9
      ],
      "weeklyWasted": [
        175,
        66,
        157,
        5,
        164,
        0,
        17,
        0,
        7,
        0,
        1,
        78,
        37,
        39,
        160,
        80,
        43,
        9,
        17,
        14,
        20,
        2,
        70,
        88,
        27,
        0,
        40,
        73,
        90,
        1,
        37,
        78,
        14,
        85,
        25,
        78,
        2,
        4,
        39,
        74,
        24,
        0,
        99,
        9,
        62,
        76,
        74,
        96,
        2,
        75,
        39,
        98,
        0,
        0,
        56,
        79,
        0,
        41,
        19,
        98,
        99,
        58,
        77,
        99,
        99,
        71,
        88,
        83,
        25,
        22
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        98,
        2,
        124,
        147,
        171,
        86,
        194,
        7,
        52,
        160,
        130,
        41,
        47,
        0,
        92,
        89,
        141,
        77,
        64,
        91,
        28,
        89,
        87,
        52,
        85,
        97,
        113,
        88,
        0,
        27,
        22,
        56,
        125,
        199,
        34,
        123,
        89,
        161,
        10,
        25,
        107,
        74,
        82,
        198,
        18,
        174,
        195,
        35,
        103,
        73,
        2,
        79
      ],
      "weeklyWasted": [
        34,
        123,
        89,
        161,
        10,
        25,
        107,
        74,
        82,
        198,
        18,
        174,
        195,
        35,
        103,
        73,
        2,
        79,
        20,
        1,
        4,
        12,
        47,
        78,
        99,
        4,
        72,
        46,
        20,
        90,
        41,
        73,
        26,
        15,
        98,
        67,
        60,
        16,
        36,
        0,
        0,
        99,
        99,
        99,
        31,
        1,
        37,
        35,
        2,
        97,
        1,
        67,
        69,
        0,
        87,
        84,
        99,
        90,
        99,
        14,
        99,
        10,
        20,
        0,
        99,
        98,
        99,
        98,
        99,
        99
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        116,
        9,
        198,
        70,
        29,
        114,
        190,
        51,
        45,
        82,
        58,
        113,
        198,
        1,
        159,
        146,
        118,
        165,
        39,
        148,
        111,
        79,
        140,
        195,
        114,
        184,
        197,
        131,
        0,
        0,
        176,
        163,
        106,
        69,
        24,
        65,
        48,
        47,
        199,
        105,
        44,
        0,
        0,
        119,
        8,
        193,
        52,
        198,
        182,
        153,
        61,
        106
      ],
      "weeklyWasted": [
        24,
        65,
        48,
        47,
        199,
        105,
        44,
        0,
        0,
        119,
        8,
        193,
        52,
        198,
        182,
        153,
        61,
        106,
        63,
        1,
        31,
        18,
        76,
        65,
        86,
        37,
        37,
        34,
        69,
        57,
        1,
        98,
        82,
        1,
        38,
        72,
        1,
        0,
        22,
        52,
        28,
        77,
        66,
        99,
        37,
        32,
        49,
        29,
        19,
        42,
        68,
        71,
        99,
        48,
        99,
        33,
        42,
        93,
        50,
        34,
        47,
        1,
        74,
        99,
        34,
        98,
        99,
        1,
        99,
        56
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        78,
        11,
        166,
        195,
        52,
        53,
        194,
        3,
        191,
        1,
        27,
        2,
        198,
        117,
        17,
        113,
        136,
        113,
        197,
        67,
        18,
        26,
        105,
        1,
        117,
        152,
        127,
        35,
        81,
        120,
        199,
        0,
        123,
        145,
        115,
        84,
        120,
        5,
        122,
        199,
        70,
        102,
        199,
        100,
        8,
        196,
        0,
        0,
        113,
        171,
        108,
        97
      ],
      "weeklyWasted": [
        115,
        84,
        120,
        5,
        122,
        199,
        70,
        102,
        199,
        100,
        8,
        196,
        0,
        0,
        113,
        171,
        108,
        97,
        72,
        41,
        27,
        14,
        98,
        98,
        45,
        19,
        54,
        28,
        99,
        23,
        42,
        99,
        79,
        58,
        68,
        65,
        17,
        31,
        92,
        65,
        36,
        21,
        0,
        90,
        61,
        97,
        2,
        1,
        12,
        81,
        67,
        3,
        39,
        32,
        76,
        63,
        90,
        99,
        0,
        0,
        59,
        99,
        33,
        99,
        42,
        75,
        99,
        99,
        12,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        181,
        101,
        111,
        53,
        96,
        1,
        83,
        38,
        102,
        1,
        38,
        2,
        198,
        62,
        1,
        171,
        192,
        138,
        3,
        38,
        95,
        4,
        2,
        43,
        74,
        193,
        197,
        45,
        145,
        124,
        70,
        28,
        1,
        90,
        38,
        126,
        151,
        103,
        101,
        113,
        74,
        0,
        76,
        69,
        1,
        156,
        81,
        14,
        198,
        156,
        2,
        64
      ],
      "weeklyWasted": [
        38,
        126,
        151,
        103,
        101,
        113,
        74,
        0,
        76,
        69,
        1,
        156,
        81,
        14,
        198,
        156,
        2,
        64,
        46,
        19,
        43,
        25,
        53,
        38,
        52,
        0,
        95,
        55,
        62,
        62,
        64,
        34,
        0,
        39,
        67,
        37,
        62,
        56,
        73,
        63,
        65,
        12,
        99,
        99,
        75,
        62,
        32,
        41,
        2,
        97,
        41,
        98,
        87,
        63,
        66,
        13,
        99,
        1,
        26,
        0,
        99,
        16,
        33,
        0,
        99,
        98,
        21,
        48,
        54,
        13
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyUsage": [
        199,
        2,
        167,
        76,
        0,
        111,
        133,
        197,
        150,
        1,
        199,
        153,
        198,
        0,
        64,
        75,
        114,
        1,
        197,
        193,
        125,
        71,
        2,
        13,
        67,
        198,
        124,
        33,
        199,
        63,
        94,
        114,
        10,
        134,
        198,
        90,
        160,
        114,
        140,
        140,
        199,
        96,
        24,
        0,
        37,
        196,
        137,
        26,
        192,
        98,
        136,
        3
      ],
      "weeklyWasted": [
        198,
        90,
        160,
        114,
        140,
        140,
        199,
        96,
        24,
        0,
        37,
        196,
        137,
        26,
        192,
        98,
        136,
        3,
        55,
        22,
        99,
        2,
        98,
        67,
        1,
        50,
        74,
        26,
        17,
        22,
        52,
        99,
        0,
        12,
        15,
        71,
        61,
        42,
        99,
        0,
        9,
        0,
        18,
        9,
        47,
        1,
        99,
        4,
        4,
        51,
        23,
        77,
        50,
        41,
        48,
        64,
        0,
        99,
        29,
        51,
        90,
        68,
        76,
        42,
        96,
        64,
        50,
        99,
        99,
        19
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        24,
        126,
        160,
        102,
        156,
        52,
        133,
        136,
        2,
        59,
        199,
        148,
        198,
        70,
        93,
        54,
        150,
        86,
        171,
        131,
        153,
        101,
        131,
        118,
        3,
        175,
        141,
        1,
        147,
        105,
        114,
        0,
        187,
        51,
        4,
        2,
        198,
        113,
        182,
        132,
        146,
        32,
        84,
        11,
        1,
        123,
        107,
        101,
        172,
        2,
        9,
        93
      ],
      "weeklyWasted": [
        4,
        2,
        198,
        113,
        182,
        132,
        146,
        32,
        84,
        11,
        1,
        123,
        107,
        101,
        172,
        2,
        9,
        93,
        81,
        1,
        99,
        36,
        80,
        28,
        46,
        29,
        99,
        16,
        78,
        99,
        1,
        73,
        97,
        12,
        47,
        99,
        58,
        0,
        93,
        0,
        10,
        0,
        57,
        66,
        89,
        75,
        2,
        11,
        44,
        23,
        72,
        24,
        67,
        67,
        99,
        63,
        98,
        54,
        5,
        0,
        26,
        50,
        32,
        67,
        50,
        36,
        65,
        43,
        52,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        107,
        2,
        0,
        195,
        81,
        197,
        76,
        34,
        2,
        72,
        118,
        197,
        198,
        126,
        189,
        19,
        192,
        167,
        110,
        113,
        80,
        45,
        2,
        118,
        115,
        13,
        184,
        1,
        199,
        109,
        95,
        0,
        1,
        88,
        107,
        188,
        198,
        48,
        137,
        55,
        63,
        92,
        57,
        0,
        29,
        175,
        46,
        38,
        198,
        59,
        40,
        3
      ],
      "weeklyWasted": [
        107,
        188,
        198,
        48,
        137,
        55,
        63,
        92,
        57,
        0,
        29,
        175,
        46,
        38,
        198,
        59,
        40,
        3,
        83,
        11,
        0,
        2,
        98,
        49,
        23,
        26,
        47,
        76,
        78,
        32,
        1,
        99,
        36,
        1,
        0,
        99,
        24,
        57,
        41,
        42,
        42,
        68,
        92,
        1,
        65,
        1,
        6,
        57,
        22,
        28,
        2,
        98,
        27,
        0,
        78,
        91,
        72,
        63,
        22,
        43,
        83,
        99,
        85,
        17,
        99,
        98,
        99,
        74,
        46,
        15
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        137,
        76,
        72,
        45,
        16,
        139,
        100,
        19,
        2,
        130,
        0,
        2,
        60,
        125,
        199,
        134,
        192,
        165,
        56,
        165,
        135,
        96,
        79,
        66,
        8,
        78,
        187,
        1,
        127,
        198,
        95,
        71,
        1,
        4,
        4,
        0,
        145,
        79,
        199,
        0,
        0,
        0,
        75,
        0,
        68,
        146,
        139,
        56,
        141,
        2,
        2,
        195
      ],
      "weeklyWasted": [
        4,
        0,
        145,
        79,
        199,
        0,
        0,
        0,
        75,
        0,
        68,
        146,
        139,
        56,
        141,
        2,
        2,
        195,
        41,
        1,
        27,
        68,
        98,
        1,
        60,
        0,
        68,
        54,
        34,
        57,
        5,
        61,
        87,
        94,
        24,
        87,
        33,
        62,
        2,
        66,
        62,
        0,
        70,
        99,
        64,
        71,
        57,
        84,
        2,
        62,
        35,
        98,
        74,
        97,
        64,
        49,
        57,
        99,
        0,
        25,
        65,
        99,
        51,
        26,
        99,
        92,
        70,
        1,
        75,
        13
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        199,
        67,
        0,
        45,
        77,
        83,
        12,
        15,
        138,
        1,
        0,
        45,
        49,
        64,
        187,
        109,
        192,
        192,
        3,
        18,
        43,
        22,
        124,
        47,
        127,
        128,
        141,
        66,
        138,
        198,
        92,
        0,
        1,
        73,
        154,
        37,
        140,
        194,
        35,
        183,
        38,
        28,
        65,
        7,
        50,
        4,
        82,
        34,
        197,
        92,
        4,
        3
      ],
      "weeklyWasted": [
        154,
        37,
        140,
        194,
        35,
        183,
        38,
        28,
        65,
        7,
        50,
        4,
        82,
        34,
        197,
        92,
        4,
        3,
        83,
        37,
        20,
        11,
        98,
        88,
        8,
        9,
        24,
        88,
        85,
        64,
        72,
        81,
        50,
        18,
        11,
        72,
        60,
        47,
        3,
        0,
        30,
        0,
        0,
        62,
        63,
        21,
        92,
        27,
        21,
        24,
        50,
        98,
        51,
        34,
        54,
        9,
        99,
        98,
        21,
        12,
        93,
        99,
        72,
        43,
        99,
        98,
        0,
        1,
        27,
        1
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        45,
        33,
        31,
        5,
        146,
        51,
        7,
        3,
        2,
        1,
        80,
        88,
        198,
        32,
        189,
        163,
        114,
        199,
        132,
        4,
        156,
        4,
        69,
        17,
        108,
        114,
        194,
        87,
        139,
        122,
        118,
        113,
        42,
        4,
        158,
        134,
        44,
        78,
        199,
        72,
        49,
        17,
        0,
        32,
        22,
        88,
        105,
        116,
        198,
        51,
        2,
        3
      ],
      "weeklyWasted": [
        158,
        134,
        44,
        78,
        199,
        72,
        49,
        17,
        0,
        32,
        22,
        88,
        105,
        116,
        198,
        51,
        2,
        3,
        97,
        17,
        61,
        2,
        67,
        1,
        87,
        0,
        0,
        72,
        10,
        14,
        25,
        3,
        44,
        33,
        21,
        38,
        1,
        83,
        58,
        76,
        12,
        38,
        42,
        88,
        31,
        79,
        48,
        10,
        67,
        97,
        99,
        98,
        25,
        16,
        70,
        98,
        64,
        1,
        8,
        0,
        40,
        74,
        84,
        99,
        81,
        98,
        21,
        19,
        62,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        8,
        69,
        140,
        32,
        72,
        95,
        63,
        197,
        52,
        1,
        151,
        161,
        163,
        199,
        199,
        86,
        37,
        110,
        149,
        98,
        197,
        78,
        124,
        29,
        3,
        171,
        197,
        77,
        96,
        139,
        199,
        128,
        50,
        4,
        60,
        64,
        198,
        122,
        114,
        14,
        0,
        0,
        0,
        53,
        42,
        196,
        8,
        81,
        191,
        115,
        178,
        3
      ],
      "weeklyWasted": [
        60,
        64,
        198,
        122,
        114,
        14,
        0,
        0,
        0,
        53,
        42,
        196,
        8,
        81,
        191,
        115,
        178,
        3,
        52,
        3,
        47,
        2,
        98,
        1,
        38,
        45,
        36,
        2,
        6,
        1,
        26,
        3,
        97,
        31,
        0,
        1,
        19,
        99,
        95,
        0,
        27,
        6,
        5,
        75,
        68,
        79,
        65,
        30,
        2,
        97,
        60,
        68,
        14,
        0,
        84,
        77,
        25,
        1,
        55,
        96,
        85,
        86,
        75,
        99,
        99,
        72,
        87,
        99,
        67,
        48
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        190,
        2,
        35,
        195,
        162,
        106,
        3,
        3,
        110,
        1,
        56,
        2,
        159,
        117,
        151,
        7,
        192,
        52,
        171,
        60,
        1,
        58,
        126,
        147,
        3,
        118,
        144,
        1,
        117,
        37,
        0,
        0,
        38,
        199,
        83,
        42,
        42,
        108,
        199,
        52,
        20,
        0,
        84,
        144,
        1,
        74,
        46,
        152,
        42,
        112,
        36,
        106
      ],
      "weeklyWasted": [
        83,
        42,
        42,
        108,
        199,
        52,
        20,
        0,
        84,
        144,
        1,
        74,
        46,
        152,
        42,
        112,
        36,
        106,
        16,
        1,
        0,
        62,
        65,
        76,
        77,
        0,
        15,
        97,
        99,
        97,
        56,
        3,
        8,
        68,
        27,
        80,
        24,
        28,
        2,
        45,
        5,
        72,
        27,
        97,
        58,
        78,
        64,
        90,
        2,
        97,
        1,
        92,
        61,
        4,
        28,
        90,
        95,
        99,
        12,
        67,
        99,
        54,
        29,
        72,
        33,
        98,
        30,
        1,
        87,
        1
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        152,
        121,
        181,
        107,
        0,
        28,
        20,
        149,
        2,
        49,
        199,
        106,
        198,
        47,
        125,
        3,
        192,
        118,
        3,
        41,
        1,
        4,
        4,
        118,
        91,
        97,
        180,
        1,
        142,
        0,
        29,
        73,
        132,
        120,
        7,
        13,
        88,
        80,
        10,
        73,
        165,
        199,
        0,
        100,
        5,
        196,
        119,
        7,
        198,
        97,
        136,
        156
      ],
      "weeklyWasted": [
        7,
        13,
        88,
        80,
        10,
        73,
        165,
        199,
        0,
        100,
        5,
        196,
        119,
        7,
        198,
        97,
        136,
        156,
        26,
        40,
        48,
        2,
        93,
        1,
        89,
        60,
        99,
        47,
        53,
        66,
        66,
        99,
        64,
        1,
        87,
        99,
        1,
        82,
        53,
        0,
        90,
        64,
        43,
        2,
        98,
        1,
        80,
        18,
        2,
        83,
        44,
        67,
        99,
        69,
        86,
        74,
        38,
        66,
        6,
        88,
        75,
        58,
        67,
        0,
        59,
        98,
        37,
        98,
        49,
        16
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        149,
        99,
        0,
        46,
        28,
        37,
        190,
        106,
        112,
        1,
        140,
        157,
        52,
        0,
        97,
        66,
        0,
        153,
        60,
        197,
        183,
        4,
        53,
        59,
        67,
        37,
        85,
        29,
        150,
        97,
        166,
        19,
        139,
        199,
        27,
        0,
        111,
        96,
        10,
        199,
        97,
        78,
        137,
        46,
        25,
        126,
        0,
        0,
        198,
        171,
        119,
        5
      ],
      "weeklyWasted": [
        27,
        0,
        111,
        96,
        10,
        199,
        97,
        78,
        137,
        46,
        25,
        126,
        0,
        0,
        198,
        171,
        119,
        5,
        99,
        62,
        99,
        6,
        94,
        57,
        48,
        65,
        67,
        77,
        10,
        60,
        62,
        99,
        64,
        20,
        48,
        10,
        31,
        81,
        37,
        28,
        0,
        78,
        62,
        36,
        82,
        58,
        80,
        72,
        2,
        81,
        6,
        98,
        23,
        0,
        21,
        97,
        43,
        57,
        34,
        1,
        59,
        76,
        21,
        14,
        99,
        85,
        7,
        89,
        76,
        73
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        106,
        115,
        41,
        194,
        43,
        1,
        147,
        197,
        2,
        1,
        130,
        2,
        137,
        69,
        38,
        189,
        137,
        146,
        123,
        2,
        164,
        31,
        89,
        101,
        3,
        117,
        66,
        1,
        113,
        198,
        71,
        89,
        179,
        166,
        179,
        0,
        134,
        166,
        199,
        76,
        42,
        30,
        63,
        114,
        39,
        138,
        0,
        42,
        198,
        144,
        191,
        3
      ],
      "weeklyWasted": [
        179,
        0,
        134,
        166,
        199,
        76,
        42,
        30,
        63,
        114,
        39,
        138,
        0,
        42,
        198,
        144,
        191,
        3,
        57,
        10,
        99,
        19,
        98,
        42,
        98,
        30,
        99,
        21,
        77,
        52,
        39,
        69,
        84,
        47,
        34,
        69,
        91,
        0,
        81,
        45,
        22,
        19,
        0,
        72,
        0,
        19,
        67,
        26,
        33,
        97,
        76,
        38,
        97,
        19,
        75,
        15,
        0,
        50,
        5,
        0,
        99,
        99,
        80,
        41,
        98,
        13,
        75,
        99,
        78,
        60
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        7,
        54,
        198,
        5,
        144,
        99,
        78,
        3,
        137,
        1,
        199,
        2,
        154,
        25,
        142,
        198,
        173,
        73,
        70,
        2,
        180,
        88,
        96,
        128,
        123,
        198,
        63,
        197,
        164,
        115,
        56,
        52,
        10,
        97,
        98,
        136,
        121,
        121,
        199,
        126,
        48,
        92,
        0,
        171,
        39,
        27,
        158,
        196,
        198,
        73,
        151,
        104
      ],
      "weeklyWasted": [
        98,
        136,
        121,
        121,
        199,
        126,
        48,
        92,
        0,
        171,
        39,
        27,
        158,
        196,
        198,
        73,
        151,
        104,
        30,
        16,
        64,
        32,
        62,
        47,
        99,
        78,
        64,
        16,
        97,
        26,
        46,
        57,
        71,
        88,
        0,
        81,
        9,
        67,
        31,
        99,
        39,
        77,
        27,
        99,
        81,
        27,
        2,
        21,
        2,
        18,
        25,
        78,
        99,
        23,
        18,
        33,
        95,
        3,
        28,
        0,
        74,
        99,
        58,
        38,
        99,
        98,
        99,
        99,
        41,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        76,
        142,
        143,
        174,
        100,
        135,
        77,
        10,
        180,
        1,
        106,
        2,
        2,
        0,
        102,
        198,
        192,
        1,
        68,
        190,
        197,
        160,
        27,
        103,
        3,
        163,
        197,
        49,
        77,
        80,
        54,
        142,
        30,
        4,
        102,
        83,
        81,
        113,
        196,
        131,
        199,
        16,
        138,
        41,
        9,
        196,
        87,
        198,
        198,
        151,
        64,
        74
      ],
      "weeklyWasted": [
        102,
        83,
        81,
        113,
        196,
        131,
        199,
        16,
        138,
        41,
        9,
        196,
        87,
        198,
        198,
        151,
        64,
        74,
        75,
        29,
        33,
        2,
        98,
        65,
        10,
        7,
        98,
        86,
        2,
        99,
        65,
        87,
        59,
        77,
        23,
        79,
        99,
        50,
        83,
        9,
        14,
        6,
        18,
        99,
        49,
        97,
        30,
        75,
        28,
        36,
        44,
        1,
        76,
        55,
        42,
        0,
        0,
        99,
        0,
        56,
        86,
        99,
        30,
        28,
        75,
        44,
        96,
        63,
        81,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        165,
        103,
        126,
        148,
        129,
        166,
        194,
        3,
        62,
        99,
        199,
        115,
        55,
        183,
        199,
        63,
        192,
        7,
        64,
        125,
        45,
        8,
        6,
        24,
        74,
        46,
        139,
        36,
        32,
        95,
        30,
        76,
        37,
        139,
        55,
        191,
        44,
        199,
        104,
        97,
        93,
        119,
        132,
        24,
        40,
        138,
        169,
        141,
        198,
        79,
        29,
        11
      ],
      "weeklyWasted": [
        55,
        191,
        44,
        199,
        104,
        97,
        93,
        119,
        132,
        24,
        40,
        138,
        169,
        141,
        198,
        79,
        29,
        11,
        14,
        38,
        39,
        8,
        82,
        22,
        36,
        0,
        99,
        27,
        60,
        22,
        34,
        75,
        82,
        46,
        56,
        69,
        21,
        82,
        13,
        34,
        91,
        66,
        15,
        96,
        98,
        59,
        19,
        53,
        2,
        97,
        1,
        98,
        77,
        65,
        60,
        35,
        26,
        99,
        62,
        8,
        88,
        64,
        75,
        30,
        0,
        67,
        99,
        59,
        99,
        6
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        199,
        84,
        198,
        5,
        153,
        146,
        130,
        3,
        181,
        160,
        146,
        52,
        162,
        170,
        118,
        3,
        180,
        161,
        74,
        70,
        176,
        4,
        61,
        178,
        14,
        59,
        139,
        59,
        18,
        116,
        73,
        68,
        36,
        171,
        187,
        63,
        0,
        199,
        10,
        111,
        58,
        63,
        153,
        0,
        1,
        127,
        124,
        185,
        101,
        21,
        12,
        124
      ],
      "weeklyWasted": [
        187,
        63,
        0,
        199,
        10,
        111,
        58,
        63,
        153,
        0,
        1,
        127,
        124,
        185,
        101,
        21,
        12,
        124,
        63,
        30,
        99,
        48,
        80,
        39,
        33,
        0,
        99,
        0,
        63,
        99,
        18,
        3,
        39,
        98,
        75,
        68,
        81,
        23,
        68,
        27,
        15,
        33,
        59,
        99,
        0,
        59,
        42,
        54,
        2,
        81,
        68,
        79,
        99,
        45,
        45,
        65,
        0,
        37,
        7,
        53,
        0,
        44,
        89,
        29,
        57,
        91,
        99,
        1,
        45,
        25
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        143,
        2,
        198,
        40,
        28,
        43,
        110,
        197,
        2,
        1,
        187,
        75,
        192,
        74,
        199,
        68,
        192,
        193,
        197,
        2,
        197,
        128,
        76,
        176,
        3,
        198,
        193,
        1,
        173,
        66,
        119,
        0,
        97,
        132,
        43,
        199,
        69,
        50,
        72,
        0,
        48,
        127,
        0,
        37,
        36,
        196,
        61,
        115,
        65,
        30,
        187,
        3
      ],
      "weeklyWasted": [
        43,
        199,
        69,
        50,
        72,
        0,
        48,
        127,
        0,
        37,
        36,
        196,
        61,
        115,
        65,
        30,
        187,
        3,
        54,
        22,
        7,
        12,
        75,
        27,
        99,
        52,
        90,
        54,
        56,
        71,
        1,
        9,
        50,
        1,
        0,
        23,
        26,
        71,
        49,
        30,
        28,
        2,
        0,
        41,
        98,
        83,
        99,
        68,
        2,
        11,
        33,
        98,
        99,
        26,
        34,
        37,
        99,
        85,
        0,
        75,
        99,
        87,
        99,
        11,
        99,
        50,
        22,
        71,
        43,
        21
      ]
    },
    {
      "id": "965102D9",
      "weeklyUsage": [
        86,
        51,
        10,
        104,
        0,
        103,
        63,
        3,
        192,
        129,
        19,
        41,
        198,
        26,
        26,
        69,
        48,
        199,
        110,
        60,
        13,
        65,
        2,
        55,
        35,
        67,
        180,
        34,
        190,
        198,
        199,
        0,
        19,
        78,
        31,
        6,
        103,
        35,
        10,
        167,
        0,
        9,
        134,
        0,
        139,
        59,
        118,
        0,
        169,
        103,
        146,
        16
      ],
      "weeklyWasted": [
        31,
        6,
        103,
        35,
        10,
        167,
        0,
        9,
        134,
        0,
        139,
        59,
        118,
        0,
        169,
        103,
        146,
        16,
        79,
        51,
        15,
        24,
        98,
        26,
        45,
        66,
        34,
        93,
        66,
        62,
        47,
        99,
        57,
        28,
        49,
        99,
        1,
        56,
        90,
        0,
        0,
        5,
        16,
        1,
        98,
        1,
        2,
        1,
        6,
        97,
        1,
        98,
        0,
        39,
        41,
        5,
        30,
        58,
        5,
        57,
        86,
        59,
        69,
        53,
        36,
        70,
        99,
        61,
        28,
        33
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        185,
        139,
        198,
        171,
        17,
        180,
        133,
        21,
        98,
        101,
        0,
        140,
        115,
        0,
        171,
        179,
        150,
        131,
        142,
        117,
        125,
        88,
        156,
        121,
        107,
        117,
        153,
        1,
        91,
        104,
        99,
        94,
        197,
        162,
        78,
        33,
        198,
        39,
        127,
        96,
        119,
        100,
        0,
        56,
        126,
        118,
        0,
        69,
        175,
        35,
        34,
        77
      ],
      "weeklyWasted": [
        78,
        33,
        198,
        39,
        127,
        96,
        119,
        100,
        0,
        56,
        126,
        118,
        0,
        69,
        175,
        35,
        34,
        77,
        99,
        17,
        99,
        43,
        98,
        29,
        52,
        13,
        58,
        0,
        42,
        99,
        51,
        46,
        38,
        55,
        64,
        27,
        1,
        0,
        2,
        0,
        54,
        0,
        86,
        99,
        98,
        66,
        48,
        44,
        2,
        49,
        1,
        90,
        99,
        76,
        25,
        64,
        99,
        59,
        36,
        32,
        99,
        1,
        33,
        84,
        99,
        48,
        99,
        12,
        99,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        33,
        2,
        0,
        37,
        0,
        171,
        59,
        101,
        198,
        81,
        199,
        66,
        163,
        198,
        123,
        124,
        81,
        93,
        100,
        2,
        197,
        15,
        75,
        182,
        76,
        147,
        136,
        1,
        61,
        62,
        68,
        126,
        197,
        88,
        171,
        2,
        104,
        199,
        89,
        99,
        0,
        199,
        38,
        3,
        31,
        157,
        27,
        41,
        156,
        102,
        78,
        196
      ],
      "weeklyWasted": [
        171,
        2,
        104,
        199,
        89,
        99,
        0,
        199,
        38,
        3,
        31,
        157,
        27,
        41,
        156,
        102,
        78,
        196,
        30,
        44,
        41,
        22,
        98,
        98,
        18,
        21,
        91,
        8,
        12,
        78,
        72,
        99,
        0,
        33,
        36,
        28,
        68,
        42,
        83,
        52,
        97,
        0,
        33,
        99,
        64,
        2,
        99,
        35,
        2,
        79,
        2,
        82,
        66,
        68,
        52,
        91,
        11,
        69,
        17,
        0,
        48,
        31,
        75,
        96,
        61,
        73,
        75,
        53,
        8,
        43
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        162,
        147,
        0,
        131,
        12,
        196,
        60,
        122,
        178,
        1,
        199,
        2,
        139,
        0,
        34,
        3,
        108,
        116,
        33,
        142,
        197,
        63,
        197,
        195,
        3,
        158,
        122,
        58,
        70,
        131,
        21,
        174,
        1,
        133,
        60,
        38,
        176,
        191,
        10,
        199,
        70,
        58,
        166,
        12,
        85,
        183,
        25,
        63,
        198,
        82,
        51,
        3
      ],
      "weeklyWasted": [
        60,
        38,
        176,
        191,
        10,
        199,
        70,
        58,
        166,
        12,
        85,
        183,
        25,
        63,
        198,
        82,
        51,
        3,
        82,
        31,
        70,
        38,
        88,
        1,
        38,
        0,
        64,
        43,
        67,
        13,
        1,
        93,
        77,
        34,
        41,
        99,
        29,
        73,
        98,
        13,
        8,
        0,
        0,
        11,
        78,
        74,
        97,
        45,
        2,
        97,
        79,
        98,
        32,
        0,
        99,
        40,
        0,
        91,
        0,
        11,
        75,
        99,
        99,
        0,
        0,
        98,
        0,
        21,
        99,
        1
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyUsage": [
        147,
        2,
        142,
        52,
        46,
        197,
        163,
        3,
        23,
        1,
        94,
        2,
        14,
        72,
        82,
        137,
        192,
        47,
        27,
        137,
        91,
        14,
        164,
        1,
        3,
        136,
        107,
        127,
        197,
        72,
        199,
        0,
        1,
        4,
        198,
        83,
        0,
        5,
        86,
        0,
        2,
        33,
        0,
        0,
        58,
        186,
        60,
        37,
        140,
        123,
        158,
        5
      ],
      "weeklyWasted": [
        198,
        83,
        0,
        5,
        86,
        0,
        2,
        33,
        0,
        0,
        58,
        186,
        60,
        37,
        140,
        123,
        158,
        5,
        44,
        60,
        0,
        63,
        34,
        98,
        79,
        0,
        99,
        25,
        61,
        53,
        99,
        99,
        40,
        48,
        9,
        99,
        44,
        0,
        60,
        65,
        14,
        12,
        18,
        38,
        64,
        4,
        99,
        53,
        10,
        45,
        1,
        98,
        0,
        32,
        58,
        77,
        94,
        40,
        46,
        23,
        99,
        99,
        61,
        66,
        42,
        31,
        86,
        97,
        99,
        1
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        155,
        101,
        116,
        5,
        157,
        49,
        113,
        148,
        43,
        1,
        199,
        56,
        198,
        137,
        126,
        3,
        192,
        51,
        197,
        135,
        125,
        195,
        130,
        65,
        163,
        140,
        54,
        1,
        47,
        97,
        134,
        100,
        27,
        178,
        103,
        199,
        134,
        109,
        117,
        0,
        7,
        108,
        53,
        95,
        114,
        4,
        0,
        198,
        198,
        121,
        154,
        43
      ],
      "weeklyWasted": [
        103,
        199,
        134,
        109,
        117,
        0,
        7,
        108,
        53,
        95,
        114,
        4,
        0,
        198,
        198,
        121,
        154,
        43,
        66,
        1,
        0,
        2,
        63,
        98,
        74,
        0,
        99,
        0,
        40,
        65,
        52,
        49,
        11,
        50,
        0,
        50,
        70,
        9,
        2,
        0,
        15,
        25,
        32,
        99,
        98,
        82,
        99,
        1,
        8,
        39,
        30,
        98,
        62,
        96,
        69,
        38,
        99,
        63,
        99,
        0,
        90,
        99,
        99,
        64,
        66,
        98,
        34,
        50,
        7,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        124,
        135,
        0,
        5,
        116,
        197,
        194,
        64,
        88,
        26,
        9,
        192,
        198,
        0,
        70,
        3,
        192,
        152,
        197,
        85,
        1,
        8,
        197,
        1,
        3,
        54,
        197,
        30,
        125,
        103,
        199,
        24,
        33,
        60,
        145,
        43,
        135,
        82,
        16,
        51,
        0,
        23,
        41,
        0,
        1,
        91,
        43,
        0,
        158,
        2,
        17,
        3
      ],
      "weeklyWasted": [
        145,
        43,
        135,
        82,
        16,
        51,
        0,
        23,
        41,
        0,
        1,
        91,
        43,
        0,
        158,
        2,
        17,
        3,
        1,
        22,
        94,
        3,
        95,
        1,
        7,
        4,
        56,
        99,
        40,
        1,
        15,
        56,
        82,
        98,
        63,
        1,
        12,
        4,
        58,
        0,
        80,
        11,
        68,
        22,
        65,
        67,
        99,
        8,
        91,
        97,
        40,
        87,
        67,
        7,
        30,
        99,
        98,
        76,
        35,
        21,
        93,
        84,
        70,
        39,
        82,
        0,
        99,
        99,
        71,
        19
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        122,
        106,
        159,
        5,
        174,
        97,
        178,
        135,
        199,
        1,
        199,
        2,
        181,
        0,
        142,
        127,
        166,
        195,
        185,
        49,
        126,
        198,
        70,
        76,
        3,
        6,
        54,
        123,
        44,
        198,
        79,
        117,
        197,
        199,
        178,
        44,
        115,
        5,
        84,
        104,
        11,
        69,
        88,
        103,
        17,
        74,
        195,
        164,
        155,
        6,
        7,
        3
      ],
      "weeklyWasted": [
        178,
        44,
        115,
        5,
        84,
        104,
        11,
        69,
        88,
        103,
        17,
        74,
        195,
        164,
        155,
        6,
        7,
        3,
        22,
        34,
        99,
        2,
        98,
        7,
        99,
        99,
        99,
        41,
        99,
        25,
        68,
        99,
        74,
        17,
        51,
        79,
        99,
        58,
        20,
        59,
        8,
        0,
        99,
        99,
        59,
        36,
        19,
        25,
        2,
        59,
        31,
        98,
        99,
        50,
        52,
        99,
        28,
        32,
        75,
        0,
        85,
        36,
        99,
        16,
        99,
        48,
        99,
        48,
        37,
        39
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        36,
        59,
        198,
        81,
        198,
        108,
        194,
        88,
        66,
        1,
        76,
        186,
        6,
        50,
        100,
        42,
        180,
        1,
        85,
        2,
        129,
        104,
        197,
        8,
        3,
        22,
        111,
        83,
        69,
        125,
        130,
        91,
        97,
        125,
        198,
        177,
        72,
        22,
        54,
        53,
        10,
        42,
        87,
        78,
        96,
        164,
        66,
        24,
        120,
        82,
        161,
        3
      ],
      "weeklyWasted": [
        198,
        177,
        72,
        22,
        54,
        53,
        10,
        42,
        87,
        78,
        96,
        164,
        66,
        24,
        120,
        82,
        161,
        3,
        46,
        37,
        93,
        26,
        31,
        37,
        92,
        22,
        58,
        0,
        85,
        96,
        2,
        68,
        89,
        7,
        0,
        99,
        2,
        21,
        2,
        40,
        26,
        44,
        35,
        72,
        52,
        54,
        42,
        37,
        49,
        29,
        1,
        37,
        43,
        93,
        43,
        99,
        83,
        73,
        3,
        0,
        56,
        96,
        34,
        80,
        99,
        18,
        99,
        96,
        57,
        63
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        199,
        2,
        40,
        151,
        83,
        117,
        184,
        161,
        16,
        1,
        60,
        44,
        181,
        160,
        130,
        92,
        192,
        199,
        63,
        83,
        110,
        30,
        2,
        148,
        105,
        135,
        75,
        1,
        163,
        126,
        91,
        152,
        197,
        182,
        185,
        173,
        106,
        101,
        176,
        199,
        115,
        199,
        195,
        154,
        47,
        122,
        57,
        0,
        198,
        44,
        119,
        90
      ],
      "weeklyWasted": [
        185,
        173,
        106,
        101,
        176,
        199,
        115,
        199,
        195,
        154,
        47,
        122,
        57,
        0,
        198,
        44,
        119,
        90,
        45,
        90,
        71,
        33,
        37,
        43,
        20,
        29,
        87,
        56,
        57,
        65,
        64,
        86,
        40,
        43,
        40,
        13,
        1,
        72,
        99,
        25,
        22,
        29,
        87,
        69,
        19,
        97,
        74,
        54,
        2,
        97,
        36,
        98,
        72,
        1,
        61,
        16,
        21,
        41,
        32,
        0,
        77,
        78,
        99,
        27,
        57,
        52,
        16,
        94,
        19,
        65
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        190,
        63,
        158,
        13,
        61,
        1,
        86,
        3,
        199,
        13,
        0,
        48,
        121,
        0,
        101,
        176,
        91,
        173,
        197,
        10,
        86,
        198,
        140,
        93,
        96,
        85,
        148,
        197,
        159,
        47,
        12,
        63,
        89,
        199,
        105,
        45,
        63,
        5,
        65,
        9,
        199,
        0,
        0,
        167,
        1,
        196,
        183,
        77,
        197,
        109,
        151,
        3
      ],
      "weeklyWasted": [
        105,
        45,
        63,
        5,
        65,
        9,
        199,
        0,
        0,
        167,
        1,
        196,
        183,
        77,
        197,
        109,
        151,
        3,
        1,
        1,
        0,
        85,
        96,
        83,
        79,
        29,
        88,
        47,
        99,
        94,
        20,
        95,
        37,
        1,
        98,
        81,
        1,
        10,
        20,
        41,
        0,
        72,
        45,
        57,
        38,
        1,
        54,
        3,
        6,
        45,
        51,
        87,
        83,
        0,
        99,
        50,
        53,
        64,
        70,
        30,
        85,
        50,
        93,
        21,
        60,
        98,
        45,
        77,
        23,
        2
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        61,
        85,
        191,
        105,
        127,
        197,
        147,
        94,
        114,
        38,
        0,
        2,
        146,
        199,
        156,
        93,
        192,
        199,
        120,
        134,
        197,
        40,
        196,
        156,
        3,
        189,
        142,
        1,
        199,
        33,
        76,
        0,
        68,
        199,
        146,
        181,
        128,
        166,
        160,
        0,
        0,
        61,
        150,
        177,
        60,
        133,
        79,
        81,
        175,
        132,
        77,
        134
      ],
      "weeklyWasted": [
        146,
        181,
        128,
        166,
        160,
        0,
        0,
        61,
        150,
        177,
        60,
        133,
        79,
        81,
        175,
        132,
        77,
        134,
        24,
        1,
        37,
        89,
        90,
        27,
        96,
        0,
        72,
        0,
        99,
        90,
        53,
        85,
        74,
        1,
        46,
        99,
        8,
        22,
        99,
        86,
        36,
        0,
        99,
        99,
        48,
        65,
        62,
        58,
        2,
        97,
        27,
        98,
        88,
        0,
        1,
        74,
        58,
        77,
        38,
        38,
        75,
        43,
        49,
        75,
        99,
        57,
        3,
        41,
        99,
        23
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        29,
        88,
        115,
        52,
        23,
        84,
        17,
        3,
        2,
        1,
        0,
        71,
        198,
        199,
        199,
        134,
        192,
        199,
        3,
        181,
        97,
        198,
        113,
        73,
        197,
        150,
        197,
        93,
        78,
        116,
        124,
        120,
        111,
        36,
        78,
        86,
        120,
        63,
        58,
        199,
        82,
        0,
        31,
        136,
        98,
        196,
        60,
        77,
        198,
        100,
        115,
        95
      ],
      "weeklyWasted": [
        78,
        86,
        120,
        63,
        58,
        199,
        82,
        0,
        31,
        136,
        98,
        196,
        60,
        77,
        198,
        100,
        115,
        95,
        25,
        1,
        96,
        10,
        98,
        75,
        4,
        18,
        99,
        16,
        99,
        75,
        13,
        99,
        48,
        50,
        63,
        66,
        99,
        99,
        62,
        29,
        59,
        1,
        66,
        99,
        97,
        97,
        8,
        43,
        2,
        43,
        1,
        56,
        66,
        14,
        60,
        97,
        43,
        99,
        0,
        0,
        88,
        77,
        99,
        22,
        78,
        10,
        99,
        99,
        37,
        1
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        138,
        57,
        0,
        175,
        196,
        90,
        189,
        115,
        178,
        1,
        199,
        52,
        195,
        0,
        1,
        76,
        138,
        97,
        92,
        2,
        197,
        164,
        2,
        29,
        3,
        99,
        128,
        1,
        137,
        0,
        70,
        0,
        1,
        168,
        165,
        25,
        37,
        105,
        10,
        0,
        199,
        45,
        56,
        0,
        1,
        196,
        10,
        69,
        1,
        167,
        135,
        3
      ],
      "weeklyWasted": [
        165,
        25,
        37,
        105,
        10,
        0,
        199,
        45,
        56,
        0,
        1,
        196,
        10,
        69,
        1,
        167,
        135,
        3,
        42,
        20,
        7,
        12,
        98,
        47,
        78,
        0,
        99,
        99,
        75,
        88,
        57,
        55,
        0,
        98,
        35,
        81,
        41,
        63,
        69,
        8,
        0,
        92,
        10,
        99,
        19,
        17,
        46,
        3,
        2,
        97,
        29,
        98,
        37,
        0,
        88,
        6,
        47,
        99,
        88,
        58,
        90,
        99,
        84,
        62,
        58,
        85,
        30,
        99,
        39,
        49
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        125,
        110,
        27,
        5,
        0,
        1,
        62,
        92,
        3,
        1,
        178,
        32,
        145,
        199,
        10,
        106,
        167,
        152,
        47,
        43,
        142,
        29,
        2,
        163,
        139,
        55,
        192,
        27,
        157,
        70,
        103,
        136,
        77,
        4,
        25,
        110,
        84,
        199,
        153,
        186,
        0,
        7,
        0,
        92,
        1,
        196,
        97,
        67,
        198,
        141,
        160,
        51
      ],
      "weeklyWasted": [
        25,
        110,
        84,
        199,
        153,
        186,
        0,
        7,
        0,
        92,
        1,
        196,
        97,
        67,
        198,
        141,
        160,
        51,
        43,
        1,
        21,
        2,
        98,
        1,
        52,
        31,
        60,
        34,
        46,
        48,
        61,
        63,
        74,
        20,
        65,
        70,
        1,
        85,
        2,
        34,
        25,
        66,
        58,
        2,
        63,
        97,
        46,
        37,
        6,
        97,
        99,
        98,
        63,
        81,
        72,
        70,
        50,
        31,
        23,
        6,
        66,
        52,
        99,
        99,
        0,
        98,
        99,
        47,
        28,
        1
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        180,
        123,
        56,
        54,
        0,
        127,
        178,
        3,
        46,
        1,
        70,
        22,
        70,
        0,
        199,
        110,
        192,
        188,
        3,
        81,
        44,
        198,
        180,
        191,
        128,
        22,
        180,
        1,
        167,
        120,
        0,
        7,
        39,
        168,
        105,
        199,
        95,
        98,
        114,
        131,
        139,
        76,
        63,
        187,
        1,
        76,
        0,
        8,
        144,
        2,
        193,
        3
      ],
      "weeklyWasted": [
        105,
        199,
        95,
        98,
        114,
        131,
        139,
        76,
        63,
        187,
        1,
        76,
        0,
        8,
        144,
        2,
        193,
        3,
        35,
        60,
        0,
        4,
        98,
        80,
        72,
        99,
        33,
        40,
        99,
        76,
        61,
        99,
        97,
        40,
        44,
        65,
        19,
        99,
        50,
        32,
        0,
        2,
        72,
        35,
        69,
        97,
        84,
        39,
        6,
        51,
        62,
        98,
        9,
        10,
        68,
        93,
        52,
        39,
        0,
        49,
        74,
        85,
        99,
        0,
        37,
        50,
        75,
        91,
        91,
        71
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        122,
        37,
        98,
        43,
        109,
        1,
        90,
        131,
        107,
        198,
        92,
        99,
        66,
        176,
        125,
        123,
        158,
        50,
        195,
        197,
        197,
        119,
        168,
        150,
        17,
        80,
        187,
        1,
        5,
        76,
        166,
        61,
        86,
        189,
        172,
        53,
        24,
        15,
        180,
        193,
        47,
        45,
        76,
        94,
        64,
        178,
        0,
        91,
        135,
        127,
        98,
        19
      ],
      "weeklyWasted": [
        172,
        53,
        24,
        15,
        180,
        193,
        47,
        45,
        76,
        94,
        64,
        178,
        0,
        91,
        135,
        127,
        98,
        19,
        33,
        10,
        72,
        2,
        87,
        86,
        74,
        27,
        87,
        41,
        31,
        89,
        9,
        99,
        11,
        1,
        63,
        20,
        34,
        0,
        12,
        46,
        97,
        0,
        76,
        99,
        98,
        97,
        63,
        12,
        45,
        55,
        88,
        98,
        99,
        29,
        67,
        0,
        47,
        69,
        0,
        0,
        99,
        83,
        43,
        0,
        80,
        98,
        48,
        1,
        46,
        36
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        199,
        31,
        142,
        5,
        32,
        113,
        185,
        109,
        2,
        1,
        53,
        114,
        159,
        57,
        176,
        33,
        178,
        175,
        76,
        2,
        170,
        136,
        70,
        34,
        3,
        178,
        170,
        14,
        37,
        52,
        74,
        145,
        1,
        96,
        137,
        24,
        78,
        5,
        167,
        11,
        0,
        0,
        126,
        0,
        1,
        196,
        116,
        0,
        198,
        27,
        94,
        3
      ],
      "weeklyWasted": [
        137,
        24,
        78,
        5,
        167,
        11,
        0,
        0,
        126,
        0,
        1,
        196,
        116,
        0,
        198,
        27,
        94,
        3,
        5,
        42,
        35,
        2,
        58,
        42,
        13,
        0,
        34,
        50,
        56,
        23,
        72,
        3,
        54,
        53,
        28,
        84,
        37,
        60,
        99,
        0,
        11,
        63,
        99,
        47,
        96,
        70,
        60,
        42,
        2,
        30,
        35,
        90,
        81,
        62,
        99,
        40,
        40,
        46,
        99,
        51,
        43,
        99,
        63,
        25,
        99,
        98,
        73,
        76,
        12,
        2
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        199,
        32,
        135,
        97,
        57,
        164,
        148,
        83,
        42,
        1,
        141,
        159,
        93,
        0,
        199,
        96,
        192,
        183,
        82,
        2,
        85,
        54,
        161,
        74,
        3,
        152,
        161,
        185,
        152,
        22,
        0,
        28,
        1,
        101,
        130,
        123,
        118,
        70,
        130,
        19,
        125,
        9,
        0,
        0,
        19,
        196,
        124,
        83,
        1,
        69,
        107,
        109
      ],
      "weeklyWasted": [
        130,
        123,
        118,
        70,
        130,
        19,
        125,
        9,
        0,
        0,
        19,
        196,
        124,
        83,
        1,
        69,
        107,
        109,
        56,
        1,
        6,
        16,
        66,
        15,
        99,
        0,
        78,
        36,
        97,
        83,
        37,
        50,
        35,
        1,
        67,
        92,
        40,
        30,
        50,
        75,
        83,
        12,
        65,
        99,
        51,
        59,
        99,
        45,
        2,
        84,
        7,
        74,
        26,
        59,
        78,
        47,
        99,
        72,
        80,
        98,
        99,
        83,
        18,
        39,
        47,
        79,
        80,
        96,
        86,
        61
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        60,
        2,
        75,
        80,
        66,
        30,
        194,
        50,
        2,
        1,
        199,
        22,
        155,
        122,
        8,
        70,
        192,
        199,
        61,
        136,
        25,
        46,
        127,
        1,
        87,
        57,
        197,
        62,
        70,
        113,
        128,
        58,
        197,
        42,
        169,
        9,
        63,
        125,
        178,
        36,
        110,
        134,
        0,
        181,
        33,
        119,
        76,
        70,
        172,
        171,
        67,
        3
      ],
      "weeklyWasted": [
        169,
        9,
        63,
        125,
        178,
        36,
        110,
        134,
        0,
        181,
        33,
        119,
        76,
        70,
        172,
        171,
        67,
        3,
        1,
        9,
        2,
        27,
        65,
        77,
        99,
        0,
        72,
        99,
        99,
        1,
        1,
        60,
        86,
        7,
        98,
        1,
        71,
        71,
        74,
        51,
        27,
        0,
        78,
        93,
        8,
        76,
        58,
        1,
        99,
        7,
        56,
        85,
        57,
        0,
        81,
        99,
        93,
        76,
        68,
        0,
        86,
        78,
        44,
        53,
        43,
        94,
        45,
        89,
        40,
        17
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        197,
        11,
        123,
        50,
        37,
        101,
        110,
        13,
        104,
        79,
        0,
        35,
        198,
        199,
        199,
        3,
        192,
        189,
        143,
        12,
        180,
        4,
        92,
        51,
        3,
        1,
        174,
        48,
        128,
        87,
        94,
        158,
        36,
        199,
        103,
        87,
        119,
        72,
        129,
        0,
        0,
        5,
        0,
        169,
        16,
        187,
        112,
        194,
        184,
        159,
        115,
        11
      ],
      "weeklyWasted": [
        103,
        87,
        119,
        72,
        129,
        0,
        0,
        5,
        0,
        169,
        16,
        187,
        112,
        194,
        184,
        159,
        115,
        11,
        50,
        4,
        0,
        40,
        98,
        1,
        99,
        1,
        13,
        0,
        99,
        1,
        62,
        3,
        0,
        26,
        20,
        77,
        11,
        53,
        9,
        28,
        0,
        9,
        99,
        99,
        92,
        55,
        72,
        29,
        81,
        10,
        1,
        73,
        98,
        64,
        79,
        62,
        99,
        1,
        99,
        0,
        87,
        81,
        48,
        31,
        99,
        97,
        29,
        64,
        17,
        31
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        122,
        16,
        140,
        163,
        146,
        197,
        173,
        3,
        2,
        1,
        79,
        2,
        61,
        0,
        149,
        114,
        192,
        48,
        197,
        124,
        105,
        146,
        9,
        1,
        20,
        198,
        195,
        71,
        199,
        144,
        117,
        57,
        37,
        31,
        136,
        199,
        58,
        191,
        137,
        199,
        80,
        58,
        146,
        0,
        19,
        39,
        81,
        148,
        145,
        2,
        2,
        3
      ],
      "weeklyWasted": [
        136,
        199,
        58,
        191,
        137,
        199,
        80,
        58,
        146,
        0,
        19,
        39,
        81,
        148,
        145,
        2,
        2,
        3,
        30,
        25,
        0,
        43,
        59,
        72,
        99,
        17,
        49,
        62,
        99,
        56,
        72,
        95,
        46,
        35,
        56,
        48,
        10,
        99,
        72,
        89,
        93,
        1,
        27,
        44,
        98,
        64,
        55,
        63,
        42,
        62,
        25,
        98,
        0,
        0,
        31,
        99,
        0,
        98,
        84,
        26,
        95,
        85,
        97,
        89,
        99,
        98,
        31,
        91,
        99,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        129,
        16,
        72,
        5,
        108,
        195,
        56,
        167,
        88,
        47,
        116,
        28,
        133,
        123,
        199,
        119,
        192,
        199,
        3,
        2,
        146,
        15,
        158,
        51,
        27,
        93,
        154,
        62,
        162,
        92,
        87,
        0,
        43,
        115,
        4,
        199,
        151,
        121,
        142,
        22,
        0,
        120,
        0,
        55,
        1,
        54,
        91,
        13,
        126,
        99,
        137,
        66
      ],
      "weeklyWasted": [
        4,
        199,
        151,
        121,
        142,
        22,
        0,
        120,
        0,
        55,
        1,
        54,
        91,
        13,
        126,
        99,
        137,
        66,
        21,
        62,
        73,
        22,
        52,
        58,
        63,
        29,
        99,
        76,
        99,
        66,
        67,
        74,
        42,
        52,
        33,
        99,
        35,
        72,
        63,
        64,
        2,
        0,
        42,
        68,
        96,
        59,
        3,
        57,
        2,
        56,
        1,
        98,
        3,
        93,
        59,
        12,
        99,
        1,
        0,
        50,
        41,
        77,
        55,
        38,
        84,
        36,
        63,
        33,
        25,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        199,
        29,
        10,
        5,
        14,
        88,
        113,
        83,
        191,
        98,
        80,
        65,
        198,
        0,
        163,
        3,
        110,
        163,
        123,
        160,
        144,
        32,
        2,
        108,
        157,
        8,
        121,
        1,
        116,
        88,
        166,
        104,
        107,
        105,
        4,
        0,
        152,
        60,
        174,
        12,
        68,
        24,
        0,
        84,
        14,
        144,
        57,
        135,
        198,
        31,
        164,
        135
      ],
      "weeklyWasted": [
        4,
        0,
        152,
        60,
        174,
        12,
        68,
        24,
        0,
        84,
        14,
        144,
        57,
        135,
        198,
        31,
        164,
        135,
        99,
        1,
        53,
        3,
        65,
        1,
        28,
        0,
        31,
        45,
        65,
        1,
        62,
        72,
        19,
        27,
        31,
        64,
        1,
        94,
        66,
        25,
        0,
        38,
        12,
        89,
        4,
        83,
        41,
        92,
        2,
        67,
        71,
        69,
        58,
        9,
        68,
        72,
        62,
        99,
        99,
        43,
        89,
        79,
        99,
        5,
        8,
        38,
        43,
        91,
        99,
        39
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        182,
        67,
        6,
        24,
        123,
        79,
        194,
        96,
        62,
        1,
        144,
        90,
        194,
        96,
        189,
        3,
        121,
        199,
        147,
        2,
        197,
        91,
        121,
        125,
        3,
        198,
        102,
        48,
        157,
        70,
        0,
        109,
        62,
        199,
        60,
        0,
        15,
        119,
        81,
        59,
        110,
        63,
        45,
        0,
        1,
        42,
        195,
        76,
        158,
        107,
        40,
        54
      ],
      "weeklyWasted": [
        60,
        0,
        15,
        119,
        81,
        59,
        110,
        63,
        45,
        0,
        1,
        42,
        195,
        76,
        158,
        107,
        40,
        54,
        1,
        35,
        30,
        2,
        14,
        1,
        64,
        0,
        63,
        94,
        90,
        1,
        98,
        23,
        56,
        12,
        30,
        33,
        9,
        12,
        93,
        0,
        0,
        99,
        0,
        64,
        5,
        77,
        99,
        18,
        2,
        19,
        73,
        90,
        0,
        0,
        42,
        44,
        99,
        34,
        68,
        54,
        86,
        99,
        37,
        78,
        94,
        18,
        99,
        99,
        20,
        27
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        113,
        99,
        115,
        182,
        157,
        1,
        194,
        62,
        2,
        56,
        158,
        64,
        141,
        135,
        94,
        114,
        129,
        131,
        54,
        182,
        145,
        4,
        65,
        146,
        3,
        82,
        178,
        1,
        72,
        63,
        135,
        0,
        21,
        86,
        144,
        72,
        159,
        64,
        158,
        88,
        90,
        39,
        80,
        91,
        46,
        162,
        28,
        130,
        107,
        199,
        87,
        3
      ],
      "weeklyWasted": [
        144,
        72,
        159,
        64,
        158,
        88,
        90,
        39,
        80,
        91,
        46,
        162,
        28,
        130,
        107,
        199,
        87,
        3,
        99,
        39,
        0,
        35,
        96,
        1,
        0,
        41,
        47,
        80,
        31,
        30,
        68,
        59,
        43,
        1,
        65,
        26,
        1,
        60,
        51,
        0,
        14,
        0,
        20,
        14,
        13,
        1,
        74,
        22,
        19,
        80,
        72,
        53,
        2,
        99,
        66,
        87,
        99,
        79,
        0,
        0,
        99,
        81,
        54,
        99,
        44,
        68,
        50,
        81,
        27,
        38
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        184,
        56,
        195,
        90,
        25,
        48,
        194,
        3,
        144,
        48,
        199,
        86,
        198,
        85,
        164,
        81,
        121,
        155,
        186,
        89,
        127,
        198,
        2,
        126,
        3,
        81,
        61,
        126,
        119,
        113,
        0,
        45,
        81,
        171,
        164,
        0,
        39,
        85,
        88,
        0,
        199,
        140,
        74,
        78,
        81,
        4,
        156,
        145,
        76,
        148,
        24,
        3
      ],
      "weeklyWasted": [
        164,
        0,
        39,
        85,
        88,
        0,
        199,
        140,
        74,
        78,
        81,
        4,
        156,
        145,
        76,
        148,
        24,
        3,
        1,
        6,
        1,
        2,
        52,
        18,
        99,
        0,
        85,
        25,
        22,
        19,
        78,
        86,
        9,
        43,
        0,
        37,
        6,
        0,
        52,
        29,
        0,
        99,
        59,
        90,
        59,
        44,
        29,
        19,
        45,
        44,
        45,
        98,
        85,
        70,
        68,
        61,
        12,
        7,
        99,
        67,
        99,
        88,
        0,
        23,
        99,
        98,
        93,
        80,
        99,
        1
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        107,
        114,
        0,
        12,
        198,
        197,
        110,
        3,
        64,
        20,
        176,
        28,
        156,
        48,
        199,
        63,
        192,
        87,
        197,
        117,
        159,
        97,
        164,
        150,
        86,
        167,
        116,
        197,
        199,
        110,
        23,
        70,
        141,
        139,
        86,
        0,
        120,
        73,
        110,
        198,
        73,
        80,
        37,
        96,
        6,
        87,
        195,
        69,
        189,
        143,
        49,
        3
      ],
      "weeklyWasted": [
        86,
        0,
        120,
        73,
        110,
        198,
        73,
        80,
        37,
        96,
        6,
        87,
        195,
        69,
        189,
        143,
        49,
        3,
        34,
        22,
        45,
        2,
        98,
        56,
        56,
        44,
        43,
        59,
        38,
        66,
        76,
        99,
        75,
        5,
        17,
        86,
        57,
        0,
        54,
        87,
        0,
        75,
        46,
        99,
        98,
        1,
        45,
        1,
        31,
        83,
        99,
        81,
        99,
        48,
        14,
        0,
        99,
        87,
        6,
        41,
        92,
        80,
        38,
        0,
        48,
        53,
        51,
        99,
        56,
        1
      ]
    },
    {
      "id": "C71B9801",
      "weeklyUsage": [
        179,
        97,
        198,
        52,
        175,
        42,
        82,
        3,
        129,
        89,
        193,
        42,
        117,
        130,
        1,
        3,
        192,
        8,
        96,
        98,
        197,
        88,
        189,
        155,
        95,
        16,
        116,
        61,
        199,
        198,
        89,
        146,
        178,
        138,
        198,
        179,
        142,
        70,
        49,
        139,
        34,
        106,
        0,
        198,
        1,
        164,
        70,
        0,
        198,
        36,
        27,
        65
      ],
      "weeklyWasted": [
        198,
        179,
        142,
        70,
        49,
        139,
        34,
        106,
        0,
        198,
        1,
        164,
        70,
        0,
        198,
        36,
        27,
        65,
        30,
        21,
        98,
        23,
        0,
        48,
        81,
        67,
        99,
        60,
        71,
        61,
        20,
        99,
        97,
        16,
        87,
        45,
        3,
        3,
        2,
        24,
        0,
        57,
        99,
        94,
        98,
        82,
        67,
        1,
        2,
        63,
        62,
        98,
        0,
        50,
        60,
        93,
        45,
        69,
        25,
        11,
        99,
        78,
        59,
        82,
        86,
        30,
        90,
        82,
        99,
        1
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        199,
        127,
        7,
        5,
        0,
        1,
        32,
        5,
        199,
        74,
        54,
        32,
        198,
        148,
        196,
        105,
        192,
        199,
        3,
        2,
        197,
        4,
        54,
        110,
        3,
        198,
        197,
        33,
        0,
        73,
        108,
        3,
        1,
        58,
        92,
        41,
        0,
        91,
        11,
        104,
        7,
        16,
        114,
        0,
        1,
        196,
        156,
        77,
        1,
        93,
        87,
        3
      ],
      "weeklyWasted": [
        92,
        41,
        0,
        91,
        11,
        104,
        7,
        16,
        114,
        0,
        1,
        196,
        156,
        77,
        1,
        93,
        87,
        3,
        75,
        44,
        5,
        96,
        7,
        29,
        2,
        2,
        0,
        48,
        66,
        30,
        45,
        5,
        90,
        16,
        81,
        39,
        39,
        99,
        63,
        0,
        53,
        4,
        44,
        38,
        98,
        18,
        99,
        63,
        2,
        68,
        58,
        98,
        20,
        79,
        99,
        38,
        80,
        76,
        42,
        29,
        6,
        99,
        66,
        95,
        14,
        88,
        48,
        4,
        20,
        58
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyUsage": [
        103,
        87,
        33,
        195,
        184,
        84,
        84,
        73,
        89,
        104,
        54,
        45,
        50,
        0,
        69,
        40,
        138,
        169,
        146,
        133,
        99,
        34,
        191,
        170,
        82,
        131,
        197,
        124,
        199,
        75,
        199,
        85,
        6,
        137,
        145,
        63,
        0,
        97,
        199,
        0,
        8,
        143,
        142,
        144,
        44,
        158,
        158,
        156,
        186,
        80,
        2,
        64
      ],
      "weeklyWasted": [
        145,
        63,
        0,
        97,
        199,
        0,
        8,
        143,
        142,
        144,
        44,
        158,
        158,
        156,
        186,
        80,
        2,
        64,
        1,
        14,
        13,
        60,
        98,
        88,
        72,
        18,
        0,
        69,
        99,
        76,
        34,
        76,
        40,
        30,
        20,
        99,
        21,
        18,
        25,
        3,
        43,
        58,
        29,
        33,
        17,
        53,
        25,
        17,
        12,
        59,
        54,
        90,
        7,
        99,
        99,
        43,
        16,
        73,
        11,
        62,
        0,
        73,
        17,
        49,
        87,
        81,
        0,
        20,
        0,
        42
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        199,
        77,
        110,
        5,
        0,
        197,
        54,
        3,
        173,
        78,
        199,
        15,
        69,
        147,
        118,
        148,
        158,
        197,
        119,
        138,
        197,
        93,
        91,
        195,
        100,
        198,
        150,
        197,
        0,
        146,
        197,
        117,
        90,
        199,
        59,
        54,
        198,
        134,
        10,
        0,
        92,
        199,
        59,
        151,
        61,
        167,
        45,
        91,
        126,
        132,
        69,
        108
      ],
      "weeklyWasted": [
        59,
        54,
        198,
        134,
        10,
        0,
        92,
        199,
        59,
        151,
        61,
        167,
        45,
        91,
        126,
        132,
        69,
        108,
        99,
        5,
        99,
        12,
        87,
        1,
        54,
        21,
        81,
        0,
        19,
        65,
        18,
        99,
        87,
        39,
        27,
        45,
        83,
        49,
        27,
        33,
        97,
        0,
        25,
        99,
        42,
        77,
        99,
        72,
        40,
        97,
        79,
        14,
        17,
        60,
        75,
        66,
        39,
        46,
        6,
        28,
        6,
        75,
        99,
        83,
        25,
        28,
        43,
        99,
        70,
        39
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        171,
        2,
        0,
        100,
        154,
        134,
        77,
        3,
        147,
        113,
        0,
        71,
        26,
        10,
        13,
        126,
        100,
        118,
        11,
        152,
        54,
        131,
        72,
        69,
        3,
        131,
        164,
        1,
        171,
        198,
        199,
        196,
        116,
        70,
        110,
        65,
        119,
        5,
        33,
        179,
        32,
        136,
        0,
        0,
        78,
        4,
        47,
        153,
        151,
        41,
        2,
        3
      ],
      "weeklyWasted": [
        110,
        65,
        119,
        5,
        33,
        179,
        32,
        136,
        0,
        0,
        78,
        4,
        47,
        153,
        151,
        41,
        2,
        3,
        53,
        6,
        0,
        31,
        98,
        85,
        1,
        44,
        63,
        36,
        44,
        99,
        64,
        99,
        73,
        10,
        0,
        99,
        34,
        0,
        2,
        23,
        0,
        0,
        64,
        31,
        70,
        1,
        17,
        96,
        2,
        15,
        35,
        97,
        99,
        85,
        76,
        73,
        67,
        66,
        0,
        0,
        45,
        36,
        63,
        0,
        61,
        34,
        43,
        80,
        56,
        44
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        79,
        61,
        56,
        37,
        0,
        90,
        110,
        3,
        84,
        36,
        84,
        75,
        198,
        70,
        147,
        144,
        171,
        84,
        3,
        23,
        197,
        4,
        128,
        1,
        3,
        1,
        197,
        35,
        67,
        89,
        131,
        133,
        1,
        4,
        4,
        199,
        198,
        181,
        89,
        193,
        0,
        0,
        49,
        40,
        167,
        57,
        0,
        180,
        198,
        164,
        197,
        3
      ],
      "weeklyWasted": [
        4,
        199,
        198,
        181,
        89,
        193,
        0,
        0,
        49,
        40,
        167,
        57,
        0,
        180,
        198,
        164,
        197,
        3,
        79,
        55,
        13,
        22,
        75,
        98,
        0,
        0,
        78,
        42,
        41,
        47,
        53,
        50,
        46,
        33,
        18,
        71,
        52,
        55,
        3,
        25,
        40,
        21,
        24,
        55,
        98,
        66,
        2,
        50,
        46,
        2,
        26,
        80,
        38,
        30,
        79,
        48,
        73,
        50,
        0,
        56,
        56,
        78,
        99,
        35,
        99,
        98,
        71,
        78,
        99,
        1
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        199,
        21,
        148,
        55,
        0,
        58,
        5,
        3,
        199,
        95,
        199,
        87,
        134,
        84,
        5,
        151,
        192,
        165,
        3,
        124,
        56,
        4,
        89,
        105,
        171,
        123,
        166,
        54,
        199,
        150,
        199,
        41,
        15,
        19,
        4,
        17,
        198,
        184,
        130,
        3,
        124,
        47,
        114,
        16,
        52,
        182,
        75,
        198,
        110,
        32,
        10,
        3
      ],
      "weeklyWasted": [
        4,
        17,
        198,
        184,
        130,
        3,
        124,
        47,
        114,
        16,
        52,
        182,
        75,
        198,
        110,
        32,
        10,
        3,
        9,
        7,
        64,
        96,
        98,
        1,
        11,
        21,
        86,
        83,
        52,
        69,
        42,
        94,
        97,
        98,
        45,
        56,
        87,
        87,
        35,
        67,
        0,
        0,
        12,
        29,
        71,
        70,
        51,
        72,
        2,
        97,
        34,
        83,
        91,
        7,
        44,
        12,
        33,
        17,
        4,
        98,
        73,
        77,
        65,
        38,
        34,
        69,
        0,
        1,
        32,
        31
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        60,
        2,
        77,
        130,
        198,
        171,
        85,
        111,
        2,
        1,
        108,
        70,
        190,
        106,
        195,
        113,
        148,
        199,
        3,
        57,
        197,
        40,
        63,
        140,
        83,
        141,
        146,
        35,
        120,
        57,
        96,
        80,
        6,
        62,
        80,
        191,
        198,
        78,
        106,
        25,
        161,
        198,
        81,
        21,
        12,
        159,
        25,
        0,
        198,
        171,
        2,
        6
      ],
      "weeklyWasted": [
        80,
        191,
        198,
        78,
        106,
        25,
        161,
        198,
        81,
        21,
        12,
        159,
        25,
        0,
        198,
        171,
        2,
        6,
        20,
        53,
        0,
        22,
        46,
        77,
        54,
        0,
        90,
        99,
        0,
        45,
        70,
        85,
        92,
        54,
        0,
        71,
        34,
        99,
        2,
        15,
        90,
        99,
        45,
        99,
        76,
        70,
        26,
        14,
        76,
        40,
        24,
        98,
        99,
        55,
        99,
        54,
        42,
        60,
        91,
        16,
        98,
        62,
        38,
        3,
        99,
        98,
        0,
        99,
        44,
        1
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyUsage": [
        164,
        2,
        178,
        92,
        47,
        189,
        144,
        80,
        56,
        83,
        199,
        2,
        117,
        180,
        159,
        139,
        163,
        1,
        22,
        2,
        197,
        126,
        52,
        45,
        190,
        192,
        197,
        1,
        0,
        45,
        62,
        20,
        1,
        63,
        150,
        27,
        32,
        199,
        174,
        57,
        98,
        0,
        61,
        0,
        17,
        18,
        105,
        198,
        156,
        199,
        42,
        3
      ],
      "weeklyWasted": [
        150,
        27,
        32,
        199,
        174,
        57,
        98,
        0,
        61,
        0,
        17,
        18,
        105,
        198,
        156,
        199,
        42,
        3,
        14,
        1,
        31,
        35,
        59,
        48,
        89,
        0,
        99,
        19,
        53,
        53,
        99,
        40,
        44,
        20,
        53,
        89,
        88,
        0,
        2,
        77,
        62,
        0,
        99,
        52,
        76,
        95,
        91,
        93,
        30,
        42,
        35,
        98,
        99,
        99,
        62,
        75,
        48,
        99,
        0,
        68,
        99,
        99,
        99,
        54,
        28,
        55,
        2,
        41,
        55,
        1
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        38,
        2,
        35,
        5,
        198,
        197,
        194,
        22,
        199,
        31,
        0,
        36,
        166,
        153,
        199,
        152,
        192,
        95,
        197,
        2,
        6,
        198,
        2,
        161,
        3,
        198,
        144,
        75,
        156,
        0,
        51,
        0,
        134,
        42,
        134,
        199,
        198,
        53,
        150,
        85,
        42,
        96,
        0,
        57,
        1,
        196,
        47,
        117,
        134,
        131,
        2,
        80
      ],
      "weeklyWasted": [
        134,
        199,
        198,
        53,
        150,
        85,
        42,
        96,
        0,
        57,
        1,
        196,
        47,
        117,
        134,
        131,
        2,
        80,
        24,
        1,
        74,
        2,
        0,
        63,
        46,
        0,
        99,
        99,
        90,
        70,
        72,
        97,
        90,
        43,
        28,
        99,
        14,
        19,
        52,
        0,
        42,
        30,
        88,
        99,
        66,
        46,
        36,
        1,
        72,
        27,
        43,
        98,
        26,
        0,
        97,
        36,
        3,
        96,
        99,
        0,
        57,
        89,
        99,
        1,
        36,
        17,
        0,
        99,
        47,
        1
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        199,
        2,
        154,
        195,
        101,
        197,
        108,
        3,
        117,
        76,
        51,
        22,
        29,
        0,
        199,
        198,
        192,
        161,
        44,
        2,
        1,
        28,
        110,
        84,
        114,
        162,
        182,
        122,
        123,
        25,
        199,
        123,
        83,
        199,
        97,
        122,
        13,
        5,
        104,
        0,
        145,
        171,
        193,
        52,
        1,
        117,
        106,
        198,
        64,
        124,
        2,
        179
      ],
      "weeklyWasted": [
        97,
        122,
        13,
        5,
        104,
        0,
        145,
        171,
        193,
        52,
        1,
        117,
        106,
        198,
        64,
        124,
        2,
        179,
        14,
        17,
        0,
        42,
        98,
        86,
        84,
        43,
        33,
        59,
        70,
        98,
        20,
        47,
        97,
        46,
        0,
        60,
        44,
        15,
        57,
        0,
        35,
        0,
        56,
        99,
        33,
        14,
        99,
        14,
        2,
        42,
        23,
        98,
        0,
        62,
        99,
        43,
        56,
        73,
        11,
        81,
        70,
        48,
        76,
        21,
        31,
        98,
        27,
        57,
        63,
        78
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        168,
        53,
        63,
        5,
        12,
        147,
        40,
        3,
        51,
        58,
        199,
        2,
        2,
        64,
        1,
        119,
        96,
        1,
        68,
        197,
        32,
        38,
        197,
        69,
        3,
        28,
        105,
        83,
        97,
        55,
        0,
        23,
        5,
        110,
        164,
        28,
        102,
        128,
        163,
        94,
        72,
        87,
        0,
        0,
        1,
        26,
        47,
        119,
        175,
        109,
        8,
        145
      ],
      "weeklyWasted": [
        164,
        28,
        102,
        128,
        163,
        94,
        72,
        87,
        0,
        0,
        1,
        26,
        47,
        119,
        175,
        109,
        8,
        145,
        82,
        1,
        0,
        6,
        52,
        51,
        42,
        0,
        99,
        99,
        99,
        24,
        99,
        84,
        69,
        79,
        94,
        19,
        1,
        0,
        62,
        99,
        56,
        73,
        33,
        90,
        81,
        47,
        34,
        5,
        69,
        52,
        86,
        69,
        99,
        0,
        77,
        92,
        23,
        84,
        36,
        0,
        99,
        86,
        14,
        59,
        17,
        45,
        35,
        88,
        99,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        39,
        102,
        118,
        78,
        0,
        13,
        153,
        197,
        173,
        1,
        55,
        2,
        2,
        0,
        77,
        3,
        192,
        188,
        76,
        159,
        115,
        137,
        156,
        82,
        3,
        5,
        3,
        45,
        51,
        71,
        23,
        196,
        1,
        18,
        40,
        55,
        98,
        183,
        16,
        163,
        28,
        58,
        2,
        96,
        64,
        18,
        46,
        198,
        191,
        148,
        80,
        3
      ],
      "weeklyWasted": [
        40,
        55,
        98,
        183,
        16,
        163,
        28,
        58,
        2,
        96,
        64,
        18,
        46,
        198,
        191,
        148,
        80,
        3,
        56,
        1,
        59,
        2,
        96,
        38,
        99,
        44,
        29,
        97,
        0,
        99,
        47,
        58,
        3,
        23,
        92,
        59,
        33,
        35,
        2,
        47,
        0,
        52,
        36,
        93,
        98,
        10,
        2,
        28,
        2,
        97,
        31,
        65,
        65,
        62,
        51,
        57,
        99,
        1,
        80,
        30,
        66,
        41,
        0,
        49,
        72,
        87,
        31,
        93,
        41,
        33
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        7,
        2,
        198,
        5,
        131,
        8,
        3,
        115,
        159,
        1,
        181,
        37,
        16,
        12,
        51,
        116,
        192,
        196,
        73,
        2,
        159,
        198,
        140,
        77,
        79,
        1,
        83,
        78,
        175,
        73,
        133,
        196,
        197,
        153,
        74,
        199,
        0,
        103,
        177,
        0,
        199,
        57,
        78,
        132,
        114,
        45,
        188,
        145,
        198,
        114,
        84,
        3
      ],
      "weeklyWasted": [
        74,
        199,
        0,
        103,
        177,
        0,
        199,
        57,
        78,
        132,
        114,
        45,
        188,
        145,
        198,
        114,
        84,
        3,
        12,
        46,
        24,
        69,
        0,
        46,
        82,
        12,
        99,
        23,
        99,
        99,
        72,
        99,
        22,
        98,
        75,
        83,
        68,
        0,
        36,
        4,
        0,
        22,
        74,
        99,
        12,
        27,
        2,
        1,
        2,
        65,
        24,
        98,
        99,
        7,
        84,
        69,
        29,
        1,
        35,
        0,
        85,
        40,
        77,
        47,
        99,
        78,
        83,
        99,
        36,
        32
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        142,
        177,
        110,
        91,
        70,
        92,
        104,
        97,
        76,
        1,
        73,
        103,
        190,
        155,
        127,
        92,
        192,
        66,
        3,
        132,
        169,
        63,
        197,
        94,
        44,
        163,
        197,
        29,
        108,
        198,
        138,
        0,
        16,
        78,
        127,
        124,
        173,
        7,
        199,
        199,
        67,
        19,
        122,
        0,
        1,
        196,
        106,
        83,
        1,
        99,
        75,
        3
      ],
      "weeklyWasted": [
        127,
        124,
        173,
        7,
        199,
        199,
        67,
        19,
        122,
        0,
        1,
        196,
        106,
        83,
        1,
        99,
        75,
        3,
        82,
        72,
        38,
        2,
        98,
        70,
        0,
        37,
        65,
        42,
        99,
        87,
        11,
        87,
        45,
        98,
        77,
        34,
        95,
        99,
        50,
        68,
        59,
        0,
        99,
        93,
        60,
        97,
        90,
        1,
        2,
        52,
        35,
        37,
        0,
        10,
        31,
        59,
        0,
        95,
        0,
        34,
        99,
        12,
        99,
        23,
        28,
        83,
        31,
        5,
        99,
        12
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        131,
        145,
        156,
        5,
        88,
        60,
        67,
        47,
        2,
        37,
        0,
        18,
        165,
        126,
        183,
        79,
        192,
        72,
        81,
        2,
        197,
        49,
        54,
        63,
        197,
        73,
        197,
        87,
        126,
        149,
        26,
        65,
        119,
        67,
        88,
        161,
        191,
        135,
        138,
        21,
        25,
        4,
        0,
        0,
        3,
        124,
        110,
        2,
        198,
        187,
        109,
        15
      ],
      "weeklyWasted": [
        88,
        161,
        191,
        135,
        138,
        21,
        25,
        4,
        0,
        0,
        3,
        124,
        110,
        2,
        198,
        187,
        109,
        15,
        34,
        1,
        0,
        51,
        81,
        21,
        99,
        22,
        91,
        25,
        99,
        52,
        99,
        20,
        48,
        1,
        42,
        56,
        5,
        54,
        99,
        0,
        13,
        0,
        67,
        66,
        98,
        42,
        77,
        59,
        17,
        97,
        1,
        48,
        59,
        12,
        9,
        55,
        99,
        68,
        41,
        3,
        59,
        68,
        73,
        0,
        77,
        98,
        68,
        96,
        37,
        8
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        103,
        2,
        106,
        32,
        142,
        197,
        32,
        45,
        129,
        47,
        27,
        84,
        2,
        67,
        99,
        104,
        151,
        180,
        144,
        41,
        197,
        13,
        188,
        80,
        3,
        1,
        70,
        16,
        199,
        0,
        121,
        169,
        197,
        153,
        21,
        0,
        36,
        48,
        106,
        27,
        156,
        0,
        132,
        169,
        40,
        122,
        57,
        88,
        198,
        199,
        143,
        196
      ],
      "weeklyWasted": [
        21,
        0,
        36,
        48,
        106,
        27,
        156,
        0,
        132,
        169,
        40,
        122,
        57,
        88,
        198,
        199,
        143,
        196,
        1,
        39,
        82,
        19,
        55,
        79,
        99,
        35,
        82,
        25,
        41,
        99,
        64,
        69,
        57,
        34,
        38,
        84,
        73,
        7,
        13,
        0,
        4,
        72,
        47,
        53,
        64,
        45,
        2,
        29,
        14,
        15,
        70,
        81,
        99,
        78,
        61,
        4,
        99,
        29,
        47,
        31,
        80,
        41,
        38,
        26,
        92,
        87,
        26,
        99,
        66,
        19
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        194,
        2,
        0,
        5,
        124,
        117,
        155,
        3,
        62,
        1,
        24,
        48,
        117,
        144,
        181,
        103,
        135,
        1,
        123,
        166,
        197,
        42,
        179,
        129,
        82,
        158,
        3,
        47,
        3,
        98,
        110,
        33,
        52,
        23,
        14,
        60,
        116,
        118,
        127,
        131,
        0,
        110,
        92,
        107,
        107,
        196,
        0,
        141,
        198,
        115,
        84,
        35
      ],
      "weeklyWasted": [
        14,
        60,
        116,
        118,
        127,
        131,
        0,
        110,
        92,
        107,
        107,
        196,
        0,
        141,
        198,
        115,
        84,
        35,
        21,
        45,
        99,
        2,
        85,
        36,
        45,
        0,
        99,
        60,
        95,
        39,
        24,
        99,
        76,
        47,
        93,
        99,
        1,
        74,
        77,
        70,
        37,
        0,
        0,
        92,
        98,
        51,
        38,
        27,
        36,
        97,
        43,
        65,
        0,
        59,
        72,
        99,
        99,
        99,
        99,
        0,
        18,
        96,
        33,
        61,
        99,
        66,
        40,
        84,
        99,
        96
      ]
    },
    {
      "id": "F55885BD",
      "weeklyUsage": [
        170,
        57,
        167,
        5,
        33,
        122,
        54,
        23,
        13,
        1,
        150,
        84,
        198,
        131,
        199,
        158,
        192,
        163,
        197,
        2,
        180,
        59,
        2,
        1,
        3,
        22,
        197,
        11,
        185,
        113,
        120,
        191,
        120,
        23,
        151,
        199,
        127,
        180,
        100,
        0,
        0,
        126,
        113,
        0,
        1,
        10,
        115,
        81,
        111,
        199,
        52,
        79
      ],
      "weeklyWasted": [
        151,
        199,
        127,
        180,
        100,
        0,
        0,
        126,
        113,
        0,
        1,
        10,
        115,
        81,
        111,
        199,
        52,
        79,
        1,
        47,
        0,
        2,
        98,
        42,
        61,
        0,
        99,
        2,
        99,
        44,
        60,
        96,
        0,
        47,
        76,
        1,
        1,
        52,
        44,
        0,
        93,
        0,
        45,
        44,
        98,
        21,
        57,
        1,
        71,
        81,
        1,
        71,
        16,
        31,
        49,
        10,
        11,
        99,
        84,
        0,
        26,
        36,
        99,
        6,
        83,
        95,
        35,
        92,
        99,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        199,
        90,
        198,
        34,
        83,
        1,
        157,
        3,
        127,
        15,
        164,
        20,
        148,
        39,
        184,
        3,
        177,
        46,
        181,
        130,
        50,
        120,
        64,
        60,
        180,
        21,
        3,
        43,
        100,
        149,
        0,
        175,
        134,
        199,
        15,
        0,
        104,
        138,
        38,
        178,
        113,
        89,
        58,
        0,
        1,
        58,
        34,
        74,
        198,
        4,
        2,
        3
      ],
      "weeklyWasted": [
        15,
        0,
        104,
        138,
        38,
        178,
        113,
        89,
        58,
        0,
        1,
        58,
        34,
        74,
        198,
        4,
        2,
        3,
        1,
        21,
        99,
        19,
        98,
        11,
        99,
        0,
        30,
        32,
        67,
        51,
        69,
        3,
        55,
        28,
        78,
        80,
        48,
        8,
        2,
        76,
        54,
        45,
        56,
        99,
        32,
        1,
        15,
        37,
        2,
        97,
        26,
        58,
        51,
        0,
        14,
        99,
        1,
        62,
        2,
        0,
        99,
        80,
        18,
        93,
        40,
        98,
        96,
        80,
        99,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        146,
        105,
        116,
        5,
        2,
        133,
        38,
        37,
        95,
        62,
        88,
        155,
        175,
        199,
        53,
        161,
        158,
        199,
        3,
        168,
        197,
        4,
        96,
        195,
        42,
        58,
        85,
        152,
        54,
        44,
        7,
        101,
        1,
        64,
        155,
        0,
        67,
        19,
        16,
        43,
        0,
        2,
        0,
        164,
        1,
        196,
        0,
        123,
        198,
        162,
        142,
        196
      ],
      "weeklyWasted": [
        155,
        0,
        67,
        19,
        16,
        43,
        0,
        2,
        0,
        164,
        1,
        196,
        0,
        123,
        198,
        162,
        142,
        196,
        89,
        3,
        55,
        2,
        66,
        61,
        4,
        0,
        62,
        99,
        93,
        3,
        1,
        18,
        71,
        14,
        45,
        99,
        50,
        29,
        42,
        99,
        40,
        10,
        0,
        99,
        65,
        81,
        20,
        15,
        22,
        58,
        99,
        86,
        56,
        15,
        81,
        36,
        81,
        1,
        0,
        39,
        71,
        78,
        13,
        76,
        99,
        98,
        16,
        79,
        34,
        53
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        187,
        2,
        117,
        58,
        63,
        4,
        110,
        3,
        112,
        1,
        84,
        47,
        193,
        54,
        188,
        128,
        190,
        177,
        128,
        2,
        197,
        134,
        147,
        160,
        30,
        115,
        3,
        90,
        199,
        198,
        135,
        0,
        110,
        199,
        99,
        145,
        119,
        5,
        199,
        15,
        97,
        52,
        0,
        36,
        1,
        92,
        0,
        184,
        66,
        114,
        115,
        64
      ],
      "weeklyWasted": [
        99,
        145,
        119,
        5,
        199,
        15,
        97,
        52,
        0,
        36,
        1,
        92,
        0,
        184,
        66,
        114,
        115,
        64,
        30,
        16,
        99,
        18,
        0,
        93,
        57,
        0,
        0,
        53,
        67,
        1,
        28,
        3,
        9,
        25,
        23,
        75,
        8,
        16,
        7,
        46,
        32,
        58,
        99,
        99,
        78,
        29,
        99,
        55,
        2,
        81,
        96,
        98,
        45,
        4,
        64,
        4,
        67,
        1,
        11,
        0,
        99,
        99,
        99,
        81,
        99,
        72,
        99,
        1,
        45,
        1
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        125,
        199,
        144,
        42,
        75,
        1,
        148,
        76,
        199,
        67,
        199,
        136,
        96,
        13,
        169,
        138,
        192,
        34,
        133,
        11,
        35,
        4,
        57,
        195,
        3,
        183,
        187,
        128,
        89,
        3,
        199,
        84,
        40,
        8,
        5,
        26,
        47,
        113,
        10,
        62,
        16,
        32,
        0,
        36,
        19,
        196,
        0,
        172,
        198,
        116,
        130,
        132
      ],
      "weeklyWasted": [
        5,
        26,
        47,
        113,
        10,
        62,
        16,
        32,
        0,
        36,
        19,
        196,
        0,
        172,
        198,
        116,
        130,
        132,
        57,
        1,
        15,
        24,
        95,
        57,
        0,
        10,
        43,
        61,
        88,
        39,
        91,
        99,
        97,
        98,
        10,
        99,
        36,
        61,
        28,
        0,
        0,
        23,
        12,
        21,
        79,
        97,
        99,
        16,
        68,
        22,
        6,
        56,
        95,
        77,
        61,
        0,
        39,
        87,
        0,
        98,
        35,
        68,
        58,
        60,
        68,
        98,
        25,
        99,
        99,
        82
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        199,
        83,
        62,
        61,
        0,
        197,
        194,
        150,
        78,
        1,
        128,
        176,
        162,
        92,
        80,
        60,
        127,
        89,
        46,
        197,
        197,
        52,
        149,
        70,
        3,
        1,
        193,
        28,
        0,
        198,
        118,
        118,
        88,
        4,
        193,
        0,
        198,
        107,
        10,
        199,
        61,
        18,
        0,
        0,
        57,
        140,
        0,
        25,
        116,
        199,
        157,
        103
      ],
      "weeklyWasted": [
        193,
        0,
        198,
        107,
        10,
        199,
        61,
        18,
        0,
        0,
        57,
        140,
        0,
        25,
        116,
        199,
        157,
        103,
        85,
        14,
        99,
        32,
        98,
        32,
        10,
        20,
        83,
        99,
        44,
        32,
        69,
        99,
        27,
        70,
        62,
        8,
        63,
        88,
        2,
        24,
        97,
        3,
        0,
        54,
        17,
        63,
        26,
        75,
        89,
        28,
        94,
        92,
        72,
        0,
        4,
        89,
        30,
        1,
        25,
        0,
        4,
        86,
        0,
        58,
        99,
        73,
        85,
        80,
        51,
        1
      ]
    },
    {
      "id": "958C4CED",
      "weeklyUsage": [
        189,
        109,
        26,
        155,
        31,
        197,
        114,
        135,
        71,
        1,
        102,
        19,
        106,
        63,
        1,
        3,
        143,
        112,
        62,
        122,
        1,
        4,
        146,
        66,
        3,
        19,
        165,
        139,
        199,
        125,
        109,
        91,
        124,
        174,
        198,
        0,
        149,
        35,
        10,
        14,
        199,
        0,
        44,
        0,
        69,
        90,
        12,
        61,
        120,
        199,
        60,
        196
      ],
      "weeklyWasted": [
        198,
        0,
        149,
        35,
        10,
        14,
        199,
        0,
        44,
        0,
        69,
        90,
        12,
        61,
        120,
        199,
        60,
        196,
        25,
        17,
        3,
        4,
        69,
        98,
        43,
        0,
        59,
        35,
        94,
        29,
        99,
        99,
        67,
        1,
        14,
        3,
        32,
        0,
        46,
        6,
        68,
        8,
        95,
        21,
        27,
        29,
        91,
        30,
        35,
        53,
        99,
        78,
        99,
        28,
        55,
        89,
        99,
        1,
        13,
        60,
        86,
        99,
        44,
        34,
        97,
        92,
        0,
        99,
        63,
        4
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        162,
        113,
        65,
        62,
        162,
        146,
        152,
        7,
        170,
        57,
        53,
        73,
        198,
        62,
        199,
        173,
        192,
        199,
        96,
        67,
        197,
        35,
        176,
        65,
        29,
        186,
        41,
        1,
        0,
        32,
        43,
        56,
        11,
        100,
        121,
        199,
        113,
        166,
        170,
        14,
        0,
        57,
        0,
        0,
        28,
        196,
        138,
        32,
        175,
        2,
        106,
        181
      ],
      "weeklyWasted": [
        121,
        199,
        113,
        166,
        170,
        14,
        0,
        57,
        0,
        0,
        28,
        196,
        138,
        32,
        175,
        2,
        106,
        181,
        1,
        1,
        26,
        2,
        81,
        1,
        40,
        11,
        97,
        23,
        80,
        8,
        99,
        99,
        11,
        8,
        34,
        74,
        16,
        51,
        28,
        50,
        0,
        99,
        71,
        99,
        17,
        15,
        2,
        79,
        20,
        97,
        1,
        98,
        32,
        20,
        90,
        99,
        77,
        93,
        47,
        0,
        99,
        56,
        90,
        0,
        99,
        67,
        94,
        94,
        41,
        1
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        89,
        70,
        22,
        5,
        4,
        144,
        127,
        138,
        125,
        12,
        0,
        2,
        138,
        39,
        117,
        121,
        156,
        114,
        197,
        172,
        1,
        137,
        35,
        121,
        3,
        159,
        160,
        75,
        110,
        171,
        48,
        62,
        162,
        199,
        4,
        88,
        198,
        135,
        108,
        199,
        29,
        0,
        89,
        19,
        1,
        119,
        150,
        132,
        198,
        194,
        168,
        19
      ],
      "weeklyWasted": [
        4,
        88,
        198,
        135,
        108,
        199,
        29,
        0,
        89,
        19,
        1,
        119,
        150,
        132,
        198,
        194,
        168,
        19,
        62,
        1,
        61,
        44,
        63,
        39,
        99,
        47,
        50,
        37,
        69,
        53,
        55,
        74,
        1,
        1,
        98,
        1,
        40,
        71,
        63,
        0,
        0,
        35,
        48,
        46,
        98,
        63,
        52,
        28,
        2,
        20,
        44,
        98,
        72,
        39,
        38,
        82,
        93,
        89,
        42,
        26,
        99,
        99,
        99,
        76,
        99,
        11,
        68,
        8,
        48,
        17
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        182,
        91,
        59,
        5,
        57,
        124,
        80,
        3,
        157,
        1,
        123,
        88,
        102,
        0,
        154,
        3,
        0,
        80,
        177,
        59,
        2,
        70,
        84,
        133,
        24,
        139,
        141,
        163,
        199,
        12,
        61,
        0,
        23,
        96,
        70,
        0,
        0,
        105,
        199,
        118,
        80,
        0,
        77,
        0,
        1,
        4,
        50,
        143,
        169,
        53,
        2,
        195
      ],
      "weeklyWasted": [
        70,
        0,
        0,
        105,
        199,
        118,
        80,
        0,
        77,
        0,
        1,
        4,
        50,
        143,
        169,
        53,
        2,
        195,
        1,
        1,
        2,
        93,
        98,
        15,
        28,
        20,
        3,
        66,
        68,
        99,
        77,
        88,
        0,
        98,
        56,
        50,
        84,
        10,
        33,
        91,
        29,
        17,
        82,
        68,
        68,
        72,
        65,
        28,
        2,
        65,
        93,
        37,
        93,
        87,
        89,
        10,
        99,
        79,
        0,
        52,
        56,
        99,
        99,
        58,
        32,
        37,
        26,
        52,
        43,
        30
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        127,
        59,
        198,
        17,
        114,
        1,
        37,
        48,
        114,
        67,
        188,
        22,
        149,
        139,
        58,
        3,
        30,
        63,
        117,
        2,
        50,
        87,
        2,
        129,
        176,
        40,
        151,
        1,
        199,
        85,
        117,
        56,
        52,
        20,
        74,
        167,
        171,
        56,
        199,
        0,
        0,
        199,
        141,
        0,
        16,
        122,
        0,
        32,
        150,
        118,
        69,
        87
      ],
      "weeklyWasted": [
        74,
        167,
        171,
        56,
        199,
        0,
        0,
        199,
        141,
        0,
        16,
        122,
        0,
        32,
        150,
        118,
        69,
        87,
        17,
        1,
        21,
        17,
        89,
        87,
        99,
        0,
        78,
        75,
        99,
        99,
        99,
        3,
        0,
        98,
        0,
        99,
        12,
        56,
        53,
        51,
        0,
        29,
        34,
        81,
        98,
        62,
        89,
        1,
        2,
        97,
        23,
        91,
        99,
        49,
        99,
        37,
        28,
        95,
        6,
        79,
        89,
        1,
        31,
        62,
        0,
        98,
        31,
        99,
        99,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyUsage": [
        127,
        98,
        50,
        31,
        1,
        98,
        150,
        3,
        2,
        58,
        145,
        2,
        116,
        95,
        63,
        27,
        192,
        199,
        81,
        65,
        107,
        8,
        68,
        169,
        103,
        1,
        197,
        1,
        139,
        112,
        199,
        83,
        107,
        138,
        68,
        0,
        0,
        145,
        75,
        14,
        0,
        0,
        13,
        81,
        6,
        60,
        63,
        188,
        198,
        189,
        44,
        191
      ],
      "weeklyWasted": [
        68,
        0,
        0,
        145,
        75,
        14,
        0,
        0,
        13,
        81,
        6,
        60,
        63,
        188,
        198,
        189,
        44,
        191,
        77,
        74,
        54,
        2,
        47,
        77,
        30,
        0,
        82,
        37,
        7,
        95,
        49,
        89,
        46,
        39,
        79,
        99,
        19,
        0,
        61,
        99,
        39,
        13,
        29,
        99,
        73,
        57,
        3,
        1,
        35,
        22,
        11,
        98,
        70,
        99,
        30,
        73,
        24,
        1,
        55,
        0,
        95,
        16,
        21,
        27,
        75,
        98,
        99,
        61,
        99,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        130,
        77,
        57,
        97,
        93,
        83,
        3,
        77,
        51,
        25,
        197,
        22,
        16,
        39,
        197,
        118,
        192,
        199,
        137,
        11,
        137,
        138,
        142,
        181,
        3,
        198,
        131,
        79,
        199,
        130,
        99,
        138,
        89,
        70,
        172,
        141,
        136,
        109,
        138,
        41,
        0,
        121,
        109,
        107,
        155,
        90,
        106,
        198,
        84,
        94,
        172,
        115
      ],
      "weeklyWasted": [
        172,
        141,
        136,
        109,
        138,
        41,
        0,
        121,
        109,
        107,
        155,
        90,
        106,
        198,
        84,
        94,
        172,
        115,
        59,
        22,
        55,
        68,
        98,
        94,
        99,
        23,
        32,
        20,
        99,
        99,
        16,
        3,
        54,
        1,
        0,
        67,
        20,
        59,
        82,
        21,
        92,
        0,
        26,
        32,
        74,
        72,
        99,
        61,
        14,
        58,
        30,
        83,
        76,
        72,
        43,
        72,
        77,
        53,
        54,
        49,
        42,
        54,
        59,
        99,
        99,
        86,
        83,
        90,
        13,
        35
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyUsage": [
        94,
        13,
        11,
        195,
        135,
        33,
        106,
        180,
        69,
        63,
        36,
        2,
        186,
        0,
        44,
        122,
        143,
        199,
        23,
        103,
        197,
        9,
        112,
        12,
        3,
        99,
        109,
        28,
        111,
        47,
        84,
        71,
        197,
        107,
        123,
        0,
        191,
        42,
        90,
        21,
        135,
        3,
        57,
        65,
        1,
        195,
        58,
        83,
        198,
        112,
        2,
        136
      ],
      "weeklyWasted": [
        123,
        0,
        191,
        42,
        90,
        21,
        135,
        3,
        57,
        65,
        1,
        195,
        58,
        83,
        198,
        112,
        2,
        136,
        46,
        14,
        99,
        32,
        91,
        24,
        62,
        18,
        99,
        15,
        59,
        59,
        59,
        98,
        0,
        98,
        55,
        81,
        99,
        36,
        54,
        54,
        0,
        39,
        58,
        99,
        58,
        97,
        73,
        52,
        19,
        57,
        1,
        82,
        99,
        46,
        74,
        41,
        73,
        41,
        0,
        0,
        69,
        99,
        26,
        0,
        99,
        98,
        22,
        54,
        18,
        85
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        199,
        86,
        182,
        36,
        0,
        132,
        49,
        131,
        2,
        1,
        199,
        27,
        198,
        98,
        187,
        129,
        192,
        151,
        3,
        2,
        80,
        4,
        195,
        111,
        3,
        74,
        197,
        78,
        147,
        198,
        78,
        1,
        99,
        163,
        156,
        133,
        198,
        191,
        146,
        97,
        1,
        47,
        199,
        0,
        39,
        9,
        195,
        46,
        198,
        167,
        67,
        85
      ],
      "weeklyWasted": [
        156,
        133,
        198,
        191,
        146,
        97,
        1,
        47,
        199,
        0,
        39,
        9,
        195,
        46,
        198,
        167,
        67,
        85,
        1,
        90,
        43,
        52,
        98,
        74,
        61,
        70,
        99,
        63,
        79,
        4,
        42,
        68,
        93,
        91,
        60,
        17,
        74,
        77,
        71,
        25,
        3,
        0,
        19,
        49,
        10,
        36,
        29,
        14,
        16,
        58,
        50,
        59,
        49,
        99,
        99,
        0,
        0,
        29,
        23,
        0,
        59,
        77,
        74,
        75,
        99,
        98,
        34,
        80,
        16,
        19
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        182,
        119,
        178,
        59,
        0,
        106,
        99,
        105,
        199,
        74,
        28,
        2,
        195,
        0,
        199,
        117,
        81,
        22,
        9,
        79,
        197,
        20,
        183,
        69,
        171,
        59,
        190,
        1,
        171,
        48,
        81,
        72,
        1,
        199,
        121,
        199,
        38,
        49,
        199,
        83,
        104,
        11,
        165,
        46,
        122,
        83,
        0,
        118,
        164,
        199,
        117,
        3
      ],
      "weeklyWasted": [
        121,
        199,
        38,
        49,
        199,
        83,
        104,
        11,
        165,
        46,
        122,
        83,
        0,
        118,
        164,
        199,
        117,
        3,
        6,
        10,
        56,
        33,
        98,
        98,
        6,
        81,
        93,
        71,
        60,
        71,
        75,
        63,
        38,
        53,
        13,
        99,
        1,
        75,
        2,
        81,
        9,
        3,
        24,
        63,
        86,
        10,
        92,
        94,
        2,
        71,
        30,
        79,
        68,
        87,
        89,
        3,
        64,
        1,
        0,
        0,
        60,
        99,
        99,
        12,
        85,
        42,
        0,
        99,
        8,
        60
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyUsage": [
        151,
        22,
        17,
        152,
        0,
        1,
        183,
        3,
        109,
        36,
        199,
        58,
        2,
        0,
        48,
        198,
        190,
        116,
        3,
        144,
        1,
        78,
        152,
        150,
        197,
        163,
        143,
        72,
        199,
        143,
        117,
        113,
        96,
        199,
        135,
        141,
        97,
        10,
        199,
        126,
        22,
        112,
        84,
        0,
        68,
        129,
        47,
        0,
        155,
        67,
        13,
        70
      ],
      "weeklyWasted": [
        135,
        141,
        97,
        10,
        199,
        126,
        22,
        112,
        84,
        0,
        68,
        129,
        47,
        0,
        155,
        67,
        13,
        70,
        74,
        39,
        0,
        2,
        92,
        98,
        0,
        28,
        97,
        41,
        43,
        49,
        6,
        78,
        50,
        98,
        55,
        99,
        19,
        48,
        31,
        0,
        34,
        10,
        0,
        27,
        35,
        97,
        99,
        41,
        2,
        63,
        5,
        98,
        8,
        78,
        67,
        31,
        22,
        85,
        34,
        13,
        75,
        84,
        64,
        51,
        4,
        37,
        54,
        95,
        28,
        42
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        63,
        2,
        84,
        53,
        122,
        41,
        194,
        3,
        132,
        39,
        65,
        91,
        190,
        88,
        152,
        3,
        192,
        199,
        156,
        105,
        45,
        72,
        65,
        195,
        33,
        41,
        179,
        107,
        65,
        62,
        137,
        133,
        144,
        199,
        46,
        0,
        0,
        143,
        132,
        133,
        81,
        54,
        57,
        29,
        56,
        176,
        40,
        22,
        182,
        139,
        15,
        53
      ],
      "weeklyWasted": [
        46,
        0,
        0,
        143,
        132,
        133,
        81,
        54,
        57,
        29,
        56,
        176,
        40,
        22,
        182,
        139,
        15,
        53,
        1,
        78,
        68,
        51,
        14,
        27,
        99,
        0,
        49,
        30,
        69,
        98,
        61,
        7,
        73,
        16,
        60,
        66,
        8,
        32,
        64,
        0,
        50,
        99,
        19,
        82,
        8,
        82,
        98,
        3,
        17,
        97,
        66,
        62,
        56,
        2,
        36,
        0,
        16,
        62,
        26,
        27,
        92,
        91,
        67,
        76,
        94,
        46,
        99,
        58,
        46,
        2
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        184,
        81,
        40,
        63,
        90,
        197,
        137,
        3,
        103,
        52,
        186,
        2,
        151,
        84,
        33,
        133,
        192,
        199,
        57,
        189,
        120,
        4,
        110,
        141,
        133,
        1,
        179,
        28,
        182,
        132,
        39,
        137,
        1,
        83,
        102,
        5,
        90,
        95,
        95,
        0,
        0,
        199,
        10,
        0,
        1,
        4,
        67,
        0,
        198,
        48,
        36,
        3
      ],
      "weeklyWasted": [
        102,
        5,
        90,
        95,
        95,
        0,
        0,
        199,
        10,
        0,
        1,
        4,
        67,
        0,
        198,
        48,
        36,
        3,
        82,
        31,
        16,
        2,
        87,
        98,
        20,
        35,
        0,
        60,
        99,
        32,
        99,
        99,
        52,
        72,
        26,
        60,
        1,
        60,
        49,
        99,
        25,
        56,
        65,
        34,
        54,
        46,
        28,
        14,
        2,
        8,
        43,
        98,
        0,
        96,
        87,
        80,
        99,
        1,
        9,
        62,
        99,
        99,
        51,
        39,
        38,
        13,
        5,
        99,
        97,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        199,
        121,
        52,
        28,
        141,
        116,
        54,
        154,
        2,
        41,
        81,
        2,
        129,
        0,
        199,
        151,
        192,
        27,
        3,
        2,
        1,
        96,
        21,
        125,
        159,
        51,
        103,
        190,
        95,
        13,
        117,
        179,
        61,
        28,
        75,
        199,
        72,
        199,
        76,
        5,
        88,
        160,
        118,
        30,
        1,
        62,
        81,
        45,
        146,
        2,
        14,
        12
      ],
      "weeklyWasted": [
        75,
        199,
        72,
        199,
        76,
        5,
        88,
        160,
        118,
        30,
        1,
        62,
        81,
        45,
        146,
        2,
        14,
        12,
        99,
        98,
        49,
        2,
        92,
        95,
        99,
        37,
        99,
        66,
        99,
        36,
        99,
        3,
        97,
        98,
        0,
        65,
        80,
        81,
        84,
        19,
        0,
        23,
        97,
        56,
        82,
        97,
        99,
        10,
        2,
        95,
        1,
        85,
        41,
        61,
        87,
        30,
        7,
        37,
        0,
        0,
        99,
        76,
        99,
        50,
        91,
        25,
        46,
        59,
        4,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        199,
        2,
        96,
        98,
        56,
        1,
        57,
        71,
        86,
        91,
        96,
        21,
        46,
        195,
        194,
        198,
        140,
        49,
        85,
        117,
        126,
        57,
        2,
        47,
        187,
        103,
        83,
        29,
        31,
        99,
        62,
        163,
        101,
        199,
        136,
        146,
        121,
        55,
        175,
        71,
        0,
        88,
        0,
        84,
        199,
        153,
        47,
        62,
        126,
        169,
        75,
        3
      ],
      "weeklyWasted": [
        136,
        146,
        121,
        55,
        175,
        71,
        0,
        88,
        0,
        84,
        199,
        153,
        47,
        62,
        126,
        169,
        75,
        3,
        18,
        1,
        19,
        2,
        79,
        98,
        69,
        0,
        89,
        0,
        91,
        62,
        21,
        59,
        83,
        5,
        35,
        99,
        1,
        0,
        28,
        64,
        0,
        70,
        21,
        99,
        6,
        1,
        2,
        40,
        78,
        35,
        43,
        98,
        0,
        33,
        99,
        62,
        95,
        99,
        0,
        12,
        89,
        1,
        23,
        61,
        72,
        98,
        99,
        15,
        65,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        7,
        77,
        89,
        5,
        45,
        1,
        101,
        3,
        166,
        1,
        139,
        101,
        43,
        81,
        199,
        85,
        121,
        190,
        96,
        38,
        11,
        4,
        176,
        183,
        3,
        101,
        3,
        39,
        199,
        156,
        198,
        0,
        118,
        111,
        196,
        75,
        124,
        12,
        119,
        49,
        0,
        191,
        37,
        39,
        22,
        29,
        0,
        0,
        146,
        41,
        150,
        196
      ],
      "weeklyWasted": [
        196,
        75,
        124,
        12,
        119,
        49,
        0,
        191,
        37,
        39,
        22,
        29,
        0,
        0,
        146,
        41,
        150,
        196,
        53,
        36,
        26,
        7,
        98,
        64,
        71,
        22,
        0,
        38,
        99,
        33,
        10,
        28,
        60,
        58,
        98,
        99,
        9,
        84,
        40,
        38,
        15,
        11,
        0,
        24,
        62,
        72,
        49,
        1,
        23,
        67,
        70,
        98,
        39,
        34,
        85,
        90,
        92,
        1,
        40,
        30,
        99,
        99,
        15,
        39,
        12,
        20,
        93,
        99,
        99,
        29
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        199,
        109,
        181,
        70,
        117,
        148,
        131,
        3,
        61,
        81,
        199,
        54,
        169,
        110,
        199,
        130,
        167,
        199,
        34,
        122,
        197,
        4,
        83,
        30,
        39,
        64,
        197,
        109,
        100,
        131,
        102,
        148,
        179,
        141,
        125,
        20,
        25,
        136,
        134,
        67,
        0,
        100,
        69,
        16,
        1,
        29,
        91,
        160,
        173,
        4,
        39,
        58
      ],
      "weeklyWasted": [
        125,
        20,
        25,
        136,
        134,
        67,
        0,
        100,
        69,
        16,
        1,
        29,
        91,
        160,
        173,
        4,
        39,
        58,
        72,
        13,
        0,
        2,
        69,
        65,
        54,
        49,
        99,
        60,
        25,
        10,
        6,
        83,
        38,
        44,
        11,
        14,
        99,
        80,
        43,
        65,
        9,
        0,
        17,
        99,
        50,
        52,
        58,
        1,
        42,
        4,
        43,
        98,
        48,
        4,
        42,
        34,
        4,
        1,
        90,
        78,
        57,
        86,
        99,
        81,
        0,
        98,
        42,
        89,
        6,
        70
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyUsage": [
        199,
        2,
        121,
        5,
        0,
        148,
        58,
        18,
        98,
        83,
        193,
        76,
        132,
        126,
        68,
        101,
        175,
        72,
        117,
        2,
        197,
        123,
        12,
        1,
        118,
        198,
        176,
        78,
        26,
        11,
        75,
        69,
        1,
        4,
        107,
        0,
        158,
        54,
        129,
        132,
        17,
        78,
        0,
        27,
        199,
        176,
        0,
        0,
        168,
        74,
        155,
        36
      ],
      "weeklyWasted": [
        107,
        0,
        158,
        54,
        129,
        132,
        17,
        78,
        0,
        27,
        199,
        176,
        0,
        0,
        168,
        74,
        155,
        36,
        43,
        1,
        14,
        41,
        84,
        1,
        53,
        39,
        81,
        0,
        99,
        71,
        28,
        95,
        0,
        2,
        37,
        99,
        1,
        27,
        75,
        29,
        97,
        15,
        79,
        24,
        65,
        1,
        63,
        44,
        27,
        97,
        46,
        69,
        7,
        99,
        99,
        86,
        99,
        99,
        2,
        0,
        57,
        59,
        52,
        55,
        27,
        70,
        14,
        35,
        27,
        1
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        99,
        80,
        52,
        17,
        86,
        1,
        3,
        83,
        25,
        1,
        13,
        141,
        70,
        0,
        181,
        178,
        192,
        74,
        119,
        2,
        96,
        40,
        133,
        130,
        197,
        182,
        124,
        1,
        61,
        101,
        0,
        34,
        1,
        82,
        198,
        199,
        70,
        85,
        180,
        48,
        0,
        2,
        69,
        176,
        48,
        102,
        23,
        79,
        198,
        199,
        65,
        196
      ],
      "weeklyWasted": [
        198,
        199,
        70,
        85,
        180,
        48,
        0,
        2,
        69,
        176,
        48,
        102,
        23,
        79,
        198,
        199,
        65,
        196,
        63,
        45,
        41,
        18,
        56,
        38,
        99,
        39,
        61,
        80,
        99,
        89,
        10,
        38,
        52,
        27,
        62,
        44,
        1,
        63,
        2,
        68,
        97,
        31,
        99,
        99,
        74,
        83,
        39,
        1,
        2,
        20,
        41,
        91,
        99,
        63,
        14,
        97,
        70,
        99,
        58,
        0,
        99,
        76,
        99,
        99,
        99,
        98,
        99,
        45,
        30,
        51
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        125,
        21,
        61,
        114,
        51,
        144,
        21,
        187,
        74,
        45,
        176,
        2,
        169,
        100,
        125,
        105,
        45,
        175,
        96,
        66,
        151,
        105,
        33,
        195,
        194,
        161,
        174,
        36,
        130,
        80,
        31,
        37,
        158,
        182,
        107,
        66,
        112,
        194,
        115,
        171,
        0,
        174,
        128,
        0,
        134,
        113,
        67,
        172,
        198,
        5,
        28,
        163
      ],
      "weeklyWasted": [
        107,
        66,
        112,
        194,
        115,
        171,
        0,
        174,
        128,
        0,
        134,
        113,
        67,
        172,
        198,
        5,
        28,
        163,
        53,
        25,
        11,
        24,
        69,
        35,
        99,
        40,
        99,
        84,
        29,
        22,
        94,
        27,
        5,
        16,
        59,
        69,
        9,
        81,
        89,
        46,
        8,
        28,
        35,
        91,
        41,
        32,
        2,
        27,
        3,
        97,
        39,
        98,
        28,
        0,
        99,
        33,
        68,
        78,
        0,
        37,
        99,
        99,
        7,
        3,
        7,
        98,
        29,
        71,
        99,
        1
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyUsage": [
        142,
        2,
        0,
        78,
        6,
        73,
        93,
        58,
        191,
        117,
        52,
        45,
        28,
        0,
        1,
        136,
        192,
        118,
        197,
        38,
        1,
        4,
        70,
        153,
        3,
        124,
        192,
        83,
        136,
        25,
        104,
        196,
        124,
        76,
        4,
        67,
        55,
        73,
        68,
        49,
        5,
        13,
        111,
        0,
        1,
        82,
        0,
        198,
        198,
        141,
        121,
        120
      ],
      "weeklyWasted": [
        4,
        67,
        55,
        73,
        68,
        49,
        5,
        13,
        111,
        0,
        1,
        82,
        0,
        198,
        198,
        141,
        121,
        120,
        99,
        3,
        0,
        48,
        4,
        98,
        38,
        0,
        63,
        61,
        63,
        77,
        43,
        99,
        37,
        43,
        77,
        82,
        77,
        0,
        57,
        36,
        29,
        99,
        14,
        51,
        98,
        8,
        96,
        1,
        17,
        12,
        91,
        74,
        99,
        19,
        66,
        35,
        99,
        99,
        99,
        0,
        99,
        56,
        0,
        99,
        56,
        98,
        68,
        1,
        13,
        41
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        199,
        83,
        102,
        88,
        0,
        115,
        12,
        79,
        199,
        1,
        199,
        150,
        129,
        40,
        199,
        9,
        115,
        57,
        129,
        83,
        7,
        4,
        197,
        164,
        112,
        198,
        159,
        81,
        199,
        52,
        199,
        0,
        45,
        186,
        162,
        156,
        41,
        199,
        199,
        33,
        54,
        199,
        199,
        55,
        1,
        196,
        0,
        145,
        114,
        63,
        62,
        88
      ],
      "weeklyWasted": [
        162,
        156,
        41,
        199,
        199,
        33,
        54,
        199,
        199,
        55,
        1,
        196,
        0,
        145,
        114,
        63,
        62,
        88,
        99,
        38,
        95,
        9,
        79,
        86,
        52,
        54,
        99,
        85,
        85,
        99,
        51,
        99,
        77,
        69,
        43,
        21,
        76,
        76,
        62,
        99,
        0,
        0,
        0,
        18,
        64,
        56,
        36,
        25,
        62,
        62,
        56,
        98,
        0,
        46,
        99,
        54,
        0,
        87,
        29,
        0,
        52,
        99,
        63,
        0,
        91,
        18,
        2,
        99,
        99,
        9
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        153,
        45,
        110,
        71,
        7,
        55,
        194,
        3,
        138,
        67,
        199,
        87,
        198,
        174,
        154,
        76,
        192,
        109,
        88,
        2,
        174,
        198,
        59,
        71,
        23,
        61,
        178,
        41,
        95,
        53,
        29,
        39,
        98,
        169,
        70,
        199,
        78,
        107,
        110,
        110,
        76,
        199,
        105,
        94,
        76,
        171,
        72,
        47,
        198,
        81,
        2,
        132
      ],
      "weeklyWasted": [
        70,
        199,
        78,
        107,
        110,
        110,
        76,
        199,
        105,
        94,
        76,
        171,
        72,
        47,
        198,
        81,
        2,
        132,
        25,
        33,
        73,
        43,
        76,
        26,
        60,
        29,
        54,
        12,
        53,
        1,
        70,
        54,
        64,
        39,
        0,
        99,
        17,
        35,
        37,
        76,
        49,
        24,
        94,
        1,
        20,
        97,
        62,
        24,
        2,
        74,
        26,
        98,
        6,
        65,
        1,
        99,
        60,
        18,
        42,
        0,
        76,
        33,
        99,
        0,
        66,
        42,
        28,
        99,
        16,
        61
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        132,
        135,
        106,
        5,
        198,
        1,
        177,
        134,
        152,
        11,
        199,
        2,
        179,
        177,
        97,
        163,
        190,
        42,
        154,
        30,
        67,
        149,
        84,
        105,
        124,
        198,
        150,
        1,
        69,
        87,
        74,
        0,
        1,
        23,
        191,
        199,
        18,
        113,
        55,
        16,
        84,
        96,
        150,
        0,
        7,
        196,
        0,
        1,
        150,
        110,
        116,
        151
      ],
      "weeklyWasted": [
        191,
        199,
        18,
        113,
        55,
        16,
        84,
        96,
        150,
        0,
        7,
        196,
        0,
        1,
        150,
        110,
        116,
        151,
        75,
        1,
        49,
        37,
        98,
        98,
        49,
        34,
        99,
        78,
        99,
        54,
        84,
        78,
        51,
        46,
        58,
        99,
        47,
        74,
        49,
        0,
        0,
        0,
        33,
        74,
        98,
        21,
        99,
        61,
        2,
        63,
        23,
        85,
        94,
        0,
        85,
        83,
        46,
        75,
        8,
        34,
        39,
        26,
        99,
        93,
        32,
        98,
        5,
        31,
        99,
        19
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        199,
        2,
        191,
        179,
        153,
        134,
        73,
        72,
        149,
        1,
        199,
        98,
        198,
        49,
        1,
        3,
        189,
        116,
        96,
        28,
        127,
        4,
        54,
        14,
        151,
        68,
        85,
        47,
        199,
        118,
        199,
        0,
        1,
        82,
        155,
        0,
        198,
        104,
        150,
        0,
        88,
        61,
        0,
        99,
        1,
        111,
        9,
        138,
        198,
        18,
        17,
        15
      ],
      "weeklyWasted": [
        155,
        0,
        198,
        104,
        150,
        0,
        88,
        61,
        0,
        99,
        1,
        111,
        9,
        138,
        198,
        18,
        17,
        15,
        40,
        8,
        69,
        31,
        98,
        77,
        0,
        53,
        2,
        4,
        67,
        99,
        41,
        73,
        54,
        72,
        23,
        99,
        49,
        56,
        15,
        0,
        0,
        35,
        56,
        38,
        39,
        54,
        89,
        96,
        8,
        97,
        88,
        58,
        99,
        78,
        88,
        99,
        23,
        40,
        0,
        84,
        15,
        68,
        43,
        3,
        99,
        98,
        19,
        87,
        0,
        55
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        89,
        93,
        148,
        154,
        79,
        2,
        39,
        35,
        69,
        110,
        32,
        101,
        75,
        0,
        165,
        99,
        192,
        199,
        49,
        5,
        124,
        154,
        35,
        104,
        3,
        62,
        154,
        77,
        99,
        64,
        0,
        196,
        1,
        131,
        83,
        0,
        136,
        46,
        132,
        0,
        115,
        13,
        139,
        0,
        1,
        155,
        195,
        152,
        198,
        98,
        2,
        120
      ],
      "weeklyWasted": [
        83,
        0,
        136,
        46,
        132,
        0,
        115,
        13,
        139,
        0,
        1,
        155,
        195,
        152,
        198,
        98,
        2,
        120,
        62,
        15,
        17,
        24,
        61,
        76,
        96,
        13,
        43,
        74,
        50,
        56,
        1,
        74,
        8,
        17,
        66,
        45,
        13,
        12,
        99,
        48,
        0,
        38,
        63,
        88,
        50,
        52,
        2,
        17,
        2,
        64,
        1,
        81,
        99,
        82,
        88,
        58,
        99,
        99,
        46,
        31,
        78,
        81,
        14,
        74,
        99,
        98,
        99,
        57,
        0,
        24
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyUsage": [
        199,
        70,
        106,
        26,
        0,
        62,
        194,
        197,
        2,
        7,
        199,
        138,
        198,
        0,
        195,
        65,
        126,
        195,
        170,
        159,
        197,
        4,
        2,
        1,
        131,
        1,
        102,
        37,
        13,
        156,
        199,
        44,
        70,
        143,
        4,
        0,
        198,
        199,
        161,
        50,
        70,
        149,
        0,
        0,
        25,
        179,
        86,
        151,
        193,
        65,
        142,
        4
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        199,
        161,
        50,
        70,
        149,
        0,
        0,
        25,
        179,
        86,
        151,
        193,
        65,
        142,
        4,
        1,
        40,
        99,
        2,
        98,
        98,
        68,
        0,
        65,
        0,
        76,
        26,
        1,
        11,
        21,
        43,
        0,
        72,
        20,
        87,
        38,
        83,
        97,
        0,
        51,
        99,
        73,
        1,
        89,
        28,
        2,
        73,
        1,
        71,
        42,
        0,
        75,
        99,
        4,
        46,
        22,
        0,
        99,
        47,
        53,
        0,
        99,
        98,
        90,
        61,
        40,
        1
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        199,
        84,
        87,
        5,
        25,
        56,
        41,
        69,
        41,
        117,
        133,
        24,
        50,
        86,
        2,
        49,
        88,
        89,
        36,
        52,
        191,
        198,
        2,
        135,
        43,
        1,
        35,
        186,
        12,
        61,
        14,
        65,
        1,
        37,
        198,
        175,
        26,
        47,
        63,
        105,
        146,
        21,
        31,
        0,
        157,
        158,
        0,
        132,
        141,
        199,
        68,
        84
      ],
      "weeklyWasted": [
        198,
        175,
        26,
        47,
        63,
        105,
        146,
        21,
        31,
        0,
        157,
        158,
        0,
        132,
        141,
        199,
        68,
        84,
        40,
        1,
        13,
        12,
        50,
        98,
        76,
        5,
        96,
        11,
        71,
        74,
        40,
        99,
        45,
        38,
        31,
        71,
        29,
        0,
        48,
        0,
        0,
        0,
        81,
        71,
        0,
        60,
        47,
        9,
        2,
        96,
        59,
        98,
        99,
        0,
        26,
        95,
        84,
        43,
        99,
        0,
        25,
        13,
        99,
        43,
        92,
        35,
        11,
        25,
        28,
        61
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        199,
        2,
        40,
        5,
        198,
        21,
        99,
        94,
        148,
        65,
        136,
        37,
        171,
        129,
        199,
        167,
        152,
        199,
        103,
        84,
        102,
        120,
        34,
        152,
        76,
        164,
        197,
        1,
        0,
        127,
        19,
        0,
        126,
        11,
        29,
        63,
        70,
        80,
        46,
        69,
        0,
        0,
        0,
        0,
        1,
        164,
        43,
        42,
        26,
        2,
        134,
        3
      ],
      "weeklyWasted": [
        29,
        63,
        70,
        80,
        46,
        69,
        0,
        0,
        0,
        0,
        1,
        164,
        43,
        42,
        26,
        2,
        134,
        3,
        64,
        19,
        41,
        46,
        83,
        39,
        93,
        0,
        33,
        12,
        91,
        84,
        63,
        72,
        0,
        1,
        98,
        40,
        14,
        79,
        54,
        52,
        16,
        13,
        92,
        70,
        96,
        15,
        33,
        19,
        26,
        94,
        68,
        85,
        99,
        23,
        99,
        15,
        71,
        99,
        0,
        0,
        58,
        55,
        99,
        37,
        18,
        56,
        10,
        55,
        99,
        15
      ]
    },
    {
      "id": "4FF22342",
      "weeklyUsage": [
        122,
        199,
        112,
        11,
        198,
        1,
        3,
        60,
        2,
        90,
        199,
        162,
        159,
        131,
        55,
        85,
        138,
        27,
        27,
        197,
        141,
        75,
        98,
        86,
        3,
        3,
        135,
        144,
        38,
        198,
        131,
        107,
        1,
        4,
        37,
        41,
        168,
        174,
        10,
        199,
        29,
        126,
        88,
        30,
        22,
        4,
        61,
        0,
        198,
        74,
        67,
        46
      ],
      "weeklyWasted": [
        37,
        41,
        168,
        174,
        10,
        199,
        29,
        126,
        88,
        30,
        22,
        4,
        61,
        0,
        198,
        74,
        67,
        46,
        99,
        44,
        99,
        8,
        98,
        37,
        0,
        39,
        99,
        78,
        60,
        54,
        73,
        97,
        29,
        20,
        38,
        40,
        99,
        86,
        87,
        0,
        38,
        0,
        21,
        37,
        98,
        97,
        2,
        55,
        2,
        70,
        43,
        98,
        60,
        0,
        99,
        99,
        23,
        9,
        30,
        38,
        70,
        82,
        99,
        31,
        99,
        98,
        10,
        32,
        99,
        1
      ]
    },
    {
      "id": "3D26994F",
      "weeklyUsage": [
        93,
        127,
        55,
        23,
        0,
        1,
        66,
        53,
        90,
        1,
        93,
        93,
        115,
        0,
        167,
        118,
        192,
        164,
        197,
        57,
        1,
        198,
        36,
        1,
        3,
        5,
        129,
        81,
        105,
        0,
        1,
        0,
        1,
        187,
        109,
        199,
        0,
        45,
        49,
        68,
        54,
        0,
        100,
        30,
        1,
        156,
        0,
        58,
        162,
        117,
        79,
        3
      ],
      "weeklyWasted": [
        109,
        199,
        0,
        45,
        49,
        68,
        54,
        0,
        100,
        30,
        1,
        156,
        0,
        58,
        162,
        117,
        79,
        3,
        1,
        63,
        0,
        2,
        3,
        98,
        99,
        10,
        99,
        0,
        99,
        45,
        46,
        94,
        56,
        16,
        33,
        99,
        5,
        61,
        53,
        3,
        0,
        12,
        0,
        67,
        0,
        13,
        33,
        63,
        2,
        82,
        1,
        98,
        99,
        0,
        99,
        95,
        0,
        53,
        99,
        40,
        99,
        43,
        66,
        99,
        28,
        98,
        25,
        92,
        96,
        63
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        177,
        157,
        57,
        5,
        60,
        20,
        194,
        102,
        96,
        29,
        186,
        43,
        198,
        26,
        6,
        115,
        112,
        199,
        40,
        185,
        158,
        4,
        116,
        70,
        3,
        68,
        83,
        1,
        80,
        194,
        115,
        61,
        9,
        4,
        198,
        20,
        198,
        5,
        86,
        87,
        58,
        179,
        0,
        63,
        119,
        125,
        0,
        0,
        198,
        2,
        100,
        3
      ],
      "weeklyWasted": [
        198,
        20,
        198,
        5,
        86,
        87,
        58,
        179,
        0,
        63,
        119,
        125,
        0,
        0,
        198,
        2,
        100,
        3,
        53,
        27,
        99,
        2,
        96,
        2,
        66,
        0,
        5,
        41,
        99,
        80,
        99,
        99,
        0,
        88,
        22,
        52,
        23,
        84,
        2,
        61,
        8,
        0,
        44,
        27,
        98,
        16,
        84,
        35,
        2,
        69,
        54,
        94,
        49,
        68,
        36,
        99,
        41,
        8,
        72,
        0,
        99,
        84,
        52,
        13,
        99,
        98,
        17,
        99,
        36,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        20,
        84,
        67,
        121,
        48,
        197,
        3,
        39,
        2,
        113,
        199,
        132,
        100,
        199,
        199,
        52,
        163,
        80,
        148,
        42,
        173,
        6,
        4,
        184,
        92,
        42,
        164,
        40,
        177,
        66,
        132,
        127,
        93,
        37,
        4,
        0,
        184,
        130,
        150,
        0,
        0,
        12,
        9,
        150,
        69,
        4,
        41,
        95,
        198,
        81,
        103,
        89
      ],
      "weeklyWasted": [
        4,
        0,
        184,
        130,
        150,
        0,
        0,
        12,
        9,
        150,
        69,
        4,
        41,
        95,
        198,
        81,
        103,
        89,
        3,
        98,
        29,
        5,
        56,
        65,
        56,
        0,
        85,
        0,
        20,
        63,
        46,
        3,
        74,
        24,
        3,
        64,
        59,
        0,
        2,
        0,
        62,
        0,
        55,
        99,
        72,
        71,
        68,
        49,
        12,
        7,
        1,
        98,
        15,
        86,
        19,
        54,
        99,
        1,
        36,
        0,
        88,
        67,
        99,
        99,
        99,
        98,
        0,
        99,
        58,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        103,
        63,
        190,
        195,
        59,
        2,
        194,
        171,
        27,
        72,
        66,
        2,
        14,
        43,
        134,
        96,
        192,
        199,
        42,
        21,
        197,
        198,
        65,
        137,
        92,
        1,
        197,
        109,
        175,
        147,
        199,
        118,
        81,
        199,
        153,
        162,
        41,
        39,
        139,
        55,
        0,
        89,
        36,
        96,
        106,
        57,
        99,
        67,
        198,
        173,
        157,
        3
      ],
      "weeklyWasted": [
        153,
        162,
        41,
        39,
        139,
        55,
        0,
        89,
        36,
        96,
        106,
        57,
        99,
        67,
        198,
        173,
        157,
        3,
        26,
        52,
        90,
        18,
        75,
        65,
        81,
        66,
        99,
        4,
        82,
        69,
        32,
        77,
        39,
        4,
        98,
        79,
        12,
        93,
        76,
        0,
        31,
        0,
        13,
        18,
        46,
        24,
        40,
        55,
        2,
        97,
        28,
        39,
        99,
        0,
        80,
        99,
        49,
        45,
        0,
        0,
        84,
        61,
        46,
        99,
        58,
        69,
        57,
        99,
        42,
        99
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        7,
        71,
        61,
        170,
        170,
        64,
        194,
        65,
        195,
        1,
        191,
        126,
        145,
        89,
        67,
        3,
        0,
        180,
        197,
        154,
        4,
        60,
        7,
        59,
        3,
        33,
        156,
        125,
        67,
        54,
        199,
        0,
        197,
        111,
        159,
        0,
        160,
        199,
        116,
        95,
        0,
        0,
        0,
        48,
        4,
        4,
        0,
        80,
        96,
        105,
        174,
        111
      ],
      "weeklyWasted": [
        159,
        0,
        160,
        199,
        116,
        95,
        0,
        0,
        0,
        48,
        4,
        4,
        0,
        80,
        96,
        105,
        174,
        111,
        22,
        1,
        22,
        37,
        98,
        74,
        17,
        20,
        37,
        91,
        47,
        99,
        75,
        99,
        43,
        1,
        17,
        84,
        66,
        20,
        54,
        0,
        31,
        99,
        0,
        28,
        79,
        84,
        99,
        21,
        2,
        80,
        59,
        98,
        99,
        1,
        99,
        0,
        73,
        82,
        54,
        0,
        72,
        81,
        99,
        99,
        99,
        38,
        78,
        36,
        43,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        199,
        73,
        126,
        36,
        127,
        50,
        3,
        148,
        77,
        174,
        38,
        58,
        126,
        94,
        199,
        83,
        192,
        18,
        72,
        146,
        118,
        62,
        48,
        1,
        78,
        71,
        153,
        39,
        175,
        198,
        79,
        170,
        35,
        19,
        99,
        104,
        120,
        5,
        10,
        118,
        8,
        51,
        26,
        0,
        89,
        73,
        27,
        67,
        107,
        199,
        134,
        51
      ],
      "weeklyWasted": [
        99,
        104,
        120,
        5,
        10,
        118,
        8,
        51,
        26,
        0,
        89,
        73,
        27,
        67,
        107,
        199,
        134,
        51,
        1,
        23,
        0,
        80,
        98,
        50,
        17,
        28,
        85,
        39,
        46,
        47,
        99,
        97,
        47,
        10,
        76,
        1,
        56,
        0,
        2,
        0,
        19,
        0,
        82,
        99,
        0,
        46,
        28,
        15,
        99,
        61,
        42,
        82,
        57,
        17,
        55,
        99,
        56,
        78,
        94,
        0,
        99,
        99,
        99,
        5,
        99,
        41,
        3,
        71,
        25,
        37
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        71,
        42,
        109,
        104,
        180,
        17,
        191,
        49,
        2,
        1,
        71,
        40,
        97,
        34,
        134,
        81,
        12,
        191,
        130,
        160,
        168,
        175,
        197,
        144,
        3,
        4,
        151,
        103,
        58,
        141,
        199,
        50,
        1,
        43,
        198,
        135,
        198,
        5,
        199,
        156,
        11,
        28,
        150,
        32,
        99,
        105,
        0,
        59,
        198,
        199,
        41,
        3
      ],
      "weeklyWasted": [
        198,
        135,
        198,
        5,
        199,
        156,
        11,
        28,
        150,
        32,
        99,
        105,
        0,
        59,
        198,
        199,
        41,
        3,
        68,
        42,
        39,
        2,
        66,
        91,
        41,
        0,
        63,
        9,
        99,
        31,
        9,
        44,
        0,
        54,
        37,
        78,
        1,
        99,
        36,
        22,
        0,
        0,
        0,
        99,
        11,
        74,
        2,
        51,
        28,
        35,
        35,
        60,
        84,
        38,
        55,
        39,
        52,
        92,
        28,
        53,
        17,
        99,
        99,
        67,
        99,
        98,
        99,
        30,
        99,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        9,
        77,
        33,
        40,
        0,
        78,
        85,
        82,
        199,
        113,
        84,
        31,
        45,
        85,
        199,
        134,
        133,
        161,
        12,
        34,
        197,
        198,
        53,
        195,
        14,
        63,
        135,
        178,
        199,
        0,
        81,
        99,
        127,
        163,
        142,
        0,
        0,
        22,
        173,
        78,
        7,
        37,
        38,
        119,
        107,
        69,
        0,
        23,
        192,
        184,
        176,
        3
      ],
      "weeklyWasted": [
        142,
        0,
        0,
        22,
        173,
        78,
        7,
        37,
        38,
        119,
        107,
        69,
        0,
        23,
        192,
        184,
        176,
        3,
        11,
        1,
        9,
        2,
        89,
        7,
        99,
        0,
        62,
        99,
        91,
        55,
        99,
        81,
        31,
        98,
        98,
        40,
        21,
        69,
        2,
        34,
        0,
        19,
        26,
        83,
        24,
        97,
        2,
        35,
        2,
        97,
        55,
        98,
        76,
        58,
        60,
        65,
        54,
        46,
        99,
        19,
        99,
        46,
        86,
        46,
        45,
        69,
        72,
        99,
        50,
        99
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        144,
        66,
        198,
        84,
        198,
        173,
        194,
        148,
        74,
        124,
        0,
        34,
        178,
        71,
        96,
        110,
        81,
        199,
        120,
        136,
        197,
        149,
        136,
        88,
        32,
        198,
        54,
        1,
        71,
        135,
        118,
        128,
        197,
        145,
        106,
        83,
        132,
        5,
        116,
        144,
        185,
        86,
        135,
        0,
        4,
        62,
        57,
        112,
        198,
        78,
        135,
        68
      ],
      "weeklyWasted": [
        106,
        83,
        132,
        5,
        116,
        144,
        185,
        86,
        135,
        0,
        4,
        62,
        57,
        112,
        198,
        78,
        135,
        68,
        84,
        21,
        94,
        62,
        98,
        70,
        22,
        21,
        63,
        54,
        50,
        95,
        99,
        79,
        0,
        72,
        70,
        74,
        99,
        0,
        2,
        50,
        63,
        47,
        0,
        83,
        78,
        67,
        28,
        68,
        2,
        1,
        31,
        98,
        99,
        71,
        73,
        31,
        43,
        99,
        62,
        0,
        72,
        56,
        97,
        24,
        64,
        49,
        34,
        1,
        63,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        78,
        59,
        33,
        49,
        198,
        191,
        158,
        197,
        2,
        1,
        199,
        2,
        179,
        82,
        144,
        3,
        145,
        88,
        166,
        197,
        167,
        167,
        114,
        133,
        197,
        59,
        142,
        15,
        186,
        62,
        99,
        73,
        140,
        106,
        198,
        20,
        108,
        77,
        113,
        153,
        199,
        0,
        0,
        19,
        1,
        189,
        0,
        24,
        150,
        199,
        157,
        144
      ],
      "weeklyWasted": [
        198,
        20,
        108,
        77,
        113,
        153,
        199,
        0,
        0,
        19,
        1,
        189,
        0,
        24,
        150,
        199,
        157,
        144,
        1,
        1,
        86,
        13,
        67,
        98,
        72,
        0,
        99,
        40,
        65,
        85,
        88,
        85,
        19,
        98,
        80,
        99,
        2,
        30,
        90,
        23,
        44,
        18,
        3,
        70,
        44,
        1,
        96,
        88,
        82,
        78,
        1,
        72,
        58,
        11,
        71,
        77,
        75,
        92,
        0,
        4,
        63,
        99,
        99,
        7,
        40,
        98,
        0,
        16,
        40,
        1
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        199,
        2,
        127,
        27,
        0,
        86,
        79,
        48,
        188,
        3,
        147,
        75,
        2,
        83,
        130,
        88,
        192,
        93,
        180,
        163,
        150,
        198,
        31,
        57,
        197,
        9,
        29,
        113,
        0,
        121,
        74,
        44,
        84,
        61,
        162,
        57,
        72,
        148,
        138,
        20,
        119,
        0,
        0,
        20,
        134,
        74,
        96,
        157,
        198,
        168,
        58,
        35
      ],
      "weeklyWasted": [
        162,
        57,
        72,
        148,
        138,
        20,
        119,
        0,
        0,
        20,
        134,
        74,
        96,
        157,
        198,
        168,
        58,
        35,
        27,
        1,
        15,
        55,
        65,
        65,
        67,
        15,
        72,
        27,
        50,
        26,
        61,
        99,
        2,
        12,
        98,
        45,
        37,
        22,
        9,
        58,
        23,
        0,
        17,
        66,
        28,
        20,
        45,
        1,
        37,
        11,
        98,
        46,
        99,
        33,
        78,
        62,
        80,
        75,
        61,
        14,
        68,
        61,
        36,
        64,
        97,
        98,
        73,
        70,
        42,
        81
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        92,
        68,
        73,
        191,
        90,
        197,
        194,
        57,
        187,
        72,
        0,
        181,
        125,
        0,
        160,
        28,
        192,
        46,
        147,
        176,
        1,
        47,
        77,
        1,
        142,
        198,
        117,
        64,
        148,
        142,
        77,
        0,
        197,
        4,
        26,
        77,
        103,
        84,
        10,
        129,
        5,
        31,
        24,
        198,
        69,
        165,
        119,
        123,
        197,
        199,
        91,
        3
      ],
      "weeklyWasted": [
        26,
        77,
        103,
        84,
        10,
        129,
        5,
        31,
        24,
        198,
        69,
        165,
        119,
        123,
        197,
        199,
        91,
        3,
        99,
        1,
        99,
        21,
        98,
        1,
        99,
        0,
        56,
        54,
        30,
        13,
        99,
        99,
        32,
        14,
        16,
        1,
        35,
        58,
        71,
        25,
        61,
        11,
        23,
        27,
        66,
        79,
        73,
        35,
        46,
        65,
        48,
        64,
        54,
        78,
        63,
        97,
        41,
        74,
        20,
        0,
        93,
        83,
        16,
        48,
        88,
        14,
        66,
        99,
        99,
        1
      ]
    },
    {
      "id": "F04529EA",
      "weeklyUsage": [
        172,
        98,
        54,
        58,
        54,
        112,
        65,
        3,
        124,
        1,
        53,
        63,
        31,
        72,
        94,
        70,
        191,
        101,
        124,
        125,
        2,
        4,
        179,
        90,
        3,
        123,
        145,
        32,
        199,
        62,
        116,
        0,
        1,
        189,
        4,
        118,
        111,
        5,
        19,
        199,
        0,
        133,
        60,
        59,
        1,
        156,
        42,
        2,
        198,
        2,
        7,
        88
      ],
      "weeklyWasted": [
        4,
        118,
        111,
        5,
        19,
        199,
        0,
        133,
        60,
        59,
        1,
        156,
        42,
        2,
        198,
        2,
        7,
        88,
        15,
        46,
        81,
        7,
        67,
        78,
        99,
        0,
        18,
        43,
        99,
        31,
        41,
        99,
        74,
        19,
        92,
        54,
        1,
        87,
        61,
        21,
        3,
        31,
        58,
        25,
        98,
        62,
        76,
        82,
        2,
        83,
        34,
        98,
        0,
        0,
        50,
        83,
        22,
        55,
        72,
        48,
        79,
        43,
        65,
        36,
        99,
        98,
        99,
        99,
        75,
        44
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        147,
        98,
        178,
        92,
        36,
        78,
        148,
        72,
        69,
        1,
        79,
        179,
        123,
        10,
        194,
        146,
        192,
        106,
        147,
        31,
        17,
        4,
        85,
        29,
        104,
        151,
        142,
        1,
        0,
        113,
        83,
        47,
        26,
        199,
        198,
        71,
        0,
        93,
        26,
        40,
        72,
        44,
        0,
        83,
        199,
        196,
        0,
        98,
        39,
        195,
        30,
        116
      ],
      "weeklyWasted": [
        198,
        71,
        0,
        93,
        26,
        40,
        72,
        44,
        0,
        83,
        199,
        196,
        0,
        98,
        39,
        195,
        30,
        116,
        1,
        44,
        23,
        2,
        84,
        1,
        12,
        0,
        99,
        74,
        99,
        1,
        99,
        38,
        78,
        98,
        0,
        1,
        32,
        38,
        81,
        0,
        67,
        92,
        14,
        94,
        98,
        5,
        98,
        69,
        2,
        97,
        89,
        98,
        99,
        87,
        40,
        68,
        69,
        35,
        60,
        0,
        37,
        53,
        70,
        11,
        52,
        48,
        25,
        99,
        57,
        1
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        188,
        40,
        0,
        187,
        59,
        1,
        69,
        107,
        158,
        1,
        83,
        63,
        2,
        0,
        79,
        130,
        114,
        199,
        68,
        135,
        162,
        111,
        197,
        133,
        3,
        33,
        31,
        1,
        117,
        138,
        178,
        0,
        1,
        40,
        163,
        41,
        123,
        5,
        63,
        0,
        85,
        0,
        82,
        42,
        5,
        78,
        72,
        66,
        198,
        47,
        68,
        13
      ],
      "weeklyWasted": [
        163,
        41,
        123,
        5,
        63,
        0,
        85,
        0,
        82,
        42,
        5,
        78,
        72,
        66,
        198,
        47,
        68,
        13,
        99,
        32,
        60,
        2,
        98,
        62,
        38,
        8,
        30,
        42,
        49,
        72,
        1,
        3,
        29,
        24,
        80,
        78,
        11,
        65,
        50,
        0,
        10,
        47,
        29,
        20,
        98,
        97,
        82,
        1,
        28,
        97,
        66,
        98,
        99,
        73,
        42,
        99,
        99,
        29,
        35,
        62,
        88,
        76,
        3,
        26,
        99,
        98,
        24,
        56,
        94,
        32
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        199,
        93,
        66,
        32,
        16,
        120,
        40,
        197,
        45,
        1,
        20,
        31,
        134,
        0,
        155,
        67,
        117,
        187,
        3,
        2,
        197,
        45,
        6,
        195,
        61,
        198,
        108,
        1,
        199,
        94,
        14,
        0,
        1,
        79,
        106,
        0,
        186,
        74,
        177,
        0,
        0,
        109,
        11,
        29,
        69,
        196,
        0,
        21,
        198,
        199,
        81,
        121
      ],
      "weeklyWasted": [
        106,
        0,
        186,
        74,
        177,
        0,
        0,
        109,
        11,
        29,
        69,
        196,
        0,
        21,
        198,
        199,
        81,
        121,
        35,
        57,
        98,
        2,
        98,
        1,
        42,
        0,
        61,
        68,
        99,
        37,
        99,
        3,
        97,
        5,
        22,
        50,
        78,
        79,
        32,
        10,
        13,
        0,
        99,
        73,
        98,
        58,
        99,
        30,
        42,
        97,
        45,
        80,
        1,
        79,
        60,
        49,
        72,
        56,
        10,
        98,
        78,
        40,
        88,
        0,
        99,
        98,
        0,
        35,
        18,
        10
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        144,
        2,
        104,
        55,
        123,
        87,
        146,
        3,
        2,
        83,
        168,
        81,
        25,
        34,
        148,
        174,
        24,
        199,
        58,
        141,
        31,
        63,
        193,
        112,
        173,
        153,
        176,
        59,
        119,
        198,
        19,
        196,
        1,
        85,
        92,
        0,
        171,
        58,
        82,
        92,
        99,
        0,
        0,
        0,
        1,
        196,
        79,
        75,
        167,
        2,
        143,
        3
      ],
      "weeklyWasted": [
        92,
        0,
        171,
        58,
        82,
        92,
        99,
        0,
        0,
        0,
        1,
        196,
        79,
        75,
        167,
        2,
        143,
        3,
        48,
        1,
        44,
        41,
        87,
        70,
        0,
        33,
        4,
        44,
        74,
        99,
        56,
        99,
        91,
        98,
        96,
        73,
        55,
        17,
        57,
        0,
        6,
        16,
        42,
        16,
        46,
        69,
        99,
        83,
        2,
        97,
        18,
        92,
        51,
        75,
        57,
        4,
        18,
        99,
        0,
        48,
        0,
        99,
        43,
        70,
        60,
        98,
        69,
        65,
        45,
        36
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        89,
        69,
        130,
        101,
        59,
        157,
        194,
        3,
        199,
        1,
        92,
        9,
        89,
        0,
        120,
        83,
        192,
        157,
        3,
        2,
        127,
        100,
        93,
        66,
        195,
        1,
        38,
        157,
        0,
        0,
        0,
        128,
        162,
        78,
        108,
        0,
        55,
        60,
        10,
        125,
        82,
        57,
        59,
        0,
        86,
        139,
        115,
        67,
        198,
        149,
        2,
        173
      ],
      "weeklyWasted": [
        108,
        0,
        55,
        60,
        10,
        125,
        82,
        57,
        59,
        0,
        86,
        139,
        115,
        67,
        198,
        149,
        2,
        173,
        25,
        1,
        40,
        69,
        21,
        30,
        99,
        46,
        61,
        25,
        99,
        99,
        1,
        50,
        15,
        10,
        55,
        73,
        3,
        0,
        75,
        0,
        54,
        64,
        33,
        45,
        72,
        62,
        21,
        51,
        2,
        74,
        42,
        81,
        89,
        61,
        62,
        35,
        66,
        19,
        23,
        32,
        77,
        71,
        91,
        27,
        51,
        65,
        0,
        30,
        26,
        1
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        199,
        102,
        149,
        106,
        179,
        110,
        58,
        75,
        4,
        1,
        199,
        41,
        198,
        107,
        170,
        3,
        188,
        179,
        158,
        143,
        37,
        91,
        64,
        162,
        162,
        198,
        160,
        101,
        145,
        128,
        199,
        124,
        122,
        150,
        4,
        22,
        63,
        52,
        117,
        138,
        152,
        62,
        149,
        10,
        1,
        36,
        195,
        198,
        172,
        20,
        26,
        91
      ],
      "weeklyWasted": [
        4,
        22,
        63,
        52,
        117,
        138,
        152,
        62,
        149,
        10,
        1,
        36,
        195,
        198,
        172,
        20,
        26,
        91,
        1,
        1,
        62,
        4,
        81,
        47,
        99,
        0,
        55,
        38,
        52,
        30,
        23,
        32,
        85,
        98,
        21,
        99,
        8,
        71,
        85,
        42,
        0,
        11,
        36,
        75,
        48,
        1,
        52,
        1,
        35,
        83,
        37,
        36,
        70,
        12,
        99,
        67,
        3,
        40,
        49,
        17,
        60,
        46,
        52,
        36,
        95,
        98,
        0,
        38,
        53,
        1
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyUsage": [
        148,
        76,
        39,
        5,
        47,
        9,
        141,
        197,
        95,
        1,
        131,
        2,
        198,
        75,
        97,
        3,
        105,
        56,
        185,
        2,
        106,
        44,
        121,
        99,
        147,
        156,
        182,
        38,
        199,
        100,
        98,
        69,
        20,
        54,
        104,
        199,
        198,
        15,
        10,
        0,
        106,
        129,
        28,
        55,
        1,
        4,
        0,
        0,
        198,
        96,
        128,
        54
      ],
      "weeklyWasted": [
        104,
        199,
        198,
        15,
        10,
        0,
        106,
        129,
        28,
        55,
        1,
        4,
        0,
        0,
        198,
        96,
        128,
        54,
        25,
        8,
        28,
        54,
        89,
        1,
        74,
        38,
        38,
        0,
        12,
        68,
        60,
        67,
        33,
        2,
        45,
        1,
        23,
        75,
        44,
        0,
        56,
        40,
        0,
        5,
        64,
        31,
        93,
        1,
        2,
        64,
        30,
        81,
        12,
        39,
        66,
        85,
        99,
        70,
        0,
        61,
        93,
        41,
        81,
        46,
        47,
        76,
        94,
        80,
        21,
        7
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        162,
        49,
        41,
        147,
        33,
        107,
        134,
        67,
        103,
        57,
        138,
        197,
        185,
        69,
        140,
        115,
        192,
        160,
        132,
        97,
        141,
        4,
        177,
        159,
        3,
        123,
        57,
        134,
        172,
        105,
        2,
        0,
        197,
        125,
        86,
        0,
        128,
        103,
        10,
        91,
        110,
        39,
        0,
        172,
        58,
        4,
        17,
        52,
        198,
        147,
        135,
        4
      ],
      "weeklyWasted": [
        86,
        0,
        128,
        103,
        10,
        91,
        110,
        39,
        0,
        172,
        58,
        4,
        17,
        52,
        198,
        147,
        135,
        4,
        54,
        67,
        60,
        22,
        98,
        56,
        41,
        13,
        98,
        55,
        70,
        14,
        68,
        99,
        0,
        78,
        49,
        99,
        31,
        0,
        2,
        26,
        15,
        84,
        37,
        91,
        26,
        97,
        79,
        37,
        2,
        85,
        99,
        74,
        99,
        51,
        86,
        26,
        99,
        36,
        20,
        42,
        96,
        72,
        42,
        25,
        99,
        90,
        97,
        83,
        79,
        1
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        7,
        68,
        26,
        105,
        45,
        36,
        122,
        64,
        199,
        67,
        199,
        179,
        141,
        189,
        149,
        109,
        0,
        98,
        197,
        33,
        106,
        198,
        10,
        143,
        12,
        75,
        91,
        1,
        43,
        0,
        27,
        8,
        197,
        27,
        109,
        17,
        11,
        5,
        33,
        66,
        199,
        108,
        39,
        36,
        32,
        196,
        129,
        53,
        23,
        199,
        152,
        106
      ],
      "weeklyWasted": [
        109,
        17,
        11,
        5,
        33,
        66,
        199,
        108,
        39,
        36,
        32,
        196,
        129,
        53,
        23,
        199,
        152,
        106,
        1,
        1,
        0,
        58,
        58,
        83,
        32,
        0,
        99,
        0,
        99,
        69,
        99,
        78,
        1,
        19,
        39,
        42,
        9,
        42,
        39,
        0,
        0,
        85,
        0,
        85,
        0,
        67,
        63,
        5,
        2,
        97,
        60,
        98,
        78,
        0,
        66,
        99,
        85,
        31,
        96,
        0,
        99,
        92,
        91,
        53,
        87,
        63,
        97,
        99,
        73,
        16
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyUsage": [
        199,
        52,
        0,
        122,
        121,
        34,
        158,
        3,
        57,
        1,
        65,
        42,
        61,
        123,
        119,
        95,
        158,
        59,
        38,
        197,
        138,
        132,
        2,
        57,
        3,
        67,
        197,
        73,
        51,
        198,
        71,
        113,
        40,
        51,
        4,
        0,
        198,
        41,
        199,
        153,
        148,
        133,
        119,
        74,
        195,
        196,
        116,
        52,
        154,
        74,
        2,
        93
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        41,
        199,
        153,
        148,
        133,
        119,
        74,
        195,
        196,
        116,
        52,
        154,
        74,
        2,
        93,
        2,
        1,
        0,
        50,
        73,
        98,
        8,
        0,
        0,
        32,
        51,
        36,
        48,
        74,
        0,
        7,
        8,
        14,
        1,
        1,
        87,
        17,
        91,
        50,
        67,
        99,
        52,
        52,
        79,
        26,
        2,
        73,
        47,
        78,
        98,
        47,
        99,
        87,
        99,
        99,
        56,
        42,
        62,
        49,
        92,
        43,
        55,
        98,
        69,
        33,
        52,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        133,
        28,
        87,
        25,
        134,
        1,
        145,
        3,
        161,
        1,
        199,
        59,
        184,
        46,
        199,
        3,
        135,
        61,
        72,
        65,
        19,
        101,
        158,
        195,
        197,
        123,
        169,
        1,
        199,
        36,
        93,
        106,
        85,
        147,
        51,
        0,
        174,
        94,
        149,
        199,
        0,
        30,
        50,
        0,
        1,
        94,
        85,
        0,
        198,
        199,
        92,
        177
      ],
      "weeklyWasted": [
        51,
        0,
        174,
        94,
        149,
        199,
        0,
        30,
        50,
        0,
        1,
        94,
        85,
        0,
        198,
        199,
        92,
        177,
        64,
        5,
        33,
        2,
        88,
        66,
        24,
        60,
        2,
        39,
        99,
        86,
        23,
        45,
        52,
        1,
        33,
        99,
        1,
        1,
        84,
        99,
        0,
        67,
        0,
        45,
        26,
        44,
        2,
        1,
        31,
        45,
        99,
        98,
        65,
        59,
        76,
        39,
        99,
        58,
        0,
        82,
        52,
        66,
        98,
        71,
        95,
        61,
        47,
        99,
        0,
        58
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        82,
        2,
        67,
        5,
        0,
        178,
        3,
        3,
        83,
        99,
        163,
        36,
        74,
        117,
        25,
        132,
        192,
        127,
        116,
        103,
        126,
        30,
        54,
        1,
        3,
        1,
        86,
        24,
        87,
        137,
        104,
        0,
        141,
        130,
        28,
        0,
        70,
        177,
        194,
        199,
        0,
        0,
        0,
        59,
        1,
        145,
        0,
        148,
        198,
        82,
        90,
        152
      ],
      "weeklyWasted": [
        28,
        0,
        70,
        177,
        194,
        199,
        0,
        0,
        0,
        59,
        1,
        145,
        0,
        148,
        198,
        82,
        90,
        152,
        61,
        75,
        83,
        2,
        98,
        58,
        55,
        40,
        68,
        59,
        99,
        74,
        40,
        88,
        51,
        31,
        98,
        58,
        58,
        55,
        2,
        26,
        29,
        0,
        81,
        60,
        51,
        97,
        62,
        84,
        2,
        55,
        98,
        98,
        0,
        37,
        10,
        65,
        22,
        50,
        0,
        0,
        72,
        99,
        94,
        75,
        41,
        84,
        27,
        69,
        0,
        1
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        199,
        2,
        112,
        195,
        0,
        145,
        153,
        3,
        175,
        24,
        102,
        20,
        60,
        0,
        199,
        190,
        61,
        106,
        55,
        2,
        133,
        198,
        110,
        133,
        167,
        120,
        113,
        55,
        87,
        0,
        189,
        41,
        75,
        26,
        108,
        126,
        0,
        81,
        84,
        95,
        116,
        115,
        111,
        32,
        38,
        196,
        28,
        85,
        62,
        147,
        156,
        3
      ],
      "weeklyWasted": [
        108,
        126,
        0,
        81,
        84,
        95,
        116,
        115,
        111,
        32,
        38,
        196,
        28,
        85,
        62,
        147,
        156,
        3,
        19,
        1,
        20,
        49,
        0,
        47,
        99,
        60,
        12,
        64,
        97,
        45,
        1,
        91,
        97,
        15,
        8,
        99,
        7,
        0,
        99,
        7,
        0,
        53,
        39,
        29,
        0,
        93,
        86,
        42,
        2,
        57,
        1,
        92,
        70,
        8,
        55,
        50,
        61,
        99,
        26,
        7,
        72,
        53,
        71,
        85,
        22,
        40,
        0,
        99,
        0,
        92
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        199,
        2,
        190,
        5,
        71,
        1,
        120,
        15,
        90,
        71,
        162,
        2,
        198,
        10,
        199,
        154,
        192,
        199,
        53,
        2,
        158,
        156,
        197,
        104,
        103,
        162,
        163,
        1,
        157,
        65,
        128,
        60,
        1,
        199,
        4,
        0,
        198,
        142,
        199,
        183,
        17,
        0,
        21,
        88,
        1,
        128,
        195,
        106,
        198,
        54,
        90,
        3
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        142,
        199,
        183,
        17,
        0,
        21,
        88,
        1,
        128,
        195,
        106,
        198,
        54,
        90,
        3,
        1,
        57,
        53,
        2,
        98,
        29,
        53,
        65,
        99,
        40,
        0,
        88,
        99,
        3,
        97,
        50,
        0,
        99,
        42,
        48,
        2,
        25,
        0,
        5,
        69,
        99,
        0,
        97,
        99,
        76,
        2,
        54,
        25,
        66,
        70,
        0,
        71,
        46,
        97,
        99,
        1,
        0,
        67,
        99,
        99,
        34,
        86,
        59,
        14,
        87,
        7,
        1
      ]
    },
    {
      "id": "3D26994F",
      "weeklyUsage": [
        7,
        129,
        0,
        5,
        19,
        193,
        151,
        59,
        2,
        57,
        0,
        86,
        198,
        53,
        146,
        109,
        95,
        199,
        4,
        48,
        197,
        53,
        127,
        132,
        78,
        177,
        145,
        1,
        93,
        198,
        97,
        2,
        129,
        150,
        102,
        113,
        97,
        71,
        125,
        56,
        0,
        0,
        39,
        5,
        1,
        143,
        0,
        198,
        195,
        195,
        2,
        3
      ],
      "weeklyWasted": [
        102,
        113,
        97,
        71,
        125,
        56,
        0,
        0,
        39,
        5,
        1,
        143,
        0,
        198,
        195,
        195,
        2,
        3,
        20,
        18,
        90,
        60,
        38,
        65,
        57,
        0,
        70,
        0,
        99,
        81,
        48,
        24,
        86,
        93,
        0,
        67,
        1,
        49,
        2,
        14,
        35,
        70,
        56,
        96,
        96,
        97,
        99,
        35,
        30,
        97,
        70,
        62,
        99,
        14,
        31,
        0,
        99,
        1,
        97,
        0,
        99,
        17,
        74,
        0,
        47,
        10,
        99,
        98,
        32,
        78
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        184,
        2,
        104,
        29,
        33,
        197,
        3,
        197,
        138,
        1,
        199,
        2,
        94,
        60,
        182,
        3,
        176,
        104,
        36,
        197,
        31,
        198,
        2,
        123,
        124,
        1,
        185,
        25,
        199,
        42,
        31,
        121,
        173,
        93,
        4,
        1,
        42,
        105,
        19,
        199,
        41,
        199,
        57,
        67,
        1,
        58,
        88,
        119,
        146,
        79,
        192,
        46
      ],
      "weeklyWasted": [
        4,
        1,
        42,
        105,
        19,
        199,
        41,
        199,
        57,
        67,
        1,
        58,
        88,
        119,
        146,
        79,
        192,
        46,
        30,
        37,
        6,
        18,
        78,
        48,
        4,
        83,
        0,
        0,
        33,
        82,
        99,
        50,
        21,
        1,
        7,
        20,
        1,
        50,
        44,
        22,
        0,
        0,
        17,
        64,
        0,
        50,
        2,
        96,
        2,
        97,
        28,
        98,
        72,
        19,
        76,
        93,
        0,
        99,
        0,
        86,
        99,
        40,
        76,
        11,
        99,
        98,
        18,
        59,
        99,
        32
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        103,
        156,
        149,
        5,
        0,
        104,
        48,
        31,
        81,
        68,
        76,
        43,
        82,
        5,
        199,
        198,
        188,
        127,
        106,
        57,
        197,
        25,
        182,
        30,
        3,
        38,
        109,
        34,
        193,
        63,
        32,
        133,
        39,
        199,
        175,
        133,
        29,
        199,
        38,
        106,
        40,
        99,
        4,
        136,
        184,
        4,
        57,
        66,
        173,
        12,
        30,
        65
      ],
      "weeklyWasted": [
        175,
        133,
        29,
        199,
        38,
        106,
        40,
        99,
        4,
        136,
        184,
        4,
        57,
        66,
        173,
        12,
        30,
        65,
        52,
        1,
        50,
        40,
        98,
        36,
        1,
        14,
        99,
        0,
        78,
        3,
        20,
        89,
        86,
        98,
        20,
        62,
        23,
        21,
        2,
        78,
        34,
        25,
        59,
        70,
        39,
        22,
        87,
        96,
        10,
        15,
        1,
        55,
        63,
        31,
        67,
        94,
        45,
        12,
        41,
        0,
        44,
        20,
        80,
        55,
        99,
        48,
        91,
        99,
        99,
        1
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        77,
        136,
        14,
        5,
        141,
        171,
        11,
        40,
        199,
        144,
        182,
        2,
        106,
        88,
        199,
        164,
        60,
        134,
        61,
        12,
        146,
        20,
        2,
        122,
        3,
        162,
        99,
        28,
        91,
        87,
        199,
        0,
        55,
        5,
        96,
        18,
        144,
        157,
        42,
        0,
        0,
        199,
        80,
        65,
        85,
        4,
        0,
        198,
        198,
        7,
        73,
        7
      ],
      "weeklyWasted": [
        96,
        18,
        144,
        157,
        42,
        0,
        0,
        199,
        80,
        65,
        85,
        4,
        0,
        198,
        198,
        7,
        73,
        7,
        99,
        57,
        94,
        70,
        98,
        2,
        99,
        27,
        90,
        38,
        58,
        87,
        72,
        49,
        81,
        94,
        98,
        99,
        44,
        33,
        2,
        78,
        65,
        0,
        29,
        56,
        78,
        29,
        37,
        5,
        2,
        66,
        40,
        80,
        75,
        64,
        48,
        74,
        49,
        47,
        1,
        42,
        99,
        23,
        20,
        48,
        45,
        20,
        99,
        39,
        52,
        1
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        199,
        60,
        135,
        20,
        0,
        36,
        194,
        197,
        191,
        2,
        95,
        91,
        198,
        83,
        148,
        192,
        188,
        129,
        60,
        55,
        137,
        4,
        74,
        86,
        117,
        127,
        114,
        92,
        53,
        155,
        57,
        22,
        197,
        184,
        84,
        111,
        121,
        199,
        199,
        4,
        4,
        130,
        105,
        76,
        199,
        85,
        195,
        87,
        198,
        2,
        95,
        3
      ],
      "weeklyWasted": [
        84,
        111,
        121,
        199,
        199,
        4,
        4,
        130,
        105,
        76,
        199,
        85,
        195,
        87,
        198,
        2,
        95,
        3,
        89,
        1,
        44,
        2,
        64,
        1,
        99,
        7,
        99,
        44,
        6,
        47,
        38,
        99,
        20,
        1,
        98,
        52,
        49,
        63,
        2,
        50,
        22,
        0,
        32,
        99,
        58,
        50,
        34,
        20,
        14,
        68,
        37,
        82,
        0,
        0,
        36,
        45,
        0,
        87,
        66,
        21,
        99,
        99,
        99,
        71,
        50,
        98,
        71,
        10,
        60,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        48,
        142,
        198,
        5,
        32,
        103,
        173,
        32,
        189,
        111,
        51,
        63,
        74,
        110,
        91,
        3,
        1,
        95,
        197,
        110,
        161,
        180,
        96,
        111,
        155,
        87,
        47,
        66,
        165,
        127,
        63,
        65,
        52,
        178,
        175,
        28,
        160,
        23,
        145,
        99,
        199,
        0,
        199,
        0,
        134,
        196,
        129,
        104,
        198,
        148,
        121,
        25
      ],
      "weeklyWasted": [
        175,
        28,
        160,
        23,
        145,
        99,
        199,
        0,
        199,
        0,
        134,
        196,
        129,
        104,
        198,
        148,
        121,
        25,
        25,
        25,
        29,
        81,
        72,
        41,
        54,
        0,
        99,
        38,
        39,
        1,
        99,
        20,
        0,
        43,
        51,
        56,
        62,
        0,
        99,
        26,
        0,
        0,
        99,
        84,
        23,
        26,
        18,
        15,
        2,
        97,
        57,
        63,
        70,
        20,
        40,
        83,
        73,
        99,
        27,
        58,
        18,
        99,
        2,
        36,
        47,
        88,
        15,
        89,
        41,
        45
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        199,
        2,
        69,
        62,
        87,
        103,
        147,
        166,
        57,
        10,
        80,
        137,
        185,
        49,
        107,
        3,
        49,
        127,
        7,
        99,
        197,
        7,
        8,
        161,
        6,
        150,
        56,
        26,
        120,
        70,
        20,
        49,
        112,
        57,
        140,
        134,
        198,
        5,
        65,
        30,
        39,
        26,
        94,
        0,
        127,
        196,
        0,
        79,
        139,
        89,
        157,
        3
      ],
      "weeklyWasted": [
        140,
        134,
        198,
        5,
        65,
        30,
        39,
        26,
        94,
        0,
        127,
        196,
        0,
        79,
        139,
        89,
        157,
        3,
        20,
        17,
        99,
        45,
        98,
        85,
        78,
        0,
        99,
        51,
        62,
        84,
        59,
        86,
        41,
        30,
        0,
        75,
        4,
        10,
        2,
        0,
        7,
        62,
        18,
        99,
        64,
        41,
        37,
        15,
        34,
        76,
        47,
        98,
        79,
        15,
        75,
        52,
        99,
        68,
        91,
        27,
        91,
        54,
        24,
        72,
        76,
        20,
        51,
        94,
        56,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        199,
        74,
        82,
        97,
        0,
        37,
        89,
        78,
        103,
        147,
        199,
        44,
        110,
        199,
        106,
        181,
        141,
        199,
        85,
        2,
        1,
        4,
        16,
        116,
        163,
        136,
        158,
        1,
        127,
        15,
        63,
        57,
        124,
        37,
        198,
        89,
        0,
        87,
        199,
        0,
        0,
        182,
        0,
        53,
        67,
        4,
        191,
        6,
        96,
        139,
        31,
        78
      ],
      "weeklyWasted": [
        198,
        89,
        0,
        87,
        199,
        0,
        0,
        182,
        0,
        53,
        67,
        4,
        191,
        6,
        96,
        139,
        31,
        78,
        31,
        36,
        5,
        2,
        76,
        66,
        67,
        0,
        81,
        99,
        72,
        5,
        90,
        43,
        45,
        73,
        98,
        74,
        8,
        64,
        2,
        72,
        97,
        35,
        23,
        99,
        98,
        64,
        85,
        55,
        2,
        13,
        61,
        98,
        33,
        0,
        99,
        21,
        66,
        41,
        54,
        20,
        75,
        25,
        99,
        50,
        67,
        82,
        65,
        51,
        55,
        16
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyUsage": [
        183,
        199,
        0,
        94,
        108,
        196,
        115,
        124,
        117,
        1,
        199,
        50,
        74,
        112,
        199,
        105,
        143,
        199,
        3,
        114,
        197,
        93,
        52,
        102,
        24,
        175,
        78,
        92,
        157,
        198,
        135,
        119,
        197,
        19,
        33,
        138,
        159,
        31,
        91,
        141,
        54,
        0,
        0,
        88,
        127,
        112,
        16,
        95,
        198,
        2,
        24,
        157
      ],
      "weeklyWasted": [
        33,
        138,
        159,
        31,
        91,
        141,
        54,
        0,
        0,
        88,
        127,
        112,
        16,
        95,
        198,
        2,
        24,
        157,
        13,
        5,
        53,
        2,
        85,
        98,
        9,
        47,
        98,
        99,
        66,
        90,
        51,
        99,
        79,
        87,
        70,
        99,
        99,
        51,
        68,
        45,
        0,
        57,
        31,
        99,
        14,
        97,
        46,
        35,
        7,
        59,
        69,
        98,
        93,
        13,
        99,
        98,
        99,
        35,
        5,
        0,
        64,
        99,
        83,
        21,
        99,
        0,
        2,
        98,
        31,
        1
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyUsage": [
        199,
        2,
        89,
        97,
        80,
        168,
        54,
        151,
        2,
        1,
        50,
        2,
        19,
        0,
        156,
        51,
        75,
        60,
        72,
        161,
        166,
        198,
        126,
        62,
        3,
        179,
        188,
        28,
        95,
        31,
        53,
        79,
        1,
        4,
        105,
        15,
        73,
        21,
        165,
        107,
        119,
        0,
        13,
        68,
        1,
        186,
        93,
        0,
        198,
        2,
        197,
        3
      ],
      "weeklyWasted": [
        105,
        15,
        73,
        21,
        165,
        107,
        119,
        0,
        13,
        68,
        1,
        186,
        93,
        0,
        198,
        2,
        197,
        3,
        15,
        34,
        55,
        13,
        96,
        1,
        68,
        51,
        14,
        94,
        99,
        58,
        99,
        74,
        50,
        31,
        0,
        86,
        94,
        67,
        27,
        0,
        27,
        4,
        81,
        49,
        60,
        77,
        17,
        81,
        2,
        61,
        1,
        81,
        77,
        38,
        80,
        73,
        74,
        99,
        36,
        30,
        24,
        99,
        99,
        38,
        32,
        59,
        38,
        90,
        70,
        31
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        96,
        68,
        164,
        62,
        0,
        71,
        179,
        69,
        59,
        1,
        0,
        2,
        69,
        156,
        199,
        81,
        192,
        1,
        197,
        2,
        47,
        198,
        31,
        98,
        55,
        110,
        173,
        1,
        151,
        119,
        10,
        76,
        65,
        199,
        132,
        110,
        198,
        74,
        143,
        19,
        137,
        0,
        0,
        0,
        91,
        196,
        90,
        106,
        198,
        199,
        101,
        51
      ],
      "weeklyWasted": [
        132,
        110,
        198,
        74,
        143,
        19,
        137,
        0,
        0,
        0,
        91,
        196,
        90,
        106,
        198,
        199,
        101,
        51,
        77,
        5,
        68,
        2,
        98,
        1,
        50,
        0,
        99,
        39,
        65,
        44,
        35,
        47,
        44,
        1,
        0,
        84,
        5,
        44,
        21,
        0,
        65,
        3,
        50,
        99,
        67,
        78,
        52,
        15,
        52,
        76,
        35,
        82,
        73,
        0,
        2,
        73,
        99,
        99,
        1,
        61,
        87,
        96,
        46,
        93,
        25,
        98,
        86,
        99,
        27,
        23
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        158,
        14,
        116,
        5,
        136,
        93,
        130,
        92,
        199,
        169,
        162,
        68,
        191,
        168,
        199,
        104,
        40,
        184,
        105,
        27,
        166,
        66,
        89,
        108,
        113,
        100,
        65,
        75,
        98,
        125,
        78,
        196,
        70,
        199,
        89,
        101,
        103,
        143,
        173,
        112,
        0,
        78,
        0,
        44,
        78,
        146,
        106,
        104,
        188,
        176,
        114,
        147
      ],
      "weeklyWasted": [
        89,
        101,
        103,
        143,
        173,
        112,
        0,
        78,
        0,
        44,
        78,
        146,
        106,
        104,
        188,
        176,
        114,
        147,
        46,
        1,
        99,
        73,
        0,
        86,
        68,
        99,
        82,
        14,
        24,
        79,
        44,
        93,
        7,
        28,
        67,
        40,
        85,
        40,
        58,
        51,
        21,
        34,
        61,
        99,
        52,
        43,
        2,
        1,
        34,
        78,
        18,
        84,
        99,
        39,
        65,
        70,
        56,
        1,
        59,
        0,
        54,
        32,
        99,
        39,
        50,
        98,
        0,
        83,
        47,
        30
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        119,
        74,
        130,
        30,
        0,
        67,
        55,
        77,
        199,
        1,
        48,
        121,
        198,
        146,
        126,
        134,
        80,
        157,
        82,
        44,
        197,
        161,
        44,
        66,
        133,
        155,
        142,
        36,
        157,
        46,
        130,
        53,
        23,
        187,
        198,
        73,
        192,
        199,
        199,
        199,
        192,
        159,
        120,
        3,
        1,
        151,
        0,
        61,
        198,
        53,
        133,
        3
      ],
      "weeklyWasted": [
        198,
        73,
        192,
        199,
        199,
        199,
        192,
        159,
        120,
        3,
        1,
        151,
        0,
        61,
        198,
        53,
        133,
        3,
        56,
        8,
        0,
        2,
        91,
        1,
        50,
        64,
        99,
        72,
        46,
        99,
        60,
        90,
        28,
        98,
        10,
        62,
        99,
        77,
        2,
        18,
        0,
        34,
        64,
        56,
        7,
        66,
        28,
        16,
        2,
        83,
        1,
        81,
        42,
        20,
        24,
        84,
        0,
        21,
        22,
        53,
        4,
        99,
        58,
        0,
        54,
        82,
        1,
        93,
        46,
        52
      ]
    },
    {
      "id": "965102D9",
      "weeklyUsage": [
        188,
        168,
        198,
        48,
        0,
        179,
        54,
        97,
        196,
        76,
        0,
        45,
        163,
        120,
        108,
        46,
        192,
        118,
        71,
        172,
        16,
        198,
        86,
        156,
        197,
        66,
        183,
        26,
        49,
        144,
        131,
        25,
        1,
        4,
        134,
        54,
        23,
        159,
        100,
        52,
        181,
        52,
        0,
        99,
        59,
        154,
        102,
        161,
        179,
        126,
        114,
        14
      ],
      "weeklyWasted": [
        134,
        54,
        23,
        159,
        100,
        52,
        181,
        52,
        0,
        99,
        59,
        154,
        102,
        161,
        179,
        126,
        114,
        14,
        31,
        1,
        79,
        31,
        98,
        41,
        52,
        78,
        99,
        8,
        99,
        63,
        71,
        91,
        76,
        51,
        33,
        46,
        12,
        30,
        38,
        0,
        0,
        30,
        53,
        6,
        98,
        36,
        57,
        1,
        39,
        13,
        41,
        73,
        87,
        54,
        99,
        53,
        98,
        99,
        2,
        89,
        12,
        66,
        89,
        6,
        0,
        60,
        7,
        56,
        99,
        12
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        170,
        9,
        198,
        13,
        198,
        197,
        194,
        140,
        2,
        8,
        199,
        118,
        192,
        118,
        81,
        67,
        105,
        1,
        3,
        98,
        157,
        43,
        21,
        1,
        65,
        16,
        175,
        44,
        100,
        198,
        198,
        133,
        63,
        199,
        122,
        161,
        0,
        143,
        145,
        10,
        199,
        199,
        132,
        0,
        12,
        196,
        0,
        29,
        169,
        199,
        129,
        63
      ],
      "weeklyWasted": [
        122,
        161,
        0,
        143,
        145,
        10,
        199,
        199,
        132,
        0,
        12,
        196,
        0,
        29,
        169,
        199,
        129,
        63,
        17,
        38,
        43,
        31,
        70,
        1,
        99,
        0,
        68,
        14,
        99,
        17,
        99,
        99,
        38,
        52,
        19,
        21,
        64,
        44,
        2,
        15,
        26,
        2,
        86,
        66,
        98,
        97,
        3,
        1,
        2,
        47,
        46,
        98,
        26,
        19,
        25,
        71,
        3,
        70,
        59,
        0,
        79,
        85,
        84,
        35,
        99,
        0,
        99,
        99,
        99,
        1
      ]
    },
    {
      "id": "7B415249",
      "weeklyUsage": [
        88,
        139,
        30,
        193,
        65,
        144,
        194,
        132,
        41,
        83,
        197,
        184,
        103,
        0,
        1,
        87,
        177,
        97,
        197,
        82,
        1,
        4,
        120,
        1,
        3,
        120,
        197,
        59,
        199,
        84,
        130,
        0,
        135,
        4,
        168,
        141,
        198,
        5,
        10,
        19,
        29,
        38,
        46,
        137,
        1,
        86,
        11,
        131,
        198,
        27,
        3,
        23
      ],
      "weeklyWasted": [
        168,
        141,
        198,
        5,
        10,
        19,
        29,
        38,
        46,
        137,
        1,
        86,
        11,
        131,
        198,
        27,
        3,
        23,
        50,
        18,
        15,
        88,
        98,
        23,
        37,
        17,
        79,
        0,
        99,
        99,
        76,
        99,
        10,
        86,
        61,
        13,
        44,
        15,
        68,
        0,
        97,
        20,
        14,
        70,
        60,
        15,
        63,
        24,
        2,
        55,
        41,
        69,
        93,
        77,
        63,
        96,
        99,
        94,
        90,
        0,
        98,
        50,
        50,
        87,
        60,
        98,
        99,
        64,
        71,
        1
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        58,
        15,
        103,
        148,
        101,
        16,
        52,
        6,
        61,
        1,
        169,
        194,
        198,
        131,
        145,
        3,
        0,
        184,
        138,
        123,
        197,
        76,
        36,
        161,
        3,
        45,
        139,
        197,
        64,
        129,
        98,
        0,
        92,
        45,
        4,
        84,
        198,
        156,
        169,
        9,
        0,
        46,
        0,
        101,
        90,
        196,
        0,
        101,
        198,
        2,
        130,
        3
      ],
      "weeklyWasted": [
        4,
        84,
        198,
        156,
        169,
        9,
        0,
        46,
        0,
        101,
        90,
        196,
        0,
        101,
        198,
        2,
        130,
        3,
        43,
        10,
        96,
        28,
        93,
        41,
        30,
        25,
        36,
        47,
        98,
        1,
        52,
        14,
        54,
        54,
        17,
        99,
        54,
        90,
        42,
        88,
        58,
        0,
        61,
        48,
        66,
        35,
        2,
        1,
        99,
        46,
        87,
        98,
        84,
        0,
        84,
        99,
        0,
        98,
        1,
        64,
        65,
        71,
        81,
        0,
        75,
        98,
        46,
        87,
        60,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        199,
        15,
        0,
        94,
        136,
        46,
        51,
        54,
        130,
        67,
        196,
        2,
        198,
        8,
        1,
        3,
        176,
        199,
        30,
        27,
        197,
        23,
        143,
        126,
        3,
        79,
        153,
        86,
        199,
        178,
        98,
        18,
        85,
        56,
        4,
        102,
        163,
        21,
        55,
        86,
        132,
        0,
        153,
        123,
        2,
        196,
        57,
        29,
        147,
        105,
        111,
        3
      ],
      "weeklyWasted": [
        4,
        102,
        163,
        21,
        55,
        86,
        132,
        0,
        153,
        123,
        2,
        196,
        57,
        29,
        147,
        105,
        111,
        3,
        76,
        18,
        24,
        6,
        98,
        13,
        59,
        0,
        99,
        99,
        47,
        99,
        68,
        99,
        0,
        98,
        59,
        78,
        99,
        59,
        2,
        77,
        0,
        29,
        49,
        99,
        60,
        38,
        55,
        9,
        2,
        97,
        1,
        76,
        68,
        0,
        16,
        7,
        44,
        1,
        31,
        46,
        99,
        72,
        51,
        0,
        99,
        30,
        60,
        1,
        0,
        7
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        100,
        114,
        70,
        139,
        152,
        67,
        92,
        40,
        59,
        199,
        34,
        85,
        105,
        66,
        153,
        67,
        192,
        25,
        140,
        62,
        151,
        10,
        50,
        61,
        3,
        1,
        57,
        95,
        127,
        88,
        199,
        0,
        1,
        37,
        91,
        13,
        198,
        126,
        199,
        20,
        0,
        117,
        0,
        26,
        1,
        4,
        63,
        198,
        198,
        106,
        4,
        161
      ],
      "weeklyWasted": [
        91,
        13,
        198,
        126,
        199,
        20,
        0,
        117,
        0,
        26,
        1,
        4,
        63,
        198,
        198,
        106,
        4,
        161,
        34,
        5,
        53,
        30,
        66,
        96,
        38,
        0,
        88,
        66,
        99,
        8,
        99,
        3,
        31,
        35,
        61,
        99,
        1,
        35,
        49,
        99,
        16,
        0,
        20,
        98,
        73,
        41,
        63,
        48,
        60,
        60,
        70,
        57,
        65,
        98,
        99,
        54,
        99,
        93,
        0,
        21,
        99,
        16,
        0,
        0,
        32,
        98,
        54,
        99,
        99,
        13
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        7,
        2,
        64,
        153,
        45,
        131,
        176,
        65,
        176,
        1,
        10,
        68,
        30,
        1,
        199,
        198,
        182,
        107,
        3,
        80,
        1,
        177,
        2,
        96,
        131,
        53,
        166,
        1,
        167,
        43,
        0,
        196,
        129,
        4,
        174,
        46,
        37,
        90,
        92,
        86,
        199,
        0,
        34,
        13,
        1,
        196,
        65,
        148,
        198,
        88,
        161,
        43
      ],
      "weeklyWasted": [
        174,
        46,
        37,
        90,
        92,
        86,
        199,
        0,
        34,
        13,
        1,
        196,
        65,
        148,
        198,
        88,
        161,
        43,
        72,
        65,
        1,
        96,
        65,
        98,
        65,
        0,
        42,
        3,
        85,
        99,
        45,
        7,
        96,
        98,
        39,
        26,
        99,
        46,
        99,
        0,
        97,
        99,
        16,
        24,
        45,
        51,
        96,
        22,
        2,
        16,
        1,
        98,
        82,
        27,
        9,
        49,
        40,
        64,
        46,
        59,
        61,
        47,
        82,
        99,
        23,
        61,
        9,
        87,
        50,
        21
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        199,
        2,
        0,
        5,
        182,
        169,
        77,
        145,
        186,
        1,
        199,
        93,
        177,
        0,
        175,
        3,
        108,
        166,
        102,
        41,
        1,
        25,
        20,
        163,
        124,
        127,
        178,
        31,
        149,
        65,
        27,
        196,
        116,
        4,
        4,
        0,
        198,
        131,
        10,
        97,
        199,
        199,
        12,
        52,
        16,
        196,
        20,
        73,
        131,
        52,
        62,
        86
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        131,
        10,
        97,
        199,
        199,
        12,
        52,
        16,
        196,
        20,
        73,
        131,
        52,
        62,
        86,
        17,
        1,
        92,
        2,
        98,
        27,
        48,
        0,
        90,
        0,
        66,
        99,
        99,
        99,
        41,
        1,
        47,
        73,
        55,
        45,
        83,
        0,
        31,
        0,
        71,
        99,
        75,
        34,
        99,
        1,
        2,
        97,
        40,
        98,
        87,
        9,
        20,
        96,
        17,
        1,
        4,
        84,
        99,
        74,
        92,
        16,
        68,
        56,
        49,
        76,
        54,
        1
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        199,
        2,
        0,
        185,
        113,
        109,
        156,
        86,
        58,
        1,
        123,
        2,
        61,
        76,
        162,
        57,
        67,
        199,
        91,
        43,
        104,
        4,
        103,
        61,
        3,
        50,
        26,
        41,
        79,
        153,
        148,
        76,
        1,
        199,
        62,
        84,
        102,
        30,
        87,
        0,
        101,
        105,
        104,
        143,
        1,
        163,
        0,
        107,
        198,
        114,
        3,
        80
      ],
      "weeklyWasted": [
        62,
        84,
        102,
        30,
        87,
        0,
        101,
        105,
        104,
        143,
        1,
        163,
        0,
        107,
        198,
        114,
        3,
        80,
        1,
        14,
        89,
        79,
        80,
        17,
        99,
        0,
        0,
        22,
        16,
        25,
        23,
        96,
        0,
        53,
        32,
        68,
        98,
        33,
        3,
        17,
        0,
        84,
        21,
        40,
        88,
        83,
        79,
        13,
        82,
        43,
        52,
        46,
        18,
        68,
        89,
        56,
        99,
        59,
        0,
        96,
        99,
        32,
        99,
        53,
        99,
        93,
        23,
        86,
        15,
        9
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        199,
        30,
        110,
        157,
        51,
        68,
        85,
        148,
        2,
        56,
        199,
        141,
        124,
        0,
        14,
        102,
        192,
        166,
        3,
        2,
        1,
        4,
        56,
        93,
        143,
        33,
        193,
        56,
        116,
        3,
        81,
        87,
        1,
        170,
        120,
        0,
        0,
        199,
        135,
        0,
        121,
        0,
        0,
        0,
        43,
        129,
        100,
        93,
        198,
        13,
        118,
        113
      ],
      "weeklyWasted": [
        120,
        0,
        0,
        199,
        135,
        0,
        121,
        0,
        0,
        0,
        43,
        129,
        100,
        93,
        198,
        13,
        118,
        113,
        1,
        1,
        0,
        2,
        98,
        98,
        36,
        0,
        58,
        98,
        80,
        53,
        32,
        3,
        45,
        25,
        98,
        79,
        1,
        99,
        2,
        0,
        41,
        0,
        22,
        15,
        75,
        38,
        2,
        15,
        2,
        89,
        1,
        49,
        74,
        13,
        90,
        81,
        64,
        6,
        58,
        73,
        99,
        71,
        99,
        60,
        57,
        77,
        37,
        76,
        99,
        17
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyUsage": [
        134,
        13,
        139,
        195,
        93,
        197,
        120,
        3,
        88,
        34,
        66,
        178,
        47,
        0,
        183,
        185,
        192,
        133,
        89,
        185,
        187,
        4,
        16,
        15,
        117,
        93,
        139,
        61,
        199,
        117,
        198,
        120,
        131,
        4,
        73,
        199,
        178,
        181,
        79,
        30,
        105,
        0,
        0,
        70,
        199,
        168,
        195,
        10,
        198,
        114,
        106,
        3
      ],
      "weeklyWasted": [
        73,
        199,
        178,
        181,
        79,
        30,
        105,
        0,
        0,
        70,
        199,
        168,
        195,
        10,
        198,
        114,
        106,
        3,
        50,
        1,
        58,
        26,
        79,
        68,
        56,
        0,
        42,
        0,
        88,
        65,
        47,
        65,
        97,
        27,
        66,
        63,
        1,
        23,
        98,
        40,
        59,
        29,
        24,
        66,
        60,
        31,
        2,
        96,
        2,
        74,
        26,
        98,
        99,
        99,
        85,
        67,
        12,
        99,
        26,
        0,
        23,
        38,
        52,
        99,
        33,
        98,
        99,
        15,
        58,
        22
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyUsage": [
        102,
        2,
        154,
        159,
        90,
        92,
        35,
        157,
        199,
        140,
        199,
        185,
        198,
        0,
        164,
        136,
        133,
        99,
        138,
        197,
        6,
        126,
        52,
        195,
        114,
        114,
        115,
        146,
        128,
        92,
        44,
        0,
        142,
        162,
        4,
        199,
        142,
        71,
        10,
        199,
        112,
        65,
        11,
        87,
        32,
        16,
        6,
        113,
        155,
        24,
        181,
        3
      ],
      "weeklyWasted": [
        4,
        199,
        142,
        71,
        10,
        199,
        112,
        65,
        11,
        87,
        32,
        16,
        6,
        113,
        155,
        24,
        181,
        3,
        56,
        1,
        91,
        38,
        98,
        65,
        53,
        18,
        79,
        18,
        44,
        99,
        99,
        99,
        16,
        35,
        0,
        47,
        73,
        0,
        2,
        42,
        0,
        75,
        22,
        5,
        45,
        2,
        2,
        84,
        2,
        74,
        1,
        70,
        92,
        29,
        99,
        24,
        0,
        99,
        49,
        0,
        89,
        3,
        73,
        0,
        99,
        98,
        42,
        48,
        99,
        40
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        70,
        2,
        172,
        195,
        0,
        101,
        121,
        155,
        2,
        1,
        0,
        76,
        128,
        0,
        90,
        3,
        192,
        165,
        119,
        58,
        53,
        34,
        119,
        137,
        3,
        198,
        161,
        8,
        199,
        35,
        82,
        0,
        27,
        4,
        4,
        67,
        138,
        5,
        83,
        16,
        94,
        125,
        119,
        56,
        31,
        196,
        0,
        0,
        198,
        2,
        97,
        41
      ],
      "weeklyWasted": [
        4,
        67,
        138,
        5,
        83,
        16,
        94,
        125,
        119,
        56,
        31,
        196,
        0,
        0,
        198,
        2,
        97,
        41,
        64,
        1,
        13,
        96,
        96,
        75,
        28,
        6,
        22,
        25,
        99,
        29,
        99,
        99,
        33,
        36,
        27,
        68,
        15,
        27,
        92,
        0,
        45,
        39,
        23,
        41,
        72,
        26,
        99,
        21,
        18,
        97,
        3,
        80,
        43,
        90,
        62,
        60,
        99,
        90,
        0,
        28,
        84,
        63,
        79,
        34,
        68,
        21,
        23,
        14,
        15,
        17
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        126,
        7,
        136,
        5,
        0,
        73,
        54,
        197,
        23,
        55,
        147,
        108,
        81,
        70,
        39,
        115,
        23,
        199,
        3,
        122,
        197,
        4,
        92,
        69,
        3,
        80,
        150,
        83,
        110,
        0,
        111,
        138,
        111,
        199,
        198,
        43,
        22,
        5,
        107,
        0,
        0,
        199,
        0,
        0,
        141,
        196,
        0,
        49,
        126,
        199,
        54,
        196
      ],
      "weeklyWasted": [
        198,
        43,
        22,
        5,
        107,
        0,
        0,
        199,
        0,
        0,
        141,
        196,
        0,
        49,
        126,
        199,
        54,
        196,
        32,
        30,
        99,
        36,
        98,
        65,
        22,
        37,
        70,
        0,
        99,
        27,
        76,
        25,
        14,
        37,
        56,
        99,
        25,
        70,
        2,
        0,
        21,
        99,
        57,
        81,
        37,
        18,
        48,
        27,
        2,
        46,
        24,
        98,
        99,
        5,
        87,
        45,
        99,
        86,
        44,
        8,
        73,
        99,
        46,
        44,
        72,
        98,
        99,
        94,
        95,
        58
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        133,
        112,
        197,
        176,
        0,
        197,
        3,
        103,
        199,
        124,
        51,
        2,
        198,
        46,
        6,
        117,
        71,
        137,
        120,
        164,
        1,
        153,
        69,
        1,
        193,
        145,
        161,
        59,
        33,
        66,
        105,
        112,
        1,
        43,
        136,
        33,
        78,
        199,
        62,
        199,
        9,
        0,
        48,
        0,
        1,
        76,
        195,
        198,
        198,
        102,
        123,
        180
      ],
      "weeklyWasted": [
        136,
        33,
        78,
        199,
        62,
        199,
        9,
        0,
        48,
        0,
        1,
        76,
        195,
        198,
        198,
        102,
        123,
        180,
        19,
        10,
        21,
        34,
        57,
        3,
        13,
        21,
        87,
        99,
        92,
        97,
        59,
        71,
        7,
        1,
        61,
        61,
        1,
        72,
        69,
        37,
        23,
        0,
        18,
        22,
        78,
        1,
        8,
        37,
        2,
        11,
        32,
        70,
        99,
        15,
        99,
        62,
        8,
        91,
        56,
        17,
        97,
        99,
        61,
        32,
        17,
        98,
        52,
        72,
        94,
        1
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        150,
        2,
        56,
        195,
        32,
        80,
        189,
        109,
        168,
        129,
        194,
        32,
        161,
        0,
        138,
        3,
        192,
        199,
        121,
        197,
        138,
        154,
        2,
        195,
        75,
        107,
        197,
        66,
        182,
        84,
        100,
        0,
        149,
        179,
        42,
        199,
        45,
        80,
        120,
        0,
        199,
        199,
        65,
        198,
        63,
        175,
        0,
        198,
        102,
        110,
        59,
        3
      ],
      "weeklyWasted": [
        42,
        199,
        45,
        80,
        120,
        0,
        199,
        199,
        65,
        198,
        63,
        175,
        0,
        198,
        102,
        110,
        59,
        3,
        68,
        5,
        43,
        26,
        98,
        1,
        60,
        44,
        63,
        99,
        28,
        12,
        99,
        12,
        97,
        49,
        0,
        21,
        87,
        62,
        24,
        25,
        0,
        13,
        36,
        59,
        51,
        97,
        92,
        53,
        14,
        97,
        83,
        14,
        99,
        88,
        99,
        19,
        0,
        89,
        52,
        58,
        0,
        82,
        36,
        46,
        72,
        27,
        30,
        86,
        26,
        99
      ]
    },
    {
      "id": "7B415249",
      "weeklyUsage": [
        199,
        2,
        0,
        5,
        6,
        84,
        59,
        126,
        2,
        16,
        199,
        76,
        120,
        38,
        180,
        157,
        192,
        96,
        3,
        52,
        163,
        4,
        2,
        6,
        19,
        29,
        81,
        158,
        132,
        0,
        81,
        0,
        113,
        106,
        86,
        36,
        0,
        80,
        10,
        0,
        0,
        0,
        0,
        0,
        1,
        4,
        181,
        48,
        198,
        199,
        122,
        91
      ],
      "weeklyWasted": [
        86,
        36,
        0,
        80,
        10,
        0,
        0,
        0,
        0,
        0,
        1,
        4,
        181,
        48,
        198,
        199,
        122,
        91,
        1,
        31,
        78,
        2,
        42,
        31,
        83,
        2,
        99,
        23,
        99,
        47,
        38,
        55,
        97,
        90,
        36,
        59,
        21,
        16,
        34,
        0,
        14,
        74,
        36,
        99,
        52,
        97,
        35,
        65,
        2,
        41,
        46,
        98,
        63,
        41,
        67,
        84,
        39,
        67,
        0,
        35,
        73,
        67,
        52,
        99,
        99,
        98,
        73,
        91,
        61,
        90
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        161,
        2,
        56,
        55,
        198,
        126,
        74,
        112,
        199,
        1,
        186,
        79,
        124,
        92,
        82,
        3,
        64,
        171,
        42,
        76,
        152,
        198,
        87,
        67,
        151,
        114,
        148,
        2,
        8,
        106,
        79,
        92,
        1,
        4,
        114,
        4,
        81,
        148,
        132,
        68,
        2,
        22,
        199,
        0,
        111,
        196,
        71,
        109,
        134,
        178,
        157,
        137
      ],
      "weeklyWasted": [
        114,
        4,
        81,
        148,
        132,
        68,
        2,
        22,
        199,
        0,
        111,
        196,
        71,
        109,
        134,
        178,
        157,
        137,
        42,
        5,
        6,
        2,
        92,
        98,
        56,
        0,
        80,
        56,
        99,
        99,
        43,
        99,
        15,
        44,
        74,
        67,
        28,
        86,
        15,
        67,
        19,
        47,
        70,
        73,
        36,
        72,
        47,
        1,
        3,
        78,
        53,
        98,
        38,
        0,
        99,
        39,
        19,
        73,
        12,
        98,
        62,
        52,
        7,
        47,
        48,
        71,
        99,
        5,
        71,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        7,
        2,
        198,
        5,
        0,
        89,
        156,
        48,
        2,
        132,
        110,
        195,
        152,
        0,
        150,
        168,
        190,
        165,
        107,
        125,
        85,
        83,
        54,
        134,
        3,
        109,
        111,
        39,
        126,
        90,
        9,
        0,
        65,
        165,
        198,
        110,
        182,
        49,
        147,
        118,
        111,
        141,
        87,
        11,
        126,
        133,
        99,
        19,
        198,
        2,
        197,
        75
      ],
      "weeklyWasted": [
        198,
        110,
        182,
        49,
        147,
        118,
        111,
        141,
        87,
        11,
        126,
        133,
        99,
        19,
        198,
        2,
        197,
        75,
        54,
        1,
        91,
        2,
        98,
        79,
        40,
        0,
        99,
        38,
        57,
        48,
        99,
        99,
        78,
        1,
        69,
        93,
        20,
        72,
        43,
        0,
        20,
        0,
        46,
        45,
        60,
        40,
        99,
        35,
        2,
        73,
        56,
        98,
        99,
        21,
        55,
        82,
        72,
        94,
        52,
        0,
        98,
        31,
        71,
        39,
        0,
        70,
        99,
        99,
        61,
        1
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        138,
        129,
        26,
        5,
        0,
        1,
        105,
        94,
        71,
        43,
        71,
        12,
        99,
        0,
        199,
        150,
        0,
        78,
        67,
        188,
        156,
        37,
        107,
        195,
        48,
        161,
        136,
        41,
        175,
        118,
        148,
        0,
        1,
        110,
        169,
        50,
        198,
        199,
        189,
        192,
        100,
        0,
        0,
        173,
        131,
        105,
        0,
        74,
        176,
        42,
        159,
        66
      ],
      "weeklyWasted": [
        169,
        50,
        198,
        199,
        189,
        192,
        100,
        0,
        0,
        173,
        131,
        105,
        0,
        74,
        176,
        42,
        159,
        66,
        21,
        29,
        48,
        5,
        98,
        62,
        34,
        28,
        78,
        21,
        99,
        73,
        77,
        93,
        32,
        98,
        33,
        54,
        25,
        54,
        2,
        68,
        20,
        24,
        44,
        88,
        60,
        71,
        84,
        59,
        66,
        57,
        67,
        93,
        51,
        20,
        48,
        61,
        0,
        60,
        28,
        71,
        75,
        61,
        79,
        0,
        0,
        98,
        70,
        58,
        52,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        28,
        74,
        153,
        48,
        55,
        197,
        24,
        94,
        109,
        1,
        161,
        87,
        106,
        0,
        146,
        8,
        192,
        128,
        197,
        2,
        177,
        4,
        110,
        75,
        3,
        181,
        91,
        2,
        199,
        77,
        127,
        0,
        84,
        4,
        198,
        181,
        198,
        5,
        46,
        0,
        0,
        106,
        0,
        0,
        9,
        196,
        51,
        110,
        198,
        106,
        2,
        3
      ],
      "weeklyWasted": [
        198,
        181,
        198,
        5,
        46,
        0,
        0,
        106,
        0,
        0,
        9,
        196,
        51,
        110,
        198,
        106,
        2,
        3,
        74,
        70,
        47,
        96,
        98,
        67,
        28,
        0,
        92,
        0,
        82,
        74,
        77,
        32,
        95,
        43,
        1,
        90,
        15,
        48,
        29,
        8,
        19,
        36,
        16,
        47,
        81,
        80,
        77,
        73,
        27,
        64,
        33,
        80,
        69,
        67,
        62,
        0,
        99,
        99,
        97,
        46,
        99,
        72,
        0,
        61,
        82,
        13,
        94,
        99,
        99,
        29
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        199,
        2,
        50,
        72,
        0,
        153,
        14,
        27,
        2,
        1,
        134,
        82,
        198,
        0,
        1,
        72,
        192,
        147,
        3,
        39,
        197,
        177,
        2,
        48,
        183,
        79,
        152,
        1,
        0,
        147,
        85,
        181,
        12,
        148,
        171,
        188,
        7,
        5,
        95,
        199,
        143,
        199,
        0,
        75,
        33,
        196,
        178,
        0,
        198,
        41,
        2,
        7
      ],
      "weeklyWasted": [
        171,
        188,
        7,
        5,
        95,
        199,
        143,
        199,
        0,
        75,
        33,
        196,
        178,
        0,
        198,
        41,
        2,
        7,
        79,
        65,
        99,
        2,
        20,
        80,
        99,
        41,
        53,
        23,
        26,
        26,
        28,
        99,
        18,
        31,
        41,
        73,
        90,
        87,
        80,
        48,
        0,
        88,
        99,
        99,
        73,
        48,
        37,
        45,
        2,
        4,
        69,
        82,
        58,
        26,
        99,
        99,
        48,
        36,
        2,
        3,
        34,
        99,
        57,
        55,
        87,
        81,
        99,
        82,
        58,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        199,
        2,
        122,
        43,
        83,
        1,
        146,
        94,
        7,
        23,
        0,
        93,
        198,
        30,
        132,
        3,
        139,
        181,
        3,
        126,
        58,
        108,
        6,
        125,
        3,
        191,
        169,
        1,
        55,
        141,
        17,
        41,
        62,
        4,
        80,
        36,
        99,
        5,
        111,
        85,
        162,
        100,
        148,
        15,
        73,
        196,
        54,
        93,
        177,
        14,
        54,
        17
      ],
      "weeklyWasted": [
        80,
        36,
        99,
        5,
        111,
        85,
        162,
        100,
        148,
        15,
        73,
        196,
        54,
        93,
        177,
        14,
        54,
        17,
        49,
        32,
        12,
        2,
        51,
        29,
        92,
        4,
        89,
        67,
        18,
        71,
        44,
        95,
        31,
        1,
        57,
        36,
        29,
        49,
        80,
        25,
        97,
        0,
        28,
        50,
        68,
        65,
        99,
        22,
        9,
        39,
        20,
        95,
        99,
        0,
        25,
        45,
        55,
        26,
        99,
        0,
        44,
        68,
        99,
        70,
        99,
        15,
        99,
        23,
        0,
        99
      ]
    },
    {
      "id": "F55885BD",
      "weeklyUsage": [
        199,
        111,
        73,
        5,
        82,
        150,
        194,
        122,
        6,
        1,
        115,
        102,
        183,
        98,
        97,
        142,
        192,
        61,
        180,
        185,
        197,
        107,
        155,
        32,
        3,
        172,
        161,
        91,
        63,
        174,
        150,
        75,
        47,
        42,
        100,
        93,
        172,
        5,
        69,
        123,
        92,
        70,
        85,
        14,
        1,
        130,
        10,
        198,
        198,
        68,
        182,
        64
      ],
      "weeklyWasted": [
        100,
        93,
        172,
        5,
        69,
        123,
        92,
        70,
        85,
        14,
        1,
        130,
        10,
        198,
        198,
        68,
        182,
        64,
        99,
        34,
        3,
        50,
        75,
        1,
        55,
        22,
        99,
        0,
        45,
        68,
        45,
        99,
        45,
        1,
        23,
        81,
        30,
        26,
        41,
        0,
        0,
        8,
        0,
        99,
        98,
        43,
        89,
        30,
        28,
        64,
        90,
        98,
        99,
        71,
        99,
        25,
        37,
        89,
        95,
        0,
        99,
        99,
        99,
        0,
        87,
        98,
        68,
        1,
        82,
        27
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        199,
        93,
        0,
        50,
        0,
        183,
        26,
        128,
        74,
        100,
        103,
        128,
        182,
        0,
        86,
        3,
        128,
        199,
        158,
        2,
        123,
        68,
        48,
        75,
        3,
        1,
        157,
        1,
        147,
        6,
        199,
        0,
        179,
        199,
        4,
        73,
        53,
        199,
        107,
        6,
        37,
        4,
        0,
        9,
        38,
        127,
        58,
        0,
        165,
        115,
        132,
        114
      ],
      "weeklyWasted": [
        4,
        73,
        53,
        199,
        107,
        6,
        37,
        4,
        0,
        9,
        38,
        127,
        58,
        0,
        165,
        115,
        132,
        114,
        87,
        62,
        0,
        8,
        90,
        53,
        99,
        0,
        60,
        65,
        99,
        1,
        84,
        47,
        31,
        38,
        30,
        77,
        33,
        0,
        55,
        68,
        5,
        1,
        59,
        35,
        25,
        56,
        73,
        36,
        5,
        82,
        44,
        65,
        0,
        1,
        71,
        58,
        35,
        52,
        12,
        98,
        27,
        82,
        52,
        15,
        69,
        98,
        83,
        95,
        25,
        57
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        111,
        97,
        107,
        47,
        124,
        1,
        8,
        156,
        126,
        1,
        141,
        109,
        84,
        50,
        199,
        67,
        133,
        161,
        56,
        2,
        81,
        198,
        6,
        195,
        124,
        116,
        197,
        123,
        199,
        8,
        39,
        60,
        45,
        199,
        75,
        0,
        176,
        98,
        103,
        199,
        145,
        70,
        0,
        0,
        1,
        123,
        0,
        25,
        21,
        42,
        172,
        3
      ],
      "weeklyWasted": [
        75,
        0,
        176,
        98,
        103,
        199,
        145,
        70,
        0,
        0,
        1,
        123,
        0,
        25,
        21,
        42,
        172,
        3,
        29,
        15,
        46,
        15,
        98,
        1,
        99,
        0,
        99,
        60,
        53,
        98,
        49,
        3,
        87,
        98,
        63,
        35,
        24,
        82,
        94,
        0,
        0,
        77,
        33,
        57,
        0,
        54,
        35,
        88,
        2,
        77,
        26,
        98,
        77,
        0,
        99,
        22,
        14,
        99,
        32,
        90,
        99,
        99,
        74,
        99,
        53,
        98,
        99,
        31,
        54,
        55
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        139,
        69,
        128,
        5,
        0,
        190,
        3,
        129,
        48,
        1,
        80,
        41,
        2,
        165,
        118,
        164,
        192,
        55,
        151,
        115,
        72,
        128,
        133,
        195,
        125,
        85,
        197,
        1,
        186,
        198,
        113,
        196,
        117,
        114,
        71,
        199,
        118,
        120,
        46,
        27,
        0,
        13,
        76,
        28,
        105,
        85,
        0,
        74,
        198,
        46,
        2,
        87
      ],
      "weeklyWasted": [
        71,
        199,
        118,
        120,
        46,
        27,
        0,
        13,
        76,
        28,
        105,
        85,
        0,
        74,
        198,
        46,
        2,
        87,
        72,
        31,
        10,
        2,
        98,
        98,
        35,
        35,
        99,
        9,
        17,
        76,
        53,
        99,
        77,
        1,
        23,
        99,
        36,
        58,
        9,
        99,
        60,
        52,
        5,
        99,
        76,
        81,
        99,
        13,
        69,
        68,
        1,
        86,
        99,
        0,
        79,
        99,
        0,
        65,
        16,
        20,
        1,
        52,
        99,
        89,
        99,
        89,
        26,
        98,
        99,
        14
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        199,
        78,
        79,
        5,
        0,
        33,
        74,
        91,
        2,
        90,
        192,
        42,
        198,
        132,
        199,
        160,
        78,
        162,
        47,
        129,
        149,
        130,
        18,
        139,
        117,
        198,
        117,
        90,
        198,
        111,
        107,
        196,
        2,
        199,
        198,
        0,
        60,
        36,
        10,
        98,
        0,
        127,
        0,
        0,
        199,
        181,
        5,
        76,
        47,
        181,
        190,
        183
      ],
      "weeklyWasted": [
        198,
        0,
        60,
        36,
        10,
        98,
        0,
        127,
        0,
        0,
        199,
        181,
        5,
        76,
        47,
        181,
        190,
        183,
        54,
        16,
        99,
        49,
        60,
        45,
        99,
        14,
        45,
        99,
        99,
        71,
        26,
        81,
        28,
        25,
        59,
        4,
        46,
        46,
        48,
        49,
        97,
        35,
        16,
        88,
        75,
        33,
        92,
        1,
        2,
        97,
        67,
        98,
        40,
        32,
        70,
        34,
        42,
        27,
        0,
        6,
        99,
        98,
        44,
        90,
        62,
        98,
        92,
        1,
        99,
        34
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        44,
        29,
        51,
        140,
        198,
        128,
        173,
        35,
        90,
        72,
        48,
        2,
        128,
        3,
        199,
        96,
        192,
        81,
        92,
        197,
        197,
        4,
        177,
        64,
        16,
        198,
        149,
        52,
        170,
        133,
        194,
        57,
        124,
        191,
        95,
        0,
        198,
        5,
        93,
        61,
        3,
        169,
        119,
        88,
        109,
        100,
        0,
        15,
        122,
        176,
        56,
        9
      ],
      "weeklyWasted": [
        95,
        0,
        198,
        5,
        93,
        61,
        3,
        169,
        119,
        88,
        109,
        100,
        0,
        15,
        122,
        176,
        56,
        9,
        30,
        3,
        0,
        2,
        50,
        1,
        99,
        0,
        25,
        46,
        99,
        48,
        99,
        45,
        64,
        46,
        61,
        48,
        32,
        0,
        66,
        1,
        86,
        0,
        58,
        64,
        98,
        32,
        99,
        64,
        2,
        46,
        43,
        94,
        67,
        25,
        33,
        94,
        60,
        91,
        99,
        58,
        99,
        24,
        56,
        68,
        59,
        71,
        99,
        90,
        99,
        8
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        164,
        119,
        73,
        49,
        145,
        128,
        51,
        69,
        113,
        64,
        177,
        2,
        120,
        0,
        119,
        169,
        167,
        158,
        36,
        193,
        97,
        110,
        118,
        195,
        62,
        198,
        81,
        1,
        137,
        184,
        117,
        0,
        49,
        76,
        198,
        199,
        167,
        52,
        182,
        30,
        111,
        27,
        40,
        0,
        53,
        4,
        0,
        52,
        198,
        153,
        44,
        78
      ],
      "weeklyWasted": [
        198,
        199,
        167,
        52,
        182,
        30,
        111,
        27,
        40,
        0,
        53,
        4,
        0,
        52,
        198,
        153,
        44,
        78,
        54,
        21,
        26,
        2,
        98,
        98,
        51,
        96,
        34,
        65,
        39,
        61,
        44,
        70,
        0,
        98,
        30,
        4,
        43,
        73,
        58,
        0,
        15,
        0,
        81,
        1,
        69,
        52,
        2,
        75,
        2,
        31,
        26,
        88,
        93,
        1,
        99,
        31,
        99,
        85,
        75,
        67,
        96,
        99,
        72,
        49,
        93,
        25,
        26,
        65,
        99,
        9
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        38,
        63,
        198,
        29,
        0,
        157,
        132,
        127,
        2,
        1,
        199,
        104,
        192,
        28,
        122,
        73,
        112,
        182,
        82,
        67,
        197,
        198,
        74,
        143,
        3,
        75,
        101,
        77,
        199,
        198,
        82,
        59,
        95,
        199,
        60,
        0,
        98,
        108,
        112,
        0,
        14,
        199,
        54,
        0,
        60,
        109,
        74,
        39,
        95,
        199,
        101,
        3
      ],
      "weeklyWasted": [
        60,
        0,
        98,
        108,
        112,
        0,
        14,
        199,
        54,
        0,
        60,
        109,
        74,
        39,
        95,
        199,
        101,
        3,
        32,
        45,
        99,
        2,
        47,
        39,
        47,
        0,
        99,
        63,
        72,
        90,
        20,
        51,
        6,
        3,
        46,
        20,
        6,
        99,
        2,
        30,
        10,
        0,
        61,
        99,
        28,
        97,
        99,
        29,
        24,
        82,
        1,
        98,
        69,
        52,
        1,
        33,
        49,
        46,
        18,
        98,
        43,
        99,
        67,
        28,
        99,
        98,
        99,
        61,
        27,
        1
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        70,
        2,
        139,
        186,
        20,
        181,
        65,
        3,
        96,
        35,
        32,
        19,
        182,
        112,
        75,
        164,
        141,
        169,
        84,
        2,
        178,
        48,
        2,
        122,
        3,
        20,
        42,
        62,
        155,
        100,
        199,
        107,
        102,
        116,
        198,
        199,
        198,
        5,
        156,
        69,
        0,
        102,
        17,
        0,
        199,
        196,
        103,
        159,
        80,
        174,
        2,
        55
      ],
      "weeklyWasted": [
        198,
        199,
        198,
        5,
        156,
        69,
        0,
        102,
        17,
        0,
        199,
        196,
        103,
        159,
        80,
        174,
        2,
        55,
        25,
        27,
        0,
        2,
        0,
        96,
        69,
        0,
        70,
        0,
        99,
        33,
        21,
        96,
        6,
        98,
        22,
        85,
        3,
        0,
        94,
        2,
        40,
        36,
        72,
        99,
        42,
        73,
        99,
        7,
        64,
        53,
        25,
        89,
        29,
        7,
        51,
        18,
        85,
        58,
        28,
        53,
        91,
        72,
        99,
        82,
        99,
        14,
        68,
        45,
        99,
        47
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        193,
        120,
        34,
        5,
        0,
        193,
        42,
        10,
        19,
        1,
        190,
        90,
        198,
        105,
        160,
        3,
        160,
        97,
        3,
        144,
        104,
        51,
        54,
        121,
        197,
        198,
        146,
        1,
        21,
        198,
        19,
        0,
        1,
        55,
        116,
        191,
        56,
        5,
        58,
        0,
        197,
        0,
        101,
        116,
        1,
        156,
        0,
        27,
        170,
        199,
        173,
        61
      ],
      "weeklyWasted": [
        116,
        191,
        56,
        5,
        58,
        0,
        197,
        0,
        101,
        116,
        1,
        156,
        0,
        27,
        170,
        199,
        173,
        61,
        50,
        2,
        35,
        96,
        31,
        1,
        91,
        0,
        72,
        57,
        99,
        11,
        5,
        3,
        61,
        97,
        65,
        46,
        1,
        70,
        2,
        10,
        49,
        7,
        50,
        68,
        98,
        65,
        73,
        49,
        2,
        97,
        32,
        98,
        63,
        84,
        86,
        61,
        99,
        11,
        6,
        97,
        99,
        59,
        34,
        77,
        55,
        98,
        64,
        99,
        28,
        19
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        7,
        199,
        90,
        5,
        80,
        135,
        194,
        29,
        14,
        131,
        52,
        36,
        2,
        0,
        53,
        188,
        17,
        160,
        30,
        197,
        1,
        198,
        2,
        176,
        186,
        198,
        161,
        85,
        148,
        145,
        183,
        196,
        197,
        199,
        148,
        113,
        189,
        41,
        108,
        174,
        16,
        11,
        0,
        77,
        10,
        133,
        0,
        66,
        118,
        9,
        18,
        62
      ],
      "weeklyWasted": [
        148,
        113,
        189,
        41,
        108,
        174,
        16,
        11,
        0,
        77,
        10,
        133,
        0,
        66,
        118,
        9,
        18,
        62,
        42,
        2,
        99,
        31,
        98,
        98,
        58,
        44,
        52,
        0,
        15,
        86,
        64,
        90,
        61,
        80,
        98,
        85,
        95,
        2,
        67,
        0,
        0,
        0,
        0,
        13,
        75,
        47,
        42,
        45,
        2,
        74,
        20,
        49,
        76,
        6,
        37,
        7,
        98,
        72,
        12,
        50,
        99,
        29,
        64,
        41,
        33,
        26,
        29,
        76,
        61,
        1
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        114,
        199,
        198,
        34,
        111,
        17,
        136,
        3,
        2,
        1,
        199,
        2,
        118,
        84,
        188,
        63,
        192,
        133,
        3,
        13,
        197,
        197,
        161,
        1,
        88,
        1,
        124,
        26,
        105,
        35,
        56,
        98,
        96,
        142,
        12,
        58,
        0,
        190,
        130,
        128,
        35,
        7,
        199,
        0,
        1,
        170,
        68,
        194,
        144,
        115,
        59,
        48
      ],
      "weeklyWasted": [
        12,
        58,
        0,
        190,
        130,
        128,
        35,
        7,
        199,
        0,
        1,
        170,
        68,
        194,
        144,
        115,
        59,
        48,
        1,
        98,
        85,
        2,
        45,
        6,
        43,
        99,
        84,
        24,
        21,
        97,
        62,
        91,
        89,
        98,
        98,
        99,
        78,
        77,
        46,
        12,
        20,
        54,
        99,
        86,
        0,
        38,
        33,
        35,
        2,
        97,
        44,
        98,
        65,
        18,
        99,
        39,
        0,
        92,
        15,
        0,
        53,
        75,
        99,
        63,
        94,
        11,
        71,
        99,
        79,
        1
      ]
    },
    {
      "id": "4FF22342",
      "weeklyUsage": [
        86,
        79,
        10,
        5,
        107,
        191,
        57,
        124,
        2,
        1,
        104,
        197,
        112,
        0,
        153,
        198,
        177,
        163,
        32,
        52,
        96,
        198,
        94,
        17,
        3,
        28,
        110,
        1,
        0,
        84,
        97,
        0,
        1,
        47,
        138,
        34,
        147,
        199,
        70,
        153,
        0,
        181,
        122,
        46,
        87,
        91,
        195,
        175,
        198,
        76,
        181,
        92
      ],
      "weeklyWasted": [
        138,
        34,
        147,
        199,
        70,
        153,
        0,
        181,
        122,
        46,
        87,
        91,
        195,
        175,
        198,
        76,
        181,
        92,
        74,
        91,
        73,
        25,
        0,
        1,
        78,
        26,
        46,
        39,
        10,
        30,
        43,
        47,
        14,
        1,
        36,
        57,
        20,
        75,
        54,
        0,
        97,
        57,
        28,
        99,
        5,
        24,
        33,
        73,
        6,
        46,
        39,
        98,
        83,
        16,
        66,
        99,
        19,
        74,
        76,
        0,
        99,
        68,
        82,
        34,
        86,
        98,
        68,
        88,
        42,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        199,
        2,
        60,
        36,
        144,
        112,
        194,
        16,
        2,
        143,
        43,
        2,
        48,
        0,
        159,
        3,
        74,
        156,
        3,
        155,
        197,
        172,
        2,
        141,
        134,
        155,
        197,
        197,
        49,
        93,
        182,
        0,
        1,
        133,
        51,
        28,
        0,
        40,
        101,
        40,
        139,
        1,
        0,
        0,
        1,
        144,
        30,
        60,
        153,
        199,
        26,
        125
      ],
      "weeklyWasted": [
        51,
        28,
        0,
        40,
        101,
        40,
        139,
        1,
        0,
        0,
        1,
        144,
        30,
        60,
        153,
        199,
        26,
        125,
        26,
        1,
        42,
        57,
        90,
        74,
        79,
        0,
        22,
        4,
        99,
        51,
        99,
        11,
        9,
        9,
        53,
        27,
        39,
        11,
        32,
        0,
        97,
        21,
        34,
        90,
        61,
        27,
        53,
        5,
        99,
        56,
        99,
        98,
        90,
        28,
        43,
        85,
        73,
        1,
        99,
        0,
        73,
        99,
        46,
        2,
        16,
        98,
        34,
        35,
        99,
        77
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyUsage": [
        129,
        164,
        198,
        5,
        90,
        133,
        194,
        187,
        2,
        1,
        152,
        45,
        198,
        0,
        199,
        88,
        145,
        60,
        53,
        152,
        65,
        26,
        103,
        37,
        161,
        174,
        73,
        30,
        66,
        151,
        95,
        0,
        23,
        162,
        84,
        199,
        114,
        80,
        199,
        193,
        118,
        12,
        0,
        111,
        1,
        4,
        27,
        160,
        198,
        66,
        151,
        32
      ],
      "weeklyWasted": [
        84,
        199,
        114,
        80,
        199,
        193,
        118,
        12,
        0,
        111,
        1,
        4,
        27,
        160,
        198,
        66,
        151,
        32,
        8,
        1,
        99,
        32,
        85,
        39,
        99,
        0,
        99,
        40,
        58,
        11,
        99,
        56,
        59,
        22,
        3,
        63,
        40,
        56,
        45,
        4,
        97,
        0,
        42,
        56,
        70,
        52,
        48,
        11,
        2,
        58,
        71,
        25,
        99,
        1,
        99,
        98,
        94,
        5,
        99,
        0,
        73,
        99,
        99,
        22,
        81,
        75,
        52,
        80,
        99,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        157,
        64,
        62,
        106,
        198,
        12,
        194,
        39,
        13,
        106,
        73,
        88,
        159,
        0,
        21,
        140,
        150,
        78,
        101,
        171,
        197,
        198,
        85,
        113,
        3,
        198,
        120,
        41,
        27,
        50,
        71,
        0,
        1,
        42,
        114,
        77,
        103,
        5,
        12,
        96,
        91,
        21,
        57,
        0,
        160,
        7,
        0,
        95,
        198,
        199,
        145,
        60
      ],
      "weeklyWasted": [
        114,
        77,
        103,
        5,
        12,
        96,
        91,
        21,
        57,
        0,
        160,
        7,
        0,
        95,
        198,
        199,
        145,
        60,
        19,
        4,
        56,
        5,
        98,
        98,
        15,
        30,
        68,
        38,
        99,
        73,
        99,
        26,
        0,
        1,
        26,
        99,
        35,
        0,
        98,
        63,
        47,
        40,
        86,
        65,
        98,
        1,
        77,
        80,
        64,
        66,
        14,
        86,
        99,
        78,
        85,
        60,
        45,
        67,
        47,
        29,
        99,
        99,
        0,
        0,
        98,
        98,
        48,
        98,
        53,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        49,
        4,
        187,
        180,
        13,
        21,
        58,
        14,
        199,
        1,
        77,
        153,
        23,
        66,
        89,
        3,
        106,
        123,
        180,
        2,
        197,
        124,
        32,
        147,
        106,
        186,
        191,
        5,
        199,
        198,
        37,
        0,
        1,
        152,
        38,
        0,
        73,
        5,
        188,
        199,
        129,
        9,
        64,
        0,
        37,
        97,
        115,
        20,
        178,
        111,
        97,
        58
      ],
      "weeklyWasted": [
        38,
        0,
        73,
        5,
        188,
        199,
        129,
        9,
        64,
        0,
        37,
        97,
        115,
        20,
        178,
        111,
        97,
        58,
        99,
        5,
        77,
        2,
        98,
        33,
        76,
        10,
        15,
        95,
        77,
        33,
        15,
        77,
        14,
        55,
        75,
        62,
        1,
        55,
        89,
        74,
        0,
        32,
        62,
        11,
        61,
        1,
        24,
        37,
        2,
        97,
        1,
        98,
        37,
        10,
        61,
        61,
        40,
        53,
        32,
        98,
        65,
        54,
        49,
        26,
        0,
        91,
        99,
        99,
        93,
        89
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        26,
        199,
        43,
        5,
        67,
        162,
        63,
        4,
        2,
        121,
        199,
        2,
        174,
        83,
        115,
        148,
        192,
        85,
        98,
        2,
        187,
        6,
        197,
        96,
        3,
        169,
        99,
        58,
        97,
        198,
        55,
        76,
        1,
        87,
        9,
        42,
        140,
        199,
        63,
        108,
        0,
        0,
        0,
        24,
        1,
        50,
        135,
        58,
        198,
        26,
        2,
        102
      ],
      "weeklyWasted": [
        9,
        42,
        140,
        199,
        63,
        108,
        0,
        0,
        0,
        24,
        1,
        50,
        135,
        58,
        198,
        26,
        2,
        102,
        68,
        38,
        43,
        2,
        69,
        32,
        99,
        0,
        77,
        49,
        99,
        32,
        83,
        88,
        73,
        55,
        1,
        71,
        18,
        97,
        2,
        30,
        58,
        0,
        15,
        11,
        98,
        97,
        34,
        33,
        40,
        78,
        73,
        37,
        31,
        24,
        13,
        71,
        8,
        35,
        23,
        42,
        98,
        71,
        76,
        39,
        81,
        25,
        20,
        99,
        65,
        1
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        30,
        21,
        198,
        60,
        125,
        34,
        160,
        3,
        151,
        1,
        127,
        73,
        163,
        62,
        125,
        78,
        141,
        152,
        3,
        56,
        55,
        4,
        2,
        195,
        82,
        121,
        105,
        1,
        80,
        47,
        41,
        0,
        27,
        199,
        122,
        0,
        198,
        101,
        10,
        63,
        160,
        0,
        156,
        0,
        1,
        196,
        169,
        0,
        198,
        132,
        16,
        174
      ],
      "weeklyWasted": [
        122,
        0,
        198,
        101,
        10,
        63,
        160,
        0,
        156,
        0,
        1,
        196,
        169,
        0,
        198,
        132,
        16,
        174,
        99,
        48,
        40,
        2,
        88,
        1,
        83,
        0,
        99,
        99,
        74,
        72,
        20,
        20,
        46,
        60,
        98,
        45,
        99,
        83,
        58,
        0,
        0,
        60,
        29,
        42,
        0,
        67,
        59,
        19,
        13,
        92,
        75,
        13,
        4,
        21,
        99,
        60,
        6,
        13,
        0,
        84,
        93,
        80,
        99,
        35,
        99,
        46,
        50,
        75,
        99,
        52
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyUsage": [
        55,
        54,
        184,
        5,
        0,
        91,
        179,
        117,
        65,
        1,
        76,
        57,
        113,
        10,
        144,
        57,
        10,
        152,
        70,
        19,
        142,
        4,
        195,
        155,
        56,
        41,
        197,
        95,
        117,
        96,
        199,
        124,
        197,
        199,
        198,
        36,
        135,
        44,
        90,
        77,
        51,
        11,
        0,
        79,
        190,
        181,
        0,
        0,
        198,
        17,
        168,
        3
      ],
      "weeklyWasted": [
        198,
        36,
        135,
        44,
        90,
        77,
        51,
        11,
        0,
        79,
        190,
        181,
        0,
        0,
        198,
        17,
        168,
        3,
        91,
        27,
        99,
        4,
        98,
        1,
        27,
        4,
        69,
        49,
        99,
        58,
        17,
        15,
        45,
        86,
        98,
        99,
        10,
        20,
        73,
        0,
        78,
        15,
        37,
        51,
        98,
        67,
        34,
        5,
        37,
        77,
        24,
        73,
        54,
        12,
        99,
        51,
        99,
        85,
        25,
        98,
        99,
        99,
        14,
        22,
        0,
        33,
        69,
        92,
        56,
        26
      ]
    },
    {
      "id": "C71B9801",
      "weeklyUsage": [
        145,
        2,
        162,
        51,
        0,
        59,
        87,
        86,
        86,
        1,
        125,
        87,
        113,
        56,
        138,
        112,
        192,
        122,
        3,
        62,
        148,
        4,
        169,
        195,
        171,
        138,
        159,
        60,
        0,
        135,
        0,
        149,
        28,
        199,
        4,
        62,
        43,
        67,
        87,
        43,
        0,
        149,
        0,
        107,
        56,
        196,
        0,
        7,
        130,
        98,
        125,
        139
      ],
      "weeklyWasted": [
        4,
        62,
        43,
        67,
        87,
        43,
        0,
        149,
        0,
        107,
        56,
        196,
        0,
        7,
        130,
        98,
        125,
        139,
        1,
        1,
        43,
        10,
        0,
        39,
        99,
        10,
        44,
        0,
        68,
        67,
        54,
        99,
        48,
        1,
        58,
        61,
        11,
        67,
        51,
        20,
        64,
        0,
        84,
        87,
        36,
        81,
        92,
        56,
        10,
        86,
        23,
        98,
        22,
        20,
        1,
        84,
        49,
        23,
        5,
        82,
        99,
        99,
        84,
        41,
        47,
        98,
        56,
        73,
        0,
        43
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        191,
        165,
        0,
        8,
        0,
        1,
        194,
        197,
        81,
        1,
        199,
        8,
        198,
        15,
        199,
        156,
        110,
        178,
        42,
        63,
        118,
        127,
        197,
        113,
        93,
        97,
        92,
        67,
        199,
        116,
        67,
        0,
        37,
        119,
        111,
        199,
        0,
        40,
        79,
        5,
        125,
        83,
        18,
        198,
        1,
        157,
        16,
        8,
        198,
        49,
        197,
        48
      ],
      "weeklyWasted": [
        111,
        199,
        0,
        40,
        79,
        5,
        125,
        83,
        18,
        198,
        1,
        157,
        16,
        8,
        198,
        49,
        197,
        48,
        27,
        24,
        57,
        2,
        98,
        98,
        15,
        9,
        99,
        0,
        99,
        79,
        66,
        89,
        59,
        98,
        83,
        99,
        46,
        46,
        6,
        65,
        30,
        69,
        48,
        44,
        25,
        28,
        99,
        54,
        2,
        97,
        1,
        64,
        1,
        13,
        99,
        99,
        99,
        86,
        35,
        33,
        64,
        95,
        99,
        0,
        94,
        98,
        49,
        99,
        81,
        65
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        199,
        124,
        48,
        59,
        198,
        1,
        143,
        42,
        65,
        1,
        154,
        23,
        160,
        89,
        151,
        41,
        192,
        127,
        149,
        127,
        134,
        73,
        63,
        106,
        61,
        106,
        158,
        3,
        199,
        98,
        193,
        114,
        54,
        86,
        153,
        199,
        38,
        43,
        76,
        199,
        152,
        67,
        0,
        129,
        1,
        133,
        24,
        90,
        193,
        115,
        141,
        3
      ],
      "weeklyWasted": [
        153,
        199,
        38,
        43,
        76,
        199,
        152,
        67,
        0,
        129,
        1,
        133,
        24,
        90,
        193,
        115,
        141,
        3,
        21,
        8,
        40,
        2,
        86,
        62,
        72,
        12,
        67,
        56,
        29,
        24,
        20,
        99,
        68,
        47,
        2,
        75,
        69,
        46,
        27,
        73,
        18,
        15,
        35,
        99,
        1,
        37,
        2,
        58,
        2,
        64,
        50,
        1,
        56,
        48,
        13,
        61,
        39,
        94,
        60,
        24,
        99,
        99,
        74,
        38,
        71,
        84,
        13,
        95,
        99,
        31
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        199,
        62,
        85,
        87,
        80,
        118,
        156,
        135,
        184,
        99,
        199,
        34,
        162,
        0,
        1,
        158,
        184,
        143,
        63,
        76,
        62,
        27,
        2,
        129,
        52,
        93,
        197,
        1,
        199,
        34,
        85,
        0,
        43,
        145,
        109,
        41,
        85,
        95,
        86,
        42,
        52,
        114,
        80,
        70,
        73,
        122,
        127,
        195,
        198,
        124,
        2,
        38
      ],
      "weeklyWasted": [
        109,
        41,
        85,
        95,
        86,
        42,
        52,
        114,
        80,
        70,
        73,
        122,
        127,
        195,
        198,
        124,
        2,
        38,
        29,
        7,
        47,
        2,
        98,
        1,
        51,
        19,
        99,
        85,
        44,
        2,
        51,
        99,
        92,
        56,
        10,
        99,
        25,
        19,
        96,
        28,
        26,
        32,
        42,
        34,
        37,
        1,
        45,
        18,
        2,
        67,
        13,
        98,
        0,
        30,
        92,
        45,
        27,
        93,
        22,
        0,
        45,
        66,
        29,
        91,
        84,
        70,
        92,
        76,
        99,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        125,
        82,
        42,
        5,
        115,
        177,
        17,
        97,
        2,
        1,
        73,
        2,
        95,
        64,
        199,
        151,
        99,
        92,
        121,
        30,
        130,
        20,
        174,
        145,
        46,
        130,
        157,
        1,
        75,
        84,
        28,
        18,
        1,
        4,
        73,
        132,
        144,
        106,
        44,
        40,
        79,
        47,
        69,
        157,
        80,
        144,
        0,
        180,
        198,
        89,
        69,
        121
      ],
      "weeklyWasted": [
        73,
        132,
        144,
        106,
        44,
        40,
        79,
        47,
        69,
        157,
        80,
        144,
        0,
        180,
        198,
        89,
        69,
        121,
        20,
        1,
        12,
        43,
        98,
        1,
        60,
        12,
        55,
        55,
        10,
        72,
        65,
        52,
        55,
        1,
        74,
        99,
        99,
        72,
        64,
        38,
        50,
        0,
        77,
        99,
        98,
        83,
        99,
        78,
        2,
        13,
        18,
        98,
        77,
        52,
        61,
        38,
        57,
        75,
        23,
        98,
        5,
        62,
        77,
        56,
        89,
        98,
        73,
        1,
        47,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        40,
        119,
        143,
        5,
        78,
        94,
        194,
        34,
        33,
        118,
        111,
        2,
        189,
        97,
        15,
        76,
        93,
        199,
        55,
        127,
        197,
        4,
        76,
        37,
        3,
        137,
        129,
        70,
        0,
        120,
        86,
        102,
        1,
        199,
        134,
        0,
        157,
        68,
        121,
        181,
        0,
        40,
        0,
        0,
        68,
        183,
        0,
        55,
        198,
        2,
        134,
        3
      ],
      "weeklyWasted": [
        134,
        0,
        157,
        68,
        121,
        181,
        0,
        40,
        0,
        0,
        68,
        183,
        0,
        55,
        198,
        2,
        134,
        3,
        99,
        59,
        99,
        76,
        98,
        29,
        90,
        1,
        89,
        69,
        60,
        54,
        99,
        35,
        0,
        35,
        98,
        59,
        19,
        99,
        39,
        67,
        82,
        0,
        20,
        88,
        0,
        47,
        99,
        15,
        17,
        82,
        99,
        60,
        15,
        0,
        4,
        51,
        40,
        35,
        96,
        3,
        99,
        88,
        90,
        99,
        97,
        27,
        99,
        95,
        71,
        1
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        130,
        126,
        90,
        136,
        116,
        107,
        194,
        87,
        2,
        9,
        192,
        21,
        172,
        0,
        199,
        117,
        167,
        57,
        197,
        124,
        106,
        26,
        105,
        60,
        47,
        176,
        197,
        50,
        170,
        146,
        64,
        24,
        197,
        66,
        78,
        139,
        127,
        5,
        60,
        0,
        34,
        0,
        0,
        54,
        1,
        124,
        0,
        78,
        159,
        199,
        111,
        57
      ],
      "weeklyWasted": [
        78,
        139,
        127,
        5,
        60,
        0,
        34,
        0,
        0,
        54,
        1,
        124,
        0,
        78,
        159,
        199,
        111,
        57,
        1,
        1,
        66,
        40,
        98,
        58,
        99,
        0,
        9,
        0,
        87,
        87,
        99,
        63,
        49,
        38,
        46,
        99,
        35,
        0,
        99,
        29,
        71,
        0,
        21,
        99,
        66,
        74,
        99,
        15,
        23,
        62,
        96,
        44,
        99,
        81,
        1,
        54,
        99,
        55,
        87,
        0,
        84,
        24,
        91,
        33,
        14,
        98,
        38,
        95,
        18,
        49
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        33,
        169,
        34,
        131,
        65,
        1,
        102,
        82,
        27,
        1,
        0,
        73,
        93,
        0,
        166,
        154,
        112,
        132,
        126,
        87,
        156,
        117,
        88,
        138,
        116,
        117,
        189,
        71,
        150,
        136,
        89,
        71,
        83,
        199,
        140,
        23,
        111,
        5,
        137,
        28,
        37,
        93,
        0,
        75,
        1,
        196,
        64,
        162,
        183,
        125,
        49,
        5
      ],
      "weeklyWasted": [
        140,
        23,
        111,
        5,
        137,
        28,
        37,
        93,
        0,
        75,
        1,
        196,
        64,
        162,
        183,
        125,
        49,
        5,
        17,
        6,
        15,
        2,
        13,
        53,
        35,
        0,
        80,
        3,
        32,
        70,
        14,
        96,
        50,
        25,
        6,
        99,
        11,
        22,
        69,
        17,
        85,
        28,
        51,
        34,
        98,
        73,
        99,
        38,
        2,
        58,
        52,
        16,
        62,
        75,
        41,
        49,
        81,
        31,
        5,
        32,
        99,
        73,
        99,
        67,
        26,
        3,
        33,
        77,
        31,
        37
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        118,
        2,
        133,
        33,
        102,
        115,
        191,
        46,
        190,
        1,
        20,
        39,
        172,
        10,
        7,
        92,
        100,
        175,
        197,
        34,
        197,
        157,
        147,
        63,
        3,
        198,
        172,
        40,
        47,
        198,
        49,
        135,
        90,
        199,
        29,
        65,
        40,
        199,
        84,
        159,
        8,
        42,
        0,
        113,
        27,
        195,
        48,
        33,
        198,
        101,
        82,
        163
      ],
      "weeklyWasted": [
        29,
        65,
        40,
        199,
        84,
        159,
        8,
        42,
        0,
        113,
        27,
        195,
        48,
        33,
        198,
        101,
        82,
        163,
        18,
        11,
        78,
        2,
        98,
        1,
        40,
        19,
        99,
        99,
        60,
        1,
        51,
        99,
        26,
        98,
        35,
        41,
        30,
        41,
        92,
        34,
        35,
        63,
        27,
        94,
        13,
        75,
        65,
        1,
        3,
        2,
        73,
        49,
        50,
        40,
        99,
        0,
        11,
        94,
        0,
        0,
        38,
        79,
        48,
        53,
        66,
        30,
        41,
        88,
        29,
        18
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        48,
        44,
        153,
        5,
        90,
        72,
        3,
        147,
        199,
        2,
        199,
        29,
        198,
        131,
        199,
        164,
        104,
        174,
        81,
        2,
        90,
        80,
        99,
        101,
        197,
        110,
        146,
        44,
        0,
        89,
        100,
        73,
        129,
        95,
        4,
        0,
        40,
        195,
        105,
        116,
        33,
        54,
        25,
        13,
        14,
        4,
        127,
        144,
        78,
        199,
        2,
        114
      ],
      "weeklyWasted": [
        4,
        0,
        40,
        195,
        105,
        116,
        33,
        54,
        25,
        13,
        14,
        4,
        127,
        144,
        78,
        199,
        2,
        114,
        54,
        1,
        81,
        31,
        98,
        37,
        99,
        0,
        99,
        19,
        35,
        83,
        8,
        3,
        30,
        93,
        98,
        63,
        27,
        41,
        95,
        0,
        16,
        51,
        27,
        99,
        42,
        76,
        23,
        7,
        2,
        95,
        61,
        98,
        99,
        27,
        55,
        0,
        47,
        5,
        40,
        0,
        99,
        60,
        71,
        0,
        70,
        37,
        26,
        67,
        30,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        199,
        81,
        112,
        5,
        77,
        197,
        194,
        197,
        2,
        77,
        199,
        158,
        182,
        149,
        199,
        198,
        192,
        156,
        178,
        55,
        122,
        120,
        44,
        157,
        40,
        198,
        70,
        81,
        3,
        82,
        86,
        195,
        197,
        56,
        198,
        199,
        130,
        107,
        199,
        2,
        0,
        199,
        0,
        174,
        23,
        4,
        0,
        198,
        198,
        187,
        117,
        15
      ],
      "weeklyWasted": [
        198,
        199,
        130,
        107,
        199,
        2,
        0,
        199,
        0,
        174,
        23,
        4,
        0,
        198,
        198,
        187,
        117,
        15,
        49,
        31,
        26,
        92,
        92,
        97,
        99,
        0,
        24,
        32,
        55,
        1,
        42,
        92,
        77,
        38,
        0,
        1,
        23,
        50,
        2,
        39,
        65,
        90,
        28,
        5,
        62,
        65,
        65,
        44,
        17,
        69,
        50,
        98,
        77,
        61,
        96,
        87,
        99,
        58,
        58,
        0,
        67,
        37,
        65,
        5,
        83,
        36,
        90,
        99,
        69,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyUsage": [
        199,
        66,
        174,
        5,
        21,
        176,
        63,
        197,
        75,
        1,
        171,
        116,
        117,
        17,
        191,
        78,
        192,
        102,
        136,
        3,
        154,
        4,
        134,
        1,
        3,
        41,
        179,
        188,
        144,
        121,
        199,
        191,
        97,
        4,
        152,
        152,
        72,
        142,
        94,
        103,
        139,
        187,
        0,
        69,
        71,
        4,
        78,
        58,
        105,
        151,
        167,
        142
      ],
      "weeklyWasted": [
        152,
        152,
        72,
        142,
        94,
        103,
        139,
        187,
        0,
        69,
        71,
        4,
        78,
        58,
        105,
        151,
        167,
        142,
        68,
        17,
        0,
        2,
        89,
        51,
        0,
        0,
        99,
        30,
        92,
        90,
        34,
        76,
        91,
        1,
        9,
        24,
        52,
        73,
        29,
        19,
        42,
        29,
        5,
        99,
        94,
        97,
        57,
        26,
        36,
        37,
        49,
        77,
        23,
        34,
        42,
        25,
        54,
        99,
        0,
        17,
        99,
        87,
        99,
        76,
        99,
        61,
        74,
        93,
        99,
        42
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        189,
        2,
        86,
        78,
        0,
        78,
        64,
        86,
        145,
        1,
        39,
        73,
        99,
        0,
        187,
        43,
        79,
        58,
        117,
        92,
        50,
        111,
        134,
        21,
        47,
        195,
        104,
        65,
        0,
        41,
        56,
        137,
        9,
        102,
        41,
        11,
        0,
        87,
        37,
        79,
        85,
        30,
        115,
        19,
        75,
        138,
        117,
        141,
        172,
        130,
        113,
        57
      ],
      "weeklyWasted": [
        41,
        11,
        0,
        87,
        37,
        79,
        85,
        30,
        115,
        19,
        75,
        138,
        117,
        141,
        172,
        130,
        113,
        57,
        53,
        40,
        16,
        28,
        75,
        86,
        43,
        13,
        0,
        37,
        73,
        58,
        14,
        99,
        0,
        7,
        6,
        51,
        1,
        62,
        2,
        5,
        88,
        60,
        0,
        24,
        98,
        24,
        2,
        17,
        2,
        83,
        1,
        50,
        95,
        38,
        74,
        56,
        37,
        69,
        0,
        36,
        58,
        58,
        22,
        0,
        99,
        98,
        60,
        48,
        35,
        98
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        44,
        2,
        88,
        52,
        143,
        1,
        69,
        102,
        73,
        62,
        0,
        33,
        122,
        36,
        199,
        135,
        27,
        186,
        97,
        97,
        152,
        149,
        71,
        153,
        197,
        146,
        116,
        1,
        199,
        37,
        20,
        0,
        126,
        163,
        130,
        64,
        86,
        31,
        167,
        74,
        0,
        77,
        31,
        0,
        100,
        4,
        104,
        66,
        198,
        98,
        115,
        140
      ],
      "weeklyWasted": [
        130,
        64,
        86,
        31,
        167,
        74,
        0,
        77,
        31,
        0,
        100,
        4,
        104,
        66,
        198,
        98,
        115,
        140,
        28,
        1,
        99,
        76,
        88,
        76,
        99,
        0,
        99,
        58,
        75,
        80,
        1,
        83,
        36,
        2,
        62,
        99,
        11,
        99,
        41,
        20,
        26,
        0,
        84,
        52,
        50,
        24,
        79,
        1,
        2,
        97,
        1,
        4,
        99,
        5,
        98,
        50,
        64,
        94,
        23,
        7,
        99,
        1,
        98,
        0,
        73,
        98,
        64,
        51,
        17,
        6
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        55,
        75,
        89,
        88,
        64,
        157,
        182,
        55,
        70,
        1,
        149,
        184,
        182,
        23,
        84,
        3,
        125,
        125,
        197,
        175,
        128,
        122,
        143,
        126,
        3,
        130,
        143,
        60,
        199,
        198,
        191,
        89,
        38,
        199,
        39,
        23,
        198,
        5,
        117,
        33,
        0,
        124,
        64,
        185,
        1,
        144,
        23,
        143,
        198,
        40,
        120,
        71
      ],
      "weeklyWasted": [
        39,
        23,
        198,
        5,
        117,
        33,
        0,
        124,
        64,
        185,
        1,
        144,
        23,
        143,
        198,
        40,
        120,
        71,
        66,
        33,
        43,
        37,
        98,
        75,
        72,
        48,
        65,
        59,
        88,
        43,
        47,
        83,
        45,
        38,
        4,
        99,
        2,
        55,
        54,
        52,
        22,
        73,
        34,
        62,
        98,
        62,
        88,
        38,
        2,
        58,
        55,
        55,
        91,
        82,
        61,
        99,
        99,
        78,
        1,
        3,
        72,
        54,
        43,
        43,
        99,
        98,
        46,
        89,
        55,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        145,
        129,
        77,
        68,
        0,
        127,
        106,
        47,
        2,
        1,
        108,
        21,
        152,
        96,
        199,
        3,
        192,
        102,
        137,
        2,
        168,
        152,
        101,
        178,
        16,
        190,
        98,
        105,
        39,
        65,
        34,
        110,
        1,
        88,
        59,
        31,
        132,
        168,
        72,
        171,
        199,
        0,
        122,
        73,
        20,
        48,
        0,
        31,
        34,
        185,
        62,
        188
      ],
      "weeklyWasted": [
        59,
        31,
        132,
        168,
        72,
        171,
        199,
        0,
        122,
        73,
        20,
        48,
        0,
        31,
        34,
        185,
        62,
        188,
        49,
        13,
        77,
        79,
        98,
        23,
        0,
        34,
        99,
        25,
        86,
        49,
        77,
        99,
        0,
        35,
        32,
        99,
        52,
        35,
        41,
        0,
        97,
        99,
        99,
        54,
        51,
        5,
        15,
        30,
        2,
        97,
        18,
        98,
        99,
        38,
        77,
        99,
        99,
        87,
        43,
        0,
        61,
        99,
        69,
        0,
        99,
        54,
        21,
        92,
        89,
        1
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        187,
        87,
        66,
        184,
        49,
        15,
        37,
        121,
        85,
        17,
        184,
        52,
        188,
        0,
        199,
        67,
        98,
        185,
        3,
        28,
        65,
        4,
        197,
        65,
        15,
        59,
        28,
        108,
        186,
        136,
        95,
        78,
        172,
        4,
        107,
        199,
        151,
        5,
        114,
        13,
        0,
        62,
        101,
        143,
        1,
        4,
        90,
        19,
        198,
        73,
        188,
        3
      ],
      "weeklyWasted": [
        107,
        199,
        151,
        5,
        114,
        13,
        0,
        62,
        101,
        143,
        1,
        4,
        90,
        19,
        198,
        73,
        188,
        3,
        7,
        22,
        99,
        2,
        98,
        72,
        46,
        74,
        99,
        64,
        53,
        95,
        28,
        99,
        12,
        24,
        44,
        99,
        37,
        58,
        24,
        44,
        18,
        0,
        66,
        72,
        74,
        57,
        75,
        17,
        2,
        97,
        44,
        98,
        68,
        47,
        59,
        41,
        23,
        68,
        46,
        86,
        52,
        66,
        57,
        0,
        41,
        67,
        0,
        98,
        76,
        1
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        112,
        76,
        191,
        34,
        198,
        188,
        194,
        3,
        70,
        1,
        79,
        2,
        125,
        58,
        177,
        70,
        192,
        128,
        75,
        105,
        49,
        110,
        82,
        172,
        5,
        108,
        197,
        88,
        199,
        105,
        199,
        0,
        47,
        45,
        62,
        156,
        198,
        173,
        84,
        170,
        114,
        19,
        2,
        126,
        7,
        10,
        121,
        169,
        198,
        2,
        92,
        3
      ],
      "weeklyWasted": [
        62,
        156,
        198,
        173,
        84,
        170,
        114,
        19,
        2,
        126,
        7,
        10,
        121,
        169,
        198,
        2,
        92,
        3,
        59,
        1,
        5,
        2,
        40,
        30,
        99,
        33,
        69,
        87,
        66,
        54,
        62,
        81,
        63,
        31,
        28,
        72,
        1,
        60,
        99,
        29,
        87,
        47,
        99,
        82,
        79,
        29,
        81,
        18,
        2,
        48,
        26,
        85,
        99,
        13,
        69,
        99,
        34,
        91,
        9,
        0,
        99,
        43,
        61,
        81,
        99,
        38,
        0,
        99,
        99,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        50,
        32,
        170,
        36,
        130,
        63,
        34,
        3,
        161,
        1,
        1,
        77,
        50,
        29,
        193,
        198,
        164,
        155,
        195,
        2,
        169,
        4,
        2,
        158,
        3,
        182,
        181,
        110,
        151,
        108,
        101,
        41,
        150,
        145,
        94,
        63,
        113,
        199,
        199,
        52,
        0,
        103,
        51,
        21,
        1,
        195,
        141,
        177,
        198,
        89,
        134,
        91
      ],
      "weeklyWasted": [
        94,
        63,
        113,
        199,
        199,
        52,
        0,
        103,
        51,
        21,
        1,
        195,
        141,
        177,
        198,
        89,
        134,
        91,
        99,
        63,
        0,
        21,
        98,
        57,
        94,
        0,
        66,
        56,
        99,
        63,
        78,
        6,
        64,
        44,
        69,
        75,
        1,
        33,
        89,
        0,
        43,
        0,
        17,
        81,
        73,
        65,
        99,
        3,
        14,
        22,
        59,
        50,
        99,
        37,
        68,
        30,
        76,
        83,
        48,
        50,
        99,
        72,
        0,
        89,
        41,
        98,
        41,
        33,
        99,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        195,
        166,
        172,
        82,
        94,
        66,
        171,
        11,
        2,
        1,
        175,
        69,
        2,
        75,
        182,
        20,
        139,
        34,
        46,
        101,
        3,
        4,
        137,
        195,
        145,
        74,
        158,
        145,
        199,
        118,
        199,
        0,
        149,
        88,
        9,
        199,
        198,
        55,
        100,
        98,
        73,
        3,
        143,
        11,
        66,
        196,
        9,
        82,
        148,
        87,
        136,
        16
      ],
      "weeklyWasted": [
        9,
        199,
        198,
        55,
        100,
        98,
        73,
        3,
        143,
        11,
        66,
        196,
        9,
        82,
        148,
        87,
        136,
        16,
        45,
        35,
        0,
        2,
        0,
        93,
        68,
        0,
        55,
        0,
        49,
        83,
        1,
        86,
        76,
        52,
        42,
        99,
        1,
        55,
        88,
        99,
        23,
        3,
        24,
        46,
        42,
        24,
        15,
        57,
        48,
        89,
        1,
        86,
        0,
        18,
        77,
        99,
        7,
        99,
        24,
        68,
        19,
        48,
        43,
        69,
        67,
        81,
        99,
        24,
        99,
        8
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        35,
        94,
        198,
        48,
        0,
        139,
        76,
        78,
        183,
        1,
        136,
        197,
        131,
        32,
        196,
        137,
        132,
        143,
        23,
        29,
        118,
        136,
        143,
        89,
        183,
        53,
        197,
        47,
        199,
        138,
        132,
        0,
        52,
        50,
        56,
        104,
        164,
        197,
        199,
        199,
        133,
        0,
        101,
        0,
        41,
        196,
        0,
        74,
        104,
        44,
        187,
        3
      ],
      "weeklyWasted": [
        56,
        104,
        164,
        197,
        199,
        199,
        133,
        0,
        101,
        0,
        41,
        196,
        0,
        74,
        104,
        44,
        187,
        3,
        37,
        1,
        76,
        72,
        47,
        29,
        15,
        0,
        81,
        22,
        43,
        99,
        47,
        70,
        70,
        55,
        76,
        80,
        99,
        65,
        22,
        25,
        68,
        0,
        20,
        99,
        60,
        97,
        93,
        1,
        4,
        86,
        23,
        98,
        55,
        35,
        50,
        99,
        0,
        1,
        14,
        56,
        20,
        99,
        99,
        48,
        99,
        94,
        99,
        66,
        14,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        199,
        34,
        85,
        145,
        52,
        110,
        90,
        3,
        91,
        3,
        155,
        77,
        159,
        0,
        30,
        3,
        72,
        192,
        197,
        60,
        197,
        4,
        131,
        70,
        168,
        1,
        197,
        1,
        57,
        55,
        124,
        10,
        61,
        107,
        7,
        67,
        116,
        185,
        10,
        197,
        116,
        0,
        63,
        151,
        1,
        4,
        23,
        100,
        198,
        108,
        69,
        159
      ],
      "weeklyWasted": [
        7,
        67,
        116,
        185,
        10,
        197,
        116,
        0,
        63,
        151,
        1,
        4,
        23,
        100,
        198,
        108,
        69,
        159,
        41,
        1,
        1,
        2,
        84,
        1,
        44,
        1,
        90,
        46,
        87,
        67,
        76,
        67,
        18,
        32,
        50,
        41,
        50,
        66,
        59,
        8,
        18,
        66,
        79,
        74,
        95,
        14,
        2,
        34,
        2,
        95,
        1,
        82,
        32,
        71,
        36,
        99,
        99,
        58,
        0,
        16,
        68,
        99,
        58,
        87,
        99,
        58,
        99,
        71,
        11,
        1
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        7,
        69,
        124,
        42,
        126,
        115,
        194,
        147,
        2,
        1,
        199,
        2,
        175,
        167,
        1,
        72,
        153,
        128,
        197,
        74,
        90,
        23,
        87,
        1,
        79,
        56,
        173,
        171,
        199,
        198,
        33,
        97,
        197,
        106,
        163,
        130,
        0,
        143,
        87,
        43,
        70,
        28,
        0,
        44,
        143,
        85,
        166,
        10,
        142,
        93,
        29,
        3
      ],
      "weeklyWasted": [
        163,
        130,
        0,
        143,
        87,
        43,
        70,
        28,
        0,
        44,
        143,
        85,
        166,
        10,
        142,
        93,
        29,
        3,
        17,
        1,
        95,
        24,
        72,
        32,
        34,
        0,
        89,
        86,
        99,
        1,
        44,
        80,
        65,
        44,
        78,
        99,
        1,
        45,
        69,
        87,
        41,
        30,
        28,
        82,
        98,
        97,
        86,
        8,
        2,
        29,
        23,
        27,
        72,
        42,
        99,
        99,
        41,
        6,
        8,
        26,
        99,
        99,
        87,
        46,
        99,
        92,
        99,
        74,
        99,
        32
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        139,
        96,
        105,
        5,
        71,
        1,
        57,
        131,
        199,
        9,
        157,
        131,
        104,
        33,
        199,
        23,
        175,
        82,
        80,
        2,
        29,
        74,
        174,
        187,
        17,
        56,
        168,
        37,
        58,
        96,
        103,
        196,
        48,
        135,
        38,
        0,
        130,
        179,
        10,
        26,
        15,
        129,
        199,
        101,
        168,
        89,
        50,
        97,
        198,
        170,
        21,
        81
      ],
      "weeklyWasted": [
        38,
        0,
        130,
        179,
        10,
        26,
        15,
        129,
        199,
        101,
        168,
        89,
        50,
        97,
        198,
        170,
        21,
        81,
        17,
        24,
        1,
        2,
        86,
        65,
        99,
        62,
        89,
        2,
        50,
        77,
        77,
        99,
        0,
        7,
        49,
        99,
        1,
        3,
        61,
        44,
        0,
        99,
        29,
        99,
        98,
        38,
        68,
        75,
        42,
        77,
        97,
        71,
        99,
        46,
        62,
        82,
        48,
        57,
        8,
        13,
        99,
        94,
        63,
        45,
        6,
        75,
        52,
        84,
        92,
        1
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        64,
        16,
        66,
        6,
        131,
        1,
        153,
        18,
        97,
        199,
        199,
        64,
        165,
        40,
        165,
        148,
        43,
        140,
        91,
        2,
        1,
        65,
        94,
        1,
        3,
        43,
        141,
        1,
        150,
        92,
        48,
        9,
        104,
        142,
        114,
        199,
        39,
        164,
        125,
        60,
        0,
        144,
        169,
        169,
        31,
        133,
        131,
        44,
        198,
        85,
        45,
        67
      ],
      "weeklyWasted": [
        114,
        199,
        39,
        164,
        125,
        60,
        0,
        144,
        169,
        169,
        31,
        133,
        131,
        44,
        198,
        85,
        45,
        67,
        1,
        6,
        17,
        28,
        74,
        98,
        48,
        74,
        55,
        71,
        69,
        87,
        64,
        97,
        63,
        50,
        0,
        74,
        95,
        75,
        2,
        25,
        0,
        35,
        0,
        27,
        44,
        97,
        12,
        1,
        59,
        86,
        61,
        98,
        99,
        39,
        99,
        35,
        53,
        28,
        29,
        60,
        42,
        99,
        30,
        11,
        63,
        94,
        31,
        55,
        34,
        37
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        91,
        26,
        79,
        8,
        0,
        98,
        96,
        197,
        2,
        28,
        122,
        162,
        149,
        171,
        199,
        108,
        114,
        156,
        3,
        178,
        197,
        9,
        119,
        147,
        118,
        164,
        138,
        1,
        138,
        134,
        199,
        64,
        99,
        106,
        50,
        154,
        78,
        114,
        76,
        199,
        102,
        0,
        60,
        0,
        45,
        132,
        27,
        0,
        198,
        145,
        181,
        3
      ],
      "weeklyWasted": [
        50,
        154,
        78,
        114,
        76,
        199,
        102,
        0,
        60,
        0,
        45,
        132,
        27,
        0,
        198,
        145,
        181,
        3,
        99,
        2,
        28,
        16,
        98,
        27,
        79,
        62,
        0,
        86,
        46,
        54,
        70,
        99,
        44,
        1,
        35,
        22,
        32,
        99,
        84,
        35,
        77,
        57,
        0,
        72,
        57,
        97,
        45,
        43,
        82,
        97,
        1,
        68,
        31,
        32,
        22,
        99,
        12,
        38,
        0,
        0,
        99,
        67,
        99,
        57,
        99,
        6,
        99,
        57,
        99,
        57
      ]
    },
    {
      "id": "7B415249",
      "weeklyUsage": [
        172,
        2,
        198,
        147,
        62,
        30,
        156,
        72,
        125,
        1,
        0,
        188,
        160,
        199,
        91,
        3,
        87,
        152,
        88,
        2,
        197,
        198,
        188,
        171,
        66,
        154,
        35,
        55,
        199,
        65,
        163,
        0,
        1,
        182,
        147,
        81,
        135,
        83,
        44,
        39,
        0,
        196,
        17,
        59,
        60,
        100,
        38,
        0,
        197,
        93,
        159,
        3
      ],
      "weeklyWasted": [
        147,
        81,
        135,
        83,
        44,
        39,
        0,
        196,
        17,
        59,
        60,
        100,
        38,
        0,
        197,
        93,
        159,
        3,
        43,
        59,
        39,
        2,
        98,
        90,
        78,
        46,
        84,
        27,
        99,
        45,
        67,
        3,
        26,
        51,
        98,
        71,
        15,
        88,
        50,
        24,
        0,
        5,
        7,
        1,
        70,
        71,
        84,
        34,
        2,
        82,
        1,
        93,
        99,
        0,
        89,
        81,
        99,
        98,
        0,
        98,
        35,
        38,
        63,
        14,
        0,
        59,
        60,
        70,
        51,
        1
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        7,
        115,
        174,
        5,
        100,
        101,
        18,
        35,
        79,
        1,
        67,
        2,
        198,
        66,
        199,
        51,
        142,
        69,
        105,
        92,
        15,
        121,
        197,
        150,
        107,
        198,
        156,
        82,
        64,
        65,
        0,
        149,
        25,
        35,
        139,
        199,
        19,
        184,
        156,
        33,
        87,
        120,
        88,
        24,
        59,
        196,
        147,
        38,
        1,
        168,
        56,
        177
      ],
      "weeklyWasted": [
        139,
        199,
        19,
        184,
        156,
        33,
        87,
        120,
        88,
        24,
        59,
        196,
        147,
        38,
        1,
        168,
        56,
        177,
        67,
        1,
        20,
        27,
        0,
        26,
        99,
        0,
        99,
        97,
        99,
        99,
        1,
        47,
        83,
        93,
        57,
        99,
        7,
        69,
        2,
        39,
        56,
        18,
        26,
        72,
        0,
        77,
        35,
        1,
        36,
        40,
        73,
        82,
        96,
        10,
        56,
        0,
        42,
        87,
        99,
        22,
        99,
        99,
        65,
        46,
        60,
        80,
        99,
        62,
        61,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        199,
        82,
        95,
        91,
        129,
        137,
        194,
        107,
        2,
        102,
        0,
        2,
        70,
        0,
        63,
        100,
        170,
        14,
        3,
        21,
        41,
        49,
        32,
        57,
        3,
        168,
        190,
        1,
        55,
        97,
        199,
        0,
        1,
        46,
        198,
        69,
        146,
        194,
        69,
        53,
        146,
        0,
        0,
        24,
        1,
        196,
        33,
        36,
        124,
        199,
        85,
        29
      ],
      "weeklyWasted": [
        198,
        69,
        146,
        194,
        69,
        53,
        146,
        0,
        0,
        24,
        1,
        196,
        33,
        36,
        124,
        199,
        85,
        29,
        7,
        1,
        4,
        30,
        98,
        46,
        51,
        54,
        71,
        94,
        72,
        39,
        1,
        99,
        43,
        20,
        53,
        99,
        19,
        45,
        79,
        0,
        40,
        0,
        17,
        35,
        2,
        1,
        99,
        27,
        17,
        97,
        11,
        63,
        19,
        67,
        75,
        81,
        99,
        99,
        22,
        0,
        59,
        99,
        81,
        56,
        0,
        98,
        4,
        38,
        41,
        49
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyUsage": [
        177,
        67,
        198,
        14,
        86,
        197,
        96,
        197,
        28,
        1,
        199,
        135,
        92,
        0,
        179,
        79,
        162,
        59,
        197,
        7,
        115,
        62,
        191,
        92,
        97,
        1,
        139,
        70,
        110,
        87,
        49,
        196,
        164,
        158,
        38,
        154,
        115,
        176,
        10,
        103,
        0,
        0,
        0,
        59,
        1,
        4,
        49,
        93,
        198,
        161,
        97,
        20
      ],
      "weeklyWasted": [
        38,
        154,
        115,
        176,
        10,
        103,
        0,
        0,
        0,
        59,
        1,
        4,
        49,
        93,
        198,
        161,
        97,
        20,
        3,
        7,
        77,
        10,
        58,
        1,
        72,
        15,
        3,
        91,
        69,
        54,
        68,
        76,
        63,
        22,
        24,
        99,
        21,
        40,
        66,
        2,
        97,
        9,
        49,
        99,
        44,
        93,
        2,
        75,
        4,
        70,
        37,
        84,
        60,
        0,
        48,
        70,
        0,
        31,
        29,
        0,
        99,
        81,
        52,
        0,
        64,
        98,
        51,
        85,
        39,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        125,
        174,
        145,
        23,
        62,
        1,
        194,
        3,
        79,
        135,
        199,
        78,
        103,
        47,
        97,
        82,
        192,
        1,
        197,
        28,
        48,
        30,
        19,
        58,
        124,
        94,
        176,
        25,
        91,
        70,
        69,
        0,
        11,
        50,
        79,
        68,
        188,
        195,
        90,
        109,
        36,
        10,
        0,
        101,
        1,
        4,
        190,
        78,
        163,
        2,
        93,
        56
      ],
      "weeklyWasted": [
        79,
        68,
        188,
        195,
        90,
        109,
        36,
        10,
        0,
        101,
        1,
        4,
        190,
        78,
        163,
        2,
        93,
        56,
        40,
        1,
        32,
        2,
        98,
        47,
        9,
        0,
        60,
        59,
        99,
        55,
        99,
        98,
        87,
        71,
        46,
        99,
        26,
        0,
        11,
        30,
        15,
        10,
        73,
        14,
        98,
        1,
        71,
        1,
        3,
        93,
        1,
        45,
        99,
        99,
        82,
        26,
        27,
        94,
        4,
        0,
        81,
        38,
        12,
        52,
        0,
        37,
        99,
        99,
        67,
        36
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        170,
        2,
        8,
        11,
        71,
        46,
        5,
        168,
        82,
        1,
        199,
        165,
        198,
        91,
        179,
        131,
        164,
        157,
        110,
        42,
        125,
        4,
        181,
        106,
        80,
        198,
        3,
        59,
        199,
        14,
        184,
        12,
        197,
        115,
        4,
        199,
        144,
        151,
        179,
        140,
        97,
        199,
        77,
        0,
        1,
        4,
        91,
        9,
        198,
        5,
        197,
        109
      ],
      "weeklyWasted": [
        4,
        199,
        144,
        151,
        179,
        140,
        97,
        199,
        77,
        0,
        1,
        4,
        91,
        9,
        198,
        5,
        197,
        109,
        43,
        52,
        82,
        7,
        98,
        35,
        30,
        15,
        66,
        0,
        33,
        93,
        56,
        96,
        15,
        40,
        0,
        99,
        10,
        58,
        37,
        25,
        42,
        58,
        28,
        1,
        39,
        54,
        2,
        96,
        47,
        49,
        24,
        90,
        43,
        11,
        99,
        49,
        69,
        99,
        22,
        89,
        84,
        99,
        69,
        0,
        99,
        0,
        66,
        44,
        99,
        8
      ]
    },
    {
      "id": "C71B9801",
      "weeklyUsage": [
        58,
        56,
        84,
        195,
        154,
        138,
        84,
        7,
        199,
        46,
        122,
        35,
        198,
        199,
        127,
        129,
        95,
        177,
        113,
        92,
        97,
        198,
        2,
        13,
        3,
        120,
        97,
        197,
        74,
        58,
        18,
        167,
        197,
        199,
        108,
        111,
        146,
        5,
        117,
        92,
        0,
        112,
        0,
        55,
        33,
        196,
        41,
        120,
        144,
        155,
        80,
        153
      ],
      "weeklyWasted": [
        108,
        111,
        146,
        5,
        117,
        92,
        0,
        112,
        0,
        55,
        33,
        196,
        41,
        120,
        144,
        155,
        80,
        153,
        62,
        5,
        37,
        59,
        64,
        98,
        45,
        0,
        61,
        19,
        99,
        99,
        52,
        13,
        24,
        27,
        52,
        82,
        23,
        16,
        25,
        0,
        46,
        0,
        14,
        99,
        61,
        75,
        2,
        22,
        2,
        1,
        81,
        72,
        99,
        36,
        60,
        61,
        0,
        1,
        83,
        0,
        28,
        13,
        15,
        83,
        51,
        25,
        73,
        2,
        99,
        1
      ]
    },
    {
      "id": "D9345860",
      "weeklyUsage": [
        199,
        2,
        163,
        56,
        147,
        1,
        169,
        10,
        80,
        1,
        150,
        144,
        16,
        101,
        199,
        121,
        160,
        199,
        54,
        2,
        81,
        145,
        129,
        103,
        3,
        1,
        197,
        1,
        199,
        54,
        195,
        50,
        25,
        32,
        111,
        130,
        40,
        107,
        124,
        17,
        0,
        65,
        118,
        97,
        71,
        196,
        0,
        170,
        164,
        7,
        178,
        100
      ],
      "weeklyWasted": [
        111,
        130,
        40,
        107,
        124,
        17,
        0,
        65,
        118,
        97,
        71,
        196,
        0,
        170,
        164,
        7,
        178,
        100,
        46,
        87,
        2,
        30,
        98,
        77,
        93,
        3,
        57,
        0,
        82,
        89,
        12,
        64,
        80,
        80,
        7,
        66,
        49,
        53,
        43,
        49,
        60,
        47,
        0,
        59,
        68,
        61,
        88,
        35,
        2,
        42,
        36,
        66,
        44,
        73,
        99,
        73,
        95,
        99,
        51,
        21,
        99,
        99,
        99,
        54,
        88,
        61,
        59,
        41,
        26,
        94
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        48,
        89,
        130,
        51,
        198,
        27,
        112,
        124,
        20,
        41,
        199,
        2,
        198,
        99,
        1,
        84,
        176,
        180,
        3,
        2,
        122,
        4,
        43,
        141,
        178,
        109,
        171,
        73,
        0,
        94,
        22,
        177,
        40,
        150,
        117,
        61,
        44,
        140,
        116,
        0,
        0,
        0,
        0,
        0,
        1,
        103,
        118,
        33,
        178,
        163,
        2,
        196
      ],
      "weeklyWasted": [
        117,
        61,
        44,
        140,
        116,
        0,
        0,
        0,
        0,
        0,
        1,
        103,
        118,
        33,
        178,
        163,
        2,
        196,
        1,
        1,
        0,
        24,
        7,
        74,
        80,
        0,
        78,
        54,
        78,
        59,
        63,
        41,
        47,
        19,
        74,
        75,
        31,
        77,
        54,
        67,
        5,
        77,
        99,
        99,
        59,
        51,
        56,
        43,
        2,
        40,
        69,
        59,
        85,
        41,
        96,
        41,
        99,
        75,
        0,
        88,
        73,
        96,
        99,
        55,
        38,
        67,
        20,
        15,
        75,
        57
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        155,
        60,
        110,
        146,
        0,
        67,
        92,
        3,
        118,
        120,
        199,
        96,
        94,
        196,
        93,
        90,
        70,
        101,
        27,
        87,
        111,
        124,
        2,
        89,
        107,
        102,
        155,
        37,
        0,
        33,
        199,
        41,
        1,
        31,
        109,
        150,
        100,
        50,
        10,
        71,
        147,
        13,
        0,
        0,
        82,
        83,
        12,
        76,
        182,
        78,
        69,
        50
      ],
      "weeklyWasted": [
        109,
        150,
        100,
        50,
        10,
        71,
        147,
        13,
        0,
        0,
        82,
        83,
        12,
        76,
        182,
        78,
        69,
        50,
        1,
        10,
        39,
        53,
        83,
        86,
        0,
        0,
        26,
        39,
        65,
        94,
        99,
        3,
        0,
        1,
        59,
        52,
        12,
        74,
        2,
        37,
        49,
        22,
        44,
        83,
        79,
        32,
        98,
        12,
        2,
        35,
        32,
        98,
        99,
        20,
        99,
        99,
        33,
        1,
        8,
        0,
        79,
        87,
        6,
        75,
        35,
        97,
        92,
        10,
        11,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        141,
        14,
        198,
        5,
        99,
        24,
        97,
        3,
        102,
        18,
        150,
        2,
        141,
        16,
        135,
        84,
        119,
        199,
        57,
        2,
        132,
        103,
        76,
        144,
        197,
        161,
        196,
        75,
        199,
        149,
        18,
        74,
        77,
        4,
        4,
        72,
        135,
        169,
        98,
        54,
        36,
        32,
        46,
        71,
        142,
        156,
        195,
        198,
        198,
        26,
        107,
        77
      ],
      "weeklyWasted": [
        4,
        72,
        135,
        169,
        98,
        54,
        36,
        32,
        46,
        71,
        142,
        156,
        195,
        198,
        198,
        26,
        107,
        77,
        22,
        1,
        22,
        16,
        98,
        7,
        73,
        0,
        18,
        29,
        99,
        61,
        15,
        45,
        30,
        74,
        78,
        99,
        49,
        80,
        2,
        40,
        76,
        0,
        0,
        94,
        98,
        60,
        98,
        1,
        2,
        75,
        27,
        98,
        57,
        38,
        69,
        37,
        99,
        80,
        15,
        40,
        73,
        42,
        99,
        96,
        69,
        77,
        99,
        68,
        46,
        2
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        7,
        59,
        83,
        18,
        0,
        82,
        80,
        77,
        92,
        1,
        199,
        159,
        28,
        168,
        7,
        88,
        80,
        139,
        99,
        197,
        60,
        101,
        59,
        195,
        128,
        115,
        94,
        33,
        199,
        120,
        45,
        144,
        81,
        13,
        117,
        0,
        136,
        194,
        199,
        129,
        106,
        199,
        0,
        50,
        114,
        196,
        161,
        108,
        198,
        170,
        158,
        3
      ],
      "weeklyWasted": [
        117,
        0,
        136,
        194,
        199,
        129,
        106,
        199,
        0,
        50,
        114,
        196,
        161,
        108,
        198,
        170,
        158,
        3,
        83,
        15,
        33,
        2,
        66,
        98,
        22,
        0,
        99,
        34,
        99,
        99,
        99,
        98,
        0,
        29,
        45,
        78,
        12,
        57,
        57,
        4,
        0,
        90,
        15,
        44,
        8,
        77,
        62,
        96,
        2,
        97,
        25,
        68,
        59,
        30,
        35,
        0,
        5,
        61,
        0,
        23,
        99,
        99,
        23,
        99,
        0,
        98,
        47,
        94,
        99,
        17
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        199,
        91,
        46,
        157,
        190,
        128,
        7,
        81,
        181,
        92,
        64,
        55,
        68,
        0,
        199,
        137,
        50,
        199,
        62,
        24,
        35,
        89,
        104,
        119,
        113,
        87,
        157,
        1,
        193,
        42,
        76,
        106,
        74,
        59,
        4,
        105,
        120,
        58,
        10,
        96,
        124,
        138,
        27,
        26,
        1,
        88,
        0,
        194,
        99,
        63,
        94,
        3
      ],
      "weeklyWasted": [
        4,
        105,
        120,
        58,
        10,
        96,
        124,
        138,
        27,
        26,
        1,
        88,
        0,
        194,
        99,
        63,
        94,
        3,
        99,
        35,
        42,
        54,
        98,
        98,
        38,
        0,
        99,
        69,
        99,
        90,
        81,
        87,
        1,
        10,
        66,
        75,
        29,
        8,
        53,
        20,
        0,
        0,
        28,
        54,
        78,
        55,
        69,
        1,
        2,
        77,
        80,
        98,
        99,
        6,
        13,
        39,
        65,
        93,
        85,
        52,
        91,
        99,
        32,
        33,
        15,
        0,
        72,
        39,
        79,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        199,
        96,
        63,
        31,
        96,
        46,
        154,
        20,
        134,
        1,
        199,
        53,
        198,
        159,
        199,
        14,
        67,
        59,
        133,
        152,
        111,
        66,
        2,
        1,
        3,
        104,
        112,
        128,
        199,
        115,
        31,
        196,
        1,
        54,
        198,
        174,
        198,
        159,
        112,
        26,
        94,
        199,
        4,
        0,
        1,
        57,
        74,
        0,
        161,
        132,
        2,
        100
      ],
      "weeklyWasted": [
        198,
        174,
        198,
        159,
        112,
        26,
        94,
        199,
        4,
        0,
        1,
        57,
        74,
        0,
        161,
        132,
        2,
        100,
        4,
        1,
        35,
        25,
        42,
        37,
        9,
        14,
        99,
        99,
        99,
        42,
        74,
        82,
        70,
        98,
        0,
        60,
        5,
        66,
        7,
        99,
        0,
        34,
        14,
        61,
        23,
        66,
        59,
        22,
        12,
        23,
        89,
        71,
        0,
        80,
        99,
        77,
        31,
        6,
        0,
        34,
        43,
        99,
        75,
        99,
        70,
        57,
        32,
        68,
        99,
        47
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyUsage": [
        56,
        2,
        110,
        5,
        92,
        176,
        70,
        53,
        2,
        133,
        129,
        35,
        198,
        199,
        199,
        96,
        68,
        180,
        3,
        125,
        177,
        144,
        96,
        166,
        93,
        110,
        192,
        100,
        43,
        84,
        41,
        175,
        117,
        86,
        26,
        155,
        99,
        199,
        84,
        9,
        0,
        74,
        71,
        0,
        112,
        192,
        145,
        59,
        198,
        5,
        152,
        181
      ],
      "weeklyWasted": [
        26,
        155,
        99,
        199,
        84,
        9,
        0,
        74,
        71,
        0,
        112,
        192,
        145,
        59,
        198,
        5,
        152,
        181,
        36,
        58,
        22,
        2,
        98,
        1,
        99,
        0,
        98,
        28,
        81,
        62,
        24,
        3,
        76,
        98,
        0,
        52,
        8,
        0,
        2,
        22,
        21,
        22,
        59,
        57,
        15,
        68,
        99,
        28,
        59,
        26,
        28,
        98,
        99,
        15,
        39,
        53,
        51,
        99,
        17,
        98,
        36,
        99,
        99,
        17,
        73,
        98,
        60,
        32,
        99,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        104,
        149,
        45,
        145,
        148,
        197,
        77,
        172,
        159,
        145,
        199,
        2,
        106,
        53,
        58,
        198,
        136,
        1,
        3,
        171,
        45,
        198,
        93,
        146,
        51,
        66,
        133,
        39,
        92,
        140,
        93,
        196,
        177,
        4,
        4,
        89,
        198,
        5,
        10,
        199,
        113,
        183,
        0,
        0,
        129,
        134,
        150,
        43,
        42,
        199,
        12,
        90
      ],
      "weeklyWasted": [
        4,
        89,
        198,
        5,
        10,
        199,
        113,
        183,
        0,
        0,
        129,
        134,
        150,
        43,
        42,
        199,
        12,
        90,
        73,
        1,
        76,
        43,
        95,
        98,
        89,
        54,
        30,
        43,
        39,
        69,
        48,
        98,
        38,
        1,
        65,
        82,
        34,
        0,
        99,
        0,
        46,
        2,
        39,
        69,
        90,
        97,
        2,
        7,
        4,
        1,
        1,
        65,
        44,
        26,
        1,
        77,
        63,
        41,
        61,
        21,
        99,
        73,
        99,
        37,
        99,
        0,
        0,
        55,
        99,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        199,
        111,
        80,
        68,
        64,
        139,
        3,
        92,
        2,
        85,
        93,
        2,
        198,
        55,
        99,
        15,
        134,
        199,
        142,
        69,
        145,
        58,
        79,
        195,
        3,
        66,
        159,
        87,
        129,
        108,
        199,
        21,
        87,
        157,
        150,
        181,
        24,
        5,
        74,
        0,
        63,
        45,
        84,
        4,
        58,
        196,
        0,
        0,
        1,
        199,
        14,
        27
      ],
      "weeklyWasted": [
        150,
        181,
        24,
        5,
        74,
        0,
        63,
        45,
        84,
        4,
        58,
        196,
        0,
        0,
        1,
        199,
        14,
        27,
        48,
        67,
        0,
        2,
        69,
        59,
        75,
        0,
        55,
        69,
        99,
        99,
        13,
        6,
        35,
        25,
        67,
        80,
        30,
        28,
        67,
        39,
        60,
        0,
        39,
        98,
        98,
        97,
        2,
        1,
        99,
        31,
        32,
        77,
        0,
        91,
        87,
        44,
        99,
        48,
        20,
        98,
        42,
        41,
        47,
        48,
        71,
        86,
        52,
        99,
        99,
        64
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        167,
        118,
        198,
        92,
        126,
        197,
        30,
        51,
        78,
        194,
        0,
        34,
        133,
        78,
        1,
        198,
        192,
        92,
        129,
        197,
        159,
        132,
        197,
        171,
        3,
        198,
        197,
        1,
        199,
        56,
        81,
        116,
        28,
        4,
        94,
        172,
        198,
        135,
        10,
        16,
        21,
        168,
        0,
        69,
        14,
        15,
        162,
        198,
        198,
        126,
        51,
        84
      ],
      "weeklyWasted": [
        94,
        172,
        198,
        135,
        10,
        16,
        21,
        168,
        0,
        69,
        14,
        15,
        162,
        198,
        198,
        126,
        51,
        84,
        49,
        31,
        45,
        8,
        98,
        1,
        99,
        53,
        0,
        9,
        99,
        70,
        99,
        5,
        52,
        1,
        0,
        99,
        44,
        41,
        38,
        12,
        9,
        0,
        53,
        35,
        75,
        61,
        35,
        79,
        2,
        8,
        28,
        71,
        99,
        68,
        76,
        35,
        33,
        10,
        30,
        37,
        27,
        29,
        31,
        22,
        34,
        98,
        4,
        85,
        99,
        1
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        101,
        13,
        89,
        195,
        198,
        1,
        194,
        3,
        2,
        1,
        39,
        2,
        190,
        92,
        136,
        127,
        142,
        199,
        3,
        197,
        194,
        4,
        21,
        175,
        25,
        198,
        130,
        103,
        128,
        142,
        103,
        196,
        49,
        192,
        51,
        0,
        198,
        5,
        112,
        18,
        22,
        57,
        40,
        150,
        58,
        141,
        70,
        81,
        169,
        80,
        2,
        68
      ],
      "weeklyWasted": [
        51,
        0,
        198,
        5,
        112,
        18,
        22,
        57,
        40,
        150,
        58,
        141,
        70,
        81,
        169,
        80,
        2,
        68,
        55,
        1,
        54,
        2,
        98,
        73,
        30,
        53,
        0,
        90,
        54,
        60,
        46,
        71,
        97,
        98,
        5,
        65,
        99,
        72,
        33,
        99,
        94,
        32,
        30,
        99,
        98,
        43,
        99,
        72,
        57,
        55,
        1,
        54,
        13,
        89,
        28,
        47,
        0,
        1,
        65,
        0,
        86,
        99,
        0,
        41,
        99,
        98,
        77,
        40,
        99,
        9
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        62,
        153,
        99,
        89,
        44,
        197,
        125,
        59,
        91,
        199,
        150,
        95,
        158,
        199,
        59,
        83,
        192,
        117,
        103,
        146,
        197,
        36,
        21,
        79,
        101,
        1,
        35,
        33,
        168,
        142,
        83,
        196,
        157,
        152,
        152,
        59,
        44,
        12,
        114,
        72,
        44,
        0,
        85,
        20,
        28,
        88,
        52,
        64,
        198,
        169,
        2,
        46
      ],
      "weeklyWasted": [
        152,
        59,
        44,
        12,
        114,
        72,
        44,
        0,
        85,
        20,
        28,
        88,
        52,
        64,
        198,
        169,
        2,
        46,
        24,
        43,
        59,
        70,
        98,
        71,
        99,
        1,
        80,
        43,
        47,
        30,
        8,
        36,
        82,
        55,
        98,
        78,
        48,
        63,
        2,
        99,
        16,
        0,
        55,
        46,
        74,
        47,
        29,
        42,
        99,
        88,
        60,
        36,
        99,
        3,
        35,
        0,
        49,
        3,
        18,
        15,
        99,
        99,
        43,
        58,
        0,
        50,
        5,
        63,
        41,
        15
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        199,
        105,
        122,
        53,
        120,
        138,
        166,
        22,
        105,
        1,
        0,
        130,
        198,
        0,
        199,
        80,
        135,
        169,
        81,
        78,
        51,
        198,
        28,
        83,
        169,
        134,
        124,
        1,
        199,
        77,
        33,
        0,
        66,
        78,
        13,
        130,
        82,
        111,
        107,
        100,
        65,
        151,
        199,
        79,
        1,
        148,
        44,
        83,
        84,
        93,
        55,
        81
      ],
      "weeklyWasted": [
        13,
        130,
        82,
        111,
        107,
        100,
        65,
        151,
        199,
        79,
        1,
        148,
        44,
        83,
        84,
        93,
        55,
        81,
        66,
        35,
        20,
        2,
        79,
        49,
        12,
        0,
        99,
        20,
        54,
        44,
        58,
        69,
        97,
        20,
        4,
        72,
        54,
        27,
        96,
        0,
        48,
        48,
        0,
        65,
        0,
        52,
        91,
        44,
        9,
        97,
        1,
        98,
        79,
        33,
        46,
        53,
        38,
        99,
        3,
        10,
        47,
        99,
        99,
        0,
        0,
        96,
        91,
        90,
        52,
        61
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        103,
        136,
        133,
        64,
        6,
        118,
        72,
        197,
        108,
        1,
        199,
        2,
        185,
        105,
        119,
        3,
        192,
        173,
        36,
        143,
        111,
        153,
        181,
        128,
        3,
        1,
        3,
        1,
        158,
        135,
        178,
        103,
        127,
        187,
        68,
        187,
        179,
        175,
        10,
        0,
        58,
        199,
        0,
        71,
        118,
        110,
        41,
        34,
        190,
        199,
        86,
        3
      ],
      "weeklyWasted": [
        68,
        187,
        179,
        175,
        10,
        0,
        58,
        199,
        0,
        71,
        118,
        110,
        41,
        34,
        190,
        199,
        86,
        3,
        82,
        98,
        59,
        2,
        81,
        1,
        66,
        93,
        62,
        0,
        29,
        90,
        56,
        91,
        44,
        5,
        44,
        44,
        46,
        62,
        99,
        30,
        89,
        33,
        37,
        1,
        71,
        97,
        2,
        49,
        81,
        74,
        36,
        96,
        26,
        6,
        1,
        39,
        26,
        57,
        60,
        16,
        99,
        78,
        74,
        26,
        81,
        0,
        77,
        77,
        72,
        60
      ]
    },
    {
      "id": 9202537,
      "weeklyUsage": [
        199,
        2,
        69,
        85,
        112,
        2,
        28,
        182,
        118,
        155,
        0,
        70,
        2,
        97,
        176,
        3,
        69,
        199,
        32,
        122,
        188,
        130,
        121,
        43,
        141,
        1,
        170,
        147,
        149,
        120,
        60,
        179,
        1,
        107,
        17,
        0,
        30,
        5,
        35,
        0,
        143,
        19,
        77,
        65,
        104,
        115,
        173,
        165,
        198,
        168,
        27,
        108
      ],
      "weeklyWasted": [
        17,
        0,
        30,
        5,
        35,
        0,
        143,
        19,
        77,
        65,
        104,
        115,
        173,
        165,
        198,
        168,
        27,
        108,
        20,
        1,
        8,
        27,
        66,
        98,
        72,
        38,
        11,
        29,
        18,
        79,
        1,
        56,
        97,
        4,
        49,
        40,
        21,
        0,
        41,
        1,
        0,
        60,
        16,
        99,
        84,
        10,
        23,
        1,
        99,
        32,
        39,
        89,
        99,
        12,
        99,
        0,
        99,
        85,
        57,
        2,
        99,
        60,
        11,
        86,
        99,
        47,
        38,
        31,
        57,
        46
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        138,
        2,
        50,
        187,
        179,
        62,
        27,
        8,
        44,
        43,
        134,
        96,
        182,
        61,
        154,
        198,
        192,
        194,
        9,
        97,
        132,
        21,
        2,
        57,
        197,
        15,
        116,
        57,
        188,
        8,
        85,
        141,
        21,
        78,
        198,
        148,
        0,
        5,
        140,
        77,
        114,
        63,
        79,
        0,
        125,
        23,
        91,
        26,
        94,
        56,
        99,
        6
      ],
      "weeklyWasted": [
        198,
        148,
        0,
        5,
        140,
        77,
        114,
        63,
        79,
        0,
        125,
        23,
        91,
        26,
        94,
        56,
        99,
        6,
        15,
        80,
        17,
        2,
        98,
        83,
        65,
        25,
        46,
        25,
        89,
        98,
        50,
        62,
        37,
        54,
        54,
        79,
        39,
        33,
        22,
        0,
        51,
        85,
        0,
        75,
        31,
        48,
        2,
        1,
        2,
        77,
        31,
        98,
        99,
        76,
        80,
        62,
        0,
        1,
        29,
        86,
        99,
        42,
        58,
        62,
        91,
        98,
        65,
        1,
        99,
        38
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        61,
        68,
        82,
        195,
        116,
        50,
        126,
        7,
        128,
        118,
        49,
        183,
        143,
        153,
        199,
        68,
        192,
        59,
        118,
        72,
        73,
        49,
        58,
        116,
        121,
        157,
        187,
        45,
        127,
        110,
        64,
        59,
        192,
        163,
        18,
        32,
        109,
        81,
        115,
        24,
        86,
        0,
        15,
        67,
        1,
        196,
        0,
        0,
        158,
        47,
        49,
        3
      ],
      "weeklyWasted": [
        18,
        32,
        109,
        81,
        115,
        24,
        86,
        0,
        15,
        67,
        1,
        196,
        0,
        0,
        158,
        47,
        49,
        3,
        46,
        12,
        99,
        47,
        98,
        51,
        26,
        0,
        79,
        22,
        86,
        60,
        55,
        74,
        97,
        55,
        32,
        55,
        39,
        59,
        60,
        41,
        47,
        81,
        30,
        31,
        65,
        97,
        23,
        46,
        35,
        49,
        65,
        38,
        99,
        89,
        25,
        54,
        79,
        76,
        19,
        11,
        46,
        46,
        56,
        91,
        0,
        98,
        3,
        99,
        99,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        199,
        99,
        173,
        5,
        27,
        1,
        87,
        3,
        2,
        1,
        199,
        61,
        173,
        105,
        43,
        85,
        173,
        146,
        3,
        56,
        44,
        12,
        137,
        51,
        3,
        1,
        174,
        72,
        91,
        154,
        44,
        152,
        1,
        55,
        192,
        150,
        128,
        5,
        104,
        0,
        0,
        33,
        115,
        0,
        71,
        196,
        131,
        198,
        198,
        199,
        2,
        196
      ],
      "weeklyWasted": [
        192,
        150,
        128,
        5,
        104,
        0,
        0,
        33,
        115,
        0,
        71,
        196,
        131,
        198,
        198,
        199,
        2,
        196,
        22,
        1,
        30,
        49,
        73,
        1,
        52,
        0,
        19,
        21,
        49,
        18,
        1,
        68,
        40,
        34,
        91,
        14,
        36,
        22,
        37,
        7,
        68,
        0,
        54,
        93,
        66,
        80,
        78,
        10,
        48,
        97,
        99,
        46,
        0,
        25,
        36,
        42,
        99,
        1,
        47,
        0,
        99,
        99,
        24,
        77,
        99,
        98,
        99,
        1,
        99,
        47
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        57,
        56,
        198,
        5,
        37,
        85,
        13,
        3,
        199,
        41,
        53,
        46,
        45,
        0,
        199,
        136,
        50,
        172,
        71,
        18,
        59,
        198,
        2,
        195,
        197,
        108,
        190,
        76,
        65,
        64,
        105,
        0,
        121,
        138,
        198,
        0,
        24,
        42,
        199,
        0,
        122,
        0,
        128,
        28,
        1,
        147,
        3,
        56,
        1,
        199,
        121,
        89
      ],
      "weeklyWasted": [
        198,
        0,
        24,
        42,
        199,
        0,
        122,
        0,
        128,
        28,
        1,
        147,
        3,
        56,
        1,
        199,
        121,
        89,
        24,
        1,
        22,
        21,
        68,
        40,
        45,
        2,
        32,
        99,
        66,
        99,
        67,
        58,
        0,
        22,
        60,
        99,
        46,
        57,
        2,
        28,
        0,
        17,
        75,
        63,
        59,
        1,
        99,
        11,
        2,
        16,
        42,
        98,
        99,
        89,
        70,
        56,
        99,
        55,
        1,
        98,
        88,
        99,
        21,
        45,
        4,
        75,
        35,
        8,
        20,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        197,
        66,
        0,
        68,
        13,
        107,
        185,
        197,
        132,
        1,
        120,
        68,
        119,
        41,
        60,
        87,
        0,
        199,
        116,
        2,
        57,
        74,
        2,
        1,
        3,
        131,
        163,
        1,
        199,
        93,
        199,
        0,
        12,
        122,
        198,
        122,
        198,
        5,
        199,
        140,
        88,
        32,
        199,
        139,
        1,
        84,
        0,
        88,
        182,
        5,
        197,
        3
      ],
      "weeklyWasted": [
        198,
        122,
        198,
        5,
        199,
        140,
        88,
        32,
        199,
        139,
        1,
        84,
        0,
        88,
        182,
        5,
        197,
        3,
        45,
        13,
        29,
        9,
        95,
        98,
        29,
        5,
        95,
        52,
        55,
        53,
        54,
        92,
        0,
        33,
        57,
        65,
        38,
        65,
        36,
        48,
        28,
        78,
        59,
        30,
        0,
        10,
        62,
        9,
        24,
        65,
        1,
        66,
        55,
        13,
        55,
        53,
        99,
        99,
        0,
        37,
        63,
        75,
        42,
        13,
        85,
        36,
        73,
        45,
        99,
        53
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        199,
        35,
        16,
        5,
        132,
        176,
        136,
        98,
        92,
        1,
        199,
        178,
        198,
        79,
        54,
        87,
        192,
        138,
        54,
        189,
        88,
        4,
        54,
        162,
        28,
        198,
        133,
        108,
        29,
        140,
        61,
        196,
        26,
        4,
        30,
        104,
        116,
        108,
        42,
        0,
        134,
        147,
        0,
        0,
        1,
        144,
        147,
        53,
        152,
        2,
        73,
        3
      ],
      "weeklyWasted": [
        30,
        104,
        116,
        108,
        42,
        0,
        134,
        147,
        0,
        0,
        1,
        144,
        147,
        53,
        152,
        2,
        73,
        3,
        79,
        7,
        73,
        72,
        84,
        90,
        64,
        30,
        35,
        49,
        88,
        1,
        45,
        92,
        16,
        7,
        77,
        73,
        60,
        99,
        59,
        45,
        12,
        0,
        14,
        99,
        11,
        49,
        2,
        20,
        2,
        97,
        35,
        71,
        99,
        9,
        19,
        86,
        0,
        1,
        0,
        65,
        71,
        62,
        99,
        2,
        99,
        77,
        4,
        10,
        42,
        26
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        65,
        2,
        0,
        94,
        0,
        197,
        70,
        183,
        199,
        132,
        22,
        2,
        135,
        0,
        163,
        71,
        192,
        199,
        102,
        160,
        54,
        198,
        2,
        41,
        92,
        198,
        141,
        38,
        111,
        16,
        22,
        0,
        57,
        115,
        96,
        23,
        83,
        39,
        39,
        86,
        94,
        0,
        93,
        49,
        26,
        77,
        0,
        198,
        189,
        120,
        2,
        76
      ],
      "weeklyWasted": [
        96,
        23,
        83,
        39,
        39,
        86,
        94,
        0,
        93,
        49,
        26,
        77,
        0,
        198,
        189,
        120,
        2,
        76,
        28,
        11,
        83,
        61,
        98,
        25,
        53,
        48,
        67,
        0,
        10,
        24,
        69,
        99,
        44,
        1,
        57,
        40,
        75,
        33,
        33,
        0,
        45,
        3,
        21,
        99,
        98,
        1,
        89,
        91,
        2,
        87,
        26,
        1,
        99,
        24,
        56,
        84,
        40,
        46,
        25,
        20,
        61,
        64,
        77,
        0,
        50,
        96,
        69,
        18,
        57,
        37
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        17,
        2,
        194,
        128,
        134,
        103,
        156,
        3,
        58,
        118,
        119,
        75,
        78,
        150,
        199,
        161,
        192,
        199,
        144,
        2,
        197,
        130,
        25,
        40,
        128,
        136,
        152,
        41,
        149,
        0,
        42,
        121,
        1,
        99,
        108,
        0,
        83,
        147,
        100,
        0,
        199,
        38,
        0,
        27,
        1,
        78,
        109,
        141,
        58,
        142,
        2,
        161
      ],
      "weeklyWasted": [
        108,
        0,
        83,
        147,
        100,
        0,
        199,
        38,
        0,
        27,
        1,
        78,
        109,
        141,
        58,
        142,
        2,
        161,
        1,
        42,
        25,
        96,
        6,
        37,
        71,
        0,
        38,
        0,
        81,
        54,
        63,
        3,
        71,
        79,
        51,
        72,
        29,
        42,
        12,
        68,
        23,
        5,
        0,
        91,
        98,
        97,
        80,
        77,
        2,
        83,
        31,
        98,
        99,
        99,
        76,
        60,
        99,
        54,
        20,
        17,
        99,
        48,
        99,
        0,
        0,
        98,
        47,
        47,
        25,
        17
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        173,
        2,
        177,
        136,
        2,
        197,
        60,
        49,
        199,
        74,
        92,
        64,
        126,
        18,
        41,
        147,
        192,
        58,
        83,
        2,
        160,
        4,
        2,
        39,
        7,
        28,
        197,
        47,
        169,
        98,
        0,
        57,
        86,
        4,
        27,
        112,
        0,
        199,
        115,
        125,
        88,
        0,
        0,
        22,
        1,
        30,
        195,
        65,
        198,
        2,
        2,
        138
      ],
      "weeklyWasted": [
        27,
        112,
        0,
        199,
        115,
        125,
        88,
        0,
        0,
        22,
        1,
        30,
        195,
        65,
        198,
        2,
        2,
        138,
        74,
        12,
        54,
        2,
        98,
        98,
        50,
        49,
        6,
        77,
        49,
        19,
        73,
        50,
        37,
        1,
        64,
        25,
        20,
        45,
        53,
        76,
        97,
        0,
        29,
        22,
        57,
        31,
        82,
        33,
        21,
        90,
        85,
        98,
        82,
        0,
        11,
        59,
        49,
        63,
        0,
        0,
        71,
        99,
        60,
        46,
        99,
        94,
        45,
        84,
        19,
        38
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        7,
        75,
        108,
        195,
        27,
        12,
        194,
        114,
        120,
        57,
        0,
        2,
        82,
        50,
        1,
        106,
        142,
        41,
        89,
        173,
        185,
        198,
        2,
        153,
        95,
        198,
        155,
        44,
        199,
        0,
        129,
        0,
        117,
        199,
        186,
        142,
        0,
        5,
        129,
        68,
        140,
        0,
        199,
        160,
        125,
        196,
        52,
        103,
        29,
        159,
        63,
        3
      ],
      "weeklyWasted": [
        186,
        142,
        0,
        5,
        129,
        68,
        140,
        0,
        199,
        160,
        125,
        196,
        52,
        103,
        29,
        159,
        63,
        3,
        86,
        15,
        34,
        3,
        98,
        98,
        45,
        15,
        88,
        48,
        50,
        74,
        61,
        65,
        0,
        89,
        97,
        78,
        45,
        4,
        45,
        0,
        0,
        84,
        0,
        69,
        70,
        1,
        18,
        55,
        2,
        94,
        1,
        98,
        99,
        28,
        67,
        5,
        99,
        99,
        99,
        17,
        99,
        38,
        26,
        50,
        15,
        90,
        38,
        97,
        99,
        45
      ]
    },
    {
      "id": "F04529EA",
      "weeklyUsage": [
        199,
        100,
        41,
        5,
        0,
        197,
        56,
        68,
        2,
        24,
        93,
        30,
        132,
        25,
        82,
        100,
        192,
        166,
        3,
        142,
        113,
        133,
        119,
        1,
        134,
        1,
        183,
        26,
        130,
        44,
        97,
        73,
        85,
        44,
        113,
        0,
        0,
        199,
        83,
        160,
        78,
        162,
        27,
        0,
        1,
        122,
        0,
        44,
        198,
        140,
        130,
        171
      ],
      "weeklyWasted": [
        113,
        0,
        0,
        199,
        83,
        160,
        78,
        162,
        27,
        0,
        1,
        122,
        0,
        44,
        198,
        140,
        130,
        171,
        49,
        54,
        39,
        2,
        40,
        67,
        28,
        9,
        91,
        99,
        63,
        82,
        19,
        93,
        0,
        98,
        17,
        80,
        22,
        53,
        79,
        58,
        3,
        36,
        27,
        99,
        98,
        50,
        39,
        47,
        2,
        43,
        46,
        86,
        14,
        78,
        84,
        41,
        31,
        60,
        4,
        0,
        99,
        86,
        48,
        32,
        0,
        98,
        33,
        99,
        36,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        57,
        94,
        77,
        10,
        0,
        134,
        90,
        55,
        45,
        4,
        82,
        2,
        21,
        0,
        166,
        198,
        192,
        15,
        53,
        4,
        128,
        4,
        99,
        126,
        28,
        5,
        110,
        123,
        173,
        73,
        162,
        0,
        66,
        81,
        71,
        83,
        136,
        10,
        10,
        23,
        111,
        19,
        113,
        0,
        109,
        196,
        67,
        40,
        90,
        172,
        47,
        87
      ],
      "weeklyWasted": [
        71,
        83,
        136,
        10,
        10,
        23,
        111,
        19,
        113,
        0,
        109,
        196,
        67,
        40,
        90,
        172,
        47,
        87,
        23,
        1,
        11,
        2,
        92,
        79,
        0,
        2,
        82,
        50,
        99,
        88,
        46,
        6,
        97,
        50,
        51,
        99,
        81,
        48,
        92,
        0,
        37,
        0,
        30,
        86,
        47,
        48,
        32,
        55,
        16,
        58,
        67,
        56,
        45,
        91,
        51,
        80,
        60,
        1,
        52,
        85,
        98,
        78,
        97,
        58,
        63,
        1,
        38,
        99,
        99,
        32
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        60,
        82,
        175,
        106,
        0,
        197,
        194,
        82,
        158,
        57,
        0,
        110,
        79,
        0,
        64,
        38,
        130,
        98,
        156,
        197,
        104,
        110,
        95,
        34,
        55,
        177,
        50,
        72,
        199,
        39,
        178,
        39,
        37,
        151,
        157,
        0,
        169,
        43,
        69,
        177,
        18,
        0,
        124,
        72,
        50,
        122,
        0,
        150,
        129,
        38,
        132,
        3
      ],
      "weeklyWasted": [
        157,
        0,
        169,
        43,
        69,
        177,
        18,
        0,
        124,
        72,
        50,
        122,
        0,
        150,
        129,
        38,
        132,
        3,
        99,
        1,
        19,
        9,
        98,
        98,
        37,
        0,
        79,
        69,
        62,
        99,
        54,
        60,
        73,
        98,
        2,
        70,
        99,
        0,
        44,
        63,
        62,
        83,
        0,
        99,
        66,
        6,
        32,
        1,
        2,
        78,
        87,
        67,
        99,
        42,
        84,
        0,
        55,
        99,
        90,
        0,
        68,
        21,
        57,
        73,
        31,
        97,
        10,
        99,
        99,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        174,
        108,
        134,
        5,
        0,
        90,
        3,
        3,
        20,
        44,
        199,
        36,
        147,
        148,
        102,
        12,
        102,
        122,
        65,
        104,
        150,
        114,
        88,
        124,
        84,
        1,
        165,
        4,
        19,
        98,
        0,
        196,
        1,
        120,
        100,
        0,
        96,
        196,
        164,
        82,
        45,
        78,
        20,
        80,
        1,
        99,
        142,
        121,
        161,
        10,
        59,
        3
      ],
      "weeklyWasted": [
        100,
        0,
        96,
        196,
        164,
        82,
        45,
        78,
        20,
        80,
        1,
        99,
        142,
        121,
        161,
        10,
        59,
        3,
        8,
        33,
        74,
        52,
        98,
        64,
        93,
        11,
        71,
        38,
        69,
        55,
        13,
        72,
        25,
        98,
        38,
        71,
        45,
        47,
        30,
        59,
        73,
        0,
        16,
        94,
        98,
        79,
        43,
        9,
        14,
        64,
        1,
        98,
        89,
        26,
        34,
        77,
        16,
        66,
        1,
        82,
        99,
        61,
        94,
        77,
        99,
        98,
        0,
        49,
        38,
        23
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        97,
        78,
        178,
        51,
        79,
        75,
        166,
        37,
        75,
        1,
        0,
        123,
        198,
        26,
        140,
        82,
        131,
        155,
        9,
        167,
        180,
        4,
        124,
        165,
        65,
        149,
        137,
        43,
        199,
        160,
        114,
        0,
        68,
        199,
        36,
        164,
        91,
        44,
        85,
        75,
        176,
        30,
        13,
        133,
        6,
        196,
        0,
        17,
        165,
        148,
        42,
        3
      ],
      "weeklyWasted": [
        36,
        164,
        91,
        44,
        85,
        75,
        176,
        30,
        13,
        133,
        6,
        196,
        0,
        17,
        165,
        148,
        42,
        3,
        50,
        8,
        57,
        18,
        98,
        29,
        68,
        99,
        1,
        5,
        92,
        50,
        36,
        71,
        48,
        98,
        17,
        85,
        18,
        83,
        22,
        10,
        38,
        19,
        24,
        58,
        73,
        1,
        89,
        34,
        2,
        97,
        34,
        60,
        45,
        72,
        60,
        53,
        61,
        73,
        0,
        0,
        99,
        76,
        99,
        80,
        26,
        93,
        94,
        99,
        6,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        128,
        65,
        142,
        125,
        0,
        166,
        3,
        197,
        88,
        1,
        1,
        2,
        124,
        0,
        162,
        163,
        192,
        165,
        103,
        2,
        92,
        198,
        2,
        33,
        41,
        69,
        197,
        44,
        113,
        0,
        73,
        190,
        1,
        92,
        88,
        160,
        99,
        126,
        72,
        94,
        113,
        179,
        24,
        50,
        54,
        77,
        195,
        97,
        172,
        77,
        2,
        61
      ],
      "weeklyWasted": [
        88,
        160,
        99,
        126,
        72,
        94,
        113,
        179,
        24,
        50,
        54,
        77,
        195,
        97,
        172,
        77,
        2,
        61,
        36,
        98,
        7,
        2,
        98,
        72,
        13,
        99,
        91,
        0,
        63,
        56,
        45,
        3,
        60,
        98,
        56,
        99,
        50,
        86,
        50,
        30,
        24,
        19,
        48,
        81,
        81,
        12,
        14,
        1,
        6,
        95,
        1,
        98,
        99,
        44,
        96,
        46,
        59,
        18,
        62,
        31,
        67,
        99,
        77,
        13,
        94,
        52,
        0,
        99,
        76,
        6
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        199,
        108,
        73,
        54,
        198,
        122,
        3,
        197,
        176,
        1,
        199,
        164,
        189,
        0,
        42,
        38,
        118,
        165,
        13,
        62,
        184,
        26,
        86,
        142,
        43,
        119,
        61,
        37,
        46,
        76,
        125,
        0,
        143,
        105,
        198,
        78,
        126,
        150,
        79,
        148,
        72,
        97,
        69,
        0,
        1,
        196,
        146,
        85,
        151,
        83,
        176,
        39
      ],
      "weeklyWasted": [
        198,
        78,
        126,
        150,
        79,
        148,
        72,
        97,
        69,
        0,
        1,
        196,
        146,
        85,
        151,
        83,
        176,
        39,
        94,
        90,
        35,
        6,
        61,
        43,
        66,
        33,
        99,
        84,
        99,
        86,
        36,
        99,
        97,
        44,
        49,
        93,
        65,
        90,
        91,
        8,
        0,
        36,
        22,
        46,
        59,
        20,
        51,
        20,
        2,
        97,
        35,
        98,
        79,
        14,
        74,
        41,
        0,
        70,
        7,
        98,
        38,
        74,
        99,
        44,
        73,
        84,
        54,
        64,
        52,
        1
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        78,
        108,
        64,
        68,
        16,
        1,
        121,
        88,
        132,
        1,
        163,
        153,
        198,
        0,
        151,
        198,
        89,
        186,
        75,
        10,
        44,
        108,
        39,
        151,
        79,
        46,
        175,
        53,
        199,
        9,
        0,
        36,
        107,
        67,
        191,
        199,
        3,
        67,
        199,
        166,
        15,
        184,
        118,
        0,
        1,
        196,
        132,
        155,
        198,
        2,
        35,
        8
      ],
      "weeklyWasted": [
        191,
        199,
        3,
        67,
        199,
        166,
        15,
        184,
        118,
        0,
        1,
        196,
        132,
        155,
        198,
        2,
        35,
        8,
        30,
        20,
        53,
        77,
        63,
        50,
        53,
        0,
        96,
        35,
        35,
        1,
        42,
        60,
        77,
        98,
        98,
        81,
        20,
        79,
        83,
        64,
        45,
        99,
        47,
        84,
        32,
        48,
        2,
        1,
        34,
        73,
        22,
        58,
        99,
        14,
        66,
        29,
        53,
        80,
        48,
        0,
        99,
        99,
        26,
        0,
        64,
        98,
        8,
        60,
        51,
        3
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        37,
        2,
        198,
        52,
        0,
        105,
        59,
        5,
        109,
        138,
        146,
        182,
        198,
        177,
        115,
        94,
        192,
        138,
        3,
        2,
        58,
        31,
        94,
        162,
        39,
        72,
        187,
        60,
        74,
        80,
        120,
        43,
        197,
        178,
        4,
        11,
        74,
        86,
        125,
        0,
        87,
        0,
        8,
        0,
        1,
        4,
        168,
        132,
        189,
        2,
        25,
        92
      ],
      "weeklyWasted": [
        4,
        11,
        74,
        86,
        125,
        0,
        87,
        0,
        8,
        0,
        1,
        4,
        168,
        132,
        189,
        2,
        25,
        92,
        1,
        1,
        15,
        2,
        68,
        1,
        36,
        12,
        15,
        23,
        51,
        56,
        1,
        98,
        32,
        62,
        72,
        94,
        17,
        49,
        52,
        0,
        0,
        0,
        93,
        12,
        98,
        29,
        65,
        5,
        2,
        71,
        33,
        98,
        89,
        0,
        88,
        56,
        93,
        99,
        13,
        98,
        55,
        82,
        99,
        71,
        31,
        12,
        71,
        52,
        41,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        151,
        91,
        0,
        5,
        35,
        181,
        149,
        12,
        125,
        83,
        153,
        97,
        153,
        103,
        143,
        158,
        192,
        44,
        3,
        171,
        150,
        87,
        69,
        156,
        75,
        198,
        145,
        1,
        38,
        117,
        199,
        87,
        125,
        98,
        164,
        0,
        138,
        78,
        30,
        196,
        131,
        199,
        101,
        198,
        54,
        151,
        40,
        198,
        143,
        5,
        22,
        74
      ],
      "weeklyWasted": [
        164,
        0,
        138,
        78,
        30,
        196,
        131,
        199,
        101,
        198,
        54,
        151,
        40,
        198,
        143,
        5,
        22,
        74,
        63,
        6,
        67,
        27,
        98,
        43,
        0,
        99,
        43,
        69,
        71,
        83,
        25,
        86,
        50,
        15,
        49,
        62,
        91,
        34,
        99,
        4,
        20,
        15,
        0,
        55,
        82,
        61,
        52,
        61,
        46,
        55,
        92,
        2,
        99,
        96,
        50,
        50,
        26,
        99,
        7,
        36,
        57,
        65,
        63,
        0,
        9,
        78,
        0,
        96,
        24,
        76
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        77,
        68,
        121,
        24,
        9,
        197,
        194,
        125,
        2,
        86,
        198,
        197,
        171,
        181,
        191,
        115,
        192,
        199,
        3,
        64,
        197,
        58,
        122,
        5,
        3,
        1,
        111,
        1,
        139,
        153,
        119,
        95,
        93,
        119,
        69,
        91,
        152,
        49,
        199,
        10,
        199,
        0,
        26,
        0,
        162,
        182,
        50,
        80,
        198,
        199,
        20,
        108
      ],
      "weeklyWasted": [
        69,
        91,
        152,
        49,
        199,
        10,
        199,
        0,
        26,
        0,
        162,
        182,
        50,
        80,
        198,
        199,
        20,
        108,
        34,
        1,
        37,
        47,
        0,
        23,
        43,
        14,
        64,
        37,
        36,
        99,
        1,
        75,
        97,
        74,
        0,
        16,
        99,
        49,
        53,
        64,
        68,
        0,
        9,
        72,
        62,
        80,
        72,
        35,
        59,
        69,
        48,
        11,
        94,
        0,
        37,
        85,
        5,
        2,
        0,
        16,
        83,
        67,
        43,
        4,
        99,
        72,
        90,
        99,
        0,
        69
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        199,
        89,
        128,
        161,
        198,
        197,
        126,
        82,
        2,
        21,
        24,
        28,
        109,
        58,
        199,
        3,
        192,
        115,
        11,
        59,
        197,
        4,
        197,
        81,
        3,
        30,
        74,
        25,
        3,
        177,
        199,
        137,
        43,
        56,
        69,
        0,
        159,
        181,
        116,
        126,
        71,
        0,
        11,
        38,
        49,
        164,
        48,
        198,
        198,
        199,
        83,
        3
      ],
      "weeklyWasted": [
        69,
        0,
        159,
        181,
        116,
        126,
        71,
        0,
        11,
        38,
        49,
        164,
        48,
        198,
        198,
        199,
        83,
        3,
        1,
        78,
        36,
        2,
        98,
        13,
        97,
        89,
        30,
        40,
        99,
        33,
        60,
        30,
        21,
        33,
        98,
        67,
        23,
        64,
        17,
        0,
        0,
        0,
        25,
        1,
        29,
        56,
        47,
        59,
        2,
        97,
        1,
        54,
        0,
        52,
        99,
        99,
        2,
        50,
        52,
        0,
        25,
        99,
        66,
        27,
        99,
        98,
        21,
        18,
        50,
        27
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        199,
        186,
        6,
        84,
        56,
        41,
        167,
        3,
        109,
        1,
        75,
        26,
        109,
        0,
        189,
        3,
        145,
        166,
        42,
        175,
        181,
        4,
        2,
        195,
        18,
        1,
        142,
        41,
        182,
        31,
        30,
        0,
        1,
        136,
        142,
        52,
        72,
        5,
        126,
        97,
        0,
        19,
        64,
        0,
        58,
        32,
        46,
        138,
        175,
        23,
        25,
        31
      ],
      "weeklyWasted": [
        142,
        52,
        72,
        5,
        126,
        97,
        0,
        19,
        64,
        0,
        58,
        32,
        46,
        138,
        175,
        23,
        25,
        31,
        49,
        34,
        52,
        59,
        94,
        73,
        39,
        0,
        99,
        57,
        94,
        1,
        99,
        75,
        4,
        35,
        31,
        99,
        4,
        27,
        2,
        97,
        13,
        22,
        99,
        62,
        39,
        62,
        47,
        1,
        48,
        53,
        1,
        98,
        99,
        42,
        69,
        0,
        95,
        25,
        8,
        25,
        99,
        61,
        47,
        49,
        53,
        53,
        93,
        38,
        53,
        1
      ]
    },
    {
      "id": "4FF22342",
      "weeklyUsage": [
        132,
        158,
        195,
        62,
        31,
        197,
        59,
        3,
        160,
        1,
        199,
        2,
        128,
        0,
        199,
        75,
        192,
        199,
        195,
        80,
        139,
        63,
        105,
        195,
        3,
        28,
        79,
        129,
        114,
        7,
        61,
        3,
        155,
        199,
        105,
        0,
        0,
        29,
        103,
        113,
        11,
        44,
        52,
        90,
        1,
        148,
        47,
        193,
        190,
        17,
        2,
        26
      ],
      "weeklyWasted": [
        105,
        0,
        0,
        29,
        103,
        113,
        11,
        44,
        52,
        90,
        1,
        148,
        47,
        193,
        190,
        17,
        2,
        26,
        34,
        98,
        92,
        54,
        88,
        50,
        93,
        55,
        49,
        0,
        6,
        99,
        71,
        99,
        39,
        98,
        42,
        53,
        70,
        37,
        38,
        37,
        89,
        99,
        27,
        56,
        44,
        1,
        2,
        53,
        19,
        97,
        1,
        98,
        4,
        29,
        1,
        0,
        12,
        77,
        12,
        30,
        0,
        62,
        29,
        34,
        52,
        68,
        20,
        80,
        16,
        1
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        152,
        8,
        42,
        5,
        7,
        109,
        113,
        3,
        2,
        20,
        137,
        36,
        151,
        191,
        189,
        140,
        192,
        121,
        3,
        4,
        197,
        94,
        162,
        103,
        125,
        94,
        197,
        75,
        134,
        83,
        21,
        86,
        61,
        158,
        92,
        0,
        26,
        104,
        199,
        139,
        81,
        0,
        53,
        0,
        43,
        41,
        68,
        131,
        75,
        199,
        118,
        128
      ],
      "weeklyWasted": [
        92,
        0,
        26,
        104,
        199,
        139,
        81,
        0,
        53,
        0,
        43,
        41,
        68,
        131,
        75,
        199,
        118,
        128,
        1,
        1,
        6,
        16,
        75,
        1,
        99,
        0,
        99,
        47,
        51,
        1,
        1,
        3,
        97,
        87,
        12,
        24,
        5,
        26,
        2,
        36,
        84,
        0,
        50,
        63,
        28,
        81,
        99,
        6,
        83,
        75,
        51,
        98,
        17,
        62,
        70,
        48,
        86,
        80,
        58,
        10,
        58,
        38,
        27,
        25,
        41,
        51,
        2,
        74,
        83,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        143,
        2,
        23,
        81,
        108,
        151,
        194,
        3,
        127,
        1,
        65,
        22,
        181,
        20,
        188,
        163,
        192,
        163,
        49,
        2,
        151,
        158,
        2,
        124,
        6,
        1,
        66,
        56,
        0,
        0,
        84,
        6,
        1,
        31,
        18,
        0,
        108,
        5,
        153,
        82,
        112,
        0,
        33,
        84,
        25,
        196,
        73,
        198,
        145,
        151,
        2,
        84
      ],
      "weeklyWasted": [
        18,
        0,
        108,
        5,
        153,
        82,
        112,
        0,
        33,
        84,
        25,
        196,
        73,
        198,
        145,
        151,
        2,
        84,
        1,
        1,
        23,
        96,
        1,
        68,
        19,
        3,
        99,
        18,
        99,
        59,
        19,
        68,
        68,
        27,
        98,
        80,
        11,
        34,
        57,
        0,
        19,
        4,
        50,
        99,
        27,
        82,
        99,
        38,
        2,
        60,
        26,
        74,
        69,
        0,
        62,
        38,
        67,
        65,
        79,
        42,
        68,
        38,
        99,
        0,
        60,
        98,
        83,
        52,
        0,
        1
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        23,
        2,
        66,
        5,
        0,
        79,
        141,
        167,
        147,
        17,
        199,
        197,
        188,
        0,
        156,
        154,
        166,
        183,
        71,
        83,
        50,
        4,
        2,
        126,
        191,
        150,
        131,
        10,
        117,
        144,
        64,
        100,
        1,
        42,
        92,
        90,
        70,
        186,
        30,
        40,
        47,
        66,
        0,
        0,
        87,
        196,
        77,
        7,
        198,
        67,
        104,
        18
      ],
      "weeklyWasted": [
        92,
        90,
        70,
        186,
        30,
        40,
        47,
        66,
        0,
        0,
        87,
        196,
        77,
        7,
        198,
        67,
        104,
        18,
        30,
        1,
        32,
        2,
        17,
        59,
        99,
        0,
        90,
        99,
        99,
        43,
        23,
        82,
        97,
        35,
        57,
        62,
        18,
        72,
        87,
        4,
        29,
        67,
        56,
        32,
        79,
        1,
        41,
        41,
        2,
        71,
        36,
        98,
        40,
        28,
        46,
        64,
        99,
        1,
        0,
        45,
        58,
        53,
        15,
        87,
        34,
        98,
        38,
        99,
        99,
        8
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        138,
        59,
        198,
        195,
        20,
        148,
        3,
        3,
        80,
        1,
        40,
        2,
        195,
        80,
        144,
        77,
        64,
        58,
        83,
        45,
        187,
        4,
        109,
        54,
        197,
        114,
        102,
        1,
        182,
        117,
        166,
        133,
        85,
        126,
        174,
        79,
        132,
        133,
        199,
        0,
        199,
        0,
        8,
        22,
        1,
        196,
        101,
        177,
        152,
        155,
        51,
        3
      ],
      "weeklyWasted": [
        174,
        79,
        132,
        133,
        199,
        0,
        199,
        0,
        8,
        22,
        1,
        196,
        101,
        177,
        152,
        155,
        51,
        3,
        53,
        33,
        0,
        2,
        82,
        65,
        99,
        13,
        99,
        99,
        89,
        71,
        1,
        20,
        63,
        57,
        7,
        64,
        49,
        99,
        68,
        83,
        21,
        0,
        0,
        98,
        98,
        68,
        65,
        22,
        2,
        95,
        66,
        74,
        19,
        0,
        78,
        59,
        37,
        74,
        0,
        6,
        45,
        85,
        37,
        24,
        58,
        92,
        0,
        85,
        9,
        66
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        164,
        2,
        80,
        16,
        0,
        101,
        3,
        144,
        2,
        11,
        173,
        2,
        107,
        0,
        199,
        191,
        192,
        199,
        166,
        23,
        120,
        44,
        126,
        123,
        118,
        1,
        175,
        66,
        114,
        45,
        0,
        175,
        58,
        199,
        5,
        122,
        0,
        199,
        199,
        0,
        33,
        42,
        41,
        15,
        1,
        43,
        195,
        148,
        198,
        89,
        48,
        150
      ],
      "weeklyWasted": [
        5,
        122,
        0,
        199,
        199,
        0,
        33,
        42,
        41,
        15,
        1,
        43,
        195,
        148,
        198,
        89,
        48,
        150,
        7,
        8,
        0,
        2,
        33,
        4,
        78,
        28,
        18,
        21,
        16,
        54,
        13,
        3,
        0,
        98,
        50,
        78,
        1,
        25,
        24,
        82,
        57,
        90,
        73,
        99,
        22,
        37,
        84,
        59,
        30,
        8,
        1,
        72,
        99,
        63,
        69,
        0,
        0,
        91,
        30,
        98,
        41,
        99,
        37,
        29,
        42,
        98,
        36,
        51,
        40,
        27
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyUsage": [
        199,
        21,
        166,
        195,
        36,
        33,
        194,
        56,
        177,
        1,
        0,
        41,
        2,
        60,
        138,
        94,
        94,
        199,
        197,
        90,
        197,
        99,
        197,
        166,
        135,
        163,
        122,
        104,
        51,
        155,
        105,
        0,
        37,
        150,
        97,
        199,
        47,
        94,
        40,
        199,
        121,
        23,
        152,
        179,
        94,
        196,
        187,
        41,
        198,
        62,
        66,
        3
      ],
      "weeklyWasted": [
        97,
        199,
        47,
        94,
        40,
        199,
        121,
        23,
        152,
        179,
        94,
        196,
        187,
        41,
        198,
        62,
        66,
        3,
        39,
        7,
        35,
        37,
        98,
        1,
        25,
        60,
        39,
        0,
        0,
        18,
        1,
        99,
        97,
        21,
        46,
        35,
        86,
        79,
        75,
        38,
        31,
        22,
        87,
        48,
        36,
        11,
        49,
        1,
        2,
        74,
        32,
        76,
        82,
        0,
        43,
        44,
        49,
        99,
        89,
        42,
        94,
        58,
        40,
        57,
        99,
        98,
        99,
        42,
        99,
        36
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        61,
        59,
        198,
        195,
        43,
        70,
        97,
        177,
        169,
        65,
        0,
        130,
        123,
        20,
        152,
        26,
        192,
        118,
        197,
        197,
        78,
        129,
        56,
        155,
        3,
        198,
        177,
        47,
        199,
        70,
        90,
        0,
        24,
        173,
        152,
        49,
        198,
        44,
        199,
        12,
        192,
        171,
        75,
        29,
        199,
        189,
        34,
        39,
        97,
        147,
        162,
        13
      ],
      "weeklyWasted": [
        152,
        49,
        198,
        44,
        199,
        12,
        192,
        171,
        75,
        29,
        199,
        189,
        34,
        39,
        97,
        147,
        162,
        13,
        99,
        1,
        71,
        2,
        98,
        84,
        33,
        0,
        99,
        4,
        78,
        99,
        31,
        15,
        11,
        18,
        57,
        25,
        37,
        0,
        2,
        41,
        97,
        0,
        54,
        99,
        40,
        42,
        99,
        66,
        2,
        89,
        24,
        98,
        50,
        29,
        58,
        81,
        23,
        99,
        25,
        27,
        99,
        20,
        31,
        59,
        99,
        98,
        68,
        53,
        77,
        80
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        168,
        2,
        106,
        6,
        137,
        160,
        18,
        42,
        135,
        199,
        0,
        82,
        33,
        63,
        180,
        198,
        192,
        167,
        55,
        2,
        1,
        4,
        2,
        68,
        37,
        110,
        197,
        1,
        0,
        59,
        6,
        54,
        108,
        131,
        68,
        108,
        198,
        5,
        188,
        51,
        98,
        11,
        89,
        0,
        49,
        29,
        88,
        198,
        198,
        18,
        2,
        129
      ],
      "weeklyWasted": [
        68,
        108,
        198,
        5,
        188,
        51,
        98,
        11,
        89,
        0,
        49,
        29,
        88,
        198,
        198,
        18,
        2,
        129,
        1,
        1,
        0,
        58,
        31,
        1,
        32,
        0,
        89,
        41,
        55,
        50,
        26,
        99,
        20,
        1,
        68,
        33,
        51,
        28,
        49,
        0,
        20,
        0,
        63,
        99,
        16,
        45,
        44,
        1,
        14,
        1,
        44,
        56,
        92,
        55,
        99,
        41,
        98,
        99,
        41,
        15,
        77,
        84,
        47,
        0,
        85,
        73,
        21,
        46,
        35,
        19
      ]
    },
    {
      "id": "C71B9801",
      "weeklyUsage": [
        199,
        84,
        198,
        42,
        66,
        109,
        120,
        117,
        52,
        166,
        142,
        39,
        186,
        0,
        199,
        97,
        192,
        135,
        94,
        2,
        158,
        164,
        2,
        1,
        117,
        110,
        67,
        150,
        120,
        111,
        199,
        10,
        1,
        24,
        44,
        199,
        198,
        95,
        199,
        6,
        0,
        107,
        124,
        24,
        192,
        142,
        127,
        50,
        134,
        199,
        112,
        69
      ],
      "weeklyWasted": [
        44,
        199,
        198,
        95,
        199,
        6,
        0,
        107,
        124,
        24,
        192,
        142,
        127,
        50,
        134,
        199,
        112,
        69,
        54,
        1,
        0,
        2,
        98,
        40,
        50,
        0,
        37,
        0,
        99,
        1,
        62,
        99,
        14,
        83,
        80,
        99,
        26,
        92,
        41,
        4,
        79,
        17,
        68,
        99,
        59,
        1,
        22,
        1,
        20,
        81,
        33,
        98,
        0,
        0,
        99,
        71,
        70,
        99,
        3,
        24,
        99,
        80,
        99,
        0,
        99,
        27,
        99,
        68,
        47,
        29
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        189,
        114,
        0,
        5,
        5,
        187,
        3,
        166,
        2,
        159,
        199,
        4,
        151,
        116,
        143,
        33,
        94,
        136,
        3,
        107,
        134,
        36,
        9,
        116,
        79,
        1,
        151,
        1,
        14,
        105,
        49,
        96,
        54,
        54,
        39,
        44,
        198,
        68,
        48,
        54,
        32,
        0,
        0,
        0,
        21,
        148,
        143,
        71,
        198,
        112,
        140,
        164
      ],
      "weeklyWasted": [
        39,
        44,
        198,
        68,
        48,
        54,
        32,
        0,
        0,
        0,
        21,
        148,
        143,
        71,
        198,
        112,
        140,
        164,
        27,
        1,
        97,
        2,
        98,
        73,
        46,
        45,
        44,
        35,
        40,
        1,
        1,
        55,
        89,
        13,
        26,
        27,
        22,
        72,
        33,
        78,
        27,
        36,
        36,
        92,
        22,
        1,
        37,
        17,
        79,
        42,
        75,
        62,
        74,
        0,
        50,
        29,
        99,
        6,
        42,
        0,
        52,
        99,
        99,
        0,
        99,
        76,
        24,
        55,
        45,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        199,
        2,
        124,
        127,
        104,
        114,
        183,
        57,
        46,
        74,
        197,
        119,
        110,
        89,
        197,
        3,
        162,
        3,
        7,
        2,
        179,
        4,
        153,
        195,
        80,
        159,
        194,
        1,
        101,
        149,
        198,
        122,
        1,
        81,
        156,
        40,
        191,
        146,
        199,
        10,
        0,
        0,
        67,
        51,
        9,
        155,
        118,
        157,
        183,
        199,
        67,
        29
      ],
      "weeklyWasted": [
        156,
        40,
        191,
        146,
        199,
        10,
        0,
        0,
        67,
        51,
        9,
        155,
        118,
        157,
        183,
        199,
        67,
        29,
        64,
        6,
        66,
        2,
        0,
        83,
        99,
        0,
        80,
        41,
        43,
        75,
        43,
        99,
        17,
        52,
        35,
        97,
        26,
        21,
        2,
        49,
        34,
        0,
        80,
        99,
        74,
        97,
        56,
        11,
        7,
        46,
        11,
        90,
        0,
        36,
        95,
        58,
        44,
        1,
        0,
        3,
        99,
        99,
        84,
        72,
        99,
        98,
        81,
        53,
        49,
        40
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        90,
        30,
        198,
        16,
        7,
        17,
        194,
        120,
        84,
        1,
        71,
        23,
        75,
        168,
        150,
        12,
        161,
        199,
        127,
        39,
        15,
        87,
        149,
        131,
        106,
        160,
        181,
        1,
        138,
        198,
        87,
        124,
        50,
        46,
        146,
        199,
        45,
        159,
        151,
        199,
        71,
        0,
        104,
        49,
        1,
        114,
        149,
        126,
        164,
        44,
        78,
        120
      ],
      "weeklyWasted": [
        146,
        199,
        45,
        159,
        151,
        199,
        71,
        0,
        104,
        49,
        1,
        114,
        149,
        126,
        164,
        44,
        78,
        120,
        2,
        8,
        37,
        58,
        98,
        1,
        93,
        5,
        99,
        44,
        62,
        31,
        41,
        84,
        83,
        20,
        66,
        43,
        67,
        66,
        32,
        14,
        3,
        0,
        99,
        69,
        17,
        38,
        2,
        10,
        2,
        83,
        95,
        98,
        54,
        9,
        1,
        98,
        93,
        12,
        99,
        0,
        99,
        99,
        99,
        33,
        94,
        12,
        69,
        95,
        79,
        76
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        164,
        198,
        58,
        5,
        73,
        132,
        194,
        3,
        110,
        66,
        31,
        91,
        98,
        63,
        1,
        25,
        144,
        46,
        109,
        121,
        184,
        121,
        185,
        35,
        3,
        1,
        187,
        106,
        71,
        149,
        199,
        0,
        84,
        199,
        66,
        0,
        146,
        101,
        10,
        107,
        84,
        10,
        157,
        27,
        1,
        68,
        0,
        119,
        128,
        163,
        94,
        31
      ],
      "weeklyWasted": [
        66,
        0,
        146,
        101,
        10,
        107,
        84,
        10,
        157,
        27,
        1,
        68,
        0,
        119,
        128,
        163,
        94,
        31,
        80,
        20,
        62,
        2,
        98,
        60,
        68,
        40,
        0,
        99,
        54,
        41,
        66,
        82,
        19,
        57,
        66,
        1,
        82,
        0,
        99,
        0,
        49,
        0,
        31,
        63,
        83,
        21,
        99,
        12,
        14,
        75,
        99,
        42,
        58,
        62,
        96,
        53,
        19,
        96,
        23,
        77,
        95,
        85,
        63,
        37,
        76,
        98,
        0,
        90,
        7,
        37
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        168,
        144,
        167,
        61,
        99,
        44,
        131,
        17,
        73,
        1,
        199,
        68,
        115,
        0,
        151,
        83,
        163,
        141,
        33,
        125,
        197,
        178,
        41,
        136,
        132,
        1,
        95,
        170,
        199,
        67,
        117,
        0,
        197,
        173,
        5,
        14,
        68,
        47,
        199,
        199,
        25,
        199,
        0,
        198,
        1,
        4,
        179,
        62,
        198,
        181,
        172,
        85
      ],
      "weeklyWasted": [
        5,
        14,
        68,
        47,
        199,
        199,
        25,
        199,
        0,
        198,
        1,
        4,
        179,
        62,
        198,
        181,
        172,
        85,
        1,
        17,
        0,
        2,
        98,
        1,
        17,
        60,
        99,
        44,
        68,
        72,
        1,
        43,
        26,
        1,
        13,
        70,
        1,
        55,
        2,
        31,
        0,
        19,
        0,
        99,
        72,
        97,
        21,
        96,
        2,
        74,
        27,
        90,
        93,
        47,
        82,
        82,
        69,
        67,
        12,
        14,
        93,
        56,
        56,
        99,
        99,
        73,
        99,
        97,
        54,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        169,
        2,
        198,
        138,
        91,
        37,
        194,
        42,
        9,
        113,
        112,
        2,
        178,
        199,
        113,
        88,
        192,
        175,
        197,
        155,
        197,
        133,
        26,
        195,
        15,
        198,
        28,
        90,
        106,
        0,
        69,
        0,
        197,
        184,
        151,
        0,
        131,
        12,
        159,
        34,
        10,
        0,
        0,
        84,
        1,
        158,
        81,
        54,
        139,
        173,
        104,
        85
      ],
      "weeklyWasted": [
        151,
        0,
        131,
        12,
        159,
        34,
        10,
        0,
        0,
        84,
        1,
        158,
        81,
        54,
        139,
        173,
        104,
        85,
        1,
        7,
        82,
        37,
        74,
        1,
        64,
        9,
        50,
        30,
        32,
        50,
        51,
        9,
        39,
        22,
        31,
        94,
        1,
        66,
        49,
        24,
        89,
        81,
        2,
        99,
        8,
        58,
        78,
        9,
        2,
        97,
        34,
        51,
        99,
        75,
        82,
        0,
        91,
        79,
        69,
        50,
        70,
        1,
        99,
        58,
        43,
        61,
        22,
        77,
        6,
        75
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        199,
        2,
        139,
        175,
        129,
        14,
        194,
        193,
        69,
        1,
        47,
        134,
        2,
        0,
        61,
        3,
        147,
        199,
        103,
        76,
        1,
        4,
        141,
        75,
        71,
        198,
        88,
        27,
        199,
        198,
        133,
        144,
        1,
        4,
        166,
        116,
        137,
        75,
        61,
        38,
        136,
        122,
        161,
        182,
        59,
        44,
        82,
        84,
        198,
        180,
        58,
        75
      ],
      "weeklyWasted": [
        166,
        116,
        137,
        75,
        61,
        38,
        136,
        122,
        161,
        182,
        59,
        44,
        82,
        84,
        198,
        180,
        58,
        75,
        1,
        19,
        0,
        39,
        98,
        69,
        49,
        57,
        56,
        99,
        34,
        34,
        1,
        80,
        56,
        38,
        73,
        3,
        41,
        71,
        47,
        56,
        0,
        20,
        23,
        41,
        62,
        1,
        45,
        10,
        55,
        97,
        41,
        30,
        64,
        27,
        99,
        99,
        54,
        54,
        19,
        0,
        83,
        77,
        20,
        43,
        99,
        98,
        33,
        70,
        99,
        84
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        98,
        64,
        198,
        88,
        89,
        67,
        194,
        131,
        164,
        1,
        0,
        57,
        155,
        82,
        133,
        3,
        192,
        132,
        110,
        22,
        197,
        90,
        89,
        86,
        3,
        1,
        129,
        22,
        74,
        106,
        61,
        79,
        1,
        181,
        15,
        199,
        75,
        50,
        60,
        82,
        46,
        178,
        165,
        28,
        1,
        152,
        195,
        58,
        196,
        160,
        112,
        3
      ],
      "weeklyWasted": [
        15,
        199,
        75,
        50,
        60,
        82,
        46,
        178,
        165,
        28,
        1,
        152,
        195,
        58,
        196,
        160,
        112,
        3,
        1,
        35,
        99,
        2,
        85,
        33,
        99,
        63,
        62,
        31,
        99,
        35,
        9,
        20,
        80,
        89,
        47,
        77,
        1,
        76,
        73,
        6,
        87,
        2,
        40,
        99,
        26,
        97,
        47,
        46,
        14,
        84,
        30,
        72,
        85,
        91,
        74,
        39,
        0,
        75,
        28,
        0,
        87,
        1,
        20,
        0,
        71,
        98,
        99,
        97,
        99,
        66
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        199,
        66,
        144,
        51,
        135,
        197,
        94,
        55,
        92,
        60,
        174,
        85,
        37,
        1,
        177,
        63,
        138,
        114,
        126,
        2,
        116,
        73,
        54,
        76,
        127,
        173,
        172,
        71,
        127,
        56,
        25,
        27,
        60,
        153,
        8,
        67,
        0,
        199,
        137,
        42,
        0,
        18,
        0,
        18,
        65,
        62,
        195,
        98,
        198,
        173,
        116,
        89
      ],
      "weeklyWasted": [
        8,
        67,
        0,
        199,
        137,
        42,
        0,
        18,
        0,
        18,
        65,
        62,
        195,
        98,
        198,
        173,
        116,
        89,
        48,
        1,
        0,
        14,
        98,
        1,
        99,
        0,
        99,
        85,
        99,
        96,
        57,
        40,
        74,
        10,
        47,
        99,
        1,
        18,
        2,
        0,
        78,
        47,
        22,
        63,
        82,
        45,
        99,
        34,
        2,
        96,
        1,
        98,
        49,
        34,
        67,
        54,
        3,
        94,
        4,
        22,
        99,
        1,
        34,
        66,
        99,
        35,
        99,
        70,
        99,
        13
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        199,
        159,
        163,
        195,
        140,
        1,
        122,
        3,
        175,
        115,
        52,
        97,
        98,
        0,
        199,
        198,
        156,
        172,
        197,
        119,
        182,
        125,
        83,
        1,
        87,
        198,
        79,
        1,
        45,
        35,
        199,
        0,
        1,
        159,
        188,
        0,
        109,
        199,
        181,
        175,
        68,
        18,
        104,
        0,
        42,
        21,
        55,
        106,
        165,
        2,
        2,
        3
      ],
      "weeklyWasted": [
        188,
        0,
        109,
        199,
        181,
        175,
        68,
        18,
        104,
        0,
        42,
        21,
        55,
        106,
        165,
        2,
        2,
        3,
        83,
        1,
        78,
        2,
        98,
        46,
        46,
        85,
        99,
        27,
        31,
        27,
        42,
        48,
        17,
        18,
        98,
        99,
        57,
        11,
        40,
        52,
        9,
        0,
        47,
        99,
        98,
        34,
        18,
        1,
        15,
        79,
        56,
        49,
        87,
        0,
        80,
        85,
        22,
        99,
        30,
        70,
        64,
        43,
        53,
        20,
        15,
        98,
        63,
        73,
        99,
        5
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        199,
        2,
        53,
        174,
        184,
        21,
        109,
        120,
        54,
        1,
        175,
        80,
        177,
        0,
        199,
        30,
        0,
        193,
        106,
        2,
        158,
        143,
        2,
        1,
        18,
        20,
        169,
        1,
        124,
        124,
        0,
        83,
        1,
        109,
        149,
        0,
        0,
        171,
        144,
        54,
        57,
        0,
        120,
        108,
        1,
        176,
        100,
        48,
        198,
        2,
        123,
        93
      ],
      "weeklyWasted": [
        149,
        0,
        0,
        171,
        144,
        54,
        57,
        0,
        120,
        108,
        1,
        176,
        100,
        48,
        198,
        2,
        123,
        93,
        1,
        96,
        76,
        21,
        21,
        86,
        69,
        60,
        50,
        0,
        29,
        69,
        54,
        52,
        39,
        1,
        28,
        96,
        34,
        87,
        47,
        40,
        60,
        35,
        23,
        99,
        3,
        97,
        35,
        44,
        2,
        93,
        61,
        59,
        65,
        0,
        66,
        57,
        69,
        1,
        10,
        56,
        70,
        73,
        65,
        11,
        99,
        97,
        99,
        88,
        0,
        5
      ]
    },
    {
      "id": "F55885BD",
      "weeklyUsage": [
        199,
        2,
        71,
        5,
        118,
        197,
        3,
        150,
        61,
        1,
        199,
        116,
        134,
        44,
        199,
        101,
        176,
        187,
        102,
        197,
        70,
        21,
        24,
        1,
        9,
        33,
        129,
        1,
        140,
        81,
        84,
        179,
        127,
        50,
        55,
        63,
        41,
        116,
        84,
        70,
        0,
        186,
        45,
        83,
        28,
        121,
        164,
        101,
        194,
        40,
        174,
        92
      ],
      "weeklyWasted": [
        55,
        63,
        41,
        116,
        84,
        70,
        0,
        186,
        45,
        83,
        28,
        121,
        164,
        101,
        194,
        40,
        174,
        92,
        46,
        36,
        0,
        23,
        0,
        98,
        77,
        0,
        99,
        27,
        58,
        59,
        45,
        94,
        49,
        93,
        4,
        58,
        11,
        67,
        37,
        8,
        0,
        28,
        16,
        99,
        98,
        4,
        57,
        39,
        29,
        12,
        1,
        76,
        56,
        16,
        99,
        56,
        35,
        70,
        70,
        37,
        99,
        99,
        35,
        0,
        99,
        98,
        79,
        52,
        79,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        48,
        2,
        79,
        18,
        175,
        173,
        194,
        3,
        86,
        83,
        198,
        60,
        51,
        11,
        26,
        148,
        192,
        199,
        65,
        2,
        197,
        7,
        80,
        1,
        3,
        1,
        170,
        86,
        39,
        80,
        68,
        0,
        44,
        183,
        111,
        0,
        4,
        151,
        107,
        0,
        25,
        66,
        21,
        129,
        1,
        152,
        30,
        98,
        187,
        78,
        97,
        85
      ],
      "weeklyWasted": [
        111,
        0,
        4,
        151,
        107,
        0,
        25,
        66,
        21,
        129,
        1,
        152,
        30,
        98,
        187,
        78,
        97,
        85,
        1,
        1,
        10,
        96,
        1,
        60,
        99,
        36,
        43,
        60,
        60,
        1,
        75,
        17,
        43,
        4,
        98,
        99,
        39,
        5,
        24,
        65,
        42,
        50,
        96,
        99,
        15,
        71,
        78,
        46,
        2,
        5,
        3,
        72,
        36,
        99,
        77,
        77,
        99,
        56,
        0,
        42,
        0,
        99,
        99,
        60,
        7,
        66,
        17,
        83,
        6,
        25
      ]
    },
    {
      "id": "965102D9",
      "weeklyUsage": [
        148,
        2,
        27,
        5,
        0,
        197,
        194,
        3,
        77,
        35,
        132,
        2,
        7,
        3,
        199,
        180,
        192,
        147,
        23,
        180,
        132,
        97,
        49,
        55,
        3,
        4,
        134,
        1,
        27,
        113,
        33,
        0,
        1,
        160,
        36,
        64,
        161,
        113,
        152,
        0,
        28,
        14,
        21,
        53,
        76,
        196,
        82,
        126,
        174,
        91,
        2,
        124
      ],
      "weeklyWasted": [
        36,
        64,
        161,
        113,
        152,
        0,
        28,
        14,
        21,
        53,
        76,
        196,
        82,
        126,
        174,
        91,
        2,
        124,
        41,
        8,
        0,
        25,
        91,
        51,
        99,
        0,
        55,
        0,
        0,
        1,
        43,
        70,
        76,
        4,
        5,
        99,
        1,
        25,
        7,
        29,
        24,
        24,
        72,
        1,
        44,
        61,
        99,
        77,
        2,
        62,
        39,
        57,
        92,
        47,
        62,
        84,
        73,
        21,
        86,
        55,
        69,
        99,
        99,
        33,
        97,
        98,
        19,
        64,
        18,
        49
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        99,
        63,
        91,
        60,
        0,
        164,
        122,
        110,
        145,
        51,
        199,
        57,
        154,
        3,
        29,
        3,
        192,
        74,
        197,
        175,
        197,
        4,
        15,
        121,
        181,
        167,
        78,
        65,
        199,
        103,
        10,
        0,
        128,
        199,
        4,
        0,
        188,
        97,
        147,
        117,
        146,
        79,
        73,
        0,
        1,
        4,
        195,
        82,
        109,
        52,
        2,
        106
      ],
      "weeklyWasted": [
        4,
        0,
        188,
        97,
        147,
        117,
        146,
        79,
        73,
        0,
        1,
        4,
        195,
        82,
        109,
        52,
        2,
        106,
        79,
        1,
        99,
        2,
        98,
        88,
        22,
        0,
        99,
        64,
        54,
        1,
        63,
        99,
        55,
        5,
        0,
        43,
        43,
        4,
        69,
        59,
        62,
        0,
        18,
        2,
        75,
        5,
        2,
        90,
        99,
        11,
        1,
        82,
        78,
        93,
        77,
        0,
        0,
        99,
        26,
        89,
        22,
        71,
        42,
        37,
        99,
        98,
        20,
        86,
        99,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        178,
        2,
        162,
        60,
        154,
        130,
        194,
        197,
        2,
        1,
        58,
        83,
        109,
        0,
        199,
        68,
        192,
        199,
        3,
        37,
        67,
        66,
        67,
        1,
        3,
        158,
        166,
        95,
        91,
        152,
        97,
        163,
        1,
        4,
        87,
        199,
        116,
        78,
        37,
        31,
        199,
        0,
        0,
        34,
        52,
        140,
        83,
        141,
        198,
        119,
        41,
        3
      ],
      "weeklyWasted": [
        87,
        199,
        116,
        78,
        37,
        31,
        199,
        0,
        0,
        34,
        52,
        140,
        83,
        141,
        198,
        119,
        41,
        3,
        13,
        9,
        16,
        81,
        45,
        88,
        99,
        0,
        0,
        13,
        42,
        72,
        65,
        81,
        77,
        1,
        0,
        45,
        39,
        42,
        99,
        57,
        70,
        22,
        43,
        71,
        63,
        40,
        2,
        1,
        2,
        13,
        23,
        59,
        80,
        8,
        59,
        69,
        99,
        55,
        99,
        0,
        99,
        56,
        99,
        45,
        99,
        98,
        99,
        75,
        99,
        16
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyUsage": [
        17,
        127,
        198,
        85,
        139,
        197,
        46,
        54,
        88,
        199,
        0,
        34,
        198,
        32,
        126,
        130,
        192,
        86,
        45,
        197,
        197,
        198,
        39,
        93,
        85,
        57,
        184,
        163,
        0,
        15,
        71,
        87,
        118,
        143,
        154,
        17,
        135,
        173,
        180,
        94,
        33,
        0,
        0,
        152,
        40,
        16,
        135,
        138,
        176,
        199,
        108,
        95
      ],
      "weeklyWasted": [
        154,
        17,
        135,
        173,
        180,
        94,
        33,
        0,
        0,
        152,
        40,
        16,
        135,
        138,
        176,
        199,
        108,
        95,
        75,
        1,
        38,
        2,
        86,
        62,
        33,
        4,
        99,
        99,
        91,
        85,
        55,
        68,
        42,
        78,
        98,
        79,
        41,
        0,
        2,
        12,
        40,
        40,
        39,
        98,
        63,
        1,
        78,
        1,
        41,
        7,
        57,
        35,
        99,
        43,
        99,
        99,
        43,
        17,
        12,
        22,
        65,
        96,
        27,
        95,
        20,
        98,
        0,
        99,
        47,
        17
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyUsage": [
        199,
        130,
        33,
        54,
        69,
        197,
        10,
        3,
        2,
        77,
        199,
        2,
        155,
        0,
        107,
        109,
        177,
        52,
        86,
        2,
        40,
        4,
        109,
        43,
        133,
        62,
        84,
        71,
        122,
        146,
        7,
        56,
        97,
        184,
        158,
        0,
        0,
        136,
        114,
        0,
        0,
        29,
        73,
        62,
        1,
        106,
        0,
        0,
        142,
        48,
        2,
        151
      ],
      "weeklyWasted": [
        158,
        0,
        0,
        136,
        114,
        0,
        0,
        29,
        73,
        62,
        1,
        106,
        0,
        0,
        142,
        48,
        2,
        151,
        30,
        64,
        22,
        2,
        95,
        1,
        45,
        0,
        99,
        99,
        73,
        69,
        18,
        74,
        62,
        98,
        11,
        63,
        16,
        45,
        59,
        90,
        58,
        1,
        60,
        99,
        66,
        83,
        6,
        1,
        99,
        63,
        39,
        98,
        0,
        9,
        49,
        82,
        80,
        53,
        0,
        98,
        89,
        99,
        55,
        0,
        51,
        84,
        62,
        90,
        63,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        199,
        73,
        135,
        56,
        67,
        1,
        172,
        168,
        123,
        1,
        136,
        74,
        154,
        0,
        123,
        111,
        107,
        145,
        88,
        124,
        88,
        138,
        112,
        119,
        176,
        161,
        197,
        52,
        172,
        137,
        30,
        0,
        108,
        127,
        62,
        197,
        31,
        5,
        94,
        0,
        177,
        199,
        0,
        123,
        1,
        38,
        17,
        59,
        198,
        129,
        85,
        87
      ],
      "weeklyWasted": [
        62,
        197,
        31,
        5,
        94,
        0,
        177,
        199,
        0,
        123,
        1,
        38,
        17,
        59,
        198,
        129,
        85,
        87,
        1,
        1,
        41,
        5,
        98,
        51,
        86,
        38,
        0,
        11,
        99,
        17,
        99,
        30,
        0,
        1,
        0,
        52,
        1,
        89,
        2,
        48,
        19,
        11,
        34,
        61,
        54,
        40,
        99,
        69,
        10,
        37,
        1,
        98,
        83,
        0,
        99,
        91,
        99,
        99,
        43,
        0,
        99,
        66,
        47,
        22,
        76,
        98,
        36,
        99,
        99,
        71
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        199,
        131,
        30,
        5,
        124,
        130,
        29,
        52,
        134,
        1,
        0,
        2,
        69,
        199,
        150,
        91,
        132,
        49,
        97,
        134,
        197,
        6,
        2,
        133,
        3,
        70,
        108,
        88,
        152,
        78,
        28,
        82,
        24,
        7,
        150,
        77,
        19,
        199,
        127,
        59,
        21,
        175,
        2,
        34,
        1,
        4,
        52,
        16,
        192,
        2,
        92,
        76
      ],
      "weeklyWasted": [
        150,
        77,
        19,
        199,
        127,
        59,
        21,
        175,
        2,
        34,
        1,
        4,
        52,
        16,
        192,
        2,
        92,
        76,
        1,
        95,
        56,
        16,
        82,
        98,
        33,
        0,
        69,
        81,
        94,
        43,
        99,
        92,
        67,
        31,
        7,
        1,
        6,
        27,
        7,
        72,
        3,
        0,
        97,
        20,
        98,
        13,
        95,
        2,
        22,
        40,
        1,
        98,
        35,
        53,
        60,
        64,
        20,
        56,
        11,
        4,
        99,
        44,
        51,
        45,
        99,
        86,
        40,
        93,
        37,
        1
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        125,
        65,
        189,
        195,
        57,
        125,
        130,
        12,
        199,
        1,
        36,
        58,
        91,
        0,
        31,
        186,
        153,
        182,
        158,
        197,
        197,
        95,
        110,
        177,
        3,
        84,
        112,
        168,
        145,
        104,
        172,
        0,
        49,
        11,
        45,
        141,
        111,
        5,
        37,
        102,
        152,
        191,
        0,
        48,
        49,
        195,
        15,
        97,
        170,
        99,
        34,
        63
      ],
      "weeklyWasted": [
        45,
        141,
        111,
        5,
        37,
        102,
        152,
        191,
        0,
        48,
        49,
        195,
        15,
        97,
        170,
        99,
        34,
        63,
        22,
        1,
        40,
        2,
        87,
        98,
        59,
        0,
        99,
        0,
        40,
        83,
        67,
        99,
        29,
        1,
        8,
        99,
        1,
        48,
        96,
        48,
        12,
        93,
        31,
        43,
        27,
        1,
        2,
        22,
        23,
        80,
        25,
        67,
        73,
        14,
        66,
        53,
        57,
        99,
        73,
        7,
        99,
        74,
        31,
        28,
        99,
        98,
        75,
        69,
        75,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        156,
        96,
        198,
        5,
        34,
        1,
        109,
        51,
        50,
        1,
        141,
        59,
        168,
        175,
        199,
        148,
        20,
        28,
        3,
        92,
        129,
        133,
        2,
        59,
        182,
        1,
        156,
        66,
        42,
        114,
        0,
        33,
        145,
        123,
        115,
        159,
        156,
        153,
        163,
        24,
        116,
        199,
        133,
        32,
        45,
        119,
        195,
        33,
        198,
        23,
        2,
        105
      ],
      "weeklyWasted": [
        115,
        159,
        156,
        153,
        163,
        24,
        116,
        199,
        133,
        32,
        45,
        119,
        195,
        33,
        198,
        23,
        2,
        105,
        1,
        36,
        26,
        20,
        98,
        22,
        33,
        0,
        99,
        40,
        53,
        15,
        64,
        4,
        97,
        23,
        36,
        99,
        26,
        27,
        51,
        10,
        26,
        0,
        49,
        99,
        98,
        1,
        17,
        1,
        49,
        42,
        32,
        98,
        73,
        0,
        99,
        99,
        7,
        85,
        24,
        16,
        98,
        51,
        99,
        2,
        99,
        98,
        7,
        95,
        99,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        11,
        124,
        198,
        103,
        11,
        136,
        19,
        41,
        19,
        6,
        199,
        95,
        132,
        59,
        50,
        17,
        190,
        199,
        135,
        166,
        174,
        51,
        102,
        131,
        96,
        37,
        180,
        21,
        45,
        104,
        199,
        61,
        54,
        82,
        43,
        0,
        198,
        31,
        10,
        0,
        34,
        106,
        22,
        25,
        24,
        196,
        73,
        50,
        196,
        86,
        166,
        196
      ],
      "weeklyWasted": [
        43,
        0,
        198,
        31,
        10,
        0,
        34,
        106,
        22,
        25,
        24,
        196,
        73,
        50,
        196,
        86,
        166,
        196,
        71,
        24,
        99,
        2,
        65,
        98,
        35,
        0,
        87,
        15,
        73,
        1,
        36,
        79,
        50,
        98,
        37,
        99,
        59,
        31,
        66,
        66,
        97,
        0,
        18,
        45,
        45,
        1,
        62,
        86,
        2,
        69,
        37,
        98,
        74,
        14,
        99,
        1,
        12,
        11,
        25,
        79,
        50,
        99,
        29,
        84,
        52,
        98,
        88,
        94,
        51,
        1
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        133,
        2,
        81,
        59,
        46,
        197,
        194,
        13,
        199,
        1,
        112,
        54,
        22,
        66,
        188,
        183,
        192,
        199,
        63,
        197,
        1,
        10,
        2,
        195,
        3,
        78,
        172,
        1,
        199,
        179,
        13,
        0,
        87,
        199,
        185,
        32,
        198,
        75,
        10,
        172,
        154,
        199,
        115,
        132,
        1,
        196,
        0,
        198,
        76,
        2,
        42,
        109
      ],
      "weeklyWasted": [
        185,
        32,
        198,
        75,
        10,
        172,
        154,
        199,
        115,
        132,
        1,
        196,
        0,
        198,
        76,
        2,
        42,
        109,
        93,
        24,
        12,
        13,
        66,
        97,
        42,
        8,
        94,
        31,
        62,
        90,
        60,
        99,
        83,
        98,
        0,
        57,
        79,
        43,
        57,
        60,
        0,
        0,
        12,
        52,
        98,
        44,
        85,
        6,
        26,
        81,
        12,
        46,
        79,
        27,
        53,
        35,
        30,
        79,
        57,
        66,
        43,
        83,
        26,
        64,
        21,
        15,
        71,
        12,
        99,
        79
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyUsage": [
        199,
        77,
        186,
        36,
        119,
        59,
        139,
        83,
        103,
        102,
        105,
        142,
        194,
        89,
        138,
        115,
        3,
        64,
        37,
        175,
        175,
        98,
        2,
        189,
        85,
        36,
        153,
        60,
        80,
        89,
        75,
        131,
        177,
        20,
        27,
        79,
        78,
        195,
        10,
        23,
        0,
        113,
        108,
        0,
        1,
        164,
        195,
        27,
        198,
        2,
        144,
        3
      ],
      "weeklyWasted": [
        27,
        79,
        78,
        195,
        10,
        23,
        0,
        113,
        108,
        0,
        1,
        164,
        195,
        27,
        198,
        2,
        144,
        3,
        82,
        48,
        37,
        13,
        98,
        63,
        70,
        0,
        99,
        36,
        56,
        86,
        99,
        91,
        70,
        36,
        0,
        44,
        68,
        85,
        29,
        0,
        0,
        55,
        17,
        1,
        98,
        56,
        89,
        23,
        2,
        38,
        28,
        98,
        21,
        0,
        43,
        26,
        0,
        90,
        4,
        86,
        16,
        11,
        58,
        54,
        55,
        36,
        99,
        41,
        59,
        1
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        148,
        97,
        93,
        7,
        1,
        20,
        194,
        72,
        26,
        1,
        199,
        2,
        53,
        34,
        134,
        98,
        157,
        83,
        106,
        122,
        128,
        87,
        2,
        104,
        62,
        132,
        54,
        112,
        30,
        29,
        17,
        48,
        61,
        41,
        174,
        64,
        198,
        124,
        61,
        29,
        120,
        107,
        72,
        77,
        13,
        196,
        63,
        185,
        161,
        199,
        118,
        196
      ],
      "weeklyWasted": [
        174,
        64,
        198,
        124,
        61,
        29,
        120,
        107,
        72,
        77,
        13,
        196,
        63,
        185,
        161,
        199,
        118,
        196,
        1,
        4,
        47,
        2,
        98,
        88,
        99,
        0,
        99,
        99,
        99,
        42,
        62,
        99,
        6,
        98,
        16,
        99,
        23,
        80,
        26,
        42,
        52,
        16,
        27,
        74,
        60,
        49,
        2,
        80,
        10,
        72,
        17,
        98,
        56,
        30,
        26,
        99,
        42,
        99,
        99,
        45,
        88,
        23,
        68,
        0,
        71,
        98,
        29,
        99,
        36,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        13,
        75,
        53,
        87,
        198,
        52,
        79,
        3,
        199,
        1,
        12,
        44,
        182,
        177,
        1,
        157,
        134,
        42,
        197,
        197,
        100,
        198,
        2,
        147,
        129,
        198,
        169,
        1,
        199,
        119,
        192,
        124,
        8,
        168,
        4,
        7,
        117,
        123,
        36,
        199,
        28,
        199,
        53,
        35,
        70,
        39,
        0,
        80,
        198,
        2,
        68,
        3
      ],
      "weeklyWasted": [
        4,
        7,
        117,
        123,
        36,
        199,
        28,
        199,
        53,
        35,
        70,
        39,
        0,
        80,
        198,
        2,
        68,
        3,
        99,
        1,
        94,
        96,
        98,
        78,
        10,
        0,
        99,
        48,
        0,
        33,
        99,
        76,
        0,
        35,
        36,
        72,
        45,
        45,
        70,
        0,
        0,
        0,
        16,
        23,
        59,
        44,
        61,
        96,
        18,
        79,
        5,
        64,
        99,
        65,
        61,
        51,
        0,
        81,
        39,
        0,
        30,
        51,
        46,
        57,
        23,
        98,
        39,
        62,
        99,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        140,
        119,
        198,
        19,
        0,
        1,
        194,
        76,
        180,
        1,
        143,
        43,
        152,
        41,
        199,
        102,
        90,
        194,
        4,
        72,
        89,
        13,
        73,
        123,
        197,
        84,
        128,
        94,
        199,
        173,
        81,
        116,
        111,
        199,
        159,
        66,
        35,
        199,
        141,
        49,
        27,
        48,
        0,
        48,
        1,
        170,
        143,
        47,
        198,
        10,
        50,
        13
      ],
      "weeklyWasted": [
        159,
        66,
        35,
        199,
        141,
        49,
        27,
        48,
        0,
        48,
        1,
        170,
        143,
        47,
        198,
        10,
        50,
        13,
        8,
        2,
        50,
        41,
        65,
        76,
        51,
        12,
        68,
        32,
        99,
        28,
        50,
        29,
        33,
        97,
        72,
        99,
        82,
        99,
        45,
        39,
        90,
        0,
        63,
        1,
        18,
        6,
        75,
        36,
        2,
        37,
        75,
        98,
        79,
        49,
        61,
        41,
        0,
        27,
        10,
        19,
        92,
        60,
        99,
        99,
        75,
        98,
        5,
        21,
        89,
        45
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyUsage": [
        143,
        65,
        56,
        183,
        148,
        1,
        74,
        112,
        169,
        1,
        43,
        49,
        198,
        0,
        170,
        3,
        174,
        199,
        169,
        143,
        1,
        120,
        2,
        64,
        133,
        42,
        156,
        51,
        199,
        119,
        83,
        147,
        59,
        72,
        4,
        4,
        198,
        5,
        76,
        0,
        90,
        90,
        0,
        0,
        1,
        188,
        91,
        48,
        198,
        2,
        187,
        48
      ],
      "weeklyWasted": [
        4,
        4,
        198,
        5,
        76,
        0,
        90,
        90,
        0,
        0,
        1,
        188,
        91,
        48,
        198,
        2,
        187,
        48,
        24,
        41,
        56,
        2,
        98,
        94,
        65,
        81,
        25,
        97,
        99,
        99,
        68,
        99,
        39,
        1,
        0,
        99,
        4,
        25,
        2,
        0,
        0,
        72,
        54,
        37,
        98,
        1,
        53,
        1,
        2,
        97,
        35,
        70,
        99,
        68,
        99,
        99,
        70,
        27,
        38,
        25,
        99,
        73,
        99,
        0,
        94,
        98,
        80,
        87,
        56,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        112,
        102,
        102,
        5,
        82,
        1,
        194,
        101,
        95,
        1,
        120,
        38,
        46,
        64,
        199,
        114,
        192,
        199,
        26,
        26,
        87,
        26,
        187,
        44,
        166,
        198,
        77,
        108,
        177,
        143,
        119,
        187,
        52,
        194,
        138,
        192,
        198,
        70,
        122,
        121,
        0,
        40,
        48,
        42,
        48,
        88,
        25,
        50,
        165,
        131,
        143,
        16
      ],
      "weeklyWasted": [
        138,
        192,
        198,
        70,
        122,
        121,
        0,
        40,
        48,
        42,
        48,
        88,
        25,
        50,
        165,
        131,
        143,
        16,
        55,
        46,
        43,
        61,
        98,
        80,
        90,
        69,
        7,
        24,
        99,
        55,
        1,
        57,
        0,
        1,
        98,
        13,
        42,
        45,
        99,
        37,
        12,
        0,
        47,
        99,
        52,
        39,
        25,
        63,
        2,
        48,
        1,
        83,
        55,
        57,
        85,
        99,
        57,
        99,
        88,
        19,
        0,
        63,
        60,
        34,
        99,
        98,
        93,
        80,
        99,
        97
      ]
    },
    {
      "id": "C71B9801",
      "weeklyUsage": [
        90,
        63,
        76,
        91,
        198,
        1,
        90,
        109,
        140,
        107,
        10,
        57,
        58,
        0,
        87,
        153,
        192,
        142,
        89,
        2,
        103,
        4,
        43,
        22,
        103,
        198,
        142,
        101,
        125,
        91,
        199,
        81,
        16,
        164,
        31,
        199,
        95,
        41,
        86,
        129,
        41,
        106,
        135,
        111,
        168,
        14,
        90,
        94,
        198,
        2,
        64,
        3
      ],
      "weeklyWasted": [
        31,
        199,
        95,
        41,
        86,
        129,
        41,
        106,
        135,
        111,
        168,
        14,
        90,
        94,
        198,
        2,
        64,
        3,
        77,
        26,
        15,
        2,
        98,
        91,
        5,
        0,
        83,
        25,
        99,
        57,
        73,
        99,
        0,
        1,
        72,
        51,
        70,
        5,
        2,
        81,
        36,
        5,
        99,
        99,
        98,
        1,
        2,
        1,
        2,
        27,
        20,
        98,
        99,
        84,
        87,
        99,
        89,
        74,
        57,
        41,
        80,
        71,
        53,
        41,
        96,
        40,
        99,
        99,
        30,
        64
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        70,
        85,
        162,
        37,
        198,
        90,
        77,
        174,
        98,
        81,
        121,
        36,
        155,
        54,
        199,
        198,
        147,
        96,
        131,
        10,
        155,
        153,
        2,
        109,
        123,
        52,
        40,
        88,
        63,
        57,
        70,
        85,
        73,
        183,
        122,
        63,
        69,
        131,
        142,
        137,
        0,
        115,
        95,
        0,
        1,
        72,
        22,
        128,
        198,
        85,
        138,
        41
      ],
      "weeklyWasted": [
        122,
        63,
        69,
        131,
        142,
        137,
        0,
        115,
        95,
        0,
        1,
        72,
        22,
        128,
        198,
        85,
        138,
        41,
        58,
        23,
        57,
        2,
        95,
        52,
        0,
        10,
        99,
        0,
        99,
        99,
        55,
        67,
        60,
        83,
        26,
        99,
        10,
        0,
        67,
        35,
        13,
        85,
        54,
        99,
        75,
        8,
        23,
        35,
        2,
        74,
        1,
        98,
        99,
        23,
        78,
        57,
        29,
        99,
        0,
        17,
        99,
        54,
        92,
        75,
        99,
        5,
        44,
        22,
        99,
        40
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        107,
        123,
        45,
        48,
        62,
        195,
        20,
        110,
        23,
        94,
        199,
        35,
        193,
        51,
        1,
        138,
        192,
        83,
        197,
        186,
        140,
        4,
        147,
        113,
        3,
        37,
        140,
        1,
        169,
        198,
        199,
        0,
        1,
        4,
        46,
        73,
        64,
        74,
        10,
        156,
        178,
        199,
        54,
        0,
        2,
        105,
        31,
        33,
        148,
        44,
        141,
        118
      ],
      "weeklyWasted": [
        46,
        73,
        64,
        74,
        10,
        156,
        178,
        199,
        54,
        0,
        2,
        105,
        31,
        33,
        148,
        44,
        141,
        118,
        48,
        49,
        99,
        4,
        98,
        98,
        26,
        25,
        30,
        54,
        59,
        1,
        72,
        56,
        11,
        1,
        0,
        47,
        19,
        89,
        2,
        56,
        87,
        0,
        0,
        80,
        94,
        71,
        2,
        54,
        40,
        44,
        58,
        98,
        46,
        64,
        46,
        99,
        90,
        1,
        8,
        0,
        67,
        87,
        11,
        0,
        99,
        98,
        51,
        45,
        35,
        1
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        132,
        146,
        0,
        5,
        0,
        15,
        94,
        102,
        199,
        1,
        102,
        18,
        155,
        3,
        60,
        81,
        192,
        153,
        118,
        184,
        64,
        33,
        197,
        195,
        3,
        32,
        197,
        5,
        174,
        119,
        91,
        182,
        37,
        102,
        126,
        44,
        88,
        81,
        101,
        2,
        70,
        50,
        154,
        92,
        1,
        95,
        0,
        118,
        173,
        134,
        161,
        149
      ],
      "weeklyWasted": [
        126,
        44,
        88,
        81,
        101,
        2,
        70,
        50,
        154,
        92,
        1,
        95,
        0,
        118,
        173,
        134,
        161,
        149,
        63,
        72,
        25,
        2,
        98,
        71,
        37,
        54,
        99,
        99,
        99,
        99,
        4,
        99,
        12,
        58,
        98,
        99,
        20,
        65,
        91,
        35,
        0,
        30,
        0,
        73,
        0,
        92,
        27,
        1,
        2,
        83,
        91,
        93,
        90,
        56,
        99,
        35,
        0,
        65,
        9,
        0,
        98,
        83,
        24,
        49,
        0,
        35,
        43,
        6,
        25,
        68
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        199,
        73,
        146,
        54,
        132,
        102,
        92,
        3,
        72,
        1,
        199,
        67,
        85,
        199,
        124,
        137,
        192,
        1,
        3,
        2,
        123,
        130,
        187,
        1,
        20,
        30,
        164,
        41,
        0,
        184,
        104,
        158,
        90,
        54,
        128,
        71,
        104,
        199,
        35,
        56,
        45,
        6,
        54,
        0,
        39,
        186,
        195,
        73,
        1,
        8,
        127,
        3
      ],
      "weeklyWasted": [
        128,
        71,
        104,
        199,
        35,
        56,
        45,
        6,
        54,
        0,
        39,
        186,
        195,
        73,
        1,
        8,
        127,
        3,
        36,
        74,
        26,
        44,
        83,
        98,
        74,
        0,
        97,
        24,
        46,
        88,
        24,
        31,
        97,
        22,
        98,
        83,
        83,
        0,
        86,
        34,
        25,
        0,
        18,
        74,
        58,
        55,
        2,
        66,
        2,
        77,
        1,
        98,
        43,
        27,
        99,
        99,
        28,
        33,
        0,
        0,
        77,
        59,
        64,
        89,
        37,
        26,
        78,
        84,
        23,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        199,
        79,
        188,
        195,
        115,
        100,
        85,
        197,
        87,
        105,
        122,
        2,
        131,
        0,
        132,
        60,
        192,
        92,
        63,
        109,
        197,
        106,
        155,
        63,
        5,
        129,
        160,
        92,
        170,
        35,
        74,
        31,
        102,
        140,
        129,
        178,
        101,
        5,
        10,
        23,
        183,
        18,
        0,
        42,
        52,
        66,
        48,
        88,
        198,
        199,
        12,
        3
      ],
      "weeklyWasted": [
        129,
        178,
        101,
        5,
        10,
        23,
        183,
        18,
        0,
        42,
        52,
        66,
        48,
        88,
        198,
        199,
        12,
        3,
        33,
        27,
        48,
        2,
        95,
        59,
        99,
        0,
        99,
        0,
        53,
        50,
        46,
        45,
        97,
        1,
        0,
        49,
        78,
        6,
        2,
        28,
        97,
        16,
        75,
        99,
        98,
        59,
        78,
        14,
        79,
        77,
        44,
        31,
        73,
        65,
        12,
        75,
        88,
        99,
        91,
        0,
        99,
        69,
        29,
        36,
        84,
        98,
        12,
        79,
        99,
        44
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        121,
        116,
        105,
        57,
        51,
        9,
        190,
        25,
        148,
        8,
        120,
        27,
        2,
        59,
        11,
        3,
        175,
        199,
        62,
        161,
        119,
        4,
        77,
        49,
        56,
        115,
        179,
        1,
        125,
        198,
        44,
        0,
        63,
        132,
        4,
        0,
        0,
        130,
        119,
        45,
        60,
        0,
        70,
        17,
        1,
        161,
        19,
        126,
        189,
        122,
        94,
        54
      ],
      "weeklyWasted": [
        4,
        0,
        0,
        130,
        119,
        45,
        60,
        0,
        70,
        17,
        1,
        161,
        19,
        126,
        189,
        122,
        94,
        54,
        96,
        11,
        3,
        16,
        98,
        55,
        89,
        14,
        80,
        68,
        0,
        1,
        8,
        26,
        95,
        45,
        51,
        51,
        30,
        71,
        29,
        0,
        47,
        57,
        0,
        84,
        10,
        5,
        21,
        31,
        56,
        87,
        69,
        66,
        76,
        5,
        89,
        14,
        46,
        71,
        99,
        22,
        81,
        99,
        99,
        27,
        40,
        98,
        66,
        99,
        30,
        99
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        7,
        2,
        198,
        73,
        147,
        119,
        194,
        31,
        114,
        1,
        81,
        49,
        158,
        161,
        137,
        102,
        186,
        161,
        3,
        186,
        60,
        126,
        115,
        177,
        105,
        149,
        97,
        1,
        131,
        147,
        0,
        133,
        117,
        199,
        188,
        52,
        198,
        52,
        92,
        1,
        152,
        89,
        0,
        101,
        18,
        151,
        0,
        152,
        198,
        154,
        32,
        3
      ],
      "weeklyWasted": [
        188,
        52,
        198,
        52,
        92,
        1,
        152,
        89,
        0,
        101,
        18,
        151,
        0,
        152,
        198,
        154,
        32,
        3,
        22,
        6,
        56,
        75,
        41,
        50,
        99,
        0,
        23,
        95,
        92,
        80,
        36,
        60,
        63,
        1,
        52,
        83,
        27,
        44,
        2,
        99,
        37,
        0,
        77,
        99,
        22,
        97,
        30,
        65,
        31,
        97,
        1,
        58,
        99,
        39,
        1,
        71,
        36,
        62,
        0,
        13,
        99,
        40,
        99,
        87,
        87,
        68,
        0,
        70,
        45,
        48
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        79,
        56,
        121,
        144,
        129,
        42,
        79,
        146,
        125,
        19,
        112,
        41,
        2,
        186,
        117,
        8,
        55,
        37,
        152,
        65,
        197,
        29,
        4,
        66,
        92,
        89,
        180,
        69,
        139,
        121,
        81,
        85,
        179,
        30,
        84,
        154,
        78,
        7,
        46,
        28,
        197,
        28,
        33,
        47,
        53,
        104,
        4,
        198,
        31,
        57,
        42,
        3
      ],
      "weeklyWasted": [
        84,
        154,
        78,
        7,
        46,
        28,
        197,
        28,
        33,
        47,
        53,
        104,
        4,
        198,
        31,
        57,
        42,
        3,
        7,
        1,
        0,
        63,
        36,
        1,
        57,
        0,
        80,
        0,
        20,
        97,
        60,
        12,
        50,
        1,
        67,
        23,
        37,
        91,
        2,
        14,
        21,
        0,
        22,
        86,
        66,
        57,
        99,
        24,
        7,
        58,
        30,
        70,
        64,
        25,
        1,
        53,
        99,
        97,
        73,
        45,
        39,
        67,
        99,
        42,
        35,
        80,
        95,
        1,
        99,
        99
      ]
    },
    {
      "id": "7B415249",
      "weeklyUsage": [
        157,
        192,
        26,
        154,
        40,
        123,
        126,
        3,
        122,
        63,
        130,
        150,
        120,
        0,
        71,
        169,
        77,
        164,
        141,
        110,
        17,
        45,
        99,
        140,
        3,
        75,
        136,
        104,
        63,
        173,
        48,
        111,
        16,
        4,
        132,
        39,
        5,
        121,
        10,
        0,
        0,
        0,
        0,
        0,
        27,
        107,
        66,
        157,
        159,
        87,
        26,
        196
      ],
      "weeklyWasted": [
        132,
        39,
        5,
        121,
        10,
        0,
        0,
        0,
        0,
        0,
        27,
        107,
        66,
        157,
        159,
        87,
        26,
        196,
        59,
        17,
        42,
        12,
        98,
        1,
        99,
        0,
        70,
        46,
        91,
        1,
        4,
        3,
        48,
        8,
        98,
        56,
        1,
        0,
        73,
        42,
        27,
        0,
        31,
        99,
        47,
        54,
        63,
        1,
        31,
        47,
        35,
        52,
        99,
        40,
        78,
        33,
        75,
        59,
        20,
        98,
        69,
        99,
        42,
        8,
        71,
        70,
        67,
        95,
        85,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        199,
        157,
        0,
        83,
        147,
        122,
        131,
        18,
        126,
        10,
        149,
        2,
        116,
        9,
        96,
        114,
        152,
        90,
        80,
        109,
        197,
        110,
        136,
        184,
        3,
        1,
        141,
        70,
        188,
        30,
        93,
        41,
        154,
        143,
        31,
        0,
        117,
        29,
        10,
        89,
        99,
        75,
        85,
        183,
        20,
        4,
        15,
        198,
        198,
        109,
        110,
        153
      ],
      "weeklyWasted": [
        31,
        0,
        117,
        29,
        10,
        89,
        99,
        75,
        85,
        183,
        20,
        4,
        15,
        198,
        198,
        109,
        110,
        153,
        29,
        6,
        51,
        94,
        98,
        86,
        0,
        56,
        94,
        10,
        0,
        79,
        65,
        99,
        51,
        13,
        3,
        66,
        99,
        51,
        79,
        78,
        15,
        99,
        16,
        99,
        98,
        77,
        2,
        37,
        32,
        72,
        99,
        9,
        99,
        62,
        99,
        33,
        99,
        70,
        0,
        0,
        80,
        99,
        20,
        72,
        86,
        4,
        37,
        63,
        14,
        69
      ]
    },
    {
      "id": "F55885BD",
      "weeklyUsage": [
        175,
        69,
        54,
        89,
        0,
        65,
        23,
        95,
        2,
        55,
        1,
        144,
        198,
        199,
        81,
        148,
        136,
        87,
        193,
        115,
        197,
        161,
        197,
        116,
        3,
        174,
        197,
        139,
        18,
        110,
        85,
        144,
        124,
        87,
        181,
        47,
        67,
        81,
        135,
        118,
        104,
        47,
        131,
        0,
        90,
        196,
        103,
        180,
        195,
        164,
        146,
        39
      ],
      "weeklyWasted": [
        181,
        47,
        67,
        81,
        135,
        118,
        104,
        47,
        131,
        0,
        90,
        196,
        103,
        180,
        195,
        164,
        146,
        39,
        81,
        17,
        3,
        38,
        37,
        60,
        87,
        0,
        46,
        40,
        48,
        72,
        11,
        85,
        24,
        34,
        98,
        19,
        47,
        60,
        35,
        0,
        42,
        1,
        0,
        99,
        50,
        73,
        37,
        6,
        58,
        53,
        45,
        38,
        39,
        52,
        68,
        0,
        56,
        99,
        33,
        56,
        20,
        41,
        40,
        69,
        83,
        28,
        55,
        1,
        74,
        75
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        7,
        174,
        96,
        74,
        159,
        55,
        94,
        3,
        71,
        1,
        77,
        22,
        2,
        134,
        199,
        11,
        192,
        89,
        158,
        105,
        31,
        73,
        147,
        159,
        3,
        62,
        197,
        99,
        166,
        66,
        199,
        0,
        146,
        102,
        69,
        198,
        178,
        177,
        87,
        166,
        183,
        0,
        193,
        63,
        35,
        196,
        132,
        0,
        198,
        2,
        43,
        40
      ],
      "weeklyWasted": [
        69,
        198,
        178,
        177,
        87,
        166,
        183,
        0,
        193,
        63,
        35,
        196,
        132,
        0,
        198,
        2,
        43,
        40,
        29,
        13,
        43,
        28,
        98,
        10,
        52,
        11,
        99,
        62,
        37,
        99,
        99,
        54,
        46,
        20,
        60,
        58,
        78,
        10,
        99,
        85,
        0,
        36,
        69,
        47,
        46,
        84,
        99,
        63,
        2,
        78,
        1,
        19,
        94,
        6,
        99,
        99,
        89,
        99,
        0,
        46,
        96,
        70,
        42,
        0,
        83,
        12,
        90,
        96,
        99,
        36
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        162,
        89,
        198,
        20,
        33,
        48,
        187,
        3,
        79,
        1,
        199,
        2,
        181,
        87,
        63,
        78,
        154,
        1,
        57,
        21,
        180,
        4,
        122,
        48,
        62,
        127,
        67,
        1,
        134,
        158,
        88,
        32,
        68,
        82,
        120,
        199,
        114,
        98,
        197,
        28,
        80,
        98,
        0,
        28,
        49,
        149,
        78,
        4,
        178,
        45,
        114,
        79
      ],
      "weeklyWasted": [
        120,
        199,
        114,
        98,
        197,
        28,
        80,
        98,
        0,
        28,
        49,
        149,
        78,
        4,
        178,
        45,
        114,
        79,
        60,
        38,
        85,
        7,
        0,
        35,
        36,
        0,
        99,
        99,
        99,
        33,
        35,
        83,
        87,
        47,
        67,
        92,
        8,
        42,
        49,
        0,
        12,
        0,
        71,
        1,
        49,
        26,
        49,
        1,
        58,
        70,
        75,
        98,
        45,
        38,
        76,
        88,
        65,
        99,
        50,
        0,
        40,
        99,
        99,
        60,
        10,
        60,
        38,
        75,
        44,
        90
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        161,
        112,
        62,
        195,
        77,
        197,
        126,
        95,
        160,
        68,
        65,
        46,
        81,
        0,
        173,
        78,
        96,
        159,
        66,
        111,
        86,
        148,
        173,
        134,
        110,
        22,
        177,
        107,
        188,
        102,
        114,
        153,
        86,
        145,
        174,
        117,
        198,
        99,
        153,
        18,
        2,
        101,
        0,
        68,
        65,
        161,
        0,
        198,
        198,
        18,
        188,
        3
      ],
      "weeklyWasted": [
        174,
        117,
        198,
        99,
        153,
        18,
        2,
        101,
        0,
        68,
        65,
        161,
        0,
        198,
        198,
        18,
        188,
        3,
        90,
        25,
        93,
        2,
        48,
        78,
        92,
        45,
        93,
        26,
        34,
        99,
        35,
        88,
        7,
        43,
        49,
        50,
        52,
        15,
        58,
        37,
        6,
        30,
        34,
        99,
        79,
        97,
        99,
        61,
        36,
        76,
        1,
        97,
        59,
        61,
        99,
        0,
        99,
        99,
        3,
        98,
        90,
        99,
        70,
        12,
        30,
        98,
        99,
        69,
        65,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        137,
        2,
        100,
        50,
        16,
        28,
        168,
        135,
        180,
        62,
        108,
        61,
        33,
        0,
        120,
        70,
        52,
        120,
        138,
        197,
        77,
        67,
        36,
        12,
        63,
        113,
        178,
        54,
        173,
        94,
        71,
        0,
        1,
        199,
        134,
        0,
        128,
        134,
        94,
        0,
        77,
        48,
        42,
        11,
        199,
        80,
        92,
        111,
        198,
        95,
        155,
        3
      ],
      "weeklyWasted": [
        134,
        0,
        128,
        134,
        94,
        0,
        77,
        48,
        42,
        11,
        199,
        80,
        92,
        111,
        198,
        95,
        155,
        3,
        1,
        1,
        22,
        2,
        98,
        46,
        77,
        36,
        64,
        70,
        50,
        81,
        99,
        99,
        63,
        7,
        36,
        99,
        99,
        99,
        2,
        53,
        0,
        0,
        59,
        60,
        58,
        1,
        36,
        1,
        2,
        89,
        11,
        57,
        99,
        16,
        14,
        93,
        90,
        66,
        92,
        84,
        63,
        78,
        99,
        0,
        41,
        64,
        41,
        99,
        87,
        52
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        32,
        199,
        39,
        95,
        129,
        177,
        87,
        3,
        199,
        1,
        128,
        2,
        120,
        67,
        37,
        197,
        192,
        95,
        127,
        97,
        134,
        70,
        189,
        151,
        36,
        67,
        99,
        112,
        159,
        59,
        91,
        0,
        18,
        106,
        57,
        17,
        61,
        121,
        116,
        199,
        71,
        9,
        199,
        133,
        22,
        95,
        0,
        78,
        188,
        13,
        104,
        12
      ],
      "weeklyWasted": [
        57,
        17,
        61,
        121,
        116,
        199,
        71,
        9,
        199,
        133,
        22,
        95,
        0,
        78,
        188,
        13,
        104,
        12,
        73,
        56,
        42,
        57,
        31,
        76,
        31,
        53,
        73,
        68,
        63,
        77,
        65,
        58,
        9,
        98,
        0,
        99,
        46,
        46,
        55,
        67,
        43,
        0,
        17,
        89,
        72,
        16,
        33,
        52,
        2,
        97,
        14,
        45,
        99,
        63,
        79,
        71,
        39,
        87,
        53,
        0,
        82,
        67,
        85,
        7,
        97,
        98,
        36,
        71,
        15,
        38
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        199,
        98,
        84,
        195,
        132,
        197,
        194,
        3,
        71,
        155,
        99,
        20,
        198,
        0,
        175,
        131,
        172,
        101,
        93,
        78,
        197,
        182,
        16,
        1,
        3,
        116,
        3,
        1,
        117,
        198,
        18,
        0,
        96,
        199,
        18,
        151,
        102,
        39,
        199,
        73,
        0,
        70,
        81,
        154,
        1,
        4,
        44,
        196,
        159,
        143,
        2,
        63
      ],
      "weeklyWasted": [
        18,
        151,
        102,
        39,
        199,
        73,
        0,
        70,
        81,
        154,
        1,
        4,
        44,
        196,
        159,
        143,
        2,
        63,
        31,
        38,
        60,
        44,
        98,
        37,
        76,
        12,
        99,
        37,
        63,
        31,
        15,
        32,
        91,
        53,
        49,
        99,
        31,
        0,
        88,
        40,
        0,
        1,
        18,
        99,
        73,
        97,
        81,
        25,
        99,
        1,
        42,
        98,
        74,
        35,
        73,
        56,
        99,
        1,
        22,
        24,
        90,
        41,
        23,
        6,
        41,
        78,
        4,
        1,
        53,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        97,
        17,
        102,
        5,
        127,
        149,
        75,
        30,
        50,
        1,
        138,
        138,
        107,
        0,
        22,
        32,
        179,
        87,
        14,
        2,
        55,
        43,
        174,
        2,
        3,
        80,
        90,
        50,
        89,
        147,
        120,
        196,
        63,
        199,
        151,
        111,
        106,
        131,
        159,
        125,
        5,
        33,
        176,
        45,
        34,
        156,
        0,
        52,
        198,
        140,
        69,
        122
      ],
      "weeklyWasted": [
        151,
        111,
        106,
        131,
        159,
        125,
        5,
        33,
        176,
        45,
        34,
        156,
        0,
        52,
        198,
        140,
        69,
        122,
        4,
        84,
        9,
        2,
        88,
        98,
        49,
        34,
        99,
        0,
        47,
        26,
        95,
        58,
        12,
        44,
        86,
        59,
        99,
        14,
        33,
        36,
        62,
        16,
        86,
        26,
        66,
        1,
        52,
        5,
        16,
        94,
        1,
        98,
        1,
        0,
        21,
        75,
        53,
        1,
        80,
        9,
        99,
        19,
        78,
        99,
        72,
        15,
        88,
        40,
        24,
        92
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        41,
        59,
        85,
        95,
        14,
        38,
        194,
        187,
        2,
        1,
        112,
        197,
        191,
        70,
        199,
        68,
        64,
        49,
        88,
        2,
        145,
        50,
        197,
        140,
        119,
        100,
        197,
        29,
        173,
        145,
        85,
        95,
        29,
        151,
        154,
        0,
        120,
        99,
        91,
        0,
        37,
        75,
        0,
        0,
        1,
        196,
        0,
        73,
        198,
        163,
        174,
        3
      ],
      "weeklyWasted": [
        154,
        0,
        120,
        99,
        91,
        0,
        37,
        75,
        0,
        0,
        1,
        196,
        0,
        73,
        198,
        163,
        174,
        3,
        1,
        55,
        16,
        18,
        94,
        18,
        99,
        33,
        70,
        40,
        51,
        89,
        99,
        74,
        34,
        37,
        62,
        56,
        4,
        99,
        2,
        76,
        27,
        13,
        0,
        80,
        98,
        70,
        54,
        9,
        67,
        84,
        42,
        56,
        1,
        0,
        25,
        72,
        56,
        78,
        21,
        42,
        93,
        99,
        99,
        70,
        10,
        54,
        38,
        99,
        20,
        27
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        196,
        101,
        15,
        119,
        25,
        84,
        180,
        43,
        51,
        1,
        30,
        2,
        186,
        7,
        199,
        55,
        113,
        199,
        78,
        95,
        197,
        34,
        41,
        158,
        24,
        25,
        189,
        1,
        190,
        60,
        10,
        78,
        18,
        183,
        198,
        43,
        73,
        5,
        199,
        64,
        82,
        130,
        0,
        107,
        158,
        196,
        39,
        43,
        166,
        199,
        89,
        92
      ],
      "weeklyWasted": [
        198,
        43,
        73,
        5,
        199,
        64,
        82,
        130,
        0,
        107,
        158,
        196,
        39,
        43,
        166,
        199,
        89,
        92,
        75,
        5,
        58,
        2,
        89,
        20,
        56,
        61,
        99,
        90,
        99,
        50,
        99,
        66,
        27,
        62,
        18,
        40,
        7,
        27,
        2,
        31,
        72,
        10,
        80,
        99,
        45,
        46,
        99,
        37,
        99,
        62,
        66,
        1,
        50,
        41,
        69,
        5,
        39,
        39,
        0,
        41,
        51,
        99,
        52,
        13,
        97,
        98,
        15,
        33,
        14,
        44
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        78,
        26,
        74,
        195,
        16,
        131,
        194,
        26,
        199,
        1,
        59,
        99,
        2,
        60,
        1,
        100,
        181,
        199,
        115,
        117,
        197,
        137,
        48,
        162,
        124,
        83,
        120,
        1,
        162,
        6,
        156,
        0,
        1,
        152,
        41,
        107,
        69,
        22,
        54,
        0,
        199,
        7,
        161,
        40,
        160,
        125,
        21,
        168,
        198,
        89,
        2,
        15
      ],
      "weeklyWasted": [
        41,
        107,
        69,
        22,
        54,
        0,
        199,
        7,
        161,
        40,
        160,
        125,
        21,
        168,
        198,
        89,
        2,
        15,
        99,
        69,
        47,
        40,
        50,
        98,
        68,
        0,
        81,
        62,
        66,
        77,
        51,
        96,
        70,
        35,
        98,
        63,
        66,
        88,
        99,
        0,
        26,
        6,
        0,
        99,
        62,
        12,
        87,
        1,
        2,
        97,
        38,
        98,
        80,
        69,
        40,
        78,
        56,
        38,
        93,
        0,
        99,
        76,
        87,
        38,
        69,
        98,
        39,
        50,
        0,
        45
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        199,
        2,
        36,
        195,
        130,
        162,
        114,
        14,
        76,
        199,
        44,
        2,
        187,
        6,
        98,
        102,
        192,
        197,
        66,
        2,
        164,
        99,
        2,
        80,
        151,
        112,
        115,
        23,
        191,
        137,
        184,
        119,
        1,
        78,
        89,
        82,
        174,
        29,
        137,
        64,
        169,
        52,
        129,
        7,
        68,
        120,
        37,
        111,
        198,
        187,
        2,
        92
      ],
      "weeklyWasted": [
        89,
        82,
        174,
        29,
        137,
        64,
        169,
        52,
        129,
        7,
        68,
        120,
        37,
        111,
        198,
        187,
        2,
        92,
        99,
        72,
        99,
        44,
        93,
        29,
        96,
        0,
        60,
        81,
        0,
        45,
        95,
        73,
        37,
        51,
        72,
        74,
        16,
        13,
        35,
        59,
        90,
        0,
        41,
        72,
        98,
        55,
        80,
        6,
        27,
        83,
        8,
        62,
        92,
        39,
        63,
        58,
        77,
        99,
        30,
        0,
        90,
        31,
        53,
        72,
        17,
        97,
        99,
        69,
        8,
        16
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        148,
        107,
        31,
        5,
        149,
        145,
        137,
        27,
        147,
        90,
        0,
        75,
        128,
        68,
        195,
        198,
        192,
        129,
        128,
        2,
        137,
        4,
        2,
        23,
        99,
        97,
        188,
        1,
        75,
        198,
        199,
        79,
        1,
        112,
        198,
        158,
        96,
        173,
        172,
        130,
        54,
        66,
        120,
        0,
        63,
        177,
        90,
        20,
        118,
        199,
        2,
        63
      ],
      "weeklyWasted": [
        198,
        158,
        96,
        173,
        172,
        130,
        54,
        66,
        120,
        0,
        63,
        177,
        90,
        20,
        118,
        199,
        2,
        63,
        65,
        37,
        7,
        2,
        29,
        93,
        21,
        0,
        0,
        0,
        95,
        40,
        54,
        84,
        44,
        48,
        1,
        24,
        24,
        78,
        85,
        9,
        82,
        4,
        64,
        59,
        76,
        56,
        53,
        18,
        54,
        97,
        62,
        68,
        99,
        8,
        99,
        99,
        49,
        39,
        32,
        26,
        84,
        63,
        7,
        14,
        19,
        75,
        40,
        99,
        49,
        1
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        12,
        65,
        166,
        158,
        178,
        130,
        55,
        59,
        85,
        78,
        58,
        101,
        136,
        0,
        97,
        138,
        192,
        84,
        41,
        83,
        47,
        107,
        82,
        64,
        90,
        148,
        169,
        124,
        108,
        124,
        155,
        0,
        35,
        38,
        23,
        199,
        132,
        176,
        86,
        87,
        0,
        172,
        129,
        150,
        199,
        110,
        0,
        198,
        198,
        96,
        15,
        37
      ],
      "weeklyWasted": [
        23,
        199,
        132,
        176,
        86,
        87,
        0,
        172,
        129,
        150,
        199,
        110,
        0,
        198,
        198,
        96,
        15,
        37,
        54,
        43,
        0,
        23,
        98,
        41,
        31,
        99,
        70,
        0,
        22,
        27,
        68,
        70,
        46,
        39,
        0,
        56,
        10,
        99,
        6,
        26,
        42,
        58,
        38,
        99,
        98,
        9,
        2,
        63,
        25,
        65,
        1,
        98,
        84,
        69,
        58,
        88,
        76,
        99,
        7,
        10,
        71,
        99,
        99,
        15,
        38,
        98,
        62,
        99,
        99,
        27
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        78,
        94,
        191,
        5,
        17,
        136,
        97,
        50,
        69,
        99,
        91,
        52,
        190,
        136,
        178,
        3,
        192,
        106,
        171,
        2,
        93,
        37,
        131,
        195,
        43,
        196,
        197,
        35,
        199,
        198,
        18,
        0,
        148,
        199,
        8,
        3,
        198,
        199,
        99,
        49,
        0,
        7,
        41,
        0,
        1,
        162,
        190,
        60,
        198,
        199,
        7,
        164
      ],
      "weeklyWasted": [
        8,
        3,
        198,
        199,
        99,
        49,
        0,
        7,
        41,
        0,
        1,
        162,
        190,
        60,
        198,
        199,
        7,
        164,
        26,
        10,
        4,
        2,
        84,
        1,
        99,
        0,
        98,
        47,
        55,
        39,
        64,
        57,
        20,
        98,
        74,
        27,
        69,
        0,
        2,
        34,
        97,
        0,
        64,
        67,
        60,
        33,
        51,
        50,
        2,
        80,
        25,
        98,
        26,
        39,
        20,
        63,
        8,
        99,
        58,
        50,
        89,
        86,
        53,
        60,
        43,
        98,
        78,
        99,
        83,
        28
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        199,
        2,
        162,
        145,
        9,
        197,
        3,
        113,
        2,
        71,
        199,
        124,
        89,
        32,
        66,
        117,
        192,
        159,
        167,
        66,
        177,
        124,
        40,
        120,
        3,
        198,
        63,
        165,
        90,
        3,
        101,
        44,
        4,
        4,
        198,
        59,
        46,
        113,
        87,
        0,
        0,
        77,
        21,
        123,
        178,
        4,
        57,
        84,
        198,
        170,
        137,
        25
      ],
      "weeklyWasted": [
        198,
        59,
        46,
        113,
        87,
        0,
        0,
        77,
        21,
        123,
        178,
        4,
        57,
        84,
        198,
        170,
        137,
        25,
        20,
        1,
        17,
        5,
        68,
        1,
        0,
        24,
        94,
        55,
        58,
        80,
        41,
        86,
        50,
        1,
        0,
        88,
        9,
        49,
        27,
        16,
        43,
        0,
        97,
        99,
        70,
        62,
        14,
        96,
        99,
        53,
        33,
        98,
        99,
        50,
        99,
        19,
        92,
        53,
        15,
        82,
        78,
        99,
        61,
        70,
        74,
        98,
        51,
        25,
        0,
        14
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        169,
        115,
        27,
        195,
        77,
        143,
        164,
        3,
        107,
        1,
        182,
        28,
        198,
        0,
        140,
        101,
        137,
        1,
        3,
        197,
        100,
        198,
        75,
        78,
        57,
        91,
        164,
        96,
        143,
        43,
        48,
        81,
        1,
        96,
        152,
        20,
        90,
        5,
        115,
        131,
        199,
        111,
        67,
        0,
        1,
        11,
        26,
        32,
        98,
        106,
        135,
        105
      ],
      "weeklyWasted": [
        152,
        20,
        90,
        5,
        115,
        131,
        199,
        111,
        67,
        0,
        1,
        11,
        26,
        32,
        98,
        106,
        135,
        105,
        86,
        27,
        11,
        40,
        77,
        82,
        45,
        60,
        73,
        99,
        90,
        59,
        79,
        61,
        44,
        78,
        11,
        61,
        4,
        0,
        63,
        98,
        0,
        30,
        0,
        45,
        98,
        1,
        87,
        1,
        72,
        61,
        30,
        88,
        99,
        82,
        99,
        56,
        23,
        99,
        23,
        35,
        72,
        72,
        42,
        22,
        9,
        98,
        98,
        32,
        7,
        17
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        170,
        139,
        158,
        60,
        0,
        76,
        32,
        62,
        52,
        1,
        158,
        197,
        113,
        64,
        148,
        3,
        192,
        132,
        97,
        67,
        141,
        64,
        120,
        1,
        120,
        133,
        182,
        43,
        79,
        108,
        199,
        116,
        117,
        4,
        45,
        76,
        150,
        69,
        10,
        95,
        0,
        36,
        62,
        0,
        107,
        177,
        73,
        56,
        198,
        186,
        197,
        136
      ],
      "weeklyWasted": [
        45,
        76,
        150,
        69,
        10,
        95,
        0,
        36,
        62,
        0,
        107,
        177,
        73,
        56,
        198,
        186,
        197,
        136,
        99,
        9,
        0,
        44,
        70,
        78,
        46,
        0,
        94,
        85,
        99,
        17,
        1,
        73,
        76,
        20,
        98,
        2,
        1,
        35,
        64,
        0,
        12,
        0,
        0,
        23,
        30,
        83,
        81,
        96,
        4,
        97,
        46,
        63,
        38,
        51,
        77,
        99,
        82,
        63,
        45,
        13,
        30,
        52,
        70,
        56,
        74,
        98,
        99,
        43,
        17,
        65
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        43,
        104,
        25,
        68,
        42,
        197,
        194,
        30,
        105,
        1,
        56,
        26,
        56,
        84,
        83,
        121,
        192,
        71,
        175,
        111,
        91,
        70,
        131,
        123,
        50,
        171,
        197,
        42,
        148,
        198,
        90,
        70,
        119,
        4,
        113,
        102,
        66,
        89,
        30,
        168,
        75,
        64,
        0,
        0,
        110,
        196,
        14,
        142,
        198,
        32,
        60,
        46
      ],
      "weeklyWasted": [
        113,
        102,
        66,
        89,
        30,
        168,
        75,
        64,
        0,
        0,
        110,
        196,
        14,
        142,
        198,
        32,
        60,
        46,
        76,
        50,
        52,
        20,
        53,
        54,
        67,
        0,
        75,
        54,
        75,
        74,
        55,
        99,
        78,
        19,
        44,
        86,
        1,
        8,
        54,
        40,
        49,
        22,
        16,
        35,
        76,
        77,
        32,
        3,
        62,
        27,
        40,
        45,
        69,
        0,
        26,
        77,
        0,
        99,
        0,
        5,
        13,
        99,
        14,
        54,
        52,
        13,
        31,
        99,
        99,
        1
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        157,
        2,
        171,
        194,
        146,
        178,
        35,
        57,
        80,
        1,
        118,
        158,
        198,
        58,
        164,
        150,
        83,
        125,
        97,
        2,
        100,
        41,
        75,
        73,
        60,
        198,
        144,
        48,
        199,
        128,
        158,
        121,
        63,
        4,
        14,
        18,
        198,
        111,
        145,
        0,
        0,
        23,
        0,
        31,
        1,
        152,
        0,
        0,
        165,
        199,
        119,
        95
      ],
      "weeklyWasted": [
        14,
        18,
        198,
        111,
        145,
        0,
        0,
        23,
        0,
        31,
        1,
        152,
        0,
        0,
        165,
        199,
        119,
        95,
        6,
        64,
        8,
        17,
        73,
        39,
        48,
        17,
        79,
        44,
        84,
        86,
        99,
        85,
        9,
        2,
        78,
        21,
        2,
        61,
        92,
        18,
        21,
        36,
        46,
        99,
        98,
        66,
        2,
        7,
        12,
        72,
        39,
        63,
        99,
        36,
        99,
        71,
        0,
        99,
        0,
        44,
        84,
        78,
        60,
        61,
        91,
        98,
        99,
        57,
        37,
        58
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        91,
        57,
        139,
        29,
        143,
        26,
        190,
        135,
        153,
        1,
        0,
        37,
        2,
        184,
        57,
        113,
        0,
        141,
        196,
        197,
        197,
        92,
        2,
        32,
        3,
        1,
        136,
        91,
        170,
        80,
        124,
        8,
        19,
        40,
        190,
        112,
        63,
        5,
        176,
        199,
        30,
        90,
        0,
        6,
        134,
        134,
        0,
        55,
        198,
        66,
        196,
        187
      ],
      "weeklyWasted": [
        190,
        112,
        63,
        5,
        176,
        199,
        30,
        90,
        0,
        6,
        134,
        134,
        0,
        55,
        198,
        66,
        196,
        187,
        35,
        48,
        0,
        22,
        98,
        98,
        99,
        38,
        93,
        70,
        99,
        92,
        99,
        59,
        0,
        41,
        44,
        68,
        24,
        36,
        67,
        66,
        33,
        8,
        0,
        99,
        52,
        54,
        39,
        96,
        5,
        97,
        28,
        58,
        97,
        0,
        59,
        89,
        96,
        64,
        72,
        14,
        67,
        59,
        99,
        0,
        99,
        98,
        99,
        99,
        48,
        29
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        65,
        145,
        99,
        59,
        118,
        1,
        66,
        178,
        18,
        173,
        59,
        2,
        103,
        16,
        145,
        17,
        111,
        199,
        197,
        111,
        129,
        72,
        123,
        195,
        128,
        61,
        160,
        36,
        199,
        109,
        101,
        0,
        84,
        164,
        90,
        0,
        170,
        5,
        100,
        59,
        51,
        6,
        199,
        59,
        10,
        126,
        11,
        82,
        198,
        192,
        64,
        30
      ],
      "weeklyWasted": [
        90,
        0,
        170,
        5,
        100,
        59,
        51,
        6,
        199,
        59,
        10,
        126,
        11,
        82,
        198,
        192,
        64,
        30,
        56,
        34,
        90,
        31,
        66,
        59,
        39,
        0,
        74,
        87,
        41,
        99,
        43,
        6,
        40,
        3,
        68,
        99,
        16,
        0,
        2,
        0,
        58,
        10,
        99,
        99,
        89,
        90,
        15,
        43,
        2,
        37,
        40,
        98,
        99,
        53,
        99,
        73,
        70,
        89,
        44,
        0,
        94,
        25,
        68,
        42,
        99,
        98,
        99,
        14,
        0,
        31
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        168,
        107,
        116,
        168,
        0,
        186,
        3,
        72,
        154,
        51,
        0,
        77,
        9,
        51,
        93,
        159,
        126,
        184,
        21,
        156,
        113,
        57,
        119,
        1,
        136,
        11,
        156,
        1,
        53,
        14,
        25,
        1,
        167,
        31,
        181,
        117,
        73,
        29,
        87,
        36,
        54,
        0,
        21,
        0,
        95,
        79,
        86,
        75,
        198,
        199,
        51,
        129
      ],
      "weeklyWasted": [
        181,
        117,
        73,
        29,
        87,
        36,
        54,
        0,
        21,
        0,
        95,
        79,
        86,
        75,
        198,
        199,
        51,
        129,
        33,
        57,
        25,
        17,
        98,
        80,
        99,
        39,
        39,
        66,
        39,
        1,
        4,
        99,
        78,
        1,
        45,
        47,
        74,
        47,
        2,
        0,
        0,
        56,
        0,
        32,
        16,
        55,
        64,
        32,
        31,
        63,
        54,
        97,
        80,
        56,
        99,
        33,
        99,
        12,
        26,
        64,
        0,
        86,
        99,
        59,
        79,
        98,
        6,
        91,
        99,
        78
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyUsage": [
        25,
        120,
        59,
        29,
        63,
        119,
        123,
        82,
        62,
        1,
        199,
        134,
        85,
        172,
        133,
        91,
        192,
        1,
        53,
        122,
        89,
        198,
        141,
        73,
        104,
        14,
        124,
        71,
        191,
        90,
        71,
        69,
        73,
        129,
        100,
        123,
        0,
        45,
        144,
        199,
        88,
        28,
        64,
        0,
        121,
        4,
        7,
        9,
        131,
        157,
        197,
        109
      ],
      "weeklyWasted": [
        100,
        123,
        0,
        45,
        144,
        199,
        88,
        28,
        64,
        0,
        121,
        4,
        7,
        9,
        131,
        157,
        197,
        109,
        1,
        30,
        42,
        40,
        43,
        85,
        19,
        0,
        70,
        60,
        82,
        96,
        66,
        53,
        20,
        80,
        37,
        99,
        11,
        47,
        2,
        0,
        7,
        17,
        35,
        5,
        53,
        47,
        2,
        86,
        23,
        97,
        1,
        68,
        86,
        0,
        99,
        99,
        22,
        64,
        44,
        0,
        89,
        88,
        73,
        40,
        24,
        66,
        55,
        87,
        54,
        17
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        150,
        180,
        198,
        166,
        198,
        197,
        3,
        167,
        54,
        12,
        0,
        125,
        162,
        199,
        129,
        73,
        110,
        5,
        197,
        128,
        140,
        4,
        197,
        68,
        3,
        88,
        29,
        146,
        33,
        155,
        199,
        0,
        80,
        24,
        198,
        151,
        198,
        138,
        84,
        9,
        0,
        77,
        0,
        150,
        107,
        89,
        20,
        81,
        151,
        91,
        154,
        30
      ],
      "weeklyWasted": [
        198,
        151,
        198,
        138,
        84,
        9,
        0,
        77,
        0,
        150,
        107,
        89,
        20,
        81,
        151,
        91,
        154,
        30,
        88,
        1,
        48,
        16,
        66,
        45,
        0,
        0,
        99,
        85,
        82,
        30,
        47,
        99,
        97,
        42,
        4,
        26,
        44,
        47,
        57,
        9,
        25,
        0,
        24,
        5,
        98,
        68,
        2,
        16,
        52,
        58,
        99,
        46,
        11,
        10,
        74,
        81,
        96,
        67,
        65,
        0,
        75,
        99,
        99,
        58,
        50,
        29,
        94,
        75,
        55,
        1
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        199,
        30,
        144,
        33,
        0,
        110,
        102,
        65,
        90,
        1,
        29,
        54,
        87,
        0,
        134,
        198,
        192,
        53,
        37,
        5,
        30,
        4,
        167,
        1,
        173,
        117,
        177,
        1,
        191,
        99,
        133,
        37,
        65,
        4,
        26,
        170,
        133,
        197,
        98,
        0,
        51,
        121,
        0,
        19,
        93,
        187,
        195,
        159,
        198,
        44,
        107,
        162
      ],
      "weeklyWasted": [
        26,
        170,
        133,
        197,
        98,
        0,
        51,
        121,
        0,
        19,
        93,
        187,
        195,
        159,
        198,
        44,
        107,
        162,
        32,
        1,
        84,
        2,
        67,
        33,
        55,
        0,
        93,
        38,
        44,
        66,
        17,
        70,
        91,
        39,
        72,
        70,
        28,
        75,
        95,
        2,
        64,
        0,
        43,
        45,
        98,
        97,
        32,
        20,
        2,
        97,
        1,
        93,
        24,
        9,
        58,
        37,
        98,
        99,
        22,
        0,
        86,
        76,
        99,
        33,
        99,
        81,
        99,
        36,
        56,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        18,
        2,
        66,
        5,
        90,
        16,
        92,
        3,
        199,
        53,
        53,
        117,
        51,
        0,
        156,
        178,
        192,
        178,
        3,
        94,
        3,
        26,
        58,
        167,
        133,
        1,
        129,
        24,
        0,
        196,
        53,
        196,
        139,
        4,
        58,
        171,
        198,
        50,
        64,
        0,
        74,
        92,
        142,
        0,
        1,
        110,
        0,
        50,
        171,
        174,
        185,
        71
      ],
      "weeklyWasted": [
        58,
        171,
        198,
        50,
        64,
        0,
        74,
        92,
        142,
        0,
        1,
        110,
        0,
        50,
        171,
        174,
        185,
        71,
        19,
        24,
        96,
        95,
        70,
        28,
        99,
        96,
        17,
        28,
        98,
        80,
        73,
        27,
        58,
        57,
        34,
        52,
        41,
        44,
        2,
        16,
        62,
        19,
        19,
        99,
        98,
        50,
        80,
        68,
        49,
        97,
        23,
        98,
        99,
        50,
        57,
        36,
        99,
        1,
        24,
        4,
        75,
        1,
        96,
        99,
        32,
        61,
        99,
        3,
        60,
        20
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        171,
        2,
        109,
        21,
        33,
        41,
        144,
        137,
        2,
        44,
        159,
        150,
        198,
        149,
        29,
        198,
        192,
        160,
        3,
        15,
        107,
        112,
        197,
        195,
        93,
        153,
        124,
        7,
        56,
        85,
        122,
        105,
        103,
        97,
        143,
        199,
        105,
        5,
        182,
        0,
        0,
        32,
        108,
        101,
        1,
        104,
        0,
        87,
        158,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        143,
        199,
        105,
        5,
        182,
        0,
        0,
        32,
        108,
        101,
        1,
        104,
        0,
        87,
        158,
        199,
        2,
        3,
        45,
        1,
        96,
        81,
        57,
        98,
        35,
        0,
        91,
        55,
        75,
        63,
        14,
        58,
        83,
        56,
        83,
        26,
        46,
        38,
        51,
        25,
        27,
        0,
        41,
        99,
        76,
        52,
        50,
        39,
        52,
        97,
        1,
        81,
        87,
        48,
        73,
        0,
        85,
        63,
        16,
        9,
        84,
        48,
        59,
        69,
        66,
        71,
        68,
        10,
        31,
        1
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        130,
        77,
        94,
        107,
        69,
        187,
        194,
        24,
        2,
        31,
        199,
        81,
        198,
        33,
        199,
        160,
        102,
        143,
        112,
        14,
        137,
        92,
        2,
        91,
        104,
        35,
        96,
        141,
        169,
        0,
        54,
        0,
        197,
        100,
        74,
        0,
        85,
        106,
        76,
        0,
        103,
        16,
        69,
        139,
        1,
        186,
        195,
        101,
        198,
        141,
        50,
        95
      ],
      "weeklyWasted": [
        74,
        0,
        85,
        106,
        76,
        0,
        103,
        16,
        69,
        139,
        1,
        186,
        195,
        101,
        198,
        141,
        50,
        95,
        80,
        1,
        62,
        2,
        98,
        42,
        99,
        0,
        84,
        72,
        99,
        51,
        47,
        35,
        18,
        1,
        0,
        73,
        16,
        27,
        55,
        55,
        31,
        69,
        84,
        99,
        10,
        1,
        2,
        11,
        64,
        27,
        79,
        70,
        60,
        57,
        99,
        5,
        99,
        99,
        91,
        10,
        79,
        99,
        22,
        0,
        99,
        98,
        13,
        99,
        8,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        133,
        64,
        109,
        170,
        116,
        197,
        194,
        23,
        95,
        33,
        11,
        66,
        127,
        0,
        188,
        3,
        123,
        120,
        136,
        197,
        39,
        111,
        33,
        195,
        80,
        184,
        159,
        1,
        189,
        198,
        195,
        0,
        59,
        148,
        4,
        199,
        107,
        67,
        10,
        56,
        194,
        199,
        75,
        164,
        1,
        150,
        0,
        30,
        144,
        187,
        61,
        68
      ],
      "weeklyWasted": [
        4,
        199,
        107,
        67,
        10,
        56,
        194,
        199,
        75,
        164,
        1,
        150,
        0,
        30,
        144,
        187,
        61,
        68,
        1,
        15,
        99,
        38,
        98,
        1,
        70,
        0,
        57,
        67,
        96,
        11,
        88,
        55,
        33,
        29,
        0,
        28,
        43,
        15,
        68,
        0,
        57,
        39,
        36,
        33,
        78,
        59,
        99,
        33,
        2,
        97,
        1,
        98,
        46,
        77,
        34,
        86,
        99,
        42,
        86,
        0,
        67,
        32,
        66,
        87,
        99,
        91,
        99,
        76,
        34,
        35
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        172,
        99,
        140,
        135,
        107,
        144,
        18,
        104,
        2,
        1,
        46,
        119,
        198,
        51,
        148,
        124,
        192,
        123,
        159,
        183,
        110,
        12,
        2,
        81,
        3,
        147,
        170,
        49,
        27,
        108,
        132,
        60,
        1,
        55,
        31,
        199,
        94,
        94,
        10,
        2,
        71,
        188,
        51,
        140,
        26,
        110,
        90,
        180,
        116,
        199,
        61,
        3
      ],
      "weeklyWasted": [
        31,
        199,
        94,
        94,
        10,
        2,
        71,
        188,
        51,
        140,
        26,
        110,
        90,
        180,
        116,
        199,
        61,
        3,
        1,
        26,
        0,
        2,
        79,
        1,
        13,
        0,
        99,
        49,
        84,
        93,
        64,
        71,
        60,
        1,
        0,
        99,
        19,
        31,
        32,
        0,
        0,
        48,
        64,
        70,
        57,
        77,
        62,
        1,
        50,
        97,
        32,
        83,
        42,
        22,
        99,
        99,
        0,
        95,
        53,
        13,
        99,
        78,
        99,
        12,
        99,
        98,
        96,
        72,
        99,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        67,
        92,
        0,
        43,
        139,
        157,
        194,
        147,
        2,
        84,
        124,
        67,
        198,
        34,
        106,
        3,
        172,
        184,
        110,
        92,
        55,
        86,
        34,
        175,
        3,
        34,
        126,
        53,
        80,
        0,
        163,
        128,
        80,
        62,
        8,
        0,
        148,
        5,
        10,
        190,
        192,
        69,
        170,
        115,
        33,
        149,
        0,
        48,
        198,
        159,
        101,
        176
      ],
      "weeklyWasted": [
        8,
        0,
        148,
        5,
        10,
        190,
        192,
        69,
        170,
        115,
        33,
        149,
        0,
        48,
        198,
        159,
        101,
        176,
        17,
        45,
        7,
        51,
        98,
        45,
        99,
        2,
        49,
        26,
        83,
        87,
        75,
        89,
        10,
        1,
        67,
        81,
        79,
        6,
        19,
        18,
        64,
        87,
        55,
        99,
        98,
        7,
        99,
        11,
        54,
        87,
        52,
        98,
        77,
        99,
        43,
        67,
        99,
        1,
        96,
        65,
        99,
        99,
        99,
        4,
        91,
        98,
        99,
        63,
        28,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        103,
        2,
        61,
        5,
        0,
        1,
        18,
        100,
        2,
        1,
        70,
        2,
        191,
        0,
        171,
        125,
        174,
        74,
        189,
        2,
        59,
        145,
        167,
        93,
        3,
        1,
        197,
        114,
        128,
        101,
        0,
        122,
        1,
        121,
        88,
        110,
        78,
        62,
        115,
        0,
        33,
        152,
        0,
        36,
        1,
        177,
        60,
        133,
        13,
        150,
        2,
        119
      ],
      "weeklyWasted": [
        88,
        110,
        78,
        62,
        115,
        0,
        33,
        152,
        0,
        36,
        1,
        177,
        60,
        133,
        13,
        150,
        2,
        119,
        2,
        1,
        0,
        2,
        98,
        34,
        79,
        0,
        32,
        49,
        43,
        1,
        45,
        56,
        43,
        98,
        87,
        49,
        11,
        3,
        2,
        0,
        1,
        74,
        33,
        99,
        43,
        97,
        72,
        1,
        77,
        43,
        68,
        98,
        55,
        97,
        70,
        28,
        50,
        90,
        37,
        88,
        2,
        93,
        76,
        28,
        93,
        75,
        38,
        53,
        38,
        99
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        163,
        2,
        172,
        46,
        198,
        167,
        194,
        114,
        2,
        1,
        77,
        50,
        198,
        0,
        96,
        96,
        192,
        37,
        24,
        126,
        197,
        132,
        78,
        1,
        3,
        115,
        141,
        33,
        69,
        82,
        19,
        120,
        197,
        4,
        153,
        145,
        178,
        138,
        91,
        199,
        172,
        173,
        0,
        77,
        1,
        96,
        167,
        59,
        161,
        124,
        24,
        3
      ],
      "weeklyWasted": [
        153,
        145,
        178,
        138,
        91,
        199,
        172,
        173,
        0,
        77,
        1,
        96,
        167,
        59,
        161,
        124,
        24,
        3,
        1,
        5,
        48,
        10,
        85,
        44,
        44,
        0,
        99,
        99,
        36,
        1,
        49,
        79,
        46,
        24,
        0,
        72,
        1,
        57,
        53,
        0,
        43,
        0,
        99,
        27,
        98,
        8,
        99,
        41,
        25,
        24,
        32,
        74,
        99,
        6,
        65,
        50,
        39,
        99,
        35,
        0,
        92,
        99,
        70,
        0,
        87,
        91,
        48,
        89,
        99,
        1
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        162,
        2,
        198,
        19,
        0,
        191,
        115,
        88,
        93,
        10,
        84,
        150,
        96,
        124,
        156,
        85,
        192,
        195,
        3,
        160,
        152,
        4,
        20,
        47,
        14,
        102,
        21,
        17,
        127,
        140,
        117,
        160,
        194,
        84,
        39,
        23,
        96,
        142,
        65,
        27,
        0,
        199,
        0,
        0,
        1,
        136,
        95,
        65,
        198,
        181,
        147,
        42
      ],
      "weeklyWasted": [
        39,
        23,
        96,
        142,
        65,
        27,
        0,
        199,
        0,
        0,
        1,
        136,
        95,
        65,
        198,
        181,
        147,
        42,
        35,
        53,
        55,
        57,
        55,
        21,
        45,
        0,
        99,
        0,
        20,
        2,
        42,
        99,
        71,
        12,
        83,
        18,
        1,
        15,
        78,
        0,
        97,
        6,
        83,
        90,
        53,
        97,
        26,
        96,
        97,
        30,
        38,
        98,
        78,
        0,
        99,
        90,
        21,
        1,
        57,
        0,
        99,
        99,
        99,
        0,
        99,
        98,
        99,
        51,
        75,
        11
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        199,
        121,
        0,
        38,
        0,
        1,
        3,
        77,
        81,
        26,
        40,
        41,
        122,
        49,
        147,
        198,
        94,
        101,
        50,
        126,
        105,
        78,
        2,
        132,
        5,
        122,
        66,
        160,
        199,
        87,
        106,
        0,
        122,
        103,
        95,
        35,
        0,
        5,
        152,
        167,
        49,
        88,
        55,
        60,
        1,
        129,
        0,
        42,
        198,
        12,
        98,
        18
      ],
      "weeklyWasted": [
        95,
        35,
        0,
        5,
        152,
        167,
        49,
        88,
        55,
        60,
        1,
        129,
        0,
        42,
        198,
        12,
        98,
        18,
        73,
        23,
        46,
        2,
        52,
        76,
        33,
        0,
        49,
        0,
        90,
        83,
        99,
        92,
        0,
        87,
        48,
        64,
        1,
        0,
        40,
        0,
        8,
        6,
        51,
        99,
        11,
        49,
        80,
        52,
        2,
        73,
        22,
        98,
        49,
        6,
        99,
        0,
        99,
        72,
        37,
        1,
        99,
        99,
        3,
        28,
        30,
        98,
        0,
        28,
        99,
        50
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        139,
        67,
        197,
        5,
        95,
        90,
        121,
        3,
        71,
        80,
        125,
        49,
        3,
        69,
        54,
        104,
        67,
        168,
        139,
        52,
        8,
        51,
        2,
        169,
        120,
        71,
        140,
        96,
        56,
        51,
        20,
        60,
        8,
        82,
        72,
        0,
        191,
        199,
        117,
        45,
        199,
        180,
        61,
        38,
        16,
        167,
        0,
        66,
        175,
        37,
        2,
        80
      ],
      "weeklyWasted": [
        72,
        0,
        191,
        199,
        117,
        45,
        199,
        180,
        61,
        38,
        16,
        167,
        0,
        66,
        175,
        37,
        2,
        80,
        1,
        8,
        37,
        29,
        91,
        53,
        84,
        0,
        99,
        26,
        90,
        24,
        99,
        89,
        32,
        98,
        68,
        83,
        84,
        59,
        51,
        29,
        29,
        17,
        22,
        49,
        69,
        96,
        17,
        60,
        32,
        93,
        33,
        57,
        99,
        11,
        56,
        56,
        0,
        30,
        0,
        45,
        54,
        40,
        99,
        60,
        64,
        67,
        0,
        44,
        99,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        169,
        2,
        198,
        38,
        17,
        81,
        180,
        38,
        159,
        1,
        0,
        31,
        47,
        41,
        128,
        198,
        192,
        163,
        3,
        2,
        168,
        111,
        10,
        195,
        74,
        64,
        171,
        33,
        199,
        198,
        130,
        141,
        121,
        199,
        158,
        199,
        103,
        102,
        170,
        0,
        0,
        155,
        134,
        116,
        122,
        162,
        0,
        144,
        191,
        143,
        104,
        3
      ],
      "weeklyWasted": [
        158,
        199,
        103,
        102,
        170,
        0,
        0,
        155,
        134,
        116,
        122,
        162,
        0,
        144,
        191,
        143,
        104,
        3,
        99,
        1,
        44,
        28,
        0,
        98,
        79,
        23,
        76,
        69,
        15,
        82,
        36,
        7,
        66,
        84,
        0,
        82,
        33,
        50,
        77,
        0,
        36,
        10,
        99,
        99,
        98,
        97,
        40,
        69,
        2,
        65,
        25,
        98,
        87,
        38,
        99,
        56,
        99,
        99,
        46,
        0,
        94,
        54,
        99,
        84,
        49,
        98,
        89,
        99,
        34,
        24
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        109,
        20,
        100,
        195,
        88,
        1,
        58,
        116,
        2,
        1,
        0,
        2,
        161,
        113,
        96,
        132,
        0,
        180,
        17,
        115,
        87,
        147,
        108,
        134,
        59,
        115,
        140,
        65,
        181,
        142,
        199,
        27,
        1,
        66,
        4,
        199,
        143,
        5,
        163,
        199,
        0,
        113,
        73,
        31,
        1,
        4,
        22,
        0,
        198,
        146,
        79,
        3
      ],
      "weeklyWasted": [
        4,
        199,
        143,
        5,
        163,
        199,
        0,
        113,
        73,
        31,
        1,
        4,
        22,
        0,
        198,
        146,
        79,
        3,
        85,
        1,
        96,
        2,
        83,
        88,
        0,
        16,
        10,
        32,
        36,
        99,
        74,
        81,
        20,
        21,
        12,
        63,
        6,
        19,
        9,
        9,
        78,
        98,
        0,
        70,
        98,
        28,
        76,
        30,
        22,
        97,
        60,
        74,
        62,
        20,
        28,
        74,
        53,
        59,
        0,
        48,
        5,
        70,
        43,
        71,
        71,
        65,
        99,
        12,
        99,
        1
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        199,
        137,
        157,
        5,
        48,
        43,
        16,
        51,
        127,
        102,
        199,
        46,
        165,
        171,
        160,
        3,
        192,
        86,
        42,
        2,
        197,
        4,
        2,
        102,
        98,
        12,
        129,
        72,
        0,
        116,
        109,
        145,
        45,
        88,
        4,
        0,
        198,
        199,
        137,
        187,
        0,
        199,
        0,
        139,
        1,
        140,
        103,
        198,
        198,
        148,
        55,
        196
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        199,
        137,
        187,
        0,
        199,
        0,
        139,
        1,
        140,
        103,
        198,
        198,
        148,
        55,
        196,
        33,
        34,
        65,
        2,
        44,
        35,
        58,
        0,
        99,
        37,
        51,
        67,
        73,
        90,
        46,
        61,
        2,
        23,
        1,
        38,
        36,
        36,
        97,
        42,
        44,
        99,
        48,
        97,
        23,
        19,
        74,
        91,
        87,
        98,
        41,
        38,
        80,
        83,
        0,
        58,
        9,
        16,
        96,
        99,
        66,
        99,
        99,
        57,
        96,
        80,
        90,
        1
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        129,
        148,
        75,
        113,
        0,
        102,
        98,
        34,
        134,
        1,
        109,
        169,
        177,
        99,
        181,
        7,
        138,
        81,
        3,
        85,
        197,
        17,
        85,
        58,
        179,
        21,
        75,
        2,
        0,
        198,
        91,
        0,
        78,
        72,
        175,
        80,
        160,
        72,
        100,
        108,
        0,
        78,
        131,
        49,
        115,
        146,
        33,
        114,
        187,
        161,
        67,
        3
      ],
      "weeklyWasted": [
        175,
        80,
        160,
        72,
        100,
        108,
        0,
        78,
        131,
        49,
        115,
        146,
        33,
        114,
        187,
        161,
        67,
        3,
        72,
        33,
        17,
        8,
        98,
        35,
        46,
        48,
        85,
        95,
        52,
        47,
        9,
        99,
        71,
        1,
        18,
        45,
        1,
        69,
        22,
        3,
        1,
        0,
        21,
        51,
        75,
        34,
        43,
        1,
        99,
        25,
        99,
        68,
        65,
        7,
        99,
        4,
        96,
        99,
        8,
        77,
        84,
        79,
        99,
        0,
        36,
        98,
        45,
        66,
        93,
        18
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        21,
        28,
        160,
        99,
        0,
        1,
        55,
        111,
        148,
        1,
        199,
        2,
        185,
        97,
        1,
        93,
        129,
        54,
        30,
        131,
        141,
        4,
        131,
        120,
        23,
        198,
        93,
        1,
        182,
        34,
        112,
        0,
        129,
        173,
        150,
        0,
        152,
        5,
        188,
        24,
        163,
        116,
        0,
        48,
        1,
        168,
        104,
        51,
        198,
        37,
        13,
        141
      ],
      "weeklyWasted": [
        150,
        0,
        152,
        5,
        188,
        24,
        163,
        116,
        0,
        48,
        1,
        168,
        104,
        51,
        198,
        37,
        13,
        141,
        66,
        1,
        58,
        2,
        98,
        26,
        11,
        12,
        99,
        48,
        99,
        14,
        39,
        87,
        72,
        98,
        26,
        83,
        30,
        52,
        39,
        73,
        0,
        31,
        18,
        99,
        98,
        92,
        44,
        76,
        44,
        88,
        74,
        83,
        60,
        64,
        99,
        64,
        46,
        71,
        0,
        11,
        78,
        85,
        5,
        0,
        2,
        74,
        0,
        89,
        56,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        38,
        98,
        66,
        43,
        84,
        178,
        16,
        90,
        199,
        133,
        72,
        42,
        9,
        0,
        75,
        81,
        58,
        172,
        197,
        197,
        40,
        164,
        88,
        73,
        3,
        165,
        186,
        104,
        199,
        94,
        0,
        0,
        41,
        4,
        176,
        144,
        198,
        7,
        10,
        38,
        44,
        149,
        0,
        0,
        132,
        22,
        76,
        0,
        1,
        2,
        60,
        122
      ],
      "weeklyWasted": [
        176,
        144,
        198,
        7,
        10,
        38,
        44,
        149,
        0,
        0,
        132,
        22,
        76,
        0,
        1,
        2,
        60,
        122,
        99,
        12,
        36,
        2,
        98,
        59,
        99,
        16,
        67,
        59,
        19,
        87,
        43,
        99,
        0,
        60,
        53,
        50,
        59,
        24,
        2,
        0,
        18,
        9,
        25,
        97,
        63,
        53,
        99,
        1,
        6,
        97,
        60,
        98,
        46,
        45,
        99,
        38,
        99,
        1,
        50,
        58,
        69,
        57,
        39,
        27,
        99,
        66,
        79,
        96,
        55,
        79
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyUsage": [
        54,
        2,
        166,
        66,
        65,
        1,
        97,
        22,
        114,
        46,
        199,
        2,
        158,
        185,
        199,
        98,
        102,
        192,
        58,
        111,
        197,
        142,
        2,
        195,
        167,
        105,
        43,
        1,
        164,
        183,
        80,
        0,
        1,
        60,
        9,
        54,
        156,
        76,
        197,
        48,
        20,
        169,
        6,
        167,
        34,
        7,
        70,
        51,
        178,
        199,
        144,
        118
      ],
      "weeklyWasted": [
        9,
        54,
        156,
        76,
        197,
        48,
        20,
        169,
        6,
        167,
        34,
        7,
        70,
        51,
        178,
        199,
        144,
        118,
        26,
        17,
        73,
        24,
        84,
        1,
        39,
        0,
        64,
        1,
        69,
        76,
        64,
        10,
        39,
        68,
        55,
        31,
        46,
        99,
        34,
        72,
        50,
        37,
        0,
        99,
        57,
        1,
        47,
        74,
        2,
        97,
        1,
        68,
        99,
        61,
        73,
        83,
        24,
        34,
        44,
        40,
        99,
        3,
        19,
        98,
        40,
        72,
        99,
        96,
        99,
        2
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        24,
        71,
        157,
        47,
        3,
        197,
        162,
        3,
        80,
        1,
        3,
        2,
        196,
        111,
        85,
        102,
        192,
        144,
        121,
        2,
        197,
        112,
        82,
        123,
        104,
        160,
        5,
        47,
        198,
        125,
        32,
        59,
        165,
        199,
        36,
        23,
        2,
        186,
        140,
        24,
        94,
        135,
        0,
        67,
        31,
        114,
        29,
        175,
        198,
        81,
        101,
        3
      ],
      "weeklyWasted": [
        36,
        23,
        2,
        186,
        140,
        24,
        94,
        135,
        0,
        67,
        31,
        114,
        29,
        175,
        198,
        81,
        101,
        3,
        62,
        66,
        47,
        2,
        98,
        50,
        80,
        9,
        95,
        99,
        33,
        29,
        70,
        97,
        26,
        98,
        0,
        99,
        24,
        44,
        30,
        70,
        89,
        64,
        51,
        78,
        70,
        48,
        23,
        44,
        56,
        97,
        86,
        98,
        34,
        1,
        96,
        46,
        0,
        38,
        0,
        23,
        99,
        99,
        0,
        39,
        99,
        92,
        97,
        99,
        45,
        29
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        199,
        2,
        149,
        195,
        43,
        2,
        150,
        68,
        17,
        34,
        0,
        39,
        173,
        50,
        183,
        88,
        192,
        183,
        43,
        23,
        113,
        126,
        58,
        1,
        187,
        55,
        3,
        77,
        199,
        115,
        107,
        113,
        139,
        119,
        132,
        45,
        198,
        42,
        186,
        199,
        47,
        22,
        141,
        0,
        1,
        4,
        195,
        132,
        120,
        184,
        23,
        3
      ],
      "weeklyWasted": [
        132,
        45,
        198,
        42,
        186,
        199,
        47,
        22,
        141,
        0,
        1,
        4,
        195,
        132,
        120,
        184,
        23,
        3,
        88,
        4,
        99,
        29,
        98,
        80,
        96,
        0,
        66,
        33,
        99,
        75,
        59,
        21,
        0,
        87,
        44,
        80,
        37,
        0,
        98,
        83,
        44,
        0,
        28,
        93,
        43,
        74,
        63,
        22,
        2,
        84,
        34,
        66,
        99,
        0,
        83,
        42,
        91,
        1,
        99,
        0,
        99,
        17,
        28,
        64,
        99,
        98,
        91,
        26,
        40,
        42
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        125,
        83,
        43,
        177,
        0,
        197,
        130,
        64,
        18,
        1,
        199,
        96,
        176,
        48,
        199,
        167,
        91,
        169,
        3,
        64,
        72,
        102,
        97,
        29,
        162,
        194,
        147,
        62,
        157,
        79,
        93,
        64,
        101,
        88,
        70,
        90,
        198,
        85,
        184,
        37,
        166,
        71,
        41,
        67,
        1,
        99,
        66,
        37,
        138,
        2,
        109,
        3
      ],
      "weeklyWasted": [
        70,
        90,
        198,
        85,
        184,
        37,
        166,
        71,
        41,
        67,
        1,
        99,
        66,
        37,
        138,
        2,
        109,
        3,
        33,
        31,
        45,
        59,
        80,
        55,
        65,
        10,
        62,
        47,
        99,
        19,
        1,
        91,
        41,
        11,
        31,
        99,
        30,
        98,
        92,
        0,
        48,
        2,
        9,
        66,
        46,
        57,
        76,
        67,
        31,
        87,
        25,
        98,
        84,
        23,
        99,
        43,
        57,
        99,
        19,
        62,
        32,
        45,
        90,
        99,
        97,
        98,
        10,
        35,
        99,
        1
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        51,
        81,
        37,
        111,
        0,
        121,
        188,
        121,
        127,
        102,
        179,
        2,
        51,
        140,
        199,
        197,
        192,
        142,
        39,
        137,
        197,
        86,
        142,
        61,
        3,
        12,
        165,
        27,
        1,
        94,
        37,
        146,
        107,
        123,
        89,
        7,
        1,
        138,
        162,
        113,
        69,
        62,
        0,
        78,
        19,
        161,
        125,
        106,
        198,
        144,
        36,
        3
      ],
      "weeklyWasted": [
        89,
        7,
        1,
        138,
        162,
        113,
        69,
        62,
        0,
        78,
        19,
        161,
        125,
        106,
        198,
        144,
        36,
        3,
        66,
        47,
        29,
        82,
        35,
        85,
        43,
        10,
        14,
        0,
        18,
        13,
        54,
        93,
        33,
        5,
        44,
        60,
        8,
        0,
        7,
        0,
        47,
        4,
        76,
        93,
        86,
        68,
        64,
        86,
        62,
        37,
        35,
        49,
        89,
        62,
        1,
        63,
        20,
        72,
        26,
        37,
        99,
        99,
        43,
        94,
        69,
        9,
        45,
        79,
        97,
        34
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        199,
        63,
        33,
        5,
        0,
        111,
        96,
        3,
        49,
        29,
        118,
        131,
        158,
        0,
        167,
        24,
        192,
        180,
        3,
        86,
        16,
        4,
        138,
        157,
        156,
        46,
        158,
        1,
        184,
        148,
        97,
        0,
        13,
        43,
        4,
        33,
        131,
        173,
        149,
        0,
        0,
        0,
        91,
        61,
        21,
        156,
        127,
        0,
        105,
        182,
        145,
        3
      ],
      "weeklyWasted": [
        4,
        33,
        131,
        173,
        149,
        0,
        0,
        0,
        91,
        61,
        21,
        156,
        127,
        0,
        105,
        182,
        145,
        3,
        1,
        41,
        0,
        7,
        98,
        50,
        8,
        34,
        0,
        0,
        1,
        96,
        56,
        87,
        2,
        59,
        46,
        99,
        91,
        66,
        2,
        53,
        31,
        57,
        93,
        99,
        75,
        49,
        83,
        1,
        2,
        71,
        29,
        88,
        32,
        0,
        60,
        60,
        61,
        72,
        1,
        54,
        57,
        84,
        99,
        68,
        51,
        98,
        99,
        59,
        50,
        24
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        164,
        4,
        18,
        195,
        171,
        60,
        109,
        122,
        98,
        1,
        199,
        2,
        146,
        24,
        87,
        3,
        144,
        181,
        109,
        28,
        120,
        115,
        128,
        43,
        3,
        117,
        89,
        161,
        174,
        42,
        60,
        39,
        31,
        151,
        84,
        0,
        189,
        60,
        126,
        72,
        199,
        187,
        199,
        29,
        61,
        196,
        115,
        113,
        198,
        154,
        94,
        88
      ],
      "weeklyWasted": [
        84,
        0,
        189,
        60,
        126,
        72,
        199,
        187,
        199,
        29,
        61,
        196,
        115,
        113,
        198,
        154,
        94,
        88,
        1,
        21,
        22,
        96,
        96,
        78,
        37,
        76,
        99,
        1,
        99,
        6,
        1,
        3,
        22,
        47,
        98,
        71,
        2,
        99,
        80,
        87,
        16,
        21,
        0,
        73,
        82,
        49,
        65,
        81,
        31,
        54,
        44,
        98,
        45,
        74,
        99,
        0,
        99,
        99,
        2,
        31,
        48,
        99,
        50,
        27,
        55,
        98,
        5,
        4,
        79,
        9
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        154,
        6,
        83,
        97,
        198,
        184,
        194,
        41,
        199,
        55,
        61,
        62,
        2,
        159,
        127,
        84,
        192,
        41,
        128,
        52,
        21,
        109,
        146,
        192,
        3,
        135,
        135,
        42,
        199,
        198,
        118,
        83,
        117,
        149,
        90,
        0,
        103,
        130,
        89,
        9,
        55,
        71,
        0,
        94,
        49,
        57,
        81,
        198,
        151,
        42,
        2,
        3
      ],
      "weeklyWasted": [
        90,
        0,
        103,
        130,
        89,
        9,
        55,
        71,
        0,
        94,
        49,
        57,
        81,
        198,
        151,
        42,
        2,
        3,
        23,
        24,
        66,
        96,
        98,
        35,
        21,
        38,
        99,
        21,
        0,
        99,
        71,
        76,
        26,
        19,
        0,
        58,
        34,
        0,
        19,
        47,
        0,
        41,
        63,
        76,
        98,
        28,
        65,
        55,
        99,
        97,
        4,
        50,
        84,
        32,
        37,
        93,
        86,
        99,
        59,
        60,
        33,
        5,
        0,
        49,
        47,
        51,
        6,
        81,
        99,
        32
      ]
    },
    {
      "id": "D9345860",
      "weeklyUsage": [
        88,
        117,
        180,
        81,
        0,
        76,
        82,
        45,
        151,
        1,
        111,
        2,
        174,
        0,
        111,
        3,
        192,
        199,
        3,
        150,
        177,
        49,
        32,
        32,
        3,
        119,
        189,
        1,
        176,
        182,
        105,
        0,
        1,
        135,
        22,
        96,
        142,
        142,
        199,
        136,
        0,
        67,
        111,
        12,
        26,
        196,
        103,
        21,
        132,
        2,
        162,
        3
      ],
      "weeklyWasted": [
        22,
        96,
        142,
        142,
        199,
        136,
        0,
        67,
        111,
        12,
        26,
        196,
        103,
        21,
        132,
        2,
        162,
        3,
        82,
        55,
        23,
        19,
        76,
        33,
        0,
        16,
        69,
        56,
        72,
        47,
        63,
        12,
        38,
        98,
        28,
        71,
        20,
        77,
        2,
        25,
        0,
        0,
        0,
        79,
        27,
        97,
        53,
        48,
        2,
        97,
        31,
        87,
        21,
        7,
        60,
        60,
        15,
        97,
        33,
        96,
        93,
        83,
        48,
        36,
        66,
        69,
        99,
        55,
        45,
        66
      ]
    },
    {
      "id": "44804BD4",
      "weeklyUsage": [
        199,
        2,
        50,
        195,
        186,
        171,
        49,
        12,
        62,
        31,
        149,
        82,
        76,
        0,
        143,
        3,
        192,
        140,
        3,
        160,
        94,
        198,
        180,
        76,
        147,
        40,
        194,
        122,
        199,
        85,
        110,
        157,
        134,
        199,
        13,
        0,
        105,
        165,
        71,
        32,
        146,
        0,
        199,
        45,
        128,
        58,
        0,
        166,
        198,
        164,
        84,
        80
      ],
      "weeklyWasted": [
        13,
        0,
        105,
        165,
        71,
        32,
        146,
        0,
        199,
        45,
        128,
        58,
        0,
        166,
        198,
        164,
        84,
        80,
        25,
        1,
        0,
        32,
        37,
        73,
        99,
        24,
        59,
        0,
        78,
        99,
        1,
        91,
        0,
        1,
        75,
        72,
        81,
        35,
        73,
        40,
        69,
        3,
        37,
        84,
        73,
        97,
        59,
        5,
        54,
        97,
        74,
        35,
        99,
        97,
        62,
        62,
        80,
        64,
        8,
        43,
        49,
        99,
        49,
        48,
        99,
        56,
        77,
        1,
        2,
        8
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        199,
        85,
        198,
        195,
        198,
        60,
        110,
        3,
        109,
        95,
        156,
        25,
        198,
        176,
        189,
        58,
        108,
        125,
        85,
        102,
        119,
        8,
        80,
        176,
        191,
        1,
        27,
        93,
        149,
        35,
        83,
        109,
        197,
        178,
        183,
        46,
        198,
        57,
        156,
        133,
        0,
        175,
        65,
        0,
        1,
        4,
        83,
        149,
        198,
        143,
        104,
        51
      ],
      "weeklyWasted": [
        183,
        46,
        198,
        57,
        156,
        133,
        0,
        175,
        65,
        0,
        1,
        4,
        83,
        149,
        198,
        143,
        104,
        51,
        1,
        33,
        61,
        2,
        98,
        60,
        47,
        18,
        66,
        40,
        46,
        78,
        75,
        99,
        0,
        53,
        0,
        79,
        37,
        79,
        27,
        0,
        41,
        21,
        99,
        1,
        47,
        7,
        59,
        48,
        74,
        58,
        68,
        98,
        65,
        4,
        1,
        43,
        83,
        87,
        0,
        0,
        0,
        81,
        35,
        80,
        26,
        90,
        33,
        1,
        99,
        1
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        111,
        74,
        88,
        5,
        74,
        146,
        54,
        147,
        84,
        1,
        126,
        63,
        47,
        47,
        148,
        59,
        192,
        199,
        21,
        139,
        149,
        106,
        197,
        158,
        101,
        79,
        165,
        1,
        82,
        174,
        0,
        29,
        189,
        95,
        106,
        33,
        119,
        96,
        20,
        6,
        19,
        13,
        90,
        117,
        23,
        139,
        96,
        129,
        198,
        199,
        149,
        69
      ],
      "weeklyWasted": [
        106,
        33,
        119,
        96,
        20,
        6,
        19,
        13,
        90,
        117,
        23,
        139,
        96,
        129,
        198,
        199,
        149,
        69,
        49,
        1,
        77,
        69,
        88,
        98,
        99,
        0,
        65,
        23,
        99,
        91,
        22,
        99,
        40,
        49,
        56,
        81,
        98,
        0,
        31,
        46,
        0,
        0,
        2,
        99,
        58,
        34,
        99,
        77,
        23,
        36,
        47,
        30,
        99,
        4,
        61,
        27,
        30,
        70,
        99,
        18,
        34,
        29,
        39,
        0,
        66,
        49,
        32,
        78,
        92,
        18
      ]
    },
    {
      "id": "F04529EA",
      "weeklyUsage": [
        44,
        5,
        140,
        111,
        118,
        197,
        194,
        3,
        181,
        1,
        0,
        2,
        87,
        49,
        78,
        79,
        184,
        57,
        30,
        16,
        178,
        97,
        133,
        46,
        3,
        97,
        140,
        16,
        57,
        138,
        77,
        0,
        115,
        137,
        79,
        105,
        0,
        27,
        70,
        117,
        0,
        0,
        110,
        113,
        20,
        41,
        25,
        40,
        182,
        87,
        183,
        143
      ],
      "weeklyWasted": [
        79,
        105,
        0,
        27,
        70,
        117,
        0,
        0,
        110,
        113,
        20,
        41,
        25,
        40,
        182,
        87,
        183,
        143,
        48,
        8,
        47,
        14,
        42,
        28,
        65,
        0,
        69,
        35,
        93,
        32,
        64,
        86,
        32,
        40,
        0,
        28,
        36,
        0,
        71,
        30,
        0,
        8,
        58,
        70,
        98,
        97,
        64,
        7,
        2,
        97,
        92,
        98,
        76,
        0,
        46,
        54,
        99,
        41,
        57,
        0,
        54,
        66,
        74,
        43,
        99,
        59,
        17,
        99,
        44,
        1
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        37,
        2,
        121,
        85,
        36,
        38,
        168,
        81,
        133,
        125,
        128,
        169,
        101,
        103,
        29,
        45,
        192,
        110,
        181,
        2,
        132,
        86,
        169,
        104,
        161,
        109,
        88,
        1,
        81,
        144,
        148,
        0,
        88,
        4,
        198,
        171,
        99,
        139,
        163,
        199,
        53,
        0,
        162,
        22,
        31,
        166,
        163,
        0,
        148,
        179,
        2,
        3
      ],
      "weeklyWasted": [
        198,
        171,
        99,
        139,
        163,
        199,
        53,
        0,
        162,
        22,
        31,
        166,
        163,
        0,
        148,
        179,
        2,
        3,
        1,
        1,
        28,
        27,
        50,
        22,
        70,
        20,
        99,
        0,
        41,
        9,
        40,
        99,
        14,
        45,
        32,
        29,
        63,
        10,
        60,
        0,
        33,
        0,
        99,
        72,
        33,
        60,
        60,
        96,
        2,
        97,
        11,
        88,
        79,
        26,
        7,
        0,
        66,
        50,
        0,
        0,
        99,
        86,
        92,
        76,
        99,
        98,
        74,
        56,
        99,
        10
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        55,
        30,
        88,
        177,
        15,
        1,
        129,
        3,
        104,
        1,
        0,
        164,
        152,
        103,
        148,
        105,
        166,
        159,
        143,
        197,
        118,
        77,
        78,
        154,
        30,
        33,
        197,
        25,
        173,
        149,
        172,
        137,
        66,
        136,
        198,
        17,
        44,
        57,
        110,
        166,
        139,
        182,
        73,
        198,
        69,
        189,
        33,
        7,
        178,
        190,
        124,
        57
      ],
      "weeklyWasted": [
        198,
        17,
        44,
        57,
        110,
        166,
        139,
        182,
        73,
        198,
        69,
        189,
        33,
        7,
        178,
        190,
        124,
        57,
        59,
        34,
        12,
        2,
        93,
        1,
        52,
        26,
        27,
        72,
        67,
        1,
        1,
        99,
        86,
        28,
        60,
        51,
        1,
        53,
        80,
        0,
        87,
        0,
        31,
        99,
        1,
        52,
        99,
        28,
        2,
        97,
        19,
        56,
        35,
        88,
        55,
        99,
        0,
        1,
        2,
        75,
        99,
        84,
        99,
        43,
        0,
        98,
        18,
        59,
        0,
        67
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        114,
        90,
        90,
        150,
        121,
        77,
        97,
        13,
        9,
        115,
        101,
        192,
        198,
        98,
        68,
        124,
        141,
        44,
        197,
        129,
        112,
        15,
        75,
        1,
        79,
        167,
        197,
        59,
        199,
        139,
        84,
        36,
        197,
        121,
        183,
        0,
        198,
        5,
        22,
        127,
        5,
        79,
        0,
        28,
        44,
        190,
        0,
        48,
        187,
        148,
        190,
        3
      ],
      "weeklyWasted": [
        183,
        0,
        198,
        5,
        22,
        127,
        5,
        79,
        0,
        28,
        44,
        190,
        0,
        48,
        187,
        148,
        190,
        3,
        75,
        41,
        0,
        2,
        98,
        91,
        33,
        0,
        99,
        46,
        99,
        72,
        35,
        99,
        74,
        29,
        35,
        77,
        41,
        69,
        10,
        2,
        74,
        4,
        0,
        71,
        29,
        20,
        44,
        40,
        2,
        62,
        60,
        75,
        29,
        25,
        49,
        15,
        72,
        40,
        0,
        0,
        99,
        83,
        5,
        75,
        69,
        98,
        99,
        40,
        51,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        77,
        104,
        198,
        71,
        172,
        27,
        166,
        57,
        106,
        44,
        7,
        27,
        26,
        63,
        156,
        132,
        143,
        1,
        197,
        114,
        159,
        93,
        2,
        156,
        88,
        5,
        153,
        83,
        91,
        152,
        91,
        0,
        1,
        96,
        198,
        113,
        90,
        109,
        133,
        49,
        199,
        8,
        134,
        21,
        199,
        196,
        69,
        174,
        193,
        13,
        40,
        56
      ],
      "weeklyWasted": [
        198,
        113,
        90,
        109,
        133,
        49,
        199,
        8,
        134,
        21,
        199,
        196,
        69,
        174,
        193,
        13,
        40,
        56,
        71,
        26,
        51,
        37,
        79,
        2,
        81,
        0,
        73,
        60,
        51,
        72,
        40,
        64,
        0,
        1,
        98,
        99,
        9,
        37,
        99,
        0,
        87,
        0,
        31,
        81,
        98,
        1,
        53,
        3,
        6,
        85,
        1,
        93,
        30,
        38,
        70,
        39,
        99,
        64,
        32,
        0,
        92,
        68,
        99,
        77,
        80,
        92,
        99,
        5,
        61,
        14
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        136,
        87,
        8,
        5,
        53,
        118,
        28,
        45,
        47,
        199,
        199,
        57,
        198,
        199,
        163,
        3,
        76,
        33,
        105,
        2,
        127,
        4,
        13,
        44,
        197,
        153,
        183,
        1,
        81,
        152,
        74,
        80,
        1,
        97,
        148,
        174,
        185,
        108,
        199,
        0,
        0,
        73,
        113,
        52,
        28,
        4,
        87,
        53,
        198,
        174,
        93,
        155
      ],
      "weeklyWasted": [
        148,
        174,
        185,
        108,
        199,
        0,
        0,
        73,
        113,
        52,
        28,
        4,
        87,
        53,
        198,
        174,
        93,
        155,
        1,
        1,
        0,
        2,
        98,
        21,
        54,
        6,
        59,
        0,
        66,
        72,
        61,
        12,
        30,
        26,
        21,
        99,
        32,
        99,
        2,
        99,
        40,
        0,
        54,
        76,
        98,
        47,
        29,
        22,
        41,
        97,
        34,
        52,
        0,
        44,
        73,
        99,
        99,
        51,
        2,
        21,
        99,
        53,
        65,
        62,
        71,
        98,
        72,
        80,
        29,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        199,
        93,
        0,
        25,
        185,
        197,
        28,
        3,
        102,
        61,
        0,
        2,
        138,
        60,
        49,
        52,
        173,
        175,
        188,
        183,
        145,
        64,
        197,
        64,
        3,
        76,
        135,
        36,
        193,
        198,
        80,
        0,
        43,
        199,
        119,
        187,
        90,
        84,
        10,
        24,
        0,
        188,
        6,
        90,
        73,
        146,
        46,
        31,
        113,
        53,
        95,
        150
      ],
      "weeklyWasted": [
        119,
        187,
        90,
        84,
        10,
        24,
        0,
        188,
        6,
        90,
        73,
        146,
        46,
        31,
        113,
        53,
        95,
        150,
        99,
        33,
        53,
        2,
        98,
        22,
        29,
        15,
        61,
        57,
        26,
        66,
        24,
        99,
        76,
        44,
        65,
        58,
        52,
        27,
        27,
        47,
        0,
        0,
        99,
        50,
        73,
        38,
        63,
        32,
        2,
        64,
        60,
        98,
        15,
        2,
        39,
        41,
        0,
        99,
        11,
        79,
        46,
        72,
        93,
        0,
        75,
        22,
        99,
        82,
        19,
        99
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        173,
        102,
        0,
        5,
        0,
        115,
        155,
        197,
        115,
        52,
        37,
        2,
        101,
        41,
        1,
        3,
        192,
        199,
        120,
        121,
        120,
        28,
        61,
        141,
        3,
        120,
        138,
        1,
        111,
        30,
        0,
        48,
        1,
        197,
        112,
        0,
        101,
        30,
        158,
        93,
        56,
        1,
        122,
        16,
        19,
        190,
        30,
        0,
        160,
        159,
        146,
        3
      ],
      "weeklyWasted": [
        112,
        0,
        101,
        30,
        158,
        93,
        56,
        1,
        122,
        16,
        19,
        190,
        30,
        0,
        160,
        159,
        146,
        3,
        82,
        16,
        96,
        22,
        96,
        1,
        99,
        85,
        22,
        58,
        48,
        19,
        38,
        67,
        52,
        27,
        11,
        99,
        26,
        58,
        47,
        0,
        16,
        0,
        0,
        11,
        98,
        71,
        16,
        21,
        17,
        97,
        74,
        98,
        99,
        42,
        1,
        45,
        28,
        28,
        0,
        98,
        49,
        82,
        51,
        64,
        47,
        39,
        0,
        88,
        40,
        92
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        29,
        24,
        0,
        55,
        174,
        114,
        194,
        106,
        103,
        1,
        103,
        78,
        116,
        62,
        104,
        146,
        152,
        134,
        197,
        40,
        119,
        198,
        197,
        32,
        37,
        108,
        110,
        72,
        149,
        52,
        131,
        184,
        122,
        134,
        131,
        28,
        159,
        5,
        183,
        20,
        88,
        9,
        110,
        98,
        5,
        77,
        42,
        198,
        149,
        125,
        31,
        128
      ],
      "weeklyWasted": [
        131,
        28,
        159,
        5,
        183,
        20,
        88,
        9,
        110,
        98,
        5,
        77,
        42,
        198,
        149,
        125,
        31,
        128,
        16,
        47,
        0,
        8,
        98,
        84,
        47,
        5,
        99,
        59,
        41,
        60,
        31,
        99,
        61,
        38,
        0,
        54,
        80,
        31,
        2,
        56,
        12,
        44,
        53,
        99,
        0,
        22,
        5,
        23,
        24,
        57,
        68,
        98,
        47,
        33,
        53,
        54,
        16,
        99,
        99,
        0,
        99,
        99,
        47,
        40,
        99,
        98,
        99,
        99,
        99,
        1
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        199,
        2,
        157,
        68,
        81,
        156,
        129,
        110,
        103,
        43,
        108,
        2,
        165,
        0,
        165,
        27,
        122,
        199,
        3,
        157,
        61,
        4,
        104,
        96,
        53,
        79,
        187,
        1,
        175,
        198,
        199,
        62,
        197,
        199,
        36,
        199,
        178,
        120,
        199,
        48,
        0,
        105,
        104,
        48,
        1,
        135,
        137,
        19,
        198,
        119,
        82,
        3
      ],
      "weeklyWasted": [
        36,
        199,
        178,
        120,
        199,
        48,
        0,
        105,
        104,
        48,
        1,
        135,
        137,
        19,
        198,
        119,
        82,
        3,
        79,
        36,
        99,
        39,
        91,
        67,
        99,
        77,
        6,
        38,
        54,
        18,
        57,
        73,
        45,
        66,
        43,
        60,
        64,
        72,
        2,
        32,
        18,
        7,
        5,
        1,
        36,
        73,
        99,
        42,
        2,
        53,
        36,
        74,
        0,
        23,
        80,
        53,
        84,
        76,
        43,
        0,
        44,
        58,
        99,
        80,
        99,
        82,
        32,
        56,
        32,
        90
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyUsage": [
        199,
        2,
        198,
        80,
        109,
        101,
        61,
        197,
        150,
        95,
        199,
        146,
        198,
        98,
        13,
        3,
        161,
        34,
        100,
        88,
        197,
        37,
        74,
        1,
        71,
        73,
        172,
        1,
        83,
        124,
        199,
        196,
        118,
        4,
        4,
        195,
        156,
        5,
        10,
        0,
        101,
        133,
        159,
        0,
        100,
        68,
        195,
        152,
        165,
        180,
        41,
        3
      ],
      "weeklyWasted": [
        4,
        195,
        156,
        5,
        10,
        0,
        101,
        133,
        159,
        0,
        100,
        68,
        195,
        152,
        165,
        180,
        41,
        3,
        1,
        50,
        62,
        13,
        98,
        21,
        99,
        6,
        58,
        0,
        0,
        99,
        52,
        89,
        79,
        5,
        0,
        99,
        1,
        11,
        11,
        0,
        26,
        0,
        22,
        99,
        65,
        40,
        94,
        17,
        2,
        84,
        26,
        48,
        70,
        0,
        66,
        93,
        4,
        74,
        71,
        89,
        88,
        41,
        46,
        82,
        99,
        98,
        99,
        61,
        54,
        63
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        140,
        139,
        189,
        46,
        90,
        1,
        121,
        69,
        111,
        22,
        127,
        124,
        184,
        48,
        199,
        198,
        95,
        1,
        62,
        34,
        49,
        159,
        55,
        1,
        31,
        41,
        116,
        95,
        177,
        102,
        0,
        46,
        73,
        87,
        126,
        199,
        134,
        60,
        102,
        180,
        56,
        35,
        199,
        46,
        6,
        196,
        0,
        45,
        198,
        134,
        134,
        61
      ],
      "weeklyWasted": [
        126,
        199,
        134,
        60,
        102,
        180,
        56,
        35,
        199,
        46,
        6,
        196,
        0,
        45,
        198,
        134,
        134,
        61,
        72,
        50,
        60,
        46,
        98,
        98,
        99,
        0,
        34,
        47,
        99,
        99,
        76,
        35,
        0,
        98,
        32,
        81,
        11,
        83,
        45,
        23,
        0,
        0,
        16,
        67,
        98,
        1,
        87,
        50,
        9,
        69,
        32,
        80,
        56,
        73,
        57,
        89,
        58,
        92,
        84,
        37,
        99,
        45,
        65,
        56,
        12,
        77,
        22,
        99,
        99,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        121,
        93,
        198,
        104,
        0,
        97,
        124,
        10,
        178,
        1,
        199,
        173,
        123,
        82,
        54,
        87,
        181,
        105,
        14,
        197,
        1,
        126,
        155,
        26,
        126,
        45,
        178,
        1,
        150,
        138,
        199,
        0,
        118,
        70,
        59,
        13,
        70,
        5,
        138,
        50,
        199,
        0,
        50,
        92,
        1,
        172,
        68,
        143,
        189,
        140,
        2,
        61
      ],
      "weeklyWasted": [
        59,
        13,
        70,
        5,
        138,
        50,
        199,
        0,
        50,
        92,
        1,
        172,
        68,
        143,
        189,
        140,
        2,
        61,
        25,
        1,
        21,
        41,
        98,
        98,
        34,
        41,
        94,
        47,
        67,
        1,
        81,
        99,
        47,
        98,
        43,
        72,
        33,
        8,
        49,
        62,
        28,
        0,
        21,
        88,
        0,
        22,
        2,
        45,
        5,
        58,
        26,
        44,
        99,
        15,
        45,
        54,
        99,
        39,
        16,
        78,
        99,
        81,
        36,
        36,
        76,
        98,
        0,
        81,
        13,
        65
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyUsage": [
        199,
        141,
        132,
        93,
        0,
        101,
        33,
        139,
        2,
        1,
        199,
        114,
        191,
        132,
        166,
        55,
        192,
        93,
        3,
        144,
        22,
        44,
        128,
        66,
        103,
        189,
        137,
        162,
        90,
        115,
        0,
        188,
        197,
        4,
        11,
        28,
        41,
        56,
        103,
        21,
        175,
        79,
        40,
        0,
        1,
        183,
        52,
        0,
        1,
        189,
        143,
        196
      ],
      "weeklyWasted": [
        11,
        28,
        41,
        56,
        103,
        21,
        175,
        79,
        40,
        0,
        1,
        183,
        52,
        0,
        1,
        189,
        143,
        196,
        39,
        9,
        35,
        39,
        98,
        63,
        60,
        0,
        99,
        76,
        99,
        1,
        68,
        99,
        86,
        80,
        45,
        99,
        37,
        38,
        73,
        62,
        45,
        44,
        25,
        97,
        49,
        19,
        95,
        1,
        75,
        97,
        84,
        91,
        29,
        0,
        36,
        61,
        4,
        67,
        46,
        0,
        63,
        47,
        16,
        36,
        65,
        98,
        0,
        50,
        41,
        51
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        168,
        2,
        182,
        195,
        99,
        108,
        194,
        98,
        137,
        1,
        0,
        2,
        198,
        0,
        94,
        67,
        6,
        114,
        31,
        45,
        188,
        198,
        2,
        103,
        175,
        137,
        197,
        52,
        183,
        118,
        70,
        8,
        15,
        159,
        61,
        73,
        118,
        77,
        82,
        105,
        192,
        151,
        199,
        198,
        95,
        59,
        50,
        26,
        198,
        199,
        197,
        34
      ],
      "weeklyWasted": [
        61,
        73,
        118,
        77,
        82,
        105,
        192,
        151,
        199,
        198,
        95,
        59,
        50,
        26,
        198,
        199,
        197,
        34,
        59,
        2,
        99,
        2,
        98,
        15,
        0,
        15,
        50,
        97,
        99,
        99,
        76,
        66,
        0,
        76,
        68,
        72,
        58,
        17,
        27,
        0,
        0,
        32,
        38,
        13,
        98,
        1,
        90,
        17,
        2,
        97,
        1,
        94,
        99,
        34,
        99,
        25,
        63,
        75,
        90,
        0,
        99,
        99,
        52,
        0,
        55,
        86,
        39,
        67,
        31,
        1
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        145,
        26,
        111,
        42,
        92,
        1,
        194,
        92,
        2,
        1,
        170,
        16,
        128,
        30,
        159,
        7,
        11,
        137,
        79,
        180,
        144,
        6,
        36,
        1,
        3,
        191,
        105,
        78,
        154,
        198,
        138,
        68,
        1,
        33,
        138,
        83,
        72,
        5,
        33,
        30,
        199,
        72,
        0,
        92,
        87,
        196,
        26,
        40,
        163,
        199,
        24,
        3
      ],
      "weeklyWasted": [
        138,
        83,
        72,
        5,
        33,
        30,
        199,
        72,
        0,
        92,
        87,
        196,
        26,
        40,
        163,
        199,
        24,
        3,
        32,
        17,
        17,
        35,
        98,
        98,
        42,
        0,
        32,
        67,
        37,
        49,
        34,
        73,
        20,
        98,
        63,
        20,
        50,
        59,
        72,
        40,
        38,
        4,
        40,
        99,
        94,
        18,
        51,
        1,
        97,
        74,
        90,
        98,
        99,
        11,
        85,
        99,
        35,
        68,
        99,
        0,
        83,
        99,
        48,
        43,
        48,
        30,
        0,
        78,
        99,
        70
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        57,
        184,
        127,
        160,
        46,
        87,
        50,
        54,
        140,
        50,
        0,
        13,
        2,
        0,
        94,
        3,
        133,
        19,
        3,
        197,
        157,
        56,
        2,
        109,
        105,
        187,
        158,
        65,
        81,
        1,
        32,
        0,
        6,
        199,
        198,
        34,
        198,
        5,
        180,
        139,
        63,
        13,
        112,
        98,
        111,
        196,
        0,
        28,
        145,
        146,
        103,
        56
      ],
      "weeklyWasted": [
        198,
        34,
        198,
        5,
        180,
        139,
        63,
        13,
        112,
        98,
        111,
        196,
        0,
        28,
        145,
        146,
        103,
        56,
        58,
        1,
        70,
        2,
        98,
        40,
        0,
        30,
        88,
        30,
        12,
        23,
        14,
        54,
        97,
        1,
        52,
        70,
        42,
        73,
        44,
        0,
        0,
        24,
        32,
        68,
        16,
        68,
        46,
        1,
        24,
        97,
        35,
        97,
        99,
        26,
        59,
        98,
        56,
        15,
        0,
        85,
        41,
        99,
        99,
        33,
        47,
        61,
        99,
        55,
        38,
        15
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        160,
        143,
        108,
        24,
        198,
        1,
        98,
        3,
        30,
        39,
        82,
        85,
        198,
        162,
        199,
        198,
        122,
        89,
        91,
        2,
        105,
        84,
        140,
        1,
        3,
        5,
        81,
        133,
        60,
        110,
        145,
        20,
        1,
        61,
        62,
        191,
        107,
        94,
        10,
        143,
        84,
        108,
        112,
        40,
        87,
        87,
        58,
        11,
        198,
        116,
        102,
        81
      ],
      "weeklyWasted": [
        62,
        191,
        107,
        94,
        10,
        143,
        84,
        108,
        112,
        40,
        87,
        87,
        58,
        11,
        198,
        116,
        102,
        81,
        56,
        44,
        25,
        2,
        64,
        60,
        61,
        0,
        99,
        26,
        99,
        1,
        92,
        88,
        60,
        36,
        17,
        64,
        32,
        92,
        81,
        0,
        21,
        62,
        34,
        83,
        30,
        36,
        62,
        46,
        2,
        50,
        38,
        65,
        7,
        53,
        46,
        0,
        31,
        96,
        50,
        24,
        74,
        99,
        30,
        99,
        74,
        45,
        31,
        72,
        61,
        1
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        38,
        82,
        187,
        87,
        136,
        158,
        193,
        128,
        80,
        1,
        192,
        93,
        167,
        0,
        122,
        3,
        192,
        199,
        68,
        2,
        98,
        198,
        88,
        108,
        130,
        101,
        82,
        180,
        113,
        68,
        160,
        0,
        55,
        199,
        169,
        172,
        0,
        5,
        13,
        0,
        61,
        2,
        75,
        50,
        80,
        69,
        0,
        44,
        1,
        199,
        102,
        41
      ],
      "weeklyWasted": [
        169,
        172,
        0,
        5,
        13,
        0,
        61,
        2,
        75,
        50,
        80,
        69,
        0,
        44,
        1,
        199,
        102,
        41,
        10,
        51,
        38,
        23,
        36,
        70,
        99,
        0,
        99,
        88,
        86,
        17,
        33,
        23,
        46,
        96,
        21,
        72,
        23,
        29,
        46,
        76,
        97,
        99,
        60,
        60,
        58,
        22,
        58,
        7,
        12,
        68,
        36,
        91,
        38,
        13,
        30,
        72,
        99,
        11,
        90,
        1,
        99,
        62,
        52,
        40,
        99,
        49,
        48,
        57,
        99,
        1
      ]
    },
    {
      "id": "D9345860",
      "weeklyUsage": [
        43,
        33,
        0,
        13,
        96,
        1,
        188,
        151,
        199,
        11,
        76,
        67,
        174,
        67,
        71,
        141,
        154,
        63,
        78,
        35,
        143,
        198,
        19,
        195,
        96,
        136,
        197,
        71,
        127,
        198,
        100,
        83,
        3,
        199,
        133,
        199,
        144,
        78,
        25,
        21,
        0,
        3,
        0,
        46,
        1,
        196,
        0,
        132,
        192,
        22,
        93,
        98
      ],
      "weeklyWasted": [
        133,
        199,
        144,
        78,
        25,
        21,
        0,
        3,
        0,
        46,
        1,
        196,
        0,
        132,
        192,
        22,
        93,
        98,
        68,
        31,
        56,
        28,
        98,
        30,
        99,
        16,
        86,
        77,
        40,
        60,
        49,
        32,
        96,
        98,
        47,
        99,
        60,
        36,
        60,
        87,
        11,
        48,
        24,
        63,
        98,
        48,
        58,
        29,
        13,
        97,
        34,
        40,
        99,
        0,
        71,
        86,
        64,
        1,
        82,
        0,
        60,
        1,
        99,
        22,
        7,
        98,
        75,
        99,
        78,
        10
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        134,
        63,
        198,
        66,
        108,
        189,
        194,
        3,
        153,
        1,
        69,
        2,
        143,
        11,
        199,
        78,
        95,
        105,
        6,
        63,
        197,
        198,
        69,
        65,
        104,
        79,
        89,
        75,
        110,
        17,
        48,
        130,
        53,
        165,
        198,
        0,
        10,
        199,
        146,
        12,
        155,
        166,
        55,
        0,
        1,
        194,
        116,
        117,
        102,
        199,
        128,
        136
      ],
      "weeklyWasted": [
        198,
        0,
        10,
        199,
        146,
        12,
        155,
        166,
        55,
        0,
        1,
        194,
        116,
        117,
        102,
        199,
        128,
        136,
        1,
        12,
        9,
        14,
        13,
        40,
        59,
        31,
        39,
        52,
        91,
        42,
        51,
        53,
        0,
        46,
        51,
        40,
        3,
        43,
        38,
        53,
        58,
        0,
        59,
        19,
        31,
        97,
        31,
        22,
        2,
        61,
        61,
        83,
        4,
        0,
        86,
        11,
        72,
        99,
        42,
        3,
        99,
        99,
        68,
        74,
        99,
        65,
        72,
        82,
        94,
        1
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        134,
        2,
        0,
        50,
        88,
        142,
        3,
        3,
        2,
        1,
        111,
        92,
        181,
        102,
        154,
        9,
        192,
        199,
        3,
        36,
        1,
        50,
        109,
        195,
        163,
        43,
        197,
        32,
        62,
        63,
        0,
        76,
        167,
        111,
        102,
        0,
        0,
        199,
        165,
        91,
        5,
        0,
        194,
        0,
        1,
        163,
        113,
        93,
        12,
        56,
        2,
        135
      ],
      "weeklyWasted": [
        102,
        0,
        0,
        199,
        165,
        91,
        5,
        0,
        194,
        0,
        1,
        163,
        113,
        93,
        12,
        56,
        2,
        135,
        44,
        35,
        0,
        69,
        84,
        33,
        99,
        0,
        49,
        99,
        72,
        7,
        99,
        55,
        54,
        98,
        72,
        57,
        96,
        0,
        2,
        0,
        84,
        0,
        99,
        62,
        75,
        74,
        86,
        2,
        29,
        50,
        1,
        98,
        73,
        27,
        2,
        0,
        99,
        90,
        31,
        53,
        99,
        45,
        6,
        72,
        46,
        86,
        99,
        49,
        52,
        1
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        190,
        140,
        24,
        5,
        30,
        194,
        115,
        3,
        91,
        111,
        174,
        20,
        82,
        0,
        105,
        198,
        119,
        136,
        38,
        2,
        141,
        4,
        125,
        58,
        3,
        5,
        185,
        54,
        0,
        24,
        53,
        119,
        6,
        91,
        161,
        170,
        0,
        199,
        87,
        0,
        15,
        0,
        0,
        39,
        1,
        128,
        195,
        198,
        166,
        180,
        2,
        3
      ],
      "weeklyWasted": [
        161,
        170,
        0,
        199,
        87,
        0,
        15,
        0,
        0,
        39,
        1,
        128,
        195,
        198,
        166,
        180,
        2,
        3,
        1,
        72,
        0,
        48,
        9,
        75,
        87,
        66,
        0,
        60,
        29,
        76,
        99,
        41,
        0,
        38,
        62,
        83,
        1,
        90,
        42,
        70,
        0,
        32,
        50,
        99,
        35,
        1,
        44,
        1,
        2,
        1,
        30,
        88,
        22,
        42,
        82,
        34,
        99,
        1,
        96,
        43,
        99,
        73,
        99,
        39,
        14,
        98,
        34,
        68,
        21,
        9
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        7,
        2,
        90,
        29,
        98,
        136,
        121,
        148,
        76,
        23,
        83,
        81,
        198,
        0,
        199,
        111,
        139,
        166,
        118,
        89,
        111,
        77,
        8,
        1,
        3,
        176,
        196,
        18,
        199,
        0,
        101,
        158,
        68,
        135,
        120,
        106,
        116,
        52,
        38,
        0,
        53,
        180,
        199,
        134,
        1,
        196,
        158,
        154,
        35,
        162,
        17,
        189
      ],
      "weeklyWasted": [
        120,
        106,
        116,
        52,
        38,
        0,
        53,
        180,
        199,
        134,
        1,
        196,
        158,
        154,
        35,
        162,
        17,
        189,
        48,
        2,
        0,
        50,
        28,
        1,
        73,
        0,
        90,
        34,
        49,
        99,
        1,
        70,
        97,
        98,
        70,
        63,
        97,
        85,
        57,
        29,
        34,
        84,
        49,
        97,
        98,
        97,
        97,
        12,
        2,
        74,
        77,
        64,
        63,
        0,
        72,
        83,
        56,
        40,
        49,
        3,
        0,
        54,
        83,
        94,
        99,
        81,
        63,
        87,
        99,
        53
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        199,
        139,
        12,
        174,
        198,
        128,
        68,
        103,
        97,
        133,
        115,
        2,
        168,
        0,
        171,
        73,
        192,
        66,
        172,
        52,
        75,
        106,
        53,
        1,
        8,
        198,
        197,
        55,
        141,
        0,
        35,
        54,
        197,
        117,
        54,
        46,
        41,
        199,
        129,
        191,
        111,
        125,
        9,
        31,
        1,
        169,
        169,
        198,
        141,
        166,
        2,
        3
      ],
      "weeklyWasted": [
        54,
        46,
        41,
        199,
        129,
        191,
        111,
        125,
        9,
        31,
        1,
        169,
        169,
        198,
        141,
        166,
        2,
        3,
        50,
        39,
        0,
        52,
        84,
        90,
        99,
        0,
        98,
        58,
        4,
        92,
        67,
        59,
        53,
        39,
        9,
        79,
        91,
        43,
        15,
        15,
        0,
        12,
        63,
        99,
        19,
        55,
        46,
        1,
        2,
        30,
        49,
        78,
        46,
        51,
        61,
        57,
        0,
        99,
        99,
        0,
        99,
        99,
        99,
        0,
        97,
        54,
        64,
        31,
        10,
        9
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        89,
        199,
        127,
        131,
        37,
        197,
        173,
        101,
        126,
        1,
        74,
        119,
        168,
        0,
        73,
        21,
        73,
        64,
        53,
        2,
        49,
        97,
        81,
        115,
        3,
        1,
        137,
        31,
        174,
        68,
        50,
        33,
        120,
        10,
        156,
        199,
        48,
        5,
        161,
        0,
        20,
        0,
        25,
        182,
        2,
        118,
        31,
        40,
        157,
        199,
        92,
        3
      ],
      "weeklyWasted": [
        156,
        199,
        48,
        5,
        161,
        0,
        20,
        0,
        25,
        182,
        2,
        118,
        31,
        40,
        157,
        199,
        92,
        3,
        39,
        52,
        30,
        13,
        98,
        86,
        99,
        29,
        77,
        68,
        68,
        40,
        44,
        89,
        94,
        48,
        4,
        66,
        47,
        50,
        2,
        39,
        9,
        99,
        32,
        59,
        65,
        45,
        78,
        6,
        2,
        59,
        50,
        98,
        99,
        74,
        64,
        94,
        43,
        1,
        86,
        11,
        97,
        57,
        16,
        14,
        99,
        72,
        99,
        99,
        22,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        167,
        75,
        83,
        21,
        47,
        197,
        25,
        41,
        123,
        137,
        161,
        25,
        74,
        50,
        199,
        148,
        192,
        96,
        88,
        86,
        1,
        4,
        128,
        157,
        44,
        120,
        73,
        1,
        0,
        121,
        12,
        7,
        85,
        125,
        135,
        138,
        94,
        14,
        122,
        87,
        146,
        0,
        0,
        0,
        43,
        172,
        137,
        103,
        190,
        107,
        33,
        22
      ],
      "weeklyWasted": [
        135,
        138,
        94,
        14,
        122,
        87,
        146,
        0,
        0,
        0,
        43,
        172,
        137,
        103,
        190,
        107,
        33,
        22,
        51,
        1,
        54,
        2,
        98,
        46,
        29,
        34,
        63,
        99,
        62,
        69,
        1,
        99,
        64,
        7,
        29,
        83,
        1,
        4,
        65,
        43,
        7,
        14,
        18,
        63,
        73,
        97,
        96,
        12,
        6,
        44,
        83,
        88,
        0,
        47,
        65,
        92,
        0,
        76,
        0,
        22,
        77,
        81,
        51,
        70,
        99,
        98,
        27,
        57,
        50,
        42
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        199,
        122,
        55,
        35,
        188,
        192,
        9,
        133,
        14,
        1,
        184,
        60,
        198,
        133,
        156,
        150,
        192,
        199,
        119,
        55,
        105,
        144,
        97,
        117,
        143,
        198,
        170,
        31,
        78,
        124,
        0,
        129,
        86,
        4,
        137,
        138,
        33,
        189,
        114,
        87,
        79,
        1,
        107,
        0,
        1,
        121,
        185,
        135,
        198,
        145,
        77,
        55
      ],
      "weeklyWasted": [
        137,
        138,
        33,
        189,
        114,
        87,
        79,
        1,
        107,
        0,
        1,
        121,
        185,
        135,
        198,
        145,
        77,
        55,
        37,
        24,
        22,
        96,
        18,
        98,
        49,
        0,
        46,
        38,
        28,
        58,
        49,
        8,
        68,
        35,
        28,
        99,
        99,
        44,
        83,
        43,
        28,
        0,
        32,
        55,
        68,
        74,
        36,
        64,
        2,
        1,
        7,
        26,
        84,
        7,
        12,
        44,
        57,
        1,
        83,
        5,
        99,
        52,
        99,
        24,
        99,
        73,
        38,
        85,
        5,
        18
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        176,
        154,
        73,
        190,
        194,
        79,
        99,
        3,
        101,
        69,
        76,
        2,
        76,
        0,
        134,
        43,
        129,
        156,
        77,
        182,
        57,
        48,
        53,
        169,
        197,
        74,
        196,
        38,
        190,
        142,
        123,
        0,
        86,
        181,
        4,
        7,
        137,
        102,
        59,
        132,
        37,
        87,
        192,
        0,
        72,
        4,
        136,
        161,
        198,
        199,
        134,
        111
      ],
      "weeklyWasted": [
        4,
        7,
        137,
        102,
        59,
        132,
        37,
        87,
        192,
        0,
        72,
        4,
        136,
        161,
        198,
        199,
        134,
        111,
        99,
        55,
        26,
        2,
        49,
        55,
        25,
        0,
        99,
        4,
        91,
        11,
        1,
        69,
        39,
        1,
        77,
        54,
        30,
        73,
        90,
        45,
        26,
        32,
        88,
        5,
        98,
        33,
        68,
        38,
        36,
        71,
        40,
        22,
        99,
        0,
        81,
        96,
        36,
        1,
        0,
        0,
        99,
        48,
        52,
        4,
        65,
        64,
        45,
        2,
        51,
        1
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyUsage": [
        128,
        30,
        80,
        38,
        41,
        130,
        165,
        92,
        199,
        52,
        116,
        65,
        186,
        0,
        148,
        139,
        135,
        199,
        3,
        156,
        24,
        107,
        70,
        160,
        3,
        117,
        142,
        31,
        199,
        0,
        90,
        174,
        1,
        106,
        4,
        177,
        127,
        136,
        57,
        70,
        129,
        199,
        126,
        163,
        1,
        191,
        60,
        111,
        198,
        46,
        63,
        192
      ],
      "weeklyWasted": [
        4,
        177,
        127,
        136,
        57,
        70,
        129,
        199,
        126,
        163,
        1,
        191,
        60,
        111,
        198,
        46,
        63,
        192,
        32,
        19,
        30,
        2,
        79,
        98,
        71,
        2,
        55,
        89,
        62,
        78,
        66,
        91,
        43,
        27,
        38,
        99,
        13,
        3,
        82,
        0,
        7,
        99,
        98,
        78,
        18,
        42,
        69,
        10,
        2,
        53,
        24,
        98,
        99,
        46,
        80,
        99,
        77,
        99,
        99,
        0,
        99,
        49,
        39,
        40,
        95,
        49,
        40,
        55,
        51,
        35
      ]
    },
    {
      "id": 9202537,
      "weeklyUsage": [
        39,
        2,
        150,
        78,
        39,
        99,
        77,
        23,
        94,
        1,
        142,
        197,
        184,
        0,
        199,
        117,
        192,
        105,
        3,
        78,
        83,
        185,
        2,
        125,
        141,
        1,
        103,
        132,
        19,
        32,
        93,
        196,
        155,
        135,
        4,
        141,
        92,
        55,
        125,
        53,
        99,
        0,
        2,
        21,
        37,
        177,
        14,
        23,
        140,
        90,
        76,
        3
      ],
      "weeklyWasted": [
        4,
        141,
        92,
        55,
        125,
        53,
        99,
        0,
        2,
        21,
        37,
        177,
        14,
        23,
        140,
        90,
        76,
        3,
        49,
        8,
        95,
        35,
        64,
        84,
        3,
        39,
        70,
        0,
        60,
        99,
        87,
        79,
        80,
        17,
        0,
        99,
        1,
        3,
        45,
        80,
        32,
        64,
        51,
        99,
        98,
        81,
        23,
        1,
        2,
        54,
        33,
        98,
        62,
        37,
        67,
        88,
        39,
        79,
        0,
        98,
        60,
        49,
        81,
        77,
        94,
        86,
        13,
        99,
        4,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        151,
        145,
        38,
        56,
        52,
        174,
        194,
        3,
        117,
        1,
        199,
        46,
        61,
        13,
        13,
        33,
        192,
        112,
        59,
        55,
        169,
        4,
        89,
        195,
        87,
        198,
        183,
        151,
        159,
        146,
        111,
        79,
        16,
        4,
        89,
        23,
        137,
        147,
        175,
        71,
        199,
        0,
        0,
        0,
        1,
        196,
        68,
        24,
        166,
        199,
        2,
        77
      ],
      "weeklyWasted": [
        89,
        23,
        137,
        147,
        175,
        71,
        199,
        0,
        0,
        0,
        1,
        196,
        68,
        24,
        166,
        199,
        2,
        77,
        46,
        1,
        0,
        2,
        0,
        76,
        19,
        0,
        81,
        78,
        11,
        34,
        1,
        99,
        97,
        54,
        62,
        49,
        32,
        46,
        84,
        0,
        89,
        81,
        53,
        94,
        28,
        78,
        45,
        1,
        89,
        97,
        29,
        75,
        20,
        10,
        75,
        61,
        99,
        43,
        82,
        25,
        62,
        84,
        85,
        50,
        61,
        89,
        13,
        60,
        99,
        74
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyUsage": [
        42,
        2,
        61,
        5,
        198,
        1,
        194,
        3,
        90,
        27,
        136,
        41,
        171,
        58,
        146,
        198,
        71,
        60,
        120,
        122,
        197,
        148,
        2,
        81,
        3,
        198,
        197,
        1,
        178,
        90,
        161,
        0,
        1,
        4,
        151,
        199,
        142,
        40,
        150,
        0,
        31,
        143,
        0,
        191,
        4,
        171,
        0,
        16,
        136,
        36,
        107,
        54
      ],
      "weeklyWasted": [
        151,
        199,
        142,
        40,
        150,
        0,
        31,
        143,
        0,
        191,
        4,
        171,
        0,
        16,
        136,
        36,
        107,
        54,
        80,
        1,
        75,
        52,
        94,
        1,
        84,
        32,
        35,
        1,
        86,
        89,
        99,
        55,
        18,
        92,
        0,
        99,
        6,
        82,
        2,
        99,
        13,
        60,
        63,
        59,
        76,
        22,
        60,
        78,
        51,
        12,
        41,
        98,
        12,
        45,
        23,
        48,
        75,
        77,
        8,
        32,
        73,
        14,
        61,
        27,
        26,
        44,
        44,
        99,
        99,
        34
      ]
    },
    {
      "id": "FB70040E",
      "weeklyUsage": [
        177,
        60,
        198,
        51,
        177,
        73,
        44,
        46,
        199,
        82,
        105,
        44,
        126,
        64,
        2,
        124,
        192,
        111,
        197,
        121,
        78,
        127,
        109,
        132,
        131,
        167,
        197,
        1,
        68,
        9,
        60,
        0,
        138,
        32,
        179,
        8,
        163,
        23,
        80,
        106,
        125,
        172,
        117,
        0,
        69,
        196,
        64,
        198,
        198,
        76,
        47,
        3
      ],
      "weeklyWasted": [
        179,
        8,
        163,
        23,
        80,
        106,
        125,
        172,
        117,
        0,
        69,
        196,
        64,
        198,
        198,
        76,
        47,
        3,
        46,
        1,
        1,
        16,
        88,
        1,
        99,
        14,
        69,
        22,
        99,
        38,
        77,
        54,
        38,
        45,
        56,
        79,
        61,
        13,
        62,
        33,
        37,
        34,
        0,
        40,
        55,
        25,
        6,
        1,
        12,
        70,
        1,
        98,
        68,
        26,
        75,
        11,
        14,
        99,
        39,
        43,
        99,
        71,
        20,
        61,
        68,
        36,
        99,
        53,
        63,
        17
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        199,
        159,
        43,
        44,
        1,
        87,
        3,
        3,
        31,
        59,
        92,
        2,
        167,
        172,
        10,
        31,
        192,
        121,
        3,
        2,
        81,
        72,
        175,
        111,
        3,
        23,
        138,
        71,
        175,
        16,
        199,
        45,
        88,
        101,
        31,
        199,
        198,
        89,
        70,
        1,
        0,
        0,
        0,
        81,
        48,
        100,
        14,
        76,
        198,
        9,
        19,
        103
      ],
      "weeklyWasted": [
        31,
        199,
        198,
        89,
        70,
        1,
        0,
        0,
        0,
        81,
        48,
        100,
        14,
        76,
        198,
        9,
        19,
        103,
        96,
        98,
        65,
        2,
        43,
        98,
        17,
        0,
        54,
        31,
        81,
        6,
        48,
        43,
        79,
        55,
        39,
        56,
        4,
        41,
        46,
        0,
        22,
        0,
        75,
        35,
        98,
        82,
        81,
        12,
        41,
        60,
        85,
        51,
        0,
        62,
        87,
        97,
        47,
        68,
        0,
        66,
        4,
        14,
        89,
        68,
        55,
        69,
        6,
        99,
        1,
        1
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyUsage": [
        105,
        192,
        85,
        5,
        188,
        125,
        32,
        128,
        119,
        192,
        199,
        64,
        62,
        0,
        107,
        59,
        192,
        188,
        3,
        42,
        115,
        19,
        2,
        122,
        178,
        154,
        182,
        57,
        84,
        153,
        116,
        185,
        1,
        4,
        141,
        77,
        143,
        58,
        188,
        30,
        42,
        71,
        0,
        35,
        54,
        90,
        0,
        1,
        198,
        199,
        3,
        75
      ],
      "weeklyWasted": [
        141,
        77,
        143,
        58,
        188,
        30,
        42,
        71,
        0,
        35,
        54,
        90,
        0,
        1,
        198,
        199,
        3,
        75,
        37,
        12,
        27,
        39,
        69,
        85,
        59,
        80,
        87,
        0,
        72,
        84,
        73,
        68,
        0,
        52,
        80,
        51,
        57,
        75,
        67,
        84,
        51,
        0,
        90,
        54,
        98,
        23,
        2,
        6,
        3,
        14,
        1,
        98,
        0,
        29,
        47,
        99,
        28,
        1,
        27,
        62,
        77,
        68,
        99,
        0,
        64,
        89,
        5,
        83,
        58,
        1
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        137,
        2,
        166,
        5,
        32,
        70,
        71,
        3,
        164,
        36,
        199,
        2,
        74,
        0,
        60,
        27,
        181,
        102,
        36,
        81,
        197,
        121,
        129,
        122,
        151,
        98,
        8,
        113,
        117,
        198,
        41,
        166,
        140,
        199,
        149,
        0,
        198,
        18,
        58,
        92,
        133,
        0,
        30,
        0,
        1,
        196,
        0,
        0,
        85,
        72,
        74,
        3
      ],
      "weeklyWasted": [
        149,
        0,
        198,
        18,
        58,
        92,
        133,
        0,
        30,
        0,
        1,
        196,
        0,
        0,
        85,
        72,
        74,
        3,
        61,
        49,
        99,
        3,
        46,
        78,
        48,
        10,
        17,
        29,
        1,
        99,
        70,
        89,
        27,
        98,
        71,
        79,
        22,
        0,
        2,
        15,
        0,
        13,
        37,
        25,
        28,
        52,
        26,
        13,
        2,
        93,
        1,
        66,
        99,
        40,
        84,
        83,
        25,
        98,
        35,
        40,
        99,
        54,
        50,
        86,
        20,
        52,
        91,
        53,
        45,
        1
      ]
    },
    {
      "id": "4FF22342",
      "weeklyUsage": [
        199,
        87,
        103,
        5,
        136,
        143,
        135,
        38,
        127,
        111,
        140,
        187,
        87,
        100,
        199,
        102,
        192,
        125,
        37,
        2,
        7,
        91,
        106,
        1,
        37,
        1,
        183,
        13,
        88,
        74,
        67,
        0,
        95,
        57,
        4,
        0,
        152,
        32,
        14,
        64,
        30,
        21,
        109,
        172,
        53,
        80,
        22,
        77,
        198,
        142,
        13,
        81
      ],
      "weeklyWasted": [
        4,
        0,
        152,
        32,
        14,
        64,
        30,
        21,
        109,
        172,
        53,
        80,
        22,
        77,
        198,
        142,
        13,
        81,
        6,
        1,
        5,
        2,
        98,
        14,
        25,
        10,
        91,
        25,
        3,
        35,
        49,
        99,
        53,
        79,
        18,
        48,
        35,
        30,
        56,
        50,
        54,
        21,
        99,
        99,
        77,
        1,
        26,
        21,
        49,
        70,
        97,
        79,
        68,
        74,
        49,
        66,
        77,
        67,
        99,
        28,
        82,
        49,
        59,
        22,
        99,
        98,
        99,
        66,
        4,
        1
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        199,
        2,
        87,
        5,
        0,
        87,
        16,
        127,
        149,
        1,
        199,
        2,
        18,
        0,
        8,
        65,
        122,
        88,
        197,
        79,
        1,
        58,
        141,
        178,
        148,
        134,
        167,
        67,
        162,
        0,
        189,
        80,
        1,
        95,
        147,
        0,
        133,
        199,
        10,
        15,
        120,
        90,
        50,
        0,
        1,
        196,
        102,
        168,
        98,
        114,
        145,
        12
      ],
      "weeklyWasted": [
        147,
        0,
        133,
        199,
        10,
        15,
        120,
        90,
        50,
        0,
        1,
        196,
        102,
        168,
        98,
        114,
        145,
        12,
        45,
        53,
        24,
        7,
        84,
        1,
        99,
        2,
        70,
        94,
        65,
        70,
        71,
        77,
        97,
        45,
        31,
        99,
        26,
        78,
        40,
        1,
        62,
        0,
        33,
        68,
        76,
        22,
        64,
        53,
        2,
        82,
        36,
        76,
        96,
        1,
        40,
        0,
        9,
        63,
        0,
        64,
        83,
        46,
        48,
        0,
        48,
        98,
        69,
        69,
        99,
        40
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        199,
        139,
        192,
        97,
        45,
        57,
        184,
        77,
        45,
        1,
        199,
        143,
        160,
        50,
        88,
        123,
        103,
        199,
        81,
        2,
        82,
        93,
        157,
        195,
        24,
        198,
        179,
        49,
        92,
        198,
        199,
        0,
        96,
        199,
        108,
        30,
        115,
        150,
        12,
        81,
        130,
        172,
        103,
        93,
        1,
        88,
        153,
        152,
        198,
        2,
        108,
        51
      ],
      "weeklyWasted": [
        108,
        30,
        115,
        150,
        12,
        81,
        130,
        172,
        103,
        93,
        1,
        88,
        153,
        152,
        198,
        2,
        108,
        51,
        82,
        1,
        75,
        37,
        84,
        59,
        72,
        21,
        61,
        85,
        0,
        98,
        43,
        40,
        97,
        1,
        35,
        99,
        47,
        67,
        2,
        69,
        22,
        30,
        43,
        79,
        68,
        9,
        99,
        1,
        2,
        17,
        32,
        79,
        74,
        31,
        99,
        99,
        20,
        89,
        0,
        0,
        53,
        65,
        80,
        21,
        70,
        65,
        0,
        44,
        72,
        50
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        111,
        2,
        151,
        9,
        0,
        98,
        3,
        104,
        66,
        1,
        133,
        197,
        188,
        46,
        129,
        3,
        192,
        65,
        188,
        17,
        174,
        82,
        63,
        15,
        57,
        11,
        143,
        14,
        199,
        141,
        153,
        0,
        98,
        4,
        66,
        199,
        182,
        5,
        85,
        17,
        178,
        49,
        122,
        33,
        34,
        81,
        0,
        125,
        198,
        199,
        77,
        196
      ],
      "weeklyWasted": [
        66,
        199,
        182,
        5,
        85,
        17,
        178,
        49,
        122,
        33,
        34,
        81,
        0,
        125,
        198,
        199,
        77,
        196,
        74,
        53,
        84,
        2,
        98,
        88,
        99,
        29,
        93,
        2,
        99,
        4,
        12,
        99,
        73,
        10,
        51,
        1,
        13,
        46,
        2,
        0,
        26,
        0,
        61,
        26,
        70,
        97,
        58,
        58,
        2,
        96,
        1,
        98,
        69,
        43,
        86,
        42,
        25,
        99,
        94,
        6,
        99,
        99,
        61,
        11,
        82,
        98,
        64,
        29,
        52,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        184,
        2,
        152,
        63,
        162,
        197,
        110,
        3,
        199,
        1,
        199,
        60,
        110,
        0,
        62,
        176,
        52,
        165,
        3,
        91,
        18,
        160,
        114,
        153,
        78,
        185,
        46,
        1,
        0,
        81,
        118,
        117,
        197,
        18,
        121,
        71,
        198,
        179,
        151,
        198,
        155,
        199,
        29,
        26,
        15,
        94,
        94,
        111,
        75,
        126,
        63,
        3
      ],
      "weeklyWasted": [
        121,
        71,
        198,
        179,
        151,
        198,
        155,
        199,
        29,
        26,
        15,
        94,
        94,
        111,
        75,
        126,
        63,
        3,
        99,
        5,
        35,
        14,
        89,
        98,
        55,
        3,
        88,
        0,
        73,
        54,
        24,
        50,
        0,
        47,
        42,
        99,
        57,
        34,
        24,
        0,
        23,
        10,
        9,
        88,
        53,
        26,
        76,
        40,
        2,
        89,
        38,
        59,
        50,
        0,
        71,
        82,
        0,
        67,
        34,
        0,
        65,
        60,
        28,
        24,
        99,
        93,
        32,
        52,
        99,
        23
      ]
    },
    {
      "id": "11102F47",
      "weeklyUsage": [
        199,
        2,
        198,
        97,
        0,
        197,
        194,
        117,
        2,
        15,
        0,
        148,
        145,
        59,
        199,
        3,
        144,
        145,
        154,
        159,
        48,
        5,
        121,
        26,
        3,
        198,
        58,
        53,
        145,
        198,
        116,
        50,
        1,
        35,
        113,
        199,
        70,
        38,
        107,
        67,
        120,
        146,
        114,
        198,
        17,
        196,
        23,
        106,
        160,
        167,
        13,
        3
      ],
      "weeklyWasted": [
        113,
        199,
        70,
        38,
        107,
        67,
        120,
        146,
        114,
        198,
        17,
        196,
        23,
        106,
        160,
        167,
        13,
        3,
        1,
        1,
        0,
        23,
        29,
        98,
        31,
        16,
        75,
        32,
        48,
        13,
        34,
        98,
        61,
        26,
        59,
        36,
        6,
        81,
        75,
        0,
        59,
        0,
        99,
        99,
        98,
        72,
        99,
        20,
        2,
        96,
        34,
        98,
        99,
        56,
        78,
        74,
        97,
        67,
        29,
        0,
        99,
        99,
        70,
        99,
        99,
        98,
        61,
        66,
        97,
        57
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        199,
        53,
        39,
        20,
        51,
        197,
        96,
        197,
        2,
        25,
        199,
        50,
        95,
        60,
        163,
        109,
        177,
        134,
        35,
        104,
        164,
        19,
        197,
        129,
        21,
        198,
        44,
        33,
        199,
        10,
        197,
        64,
        1,
        8,
        4,
        84,
        198,
        116,
        152,
        10,
        43,
        199,
        73,
        60,
        16,
        11,
        33,
        86,
        184,
        139,
        49,
        105
      ],
      "weeklyWasted": [
        4,
        84,
        198,
        116,
        152,
        10,
        43,
        199,
        73,
        60,
        16,
        11,
        33,
        86,
        184,
        139,
        49,
        105,
        11,
        98,
        99,
        36,
        98,
        6,
        55,
        46,
        39,
        25,
        76,
        43,
        36,
        45,
        96,
        68,
        0,
        75,
        29,
        65,
        99,
        65,
        0,
        0,
        0,
        1,
        98,
        72,
        33,
        31,
        57,
        4,
        32,
        85,
        1,
        85,
        56,
        67,
        80,
        59,
        50,
        0,
        88,
        59,
        67,
        68,
        77,
        43,
        37,
        31,
        46,
        1
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        56,
        2,
        57,
        70,
        145,
        1,
        96,
        52,
        131,
        2,
        43,
        60,
        198,
        101,
        188,
        176,
        127,
        132,
        32,
        50,
        197,
        107,
        2,
        167,
        13,
        104,
        52,
        1,
        154,
        117,
        138,
        37,
        175,
        199,
        4,
        110,
        198,
        5,
        139,
        61,
        0,
        105,
        56,
        68,
        49,
        25,
        0,
        138,
        184,
        199,
        197,
        132
      ],
      "weeklyWasted": [
        4,
        110,
        198,
        5,
        139,
        61,
        0,
        105,
        56,
        68,
        49,
        25,
        0,
        138,
        184,
        199,
        197,
        132,
        99,
        6,
        0,
        2,
        91,
        98,
        99,
        0,
        25,
        91,
        94,
        85,
        24,
        63,
        0,
        4,
        26,
        99,
        4,
        0,
        72,
        41,
        50,
        62,
        53,
        99,
        53,
        41,
        2,
        77,
        2,
        51,
        1,
        98,
        98,
        52,
        21,
        0,
        94,
        58,
        41,
        0,
        99,
        9,
        0,
        46,
        99,
        98,
        99,
        99,
        55,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        37,
        128,
        0,
        65,
        6,
        1,
        154,
        55,
        42,
        28,
        154,
        47,
        198,
        124,
        199,
        138,
        147,
        117,
        96,
        47,
        42,
        72,
        117,
        195,
        197,
        105,
        149,
        21,
        79,
        136,
        54,
        16,
        1,
        175,
        4,
        199,
        69,
        6,
        62,
        75,
        104,
        28,
        199,
        27,
        11,
        135,
        28,
        0,
        184,
        151,
        175,
        3
      ],
      "weeklyWasted": [
        4,
        199,
        69,
        6,
        62,
        75,
        104,
        28,
        199,
        27,
        11,
        135,
        28,
        0,
        184,
        151,
        175,
        3,
        20,
        2,
        3,
        5,
        54,
        56,
        99,
        0,
        99,
        99,
        20,
        1,
        60,
        87,
        11,
        1,
        58,
        46,
        1,
        42,
        2,
        0,
        0,
        45,
        39,
        99,
        28,
        97,
        26,
        18,
        47,
        97,
        15,
        98,
        27,
        0,
        1,
        59,
        99,
        1,
        52,
        0,
        99,
        67,
        99,
        72,
        58,
        98,
        95,
        35,
        55,
        25
      ]
    },
    {
      "id": "F55885BD",
      "weeklyUsage": [
        99,
        2,
        3,
        76,
        0,
        123,
        35,
        118,
        137,
        56,
        158,
        165,
        198,
        69,
        161,
        150,
        182,
        199,
        50,
        91,
        101,
        42,
        18,
        59,
        98,
        1,
        118,
        1,
        39,
        73,
        31,
        0,
        132,
        109,
        64,
        193,
        198,
        199,
        85,
        112,
        43,
        126,
        150,
        0,
        160,
        98,
        182,
        0,
        82,
        28,
        13,
        72
      ],
      "weeklyWasted": [
        64,
        193,
        198,
        199,
        85,
        112,
        43,
        126,
        150,
        0,
        160,
        98,
        182,
        0,
        82,
        28,
        13,
        72,
        99,
        71,
        12,
        2,
        43,
        1,
        60,
        60,
        94,
        36,
        37,
        99,
        14,
        75,
        0,
        1,
        98,
        22,
        29,
        65,
        62,
        0,
        3,
        3,
        16,
        99,
        48,
        71,
        18,
        16,
        2,
        35,
        5,
        98,
        12,
        4,
        79,
        99,
        36,
        79,
        14,
        0,
        99,
        99,
        99,
        39,
        82,
        35,
        61,
        4,
        61,
        47
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        164,
        74,
        198,
        6,
        73,
        170,
        3,
        197,
        2,
        57,
        128,
        100,
        100,
        104,
        157,
        3,
        192,
        199,
        126,
        6,
        197,
        108,
        197,
        195,
        50,
        197,
        158,
        26,
        0,
        44,
        0,
        5,
        94,
        165,
        148,
        79,
        93,
        165,
        85,
        41,
        125,
        0,
        0,
        35,
        45,
        81,
        68,
        180,
        198,
        199,
        81,
        33
      ],
      "weeklyWasted": [
        148,
        79,
        93,
        165,
        85,
        41,
        125,
        0,
        0,
        35,
        45,
        81,
        68,
        180,
        198,
        199,
        81,
        33,
        65,
        3,
        19,
        85,
        87,
        9,
        99,
        27,
        50,
        1,
        85,
        1,
        26,
        45,
        71,
        57,
        3,
        25,
        48,
        23,
        2,
        0,
        42,
        0,
        68,
        52,
        6,
        53,
        12,
        96,
        79,
        97,
        28,
        98,
        68,
        99,
        26,
        0,
        46,
        76,
        20,
        20,
        99,
        93,
        99,
        7,
        99,
        58,
        49,
        39,
        51,
        65
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        54,
        2,
        0,
        5,
        0,
        96,
        194,
        179,
        56,
        31,
        199,
        81,
        10,
        14,
        192,
        131,
        119,
        89,
        148,
        164,
        166,
        82,
        180,
        132,
        110,
        78,
        87,
        37,
        137,
        89,
        0,
        83,
        43,
        69,
        173,
        0,
        36,
        5,
        13,
        199,
        199,
        0,
        15,
        164,
        53,
        165,
        19,
        144,
        166,
        175,
        175,
        99
      ],
      "weeklyWasted": [
        173,
        0,
        36,
        5,
        13,
        199,
        199,
        0,
        15,
        164,
        53,
        165,
        19,
        144,
        166,
        175,
        175,
        99,
        89,
        30,
        78,
        33,
        98,
        98,
        26,
        18,
        67,
        94,
        99,
        85,
        77,
        97,
        53,
        31,
        48,
        65,
        60,
        66,
        36,
        99,
        0,
        42,
        0,
        99,
        26,
        59,
        52,
        65,
        2,
        53,
        99,
        71,
        25,
        99,
        86,
        99,
        43,
        1,
        28,
        16,
        94,
        85,
        61,
        35,
        61,
        94,
        0,
        99,
        83,
        24
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        120,
        129,
        176,
        59,
        0,
        137,
        91,
        90,
        165,
        1,
        199,
        123,
        198,
        0,
        147,
        75,
        149,
        191,
        3,
        36,
        84,
        4,
        197,
        110,
        3,
        1,
        124,
        97,
        199,
        57,
        119,
        2,
        197,
        165,
        8,
        0,
        153,
        115,
        55,
        42,
        0,
        71,
        0,
        58,
        1,
        109,
        177,
        127,
        183,
        2,
        106,
        130
      ],
      "weeklyWasted": [
        8,
        0,
        153,
        115,
        55,
        42,
        0,
        71,
        0,
        58,
        1,
        109,
        177,
        127,
        183,
        2,
        106,
        130,
        1,
        20,
        0,
        13,
        90,
        89,
        99,
        71,
        16,
        79,
        31,
        90,
        14,
        40,
        82,
        42,
        20,
        75,
        34,
        64,
        67,
        69,
        97,
        24,
        28,
        65,
        82,
        39,
        71,
        15,
        43,
        81,
        97,
        84,
        0,
        72,
        99,
        0,
        68,
        55,
        20,
        17,
        74,
        99,
        71,
        81,
        89,
        98,
        45,
        98,
        25,
        1
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        129,
        68,
        162,
        195,
        42,
        103,
        185,
        3,
        199,
        1,
        83,
        56,
        139,
        38,
        199,
        14,
        192,
        127,
        157,
        30,
        28,
        69,
        102,
        195,
        190,
        198,
        75,
        116,
        113,
        128,
        2,
        158,
        97,
        146,
        32,
        199,
        126,
        35,
        64,
        158,
        107,
        32,
        0,
        63,
        1,
        145,
        163,
        29,
        117,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        32,
        199,
        126,
        35,
        64,
        158,
        107,
        32,
        0,
        63,
        1,
        145,
        163,
        29,
        117,
        199,
        2,
        3,
        60,
        1,
        47,
        11,
        92,
        85,
        86,
        47,
        51,
        0,
        0,
        99,
        29,
        99,
        96,
        98,
        20,
        99,
        6,
        99,
        66,
        90,
        89,
        24,
        0,
        61,
        76,
        53,
        2,
        1,
        2,
        94,
        80,
        98,
        83,
        49,
        5,
        0,
        14,
        99,
        86,
        43,
        82,
        38,
        40,
        39,
        67,
        94,
        47,
        79,
        56,
        38
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        199,
        2,
        85,
        83,
        123,
        64,
        38,
        105,
        108,
        1,
        45,
        2,
        86,
        0,
        199,
        3,
        192,
        189,
        197,
        4,
        197,
        4,
        36,
        111,
        38,
        1,
        56,
        122,
        199,
        116,
        0,
        196,
        1,
        55,
        161,
        152,
        131,
        117,
        176,
        59,
        110,
        18,
        0,
        143,
        1,
        106,
        30,
        66,
        198,
        4,
        93,
        4
      ],
      "weeklyWasted": [
        161,
        152,
        131,
        117,
        176,
        59,
        110,
        18,
        0,
        143,
        1,
        106,
        30,
        66,
        198,
        4,
        93,
        4,
        13,
        31,
        0,
        2,
        91,
        50,
        82,
        0,
        78,
        92,
        82,
        72,
        60,
        90,
        70,
        6,
        32,
        99,
        23,
        39,
        13,
        69,
        56,
        52,
        46,
        58,
        52,
        77,
        49,
        1,
        36,
        97,
        48,
        98,
        88,
        11,
        36,
        51,
        88,
        52,
        33,
        9,
        86,
        51,
        67,
        43,
        57,
        43,
        46,
        99,
        5,
        26
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        68,
        64,
        24,
        5,
        77,
        1,
        4,
        93,
        104,
        1,
        73,
        66,
        178,
        199,
        199,
        198,
        101,
        167,
        197,
        96,
        171,
        37,
        149,
        1,
        3,
        178,
        135,
        45,
        88,
        43,
        123,
        0,
        16,
        46,
        149,
        176,
        100,
        144,
        168,
        96,
        37,
        142,
        50,
        0,
        13,
        196,
        138,
        198,
        152,
        199,
        2,
        103
      ],
      "weeklyWasted": [
        149,
        176,
        100,
        144,
        168,
        96,
        37,
        142,
        50,
        0,
        13,
        196,
        138,
        198,
        152,
        199,
        2,
        103,
        99,
        1,
        68,
        2,
        98,
        1,
        93,
        32,
        21,
        0,
        12,
        42,
        1,
        3,
        41,
        16,
        72,
        24,
        70,
        99,
        66,
        78,
        56,
        1,
        14,
        78,
        66,
        81,
        29,
        70,
        12,
        30,
        75,
        70,
        25,
        69,
        15,
        54,
        0,
        55,
        9,
        0,
        33,
        39,
        88,
        35,
        99,
        98,
        52,
        6,
        99,
        47
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        7,
        86,
        72,
        5,
        14,
        167,
        105,
        102,
        175,
        137,
        141,
        142,
        180,
        0,
        42,
        145,
        63,
        154,
        87,
        35,
        45,
        146,
        2,
        195,
        172,
        184,
        141,
        33,
        130,
        145,
        133,
        109,
        169,
        4,
        171,
        104,
        0,
        16,
        68,
        3,
        0,
        196,
        73,
        175,
        81,
        55,
        0,
        64,
        197,
        138,
        124,
        3
      ],
      "weeklyWasted": [
        171,
        104,
        0,
        16,
        68,
        3,
        0,
        196,
        73,
        175,
        81,
        55,
        0,
        64,
        197,
        138,
        124,
        3,
        67,
        1,
        47,
        91,
        98,
        54,
        82,
        16,
        92,
        65,
        49,
        83,
        99,
        99,
        45,
        15,
        62,
        99,
        1,
        22,
        49,
        0,
        0,
        80,
        12,
        89,
        76,
        2,
        99,
        96,
        31,
        31,
        1,
        98,
        89,
        7,
        49,
        99,
        24,
        99,
        85,
        0,
        45,
        93,
        0,
        80,
        0,
        98,
        99,
        99,
        61,
        1
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        199,
        130,
        149,
        5,
        2,
        23,
        38,
        197,
        171,
        7,
        107,
        48,
        100,
        76,
        55,
        3,
        176,
        199,
        197,
        154,
        197,
        116,
        128,
        195,
        3,
        133,
        3,
        137,
        110,
        153,
        83,
        0,
        31,
        199,
        97,
        0,
        194,
        5,
        113,
        166,
        27,
        104,
        124,
        36,
        99,
        31,
        149,
        46,
        198,
        20,
        151,
        3
      ],
      "weeklyWasted": [
        97,
        0,
        194,
        5,
        113,
        166,
        27,
        104,
        124,
        36,
        99,
        31,
        149,
        46,
        198,
        20,
        151,
        3,
        99,
        49,
        67,
        43,
        79,
        1,
        44,
        54,
        7,
        68,
        54,
        24,
        13,
        67,
        13,
        64,
        98,
        67,
        1,
        0,
        34,
        1,
        0,
        0,
        95,
        76,
        7,
        31,
        70,
        1,
        2,
        84,
        99,
        81,
        82,
        9,
        49,
        62,
        94,
        59,
        85,
        68,
        72,
        53,
        17,
        84,
        63,
        70,
        72,
        1,
        42,
        1
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        199,
        16,
        150,
        105,
        104,
        32,
        125,
        48,
        122,
        54,
        106,
        155,
        198,
        113,
        55,
        40,
        192,
        185,
        100,
        62,
        116,
        4,
        197,
        72,
        3,
        174,
        169,
        70,
        119,
        105,
        13,
        0,
        197,
        67,
        121,
        61,
        103,
        5,
        166,
        95,
        56,
        101,
        0,
        77,
        1,
        4,
        0,
        27,
        162,
        182,
        180,
        6
      ],
      "weeklyWasted": [
        121,
        61,
        103,
        5,
        166,
        95,
        56,
        101,
        0,
        77,
        1,
        4,
        0,
        27,
        162,
        182,
        180,
        6,
        59,
        30,
        0,
        2,
        84,
        23,
        88,
        81,
        45,
        99,
        58,
        40,
        99,
        63,
        3,
        20,
        18,
        40,
        1,
        29,
        2,
        72,
        41,
        11,
        19,
        99,
        63,
        63,
        76,
        27,
        24,
        79,
        71,
        58,
        99,
        59,
        99,
        80,
        20,
        57,
        6,
        0,
        99,
        99,
        0,
        83,
        99,
        91,
        99,
        96,
        99,
        30
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        199,
        125,
        108,
        118,
        198,
        132,
        194,
        70,
        151,
        1,
        107,
        2,
        156,
        39,
        199,
        84,
        155,
        54,
        129,
        69,
        68,
        198,
        26,
        1,
        3,
        8,
        197,
        92,
        0,
        109,
        73,
        34,
        13,
        199,
        157,
        86,
        198,
        77,
        124,
        88,
        72,
        44,
        97,
        12,
        8,
        165,
        0,
        176,
        163,
        99,
        2,
        19
      ],
      "weeklyWasted": [
        157,
        86,
        198,
        77,
        124,
        88,
        72,
        44,
        97,
        12,
        8,
        165,
        0,
        176,
        163,
        99,
        2,
        19,
        48,
        1,
        24,
        53,
        75,
        69,
        64,
        35,
        65,
        99,
        99,
        1,
        78,
        93,
        65,
        19,
        0,
        99,
        1,
        0,
        99,
        31,
        65,
        71,
        91,
        66,
        41,
        93,
        86,
        7,
        84,
        88,
        65,
        28,
        69,
        75,
        82,
        50,
        99,
        39,
        87,
        22,
        68,
        84,
        71,
        78,
        78,
        26,
        64,
        99,
        20,
        1
      ]
    },
    {
      "id": "11102F47",
      "weeklyUsage": [
        199,
        104,
        59,
        5,
        7,
        195,
        93,
        113,
        74,
        13,
        199,
        33,
        198,
        0,
        115,
        113,
        157,
        105,
        101,
        197,
        143,
        183,
        2,
        195,
        66,
        67,
        126,
        42,
        199,
        90,
        0,
        52,
        66,
        129,
        198,
        81,
        115,
        5,
        180,
        77,
        8,
        199,
        0,
        172,
        1,
        55,
        84,
        0,
        198,
        115,
        173,
        99
      ],
      "weeklyWasted": [
        198,
        81,
        115,
        5,
        180,
        77,
        8,
        199,
        0,
        172,
        1,
        55,
        84,
        0,
        198,
        115,
        173,
        99,
        99,
        32,
        44,
        5,
        92,
        58,
        15,
        67,
        0,
        15,
        75,
        63,
        59,
        72,
        36,
        12,
        0,
        42,
        1,
        16,
        2,
        99,
        44,
        0,
        52,
        21,
        64,
        42,
        36,
        77,
        98,
        51,
        53,
        98,
        99,
        40,
        78,
        37,
        52,
        99,
        82,
        0,
        99,
        77,
        43,
        51,
        23,
        98,
        3,
        52,
        54,
        11
      ]
    },
    {
      "id": 9202537,
      "weeklyUsage": [
        20,
        2,
        198,
        72,
        0,
        114,
        194,
        178,
        134,
        114,
        51,
        140,
        198,
        142,
        92,
        106,
        162,
        155,
        160,
        142,
        96,
        130,
        82,
        129,
        76,
        76,
        146,
        1,
        116,
        198,
        105,
        23,
        22,
        112,
        63,
        97,
        135,
        101,
        129,
        199,
        54,
        0,
        78,
        0,
        146,
        196,
        10,
        38,
        198,
        174,
        176,
        59
      ],
      "weeklyWasted": [
        63,
        97,
        135,
        101,
        129,
        199,
        54,
        0,
        78,
        0,
        146,
        196,
        10,
        38,
        198,
        174,
        176,
        59,
        1,
        1,
        26,
        2,
        98,
        88,
        42,
        8,
        13,
        0,
        78,
        59,
        64,
        78,
        0,
        4,
        22,
        1,
        27,
        46,
        45,
        36,
        57,
        77,
        0,
        19,
        77,
        48,
        47,
        1,
        45,
        64,
        45,
        40,
        78,
        34,
        76,
        90,
        41,
        79,
        51,
        59,
        74,
        99,
        49,
        70,
        99,
        98,
        59,
        75,
        47,
        85
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        149,
        2,
        190,
        69,
        0,
        81,
        85,
        154,
        25,
        1,
        113,
        138,
        180,
        0,
        112,
        179,
        192,
        127,
        120,
        2,
        197,
        4,
        93,
        113,
        133,
        198,
        125,
        40,
        146,
        138,
        70,
        0,
        1,
        4,
        66,
        84,
        141,
        123,
        79,
        69,
        119,
        92,
        88,
        0,
        17,
        196,
        46,
        0,
        100,
        113,
        111,
        115
      ],
      "weeklyWasted": [
        66,
        84,
        141,
        123,
        79,
        69,
        119,
        92,
        88,
        0,
        17,
        196,
        46,
        0,
        100,
        113,
        111,
        115,
        94,
        40,
        32,
        2,
        98,
        35,
        36,
        77,
        46,
        35,
        99,
        14,
        47,
        99,
        34,
        1,
        6,
        63,
        21,
        74,
        87,
        28,
        56,
        3,
        21,
        29,
        98,
        33,
        57,
        34,
        2,
        97,
        1,
        76,
        33,
        26,
        81,
        77,
        79,
        99,
        16,
        86,
        99,
        88,
        77,
        13,
        66,
        98,
        0,
        59,
        54,
        60
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        62,
        86,
        0,
        5,
        59,
        67,
        80,
        176,
        2,
        1,
        86,
        128,
        111,
        0,
        102,
        134,
        0,
        140,
        192,
        169,
        106,
        94,
        106,
        102,
        103,
        161,
        65,
        68,
        199,
        187,
        89,
        105,
        45,
        103,
        59,
        122,
        198,
        5,
        10,
        53,
        199,
        0,
        0,
        1,
        1,
        196,
        0,
        11,
        136,
        2,
        114,
        128
      ],
      "weeklyWasted": [
        59,
        122,
        198,
        5,
        10,
        53,
        199,
        0,
        0,
        1,
        1,
        196,
        0,
        11,
        136,
        2,
        114,
        128,
        37,
        1,
        52,
        2,
        97,
        68,
        29,
        31,
        82,
        81,
        40,
        48,
        99,
        79,
        32,
        27,
        46,
        69,
        50,
        30,
        70,
        0,
        0,
        56,
        65,
        73,
        32,
        59,
        57,
        1,
        2,
        43,
        46,
        98,
        58,
        50,
        99,
        94,
        23,
        72,
        36,
        53,
        99,
        99,
        70,
        12,
        69,
        66,
        36,
        80,
        48,
        52
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        188,
        2,
        73,
        87,
        141,
        99,
        46,
        80,
        173,
        1,
        70,
        31,
        2,
        50,
        116,
        164,
        0,
        183,
        120,
        15,
        33,
        111,
        2,
        184,
        6,
        123,
        143,
        124,
        167,
        92,
        29,
        196,
        103,
        54,
        177,
        199,
        181,
        53,
        90,
        59,
        42,
        0,
        85,
        0,
        1,
        4,
        13,
        64,
        198,
        43,
        149,
        144
      ],
      "weeklyWasted": [
        177,
        199,
        181,
        53,
        90,
        59,
        42,
        0,
        85,
        0,
        1,
        4,
        13,
        64,
        198,
        43,
        149,
        144,
        16,
        7,
        19,
        75,
        98,
        98,
        34,
        0,
        83,
        0,
        99,
        99,
        76,
        79,
        0,
        28,
        0,
        59,
        24,
        49,
        30,
        99,
        8,
        52,
        60,
        40,
        98,
        88,
        24,
        52,
        2,
        1,
        26,
        98,
        99,
        39,
        1,
        49,
        94,
        47,
        70,
        0,
        33,
        9,
        68,
        91,
        28,
        94,
        28,
        1,
        79,
        16
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        199,
        2,
        138,
        5,
        0,
        158,
        194,
        13,
        199,
        1,
        0,
        76,
        198,
        18,
        69,
        74,
        165,
        135,
        36,
        94,
        65,
        38,
        36,
        195,
        85,
        105,
        170,
        89,
        125,
        27,
        45,
        143,
        169,
        190,
        151,
        167,
        21,
        77,
        10,
        35,
        10,
        131,
        65,
        176,
        128,
        196,
        122,
        198,
        122,
        199,
        174,
        99
      ],
      "weeklyWasted": [
        151,
        167,
        21,
        77,
        10,
        35,
        10,
        131,
        65,
        176,
        128,
        196,
        122,
        198,
        122,
        199,
        174,
        99,
        33,
        14,
        38,
        72,
        49,
        46,
        99,
        18,
        45,
        69,
        32,
        78,
        69,
        99,
        11,
        98,
        88,
        50,
        99,
        80,
        62,
        5,
        41,
        11,
        0,
        99,
        60,
        62,
        99,
        56,
        17,
        97,
        58,
        98,
        53,
        6,
        4,
        0,
        45,
        28,
        33,
        51,
        0,
        99,
        0,
        82,
        31,
        92,
        12,
        45,
        99,
        25
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        199,
        134,
        120,
        122,
        187,
        19,
        35,
        152,
        123,
        54,
        199,
        152,
        198,
        0,
        199,
        52,
        192,
        139,
        3,
        66,
        197,
        10,
        73,
        48,
        54,
        41,
        104,
        40,
        70,
        34,
        162,
        166,
        1,
        4,
        61,
        98,
        16,
        190,
        59,
        8,
        142,
        52,
        0,
        0,
        1,
        83,
        32,
        81,
        141,
        69,
        77,
        3
      ],
      "weeklyWasted": [
        61,
        98,
        16,
        190,
        59,
        8,
        142,
        52,
        0,
        0,
        1,
        83,
        32,
        81,
        141,
        69,
        77,
        3,
        30,
        96,
        30,
        15,
        81,
        64,
        99,
        45,
        73,
        27,
        99,
        52,
        87,
        35,
        21,
        25,
        75,
        51,
        82,
        76,
        45,
        60,
        0,
        0,
        35,
        42,
        59,
        97,
        51,
        7,
        2,
        96,
        56,
        80,
        45,
        57,
        86,
        98,
        0,
        12,
        21,
        12,
        90,
        81,
        66,
        45,
        99,
        31,
        24,
        19,
        48,
        64
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        199,
        92,
        198,
        94,
        0,
        197,
        16,
        175,
        92,
        106,
        54,
        2,
        117,
        71,
        195,
        69,
        99,
        188,
        3,
        165,
        184,
        131,
        105,
        183,
        197,
        145,
        197,
        89,
        182,
        39,
        106,
        36,
        119,
        199,
        4,
        0,
        109,
        46,
        132,
        132,
        29,
        70,
        0,
        86,
        28,
        145,
        110,
        14,
        198,
        156,
        197,
        127
      ],
      "weeklyWasted": [
        4,
        0,
        109,
        46,
        132,
        132,
        29,
        70,
        0,
        86,
        28,
        145,
        110,
        14,
        198,
        156,
        197,
        127,
        96,
        15,
        18,
        2,
        94,
        1,
        25,
        0,
        99,
        71,
        99,
        65,
        36,
        87,
        97,
        1,
        38,
        40,
        1,
        0,
        55,
        45,
        62,
        0,
        95,
        48,
        45,
        25,
        74,
        90,
        32,
        89,
        54,
        98,
        99,
        4,
        99,
        97,
        49,
        99,
        15,
        0,
        60,
        38,
        52,
        75,
        36,
        98,
        59,
        94,
        11,
        1
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        193,
        71,
        23,
        109,
        163,
        119,
        137,
        89,
        56,
        1,
        25,
        2,
        167,
        42,
        26,
        3,
        150,
        87,
        106,
        2,
        25,
        4,
        177,
        195,
        3,
        198,
        196,
        45,
        199,
        3,
        59,
        0,
        172,
        199,
        198,
        136,
        152,
        69,
        199,
        0,
        0,
        0,
        70,
        118,
        1,
        176,
        0,
        0,
        188,
        199,
        40,
        91
      ],
      "weeklyWasted": [
        198,
        136,
        152,
        69,
        199,
        0,
        0,
        0,
        70,
        118,
        1,
        176,
        0,
        0,
        188,
        199,
        40,
        91,
        99,
        12,
        23,
        27,
        98,
        40,
        99,
        0,
        50,
        99,
        93,
        39,
        56,
        88,
        0,
        98,
        51,
        59,
        4,
        20,
        2,
        68,
        0,
        0,
        70,
        66,
        59,
        48,
        99,
        1,
        2,
        72,
        18,
        98,
        0,
        73,
        84,
        97,
        99,
        70,
        66,
        67,
        22,
        2,
        86,
        82,
        69,
        61,
        0,
        30,
        35,
        47
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        100,
        43,
        79,
        112,
        140,
        144,
        44,
        3,
        147,
        1,
        81,
        19,
        153,
        115,
        186,
        51,
        192,
        199,
        51,
        151,
        197,
        37,
        20,
        124,
        3,
        47,
        181,
        132,
        75,
        128,
        45,
        115,
        26,
        110,
        4,
        158,
        159,
        53,
        110,
        50,
        0,
        37,
        169,
        1,
        50,
        4,
        71,
        198,
        193,
        199,
        2,
        105
      ],
      "weeklyWasted": [
        4,
        158,
        159,
        53,
        110,
        50,
        0,
        37,
        169,
        1,
        50,
        4,
        71,
        198,
        193,
        199,
        2,
        105,
        54,
        1,
        9,
        96,
        70,
        31,
        99,
        4,
        8,
        42,
        45,
        97,
        76,
        3,
        97,
        26,
        56,
        69,
        10,
        41,
        34,
        89,
        88,
        94,
        0,
        72,
        0,
        97,
        23,
        14,
        11,
        16,
        99,
        16,
        38,
        71,
        38,
        43,
        32,
        23,
        22,
        0,
        10,
        62,
        60,
        72,
        99,
        86,
        99,
        4,
        0,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        199,
        96,
        122,
        195,
        62,
        119,
        103,
        197,
        130,
        42,
        199,
        43,
        163,
        0,
        73,
        76,
        108,
        192,
        90,
        118,
        197,
        53,
        94,
        153,
        189,
        92,
        142,
        1,
        160,
        198,
        107,
        108,
        180,
        78,
        188,
        18,
        147,
        197,
        60,
        118,
        127,
        144,
        0,
        66,
        3,
        28,
        134,
        73,
        198,
        63,
        119,
        28
      ],
      "weeklyWasted": [
        188,
        18,
        147,
        197,
        60,
        118,
        127,
        144,
        0,
        66,
        3,
        28,
        134,
        73,
        198,
        63,
        119,
        28,
        67,
        38,
        63,
        38,
        21,
        73,
        45,
        44,
        80,
        42,
        66,
        98,
        61,
        71,
        45,
        94,
        5,
        99,
        12,
        99,
        99,
        30,
        0,
        31,
        49,
        94,
        59,
        1,
        51,
        31,
        26,
        97,
        45,
        55,
        13,
        0,
        90,
        84,
        94,
        28,
        24,
        0,
        99,
        77,
        99,
        67,
        84,
        98,
        62,
        99,
        92,
        1
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        152,
        51,
        44,
        52,
        158,
        17,
        175,
        12,
        158,
        1,
        0,
        40,
        105,
        106,
        199,
        3,
        11,
        195,
        74,
        2,
        1,
        136,
        2,
        160,
        126,
        124,
        176,
        176,
        199,
        198,
        171,
        196,
        29,
        85,
        121,
        15,
        43,
        199,
        113,
        88,
        90,
        58,
        105,
        78,
        1,
        145,
        0,
        49,
        158,
        193,
        120,
        23
      ],
      "weeklyWasted": [
        121,
        15,
        43,
        199,
        113,
        88,
        90,
        58,
        105,
        78,
        1,
        145,
        0,
        49,
        158,
        193,
        120,
        23,
        45,
        1,
        35,
        2,
        90,
        52,
        58,
        22,
        15,
        78,
        14,
        64,
        60,
        88,
        18,
        55,
        59,
        75,
        99,
        55,
        96,
        94,
        4,
        7,
        47,
        38,
        31,
        23,
        58,
        1,
        2,
        73,
        79,
        91,
        0,
        48,
        99,
        48,
        32,
        6,
        5,
        98,
        63,
        71,
        32,
        48,
        90,
        98,
        63,
        58,
        16,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyUsage": [
        111,
        184,
        0,
        5,
        118,
        170,
        81,
        3,
        197,
        195,
        61,
        2,
        39,
        0,
        136,
        3,
        192,
        192,
        65,
        47,
        197,
        4,
        95,
        162,
        3,
        157,
        192,
        107,
        181,
        49,
        90,
        39,
        103,
        199,
        169,
        3,
        40,
        103,
        10,
        70,
        34,
        17,
        0,
        21,
        1,
        123,
        0,
        39,
        198,
        65,
        2,
        116
      ],
      "weeklyWasted": [
        169,
        3,
        40,
        103,
        10,
        70,
        34,
        17,
        0,
        21,
        1,
        123,
        0,
        39,
        198,
        65,
        2,
        116,
        30,
        75,
        79,
        69,
        67,
        1,
        99,
        44,
        65,
        34,
        0,
        78,
        42,
        99,
        43,
        53,
        52,
        49,
        96,
        99,
        72,
        43,
        54,
        13,
        27,
        43,
        98,
        27,
        60,
        17,
        99,
        78,
        47,
        30,
        23,
        7,
        73,
        88,
        99,
        99,
        12,
        47,
        38,
        61,
        99,
        0,
        49,
        63,
        38,
        60,
        99,
        99
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        89,
        86,
        113,
        66,
        0,
        155,
        46,
        43,
        2,
        1,
        199,
        100,
        187,
        51,
        189,
        101,
        192,
        86,
        120,
        2,
        114,
        56,
        2,
        43,
        75,
        198,
        182,
        137,
        139,
        78,
        194,
        98,
        126,
        87,
        43,
        131,
        153,
        115,
        146,
        0,
        140,
        0,
        0,
        198,
        1,
        172,
        45,
        146,
        30,
        93,
        114,
        17
      ],
      "weeklyWasted": [
        43,
        131,
        153,
        115,
        146,
        0,
        140,
        0,
        0,
        198,
        1,
        172,
        45,
        146,
        30,
        93,
        114,
        17,
        79,
        11,
        60,
        2,
        57,
        67,
        72,
        5,
        63,
        69,
        75,
        99,
        60,
        74,
        73,
        8,
        0,
        47,
        24,
        59,
        2,
        32,
        58,
        47,
        28,
        63,
        67,
        72,
        99,
        4,
        2,
        64,
        75,
        98,
        54,
        17,
        74,
        99,
        99,
        99,
        8,
        0,
        91,
        83,
        71,
        8,
        99,
        66,
        99,
        99,
        0,
        14
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        199,
        14,
        21,
        18,
        198,
        161,
        161,
        100,
        2,
        20,
        112,
        68,
        198,
        190,
        171,
        75,
        192,
        1,
        197,
        153,
        197,
        84,
        15,
        1,
        51,
        14,
        151,
        143,
        174,
        198,
        197,
        48,
        96,
        167,
        89,
        24,
        147,
        58,
        46,
        72,
        39,
        0,
        0,
        110,
        1,
        80,
        26,
        100,
        198,
        195,
        2,
        3
      ],
      "weeklyWasted": [
        89,
        24,
        147,
        58,
        46,
        72,
        39,
        0,
        0,
        110,
        1,
        80,
        26,
        100,
        198,
        195,
        2,
        3,
        1,
        31,
        88,
        96,
        52,
        89,
        57,
        16,
        0,
        0,
        99,
        1,
        42,
        53,
        54,
        85,
        21,
        99,
        31,
        26,
        60,
        74,
        62,
        0,
        11,
        26,
        76,
        39,
        19,
        48,
        76,
        7,
        74,
        98,
        99,
        84,
        38,
        11,
        99,
        1,
        15,
        0,
        86,
        13,
        79,
        84,
        70,
        46,
        1,
        66,
        23,
        58
      ]
    },
    {
      "id": "D9345860",
      "weeklyUsage": [
        154,
        2,
        176,
        5,
        67,
        61,
        194,
        37,
        125,
        1,
        36,
        2,
        2,
        8,
        95,
        114,
        192,
        189,
        54,
        72,
        183,
        44,
        2,
        138,
        3,
        101,
        148,
        1,
        155,
        188,
        43,
        76,
        75,
        155,
        120,
        66,
        198,
        168,
        188,
        64,
        0,
        78,
        198,
        49,
        45,
        121,
        0,
        81,
        198,
        138,
        143,
        178
      ],
      "weeklyWasted": [
        120,
        66,
        198,
        168,
        188,
        64,
        0,
        78,
        198,
        49,
        45,
        121,
        0,
        81,
        198,
        138,
        143,
        178,
        99,
        63,
        31,
        2,
        35,
        54,
        37,
        27,
        72,
        9,
        63,
        1,
        9,
        43,
        27,
        35,
        75,
        78,
        29,
        43,
        45,
        19,
        68,
        0,
        90,
        76,
        36,
        52,
        45,
        74,
        18,
        59,
        40,
        75,
        99,
        27,
        54,
        68,
        99,
        34,
        99,
        20,
        70,
        99,
        76,
        95,
        99,
        90,
        62,
        58,
        86,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        199,
        24,
        70,
        156,
        98,
        137,
        12,
        3,
        128,
        11,
        199,
        37,
        155,
        199,
        177,
        169,
        38,
        199,
        3,
        9,
        197,
        4,
        69,
        61,
        137,
        72,
        167,
        178,
        0,
        0,
        0,
        127,
        197,
        15,
        124,
        0,
        65,
        199,
        199,
        0,
        0,
        133,
        38,
        48,
        19,
        174,
        35,
        122,
        133,
        138,
        116,
        134
      ],
      "weeklyWasted": [
        124,
        0,
        65,
        199,
        199,
        0,
        0,
        133,
        38,
        48,
        19,
        174,
        35,
        122,
        133,
        138,
        116,
        134,
        18,
        1,
        0,
        46,
        49,
        82,
        18,
        64,
        79,
        94,
        21,
        19,
        63,
        77,
        41,
        43,
        14,
        20,
        4,
        62,
        73,
        16,
        0,
        63,
        0,
        98,
        29,
        52,
        55,
        23,
        88,
        60,
        80,
        98,
        63,
        59,
        99,
        40,
        70,
        37,
        40,
        0,
        0,
        78,
        99,
        99,
        0,
        98,
        53,
        59,
        25,
        29
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        141,
        2,
        140,
        5,
        0,
        128,
        106,
        55,
        136,
        1,
        156,
        120,
        53,
        3,
        199,
        32,
        125,
        163,
        155,
        2,
        197,
        118,
        3,
        134,
        54,
        119,
        5,
        40,
        125,
        198,
        199,
        179,
        76,
        82,
        92,
        89,
        102,
        125,
        199,
        70,
        16,
        87,
        0,
        43,
        27,
        62,
        21,
        161,
        198,
        2,
        140,
        88
      ],
      "weeklyWasted": [
        92,
        89,
        102,
        125,
        199,
        70,
        16,
        87,
        0,
        43,
        27,
        62,
        21,
        161,
        198,
        2,
        140,
        88,
        73,
        20,
        99,
        19,
        41,
        34,
        0,
        7,
        93,
        66,
        71,
        90,
        39,
        26,
        11,
        47,
        25,
        99,
        89,
        73,
        31,
        0,
        29,
        0,
        79,
        20,
        93,
        80,
        93,
        96,
        2,
        48,
        1,
        98,
        75,
        72,
        19,
        0,
        12,
        1,
        27,
        25,
        59,
        79,
        79,
        72,
        99,
        98,
        70,
        45,
        81,
        1
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyUsage": [
        145,
        2,
        130,
        5,
        33,
        115,
        49,
        7,
        87,
        31,
        199,
        108,
        173,
        0,
        119,
        184,
        192,
        1,
        113,
        197,
        96,
        8,
        139,
        28,
        114,
        1,
        57,
        1,
        72,
        67,
        20,
        2,
        50,
        4,
        119,
        199,
        198,
        197,
        65,
        107,
        0,
        199,
        44,
        30,
        4,
        196,
        133,
        0,
        176,
        65,
        99,
        3
      ],
      "weeklyWasted": [
        119,
        199,
        198,
        197,
        65,
        107,
        0,
        199,
        44,
        30,
        4,
        196,
        133,
        0,
        176,
        65,
        99,
        3,
        39,
        63,
        63,
        2,
        33,
        84,
        64,
        0,
        99,
        0,
        83,
        56,
        62,
        99,
        47,
        98,
        62,
        65,
        26,
        16,
        41,
        41,
        22,
        4,
        0,
        31,
        98,
        13,
        84,
        19,
        58,
        94,
        40,
        98,
        0,
        0,
        48,
        99,
        25,
        99,
        94,
        0,
        84,
        99,
        99,
        36,
        35,
        46,
        49,
        89,
        99,
        1
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        149,
        60,
        0,
        186,
        110,
        79,
        65,
        3,
        191,
        1,
        199,
        71,
        66,
        0,
        125,
        74,
        192,
        115,
        170,
        86,
        103,
        141,
        61,
        173,
        43,
        129,
        176,
        148,
        121,
        4,
        105,
        1,
        2,
        99,
        83,
        0,
        139,
        129,
        10,
        99,
        123,
        36,
        47,
        0,
        1,
        196,
        0,
        61,
        1,
        77,
        132,
        154
      ],
      "weeklyWasted": [
        83,
        0,
        139,
        129,
        10,
        99,
        123,
        36,
        47,
        0,
        1,
        196,
        0,
        61,
        1,
        77,
        132,
        154,
        93,
        53,
        16,
        15,
        98,
        24,
        76,
        82,
        66,
        99,
        99,
        99,
        49,
        83,
        0,
        45,
        98,
        29,
        1,
        29,
        2,
        0,
        0,
        12,
        48,
        64,
        24,
        97,
        30,
        9,
        2,
        85,
        1,
        31,
        28,
        42,
        99,
        99,
        91,
        99,
        27,
        0,
        81,
        79,
        99,
        12,
        42,
        46,
        88,
        91,
        0,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        20,
        104,
        0,
        5,
        138,
        14,
        62,
        3,
        199,
        1,
        96,
        37,
        184,
        99,
        4,
        168,
        58,
        94,
        73,
        2,
        55,
        4,
        143,
        44,
        85,
        134,
        44,
        79,
        42,
        94,
        61,
        81,
        166,
        151,
        88,
        12,
        150,
        118,
        113,
        0,
        0,
        199,
        65,
        11,
        1,
        185,
        17,
        113,
        163,
        22,
        104,
        102
      ],
      "weeklyWasted": [
        88,
        12,
        150,
        118,
        113,
        0,
        0,
        199,
        65,
        11,
        1,
        185,
        17,
        113,
        163,
        22,
        104,
        102,
        99,
        62,
        72,
        49,
        0,
        17,
        27,
        0,
        99,
        86,
        99,
        87,
        60,
        3,
        26,
        98,
        0,
        99,
        3,
        52,
        2,
        75,
        0,
        92,
        0,
        67,
        75,
        76,
        2,
        17,
        52,
        97,
        44,
        79,
        99,
        54,
        53,
        65,
        23,
        53,
        95,
        44,
        99,
        69,
        31,
        20,
        17,
        93,
        66,
        75,
        53,
        9
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        182,
        2,
        155,
        18,
        143,
        6,
        168,
        3,
        2,
        1,
        0,
        23,
        93,
        118,
        199,
        75,
        173,
        117,
        109,
        29,
        129,
        198,
        2,
        99,
        188,
        136,
        197,
        86,
        163,
        135,
        199,
        26,
        131,
        133,
        102,
        82,
        120,
        81,
        89,
        15,
        193,
        171,
        116,
        0,
        199,
        27,
        113,
        117,
        178,
        57,
        197,
        3
      ],
      "weeklyWasted": [
        102,
        82,
        120,
        81,
        89,
        15,
        193,
        171,
        116,
        0,
        199,
        27,
        113,
        117,
        178,
        57,
        197,
        3,
        85,
        34,
        11,
        3,
        91,
        49,
        69,
        18,
        76,
        48,
        96,
        93,
        69,
        79,
        43,
        20,
        28,
        75,
        49,
        0,
        48,
        35,
        0,
        0,
        33,
        42,
        62,
        31,
        2,
        82,
        2,
        70,
        37,
        59,
        97,
        5,
        73,
        82,
        66,
        43,
        75,
        77,
        99,
        14,
        45,
        57,
        69,
        98,
        28,
        42,
        62,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        135,
        59,
        95,
        72,
        146,
        1,
        48,
        3,
        2,
        1,
        124,
        94,
        93,
        171,
        52,
        3,
        106,
        22,
        3,
        166,
        147,
        4,
        197,
        195,
        116,
        25,
        105,
        69,
        40,
        157,
        0,
        148,
        1,
        4,
        51,
        116,
        171,
        47,
        74,
        123,
        50,
        0,
        0,
        190,
        109,
        99,
        0,
        83,
        198,
        75,
        2,
        71
      ],
      "weeklyWasted": [
        51,
        116,
        171,
        47,
        74,
        123,
        50,
        0,
        0,
        190,
        109,
        99,
        0,
        83,
        198,
        75,
        2,
        71,
        75,
        11,
        87,
        96,
        78,
        28,
        33,
        33,
        93,
        54,
        24,
        16,
        1,
        82,
        0,
        49,
        32,
        88,
        27,
        61,
        54,
        71,
        0,
        0,
        61,
        69,
        98,
        82,
        31,
        23,
        62,
        97,
        29,
        58,
        99,
        0,
        52,
        68,
        52,
        17,
        29,
        46,
        99,
        99,
        43,
        53,
        99,
        98,
        80,
        1,
        61,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        115,
        110,
        198,
        10,
        52,
        87,
        180,
        180,
        142,
        1,
        196,
        29,
        198,
        89,
        81,
        3,
        33,
        199,
        3,
        24,
        1,
        4,
        41,
        158,
        115,
        1,
        131,
        19,
        48,
        94,
        199,
        19,
        165,
        191,
        138,
        0,
        164,
        171,
        141,
        0,
        78,
        75,
        91,
        142,
        1,
        76,
        73,
        72,
        198,
        2,
        112,
        71
      ],
      "weeklyWasted": [
        138,
        0,
        164,
        171,
        141,
        0,
        78,
        75,
        91,
        142,
        1,
        76,
        73,
        72,
        198,
        2,
        112,
        71,
        32,
        1,
        9,
        83,
        54,
        26,
        99,
        19,
        89,
        99,
        81,
        99,
        75,
        54,
        65,
        51,
        30,
        39,
        29,
        91,
        2,
        77,
        25,
        41,
        39,
        91,
        37,
        52,
        52,
        1,
        35,
        32,
        3,
        96,
        81,
        10,
        1,
        99,
        34,
        97,
        12,
        60,
        95,
        79,
        82,
        53,
        42,
        98,
        28,
        99,
        24,
        13
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        46,
        182,
        0,
        77,
        40,
        119,
        148,
        3,
        49,
        149,
        199,
        94,
        128,
        0,
        174,
        102,
        192,
        199,
        119,
        103,
        197,
        136,
        25,
        127,
        5,
        14,
        125,
        66,
        0,
        44,
        70,
        0,
        197,
        55,
        4,
        0,
        146,
        9,
        167,
        108,
        57,
        0,
        11,
        194,
        1,
        4,
        0,
        198,
        198,
        188,
        168,
        152
      ],
      "weeklyWasted": [
        4,
        0,
        146,
        9,
        167,
        108,
        57,
        0,
        11,
        194,
        1,
        4,
        0,
        198,
        198,
        188,
        168,
        152,
        42,
        1,
        37,
        2,
        30,
        1,
        49,
        0,
        65,
        34,
        53,
        63,
        68,
        12,
        41,
        1,
        17,
        99,
        30,
        43,
        32,
        0,
        41,
        94,
        55,
        99,
        80,
        24,
        99,
        96,
        47,
        77,
        35,
        82,
        99,
        96,
        79,
        6,
        70,
        65,
        13,
        32,
        99,
        99,
        36,
        0,
        76,
        59,
        99,
        99,
        0,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        199,
        62,
        128,
        93,
        51,
        1,
        41,
        36,
        115,
        21,
        155,
        32,
        198,
        17,
        138,
        91,
        57,
        166,
        95,
        2,
        57,
        44,
        34,
        38,
        197,
        124,
        114,
        1,
        198,
        139,
        126,
        31,
        77,
        105,
        133,
        0,
        116,
        33,
        199,
        116,
        5,
        39,
        127,
        0,
        39,
        81,
        54,
        71,
        180,
        199,
        98,
        15
      ],
      "weeklyWasted": [
        133,
        0,
        116,
        33,
        199,
        116,
        5,
        39,
        127,
        0,
        39,
        81,
        54,
        71,
        180,
        199,
        98,
        15,
        53,
        13,
        93,
        32,
        82,
        69,
        61,
        6,
        84,
        59,
        99,
        82,
        1,
        99,
        22,
        98,
        72,
        99,
        57,
        47,
        2,
        91,
        0,
        0,
        66,
        27,
        67,
        40,
        78,
        1,
        7,
        78,
        77,
        98,
        99,
        0,
        44,
        76,
        11,
        70,
        2,
        2,
        48,
        31,
        52,
        77,
        2,
        98,
        0,
        45,
        24,
        1
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        199,
        2,
        31,
        39,
        63,
        115,
        44,
        179,
        13,
        1,
        199,
        96,
        123,
        0,
        166,
        74,
        79,
        182,
        162,
        161,
        86,
        132,
        2,
        124,
        197,
        113,
        123,
        1,
        53,
        88,
        29,
        171,
        1,
        18,
        39,
        147,
        66,
        92,
        10,
        0,
        85,
        28,
        199,
        14,
        15,
        196,
        35,
        114,
        198,
        199,
        102,
        3
      ],
      "weeklyWasted": [
        39,
        147,
        66,
        92,
        10,
        0,
        85,
        28,
        199,
        14,
        15,
        196,
        35,
        114,
        198,
        199,
        102,
        3,
        55,
        33,
        28,
        44,
        43,
        66,
        88,
        5,
        99,
        28,
        0,
        99,
        65,
        63,
        0,
        36,
        75,
        59,
        78,
        45,
        17,
        85,
        17,
        22,
        47,
        43,
        98,
        52,
        2,
        17,
        56,
        43,
        83,
        64,
        99,
        28,
        69,
        89,
        23,
        89,
        21,
        26,
        87,
        84,
        91,
        57,
        99,
        98,
        60,
        78,
        28,
        48
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        104,
        2,
        0,
        122,
        89,
        88,
        57,
        100,
        199,
        82,
        145,
        164,
        11,
        88,
        134,
        187,
        180,
        180,
        58,
        189,
        16,
        78,
        55,
        195,
        79,
        124,
        96,
        68,
        91,
        13,
        199,
        111,
        83,
        111,
        4,
        0,
        137,
        5,
        90,
        0,
        141,
        92,
        39,
        18,
        1,
        101,
        183,
        157,
        91,
        142,
        60,
        148
      ],
      "weeklyWasted": [
        4,
        0,
        137,
        5,
        90,
        0,
        141,
        92,
        39,
        18,
        1,
        101,
        183,
        157,
        91,
        142,
        60,
        148,
        29,
        30,
        0,
        34,
        73,
        1,
        89,
        22,
        82,
        31,
        49,
        93,
        81,
        89,
        42,
        43,
        87,
        45,
        93,
        46,
        31,
        0,
        0,
        27,
        0,
        41,
        54,
        69,
        26,
        26,
        70,
        59,
        42,
        98,
        76,
        76,
        42,
        0,
        65,
        56,
        63,
        14,
        35,
        38,
        71,
        94,
        0,
        98,
        10,
        1,
        44,
        1
      ]
    },
    {
      "id": "4FF22342",
      "weeklyUsage": [
        199,
        103,
        16,
        5,
        122,
        1,
        117,
        10,
        45,
        1,
        71,
        92,
        191,
        69,
        142,
        174,
        192,
        102,
        3,
        34,
        129,
        50,
        54,
        144,
        150,
        75,
        142,
        110,
        88,
        82,
        22,
        196,
        38,
        4,
        4,
        199,
        81,
        5,
        36,
        0,
        58,
        86,
        121,
        0,
        1,
        196,
        102,
        149,
        198,
        73,
        7,
        3
      ],
      "weeklyWasted": [
        4,
        199,
        81,
        5,
        36,
        0,
        58,
        86,
        121,
        0,
        1,
        196,
        102,
        149,
        198,
        73,
        7,
        3,
        21,
        4,
        0,
        46,
        0,
        52,
        61,
        12,
        92,
        20,
        99,
        42,
        18,
        56,
        65,
        41,
        45,
        48,
        8,
        79,
        43,
        75,
        37,
        94,
        51,
        99,
        71,
        39,
        53,
        22,
        32,
        85,
        1,
        98,
        81,
        12,
        47,
        53,
        77,
        99,
        73,
        0,
        65,
        1,
        67,
        70,
        37,
        98,
        9,
        49,
        58,
        99
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        74,
        180,
        173,
        5,
        42,
        1,
        183,
        36,
        2,
        44,
        111,
        146,
        181,
        149,
        199,
        118,
        0,
        190,
        91,
        153,
        145,
        89,
        156,
        133,
        196,
        55,
        152,
        109,
        160,
        81,
        199,
        67,
        61,
        187,
        58,
        61,
        198,
        129,
        10,
        184,
        30,
        23,
        176,
        0,
        1,
        196,
        80,
        0,
        136,
        2,
        197,
        3
      ],
      "weeklyWasted": [
        58,
        61,
        198,
        129,
        10,
        184,
        30,
        23,
        176,
        0,
        1,
        196,
        80,
        0,
        136,
        2,
        197,
        3,
        53,
        1,
        16,
        72,
        36,
        49,
        67,
        64,
        54,
        0,
        61,
        58,
        81,
        98,
        40,
        1,
        32,
        72,
        44,
        33,
        2,
        8,
        91,
        11,
        96,
        99,
        58,
        42,
        86,
        1,
        17,
        97,
        7,
        98,
        99,
        8,
        99,
        82,
        45,
        99,
        37,
        47,
        99,
        40,
        75,
        0,
        99,
        98,
        83,
        21,
        16,
        40
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyUsage": [
        32,
        10,
        58,
        41,
        68,
        132,
        34,
        3,
        140,
        28,
        166,
        6,
        68,
        13,
        95,
        10,
        160,
        178,
        127,
        120,
        197,
        11,
        197,
        195,
        161,
        9,
        197,
        91,
        120,
        45,
        125,
        105,
        19,
        122,
        144,
        0,
        59,
        160,
        199,
        92,
        0,
        19,
        121,
        0,
        39,
        121,
        0,
        150,
        198,
        145,
        47,
        196
      ],
      "weeklyWasted": [
        144,
        0,
        59,
        160,
        199,
        92,
        0,
        19,
        121,
        0,
        39,
        121,
        0,
        150,
        198,
        145,
        47,
        196,
        45,
        98,
        0,
        5,
        60,
        65,
        99,
        0,
        78,
        99,
        13,
        77,
        54,
        66,
        31,
        59,
        29,
        28,
        4,
        99,
        52,
        12,
        13,
        2,
        36,
        44,
        16,
        97,
        89,
        41,
        91,
        82,
        30,
        98,
        20,
        67,
        73,
        43,
        70,
        53,
        15,
        44,
        51,
        73,
        55,
        14,
        99,
        67,
        19,
        61,
        0,
        1
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        153,
        152,
        99,
        5,
        0,
        127,
        128,
        11,
        24,
        87,
        199,
        96,
        198,
        0,
        165,
        60,
        76,
        191,
        102,
        142,
        121,
        78,
        122,
        195,
        28,
        14,
        153,
        45,
        91,
        135,
        199,
        102,
        9,
        199,
        100,
        70,
        198,
        142,
        134,
        81,
        0,
        5,
        100,
        115,
        34,
        4,
        83,
        0,
        198,
        102,
        2,
        3
      ],
      "weeklyWasted": [
        100,
        70,
        198,
        142,
        134,
        81,
        0,
        5,
        100,
        115,
        34,
        4,
        83,
        0,
        198,
        102,
        2,
        3,
        47,
        1,
        34,
        81,
        46,
        46,
        99,
        0,
        77,
        99,
        11,
        8,
        7,
        99,
        26,
        43,
        46,
        50,
        12,
        65,
        2,
        47,
        31,
        0,
        99,
        34,
        34,
        97,
        84,
        65,
        2,
        97,
        56,
        44,
        44,
        0,
        70,
        75,
        0,
        1,
        22,
        59,
        47,
        99,
        27,
        57,
        99,
        98,
        48,
        35,
        99,
        50
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        199,
        103,
        0,
        5,
        28,
        165,
        194,
        3,
        2,
        199,
        199,
        173,
        173,
        177,
        157,
        107,
        177,
        185,
        56,
        59,
        39,
        24,
        2,
        128,
        3,
        1,
        106,
        1,
        0,
        48,
        94,
        106,
        187,
        110,
        64,
        78,
        5,
        199,
        10,
        45,
        0,
        80,
        0,
        104,
        1,
        57,
        116,
        89,
        181,
        154,
        123,
        73
      ],
      "weeklyWasted": [
        64,
        78,
        5,
        199,
        10,
        45,
        0,
        80,
        0,
        104,
        1,
        57,
        116,
        89,
        181,
        154,
        123,
        73,
        5,
        11,
        26,
        2,
        98,
        79,
        12,
        63,
        82,
        66,
        99,
        1,
        41,
        65,
        85,
        1,
        90,
        19,
        10,
        52,
        37,
        0,
        17,
        39,
        0,
        29,
        92,
        97,
        57,
        30,
        56,
        97,
        39,
        55,
        24,
        99,
        78,
        68,
        70,
        74,
        49,
        0,
        87,
        27,
        99,
        30,
        99,
        98,
        99,
        99,
        0,
        16
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        198,
        2,
        99,
        84,
        2,
        51,
        3,
        3,
        99,
        1,
        0,
        87,
        196,
        0,
        168,
        45,
        192,
        168,
        89,
        153,
        171,
        120,
        85,
        66,
        195,
        1,
        197,
        48,
        149,
        121,
        65,
        19,
        1,
        110,
        52,
        102,
        198,
        182,
        83,
        61,
        68,
        74,
        79,
        76,
        35,
        159,
        37,
        198,
        182,
        165,
        18,
        196
      ],
      "weeklyWasted": [
        52,
        102,
        198,
        182,
        83,
        61,
        68,
        74,
        79,
        76,
        35,
        159,
        37,
        198,
        182,
        165,
        18,
        196,
        1,
        1,
        99,
        40,
        50,
        70,
        44,
        48,
        86,
        19,
        70,
        73,
        73,
        26,
        97,
        39,
        49,
        67,
        99,
        74,
        30,
        50,
        64,
        0,
        99,
        99,
        13,
        69,
        12,
        1,
        2,
        78,
        45,
        98,
        99,
        40,
        2,
        99,
        99,
        1,
        17,
        66,
        0,
        25,
        99,
        17,
        99,
        26,
        1,
        44,
        0,
        16
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        72,
        90,
        15,
        69,
        55,
        197,
        29,
        93,
        77,
        1,
        156,
        55,
        155,
        0,
        53,
        71,
        144,
        172,
        94,
        163,
        3,
        167,
        2,
        152,
        120,
        175,
        173,
        20,
        86,
        104,
        45,
        111,
        174,
        174,
        4,
        34,
        167,
        47,
        10,
        0,
        199,
        119,
        29,
        148,
        47,
        163,
        0,
        174,
        1,
        99,
        2,
        3
      ],
      "weeklyWasted": [
        4,
        34,
        167,
        47,
        10,
        0,
        199,
        119,
        29,
        148,
        47,
        163,
        0,
        174,
        1,
        99,
        2,
        3,
        6,
        1,
        0,
        47,
        98,
        1,
        99,
        0,
        0,
        6,
        0,
        65,
        99,
        58,
        37,
        98,
        69,
        59,
        2,
        21,
        2,
        0,
        74,
        81,
        0,
        51,
        71,
        97,
        34,
        14,
        7,
        38,
        1,
        86,
        99,
        69,
        21,
        71,
        37,
        99,
        99,
        66,
        60,
        96,
        99,
        10,
        99,
        86,
        90,
        61,
        45,
        6
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyUsage": [
        199,
        98,
        111,
        110,
        132,
        108,
        181,
        109,
        157,
        1,
        94,
        31,
        162,
        0,
        199,
        125,
        192,
        80,
        197,
        192,
        126,
        138,
        86,
        127,
        95,
        198,
        107,
        1,
        102,
        134,
        190,
        0,
        86,
        195,
        121,
        160,
        129,
        38,
        90,
        56,
        117,
        199,
        0,
        126,
        46,
        96,
        116,
        101,
        198,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        121,
        160,
        129,
        38,
        90,
        56,
        117,
        199,
        0,
        126,
        46,
        96,
        116,
        101,
        198,
        199,
        2,
        3,
        58,
        1,
        21,
        2,
        98,
        24,
        99,
        29,
        26,
        60,
        99,
        58,
        66,
        6,
        52,
        32,
        50,
        43,
        1,
        53,
        2,
        33,
        7,
        0,
        43,
        74,
        51,
        97,
        51,
        8,
        56,
        68,
        61,
        1,
        74,
        99,
        96,
        67,
        18,
        99,
        0,
        0,
        68,
        91,
        9,
        59,
        46,
        95,
        55,
        43,
        91,
        86
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        118,
        84,
        61,
        161,
        41,
        1,
        137,
        3,
        109,
        1,
        40,
        84,
        116,
        172,
        32,
        125,
        135,
        199,
        133,
        40,
        1,
        198,
        197,
        195,
        92,
        109,
        174,
        104,
        37,
        103,
        33,
        93,
        1,
        167,
        117,
        44,
        47,
        124,
        157,
        19,
        17,
        86,
        19,
        106,
        167,
        196,
        52,
        89,
        184,
        160,
        76,
        135
      ],
      "weeklyWasted": [
        117,
        44,
        47,
        124,
        157,
        19,
        17,
        86,
        19,
        106,
        167,
        196,
        52,
        89,
        184,
        160,
        76,
        135,
        32,
        1,
        0,
        82,
        89,
        57,
        42,
        29,
        6,
        78,
        83,
        36,
        18,
        66,
        87,
        11,
        98,
        4,
        18,
        73,
        9,
        47,
        36,
        61,
        59,
        54,
        39,
        72,
        29,
        39,
        56,
        97,
        94,
        48,
        58,
        99,
        19,
        99,
        95,
        87,
        53,
        42,
        60,
        76,
        34,
        55,
        49,
        96,
        92,
        83,
        15,
        50
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        147,
        2,
        198,
        87,
        126,
        1,
        194,
        170,
        19,
        59,
        119,
        123,
        151,
        0,
        165,
        98,
        184,
        106,
        170,
        50,
        197,
        119,
        147,
        26,
        119,
        170,
        178,
        197,
        169,
        109,
        24,
        137,
        116,
        175,
        167,
        112,
        80,
        5,
        10,
        0,
        21,
        137,
        0,
        62,
        1,
        142,
        0,
        118,
        172,
        94,
        160,
        114
      ],
      "weeklyWasted": [
        167,
        112,
        80,
        5,
        10,
        0,
        21,
        137,
        0,
        62,
        1,
        142,
        0,
        118,
        172,
        94,
        160,
        114,
        34,
        24,
        31,
        21,
        87,
        10,
        76,
        0,
        81,
        89,
        83,
        73,
        65,
        80,
        38,
        98,
        59,
        99,
        8,
        17,
        34,
        26,
        5,
        51,
        17,
        99,
        32,
        36,
        98,
        10,
        2,
        66,
        54,
        90,
        36,
        30,
        56,
        36,
        99,
        99,
        70,
        0,
        81,
        99,
        23,
        48,
        99,
        98,
        25,
        50,
        47,
        29
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        7,
        107,
        143,
        77,
        75,
        139,
        190,
        121,
        190,
        52,
        199,
        138,
        198,
        175,
        155,
        16,
        135,
        141,
        147,
        72,
        197,
        109,
        46,
        180,
        101,
        70,
        102,
        89,
        101,
        57,
        162,
        78,
        44,
        199,
        88,
        49,
        189,
        5,
        10,
        0,
        21,
        186,
        0,
        20,
        199,
        193,
        195,
        77,
        1,
        161,
        46,
        7
      ],
      "weeklyWasted": [
        88,
        49,
        189,
        5,
        10,
        0,
        21,
        186,
        0,
        20,
        199,
        193,
        195,
        77,
        1,
        161,
        46,
        7,
        81,
        1,
        16,
        6,
        98,
        1,
        99,
        0,
        76,
        19,
        96,
        51,
        37,
        80,
        0,
        36,
        27,
        45,
        33,
        25,
        40,
        0,
        5,
        71,
        0,
        99,
        0,
        32,
        2,
        17,
        77,
        71,
        65,
        72,
        37,
        3,
        27,
        0,
        61,
        38,
        50,
        98,
        99,
        52,
        99,
        23,
        70,
        94,
        69,
        88,
        37,
        26
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        199,
        152,
        40,
        33,
        0,
        145,
        17,
        33,
        2,
        129,
        199,
        120,
        164,
        71,
        127,
        117,
        192,
        1,
        3,
        138,
        22,
        4,
        96,
        195,
        171,
        39,
        87,
        99,
        189,
        149,
        165,
        0,
        128,
        89,
        15,
        0,
        124,
        68,
        171,
        175,
        50,
        124,
        72,
        0,
        31,
        4,
        90,
        107,
        178,
        32,
        18,
        124
      ],
      "weeklyWasted": [
        15,
        0,
        124,
        68,
        171,
        175,
        50,
        124,
        72,
        0,
        31,
        4,
        90,
        107,
        178,
        32,
        18,
        124,
        57,
        23,
        34,
        2,
        73,
        55,
        24,
        60,
        80,
        81,
        99,
        61,
        27,
        95,
        16,
        32,
        39,
        46,
        29,
        3,
        32,
        3,
        36,
        0,
        56,
        87,
        80,
        47,
        36,
        25,
        45,
        63,
        54,
        98,
        32,
        67,
        71,
        67,
        66,
        91,
        0,
        21,
        99,
        92,
        37,
        52,
        59,
        98,
        99,
        3,
        33,
        11
      ]
    },
    {
      "id": "C71B9801",
      "weeklyUsage": [
        181,
        187,
        149,
        50,
        0,
        124,
        97,
        3,
        152,
        1,
        16,
        175,
        189,
        0,
        107,
        67,
        192,
        172,
        57,
        136,
        88,
        123,
        145,
        195,
        42,
        44,
        163,
        48,
        170,
        115,
        68,
        64,
        1,
        182,
        4,
        56,
        102,
        5,
        43,
        51,
        45,
        0,
        100,
        166,
        76,
        176,
        0,
        52,
        198,
        2,
        187,
        3
      ],
      "weeklyWasted": [
        4,
        56,
        102,
        5,
        43,
        51,
        45,
        0,
        100,
        166,
        76,
        176,
        0,
        52,
        198,
        2,
        187,
        3,
        90,
        38,
        97,
        2,
        95,
        38,
        17,
        35,
        85,
        92,
        99,
        86,
        67,
        99,
        70,
        45,
        15,
        81,
        17,
        62,
        67,
        1,
        0,
        0,
        50,
        1,
        98,
        97,
        33,
        75,
        31,
        78,
        28,
        45,
        63,
        68,
        99,
        95,
        99,
        52,
        5,
        62,
        99,
        85,
        99,
        4,
        99,
        88,
        63,
        72,
        34,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        76,
        9,
        175,
        73,
        64,
        169,
        194,
        57,
        2,
        120,
        190,
        2,
        6,
        25,
        54,
        157,
        192,
        62,
        3,
        59,
        1,
        45,
        31,
        61,
        3,
        45,
        169,
        69,
        121,
        98,
        79,
        0,
        1,
        4,
        4,
        142,
        46,
        44,
        199,
        19,
        134,
        133,
        170,
        23,
        185,
        163,
        195,
        99,
        73,
        18,
        2,
        3
      ],
      "weeklyWasted": [
        4,
        142,
        46,
        44,
        199,
        19,
        134,
        133,
        170,
        23,
        185,
        163,
        195,
        99,
        73,
        18,
        2,
        3,
        52,
        12,
        44,
        30,
        57,
        42,
        99,
        6,
        55,
        27,
        99,
        39,
        6,
        86,
        18,
        19,
        66,
        80,
        1,
        33,
        2,
        19,
        67,
        0,
        21,
        99,
        42,
        34,
        58,
        7,
        2,
        41,
        24,
        85,
        35,
        24,
        76,
        75,
        99,
        43,
        6,
        50,
        99,
        29,
        99,
        78,
        24,
        86,
        99,
        86,
        99,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        199,
        121,
        198,
        14,
        72,
        142,
        153,
        30,
        41,
        1,
        180,
        22,
        197,
        77,
        144,
        192,
        192,
        94,
        109,
        88,
        82,
        123,
        145,
        1,
        3,
        41,
        162,
        82,
        142,
        124,
        165,
        126,
        1,
        147,
        103,
        177,
        43,
        74,
        17,
        57,
        0,
        56,
        108,
        0,
        33,
        51,
        150,
        57,
        124,
        2,
        5,
        194
      ],
      "weeklyWasted": [
        103,
        177,
        43,
        74,
        17,
        57,
        0,
        56,
        108,
        0,
        33,
        51,
        150,
        57,
        124,
        2,
        5,
        194,
        1,
        1,
        48,
        3,
        98,
        49,
        72,
        3,
        81,
        0,
        55,
        1,
        55,
        99,
        81,
        98,
        4,
        65,
        75,
        99,
        68,
        41,
        81,
        0,
        2,
        99,
        42,
        90,
        12,
        53,
        62,
        37,
        1,
        98,
        13,
        0,
        85,
        29,
        0,
        31,
        34,
        28,
        68,
        61,
        46,
        38,
        50,
        98,
        99,
        50,
        99,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        7,
        2,
        198,
        195,
        159,
        22,
        182,
        36,
        136,
        63,
        0,
        2,
        26,
        0,
        199,
        123,
        135,
        169,
        94,
        9,
        183,
        127,
        46,
        49,
        197,
        152,
        166,
        61,
        199,
        198,
        106,
        30,
        43,
        199,
        113,
        49,
        148,
        72,
        147,
        91,
        107,
        108,
        104,
        0,
        121,
        96,
        0,
        90,
        198,
        107,
        8,
        3
      ],
      "weeklyWasted": [
        113,
        49,
        148,
        72,
        147,
        91,
        107,
        108,
        104,
        0,
        121,
        96,
        0,
        90,
        198,
        107,
        8,
        3,
        32,
        1,
        27,
        62,
        67,
        43,
        47,
        5,
        63,
        27,
        85,
        99,
        96,
        46,
        14,
        32,
        20,
        67,
        77,
        35,
        7,
        69,
        39,
        6,
        58,
        34,
        46,
        60,
        28,
        63,
        46,
        60,
        1,
        9,
        99,
        44,
        1,
        84,
        50,
        51,
        0,
        24,
        99,
        90,
        90,
        98,
        99,
        17,
        81,
        1,
        52,
        58
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        33,
        66,
        198,
        32,
        67,
        136,
        59,
        87,
        67,
        199,
        77,
        45,
        166,
        0,
        161,
        52,
        192,
        184,
        69,
        107,
        197,
        40,
        101,
        195,
        3,
        1,
        170,
        126,
        98,
        101,
        199,
        157,
        145,
        6,
        166,
        108,
        137,
        67,
        180,
        0,
        63,
        36,
        0,
        62,
        125,
        154,
        16,
        71,
        119,
        199,
        67,
        99
      ],
      "weeklyWasted": [
        166,
        108,
        137,
        67,
        180,
        0,
        63,
        36,
        0,
        62,
        125,
        154,
        16,
        71,
        119,
        199,
        67,
        99,
        85,
        34,
        10,
        41,
        84,
        98,
        60,
        12,
        7,
        72,
        78,
        61,
        44,
        31,
        0,
        1,
        41,
        64,
        50,
        71,
        88,
        63,
        63,
        19,
        49,
        99,
        73,
        31,
        2,
        27,
        5,
        41,
        1,
        75,
        99,
        32,
        49,
        29,
        92,
        74,
        0,
        0,
        94,
        82,
        65,
        99,
        99,
        98,
        99,
        32,
        44,
        32
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        199,
        78,
        78,
        5,
        0,
        92,
        36,
        3,
        115,
        1,
        169,
        2,
        181,
        58,
        199,
        118,
        187,
        123,
        108,
        2,
        15,
        82,
        2,
        12,
        132,
        108,
        181,
        1,
        128,
        0,
        199,
        40,
        39,
        4,
        146,
        20,
        60,
        199,
        112,
        63,
        80,
        41,
        75,
        66,
        1,
        89,
        123,
        137,
        1,
        148,
        148,
        92
      ],
      "weeklyWasted": [
        146,
        20,
        60,
        199,
        112,
        63,
        80,
        41,
        75,
        66,
        1,
        89,
        123,
        137,
        1,
        148,
        148,
        92,
        68,
        17,
        0,
        14,
        59,
        38,
        16,
        8,
        46,
        0,
        84,
        68,
        53,
        99,
        53,
        1,
        8,
        56,
        1,
        99,
        47,
        0,
        7,
        0,
        67,
        61,
        0,
        9,
        93,
        18,
        2,
        74,
        30,
        87,
        99,
        76,
        75,
        26,
        45,
        99,
        0,
        20,
        89,
        99,
        99,
        89,
        51,
        69,
        34,
        92,
        36,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        7,
        69,
        60,
        195,
        8,
        1,
        159,
        73,
        199,
        34,
        35,
        129,
        94,
        0,
        1,
        154,
        64,
        71,
        197,
        81,
        130,
        151,
        114,
        167,
        116,
        75,
        101,
        111,
        199,
        75,
        193,
        59,
        1,
        149,
        109,
        0,
        0,
        5,
        139,
        0,
        117,
        15,
        151,
        0,
        113,
        168,
        0,
        55,
        99,
        148,
        68,
        76
      ],
      "weeklyWasted": [
        109,
        0,
        0,
        5,
        139,
        0,
        117,
        15,
        151,
        0,
        113,
        168,
        0,
        55,
        99,
        148,
        68,
        76,
        38,
        73,
        3,
        50,
        98,
        68,
        99,
        0,
        99,
        82,
        99,
        99,
        70,
        57,
        37,
        61,
        56,
        85,
        9,
        0,
        2,
        39,
        4,
        22,
        0,
        74,
        83,
        4,
        2,
        1,
        30,
        79,
        67,
        98,
        57,
        43,
        40,
        33,
        57,
        94,
        32,
        0,
        78,
        92,
        38,
        27,
        0,
        78,
        41,
        47,
        99,
        1
      ]
    },
    {
      "id": "049BCC88",
      "weeklyUsage": [
        115,
        167,
        187,
        71,
        198,
        108,
        17,
        3,
        71,
        132,
        199,
        56,
        191,
        61,
        199,
        136,
        192,
        199,
        23,
        60,
        137,
        154,
        121,
        17,
        18,
        64,
        22,
        24,
        0,
        131,
        4,
        99,
        172,
        138,
        101,
        50,
        81,
        171,
        129,
        160,
        141,
        168,
        49,
        55,
        1,
        172,
        127,
        105,
        1,
        127,
        142,
        24
      ],
      "weeklyWasted": [
        101,
        50,
        81,
        171,
        129,
        160,
        141,
        168,
        49,
        55,
        1,
        172,
        127,
        105,
        1,
        127,
        142,
        24,
        1,
        1,
        24,
        2,
        57,
        6,
        62,
        49,
        40,
        39,
        99,
        1,
        1,
        99,
        46,
        1,
        26,
        68,
        3,
        90,
        46,
        6,
        47,
        0,
        54,
        75,
        21,
        55,
        54,
        40,
        46,
        97,
        48,
        96,
        53,
        69,
        40,
        69,
        17,
        83,
        25,
        0,
        85,
        99,
        99,
        35,
        85,
        98,
        74,
        75,
        99,
        11
      ]
    },
    {
      "id": "F55885BD",
      "weeklyUsage": [
        172,
        166,
        163,
        59,
        198,
        197,
        194,
        3,
        2,
        1,
        49,
        2,
        185,
        132,
        51,
        76,
        192,
        30,
        12,
        2,
        197,
        49,
        118,
        75,
        3,
        167,
        186,
        1,
        132,
        31,
        199,
        91,
        197,
        38,
        182,
        174,
        173,
        28,
        199,
        64,
        12,
        166,
        176,
        0,
        86,
        163,
        125,
        71,
        152,
        199,
        2,
        3
      ],
      "weeklyWasted": [
        182,
        174,
        173,
        28,
        199,
        64,
        12,
        166,
        176,
        0,
        86,
        163,
        125,
        71,
        152,
        199,
        2,
        3,
        45,
        59,
        41,
        2,
        98,
        1,
        95,
        0,
        90,
        85,
        99,
        1,
        45,
        53,
        26,
        98,
        0,
        39,
        1,
        63,
        99,
        76,
        23,
        30,
        99,
        41,
        98,
        97,
        99,
        4,
        70,
        97,
        45,
        88,
        0,
        25,
        77,
        43,
        50,
        71,
        56,
        0,
        29,
        99,
        3,
        0,
        99,
        98,
        99,
        99,
        44,
        1
      ]
    },
    {
      "id": "7B415249",
      "weeklyUsage": [
        199,
        106,
        0,
        63,
        0,
        177,
        3,
        22,
        2,
        60,
        197,
        24,
        198,
        0,
        113,
        3,
        114,
        76,
        165,
        85,
        1,
        49,
        114,
        195,
        133,
        198,
        138,
        75,
        199,
        32,
        86,
        0,
        56,
        199,
        36,
        94,
        112,
        199,
        41,
        92,
        0,
        109,
        28,
        96,
        12,
        4,
        39,
        0,
        198,
        126,
        120,
        117
      ],
      "weeklyWasted": [
        36,
        94,
        112,
        199,
        41,
        92,
        0,
        109,
        28,
        96,
        12,
        4,
        39,
        0,
        198,
        126,
        120,
        117,
        46,
        24,
        35,
        2,
        66,
        69,
        63,
        0,
        37,
        38,
        53,
        4,
        48,
        99,
        3,
        98,
        14,
        44,
        1,
        30,
        48,
        80,
        26,
        0,
        34,
        27,
        98,
        1,
        78,
        1,
        2,
        66,
        21,
        98,
        53,
        99,
        99,
        0,
        89,
        97,
        13,
        0,
        75,
        99,
        23,
        0,
        0,
        98,
        33,
        95,
        32,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        167,
        142,
        53,
        5,
        8,
        115,
        194,
        46,
        107,
        1,
        0,
        87,
        100,
        43,
        20,
        20,
        182,
        142,
        116,
        53,
        35,
        93,
        182,
        1,
        91,
        38,
        133,
        186,
        199,
        22,
        59,
        0,
        75,
        199,
        143,
        194,
        61,
        14,
        199,
        54,
        83,
        7,
        126,
        2,
        18,
        137,
        11,
        43,
        174,
        10,
        153,
        24
      ],
      "weeklyWasted": [
        143,
        194,
        61,
        14,
        199,
        54,
        83,
        7,
        126,
        2,
        18,
        137,
        11,
        43,
        174,
        10,
        153,
        24,
        1,
        45,
        29,
        4,
        46,
        78,
        83,
        9,
        39,
        14,
        99,
        39,
        32,
        65,
        97,
        98,
        27,
        38,
        40,
        31,
        74,
        0,
        0,
        1,
        0,
        15,
        0,
        30,
        50,
        1,
        12,
        97,
        82,
        98,
        99,
        38,
        87,
        53,
        99,
        47,
        99,
        74,
        82,
        87,
        73,
        48,
        97,
        65,
        0,
        99,
        0,
        25
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        190,
        32,
        0,
        5,
        143,
        1,
        10,
        34,
        132,
        83,
        0,
        39,
        151,
        0,
        152,
        60,
        80,
        199,
        3,
        167,
        10,
        54,
        84,
        156,
        84,
        11,
        166,
        177,
        139,
        131,
        63,
        115,
        25,
        182,
        60,
        135,
        198,
        122,
        180,
        71,
        0,
        27,
        162,
        19,
        36,
        4,
        0,
        198,
        198,
        87,
        110,
        196
      ],
      "weeklyWasted": [
        60,
        135,
        198,
        122,
        180,
        71,
        0,
        27,
        162,
        19,
        36,
        4,
        0,
        198,
        198,
        87,
        110,
        196,
        99,
        21,
        0,
        34,
        44,
        86,
        25,
        0,
        57,
        0,
        40,
        81,
        1,
        63,
        0,
        48,
        56,
        54,
        58,
        22,
        34,
        5,
        54,
        0,
        70,
        72,
        6,
        54,
        34,
        29,
        2,
        32,
        79,
        98,
        70,
        0,
        83,
        0,
        99,
        68,
        99,
        37,
        2,
        68,
        27,
        63,
        99,
        98,
        58,
        1,
        4,
        1
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyUsage": [
        199,
        142,
        104,
        100,
        198,
        109,
        194,
        149,
        149,
        1,
        149,
        2,
        198,
        63,
        59,
        3,
        192,
        151,
        197,
        162,
        164,
        137,
        156,
        37,
        3,
        198,
        28,
        34,
        116,
        116,
        53,
        38,
        115,
        166,
        168,
        0,
        29,
        182,
        76,
        175,
        81,
        9,
        4,
        198,
        1,
        154,
        0,
        163,
        198,
        26,
        2,
        127
      ],
      "weeklyWasted": [
        168,
        0,
        29,
        182,
        76,
        175,
        81,
        9,
        4,
        198,
        1,
        154,
        0,
        163,
        198,
        26,
        2,
        127,
        42,
        2,
        86,
        22,
        65,
        17,
        59,
        31,
        99,
        47,
        69,
        38,
        49,
        99,
        27,
        11,
        0,
        16,
        30,
        14,
        46,
        3,
        4,
        0,
        10,
        42,
        30,
        97,
        41,
        7,
        91,
        79,
        62,
        1,
        94,
        0,
        3,
        82,
        54,
        28,
        89,
        15,
        96,
        85,
        79,
        42,
        99,
        34,
        35,
        99,
        31,
        6
      ]
    },
    {
      "id": "D9345860",
      "weeklyUsage": [
        20,
        172,
        78,
        5,
        107,
        1,
        47,
        60,
        2,
        1,
        0,
        47,
        196,
        66,
        181,
        109,
        118,
        166,
        65,
        2,
        136,
        113,
        6,
        195,
        30,
        82,
        160,
        87,
        77,
        2,
        60,
        107,
        190,
        124,
        198,
        187,
        117,
        52,
        18,
        41,
        199,
        36,
        0,
        0,
        1,
        185,
        0,
        41,
        124,
        186,
        105,
        27
      ],
      "weeklyWasted": [
        198,
        187,
        117,
        52,
        18,
        41,
        199,
        36,
        0,
        0,
        1,
        185,
        0,
        41,
        124,
        186,
        105,
        27,
        1,
        72,
        0,
        9,
        29,
        30,
        71,
        18,
        87,
        37,
        99,
        89,
        26,
        51,
        67,
        98,
        60,
        50,
        25,
        58,
        88,
        24,
        56,
        99,
        98,
        99,
        98,
        69,
        99,
        1,
        2,
        61,
        31,
        98,
        99,
        70,
        9,
        10,
        49,
        70,
        28,
        79,
        67,
        99,
        54,
        40,
        30,
        96,
        65,
        91,
        13,
        39
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        158,
        2,
        151,
        183,
        0,
        1,
        106,
        97,
        199,
        131,
        115,
        29,
        2,
        0,
        140,
        3,
        192,
        129,
        31,
        76,
        73,
        130,
        156,
        195,
        3,
        198,
        132,
        95,
        109,
        115,
        60,
        171,
        1,
        162,
        135,
        199,
        66,
        16,
        47,
        104,
        105,
        63,
        0,
        26,
        70,
        196,
        156,
        92,
        154,
        92,
        2,
        3
      ],
      "weeklyWasted": [
        135,
        199,
        66,
        16,
        47,
        104,
        105,
        63,
        0,
        26,
        70,
        196,
        156,
        92,
        154,
        92,
        2,
        3,
        82,
        26,
        99,
        45,
        63,
        65,
        61,
        43,
        18,
        75,
        54,
        60,
        1,
        90,
        95,
        7,
        69,
        99,
        73,
        21,
        68,
        0,
        0,
        0,
        81,
        71,
        92,
        63,
        64,
        78,
        2,
        90,
        61,
        98,
        99,
        54,
        69,
        34,
        80,
        1,
        8,
        53,
        75,
        72,
        91,
        95,
        0,
        65,
        72,
        49,
        99,
        71
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        106,
        2,
        0,
        21,
        161,
        112,
        28,
        73,
        85,
        1,
        199,
        51,
        137,
        130,
        140,
        3,
        192,
        87,
        3,
        93,
        1,
        150,
        154,
        39,
        103,
        198,
        138,
        88,
        50,
        70,
        142,
        130,
        146,
        32,
        198,
        71,
        133,
        199,
        123,
        149,
        2,
        12,
        0,
        0,
        121,
        189,
        81,
        145,
        198,
        195,
        2,
        149
      ],
      "weeklyWasted": [
        198,
        71,
        133,
        199,
        123,
        149,
        2,
        12,
        0,
        0,
        121,
        189,
        81,
        145,
        198,
        195,
        2,
        149,
        30,
        1,
        74,
        80,
        70,
        35,
        60,
        15,
        80,
        0,
        99,
        99,
        64,
        99,
        42,
        18,
        98,
        54,
        68,
        3,
        79,
        82,
        20,
        0,
        99,
        67,
        69,
        66,
        90,
        26,
        2,
        46,
        79,
        67,
        19,
        0,
        81,
        67,
        99,
        62,
        5,
        12,
        57,
        99,
        94,
        77,
        88,
        23,
        39,
        69,
        75,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyUsage": [
        141,
        2,
        191,
        93,
        51,
        197,
        18,
        141,
        194,
        1,
        71,
        86,
        198,
        0,
        132,
        19,
        182,
        182,
        70,
        26,
        101,
        65,
        47,
        172,
        154,
        1,
        153,
        1,
        125,
        95,
        199,
        76,
        1,
        199,
        50,
        104,
        198,
        39,
        199,
        121,
        122,
        4,
        66,
        93,
        107,
        196,
        69,
        68,
        173,
        137,
        176,
        3
      ],
      "weeklyWasted": [
        50,
        104,
        198,
        39,
        199,
        121,
        122,
        4,
        66,
        93,
        107,
        196,
        69,
        68,
        173,
        137,
        176,
        3,
        99,
        1,
        31,
        49,
        58,
        76,
        23,
        0,
        69,
        54,
        40,
        99,
        46,
        50,
        85,
        2,
        20,
        99,
        1,
        0,
        61,
        16,
        58,
        0,
        13,
        99,
        0,
        48,
        49,
        26,
        2,
        97,
        30,
        72,
        91,
        20,
        80,
        70,
        8,
        86,
        7,
        9,
        72,
        51,
        41,
        51,
        11,
        98,
        50,
        73,
        52,
        10
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        35,
        2,
        33,
        144,
        137,
        197,
        188,
        25,
        138,
        29,
        53,
        49,
        154,
        125,
        104,
        111,
        192,
        178,
        160,
        3,
        170,
        104,
        137,
        49,
        3,
        1,
        87,
        110,
        22,
        115,
        88,
        196,
        115,
        118,
        4,
        0,
        71,
        192,
        47,
        137,
        0,
        0,
        43,
        164,
        10,
        4,
        68,
        156,
        185,
        199,
        2,
        56
      ],
      "weeklyWasted": [
        4,
        0,
        71,
        192,
        47,
        137,
        0,
        0,
        43,
        164,
        10,
        4,
        68,
        156,
        185,
        199,
        2,
        56,
        76,
        58,
        7,
        43,
        44,
        98,
        99,
        18,
        39,
        85,
        33,
        79,
        54,
        77,
        23,
        15,
        65,
        45,
        46,
        0,
        73,
        44,
        1,
        83,
        78,
        99,
        62,
        68,
        58,
        1,
        99,
        92,
        99,
        96,
        29,
        53,
        80,
        84,
        66,
        1,
        46,
        16,
        99,
        99,
        39,
        0,
        99,
        28,
        3,
        86,
        99,
        1
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        199,
        40,
        0,
        53,
        198,
        1,
        181,
        3,
        19,
        13,
        199,
        46,
        129,
        88,
        194,
        147,
        151,
        188,
        132,
        2,
        82,
        4,
        162,
        137,
        3,
        1,
        173,
        30,
        199,
        112,
        12,
        141,
        101,
        172,
        29,
        199,
        52,
        195,
        10,
        199,
        0,
        84,
        98,
        35,
        22,
        77,
        195,
        40,
        198,
        140,
        43,
        144
      ],
      "weeklyWasted": [
        29,
        199,
        52,
        195,
        10,
        199,
        0,
        84,
        98,
        35,
        22,
        77,
        195,
        40,
        198,
        140,
        43,
        144,
        40,
        78,
        41,
        2,
        98,
        1,
        81,
        31,
        14,
        75,
        99,
        49,
        52,
        99,
        66,
        76,
        34,
        75,
        26,
        52,
        46,
        84,
        17,
        59,
        45,
        42,
        42,
        64,
        34,
        14,
        66,
        97,
        99,
        74,
        46,
        9,
        42,
        61,
        0,
        47,
        16,
        15,
        99,
        99,
        81,
        51,
        99,
        89,
        5,
        89,
        54,
        95
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        130,
        2,
        198,
        195,
        95,
        143,
        61,
        183,
        188,
        1,
        72,
        33,
        2,
        161,
        199,
        11,
        192,
        199,
        84,
        162,
        146,
        198,
        87,
        70,
        51,
        184,
        95,
        79,
        155,
        86,
        39,
        5,
        63,
        175,
        28,
        67,
        0,
        41,
        67,
        105,
        22,
        161,
        50,
        190,
        173,
        44,
        0,
        122,
        198,
        35,
        152,
        44
      ],
      "weeklyWasted": [
        28,
        67,
        0,
        41,
        67,
        105,
        22,
        161,
        50,
        190,
        173,
        44,
        0,
        122,
        198,
        35,
        152,
        44,
        76,
        30,
        18,
        2,
        82,
        98,
        0,
        78,
        27,
        33,
        33,
        99,
        98,
        99,
        77,
        1,
        35,
        99,
        17,
        36,
        88,
        26,
        0,
        99,
        0,
        34,
        32,
        59,
        66,
        96,
        16,
        82,
        33,
        78,
        54,
        38,
        99,
        48,
        65,
        99,
        41,
        0,
        99,
        99,
        99,
        49,
        99,
        80,
        49,
        89,
        94,
        30
      ]
    },
    {
      "id": "FB70040E",
      "weeklyUsage": [
        157,
        76,
        93,
        43,
        52,
        135,
        116,
        3,
        199,
        87,
        6,
        37,
        111,
        52,
        127,
        3,
        48,
        63,
        97,
        49,
        179,
        198,
        59,
        195,
        115,
        166,
        177,
        22,
        178,
        118,
        175,
        0,
        57,
        116,
        90,
        95,
        103,
        54,
        10,
        152,
        76,
        46,
        119,
        0,
        65,
        196,
        0,
        15,
        198,
        22,
        108,
        3
      ],
      "weeklyWasted": [
        90,
        95,
        103,
        54,
        10,
        152,
        76,
        46,
        119,
        0,
        65,
        196,
        0,
        15,
        198,
        22,
        108,
        3,
        59,
        55,
        71,
        4,
        65,
        58,
        99,
        15,
        99,
        73,
        69,
        87,
        61,
        84,
        35,
        1,
        50,
        86,
        11,
        63,
        68,
        0,
        0,
        0,
        31,
        1,
        98,
        57,
        99,
        22,
        2,
        97,
        21,
        78,
        89,
        0,
        9,
        96,
        13,
        99,
        9,
        95,
        46,
        99,
        19,
        80,
        61,
        98,
        38,
        32,
        99,
        62
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        123,
        136,
        67,
        5,
        56,
        163,
        50,
        17,
        14,
        56,
        62,
        2,
        198,
        0,
        1,
        141,
        101,
        197,
        54,
        185,
        181,
        4,
        23,
        57,
        163,
        132,
        197,
        44,
        58,
        198,
        110,
        0,
        17,
        178,
        4,
        57,
        50,
        19,
        10,
        59,
        102,
        114,
        0,
        0,
        25,
        142,
        34,
        80,
        182,
        2,
        117,
        196
      ],
      "weeklyWasted": [
        4,
        57,
        50,
        19,
        10,
        59,
        102,
        114,
        0,
        0,
        25,
        142,
        34,
        80,
        182,
        2,
        117,
        196,
        76,
        54,
        59,
        17,
        98,
        77,
        37,
        49,
        34,
        18,
        99,
        10,
        94,
        62,
        79,
        98,
        98,
        83,
        99,
        99,
        74,
        58,
        2,
        0,
        1,
        83,
        79,
        25,
        99,
        33,
        15,
        74,
        40,
        98,
        24,
        26,
        59,
        48,
        43,
        32,
        0,
        18,
        61,
        99,
        99,
        80,
        66,
        78,
        47,
        87,
        55,
        1
      ]
    },
    {
      "id": "F04529EA",
      "weeklyUsage": [
        199,
        63,
        126,
        141,
        91,
        112,
        42,
        147,
        2,
        54,
        74,
        93,
        77,
        0,
        16,
        76,
        192,
        1,
        28,
        11,
        184,
        108,
        104,
        75,
        186,
        198,
        197,
        39,
        105,
        147,
        55,
        84,
        1,
        133,
        73,
        199,
        163,
        5,
        12,
        108,
        199,
        17,
        184,
        36,
        157,
        78,
        0,
        31,
        198,
        199,
        2,
        118
      ],
      "weeklyWasted": [
        73,
        199,
        163,
        5,
        12,
        108,
        199,
        17,
        184,
        36,
        157,
        78,
        0,
        31,
        198,
        199,
        2,
        118,
        14,
        1,
        66,
        2,
        98,
        37,
        0,
        0,
        99,
        0,
        32,
        96,
        99,
        99,
        44,
        69,
        7,
        77,
        67,
        57,
        10,
        46,
        77,
        14,
        99,
        48,
        82,
        15,
        2,
        1,
        2,
        97,
        59,
        55,
        99,
        42,
        43,
        68,
        6,
        99,
        15,
        23,
        13,
        85,
        99,
        11,
        99,
        31,
        99,
        86,
        99,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        199,
        2,
        86,
        82,
        105,
        89,
        117,
        16,
        96,
        103,
        198,
        88,
        127,
        199,
        195,
        3,
        192,
        150,
        3,
        2,
        197,
        179,
        8,
        150,
        173,
        198,
        148,
        1,
        25,
        106,
        0,
        105,
        103,
        100,
        143,
        2,
        135,
        23,
        199,
        0,
        73,
        0,
        41,
        57,
        40,
        147,
        133,
        0,
        177,
        139,
        90,
        79
      ],
      "weeklyWasted": [
        143,
        2,
        135,
        23,
        199,
        0,
        73,
        0,
        41,
        57,
        40,
        147,
        133,
        0,
        177,
        139,
        90,
        79,
        1,
        1,
        42,
        40,
        48,
        27,
        87,
        0,
        45,
        32,
        99,
        99,
        51,
        28,
        52,
        1,
        12,
        41,
        17,
        8,
        3,
        79,
        25,
        78,
        51,
        84,
        17,
        70,
        69,
        41,
        2,
        97,
        1,
        98,
        99,
        13,
        57,
        0,
        35,
        99,
        7,
        13,
        76,
        9,
        65,
        42,
        0,
        98,
        72,
        49,
        62,
        57
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        44,
        65,
        198,
        73,
        109,
        197,
        16,
        41,
        58,
        98,
        51,
        79,
        44,
        0,
        126,
        3,
        192,
        199,
        75,
        6,
        197,
        123,
        2,
        195,
        72,
        146,
        98,
        1,
        73,
        117,
        44,
        0,
        170,
        190,
        59,
        100,
        198,
        48,
        156,
        139,
        0,
        67,
        57,
        25,
        199,
        4,
        131,
        198,
        198,
        157,
        2,
        99
      ],
      "weeklyWasted": [
        59,
        100,
        198,
        48,
        156,
        139,
        0,
        67,
        57,
        25,
        199,
        4,
        131,
        198,
        198,
        157,
        2,
        99,
        14,
        18,
        99,
        56,
        98,
        42,
        18,
        0,
        0,
        32,
        44,
        46,
        52,
        3,
        48,
        38,
        0,
        99,
        15,
        63,
        38,
        99,
        17,
        50,
        99,
        59,
        98,
        12,
        40,
        76,
        2,
        45,
        1,
        98,
        99,
        5,
        39,
        0,
        60,
        92,
        0,
        51,
        69,
        91,
        20,
        65,
        60,
        84,
        88,
        33,
        60,
        27
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        199,
        73,
        0,
        5,
        0,
        197,
        91,
        197,
        62,
        193,
        57,
        2,
        194,
        114,
        174,
        129,
        192,
        105,
        154,
        2,
        197,
        4,
        23,
        114,
        79,
        117,
        84,
        1,
        0,
        26,
        86,
        171,
        170,
        161,
        135,
        73,
        0,
        199,
        199,
        0,
        2,
        7,
        0,
        14,
        46,
        166,
        66,
        198,
        195,
        109,
        112,
        3
      ],
      "weeklyWasted": [
        135,
        73,
        0,
        199,
        199,
        0,
        2,
        7,
        0,
        14,
        46,
        166,
        66,
        198,
        195,
        109,
        112,
        3,
        14,
        3,
        16,
        42,
        23,
        1,
        87,
        0,
        32,
        76,
        68,
        34,
        85,
        59,
        59,
        1,
        28,
        36,
        7,
        60,
        76,
        17,
        63,
        34,
        0,
        99,
        90,
        61,
        99,
        13,
        78,
        9,
        51,
        75,
        35,
        44,
        37,
        64,
        99,
        86,
        12,
        0,
        99,
        99,
        99,
        65,
        55,
        69,
        46,
        40,
        52,
        23
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        98,
        22,
        84,
        117,
        34,
        77,
        26,
        93,
        2,
        193,
        69,
        62,
        87,
        120,
        199,
        81,
        187,
        166,
        3,
        2,
        88,
        142,
        59,
        184,
        115,
        14,
        158,
        38,
        199,
        173,
        76,
        132,
        123,
        128,
        39,
        43,
        122,
        5,
        106,
        61,
        29,
        46,
        24,
        60,
        109,
        11,
        0,
        165,
        184,
        166,
        96,
        123
      ],
      "weeklyWasted": [
        39,
        43,
        122,
        5,
        106,
        61,
        29,
        46,
        24,
        60,
        109,
        11,
        0,
        165,
        184,
        166,
        96,
        123,
        75,
        26,
        43,
        2,
        98,
        11,
        42,
        85,
        99,
        97,
        84,
        54,
        43,
        83,
        45,
        62,
        62,
        69,
        51,
        72,
        2,
        44,
        0,
        7,
        33,
        66,
        76,
        56,
        99,
        82,
        2,
        50,
        99,
        98,
        66,
        75,
        65,
        54,
        65,
        6,
        26,
        52,
        50,
        99,
        79,
        29,
        0,
        98,
        0,
        98,
        99,
        29
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        199,
        84,
        39,
        5,
        136,
        149,
        100,
        132,
        156,
        180,
        28,
        53,
        198,
        0,
        1,
        61,
        192,
        199,
        160,
        99,
        135,
        37,
        8,
        1,
        99,
        25,
        197,
        54,
        131,
        151,
        199,
        107,
        1,
        96,
        198,
        91,
        84,
        199,
        24,
        111,
        139,
        0,
        0,
        24,
        32,
        140,
        0,
        0,
        198,
        2,
        49,
        80
      ],
      "weeklyWasted": [
        198,
        91,
        84,
        199,
        24,
        111,
        139,
        0,
        0,
        24,
        32,
        140,
        0,
        0,
        198,
        2,
        49,
        80,
        43,
        18,
        99,
        13,
        87,
        20,
        50,
        31,
        17,
        81,
        69,
        30,
        50,
        99,
        60,
        22,
        24,
        66,
        60,
        3,
        87,
        0,
        59,
        10,
        60,
        43,
        53,
        51,
        99,
        4,
        2,
        37,
        99,
        69,
        44,
        12,
        74,
        99,
        99,
        1,
        23,
        0,
        86,
        99,
        88,
        52,
        99,
        73,
        99,
        99,
        95,
        24
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        199,
        168,
        30,
        5,
        167,
        101,
        92,
        28,
        2,
        32,
        30,
        26,
        198,
        0,
        165,
        112,
        33,
        1,
        139,
        126,
        167,
        198,
        136,
        5,
        3,
        129,
        197,
        29,
        24,
        77,
        92,
        142,
        21,
        48,
        198,
        42,
        64,
        116,
        122,
        130,
        39,
        111,
        100,
        158,
        1,
        42,
        30,
        145,
        198,
        199,
        117,
        70
      ],
      "weeklyWasted": [
        198,
        42,
        64,
        116,
        122,
        130,
        39,
        111,
        100,
        158,
        1,
        42,
        30,
        145,
        198,
        199,
        117,
        70,
        39,
        1,
        87,
        11,
        66,
        1,
        52,
        39,
        0,
        99,
        19,
        3,
        60,
        98,
        29,
        72,
        25,
        99,
        45,
        31,
        68,
        46,
        28,
        16,
        92,
        35,
        98,
        70,
        99,
        21,
        14,
        27,
        72,
        98,
        95,
        66,
        42,
        88,
        4,
        45,
        0,
        30,
        36,
        79,
        99,
        5,
        9,
        90,
        26,
        84,
        71,
        25
      ]
    },
    {
      "id": "E491B04D",
      "weeklyUsage": [
        19,
        2,
        164,
        86,
        164,
        137,
        194,
        59,
        191,
        1,
        117,
        25,
        101,
        104,
        127,
        142,
        145,
        199,
        3,
        53,
        117,
        4,
        142,
        104,
        122,
        193,
        180,
        41,
        199,
        198,
        71,
        101,
        149,
        103,
        93,
        13,
        198,
        5,
        54,
        40,
        0,
        29,
        140,
        144,
        5,
        55,
        73,
        18,
        145,
        2,
        105,
        60
      ],
      "weeklyWasted": [
        93,
        13,
        198,
        5,
        54,
        40,
        0,
        29,
        140,
        144,
        5,
        55,
        73,
        18,
        145,
        2,
        105,
        60,
        62,
        35,
        53,
        29,
        17,
        19,
        96,
        14,
        26,
        65,
        99,
        86,
        99,
        99,
        97,
        98,
        49,
        77,
        74,
        61,
        53,
        65,
        5,
        8,
        34,
        89,
        77,
        40,
        67,
        1,
        13,
        97,
        33,
        42,
        67,
        0,
        63,
        72,
        43,
        47,
        44,
        3,
        92,
        30,
        15,
        23,
        14,
        66,
        50,
        76,
        99,
        2
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyUsage": [
        199,
        68,
        142,
        67,
        198,
        77,
        194,
        54,
        135,
        30,
        0,
        28,
        156,
        90,
        67,
        95,
        104,
        170,
        3,
        190,
        154,
        65,
        135,
        1,
        3,
        65,
        100,
        24,
        125,
        126,
        63,
        159,
        1,
        80,
        82,
        173,
        198,
        5,
        10,
        26,
        112,
        108,
        120,
        76,
        44,
        185,
        44,
        152,
        198,
        167,
        52,
        12
      ],
      "weeklyWasted": [
        82,
        173,
        198,
        5,
        10,
        26,
        112,
        108,
        120,
        76,
        44,
        185,
        44,
        152,
        198,
        167,
        52,
        12,
        27,
        12,
        39,
        96,
        76,
        51,
        99,
        0,
        0,
        35,
        99,
        99,
        44,
        55,
        65,
        1,
        62,
        50,
        91,
        5,
        99,
        0,
        24,
        0,
        99,
        75,
        33,
        77,
        61,
        1,
        48,
        8,
        48,
        62,
        76,
        57,
        99,
        97,
        99,
        14,
        61,
        57,
        98,
        82,
        99,
        19,
        70,
        72,
        57,
        1,
        2,
        52
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        102,
        199,
        0,
        5,
        81,
        85,
        89,
        72,
        2,
        199,
        199,
        80,
        198,
        199,
        172,
        24,
        192,
        84,
        3,
        2,
        148,
        11,
        95,
        148,
        75,
        147,
        70,
        145,
        32,
        119,
        81,
        0,
        93,
        199,
        4,
        76,
        198,
        170,
        59,
        140,
        0,
        108,
        0,
        34,
        18,
        35,
        73,
        19,
        126,
        199,
        43,
        133
      ],
      "weeklyWasted": [
        4,
        76,
        198,
        170,
        59,
        140,
        0,
        108,
        0,
        34,
        18,
        35,
        73,
        19,
        126,
        199,
        43,
        133,
        1,
        27,
        54,
        2,
        45,
        71,
        82,
        0,
        77,
        99,
        99,
        43,
        52,
        28,
        82,
        48,
        54,
        68,
        1,
        99,
        47,
        99,
        47,
        2,
        37,
        99,
        21,
        28,
        35,
        29,
        33,
        88,
        7,
        87,
        23,
        42,
        4,
        99,
        69,
        65,
        27,
        9,
        99,
        61,
        99,
        0,
        88,
        98,
        30,
        99,
        38,
        1
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        64,
        111,
        198,
        36,
        89,
        89,
        194,
        3,
        173,
        1,
        123,
        25,
        198,
        60,
        84,
        89,
        148,
        199,
        134,
        35,
        76,
        27,
        159,
        195,
        3,
        26,
        6,
        56,
        18,
        198,
        73,
        0,
        143,
        199,
        198,
        18,
        34,
        29,
        46,
        99,
        110,
        68,
        90,
        78,
        42,
        125,
        0,
        198,
        192,
        101,
        2,
        3
      ],
      "weeklyWasted": [
        198,
        18,
        34,
        29,
        46,
        99,
        110,
        68,
        90,
        78,
        42,
        125,
        0,
        198,
        192,
        101,
        2,
        3,
        57,
        29,
        99,
        44,
        55,
        34,
        64,
        13,
        99,
        72,
        33,
        87,
        65,
        85,
        10,
        50,
        98,
        21,
        34,
        23,
        33,
        8,
        38,
        9,
        41,
        99,
        35,
        97,
        49,
        1,
        4,
        97,
        1,
        1,
        35,
        7,
        40,
        93,
        70,
        1,
        99,
        0,
        99,
        99,
        15,
        0,
        99,
        0,
        66,
        99,
        0,
        25
      ]
    },
    {
      "id": "D9345860",
      "weeklyUsage": [
        7,
        28,
        40,
        103,
        32,
        120,
        114,
        3,
        178,
        1,
        162,
        144,
        3,
        56,
        184,
        66,
        192,
        168,
        48,
        128,
        147,
        119,
        185,
        89,
        22,
        66,
        98,
        99,
        199,
        133,
        5,
        192,
        197,
        19,
        86,
        66,
        75,
        5,
        199,
        110,
        88,
        22,
        84,
        18,
        74,
        171,
        2,
        171,
        198,
        125,
        2,
        3
      ],
      "weeklyWasted": [
        86,
        66,
        75,
        5,
        199,
        110,
        88,
        22,
        84,
        18,
        74,
        171,
        2,
        171,
        198,
        125,
        2,
        3,
        73,
        94,
        99,
        2,
        87,
        41,
        89,
        55,
        99,
        39,
        99,
        72,
        1,
        53,
        97,
        37,
        68,
        57,
        12,
        57,
        67,
        99,
        50,
        88,
        50,
        83,
        98,
        76,
        36,
        81,
        28,
        97,
        36,
        98,
        90,
        21,
        70,
        61,
        21,
        88,
        0,
        0,
        59,
        71,
        61,
        73,
        64,
        0,
        51,
        52,
        99,
        96
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        199,
        80,
        168,
        64,
        76,
        150,
        141,
        19,
        92,
        44,
        142,
        113,
        71,
        0,
        1,
        58,
        192,
        137,
        40,
        98,
        58,
        16,
        188,
        195,
        140,
        82,
        53,
        99,
        115,
        126,
        127,
        117,
        95,
        69,
        66,
        199,
        198,
        93,
        10,
        22,
        104,
        8,
        0,
        0,
        41,
        125,
        104,
        63,
        198,
        21,
        84,
        19
      ],
      "weeklyWasted": [
        66,
        199,
        198,
        93,
        10,
        22,
        104,
        8,
        0,
        0,
        41,
        125,
        104,
        63,
        198,
        21,
        84,
        19,
        80,
        49,
        41,
        15,
        72,
        90,
        2,
        52,
        68,
        40,
        48,
        82,
        44,
        60,
        97,
        15,
        98,
        75,
        53,
        0,
        59,
        46,
        33,
        54,
        37,
        56,
        4,
        22,
        23,
        35,
        2,
        16,
        79,
        71,
        49,
        0,
        77,
        87,
        99,
        99,
        0,
        56,
        5,
        60,
        82,
        32,
        44,
        6,
        60,
        31,
        53,
        47
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        22,
        128,
        116,
        33,
        0,
        1,
        18,
        38,
        168,
        84,
        132,
        2,
        198,
        153,
        199,
        74,
        96,
        199,
        76,
        105,
        197,
        114,
        74,
        131,
        99,
        171,
        70,
        115,
        8,
        134,
        161,
        59,
        74,
        61,
        155,
        7,
        0,
        91,
        186,
        75,
        0,
        0,
        0,
        30,
        138,
        91,
        62,
        173,
        174,
        199,
        23,
        3
      ],
      "weeklyWasted": [
        155,
        7,
        0,
        91,
        186,
        75,
        0,
        0,
        0,
        30,
        138,
        91,
        62,
        173,
        174,
        199,
        23,
        3,
        18,
        8,
        23,
        20,
        96,
        34,
        99,
        35,
        99,
        93,
        16,
        99,
        66,
        75,
        0,
        1,
        98,
        61,
        16,
        83,
        43,
        0,
        32,
        30,
        52,
        99,
        18,
        97,
        2,
        1,
        30,
        55,
        81,
        98,
        81,
        16,
        21,
        91,
        74,
        39,
        19,
        37,
        57,
        68,
        99,
        97,
        99,
        79,
        68,
        75,
        27,
        1
      ]
    },
    {
      "id": "FB70040E",
      "weeklyUsage": [
        112,
        44,
        117,
        5,
        68,
        1,
        194,
        3,
        2,
        81,
        199,
        92,
        40,
        199,
        156,
        164,
        192,
        100,
        3,
        53,
        197,
        46,
        110,
        172,
        44,
        126,
        121,
        15,
        16,
        85,
        199,
        56,
        128,
        138,
        73,
        20,
        198,
        74,
        60,
        87,
        174,
        0,
        55,
        111,
        45,
        196,
        79,
        115,
        106,
        101,
        87,
        166
      ],
      "weeklyWasted": [
        73,
        20,
        198,
        74,
        60,
        87,
        174,
        0,
        55,
        111,
        45,
        196,
        79,
        115,
        106,
        101,
        87,
        166,
        8,
        1,
        38,
        18,
        81,
        50,
        81,
        57,
        37,
        48,
        25,
        71,
        99,
        99,
        97,
        17,
        50,
        79,
        61,
        47,
        68,
        42,
        72,
        15,
        69,
        99,
        2,
        97,
        2,
        49,
        2,
        64,
        59,
        89,
        53,
        82,
        92,
        49,
        43,
        35,
        51,
        45,
        16,
        51,
        64,
        0,
        95,
        98,
        98,
        87,
        32,
        99
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        142,
        2,
        51,
        119,
        126,
        107,
        194,
        3,
        66,
        1,
        143,
        32,
        129,
        35,
        199,
        3,
        161,
        114,
        3,
        81,
        131,
        20,
        67,
        112,
        105,
        187,
        71,
        86,
        0,
        144,
        0,
        175,
        99,
        126,
        142,
        45,
        150,
        70,
        71,
        0,
        76,
        0,
        182,
        28,
        1,
        118,
        121,
        198,
        176,
        74,
        9,
        6
      ],
      "weeklyWasted": [
        142,
        45,
        150,
        70,
        71,
        0,
        76,
        0,
        182,
        28,
        1,
        118,
        121,
        198,
        176,
        74,
        9,
        6,
        1,
        54,
        99,
        66,
        82,
        42,
        82,
        0,
        23,
        25,
        46,
        1,
        1,
        38,
        19,
        28,
        57,
        72,
        1,
        84,
        10,
        86,
        8,
        0,
        30,
        57,
        43,
        79,
        23,
        33,
        99,
        41,
        83,
        98,
        48,
        8,
        64,
        56,
        68,
        52,
        98,
        43,
        99,
        61,
        7,
        43,
        60,
        98,
        26,
        76,
        66,
        5
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        39,
        109,
        126,
        5,
        123,
        167,
        194,
        36,
        52,
        1,
        138,
        22,
        166,
        199,
        165,
        3,
        181,
        196,
        123,
        175,
        175,
        4,
        98,
        38,
        112,
        1,
        101,
        145,
        110,
        145,
        58,
        6,
        89,
        119,
        154,
        150,
        92,
        5,
        83,
        199,
        174,
        0,
        0,
        87,
        1,
        147,
        0,
        112,
        198,
        177,
        4,
        125
      ],
      "weeklyWasted": [
        154,
        150,
        92,
        5,
        83,
        199,
        174,
        0,
        0,
        87,
        1,
        147,
        0,
        112,
        198,
        177,
        4,
        125,
        45,
        1,
        33,
        55,
        42,
        40,
        99,
        22,
        99,
        26,
        60,
        81,
        68,
        92,
        0,
        21,
        80,
        55,
        51,
        5,
        76,
        79,
        97,
        67,
        27,
        99,
        37,
        30,
        22,
        10,
        62,
        97,
        90,
        44,
        72,
        31,
        40,
        91,
        99,
        30,
        13,
        0,
        99,
        36,
        92,
        21,
        99,
        98,
        99,
        17,
        85,
        53
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        7,
        95,
        96,
        46,
        58,
        73,
        118,
        52,
        2,
        110,
        199,
        197,
        85,
        118,
        152,
        198,
        98,
        147,
        78,
        192,
        4,
        110,
        57,
        117,
        153,
        1,
        106,
        46,
        0,
        12,
        102,
        81,
        59,
        66,
        4,
        72,
        76,
        125,
        199,
        7,
        2,
        0,
        0,
        28,
        127,
        189,
        0,
        181,
        198,
        134,
        115,
        98
      ],
      "weeklyWasted": [
        4,
        72,
        76,
        125,
        199,
        7,
        2,
        0,
        0,
        28,
        127,
        189,
        0,
        181,
        198,
        134,
        115,
        98,
        1,
        1,
        32,
        18,
        1,
        77,
        48,
        0,
        44,
        24,
        82,
        84,
        56,
        34,
        80,
        19,
        5,
        76,
        21,
        38,
        74,
        35,
        39,
        43,
        30,
        70,
        49,
        97,
        2,
        96,
        18,
        75,
        1,
        70,
        99,
        99,
        70,
        96,
        61,
        1,
        62,
        26,
        99,
        45,
        73,
        75,
        71,
        98,
        99,
        99,
        56,
        25
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        175,
        96,
        0,
        36,
        104,
        122,
        88,
        3,
        81,
        75,
        117,
        43,
        182,
        95,
        168,
        146,
        131,
        199,
        171,
        58,
        72,
        4,
        79,
        83,
        89,
        163,
        197,
        71,
        146,
        85,
        199,
        0,
        37,
        125,
        4,
        199,
        58,
        103,
        50,
        109,
        0,
        0,
        58,
        0,
        12,
        101,
        0,
        67,
        148,
        2,
        185,
        85
      ],
      "weeklyWasted": [
        4,
        199,
        58,
        103,
        50,
        109,
        0,
        0,
        58,
        0,
        12,
        101,
        0,
        67,
        148,
        2,
        185,
        85,
        81,
        1,
        18,
        68,
        98,
        69,
        67,
        40,
        0,
        51,
        50,
        1,
        57,
        85,
        39,
        77,
        25,
        99,
        37,
        99,
        77,
        0,
        64,
        2,
        28,
        1,
        98,
        40,
        92,
        23,
        2,
        51,
        1,
        98,
        0,
        38,
        41,
        81,
        93,
        42,
        0,
        0,
        99,
        98,
        70,
        0,
        78,
        98,
        50,
        72,
        39,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        152,
        2,
        51,
        169,
        0,
        1,
        38,
        88,
        183,
        1,
        102,
        2,
        85,
        20,
        165,
        121,
        107,
        170,
        107,
        69,
        92,
        56,
        2,
        130,
        3,
        42,
        197,
        1,
        119,
        151,
        115,
        94,
        77,
        82,
        198,
        123,
        198,
        39,
        178,
        116,
        49,
        14,
        0,
        69,
        88,
        144,
        65,
        52,
        198,
        2,
        187,
        3
      ],
      "weeklyWasted": [
        198,
        123,
        198,
        39,
        178,
        116,
        49,
        14,
        0,
        69,
        88,
        144,
        65,
        52,
        198,
        2,
        187,
        3,
        11,
        14,
        0,
        2,
        98,
        73,
        56,
        99,
        71,
        0,
        99,
        81,
        48,
        74,
        61,
        98,
        34,
        69,
        7,
        59,
        71,
        5,
        32,
        20,
        2,
        1,
        0,
        67,
        86,
        22,
        13,
        80,
        1,
        68,
        99,
        18,
        88,
        99,
        97,
        55,
        0,
        56,
        46,
        46,
        69,
        65,
        88,
        98,
        46,
        34,
        70,
        71
      ]
    },
    {
      "id": "D9345860",
      "weeklyUsage": [
        147,
        68,
        158,
        143,
        0,
        197,
        169,
        72,
        59,
        1,
        0,
        51,
        178,
        104,
        184,
        66,
        192,
        89,
        88,
        13,
        102,
        43,
        197,
        47,
        3,
        91,
        87,
        55,
        199,
        135,
        124,
        0,
        72,
        62,
        26,
        186,
        198,
        87,
        115,
        27,
        54,
        51,
        118,
        87,
        28,
        118,
        190,
        198,
        198,
        48,
        197,
        12
      ],
      "weeklyWasted": [
        26,
        186,
        198,
        87,
        115,
        27,
        54,
        51,
        118,
        87,
        28,
        118,
        190,
        198,
        198,
        48,
        197,
        12,
        51,
        43,
        0,
        20,
        98,
        46,
        58,
        38,
        59,
        0,
        99,
        97,
        26,
        3,
        97,
        98,
        98,
        99,
        60,
        83,
        35,
        15,
        27,
        0,
        13,
        12,
        98,
        45,
        99,
        75,
        2,
        80,
        33,
        94,
        34,
        41,
        73,
        48,
        92,
        99,
        0,
        0,
        27,
        99,
        37,
        65,
        79,
        98,
        0,
        67,
        15,
        30
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        7,
        2,
        192,
        12,
        47,
        125,
        28,
        3,
        199,
        47,
        118,
        41,
        2,
        158,
        105,
        133,
        192,
        199,
        105,
        92,
        197,
        144,
        102,
        168,
        112,
        29,
        155,
        11,
        32,
        0,
        34,
        0,
        197,
        158,
        133,
        154,
        167,
        129,
        10,
        25,
        125,
        50,
        72,
        18,
        43,
        105,
        138,
        118,
        198,
        5,
        33,
        3
      ],
      "weeklyWasted": [
        133,
        154,
        167,
        129,
        10,
        25,
        125,
        50,
        72,
        18,
        43,
        105,
        138,
        118,
        198,
        5,
        33,
        3,
        79,
        50,
        78,
        52,
        0,
        70,
        99,
        28,
        30,
        0,
        32,
        81,
        14,
        68,
        93,
        23,
        98,
        80,
        20,
        16,
        13,
        51,
        0,
        9,
        16,
        43,
        88,
        62,
        63,
        43,
        2,
        68,
        33,
        61,
        89,
        0,
        34,
        39,
        0,
        35,
        0,
        89,
        81,
        26,
        56,
        50,
        0,
        58,
        8,
        39,
        82,
        68
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        120,
        93,
        198,
        61,
        0,
        59,
        194,
        103,
        33,
        1,
        199,
        70,
        198,
        0,
        199,
        101,
        35,
        186,
        71,
        163,
        173,
        198,
        124,
        89,
        3,
        28,
        138,
        159,
        199,
        95,
        40,
        0,
        56,
        144,
        4,
        0,
        198,
        120,
        170,
        115,
        199,
        111,
        0,
        198,
        103,
        196,
        68,
        180,
        152,
        153,
        165,
        3
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        120,
        170,
        115,
        199,
        111,
        0,
        198,
        103,
        196,
        68,
        180,
        152,
        153,
        165,
        3,
        47,
        1,
        43,
        2,
        42,
        8,
        67,
        40,
        19,
        0,
        99,
        69,
        73,
        30,
        97,
        2,
        0,
        64,
        13,
        29,
        61,
        19,
        80,
        19,
        0,
        59,
        32,
        46,
        98,
        42,
        18,
        81,
        14,
        87,
        99,
        43,
        94,
        55,
        25,
        69,
        23,
        1,
        61,
        77,
        61,
        81,
        99,
        98,
        95,
        99,
        13,
        13
      ]
    },
    {
      "id": "4FF22342",
      "weeklyUsage": [
        199,
        101,
        198,
        135,
        113,
        197,
        194,
        121,
        59,
        38,
        45,
        46,
        107,
        24,
        61,
        97,
        192,
        131,
        134,
        66,
        143,
        4,
        169,
        107,
        3,
        15,
        180,
        49,
        171,
        103,
        132,
        13,
        1,
        121,
        157,
        20,
        133,
        64,
        113,
        0,
        0,
        86,
        0,
        0,
        23,
        36,
        147,
        90,
        198,
        28,
        2,
        3
      ],
      "weeklyWasted": [
        157,
        20,
        133,
        64,
        113,
        0,
        0,
        86,
        0,
        0,
        23,
        36,
        147,
        90,
        198,
        28,
        2,
        3,
        1,
        31,
        38,
        2,
        91,
        35,
        34,
        0,
        67,
        51,
        65,
        21,
        24,
        26,
        97,
        98,
        0,
        78,
        52,
        66,
        77,
        45,
        69,
        9,
        0,
        3,
        39,
        60,
        99,
        19,
        2,
        94,
        49,
        92,
        35,
        40,
        99,
        36,
        99,
        40,
        48,
        0,
        96,
        94,
        67,
        63,
        70,
        94,
        45,
        63,
        0,
        2
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        173,
        77,
        115,
        5,
        0,
        1,
        91,
        3,
        199,
        68,
        199,
        2,
        149,
        0,
        95,
        198,
        192,
        122,
        3,
        66,
        108,
        41,
        48,
        116,
        3,
        45,
        119,
        30,
        161,
        198,
        0,
        196,
        140,
        136,
        36,
        199,
        61,
        34,
        199,
        138,
        0,
        56,
        9,
        52,
        64,
        196,
        148,
        59,
        196,
        143,
        2,
        3
      ],
      "weeklyWasted": [
        36,
        199,
        61,
        34,
        199,
        138,
        0,
        56,
        9,
        52,
        64,
        196,
        148,
        59,
        196,
        143,
        2,
        3,
        45,
        15,
        43,
        33,
        16,
        47,
        83,
        0,
        99,
        44,
        19,
        9,
        80,
        99,
        76,
        4,
        42,
        84,
        14,
        69,
        99,
        41,
        97,
        27,
        99,
        64,
        2,
        97,
        30,
        35,
        26,
        34,
        66,
        83,
        62,
        16,
        1,
        49,
        16,
        43,
        17,
        0,
        42,
        75,
        72,
        67,
        99,
        98,
        99,
        59,
        33,
        59
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        173,
        59,
        61,
        109,
        120,
        197,
        194,
        166,
        66,
        1,
        75,
        152,
        196,
        0,
        184,
        3,
        75,
        199,
        171,
        2,
        197,
        28,
        148,
        87,
        81,
        58,
        108,
        15,
        131,
        92,
        31,
        103,
        139,
        173,
        78,
        9,
        159,
        199,
        18,
        39,
        0,
        168,
        0,
        54,
        99,
        137,
        170,
        198,
        198,
        81,
        190,
        3
      ],
      "weeklyWasted": [
        78,
        9,
        159,
        199,
        18,
        39,
        0,
        168,
        0,
        54,
        99,
        137,
        170,
        198,
        198,
        81,
        190,
        3,
        41,
        30,
        0,
        34,
        98,
        39,
        63,
        0,
        14,
        33,
        99,
        99,
        75,
        15,
        65,
        35,
        2,
        51,
        9,
        29,
        65,
        0,
        43,
        53,
        18,
        99,
        44,
        43,
        80,
        54,
        2,
        51,
        35,
        47,
        85,
        0,
        48,
        38,
        75,
        50,
        99,
        75,
        78,
        80,
        78,
        37,
        64,
        98,
        99,
        90,
        23,
        28
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyUsage": [
        92,
        85,
        95,
        5,
        27,
        51,
        91,
        197,
        2,
        1,
        194,
        110,
        187,
        39,
        16,
        79,
        163,
        62,
        39,
        104,
        130,
        113,
        47,
        1,
        89,
        74,
        7,
        36,
        0,
        144,
        113,
        138,
        21,
        4,
        119,
        199,
        144,
        69,
        132,
        0,
        23,
        165,
        0,
        132,
        167,
        123,
        91,
        72,
        198,
        164,
        96,
        75
      ],
      "weeklyWasted": [
        119,
        199,
        144,
        69,
        132,
        0,
        23,
        165,
        0,
        132,
        167,
        123,
        91,
        72,
        198,
        164,
        96,
        75,
        72,
        1,
        88,
        28,
        92,
        7,
        85,
        42,
        86,
        0,
        83,
        1,
        55,
        50,
        46,
        52,
        58,
        41,
        19,
        64,
        55,
        0,
        0,
        74,
        55,
        99,
        98,
        58,
        2,
        1,
        2,
        62,
        34,
        43,
        99,
        25,
        84,
        0,
        63,
        81,
        75,
        0,
        99,
        78,
        99,
        25,
        5,
        45,
        99,
        99,
        63,
        1
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        134,
        61,
        154,
        195,
        43,
        141,
        157,
        189,
        113,
        1,
        120,
        34,
        40,
        0,
        37,
        51,
        192,
        157,
        101,
        197,
        164,
        153,
        117,
        147,
        3,
        125,
        144,
        1,
        160,
        198,
        71,
        0,
        23,
        198,
        87,
        49,
        101,
        32,
        15,
        0,
        199,
        199,
        96,
        189,
        3,
        29,
        0,
        0,
        134,
        64,
        35,
        3
      ],
      "weeklyWasted": [
        87,
        49,
        101,
        32,
        15,
        0,
        199,
        199,
        96,
        189,
        3,
        29,
        0,
        0,
        134,
        64,
        35,
        3,
        23,
        3,
        93,
        2,
        98,
        1,
        70,
        99,
        0,
        56,
        38,
        1,
        72,
        99,
        76,
        1,
        39,
        25,
        43,
        67,
        65,
        0,
        73,
        99,
        59,
        28,
        98,
        23,
        99,
        28,
        29,
        97,
        47,
        89,
        99,
        79,
        75,
        47,
        99,
        99,
        34,
        16,
        95,
        99,
        76,
        71,
        99,
        98,
        99,
        90,
        99,
        99
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        117,
        41,
        68,
        89,
        0,
        165,
        130,
        163,
        39,
        144,
        142,
        2,
        105,
        86,
        199,
        138,
        115,
        83,
        56,
        176,
        192,
        64,
        121,
        194,
        125,
        198,
        197,
        97,
        166,
        198,
        129,
        84,
        156,
        76,
        4,
        81,
        198,
        5,
        149,
        76,
        0,
        177,
        5,
        37,
        79,
        124,
        0,
        28,
        198,
        199,
        164,
        95
      ],
      "weeklyWasted": [
        4,
        81,
        198,
        5,
        149,
        76,
        0,
        177,
        5,
        37,
        79,
        124,
        0,
        28,
        198,
        199,
        164,
        95,
        99,
        24,
        64,
        2,
        98,
        64,
        34,
        43,
        69,
        51,
        46,
        51,
        99,
        71,
        0,
        6,
        0,
        99,
        73,
        71,
        99,
        65,
        71,
        83,
        33,
        75,
        98,
        18,
        94,
        24,
        5,
        33,
        58,
        86,
        28,
        36,
        79,
        0,
        31,
        8,
        20,
        0,
        99,
        74,
        37,
        0,
        26,
        51,
        51,
        67,
        99,
        1
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        138,
        121,
        0,
        45,
        76,
        94,
        105,
        85,
        54,
        12,
        199,
        33,
        198,
        0,
        199,
        128,
        0,
        192,
        3,
        89,
        1,
        4,
        2,
        130,
        3,
        20,
        159,
        84,
        199,
        133,
        179,
        35,
        67,
        173,
        4,
        0,
        128,
        5,
        192,
        35,
        0,
        199,
        0,
        22,
        1,
        4,
        53,
        93,
        11,
        160,
        59,
        192
      ],
      "weeklyWasted": [
        4,
        0,
        128,
        5,
        192,
        35,
        0,
        199,
        0,
        22,
        1,
        4,
        53,
        93,
        11,
        160,
        59,
        192,
        17,
        7,
        1,
        49,
        98,
        65,
        99,
        0,
        0,
        82,
        99,
        99,
        92,
        3,
        11,
        59,
        98,
        72,
        72,
        61,
        2,
        0,
        59,
        50,
        41,
        5,
        77,
        53,
        82,
        18,
        34,
        60,
        79,
        32,
        99,
        55,
        13,
        49,
        61,
        1,
        0,
        0,
        99,
        76,
        0,
        49,
        99,
        98,
        30,
        1,
        19,
        13
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        199,
        124,
        129,
        81,
        74,
        160,
        6,
        3,
        189,
        125,
        199,
        22,
        27,
        0,
        46,
        82,
        61,
        132,
        58,
        94,
        64,
        4,
        2,
        183,
        143,
        128,
        82,
        1,
        112,
        101,
        199,
        0,
        40,
        142,
        4,
        16,
        198,
        35,
        91,
        80,
        69,
        185,
        185,
        151,
        38,
        95,
        13,
        146,
        192,
        71,
        24,
        130
      ],
      "weeklyWasted": [
        4,
        16,
        198,
        35,
        91,
        80,
        69,
        185,
        185,
        151,
        38,
        95,
        13,
        146,
        192,
        71,
        24,
        130,
        99,
        76,
        0,
        2,
        77,
        97,
        28,
        0,
        28,
        84,
        54,
        38,
        5,
        36,
        48,
        41,
        98,
        64,
        35,
        83,
        44,
        40,
        0,
        0,
        21,
        34,
        98,
        30,
        99,
        8,
        2,
        32,
        43,
        74,
        0,
        68,
        82,
        42,
        15,
        43,
        80,
        55,
        85,
        99,
        86,
        81,
        0,
        98,
        67,
        41,
        29,
        19
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        161,
        78,
        198,
        5,
        198,
        102,
        22,
        3,
        117,
        18,
        172,
        16,
        166,
        199,
        187,
        149,
        190,
        44,
        121,
        2,
        197,
        105,
        197,
        89,
        160,
        116,
        164,
        31,
        6,
        198,
        0,
        135,
        1,
        174,
        167,
        34,
        35,
        199,
        116,
        126,
        7,
        43,
        34,
        85,
        5,
        35,
        168,
        17,
        198,
        136,
        87,
        128
      ],
      "weeklyWasted": [
        167,
        34,
        35,
        199,
        116,
        126,
        7,
        43,
        34,
        85,
        5,
        35,
        168,
        17,
        198,
        136,
        87,
        128,
        30,
        56,
        0,
        8,
        20,
        45,
        99,
        0,
        99,
        99,
        79,
        7,
        15,
        71,
        53,
        25,
        60,
        26,
        13,
        28,
        42,
        62,
        90,
        36,
        74,
        78,
        86,
        58,
        99,
        54,
        2,
        41,
        25,
        98,
        60,
        0,
        18,
        30,
        0,
        68,
        0,
        11,
        99,
        77,
        54,
        70,
        99,
        98,
        73,
        41,
        55,
        44
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        185,
        94,
        152,
        75,
        0,
        126,
        151,
        88,
        50,
        24,
        48,
        112,
        149,
        192,
        160,
        3,
        0,
        189,
        143,
        188,
        197,
        116,
        82,
        27,
        3,
        139,
        170,
        127,
        111,
        156,
        96,
        196,
        1,
        199,
        115,
        125,
        198,
        95,
        66,
        60,
        86,
        0,
        5,
        0,
        98,
        196,
        0,
        41,
        198,
        140,
        173,
        182
      ],
      "weeklyWasted": [
        115,
        125,
        198,
        95,
        66,
        60,
        86,
        0,
        5,
        0,
        98,
        196,
        0,
        41,
        198,
        140,
        173,
        182,
        82,
        43,
        32,
        37,
        40,
        73,
        48,
        12,
        75,
        72,
        79,
        67,
        21,
        95,
        97,
        76,
        2,
        48,
        70,
        78,
        63,
        35,
        97,
        0,
        60,
        99,
        75,
        82,
        83,
        42,
        36,
        80,
        37,
        98,
        99,
        0,
        4,
        75,
        50,
        62,
        88,
        51,
        24,
        83,
        79,
        40,
        59,
        98,
        36,
        63,
        7,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        49,
        68,
        100,
        58,
        151,
        39,
        189,
        129,
        5,
        14,
        67,
        96,
        179,
        30,
        158,
        76,
        192,
        199,
        48,
        27,
        189,
        27,
        2,
        134,
        3,
        169,
        192,
        56,
        0,
        96,
        13,
        165,
        1,
        4,
        42,
        137,
        198,
        43,
        40,
        62,
        52,
        31,
        0,
        104,
        1,
        150,
        87,
        198,
        198,
        152,
        194,
        3
      ],
      "weeklyWasted": [
        42,
        137,
        198,
        43,
        40,
        62,
        52,
        31,
        0,
        104,
        1,
        150,
        87,
        198,
        198,
        152,
        194,
        3,
        28,
        1,
        0,
        50,
        89,
        1,
        52,
        0,
        2,
        0,
        99,
        23,
        1,
        3,
        19,
        6,
        0,
        54,
        6,
        98,
        41,
        72,
        67,
        54,
        49,
        99,
        15,
        81,
        53,
        7,
        5,
        97,
        1,
        98,
        99,
        0,
        67,
        50,
        99,
        99,
        32,
        7,
        47,
        88,
        99,
        22,
        99,
        68,
        99,
        37,
        99,
        99
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        73,
        2,
        76,
        5,
        0,
        1,
        194,
        14,
        73,
        1,
        89,
        137,
        109,
        109,
        175,
        54,
        101,
        171,
        31,
        116,
        161,
        136,
        180,
        125,
        61,
        105,
        170,
        116,
        75,
        198,
        85,
        59,
        1,
        68,
        169,
        13,
        198,
        79,
        66,
        156,
        191,
        11,
        4,
        9,
        24,
        179,
        33,
        175,
        198,
        162,
        70,
        3
      ],
      "weeklyWasted": [
        169,
        13,
        198,
        79,
        66,
        156,
        191,
        11,
        4,
        9,
        24,
        179,
        33,
        175,
        198,
        162,
        70,
        3,
        1,
        1,
        99,
        96,
        75,
        22,
        58,
        99,
        15,
        0,
        65,
        29,
        72,
        96,
        0,
        66,
        66,
        63,
        71,
        86,
        32,
        73,
        38,
        4,
        85,
        51,
        98,
        2,
        2,
        20,
        49,
        3,
        64,
        88,
        88,
        27,
        49,
        99,
        12,
        1,
        21,
        0,
        0,
        79,
        10,
        99,
        99,
        32,
        53,
        88,
        77,
        3
      ]
    },
    {
      "id": "067037B3",
      "weeklyUsage": [
        160,
        2,
        49,
        125,
        4,
        40,
        194,
        36,
        90,
        1,
        32,
        2,
        41,
        0,
        188,
        19,
        125,
        67,
        6,
        113,
        108,
        198,
        42,
        36,
        103,
        125,
        138,
        22,
        142,
        189,
        33,
        65,
        1,
        72,
        174,
        199,
        0,
        51,
        75,
        2,
        199,
        7,
        199,
        63,
        113,
        122,
        15,
        93,
        198,
        126,
        136,
        61
      ],
      "weeklyWasted": [
        174,
        199,
        0,
        51,
        75,
        2,
        199,
        7,
        199,
        63,
        113,
        122,
        15,
        93,
        198,
        126,
        136,
        61,
        1,
        2,
        14,
        60,
        98,
        67,
        99,
        25,
        34,
        0,
        66,
        39,
        71,
        99,
        39,
        82,
        0,
        1,
        86,
        0,
        88,
        24,
        0,
        0,
        99,
        7,
        77,
        5,
        99,
        16,
        46,
        97,
        25,
        89,
        99,
        76,
        72,
        72,
        67,
        52,
        37,
        14,
        34,
        74,
        75,
        38,
        99,
        60,
        63,
        99,
        94,
        80
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        199,
        2,
        0,
        169,
        118,
        163,
        138,
        117,
        2,
        123,
        0,
        72,
        198,
        0,
        199,
        75,
        192,
        1,
        197,
        95,
        121,
        4,
        33,
        96,
        38,
        198,
        188,
        40,
        126,
        128,
        28,
        84,
        133,
        165,
        198,
        169,
        69,
        5,
        64,
        21,
        0,
        199,
        76,
        51,
        199,
        166,
        7,
        26,
        188,
        86,
        2,
        9
      ],
      "weeklyWasted": [
        198,
        169,
        69,
        5,
        64,
        21,
        0,
        199,
        76,
        51,
        199,
        166,
        7,
        26,
        188,
        86,
        2,
        9,
        3,
        79,
        32,
        40,
        32,
        1,
        76,
        0,
        99,
        48,
        25,
        53,
        74,
        99,
        43,
        1,
        33,
        14,
        38,
        33,
        87,
        0,
        97,
        4,
        91,
        10,
        98,
        26,
        99,
        36,
        2,
        50,
        60,
        98,
        17,
        47,
        44,
        0,
        64,
        77,
        30,
        60,
        88,
        58,
        35,
        47,
        53,
        86,
        99,
        75,
        54,
        26
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        156,
        132,
        0,
        195,
        60,
        80,
        160,
        94,
        190,
        1,
        60,
        46,
        117,
        101,
        151,
        198,
        157,
        10,
        197,
        161,
        75,
        34,
        136,
        175,
        80,
        93,
        168,
        84,
        199,
        98,
        88,
        91,
        114,
        152,
        114,
        127,
        56,
        199,
        199,
        62,
        82,
        172,
        0,
        17,
        6,
        79,
        100,
        39,
        181,
        126,
        80,
        66
      ],
      "weeklyWasted": [
        114,
        127,
        56,
        199,
        199,
        62,
        82,
        172,
        0,
        17,
        6,
        79,
        100,
        39,
        181,
        126,
        80,
        66,
        38,
        72,
        19,
        2,
        95,
        1,
        85,
        29,
        77,
        72,
        99,
        54,
        99,
        99,
        97,
        98,
        8,
        36,
        7,
        0,
        94,
        27,
        48,
        80,
        0,
        56,
        73,
        74,
        87,
        6,
        30,
        74,
        99,
        69,
        87,
        24,
        62,
        99,
        21,
        99,
        0,
        0,
        78,
        57,
        88,
        18,
        21,
        98,
        32,
        99,
        82,
        11
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        109,
        28,
        0,
        67,
        129,
        104,
        113,
        97,
        145,
        133,
        199,
        197,
        181,
        50,
        36,
        127,
        33,
        50,
        67,
        58,
        44,
        4,
        64,
        68,
        83,
        111,
        155,
        112,
        125,
        40,
        150,
        112,
        136,
        199,
        198,
        0,
        43,
        5,
        48,
        0,
        76,
        0,
        118,
        95,
        15,
        4,
        0,
        166,
        186,
        50,
        2,
        3
      ],
      "weeklyWasted": [
        198,
        0,
        43,
        5,
        48,
        0,
        76,
        0,
        118,
        95,
        15,
        4,
        0,
        166,
        186,
        50,
        2,
        3,
        1,
        13,
        0,
        36,
        59,
        47,
        45,
        24,
        72,
        85,
        0,
        55,
        64,
        49,
        66,
        36,
        75,
        87,
        58,
        19,
        31,
        35,
        0,
        99,
        23,
        51,
        72,
        45,
        78,
        1,
        2,
        69,
        40,
        69,
        88,
        1,
        82,
        76,
        72,
        55,
        73,
        25,
        42,
        42,
        99,
        0,
        3,
        24,
        50,
        99,
        55,
        67
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        124,
        2,
        93,
        63,
        106,
        197,
        137,
        153,
        2,
        1,
        90,
        147,
        113,
        0,
        163,
        103,
        0,
        121,
        100,
        197,
        1,
        138,
        2,
        176,
        41,
        142,
        176,
        36,
        199,
        63,
        92,
        0,
        2,
        4,
        97,
        0,
        0,
        79,
        10,
        106,
        86,
        0,
        29,
        130,
        1,
        175,
        17,
        0,
        115,
        199,
        197,
        176
      ],
      "weeklyWasted": [
        97,
        0,
        0,
        79,
        10,
        106,
        86,
        0,
        29,
        130,
        1,
        175,
        17,
        0,
        115,
        199,
        197,
        176,
        29,
        1,
        98,
        2,
        94,
        1,
        63,
        0,
        96,
        84,
        99,
        27,
        99,
        99,
        37,
        30,
        14,
        71,
        99,
        75,
        50,
        0,
        23,
        63,
        38,
        75,
        98,
        25,
        2,
        45,
        2,
        65,
        8,
        47,
        0,
        58,
        38,
        0,
        68,
        1,
        32,
        0,
        99,
        99,
        50,
        79,
        99,
        45,
        19,
        99,
        64,
        1
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        174,
        65,
        69,
        53,
        196,
        152,
        194,
        48,
        188,
        1,
        137,
        47,
        40,
        0,
        199,
        3,
        140,
        38,
        163,
        78,
        197,
        198,
        137,
        37,
        3,
        198,
        159,
        96,
        199,
        135,
        99,
        0,
        98,
        48,
        4,
        104,
        13,
        48,
        10,
        0,
        80,
        0,
        40,
        0,
        123,
        100,
        99,
        186,
        135,
        140,
        72,
        37
      ],
      "weeklyWasted": [
        4,
        104,
        13,
        48,
        10,
        0,
        80,
        0,
        40,
        0,
        123,
        100,
        99,
        186,
        135,
        140,
        72,
        37,
        32,
        1,
        49,
        9,
        69,
        20,
        70,
        0,
        18,
        0,
        0,
        82,
        43,
        82,
        67,
        30,
        14,
        48,
        1,
        24,
        84,
        16,
        21,
        82,
        43,
        18,
        41,
        75,
        99,
        69,
        2,
        27,
        43,
        52,
        57,
        99,
        79,
        96,
        72,
        76,
        59,
        9,
        79,
        80,
        67,
        34,
        99,
        69,
        94,
        85,
        0,
        90
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        154,
        2,
        198,
        5,
        198,
        1,
        131,
        12,
        199,
        146,
        118,
        129,
        143,
        199,
        131,
        30,
        73,
        138,
        135,
        195,
        161,
        97,
        77,
        7,
        126,
        139,
        149,
        183,
        153,
        113,
        53,
        92,
        31,
        97,
        50,
        47,
        198,
        142,
        44,
        112,
        0,
        67,
        68,
        14,
        1,
        10,
        0,
        39,
        198,
        135,
        197,
        147
      ],
      "weeklyWasted": [
        50,
        47,
        198,
        142,
        44,
        112,
        0,
        67,
        68,
        14,
        1,
        10,
        0,
        39,
        198,
        135,
        197,
        147,
        1,
        2,
        50,
        2,
        67,
        73,
        21,
        0,
        99,
        20,
        0,
        87,
        59,
        50,
        54,
        76,
        58,
        65,
        1,
        4,
        70,
        0,
        32,
        56,
        0,
        90,
        84,
        57,
        12,
        1,
        25,
        86,
        14,
        98,
        99,
        40,
        70,
        99,
        47,
        99,
        7,
        0,
        66,
        44,
        77,
        93,
        82,
        96,
        66,
        66,
        25,
        9
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        69,
        5,
        140,
        23,
        133,
        2,
        156,
        66,
        15,
        20,
        126,
        22,
        90,
        120,
        122,
        3,
        0,
        175,
        127,
        197,
        83,
        75,
        136,
        1,
        197,
        4,
        169,
        1,
        75,
        29,
        104,
        91,
        29,
        174,
        109,
        0,
        122,
        16,
        126,
        199,
        149,
        0,
        39,
        103,
        1,
        4,
        28,
        76,
        198,
        95,
        10,
        177
      ],
      "weeklyWasted": [
        109,
        0,
        122,
        16,
        126,
        199,
        149,
        0,
        39,
        103,
        1,
        4,
        28,
        76,
        198,
        95,
        10,
        177,
        1,
        1,
        44,
        2,
        78,
        58,
        38,
        29,
        23,
        40,
        76,
        99,
        63,
        64,
        11,
        83,
        71,
        79,
        99,
        79,
        7,
        59,
        0,
        0,
        95,
        72,
        37,
        51,
        2,
        25,
        2,
        61,
        87,
        43,
        78,
        52,
        99,
        99,
        31,
        43,
        6,
        0,
        78,
        99,
        99,
        20,
        99,
        55,
        23,
        93,
        81,
        7
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        177,
        74,
        56,
        81,
        57,
        47,
        12,
        197,
        136,
        55,
        46,
        44,
        198,
        98,
        199,
        3,
        192,
        125,
        39,
        2,
        36,
        71,
        61,
        195,
        138,
        74,
        197,
        176,
        134,
        57,
        102,
        137,
        119,
        4,
        100,
        73,
        0,
        174,
        12,
        43,
        66,
        59,
        53,
        0,
        28,
        28,
        46,
        66,
        93,
        199,
        2,
        93
      ],
      "weeklyWasted": [
        100,
        73,
        0,
        174,
        12,
        43,
        66,
        59,
        53,
        0,
        28,
        28,
        46,
        66,
        93,
        199,
        2,
        93,
        1,
        29,
        1,
        22,
        98,
        98,
        67,
        0,
        29,
        83,
        67,
        85,
        66,
        94,
        82,
        57,
        55,
        56,
        1,
        14,
        81,
        0,
        97,
        33,
        60,
        99,
        16,
        54,
        75,
        11,
        2,
        97,
        94,
        98,
        6,
        84,
        99,
        0,
        28,
        17,
        0,
        0,
        74,
        28,
        99,
        99,
        92,
        98,
        33,
        1,
        32,
        38
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        187,
        79,
        0,
        11,
        106,
        90,
        3,
        3,
        68,
        71,
        39,
        132,
        198,
        199,
        122,
        81,
        189,
        24,
        3,
        79,
        28,
        19,
        2,
        195,
        127,
        57,
        76,
        54,
        162,
        186,
        3,
        0,
        129,
        146,
        4,
        0,
        159,
        199,
        148,
        193,
        0,
        89,
        0,
        157,
        1,
        4,
        152,
        0,
        198,
        110,
        2,
        171
      ],
      "weeklyWasted": [
        4,
        0,
        159,
        199,
        148,
        193,
        0,
        89,
        0,
        157,
        1,
        4,
        152,
        0,
        198,
        110,
        2,
        171,
        99,
        67,
        99,
        2,
        98,
        37,
        54,
        0,
        99,
        78,
        99,
        41,
        99,
        17,
        0,
        98,
        30,
        53,
        49,
        75,
        49,
        59,
        86,
        25,
        0,
        38,
        98,
        70,
        49,
        64,
        2,
        38,
        69,
        98,
        67,
        12,
        43,
        38,
        0,
        52,
        0,
        97,
        99,
        72,
        10,
        32,
        99,
        98,
        36,
        54,
        41,
        1
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyUsage": [
        110,
        177,
        0,
        5,
        0,
        82,
        142,
        3,
        2,
        1,
        172,
        49,
        109,
        97,
        199,
        7,
        153,
        101,
        55,
        64,
        81,
        40,
        53,
        149,
        3,
        1,
        146,
        59,
        84,
        54,
        53,
        87,
        9,
        118,
        198,
        140,
        0,
        132,
        167,
        149,
        22,
        0,
        0,
        1,
        1,
        168,
        18,
        13,
        174,
        40,
        124,
        29
      ],
      "weeklyWasted": [
        198,
        140,
        0,
        132,
        167,
        149,
        22,
        0,
        0,
        1,
        1,
        168,
        18,
        13,
        174,
        40,
        124,
        29,
        49,
        56,
        12,
        66,
        98,
        2,
        65,
        0,
        5,
        29,
        99,
        63,
        69,
        99,
        17,
        89,
        52,
        1,
        58,
        0,
        42,
        0,
        2,
        0,
        29,
        35,
        75,
        97,
        69,
        41,
        58,
        89,
        29,
        65,
        99,
        62,
        75,
        53,
        99,
        89,
        71,
        68,
        99,
        84,
        29,
        74,
        5,
        85,
        99,
        75,
        99,
        10
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyUsage": [
        84,
        2,
        102,
        195,
        164,
        108,
        125,
        197,
        78,
        1,
        199,
        48,
        104,
        79,
        4,
        159,
        192,
        45,
        197,
        55,
        62,
        59,
        67,
        85,
        54,
        42,
        166,
        66,
        199,
        140,
        110,
        0,
        197,
        115,
        182,
        0,
        62,
        113,
        128,
        131,
        90,
        199,
        122,
        0,
        1,
        4,
        107,
        73,
        67,
        73,
        2,
        133
      ],
      "weeklyWasted": [
        182,
        0,
        62,
        113,
        128,
        131,
        90,
        199,
        122,
        0,
        1,
        4,
        107,
        73,
        67,
        73,
        2,
        133,
        27,
        89,
        28,
        2,
        72,
        98,
        85,
        1,
        84,
        0,
        99,
        99,
        62,
        99,
        32,
        98,
        21,
        99,
        99,
        0,
        74,
        92,
        51,
        61,
        0,
        32,
        88,
        68,
        72,
        51,
        22,
        82,
        32,
        83,
        18,
        69,
        80,
        61,
        30,
        43,
        4,
        0,
        69,
        23,
        42,
        42,
        99,
        23,
        8,
        99,
        81,
        52
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        64,
        91,
        69,
        5,
        99,
        197,
        145,
        151,
        73,
        1,
        199,
        82,
        185,
        42,
        169,
        3,
        192,
        199,
        39,
        72,
        135,
        53,
        81,
        110,
        3,
        113,
        46,
        1,
        149,
        70,
        195,
        0,
        139,
        95,
        65,
        159,
        160,
        20,
        128,
        0,
        82,
        165,
        0,
        20,
        1,
        4,
        0,
        189,
        198,
        151,
        197,
        32
      ],
      "weeklyWasted": [
        65,
        159,
        160,
        20,
        128,
        0,
        82,
        165,
        0,
        20,
        1,
        4,
        0,
        189,
        198,
        151,
        197,
        32,
        54,
        5,
        63,
        5,
        62,
        1,
        99,
        0,
        99,
        0,
        33,
        62,
        57,
        31,
        55,
        24,
        55,
        52,
        99,
        10,
        31,
        29,
        47,
        62,
        22,
        99,
        56,
        59,
        2,
        18,
        2,
        22,
        4,
        98,
        99,
        7,
        23,
        42,
        63,
        97,
        0,
        10,
        98,
        64,
        34,
        0,
        46,
        42,
        45,
        92,
        76,
        47
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        199,
        132,
        21,
        73,
        117,
        139,
        76,
        126,
        2,
        1,
        92,
        44,
        119,
        0,
        114,
        94,
        192,
        111,
        197,
        147,
        77,
        19,
        197,
        155,
        3,
        50,
        197,
        41,
        199,
        124,
        199,
        90,
        67,
        27,
        177,
        136,
        198,
        45,
        60,
        69,
        85,
        0,
        0,
        12,
        1,
        21,
        86,
        198,
        198,
        42,
        2,
        82
      ],
      "weeklyWasted": [
        177,
        136,
        198,
        45,
        60,
        69,
        85,
        0,
        0,
        12,
        1,
        21,
        86,
        198,
        198,
        42,
        2,
        82,
        36,
        1,
        75,
        53,
        98,
        49,
        59,
        0,
        99,
        82,
        43,
        59,
        34,
        83,
        51,
        48,
        58,
        83,
        38,
        0,
        52,
        0,
        84,
        78,
        49,
        38,
        98,
        66,
        2,
        35,
        64,
        97,
        25,
        76,
        99,
        57,
        60,
        54,
        68,
        57,
        39,
        0,
        97,
        50,
        99,
        58,
        77,
        98,
        40,
        51,
        45,
        18
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        199,
        65,
        75,
        32,
        0,
        98,
        80,
        111,
        55,
        67,
        114,
        122,
        27,
        162,
        158,
        3,
        192,
        1,
        3,
        124,
        124,
        107,
        169,
        134,
        137,
        198,
        197,
        1,
        0,
        40,
        79,
        168,
        1,
        4,
        198,
        28,
        197,
        100,
        10,
        63,
        95,
        182,
        34,
        0,
        57,
        179,
        106,
        33,
        107,
        177,
        85,
        93
      ],
      "weeklyWasted": [
        198,
        28,
        197,
        100,
        10,
        63,
        95,
        182,
        34,
        0,
        57,
        179,
        106,
        33,
        107,
        177,
        85,
        93,
        1,
        37,
        0,
        67,
        0,
        1,
        66,
        0,
        80,
        19,
        29,
        20,
        55,
        3,
        84,
        16,
        46,
        51,
        50,
        99,
        75,
        16,
        11,
        79,
        99,
        68,
        59,
        54,
        34,
        30,
        26,
        80,
        34,
        81,
        77,
        0,
        74,
        43,
        99,
        31,
        9,
        0,
        59,
        54,
        55,
        22,
        32,
        58,
        99,
        59,
        24,
        15
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        199,
        2,
        198,
        64,
        198,
        55,
        87,
        3,
        100,
        60,
        47,
        2,
        198,
        58,
        82,
        198,
        169,
        1,
        3,
        104,
        197,
        198,
        76,
        1,
        23,
        114,
        197,
        62,
        198,
        115,
        199,
        123,
        1,
        25,
        23,
        199,
        121,
        150,
        173,
        41,
        132,
        74,
        130,
        0,
        7,
        196,
        156,
        115,
        95,
        175,
        119,
        3
      ],
      "weeklyWasted": [
        23,
        199,
        121,
        150,
        173,
        41,
        132,
        74,
        130,
        0,
        7,
        196,
        156,
        115,
        95,
        175,
        119,
        3,
        22,
        46,
        0,
        2,
        63,
        70,
        90,
        36,
        70,
        26,
        24,
        54,
        41,
        3,
        42,
        69,
        0,
        66,
        7,
        99,
        59,
        74,
        88,
        58,
        51,
        33,
        98,
        30,
        27,
        6,
        2,
        51,
        25,
        86,
        60,
        68,
        99,
        55,
        45,
        99,
        97,
        0,
        66,
        99,
        80,
        28,
        99,
        98,
        62,
        99,
        45,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        119,
        112,
        0,
        154,
        198,
        22,
        63,
        52,
        153,
        57,
        171,
        38,
        122,
        0,
        87,
        129,
        35,
        183,
        35,
        23,
        1,
        23,
        58,
        195,
        162,
        140,
        128,
        73,
        199,
        4,
        199,
        112,
        192,
        112,
        115,
        132,
        132,
        29,
        107,
        164,
        0,
        113,
        71,
        72,
        1,
        131,
        0,
        99,
        198,
        2,
        156,
        34
      ],
      "weeklyWasted": [
        115,
        132,
        132,
        29,
        107,
        164,
        0,
        113,
        71,
        72,
        1,
        131,
        0,
        99,
        198,
        2,
        156,
        34,
        62,
        1,
        99,
        57,
        80,
        98,
        24,
        35,
        32,
        55,
        81,
        99,
        65,
        68,
        3,
        11,
        37,
        99,
        99,
        73,
        99,
        0,
        0,
        90,
        24,
        2,
        98,
        74,
        99,
        86,
        2,
        97,
        99,
        77,
        48,
        63,
        79,
        55,
        23,
        1,
        11,
        46,
        0,
        68,
        7,
        53,
        99,
        53,
        30,
        75,
        87,
        30
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        75,
        151,
        187,
        83,
        69,
        23,
        189,
        3,
        17,
        181,
        83,
        53,
        19,
        126,
        140,
        147,
        192,
        75,
        3,
        2,
        144,
        54,
        133,
        122,
        22,
        123,
        132,
        54,
        140,
        110,
        157,
        162,
        146,
        121,
        198,
        116,
        121,
        97,
        199,
        199,
        16,
        132,
        0,
        33,
        42,
        41,
        142,
        47,
        198,
        199,
        97,
        3
      ],
      "weeklyWasted": [
        198,
        116,
        121,
        97,
        199,
        199,
        16,
        132,
        0,
        33,
        42,
        41,
        142,
        47,
        198,
        199,
        97,
        3,
        45,
        1,
        42,
        18,
        98,
        1,
        99,
        69,
        46,
        38,
        52,
        40,
        1,
        64,
        36,
        1,
        42,
        99,
        36,
        0,
        81,
        88,
        78,
        0,
        99,
        98,
        50,
        83,
        51,
        46,
        2,
        97,
        51,
        96,
        99,
        99,
        99,
        58,
        99,
        1,
        0,
        0,
        83,
        37,
        2,
        60,
        32,
        98,
        68,
        96,
        54,
        37
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        132,
        125,
        0,
        37,
        37,
        110,
        3,
        92,
        35,
        1,
        166,
        43,
        93,
        193,
        199,
        129,
        192,
        125,
        160,
        176,
        85,
        29,
        120,
        90,
        96,
        24,
        144,
        85,
        163,
        170,
        120,
        117,
        190,
        4,
        140,
        155,
        19,
        104,
        51,
        160,
        13,
        175,
        0,
        86,
        1,
        108,
        177,
        79,
        192,
        46,
        110,
        41
      ],
      "weeklyWasted": [
        140,
        155,
        19,
        104,
        51,
        160,
        13,
        175,
        0,
        86,
        1,
        108,
        177,
        79,
        192,
        46,
        110,
        41,
        52,
        58,
        43,
        2,
        98,
        50,
        99,
        29,
        61,
        60,
        60,
        79,
        59,
        85,
        97,
        1,
        42,
        48,
        12,
        31,
        98,
        39,
        28,
        13,
        45,
        63,
        75,
        31,
        95,
        9,
        22,
        97,
        1,
        98,
        55,
        61,
        84,
        46,
        52,
        85,
        25,
        21,
        11,
        39,
        94,
        42,
        34,
        97,
        67,
        52,
        52,
        13
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        45,
        59,
        21,
        112,
        14,
        117,
        194,
        118,
        54,
        38,
        49,
        41,
        2,
        13,
        165,
        23,
        192,
        112,
        197,
        108,
        109,
        87,
        130,
        79,
        3,
        1,
        117,
        89,
        40,
        0,
        199,
        16,
        71,
        165,
        78,
        40,
        134,
        5,
        43,
        44,
        0,
        80,
        109,
        184,
        139,
        196,
        105,
        126,
        187,
        173,
        158,
        30
      ],
      "weeklyWasted": [
        78,
        40,
        134,
        5,
        43,
        44,
        0,
        80,
        109,
        184,
        139,
        196,
        105,
        126,
        187,
        173,
        158,
        30,
        1,
        39,
        15,
        2,
        74,
        40,
        85,
        0,
        45,
        40,
        99,
        53,
        45,
        96,
        40,
        1,
        32,
        91,
        24,
        8,
        77,
        0,
        95,
        0,
        13,
        99,
        25,
        51,
        56,
        16,
        2,
        97,
        24,
        98,
        11,
        41,
        60,
        80,
        81,
        68,
        0,
        14,
        89,
        99,
        99,
        75,
        99,
        64,
        60,
        99,
        87,
        48
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        100,
        2,
        93,
        5,
        126,
        121,
        125,
        134,
        2,
        1,
        175,
        114,
        198,
        0,
        122,
        81,
        192,
        114,
        31,
        33,
        148,
        4,
        106,
        1,
        3,
        52,
        100,
        1,
        138,
        117,
        199,
        98,
        116,
        162,
        138,
        133,
        160,
        5,
        33,
        39,
        199,
        0,
        0,
        0,
        1,
        144,
        38,
        42,
        154,
        87,
        74,
        3
      ],
      "weeklyWasted": [
        138,
        133,
        160,
        5,
        33,
        39,
        199,
        0,
        0,
        0,
        1,
        144,
        38,
        42,
        154,
        87,
        74,
        3,
        18,
        20,
        37,
        2,
        42,
        98,
        83,
        57,
        20,
        11,
        98,
        6,
        2,
        91,
        48,
        98,
        98,
        77,
        98,
        25,
        93,
        0,
        10,
        26,
        99,
        56,
        71,
        61,
        44,
        1,
        2,
        56,
        44,
        86,
        61,
        50,
        84,
        0,
        99,
        5,
        67,
        2,
        66,
        34,
        99,
        1,
        5,
        45,
        81,
        69,
        61,
        1
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        97,
        90,
        3,
        5,
        47,
        30,
        181,
        50,
        2,
        1,
        199,
        15,
        102,
        0,
        169,
        118,
        192,
        132,
        3,
        69,
        197,
        198,
        197,
        1,
        138,
        54,
        93,
        111,
        136,
        0,
        0,
        167,
        197,
        5,
        190,
        0,
        162,
        199,
        10,
        2,
        199,
        64,
        0,
        49,
        1,
        48,
        81,
        173,
        110,
        143,
        2,
        114
      ],
      "weeklyWasted": [
        190,
        0,
        162,
        199,
        10,
        2,
        199,
        64,
        0,
        49,
        1,
        48,
        81,
        173,
        110,
        143,
        2,
        114,
        1,
        98,
        28,
        26,
        68,
        63,
        68,
        32,
        99,
        41,
        81,
        28,
        5,
        38,
        72,
        49,
        75,
        67,
        61,
        28,
        2,
        0,
        6,
        8,
        0,
        72,
        9,
        52,
        29,
        34,
        94,
        87,
        57,
        39,
        83,
        12,
        89,
        34,
        99,
        83,
        99,
        55,
        99,
        99,
        40,
        1,
        81,
        64,
        76,
        99,
        57,
        34
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        160,
        83,
        78,
        81,
        0,
        134,
        17,
        143,
        91,
        27,
        16,
        36,
        170,
        0,
        199,
        106,
        66,
        199,
        3,
        58,
        195,
        4,
        2,
        161,
        138,
        181,
        158,
        25,
        168,
        67,
        73,
        136,
        64,
        4,
        100,
        109,
        198,
        130,
        126,
        83,
        0,
        83,
        0,
        71,
        160,
        17,
        60,
        50,
        198,
        92,
        84,
        180
      ],
      "weeklyWasted": [
        100,
        109,
        198,
        130,
        126,
        83,
        0,
        83,
        0,
        71,
        160,
        17,
        60,
        50,
        198,
        92,
        84,
        180,
        99,
        48,
        93,
        2,
        22,
        98,
        99,
        9,
        79,
        0,
        0,
        69,
        41,
        94,
        57,
        1,
        67,
        44,
        61,
        30,
        62,
        16,
        22,
        0,
        67,
        72,
        98,
        48,
        55,
        46,
        2,
        51,
        13,
        90,
        99,
        48,
        47,
        89,
        53,
        99,
        53,
        0,
        82,
        89,
        59,
        43,
        75,
        98,
        38,
        56,
        52,
        47
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        199,
        188,
        67,
        116,
        168,
        67,
        194,
        3,
        2,
        114,
        184,
        86,
        198,
        98,
        133,
        175,
        192,
        93,
        131,
        129,
        197,
        81,
        141,
        175,
        197,
        198,
        197,
        32,
        121,
        111,
        104,
        0,
        1,
        87,
        72,
        0,
        198,
        154,
        52,
        199,
        70,
        0,
        180,
        82,
        17,
        196,
        39,
        198,
        133,
        115,
        2,
        3
      ],
      "weeklyWasted": [
        72,
        0,
        198,
        154,
        52,
        199,
        70,
        0,
        180,
        82,
        17,
        196,
        39,
        198,
        133,
        115,
        2,
        3,
        79,
        49,
        51,
        76,
        82,
        8,
        34,
        0,
        42,
        0,
        59,
        80,
        84,
        3,
        66,
        23,
        4,
        99,
        96,
        3,
        77,
        0,
        64,
        16,
        27,
        99,
        65,
        1,
        36,
        35,
        2,
        45,
        1,
        39,
        64,
        78,
        37,
        76,
        0,
        80,
        16,
        28,
        51,
        52,
        73,
        87,
        51,
        98,
        99,
        37,
        43,
        32
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        197,
        87,
        0,
        102,
        0,
        183,
        3,
        138,
        199,
        108,
        78,
        141,
        137,
        0,
        149,
        198,
        191,
        86,
        46,
        142,
        123,
        117,
        196,
        128,
        142,
        68,
        177,
        43,
        137,
        65,
        74,
        4,
        154,
        4,
        56,
        6,
        130,
        141,
        153,
        44,
        70,
        11,
        0,
        103,
        1,
        54,
        45,
        51,
        81,
        136,
        88,
        193
      ],
      "weeklyWasted": [
        56,
        6,
        130,
        141,
        153,
        44,
        70,
        11,
        0,
        103,
        1,
        54,
        45,
        51,
        81,
        136,
        88,
        193,
        81,
        10,
        1,
        27,
        67,
        29,
        62,
        29,
        99,
        0,
        5,
        99,
        46,
        99,
        42,
        98,
        23,
        43,
        41,
        0,
        39,
        0,
        0,
        52,
        1,
        98,
        53,
        61,
        50,
        34,
        26,
        9,
        13,
        98,
        59,
        75,
        71,
        0,
        30,
        37,
        14,
        63,
        77,
        79,
        59,
        72,
        39,
        67,
        30,
        2,
        41,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyUsage": [
        199,
        67,
        0,
        82,
        130,
        60,
        194,
        160,
        2,
        1,
        112,
        74,
        91,
        83,
        14,
        3,
        192,
        67,
        90,
        194,
        32,
        193,
        30,
        94,
        3,
        133,
        197,
        128,
        177,
        105,
        62,
        60,
        1,
        110,
        67,
        95,
        123,
        5,
        10,
        64,
        73,
        7,
        140,
        59,
        18,
        157,
        0,
        113,
        184,
        168,
        168,
        24
      ],
      "weeklyWasted": [
        67,
        95,
        123,
        5,
        10,
        64,
        73,
        7,
        140,
        59,
        18,
        157,
        0,
        113,
        184,
        168,
        168,
        24,
        64,
        7,
        37,
        69,
        98,
        98,
        91,
        3,
        99,
        80,
        41,
        72,
        47,
        99,
        0,
        2,
        38,
        79,
        20,
        3,
        48,
        0,
        17,
        80,
        0,
        18,
        82,
        22,
        63,
        29,
        2,
        86,
        40,
        98,
        69,
        20,
        79,
        61,
        99,
        99,
        39,
        0,
        52,
        41,
        62,
        20,
        99,
        98,
        69,
        69,
        99,
        1
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        41,
        2,
        145,
        5,
        114,
        94,
        86,
        174,
        182,
        39,
        163,
        58,
        198,
        87,
        98,
        171,
        32,
        199,
        154,
        2,
        1,
        128,
        51,
        91,
        65,
        186,
        106,
        66,
        7,
        27,
        4,
        196,
        1,
        112,
        122,
        99,
        175,
        47,
        141,
        199,
        92,
        0,
        146,
        131,
        72,
        194,
        56,
        72,
        198,
        50,
        175,
        70
      ],
      "weeklyWasted": [
        122,
        99,
        175,
        47,
        141,
        199,
        92,
        0,
        146,
        131,
        72,
        194,
        56,
        72,
        198,
        50,
        175,
        70,
        17,
        36,
        0,
        55,
        59,
        48,
        27,
        0,
        87,
        56,
        38,
        80,
        15,
        73,
        54,
        69,
        22,
        99,
        18,
        46,
        72,
        31,
        0,
        45,
        0,
        41,
        36,
        30,
        24,
        26,
        16,
        78,
        35,
        98,
        84,
        2,
        41,
        78,
        0,
        99,
        69,
        0,
        69,
        42,
        42,
        99,
        98,
        45,
        55,
        82,
        57,
        1
      ]
    },
    {
      "id": "8164C892",
      "weeklyUsage": [
        7,
        158,
        198,
        54,
        132,
        71,
        59,
        28,
        130,
        1,
        199,
        75,
        169,
        132,
        81,
        3,
        192,
        3,
        113,
        181,
        189,
        54,
        197,
        103,
        3,
        76,
        85,
        118,
        44,
        96,
        114,
        149,
        171,
        137,
        125,
        123,
        75,
        153,
        44,
        62,
        0,
        121,
        0,
        34,
        1,
        92,
        0,
        183,
        198,
        199,
        94,
        82
      ],
      "weeklyWasted": [
        125,
        123,
        75,
        153,
        44,
        62,
        0,
        121,
        0,
        34,
        1,
        92,
        0,
        183,
        198,
        199,
        94,
        82,
        1,
        13,
        45,
        34,
        5,
        98,
        99,
        9,
        99,
        99,
        86,
        99,
        99,
        77,
        97,
        14,
        43,
        58,
        39,
        41,
        82,
        16,
        0,
        56,
        23,
        75,
        0,
        76,
        30,
        79,
        44,
        87,
        66,
        56,
        68,
        0,
        75,
        70,
        0,
        17,
        96,
        0,
        59,
        83,
        99,
        25,
        99,
        3,
        30,
        64,
        42,
        1
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        199,
        2,
        198,
        195,
        50,
        90,
        194,
        53,
        101,
        2,
        28,
        41,
        187,
        0,
        176,
        3,
        180,
        199,
        3,
        197,
        99,
        22,
        123,
        68,
        13,
        85,
        143,
        50,
        116,
        52,
        68,
        133,
        56,
        199,
        91,
        0,
        198,
        100,
        77,
        116,
        199,
        39,
        199,
        109,
        65,
        166,
        64,
        198,
        198,
        199,
        197,
        159
      ],
      "weeklyWasted": [
        91,
        0,
        198,
        100,
        77,
        116,
        199,
        39,
        199,
        109,
        65,
        166,
        64,
        198,
        198,
        199,
        197,
        159,
        68,
        16,
        95,
        68,
        98,
        43,
        58,
        33,
        69,
        0,
        14,
        55,
        58,
        24,
        0,
        14,
        10,
        99,
        49,
        63,
        71,
        1,
        38,
        2,
        66,
        99,
        0,
        97,
        77,
        42,
        5,
        48,
        43,
        47,
        99,
        0,
        39,
        41,
        40,
        44,
        33,
        35,
        61,
        83,
        70,
        74,
        99,
        98,
        54,
        36,
        70,
        40
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        70,
        143,
        75,
        70,
        0,
        1,
        67,
        89,
        2,
        1,
        154,
        2,
        78,
        49,
        195,
        144,
        192,
        142,
        111,
        2,
        197,
        51,
        197,
        32,
        103,
        1,
        48,
        56,
        22,
        71,
        0,
        44,
        197,
        50,
        143,
        117,
        71,
        54,
        121,
        49,
        31,
        33,
        48,
        24,
        92,
        187,
        151,
        50,
        182,
        151,
        127,
        91
      ],
      "weeklyWasted": [
        143,
        117,
        71,
        54,
        121,
        49,
        31,
        33,
        48,
        24,
        92,
        187,
        151,
        50,
        182,
        151,
        127,
        91,
        83,
        98,
        44,
        2,
        0,
        38,
        71,
        13,
        99,
        5,
        99,
        28,
        9,
        63,
        0,
        41,
        43,
        57,
        9,
        74,
        40,
        0,
        92,
        89,
        74,
        32,
        60,
        71,
        98,
        63,
        2,
        97,
        63,
        90,
        45,
        33,
        70,
        53,
        72,
        1,
        0,
        43,
        99,
        56,
        95,
        20,
        80,
        65,
        33,
        95,
        35,
        66
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        24,
        2,
        113,
        57,
        76,
        132,
        178,
        3,
        151,
        102,
        0,
        96,
        2,
        0,
        93,
        15,
        33,
        116,
        73,
        59,
        34,
        4,
        124,
        195,
        3,
        101,
        181,
        75,
        199,
        193,
        162,
        79,
        91,
        112,
        4,
        21,
        198,
        125,
        175,
        94,
        57,
        15,
        155,
        18,
        139,
        70,
        23,
        100,
        198,
        199,
        85,
        196
      ],
      "weeklyWasted": [
        4,
        21,
        198,
        125,
        175,
        94,
        57,
        15,
        155,
        18,
        139,
        70,
        23,
        100,
        198,
        199,
        85,
        196,
        76,
        1,
        4,
        55,
        92,
        37,
        46,
        9,
        41,
        56,
        34,
        99,
        32,
        3,
        33,
        98,
        98,
        91,
        95,
        8,
        30,
        19,
        65,
        6,
        48,
        85,
        46,
        97,
        74,
        36,
        15,
        70,
        62,
        58,
        37,
        74,
        40,
        47,
        99,
        10,
        0,
        48,
        71,
        43,
        99,
        28,
        78,
        67,
        99,
        1,
        45,
        99
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        89,
        135,
        0,
        56,
        0,
        191,
        180,
        30,
        79,
        1,
        68,
        197,
        179,
        22,
        199,
        195,
        192,
        101,
        108,
        139,
        60,
        190,
        156,
        132,
        3,
        198,
        159,
        1,
        199,
        126,
        118,
        95,
        95,
        34,
        160,
        199,
        110,
        29,
        127,
        60,
        114,
        165,
        5,
        30,
        12,
        187,
        69,
        14,
        147,
        50,
        188,
        80
      ],
      "weeklyWasted": [
        160,
        199,
        110,
        29,
        127,
        60,
        114,
        165,
        5,
        30,
        12,
        187,
        69,
        14,
        147,
        50,
        188,
        80,
        64,
        10,
        19,
        8,
        98,
        23,
        4,
        39,
        13,
        26,
        94,
        1,
        70,
        99,
        90,
        3,
        20,
        1,
        35,
        36,
        45,
        50,
        54,
        0,
        52,
        31,
        98,
        37,
        99,
        12,
        2,
        97,
        54,
        98,
        89,
        15,
        89,
        95,
        99,
        99,
        14,
        38,
        99,
        99,
        56,
        86,
        0,
        7,
        62,
        78,
        14,
        1
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        102,
        2,
        198,
        109,
        15,
        56,
        43,
        3,
        199,
        1,
        39,
        65,
        73,
        48,
        199,
        126,
        117,
        188,
        75,
        33,
        103,
        126,
        76,
        148,
        197,
        158,
        197,
        92,
        195,
        44,
        99,
        0,
        197,
        199,
        4,
        0,
        92,
        5,
        156,
        87,
        77,
        54,
        0,
        0,
        1,
        149,
        90,
        92,
        198,
        40,
        34,
        85
      ],
      "weeklyWasted": [
        4,
        0,
        92,
        5,
        156,
        87,
        77,
        54,
        0,
        0,
        1,
        149,
        90,
        92,
        198,
        40,
        34,
        85,
        37,
        1,
        24,
        8,
        98,
        31,
        0,
        0,
        99,
        41,
        71,
        53,
        1,
        53,
        59,
        1,
        0,
        60,
        2,
        62,
        82,
        28,
        28,
        1,
        95,
        24,
        69,
        97,
        99,
        96,
        27,
        71,
        1,
        23,
        99,
        89,
        78,
        79,
        99,
        99,
        0,
        81,
        45,
        99,
        74,
        53,
        46,
        97,
        68,
        93,
        16,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        199,
        110,
        130,
        158,
        3,
        23,
        101,
        118,
        135,
        28,
        109,
        197,
        109,
        47,
        171,
        3,
        35,
        43,
        3,
        88,
        141,
        93,
        140,
        109,
        31,
        186,
        55,
        1,
        108,
        146,
        199,
        14,
        18,
        31,
        183,
        184,
        68,
        129,
        89,
        13,
        65,
        91,
        74,
        0,
        90,
        196,
        0,
        51,
        198,
        90,
        167,
        3
      ],
      "weeklyWasted": [
        183,
        184,
        68,
        129,
        89,
        13,
        65,
        91,
        74,
        0,
        90,
        196,
        0,
        51,
        198,
        90,
        167,
        3,
        11,
        39,
        99,
        2,
        98,
        49,
        17,
        29,
        87,
        0,
        19,
        26,
        58,
        73,
        79,
        41,
        15,
        47,
        26,
        65,
        89,
        0,
        0,
        0,
        21,
        71,
        20,
        1,
        31,
        51,
        2,
        31,
        43,
        72,
        43,
        0,
        83,
        88,
        0,
        49,
        67,
        53,
        72,
        99,
        74,
        65,
        90,
        97,
        63,
        55,
        46,
        40
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        199,
        2,
        91,
        92,
        15,
        47,
        154,
        82,
        128,
        1,
        146,
        18,
        114,
        0,
        1,
        130,
        177,
        199,
        187,
        108,
        197,
        4,
        67,
        195,
        63,
        138,
        193,
        22,
        172,
        113,
        81,
        57,
        16,
        105,
        144,
        6,
        58,
        110,
        136,
        102,
        0,
        0,
        32,
        121,
        131,
        39,
        43,
        92,
        198,
        126,
        135,
        76
      ],
      "weeklyWasted": [
        144,
        6,
        58,
        110,
        136,
        102,
        0,
        0,
        32,
        121,
        131,
        39,
        43,
        92,
        198,
        126,
        135,
        76,
        32,
        7,
        0,
        2,
        84,
        22,
        45,
        20,
        44,
        77,
        21,
        11,
        73,
        70,
        65,
        19,
        0,
        99,
        79,
        31,
        34,
        58,
        36,
        99,
        31,
        99,
        98,
        44,
        99,
        20,
        12,
        97,
        1,
        82,
        79,
        75,
        68,
        66,
        99,
        1,
        40,
        16,
        99,
        99,
        18,
        66,
        99,
        98,
        99,
        99,
        99,
        1
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        145,
        77,
        49,
        5,
        0,
        1,
        165,
        48,
        193,
        1,
        199,
        32,
        196,
        120,
        113,
        161,
        6,
        199,
        52,
        2,
        197,
        129,
        19,
        195,
        108,
        38,
        139,
        1,
        65,
        165,
        0,
        180,
        1,
        185,
        198,
        41,
        108,
        5,
        25,
        9,
        0,
        85,
        107,
        63,
        56,
        155,
        36,
        0,
        172,
        37,
        75,
        196
      ],
      "weeklyWasted": [
        198,
        41,
        108,
        5,
        25,
        9,
        0,
        85,
        107,
        63,
        56,
        155,
        36,
        0,
        172,
        37,
        75,
        196,
        65,
        15,
        37,
        2,
        16,
        52,
        44,
        0,
        0,
        9,
        99,
        1,
        1,
        89,
        58,
        44,
        59,
        99,
        36,
        43,
        33,
        82,
        13,
        0,
        49,
        99,
        32,
        78,
        58,
        1,
        21,
        97,
        99,
        98,
        92,
        0,
        99,
        0,
        27,
        44,
        39,
        66,
        0,
        72,
        18,
        99,
        84,
        98,
        76,
        82,
        45,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        100,
        116,
        198,
        52,
        156,
        197,
        194,
        113,
        147,
        1,
        44,
        9,
        122,
        0,
        166,
        171,
        192,
        64,
        197,
        150,
        197,
        198,
        197,
        142,
        3,
        172,
        3,
        79,
        199,
        116,
        57,
        82,
        186,
        164,
        138,
        199,
        95,
        97,
        35,
        50,
        193,
        101,
        0,
        68,
        62,
        143,
        153,
        198,
        163,
        137,
        97,
        53
      ],
      "weeklyWasted": [
        138,
        199,
        95,
        97,
        35,
        50,
        193,
        101,
        0,
        68,
        62,
        143,
        153,
        198,
        163,
        137,
        97,
        53,
        59,
        1,
        56,
        32,
        97,
        1,
        99,
        60,
        67,
        53,
        3,
        55,
        73,
        73,
        97,
        49,
        57,
        73,
        96,
        70,
        57,
        0,
        13,
        0,
        54,
        61,
        32,
        22,
        34,
        65,
        98,
        32,
        33,
        98,
        99,
        12,
        11,
        67,
        14,
        99,
        99,
        33,
        57,
        53,
        0,
        0,
        99,
        76,
        32,
        90,
        99,
        68
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        55,
        2,
        198,
        177,
        0,
        126,
        194,
        76,
        54,
        79,
        18,
        52,
        172,
        87,
        1,
        198,
        166,
        68,
        3,
        107,
        197,
        119,
        136,
        69,
        103,
        90,
        52,
        1,
        47,
        119,
        103,
        167,
        175,
        7,
        188,
        59,
        30,
        120,
        135,
        118,
        7,
        0,
        56,
        60,
        79,
        191,
        0,
        111,
        89,
        3,
        95,
        3
      ],
      "weeklyWasted": [
        188,
        59,
        30,
        120,
        135,
        118,
        7,
        0,
        56,
        60,
        79,
        191,
        0,
        111,
        89,
        3,
        95,
        3,
        85,
        6,
        30,
        20,
        98,
        44,
        61,
        0,
        99,
        97,
        91,
        31,
        99,
        72,
        0,
        21,
        86,
        73,
        26,
        62,
        82,
        12,
        36,
        0,
        16,
        99,
        98,
        75,
        25,
        54,
        16,
        48,
        50,
        98,
        99,
        2,
        99,
        61,
        99,
        82,
        49,
        0,
        52,
        75,
        64,
        78,
        32,
        80,
        29,
        36,
        99,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        123,
        98,
        144,
        90,
        65,
        197,
        24,
        3,
        56,
        95,
        133,
        45,
        118,
        99,
        73,
        16,
        0,
        179,
        176,
        125,
        1,
        4,
        45,
        153,
        197,
        110,
        188,
        41,
        196,
        106,
        159,
        0,
        1,
        150,
        198,
        0,
        33,
        130,
        78,
        67,
        31,
        173,
        83,
        0,
        33,
        62,
        49,
        0,
        174,
        83,
        73,
        117
      ],
      "weeklyWasted": [
        198,
        0,
        33,
        130,
        78,
        67,
        31,
        173,
        83,
        0,
        33,
        62,
        49,
        0,
        174,
        83,
        73,
        117,
        22,
        1,
        25,
        54,
        44,
        7,
        0,
        6,
        41,
        99,
        99,
        58,
        71,
        77,
        29,
        30,
        58,
        67,
        68,
        96,
        2,
        20,
        25,
        7,
        13,
        1,
        98,
        66,
        96,
        32,
        29,
        25,
        37,
        98,
        19,
        34,
        99,
        61,
        67,
        2,
        17,
        43,
        0,
        99,
        97,
        88,
        0,
        79,
        53,
        99,
        99,
        9
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        93,
        100,
        61,
        121,
        7,
        73,
        146,
        162,
        46,
        1,
        172,
        44,
        145,
        132,
        119,
        156,
        114,
        25,
        116,
        17,
        178,
        98,
        126,
        67,
        129,
        63,
        120,
        96,
        116,
        141,
        132,
        97,
        129,
        127,
        29,
        199,
        119,
        59,
        182,
        84,
        29,
        68,
        92,
        0,
        96,
        154,
        47,
        0,
        198,
        137,
        77,
        3
      ],
      "weeklyWasted": [
        29,
        199,
        119,
        59,
        182,
        84,
        29,
        68,
        92,
        0,
        96,
        154,
        47,
        0,
        198,
        137,
        77,
        3,
        1,
        1,
        10,
        8,
        23,
        95,
        46,
        0,
        94,
        0,
        88,
        1,
        1,
        99,
        78,
        39,
        27,
        99,
        9,
        12,
        99,
        9,
        37,
        29,
        98,
        99,
        20,
        90,
        52,
        1,
        39,
        52,
        30,
        63,
        0,
        8,
        50,
        79,
        69,
        93,
        60,
        0,
        99,
        38,
        82,
        94,
        99,
        83,
        19,
        80,
        44,
        30
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyUsage": [
        199,
        60,
        162,
        33,
        60,
        168,
        109,
        28,
        199,
        1,
        0,
        126,
        135,
        0,
        199,
        157,
        135,
        74,
        50,
        65,
        139,
        125,
        183,
        195,
        164,
        127,
        158,
        1,
        149,
        56,
        0,
        113,
        1,
        61,
        121,
        171,
        90,
        43,
        88,
        94,
        130,
        91,
        0,
        160,
        1,
        196,
        105,
        6,
        198,
        144,
        178,
        125
      ],
      "weeklyWasted": [
        121,
        171,
        90,
        43,
        88,
        94,
        130,
        91,
        0,
        160,
        1,
        196,
        105,
        6,
        198,
        144,
        178,
        125,
        99,
        38,
        92,
        2,
        15,
        72,
        31,
        26,
        69,
        99,
        95,
        84,
        46,
        3,
        28,
        76,
        0,
        65,
        1,
        99,
        66,
        99,
        0,
        9,
        40,
        85,
        98,
        49,
        78,
        1,
        2,
        40,
        26,
        93,
        99,
        20,
        57,
        63,
        15,
        99,
        48,
        18,
        92,
        73,
        56,
        86,
        35,
        98,
        19,
        78,
        99,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        199,
        182,
        0,
        90,
        60,
        159,
        134,
        75,
        61,
        50,
        51,
        128,
        198,
        0,
        71,
        67,
        192,
        67,
        3,
        43,
        191,
        51,
        92,
        195,
        49,
        141,
        40,
        37,
        28,
        69,
        101,
        124,
        118,
        76,
        157,
        101,
        120,
        199,
        85,
        54,
        100,
        69,
        0,
        0,
        98,
        196,
        0,
        60,
        198,
        90,
        45,
        31
      ],
      "weeklyWasted": [
        157,
        101,
        120,
        199,
        85,
        54,
        100,
        69,
        0,
        0,
        98,
        196,
        0,
        60,
        198,
        90,
        45,
        31,
        74,
        92,
        64,
        2,
        46,
        16,
        42,
        37,
        99,
        42,
        37,
        57,
        28,
        91,
        42,
        23,
        15,
        14,
        66,
        82,
        86,
        15,
        18,
        25,
        16,
        19,
        33,
        97,
        2,
        40,
        70,
        73,
        77,
        67,
        43,
        42,
        49,
        69,
        30,
        59,
        0,
        0,
        10,
        99,
        43,
        48,
        44,
        98,
        93,
        99,
        45,
        30
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        133,
        189,
        37,
        6,
        148,
        100,
        194,
        197,
        2,
        1,
        199,
        37,
        161,
        35,
        109,
        3,
        182,
        38,
        125,
        38,
        65,
        124,
        197,
        71,
        3,
        198,
        146,
        1,
        99,
        99,
        199,
        79,
        1,
        35,
        20,
        63,
        198,
        146,
        10,
        110,
        0,
        63,
        86,
        198,
        1,
        98,
        66,
        61,
        198,
        77,
        72,
        160
      ],
      "weeklyWasted": [
        20,
        63,
        198,
        146,
        10,
        110,
        0,
        63,
        86,
        198,
        1,
        98,
        66,
        61,
        198,
        77,
        72,
        160,
        50,
        79,
        53,
        26,
        98,
        17,
        20,
        23,
        30,
        0,
        63,
        81,
        38,
        99,
        97,
        78,
        11,
        96,
        1,
        70,
        33,
        58,
        0,
        32,
        54,
        1,
        98,
        35,
        9,
        19,
        36,
        53,
        24,
        45,
        61,
        58,
        99,
        99,
        99,
        73,
        85,
        62,
        99,
        99,
        99,
        30,
        99,
        98,
        99,
        88,
        99,
        1
      ]
    },
    {
      "id": "3D26994F",
      "weeklyUsage": [
        177,
        2,
        65,
        30,
        0,
        110,
        70,
        3,
        22,
        1,
        199,
        197,
        136,
        46,
        199,
        106,
        162,
        124,
        3,
        102,
        42,
        134,
        98,
        53,
        48,
        162,
        104,
        109,
        55,
        138,
        0,
        134,
        197,
        22,
        87,
        103,
        55,
        55,
        137,
        106,
        98,
        0,
        0,
        138,
        95,
        82,
        127,
        0,
        198,
        165,
        94,
        196
      ],
      "weeklyWasted": [
        87,
        103,
        55,
        55,
        137,
        106,
        98,
        0,
        0,
        138,
        95,
        82,
        127,
        0,
        198,
        165,
        94,
        196,
        98,
        12,
        99,
        33,
        76,
        98,
        0,
        0,
        99,
        99,
        99,
        22,
        34,
        99,
        39,
        52,
        0,
        94,
        1,
        52,
        2,
        44,
        59,
        27,
        58,
        24,
        95,
        64,
        99,
        96,
        7,
        28,
        99,
        98,
        99,
        11,
        85,
        88,
        76,
        83,
        99,
        0,
        92,
        99,
        46,
        99,
        74,
        98,
        35,
        99,
        61,
        1
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        12,
        82,
        0,
        61,
        0,
        34,
        159,
        143,
        199,
        39,
        199,
        38,
        118,
        28,
        199,
        120,
        96,
        199,
        3,
        194,
        142,
        70,
        26,
        173,
        156,
        198,
        135,
        1,
        142,
        198,
        199,
        34,
        81,
        4,
        150,
        29,
        96,
        79,
        10,
        92,
        115,
        82,
        0,
        51,
        1,
        196,
        134,
        4,
        96,
        109,
        83,
        3
      ],
      "weeklyWasted": [
        150,
        29,
        96,
        79,
        10,
        92,
        115,
        82,
        0,
        51,
        1,
        196,
        134,
        4,
        96,
        109,
        83,
        3,
        1,
        1,
        99,
        16,
        98,
        1,
        99,
        57,
        46,
        96,
        75,
        74,
        60,
        73,
        66,
        1,
        56,
        43,
        46,
        71,
        8,
        0,
        7,
        0,
        53,
        34,
        98,
        45,
        15,
        49,
        93,
        90,
        75,
        98,
        88,
        42,
        50,
        99,
        49,
        27,
        14,
        0,
        68,
        74,
        91,
        0,
        63,
        98,
        0,
        96,
        93,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        126,
        2,
        198,
        195,
        132,
        193,
        194,
        47,
        174,
        85,
        42,
        2,
        9,
        57,
        102,
        151,
        192,
        114,
        197,
        152,
        102,
        102,
        96,
        126,
        3,
        177,
        139,
        1,
        174,
        149,
        199,
        0,
        75,
        165,
        143,
        71,
        100,
        39,
        187,
        173,
        40,
        70,
        160,
        198,
        199,
        119,
        31,
        87,
        198,
        2,
        97,
        3
      ],
      "weeklyWasted": [
        143,
        71,
        100,
        39,
        187,
        173,
        40,
        70,
        160,
        198,
        199,
        119,
        31,
        87,
        198,
        2,
        97,
        3,
        4,
        26,
        75,
        26,
        98,
        45,
        21,
        0,
        77,
        71,
        86,
        81,
        77,
        73,
        84,
        1,
        0,
        99,
        19,
        0,
        58,
        0,
        8,
        31,
        99,
        99,
        98,
        5,
        99,
        1,
        28,
        58,
        1,
        77,
        99,
        45,
        60,
        10,
        45,
        99,
        66,
        1,
        99,
        99,
        15,
        61,
        36,
        64,
        75,
        35,
        99,
        31
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyUsage": [
        30,
        150,
        0,
        131,
        25,
        47,
        102,
        3,
        139,
        1,
        199,
        95,
        134,
        0,
        199,
        3,
        0,
        166,
        3,
        159,
        70,
        30,
        118,
        89,
        115,
        79,
        177,
        47,
        92,
        135,
        27,
        0,
        1,
        134,
        14,
        0,
        198,
        189,
        76,
        78,
        143,
        0,
        0,
        0,
        72,
        42,
        101,
        35,
        83,
        141,
        76,
        87
      ],
      "weeklyWasted": [
        14,
        0,
        198,
        189,
        76,
        78,
        143,
        0,
        0,
        0,
        72,
        42,
        101,
        35,
        83,
        141,
        76,
        87,
        86,
        1,
        21,
        56,
        98,
        8,
        56,
        0,
        0,
        99,
        82,
        46,
        65,
        40,
        47,
        79,
        49,
        99,
        26,
        28,
        2,
        99,
        16,
        0,
        27,
        49,
        4,
        23,
        71,
        58,
        25,
        97,
        97,
        98,
        99,
        71,
        12,
        78,
        0,
        77,
        76,
        98,
        99,
        77,
        52,
        53,
        80,
        8,
        23,
        44,
        25,
        62
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        115,
        62,
        125,
        5,
        105,
        144,
        138,
        142,
        199,
        12,
        193,
        110,
        189,
        199,
        152,
        3,
        115,
        32,
        197,
        2,
        38,
        148,
        79,
        195,
        110,
        152,
        116,
        155,
        112,
        78,
        199,
        5,
        174,
        149,
        120,
        71,
        67,
        14,
        146,
        10,
        131,
        17,
        1,
        30,
        1,
        195,
        87,
        198,
        127,
        178,
        133,
        27
      ],
      "weeklyWasted": [
        120,
        71,
        67,
        14,
        146,
        10,
        131,
        17,
        1,
        30,
        1,
        195,
        87,
        198,
        127,
        178,
        133,
        27,
        24,
        7,
        63,
        37,
        16,
        19,
        61,
        8,
        99,
        0,
        44,
        89,
        53,
        99,
        56,
        98,
        34,
        57,
        39,
        38,
        54,
        55,
        1,
        20,
        23,
        99,
        52,
        97,
        28,
        13,
        2,
        97,
        51,
        61,
        40,
        7,
        57,
        40,
        0,
        98,
        0,
        62,
        91,
        40,
        99,
        63,
        78,
        57,
        28,
        71,
        52,
        12
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        124,
        93,
        188,
        5,
        0,
        102,
        126,
        147,
        2,
        73,
        18,
        94,
        105,
        0,
        199,
        189,
        171,
        141,
        95,
        2,
        167,
        16,
        195,
        1,
        99,
        81,
        149,
        1,
        199,
        181,
        59,
        189,
        65,
        75,
        102,
        177,
        129,
        154,
        199,
        153,
        28,
        93,
        141,
        91,
        66,
        144,
        98,
        39,
        198,
        180,
        74,
        174
      ],
      "weeklyWasted": [
        102,
        177,
        129,
        154,
        199,
        153,
        28,
        93,
        141,
        91,
        66,
        144,
        98,
        39,
        198,
        180,
        74,
        174,
        26,
        38,
        94,
        2,
        98,
        98,
        48,
        0,
        99,
        29,
        89,
        1,
        23,
        99,
        47,
        98,
        14,
        62,
        3,
        92,
        99,
        62,
        88,
        0,
        46,
        68,
        98,
        27,
        58,
        5,
        38,
        63,
        29,
        67,
        0,
        30,
        68,
        63,
        24,
        99,
        0,
        0,
        59,
        57,
        57,
        39,
        95,
        98,
        99,
        79,
        99,
        15
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        199,
        127,
        70,
        172,
        198,
        46,
        194,
        63,
        165,
        1,
        133,
        96,
        198,
        0,
        146,
        8,
        192,
        199,
        30,
        2,
        197,
        198,
        98,
        1,
        17,
        180,
        121,
        1,
        135,
        120,
        118,
        0,
        153,
        199,
        141,
        199,
        198,
        13,
        131,
        14,
        41,
        136,
        7,
        144,
        1,
        196,
        0,
        198,
        168,
        58,
        92,
        3
      ],
      "weeklyWasted": [
        141,
        199,
        198,
        13,
        131,
        14,
        41,
        136,
        7,
        144,
        1,
        196,
        0,
        198,
        168,
        58,
        92,
        3,
        21,
        1,
        94,
        2,
        76,
        40,
        70,
        68,
        58,
        46,
        94,
        26,
        75,
        99,
        0,
        30,
        0,
        63,
        44,
        90,
        57,
        84,
        0,
        40,
        46,
        99,
        58,
        57,
        84,
        53,
        2,
        81,
        27,
        70,
        3,
        4,
        99,
        96,
        55,
        55,
        35,
        53,
        23,
        99,
        99,
        43,
        99,
        66,
        37,
        81,
        27,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        7,
        85,
        68,
        174,
        146,
        147,
        171,
        110,
        2,
        100,
        154,
        47,
        119,
        19,
        93,
        3,
        192,
        2,
        38,
        2,
        197,
        59,
        114,
        91,
        114,
        174,
        97,
        75,
        0,
        54,
        8,
        55,
        1,
        101,
        131,
        68,
        108,
        199,
        36,
        0,
        34,
        0,
        120,
        24,
        7,
        22,
        88,
        31,
        198,
        199,
        2,
        196
      ],
      "weeklyWasted": [
        131,
        68,
        108,
        199,
        36,
        0,
        34,
        0,
        120,
        24,
        7,
        22,
        88,
        31,
        198,
        199,
        2,
        196,
        1,
        45,
        6,
        38,
        52,
        51,
        35,
        0,
        99,
        23,
        67,
        20,
        75,
        72,
        27,
        45,
        4,
        37,
        91,
        0,
        99,
        22,
        31,
        77,
        82,
        61,
        37,
        61,
        2,
        18,
        80,
        1,
        31,
        68,
        81,
        41,
        48,
        0,
        56,
        95,
        22,
        60,
        65,
        68,
        68,
        23,
        99,
        19,
        28,
        77,
        26,
        42
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        165,
        83,
        155,
        31,
        16,
        148,
        141,
        118,
        93,
        149,
        199,
        138,
        198,
        0,
        32,
        3,
        101,
        7,
        3,
        2,
        40,
        107,
        138,
        44,
        156,
        5,
        81,
        31,
        54,
        35,
        29,
        6,
        59,
        29,
        133,
        37,
        52,
        199,
        153,
        56,
        169,
        60,
        109,
        19,
        1,
        70,
        195,
        111,
        167,
        181,
        153,
        46
      ],
      "weeklyWasted": [
        133,
        37,
        52,
        199,
        153,
        56,
        169,
        60,
        109,
        19,
        1,
        70,
        195,
        111,
        167,
        181,
        153,
        46,
        1,
        1,
        21,
        2,
        82,
        23,
        52,
        9,
        85,
        15,
        38,
        57,
        11,
        99,
        0,
        90,
        75,
        99,
        38,
        61,
        45,
        67,
        0,
        0,
        57,
        81,
        39,
        7,
        52,
        35,
        18,
        76,
        1,
        98,
        99,
        0,
        66,
        54,
        10,
        54,
        0,
        88,
        47,
        67,
        75,
        41,
        81,
        98,
        46,
        59,
        50,
        21
      ]
    },
    {
      "id": "7257F77C",
      "weeklyUsage": [
        122,
        84,
        98,
        89,
        0,
        41,
        46,
        3,
        56,
        8,
        33,
        12,
        98,
        0,
        162,
        3,
        192,
        173,
        38,
        39,
        167,
        171,
        98,
        1,
        29,
        32,
        182,
        1,
        142,
        104,
        81,
        0,
        25,
        4,
        22,
        143,
        154,
        199,
        199,
        0,
        36,
        34,
        75,
        0,
        1,
        110,
        116,
        119,
        166,
        145,
        119,
        12
      ],
      "weeklyWasted": [
        22,
        143,
        154,
        199,
        199,
        0,
        36,
        34,
        75,
        0,
        1,
        110,
        116,
        119,
        166,
        145,
        119,
        12,
        79,
        8,
        4,
        2,
        98,
        37,
        61,
        5,
        63,
        99,
        67,
        1,
        27,
        3,
        56,
        1,
        9,
        54,
        1,
        77,
        41,
        67,
        8,
        0,
        6,
        6,
        98,
        42,
        88,
        11,
        56,
        72,
        52,
        86,
        99,
        83,
        66,
        39,
        58,
        28,
        18,
        29,
        99,
        99,
        1,
        70,
        99,
        98,
        87,
        28,
        22,
        43
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        72,
        89,
        197,
        158,
        156,
        185,
        194,
        12,
        199,
        2,
        199,
        193,
        198,
        0,
        175,
        139,
        107,
        139,
        197,
        114,
        197,
        111,
        2,
        90,
        3,
        172,
        181,
        109,
        16,
        55,
        82,
        29,
        197,
        199,
        40,
        0,
        14,
        5,
        38,
        58,
        127,
        39,
        2,
        95,
        1,
        29,
        0,
        172,
        198,
        175,
        133,
        179
      ],
      "weeklyWasted": [
        40,
        0,
        14,
        5,
        38,
        58,
        127,
        39,
        2,
        95,
        1,
        29,
        0,
        172,
        198,
        175,
        133,
        179,
        18,
        2,
        4,
        23,
        66,
        46,
        99,
        0,
        83,
        37,
        78,
        41,
        72,
        73,
        61,
        2,
        73,
        39,
        64,
        0,
        8,
        29,
        64,
        39,
        39,
        99,
        37,
        47,
        2,
        1,
        25,
        10,
        56,
        73,
        99,
        22,
        46,
        36,
        46,
        78,
        40,
        16,
        36,
        84,
        78,
        61,
        99,
        38,
        68,
        95,
        54,
        10
      ]
    },
    {
      "id": "58D36D79",
      "weeklyUsage": [
        183,
        65,
        20,
        48,
        166,
        1,
        194,
        73,
        199,
        1,
        71,
        17,
        196,
        7,
        88,
        69,
        192,
        194,
        66,
        2,
        148,
        89,
        197,
        70,
        3,
        53,
        145,
        63,
        135,
        132,
        117,
        132,
        176,
        191,
        198,
        43,
        152,
        171,
        177,
        127,
        0,
        8,
        12,
        0,
        1,
        150,
        153,
        0,
        198,
        50,
        37,
        46
      ],
      "weeklyWasted": [
        198,
        43,
        152,
        171,
        177,
        127,
        0,
        8,
        12,
        0,
        1,
        150,
        153,
        0,
        198,
        50,
        37,
        46,
        1,
        21,
        43,
        35,
        76,
        83,
        59,
        55,
        3,
        99,
        77,
        71,
        70,
        62,
        61,
        49,
        39,
        99,
        81,
        93,
        47,
        18,
        32,
        2,
        40,
        94,
        56,
        53,
        99,
        96,
        2,
        73,
        44,
        98,
        93,
        0,
        45,
        60,
        0,
        48,
        49,
        24,
        80,
        99,
        89,
        49,
        99,
        96,
        69,
        94,
        82,
        27
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        199,
        2,
        195,
        195,
        92,
        68,
        3,
        85,
        4,
        31,
        105,
        52,
        107,
        0,
        1,
        176,
        192,
        199,
        3,
        34,
        14,
        4,
        118,
        129,
        42,
        13,
        193,
        70,
        168,
        30,
        112,
        69,
        154,
        103,
        4,
        0,
        12,
        97,
        175,
        21,
        0,
        81,
        35,
        42,
        1,
        31,
        41,
        151,
        198,
        75,
        42,
        134
      ],
      "weeklyWasted": [
        4,
        0,
        12,
        97,
        175,
        21,
        0,
        81,
        35,
        42,
        1,
        31,
        41,
        151,
        198,
        75,
        42,
        134,
        47,
        58,
        13,
        2,
        93,
        98,
        61,
        0,
        52,
        54,
        98,
        52,
        91,
        52,
        34,
        98,
        0,
        75,
        48,
        0,
        67,
        0,
        57,
        0,
        23,
        40,
        45,
        62,
        82,
        26,
        82,
        25,
        42,
        85,
        31,
        7,
        90,
        37,
        32,
        32,
        66,
        56,
        88,
        99,
        77,
        51,
        15,
        37,
        7,
        99,
        49,
        7
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyUsage": [
        95,
        99,
        28,
        5,
        73,
        128,
        111,
        197,
        63,
        59,
        199,
        50,
        156,
        0,
        199,
        80,
        181,
        184,
        197,
        147,
        131,
        4,
        29,
        164,
        3,
        44,
        79,
        75,
        199,
        0,
        103,
        0,
        167,
        174,
        138,
        0,
        50,
        64,
        13,
        118,
        0,
        86,
        0,
        150,
        1,
        82,
        58,
        78,
        198,
        65,
        168,
        80
      ],
      "weeklyWasted": [
        138,
        0,
        50,
        64,
        13,
        118,
        0,
        86,
        0,
        150,
        1,
        82,
        58,
        78,
        198,
        65,
        168,
        80,
        30,
        47,
        99,
        2,
        98,
        43,
        43,
        55,
        49,
        0,
        41,
        92,
        99,
        78,
        43,
        1,
        18,
        99,
        25,
        66,
        99,
        99,
        18,
        91,
        62,
        83,
        25,
        4,
        26,
        51,
        44,
        67,
        89,
        98,
        73,
        60,
        76,
        73,
        83,
        44,
        39,
        0,
        97,
        70,
        21,
        0,
        66,
        59,
        83,
        96,
        4,
        1
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        199,
        2,
        139,
        5,
        131,
        117,
        129,
        137,
        2,
        1,
        0,
        90,
        151,
        0,
        199,
        3,
        192,
        199,
        49,
        2,
        27,
        46,
        197,
        120,
        3,
        45,
        144,
        1,
        166,
        66,
        0,
        125,
        72,
        26,
        148,
        199,
        183,
        121,
        65,
        64,
        148,
        56,
        95,
        0,
        7,
        156,
        178,
        57,
        198,
        64,
        115,
        116
      ],
      "weeklyWasted": [
        148,
        199,
        183,
        121,
        65,
        64,
        148,
        56,
        95,
        0,
        7,
        156,
        178,
        57,
        198,
        64,
        115,
        116,
        22,
        59,
        0,
        54,
        0,
        68,
        99,
        43,
        88,
        79,
        99,
        73,
        11,
        98,
        86,
        98,
        77,
        99,
        7,
        65,
        27,
        49,
        55,
        71,
        35,
        99,
        98,
        29,
        37,
        13,
        4,
        34,
        1,
        34,
        42,
        22,
        57,
        99,
        54,
        50,
        46,
        12,
        99,
        75,
        73,
        52,
        89,
        98,
        73,
        88,
        58,
        59
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyUsage": [
        165,
        175,
        26,
        47,
        65,
        95,
        152,
        3,
        2,
        17,
        124,
        2,
        198,
        0,
        173,
        84,
        139,
        168,
        63,
        142,
        197,
        4,
        81,
        27,
        3,
        1,
        3,
        83,
        60,
        110,
        47,
        3,
        197,
        166,
        116,
        0,
        91,
        82,
        10,
        0,
        45,
        50,
        0,
        63,
        69,
        185,
        0,
        116,
        198,
        108,
        175,
        39
      ],
      "weeklyWasted": [
        116,
        0,
        91,
        82,
        10,
        0,
        45,
        50,
        0,
        63,
        69,
        185,
        0,
        116,
        198,
        108,
        175,
        39,
        12,
        78,
        66,
        71,
        28,
        98,
        84,
        4,
        62,
        47,
        49,
        1,
        35,
        97,
        74,
        23,
        3,
        55,
        14,
        0,
        52,
        0,
        10,
        36,
        38,
        99,
        0,
        27,
        99,
        87,
        27,
        50,
        95,
        84,
        99,
        38,
        2,
        27,
        76,
        57,
        65,
        0,
        88,
        99,
        99,
        0,
        97,
        98,
        91,
        93,
        0,
        1
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        170,
        79,
        37,
        92,
        137,
        184,
        85,
        3,
        92,
        199,
        35,
        75,
        5,
        129,
        167,
        83,
        192,
        55,
        197,
        71,
        55,
        9,
        116,
        136,
        171,
        65,
        197,
        158,
        71,
        120,
        199,
        146,
        91,
        77,
        4,
        74,
        198,
        12,
        134,
        0,
        0,
        80,
        0,
        121,
        103,
        70,
        24,
        177,
        138,
        167,
        163,
        100
      ],
      "weeklyWasted": [
        4,
        74,
        198,
        12,
        134,
        0,
        0,
        80,
        0,
        121,
        103,
        70,
        24,
        177,
        138,
        167,
        163,
        100,
        32,
        3,
        0,
        2,
        94,
        25,
        98,
        42,
        0,
        44,
        47,
        99,
        55,
        95,
        0,
        1,
        0,
        67,
        1,
        0,
        48,
        99,
        50,
        29,
        49,
        74,
        98,
        1,
        2,
        44,
        99,
        1,
        41,
        76,
        51,
        99,
        70,
        99,
        99,
        92,
        9,
        5,
        99,
        99,
        19,
        0,
        52,
        98,
        70,
        23,
        94,
        1
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyUsage": [
        150,
        181,
        0,
        19,
        167,
        137,
        95,
        86,
        2,
        1,
        199,
        38,
        179,
        92,
        187,
        3,
        133,
        193,
        22,
        2,
        72,
        4,
        51,
        79,
        144,
        148,
        155,
        57,
        52,
        176,
        0,
        78,
        118,
        76,
        98,
        99,
        160,
        141,
        10,
        72,
        0,
        51,
        50,
        0,
        48,
        100,
        195,
        53,
        98,
        2,
        52,
        118
      ],
      "weeklyWasted": [
        98,
        99,
        160,
        141,
        10,
        72,
        0,
        51,
        50,
        0,
        48,
        100,
        195,
        53,
        98,
        2,
        52,
        118,
        1,
        56,
        78,
        29,
        32,
        40,
        99,
        0,
        72,
        60,
        99,
        48,
        47,
        97,
        97,
        79,
        78,
        99,
        81,
        73,
        71,
        37,
        0,
        13,
        32,
        76,
        98,
        52,
        77,
        32,
        2,
        76,
        99,
        98,
        0,
        4,
        74,
        87,
        99,
        66,
        10,
        76,
        99,
        69,
        72,
        0,
        56,
        1,
        28,
        61,
        30,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        145,
        84,
        52,
        149,
        0,
        48,
        3,
        197,
        8,
        1,
        1,
        17,
        193,
        143,
        168,
        168,
        192,
        199,
        44,
        68,
        197,
        198,
        2,
        70,
        26,
        1,
        138,
        1,
        199,
        10,
        124,
        104,
        148,
        119,
        73,
        172,
        168,
        5,
        181,
        8,
        163,
        3,
        0,
        175,
        63,
        196,
        43,
        91,
        198,
        144,
        176,
        19
      ],
      "weeklyWasted": [
        73,
        172,
        168,
        5,
        181,
        8,
        163,
        3,
        0,
        175,
        63,
        196,
        43,
        91,
        198,
        144,
        176,
        19,
        44,
        9,
        0,
        2,
        39,
        98,
        79,
        33,
        99,
        32,
        99,
        64,
        1,
        99,
        71,
        2,
        0,
        82,
        1,
        64,
        99,
        0,
        89,
        0,
        73,
        93,
        0,
        97,
        46,
        55,
        2,
        90,
        51,
        98,
        80,
        34,
        88,
        83,
        43,
        72,
        70,
        0,
        60,
        80,
        84,
        59,
        40,
        0,
        12,
        86,
        82,
        38
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        7,
        138,
        176,
        7,
        34,
        81,
        56,
        92,
        2,
        1,
        26,
        41,
        198,
        0,
        166,
        3,
        110,
        155,
        78,
        2,
        83,
        4,
        161,
        68,
        3,
        48,
        127,
        42,
        124,
        71,
        133,
        178,
        168,
        4,
        46,
        94,
        82,
        110,
        145,
        85,
        0,
        126,
        62,
        84,
        23,
        120,
        0,
        45,
        194,
        100,
        152,
        24
      ],
      "weeklyWasted": [
        46,
        94,
        82,
        110,
        145,
        85,
        0,
        126,
        62,
        84,
        23,
        120,
        0,
        45,
        194,
        100,
        152,
        24,
        17,
        45,
        31,
        2,
        74,
        1,
        28,
        36,
        82,
        8,
        99,
        69,
        99,
        79,
        97,
        8,
        9,
        65,
        16,
        83,
        6,
        11,
        0,
        58,
        54,
        53,
        40,
        74,
        63,
        10,
        2,
        97,
        1,
        98,
        57,
        28,
        99,
        68,
        39,
        86,
        0,
        0,
        41,
        99,
        99,
        19,
        23,
        87,
        79,
        52,
        50,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        16,
        176,
        74,
        5,
        71,
        129,
        170,
        81,
        2,
        5,
        199,
        161,
        40,
        100,
        199,
        112,
        72,
        199,
        43,
        171,
        138,
        145,
        70,
        128,
        108,
        125,
        197,
        52,
        189,
        115,
        184,
        151,
        165,
        4,
        49,
        118,
        64,
        5,
        199,
        0,
        79,
        59,
        108,
        198,
        1,
        75,
        64,
        120,
        198,
        127,
        154,
        82
      ],
      "weeklyWasted": [
        49,
        118,
        64,
        5,
        199,
        0,
        79,
        59,
        108,
        198,
        1,
        75,
        64,
        120,
        198,
        127,
        154,
        82,
        11,
        36,
        0,
        96,
        98,
        66,
        72,
        2,
        77,
        0,
        16,
        61,
        99,
        56,
        69,
        98,
        0,
        46,
        35,
        33,
        2,
        44,
        36,
        99,
        0,
        99,
        54,
        63,
        10,
        21,
        2,
        57,
        24,
        47,
        99,
        23,
        14,
        58,
        64,
        82,
        52,
        32,
        99,
        70,
        69,
        32,
        95,
        98,
        99,
        78,
        99,
        1
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        176,
        149,
        120,
        115,
        87,
        120,
        194,
        38,
        54,
        138,
        195,
        2,
        137,
        100,
        62,
        71,
        127,
        199,
        43,
        2,
        114,
        4,
        31,
        180,
        77,
        45,
        170,
        115,
        82,
        59,
        42,
        196,
        85,
        123,
        4,
        118,
        23,
        71,
        12,
        0,
        0,
        123,
        27,
        0,
        1,
        75,
        174,
        114,
        148,
        101,
        167,
        3
      ],
      "weeklyWasted": [
        4,
        118,
        23,
        71,
        12,
        0,
        0,
        123,
        27,
        0,
        1,
        75,
        174,
        114,
        148,
        101,
        167,
        3,
        1,
        57,
        15,
        25,
        64,
        98,
        52,
        0,
        4,
        32,
        43,
        60,
        44,
        86,
        97,
        1,
        10,
        66,
        33,
        18,
        53,
        7,
        30,
        36,
        90,
        36,
        69,
        34,
        99,
        17,
        75,
        68,
        32,
        98,
        99,
        55,
        99,
        62,
        99,
        43,
        2,
        94,
        2,
        43,
        99,
        0,
        45,
        98,
        49,
        82,
        0,
        9
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyUsage": [
        199,
        68,
        60,
        195,
        110,
        85,
        3,
        3,
        199,
        65,
        128,
        2,
        34,
        86,
        81,
        110,
        192,
        178,
        3,
        197,
        126,
        134,
        80,
        5,
        110,
        80,
        172,
        26,
        0,
        198,
        47,
        196,
        43,
        147,
        99,
        71,
        197,
        44,
        115,
        199,
        199,
        199,
        80,
        0,
        84,
        64,
        105,
        131,
        198,
        20,
        72,
        95
      ],
      "weeklyWasted": [
        99,
        71,
        197,
        44,
        115,
        199,
        199,
        199,
        80,
        0,
        84,
        64,
        105,
        131,
        198,
        20,
        72,
        95,
        1,
        1,
        39,
        2,
        98,
        68,
        50,
        27,
        99,
        69,
        48,
        49,
        99,
        92,
        0,
        4,
        97,
        96,
        92,
        61,
        75,
        31,
        0,
        0,
        13,
        15,
        98,
        27,
        58,
        57,
        15,
        69,
        44,
        10,
        99,
        0,
        32,
        99,
        0,
        84,
        0,
        98,
        99,
        99,
        23,
        55,
        97,
        52,
        81,
        26,
        99,
        28
      ]
    },
    {
      "id": "7B415249",
      "weeklyUsage": [
        110,
        8,
        129,
        195,
        163,
        80,
        194,
        137,
        10,
        1,
        58,
        74,
        159,
        0,
        128,
        159,
        148,
        199,
        79,
        85,
        73,
        65,
        2,
        139,
        41,
        123,
        197,
        5,
        166,
        115,
        115,
        83,
        56,
        94,
        81,
        141,
        58,
        5,
        105,
        146,
        68,
        64,
        74,
        0,
        81,
        158,
        83,
        151,
        195,
        12,
        124,
        113
      ],
      "weeklyWasted": [
        81,
        141,
        58,
        5,
        105,
        146,
        68,
        64,
        74,
        0,
        81,
        158,
        83,
        151,
        195,
        12,
        124,
        113,
        63,
        10,
        40,
        13,
        98,
        2,
        69,
        66,
        56,
        8,
        70,
        1,
        1,
        69,
        58,
        1,
        61,
        58,
        39,
        73,
        51,
        89,
        97,
        0,
        21,
        41,
        56,
        34,
        66,
        6,
        2,
        97,
        85,
        93,
        87,
        2,
        83,
        42,
        0,
        46,
        24,
        57,
        78,
        34,
        53,
        70,
        27,
        98,
        99,
        99,
        59,
        87
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyUsage": [
        98,
        93,
        169,
        10,
        55,
        104,
        55,
        50,
        120,
        24,
        101,
        197,
        127,
        74,
        97,
        3,
        192,
        194,
        72,
        143,
        197,
        198,
        2,
        166,
        3,
        34,
        197,
        145,
        90,
        92,
        0,
        79,
        15,
        78,
        99,
        199,
        87,
        5,
        10,
        0,
        134,
        161,
        34,
        110,
        1,
        188,
        120,
        34,
        198,
        176,
        189,
        138
      ],
      "weeklyWasted": [
        99,
        199,
        87,
        5,
        10,
        0,
        134,
        161,
        34,
        110,
        1,
        188,
        120,
        34,
        198,
        176,
        189,
        138,
        42,
        10,
        0,
        8,
        98,
        69,
        64,
        0,
        55,
        0,
        97,
        55,
        99,
        44,
        68,
        46,
        30,
        99,
        8,
        73,
        2,
        9,
        21,
        33,
        13,
        42,
        98,
        73,
        83,
        1,
        2,
        61,
        30,
        51,
        99,
        44,
        53,
        73,
        26,
        99,
        34,
        78,
        98,
        29,
        83,
        22,
        53,
        98,
        99,
        99,
        99,
        1
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        146,
        2,
        65,
        151,
        0,
        18,
        51,
        3,
        149,
        75,
        199,
        81,
        133,
        50,
        199,
        18,
        178,
        106,
        112,
        107,
        197,
        4,
        39,
        181,
        197,
        72,
        197,
        58,
        82,
        94,
        199,
        0,
        115,
        199,
        4,
        0,
        0,
        170,
        90,
        1,
        22,
        15,
        0,
        108,
        31,
        4,
        28,
        135,
        168,
        111,
        94,
        20
      ],
      "weeklyWasted": [
        4,
        0,
        0,
        170,
        90,
        1,
        22,
        15,
        0,
        108,
        31,
        4,
        28,
        135,
        168,
        111,
        94,
        20,
        13,
        44,
        0,
        30,
        89,
        98,
        0,
        32,
        0,
        82,
        67,
        54,
        50,
        63,
        53,
        90,
        24,
        51,
        2,
        39,
        38,
        77,
        67,
        72,
        0,
        1,
        92,
        39,
        60,
        54,
        56,
        69,
        61,
        98,
        41,
        96,
        66,
        63,
        0,
        1,
        0,
        0,
        21,
        81,
        99,
        70,
        63,
        98,
        34,
        67,
        0,
        46
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        199,
        2,
        56,
        15,
        77,
        89,
        194,
        103,
        67,
        1,
        134,
        31,
        179,
        93,
        199,
        92,
        192,
        125,
        153,
        197,
        197,
        96,
        171,
        26,
        69,
        157,
        3,
        29,
        2,
        150,
        20,
        60,
        38,
        199,
        4,
        103,
        198,
        47,
        10,
        199,
        103,
        115,
        94,
        107,
        25,
        186,
        108,
        31,
        194,
        141,
        164,
        20
      ],
      "weeklyWasted": [
        4,
        103,
        198,
        47,
        10,
        199,
        103,
        115,
        94,
        107,
        25,
        186,
        108,
        31,
        194,
        141,
        164,
        20,
        99,
        40,
        99,
        38,
        87,
        44,
        64,
        0,
        66,
        0,
        0,
        1,
        31,
        96,
        38,
        11,
        56,
        84,
        73,
        99,
        99,
        76,
        42,
        0,
        32,
        58,
        25,
        45,
        42,
        33,
        2,
        97,
        99,
        53,
        27,
        0,
        1,
        99,
        0,
        1,
        83,
        0,
        95,
        99,
        99,
        28,
        99,
        98,
        99,
        53,
        40,
        24
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        97,
        193,
        74,
        123,
        198,
        111,
        184,
        138,
        2,
        135,
        0,
        2,
        2,
        0,
        199,
        198,
        11,
        145,
        3,
        197,
        102,
        95,
        58,
        195,
        80,
        107,
        142,
        51,
        167,
        54,
        30,
        149,
        197,
        122,
        93,
        1,
        181,
        5,
        199,
        40,
        80,
        87,
        170,
        148,
        54,
        4,
        43,
        198,
        198,
        172,
        167,
        196
      ],
      "weeklyWasted": [
        93,
        1,
        181,
        5,
        199,
        40,
        80,
        87,
        170,
        148,
        54,
        4,
        43,
        198,
        198,
        172,
        167,
        196,
        15,
        33,
        99,
        2,
        98,
        62,
        24,
        42,
        85,
        22,
        21,
        83,
        28,
        73,
        72,
        20,
        13,
        67,
        31,
        39,
        75,
        0,
        0,
        99,
        0,
        53,
        98,
        97,
        35,
        19,
        2,
        3,
        83,
        97,
        99,
        63,
        64,
        0,
        16,
        99,
        24,
        11,
        97,
        11,
        0,
        26,
        53,
        98,
        86,
        90,
        44,
        22
      ]
    },
    {
      "id": "958C4CED",
      "weeklyUsage": [
        65,
        2,
        15,
        5,
        0,
        92,
        27,
        37,
        99,
        112,
        122,
        2,
        117,
        5,
        54,
        156,
        192,
        131,
        154,
        162,
        26,
        174,
        4,
        158,
        194,
        93,
        88,
        126,
        130,
        142,
        134,
        66,
        82,
        148,
        142,
        8,
        76,
        19,
        146,
        62,
        0,
        5,
        193,
        168,
        72,
        141,
        32,
        198,
        154,
        30,
        2,
        3
      ],
      "weeklyWasted": [
        142,
        8,
        76,
        19,
        146,
        62,
        0,
        5,
        193,
        168,
        72,
        141,
        32,
        198,
        154,
        30,
        2,
        3,
        1,
        1,
        13,
        68,
        98,
        89,
        99,
        0,
        7,
        0,
        49,
        68,
        27,
        99,
        42,
        83,
        71,
        94,
        99,
        75,
        89,
        21,
        52,
        0,
        99,
        99,
        67,
        34,
        48,
        1,
        31,
        6,
        58,
        98,
        46,
        20,
        1,
        75,
        98,
        99,
        58,
        45,
        66,
        99,
        65,
        0,
        70,
        98,
        78,
        1,
        47,
        51
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        164,
        76,
        41,
        176,
        83,
        197,
        159,
        3,
        38,
        1,
        0,
        92,
        154,
        0,
        199,
        11,
        192,
        177,
        129,
        69,
        1,
        17,
        127,
        195,
        33,
        198,
        183,
        44,
        39,
        12,
        24,
        0,
        47,
        106,
        56,
        0,
        133,
        5,
        175,
        103,
        84,
        0,
        147,
        0,
        1,
        163,
        146,
        103,
        192,
        2,
        2,
        91
      ],
      "weeklyWasted": [
        56,
        0,
        133,
        5,
        175,
        103,
        84,
        0,
        147,
        0,
        1,
        163,
        146,
        103,
        192,
        2,
        2,
        91,
        38,
        18,
        99,
        40,
        98,
        89,
        76,
        33,
        19,
        34,
        91,
        39,
        39,
        6,
        17,
        22,
        48,
        82,
        51,
        99,
        73,
        42,
        35,
        0,
        80,
        68,
        98,
        59,
        86,
        1,
        53,
        80,
        78,
        98,
        99,
        48,
        38,
        47,
        98,
        65,
        0,
        39,
        19,
        99,
        86,
        87,
        3,
        82,
        99,
        8,
        53,
        36
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        115,
        96,
        128,
        5,
        0,
        1,
        75,
        20,
        193,
        1,
        0,
        104,
        2,
        0,
        149,
        3,
        192,
        143,
        3,
        93,
        197,
        52,
        108,
        162,
        128,
        1,
        155,
        95,
        168,
        113,
        140,
        0,
        44,
        166,
        182,
        0,
        17,
        63,
        180,
        111,
        0,
        15,
        0,
        88,
        1,
        174,
        0,
        50,
        198,
        133,
        168,
        39
      ],
      "weeklyWasted": [
        182,
        0,
        17,
        63,
        180,
        111,
        0,
        15,
        0,
        88,
        1,
        174,
        0,
        50,
        198,
        133,
        168,
        39,
        45,
        5,
        99,
        22,
        98,
        29,
        72,
        99,
        0,
        24,
        26,
        53,
        1,
        20,
        16,
        45,
        60,
        77,
        44,
        99,
        2,
        66,
        72,
        15,
        11,
        48,
        98,
        32,
        39,
        96,
        3,
        97,
        1,
        91,
        99,
        39,
        99,
        45,
        99,
        63,
        14,
        36,
        50,
        67,
        59,
        59,
        0,
        98,
        45,
        71,
        51,
        72
      ]
    },
    {
      "id": "330FF32E",
      "weeklyUsage": [
        73,
        76,
        132,
        49,
        177,
        105,
        194,
        197,
        2,
        1,
        56,
        31,
        129,
        0,
        199,
        92,
        132,
        129,
        184,
        2,
        197,
        71,
        197,
        105,
        33,
        1,
        161,
        44,
        124,
        142,
        73,
        172,
        98,
        173,
        106,
        199,
        68,
        199,
        68,
        0,
        0,
        166,
        170,
        182,
        23,
        102,
        0,
        62,
        198,
        180,
        197,
        3
      ],
      "weeklyWasted": [
        106,
        199,
        68,
        199,
        68,
        0,
        0,
        166,
        170,
        182,
        23,
        102,
        0,
        62,
        198,
        180,
        197,
        3,
        1,
        54,
        0,
        19,
        21,
        94,
        99,
        2,
        57,
        30,
        63,
        48,
        99,
        3,
        89,
        1,
        0,
        99,
        9,
        59,
        99,
        43,
        33,
        0,
        99,
        99,
        61,
        59,
        48,
        16,
        72,
        36,
        1,
        98,
        28,
        26,
        64,
        84,
        27,
        19,
        23,
        32,
        58,
        81,
        99,
        48,
        76,
        47,
        4,
        17,
        55,
        25
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyUsage": [
        199,
        86,
        145,
        49,
        64,
        66,
        54,
        124,
        105,
        132,
        42,
        25,
        107,
        120,
        172,
        122,
        192,
        170,
        80,
        36,
        148,
        120,
        186,
        134,
        3,
        198,
        142,
        38,
        184,
        131,
        94,
        157,
        1,
        116,
        87,
        157,
        138,
        86,
        81,
        199,
        29,
        0,
        16,
        0,
        14,
        5,
        146,
        92,
        198,
        69,
        2,
        3
      ],
      "weeklyWasted": [
        87,
        157,
        138,
        86,
        81,
        199,
        29,
        0,
        16,
        0,
        14,
        5,
        146,
        92,
        198,
        69,
        2,
        3,
        35,
        25,
        53,
        23,
        91,
        82,
        73,
        49,
        0,
        7,
        0,
        58,
        43,
        12,
        0,
        83,
        44,
        79,
        85,
        24,
        27,
        0,
        0,
        30,
        99,
        48,
        79,
        60,
        50,
        66,
        2,
        91,
        24,
        62,
        44,
        18,
        76,
        49,
        62,
        69,
        0,
        0,
        56,
        47,
        38,
        37,
        97,
        79,
        0,
        67,
        96,
        82
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        199,
        102,
        97,
        50,
        131,
        197,
        102,
        3,
        20,
        35,
        130,
        45,
        43,
        53,
        193,
        29,
        178,
        18,
        85,
        162,
        156,
        91,
        177,
        115,
        3,
        139,
        169,
        1,
        199,
        128,
        148,
        0,
        1,
        162,
        60,
        74,
        114,
        131,
        63,
        109,
        37,
        19,
        138,
        118,
        46,
        4,
        0,
        198,
        198,
        118,
        2,
        100
      ],
      "weeklyWasted": [
        60,
        74,
        114,
        131,
        63,
        109,
        37,
        19,
        138,
        118,
        46,
        4,
        0,
        198,
        198,
        118,
        2,
        100,
        7,
        14,
        20,
        58,
        98,
        74,
        0,
        48,
        69,
        99,
        99,
        73,
        63,
        45,
        87,
        90,
        98,
        27,
        24,
        6,
        47,
        77,
        60,
        0,
        90,
        92,
        66,
        50,
        52,
        8,
        69,
        22,
        76,
        18,
        37,
        66,
        26,
        58,
        43,
        91,
        55,
        41,
        99,
        99,
        83,
        3,
        99,
        21,
        33,
        99,
        86,
        20
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyUsage": [
        190,
        67,
        187,
        51,
        109,
        76,
        35,
        26,
        155,
        1,
        86,
        188,
        176,
        147,
        1,
        188,
        186,
        1,
        87,
        79,
        21,
        102,
        111,
        117,
        197,
        198,
        151,
        1,
        164,
        102,
        83,
        114,
        132,
        49,
        156,
        199,
        198,
        125,
        106,
        0,
        81,
        0,
        108,
        128,
        56,
        93,
        190,
        71,
        171,
        111,
        192,
        3
      ],
      "weeklyWasted": [
        156,
        199,
        198,
        125,
        106,
        0,
        81,
        0,
        108,
        128,
        56,
        93,
        190,
        71,
        171,
        111,
        192,
        3,
        39,
        20,
        25,
        78,
        49,
        98,
        99,
        18,
        99,
        19,
        0,
        99,
        54,
        22,
        52,
        58,
        68,
        61,
        16,
        67,
        44,
        0,
        0,
        0,
        0,
        39,
        56,
        44,
        99,
        70,
        2,
        60,
        31,
        56,
        48,
        81,
        99,
        99,
        32,
        99,
        99,
        0,
        11,
        20,
        19,
        71,
        32,
        94,
        44,
        69,
        33,
        42
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        199,
        199,
        150,
        5,
        140,
        146,
        30,
        3,
        47,
        127,
        53,
        26,
        135,
        0,
        101,
        106,
        192,
        1,
        184,
        2,
        197,
        56,
        45,
        13,
        3,
        68,
        161,
        43,
        0,
        164,
        63,
        0,
        1,
        107,
        132,
        140,
        0,
        90,
        35,
        0,
        88,
        0,
        139,
        0,
        21,
        67,
        131,
        80,
        198,
        168,
        2,
        67
      ],
      "weeklyWasted": [
        132,
        140,
        0,
        90,
        35,
        0,
        88,
        0,
        139,
        0,
        21,
        67,
        131,
        80,
        198,
        168,
        2,
        67,
        11,
        42,
        0,
        2,
        59,
        1,
        82,
        0,
        99,
        99,
        20,
        1,
        76,
        90,
        0,
        98,
        12,
        55,
        52,
        0,
        2,
        22,
        80,
        24,
        86,
        83,
        55,
        8,
        78,
        26,
        29,
        95,
        76,
        86,
        71,
        0,
        52,
        77,
        67,
        1,
        82,
        23,
        99,
        58,
        70,
        63,
        99,
        98,
        97,
        87,
        99,
        4
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyUsage": [
        169,
        123,
        198,
        60,
        151,
        197,
        60,
        3,
        70,
        51,
        111,
        57,
        8,
        70,
        59,
        3,
        192,
        55,
        72,
        197,
        1,
        90,
        19,
        152,
        3,
        36,
        197,
        21,
        8,
        70,
        199,
        191,
        182,
        12,
        11,
        106,
        87,
        96,
        10,
        199,
        175,
        17,
        110,
        192,
        1,
        196,
        57,
        198,
        174,
        149,
        83,
        114
      ],
      "weeklyWasted": [
        11,
        106,
        87,
        96,
        10,
        199,
        175,
        17,
        110,
        192,
        1,
        196,
        57,
        198,
        174,
        149,
        83,
        114,
        89,
        26,
        99,
        2,
        98,
        34,
        40,
        44,
        99,
        90,
        44,
        99,
        72,
        73,
        37,
        98,
        53,
        73,
        96,
        59,
        42,
        48,
        21,
        0,
        45,
        93,
        72,
        97,
        64,
        1,
        2,
        97,
        62,
        60,
        91,
        35,
        52,
        84,
        29,
        62,
        13,
        0,
        44,
        99,
        99,
        0,
        95,
        98,
        72,
        44,
        99,
        1
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyUsage": [
        141,
        122,
        198,
        102,
        110,
        17,
        114,
        99,
        2,
        28,
        130,
        2,
        156,
        125,
        118,
        79,
        123,
        199,
        75,
        133,
        166,
        50,
        48,
        127,
        70,
        15,
        148,
        197,
        157,
        151,
        104,
        146,
        136,
        32,
        150,
        0,
        123,
        115,
        127,
        28,
        23,
        76,
        26,
        8,
        110,
        150,
        149,
        41,
        198,
        126,
        177,
        60
      ],
      "weeklyWasted": [
        150,
        0,
        123,
        115,
        127,
        28,
        23,
        76,
        26,
        8,
        110,
        150,
        149,
        41,
        198,
        126,
        177,
        60,
        44,
        1,
        92,
        47,
        70,
        29,
        61,
        3,
        33,
        52,
        17,
        44,
        51,
        61,
        0,
        1,
        98,
        14,
        99,
        38,
        65,
        0,
        47,
        0,
        27,
        99,
        59,
        45,
        28,
        96,
        44,
        51,
        76,
        98,
        15,
        8,
        57,
        13,
        72,
        27,
        69,
        89,
        99,
        88,
        99,
        42,
        99,
        72,
        89,
        51,
        24,
        8
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        104,
        6,
        198,
        5,
        143,
        32,
        194,
        126,
        46,
        1,
        199,
        25,
        138,
        0,
        110,
        59,
        192,
        178,
        20,
        28,
        188,
        4,
        71,
        87,
        192,
        118,
        191,
        29,
        106,
        148,
        199,
        136,
        1,
        4,
        198,
        83,
        187,
        82,
        51,
        95,
        92,
        47,
        0,
        198,
        105,
        4,
        15,
        38,
        198,
        199,
        165,
        3
      ],
      "weeklyWasted": [
        198,
        83,
        187,
        82,
        51,
        95,
        92,
        47,
        0,
        198,
        105,
        4,
        15,
        38,
        198,
        199,
        165,
        3,
        1,
        42,
        99,
        23,
        98,
        98,
        37,
        27,
        55,
        51,
        82,
        69,
        58,
        75,
        67,
        19,
        44,
        47,
        99,
        78,
        99,
        0,
        51,
        0,
        66,
        32,
        98,
        1,
        68,
        32,
        32,
        81,
        32,
        56,
        99,
        22,
        53,
        64,
        58,
        43,
        6,
        0,
        99,
        79,
        86,
        29,
        68,
        98,
        19,
        99,
        23,
        31
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        199,
        2,
        167,
        50,
        0,
        1,
        118,
        29,
        142,
        1,
        29,
        197,
        2,
        122,
        179,
        127,
        133,
        9,
        197,
        47,
        60,
        114,
        181,
        49,
        3,
        154,
        188,
        1,
        175,
        54,
        106,
        22,
        44,
        74,
        4,
        44,
        198,
        74,
        126,
        199,
        10,
        145,
        122,
        29,
        17,
        196,
        162,
        133,
        191,
        84,
        133,
        28
      ],
      "weeklyWasted": [
        4,
        44,
        198,
        74,
        126,
        199,
        10,
        145,
        122,
        29,
        17,
        196,
        162,
        133,
        191,
        84,
        133,
        28,
        99,
        1,
        0,
        10,
        98,
        27,
        82,
        99,
        59,
        96,
        52,
        95,
        19,
        40,
        91,
        98,
        78,
        58,
        17,
        72,
        2,
        77,
        31,
        2,
        0,
        67,
        81,
        45,
        99,
        10,
        2,
        97,
        51,
        86,
        59,
        59,
        99,
        99,
        96,
        99,
        0,
        36,
        85,
        99,
        0,
        74,
        0,
        98,
        25,
        81,
        73,
        53
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        76,
        78,
        21,
        189,
        170,
        1,
        138,
        3,
        2,
        118,
        152,
        62,
        85,
        78,
        87,
        76,
        147,
        123,
        55,
        66,
        1,
        4,
        73,
        87,
        3,
        110,
        162,
        80,
        116,
        138,
        0,
        174,
        1,
        4,
        126,
        155,
        21,
        77,
        24,
        81,
        0,
        0,
        133,
        20,
        1,
        63,
        0,
        105,
        198,
        2,
        2,
        3
      ],
      "weeklyWasted": [
        126,
        155,
        21,
        77,
        24,
        81,
        0,
        0,
        133,
        20,
        1,
        63,
        0,
        105,
        198,
        2,
        2,
        3,
        29,
        18,
        17,
        70,
        53,
        66,
        74,
        0,
        88,
        49,
        58,
        63,
        72,
        77,
        40,
        84,
        51,
        75,
        66,
        45,
        87,
        0,
        15,
        5,
        8,
        99,
        98,
        19,
        2,
        76,
        2,
        57,
        1,
        33,
        54,
        0,
        79,
        96,
        34,
        1,
        79,
        83,
        99,
        83,
        99,
        48,
        90,
        54,
        58,
        85,
        49,
        59
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        137,
        2,
        0,
        5,
        0,
        1,
        134,
        54,
        177,
        1,
        161,
        67,
        101,
        93,
        199,
        198,
        192,
        199,
        91,
        145,
        13,
        9,
        137,
        75,
        101,
        120,
        197,
        66,
        168,
        27,
        27,
        86,
        122,
        98,
        176,
        0,
        73,
        40,
        10,
        9,
        86,
        0,
        0,
        0,
        129,
        96,
        79,
        19,
        57,
        16,
        44,
        30
      ],
      "weeklyWasted": [
        176,
        0,
        73,
        40,
        10,
        9,
        86,
        0,
        0,
        0,
        129,
        96,
        79,
        19,
        57,
        16,
        44,
        30,
        99,
        39,
        52,
        2,
        56,
        46,
        74,
        1,
        53,
        72,
        34,
        59,
        99,
        85,
        93,
        1,
        98,
        99,
        97,
        61,
        82,
        48,
        10,
        32,
        21,
        99,
        56,
        97,
        74,
        1,
        2,
        96,
        76,
        75,
        84,
        66,
        99,
        69,
        29,
        6,
        0,
        88,
        99,
        48,
        0,
        0,
        47,
        98,
        50,
        83,
        79,
        69
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        177,
        199,
        144,
        5,
        102,
        131,
        166,
        53,
        159,
        187,
        107,
        2,
        91,
        45,
        60,
        88,
        192,
        92,
        96,
        197,
        152,
        148,
        131,
        130,
        3,
        124,
        59,
        42,
        157,
        112,
        187,
        55,
        8,
        30,
        23,
        199,
        155,
        95,
        91,
        100,
        36,
        4,
        82,
        105,
        1,
        125,
        61,
        193,
        198,
        64,
        2,
        3
      ],
      "weeklyWasted": [
        23,
        199,
        155,
        95,
        91,
        100,
        36,
        4,
        82,
        105,
        1,
        125,
        61,
        193,
        198,
        64,
        2,
        3,
        99,
        1,
        49,
        30,
        78,
        9,
        50,
        32,
        77,
        99,
        47,
        1,
        66,
        99,
        97,
        61,
        80,
        99,
        33,
        18,
        91,
        69,
        2,
        0,
        77,
        59,
        73,
        97,
        99,
        1,
        7,
        97,
        11,
        56,
        69,
        0,
        75,
        99,
        68,
        99,
        70,
        49,
        99,
        99,
        70,
        0,
        61,
        49,
        24,
        91,
        17,
        17
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        150,
        2,
        0,
        9,
        0,
        82,
        20,
        67,
        2,
        33,
        199,
        2,
        140,
        0,
        140,
        75,
        146,
        122,
        3,
        2,
        106,
        70,
        181,
        88,
        175,
        1,
        43,
        181,
        153,
        198,
        58,
        57,
        10,
        4,
        54,
        103,
        164,
        199,
        138,
        24,
        72,
        9,
        0,
        0,
        39,
        109,
        60,
        17,
        6,
        199,
        94,
        145
      ],
      "weeklyWasted": [
        54,
        103,
        164,
        199,
        138,
        24,
        72,
        9,
        0,
        0,
        39,
        109,
        60,
        17,
        6,
        199,
        94,
        145,
        5,
        6,
        15,
        2,
        95,
        78,
        99,
        71,
        63,
        59,
        95,
        36,
        1,
        44,
        0,
        29,
        72,
        99,
        33,
        51,
        42,
        0,
        67,
        0,
        72,
        93,
        18,
        62,
        2,
        16,
        2,
        97,
        65,
        98,
        21,
        65,
        99,
        81,
        56,
        50,
        0,
        41,
        83,
        85,
        68,
        40,
        99,
        98,
        41,
        99,
        31,
        28
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        197,
        2,
        0,
        25,
        0,
        163,
        152,
        71,
        199,
        42,
        92,
        159,
        130,
        43,
        118,
        114,
        186,
        156,
        76,
        142,
        58,
        62,
        2,
        43,
        82,
        57,
        172,
        25,
        126,
        125,
        169,
        50,
        1,
        5,
        4,
        0,
        136,
        5,
        65,
        0,
        151,
        0,
        79,
        195,
        15,
        4,
        101,
        146,
        198,
        102,
        13,
        3
      ],
      "weeklyWasted": [
        4,
        0,
        136,
        5,
        65,
        0,
        151,
        0,
        79,
        195,
        15,
        4,
        101,
        146,
        198,
        102,
        13,
        3,
        68,
        1,
        35,
        60,
        98,
        1,
        37,
        25,
        0,
        42,
        0,
        32,
        99,
        97,
        44,
        14,
        72,
        33,
        23,
        1,
        31,
        1,
        37,
        27,
        45,
        62,
        81,
        63,
        94,
        7,
        2,
        97,
        99,
        70,
        90,
        3,
        1,
        32,
        51,
        60,
        67,
        2,
        79,
        63,
        91,
        97,
        80,
        35,
        58,
        53,
        99,
        86
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        155,
        181,
        176,
        193,
        146,
        1,
        93,
        16,
        155,
        1,
        0,
        79,
        194,
        19,
        49,
        142,
        0,
        141,
        44,
        181,
        70,
        194,
        133,
        27,
        3,
        108,
        165,
        69,
        0,
        152,
        130,
        86,
        92,
        136,
        198,
        199,
        198,
        95,
        24,
        199,
        40,
        79,
        74,
        31,
        6,
        183,
        0,
        58,
        198,
        51,
        150,
        3
      ],
      "weeklyWasted": [
        198,
        199,
        198,
        95,
        24,
        199,
        40,
        79,
        74,
        31,
        6,
        183,
        0,
        58,
        198,
        51,
        150,
        3,
        99,
        5,
        36,
        7,
        98,
        6,
        0,
        65,
        99,
        27,
        80,
        50,
        10,
        99,
        14,
        36,
        98,
        48,
        37,
        26,
        91,
        0,
        0,
        0,
        23,
        27,
        67,
        97,
        53,
        1,
        2,
        88,
        83,
        1,
        71,
        0,
        86,
        99,
        14,
        60,
        9,
        0,
        66,
        99,
        80,
        62,
        45,
        93,
        88,
        28,
        99,
        1
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        7,
        15,
        79,
        5,
        49,
        145,
        194,
        61,
        81,
        1,
        15,
        48,
        153,
        108,
        71,
        66,
        184,
        162,
        197,
        48,
        74,
        198,
        72,
        195,
        3,
        73,
        98,
        45,
        183,
        0,
        127,
        107,
        121,
        4,
        124,
        0,
        56,
        58,
        136,
        118,
        0,
        0,
        196,
        40,
        1,
        154,
        58,
        167,
        198,
        29,
        135,
        126
      ],
      "weeklyWasted": [
        124,
        0,
        56,
        58,
        136,
        118,
        0,
        0,
        196,
        40,
        1,
        154,
        58,
        167,
        198,
        29,
        135,
        126,
        38,
        98,
        10,
        47,
        0,
        1,
        42,
        11,
        99,
        3,
        58,
        75,
        37,
        54,
        78,
        64,
        98,
        47,
        52,
        0,
        71,
        16,
        0,
        44,
        0,
        63,
        98,
        65,
        2,
        56,
        2,
        67,
        2,
        98,
        99,
        0,
        71,
        0,
        99,
        63,
        63,
        0,
        89,
        99,
        79,
        99,
        43,
        91,
        73,
        1,
        53,
        1
      ]
    },
    {
      "id": "F36679BC",
      "weeklyUsage": [
        199,
        2,
        0,
        54,
        122,
        170,
        194,
        142,
        54,
        1,
        199,
        2,
        198,
        0,
        181,
        99,
        192,
        92,
        197,
        2,
        85,
        41,
        126,
        1,
        3,
        120,
        3,
        151,
        124,
        123,
        114,
        11,
        53,
        4,
        198,
        174,
        144,
        5,
        143,
        0,
        15,
        57,
        33,
        0,
        44,
        159,
        92,
        198,
        1,
        199,
        183,
        3
      ],
      "weeklyWasted": [
        198,
        174,
        144,
        5,
        143,
        0,
        15,
        57,
        33,
        0,
        44,
        159,
        92,
        198,
        1,
        199,
        183,
        3,
        1,
        1,
        90,
        3,
        98,
        1,
        99,
        0,
        41,
        45,
        79,
        43,
        1,
        49,
        48,
        1,
        0,
        86,
        1,
        26,
        53,
        57,
        63,
        6,
        33,
        23,
        53,
        6,
        61,
        13,
        96,
        78,
        46,
        80,
        46,
        37,
        99,
        86,
        82,
        63,
        74,
        6,
        99,
        64,
        73,
        0,
        17,
        98,
        50,
        55,
        23,
        30
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        199,
        2,
        2,
        5,
        0,
        49,
        20,
        114,
        199,
        1,
        122,
        132,
        189,
        43,
        115,
        72,
        58,
        183,
        58,
        52,
        120,
        24,
        75,
        195,
        126,
        158,
        142,
        1,
        199,
        6,
        75,
        63,
        80,
        21,
        102,
        71,
        101,
        127,
        10,
        62,
        0,
        66,
        0,
        0,
        1,
        134,
        190,
        74,
        162,
        8,
        163,
        43
      ],
      "weeklyWasted": [
        102,
        71,
        101,
        127,
        10,
        62,
        0,
        66,
        0,
        0,
        1,
        134,
        190,
        74,
        162,
        8,
        163,
        43,
        1,
        1,
        99,
        33,
        98,
        48,
        47,
        0,
        52,
        67,
        66,
        83,
        93,
        71,
        40,
        59,
        52,
        99,
        24,
        91,
        43,
        19,
        1,
        31,
        0,
        35,
        70,
        14,
        63,
        34,
        2,
        97,
        34,
        91,
        55,
        11,
        59,
        0,
        29,
        6,
        0,
        66,
        99,
        34,
        37,
        0,
        3,
        49,
        26,
        57,
        65,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyUsage": [
        176,
        72,
        109,
        159,
        0,
        193,
        65,
        15,
        96,
        37,
        199,
        133,
        198,
        145,
        45,
        3,
        0,
        199,
        59,
        71,
        71,
        57,
        49,
        1,
        115,
        24,
        102,
        19,
        155,
        198,
        29,
        132,
        56,
        81,
        4,
        90,
        150,
        87,
        10,
        49,
        86,
        29,
        124,
        0,
        1,
        137,
        83,
        42,
        198,
        17,
        34,
        106
      ],
      "weeklyWasted": [
        4,
        90,
        150,
        87,
        10,
        49,
        86,
        29,
        124,
        0,
        1,
        137,
        83,
        42,
        198,
        17,
        34,
        106,
        46,
        1,
        20,
        96,
        87,
        17,
        99,
        41,
        10,
        16,
        95,
        80,
        41,
        63,
        75,
        98,
        70,
        70,
        99,
        88,
        2,
        45,
        30,
        24,
        17,
        45,
        87,
        97,
        99,
        1,
        8,
        9,
        78,
        49,
        0,
        54,
        66,
        49,
        86,
        39,
        4,
        53,
        6,
        99,
        99,
        45,
        62,
        60,
        3,
        28,
        65,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyUsage": [
        82,
        103,
        19,
        5,
        75,
        186,
        52,
        26,
        129,
        63,
        199,
        100,
        183,
        33,
        94,
        68,
        178,
        1,
        176,
        109,
        197,
        83,
        95,
        1,
        3,
        94,
        172,
        197,
        0,
        66,
        199,
        27,
        1,
        4,
        169,
        83,
        38,
        154,
        46,
        86,
        0,
        81,
        0,
        0,
        51,
        190,
        10,
        87,
        198,
        2,
        126,
        54
      ],
      "weeklyWasted": [
        169,
        83,
        38,
        154,
        46,
        86,
        0,
        81,
        0,
        0,
        51,
        190,
        10,
        87,
        198,
        2,
        126,
        54,
        72,
        1,
        0,
        40,
        29,
        63,
        93,
        0,
        99,
        74,
        99,
        63,
        47,
        90,
        73,
        40,
        24,
        24,
        56,
        40,
        58,
        44,
        36,
        0,
        0,
        64,
        71,
        60,
        99,
        30,
        79,
        38,
        73,
        79,
        63,
        18,
        77,
        86,
        62,
        91,
        12,
        94,
        49,
        76,
        22,
        10,
        73,
        98,
        44,
        78,
        99,
        1
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        103,
        2,
        0,
        140,
        2,
        150,
        70,
        96,
        164,
        1,
        119,
        2,
        172,
        121,
        158,
        198,
        9,
        86,
        162,
        5,
        50,
        133,
        197,
        178,
        3,
        153,
        175,
        115,
        131,
        98,
        61,
        103,
        197,
        162,
        4,
        199,
        28,
        132,
        56,
        37,
        0,
        116,
        75,
        3,
        1,
        97,
        0,
        9,
        123,
        44,
        197,
        50
      ],
      "weeklyWasted": [
        4,
        199,
        28,
        132,
        56,
        37,
        0,
        116,
        75,
        3,
        1,
        97,
        0,
        9,
        123,
        44,
        197,
        50,
        78,
        44,
        3,
        2,
        70,
        97,
        99,
        0,
        68,
        0,
        50,
        86,
        57,
        96,
        68,
        6,
        10,
        31,
        23,
        57,
        2,
        19,
        5,
        30,
        35,
        50,
        37,
        97,
        99,
        11,
        2,
        97,
        21,
        89,
        54,
        19,
        72,
        78,
        96,
        68,
        34,
        2,
        49,
        82,
        57,
        58,
        20,
        29,
        99,
        87,
        24,
        94
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        117,
        2,
        127,
        28,
        57,
        61,
        174,
        118,
        113,
        112,
        115,
        48,
        151,
        150,
        131,
        3,
        192,
        199,
        157,
        112,
        141,
        140,
        86,
        1,
        23,
        126,
        157,
        124,
        163,
        79,
        199,
        145,
        98,
        156,
        30,
        27,
        185,
        199,
        111,
        60,
        0,
        82,
        157,
        0,
        1,
        104,
        173,
        173,
        198,
        199,
        19,
        130
      ],
      "weeklyWasted": [
        30,
        27,
        185,
        199,
        111,
        60,
        0,
        82,
        157,
        0,
        1,
        104,
        173,
        173,
        198,
        199,
        19,
        130,
        38,
        1,
        0,
        2,
        98,
        34,
        99,
        0,
        99,
        87,
        42,
        33,
        41,
        52,
        39,
        21,
        8,
        99,
        17,
        68,
        35,
        0,
        0,
        0,
        27,
        94,
        59,
        73,
        77,
        29,
        58,
        66,
        29,
        98,
        47,
        0,
        81,
        63,
        99,
        85,
        54,
        40,
        89,
        99,
        99,
        43,
        99,
        98,
        57,
        15,
        52,
        1
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        70,
        92,
        104,
        21,
        198,
        135,
        184,
        3,
        80,
        44,
        94,
        23,
        109,
        183,
        114,
        131,
        27,
        1,
        72,
        192,
        197,
        179,
        2,
        160,
        150,
        127,
        174,
        108,
        98,
        175,
        199,
        0,
        148,
        199,
        63,
        23,
        181,
        138,
        98,
        107,
        0,
        48,
        30,
        127,
        49,
        18,
        10,
        73,
        198,
        2,
        107,
        32
      ],
      "weeklyWasted": [
        63,
        23,
        181,
        138,
        98,
        107,
        0,
        48,
        30,
        127,
        49,
        18,
        10,
        73,
        198,
        2,
        107,
        32,
        1,
        1,
        51,
        14,
        90,
        32,
        82,
        0,
        90,
        21,
        34,
        92,
        74,
        99,
        63,
        98,
        48,
        70,
        16,
        43,
        2,
        99,
        0,
        96,
        46,
        48,
        98,
        22,
        19,
        20,
        2,
        1,
        10,
        65,
        99,
        0,
        77,
        70,
        57,
        79,
        66,
        67,
        99,
        47,
        76,
        42,
        0,
        47,
        30,
        78,
        50,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyUsage": [
        174,
        70,
        173,
        120,
        0,
        76,
        33,
        98,
        199,
        1,
        82,
        161,
        122,
        0,
        199,
        84,
        23,
        157,
        100,
        197,
        46,
        116,
        117,
        44,
        176,
        91,
        152,
        91,
        194,
        114,
        45,
        106,
        171,
        118,
        51,
        0,
        196,
        199,
        138,
        149,
        32,
        199,
        16,
        73,
        57,
        191,
        69,
        37,
        142,
        76,
        174,
        3
      ],
      "weeklyWasted": [
        51,
        0,
        196,
        199,
        138,
        149,
        32,
        199,
        16,
        73,
        57,
        191,
        69,
        37,
        142,
        76,
        174,
        3,
        1,
        3,
        8,
        91,
        98,
        51,
        53,
        30,
        34,
        0,
        66,
        70,
        13,
        44,
        26,
        44,
        43,
        9,
        71,
        55,
        47,
        0,
        8,
        0,
        31,
        6,
        4,
        97,
        15,
        62,
        2,
        51,
        7,
        13,
        99,
        21,
        14,
        91,
        81,
        62,
        13,
        68,
        71,
        99,
        77,
        99,
        24,
        98,
        19,
        64,
        31,
        12
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyUsage": [
        18,
        88,
        198,
        5,
        0,
        1,
        103,
        174,
        198,
        18,
        181,
        21,
        195,
        0,
        86,
        132,
        2,
        83,
        87,
        197,
        152,
        198,
        14,
        168,
        197,
        127,
        99,
        181,
        148,
        75,
        38,
        76,
        1,
        4,
        122,
        150,
        75,
        100,
        37,
        45,
        0,
        189,
        36,
        106,
        1,
        155,
        56,
        126,
        198,
        179,
        161,
        132
      ],
      "weeklyWasted": [
        122,
        150,
        75,
        100,
        37,
        45,
        0,
        189,
        36,
        106,
        1,
        155,
        56,
        126,
        198,
        179,
        161,
        132,
        20,
        2,
        0,
        9,
        97,
        1,
        57,
        39,
        5,
        29,
        4,
        77,
        37,
        65,
        8,
        13,
        0,
        24,
        27,
        88,
        35,
        0,
        25,
        99,
        19,
        78,
        98,
        40,
        2,
        79,
        2,
        16,
        1,
        73,
        76,
        30,
        90,
        0,
        0,
        85,
        45,
        35,
        99,
        55,
        68,
        59,
        68,
        98,
        99,
        76,
        99,
        42
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyUsage": [
        140,
        2,
        163,
        5,
        128,
        156,
        3,
        3,
        159,
        1,
        199,
        2,
        145,
        199,
        131,
        9,
        169,
        137,
        42,
        36,
        86,
        4,
        63,
        195,
        101,
        198,
        176,
        114,
        180,
        112,
        70,
        3,
        40,
        74,
        100,
        9,
        0,
        63,
        10,
        134,
        128,
        120,
        69,
        0,
        18,
        60,
        27,
        0,
        107,
        114,
        19,
        123
      ],
      "weeklyWasted": [
        100,
        9,
        0,
        63,
        10,
        134,
        128,
        120,
        69,
        0,
        18,
        60,
        27,
        0,
        107,
        114,
        19,
        123,
        1,
        10,
        34,
        47,
        98,
        48,
        99,
        0,
        75,
        99,
        99,
        25,
        36,
        99,
        49,
        10,
        59,
        60,
        26,
        82,
        24,
        92,
        0,
        0,
        13,
        38,
        61,
        21,
        39,
        14,
        56,
        97,
        63,
        98,
        6,
        1,
        22,
        96,
        0,
        1,
        8,
        98,
        69,
        90,
        63,
        99,
        4,
        98,
        45,
        87,
        43,
        1
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyUsage": [
        197,
        95,
        75,
        56,
        97,
        95,
        141,
        60,
        74,
        41,
        167,
        2,
        198,
        0,
        153,
        163,
        192,
        153,
        117,
        30,
        170,
        110,
        60,
        1,
        116,
        198,
        186,
        16,
        134,
        0,
        0,
        0,
        1,
        15,
        158,
        120,
        179,
        55,
        77,
        78,
        30,
        54,
        0,
        113,
        40,
        160,
        195,
        0,
        97,
        18,
        23,
        3
      ],
      "weeklyWasted": [
        158,
        120,
        179,
        55,
        77,
        78,
        30,
        54,
        0,
        113,
        40,
        160,
        195,
        0,
        97,
        18,
        23,
        3,
        27,
        1,
        43,
        27,
        98,
        1,
        57,
        0,
        13,
        0,
        96,
        1,
        27,
        76,
        97,
        59,
        36,
        94,
        21,
        0,
        2,
        30,
        45,
        26,
        83,
        99,
        47,
        27,
        64,
        16,
        59,
        97,
        1,
        79,
        99,
        66,
        97,
        88,
        99,
        99,
        38,
        51,
        69,
        1,
        11,
        0,
        47,
        70,
        55,
        99,
        22,
        14
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        92,
        162,
        198,
        97,
        0,
        191,
        194,
        55,
        197,
        50,
        199,
        104,
        150,
        108,
        1,
        18,
        192,
        199,
        52,
        59,
        43,
        4,
        133,
        195,
        62,
        142,
        78,
        43,
        199,
        91,
        46,
        20,
        196,
        141,
        115,
        68,
        14,
        44,
        129,
        107,
        66,
        0,
        55,
        0,
        7,
        103,
        0,
        0,
        158,
        101,
        139,
        93
      ],
      "weeklyWasted": [
        115,
        68,
        14,
        44,
        129,
        107,
        66,
        0,
        55,
        0,
        7,
        103,
        0,
        0,
        158,
        101,
        139,
        93,
        99,
        50,
        69,
        6,
        98,
        98,
        35,
        72,
        99,
        99,
        88,
        67,
        36,
        99,
        97,
        73,
        97,
        99,
        36,
        56,
        99,
        77,
        66,
        0,
        28,
        42,
        3,
        81,
        26,
        71,
        2,
        76,
        57,
        98,
        24,
        0,
        81,
        78,
        27,
        52,
        41,
        77,
        58,
        20,
        99,
        5,
        72,
        97,
        58,
        90,
        0,
        78
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        19,
        141,
        86,
        5,
        0,
        49,
        194,
        79,
        71,
        33,
        199,
        148,
        138,
        140,
        51,
        3,
        148,
        137,
        37,
        43,
        157,
        198,
        195,
        107,
        142,
        119,
        42,
        1,
        0,
        98,
        66,
        55,
        97,
        189,
        85,
        0,
        91,
        192,
        169,
        0,
        0,
        91,
        112,
        35,
        62,
        67,
        133,
        136,
        198,
        137,
        84,
        123
      ],
      "weeklyWasted": [
        85,
        0,
        91,
        192,
        169,
        0,
        0,
        91,
        112,
        35,
        62,
        67,
        133,
        136,
        198,
        137,
        84,
        123,
        48,
        79,
        99,
        30,
        98,
        15,
        4,
        0,
        3,
        81,
        0,
        23,
        14,
        91,
        0,
        41,
        81,
        8,
        13,
        68,
        40,
        53,
        89,
        57,
        78,
        58,
        98,
        69,
        58,
        25,
        2,
        76,
        75,
        75,
        91,
        86,
        88,
        49,
        99,
        91,
        21,
        86,
        76,
        99,
        2,
        21,
        50,
        89,
        99,
        99,
        75,
        25
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyUsage": [
        173,
        76,
        198,
        186,
        198,
        197,
        119,
        3,
        196,
        1,
        78,
        110,
        190,
        67,
        199,
        85,
        192,
        199,
        3,
        2,
        168,
        65,
        138,
        184,
        189,
        198,
        96,
        197,
        77,
        198,
        77,
        0,
        68,
        130,
        184,
        125,
        140,
        111,
        195,
        0,
        119,
        44,
        0,
        66,
        153,
        184,
        0,
        29,
        198,
        2,
        2,
        3
      ],
      "weeklyWasted": [
        184,
        125,
        140,
        111,
        195,
        0,
        119,
        44,
        0,
        66,
        153,
        184,
        0,
        29,
        198,
        2,
        2,
        3,
        1,
        2,
        46,
        32,
        34,
        86,
        47,
        0,
        99,
        50,
        96,
        71,
        99,
        90,
        0,
        10,
        17,
        54,
        21,
        99,
        99,
        58,
        55,
        4,
        97,
        99,
        63,
        29,
        54,
        10,
        26,
        56,
        38,
        89,
        73,
        8,
        99,
        16,
        99,
        39,
        27,
        53,
        78,
        83,
        60,
        90,
        99,
        71,
        64,
        99,
        10,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        199,
        114,
        146,
        5,
        68,
        28,
        67,
        96,
        136,
        19,
        156,
        162,
        30,
        195,
        199,
        94,
        192,
        199,
        33,
        30,
        140,
        81,
        44,
        79,
        3,
        198,
        120,
        36,
        199,
        51,
        0,
        0,
        69,
        138,
        46,
        140,
        198,
        113,
        10,
        6,
        60,
        0,
        199,
        59,
        1,
        4,
        124,
        198,
        198,
        83,
        121,
        3
      ],
      "weeklyWasted": [
        46,
        140,
        198,
        113,
        10,
        6,
        60,
        0,
        199,
        59,
        1,
        4,
        124,
        198,
        198,
        83,
        121,
        3,
        53,
        1,
        0,
        52,
        55,
        44,
        0,
        2,
        99,
        0,
        0,
        70,
        29,
        74,
        67,
        1,
        7,
        44,
        85,
        95,
        2,
        0,
        0,
        21,
        69,
        68,
        48,
        77,
        60,
        96,
        14,
        56,
        81,
        75,
        99,
        2,
        1,
        4,
        86,
        43,
        70,
        11,
        79,
        53,
        49,
        8,
        99,
        84,
        41,
        6,
        99,
        53
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        199,
        2,
        97,
        38,
        130,
        135,
        26,
        67,
        141,
        1,
        127,
        48,
        113,
        61,
        42,
        75,
        192,
        176,
        197,
        197,
        197,
        27,
        73,
        86,
        3,
        168,
        3,
        115,
        169,
        0,
        94,
        91,
        17,
        132,
        40,
        131,
        68,
        180,
        83,
        120,
        68,
        132,
        37,
        142,
        48,
        73,
        105,
        181,
        176,
        95,
        3,
        41
      ],
      "weeklyWasted": [
        40,
        131,
        68,
        180,
        83,
        120,
        68,
        132,
        37,
        142,
        48,
        73,
        105,
        181,
        176,
        95,
        3,
        41,
        34,
        1,
        39,
        23,
        49,
        40,
        29,
        11,
        99,
        99,
        9,
        43,
        72,
        99,
        35,
        43,
        47,
        59,
        19,
        44,
        39,
        26,
        0,
        99,
        0,
        47,
        98,
        65,
        92,
        21,
        2,
        78,
        45,
        98,
        76,
        21,
        78,
        73,
        53,
        1,
        70,
        0,
        99,
        55,
        97,
        0,
        78,
        60,
        61,
        87,
        87,
        1
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyUsage": [
        130,
        104,
        0,
        5,
        0,
        124,
        4,
        3,
        162,
        1,
        134,
        68,
        75,
        39,
        149,
        145,
        192,
        19,
        40,
        197,
        197,
        136,
        2,
        77,
        122,
        48,
        165,
        76,
        147,
        193,
        112,
        182,
        1,
        4,
        39,
        68,
        169,
        176,
        160,
        74,
        33,
        39,
        64,
        12,
        95,
        152,
        77,
        74,
        5,
        199,
        169,
        76
      ],
      "weeklyWasted": [
        39,
        68,
        169,
        176,
        160,
        74,
        33,
        39,
        64,
        12,
        95,
        152,
        77,
        74,
        5,
        199,
        169,
        76,
        99,
        29,
        39,
        17,
        98,
        92,
        63,
        0,
        72,
        99,
        8,
        99,
        56,
        66,
        0,
        42,
        58,
        99,
        17,
        65,
        2,
        0,
        0,
        0,
        12,
        5,
        71,
        97,
        72,
        76,
        6,
        71,
        28,
        98,
        32,
        47,
        83,
        22,
        81,
        63,
        0,
        3,
        70,
        99,
        32,
        86,
        99,
        98,
        16,
        45,
        85,
        1
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        157,
        2,
        141,
        195,
        160,
        61,
        143,
        3,
        88,
        1,
        111,
        40,
        2,
        141,
        199,
        124,
        173,
        132,
        3,
        108,
        73,
        106,
        197,
        1,
        3,
        141,
        169,
        28,
        155,
        92,
        100,
        16,
        1,
        44,
        106,
        132,
        44,
        185,
        10,
        0,
        119,
        0,
        119,
        44,
        1,
        196,
        0,
        162,
        122,
        193,
        2,
        3
      ],
      "weeklyWasted": [
        106,
        132,
        44,
        185,
        10,
        0,
        119,
        0,
        119,
        44,
        1,
        196,
        0,
        162,
        122,
        193,
        2,
        3,
        23,
        75,
        0,
        2,
        0,
        90,
        80,
        6,
        91,
        19,
        83,
        66,
        33,
        43,
        96,
        31,
        67,
        57,
        48,
        36,
        99,
        14,
        0,
        0,
        18,
        96,
        89,
        89,
        75,
        1,
        2,
        97,
        38,
        31,
        63,
        31,
        80,
        88,
        99,
        59,
        37,
        0,
        99,
        99,
        73,
        68,
        54,
        23,
        19,
        99,
        58,
        63
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        167,
        2,
        198,
        195,
        18,
        125,
        128,
        3,
        172,
        28,
        157,
        41,
        40,
        0,
        87,
        171,
        192,
        101,
        39,
        67,
        138,
        28,
        70,
        1,
        197,
        86,
        139,
        1,
        199,
        95,
        0,
        0,
        68,
        153,
        4,
        36,
        117,
        5,
        151,
        38,
        90,
        41,
        145,
        48,
        15,
        4,
        187,
        106,
        186,
        67,
        41,
        3
      ],
      "weeklyWasted": [
        4,
        36,
        117,
        5,
        151,
        38,
        90,
        41,
        145,
        48,
        15,
        4,
        187,
        106,
        186,
        67,
        41,
        3,
        1,
        3,
        65,
        2,
        98,
        81,
        99,
        57,
        64,
        10,
        29,
        13,
        1,
        3,
        69,
        85,
        0,
        80,
        1,
        4,
        44,
        55,
        6,
        4,
        0,
        74,
        19,
        39,
        35,
        96,
        5,
        97,
        1,
        63,
        71,
        22,
        63,
        54,
        56,
        9,
        30,
        3,
        48,
        45,
        80,
        99,
        30,
        98,
        85,
        99,
        28,
        88
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        199,
        117,
        50,
        117,
        117,
        134,
        53,
        8,
        101,
        112,
        199,
        30,
        50,
        59,
        50,
        198,
        192,
        1,
        23,
        197,
        1,
        4,
        75,
        91,
        3,
        74,
        192,
        38,
        162,
        141,
        0,
        119,
        1,
        4,
        46,
        199,
        70,
        179,
        69,
        72,
        97,
        137,
        29,
        198,
        34,
        196,
        86,
        61,
        129,
        146,
        103,
        66
      ],
      "weeklyWasted": [
        46,
        199,
        70,
        179,
        69,
        72,
        97,
        137,
        29,
        198,
        34,
        196,
        86,
        61,
        129,
        146,
        103,
        66,
        33,
        18,
        11,
        33,
        98,
        96,
        51,
        0,
        99,
        8,
        99,
        12,
        44,
        21,
        96,
        30,
        15,
        84,
        99,
        53,
        38,
        67,
        8,
        11,
        33,
        78,
        98,
        61,
        78,
        26,
        88,
        58,
        1,
        81,
        88,
        94,
        99,
        39,
        99,
        99,
        2,
        0,
        57,
        76,
        45,
        64,
        10,
        98,
        90,
        23,
        34,
        1
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyUsage": [
        7,
        119,
        139,
        42,
        43,
        142,
        148,
        81,
        86,
        22,
        165,
        197,
        176,
        50,
        191,
        107,
        124,
        67,
        93,
        89,
        197,
        112,
        38,
        195,
        67,
        165,
        6,
        44,
        123,
        22,
        183,
        0,
        159,
        103,
        198,
        0,
        198,
        5,
        199,
        0,
        101,
        0,
        5,
        0,
        173,
        168,
        0,
        32,
        181,
        162,
        197,
        3
      ],
      "weeklyWasted": [
        198,
        0,
        198,
        5,
        199,
        0,
        101,
        0,
        5,
        0,
        173,
        168,
        0,
        32,
        181,
        162,
        197,
        3,
        71,
        26,
        99,
        2,
        98,
        39,
        45,
        33,
        99,
        91,
        99,
        44,
        67,
        38,
        0,
        73,
        13,
        48,
        1,
        20,
        35,
        22,
        5,
        3,
        39,
        6,
        42,
        33,
        13,
        96,
        63,
        73,
        1,
        98,
        99,
        68,
        99,
        18,
        99,
        59,
        81,
        0,
        88,
        99,
        3,
        73,
        99,
        58,
        99,
        89,
        49,
        26
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyUsage": [
        199,
        86,
        99,
        73,
        158,
        78,
        97,
        73,
        23,
        60,
        138,
        31,
        150,
        183,
        1,
        92,
        192,
        172,
        171,
        44,
        144,
        185,
        34,
        1,
        131,
        82,
        53,
        1,
        0,
        166,
        199,
        0,
        62,
        92,
        176,
        33,
        36,
        127,
        110,
        140,
        56,
        73,
        38,
        30,
        73,
        142,
        63,
        198,
        198,
        199,
        2,
        114
      ],
      "weeklyWasted": [
        176,
        33,
        36,
        127,
        110,
        140,
        56,
        73,
        38,
        30,
        73,
        142,
        63,
        198,
        198,
        199,
        2,
        114,
        41,
        51,
        44,
        26,
        84,
        26,
        59,
        0,
        99,
        99,
        99,
        54,
        29,
        11,
        0,
        17,
        7,
        51,
        57,
        43,
        43,
        6,
        45,
        0,
        91,
        99,
        98,
        34,
        40,
        21,
        99,
        78,
        1,
        67,
        78,
        47,
        26,
        45,
        41,
        1,
        26,
        29,
        63,
        99,
        39,
        22,
        99,
        98,
        0,
        94,
        60,
        14
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        151,
        56,
        81,
        67,
        40,
        138,
        127,
        52,
        115,
        1,
        96,
        112,
        174,
        31,
        199,
        92,
        138,
        199,
        113,
        193,
        125,
        4,
        69,
        103,
        3,
        123,
        123,
        136,
        95,
        53,
        105,
        108,
        18,
        92,
        135,
        33,
        177,
        5,
        199,
        27,
        38,
        110,
        37,
        116,
        61,
        196,
        0,
        0,
        152,
        72,
        88,
        114
      ],
      "weeklyWasted": [
        135,
        33,
        177,
        5,
        199,
        27,
        38,
        110,
        37,
        116,
        61,
        196,
        0,
        0,
        152,
        72,
        88,
        114,
        40,
        3,
        99,
        2,
        98,
        78,
        58,
        0,
        57,
        90,
        68,
        85,
        30,
        99,
        97,
        34,
        1,
        77,
        62,
        99,
        56,
        44,
        30,
        16,
        26,
        99,
        98,
        55,
        99,
        64,
        2,
        97,
        67,
        98,
        20,
        47,
        72,
        26,
        46,
        1,
        23,
        0,
        82,
        64,
        22,
        20,
        70,
        98,
        99,
        67,
        25,
        17
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyUsage": [
        134,
        2,
        116,
        5,
        34,
        97,
        129,
        99,
        199,
        1,
        199,
        2,
        61,
        0,
        120,
        116,
        129,
        185,
        133,
        5,
        177,
        111,
        53,
        50,
        49,
        136,
        14,
        58,
        68,
        198,
        20,
        0,
        1,
        107,
        4,
        106,
        133,
        45,
        61,
        166,
        95,
        42,
        199,
        43,
        1,
        196,
        46,
        29,
        140,
        8,
        164,
        31
      ],
      "weeklyWasted": [
        4,
        106,
        133,
        45,
        61,
        166,
        95,
        42,
        199,
        43,
        1,
        196,
        46,
        29,
        140,
        8,
        164,
        31,
        94,
        38,
        99,
        25,
        98,
        66,
        33,
        42,
        99,
        14,
        91,
        61,
        72,
        99,
        93,
        47,
        39,
        78,
        99,
        86,
        89,
        99,
        0,
        26,
        26,
        99,
        98,
        47,
        11,
        45,
        2,
        97,
        88,
        83,
        76,
        21,
        15,
        56,
        50,
        47,
        69,
        18,
        99,
        99,
        99,
        54,
        14,
        49,
        54,
        99,
        29,
        1
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        94,
        106,
        71,
        5,
        172,
        1,
        57,
        3,
        140,
        1,
        144,
        49,
        82,
        30,
        1,
        111,
        120,
        199,
        21,
        2,
        140,
        30,
        128,
        169,
        166,
        177,
        136,
        83,
        186,
        49,
        0,
        122,
        110,
        4,
        4,
        0,
        198,
        101,
        58,
        178,
        28,
        0,
        149,
        97,
        58,
        118,
        23,
        184,
        196,
        81,
        150,
        97
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        101,
        58,
        178,
        28,
        0,
        149,
        97,
        58,
        118,
        23,
        184,
        196,
        81,
        150,
        97,
        97,
        39,
        71,
        20,
        98,
        84,
        31,
        0,
        99,
        89,
        0,
        99,
        76,
        77,
        21,
        57,
        68,
        99,
        41,
        84,
        32,
        9,
        15,
        31,
        12,
        99,
        73,
        72,
        2,
        1,
        2,
        65,
        56,
        98,
        61,
        0,
        95,
        34,
        49,
        56,
        52,
        71,
        64,
        36,
        16,
        31,
        65,
        74,
        66,
        1,
        59,
        4
      ]
    },
    {
      "id": "C36BF119",
      "weeklyUsage": [
        7,
        23,
        198,
        99,
        0,
        110,
        88,
        70,
        199,
        83,
        172,
        109,
        84,
        199,
        174,
        164,
        97,
        123,
        101,
        55,
        26,
        52,
        113,
        149,
        3,
        103,
        160,
        1,
        65,
        88,
        199,
        1,
        197,
        24,
        31,
        187,
        105,
        93,
        136,
        41,
        19,
        77,
        0,
        0,
        10,
        151,
        30,
        198,
        198,
        183,
        110,
        90
      ],
      "weeklyWasted": [
        31,
        187,
        105,
        93,
        136,
        41,
        19,
        77,
        0,
        0,
        10,
        151,
        30,
        198,
        198,
        183,
        110,
        90,
        99,
        17,
        0,
        40,
        47,
        31,
        99,
        0,
        99,
        25,
        99,
        80,
        48,
        94,
        75,
        68,
        98,
        62,
        1,
        72,
        74,
        57,
        49,
        60,
        72,
        86,
        52,
        64,
        18,
        55,
        2,
        97,
        78,
        96,
        34,
        86,
        99,
        32,
        56,
        84,
        0,
        0,
        99,
        71,
        86,
        99,
        39,
        98,
        99,
        1,
        55,
        1
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        165,
        2,
        0,
        5,
        9,
        117,
        194,
        128,
        88,
        1,
        199,
        2,
        136,
        0,
        199,
        63,
        146,
        25,
        83,
        152,
        197,
        192,
        125,
        195,
        163,
        175,
        155,
        132,
        41,
        41,
        13,
        83,
        71,
        156,
        150,
        0,
        112,
        28,
        10,
        151,
        0,
        134,
        31,
        198,
        37,
        102,
        136,
        0,
        183,
        199,
        151,
        137
      ],
      "weeklyWasted": [
        150,
        0,
        112,
        28,
        10,
        151,
        0,
        134,
        31,
        198,
        37,
        102,
        136,
        0,
        183,
        199,
        151,
        137,
        31,
        37,
        34,
        2,
        65,
        46,
        56,
        0,
        57,
        55,
        15,
        70,
        74,
        86,
        50,
        1,
        52,
        56,
        1,
        61,
        2,
        77,
        77,
        32,
        72,
        99,
        76,
        40,
        95,
        28,
        3,
        54,
        41,
        76,
        99,
        0,
        74,
        57,
        31,
        33,
        0,
        0,
        99,
        30,
        64,
        41,
        74,
        73,
        46,
        99,
        56,
        1
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyUsage": [
        7,
        18,
        7,
        192,
        78,
        1,
        79,
        197,
        37,
        22,
        95,
        124,
        198,
        0,
        83,
        3,
        192,
        173,
        14,
        147,
        103,
        47,
        133,
        106,
        3,
        1,
        119,
        62,
        199,
        79,
        123,
        0,
        111,
        4,
        37,
        54,
        198,
        50,
        147,
        96,
        43,
        12,
        56,
        40,
        2,
        196,
        0,
        196,
        198,
        86,
        117,
        123
      ],
      "weeklyWasted": [
        37,
        54,
        198,
        50,
        147,
        96,
        43,
        12,
        56,
        40,
        2,
        196,
        0,
        196,
        198,
        86,
        117,
        123,
        96,
        32,
        73,
        2,
        98,
        1,
        56,
        0,
        93,
        12,
        99,
        99,
        62,
        3,
        48,
        1,
        68,
        88,
        10,
        18,
        99,
        0,
        42,
        0,
        26,
        34,
        0,
        82,
        35,
        39,
        2,
        80,
        1,
        98,
        99,
        54,
        99,
        99,
        26,
        71,
        67,
        65,
        94,
        47,
        99,
        99,
        99,
        98,
        99,
        85,
        1,
        19
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        145,
        81,
        198,
        5,
        0,
        1,
        10,
        63,
        110,
        1,
        199,
        30,
        162,
        89,
        171,
        61,
        146,
        189,
        116,
        2,
        155,
        127,
        41,
        159,
        6,
        63,
        63,
        1,
        109,
        67,
        51,
        0,
        1,
        92,
        168,
        27,
        20,
        198,
        65,
        80,
        0,
        92,
        0,
        33,
        1,
        138,
        124,
        0,
        147,
        28,
        169,
        68
      ],
      "weeklyWasted": [
        168,
        27,
        20,
        198,
        65,
        80,
        0,
        92,
        0,
        33,
        1,
        138,
        124,
        0,
        147,
        28,
        169,
        68,
        91,
        26,
        12,
        2,
        30,
        36,
        99,
        18,
        68,
        79,
        99,
        94,
        94,
        3,
        18,
        98,
        66,
        60,
        6,
        99,
        2,
        0,
        97,
        78,
        17,
        60,
        80,
        75,
        74,
        81,
        15,
        59,
        30,
        84,
        0,
        0,
        26,
        0,
        0,
        28,
        19,
        93,
        87,
        79,
        85,
        58,
        99,
        98,
        25,
        31,
        38,
        58
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        126,
        2,
        198,
        116,
        104,
        1,
        164,
        197,
        2,
        10,
        199,
        78,
        63,
        117,
        136,
        176,
        109,
        128,
        189,
        13,
        151,
        66,
        81,
        1,
        152,
        52,
        160,
        197,
        81,
        122,
        28,
        43,
        10,
        199,
        12,
        0,
        31,
        140,
        83,
        28,
        50,
        136,
        0,
        32,
        199,
        54,
        0,
        108,
        198,
        93,
        164,
        75
      ],
      "weeklyWasted": [
        12,
        0,
        31,
        140,
        83,
        28,
        50,
        136,
        0,
        32,
        199,
        54,
        0,
        108,
        198,
        93,
        164,
        75,
        5,
        56,
        0,
        2,
        39,
        31,
        99,
        0,
        99,
        50,
        36,
        61,
        43,
        79,
        77,
        11,
        23,
        40,
        69,
        86,
        43,
        34,
        50,
        20,
        22,
        99,
        0,
        1,
        65,
        29,
        2,
        25,
        51,
        57,
        11,
        51,
        96,
        66,
        23,
        99,
        59,
        1,
        99,
        69,
        94,
        62,
        48,
        98,
        81,
        99,
        99,
        22
      ]
    },
    {
      "id": "958C4CED",
      "weeklyUsage": [
        199,
        191,
        67,
        5,
        87,
        2,
        3,
        110,
        148,
        117,
        57,
        2,
        132,
        94,
        187,
        130,
        192,
        129,
        162,
        27,
        162,
        147,
        8,
        140,
        3,
        198,
        164,
        141,
        199,
        6,
        73,
        2,
        13,
        87,
        69,
        199,
        95,
        136,
        10,
        26,
        39,
        51,
        71,
        168,
        100,
        196,
        23,
        59,
        198,
        54,
        123,
        3
      ],
      "weeklyWasted": [
        69,
        199,
        95,
        136,
        10,
        26,
        39,
        51,
        71,
        168,
        100,
        196,
        23,
        59,
        198,
        54,
        123,
        3,
        79,
        1,
        10,
        34,
        98,
        1,
        74,
        16,
        99,
        14,
        99,
        79,
        73,
        99,
        20,
        98,
        39,
        84,
        83,
        72,
        2,
        46,
        0,
        31,
        0,
        75,
        33,
        63,
        2,
        35,
        18,
        66,
        1,
        17,
        25,
        94,
        99,
        91,
        89,
        59,
        7,
        33,
        99,
        1,
        18,
        0,
        97,
        98,
        50,
        89,
        53,
        2
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyUsage": [
        199,
        146,
        65,
        39,
        57,
        174,
        40,
        60,
        49,
        25,
        3,
        95,
        177,
        0,
        199,
        155,
        182,
        189,
        95,
        2,
        100,
        4,
        150,
        11,
        3,
        119,
        71,
        44,
        0,
        34,
        53,
        0,
        79,
        57,
        4,
        199,
        198,
        102,
        46,
        43,
        0,
        116,
        14,
        85,
        1,
        4,
        37,
        124,
        198,
        107,
        2,
        156
      ],
      "weeklyWasted": [
        4,
        199,
        198,
        102,
        46,
        43,
        0,
        116,
        14,
        85,
        1,
        4,
        37,
        124,
        198,
        107,
        2,
        156,
        1,
        67,
        44,
        2,
        98,
        55,
        48,
        0,
        77,
        9,
        99,
        36,
        72,
        99,
        58,
        68,
        0,
        99,
        11,
        45,
        33,
        81,
        30,
        28,
        99,
        36,
        98,
        28,
        35,
        25,
        2,
        35,
        1,
        75,
        60,
        83,
        36,
        61,
        49,
        99,
        28,
        0,
        99,
        56,
        85,
        0,
        99,
        93,
        54,
        99,
        37,
        1
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        154,
        104,
        161,
        5,
        0,
        1,
        165,
        153,
        47,
        48,
        158,
        35,
        95,
        122,
        190,
        3,
        150,
        199,
        44,
        136,
        34,
        104,
        41,
        24,
        197,
        118,
        173,
        1,
        69,
        141,
        199,
        154,
        8,
        152,
        36,
        199,
        49,
        64,
        85,
        0,
        56,
        62,
        136,
        2,
        112,
        177,
        0,
        142,
        198,
        147,
        172,
        19
      ],
      "weeklyWasted": [
        36,
        199,
        49,
        64,
        85,
        0,
        56,
        62,
        136,
        2,
        112,
        177,
        0,
        142,
        198,
        147,
        172,
        19,
        11,
        23,
        0,
        61,
        78,
        8,
        40,
        42,
        36,
        0,
        11,
        1,
        42,
        67,
        55,
        1,
        19,
        47,
        1,
        78,
        81,
        43,
        0,
        36,
        99,
        4,
        67,
        59,
        47,
        34,
        2,
        84,
        31,
        54,
        95,
        0,
        99,
        99,
        99,
        74,
        0,
        83,
        67,
        67,
        82,
        23,
        80,
        81,
        0,
        93,
        55,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        199,
        2,
        182,
        195,
        69,
        189,
        132,
        26,
        133,
        100,
        71,
        2,
        120,
        3,
        199,
        66,
        192,
        1,
        82,
        2,
        84,
        74,
        41,
        195,
        89,
        198,
        135,
        166,
        30,
        97,
        0,
        177,
        31,
        182,
        86,
        0,
        193,
        149,
        52,
        117,
        143,
        5,
        0,
        0,
        1,
        91,
        69,
        198,
        191,
        29,
        44,
        137
      ],
      "weeklyWasted": [
        86,
        0,
        193,
        149,
        52,
        117,
        143,
        5,
        0,
        0,
        1,
        91,
        69,
        198,
        191,
        29,
        44,
        137,
        29,
        1,
        25,
        52,
        65,
        1,
        31,
        0,
        99,
        31,
        84,
        48,
        75,
        98,
        97,
        98,
        40,
        83,
        44,
        0,
        3,
        99,
        97,
        4,
        74,
        40,
        27,
        1,
        54,
        1,
        2,
        31,
        35,
        92,
        92,
        29,
        1,
        8,
        0,
        70,
        41,
        0,
        87,
        59,
        60,
        73,
        81,
        76,
        69,
        77,
        99,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        199,
        2,
        198,
        83,
        98,
        197,
        108,
        182,
        148,
        37,
        95,
        29,
        197,
        48,
        148,
        77,
        169,
        163,
        171,
        15,
        197,
        82,
        192,
        135,
        36,
        167,
        138,
        85,
        167,
        55,
        54,
        113,
        37,
        199,
        164,
        74,
        75,
        162,
        134,
        5,
        0,
        84,
        140,
        99,
        1,
        79,
        159,
        43,
        198,
        174,
        118,
        46
      ],
      "weeklyWasted": [
        164,
        74,
        75,
        162,
        134,
        5,
        0,
        84,
        140,
        99,
        1,
        79,
        159,
        43,
        198,
        174,
        118,
        46,
        1,
        3,
        99,
        36,
        84,
        90,
        99,
        46,
        15,
        46,
        89,
        91,
        66,
        64,
        7,
        98,
        46,
        99,
        26,
        99,
        45,
        99,
        0,
        56,
        81,
        85,
        38,
        58,
        47,
        77,
        2,
        57,
        1,
        80,
        76,
        58,
        22,
        98,
        13,
        75,
        56,
        70,
        27,
        13,
        33,
        1,
        41,
        47,
        43,
        56,
        51,
        33
      ]
    },
    {
      "id": "D33377F5",
      "weeklyUsage": [
        199,
        68,
        86,
        30,
        133,
        197,
        18,
        3,
        128,
        125,
        59,
        2,
        198,
        65,
        127,
        92,
        87,
        178,
        22,
        2,
        48,
        4,
        57,
        18,
        77,
        46,
        192,
        1,
        72,
        121,
        119,
        89,
        125,
        84,
        67,
        143,
        159,
        149,
        10,
        13,
        84,
        33,
        0,
        22,
        1,
        4,
        0,
        129,
        179,
        161,
        2,
        189
      ],
      "weeklyWasted": [
        67,
        143,
        159,
        149,
        10,
        13,
        84,
        33,
        0,
        22,
        1,
        4,
        0,
        129,
        179,
        161,
        2,
        189,
        15,
        14,
        82,
        4,
        92,
        48,
        34,
        0,
        90,
        16,
        0,
        62,
        60,
        60,
        26,
        18,
        54,
        33,
        18,
        84,
        47,
        48,
        97,
        0,
        78,
        99,
        58,
        64,
        95,
        32,
        32,
        24,
        40,
        98,
        71,
        0,
        17,
        99,
        28,
        83,
        0,
        22,
        11,
        49,
        93,
        25,
        99,
        49,
        66,
        55,
        22,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        125,
        116,
        4,
        45,
        40,
        69,
        91,
        124,
        56,
        1,
        196,
        71,
        173,
        0,
        187,
        79,
        11,
        161,
        117,
        197,
        120,
        4,
        78,
        145,
        3,
        166,
        65,
        1,
        199,
        20,
        111,
        0,
        102,
        199,
        126,
        72,
        25,
        5,
        29,
        179,
        102,
        26,
        145,
        85,
        1,
        180,
        0,
        0,
        198,
        113,
        181,
        60
      ],
      "weeklyWasted": [
        126,
        72,
        25,
        5,
        29,
        179,
        102,
        26,
        145,
        85,
        1,
        180,
        0,
        0,
        198,
        113,
        181,
        60,
        99,
        70,
        33,
        2,
        46,
        1,
        99,
        6,
        32,
        16,
        84,
        91,
        47,
        84,
        41,
        1,
        0,
        82,
        6,
        99,
        59,
        21,
        0,
        3,
        52,
        34,
        48,
        30,
        27,
        30,
        2,
        53,
        78,
        75,
        99,
        29,
        72,
        49,
        99,
        46,
        0,
        0,
        55,
        99,
        99,
        43,
        36,
        35,
        3,
        86,
        99,
        21
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        143,
        19,
        0,
        5,
        3,
        1,
        78,
        59,
        126,
        9,
        0,
        2,
        90,
        0,
        179,
        3,
        192,
        130,
        126,
        70,
        6,
        157,
        197,
        163,
        62,
        164,
        177,
        1,
        199,
        62,
        0,
        42,
        1,
        157,
        98,
        199,
        98,
        39,
        10,
        0,
        22,
        53,
        57,
        0,
        1,
        95,
        0,
        25,
        169,
        60,
        2,
        119
      ],
      "weeklyWasted": [
        98,
        199,
        98,
        39,
        10,
        0,
        22,
        53,
        57,
        0,
        1,
        95,
        0,
        25,
        169,
        60,
        2,
        119,
        1,
        29,
        99,
        2,
        57,
        47,
        99,
        0,
        98,
        66,
        88,
        63,
        73,
        94,
        65,
        44,
        22,
        79,
        96,
        17,
        2,
        69,
        51,
        76,
        47,
        59,
        67,
        66,
        93,
        1,
        24,
        87,
        64,
        98,
        99,
        0,
        31,
        64,
        59,
        28,
        94,
        31,
        99,
        65,
        49,
        0,
        99,
        79,
        2,
        99,
        79,
        81
      ]
    },
    {
      "id": "42A1729B",
      "weeklyUsage": [
        143,
        24,
        198,
        168,
        152,
        98,
        36,
        102,
        155,
        55,
        75,
        2,
        48,
        0,
        167,
        152,
        192,
        123,
        52,
        75,
        86,
        152,
        76,
        161,
        96,
        152,
        101,
        27,
        105,
        49,
        157,
        0,
        85,
        152,
        147,
        41,
        139,
        192,
        122,
        118,
        199,
        0,
        131,
        0,
        1,
        104,
        50,
        198,
        198,
        12,
        8,
        143
      ],
      "weeklyWasted": [
        147,
        41,
        139,
        192,
        122,
        118,
        199,
        0,
        131,
        0,
        1,
        104,
        50,
        198,
        198,
        12,
        8,
        143,
        54,
        98,
        99,
        39,
        59,
        1,
        39,
        58,
        99,
        29,
        53,
        57,
        63,
        61,
        71,
        98,
        47,
        80,
        99,
        29,
        47,
        0,
        15,
        16,
        3,
        71,
        28,
        51,
        63,
        1,
        4,
        96,
        1,
        98,
        64,
        39,
        1,
        35,
        99,
        1,
        0,
        51,
        99,
        88,
        99,
        5,
        0,
        98,
        46,
        49,
        13,
        22
      ]
    },
    {
      "id": "FB70040E",
      "weeklyUsage": [
        41,
        57,
        81,
        88,
        198,
        127,
        105,
        70,
        157,
        1,
        143,
        48,
        148,
        115,
        1,
        97,
        32,
        66,
        124,
        197,
        166,
        128,
        75,
        137,
        14,
        72,
        84,
        162,
        70,
        128,
        175,
        0,
        131,
        25,
        115,
        113,
        140,
        5,
        39,
        142,
        120,
        148,
        60,
        0,
        81,
        196,
        89,
        68,
        198,
        199,
        155,
        169
      ],
      "weeklyWasted": [
        115,
        113,
        140,
        5,
        39,
        142,
        120,
        148,
        60,
        0,
        81,
        196,
        89,
        68,
        198,
        199,
        155,
        169,
        99,
        4,
        59,
        3,
        98,
        1,
        0,
        0,
        99,
        14,
        99,
        99,
        99,
        94,
        0,
        76,
        35,
        81,
        47,
        32,
        99,
        63,
        28,
        0,
        5,
        14,
        56,
        13,
        2,
        14,
        4,
        40,
        70,
        55,
        6,
        11,
        47,
        58,
        0,
        99,
        60,
        41,
        78,
        99,
        45,
        0,
        84,
        84,
        99,
        88,
        99,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyUsage": [
        32,
        9,
        0,
        61,
        0,
        5,
        181,
        3,
        199,
        1,
        199,
        14,
        57,
        0,
        82,
        141,
        192,
        194,
        46,
        107,
        107,
        36,
        197,
        156,
        3,
        109,
        110,
        110,
        90,
        184,
        37,
        43,
        79,
        34,
        94,
        123,
        198,
        132,
        54,
        45,
        62,
        31,
        0,
        0,
        53,
        182,
        52,
        93,
        63,
        65,
        68,
        3
      ],
      "weeklyWasted": [
        94,
        123,
        198,
        132,
        54,
        45,
        62,
        31,
        0,
        0,
        53,
        182,
        52,
        93,
        63,
        65,
        68,
        3,
        76,
        64,
        96,
        47,
        60,
        45,
        99,
        25,
        49,
        21,
        0,
        97,
        6,
        74,
        56,
        55,
        98,
        75,
        63,
        6,
        58,
        41,
        0,
        26,
        0,
        67,
        36,
        65,
        67,
        58,
        66,
        76,
        17,
        67,
        96,
        35,
        12,
        46,
        11,
        99,
        4,
        56,
        79,
        99,
        49,
        35,
        54,
        35,
        11,
        61,
        8,
        65
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        165,
        2,
        198,
        10,
        75,
        197,
        63,
        109,
        197,
        39,
        77,
        2,
        177,
        199,
        179,
        151,
        192,
        57,
        3,
        96,
        197,
        4,
        2,
        195,
        3,
        88,
        123,
        146,
        42,
        0,
        66,
        0,
        168,
        199,
        5,
        20,
        157,
        110,
        10,
        43,
        20,
        86,
        0,
        2,
        29,
        5,
        85,
        192,
        192,
        199,
        136,
        71
      ],
      "weeklyWasted": [
        5,
        20,
        157,
        110,
        10,
        43,
        20,
        86,
        0,
        2,
        29,
        5,
        85,
        192,
        192,
        199,
        136,
        71,
        8,
        6,
        86,
        2,
        98,
        20,
        75,
        0,
        73,
        18,
        59,
        65,
        69,
        85,
        97,
        9,
        33,
        31,
        13,
        70,
        67,
        39,
        0,
        99,
        34,
        86,
        57,
        91,
        27,
        64,
        2,
        18,
        67,
        98,
        80,
        33,
        21,
        48,
        80,
        44,
        23,
        58,
        99,
        46,
        63,
        67,
        70,
        98,
        31,
        99,
        98,
        1
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyUsage": [
        199,
        8,
        98,
        27,
        118,
        197,
        84,
        47,
        69,
        1,
        199,
        107,
        42,
        37,
        1,
        62,
        85,
        40,
        166,
        197,
        1,
        190,
        55,
        169,
        58,
        1,
        180,
        32,
        111,
        150,
        80,
        13,
        1,
        127,
        4,
        0,
        198,
        199,
        151,
        0,
        199,
        4,
        109,
        39,
        21,
        103,
        0,
        198,
        198,
        100,
        125,
        196
      ],
      "weeklyWasted": [
        4,
        0,
        198,
        199,
        151,
        0,
        199,
        4,
        109,
        39,
        21,
        103,
        0,
        198,
        198,
        100,
        125,
        196,
        98,
        30,
        87,
        23,
        98,
        1,
        79,
        31,
        95,
        89,
        0,
        29,
        3,
        82,
        32,
        98,
        73,
        91,
        32,
        34,
        75,
        53,
        19,
        18,
        13,
        44,
        0,
        59,
        44,
        38,
        2,
        64,
        45,
        1,
        99,
        52,
        57,
        58,
        52,
        1,
        16,
        51,
        99,
        77,
        43,
        50,
        0,
        98,
        17,
        64,
        24,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        86,
        163,
        0,
        5,
        112,
        90,
        23,
        3,
        158,
        83,
        100,
        45,
        72,
        98,
        163,
        81,
        51,
        167,
        3,
        167,
        106,
        134,
        116,
        161,
        46,
        61,
        78,
        1,
        153,
        126,
        138,
        75,
        1,
        65,
        54,
        151,
        51,
        199,
        34,
        199,
        81,
        29,
        35,
        96,
        112,
        156,
        15,
        191,
        198,
        37,
        40,
        3
      ],
      "weeklyWasted": [
        54,
        151,
        51,
        199,
        34,
        199,
        81,
        29,
        35,
        96,
        112,
        156,
        15,
        191,
        198,
        37,
        40,
        3,
        70,
        1,
        99,
        65,
        98,
        62,
        94,
        27,
        64,
        0,
        69,
        44,
        42,
        58,
        9,
        79,
        37,
        64,
        47,
        78,
        86,
        6,
        30,
        31,
        8,
        36,
        1,
        31,
        9,
        42,
        2,
        97,
        1,
        52,
        99,
        28,
        27,
        61,
        99,
        1,
        90,
        1,
        50,
        12,
        85,
        32,
        77,
        98,
        83,
        99,
        86,
        3
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        199,
        97,
        58,
        84,
        55,
        1,
        194,
        10,
        143,
        26,
        0,
        97,
        91,
        0,
        147,
        131,
        0,
        52,
        158,
        197,
        197,
        134,
        152,
        195,
        197,
        177,
        185,
        1,
        199,
        198,
        119,
        0,
        61,
        167,
        58,
        29,
        148,
        26,
        20,
        136,
        199,
        0,
        146,
        1,
        17,
        108,
        133,
        32,
        198,
        140,
        110,
        113
      ],
      "weeklyWasted": [
        58,
        29,
        148,
        26,
        20,
        136,
        199,
        0,
        146,
        1,
        17,
        108,
        133,
        32,
        198,
        140,
        110,
        113,
        74,
        1,
        51,
        47,
        92,
        68,
        69,
        0,
        18,
        44,
        69,
        40,
        35,
        73,
        42,
        49,
        12,
        54,
        79,
        99,
        99,
        0,
        27,
        0,
        99,
        33,
        63,
        1,
        43,
        1,
        2,
        56,
        1,
        86,
        99,
        25,
        82,
        60,
        70,
        99,
        22,
        74,
        66,
        99,
        49,
        0,
        61,
        98,
        27,
        12,
        99,
        2
      ]
    },
    {
      "id": "11660B2E",
      "weeklyUsage": [
        171,
        2,
        62,
        195,
        136,
        102,
        94,
        43,
        32,
        1,
        0,
        2,
        64,
        6,
        199,
        158,
        0,
        164,
        78,
        181,
        109,
        17,
        197,
        141,
        3,
        198,
        143,
        1,
        177,
        16,
        87,
        40,
        1,
        92,
        24,
        0,
        181,
        5,
        117,
        81,
        107,
        0,
        35,
        18,
        1,
        184,
        0,
        157,
        198,
        115,
        151,
        173
      ],
      "weeklyWasted": [
        24,
        0,
        181,
        5,
        117,
        81,
        107,
        0,
        35,
        18,
        1,
        184,
        0,
        157,
        198,
        115,
        151,
        173,
        29,
        35,
        42,
        37,
        72,
        80,
        12,
        61,
        11,
        26,
        71,
        99,
        23,
        62,
        97,
        29,
        0,
        99,
        1,
        68,
        3,
        87,
        6,
        46,
        18,
        23,
        36,
        40,
        81,
        43,
        2,
        49,
        1,
        70,
        85,
        47,
        82,
        94,
        99,
        86,
        27,
        0,
        1,
        48,
        58,
        99,
        99,
        96,
        68,
        98,
        32,
        74
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        66,
        82,
        66,
        24,
        0,
        56,
        194,
        197,
        5,
        1,
        199,
        31,
        153,
        37,
        87,
        3,
        192,
        159,
        197,
        69,
        163,
        96,
        2,
        1,
        126,
        1,
        180,
        1,
        82,
        181,
        173,
        0,
        1,
        80,
        80,
        84,
        0,
        109,
        58,
        11,
        78,
        38,
        0,
        62,
        172,
        159,
        12,
        49,
        107,
        142,
        163,
        3
      ],
      "weeklyWasted": [
        80,
        84,
        0,
        109,
        58,
        11,
        78,
        38,
        0,
        62,
        172,
        159,
        12,
        49,
        107,
        142,
        163,
        3,
        31,
        34,
        0,
        2,
        80,
        49,
        38,
        55,
        92,
        14,
        69,
        21,
        99,
        95,
        37,
        98,
        79,
        67,
        24,
        71,
        65,
        72,
        48,
        0,
        29,
        59,
        0,
        97,
        99,
        39,
        2,
        78,
        5,
        98,
        99,
        16,
        82,
        99,
        31,
        53,
        2,
        72,
        74,
        80,
        53,
        71,
        52,
        98,
        89,
        99,
        5,
        26
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        136,
        2,
        110,
        156,
        114,
        23,
        194,
        30,
        182,
        1,
        44,
        33,
        2,
        41,
        137,
        46,
        192,
        141,
        126,
        53,
        122,
        68,
        61,
        179,
        110,
        111,
        132,
        1,
        90,
        198,
        11,
        128,
        1,
        75,
        158,
        64,
        59,
        47,
        102,
        152,
        84,
        0,
        63,
        14,
        117,
        151,
        32,
        198,
        198,
        157,
        15,
        3
      ],
      "weeklyWasted": [
        158,
        64,
        59,
        47,
        102,
        152,
        84,
        0,
        63,
        14,
        117,
        151,
        32,
        198,
        198,
        157,
        15,
        3,
        36,
        26,
        0,
        44,
        42,
        98,
        55,
        0,
        93,
        21,
        17,
        1,
        99,
        99,
        81,
        1,
        65,
        63,
        63,
        0,
        67,
        0,
        69,
        35,
        73,
        97,
        31,
        14,
        36,
        52,
        15,
        89,
        1,
        98,
        99,
        65,
        9,
        87,
        23,
        7,
        40,
        18,
        99,
        65,
        99,
        53,
        12,
        66,
        99,
        1,
        55,
        59
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        32,
        2,
        66,
        5,
        0,
        129,
        153,
        102,
        24,
        63,
        45,
        5,
        195,
        0,
        199,
        87,
        83,
        178,
        57,
        97,
        144,
        4,
        185,
        38,
        13,
        144,
        197,
        23,
        199,
        42,
        96,
        0,
        146,
        148,
        165,
        35,
        87,
        66,
        87,
        60,
        26,
        109,
        0,
        28,
        34,
        131,
        195,
        35,
        198,
        83,
        124,
        17
      ],
      "weeklyWasted": [
        165,
        35,
        87,
        66,
        87,
        60,
        26,
        109,
        0,
        28,
        34,
        131,
        195,
        35,
        198,
        83,
        124,
        17,
        1,
        53,
        99,
        2,
        30,
        21,
        99,
        6,
        66,
        0,
        99,
        75,
        74,
        78,
        97,
        1,
        98,
        12,
        9,
        0,
        68,
        0,
        14,
        0,
        29,
        99,
        0,
        11,
        99,
        1,
        2,
        45,
        53,
        74,
        99,
        2,
        37,
        29,
        97,
        99,
        91,
        0,
        78,
        75,
        52,
        37,
        7,
        98,
        60,
        99,
        27,
        16
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyUsage": [
        199,
        7,
        0,
        31,
        92,
        53,
        158,
        3,
        2,
        1,
        0,
        99,
        85,
        172,
        199,
        132,
        192,
        1,
        119,
        23,
        119,
        95,
        66,
        129,
        55,
        17,
        78,
        33,
        0,
        122,
        111,
        0,
        15,
        4,
        131,
        111,
        198,
        183,
        143,
        184,
        0,
        0,
        9,
        0,
        21,
        11,
        63,
        173,
        198,
        2,
        147,
        59
      ],
      "weeklyWasted": [
        131,
        111,
        198,
        183,
        143,
        184,
        0,
        0,
        9,
        0,
        21,
        11,
        63,
        173,
        198,
        2,
        147,
        59,
        99,
        1,
        53,
        18,
        71,
        1,
        0,
        0,
        58,
        0,
        99,
        45,
        63,
        57,
        48,
        1,
        0,
        78,
        1,
        99,
        2,
        49,
        25,
        0,
        84,
        65,
        98,
        66,
        99,
        74,
        2,
        97,
        70,
        89,
        49,
        0,
        24,
        28,
        10,
        92,
        24,
        0,
        99,
        52,
        0,
        20,
        86,
        60,
        64,
        8,
        99,
        1
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyUsage": [
        199,
        2,
        119,
        38,
        117,
        169,
        80,
        3,
        2,
        1,
        39,
        49,
        124,
        0,
        199,
        119,
        192,
        99,
        94,
        58,
        170,
        81,
        197,
        1,
        3,
        157,
        167,
        29,
        103,
        112,
        27,
        64,
        36,
        4,
        15,
        49,
        137,
        199,
        72,
        0,
        96,
        22,
        41,
        63,
        1,
        106,
        195,
        188,
        170,
        2,
        2,
        3
      ],
      "weeklyWasted": [
        15,
        49,
        137,
        199,
        72,
        0,
        96,
        22,
        41,
        63,
        1,
        106,
        195,
        188,
        170,
        2,
        2,
        3,
        1,
        32,
        45,
        26,
        98,
        1,
        15,
        0,
        31,
        65,
        34,
        82,
        44,
        49,
        97,
        98,
        18,
        75,
        27,
        52,
        2,
        77,
        39,
        24,
        0,
        32,
        37,
        69,
        81,
        65,
        2,
        60,
        62,
        64,
        16,
        5,
        82,
        68,
        12,
        54,
        48,
        0,
        40,
        67,
        42,
        66,
        69,
        74,
        12,
        99,
        99,
        28
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        78,
        51,
        198,
        56,
        175,
        112,
        194,
        36,
        93,
        41,
        46,
        20,
        187,
        150,
        199,
        151,
        146,
        185,
        33,
        131,
        171,
        41,
        161,
        144,
        3,
        1,
        136,
        44,
        131,
        145,
        109,
        55,
        197,
        126,
        191,
        53,
        0,
        5,
        27,
        0,
        0,
        0,
        33,
        6,
        109,
        93,
        0,
        64,
        152,
        161,
        4,
        79
      ],
      "weeklyWasted": [
        191,
        53,
        0,
        5,
        27,
        0,
        0,
        0,
        33,
        6,
        109,
        93,
        0,
        64,
        152,
        161,
        4,
        79,
        1,
        10,
        99,
        46,
        30,
        18,
        99,
        70,
        30,
        0,
        99,
        90,
        78,
        97,
        64,
        16,
        55,
        66,
        41,
        44,
        81,
        74,
        15,
        42,
        42,
        82,
        0,
        97,
        41,
        22,
        2,
        97,
        50,
        79,
        84,
        0,
        75,
        83,
        76,
        48,
        19,
        0,
        1,
        66,
        99,
        59,
        99,
        47,
        99,
        58,
        37,
        98
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        126,
        2,
        123,
        5,
        0,
        197,
        115,
        176,
        67,
        132,
        81,
        60,
        173,
        65,
        136,
        79,
        163,
        166,
        156,
        83,
        120,
        114,
        2,
        195,
        82,
        143,
        151,
        1,
        106,
        83,
        199,
        29,
        1,
        170,
        154,
        157,
        123,
        76,
        199,
        19,
        100,
        10,
        6,
        64,
        199,
        159,
        195,
        0,
        191,
        101,
        127,
        3
      ],
      "weeklyWasted": [
        154,
        157,
        123,
        76,
        199,
        19,
        100,
        10,
        6,
        64,
        199,
        159,
        195,
        0,
        191,
        101,
        127,
        3,
        61,
        25,
        64,
        30,
        32,
        47,
        99,
        38,
        37,
        65,
        99,
        84,
        74,
        15,
        58,
        1,
        0,
        99,
        17,
        28,
        22,
        3,
        97,
        21,
        82,
        71,
        44,
        6,
        99,
        6,
        2,
        57,
        24,
        98,
        99,
        91,
        63,
        81,
        86,
        97,
        18,
        0,
        99,
        37,
        51,
        63,
        99,
        98,
        99,
        59,
        50,
        3
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        19,
        67,
        76,
        111,
        59,
        114,
        164,
        175,
        99,
        68,
        146,
        23,
        110,
        49,
        175,
        53,
        192,
        1,
        35,
        142,
        62,
        198,
        2,
        81,
        197,
        120,
        150,
        40,
        178,
        45,
        111,
        0,
        43,
        64,
        151,
        0,
        127,
        90,
        93,
        87,
        199,
        0,
        103,
        0,
        1,
        196,
        49,
        25,
        177,
        2,
        74,
        3
      ],
      "weeklyWasted": [
        151,
        0,
        127,
        90,
        93,
        87,
        199,
        0,
        103,
        0,
        1,
        196,
        49,
        25,
        177,
        2,
        74,
        3,
        40,
        35,
        84,
        10,
        69,
        98,
        38,
        3,
        33,
        79,
        99,
        83,
        68,
        81,
        11,
        27,
        7,
        85,
        42,
        19,
        90,
        25,
        66,
        5,
        0,
        3,
        86,
        36,
        90,
        52,
        2,
        97,
        25,
        98,
        93,
        75,
        27,
        63,
        0,
        99,
        0,
        31,
        62,
        99,
        50,
        18,
        93,
        98,
        44,
        99,
        35,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        199,
        6,
        157,
        90,
        198,
        148,
        111,
        14,
        115,
        1,
        108,
        68,
        171,
        102,
        58,
        3,
        192,
        115,
        49,
        103,
        197,
        9,
        166,
        107,
        3,
        89,
        175,
        58,
        0,
        139,
        0,
        108,
        19,
        172,
        99,
        9,
        133,
        160,
        173,
        82,
        108,
        0,
        0,
        6,
        1,
        180,
        148,
        152,
        190,
        151,
        197,
        30
      ],
      "weeklyWasted": [
        99,
        9,
        133,
        160,
        173,
        82,
        108,
        0,
        0,
        6,
        1,
        180,
        148,
        152,
        190,
        151,
        197,
        30,
        64,
        1,
        25,
        2,
        98,
        21,
        0,
        0,
        99,
        96,
        99,
        54,
        1,
        53,
        51,
        19,
        98,
        38,
        36,
        99,
        54,
        89,
        59,
        0,
        90,
        62,
        8,
        76,
        83,
        32,
        2,
        46,
        74,
        40,
        18,
        39,
        33,
        62,
        36,
        68,
        0,
        24,
        41,
        82,
        99,
        70,
        99,
        98,
        80,
        1,
        36,
        63
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyUsage": [
        164,
        38,
        89,
        16,
        88,
        149,
        3,
        65,
        52,
        1,
        199,
        38,
        23,
        97,
        72,
        179,
        192,
        170,
        41,
        2,
        47,
        54,
        88,
        47,
        3,
        47,
        126,
        77,
        85,
        198,
        27,
        126,
        1,
        4,
        23,
        199,
        132,
        182,
        97,
        0,
        84,
        35,
        52,
        83,
        1,
        134,
        176,
        30,
        198,
        2,
        36,
        196
      ],
      "weeklyWasted": [
        23,
        199,
        132,
        182,
        97,
        0,
        84,
        35,
        52,
        83,
        1,
        134,
        176,
        30,
        198,
        2,
        36,
        196,
        20,
        1,
        17,
        3,
        30,
        56,
        25,
        8,
        39,
        0,
        67,
        70,
        54,
        75,
        45,
        98,
        0,
        82,
        15,
        68,
        10,
        98,
        0,
        14,
        32,
        79,
        63,
        77,
        2,
        53,
        45,
        54,
        1,
        93,
        0,
        17,
        43,
        66,
        17,
        63,
        40,
        52,
        99,
        59,
        92,
        71,
        99,
        78,
        38,
        73,
        99,
        30
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyUsage": [
        184,
        68,
        90,
        29,
        18,
        197,
        151,
        100,
        72,
        22,
        120,
        167,
        198,
        0,
        76,
        74,
        71,
        183,
        165,
        144,
        38,
        4,
        121,
        67,
        3,
        33,
        197,
        91,
        121,
        150,
        55,
        196,
        75,
        42,
        133,
        86,
        118,
        199,
        146,
        0,
        3,
        0,
        144,
        155,
        55,
        191,
        0,
        25,
        198,
        47,
        121,
        60
      ],
      "weeklyWasted": [
        133,
        86,
        118,
        199,
        146,
        0,
        3,
        0,
        144,
        155,
        55,
        191,
        0,
        25,
        198,
        47,
        121,
        60,
        32,
        31,
        0,
        40,
        98,
        98,
        66,
        0,
        93,
        0,
        95,
        92,
        54,
        76,
        63,
        35,
        0,
        71,
        47,
        34,
        40,
        0,
        85,
        0,
        0,
        99,
        51,
        97,
        99,
        69,
        2,
        65,
        26,
        58,
        99,
        63,
        69,
        46,
        63,
        99,
        99,
        0,
        82,
        99,
        69,
        10,
        0,
        98,
        99,
        90,
        41,
        1
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyUsage": [
        43,
        58,
        148,
        5,
        0,
        27,
        90,
        68,
        2,
        67,
        199,
        2,
        85,
        15,
        187,
        150,
        142,
        120,
        7,
        87,
        19,
        13,
        5,
        112,
        189,
        20,
        197,
        72,
        165,
        118,
        28,
        196,
        114,
        174,
        67,
        199,
        0,
        16,
        10,
        0,
        140,
        89,
        26,
        32,
        147,
        150,
        0,
        33,
        35,
        39,
        23,
        47
      ],
      "weeklyWasted": [
        67,
        199,
        0,
        16,
        10,
        0,
        140,
        89,
        26,
        32,
        147,
        150,
        0,
        33,
        35,
        39,
        23,
        47,
        1,
        1,
        0,
        2,
        42,
        73,
        84,
        1,
        41,
        0,
        99,
        47,
        74,
        99,
        0,
        1,
        50,
        37,
        50,
        1,
        64,
        0,
        3,
        21,
        84,
        66,
        49,
        76,
        59,
        53,
        33,
        48,
        1,
        98,
        0,
        0,
        19,
        57,
        74,
        85,
        34,
        34,
        78,
        68,
        99,
        0,
        10,
        98,
        42,
        52,
        99,
        92
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        60,
        94,
        43,
        51,
        57,
        125,
        194,
        184,
        2,
        30,
        104,
        48,
        154,
        191,
        199,
        92,
        88,
        165,
        54,
        39,
        162,
        131,
        127,
        117,
        45,
        198,
        44,
        24,
        127,
        198,
        25,
        96,
        197,
        181,
        198,
        131,
        91,
        5,
        101,
        10,
        7,
        122,
        0,
        89,
        188,
        196,
        0,
        22,
        198,
        149,
        189,
        51
      ],
      "weeklyWasted": [
        198,
        131,
        91,
        5,
        101,
        10,
        7,
        122,
        0,
        89,
        188,
        196,
        0,
        22,
        198,
        149,
        189,
        51,
        29,
        23,
        99,
        27,
        98,
        98,
        99,
        0,
        99,
        0,
        99,
        1,
        35,
        57,
        0,
        5,
        22,
        99,
        1,
        80,
        22,
        53,
        26,
        0,
        99,
        81,
        47,
        66,
        99,
        96,
        15,
        48,
        60,
        98,
        65,
        0,
        99,
        99,
        30,
        55,
        99,
        32,
        34,
        14,
        73,
        95,
        76,
        85,
        54,
        88,
        99,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyUsage": [
        199,
        69,
        122,
        44,
        109,
        101,
        186,
        3,
        66,
        1,
        61,
        2,
        2,
        0,
        104,
        3,
        192,
        78,
        61,
        197,
        197,
        198,
        161,
        1,
        107,
        52,
        44,
        66,
        89,
        198,
        128,
        111,
        162,
        4,
        185,
        0,
        198,
        145,
        150,
        35,
        23,
        0,
        0,
        157,
        151,
        4,
        97,
        148,
        198,
        45,
        190,
        3
      ],
      "weeklyWasted": [
        185,
        0,
        198,
        145,
        150,
        35,
        23,
        0,
        0,
        157,
        151,
        4,
        97,
        148,
        198,
        45,
        190,
        3,
        53,
        97,
        99,
        59,
        98,
        47,
        45,
        67,
        32,
        0,
        46,
        99,
        1,
        91,
        27,
        26,
        98,
        80,
        62,
        5,
        2,
        0,
        0,
        26,
        28,
        99,
        8,
        83,
        59,
        79,
        2,
        59,
        22,
        46,
        99,
        78,
        85,
        99,
        61,
        53,
        11,
        0,
        57,
        99,
        75,
        97,
        78,
        98,
        43,
        29,
        48,
        36
      ]
    },
    {
      "id": "FB70040E",
      "weeklyUsage": [
        159,
        2,
        74,
        5,
        0,
        30,
        157,
        37,
        105,
        1,
        124,
        80,
        196,
        167,
        48,
        86,
        122,
        155,
        87,
        197,
        183,
        40,
        197,
        179,
        80,
        86,
        173,
        33,
        199,
        101,
        57,
        23,
        24,
        137,
        23,
        44,
        73,
        22,
        142,
        109,
        199,
        45,
        0,
        53,
        128,
        193,
        140,
        42,
        198,
        121,
        197,
        43
      ],
      "weeklyWasted": [
        23,
        44,
        73,
        22,
        142,
        109,
        199,
        45,
        0,
        53,
        128,
        193,
        140,
        42,
        198,
        121,
        197,
        43,
        53,
        19,
        12,
        3,
        80,
        48,
        57,
        78,
        37,
        27,
        54,
        50,
        75,
        99,
        42,
        46,
        98,
        55,
        27,
        87,
        64,
        74,
        29,
        31,
        31,
        76,
        3,
        7,
        37,
        36,
        2,
        62,
        61,
        80,
        99,
        5,
        75,
        99,
        65,
        15,
        26,
        0,
        96,
        99,
        99,
        77,
        46,
        70,
        36,
        55,
        33,
        67
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        18,
        149,
        30,
        5,
        198,
        35,
        108,
        3,
        65,
        1,
        186,
        111,
        94,
        0,
        116,
        3,
        57,
        199,
        183,
        45,
        1,
        166,
        197,
        195,
        28,
        85,
        136,
        122,
        106,
        158,
        199,
        0,
        173,
        186,
        154,
        84,
        3,
        199,
        145,
        0,
        149,
        199,
        46,
        149,
        1,
        114,
        114,
        192,
        198,
        93,
        126,
        196
      ],
      "weeklyWasted": [
        154,
        84,
        3,
        199,
        145,
        0,
        149,
        199,
        46,
        149,
        1,
        114,
        114,
        192,
        198,
        93,
        126,
        196,
        38,
        19,
        0,
        51,
        64,
        10,
        99,
        13,
        0,
        47,
        99,
        1,
        46,
        99,
        12,
        1,
        98,
        17,
        8,
        79,
        67,
        30,
        97,
        0,
        57,
        99,
        98,
        3,
        24,
        1,
        58,
        77,
        38,
        76,
        99,
        6,
        80,
        77,
        65,
        37,
        0,
        66,
        56,
        99,
        2,
        31,
        65,
        54,
        29,
        89,
        52,
        47
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        7,
        62,
        198,
        5,
        0,
        62,
        174,
        92,
        199,
        117,
        12,
        2,
        157,
        199,
        190,
        188,
        110,
        187,
        44,
        94,
        197,
        145,
        89,
        168,
        3,
        63,
        70,
        58,
        62,
        0,
        14,
        196,
        35,
        184,
        198,
        163,
        0,
        69,
        184,
        0,
        28,
        93,
        0,
        52,
        91,
        179,
        69,
        198,
        198,
        176,
        133,
        169
      ],
      "weeklyWasted": [
        198,
        163,
        0,
        69,
        184,
        0,
        28,
        93,
        0,
        52,
        91,
        179,
        69,
        198,
        198,
        176,
        133,
        169,
        1,
        25,
        38,
        35,
        53,
        98,
        69,
        0,
        57,
        45,
        99,
        24,
        45,
        67,
        34,
        45,
        14,
        85,
        11,
        22,
        2,
        35,
        46,
        99,
        21,
        99,
        98,
        78,
        98,
        96,
        14,
        97,
        35,
        98,
        99,
        2,
        99,
        33,
        99,
        54,
        9,
        54,
        36,
        99,
        41,
        99,
        89,
        98,
        10,
        63,
        99,
        27
      ]
    },
    {
      "id": "3D26994F",
      "weeklyUsage": [
        155,
        15,
        112,
        5,
        154,
        107,
        66,
        112,
        53,
        1,
        188,
        40,
        183,
        183,
        199,
        164,
        192,
        138,
        85,
        107,
        83,
        45,
        24,
        1,
        3,
        113,
        186,
        108,
        128,
        3,
        127,
        133,
        121,
        4,
        17,
        199,
        76,
        78,
        31,
        0,
        11,
        143,
        7,
        0,
        98,
        96,
        100,
        177,
        112,
        56,
        161,
        17
      ],
      "weeklyWasted": [
        17,
        199,
        76,
        78,
        31,
        0,
        11,
        143,
        7,
        0,
        98,
        96,
        100,
        177,
        112,
        56,
        161,
        17,
        17,
        5,
        43,
        2,
        39,
        64,
        38,
        12,
        85,
        24,
        67,
        64,
        15,
        99,
        80,
        30,
        40,
        1,
        27,
        14,
        43,
        46,
        38,
        27,
        99,
        47,
        98,
        1,
        35,
        33,
        76,
        72,
        1,
        98,
        78,
        51,
        80,
        99,
        54,
        60,
        36,
        0,
        26,
        99,
        92,
        26,
        99,
        36,
        56,
        66,
        68,
        67
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        156,
        2,
        113,
        195,
        198,
        31,
        194,
        53,
        132,
        1,
        162,
        89,
        157,
        0,
        18,
        71,
        170,
        105,
        197,
        34,
        197,
        198,
        26,
        80,
        122,
        198,
        176,
        92,
        199,
        36,
        107,
        36,
        24,
        146,
        148,
        0,
        111,
        95,
        58,
        65,
        170,
        71,
        55,
        197,
        1,
        104,
        45,
        105,
        198,
        147,
        20,
        135
      ],
      "weeklyWasted": [
        148,
        0,
        111,
        95,
        58,
        65,
        170,
        71,
        55,
        197,
        1,
        104,
        45,
        105,
        198,
        147,
        20,
        135,
        15,
        1,
        0,
        57,
        91,
        89,
        51,
        30,
        30,
        49,
        99,
        93,
        71,
        99,
        32,
        4,
        0,
        30,
        41,
        35,
        91,
        48,
        0,
        99,
        49,
        1,
        8,
        12,
        99,
        1,
        2,
        51,
        47,
        81,
        99,
        99,
        74,
        37,
        55,
        99,
        8,
        21,
        83,
        99,
        29,
        1,
        66,
        98,
        68,
        99,
        58,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        154,
        155,
        129,
        89,
        132,
        1,
        194,
        20,
        141,
        160,
        78,
        27,
        192,
        59,
        170,
        108,
        192,
        199,
        3,
        138,
        108,
        38,
        101,
        160,
        108,
        198,
        168,
        59,
        132,
        130,
        199,
        40,
        86,
        101,
        122,
        159,
        101,
        13,
        81,
        199,
        27,
        155,
        116,
        137,
        1,
        147,
        0,
        18,
        198,
        150,
        29,
        135
      ],
      "weeklyWasted": [
        122,
        159,
        101,
        13,
        81,
        199,
        27,
        155,
        116,
        137,
        1,
        147,
        0,
        18,
        198,
        150,
        29,
        135,
        21,
        1,
        77,
        2,
        53,
        98,
        49,
        42,
        95,
        41,
        68,
        27,
        78,
        94,
        29,
        1,
        37,
        25,
        2,
        76,
        79,
        0,
        0,
        8,
        9,
        20,
        36,
        97,
        88,
        1,
        2,
        70,
        6,
        46,
        0,
        41,
        56,
        76,
        23,
        99,
        15,
        0,
        99,
        83,
        99,
        81,
        99,
        5,
        89,
        55,
        30,
        1
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyUsage": [
        199,
        91,
        23,
        26,
        33,
        107,
        52,
        128,
        92,
        95,
        112,
        185,
        198,
        49,
        166,
        22,
        180,
        56,
        36,
        75,
        19,
        29,
        73,
        91,
        115,
        90,
        191,
        197,
        199,
        61,
        81,
        4,
        100,
        123,
        4,
        0,
        61,
        57,
        10,
        0,
        32,
        58,
        4,
        2,
        102,
        161,
        95,
        27,
        198,
        84,
        109,
        105
      ],
      "weeklyWasted": [
        4,
        0,
        61,
        57,
        10,
        0,
        32,
        58,
        4,
        2,
        102,
        161,
        95,
        27,
        198,
        84,
        109,
        105,
        34,
        51,
        0,
        2,
        98,
        54,
        82,
        11,
        91,
        19,
        99,
        65,
        42,
        78,
        88,
        1,
        39,
        69,
        37,
        0,
        32,
        23,
        0,
        81,
        15,
        99,
        25,
        3,
        2,
        1,
        38,
        83,
        79,
        67,
        47,
        71,
        99,
        69,
        76,
        99,
        45,
        28,
        99,
        99,
        69,
        0,
        0,
        65,
        31,
        72,
        64,
        41
      ]
    },
    {
      "id": "271D2E55",
      "weeklyUsage": [
        74,
        2,
        166,
        60,
        0,
        10,
        97,
        144,
        146,
        156,
        199,
        145,
        45,
        81,
        44,
        137,
        86,
        199,
        3,
        2,
        197,
        198,
        147,
        122,
        131,
        60,
        81,
        1,
        176,
        144,
        16,
        109,
        155,
        199,
        81,
        0,
        116,
        95,
        95,
        0,
        0,
        0,
        199,
        0,
        1,
        133,
        124,
        116,
        138,
        199,
        65,
        3
      ],
      "weeklyWasted": [
        81,
        0,
        116,
        95,
        95,
        0,
        0,
        0,
        199,
        0,
        1,
        133,
        124,
        116,
        138,
        199,
        65,
        3,
        89,
        51,
        45,
        77,
        98,
        98,
        85,
        0,
        90,
        99,
        63,
        56,
        38,
        3,
        7,
        98,
        98,
        77,
        47,
        24,
        31,
        31,
        4,
        0,
        0,
        67,
        44,
        83,
        83,
        72,
        25,
        97,
        34,
        98,
        34,
        35,
        33,
        33,
        21,
        31,
        25,
        83,
        88,
        64,
        61,
        49,
        62,
        98,
        4,
        99,
        29,
        40
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        47,
        2,
        34,
        62,
        45,
        197,
        194,
        165,
        151,
        1,
        199,
        2,
        116,
        199,
        199,
        3,
        192,
        116,
        111,
        2,
        197,
        34,
        79,
        61,
        56,
        155,
        3,
        74,
        44,
        115,
        77,
        39,
        118,
        4,
        188,
        0,
        0,
        161,
        199,
        35,
        107,
        0,
        0,
        0,
        142,
        195,
        17,
        65,
        159,
        111,
        118,
        3
      ],
      "weeklyWasted": [
        188,
        0,
        0,
        161,
        199,
        35,
        107,
        0,
        0,
        0,
        142,
        195,
        17,
        65,
        159,
        111,
        118,
        3,
        1,
        19,
        0,
        96,
        12,
        51,
        81,
        0,
        64,
        0,
        32,
        99,
        23,
        36,
        14,
        1,
        19,
        27,
        39,
        50,
        41,
        0,
        91,
        13,
        53,
        83,
        41,
        55,
        2,
        1,
        63,
        42,
        1,
        98,
        95,
        13,
        1,
        40,
        99,
        61,
        0,
        10,
        99,
        99,
        99,
        20,
        55,
        98,
        23,
        59,
        10,
        48
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyUsage": [
        158,
        3,
        49,
        177,
        122,
        188,
        150,
        174,
        94,
        38,
        106,
        196,
        106,
        0,
        199,
        13,
        99,
        49,
        45,
        197,
        185,
        66,
        190,
        70,
        102,
        1,
        86,
        1,
        11,
        104,
        199,
        82,
        61,
        84,
        63,
        56,
        155,
        60,
        10,
        199,
        61,
        116,
        29,
        0,
        1,
        162,
        92,
        0,
        198,
        199,
        118,
        3
      ],
      "weeklyWasted": [
        63,
        56,
        155,
        60,
        10,
        199,
        61,
        116,
        29,
        0,
        1,
        162,
        92,
        0,
        198,
        199,
        118,
        3,
        31,
        28,
        69,
        2,
        92,
        32,
        38,
        26,
        70,
        20,
        99,
        71,
        52,
        99,
        0,
        14,
        2,
        63,
        14,
        0,
        95,
        22,
        92,
        55,
        99,
        99,
        10,
        83,
        23,
        1,
        2,
        97,
        51,
        1,
        92,
        45,
        81,
        98,
        99,
        88,
        0,
        64,
        99,
        77,
        74,
        64,
        99,
        98,
        99,
        50,
        99,
        71
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyUsage": [
        179,
        189,
        79,
        59,
        98,
        114,
        27,
        41,
        117,
        187,
        158,
        181,
        192,
        90,
        173,
        52,
        192,
        1,
        85,
        132,
        197,
        12,
        197,
        55,
        3,
        81,
        86,
        85,
        41,
        105,
        40,
        0,
        197,
        4,
        59,
        142,
        122,
        105,
        94,
        143,
        5,
        186,
        65,
        0,
        115,
        19,
        195,
        39,
        52,
        166,
        103,
        74
      ],
      "weeklyWasted": [
        59,
        142,
        122,
        105,
        94,
        143,
        5,
        186,
        65,
        0,
        115,
        19,
        195,
        39,
        52,
        166,
        103,
        74,
        78,
        98,
        25,
        23,
        98,
        23,
        99,
        25,
        72,
        0,
        99,
        23,
        61,
        92,
        63,
        29,
        56,
        52,
        1,
        59,
        32,
        0,
        97,
        0,
        15,
        59,
        13,
        31,
        99,
        17,
        2,
        7,
        2,
        46,
        33,
        22,
        86,
        83,
        12,
        99,
        31,
        0,
        94,
        99,
        33,
        34,
        56,
        53,
        99,
        1,
        99,
        1
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyUsage": [
        148,
        70,
        65,
        81,
        19,
        54,
        74,
        3,
        113,
        1,
        100,
        2,
        120,
        0,
        120,
        156,
        192,
        112,
        197,
        2,
        1,
        161,
        135,
        22,
        166,
        39,
        178,
        40,
        199,
        0,
        122,
        4,
        4,
        4,
        4,
        189,
        161,
        52,
        88,
        159,
        13,
        0,
        134,
        142,
        1,
        97,
        0,
        0,
        198,
        2,
        6,
        109
      ],
      "weeklyWasted": [
        4,
        189,
        161,
        52,
        88,
        159,
        13,
        0,
        134,
        142,
        1,
        97,
        0,
        0,
        198,
        2,
        6,
        109,
        95,
        1,
        57,
        43,
        98,
        98,
        1,
        21,
        20,
        38,
        73,
        24,
        63,
        99,
        21,
        28,
        13,
        76,
        58,
        55,
        75,
        99,
        53,
        13,
        73,
        34,
        82,
        1,
        99,
        24,
        2,
        32,
        1,
        98,
        94,
        99,
        99,
        84,
        99,
        71,
        4,
        84,
        0,
        99,
        47,
        0,
        74,
        98,
        0,
        89,
        40,
        2
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        199,
        41,
        28,
        84,
        125,
        197,
        70,
        3,
        81,
        1,
        54,
        2,
        15,
        0,
        127,
        3,
        106,
        91,
        30,
        163,
        114,
        17,
        197,
        134,
        31,
        78,
        69,
        87,
        199,
        198,
        0,
        0,
        110,
        67,
        68,
        105,
        22,
        112,
        128,
        170,
        199,
        0,
        0,
        78,
        1,
        94,
        10,
        0,
        198,
        93,
        137,
        90
      ],
      "weeklyWasted": [
        68,
        105,
        22,
        112,
        128,
        170,
        199,
        0,
        0,
        78,
        1,
        94,
        10,
        0,
        198,
        93,
        137,
        90,
        99,
        1,
        21,
        79,
        91,
        1,
        12,
        0,
        56,
        94,
        99,
        62,
        52,
        95,
        20,
        30,
        63,
        99,
        35,
        0,
        85,
        72,
        28,
        0,
        70,
        70,
        39,
        97,
        2,
        56,
        77,
        82,
        62,
        98,
        99,
        0,
        84,
        39,
        99,
        16,
        1,
        0,
        51,
        46,
        90,
        66,
        97,
        90,
        0,
        88,
        75,
        1
      ]
    },
    {
      "id": 41097027,
      "weeklyUsage": [
        159,
        94,
        0,
        72,
        93,
        2,
        136,
        145,
        132,
        1,
        193,
        56,
        36,
        0,
        1,
        3,
        164,
        102,
        124,
        126,
        144,
        96,
        106,
        127,
        89,
        198,
        86,
        185,
        37,
        150,
        176,
        36,
        134,
        170,
        127,
        21,
        127,
        5,
        10,
        11,
        77,
        0,
        58,
        46,
        146,
        196,
        54,
        92,
        183,
        124,
        10,
        58
      ],
      "weeklyWasted": [
        127,
        21,
        127,
        5,
        10,
        11,
        77,
        0,
        58,
        46,
        146,
        196,
        54,
        92,
        183,
        124,
        10,
        58,
        99,
        6,
        37,
        8,
        16,
        1,
        32,
        0,
        86,
        55,
        0,
        33,
        47,
        86,
        0,
        16,
        48,
        60,
        44,
        22,
        43,
        47,
        54,
        56,
        42,
        99,
        55,
        1,
        72,
        29,
        2,
        80,
        65,
        57,
        79,
        14,
        99,
        53,
        51,
        41,
        46,
        0,
        99,
        81,
        25,
        5,
        99,
        98,
        59,
        82,
        99,
        45
      ]
    },
    {
      "id": "EB58F890",
      "weeklyUsage": [
        7,
        74,
        198,
        147,
        6,
        197,
        120,
        23,
        186,
        73,
        137,
        4,
        107,
        98,
        66,
        20,
        192,
        170,
        185,
        189,
        1,
        149,
        2,
        161,
        186,
        160,
        40,
        1,
        199,
        115,
        63,
        0,
        125,
        90,
        193,
        110,
        198,
        30,
        70,
        6,
        180,
        72,
        113,
        0,
        13,
        196,
        0,
        0,
        47,
        199,
        143,
        186
      ],
      "weeklyWasted": [
        193,
        110,
        198,
        30,
        70,
        6,
        180,
        72,
        113,
        0,
        13,
        196,
        0,
        0,
        47,
        199,
        143,
        186,
        91,
        1,
        33,
        67,
        98,
        1,
        98,
        10,
        99,
        46,
        71,
        34,
        10,
        99,
        18,
        45,
        47,
        83,
        19,
        94,
        63,
        0,
        55,
        0,
        21,
        73,
        98,
        30,
        99,
        37,
        2,
        88,
        1,
        60,
        75,
        36,
        21,
        0,
        0,
        78,
        99,
        32,
        99,
        31,
        29,
        58,
        0,
        98,
        74,
        77,
        62,
        41
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        174,
        22,
        177,
        94,
        26,
        53,
        41,
        150,
        179,
        72,
        199,
        171,
        134,
        88,
        135,
        60,
        12,
        118,
        78,
        2,
        197,
        4,
        121,
        154,
        104,
        171,
        28,
        1,
        58,
        76,
        57,
        37,
        197,
        178,
        141,
        0,
        198,
        53,
        96,
        32,
        37,
        23,
        0,
        33,
        1,
        113,
        59,
        84,
        198,
        7,
        24,
        119
      ],
      "weeklyWasted": [
        141,
        0,
        198,
        53,
        96,
        32,
        37,
        23,
        0,
        33,
        1,
        113,
        59,
        84,
        198,
        7,
        24,
        119,
        47,
        1,
        26,
        42,
        97,
        33,
        93,
        0,
        99,
        7,
        62,
        32,
        50,
        86,
        48,
        98,
        57,
        85,
        25,
        67,
        2,
        71,
        31,
        38,
        41,
        99,
        12,
        40,
        2,
        7,
        2,
        7,
        56,
        98,
        70,
        0,
        19,
        55,
        11,
        91,
        23,
        98,
        58,
        53,
        99,
        33,
        72,
        88,
        0,
        21,
        88,
        23
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        96,
        109,
        59,
        153,
        54,
        124,
        161,
        128,
        148,
        1,
        170,
        188,
        29,
        66,
        199,
        193,
        192,
        147,
        3,
        178,
        119,
        135,
        2,
        195,
        19,
        152,
        102,
        99,
        122,
        101,
        64,
        94,
        40,
        33,
        198,
        9,
        145,
        5,
        80,
        12,
        67,
        51,
        0,
        192,
        26,
        196,
        0,
        56,
        184,
        46,
        141,
        112
      ],
      "weeklyWasted": [
        198,
        9,
        145,
        5,
        80,
        12,
        67,
        51,
        0,
        192,
        26,
        196,
        0,
        56,
        184,
        46,
        141,
        112,
        64,
        26,
        40,
        29,
        85,
        25,
        15,
        2,
        41,
        0,
        0,
        84,
        27,
        93,
        97,
        47,
        0,
        56,
        43,
        39,
        42,
        0,
        39,
        99,
        53,
        85,
        57,
        96,
        99,
        72,
        22,
        65,
        3,
        65,
        99,
        54,
        1,
        71,
        93,
        71,
        41,
        56,
        81,
        19,
        85,
        2,
        17,
        23,
        61,
        99,
        18,
        27
      ]
    },
    {
      "id": "22633EF7",
      "weeklyUsage": [
        169,
        76,
        121,
        5,
        0,
        189,
        93,
        70,
        91,
        1,
        178,
        59,
        85,
        113,
        187,
        198,
        192,
        68,
        130,
        155,
        124,
        173,
        50,
        1,
        55,
        113,
        187,
        127,
        144,
        42,
        121,
        84,
        27,
        40,
        172,
        102,
        118,
        37,
        148,
        199,
        0,
        175,
        91,
        0,
        146,
        30,
        81,
        5,
        198,
        127,
        164,
        168
      ],
      "weeklyWasted": [
        172,
        102,
        118,
        37,
        148,
        199,
        0,
        175,
        91,
        0,
        146,
        30,
        81,
        5,
        198,
        127,
        164,
        168,
        99,
        33,
        6,
        6,
        29,
        98,
        18,
        1,
        98,
        53,
        99,
        60,
        49,
        89,
        65,
        13,
        1,
        9,
        1,
        38,
        85,
        9,
        52,
        33,
        49,
        54,
        18,
        88,
        27,
        87,
        67,
        64,
        6,
        98,
        79,
        48,
        62,
        99,
        38,
        56,
        63,
        0,
        99,
        3,
        84,
        98,
        50,
        51,
        73,
        78,
        91,
        1
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        152,
        75,
        198,
        51,
        132,
        185,
        188,
        33,
        103,
        1,
        0,
        113,
        82,
        82,
        89,
        72,
        192,
        199,
        29,
        37,
        132,
        198,
        81,
        168,
        100,
        143,
        61,
        36,
        106,
        22,
        199,
        0,
        25,
        158,
        89,
        82,
        104,
        69,
        77,
        0,
        69,
        0,
        134,
        0,
        1,
        149,
        61,
        131,
        194,
        198,
        127,
        140
      ],
      "weeklyWasted": [
        89,
        82,
        104,
        69,
        77,
        0,
        69,
        0,
        134,
        0,
        1,
        149,
        61,
        131,
        194,
        198,
        127,
        140,
        54,
        13,
        60,
        37,
        62,
        1,
        99,
        0,
        68,
        13,
        99,
        99,
        98,
        42,
        0,
        56,
        76,
        99,
        39,
        13,
        45,
        0,
        55,
        1,
        0,
        32,
        44,
        64,
        27,
        1,
        6,
        97,
        1,
        98,
        14,
        5,
        43,
        99,
        59,
        27,
        67,
        43,
        99,
        28,
        90,
        23,
        44,
        24,
        12,
        59,
        69,
        1
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyUsage": [
        153,
        2,
        146,
        39,
        37,
        146,
        3,
        184,
        67,
        1,
        59,
        2,
        31,
        145,
        199,
        104,
        192,
        171,
        25,
        2,
        50,
        198,
        197,
        195,
        3,
        1,
        164,
        1,
        159,
        116,
        0,
        87,
        197,
        198,
        130,
        0,
        111,
        159,
        26,
        35,
        53,
        0,
        65,
        52,
        1,
        113,
        21,
        54,
        198,
        117,
        17,
        135
      ],
      "weeklyWasted": [
        130,
        0,
        111,
        159,
        26,
        35,
        53,
        0,
        65,
        52,
        1,
        113,
        21,
        54,
        198,
        117,
        17,
        135,
        19,
        73,
        0,
        25,
        98,
        28,
        99,
        53,
        0,
        0,
        58,
        13,
        37,
        82,
        62,
        47,
        68,
        44,
        60,
        0,
        31,
        0,
        65,
        40,
        57,
        20,
        76,
        78,
        87,
        13,
        22,
        97,
        83,
        59,
        73,
        58,
        40,
        61,
        38,
        61,
        37,
        44,
        84,
        76,
        63,
        93,
        99,
        0,
        51,
        74,
        51,
        15
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyUsage": [
        159,
        103,
        87,
        57,
        161,
        125,
        30,
        146,
        115,
        1,
        12,
        107,
        60,
        9,
        96,
        160,
        163,
        77,
        116,
        192,
        81,
        162,
        197,
        148,
        98,
        69,
        169,
        86,
        199,
        64,
        57,
        31,
        1,
        96,
        4,
        0,
        126,
        72,
        47,
        114,
        0,
        33,
        73,
        169,
        189,
        126,
        17,
        124,
        195,
        91,
        197,
        159
      ],
      "weeklyWasted": [
        4,
        0,
        126,
        72,
        47,
        114,
        0,
        33,
        73,
        169,
        189,
        126,
        17,
        124,
        195,
        91,
        197,
        159,
        22,
        8,
        1,
        2,
        98,
        33,
        14,
        23,
        0,
        99,
        84,
        51,
        60,
        56,
        35,
        23,
        71,
        53,
        75,
        74,
        2,
        21,
        39,
        22,
        24,
        73,
        98,
        66,
        56,
        35,
        43,
        79,
        39,
        98,
        71,
        83,
        59,
        24,
        34,
        61,
        46,
        18,
        82,
        84,
        61,
        10,
        94,
        98,
        81,
        35,
        0,
        1
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyUsage": [
        99,
        2,
        62,
        89,
        0,
        183,
        121,
        152,
        199,
        67,
        57,
        118,
        198,
        23,
        89,
        115,
        0,
        199,
        3,
        197,
        197,
        49,
        116,
        140,
        113,
        76,
        158,
        60,
        161,
        198,
        199,
        94,
        197,
        30,
        160,
        130,
        198,
        93,
        123,
        127,
        0,
        23,
        20,
        0,
        109,
        196,
        45,
        24,
        198,
        197,
        67,
        82
      ],
      "weeklyWasted": [
        160,
        130,
        198,
        93,
        123,
        127,
        0,
        23,
        20,
        0,
        109,
        196,
        45,
        24,
        198,
        197,
        67,
        82,
        99,
        33,
        42,
        42,
        54,
        15,
        37,
        34,
        99,
        55,
        99,
        66,
        13,
        93,
        0,
        1,
        71,
        48,
        11,
        39,
        30,
        0,
        0,
        0,
        38,
        56,
        45,
        48,
        55,
        34,
        50,
        70,
        88,
        61,
        37,
        17,
        9,
        51,
        99,
        45,
        0,
        20,
        99,
        99,
        35,
        82,
        90,
        0,
        50,
        64,
        96,
        42
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        96,
        139,
        124,
        173,
        18,
        73,
        83,
        127,
        83,
        1,
        112,
        197,
        186,
        84,
        153,
        20,
        188,
        77,
        49,
        165,
        50,
        149,
        176,
        176,
        117,
        99,
        197,
        1,
        34,
        93,
        87,
        7,
        6,
        70,
        163,
        179,
        80,
        132,
        180,
        139,
        0,
        0,
        11,
        31,
        1,
        111,
        90,
        69,
        198,
        199,
        84,
        6
      ],
      "weeklyWasted": [
        163,
        179,
        80,
        132,
        180,
        139,
        0,
        0,
        11,
        31,
        1,
        111,
        90,
        69,
        198,
        199,
        84,
        6,
        74,
        19,
        46,
        22,
        98,
        48,
        26,
        23,
        79,
        69,
        60,
        70,
        41,
        81,
        29,
        98,
        53,
        50,
        10,
        94,
        30,
        99,
        42,
        0,
        0,
        8,
        28,
        97,
        72,
        66,
        16,
        55,
        87,
        91,
        0,
        45,
        51,
        79,
        51,
        8,
        30,
        0,
        44,
        75,
        99,
        27,
        89,
        62,
        0,
        98,
        14,
        1
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyUsage": [
        79,
        136,
        167,
        5,
        80,
        37,
        194,
        113,
        54,
        12,
        199,
        2,
        181,
        160,
        187,
        3,
        192,
        169,
        101,
        122,
        197,
        16,
        2,
        195,
        3,
        126,
        55,
        1,
        116,
        67,
        95,
        166,
        110,
        70,
        166,
        176,
        198,
        5,
        49,
        67,
        0,
        197,
        0,
        60,
        52,
        140,
        36,
        139,
        198,
        199,
        2,
        42
      ],
      "weeklyWasted": [
        166,
        176,
        198,
        5,
        49,
        67,
        0,
        197,
        0,
        60,
        52,
        140,
        36,
        139,
        198,
        199,
        2,
        42,
        59,
        81,
        99,
        2,
        0,
        97,
        49,
        0,
        82,
        45,
        99,
        59,
        75,
        96,
        28,
        52,
        40,
        66,
        14,
        72,
        36,
        71,
        68,
        15,
        38,
        99,
        98,
        97,
        22,
        1,
        82,
        24,
        41,
        57,
        71,
        35,
        15,
        31,
        14,
        99,
        73,
        0,
        91,
        21,
        39,
        95,
        99,
        93,
        99,
        15,
        99,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyUsage": [
        92,
        2,
        198,
        125,
        124,
        169,
        89,
        98,
        150,
        1,
        199,
        169,
        198,
        0,
        99,
        50,
        192,
        148,
        116,
        141,
        66,
        70,
        17,
        152,
        17,
        64,
        105,
        151,
        130,
        76,
        0,
        65,
        163,
        199,
        14,
        36,
        69,
        129,
        130,
        77,
        136,
        156,
        0,
        31,
        27,
        196,
        0,
        0,
        198,
        199,
        91,
        196
      ],
      "weeklyWasted": [
        14,
        36,
        69,
        129,
        130,
        77,
        136,
        156,
        0,
        31,
        27,
        196,
        0,
        0,
        198,
        199,
        91,
        196,
        59,
        35,
        31,
        2,
        98,
        1,
        32,
        0,
        66,
        93,
        99,
        39,
        55,
        78,
        80,
        41,
        4,
        86,
        65,
        94,
        71,
        58,
        0,
        26,
        53,
        75,
        0,
        60,
        42,
        7,
        35,
        93,
        53,
        65,
        60,
        58,
        99,
        65,
        0,
        99,
        76,
        0,
        42,
        99,
        62,
        61,
        99,
        81,
        84,
        73,
        53,
        52
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyUsage": [
        145,
        75,
        198,
        20,
        65,
        64,
        194,
        118,
        199,
        1,
        134,
        2,
        170,
        0,
        167,
        111,
        187,
        60,
        37,
        194,
        190,
        78,
        144,
        189,
        15,
        163,
        123,
        124,
        46,
        96,
        164,
        20,
        106,
        95,
        153,
        199,
        99,
        153,
        99,
        127,
        0,
        66,
        101,
        9,
        44,
        180,
        0,
        149,
        187,
        199,
        159,
        71
      ],
      "weeklyWasted": [
        153,
        199,
        99,
        153,
        99,
        127,
        0,
        66,
        101,
        9,
        44,
        180,
        0,
        149,
        187,
        199,
        159,
        71,
        78,
        21,
        48,
        30,
        45,
        53,
        54,
        0,
        76,
        90,
        39,
        99,
        71,
        87,
        84,
        52,
        30,
        49,
        11,
        78,
        2,
        0,
        60,
        58,
        0,
        30,
        33,
        45,
        26,
        96,
        23,
        97,
        1,
        92,
        68,
        0,
        24,
        10,
        7,
        80,
        62,
        3,
        34,
        55,
        26,
        65,
        99,
        78,
        47,
        94,
        99,
        1
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        199,
        119,
        198,
        32,
        0,
        80,
        194,
        3,
        132,
        2,
        199,
        9,
        111,
        80,
        108,
        82,
        118,
        177,
        74,
        2,
        183,
        21,
        89,
        195,
        3,
        131,
        107,
        121,
        134,
        125,
        28,
        142,
        108,
        199,
        4,
        0,
        89,
        174,
        10,
        36,
        0,
        136,
        25,
        14,
        6,
        186,
        87,
        179,
        1,
        36,
        68,
        151
      ],
      "weeklyWasted": [
        4,
        0,
        89,
        174,
        10,
        36,
        0,
        136,
        25,
        14,
        6,
        186,
        87,
        179,
        1,
        36,
        68,
        151,
        29,
        1,
        16,
        36,
        98,
        1,
        67,
        13,
        65,
        27,
        99,
        80,
        99,
        87,
        0,
        98,
        98,
        52,
        55,
        39,
        7,
        11,
        33,
        84,
        48,
        99,
        96,
        57,
        75,
        14,
        29,
        64,
        55,
        98,
        11,
        21,
        58,
        67,
        42,
        62,
        50,
        4,
        76,
        81,
        17,
        84,
        58,
        63,
        56,
        46,
        46,
        23
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyUsage": [
        199,
        101,
        198,
        5,
        69,
        52,
        113,
        65,
        2,
        1,
        199,
        2,
        92,
        47,
        179,
        42,
        162,
        199,
        26,
        2,
        197,
        116,
        84,
        195,
        113,
        104,
        3,
        197,
        130,
        134,
        0,
        196,
        103,
        199,
        140,
        112,
        91,
        126,
        187,
        124,
        138,
        41,
        88,
        96,
        30,
        102,
        195,
        51,
        128,
        157,
        2,
        3
      ],
      "weeklyWasted": [
        140,
        112,
        91,
        126,
        187,
        124,
        138,
        41,
        88,
        96,
        30,
        102,
        195,
        51,
        128,
        157,
        2,
        3,
        39,
        6,
        93,
        2,
        35,
        1,
        99,
        67,
        99,
        79,
        60,
        1,
        16,
        71,
        37,
        35,
        79,
        78,
        1,
        64,
        95,
        68,
        0,
        0,
        64,
        99,
        0,
        13,
        17,
        51,
        59,
        66,
        34,
        97,
        99,
        0,
        99,
        92,
        13,
        52,
        51,
        0,
        79,
        95,
        58,
        99,
        39,
        72,
        42,
        47,
        63,
        99
      ]
    },
    {
      "id": "DB301E71",
      "weeklyUsage": [
        58,
        126,
        129,
        160,
        148,
        66,
        84,
        3,
        57,
        68,
        0,
        41,
        197,
        163,
        83,
        118,
        36,
        138,
        133,
        197,
        197,
        132,
        126,
        195,
        30,
        198,
        100,
        173,
        170,
        144,
        19,
        0,
        41,
        186,
        99,
        19,
        141,
        5,
        199,
        171,
        146,
        0,
        0,
        178,
        72,
        98,
        0,
        46,
        198,
        2,
        140,
        77
      ],
      "weeklyWasted": [
        99,
        19,
        141,
        5,
        199,
        171,
        146,
        0,
        0,
        178,
        72,
        98,
        0,
        46,
        198,
        2,
        140,
        77,
        49,
        1,
        69,
        2,
        83,
        98,
        2,
        32,
        46,
        99,
        99,
        65,
        68,
        94,
        44,
        42,
        21,
        83,
        12,
        70,
        39,
        99,
        0,
        13,
        0,
        1,
        0,
        43,
        2,
        33,
        2,
        75,
        69,
        98,
        99,
        24,
        70,
        58,
        67,
        81,
        59,
        0,
        71,
        34,
        81,
        4,
        22,
        22,
        18,
        57,
        41,
        10
      ]
    },
    {
      "id": "6E657E21",
      "weeklyUsage": [
        199,
        2,
        198,
        5,
        94,
        29,
        173,
        56,
        104,
        165,
        91,
        54,
        2,
        48,
        148,
        66,
        192,
        30,
        3,
        133,
        157,
        80,
        73,
        42,
        101,
        76,
        112,
        59,
        0,
        136,
        139,
        0,
        1,
        81,
        104,
        178,
        44,
        31,
        143,
        129,
        28,
        70,
        51,
        3,
        27,
        162,
        146,
        131,
        162,
        58,
        68,
        3
      ],
      "weeklyWasted": [
        104,
        178,
        44,
        31,
        143,
        129,
        28,
        70,
        51,
        3,
        27,
        162,
        146,
        131,
        162,
        58,
        68,
        3,
        21,
        36,
        80,
        35,
        78,
        98,
        72,
        97,
        0,
        40,
        59,
        39,
        5,
        84,
        21,
        62,
        93,
        99,
        1,
        82,
        44,
        77,
        35,
        0,
        4,
        37,
        45,
        44,
        82,
        88,
        2,
        75,
        28,
        98,
        50,
        68,
        48,
        35,
        98,
        99,
        13,
        0,
        99,
        96,
        12,
        71,
        64,
        98,
        43,
        50,
        99,
        1
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyUsage": [
        144,
        92,
        64,
        52,
        99,
        98,
        90,
        96,
        199,
        1,
        137,
        105,
        78,
        0,
        149,
        150,
        138,
        1,
        173,
        197,
        179,
        112,
        80,
        195,
        150,
        198,
        177,
        29,
        136,
        116,
        131,
        0,
        1,
        87,
        4,
        137,
        172,
        5,
        69,
        199,
        70,
        134,
        46,
        0,
        199,
        54,
        56,
        24,
        171,
        2,
        46,
        50
      ],
      "weeklyWasted": [
        4,
        137,
        172,
        5,
        69,
        199,
        70,
        134,
        46,
        0,
        199,
        54,
        56,
        24,
        171,
        2,
        46,
        50,
        99,
        28,
        94,
        28,
        37,
        26,
        17,
        0,
        95,
        41,
        21,
        95,
        66,
        99,
        2,
        20,
        59,
        99,
        1,
        0,
        33,
        0,
        80,
        21,
        65,
        50,
        78,
        96,
        73,
        1,
        2,
        76,
        1,
        98,
        99,
        0,
        56,
        96,
        25,
        99,
        3,
        0,
        91,
        56,
        86,
        99,
        21,
        88,
        84,
        1,
        99,
        1
      ]
    },
    {
      "id": "02A8E856",
      "weeklyUsage": [
        166,
        58,
        22,
        77,
        0,
        90,
        134,
        128,
        118,
        21,
        41,
        80,
        118,
        39,
        166,
        3,
        75,
        199,
        123,
        46,
        197,
        115,
        144,
        115,
        80,
        87,
        131,
        144,
        199,
        101,
        125,
        196,
        1,
        88,
        198,
        199,
        32,
        69,
        44,
        102,
        125,
        190,
        97,
        117,
        92,
        196,
        0,
        104,
        122,
        77,
        98,
        3
      ],
      "weeklyWasted": [
        198,
        199,
        32,
        69,
        44,
        102,
        125,
        190,
        97,
        117,
        92,
        196,
        0,
        104,
        122,
        77,
        98,
        3,
        51,
        16,
        0,
        2,
        98,
        44,
        69,
        99,
        79,
        0,
        21,
        40,
        96,
        82,
        69,
        45,
        3,
        18,
        54,
        80,
        98,
        0,
        19,
        11,
        96,
        77,
        80,
        84,
        60,
        21,
        27,
        71,
        60,
        82,
        56,
        0,
        78,
        62,
        43,
        36,
        89,
        68,
        86,
        99,
        70,
        68,
        77,
        7,
        64,
        83,
        80,
        81
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        68,
        97,
        0,
        84,
        0,
        1,
        121,
        60,
        80,
        199,
        97,
        120,
        173,
        169,
        53,
        86,
        177,
        66,
        100,
        48,
        11,
        4,
        129,
        195,
        119,
        90,
        11,
        1,
        0,
        26,
        199,
        0,
        37,
        96,
        182,
        0,
        198,
        5,
        54,
        199,
        103,
        0,
        0,
        0,
        7,
        196,
        0,
        19,
        184,
        128,
        197,
        3
      ],
      "weeklyWasted": [
        182,
        0,
        198,
        5,
        54,
        199,
        103,
        0,
        0,
        0,
        7,
        196,
        0,
        19,
        184,
        128,
        197,
        3,
        98,
        53,
        63,
        10,
        65,
        32,
        99,
        0,
        87,
        99,
        59,
        88,
        64,
        69,
        0,
        45,
        28,
        15,
        11,
        37,
        31,
        32,
        64,
        51,
        11,
        28,
        98,
        1,
        29,
        34,
        30,
        74,
        99,
        90,
        13,
        33,
        31,
        99,
        50,
        70,
        11,
        24,
        99,
        77,
        0,
        58,
        35,
        98,
        28,
        99,
        99,
        24
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        162,
        152,
        32,
        13,
        35,
        93,
        194,
        3,
        181,
        22,
        80,
        75,
        109,
        86,
        199,
        86,
        192,
        96,
        3,
        175,
        166,
        25,
        187,
        24,
        131,
        115,
        169,
        1,
        132,
        143,
        67,
        0,
        1,
        167,
        63,
        0,
        150,
        199,
        10,
        109,
        30,
        0,
        0,
        20,
        8,
        196,
        29,
        48,
        198,
        2,
        2,
        35
      ],
      "weeklyWasted": [
        63,
        0,
        150,
        199,
        10,
        109,
        30,
        0,
        0,
        20,
        8,
        196,
        29,
        48,
        198,
        2,
        2,
        35,
        36,
        1,
        99,
        2,
        48,
        43,
        87,
        31,
        46,
        79,
        39,
        1,
        60,
        99,
        31,
        47,
        69,
        53,
        15,
        89,
        57,
        91,
        39,
        0,
        33,
        77,
        98,
        97,
        2,
        25,
        49,
        65,
        99,
        98,
        28,
        0,
        3,
        97,
        8,
        38,
        0,
        0,
        95,
        99,
        63,
        0,
        19,
        31,
        0,
        99,
        99,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyUsage": [
        199,
        11,
        93,
        55,
        104,
        186,
        140,
        3,
        151,
        12,
        127,
        91,
        188,
        0,
        107,
        112,
        192,
        150,
        3,
        44,
        8,
        4,
        67,
        194,
        61,
        60,
        177,
        170,
        199,
        120,
        81,
        0,
        116,
        129,
        65,
        124,
        154,
        53,
        35,
        165,
        0,
        199,
        199,
        109,
        53,
        84,
        0,
        24,
        198,
        193,
        197,
        3
      ],
      "weeklyWasted": [
        65,
        124,
        154,
        53,
        35,
        165,
        0,
        199,
        199,
        109,
        53,
        84,
        0,
        24,
        198,
        193,
        197,
        3,
        65,
        24,
        2,
        34,
        91,
        69,
        55,
        0,
        96,
        50,
        83,
        73,
        75,
        56,
        70,
        1,
        38,
        21,
        1,
        41,
        62,
        0,
        0,
        37,
        22,
        22,
        98,
        54,
        54,
        4,
        55,
        97,
        40,
        82,
        99,
        38,
        99,
        64,
        99,
        99,
        35,
        43,
        99,
        2,
        67,
        99,
        33,
        88,
        99,
        70,
        86,
        11
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        120,
        93,
        165,
        5,
        65,
        1,
        3,
        91,
        105,
        56,
        37,
        39,
        188,
        130,
        168,
        198,
        121,
        199,
        47,
        63,
        119,
        66,
        108,
        100,
        109,
        139,
        152,
        73,
        66,
        139,
        47,
        154,
        1,
        161,
        161,
        41,
        160,
        5,
        17,
        120,
        0,
        69,
        176,
        70,
        1,
        45,
        47,
        30,
        198,
        184,
        105,
        101
      ],
      "weeklyWasted": [
        161,
        41,
        160,
        5,
        17,
        120,
        0,
        69,
        176,
        70,
        1,
        45,
        47,
        30,
        198,
        184,
        105,
        101,
        31,
        12,
        31,
        44,
        75,
        52,
        99,
        1,
        69,
        46,
        71,
        1,
        50,
        99,
        0,
        98,
        54,
        64,
        44,
        90,
        60,
        61,
        0,
        1,
        41,
        58,
        45,
        51,
        84,
        27,
        2,
        96,
        16,
        81,
        18,
        24,
        62,
        54,
        43,
        5,
        84,
        0,
        62,
        99,
        67,
        67,
        15,
        44,
        89,
        1,
        99,
        1
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        199,
        95,
        0,
        110,
        52,
        1,
        41,
        39,
        2,
        6,
        135,
        167,
        198,
        113,
        199,
        163,
        96,
        131,
        87,
        41,
        79,
        110,
        100,
        152,
        155,
        46,
        160,
        33,
        184,
        90,
        73,
        20,
        1,
        14,
        41,
        111,
        111,
        166,
        133,
        46,
        23,
        0,
        75,
        21,
        32,
        20,
        20,
        0,
        180,
        129,
        46,
        3
      ],
      "weeklyWasted": [
        41,
        111,
        111,
        166,
        133,
        46,
        23,
        0,
        75,
        21,
        32,
        20,
        20,
        0,
        180,
        129,
        46,
        3,
        1,
        12,
        61,
        2,
        98,
        27,
        99,
        42,
        37,
        57,
        46,
        67,
        29,
        23,
        60,
        98,
        60,
        65,
        47,
        99,
        64,
        81,
        67,
        24,
        29,
        1,
        64,
        76,
        25,
        46,
        13,
        97,
        1,
        60,
        22,
        68,
        99,
        74,
        0,
        91,
        35,
        98,
        72,
        60,
        68,
        3,
        99,
        86,
        24,
        11,
        39,
        19
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        63,
        2,
        87,
        63,
        0,
        3,
        189,
        148,
        64,
        1,
        138,
        142,
        119,
        92,
        199,
        9,
        147,
        185,
        174,
        29,
        197,
        168,
        2,
        92,
        80,
        198,
        135,
        70,
        183,
        187,
        113,
        0,
        136,
        105,
        102,
        26,
        198,
        148,
        22,
        31,
        65,
        76,
        184,
        0,
        12,
        133,
        77,
        30,
        171,
        151,
        154,
        3
      ],
      "weeklyWasted": [
        102,
        26,
        198,
        148,
        22,
        31,
        65,
        76,
        184,
        0,
        12,
        133,
        77,
        30,
        171,
        151,
        154,
        3,
        67,
        52,
        18,
        2,
        98,
        48,
        99,
        69,
        84,
        47,
        0,
        56,
        84,
        99,
        22,
        1,
        66,
        99,
        4,
        99,
        76,
        17,
        0,
        0,
        21,
        95,
        0,
        18,
        41,
        15,
        2,
        72,
        38,
        76,
        5,
        23,
        55,
        75,
        38,
        68,
        5,
        0,
        99,
        75,
        99,
        57,
        99,
        69,
        99,
        72,
        99,
        46
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        118,
        93,
        89,
        64,
        86,
        85,
        194,
        133,
        108,
        1,
        199,
        2,
        177,
        0,
        199,
        3,
        114,
        151,
        3,
        167,
        169,
        150,
        98,
        169,
        191,
        158,
        197,
        1,
        97,
        52,
        35,
        0,
        1,
        10,
        29,
        42,
        60,
        5,
        48,
        199,
        153,
        0,
        47,
        5,
        1,
        171,
        80,
        90,
        154,
        156,
        122,
        3
      ],
      "weeklyWasted": [
        29,
        42,
        60,
        5,
        48,
        199,
        153,
        0,
        47,
        5,
        1,
        171,
        80,
        90,
        154,
        156,
        122,
        3,
        99,
        50,
        21,
        7,
        83,
        58,
        59,
        0,
        71,
        71,
        46,
        76,
        1,
        92,
        95,
        51,
        83,
        47,
        66,
        74,
        95,
        90,
        21,
        83,
        29,
        39,
        39,
        4,
        83,
        5,
        2,
        81,
        80,
        75,
        99,
        38,
        47,
        65,
        43,
        99,
        72,
        0,
        97,
        70,
        99,
        51,
        34,
        98,
        96,
        68,
        47,
        32
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyUsage": [
        199,
        56,
        0,
        195,
        198,
        172,
        169,
        3,
        178,
        58,
        94,
        28,
        197,
        91,
        129,
        198,
        192,
        106,
        6,
        197,
        16,
        28,
        47,
        77,
        141,
        198,
        96,
        29,
        37,
        109,
        67,
        170,
        1,
        4,
        51,
        0,
        156,
        80,
        55,
        195,
        100,
        105,
        139,
        132,
        22,
        191,
        86,
        0,
        161,
        199,
        2,
        40
      ],
      "weeklyWasted": [
        51,
        0,
        156,
        80,
        55,
        195,
        100,
        105,
        139,
        132,
        22,
        191,
        86,
        0,
        161,
        199,
        2,
        40,
        96,
        3,
        99,
        33,
        98,
        25,
        12,
        32,
        80,
        0,
        87,
        24,
        35,
        92,
        96,
        98,
        28,
        80,
        32,
        20,
        80,
        57,
        0,
        0,
        66,
        43,
        49,
        97,
        2,
        1,
        15,
        97,
        65,
        35,
        99,
        35,
        57,
        92,
        31,
        72,
        58,
        15,
        99,
        52,
        72,
        99,
        63,
        98,
        95,
        80,
        52,
        1
      ]
    },
    {
      "id": "099FDC48",
      "weeklyUsage": [
        199,
        80,
        167,
        5,
        170,
        108,
        97,
        62,
        114,
        83,
        174,
        34,
        35,
        169,
        199,
        144,
        151,
        151,
        136,
        51,
        197,
        59,
        2,
        118,
        65,
        45,
        3,
        84,
        0,
        27,
        71,
        152,
        148,
        115,
        177,
        199,
        88,
        5,
        103,
        27,
        0,
        155,
        0,
        80,
        66,
        72,
        0,
        198,
        198,
        185,
        12,
        3
      ],
      "weeklyWasted": [
        177,
        199,
        88,
        5,
        103,
        27,
        0,
        155,
        0,
        80,
        66,
        72,
        0,
        198,
        198,
        185,
        12,
        3,
        1,
        12,
        19,
        37,
        33,
        68,
        99,
        2,
        52,
        0,
        55,
        65,
        37,
        76,
        6,
        1,
        69,
        75,
        28,
        0,
        2,
        50,
        9,
        82,
        55,
        65,
        98,
        36,
        2,
        20,
        39,
        56,
        31,
        80,
        78,
        99,
        18,
        55,
        64,
        52,
        34,
        19,
        56,
        11,
        31,
        88,
        99,
        94,
        61,
        68,
        20,
        1
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyUsage": [
        134,
        89,
        156,
        5,
        92,
        197,
        132,
        114,
        2,
        150,
        0,
        18,
        166,
        179,
        199,
        76,
        126,
        178,
        38,
        115,
        197,
        42,
        197,
        56,
        3,
        74,
        197,
        31,
        115,
        59,
        199,
        29,
        84,
        120,
        100,
        47,
        60,
        64,
        141,
        163,
        0,
        177,
        0,
        116,
        1,
        140,
        92,
        39,
        198,
        146,
        142,
        178
      ],
      "weeklyWasted": [
        100,
        47,
        60,
        64,
        141,
        163,
        0,
        177,
        0,
        116,
        1,
        140,
        92,
        39,
        198,
        146,
        142,
        178,
        19,
        9,
        99,
        2,
        91,
        38,
        43,
        18,
        44,
        39,
        70,
        72,
        62,
        99,
        0,
        1,
        63,
        18,
        3,
        30,
        44,
        89,
        19,
        0,
        88,
        78,
        98,
        97,
        47,
        63,
        2,
        43,
        33,
        61,
        20,
        95,
        99,
        52,
        99,
        20,
        36,
        0,
        60,
        65,
        99,
        0,
        42,
        98,
        78,
        99,
        14,
        1
      ]
    },
    {
      "id": "F413914D",
      "weeklyUsage": [
        199,
        3,
        122,
        125,
        0,
        153,
        25,
        188,
        71,
        56,
        199,
        114,
        23,
        116,
        54,
        106,
        192,
        81,
        92,
        2,
        162,
        4,
        197,
        1,
        3,
        25,
        180,
        1,
        66,
        51,
        116,
        87,
        29,
        4,
        148,
        5,
        0,
        164,
        71,
        26,
        0,
        82,
        0,
        149,
        64,
        119,
        35,
        55,
        198,
        104,
        197,
        124
      ],
      "weeklyWasted": [
        148,
        5,
        0,
        164,
        71,
        26,
        0,
        82,
        0,
        149,
        64,
        119,
        35,
        55,
        198,
        104,
        197,
        124,
        4,
        91,
        4,
        2,
        16,
        98,
        27,
        61,
        97,
        0,
        99,
        99,
        41,
        94,
        0,
        1,
        32,
        93,
        52,
        45,
        67,
        0,
        0,
        0,
        67,
        99,
        51,
        57,
        96,
        96,
        2,
        88,
        23,
        88,
        1,
        64,
        80,
        57,
        0,
        36,
        0,
        91,
        17,
        99,
        72,
        51,
        99,
        98,
        79,
        24,
        39,
        75
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        184,
        2,
        64,
        64,
        107,
        1,
        120,
        88,
        168,
        199,
        121,
        38,
        167,
        0,
        108,
        118,
        185,
        175,
        40,
        117,
        161,
        148,
        2,
        195,
        185,
        129,
        143,
        53,
        41,
        104,
        144,
        158,
        17,
        50,
        141,
        38,
        191,
        37,
        10,
        199,
        131,
        84,
        199,
        55,
        64,
        84,
        139,
        0,
        120,
        161,
        60,
        5
      ],
      "weeklyWasted": [
        141,
        38,
        191,
        37,
        10,
        199,
        131,
        84,
        199,
        55,
        64,
        84,
        139,
        0,
        120,
        161,
        60,
        5,
        54,
        25,
        14,
        2,
        98,
        93,
        52,
        0,
        28,
        58,
        99,
        99,
        47,
        69,
        0,
        53,
        26,
        66,
        37,
        71,
        2,
        0,
        20,
        31,
        59,
        99,
        69,
        97,
        31,
        22,
        2,
        7,
        17,
        98,
        99,
        19,
        74,
        35,
        0,
        59,
        82,
        54,
        49,
        30,
        25,
        18,
        59,
        98,
        39,
        31,
        99,
        20
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyUsage": [
        199,
        2,
        107,
        74,
        29,
        47,
        74,
        5,
        49,
        31,
        199,
        2,
        198,
        0,
        95,
        3,
        167,
        83,
        158,
        2,
        83,
        86,
        193,
        54,
        3,
        138,
        156,
        117,
        132,
        108,
        0,
        7,
        1,
        69,
        67,
        0,
        136,
        153,
        187,
        66,
        88,
        0,
        0,
        0,
        36,
        196,
        195,
        92,
        198,
        105,
        2,
        3
      ],
      "weeklyWasted": [
        67,
        0,
        136,
        153,
        187,
        66,
        88,
        0,
        0,
        0,
        36,
        196,
        195,
        92,
        198,
        105,
        2,
        3,
        26,
        28,
        28,
        2,
        80,
        1,
        99,
        0,
        66,
        54,
        53,
        1,
        47,
        59,
        0,
        77,
        31,
        99,
        19,
        28,
        46,
        0,
        6,
        27,
        32,
        53,
        65,
        68,
        85,
        41,
        2,
        74,
        11,
        86,
        37,
        88,
        53,
        30,
        75,
        53,
        0,
        98,
        99,
        99,
        19,
        69,
        80,
        98,
        96,
        19,
        94,
        31
      ]
    },
    {
      "id": "7D206E43",
      "weeklyUsage": [
        199,
        2,
        126,
        24,
        0,
        103,
        147,
        73,
        60,
        1,
        199,
        29,
        2,
        37,
        94,
        77,
        64,
        1,
        3,
        38,
        180,
        78,
        197,
        77,
        17,
        198,
        154,
        1,
        199,
        59,
        27,
        98,
        89,
        86,
        16,
        0,
        43,
        5,
        52,
        0,
        199,
        151,
        85,
        49,
        12,
        68,
        48,
        16,
        198,
        61,
        57,
        3
      ],
      "weeklyWasted": [
        16,
        0,
        43,
        5,
        52,
        0,
        199,
        151,
        85,
        49,
        12,
        68,
        48,
        16,
        198,
        61,
        57,
        3,
        1,
        12,
        99,
        29,
        98,
        26,
        48,
        0,
        99,
        41,
        99,
        42,
        6,
        3,
        97,
        61,
        55,
        74,
        52,
        73,
        2,
        0,
        0,
        0,
        19,
        20,
        72,
        84,
        40,
        23,
        75,
        74,
        44,
        98,
        64,
        31,
        63,
        40,
        0,
        37,
        54,
        0,
        87,
        47,
        0,
        65,
        0,
        90,
        23,
        94,
        55,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyUsage": [
        33,
        118,
        171,
        31,
        0,
        149,
        194,
        185,
        54,
        67,
        199,
        148,
        152,
        164,
        157,
        114,
        192,
        90,
        75,
        2,
        103,
        4,
        100,
        152,
        109,
        21,
        161,
        6,
        117,
        42,
        104,
        0,
        55,
        183,
        198,
        199,
        110,
        157,
        169,
        72,
        1,
        7,
        49,
        37,
        37,
        71,
        113,
        77,
        198,
        139,
        8,
        30
      ],
      "weeklyWasted": [
        198,
        199,
        110,
        157,
        169,
        72,
        1,
        7,
        49,
        37,
        37,
        71,
        113,
        77,
        198,
        139,
        8,
        30,
        2,
        5,
        26,
        13,
        62,
        23,
        99,
        16,
        97,
        73,
        99,
        29,
        99,
        99,
        85,
        98,
        1,
        30,
        58,
        53,
        30,
        99,
        14,
        45,
        0,
        80,
        40,
        17,
        99,
        1,
        2,
        85,
        35,
        98,
        19,
        32,
        33,
        7,
        65,
        1,
        36,
        0,
        91,
        60,
        60,
        17,
        33,
        0,
        24,
        99,
        31,
        32
      ]
    },
    {
      "id": 14968630,
      "weeklyUsage": [
        176,
        64,
        185,
        76,
        110,
        197,
        87,
        6,
        199,
        106,
        50,
        55,
        75,
        2,
        170,
        3,
        192,
        199,
        106,
        48,
        197,
        10,
        197,
        116,
        3,
        118,
        121,
        27,
        0,
        198,
        132,
        0,
        4,
        129,
        150,
        88,
        101,
        111,
        170,
        199,
        0,
        65,
        82,
        198,
        12,
        67,
        70,
        198,
        94,
        158,
        120,
        3
      ],
      "weeklyWasted": [
        150,
        88,
        101,
        111,
        170,
        199,
        0,
        65,
        82,
        198,
        12,
        67,
        70,
        198,
        94,
        158,
        120,
        3,
        28,
        2,
        31,
        31,
        98,
        70,
        82,
        73,
        66,
        7,
        0,
        71,
        5,
        70,
        35,
        98,
        98,
        56,
        25,
        38,
        33,
        13,
        35,
        2,
        11,
        28,
        98,
        91,
        64,
        38,
        2,
        33,
        64,
        70,
        70,
        39,
        77,
        48,
        36,
        99,
        64,
        1,
        24,
        51,
        46,
        78,
        95,
        98,
        99,
        13,
        84,
        50
      ]
    },
    {
      "id": "11102F47",
      "weeklyUsage": [
        174,
        2,
        0,
        5,
        22,
        101,
        55,
        3,
        2,
        1,
        199,
        60,
        87,
        199,
        199,
        5,
        127,
        88,
        197,
        2,
        183,
        4,
        131,
        195,
        187,
        189,
        183,
        1,
        25,
        73,
        110,
        44,
        156,
        97,
        177,
        0,
        110,
        164,
        198,
        0,
        0,
        19,
        192,
        27,
        19,
        142,
        0,
        114,
        198,
        199,
        24,
        74
      ],
      "weeklyWasted": [
        177,
        0,
        110,
        164,
        198,
        0,
        0,
        19,
        192,
        27,
        19,
        142,
        0,
        114,
        198,
        199,
        24,
        74,
        52,
        98,
        0,
        2,
        24,
        7,
        92,
        0,
        64,
        99,
        97,
        66,
        46,
        3,
        45,
        31,
        64,
        53,
        17,
        72,
        9,
        35,
        0,
        5,
        45,
        99,
        77,
        80,
        46,
        20,
        12,
        1,
        57,
        82,
        54,
        25,
        72,
        48,
        15,
        52,
        6,
        0,
        90,
        74,
        41,
        69,
        98,
        98,
        26,
        99,
        99,
        38
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyUsage": [
        199,
        126,
        198,
        46,
        0,
        197,
        194,
        197,
        41,
        119,
        76,
        40,
        190,
        38,
        143,
        3,
        183,
        199,
        192,
        2,
        172,
        14,
        73,
        133,
        129,
        46,
        36,
        62,
        76,
        148,
        112,
        0,
        74,
        37,
        15,
        0,
        198,
        199,
        52,
        123,
        35,
        33,
        26,
        75,
        32,
        115,
        84,
        189,
        184,
        122,
        183,
        97
      ],
      "weeklyWasted": [
        15,
        0,
        198,
        199,
        52,
        123,
        35,
        33,
        26,
        75,
        32,
        115,
        84,
        189,
        184,
        122,
        183,
        97,
        65,
        98,
        0,
        9,
        98,
        74,
        43,
        97,
        38,
        0,
        72,
        44,
        53,
        96,
        0,
        33,
        7,
        59,
        1,
        36,
        99,
        69,
        36,
        0,
        21,
        63,
        43,
        42,
        7,
        20,
        2,
        42,
        26,
        84,
        99,
        54,
        57,
        0,
        99,
        95,
        9,
        25,
        90,
        99,
        99,
        30,
        40,
        98,
        63,
        67,
        49,
        13
      ]
    },
    {
      "id": "864437DD",
      "weeklyUsage": [
        199,
        61,
        198,
        54,
        0,
        104,
        36,
        122,
        83,
        54,
        168,
        132,
        193,
        174,
        173,
        183,
        176,
        199,
        95,
        146,
        156,
        27,
        2,
        119,
        147,
        187,
        135,
        187,
        141,
        160,
        101,
        168,
        161,
        177,
        120,
        40,
        198,
        158,
        182,
        0,
        0,
        71,
        0,
        44,
        1,
        31,
        145,
        0,
        195,
        149,
        185,
        105
      ],
      "weeklyWasted": [
        120,
        40,
        198,
        158,
        182,
        0,
        0,
        71,
        0,
        44,
        1,
        31,
        145,
        0,
        195,
        149,
        185,
        105,
        27,
        1,
        41,
        2,
        63,
        98,
        59,
        22,
        32,
        60,
        16,
        10,
        83,
        72,
        72,
        1,
        46,
        47,
        25,
        32,
        2,
        26,
        55,
        0,
        60,
        34,
        68,
        57,
        43,
        1,
        27,
        15,
        28,
        98,
        68,
        68,
        99,
        66,
        41,
        1,
        56,
        80,
        99,
        62,
        99,
        72,
        54,
        56,
        65,
        31,
        43,
        15
      ]
    },
    {
      "id": "11102F47",
      "weeklyUsage": [
        39,
        96,
        58,
        101,
        82,
        54,
        194,
        3,
        167,
        130,
        112,
        188,
        75,
        0,
        115,
        32,
        86,
        146,
        84,
        106,
        55,
        114,
        111,
        139,
        3,
        123,
        134,
        42,
        175,
        109,
        199,
        0,
        97,
        198,
        81,
        0,
        114,
        5,
        199,
        136,
        83,
        0,
        0,
        26,
        157,
        196,
        147,
        31,
        1,
        70,
        65,
        143
      ],
      "weeklyWasted": [
        81,
        0,
        114,
        5,
        199,
        136,
        83,
        0,
        0,
        26,
        157,
        196,
        147,
        31,
        1,
        70,
        65,
        143,
        90,
        7,
        82,
        2,
        55,
        50,
        43,
        0,
        0,
        71,
        86,
        99,
        11,
        3,
        34,
        91,
        98,
        99,
        1,
        29,
        79,
        0,
        97,
        42,
        21,
        78,
        18,
        45,
        53,
        13,
        2,
        87,
        57,
        82,
        76,
        91,
        60,
        74,
        18,
        74,
        35,
        0,
        71,
        3,
        0,
        44,
        66,
        60,
        43,
        37,
        99,
        14
      ]
    },
    {
      "id": 9202537,
      "weeklyUsage": [
        199,
        2,
        81,
        77,
        0,
        92,
        177,
        197,
        2,
        44,
        82,
        54,
        119,
        0,
        199,
        3,
        188,
        199,
        193,
        72,
        197,
        35,
        118,
        158,
        3,
        79,
        61,
        64,
        44,
        164,
        105,
        0,
        148,
        92,
        60,
        106,
        92,
        124,
        107,
        9,
        67,
        0,
        7,
        28,
        12,
        171,
        0,
        163,
        198,
        177,
        124,
        90
      ],
      "weeklyWasted": [
        60,
        106,
        92,
        124,
        107,
        9,
        67,
        0,
        7,
        28,
        12,
        171,
        0,
        163,
        198,
        177,
        124,
        90,
        69,
        1,
        48,
        37,
        70,
        1,
        76,
        3,
        21,
        49,
        7,
        66,
        38,
        59,
        44,
        7,
        54,
        18,
        55,
        45,
        30,
        17,
        66,
        0,
        0,
        35,
        47,
        56,
        56,
        39,
        22,
        75,
        1,
        98,
        97,
        3,
        6,
        39,
        64,
        1,
        27,
        0,
        94,
        72,
        61,
        36,
        99,
        98,
        43,
        96,
        0,
        34
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyUsage": [
        64,
        2,
        185,
        93,
        152,
        108,
        148,
        15,
        3,
        33,
        131,
        2,
        57,
        79,
        159,
        198,
        183,
        145,
        66,
        29,
        36,
        43,
        163,
        44,
        3,
        169,
        192,
        122,
        132,
        140,
        55,
        0,
        1,
        126,
        150,
        188,
        114,
        124,
        10,
        0,
        199,
        0,
        184,
        0,
        46,
        196,
        150,
        198,
        101,
        56,
        135,
        47
      ],
      "weeklyWasted": [
        150,
        188,
        114,
        124,
        10,
        0,
        199,
        0,
        184,
        0,
        46,
        196,
        150,
        198,
        101,
        56,
        135,
        47,
        47,
        10,
        0,
        19,
        66,
        89,
        51,
        0,
        92,
        0,
        99,
        22,
        49,
        75,
        84,
        1,
        0,
        92,
        3,
        43,
        74,
        0,
        42,
        10,
        0,
        62,
        3,
        69,
        99,
        96,
        2,
        57,
        26,
        98,
        61,
        8,
        83,
        76,
        43,
        97,
        61,
        79,
        56,
        99,
        38,
        0,
        77,
        98,
        99,
        99,
        60,
        2
      ]
    },
    {
      "id": "200EA71C",
      "weeklyUsage": [
        109,
        180,
        67,
        5,
        0,
        116,
        51,
        77,
        179,
        10,
        55,
        130,
        174,
        0,
        110,
        116,
        192,
        196,
        100,
        177,
        113,
        72,
        2,
        144,
        3,
        108,
        89,
        62,
        66,
        72,
        199,
        0,
        87,
        72,
        139,
        7,
        198,
        23,
        137,
        0,
        0,
        33,
        72,
        86,
        60,
        160,
        45,
        113,
        132,
        199,
        53,
        99
      ],
      "weeklyWasted": [
        139,
        7,
        198,
        23,
        137,
        0,
        0,
        33,
        72,
        86,
        60,
        160,
        45,
        113,
        132,
        199,
        53,
        99,
        32,
        98,
        56,
        27,
        98,
        84,
        67,
        0,
        93,
        64,
        44,
        4,
        1,
        24,
        83,
        98,
        81,
        99,
        4,
        48,
        47,
        8,
        28,
        5,
        51,
        99,
        41,
        46,
        43,
        58,
        28,
        69,
        24,
        98,
        99,
        12,
        70,
        70,
        5,
        37,
        61,
        50,
        41,
        1,
        27,
        13,
        92,
        98,
        64,
        69,
        4,
        1
      ]
    },
    {
      "id": "0AB87239",
      "weeklyUsage": [
        160,
        36,
        152,
        108,
        0,
        101,
        73,
        48,
        199,
        1,
        196,
        40,
        89,
        14,
        199,
        72,
        99,
        199,
        32,
        95,
        122,
        43,
        140,
        107,
        3,
        75,
        103,
        1,
        199,
        2,
        14,
        147,
        28,
        186,
        174,
        30,
        195,
        28,
        183,
        24,
        103,
        34,
        188,
        45,
        1,
        196,
        100,
        93,
        198,
        2,
        145,
        12
      ],
      "weeklyWasted": [
        174,
        30,
        195,
        28,
        183,
        24,
        103,
        34,
        188,
        45,
        1,
        196,
        100,
        93,
        198,
        2,
        145,
        12,
        64,
        1,
        55,
        2,
        47,
        57,
        11,
        55,
        75,
        0,
        33,
        76,
        70,
        3,
        95,
        64,
        52,
        72,
        1,
        71,
        71,
        0,
        24,
        0,
        64,
        69,
        0,
        73,
        96,
        37,
        41,
        97,
        54,
        75,
        46,
        34,
        79,
        0,
        82,
        13,
        14,
        65,
        52,
        99,
        49,
        99,
        26,
        64,
        85,
        59,
        25,
        45
      ]
    },
    {
      "id": 9202537,
      "weeklyUsage": [
        199,
        142,
        143,
        87,
        163,
        130,
        118,
        3,
        90,
        149,
        50,
        104,
        122,
        77,
        137,
        13,
        192,
        68,
        182,
        2,
        45,
        147,
        58,
        99,
        48,
        59,
        147,
        1,
        107,
        198,
        173,
        60,
        72,
        174,
        129,
        165,
        93,
        139,
        103,
        0,
        80,
        67,
        66,
        18,
        1,
        83,
        0,
        198,
        179,
        26,
        2,
        10
      ],
      "weeklyWasted": [
        129,
        165,
        93,
        139,
        103,
        0,
        80,
        67,
        66,
        18,
        1,
        83,
        0,
        198,
        179,
        26,
        2,
        10,
        1,
        1,
        24,
        2,
        82,
        34,
        90,
        48,
        58,
        99,
        52,
        1,
        68,
        79,
        55,
        98,
        0,
        53,
        87,
        55,
        17,
        99,
        27,
        42,
        84,
        99,
        98,
        97,
        4,
        24,
        29,
        3,
        23,
        54,
        85,
        99,
        63,
        18,
        32,
        1,
        38,
        5,
        45,
        95,
        90,
        97,
        79,
        33,
        19,
        86,
        17,
        2
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyUsage": [
        106,
        68,
        86,
        5,
        122,
        143,
        4,
        3,
        199,
        1,
        199,
        113,
        133,
        170,
        165,
        198,
        192,
        162,
        18,
        184,
        147,
        4,
        64,
        112,
        170,
        85,
        186,
        129,
        101,
        19,
        137,
        0,
        1,
        4,
        4,
        108,
        113,
        134,
        112,
        199,
        38,
        0,
        0,
        0,
        1,
        4,
        70,
        151,
        198,
        199,
        2,
        152
      ],
      "weeklyWasted": [
        4,
        108,
        113,
        134,
        112,
        199,
        38,
        0,
        0,
        0,
        1,
        4,
        70,
        151,
        198,
        199,
        2,
        152,
        69,
        29,
        25,
        29,
        22,
        42,
        83,
        29,
        99,
        61,
        67,
        78,
        56,
        99,
        55,
        7,
        45,
        75,
        32,
        99,
        16,
        52,
        0,
        19,
        11,
        95,
        27,
        96,
        51,
        22,
        2,
        50,
        87,
        98,
        77,
        0,
        83,
        48,
        0,
        1,
        5,
        28,
        99,
        76,
        0,
        66,
        81,
        98,
        99,
        17,
        99,
        1
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyUsage": [
        199,
        138,
        95,
        159,
        79,
        64,
        194,
        59,
        2,
        199,
        71,
        143,
        129,
        0,
        166,
        93,
        192,
        71,
        163,
        197,
        152,
        133,
        167,
        97,
        3,
        168,
        127,
        142,
        10,
        126,
        70,
        0,
        111,
        82,
        13,
        49,
        186,
        93,
        10,
        188,
        0,
        53,
        7,
        31,
        15,
        97,
        21,
        133,
        167,
        10,
        12,
        3
      ],
      "weeklyWasted": [
        13,
        49,
        186,
        93,
        10,
        188,
        0,
        53,
        7,
        31,
        15,
        97,
        21,
        133,
        167,
        10,
        12,
        3,
        75,
        70,
        47,
        33,
        90,
        57,
        60,
        0,
        28,
        33,
        95,
        99,
        74,
        73,
        30,
        1,
        38,
        67,
        15,
        0,
        88,
        0,
        97,
        61,
        0,
        75,
        98,
        69,
        99,
        55,
        2,
        65,
        1,
        85,
        71,
        99,
        99,
        52,
        32,
        44,
        15,
        0,
        86,
        99,
        35,
        51,
        99,
        71,
        99,
        31,
        99,
        1
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyUsage": [
        7,
        6,
        84,
        93,
        5,
        80,
        194,
        56,
        52,
        67,
        199,
        197,
        137,
        0,
        125,
        3,
        101,
        199,
        167,
        139,
        1,
        72,
        62,
        120,
        16,
        50,
        50,
        1,
        128,
        118,
        83,
        0,
        151,
        78,
        84,
        80,
        138,
        142,
        10,
        67,
        24,
        134,
        134,
        0,
        2,
        170,
        0,
        0,
        136,
        148,
        15,
        3
      ],
      "weeklyWasted": [
        84,
        80,
        138,
        142,
        10,
        67,
        24,
        134,
        134,
        0,
        2,
        170,
        0,
        0,
        136,
        148,
        15,
        3,
        46,
        41,
        0,
        2,
        37,
        73,
        77,
        0,
        83,
        99,
        51,
        52,
        60,
        99,
        13,
        1,
        71,
        52,
        3,
        68,
        49,
        0,
        64,
        95,
        0,
        60,
        73,
        51,
        99,
        1,
        13,
        97,
        14,
        60,
        99,
        59,
        75,
        41,
        78,
        55,
        93,
        2,
        99,
        50,
        22,
        75,
        99,
        98,
        66,
        66,
        99,
        50
      ]
    },
    {
      "id": "93D87233",
      "weeklyUsage": [
        33,
        83,
        0,
        162,
        112,
        78,
        101,
        98,
        65,
        17,
        95,
        113,
        124,
        0,
        130,
        95,
        192,
        131,
        147,
        106,
        118,
        4,
        2,
        124,
        79,
        25,
        170,
        31,
        199,
        41,
        94,
        93,
        87,
        109,
        22,
        146,
        119,
        5,
        166,
        131,
        0,
        171,
        20,
        37,
        61,
        171,
        195,
        116,
        131,
        160,
        2,
        3
      ],
      "weeklyWasted": [
        22,
        146,
        119,
        5,
        166,
        131,
        0,
        171,
        20,
        37,
        61,
        171,
        195,
        116,
        131,
        160,
        2,
        3,
        76,
        75,
        77,
        2,
        93,
        98,
        85,
        13,
        78,
        8,
        70,
        48,
        15,
        3,
        89,
        25,
        0,
        55,
        19,
        84,
        85,
        86,
        7,
        0,
        0,
        58,
        98,
        7,
        52,
        1,
        2,
        62,
        9,
        65,
        32,
        97,
        76,
        67,
        72,
        1,
        99,
        0,
        74,
        16,
        40,
        65,
        99,
        98,
        99,
        88,
        14,
        50
      ]
    },
    {
      "id": "CDA15976",
      "weeklyUsage": [
        170,
        144,
        140,
        46,
        112,
        158,
        194,
        3,
        85,
        41,
        171,
        197,
        198,
        199,
        169,
        73,
        192,
        187,
        168,
        172,
        1,
        82,
        2,
        150,
        116,
        198,
        168,
        51,
        108,
        128,
        57,
        98,
        35,
        38,
        134,
        161,
        48,
        199,
        147,
        199,
        85,
        29,
        144,
        0,
        48,
        195,
        16,
        58,
        46,
        108,
        96,
        3
      ],
      "weeklyWasted": [
        134,
        161,
        48,
        199,
        147,
        199,
        85,
        29,
        144,
        0,
        48,
        195,
        16,
        58,
        46,
        108,
        96,
        3,
        41,
        1,
        99,
        36,
        91,
        59,
        12,
        0,
        41,
        71,
        42,
        72,
        49,
        58,
        76,
        74,
        77,
        63,
        99,
        8,
        23,
        0,
        97,
        11,
        32,
        75,
        27,
        53,
        66,
        6,
        2,
        64,
        99,
        72,
        99,
        72,
        2,
        74,
        0,
        23,
        26,
        0,
        99,
        99,
        96,
        99,
        67,
        22,
        99,
        64,
        83,
        39
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyUsage": [
        135,
        113,
        0,
        5,
        0,
        15,
        82,
        51,
        155,
        1,
        100,
        45,
        198,
        72,
        186,
        24,
        131,
        143,
        127,
        2,
        50,
        79,
        173,
        159,
        157,
        73,
        15,
        43,
        117,
        174,
        0,
        69,
        77,
        103,
        156,
        95,
        130,
        126,
        83,
        186,
        0,
        76,
        68,
        0,
        1,
        152,
        0,
        9,
        157,
        199,
        180,
        193
      ],
      "weeklyWasted": [
        156,
        95,
        130,
        126,
        83,
        186,
        0,
        76,
        68,
        0,
        1,
        152,
        0,
        9,
        157,
        199,
        180,
        193,
        56,
        53,
        99,
        2,
        28,
        1,
        21,
        0,
        99,
        90,
        61,
        22,
        85,
        77,
        9,
        98,
        62,
        96,
        9,
        67,
        2,
        0,
        25,
        66,
        44,
        53,
        63,
        80,
        20,
        1,
        22,
        89,
        79,
        98,
        40,
        18,
        55,
        44,
        41,
        1,
        22,
        98,
        90,
        86,
        81,
        0,
        99,
        98,
        71,
        99,
        90,
        1
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyUsage": [
        149,
        151,
        96,
        5,
        125,
        116,
        141,
        6,
        72,
        39,
        64,
        87,
        74,
        0,
        199,
        36,
        192,
        60,
        110,
        150,
        160,
        126,
        139,
        195,
        3,
        198,
        169,
        41,
        67,
        77,
        144,
        0,
        26,
        126,
        101,
        108,
        123,
        163,
        124,
        111,
        0,
        82,
        110,
        198,
        1,
        117,
        26,
        198,
        170,
        199,
        133,
        196
      ],
      "weeklyWasted": [
        101,
        108,
        123,
        163,
        124,
        111,
        0,
        82,
        110,
        198,
        1,
        117,
        26,
        198,
        170,
        199,
        133,
        196,
        50,
        40,
        89,
        2,
        33,
        57,
        33,
        49,
        99,
        94,
        67,
        74,
        1,
        75,
        0,
        1,
        98,
        52,
        1,
        7,
        53,
        89,
        34,
        13,
        89,
        99,
        97,
        71,
        99,
        44,
        2,
        92,
        77,
        60,
        83,
        68,
        62,
        53,
        33,
        99,
        0,
        0,
        95,
        63,
        99,
        40,
        72,
        98,
        66,
        82,
        34,
        25
      ]
    },
    {
      "id": "8607492C",
      "weeklyUsage": [
        168,
        2,
        158,
        81,
        130,
        109,
        166,
        71,
        5,
        1,
        84,
        2,
        95,
        22,
        197,
        158,
        192,
        150,
        171,
        2,
        123,
        198,
        2,
        157,
        3,
        161,
        96,
        115,
        187,
        33,
        46,
        0,
        18,
        199,
        41,
        0,
        192,
        50,
        72,
        36,
        59,
        51,
        119,
        0,
        131,
        194,
        132,
        116,
        174,
        8,
        77,
        99
      ],
      "weeklyWasted": [
        41,
        0,
        192,
        50,
        72,
        36,
        59,
        51,
        119,
        0,
        131,
        194,
        132,
        116,
        174,
        8,
        77,
        99,
        66,
        1,
        0,
        2,
        73,
        36,
        57,
        0,
        93,
        52,
        65,
        77,
        7,
        76,
        85,
        42,
        0,
        59,
        1,
        82,
        17,
        0,
        68,
        99,
        52,
        37,
        0,
        46,
        50,
        23,
        43,
        81,
        27,
        75,
        65,
        88,
        71,
        4,
        99,
        99,
        99,
        34,
        99,
        75,
        99,
        35,
        99,
        50,
        65,
        67,
        42,
        45
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyUsage": [
        199,
        2,
        185,
        39,
        42,
        14,
        105,
        69,
        199,
        1,
        134,
        2,
        187,
        124,
        1,
        54,
        192,
        168,
        189,
        5,
        133,
        4,
        182,
        189,
        3,
        112,
        138,
        56,
        193,
        138,
        133,
        28,
        54,
        193,
        139,
        135,
        198,
        46,
        124,
        3,
        66,
        0,
        114,
        99,
        1,
        166,
        44,
        192,
        197,
        81,
        165,
        143
      ],
      "weeklyWasted": [
        139,
        135,
        198,
        46,
        124,
        3,
        66,
        0,
        114,
        99,
        1,
        166,
        44,
        192,
        197,
        81,
        165,
        143,
        9,
        5,
        99,
        2,
        20,
        1,
        63,
        66,
        99,
        65,
        0,
        11,
        5,
        99,
        0,
        30,
        24,
        59,
        13,
        66,
        45,
        41,
        0,
        0,
        11,
        62,
        58,
        90,
        88,
        6,
        8,
        55,
        64,
        98,
        19,
        11,
        80,
        30,
        97,
        32,
        0,
        0,
        34,
        99,
        71,
        81,
        93,
        98,
        72,
        20,
        18,
        41
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        48,
        68,
        71,
        64,
        40,
        72,
        108,
        121,
        86,
        64,
        165,
        143,
        198,
        63,
        123,
        65,
        140,
        154,
        3,
        2,
        185,
        4,
        80,
        1,
        19,
        33,
        112,
        28,
        44,
        136,
        152,
        26,
        73,
        59,
        125,
        132,
        198,
        130,
        10,
        47,
        42,
        34,
        0,
        30,
        1,
        67,
        24,
        78,
        189,
        134,
        5,
        3
      ],
      "weeklyWasted": [
        125,
        132,
        198,
        130,
        10,
        47,
        42,
        34,
        0,
        30,
        1,
        67,
        24,
        78,
        189,
        134,
        5,
        3,
        36,
        30,
        25,
        79,
        65,
        42,
        31,
        0,
        82,
        75,
        3,
        99,
        74,
        3,
        29,
        20,
        39,
        99,
        16,
        88,
        86,
        0,
        10,
        65,
        47,
        76,
        98,
        92,
        24,
        12,
        17,
        97,
        62,
        98,
        88,
        25,
        82,
        99,
        46,
        1,
        82,
        55,
        87,
        60,
        99,
        42,
        99,
        43,
        61,
        50,
        13,
        43
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyUsage": [
        63,
        109,
        142,
        194,
        0,
        53,
        97,
        137,
        72,
        1,
        117,
        197,
        159,
        48,
        108,
        58,
        192,
        71,
        3,
        155,
        142,
        198,
        101,
        150,
        197,
        198,
        137,
        1,
        95,
        198,
        65,
        151,
        25,
        63,
        104,
        79,
        124,
        106,
        131,
        44,
        32,
        75,
        133,
        61,
        188,
        189,
        195,
        57,
        198,
        171,
        37,
        93
      ],
      "weeklyWasted": [
        104,
        79,
        124,
        106,
        131,
        44,
        32,
        75,
        133,
        61,
        188,
        189,
        195,
        57,
        198,
        171,
        37,
        93,
        49,
        1,
        40,
        2,
        27,
        56,
        98,
        46,
        76,
        45,
        54,
        45,
        10,
        99,
        53,
        26,
        79,
        51,
        40,
        72,
        99,
        18,
        46,
        42,
        0,
        99,
        98,
        55,
        2,
        59,
        76,
        97,
        1,
        37,
        21,
        32,
        99,
        99,
        49,
        91,
        0,
        75,
        36,
        52,
        46,
        63,
        95,
        98,
        28,
        99,
        34,
        25
      ]
    },
    {
      "id": "867038F2",
      "weeklyUsage": [
        7,
        2,
        170,
        47,
        45,
        147,
        184,
        197,
        2,
        1,
        199,
        197,
        144,
        67,
        98,
        107,
        181,
        92,
        3,
        32,
        1,
        60,
        64,
        139,
        24,
        158,
        3,
        11,
        26,
        50,
        93,
        196,
        197,
        37,
        134,
        62,
        174,
        5,
        10,
        137,
        75,
        49,
        87,
        63,
        20,
        135,
        0,
        190,
        182,
        199,
        117,
        124
      ],
      "weeklyWasted": [
        134,
        62,
        174,
        5,
        10,
        137,
        75,
        49,
        87,
        63,
        20,
        135,
        0,
        190,
        182,
        199,
        117,
        124,
        6,
        47,
        43,
        25,
        98,
        32,
        36,
        0,
        71,
        0,
        0,
        73,
        53,
        88,
        84,
        1,
        0,
        99,
        80,
        38,
        67,
        0,
        92,
        85,
        0,
        76,
        98,
        75,
        2,
        1,
        2,
        63,
        34,
        79,
        87,
        51,
        19,
        39,
        42,
        99,
        79,
        0,
        99,
        94,
        35,
        52,
        39,
        88,
        4,
        99,
        99,
        1
      ]
    },
    {
      "id": "19A12B20",
      "weeklyUsage": [
        199,
        2,
        79,
        195,
        0,
        31,
        126,
        113,
        114,
        45,
        168,
        166,
        111,
        154,
        116,
        143,
        111,
        160,
        184,
        122,
        1,
        132,
        63,
        146,
        101,
        1,
        105,
        70,
        199,
        0,
        0,
        6,
        95,
        4,
        120,
        0,
        104,
        118,
        165,
        63,
        69,
        34,
        183,
        81,
        1,
        88,
        53,
        74,
        163,
        199,
        91,
        142
      ],
      "weeklyWasted": [
        120,
        0,
        104,
        118,
        165,
        63,
        69,
        34,
        183,
        81,
        1,
        88,
        53,
        74,
        163,
        199,
        91,
        142,
        4,
        1,
        3,
        17,
        98,
        98,
        14,
        0,
        99,
        99,
        99,
        6,
        9,
        99,
        57,
        1,
        56,
        1,
        56,
        14,
        36,
        0,
        63,
        18,
        0,
        99,
        61,
        97,
        39,
        68,
        6,
        19,
        1,
        90,
        99,
        0,
        64,
        41,
        89,
        36,
        39,
        3,
        70,
        80,
        61,
        53,
        75,
        98,
        67,
        1,
        20,
        39
      ]
    },
    {
      "id": "74B98557",
      "weeklyUsage": [
        125,
        67,
        130,
        71,
        8,
        1,
        61,
        109,
        71,
        131,
        156,
        32,
        115,
        56,
        199,
        70,
        130,
        119,
        3,
        130,
        197,
        194,
        12,
        117,
        68,
        128,
        110,
        1,
        56,
        59,
        43,
        130,
        131,
        8,
        124,
        118,
        61,
        5,
        139,
        0,
        56,
        95,
        4,
        9,
        1,
        91,
        14,
        8,
        73,
        199,
        87,
        3
      ],
      "weeklyWasted": [
        124,
        118,
        61,
        5,
        139,
        0,
        56,
        95,
        4,
        9,
        1,
        91,
        14,
        8,
        73,
        199,
        87,
        3,
        1,
        50,
        33,
        28,
        79,
        97,
        29,
        64,
        30,
        0,
        99,
        47,
        74,
        81,
        31,
        1,
        81,
        56,
        1,
        37,
        2,
        0,
        0,
        23,
        35,
        74,
        20,
        47,
        71,
        56,
        23,
        55,
        75,
        98,
        99,
        11,
        60,
        55,
        59,
        23,
        45,
        55,
        77,
        25,
        56,
        99,
        55,
        67,
        99,
        36,
        49,
        22
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyUsage": [
        153,
        68,
        188,
        143,
        93,
        139,
        66,
        78,
        48,
        1,
        128,
        25,
        161,
        108,
        137,
        92,
        192,
        77,
        3,
        105,
        110,
        39,
        137,
        15,
        93,
        158,
        128,
        90,
        123,
        148,
        106,
        8,
        1,
        4,
        140,
        199,
        52,
        121,
        48,
        50,
        4,
        24,
        164,
        109,
        50,
        121,
        110,
        4,
        111,
        110,
        95,
        107
      ],
      "weeklyWasted": [
        140,
        199,
        52,
        121,
        48,
        50,
        4,
        24,
        164,
        109,
        50,
        121,
        110,
        4,
        111,
        110,
        95,
        107,
        4,
        1,
        9,
        2,
        42,
        71,
        59,
        0,
        64,
        10,
        42,
        1,
        36,
        99,
        36,
        24,
        9,
        1,
        93,
        68,
        60,
        41,
        46,
        0,
        17,
        75,
        60,
        29,
        20,
        1,
        28,
        53,
        1,
        98,
        11,
        0,
        70,
        99,
        99,
        1,
        0,
        0,
        86,
        99,
        84,
        76,
        50,
        98,
        75,
        85,
        42,
        11
      ]
    }
  ]