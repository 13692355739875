const Checkbox = (props: { value: boolean, disabled?: boolean, onClick: () => void }) => {
    return (
        <div
            style={{ boxShadow: '0px 0px 2px 0px #0000001A inset 0px 1px 2px 0px #0000001A inset' }}
            className="w-5 h-5 min-w-5 min-h-5 border border-neutral-3 bg-white rounded-[4px] cursor-pointer overflow-hidden"
            onClick={() => {
                if (!props.disabled) {
                    props.onClick()
                }
            }}
        >
            {props.value && (
                <div className="flex items-center justify-center w-full h-full bg-gradient-to-t from-primary-4 to-primary-5 cursor-pointer rounded-[4px]">
                    <img className="w-[10px] h-[10px]" src="/images/icons/ic-checkmark.svg" />
                </div>
            )}

        </div>
    )
}

export default Checkbox