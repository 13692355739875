import { ReactNode } from "react"

const Card = (props: { title: string, subtitle: string, rightArrow?: boolean, children: ReactNode }) => {
    return (
        <div className="flex flex-col w-full border border-neutral-2 bg-neutral-1 h-[310px] rounded-md p-3">
            <div className="flex items-center">
                <div>
                    <p className="font-body font-semibold">{props.title}</p>
                    <p className="font-subheadline text-neutral-5">{props.subtitle}</p>
                </div>

                {props.rightArrow && (
                    <div className="flex gap-3 ml-auto">
                        <p className="font-body text-neutral-5">View All</p>
                        <img className="h-[13px] w=[10px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                    </div>
                )}
            </div>

            {props.children}
        </div>
    )
}

export default Card