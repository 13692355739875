export const mockSpecialties = [
    "Acute Assessment Unit (AAU)",
    "Intensive Care Unit (ICU)",
    "Digestive Disease & SUrgery Institute (DDSI)",
    "Heart, Vascular & Thoracic Insitute (HVTI)",
    "Neuro",
    "Ortho",
    "Operating Theater (OT)",
    "Outreach",
    "Logistic",
    "Executive",
    "Repair & Maintenance",
]