import { useState, createContext, useContext, ReactNode, useEffect } from "react";
import { onSnapshot, doc, setDoc, collection, Timestamp, updateDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase/firebase";

import { MedicalItem, mockMedicalItems } from "../model/medicalItem/medicalItem";
import { User } from "../model/user/user";
import { Substitute } from "../model/substitute/substitute";
import { Disruption } from "../model/disruption/disruption";
import { mockProcedures } from "../model/procedure/procedure";

export const CursotChatContext = createContext<CursotChatContextType | null>(null);
export const UserContext = createContext<UserContextType | null>(null);
export const ChatContext = createContext<ChatContextType | null>(null);
export const EntityContext = createContext<EntityContextType | null>(null);
export const LocationContext = createContext<LocationContextType | null>(null);
export const TeamContext = createContext<TeamContextType | null>(null);
export const SidePanelContext = createContext<SidePanelContextType | null>(null);

export type CursotChatContextType = {
    cursors: {
        [roomId: string]: {
            mouseX: number;
            mouseY: number;
            uid: string;
            username: string;
            currentPage: string;
            photoURL?: string | undefined;
        }[]
    },
    setCursors: React.Dispatch<React.SetStateAction<{
        [roomId: string]: {
            mouseX: number;
            mouseY: number;
            uid: string;
            username: string;
            currentPage: string;
            photoURL?: string | undefined;
        }[]
    }>>
}

export type UserContextType = {
    user: User | null,
    users: User[],
    updateUser: (value: Record<string, any>) => void
}

export type ChatContextType = {
    chatPanelOpen: string,
    setChatPanelOpen: React.Dispatch<React.SetStateAction<string>>,
}

export type EntityContextType = {
    substitutes: Substitute[],
    medicalItems: MedicalItem[],
    disruptions: Disruption[],
}

export type LocationContextType = {
    selectedLocation: string,
    setSelectedLocation: React.Dispatch<React.SetStateAction<string>>,
}

export type TeamContextType = {
    selectedTeam: string,
    setSelectedTeam: React.Dispatch<React.SetStateAction<string>>,
}

export type SidePanelContextType = {
    sidePanelOpen: boolean,
    setSidePanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const GlobalContext = (props: { children: ReactNode }) => {
    const [medicalItems, setMedicalItems] = useState<MedicalItem[]>([])
    const [substitutes, setSubstitutes] = useState<Substitute[]>([])
    const [disruptions, setDisruptions] = useState<Disruption[]>([])

    const [cursors, setCursors] = useState<{
        [roomId: string]: {
            mouseX: number;
            mouseY: number;
            uid: string;
            username: string;
            currentPage: string;
            photoURL?: string | undefined;
        }[];
    }>({})
    const [user, setUser] = useState<User | null>(null)
    const [users, setUsers] = useState<User[]>([])

    const [chatPanelOpen, setChatPanelOpen] = useState('')
    const [sidePanelOpen, setSidePanelOpen] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState('')
    const [selectedTeam, setSelectedTeam] = useState('')

    const userId = auth.currentUser?.uid

    useEffect(() => {
        const unsub = onSnapshot(collection(firestore, "users"), (snapshot) => {
            const docs = snapshot.docs

            const users = docs.map((doc) => {
                const data = doc.data()

                const user: User = {
                    ...data,
                    id: data['id'],
                    name: data['name'] || '',
                    email: data['email'] || '',
                    photoURL: data['photoURL'] || ''
                }

                return user
            })

            setUser(users.find((v) => v.id === auth.currentUser?.uid) || null)

            setUsers(users)
        })

        const substituteUnsub = onSnapshot(collection(firestore, "substitutes"), (snapshot) => {
            const docs = snapshot.docs

            const data = docs.map((doc) => {
                return doc.data() as Substitute
            })

            setSubstitutes(data)
        })

        const itemUnsub = onSnapshot(collection(firestore, "items"), (snapshot) => {
            const docs = snapshot.docs

            const data = docs.map((doc) => {
                return doc.data() as MedicalItem
            })

            setMedicalItems(data)
        })

        const disruptionsUnsub = onSnapshot(collection(firestore, "disruptions"), (snapshot) => {
            const docs = snapshot.docs

            const data = docs.map((doc) => {
                return doc.data() as Disruption
            })

            setDisruptions(data)
        })

        return () => {
            unsub()
            substituteUnsub()
            itemUnsub()
            disruptionsUnsub()
        }
    }, [userId])

    const updateUser = (value: Record<string, any>) => {
        if (userId) {
            setDoc(doc(collection(firestore, 'users'), userId), { ...value, id: userId }, { merge: true })
                .then(() => { })
                .catch((e) => { })
        }
    }

    return (
        <CursotChatContext.Provider value={{ cursors, setCursors }}>
            <UserContext.Provider value={{ user, users, updateUser }}>
                <ChatContext.Provider value={{ chatPanelOpen, setChatPanelOpen }}>
                    <EntityContext.Provider value={{
                        substitutes,
                        medicalItems,
                        disruptions
                    }}>
                        <LocationContext.Provider value={{ selectedLocation, setSelectedLocation }}>
                            <TeamContext.Provider value={{ selectedTeam, setSelectedTeam }}>
                                <SidePanelContext.Provider value={{ sidePanelOpen, setSidePanelOpen }}>
                                    {props.children}
                                </SidePanelContext.Provider>
                            </TeamContext.Provider>
                        </LocationContext.Provider>
                    </EntityContext.Provider>
                </ChatContext.Provider>
            </UserContext.Provider>
        </CursotChatContext.Provider>
    );
}

export default GlobalContext