import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore"
import { getFunctions } from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyC43M6QzLAi0_stmFFOmRzRbutUi0jkKgk",
  authDomain: "theavaos.firebaseapp.com",
  projectId: "theavaos",
  storageBucket: "theavaos.appspot.com",
  messagingSenderId: "901410966828",
  appId: "1:901410966828:web:e4358117d7189198ddb089",
  measurementId: "G-32YL699C2X",
};

const firebaseConfigRealtime = {
  apiKey: "AIzaSyC43M6QzLAi0_stmFFOmRzRbutUi0jkKgk",
  authDomain: "theavaos.firebaseapp.com",
  databaseURL: "https://theavaos-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "theavaos",
  storageBucket: "theavaos.appspot.com",
  messagingSenderId: "901410966828",
  appId: "1:901410966828:web:e4358117d7189198ddb089",
  measurementId: "G-32YL699C2X",
};

export const app = initializeApp(firebaseConfig);
export const appRealtime = initializeApp(firebaseConfigRealtime, 'appRealtime');

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const storage = getStorage(app)
export const database = getDatabase(appRealtime)
export const firestore = getFirestore(app)
export const functions = getFunctions(app)