import { ReactNode, useState, useEffect, useRef, useContext, useCallback } from "react"
import { database, firestore } from "../../firebase/firebase"
import { auth } from "../../firebase/firebase"
import moment from "moment"
import TabPicker from "../tabPicker/tabPicker"
import { useCookies } from "react-cookie"
import uuid from "react-uuid"
import PrimaryButton from "../primaryButton/primaryButton"
import { matchPath, useLocation, useParams } from "react-router-dom"
import { Chatroom } from "../../model/chat/chat"
import { Timestamp, arrayRemove, arrayUnion, collection, doc, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore"
import ChatDetailPanel from "./chatDetailPanel"

const ChatPanel = (props: { selectedChatroomId?: string, onDismiss: () => void }) => {
    const { id } = useParams()

    const [selectedTab, setSelectedTab] = useState('Direct')
    const [chats, setChats] = useState<Chatroom[]>()
    const [open, setOpen] = useState(false)

    const [selectedChatRoom, setSelectedChatRoom] = useState<Chatroom>()

    const [cookies, setCookie, removeCookie] = useCookies(['uid']);

    const location = useLocation()

    const isInventoryItem = matchPath('dashboard/inventory/:id', location.pathname)
    const isProcedureItem = matchPath('dashboard/procedure/:id', location.pathname)

    const selectedChat = chats?.find((v) => v.id === selectedChatRoom?.id)

    useEffect(() => {
        const q = query(collection(firestore, "chats"),
            // where('participants', 'array-contains', auth.currentUser?.uid || ''),
            where('resolved', '==', false)
        )

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)

            const sortedChats = chats.sort((a, b) => {
                if (!b.lastMessage) {
                    return -1
                }

                if (!a.lastMessage) {
                    return -1
                }

                return (b.lastMessage?.createdAt.seconds || 0) - (a.lastMessage?.createdAt.seconds || 0)
            })

            setChats(sortedChats)

            if (!selectedChatRoom && props.selectedChatroomId !== 'open') {
                setSelectedChatRoom(chats.find((v) => v.id === props.selectedChatroomId))
            }
        })

        return () => unsub()
    }, [props.selectedChatroomId])

    useEffect(() => {
        setOpen(true)

        return () => {
            setOpen(false)
        }
    }, [])

    const createChatroom = async () => {
        const chatroomId = uuid()
        let chatroom: Chatroom = {
            id: chatroomId,
            title: 'New Conversation',
            type: 'entity',
            resolved: false,
            participants: auth.currentUser?.uid ? [auth.currentUser?.uid] : [],
            entities: [],
            entityIds: [],
            hasEntity: false,
            hasPoll: false,
            answeredPoll: false
        }

        if (isInventoryItem && id) {
            chatroom.entities = id ? [{
                id: id,
                type: 'item'
            }] : []
            chatroom.hasEntity = true
            chatroom.entityIds = [id]
        }

        if (isProcedureItem && id) {
            chatroom.entities = id ? [{
                id: id,
                type: 'scene'
            }] : []
            chatroom.hasEntity = true
            chatroom.entityIds = [id]
        }

        await setDoc(doc(firestore, 'chats', chatroomId), chatroom)
    }

    return (
        <>
            <div style={{ width: open ? '400px' : '0px', boxShadow: '0px 38px 90px 0px rgba(0, 0, 0, 0.25), 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.60)' }} className={`absolute top-0 right-0 flex flex-col h-screen bg-material-ultra border-l border-neutral-2 backdrop-blur-2xl transition-width ease-in-out duration-300 z-[300]`}>
                <div className="flex flex-col p-2.5 gap-2.5">
                    <div className="flex gap-1.5 my-1 items-center">
                        <p className="font-title-1 font-bold">Chat</p>

                        <img onClick={() => props.onDismiss()} className="cursor-pointer ml-auto h-[20px] w-[20px]" src={`/images/icons/ic-xmark-circle.svg`}></img>
                    </div>

                    <TabPicker
                        selectedTab={selectedTab}
                        tabs={["Direct", "Entity"]}
                        onChange={(v) => setSelectedTab(v)}
                    />

                    <div style={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.09) inset' }} className="flex items-center font-body border border-neutral-2 bg-white/50 rounded-md px-3.5 py-2 text-neutral-6">
                        <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                        <input className="w-full bg-transparent placeholder:text-neutral-4 focus:outline-none" placeholder="Search..."></input>
                    </div>
                </div>

                {selectedTab === "Direct" ?
                    <div className="bg-white flex-grow overflow-y-auto">
                        {chats?.filter((v) => v.participants.includes(auth.currentUser?.uid || '')).map((chat) => {
                            return (
                                <>
                                    <div onClick={() => setSelectedChatRoom(chat)} className="cursor-pointer flex py-3 px-4 gap-2 items-start">
                                        <div className={`h-[40px] min-w-[40px] gradient-teal rounded-md flex items-center justify-center`}>
                                            <img className="h-[16px] w-[16px]" src={`/images/icons/ic-shipping-box-white.svg`}></img>
                                        </div>

                                        <div className="w-full">
                                            <div className="flex">
                                                <p className="font-body line-clamp-2">{chat.title}</p>
                                                {chat.lastMessage && (
                                                    <p className="ml-auto font-body text-neutral-5 whitespace-nowrap">{moment(chat.lastMessage?.createdAt.toDate()).fromNow()}</p>
                                                )}

                                            </div>

                                            <p className="font-body text-neutral-5">{chat.lastMessage?.message}</p>
                                        </div>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-4"></div>
                                </>
                            )
                        })}
                    </div>
                    :
                    <div className="bg-white flex-grow overflow-y-auto">
                        {chats?.filter((v) => v.participants.includes(auth.currentUser?.uid || '')).map((chat) => {
                            return (
                                <>
                                    <div onClick={() => setSelectedChatRoom(chat)} className="cursor-pointer flex py-3 px-4 gap-2 items-start">
                                        <div className={`h-[40px] min-w-[40px] gradient-teal rounded-md flex items-center justify-center`}>
                                            <img className="h-[16px] w-[16px]" src={`/images/icons/ic-shipping-box-white.svg`}></img>
                                        </div>

                                        <div className="w-full">
                                            <div className="flex">
                                                <p className="font-body line-clamp-2">{chat.title}</p>
                                                {chat.lastMessage && (
                                                    <p className="ml-auto font-body text-neutral-5 whitespace-nowrap">{moment(chat.lastMessage?.createdAt.toDate()).fromNow()}</p>
                                                )}
                                            </div>

                                            <p className="font-body text-neutral-5">{chat.lastMessage?.message}</p>
                                        </div>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-4"></div>
                                </>
                            )
                        })}
                    </div>
                }

                <div className="flex p-2.5">
                    <PrimaryButton
                        title="New Conversation"
                        type="primary"
                        size="xl"
                        maxWidth={true}
                        onClick={() => createChatroom()}
                    />
                </div>
            </div>

            {selectedChatRoom && selectedChat && (
                <ChatDetailPanel
                    chatType={selectedChatRoom.type}
                    chatroom={selectedChat || selectedChatRoom}
                    chatUserId={cookies.uid || ''}
                    onDismiss={() => {
                        setSelectedChatRoom(undefined)
                    }}
                />
            )}
        </>
    )
}

export default ChatPanel