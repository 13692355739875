import { locations } from "../location/location"
import { getRandomElement } from "../../utils/array"

import '../../utils/string.ts'
import { Timestamp } from "firebase/firestore"
import { mockSpecialties } from "../specialty/specialty"

export interface Procedure {
  id: string
  name: string
  status: string // TRUE or FALSE
  surgeons: number
  items: number
  category: string
  cost: string
  location: string // Where the procedure is performed
  performedAmount: number
  availablePreferences: number
  acceptedOrders: number
  rejectedOrders: number
  backorderedOrders: number
  deletedOrders: number
  holdOrders: number
  requiredItems: string[] // IDs of items that are required for the procedure
  lastUpdated?: Timestamp
  lastUpdatedBy?: string
}

export var mockProcedures: Procedure[] = [
  {
    "id": "PR-001",
    "name": "Appendectomy",
    "status": "FALSE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 1,
    "requiredItems": "[E0A95BBC,9C01A6AA,F55885BD,BD23BA16,CB6FF509,93D87233,14968630,EB58F890,F04529EA,9FC8D91A,7B500D6B,CCD70D1D,ACF7D73A,D9345860,F36679BC,BD2DB5FE,11660B2E,11660B2E,9202537]".toArray(),
  },
  {
    "id": "PR-002",
    "name": "Cholecystectomy",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 4,
    "deletedOrders": 0,
    "requiredItems": "[867038F2,C71B9801,88F245C1,FFE4F1A9,FFE4F1A9,D8F6DF54,2C1769CF,EB58F890,7B415249,2C1769CF,CB6FF509,79FC1E21,6E657E21,CB6FF509,88F245C1,05BD5A7D,D33377F5,867038F2]".toArray(),
  },
  {
    "id": "PR-003",
    "name": "Colonoscopy",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 4,
    "deletedOrders": 0,
    "requiredItems": "[19A12B20,CCD70D1D,79FC1E21,14968630,14968630,7A8CC6BD,19A12B20,3CA906F9,065DB72B,8164C892,7D206E43,E491B04D,958C4CED,ACF7D73A,F2EF4025,42A1729B,0ED43D83,66AB5D2E]".toArray(),
  },
  {
    "id": "PR-004",
    "name": "Coronary artery bypass grafting (CABG)",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 0,
    "requiredItems": "[41097027,5F3AFC23,3C0BE0E7,E0A5E11A,B688BAF3,88F245C1,1EBC5378,05BD5A7D,2C1769CF,048701CE,9202537,44804BD4,B688BAF3,F2EF4025]".toArray(),
  },
  {
    "id": "PR-005",
    "name": "Hip replacement",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "10,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 0,
    "requiredItems": "[965102D9,CCD70D1D,BAF9CD1A,85ECBAF5,CB6FF509,74B98557,7B500D6B,F2EF4025,067037B3,93D87233,41097027,CDA15976,05BD5A7D,C71B9801,DB07BD66,6C1E6A6E,93D87233]".toArray(),
  },
  {
    "id": "PR-006",
    "name": "Hysterectomy",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 1,
    "requiredItems": "[ACF7D73A,88F245C1,D9345860,0AB87239,BB20277D,3D26994F,41097027,11660B2E,79A6C1CA,85ECBAF5,2770DFA7,CD9A49D0,4AE85F20,067037B3,E0A5E11A,88F245C1,58D36D79]".toArray(),
  },
  {
    "id": "PR-007",
    "name": "Knee replacement",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "10,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 1,
    "requiredItems": "[D8F6DF54,93D87233,200EA71C,067037B3,79FC1E21,CD9A49D0,A87CEAA3,0D70FFB7,DB301E71,0D70FFB7,200EA71C,E0A95BBC,E0A5E11A,2770DFA7,271D2E55]".toArray(),
  },
  {
    "id": "PR-008",
    "name": "Laparoscopic cholecystectomy",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 0,
    "requiredItems": "[44804BD4,542BBC0E,9FC8D91A,CCD70D1D,867038F2,9EAD0C19,7257F77C,9C01A6AA,8164C892,867038F2,099FDC48,DC16E9E8,E0A5E11A]".toArray(),
  },
  {
    "id": "PR-009",
    "name": "Open heart surgery",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 1,
    "requiredItems": "[5EDCDAE3,A87CEAA3,BB20277D,F1C1E746,9EAD0C19,BB20277D,271D2E55,5F3AFC23,E0A95BBC,DC16E9E8,93D87233,4FF22342,D9345860,C36BF119,6E657E21,BAF9CD1A,3D26994F,067037B3]".toArray(),
  },
  {
    "id": "PR-010",
    "name": "Prostate cancer surgery",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 0,
    "requiredItems": "[6E657E21,965102D9,C2F62DAA,44804BD4,049BCC88,C36BF119,D9345860,4E1CFAD9,9C01A6AA,85ECBAF5,FFE4F1A9,E0A5E11A,93D87233,C71B9801,048701CE,3D26994F,9EAD0C19]".toArray(),
  },
  {
    "id": "PR-011",
    "name": "Tonsillectomy",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[E80AAE4A,599EA2B3,79FC1E21,74B98557,7D206E43,D8F6DF54,200EA71C,BD23BA16,74B98557,7B415249,E0A5E11A,E02AF8C4,11660B2E,0ED43D83,E0A5E11A,05BD5A7D,9202537]".toArray(),
  },
  {
    "id": "PR-012",
    "name": "Cataract surgery",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 1,
    "requiredItems": "[4CD72DAE,8607492C,F55885BD,271D2E55,FB70040E,BAF9CD1A,065DB72B,CDA15976,2C1769CF,B688BAF3,DB07BD66,DC16E9E8,4AE85F20,44804BD4,7B500D6B,F36679BC,542BBC0E]".toArray(),
  },
  {
    "id": "PR-013",
    "name": "Craniotomy",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 4,
    "deletedOrders": 0,
    "requiredItems": "[93D87233,1EBC5378,19A12B20,EB58F890,C71B9801,D33377F5,4E1CFAD9,E0A95BBC,7D206E43,C36BF119,BB20277D,067037B3,79FC1E21,BD2DB5FE,11660B2E,F36679BC]".toArray(),
  },
  {
    "id": "PR-014",
    "name": "Gastric bypass",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 0,
    "requiredItems": "[1EBC5378,3C0BE0E7,4FF22342,7D206E43,099FDC48,599EA2B3,2770DFA7,9DD6903A,F2EF4025,79A6C1CA,A87CEAA3,065DB72B,8607492C,11660B2E,3C0BE0E7,44804BD4,BD2DB5FE,E80AAE4A]".toArray(),
  },
  {
    "id": "PR-015",
    "name": "Heart transplant",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[44804BD4,D9345860,200EA71C,BD23BA16,41097027,BB20277D,049BCC88,099FDC48,7B500D6B,E02AF8C4,BB20277D,A87CEAA3,87E6E3E8,542BBC0E,542BBC0E,3D26994F,099FDC48]".toArray(),
  },
  {
    "id": "PR-016",
    "name": "Liver transplant",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[8607492C,3C0BE0E7,D33377F5,9DD6903A,E491B04D,9EAD0C19,9EAD0C19,F55885BD,88F245C1,9202537,5EDCDAE3,200EA71C,3C0BE0E7,E0A95BBC,B688BAF3,64B9B7F1,4FF22342]".toArray(),
  },
  {
    "id": "PR-017",
    "name": "Lung transplant",
    "status": "FALSE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 1,
    "requiredItems": "[6E657E21,C2F62DAA,DC3EC10A,D33377F5,0D70FFB7,9DD6903A,7B500D6B,8164C892,958C4CED,5F3AFC23,3C0BE0E7,065DB72B,CCD70D1D,E0A95BBC,F413914D,CB6FF509,F413914D,D9345860]".toArray(),
  },
  {
    "id": "PR-018",
    "name": "Neurosurgery",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 4,
    "deletedOrders": 0,
    "requiredItems": "[D8F6DF54,66AB5D2E,599EA2B3,8164C892,22633EF7,8607492C,CCD70D1D,965102D9,DB301E71,02A8E856,065DB72B]".toArray(),
  },
  {
    "id": "PR-019",
    "name": "Oral and maxillofacial surgery",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 0,
    "requiredItems": "[4AE85F20,F2EF4025,864437DD,CCD70D1D,DC16E9E8,7B415249,BD2DB5FE,9C01A6AA,7B415249,B688BAF3,7B415249,867038F2,CCD70D1D,DC16E9E8,D9345860]".toArray(),
  },
  {
    "id": "PR-020",
    "name": "Orthopedic surgery",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "10,000",
    "location": "The Royal National Orthopaedic Hospital",
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 0,
    "requiredItems": "[79FC1E21,965102D9,88F245C1,E0A95BBC,19A12B20,DB301E71,271D2E55,ACF7D73A,88F245C1,958C4CED,C36BF119,2770DFA7,958C4CED,958C4CED,66AB5D2E,048701CE]".toArray(),
  },
  {
    "id": "PR-021",
    "name": "Plastic surgery",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 1,
    "requiredItems": "[864437DD,42A1729B,EB58F890,3CA906F9,9202537,85ECBAF5,9C01A6AA,965102D9,64B9B7F1,79A6C1CA,3CA906F9,C36BF119,11660B2E,867038F2,4CD72DAE]".toArray(),
  },
  {
    "id": "PR-022",
    "name": "Thoracic surgery",
    "status": "FALSE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 1,
    "requiredItems": "[9202537,3D26994F,DF6676C9,D33377F5,067037B3,3CA906F9,FB70040E,271D2E55,D33377F5,02A8E856,44804BD4,9FC8D91A,958C4CED,74B98557,5F3AFC23,7A8CC6BD,74B98557]".toArray(),
  },
  {
    "id": "PR-023",
    "name": "Arthroscopic knee",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": "The Royal National Orthopaedic Hospital",
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 1,
    "requiredItems": "[3C0BE0E7,E0A95BBC,049BCC88,BB20277D,BAF9CD1A,6C1E6A6E,4AE85F20,099FDC48,2C1769CF,E0A5E11A,065DB72B,DB301E71,D8F6DF54,542BBC0E,CDA15976,0D70FFB7,3C0BE0E7,3D26994F]".toArray(),
  },
  {
    "id": "PR-024",
    "name": "Bariatric",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 0,
    "requiredItems": "[D33377F5,DB301E71,7B415249,44804BD4,067037B3,3D26994F,D33377F5,CB6FF509,099FDC48,E02AF8C4,F04529EA,958C4CED,5EDCDAE3,FFE4F1A9,58D36D79,42A1729B,0D70FFB7]".toArray(),
  },
  {
    "id": "PR-025",
    "name": "Cardiac catheterization",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 1,
    "deletedOrders": 0,
    "requiredItems": "[FB70040E,FFE4F1A9,958C4CED,42A1729B,BD2DB5FE,A87CEAA3,958C4CED,CD9A49D0,02A8E856,8607492C,B21A9DE2,E491B04D,66AB5D2E,D33377F5,64B9B7F1]".toArray(),
  },
  {
    "id": "PR-026",
    "name": "Carpal tunnel release",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 4,
    "deletedOrders": 0,
    "requiredItems": "[7D206E43,7B500D6B,D9345860,330FF32E,9C01A6AA,F36679BC,200EA71C,05BD5A7D,A87CEAA3,C2F62DAA,3CA906F9,048701CE,41097027,965102D9,049BCC88,7D206E43,8607492C]".toArray(),
  },
  {
    "id": "PR-027",
    "name": "Cesarean section",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 1,
    "requiredItems": "[0D70FFB7,88F245C1,E02AF8C4,DF6676C9,B688BAF3,1EBC5378,049BCC88,DC16E9E8,A87CEAA3,E0A95BBC,048701CE,7D206E43,965102D9,6E657E21,66AB5D2E,4E1CFAD9,9FC8D91A]".toArray(),
  },
  {
    "id": "PR-028",
    "name": "Colon resection",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[2C1769CF,DB07BD66,7D206E43,DF6676C9,2C1769CF,7D206E43,9FC8D91A,F2EF4025,3CA906F9,C71B9801,C71B9801,6C1E6A6E,048701CE,02A8E856,049BCC88]".toArray(),
  },
  {
    "id": "PR-029",
    "name": "Endoscopy",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 4,
    "deletedOrders": 1,
    "requiredItems": "[7B500D6B,049BCC88,7B500D6B,BD2DB5FE,DB07BD66,7B415249,099FDC48,CDA15976,F55885BD,9DD6903A,CDA15976,DC16E9E8,DC3EC10A,42A1729B]".toArray(),
  },
  {
    "id": "PR-030",
    "name": "Gallbladder removal",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[CD9A49D0,867038F2,4AE85F20,EB58F890,CDA15976,E0A5E11A,C2F62DAA,864437DD,DB301E71,E0A5E11A,DC16E9E8,9DD6903A,6C1E6A6E,CDA15976,8607492C]".toArray(),
  },
  {
    "id": "PR-031",
    "name": "Hip arthroscopy",
    "status": "FALSE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": "The Royal National Orthopaedic Hospital",
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 0,
    "requiredItems": "[DF6676C9,CDA15976,6E657E21,965102D9,7B415249,049BCC88,05BD5A7D,E02AF8C4,CCD70D1D,2770DFA7,5F3AFC23,3C0BE0E7,93D87233,9DD6903A,958C4CED]".toArray(),
  },
  {
    "id": "PR-032",
    "name": "Hysterectomy",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 0,
    "requiredItems": "[9DD6903A,87E6E3E8,4AE85F20,6E657E21,5F3AFC23,6C1E6A6E,66AB5D2E,79FC1E21,85ECBAF5,9202537,F55885BD,5F3AFC23,965102D9,7A8CC6BD,958C4CED,3CA906F9,F55885BD]".toArray(),
  },
  {
    "id": "PR-033",
    "name": "Mastectomy",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 1,
    "requiredItems": "[79A6C1CA,64B9B7F1,F413914D,DF6676C9,065DB72B,85ECBAF5,4E1CFAD9,B688BAF3,42A1729B,542BBC0E,330FF32E,14968630,87E6E3E8,F55885BD,7D206E43,E80AAE4A,19A12B20]".toArray(),
  },
  {
    "id": "PR-034",
    "name": "Brain tumor",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 0,
    "requiredItems": "[87E6E3E8,B21A9DE2,9FC8D91A,BB20277D,C71B9801,1EBC5378,9C01A6AA,C2F62DAA,542BBC0E,74B98557,2C1769CF,271D2E55,DB07BD66,C36BF119,067037B3,58D36D79,C71B9801]".toArray(),
  },
  {
    "id": "PR-035",
    "name": "Carotid endarterectomy",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 0,
    "requiredItems": "[D9345860,58D36D79,9C01A6AA,BD23BA16,C71B9801,7A8CC6BD,965102D9,3D26994F,B688BAF3,DB301E71,BAF9CD1A,4AE85F20,7B415249,1EBC5378,7B415249,79FC1E21,05BD5A7D]".toArray(),
  },
  {
    "id": "PR-036",
    "name": "Cleft lip and palate",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 1,
    "requiredItems": "[067037B3,958C4CED,9C01A6AA,E491B04D,599EA2B3,9C01A6AA,66AB5D2E,065DB72B,5EDCDAE3,E0A95BBC,C2F62DAA,8164C892,542BBC0E,42A1729B,E80AAE4A,3C0BE0E7]".toArray(),
  },
  {
    "id": "PR-037",
    "name": "Cochlear implant",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 0,
    "requiredItems": "[BB20277D,DC16E9E8,DC3EC10A,D33377F5,79FC1E21,79A6C1CA,D9345860,3C0BE0E7,1EBC5378,22633EF7,065DB72B,9FC8D91A,F36679BC,05BD5A7D,9FC8D91A,7A8CC6BD,66AB5D2E,11660B2E]".toArray(),
  },
  {
    "id": "PR-038",
    "name": "Congenital heart",
    "status": "FALSE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": "Great Ormond Street Hospital",
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 1,
    "requiredItems": "[E491B04D,9202537,87E6E3E8,D8F6DF54,0AB87239,CCD70D1D,6E657E21,ACF7D73A,4CD72DAE,D8F6DF54,79A6C1CA,FB70040E,CB6FF509,87E6E3E8,1EBC5378,3D26994F,965102D9,22633EF7,B21A9DE2]".toArray(),
  },
  {
    "id": "PR-039",
    "name": "Dental implant",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 2,
    "deletedOrders": 0,
    "requiredItems": "[BAF9CD1A,4E1CFAD9,64B9B7F1,8164C892,CB6FF509,2C1769CF,9DD6903A,965102D9,3CA906F9,E02AF8C4,66AB5D2E,11660B2E,02A8E856,22633EF7,88F245C1,D8F6DF54]".toArray(),
  },
  {
    "id": "PR-040",
    "name": "Esophageal",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 4,
    "deletedOrders": 1,
    "requiredItems": "[87E6E3E8,D8F6DF54,4E1CFAD9,DC16E9E8,BB20277D,E02AF8C4,22633EF7,A87CEAA3,E02AF8C4,1EBC5378,DC16E9E8,9202537,542BBC0E,6C1E6A6E]".toArray(),
  },
  {
    "id": "PR-041",
    "name": "Hand surgery",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": "The Royal National Orthopaedic Hospital",
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 1,
    "requiredItems": "[C71B9801,19A12B20,F1C1E746,4E1CFAD9,6C1E6A6E,9C01A6AA,4E1CFAD9,E02AF8C4,42A1729B,1EBC5378,048701CE,5F3AFC23,E0A95BBC,05BD5A7D,CB6FF509,200EA71C]".toArray(),
  },
  {
    "id": "PR-042",
    "name": "Head and neck",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 1,
    "requiredItems": "[9DD6903A,7B415249,79A6C1CA,049BCC88,0D70FFB7,66AB5D2E,864437DD,CD9A49D0,F413914D,5EDCDAE3,067037B3,EB58F890,867038F2,8164C892,BD23BA16,E02AF8C4]".toArray(),
  },
  {
    "id": "PR-043",
    "name": "Kidney transplant",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": "Addenbrooke's Hospital",
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 0,
    "requiredItems": "[7257F77C,065DB72B,ACF7D73A,7257F77C,CCD70D1D,CCD70D1D,F2EF4025,9202537,0D70FFB7,DB07BD66,958C4CED,F55885BD,E80AAE4A,85ECBAF5,4CD72DAE]".toArray(),
  },
  {
    "id": "PR-044",
    "name": "Liver resection",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 0,
    "requiredItems": "[42A1729B,CB6FF509,330FF32E,DB07BD66,4CD72DAE,5EDCDAE3,1EBC5378,DC16E9E8,049BCC88,CB6FF509,93D87233]".toArray(),
  },
  {
    "id": "PR-045",
    "name": "Emergency room surgery for trauma",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Emergency room",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 0,
    "requiredItems": "[05BD5A7D,048701CE,DB301E71,88F245C1,0D70FFB7,271D2E55,DC16E9E8,FB70040E,F04529EA,22633EF7,9202537,3C0BE0E7,85ECBAF5,42A1729B,DC16E9E8]".toArray(),
  },
  {
    "id": "PR-046",
    "name": "Intensive care unit (ICU) surgery for severe sepsis",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Intensive care unit",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[19A12B20,11660B2E,049BCC88,79FC1E21,867038F2,5EDCDAE3,02A8E856,ACF7D73A,9DD6903A,87E6E3E8,CB6FF509,7B415249,9C01A6AA,74B98557,BAF9CD1A,E02AF8C4,74B98557,9FC8D91A,FB70040E]".toArray(),
  },
  {
    "id": "PR-047",
    "name": "Delivery room surgery for cesarean section",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Delivery room",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 0,
    "requiredItems": "[02A8E856,0ED43D83,0D70FFB7,067037B3,4AE85F20,F2EF4025,0ED43D83,065DB72B,F55885BD,D8F6DF54,44804BD4,DC3EC10A,02A8E856,64B9B7F1,41097027,14968630,BD23BA16]".toArray(),
  },
  {
    "id": "PR-048",
    "name": "Laboratory surgery for bone marrow biopsy",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Laboratory",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 4,
    "deletedOrders": 0,
    "requiredItems": "[0ED43D83,D8F6DF54,4CD72DAE,9EAD0C19,0D70FFB7,A87CEAA3,F2EF4025,19A12B20,58D36D79,C71B9801,CB6FF509,F04529EA,4AE85F20,7B500D6B,F2EF4025,864437DD,7A8CC6BD,9C01A6AA]".toArray(),
  },
  {
    "id": "PR-049",
    "name": "Intensive care unit (ICU) surgery for heart attack",
    "status": "FALSE",
    "surgeons": 15,
    "items": 20,
    "category": "Intensive care unit",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 0,
    "requiredItems": "[BAF9CD1A,958C4CED,88F245C1,4FF22342,2770DFA7,4AE85F20,0D70FFB7,44804BD4,E02AF8C4,DC16E9E8,271D2E55,C71B9801,099FDC48]".toArray(),
  },
  {
    "id": "PR-050",
    "name": "Delivery room surgery for vaginal breech delivery",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Delivery room",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 1,
    "requiredItems": "[E02AF8C4,ACF7D73A,42A1729B,5EDCDAE3,CCD70D1D,F2EF4025,C2F62DAA,200EA71C,14968630,F04529EA,88F245C1,FFE4F1A9,3D26994F,6E657E21,8607492C,7A8CC6BD,867038F2]".toArray(),
  },
  {
    "id": "PR-051",
    "name": "Laboratory surgery for genetic testing",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Laboratory",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 2,
    "deletedOrders": 0,
    "requiredItems": "[79FC1E21,BD2DB5FE,B688BAF3,067037B3,DB301E71,9EAD0C19,C71B9801,BD2DB5FE,42A1729B,CB6FF509,7D206E43,8164C892,11660B2E,4E1CFAD9,9FC8D91A,4AE85F20,7D206E43]".toArray(),
  },
  {
    "id": "PR-052",
    "name": "Emergency room surgery for burns",
    "status": "TRUE",
    "surgeons": 15,
    "items": 20,
    "category": "Emergency room",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[05BD5A7D,5EDCDAE3,85ECBAF5,4E1CFAD9,F2EF4025,065DB72B,EB58F890,5F3AFC23,048701CE,542BBC0E,599EA2B3,B21A9DE2,CD9A49D0,BB20277D]".toArray(),
  },
  {
    "id": "PR-053",
    "name": "Intensive care unit (ICU) surgery for stroke",
    "status": "FALSE",
    "surgeons": 15,
    "items": 20,
    "category": "Intensive care unit",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[200EA71C,065DB72B,C71B9801,7A8CC6BD,9FC8D91A,4AE85F20,065DB72B,ACF7D73A,E02AF8C4,11660B2E,049BCC88,3C0BE0E7,B21A9DE2,6C1E6A6E,C71B9801,330FF32E]".toArray(),
  },
  {
    "id": "PR-054",
    "name": "Delivery room surgery for twins",
    "status": "FALSE",
    "surgeons": 12,
    "items": 15,
    "category": "Delivery room",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 4,
    "deletedOrders": 1,
    "requiredItems": "[330FF32E,88F245C1,5F3AFC23,958C4CED,FFE4F1A9,9C01A6AA,41097027,41097027,E02AF8C4,F04529EA,7257F77C,FB70040E,DB301E71,0AB87239,19A12B20]".toArray(),
  },
  {
    "id": "PR-055",
    "name": "Laboratory surgery for blood transfusion",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Laboratory",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 4,
    "deletedOrders": 0,
    "requiredItems": "[E0A95BBC,867038F2,CB6FF509,867038F2,330FF32E,6C1E6A6E,64B9B7F1,41097027,F1C1E746,A87CEAA3,0AB87239,14968630,CB6FF509,CCD70D1D,3D26994F]".toArray(),
  },
  {
    "id": "PR-056",
    "name": "Cardiac angiography",
    "status": "FALSE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": getRandomElement(locations),
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 1,
    "requiredItems": "[79FC1E21,958C4CED,4AE85F20,0AB87239,C71B9801,049BCC88,965102D9,FFE4F1A9,79A6C1CA,DB07BD66,1EBC5378,2770DFA7,D9345860,3C0BE0E7]".toArray(),
  },
  {
    "id": "PR-057",
    "name": "Cardiac catheter ablation",
    "status": "FALSE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 1,
    "requiredItems": "[330FF32E,DF6676C9,F36679BC,BAF9CD1A,CD9A49D0,2770DFA7,200EA71C,B21A9DE2,66AB5D2E,DC3EC10A,14968630,7B415249,F2EF4025,6E657E21,3C0BE0E7]".toArray(),
  },
  {
    "id": "PR-058",
    "name": "Coronary angioplasty",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 0,
    "deletedOrders": 0,
    "requiredItems": "[3D26994F,58D36D79,E80AAE4A,4FF22342,599EA2B3,7B415249,05BD5A7D,F04529EA,271D2E55,BB20277D,DF6676C9,7A8CC6BD,7257F77C,E0A95BBC,E0A95BBC,542BBC0E,3CA906F9,867038F2]".toArray(),
  },
  {
    "id": "PR-059",
    "name": "Deep brain stimulation (DBS)",
    "status": "FALSE",
    "surgeons": 15,
    "items": 20,
    "category": "Surgeries",
    "cost": "15,000",
    "location": getRandomElement(locations),
    "performedAmount": 150,
    "availablePreferences": 7,
    "acceptedOrders": 140,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 2,
    "deletedOrders": 0,
    "requiredItems": "[88F245C1,271D2E55,5EDCDAE3,5EDCDAE3,1EBC5378,F55885BD,8607492C,7B415249,CB6FF509,85ECBAF5,05BD5A7D,0AB87239,F04529EA,B688BAF3]".toArray(),
  },
  {
    "id": "PR-060",
    "name": "Endoscopic retrograde cholangiopancreatography (ERCP)",
    "status": "FALSE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 3,
    "deletedOrders": 0,
    "requiredItems": "[FFE4F1A9,8164C892,D8F6DF54,7D206E43,FFE4F1A9,E02AF8C4,02A8E856,4CD72DAE,F55885BD,2C1769CF,7B415249,FB70040E,A87CEAA3]".toArray(),
  },
  {
    "id": "PR-061",
    "name": "Gastroscopy",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 1,
    "deletedOrders": 0,
    "requiredItems": "[79A6C1CA,2770DFA7,7257F77C,864437DD,F36679BC,BB20277D,9C01A6AA,9202537,79FC1E21,79FC1E21,CCD70D1D,DB301E71,F04529EA,067037B3,7B415249,05BD5A7D]".toArray(),
  },
  {
    "id": "PR-062",
    "name": "Hip arthroscopy",
    "status": "TRUE",
    "surgeons": 10,
    "items": 12,
    "category": "Surgeries",
    "cost": "1,000",
    "location": "The Royal National Orthopaedic Hospital",
    "performedAmount": 100,
    "availablePreferences": 5,
    "acceptedOrders": 90,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 5,
    "deletedOrders": 1,
    "requiredItems": "[14968630,FFE4F1A9,DC16E9E8,FFE4F1A9,0AB87239,542BBC0E,6C1E6A6E,F413914D,B688BAF3,867038F2,87E6E3E8,6C1E6A6E,864437DD,BD2DB5FE]".toArray(),
  },
  {
    "id": "PR-063",
    "name": "Hysteroscopy",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 1,
    "deletedOrders": 1,
    "requiredItems": "[64B9B7F1,FB70040E,3CA906F9,64B9B7F1,E02AF8C4,88F245C1,C71B9801,3D26994F,ACF7D73A,7B415249,330FF32E,965102D9,0D70FFB7,B688BAF3]".toArray(),
  },
  {
    "id": "PR-064",
    "name": "Kidney stone removal surgery",
    "status": "TRUE",
    "surgeons": 12,
    "items": 15,
    "category": "Surgeries",
    "cost": "1,200",
    "location": getRandomElement(locations),
    "performedAmount": 120,
    "availablePreferences": 6,
    "acceptedOrders": 110,
    "rejectedOrders": 5,
    "holdOrders": 5,
    "backorderedOrders": 1,
    "deletedOrders": 1,
    "requiredItems": "[A87CEAA3,8164C892,7D206E43,14968630,F2EF4025,4AE85F20,2C1769CF,0AB87239,E0A5E11A,DC3EC10A,7A8CC6BD,2770DFA7,065DB72B,9DD6903A,D8F6DF54,14968630]".toArray(),
  },
  {
    "id": "PR-065",
    "name": "Liver biopsy",
    "status": "FALSE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 0,
    "deletedOrders": 0,
    "requiredItems": "[93D87233,D8F6DF54,B21A9DE2,F1C1E746,200EA71C,1EBC5378,FB70040E,864437DD,CDA15976,ACF7D73A,58D36D79,3D26994F,065DB72B,44804BD4,599EA2B3,66AB5D2E,048701CE,099FDC48,4CD72DAE,3C0BE0E7]".toArray(),
  },
  {
    "id": "PR-066",
    "name": "Lung biopsy",
    "status": "TRUE",
    "surgeons": 8,
    "items": 10,
    "category": "Surgeries",
    "cost": "800",
    "location": getRandomElement(locations),
    "performedAmount": 80,
    "availablePreferences": 4,
    "acceptedOrders": 75,
    "rejectedOrders": 3,
    "holdOrders": 2,
    "backorderedOrders": 0,
    "deletedOrders": 1,
    "requiredItems": "[C2F62DAA,E0A5E11A,049BCC88,E491B04D,4AE85F20,1EBC5378,93D87233,1EBC5378,9DD6903A,5F3AFC23,88F245C1,F413914D,9C01A6AA,E02AF8C4,CDA15976,2C1769CF,64B9B7F1,2770DFA7,3D26994F,5EDCDAE3]".toArray(),
  }
]