export interface Preference {
    id: string
    preferenceID: string
    status: string // "TRUE" or "FALSE"
    surgeon: string // Surgeon name (mock data)
    remove: string // Items that are removed from the procedure's original items
    add: string // Items that are added to the procedure's original items
    cost: string
}

export const mockPreferences: Preference[] = [
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-000",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[7B415249,11102F48,F1C1E748]",
    "add": "[F36679BC,CD9A49D7,F1C1E754]",
    "cost": "11,833"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-001",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[F04529EA,8607492C]",
    "add": "[049BCC94,200EA71C]",
    "cost": "3,761"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-002",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[D33377F5,17B2D2B2]",
    "add": "[88F245C7,A0E7F6C8,542BBC0E]",
    "cost": "2,743"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-003",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[88F245C1,BAF9CD1A,17B2D2B2]",
    "add": "[14968630,D8F6DF61,7A8CC6BD]",
    "cost": "10,511"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-004",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[E0A95BBC,17B2D2B2]",
    "add": "[3CA906F15,17B2D2B7,DF6676C17]",
    "cost": "1,777"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-005",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[7B415249,065DB72B]",
    "add": "[4FF22348,D8F6DF61,5F3AFC31]",
    "cost": "11,126"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-006",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[E80AAE4A,22633EF8,C36BF121]",
    "add": "[F2EF4031,CD9A49D7,DB301E79]",
    "cost": "11,833"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-007",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[7A8CC6BD,74B98558,2C1769CF]",
    "add": "[85ECBAF11,EB58F897,F1C1E754]",
    "cost": "1,646"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-008",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[4E1CFAD9,7B415250,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-009",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[049BCC88,44804BD5,C36BF121]",
    "add": "[D33377F11,11660B2E]",
    "cost": "2,353"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-010",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[200EA71C,BAF9CD1A,D33377F9]",
    "add": "[4CD72DAE,E0A5E11A,02A8E864]",
    "cost": "11,988"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-011",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[049BCC88,B688BAF4,F55885BD]",
    "add": "[A0E7F6C7,C2F62DAA]",
    "cost": "1,646"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-012",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[4CD72DAE,22633EF8,CB6FF511,ACF7D73A]",
    "add": "[4FF22348,11660B2E,02A8E864]",
    "cost": "5,251"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-013",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[7A8CC6BD,7B415250,74B98559]",
    "add": "[3CA906F15]",
    "cost": "3,291"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-014",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[542BBC0E,14968630,CDA15978]",
    "add": "[200EA71C,7670C5F12]",
    "cost": "8,755"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-015",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[4E1CFAD9,049BCC89,E491B04D,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,B688BAF10,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "4,866"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-016",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[DC16E9E8,E80AAE4A,CB6FF511]",
    "add": "[9DD6903A,6E657E29]",
    "cost": "5,270"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-017",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[DF6676C9,4E1CFAD10,C36BF122]",
    "add": "[049BCC94,85ECBAF12,F1C1E754]",
    "cost": "4,364"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-018",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[200EA71C,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,3D26994F,CCD70D1D,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-019",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[E491B04D,A0E7F6C2,8607492C]",
    "add": "[85ECBAF11,58D36D86,05BD5A7D]",
    "cost": "3,356"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-020",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[542BBC0E,9DD6903A,CCD70D1D]",
    "add": "[4FF22348,E02AF8C11,0ED43D91,74B98566]",
    "cost": "9,466"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-021",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[19A12B20,9EAD0C20,3D26994F]",
    "add": "[D33377F11,CCD70D1D,B688BAF11]",
    "cost": "11,656"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-022",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[44804BD4,9EAD0C20,F55885BD,4E1CFAD13]",
    "add": "[3D26994F,E02AF8C11,11102F55,A0E7F6C10]",
    "cost": "9,954"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-023",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[C36BF119,DB301E72,93D87235]",
    "add": "[D33377F11,E0A95BBC,F55885BD]",
    "cost": "8,755"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-024",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[E0A95BBC,F55885BD,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[542BBC0E,C71B9808,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-025",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[C36BF119,02A8E857,ACF7D73A]",
    "add": "[542BBC0E]",
    "cost": "6,784"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-026",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A]",
    "add": "[F36679BC,B688BAF10,11102F55,A0E7F6C10,E0A95BBC]",
    "cost": "1,783"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-027",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[4E1CFAD9,F55885BD,8607492C,7A8CC6BD,DB301E75,ACF7D73A]",
    "add": "[F36679BC,E02AF8C11,66AB5D2E,200EA71C]",
    "cost": "1,783"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-028",
    "status": "TRUE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[7A8CC6BD,3D26994F,DF6676C11,7B415254]",
    "add": "[DF6676C15,E02AF8C11,E0A95BBC,CB6FF519]",
    "cost": "7,708"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-029",
    "status": "TRUE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[ACF7D73A,1EBC5379,93D87235]",
    "add": "[14968630,17B2D2B7]",
    "cost": "5,251"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-030",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[4E1CFAD9,C36BF120,E491B04D,ACF7D73A,DB301E75]",
    "add": "[4FF22348,A0E7F6C8,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-031",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[44804BD4,9EAD0C20,7B500D6B,099FDC51]",
    "add": "[02A8E862,8164C899,7B500D6B]",
    "cost": "8,781"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-032",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-033",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[5EDCDAE3,7B415250,BD2DB5FE,ACF7D73A]",
    "add": "[4CD72DAE,CCD70D1D,02A8E864,C36BF130]",
    "cost": "3,761"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-034",
    "status": "TRUE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[9DD6903A,E80AAE4A,11102F49]",
    "add": "[D33377F11,CDA15983,7A8CC6BD,C36BF130]",
    "cost": "8,989"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-035",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[7B415249,74B98558,E80AAE4A]",
    "add": "[14968630,19A12B27,C36BF127,4E1CFAD18,E0A95BBC]",
    "cost": "5,343"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-036",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[4E1CFAD9,9FC8D91A,79A6C1CA]",
    "add": "[6E657E27,DF6676C16,7B415257]",
    "cost": "5,299"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-037",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[049BCC88,049BCC89,F04529EA,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,DF6676C16,CCD70D1D,200EA71C]",
    "cost": "10,752"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-038",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,4FF22345,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,7A8CC6BD,A0E7F6C10,E0A95BBC,C36BF130]",
    "cost": "4,803"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-039",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[CCD70D1D,F55885BD,74B98559]",
    "add": "[F1C1E752,D8F6DF61,CCD70D1D]",
    "cost": "1,783"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-040",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[200EA71C,A0E7F6C2,E491B04D]",
    "add": "[A0E7F6C7,DC16E9E15,7A8CC6BD,A0E7F6C10]",
    "cost": "11,563"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-041",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[E491B04D,58D36D80,099FDC51]",
    "add": "[F413914D,200EA71C,E0A95BBC]",
    "cost": "5,727"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-042",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[BAF9CD1A,F55885BD,F2EF4027,D9345863]",
    "add": "[9DD6903A,065DB72B,58D36D87,E0A95BBC]",
    "cost": "8,755"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-043",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[88F245C1,7B415250,E491B04D]",
    "add": "[A0E7F6C7,EB58F897]",
    "cost": "1,169"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-044",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[048701CE,85ECBAF6]",
    "add": "[3CA906F15,DC16E9E15]",
    "cost": "5,251"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-045",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[7B415249,42A1729B,E491B04D]",
    "add": "[D8F6DF60,DB301E78,7A8CC6BD]",
    "cost": "11,126"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-046",
    "status": "TRUE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[D33377F5,02A8E857]",
    "add": "[7B500D6B,CD9A49D7]",
    "cost": "4,364"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-047",
    "status": "TRUE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[58D36D79,E80AAE4A,8607492C]",
    "add": "[02A8E862,17B2D2B7,44804BD12]",
    "cost": "9,661"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-048",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[4E1CFAD9,049BCC89,F2EF4027,DB301E75,ACF7D73A]",
    "add": "[D8F6DF60,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "9,466"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-049",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[5EDCDAE3,BD2DB5FE,05BD5A7D]",
    "add": "[A0E7F6C7,CCD70D1D,F1C1E754]",
    "cost": "8,755"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-050",
    "status": "TRUE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[C36BF119,E0A5E11A,8607492C]",
    "add": "[4FF22348,D8F6DF61]",
    "cost": "11,988"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-051",
    "status": "TRUE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[D33377F5,DB07BD67,C36BF121]",
    "add": "[200EA71C,3D26994F,7B500D6B,A0E7F6C10]",
    "cost": "9,661"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-052",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[049BCC88,42A1729B,F04529EA]",
    "add": "[E0A5E11A,DB301E78,02A8E864,542BBC0E]",
    "cost": "11,833"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-053",
    "status": "FALSE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[9FC8D91A,74B98558,74B98559]",
    "add": "[19A12B26,D33377F12,C2F62DAA]",
    "cost": "3,370"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-054",
    "status": "TRUE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[F04529EA,DB07BD67,D8F6DF56]",
    "add": "[3D26994F,330FF32E,DF6676C17]",
    "cost": "7,708"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-055",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[BAF9CD1A,41097027]",
    "add": "[88F245C7,0ED43D90,6E657E29]",
    "cost": "3,862"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-056",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[58D36D79,E80AAE4A,ACF7D73A]",
    "add": "[17B2D2B6,DC3EC10A,E80AAE4A]",
    "cost": "8,400"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-057",
    "status": "TRUE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[B21A9DE2,E80AAE4A,E491B04D]",
    "add": "[D9345866,C71B9808,0ED43D91]",
    "cost": "6,563"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-058",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[542BBC0E,7B415250,17B2D2B2]",
    "add": "[542BBC0E,02A8E863,542BBC0E]",
    "cost": "2,743"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-059",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[049BCC88,42A1729B,7A8CC6BD]",
    "add": "[02A8E862,3D26994F,17B2D2B8]",
    "cost": "5,727"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-060",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[B21A9DE2,E80AAE4A]",
    "add": "[85ECBAF11,17B2D2B7,8164C900]",
    "cost": "9,661"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-061",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[F04529EA,E80AAE4A,E491B04D]",
    "add": "[58D36D85,D33377F12,66AB5D2E]",
    "cost": "9,954"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-062",
    "status": "TRUE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[9FC8D91A,E80AAE4A,BD23BA18]",
    "add": "[6E657E27,CD9A49D7,F55885BD]",
    "cost": "7,182"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-063",
    "status": "TRUE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[DC16E9E8,DB07BD67]",
    "add": "[DB301E77,EB58F897,7A8CC6BD]",
    "cost": "11,599"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-064",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[049BCC88,E80AAE4A,8607492C]",
    "add": "[F2EF4031,11660B2E,DF6676C17]",
    "cost": "7,182"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-065",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[E491B04D,DC16E9E9]",
    "add": "[ACF7D73A,C2F62DAA]",
    "cost": "1,289"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-066",
    "status": "TRUE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[200EA71C,E80AAE4A,B688BAF5,ACF7D73A]",
    "add": "[58D36D85,CD9A49D7,66AB5D2E,E0A95BBC]",
    "cost": "1,783"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-067",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[7A8CC6BD,9FC8D91A,DF6676C11,7A8CC6BD]",
    "add": "[A0E7F6C7,93D87240,CCD70D1D,A0E7F6C10,542BBC0E]",
    "cost": "8,781"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-068",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[B21A9DE2,44804BD5,DB07BD68]",
    "add": "[4CD72DAE,8164C899,5F3AFC31]",
    "cost": "8,646"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-069",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[B21A9DE2,E0A5E11A,CDA15978]",
    "add": "[9C01A6AA,CDA15983,3CA906F17]",
    "cost": "11,430"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-070",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[7B415249,44804BD5,7A8CC6BD]",
    "add": "[DB301E77,5F3AFC30,7B415257]",
    "cost": "5,259"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-071",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[200EA71C,F55885BD,E491B04D]",
    "add": "[049BCC94,DC16E9E15,DB301E79]",
    "cost": "7,708"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-072",
    "status": "FALSE",
    "surgeon": "Dr. David Cohen",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-073",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[4FF22342,17B2D2B2]",
    "add": "[ACF7D73A,8164C899,DB301E79]",
    "cost": "2,744"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-074",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[E0A95BBC,D9345861,8607492C]",
    "add": "[02A8E862,44804BD11,A0E7F6C9,F2EF4035]",
    "cost": "5,251"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-075",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[4E1CFAD9,A0E7F6C2,F2EF4027,DB301E75]",
    "add": "[F36679BC,74B98564,CCD70D1D,A0E7F6C10,C36BF130]",
    "cost": "5,251"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-076",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[C36BF119,02A8E857]",
    "add": "[2C1769CF,11102F54]",
    "cost": "3,407"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-077",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[7D206E43,DB07BD67,542BBC0E]",
    "add": "[4CD72DAE,E0A95BBC]",
    "cost": "11,563"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-078",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[200EA71C,A0E7F6C2,E491B04D]",
    "add": "[BD2DB5FE,B688BAF10,02A8E864]",
    "cost": "11,599"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-079",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[19A12B20,8164C893,2C1769CF]",
    "add": "[F55885BD,58D36D86,CCD70D1D]",
    "cost": "11,656"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-080",
    "status": "FALSE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[4E1CFAD9,F55885BD,44804BD6,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,74B98565,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-081",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[200EA71C,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[F1C1E752,11102F54,F04529EA,7A8CC6BD,542BBC0E,85ECBAF16]",
    "cost": "1,783"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-082",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[9FC8D91A,542BBC0E]",
    "add": "[7B500D6B,DC16E9E15]",
    "cost": "8,781"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-083",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[4FF22342,D8F6DF55,B688BAF5]",
    "add": "[14968630,17B2D2B7,099FDC56]",
    "cost": "3,862"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-084",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[44804BD4,C36BF121]",
    "add": "[F36679BC,17B2D2B7,C36BF127]",
    "cost": "11,988"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-085",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[E491B04D,44804BD5,F2EF4027]",
    "add": "[F36679BC,11102F54,7B415257,BD2DB5FE]",
    "cost": "7,567"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-086",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[E0A95BBC,F55885BD,E491B04D]",
    "add": "[E0A5E11A,D8F6DF61,099FDC56]",
    "cost": "3,407"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-087",
    "status": "TRUE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[44804BD4,330FF32E,9DD6903A]",
    "add": "[9DD6903A,44804BD11]",
    "cost": "11,833"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-088",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[7A8CC6BD,BD2DB5FE,DB07BD68]",
    "add": "[F1C1E752,D8F6DF61,5F3AFC31]",
    "cost": "8,781"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-089",
    "status": "TRUE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[DC16E9E8,41097027,DB07BD68]",
    "add": "[4E1CFAD15,D8F6DF61,42A1729B]",
    "cost": "5,088"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-090",
    "status": "TRUE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[E0A95BBC,E0A5E11A,7A8CC6BD]",
    "add": "[F36679BC,D8F6DF61,1EBC5386]",
    "cost": "3,441"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-091",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[049BCC88,7B415250,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[E491B04D,E02AF8C11,CCD70D1D,F2EF4035,C36BF130]",
    "cost": "2,933"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-092",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[4E1CFAD9]",
    "add": "[049BCC94]",
    "cost": "6,784"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-093",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[C36BF119,02A8E857,17B2D2B2,ACF7D73A,DB301E75]",
    "add": "[4FF22348,EB58F897,CCD70D1D,4E1CFAD18,F2EF4035]",
    "cost": "9,954"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-094",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[DC16E9E8,A0E7F6C2]",
    "add": "[F1C1E752,93D87240,542BBC0E]",
    "cost": "5,708"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-095",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[E0A95BBC,F55885BD,5EDCDAE5,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[BB20277D,11102F54,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-096",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[9FC8D91A,A0E7F6C2,ACF7D73A]",
    "add": "[9DD6903A,E0A5E11A,42A1729B]",
    "cost": "6,563"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-097",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[7B415249,42A1729B,CDA15978]",
    "add": "[ACF7D73A,E02AF8C11]",
    "cost": "11,752"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-098",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[7B415249,42A1729B,8607492C]",
    "add": "[A0E7F6C7,5F3AFC30]",
    "cost": "11,752"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-099",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[0ED43D83,F55885BD,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,A0E7F6C10,C36BF130]",
    "cost": "8,989"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-100",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[E80AAE4A,DC16E9E9]",
    "add": "[9C01A6AA]",
    "cost": "11,126"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-101",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[4E1CFAD9,F55885BD,14968630,ACF7D73A,DC16E9E12]",
    "add": "[F36679BC,5EDCDAE10,CCD70D1D,A0E7F6C10]",
    "cost": "1,783"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-102",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[4E1CFAD9,F55885BD,CDA15978,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,C2F62DAA,A0E7F6C10,542BBC0E,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-103",
    "status": "TRUE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[E80AAE4A,85ECBAF6,D8F6DF56]",
    "add": "[200EA71C,3D26994F,EB58F898,F36679BC]",
    "cost": "10,043"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-104",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[E491B04D,BAF9CD1A,BD2DB5FE]",
    "add": "[E0A5E11A,CDA15983,3CA906F17]",
    "cost": "8,755"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-105",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[88F245C1,DB301E72,7B500D6B]",
    "add": "[ACF7D73A,D8F6DF61,F1C1E754]",
    "cost": "5,727"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-106",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[4CD72DAE,0ED43D84,11660B2E]",
    "add": "[F1C1E752,93D87240,E0A95BBC]",
    "cost": "1,289"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-107",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[D33377F5,85ECBAF6,3D26994F,4E1CFAD13]",
    "add": "[E0A5E11A,048701CE,0ED43D91,A0E7F6C10]",
    "cost": "6,563"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-108",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[D8F6DF54,330FF32E]",
    "add": "[D33377F11,93D87240]",
    "cost": "8,755"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-109",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[58D36D79,F36679BC]",
    "add": "[2C1769CF,8164C899,CB6FF519]",
    "cost": "3,356"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-110",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[7B415249,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]",
    "add": "[4FF22348,E0A95BBC,CCD70D1D,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-111",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[44804BD4,7B415250,3D26994F]",
    "add": "[E0A5E11A,065DB72B,F55885BD,200EA71C]",
    "cost": "7,708"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-112",
    "status": "TRUE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[D33377F5,CB6FF511]",
    "add": "[17B2D2B6,0ED43D91]",
    "cost": "11,988"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-113",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[4CD72DAE,0ED43D84,E491B04D]",
    "add": "[F1C1E752,DC16E9E15,BAF9CD1A]",
    "cost": "10,043"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-114",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[14968630,A0E7F6C2]",
    "add": "[ACF7D73A,DB301E78]",
    "cost": "3,370"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-115",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[E80AAE4A,88F245C2,14968630]",
    "add": "[049BCC94,065DB72B]",
    "cost": "11,126"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-116",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[DF6676C9,E80AAE4A]",
    "add": "[049BCC94,85ECBAF12,7B415257]",
    "cost": "5,259"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-117",
    "status": "TRUE",
    "surgeon": "Dr. John Smith",
    "remove": "[200EA71C,F55885BD,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,B688BAF11,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "8,565"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-118",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[F2EF4025,330FF32E,8607492C]",
    "add": "[2C1769CF,D33377F12,E0A95BBC]",
    "cost": "4,866"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-119",
    "status": "TRUE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[048701CE,79A6C1CA]",
    "add": "[9DD6903A,CCD70D1D,F1C1E754]",
    "cost": "6,784"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-120",
    "status": "TRUE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[44804BD4,14968630]",
    "add": "[7B500D6B,200EA71C,A0E7F6C9]",
    "cost": "1,289"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-121",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,4E1CFAD13,ACF7D73A]",
    "add": "[A0E7F6C7,E02AF8C11,F04529EA]",
    "cost": "2,743"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-122",
    "status": "TRUE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[BAF9CD1A,9FC8D91A,44804BD6]",
    "add": "[7B500D6B,5F3AFC30,CCD70D1D,F36679BC]",
    "cost": "5,270"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-123",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[7B415249,F55885BD,F2EF4027]",
    "add": "[2C1769CF,93D87240,7A8CC6BD]",
    "cost": "7,708"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-124",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[F04529EA,11102F48,9C01A6AA]",
    "add": "[F1C1E752,1EBC5385,E0A95BBC]",
    "cost": "1,169"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-125",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[19A12B20,F55885BD,44804BD6]",
    "add": "[4FF22348,A0E7F6C8,CCD70D1D]",
    "cost": "8,781"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-126",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[E491B04D,E80AAE4A,3D26994F]",
    "add": "[4CD72DAE,11660B2E]",
    "cost": "11,988"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-127",
    "status": "FALSE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[D9345860,42A1729B,F2EF4027,DB301E75,ACF7D73A]",
    "add": "[F36679BC,E02AF8C11,CCD70D1D,A0E7F6C10]",
    "cost": "1,783"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-128",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[7B415249,F55885BD,11660B2E,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[DF6676C15,DB07BD73,F04529EA,74B98566,542BBC0E,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-129",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[11660B2E,DC16E9E9,CB6FF511]",
    "add": "[3CA906F15,B688BAF10]",
    "cost": "1,783"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-130",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[7A8CC6BD,DB07BD67,17B2D2B2]",
    "add": "[7B500D6B,F1C1E754]",
    "cost": "3,407"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-131",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[4E1CFAD9,F55885BD,DB301E73,C36BF122,ACF7D73A]",
    "add": "[F36679BC,93D87240,7670C5F13,F2EF4035]",
    "cost": "3,370"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-132",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[4FF22342,E80AAE4A,11660B2E,4FF22345]",
    "add": "[7B415255,0ED43D90,17B2D2B8,200EA71C]",
    "cost": "3,761"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-133",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[200EA71C,4E1CFAD10,11660B2E,C36BF122,DB301E75,ACF7D73A]",
    "add": "[F36679BC,CD9A49D7,CCD70D1D,4E1CFAD18,F2EF4035]",
    "cost": "1,289"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-134",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[BAF9CD1A,F36679BC,44804BD6,DB301E75,ACF7D73A]",
    "add": "[D9345866,11102F54,BAF9CD1A,A0E7F6C10,F2EF4035]",
    "cost": "8,989"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-135",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[8164C892,44804BD5,CD9A49D2]",
    "add": "[7B415255,DC16E9E15,542BBC0E]",
    "cost": "6,563"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-136",
    "status": "TRUE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[542BBC0E,599EA2B4,F36679BC,D9345863]",
    "add": "[4E1CFAD15,E80AAE4A]",
    "cost": "11,988"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-137",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[44804BD4,DB301E72,8607492C]",
    "add": "[049BCC94,E02AF8C11,E80AAE4A]",
    "cost": "5,727"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-138",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[049BCC88,065DB72B]",
    "add": "[4CD72DAE,DB301E78,7B415257,200EA71C]",
    "cost": "11,752"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-139",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[42A1729B,17B2D2B2]",
    "add": "[85ECBAF11,8164C899,F1C1E754]",
    "cost": "1,783"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-140",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[048701CE,0ED43D84,E491B04D,4FF22345]",
    "add": "[19A12B26,9DD6903A,17B2D2B8]",
    "cost": "6,784"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-141",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[542BBC0E,C36BF120,F2EF4027,ACF7D73A]",
    "add": "[049BCC94,7B415256,DF6676C17,A0E7F6C10]",
    "cost": "3,622"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-142",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[ACF7D73A,D33377F6,11660B2E]",
    "add": "[17B2D2B6,542BBC0E,F1C1E754]",
    "cost": "8,781"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-143",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[DC16E9E8,F55885BD]",
    "add": "[864437DD,74B98564,542BBC0E]",
    "cost": "11,126"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-144",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[E491B04D,A0E7F6C2,F2EF4027]",
    "add": "[D8F6DF60,EB58F897,9FC8D91A]",
    "cost": "3,291"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-145",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[F2EF4025,66AB5D2E,C36BF121]",
    "add": "[F36679BC,F36679BC,02A8E864]",
    "cost": "10,212"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-146",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[79A6C1CA,330FF32E,8164C894]",
    "add": "[A0E7F6C7,CDA15983,DF6676C17]",
    "cost": "9,661"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-147",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[F2EF4025,F36679BC,CCD70D1D,C36BF122]",
    "add": "[44804BD10,542BBC0E,B688BAF11,4E1CFAD18]",
    "cost": "8,989"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-148",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[0ED43D83,2C1769CF]",
    "add": "[ACF7D73A,DB07BD73]",
    "cost": "7,708"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-149",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[4E1CFAD9,41097027,3D26994F]",
    "add": "[F36679BC,542BBC0E,CCD70D1D,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-150",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[DF6676C9,C36BF120,BD23BA18]",
    "add": "[542BBC0E,F1C1E754]",
    "cost": "8,400"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-151",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[DC16E9E8,DB07BD67,14968630]",
    "add": "[14968630,DC16E9E15,6E657E29]",
    "cost": "5,343"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-152",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[A0E7F6C1,B688BAF4,7A8CC6BD,D9345863,4E1CFAD13]",
    "add": "[58D36D85,17B2D2B7,02A8E864]",
    "cost": "2,744"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-153",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[DC16E9E8,F55885BD,7A8CC6BD,DB301E75]",
    "add": "[F36679BC,11660B2E,C71B9809]",
    "cost": "4,364"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-154",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[9FC8D91A,F55885BD,B688BAF5,ACF7D73A,D8F6DF58,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,EB58F898,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "4,803"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-155",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[B21A9DE2,7B415250,F55885BD]",
    "add": "[74B98563,0ED43D91]",
    "cost": "5,343"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-156",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[7A8CC6BD,11102F48,CDA15978]",
    "add": "[4CD72DAE,D8F6DF61,CB6FF519]",
    "cost": "1,196"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-157",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[44804BD4,D33377F6]",
    "add": "[9DD6903A,8164C899,0ED43D91]",
    "cost": "2,933"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-158",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[58D36D79,DB07BD67,F55885BD,7A8CC6BD]",
    "add": "[4CD72DAE,44804BD12]",
    "cost": "8,129"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-159",
    "status": "TRUE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[B21A9DE2,F55885BD,F1C1E748]",
    "add": "[D8F6DF60,5EDCDAE10,A0E7F6C9]",
    "cost": "1,646"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-160",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[7B415249,A0E7F6C2,17B2D2B2,ACF7D73A]",
    "add": "[4FF22348,11102F54,6E657E29,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "8,538"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-161",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[58D36D79,7B500D6B,542BBC0E]",
    "add": "[E0A5E11A,330FF32E,8164C900]",
    "cost": "1,289"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-162",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[DF6676C9,BAF9CD1A,CD9A49D2,ACF7D73A]",
    "add": "[17B2D2B6,B688BAF10,CCD70D1D]",
    "cost": "7,351"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-163",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[D33377F5,7B500D6B,17B2D2B2,DC16E9E11]",
    "add": "[D9345866,CD9A49D7,CCD70D1D,A0E7F6C10,542BBC0E]",
    "cost": "3,761"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-164",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[44804BD4,44804BD5,542BBC0E]",
    "add": "[14968630,D33377F12,A0E7F6C9]",
    "cost": "8,781"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-165",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[85ECBAF5,CDA15977,CB6FF511]",
    "add": "[3CA906F15,74B98564]",
    "cost": "6,563"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-166",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[B688BAF3,7B500D6B]",
    "add": "[EB58F896,D8F6DF61,F55885BD]",
    "cost": "4,109"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-167",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[E491B04D,C36BF120,17B2D2B2,ACF7D73A]",
    "add": "[F413914D,065DB72B,3CA906F17]",
    "cost": "1,783"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-168",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[7A8CC6BD,E0A5E11A,F04529EA]",
    "add": "[02A8E862,C36BF127]",
    "cost": "3,441"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-169",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[7B415249,E80AAE4A,F2EF4027]",
    "add": "[3CA906F15,E02AF8C11,42A1729B]",
    "cost": "1,783"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-170",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[C36BF119,88F245C2,F2EF4027]",
    "add": "[44804BD10,E02AF8C11,F55885BD,F36679BC,CB6FF519]",
    "cost": "8,989"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-171",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[88F245C1,11102F49]",
    "add": "[049BCC94,7B415256]",
    "cost": "8,781"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-172",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[4FF22342,BAF9CD1A]",
    "add": "[ACF7D73A,C71B9808,C2F62DAA]",
    "cost": "11,988"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-173",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[DC16E9E8,8164C893,8607492C,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[DF6676C15,E02AF8C11,CCD70D1D,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-174",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[F04529EA,065DB72B,CB6FF511,ACF7D73A]",
    "add": "[66AB5D2E,E02AF8C11,F55885BD,A0E7F6C10]",
    "cost": "7,567"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-175",
    "status": "TRUE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[9DD6903A,7B500D6B,DF6676C11]",
    "add": "[E0A95BBC,74B98564]",
    "cost": "3,407"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-176",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[7A8CC6BD,4E1CFAD10,CCD70D1D,D33377F9]",
    "add": "[6E657E27,CD9A49D7,099FDC56]",
    "cost": "2,493"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-177",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[DC16E9E8,D33377F6,E491B04D]",
    "add": "[F1C1E752,330FF32E,DF6676C17]",
    "cost": "4,866"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-178",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[7A8CC6BD,42A1729B,DF6676C11]",
    "add": "[17B2D2B6,02A8E863]",
    "cost": "3,370"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-179",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[E80AAE4A,E80AAE4A,DF6676C11]",
    "add": "[14968630,11660B2E,B688BAF11]",
    "cost": "7,182"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-180",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[19A12B20,DB301E72]",
    "add": "[E491B04D,D8F6DF61,E0A95BBC]",
    "cost": "3,356"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-181",
    "status": "TRUE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[200EA71C,F55885BD,542BBC0E,DB301E75]",
    "add": "[3D26994F,A0E7F6C8,42A1729B]",
    "cost": "4,803"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-182",
    "status": "TRUE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[E491B04D,F55885BD,F04529EA,ACF7D73A,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,542BBC0E,BD2DB5FE,F2EF4035]",
    "cost": "3,356"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-183",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-184",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[049BCC88,DB301E72,CDA15978]",
    "add": "[E0A5E11A,4E1CFAD16]",
    "cost": "3,441"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-185",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[DC16E9E8,DB301E72]",
    "add": "[049BCC94,9DD6903A,CCD70D1D]",
    "cost": "11,988"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-186",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[4FF22342,D33377F6,7A8CC6BD]",
    "add": "[E0A95BBC,DC16E9E15]",
    "cost": "11,563"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-187",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[11660B2E,DB07BD67,DC16E9E11]",
    "add": "[D33377F11,DF6676C16,F1C1E754]",
    "cost": "3,291"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-188",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[7A8CC6BD,7A8CC6BD]",
    "add": "[4FF22348,0ED43D90,EB58F898,4E1CFAD18]",
    "cost": "3,178"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-189",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[DF6676C9,E80AAE4A,DB07BD68]",
    "add": "[6E657E27,74B98564,099FDC56,F2EF4035]",
    "cost": "3,178"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-190",
    "status": "TRUE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[5EDCDAE3,85ECBAF6,CB6FF511,DB301E75]",
    "add": "[19A12B26,E02AF8C11,7B500D6B,C36BF130]",
    "cost": "2,743"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-191",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[58D36D79]",
    "add": "[4CD72DAE,CD9A49D7,11660B2E]",
    "cost": "5,299"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-192",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[44804BD4,D33377F6,8607492C,5EDCDAE6]",
    "add": "[F36679BC,1EBC5385,11102F55]",
    "cost": "3,761"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-193",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[7A8CC6BD,065DB72B,79A6C1CA]",
    "add": "[D33377F11,200EA71C,42A1729B]",
    "cost": "8,646"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-194",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[E491B04D,9C01A6AA,ACF7D73A,D33377F9]",
    "add": "[ACF7D73A,7670C5F12,F04529EA]",
    "cost": "3,178"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-195",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[DF6676C9,E0A5E11A,93D87235]",
    "add": "[14968630,DF6676C16,867038F10]",
    "cost": "7,052"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-196",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[88F245C1,E80AAE4A,8607492C]",
    "add": "[E0A5E11A,200EA71C,8607492C]",
    "cost": "8,989"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-197",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[58D36D79,DB07BD67,2C1769CF]",
    "add": "[A0E7F6C7,CDA15983,E80AAE4A]",
    "cost": "5,088"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-198",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[DB07BD66,02A8E857,F1C1E748]",
    "add": "[74B98563,8164C899,7B415257]",
    "cost": "10,212"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-199",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[4E1CFAD9,B688BAF4,C36BF121]",
    "add": "[4FF22348,74B98564,EB58F898]",
    "cost": "11,752"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-200",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[4E1CFAD9,1EBC5379,11660B2E,5EDCDAE6]",
    "add": "[4FF22348,F36679BC,A0E7F6C10,E0A95BBC]",
    "cost": "1,783"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-201",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[E80AAE4A,58D36D80,CDA15978]",
    "add": "[BB20277D,5F3AFC30,DF6676C17]",
    "cost": "3,761"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-202",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[44804BD4,E0A5E11A,79A6C1CA,ACF7D73A]",
    "add": "[9202537,F36679BC]",
    "cost": "11,563"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-203",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[CCD70D1D,42A1729B]",
    "add": "[A0E7F6C7,5F3AFC30,C36BF127]",
    "cost": "4,364"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-204",
    "status": "FALSE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[200EA71C,22633EF8,BD23BA18]",
    "add": "[BD2DB5FE,85ECBAF12,200EA71C]",
    "cost": "3,407"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-205",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[19A12B20,7B500D6B,5EDCDAE5]",
    "add": "[02A8E862,CCD70D1D,42A1729B]",
    "cost": "8,646"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-206",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-207",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[049BCC88,3D26994F,3D26994F]",
    "add": "[BB20277D,44804BD11,11660B2E]",
    "cost": "11,599"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-208",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[4E1CFAD9,F55885BD,CD9A49D2,ACF7D73A]",
    "add": "[4FF22348,048701CE,0ED43D91,A0E7F6C10]",
    "cost": "1,783"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-209",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[200EA71C,F55885BD,11660B2E,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,C71B9808,0ED43D91,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "11,988"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-210",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[542BBC0E,85ECBAF6]",
    "add": "[A0E7F6C7,C71B9808]",
    "cost": "8,755"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-211",
    "status": "TRUE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[44804BD4,F2EF4027]",
    "add": "[85ECBAF11,A0E7F6C8,F55885BD]",
    "cost": "11,126"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-212",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[CCD70D1D,C36BF120,E491B04D]",
    "add": "[DF6676C15,D8F6DF61,F1C1E754]",
    "cost": "3,356"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-213",
    "status": "FALSE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[542BBC0E,1EBC5379,17B2D2B2]",
    "add": "[44804BD10,5F3AFC30,42A1729B]",
    "cost": "1,646"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-214",
    "status": "TRUE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[88F245C1,BAF9CD1A,8164C894]",
    "add": "[3D26994F,542BBC0E,58D36D87]",
    "cost": "4,364"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-215",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[19A12B20,11102F48,E491B04D]",
    "add": "[4CD72DAE,065DB72B,42A1729B]",
    "cost": "3,370"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-216",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[D33377F5,7B415250,E491B04D]",
    "add": "[4CD72DAE,5F3AFC30,DB301E79]",
    "cost": "8,538"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-217",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[E491B04D,8164C893]",
    "add": "[049BCC94,A0E7F6C8,58D36D87]",
    "cost": "3,407"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-218",
    "status": "TRUE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[7B415249,9FC8D91A,9C01A6AA]",
    "add": "[DB301E77,44804BD11]",
    "cost": "7,182"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-219",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[F2EF4025,02A8E857]",
    "add": "[049BCC94,CCD70D1D,DB301E79]",
    "cost": "8,755"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-220",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[88F245C1,DB07BD68,D9345863]",
    "add": "[14968630,EB58F897,DF6676C17]",
    "cost": "2,353"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-221",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[4E1CFAD9,F55885BD,542BBC0E,C36BF122,DB301E75]",
    "add": "[3CA906F15,DC16E9E15,CCD70D1D,BD2DB5FE,CDA15986,C36BF130]",
    "cost": "5,727"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-222",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[58D36D79,E80AAE4A]",
    "add": "[17B2D2B6,93D87240,8164C900]",
    "cost": "7,567"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-223",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[0ED43D83,C36BF120,F2EF4027]",
    "add": "[3D26994F,DB301E78,F04529EA]",
    "cost": "1,783"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-224",
    "status": "TRUE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[9DD6903A,7B415250,F2EF4027,4E1CFAD13]",
    "add": "[F36679BC,44804BD11,CCD70D1D,A0E7F6C10]",
    "cost": "3,356"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-225",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[4E1CFAD9,F36679BC,F2EF4027]",
    "add": "[E491B04D,11102F54,867038F10,A0E7F6C10]",
    "cost": "11,599"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-226",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[0AB87239,3D26994F,F2EF4027]",
    "add": "[4FF22348,B21A9DE9,0ED43D91,E0A95BBC]",
    "cost": "7,182"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-227",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[42A1729B,3D26994F,79A6C1CA]",
    "add": "[7B415255,F36679BC,0ED43D91,F2EF4035]",
    "cost": "2,493"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-228",
    "status": "TRUE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[C36BF119,E80AAE4A,D8F6DF56]",
    "add": "[BD2DB5FE,EB58F898]",
    "cost": "7,708"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-229",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[66AB5D2E,C36BF120,C36BF121,DC16E9E11]",
    "add": "[A0E7F6C7,048701CE,42A1729B,4E1CFAD18,F2EF4035,C36BF130]",
    "cost": "7,182"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-230",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[5EDCDAE3,3D26994F,CD9A49D2,7A8CC6BD]",
    "add": "[ACF7D73A,D8F6DF61]",
    "cost": "3,370"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-231",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[B21A9DE2,F55885BD,5EDCDAE6]",
    "add": "[D33377F11,DC16E9E15,05BD5A7D,4E1CFAD18]",
    "cost": "4,109"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-232",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[19A12B20,DB301E72,2C1769CF]",
    "add": "[D9345866,542BBC0E]",
    "cost": "10,752"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-233",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[DF6676C9,599EA2B4,F2EF4027,ACF7D73A,D8F6DF58,ACF7D73A]",
    "add": "[DF6676C15,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-234",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[7B415249,41097027,79A6C1CA,D9345863]",
    "add": "[74B98563,74B98564,E0A95BBC]",
    "cost": "6,563"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-235",
    "status": "FALSE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[DC16E9E8,B688BAF4,F55885BD,ACF7D73A]",
    "add": "[85ECBAF11,44804BD11,CCD70D1D,66AB5D2E,542BBC0E]",
    "cost": "1,783"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-236",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[ACF7D73A,44804BD6]",
    "add": "[9DD6903A,DC16E9E15,79FC1E29]",
    "cost": "11,988"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-237",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[D9345860,7B415250,E491B04D,DC16E9E11]",
    "add": "[E0A5E11A,EB58F897,5F3AFC31,4E1CFAD18]",
    "cost": "1,783"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-238",
    "status": "TRUE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[9FC8D91A,C36BF120,F2EF4027]",
    "add": "[ACF7D73A,CCD70D1D]",
    "cost": "5,299"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-239",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[4E1CFAD9,02A8E857]",
    "add": "[9C01A6AA,17B2D2B7,17B2D2B8]",
    "cost": "1,783"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-240",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[ACF7D73A,065DB72B,8164C894]",
    "add": "[F2EF4031,330FF32E,58D36D87]",
    "cost": "5,259"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-241",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[9DD6903A,14968630,DB07BD68]",
    "add": "[F2EF4031,58D36D86,02A8E864]",
    "cost": "8,129"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-242",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[048701CE,F36679BC,CB6FF511]",
    "add": "[6E657E27,17B2D2B7]",
    "cost": "4,364"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-243",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[42A1729B,7B415250,CB6FF511,7A8CC6BD]",
    "add": "[14968630,CDA15983,0ED43D91]",
    "cost": "9,954"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-244",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[E0A95BBC,A0E7F6C2,CDA15978]",
    "add": "[3CA906F15,02A8E864]",
    "cost": "10,752"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-245",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[BAF9CD1A,B688BAF4,542BBC0E]",
    "add": "[D33377F11,EB58F897,7670C5F13]",
    "cost": "5,343"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-246",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[CCD70D1D,E80AAE4A]",
    "add": "[F413914D,D33377F12,02A8E864]",
    "cost": "2,933"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-247",
    "status": "FALSE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[D8F6DF54,3D26994F,F1C1E748]",
    "add": "[4FF22348,F36679BC]",
    "cost": "11,430"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-248",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[44804BD4,02A8E857]",
    "add": "[4FF22348,DC16E9E15,C71B9809]",
    "cost": "7,052"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-249",
    "status": "FALSE",
    "surgeon": "Dr. David Cohen",
    "remove": "[DC16E9E8,F55885BD,CB6FF511]",
    "add": "[14968630,E0A95BBC]",
    "cost": "2,353"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-250",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[19A12B20,7B500D6B,F55885BD]",
    "add": "[049BCC94,DC16E9E15]",
    "cost": "9,954"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-251",
    "status": "TRUE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[7B415249,049BCC89,E491B04D,ACF7D73A,D8F6DF58,ACF7D73A]",
    "add": "[88F245C7,93D87240,F04529EA,BD2DB5FE,C36BF130]",
    "cost": "4,803"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-252",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[0AB87239,A0E7F6C2]",
    "add": "[14968630,58D36D86,4FF22350]",
    "cost": "4,109"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-253",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[4E1CFAD9,C36BF120,11660B2E,ACF7D73A,D33377F9]",
    "add": "[9DD6903A,EB58F897,58D36D87,F2EF4035]",
    "cost": "1,777"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-254",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[542BBC0E,74B98558,ACF7D73A,7A8CC6BD]",
    "add": "[F1C1E752,74B98564,CCD70D1D]",
    "cost": "11,752"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-255",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[D8F6DF54,DB301E72,CDA15978,099FDC51]",
    "add": "[9DD6903A,B688BAF10,542BBC0E,E0A95BBC]",
    "cost": "11,563"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-256",
    "status": "TRUE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[9DD6903A,BAF9CD1A,8164C894]",
    "add": "[D8F6DF60,42A1729B]",
    "cost": "8,781"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-257",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[9DD6903A,A0E7F6C2,CCD70D1D]",
    "add": "[14968630,C71B9808,A0E7F6C9]",
    "cost": "10,043"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-258",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[DC16E9E8,88F245C2,CCD70D1D]",
    "add": "[F1C1E752,D8F6DF61]",
    "cost": "1,646"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-259",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[58D36D79,F55885BD,E491B04D]",
    "add": "[3D26994F,17B2D2B7]",
    "cost": "11,126"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-260",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[4E1CFAD9,44804BD5,EB58F892]",
    "add": "[BD2DB5FE,E0A95BBC,B688BAF11,CB6FF519]",
    "cost": "11,599"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-261",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[200EA71C,C36BF120,F2EF4027]",
    "add": "[88F245C7,02A8E863,CCD70D1D,200EA71C,F2EF4035]",
    "cost": "3,441"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-262",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[66AB5D2E,7B500D6B,BD23BA18,DC16E9E11]",
    "add": "[F55885BD,DB301E79]",
    "cost": "7,351"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-263",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[66AB5D2E,66AB5D2E,DF6676C11]",
    "add": "[A0E7F6C7,D8F6DF61,B688BAF11,542BBC0E]",
    "cost": "3,622"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-264",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[F04529EA,BD2DB5FE,CDA15978]",
    "add": "[4E1CFAD15,A0E7F6C8,EB58F898]",
    "cost": "9,831"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-265",
    "status": "FALSE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[F04529EA,BAF9CD1A]",
    "add": "[85ECBAF11,DB07BD73]",
    "cost": "3,178"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-266",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[B688BAF3,C36BF120,BD2DB5FE]",
    "add": "[14968630,4E1CFAD16,1EBC5386]",
    "cost": "5,088"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-267",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[D33377F5,0ED43D84,8607492C]",
    "add": "[ACF7D73A,11660B2E,02A8E864]",
    "cost": "11,833"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-268",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[9FC8D91A,BD2DB5FE,DB07BD68]",
    "add": "[02A8E862,4FF22350,7A8CC6BD]",
    "cost": "3,370"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-269",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[4E1CFAD9,F55885BD,DB301E75,7B415254]",
    "add": "[864437DD,E02AF8C11,F04529EA,200EA71C,CDA15986,C36BF130]",
    "cost": "4,803"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-270",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[F2EF4025,58D36D80,17B2D2B2,4E1CFAD13]",
    "add": "[14968630,F36679BC,E0A95BBC,4E1CFAD18,542BBC0E]",
    "cost": "11,752"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-271",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[44804BD4,42A1729B,79A6C1CA]",
    "add": "[E0A5E11A,4E1CFAD16,42A1729B,A0E7F6C10]",
    "cost": "1,783"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-272",
    "status": "TRUE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[4CD72DAE,958C4CED,E491B04D]",
    "add": "[E0A95BBC,17B2D2B7,4FF22350]",
    "cost": "11,988"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-273",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[CCD70D1D,0ED43D84,CB6FF511]",
    "add": "[D8F6DF60,065DB72B,6E657E29]",
    "cost": "11,988"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-274",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,7B415254]",
    "add": "[4FF22348,A0E7F6C8,7A8CC6BD,A0E7F6C10,542BBC0E,C36BF130]",
    "cost": "8,129"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-275",
    "status": "TRUE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[DC16E9E8,DB301E72,DB07BD68]",
    "add": "[6E657E27,17B2D2B7,7670C5F13]",
    "cost": "10,752"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-276",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[DC16E9E8,F55885BD,DB301E73,ACF7D73A]",
    "add": "[85ECBAF11,93D87240,3CA906F17,A0E7F6C10,F2EF4035]",
    "cost": "5,343"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-277",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[44804BD4,7B500D6B,F2EF4027]",
    "add": "[CCD70D1D,58D36D86]",
    "cost": "1,169"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-278",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[F2EF4025,E0A5E11A,44804BD6]",
    "add": "[14968630,CCD70D1D,DF6676C17]",
    "cost": "1,289"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-279",
    "status": "TRUE",
    "surgeon": "Dr. John Smith",
    "remove": "[542BBC0E,42A1729B,44804BD6]",
    "add": "[F1C1E752,C71B9808]",
    "cost": "5,727"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-280",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[C36BF119,B688BAF4,17B2D2B2,5EDCDAE6]",
    "add": "[CCD70D1D,C71B9808,11102F55]",
    "cost": "3,356"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-281",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[7B415249,74B98558,CB6FF511]",
    "add": "[9202537,C36BF127]",
    "cost": "9,954"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-282",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[049BCC88,D8F6DF55,CDA15978]",
    "add": "[2C1769CF,D8F6DF61,867038F10]",
    "cost": "9,831"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-283",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[C36BF119,02A8E857,F04529EA,DC16E9E11]",
    "add": "[19A12B26,CCD70D1D]",
    "cost": "2,353"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-284",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[C36BF119,330FF32E]",
    "add": "[3CA906F15,CDA15983,F1C1E754]",
    "cost": "6,784"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-285",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[200EA71C,F36679BC,F55885BD,4FF22345,DB301E75,ACF7D73A]",
    "add": "[F36679BC,E02AF8C11,F04529EA,200EA71C,85ECBAF16]",
    "cost": "1,783"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-286",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[7A8CC6BD,BD23BA18]",
    "add": "[14968630,F36679BC,02A8E864]",
    "cost": "8,538"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-287",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[ACF7D73A,58D36D80,B688BAF5,D8F6DF58]",
    "add": "[4CD72DAE,44804BD11,7B415257,F36679BC]",
    "cost": "4,364"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-288",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[B688BAF3,0ED43D84,8607492C]",
    "add": "[74B98563,D8F6DF61,44804BD12]",
    "cost": "8,400"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-289",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[DC16E9E8,E80AAE4A,C36BF121]",
    "add": "[F36679BC,C71B9808,099FDC56]",
    "cost": "5,299"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-290",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[F2EF4025,1EBC5379,542BBC0E]",
    "add": "[CCD70D1D]",
    "cost": "1,196"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-291",
    "status": "TRUE",
    "surgeon": "Dr. John Smith",
    "remove": "[DC16E9E8,E80AAE4A,8607492C]",
    "add": "[D8F6DF60,330FF32E,79FC1E29]",
    "cost": "3,407"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-292",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[542BBC0E,F36679BC,CB6FF511]",
    "add": "[58D36D85,4E1CFAD16]",
    "cost": "6,784"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-293",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[DF6676C15,E02AF8C11,CCD70D1D,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-294",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[44804BD4,C36BF120,DF6676C11]",
    "add": "[85ECBAF11,CD9A49D7,5F3AFC31]",
    "cost": "8,781"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-295",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[CCD70D1D,BAF9CD1A,CD9A49D2,099FDC51]",
    "add": "[ACF7D73A,0ED43D90,6E657E29]",
    "cost": "2,744"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-296",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[C36BF119,F36679BC,F2EF4027,D33377F9]",
    "add": "[2C1769CF,E02AF8C11,9DD6903A]",
    "cost": "1,783"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-297",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[4E1CFAD9,F55885BD,E491B04D,5EDCDAE6,ACF7D73A]",
    "add": "[4FF22348,330FF32E,DF6676C17,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-298",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[8164C892,8164C893,F04529EA]",
    "add": "[F55885BD,B688BAF10,1EBC5386]",
    "cost": "8,781"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-299",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[D8F6DF54,44804BD5]",
    "add": "[7B500D6B,CCD70D1D,0ED43D91]",
    "cost": "3,356"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-300",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[DC16E9E8,DF6676C11]",
    "add": "[F2EF4031,B688BAF10,E80AAE4A,4E1CFAD18]",
    "cost": "11,988"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-301",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[58D36D79,3D26994F]",
    "add": "[85ECBAF11,7B415257]",
    "cost": "3,178"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-302",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[14968630,7B415250,CCD70D1D]",
    "add": "[74B98563,065DB72B,542BBC0E,A0E7F6C10]",
    "cost": "11,126"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-303",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[E0A95BBC,42A1729B,C36BF121]",
    "add": "[E491B04D,9DD6903A,7A8CC6BD]",
    "cost": "11,430"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-304",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[4E1CFAD9,BD23BA18]",
    "add": "[85ECBAF11,F36679BC,542BBC0E]",
    "cost": "1,777"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-305",
    "status": "TRUE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[9FC8D91A,66AB5D2E,17B2D2B2]",
    "add": "[F1C1E752,F55885BD]",
    "cost": "7,567"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-306",
    "status": "TRUE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[D9345860,02A8E857,5EDCDAE5]",
    "add": "[58D36D85,B688BAF10,E80AAE4A,CB6FF519]",
    "cost": "5,343"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-307",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[4E1CFAD9,7B415250,F55885BD,DB301E75]",
    "add": "[4FF22348,CCD70D1D,F2EF4035]",
    "cost": "6,563"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-308",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[E491B04D,DB301E72,CB6FF511]",
    "add": "[BD2DB5FE,DF6676C16,F1C1E754]",
    "cost": "3,407"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-309",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-310",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[44804BD4,22633EF8,542BBC0E,4FF22345]",
    "add": "[4E1CFAD15,EB58F897,EB58F898]",
    "cost": "8,538"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-311",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[4E1CFAD9,F36679BC,8607492C]",
    "add": "[14968630,F36679BC,74B98566]",
    "cost": "6,563"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-312",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[44804BD4,1EBC5379,CCD70D1D]",
    "add": "[A0E7F6C7,74B98564]",
    "cost": "2,743"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-313",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[A0E7F6C1,E80AAE4A,CDA15978]",
    "add": "[9C01A6AA,17B2D2B7,099FDC56]",
    "cost": "1,196"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-314",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[66AB5D2E,330FF32E]",
    "add": "[19A12B26,1EBC5385,3CA906F17]",
    "cost": "5,299"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-315",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[58D36D79,9FC8D91A,05BD5A7D]",
    "add": "[14968630,DF6676C17]",
    "cost": "10,043"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-316",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[048701CE,7B415250,DB07BD68]",
    "add": "[E0A5E11A,58D36D86,11660B2E]",
    "cost": "11,833"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-317",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[200EA71C,F55885BD,DF6676C11,ACF7D73A,DB301E75]",
    "add": "[9C01A6AA,02A8E863,099FDC56,F2EF4035]",
    "cost": "7,052"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-318",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[4E1CFAD9,9DD6903A,F2EF4027,DC16E9E12,ACF7D73A]",
    "add": "[4FF22348,85ECBAF12,11660B2E,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-319",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[7D206E43,22633EF8,4E1CFAD13]",
    "add": "[3CA906F15,5EDCDAE10,CCD70D1D]",
    "cost": "11,599"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-320",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[44804BD4,049BCC89,F55885BD]",
    "add": "[4FF22348,58D36D87,A0E7F6C10,C36BF130]",
    "cost": "7,052"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-321",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[4E1CFAD9,22633EF8,E80AAE4A]",
    "add": "[14968630,DB07BD73,0ED43D91]",
    "cost": "10,752"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-322",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,DB301E75]",
    "add": "[542BBC0E,D33377F12,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-323",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[A0E7F6C1,330FF32E,2C1769CF,DB301E75]",
    "add": "[F36679BC,E02AF8C11,CCD70D1D,F2EF4035,C36BF130]",
    "cost": "9,831"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-324",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[14968630,BAF9CD1A,F2EF4027]",
    "add": "[85ECBAF11,CDA15983,02A8E864]",
    "cost": "3,407"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-325",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[049BCC88,599EA2B4,CB6FF511]",
    "add": "[4FF22348,5EDCDAE10]",
    "cost": "2,744"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-326",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[44804BD4,7B415250,DB301E73]",
    "add": "[3CA906F15,9DD6903A]",
    "cost": "2,353"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-327",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[E491B04D,F55885BD,C36BF121,D8F6DF58]",
    "add": "[E0A5E11A,5F3AFC30,8607492C,BD2DB5FE]",
    "cost": "3,370"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-328",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[88F245C1,C36BF120,7A8CC6BD]",
    "add": "[3CA906F15,44804BD11,02A8E864]",
    "cost": "8,129"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-329",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[7B415249,85ECBAF6,C36BF121]",
    "add": "[E491B04D,CD9A49D7,58D36D87]",
    "cost": "3,407"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-330",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[4E1CFAD9,D9345861]",
    "add": "[F55885BD,0ED43D90]",
    "cost": "6,784"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-331",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[7B415249,9EAD0C20]",
    "add": "[D9345866,74B98564,CCD70D1D]",
    "cost": "6,784"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-332",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[049BCC88,F36679BC]",
    "add": "[3D26994F,A0E7F6C8,542BBC0E]",
    "cost": "11,430"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-333",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[4E1CFAD9,F55885BD,11660B2E,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,CDA15986]",
    "cost": "1,783"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-334",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[4FF22342,7B500D6B,542BBC0E]",
    "add": "[049BCC94,542BBC0E,CCD70D1D]",
    "cost": "9,661"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-335",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[4E1CFAD9,02A8E857,F55885BD]",
    "add": "[F36679BC,542BBC0E,8607492C,BD2DB5FE]",
    "cost": "11,126"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-336",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[B21A9DE2,66AB5D2E,11660B2E,ACF7D73A]",
    "add": "[19A12B26,3D26994F,E0A95BBC]",
    "cost": "6,563"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-337",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[D9345860,3D26994F]",
    "add": "[4CD72DAE,44804BD11,9FC8D91A]",
    "cost": "9,466"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-338",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[7D206E43,DC16E9E9,F2EF4027,ACF7D73A,4E1CFAD13]",
    "add": "[9C01A6AA,EB58F897,17B2D2B8,A0E7F6C10]",
    "cost": "1,289"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-339",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[F2EF4025,66AB5D2E]",
    "add": "[17B2D2B6,DB07BD73,F1C1E754]",
    "cost": "3,761"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-340",
    "status": "TRUE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[A0E7F6C1,7B415250,17B2D2B2]",
    "add": "[6E657E27,5F3AFC30]",
    "cost": "2,743"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-341",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[DC16E9E8,85ECBAF6,17B2D2B2]",
    "add": "[14968630,8164C899,867038F10]",
    "cost": "3,178"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-342",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[4E1CFAD9,F55885BD,F1C1E748,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-343",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[B688BAF3,7B415250,9C01A6AA]",
    "add": "[17B2D2B6,EB58F897]",
    "cost": "3,761"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-344",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[88F245C1,D8F6DF55,F1C1E748]",
    "add": "[19A12B26,11102F54,0ED43D91]",
    "cost": "10,752"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-345",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[4E1CFAD9,F55885BD,BD23BA18,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,5EDCDAE10,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-346",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75]",
    "add": "[19A12B26,E02AF8C11,F36679BC,BD2DB5FE,F2EF4035,C36BF130]",
    "cost": "8,755"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-347",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[44804BD4,C36BF120,17B2D2B2]",
    "add": "[3CA906F15,EB58F897,A0E7F6C9]",
    "cost": "3,622"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-348",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[D33377F5,11102F48,CCD70D1D]",
    "add": "[4FF22348,02A8E863,4FF22350]",
    "cost": "1,289"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-349",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[D33377F5,7B415250,CB6FF511]",
    "add": "[200EA71C,D33377F12]",
    "cost": "3,291"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-350",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[7B415249,CB6FF511]",
    "add": "[3CA906F15,DB301E78,17B2D2B8]",
    "cost": "8,781"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-351",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[7B415249,F2EF4027,DB301E75,ACF7D73A]",
    "add": "[DB301E77,DC16E9E15,C2F62DAA]",
    "cost": "7,182"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-352",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[CCD70D1D,E80AAE4A,F2EF4027,ACF7D73A,ACF7D73A]",
    "add": "[D8F6DF60,EB58F897,D33377F13,200EA71C]",
    "cost": "3,356"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-353",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[D8F6DF54,14968630]",
    "add": "[F36679BC,065DB72B,CCD70D1D]",
    "cost": "5,299"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-354",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[049BCC88,66AB5D2E,CB6FF511]",
    "add": "[049BCC94,542BBC0E,F36679BC]",
    "cost": "5,270"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-355",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[D9345860,F55885BD,BD23BA18,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,CCD70D1D,C36BF130]",
    "cost": "8,989"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-356",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[DC16E9E8,B688BAF4]",
    "add": "[4FF22348,B688BAF10,42A1729B]",
    "cost": "2,933"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-357",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[7B415249,8607492C]",
    "add": "[9DD6903A,048701CE,F55885BD,C36BF130]",
    "cost": "1,777"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-358",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[44804BD4,E0A5E11A,17B2D2B2,D9345863,DB301E75]",
    "add": "[7B415255,19A12B27,CCD70D1D]",
    "cost": "5,259"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-359",
    "status": "TRUE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[D8F6DF54,4E1CFAD10,93D87235,ACF7D73A]",
    "add": "[85ECBAF11,DC16E9E15,6E657E29]",
    "cost": "11,599"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-360",
    "status": "TRUE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[DC16E9E8,F55885BD,F55885BD]",
    "add": "[4FF22348,048701CE,7B415257,C36BF130]",
    "cost": "7,052"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-361",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[4FF22342,C36BF120,7A8CC6BD]",
    "add": "[E491B04D,D8F6DF61,F36679BC]",
    "cost": "5,088"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-362",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[58D36D79,7B415250,8607492C]",
    "add": "[7B500D6B,5F3AFC30]",
    "cost": "8,755"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-363",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[F2EF4025,D33377F6,7B500D6B]",
    "add": "[F36679BC,11102F54]",
    "cost": "3,178"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-364",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[44804BD4,F55885BD,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,542BBC0E]",
    "cost": "1,783"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-365",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[DC16E9E8,D9345861,CD9A49D2,4FF22345]",
    "add": "[D8F6DF60,93D87240]",
    "cost": "4,364"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-366",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[A0E7F6C1,11102F48,7B500D6B]",
    "add": "[19A12B26,DB07BD73,C36BF127,A0E7F6C10]",
    "cost": "1,196"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-367",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[A0E7F6C1,A0E7F6C2,CDA15978]",
    "add": "[14968630,B688BAF10,66AB5D2E]",
    "cost": "5,088"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-368",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[7A8CC6BD,7B415250,C36BF121]",
    "add": "[F55885BD,CCD70D1D,58D36D87]",
    "cost": "8,989"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-369",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[DC16E9E8,BD2DB5FE,E491B04D,DC16E9E12]",
    "add": "[14968630,D8F6DF61]",
    "cost": "11,656"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-370",
    "status": "TRUE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[A0E7F6C1,E80AAE4A,7B500D6B]",
    "add": "[85ECBAF11,85ECBAF12,05BD5A7D,4E1CFAD18]",
    "cost": "3,291"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-371",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[DF6676C9,9FC8D91A,74B98559]",
    "add": "[CCD70D1D,0ED43D90,79FC1E29]",
    "cost": "11,656"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-372",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[D8F6DF54,8164C893,CB6FF511]",
    "add": "[D9345866,DC16E9E15,17B2D2B8]",
    "cost": "5,088"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-373",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[D8F6DF54,BD2DB5FE,CD9A49D2]",
    "add": "[CDA15982,D8F6DF61,8607492C]",
    "cost": "8,781"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-374",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[ACF7D73A,41097027]",
    "add": "[CDA15982,DC16E9E15,099FDC56]",
    "cost": "11,833"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-375",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[0AB87239,E80AAE4A,93D87235]",
    "add": "[66AB5D2E,DC16E9E15]",
    "cost": "9,831"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-376",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[200EA71C,BAF9CD1A,F04529EA]",
    "add": "[9202537,02A8E863,11102F55,E0A95BBC]",
    "cost": "8,989"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-377",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[DC16E9E8,66AB5D2E,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[DF6676C15,7670C5F12,867038F10,E0A95BBC]",
    "cost": "6,563"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-378",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[4E1CFAD9,BAF9CD1A,17B2D2B2]",
    "add": "[049BCC94,E02AF8C11,6E657E29]",
    "cost": "5,343"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-379",
    "status": "TRUE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[58D36D79,F36679BC,79A6C1CA,7A8CC6BD,ACF7D73A]",
    "add": "[4FF22348,11660B2E,DF6676C17,BD2DB5FE]",
    "cost": "1,783"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-380",
    "status": "TRUE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[D8F6DF54,DB07BD67,7B500D6B]",
    "add": "[BD2DB5FE,8164C899,F1C1E754]",
    "cost": "7,182"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-381",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[4E1CFAD9,7B415250,B688BAF5]",
    "add": "[E0A5E11A,17B2D2B7]",
    "cost": "11,656"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-382",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[A0E7F6C1,ACF7D73A]",
    "add": "[02A8E862,5EDCDAE10,CCD70D1D,CB6FF519]",
    "cost": "11,752"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-383",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[F2EF4025,02A8E857,F04529EA]",
    "add": "[88F245C7,19A12B27,4E1CFAD18]",
    "cost": "9,831"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-384",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[8164C892,DC16E9E9,542BBC0E]",
    "add": "[3D26994F,DB07BD73,4FF22350]",
    "cost": "5,343"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-385",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[200EA71C,1EBC5379,F04529EA]",
    "add": "[7B500D6B,DC16E9E15]",
    "cost": "1,169"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-386",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[7B415249,E0A5E11A,8607492C]",
    "add": "[D8F6DF60,F36679BC,11660B2E,66AB5D2E]",
    "cost": "4,803"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-387",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[14968630,E02AF8C11,CCD70D1D,A0E7F6C10,CDA15986]",
    "cost": "1,783"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-388",
    "status": "TRUE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[DF6676C9,22633EF8,DB301E73]",
    "add": "[ACF7D73A,7670C5F12,EB58F898]",
    "cost": "1,646"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-389",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[DF6676C9,B688BAF4,CD9A49D2]",
    "add": "[542BBC0E,C2F62DAA]",
    "cost": "11,430"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-390",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[44804BD4,A0E7F6C2,BD2DB5FE]",
    "add": "[4CD72DAE,200EA71C,542BBC0E]",
    "cost": "6,784"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-391",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[ACF7D73A,F36679BC,93D87235]",
    "add": "[A0E7F6C7,02A8E863,8164C900,66AB5D2E]",
    "cost": "6,784"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-392",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[049BCC88,BD2DB5FE,17B2D2B2]",
    "add": "[049BCC94,E02AF8C11,7B415257]",
    "cost": "7,052"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-393",
    "status": "FALSE",
    "surgeon": "Dr. David Cohen",
    "remove": "[44804BD4,E80AAE4A,D9345863]",
    "add": "[542BBC0E,CDA15983,6E657E29,4E1CFAD18]",
    "cost": "11,430"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-394",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[8164C892,E80AAE4A,DC16E9E12]",
    "add": "[ACF7D73A,D8F6DF61,542BBC0E]",
    "cost": "8,565"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-395",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[88F245C1,E491B04D]",
    "add": "[9DD6903A,A0E7F6C8,0ED43D91,7A8CC6BD]",
    "cost": "1,646"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-396",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[DC16E9E8,BAF9CD1A,17B2D2B2]",
    "add": "[D33377F11,3D26994F]",
    "cost": "8,989"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-397",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[4E1CFAD9,F55885BD,74B98559,ACF7D73A,DB301E75]",
    "add": "[ACF7D73A,DB07BD73,A0E7F6C9]",
    "cost": "8,538"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-398",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[0ED43D83,F36679BC]",
    "add": "[9DD6903A,0ED43D90,6E657E29]",
    "cost": "8,989"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-399",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[DF6676C9,A0E7F6C2,17B2D2B2]",
    "add": "[19A12B26,200EA71C]",
    "cost": "11,988"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-400",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[E491B04D,7B415250,DF6676C11]",
    "add": "[EB58F896,19A12B27]",
    "cost": "11,656"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-401",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[D8F6DF54,DB07BD67,CDA15978]",
    "add": "[D8F6DF60,A0E7F6C8,B688BAF11,BD2DB5FE]",
    "cost": "6,563"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-402",
    "status": "TRUE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[4FF22342,9DD6903A,79A6C1CA]",
    "add": "[F1C1E752,CD9A49D7,9FC8D91A,A0E7F6C10]",
    "cost": "5,251"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-403",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[7B415249,958C4CED,F2EF4027]",
    "add": "[F2EF4031,B688BAF10,8164C900]",
    "cost": "1,783"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-404",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[19A12B20,3D26994F,05BD5A7D]",
    "add": "[88F245C7,11102F54,7670C5F13]",
    "cost": "8,400"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-405",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[4CD72DAE,958C4CED,CB6FF511]",
    "add": "[02A8E862,048701CE,44804BD12]",
    "cost": "5,343"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-406",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[E491B04D,F55885BD,F2EF4027,ACF7D73A,4E1CFAD13,ACF7D73A]",
    "add": "[DF6676C15,8164C899,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-407",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[7B415249,7B415250,F1C1E748]",
    "add": "[ACF7D73A,D33377F13]",
    "cost": "11,833"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-408",
    "status": "FALSE",
    "surgeon": "Dr. David Cohen",
    "remove": "[7A8CC6BD,065DB72B,17B2D2B2]",
    "add": "[D33377F11,19A12B27,867038F10]",
    "cost": "8,538"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-409",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,D33377F9]",
    "add": "[7B500D6B,58D36D86,CCD70D1D,C36BF130]",
    "cost": "2,743"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-410",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-411",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[9DD6903A,7B500D6B,7B500D6B]",
    "add": "[F36679BC,DB07BD73]",
    "cost": "1,169"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-412",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[7D206E43,330FF32E,8607492C]",
    "add": "[7B500D6B,065DB72B]",
    "cost": "4,757"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-413",
    "status": "FALSE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[E80AAE4A,CDA15978]",
    "add": "[A0E7F6C7,11660B2E,42A1729B]",
    "cost": "8,781"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-414",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[11660B2E,DB07BD67,DF6676C11]",
    "add": "[200EA71C,85ECBAF12,6E657E29]",
    "cost": "3,370"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-415",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[5EDCDAE3,14968630,17B2D2B2]",
    "add": "[85ECBAF11,A0E7F6C8,7A8CC6BD,200EA71C]",
    "cost": "5,708"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-416",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[B688BAF3,C36BF120,CD9A49D2]",
    "add": "[4CD72DAE,11102F54]",
    "cost": "11,752"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-417",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[42A1729B,F36679BC,DB301E73]",
    "add": "[14968630,DB07BD73,F1C1E754]",
    "cost": "4,757"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-418",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[049BCC88,7B415250,E491B04D]",
    "add": "[E0A95BBC,C71B9808,42A1729B]",
    "cost": "5,299"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-419",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[58D36D79,065DB72B,93D87235,ACF7D73A,ACF7D73A]",
    "add": "[02A8E862,330FF32E,CCD70D1D,66AB5D2E,F2EF4035,C36BF130]",
    "cost": "1,289"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-420",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[4FF22342,DB07BD67,F55885BD]",
    "add": "[CCD70D1D,85ECBAF12,EB58F898]",
    "cost": "6,563"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-421",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[DC16E9E8,B688BAF4]",
    "add": "[19A12B26,6E657E29]",
    "cost": "1,646"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-422",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[F04529EA,22633EF8]",
    "add": "[4CD72DAE,B688BAF10,7670C5F13]",
    "cost": "11,430"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-423",
    "status": "TRUE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[D8F6DF54,BD2DB5FE,C36BF121]",
    "add": "[ACF7D73A,0ED43D90,74B98565]",
    "cost": "8,755"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-424",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[C36BF119,F36679BC,C36BF121]",
    "add": "[6E657E27,44804BD11,C36BF127]",
    "cost": "11,833"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-425",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[E491B04D,8164C893,F55885BD]",
    "add": "[542BBC0E,E0A5E11A,E0A95BBC]",
    "cost": "3,370"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-426",
    "status": "TRUE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[DC16E9E8,F36679BC,CD9A49D2,099FDC51,DB301E75]",
    "add": "[DB301E77,DB301E78,44804BD12,74B98566,F2EF4035]",
    "cost": "5,270"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-427",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[8164C892,F55885BD,7B500D6B]",
    "add": "[F55885BD]",
    "cost": "5,727"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-428",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[4E1CFAD9,599EA2B4,CDA15978,ACF7D73A]",
    "add": "[DB301E77,11660B2E,542BBC0E,200EA71C]",
    "cost": "1,783"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-429",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[B688BAF3,8164C893]",
    "add": "[E0A5E11A,CCD70D1D,F1C1E754]",
    "cost": "11,833"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-430",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[542BBC0E,D8F6DF55,F2EF4027,ACF7D73A]",
    "add": "[3CA906F15,D8F6DF61,CCD70D1D]",
    "cost": "8,781"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-431",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[DB07BD66,330FF32E,CB6FF511]",
    "add": "[ACF7D73A,542BBC0E,F1C1E754]",
    "cost": "7,052"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-432",
    "status": "FALSE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[4E1CFAD9,3D26994F,F2EF4027,ACF7D73A,DB301E75]",
    "add": "[DF6676C15,E02AF8C11,CCD70D1D,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-433",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[D33377F5,E80AAE4A,E80AAE4A]",
    "add": "[88F245C7,D8F6DF61,B688BAF11]",
    "cost": "5,343"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-434",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[7D206E43,9EAD0C20,F55885BD]",
    "add": "[17B2D2B6,DC16E9E15,E80AAE4A,BD2DB5FE]",
    "cost": "4,757"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-435",
    "status": "TRUE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[CCD70D1D,42A1729B,F2EF4027,099FDC51]",
    "add": "[2C1769CF,58D36D86,E80AAE4A]",
    "cost": "10,752"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-436",
    "status": "TRUE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[4E1CFAD9,B688BAF4,CDA15978,D8F6DF58,7B415254]",
    "add": "[4FF22348,F36679BC,05BD5A7D,74B98566,CDA15986,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-437",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[BAF9CD1A,DC16E9E9,8607492C]",
    "add": "[DF6676C15,9DD6903A,42A1729B,F2EF4035]",
    "cost": "5,343"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-438",
    "status": "TRUE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[F04529EA,02A8E857,C36BF121]",
    "add": "[9C01A6AA,E0A5E11A,A0E7F6C9,F2EF4035]",
    "cost": "8,565"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-439",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[049BCC88,F55885BD,F2EF4027,DB301E75]",
    "add": "[58D36D85,E02AF8C11,CCD70D1D,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-440",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[44804BD4,7B415250]",
    "add": "[A0E7F6C7,11660B2E]",
    "cost": "7,182"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-441",
    "status": "TRUE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[7D206E43,E80AAE4A,E491B04D]",
    "add": "[A0E7F6C7,C71B9808]",
    "cost": "7,182"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-442",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[7B415249]",
    "add": "[DB301E77,330FF32E]",
    "cost": "1,646"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-443",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[B21A9DE2,7B415250,EB58F892]",
    "add": "[4CD72DAE,3D26994F,6E657E29]",
    "cost": "11,988"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-444",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[B21A9DE2,74B98558,74B98559]",
    "add": "[4CD72DAE,8164C900]",
    "cost": "8,755"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-445",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[42A1729B,C36BF120,BD23BA18]",
    "add": "[85ECBAF11,E0A95BBC]",
    "cost": "10,752"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-446",
    "status": "TRUE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[048701CE,BAF9CD1A]",
    "add": "[2C1769CF,330FF32E,1EBC5386]",
    "cost": "2,743"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-447",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[66AB5D2E,66AB5D2E]",
    "add": "[F2EF4031,44804BD11]",
    "cost": "8,989"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-448",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[7B415249,F04529EA]",
    "add": "[4FF22348,A0E7F6C8,C2F62DAA,F36679BC]",
    "cost": "11,656"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-449",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[048701CE,F55885BD,CB6FF511]",
    "add": "[F1C1E752,065DB72B,C2F62DAA]",
    "cost": "8,781"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-450",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[ACF7D73A,C36BF120,79A6C1CA]",
    "add": "[85ECBAF11,DF6676C16,099FDC56]",
    "cost": "3,370"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-451",
    "status": "TRUE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[7B415249,E80AAE4A,93D87235]",
    "add": "[9DD6903A,542BBC0E,02A8E864]",
    "cost": "11,656"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-452",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[4CD72DAE,D8F6DF55,74B98559,099FDC51,ACF7D73A]",
    "add": "[BD2DB5FE,E02AF8C11,C2F62DAA,4E1CFAD18,C36BF130]",
    "cost": "1,777"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-453",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[C36BF119,02A8E857,DB07BD68]",
    "add": "[542BBC0E,93D87240,7A8CC6BD]",
    "cost": "1,169"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-454",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[ACF7D73A,7B415250,17B2D2B2]",
    "add": "[D9345866,CCD70D1D,1EBC5386]",
    "cost": "11,563"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-455",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[E80AAE4A,44804BD5,C36BF121]",
    "add": "[542BBC0E]",
    "cost": "8,755"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-456",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[44804BD4,7B415250,C36BF121]",
    "add": "[A0E7F6C7,7B415256,200EA71C]",
    "cost": "1,783"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-457",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[E0A95BBC,F36679BC,E491B04D,5EDCDAE6,D8F6DF58]",
    "add": "[BD2DB5FE,E02AF8C11,7B415257,BD2DB5FE]",
    "cost": "9,831"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-458",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[9FC8D91A,E80AAE4A,F1C1E748]",
    "add": "[74B98563,DC16E9E15,542BBC0E,A0E7F6C10]",
    "cost": "5,727"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-459",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[85ECBAF5,41097027,CD9A49D2]",
    "add": "[3D26994F,D8F6DF61,F55885BD]",
    "cost": "1,169"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-460",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[CCD70D1D,BD2DB5FE,C36BF121]",
    "add": "[66AB5D2E,5F3AFC30,B688BAF11,542BBC0E]",
    "cost": "4,364"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-461",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[CCD70D1D,CCD70D1D]",
    "add": "[6E657E27,330FF32E,02A8E864]",
    "cost": "6,784"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-462",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[85ECBAF5,DC16E9E9,BD23BA18]",
    "add": "[14968630,85ECBAF12,A0E7F6C9,C36BF130]",
    "cost": "1,289"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-463",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[D33377F5,958C4CED,5EDCDAE6]",
    "add": "[049BCC94,44804BD11,02A8E864,F2EF4035,C36BF130]",
    "cost": "8,989"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-464",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[7B415249,5EDCDAE5]",
    "add": "[542BBC0E,048701CE,DB301E79]",
    "cost": "1,646"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-465",
    "status": "TRUE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[19A12B20,C36BF120,9C01A6AA]",
    "add": "[F1C1E752,A0E7F6C8,42A1729B]",
    "cost": "1,169"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-466",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[58D36D79,9EAD0C20]",
    "add": "[200EA71C,DB07BD73,17B2D2B8]",
    "cost": "3,761"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-467",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[ACF7D73A,BAF9CD1A,CCD70D1D]",
    "add": "[E0A95BBC,11102F54,17B2D2B8,F36679BC]",
    "cost": "3,178"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-468",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[E491B04D,9EAD0C20,C36BF121]",
    "add": "[ACF7D73A,065DB72B,17B2D2B8]",
    "cost": "3,356"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-469",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[CCD70D1D,74B98558,F2EF4027]",
    "add": "[049BCC94,5EDCDAE10]",
    "cost": "2,353"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-470",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[F2EF4025,D8F6DF55,CDA15978]",
    "add": "[CDA15982,DB07BD73,F36679BC]",
    "cost": "7,052"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-471",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[049BCC88,F55885BD,F04529EA,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[7B500D6B,E02AF8C11,9FC8D91A,A0E7F6C10,F2EF4035]",
    "cost": "11,430"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-472",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[19A12B20,BAF9CD1A,CDA15978,7A8CC6BD]",
    "add": "[17B2D2B6,D8F6DF61,E80AAE4A]",
    "cost": "8,755"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-473",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[A0E7F6C1,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]",
    "add": "[DF6676C15,DF6676C16,E0A95BBC,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-474",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[049BCC88,065DB72B,F2EF4027,DB301E75]",
    "add": "[4FF22348,11102F54]",
    "cost": "6,784"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-475",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[58D36D79,7B415250,F2EF4027,4E1CFAD13]",
    "add": "[7B415255,9DD6903A,42A1729B]",
    "cost": "3,761"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-476",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[049BCC88,11102F48,DF6676C11]",
    "add": "[F36679BC,CCD70D1D]",
    "cost": "11,833"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-477",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[CCD70D1D,BD2DB5FE,B688BAF5]",
    "add": "[02A8E862,5F3AFC30,66AB5D2E]",
    "cost": "3,178"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-478",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[7B415249,9DD6903A]",
    "add": "[D8F6DF60,44804BD11]",
    "cost": "8,781"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-479",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[DC16E9E8,C36BF120,E491B04D]",
    "add": "[3D26994F,200EA71C,0ED43D91]",
    "cost": "5,727"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-480",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[14968630,7B500D6B,17B2D2B2]",
    "add": "[4FF22348,66AB5D2E]",
    "cost": "9,831"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-481",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[200EA71C,A0E7F6C2,F04529EA]",
    "add": "[EB58F896,44804BD11,8164C900]",
    "cost": "1,783"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-482",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[7B415249,F55885BD,F2EF4027,DB301E75]",
    "add": "[19A12B26,065DB72B,05BD5A7D,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-483",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[4E1CFAD9,42A1729B,BD23BA18,ACF7D73A]",
    "add": "[D33377F11,E02AF8C11,EB58F898,74B98566,CDA15986]",
    "cost": "3,178"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-484",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[44804BD4,330FF32E,CB6FF511]",
    "add": "[14968630,D8F6DF61]",
    "cost": "2,353"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-485",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[7D206E43,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-486",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[DC16E9E8,88F245C2,7B500D6B]",
    "add": "[7B415255,EB58F897,7A8CC6BD]",
    "cost": "2,353"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-487",
    "status": "TRUE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[D33377F5,88F245C2,17B2D2B2]",
    "add": "[542BBC0E,D8F6DF61,F55885BD]",
    "cost": "3,291"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-488",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[4E1CFAD9,D8F6DF55,9C01A6AA,DB301E75]",
    "add": "[BB20277D,7670C5F12,E0A95BBC,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-489",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[DC16E9E8,9EAD0C20,2C1769CF]",
    "add": "[A0E7F6C7,DC3EC10A]",
    "cost": "3,178"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-490",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[44804BD4,7B415250,F55885BD]",
    "add": "[88F245C7,CDA15983]",
    "cost": "8,989"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-491",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[66AB5D2E,E80AAE4A,ACF7D73A]",
    "add": "[F2EF4031,048701CE]",
    "cost": "5,251"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-492",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[4E1CFAD9,22633EF8,F04529EA]",
    "add": "[44804BD10,02A8E863,CCD70D1D]",
    "cost": "11,988"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-493",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[DB07BD66,7B500D6B,8607492C]",
    "add": "[F36679BC,DB07BD73,DF6676C17]",
    "cost": "5,727"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-494",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[542BBC0E,44804BD5]",
    "add": "[4E1CFAD15,DF6676C16,17B2D2B8]",
    "cost": "3,370"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-495",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[0ED43D83,958C4CED]",
    "add": "[BB20277D,DF6676C16,4E1CFAD18]",
    "cost": "1,196"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-496",
    "status": "TRUE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[4E1CFAD9,D8F6DF55,C36BF121]",
    "add": "[14968630,74B98564,E80AAE4A]",
    "cost": "7,182"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-497",
    "status": "TRUE",
    "surgeon": "Dr. John Smith",
    "remove": "[4E1CFAD9,F55885BD,11660B2E,ACF7D73A,4E1CFAD13,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,542BBC0E]",
    "cost": "4,803"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-498",
    "status": "TRUE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[8164C892,14968630,8607492C]",
    "add": "[7B500D6B,DC16E9E15,44804BD12]",
    "cost": "11,988"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-499",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[D8F6DF54,2C1769CF,F55885BD]",
    "add": "[2C1769CF,11660B2E,58D36D87]",
    "cost": "2,933"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-500",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[0ED43D83,542BBC0E]",
    "add": "[CCD70D1D,11102F54,8164C900]",
    "cost": "3,407"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-501",
    "status": "TRUE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[44804BD4,E80AAE4A,E491B04D]",
    "add": "[F55885BD,3D26994F,7B415257]",
    "cost": "1,289"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-502",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[D33377F5,CDA15978]",
    "add": "[F2EF4031,F36679BC,099FDC56]",
    "cost": "3,761"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-503",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[7A8CC6BD,74B98558,8607492C]",
    "add": "[E0A95BBC,11660B2E,66AB5D2E]",
    "cost": "11,752"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-504",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[C36BF119,330FF32E,542BBC0E]",
    "add": "[4E1CFAD15,B688BAF11,200EA71C]",
    "cost": "3,356"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-505",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[C36BF119,E80AAE4A,DB301E73,ACF7D73A]",
    "add": "[4CD72DAE,200EA71C,F04529EA]",
    "cost": "11,126"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-506",
    "status": "TRUE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[B21A9DE2,66AB5D2E,93D87235,DB301E75]",
    "add": "[DF6676C15,D8F6DF61,11102F55,74B98566]",
    "cost": "1,646"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-507",
    "status": "TRUE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[44804BD4,F36679BC]",
    "add": "[3CA906F15,CDA15983]",
    "cost": "9,661"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-508",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[4FF22342,7B415250,3D26994F]",
    "add": "[F36679BC,74B98564,F55885BD]",
    "cost": "11,988"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-509",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[F2EF4025,88F245C2]",
    "add": "[ACF7D73A,DC16E9E15,F36679BC]",
    "cost": "3,407"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-510",
    "status": "TRUE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[0ED43D83,CD9A49D2,7A8CC6BD]",
    "add": "[88F245C7,C71B9808,5F3AFC31]",
    "cost": "1,783"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-511",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[BAF9CD1A,A0E7F6C2,D9345863]",
    "add": "[9202537,330FF32E,542BBC0E]",
    "cost": "4,109"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-512",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[B688BAF3,42A1729B,F04529EA,ACF7D73A]",
    "add": "[CDA15982,D8F6DF61]",
    "cost": "4,364"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-513",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[542BBC0E,E0A5E11A,DF6676C11]",
    "add": "[3D26994F,CCD70D1D,F1C1E754]",
    "cost": "8,538"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-514",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[88F245C1,F36679BC,5EDCDAE5]",
    "add": "[44804BD10,D8F6DF61,58D36D87,CB6FF519]",
    "cost": "7,708"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-515",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[44804BD4,A0E7F6C2,CB6FF511]",
    "add": "[2C1769CF,9DD6903A]",
    "cost": "8,989"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-516",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[0AB87239,42A1729B]",
    "add": "[88F245C7,EB58F897,C36BF127]",
    "cost": "4,109"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-517",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[DC16E9E8,DC16E9E9,7B500D6B]",
    "add": "[F36679BC,11102F54,7B500D6B]",
    "cost": "11,988"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-518",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[DF6676C9,1EBC5379,DF6676C11]",
    "add": "[17B2D2B6,E02AF8C11,7B415257]",
    "cost": "9,954"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-519",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[9FC8D91A,02A8E857,F04529EA]",
    "add": "[F1C1E752,02A8E863,E80AAE4A]",
    "cost": "4,109"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-520",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[7B415249,F55885BD,05BD5A7D,DB301E75]",
    "add": "[4FF22348,0ED43D90,3CA906F17,4E1CFAD18,C36BF130]",
    "cost": "3,407"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-521",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[4E1CFAD9,9DD6903A,F55885BD,D33377F9]",
    "add": "[E491B04D,58D36D86,B688BAF11,85ECBAF16]",
    "cost": "6,563"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-522",
    "status": "TRUE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[DC16E9E8,CDA15977,F04529EA]",
    "add": "[44804BD10,DB301E78,F1C1E754]",
    "cost": "11,656"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-523",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[14968630,F2EF4027]",
    "add": "[74B98563,44804BD11,9FC8D91A]",
    "cost": "3,441"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-524",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[0ED43D83,11102F48]",
    "add": "[14968630,17B2D2B7]",
    "cost": "8,538"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-525",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[049BCC88,42A1729B,CD9A49D2]",
    "add": "[4CD72DAE,B688BAF10,7B500D6B]",
    "cost": "11,599"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-526",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[44804BD4,7B415250,CD9A49D2]",
    "add": "[14968630,58D36D86]",
    "cost": "5,343"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-527",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[ACF7D73A,F55885BD,F2EF4027,ACF7D73A]",
    "add": "[ACF7D73A,F36679BC,F1C1E754,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-528",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[4E1CFAD9,F55885BD,BD2DB5FE,ACF7D73A,DC16E9E12,ACF7D73A]",
    "add": "[4FF22348,74B98564,CCD70D1D,F2EF4035,C36BF130]",
    "cost": "6,563"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-529",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[E491B04D,065DB72B,542BBC0E]",
    "add": "[88F245C7,B688BAF10,7B415257]",
    "cost": "8,538"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-530",
    "status": "TRUE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[19A12B20,79A6C1CA]",
    "add": "[14968630,200EA71C]",
    "cost": "11,656"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-531",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[E491B04D,11102F48,F04529EA]",
    "add": "[4CD72DAE,7670C5F12,D33377F13,E0A95BBC]",
    "cost": "5,088"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-532",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[44804BD4,F55885BD,DC16E9E12]",
    "add": "[F36679BC,0ED43D90]",
    "cost": "3,441"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-533",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[542BBC0E,DB07BD67,79A6C1CA]",
    "add": "[E0A5E11A,CDA15983,F1C1E754]",
    "cost": "7,567"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-534",
    "status": "TRUE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[D33377F5,CDA15977,CDA15978]",
    "add": "[F36679BC,93D87240,C36BF127,A0E7F6C10]",
    "cost": "4,364"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-535",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[79A6C1CA,DC16E9E9,D8F6DF56]",
    "add": "[DB301E77,A0E7F6C8,F1C1E754,BD2DB5FE]",
    "cost": "2,493"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-536",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[CCD70D1D,14968630,7A8CC6BD]",
    "add": "[D8F6DF60,E02AF8C11,4E1CFAD18,F2EF4035]",
    "cost": "11,563"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-537",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[542BBC0E,E80AAE4A,BD23BA18,D8F6DF58,ACF7D73A]",
    "add": "[9DD6903A,11102F54,CCD70D1D,542BBC0E]",
    "cost": "3,178"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-538",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[F04529EA,DB07BD67,EB58F892]",
    "add": "[D33377F11,11102F54,7670C5F13]",
    "cost": "11,656"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-539",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[4E1CFAD9,7B415250,17B2D2B2]",
    "add": "[9202537,19A12B27,542BBC0E]",
    "cost": "11,656"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-540",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[11660B2E,7B415250]",
    "add": "[4CD72DAE,A0E7F6C8,C2F62DAA]",
    "cost": "11,833"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-541",
    "status": "TRUE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[E80AAE4A,CDA15978]",
    "add": "[ACF7D73A]",
    "cost": "8,400"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-542",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[E491B04D,14968630,3D26994F]",
    "add": "[19A12B26,D8F6DF61,CCD70D1D,E0A95BBC]",
    "cost": "4,364"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-543",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[4E1CFAD9,E80AAE4A]",
    "add": "[7B415255,0ED43D91]",
    "cost": "10,752"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-544",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[44804BD4,F04529EA]",
    "add": "[E0A5E11A,58D36D86,C36BF127]",
    "cost": "2,353"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-545",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[4FF22342,7B500D6B,CB6FF511]",
    "add": "[7B500D6B,330FF32E,DF6676C17]",
    "cost": "5,251"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-546",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]",
    "add": "[66AB5D2E,D33377F12,E0A95BBC,F2EF4035,C36BF130]",
    "cost": "2,744"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-547",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[0AB87239,BAF9CD1A,17B2D2B2]",
    "add": "[88F245C7,C71B9808,F1C1E754]",
    "cost": "8,989"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-548",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[D8F6DF54,3D26994F,E491B04D]",
    "add": "[CDA15982,DF6676C16,02A8E864]",
    "cost": "3,441"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-549",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[BAF9CD1A,DC16E9E9,F2EF4027]",
    "add": "[4CD72DAE,5F3AFC30,CCD70D1D]",
    "cost": "7,052"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-550",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[58D36D79,DC16E9E9,9DD6903A]",
    "add": "[049BCC94,74B98564,17B2D2B8]",
    "cost": "4,109"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-551",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[4E1CFAD9,F55885BD,93D87235,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,C71B9808,CCD70D1D,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-552",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[11660B2E,22633EF8,F04529EA]",
    "add": "[7B500D6B,065DB72B,1EBC5386,7A8CC6BD]",
    "cost": "1,196"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-553",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[D8F6DF54,7B415250,DF6676C11]",
    "add": "[9DD6903A,7B500D6B,200EA71C]",
    "cost": "6,784"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-554",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[200EA71C,8164C893,DB07BD68]",
    "add": "[14968630,D8F6DF61,867038F10,BD2DB5FE]",
    "cost": "11,833"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-555",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[0AB87239,E80AAE4A,DF6676C11]",
    "add": "[9202537,17B2D2B7,9FC8D91A]",
    "cost": "1,783"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-556",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[DC16E9E8,11102F48,F55885BD]",
    "add": "[4E1CFAD15,542BBC0E]",
    "cost": "6,784"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-557",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[9FC8D91A,F55885BD,F2EF4027,ACF7D73A,D8F6DF58,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-558",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[19A12B20,66AB5D2E,74B98559]",
    "add": "[E0A95BBC,7B415256,4FF22350]",
    "cost": "5,251"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-559",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[F2EF4025,E0A5E11A,E491B04D]",
    "add": "[88F245C7,A0E7F6C8,DF6676C17]",
    "cost": "5,270"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-560",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[19A12B20,02A8E857,F2EF4027,DB301E75]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-561",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[5EDCDAE3,CDA15977,F04529EA]",
    "add": "[A0E7F6C7,8164C899,58D36D87]",
    "cost": "7,182"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-562",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[4E1CFAD9,DC16E9E9,F2EF4027,C36BF122,DB301E75,ACF7D73A]",
    "add": "[DF6676C15,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-563",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[44804BD4,E80AAE4A,F1C1E748]",
    "add": "[14968630,11660B2E,1EBC5386]",
    "cost": "3,761"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-564",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[7B415249,B688BAF4]",
    "add": "[F36679BC,8164C899,8607492C,A0E7F6C10]",
    "cost": "7,708"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-565",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[F2EF4025,BAF9CD1A]",
    "add": "[542BBC0E,048701CE,C2F62DAA]",
    "cost": "5,299"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-566",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[4E1CFAD9,049BCC89,F2EF4027,ACF7D73A,D8F6DF58]",
    "add": "[85ECBAF11,E02AF8C11,05BD5A7D,74B98566,542BBC0E,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-567",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[42A1729B,330FF32E,F55885BD,ACF7D73A]",
    "add": "[F413914D,85ECBAF12,8607492C,74B98566,F2EF4035]",
    "cost": "7,182"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-568",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[049BCC88,11102F48,17B2D2B2]",
    "add": "[D33377F11,66AB5D2E]",
    "cost": "7,351"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-569",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[048701CE,F36679BC]",
    "add": "[BD2DB5FE,F36679BC,C36BF127]",
    "cost": "2,493"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-570",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[19A12B20,8164C893]",
    "add": "[4E1CFAD15,3D26994F]",
    "cost": "7,567"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-571",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[542BBC0E,F55885BD,F2EF4027,5EDCDAE6,DB301E75]",
    "add": "[4FF22348,4FF22349,CCD70D1D,A0E7F6C10,CB6FF519]",
    "cost": "4,803"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-572",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[0AB87239,7B500D6B,C36BF122]",
    "add": "[3CA906F15,8164C899,7A8CC6BD]",
    "cost": "3,862"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-573",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[DC16E9E8,D33377F6,17B2D2B2]",
    "add": "[3CA906F15,19A12B27,C71B9809]",
    "cost": "3,407"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-574",
    "status": "TRUE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[BAF9CD1A,A0E7F6C2,C36BF121]",
    "add": "[44804BD10,F36679BC,8607492C]",
    "cost": "2,353"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-575",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[D8F6DF54,DB301E72,E491B04D]",
    "add": "[049BCC94,8164C899,7B500D6B]",
    "cost": "11,988"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-576",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[7B415249,DB07BD67,79A6C1CA]",
    "add": "[BD2DB5FE,048701CE]",
    "cost": "1,289"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-577",
    "status": "TRUE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[ACF7D73A,DB07BD68]",
    "add": "[BB20277D,17B2D2B7,C2F62DAA]",
    "cost": "5,343"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-578",
    "status": "FALSE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[4E1CFAD9,C36BF120]",
    "add": "[9C01A6AA,9DD6903A,B688BAF11]",
    "cost": "3,178"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-579",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[BAF9CD1A,F55885BD]",
    "add": "[85ECBAF11,74B98564,5F3AFC31]",
    "cost": "11,126"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-580",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[4E1CFAD9,F55885BD,BD23BA18,4E1CFAD13,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-581",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[8164C892,DC16E9E9]",
    "add": "[19A12B26,DB301E78,7B500D6B]",
    "cost": "4,866"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-582",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[ACF7D73A,11102F48,11102F49]",
    "add": "[4CD72DAE,DC16E9E15,F1C1E754]",
    "cost": "2,493"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-583",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[DF6676C9,7B500D6B,DB301E73]",
    "add": "[7B500D6B,85ECBAF12,5F3AFC31]",
    "cost": "8,646"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-584",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[DB07BD66,F55885BD,5EDCDAE5,ACF7D73A]",
    "add": "[200EA71C,11660B2E,7B500D6B]",
    "cost": "1,646"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-585",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[D9345860,9EAD0C20]",
    "add": "[4CD72DAE,17B2D2B7,7A8CC6BD]",
    "cost": "5,251"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-586",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[7A8CC6BD,41097027,CD9A49D2]",
    "add": "[3CA906F15,9DD6903A,DF6676C17]",
    "cost": "8,755"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-587",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[7B415249,7B415250,05BD5A7D]",
    "add": "[19A12B26,DB301E78]",
    "cost": "5,251"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-588",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[0AB87239,93D87235]",
    "add": "[7B500D6B,02A8E863,7B415257]",
    "cost": "1,289"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-589",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[200EA71C,14968630,DF6676C11]",
    "add": "[58D36D85,11102F54,EB58F898]",
    "cost": "2,493"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-590",
    "status": "TRUE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[F2EF4025,11102F48,E491B04D]",
    "add": "[14968630,5EDCDAE10,A0E7F6C9]",
    "cost": "11,988"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-591",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[44804BD4,85ECBAF6,9DD6903A]",
    "add": "[F36679BC,DB07BD73]",
    "cost": "11,656"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-592",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[14968630,E80AAE4A,F55885BD]",
    "add": "[F1C1E752,D8F6DF61,099FDC56]",
    "cost": "1,777"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-593",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[7A8CC6BD,44804BD5,CCD70D1D]",
    "add": "[D8F6DF60,11102F54,8607492C]",
    "cost": "1,777"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-594",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[4CD72DAE,41097027,3D26994F]",
    "add": "[9C01A6AA,DC16E9E15,542BBC0E]",
    "cost": "1,646"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-595",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[7B415249,065DB72B]",
    "add": "[ACF7D73A,F1C1E754]",
    "cost": "8,781"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-596",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[7B415249,74B98558,F2EF4027,ACF7D73A]",
    "add": "[A0E7F6C7,D8F6DF61,C2F62DAA,4E1CFAD18,F2EF4035]",
    "cost": "10,752"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-597",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[0ED43D83,C36BF120]",
    "add": "[ACF7D73A,42A1729B]",
    "cost": "7,182"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-598",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[542BBC0E,D33377F6,F04529EA]",
    "add": "[14968630,11102F54,F55885BD]",
    "cost": "5,251"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-599",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[88F245C1,02A8E857,CD9A49D2]",
    "add": "[85ECBAF11,B688BAF10,542BBC0E]",
    "cost": "5,270"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-600",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[048701CE,B688BAF4,C36BF121,ACF7D73A,ACF7D73A]",
    "add": "[14968630,E02AF8C11,C36BF127]",
    "cost": "8,400"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-601",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[049BCC88,F55885BD,E80AAE4A]",
    "add": "[049BCC94,85ECBAF12,B688BAF11]",
    "cost": "4,364"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-602",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[ACF7D73A,330FF32E,DB07BD68]",
    "add": "[17B2D2B6,85ECBAF12,E0A95BBC]",
    "cost": "3,862"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-603",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[9FC8D91A,C36BF120,17B2D2B2]",
    "add": "[F2EF4031,E02AF8C11,EB58F898]",
    "cost": "8,781"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-604",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[44804BD4,02A8E857,DF6676C11]",
    "add": "[DB301E77,330FF32E,DB301E79]",
    "cost": "2,493"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-605",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[F04529EA,DB301E72,F2EF4027,D9345863]",
    "add": "[F36679BC,7B500D6B]",
    "cost": "5,251"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-606",
    "status": "TRUE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[A0E7F6C1,4E1CFAD10,CD9A49D2]",
    "add": "[02A8E862,74B98564,F1C1E754]",
    "cost": "3,761"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-607",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[14968630,B688BAF4,7B500D6B,ACF7D73A]",
    "add": "[CDA15982,DC16E9E15,E80AAE4A]",
    "cost": "11,126"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-608",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[049BCC88,DB301E72,79A6C1CA]",
    "add": "[66AB5D2E,CDA15983,F55885BD]",
    "cost": "1,169"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-609",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[11660B2E,02A8E857,F2EF4027]",
    "add": "[F36679BC,9DD6903A,58D36D87]",
    "cost": "6,563"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-610",
    "status": "FALSE",
    "surgeon": "Dr. Lina Krasnova",
    "remove": "[A0E7F6C1,BD2DB5FE,E491B04D,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-611",
    "status": "TRUE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[4CD72DAE,8164C893,CD9A49D2,5EDCDAE6]",
    "add": "[17B2D2B6,DB07BD73,42A1729B]",
    "cost": "3,407"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-612",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[11660B2E,22633EF8,542BBC0E]",
    "add": "[D9345866,DB07BD73,11660B2E]",
    "cost": "11,656"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-613",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[8164C892,42A1729B,8607492C,ACF7D73A]",
    "add": "[44804BD10,E02AF8C11,C71B9809,200EA71C,F2EF4035,C36BF130]",
    "cost": "9,954"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-614",
    "status": "TRUE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[200EA71C,7B415250,F2EF4027]",
    "add": "[F1C1E752,B21A9DE9,58D36D87]",
    "cost": "7,052"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-615",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[C36BF119,DF6676C11]",
    "add": "[A0E7F6C7,44804BD11]",
    "cost": "1,289"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-616",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[19A12B20,9EAD0C20,C36BF121]",
    "add": "[3D26994F,17B2D2B7,CCD70D1D]",
    "cost": "3,407"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-617",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[9DD6903A,02A8E857,17B2D2B2]",
    "add": "[A0E7F6C7,7B415256,7B500D6B]",
    "cost": "11,430"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-618",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[D8F6DF54,A0E7F6C2,F55885BD]",
    "add": "[14968630,17B2D2B7,A0E7F6C9]",
    "cost": "1,783"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-619",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[4E1CFAD9,11102F48]",
    "add": "[74B98563,11660B2E,E80AAE4A]",
    "cost": "8,755"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-620",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[542BBC0E,88F245C2,8607492C]",
    "add": "[CDA15982,8164C899,E80AAE4A]",
    "cost": "5,088"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-621",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[7B415249,F55885BD,CDA15978]",
    "add": "[542BBC0E,E02AF8C11,11102F55,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-622",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[9FC8D91A,9FC8D91A,F1C1E748]",
    "add": "[85ECBAF11,048701CE,F2EF4035]",
    "cost": "5,251"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-623",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[CCD70D1D,85ECBAF6,DF6676C11]",
    "add": "[4CD72DAE,8164C900]",
    "cost": "8,538"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-624",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[E0A95BBC,958C4CED]",
    "add": "[9C01A6AA,44804BD11,0ED43D91]",
    "cost": "8,781"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-625",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[4E1CFAD9,9FC8D91A,BD23BA18]",
    "add": "[19A12B26,E0A95BBC,7B415257]",
    "cost": "6,563"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-626",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[E491B04D,065DB72B,CB6FF511]",
    "add": "[85ECBAF11,0ED43D90]",
    "cost": "3,291"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-627",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[19A12B20,7B415250,ACF7D73A]",
    "add": "[BB20277D,200EA71C,11660B2E]",
    "cost": "11,430"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-628",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[200EA71C,14968630,CDA15978]",
    "add": "[4E1CFAD15,C2F62DAA]",
    "cost": "5,727"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-629",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[7A8CC6BD,7B415250,E491B04D]",
    "add": "[F413914D,D8F6DF61,58D36D87]",
    "cost": "3,356"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-630",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[7B415249,1EBC5379,D8F6DF56]",
    "add": "[D9345866,D33377F12]",
    "cost": "1,289"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-631",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[D33377F5,4E1CFAD10,F04529EA]",
    "add": "[EB58F896,E02AF8C11,42A1729B]",
    "cost": "8,565"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-632",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[4E1CFAD9,85ECBAF6,F2EF4027,DB301E75,ACF7D73A]",
    "add": "[ACF7D73A,CD9A49D7,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-633",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[88F245C1,2C1769CF]",
    "add": "[9202537,D8F6DF61,E80AAE4A,A0E7F6C10]",
    "cost": "1,196"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-634",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[CCD70D1D,E80AAE4A,8607492C,DC16E9E12]",
    "add": "[9202537,DB07BD73,C2F62DAA,E0A95BBC]",
    "cost": "1,169"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-635",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[D8F6DF54,D8F6DF55,D8F6DF56]",
    "add": "[02A8E862,DB07BD73,F04529EA]",
    "cost": "7,708"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-636",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[58D36D79,8164C893,CD9A49D2]",
    "add": "[A0E7F6C7,D8F6DF61,F55885BD]",
    "cost": "7,052"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-637",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[0ED43D83,11102F48,7A8CC6BD]",
    "add": "[F36679BC,CCD70D1D,9DD6903A,7A8CC6BD]",
    "cost": "2,493"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-638",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[42A1729B,F55885BD,7B500D6B,ACF7D73A,DB301E75]",
    "add": "[74B98563,DB07BD73,8607492C,74B98566,F2EF4035,C36BF130]",
    "cost": "7,182"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-639",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[4E1CFAD9,599EA2B4,2C1769CF]",
    "add": "[7B415255,17B2D2B7,7B415257]",
    "cost": "11,988"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-640",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[DC16E9E8,E80AAE4A,CB6FF511]",
    "add": "[14968630,D33377F12]",
    "cost": "11,752"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-641",
    "status": "TRUE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[ACF7D73A,330FF32E]",
    "add": "[4CD72DAE,85ECBAF12,C71B9809]",
    "cost": "11,656"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-642",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[88F245C1,958C4CED,CCD70D1D]",
    "add": "[200EA71C,DF6676C16,58D36D87]",
    "cost": "11,833"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-643",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[200EA71C,1EBC5379]",
    "add": "[19A12B26,065DB72B,DF6676C17]",
    "cost": "5,251"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-644",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[7B415249,66AB5D2E,DB07BD68]",
    "add": "[2C1769CF,065DB72B,8607492C]",
    "cost": "5,708"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-645",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[ACF7D73A,E0A5E11A,E491B04D]",
    "add": "[200EA71C,7A8CC6BD]",
    "cost": "3,862"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-646",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[D33377F5,1EBC5379,17B2D2B2]",
    "add": "[200EA71C,5F3AFC30,CCD70D1D,A0E7F6C10]",
    "cost": "3,407"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-647",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[D33377F5,02A8E857,ACF7D73A,ACF7D73A]",
    "add": "[F1C1E752,58D36D86,CCD70D1D,A0E7F6C10,E0A95BBC]",
    "cost": "11,430"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-648",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,D8F6DF61,099FDC56,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "3,862"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-649",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[D8F6DF54,E80AAE4A,CDA15978]",
    "add": "[14968630,542BBC0E,C36BF127]",
    "cost": "2,743"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-650",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[D9345860,D8F6DF55,DB301E73]",
    "add": "[E491B04D,B688BAF10,099FDC56,A0E7F6C10]",
    "cost": "1,289"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-651",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[048701CE,BD2DB5FE,F2EF4027]",
    "add": "[F36679BC,74B98564,02A8E864]",
    "cost": "5,343"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-652",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[C36BF119,9FC8D91A,542BBC0E]",
    "add": "[88F245C7,DB07BD73,7670C5F13]",
    "cost": "1,646"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-653",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[ACF7D73A,DB07BD67,14968630]",
    "add": "[A0E7F6C7,B21A9DE9,42A1729B]",
    "cost": "1,289"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-654",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[4E1CFAD9,958C4CED,BD23BA18,ACF7D73A]",
    "add": "[F36679BC,E02AF8C11,867038F10,A0E7F6C10]",
    "cost": "8,538"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-655",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[CCD70D1D,2C1769CF]",
    "add": "[ACF7D73A,DC16E9E15,42A1729B]",
    "cost": "7,182"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-656",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[19A12B20,7B415250,ACF7D73A]",
    "add": "[F1C1E752,D8F6DF61,C36BF127,E0A95BBC]",
    "cost": "8,755"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-657",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[DF6676C9,88F245C2,74B98559]",
    "add": "[6E657E27,330FF32E,F55885BD]",
    "cost": "4,866"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-658",
    "status": "TRUE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[4E1CFAD9,DC16E9E9,BD23BA18]",
    "add": "[864437DD,CCD70D1D,66AB5D2E]",
    "cost": "9,954"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-659",
    "status": "TRUE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[CCD70D1D,44804BD5]",
    "add": "[ACF7D73A,9DD6903A]",
    "cost": "11,656"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-660",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[4E1CFAD9,F55885BD,DB301E73]",
    "add": "[4FF22348,11660B2E,CCD70D1D]",
    "cost": "8,781"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-661",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[F2EF4025,14968630,5EDCDAE5,5EDCDAE6]",
    "add": "[14968630,CCD70D1D,CCD70D1D]",
    "cost": "7,052"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-662",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[DF6676C9,F55885BD,8607492C]",
    "add": "[4FF22348,58D36D86,CCD70D1D,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-663",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[DC16E9E8,E80AAE4A,CCD70D1D]",
    "add": "[4FF22348,200EA71C]",
    "cost": "6,563"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-664",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[C36BF119,7B500D6B,17B2D2B2]",
    "add": "[4CD72DAE,EB58F897,17B2D2B8]",
    "cost": "9,466"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-665",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[9DD6903A,C36BF120,DF6676C11]",
    "add": "[85ECBAF11,58D36D86,F1C1E754]",
    "cost": "6,563"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-666",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[E0A95BBC,4E1CFAD10,7A8CC6BD]",
    "add": "[D8F6DF60,DB07BD73]",
    "cost": "2,353"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-667",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[E80AAE4A,9EAD0C20,CD9A49D2]",
    "add": "[3D26994F,200EA71C,542BBC0E]",
    "cost": "3,761"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-668",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[4E1CFAD9,F55885BD]",
    "add": "[17B2D2B6,9DD6903A]",
    "cost": "11,656"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-669",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[7B415249,88F245C2,17B2D2B2]",
    "add": "[CDA15982,11660B2E,7B415257]",
    "cost": "9,466"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-670",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[E491B04D,F55885BD,EB58F892,ACF7D73A]",
    "add": "[049BCC94,02A8E864,200EA71C]",
    "cost": "8,781"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-671",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[44804BD4,D33377F6,44804BD6]",
    "add": "[02A8E862,D33377F12,7A8CC6BD,66AB5D2E]",
    "cost": "1,783"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-672",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[B688BAF3]",
    "add": "[44804BD10,44804BD11]",
    "cost": "7,052"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-673",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[B688BAF3,7B415250,DC16E9E11]",
    "add": "[A0E7F6C7,11660B2E]",
    "cost": "3,622"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-674",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[542BBC0E,BAF9CD1A,17B2D2B2]",
    "add": "[F2EF4031,7B415256,B688BAF11]",
    "cost": "3,356"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-675",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[ACF7D73A,9FC8D91A,F36679BC]",
    "add": "[E0A5E11A,8164C899,867038F10]",
    "cost": "4,364"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-676",
    "status": "FALSE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[F2EF4025,F55885BD,CCD70D1D,4E1CFAD13]",
    "add": "[85ECBAF11,8164C899,42A1729B]",
    "cost": "2,743"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-677",
    "status": "TRUE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[BAF9CD1A,7B415250]",
    "add": "[14968630,17B2D2B7,0ED43D91]",
    "cost": "5,708"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-678",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[7B415249,02A8E857,CD9A49D2]",
    "add": "[7B415255,1EBC5385,099FDC56]",
    "cost": "11,126"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-679",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[0AB87239,85ECBAF6,17B2D2B2,7A8CC6BD]",
    "add": "[F36679BC,542BBC0E]",
    "cost": "7,052"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-680",
    "status": "FALSE",
    "surgeon": "Dr. David Cohen",
    "remove": "[42A1729B,9DD6903A]",
    "add": "[DB301E77,DB301E78,3CA906F17]",
    "cost": "5,343"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-681",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[66AB5D2E,74B98559]",
    "add": "[7B500D6B,7B415256,C36BF127]",
    "cost": "3,178"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-682",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[E0A95BBC,02A8E857,2C1769CF]",
    "add": "[ACF7D73A,E02AF8C11,F1C1E754,74B98566]",
    "cost": "1,289"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-683",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[88F245C1,9FC8D91A,CCD70D1D]",
    "add": "[9C01A6AA,DC16E9E15]",
    "cost": "5,088"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-684",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[B688BAF3,17B2D2B2]",
    "add": "[88F245C7,C71B9808]",
    "cost": "6,784"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-685",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[19A12B20,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,DC16E9E15,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-686",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[88F245C1,14968630,ACF7D73A]",
    "add": "[049BCC94,17B2D2B7]",
    "cost": "8,755"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-687",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[E0A95BBC,958C4CED]",
    "add": "[4E1CFAD15,E02AF8C11,42A1729B]",
    "cost": "4,364"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-688",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A]",
    "add": "[DF6676C15,E02AF8C11,F04529EA,BD2DB5FE,CDA15986]",
    "cost": "11,656"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-689",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[9DD6903A,1EBC5379,7B500D6B]",
    "add": "[6E657E27,17B2D2B8]",
    "cost": "3,761"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-690",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[F04529EA,C36BF120,E491B04D]",
    "add": "[ACF7D73A,0ED43D90,05BD5A7D]",
    "cost": "5,299"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-691",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[049BCC88,E80AAE4A]",
    "add": "[CDA15982,DB301E79]",
    "cost": "2,744"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-692",
    "status": "FALSE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[DF6676C9,F55885BD,ACF7D73A,ACF7D73A]",
    "add": "[F2EF4031,E02AF8C11,EB58F898]",
    "cost": "3,291"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-693",
    "status": "TRUE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[ACF7D73A,E0A5E11A,93D87235]",
    "add": "[14968630,74B98564,E0A95BBC]",
    "cost": "3,291"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-694",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[4CD72DAE,A0E7F6C2,B688BAF5]",
    "add": "[E0A95BBC,E02AF8C11]",
    "cost": "9,831"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-695",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[7D206E43,44804BD5]",
    "add": "[542BBC0E,DC16E9E15,5F3AFC31]",
    "cost": "10,043"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-696",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[048701CE,599EA2B4,542BBC0E,4E1CFAD13,ACF7D73A]",
    "add": "[14968630,85ECBAF12,542BBC0E,200EA71C,CB6FF519]",
    "cost": "1,783"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-697",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[B21A9DE2,85ECBAF6,8607492C]",
    "add": "[E0A5E11A,1EBC5386]",
    "cost": "11,430"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-698",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[7D206E43,11102F48,F04529EA]",
    "add": "[85ECBAF11,5F3AFC30,42A1729B,74B98566]",
    "cost": "5,251"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-699",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[200EA71C,F55885BD,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]",
    "add": "[9C01A6AA,85ECBAF12,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-700",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[F2EF4025,42A1729B,11660B2E]",
    "add": "[66AB5D2E,4FF22350]",
    "cost": "3,370"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-701",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[F2EF4025,E80AAE4A,DB301E73]",
    "add": "[864437DD,0ED43D90,DF6676C17]",
    "cost": "5,088"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-702",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[542BBC0E,DB07BD67,C36BF121]",
    "add": "[17B2D2B6,542BBC0E,3CA906F17]",
    "cost": "11,752"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-703",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[200EA71C,D8F6DF55,E491B04D]",
    "add": "[E491B04D,11660B2E,17B2D2B8]",
    "cost": "1,169"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-704",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[4E1CFAD9,14968630,F2EF4027,C36BF122]",
    "add": "[44804BD10,11102F54,CCD70D1D,200EA71C,F2EF4035,C36BF130]",
    "cost": "5,251"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-705",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[CCD70D1D,7B500D6B,DF6676C11]",
    "add": "[02A8E862,D8F6DF61,58D36D87]",
    "cost": "1,783"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-706",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[42A1729B,8164C893,CB6FF511]",
    "add": "[F413914D,9DD6903A,BAF9CD1A]",
    "cost": "3,291"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-707",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[D9345860,88F245C2,E491B04D]",
    "add": "[19A12B26,17B2D2B7,9DD6903A]",
    "cost": "7,708"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-708",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[200EA71C,F55885BD,F55885BD,ACF7D73A,DB301E75]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-709",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[66AB5D2E,C36BF120,05BD5A7D]",
    "add": "[049BCC94,DC16E9E15,A0E7F6C9]",
    "cost": "5,727"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-710",
    "status": "TRUE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[E0A95BBC,E0A5E11A,CD9A49D2]",
    "add": "[4FF22348,7B415256,099FDC56]",
    "cost": "5,259"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-711",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[88F245C1,E80AAE4A,E491B04D]",
    "add": "[DF6676C15,5F3AFC30,E80AAE4A,A0E7F6C10,F2EF4035]",
    "cost": "7,567"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-712",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[7D206E43,DC16E9E9,3D26994F,DB301E75]",
    "add": "[CDA15982,CCD70D1D,CCD70D1D]",
    "cost": "3,356"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-713",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[7D206E43,11102F48,93D87235,D9345863]",
    "add": "[D33377F11,5EDCDAE10,7A8CC6BD]",
    "cost": "2,933"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-714",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[CCD70D1D,330FF32E,F04529EA]",
    "add": "[BD2DB5FE,93D87240,0ED43D91]",
    "cost": "1,646"
  },
  {
    "id": "PR-034",
    "preferenceID": "PREF-ID-715",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[7D206E43,7B415250,D33377F9]",
    "add": "[CDA15982,A0E7F6C8,9FC8D91A]",
    "cost": "5,727"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-716",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[7A8CC6BD]",
    "add": "[02A8E862,85ECBAF12,EB58F898]",
    "cost": "5,299"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-717",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[DC16E9E8]",
    "add": "[4FF22348,B688BAF10,17B2D2B8]",
    "cost": "2,353"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-718",
    "status": "FALSE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[ACF7D73A,DC16E9E9,542BBC0E,D9345863]",
    "add": "[CCD70D1D,85ECBAF12,C2F62DAA]",
    "cost": "8,400"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-719",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[44804BD4,DB07BD67,542BBC0E]",
    "add": "[BB20277D,93D87240,CCD70D1D,A0E7F6C10]",
    "cost": "1,777"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-720",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[44804BD4,C36BF120,E491B04D,099FDC51]",
    "add": "[2C1769CF,CCD70D1D,BAF9CD1A]",
    "cost": "7,182"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-721",
    "status": "TRUE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[79A6C1CA,E0A5E11A,2C1769CF]",
    "add": "[85ECBAF11,7B415256,79FC1E29]",
    "cost": "4,757"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-722",
    "status": "TRUE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[7B415249,66AB5D2E]",
    "add": "[049BCC94,200EA71C,E80AAE4A]",
    "cost": "3,356"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-723",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[F2EF4025,E80AAE4A]",
    "add": "[4FF22348,A0E7F6C8,DF6676C17]",
    "cost": "8,538"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-724",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[4E1CFAD9,F55885BD,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[88F245C7,E02AF8C11,CCD70D1D,A0E7F6C10]",
    "cost": "1,783"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-725",
    "status": "TRUE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[14968630,66AB5D2E,BD2DB5FE]",
    "add": "[F36679BC,3D26994F,F1C1E754]",
    "cost": "9,831"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-726",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[049BCC88,F55885BD]",
    "add": "[85ECBAF11,DB07BD73,B688BAF11]",
    "cost": "4,803"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-727",
    "status": "TRUE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[ACF7D73A,A0E7F6C2,DB07BD68]",
    "add": "[2C1769CF,048701CE]",
    "cost": "2,933"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-728",
    "status": "TRUE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[DF6676C9,7B415250,DF6676C11]",
    "add": "[F36679BC,A0E7F6C8,CCD70D1D,66AB5D2E]",
    "cost": "2,353"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-729",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[14968630,F55885BD,EB58F892,C36BF122]",
    "add": "[D33377F11,5EDCDAE10,CCD70D1D,F2EF4035]",
    "cost": "8,646"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-730",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[E80AAE4A,66AB5D2E,9DD6903A]",
    "add": "[F36679BC,11660B2E,542BBC0E]",
    "cost": "5,343"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-731",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[048701CE,F55885BD,2C1769CF]",
    "add": "[542BBC0E,7B415256,11102F55]",
    "cost": "4,866"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-732",
    "status": "TRUE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[7A8CC6BD,14968630,F2EF4027]",
    "add": "[2C1769CF,1EBC5385]",
    "cost": "8,565"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-733",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[7A8CC6BD,7B415250]",
    "add": "[F36679BC,0ED43D90,E0A95BBC,A0E7F6C10,E0A95BBC]",
    "cost": "11,833"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-734",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[58D36D79,330FF32E,BD2DB5FE]",
    "add": "[14968630,DB301E78,66AB5D2E]",
    "cost": "2,744"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-735",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[7D206E43,DB07BD67,F2EF4027,ACF7D73A]",
    "add": "[F55885BD,11660B2E,44804BD12,4E1CFAD18]",
    "cost": "3,291"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-736",
    "status": "TRUE",
    "surgeon": "Dr. John Smith",
    "remove": "[0ED43D83,41097027,C36BF121]",
    "add": "[BB20277D,17B2D2B7]",
    "cost": "2,933"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-737",
    "status": "TRUE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[CCD70D1D,E80AAE4A,DF6676C11]",
    "add": "[542BBC0E,8164C899,E80AAE4A,A0E7F6C10]",
    "cost": "6,784"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-738",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[4E1CFAD9,E0A5E11A,542BBC0E]",
    "add": "[D8F6DF60,A0E7F6C8]",
    "cost": "8,538"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-739",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[4E1CFAD9,049BCC89,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,74B98566,F2EF4035,85ECBAF16]",
    "cost": "1,783"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-740",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[D8F6DF54,BAF9CD1A,5EDCDAE5]",
    "add": "[85ECBAF11,E02AF8C11,58D36D87]",
    "cost": "5,251"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-741",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[79A6C1CA,E80AAE4A,DB07BD68]",
    "add": "[E491B04D,1EBC5385,542BBC0E]",
    "cost": "4,109"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-742",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[19A12B20,DB301E72,099FDC51]",
    "add": "[3CA906F15,7B500D6B]",
    "cost": "8,538"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-743",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[048701CE,7B500D6B,DB07BD68]",
    "add": "[4FF22348,44804BD11,C36BF127]",
    "cost": "3,356"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-744",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[0ED43D83,85ECBAF6,C36BF121]",
    "add": "[02A8E862,E0A95BBC]",
    "cost": "5,251"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-745",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[D33377F5,42A1729B,05BD5A7D]",
    "add": "[6E657E27,D33377F12,E80AAE4A]",
    "cost": "8,538"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-746",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[4E1CFAD9,F2EF4027,C36BF122,D8F6DF58,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,542BBC0E,C36BF130]",
    "cost": "4,803"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-747",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[DF6676C9,9FC8D91A,93D87235]",
    "add": "[049BCC94,F36679BC,CCD70D1D]",
    "cost": "2,744"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-748",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[D8F6DF54,3D26994F,CDA15978]",
    "add": "[A0E7F6C7,E0A95BBC,DF6676C17]",
    "cost": "4,364"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-749",
    "status": "TRUE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[049BCC88,8164C893]",
    "add": "[9202537,D8F6DF61,0ED43D91]",
    "cost": "9,466"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-750",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[E491B04D,A0E7F6C2]",
    "add": "[D9345866,A0E7F6C8,8164C900]",
    "cost": "7,182"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-751",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[14968630,D9345861,93D87235]",
    "add": "[14968630,E0A5E11A]",
    "cost": "3,178"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-752",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[DB07BD66,42A1729B,F2EF4027]",
    "add": "[4CD72DAE,542BBC0E,B688BAF11]",
    "cost": "1,777"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-753",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[5EDCDAE3,599EA2B4,3D26994F]",
    "add": "[D33377F11,B21A9DE9]",
    "cost": "11,988"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-754",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[66AB5D2E,02A8E857]",
    "add": "[EB58F896,5EDCDAE10]",
    "cost": "2,493"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-755",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[11660B2E,7B500D6B,5EDCDAE5,7A8CC6BD]",
    "add": "[6E657E27,9DD6903A,7A8CC6BD,F2EF4035]",
    "cost": "5,343"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-756",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[4E1CFAD9,66AB5D2E,BD23BA18,C36BF122]",
    "add": "[F2EF4031,CDA15983,5F3AFC31,F2EF4035]",
    "cost": "4,109"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-757",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[ACF7D73A,7B415250,8607492C]",
    "add": "[9202537,CCD70D1D,F55885BD]",
    "cost": "3,441"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-758",
    "status": "TRUE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[E491B04D,9DD6903A,F2EF4027]",
    "add": "[E0A5E11A,CCD70D1D,11102F55]",
    "cost": "7,351"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-759",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[0ED43D83,F36679BC]",
    "add": "[3D26994F,D8F6DF61,4FF22350]",
    "cost": "8,755"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-760",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[7B415249,D33377F6,F36679BC]",
    "add": "[F36679BC,E02AF8C11,8164C900]",
    "cost": "11,988"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-761",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[E80AAE4A,E80AAE4A,F2EF4027]",
    "add": "[542BBC0E,CCD70D1D,EB58F898]",
    "cost": "4,803"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-762",
    "status": "TRUE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[048701CE,DB07BD67,F2EF4027]",
    "add": "[E0A95BBC,7670C5F12,7670C5F13]",
    "cost": "6,784"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-763",
    "status": "FALSE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[0AB87239,D33377F6]",
    "add": "[ACF7D73A,D8F6DF61,7A8CC6BD]",
    "cost": "11,126"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-764",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[88F245C1,85ECBAF6,9C01A6AA]",
    "add": "[2C1769CF,74B98564]",
    "cost": "10,752"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-765",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[7B415249,E80AAE4A]",
    "add": "[85ECBAF11,17B2D2B7,02A8E864]",
    "cost": "9,661"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-766",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[9DD6903A,7B415250]",
    "add": "[864437DD,1EBC5385,7A8CC6BD,C36BF130]",
    "cost": "11,988"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-767",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[7A8CC6BD,02A8E857,E491B04D]",
    "add": "[9DD6903A,74B98564,F04529EA]",
    "cost": "8,646"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-768",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[D8F6DF54,11102F48,93D87235,ACF7D73A]",
    "add": "[E0A5E11A,A0E7F6C8,E0A95BBC]",
    "cost": "4,757"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-769",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[DC16E9E8,F55885BD]",
    "add": "[9202537,11102F54]",
    "cost": "5,251"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-770",
    "status": "FALSE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[58D36D79,DB301E72,E491B04D]",
    "add": "[BB20277D,C71B9808]",
    "cost": "3,178"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-771",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[11660B2E,74B98558,C36BF121]",
    "add": "[F2EF4031,93D87240,CCD70D1D,A0E7F6C10]",
    "cost": "11,656"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-772",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[4CD72DAE,599EA2B4,F04529EA]",
    "add": "[BB20277D,02A8E864,4E1CFAD18]",
    "cost": "3,407"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-773",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[11660B2E,9FC8D91A]",
    "add": "[E491B04D,58D36D86,F36679BC]",
    "cost": "3,356"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-774",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[D9345860,11660B2E]",
    "add": "[D9345866,E02AF8C11,42A1729B]",
    "cost": "4,757"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-775",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[44804BD4,F55885BD,DF6676C11,ACF7D73A]",
    "add": "[7B500D6B,E02AF8C11,11660B2E]",
    "cost": "6,563"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-776",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[CCD70D1D,E0A5E11A,8164C894]",
    "add": "[14968630,5EDCDAE10,02A8E864]",
    "cost": "3,370"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-777",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[4E1CFAD9,F55885BD,E491B04D,ACF7D73A]",
    "add": "[14968630,542BBC0E,EB58F898]",
    "cost": "1,783"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-778",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[542BBC0E,7B500D6B,7A8CC6BD]",
    "add": "[3D26994F,B688BAF11]",
    "cost": "3,178"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-779",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[542BBC0E,58D36D80,E491B04D]",
    "add": "[CCD70D1D,17B2D2B7,4FF22350]",
    "cost": "8,400"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-780",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[88F245C1,7B415250]",
    "add": "[049BCC94,5EDCDAE10]",
    "cost": "3,862"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-781",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[7B415249,F55885BD,F2EF4027,ACF7D73A,DB301E75]",
    "add": "[DB301E77,E02AF8C11,7A8CC6BD,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-782",
    "status": "TRUE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[200EA71C,B688BAF4,F2EF4027,DB301E75,ACF7D73A]",
    "add": "[7B415255,330FF32E,79FC1E29,A0E7F6C10,C36BF130]",
    "cost": "6,563"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-783",
    "status": "TRUE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[4CD72DAE,B688BAF4,C36BF121]",
    "add": "[049BCC94,58D36D87]",
    "cost": "7,567"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-784",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[4E1CFAD9,049BCC89,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[F36679BC,E02AF8C11,B688BAF11,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-785",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[44804BD4,F36679BC,11102F49]",
    "add": "[4FF22348,0ED43D90,E80AAE4A]",
    "cost": "2,353"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-786",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[DC16E9E8,11102F48,17B2D2B2]",
    "add": "[17B2D2B6,5EDCDAE10,D33377F13]",
    "cost": "11,430"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-787",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[42A1729B,D8F6DF55,8607492C]",
    "add": "[4CD72DAE,CD9A49D7,5F3AFC31]",
    "cost": "3,622"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-788",
    "status": "TRUE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[58D36D79,02A8E857,CDA15978,ACF7D73A,D8F6DF58]",
    "add": "[F413914D,D33377F12,7B415257,4E1CFAD18,F2EF4035,85ECBAF16]",
    "cost": "8,646"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-789",
    "status": "TRUE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[4E1CFAD9,C36BF120,3D26994F,DB301E75,7B415254]",
    "add": "[DF6676C15,E02AF8C11,CCD70D1D,A0E7F6C10,542BBC0E,85ECBAF16]",
    "cost": "1,783"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-790",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[049BCC88,8164C893]",
    "add": "[4CD72DAE,542BBC0E]",
    "cost": "7,052"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-791",
    "status": "TRUE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[DC16E9E8,DB301E72,05BD5A7D]",
    "add": "[9C01A6AA,542BBC0E]",
    "cost": "4,757"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-792",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-793",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[DF6676C9,02A8E857]",
    "add": "[BD2DB5FE,B688BAF10,DF6676C17]",
    "cost": "2,744"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-794",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[D8F6DF54,958C4CED,F1C1E748]",
    "add": "[2C1769CF,A0E7F6C9]",
    "cost": "9,831"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-795",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[F04529EA,330FF32E,5EDCDAE5,4FF22345]",
    "add": "[D8F6DF60,17B2D2B7,42A1729B]",
    "cost": "1,196"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-796",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[4E1CFAD9,C36BF120,7B500D6B,D33377F9,ACF7D73A]",
    "add": "[E491B04D,EB58F897,CCD70D1D,7A8CC6BD]",
    "cost": "1,169"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-797",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4E1CFAD15,0ED43D90,F55885BD,7A8CC6BD,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-798",
    "status": "TRUE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[049BCC88,F36679BC,C36BF121]",
    "add": "[E0A5E11A,D33377F12]",
    "cost": "8,989"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-799",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[542BBC0E,02A8E857,11102F49]",
    "add": "[ACF7D73A,5F3AFC30,A0E7F6C9]",
    "cost": "11,752"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-800",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[D33377F5,B688BAF4,2C1769CF]",
    "add": "[4CD72DAE,065DB72B,C71B9809]",
    "cost": "11,752"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-801",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[D8F6DF54,BAF9CD1A,2C1769CF,099FDC51,DC16E9E12,ACF7D73A]",
    "add": "[049BCC94,74B98564,EB58F898]",
    "cost": "3,356"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-802",
    "status": "FALSE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[88F245C1,1EBC5379,17B2D2B2,DC16E9E12]",
    "add": "[ACF7D73A,02A8E863,42A1729B]",
    "cost": "3,862"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-803",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[DC16E9E8,DB301E72,EB58F892]",
    "add": "[88F245C7,58D36D86]",
    "cost": "4,803"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-804",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[CCD70D1D,E80AAE4A,E80AAE4A,7A8CC6BD]",
    "add": "[DF6676C15,DF6676C16,F1C1E754,85ECBAF16]",
    "cost": "7,052"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-805",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[DC16E9E8,7B415250,8607492C]",
    "add": "[D9345866,8164C899,CCD70D1D,A0E7F6C10]",
    "cost": "3,441"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-806",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[B688BAF3,66AB5D2E,E491B04D]",
    "add": "[ACF7D73A,7B415256,7B415257]",
    "cost": "3,370"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-807",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[42A1729B,E0A5E11A]",
    "add": "[D33377F11,1EBC5385]",
    "cost": "1,646"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-808",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[A0E7F6C1,330FF32E,CDA15978]",
    "add": "[F1C1E752,74B98564]",
    "cost": "2,744"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-809",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[7A8CC6BD,14968630]",
    "add": "[CDA15982,4FF22349,CCD70D1D]",
    "cost": "3,761"
  },
  {
    "id": "PR-041",
    "preferenceID": "PREF-ID-810",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,7A8CC6BD,DB301E75,ACF7D73A]",
    "add": "[85ECBAF11,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-811",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[F04529EA,11102F48,F2EF4027,4FF22345]",
    "add": "[7B415255,542BBC0E,F55885BD,66AB5D2E]",
    "cost": "11,599"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-812",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[7B415249,9C01A6AA]",
    "add": "[D8F6DF60,200EA71C,58D36D87]",
    "cost": "11,656"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-813",
    "status": "FALSE",
    "surgeon": "Dr. Oluchi Emeka",
    "remove": "[E0A95BBC,42A1729B,11660B2E]",
    "add": "[85ECBAF11,E02AF8C11,17B2D2B8,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-814",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[4E1CFAD9,02A8E857,17B2D2B2]",
    "add": "[DB301E77,CDA15983,F1C1E754]",
    "cost": "8,781"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-815",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[7D206E43,74B98558,E491B04D]",
    "add": "[4FF22348,11660B2E,11102F55]",
    "cost": "1,783"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-816",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[79A6C1CA,C36BF120,2C1769CF]",
    "add": "[44804BD10,DC16E9E15,3CA906F17]",
    "cost": "1,783"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-817",
    "status": "TRUE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[4CD72DAE,85ECBAF6,F2EF4027]",
    "add": "[44804BD10,DC16E9E15,DF6676C17]",
    "cost": "3,761"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-818",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[C36BF119,3D26994F,DB07BD68]",
    "add": "[CCD70D1D,E02AF8C11,7B500D6B,F2EF4035]",
    "cost": "3,407"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-819",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[C36BF119,44804BD5,8607492C]",
    "add": "[6E657E27,200EA71C,58D36D87,200EA71C]",
    "cost": "1,196"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-820",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[7B415249,4E1CFAD10,F1C1E748]",
    "add": "[DB301E77,93D87240,8164C900]",
    "cost": "8,781"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-821",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[D33377F5,0ED43D84,EB58F892]",
    "add": "[D9345866,CCD70D1D,BD2DB5FE]",
    "cost": "9,831"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-822",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[F2EF4025,9DD6903A,11102F49]",
    "add": "[4FF22348,E02AF8C11,F55885BD]",
    "cost": "8,989"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-823",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[4E1CFAD9,9DD6903A,CCD70D1D]",
    "add": "[4FF22348,DF6676C16,17B2D2B8]",
    "cost": "1,783"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-824",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[7B415249,9EAD0C20,F04529EA]",
    "add": "[14968630,DB07BD73,0ED43D91]",
    "cost": "8,781"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-825",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[200EA71C,D33377F6,F2EF4027,ACF7D73A,DC16E9E12]",
    "add": "[14968630,B688BAF10,74B98565,7A8CC6BD,F2EF4035]",
    "cost": "1,289"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-826",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[19A12B20,14968630,F1C1E748]",
    "add": "[3CA906F15,A0E7F6C8,17B2D2B8]",
    "cost": "3,622"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-827",
    "status": "FALSE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[B21A9DE2,74B98558,E491B04D]",
    "add": "[F36679BC,B688BAF10,0ED43D91,200EA71C,CDA15986]",
    "cost": "8,755"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-828",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[B21A9DE2,1EBC5379,8607492C,5EDCDAE6]",
    "add": "[19A12B26,F36679BC,C2F62DAA,CB6FF519]",
    "cost": "1,783"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-829",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[4E1CFAD9,4E1CFAD10,E491B04D]",
    "add": "[A0E7F6C7,048701CE,EB58F898,C36BF130]",
    "cost": "11,752"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-830",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[88F245C1,E0A5E11A,C36BF121]",
    "add": "[D8F6DF60,D8F6DF61]",
    "cost": "2,493"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-831",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[ACF7D73A,F55885BD,93D87235]",
    "add": "[58D36D85,B688BAF10,E80AAE4A]",
    "cost": "1,777"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-832",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75]",
    "add": "[85ECBAF11,200EA71C,CCD70D1D,A0E7F6C10,F2EF4035]",
    "cost": "11,656"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-833",
    "status": "TRUE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[542BBC0E,BAF9CD1A,CD9A49D2]",
    "add": "[3CA906F15,BAF9CD1A]",
    "cost": "4,757"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-834",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[88F245C1,DB07BD67]",
    "add": "[9C01A6AA,4E1CFAD16,BAF9CD1A]",
    "cost": "7,182"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-835",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[CCD70D1D,BAF9CD1A,542BBC0E]",
    "add": "[9DD6903A,11102F54,C2F62DAA]",
    "cost": "11,563"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-836",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[ACF7D73A,BAF9CD1A,D8F6DF56]",
    "add": "[14968630,EB58F897]",
    "cost": "2,493"
  },
  {
    "id": "PR-001",
    "preferenceID": "PREF-ID-837",
    "status": "TRUE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[DC16E9E8,DB301E72]",
    "add": "[85ECBAF11,74B98564]",
    "cost": "3,291"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-838",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[542BBC0E]",
    "add": "[049BCC94]",
    "cost": "2,743"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-839",
    "status": "TRUE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[049BCC88,E80AAE4A,DF6676C11,ACF7D73A,DB301E75]",
    "add": "[F1C1E752,CDA15983,7A8CC6BD,4E1CFAD18,F2EF4035]",
    "cost": "11,126"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-840",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[5EDCDAE3,22633EF8,17B2D2B2]",
    "add": "[DB301E77,A0E7F6C8,17B2D2B8]",
    "cost": "8,755"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-841",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[BAF9CD1A,7B415250,3D26994F]",
    "add": "[F1C1E752,44804BD11,C71B9809]",
    "cost": "3,441"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-842",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[8164C892,E80AAE4A,E491B04D]",
    "add": "[6E657E27,58D36D86,02A8E864]",
    "cost": "1,783"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-843",
    "status": "FALSE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[4E1CFAD9,C36BF120,542BBC0E]",
    "add": "[4FF22348,D8F6DF61,58D36D87]",
    "cost": "7,351"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-844",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[19A12B20,9DD6903A,17B2D2B2]",
    "add": "[F2EF4031,F36679BC,C71B9809]",
    "cost": "11,656"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-845",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[ACF7D73A,66AB5D2E,2C1769CF,DB301E75]",
    "add": "[F413914D,CD9A49D7,3CA906F17,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-846",
    "status": "FALSE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[4E1CFAD9,E0A5E11A,099FDC51,DB301E75,ACF7D73A]",
    "add": "[DF6676C15,E02AF8C11,CCD70D1D,A0E7F6C10,CDA15986,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-847",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[5EDCDAE3,A0E7F6C2,F04529EA]",
    "add": "[D8F6DF60,74B98564,44804BD12]",
    "cost": "1,289"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-848",
    "status": "FALSE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[D33377F5]",
    "add": "[14968630,CCD70D1D]",
    "cost": "6,784"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-849",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[542BBC0E,7B415250,11102F49,D8F6DF58]",
    "add": "[2C1769CF,065DB72B,42A1729B]",
    "cost": "3,370"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-850",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A]",
    "add": "[BB20277D,E02AF8C11,42A1729B,A0E7F6C10]",
    "cost": "9,466"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-851",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[7A8CC6BD,BAF9CD1A,B688BAF5]",
    "add": "[E491B04D,74B98564,17B2D2B8]",
    "cost": "3,356"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-852",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[7D206E43,B688BAF4,5EDCDAE5,ACF7D73A]",
    "add": "[7B500D6B,7B415256,A0E7F6C10]",
    "cost": "1,646"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-853",
    "status": "TRUE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[7D206E43,F36679BC,E491B04D]",
    "add": "[7B415255,330FF32E,0ED43D91]",
    "cost": "5,727"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-854",
    "status": "FALSE",
    "surgeon": "Dr. Mohammed Farah",
    "remove": "[4E1CFAD9,330FF32E,74B98559]",
    "add": "[17B2D2B6,17B2D2B7,F55885BD]",
    "cost": "7,182"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-855",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[DC16E9E8,02A8E857,CD9A49D2]",
    "add": "[CCD70D1D,1EBC5386]",
    "cost": "3,370"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-856",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[BAF9CD1A,7B415250]",
    "add": "[66AB5D2E,065DB72B,F55885BD]",
    "cost": "2,353"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-857",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[ACF7D73A,66AB5D2E,17B2D2B2]",
    "add": "[3D26994F,DC16E9E15,EB58F898,CB6FF519]",
    "cost": "11,988"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-858",
    "status": "FALSE",
    "surgeon": "Dr. Elena Popova",
    "remove": "[DF6676C9,02A8E857,8607492C]",
    "add": "[F36679BC,DF6676C16,F1C1E754]",
    "cost": "2,743"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-859",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,E0A95BBC,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-860",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[D33377F5,02A8E857,E491B04D,5EDCDAE6]",
    "add": "[17B2D2B6,F36679BC,542BBC0E,542BBC0E]",
    "cost": "10,043"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-861",
    "status": "TRUE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[88F245C1,A0E7F6C2]",
    "add": "[049BCC94,CDA15983]",
    "cost": "11,599"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-862",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[85ECBAF5,2C1769CF,7A8CC6BD,DB301E75]",
    "add": "[4FF22348,F36679BC,7B500D6B]",
    "cost": "1,646"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-863",
    "status": "TRUE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[7B415249,41097027,7A8CC6BD]",
    "add": "[7B415255,DF6676C16,66AB5D2E]",
    "cost": "4,866"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-864",
    "status": "TRUE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[E491B04D,E0A5E11A,CB6FF511]",
    "add": "[BD2DB5FE,EB58F898]",
    "cost": "8,781"
  },
  {
    "id": "PR-002",
    "preferenceID": "PREF-ID-865",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[DB07BD66,E80AAE4A,17B2D2B2]",
    "add": "[CCD70D1D,17B2D2B7,58D36D87]",
    "cost": "11,430"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-866",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[E491B04D,B688BAF4,F04529EA]",
    "add": "[CCD70D1D,F36679BC]",
    "cost": "1,783"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-867",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[DC16E9E8,44804BD5,F2EF4027,D9345863]",
    "add": "[F2EF4031,5F3AFC30,CCD70D1D,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-868",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[542BBC0E,049BCC89,7B500D6B]",
    "add": "[7B415255,CDA15983,BAF9CD1A]",
    "cost": "1,783"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-869",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[BAF9CD1A,D8F6DF55,ACF7D73A]",
    "add": "[ACF7D73A,DC16E9E15,F1C1E754]",
    "cost": "2,353"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-870",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[7B415249,1EBC5379,B688BAF5]",
    "add": "[049BCC94,9DD6903A,EB58F898,A0E7F6C10]",
    "cost": "5,343"
  },
  {
    "id": "PR-040",
    "preferenceID": "PREF-ID-871",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[11660B2E,BD23BA18]",
    "add": "[02A8E862,58D36D86,02A8E864]",
    "cost": "3,356"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-872",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[0ED43D83,F55885BD,F36679BC]",
    "add": "[9DD6903A,5F3AFC30,7A8CC6BD]",
    "cost": "3,407"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-873",
    "status": "FALSE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[4E1CFAD9,85ECBAF6]",
    "add": "[14968630,3CA906F17]",
    "cost": "1,289"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-874",
    "status": "TRUE",
    "surgeon": "Dr. Ivan Castillo",
    "remove": "[7B415249,66AB5D2E,F04529EA]",
    "add": "[19A12B26,DB301E78,F36679BC]",
    "cost": "1,783"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-875",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[D9345860,E80AAE4A,F55885BD]",
    "add": "[4CD72DAE,93D87240]",
    "cost": "2,353"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-876",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[5EDCDAE3,88F245C2,3D26994F]",
    "add": "[58D36D85,A0E7F6C8]",
    "cost": "7,052"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-877",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[5EDCDAE3,B688BAF4,3D26994F]",
    "add": "[F36679BC,E0A95BBC,C2F62DAA]",
    "cost": "5,088"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-878",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[542BBC0E,42A1729B,ACF7D73A,ACF7D73A]",
    "add": "[7B415255,065DB72B,CCD70D1D,CDA15986]",
    "cost": "11,656"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-879",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[7A8CC6BD,E80AAE4A,F2EF4027,DB301E75]",
    "add": "[44804BD10,C71B9808,58D36D87]",
    "cost": "4,364"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-880",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[E0A95BBC,F36679BC]",
    "add": "[66AB5D2E,200EA71C,79FC1E29]",
    "cost": "3,291"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-881",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[4E1CFAD9,4E1CFAD10,F2EF4027]",
    "add": "[4FF22348,E02AF8C11,74B98566,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-882",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[ACF7D73A,F36679BC,17B2D2B2,7A8CC6BD,ACF7D73A]",
    "add": "[E0A5E11A,DB301E78,66AB5D2E]",
    "cost": "11,752"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-883",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[7A8CC6BD,5EDCDAE5]",
    "add": "[02A8E862,11660B2E]",
    "cost": "4,109"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-884",
    "status": "FALSE",
    "surgeon": "Dr. Samuel Kipkoech",
    "remove": "[542BBC0E,DC16E9E9,17B2D2B2]",
    "add": "[14968630,5F3AFC30,E80AAE4A,C36BF130]",
    "cost": "5,343"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-885",
    "status": "FALSE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[44804BD4,66AB5D2E,DB07BD68,5EDCDAE6,ACF7D73A]",
    "add": "[9C01A6AA,CDA15983,C71B9809]",
    "cost": "9,466"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-886",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[5EDCDAE3,DB301E72,CB6FF511]",
    "add": "[542BBC0E,DB301E78,6E657E29]",
    "cost": "11,126"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-887",
    "status": "FALSE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[19A12B20,A0E7F6C2,F2EF4027,ACF7D73A]",
    "add": "[D8F6DF60,B688BAF10,CCD70D1D,F36679BC,F2EF4035]",
    "cost": "8,781"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-888",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[7A8CC6BD,3D26994F,E80AAE4A]",
    "add": "[049BCC94,CDA15983,BAF9CD1A]",
    "cost": "8,565"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-889",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[D9345860,4E1CFAD10,CB6FF511]",
    "add": "[A0E7F6C7,7670C5F12,099FDC56]",
    "cost": "2,933"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-890",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[7A8CC6BD,CB6FF511]",
    "add": "[049BCC94,0ED43D91]",
    "cost": "11,430"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-891",
    "status": "TRUE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[4FF22342,DB301E72,B688BAF5]",
    "add": "[14968630,5F3AFC30,C36BF127]",
    "cost": "11,988"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-892",
    "status": "TRUE",
    "surgeon": "Dr. Alexander Petrov",
    "remove": "[E0A95BBC,C36BF120,C36BF121]",
    "add": "[17B2D2B6,EB58F897,B688BAF11]",
    "cost": "4,866"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-893",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[E0A95BBC,049BCC89,DF6676C11,D8F6DF58,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-894",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[0ED43D83,BAF9CD1A,BD23BA18,5EDCDAE6,D33377F9]",
    "add": "[7B500D6B,02A8E863,0ED43D91]",
    "cost": "4,109"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-895",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[44804BD4,DB301E72,F1C1E748]",
    "add": "[049BCC94,44804BD11,A0E7F6C9]",
    "cost": "8,565"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-896",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[F2EF4025,41097027,5EDCDAE6]",
    "add": "[7B500D6B,330FF32E,EB58F898]",
    "cost": "7,351"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-897",
    "status": "TRUE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[14968630,A0E7F6C2,C36BF121]",
    "add": "[85ECBAF11,C71B9808,DF6676C17,7A8CC6BD]",
    "cost": "11,833"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-898",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[7B415249,42A1729B,17B2D2B2]",
    "add": "[049BCC94,DB301E78,42A1729B]",
    "cost": "11,126"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-899",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[7B415249,E80AAE4A,74B98559]",
    "add": "[4E1CFAD15,D8F6DF61,3CA906F17]",
    "cost": "11,656"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-900",
    "status": "TRUE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[C36BF119,E491B04D]",
    "add": "[58D36D85,200EA71C,7670C5F13]",
    "cost": "2,933"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-901",
    "status": "FALSE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[7D206E43,BAF9CD1A,CB6FF511,D9345863]",
    "add": "[200EA71C,DF6676C16,7B415257]",
    "cost": "1,783"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-902",
    "status": "FALSE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[F2EF4025,1EBC5379,11102F49]",
    "add": "[3CA906F15,11102F54,7B500D6B,F2EF4035]",
    "cost": "11,126"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-903",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[E491B04D,DC16E9E9,17B2D2B2]",
    "add": "[864437DD,85ECBAF12]",
    "cost": "5,270"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-904",
    "status": "FALSE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[7A8CC6BD,7B500D6B,C36BF121]",
    "add": "[88F245C7,D8F6DF61,79FC1E29]",
    "cost": "2,493"
  },
  {
    "id": "PR-045",
    "preferenceID": "PREF-ID-905",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[A0E7F6C1,E0A5E11A,7A8CC6BD]",
    "add": "[66AB5D2E,11102F54,DF6676C17]",
    "cost": "5,088"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-906",
    "status": "FALSE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[E80AAE4A,D8F6DF55,542BBC0E]",
    "add": "[A0E7F6C7,5F3AFC30,F36679BC]",
    "cost": "5,251"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-907",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[049BCC88,7B500D6B,CB6FF511]",
    "add": "[85ECBAF11,58D36D86,4FF22350]",
    "cost": "5,088"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-908",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[D8F6DF54,958C4CED,F55885BD]",
    "add": "[14968630,02A8E863,867038F10]",
    "cost": "9,466"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-909",
    "status": "FALSE",
    "surgeon": "Dr. Aarav Patel",
    "remove": "[200EA71C,049BCC89,2C1769CF,C36BF122]",
    "add": "[4FF22348,A0E7F6C8,11660B2E,BD2DB5FE,F2EF4035,85ECBAF16]",
    "cost": "1,783"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-910",
    "status": "FALSE",
    "surgeon": "Dr. David Cohen",
    "remove": "[7D206E43,E80AAE4A,F55885BD]",
    "add": "[2C1769CF,330FF32E]",
    "cost": "3,407"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-911",
    "status": "FALSE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[8164C892,E0A5E11A,93D87235,DB301E75]",
    "add": "[9C01A6AA,200EA71C,8607492C]",
    "cost": "3,178"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-912",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[7B415249,E80AAE4A,F2EF4027]",
    "add": "[14968630,7670C5F12,42A1729B]",
    "cost": "4,109"
  },
  {
    "id": "PR-024",
    "preferenceID": "PREF-ID-913",
    "status": "TRUE",
    "surgeon": "Dr. John Smith",
    "remove": "[58D36D79,F36679BC]",
    "add": "[02A8E862,9DD6903A]",
    "cost": "9,954"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-914",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[4CD72DAE,22633EF8,E491B04D]",
    "add": "[3CA906F15,17B2D2B7,17B2D2B8]",
    "cost": "1,646"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-915",
    "status": "FALSE",
    "surgeon": "Dr. Nadia Hassan",
    "remove": "[200EA71C,D9345861,E491B04D,C36BF122]",
    "add": "[BD2DB5FE,D8F6DF61,BAF9CD1A]",
    "cost": "3,356"
  },
  {
    "id": "PR-022",
    "preferenceID": "PREF-ID-916",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[F2EF4025,BAF9CD1A,74B98559]",
    "add": "[14968630,C71B9808,BAF9CD1A,CB6FF519]",
    "cost": "5,088"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-917",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[66AB5D2E,02A8E857]",
    "add": "[E0A5E11A,065DB72B,F1C1E754]",
    "cost": "1,783"
  },
  {
    "id": "PR-012",
    "preferenceID": "PREF-ID-918",
    "status": "FALSE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[F2EF4025,DB07BD67]",
    "add": "[4E1CFAD15,330FF32E,02A8E864]",
    "cost": "1,289"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-919",
    "status": "FALSE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[D33377F5,7A8CC6BD]",
    "add": "[85ECBAF11,4E1CFAD16,3CA906F17]",
    "cost": "11,430"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-920",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[CCD70D1D,3D26994F,DB07BD68]",
    "add": "[44804BD10,E0A95BBC]",
    "cost": "9,466"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-921",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[7B415249,DB301E72,F2EF4027]",
    "add": "[F36679BC,11102F54,1EBC5386]",
    "cost": "4,364"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-922",
    "status": "FALSE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[4E1CFAD9,41097027,ACF7D73A,D33377F9,ACF7D73A]",
    "add": "[F1C1E752,E0A95BBC,C36BF130]",
    "cost": "11,656"
  },
  {
    "id": "PR-005",
    "preferenceID": "PREF-ID-923",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[14968630,DB301E72,8607492C]",
    "add": "[BD2DB5FE,E0A95BBC]",
    "cost": "11,988"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-924",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[F2EF4025,1EBC5379,CD9A49D2]",
    "add": "[4FF22348,B21A9DE9,542BBC0E,A0E7F6C10]",
    "cost": "1,783"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-925",
    "status": "FALSE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[7B415249,330FF32E,542BBC0E,4FF22345]",
    "add": "[E491B04D,11660B2E]",
    "cost": "9,831"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-926",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[19A12B20,05BD5A7D]",
    "add": "[3CA906F15,8164C899,7B415257]",
    "cost": "7,708"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-927",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[9FC8D91A,41097027,9C01A6AA]",
    "add": "[F36679BC]",
    "cost": "3,291"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-928",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[79A6C1CA,D9345861]",
    "add": "[864437DD,02A8E863,02A8E864,F36679BC]",
    "cost": "3,761"
  },
  {
    "id": "PR-048",
    "preferenceID": "PREF-ID-929",
    "status": "TRUE",
    "surgeon": "Dr. Amira El-Sayed",
    "remove": "[DC16E9E8,DB301E72]",
    "add": "[D33377F11,DB07BD73,11660B2E]",
    "cost": "11,430"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-930",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[048701CE,88F245C2,C36BF121]",
    "add": "[3D26994F,CCD70D1D,02A8E864,A0E7F6C10]",
    "cost": "1,646"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-931",
    "status": "TRUE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[19A12B20,7B415250,5EDCDAE5,ACF7D73A]",
    "add": "[9C01A6AA,7A8CC6BD]",
    "cost": "1,783"
  },
  {
    "id": "PR-043",
    "preferenceID": "PREF-ID-932",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[F2EF4025,A0E7F6C2,BD23BA18]",
    "add": "[A0E7F6C7,D8F6DF61,F55885BD]",
    "cost": "8,129"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-933",
    "status": "FALSE",
    "surgeon": "Dr. Sofia Martinez",
    "remove": "[0ED43D83]",
    "add": "[EB58F896,7B415256,7B500D6B]",
    "cost": "5,708"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-934",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[B21A9DE2,11102F48]",
    "add": "[049BCC94,E0A95BBC]",
    "cost": "11,563"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-935",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[4E1CFAD9,E80AAE4A,F2EF4027,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[7B500D6B,7670C5F12,CCD70D1D,A0E7F6C10,F2EF4035,C36BF130]",
    "cost": "4,803"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-936",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[44804BD4,F55885BD,11660B2E,DB301E75,ACF7D73A]",
    "add": "[9DD6903A,8164C899,02A8E864,74B98566]",
    "cost": "1,783"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-937",
    "status": "TRUE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[E491B04D,74B98558,DF6676C11]",
    "add": "[2C1769CF]",
    "cost": "4,757"
  },
  {
    "id": "PR-013",
    "preferenceID": "PREF-ID-938",
    "status": "TRUE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[F04529EA,F55885BD,CD9A49D2]",
    "add": "[66AB5D2E,DB301E78,79FC1E29]",
    "cost": "10,752"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-939",
    "status": "TRUE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[4E1CFAD9,74B98558,DF6676C11]",
    "add": "[4CD72DAE,5EDCDAE10,8164C900]",
    "cost": "11,126"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-940",
    "status": "TRUE",
    "surgeon": "Dr. Dimitri Antonov",
    "remove": "[DC16E9E8,1EBC5379,E491B04D]",
    "add": "[19A12B26,58D36D86,17B2D2B8]",
    "cost": "3,407"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-941",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[D8F6DF54,14968630]",
    "add": "[88F245C7,8164C899]",
    "cost": "5,251"
  },
  {
    "id": "PR-050",
    "preferenceID": "PREF-ID-942",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[D8F6DF54,88F245C2,F55885BD]",
    "add": "[14968630,CDA15983,EB58F898]",
    "cost": "3,761"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-943",
    "status": "FALSE",
    "surgeon": "Dr. Emily Chen",
    "remove": "[E80AAE4A,F2EF4027,099FDC51]",
    "add": "[6E657E27,58D36D86,F55885BD]",
    "cost": "8,989"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-944",
    "status": "FALSE",
    "surgeon": "Dr. Mateo Alvarez",
    "remove": "[44804BD4,22633EF8,14968630,7A8CC6BD]",
    "add": "[542BBC0E,B688BAF10,7B415257,200EA71C]",
    "cost": "7,052"
  },
  {
    "id": "PR-030",
    "preferenceID": "PREF-ID-945",
    "status": "TRUE",
    "surgeon": "Dr. Michael O'Brien",
    "remove": "[049BCC88,8164C893,BD2DB5FE]",
    "add": "[9DD6903A,58D36D86,05BD5A7D,4E1CFAD18]",
    "cost": "6,563"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-946",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[7A8CC6BD,02A8E857,CDA15978]",
    "add": "[DB301E77,7B415256,7B500D6B,7A8CC6BD]",
    "cost": "11,126"
  },
  {
    "id": "PR-042",
    "preferenceID": "PREF-ID-947",
    "status": "FALSE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[E491B04D,44804BD5,17B2D2B2]",
    "add": "[D9345866,8164C899]",
    "cost": "7,182"
  },
  {
    "id": "PR-049",
    "preferenceID": "PREF-ID-948",
    "status": "FALSE",
    "surgeon": "Dr. Tariq Al-Fayed",
    "remove": "[DC16E9E8,C36BF120,74B98559]",
    "add": "[F1C1E752,DB301E78,F04529EA,E0A95BBC]",
    "cost": "2,493"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-949",
    "status": "TRUE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[542BBC0E,02A8E857,E80AAE4A]",
    "add": "[14968630,58D36D86,CCD70D1D]",
    "cost": "1,196"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-950",
    "status": "FALSE",
    "surgeon": "Dr. Anna Smirnova",
    "remove": "[CCD70D1D,D9345861]",
    "add": "[DF6676C15,D33377F12,7B415257]",
    "cost": "1,196"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-951",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[7B415249,E80AAE4A,F2EF4027,7A8CC6BD]",
    "add": "[02A8E862,DF6676C16,CCD70D1D,F2EF4035]",
    "cost": "7,052"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-952",
    "status": "FALSE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[ACF7D73A,3D26994F,F04529EA]",
    "add": "[19A12B26,3D26994F,9FC8D91A]",
    "cost": "1,169"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-953",
    "status": "TRUE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[CCD70D1D,F55885BD,CDA15978,DC16E9E12]",
    "add": "[F36679BC,44804BD11,6E657E29,A0E7F6C10]",
    "cost": "3,356"
  },
  {
    "id": "PR-035",
    "preferenceID": "PREF-ID-954",
    "status": "FALSE",
    "surgeon": "Dr. Diego Fernandez",
    "remove": "[0ED43D83,9DD6903A,CDA15978]",
    "add": "[14968630,DC16E9E15,6E657E29]",
    "cost": "3,761"
  },
  {
    "id": "PR-047",
    "preferenceID": "PREF-ID-955",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[42A1729B,41097027,2C1769CF,ACF7D73A]",
    "add": "[4FF22348,44804BD12]",
    "cost": "3,291"
  },
  {
    "id": "PR-004",
    "preferenceID": "PREF-ID-956",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[4E1CFAD9,BAF9CD1A,E80AAE4A]",
    "add": "[ACF7D73A,A0E7F6C8,11660B2E,C36BF130]",
    "cost": "3,356"
  },
  {
    "id": "PR-020",
    "preferenceID": "PREF-ID-957",
    "status": "TRUE",
    "surgeon": "Dr. Laura Schmidt",
    "remove": "[B688BAF3,E80AAE4A,7A8CC6BD]",
    "add": "[14968630,9DD6903A]",
    "cost": "7,708"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-958",
    "status": "TRUE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[88F245C1,B688BAF4,7A8CC6BD]",
    "add": "[19A12B26,C71B9808,DB301E79]",
    "cost": "3,356"
  },
  {
    "id": "PR-029",
    "preferenceID": "PREF-ID-959",
    "status": "FALSE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[200EA71C,A0E7F6C2,3D26994F]",
    "add": "[74B98563,1EBC5385,EB58F898]",
    "cost": "8,755"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-960",
    "status": "FALSE",
    "surgeon": "Dr. Jose Garcia",
    "remove": "[049BCC88,599EA2B4]",
    "add": "[19A12B26,CDA15983,44804BD12]",
    "cost": "4,109"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-961",
    "status": "FALSE",
    "surgeon": "Dr. Jessica Lee",
    "remove": "[42A1729B,CDA15977,CCD70D1D]",
    "add": "[ACF7D73A,E0A5E11A,E80AAE4A]",
    "cost": "1,777"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-962",
    "status": "TRUE",
    "surgeon": "Dr. Priya Singh",
    "remove": "[E0A95BBC,74B98558,DB301E73,DC16E9E11]",
    "add": "[7B500D6B,02A8E863,B688BAF11,4E1CFAD18]",
    "cost": "10,511"
  },
  {
    "id": "PR-037",
    "preferenceID": "PREF-ID-963",
    "status": "FALSE",
    "surgeon": "Dr. Ahmed Al-Aziz",
    "remove": "[0AB87239,BAF9CD1A,C36BF121]",
    "add": "[85ECBAF11,11102F54,7B415257]",
    "cost": "1,646"
  },
  {
    "id": "PR-021",
    "preferenceID": "PREF-ID-964",
    "status": "TRUE",
    "surgeon": "Dr. David Cohen",
    "remove": "[E0A95BBC,F36679BC,E80AAE4A,D8F6DF58]",
    "add": "[F1C1E752,A0E7F6C8,C36BF127]",
    "cost": "11,599"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-965",
    "status": "TRUE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[B688BAF3,330FF32E,8607492C]",
    "add": "[88F245C7,E0A95BBC,44804BD12]",
    "cost": "1,646"
  },
  {
    "id": "PR-033",
    "preferenceID": "PREF-ID-966",
    "status": "FALSE",
    "surgeon": "Dr. Lei Wu",
    "remove": "[D8F6DF54,065DB72B,79A6C1CA]",
    "add": "[049BCC94,8164C899,17B2D2B8]",
    "cost": "7,182"
  },
  {
    "id": "PR-032",
    "preferenceID": "PREF-ID-967",
    "status": "TRUE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[7B415249,14968630,DB07BD68]",
    "add": "[85ECBAF11,C71B9808,7A8CC6BD]",
    "cost": "3,441"
  },
  {
    "id": "PR-009",
    "preferenceID": "PREF-ID-968",
    "status": "TRUE",
    "surgeon": "Dr. Nora Johansen",
    "remove": "[4FF22342,41097027,2C1769CF]",
    "add": "[ACF7D73A]",
    "cost": "3,761"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-969",
    "status": "FALSE",
    "surgeon": "Dr. Victoria Petrova",
    "remove": "[9FC8D91A,88F245C2,9DD6903A]",
    "add": "[3CA906F15,D8F6DF61,17B2D2B8]",
    "cost": "8,989"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-970",
    "status": "TRUE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[E491B04D,11102F48,CCD70D1D]",
    "add": "[4CD72DAE,E0A95BBC,6E657E29]",
    "cost": "3,622"
  },
  {
    "id": "PR-039",
    "preferenceID": "PREF-ID-971",
    "status": "TRUE",
    "surgeon": "Dr. Natasha Romanov",
    "remove": "[9FC8D91A,E0A5E11A,C36BF121]",
    "add": "[F36679BC,CCD70D1D,44804BD12]",
    "cost": "1,289"
  },
  {
    "id": "PR-026",
    "preferenceID": "PREF-ID-972",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[DC16E9E8,D9345861,2C1769CF,ACF7D73A,DB301E75,ACF7D73A]",
    "add": "[4FF22348,85ECBAF12,CCD70D1D,A0E7F6C10,542BBC0E,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-015",
    "preferenceID": "PREF-ID-973",
    "status": "FALSE",
    "surgeon": "Dr. Mei Huang",
    "remove": "[4E1CFAD9,F55885BD,F2EF4027,ACF7D73A,DC16E9E12,ACF7D73A]",
    "add": "[4FF22348,E02AF8C11,CCD70D1D,A0E7F6C10,F2EF4035]",
    "cost": "1,783"
  },
  {
    "id": "PR-003",
    "preferenceID": "PREF-ID-974",
    "status": "TRUE",
    "surgeon": "Dr. Hiroshi Nakamura",
    "remove": "[542BBC0E,E80AAE4A,CDA15978]",
    "add": "[D8F6DF60,EB58F897,EB58F898]",
    "cost": "4,109"
  },
  {
    "id": "PR-006",
    "preferenceID": "PREF-ID-975",
    "status": "FALSE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[5EDCDAE3,A0E7F6C2,C36BF121,ACF7D73A]",
    "add": "[14968630,5F3AFC30,02A8E864]",
    "cost": "9,831"
  },
  {
    "id": "PR-018",
    "preferenceID": "PREF-ID-976",
    "status": "FALSE",
    "surgeon": "Dr. Isabella Rossi",
    "remove": "[F2EF4025,41097027]",
    "add": "[19A12B26,E0A5E11A]",
    "cost": "9,661"
  },
  {
    "id": "PR-019",
    "preferenceID": "PREF-ID-977",
    "status": "TRUE",
    "surgeon": "Dr. Ali Reza",
    "remove": "[E491B04D,F55885BD]",
    "add": "[7B415255,DC16E9E15,A0E7F6C9]",
    "cost": "8,781"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-978",
    "status": "FALSE",
    "surgeon": "Dr. Yasmin Daher",
    "remove": "[4CD72DAE,BAF9CD1A,DF6676C11,4E1CFAD13]",
    "add": "[E0A5E11A,A0E7F6C8,02A8E864]",
    "cost": "11,563"
  },
  {
    "id": "PR-025",
    "preferenceID": "PREF-ID-979",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[F04529EA,42A1729B,8607492C]",
    "add": "[4FF22348,8164C899,42A1729B]",
    "cost": "5,088"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-980",
    "status": "FALSE",
    "surgeon": "Dr. An Nguyen",
    "remove": "[7A8CC6BD,9EAD0C20,F2EF4027,099FDC51]",
    "add": "[F1C1E752,E02AF8C11,CCD70D1D,F2EF4035,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-981",
    "status": "FALSE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[E80AAE4A,F36679BC,F2EF4027]",
    "add": "[02A8E862,DF6676C16,F36679BC]",
    "cost": "1,783"
  },
  {
    "id": "PR-016",
    "preferenceID": "PREF-ID-982",
    "status": "FALSE",
    "surgeon": "Dr. Chioma Adekunle",
    "remove": "[7B415249,DC16E9E9,2C1769CF]",
    "add": "[F2EF4031,DC16E9E15,E0A95BBC,F36679BC]",
    "cost": "3,356"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-983",
    "status": "FALSE",
    "surgeon": "Dr. Yuki Tanaka",
    "remove": "[44804BD4,42A1729B,542BBC0E,5EDCDAE6]",
    "add": "[4CD72DAE]",
    "cost": "10,511"
  },
  {
    "id": "PR-007",
    "preferenceID": "PREF-ID-984",
    "status": "FALSE",
    "surgeon": "Dr. Jacob Müller",
    "remove": "[E0A95BBC,D9345861,E491B04D]",
    "add": "[049BCC94,11102F54]",
    "cost": "11,430"
  },
  {
    "id": "PR-038",
    "preferenceID": "PREF-ID-985",
    "status": "TRUE",
    "surgeon": "Dr. Hye-Jin Kim",
    "remove": "[88F245C1,DB301E72,CB6FF511]",
    "add": "[E491B04D,11102F54,7670C5F13]",
    "cost": "4,109"
  },
  {
    "id": "PR-008",
    "preferenceID": "PREF-ID-986",
    "status": "FALSE",
    "surgeon": "Dr. Carlos Rivera",
    "remove": "[200EA71C,7B500D6B]",
    "add": "[4FF22348,EB58F897,EB58F898,A0E7F6C10,F2EF4035]",
    "cost": "8,538"
  },
  {
    "id": "PR-014",
    "preferenceID": "PREF-ID-987",
    "status": "TRUE",
    "surgeon": "Dr. Sarah Goldberg",
    "remove": "[19A12B20,ACF7D73A]",
    "add": "[44804BD10,DC16E9E15,F1C1E754]",
    "cost": "5,727"
  },
  {
    "id": "PR-023",
    "preferenceID": "PREF-ID-988",
    "status": "TRUE",
    "surgeon": "Dr. Fatima Al-Farsi",
    "remove": "[049BCC88,E80AAE4A,F36679BC,099FDC51,DB301E75]",
    "add": "[7B500D6B,D8F6DF61,BAF9CD1A]",
    "cost": "5,727"
  },
  {
    "id": "PR-046",
    "preferenceID": "PREF-ID-989",
    "status": "FALSE",
    "surgeon": "Dr. Olga Ivanova",
    "remove": "[CCD70D1D,D33377F6,ACF7D73A]",
    "add": "[14968630,7B415256,5F3AFC31]",
    "cost": "5,299"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-990",
    "status": "TRUE",
    "surgeon": "Dr. Omar Sharif",
    "remove": "[7B415249,DC16E9E9,7B500D6B]",
    "add": "[D8F6DF60,A0E7F6C8,DB301E79]",
    "cost": "3,407"
  },
  {
    "id": "PR-028",
    "preferenceID": "PREF-ID-991",
    "status": "TRUE",
    "surgeon": "Dr. Rajesh Kumar",
    "remove": "[DB07BD66,0ED43D84,17B2D2B2]",
    "add": "[02A8E862,8164C899,DF6676C17,C36BF130]",
    "cost": "1,783"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-992",
    "status": "FALSE",
    "surgeon": "Dr. Kenji Sato",
    "remove": "[58D36D79,41097027,F1C1E748]",
    "add": "[85ECBAF11,F1C1E754]",
    "cost": "4,109"
  },
  {
    "id": "PR-010",
    "preferenceID": "PREF-ID-993",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[049BCC88,74B98558,E491B04D,DC16E9E12]",
    "add": "[17B2D2B6,E02AF8C11,17B2D2B8]",
    "cost": "10,043"
  },
  {
    "id": "PR-027",
    "preferenceID": "PREF-ID-994",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[5EDCDAE3,BAF9CD1A,DF6676C11]",
    "add": "[2C1769CF,EB58F897,B688BAF11]",
    "cost": "8,781"
  },
  {
    "id": "PR-036",
    "preferenceID": "PREF-ID-995",
    "status": "TRUE",
    "surgeon": "Dr. Kwame Nkrumah",
    "remove": "[9FC8D91A,02A8E857,CB6FF511,DC16E9E11]",
    "add": "[CDA15982,DF6676C16]",
    "cost": "7,052"
  },
  {
    "id": "PR-044",
    "preferenceID": "PREF-ID-996",
    "status": "FALSE",
    "surgeon": "Dr. Kofi Mensah",
    "remove": "[85ECBAF5,DC16E9E9,CB6FF511]",
    "add": "[19A12B26,8164C899,42A1729B]",
    "cost": "11,126"
  },
  {
    "id": "PR-017",
    "preferenceID": "PREF-ID-997",
    "status": "TRUE",
    "surgeon": "Dr. Jamal Al-Khalidi",
    "remove": "[66AB5D2E,1EBC5379,E491B04D,5EDCDAE6]",
    "add": "[542BBC0E,11102F54,F1C1E754]",
    "cost": "6,784"
  },
  {
    "id": "PR-011",
    "preferenceID": "PREF-ID-998",
    "status": "TRUE",
    "surgeon": "Dr. Lily Zhang",
    "remove": "[7A8CC6BD,A0E7F6C2,CB6FF511]",
    "add": "[F1C1E752,17B2D2B7]",
    "cost": "3,407"
  },
  {
    "id": "PR-031",
    "preferenceID": "PREF-ID-999",
    "status": "FALSE",
    "surgeon": "Dr. John Smith",
    "remove": "[9FC8D91A,F55885BD,DF6676C11]",
    "add": "[9DD6903A,42A1729B,4E1CFAD18,F2EF4035]",
    "cost": "3,370"
  }
]