import { useState, useEffect, useRef, useContext } from "react"
import { matchPath, useLocation, useNavigate } from "react-router-dom"
import PrimaryButton from "../primaryButton/primaryButton"
import BarChart from "../barChart/barChart"
import ContentEditable from "react-contenteditable"
import sanitizeHtml from "sanitize-html"
import GlobalSearch from "../globalSearch/globalSearch"
import { useHotkeys } from "react-hotkeys-hook"
import { ChatMessage } from "../../model/chat/chat"
import uuid from "react-uuid"
import { Timestamp } from "firebase/firestore"
import { auth } from "../../firebase/firebase"
import Chat from "../chat/chat"
import { ChatContext, EntityContext, SidePanelContext, UserContext } from "../../context/globalContext"
import ListItemRow from "../listItemRow/listItemRow"

const AIAssistant = () => {
    const [aiInput, setAIInput] = useState(false)
    const [aiInputOpenAnimation, setAIInputOpenAnimation] = useState(true)
    const [foundDialog, setFoundDialog] = useState(false)
    const [aiConversationDialog, setAIConversationDialog] = useState(false)
    const [placeholder, setPlaceholder] = useState(0)
    const [searchShown, setSearchShown] = useState(false)
    const [entities, setEntities] = useState<string[]>([])
    const [entityNames, setEntityNames] = useState<string[]>([])
    const [enttiyInContent, setEntityInContext] = useState(0)
    const [aiInputHeight, setAIInputHeight] = useState(0)
    const [responses, setResponses] = useState<ChatMessage[]>([])
    const [loading, setLoading] = useState(false)
    const [substitutes, setSubstitutes] = useState<string[]>([])

    const placeholders = [
        'Ask Osmosis',
        'Type / to add an entity',
        'Do we have a substitute for item?',
        'What\'s Vendor fill rate?',
        'What’s the total spend in Q2 for Sterile Gloves?'
    ]

    const [content, setContent] = useState("")

    const sidePanelContext = useContext(SidePanelContext)
    const chatPanelContext = useContext(ChatContext)
    const entityContext = useContext(EntityContext)
    const userContext = useContext(UserContext)

    const aiInputRef = useRef<HTMLDivElement>(null)
    const aiPanelRef = useRef<HTMLDivElement>(null)
    const aiSubstitutePanelRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLElement>(null)
    const placeholderRefs = useRef<(HTMLDivElement | null)[]>([])
    const bottomMessageRef = useRef<HTMLDivElement>(null)

    const aiSubstitutePanelHeight = aiSubstitutePanelRef.current?.getBoundingClientRect().height || 0

    const navigate = useNavigate()
    const location = useLocation()

    const isOverview = matchPath('dashboard', location.pathname)
    const isInventory = matchPath('dashboard/inventory', location.pathname)
    const isInventoryItem = matchPath('dashboard/inventory/:id', location.pathname)
    const isProcedureItem = matchPath('dashboard/procedure/:id', location.pathname)

    useHotkeys('meta+enter', () => { if (content) { sendMessage() } }, [content], { enableOnContentEditable: true })

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeout(() => {
                setPlaceholder((prevIndex) => (prevIndex + 1) % placeholders.length)
            }, 2000)
        }, 7000)
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if (content.at(-1) === '/') {
            setSearchShown(true)
        }

        const entityMatches = content.match(RegExp("\\b(" + entityNames.join("|") + ")\\b", "g"))?.length || 0

        if (entityMatches < entities.length) {
            const lastEntity = entityNames.at(-1)

            const replacedText = content.replace(lastEntity?.slice(0, -1) || '', '')

            setContent(replacedText)

            const entitiesCopy = entities
            const entityNamesCopy = entityNames

            entitiesCopy.pop()
            entityNamesCopy.pop()

            setEntities(entitiesCopy)
            setEntityNames(entityNamesCopy)
        }

    }, [content, entityNames, entities])

    useEffect(() => {
        bottomMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [responses])

    useEffect(() => {
        setAIInputHeight(aiInputRef.current?.getBoundingClientRect().height || 0)
    }, [content])

    useEffect(() => {
        if (aiInput) {
            setAIInputOpenAnimation(true)
        } else {
            setTimeout(() => {
                setAIInputOpenAnimation(false)
            }, 200)
        }
    }, [aiInput])

    useEffect(() => {
        if (aiInputOpenAnimation) {
            contentRef.current?.focus()
        }
    }, [aiInputOpenAnimation])

    const formatContent = (text: string): string => {
        const html = '<span><span style="padding-left: 4px; padding-right: 4px; width: fit-content; display: inline-flex; align-items: center;" class="text-neutral-5 bg-neutral-1 rounded-sm text-bold"><img style="margin-right: 3px;" width="10" src="/images/icons/ic-box-gray.svg"/>$1</span></span>'

        if (entityNames.length === 0) {
            return text
        }

        const replacedText = text.replace(RegExp("\\b(" + entityNames.join("|") + ")\\b", "g"), html)
        return replacedText
    }

    const sendMessage = async () => {
        setLoading(true)

        try {
            const message: ChatMessage = {
                id: uuid(),
                message: content,
                type: "text",
                createdAt: Timestamp.now(),
                createdBy: auth.currentUser?.uid || '',
                readBy: []
            }

            const res = await fetch('https://osmosis-ai-ec12ffc2ed6e.herokuapp.com/api/ask', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    message: content
                })
            })

            const data = await res.json()

            setResponses(r => [...r, message])

            const response = data['message']

            if (response.includes('GRAPH DATA')) {
                const responseMessage: ChatMessage = {
                    id: uuid(),
                    message: response.split('GRAPH DATA')[0].slice(0, -1),
                    type: "text",
                    createdAt: Timestamp.now(),
                    createdBy: '',
                    readBy: []
                }

                const graphMessage: ChatMessage = {
                    id: uuid(),
                    message: response.split('GRAPH DATA')[1],
                    type: "graph",
                    createdAt: Timestamp.now(),
                    createdBy: '',
                    readBy: []
                }

                setResponses(r => [...r, responseMessage])
                setResponses(r => [...r, graphMessage])
            } else {
                if (response.includes('SUBSTITUTE DATA')) {
                    const splitResponse = response.split('SUBSTITUTE DATA')[0] + (response.split('SUBSTITUTE DATA')[1])?.split('##').at(-1).slice(2)
                    const substituteResponse = response.split('SUBSTITUTE DATA')[1].split('##')[1].split(';').map((v: string) => {
                        let result = v

                        if (result[0] === ' ') {
                            result = result.slice(1)
                        }

                        if (result.at(-1) === ' ') {
                            result = result.slice(0, -1)
                        }

                        return result
                    })

                    const set: Set<string> = new Set([...substitutes, ...substituteResponse])

                    setSubstitutes(Array.from(set))

                    const responseMessage: ChatMessage = {
                        id: uuid(),
                        message: splitResponse,
                        type: "text",
                        createdAt: Timestamp.now(),
                        createdBy: '',
                        readBy: []
                    }

                    setResponses(r => [...r, responseMessage])
                } else {
                    const responseMessage: ChatMessage = {
                        id: uuid(),
                        message: response,
                        type: "text",
                        createdAt: Timestamp.now(),
                        createdBy: '',
                        readBy: []
                    }

                    setResponses(r => [...r, responseMessage])
                }
            }

            setContent('')

            if (!aiConversationDialog) {
                setFoundDialog(true)
                setAIInput(false)

                setTimeout(() => {
                    setFoundDialog(false)
                    setAIConversationDialog(true)
                    setAIInput(true)
                }, 2000)
            } else {
                setAIConversationDialog(true)
            }
        } catch (error) {
            console.log(error)
        }

        setLoading(false)
    }

    return (
        <>
            {!aiInputOpenAnimation && !foundDialog && (
                <div style={{ right: sidePanelContext?.sidePanelOpen ? '' : '0px', left: sidePanelContext?.sidePanelOpen ? '260px' : '' }} className="z-[100] absolute bottom-0 bg-cover p-[80px] bg-top bg-[url('/public/images/backgrounds/bg-gradient-ai-2.svg')]">
                    <img onClick={() => {
                        setAIInput(true)
                    }} className="cursor-pointer h-[72px]" src={'/images/icons/ic-ai-sphere.svg'}></img>

                    {aiConversationDialog && (
                        <div className="absolute top-[68px] right-[68px] rounded-full bg-material-ultra border border-neutral-2 p-1.5 backdrop-blur-lg">
                            <img className="w-[22px] h-[22px]" src="/images/icons/ic-ai-chat-bubble.svg" />
                        </div>
                    )}
                </div>
            )}

            {aiInputOpenAnimation && (
                <div style={{ right: sidePanelContext?.sidePanelOpen ? '' : '80px', left: sidePanelContext?.sidePanelOpen ? '420px' : '' }} className="z-[100] absolute bottom-[80px] origin-bottom-right">
                    <div className="absolute bottom-[0px] right-0">
                        <div
                            ref={aiInputRef}
                            style={{ boxShadow: '0px 38px 90px 0px rgba(0, 0, 0, 0.25), 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.60)' }}
                            className="relative flex bg-white border-neutral-1 rounded-[14px] backdrop-blur-2xl">

                            <div className="relative flex items-center border-r border-neutral-2 bg-center bg-covercon rounded-l-[14px] bg-[url('/public/images/backgrounds/bg-gradient-ai-input.png')]">
                                <img onClick={() => {
                                    setAIInput(false)
                                }} className="mx-3 my-2 w-[32px] h-[32px] min-w-[32px] min-h-[32px] cursor-pointer" src={'/images/icons/ic-ai-sphere.svg'}></img>
                            </div>

                            <div className="relative flex items-center overflow-hidden">
                                {!content && (
                                    placeholders.map((text, index) => {
                                        return (
                                            <>
                                                {index === placeholder && (
                                                    <div className={`absolute flex items-center pointer-events-none inset-0 animate-[ai-input-placeholder-slide-in_7s_ease-in-out_forwards]`}>
                                                        <p ref={(r) => placeholderRefs.current[index] = r} className="font-body text-neutral-5 my-auto px-3">
                                                            {text}
                                                        </p>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    })
                                )}

                                <div style={{ minHeight: [2, 4].includes(placeholder) && !content ? `32px` : '0' }} className={`${aiInput ? 'animate-[ai-input-slide-in_0.2s_ease-in-out_forwards]' : 'animate-[ai-input-slide-out_0.2s_ease-in-out_forwards]'} px-3 max-h-[68px] overflow-hidden my-auto`}>
                                    <ContentEditable
                                        innerRef={contentRef}
                                        className="font-body focus:outline-none whitespace-pre-wrap"
                                        onChange={(evt) => {
                                            const sanitizeConf = {
                                                allowedTags: ["b", "i", "a", "p"],
                                                allowedAttributes: { a: ["href"] }
                                            }

                                            setContent(sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf))
                                        }}
                                        html={formatContent(content)}
                                    />
                                </div>
                            </div>

                            <div className="relative border-l border-neutral-2 flex items-center">
                                <div className="px-3">
                                    {loading ?
                                        <img className="cursor-pointer min-w-[22px] min-h-[22px] my-auto animate-spin" src="/images/icons/ic-ai-spinner.svg" />
                                        :
                                        <img onClick={() => sendMessage()} className="cursor-pointer min-w-[22px] min-h-[22px] my-auto" src="/images/icons/ic-arrow-up-circle-fill-blue.svg" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {foundDialog && (
                <div style={{ bottom: `${(aiInputHeight || 58) + 88}px`, right: sidePanelContext?.sidePanelOpen ? '' : '80px', left: sidePanelContext?.sidePanelOpen ? '95px' : '' }} className="z-[100] absolute flex shadow-lg justify-center w-[325px] rounded-[16px] bg-material-ultra border border-neutral-2 p-3 backdrop-blur-lg">
                    <p style={{ backgroundImage: 'linear-gradient(to right, #0736C2, #2151CD, #0D8DDF, #01ADFF, #3ECBFF, #9B56FE, #D654AA, #F8AEFB)' }} className="font-body py-1 font-bold bg-clip-text text-transparent">Here's what I found</p>
                </div>
            )}

            {aiConversationDialog && aiInput && (
                <div style={{ bottom: `${(aiInputHeight || 58) + 88}px`, right: sidePanelContext?.sidePanelOpen ? '' : '80px', left: sidePanelContext?.sidePanelOpen ? '95px' : '' }} className="flex flex-col gap-2 z-[100] absolute justify-center w-[325px]">
                    <div ref={aiPanelRef} style={{ maxHeight: `calc(80vh - ${aiSubstitutePanelHeight}px - 16px)`, }} className="flex shadow-lg rounded-[8px] bg-popover border border-neutral-2 p-4 backdrop-blur-2xl">
                        <div className="flex flex-col w-full">
                            <div className="flex gap-2 w-full items-center">
                                <div className={`h-[18px] min-w-[18px] gradient-black rounded-[4px] flex items-center justify-center`}>
                                    <img className="h-[12px] w-[12px]" src={`/images/icons/ic-square-dashed-white.svg`}></img>
                                </div>

                                <p className="font-body font-semibold">Conversate</p>

                                <div onClick={() => setAIInput(false)} className="flex gap-1 cursor-pointer ml-auto py-2 px-1">
                                    <p className="font-body text-neutral-5">Minimise</p>

                                    <img src="/images/icons/ic-chevron-down-gray.svg" />
                                </div>
                            </div>

                            <div className="flex flex-col mt-auto gap-2 py-2 px-2 overflow-y-scroll">
                                {responses?.map((message, index) => {
                                    const prevMessage = responses[index - 1]
                                    const nextMessage = responses[index + 1]

                                    const isStartOfGroup = prevMessage?.createdBy !== message.createdBy || prevMessage?.type !== message.type
                                    const isEndOfGroup = nextMessage?.createdBy !== message.createdBy || nextMessage?.type !== message.type

                                    return (
                                        <div key={index} className="flex gap-1 mt-auto">
                                            <div className="flex flex-col px-2 w-full">
                                                <Chat message={message} isSend={auth.currentUser?.uid === message.createdBy} withTail={isEndOfGroup}></Chat>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div ref={bottomMessageRef} className="h-[1px] pb-[1px] opacity-0"></div>
                            </div>
                        </div>
                    </div>

                    <div ref={aiSubstitutePanelRef} className="relative flex flex-col shadow-lg p-2 bg-popover border border-neutral-2 rounded-[8px] backdrop-blur-2xl">
                        {substitutes.map((substitute, index) => {
                            const item = entityContext?.medicalItems.find((v) => v.name === substitute)
                            const disruption = entityContext?.disruptions.find((v) => v.entity === item?.id)
                            const assignee = userContext?.users.find((v) => v.id === disruption?.assignee)

                            return (
                                <>
                                    {item && (
                                        <ListItemRow
                                            key={uuid()}
                                            title={item.name}
                                            hideBackground={true}
                                            hideBorder={true}
                                            subtitle={disruption ? disruption.type.charAt(0).toUpperCase() + disruption.type.slice(1) : item.status}
                                            icon={{
                                                icon: 'ic-warning-triangle.svg',
                                                gradient: 'orange'
                                            }}
                                            arrowLabel={assignee?.name}
                                            arrowRight={true}
                                            onArrowClick={() => {
                                                setAIConversationDialog(false)
                                                setAIInput(false)
                                                setResponses([])
                                                navigate(`../dashboard/inventory/${item.id}#overview`, { replace: true, state: { previousLocationPathName: location.pathname } })
                                            }}
                                        >

                                        </ListItemRow>
                                    )}

                                    {index !== (substitutes.length - 1) && item && (
                                        <div className="h-[1px] bg-neutral-2 ml-[44px]"></div>
                                    )}
                                </>
                            )
                        })}
                    </div>
                </div>

            )}

            {searchShown && (
                <GlobalSearch
                    selectable={true}
                    onSelect={(id, name) => {
                        setContent(c => c.split('/')[0] + `${name} `)
                        setEntities([...entities, id])
                        setEntityNames([...entityNames, name])
                        contentRef.current?.focus()
                    }}
                    onDismiss={() => setSearchShown(false)}
                />
            )}
        </>
    )
}

export default AIAssistant