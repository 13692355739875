import { ReactNode } from "react"

const Dialog = (props: { children: ReactNode }) => {
    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-[500] bg-black/20">
                <div className="bg-dialog backdrop-blur-2xl rounded-lg shadow-lg border border-neutral-2">
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default Dialog