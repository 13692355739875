import { useContext, useEffect, useRef, useState } from "react"
import { UserContext } from "../../context/globalContext"
import ProfilePicture from "../profilePicture/profilePicture"
import { ChatMessage, Chatroom, Poll } from "../../model/chat/chat"
import PrimaryButton from "../primaryButton/primaryButton"
import { auth, firestore, storage } from "../../firebase/firebase"
import ContentEditable from "react-contenteditable"
import sanitizeHtml from "sanitize-html"
import { FullMetadata, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import Chat from "../chat/chat"
import uuid from "react-uuid"
import { Timestamp, arrayUnion, collection, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore"
import { Notification } from "../../model/notification/notification"
import AttachmentDialog from "../attachmentDialog/attachmentDialog"
import CreatePollDialog from "../createPollDialog/createPollDialog"
import { useHotkeys } from "react-hotkeys-hook"

const ChatPage = (props: { chatroom: Chatroom }) => {
    const [pollAlert, setPollAlert] = useState<ChatMessage>()
    const [messages, setMessages] = useState<ChatMessage[]>()

    const [createPollDialog, setCreatePollDialog] = useState(false)
    const [attachmentDialog, setAttachmentDialog] = useState<{ url: string, metadata: FullMetadata | null } | null>(null)
    const [popupPosition, setPopupPosition] = useState<{ top: number, left: number } | null>(null)
    const [content, setContent] = useState("")

    const messageRefs = useRef<(HTMLDivElement | null)[]>([])
    const messagesContainerRef = useRef<HTMLDivElement>(null)
    const bottomMessageRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLElement>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const mentionPopupRef = useRef<HTMLDivElement>(null)

    const userContext = useContext(UserContext)

    useHotkeys('meta+enter', () => { if (content) { sendMessage(content) } }, [content], { enableOnContentEditable: true })

    useEffect(() => {
        const unsub = onSnapshot(collection(firestore, 'chats', props.chatroom.id, 'messages'), (snapshot) => {
            const docs = snapshot.docs

            const messages = docs.map((doc) => {
                return doc.data() as ChatMessage
            })

            const sortedMessages = messages.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds)

            setMessages(sortedMessages)
        })

        return () => unsub()
    }, [props.chatroom.id])

    useEffect(() => {
        const detectMentions = () => {
            if (contentRef) {
                const contentEditable = contentRef.current;
                const latestSpan = contentEditable?.querySelector('span:last-child');

                if (latestSpan) {
                    const rect = latestSpan.getBoundingClientRect();
                    const popupHeight = mentionPopupRef.current?.offsetHeight
                    let top = rect.top;
                    let left = rect.left;

                    if (mentionPopupRef.current) {
                        const popupRect = mentionPopupRef.current.getBoundingClientRect()
                        const windowWidth = window.innerWidth
                        const windowHeight = window.innerHeight

                        if ((popupRect?.bottom || 0) > windowHeight) {
                            top -= (popupRect?.bottom || 0) - windowHeight
                        }

                        if ((popupRect?.right || 0) > windowWidth) {
                            left -= (popupRect?.right || 0) - windowWidth
                        }
                    }

                    setPopupPosition({ top: top - (popupHeight || 0) - 16, left: left })
                } else {
                    setPopupPosition(null);
                }
            }
        }

        detectMentions();
    }, [content])

    useEffect(() => {
        const adjustPopupPosition = () => {
            if (mentionPopupRef.current) {
                const popupHeight = mentionPopupRef.current.offsetHeight

                let newTop = (popupPosition?.top || 0) - popupHeight
                let newLeft = popupPosition?.left || 0

                const popupRect = mentionPopupRef.current.getBoundingClientRect()
                const windowWidth = window.innerWidth
                const windowHeight = window.innerHeight

                if (popupRect.bottom > windowHeight) {
                    newTop -= popupRect.bottom - windowHeight
                }

                if (popupRect.right > windowWidth) {
                    newLeft -= popupRect.right - windowWidth
                }

                setPopupPosition({ top: newTop, left: newLeft })
            }
        };

        adjustPopupPosition()
    }, [mentionPopupRef.current])

    useEffect(() => {
        bottomMessageRef.current?.scrollIntoView({ behavior: 'smooth' })

        if (auth.currentUser) {
            const unreadMessages = messages?.filter((v) => !(v.readBy || []).includes(auth.currentUser!.uid))

            unreadMessages?.forEach((message) => {
                updateDoc(doc(firestore, 'chats', props.chatroom.id, 'messages', message.id), {
                    readBy: arrayUnion(auth.currentUser!.uid)
                })
            })
        }
    }, [messages])

    useEffect(() => {
        const checkVisibility = () => {
            const containerRect = messagesContainerRef?.current?.getBoundingClientRect()

            const message = messages?.find((message, index) => {
                if (message.type === 'poll') {

                    const elementRect = messageRefs.current[index]?.getBoundingClientRect()

                    if (elementRect && containerRect) {
                        return elementRect.bottom <= containerRect.top
                    }
                }

                return false
            })

            setPollAlert(message)
        }

        messagesContainerRef.current?.addEventListener('scroll', checkVisibility)

        return () => {
            messagesContainerRef.current?.removeEventListener('scroll', checkVisibility)
        }
    }, [messages])

    const highlightMentions = (text: string) => {
        const replacedText = text.replace(/@(\S+)/g, '<span>@<span class="text-[#0F62FE] text-bold">$1</span></span>')
        return replacedText
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target
        const selectedFiles = files as FileList

        if (selectedFiles?.[0]) {
            const storageRef = ref(storage, `/chats/${props.chatroom.id}/${selectedFiles?.[0].name}`);
            const uploadTask = uploadBytesResumable(storageRef, selectedFiles?.[0], { contentType: selectedFiles?.[0].type })

            uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err), () => {

                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    if (auth.currentUser) {
                        sendMessage(`${userContext?.user?.name} sent an attachment`, 'attachment', url, selectedFiles?.[0].name)
                    }
                })
            })
        }
    }

    const sendMessage = async (text: string, type: string = 'text', reference?: string, referenceType?: string) => {
        const messageId = uuid()
        let message: ChatMessage = {
            id: messageId,
            message: text,
            type: type,
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            readBy: []
        }

        if (reference) {
            message.reference = reference
        }

        if (referenceType) {
            message.referenceType = referenceType
        }

        setContent("")

        await setDoc(doc(firestore, 'chats', props.chatroom.id, 'messages', messageId), message)
        await updateDoc(doc(firestore, 'chats', props.chatroom.id), {
            lastMessage: message
        })

        const notification: Notification = {
            id: uuid(),
            title: '1 new message',
            subtitle: message.message,
            time: Timestamp.now(),
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            recipients: props.chatroom.participants.filter((v) => v !== auth.currentUser?.uid),
            seenBy: [],
            type: 'message'
        }

        await setDoc(doc(firestore, 'notifications', notification.id), notification)

        props.chatroom.entities.forEach((entity) => {
            if (entity.type === 'item') {
                updateDoc(doc(firestore, 'items', entity.id), {
                    lastUpdated: Timestamp.now(),
                    lastUpdatedBy: auth.currentUser?.uid
                })
            }
        })

    }

    const createPoll = async (poll: Poll) => {
        if (poll.message) {
            const message: ChatMessage = {
                id: uuid(),
                message: poll.message,
                type: 'text',
                createdAt: Timestamp.now(),
                createdBy: auth.currentUser?.uid || '',
                readBy: auth.currentUser?.uid ? [auth.currentUser?.uid] : []
            }

            await setDoc(doc(firestore, 'chats', props.chatroom.id, 'messages', message.id), message)
        }

        const pollMessage: ChatMessage = {
            id: uuid(),
            message: `${userContext?.user?.name} created poll`,
            type: 'poll',
            createdAt: Timestamp.now(),
            createdBy: auth.currentUser?.uid || '',
            reference: poll.id,
            readBy: auth.currentUser?.uid ? [auth.currentUser?.uid] : []
        }

        await setDoc(doc(firestore, 'chats', props.chatroom.id, 'messages', pollMessage.id), pollMessage)
        await setDoc(doc(firestore, 'chats', props.chatroom.id, 'polls', poll.id), poll)

        await updateDoc(doc(firestore, 'chats', props.chatroom.id), {
            lastMessage: pollMessage,
            hasPoll: true
        })
    }

    return (
        <>
            <div className="bg-white flex-grow flex flex-col pb-2 overflow-hidden">
                {pollAlert && (
                    <div style={{ pointerEvents: pollAlert ? 'all' : 'none' }} className="flex p-3 gap-2 bg-[#F6F6F6B8] items-center">
                        <ProfilePicture user={userContext?.users.find((v) => v.id === pollAlert.createdBy)} size={30} />
                        <p className="font-body">{userContext?.users.find((v) => v.id === pollAlert.createdBy)?.name}</p>

                        <div className="ml-auto">
                            <PrimaryButton
                                title="Answer Poll"
                                type="white"
                                size="sm"
                                onClick={() => {
                                    const index = messages?.findIndex((v) => v.id === pollAlert.id)

                                    if (index) {
                                        messageRefs.current[index]?.scrollIntoView({ behavior: 'smooth' })
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}

                <div ref={messagesContainerRef} className="flex flex-col mt-auto gap-2 py-2 px-2 overflow-y-scroll">
                    {messages?.map((message, index) => {
                        const prevMessage = messages[index - 1]
                        const nextMessage = messages[index + 1]

                        const isStartOfGroup = prevMessage?.createdBy !== message.createdBy || prevMessage?.type !== message.type
                        const isEndOfGroup = nextMessage?.createdBy !== message.createdBy || nextMessage?.type !== message.type

                        return (
                            <div key={index} ref={(r) => messageRefs.current[index] = r} className="flex gap-1 mt-auto">
                                {message.createdBy !== auth.currentUser?.uid && !['leave', 'join'].includes(message.type) && (
                                    !isStartOfGroup ?
                                        <div className="mt-auto mb-2 w-[30px] h-[28px] opacity-0 gradient-light-grey rounded-full text-white text-center">
                                        </div>
                                        :
                                        userContext?.users.find((v) => v.id === message.createdBy)?.photoURL ?
                                            <img className="mt-auto mb-2 w-[30px] h-[28px] rounded-full" src={userContext?.users.find((v) => v.id === message.createdBy)?.photoURL}></img>
                                            :
                                            <div className="mt-auto mb-2 w-[30px] h-[28px] gradient-light-grey rounded-full text-white text-center">
                                                <p className="mt-[2px]">{userContext?.users.find((v) => v.id === message.createdBy)?.name[0]}</p>
                                            </div>

                                )}

                                <div className="flex flex-col px-2 w-full">
                                    {message.createdBy !== auth.currentUser?.uid && message.type === 'text' && isStartOfGroup && (
                                        <p className="font-subheadline text-neutral-5 mb-1">{userContext?.users.find((v) => v.id === message.createdBy)?.name.split(' ')[0]}</p>
                                    )}

                                    <Chat onAttachmentClick={(v, m) => setAttachmentDialog({ url: v, metadata: m })} message={message} isSend={auth.currentUser?.uid === message.createdBy} withTail={isEndOfGroup} chatroom={props.chatroom}></Chat>
                                </div>
                            </div>
                        )
                    })}

                    <div ref={bottomMessageRef} className="h-[1px] pb-[1px] opacity-0"></div>
                </div>

                <div className="flex gap-2 items-center px-2">
                    <img onClick={() => setCreatePollDialog(true)} className="cursor-pointer h-[14px] w-[14px]" src={`/images/icons/ic-list-bullet.svg`}></img>

                    <div style={{ boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)' }} className="relative flex px-3 py-2 rounded-[16px] items-center w-full gap-2">
                        {!content && (
                            <p className="absolute font-body text-neutral-2 pointer-events-none">Type a message</p>
                        )}

                        <ContentEditable
                            innerRef={contentRef}
                            disabled={!props.chatroom.participants.includes(auth.currentUser?.uid || '')}
                            className="font-body focus:outline-none w-full"
                            onChange={(evt) => {
                                const sanitizeConf = {
                                    allowedTags: ["b", "i", "a", "p"],
                                    allowedAttributes: { a: ["href"] }
                                }

                                setContent(sanitizeHtml(evt.currentTarget.innerHTML, sanitizeConf))
                            }}
                            html={highlightMentions(content)} />

                        <input
                            ref={fileInputRef}
                            type="file"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                        />

                        <img onClick={() => fileInputRef.current?.click()} className="cursor-pointer h-[14px] w-[14px]" src={`/images/icons/ic-paperclip.svg`}></img>

                        {content && (
                            <img onClick={() => sendMessage(content)} className="cursor-pointer h-[14px] w-[14px]" src={`/images/icons/ic-paper-plane-blue.svg`}></img>
                        )}
                    </div>
                </div>
            </div>

            {attachmentDialog && (
                <AttachmentDialog
                    url={attachmentDialog.url}
                    metadata={attachmentDialog.metadata}
                    onDismiss={() => setAttachmentDialog(null)}
                />
            )}

            {createPollDialog && (
                <CreatePollDialog
                    onDismiss={() => setCreatePollDialog(false)}
                    createPoll={(v) => createPoll(v)}
                />
            )}

            {true && (
                <div ref={mentionPopupRef} style={{ top: popupPosition?.top, left: popupPosition?.left, opacity: popupPosition ? 1 : 0, pointerEvents: popupPosition ? 'all' : 'none' }} className="max-h-[300px] min-w-[200px] overflow-y-auto origin-bottom-left w-min fixed z-[300] rounded-md bg-material-medium shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none backdrop-blur-2xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                    <div className="p-1" role="none">
                        {userContext?.users?.filter((option) => (option.name || '').toLowerCase().includes((content.split('@').at(-1) || '').toLowerCase())).splice(0, 5).map((option) => {
                            return (
                                <div onClick={() => {
                                    setContent(content.replace(/\b\w+$/, option.name.split(' ')[0]))
                                }} className="group cursor-pointer flex gap-2 w-full p-1 hover:bg-primary-4 rounded-md items-center">
                                    {option.photoURL ?
                                        <img className="w-[24px] h-[24px] rounded-full" src={option?.photoURL}></img>
                                        :
                                        <div className="w-[24px] h-[24px] gradient-light-grey rounded-full text-white text-center">
                                            <p className="font-body mt-[4px]">{option.name[0]}</p>
                                        </div>
                                    }

                                    <div>
                                        <p className="font-body whitespace-nowrap group-hover:text-white">{option.name}</p>
                                        <p className="font-subheadline whitespace-nowrap text-neutral-5 group-hover:text-white/50">{option.email}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </>
    )
}

export default ChatPage