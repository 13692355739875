import { ReactNode, useState } from "react"
import ListItemRow from "../listItemRow/listItemRow"

const Accordion = (props: { 
    title: string, 
    subtitle: string, 
    arrowLabel?: string, 
    children: ReactNode,
    icon?: {
        icon: string,
        gradient: string,
    },
 }) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <div className="flex flex-col gap-2">
            <div className="cursor-pointer sticky top-0 z-[0]" onClick={() => setExpanded(!expanded)}>
                <ListItemRow
                    title={props.title}
                    subtitle={props.subtitle}
                    titleArrowTop={expanded}
                    titleArrowBottom={!expanded}
                    arrowLabel={props.arrowLabel}
                    icon={props.icon}
                />
            </div>

            {expanded && (
                <div className="flex flex-col bg-white border border-neutral-2 rounded-md">
                    {props.children}
                </div>
            )}
        </div>
    )
}

export default Accordion