import { useRef } from "react"

export interface ChartData {
    data: number[]
    color: string
}

export interface ChartLegend {
    title: string
    subtitle?: string
    color: string
}

const BarChart = (props: { datasets: ChartData[], breakpoints: number[], labels: string[], legends: ChartLegend[], selectedLabel: string }) => {
    const maxValue = Math.max(...props.breakpoints)

    const breakpointRef = useRef<HTMLDivElement>(null)

    const breakpointRect = breakpointRef.current?.getBoundingClientRect()

    return (
        <div className="h-full flex flex-col">
            <div className="relative h-full">
                <div style={{ marginLeft: `${(breakpointRect?.width || 0) + 4}px` }} className="absolute flex flex-col inset-0 p-2">
                    <div className="h-full flex">
                        {Array.from({ length: props.labels.length }, (item, index) =>
                            <div className={`flex flex-col w-full h-full border-${index === 0 ? 'x' : 'r'} border-dashed border-neutral-2 text-bottom`}>
                                <div className="h-full relative m-2 mb-[2px] mt-0 rounded-t-md flex flex-col">
                                    <div style={{ height: `${100 - (props.datasets.map((v) => v.data[index]).reduce((a, b) => a + b, 0) / maxValue * 100)}%` }}></div>

                                    {props.datasets.map((dataset, datasetIndex) => {
                                        const maxValue = Math.max(...props.breakpoints)
                                        const num = dataset.data[index]
                                        const percentage = num / maxValue * 100
                                        const lastValue = datasetIndex === 0 || props.datasets.findIndex((v) => v.data[index] !== 0) === datasetIndex

                                        return (
                                            <div style={{ zIndex: `${datasetIndex * 10 + 20}`, height: `${percentage}%` }} className={`bg-${dataset.color} ${lastValue ? 'rounded-t-md' : ''}`}></div>
                                        )
                                    })}
                                </div>

                                <p className={`font-subheadline text-${props.selectedLabel === props.labels[index] ? 'neutral-6' : 'neutral-5'} mt-auto ml-1 ${props.selectedLabel === props.labels[index] ? 'font-bold' : ''}`}>{props.labels[index]}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="absolute flex inset-0 mb-4 gap-1 p-2">
                    <div ref={breakpointRef} className="flex flex-col justify-between mt-[-6px] mb-[-6px] pr-1">
                        {props.breakpoints.reverse().map((breakpoint) => {
                            return (
                                <p className="font-caption-2 font-medium text-right text-neutral-5">{breakpoint}</p>
                            )
                        })}
                    </div>

                    <div className="w-full flex flex-col ">
                        {props.breakpoints.reverse().map((breakpoint, index) => {
                            return (
                                <div className={`w-full h-full border-${index === props.breakpoints.length - 1 ? 'y' : 't'} border-neutral-2`}></div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="flex gap-2">
                {props.legends.map((legend) => {
                    return (
                        <div className="flex items-center gap-2">
                            <div className={`w-2 h-2 bg-${legend.color} rounded-full`}></div>
                            <div>
                                <p className={`font-caption-1 ${legend.subtitle ? 'text-neutral-5' : 'text-neutral-6'}`}>{legend.title}</p>

                                {legend.subtitle && (
                                    <p className={`font-caption-1`}>{legend.subtitle}</p>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default BarChart