import { ReactNode, useState, useEffect, useContext } from "react"
import { set, ref, serverTimestamp, onValue, update } from "firebase/database"
import { database, firestore } from "../../firebase/firebase"
import { auth } from "../../firebase/firebase"
import { useCookies } from "react-cookie"

import TabPicker from "../tabPicker/tabPicker"
import ChatPage from "../chatPanel/chatPage"
import { Chatroom } from "../../model/chat/chat"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import { SidePanelContext } from "../../context/globalContext"

const SidePanel = (props: {
    titleLabel: string,
    title: string,
    icon: string,
    iconGradient: string
    chatRoomIds: string[],
    selectedTab?: string
    onDismiss: () => void, content: ReactNode, footer: ReactNode
}) => {
    const [selectedTab, setSelectedTab] = useState('Overview')

    const [chats, setChats] = useState<Chatroom[]>([])
    const [open, setOpen] = useState(false)

    const sidePanelContext = useContext(SidePanelContext)

    useEffect(() => {
        if (props.selectedTab) {
            setSelectedTab(props.selectedTab)
        }
    }, [props.selectedTab])

    useEffect(() => {
        setOpen(true)
        sidePanelContext?.setSidePanelOpen(true)

        return () => {
            sidePanelContext?.setSidePanelOpen(false)
            setOpen(false)
        }
    }, [])

    useEffect(() => {
        const chatroomIds = props.chatRoomIds.filter((v) => v)

        if (chatroomIds.length > 0) {
            const q = query(collection(firestore, "chats"), where('id', 'in', chatroomIds))

            const unsub = onSnapshot(q, (collection) => {
                const docs = collection.docs

                const chats = docs.map((doc) => doc.data() as Chatroom)

                const sortedChats = chats.sort((a, b) => {
                    return (a.lastMessage?.createdAt.seconds || 0) - (b.lastMessage?.createdAt.seconds || 0)
                })

                setChats(sortedChats)
            })

            return () => unsub()
        }
    }, [props.chatRoomIds])

    return (
        <div style={{ width: open ? '400px' : '0px', boxShadow: '0px 38px 90px 0px rgba(0, 0, 0, 0.25), 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.60)' }} className={`absolute top-0 right-0 flex flex-col h-screen bg-material-ultra border-l border-neutral-2 transition-width ease-in-out duration-300 z-[300]`}>
            <div className="flex flex-col p-2.5 gap-2.5 backdrop-blur-2xl">
                <div className="flex gap-1.5 my-2 items-center">
                    <div className={`h-[20px] min-w-[20px] gradient-${props.iconGradient} rounded-md flex items-center justify-center`}>
                        <img className="h-[14px] w-[14px]" src={`/images/icons/${props.icon}`}></img>
                    </div>

                    <p className="font-body">{props.titleLabel}</p>

                    <img onClick={() => props.onDismiss()} className="cursor-pointer ml-auto h-[20px] w-[20px]" src={`/images/icons/ic-xmark-circle.svg`}></img>
                </div>

                <p className="font-title-1 font-bold">{props.title}</p>

                <div className="flex gap-1 items-center">
                    <div className="w-[8px] h-[8px] bg-success rounded-full"></div>
                    <p className="font-subheadline text-neutral-5">Active</p>
                </div>

                <TabPicker
                    selectedTab={selectedTab}
                    tabs={["Overview", "Chat"]}
                    onChange={(v) => setSelectedTab(v)}
                />
            </div>

            {selectedTab === "Overview" ?
                <div className="bg-white flex-grow overflow-y-auto">
                    {props.content}
                </div>
                :
                chats.length === 1 ?
                    <ChatPage
                        chatroom={chats[0]}
                    />
                    :
                    <div className="h-full bg-white"></div>
            }

            {selectedTab === "Overview" && (
                <div className="flex p-2.5 backdrop-blur-2xl">
                    {props.footer}
                </div>
            )}
        </div>
    )
}

export default SidePanel