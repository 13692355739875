import { useLocation, useNavigate } from "react-router-dom"
import { AuthCredential, updatePassword, EmailAuthProvider, reauthenticateWithCredential, updateProfile } from "firebase/auth"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useState, useRef, useContext, useEffect } from "react"
import { signOut } from "firebase/auth"
import { auth, storage } from "../../firebase/firebase"
import { useCookies } from "react-cookie"
import { UserContext } from "../../context/globalContext"

import { User } from "../../model/user/user"

import Dialog from "../dialog/dialog"
import Input from "../input/input"
import PrimaryButton from "../primaryButton/primaryButton"
import { mockSpecialties } from "../../model/specialty/specialty"
import { locations } from "../../model/location/location"
import Toggle from "../toggle/toggle"

const ProfileDialog = (props: { onDismiss: () => void }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['refreshToken', 'email', 'displayName', 'uid']);

    const [currentPage, setCurrentPage] = useState('overview')
    const [team, setTeam] = useState('')
    const [userLocation, setUserLocation] = useState('')
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [verifyNewPassword, setVerifyNewPassword] = useState('')

    const [sleepWhenInactive, setSleepWhenInactive] = useState(true)
    const [personalisedView, setPersonalisedView] = useState(false)

    const [nameDialog, setNameDialog] = useState(false)
    const [currentPasswordDialog, setCurrentPasswordDialog] = useState(false)
    const [newPasswordDialog, setNewPasswordDialog] = useState(false)
    const [currentFile, setCurrentFile] = useState<File>();
    const [previewImage, setPreviewImage] = useState<string>("");

    const userContext = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()

    const fileInputRef = useRef<HTMLInputElement>(null)

    const logout = () => {
        signOut(auth)
            .then(() => {
                removeCookie('refreshToken', { path: '/' })
                removeCookie('email', { path: '/' })
                removeCookie('displayName', { path: '/' })
                removeCookie('uid', { path: '/' })

                navigate("/login", { replace: true, state: { previousLocationPathName: location.pathname} })
            })
            .catch((error) => {

            })
    }

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        setCurrentFile(selectedFiles?.[0]);
        setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));

        if (selectedFiles?.[0] && auth.currentUser) {
            const storageRef = ref(storage, `/images/${auth.currentUser?.uid}/${currentFile?.name}`);
            const uploadTask = uploadBytesResumable(storageRef, selectedFiles?.[0]);

            uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err), () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    if (auth.currentUser) {
                        updateProfile(auth.currentUser, {
                            photoURL: url
                        }).then(() => {

                        }).catch((error) => {

                        })

                        userContext?.updateUser({
                            photoURL: url
                        })
                    }
                })
            })
        }
    };

    useEffect(() => {
        setTeam(userContext?.user?.team || '')
        setUserLocation(userContext?.user?.location || '')
        setName((userContext?.user?.name || '').split(' ')[0])
        setSurname((userContext?.user?.name || '').split(' ')[1])
        setSleepWhenInactive(userContext?.user?.sleepWhenInactive || false)
        setPersonalisedView(userContext?.user?.personalisedView || false)
    }, [])

    const verifyCurrentPassword = () => {
        if (auth.currentUser) {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email || '',
                password
            )

            reauthenticateWithCredential(auth.currentUser, credential)
                .then(() => {
                    setCurrentPasswordDialog(false)
                    setNewPasswordDialog(true)
                })
                .catch(() => {

                })
        }
    }

    const updateNewPassword = async () => {
        if (auth.currentUser) {
            try {
                await updatePassword(auth.currentUser, newPassword)

                setNewPasswordDialog(false)
                userContext?.updateUser({
                    lastPasswordChange: new Date()
                })
            } catch {

            }
        }
    }

    return (
        <>
            <Dialog>
                {currentPage === 'overview' && (
                    <div className={`flex flex-col relative w-[500px] rounded-md`}>

                        <div className="flex flex-col p-6 w-full">
                            <div style={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.16)' }} className="relative h-[96px] w-[96px] p-6 rounded-full bg-white mx-auto bg-no-repeat bg-cover bg-[url('/public/images/backgrounds/bg-checker.png')]">
                                {!previewImage && userContext?.user?.photoURL && (
                                    <img className="h-[96px] w-[96px] absolute top-0 left-0 rounded-full" src={userContext?.user?.photoURL} alt="" />
                                )}

                                {previewImage && (
                                    <img className="h-[96px] w-[96px] absolute top-0 left-0 rounded-full" src={previewImage} alt="" />
                                )}

                                <div className="absolute inset-0 mt-[48px] ml-[-16px] h-[48px] w-[128px] bg-gradient-to-t from-black to-transparent" style={{ clipPath: 'circle(50% at 50% 0)' }}></div>

                                <button onClick={() => fileInputRef.current?.click()} className={`${previewImage ? 'text-white' : 'text-neutral-6'} text-white cursor-pointer absolute font-body bottom-3 left-[36px]`}>Edit</button>
                            </div>

                            <input
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />

                            <div className="flex flex-col gap-0.5 text-center mt-1.5">
                                <p className="font-title-2 font-semibold m-0">{userContext?.user?.name}</p>
                                <p className="font-title-3 m-0">{userContext?.user?.email}</p>
                            </div>

                            <div className="rounded-[5px] border border-neutral-2 bg-neutral-1 p-1 mt-6">
                                <div className="flex p-2.5 gap-2.5 items-center cursor-pointer" onClick={() => setCurrentPage('info')}>
                                    <div className={`h-[18px] min-w-[18px] gradient-black rounded-md flex items-center justify-center`}>
                                        <img className="h-[11px] w-[11px]" src={`/images/icons/ic-person-circle.svg`}></img>
                                    </div>

                                    <p className="font-body font-semibold">Personal Info</p>
                                    <img className="h-[12px] text-red-50 ml-auto" src={'/images/icons/ic-chevron-right.svg'}></img>
                                </div>

                                <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                <div className="flex p-2.5 gap-2.5 items-center cursor-pointer" onClick={() => setCurrentPage('preferences')}>
                                    <div className={`h-[18px] min-w-[18px] gradient-black rounded-md flex items-center justify-center`}>
                                        <img className="h-[11px] w-[11px]" src={`/images/icons/ic-gear-white.svg`}></img>
                                    </div>

                                    <p className="font-body font-semibold">Preferences</p>
                                    <img className="h-[12px] text-red-50 ml-auto" src={'/images/icons/ic-chevron-right.svg'}></img>
                                </div>

                                <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                <div className="flex p-2.5 gap-2.5 items-center cursor-pointer" onClick={() => setCurrentPage('security')}>
                                    <div className={`h-[18px] min-w-[18px] gradient-black rounded-md flex items-center justify-center`}>
                                        <img className="h-[11px] w-[11px]" src={`/images/icons/ic-lock-shield.svg`}></img>
                                    </div>

                                    <p className="font-body font-semibold">Security</p>
                                    <img className="h-[12px] text-red-50 ml-auto" src={'/images/icons/ic-chevron-right.svg'}></img>
                                </div>
                            </div>
                        </div>

                        <div className="mt-auto h-[1px] bg-neutral-2"></div>

                        <div className="flex px-5 py-4 items-center">
                            <PrimaryButton
                                title="Dismiss"
                                type="ghost"
                                size="lg"
                                onClick={() => props.onDismiss()}
                            />

                            <div className="flex gap-3 ml-auto">
                                <div className="flex bg-transparent rounded-[5px] px-2 py-1 justify-center items-center gap-1">
                                    <p onClick={() => logout()} className="font-body text-critical cursor-pointer">Log-Out</p>
                                </div>

                                <div onClick={() => navigate("/login/sleep", { replace: true, state: { previousLocationPathName: location.pathname} })} style={{ boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.05), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.30)' }} className="cursor-pointer flex bg-white rounded-[5px] px-2 py-1.5 justify-center items-center gap-1">
                                    <img className="h-[12px] w-[12px] mb-[2px]" src={'/images/icons/ic-sleep.svg'}></img>
                                    <p className="font-body">Sleep</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {currentPage === 'info' && (
                    <div className={`flex flex-col relative w-[500px] rounded-md`}>
                        <div className="flex flex-col p-6 w-full">
                            <div className="flex p-2.5 gap-3 items-center cursor-pointer" onClick={() => setCurrentPage('overview')}>
                                <img className="h-[12px]" src={'/images/icons/ic-chevron-left.svg'}></img>
                                <p className="font-title-3 font-semibold">Personal Info</p>
                            </div>

                            <div className="rounded-[5px] border border-neutral-2 bg-neutral-1 p-1 mt-6">
                                <div className="flex p-2.5 gap-2.5 items-center cursor-pointer" onClick={() => setNameDialog(true)}>
                                    <p className="font-body font-semibold">Name</p>
                                    <p className="font-body text-neutral-5 ml-auto">{userContext?.user?.name}</p>
                                    <img className="h-[12px]" src={'/images/icons/ic-chevron-right.svg'}></img>
                                </div>

                                <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                <div className="flex p-2.5 gap-2.5 items-center">
                                    <p className="font-body font-semibold">Team</p>

                                    <div className="ml-auto">
                                        <Input
                                            type="select"
                                            options={[
                                                {
                                                    label: "Team Name",
                                                    value: ""
                                                },
                                                ...mockSpecialties.map((team) => {
                                                    return {
                                                        label: team,
                                                        value: team
                                                    }
                                                })
                                            ]}
                                            withBorder={true}
                                            value={team}
                                            onChange={(v) => {
                                                setTeam(v)

                                                userContext?.updateUser({
                                                    team: v
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                <div className="flex p-2.5 gap-2.5 items-center">
                                    <p className="font-body font-semibold">Location</p>

                                    <div className="ml-auto">
                                        <Input
                                            type="select"
                                            options={[
                                                {
                                                    label: "Location",
                                                    value: ""
                                                },
                                                ...locations.map((team) => {
                                                    return {
                                                        label: team,
                                                        value: team
                                                    }
                                                })
                                            ]}
                                            withBorder={true}
                                            value={userLocation}
                                            onChange={(v) => {
                                                setUserLocation(v)

                                                userContext?.updateUser({
                                                    location: v
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                {currentPage === 'preferences' && (
                    <div className={`flex flex-col relative w-[500px] rounded-md`}>
                        <div className="flex flex-col p-6 w-full">
                            <div className="flex p-2.5 gap-3 items-center cursor-pointer" onClick={() => setCurrentPage('overview')}>
                                <img className="h-[12px]" src={'/images/icons/ic-chevron-left.svg'}></img>
                                <p className="font-title-3 font-semibold">Preferences</p>
                            </div>

                            <div className="rounded-[5px] border border-neutral-2 bg-neutral-1 p-1 mt-6">
                                <div className="flex p-2.5 gap-2.5 items-center">
                                    <div className="flex flex-col gap-0">
                                        <p className="font-body font-semibold">Personalised View</p>
                                        <p className="font-subheadline text-neutral-5">Automatically filter Osmosis to show by default all data based on my location and team</p>
                                    </div>

                                    <Toggle
                                        value={personalisedView}
                                        onChange={() => {
                                            setPersonalisedView(!personalisedView)

                                            userContext?.updateUser({
                                                personalisedView: !personalisedView
                                            })
                                        }}
                                    />
                                </div>

                                <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                <div className="flex p-2.5 gap-2.5 items-center">
                                    <div className="flex flex-col gap-0">
                                        <p className="font-body font-semibold">Sleep</p>
                                        <p className="font-subheadline text-neutral-5">When you are inactive for 10 minutes, Osmosis will log-out the platform without requiring you to specify the email address on the next log-in.</p>
                                    </div>

                                    <Toggle
                                        value={sleepWhenInactive}
                                        onChange={() => {
                                            setSleepWhenInactive(!sleepWhenInactive)

                                            userContext?.updateUser({
                                                sleepWhenInactive: sleepWhenInactive
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                {currentPage === 'security' && (
                    <div className={`flex flex-col relative w-[500px] rounded-md`}>
                        <div className="flex flex-col p-6 w-full">
                            <div className="flex p-2.5 gap-3 items-center cursor-pointer" onClick={() => setCurrentPage('overview')}>
                                <img className="h-[12px]" src={'/images/icons/ic-chevron-left.svg'}></img>
                                <p className="font-title-3 font-semibold">Sign-In & Security</p>
                            </div>

                            <div className="rounded-[5px] border border-neutral-2 bg-neutral-1 p-1 mt-6">
                                <div className="flex p-2.5 gap-2.5 items-center">
                                    <div className="flex flex-col gap-0">
                                        <p className="font-body font-semibold">Password</p>
                                        {userContext?.user?.lastPasswordChange ?
                                            <p className="font-subheadline text-neutral-5">{`Last Changed ${userContext.user.lastPasswordChange.toDate().toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}`}</p>
                                            :
                                            <p className="font-subheadline text-neutral-5">Never Changed</p>
                                        }
                                    </div>

                                    <div className="ml-auto">
                                        <PrimaryButton
                                            title="Change Password"
                                            type="white"
                                            size="sm"
                                            onClick={() => setCurrentPasswordDialog(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </Dialog>

            {nameDialog && (
                <Dialog>
                    <div className={`flex flex-col relative w-[320px] rounded-md`}>
                        <div className="flex flex-col p-6 pb-0 w-full gap-2">
                            <Input
                                type="text"
                                label="Name"
                                placeholder="Name"
                                value={name}
                                onChange={(v) => setName(v)}
                            />

                            <Input
                                type="text"
                                label="Surname"
                                placeholder="Surname"
                                value={surname}
                                onChange={(v) => setSurname(v)}
                            />
                        </div>

                        <div className="flex px-5 py-4 items-center">
                            <PrimaryButton
                                title="Dismiss"
                                type="ghost"
                                size="xl"
                                onClick={() => setNameDialog(false)}
                            />

                            <div className="ml-auto">
                                <PrimaryButton
                                    title="Update"
                                    type="primary"
                                    size="xl"
                                    onClick={() => {
                                        userContext?.updateUser({
                                            name: `${name} ${surname}`
                                        })

                                        setNameDialog(false)
                                    }}
                                    disabled={!name || !surname}
                                />
                            </div>

                        </div>
                    </div>
                </Dialog>
            )}

            {currentPasswordDialog && (
                <Dialog>
                    <div className="relative p-5 w-[330px]">
                        <div className="flex">
                            <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                                <img className="h-[46px] w-[46px] gradient-teal p-2.5 rounded-full" src={'/images/icons/ic-lock-shield.svg'}></img>
                            </div>

                        </div>

                        <div className="flex flex-col gap-[10px] text-center my-[20px]">
                            <p className="font-body font-neutral-6 font-semibold">Verify It’s You</p>
                            <p className="font-subheadline font-neutral-2">{`To access your personal security information, enter the password for Osmosis email “${userContext?.user?.email || cookies.email}”`}</p>
                        </div>

                        <div className="flex flex-col gap-2">

                            <Input
                                type="password"
                                label="Password"
                                placeholder="Password"
                                value={password}
                                onChange={(v) => setPassword(v)}
                            />
                        </div>


                        <div className="flex mt-5">
                            <PrimaryButton
                                title="Dismiss"
                                type="ghost"
                                size="xl"
                                onClick={() => setCurrentPasswordDialog(false)}
                            />

                            <div className="flex gap-2 ml-auto">
                                <PrimaryButton
                                    title="Verify"
                                    type="primary"
                                    size="xl"
                                    disabled={!password}
                                    onClick={() => verifyCurrentPassword()}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}

            {newPasswordDialog && (
                <Dialog>
                    <div className="relative p-5 w-[330px]">
                        <div className="flex flex-col gap-[10px] text-center mb-[20px]">
                            <p className="font-body font-neutral-6 font-semibold">Change Password</p>
                            <p className="font-subheadline font-neutral-2">Your password must be at least 8 characters long and include a number, an uppercase letter and a lowercase letter.</p>
                        </div>

                        <div className="flex flex-col gap-2">
                            <Input
                                type="password"
                                label="New Password"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(v) => setNewPassword(v)}
                            />

                            <Input
                                type="password"
                                label="Verify New Password"
                                placeholder="Verify New Password"
                                value={verifyNewPassword}
                                onChange={(v) => setVerifyNewPassword(v)}
                            />
                        </div>


                        <div className="flex mt-5">
                            <PrimaryButton
                                title="Dismiss"
                                type="ghost"
                                size="xl"
                                onClick={() => setNewPasswordDialog(false)}
                            />

                            <div className="flex gap-2 ml-auto">
                                <PrimaryButton
                                    title="Change"
                                    type="primary"
                                    size="xl"
                                    disabled={
                                        !newPassword ||
                                        !verifyNewPassword ||
                                        newPassword !== verifyNewPassword ||
                                        !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword)
                                    }
                                    onClick={() => updateNewPassword()}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    )
}

export default ProfileDialog