import { FullMetadata, getBlob, ref } from "firebase/storage"
import { storage } from "../../firebase/firebase"
import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"

const AttachmentDialog = (props: { url: string, metadata: FullMetadata | null, onDismiss: () => void }) => {
    const download = async () => {
        const fileRef = ref(storage, props.url)
        const blob = await getBlob(fileRef)
        const blobUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = `${props.metadata?.name}`
        link.click()
    }

    return (
        <Dialog>
            <div className="relative w-[490px] bg-white rounded-lg">
                <div className="flex p-4">
                    <img className="max-h-[490px]" src={props.url} />
                </div>

                <div className="h-[1px] bg-neutral-3"></div>

                <div className="flex gap-2 p-4">
                    <PrimaryButton
                        title="Dismiss"
                        type="gray"
                        size="sm"
                        onClick={() => props.onDismiss()}
                    />

                    <div className="ml-auto">
                        <PrimaryButton
                            title="Download"
                            type="gray"
                            size="sm"
                            icon="ic-square-arrow-fill.svg"
                            iconSize={12}
                            onClick={() => download()}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default AttachmentDialog