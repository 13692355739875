export interface MedicalItemDisruption {
    id: string
    weeklyDisrupted: number[]
}

export const mockMedicalItemDisruptions: MedicalItemDisruption[] = [
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        1,
        2,
        3,
        3,
        0,
        1,
        0,
        4,
        0,
        3,
        2,
        1,
        0,
        4,
        3,
        3,
        2,
        0,
        4,
        0,
        3,
        2,
        1,
        3,
        1,
        1,
        0,
        0,
        0,
        0,
        2,
        0,
        2,
        0,
        2,
        3,
        3,
        2,
        2,
        0,
        4,
        2,
        2,
        4,
        1,
        2,
        2,
        4,
        4,
        2,
        0,
        0
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        2,
        2,
        1,
        2,
        4,
        1,
        0,
        1,
        2,
        3,
        2,
        4,
        2,
        3,
        0,
        2,
        4,
        0,
        2,
        1,
        4,
        1,
        3,
        0,
        1,
        2,
        0,
        1,
        3,
        3,
        1,
        2,
        0,
        2,
        0,
        2,
        4,
        3,
        1,
        2,
        3,
        2,
        2,
        3,
        2,
        0,
        0,
        0,
        4,
        1,
        1,
        1
      ]
    },
    {
      "id": "200EA71C",
      "weeklyDisrupted": [
        3,
        3,
        2,
        0,
        0,
        1,
        0,
        3,
        1,
        2,
        4,
        3,
        4,
        4,
        1,
        4,
        2,
        1,
        3,
        0,
        4,
        3,
        4,
        2,
        0,
        2,
        0,
        0,
        2,
        0,
        0,
        3,
        0,
        0,
        2,
        2,
        4,
        4,
        2,
        4,
        1,
        0,
        4,
        0,
        2,
        4,
        1,
        0,
        1,
        1,
        3,
        3
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        3,
        0,
        3,
        2,
        1,
        4,
        0,
        3,
        0,
        1,
        4,
        3,
        3,
        4,
        2,
        4,
        0,
        0,
        3,
        4,
        1,
        0,
        4,
        4,
        1,
        3,
        3,
        0,
        4,
        4,
        0,
        3,
        4,
        1,
        3,
        3,
        4,
        0,
        4,
        1,
        4,
        1,
        3,
        1,
        0,
        3,
        0,
        0,
        4,
        4,
        4,
        2
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        4,
        2,
        1,
        0,
        4,
        3,
        3,
        3,
        4,
        3,
        4,
        2,
        0,
        4,
        0,
        1,
        2,
        2,
        2,
        3,
        3,
        3,
        4,
        1,
        3,
        4,
        1,
        2,
        1,
        3,
        4,
        3,
        0,
        2,
        0,
        4,
        3,
        3,
        2,
        0,
        1,
        3,
        3,
        2,
        0,
        2,
        0,
        0,
        3,
        1,
        2,
        3
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        1,
        3,
        1,
        3,
        2,
        4,
        0,
        3,
        0,
        3,
        4,
        4,
        3,
        2,
        2,
        4,
        4,
        1,
        3,
        0,
        2,
        3,
        4,
        1,
        0,
        0,
        2,
        0,
        4,
        4,
        3,
        1,
        2,
        2,
        0,
        2,
        4,
        0,
        2,
        0,
        2,
        4,
        0,
        4,
        0,
        0,
        1,
        0,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        0,
        3,
        2,
        4,
        4,
        0,
        0,
        2,
        4,
        4,
        3,
        4,
        1,
        3,
        3,
        3,
        3,
        4,
        4,
        2,
        3,
        4,
        4,
        4,
        0,
        3,
        0,
        1,
        3,
        3,
        0,
        0,
        2,
        1,
        2,
        0,
        4,
        3,
        1,
        1,
        4,
        4,
        2,
        3,
        0,
        4,
        4,
        0,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        2,
        0,
        0,
        4,
        0,
        4,
        0,
        0,
        0,
        3,
        4,
        4,
        4,
        1,
        2,
        1,
        3,
        0,
        1,
        0,
        3,
        4,
        0,
        0,
        0,
        0,
        0,
        3,
        4,
        4,
        0,
        3,
        0,
        0,
        4,
        3,
        4,
        4,
        1,
        1,
        3,
        2,
        4,
        4,
        3,
        4,
        0,
        0,
        4,
        3,
        4,
        0
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        4,
        4,
        1,
        4,
        4,
        2,
        2,
        0,
        0,
        2,
        2,
        0,
        4,
        4,
        4,
        4,
        2,
        1,
        0,
        1,
        4,
        4,
        1,
        0,
        4,
        0,
        0,
        1,
        3,
        3,
        0,
        4,
        0,
        1,
        1,
        1,
        0,
        4,
        0,
        0,
        1,
        1,
        0,
        0,
        0,
        0,
        4,
        0,
        3,
        3,
        2,
        1
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        0,
        4,
        0,
        1,
        0,
        0,
        4,
        3,
        4,
        2,
        2,
        4,
        4,
        0,
        3,
        1,
        0,
        0,
        0,
        1,
        4,
        4,
        3,
        1,
        0,
        3,
        0,
        0,
        0,
        4,
        4,
        3,
        4,
        0,
        3,
        4,
        2,
        2,
        0,
        0,
        0,
        2,
        1,
        0,
        0,
        3,
        0,
        3,
        3,
        2,
        2,
        4
      ]
    },
    {
      "id": "049BCC88",
      "weeklyDisrupted": [
        3,
        3,
        0,
        3,
        0,
        1,
        0,
        3,
        3,
        2,
        2,
        0,
        1,
        1,
        4,
        4,
        4,
        2,
        2,
        0,
        3,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        2,
        1,
        4,
        2,
        0,
        2,
        0,
        0,
        1,
        0,
        2,
        0,
        1,
        2,
        0,
        0,
        3,
        1,
        4,
        0,
        4,
        4,
        1,
        3
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        3,
        4,
        0,
        2,
        3,
        2,
        4,
        4,
        3,
        3,
        2,
        1,
        2,
        1,
        4,
        3,
        0,
        2,
        2,
        2,
        3,
        2,
        3,
        0,
        2,
        1,
        1,
        2,
        0,
        4,
        3,
        3,
        3,
        0,
        0,
        0,
        3,
        3,
        1,
        3,
        0,
        0,
        2,
        4,
        2,
        4,
        1,
        0
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        3,
        0,
        3,
        1,
        3,
        2,
        2,
        4,
        0,
        1,
        4,
        1,
        1,
        2,
        3,
        4,
        4,
        0,
        2,
        0,
        3,
        4,
        0,
        3,
        2,
        1,
        1,
        0,
        0,
        1,
        0,
        2,
        0,
        0,
        2,
        2,
        2,
        0,
        0,
        0,
        2,
        2,
        1,
        0,
        3,
        3,
        3,
        1,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        2,
        0,
        1,
        3,
        0,
        3,
        0,
        1,
        4,
        3,
        4,
        2,
        0,
        4,
        2,
        4,
        0,
        0,
        1,
        1,
        0,
        0,
        3,
        0,
        0,
        4,
        2,
        1,
        4,
        3,
        3,
        4,
        2,
        4,
        1,
        4,
        2,
        2,
        3,
        0,
        3,
        1,
        0,
        4,
        3,
        4,
        4,
        0,
        4,
        4,
        1,
        1
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        3,
        4,
        0,
        1,
        1,
        0,
        0,
        2,
        4,
        2,
        4,
        3,
        4,
        4,
        4,
        4,
        3,
        4,
        2,
        1,
        2,
        0,
        1,
        0,
        0,
        4,
        1,
        2,
        4,
        4,
        4,
        2,
        1,
        0,
        1,
        3,
        4,
        0,
        2,
        0,
        2,
        2,
        1,
        4,
        1,
        3,
        4,
        1,
        4,
        4,
        0,
        4
      ]
    },
    {
      "id": "D9345860",
      "weeklyDisrupted": [
        4,
        0,
        4,
        3,
        1,
        3,
        0,
        4,
        1,
        0,
        4,
        2,
        1,
        4,
        4,
        3,
        4,
        4,
        0,
        1,
        0,
        0,
        4,
        1,
        1,
        4,
        2,
        0,
        1,
        3,
        1,
        0,
        3,
        2,
        0,
        2,
        3,
        0,
        4,
        2,
        3,
        2,
        2,
        4,
        4,
        3,
        4,
        0,
        2,
        1,
        2,
        4
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        2,
        0,
        1,
        3,
        1,
        4,
        0,
        4,
        4,
        4,
        1,
        0,
        2,
        0,
        3,
        1,
        3,
        1,
        2,
        1,
        2,
        3,
        4,
        0,
        0,
        2,
        0,
        2,
        4,
        4,
        3,
        2,
        2,
        2,
        2,
        2,
        1,
        2,
        2,
        0,
        2,
        2,
        2,
        4,
        0,
        4,
        1,
        0,
        1,
        3,
        4,
        1
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        2,
        2,
        3,
        0,
        0,
        3,
        0,
        2,
        0,
        0,
        2,
        4,
        4,
        1,
        3,
        0,
        2,
        2,
        0,
        3,
        1,
        2,
        2,
        3,
        1,
        4,
        3,
        1,
        4,
        4,
        1,
        4,
        0,
        2,
        4,
        4,
        4,
        0,
        3,
        2,
        2,
        1,
        1,
        1,
        1,
        2,
        0,
        0,
        1,
        1,
        3,
        4
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        4,
        0,
        3,
        2,
        0,
        3,
        3,
        4,
        0,
        0,
        2,
        0,
        3,
        3,
        2,
        2,
        4,
        0,
        2,
        1,
        0,
        4,
        4,
        1,
        0,
        4,
        0,
        0,
        1,
        4,
        3,
        4,
        2,
        0,
        3,
        0,
        3,
        4,
        3,
        4,
        4,
        2,
        2,
        4,
        2,
        3,
        0,
        0,
        3,
        1,
        0,
        0
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        3,
        2,
        2,
        0,
        0,
        3,
        2,
        0,
        1,
        0,
        4,
        0,
        0,
        1,
        2,
        3,
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        1,
        1,
        4,
        3,
        0,
        0,
        3,
        1,
        0,
        1,
        2,
        4,
        4,
        1,
        2,
        1,
        0,
        0,
        3,
        0,
        2,
        1,
        1,
        4,
        2,
        2,
        0
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyDisrupted": [
        4,
        1,
        0,
        4,
        2,
        4,
        0,
        2,
        0,
        3,
        4,
        4,
        1,
        0,
        4,
        0,
        3,
        0,
        4,
        2,
        4,
        0,
        3,
        0,
        0,
        2,
        1,
        0,
        4,
        0,
        0,
        2,
        0,
        1,
        0,
        3,
        1,
        0,
        0,
        0,
        4,
        2,
        3,
        2,
        1,
        4,
        2,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        4,
        2,
        0,
        0,
        2,
        4,
        2,
        0,
        1,
        2,
        3,
        0,
        2,
        3,
        1,
        4,
        2,
        0,
        1,
        0,
        0,
        3,
        2,
        0,
        0,
        3,
        0,
        2,
        3,
        0,
        3,
        3,
        4,
        1,
        0,
        3,
        0,
        0,
        0,
        0,
        2,
        0,
        1,
        0,
        0,
        0,
        0,
        2,
        4,
        1,
        1,
        0
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        0,
        0,
        0,
        1,
        2,
        2,
        2,
        1,
        4,
        4,
        0,
        4,
        4,
        2,
        3,
        0,
        2,
        2,
        0,
        1,
        3,
        4,
        4,
        0,
        0,
        3,
        3,
        3,
        0,
        2,
        2,
        1,
        0,
        1,
        2,
        1,
        2,
        4,
        0,
        0,
        3,
        3,
        3,
        3,
        2,
        4,
        0,
        1,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "D33377F5",
      "weeklyDisrupted": [
        2,
        4,
        1,
        1,
        0,
        3,
        0,
        0,
        2,
        0,
        0,
        0,
        4,
        0,
        4,
        1,
        3,
        0,
        0,
        4,
        4,
        3,
        1,
        2,
        0,
        1,
        3,
        3,
        2,
        2,
        2,
        2,
        4,
        0,
        3,
        2,
        2,
        2,
        0,
        0,
        0,
        3,
        2,
        3,
        0,
        1,
        0,
        4,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        3,
        2,
        0,
        1,
        0,
        4,
        0,
        2,
        1,
        1,
        4,
        0,
        0,
        3,
        4,
        3,
        2,
        0,
        3,
        1,
        1,
        0,
        1,
        3,
        1,
        3,
        1,
        2,
        3,
        3,
        1,
        4,
        0,
        3,
        2,
        0,
        4,
        4,
        0,
        0,
        1,
        0,
        1,
        4,
        2,
        1,
        2,
        2,
        2,
        1,
        2,
        0
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        2,
        3,
        4,
        1,
        1,
        0,
        0,
        3,
        4,
        4,
        3,
        0,
        0,
        2,
        4,
        0,
        0,
        3,
        0,
        1,
        4,
        4,
        4,
        2,
        0,
        1,
        0,
        2,
        4,
        0,
        0,
        3,
        0,
        1,
        0,
        2,
        2,
        0,
        4,
        0,
        3,
        2,
        2,
        0,
        2,
        3,
        0,
        2,
        2,
        2,
        1,
        4
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        4,
        0,
        0,
        4,
        1,
        4,
        2,
        4,
        4,
        0,
        1,
        0,
        2,
        4,
        4,
        3,
        3,
        0,
        4,
        0,
        0,
        2,
        0,
        2,
        0,
        0,
        2,
        0,
        3,
        3,
        0,
        2,
        4,
        0,
        2,
        4,
        4,
        0,
        4,
        0,
        4,
        4,
        1,
        4,
        2,
        0,
        2,
        0,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        3,
        3,
        0,
        4,
        1,
        1,
        0,
        2,
        1,
        4,
        4,
        0,
        4,
        1,
        2,
        4,
        1,
        0,
        1,
        0,
        0,
        2,
        4,
        3,
        0,
        0,
        2,
        4,
        0,
        3,
        3,
        0,
        3,
        2,
        3,
        3,
        4,
        4,
        1,
        0,
        2,
        1,
        1,
        3,
        0,
        3,
        2,
        1,
        4,
        4,
        3,
        0
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        1,
        4,
        4,
        3,
        1,
        1,
        0,
        3,
        2,
        3,
        0,
        4,
        4,
        3,
        2,
        3,
        4,
        4,
        4,
        1,
        4,
        3,
        4,
        3,
        0,
        4,
        0,
        4,
        1,
        3,
        2,
        2,
        2,
        2,
        4,
        1,
        4,
        4,
        1,
        0,
        3,
        1,
        1,
        2,
        1,
        2,
        1,
        1,
        2,
        0,
        3,
        0
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        4,
        3,
        0,
        1,
        1,
        4,
        4,
        3,
        4,
        4,
        4,
        3,
        3,
        4,
        1,
        0,
        2,
        1,
        1,
        0,
        0,
        3,
        0,
        1,
        0,
        4,
        0,
        3,
        2,
        3,
        0,
        3,
        3,
        2,
        1,
        0,
        4,
        2,
        0,
        0,
        0,
        2,
        3,
        3,
        0,
        4,
        1,
        0,
        4,
        4,
        1,
        0
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        4,
        0,
        4,
        2,
        2,
        1,
        2,
        1,
        1,
        3,
        2,
        4,
        4,
        3,
        1,
        3,
        2,
        0,
        0,
        3,
        3,
        4,
        2,
        4,
        0,
        0,
        2,
        0,
        4,
        4,
        4,
        3,
        1,
        2,
        0,
        4,
        2,
        0,
        4,
        4,
        0,
        4,
        0,
        1,
        4,
        3,
        1,
        3,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        4,
        3,
        2,
        3,
        1,
        4,
        2,
        0,
        4,
        3,
        0,
        4,
        0,
        2,
        1,
        2,
        4,
        2,
        0,
        0,
        1,
        4,
        0,
        0,
        1,
        1,
        3,
        0,
        4,
        3,
        1,
        2,
        2,
        0,
        2,
        4,
        3,
        4,
        3,
        0,
        4,
        1,
        0,
        0,
        0,
        4,
        0,
        1,
        2,
        1,
        0,
        3
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        2,
        0,
        1,
        4,
        0,
        2,
        0,
        1,
        3,
        3,
        4,
        3,
        3,
        1,
        1,
        1,
        4,
        0,
        1,
        0,
        4,
        4,
        2,
        1,
        0,
        2,
        2,
        0,
        0,
        4,
        3,
        3,
        0,
        0,
        1,
        2,
        4,
        4,
        0,
        0,
        2,
        1,
        0,
        1,
        3,
        4,
        1,
        1,
        1,
        0,
        4,
        3
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        0,
        3,
        1,
        0,
        0,
        2,
        4,
        2,
        2,
        3,
        1,
        4,
        3,
        4,
        1,
        0,
        3,
        1,
        4,
        3,
        1,
        4,
        4,
        4,
        2,
        4,
        0,
        2,
        0,
        0,
        4,
        3,
        0,
        1,
        4,
        1,
        1,
        2,
        1,
        1,
        0,
        2,
        3,
        1,
        4,
        4,
        1,
        1,
        2,
        0,
        2,
        0
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        3,
        0,
        1,
        4,
        3,
        2,
        1,
        1,
        4,
        0,
        3,
        0,
        4,
        0,
        2,
        3,
        2,
        0,
        1,
        2,
        0,
        0,
        2,
        4,
        0,
        0,
        0,
        0,
        1,
        4,
        4,
        4,
        3,
        1,
        0,
        0,
        0,
        2,
        3,
        2,
        4,
        4,
        0,
        4,
        3,
        4,
        1,
        0,
        2,
        1,
        0,
        4
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        4,
        2,
        2,
        0,
        1,
        1,
        4,
        1,
        2,
        0,
        4,
        3,
        4,
        0,
        2,
        1,
        3,
        1,
        1,
        4,
        4,
        0,
        2,
        2,
        0,
        4,
        0,
        0,
        0,
        4,
        2,
        2,
        0,
        2,
        4,
        2,
        3,
        2,
        0,
        3,
        4,
        1,
        0,
        2,
        3,
        4,
        1,
        2,
        3,
        4,
        0,
        3
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        4,
        1,
        1,
        4,
        0,
        3,
        0,
        3,
        2,
        1,
        1,
        3,
        0,
        4,
        4,
        4,
        3,
        1,
        0,
        2,
        0,
        2,
        3,
        0,
        3,
        2,
        0,
        0,
        4,
        4,
        0,
        2,
        4,
        1,
        3,
        2,
        4,
        4,
        1,
        0,
        2,
        1,
        0,
        4,
        0,
        3,
        0,
        0,
        3,
        1,
        3,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        3,
        0,
        2,
        0,
        0,
        4,
        0,
        1,
        2,
        0,
        4,
        3,
        1,
        1,
        2,
        4,
        2,
        4,
        2,
        2,
        4,
        3,
        0,
        2,
        1,
        1,
        0,
        3,
        0,
        0,
        3,
        4,
        2,
        0,
        2,
        0,
        3,
        4,
        1,
        0,
        3,
        2,
        0,
        2,
        4,
        4,
        0,
        4,
        3,
        0,
        4,
        0
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        3,
        0,
        3,
        1,
        0,
        4,
        2,
        3,
        1,
        3,
        4,
        2,
        0,
        3,
        4,
        0,
        3,
        0,
        2,
        0,
        1,
        3,
        0,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        0,
        1,
        4,
        0,
        2,
        4,
        3,
        3,
        2,
        0,
        0,
        2,
        3,
        2,
        1,
        0,
        0,
        2,
        2,
        1,
        2,
        2
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        3,
        1,
        0,
        1,
        3,
        1,
        1,
        4,
        0,
        2,
        0,
        2,
        0,
        4,
        3,
        1,
        3,
        0,
        3,
        0,
        2,
        4,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        2,
        4,
        3,
        3,
        1,
        0,
        4,
        2,
        0,
        0,
        1,
        3,
        1,
        0,
        4,
        1,
        4,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        0,
        3,
        2,
        4,
        1,
        0,
        2,
        2,
        2,
        0,
        4,
        4,
        4,
        0,
        2,
        4,
        2,
        0,
        3,
        1,
        2,
        2,
        4,
        4,
        0,
        3,
        2,
        0,
        4,
        3,
        2,
        4,
        0,
        0,
        0,
        3,
        3,
        2,
        4,
        1,
        3,
        4,
        2,
        0,
        4,
        2,
        1,
        3,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyDisrupted": [
        0,
        0,
        3,
        3,
        2,
        4,
        0,
        4,
        4,
        0,
        2,
        3,
        4,
        1,
        2,
        1,
        4,
        0,
        3,
        0,
        2,
        4,
        2,
        2,
        0,
        2,
        2,
        0,
        3,
        2,
        0,
        1,
        0,
        0,
        2,
        4,
        4,
        4,
        0,
        1,
        1,
        1,
        4,
        3,
        0,
        1,
        1,
        2,
        4,
        1,
        3,
        4
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        0,
        4,
        3,
        1,
        0,
        0,
        4,
        1,
        2,
        2,
        1,
        1,
        0,
        0,
        4,
        4,
        1,
        2,
        2,
        2,
        0,
        4,
        4,
        3,
        3,
        1,
        1,
        0,
        2,
        0,
        3,
        3,
        0,
        4,
        0,
        4,
        2,
        2,
        3,
        0,
        2,
        2,
        0,
        2,
        0,
        2,
        1,
        3,
        0,
        4,
        2,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        3,
        2,
        0,
        1,
        2,
        3,
        0,
        0,
        4,
        1,
        3,
        0,
        0,
        4,
        2,
        0,
        2,
        0,
        2,
        2,
        3,
        1,
        0,
        1,
        2,
        3,
        2,
        3,
        3,
        2,
        3,
        3,
        0,
        0,
        0,
        2,
        0,
        4,
        0,
        0,
        2,
        4,
        0,
        4,
        0,
        4,
        0,
        1,
        4,
        1,
        1,
        0
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        4,
        1,
        1,
        1,
        0,
        1,
        0,
        2,
        1,
        2,
        4,
        0,
        4,
        2,
        2,
        2,
        4,
        0,
        2,
        2,
        4,
        4,
        3,
        2,
        0,
        0,
        0,
        0,
        4,
        4,
        2,
        4,
        4,
        2,
        0,
        3,
        4,
        4,
        3,
        0,
        4,
        2,
        1,
        0,
        2,
        4,
        0,
        2,
        2,
        2,
        2,
        2
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        4,
        1,
        0,
        1,
        1,
        4,
        2,
        1,
        4,
        4,
        4,
        4,
        4,
        0,
        2,
        0,
        2,
        1,
        0,
        0,
        0,
        1,
        4,
        0,
        1,
        4,
        1,
        0,
        0,
        2,
        2,
        1,
        2,
        0,
        0,
        4,
        0,
        4,
        2,
        0,
        1,
        1,
        0,
        2,
        1,
        3,
        2,
        2,
        2,
        4,
        4,
        2
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        4,
        2,
        2,
        0,
        0,
        2,
        0,
        2,
        4,
        2,
        4,
        4,
        1,
        3,
        3,
        0,
        2,
        0,
        3,
        0,
        4,
        1,
        0,
        2,
        0,
        4,
        0,
        1,
        4,
        3,
        0,
        4,
        1,
        0,
        1,
        4,
        4,
        4,
        0,
        0,
        4,
        2,
        1,
        3,
        0,
        0,
        3,
        2,
        4,
        3,
        0,
        1
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        1,
        3,
        2,
        0,
        2,
        0,
        1,
        3,
        4,
        3,
        4,
        4,
        4,
        2,
        2,
        4,
        1,
        2,
        0,
        0,
        0,
        4,
        3,
        0,
        0,
        0,
        2,
        1,
        0,
        1,
        2,
        0,
        0,
        1,
        2,
        2,
        2,
        4,
        2,
        0,
        0,
        2,
        1,
        4,
        2,
        1,
        2,
        3,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        0,
        2,
        2,
        3,
        0,
        4,
        0,
        4,
        4,
        4,
        4,
        2,
        3,
        3,
        4,
        0,
        4,
        1,
        4,
        1,
        4,
        1,
        2,
        0,
        0,
        4,
        0,
        2,
        0,
        0,
        1,
        4,
        4,
        0,
        1,
        3,
        4,
        2,
        4,
        1,
        1,
        2,
        4,
        0,
        1,
        0,
        1,
        0,
        1,
        4,
        4,
        4
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        1,
        2,
        4,
        1,
        4,
        0,
        3,
        2,
        2,
        1,
        4,
        2,
        1,
        4,
        4,
        1,
        1,
        0,
        0,
        0,
        4,
        4,
        3,
        3,
        0,
        1,
        2,
        1,
        4,
        1,
        4,
        4,
        2,
        0,
        0,
        1,
        0,
        4,
        4,
        2,
        0,
        2,
        2,
        3,
        3,
        3,
        0,
        0,
        2,
        0,
        3,
        2
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        0,
        2,
        1,
        2,
        0,
        1,
        1,
        3,
        4,
        4,
        3,
        3,
        1,
        3,
        3,
        2,
        0,
        3,
        1,
        1,
        4,
        4,
        4,
        0,
        2,
        2,
        2,
        1,
        2,
        0,
        1,
        4,
        2,
        2,
        2,
        4,
        0,
        4,
        0,
        4,
        4,
        2,
        4,
        2,
        0,
        2,
        2,
        0,
        4,
        4,
        2,
        2
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        3,
        3,
        2,
        3,
        4,
        4,
        1,
        0,
        2,
        3,
        2,
        1,
        4,
        1,
        3,
        4,
        2,
        4,
        1,
        3,
        4,
        4,
        2,
        0,
        0,
        0,
        1,
        4,
        3,
        3,
        4,
        2,
        4,
        3,
        0,
        4,
        3,
        0,
        2,
        0,
        4,
        2,
        0,
        2,
        0,
        2,
        0,
        3,
        4,
        4,
        1,
        3
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        4,
        1,
        1,
        1,
        4,
        2,
        1,
        0,
        3,
        2,
        4,
        2,
        3,
        1,
        1,
        3,
        2,
        2,
        0,
        3,
        0,
        4,
        2,
        3,
        0,
        0,
        2,
        1,
        0,
        4,
        4,
        2,
        0,
        4,
        4,
        4,
        2,
        2,
        0,
        0,
        2,
        4,
        4,
        2,
        0,
        3,
        1,
        1,
        0,
        4,
        4,
        4
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        2,
        0,
        1,
        2,
        1,
        3,
        0,
        1,
        2,
        1,
        4,
        4,
        0,
        3,
        3,
        1,
        4,
        0,
        2,
        4,
        2,
        1,
        1,
        0,
        2,
        2,
        2,
        3,
        4,
        4,
        1,
        4,
        0,
        0,
        2,
        2,
        4,
        1,
        0,
        0,
        2,
        0,
        0,
        1,
        0,
        0,
        0,
        4,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        1,
        0,
        3,
        1,
        0,
        4,
        2,
        0,
        1,
        4,
        2,
        0,
        2,
        4,
        2,
        4,
        2,
        0,
        4,
        1,
        0,
        0,
        3,
        1,
        1,
        3,
        0,
        3,
        4,
        3,
        4,
        1,
        1,
        2,
        2,
        3,
        3,
        4,
        2,
        0,
        2,
        2,
        0,
        3,
        0,
        0,
        2,
        2,
        4,
        3,
        0,
        0
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        0,
        0,
        1,
        2,
        0,
        2,
        1,
        2,
        3,
        0,
        1,
        4,
        4,
        3,
        1,
        1,
        0,
        1,
        3,
        4,
        0,
        1,
        3,
        2,
        0,
        2,
        2,
        0,
        3,
        2,
        4,
        4,
        3,
        2,
        4,
        2,
        4,
        4,
        0,
        0,
        4,
        4,
        0,
        2,
        2,
        4,
        0,
        3,
        0,
        1,
        3,
        0
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        2,
        0,
        1,
        0,
        4,
        2,
        0,
        1,
        4,
        1,
        3,
        4,
        4,
        0,
        4,
        4,
        3,
        0,
        2,
        1,
        4,
        1,
        3,
        2,
        0,
        4,
        0,
        0,
        2,
        3,
        0,
        2,
        1,
        1,
        1,
        3,
        4,
        0,
        0,
        4,
        4,
        3,
        0,
        1,
        3,
        0,
        2,
        3,
        4,
        2,
        3,
        4
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        4,
        1,
        3,
        2,
        1,
        2,
        4,
        0,
        0,
        2,
        1,
        1,
        4,
        1,
        4,
        2,
        3,
        3,
        1,
        4,
        2,
        1,
        4,
        1,
        0,
        0,
        0,
        1,
        3,
        4,
        1,
        2,
        2,
        0,
        4,
        1,
        3,
        4,
        1,
        0,
        3,
        2,
        0,
        4,
        0,
        2,
        4,
        1,
        2,
        2,
        4,
        0
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        2,
        3,
        1,
        1,
        2,
        0,
        4,
        3,
        1,
        3,
        1,
        0,
        4,
        3,
        3,
        2,
        2,
        1,
        3,
        4,
        2,
        4,
        0,
        0,
        0,
        1,
        2,
        0,
        1,
        0,
        0,
        2,
        0,
        1,
        2,
        2,
        4,
        4,
        0,
        3,
        2,
        3,
        2,
        3,
        1,
        1,
        4,
        4,
        4,
        0,
        1,
        3
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        4,
        0,
        3,
        1,
        1,
        4,
        0,
        3,
        1,
        0,
        2,
        4,
        2,
        4,
        2,
        3,
        2,
        1,
        3,
        0,
        4,
        2,
        4,
        1,
        0,
        3,
        1,
        0,
        0,
        4,
        3,
        4,
        2,
        0,
        3,
        3,
        4,
        4,
        3,
        0,
        4,
        2,
        3,
        4,
        3,
        4,
        4,
        3,
        4,
        0,
        4,
        1
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        1,
        4,
        0,
        1,
        3,
        1,
        0,
        4,
        3,
        0,
        4,
        4,
        4,
        1,
        0,
        4,
        2,
        0,
        0,
        4,
        0,
        2,
        4,
        0,
        2,
        4,
        1,
        0,
        3,
        0,
        3,
        3,
        2,
        0,
        1,
        4,
        4,
        0,
        0,
        0,
        4,
        2,
        0,
        3,
        2,
        2,
        3,
        1,
        4,
        3,
        2,
        0
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        4,
        0,
        0,
        2,
        0,
        3,
        0,
        2,
        0,
        0,
        4,
        4,
        2,
        0,
        4,
        3,
        2,
        0,
        0,
        0,
        0,
        4,
        0,
        0,
        0,
        2,
        0,
        1,
        1,
        1,
        4,
        2,
        0,
        2,
        4,
        3,
        4,
        4,
        2,
        0,
        1,
        1,
        0,
        4,
        2,
        1,
        3,
        1,
        3,
        4,
        4,
        4
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        3,
        1,
        0,
        3,
        1,
        2,
        4,
        0,
        2,
        4,
        0,
        0,
        1,
        2,
        1,
        4,
        1,
        0,
        3,
        1,
        4,
        3,
        2,
        0,
        0,
        3,
        1,
        3,
        1,
        0,
        1,
        2,
        0,
        1,
        4,
        3,
        4,
        4,
        0,
        1,
        4,
        3,
        1,
        1,
        3,
        1,
        2,
        2,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        3,
        3,
        3,
        2,
        0,
        1,
        0,
        3,
        3,
        0,
        1,
        3,
        3,
        0,
        1,
        1,
        0,
        2,
        3,
        4,
        2,
        4,
        0,
        0,
        0,
        0,
        3,
        3,
        1,
        0,
        0,
        2,
        4,
        0,
        3,
        1,
        1,
        4,
        2,
        4,
        1,
        3,
        0,
        3,
        4,
        3,
        0,
        0,
        4,
        2,
        2,
        0
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        3,
        1,
        2,
        1,
        1,
        3,
        4,
        0,
        2,
        4,
        4,
        4,
        2,
        4,
        2,
        0,
        1,
        4,
        2,
        1,
        2,
        3,
        0,
        0,
        0,
        4,
        3,
        4,
        2,
        4,
        4,
        3,
        3,
        2,
        2,
        2,
        2,
        2,
        0,
        0,
        1,
        3,
        4,
        4,
        2,
        2,
        0,
        3,
        4,
        1,
        1,
        1
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        3,
        0,
        1,
        0,
        0,
        3,
        3,
        2,
        3,
        0,
        3,
        3,
        3,
        1,
        4,
        4,
        4,
        1,
        1,
        0,
        0,
        0,
        3,
        2,
        0,
        4,
        1,
        4,
        4,
        0,
        1,
        1,
        2,
        1,
        3,
        2,
        4,
        2,
        1,
        0,
        0,
        1,
        4,
        4,
        1,
        1,
        0,
        0,
        3,
        3,
        2,
        0
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        3,
        1,
        2,
        1,
        1,
        4,
        0,
        0,
        1,
        4,
        2,
        4,
        2,
        1,
        2,
        4,
        1,
        4,
        2,
        4,
        1,
        1,
        2,
        0,
        0,
        2,
        2,
        0,
        3,
        1,
        4,
        1,
        1,
        2,
        3,
        3,
        4,
        0,
        3,
        1,
        2,
        2,
        0,
        0,
        4,
        4,
        0,
        3,
        1,
        4,
        1,
        2
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        4,
        4,
        0,
        3,
        0,
        3,
        1,
        4,
        1,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        4,
        0,
        4,
        3,
        3,
        0,
        4,
        1,
        0,
        1,
        1,
        0,
        4,
        4,
        0,
        4,
        1,
        0,
        0,
        2,
        4,
        0,
        1,
        1,
        3,
        4,
        0,
        0,
        0,
        1,
        1,
        4,
        3,
        4,
        0,
        3
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        3,
        2,
        4,
        3,
        0,
        3,
        0,
        1,
        4,
        4,
        1,
        0,
        4,
        1,
        0,
        3,
        1,
        4,
        0,
        3,
        1,
        2,
        2,
        2,
        0,
        0,
        3,
        2,
        2,
        1,
        2,
        2,
        1,
        2,
        3,
        4,
        2,
        4,
        4,
        1,
        3,
        4,
        1,
        0,
        0,
        1,
        0,
        1,
        4,
        4,
        1,
        1
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        4,
        0,
        4,
        0,
        4,
        4,
        3,
        0,
        4,
        0,
        4,
        3,
        4,
        4,
        2,
        2,
        4,
        2,
        0,
        4,
        2,
        4,
        3,
        1,
        0,
        3,
        0,
        0,
        4,
        2,
        1,
        3,
        4,
        2,
        3,
        4,
        4,
        4,
        0,
        0,
        3,
        2,
        0,
        4,
        3,
        3,
        0,
        4,
        0,
        4,
        1,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        0,
        4,
        1,
        4,
        1,
        0,
        1,
        3,
        0,
        0,
        1,
        3,
        0,
        2,
        3,
        1,
        2,
        4,
        1,
        0,
        2,
        4,
        4,
        1,
        1,
        2,
        0,
        0,
        1,
        0,
        4,
        1,
        2,
        2,
        0,
        3,
        4,
        4,
        4,
        4,
        0,
        1,
        2,
        1,
        2,
        4,
        4,
        3,
        0,
        0,
        3,
        3
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        2,
        3,
        2,
        4,
        0,
        3,
        4,
        0,
        1,
        1,
        2,
        0,
        2,
        1,
        3,
        1,
        4,
        0,
        0,
        3,
        2,
        4,
        2,
        1,
        0,
        0,
        4,
        0,
        0,
        0,
        1,
        3,
        3,
        1,
        2,
        4,
        1,
        4,
        2,
        1,
        0,
        3,
        4,
        4,
        4,
        3,
        0,
        2,
        0,
        4,
        4,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        3,
        3,
        2,
        1,
        4,
        2,
        0,
        4,
        1,
        1,
        4,
        0,
        2,
        0,
        2,
        4,
        1,
        1,
        0,
        1,
        1,
        4,
        0,
        0,
        0,
        2,
        0,
        4,
        3,
        0,
        0,
        4,
        4,
        3,
        3,
        3,
        2,
        2,
        1,
        0,
        2,
        3,
        1,
        3,
        0,
        4,
        1,
        1,
        2,
        1,
        0,
        4
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        2,
        4,
        0,
        3,
        0,
        3,
        0,
        0,
        4,
        0,
        2,
        1,
        4,
        3,
        1,
        0,
        2,
        3,
        3,
        2,
        4,
        2,
        1,
        0,
        2,
        4,
        0,
        2,
        0,
        3,
        3,
        0,
        1,
        2,
        4,
        1,
        3,
        4,
        0,
        1,
        4,
        4,
        1,
        3,
        2,
        2,
        2,
        1,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        4,
        3,
        4,
        0,
        2,
        2,
        3,
        4,
        2,
        0,
        4,
        4,
        2,
        1,
        2,
        0,
        0,
        0,
        0,
        2,
        1,
        2,
        0,
        1,
        2,
        4,
        2,
        0,
        0,
        0,
        0,
        2,
        1,
        2,
        3,
        4,
        4,
        0,
        0,
        2,
        4,
        1,
        0,
        3,
        0,
        4,
        1,
        4,
        4,
        0,
        3,
        2
      ]
    },
    {
      "id": "0AB87239",
      "weeklyDisrupted": [
        2,
        1,
        0,
        2,
        0,
        3,
        0,
        1,
        1,
        0,
        4,
        1,
        2,
        3,
        2,
        1,
        0,
        1,
        0,
        2,
        0,
        0,
        4,
        4,
        1,
        4,
        2,
        3,
        1,
        4,
        2,
        2,
        3,
        3,
        4,
        1,
        3,
        4,
        4,
        2,
        4,
        2,
        3,
        3,
        4,
        2,
        4,
        0,
        0,
        2,
        3,
        1
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        4,
        1,
        0,
        3,
        4,
        4,
        3,
        4,
        4,
        2,
        3,
        4,
        3,
        1,
        2,
        4,
        2,
        2,
        2,
        0,
        0,
        4,
        2,
        0,
        0,
        4,
        2,
        3,
        0,
        2,
        1,
        2,
        2,
        4,
        3,
        2,
        4,
        4,
        3,
        0,
        1,
        4,
        0,
        4,
        2,
        2,
        1,
        0,
        3,
        0,
        3,
        2
      ]
    },
    {
      "id": "D33377F5",
      "weeklyDisrupted": [
        2,
        4,
        0,
        1,
        0,
        2,
        4,
        1,
        4,
        0,
        3,
        4,
        1,
        0,
        2,
        4,
        2,
        0,
        0,
        4,
        4,
        1,
        1,
        1,
        4,
        1,
        0,
        1,
        4,
        0,
        1,
        4,
        0,
        0,
        0,
        4,
        0,
        4,
        2,
        0,
        3,
        3,
        0,
        3,
        0,
        0,
        2,
        2,
        4,
        3,
        1,
        4
      ]
    },
    {
      "id": "048701CE",
      "weeklyDisrupted": [
        3,
        0,
        4,
        4,
        2,
        3,
        0,
        0,
        2,
        4,
        1,
        4,
        4,
        0,
        2,
        4,
        3,
        0,
        3,
        1,
        2,
        3,
        4,
        1,
        0,
        3,
        1,
        4,
        4,
        4,
        1,
        4,
        3,
        1,
        2,
        3,
        4,
        3,
        2,
        1,
        2,
        3,
        1,
        4,
        3,
        1,
        0,
        1,
        3,
        4,
        4,
        4
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        4,
        1,
        0,
        0,
        4,
        3,
        2,
        1,
        4,
        3,
        4,
        0,
        3,
        4,
        0,
        4,
        4,
        0,
        3,
        0,
        0,
        0,
        2,
        2,
        0,
        3,
        0,
        0,
        2,
        2,
        3,
        2,
        3,
        0,
        4,
        3,
        4,
        4,
        0,
        0,
        4,
        4,
        2,
        4,
        0,
        4,
        1,
        0,
        1,
        4,
        0,
        0
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyDisrupted": [
        3,
        1,
        1,
        0,
        1,
        2,
        0,
        1,
        2,
        1,
        3,
        3,
        4,
        1,
        1,
        2,
        1,
        0,
        3,
        3,
        3,
        1,
        4,
        2,
        1,
        0,
        0,
        3,
        1,
        0,
        0,
        3,
        2,
        1,
        2,
        4,
        4,
        4,
        1,
        4,
        2,
        2,
        0,
        1,
        2,
        3,
        3,
        0,
        1,
        1,
        4,
        4
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        2,
        4,
        1,
        4,
        1,
        0,
        0,
        2,
        4,
        2,
        4,
        1,
        4,
        1,
        2,
        3,
        4,
        0,
        3,
        4,
        0,
        4,
        4,
        4,
        0,
        4,
        3,
        0,
        4,
        3,
        4,
        4,
        1,
        1,
        3,
        2,
        4,
        4,
        4,
        2,
        0,
        2,
        1,
        0,
        3,
        3,
        4,
        1,
        4,
        1,
        0,
        1
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        1,
        2,
        0,
        1,
        2,
        0,
        2,
        3,
        4,
        1,
        4,
        4,
        0,
        4,
        3,
        4,
        2,
        4,
        0,
        3,
        1,
        3,
        3,
        4,
        0,
        4,
        0,
        0,
        2,
        1,
        4,
        2,
        0,
        1,
        4,
        0,
        4,
        0,
        0,
        0,
        4,
        3,
        3,
        4,
        2,
        4,
        2,
        1,
        3,
        0,
        1,
        4
      ]
    },
    {
      "id": "19A12B20",
      "weeklyDisrupted": [
        4,
        0,
        2,
        2,
        0,
        4,
        0,
        0,
        0,
        0,
        4,
        2,
        2,
        0,
        4,
        2,
        4,
        3,
        3,
        3,
        4,
        1,
        4,
        0,
        1,
        1,
        0,
        1,
        4,
        4,
        0,
        2,
        0,
        1,
        0,
        0,
        1,
        0,
        1,
        1,
        4,
        2,
        3,
        0,
        0,
        3,
        0,
        1,
        4,
        2,
        2,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        0,
        2,
        3,
        2,
        1,
        2,
        2,
        4,
        1,
        1,
        4,
        3,
        4,
        2,
        3,
        4,
        3,
        0,
        0,
        0,
        0,
        2,
        4,
        3,
        0,
        4,
        1,
        0,
        0,
        0,
        3,
        3,
        3,
        1,
        1,
        2,
        4,
        2,
        3,
        2,
        0,
        2,
        1,
        3,
        4,
        4,
        4,
        0,
        4,
        3,
        4,
        2
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        0,
        2,
        1,
        4,
        0,
        3,
        1,
        4,
        1,
        0,
        4,
        0,
        1,
        0,
        2,
        2,
        2,
        0,
        0,
        3,
        2,
        3,
        4,
        1,
        0,
        3,
        1,
        0,
        0,
        0,
        0,
        2,
        3,
        0,
        2,
        3,
        4,
        4,
        3,
        0,
        3,
        1,
        0,
        4,
        0,
        3,
        4,
        0,
        4,
        4,
        1,
        4
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        1,
        1,
        3,
        3,
        1,
        3,
        1,
        4,
        4,
        3,
        4,
        4,
        4,
        2,
        3,
        0,
        0,
        0,
        0,
        4,
        1,
        0,
        3,
        4,
        0,
        0,
        0,
        2,
        2,
        1,
        1,
        3,
        3,
        0,
        3,
        3,
        4,
        2,
        0,
        0,
        0,
        4,
        2,
        2,
        1,
        2,
        1,
        0,
        0,
        4,
        3,
        4
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        0,
        2,
        0,
        3,
        4,
        4,
        2,
        0,
        4,
        2,
        2,
        0,
        4,
        1,
        2,
        4,
        3,
        1,
        2,
        1,
        0,
        4,
        4,
        0,
        2,
        2,
        1,
        2,
        4,
        2,
        4,
        4,
        2,
        1,
        1,
        3,
        3,
        4,
        0,
        1,
        3,
        4,
        0,
        4,
        0,
        1,
        2,
        4,
        0,
        0,
        3,
        2
      ]
    },
    {
      "id": "14968630",
      "weeklyDisrupted": [
        2,
        4,
        0,
        0,
        1,
        2,
        0,
        3,
        3,
        4,
        1,
        1,
        1,
        1,
        1,
        3,
        1,
        2,
        2,
        3,
        3,
        3,
        0,
        0,
        2,
        2,
        0,
        0,
        3,
        1,
        0,
        2,
        0,
        3,
        0,
        2,
        1,
        2,
        1,
        0,
        2,
        1,
        1,
        4,
        1,
        2,
        4,
        0,
        4,
        2,
        0,
        4
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        2,
        4,
        0,
        4,
        3,
        3,
        0,
        0,
        0,
        2,
        4,
        4,
        0,
        4,
        3,
        4,
        4,
        0,
        4,
        4,
        3,
        0,
        0,
        0,
        0,
        4,
        0,
        1,
        3,
        4,
        0,
        4,
        3,
        0,
        1,
        3,
        3,
        4,
        1,
        1,
        0,
        1,
        1,
        4,
        1,
        2,
        1,
        1,
        4,
        4,
        4,
        2
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        3,
        4,
        1,
        4,
        2,
        4,
        4,
        4,
        1,
        4,
        2,
        0,
        4,
        0,
        4,
        2,
        2,
        3,
        4,
        2,
        3,
        4,
        3,
        0,
        0,
        2,
        4,
        2,
        1,
        3,
        0,
        2,
        4,
        1,
        2,
        2,
        3,
        0,
        2,
        0,
        1,
        0,
        1,
        0,
        2,
        1,
        1,
        1,
        4,
        2,
        0,
        4
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        4,
        1,
        2,
        2,
        3,
        3,
        1,
        1,
        2,
        3,
        4,
        4,
        1,
        2,
        0,
        1,
        3,
        0,
        0,
        4,
        4,
        4,
        1,
        4,
        0,
        1,
        0,
        1,
        4,
        4,
        3,
        4,
        0,
        0,
        1,
        4,
        4,
        0,
        0,
        3,
        4,
        2,
        2,
        2,
        2,
        4,
        1,
        0,
        2,
        0,
        0,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyDisrupted": [
        4,
        4,
        0,
        2,
        1,
        2,
        2,
        4,
        3,
        2,
        2,
        3,
        2,
        2,
        2,
        4,
        2,
        0,
        3,
        1,
        1,
        1,
        1,
        4,
        0,
        3,
        0,
        1,
        2,
        2,
        0,
        0,
        2,
        0,
        4,
        3,
        4,
        2,
        1,
        4,
        2,
        2,
        0,
        3,
        0,
        1,
        4,
        4,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        0,
        0,
        3,
        4,
        1,
        4,
        2,
        1,
        3,
        0,
        2,
        1,
        3,
        1,
        2,
        4,
        2,
        0,
        1,
        0,
        1,
        1,
        4,
        1,
        2,
        3,
        0,
        2,
        2,
        1,
        0,
        3,
        1,
        1,
        2,
        2,
        1,
        4,
        1,
        2,
        4,
        4,
        3,
        3,
        2,
        1,
        4,
        4,
        2,
        1,
        3,
        0
      ]
    },
    {
      "id": "958C4CED",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        1,
        2,
        3,
        1,
        4,
        4,
        3,
        1,
        4,
        4,
        2,
        0,
        1,
        0,
        1,
        4,
        4,
        0,
        2,
        0,
        2,
        4,
        2,
        4,
        4,
        4,
        3,
        4,
        4,
        0,
        0,
        2,
        2,
        4,
        2,
        3,
        3,
        4,
        3,
        3,
        0,
        4,
        0,
        4,
        3,
        0,
        3,
        2
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        1,
        3,
        1,
        3,
        0,
        3,
        0,
        2,
        1,
        4,
        1,
        0,
        1,
        2,
        1,
        2,
        0,
        3,
        2,
        0,
        3,
        2,
        0,
        1,
        0,
        4,
        1,
        2,
        0,
        2,
        0,
        1,
        4,
        3,
        2,
        0,
        4,
        4,
        0,
        1,
        4,
        1,
        4,
        1,
        0,
        2,
        0,
        0,
        4,
        1,
        4,
        0
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        0,
        3,
        0,
        1,
        0,
        4,
        1,
        2,
        0,
        0,
        0,
        2,
        2,
        2,
        2,
        4,
        3,
        1,
        0,
        1,
        1,
        2,
        2,
        2,
        0,
        2,
        0,
        0,
        4,
        3,
        0,
        2,
        0,
        0,
        0,
        4,
        4,
        1,
        0,
        0,
        3,
        3,
        2,
        4,
        1,
        0,
        2,
        0,
        4,
        1,
        3,
        4
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        2,
        1,
        2,
        4,
        0,
        2,
        0,
        2,
        1,
        0,
        3,
        3,
        4,
        3,
        3,
        3,
        4,
        0,
        3,
        3,
        3,
        2,
        2,
        4,
        0,
        3,
        0,
        0,
        4,
        4,
        0,
        4,
        1,
        2,
        0,
        2,
        1,
        4,
        0,
        0,
        4,
        4,
        3,
        3,
        0,
        2,
        1,
        2,
        3,
        1,
        2,
        4
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        0,
        3,
        4,
        0,
        0,
        4,
        1,
        4,
        3,
        0,
        4,
        1,
        4,
        2,
        3,
        3,
        1,
        0,
        0,
        3,
        0,
        0,
        1,
        1,
        3,
        3,
        0,
        3,
        3,
        0,
        0,
        4,
        4,
        1,
        3,
        0,
        4,
        2,
        0,
        1,
        3,
        1,
        1,
        0,
        0,
        0,
        0,
        3,
        4,
        4,
        0,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        3,
        0,
        3,
        0,
        2,
        3,
        1,
        4,
        2,
        1,
        3,
        4,
        0,
        3,
        4,
        0,
        4,
        0,
        2,
        2,
        0,
        1,
        0,
        4,
        0,
        0,
        1,
        0,
        2,
        0,
        0,
        2,
        1,
        0,
        3,
        2,
        2,
        4,
        1,
        3,
        0,
        3,
        2,
        4,
        1,
        4,
        3,
        0,
        1,
        4,
        0,
        0
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        4,
        3,
        1,
        2,
        0,
        2,
        2,
        3,
        4,
        2,
        4,
        0,
        1,
        3,
        3,
        3,
        3,
        3,
        1,
        1,
        2,
        1,
        4,
        0,
        0,
        1,
        1,
        3,
        0,
        0,
        3,
        4,
        3,
        2,
        0,
        3,
        2,
        0,
        3,
        1,
        1,
        3,
        4,
        4,
        0,
        2,
        1,
        2,
        4,
        2,
        2,
        4
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        4,
        4,
        3,
        3,
        4,
        4,
        2,
        0,
        4,
        1,
        4,
        1,
        4,
        2,
        4,
        2,
        4,
        0,
        0,
        1,
        0,
        1,
        0,
        3,
        1,
        4,
        1,
        0,
        1,
        0,
        4,
        2,
        4,
        1,
        0,
        4,
        3,
        4,
        3,
        0,
        4,
        4,
        0,
        1,
        4,
        1,
        0,
        3,
        4,
        4,
        0,
        4
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        4,
        0,
        4,
        3,
        4,
        0,
        0,
        0,
        3,
        3,
        4,
        3,
        2,
        3,
        2,
        2,
        4,
        0,
        4,
        4,
        2,
        0,
        0,
        1,
        0,
        4,
        0,
        0,
        2,
        3,
        2,
        0,
        2,
        2,
        2,
        4,
        3,
        1,
        0,
        0,
        4,
        2,
        1,
        4,
        3,
        2,
        4,
        0,
        1,
        0,
        4,
        4
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        3,
        2,
        2,
        4,
        2,
        4,
        0,
        2,
        0,
        3,
        0,
        0,
        3,
        0,
        4,
        1,
        3,
        2,
        0,
        4,
        0,
        4,
        3,
        1,
        1,
        3,
        3,
        0,
        4,
        2,
        1,
        0,
        3,
        0,
        1,
        3,
        1,
        4,
        3,
        2,
        2,
        4,
        0,
        3,
        1,
        0,
        0,
        0,
        2,
        4,
        3,
        2
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        3,
        0,
        1,
        1,
        1,
        2,
        2,
        2,
        3,
        3,
        1,
        2,
        0,
        1,
        4,
        0,
        2,
        2,
        4,
        2,
        0,
        4,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        2,
        1,
        3,
        3,
        0,
        2,
        3,
        3,
        3,
        0,
        2,
        4,
        1,
        4,
        4,
        1,
        1,
        0,
        2,
        3,
        3,
        1,
        4
      ]
    },
    {
      "id": "200EA71C",
      "weeklyDisrupted": [
        3,
        1,
        0,
        2,
        0,
        4,
        0,
        4,
        3,
        0,
        2,
        0,
        2,
        1,
        2,
        3,
        2,
        2,
        1,
        4,
        0,
        4,
        1,
        1,
        0,
        2,
        1,
        1,
        3,
        1,
        2,
        3,
        4,
        2,
        0,
        1,
        4,
        2,
        0,
        0,
        1,
        4,
        0,
        2,
        1,
        3,
        0,
        4,
        3,
        4,
        0,
        3
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        2,
        3,
        0,
        4,
        3,
        1,
        0,
        2,
        2,
        4,
        0,
        3,
        1,
        2,
        0,
        4,
        2,
        1,
        0,
        4,
        0,
        4,
        3,
        0,
        3,
        0,
        1,
        1,
        2,
        1,
        0,
        3,
        3,
        0,
        4,
        3,
        4,
        4,
        0,
        1,
        3,
        3,
        0,
        4,
        0,
        3,
        4,
        4,
        4,
        0,
        0,
        2
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        3,
        1,
        4,
        1,
        1,
        1,
        1,
        2,
        4,
        1,
        4,
        3,
        2,
        1,
        4,
        0,
        4,
        1,
        1,
        0,
        4,
        0,
        1,
        1,
        0,
        2,
        0,
        2,
        3,
        1,
        0,
        4,
        2,
        0,
        3,
        2,
        2,
        1,
        0,
        1,
        4,
        3,
        0,
        4,
        2,
        0,
        0,
        1,
        0,
        2,
        2,
        4
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        3,
        0,
        3,
        3,
        2,
        2,
        1,
        2,
        4,
        0,
        0,
        4,
        3,
        4,
        2,
        1,
        3,
        1,
        2,
        1,
        2,
        3,
        4,
        1,
        1,
        0,
        0,
        0,
        2,
        4,
        3,
        3,
        2,
        0,
        0,
        4,
        4,
        0,
        0,
        0,
        3,
        4,
        2,
        3,
        1,
        4,
        3,
        1,
        4,
        3,
        4,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        4,
        3,
        0,
        4,
        0,
        3,
        2,
        2,
        3,
        0,
        4,
        1,
        0,
        0,
        0,
        4,
        2,
        1,
        0,
        0,
        4,
        4,
        4,
        3,
        0,
        2,
        2,
        2,
        0,
        2,
        2,
        3,
        0,
        1,
        4,
        3,
        4,
        0,
        2,
        0,
        4,
        2,
        3,
        1,
        0,
        1,
        0,
        3,
        4,
        1,
        1,
        4
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        2,
        0,
        1,
        4,
        4,
        4,
        0,
        0,
        3,
        0,
        2,
        3,
        3,
        4,
        2,
        4,
        3,
        0,
        1,
        0,
        1,
        2,
        2,
        3,
        0,
        2,
        1,
        1,
        2,
        4,
        3,
        1,
        4,
        3,
        2,
        3,
        4,
        4,
        0,
        1,
        4,
        4,
        2,
        4,
        1,
        4,
        4,
        4,
        3,
        1,
        2,
        4
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        1,
        1,
        3,
        4,
        0,
        4,
        1,
        1,
        1,
        1,
        3,
        4,
        4,
        4,
        2,
        4,
        3,
        1,
        0,
        2,
        0,
        2,
        3,
        3,
        0,
        2,
        0,
        0,
        0,
        3,
        0,
        3,
        2,
        0,
        3,
        1,
        0,
        4,
        0,
        0,
        0,
        1,
        0,
        4,
        4,
        1,
        0,
        0,
        4,
        0,
        1,
        2
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyDisrupted": [
        4,
        1,
        3,
        1,
        0,
        3,
        1,
        0,
        1,
        4,
        2,
        3,
        4,
        0,
        1,
        0,
        2,
        0,
        0,
        4,
        3,
        0,
        0,
        0,
        0,
        4,
        0,
        3,
        2,
        4,
        0,
        2,
        0,
        3,
        1,
        1,
        3,
        3,
        0,
        3,
        3,
        1,
        1,
        2,
        4,
        1,
        0,
        0,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        2,
        4,
        2,
        2,
        1,
        4,
        0,
        0,
        2,
        0,
        3,
        4,
        2,
        1,
        1,
        0,
        2,
        0,
        2,
        4,
        0,
        4,
        3,
        0,
        0,
        3,
        2,
        0,
        0,
        4,
        3,
        2,
        4,
        3,
        4,
        1,
        3,
        0,
        1,
        0,
        0,
        2,
        4,
        3,
        1,
        3,
        4,
        0,
        4,
        4,
        4,
        3
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        3,
        0,
        4,
        2,
        2,
        0,
        1,
        2,
        4,
        0,
        2,
        3,
        4,
        3,
        4,
        2,
        1,
        0,
        3,
        3,
        1,
        0,
        4,
        3,
        0,
        4,
        0,
        2,
        2,
        4,
        3,
        3,
        0,
        0,
        2,
        3,
        4,
        4,
        3,
        1,
        4,
        4,
        0,
        1,
        0,
        4,
        0,
        0,
        2,
        1,
        4,
        4
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyDisrupted": [
        0,
        0,
        0,
        2,
        4,
        2,
        0,
        1,
        4,
        4,
        2,
        3,
        4,
        1,
        3,
        2,
        0,
        0,
        0,
        2,
        4,
        2,
        4,
        2,
        0,
        4,
        2,
        1,
        3,
        4,
        4,
        2,
        2,
        4,
        2,
        2,
        4,
        3,
        0,
        3,
        4,
        4,
        0,
        1,
        4,
        3,
        2,
        4,
        4,
        3,
        2,
        4
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        0,
        1,
        4,
        2,
        0,
        2,
        0,
        1,
        4,
        0,
        4,
        0,
        3,
        4,
        2,
        2,
        1,
        4,
        2,
        0,
        4,
        0,
        0,
        3,
        0,
        1,
        0,
        0,
        4,
        2,
        0,
        2,
        0,
        0,
        0,
        0,
        4,
        3,
        1,
        0,
        4,
        3,
        3,
        0,
        0,
        1,
        4,
        0,
        4,
        2,
        3,
        0
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        3,
        1,
        1,
        1,
        1,
        4,
        1,
        3,
        4,
        0,
        2,
        2,
        1,
        3,
        4,
        3,
        3,
        1,
        4,
        0,
        3,
        3,
        2,
        4,
        0,
        3,
        0,
        0,
        0,
        3,
        3,
        1,
        1,
        3,
        2,
        4,
        4,
        4,
        3,
        1,
        2,
        4,
        3,
        2,
        0,
        4,
        4,
        3,
        1,
        4,
        2,
        2
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        0,
        3,
        1,
        3,
        1,
        2,
        2,
        0,
        3,
        2,
        2,
        3,
        4,
        0,
        4,
        2,
        2,
        1,
        0,
        1,
        4,
        2,
        4,
        2,
        3,
        4,
        2,
        0,
        4,
        4,
        4,
        2,
        4,
        3,
        2,
        2,
        3,
        0,
        4,
        1,
        0,
        4,
        0,
        3,
        4,
        4,
        0,
        0,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyDisrupted": [
        3,
        3,
        4,
        2,
        0,
        4,
        3,
        0,
        3,
        1,
        3,
        2,
        2,
        4,
        1,
        3,
        4,
        1,
        0,
        4,
        1,
        3,
        2,
        3,
        4,
        3,
        2,
        0,
        0,
        1,
        4,
        4,
        2,
        0,
        0,
        3,
        2,
        3,
        4,
        0,
        0,
        3,
        1,
        0,
        0,
        2,
        0,
        0,
        2,
        2,
        0,
        2
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        1,
        1,
        1,
        4,
        2,
        1,
        0,
        4,
        4,
        3,
        4,
        4,
        4,
        3,
        4,
        4,
        3,
        1,
        2,
        2,
        4,
        4,
        3,
        3,
        0,
        0,
        0,
        3,
        0,
        0,
        3,
        2,
        4,
        0,
        3,
        2,
        2,
        4,
        0,
        0,
        2,
        3,
        2,
        2,
        0,
        4,
        0,
        1,
        2,
        1,
        4,
        2
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        1,
        0,
        1,
        4,
        1,
        3,
        2,
        0,
        4,
        0,
        0,
        4,
        4,
        0,
        2,
        0,
        4,
        0,
        0,
        0,
        2,
        2,
        4,
        1,
        3,
        2,
        4,
        1,
        4,
        3,
        1,
        4,
        0,
        0,
        4,
        4,
        2,
        3,
        0,
        0,
        2,
        1,
        0,
        4,
        3,
        0,
        0,
        4,
        3,
        1,
        4,
        2
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyDisrupted": [
        2,
        4,
        2,
        2,
        1,
        2,
        1,
        0,
        2,
        0,
        4,
        3,
        4,
        4,
        3,
        2,
        2,
        0,
        0,
        1,
        2,
        0,
        4,
        1,
        0,
        4,
        1,
        1,
        4,
        4,
        1,
        3,
        2,
        1,
        3,
        3,
        1,
        1,
        2,
        0,
        1,
        4,
        1,
        4,
        1,
        4,
        2,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        2,
        0,
        0,
        0,
        1,
        4,
        0,
        1,
        2,
        0,
        4,
        0,
        3,
        2,
        3,
        3,
        2,
        0,
        4,
        1,
        3,
        2,
        2,
        4,
        0,
        0,
        2,
        0,
        4,
        4,
        0,
        3,
        0,
        0,
        1,
        3,
        4,
        3,
        0,
        0,
        4,
        2,
        2,
        2,
        0,
        1,
        4,
        1,
        2,
        2,
        2,
        1
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        2,
        0,
        3,
        3,
        1,
        4,
        0,
        0,
        1,
        4,
        1,
        4,
        4,
        2,
        2,
        4,
        2,
        2,
        2,
        1,
        2,
        0,
        0,
        2,
        0,
        4,
        2,
        2,
        2,
        4,
        1,
        3,
        0,
        0,
        4,
        1,
        4,
        2,
        2,
        0,
        2,
        0,
        0,
        0,
        4,
        4,
        0,
        3,
        4,
        4,
        0,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        2,
        0,
        0,
        4,
        0,
        3,
        0,
        4,
        4,
        4,
        4,
        0,
        4,
        4,
        2,
        2,
        0,
        4,
        1,
        0,
        4,
        1,
        4,
        3,
        0,
        1,
        0,
        0,
        4,
        3,
        0,
        4,
        1,
        2,
        3,
        3,
        4,
        4,
        2,
        1,
        2,
        4,
        1,
        4,
        1,
        4,
        0,
        0,
        0,
        1,
        1,
        0
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        3,
        1,
        4,
        1,
        2,
        4,
        0,
        0,
        4,
        0,
        4,
        3,
        2,
        4,
        4,
        4,
        4,
        4,
        2,
        2,
        1,
        3,
        0,
        1,
        0,
        2,
        1,
        1,
        3,
        1,
        0,
        4,
        0,
        0,
        0,
        3,
        4,
        4,
        0,
        0,
        2,
        1,
        0,
        0,
        0,
        4,
        3,
        0,
        4,
        0,
        2,
        2
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        2,
        0,
        3,
        1,
        1,
        3,
        0,
        1,
        3,
        3,
        4,
        2,
        4,
        3,
        4,
        4,
        2,
        4,
        1,
        2,
        0,
        2,
        2,
        2,
        0,
        0,
        2,
        0,
        4,
        4,
        1,
        0,
        0,
        1,
        3,
        4,
        4,
        0,
        3,
        1,
        4,
        4,
        1,
        2,
        0,
        0,
        4,
        0,
        3,
        0,
        4,
        1
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        3,
        1,
        1,
        1,
        1,
        2,
        0,
        0,
        2,
        3,
        4,
        3,
        1,
        0,
        2,
        1,
        4,
        3,
        0,
        3,
        1,
        0,
        3,
        1,
        0,
        4,
        0,
        4,
        4,
        2,
        0,
        3,
        1,
        2,
        0,
        0,
        2,
        1,
        0,
        0,
        3,
        1,
        2,
        4,
        0,
        4,
        4,
        0,
        1,
        2,
        4,
        0
      ]
    },
    {
      "id": "048701CE",
      "weeklyDisrupted": [
        3,
        3,
        2,
        0,
        1,
        1,
        0,
        1,
        2,
        1,
        3,
        3,
        2,
        3,
        2,
        4,
        2,
        0,
        0,
        2,
        2,
        4,
        1,
        1,
        0,
        0,
        4,
        0,
        0,
        2,
        0,
        4,
        2,
        2,
        0,
        0,
        4,
        0,
        0,
        0,
        2,
        0,
        1,
        0,
        3,
        4,
        0,
        0,
        2,
        3,
        0,
        2
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        3,
        0,
        0,
        1,
        1,
        2,
        1,
        2,
        4,
        0,
        0,
        3,
        0,
        1,
        2,
        0,
        2,
        3,
        1,
        4,
        4,
        3,
        1,
        1,
        0,
        4,
        0,
        1,
        2,
        2,
        1,
        4,
        0,
        0,
        0,
        2,
        2,
        0,
        2,
        0,
        4,
        4,
        0,
        2,
        0,
        0,
        0,
        2,
        3,
        3,
        1,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyDisrupted": [
        4,
        0,
        1,
        2,
        2,
        3,
        0,
        3,
        4,
        1,
        4,
        4,
        0,
        4,
        2,
        1,
        2,
        0,
        0,
        3,
        0,
        3,
        4,
        4,
        0,
        4,
        4,
        3,
        3,
        3,
        0,
        2,
        2,
        0,
        3,
        0,
        4,
        4,
        4,
        0,
        3,
        2,
        4,
        4,
        1,
        4,
        1,
        0,
        4,
        0,
        3,
        2
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        4,
        3,
        4,
        3,
        1,
        3,
        2,
        4,
        2,
        1,
        0,
        3,
        1,
        0,
        3,
        1,
        2,
        1,
        0,
        1,
        4,
        1,
        0,
        2,
        2,
        1,
        2,
        0,
        0,
        0,
        0,
        4,
        2,
        1,
        1,
        4,
        4,
        4,
        1,
        2,
        3,
        3,
        0,
        0,
        0,
        2,
        2,
        2,
        3,
        1,
        1,
        2
      ]
    },
    {
      "id": "0AB87239",
      "weeklyDisrupted": [
        3,
        2,
        0,
        4,
        1,
        2,
        3,
        3,
        2,
        0,
        0,
        1,
        0,
        4,
        3,
        0,
        2,
        2,
        3,
        2,
        3,
        4,
        2,
        1,
        0,
        1,
        0,
        0,
        2,
        0,
        1,
        3,
        0,
        0,
        0,
        4,
        2,
        0,
        3,
        0,
        2,
        2,
        0,
        0,
        1,
        2,
        1,
        0,
        4,
        3,
        2,
        4
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        4,
        0,
        4,
        2,
        0,
        2,
        0,
        0,
        0,
        2,
        3,
        0,
        2,
        2,
        0,
        3,
        4,
        1,
        4,
        0,
        3,
        3,
        2,
        0,
        1,
        2,
        0,
        0,
        0,
        2,
        0,
        4,
        0,
        0,
        2,
        1,
        1,
        3,
        3,
        0,
        0,
        4,
        0,
        1,
        2,
        0,
        1,
        1,
        4,
        2,
        3,
        0
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        3,
        1,
        4,
        0,
        1,
        2,
        1,
        1,
        4,
        0,
        0,
        4,
        2,
        3,
        2,
        3,
        2,
        1,
        0,
        4,
        0,
        2,
        0,
        4,
        0,
        3,
        1,
        3,
        2,
        2,
        4,
        4,
        2,
        3,
        1,
        3,
        2,
        1,
        2,
        0,
        2,
        2,
        0,
        3,
        3,
        3,
        0,
        4,
        2,
        1,
        1,
        4
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        0,
        3,
        1,
        1,
        1,
        2,
        0,
        3,
        0,
        2,
        1,
        1,
        2,
        2,
        2,
        4,
        1,
        4,
        1,
        1,
        3,
        3,
        0,
        0,
        0,
        0,
        0,
        0,
        4,
        2,
        1,
        4,
        3,
        1,
        0,
        0,
        3,
        0,
        2,
        4,
        4,
        2,
        0,
        2,
        4,
        4,
        3,
        2,
        4,
        0,
        1,
        0
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        2,
        0,
        3,
        2,
        4,
        3,
        3,
        4,
        4,
        4,
        4,
        0,
        1,
        2,
        3,
        0,
        0,
        0,
        4,
        4,
        2,
        4,
        2,
        1,
        1,
        3,
        1,
        4,
        3,
        2,
        0,
        4,
        4,
        1,
        3,
        4,
        4,
        4,
        0,
        2,
        3,
        2,
        4,
        2,
        0,
        1,
        2,
        1,
        4,
        1,
        2,
        2
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        0,
        3,
        1,
        3,
        0,
        3,
        2,
        1,
        4,
        1,
        4,
        2,
        4,
        0,
        2,
        0,
        1,
        0,
        0,
        0,
        1,
        4,
        4,
        4,
        0,
        1,
        1,
        2,
        4,
        4,
        0,
        4,
        4,
        1,
        2,
        4,
        4,
        4,
        2,
        2,
        2,
        4,
        4,
        2,
        1,
        4,
        0,
        2,
        4,
        0,
        3,
        0
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyDisrupted": [
        2,
        4,
        4,
        2,
        4,
        1,
        3,
        1,
        4,
        1,
        1,
        4,
        3,
        4,
        3,
        0,
        3,
        3,
        0,
        3,
        0,
        3,
        3,
        2,
        0,
        4,
        1,
        0,
        3,
        2,
        0,
        3,
        0,
        0,
        0,
        2,
        4,
        1,
        3,
        0,
        2,
        4,
        4,
        2,
        0,
        1,
        0,
        0,
        1,
        4,
        3,
        0
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        4,
        4,
        3,
        4,
        0,
        4,
        0,
        3,
        1,
        3,
        4,
        0,
        3,
        4,
        1,
        2,
        1,
        1,
        0,
        2,
        3,
        3,
        4,
        2,
        0,
        4,
        2,
        4,
        0,
        2,
        1,
        4,
        0,
        0,
        1,
        3,
        1,
        4,
        1,
        0,
        4,
        4,
        2,
        2,
        4,
        4,
        3,
        0,
        4,
        1,
        2,
        4
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        2,
        3,
        3,
        1,
        0,
        2,
        0,
        1,
        0,
        0,
        1,
        2,
        4,
        4,
        0,
        4,
        3,
        0,
        3,
        2,
        4,
        1,
        3,
        2,
        0,
        4,
        0,
        0,
        1,
        4,
        1,
        3,
        2,
        4,
        2,
        2,
        4,
        4,
        2,
        2,
        0,
        4,
        4,
        0,
        3,
        3,
        3,
        0,
        4,
        3,
        1,
        0
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        4,
        4,
        0,
        2,
        0,
        1,
        1,
        1,
        2,
        4,
        2,
        1,
        0,
        0,
        1,
        0,
        3,
        2,
        2,
        2,
        4,
        3,
        3,
        0,
        0,
        1,
        3,
        0,
        4,
        3,
        0,
        4,
        4,
        0,
        0,
        3,
        1,
        0,
        3,
        0,
        0,
        3,
        1,
        0,
        0,
        4,
        2,
        4,
        0,
        2,
        4,
        4
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        3,
        2,
        1,
        1,
        1,
        1,
        0,
        4,
        3,
        4,
        3,
        4,
        4,
        4,
        4,
        4,
        2,
        1,
        4,
        0,
        1,
        3,
        4,
        2,
        0,
        2,
        0,
        0,
        2,
        3,
        4,
        4,
        4,
        3,
        4,
        3,
        2,
        0,
        3,
        1,
        2,
        2,
        4,
        4,
        1,
        4,
        1,
        0,
        1,
        2,
        2,
        1
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        0,
        2,
        1,
        2,
        0,
        2,
        4,
        4,
        2,
        0,
        1,
        2,
        3,
        1,
        2,
        3,
        1,
        4,
        0,
        3,
        2,
        4,
        2,
        3,
        0,
        2,
        1,
        1,
        3,
        2,
        4,
        4,
        0,
        0,
        1,
        3,
        4,
        1,
        1,
        0,
        3,
        2,
        0,
        4,
        2,
        3,
        3,
        4,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        4,
        4,
        4,
        4,
        0,
        0,
        1,
        1,
        0,
        0,
        0,
        2,
        4,
        4,
        1,
        1,
        0,
        4,
        1,
        3,
        1,
        3,
        0,
        4,
        0,
        2,
        0,
        3,
        3,
        0,
        1,
        4,
        3,
        3,
        4,
        1,
        3,
        4,
        4,
        0,
        4,
        0,
        3,
        2,
        0,
        4,
        1,
        1,
        0,
        1,
        2,
        2
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        2,
        2,
        1,
        4,
        1,
        0,
        1,
        0,
        1,
        3,
        4,
        4,
        3,
        3,
        2,
        4,
        0,
        0,
        1,
        1,
        2,
        0,
        1,
        1,
        0,
        0,
        0,
        0,
        2,
        0,
        4,
        3,
        1,
        0,
        1,
        3,
        3,
        0,
        2,
        0,
        0,
        3,
        4,
        4,
        0,
        4,
        1,
        0,
        2,
        0,
        3,
        1
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        2,
        0,
        0,
        4,
        4,
        0,
        0,
        0,
        4,
        2,
        4,
        0,
        4,
        4,
        2,
        4,
        0,
        1,
        2,
        0,
        4,
        3,
        2,
        0,
        0,
        0,
        0,
        0,
        4,
        2,
        4,
        4,
        4,
        0,
        4,
        4,
        4,
        0,
        2,
        0,
        4,
        4,
        2,
        4,
        0,
        3,
        3,
        2,
        0,
        4,
        1,
        0
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        4,
        2,
        1,
        3,
        0,
        2,
        3,
        4,
        4,
        0,
        3,
        4,
        3,
        2,
        2,
        1,
        3,
        2,
        4,
        0,
        4,
        1,
        3,
        4,
        0,
        4,
        0,
        0,
        0,
        3,
        3,
        4,
        1,
        0,
        3,
        3,
        4,
        0,
        0,
        0,
        2,
        2,
        0,
        2,
        0,
        2,
        0,
        3,
        4,
        4,
        1,
        4
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        2,
        2,
        1,
        2,
        1,
        0,
        2,
        3,
        3,
        3,
        1,
        4,
        2,
        2,
        3,
        0,
        0,
        2,
        0,
        1,
        4,
        2,
        4,
        0,
        0,
        0,
        3,
        1,
        0,
        3,
        3,
        4,
        2,
        0,
        0,
        0,
        3,
        0,
        0,
        0,
        1,
        2,
        3,
        2,
        2,
        2,
        0,
        0,
        2,
        1,
        0,
        3
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        4,
        2,
        1,
        3,
        2,
        1,
        2,
        4,
        4,
        2,
        3,
        0,
        2,
        2,
        4,
        3,
        0,
        4,
        4,
        4,
        0,
        4,
        2,
        0,
        3,
        2,
        1,
        3,
        4,
        4,
        3,
        0,
        0,
        1,
        3,
        4,
        3,
        0,
        2,
        3,
        2,
        1,
        4,
        2,
        0,
        0,
        4,
        3,
        4,
        2,
        0,
        3
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        2,
        4,
        4,
        2,
        1,
        2,
        1,
        0,
        2,
        1,
        1,
        4,
        4,
        0,
        2,
        0,
        1,
        1,
        0,
        4,
        0,
        3,
        3,
        0,
        0,
        4,
        2,
        3,
        2,
        2,
        3,
        3,
        1,
        3,
        4,
        3,
        4,
        4,
        0,
        0,
        2,
        3,
        3,
        0,
        1,
        4,
        2,
        4,
        2,
        4,
        3,
        2
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        0,
        0,
        3,
        4,
        1,
        4,
        0,
        3,
        2,
        0,
        0,
        1,
        2,
        4,
        4,
        0,
        0,
        4,
        2,
        1,
        4,
        0,
        3,
        0,
        0,
        0,
        4,
        1,
        2,
        2,
        0,
        4,
        3,
        0,
        0,
        4,
        4,
        0,
        0,
        0,
        2,
        3,
        1,
        4,
        0,
        2,
        0,
        2,
        3,
        2,
        4,
        1
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        2,
        4,
        4,
        4,
        2,
        1,
        3,
        4,
        4,
        3,
        0,
        3,
        2,
        1,
        2,
        1,
        0,
        1,
        0,
        2,
        3,
        4,
        4,
        0,
        0,
        0,
        2,
        1,
        0,
        2,
        1,
        1,
        0,
        1,
        3,
        1,
        2,
        4,
        1,
        0,
        4,
        2,
        0,
        2,
        0,
        3,
        0,
        2,
        2,
        0,
        4,
        2
      ]
    },
    {
      "id": "14968630",
      "weeklyDisrupted": [
        0,
        0,
        1,
        4,
        1,
        3,
        1,
        2,
        4,
        0,
        0,
        2,
        4,
        1,
        2,
        3,
        4,
        0,
        4,
        4,
        3,
        4,
        4,
        0,
        1,
        2,
        2,
        4,
        0,
        1,
        4,
        3,
        4,
        2,
        3,
        1,
        3,
        4,
        0,
        0,
        0,
        4,
        0,
        2,
        1,
        3,
        0,
        3,
        4,
        1,
        2,
        0
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        1,
        4,
        1,
        1,
        0,
        0,
        2,
        3,
        4,
        1,
        1,
        4,
        4,
        2,
        4,
        4,
        2,
        2,
        3,
        0,
        4,
        0,
        1,
        0,
        2,
        0,
        2,
        0,
        4,
        2,
        0,
        3,
        0,
        0,
        3,
        1,
        4,
        0,
        0,
        2,
        3,
        0,
        0,
        3,
        3,
        0,
        0,
        0,
        4,
        4,
        0,
        4
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        3,
        2,
        2,
        0,
        1,
        3,
        0,
        0,
        4,
        0,
        4,
        0,
        1,
        0,
        0,
        4,
        3,
        0,
        2,
        4,
        0,
        3,
        3,
        1,
        0,
        3,
        3,
        2,
        4,
        4,
        0,
        4,
        4,
        0,
        0,
        3,
        1,
        0,
        0,
        0,
        1,
        3,
        2,
        4,
        1,
        0,
        0,
        0,
        3,
        4,
        4,
        4
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        1,
        2,
        1,
        2,
        1,
        4,
        0,
        0,
        4,
        3,
        4,
        1,
        1,
        1,
        2,
        4,
        4,
        0,
        2,
        3,
        1,
        0,
        4,
        2,
        3,
        4,
        2,
        0,
        1,
        3,
        1,
        1,
        0,
        2,
        1,
        4,
        4,
        0,
        0,
        0,
        3,
        1,
        2,
        2,
        0,
        3,
        2,
        2,
        4,
        2,
        1,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        4,
        1,
        0,
        0,
        1,
        2,
        0,
        4,
        0,
        2,
        3,
        4,
        4,
        1,
        2,
        4,
        3,
        0,
        4,
        4,
        1,
        0,
        2,
        0,
        0,
        2,
        1,
        1,
        0,
        2,
        3,
        4,
        2,
        1,
        1,
        3,
        3,
        4,
        4,
        3,
        1,
        4,
        1,
        0,
        2,
        4,
        2,
        0,
        4,
        4,
        0,
        2
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        2,
        4,
        0,
        0,
        0,
        4,
        0,
        2,
        2,
        0,
        4,
        4,
        2,
        2,
        2,
        1,
        3,
        0,
        0,
        2,
        1,
        4,
        2,
        1,
        0,
        0,
        0,
        1,
        2,
        0,
        0,
        4,
        2,
        0,
        1,
        3,
        4,
        4,
        0,
        2,
        2,
        1,
        0,
        4,
        4,
        2,
        0,
        3,
        4,
        3,
        1,
        4
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyDisrupted": [
        0,
        2,
        0,
        4,
        1,
        3,
        3,
        4,
        0,
        0,
        4,
        2,
        2,
        0,
        2,
        0,
        2,
        1,
        0,
        2,
        1,
        0,
        4,
        1,
        0,
        3,
        3,
        0,
        1,
        4,
        1,
        2,
        0,
        0,
        3,
        3,
        3,
        0,
        3,
        1,
        3,
        4,
        2,
        4,
        1,
        4,
        1,
        3,
        2,
        0,
        4,
        4
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        3,
        2,
        0,
        1,
        1,
        3,
        0,
        4,
        1,
        3,
        2,
        3,
        4,
        2,
        2,
        0,
        2,
        0,
        1,
        0,
        4,
        0,
        2,
        1,
        0,
        2,
        0,
        0,
        3,
        4,
        1,
        2,
        1,
        2,
        4,
        2,
        3,
        4,
        2,
        0,
        3,
        4,
        0,
        3,
        0,
        1,
        2,
        0,
        4,
        2,
        0,
        3
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        4,
        2,
        2,
        1,
        0,
        4,
        3,
        4,
        1,
        1,
        1,
        2,
        4,
        1,
        0,
        3,
        3,
        0,
        2,
        1,
        1,
        0,
        0,
        2,
        0,
        1,
        2,
        4,
        0,
        4,
        0,
        4,
        1,
        1,
        3,
        4,
        3,
        4,
        0,
        0,
        4,
        2,
        1,
        3,
        2,
        0,
        0,
        4,
        1,
        4,
        1,
        0
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        0,
        2,
        0,
        0,
        2,
        2,
        0,
        1,
        3,
        1,
        2,
        3,
        4,
        4,
        4,
        1,
        1,
        0,
        4,
        0,
        0,
        0,
        0,
        1,
        0,
        1,
        1,
        1,
        4,
        2,
        0,
        2,
        1,
        2,
        3,
        3,
        4,
        2,
        1,
        1,
        0,
        4,
        4,
        2,
        0,
        4,
        2,
        0,
        3,
        4,
        0,
        2
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        2,
        1,
        3,
        0,
        2,
        4,
        1,
        0,
        3,
        3,
        3,
        4,
        1,
        0,
        1,
        1,
        1,
        1,
        0,
        4,
        4,
        3,
        0,
        1,
        0,
        0,
        3,
        3,
        3,
        3,
        1,
        4,
        0,
        0,
        0,
        0,
        1,
        3,
        0,
        0,
        4,
        2,
        1,
        4,
        0,
        0,
        4,
        2,
        4,
        4,
        4,
        1
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        4,
        2,
        0,
        4,
        2,
        2,
        1,
        1,
        1,
        1,
        2,
        3,
        1,
        0,
        3,
        2,
        2,
        3,
        3,
        1,
        1,
        2,
        2,
        0,
        0,
        0,
        0,
        1,
        3,
        1,
        2,
        2,
        3,
        0,
        1,
        1,
        3,
        0,
        0,
        0,
        0,
        1,
        1,
        4,
        1,
        3,
        1,
        3,
        4,
        1,
        4,
        0
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        1,
        1,
        4,
        2,
        4,
        2,
        4,
        1,
        0,
        3,
        2,
        3,
        1,
        2,
        2,
        1,
        3,
        0,
        0,
        3,
        4,
        4,
        4,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        2,
        2,
        0,
        1,
        3,
        4,
        4,
        1,
        2,
        0,
        2,
        4,
        0,
        3,
        1,
        4,
        1,
        2,
        4,
        1,
        4,
        2
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        3,
        2,
        0,
        4,
        0,
        4,
        1,
        1,
        3,
        2,
        1,
        3,
        0,
        0,
        4,
        2,
        1,
        1,
        1,
        3,
        1,
        1,
        4,
        1,
        0,
        0,
        2,
        2,
        4,
        2,
        4,
        4,
        3,
        1,
        2,
        4,
        2,
        1,
        2,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        0,
        1,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyDisrupted": [
        4,
        2,
        1,
        3,
        2,
        4,
        0,
        4,
        4,
        0,
        2,
        4,
        1,
        0,
        2,
        3,
        2,
        0,
        1,
        2,
        0,
        2,
        3,
        0,
        2,
        1,
        0,
        0,
        2,
        1,
        3,
        2,
        0,
        1,
        2,
        3,
        4,
        0,
        2,
        0,
        4,
        0,
        0,
        2,
        0,
        0,
        3,
        0,
        3,
        1,
        0,
        1
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        4,
        0,
        3,
        2,
        3,
        4,
        0,
        2,
        2,
        0,
        2,
        3,
        0,
        1,
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        1,
        3,
        1,
        2,
        2,
        0,
        0,
        0,
        1,
        3,
        4,
        1,
        2,
        2,
        2,
        4,
        2,
        1,
        0,
        2,
        2,
        0,
        3,
        0,
        1,
        0,
        1,
        4,
        2,
        0,
        4
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        0,
        3,
        1,
        1,
        4,
        2,
        0,
        3,
        0,
        0,
        4,
        4,
        1,
        1,
        4,
        0,
        4,
        0,
        0,
        3,
        4,
        0,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        4,
        0,
        1,
        0,
        0,
        1,
        4,
        0,
        4,
        0,
        0,
        1,
        1,
        2,
        3,
        3,
        1,
        4,
        2,
        2,
        2,
        3,
        3
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        1,
        4,
        2,
        3,
        0,
        0,
        4,
        2,
        4,
        2,
        0,
        0,
        4,
        0,
        1,
        0,
        1,
        1,
        0,
        2,
        3,
        3,
        3,
        0,
        1,
        0,
        2,
        0,
        0,
        2,
        4,
        4,
        0,
        4,
        0,
        2,
        0,
        1,
        1,
        1,
        0,
        2,
        0,
        1,
        4,
        4,
        4,
        2,
        1,
        4,
        3,
        4
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        0,
        3,
        1,
        3,
        0,
        4,
        0,
        0,
        2,
        2,
        4,
        0,
        4,
        4,
        2,
        4,
        4,
        0,
        3,
        1,
        4,
        2,
        4,
        0,
        0,
        4,
        2,
        2,
        2,
        4,
        0,
        2,
        0,
        2,
        2,
        4,
        4,
        2,
        2,
        0,
        2,
        4,
        2,
        4,
        4,
        3,
        2,
        1,
        4,
        1,
        2,
        3
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        0,
        0,
        3,
        0,
        4,
        3,
        1,
        2,
        2,
        4,
        4,
        0,
        4,
        4,
        2,
        4,
        4,
        0,
        1,
        2,
        3,
        4,
        1,
        3,
        1,
        0,
        3,
        1,
        3,
        4,
        2,
        3,
        4,
        4,
        2,
        4,
        4,
        0,
        0,
        0,
        4,
        3,
        3,
        0,
        0,
        2,
        0,
        3,
        4,
        3,
        4,
        3
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        0,
        1,
        3,
        4,
        4,
        4,
        2,
        1,
        3,
        4,
        2,
        4,
        4,
        0,
        2,
        4,
        4,
        0,
        1,
        3,
        2,
        3,
        4,
        1,
        0,
        4,
        4,
        2,
        1,
        4,
        3,
        4,
        3,
        4,
        0,
        3,
        4,
        2,
        2,
        0,
        0,
        3,
        2,
        2,
        3,
        4,
        1,
        1,
        3,
        0,
        0,
        4
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        3,
        0,
        0,
        1,
        4,
        4,
        0,
        1,
        0,
        2,
        2,
        2,
        4,
        2,
        2,
        0,
        3,
        0,
        0,
        2,
        4,
        0,
        4,
        0,
        0,
        3,
        0,
        1,
        2,
        4,
        2,
        1,
        4,
        0,
        4,
        4,
        2,
        0,
        1,
        1,
        4,
        3,
        4,
        0,
        0,
        3,
        0,
        4,
        3,
        4,
        4,
        1
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        4,
        1,
        1,
        4,
        0,
        3,
        2,
        1,
        4,
        0,
        4,
        0,
        4,
        0,
        3,
        0,
        3,
        1,
        0,
        0,
        1,
        0,
        1,
        1,
        0,
        4,
        2,
        4,
        2,
        2,
        1,
        0,
        1,
        0,
        3,
        3,
        2,
        4,
        0,
        1,
        4,
        4,
        1,
        3,
        4,
        0,
        1,
        4,
        3,
        0,
        2,
        1
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        0,
        1,
        3,
        3,
        1,
        2,
        0,
        4,
        2,
        1,
        4,
        0,
        4,
        4,
        2,
        3,
        3,
        1,
        2,
        4,
        2,
        2,
        4,
        4,
        0,
        4,
        0,
        0,
        4,
        2,
        3,
        4,
        0,
        2,
        0,
        3,
        3,
        0,
        4,
        0,
        1,
        4,
        2,
        4,
        2,
        2,
        2,
        0,
        4,
        0,
        4,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        2,
        2,
        0,
        1,
        0,
        3,
        4,
        4,
        4,
        1,
        2,
        0,
        1,
        1,
        3,
        4,
        0,
        0,
        3,
        4,
        3,
        4,
        4,
        0,
        0,
        2,
        1,
        0,
        1,
        2,
        4,
        4,
        1,
        0,
        2,
        4,
        4,
        0,
        2,
        0,
        4,
        1,
        0,
        3,
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        2
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        0,
        0,
        0,
        2,
        4,
        3,
        2,
        1,
        4,
        2,
        3,
        4,
        4,
        4,
        3,
        0,
        3,
        0,
        2,
        2,
        3,
        4,
        4,
        2,
        0,
        3,
        0,
        1,
        4,
        3,
        0,
        0,
        4,
        3,
        0,
        3,
        4,
        3,
        0,
        1,
        2,
        1,
        2,
        1,
        0,
        1,
        2,
        2,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        4,
        0,
        0,
        4,
        0,
        1,
        2,
        4,
        4,
        4,
        4,
        4,
        2,
        3,
        1,
        4,
        2,
        1,
        4,
        0,
        3,
        4,
        4,
        1,
        0,
        4,
        2,
        0,
        4,
        3,
        0,
        3,
        0,
        2,
        1,
        4,
        4,
        4,
        4,
        1,
        3,
        4,
        4,
        0,
        1,
        3,
        2,
        0,
        4,
        1,
        2,
        4
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        1,
        3,
        2,
        1,
        2,
        0,
        4,
        2,
        3,
        3,
        0,
        2,
        4,
        0,
        3,
        4,
        2,
        2,
        0,
        4,
        2,
        2,
        3,
        0,
        1,
        1,
        2,
        0,
        1,
        1,
        3,
        1,
        0,
        0,
        1,
        4,
        1,
        0,
        0,
        0,
        4,
        0,
        1,
        1,
        2,
        3,
        1,
        3,
        2,
        2,
        0,
        4
      ]
    },
    {
      "id": "E491B04D",
      "weeklyDisrupted": [
        3,
        3,
        4,
        3,
        3,
        4,
        1,
        3,
        3,
        4,
        0,
        4,
        4,
        4,
        2,
        1,
        2,
        1,
        4,
        2,
        4,
        4,
        4,
        0,
        0,
        0,
        2,
        4,
        4,
        2,
        2,
        4,
        2,
        0,
        4,
        1,
        1,
        2,
        1,
        2,
        3,
        4,
        4,
        4,
        3,
        2,
        0,
        2,
        4,
        3,
        4,
        2
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        4,
        3,
        0,
        4,
        3,
        2,
        0,
        1,
        3,
        4,
        4,
        4,
        2,
        0,
        1,
        2,
        4,
        0,
        0,
        4,
        1,
        1,
        4,
        3,
        1,
        0,
        1,
        0,
        4,
        1,
        0,
        3,
        0,
        0,
        2,
        4,
        4,
        0,
        0,
        0,
        3,
        1,
        0,
        3,
        0,
        3,
        1,
        0,
        4,
        3,
        0,
        4
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        1,
        2,
        2,
        1,
        1,
        3,
        0,
        1,
        4,
        4,
        4,
        3,
        3,
        2,
        2,
        0,
        0,
        4,
        0,
        1,
        1,
        4,
        3,
        1,
        0,
        4,
        1,
        0,
        3,
        0,
        3,
        2,
        0,
        0,
        0,
        3,
        4,
        1,
        3,
        0,
        1,
        2,
        4,
        4,
        0,
        0,
        0,
        0,
        4,
        4,
        3,
        4
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        2,
        2,
        4,
        1,
        2,
        0,
        0,
        2,
        4,
        0,
        2,
        2,
        4,
        0,
        3,
        4,
        3,
        2,
        0,
        4,
        0,
        3,
        1,
        3,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        2,
        3,
        4,
        4,
        0,
        0,
        0,
        3,
        2,
        1,
        0,
        0,
        3,
        0,
        1,
        4,
        0,
        0,
        3
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        1,
        0,
        0,
        3,
        3,
        3,
        3,
        4,
        4,
        1,
        1,
        1,
        4,
        4,
        2,
        4,
        3,
        2,
        2,
        0,
        1,
        0,
        4,
        1,
        0,
        2,
        1,
        1,
        4,
        1,
        2,
        1,
        1,
        0,
        2,
        4,
        3,
        0,
        0,
        1,
        4,
        4,
        2,
        4,
        0,
        3,
        1,
        0,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        3,
        2,
        2,
        1,
        4,
        2,
        1,
        0,
        3,
        4,
        3,
        0,
        4,
        0,
        4,
        1,
        3,
        4,
        0,
        4,
        1,
        4,
        2,
        1,
        0,
        0,
        2,
        2,
        4,
        2,
        4,
        4,
        1,
        0,
        0,
        3,
        4,
        0,
        4,
        4,
        2,
        0,
        0,
        3,
        4,
        1,
        0,
        2,
        0,
        4,
        2,
        1
      ]
    },
    {
      "id": "19A12B20",
      "weeklyDisrupted": [
        0,
        3,
        0,
        0,
        1,
        0,
        0,
        3,
        4,
        2,
        4,
        4,
        4,
        4,
        2,
        4,
        4,
        0,
        1,
        0,
        0,
        1,
        4,
        2,
        4,
        4,
        0,
        4,
        2,
        2,
        1,
        4,
        0,
        0,
        1,
        4,
        4,
        4,
        0,
        0,
        0,
        4,
        2,
        4,
        3,
        4,
        4,
        0,
        3,
        3,
        0,
        4
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        2,
        1,
        0,
        4,
        2,
        4,
        3,
        4,
        0,
        1,
        4,
        1,
        2,
        1,
        4,
        4,
        1,
        4,
        2,
        3,
        0,
        0,
        2,
        0,
        0,
        0,
        1,
        0,
        2,
        1,
        4,
        4,
        2,
        1,
        0,
        3,
        4,
        0,
        1,
        1,
        0,
        4,
        0,
        2,
        1,
        3,
        2,
        4,
        4,
        4,
        0,
        3
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        2,
        3,
        0,
        2,
        1,
        2,
        1,
        4,
        2,
        0,
        2,
        4,
        0,
        4,
        3,
        2,
        4,
        4,
        0,
        0,
        2,
        3,
        2,
        4,
        0,
        4,
        0,
        0,
        2,
        0,
        1,
        1,
        3,
        1,
        4,
        3,
        4,
        4,
        0,
        0,
        4,
        4,
        0,
        4,
        0,
        2,
        4,
        0,
        3,
        1,
        3,
        4
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        2,
        1,
        1,
        1,
        0,
        4,
        4,
        2,
        0,
        0,
        4,
        4,
        1,
        0,
        2,
        4,
        3,
        1,
        2,
        1,
        0,
        0,
        1,
        2,
        0,
        2,
        1,
        3,
        0,
        4,
        0,
        3,
        3,
        1,
        2,
        3,
        4,
        4,
        0,
        0,
        4,
        2,
        4,
        4,
        2,
        0,
        1,
        2,
        4,
        2,
        1,
        3
      ]
    },
    {
      "id": "D9345860",
      "weeklyDisrupted": [
        4,
        4,
        2,
        2,
        4,
        1,
        1,
        3,
        3,
        0,
        4,
        2,
        4,
        4,
        4,
        2,
        2,
        1,
        0,
        3,
        4,
        0,
        4,
        2,
        2,
        2,
        2,
        0,
        3,
        1,
        0,
        4,
        1,
        0,
        2,
        3,
        3,
        1,
        3,
        3,
        3,
        4,
        3,
        4,
        4,
        3,
        0,
        3,
        2,
        0,
        2,
        4
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        4,
        1,
        0,
        4,
        1,
        3,
        0,
        0,
        4,
        4,
        0,
        3,
        4,
        3,
        4,
        1,
        4,
        1,
        2,
        2,
        1,
        1,
        4,
        0,
        2,
        3,
        1,
        1,
        1,
        0,
        0,
        3,
        2,
        1,
        3,
        3,
        4,
        4,
        0,
        0,
        4,
        4,
        4,
        1,
        3,
        4,
        1,
        3,
        1,
        4,
        4,
        0
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        4,
        3,
        0,
        0,
        2,
        3,
        2,
        0,
        4,
        0,
        4,
        1,
        4,
        4,
        3,
        0,
        4,
        0,
        1,
        2,
        4,
        0,
        4,
        4,
        1,
        0,
        1,
        1,
        4,
        4,
        2,
        2,
        0,
        0,
        1,
        2,
        2,
        0,
        2,
        2,
        2,
        3,
        2,
        3,
        0,
        4,
        0,
        1,
        4,
        4,
        0,
        0
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyDisrupted": [
        3,
        4,
        0,
        1,
        1,
        3,
        0,
        4,
        4,
        2,
        4,
        2,
        4,
        1,
        2,
        4,
        3,
        0,
        2,
        3,
        0,
        0,
        3,
        1,
        0,
        4,
        2,
        0,
        3,
        4,
        0,
        2,
        3,
        0,
        2,
        4,
        4,
        2,
        3,
        2,
        4,
        4,
        3,
        4,
        2,
        0,
        0,
        1,
        4,
        3,
        2,
        4
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        2,
        4,
        1,
        4,
        1,
        0,
        1,
        4,
        4,
        0,
        4,
        4,
        0,
        1,
        3,
        0,
        2,
        1,
        1,
        0,
        3,
        4,
        0,
        1,
        4,
        2,
        0,
        0,
        2,
        3,
        1,
        4,
        1,
        0,
        0,
        1,
        0,
        4,
        2,
        3,
        4,
        0,
        2,
        3,
        4,
        3,
        3,
        0,
        4,
        2,
        0,
        2
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        2,
        4,
        0,
        2,
        0,
        4,
        1,
        4,
        4,
        0,
        1,
        4,
        4,
        0,
        2,
        3,
        0,
        0,
        1,
        0,
        4,
        2,
        3,
        2,
        0,
        1,
        1,
        0,
        0,
        0,
        2,
        2,
        1,
        0,
        4,
        2,
        4,
        4,
        4,
        3,
        3,
        0,
        2,
        0,
        1,
        4,
        0,
        1,
        3,
        3,
        0,
        4
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        2,
        1,
        2,
        4,
        2,
        4,
        4,
        2,
        0,
        1,
        1,
        1,
        2,
        0,
        4,
        0,
        1,
        1,
        0,
        4,
        2,
        3,
        2,
        0,
        0,
        2,
        2,
        0,
        1,
        2,
        0,
        4,
        4,
        0,
        4,
        2,
        2,
        1,
        1,
        0,
        3,
        1,
        1,
        4,
        0,
        3,
        0,
        0,
        2,
        4,
        2,
        3
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyDisrupted": [
        1,
        2,
        4,
        4,
        0,
        2,
        2,
        3,
        0,
        0,
        4,
        4,
        0,
        4,
        4,
        2,
        0,
        2,
        0,
        0,
        4,
        1,
        3,
        2,
        0,
        2,
        1,
        0,
        3,
        0,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        3,
        2,
        1,
        2,
        1,
        2,
        2,
        0,
        4,
        0,
        2,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        4,
        1,
        1,
        4,
        0,
        1,
        0,
        0,
        3,
        0,
        3,
        3,
        4,
        4,
        3,
        4,
        2,
        2,
        4,
        0,
        4,
        4,
        3,
        2,
        0,
        4,
        1,
        1,
        3,
        4,
        2,
        4,
        4,
        2,
        0,
        1,
        2,
        3,
        4,
        0,
        4,
        4,
        0,
        2,
        4,
        2,
        4,
        2,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        2,
        4,
        0,
        3,
        0,
        2,
        0,
        4,
        2,
        0,
        0,
        0,
        4,
        3,
        2,
        2,
        0,
        0,
        1,
        1,
        3,
        1,
        4,
        4,
        4,
        4,
        0,
        1,
        0,
        1,
        4,
        1,
        2,
        3,
        1,
        4,
        4,
        4,
        2,
        4,
        3,
        2,
        2,
        1,
        4,
        3,
        1,
        2,
        1,
        3,
        2,
        3
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        4,
        2,
        1,
        3,
        2,
        1,
        0,
        2,
        4,
        3,
        2,
        3,
        4,
        1,
        2,
        4,
        4,
        4,
        2,
        0,
        0,
        1,
        1,
        0,
        0,
        4,
        1,
        1,
        3,
        3,
        1,
        2,
        1,
        4,
        2,
        2,
        3,
        3,
        4,
        0,
        4,
        2,
        0,
        2,
        3,
        2,
        4,
        1,
        3,
        4,
        3,
        4
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        4,
        4,
        1,
        0,
        2,
        4,
        4,
        1,
        0,
        3,
        4,
        4,
        1,
        4,
        1,
        1,
        2,
        4,
        0,
        1,
        2,
        4,
        0,
        1,
        0,
        4,
        0,
        0,
        0,
        0,
        3,
        4,
        4,
        0,
        3,
        1,
        4,
        4,
        0,
        0,
        0,
        0,
        2,
        3,
        1,
        1,
        0,
        2,
        0,
        1,
        4,
        0
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        3,
        2,
        3,
        2,
        4,
        4,
        4,
        0,
        4,
        0,
        4,
        0,
        3,
        4,
        2,
        4,
        4,
        0,
        3,
        0,
        1,
        0,
        0,
        1,
        1,
        0,
        2,
        1,
        2,
        4,
        3,
        2,
        3,
        2,
        1,
        3,
        4,
        3,
        1,
        0,
        4,
        4,
        0,
        4,
        1,
        0,
        3,
        0,
        2,
        3,
        1,
        0
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        4,
        0,
        3,
        1,
        1,
        1,
        2,
        4,
        4,
        3,
        4,
        3,
        2,
        1,
        1,
        0,
        3,
        0,
        3,
        1,
        2,
        0,
        2,
        4,
        0,
        2,
        2,
        4,
        3,
        4,
        4,
        3,
        3,
        4,
        4,
        0,
        4,
        4,
        3,
        1,
        1,
        2,
        1,
        2,
        4,
        1,
        1,
        1,
        4,
        4,
        1,
        0
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        3,
        0,
        2,
        2,
        4,
        4,
        0,
        1,
        3,
        4,
        4,
        4,
        4,
        2,
        0,
        4,
        4,
        1,
        2,
        1,
        3,
        0,
        0,
        2,
        2,
        1,
        1,
        4,
        3,
        4,
        1,
        3,
        0,
        2,
        4,
        4,
        3,
        4,
        1,
        0,
        4,
        3,
        0,
        4,
        1,
        1,
        0,
        4,
        4,
        4,
        3,
        0
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        4,
        0,
        4,
        1,
        4,
        4,
        0,
        0,
        4,
        1,
        4,
        0,
        4,
        1,
        1,
        4,
        1,
        0,
        4,
        2,
        4,
        3,
        0,
        1,
        0,
        2,
        1,
        2,
        1,
        1,
        2,
        4,
        4,
        0,
        3,
        3,
        4,
        3,
        1,
        0,
        3,
        3,
        0,
        4,
        3,
        3,
        1,
        2,
        4,
        1,
        0,
        4
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        2,
        0,
        0,
        2,
        1,
        4,
        0,
        1,
        4,
        4,
        2,
        3,
        4,
        3,
        1,
        4,
        2,
        0,
        3,
        4,
        0,
        2,
        4,
        1,
        0,
        2,
        1,
        1,
        2,
        4,
        4,
        0,
        3,
        1,
        0,
        3,
        4,
        4,
        1,
        1,
        1,
        4,
        1,
        0,
        0,
        3,
        0,
        0,
        4,
        3,
        4,
        2
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        0,
        2,
        4,
        4,
        0,
        4,
        0,
        2,
        1,
        1,
        4,
        4,
        0,
        0,
        4,
        2,
        2,
        0,
        0,
        0,
        3,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        2,
        3,
        0,
        4,
        4,
        1,
        0,
        4,
        3,
        0,
        2,
        1,
        2,
        3,
        0,
        1,
        0,
        2,
        0,
        0,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        4,
        2,
        0,
        3,
        4,
        2,
        0,
        0,
        0,
        0,
        4,
        3,
        2,
        4,
        1,
        0,
        3,
        1,
        1,
        2,
        2,
        4,
        4,
        1,
        2,
        3,
        3,
        2,
        4,
        4,
        2,
        2,
        2,
        1,
        1,
        4,
        4,
        4,
        2,
        1,
        4,
        3,
        4,
        4,
        2,
        2,
        4,
        1,
        4,
        4,
        0,
        3
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        1,
        0,
        4,
        0,
        0,
        1,
        1,
        3,
        3,
        1,
        0,
        2,
        1,
        2,
        4,
        4,
        0,
        2,
        4,
        4,
        4,
        4,
        4,
        0,
        4,
        1,
        0,
        0,
        0,
        0,
        4,
        2,
        1,
        1,
        1,
        3,
        1,
        0,
        1,
        1,
        1,
        4,
        1,
        0,
        1,
        3,
        0,
        2,
        2,
        3,
        4,
        2
      ]
    },
    {
      "id": "048701CE",
      "weeklyDisrupted": [
        1,
        2,
        4,
        4,
        2,
        1,
        0,
        4,
        0,
        3,
        0,
        3,
        4,
        0,
        2,
        3,
        1,
        0,
        0,
        4,
        0,
        2,
        4,
        1,
        3,
        2,
        0,
        0,
        2,
        2,
        1,
        2,
        2,
        0,
        3,
        2,
        2,
        0,
        3,
        0,
        1,
        3,
        2,
        4,
        4,
        1,
        1,
        2,
        0,
        4,
        4,
        3
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        0,
        4,
        2,
        3,
        3,
        0,
        4,
        0,
        2,
        1,
        3,
        1,
        3,
        3,
        2,
        0,
        2,
        1,
        0,
        4,
        4,
        4,
        4,
        0,
        3,
        3,
        4,
        3,
        3,
        2,
        4,
        4,
        0,
        2,
        0,
        4,
        1,
        3,
        4,
        0,
        0,
        2,
        0,
        4,
        0,
        4,
        0,
        3,
        1,
        1,
        1,
        2
      ]
    },
    {
      "id": "41097027",
      "weeklyDisrupted": [
        4,
        3,
        0,
        4,
        1,
        1,
        0,
        3,
        4,
        0,
        4,
        2,
        4,
        2,
        2,
        4,
        3,
        1,
        0,
        1,
        3,
        3,
        3,
        0,
        0,
        3,
        4,
        0,
        3,
        0,
        3,
        2,
        4,
        1,
        3,
        3,
        4,
        1,
        0,
        3,
        4,
        4,
        2,
        3,
        2,
        1,
        4,
        2,
        1,
        0,
        0,
        4
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        0,
        2,
        3,
        3,
        3,
        2,
        1,
        2,
        0,
        0,
        4,
        0,
        4,
        2,
        1,
        3,
        4,
        2,
        1,
        0,
        1,
        2,
        0,
        3,
        3,
        2,
        1,
        0,
        2,
        3,
        0,
        2,
        0,
        0,
        2,
        1,
        4,
        3,
        3,
        0,
        4,
        4,
        0,
        3,
        0,
        3,
        4,
        4,
        2,
        4,
        2,
        4
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        4,
        3,
        3,
        1,
        0,
        4,
        0,
        1,
        1,
        1,
        4,
        0,
        3,
        0,
        2,
        3,
        0,
        0,
        2,
        1,
        2,
        4,
        4,
        0,
        2,
        0,
        2,
        2,
        4,
        4,
        4,
        4,
        3,
        0,
        0,
        3,
        3,
        4,
        3,
        2,
        4,
        1,
        1,
        2,
        3,
        4,
        0,
        4,
        4,
        1,
        0,
        1
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        1,
        1,
        0,
        3,
        4,
        2,
        0,
        2,
        1,
        2,
        4,
        4,
        1,
        2,
        2,
        0,
        3,
        1,
        4,
        3,
        4,
        0,
        2,
        4,
        3,
        0,
        1,
        2,
        4,
        4,
        0,
        2,
        4,
        0,
        1,
        2,
        2,
        0,
        0,
        0,
        4,
        4,
        4,
        4,
        3,
        3,
        1,
        0,
        1,
        1,
        0,
        4
      ]
    },
    {
      "id": "049BCC88",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        4,
        4,
        0,
        3,
        0,
        3,
        4,
        2,
        4,
        4,
        1,
        3,
        0,
        2,
        4,
        3,
        2,
        0,
        0,
        1,
        0,
        2,
        1,
        1,
        3,
        1,
        1,
        3,
        4,
        0,
        1,
        0,
        4,
        4,
        4,
        0,
        3,
        3,
        4,
        4,
        0,
        3,
        4,
        1,
        1,
        4,
        4,
        4
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        4,
        2,
        2,
        0,
        1,
        4,
        0,
        1,
        1,
        2,
        4,
        2,
        1,
        2,
        3,
        3,
        1,
        0,
        2,
        2,
        0,
        3,
        4,
        1,
        0,
        0,
        2,
        0,
        4,
        0,
        0,
        4,
        0,
        0,
        2,
        3,
        4,
        4,
        0,
        2,
        4,
        3,
        4,
        4,
        0,
        0,
        3,
        4,
        0,
        2,
        3,
        2
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        0,
        2,
        2,
        1,
        2,
        2,
        0,
        2,
        4,
        4,
        3,
        4,
        2,
        2,
        2,
        4,
        0,
        4,
        1,
        0,
        3,
        4,
        0,
        1,
        0,
        1,
        1,
        0,
        3,
        0,
        0,
        1,
        0,
        0,
        3,
        2,
        4,
        4,
        2,
        3,
        0,
        4,
        1,
        1,
        3,
        0,
        1,
        2,
        4,
        2,
        3,
        1
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        2,
        3,
        0,
        3,
        1,
        1,
        0,
        1,
        4,
        1,
        4,
        1,
        2,
        3,
        2,
        4,
        3,
        0,
        2,
        3,
        3,
        3,
        4,
        1,
        0,
        0,
        1,
        1,
        4,
        2,
        3,
        3,
        3,
        2,
        1,
        2,
        1,
        0,
        0,
        0,
        4,
        2,
        1,
        1,
        0,
        4,
        1,
        2,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        0,
        3,
        3,
        0,
        2,
        4,
        0,
        1,
        2,
        2,
        4,
        4,
        2,
        4,
        4,
        4,
        2,
        1,
        2,
        2,
        4,
        3,
        3,
        2,
        0,
        2,
        1,
        0,
        1,
        0,
        2,
        1,
        2,
        0,
        3,
        4,
        3,
        4,
        0,
        0,
        2,
        4,
        4,
        2,
        2,
        3,
        2,
        2,
        2,
        2,
        4,
        4
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        2,
        2,
        1,
        1,
        0,
        2,
        0,
        2,
        2,
        2,
        0,
        2,
        3,
        4,
        1,
        1,
        1,
        0,
        3,
        0,
        4,
        1,
        3,
        0,
        0,
        2,
        2,
        1,
        3,
        2,
        0,
        3,
        1,
        0,
        0,
        4,
        4,
        0,
        0,
        0,
        4,
        3,
        2,
        4,
        0,
        4,
        1,
        0,
        3,
        4,
        4,
        1
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        3,
        3,
        4,
        1,
        0,
        4,
        0,
        4,
        2,
        4,
        4,
        3,
        4,
        1,
        1,
        2,
        0,
        0,
        3,
        2,
        3,
        4,
        1,
        0,
        0,
        2,
        0,
        2,
        2,
        0,
        3,
        2,
        2,
        2,
        2,
        4,
        4,
        4,
        0,
        0,
        4,
        1,
        4,
        4,
        1,
        2,
        0,
        3,
        3,
        2,
        0,
        4
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        1,
        1,
        3,
        4,
        1,
        2,
        0,
        3,
        1,
        0,
        0,
        0,
        4,
        2,
        4,
        4,
        1,
        3,
        2,
        4,
        1,
        4,
        4,
        3,
        3,
        4,
        3,
        0,
        2,
        4,
        0,
        3,
        1,
        0,
        1,
        2,
        4,
        0,
        2,
        0,
        4,
        0,
        3,
        4,
        1,
        1,
        0,
        2,
        1,
        0,
        3,
        4
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        0,
        3,
        0,
        3,
        4,
        3,
        0,
        2,
        4,
        3,
        4,
        2,
        2,
        0,
        3,
        1,
        0,
        0,
        0,
        1,
        0,
        3,
        0,
        4,
        0,
        4,
        2,
        3,
        2,
        0,
        1,
        4,
        1,
        3,
        1,
        2,
        3,
        3,
        1,
        2,
        0,
        2,
        2,
        3,
        4,
        4,
        2,
        3,
        3,
        0,
        0,
        4
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        4,
        2,
        0,
        4,
        2,
        4,
        2,
        0,
        1,
        1,
        4,
        4,
        2,
        4,
        2,
        4,
        3,
        0,
        2,
        3,
        2,
        2,
        4,
        1,
        1,
        2,
        2,
        3,
        3,
        4,
        3,
        2,
        3,
        0,
        4,
        2,
        4,
        2,
        0,
        0,
        4,
        0,
        1,
        2,
        0,
        2,
        1,
        3,
        4,
        2,
        3,
        3
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        3,
        0,
        2,
        3,
        0,
        3,
        1,
        3,
        1,
        2,
        0,
        2,
        2,
        0,
        1,
        3,
        0,
        2,
        1,
        1,
        4,
        4,
        2,
        0,
        0,
        0,
        2,
        3,
        4,
        0,
        0,
        2,
        1,
        3,
        0,
        0,
        4,
        4,
        0,
        2,
        4,
        3,
        4,
        4,
        1,
        2,
        3,
        2,
        4,
        0,
        3,
        0
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        0,
        4,
        0,
        2,
        1,
        0,
        2,
        4,
        2,
        2,
        3,
        1,
        2,
        0,
        3,
        4,
        1,
        1,
        0,
        2,
        1,
        2,
        0,
        0,
        3,
        3,
        1,
        0,
        0,
        1,
        0,
        1,
        3,
        0,
        3,
        4,
        3,
        0,
        0,
        3,
        0,
        1,
        2,
        2,
        0,
        2,
        3,
        1,
        2,
        4,
        1,
        4
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        1,
        3,
        3,
        4,
        1,
        0,
        0,
        2,
        4,
        0,
        3,
        3,
        2,
        1,
        1,
        4,
        3,
        1,
        1,
        3,
        0,
        3,
        4,
        2,
        0,
        3,
        3,
        1,
        4,
        3,
        2,
        2,
        0,
        0,
        2,
        2,
        2,
        0,
        1,
        0,
        1,
        3,
        1,
        2,
        1,
        2,
        2,
        0,
        4,
        0,
        3,
        1
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        2,
        4,
        4,
        0,
        3,
        4,
        1,
        4,
        3,
        0,
        0,
        4,
        4,
        1,
        4,
        2,
        2,
        0,
        0,
        4,
        0,
        1,
        4,
        1,
        0,
        2,
        0,
        0,
        4,
        2,
        4,
        0,
        3,
        2,
        4,
        2,
        3,
        4,
        0,
        2,
        1,
        0,
        1,
        2,
        0,
        2,
        0,
        2,
        1,
        4,
        3,
        4
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        4,
        0,
        2,
        1,
        2,
        0,
        0,
        0,
        0,
        4,
        1,
        1,
        0,
        4,
        4,
        4,
        4,
        0,
        1,
        4,
        2,
        0,
        3,
        2,
        1,
        0,
        2,
        1,
        0,
        4,
        2,
        3,
        2,
        0,
        4,
        3,
        0,
        0,
        0,
        0,
        2,
        2,
        3,
        3,
        0,
        3,
        4,
        0,
        3,
        0,
        2,
        2
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyDisrupted": [
        2,
        2,
        4,
        4,
        2,
        2,
        4,
        4,
        4,
        3,
        1,
        3,
        4,
        4,
        0,
        4,
        4,
        3,
        0,
        0,
        3,
        3,
        0,
        0,
        0,
        3,
        0,
        0,
        1,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        2,
        4,
        2,
        0,
        4,
        3,
        3,
        4,
        3,
        1,
        0,
        4,
        4,
        4,
        3,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        0,
        2,
        2,
        0,
        4,
        4,
        1,
        0,
        3,
        2,
        4,
        2,
        4,
        0,
        2,
        1,
        3,
        2,
        4,
        3,
        2,
        2,
        3,
        4,
        0,
        2,
        0,
        0,
        4,
        2,
        0,
        3,
        4,
        0,
        4,
        4,
        3,
        4,
        3,
        0,
        3,
        3,
        2,
        4,
        0,
        3,
        3,
        0,
        0,
        4,
        2,
        1
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        0,
        4,
        0,
        4,
        0,
        1,
        0,
        2,
        2,
        4,
        1,
        0,
        2,
        2,
        3,
        2,
        1,
        1,
        2,
        2,
        3,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        3,
        0,
        4,
        4,
        0,
        0,
        2,
        2,
        0,
        0,
        0,
        2,
        3,
        4,
        4,
        0,
        3,
        0,
        2,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        3,
        2,
        3,
        1,
        0,
        0,
        0,
        1,
        1,
        2,
        3,
        3,
        3,
        4,
        0,
        1,
        2,
        3,
        2,
        2,
        4,
        4,
        2,
        3,
        0,
        2,
        1,
        3,
        0,
        4,
        4,
        4,
        0,
        0,
        2,
        4,
        3,
        4,
        3,
        0,
        2,
        0,
        1,
        3,
        4,
        1,
        0,
        0,
        0,
        4,
        3,
        0
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        3,
        4,
        4,
        1,
        4,
        0,
        0,
        3,
        2,
        0,
        0,
        3,
        4,
        4,
        2,
        3,
        4,
        1,
        3,
        3,
        4,
        3,
        4,
        0,
        1,
        3,
        4,
        0,
        3,
        3,
        2,
        4,
        1,
        1,
        0,
        3,
        0,
        1,
        2,
        0,
        2,
        2,
        1,
        3,
        4,
        2,
        2,
        4,
        2,
        4,
        2,
        0
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        1,
        0,
        1,
        1,
        4,
        4,
        0,
        1,
        4,
        4,
        4,
        4,
        4,
        1,
        1,
        4,
        1,
        1,
        2,
        2,
        0,
        3,
        0,
        1,
        0,
        4,
        0,
        0,
        1,
        4,
        2,
        0,
        0,
        0,
        0,
        3,
        3,
        0,
        1,
        0,
        3,
        4,
        2,
        2,
        3,
        2,
        0,
        0,
        3,
        0,
        4,
        0
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        2,
        4,
        3,
        3,
        0,
        0,
        4,
        3,
        3,
        1,
        2,
        4,
        0,
        0,
        2,
        0,
        0,
        4,
        0,
        3,
        0,
        3,
        3,
        0,
        0,
        2,
        1,
        0,
        1,
        0,
        3,
        0,
        1,
        0,
        1,
        4,
        1,
        4,
        2,
        1,
        3,
        2,
        2,
        3,
        0,
        3,
        3,
        1,
        2,
        2,
        4,
        0
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        0,
        1,
        2,
        2,
        3,
        1,
        0,
        1,
        4,
        0,
        0,
        4,
        3,
        0,
        3,
        4,
        2,
        1,
        0,
        2,
        1,
        0,
        4,
        1,
        2,
        3,
        3,
        0,
        2,
        0,
        1,
        3,
        0,
        3,
        3,
        3,
        4,
        0,
        4,
        3,
        4,
        1,
        2,
        3,
        1,
        4,
        2,
        2,
        3,
        4,
        0,
        2
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        3,
        0,
        2,
        2,
        0,
        4,
        2,
        0,
        0,
        0,
        3,
        0,
        4,
        2,
        1,
        1,
        0,
        0,
        3,
        0,
        1,
        1,
        0,
        2,
        0,
        1,
        4,
        0,
        1,
        3,
        1,
        4,
        4,
        0,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        0,
        0,
        2,
        0,
        2,
        1,
        4,
        1,
        4,
        2,
        0
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        3,
        4,
        2,
        2,
        3,
        0,
        0,
        2,
        3,
        1,
        4,
        4,
        2,
        3,
        2,
        0,
        4,
        3,
        4,
        3,
        1,
        2,
        2,
        3,
        0,
        0,
        0,
        0,
        3,
        0,
        0,
        4,
        0,
        0,
        1,
        1,
        3,
        0,
        0,
        0,
        1,
        1,
        0,
        2,
        1,
        3,
        0,
        3,
        4,
        0,
        3,
        2
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyDisrupted": [
        0,
        0,
        3,
        3,
        1,
        3,
        1,
        1,
        4,
        0,
        3,
        2,
        4,
        0,
        2,
        2,
        3,
        1,
        0,
        4,
        1,
        1,
        4,
        3,
        0,
        4,
        2,
        1,
        0,
        4,
        3,
        3,
        4,
        3,
        4,
        3,
        4,
        4,
        0,
        2,
        3,
        2,
        1,
        4,
        0,
        3,
        0,
        1,
        0,
        4,
        2,
        2
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        3,
        3,
        0,
        1,
        1,
        3,
        1,
        4,
        2,
        0,
        4,
        3,
        2,
        0,
        3,
        4,
        4,
        0,
        1,
        1,
        4,
        4,
        1,
        2,
        2,
        1,
        0,
        3,
        0,
        0,
        0,
        1,
        4,
        2,
        4,
        2,
        1,
        4,
        2,
        1,
        0,
        3,
        1,
        4,
        4,
        0,
        4,
        2,
        1,
        2,
        0,
        2
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        0,
        3,
        2,
        4,
        0,
        2,
        0,
        4,
        2,
        4,
        2,
        4,
        4,
        0,
        3,
        0,
        1,
        0,
        2,
        1,
        0,
        2,
        4,
        1,
        0,
        2,
        0,
        0,
        1,
        2,
        4,
        2,
        4,
        3,
        3,
        4,
        1,
        3,
        0,
        0,
        1,
        2,
        2,
        2,
        0,
        4,
        0,
        0,
        4,
        4,
        1,
        4
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        0,
        2,
        0,
        2,
        0,
        2,
        2,
        1,
        2,
        1,
        4,
        4,
        0,
        0,
        2,
        4,
        3,
        0,
        1,
        4,
        4,
        4,
        3,
        1,
        0,
        0,
        3,
        0,
        4,
        0,
        0,
        2,
        3,
        1,
        0,
        2,
        4,
        0,
        0,
        2,
        2,
        3,
        0,
        4,
        1,
        0,
        2,
        3,
        3,
        4,
        3,
        4
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        2,
        1,
        1,
        4,
        1,
        1,
        0,
        3,
        2,
        2,
        1,
        3,
        4,
        2,
        3,
        4,
        4,
        0,
        0,
        2,
        0,
        0,
        4,
        0,
        2,
        3,
        0,
        0,
        0,
        3,
        4,
        4,
        2,
        2,
        1,
        1,
        3,
        3,
        4,
        2,
        1,
        2,
        3,
        3,
        0,
        0,
        3,
        0,
        0,
        1,
        4,
        3
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        4,
        3,
        0,
        0,
        4,
        0,
        0,
        3,
        3,
        4,
        4,
        4,
        3,
        4,
        0,
        4,
        0,
        0,
        2,
        1,
        0,
        4,
        1,
        2,
        0,
        3,
        0,
        1,
        4,
        1,
        0,
        4,
        2,
        1,
        0,
        0,
        3,
        0,
        2,
        0,
        2,
        2,
        2,
        2,
        0,
        4,
        0,
        2,
        2,
        1,
        0,
        0
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        3,
        2,
        3,
        4,
        2,
        1,
        0,
        4,
        4,
        2,
        4,
        4,
        2,
        2,
        3,
        0,
        3,
        3,
        3,
        0,
        3,
        4,
        3,
        0,
        0,
        2,
        1,
        3,
        0,
        2,
        1,
        4,
        0,
        1,
        3,
        4,
        3,
        4,
        2,
        3,
        4,
        2,
        2,
        4,
        0,
        1,
        0,
        4,
        0,
        1,
        3,
        0
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        2,
        2,
        2,
        4,
        0,
        2,
        0,
        2,
        0,
        0,
        4,
        4,
        3,
        3,
        2,
        4,
        3,
        0,
        0,
        0,
        1,
        0,
        4,
        0,
        3,
        4,
        0,
        0,
        0,
        4,
        2,
        4,
        0,
        0,
        4,
        4,
        4,
        3,
        2,
        0,
        0,
        2,
        0,
        4,
        2,
        2,
        1,
        0,
        2,
        0,
        1,
        2
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        3,
        2,
        4,
        2,
        0,
        3,
        0,
        2,
        1,
        0,
        4,
        0,
        0,
        2,
        1,
        4,
        4,
        0,
        0,
        3,
        3,
        2,
        4,
        2,
        1,
        2,
        1,
        0,
        4,
        3,
        0,
        4,
        4,
        0,
        3,
        4,
        4,
        4,
        3,
        0,
        2,
        4,
        0,
        3,
        4,
        2,
        0,
        2,
        4,
        2,
        1,
        0
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        2,
        1,
        3,
        0,
        0,
        4,
        3,
        1,
        4,
        4,
        2,
        0,
        4,
        2,
        3,
        3,
        4,
        0,
        3,
        1,
        3,
        2,
        3,
        2,
        0,
        1,
        0,
        4,
        2,
        1,
        0,
        4,
        3,
        1,
        4,
        2,
        4,
        2,
        0,
        1,
        4,
        2,
        0,
        2,
        1,
        0,
        1,
        3,
        4,
        2,
        2,
        2
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyDisrupted": [
        4,
        4,
        4,
        3,
        4,
        1,
        3,
        2,
        2,
        1,
        4,
        4,
        4,
        2,
        4,
        0,
        2,
        0,
        2,
        4,
        2,
        3,
        4,
        4,
        1,
        3,
        2,
        2,
        2,
        2,
        0,
        2,
        2,
        0,
        2,
        4,
        3,
        1,
        1,
        0,
        3,
        2,
        3,
        4,
        4,
        4,
        0,
        0,
        4,
        4,
        4,
        4
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        4,
        4,
        3,
        1,
        2,
        3,
        0,
        2,
        0,
        3,
        4,
        4,
        0,
        2,
        1,
        2,
        2,
        3,
        0,
        2,
        2,
        4,
        4,
        2,
        0,
        3,
        0,
        0,
        0,
        4,
        0,
        3,
        0,
        0,
        0,
        3,
        1,
        0,
        2,
        1,
        0,
        1,
        4,
        2,
        0,
        4,
        1,
        0,
        0,
        4,
        4,
        2
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        2,
        3,
        2,
        4,
        0,
        1,
        4,
        0,
        3,
        0,
        1,
        0,
        4,
        4,
        1,
        4,
        0,
        4,
        3,
        0,
        4,
        2,
        3,
        3,
        0,
        3,
        1,
        1,
        0,
        4,
        4,
        4,
        2,
        1,
        2,
        2,
        4,
        4,
        3,
        0,
        2,
        4,
        2,
        2,
        0,
        3,
        2,
        1,
        0,
        4,
        1,
        4
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        2,
        2,
        4,
        0,
        3,
        0,
        1,
        4,
        0,
        0,
        4,
        2,
        4,
        4,
        2,
        2,
        2,
        2,
        4,
        3,
        4,
        4,
        1,
        4,
        1,
        0,
        3,
        4,
        1,
        4,
        0,
        4,
        3,
        2,
        3,
        3,
        0,
        4,
        1,
        1,
        3,
        4,
        1,
        0,
        1,
        4,
        1,
        4,
        3,
        1,
        2,
        3
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        4,
        3,
        2,
        1,
        1,
        0,
        0,
        2,
        1,
        3,
        4,
        4,
        4,
        4,
        4,
        1,
        3,
        4,
        2,
        0,
        3,
        2,
        0,
        0,
        0,
        0,
        0,
        3,
        4,
        1,
        1,
        2,
        1,
        1,
        1,
        4,
        0,
        0,
        0,
        0,
        4,
        2,
        3,
        2,
        0,
        0,
        1,
        2,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "41097027",
      "weeklyDisrupted": [
        1,
        2,
        0,
        0,
        4,
        1,
        1,
        0,
        0,
        3,
        4,
        0,
        4,
        4,
        0,
        0,
        1,
        1,
        3,
        0,
        2,
        2,
        2,
        0,
        0,
        4,
        2,
        3,
        0,
        2,
        3,
        4,
        2,
        1,
        2,
        3,
        2,
        0,
        0,
        0,
        4,
        3,
        2,
        1,
        2,
        3,
        0,
        1,
        4,
        1,
        0,
        2
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        3,
        3,
        1,
        3,
        1,
        4,
        1,
        1,
        4,
        0,
        2,
        2,
        4,
        1,
        4,
        0,
        0,
        0,
        0,
        3,
        4,
        0,
        2,
        4,
        0,
        3,
        0,
        2,
        0,
        3,
        4,
        3,
        4,
        0,
        2,
        4,
        4,
        4,
        0,
        2,
        4,
        4,
        0,
        0,
        4,
        4,
        0,
        3,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "0AB87239",
      "weeklyDisrupted": [
        2,
        3,
        2,
        1,
        4,
        3,
        0,
        1,
        4,
        0,
        4,
        3,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        2,
        0,
        0,
        1,
        4,
        0,
        4,
        1,
        0,
        2,
        4,
        3,
        4,
        0,
        4,
        4,
        2,
        4,
        1,
        0,
        0,
        2,
        3,
        1,
        4,
        0,
        0,
        0,
        4,
        4,
        4,
        2,
        1
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        3,
        2,
        4,
        3,
        0,
        4,
        4,
        4,
        2,
        1,
        1,
        3,
        0,
        2,
        0,
        4,
        0,
        1,
        0,
        4,
        3,
        4,
        3,
        2,
        2,
        1,
        3,
        1,
        3,
        0,
        0,
        4,
        0,
        1,
        1,
        4,
        1,
        0,
        0,
        0,
        4,
        1,
        2,
        0,
        0,
        2,
        0,
        2,
        4,
        3,
        3,
        3
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        4,
        4,
        3,
        3,
        4,
        2,
        1,
        0,
        2,
        3,
        4,
        3,
        2,
        1,
        3,
        4,
        3,
        2,
        3,
        0,
        2,
        3,
        2,
        0,
        1,
        2,
        0,
        0,
        1,
        4,
        0,
        1,
        1,
        2,
        0,
        1,
        1,
        0,
        3,
        0,
        4,
        2,
        0,
        3,
        1,
        2,
        4,
        1,
        2,
        1,
        3,
        4
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        1,
        0,
        0,
        0,
        0,
        3,
        2,
        3,
        1,
        0,
        2,
        2,
        2,
        3,
        2,
        4,
        4,
        1,
        2,
        4,
        0,
        2,
        4,
        0,
        3,
        0,
        1,
        4,
        3,
        2,
        4,
        4,
        3,
        1,
        0,
        1,
        2,
        4,
        2,
        2,
        2,
        4,
        0,
        4,
        4,
        4,
        0,
        2,
        4,
        3,
        0,
        0
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        3,
        0,
        4,
        0,
        4,
        4,
        3,
        4,
        0,
        1,
        4,
        4,
        1,
        4,
        2,
        3,
        2,
        3,
        2,
        1,
        1,
        4,
        3,
        0,
        3,
        4,
        2,
        0,
        0,
        1,
        2,
        2,
        2,
        0,
        4,
        2,
        2,
        4,
        3,
        1,
        3,
        2,
        2,
        3,
        0,
        0,
        4,
        2,
        4,
        4,
        4,
        4
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyDisrupted": [
        3,
        3,
        1,
        4,
        0,
        2,
        2,
        3,
        4,
        1,
        4,
        4,
        1,
        4,
        1,
        3,
        4,
        0,
        2,
        0,
        0,
        4,
        0,
        0,
        0,
        3,
        2,
        2,
        3,
        2,
        2,
        2,
        0,
        0,
        2,
        2,
        3,
        4,
        0,
        2,
        3,
        1,
        3,
        4,
        0,
        1,
        0,
        0,
        2,
        0,
        1,
        3
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        4,
        2,
        2,
        1,
        3,
        0,
        3,
        0,
        4,
        4,
        1,
        4,
        2,
        3,
        4,
        3,
        4,
        1,
        3,
        4,
        1,
        1,
        2,
        0,
        1,
        2,
        0,
        0,
        0,
        1,
        4,
        3,
        0,
        0,
        0,
        3,
        4,
        1,
        0,
        2,
        2,
        3,
        0,
        3,
        4,
        3,
        1,
        2,
        2,
        0,
        3,
        1
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        2,
        0,
        4,
        3,
        3,
        4,
        1,
        2,
        1,
        4,
        3,
        2,
        1,
        4,
        1,
        1,
        1,
        0,
        4,
        1,
        0,
        4,
        3,
        2,
        2,
        4,
        3,
        0,
        3,
        4,
        0,
        4,
        4,
        0,
        3,
        4,
        3,
        4,
        1,
        1,
        4,
        3,
        1,
        4,
        0,
        4,
        0,
        0,
        1,
        1,
        3,
        1
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        3,
        2,
        2,
        4,
        1,
        4,
        0,
        4,
        2,
        3,
        1,
        0,
        2,
        2,
        3,
        0,
        3,
        4,
        1,
        4,
        3,
        4,
        4,
        0,
        0,
        2,
        3,
        1,
        3,
        4,
        1,
        1,
        0,
        4,
        1,
        2,
        4,
        0,
        0,
        0,
        1,
        2,
        1,
        0,
        1,
        2,
        0,
        2,
        1,
        4,
        4,
        2
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        0,
        0,
        0,
        4,
        3,
        1,
        3,
        2,
        1,
        0,
        2,
        2,
        2,
        3,
        3,
        0,
        4,
        0,
        1,
        2,
        3,
        3,
        4,
        2,
        0,
        1,
        0,
        0,
        2,
        3,
        1,
        2,
        2,
        2,
        2,
        3,
        3,
        0,
        0,
        1,
        4,
        3,
        2,
        2,
        4,
        0,
        4,
        2,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "D9345860",
      "weeklyDisrupted": [
        2,
        0,
        2,
        3,
        0,
        1,
        2,
        2,
        4,
        2,
        4,
        3,
        2,
        4,
        1,
        1,
        0,
        0,
        2,
        3,
        4,
        4,
        1,
        3,
        0,
        4,
        4,
        0,
        1,
        4,
        3,
        2,
        3,
        1,
        2,
        4,
        3,
        4,
        2,
        0,
        1,
        2,
        2,
        4,
        1,
        4,
        4,
        1,
        4,
        3,
        3,
        3
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        1,
        0,
        0,
        0,
        0,
        2,
        1,
        3,
        0,
        4,
        3,
        1,
        4,
        4,
        2,
        3,
        1,
        2,
        3,
        3,
        4,
        4,
        4,
        1,
        0,
        4,
        4,
        1,
        2,
        4,
        3,
        0,
        3,
        3,
        0,
        4,
        2,
        0,
        3,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        4,
        0,
        4,
        4,
        0,
        4
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        0,
        2,
        0,
        2,
        0,
        0,
        1,
        4,
        4,
        0,
        2,
        3,
        0,
        1,
        3,
        0,
        1,
        2,
        3,
        1,
        2,
        0,
        0,
        1,
        0,
        3,
        0,
        0,
        2,
        2,
        0,
        2,
        1,
        0,
        1,
        2,
        4,
        1,
        4,
        3,
        1,
        2,
        4,
        4,
        1,
        4,
        4,
        4,
        3,
        2,
        4,
        4
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        3,
        1,
        0,
        0,
        4,
        2,
        4,
        2,
        4,
        3,
        3,
        1,
        1,
        2,
        0,
        3,
        2,
        0,
        0,
        0,
        0,
        4,
        0,
        2,
        4,
        0,
        0,
        1,
        2,
        2,
        0,
        1,
        3,
        0,
        2,
        1,
        2,
        4,
        2,
        0,
        0,
        1,
        3,
        2,
        4,
        2,
        0,
        0,
        1,
        3,
        2,
        1
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        4,
        4,
        1,
        1,
        4,
        2,
        0,
        3,
        1,
        4,
        1,
        3,
        2,
        2,
        2,
        2,
        0,
        1,
        1,
        0,
        3,
        0,
        0,
        3,
        0,
        1,
        0,
        4,
        1,
        0,
        0,
        4,
        1,
        1,
        2,
        2,
        3,
        0,
        0,
        4,
        3,
        3,
        0,
        4,
        1,
        2,
        1,
        2,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "0AB87239",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        1,
        2,
        2,
        4,
        4,
        1,
        4,
        2,
        0,
        2,
        3,
        4,
        3,
        0,
        0,
        0,
        0,
        3,
        0,
        3,
        1,
        0,
        0,
        4,
        3,
        3,
        0,
        4,
        0,
        2,
        4,
        3,
        4,
        4,
        4,
        2,
        4,
        1,
        2,
        2,
        4,
        4,
        0,
        4,
        4,
        4,
        0,
        1
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        4,
        2,
        1,
        1,
        1,
        0,
        0,
        4,
        1,
        2,
        4,
        4,
        0,
        4,
        3,
        4,
        3,
        0,
        2,
        2,
        0,
        2,
        2,
        2,
        0,
        0,
        0,
        0,
        3,
        0,
        2,
        3,
        0,
        1,
        0,
        4,
        4,
        0,
        4,
        0,
        4,
        1,
        4,
        2,
        0,
        4,
        1,
        0,
        4,
        1,
        0,
        4
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        4,
        0,
        4,
        2,
        0,
        2,
        2,
        2,
        1,
        0,
        3,
        2,
        4,
        2,
        3,
        2,
        4,
        0,
        3,
        0,
        2,
        0,
        3,
        2,
        1,
        1,
        0,
        0,
        0,
        4,
        3,
        4,
        4,
        0,
        1,
        2,
        1,
        4,
        3,
        2,
        1,
        2,
        0,
        4,
        1,
        0,
        0,
        0,
        2,
        0,
        2,
        1
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        4,
        0,
        1,
        0,
        0,
        3,
        0,
        1,
        1,
        0,
        4,
        3,
        4,
        1,
        2,
        0,
        0,
        2,
        1,
        1,
        1,
        3,
        0,
        2,
        1,
        4,
        0,
        0,
        0,
        3,
        1,
        3,
        2,
        1,
        3,
        4,
        4,
        4,
        0,
        0,
        4,
        0,
        1,
        0,
        0,
        4,
        1,
        2,
        2,
        2,
        2,
        3
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyDisrupted": [
        0,
        0,
        1,
        3,
        0,
        3,
        4,
        1,
        0,
        0,
        1,
        1,
        4,
        2,
        2,
        4,
        3,
        4,
        1,
        0,
        3,
        2,
        0,
        1,
        2,
        4,
        1,
        0,
        4,
        3,
        2,
        3,
        1,
        2,
        3,
        2,
        3,
        3,
        0,
        0,
        1,
        3,
        0,
        4,
        2,
        2,
        4,
        4,
        2,
        3,
        3,
        2
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        2,
        1,
        0,
        2,
        2,
        2,
        0,
        3,
        1,
        2,
        1,
        2,
        4,
        3,
        2,
        3,
        4,
        0,
        2,
        4,
        4,
        4,
        4,
        4,
        1,
        4,
        0,
        0,
        2,
        3,
        4,
        2,
        3,
        1,
        0,
        4,
        4,
        3,
        1,
        0,
        0,
        4,
        1,
        0,
        0,
        1,
        3,
        0,
        4,
        1,
        4,
        0
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        2,
        0,
        3,
        0,
        0,
        2,
        1,
        3,
        4,
        1,
        3,
        2,
        3,
        1,
        0,
        4,
        3,
        2,
        0,
        4,
        0,
        3,
        3,
        3,
        2,
        0,
        2,
        0,
        2,
        4,
        3,
        3,
        4,
        0,
        4,
        1,
        2,
        4,
        0,
        0,
        3,
        1,
        3,
        3,
        3,
        2,
        3,
        3,
        0,
        0,
        4,
        2
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        0,
        0,
        2,
        4,
        2,
        1,
        1,
        4,
        1,
        4,
        1,
        4,
        4,
        4,
        3,
        4,
        4,
        0,
        3,
        2,
        3,
        2,
        4,
        2,
        0,
        4,
        3,
        0,
        4,
        2,
        0,
        2,
        0,
        1,
        2,
        2,
        4,
        3,
        2,
        2,
        0,
        1,
        0,
        4,
        2,
        2,
        1,
        2,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        4,
        2,
        1,
        2,
        4,
        1,
        0,
        3,
        3,
        2,
        4,
        3,
        4,
        4,
        1,
        4,
        4,
        3,
        4,
        3,
        1,
        1,
        3,
        0,
        0,
        3,
        0,
        0,
        2,
        4,
        0,
        2,
        0,
        0,
        2,
        4,
        4,
        1,
        2,
        0,
        3,
        3,
        0,
        4,
        2,
        3,
        4,
        2,
        4,
        4,
        4,
        0
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        0,
        2,
        0,
        0,
        0,
        3,
        0,
        1,
        4,
        4,
        4,
        4,
        4,
        0,
        2,
        1,
        0,
        3,
        2,
        2,
        4,
        0,
        2,
        1,
        0,
        2,
        1,
        4,
        1,
        0,
        0,
        3,
        0,
        0,
        0,
        3,
        4,
        4,
        0,
        1,
        4,
        1,
        0,
        4,
        0,
        2,
        0,
        2,
        4,
        4,
        0,
        3
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        4,
        1,
        3,
        4,
        3,
        2,
        1,
        0,
        1,
        0,
        0,
        1,
        0,
        2,
        0,
        4,
        4,
        0,
        0,
        3,
        0,
        2,
        4,
        2,
        1,
        3,
        2,
        1,
        1,
        4,
        2,
        4,
        3,
        0,
        0,
        0,
        4,
        0,
        3,
        1,
        0,
        3,
        0,
        4,
        2,
        3,
        0,
        0,
        4,
        0,
        3,
        4
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        2,
        0,
        3,
        2,
        3,
        2,
        2,
        2,
        3,
        4,
        1,
        3,
        3,
        3,
        4,
        1,
        2,
        0,
        3,
        3,
        0,
        4,
        4,
        2,
        0,
        1,
        0,
        1,
        1,
        0,
        2,
        1,
        2,
        0,
        3,
        1,
        2,
        3,
        2,
        0,
        4,
        4,
        3,
        4,
        0,
        2,
        4,
        2,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyDisrupted": [
        1,
        0,
        3,
        3,
        0,
        2,
        2,
        0,
        1,
        1,
        2,
        4,
        4,
        3,
        2,
        4,
        4,
        1,
        2,
        0,
        2,
        4,
        4,
        1,
        0,
        0,
        4,
        0,
        2,
        4,
        4,
        3,
        2,
        2,
        4,
        4,
        3,
        1,
        2,
        0,
        1,
        4,
        4,
        1,
        0,
        3,
        1,
        2,
        4,
        1,
        3,
        2
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        3,
        0,
        2,
        2,
        0,
        1,
        2,
        3,
        4,
        3,
        4,
        2,
        1,
        2,
        0,
        1,
        0,
        0,
        0,
        4,
        0,
        4,
        3,
        0,
        3,
        0,
        1,
        3,
        0,
        1,
        2,
        4,
        0,
        0,
        3,
        3,
        1,
        3,
        3,
        2,
        4,
        1,
        0,
        2,
        4,
        4,
        0,
        1,
        1,
        4,
        4
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        4,
        4,
        3,
        4,
        0,
        0,
        0,
        4,
        4,
        0,
        1,
        3,
        3,
        0,
        4,
        4,
        1,
        4,
        0,
        3,
        3,
        1,
        4,
        3,
        3,
        4,
        0,
        0,
        0,
        1,
        4,
        2,
        0,
        3,
        2,
        4,
        4,
        3,
        3,
        4,
        4,
        3,
        0,
        3,
        2,
        3,
        0,
        1,
        0,
        4,
        0,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        3,
        1,
        1,
        3,
        2,
        0,
        0,
        0,
        4,
        0,
        4,
        4,
        4,
        4,
        1,
        4,
        0,
        2,
        2,
        3,
        4,
        0,
        3,
        3,
        0,
        0,
        1,
        1,
        3,
        3,
        0,
        0,
        3,
        1,
        0,
        3,
        4,
        4,
        2,
        0,
        4,
        3,
        2,
        4,
        0,
        4,
        2,
        1,
        4,
        4,
        4,
        0
      ]
    },
    {
      "id": "41097027",
      "weeklyDisrupted": [
        0,
        2,
        4,
        3,
        1,
        0,
        0,
        4,
        4,
        2,
        2,
        4,
        2,
        4,
        3,
        0,
        4,
        2,
        0,
        0,
        1,
        4,
        4,
        0,
        0,
        2,
        1,
        0,
        2,
        4,
        0,
        3,
        0,
        0,
        4,
        4,
        2,
        4,
        3,
        0,
        4,
        0,
        3,
        2,
        2,
        3,
        0,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        3,
        0,
        2,
        3,
        0,
        3,
        4,
        0,
        3,
        0,
        4,
        1,
        2,
        3,
        4,
        0,
        1,
        1,
        2,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        3,
        0,
        4,
        2,
        1,
        3,
        3,
        1,
        0,
        2,
        2,
        1,
        0,
        3,
        2,
        1,
        1,
        4,
        4,
        2,
        2,
        2,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyDisrupted": [
        3,
        4,
        0,
        4,
        2,
        3,
        1,
        2,
        0,
        4,
        2,
        1,
        4,
        3,
        2,
        0,
        3,
        2,
        0,
        2,
        3,
        2,
        4,
        1,
        0,
        2,
        3,
        2,
        3,
        3,
        1,
        3,
        2,
        2,
        2,
        3,
        4,
        4,
        0,
        3,
        3,
        4,
        0,
        0,
        3,
        0,
        0,
        0,
        4,
        2,
        1,
        4
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        0,
        1,
        2,
        1,
        1,
        0,
        4,
        1,
        4,
        1,
        4,
        4,
        2,
        0,
        3,
        3,
        4,
        3,
        4,
        2,
        0,
        0,
        2,
        1,
        3,
        4,
        4,
        4,
        3,
        2,
        0,
        4,
        3,
        2,
        2,
        4,
        4,
        1,
        0,
        1,
        3,
        4,
        0,
        2,
        3,
        2,
        2,
        2
      ]
    },
    {
      "id": "14968630",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        4,
        2,
        1,
        1,
        2,
        0,
        3,
        1,
        3,
        4,
        4,
        0,
        1,
        2,
        1,
        4,
        0,
        0,
        0,
        1,
        1,
        3,
        2,
        1,
        4,
        2,
        2,
        3,
        2,
        2,
        4,
        4,
        4,
        4,
        0,
        0,
        3,
        4,
        3,
        4,
        2,
        1,
        0,
        2,
        0,
        0,
        2,
        2
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        0,
        4,
        1,
        3,
        0,
        4,
        0,
        1,
        1,
        0,
        3,
        4,
        4,
        0,
        2,
        1,
        3,
        0,
        0,
        1,
        0,
        2,
        4,
        4,
        0,
        4,
        1,
        0,
        2,
        3,
        0,
        1,
        4,
        0,
        1,
        4,
        3,
        4,
        0,
        0,
        0,
        3,
        4,
        1,
        1,
        4,
        4,
        1,
        4,
        2,
        4,
        3
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        0,
        4,
        1,
        0,
        0,
        2,
        0,
        4,
        2,
        2,
        4,
        4,
        0,
        4,
        1,
        4,
        3,
        4,
        1,
        4,
        0,
        4,
        4,
        1,
        0,
        0,
        2,
        3,
        0,
        1,
        4,
        2,
        4,
        3,
        4,
        4,
        3,
        4,
        2,
        0,
        0,
        1,
        2,
        4,
        1,
        4,
        0,
        3,
        0,
        4,
        4,
        2
      ]
    },
    {
      "id": "E491B04D",
      "weeklyDisrupted": [
        1,
        1,
        0,
        0,
        2,
        2,
        0,
        1,
        4,
        2,
        4,
        1,
        4,
        4,
        4,
        0,
        0,
        0,
        4,
        3,
        3,
        1,
        4,
        0,
        2,
        1,
        0,
        2,
        4,
        4,
        4,
        3,
        0,
        2,
        4,
        4,
        4,
        0,
        3,
        0,
        3,
        3,
        0,
        0,
        4,
        0,
        3,
        2,
        4,
        3,
        2,
        4
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        3,
        2,
        1,
        2,
        0,
        4,
        4,
        0,
        1,
        3,
        1,
        0,
        4,
        0,
        0,
        0,
        3,
        4,
        0,
        2,
        0,
        0,
        3,
        0,
        0,
        4,
        0,
        1,
        4,
        4,
        4,
        0,
        3,
        3,
        4,
        3,
        4,
        4,
        2,
        3,
        4,
        4,
        1,
        1,
        4,
        4,
        0,
        2,
        1,
        1,
        2,
        2
      ]
    },
    {
      "id": "19A12B20",
      "weeklyDisrupted": [
        0,
        2,
        4,
        0,
        3,
        4,
        0,
        4,
        2,
        0,
        4,
        2,
        0,
        4,
        3,
        4,
        4,
        2,
        0,
        0,
        0,
        4,
        2,
        0,
        3,
        2,
        2,
        0,
        2,
        0,
        0,
        2,
        2,
        1,
        2,
        0,
        4,
        0,
        1,
        1,
        1,
        2,
        0,
        4,
        1,
        0,
        2,
        3,
        2,
        4,
        4,
        3
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        4,
        2,
        1,
        0,
        1,
        0,
        4,
        0,
        4,
        2,
        1,
        2,
        4,
        4,
        1,
        2,
        2,
        3,
        2,
        3,
        1,
        2,
        0,
        1,
        1,
        4,
        4,
        1,
        4,
        4,
        3,
        3,
        3,
        0,
        2,
        0,
        4,
        3,
        0,
        0,
        3,
        0,
        4,
        0,
        0,
        4,
        0,
        4
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        0,
        2,
        2,
        4,
        4,
        1,
        2,
        1,
        2,
        4,
        3,
        2,
        3,
        4,
        2,
        4,
        2,
        4,
        2,
        4,
        3,
        1,
        2,
        0,
        1,
        3,
        4,
        2,
        4,
        2,
        2,
        4,
        1,
        3,
        4,
        3,
        4,
        0,
        2,
        1,
        2,
        4,
        0,
        4,
        2,
        4,
        0,
        2,
        0,
        1,
        4,
        2
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        4,
        0,
        4,
        2,
        2,
        0,
        1,
        0,
        2,
        4,
        4,
        2,
        4,
        3,
        1,
        1,
        4,
        4,
        3,
        3,
        2,
        0,
        4,
        2,
        0,
        3,
        0,
        0,
        3,
        2,
        4,
        2,
        0,
        1,
        0,
        3,
        2,
        4,
        4,
        2,
        4,
        4,
        1,
        0,
        2,
        4,
        4,
        2,
        0,
        1,
        4,
        2
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        4,
        0,
        0,
        3,
        0,
        4,
        4,
        1,
        0,
        0,
        4,
        3,
        0,
        0,
        1,
        1,
        3,
        3,
        0,
        3,
        0,
        0,
        1,
        4,
        0,
        1,
        1,
        1,
        4,
        3,
        2,
        1,
        4,
        1,
        4,
        3,
        2,
        4,
        0,
        0,
        2,
        3,
        0,
        2,
        3,
        4,
        4,
        0,
        2,
        2,
        3,
        4
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        4,
        0,
        0,
        1,
        0,
        4,
        2,
        2,
        4,
        0,
        2,
        1,
        3,
        2,
        0,
        2,
        4,
        2,
        2,
        0,
        0,
        2,
        3,
        0,
        2,
        2,
        1,
        0,
        3,
        0,
        1,
        3,
        0,
        0,
        1,
        4,
        0,
        4,
        0,
        2,
        4,
        4,
        1,
        4,
        4,
        2,
        0,
        1,
        2,
        1,
        2,
        4
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyDisrupted": [
        4,
        3,
        1,
        3,
        4,
        3,
        2,
        4,
        1,
        1,
        3,
        1,
        0,
        0,
        4,
        4,
        0,
        4,
        0,
        2,
        4,
        4,
        3,
        1,
        1,
        0,
        1,
        0,
        1,
        0,
        3,
        2,
        3,
        3,
        0,
        2,
        4,
        0,
        0,
        1,
        3,
        1,
        0,
        2,
        3,
        4,
        0,
        0,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        4,
        2,
        3,
        4,
        0,
        2,
        3,
        3,
        0,
        1,
        3,
        2,
        4,
        0,
        3,
        0,
        2,
        0,
        1,
        4,
        4,
        1,
        4,
        1,
        0,
        1,
        1,
        0,
        4,
        4,
        4,
        2,
        2,
        0,
        3,
        3,
        4,
        3,
        2,
        0,
        1,
        4,
        0,
        4,
        3,
        4,
        0,
        3,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyDisrupted": [
        4,
        4,
        1,
        1,
        3,
        3,
        0,
        4,
        4,
        3,
        4,
        4,
        4,
        3,
        4,
        4,
        3,
        1,
        2,
        1,
        1,
        3,
        3,
        4,
        0,
        3,
        1,
        1,
        1,
        0,
        0,
        2,
        2,
        3,
        3,
        2,
        4,
        4,
        0,
        0,
        3,
        4,
        0,
        4,
        1,
        4,
        0,
        0,
        3,
        4,
        1,
        4
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        0,
        2,
        0,
        0,
        2,
        0,
        2,
        3,
        4,
        1,
        4,
        2,
        3,
        2,
        3,
        4,
        1,
        4,
        3,
        2,
        0,
        3,
        0,
        0,
        1,
        2,
        0,
        2,
        0,
        0,
        2,
        2,
        1,
        0,
        2,
        0,
        4,
        4,
        0,
        0,
        3,
        2,
        4,
        4,
        2,
        3,
        4,
        3,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        0,
        1,
        0,
        3,
        0,
        4,
        0,
        1,
        1,
        1,
        4,
        0,
        2,
        0,
        4,
        3,
        3,
        0,
        2,
        1,
        1,
        0,
        2,
        0,
        0,
        0,
        1,
        2,
        0,
        2,
        2,
        2,
        4,
        0,
        1,
        1,
        0,
        4,
        3,
        4,
        4,
        3,
        3,
        4,
        4,
        2,
        4,
        0,
        1,
        4,
        2,
        0
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        2,
        1,
        3,
        3,
        2,
        2,
        3,
        2,
        1,
        2,
        0,
        0,
        3,
        4,
        4,
        3,
        3,
        0,
        2,
        3,
        1,
        4,
        4,
        2,
        3,
        3,
        0,
        2,
        3,
        0,
        3,
        4,
        4,
        0,
        0,
        4,
        3,
        4,
        4,
        0,
        0,
        2,
        3,
        4,
        0,
        0,
        2,
        4,
        4,
        2,
        2,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        3,
        4,
        0,
        0,
        1,
        4,
        0,
        0,
        4,
        4,
        3,
        4,
        4,
        0,
        1,
        1,
        2,
        3,
        1,
        3,
        4,
        4,
        3,
        1,
        1,
        0,
        3,
        0,
        3,
        2,
        0,
        1,
        1,
        0,
        0,
        3,
        4,
        0,
        0,
        0,
        4,
        2,
        1,
        1,
        0,
        4,
        2,
        1,
        1,
        2,
        4,
        2
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyDisrupted": [
        3,
        0,
        0,
        4,
        0,
        2,
        0,
        2,
        0,
        3,
        1,
        4,
        1,
        4,
        4,
        0,
        4,
        0,
        3,
        0,
        2,
        3,
        2,
        0,
        1,
        2,
        0,
        0,
        0,
        0,
        1,
        4,
        4,
        1,
        0,
        2,
        2,
        4,
        2,
        0,
        0,
        3,
        4,
        1,
        0,
        1,
        0,
        2,
        4,
        0,
        4,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        4,
        2,
        0,
        4,
        2,
        1,
        3,
        0,
        4,
        1,
        4,
        4,
        1,
        2,
        2,
        3,
        3,
        0,
        3,
        4,
        4,
        0,
        1,
        1,
        0,
        1,
        0,
        0,
        4,
        2,
        4,
        4,
        3,
        1,
        0,
        2,
        4,
        0,
        3,
        0,
        4,
        4,
        0,
        4,
        0,
        4,
        1,
        0,
        2,
        0,
        2,
        4
      ]
    },
    {
      "id": "D9345860",
      "weeklyDisrupted": [
        0,
        3,
        1,
        2,
        0,
        2,
        1,
        2,
        4,
        1,
        3,
        1,
        3,
        0,
        0,
        4,
        2,
        1,
        0,
        1,
        3,
        1,
        3,
        0,
        1,
        4,
        3,
        0,
        4,
        4,
        4,
        2,
        0,
        0,
        0,
        4,
        3,
        0,
        2,
        2,
        4,
        2,
        4,
        4,
        0,
        4,
        0,
        2,
        2,
        1,
        2,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        2,
        2,
        0,
        4,
        2,
        2,
        0,
        4,
        4,
        4,
        4,
        4,
        2,
        2,
        0,
        2,
        2,
        4,
        0,
        0,
        0,
        4,
        3,
        0,
        2,
        0,
        1,
        1,
        3,
        0,
        2,
        3,
        0,
        2,
        1,
        3,
        3,
        3,
        0,
        2,
        4,
        1,
        0,
        4,
        2,
        3,
        1,
        1,
        4,
        1,
        3,
        1
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        2,
        4,
        4,
        4,
        1,
        1,
        0,
        2,
        1,
        0,
        0,
        1,
        3,
        3,
        4,
        1,
        3,
        2,
        0,
        4,
        3,
        4,
        4,
        0,
        0,
        2,
        4,
        1,
        1,
        4,
        2,
        3,
        1,
        0,
        1,
        4,
        4,
        0,
        3,
        4,
        3,
        4,
        1,
        2,
        4,
        4,
        0,
        3,
        2,
        1,
        4,
        3
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        0,
        3,
        3,
        4,
        1,
        3,
        0,
        4,
        4,
        0,
        4,
        1,
        2,
        0,
        4,
        2,
        2,
        2,
        0,
        2,
        4,
        1,
        3,
        0,
        0,
        3,
        2,
        1,
        0,
        1,
        0,
        3,
        0,
        0,
        3,
        3,
        4,
        1,
        3,
        0,
        3,
        4,
        1,
        4,
        4,
        1,
        1,
        1,
        2,
        4,
        4,
        4
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyDisrupted": [
        3,
        1,
        0,
        0,
        0,
        3,
        0,
        1,
        2,
        4,
        4,
        1,
        2,
        4,
        2,
        4,
        3,
        0,
        0,
        0,
        0,
        4,
        3,
        1,
        0,
        0,
        0,
        4,
        0,
        4,
        2,
        4,
        0,
        2,
        2,
        0,
        4,
        0,
        0,
        0,
        4,
        1,
        1,
        4,
        4,
        0,
        3,
        1,
        0,
        2,
        2,
        0
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        1,
        0,
        3,
        3,
        0,
        1,
        0,
        3,
        1,
        1,
        2,
        0,
        3,
        2,
        3,
        4,
        2,
        4,
        3,
        2,
        1,
        1,
        4,
        0,
        0,
        0,
        0,
        1,
        4,
        4,
        0,
        2,
        1,
        1,
        0,
        3,
        4,
        0,
        4,
        1,
        0,
        1,
        2,
        4,
        0,
        2,
        1,
        0,
        1,
        0,
        3,
        2
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        3,
        0,
        2,
        4,
        4,
        4,
        4,
        1,
        0,
        2,
        2,
        4,
        4,
        3,
        0,
        3,
        4,
        0,
        2,
        3,
        3,
        1,
        0,
        0,
        0,
        2,
        3,
        2,
        4,
        4,
        0,
        3,
        4,
        1,
        1,
        3,
        4,
        4,
        0,
        0,
        2,
        4,
        2,
        3,
        0,
        0,
        0,
        2,
        3,
        3,
        4,
        2
      ]
    },
    {
      "id": "048701CE",
      "weeklyDisrupted": [
        0,
        4,
        1,
        4,
        0,
        1,
        0,
        2,
        1,
        3,
        0,
        1,
        2,
        4,
        4,
        4,
        0,
        4,
        0,
        2,
        3,
        4,
        0,
        2,
        0,
        2,
        0,
        0,
        3,
        4,
        0,
        2,
        0,
        0,
        0,
        4,
        4,
        0,
        2,
        3,
        2,
        2,
        2,
        0,
        0,
        1,
        2,
        0,
        4,
        4,
        3,
        4
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        3,
        4,
        3,
        4,
        0,
        3,
        0,
        2,
        2,
        0,
        3,
        4,
        4,
        0,
        2,
        3,
        2,
        0,
        3,
        2,
        0,
        0,
        1,
        2,
        1,
        1,
        4,
        0,
        0,
        2,
        4,
        4,
        2,
        0,
        4,
        4,
        4,
        4,
        0,
        3,
        3,
        4,
        0,
        4,
        1,
        0,
        0,
        2,
        3,
        1,
        0,
        2
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        0,
        1,
        1,
        3,
        4,
        3,
        4,
        1,
        0,
        4,
        0,
        4,
        4,
        4,
        2,
        1,
        2,
        1,
        4,
        0,
        4,
        1,
        1,
        4,
        1,
        4,
        1,
        0,
        1,
        1,
        3,
        4,
        4,
        3,
        2,
        3,
        4,
        4,
        0,
        0,
        1,
        2,
        4,
        4,
        3,
        4,
        4,
        1,
        4,
        0,
        3,
        0
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        0,
        1,
        3,
        1,
        0,
        2,
        0,
        3,
        4,
        1,
        3,
        3,
        3,
        4,
        4,
        2,
        2,
        0,
        2,
        4,
        2,
        0,
        4,
        2,
        3,
        0,
        4,
        2,
        3,
        1,
        0,
        3,
        0,
        2,
        2,
        4,
        3,
        2,
        2,
        0,
        1,
        2,
        1,
        4,
        3,
        4,
        3,
        0,
        4,
        2,
        0,
        1
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        4,
        0,
        3,
        3,
        1,
        3,
        3,
        2,
        4,
        3,
        1,
        0,
        4,
        3,
        4,
        0,
        3,
        0,
        2,
        2,
        1,
        3,
        4,
        0,
        0,
        0,
        2,
        0,
        1,
        0,
        3,
        3,
        0,
        0,
        0,
        4,
        1,
        4,
        2,
        1,
        0,
        4,
        1,
        2,
        2,
        2,
        4,
        0,
        4,
        4,
        4,
        0
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        2,
        4,
        2,
        2,
        0,
        4,
        0,
        4,
        4,
        2,
        4,
        4,
        1,
        4,
        4,
        1,
        2,
        0,
        0,
        3,
        1,
        4,
        3,
        2,
        0,
        2,
        2,
        0,
        4,
        4,
        4,
        2,
        0,
        3,
        2,
        3,
        4,
        4,
        4,
        2,
        1,
        1,
        2,
        1,
        1,
        3,
        3,
        2,
        4,
        2,
        0,
        4
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        4,
        0,
        2,
        1,
        0,
        3,
        2,
        2,
        1,
        2,
        2,
        0,
        2,
        3,
        4,
        0,
        3,
        0,
        2,
        3,
        4,
        3,
        4,
        1,
        0,
        0,
        3,
        2,
        2,
        4,
        0,
        4,
        2,
        3,
        1,
        3,
        4,
        4,
        0,
        1,
        3,
        3,
        0,
        1,
        3,
        1,
        0,
        0,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "067037B3",
      "weeklyDisrupted": [
        4,
        2,
        0,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        1,
        0,
        4,
        1,
        1,
        4,
        0,
        0,
        1,
        2,
        2,
        0,
        2,
        0,
        0,
        0,
        1,
        0,
        3,
        4,
        0,
        2,
        2,
        0,
        3,
        4,
        4,
        0,
        0,
        0,
        4,
        3,
        0,
        3,
        0,
        1,
        0,
        4,
        0,
        1,
        4,
        2
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        3,
        4,
        2,
        1,
        3,
        1,
        0,
        3,
        1,
        3,
        4,
        4,
        4,
        4,
        3,
        3,
        3,
        0,
        2,
        2,
        3,
        3,
        4,
        2,
        1,
        4,
        1,
        1,
        0,
        4,
        3,
        2,
        0,
        0,
        1,
        2,
        4,
        2,
        0,
        1,
        1,
        3,
        2,
        4,
        2,
        2,
        1,
        3,
        2,
        4,
        3,
        1
      ]
    },
    {
      "id": "E491B04D",
      "weeklyDisrupted": [
        3,
        0,
        0,
        1,
        3,
        3,
        0,
        4,
        3,
        4,
        2,
        1,
        2,
        2,
        4,
        4,
        4,
        0,
        3,
        0,
        0,
        2,
        3,
        2,
        0,
        1,
        0,
        3,
        2,
        1,
        4,
        2,
        1,
        3,
        1,
        4,
        4,
        0,
        3,
        0,
        3,
        1,
        0,
        0,
        1,
        0,
        1,
        0,
        2,
        0,
        3,
        0
      ]
    },
    {
      "id": "049BCC88",
      "weeklyDisrupted": [
        1,
        4,
        2,
        3,
        0,
        2,
        1,
        4,
        1,
        0,
        0,
        1,
        0,
        0,
        1,
        4,
        4,
        2,
        0,
        2,
        0,
        0,
        4,
        1,
        1,
        1,
        3,
        1,
        4,
        1,
        1,
        2,
        0,
        0,
        2,
        4,
        4,
        0,
        3,
        4,
        0,
        2,
        0,
        4,
        0,
        0,
        1,
        3,
        3,
        3,
        0,
        1
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        2,
        2,
        3,
        0,
        4,
        4,
        1,
        1,
        4,
        3,
        3,
        2,
        2,
        2,
        3,
        4,
        1,
        0,
        1,
        2,
        2,
        4,
        0,
        0,
        1,
        3,
        0,
        0,
        2,
        2,
        0,
        2,
        1,
        4,
        0,
        3,
        4,
        3,
        0,
        1,
        3,
        4,
        1,
        3,
        0,
        3,
        0,
        1,
        3,
        0,
        3,
        1
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        0,
        4,
        2,
        4,
        2,
        1,
        2,
        2,
        4,
        0,
        1,
        4,
        2,
        0,
        1,
        4,
        3,
        0,
        3,
        4,
        4,
        4,
        2,
        1,
        3,
        4,
        1,
        0,
        2,
        1,
        2,
        2,
        0,
        1,
        0,
        2,
        1,
        4,
        2,
        2,
        3,
        4,
        0,
        0,
        4,
        2,
        2,
        0,
        4,
        0,
        2,
        2
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        0,
        1,
        4,
        3,
        2,
        2,
        2,
        2,
        0,
        2,
        0,
        4,
        2,
        0,
        4,
        0,
        2,
        3,
        0,
        3,
        4,
        0,
        4,
        0,
        0,
        3,
        1,
        0,
        2,
        3,
        0,
        3,
        0,
        0,
        4,
        1,
        0,
        1,
        0,
        2,
        0,
        2,
        0,
        3,
        3,
        2,
        3,
        1,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        4,
        3,
        3,
        0,
        1,
        3,
        3,
        4,
        4,
        2,
        4,
        0,
        2,
        0,
        3,
        3,
        4,
        4,
        0,
        2,
        0,
        4,
        2,
        3,
        0,
        0,
        0,
        0,
        0,
        1,
        3,
        2,
        2,
        0,
        1,
        2,
        4,
        4,
        1,
        1,
        4,
        2,
        0,
        4,
        1,
        0,
        0,
        0,
        0,
        4,
        0,
        4
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        3,
        0,
        2,
        1,
        0,
        4,
        1,
        2,
        3,
        2,
        4,
        2,
        0,
        1,
        2,
        0,
        1,
        0,
        4,
        0,
        0,
        4,
        0,
        4,
        0,
        3,
        1,
        0,
        2,
        1,
        2,
        4,
        4,
        0,
        3,
        0,
        4,
        4,
        0,
        2,
        3,
        1,
        1,
        0,
        4,
        2,
        1,
        0,
        2,
        2,
        3,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        2,
        4,
        0,
        0,
        3,
        4,
        1,
        3,
        4,
        0,
        3,
        4,
        4,
        0,
        2,
        2,
        1,
        0,
        2,
        3,
        0,
        0,
        0,
        4,
        0,
        3,
        3,
        1,
        4,
        4,
        0,
        2,
        0,
        2,
        4,
        4,
        3,
        3,
        1,
        1,
        2,
        1,
        4,
        3,
        3,
        1,
        2,
        1,
        2,
        4,
        2,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        3,
        3,
        2,
        2,
        0,
        3,
        2,
        3,
        4,
        4,
        2,
        3,
        3,
        2,
        0,
        2,
        1,
        2,
        0,
        2,
        3,
        4,
        4,
        1,
        0,
        1,
        2,
        0,
        0,
        2,
        0,
        4,
        3,
        0,
        1,
        3,
        1,
        3,
        0,
        4,
        4,
        4,
        2,
        3,
        0,
        1,
        2,
        2,
        1,
        2,
        2,
        4
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        4,
        2,
        4,
        3,
        2,
        0,
        3,
        2,
        1,
        4,
        0,
        4,
        0,
        4,
        1,
        3,
        2,
        0,
        2,
        4,
        3,
        3,
        3,
        1,
        0,
        2,
        0,
        2,
        0,
        2,
        0,
        4,
        3,
        1,
        3,
        0,
        3,
        3,
        3,
        0,
        0,
        4,
        0,
        4,
        0,
        0,
        0,
        4,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyDisrupted": [
        2,
        0,
        2,
        1,
        1,
        4,
        0,
        0,
        1,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        4,
        1,
        3,
        2,
        0,
        3,
        1,
        1,
        0,
        0,
        2,
        1,
        1,
        3,
        0,
        4,
        4,
        0,
        4,
        0,
        4,
        4,
        0,
        0,
        2,
        4,
        0,
        1,
        3,
        1,
        0,
        1,
        4,
        1,
        2,
        2
      ]
    },
    {
      "id": "048701CE",
      "weeklyDisrupted": [
        4,
        4,
        0,
        2,
        1,
        1,
        1,
        0,
        2,
        4,
        4,
        1,
        4,
        1,
        3,
        3,
        3,
        2,
        2,
        3,
        1,
        0,
        2,
        0,
        0,
        2,
        4,
        3,
        4,
        3,
        4,
        4,
        1,
        4,
        1,
        4,
        1,
        4,
        2,
        0,
        0,
        4,
        2,
        4,
        0,
        1,
        1,
        4,
        2,
        4,
        1,
        0
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        3,
        3,
        4,
        4,
        2,
        3,
        4,
        1,
        1,
        1,
        4,
        0,
        4,
        0,
        1,
        0,
        3,
        1,
        0,
        0,
        3,
        0,
        4,
        1,
        0,
        3,
        0,
        1,
        1,
        1,
        2,
        3,
        4,
        4,
        0,
        4,
        3,
        4,
        1,
        0,
        1,
        2,
        1,
        4,
        0,
        2,
        4,
        1,
        3,
        4,
        2,
        2
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        2,
        2,
        1,
        4,
        0,
        0,
        0,
        1,
        2,
        0,
        3,
        4,
        4,
        2,
        1,
        2,
        0,
        0,
        0,
        2,
        2,
        1,
        4,
        2,
        1,
        3,
        4,
        0,
        3,
        0,
        3,
        4,
        0,
        2,
        2,
        2,
        3,
        2,
        3,
        1,
        2,
        4,
        0,
        4,
        1,
        2,
        0,
        2,
        2,
        1,
        2,
        4
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        0,
        0,
        1,
        4,
        1,
        3,
        2,
        2,
        3,
        1,
        4,
        0,
        4,
        0,
        4,
        1,
        1,
        0,
        2,
        4,
        4,
        2,
        4,
        0,
        0,
        4,
        0,
        1,
        0,
        3,
        4,
        4,
        2,
        0,
        1,
        4,
        4,
        4,
        0,
        0,
        0,
        4,
        1,
        4,
        0,
        4,
        1,
        2,
        2,
        2,
        3,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        4,
        1,
        2,
        0,
        1,
        3,
        0,
        1,
        0,
        0,
        0,
        3,
        4,
        3,
        2,
        3,
        2,
        2,
        0,
        3,
        0,
        0,
        2,
        4,
        0,
        4,
        4,
        0,
        2,
        3,
        4,
        1,
        0,
        3,
        1,
        4,
        4,
        3,
        1,
        0,
        3,
        3,
        0,
        4,
        0,
        2,
        4,
        0,
        2,
        4,
        3,
        1
      ]
    },
    {
      "id": "048701CE",
      "weeklyDisrupted": [
        3,
        1,
        3,
        4,
        1,
        0,
        0,
        0,
        1,
        1,
        3,
        4,
        2,
        2,
        1,
        1,
        1,
        0,
        4,
        4,
        1,
        0,
        2,
        2,
        0,
        0,
        1,
        3,
        3,
        4,
        1,
        4,
        4,
        0,
        4,
        1,
        4,
        1,
        0,
        0,
        3,
        4,
        2,
        4,
        0,
        3,
        1,
        0,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        3,
        1,
        0,
        3,
        4,
        3,
        2,
        0,
        3,
        2,
        0,
        4,
        0,
        3,
        2,
        0,
        3,
        1,
        2,
        2,
        3,
        2,
        2,
        0,
        0,
        2,
        2,
        1,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        2,
        3,
        4,
        1,
        2,
        3,
        3,
        2,
        2,
        3,
        1,
        0,
        0,
        2,
        0,
        2,
        2
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyDisrupted": [
        2,
        4,
        4,
        3,
        2,
        0,
        0,
        3,
        4,
        1,
        2,
        4,
        4,
        0,
        4,
        0,
        1,
        0,
        0,
        2,
        2,
        2,
        3,
        2,
        0,
        2,
        1,
        0,
        3,
        0,
        0,
        3,
        3,
        0,
        0,
        4,
        4,
        0,
        0,
        1,
        0,
        2,
        2,
        4,
        2,
        4,
        0,
        3,
        4,
        3,
        4,
        4
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        4,
        4,
        3,
        0,
        0,
        4,
        1,
        0,
        2,
        2,
        2,
        1,
        0,
        0,
        2,
        0,
        2,
        4,
        0,
        3,
        4,
        4,
        0,
        0,
        0,
        0,
        1,
        1,
        3,
        1,
        0,
        4,
        3,
        0,
        1,
        4,
        3,
        2,
        0,
        1,
        3,
        0,
        1,
        2,
        2,
        1,
        0,
        3,
        2,
        2,
        2,
        0
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        0,
        1,
        0,
        4,
        1,
        3,
        1,
        4,
        2,
        0,
        3,
        3,
        3,
        0,
        4,
        0,
        4,
        0,
        4,
        4,
        0,
        4,
        4,
        0,
        1,
        3,
        3,
        0,
        4,
        4,
        2,
        3,
        0,
        3,
        1,
        2,
        4,
        0,
        0,
        0,
        0,
        2,
        3,
        4,
        2,
        3,
        2,
        0,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        4,
        3,
        2,
        1,
        4,
        1,
        0,
        0,
        0,
        4,
        4,
        4,
        1,
        3,
        0,
        4,
        4,
        3,
        2,
        1,
        0,
        1,
        1,
        1,
        1,
        2,
        1,
        0,
        2,
        4,
        4,
        2,
        4,
        1,
        3,
        0,
        4,
        0,
        0,
        0,
        4,
        3,
        0,
        4,
        2,
        0,
        2,
        1,
        4,
        3,
        2,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        4,
        1,
        4,
        0,
        3,
        3,
        2,
        2,
        2,
        2,
        3,
        2,
        1,
        3,
        4,
        0,
        0,
        0,
        1,
        4,
        3,
        0,
        2,
        1,
        2,
        0,
        2,
        3,
        1,
        2,
        4,
        0,
        0,
        0,
        3,
        2,
        4,
        0,
        4,
        4,
        2,
        0,
        0,
        4,
        4,
        2,
        0,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        4,
        1,
        4,
        1,
        0,
        3,
        0,
        1,
        2,
        0,
        0,
        1,
        3,
        1,
        4,
        3,
        2,
        2,
        3,
        4,
        1,
        4,
        4,
        3,
        1,
        2,
        0,
        0,
        1,
        4,
        3,
        1,
        0,
        2,
        3,
        3,
        4,
        0,
        4,
        0,
        4,
        1,
        2,
        2,
        0,
        3,
        0,
        2,
        4,
        4,
        0,
        0
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyDisrupted": [
        2,
        0,
        3,
        3,
        0,
        2,
        4,
        4,
        0,
        2,
        4,
        2,
        0,
        4,
        3,
        3,
        0,
        0,
        0,
        2,
        3,
        4,
        2,
        0,
        1,
        1,
        1,
        1,
        3,
        2,
        1,
        4,
        0,
        3,
        2,
        2,
        3,
        4,
        0,
        2,
        1,
        3,
        2,
        3,
        2,
        1,
        4,
        0,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        2,
        2,
        2,
        1,
        1,
        1,
        4,
        2,
        3,
        3,
        3,
        3,
        3,
        0,
        2,
        0,
        2,
        0,
        2,
        0,
        0,
        3,
        3,
        0,
        0,
        2,
        0,
        1,
        1,
        4,
        4,
        3,
        0,
        2,
        3,
        3,
        1,
        2,
        4,
        1,
        1,
        2,
        3,
        3,
        4,
        4,
        1,
        1,
        1,
        1,
        3,
        4
      ]
    },
    {
      "id": "067037B3",
      "weeklyDisrupted": [
        3,
        0,
        4,
        0,
        4,
        3,
        0,
        2,
        3,
        0,
        1,
        4,
        4,
        0,
        4,
        1,
        4,
        0,
        0,
        0,
        2,
        1,
        3,
        4,
        2,
        4,
        2,
        0,
        0,
        0,
        1,
        2,
        1,
        0,
        4,
        0,
        4,
        1,
        2,
        1,
        0,
        0,
        2,
        2,
        0,
        3,
        2,
        4,
        4,
        2,
        3,
        4
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        4,
        2,
        4,
        2,
        0,
        4,
        0,
        0,
        0,
        1,
        0,
        3,
        2,
        0,
        4,
        2,
        3,
        0,
        0,
        4,
        0,
        2,
        3,
        1,
        0,
        2,
        1,
        0,
        4,
        3,
        3,
        4,
        0,
        0,
        3,
        3,
        3,
        0,
        1,
        0,
        4,
        1,
        0,
        4,
        0,
        2,
        1,
        4,
        4,
        4,
        4,
        3
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        2,
        4,
        1,
        3,
        4,
        4,
        2,
        0,
        4,
        1,
        4,
        0,
        4,
        4,
        3,
        3,
        0,
        0,
        1,
        0,
        1,
        2,
        2,
        1,
        2,
        4,
        1,
        1,
        2,
        3,
        4,
        3,
        1,
        0,
        1,
        2,
        4,
        4,
        4,
        3,
        3,
        3,
        4,
        3,
        0,
        4,
        0,
        0,
        3,
        1,
        0,
        0
      ]
    },
    {
      "id": "049BCC88",
      "weeklyDisrupted": [
        4,
        0,
        1,
        3,
        0,
        0,
        0,
        0,
        3,
        3,
        4,
        4,
        3,
        3,
        1,
        2,
        0,
        0,
        3,
        1,
        3,
        2,
        4,
        4,
        3,
        4,
        1,
        1,
        2,
        0,
        3,
        4,
        3,
        1,
        3,
        4,
        4,
        4,
        3,
        4,
        1,
        2,
        1,
        1,
        2,
        4,
        0,
        2,
        4,
        1,
        2,
        3
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        2,
        0,
        1,
        2,
        4,
        3,
        0,
        0,
        2,
        3,
        3,
        3,
        3,
        2,
        2,
        0,
        4,
        0,
        1,
        1,
        3,
        1,
        3,
        3,
        0,
        4,
        0,
        2,
        3,
        4,
        0,
        2,
        2,
        2,
        1,
        2,
        3,
        4,
        0,
        3,
        4,
        2,
        4,
        4,
        3,
        4,
        2,
        1,
        3,
        4,
        0,
        4
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        4,
        3,
        1,
        4,
        2,
        4,
        0,
        3,
        0,
        0,
        4,
        1,
        2,
        1,
        2,
        4,
        2,
        0,
        3,
        0,
        3,
        3,
        4,
        2,
        0,
        3,
        0,
        0,
        4,
        2,
        3,
        3,
        2,
        0,
        0,
        4,
        0,
        3,
        4,
        0,
        3,
        1,
        2,
        2,
        0,
        4,
        0,
        0,
        4,
        0,
        1,
        0
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        2,
        0,
        2,
        0,
        2,
        4,
        4,
        2,
        4,
        4,
        1,
        1,
        3,
        2,
        4,
        0,
        0,
        0,
        2,
        1,
        0,
        3,
        0,
        1,
        1,
        1,
        2,
        0,
        4,
        2,
        3,
        2,
        0,
        2,
        3,
        1,
        0,
        2,
        3,
        4,
        1,
        4,
        0,
        3,
        2,
        2,
        0,
        2,
        2,
        1,
        2,
        4
      ]
    },
    {
      "id": "14968630",
      "weeklyDisrupted": [
        0,
        2,
        0,
        4,
        4,
        2,
        0,
        3,
        4,
        4,
        3,
        4,
        3,
        4,
        1,
        3,
        4,
        0,
        1,
        4,
        2,
        1,
        3,
        2,
        1,
        4,
        1,
        0,
        4,
        4,
        3,
        1,
        0,
        1,
        2,
        4,
        3,
        0,
        0,
        0,
        4,
        3,
        4,
        2,
        0,
        1,
        4,
        3,
        4,
        3,
        4,
        1
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyDisrupted": [
        4,
        3,
        0,
        0,
        2,
        2,
        0,
        1,
        4,
        3,
        4,
        3,
        3,
        2,
        4,
        0,
        0,
        0,
        2,
        3,
        0,
        2,
        4,
        1,
        0,
        2,
        2,
        3,
        3,
        4,
        4,
        4,
        2,
        2,
        4,
        4,
        4,
        2,
        1,
        0,
        3,
        0,
        4,
        2,
        0,
        3,
        0,
        4,
        2,
        1,
        4,
        4
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        3,
        4,
        1,
        3,
        1,
        0,
        0,
        0,
        0,
        0,
        2,
        4,
        3,
        4,
        1,
        0,
        1,
        1,
        0,
        1,
        3,
        4,
        2,
        2,
        0,
        3,
        2,
        1,
        1,
        3,
        2,
        4,
        4,
        0,
        0,
        0,
        3,
        4,
        2,
        1,
        1,
        4,
        0,
        2,
        2,
        4,
        2,
        1,
        2,
        0,
        0,
        3
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        4,
        0,
        1,
        4,
        1,
        0,
        2,
        4,
        2,
        3,
        1,
        3,
        1,
        4,
        0,
        0,
        4,
        4,
        2,
        0,
        2,
        2,
        0,
        1,
        0,
        0,
        1,
        0,
        4,
        4,
        1,
        1,
        0,
        1,
        3,
        4,
        4,
        3,
        4,
        2,
        1,
        4,
        4,
        1,
        3,
        3,
        0,
        0,
        3,
        2,
        1,
        4
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        4,
        4,
        2,
        4,
        0,
        2,
        0,
        0,
        3,
        3,
        3,
        0,
        3,
        4,
        2,
        4,
        0,
        0,
        3,
        3,
        0,
        3,
        4,
        4,
        0,
        4,
        3,
        0,
        0,
        0,
        0,
        4,
        4,
        3,
        4,
        4,
        4,
        4,
        1,
        0,
        4,
        2,
        1,
        2,
        1,
        4,
        1,
        0,
        4,
        1,
        0,
        0
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        2,
        3,
        3,
        4,
        1,
        4,
        2,
        4,
        2,
        2,
        2,
        1,
        0,
        3,
        4,
        4,
        2,
        0,
        1,
        1,
        1,
        2,
        4,
        1,
        0,
        4,
        0,
        1,
        4,
        2,
        1,
        0,
        2,
        3,
        2,
        3,
        4,
        4,
        1,
        3,
        1,
        0,
        2,
        3,
        2,
        0,
        0,
        0,
        4,
        4,
        0,
        0
      ]
    },
    {
      "id": "067037B3",
      "weeklyDisrupted": [
        3,
        0,
        1,
        0,
        3,
        2,
        2,
        4,
        1,
        4,
        3,
        4,
        2,
        4,
        2,
        4,
        3,
        2,
        2,
        0,
        0,
        3,
        2,
        0,
        1,
        2,
        0,
        1,
        3,
        2,
        2,
        3,
        2,
        1,
        4,
        0,
        4,
        4,
        0,
        1,
        1,
        1,
        2,
        4,
        0,
        1,
        2,
        2,
        4,
        3,
        4,
        2
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        1,
        4,
        4,
        0,
        1,
        4,
        0,
        3,
        0,
        2,
        2,
        4,
        1,
        4,
        2,
        4,
        4,
        4,
        0,
        3,
        1,
        4,
        0,
        0,
        0,
        0,
        2,
        1,
        0,
        1,
        0,
        4,
        4,
        4,
        2,
        4,
        3,
        4,
        2,
        3,
        3,
        2,
        1,
        4,
        4,
        2,
        2,
        1,
        0,
        0,
        4,
        1
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        1,
        3,
        4,
        1,
        0,
        1,
        2,
        4,
        1,
        2,
        2,
        4,
        0,
        4,
        2,
        3,
        2,
        2,
        1,
        1,
        3,
        2,
        0,
        0,
        0,
        1,
        0,
        4,
        3,
        0,
        0,
        4,
        0,
        0,
        3,
        1,
        0,
        4,
        2,
        0,
        2,
        2,
        4,
        3,
        0,
        4,
        1,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        1,
        0,
        4,
        3,
        1,
        4,
        1,
        3,
        0,
        2,
        2,
        1,
        4,
        2,
        3,
        3,
        2,
        1,
        3,
        4,
        1,
        4,
        4,
        2,
        0,
        1,
        0,
        0,
        4,
        3,
        3,
        4,
        4,
        1,
        3,
        3,
        4,
        4,
        2,
        0,
        3,
        2,
        0,
        0,
        0,
        2,
        0,
        3,
        4,
        4,
        4,
        4
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        1,
        1,
        3,
        4,
        2,
        2,
        1,
        3,
        4,
        3,
        0,
        1,
        4,
        0,
        4,
        0,
        2,
        0,
        2,
        3,
        0,
        3,
        4,
        4,
        0,
        4,
        4,
        1,
        1,
        3,
        2,
        4,
        4,
        2,
        3,
        2,
        3,
        3,
        1,
        1,
        4,
        4,
        4,
        2,
        2,
        3,
        0,
        2,
        2,
        4,
        2,
        2
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        0,
        1,
        0,
        3,
        0,
        3,
        3,
        2,
        0,
        0,
        0,
        0,
        2,
        1,
        2,
        0,
        2,
        0,
        2,
        2,
        2,
        4,
        4,
        1,
        2,
        2,
        1,
        0,
        0,
        0,
        3,
        0,
        0,
        2,
        2,
        2,
        4,
        3,
        0,
        0,
        4,
        3,
        4,
        4,
        0,
        2,
        2,
        2,
        2,
        0,
        1,
        4
      ]
    },
    {
      "id": "067037B3",
      "weeklyDisrupted": [
        4,
        3,
        0,
        0,
        0,
        4,
        0,
        4,
        1,
        2,
        0,
        4,
        3,
        2,
        4,
        4,
        2,
        0,
        4,
        3,
        3,
        0,
        0,
        2,
        4,
        3,
        1,
        0,
        1,
        4,
        4,
        3,
        0,
        4,
        3,
        1,
        3,
        0,
        3,
        1,
        4,
        0,
        0,
        3,
        0,
        1,
        0,
        0,
        0,
        1,
        0,
        4
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        3,
        4,
        2,
        4,
        0,
        1,
        1,
        4,
        4,
        0,
        3,
        0,
        0,
        1,
        0,
        4,
        0,
        0,
        0,
        3,
        1,
        2,
        4,
        1,
        1,
        2,
        2,
        3,
        2,
        3,
        4,
        1,
        0,
        0,
        4,
        3,
        1,
        3,
        2,
        0,
        0,
        1,
        1,
        0,
        1,
        2,
        1,
        3,
        0,
        0,
        1,
        1
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        4,
        0,
        1,
        3,
        0,
        3,
        0,
        4,
        2,
        2,
        4,
        4,
        4,
        4,
        3,
        0,
        3,
        0,
        3,
        1,
        0,
        2,
        3,
        0,
        1,
        4,
        0,
        1,
        2,
        4,
        3,
        3,
        4,
        4,
        1,
        3,
        4,
        0,
        2,
        0,
        3,
        4,
        4,
        0,
        2,
        1,
        4,
        2,
        2,
        0,
        4,
        2
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyDisrupted": [
        1,
        1,
        1,
        2,
        1,
        0,
        1,
        0,
        4,
        2,
        3,
        0,
        0,
        1,
        0,
        2,
        2,
        3,
        2,
        1,
        1,
        4,
        4,
        1,
        0,
        0,
        1,
        2,
        1,
        1,
        1,
        4,
        1,
        1,
        2,
        4,
        3,
        4,
        0,
        1,
        4,
        4,
        3,
        4,
        0,
        3,
        2,
        1,
        4,
        1,
        4,
        1
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyDisrupted": [
        4,
        4,
        0,
        2,
        0,
        3,
        0,
        1,
        3,
        4,
        4,
        1,
        1,
        2,
        1,
        3,
        4,
        2,
        2,
        0,
        4,
        0,
        4,
        0,
        2,
        1,
        1,
        4,
        3,
        3,
        0,
        4,
        0,
        0,
        0,
        2,
        3,
        0,
        0,
        0,
        4,
        3,
        0,
        3,
        1,
        0,
        1,
        3,
        4,
        0,
        0,
        4
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        0,
        4,
        0,
        2,
        2,
        4,
        0,
        1,
        4,
        3,
        4,
        4,
        2,
        3,
        3,
        2,
        4,
        0,
        0,
        3,
        4,
        3,
        3,
        2,
        0,
        0,
        2,
        4,
        4,
        2,
        0,
        3,
        3,
        0,
        1,
        2,
        4,
        4,
        0,
        2,
        2,
        4,
        0,
        4,
        1,
        3,
        0,
        1,
        3,
        0,
        4,
        4
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        3,
        3,
        2,
        3,
        2,
        4,
        3,
        0,
        1,
        0,
        1,
        1,
        4,
        2,
        1,
        2,
        3,
        0,
        2,
        4,
        2,
        2,
        4,
        2,
        0,
        1,
        0,
        0,
        0,
        4,
        0,
        2,
        3,
        0,
        3,
        3,
        3,
        0,
        0,
        0,
        2,
        4,
        2,
        0,
        3,
        4,
        0,
        4,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        1,
        3,
        4,
        0,
        2,
        4,
        1,
        0,
        3,
        2,
        4,
        0,
        0,
        4,
        3,
        4,
        3,
        0,
        4,
        0,
        4,
        4,
        2,
        1,
        0,
        2,
        0,
        4,
        2,
        0,
        4,
        0,
        1,
        1,
        0,
        4,
        2,
        4,
        4,
        0,
        1,
        0,
        2,
        4,
        0,
        0,
        1,
        3,
        1,
        4,
        3
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        2,
        1,
        0,
        4,
        3,
        3,
        2,
        3,
        4,
        0,
        2,
        0,
        4,
        3,
        4,
        4,
        0,
        2,
        2,
        0,
        2,
        1,
        3,
        0,
        0,
        0,
        0,
        1,
        0,
        2,
        0,
        3,
        0,
        0,
        3,
        3,
        4,
        0,
        3,
        0,
        4,
        2,
        0,
        3,
        0,
        0,
        0,
        4,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        3,
        4,
        3,
        2,
        1,
        3,
        4,
        2,
        3,
        2,
        4,
        4,
        4,
        4,
        3,
        4,
        2,
        1,
        3,
        1,
        3,
        1,
        4,
        4,
        0,
        3,
        0,
        1,
        0,
        0,
        4,
        2,
        0,
        0,
        2,
        3,
        3,
        0,
        2,
        0,
        4,
        4,
        1,
        3,
        4,
        2,
        0,
        0,
        4,
        0,
        4,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        0,
        0,
        2,
        4,
        4,
        3,
        2,
        2,
        4,
        1,
        4,
        2,
        4,
        2,
        2,
        3,
        3,
        1,
        2,
        0,
        0,
        1,
        4,
        4,
        0,
        4,
        3,
        1,
        0,
        2,
        4,
        4,
        1,
        0,
        4,
        4,
        4,
        4,
        0,
        4,
        4,
        1,
        1,
        3,
        2,
        1,
        2,
        2,
        2,
        0,
        2,
        0
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        2,
        1,
        2,
        1,
        1,
        0,
        0,
        4,
        1,
        2,
        4,
        4,
        4,
        2,
        4,
        4,
        3,
        0,
        3,
        0,
        4,
        0,
        0,
        3,
        0,
        2,
        1,
        3,
        3,
        4,
        0,
        1,
        4,
        0,
        1,
        2,
        4,
        4,
        0,
        0,
        4,
        2,
        1,
        0,
        2,
        1,
        0,
        4,
        4,
        1,
        0,
        4
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        2,
        4,
        3,
        3,
        0,
        0,
        0,
        0,
        2,
        3,
        4,
        0,
        1,
        0,
        4,
        3,
        0,
        4,
        0,
        4,
        2,
        1,
        0,
        0,
        0,
        0,
        3,
        0,
        4,
        4,
        1,
        4,
        0,
        3,
        4,
        3,
        0,
        1,
        0,
        0,
        4,
        0,
        0,
        0,
        0,
        3,
        1,
        2,
        1,
        2,
        0,
        4
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        3,
        0,
        4,
        4,
        0,
        3,
        0,
        3,
        4,
        4,
        0,
        1,
        4,
        0,
        2,
        4,
        0,
        0,
        0,
        3,
        4,
        2,
        1,
        1,
        0,
        3,
        3,
        4,
        0,
        1,
        4,
        4,
        4,
        2,
        4,
        4,
        2,
        4,
        0,
        0,
        3,
        4,
        1,
        4,
        3,
        2,
        0,
        2,
        4,
        1,
        3,
        0
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        1,
        1,
        1,
        1,
        4,
        4,
        4,
        4,
        3,
        2,
        3,
        2,
        3,
        4,
        2,
        2,
        4,
        0,
        4,
        1,
        0,
        3,
        1,
        1,
        1,
        2,
        0,
        2,
        3,
        0,
        0,
        3,
        1,
        3,
        3,
        1,
        3,
        4,
        0,
        0,
        2,
        4,
        2,
        2,
        0,
        0,
        0,
        4,
        0,
        2,
        4,
        3
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        2,
        0,
        1,
        0,
        2,
        2,
        4,
        0,
        4,
        0,
        1,
        4,
        3,
        4,
        2,
        0,
        2,
        0,
        0,
        1,
        4,
        3,
        1,
        0,
        0,
        4,
        2,
        4,
        4,
        4,
        3,
        2,
        4,
        3,
        1,
        3,
        3,
        4,
        0,
        0,
        1,
        4,
        4,
        2,
        4,
        3,
        2,
        3,
        0,
        0,
        4,
        0
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        3,
        4,
        0,
        4,
        1,
        2,
        0,
        3,
        0,
        0,
        4,
        0,
        1,
        1,
        3,
        4,
        3,
        2,
        2,
        0,
        1,
        0,
        4,
        1,
        0,
        2,
        1,
        0,
        0,
        2,
        3,
        3,
        1,
        2,
        4,
        4,
        2,
        0,
        2,
        2,
        4,
        4,
        0,
        4,
        3,
        0,
        0,
        0,
        3,
        2,
        0,
        0
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyDisrupted": [
        4,
        0,
        0,
        3,
        0,
        4,
        2,
        0,
        1,
        1,
        3,
        2,
        4,
        2,
        3,
        2,
        4,
        0,
        1,
        0,
        2,
        1,
        3,
        4,
        0,
        4,
        2,
        0,
        0,
        2,
        4,
        2,
        3,
        1,
        0,
        4,
        3,
        4,
        4,
        2,
        2,
        4,
        2,
        4,
        4,
        2,
        2,
        2,
        4,
        2,
        2,
        1
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        1,
        3,
        1,
        1,
        4,
        2,
        0,
        4,
        4,
        3,
        4,
        4,
        2,
        2,
        0,
        2,
        2,
        1,
        0,
        1,
        4,
        4,
        4,
        4,
        1,
        4,
        2,
        0,
        1,
        0,
        3,
        2,
        2,
        4,
        2,
        2,
        3,
        0,
        2,
        2,
        3,
        3,
        3,
        1,
        4,
        4,
        0,
        1,
        0,
        2,
        3,
        4
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        3,
        4,
        4,
        2,
        0,
        2,
        0,
        3,
        4,
        4,
        2,
        2,
        2,
        1,
        1,
        2,
        0,
        4,
        3,
        4,
        3,
        4,
        0,
        0,
        0,
        0,
        3,
        0,
        3,
        2,
        0,
        4,
        3,
        2,
        0,
        3,
        2,
        0,
        1,
        0,
        4,
        3,
        1,
        0,
        0,
        4,
        0,
        0,
        3,
        1,
        4,
        3
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        3,
        2,
        2,
        4,
        1,
        0,
        4,
        4,
        3,
        4,
        0,
        4,
        4,
        4,
        3,
        2,
        2,
        3,
        3,
        4,
        4,
        1,
        3,
        1,
        0,
        3,
        0,
        2,
        3,
        3,
        3,
        4,
        2,
        0,
        4,
        4,
        4,
        0,
        2,
        3,
        1,
        4,
        1,
        0,
        0,
        2,
        3,
        4,
        2,
        3,
        4,
        4
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        4,
        3,
        2,
        2,
        4,
        4,
        3,
        4,
        4,
        4,
        2,
        4,
        3,
        0,
        2,
        0,
        4,
        1,
        2,
        0,
        4,
        3,
        1,
        3,
        0,
        2,
        0,
        1,
        0,
        1,
        0,
        2,
        2,
        0,
        3,
        1,
        2,
        4,
        0,
        0,
        1,
        3,
        1,
        2,
        0,
        2,
        2,
        1,
        1,
        0,
        4,
        0
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        3,
        3,
        0,
        4,
        3,
        3,
        0,
        4,
        1,
        4,
        2,
        4,
        4,
        4,
        2,
        4,
        3,
        1,
        0,
        2,
        2,
        1,
        1,
        2,
        0,
        1,
        1,
        1,
        3,
        1,
        1,
        0,
        4,
        1,
        2,
        2,
        4,
        4,
        0,
        1,
        3,
        0,
        4,
        3,
        0,
        1,
        2,
        3,
        4,
        1,
        4,
        0
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        1,
        0,
        0,
        1,
        1,
        3,
        4,
        0,
        0,
        0,
        4,
        2,
        2,
        4,
        2,
        0,
        3,
        4,
        1,
        1,
        3,
        0,
        2,
        2,
        0,
        3,
        2,
        2,
        1,
        2,
        1,
        2,
        3,
        0,
        3,
        1,
        4,
        3,
        0,
        3,
        0,
        2,
        2,
        2,
        0,
        3,
        1,
        0,
        3,
        1,
        4,
        4
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        4,
        0,
        1,
        0,
        0,
        4,
        4,
        1,
        4,
        0,
        4,
        0,
        4,
        2,
        2,
        4,
        4,
        3,
        0,
        0,
        0,
        3,
        0,
        1,
        3,
        4,
        0,
        0,
        3,
        3,
        1,
        0,
        1,
        4,
        2,
        3,
        1,
        4,
        1,
        1,
        4,
        0,
        0,
        4,
        4,
        0,
        0,
        2,
        0,
        2,
        0,
        0
      ]
    },
    {
      "id": "D9345860",
      "weeklyDisrupted": [
        1,
        1,
        0,
        1,
        1,
        0,
        0,
        4,
        3,
        0,
        4,
        4,
        4,
        4,
        4,
        4,
        3,
        0,
        4,
        0,
        2,
        0,
        0,
        0,
        2,
        2,
        0,
        1,
        1,
        0,
        0,
        2,
        0,
        0,
        1,
        0,
        4,
        1,
        2,
        1,
        3,
        2,
        4,
        3,
        1,
        1,
        2,
        0,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        1,
        2,
        4,
        1,
        1,
        3,
        0,
        0,
        2,
        2,
        0,
        4,
        4,
        2,
        4,
        1,
        4,
        0,
        3,
        3,
        1,
        3,
        4,
        3,
        0,
        0,
        2,
        0,
        3,
        4,
        0,
        2,
        2,
        0,
        0,
        3,
        4,
        0,
        0,
        0,
        1,
        4,
        0,
        4,
        2,
        3,
        0,
        1,
        3,
        1,
        4,
        0
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        0,
        2,
        4,
        0,
        1,
        4,
        3,
        4,
        0,
        1,
        4,
        1,
        4,
        4,
        3,
        0,
        0,
        0,
        0,
        2,
        4,
        4,
        1,
        3,
        0,
        2,
        0,
        0,
        3,
        4,
        0,
        3,
        0,
        2,
        1,
        2,
        4,
        4,
        0,
        3,
        3,
        3,
        1,
        2,
        2,
        3,
        0,
        3,
        3,
        0,
        4,
        0
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyDisrupted": [
        4,
        3,
        1,
        4,
        0,
        3,
        0,
        0,
        1,
        4,
        4,
        1,
        4,
        2,
        2,
        1,
        2,
        0,
        0,
        3,
        1,
        2,
        2,
        1,
        0,
        4,
        0,
        1,
        4,
        4,
        3,
        3,
        4,
        0,
        0,
        4,
        4,
        0,
        0,
        0,
        3,
        2,
        1,
        3,
        1,
        4,
        0,
        0,
        4,
        4,
        3,
        2
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        1,
        3,
        3,
        1,
        1,
        4,
        1,
        0,
        4,
        2,
        4,
        4,
        2,
        4,
        2,
        0,
        2,
        1,
        0,
        1,
        1,
        4,
        2,
        0,
        0,
        2,
        0,
        1,
        0,
        1,
        2,
        2,
        3,
        2,
        1,
        0,
        2,
        4,
        2,
        3,
        3,
        1,
        3,
        3,
        3,
        4,
        4,
        1,
        4,
        0,
        4,
        1
      ]
    },
    {
      "id": "049BCC88",
      "weeklyDisrupted": [
        4,
        2,
        2,
        0,
        1,
        0,
        1,
        0,
        4,
        4,
        4,
        3,
        2,
        4,
        2,
        4,
        0,
        1,
        2,
        2,
        2,
        2,
        1,
        2,
        0,
        3,
        1,
        1,
        3,
        2,
        4,
        4,
        2,
        2,
        4,
        4,
        4,
        0,
        3,
        2,
        3,
        2,
        0,
        0,
        0,
        3,
        3,
        2,
        3,
        0,
        1,
        1
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        4,
        1,
        2,
        2,
        4,
        2,
        0,
        4,
        2,
        3,
        4,
        0,
        3,
        2,
        3,
        2,
        4,
        2,
        0,
        0,
        3,
        0,
        4,
        0,
        0,
        3,
        0,
        2,
        0,
        3,
        0,
        1,
        4,
        3,
        4,
        3,
        4,
        0,
        0,
        0,
        4,
        4,
        1,
        1,
        4,
        4,
        0,
        4,
        3,
        2,
        3,
        2
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        2,
        2,
        3,
        0,
        4,
        2,
        1,
        3,
        3,
        4,
        3,
        3,
        3,
        2,
        0,
        4,
        3,
        0,
        3,
        2,
        0,
        4,
        4,
        2,
        0,
        3,
        2,
        1,
        0,
        0,
        2,
        3,
        0,
        0,
        3,
        2,
        4,
        3,
        3,
        0,
        2,
        2,
        4,
        4,
        1,
        3,
        0,
        0,
        2,
        1,
        4,
        2
      ]
    },
    {
      "id": "067037B3",
      "weeklyDisrupted": [
        3,
        4,
        1,
        0,
        1,
        3,
        1,
        3,
        4,
        4,
        4,
        0,
        1,
        4,
        4,
        4,
        4,
        4,
        1,
        4,
        3,
        0,
        4,
        0,
        0,
        3,
        0,
        1,
        4,
        1,
        3,
        2,
        0,
        1,
        4,
        2,
        4,
        0,
        3,
        4,
        2,
        3,
        0,
        3,
        4,
        0,
        0,
        0,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        4,
        2,
        1,
        2,
        0,
        4,
        1,
        4,
        0,
        3,
        4,
        0,
        4,
        4,
        0,
        3,
        2,
        0,
        1,
        2,
        0,
        4,
        3,
        4,
        0,
        2,
        2,
        0,
        3,
        3,
        0,
        4,
        3,
        4,
        3,
        4,
        2,
        0,
        0,
        0,
        4,
        3,
        0,
        4,
        0,
        1,
        0,
        0,
        4,
        2,
        1,
        0
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        3,
        2,
        4,
        3,
        0,
        3,
        3,
        0,
        0,
        4,
        4,
        4,
        0,
        4,
        1,
        2,
        4,
        3,
        2,
        1,
        2,
        1,
        3,
        0,
        1,
        4,
        1,
        2,
        3,
        2,
        1,
        1,
        2,
        2,
        1,
        3,
        2,
        0,
        0,
        1,
        2,
        4,
        2,
        4,
        0,
        1,
        0,
        2,
        1,
        1,
        3,
        4
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        4,
        0,
        0,
        2,
        2,
        3,
        1,
        1,
        2,
        4,
        3,
        1,
        4,
        2,
        4,
        2,
        4,
        0,
        1,
        3,
        4,
        0,
        4,
        0,
        0,
        2,
        1,
        4,
        2,
        4,
        2,
        4,
        2,
        4,
        4,
        3,
        4,
        4,
        1,
        1,
        4,
        4,
        3,
        0,
        0,
        2,
        0,
        3,
        2,
        1,
        3,
        1
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        4,
        4,
        4,
        4,
        0,
        0,
        1,
        2,
        2,
        0,
        1,
        1,
        3,
        3,
        3,
        4,
        4,
        0,
        1,
        3,
        0,
        4,
        4,
        0,
        3,
        4,
        3,
        0,
        4,
        3,
        4,
        4,
        0,
        0,
        2,
        4,
        2,
        4,
        4,
        1,
        4,
        1,
        0,
        0,
        2,
        1,
        4,
        0,
        0,
        3,
        2,
        2
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        0,
        3,
        3,
        3,
        2,
        2,
        2,
        3,
        1,
        4,
        0,
        0,
        2,
        1,
        1,
        4,
        3,
        0,
        1,
        0,
        3,
        2,
        0,
        1,
        0,
        2,
        2,
        0,
        0,
        1,
        4,
        0,
        0,
        4,
        2,
        1,
        4,
        2,
        0,
        3,
        4,
        3,
        0,
        0,
        0,
        4,
        4,
        4,
        3,
        0,
        4,
        4
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        4,
        4,
        0,
        4,
        4,
        4,
        1,
        0,
        4,
        4,
        2,
        3,
        0,
        2,
        4,
        0,
        1,
        1,
        0,
        0,
        4,
        3,
        1,
        2,
        0,
        2,
        3,
        0,
        4,
        2,
        0,
        4,
        0,
        0,
        1,
        2,
        4,
        0,
        2,
        0,
        2,
        2,
        0,
        0,
        0,
        2,
        2,
        0,
        4,
        1,
        3,
        4
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        1,
        1,
        0,
        3,
        0,
        4,
        1,
        1,
        4,
        2,
        1,
        1,
        2,
        0,
        2,
        3,
        1,
        1,
        0,
        4,
        1,
        0,
        2,
        1,
        0,
        4,
        4,
        2,
        0,
        3,
        0,
        2,
        2,
        0,
        1,
        3,
        4,
        4,
        4,
        0,
        3,
        3,
        0,
        0,
        0,
        3,
        2,
        0,
        4,
        2,
        3,
        2
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        2,
        2,
        2,
        4,
        0,
        1,
        4,
        0,
        4,
        1,
        2,
        2,
        3,
        2,
        1,
        0,
        0,
        3,
        0,
        0,
        3,
        3,
        4,
        3,
        0,
        2,
        0,
        1,
        0,
        1,
        3,
        0,
        0,
        0,
        2,
        4,
        3,
        4,
        0,
        0,
        3,
        1,
        2,
        2,
        0,
        0,
        0,
        0,
        1,
        0,
        3,
        4
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        2,
        3,
        0,
        3,
        0,
        4,
        4,
        0,
        0,
        3,
        4,
        0,
        2,
        1,
        4,
        4,
        3,
        0,
        0,
        3,
        1,
        4,
        4,
        3,
        0,
        3,
        0,
        0,
        0,
        2,
        4,
        3,
        4,
        1,
        4,
        1,
        4,
        4,
        4,
        1,
        0,
        1,
        1,
        4,
        4,
        2,
        1,
        1,
        4,
        4,
        4,
        3
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        3,
        1,
        3,
        0,
        3,
        4,
        1,
        2,
        4,
        3,
        2,
        1,
        0,
        0,
        4,
        4,
        4,
        0,
        3,
        0,
        2,
        1,
        0,
        0,
        4,
        3,
        0,
        4,
        4,
        2,
        4,
        2,
        1,
        2,
        2,
        1,
        0,
        0,
        0,
        0,
        1,
        2,
        4,
        0,
        4,
        1,
        0,
        4,
        3,
        1,
        0
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        1,
        4,
        0,
        4,
        2,
        3,
        2,
        4,
        4,
        4,
        4,
        4,
        1,
        3,
        4,
        0,
        4,
        3,
        0,
        4,
        0,
        2,
        2,
        3,
        0,
        1,
        0,
        3,
        0,
        1,
        4,
        4,
        3,
        0,
        0,
        4,
        4,
        4,
        1,
        0,
        2,
        3,
        2,
        3,
        1,
        1,
        1,
        0,
        2,
        0,
        4,
        0
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        3,
        0,
        4,
        4,
        1,
        2,
        0,
        3,
        4,
        0,
        3,
        4,
        3,
        1,
        0,
        2,
        3,
        0,
        0,
        2,
        3,
        0,
        2,
        0,
        0,
        4,
        4,
        0,
        0,
        3,
        0,
        2,
        2,
        2,
        4,
        2,
        3,
        0,
        0,
        0,
        4,
        1,
        1,
        4,
        3,
        1,
        2,
        2,
        1,
        1,
        3,
        2
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        2,
        2,
        3,
        1,
        1,
        2,
        2,
        2,
        3,
        2,
        0,
        2,
        4,
        0,
        3,
        2,
        4,
        3,
        0,
        2,
        4,
        4,
        4,
        2,
        0,
        3,
        0,
        2,
        1,
        0,
        3,
        4,
        0,
        0,
        4,
        2,
        2,
        3,
        2,
        0,
        3,
        3,
        0,
        1,
        4,
        3,
        0,
        0,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        4,
        3,
        1,
        1,
        2,
        0,
        4,
        4,
        4,
        4,
        1,
        4,
        2,
        3,
        2,
        2,
        1,
        0,
        4,
        4,
        2,
        4,
        0,
        2,
        4,
        0,
        0,
        0,
        2,
        2,
        4,
        0,
        0,
        1,
        0,
        0,
        0,
        4,
        2,
        4,
        1,
        3,
        3,
        4,
        2,
        3,
        4,
        3,
        4,
        3,
        1,
        1
      ]
    },
    {
      "id": "958C4CED",
      "weeklyDisrupted": [
        0,
        3,
        2,
        3,
        0,
        3,
        3,
        2,
        4,
        2,
        2,
        4,
        4,
        2,
        2,
        3,
        4,
        0,
        1,
        0,
        4,
        2,
        0,
        1,
        1,
        4,
        0,
        1,
        4,
        1,
        0,
        2,
        4,
        0,
        1,
        3,
        4,
        4,
        2,
        1,
        3,
        4,
        1,
        4,
        4,
        4,
        0,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        4,
        1,
        0,
        3,
        0,
        4,
        2,
        4,
        1,
        0,
        2,
        3,
        1,
        1,
        2,
        2,
        4,
        0,
        1,
        2,
        1,
        3,
        4,
        1,
        0,
        4,
        1,
        1,
        3,
        4,
        3,
        4,
        3,
        1,
        3,
        4,
        3,
        4,
        4,
        1,
        2,
        1,
        2,
        2,
        1,
        3,
        2,
        2,
        0,
        3,
        1,
        0
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        0,
        0,
        0,
        4,
        0,
        3,
        0,
        4,
        3,
        0,
        2,
        4,
        3,
        4,
        3,
        4,
        2,
        2,
        0,
        2,
        4,
        1,
        4,
        2,
        1,
        3,
        2,
        0,
        1,
        2,
        4,
        2,
        0,
        3,
        0,
        3,
        3,
        4,
        0,
        1,
        1,
        3,
        1,
        3,
        0,
        0,
        0,
        2,
        2,
        4,
        1,
        4
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        2,
        0,
        2,
        3,
        2,
        1,
        2,
        2,
        1,
        1,
        4,
        1,
        1,
        4,
        2,
        3,
        3,
        0,
        0,
        0,
        4,
        4,
        3,
        0,
        1,
        0,
        2,
        0,
        2,
        4,
        2,
        3,
        0,
        4,
        0,
        0,
        1,
        0,
        0,
        1,
        1,
        1,
        2,
        2,
        2,
        4,
        2,
        1,
        3,
        1,
        0,
        4
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        2,
        0,
        1,
        3,
        0,
        3,
        4,
        4,
        3,
        2,
        3,
        0,
        4,
        4,
        2,
        3,
        4,
        1,
        3,
        2,
        1,
        3,
        4,
        0,
        3,
        2,
        0,
        1,
        2,
        3,
        3,
        2,
        0,
        2,
        1,
        2,
        4,
        4,
        0,
        1,
        4,
        4,
        1,
        3,
        1,
        0,
        4,
        1,
        4,
        3,
        0,
        0
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        0,
        1,
        3,
        4,
        1,
        3,
        0,
        2,
        3,
        0,
        3,
        4,
        4,
        3,
        4,
        0,
        4,
        1,
        3,
        3,
        1,
        2,
        3,
        3,
        0,
        4,
        1,
        4,
        1,
        0,
        1,
        3,
        3,
        0,
        2,
        3,
        4,
        4,
        1,
        2,
        3,
        0,
        2,
        0,
        2,
        2,
        0,
        0,
        4,
        0,
        2,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        4,
        2,
        4,
        4,
        4,
        0,
        0,
        0,
        4,
        3,
        2,
        2,
        3,
        4,
        3,
        4,
        4,
        2,
        4,
        0,
        3,
        1,
        3,
        1,
        0,
        2,
        0,
        0,
        2,
        1,
        4,
        0,
        0,
        0,
        0,
        4,
        3,
        4,
        3,
        0,
        4,
        4,
        4,
        0,
        1,
        4,
        0,
        3,
        2,
        0,
        4,
        3
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        3,
        4,
        1,
        4,
        4,
        4,
        2,
        3,
        2,
        4,
        4,
        4,
        0,
        4,
        3,
        3,
        4,
        2,
        2,
        3,
        0,
        1,
        4,
        0,
        0,
        4,
        2,
        1,
        4,
        4,
        4,
        2,
        3,
        2,
        0,
        2,
        3,
        0,
        1,
        1,
        4,
        4,
        3,
        4,
        4,
        2,
        1,
        0,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        0,
        0,
        0,
        4,
        0,
        3,
        0,
        2,
        4,
        1,
        1,
        2,
        1,
        0,
        2,
        2,
        1,
        2,
        0,
        3,
        3,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        2,
        2,
        3,
        1,
        1,
        0,
        3,
        3,
        4,
        1,
        0,
        2,
        3,
        2,
        3,
        0,
        4,
        1,
        1,
        4,
        4,
        3,
        2
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        0,
        0,
        4,
        3,
        2,
        0,
        2,
        3,
        0,
        4,
        1,
        3,
        0,
        2,
        1,
        4,
        0,
        0,
        1,
        0,
        4,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        1,
        2,
        4,
        3,
        4,
        4,
        2,
        4,
        4,
        3,
        0,
        0,
        2,
        0,
        1,
        0,
        1,
        3,
        0,
        4,
        2,
        2,
        1
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        0,
        1,
        3,
        4,
        3,
        0,
        0,
        4,
        4,
        0,
        2,
        4,
        2,
        3,
        3,
        1,
        4,
        0,
        3,
        4,
        2,
        4,
        4,
        2,
        1,
        2,
        0,
        0,
        2,
        3,
        2,
        2,
        1,
        0,
        0,
        2,
        4,
        4,
        0,
        3,
        1,
        4,
        1,
        4,
        0,
        3,
        0,
        0,
        2,
        0,
        2,
        0
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        4,
        2,
        3,
        2,
        1,
        0,
        1,
        2,
        4,
        3,
        4,
        3,
        3,
        4,
        0,
        4,
        4,
        1,
        3,
        4,
        4,
        4,
        1,
        0,
        0,
        4,
        0,
        2,
        4,
        4,
        3,
        2,
        4,
        1,
        2,
        2,
        3,
        2,
        0,
        2,
        2,
        3,
        1,
        4,
        0,
        3,
        4,
        0,
        2,
        1,
        3,
        4
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        2,
        3,
        4,
        3,
        2,
        4,
        3,
        3,
        1,
        3,
        3,
        4,
        3,
        0,
        1,
        4,
        4,
        0,
        1,
        4,
        4,
        4,
        3,
        1,
        3,
        3,
        3,
        2,
        1,
        2,
        2,
        3,
        3,
        2,
        1,
        3,
        2,
        1,
        4,
        0,
        0,
        4,
        0,
        4,
        3,
        4,
        0,
        2,
        4,
        4,
        4,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        3,
        1,
        2,
        3,
        1,
        3,
        0,
        4,
        1,
        1,
        3,
        2,
        4,
        4,
        4,
        0,
        4,
        1,
        2,
        4,
        1,
        2,
        3,
        4,
        3,
        0,
        0,
        0,
        4,
        0,
        4,
        3,
        4,
        2,
        1,
        2,
        3,
        0,
        3,
        2,
        4,
        2,
        0,
        2,
        1,
        2,
        0,
        0,
        4,
        4,
        1,
        0
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        1,
        1,
        0,
        3,
        0,
        3,
        0,
        3,
        4,
        0,
        3,
        2,
        4,
        4,
        1,
        2,
        0,
        1,
        2,
        4,
        4,
        0,
        0,
        1,
        0,
        0,
        4,
        1,
        4,
        4,
        1,
        4,
        2,
        2,
        2,
        2,
        4,
        3,
        0,
        1,
        4,
        4,
        0,
        4,
        0,
        2,
        0,
        3,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        4,
        2,
        0,
        3,
        0,
        1,
        0,
        3,
        1,
        0,
        4,
        1,
        4,
        4,
        3,
        4,
        0,
        0,
        4,
        0,
        2,
        1,
        3,
        0,
        0,
        2,
        1,
        0,
        0,
        3,
        0,
        2,
        2,
        1,
        1,
        4,
        4,
        4,
        1,
        0,
        3,
        1,
        0,
        4,
        4,
        2,
        2,
        0,
        2,
        1,
        1,
        4
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        4,
        1,
        0,
        4,
        0,
        3,
        2,
        3,
        0,
        2,
        4,
        4,
        3,
        3,
        0,
        4,
        2,
        0,
        2,
        3,
        4,
        1,
        3,
        2,
        0,
        1,
        1,
        0,
        4,
        3,
        0,
        3,
        0,
        2,
        2,
        4,
        4,
        0,
        2,
        1,
        4,
        4,
        2,
        1,
        0,
        3,
        0,
        3,
        3,
        4,
        2,
        3
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        1,
        0,
        4,
        1,
        0,
        3,
        0,
        2,
        2,
        1,
        2,
        4,
        2,
        1,
        3,
        1,
        4,
        0,
        0,
        3,
        3,
        4,
        3,
        1,
        1,
        4,
        2,
        2,
        0,
        4,
        0,
        2,
        3,
        0,
        0,
        2,
        1,
        4,
        0,
        0,
        2,
        3,
        2,
        3,
        4,
        3,
        1,
        4,
        4,
        1,
        1,
        0
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        0,
        4,
        2,
        4,
        0,
        4,
        0,
        4,
        4,
        0,
        3,
        4,
        4,
        0,
        3,
        1,
        2,
        0,
        0,
        1,
        1,
        3,
        4,
        1,
        3,
        4,
        1,
        0,
        4,
        0,
        4,
        3,
        4,
        0,
        1,
        3,
        2,
        3,
        4,
        2,
        2,
        2,
        0,
        1,
        0,
        4,
        0,
        0,
        1,
        0,
        1,
        4
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        0,
        3,
        0,
        4,
        0,
        1,
        3,
        2,
        4,
        0,
        1,
        0,
        4,
        3,
        1,
        3,
        3,
        2,
        0,
        3,
        4,
        4,
        4,
        0,
        0,
        0,
        2,
        0,
        1,
        4,
        0,
        4,
        3,
        4,
        2,
        3,
        3,
        4,
        3,
        0,
        1,
        1,
        2,
        3,
        1,
        2,
        4,
        3,
        4,
        4,
        4,
        3
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        1,
        4,
        3,
        4,
        3,
        1,
        1,
        2,
        4,
        4,
        3,
        2,
        4,
        2,
        1,
        4,
        4,
        0,
        2,
        1,
        2,
        1,
        4,
        0,
        2,
        1,
        1,
        2,
        4,
        4,
        2,
        3,
        1,
        2,
        4,
        1,
        2,
        4,
        2,
        2,
        2,
        3,
        1,
        3,
        4,
        4,
        2,
        0,
        2,
        4,
        1,
        4
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        4,
        2,
        0,
        0,
        3,
        3,
        0,
        4,
        4,
        0,
        4,
        4,
        3,
        0,
        3,
        4,
        2,
        2,
        1,
        0,
        1,
        2,
        0,
        1,
        3,
        4,
        0,
        1,
        2,
        4,
        4,
        2,
        0,
        4,
        4,
        2,
        4,
        0,
        0,
        0,
        4,
        2,
        1,
        4,
        2,
        0,
        2,
        2,
        1,
        2,
        2,
        1
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        3,
        2,
        0,
        0,
        0,
        1,
        0,
        4,
        2,
        1,
        4,
        0,
        0,
        4,
        3,
        4,
        0,
        2,
        0,
        3,
        1,
        1,
        1,
        0,
        2,
        1,
        0,
        2,
        2,
        2,
        3,
        4,
        0,
        2,
        3,
        2,
        1,
        2,
        2,
        3,
        3,
        3,
        2,
        2,
        3,
        2,
        0,
        3,
        4,
        2,
        1,
        2
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        2,
        0,
        2,
        4,
        3,
        3,
        4,
        1,
        1,
        3,
        2,
        0,
        3,
        3,
        2,
        2,
        2,
        1,
        1,
        1,
        1,
        4,
        4,
        0,
        0,
        0,
        2,
        0,
        3,
        3,
        2,
        3,
        0,
        2,
        2,
        4,
        2,
        2,
        0,
        0,
        1,
        4,
        2,
        1,
        4,
        2,
        0,
        0,
        4,
        4,
        2,
        0
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        2,
        2,
        1,
        0,
        4,
        4,
        2,
        2,
        3,
        2,
        3,
        3,
        0,
        3,
        2,
        4,
        4,
        4,
        1,
        0,
        4,
        2,
        0,
        0,
        0,
        4,
        0,
        3,
        3,
        0,
        0,
        3,
        2,
        1,
        0,
        2,
        4,
        2,
        0,
        1,
        2,
        3,
        2,
        4,
        0,
        1,
        1,
        0,
        4,
        1,
        3,
        2
      ]
    },
    {
      "id": "867038F2",
      "weeklyDisrupted": [
        4,
        3,
        2,
        0,
        0,
        4,
        0,
        4,
        4,
        0,
        2,
        3,
        4,
        0,
        1,
        3,
        3,
        1,
        1,
        4,
        1,
        0,
        1,
        1,
        4,
        1,
        1,
        4,
        0,
        0,
        3,
        4,
        0,
        1,
        0,
        2,
        3,
        4,
        4,
        2,
        3,
        4,
        0,
        4,
        3,
        4,
        4,
        4,
        4,
        1,
        0,
        1
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        0,
        4,
        1,
        2,
        0,
        0,
        0,
        3,
        4,
        4,
        0,
        3,
        4,
        3,
        4,
        4,
        3,
        1,
        2,
        2,
        4,
        3,
        4,
        0,
        3,
        0,
        1,
        0,
        2,
        0,
        0,
        4,
        0,
        1,
        0,
        4,
        4,
        0,
        0,
        4,
        4,
        2,
        0,
        0,
        3,
        3,
        0,
        2,
        3,
        1,
        1,
        2
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyDisrupted": [
        0,
        2,
        0,
        2,
        1,
        2,
        2,
        0,
        2,
        0,
        4,
        3,
        4,
        1,
        2,
        1,
        3,
        3,
        0,
        2,
        0,
        3,
        4,
        3,
        1,
        4,
        1,
        1,
        2,
        4,
        3,
        3,
        1,
        0,
        0,
        0,
        2,
        4,
        4,
        0,
        3,
        1,
        3,
        2,
        3,
        4,
        3,
        0,
        4,
        2,
        2,
        0
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        4,
        1,
        1,
        4,
        1,
        4,
        4,
        4,
        0,
        2,
        0,
        0,
        2,
        0,
        2,
        0,
        4,
        0,
        0,
        2,
        3,
        4,
        3,
        0,
        3,
        2,
        0,
        2,
        2,
        1,
        2,
        2,
        3,
        2,
        4,
        3,
        0,
        1,
        0,
        1,
        2,
        4,
        0,
        1,
        0,
        2,
        0,
        0,
        4,
        4,
        0,
        1
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        0,
        1,
        4,
        2,
        1,
        0,
        2,
        4,
        4,
        1,
        4,
        3,
        4,
        1,
        3,
        0,
        2,
        0,
        1,
        4,
        2,
        4,
        3,
        4,
        0,
        4,
        3,
        3,
        3,
        4,
        0,
        4,
        3,
        0,
        2,
        4,
        4,
        0,
        2,
        0,
        3,
        3,
        2,
        0,
        1,
        1,
        0,
        0,
        4,
        0,
        0,
        4
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        0,
        3,
        0,
        3,
        4,
        0,
        3,
        2,
        4,
        0,
        3,
        4,
        4,
        4,
        3,
        1,
        2,
        1,
        0,
        3,
        4,
        0,
        4,
        4,
        2,
        4,
        1,
        0,
        3,
        0,
        0,
        4,
        0,
        0,
        0,
        2,
        4,
        4,
        1,
        1,
        3,
        4,
        2,
        4,
        0,
        4,
        0,
        2,
        1,
        4,
        3,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        1,
        3,
        2,
        2,
        4,
        1,
        2,
        3,
        4,
        3,
        4,
        4,
        4,
        1,
        1,
        4,
        3,
        0,
        0,
        0,
        3,
        2,
        1,
        2,
        1,
        3,
        0,
        1,
        2,
        0,
        0,
        4,
        2,
        0,
        1,
        4,
        2,
        3,
        0,
        1,
        0,
        4,
        2,
        1,
        0,
        4,
        0,
        3,
        3,
        4,
        0,
        2
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        0,
        3,
        1,
        2,
        1,
        3,
        4,
        1,
        4,
        4,
        4,
        0,
        4,
        2,
        2,
        4,
        2,
        3,
        1,
        0,
        4,
        3,
        0,
        1,
        0,
        3,
        1,
        0,
        4,
        1,
        3,
        4,
        4,
        1,
        0,
        2,
        0,
        0,
        4,
        1,
        1,
        3,
        0,
        2,
        2,
        1,
        0,
        4,
        4,
        2,
        3,
        0
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        1,
        0,
        0,
        3,
        1,
        4,
        3,
        0,
        0,
        0,
        4,
        4,
        2,
        1,
        0,
        3,
        4,
        0,
        0,
        0,
        0,
        1,
        1,
        0,
        0,
        3,
        2,
        0,
        3,
        4,
        4,
        4,
        1,
        0,
        3,
        2,
        4,
        2,
        0,
        0,
        3,
        2,
        0,
        4,
        0,
        2,
        1,
        4,
        2,
        2,
        4,
        2
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        3,
        0,
        4,
        0,
        4,
        3,
        2,
        0,
        4,
        2,
        4,
        0,
        2,
        4,
        1,
        1,
        1,
        4,
        0,
        2,
        1,
        4,
        0,
        0,
        0,
        2,
        0,
        1,
        0,
        0,
        1,
        0,
        0,
        1,
        2,
        1,
        0,
        4,
        2,
        1,
        2,
        2,
        2,
        1,
        2,
        4,
        2,
        4,
        0,
        3,
        0,
        4
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyDisrupted": [
        2,
        2,
        3,
        0,
        1,
        2,
        3,
        4,
        4,
        1,
        1,
        4,
        1,
        0,
        1,
        4,
        4,
        0,
        0,
        4,
        0,
        0,
        4,
        3,
        0,
        4,
        0,
        0,
        0,
        2,
        3,
        1,
        0,
        3,
        3,
        2,
        4,
        0,
        1,
        3,
        3,
        1,
        1,
        4,
        4,
        3,
        1,
        0,
        4,
        0,
        3,
        3
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        3,
        4,
        2,
        2,
        4,
        1,
        0,
        3,
        0,
        1,
        4,
        4,
        3,
        0,
        2,
        2,
        1,
        4,
        4,
        2,
        1,
        3,
        1,
        0,
        0,
        2,
        2,
        0,
        4,
        0,
        0,
        3,
        3,
        1,
        0,
        3,
        3,
        0,
        4,
        0,
        0,
        1,
        1,
        4,
        0,
        0,
        4,
        0,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        0,
        0,
        4,
        3,
        0,
        2,
        1,
        4,
        4,
        1,
        2,
        0,
        4,
        0,
        3,
        4,
        4,
        0,
        1,
        2,
        0,
        3,
        4,
        3,
        0,
        4,
        2,
        1,
        0,
        0,
        0,
        2,
        2,
        0,
        3,
        3,
        3,
        1,
        2,
        0,
        0,
        0,
        2,
        2,
        4,
        1,
        1,
        1,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        1,
        3,
        4,
        0,
        1,
        1,
        0,
        2,
        3,
        2,
        2,
        3,
        4,
        2,
        2,
        3,
        0,
        1,
        2,
        2,
        3,
        1,
        2,
        0,
        0,
        0,
        0,
        1,
        0,
        3,
        0,
        2,
        0,
        0,
        4,
        3,
        2,
        1,
        4,
        0,
        4,
        3,
        0,
        0,
        2,
        3,
        2,
        3,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        0,
        0,
        0,
        2,
        1,
        2,
        3,
        0,
        4,
        3,
        4,
        4,
        0,
        4,
        3,
        2,
        4,
        1,
        1,
        1,
        1,
        3,
        4,
        1,
        0,
        3,
        2,
        0,
        4,
        4,
        0,
        4,
        0,
        0,
        3,
        4,
        4,
        4,
        0,
        0,
        1,
        4,
        0,
        4,
        0,
        4,
        0,
        1,
        1,
        1,
        4,
        2
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        1,
        2,
        3,
        3,
        3,
        4,
        0,
        0,
        0,
        4,
        0,
        0,
        4,
        2,
        3,
        4,
        3,
        0,
        1,
        0,
        3,
        0,
        2,
        1,
        0,
        2,
        0,
        4,
        3,
        4,
        0,
        4,
        0,
        3,
        0,
        3,
        4,
        3,
        3,
        0,
        4,
        3,
        3,
        0,
        0,
        3,
        3,
        0,
        2,
        3,
        2,
        0
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        1,
        0,
        4,
        3,
        0,
        2,
        0,
        2,
        4,
        1,
        0,
        4,
        4,
        4,
        1,
        4,
        3,
        0,
        2,
        3,
        0,
        4,
        4,
        4,
        0,
        4,
        1,
        0,
        0,
        2,
        4,
        0,
        0,
        0,
        4,
        3,
        4,
        0,
        1,
        2,
        2,
        2,
        2,
        2,
        4,
        4,
        2,
        2,
        2,
        1,
        4,
        3
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        4,
        4,
        0,
        1,
        1,
        1,
        3,
        0,
        4,
        2,
        3,
        2,
        2,
        3,
        3,
        4,
        2,
        0,
        2,
        0,
        1,
        0,
        4,
        2,
        0,
        3,
        1,
        3,
        1,
        0,
        2,
        3,
        1,
        0,
        0,
        2,
        4,
        4,
        4,
        4,
        3,
        4,
        2,
        4,
        0,
        2,
        3,
        4,
        0,
        0,
        1,
        4
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        3,
        1,
        1,
        4,
        3,
        4,
        4,
        0,
        2,
        1,
        4,
        4,
        4,
        1,
        1,
        3,
        4,
        0,
        1,
        3,
        2,
        4,
        3,
        2,
        1,
        2,
        1,
        1,
        4,
        4,
        0,
        3,
        2,
        0,
        4,
        1,
        1,
        4,
        0,
        1,
        2,
        1,
        3,
        2,
        0,
        4,
        0,
        4,
        4,
        1,
        4,
        0
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        4,
        0,
        4,
        3,
        2,
        2,
        0,
        0,
        3,
        2,
        2,
        4,
        4,
        3,
        1,
        0,
        3,
        0,
        0,
        3,
        4,
        2,
        4,
        2,
        0,
        4,
        4,
        4,
        3,
        2,
        2,
        4,
        0,
        3,
        3,
        4,
        3,
        4,
        1,
        1,
        0,
        4,
        1,
        2,
        0,
        4,
        1,
        2,
        1,
        0,
        1,
        4
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        4,
        1,
        3,
        0,
        0,
        4,
        1,
        4,
        4,
        4,
        1,
        4,
        4,
        1,
        1,
        2,
        0,
        0,
        4,
        1,
        3,
        3,
        4,
        3,
        2,
        0,
        1,
        0,
        3,
        3,
        1,
        3,
        3,
        1,
        1,
        3,
        4,
        0,
        4,
        0,
        4,
        0,
        1,
        3,
        0,
        4,
        0,
        0,
        4,
        4,
        4,
        0
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        4,
        3,
        4,
        3,
        1,
        3,
        4,
        3,
        4,
        2,
        0,
        0,
        3,
        1,
        3,
        0,
        0,
        1,
        0,
        2,
        4,
        4,
        1,
        1,
        1,
        2,
        0,
        2,
        2,
        3,
        1,
        3,
        0,
        4,
        1,
        1,
        0,
        1,
        0,
        1,
        4,
        1,
        0,
        0,
        2,
        2,
        0,
        1,
        2,
        1,
        1,
        3
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        4,
        2,
        2,
        1,
        4,
        0,
        2,
        4,
        2,
        4,
        3,
        2,
        2,
        2,
        1,
        3,
        1,
        0,
        0,
        4,
        4,
        1,
        4,
        4,
        0,
        0,
        1,
        0,
        2,
        0,
        3,
        2,
        3,
        0,
        2,
        1,
        2,
        3,
        1,
        0,
        2,
        2,
        1,
        0,
        0,
        4,
        1,
        2,
        0,
        0,
        1,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        4,
        0,
        2,
        0,
        2,
        1,
        3,
        0,
        2,
        0,
        4,
        2,
        2,
        3,
        2,
        1,
        2,
        0,
        2,
        2,
        2,
        0,
        2,
        2,
        0,
        2,
        0,
        0,
        4,
        0,
        0,
        4,
        0,
        1,
        3,
        1,
        4,
        4,
        0,
        0,
        0,
        4,
        1,
        3,
        0,
        4,
        3,
        4,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyDisrupted": [
        2,
        0,
        0,
        2,
        0,
        2,
        4,
        0,
        0,
        4,
        2,
        2,
        0,
        2,
        1,
        4,
        2,
        0,
        2,
        0,
        4,
        4,
        4,
        0,
        1,
        1,
        0,
        0,
        0,
        4,
        3,
        3,
        4,
        2,
        2,
        4,
        4,
        4,
        2,
        1,
        0,
        3,
        0,
        2,
        0,
        4,
        1,
        2,
        4,
        1,
        4,
        2
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        4,
        0,
        1,
        4,
        2,
        1,
        0,
        1,
        0,
        4,
        4,
        1,
        4,
        1,
        2,
        4,
        3,
        4,
        2,
        1,
        2,
        3,
        3,
        0,
        0,
        3,
        2,
        0,
        4,
        3,
        0,
        4,
        0,
        2,
        0,
        4,
        4,
        1,
        3,
        1,
        4,
        4,
        4,
        3,
        2,
        0,
        2,
        0,
        4,
        2,
        2,
        4
      ]
    },
    {
      "id": "E491B04D",
      "weeklyDisrupted": [
        0,
        4,
        3,
        0,
        0,
        0,
        0,
        4,
        4,
        3,
        3,
        0,
        4,
        4,
        3,
        4,
        4,
        0,
        3,
        4,
        0,
        4,
        0,
        1,
        0,
        1,
        0,
        0,
        4,
        3,
        0,
        0,
        4,
        1,
        4,
        4,
        2,
        0,
        0,
        4,
        3,
        2,
        2,
        1,
        0,
        0,
        2,
        3,
        2,
        4,
        2,
        2
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        2,
        2,
        4,
        4,
        4,
        3,
        4,
        1,
        1,
        4,
        2,
        4,
        0,
        4,
        3,
        0,
        4,
        2,
        3,
        3,
        2,
        4,
        4,
        2,
        1,
        2,
        1,
        0,
        4,
        4,
        0,
        3,
        2,
        1,
        1,
        0,
        2,
        4,
        0,
        0,
        1,
        3,
        2,
        1,
        3,
        4,
        0,
        4,
        4,
        0,
        3,
        1
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        4,
        2,
        0,
        2,
        4,
        4,
        3,
        1,
        2,
        0,
        2,
        2,
        2,
        2,
        4,
        2,
        4,
        1,
        0,
        1,
        0,
        2,
        2,
        1,
        2,
        3,
        1,
        4,
        1,
        2,
        3,
        2,
        0,
        1,
        3,
        2,
        4,
        0,
        2,
        2,
        3,
        0,
        1,
        4,
        4,
        0,
        2,
        0,
        3,
        4,
        0,
        0
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        0,
        0,
        1,
        4,
        4,
        2,
        3,
        0,
        0,
        0,
        3,
        1,
        3,
        0,
        2,
        4,
        4,
        0,
        0,
        0,
        4,
        2,
        0,
        0,
        0,
        1,
        1,
        3,
        2,
        4,
        2,
        4,
        2,
        2,
        1,
        3,
        1,
        4,
        0,
        0,
        0,
        2,
        0,
        3,
        2,
        1,
        4,
        4,
        4,
        4,
        3,
        0
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        0,
        3,
        3,
        4,
        0,
        2,
        1,
        0,
        2,
        4,
        2,
        4,
        3,
        4,
        4,
        2,
        3,
        4,
        2,
        0,
        2,
        2,
        4,
        1,
        1,
        4,
        0,
        2,
        1,
        2,
        4,
        0,
        3,
        0,
        3,
        4,
        4,
        3,
        2,
        0,
        4,
        0,
        2,
        4,
        2,
        4,
        2,
        2,
        0,
        4,
        4,
        3
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        4,
        0,
        4,
        1,
        0,
        4,
        0,
        4,
        2,
        4,
        4,
        1,
        1,
        3,
        3,
        0,
        4,
        0,
        0,
        0,
        3,
        4,
        3,
        3,
        0,
        2,
        2,
        0,
        4,
        1,
        0,
        1,
        4,
        1,
        0,
        4,
        4,
        0,
        0,
        2,
        2,
        1,
        3,
        4,
        4,
        4,
        4,
        0,
        2,
        2,
        4,
        0
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        3,
        4,
        1,
        2,
        0,
        2,
        3,
        4,
        4,
        0,
        2,
        4,
        3,
        4,
        2,
        0,
        0,
        3,
        2,
        2,
        4,
        4,
        3,
        3,
        0,
        3,
        2,
        0,
        1,
        0,
        4,
        3,
        2,
        3,
        0,
        2,
        2,
        4,
        3,
        0,
        1,
        2,
        4,
        1,
        3,
        4,
        1,
        0,
        4,
        3,
        1,
        0
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        3,
        0,
        1,
        2,
        1,
        1,
        0,
        1,
        0,
        0,
        4,
        0,
        4,
        2,
        4,
        4,
        4,
        1,
        3,
        3,
        1,
        0,
        3,
        0,
        0,
        2,
        1,
        0,
        3,
        2,
        3,
        2,
        1,
        0,
        0,
        4,
        1,
        0,
        4,
        0,
        2,
        4,
        1,
        4,
        3,
        2,
        3,
        1,
        2,
        1,
        1,
        4
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        2,
        2,
        1,
        2,
        1,
        4,
        0,
        3,
        4,
        2,
        4,
        2,
        3,
        2,
        2,
        3,
        3,
        2,
        1,
        0,
        3,
        2,
        4,
        1,
        1,
        4,
        0,
        2,
        3,
        3,
        4,
        1,
        3,
        0,
        0,
        3,
        2,
        4,
        1,
        1,
        2,
        4,
        1,
        4,
        0,
        3,
        2,
        4,
        0,
        3,
        3,
        2
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        2,
        0,
        2,
        0,
        2,
        1,
        0,
        4,
        4,
        3,
        4,
        4,
        4,
        3,
        4,
        1,
        1,
        1,
        2,
        4,
        0,
        0,
        2,
        2,
        1,
        4,
        1,
        3,
        2,
        1,
        4,
        3,
        2,
        1,
        3,
        4,
        3,
        4,
        3,
        0,
        3,
        0,
        0,
        2,
        0,
        4,
        0,
        1,
        1,
        4,
        2,
        4
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        1,
        2,
        0,
        4,
        3,
        2,
        0,
        3,
        4,
        1,
        4,
        2,
        0,
        3,
        4,
        3,
        0,
        2,
        0,
        0,
        1,
        4,
        2,
        0,
        0,
        2,
        3,
        0,
        0,
        2,
        2,
        3,
        3,
        4,
        2,
        4,
        4,
        1,
        2,
        3,
        3,
        1,
        3,
        4,
        2,
        2,
        4,
        1,
        1,
        4,
        1,
        3
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        4,
        0,
        0,
        1,
        2,
        3,
        4,
        0,
        4,
        1,
        3,
        0,
        4,
        4,
        2,
        0,
        0,
        0,
        1,
        1,
        4,
        2,
        1,
        1,
        1,
        0,
        2,
        0,
        4,
        4,
        4,
        3,
        4,
        1,
        1,
        2,
        4,
        4,
        0,
        0,
        0,
        2,
        2,
        4,
        3,
        4,
        0,
        0,
        4,
        0,
        2,
        3
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        0,
        0,
        3,
        0,
        4,
        1,
        2,
        2,
        1,
        3,
        4,
        1,
        2,
        2,
        3,
        3,
        1,
        0,
        4,
        0,
        0,
        3,
        0,
        1,
        0,
        3,
        1,
        3,
        2,
        4,
        2,
        0,
        4,
        1,
        3,
        4,
        4,
        3,
        1,
        0,
        3,
        2,
        4,
        4,
        0,
        2,
        0,
        1,
        1,
        4,
        2,
        3
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        4,
        3,
        4,
        0,
        2,
        2,
        2,
        4,
        0,
        2,
        4,
        1,
        2,
        4,
        1,
        2,
        4,
        3,
        3,
        3,
        0,
        4,
        2,
        0,
        0,
        0,
        2,
        4,
        2,
        4,
        0,
        4,
        2,
        2,
        2,
        2,
        2,
        1,
        0,
        2,
        3,
        4,
        4,
        2,
        0,
        0,
        0,
        2,
        3,
        2,
        3,
        2
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        4,
        2,
        1,
        0,
        4,
        0,
        2,
        2,
        4,
        2,
        2,
        4,
        4,
        2,
        3,
        4,
        0,
        0,
        3,
        0,
        0,
        3,
        0,
        3,
        0,
        4,
        1,
        1,
        1,
        3,
        0,
        3,
        3,
        2,
        2,
        4,
        4,
        2,
        0,
        4,
        2,
        4,
        2,
        0,
        0,
        4,
        2,
        2,
        1,
        3,
        3,
        3
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        1,
        1,
        2,
        3,
        1,
        3,
        3,
        1,
        0,
        1,
        1,
        2,
        0,
        0,
        0,
        1,
        3,
        3,
        0,
        2,
        3,
        4,
        3,
        1,
        0,
        0,
        1,
        1,
        1,
        0,
        1,
        3,
        4,
        1,
        0,
        2,
        1,
        3,
        2,
        1,
        3,
        3,
        2,
        4,
        1,
        2,
        1,
        1,
        4,
        0,
        0,
        4
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        3,
        1,
        2,
        4,
        0,
        1,
        0,
        3,
        0,
        4,
        1,
        1,
        4,
        1,
        2,
        4,
        3,
        4,
        2,
        3,
        1,
        3,
        4,
        0,
        2,
        2,
        0,
        0,
        0,
        4,
        0,
        2,
        0,
        3,
        0,
        3,
        3,
        0,
        1,
        0,
        4,
        2,
        0,
        3,
        0,
        0,
        0,
        4,
        1,
        2,
        2,
        4
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        3,
        2,
        4,
        4,
        0,
        3,
        0,
        1,
        3,
        0,
        0,
        2,
        3,
        4,
        2,
        0,
        2,
        0,
        4,
        1,
        0,
        2,
        3,
        2,
        0,
        0,
        1,
        1,
        4,
        1,
        0,
        4,
        1,
        2,
        1,
        2,
        4,
        4,
        0,
        0,
        0,
        3,
        0,
        1,
        3,
        1,
        0,
        0,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        2,
        0,
        1,
        0,
        0,
        3,
        0,
        1,
        0,
        0,
        4,
        3,
        3,
        2,
        4,
        2,
        4,
        2,
        4,
        2,
        0,
        0,
        1,
        1,
        1,
        1,
        2,
        4,
        3,
        4,
        0,
        3,
        2,
        3,
        0,
        4,
        4,
        4,
        0,
        3,
        3,
        3,
        4,
        4,
        2,
        0,
        0,
        0,
        4,
        3,
        1,
        0
      ]
    },
    {
      "id": "958C4CED",
      "weeklyDisrupted": [
        3,
        0,
        4,
        2,
        4,
        0,
        3,
        2,
        4,
        0,
        3,
        4,
        1,
        4,
        1,
        4,
        4,
        0,
        0,
        1,
        3,
        3,
        2,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        0,
        3,
        0,
        1,
        2,
        2,
        3,
        3,
        0,
        0,
        2,
        4,
        3,
        4,
        0,
        4,
        4,
        1,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        3,
        0,
        1,
        1,
        1,
        3,
        3,
        0,
        4,
        3,
        4,
        0,
        0,
        4,
        1,
        0,
        2,
        2,
        2,
        2,
        4,
        4,
        4,
        2,
        0,
        2,
        2,
        0,
        4,
        4,
        2,
        4,
        1,
        1,
        0,
        4,
        0,
        3,
        0,
        2,
        1,
        3,
        0,
        4,
        2,
        4,
        0,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        4,
        1,
        0,
        4,
        0,
        2,
        3,
        4,
        4,
        1,
        2,
        1,
        0,
        1,
        2,
        3,
        2,
        3,
        0,
        3,
        0,
        4,
        3,
        0,
        0,
        3,
        0,
        1,
        0,
        3,
        1,
        0,
        1,
        0,
        4,
        4,
        0,
        4,
        2,
        0,
        1,
        1,
        2,
        4,
        3,
        2,
        3,
        1,
        0,
        1,
        2,
        4
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        3,
        4,
        1,
        2,
        1,
        0,
        0,
        0,
        4,
        4,
        1,
        4,
        0,
        3,
        2,
        4,
        4,
        0,
        3,
        0,
        4,
        2,
        4,
        0,
        0,
        0,
        1,
        0,
        1,
        1,
        0,
        4,
        1,
        1,
        1,
        3,
        4,
        0,
        0,
        0,
        4,
        2,
        3,
        4,
        3,
        3,
        1,
        4,
        4,
        2,
        4,
        3
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        2,
        2,
        0,
        1,
        0,
        3,
        0,
        4,
        2,
        1,
        1,
        3,
        3,
        2,
        4,
        3,
        0,
        4,
        0,
        2,
        4,
        3,
        0,
        4,
        0,
        0,
        0,
        1,
        2,
        0,
        1,
        2,
        0,
        0,
        0,
        4,
        4,
        1,
        2,
        1,
        3,
        0,
        3,
        2,
        2,
        4,
        3,
        2,
        2,
        4,
        3,
        4
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        3,
        3,
        1,
        1,
        3,
        4,
        2,
        2,
        4,
        4,
        2,
        1,
        4,
        3,
        3,
        4,
        3,
        3,
        1,
        4,
        0,
        2,
        1,
        1,
        4,
        0,
        0,
        0,
        1,
        3,
        1,
        2,
        4,
        0,
        0,
        4,
        0,
        4,
        2,
        0,
        3,
        2,
        0,
        2,
        1,
        1,
        2,
        1,
        4,
        2,
        0,
        4
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        2,
        2,
        4,
        0,
        2,
        3,
        0,
        2,
        3,
        3,
        4,
        1,
        2,
        2,
        4,
        4,
        4,
        0,
        3,
        3,
        3,
        4,
        2,
        3,
        0,
        1,
        2,
        3,
        3,
        4,
        2,
        3,
        2,
        1,
        4,
        2,
        4,
        4,
        2,
        1,
        4,
        3,
        1,
        3,
        0,
        4,
        0,
        0,
        2,
        3,
        3,
        0
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        3,
        4,
        0,
        4,
        2,
        3,
        1,
        2,
        4,
        3,
        4,
        2,
        1,
        2,
        2,
        2,
        3,
        0,
        2,
        4,
        2,
        1,
        4,
        1,
        1,
        4,
        4,
        4,
        2,
        4,
        3,
        4,
        4,
        0,
        4,
        4,
        2,
        4,
        0,
        1,
        3,
        4,
        1,
        4,
        0,
        0,
        3,
        2,
        2,
        0,
        4,
        2
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyDisrupted": [
        2,
        3,
        4,
        3,
        2,
        4,
        2,
        0,
        0,
        4,
        4,
        4,
        4,
        0,
        1,
        3,
        3,
        0,
        0,
        3,
        1,
        4,
        3,
        0,
        2,
        3,
        1,
        0,
        1,
        2,
        3,
        4,
        2,
        1,
        4,
        1,
        1,
        4,
        0,
        0,
        2,
        4,
        0,
        4,
        3,
        4,
        3,
        4,
        3,
        1,
        2,
        0
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        1,
        0,
        0,
        3,
        0,
        1,
        2,
        0,
        2,
        4,
        2,
        4,
        2,
        1,
        2,
        2,
        3,
        1,
        0,
        4,
        0,
        2,
        4,
        0,
        1,
        4,
        2,
        3,
        0,
        2,
        0,
        4,
        1,
        2,
        2,
        4,
        4,
        3,
        3,
        0,
        0,
        4,
        4,
        4,
        0,
        3,
        1,
        4,
        4,
        4,
        4,
        3
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        1,
        4,
        0,
        1,
        0,
        4,
        0,
        4,
        1,
        4,
        3,
        2,
        3,
        2,
        3,
        0,
        2,
        4,
        0,
        0,
        0,
        2,
        2,
        0,
        1,
        0,
        2,
        2,
        2,
        1,
        0,
        3,
        3,
        1,
        0,
        0,
        4,
        1,
        2,
        0,
        2,
        0,
        0,
        2,
        3,
        0,
        2,
        0
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        4,
        2,
        4,
        0,
        0,
        2,
        0,
        0,
        4,
        4,
        4,
        4,
        3,
        4,
        1,
        0,
        4,
        2,
        0,
        1,
        3,
        2,
        3,
        0,
        1,
        4,
        2,
        0,
        4,
        0,
        0,
        4,
        0,
        0,
        1,
        3,
        2,
        0,
        2,
        3,
        3,
        1,
        1,
        1,
        3,
        3,
        1,
        0,
        0,
        3,
        4,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        0,
        4,
        0,
        2,
        0,
        4,
        2,
        0,
        4,
        3,
        1,
        3,
        3,
        1,
        4,
        4,
        0,
        1,
        3,
        1,
        2,
        3,
        0,
        4,
        1,
        1,
        2,
        2,
        1,
        3,
        2,
        0,
        4,
        2,
        0,
        1,
        4,
        4,
        2,
        4,
        3,
        0,
        4,
        0,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        3,
        3,
        0,
        3,
        0,
        4,
        0,
        1,
        4,
        4,
        3,
        3,
        0,
        1,
        1,
        3,
        2,
        0,
        1,
        4,
        3,
        2,
        3,
        0,
        0,
        0,
        4,
        4,
        4,
        3,
        1,
        3,
        3,
        0,
        0,
        4,
        2,
        4,
        2,
        0,
        0,
        2,
        2,
        4,
        1,
        4,
        3,
        2,
        3,
        4,
        4,
        0
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        2,
        3,
        0,
        4,
        0,
        1,
        0,
        1,
        3,
        0,
        3,
        1,
        2,
        0,
        1,
        4,
        1,
        1,
        0,
        2,
        3,
        4,
        2,
        0,
        1,
        1,
        2,
        4,
        4,
        2,
        2,
        1,
        0,
        3,
        4,
        0,
        4,
        3,
        2,
        0,
        4,
        1,
        2,
        1,
        0,
        0,
        4,
        4,
        4,
        4,
        1,
        3
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        2,
        0,
        4,
        0,
        2,
        4,
        0,
        2,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        4,
        3,
        0,
        1,
        2,
        0,
        4,
        4,
        0,
        0,
        3,
        1,
        4,
        3,
        3,
        0,
        4,
        0,
        2,
        0,
        2,
        2,
        2,
        3,
        0,
        2,
        1,
        2,
        4,
        0,
        0,
        2,
        4,
        3,
        1,
        0,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        1,
        2,
        3,
        1,
        4,
        0,
        1,
        1,
        2,
        3,
        4,
        3,
        4,
        2,
        4,
        1,
        0,
        0,
        1,
        0,
        0,
        1,
        1,
        0,
        0,
        0,
        1,
        4,
        4,
        2,
        0,
        4,
        0,
        0,
        4,
        3,
        0,
        0,
        0,
        1,
        2,
        0,
        3,
        2,
        1,
        0,
        0,
        3,
        2,
        2,
        2
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        3,
        2,
        0,
        0,
        2,
        1,
        3,
        2,
        1,
        4,
        1,
        3,
        0,
        0,
        4,
        0,
        1,
        0,
        0,
        2,
        1,
        1,
        0,
        1,
        2,
        4,
        2,
        2,
        4,
        1,
        2,
        2,
        4,
        2,
        2,
        2,
        4,
        2,
        1,
        4,
        1,
        3,
        0,
        0,
        4,
        2,
        0,
        3
      ]
    },
    {
      "id": "200EA71C",
      "weeklyDisrupted": [
        2,
        0,
        1,
        0,
        2,
        3,
        2,
        0,
        3,
        1,
        4,
        0,
        4,
        4,
        4,
        2,
        4,
        0,
        2,
        0,
        4,
        2,
        2,
        0,
        0,
        0,
        2,
        0,
        4,
        4,
        0,
        3,
        4,
        2,
        2,
        4,
        2,
        4,
        0,
        0,
        4,
        3,
        0,
        0,
        3,
        0,
        0,
        3,
        4,
        4,
        2,
        2
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        4,
        2,
        4,
        3,
        4,
        0,
        2,
        2,
        4,
        0,
        1,
        4,
        2,
        0,
        3,
        3,
        0,
        0,
        0,
        1,
        3,
        0,
        2,
        0,
        0,
        3,
        0,
        2,
        0,
        1,
        0,
        2,
        0,
        1,
        0,
        4,
        4,
        0,
        4,
        1,
        4,
        2,
        1,
        2,
        1,
        2,
        0,
        0,
        4,
        1,
        3,
        4
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        4,
        0,
        1,
        0,
        4,
        4,
        2,
        0,
        0,
        4,
        2,
        3,
        4,
        0,
        1,
        4,
        4,
        1,
        3,
        0,
        3,
        4,
        0,
        0,
        0,
        0,
        0,
        0,
        2,
        4,
        3,
        0,
        1,
        0,
        2,
        2,
        1,
        1,
        0,
        1,
        1,
        3,
        1,
        1,
        4,
        1,
        4,
        3,
        4,
        2,
        3,
        4
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        0,
        3,
        0,
        3,
        1,
        3,
        1,
        4,
        2,
        2,
        3,
        3,
        4,
        4,
        2,
        3,
        3,
        0,
        3,
        4,
        1,
        0,
        0,
        0,
        1,
        4,
        0,
        4,
        0,
        0,
        4,
        3,
        4,
        1,
        1,
        4,
        4,
        4,
        0,
        1,
        4,
        0,
        4,
        2,
        0,
        2,
        2,
        2,
        4,
        2,
        2,
        0
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        3,
        4,
        1,
        2,
        3,
        0,
        2,
        2,
        4,
        4,
        2,
        0,
        1,
        2,
        2,
        4,
        0,
        4,
        0,
        3,
        4,
        4,
        4,
        0,
        3,
        1,
        0,
        1,
        2,
        4,
        2,
        4,
        1,
        0,
        1,
        4,
        4,
        1,
        1,
        0,
        3,
        0,
        0,
        3,
        0,
        2,
        2,
        3,
        2,
        4,
        1,
        4
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        1,
        2,
        2,
        1,
        2,
        3,
        0,
        0,
        3,
        0,
        4,
        4,
        4,
        2,
        2,
        4,
        2,
        2,
        1,
        0,
        4,
        2,
        1,
        1,
        0,
        3,
        0,
        0,
        3,
        0,
        4,
        0,
        0,
        1,
        2,
        3,
        3,
        4,
        2,
        0,
        4,
        4,
        1,
        4,
        1,
        0,
        1,
        0,
        4,
        2,
        0,
        0
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        2,
        2,
        0,
        2,
        4,
        2,
        0,
        2,
        4,
        0,
        4,
        4,
        3,
        2,
        2,
        0,
        1,
        2,
        4,
        3,
        3,
        3,
        3,
        4,
        0,
        4,
        0,
        3,
        4,
        4,
        4,
        3,
        4,
        0,
        4,
        4,
        3,
        4,
        0,
        0,
        4,
        3,
        4,
        4,
        0,
        4,
        0,
        1,
        4,
        2,
        3,
        2
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        2,
        1,
        2,
        4,
        2,
        3,
        1,
        0,
        1,
        4,
        4,
        2,
        0,
        1,
        3,
        0,
        3,
        0,
        0,
        1,
        4,
        0,
        4,
        1,
        2,
        0,
        0,
        0,
        4,
        4,
        2,
        4,
        4,
        0,
        1,
        2,
        0,
        4,
        3,
        0,
        2,
        3,
        1,
        4,
        2,
        4,
        1,
        0,
        4,
        0,
        1,
        2
      ]
    },
    {
      "id": "D33377F5",
      "weeklyDisrupted": [
        4,
        4,
        4,
        2,
        2,
        4,
        4,
        0,
        3,
        3,
        0,
        0,
        2,
        2,
        1,
        3,
        4,
        0,
        4,
        1,
        4,
        4,
        3,
        0,
        0,
        2,
        0,
        0,
        3,
        4,
        0,
        3,
        4,
        0,
        0,
        3,
        4,
        4,
        4,
        2,
        4,
        4,
        1,
        4,
        0,
        3,
        2,
        3,
        2,
        0,
        4,
        3
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        3,
        3,
        0,
        1,
        2,
        1,
        4,
        3,
        4,
        0,
        1,
        2,
        4,
        4,
        4,
        4,
        1,
        0,
        3,
        0,
        0,
        3,
        3,
        3,
        1,
        4,
        0,
        3,
        0,
        0,
        4,
        2,
        2,
        0,
        0,
        2,
        4,
        4,
        2,
        1,
        4,
        2,
        3,
        2,
        2,
        2,
        0,
        0,
        3,
        0,
        0,
        1
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        2,
        0,
        4,
        0,
        0,
        3,
        1,
        2,
        3,
        0,
        4,
        1,
        3,
        4,
        3,
        2,
        0,
        1,
        2,
        1,
        1,
        2,
        3,
        2,
        0,
        3,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        1,
        3,
        2,
        2,
        4,
        0,
        0,
        2,
        2,
        4,
        2,
        1,
        4,
        1,
        0,
        4,
        0,
        1,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        2,
        0,
        1,
        4,
        1,
        4,
        0,
        1,
        1,
        2,
        3,
        1,
        0,
        1,
        2,
        1,
        3,
        1,
        4,
        2,
        1,
        4,
        1,
        0,
        1,
        2,
        0,
        0,
        3,
        4,
        0,
        4,
        4,
        0,
        0,
        0,
        4,
        1,
        2,
        2,
        4,
        4,
        0,
        2,
        3,
        0,
        4,
        4,
        3,
        4,
        4,
        3
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyDisrupted": [
        1,
        4,
        0,
        3,
        0,
        4,
        0,
        0,
        1,
        2,
        3,
        3,
        2,
        1,
        4,
        4,
        1,
        0,
        0,
        0,
        2,
        0,
        4,
        2,
        0,
        3,
        3,
        0,
        0,
        1,
        1,
        0,
        0,
        0,
        2,
        4,
        4,
        0,
        2,
        0,
        4,
        1,
        4,
        3,
        0,
        2,
        0,
        3,
        2,
        0,
        0,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        4,
        4,
        0,
        1,
        0,
        1,
        2,
        0,
        2,
        4,
        0,
        1,
        0,
        0,
        0,
        4,
        3,
        1,
        3,
        1,
        2,
        3,
        0,
        0,
        0,
        0,
        0,
        2,
        4,
        0,
        1,
        4,
        2,
        2,
        1,
        1,
        4,
        2,
        3,
        0,
        2,
        3,
        0,
        4,
        1,
        0,
        1,
        1,
        1,
        0,
        2,
        4
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        0,
        0,
        1,
        4,
        4,
        1,
        0,
        0,
        4,
        0,
        2,
        1,
        4,
        4,
        3,
        2,
        3,
        0,
        2,
        4,
        4,
        4,
        3,
        3,
        0,
        1,
        2,
        0,
        2,
        3,
        3,
        2,
        2,
        1,
        1,
        4,
        4,
        0,
        2,
        1,
        3,
        4,
        1,
        2,
        3,
        4,
        1,
        2,
        3,
        0,
        4,
        4
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        3,
        2,
        2,
        4,
        0,
        4,
        0,
        1,
        3,
        0,
        4,
        1,
        4,
        4,
        4,
        1,
        4,
        0,
        3,
        0,
        4,
        0,
        0,
        3,
        0,
        2,
        1,
        0,
        0,
        0,
        0,
        4,
        3,
        0,
        3,
        3,
        4,
        4,
        1,
        0,
        4,
        0,
        1,
        1,
        0,
        0,
        0,
        3,
        2,
        2,
        2,
        2
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        4,
        2,
        0,
        4,
        1,
        3,
        1,
        2,
        2,
        0,
        2,
        0,
        4,
        0,
        4,
        1,
        0,
        0,
        3,
        0,
        4,
        0,
        4,
        1,
        1,
        0,
        1,
        1,
        0,
        4,
        1,
        4,
        2,
        0,
        3,
        4,
        4,
        4,
        3,
        1,
        1,
        3,
        1,
        2,
        3,
        4,
        0,
        4,
        4,
        0,
        3,
        1
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        4,
        1,
        0,
        3,
        4,
        1,
        4,
        4,
        0,
        4,
        4,
        1,
        3,
        4,
        3,
        4,
        3,
        1,
        4,
        4,
        3,
        4,
        3,
        1,
        4,
        2,
        0,
        4,
        3,
        3,
        2,
        4,
        2,
        1,
        3,
        4,
        2,
        4,
        3,
        3,
        1,
        3,
        0,
        0,
        0,
        4,
        1,
        0,
        0,
        2,
        2,
        0
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        4,
        2,
        4,
        0,
        4,
        4,
        0,
        4,
        4,
        3,
        2,
        4,
        3,
        0,
        0,
        4,
        3,
        0,
        1,
        3,
        1,
        4,
        3,
        4,
        0,
        0,
        0,
        1,
        3,
        2,
        4,
        4,
        3,
        2,
        2,
        0,
        3,
        1,
        0,
        1,
        2,
        1,
        0,
        0,
        1,
        0,
        0,
        0,
        4,
        0,
        2,
        2
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        4,
        0,
        3,
        2,
        3,
        4,
        0,
        2,
        0,
        4,
        3,
        2,
        2,
        3,
        4,
        4,
        3,
        4,
        4,
        2,
        4,
        1,
        2,
        0,
        0,
        0,
        1,
        0,
        4,
        3,
        3,
        3,
        3,
        1,
        3,
        3,
        1,
        3,
        0,
        1,
        4,
        1,
        2,
        0,
        0,
        2,
        0,
        1,
        2,
        0,
        4,
        4
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        4,
        3,
        0,
        4,
        1,
        3,
        3,
        3,
        4,
        4,
        3,
        2,
        3,
        2,
        1,
        2,
        0,
        0,
        4,
        4,
        2,
        4,
        2,
        0,
        0,
        4,
        4,
        4,
        4,
        3,
        1,
        4,
        1,
        4,
        1,
        4,
        2,
        4,
        0,
        0,
        1,
        3,
        4,
        4,
        0,
        0,
        1,
        1,
        4,
        1,
        4,
        0
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        4,
        4,
        2,
        3,
        2,
        1,
        2,
        2,
        3,
        1,
        3,
        2,
        3,
        0,
        2,
        1,
        1,
        1,
        0,
        0,
        0,
        2,
        0,
        2,
        0,
        4,
        0,
        0,
        4,
        0,
        0,
        0,
        0,
        0,
        1,
        4,
        4,
        2,
        1,
        0,
        3,
        2,
        0,
        1,
        3,
        4,
        4,
        0,
        2,
        4,
        3,
        2
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        4,
        0,
        1,
        2,
        4,
        3,
        0,
        0,
        4,
        2,
        4,
        1,
        2,
        3,
        2,
        3,
        2,
        1,
        4,
        0,
        2,
        3,
        0,
        2,
        0,
        3,
        2,
        4,
        4,
        3,
        0,
        2,
        0,
        1,
        2,
        2,
        4,
        4,
        0,
        1,
        4,
        4,
        0,
        1,
        4,
        2,
        1,
        0,
        2,
        2,
        4,
        0
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        3,
        2,
        0,
        4,
        3,
        1,
        4,
        4,
        1,
        0,
        4,
        2,
        3,
        4,
        4,
        1,
        3,
        0,
        2,
        0,
        1,
        0,
        4,
        4,
        0,
        2,
        0,
        2,
        0,
        4,
        1,
        1,
        3,
        0,
        3,
        3,
        4,
        4,
        4,
        2,
        3,
        4,
        0,
        2,
        3,
        2,
        0,
        1,
        2,
        0,
        0,
        3
      ]
    },
    {
      "id": "D33377F5",
      "weeklyDisrupted": [
        1,
        4,
        1,
        4,
        4,
        1,
        1,
        0,
        3,
        3,
        2,
        4,
        4,
        0,
        2,
        0,
        4,
        3,
        0,
        0,
        4,
        3,
        4,
        4,
        1,
        3,
        2,
        4,
        0,
        3,
        3,
        2,
        2,
        0,
        0,
        4,
        4,
        4,
        3,
        0,
        0,
        2,
        4,
        3,
        0,
        3,
        2,
        3,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        0,
        0,
        4,
        3,
        2,
        4,
        0,
        3,
        3,
        4,
        1,
        3,
        0,
        1,
        2,
        0,
        4,
        1,
        4,
        4,
        1,
        3,
        0,
        4,
        0,
        0,
        2,
        3,
        4,
        3,
        0,
        4,
        0,
        1,
        2,
        2,
        4,
        2,
        2,
        1,
        3,
        4,
        4,
        2,
        1,
        1,
        1,
        0,
        4,
        0,
        2,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        3,
        1,
        4,
        2,
        0,
        1,
        2,
        3,
        3,
        0,
        4,
        4,
        4,
        2,
        0,
        4,
        4,
        0,
        4,
        4,
        0,
        3,
        2,
        1,
        0,
        4,
        2,
        1,
        4,
        4,
        0,
        2,
        0,
        3,
        2,
        0,
        4,
        4,
        1,
        3,
        4,
        2,
        0,
        0,
        0,
        0,
        4,
        3,
        4,
        2,
        0,
        2
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        2,
        1,
        3,
        4,
        1,
        2,
        3,
        3,
        3,
        0,
        0,
        3,
        1,
        2,
        3,
        4,
        4,
        0,
        4,
        4,
        4,
        2,
        0,
        1,
        2,
        4,
        0,
        0,
        2,
        0,
        0,
        2,
        0,
        2,
        3,
        3,
        3,
        3,
        0,
        3,
        4,
        2,
        0,
        3,
        4,
        1,
        0,
        1,
        4,
        1,
        2,
        4
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        4,
        0,
        2,
        4,
        1,
        3,
        0,
        0,
        1,
        4,
        2,
        3,
        2,
        4,
        2,
        0,
        4,
        0,
        4,
        4,
        3,
        4,
        3,
        2,
        2,
        4,
        1,
        2,
        1,
        2,
        1,
        0,
        3,
        0,
        3,
        3,
        4,
        4,
        0,
        0,
        4,
        4,
        0,
        1,
        3,
        4,
        0,
        1,
        2,
        4,
        4,
        1
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        4,
        4,
        3,
        3,
        0,
        0,
        0,
        2,
        2,
        4,
        1,
        3,
        0,
        0,
        2,
        3,
        2,
        0,
        0,
        4,
        4,
        2,
        2,
        0,
        1,
        2,
        3,
        0,
        2,
        0,
        0,
        3,
        2,
        2,
        4,
        3,
        1,
        4,
        2,
        1,
        0,
        1,
        0,
        4,
        4,
        0,
        3,
        3,
        2,
        4,
        0,
        4
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        3,
        3,
        4,
        0,
        4,
        0,
        0,
        3,
        3,
        2,
        4,
        4,
        4,
        1,
        0,
        3,
        2,
        0,
        0,
        3,
        4,
        2,
        2,
        2,
        0,
        0,
        1,
        2,
        3,
        3,
        2,
        4,
        0,
        2,
        1,
        1,
        4,
        2,
        2,
        0,
        3,
        2,
        0,
        0,
        3,
        4,
        0,
        1,
        4,
        4,
        4,
        2
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyDisrupted": [
        2,
        2,
        0,
        0,
        2,
        0,
        0,
        1,
        4,
        1,
        4,
        3,
        4,
        2,
        1,
        1,
        1,
        0,
        3,
        2,
        0,
        3,
        3,
        0,
        0,
        4,
        1,
        4,
        2,
        4,
        4,
        2,
        4,
        3,
        2,
        4,
        4,
        4,
        2,
        3,
        2,
        4,
        0,
        4,
        1,
        4,
        0,
        0,
        0,
        2,
        2,
        0
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        4,
        4,
        0,
        2,
        2,
        0,
        2,
        4,
        1,
        2,
        4,
        4,
        3,
        4,
        1,
        3,
        2,
        2,
        0,
        4,
        2,
        2,
        4,
        3,
        0,
        3,
        2,
        3,
        4,
        0,
        3,
        4,
        3,
        4,
        3,
        2,
        4,
        3,
        1,
        0,
        4,
        3,
        0,
        2,
        0,
        1,
        1,
        4,
        2,
        4,
        3,
        3
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        4,
        2,
        4,
        3,
        1,
        4,
        0,
        4,
        1,
        0,
        3,
        2,
        4,
        3,
        3,
        4,
        3,
        0,
        4,
        1,
        0,
        4,
        4,
        4,
        0,
        2,
        1,
        0,
        1,
        4,
        3,
        2,
        4,
        4,
        0,
        1,
        3,
        1,
        2,
        3,
        3,
        2,
        2,
        3,
        1,
        2,
        2,
        1,
        1,
        2,
        4,
        0
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        2,
        0,
        0,
        4,
        1,
        4,
        0,
        0,
        3,
        0,
        1,
        4,
        2,
        2,
        4,
        3,
        2,
        0,
        0,
        2,
        4,
        0,
        2,
        0,
        3,
        1,
        4,
        0,
        4,
        3,
        4,
        4,
        2,
        2,
        1,
        2,
        4,
        0,
        2,
        0,
        4,
        1,
        0,
        4,
        0,
        0,
        2,
        3,
        3,
        4,
        2,
        4
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        3,
        4,
        3,
        0,
        1,
        4,
        0,
        4,
        2,
        3,
        4,
        3,
        0,
        0,
        1,
        1,
        3,
        2,
        0,
        2,
        2,
        0,
        0,
        0,
        0,
        4,
        1,
        4,
        2,
        1,
        1,
        1,
        1,
        0,
        0,
        1,
        3,
        0,
        3,
        0,
        4,
        2,
        1,
        2,
        2,
        3,
        0,
        1,
        2,
        0,
        2,
        4
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        1,
        4,
        2,
        3,
        2,
        2,
        0,
        1,
        1,
        3,
        4,
        4,
        1,
        4,
        2,
        0,
        0,
        3,
        4,
        2,
        4,
        3,
        3,
        1,
        0,
        0,
        0,
        1,
        0,
        2,
        1,
        2,
        0,
        0,
        0,
        0,
        3,
        2,
        0,
        0,
        2,
        2,
        1,
        3,
        1,
        4,
        2,
        2,
        2,
        0,
        4,
        4
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        3,
        2,
        0,
        0,
        4,
        4,
        1,
        1,
        2,
        0,
        4,
        2,
        4,
        4,
        3,
        3,
        3,
        1,
        0,
        1,
        0,
        0,
        3,
        1,
        0,
        4,
        2,
        0,
        1,
        0,
        4,
        4,
        0,
        1,
        3,
        2,
        4,
        2,
        1,
        1,
        3,
        2,
        0,
        2,
        1,
        1,
        2,
        4,
        0,
        1,
        0,
        2
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        3,
        1,
        1,
        0,
        2,
        4,
        2,
        2,
        4,
        4,
        3,
        0,
        0,
        3,
        1,
        4,
        0,
        1,
        0,
        2,
        4,
        4,
        0,
        0,
        3,
        2,
        2,
        4,
        4,
        3,
        1,
        4,
        3,
        3,
        0,
        4,
        2,
        4,
        0,
        0,
        1,
        0,
        2,
        4,
        1,
        1,
        0,
        1,
        3,
        0,
        0,
        0
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        1,
        1,
        1,
        1,
        3,
        4,
        1,
        0,
        4,
        1,
        4,
        1,
        1,
        1,
        1,
        0,
        2,
        3,
        1,
        3,
        1,
        0,
        2,
        0,
        0,
        4,
        3,
        4,
        1,
        3,
        0,
        2,
        2,
        0,
        1,
        3,
        3,
        4,
        0,
        0,
        0,
        0,
        3,
        4,
        0,
        0,
        0,
        3,
        1,
        2,
        1,
        3
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        2,
        1,
        2,
        2,
        4,
        2,
        3,
        1,
        4,
        4,
        3,
        1,
        0,
        2,
        1,
        3,
        4,
        1,
        2,
        1,
        1,
        4,
        3,
        0,
        0,
        0,
        1,
        0,
        4,
        2,
        2,
        2,
        3,
        2,
        0,
        3,
        0,
        1,
        1,
        0,
        0,
        1,
        0,
        3,
        2,
        2,
        2,
        1,
        4,
        3,
        1,
        3
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        2,
        1,
        3,
        0,
        0,
        4,
        1,
        2,
        1,
        4,
        3,
        3,
        2,
        3,
        2,
        0,
        2,
        2,
        1,
        4,
        0,
        4,
        0,
        3,
        0,
        4,
        0,
        0,
        4,
        3,
        0,
        2,
        4,
        2,
        2,
        4,
        2,
        1,
        0,
        0,
        0,
        1,
        2,
        1,
        0,
        4,
        0,
        0,
        2,
        4,
        4,
        3
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        3,
        2,
        1,
        4,
        1,
        1,
        2,
        4,
        0,
        4,
        1,
        4,
        0,
        1,
        1,
        2,
        4,
        0,
        0,
        3,
        2,
        4,
        3,
        0,
        2,
        0,
        0,
        0,
        1,
        0,
        1,
        4,
        0,
        0,
        0,
        3,
        3,
        4,
        1,
        0,
        1,
        2,
        0,
        2,
        0,
        4,
        0,
        0,
        4,
        4,
        3,
        3
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyDisrupted": [
        1,
        0,
        0,
        4,
        2,
        3,
        0,
        0,
        2,
        2,
        4,
        4,
        3,
        2,
        2,
        3,
        3,
        2,
        2,
        0,
        2,
        0,
        1,
        3,
        0,
        2,
        1,
        0,
        3,
        4,
        4,
        4,
        4,
        0,
        0,
        3,
        3,
        2,
        1,
        0,
        2,
        3,
        1,
        4,
        0,
        1,
        1,
        0,
        4,
        1,
        3,
        0
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        0,
        0,
        2,
        4,
        0,
        3,
        1,
        3,
        0,
        3,
        0,
        2,
        4,
        4,
        3,
        1,
        3,
        0,
        2,
        0,
        4,
        1,
        3,
        0,
        1,
        0,
        1,
        3,
        3,
        4,
        0,
        4,
        3,
        0,
        3,
        4,
        4,
        4,
        0,
        1,
        4,
        4,
        2,
        3,
        4,
        4,
        0,
        3,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        4,
        0,
        0,
        4,
        1,
        2,
        2,
        4,
        4,
        0,
        4,
        3,
        1,
        3,
        4,
        4,
        3,
        1,
        1,
        0,
        0,
        3,
        1,
        4,
        1,
        2,
        1,
        0,
        4,
        1,
        0,
        2,
        2,
        2,
        4,
        4,
        4,
        4,
        4,
        2,
        4,
        2,
        0,
        4,
        1,
        0,
        4,
        1,
        4,
        1,
        3,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        3,
        1,
        0,
        1,
        2,
        2,
        0,
        4,
        0,
        1,
        4,
        0,
        1,
        3,
        2,
        4,
        4,
        2,
        1,
        0,
        1,
        0,
        1,
        0,
        0,
        2,
        1,
        3,
        3,
        4,
        1,
        2,
        2,
        0,
        2,
        3,
        2,
        0,
        3,
        0,
        3,
        2,
        4,
        4,
        1,
        1,
        4,
        3,
        4,
        2,
        2,
        3
      ]
    },
    {
      "id": "958C4CED",
      "weeklyDisrupted": [
        3,
        1,
        4,
        1,
        0,
        4,
        0,
        2,
        2,
        0,
        3,
        3,
        0,
        1,
        1,
        4,
        3,
        2,
        0,
        3,
        1,
        4,
        2,
        3,
        0,
        2,
        1,
        1,
        4,
        4,
        3,
        2,
        1,
        1,
        3,
        4,
        0,
        4,
        0,
        0,
        4,
        2,
        1,
        2,
        1,
        3,
        0,
        0,
        4,
        1,
        0,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        4,
        4,
        3,
        4,
        4,
        1,
        3,
        4,
        4,
        3,
        4,
        2,
        0,
        2,
        2,
        1,
        2,
        2,
        2,
        3,
        0,
        4,
        3,
        2,
        2,
        3,
        0,
        1,
        4,
        4,
        0,
        0,
        0,
        2,
        4,
        3,
        2,
        4,
        0,
        2,
        3,
        3,
        3,
        4,
        2,
        3,
        2,
        2,
        3,
        2,
        0,
        0
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        0,
        2,
        1,
        2,
        1,
        3,
        0,
        1,
        0,
        0,
        4,
        4,
        3,
        0,
        4,
        1,
        3,
        0,
        1,
        1,
        0,
        3,
        1,
        0,
        1,
        4,
        0,
        0,
        3,
        2,
        4,
        1,
        3,
        4,
        2,
        1,
        2,
        4,
        0,
        0,
        4,
        1,
        4,
        1,
        0,
        1,
        3,
        1,
        3,
        4,
        1,
        2
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        1,
        2,
        0,
        1,
        4,
        1,
        2,
        2,
        1,
        0,
        1,
        0,
        4,
        1,
        2,
        2,
        2,
        1,
        3,
        2,
        4,
        0,
        2,
        0,
        0,
        1,
        1,
        0,
        2,
        0,
        0,
        2,
        0,
        2,
        0,
        3,
        2,
        0,
        3,
        0,
        4,
        4,
        0,
        2,
        2,
        0,
        0,
        1,
        2,
        0,
        4,
        1
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        2,
        0,
        1,
        3,
        1,
        3,
        0,
        2,
        1,
        2,
        2,
        0,
        4,
        1,
        4,
        4,
        4,
        0,
        4,
        2,
        2,
        0,
        4,
        2,
        0,
        3,
        1,
        1,
        3,
        0,
        1,
        2,
        3,
        0,
        2,
        2,
        4,
        0,
        4,
        3,
        2,
        2,
        1,
        3,
        0,
        4,
        1,
        0,
        4,
        4,
        0,
        0
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        4,
        4,
        1,
        0,
        2,
        1,
        0,
        4,
        4,
        3,
        4,
        1,
        4,
        3,
        4,
        3,
        3,
        3,
        3,
        1,
        1,
        3,
        0,
        2,
        2,
        3,
        0,
        2,
        2,
        4,
        2,
        1,
        4,
        3,
        0,
        4,
        4,
        2,
        0,
        3,
        4,
        2,
        1,
        3,
        3,
        4,
        4,
        2,
        2,
        2,
        4,
        0
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        1,
        4,
        1,
        0,
        1,
        0,
        4,
        4,
        4,
        4,
        4,
        2,
        3,
        1,
        3,
        4,
        0,
        0,
        2,
        3,
        2,
        3,
        4,
        3,
        2,
        3,
        2,
        1,
        4,
        4,
        3,
        3,
        0,
        3,
        3,
        4,
        4,
        3,
        4,
        1,
        2,
        3,
        0,
        2,
        2,
        0,
        0,
        3,
        0,
        4,
        3,
        4
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyDisrupted": [
        2,
        1,
        0,
        0,
        1,
        4,
        1,
        0,
        4,
        3,
        4,
        1,
        4,
        1,
        2,
        4,
        0,
        2,
        1,
        2,
        2,
        0,
        3,
        2,
        0,
        0,
        2,
        3,
        4,
        4,
        1,
        3,
        0,
        1,
        4,
        3,
        3,
        4,
        3,
        1,
        2,
        3,
        1,
        4,
        3,
        2,
        0,
        0,
        2,
        3,
        0,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        3,
        0,
        2,
        4,
        4,
        3,
        0,
        1,
        4,
        0,
        3,
        1,
        3,
        4,
        4,
        1,
        0,
        1,
        0,
        3,
        3,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        4,
        2,
        0,
        3,
        4,
        1,
        0,
        3,
        4,
        2,
        0,
        4,
        4,
        2,
        0,
        3,
        4,
        0,
        2,
        0,
        0,
        0,
        3,
        0
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyDisrupted": [
        3,
        0,
        2,
        3,
        1,
        4,
        0,
        3,
        3,
        2,
        4,
        4,
        1,
        2,
        3,
        3,
        4,
        3,
        1,
        4,
        0,
        1,
        4,
        3,
        1,
        0,
        4,
        0,
        2,
        4,
        4,
        2,
        1,
        0,
        4,
        3,
        4,
        1,
        2,
        0,
        3,
        2,
        2,
        4,
        1,
        0,
        0,
        0,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        0,
        2,
        4,
        3,
        1,
        4,
        0,
        0,
        4,
        0,
        1,
        1,
        4,
        1,
        4,
        4,
        2,
        0,
        3,
        0,
        0,
        0,
        3,
        0,
        0,
        2,
        0,
        1,
        4,
        3,
        2,
        1,
        3,
        3,
        3,
        3,
        3,
        0,
        0,
        0,
        4,
        4,
        2,
        4,
        1,
        1,
        0,
        0,
        1,
        4,
        3,
        1
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        2,
        0,
        0,
        4,
        0,
        2,
        0,
        2,
        0,
        2,
        2,
        0,
        2,
        4,
        2,
        3,
        2,
        1,
        1,
        4,
        3,
        3,
        3,
        0,
        0,
        2,
        2,
        2,
        0,
        3,
        3,
        2,
        2,
        2,
        0,
        4,
        2,
        4,
        0,
        0,
        0,
        4,
        0,
        3,
        4,
        3,
        0,
        2,
        1,
        0,
        4,
        4
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        0,
        4,
        1,
        4,
        2,
        1,
        3,
        4,
        4,
        3,
        4,
        1,
        1,
        2,
        3,
        4,
        2,
        2,
        1,
        1,
        0,
        4,
        4,
        3,
        1,
        2,
        2,
        0,
        4,
        4,
        1,
        2,
        0,
        1,
        4,
        2,
        4,
        0,
        1,
        1,
        4,
        3,
        0,
        2,
        0,
        2,
        0,
        1,
        0,
        2,
        1,
        2
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyDisrupted": [
        4,
        1,
        2,
        3,
        1,
        3,
        0,
        4,
        4,
        0,
        3,
        3,
        4,
        4,
        4,
        0,
        2,
        4,
        2,
        2,
        3,
        2,
        0,
        4,
        3,
        3,
        4,
        4,
        4,
        1,
        3,
        2,
        0,
        0,
        3,
        4,
        4,
        2,
        2,
        0,
        4,
        2,
        2,
        4,
        0,
        4,
        1,
        0,
        0,
        4,
        2,
        4
      ]
    },
    {
      "id": "41097027",
      "weeklyDisrupted": [
        4,
        0,
        2,
        1,
        0,
        2,
        0,
        2,
        3,
        1,
        3,
        0,
        4,
        0,
        0,
        3,
        1,
        1,
        0,
        1,
        2,
        2,
        2,
        3,
        2,
        1,
        0,
        0,
        1,
        3,
        4,
        1,
        0,
        2,
        4,
        4,
        4,
        0,
        1,
        0,
        3,
        4,
        0,
        1,
        1,
        3,
        0,
        1,
        4,
        4,
        4,
        2
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyDisrupted": [
        1,
        0,
        4,
        2,
        0,
        3,
        2,
        1,
        2,
        3,
        1,
        4,
        3,
        0,
        4,
        0,
        3,
        3,
        1,
        0,
        4,
        0,
        2,
        0,
        1,
        2,
        1,
        0,
        0,
        0,
        3,
        2,
        4,
        0,
        4,
        1,
        3,
        4,
        2,
        0,
        1,
        3,
        0,
        0,
        0,
        3,
        0,
        3,
        4,
        3,
        2,
        2
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        1,
        3,
        3,
        2,
        1,
        1,
        2,
        4,
        2,
        4,
        4,
        4,
        1,
        4,
        1,
        2,
        3,
        3,
        2,
        3,
        0,
        4,
        4,
        4,
        2,
        1,
        1,
        0,
        2,
        0,
        0,
        3,
        4,
        0,
        0,
        3,
        4,
        1,
        1,
        0,
        3,
        4,
        2,
        4,
        0,
        2,
        4,
        0,
        4,
        1,
        4,
        1
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        3,
        2,
        3,
        0,
        0,
        1,
        0,
        0,
        4,
        2,
        2,
        0,
        4,
        0,
        1,
        2,
        0,
        2,
        1,
        2,
        0,
        3,
        2,
        0,
        0,
        0,
        4,
        1,
        2,
        1,
        0,
        2,
        0,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        3,
        1,
        3,
        0,
        1,
        0,
        0,
        2,
        3,
        1,
        4,
        0
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        0,
        2,
        2,
        0,
        4,
        1,
        1,
        4,
        4,
        0,
        4,
        2,
        0,
        3,
        3,
        0,
        4,
        0,
        3,
        0,
        0,
        2,
        2,
        0,
        2,
        3,
        0,
        1,
        4,
        4,
        3,
        1,
        1,
        0,
        3,
        2,
        0,
        0,
        0,
        0,
        0,
        2,
        3,
        4,
        0,
        3,
        1,
        2,
        1,
        4,
        3,
        2
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyDisrupted": [
        2,
        0,
        3,
        3,
        0,
        1,
        0,
        1,
        3,
        2,
        1,
        2,
        4,
        0,
        3,
        4,
        3,
        1,
        2,
        3,
        4,
        1,
        3,
        0,
        2,
        1,
        0,
        0,
        0,
        0,
        0,
        4,
        0,
        0,
        4,
        4,
        3,
        4,
        0,
        3,
        2,
        4,
        0,
        1,
        4,
        4,
        0,
        4,
        4,
        1,
        1,
        3
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        0,
        2,
        2,
        1,
        1,
        1,
        2,
        4,
        4,
        4,
        2,
        0,
        4,
        1,
        4,
        4,
        4,
        0,
        4,
        2,
        1,
        2,
        0,
        0,
        0,
        3,
        2,
        0,
        4,
        1,
        4,
        4,
        1,
        0,
        4,
        4,
        3,
        0,
        0,
        1,
        4,
        3,
        0,
        2,
        1,
        0,
        0,
        3,
        4,
        3,
        2,
        0
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        1,
        3,
        2,
        3,
        1,
        2,
        2,
        1,
        3,
        0,
        3,
        1,
        3,
        0,
        3,
        4,
        2,
        1,
        3,
        1,
        1,
        3,
        3,
        0,
        0,
        0,
        2,
        0,
        4,
        4,
        3,
        4,
        2,
        0,
        4,
        4,
        2,
        4,
        0,
        0,
        1,
        1,
        0,
        2,
        0,
        2,
        4,
        1,
        4,
        4,
        0,
        2
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        3,
        4,
        2,
        3,
        0,
        1,
        0,
        3,
        4,
        3,
        1,
        0,
        4,
        1,
        1,
        0,
        0,
        1,
        0,
        4,
        2,
        2,
        4,
        3,
        2,
        1,
        0,
        1,
        4,
        0,
        2,
        1,
        4,
        0,
        1,
        2,
        4,
        1,
        2,
        4,
        4,
        2,
        1,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        4,
        4
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        3,
        3,
        1,
        2,
        4,
        2,
        2,
        4,
        2,
        3,
        3,
        0,
        1,
        1,
        4,
        4,
        2,
        4,
        4,
        0,
        1,
        3,
        2,
        1,
        2,
        0,
        2,
        3,
        4,
        4,
        0,
        1,
        3,
        4,
        4,
        0,
        4,
        4,
        1,
        0,
        4,
        4,
        4,
        3,
        0,
        0,
        2,
        1,
        2,
        4,
        3,
        0
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        2,
        0,
        1,
        3,
        1,
        0,
        4,
        1,
        1,
        3,
        1,
        2,
        0,
        3,
        4,
        4,
        0,
        3,
        2,
        4,
        2,
        0,
        4,
        0,
        1,
        2,
        0,
        0,
        2,
        3,
        1,
        4,
        0,
        0,
        3,
        2,
        0,
        2,
        0,
        0,
        2,
        3,
        3,
        3,
        2,
        4,
        0,
        3,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        2,
        2,
        2,
        3,
        0,
        2,
        0,
        2,
        1,
        4,
        4,
        0,
        4,
        0,
        1,
        3,
        1,
        3,
        0,
        3,
        4,
        4,
        0,
        3,
        0,
        4,
        0,
        1,
        0,
        4,
        0,
        2,
        4,
        0,
        1,
        4,
        3,
        4,
        0,
        0,
        1,
        4,
        3,
        0,
        0,
        4,
        1,
        1,
        4,
        2,
        1,
        4
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        2,
        2,
        1,
        4,
        4,
        2,
        0,
        3,
        3,
        0,
        4,
        2,
        4,
        2,
        4,
        0,
        2,
        4,
        1,
        0,
        2,
        4,
        2,
        2,
        0,
        2,
        0,
        0,
        2,
        4,
        4,
        4,
        0,
        0,
        3,
        4,
        3,
        1,
        0,
        0,
        3,
        2,
        1,
        4,
        3,
        2,
        0,
        0,
        4,
        4,
        1,
        4
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        4,
        3,
        1,
        0,
        2,
        0,
        0,
        3,
        4,
        4,
        4,
        4,
        3,
        0,
        3,
        0,
        1,
        2,
        0,
        3,
        4,
        4,
        0,
        0,
        0,
        0,
        2,
        0,
        3,
        0,
        0,
        4,
        0,
        0,
        1,
        3,
        2,
        0,
        2,
        4,
        0,
        1,
        1,
        3,
        0,
        4,
        1,
        4,
        4,
        2,
        2,
        2
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        3,
        3,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        1,
        3,
        1,
        4,
        4,
        2,
        2,
        3,
        4,
        3,
        0,
        3,
        3,
        1,
        0,
        1,
        3,
        0,
        0,
        1,
        0,
        0,
        4,
        0,
        1,
        1,
        3,
        2,
        4,
        3,
        1,
        3,
        4,
        0,
        1,
        0,
        0,
        1,
        1,
        1,
        3,
        0,
        2
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        3,
        3,
        0,
        1,
        0,
        2,
        4,
        3,
        2,
        0,
        1,
        4,
        2,
        0,
        4,
        0,
        1,
        1,
        0,
        4,
        4,
        0,
        4,
        4,
        0,
        1,
        0,
        0,
        1,
        3,
        4,
        4,
        0,
        0,
        0,
        3,
        4,
        0,
        0,
        0,
        2,
        3,
        2,
        2,
        0,
        3,
        1,
        0,
        2,
        4,
        4,
        1
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        4,
        4,
        3,
        2,
        2,
        1,
        0,
        2,
        3,
        3,
        4,
        1,
        1,
        2,
        3,
        4,
        4,
        3,
        2,
        4,
        0,
        1,
        3,
        0,
        1,
        1,
        4,
        4,
        1,
        0,
        1,
        1,
        1,
        3,
        0,
        3,
        2,
        2,
        1,
        2,
        3,
        3,
        2,
        4,
        1,
        0,
        2,
        3,
        3,
        4,
        3,
        3
      ]
    },
    {
      "id": "19A12B20",
      "weeklyDisrupted": [
        3,
        0,
        2,
        2,
        0,
        0,
        0,
        0,
        4,
        1,
        4,
        1,
        3,
        1,
        1,
        4,
        4,
        3,
        0,
        3,
        0,
        1,
        4,
        4,
        0,
        2,
        1,
        1,
        2,
        0,
        3,
        3,
        4,
        1,
        1,
        3,
        4,
        2,
        1,
        0,
        2,
        1,
        1,
        1,
        1,
        3,
        0,
        0,
        1,
        1,
        4,
        3
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        4,
        0,
        2,
        4,
        2,
        2,
        4,
        3,
        0,
        4,
        1,
        1,
        2,
        3,
        2,
        4,
        3,
        4,
        3,
        2,
        4,
        1,
        3,
        1,
        0,
        0,
        2,
        2,
        4,
        0,
        4,
        4,
        0,
        0,
        1,
        4,
        1,
        2,
        3,
        1,
        0,
        4,
        0,
        2,
        0,
        1,
        0,
        4,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        2,
        4,
        4,
        4,
        1,
        2,
        0,
        2,
        0,
        3,
        4,
        2,
        4,
        2,
        2,
        2,
        0,
        0,
        3,
        2,
        0,
        4,
        4,
        0,
        1,
        1,
        1,
        2,
        0,
        2,
        2,
        4,
        0,
        3,
        1,
        3,
        4,
        3,
        3,
        0,
        0,
        4,
        3,
        0,
        1,
        4,
        1,
        3,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        1,
        1,
        4,
        4,
        1,
        0,
        4,
        2,
        4,
        0,
        2,
        0,
        0,
        2,
        4,
        0,
        1,
        2,
        2,
        1,
        4,
        3,
        3,
        0,
        0,
        4,
        2,
        0,
        3,
        4,
        4,
        4,
        0,
        1,
        2,
        3,
        1,
        3,
        1,
        0,
        4,
        4,
        1,
        4,
        4,
        2,
        2,
        0,
        2,
        0,
        3,
        3
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        2,
        0,
        0,
        0,
        1,
        1,
        0,
        1,
        2,
        0,
        3,
        0,
        1,
        1,
        3,
        0,
        1,
        2,
        3,
        1,
        4,
        0,
        2,
        1,
        0,
        4,
        0,
        0,
        4,
        2,
        1,
        2,
        2,
        2,
        0,
        2,
        2,
        0,
        0,
        0,
        2,
        2,
        1,
        2,
        3,
        2,
        2,
        2,
        4,
        4,
        3,
        2
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        2,
        3,
        4,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        0,
        3,
        4,
        2,
        4,
        4,
        3,
        1,
        2,
        2,
        0,
        3,
        4,
        0,
        3,
        4,
        0,
        1,
        0,
        4,
        4,
        0,
        0,
        4,
        3,
        2,
        4,
        0,
        3,
        0,
        4,
        2,
        3,
        0,
        2,
        2,
        0,
        2,
        4,
        1,
        2,
        2
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        1,
        1,
        0,
        4,
        4,
        3,
        0,
        4,
        4,
        4,
        1,
        4,
        0,
        3,
        1,
        2,
        1,
        0,
        0,
        1,
        2,
        4,
        0,
        1,
        0,
        2,
        0,
        4,
        4,
        1,
        4,
        3,
        2,
        0,
        0,
        3,
        3,
        3,
        3,
        0,
        4,
        3,
        3,
        4,
        0,
        4,
        0,
        0,
        4,
        3,
        0,
        3
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        0,
        3,
        2,
        3,
        1,
        0,
        0,
        4,
        1,
        1,
        4,
        3,
        2,
        0,
        3,
        0,
        3,
        4,
        3,
        0,
        4,
        1,
        4,
        0,
        2,
        1,
        2,
        1,
        1,
        4,
        2,
        2,
        1,
        0,
        0,
        4,
        0,
        1,
        0,
        4,
        4,
        2,
        4,
        2,
        4,
        4,
        4,
        0,
        3,
        1,
        2,
        4
      ]
    },
    {
      "id": "D33377F5",
      "weeklyDisrupted": [
        2,
        1,
        3,
        1,
        1,
        4,
        1,
        3,
        2,
        1,
        4,
        3,
        3,
        0,
        2,
        3,
        2,
        2,
        2,
        4,
        3,
        1,
        0,
        2,
        0,
        4,
        1,
        3,
        3,
        0,
        0,
        4,
        0,
        0,
        2,
        2,
        4,
        0,
        0,
        0,
        0,
        1,
        0,
        2,
        0,
        4,
        4,
        1,
        3,
        1,
        3,
        4
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        0,
        1,
        4,
        2,
        4,
        1,
        3,
        4,
        0,
        2,
        3,
        1,
        1,
        3,
        0,
        4,
        1,
        2,
        0,
        0,
        0,
        2,
        1,
        2,
        4,
        2,
        3,
        1,
        1,
        0,
        1,
        1,
        1,
        2,
        2,
        4,
        1,
        1,
        2,
        2,
        3,
        4,
        4,
        4,
        3,
        0,
        0,
        4,
        0,
        2,
        2
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        1,
        1,
        0,
        1,
        4,
        2,
        0,
        3,
        4,
        2,
        4,
        4,
        1,
        4,
        0,
        2,
        1,
        2,
        4,
        3,
        0,
        0,
        4,
        1,
        0,
        2,
        2,
        2,
        2,
        0,
        3,
        0,
        2,
        0,
        2,
        2,
        4,
        1,
        4,
        0,
        0,
        2,
        4,
        4,
        1,
        4,
        2,
        1,
        4,
        2,
        2,
        2
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        2,
        2,
        4,
        2,
        0,
        0,
        0,
        3,
        3,
        1,
        2,
        2,
        2,
        4,
        3,
        4,
        3,
        3,
        0,
        3,
        0,
        1,
        4,
        1,
        0,
        2,
        1,
        0,
        2,
        0,
        0,
        2,
        3,
        0,
        0,
        2,
        3,
        0,
        0,
        2,
        4,
        1,
        2,
        4,
        0,
        3,
        1,
        0,
        1,
        2,
        4,
        1
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        4,
        4,
        0,
        2,
        0,
        4,
        2,
        0,
        2,
        1,
        1,
        3,
        2,
        3,
        2,
        2,
        4,
        3,
        2,
        2,
        2,
        4,
        4,
        0,
        2,
        2,
        4,
        3,
        4,
        3,
        2,
        4,
        0,
        0,
        0,
        2,
        4,
        0,
        3,
        2,
        3,
        3,
        0,
        0,
        3,
        2,
        0,
        3,
        2,
        4,
        0,
        1
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        4,
        4,
        2,
        0,
        2,
        4,
        1,
        3,
        4,
        0,
        3,
        0,
        4,
        0,
        1,
        0,
        2,
        2,
        0,
        3,
        4,
        4,
        3,
        3,
        0,
        2,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        2,
        0,
        3,
        3,
        4,
        0,
        4,
        4,
        1,
        1,
        2,
        4,
        4,
        4,
        0,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        4,
        0,
        4,
        3,
        0,
        1,
        1,
        1,
        4,
        0,
        4,
        0,
        4,
        1,
        2,
        4,
        2,
        2,
        1,
        4,
        0,
        1,
        4,
        0,
        0,
        2,
        3,
        0,
        1,
        3,
        3,
        2,
        0,
        4,
        3,
        4,
        4,
        1,
        2,
        0,
        4,
        3,
        4,
        4,
        0,
        2,
        0,
        3,
        1,
        1,
        2,
        0
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        2,
        3,
        4,
        2,
        1,
        3,
        0,
        2,
        3,
        0,
        0,
        3,
        1,
        4,
        1,
        2,
        2,
        0,
        2,
        2,
        3,
        2,
        2,
        0,
        0,
        2,
        2,
        1,
        4,
        4,
        0,
        1,
        0,
        1,
        2,
        2,
        4,
        1,
        0,
        0,
        4,
        2,
        0,
        3,
        0,
        2,
        2,
        4,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        2,
        2,
        4,
        2,
        1,
        2,
        0,
        4,
        2,
        2,
        2,
        3,
        3,
        2,
        4,
        4,
        2,
        2,
        0,
        0,
        1,
        4,
        4,
        2,
        0,
        1,
        0,
        0,
        2,
        4,
        2,
        4,
        2,
        0,
        0,
        4,
        4,
        0,
        0,
        0,
        4,
        3,
        4,
        2,
        3,
        4,
        1,
        2,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        3,
        1,
        2,
        1,
        4,
        2,
        3,
        1,
        4,
        0,
        0,
        0,
        4,
        2,
        2,
        2,
        4,
        2,
        1,
        2,
        3,
        4,
        4,
        1,
        3,
        3,
        0,
        0,
        4,
        3,
        2,
        4,
        2,
        2,
        3,
        4,
        1,
        3,
        2,
        0,
        4,
        4,
        0,
        2,
        0,
        4,
        0,
        1,
        0,
        4,
        3,
        3
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        0,
        1,
        0,
        2,
        2,
        0,
        0,
        3,
        3,
        2,
        4,
        3,
        1,
        4,
        4,
        3,
        3,
        2,
        4,
        2,
        2,
        0,
        4,
        0,
        0,
        1,
        1,
        0,
        0,
        0,
        2,
        2,
        2,
        1,
        1,
        3,
        4,
        2,
        1,
        0,
        1,
        3,
        4,
        3,
        1,
        0,
        4,
        0,
        2,
        2,
        4,
        2
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        3,
        3,
        0,
        4,
        3,
        1,
        0,
        0,
        1,
        4,
        4,
        1,
        4,
        2,
        1,
        4,
        3,
        0,
        2,
        0,
        0,
        3,
        3,
        4,
        0,
        4,
        0,
        1,
        0,
        2,
        3,
        4,
        3,
        0,
        1,
        4,
        2,
        1,
        3,
        0,
        3,
        2,
        1,
        2,
        4,
        4,
        4,
        1,
        0,
        4,
        2,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        4,
        1,
        3,
        1,
        1,
        2,
        1,
        2,
        4,
        1,
        0,
        0,
        3,
        0,
        3,
        4,
        3,
        0,
        0,
        4,
        2,
        3,
        2,
        3,
        3,
        1,
        0,
        2,
        3,
        4,
        1,
        3,
        1,
        2,
        2,
        2,
        4,
        1,
        0,
        0,
        4,
        3,
        2,
        4,
        0,
        2,
        0,
        1,
        2,
        3,
        1,
        1
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        4,
        2,
        4,
        0,
        2,
        4,
        0,
        3,
        4,
        0,
        4,
        4,
        1,
        3,
        0,
        4,
        1,
        3,
        3,
        4,
        0,
        0,
        2,
        2,
        0,
        1,
        4,
        0,
        4,
        4,
        2,
        4,
        0,
        1,
        3,
        4,
        4,
        1,
        0,
        0,
        1,
        3,
        0,
        3,
        0,
        0,
        3,
        1,
        3,
        1,
        0,
        0
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        0,
        0,
        2,
        3,
        1,
        2,
        2,
        3,
        4,
        0,
        4,
        1,
        3,
        2,
        4,
        1,
        1,
        1,
        1,
        4,
        1,
        3,
        4,
        1,
        0,
        2,
        0,
        1,
        1,
        1,
        3,
        3,
        4,
        0,
        4,
        2,
        4,
        3,
        0,
        0,
        4,
        1,
        3,
        4,
        1,
        4,
        3,
        1,
        4,
        1,
        3,
        3
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        4,
        2,
        2,
        0,
        0,
        1,
        0,
        3,
        3,
        0,
        3,
        2,
        3,
        4,
        1,
        4,
        3,
        4,
        2,
        0,
        0,
        1,
        4,
        0,
        1,
        4,
        1,
        0,
        4,
        0,
        3,
        4,
        2,
        0,
        2,
        0,
        1,
        3,
        0,
        0,
        2,
        2,
        1,
        3,
        0,
        3,
        0,
        3,
        0,
        4,
        2,
        3
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        4,
        1,
        4,
        3,
        1,
        4,
        0,
        2,
        1,
        4,
        4,
        2,
        4,
        4,
        4,
        2,
        3,
        4,
        3,
        0,
        2,
        4,
        0,
        2,
        1,
        2,
        2,
        2,
        3,
        2,
        0,
        3,
        2,
        1,
        1,
        2,
        4,
        4,
        3,
        0,
        3,
        2,
        2,
        1,
        2,
        3,
        0,
        2,
        2,
        0,
        0,
        1
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        3,
        3,
        2,
        4,
        1,
        2,
        4,
        4,
        4,
        1,
        4,
        0,
        3,
        0,
        2,
        4,
        1,
        4,
        1,
        1,
        1,
        3,
        3,
        3,
        0,
        3,
        1,
        0,
        0,
        3,
        1,
        4,
        4,
        0,
        3,
        4,
        3,
        4,
        3,
        1,
        4,
        4,
        1,
        4,
        1,
        4,
        0,
        4,
        3,
        2,
        3,
        0
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        0,
        4,
        4,
        0,
        3,
        4,
        3,
        3,
        4,
        4,
        4,
        4,
        1,
        1,
        0,
        3,
        0,
        0,
        0,
        1,
        3,
        4,
        1,
        1,
        0,
        1,
        2,
        1,
        2,
        0,
        3,
        2,
        3,
        2,
        1,
        0,
        4,
        4,
        4,
        1,
        3,
        1,
        1,
        2,
        0,
        1,
        0,
        4,
        2,
        2,
        0,
        0
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        3,
        4,
        0,
        1,
        0,
        0,
        0,
        3,
        1,
        2,
        0,
        3,
        4,
        2,
        2,
        4,
        2,
        4,
        2,
        1,
        4,
        1,
        4,
        0,
        0,
        1,
        2,
        0,
        0,
        3,
        2,
        2,
        1,
        3,
        3,
        0,
        1,
        4,
        0,
        1,
        4,
        2,
        0,
        1,
        0,
        2,
        1,
        2,
        1,
        4,
        4,
        1
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        3,
        0,
        0,
        2,
        1,
        3,
        0,
        1,
        4,
        0,
        4,
        3,
        4,
        4,
        0,
        4,
        4,
        2,
        4,
        0,
        0,
        2,
        4,
        1,
        0,
        0,
        0,
        2,
        4,
        4,
        4,
        2,
        0,
        1,
        0,
        3,
        4,
        0,
        4,
        0,
        3,
        2,
        4,
        4,
        3,
        2,
        1,
        1,
        4,
        4,
        0,
        0
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        4,
        4,
        3,
        0,
        4,
        1,
        2,
        2,
        3,
        0,
        4,
        4,
        2,
        4,
        1,
        0,
        0,
        4,
        3,
        3,
        3,
        4,
        1,
        1,
        0,
        0,
        2,
        3,
        4,
        0,
        0,
        3,
        3,
        0,
        0,
        1,
        1,
        4,
        1,
        1,
        3,
        3,
        0,
        3,
        0,
        4,
        2,
        0,
        2,
        1,
        4,
        0
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        3,
        4,
        1,
        3,
        3,
        1,
        2,
        3,
        4,
        3,
        3,
        3,
        4,
        1,
        4,
        0,
        0,
        1,
        4,
        3,
        4,
        0,
        4,
        0,
        1,
        2,
        0,
        1,
        0,
        0,
        1,
        4,
        2,
        0,
        0,
        2,
        1,
        4,
        1,
        0,
        0,
        3,
        0,
        2,
        0,
        4,
        4,
        1,
        2,
        2,
        2,
        2
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        0,
        0,
        4,
        2,
        0,
        3,
        0,
        4,
        1,
        1,
        3,
        4,
        3,
        0,
        0,
        3,
        2,
        0,
        4,
        3,
        0,
        4,
        4,
        0,
        1,
        0,
        2,
        0,
        2,
        3,
        1,
        4,
        0,
        3,
        3,
        0,
        4,
        1,
        2,
        0,
        2,
        4,
        3,
        4,
        3,
        2,
        1,
        2,
        4,
        1,
        2,
        1
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        2,
        3,
        3,
        0,
        0,
        2,
        3,
        1,
        3,
        0,
        4,
        1,
        4,
        0,
        0,
        3,
        2,
        0,
        4,
        2,
        2,
        4,
        3,
        4,
        0,
        3,
        0,
        0,
        0,
        3,
        1,
        3,
        3,
        0,
        2,
        4,
        4,
        0,
        0,
        0,
        4,
        2,
        0,
        1,
        1,
        2,
        0,
        2,
        3,
        0,
        0,
        4
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        1,
        0,
        1,
        0,
        2,
        2,
        2,
        0,
        4,
        2,
        4,
        4,
        4,
        4,
        2,
        2,
        4,
        0,
        0,
        3,
        4,
        2,
        3,
        1,
        2,
        4,
        0,
        1,
        2,
        0,
        1,
        0,
        4,
        2,
        0,
        3,
        4,
        0,
        0,
        4,
        3,
        2,
        0,
        4,
        4,
        3,
        3,
        0,
        0,
        3,
        1,
        2
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        0,
        0,
        3,
        3,
        4,
        4,
        0,
        0,
        3,
        0,
        4,
        4,
        3,
        4,
        3,
        2,
        0,
        1,
        0,
        0,
        1,
        2,
        0,
        2,
        0,
        2,
        0,
        1,
        2,
        1,
        0,
        2,
        2,
        1,
        2,
        1,
        4,
        3,
        0,
        0,
        2,
        0,
        4,
        2,
        0,
        0,
        0,
        0,
        1,
        0,
        3,
        1
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        0,
        3,
        3,
        4,
        4,
        2,
        1,
        1,
        3,
        4,
        4,
        4,
        1,
        4,
        0,
        1,
        2,
        0,
        2,
        2,
        0,
        2,
        4,
        2,
        0,
        1,
        1,
        1,
        0,
        3,
        3,
        0,
        0,
        0,
        0,
        4,
        2,
        4,
        1,
        0,
        0,
        4,
        2,
        4,
        4,
        4,
        3,
        2,
        4,
        0,
        3,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        2,
        0,
        4,
        4,
        0,
        4,
        0,
        0,
        0,
        2,
        1,
        3,
        2,
        0,
        2,
        2,
        3,
        1,
        2,
        0,
        1,
        1,
        0,
        0,
        0,
        2,
        3,
        3,
        1,
        4,
        0,
        4,
        3,
        1,
        4,
        4,
        3,
        4,
        3,
        0,
        2,
        3,
        2,
        4,
        0,
        1,
        0,
        1,
        2,
        0,
        4,
        4
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        0,
        0,
        2,
        4,
        0,
        1,
        3,
        0,
        0,
        1,
        0,
        0,
        1,
        4,
        4,
        0,
        4,
        0,
        0,
        3,
        4,
        3,
        4,
        4,
        2,
        2,
        0,
        1,
        2,
        4,
        0,
        2,
        1,
        1,
        0,
        0,
        3,
        4,
        1,
        1,
        2,
        3,
        0,
        4,
        1,
        4,
        3,
        2,
        0,
        2,
        4,
        0
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        3,
        2,
        0,
        0,
        3,
        4,
        1,
        0,
        3,
        2,
        4,
        4,
        4,
        2,
        2,
        4,
        3,
        2,
        2,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        4,
        2,
        2,
        2,
        0,
        4,
        2,
        2,
        2,
        1,
        1,
        4,
        0,
        1,
        3,
        4,
        4,
        3,
        0,
        2,
        4,
        3,
        2,
        4,
        2,
        3
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        3,
        4,
        4,
        0,
        2,
        0,
        0,
        4,
        4,
        2,
        4,
        4,
        4,
        4,
        1,
        2,
        4,
        2,
        4,
        0,
        0,
        3,
        2,
        0,
        1,
        4,
        0,
        0,
        3,
        1,
        0,
        3,
        0,
        1,
        2,
        2,
        3,
        2,
        3,
        1,
        3,
        2,
        0,
        3,
        2,
        2,
        1,
        1,
        4,
        4,
        2,
        3
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        4,
        1,
        2,
        4,
        0,
        4,
        2,
        0,
        3,
        0,
        3,
        3,
        3,
        1,
        2,
        1,
        0,
        1,
        2,
        4,
        2,
        0,
        4,
        4,
        0,
        2,
        0,
        0,
        0,
        0,
        3,
        2,
        2,
        0,
        1,
        3,
        4,
        4,
        2,
        3,
        2,
        4,
        3,
        2,
        0,
        1,
        0,
        2,
        2,
        2,
        0,
        4
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        2,
        4,
        2,
        0,
        0,
        3,
        0,
        1,
        2,
        0,
        3,
        1,
        4,
        1,
        1,
        4,
        4,
        1,
        0,
        4,
        1,
        3,
        4,
        3,
        2,
        3,
        1,
        2,
        4,
        0,
        4,
        2,
        0,
        1,
        0,
        4,
        1,
        1,
        3,
        0,
        4,
        3,
        1,
        1,
        0,
        2,
        0,
        2,
        4,
        4,
        1,
        4
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        1,
        4,
        0,
        4,
        1,
        0,
        1,
        3,
        0,
        3,
        0,
        4,
        3,
        2,
        4,
        2,
        1,
        2,
        3,
        1,
        4,
        4,
        3,
        0,
        0,
        0,
        0,
        2,
        1,
        0,
        0,
        3,
        2,
        3,
        0,
        1,
        0,
        4,
        1,
        0,
        4,
        3,
        4,
        0,
        2,
        1,
        2,
        4,
        4,
        1,
        3,
        3
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        4,
        0,
        3,
        4,
        0,
        3,
        4,
        0,
        3,
        1,
        2,
        4,
        4,
        3,
        2,
        3,
        2,
        0,
        1,
        1,
        0,
        1,
        4,
        0,
        4,
        4,
        2,
        3,
        0,
        1,
        3,
        2,
        4,
        1,
        3,
        2,
        3,
        4,
        4,
        3,
        2,
        3,
        3,
        4,
        4,
        3,
        0,
        4,
        1,
        4,
        4,
        1
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        3,
        4,
        4,
        2,
        1,
        4,
        1,
        1,
        4,
        0,
        0,
        3,
        4,
        0,
        4,
        0,
        2,
        0,
        1,
        4,
        4,
        1,
        0,
        2,
        0,
        0,
        3,
        1,
        1,
        4,
        4,
        4,
        0,
        0,
        4,
        4,
        3,
        0,
        1,
        1,
        4,
        4,
        0,
        3,
        0,
        3,
        0,
        4,
        0,
        0,
        3,
        1
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        3,
        1,
        1,
        4,
        1,
        2,
        3,
        0,
        2,
        3,
        3,
        0,
        3,
        4,
        1,
        4,
        2,
        0,
        3,
        0,
        4,
        3,
        1,
        3,
        0,
        1,
        0,
        1,
        4,
        4,
        1,
        1,
        1,
        3,
        0,
        2,
        0,
        4,
        0,
        1,
        1,
        2,
        0,
        1,
        3,
        3,
        4,
        0,
        3,
        2,
        4,
        4
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        0,
        4,
        2,
        4,
        1,
        2,
        0,
        2,
        1,
        0,
        4,
        3,
        2,
        0,
        4,
        0,
        2,
        4,
        4,
        1,
        1,
        1,
        4,
        2,
        0,
        4,
        0,
        0,
        0,
        0,
        0,
        1,
        4,
        0,
        3,
        3,
        3,
        0,
        3,
        2,
        3,
        2,
        2,
        3,
        0,
        4,
        3,
        3,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyDisrupted": [
        4,
        3,
        0,
        3,
        4,
        1,
        0,
        4,
        4,
        0,
        4,
        1,
        4,
        1,
        2,
        3,
        4,
        1,
        1,
        0,
        4,
        4,
        4,
        1,
        3,
        3,
        1,
        0,
        4,
        2,
        3,
        2,
        2,
        0,
        0,
        4,
        4,
        0,
        1,
        0,
        4,
        4,
        0,
        0,
        0,
        3,
        0,
        0,
        4,
        0,
        0,
        2
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        3,
        4,
        3,
        3,
        3,
        4,
        4,
        1,
        4,
        4,
        4,
        0,
        0,
        4,
        0,
        1,
        1,
        2,
        3,
        2,
        1,
        4,
        0,
        0,
        0,
        4,
        1,
        4,
        1,
        1,
        3,
        1,
        1,
        1,
        0,
        2,
        0,
        4,
        2,
        2,
        1,
        2,
        1,
        0,
        3,
        0,
        0,
        1,
        4,
        4,
        2,
        2
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        2,
        4,
        0,
        3,
        2,
        2,
        4,
        0,
        2,
        4,
        3,
        4,
        0,
        3,
        4,
        1,
        3,
        4,
        0,
        3,
        2,
        1,
        0,
        0,
        2,
        2,
        0,
        1,
        4,
        3,
        0,
        2,
        4,
        4,
        0,
        2,
        1,
        4,
        1,
        0,
        0,
        2,
        2,
        4,
        0,
        0,
        1,
        4,
        4,
        1,
        0,
        0
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        1,
        2,
        4,
        4,
        4,
        1,
        1,
        2,
        3,
        3,
        4,
        4,
        2,
        2,
        2,
        4,
        2,
        1,
        1,
        1,
        0,
        4,
        3,
        0,
        0,
        2,
        2,
        3,
        3,
        4,
        0,
        3,
        0,
        1,
        2,
        1,
        4,
        0,
        3,
        1,
        0,
        4,
        3,
        2,
        0,
        4,
        2,
        4,
        2,
        0,
        3,
        4
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        4,
        2,
        1,
        3,
        0,
        4,
        1,
        2,
        0,
        4,
        4,
        3,
        3,
        3,
        2,
        0,
        3,
        0,
        0,
        3,
        3,
        3,
        4,
        2,
        0,
        3,
        4,
        1,
        3,
        4,
        3,
        4,
        0,
        0,
        2,
        3,
        3,
        4,
        0,
        1,
        0,
        2,
        2,
        0,
        2,
        3,
        0,
        2,
        1,
        0,
        3,
        4
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        1,
        3,
        3,
        4,
        0,
        4,
        2,
        4,
        1,
        1,
        1,
        2,
        4,
        1,
        3,
        4,
        3,
        1,
        3,
        0,
        3,
        0,
        4,
        2,
        0,
        0,
        2,
        0,
        2,
        4,
        1,
        4,
        4,
        0,
        4,
        3,
        4,
        0,
        2,
        0,
        1,
        3,
        0,
        2,
        1,
        4,
        2,
        0,
        4,
        3,
        3,
        4
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        3,
        4,
        2,
        0,
        0,
        1,
        0,
        4,
        0,
        4,
        1,
        1,
        4,
        0,
        1,
        2,
        4,
        2,
        2,
        4,
        4,
        4,
        4,
        0,
        2,
        2,
        0,
        1,
        3,
        0,
        3,
        2,
        2,
        0,
        0,
        2,
        0,
        2,
        0,
        0,
        1,
        1,
        0,
        0,
        1,
        4,
        2,
        1,
        1,
        4,
        4,
        4
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        3,
        0,
        0,
        1,
        0,
        1,
        3,
        0,
        3,
        1,
        3,
        0,
        4,
        0,
        2,
        4,
        4,
        4,
        1,
        2,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        3,
        0,
        0,
        0,
        2,
        1,
        2,
        2,
        1,
        0,
        2,
        4,
        0,
        1,
        3,
        4,
        1,
        4,
        3,
        0,
        0,
        0,
        4,
        3,
        2
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        1,
        4,
        1,
        2,
        0,
        1,
        0,
        1,
        2,
        1,
        2,
        4,
        2,
        2,
        2,
        3,
        2,
        1,
        4,
        3,
        2,
        1,
        0,
        4,
        0,
        0,
        3,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        2,
        2,
        3,
        3,
        2,
        0,
        1,
        4,
        0,
        0,
        0,
        4,
        2,
        3,
        2,
        4,
        4,
        2
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        3,
        3,
        2,
        0,
        2,
        3,
        2,
        2,
        3,
        3,
        3,
        1,
        1,
        4,
        0,
        0,
        2,
        2,
        0,
        3,
        2,
        4,
        2,
        4,
        0,
        0,
        2,
        0,
        3,
        1,
        1,
        0,
        0,
        2,
        0,
        1,
        1,
        2,
        2,
        2,
        3,
        4,
        0,
        4,
        0,
        4,
        0,
        0,
        3,
        0,
        3,
        3
      ]
    },
    {
      "id": "048701CE",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        4,
        3,
        2,
        0,
        2,
        3,
        1,
        0,
        4,
        1,
        2,
        2,
        2,
        2,
        0,
        1,
        3,
        3,
        0,
        0,
        1,
        0,
        1,
        0,
        2,
        2,
        1,
        2,
        2,
        2,
        3,
        0,
        1,
        4,
        2,
        2,
        3,
        3,
        0,
        1,
        1,
        4,
        0,
        1,
        0,
        0,
        2,
        0
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        2,
        3,
        1,
        3,
        4,
        0,
        0,
        4,
        3,
        2,
        4,
        4,
        2,
        2,
        2,
        1,
        3,
        0,
        0,
        3,
        3,
        2,
        2,
        0,
        0,
        1,
        1,
        0,
        1,
        4,
        1,
        4,
        1,
        3,
        0,
        2,
        4,
        0,
        0,
        0,
        1,
        1,
        0,
        3,
        1,
        1,
        2,
        0,
        4,
        2,
        1,
        3
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        3,
        3,
        2,
        1,
        2,
        3,
        4,
        0,
        2,
        0,
        0,
        1,
        4,
        1,
        1,
        3,
        1,
        2,
        0,
        3,
        4,
        4,
        3,
        3,
        0,
        3,
        4,
        1,
        0,
        1,
        3,
        3,
        4,
        0,
        0,
        1,
        2,
        3,
        0,
        1,
        3,
        3,
        0,
        2,
        3,
        2,
        0,
        1,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        4,
        2,
        2,
        3,
        4,
        4,
        1,
        2,
        3,
        0,
        2,
        4,
        2,
        4,
        4,
        3,
        3,
        0,
        4,
        0,
        0,
        1,
        4,
        1,
        0,
        3,
        1,
        0,
        0,
        3,
        3,
        4,
        3,
        2,
        3,
        3,
        3,
        4,
        0,
        2,
        2,
        2,
        4,
        4,
        2,
        4,
        0,
        4,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        2,
        0,
        0,
        1,
        0,
        2,
        0,
        2,
        2,
        0,
        4,
        0,
        4,
        0,
        1,
        1,
        3,
        4,
        1,
        4,
        0,
        1,
        1,
        4,
        0,
        2,
        0,
        1,
        0,
        3,
        4,
        0,
        3,
        2,
        3,
        4,
        4,
        1,
        3,
        0,
        0,
        0,
        3,
        3,
        0,
        1,
        0,
        3,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        3,
        2,
        3,
        3,
        4,
        3,
        0,
        2,
        4,
        0,
        4,
        2,
        4,
        0,
        1,
        4,
        0,
        1,
        3,
        4,
        0,
        0,
        4,
        4,
        0,
        3,
        0,
        2,
        1,
        2,
        2,
        1,
        1,
        0,
        2,
        4,
        3,
        1,
        0,
        0,
        3,
        4,
        3,
        3,
        0,
        4,
        0,
        0,
        2,
        4,
        0,
        4
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        3,
        0,
        2,
        3,
        2,
        1,
        1,
        1,
        4,
        1,
        4,
        0,
        1,
        1,
        4,
        4,
        3,
        0,
        1,
        4,
        0,
        4,
        4,
        1,
        0,
        1,
        3,
        2,
        3,
        2,
        0,
        4,
        2,
        4,
        2,
        4,
        3,
        0,
        1,
        0,
        4,
        2,
        2,
        4,
        2,
        2,
        0,
        4,
        1,
        1,
        3,
        0
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        3,
        2,
        4,
        0,
        2,
        1,
        1,
        4,
        0,
        1,
        4,
        0,
        3,
        3,
        3,
        1,
        3,
        1,
        0,
        3,
        0,
        3,
        4,
        0,
        0,
        1,
        0,
        0,
        0,
        2,
        1,
        4,
        1,
        1,
        3,
        4,
        4,
        0,
        0,
        2,
        4,
        2,
        0,
        4,
        2,
        1,
        1,
        4,
        4,
        2,
        3,
        4
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        3,
        4,
        0,
        2,
        0,
        4,
        2,
        4,
        1,
        4,
        4,
        4,
        2,
        2,
        3,
        4,
        4,
        1,
        2,
        0,
        2,
        2,
        2,
        1,
        0,
        3,
        1,
        3,
        1,
        0,
        1,
        2,
        4,
        4,
        2,
        3,
        2,
        1,
        2,
        0,
        3,
        4,
        4,
        4,
        2,
        1,
        2,
        0,
        4,
        2,
        4,
        3
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        1,
        0,
        1,
        4,
        4,
        0,
        4,
        1,
        2,
        3,
        1,
        0,
        3,
        4,
        1,
        3,
        2,
        4,
        0,
        3,
        0,
        1,
        2,
        2,
        0,
        1,
        1,
        1,
        3,
        3,
        3,
        4,
        0,
        0,
        2,
        2,
        3,
        4,
        1,
        3,
        2,
        0,
        3,
        3,
        4,
        3,
        2,
        4,
        2,
        1,
        0,
        2
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        4,
        3,
        3,
        2,
        2,
        2,
        3,
        2,
        1,
        4,
        1,
        0,
        4,
        3,
        0,
        4,
        3,
        0,
        0,
        3,
        2,
        3,
        3,
        0,
        0,
        4,
        0,
        2,
        1,
        4,
        2,
        4,
        2,
        3,
        4,
        2,
        4,
        4,
        0,
        0,
        3,
        2,
        0,
        0,
        1,
        4,
        0,
        1,
        0,
        0,
        1,
        3
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        3,
        4,
        2,
        0,
        0,
        4,
        4,
        0,
        0,
        0,
        3,
        4,
        3,
        0,
        3,
        3,
        0,
        1,
        1,
        2,
        0,
        1,
        2,
        1,
        0,
        3,
        1,
        4,
        4,
        0,
        4,
        4,
        0,
        1,
        3,
        3,
        3,
        1,
        1,
        0,
        2,
        2,
        0,
        4,
        0,
        0,
        0,
        4,
        2,
        2,
        2,
        0
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        4,
        4,
        0,
        0,
        4,
        3,
        3,
        3,
        0,
        2,
        4,
        4,
        4,
        4,
        4,
        4,
        2,
        1,
        3,
        3,
        4,
        1,
        0,
        2,
        0,
        2,
        0,
        1,
        0,
        1,
        0,
        4,
        0,
        0,
        0,
        2,
        2,
        2,
        2,
        0,
        2,
        4,
        4,
        4,
        4,
        0,
        1,
        4,
        4,
        0,
        2,
        0
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        0,
        4,
        0,
        1,
        0,
        4,
        2,
        2,
        1,
        4,
        3,
        1,
        3,
        0,
        4,
        3,
        1,
        0,
        0,
        0,
        3,
        2,
        2,
        1,
        0,
        2,
        3,
        4,
        4,
        4,
        0,
        3,
        4,
        2,
        1,
        2,
        2,
        0,
        3,
        0,
        1,
        0,
        1,
        0,
        3,
        2,
        1,
        1,
        2,
        4,
        1,
        4
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        4,
        2,
        3,
        3,
        0,
        3,
        4,
        0,
        1,
        2,
        3,
        1,
        3,
        2,
        3,
        0,
        2,
        0,
        3,
        0,
        2,
        1,
        0,
        0,
        0,
        2,
        0,
        4,
        2,
        4,
        0,
        4,
        3,
        1,
        3,
        4,
        2,
        4,
        0,
        0,
        0,
        4,
        1,
        4,
        4,
        2,
        0,
        3,
        4,
        0,
        3,
        0
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        0,
        2,
        4,
        4,
        1,
        3,
        0,
        0,
        0,
        3,
        4,
        4,
        4,
        0,
        1,
        2,
        3,
        1,
        0,
        2,
        0,
        0,
        2,
        0,
        0,
        0,
        1,
        1,
        0,
        3,
        0,
        4,
        0,
        2,
        3,
        3,
        2,
        4,
        0,
        0,
        2,
        2,
        0,
        2,
        3,
        1,
        2,
        1,
        1,
        0,
        3,
        1
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        1,
        4,
        4,
        0,
        1,
        3,
        1,
        3,
        3,
        4,
        3,
        2,
        0,
        4,
        4,
        0,
        1,
        1,
        2,
        2,
        4,
        2,
        0,
        0,
        0,
        2,
        0,
        4,
        0,
        2,
        1,
        4,
        0,
        0,
        2,
        2,
        1,
        4,
        2,
        1,
        4,
        2,
        0,
        2,
        4,
        2,
        0,
        2,
        3,
        4,
        4,
        0
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        4,
        0,
        0,
        2,
        0,
        4,
        1,
        0,
        2,
        3,
        3,
        1,
        0,
        3,
        2,
        1,
        1,
        4,
        0,
        0,
        0,
        1,
        4,
        2,
        0,
        2,
        0,
        1,
        2,
        4,
        3,
        2,
        2,
        0,
        0,
        2,
        1,
        3,
        2,
        0,
        0,
        2,
        3,
        4,
        0,
        1,
        4,
        0,
        0,
        4,
        2,
        0
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        1,
        0,
        1,
        4,
        1,
        2,
        2,
        4,
        4,
        2,
        4,
        2,
        4,
        0,
        1,
        2,
        4,
        0,
        3,
        0,
        3,
        1,
        4,
        0,
        0,
        2,
        0,
        1,
        4,
        2,
        4,
        4,
        3,
        0,
        1,
        3,
        4,
        1,
        0,
        0,
        4,
        4,
        1,
        4,
        0,
        2,
        1,
        4,
        3,
        3,
        2,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        4,
        3,
        0,
        0,
        0,
        2,
        0,
        2,
        1,
        0,
        4,
        2,
        4,
        4,
        0,
        2,
        2,
        1,
        2,
        2,
        1,
        1,
        2,
        1,
        0,
        4,
        0,
        1,
        2,
        2,
        4,
        1,
        0,
        0,
        1,
        3,
        2,
        4,
        0,
        1,
        4,
        2,
        4,
        4,
        2,
        3,
        4,
        3,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "200EA71C",
      "weeklyDisrupted": [
        2,
        3,
        2,
        3,
        0,
        4,
        3,
        3,
        4,
        2,
        2,
        2,
        3,
        0,
        1,
        0,
        1,
        1,
        2,
        2,
        2,
        2,
        4,
        0,
        1,
        4,
        2,
        2,
        1,
        0,
        2,
        2,
        1,
        4,
        0,
        4,
        2,
        4,
        0,
        1,
        3,
        4,
        0,
        3,
        0,
        1,
        0,
        3,
        3,
        4,
        2,
        0
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        1,
        0,
        0,
        1,
        0,
        2,
        0,
        1,
        3,
        0,
        2,
        1,
        4,
        0,
        1,
        4,
        0,
        4,
        0,
        4,
        4,
        3,
        4,
        3,
        2,
        2,
        2,
        1,
        3,
        4,
        3,
        4,
        4,
        0,
        3,
        4,
        4,
        0,
        3,
        3,
        4,
        3,
        2,
        3,
        0,
        4,
        0,
        0,
        3,
        2,
        1,
        0
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        4,
        4,
        0,
        4,
        0,
        4,
        2,
        4,
        4,
        0,
        4,
        2,
        0,
        2,
        2,
        1,
        0,
        4,
        4,
        2,
        3,
        3,
        2,
        0,
        0,
        4,
        0,
        3,
        3,
        0,
        1,
        4,
        0,
        1,
        0,
        4,
        3,
        4,
        0,
        0,
        2,
        2,
        0,
        2,
        0,
        0,
        0,
        0,
        4,
        1,
        0,
        4
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        3,
        0,
        4,
        4,
        2,
        3,
        0,
        1,
        2,
        2,
        3,
        3,
        4,
        2,
        2,
        2,
        3,
        0,
        2,
        4,
        1,
        0,
        4,
        3,
        1,
        0,
        0,
        2,
        0,
        4,
        3,
        2,
        0,
        0,
        0,
        4,
        3,
        1,
        4,
        1,
        3,
        4,
        2,
        4,
        1,
        4,
        1,
        0,
        4,
        2,
        2,
        4
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        0,
        4,
        1,
        4,
        0,
        1,
        0,
        0,
        0,
        4,
        4,
        4,
        4,
        0,
        3,
        4,
        2,
        3,
        0,
        4,
        3,
        3,
        4,
        1,
        1,
        0,
        3,
        3,
        4,
        4,
        2,
        4,
        4,
        2,
        1,
        0,
        2,
        0,
        3,
        1,
        0,
        4,
        4,
        3,
        0,
        4,
        0,
        2,
        2,
        2,
        4,
        2
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        2,
        1,
        2,
        4,
        3,
        4,
        0,
        1,
        2,
        0,
        4,
        1,
        4,
        2,
        1,
        2,
        4,
        0,
        3,
        2,
        2,
        0,
        3,
        1,
        2,
        4,
        2,
        1,
        2,
        3,
        0,
        1,
        4,
        3,
        0,
        3,
        4,
        4,
        0,
        0,
        1,
        4,
        1,
        1,
        2,
        1,
        3,
        2,
        1,
        2,
        2,
        0
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        2,
        4,
        2,
        2,
        2,
        1,
        0,
        2,
        4,
        4,
        4,
        4,
        2,
        4,
        0,
        0,
        0,
        0,
        3,
        1,
        2,
        1,
        4,
        0,
        0,
        1,
        2,
        4,
        1,
        2,
        1,
        1,
        1,
        0,
        0,
        4,
        0,
        4,
        4,
        0,
        3,
        4,
        3,
        4,
        0,
        3,
        4,
        1,
        0,
        2,
        1,
        0
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        3,
        3,
        2,
        4,
        0,
        1,
        4,
        0,
        1,
        4,
        4,
        0,
        0,
        3,
        0,
        4,
        2,
        3,
        0,
        3,
        0,
        3,
        2,
        1,
        0,
        0,
        0,
        2,
        2,
        4,
        0,
        2,
        2,
        0,
        3,
        1,
        2,
        3,
        0,
        3,
        0,
        1,
        3,
        3,
        0,
        4,
        0,
        3,
        0,
        4,
        3,
        0
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        0,
        4,
        4,
        2,
        1,
        1,
        2,
        1,
        4,
        2,
        1,
        2,
        0,
        0,
        1,
        3,
        0,
        2,
        4,
        2,
        1,
        4,
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        4,
        0,
        1,
        0,
        0,
        1,
        4,
        2,
        2,
        0,
        3,
        1,
        3,
        2,
        3,
        2,
        0,
        4,
        4,
        4,
        1
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        3,
        3,
        0,
        1,
        0,
        3,
        4,
        1,
        4,
        1,
        4,
        0,
        3,
        0,
        2,
        3,
        3,
        0,
        0,
        4,
        1,
        0,
        0,
        0,
        0,
        4,
        1,
        4,
        4,
        4,
        3,
        3,
        1,
        0,
        4,
        2,
        1,
        4,
        0,
        4,
        4,
        2,
        2,
        4,
        4,
        3,
        1,
        0,
        4,
        4,
        0,
        2
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        4,
        0,
        3,
        0,
        4,
        4,
        2,
        1,
        2,
        0,
        4,
        0,
        1,
        3,
        1,
        2,
        4,
        2,
        4,
        1,
        2,
        4,
        2,
        2,
        0,
        1,
        2,
        0,
        4,
        3,
        0,
        0,
        2,
        1,
        0,
        1,
        3,
        1,
        0,
        0,
        3,
        0,
        0,
        3,
        1,
        4,
        0,
        0,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        4,
        3,
        0,
        0,
        3,
        4,
        1,
        1,
        1,
        4,
        3,
        1,
        0,
        2,
        2,
        2,
        4,
        1,
        0,
        4,
        4,
        0,
        1,
        0,
        0,
        2,
        1,
        1,
        2,
        4,
        0,
        3,
        0,
        2,
        0,
        4,
        4,
        4,
        0,
        0,
        1,
        4,
        0,
        1,
        2,
        4,
        1,
        2,
        1,
        0,
        4,
        3
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        4,
        2,
        1,
        4,
        0,
        2,
        3,
        0,
        2,
        0,
        2,
        3,
        0,
        0,
        4,
        1,
        0,
        0,
        2,
        1,
        4,
        0,
        4,
        2,
        0,
        0,
        0,
        2,
        4,
        3,
        4,
        2,
        0,
        0,
        3,
        2,
        4,
        0,
        0,
        0,
        3,
        2,
        4,
        2,
        4,
        2,
        0,
        0,
        4,
        4,
        0,
        2
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        1,
        4,
        0,
        0,
        4,
        3,
        0,
        2,
        4,
        0,
        2,
        2,
        0,
        1,
        3,
        3,
        3,
        4,
        0,
        4,
        2,
        2,
        3,
        0,
        2,
        2,
        2,
        1,
        4,
        1,
        0,
        3,
        0,
        0,
        2,
        2,
        4,
        2,
        4,
        1,
        4,
        1,
        2,
        4,
        0,
        0,
        4,
        0,
        0,
        3,
        1,
        4
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        4,
        0,
        0,
        1,
        2,
        2,
        1,
        1,
        3,
        0,
        3,
        4,
        2,
        0,
        3,
        4,
        3,
        3,
        1,
        0,
        0,
        0,
        3,
        0,
        0,
        0,
        1,
        1,
        3,
        4,
        3,
        3,
        3,
        0,
        4,
        1,
        3,
        4,
        0,
        0,
        4,
        4,
        0,
        4,
        4,
        0,
        3,
        4,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "E491B04D",
      "weeklyDisrupted": [
        4,
        1,
        3,
        1,
        3,
        4,
        0,
        0,
        4,
        1,
        4,
        0,
        0,
        4,
        2,
        3,
        3,
        1,
        0,
        4,
        3,
        0,
        2,
        0,
        2,
        0,
        0,
        2,
        2,
        0,
        1,
        1,
        2,
        1,
        1,
        4,
        3,
        3,
        2,
        0,
        1,
        2,
        0,
        4,
        0,
        4,
        2,
        1,
        2,
        0,
        0,
        2
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        2,
        0,
        0,
        2,
        0,
        3,
        1,
        1,
        0,
        3,
        0,
        0,
        1,
        0,
        3,
        2,
        0,
        2,
        2,
        2,
        3,
        1,
        0,
        1,
        0,
        2,
        4,
        1,
        0,
        2,
        0,
        4,
        2,
        0,
        1,
        3,
        3,
        1,
        3,
        1,
        2,
        2,
        3,
        2,
        0,
        1,
        1,
        1,
        2,
        4,
        4,
        0
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        2,
        2,
        2,
        4,
        4,
        0,
        1,
        0,
        1,
        0,
        4,
        4,
        4,
        4,
        2,
        0,
        4,
        3,
        2,
        0,
        1,
        0,
        4,
        1,
        0,
        0,
        0,
        1,
        2,
        2,
        1,
        2,
        4,
        1,
        3,
        3,
        1,
        0,
        4,
        1,
        4,
        4,
        0,
        4,
        2,
        4,
        4,
        4,
        2,
        4,
        0,
        2
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        1,
        2,
        2,
        1,
        2,
        3,
        0,
        3,
        1,
        4,
        3,
        4,
        4,
        3,
        0,
        4,
        4,
        2,
        3,
        4,
        0,
        1,
        0,
        1,
        0,
        4,
        0,
        1,
        0,
        1,
        0,
        2,
        0,
        0,
        3,
        0,
        4,
        2,
        4,
        2,
        2,
        2,
        1,
        4,
        1,
        3,
        3,
        1,
        0,
        2,
        3,
        4
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        0,
        3,
        1,
        1,
        4,
        1,
        4,
        0,
        3,
        1,
        4,
        1,
        0,
        2,
        0,
        3,
        0,
        0,
        0,
        2,
        2,
        3,
        0,
        4,
        0,
        0,
        2,
        2,
        2,
        3,
        0,
        4,
        0,
        0,
        2,
        4,
        4,
        4,
        2,
        0,
        3,
        1,
        0,
        3,
        3,
        3,
        4,
        3,
        4,
        4,
        2,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        2,
        0,
        4,
        3,
        0,
        4,
        0,
        0,
        3,
        0,
        4,
        0,
        3,
        3,
        0,
        2,
        2,
        0,
        0,
        2,
        2,
        4,
        4,
        3,
        2,
        4,
        0,
        2,
        3,
        3,
        0,
        2,
        2,
        0,
        4,
        1,
        3,
        4,
        0,
        0,
        4,
        2,
        3,
        3,
        3,
        4,
        1,
        0,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        3,
        1,
        2,
        0,
        0,
        0,
        0,
        3,
        1,
        0,
        4,
        4,
        3,
        2,
        0,
        4,
        2,
        0,
        1,
        3,
        1,
        0,
        1,
        1,
        0,
        1,
        0,
        0,
        2,
        2,
        0,
        3,
        2,
        0,
        0,
        3,
        1,
        4,
        0,
        0,
        2,
        1,
        0,
        4,
        4,
        2,
        0,
        2,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "E0A5E11A",
      "weeklyDisrupted": [
        2,
        0,
        2,
        4,
        2,
        4,
        2,
        3,
        4,
        3,
        4,
        1,
        2,
        0,
        2,
        0,
        0,
        2,
        0,
        0,
        0,
        2,
        3,
        4,
        0,
        0,
        4,
        1,
        0,
        0,
        0,
        3,
        4,
        2,
        3,
        0,
        2,
        4,
        3,
        0,
        2,
        2,
        0,
        0,
        0,
        4,
        0,
        0,
        1,
        0,
        1,
        0
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        1,
        0,
        0,
        3,
        0,
        0,
        2,
        3,
        3,
        0,
        2,
        4,
        2,
        3,
        1,
        2,
        2,
        2,
        1,
        3,
        2,
        4,
        3,
        3,
        1,
        4,
        2,
        0,
        1,
        0,
        0,
        3,
        4,
        1,
        2,
        3,
        4,
        1,
        0,
        0,
        1,
        2,
        4,
        0,
        0,
        4,
        0,
        0,
        3,
        3,
        4,
        3
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        4,
        2,
        3,
        0,
        0,
        2,
        1,
        2,
        2,
        0,
        4,
        2,
        1,
        1,
        2,
        3,
        2,
        4,
        0,
        2,
        1,
        3,
        0,
        0,
        0,
        3,
        2,
        0,
        3,
        0,
        0,
        4,
        2,
        1,
        0,
        1,
        4,
        3,
        1,
        4,
        1,
        1,
        1,
        0,
        2,
        2,
        0,
        1,
        3,
        1,
        2,
        3
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyDisrupted": [
        3,
        2,
        1,
        4,
        0,
        3,
        0,
        3,
        4,
        2,
        4,
        0,
        4,
        0,
        4,
        0,
        1,
        3,
        0,
        4,
        2,
        2,
        4,
        3,
        4,
        4,
        4,
        2,
        4,
        3,
        4,
        4,
        2,
        0,
        3,
        4,
        3,
        3,
        1,
        0,
        3,
        2,
        0,
        2,
        1,
        2,
        0,
        0,
        0,
        4,
        1,
        3
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        0,
        2,
        4,
        0,
        4,
        0,
        1,
        3,
        1,
        2,
        4,
        2,
        1,
        2,
        3,
        4,
        2,
        4,
        4,
        2,
        4,
        3,
        0,
        1,
        2,
        1,
        2,
        2,
        0,
        0,
        3,
        4,
        2,
        0,
        0,
        4,
        3,
        2,
        1,
        2,
        4,
        0,
        4,
        2,
        1,
        1,
        2,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        3,
        0,
        4,
        4,
        0,
        3,
        0,
        1,
        4,
        3,
        2,
        4,
        3,
        1,
        1,
        2,
        1,
        0,
        0,
        1,
        0,
        2,
        4,
        1,
        1,
        4,
        2,
        0,
        1,
        2,
        4,
        4,
        1,
        2,
        2,
        1,
        0,
        4,
        3,
        0,
        0,
        1,
        4,
        4,
        1,
        3,
        1,
        2,
        0,
        4,
        0,
        2
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        4,
        2,
        1,
        2,
        0,
        0,
        0,
        2,
        2,
        4,
        4,
        2,
        3,
        1,
        2,
        0,
        3,
        1,
        2,
        4,
        0,
        4,
        3,
        2,
        0,
        0,
        1,
        0,
        2,
        3,
        4,
        4,
        4,
        2,
        2,
        4,
        4,
        2,
        0,
        3,
        0,
        0,
        0,
        0,
        0,
        4,
        1,
        3,
        4,
        4,
        2,
        0
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        4,
        2,
        2,
        0,
        2,
        1,
        0,
        1,
        2,
        3,
        4,
        4,
        3,
        0,
        3,
        3,
        3,
        0,
        0,
        3,
        4,
        1,
        1,
        3,
        3,
        4,
        0,
        0,
        0,
        4,
        3,
        4,
        4,
        0,
        1,
        2,
        3,
        3,
        0,
        0,
        0,
        2,
        3,
        4,
        2,
        4,
        2,
        2,
        2,
        4,
        3,
        1
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        0,
        0,
        4,
        3,
        2,
        1,
        2,
        3,
        2,
        1,
        3,
        4,
        4,
        2,
        0,
        3,
        1,
        1,
        0,
        1,
        2,
        0,
        4,
        1,
        1,
        0,
        2,
        0,
        1,
        1,
        0,
        3,
        0,
        1,
        0,
        0,
        0,
        0,
        4,
        0,
        0,
        3,
        1,
        2,
        2,
        4,
        4,
        0,
        2,
        3,
        2,
        1
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        3,
        2,
        4,
        3,
        3,
        0,
        1,
        0,
        4,
        3,
        0,
        3,
        4,
        4,
        4,
        3,
        4,
        0,
        0,
        4,
        4,
        4,
        4,
        3,
        0,
        0,
        0,
        1,
        2,
        0,
        3,
        4,
        3,
        4,
        4,
        1,
        3,
        4,
        1,
        2,
        2,
        2,
        0,
        3,
        3,
        4,
        0,
        4,
        0,
        2,
        3,
        4
      ]
    },
    {
      "id": "DB07BD66",
      "weeklyDisrupted": [
        0,
        0,
        0,
        2,
        0,
        3,
        3,
        3,
        4,
        0,
        0,
        0,
        4,
        1,
        3,
        1,
        0,
        3,
        0,
        4,
        0,
        2,
        2,
        4,
        2,
        4,
        0,
        1,
        2,
        0,
        3,
        0,
        0,
        0,
        3,
        0,
        2,
        4,
        1,
        1,
        4,
        2,
        2,
        4,
        3,
        3,
        1,
        3,
        3,
        2,
        1,
        1
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        1,
        2,
        1,
        4,
        0,
        2,
        0,
        1,
        0,
        1,
        4,
        4,
        4,
        4,
        3,
        1,
        1,
        2,
        1,
        3,
        2,
        0,
        4,
        2,
        0,
        2,
        0,
        0,
        2,
        3,
        4,
        2,
        4,
        2,
        3,
        4,
        4,
        2,
        2,
        1,
        3,
        4,
        1,
        3,
        0,
        0,
        2,
        1,
        3,
        1,
        3,
        4
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        3,
        4,
        0,
        2,
        1,
        2,
        0,
        1,
        4,
        0,
        4,
        2,
        2,
        0,
        1,
        4,
        0,
        4,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        4,
        1,
        0,
        4,
        3,
        0,
        3,
        3,
        1,
        0,
        4,
        4,
        2,
        0,
        0,
        4,
        2,
        3,
        4,
        2,
        0,
        2,
        0,
        4,
        3,
        3,
        0
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        2,
        0,
        2,
        0,
        4,
        2,
        2,
        0,
        4,
        0,
        4,
        4,
        0,
        3,
        2,
        0,
        2,
        2,
        1,
        1,
        3,
        0,
        0,
        0,
        2,
        4,
        2,
        0,
        4,
        0,
        1,
        1,
        0,
        0,
        2,
        4,
        4,
        1,
        0,
        3,
        4,
        2,
        0,
        3,
        1,
        2,
        0,
        4,
        3,
        0,
        2,
        4
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        1,
        0,
        0,
        4,
        0,
        4,
        1,
        4,
        1,
        2,
        3,
        4,
        1,
        1,
        3,
        4,
        1,
        0,
        2,
        2,
        2,
        0,
        0,
        2,
        0,
        2,
        0,
        0,
        1,
        4,
        4,
        3,
        1,
        0,
        4,
        4,
        4,
        0,
        2,
        0,
        4,
        2,
        0,
        4,
        3,
        0,
        0,
        0,
        4,
        4,
        3,
        4
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        4,
        4,
        1,
        3,
        3,
        1,
        2,
        4,
        4,
        0,
        0,
        4,
        2,
        4,
        3,
        4,
        3,
        1,
        2,
        1,
        3,
        2,
        4,
        1,
        0,
        3,
        1,
        2,
        2,
        1,
        0,
        2,
        0,
        0,
        0,
        2,
        3,
        4,
        3,
        4,
        4,
        4,
        1,
        3,
        3,
        4,
        4,
        0,
        1,
        0,
        2,
        1
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        1,
        0,
        0,
        3,
        0,
        3,
        3,
        0,
        1,
        3,
        0,
        0,
        4,
        0,
        4,
        2,
        4,
        0,
        0,
        3,
        4,
        3,
        3,
        2,
        0,
        2,
        4,
        2,
        4,
        4,
        3,
        4,
        2,
        0,
        0,
        4,
        2,
        4,
        2,
        4,
        2,
        4,
        1,
        3,
        3,
        4,
        4,
        1,
        4,
        0,
        2,
        0
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        4,
        3,
        0,
        4,
        0,
        0,
        0,
        2,
        3,
        0,
        2,
        2,
        2,
        4,
        2,
        1,
        1,
        3,
        3,
        3,
        0,
        2,
        2,
        2,
        0,
        0,
        0,
        1,
        0,
        2,
        2,
        3,
        4,
        4,
        3,
        2,
        4,
        2,
        1,
        3,
        4,
        4,
        1,
        2,
        4,
        4,
        3,
        2,
        0,
        2,
        4,
        4
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        4,
        2,
        3,
        4,
        0,
        4,
        1,
        4,
        4,
        0,
        3,
        1,
        1,
        2,
        2,
        2,
        1,
        0,
        3,
        1,
        3,
        3,
        0,
        1,
        0,
        0,
        3,
        1,
        2,
        3,
        0,
        4,
        0,
        4,
        2,
        1,
        0,
        0,
        0,
        1,
        3,
        0,
        1,
        2,
        1,
        4,
        1,
        2,
        2,
        2,
        1,
        1
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyDisrupted": [
        4,
        2,
        3,
        4,
        3,
        4,
        1,
        0,
        3,
        4,
        3,
        1,
        2,
        3,
        2,
        1,
        3,
        0,
        0,
        0,
        2,
        1,
        4,
        1,
        0,
        4,
        0,
        4,
        2,
        4,
        3,
        3,
        4,
        4,
        1,
        4,
        4,
        4,
        1,
        1,
        4,
        4,
        0,
        4,
        4,
        4,
        0,
        1,
        4,
        3,
        2,
        3
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        2,
        1,
        0,
        3,
        0,
        4,
        2,
        3,
        3,
        0,
        4,
        0,
        4,
        0,
        4,
        1,
        4,
        2,
        0,
        0,
        0,
        3,
        4,
        2,
        0,
        3,
        0,
        1,
        2,
        3,
        4,
        3,
        1,
        2,
        3,
        3,
        4,
        1,
        0,
        0,
        4,
        4,
        0,
        3,
        4,
        3,
        0,
        3,
        4,
        3,
        2,
        4
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        3,
        0,
        0,
        4,
        0,
        2,
        4,
        2,
        1,
        4,
        1,
        4,
        2,
        4,
        4,
        0,
        0,
        4,
        0,
        3,
        4,
        4,
        4,
        0,
        0,
        0,
        0,
        2,
        2,
        0,
        3,
        4,
        2,
        1,
        1,
        2,
        2,
        2,
        2,
        0,
        4,
        2,
        1,
        1,
        0,
        4,
        0,
        1,
        2,
        2,
        1,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        4,
        2,
        4,
        3,
        0,
        0,
        4,
        4,
        4,
        3,
        2,
        0,
        4,
        2,
        2,
        4,
        3,
        2,
        3,
        3,
        4,
        4,
        4,
        0,
        0,
        4,
        0,
        0,
        3,
        4,
        4,
        4,
        2,
        3,
        2,
        4,
        4,
        4,
        0,
        3,
        2,
        1,
        0,
        0,
        2,
        4,
        0,
        4,
        0,
        1,
        2,
        4
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        3,
        4,
        2,
        1,
        1,
        4,
        0,
        3,
        4,
        4,
        4,
        3,
        1,
        2,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        0,
        3,
        4,
        0,
        4,
        3,
        0,
        0,
        2,
        4,
        4,
        4,
        1,
        1,
        4,
        4,
        3,
        4,
        1,
        2,
        3,
        1,
        4,
        3,
        4,
        3,
        0,
        3,
        3,
        4,
        4
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        4,
        3,
        1,
        1,
        0,
        4,
        2,
        3,
        2,
        3,
        4,
        2,
        1,
        2,
        4,
        1,
        4,
        4,
        1,
        1,
        0,
        1,
        0,
        0,
        0,
        4,
        0,
        1,
        4,
        0,
        0,
        3,
        4,
        0,
        4,
        2,
        2,
        4,
        3,
        0,
        4,
        3,
        3,
        3,
        1,
        1,
        4,
        1,
        1,
        4,
        3,
        4
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        3,
        0,
        4,
        2,
        0,
        4,
        2,
        4,
        2,
        0,
        3,
        4,
        4,
        0,
        4,
        4,
        2,
        0,
        2,
        0,
        0,
        2,
        2,
        4,
        0,
        2,
        0,
        2,
        4,
        4,
        4,
        3,
        4,
        0,
        4,
        4,
        4,
        4,
        2,
        3,
        0,
        2,
        0,
        4,
        2,
        0,
        0,
        1,
        3,
        1,
        0,
        0
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        3,
        0,
        3,
        1,
        0,
        4,
        2,
        3,
        1,
        0,
        0,
        4,
        0,
        3,
        4,
        4,
        3,
        2,
        3,
        0,
        0,
        0,
        2,
        0,
        0,
        4,
        0,
        1,
        0,
        3,
        0,
        1,
        2,
        1,
        2,
        2,
        4,
        4,
        0,
        0,
        4,
        2,
        2,
        3,
        1,
        0,
        0,
        0,
        4,
        0,
        2,
        3
      ]
    },
    {
      "id": "4FF22342",
      "weeklyDisrupted": [
        3,
        3,
        1,
        0,
        1,
        0,
        0,
        1,
        3,
        3,
        3,
        4,
        3,
        4,
        4,
        4,
        3,
        3,
        3,
        1,
        4,
        0,
        0,
        0,
        0,
        1,
        0,
        3,
        2,
        2,
        0,
        4,
        0,
        0,
        0,
        0,
        2,
        4,
        4,
        1,
        4,
        1,
        4,
        1,
        0,
        1,
        4,
        2,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "79A6C1CA",
      "weeklyDisrupted": [
        4,
        4,
        0,
        0,
        4,
        3,
        0,
        0,
        0,
        2,
        4,
        2,
        0,
        3,
        2,
        4,
        3,
        4,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        0,
        2,
        2,
        4,
        4,
        2,
        4,
        4,
        0,
        0,
        2,
        1,
        2,
        4,
        0,
        2,
        3,
        4,
        4,
        1,
        4,
        4,
        0,
        3,
        4,
        0,
        2
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        3,
        1,
        3,
        4,
        0,
        3,
        1,
        4,
        2,
        4,
        0,
        0,
        3,
        1,
        0,
        2,
        0,
        0,
        4,
        2,
        4,
        3,
        2,
        0,
        0,
        2,
        2,
        2,
        2,
        0,
        4,
        2,
        1,
        2,
        1,
        1,
        1,
        4,
        2,
        1,
        3,
        3,
        0,
        4,
        0,
        2,
        4,
        1,
        2,
        2,
        4,
        4
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        3,
        0,
        3,
        4,
        2,
        3,
        0,
        4,
        2,
        0,
        1,
        0,
        0,
        4,
        4,
        4,
        3,
        3,
        0,
        4,
        4,
        0,
        0,
        3,
        0,
        2,
        0,
        0,
        4,
        3,
        0,
        4,
        0,
        0,
        2,
        4,
        1,
        4,
        4,
        0,
        4,
        4,
        0,
        4,
        0,
        1,
        0,
        1,
        1,
        1,
        1,
        4
      ]
    },
    {
      "id": "D33377F5",
      "weeklyDisrupted": [
        4,
        4,
        1,
        2,
        2,
        4,
        3,
        3,
        4,
        4,
        0,
        0,
        2,
        0,
        4,
        1,
        4,
        2,
        1,
        3,
        1,
        3,
        3,
        1,
        1,
        4,
        1,
        4,
        0,
        3,
        1,
        0,
        2,
        0,
        2,
        0,
        0,
        2,
        4,
        0,
        4,
        2,
        4,
        4,
        0,
        4,
        1,
        4,
        1,
        2,
        2,
        2
      ]
    },
    {
      "id": "049BCC88",
      "weeklyDisrupted": [
        0,
        4,
        2,
        4,
        2,
        3,
        0,
        2,
        3,
        3,
        4,
        4,
        1,
        4,
        3,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        1,
        4,
        0,
        4,
        0,
        2,
        4,
        1,
        4,
        0,
        4,
        2,
        4,
        3,
        4,
        4,
        1,
        2,
        0,
        3,
        2,
        0,
        1,
        4,
        0,
        0,
        4,
        0,
        0,
        0
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        1,
        0,
        1,
        4,
        1,
        3,
        2,
        2,
        4,
        4,
        2,
        0,
        0,
        4,
        3,
        4,
        2,
        1,
        0,
        0,
        4,
        4,
        0,
        1,
        0,
        1,
        2,
        3,
        1,
        0,
        0,
        4,
        2,
        4,
        0,
        3,
        0,
        2,
        0,
        1,
        1,
        3,
        3,
        3,
        0,
        2,
        0,
        0,
        3,
        1,
        3,
        4
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        1,
        0,
        1,
        3,
        4,
        1,
        2,
        1,
        0,
        4,
        4,
        2,
        3,
        4,
        1,
        4,
        2,
        0,
        1,
        4,
        0,
        0,
        4,
        1,
        1,
        4,
        1,
        4,
        0,
        4,
        4,
        4,
        4,
        3,
        1,
        2,
        1,
        4,
        4,
        0,
        0,
        3,
        1,
        0,
        1,
        4,
        2,
        2,
        4,
        4,
        0,
        3
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        2,
        3,
        2,
        4,
        0,
        0,
        1,
        0,
        4,
        4,
        3,
        2,
        3,
        4,
        1,
        4,
        3,
        0,
        1,
        0,
        0,
        0,
        2,
        0,
        0,
        4,
        2,
        4,
        2,
        2,
        2,
        4,
        4,
        3,
        1,
        4,
        4,
        4,
        3,
        1,
        1,
        1,
        4,
        2,
        3,
        1,
        2,
        0,
        0,
        4,
        1,
        4
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        3,
        0,
        2,
        3,
        0,
        2,
        0,
        1,
        0,
        1,
        0,
        4,
        4,
        3,
        2,
        0,
        1,
        0,
        2,
        4,
        2,
        2,
        4,
        1,
        0,
        3,
        4,
        1,
        0,
        4,
        3,
        4,
        4,
        0,
        4,
        2,
        4,
        4,
        0,
        0,
        2,
        4,
        2,
        3,
        1,
        3,
        0,
        2,
        1,
        3,
        4,
        0
      ]
    },
    {
      "id": "099FDC48",
      "weeklyDisrupted": [
        4,
        4,
        3,
        0,
        2,
        3,
        0,
        1,
        4,
        4,
        0,
        4,
        0,
        4,
        4,
        2,
        4,
        3,
        2,
        4,
        0,
        3,
        4,
        3,
        2,
        1,
        0,
        1,
        3,
        1,
        2,
        3,
        0,
        1,
        2,
        2,
        4,
        0,
        2,
        0,
        1,
        4,
        0,
        2,
        0,
        2,
        0,
        1,
        4,
        4,
        4,
        3
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        0,
        2,
        1,
        3,
        0,
        2,
        2,
        1,
        2,
        0,
        4,
        1,
        0,
        1,
        4,
        0,
        2,
        3,
        2,
        1,
        0,
        3,
        4,
        2,
        2,
        2,
        0,
        4,
        2,
        4,
        4,
        2,
        0,
        0,
        0,
        2,
        3,
        4,
        0,
        2,
        4,
        1,
        3,
        4,
        3,
        0,
        0,
        2,
        1,
        2,
        2,
        2
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        3,
        2,
        0,
        2,
        2,
        0,
        3,
        4,
        4,
        2,
        4,
        4,
        1,
        0,
        0,
        2,
        2,
        1,
        2,
        2,
        4,
        1,
        2,
        0,
        4,
        2,
        0,
        1,
        0,
        3,
        2,
        0,
        1,
        2,
        2,
        2,
        4,
        1,
        0,
        4,
        2,
        2,
        0,
        2,
        4,
        4,
        2,
        1,
        0,
        2,
        4
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        1,
        1,
        2,
        1,
        0,
        3,
        0,
        4,
        4,
        0,
        3,
        0,
        3,
        0,
        1,
        1,
        1,
        0,
        1,
        0,
        0,
        2,
        0,
        2,
        4,
        4,
        0,
        0,
        0,
        2,
        0,
        4,
        4,
        0,
        4,
        4,
        3,
        4,
        2,
        0,
        4,
        4,
        1,
        4,
        3,
        2,
        0,
        0,
        4,
        0,
        3,
        1
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        0,
        1,
        4,
        3,
        3,
        1,
        0,
        4,
        0,
        1,
        4,
        0,
        2,
        0,
        4,
        1,
        3,
        0,
        4,
        2,
        0,
        3,
        4,
        0,
        0,
        0,
        1,
        0,
        3,
        4,
        0,
        4,
        1,
        0,
        0,
        3,
        4,
        0,
        1,
        0,
        3,
        2,
        2,
        3,
        0,
        4,
        4,
        0,
        1,
        2,
        3,
        4
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        4,
        3,
        3,
        4,
        2,
        0,
        2,
        0,
        0,
        2,
        1,
        2,
        0,
        3,
        0,
        0,
        1,
        4,
        0,
        4,
        2,
        1,
        3,
        0,
        0,
        0,
        1,
        2,
        4,
        4,
        1,
        1,
        0,
        0,
        0,
        4,
        0,
        3,
        1,
        0,
        3,
        0,
        2,
        1,
        3,
        1,
        0,
        0,
        4,
        2,
        0,
        1
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyDisrupted": [
        4,
        1,
        0,
        1,
        0,
        3,
        4,
        0,
        2,
        2,
        3,
        0,
        3,
        4,
        2,
        2,
        3,
        4,
        2,
        4,
        2,
        4,
        0,
        2,
        0,
        1,
        2,
        1,
        1,
        0,
        0,
        4,
        1,
        4,
        2,
        4,
        4,
        4,
        0,
        0,
        4,
        3,
        0,
        1,
        4,
        0,
        0,
        1,
        4,
        3,
        2,
        0
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        1,
        1,
        4,
        4,
        4,
        4,
        1,
        2,
        3,
        4,
        3,
        4,
        1,
        1,
        2,
        2,
        0,
        0,
        0,
        4,
        4,
        2,
        4,
        0,
        0,
        2,
        4,
        3,
        4,
        4,
        1,
        4,
        0,
        3,
        0,
        2,
        3,
        0,
        2,
        0,
        1,
        4,
        2,
        3,
        2,
        4,
        0,
        4,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        2,
        2,
        4,
        3,
        2,
        2,
        0,
        4,
        4,
        0,
        0,
        1,
        3,
        3,
        2,
        2,
        1,
        0,
        4,
        4,
        0,
        4,
        0,
        1,
        0,
        0,
        2,
        0,
        2,
        2,
        2,
        0,
        3,
        3,
        0,
        2,
        4,
        0,
        1,
        0,
        0,
        2,
        2,
        4,
        2,
        2,
        0,
        0,
        4,
        4,
        4,
        3
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        1,
        1,
        4,
        2,
        0,
        3,
        0,
        0,
        2,
        1,
        1,
        0,
        3,
        1,
        2,
        2,
        3,
        1,
        1,
        4,
        4,
        4,
        2,
        4,
        0,
        3,
        0,
        0,
        3,
        2,
        2,
        4,
        0,
        0,
        3,
        3,
        2,
        1,
        0,
        0,
        3,
        4,
        0,
        0,
        1,
        3,
        1,
        0,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        3,
        1,
        4,
        3,
        2,
        4,
        0,
        0,
        4,
        4,
        2,
        4,
        4,
        0,
        2,
        4,
        1,
        0,
        0,
        4,
        1,
        3,
        3,
        0,
        0,
        2,
        0,
        0,
        3,
        1,
        0,
        4,
        1,
        1,
        1,
        4,
        3,
        0,
        3,
        3,
        4,
        0,
        0,
        2,
        1,
        1,
        0,
        4,
        4,
        2,
        2,
        4
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        4,
        2,
        1,
        2,
        2,
        4,
        0,
        2,
        4,
        1,
        3,
        4,
        4,
        0,
        3,
        3,
        4,
        0,
        0,
        2,
        1,
        4,
        4,
        0,
        0,
        0,
        0,
        0,
        2,
        4,
        4,
        0,
        2,
        0,
        1,
        1,
        3,
        4,
        1,
        0,
        1,
        4,
        2,
        4,
        3,
        2,
        4,
        0,
        2,
        2,
        4,
        2
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        4,
        4,
        3,
        4,
        0,
        2,
        0,
        4,
        1,
        1,
        3,
        2,
        3,
        4,
        3,
        1,
        4,
        1,
        3,
        1,
        4,
        0,
        2,
        0,
        0,
        2,
        2,
        0,
        4,
        3,
        0,
        4,
        2,
        1,
        0,
        3,
        4,
        2,
        3,
        0,
        2,
        4,
        0,
        4,
        3,
        4,
        4,
        0,
        2,
        0,
        4,
        0
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        4,
        3,
        1,
        3,
        3,
        0,
        0,
        3,
        4,
        4,
        4,
        3,
        4,
        4,
        1,
        3,
        1,
        0,
        2,
        0,
        0,
        3,
        2,
        0,
        0,
        3,
        1,
        4,
        4,
        4,
        3,
        0,
        2,
        2,
        3,
        2,
        4,
        4,
        0,
        2,
        4,
        0,
        2,
        4,
        2,
        0,
        4,
        0,
        0,
        0,
        4,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        0,
        3,
        3,
        1,
        1,
        4,
        1,
        1,
        1,
        3,
        4,
        2,
        0,
        3,
        3,
        4,
        2,
        2,
        0,
        2,
        4,
        4,
        0,
        1,
        0,
        3,
        0,
        0,
        3,
        2,
        3,
        4,
        4,
        0,
        1,
        2,
        1,
        1,
        1,
        0,
        3,
        3,
        0,
        2,
        2,
        2,
        0,
        1,
        2,
        0,
        3,
        0
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        3,
        4,
        2,
        3,
        2,
        3,
        0,
        3,
        0,
        0,
        4,
        1,
        4,
        2,
        3,
        2,
        2,
        0,
        0,
        0,
        0,
        0,
        4,
        1,
        2,
        3,
        0,
        0,
        4,
        3,
        2,
        2,
        2,
        3,
        1,
        2,
        3,
        0,
        4,
        1,
        2,
        4,
        2,
        2,
        4,
        2,
        0,
        0,
        4,
        3,
        2,
        4
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        4,
        2,
        3,
        1,
        0,
        4,
        0,
        4,
        4,
        2,
        0,
        1,
        4,
        1,
        4,
        1,
        0,
        2,
        4,
        4,
        2,
        4,
        2,
        0,
        0,
        4,
        0,
        1,
        1,
        2,
        1,
        3,
        3,
        1,
        4,
        1,
        4,
        4,
        1,
        1,
        3,
        2,
        1,
        0,
        2,
        3,
        3,
        4,
        4,
        4,
        4,
        0
      ]
    },
    {
      "id": "4AE85F20",
      "weeklyDisrupted": [
        3,
        0,
        0,
        2,
        2,
        3,
        0,
        0,
        1,
        0,
        4,
        0,
        1,
        0,
        2,
        2,
        2,
        0,
        0,
        0,
        0,
        0,
        2,
        1,
        4,
        3,
        0,
        1,
        2,
        4,
        2,
        1,
        4,
        0,
        0,
        2,
        4,
        0,
        1,
        0,
        1,
        2,
        4,
        4,
        1,
        1,
        1,
        0,
        2,
        3,
        3,
        4
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        1,
        0,
        0,
        4,
        1,
        3,
        4,
        0,
        2,
        2,
        3,
        0,
        2,
        1,
        2,
        1,
        4,
        0,
        3,
        3,
        4,
        4,
        4,
        2,
        0,
        1,
        1,
        4,
        0,
        2,
        1,
        4,
        4,
        2,
        1,
        3,
        3,
        4,
        0,
        1,
        1,
        4,
        3,
        1,
        1,
        0,
        0,
        0,
        3,
        0,
        3,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        1,
        0,
        1,
        2,
        2,
        4,
        0,
        3,
        1,
        2,
        2,
        3,
        0,
        2,
        0,
        3,
        0,
        0,
        4,
        2,
        1,
        4,
        0,
        4,
        0,
        1,
        0,
        1,
        0,
        0,
        3,
        3,
        3,
        2,
        3,
        4,
        4,
        4,
        0,
        0,
        3,
        2,
        0,
        4,
        1,
        2,
        0,
        0,
        4,
        2,
        1,
        2
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        2,
        4,
        3,
        3,
        4,
        2,
        4,
        0,
        3,
        4,
        0,
        2,
        3,
        1,
        2,
        2,
        1,
        0,
        0,
        1,
        4,
        2,
        3,
        0,
        2,
        3,
        0,
        0,
        0,
        4,
        3,
        3,
        0,
        0,
        0,
        4,
        2,
        2,
        3,
        0,
        3,
        3,
        1,
        4,
        3,
        4,
        0,
        3,
        0,
        1,
        4,
        4
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        4,
        1,
        1,
        2,
        4,
        2,
        3,
        4,
        4,
        3,
        4,
        2,
        4,
        3,
        2,
        3,
        1,
        2,
        2,
        0,
        4,
        0,
        2,
        0,
        0,
        4,
        0,
        2,
        4,
        3,
        1,
        2,
        2,
        2,
        1,
        4,
        4,
        2,
        3,
        0,
        1,
        3,
        0,
        0,
        0,
        2,
        0,
        2,
        3,
        2,
        3,
        4
      ]
    },
    {
      "id": "867038F2",
      "weeklyDisrupted": [
        4,
        1,
        0,
        0,
        0,
        4,
        4,
        1,
        1,
        0,
        4,
        1,
        2,
        3,
        1,
        2,
        3,
        4,
        3,
        2,
        0,
        2,
        3,
        1,
        3,
        2,
        2,
        4,
        1,
        3,
        3,
        3,
        2,
        4,
        0,
        3,
        1,
        3,
        4,
        3,
        3,
        2,
        1,
        0,
        2,
        2,
        0,
        1,
        4,
        4,
        0,
        0
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        2,
        4,
        0,
        1,
        0,
        4,
        3,
        4,
        2,
        4,
        0,
        2,
        1,
        4,
        2,
        2,
        0,
        4,
        2,
        1,
        4,
        1,
        4,
        2,
        0,
        4,
        0,
        3,
        0,
        2,
        3,
        4,
        3,
        0,
        0,
        0,
        3,
        0,
        0,
        4,
        4,
        2,
        0,
        0,
        1,
        3,
        0,
        0,
        4,
        1,
        1,
        2
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        4,
        3,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        4,
        4,
        4,
        4,
        3,
        1,
        3,
        2,
        3,
        0,
        0,
        0,
        2,
        2,
        1,
        0,
        1,
        0,
        0,
        0,
        1,
        0,
        2,
        4,
        3,
        1,
        3,
        3,
        4,
        0,
        0,
        4,
        4,
        1,
        4,
        1,
        2,
        2,
        3,
        4,
        4,
        3,
        2
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        2,
        1,
        1,
        0,
        3,
        2,
        1,
        1,
        3,
        0,
        3,
        3,
        4,
        4,
        1,
        4,
        1,
        1,
        3,
        0,
        4,
        1,
        2,
        2,
        0,
        2,
        2,
        0,
        0,
        4,
        1,
        4,
        3,
        1,
        4,
        3,
        4,
        4,
        0,
        0,
        3,
        2,
        1,
        3,
        1,
        4,
        0,
        3,
        4,
        4,
        4,
        4
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        3,
        0,
        2,
        1,
        0,
        3,
        2,
        4,
        1,
        0,
        0,
        2,
        0,
        0,
        2,
        1,
        4,
        2,
        2,
        3,
        4,
        0,
        2,
        0,
        0,
        3,
        1,
        2,
        1,
        1,
        0,
        4,
        0,
        1,
        3,
        3,
        3,
        4,
        0,
        2,
        4,
        1,
        0,
        4,
        0,
        3,
        0,
        0,
        0,
        0,
        2,
        0
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        0,
        0,
        0,
        1,
        4,
        0,
        2,
        2,
        2,
        4,
        4,
        4,
        1,
        2,
        2,
        4,
        4,
        4,
        1,
        1,
        1,
        4,
        0,
        2,
        0,
        1,
        0,
        0,
        2,
        1,
        3,
        4,
        2,
        0,
        2,
        3,
        2,
        3,
        3,
        2,
        2,
        1,
        2,
        4,
        0,
        1,
        0,
        1,
        4,
        1,
        1,
        0
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        2,
        1,
        4,
        4,
        1,
        4,
        0,
        3,
        4,
        3,
        3,
        3,
        2,
        3,
        3,
        0,
        2,
        0,
        3,
        1,
        3,
        3,
        2,
        0,
        0,
        4,
        1,
        0,
        1,
        4,
        0,
        3,
        4,
        0,
        0,
        2,
        4,
        3,
        0,
        0,
        4,
        2,
        2,
        0,
        1,
        2,
        1,
        2,
        4,
        2,
        4,
        1
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyDisrupted": [
        3,
        2,
        3,
        1,
        2,
        1,
        2,
        2,
        4,
        0,
        2,
        3,
        3,
        4,
        2,
        2,
        3,
        0,
        1,
        0,
        4,
        4,
        1,
        3,
        0,
        0,
        0,
        0,
        2,
        2,
        3,
        3,
        1,
        0,
        1,
        3,
        0,
        4,
        4,
        1,
        2,
        0,
        0,
        0,
        1,
        4,
        4,
        3,
        4,
        0,
        2,
        0
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        1,
        2,
        1,
        3,
        0,
        4,
        4,
        2,
        3,
        4,
        4,
        4,
        0,
        1,
        2,
        3,
        1,
        4,
        2,
        2,
        3,
        4,
        2,
        4,
        1,
        1,
        2,
        4,
        4,
        1,
        0,
        4,
        3,
        2,
        2,
        4,
        4,
        4,
        0,
        0,
        2,
        2,
        1,
        0,
        4,
        2,
        0,
        2,
        2,
        0,
        0,
        0
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        3,
        2,
        3,
        4,
        2,
        4,
        0,
        4,
        3,
        3,
        2,
        3,
        3,
        0,
        2,
        4,
        3,
        1,
        2,
        1,
        2,
        1,
        4,
        0,
        2,
        0,
        0,
        4,
        0,
        2,
        0,
        3,
        0,
        1,
        4,
        4,
        4,
        3,
        3,
        0,
        4,
        3,
        1,
        3,
        0,
        2,
        4,
        2,
        4,
        4,
        0,
        4
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        0,
        3,
        0,
        4,
        2,
        0,
        0,
        0,
        3,
        0,
        0,
        4,
        3,
        3,
        0,
        0,
        3,
        0,
        2,
        4,
        4,
        3,
        4,
        3,
        2,
        2,
        4,
        3,
        4,
        4,
        1,
        4,
        1,
        2,
        0,
        4,
        0,
        4,
        3,
        1,
        1,
        4,
        1,
        2,
        1,
        4,
        3,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        4,
        4,
        2,
        0,
        2,
        4,
        1,
        4,
        4,
        4,
        1,
        1,
        4,
        3,
        4,
        1,
        3,
        0,
        2,
        0,
        4,
        3,
        2,
        0,
        0,
        1,
        0,
        2,
        3,
        1,
        0,
        4,
        3,
        4,
        0,
        2,
        4,
        0,
        1,
        3,
        3,
        4,
        3,
        3,
        2,
        1,
        0,
        2,
        4,
        4,
        3,
        0
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        4,
        2,
        2,
        0,
        3,
        4,
        0,
        1,
        3,
        2,
        1,
        2,
        4,
        3,
        1,
        4,
        1,
        0,
        0,
        2,
        4,
        4,
        4,
        1,
        0,
        4,
        1,
        1,
        1,
        4,
        3,
        4,
        1,
        3,
        0,
        3,
        2,
        3,
        2,
        1,
        0,
        1,
        0,
        2,
        1,
        4,
        2,
        2,
        4,
        1,
        2,
        0
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyDisrupted": [
        2,
        4,
        2,
        3,
        0,
        1,
        0,
        4,
        1,
        0,
        4,
        1,
        0,
        1,
        0,
        3,
        3,
        0,
        0,
        2,
        2,
        4,
        3,
        2,
        0,
        2,
        0,
        2,
        0,
        0,
        3,
        4,
        0,
        2,
        1,
        2,
        4,
        4,
        1,
        2,
        2,
        2,
        2,
        4,
        2,
        3,
        4,
        0,
        4,
        4,
        3,
        3
      ]
    },
    {
      "id": "F36679BC",
      "weeklyDisrupted": [
        3,
        4,
        1,
        4,
        1,
        4,
        4,
        3,
        2,
        0,
        2,
        0,
        4,
        1,
        1,
        2,
        1,
        0,
        1,
        0,
        1,
        2,
        4,
        2,
        0,
        0,
        0,
        2,
        1,
        0,
        2,
        4,
        4,
        4,
        4,
        1,
        4,
        4,
        3,
        0,
        3,
        2,
        0,
        4,
        0,
        1,
        0,
        2,
        4,
        1,
        0,
        0
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        4,
        4,
        4,
        0,
        0,
        0,
        0,
        3,
        4,
        0,
        1,
        2,
        3,
        4,
        3,
        4,
        3,
        1,
        0,
        0,
        0,
        2,
        2,
        0,
        0,
        3,
        0,
        1,
        1,
        3,
        4,
        2,
        0,
        0,
        3,
        3,
        3,
        2,
        2,
        0,
        3,
        2,
        0,
        1,
        4,
        4,
        2,
        3,
        1,
        0,
        4,
        1
      ]
    },
    {
      "id": "B21A9DE2",
      "weeklyDisrupted": [
        4,
        0,
        1,
        0,
        3,
        4,
        1,
        2,
        2,
        0,
        3,
        4,
        4,
        3,
        4,
        4,
        2,
        0,
        2,
        3,
        1,
        0,
        4,
        2,
        0,
        4,
        1,
        1,
        3,
        4,
        2,
        4,
        0,
        2,
        3,
        3,
        4,
        3,
        0,
        1,
        2,
        3,
        0,
        3,
        3,
        1,
        0,
        4,
        2,
        0,
        0,
        3
      ]
    },
    {
      "id": "3CA906F9",
      "weeklyDisrupted": [
        1,
        1,
        0,
        3,
        1,
        1,
        1,
        3,
        4,
        1,
        4,
        1,
        3,
        4,
        4,
        4,
        3,
        0,
        4,
        1,
        0,
        3,
        3,
        1,
        0,
        1,
        0,
        3,
        3,
        0,
        1,
        3,
        0,
        1,
        0,
        4,
        4,
        0,
        2,
        0,
        0,
        2,
        0,
        4,
        0,
        0,
        2,
        0,
        4,
        2,
        2,
        4
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        0,
        1,
        1,
        2,
        0,
        3,
        1,
        4,
        2,
        1,
        4,
        0,
        1,
        0,
        0,
        3,
        4,
        2,
        0,
        3,
        3,
        0,
        4,
        0,
        2,
        0,
        0,
        0,
        0,
        1,
        1,
        3,
        0,
        0,
        4,
        4,
        3,
        0,
        0,
        1,
        4,
        1,
        2,
        4,
        0,
        1,
        3,
        3,
        2,
        1,
        4,
        2
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        4,
        1,
        0,
        4,
        0,
        2,
        3,
        3,
        3,
        2,
        0,
        0,
        2,
        2,
        2,
        0,
        0,
        1,
        3,
        3,
        4,
        1,
        4,
        0,
        2,
        2,
        1,
        4,
        4,
        4,
        2,
        4,
        3,
        0,
        0,
        3,
        1,
        3,
        3,
        1,
        2,
        3,
        1,
        4,
        0,
        3,
        0,
        1,
        1,
        2,
        0,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        0,
        4,
        0,
        2,
        4,
        3,
        2,
        0,
        4,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        3,
        0,
        4,
        0,
        0,
        3,
        2,
        2,
        4,
        4,
        1,
        3,
        1,
        0,
        1,
        3,
        1,
        0,
        0,
        2,
        0,
        2,
        0,
        2,
        2,
        3,
        0,
        1,
        4,
        0,
        1,
        2
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        3,
        2,
        4,
        4,
        0,
        2,
        0,
        4,
        2,
        2,
        1,
        4,
        4,
        4,
        2,
        3,
        0,
        0,
        3,
        0,
        0,
        4,
        3,
        0,
        0,
        0,
        0,
        0,
        1,
        3,
        2,
        2,
        0,
        4,
        2,
        4,
        4,
        4,
        2,
        0,
        3,
        1,
        0,
        1,
        4,
        2,
        0,
        0,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        4,
        0,
        4,
        1,
        3,
        0,
        0,
        0,
        1,
        2,
        1,
        4,
        2,
        2,
        2,
        0,
        0,
        0,
        1,
        1,
        2,
        1,
        0,
        0,
        0,
        0,
        1,
        2,
        3,
        1,
        0,
        4,
        0,
        0,
        4,
        1,
        4,
        0,
        0,
        0,
        3,
        2,
        0,
        0,
        2,
        2,
        0,
        3,
        0,
        3,
        2,
        4
      ]
    },
    {
      "id": "19A12B20",
      "weeklyDisrupted": [
        4,
        3,
        0,
        1,
        0,
        2,
        1,
        0,
        2,
        1,
        3,
        0,
        4,
        0,
        3,
        4,
        2,
        4,
        4,
        0,
        4,
        2,
        2,
        0,
        0,
        2,
        0,
        1,
        4,
        4,
        0,
        1,
        1,
        0,
        0,
        1,
        2,
        0,
        1,
        1,
        4,
        2,
        0,
        0,
        0,
        4,
        0,
        2,
        0,
        1,
        2,
        2
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        0,
        0,
        0,
        3,
        0,
        4,
        0,
        0,
        4,
        1,
        1,
        4,
        4,
        0,
        1,
        4,
        3,
        0,
        1,
        4,
        3,
        2,
        3,
        1,
        3,
        1,
        1,
        2,
        3,
        3,
        1,
        4,
        0,
        3,
        0,
        4,
        4,
        0,
        0,
        3,
        4,
        1,
        0,
        2,
        3,
        4,
        1,
        2,
        4,
        3,
        0,
        3
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        4,
        2,
        1,
        0,
        1,
        1,
        0,
        1,
        1,
        3,
        0,
        4,
        4,
        1,
        2,
        1,
        1,
        3,
        4,
        1,
        3,
        3,
        2,
        1,
        0,
        1,
        0,
        0,
        0,
        2,
        0,
        1,
        4,
        0,
        1,
        2,
        4,
        0,
        0,
        4,
        2,
        4,
        2,
        0,
        3,
        2,
        0,
        4,
        4,
        2,
        1,
        4
      ]
    },
    {
      "id": "41097027",
      "weeklyDisrupted": [
        4,
        2,
        0,
        0,
        0,
        1,
        0,
        4,
        2,
        3,
        0,
        2,
        1,
        4,
        1,
        4,
        4,
        2,
        3,
        1,
        4,
        2,
        4,
        0,
        0,
        4,
        1,
        0,
        2,
        0,
        0,
        3,
        3,
        2,
        0,
        2,
        4,
        2,
        0,
        0,
        4,
        4,
        1,
        1,
        0,
        3,
        2,
        3,
        3,
        4,
        1,
        0
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        4,
        0,
        1,
        4,
        0,
        3,
        2,
        3,
        0,
        1,
        4,
        1,
        3,
        4,
        3,
        4,
        4,
        1,
        2,
        4,
        4,
        2,
        3,
        4,
        0,
        1,
        3,
        0,
        3,
        0,
        1,
        0,
        0,
        2,
        0,
        3,
        3,
        0,
        2,
        0,
        0,
        4,
        2,
        0,
        3,
        3,
        4,
        2,
        4,
        4,
        2,
        1
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        3,
        3,
        0,
        4,
        0,
        1,
        3,
        3,
        3,
        3,
        4,
        1,
        4,
        1,
        2,
        4,
        3,
        2,
        0,
        0,
        3,
        0,
        3,
        0,
        0,
        4,
        0,
        1,
        3,
        0,
        0,
        1,
        0,
        1,
        1,
        4,
        4,
        4,
        4,
        4,
        3,
        2,
        0,
        4,
        0,
        1,
        3,
        0,
        2,
        4,
        2,
        2
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        4,
        1,
        1,
        4,
        0,
        2,
        2,
        4,
        4,
        0,
        4,
        3,
        4,
        0,
        2,
        0,
        1,
        0,
        3,
        3,
        3,
        2,
        4,
        2,
        2,
        4,
        0,
        3,
        3,
        4,
        3,
        4,
        1,
        2,
        2,
        4,
        4,
        2,
        2,
        0,
        3,
        2,
        3,
        0,
        1,
        4,
        3,
        1,
        4,
        4,
        4,
        0
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        2,
        1,
        2,
        2,
        0,
        1,
        4,
        3,
        3,
        2,
        2,
        1,
        3,
        4,
        2,
        4,
        2,
        0,
        4,
        4,
        3,
        4,
        2,
        0,
        0,
        3,
        2,
        2,
        2,
        2,
        2,
        4,
        2,
        0,
        1,
        3,
        4,
        1,
        1,
        2,
        3,
        2,
        2,
        3,
        4,
        3,
        0,
        4,
        4,
        2,
        0,
        2
      ]
    },
    {
      "id": "E80AAE4A",
      "weeklyDisrupted": [
        0,
        3,
        3,
        2,
        2,
        4,
        4,
        4,
        4,
        4,
        1,
        4,
        2,
        1,
        0,
        1,
        0,
        1,
        1,
        1,
        3,
        2,
        4,
        1,
        0,
        3,
        1,
        1,
        0,
        0,
        1,
        3,
        3,
        0,
        1,
        3,
        1,
        4,
        3,
        1,
        4,
        2,
        2,
        0,
        0,
        2,
        0,
        0,
        4,
        1,
        4,
        2
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        4,
        3,
        0,
        2,
        4,
        2,
        2,
        1,
        0,
        4,
        2,
        0,
        0,
        4,
        2,
        4,
        3,
        3,
        2,
        2,
        0,
        3,
        3,
        0,
        2,
        0,
        4,
        1,
        4,
        4,
        1,
        4,
        3,
        0,
        4,
        2,
        4,
        3,
        2,
        1,
        2,
        3,
        1,
        4,
        0,
        3,
        1,
        3,
        3,
        4,
        4,
        0
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        0,
        1,
        0,
        1,
        3,
        3,
        4,
        0,
        4,
        2,
        2,
        2,
        4,
        3,
        2,
        4,
        2,
        0,
        1,
        2,
        3,
        3,
        4,
        2,
        4,
        3,
        0,
        1,
        1,
        3,
        4,
        2,
        4,
        0,
        3,
        4,
        1,
        4,
        0,
        0,
        0,
        2,
        1,
        2,
        1,
        3,
        2,
        4,
        2,
        2,
        3,
        4
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        0,
        0,
        0,
        3,
        4,
        0,
        4,
        2,
        0,
        3,
        4,
        0,
        3,
        4,
        3,
        4,
        3,
        4,
        2,
        1,
        0,
        4,
        4,
        0,
        0,
        2,
        1,
        0,
        0,
        0,
        3,
        2,
        4,
        0,
        0,
        2,
        2,
        0,
        3,
        1,
        1,
        4,
        3,
        4,
        2,
        3,
        4,
        1,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        0,
        2,
        3,
        3,
        3,
        2,
        4,
        1,
        3,
        3,
        2,
        2,
        2,
        0,
        1,
        4,
        0,
        0,
        0,
        2,
        2,
        2,
        1,
        2,
        0,
        4,
        2,
        2,
        1,
        1,
        4,
        2,
        4,
        4,
        0,
        2,
        3,
        2,
        4,
        4,
        1,
        2,
        1,
        0,
        4,
        4,
        2,
        3
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        0,
        2,
        0,
        4,
        0,
        4,
        0,
        4,
        2,
        0,
        4,
        2,
        3,
        2,
        4,
        2,
        3,
        1,
        3,
        3,
        3,
        1,
        4,
        1,
        1,
        1,
        1,
        4,
        4,
        3,
        0,
        3,
        4,
        0,
        1,
        3,
        4,
        4,
        3,
        0,
        0,
        4,
        1,
        2,
        1,
        1,
        3,
        0,
        2,
        0,
        4,
        1
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        4,
        4,
        4,
        4,
        0,
        1,
        4,
        3,
        3,
        4,
        1,
        3,
        0,
        1,
        4,
        0,
        4,
        2,
        1,
        0,
        1,
        4,
        1,
        2,
        1,
        4,
        0,
        0,
        0,
        0,
        0,
        1,
        4,
        1,
        3,
        1,
        1,
        4,
        1,
        1,
        1,
        3,
        4,
        4,
        0,
        1,
        0,
        3,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        4,
        2,
        1,
        3,
        3,
        2,
        3,
        1,
        1,
        3,
        4,
        3,
        2,
        3,
        2,
        4,
        2,
        2,
        1,
        0,
        1,
        2,
        3,
        1,
        0,
        0,
        2,
        0,
        4,
        4,
        1,
        4,
        4,
        0,
        0,
        4,
        4,
        0,
        2,
        4,
        3,
        2,
        0,
        1,
        1,
        3,
        0,
        2,
        4,
        2,
        3,
        1
      ]
    },
    {
      "id": "C36BF119",
      "weeklyDisrupted": [
        4,
        2,
        1,
        3,
        2,
        0,
        0,
        3,
        3,
        2,
        4,
        3,
        2,
        4,
        4,
        2,
        2,
        0,
        4,
        4,
        4,
        3,
        3,
        1,
        0,
        0,
        0,
        0,
        2,
        1,
        0,
        4,
        4,
        0,
        1,
        4,
        4,
        0,
        3,
        2,
        1,
        2,
        4,
        4,
        0,
        2,
        2,
        1,
        3,
        4,
        4,
        0
      ]
    },
    {
      "id": "E02AF8C4",
      "weeklyDisrupted": [
        2,
        3,
        3,
        3,
        2,
        3,
        0,
        1,
        0,
        4,
        2,
        3,
        4,
        2,
        2,
        0,
        2,
        1,
        2,
        2,
        2,
        0,
        3,
        3,
        0,
        3,
        1,
        0,
        1,
        4,
        0,
        3,
        4,
        0,
        0,
        2,
        3,
        4,
        0,
        0,
        3,
        4,
        3,
        4,
        3,
        2,
        2,
        0,
        2,
        4,
        4,
        1
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        3,
        0,
        4,
        3,
        2,
        2,
        0,
        0,
        4,
        4,
        1,
        4,
        4,
        3,
        1,
        4,
        4,
        1,
        0,
        1,
        3,
        0,
        1,
        0,
        2,
        4,
        0,
        0,
        0,
        1,
        0,
        3,
        0,
        0,
        2,
        2,
        4,
        3,
        1,
        2,
        2,
        3,
        2,
        3,
        1,
        4,
        3,
        2,
        1,
        0,
        0,
        4
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        0,
        4,
        2,
        1,
        4,
        1,
        4,
        2,
        4,
        3,
        4,
        4,
        4,
        3,
        2,
        4,
        3,
        4,
        0,
        2,
        1,
        0,
        1,
        2,
        2,
        2,
        2,
        1,
        4,
        3,
        3,
        3,
        3,
        3,
        2,
        4,
        4,
        4,
        3,
        0,
        2,
        4,
        0,
        1,
        1,
        1,
        3,
        1,
        2,
        0,
        1,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        3,
        0,
        0,
        3,
        0,
        4,
        1,
        2,
        4,
        0,
        0,
        4,
        4,
        4,
        4,
        0,
        2,
        0,
        2,
        0,
        3,
        1,
        3,
        0,
        0,
        1,
        0,
        4,
        2,
        2,
        0,
        1,
        3,
        0,
        3,
        4,
        4,
        4,
        2,
        0,
        4,
        2,
        3,
        4,
        4,
        4,
        1,
        4,
        0,
        1,
        3,
        4
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        0,
        4,
        3,
        4,
        4,
        3,
        0,
        0,
        4,
        4,
        1,
        4,
        4,
        1,
        1,
        0,
        1,
        0,
        0,
        2,
        2,
        2,
        4,
        0,
        1,
        4,
        1,
        0,
        1,
        4,
        2,
        3,
        3,
        0,
        2,
        2,
        4,
        4,
        1,
        1,
        3,
        3,
        3,
        3,
        2,
        4,
        4,
        0,
        0,
        0,
        4,
        4
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        3,
        4,
        0,
        4,
        1,
        0,
        2,
        0,
        1,
        1,
        2,
        1,
        1,
        1,
        2,
        1,
        0,
        0,
        0,
        0,
        3,
        3,
        3,
        3,
        0,
        4,
        0,
        0,
        2,
        4,
        2,
        2,
        2,
        3,
        0,
        3,
        2,
        4,
        4,
        1,
        1,
        3,
        0,
        2,
        4,
        3,
        4,
        0,
        4,
        1,
        3,
        1
      ]
    },
    {
      "id": "F2EF4025",
      "weeklyDisrupted": [
        2,
        2,
        2,
        2,
        1,
        4,
        3,
        3,
        1,
        2,
        4,
        4,
        4,
        2,
        4,
        2,
        3,
        2,
        0,
        4,
        4,
        3,
        3,
        0,
        1,
        3,
        0,
        1,
        4,
        4,
        3,
        4,
        4,
        2,
        4,
        3,
        4,
        3,
        2,
        0,
        3,
        1,
        0,
        2,
        0,
        4,
        1,
        0,
        2,
        0,
        0,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        0,
        2,
        1,
        3,
        2,
        2,
        0,
        4,
        1,
        2,
        2,
        4,
        3,
        4,
        2,
        3,
        0,
        1,
        3,
        0,
        1,
        4,
        4,
        2,
        0,
        4,
        0,
        1,
        0,
        4,
        2,
        2,
        2,
        2,
        3,
        2,
        4,
        3,
        0,
        0,
        3,
        2,
        0,
        2,
        0,
        3,
        1,
        3,
        3,
        0,
        4,
        1
      ]
    },
    {
      "id": "965102D9",
      "weeklyDisrupted": [
        3,
        0,
        2,
        1,
        4,
        4,
        0,
        3,
        1,
        2,
        4,
        3,
        2,
        4,
        2,
        0,
        4,
        4,
        3,
        0,
        1,
        1,
        3,
        4,
        2,
        3,
        0,
        0,
        1,
        0,
        3,
        0,
        1,
        0,
        0,
        2,
        4,
        1,
        3,
        0,
        0,
        3,
        3,
        1,
        0,
        2,
        1,
        0,
        4,
        0,
        0,
        2
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyDisrupted": [
        3,
        0,
        3,
        1,
        1,
        4,
        4,
        1,
        1,
        4,
        1,
        4,
        4,
        1,
        1,
        2,
        3,
        0,
        4,
        4,
        0,
        3,
        0,
        0,
        0,
        1,
        0,
        0,
        4,
        4,
        0,
        2,
        1,
        1,
        3,
        1,
        1,
        2,
        0,
        2,
        0,
        0,
        0,
        3,
        1,
        3,
        0,
        2,
        4,
        0,
        3,
        1
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        3,
        3,
        0,
        2,
        0,
        4,
        2,
        1,
        0,
        4,
        3,
        2,
        0,
        3,
        0,
        1,
        1,
        2,
        0,
        1,
        4,
        0,
        2,
        0,
        0,
        2,
        4,
        3,
        0,
        2,
        0,
        2,
        0,
        0,
        0,
        4,
        1,
        4,
        0,
        1,
        2,
        3,
        2,
        0,
        4,
        4,
        4,
        1,
        2,
        0,
        4,
        1
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        3,
        0,
        0,
        3,
        0,
        4,
        0,
        1,
        0,
        1,
        3,
        4,
        0,
        2,
        1,
        1,
        4,
        3,
        0,
        0,
        4,
        4,
        4,
        0,
        0,
        4,
        3,
        4,
        3,
        4,
        0,
        2,
        4,
        0,
        2,
        2,
        3,
        4,
        0,
        0,
        4,
        3,
        0,
        1,
        2,
        4,
        2,
        0,
        2,
        0,
        4,
        0
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        2,
        4,
        1,
        1,
        3,
        2,
        2,
        3,
        3,
        3,
        1,
        0,
        0,
        2,
        3,
        2,
        4,
        2,
        4,
        0,
        4,
        1,
        1,
        0,
        3,
        3,
        0,
        1,
        0,
        0,
        1,
        4,
        0,
        1,
        0,
        2,
        4,
        4,
        1,
        2,
        4,
        2,
        0,
        1,
        4,
        0,
        1,
        0,
        4,
        4,
        2,
        2
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        2,
        4,
        1,
        2,
        1,
        3,
        0,
        2,
        0,
        1,
        2,
        0,
        4,
        0,
        2,
        0,
        0,
        3,
        0,
        4,
        4,
        1,
        1,
        2,
        0,
        2,
        1,
        1,
        1,
        1,
        4,
        2,
        4,
        1,
        1,
        2,
        4,
        0,
        1,
        0,
        4,
        2,
        4,
        1,
        3,
        4,
        1,
        0,
        3,
        2,
        2,
        4
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        0,
        3,
        0,
        4,
        2,
        3,
        1,
        4,
        1,
        2,
        4,
        4,
        0,
        4,
        4,
        1,
        4,
        2,
        1,
        2,
        3,
        0,
        1,
        1,
        2,
        3,
        0,
        0,
        1,
        2,
        3,
        3,
        4,
        1,
        4,
        3,
        2,
        2,
        3,
        0,
        1,
        2,
        0,
        4,
        0,
        4,
        0,
        0,
        3,
        4,
        0,
        0
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        0,
        3,
        0,
        3,
        0,
        0,
        3,
        4,
        3,
        2,
        0,
        1,
        4,
        2,
        3,
        0,
        1,
        1,
        1,
        0,
        4,
        0,
        4,
        3,
        0,
        4,
        0,
        1,
        4,
        1,
        3,
        2,
        2,
        0,
        3,
        0,
        2,
        4,
        4,
        0,
        1,
        4,
        4,
        0,
        0,
        4,
        0,
        1,
        2,
        4,
        0,
        4
      ]
    },
    {
      "id": "958C4CED",
      "weeklyDisrupted": [
        3,
        1,
        0,
        0,
        3,
        3,
        0,
        3,
        0,
        3,
        1,
        4,
        4,
        3,
        2,
        2,
        2,
        2,
        1,
        2,
        3,
        0,
        2,
        3,
        3,
        1,
        0,
        1,
        2,
        2,
        2,
        4,
        0,
        3,
        4,
        4,
        3,
        4,
        0,
        2,
        3,
        4,
        1,
        4,
        2,
        0,
        4,
        4,
        4,
        1,
        3,
        0
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        3,
        3,
        4,
        0,
        3,
        3,
        0,
        4,
        2,
        2,
        4,
        3,
        4,
        1,
        4,
        4,
        2,
        1,
        3,
        4,
        0,
        4,
        3,
        2,
        0,
        0,
        2,
        0,
        0,
        1,
        4,
        4,
        0,
        2,
        4,
        3,
        4,
        0,
        0,
        0,
        2,
        3,
        3,
        4,
        0,
        3,
        0,
        3,
        4,
        4,
        4,
        0
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        4,
        0,
        4,
        0,
        0,
        2,
        0,
        1,
        3,
        0,
        4,
        3,
        4,
        4,
        1,
        0,
        0,
        2,
        0,
        3,
        3,
        3,
        3,
        0,
        0,
        0,
        0,
        0,
        2,
        4,
        2,
        4,
        1,
        4,
        4,
        2,
        3,
        4,
        1,
        0,
        2,
        3,
        4,
        0,
        1,
        3,
        0,
        3,
        2,
        1,
        3,
        4
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyDisrupted": [
        2,
        2,
        0,
        4,
        0,
        3,
        2,
        0,
        4,
        1,
        4,
        4,
        4,
        0,
        2,
        2,
        1,
        1,
        0,
        4,
        0,
        3,
        2,
        1,
        2,
        2,
        4,
        3,
        4,
        4,
        4,
        3,
        2,
        1,
        3,
        2,
        1,
        4,
        0,
        1,
        0,
        4,
        1,
        4,
        3,
        0,
        0,
        1,
        4,
        0,
        1,
        0
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        2,
        3,
        4,
        4,
        1,
        4,
        0,
        1,
        2,
        0,
        0,
        4,
        4,
        4,
        4,
        1,
        4,
        0,
        4,
        2,
        4,
        2,
        4,
        1,
        0,
        4,
        0,
        2,
        0,
        0,
        4,
        3,
        0,
        2,
        2,
        4,
        4,
        4,
        4,
        1,
        4,
        4,
        0,
        0,
        0,
        4,
        0,
        3,
        2,
        0,
        4,
        3
      ]
    },
    {
      "id": "41097027",
      "weeklyDisrupted": [
        3,
        4,
        2,
        4,
        4,
        1,
        3,
        1,
        4,
        0,
        4,
        3,
        1,
        3,
        4,
        3,
        4,
        1,
        0,
        1,
        1,
        4,
        2,
        2,
        0,
        2,
        2,
        0,
        4,
        3,
        2,
        3,
        0,
        0,
        0,
        0,
        4,
        0,
        3,
        4,
        4,
        3,
        1,
        4,
        4,
        4,
        1,
        0,
        1,
        1,
        2,
        4
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        3,
        3,
        4,
        4,
        3,
        3,
        1,
        1,
        3,
        1,
        3,
        4,
        0,
        1,
        1,
        0,
        1,
        0,
        0,
        0,
        4,
        4,
        1,
        3,
        0,
        2,
        2,
        0,
        2,
        2,
        0,
        3,
        3,
        0,
        0,
        2,
        4,
        3,
        0,
        0,
        2,
        2,
        4,
        3,
        0,
        4,
        0,
        1,
        1,
        0,
        4,
        1
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        1,
        0,
        2,
        0,
        2,
        1,
        0,
        0,
        4,
        4,
        2,
        3,
        2,
        2,
        3,
        3,
        3,
        0,
        3,
        4,
        0,
        1,
        4,
        2,
        0,
        2,
        0,
        0,
        0,
        0,
        4,
        3,
        2,
        3,
        1,
        3,
        2,
        0,
        2,
        1,
        2,
        4,
        3,
        1,
        0,
        3,
        3,
        3,
        4,
        0,
        1,
        4
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        3,
        0,
        4,
        1,
        4,
        1,
        3,
        2,
        4,
        1,
        3,
        4,
        1,
        3,
        0,
        3,
        2,
        0,
        3,
        3,
        0,
        0,
        2,
        1,
        2,
        3,
        3,
        2,
        3,
        3,
        1,
        0,
        1,
        2,
        3,
        4,
        1,
        0,
        2,
        0,
        3,
        2,
        1,
        4,
        1,
        3,
        3,
        0,
        4,
        0,
        0,
        0
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        0,
        2,
        0,
        0,
        1,
        4,
        1,
        0,
        4,
        4,
        2,
        4,
        4,
        3,
        3,
        4,
        1,
        0,
        4,
        0,
        4,
        3,
        2,
        3,
        4,
        2,
        4,
        4,
        0,
        0,
        0,
        2,
        3,
        0,
        0,
        0,
        3,
        2,
        0,
        1,
        0,
        2,
        0,
        1,
        4,
        2,
        0,
        0
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        1,
        2,
        4,
        0,
        0,
        4,
        1,
        0,
        3,
        0,
        4,
        4,
        4,
        0,
        2,
        2,
        0,
        0,
        0,
        3,
        4,
        3,
        4,
        3,
        0,
        4,
        2,
        1,
        2,
        4,
        4,
        1,
        4,
        3,
        1,
        2,
        3,
        0,
        0,
        0,
        1,
        3,
        1,
        2,
        0,
        3,
        0,
        1,
        4,
        1,
        4,
        3
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        4,
        0,
        2,
        2,
        0,
        1,
        1,
        4,
        1,
        1,
        1,
        2,
        4,
        3,
        1,
        0,
        2,
        1,
        3,
        3,
        4,
        4,
        4,
        4,
        3,
        3,
        0,
        0,
        0,
        4,
        0,
        2,
        3,
        4,
        0,
        3,
        4,
        0,
        3,
        0,
        0,
        2,
        2,
        2,
        0,
        4,
        0,
        0,
        4,
        4,
        4,
        1
      ]
    },
    {
      "id": "D9345860",
      "weeklyDisrupted": [
        3,
        2,
        4,
        4,
        0,
        4,
        4,
        2,
        2,
        2,
        3,
        1,
        4,
        3,
        2,
        0,
        0,
        0,
        2,
        4,
        4,
        2,
        4,
        0,
        0,
        0,
        2,
        3,
        4,
        4,
        0,
        3,
        1,
        2,
        3,
        0,
        4,
        4,
        2,
        0,
        2,
        4,
        4,
        3,
        4,
        0,
        0,
        1,
        4,
        2,
        0,
        4
      ]
    },
    {
      "id": "049BCC88",
      "weeklyDisrupted": [
        3,
        2,
        1,
        2,
        2,
        3,
        0,
        4,
        3,
        3,
        4,
        1,
        1,
        0,
        4,
        1,
        1,
        3,
        3,
        1,
        3,
        4,
        0,
        3,
        1,
        0,
        0,
        3,
        4,
        1,
        0,
        4,
        1,
        1,
        0,
        3,
        3,
        4,
        0,
        0,
        4,
        1,
        0,
        2,
        3,
        2,
        0,
        0,
        4,
        0,
        2,
        4
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        1,
        1,
        4,
        4,
        2,
        0,
        3,
        1,
        2,
        2,
        1,
        3,
        0,
        1,
        4,
        1,
        4,
        2,
        0,
        0,
        3,
        4,
        3,
        0,
        0,
        1,
        2,
        0,
        4,
        1,
        0,
        4,
        1,
        0,
        1,
        2,
        3,
        2,
        1,
        1,
        1,
        3,
        0,
        0,
        4,
        3,
        1,
        1,
        4,
        0,
        3,
        4
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        2,
        1,
        2,
        0,
        4,
        1,
        1,
        4,
        2,
        2,
        2,
        3,
        1,
        3,
        2,
        4,
        4,
        1,
        3,
        0,
        0,
        3,
        0,
        3,
        0,
        3,
        2,
        1,
        4,
        0,
        1,
        0,
        0,
        3,
        3,
        2,
        4,
        2,
        3,
        2,
        3,
        3,
        2,
        0,
        1,
        3,
        4,
        0,
        4,
        0,
        2,
        3
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        4,
        0,
        2,
        3,
        2,
        0,
        0,
        3,
        1,
        2,
        0,
        4,
        1,
        4,
        3,
        4,
        3,
        0,
        2,
        1,
        0,
        0,
        1,
        1,
        0,
        0,
        1,
        0,
        1,
        0,
        1,
        4,
        0,
        2,
        4,
        4,
        4,
        0,
        3,
        0,
        3,
        2,
        0,
        2,
        0,
        1,
        2,
        2,
        4,
        0,
        4,
        1
      ]
    },
    {
      "id": "330FF32E",
      "weeklyDisrupted": [
        4,
        4,
        4,
        2,
        3,
        0,
        0,
        4,
        1,
        0,
        1,
        1,
        1,
        3,
        3,
        4,
        1,
        3,
        0,
        4,
        2,
        4,
        4,
        0,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        4,
        2,
        3,
        3,
        4,
        3,
        0,
        3,
        0,
        3,
        2,
        0,
        1,
        1,
        3,
        4,
        3,
        2,
        2,
        2,
        2
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        3,
        4,
        0,
        1,
        2,
        2,
        0,
        2,
        4,
        2,
        4,
        2,
        1,
        1,
        2,
        4,
        4,
        4,
        0,
        3,
        0,
        3,
        2,
        2,
        0,
        3,
        0,
        0,
        2,
        2,
        0,
        3,
        4,
        1,
        1,
        4,
        4,
        1,
        4,
        0,
        1,
        1,
        0,
        3,
        3,
        1,
        1,
        1,
        1,
        4,
        0,
        3
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        4,
        4,
        1,
        0,
        1,
        1,
        2,
        0,
        3,
        2,
        3,
        0,
        2,
        3,
        0,
        0,
        3,
        2,
        0,
        2,
        1,
        4,
        1,
        4,
        0,
        2,
        0,
        0,
        0,
        0,
        3,
        3,
        0,
        2,
        4,
        2,
        1,
        2,
        0,
        2,
        2,
        3,
        0,
        2,
        2,
        4,
        0,
        4,
        2,
        2,
        0,
        2
      ]
    },
    {
      "id": "200EA71C",
      "weeklyDisrupted": [
        2,
        2,
        0,
        0,
        0,
        4,
        0,
        0,
        4,
        3,
        4,
        1,
        4,
        2,
        4,
        4,
        0,
        4,
        3,
        4,
        3,
        2,
        2,
        1,
        0,
        0,
        3,
        3,
        3,
        0,
        4,
        4,
        0,
        0,
        2,
        3,
        3,
        1,
        3,
        3,
        1,
        2,
        1,
        1,
        4,
        0,
        0,
        3,
        4,
        4,
        1,
        3
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        0,
        4,
        3,
        4,
        2,
        4,
        1,
        3,
        4,
        0,
        4,
        3,
        3,
        0,
        4,
        1,
        4,
        1,
        0,
        0,
        3,
        4,
        3,
        2,
        2,
        1,
        1,
        0,
        2,
        3,
        1,
        4,
        2,
        1,
        2,
        4,
        2,
        1,
        4,
        1,
        1,
        0,
        0,
        1,
        1,
        3,
        2,
        0,
        4,
        4,
        3,
        0
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        2,
        0,
        0,
        1,
        3,
        2,
        0,
        4,
        4,
        3,
        2,
        2,
        4,
        4,
        3,
        3,
        0,
        0,
        3,
        0,
        4,
        4,
        2,
        2,
        0,
        4,
        0,
        2,
        1,
        2,
        0,
        0,
        4,
        0,
        2,
        1,
        4,
        4,
        1,
        1,
        2,
        2,
        0,
        0,
        4,
        2,
        1,
        1,
        4,
        3,
        4,
        0
      ]
    },
    {
      "id": "44804BD4",
      "weeklyDisrupted": [
        0,
        0,
        1,
        0,
        4,
        0,
        0,
        4,
        3,
        1,
        4,
        1,
        0,
        3,
        4,
        4,
        4,
        4,
        4,
        4,
        2,
        0,
        4,
        3,
        0,
        3,
        0,
        0,
        1,
        0,
        0,
        3,
        0,
        0,
        2,
        4,
        3,
        0,
        4,
        0,
        1,
        4,
        4,
        4,
        0,
        4,
        1,
        2,
        1,
        1,
        3,
        4
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        0,
        4,
        0,
        2,
        0,
        2,
        1,
        2,
        3,
        1,
        4,
        1,
        2,
        4,
        3,
        2,
        0,
        2,
        0,
        4,
        4,
        4,
        4,
        0,
        0,
        1,
        0,
        2,
        4,
        2,
        3,
        4,
        4,
        0,
        0,
        4,
        3,
        0,
        1,
        3,
        4,
        2,
        3,
        2,
        4,
        0,
        0,
        0,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        3,
        4,
        0,
        4,
        0,
        3,
        0,
        3,
        1,
        0,
        0,
        1,
        2,
        0,
        2,
        0,
        2,
        4,
        3,
        0,
        3,
        2,
        3,
        1,
        1,
        3,
        0,
        0,
        0,
        1,
        1,
        4,
        0,
        0,
        0,
        2,
        4,
        0,
        1,
        0,
        4,
        2,
        2,
        2,
        2,
        0,
        4,
        2,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        0,
        1,
        0,
        1,
        4,
        2,
        0,
        0,
        2,
        1,
        4,
        4,
        3,
        4,
        2,
        3,
        1,
        1,
        2,
        4,
        3,
        1,
        3,
        0,
        0,
        0,
        0,
        1,
        4,
        4,
        0,
        2,
        0,
        2,
        2,
        3,
        4,
        0,
        0,
        0,
        2,
        4,
        1,
        2,
        0,
        0,
        3,
        0,
        4,
        1,
        4,
        4
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        4,
        3,
        0,
        4,
        4,
        2,
        2,
        4,
        3,
        4,
        4,
        4,
        1,
        3,
        2,
        2,
        4,
        0,
        1,
        1,
        0,
        1,
        3,
        3,
        3,
        3,
        1,
        2,
        0,
        0,
        4,
        0,
        2,
        2,
        2,
        3,
        4,
        3,
        4,
        0,
        3,
        2,
        1,
        2,
        0,
        4,
        3,
        0,
        0,
        0,
        2,
        3
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        1,
        4,
        0,
        1,
        4,
        2,
        1,
        2,
        3,
        3,
        4,
        1,
        3,
        4,
        3,
        3,
        3,
        0,
        1,
        1,
        0,
        4,
        4,
        3,
        0,
        4,
        0,
        2,
        3,
        1,
        4,
        1,
        4,
        1,
        3,
        2,
        3,
        4,
        3,
        1,
        1,
        4,
        3,
        0,
        3,
        4,
        2,
        0,
        4,
        1,
        1,
        4
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        4,
        2,
        0,
        4,
        4,
        3,
        2,
        1,
        0,
        3,
        4,
        4,
        4,
        2,
        3,
        0,
        2,
        4,
        2,
        0,
        1,
        0,
        1,
        2,
        0,
        3,
        2,
        2,
        0,
        3,
        1,
        4,
        1,
        0,
        2,
        3,
        3,
        4,
        0,
        0,
        4,
        4,
        0,
        4,
        3,
        2,
        0,
        1,
        3,
        4,
        3,
        1
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        2,
        4,
        1,
        0,
        3,
        0,
        2,
        1,
        1,
        4,
        1,
        0,
        0,
        0,
        2,
        0,
        0,
        2,
        2,
        4,
        3,
        3,
        4,
        2,
        1,
        0,
        1,
        0,
        0,
        0,
        0,
        3,
        1,
        0,
        0,
        2,
        4,
        0,
        0,
        3,
        4,
        0,
        0,
        0,
        0,
        2,
        0,
        2,
        4,
        3,
        1,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        4,
        2,
        3,
        3,
        0,
        4,
        1,
        2,
        4,
        1,
        4,
        0,
        3,
        4,
        4,
        4,
        4,
        0,
        1,
        0,
        1,
        4,
        2,
        1,
        0,
        0,
        1,
        1,
        0,
        1,
        1,
        2,
        4,
        3,
        2,
        4,
        4,
        0,
        0,
        0,
        4,
        4,
        4,
        3,
        0,
        4,
        1,
        0,
        3,
        4,
        0,
        4
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        3,
        0,
        0,
        4,
        2,
        1,
        4,
        2,
        0,
        4,
        3,
        2,
        0,
        4,
        2,
        3,
        2,
        2,
        2,
        0,
        0,
        2,
        3,
        1,
        2,
        1,
        2,
        4,
        2,
        2,
        1,
        0,
        1,
        1,
        3,
        4,
        1,
        3,
        1,
        0,
        4,
        2,
        1,
        4,
        4,
        2,
        3,
        2,
        4,
        0,
        2,
        0
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        4,
        3,
        3,
        1,
        0,
        3,
        3,
        4,
        4,
        1,
        1,
        0,
        0,
        3,
        4,
        1,
        1,
        2,
        0,
        1,
        0,
        0,
        3,
        1,
        0,
        0,
        0,
        1,
        0,
        0,
        1,
        3,
        3,
        1,
        0,
        4,
        4,
        3,
        0,
        2,
        3,
        2,
        1,
        3,
        2,
        3,
        2,
        0,
        4,
        3,
        0,
        0
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        4,
        4,
        0,
        4,
        4,
        0,
        4,
        4,
        2,
        4,
        3,
        4,
        1,
        4,
        4,
        4,
        4,
        3,
        1,
        4,
        1,
        2,
        4,
        3,
        1,
        0,
        1,
        0,
        4,
        3,
        4,
        2,
        2,
        0,
        0,
        2,
        1,
        0,
        1,
        3,
        4,
        0,
        0,
        0,
        1,
        1,
        0,
        1,
        3,
        1,
        0,
        0
      ]
    },
    {
      "id": "BAF9CD1A",
      "weeklyDisrupted": [
        4,
        2,
        3,
        2,
        2,
        3,
        4,
        0,
        3,
        4,
        2,
        4,
        3,
        0,
        3,
        0,
        4,
        3,
        0,
        4,
        1,
        2,
        3,
        0,
        0,
        0,
        4,
        3,
        4,
        0,
        2,
        4,
        1,
        3,
        2,
        2,
        0,
        0,
        2,
        0,
        3,
        4,
        0,
        3,
        2,
        0,
        0,
        4,
        2,
        1,
        0,
        4
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        0,
        3,
        0,
        0,
        0,
        4,
        0,
        2,
        4,
        4,
        0,
        4,
        4,
        0,
        2,
        3,
        0,
        0,
        0,
        3,
        4,
        0,
        0,
        3,
        1,
        2,
        4,
        0,
        4,
        4,
        0,
        3,
        1,
        0,
        0,
        2,
        4,
        0,
        1,
        0,
        1,
        1,
        0,
        2,
        0,
        3,
        3,
        2,
        2,
        0,
        0,
        4
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        2,
        4,
        1,
        0,
        4,
        0,
        0,
        1,
        3,
        0,
        4,
        3,
        1,
        0,
        0,
        4,
        2,
        3,
        0,
        3,
        1,
        3,
        3,
        0,
        2,
        0,
        2,
        0,
        0,
        0,
        0,
        4,
        0,
        4,
        1,
        4,
        4,
        0,
        0,
        4,
        3,
        2,
        0,
        2,
        2,
        4,
        1,
        2,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        4,
        1,
        3,
        2,
        2,
        2,
        1,
        3,
        1,
        4,
        4,
        3,
        1,
        4,
        0,
        3,
        3,
        4,
        0,
        4,
        3,
        2,
        3,
        0,
        1,
        2,
        0,
        0,
        0,
        4,
        0,
        4,
        0,
        0,
        4,
        2,
        0,
        2,
        0,
        1,
        4,
        0,
        3,
        2,
        1,
        4,
        0,
        3,
        3,
        2,
        1,
        1
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        2,
        0,
        1,
        3,
        2,
        0,
        3,
        0,
        4,
        2,
        4,
        3,
        4,
        1,
        0,
        3,
        3,
        0,
        4,
        3,
        2,
        1,
        2,
        4,
        0,
        3,
        0,
        0,
        2,
        1,
        0,
        1,
        0,
        1,
        1,
        1,
        2,
        4,
        1,
        1,
        4,
        3,
        3,
        4,
        3,
        2,
        4,
        0,
        0,
        4,
        2,
        2
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        4,
        3,
        2,
        2,
        2,
        3,
        3,
        3,
        0,
        0,
        3,
        1,
        3,
        3,
        4,
        3,
        3,
        1,
        0,
        1,
        1,
        1,
        0,
        3,
        0,
        2,
        1,
        0,
        1,
        3,
        0,
        1,
        3,
        0,
        0,
        3,
        3,
        4,
        1,
        4,
        4,
        3,
        0,
        4,
        3,
        1,
        1,
        0,
        4,
        1,
        0,
        4
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        0,
        2,
        4,
        4,
        1,
        4,
        1,
        3,
        1,
        0,
        3,
        1,
        4,
        0,
        0,
        0,
        3,
        0,
        0,
        4,
        1,
        1,
        0,
        0,
        0,
        2,
        1,
        2,
        3,
        1,
        0,
        4,
        4,
        2,
        4,
        3,
        2,
        4,
        2,
        0,
        3,
        3,
        1,
        4,
        1,
        1,
        1,
        0,
        2,
        4,
        1,
        0
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        1,
        2,
        4,
        4,
        0,
        4,
        0,
        4,
        3,
        1,
        4,
        4,
        4,
        0,
        2,
        4,
        3,
        0,
        2,
        2,
        0,
        4,
        3,
        2,
        0,
        3,
        4,
        1,
        4,
        2,
        4,
        0,
        0,
        1,
        0,
        4,
        4,
        0,
        2,
        1,
        2,
        2,
        1,
        4,
        3,
        0,
        1,
        0,
        4,
        2,
        0,
        4
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        4,
        0,
        0,
        1,
        1,
        3,
        4,
        2,
        4,
        4,
        2,
        4,
        2,
        0,
        3,
        0,
        2,
        1,
        4,
        3,
        0,
        4,
        0,
        1,
        0,
        3,
        1,
        0,
        4,
        2,
        4,
        4,
        2,
        2,
        3,
        4,
        4,
        4,
        0,
        1,
        0,
        2,
        1,
        2,
        2,
        2,
        1,
        1,
        4,
        2,
        0,
        3
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        4,
        1,
        1,
        0,
        2,
        3,
        0,
        1,
        3,
        0,
        4,
        3,
        4,
        3,
        3,
        2,
        3,
        1,
        0,
        1,
        1,
        0,
        4,
        4,
        2,
        1,
        2,
        0,
        3,
        4,
        3,
        3,
        4,
        1,
        4,
        4,
        4,
        0,
        1,
        0,
        2,
        4,
        0,
        1,
        4,
        2,
        0,
        1,
        2,
        0,
        3,
        1
      ]
    },
    {
      "id": "958C4CED",
      "weeklyDisrupted": [
        4,
        0,
        2,
        3,
        0,
        3,
        1,
        1,
        0,
        2,
        3,
        0,
        3,
        2,
        1,
        0,
        2,
        0,
        2,
        2,
        3,
        3,
        3,
        2,
        2,
        1,
        0,
        0,
        4,
        4,
        4,
        4,
        1,
        4,
        3,
        1,
        0,
        2,
        0,
        1,
        0,
        2,
        3,
        0,
        3,
        3,
        0,
        4,
        4,
        2,
        1,
        0
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        3,
        0,
        3,
        4,
        0,
        3,
        4,
        1,
        3,
        0,
        3,
        0,
        4,
        0,
        2,
        1,
        1,
        0,
        1,
        3,
        4,
        3,
        4,
        0,
        2,
        4,
        0,
        0,
        0,
        0,
        3,
        4,
        1,
        1,
        3,
        3,
        0,
        2,
        3,
        3,
        4,
        4,
        0,
        4,
        4,
        4,
        0,
        2,
        1,
        0,
        2,
        4
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        0,
        4,
        0,
        4,
        3,
        0,
        2,
        4,
        4,
        1,
        3,
        4,
        0,
        0,
        3,
        1,
        4,
        3,
        3,
        0,
        2,
        4,
        0,
        2,
        1,
        2,
        1,
        1,
        3,
        0,
        1,
        4,
        2,
        0,
        2,
        4,
        4,
        1,
        1,
        0,
        4,
        4,
        1,
        3,
        0,
        0,
        0,
        4,
        4,
        1,
        3,
        0
      ]
    },
    {
      "id": "C2F62DAA",
      "weeklyDisrupted": [
        2,
        3,
        4,
        3,
        1,
        2,
        3,
        1,
        3,
        2,
        1,
        1,
        4,
        1,
        2,
        3,
        2,
        2,
        3,
        1,
        1,
        2,
        3,
        1,
        0,
        2,
        1,
        2,
        0,
        0,
        0,
        1,
        2,
        3,
        4,
        3,
        4,
        3,
        3,
        3,
        2,
        4,
        2,
        3,
        2,
        2,
        0,
        0,
        4,
        2,
        1,
        2
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        1,
        1,
        0,
        4,
        0,
        2,
        1,
        3,
        4,
        1,
        0,
        0,
        4,
        4,
        4,
        1,
        2,
        3,
        1,
        0,
        4,
        0,
        2,
        0,
        0,
        4,
        0,
        0,
        3,
        3,
        4,
        4,
        0,
        1,
        3,
        4,
        4,
        4,
        4,
        0,
        4,
        2,
        0,
        1,
        0,
        2,
        4,
        0,
        0,
        2,
        1,
        4
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        4,
        3,
        2,
        3,
        4,
        0,
        4,
        1,
        1,
        0,
        4,
        0,
        2,
        1,
        2,
        1,
        3,
        2,
        0,
        2,
        0,
        1,
        4,
        2,
        3,
        2,
        0,
        4,
        4,
        2,
        4,
        2,
        4,
        1,
        0,
        3,
        0,
        4,
        0,
        1,
        4,
        2,
        2,
        4,
        1,
        1,
        0,
        1,
        0,
        3,
        1,
        2
      ]
    },
    {
      "id": "6C1E6A6E",
      "weeklyDisrupted": [
        4,
        2,
        3,
        4,
        2,
        2,
        4,
        4,
        4,
        0,
        1,
        4,
        4,
        2,
        3,
        0,
        1,
        4,
        4,
        3,
        4,
        4,
        3,
        2,
        1,
        2,
        1,
        1,
        4,
        3,
        2,
        4,
        0,
        0,
        4,
        1,
        4,
        0,
        0,
        0,
        2,
        2,
        0,
        0,
        3,
        2,
        0,
        4,
        4,
        1,
        3,
        4
      ]
    },
    {
      "id": "9202537",
      "weeklyDisrupted": [
        4,
        1,
        3,
        4,
        0,
        1,
        1,
        4,
        4,
        1,
        3,
        0,
        4,
        2,
        2,
        4,
        1,
        0,
        1,
        0,
        1,
        2,
        3,
        2,
        0,
        4,
        0,
        0,
        3,
        4,
        1,
        2,
        1,
        0,
        2,
        4,
        3,
        0,
        0,
        3,
        4,
        2,
        3,
        4,
        4,
        3,
        0,
        2,
        1,
        4,
        2,
        4
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        1,
        4,
        4,
        3,
        0,
        4,
        4,
        4,
        2,
        4,
        3,
        3,
        4,
        0,
        4,
        3,
        0,
        1,
        0,
        2,
        3,
        3,
        2,
        0,
        0,
        2,
        0,
        4,
        3,
        4,
        3,
        2,
        0,
        1,
        2,
        0,
        2,
        0,
        2,
        0,
        2,
        4,
        0,
        1,
        0,
        4,
        1,
        0,
        4,
        4,
        0,
        4
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        4,
        3,
        4,
        4,
        0,
        3,
        3,
        4,
        1,
        0,
        0,
        2,
        4,
        1,
        3,
        3,
        1,
        0,
        1,
        3,
        3,
        4,
        4,
        0,
        0,
        3,
        0,
        0,
        1,
        1,
        3,
        0,
        2,
        0,
        4,
        3,
        3,
        4,
        0,
        2,
        1,
        2,
        0,
        1,
        1,
        4,
        1,
        2,
        0,
        1,
        3,
        2
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        2,
        0,
        2,
        4,
        0,
        4,
        0,
        0,
        2,
        0,
        1,
        1,
        3,
        3,
        4,
        1,
        3,
        1,
        1,
        4,
        2,
        0,
        4,
        1,
        1,
        4,
        0,
        2,
        3,
        4,
        4,
        2,
        1,
        1,
        2,
        4,
        4,
        4,
        2,
        3,
        1,
        4,
        4,
        2,
        4,
        4,
        3,
        1,
        2,
        0,
        4,
        1
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        3,
        2,
        1,
        0,
        2,
        2,
        2,
        0,
        4,
        1,
        4,
        4,
        3,
        2,
        4,
        4,
        4,
        0,
        3,
        0,
        0,
        0,
        4,
        0,
        1,
        4,
        2,
        1,
        3,
        4,
        0,
        3,
        1,
        0,
        1,
        2,
        4,
        3,
        0,
        4,
        1,
        1,
        4,
        4,
        2,
        3,
        4,
        2,
        4,
        1,
        2,
        4
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        0,
        0,
        2,
        4,
        3,
        4,
        4,
        1,
        0,
        3,
        4,
        1,
        4,
        3,
        4,
        2,
        1,
        1,
        2,
        1,
        4,
        3,
        4,
        0,
        0,
        1,
        1,
        0,
        4,
        2,
        1,
        4,
        0,
        0,
        0,
        3,
        4,
        0,
        4,
        2,
        2,
        4,
        1,
        3,
        2,
        0,
        1,
        1,
        4,
        1,
        3,
        4
      ]
    },
    {
      "id": "8164C892",
      "weeklyDisrupted": [
        2,
        3,
        0,
        4,
        0,
        0,
        4,
        2,
        4,
        4,
        3,
        0,
        3,
        0,
        2,
        2,
        0,
        4,
        0,
        0,
        2,
        1,
        2,
        2,
        3,
        0,
        2,
        3,
        1,
        1,
        3,
        3,
        2,
        0,
        2,
        3,
        1,
        4,
        1,
        4,
        2,
        2,
        0,
        1,
        3,
        1,
        0,
        4,
        1,
        2,
        4,
        2
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        4,
        0,
        1,
        3,
        0,
        4,
        0,
        4,
        0,
        2,
        3,
        0,
        2,
        0,
        3,
        3,
        2,
        0,
        0,
        3,
        2,
        2,
        1,
        0,
        0,
        4,
        3,
        1,
        3,
        4,
        0,
        0,
        4,
        2,
        0,
        4,
        2,
        0,
        3,
        2,
        0,
        4,
        3,
        3,
        3,
        2,
        4,
        0,
        2,
        4,
        0,
        4
      ]
    },
    {
      "id": "4CD72DAE",
      "weeklyDisrupted": [
        4,
        0,
        1,
        4,
        2,
        2,
        1,
        0,
        2,
        0,
        0,
        4,
        0,
        3,
        0,
        0,
        4,
        1,
        2,
        3,
        4,
        3,
        2,
        1,
        0,
        2,
        1,
        0,
        3,
        3,
        0,
        2,
        3,
        0,
        1,
        4,
        3,
        3,
        0,
        0,
        4,
        4,
        0,
        4,
        2,
        4,
        0,
        0,
        3,
        1,
        3,
        3
      ]
    },
    {
      "id": "9C01A6AA",
      "weeklyDisrupted": [
        1,
        4,
        4,
        2,
        4,
        2,
        0,
        4,
        2,
        2,
        3,
        3,
        4,
        2,
        3,
        4,
        4,
        1,
        0,
        4,
        2,
        4,
        4,
        3,
        0,
        0,
        1,
        0,
        4,
        4,
        1,
        3,
        3,
        2,
        4,
        4,
        4,
        1,
        3,
        0,
        2,
        2,
        1,
        3,
        2,
        4,
        0,
        4,
        4,
        4,
        3,
        1
      ]
    },
    {
      "id": "867038F2",
      "weeklyDisrupted": [
        2,
        2,
        3,
        1,
        4,
        3,
        1,
        2,
        1,
        4,
        2,
        4,
        4,
        4,
        1,
        2,
        3,
        3,
        4,
        0,
        4,
        1,
        3,
        0,
        0,
        4,
        3,
        3,
        1,
        2,
        3,
        3,
        3,
        0,
        1,
        3,
        0,
        4,
        3,
        1,
        2,
        0,
        1,
        0,
        0,
        4,
        2,
        4,
        0,
        2,
        0,
        2
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        4,
        0,
        2,
        0,
        2,
        3,
        0,
        4,
        2,
        4,
        3,
        3,
        1,
        4,
        1,
        2,
        3,
        2,
        2,
        4,
        3,
        4,
        2,
        1,
        4,
        0,
        0,
        2,
        0,
        0,
        2,
        2,
        2,
        4,
        0,
        3,
        4,
        2,
        0,
        1,
        2,
        2,
        3,
        1,
        1,
        3,
        1,
        0,
        4,
        4,
        0,
        2
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyDisrupted": [
        3,
        0,
        3,
        4,
        0,
        4,
        2,
        0,
        4,
        2,
        1,
        3,
        4,
        4,
        1,
        1,
        3,
        1,
        1,
        1,
        4,
        1,
        3,
        0,
        1,
        4,
        0,
        0,
        0,
        2,
        3,
        4,
        0,
        0,
        1,
        1,
        1,
        4,
        3,
        0,
        4,
        3,
        0,
        1,
        1,
        3,
        1,
        4,
        4,
        0,
        0,
        3
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        4,
        4,
        4,
        1,
        1,
        4,
        3,
        2,
        4,
        0,
        4,
        0,
        0,
        4,
        3,
        1,
        1,
        1,
        0,
        0,
        3,
        0,
        0,
        3,
        1,
        3,
        2,
        1,
        2,
        2,
        3,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        1,
        1,
        4,
        1,
        0,
        0,
        0,
        4,
        1,
        1,
        0,
        4,
        0,
        4
      ]
    },
    {
      "id": "200EA71C",
      "weeklyDisrupted": [
        0,
        0,
        4,
        2,
        0,
        1,
        0,
        4,
        3,
        0,
        4,
        2,
        4,
        4,
        0,
        4,
        0,
        1,
        2,
        3,
        0,
        0,
        1,
        4,
        0,
        0,
        0,
        1,
        3,
        0,
        2,
        2,
        2,
        1,
        4,
        4,
        0,
        3,
        1,
        0,
        3,
        2,
        0,
        4,
        0,
        0,
        1,
        0,
        4,
        4,
        1,
        3
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        4,
        4,
        3,
        3,
        3,
        2,
        3,
        4,
        4,
        1,
        3,
        0,
        0,
        1,
        4,
        2,
        2,
        2,
        1,
        0,
        4,
        4,
        4,
        3,
        0,
        3,
        0,
        1,
        0,
        4,
        0,
        2,
        2,
        1,
        1,
        3,
        4,
        4,
        0,
        0,
        3,
        2,
        4,
        1,
        0,
        2,
        2,
        0,
        1,
        0,
        0,
        4
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        0,
        0,
        4,
        0,
        1,
        4,
        4,
        1,
        3,
        0,
        1,
        0,
        4,
        0,
        4,
        0,
        1,
        1,
        0,
        0,
        1,
        2,
        2,
        2,
        0,
        0,
        1,
        0,
        0,
        2,
        2,
        0,
        0,
        2,
        0,
        1,
        4,
        2,
        2,
        2,
        3,
        4,
        3,
        4,
        4,
        1,
        0,
        1,
        0,
        4,
        2,
        3
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        1,
        2,
        3,
        2,
        0,
        1,
        3,
        4,
        4,
        0,
        2,
        4,
        3,
        1,
        4,
        3,
        1,
        0,
        1,
        0,
        2,
        3,
        1,
        0,
        2,
        2,
        1,
        0,
        3,
        1,
        0,
        2,
        3,
        0,
        0,
        4,
        4,
        0,
        3,
        0,
        3,
        4,
        2,
        0,
        1,
        4,
        1,
        3,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        4,
        4,
        3,
        3,
        3,
        2,
        0,
        2,
        0,
        0,
        4,
        3,
        0,
        0,
        3,
        2,
        4,
        0,
        0,
        4,
        1,
        4,
        4,
        1,
        0,
        1,
        0,
        0,
        3,
        2,
        2,
        4,
        0,
        1,
        1,
        2,
        4,
        0,
        0,
        0,
        4,
        2,
        2,
        4,
        1,
        1,
        0,
        0,
        4,
        1,
        2,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        2,
        3,
        0,
        4,
        2,
        0,
        2,
        3,
        4,
        2,
        3,
        0,
        3,
        2,
        2,
        4,
        2,
        4,
        4,
        2,
        3,
        2,
        2,
        0,
        0,
        3,
        1,
        2,
        3,
        4,
        1,
        3,
        1,
        0,
        2,
        4,
        2,
        0,
        4,
        2,
        4,
        0,
        0,
        4,
        1,
        4,
        0,
        4,
        0,
        1,
        0,
        3
      ]
    },
    {
      "id": "17B2D2B0",
      "weeklyDisrupted": [
        2,
        4,
        1,
        1,
        0,
        4,
        0,
        2,
        4,
        3,
        3,
        0,
        3,
        4,
        1,
        1,
        1,
        4,
        0,
        3,
        2,
        4,
        4,
        4,
        0,
        4,
        2,
        0,
        0,
        1,
        1,
        4,
        4,
        0,
        0,
        4,
        4,
        4,
        4,
        1,
        1,
        2,
        4,
        0,
        1,
        4,
        0,
        3,
        0,
        4,
        4,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        4,
        4,
        1,
        2,
        4,
        2,
        0,
        1,
        4,
        3,
        1,
        2,
        4,
        0,
        2,
        0,
        2,
        4,
        3,
        1,
        4,
        2,
        1,
        0,
        0,
        0,
        1,
        1,
        0,
        3,
        0,
        3,
        2,
        0,
        3,
        2,
        3,
        4,
        0,
        1,
        3,
        4,
        3,
        4,
        3,
        4,
        0,
        1,
        4,
        4,
        1,
        2
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        0,
        2,
        2,
        0,
        2,
        2,
        0,
        2,
        3,
        2,
        4,
        4,
        2,
        1,
        2,
        3,
        1,
        2,
        1,
        2,
        0,
        0,
        4,
        0,
        0,
        4,
        0,
        0,
        3,
        4,
        4,
        4,
        0,
        0,
        4,
        1,
        3,
        0,
        3,
        0,
        3,
        1,
        3,
        4,
        2,
        3,
        2,
        1,
        4,
        4,
        0,
        2
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        0,
        0,
        1,
        4,
        2,
        2,
        1,
        2,
        1,
        0,
        0,
        4,
        2,
        2,
        1,
        4,
        3,
        0,
        2,
        3,
        4,
        0,
        1,
        0,
        0,
        0,
        0,
        2,
        4,
        4,
        0,
        4,
        1,
        1,
        1,
        1,
        4,
        0,
        0,
        0,
        4,
        4,
        0,
        0,
        4,
        3,
        0,
        4,
        4,
        0,
        3,
        2
      ]
    },
    {
      "id": "EB58F890",
      "weeklyDisrupted": [
        0,
        0,
        0,
        4,
        0,
        2,
        4,
        0,
        4,
        0,
        4,
        0,
        4,
        2,
        2,
        0,
        3,
        3,
        0,
        4,
        0,
        4,
        4,
        3,
        0,
        2,
        0,
        1,
        4,
        4,
        3,
        2,
        2,
        0,
        1,
        2,
        2,
        0,
        1,
        2,
        0,
        4,
        2,
        3,
        1,
        1,
        0,
        0,
        0,
        3,
        0,
        1
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        4,
        3,
        4,
        1,
        0,
        1,
        2,
        4,
        0,
        0,
        1,
        2,
        3,
        4,
        4,
        1,
        4,
        2,
        1,
        3,
        0,
        2,
        3,
        3,
        2,
        2,
        0,
        0,
        2,
        0,
        1,
        1,
        3,
        1,
        0,
        4,
        3,
        1,
        1,
        2,
        4,
        2,
        1,
        3,
        0,
        0,
        1,
        1,
        4,
        4,
        2,
        0
      ]
    },
    {
      "id": "867038F2",
      "weeklyDisrupted": [
        2,
        2,
        1,
        1,
        0,
        2,
        1,
        0,
        0,
        3,
        4,
        3,
        4,
        2,
        1,
        3,
        1,
        1,
        0,
        2,
        0,
        3,
        2,
        3,
        0,
        0,
        3,
        0,
        4,
        3,
        4,
        2,
        2,
        1,
        4,
        0,
        3,
        4,
        0,
        1,
        1,
        1,
        1,
        4,
        3,
        4,
        0,
        4,
        4,
        3,
        2,
        2
      ]
    },
    {
      "id": "87E6E3E8",
      "weeklyDisrupted": [
        2,
        2,
        2,
        0,
        4,
        4,
        0,
        1,
        3,
        0,
        4,
        4,
        2,
        4,
        3,
        0,
        2,
        2,
        4,
        4,
        2,
        3,
        2,
        0,
        0,
        0,
        2,
        0,
        2,
        0,
        1,
        2,
        4,
        4,
        3,
        2,
        4,
        2,
        4,
        0,
        1,
        2,
        1,
        3,
        1,
        4,
        2,
        2,
        4,
        2,
        3,
        1
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        3,
        4,
        1,
        1,
        0,
        0,
        1,
        4,
        4,
        0,
        0,
        2,
        4,
        3,
        4,
        4,
        2,
        4,
        4,
        3,
        0,
        2,
        4,
        0,
        0,
        0,
        1,
        0,
        1,
        3,
        2,
        2,
        4,
        0,
        4,
        4,
        3,
        0,
        3,
        4,
        3,
        3,
        4,
        4,
        1,
        0,
        1,
        3,
        1,
        3,
        3,
        4
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        3,
        3,
        2,
        2,
        3,
        0,
        0,
        3,
        1,
        3,
        4,
        0,
        3,
        4,
        4,
        1,
        3,
        3,
        4,
        2,
        1,
        0,
        1,
        4,
        0,
        4,
        2,
        1,
        0,
        1,
        2,
        2,
        4,
        2,
        3,
        4,
        3,
        2,
        3,
        0,
        4,
        2,
        3,
        1,
        1,
        1,
        0,
        2,
        0,
        2,
        2,
        3
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        0,
        0,
        0,
        4,
        4,
        0,
        2,
        1,
        3,
        2,
        1,
        1,
        4,
        2,
        1,
        4,
        3,
        2,
        3,
        3,
        0,
        3,
        0,
        2,
        1,
        0,
        0,
        2,
        4,
        4,
        3,
        4,
        4,
        3,
        1,
        2,
        0,
        4,
        1,
        0,
        0,
        4,
        1,
        4,
        0,
        1,
        2,
        3,
        4,
        2,
        2,
        2
      ]
    },
    {
      "id": "2770DFA7",
      "weeklyDisrupted": [
        0,
        3,
        4,
        4,
        3,
        2,
        1,
        2,
        4,
        1,
        2,
        4,
        4,
        4,
        0,
        3,
        0,
        1,
        0,
        4,
        3,
        4,
        4,
        3,
        0,
        1,
        4,
        3,
        2,
        2,
        4,
        1,
        3,
        0,
        4,
        2,
        0,
        4,
        2,
        1,
        2,
        4,
        3,
        0,
        2,
        4,
        0,
        1,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "067037B3",
      "weeklyDisrupted": [
        4,
        0,
        3,
        1,
        2,
        0,
        0,
        4,
        1,
        2,
        4,
        2,
        3,
        4,
        1,
        4,
        3,
        2,
        0,
        4,
        0,
        3,
        4,
        1,
        4,
        2,
        1,
        0,
        0,
        4,
        2,
        2,
        2,
        2,
        4,
        4,
        2,
        0,
        0,
        1,
        4,
        2,
        1,
        3,
        3,
        4,
        4,
        2,
        2,
        4,
        3,
        3
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        3,
        0,
        2,
        4,
        0,
        3,
        0,
        0,
        3,
        0,
        3,
        4,
        0,
        4,
        3,
        3,
        4,
        0,
        0,
        0,
        4,
        1,
        2,
        0,
        0,
        3,
        2,
        1,
        1,
        2,
        0,
        4,
        3,
        2,
        1,
        2,
        1,
        4,
        2,
        0,
        4,
        0,
        0,
        3,
        1,
        1,
        4,
        3,
        1,
        0,
        2,
        1
      ]
    },
    {
      "id": "19A12B20",
      "weeklyDisrupted": [
        0,
        1,
        0,
        3,
        4,
        2,
        1,
        1,
        3,
        0,
        3,
        4,
        4,
        4,
        0,
        2,
        4,
        0,
        4,
        2,
        3,
        3,
        4,
        0,
        0,
        0,
        2,
        2,
        2,
        4,
        0,
        3,
        0,
        0,
        0,
        0,
        3,
        4,
        0,
        0,
        4,
        4,
        1,
        2,
        0,
        2,
        3,
        1,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        4,
        2,
        1,
        0,
        0,
        4,
        0,
        1,
        3,
        0,
        4,
        0,
        4,
        1,
        2,
        2,
        3,
        0,
        4,
        4,
        1,
        0,
        0,
        2,
        0,
        0,
        2,
        4,
        4,
        1,
        3,
        4,
        0,
        0,
        3,
        4,
        4,
        4,
        0,
        0,
        3,
        4,
        0,
        0,
        2,
        0,
        0,
        4,
        4,
        2,
        2,
        1
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        1,
        0,
        0,
        4,
        2,
        3,
        0,
        4,
        0,
        0,
        4,
        4,
        3,
        2,
        3,
        3,
        4,
        1,
        4,
        4,
        2,
        0,
        4,
        4,
        0,
        0,
        0,
        0,
        4,
        2,
        4,
        0,
        0,
        0,
        2,
        2,
        2,
        0,
        3,
        2,
        2,
        3,
        1,
        2,
        0,
        4,
        3,
        1,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        4,
        4,
        0,
        0,
        4,
        0,
        0,
        4,
        2,
        2,
        4,
        1,
        1,
        0,
        2,
        4,
        3,
        1,
        1,
        0,
        0,
        4,
        1,
        1,
        0,
        2,
        1,
        1,
        3,
        1,
        0,
        2,
        2,
        1,
        4,
        1,
        3,
        2,
        1,
        0,
        2,
        1,
        0,
        1,
        0,
        3,
        1,
        0,
        4,
        1,
        0,
        4
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        3,
        0,
        0,
        4,
        0,
        3,
        0,
        0,
        0,
        3,
        3,
        0,
        4,
        0,
        2,
        4,
        2,
        0,
        0,
        0,
        3,
        0,
        4,
        0,
        0,
        0,
        2,
        0,
        3,
        4,
        1,
        4,
        0,
        0,
        2,
        3,
        3,
        0,
        2,
        1,
        0,
        4,
        1,
        1,
        3,
        4,
        0,
        1,
        4,
        0,
        0,
        4
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        4,
        0,
        0,
        3,
        1,
        2,
        3,
        1,
        0,
        2,
        2,
        2,
        4,
        3,
        0,
        3,
        3,
        0,
        2,
        4,
        4,
        1,
        3,
        0,
        0,
        2,
        0,
        3,
        4,
        2,
        1,
        4,
        2,
        0,
        0,
        4,
        2,
        1,
        2,
        0,
        2,
        4,
        0,
        1,
        3,
        4,
        2,
        2,
        3,
        1,
        1,
        4
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        3,
        0,
        0,
        1,
        0,
        4,
        0,
        3,
        3,
        0,
        4,
        2,
        4,
        4,
        2,
        4,
        3,
        0,
        3,
        0,
        0,
        4,
        1,
        2,
        0,
        2,
        0,
        0,
        4,
        4,
        1,
        1,
        1,
        1,
        3,
        0,
        4,
        0,
        3,
        2,
        1,
        4,
        3,
        4,
        0,
        2,
        1,
        0,
        1,
        0,
        2,
        2
      ]
    },
    {
      "id": "F1C1E746",
      "weeklyDisrupted": [
        2,
        1,
        4,
        4,
        0,
        3,
        0,
        4,
        2,
        2,
        1,
        2,
        4,
        0,
        2,
        2,
        2,
        1,
        1,
        2,
        0,
        0,
        4,
        0,
        0,
        3,
        0,
        0,
        0,
        0,
        0,
        4,
        1,
        0,
        3,
        4,
        3,
        0,
        4,
        1,
        1,
        3,
        0,
        4,
        2,
        4,
        0,
        2,
        0,
        1,
        4,
        4
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        3,
        0,
        2,
        1,
        0,
        4,
        0,
        4,
        4,
        2,
        4,
        3,
        0,
        1,
        3,
        0,
        2,
        0,
        0,
        1,
        4,
        4,
        2,
        4,
        0,
        0,
        1,
        1,
        4,
        4,
        2,
        2,
        0,
        0,
        0,
        3,
        2,
        1,
        4,
        1,
        1,
        1,
        1,
        2,
        2,
        4,
        2,
        0,
        0,
        2,
        4,
        0
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        2,
        2,
        2,
        1,
        0,
        2,
        0,
        1,
        1,
        3,
        3,
        3,
        1,
        0,
        2,
        0,
        0,
        0,
        3,
        4,
        4,
        4,
        4,
        3,
        0,
        0,
        3,
        0,
        3,
        4,
        3,
        2,
        0,
        0,
        4,
        2,
        2,
        4,
        1,
        0,
        0,
        1,
        2,
        1,
        0,
        1,
        1,
        4,
        3,
        2,
        3,
        1
      ]
    },
    {
      "id": "958C4CED",
      "weeklyDisrupted": [
        2,
        0,
        2,
        0,
        2,
        3,
        2,
        0,
        0,
        4,
        2,
        2,
        4,
        2,
        0,
        2,
        4,
        4,
        0,
        1,
        3,
        4,
        4,
        3,
        0,
        2,
        2,
        1,
        0,
        1,
        2,
        3,
        3,
        2,
        0,
        4,
        2,
        0,
        4,
        0,
        3,
        2,
        1,
        0,
        2,
        3,
        0,
        1,
        3,
        0,
        3,
        1
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        4,
        3,
        2,
        0,
        4,
        3,
        0,
        3,
        2,
        0,
        3,
        2,
        2,
        4,
        2,
        4,
        3,
        1,
        0,
        0,
        0,
        4,
        3,
        0,
        0,
        2,
        0,
        2,
        3,
        1,
        2,
        3,
        0,
        3,
        2,
        1,
        4,
        3,
        3,
        0,
        2,
        2,
        0,
        0,
        1,
        1,
        2,
        0,
        2,
        0,
        0,
        4
      ]
    },
    {
      "id": "0ED43D83",
      "weeklyDisrupted": [
        3,
        1,
        0,
        4,
        0,
        4,
        4,
        0,
        3,
        0,
        1,
        3,
        4,
        0,
        4,
        0,
        2,
        0,
        0,
        1,
        2,
        0,
        0,
        0,
        0,
        4,
        2,
        0,
        2,
        2,
        2,
        4,
        0,
        0,
        3,
        4,
        4,
        4,
        0,
        2,
        3,
        3,
        0,
        4,
        4,
        2,
        2,
        2,
        4,
        2,
        2,
        4
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        0,
        2,
        1,
        4,
        0,
        1,
        0,
        1,
        4,
        4,
        4,
        1,
        3,
        4,
        2,
        3,
        3,
        0,
        3,
        0,
        3,
        0,
        2,
        0,
        0,
        4,
        0,
        4,
        0,
        4,
        0,
        4,
        3,
        0,
        1,
        4,
        4,
        4,
        1,
        2,
        4,
        4,
        1,
        4,
        0,
        0,
        3,
        3,
        4,
        4,
        4,
        1
      ]
    },
    {
      "id": "FB70040E",
      "weeklyDisrupted": [
        4,
        0,
        1,
        0,
        0,
        0,
        1,
        4,
        4,
        0,
        2,
        3,
        3,
        2,
        2,
        2,
        4,
        0,
        1,
        2,
        0,
        2,
        4,
        4,
        2,
        3,
        2,
        2,
        0,
        0,
        3,
        4,
        0,
        2,
        0,
        2,
        3,
        4,
        2,
        1,
        1,
        3,
        0,
        3,
        3,
        2,
        4,
        0,
        1,
        3,
        4,
        0
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        4,
        1,
        0,
        4,
        1,
        3,
        0,
        4,
        0,
        4,
        2,
        0,
        2,
        4,
        4,
        4,
        3,
        3,
        4,
        0,
        3,
        2,
        1,
        2,
        0,
        2,
        2,
        0,
        4,
        4,
        0,
        4,
        1,
        1,
        3,
        1,
        3,
        4,
        4,
        0,
        2,
        1,
        0,
        2,
        0,
        2,
        0,
        0,
        1,
        0,
        1,
        1
      ]
    },
    {
      "id": "05BD5A7D",
      "weeklyDisrupted": [
        3,
        0,
        0,
        4,
        4,
        4,
        4,
        2,
        1,
        0,
        4,
        0,
        0,
        4,
        1,
        3,
        2,
        1,
        0,
        1,
        3,
        4,
        1,
        3,
        0,
        3,
        1,
        0,
        4,
        4,
        3,
        2,
        4,
        0,
        0,
        0,
        0,
        4,
        4,
        0,
        0,
        1,
        2,
        4,
        1,
        2,
        1,
        0,
        2,
        4,
        0,
        0
      ]
    },
    {
      "id": "9FC8D91A",
      "weeklyDisrupted": [
        2,
        4,
        0,
        1,
        4,
        0,
        2,
        0,
        0,
        4,
        2,
        2,
        4,
        1,
        2,
        3,
        4,
        0,
        1,
        2,
        1,
        3,
        0,
        0,
        3,
        2,
        1,
        4,
        2,
        0,
        3,
        3,
        1,
        2,
        0,
        2,
        0,
        4,
        2,
        1,
        0,
        2,
        0,
        4,
        3,
        0,
        0,
        3,
        0,
        3,
        2,
        3
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        2,
        0,
        2,
        0,
        1,
        4,
        0,
        0,
        4,
        0,
        4,
        0,
        4,
        4,
        2,
        4,
        2,
        0,
        1,
        3,
        4,
        3,
        2,
        2,
        1,
        2,
        4,
        2,
        2,
        3,
        0,
        4,
        0,
        0,
        1,
        2,
        4,
        4,
        4,
        2,
        0,
        2,
        0,
        0,
        3,
        4,
        2,
        0,
        3,
        0,
        3,
        4
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        2,
        3,
        0,
        4,
        1,
        3,
        0,
        1,
        4,
        3,
        0,
        0,
        3,
        1,
        4,
        4,
        4,
        2,
        1,
        2,
        2,
        2,
        0,
        0,
        3,
        2,
        0,
        1,
        2,
        2,
        0,
        3,
        3,
        0,
        3,
        0,
        1,
        4,
        4,
        1,
        4,
        2,
        1,
        4,
        0,
        2,
        1,
        2,
        1,
        1,
        1,
        1
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        3,
        1,
        0,
        4,
        0,
        3,
        0,
        1,
        3,
        0,
        4,
        0,
        2,
        4,
        4,
        3,
        0,
        1,
        0,
        1,
        3,
        0,
        4,
        1,
        1,
        1,
        3,
        2,
        4,
        4,
        0,
        4,
        1,
        0,
        1,
        4,
        4,
        4,
        0,
        0,
        2,
        2,
        0,
        2,
        2,
        1,
        1,
        1,
        0,
        1,
        4,
        2
      ]
    },
    {
      "id": "2C1769CF",
      "weeklyDisrupted": [
        3,
        1,
        1,
        0,
        0,
        0,
        0,
        0,
        1,
        4,
        4,
        0,
        0,
        3,
        1,
        4,
        4,
        2,
        4,
        2,
        3,
        3,
        1,
        1,
        0,
        1,
        1,
        1,
        4,
        0,
        0,
        4,
        0,
        1,
        0,
        3,
        2,
        0,
        0,
        1,
        2,
        1,
        0,
        4,
        0,
        0,
        2,
        0,
        1,
        1,
        3,
        3
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        0,
        4,
        2,
        4,
        0,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        0,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        4,
        0,
        2,
        1,
        0,
        1,
        2,
        0,
        1,
        4,
        3,
        0,
        1,
        4,
        4,
        2,
        2,
        2,
        4,
        4,
        0,
        3,
        3,
        4,
        0,
        3,
        2,
        3,
        2,
        0
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        0,
        0,
        0,
        3,
        1,
        3,
        4,
        2,
        4,
        4,
        3,
        0,
        4,
        4,
        2,
        0,
        2,
        0,
        4,
        0,
        4,
        3,
        0,
        0,
        0,
        4,
        0,
        4,
        0,
        4,
        0,
        2,
        3,
        1,
        2,
        1,
        4,
        4,
        0,
        0,
        3,
        4,
        3,
        3,
        3,
        0,
        4,
        0,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "64B9B7F1",
      "weeklyDisrupted": [
        0,
        2,
        0,
        2,
        1,
        2,
        0,
        4,
        3,
        4,
        3,
        0,
        4,
        0,
        1,
        4,
        4,
        0,
        2,
        1,
        1,
        4,
        4,
        1,
        0,
        4,
        2,
        0,
        3,
        3,
        0,
        2,
        4,
        0,
        3,
        2,
        2,
        3,
        4,
        0,
        1,
        2,
        1,
        1,
        1,
        4,
        0,
        0,
        3,
        4,
        3,
        1
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        1,
        1,
        4,
        3,
        0,
        4,
        0,
        1,
        0,
        0,
        2,
        3,
        0,
        3,
        2,
        1,
        0,
        3,
        2,
        4,
        1,
        1,
        0,
        1,
        0,
        1,
        1,
        1,
        2,
        3,
        1,
        2,
        2,
        2,
        0,
        1,
        4,
        0,
        3,
        2,
        3,
        4,
        0,
        0,
        4,
        4,
        4,
        3,
        0,
        2,
        2,
        4
      ]
    },
    {
      "id": "F04529EA",
      "weeklyDisrupted": [
        1,
        0,
        0,
        1,
        3,
        3,
        3,
        0,
        2,
        2,
        3,
        2,
        4,
        1,
        1,
        3,
        2,
        1,
        0,
        0,
        0,
        4,
        1,
        2,
        1,
        1,
        0,
        2,
        1,
        4,
        4,
        2,
        3,
        2,
        3,
        4,
        1,
        0,
        0,
        2,
        0,
        3,
        4,
        2,
        2,
        4,
        0,
        0,
        4,
        4,
        0,
        0
      ]
    },
    {
      "id": "7257F77C",
      "weeklyDisrupted": [
        2,
        1,
        0,
        0,
        0,
        2,
        1,
        0,
        1,
        2,
        2,
        4,
        3,
        4,
        0,
        4,
        3,
        1,
        4,
        3,
        2,
        0,
        2,
        0,
        0,
        0,
        3,
        2,
        3,
        2,
        2,
        4,
        0,
        2,
        0,
        2,
        3,
        4,
        3,
        0,
        0,
        3,
        2,
        3,
        3,
        1,
        0,
        3,
        4,
        0,
        4,
        4
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        2,
        4,
        0,
        2,
        0,
        2,
        3,
        1,
        4,
        0,
        4,
        2,
        0,
        4,
        4,
        3,
        4,
        0,
        0,
        3,
        2,
        4,
        4,
        3,
        0,
        3,
        3,
        1,
        3,
        4,
        4,
        3,
        4,
        2,
        2,
        4,
        3,
        4,
        4,
        2,
        1,
        4,
        0,
        2,
        0,
        4,
        0,
        2,
        1,
        0,
        0,
        4
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        1,
        0,
        1,
        4,
        3,
        2,
        2,
        3,
        4,
        2,
        3,
        1,
        4,
        4,
        0,
        4,
        0,
        2,
        1,
        2,
        0,
        0,
        3,
        2,
        0,
        4,
        0,
        2,
        3,
        4,
        3,
        2,
        3,
        0,
        2,
        4,
        4,
        3,
        4,
        0,
        3,
        3,
        2,
        4,
        1,
        4,
        2,
        0,
        3,
        4,
        0,
        2
      ]
    },
    {
      "id": "14968630",
      "weeklyDisrupted": [
        1,
        0,
        2,
        2,
        4,
        4,
        0,
        4,
        2,
        0,
        3,
        3,
        0,
        1,
        2,
        0,
        2,
        2,
        2,
        1,
        1,
        3,
        1,
        1,
        0,
        3,
        0,
        0,
        0,
        1,
        1,
        3,
        3,
        3,
        4,
        4,
        3,
        0,
        1,
        1,
        3,
        4,
        2,
        2,
        1,
        4,
        0,
        1,
        2,
        1,
        3,
        1
      ]
    },
    {
      "id": "D8F6DF54",
      "weeklyDisrupted": [
        4,
        4,
        2,
        4,
        4,
        3,
        1,
        4,
        1,
        1,
        3,
        2,
        1,
        3,
        2,
        3,
        4,
        0,
        2,
        0,
        4,
        4,
        4,
        3,
        0,
        2,
        2,
        0,
        0,
        4,
        1,
        0,
        4,
        0,
        0,
        4,
        4,
        4,
        4,
        0,
        2,
        2,
        1,
        4,
        1,
        1,
        2,
        2,
        4,
        1,
        2,
        0
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        2,
        4,
        3,
        2,
        0,
        2,
        0,
        4,
        3,
        4,
        2,
        4,
        2,
        1,
        3,
        3,
        3,
        0,
        3,
        1,
        0,
        4,
        4,
        1,
        2,
        2,
        0,
        0,
        0,
        1,
        0,
        3,
        3,
        0,
        3,
        1,
        4,
        2,
        1,
        1,
        1,
        2,
        0,
        4,
        1,
        3,
        0,
        0,
        4,
        0,
        4,
        0
      ]
    },
    {
      "id": "DC3EC10A",
      "weeklyDisrupted": [
        4,
        0,
        0,
        0,
        0,
        2,
        4,
        0,
        2,
        0,
        2,
        3,
        2,
        3,
        0,
        3,
        2,
        4,
        0,
        2,
        0,
        4,
        0,
        0,
        1,
        2,
        2,
        1,
        4,
        2,
        0,
        1,
        0,
        0,
        2,
        1,
        3,
        0,
        1,
        2,
        3,
        2,
        0,
        3,
        0,
        0,
        2,
        1,
        3,
        1,
        1,
        0
      ]
    },
    {
      "id": "542BBC0E",
      "weeklyDisrupted": [
        3,
        4,
        1,
        1,
        1,
        2,
        0,
        2,
        3,
        3,
        4,
        1,
        3,
        4,
        3,
        3,
        4,
        2,
        1,
        0,
        1,
        1,
        0,
        0,
        0,
        4,
        0,
        4,
        0,
        3,
        1,
        2,
        4,
        2,
        3,
        4,
        4,
        4,
        2,
        0,
        3,
        4,
        2,
        4,
        0,
        2,
        1,
        4,
        2,
        4,
        0,
        2
      ]
    },
    {
      "id": "14968630",
      "weeklyDisrupted": [
        4,
        4,
        1,
        4,
        0,
        0,
        0,
        4,
        4,
        3,
        4,
        4,
        4,
        1,
        0,
        0,
        2,
        2,
        3,
        1,
        3,
        2,
        3,
        2,
        0,
        3,
        0,
        4,
        4,
        4,
        0,
        1,
        3,
        0,
        2,
        3,
        4,
        4,
        0,
        0,
        4,
        3,
        2,
        4,
        0,
        4,
        0,
        3,
        1,
        4,
        4,
        2
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        4,
        3,
        0,
        3,
        0,
        4,
        2,
        4,
        1,
        0,
        4,
        0,
        2,
        0,
        2,
        0,
        1,
        0,
        1,
        1,
        4,
        3,
        3,
        1,
        0,
        0,
        0,
        0,
        1,
        3,
        0,
        4,
        0,
        0,
        0,
        4,
        2,
        1,
        1,
        4,
        2,
        0,
        0,
        0,
        4,
        2,
        4,
        1,
        4,
        4,
        2,
        3
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        1,
        0,
        4,
        0,
        2,
        3,
        1,
        1,
        1,
        0,
        1,
        3,
        0,
        0,
        2,
        1,
        3,
        4,
        3,
        0,
        1,
        4,
        4,
        0,
        4,
        1,
        0,
        1,
        0,
        1,
        2,
        3,
        1,
        1,
        1,
        3,
        2,
        1,
        2,
        2,
        4,
        4,
        1,
        4,
        3,
        3,
        0,
        1,
        2,
        2,
        3,
        4
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        4,
        3,
        4,
        3,
        4,
        3,
        0,
        0,
        1,
        1,
        4,
        4,
        4,
        3,
        0,
        3,
        2,
        1,
        4,
        4,
        1,
        0,
        4,
        0,
        0,
        2,
        3,
        0,
        4,
        4,
        0,
        2,
        1,
        1,
        1,
        3,
        4,
        2,
        4,
        0,
        4,
        4,
        3,
        1,
        0,
        4,
        0,
        2,
        1,
        1,
        3,
        0
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        2,
        4,
        4,
        0,
        2,
        1,
        3,
        3,
        2,
        3,
        4,
        1,
        1,
        1,
        1,
        4,
        2,
        0,
        4,
        2,
        3,
        4,
        0,
        0,
        0,
        2,
        1,
        0,
        3,
        4,
        4,
        3,
        2,
        0,
        4,
        3,
        4,
        4,
        0,
        3,
        2,
        1,
        0,
        4,
        3,
        1,
        1,
        3,
        4,
        4,
        2,
        4
      ]
    },
    {
      "id": "88F245C1",
      "weeklyDisrupted": [
        1,
        4,
        4,
        2,
        0,
        2,
        1,
        1,
        2,
        0,
        2,
        4,
        4,
        0,
        2,
        2,
        4,
        0,
        0,
        4,
        0,
        3,
        4,
        3,
        3,
        4,
        4,
        0,
        1,
        4,
        4,
        2,
        3,
        1,
        1,
        4,
        3,
        3,
        1,
        3,
        2,
        2,
        2,
        4,
        2,
        4,
        0,
        2,
        1,
        1,
        1,
        1
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        3,
        0,
        0,
        1,
        0,
        3,
        3,
        2,
        3,
        2,
        3,
        3,
        3,
        0,
        3,
        0,
        2,
        1,
        4,
        0,
        0,
        2,
        0,
        2,
        0,
        0,
        0,
        0,
        0,
        2,
        0,
        0,
        4,
        2,
        0,
        4,
        4,
        1,
        4,
        2,
        4,
        3,
        2,
        4,
        3,
        4,
        0,
        0,
        4,
        1,
        2,
        4
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        2,
        1,
        4,
        2,
        0,
        2,
        1,
        0,
        3,
        1,
        4,
        4,
        3,
        2,
        3,
        0,
        0,
        0,
        0,
        1,
        2,
        4,
        4,
        4,
        3,
        4,
        0,
        0,
        0,
        3,
        4,
        2,
        0,
        2,
        4,
        2,
        4,
        4,
        4,
        4,
        4,
        2,
        2,
        3,
        2,
        4,
        1,
        0,
        2,
        2,
        4,
        3
      ]
    },
    {
      "id": "E0A95BBC",
      "weeklyDisrupted": [
        4,
        2,
        4,
        1,
        0,
        2,
        0,
        3,
        3,
        2,
        2,
        4,
        2,
        4,
        2,
        4,
        4,
        0,
        0,
        4,
        0,
        4,
        2,
        0,
        0,
        2,
        1,
        3,
        3,
        4,
        2,
        2,
        2,
        0,
        2,
        0,
        4,
        0,
        0,
        0,
        2,
        2,
        2,
        4,
        4,
        2,
        1,
        1,
        4,
        2,
        4,
        4
      ]
    },
    {
      "id": "864437DD",
      "weeklyDisrupted": [
        3,
        2,
        0,
        4,
        3,
        1,
        1,
        2,
        3,
        4,
        1,
        4,
        4,
        4,
        4,
        3,
        3,
        1,
        0,
        1,
        0,
        2,
        4,
        0,
        0,
        4,
        1,
        2,
        0,
        3,
        0,
        4,
        0,
        3,
        2,
        3,
        4,
        0,
        3,
        1,
        3,
        0,
        2,
        2,
        3,
        0,
        4,
        1,
        4,
        0,
        4,
        3
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        3,
        3,
        0,
        2,
        0,
        3,
        0,
        1,
        3,
        1,
        2,
        4,
        2,
        1,
        1,
        1,
        4,
        4,
        1,
        3,
        1,
        4,
        1,
        0,
        2,
        4,
        1,
        0,
        2,
        2,
        1,
        3,
        4,
        0,
        0,
        1,
        4,
        0,
        3,
        0,
        2,
        3,
        0,
        1,
        4,
        4,
        1,
        1,
        4,
        4,
        4,
        4
      ]
    },
    {
      "id": "A0E7F6C1",
      "weeklyDisrupted": [
        2,
        2,
        0,
        2,
        1,
        1,
        0,
        4,
        4,
        0,
        3,
        2,
        0,
        4,
        4,
        1,
        1,
        4,
        1,
        4,
        2,
        1,
        3,
        1,
        0,
        0,
        0,
        2,
        4,
        0,
        0,
        2,
        1,
        0,
        0,
        3,
        2,
        1,
        3,
        3,
        4,
        1,
        2,
        2,
        1,
        2,
        1,
        0,
        2,
        4,
        3,
        3
      ]
    },
    {
      "id": "BD2DB5FE",
      "weeklyDisrupted": [
        4,
        0,
        2,
        4,
        2,
        4,
        3,
        4,
        0,
        1,
        0,
        3,
        0,
        4,
        3,
        1,
        2,
        1,
        1,
        1,
        2,
        2,
        1,
        0,
        1,
        3,
        0,
        0,
        3,
        2,
        0,
        3,
        1,
        3,
        3,
        3,
        4,
        1,
        2,
        0,
        4,
        1,
        3,
        1,
        2,
        3,
        1,
        0,
        4,
        4,
        2,
        1
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        0,
        4,
        2,
        4,
        2,
        3,
        2,
        0,
        3,
        0,
        3,
        0,
        4,
        3,
        2,
        3,
        4,
        0,
        1,
        2,
        3,
        4,
        3,
        0,
        2,
        2,
        0,
        3,
        4,
        3,
        3,
        4,
        4,
        0,
        2,
        2,
        4,
        3,
        0,
        3,
        4,
        2,
        0,
        1,
        0,
        3,
        1,
        3,
        1,
        3,
        0,
        0
      ]
    },
    {
      "id": "067037B3",
      "weeklyDisrupted": [
        3,
        2,
        3,
        2,
        3,
        0,
        1,
        3,
        4,
        3,
        4,
        4,
        0,
        4,
        3,
        4,
        4,
        3,
        2,
        4,
        2,
        1,
        0,
        1,
        0,
        2,
        0,
        2,
        4,
        0,
        1,
        3,
        1,
        2,
        4,
        0,
        4,
        2,
        4,
        0,
        3,
        1,
        0,
        2,
        2,
        4,
        1,
        3,
        4,
        2,
        2,
        4
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        4,
        4,
        0,
        3,
        0,
        4,
        4,
        2,
        0,
        3,
        0,
        1,
        1,
        3,
        2,
        0,
        3,
        3,
        0,
        4,
        4,
        2,
        4,
        1,
        0,
        3,
        2,
        0,
        4,
        3,
        0,
        3,
        2,
        2,
        3,
        4,
        2,
        3,
        3,
        2,
        3,
        2,
        0,
        1,
        0,
        4,
        0,
        1,
        0,
        4,
        1,
        3
      ]
    },
    {
      "id": "599EA2B3",
      "weeklyDisrupted": [
        1,
        3,
        0,
        4,
        1,
        4,
        1,
        3,
        0,
        0,
        4,
        1,
        1,
        2,
        4,
        3,
        2,
        3,
        1,
        0,
        4,
        3,
        0,
        2,
        0,
        0,
        2,
        0,
        4,
        3,
        0,
        4,
        2,
        1,
        2,
        2,
        4,
        4,
        3,
        0,
        1,
        2,
        0,
        3,
        0,
        2,
        4,
        1,
        2,
        1,
        1,
        0
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        3,
        4,
        1,
        1,
        1,
        2,
        0,
        4,
        2,
        0,
        4,
        4,
        2,
        3,
        2,
        4,
        0,
        0,
        0,
        3,
        1,
        3,
        4,
        4,
        0,
        0,
        0,
        0,
        4,
        0,
        2,
        2,
        3,
        1,
        0,
        4,
        4,
        0,
        4,
        3,
        4,
        2,
        2,
        3,
        0,
        2,
        1,
        0,
        2,
        0,
        1,
        0
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        3,
        0,
        2,
        3,
        0,
        3,
        0,
        1,
        0,
        0,
        4,
        4,
        0,
        1,
        3,
        0,
        2,
        2,
        3,
        3,
        0,
        0,
        4,
        0,
        0,
        2,
        0,
        0,
        0,
        3,
        3,
        4,
        2,
        1,
        2,
        3,
        2,
        0,
        1,
        0,
        4,
        3,
        0,
        4,
        2,
        0,
        0,
        1,
        4,
        1,
        4,
        0
      ]
    },
    {
      "id": "C71B9801",
      "weeklyDisrupted": [
        4,
        2,
        4,
        3,
        4,
        4,
        0,
        0,
        1,
        3,
        3,
        3,
        2,
        4,
        1,
        3,
        3,
        0,
        3,
        3,
        3,
        4,
        3,
        0,
        0,
        0,
        0,
        0,
        4,
        4,
        0,
        1,
        0,
        1,
        0,
        4,
        2,
        0,
        0,
        1,
        2,
        2,
        3,
        4,
        4,
        0,
        0,
        0,
        4,
        4,
        3,
        3
      ]
    },
    {
      "id": "58D36D79",
      "weeklyDisrupted": [
        3,
        1,
        0,
        0,
        1,
        0,
        0,
        1,
        4,
        0,
        4,
        2,
        0,
        3,
        4,
        1,
        1,
        0,
        4,
        1,
        0,
        3,
        0,
        2,
        1,
        3,
        0,
        0,
        4,
        3,
        0,
        2,
        2,
        0,
        4,
        0,
        4,
        4,
        0,
        4,
        4,
        1,
        3,
        4,
        2,
        3,
        0,
        1,
        2,
        1,
        0,
        4
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        1,
        4,
        4,
        2,
        4,
        1,
        4,
        1,
        4,
        0,
        1,
        4,
        4,
        4,
        3,
        1,
        3,
        0,
        4,
        2,
        4,
        4,
        3,
        0,
        0,
        2,
        0,
        1,
        0,
        2,
        0,
        3,
        1,
        1,
        4,
        4,
        4,
        4,
        3,
        3,
        0,
        4,
        1,
        2,
        2,
        4,
        0,
        2,
        3,
        0,
        4,
        0
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        4,
        4,
        3,
        1,
        0,
        2,
        0,
        4,
        4,
        4,
        1,
        2,
        2,
        4,
        4,
        1,
        3,
        0,
        2,
        1,
        4,
        2,
        1,
        0,
        0,
        0,
        1,
        3,
        1,
        3,
        0,
        2,
        0,
        3,
        1,
        4,
        4,
        4,
        0,
        0,
        4,
        3,
        2,
        0,
        0,
        2,
        2,
        2,
        1,
        2,
        2,
        0
      ]
    },
    {
      "id": "74B98557",
      "weeklyDisrupted": [
        3,
        0,
        0,
        1,
        1,
        4,
        4,
        3,
        2,
        1,
        4,
        3,
        3,
        2,
        4,
        2,
        3,
        0,
        2,
        3,
        0,
        3,
        1,
        3,
        1,
        0,
        1,
        0,
        2,
        0,
        4,
        4,
        2,
        1,
        4,
        4,
        2,
        0,
        3,
        2,
        0,
        3,
        4,
        4,
        0,
        2,
        0,
        4,
        2,
        1,
        2,
        0
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        4,
        3,
        0,
        3,
        2,
        2,
        4,
        0,
        0,
        4,
        3,
        2,
        3,
        2,
        1,
        0,
        3,
        1,
        0,
        0,
        1,
        4,
        4,
        0,
        0,
        0,
        2,
        0,
        2,
        2,
        0,
        4,
        4,
        0,
        1,
        3,
        4,
        4,
        1,
        2,
        2,
        4,
        1,
        2,
        0,
        0,
        1,
        0,
        3,
        1,
        2,
        1
      ]
    },
    {
      "id": "0D70FFB7",
      "weeklyDisrupted": [
        3,
        4,
        0,
        4,
        1,
        1,
        1,
        2,
        2,
        2,
        4,
        0,
        4,
        1,
        0,
        2,
        4,
        0,
        0,
        4,
        1,
        4,
        3,
        0,
        0,
        0,
        0,
        2,
        4,
        1,
        3,
        4,
        4,
        1,
        1,
        3,
        0,
        4,
        0,
        0,
        4,
        4,
        1,
        2,
        4,
        3,
        0,
        2,
        4,
        3,
        3,
        4
      ]
    },
    {
      "id": "CD9A49D0",
      "weeklyDisrupted": [
        1,
        4,
        0,
        0,
        2,
        0,
        2,
        0,
        1,
        4,
        4,
        3,
        1,
        3,
        1,
        1,
        3,
        4,
        0,
        4,
        4,
        0,
        0,
        1,
        0,
        3,
        3,
        1,
        4,
        4,
        2,
        3,
        4,
        2,
        4,
        3,
        1,
        2,
        0,
        0,
        0,
        2,
        4,
        4,
        0,
        1,
        2,
        1,
        4,
        3,
        3,
        3
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        4,
        3,
        2,
        4,
        4,
        1,
        2,
        0,
        4,
        0,
        2,
        4,
        1,
        0,
        2,
        3,
        3,
        0,
        2,
        0,
        4,
        3,
        2,
        1,
        0,
        0,
        2,
        4,
        2,
        0,
        2,
        4,
        0,
        0,
        0,
        1,
        4,
        4,
        0,
        1,
        0,
        2,
        2,
        4,
        1,
        1,
        3,
        2,
        1,
        1,
        4,
        2
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        3,
        2,
        0,
        0,
        4,
        2,
        2,
        0,
        4,
        0,
        3,
        4,
        3,
        0,
        2,
        2,
        4,
        4,
        1,
        3,
        0,
        0,
        3,
        1,
        2,
        2,
        2,
        0,
        4,
        4,
        1,
        4,
        3,
        1,
        4,
        3,
        4,
        3,
        1,
        1,
        3,
        0,
        0,
        4,
        0,
        0,
        2,
        4,
        1,
        4,
        3,
        2
      ]
    },
    {
      "id": "93D87233",
      "weeklyDisrupted": [
        4,
        4,
        4,
        1,
        2,
        0,
        1,
        4,
        4,
        0,
        0,
        0,
        4,
        1,
        1,
        1,
        2,
        3,
        0,
        0,
        2,
        3,
        3,
        2,
        2,
        3,
        2,
        0,
        0,
        0,
        4,
        0,
        0,
        4,
        1,
        2,
        1,
        0,
        3,
        0,
        3,
        4,
        0,
        2,
        0,
        3,
        2,
        0,
        1,
        2,
        2,
        4
      ]
    },
    {
      "id": "FFE4F1A9",
      "weeklyDisrupted": [
        4,
        2,
        0,
        0,
        4,
        0,
        0,
        1,
        4,
        1,
        4,
        4,
        2,
        2,
        1,
        0,
        4,
        0,
        2,
        1,
        1,
        1,
        0,
        0,
        0,
        3,
        1,
        4,
        4,
        4,
        4,
        4,
        4,
        0,
        2,
        2,
        1,
        4,
        3,
        0,
        3,
        3,
        3,
        4,
        0,
        3,
        1,
        2,
        1,
        2,
        2,
        3
      ]
    },
    {
      "id": "7B415249",
      "weeklyDisrupted": [
        2,
        0,
        0,
        1,
        0,
        3,
        0,
        3,
        4,
        2,
        4,
        1,
        4,
        0,
        2,
        1,
        1,
        3,
        0,
        0,
        2,
        2,
        3,
        1,
        2,
        4,
        1,
        3,
        2,
        0,
        1,
        4,
        1,
        0,
        0,
        3,
        3,
        1,
        2,
        1,
        0,
        3,
        2,
        3,
        0,
        1,
        0,
        0,
        4,
        1,
        3,
        4
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        4,
        4,
        0,
        4,
        0,
        4,
        3,
        0,
        1,
        4,
        4,
        0,
        3,
        0,
        2,
        4,
        2,
        2,
        0,
        1,
        3,
        3,
        1,
        2,
        2,
        1,
        0,
        0,
        4,
        4,
        0,
        4,
        0,
        1,
        1,
        3,
        3,
        3,
        1,
        4,
        3,
        2,
        0,
        4,
        2,
        0,
        0,
        1,
        4,
        1,
        2,
        1
      ]
    },
    {
      "id": "42A1729B",
      "weeklyDisrupted": [
        4,
        1,
        4,
        2,
        0,
        4,
        4,
        0,
        1,
        0,
        3,
        0,
        1,
        1,
        1,
        2,
        4,
        3,
        2,
        3,
        0,
        2,
        4,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        2,
        2,
        0,
        0,
        2,
        4,
        3,
        1,
        1,
        0,
        4,
        3,
        0,
        4,
        0,
        1,
        0,
        2,
        3,
        3,
        1,
        0
      ]
    },
    {
      "id": "11102F47",
      "weeklyDisrupted": [
        3,
        0,
        0,
        2,
        0,
        2,
        0,
        0,
        4,
        0,
        3,
        1,
        0,
        0,
        2,
        3,
        2,
        1,
        1,
        0,
        0,
        4,
        3,
        1,
        0,
        4,
        0,
        0,
        0,
        2,
        0,
        2,
        2,
        2,
        2,
        4,
        3,
        0,
        0,
        4,
        3,
        1,
        1,
        4,
        0,
        0,
        2,
        1,
        0,
        0,
        2,
        4
      ]
    },
    {
      "id": "CDA15976",
      "weeklyDisrupted": [
        4,
        4,
        2,
        2,
        1,
        0,
        4,
        3,
        1,
        2,
        3,
        4,
        3,
        1,
        2,
        2,
        4,
        4,
        2,
        1,
        3,
        3,
        2,
        0,
        0,
        2,
        1,
        2,
        1,
        3,
        4,
        4,
        0,
        0,
        0,
        3,
        4,
        3,
        1,
        4,
        4,
        4,
        0,
        2,
        3,
        1,
        1,
        2,
        0,
        2,
        2,
        3
      ]
    },
    {
      "id": "CCD70D1D",
      "weeklyDisrupted": [
        4,
        1,
        4,
        4,
        2,
        2,
        2,
        2,
        3,
        0,
        3,
        0,
        4,
        0,
        1,
        4,
        3,
        0,
        2,
        4,
        3,
        4,
        4,
        0,
        0,
        3,
        2,
        0,
        2,
        4,
        4,
        4,
        0,
        4,
        4,
        3,
        3,
        1,
        0,
        0,
        3,
        4,
        0,
        0,
        4,
        0,
        0,
        1,
        4,
        2,
        3,
        0
      ]
    },
    {
      "id": "02A8E856",
      "weeklyDisrupted": [
        3,
        2,
        2,
        4,
        0,
        2,
        3,
        2,
        4,
        3,
        3,
        0,
        4,
        4,
        2,
        4,
        2,
        1,
        0,
        4,
        1,
        0,
        4,
        0,
        2,
        3,
        2,
        1,
        4,
        4,
        2,
        4,
        2,
        2,
        0,
        4,
        4,
        3,
        4,
        3,
        4,
        2,
        0,
        1,
        4,
        4,
        0,
        4,
        3,
        0,
        2,
        0
      ]
    },
    {
      "id": "7670C5F5",
      "weeklyDisrupted": [
        3,
        0,
        4,
        2,
        4,
        4,
        0,
        0,
        0,
        0,
        4,
        0,
        2,
        3,
        0,
        2,
        2,
        0,
        2,
        3,
        4,
        4,
        0,
        4,
        0,
        4,
        2,
        0,
        3,
        4,
        4,
        2,
        2,
        3,
        0,
        3,
        2,
        4,
        0,
        1,
        0,
        3,
        4,
        2,
        3,
        3,
        3,
        0,
        4,
        2,
        3,
        0
      ]
    },
    {
      "id": "9EAD0C19",
      "weeklyDisrupted": [
        1,
        2,
        4,
        2,
        0,
        4,
        0,
        1,
        4,
        2,
        1,
        1,
        4,
        3,
        2,
        4,
        3,
        2,
        0,
        2,
        3,
        1,
        4,
        0,
        1,
        4,
        0,
        3,
        3,
        1,
        3,
        4,
        1,
        0,
        4,
        1,
        1,
        3,
        2,
        1,
        4,
        1,
        3,
        4,
        0,
        3,
        4,
        0,
        2,
        3,
        2,
        4
      ]
    },
    {
      "id": "D33377F5",
      "weeklyDisrupted": [
        0,
        3,
        2,
        1,
        0,
        0,
        0,
        3,
        0,
        4,
        4,
        1,
        2,
        3,
        2,
        4,
        1,
        1,
        1,
        2,
        3,
        0,
        4,
        2,
        1,
        3,
        1,
        0,
        0,
        0,
        1,
        4,
        3,
        0,
        0,
        4,
        3,
        3,
        1,
        4,
        1,
        4,
        0,
        1,
        0,
        4,
        4,
        0,
        4,
        1,
        4,
        1
      ]
    },
    {
      "id": "ACF7D73A",
      "weeklyDisrupted": [
        1,
        0,
        0,
        4,
        1,
        3,
        0,
        3,
        0,
        3,
        4,
        3,
        2,
        3,
        3,
        4,
        4,
        0,
        2,
        3,
        1,
        1,
        4,
        2,
        0,
        2,
        2,
        1,
        3,
        3,
        4,
        4,
        2,
        0,
        4,
        4,
        4,
        4,
        0,
        0,
        0,
        3,
        0,
        4,
        2,
        0,
        1,
        0,
        4,
        0,
        0,
        1
      ]
    },
    {
      "id": "271D2E55",
      "weeklyDisrupted": [
        3,
        0,
        1,
        2,
        1,
        2,
        1,
        3,
        0,
        0,
        4,
        4,
        0,
        2,
        4,
        3,
        3,
        1,
        3,
        0,
        1,
        0,
        3,
        2,
        0,
        4,
        0,
        1,
        2,
        4,
        3,
        4,
        1,
        0,
        0,
        3,
        2,
        0,
        4,
        0,
        1,
        2,
        4,
        4,
        2,
        1,
        2,
        2,
        4,
        1,
        2,
        4
      ]
    },
    {
      "id": "DC16E9E8",
      "weeklyDisrupted": [
        2,
        0,
        0,
        3,
        0,
        2,
        0,
        3,
        4,
        0,
        3,
        0,
        2,
        0,
        2,
        2,
        2,
        3,
        0,
        1,
        3,
        2,
        4,
        1,
        0,
        1,
        0,
        0,
        2,
        2,
        0,
        4,
        1,
        2,
        3,
        2,
        1,
        4,
        0,
        3,
        3,
        2,
        0,
        1,
        1,
        4,
        0,
        4,
        4,
        2,
        3,
        0
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        0,
        1,
        2,
        3,
        1,
        4,
        3,
        2,
        3,
        4,
        1,
        4,
        3,
        4,
        4,
        3,
        3,
        0,
        0,
        0,
        0,
        0,
        4,
        4,
        1,
        3,
        0,
        0,
        1,
        3,
        1,
        2,
        0,
        0,
        4,
        4,
        3,
        4,
        2,
        0,
        2,
        2,
        1,
        0,
        4,
        4,
        0,
        2,
        4,
        2,
        1,
        2
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        4,
        1,
        0,
        1,
        0,
        4,
        3,
        4,
        0,
        3,
        4,
        4,
        2,
        4,
        4,
        1,
        2,
        0,
        4,
        3,
        3,
        4,
        2,
        2,
        2,
        3,
        2,
        2,
        3,
        2,
        0,
        2,
        2,
        3,
        1,
        2,
        4,
        1,
        0,
        3,
        3,
        4,
        0,
        4,
        0,
        0,
        1,
        3,
        3,
        4,
        3,
        3
      ]
    },
    {
      "id": "6E657E21",
      "weeklyDisrupted": [
        3,
        4,
        3,
        2,
        1,
        2,
        0,
        0,
        0,
        4,
        4,
        4,
        4,
        0,
        2,
        0,
        2,
        4,
        1,
        4,
        4,
        3,
        4,
        0,
        1,
        0,
        3,
        1,
        4,
        4,
        3,
        4,
        4,
        2,
        3,
        3,
        4,
        0,
        0,
        0,
        2,
        2,
        0,
        4,
        0,
        0,
        0,
        3,
        0,
        4,
        4,
        3
      ]
    },
    {
      "id": "CB6FF509",
      "weeklyDisrupted": [
        4,
        3,
        4,
        2,
        0,
        3,
        0,
        3,
        4,
        1,
        4,
        2,
        4,
        0,
        4,
        3,
        3,
        0,
        1,
        1,
        0,
        0,
        0,
        0,
        4,
        0,
        0,
        0,
        4,
        3,
        0,
        2,
        1,
        2,
        4,
        4,
        3,
        0,
        3,
        1,
        4,
        4,
        2,
        4,
        2,
        1,
        0,
        4,
        4,
        2,
        1,
        3
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        3,
        0,
        4,
        3,
        2,
        2,
        2,
        0,
        1,
        0,
        3,
        0,
        0,
        2,
        3,
        0,
        0,
        2,
        2,
        0,
        4,
        4,
        0,
        0,
        0,
        1,
        1,
        4,
        1,
        2,
        2,
        3,
        2,
        0,
        4,
        0,
        2,
        2,
        0,
        0,
        0,
        2,
        0,
        4,
        4,
        4,
        0,
        4,
        4,
        0,
        0,
        4
      ]
    },
    {
      "id": "7D206E43",
      "weeklyDisrupted": [
        0,
        1,
        3,
        0,
        0,
        4,
        0,
        0,
        1,
        0,
        2,
        2,
        2,
        1,
        1,
        1,
        4,
        2,
        0,
        4,
        3,
        0,
        4,
        1,
        0,
        0,
        2,
        0,
        3,
        4,
        0,
        3,
        0,
        1,
        2,
        0,
        4,
        0,
        0,
        0,
        1,
        4,
        3,
        3,
        1,
        4,
        0,
        2,
        4,
        3,
        4,
        0
      ]
    },
    {
      "id": "11660B2E",
      "weeklyDisrupted": [
        2,
        0,
        0,
        1,
        3,
        1,
        1,
        0,
        3,
        3,
        2,
        2,
        3,
        3,
        0,
        1,
        2,
        4,
        1,
        3,
        4,
        2,
        2,
        4,
        0,
        0,
        2,
        1,
        0,
        0,
        1,
        2,
        0,
        2,
        0,
        3,
        2,
        0,
        0,
        0,
        4,
        4,
        3,
        1,
        0,
        2,
        0,
        3,
        3,
        3,
        4,
        4
      ]
    },
    {
      "id": "79FC1E21",
      "weeklyDisrupted": [
        1,
        3,
        0,
        3,
        0,
        2,
        1,
        2,
        1,
        4,
        3,
        4,
        2,
        4,
        2,
        4,
        2,
        0,
        3,
        2,
        2,
        1,
        3,
        0,
        3,
        4,
        2,
        3,
        2,
        0,
        4,
        4,
        2,
        2,
        2,
        2,
        4,
        4,
        4,
        1,
        0,
        4,
        4,
        2,
        2,
        4,
        3,
        0,
        4,
        4,
        3,
        2
      ]
    },
    {
      "id": "DF6676C9",
      "weeklyDisrupted": [
        0,
        2,
        3,
        3,
        4,
        3,
        2,
        3,
        1,
        0,
        2,
        2,
        2,
        2,
        4,
        0,
        3,
        0,
        3,
        4,
        4,
        4,
        0,
        1,
        2,
        2,
        1,
        2,
        3,
        2,
        2,
        3,
        0,
        3,
        1,
        2,
        0,
        2,
        1,
        2,
        1,
        4,
        0,
        0,
        1,
        3,
        1,
        0,
        2,
        0,
        0,
        2
      ]
    },
    {
      "id": "7B500D6B",
      "weeklyDisrupted": [
        3,
        1,
        3,
        2,
        2,
        3,
        4,
        1,
        4,
        0,
        3,
        4,
        4,
        4,
        1,
        3,
        4,
        0,
        0,
        2,
        1,
        3,
        4,
        0,
        0,
        4,
        2,
        3,
        0,
        4,
        4,
        4,
        4,
        3,
        0,
        4,
        4,
        3,
        0,
        0,
        0,
        2,
        4,
        3,
        4,
        4,
        1,
        4,
        3,
        2,
        3,
        1
      ]
    },
    {
      "id": "9DD6903A",
      "weeklyDisrupted": [
        0,
        4,
        1,
        2,
        4,
        0,
        4,
        4,
        3,
        1,
        1,
        2,
        2,
        1,
        3,
        4,
        1,
        1,
        0,
        4,
        2,
        4,
        4,
        2,
        3,
        2,
        0,
        0,
        0,
        0,
        2,
        4,
        0,
        0,
        2,
        3,
        2,
        4,
        0,
        2,
        1,
        1,
        0,
        4,
        1,
        3,
        1,
        2,
        0,
        1,
        2,
        4
      ]
    },
    {
      "id": "66AB5D2E",
      "weeklyDisrupted": [
        4,
        0,
        4,
        0,
        2,
        3,
        0,
        2,
        2,
        1,
        4,
        1,
        2,
        4,
        1,
        1,
        3,
        1,
        2,
        1,
        1,
        1,
        3,
        0,
        0,
        3,
        2,
        3,
        0,
        1,
        0,
        2,
        0,
        0,
        1,
        2,
        4,
        2,
        0,
        0,
        3,
        3,
        4,
        4,
        3,
        4,
        1,
        2,
        2,
        0,
        2,
        3
      ]
    },
    {
      "id": "DB301E71",
      "weeklyDisrupted": [
        3,
        4,
        3,
        3,
        2,
        3,
        1,
        0,
        1,
        3,
        4,
        2,
        0,
        2,
        1,
        2,
        1,
        0,
        1,
        1,
        1,
        4,
        2,
        1,
        0,
        3,
        2,
        2,
        4,
        3,
        2,
        4,
        0,
        1,
        0,
        0,
        4,
        4,
        2,
        0,
        2,
        3,
        1,
        3,
        0,
        2,
        1,
        0,
        4,
        4,
        3,
        4
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        0,
        4,
        1,
        4,
        2,
        2,
        0,
        4,
        4,
        1,
        4,
        0,
        3,
        0,
        3,
        4,
        4,
        1,
        0,
        0,
        2,
        4,
        4,
        0,
        0,
        4,
        1,
        0,
        3,
        1,
        1,
        3,
        4,
        3,
        4,
        3,
        4,
        4,
        0,
        0,
        3,
        1,
        2,
        3,
        0,
        0,
        4,
        4,
        0,
        4,
        3,
        4
      ]
    },
    {
      "id": "85ECBAF5",
      "weeklyDisrupted": [
        4,
        3,
        0,
        1,
        1,
        2,
        0,
        4,
        3,
        0,
        2,
        3,
        4,
        4,
        2,
        1,
        4,
        1,
        4,
        0,
        3,
        0,
        1,
        2,
        3,
        4,
        0,
        2,
        4,
        1,
        2,
        2,
        0,
        4,
        0,
        0,
        4,
        3,
        2,
        2,
        3,
        4,
        4,
        4,
        3,
        3,
        4,
        1,
        4,
        1,
        3,
        1
      ]
    },
    {
      "id": "5EDCDAE3",
      "weeklyDisrupted": [
        2,
        4,
        0,
        4,
        0,
        1,
        0,
        3,
        4,
        1,
        1,
        4,
        4,
        1,
        3,
        1,
        2,
        3,
        0,
        2,
        0,
        0,
        4,
        1,
        0,
        4,
        1,
        0,
        1,
        0,
        0,
        0,
        3,
        2,
        0,
        3,
        4,
        3,
        1,
        1,
        4,
        1,
        1,
        1,
        4,
        4,
        0,
        0,
        1,
        0,
        4,
        3
      ]
    },
    {
      "id": "B688BAF3",
      "weeklyDisrupted": [
        3,
        0,
        3,
        3,
        0,
        3,
        1,
        0,
        1,
        0,
        4,
        3,
        3,
        2,
        3,
        0,
        2,
        0,
        1,
        0,
        4,
        4,
        0,
        2,
        0,
        0,
        1,
        3,
        0,
        3,
        1,
        2,
        3,
        1,
        2,
        0,
        4,
        1,
        0,
        0,
        3,
        2,
        2,
        2,
        3,
        4,
        0,
        0,
        3,
        4,
        3,
        2
      ]
    },
    {
      "id": "F413914D",
      "weeklyDisrupted": [
        0,
        2,
        0,
        2,
        4,
        4,
        1,
        3,
        0,
        3,
        4,
        2,
        1,
        3,
        0,
        4,
        3,
        1,
        2,
        3,
        0,
        4,
        4,
        0,
        0,
        3,
        0,
        1,
        4,
        4,
        1,
        4,
        4,
        0,
        2,
        1,
        3,
        4,
        1,
        0,
        3,
        4,
        0,
        3,
        0,
        2,
        1,
        4,
        2,
        4,
        2,
        0
      ]
    },
    {
      "id": "8607492C",
      "weeklyDisrupted": [
        4,
        0,
        4,
        2,
        0,
        1,
        0,
        2,
        2,
        0,
        2,
        2,
        3,
        4,
        2,
        4,
        4,
        0,
        2,
        4,
        0,
        4,
        4,
        4,
        0,
        2,
        0,
        2,
        1,
        3,
        4,
        3,
        2,
        1,
        4,
        2,
        3,
        4,
        2,
        0,
        2,
        1,
        2,
        1,
        0,
        2,
        0,
        0,
        3,
        4,
        3,
        3
      ]
    },
    {
      "id": "1EBC5378",
      "weeklyDisrupted": [
        1,
        0,
        1,
        0,
        4,
        4,
        1,
        3,
        4,
        2,
        1,
        4,
        2,
        0,
        4,
        0,
        3,
        0,
        0,
        2,
        3,
        1,
        2,
        3,
        0,
        2,
        0,
        3,
        4,
        3,
        3,
        4,
        0,
        4,
        2,
        2,
        1,
        4,
        1,
        1,
        0,
        4,
        1,
        2,
        0,
        3,
        2,
        1,
        3,
        4,
        0,
        2
      ]
    },
    {
      "id": "3C0BE0E7",
      "weeklyDisrupted": [
        3,
        4,
        4,
        2,
        0,
        2,
        0,
        4,
        2,
        1,
        2,
        4,
        2,
        1,
        4,
        1,
        2,
        2,
        1,
        1,
        0,
        2,
        2,
        2,
        0,
        2,
        0,
        0,
        1,
        1,
        2,
        4,
        0,
        0,
        4,
        4,
        4,
        4,
        0,
        2,
        3,
        3,
        1,
        4,
        0,
        4,
        0,
        4,
        4,
        0,
        4,
        2
      ]
    },
    {
      "id": "3D26994F",
      "weeklyDisrupted": [
        4,
        4,
        1,
        1,
        1,
        2,
        0,
        2,
        1,
        0,
        4,
        4,
        1,
        2,
        4,
        1,
        2,
        4,
        3,
        2,
        3,
        3,
        0,
        0,
        0,
        3,
        1,
        0,
        1,
        1,
        1,
        4,
        0,
        1,
        0,
        4,
        4,
        0,
        4,
        2,
        2,
        3,
        0,
        4,
        0,
        1,
        1,
        2,
        3,
        4,
        4,
        3
      ]
    },
    {
      "id": "BB20277D",
      "weeklyDisrupted": [
        1,
        3,
        4,
        0,
        0,
        4,
        1,
        3,
        3,
        3,
        2,
        3,
        0,
        0,
        1,
        3,
        2,
        0,
        0,
        0,
        4,
        1,
        1,
        0,
        0,
        2,
        0,
        0,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        3,
        3,
        4,
        0,
        4,
        4,
        2,
        1,
        4,
        2,
        2,
        4,
        1,
        2,
        3,
        1,
        4
      ]
    },
    {
      "id": "F55885BD",
      "weeklyDisrupted": [
        3,
        3,
        0,
        4,
        0,
        4,
        4,
        4,
        4,
        1,
        1,
        0,
        4,
        0,
        4,
        1,
        4,
        0,
        0,
        0,
        0,
        4,
        4,
        4,
        2,
        2,
        2,
        1,
        0,
        0,
        2,
        1,
        2,
        1,
        4,
        3,
        0,
        4,
        2,
        2,
        3,
        4,
        1,
        1,
        0,
        2,
        0,
        4,
        4,
        1,
        1,
        2
      ]
    },
    {
      "id": "A87CEAA3",
      "weeklyDisrupted": [
        3,
        3,
        0,
        2,
        2,
        2,
        1,
        4,
        3,
        1,
        1,
        1,
        3,
        3,
        0,
        1,
        4,
        2,
        4,
        4,
        1,
        2,
        1,
        0,
        0,
        2,
        1,
        0,
        4,
        4,
        0,
        3,
        0,
        2,
        0,
        4,
        3,
        4,
        4,
        1,
        1,
        4,
        1,
        3,
        2,
        2,
        4,
        1,
        4,
        4,
        1,
        4
      ]
    },
    {
      "id": "5F3AFC23",
      "weeklyDisrupted": [
        3,
        0,
        0,
        0,
        1,
        0,
        0,
        2,
        2,
        0,
        4,
        3,
        2,
        3,
        1,
        4,
        4,
        0,
        4,
        0,
        4,
        0,
        0,
        1,
        0,
        2,
        1,
        4,
        2,
        0,
        0,
        2,
        4,
        0,
        1,
        3,
        0,
        2,
        0,
        1,
        0,
        3,
        2,
        0,
        0,
        1,
        0,
        1,
        3,
        4,
        1,
        4
      ]
    },
    {
      "id": "7A8CC6BD",
      "weeklyDisrupted": [
        0,
        2,
        1,
        1,
        2,
        2,
        2,
        0,
        1,
        2,
        2,
        2,
        4,
        0,
        1,
        2,
        0,
        1,
        0,
        0,
        3,
        2,
        2,
        0,
        0,
        0,
        0,
        4,
        3,
        3,
        0,
        4,
        3,
        1,
        1,
        2,
        3,
        4,
        0,
        3,
        3,
        1,
        0,
        3,
        4,
        3,
        0,
        2,
        3,
        2,
        3,
        1
      ]
    },
    {
      "id": "065DB72B",
      "weeklyDisrupted": [
        1,
        3,
        0,
        4,
        1,
        2,
        1,
        0,
        2,
        4,
        4,
        1,
        3,
        0,
        3,
        4,
        3,
        4,
        0,
        4,
        2,
        4,
        1,
        1,
        4,
        3,
        2,
        3,
        3,
        4,
        0,
        2,
        4,
        0,
        2,
        4,
        0,
        4,
        1,
        2,
        3,
        1,
        0,
        3,
        2,
        0,
        0,
        2,
        1,
        3,
        4,
        1
      ]
    },
    {
      "id": "BD23BA16",
      "weeklyDisrupted": [
        1,
        4,
        2,
        4,
        2,
        0,
        1,
        4,
        2,
        2,
        4,
        2,
        4,
        2,
        3,
        4,
        1,
        2,
        4,
        0,
        0,
        2,
        4,
        4,
        4,
        2,
        2,
        0,
        4,
        3,
        0,
        2,
        4,
        1,
        2,
        4,
        3,
        0,
        3,
        1,
        4,
        0,
        3,
        4,
        0,
        3,
        3,
        1,
        4,
        2,
        4,
        2
      ]
    },
    {
      "id": "22633EF7",
      "weeklyDisrupted": [
        4,
        2,
        4,
        0,
        1,
        3,
        0,
        2,
        4,
        3,
        3,
        4,
        4,
        4,
        2,
        1,
        2,
        1,
        4,
        2,
        3,
        0,
        1,
        1,
        0,
        1,
        0,
        2,
        3,
        0,
        0,
        2,
        4,
        0,
        2,
        4,
        4,
        3,
        0,
        0,
        2,
        4,
        0,
        2,
        1,
        4,
        0,
        0,
        4,
        1,
        3,
        1
      ]
    },
    {
      "id": "4E1CFAD9",
      "weeklyDisrupted": [
        3,
        3,
        2,
        3,
        1,
        3,
        2,
        3,
        4,
        2,
        4,
        2,
        0,
        1,
        0,
        4,
        2,
        4,
        0,
        4,
        2,
        4,
        4,
        0,
        2,
        3,
        1,
        1,
        4,
        4,
        3,
        3,
        0,
        0,
        3,
        4,
        4,
        4,
        4,
        0,
        2,
        1,
        0,
        3,
        2,
        2,
        3,
        0,
        4,
        0,
        1,
        0
      ]
    }
  ]