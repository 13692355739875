import { ReactNode } from "react"

const ListItemRow = (props: {
    title: string,
    subtitle?: string,
    subtitleHTML?: ReactNode,
    icon?: {
        icon: string,
        gradient: string,
    },
    button?: {
        title: string
    },
    arrowLabel?: string,
    titleArrowBottom?: boolean,
    titleArrowTop?: boolean,
    arrowRight?: boolean,
    hideBorder?: boolean,
    hideBackground?: boolean,
    iconSize?: number,
    children?: ReactNode,
    onArrowClick?: () => void
}) => {
    return (
        <div onClick={props.onArrowClick} style={{ borderWidth: `${props.hideBorder ? '' : '1px'}`, backgroundColor: `${props.hideBackground ? 'transparent' : 'white'}` }} className="flex border-neutral-2 rounded-md h-[46px] items-center px-3 py-6 gap-2 cursor-pointer">
            {props.icon && (props.titleArrowBottom || props.titleArrowTop) && (
                <div className="flex flex-col items-start py-1.5 h-[24px]">
                    {props.titleArrowBottom && (
                        <img className="w-[10px] text-black" src={'/images/icons/ic-chevron-down.svg'}></img>
                    )}

                    {props.titleArrowTop && (
                        <img className="w-[10px] text-black" src={'/images/icons/ic-chevron-top.svg'}></img>
                    )}
                </div>
            )}

            {props.icon && (
                <div style={{ minWidth: `${props.iconSize || 26}px`, height: `${props.iconSize || 26}px` }} className={`gradient-${props.icon.gradient} rounded-md flex items-center justify-center`}>
                    <img className="h-[16px] w-[16px]" src={`/images/icons/${props.icon?.icon}`}></img>
                </div>
            )}

            <div className="mr-auto w-full">
                <div className="flex gap-1.5">
                    {!props.icon && (props.titleArrowBottom || props.titleArrowTop) && (
                        <>
                            {
                                props.titleArrowBottom && (
                                    <img className="w-[10px] text-black" src={'/images/icons/ic-chevron-down.svg'}></img>
                                )
                            }

                            {props.titleArrowTop && (
                                <img className="w-[10px] text-black" src={'/images/icons/ic-chevron-top.svg'}></img>
                            )}
                        </>
                    )}

                    <p className="font-body text-neutral-6 w-fit line-clamp-1">{props.title}</p>
                </div>

                {props.subtitleHTML ?
                    props.subtitleHTML
                    :
                    props.subtitle && (
                        <p className="font-subheadline text-neutral-5 line-clamp-1">{props.subtitle}</p>
                    )
                }
            </div>

            {props.children}

            {props.arrowLabel && (
                <p className="font-body text-neutral-5 w-fit whitespace-nowrap">
                    {props.arrowLabel}
                </p>
            )}

            {props.arrowRight && (
                <img className="pl-1 h-[13px] w=[10px]" src={process.env.PUBLIC_URL + '/images/icons/ic-chevron-right.svg'}></img>
            )}
        </div>
    )
}

export default ListItemRow