import { User } from "../../model/user/user"

const ProfilePicture = (props: { user: User | null | undefined, size: number }) => {
    return (
        <>
            {props.user?.photoURL ? <img style={{ width: `${props.size}px`, height: `${props.size}px` }} className="rounded-full" src={props.user.photoURL}></img>
                :
                <div style={{ width: `${props.size}px`, height: `${props.size}px`, minWidth: `${props.size}px`, minHeight: `${props.size}px` }} className="flex items-center justify-center gradient-light-grey rounded-full text-white text-center">
                    <p style={{ fontSize: `${props.size * 0.5}px` }} className="">{props.user?.name[0] || 'G'}</p>
                </div>
            }
        </>
    )
}

export default ProfilePicture