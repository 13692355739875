import { useState, useEffect, useContext } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { mockProcedures } from "../../model/procedure/procedure"
import { useCookies } from "react-cookie"
import uuid from "react-uuid"

import BarChart from "../../components/barChart/barChart"
import Toggle from "../../components/toggle/toggle"
import SidebarNavigationLink from "../../components/sidebarNavigationLink/sidebarNavigationLink"
import SearchBar from "../../components/searchBar/searchBar"
import PrimaryButton from "../../components/primaryButton/primaryButton"
import Select from "../../components/select/select"
import ListItemRow from "../../components/listItemRow/listItemRow"
import StackedListBar from "../../components/stackedListBar/stackedListBar"
import Card from "../../components/card/card"
import Tag from "../../components/tag/tag"
import SwapDialog from "../../components/swapDialog/swapDialog"
import PreferenceDialog from "../../components/preferenceDialog/preferenceDialog"
import SubscribeDialog from "../../components/subscribeDialog/subscribeDialog"
import Accordion from "../../components/accordion/accordion"
import { mockSurgeons } from "../../model/people/people"
import { MedicalItem } from "../../model/medicalItem/medicalItem"
import DisableDialog from "../../components/disableDialog/disableDialog"
import { mockProcedureUtilisations } from "../../model/procedure/procedureUtilisation"
import { getPastSixWeeksStartDates } from "../../utils/date"
import { mockPreferences } from "../../model/preference/preference"
import SidePanel from "../../components/sidePanel/sidePanel"
import { mockOrders, Order } from "../../model/order/order"
import { mockMedicalItemOrders } from "../../model/medicalItem/medicalItemOrder"
import { mockMedicalItemUsages } from "../../model/medicalItem/medicalItemUsage"

import '../../utils/string'
import { mockVendors, Vendor } from "../../model/vendor/vendor"
import { mockContracts } from "../../model/contract/contract"
import { mockSpecialties } from "../../model/specialty/specialty"
import { EntityContext, UserContext } from "../../context/globalContext"
import moment from "moment"

const VendorItem = () => {
    const { id } = useParams()

    const [cookies, setCookie, removeCookie] = useCookies(['displayName', 'uid']);
    const [uid, setUid] = useState(cookies.uid || uuid().toString())

    const [currentPage, setCurrentPage] = useState('')
    const [subscribe, setSubscribe] = useState('')
    const [selectedSurgeon, setSelectedSurgeon] = useState('surgeon')
    const [selectedOrder, setSelectedOrder] = useState<Order>()
    const [selectedMedicalItem, setSelectedMedicalItem] = useState<MedicalItem>()

    const [subscribeDialog, setSubscribeDialog] = useState(false)
    const [swapDialog, setSwapDialog] = useState(false)
    const [preferenceDialog, setPreferenceDialog] = useState(false)
    const [activeDialog, setActiveDialog] = useState(false)

    const [vendor, setVendor] = useState(mockVendors.find((item) => item.name === id) || {
        "id": "VNDR-0000000",
        "status": "inactive",
        "name": "MedixSupply Chain",
        "tag": "Facilities Management, Laboratory Supplies",
        "contactName": "Matthew Charles",
        "industryRating": 3.1,
        "procedures": "[PR-039,PR-032,PR-066,PR-037,PR-070,PR-047,PR-068,PR-066,PR-072,PR-046]",
        "contracts": "[CNTRCT-0000006,CNTRCT-0000063,CNTRCT-0000049]",
        "orders": "[ORDR-0-0000013,ORDR-1-0000055,ORDR-2-0000020,ORDR-3-0000020,ORDR-4-0000022,ORDR-5-0000014,ORDR-6-0000017,ORDR-7-0000038,ORDR-8-0000042,ORDR-9-0000018,ORDR-10-0000024,ORDR-11-0000057,ORDR-12-0000052,ORDR-13-0000046,ORDR-14-0000035,ORDR-15-0000031,ORDR-16-0000047,ORDR-17-0000042,ORDR-18-0000020]",
        "spendWeekly": [
            3064,
            2908,
            2983,
            2902,
            2903,
            2905,
            2927,
            2984,
            3100,
            2998,
            3093,
            3095,
            2942,
            2901,
            2932,
            2901,
            3098,
            3060,
            2954,
            3098,
            3100,
            2950,
            2989,
            2934,
            3069,
            3045,
            2921,
            2991,
            2901,
            2915,
            2990,
            2918,
            3099,
            2997,
            3084,
            2997,
            3100,
            2984,
            3099,
            2955,
            3043,
            3004,
            3097,
            2901,
            3046,
            2914,
            2901,
            3062,
            2976,
            2975,
            2914,
            2938
        ],
        "orderRejected": [
            2938,
            16,
            17,
            14,
            2,
            2,
            4,
            0,
            10,
            11,
            5,
            1,
            13,
            18,
            19,
            4,
            12,
            0,
            3,
            10,
            13,
            19,
            4,
            19,
            19,
            15,
            9,
            0,
            4,
            5,
            18,
            19,
            18,
            19,
            0,
            4,
            11,
            19,
            18,
            0,
            10,
            19,
            1,
            0,
            9,
            12,
            5,
            4,
            0,
            2,
            6,
            10,
            11
        ],
        "orderAccepted": [
            0,
            8,
            1,
            6,
            4,
            10,
            2,
            6,
            0,
            10,
            6,
            8,
            8,
            11,
            3,
            11,
            3,
            10,
            9,
            5,
            0,
            1,
            10,
            1,
            9,
            11,
            11,
            11,
            4,
            8,
            7,
            6,
            0,
            1,
            9,
            9,
            2,
            0,
            11,
            10,
            2,
            9,
            10,
            11,
            5,
            7,
            3,
            11,
            8,
            11,
            2,
            10
        ],
        "orderHold": [
            3,
            3,
            2,
            0,
            3,
            1,
            0,
            3,
            2,
            2,
            4,
            0,
            1,
            2,
            0,
            1,
            2,
            1,
            4,
            0,
            1,
            1,
            2,
            4,
            0,
            0,
            1,
            2,
            4,
            2,
            1,
            1,
            0,
            4,
            1,
            2,
            1,
            4,
            4,
            3,
            2,
            3,
            3,
            4,
            0,
            1,
            4,
            2,
            0,
            0,
            2,
            3
        ],
        "orderBackordered": [
            3,
            0,
            1,
            1,
            0,
            0,
            2,
            2,
            2,
            2,
            1,
            1,
            1,
            1,
            0,
            2,
            1,
            2,
            2,
            0,
            2,
            1,
            2,
            1,
            2,
            0,
            2,
            0,
            1,
            1,
            2,
            0,
            2,
            1,
            1,
            1,
            1,
            2,
            1,
            1,
            1,
            0,
            0,
            2,
            0,
            0,
            2,
            0,
            1,
            1,
            0,
            1,
            2
        ],
        "issueIncomplete": [
            2,
            1,
            1,
            1,
            1,
            0,
            0,
            0,
            1,
            1,
            1,
            1,
            1,
            0,
            1,
            0,
            0,
            1,
            0,
            0,
            1,
            0,
            1,
            0,
            0,
            1,
            0,
            1,
            0,
            0,
            1,
            0,
            1,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            1,
            0,
            1,
            0,
            0,
            1,
            0,
            0,
            0,
            1,
            1,
            1,
            1
        ],
        "issueQuality": [
            2,
            0,
            4,
            4,
            3,
            3,
            3,
            4,
            1,
            1,
            1,
            1,
            4,
            0,
            0,
            3,
            2,
            1,
            0,
            0,
            0,
            4,
            4,
            0,
            1,
            1,
            1,
            4,
            4,
            2,
            3,
            0,
            1,
            0,
            4,
            2,
            2,
            1,
            0,
            0,
            0,
            3,
            1,
            4,
            4,
            4,
            4,
            4,
            3,
            4,
            1,
            3
        ],
        "issueDelay": [
            0,
            0,
            1,
            1,
            1,
            4,
            4,
            0,
            1,
            1,
            0,
            3,
            3,
            4,
            4,
            2,
            4,
            1,
            1,
            2,
            2,
            2,
            2,
            4,
            1,
            2,
            4,
            0,
            3,
            4,
            2,
            3,
            4,
            0,
            4,
            0,
            0,
            3,
            4,
            2,
            0,
            3,
            4,
            2,
            1,
            3,
            0,
            2,
            1,
            0,
            0,
            4
        ],
        "issueQuantity": [
            1,
            4,
            2,
            2,
            3,
            4,
            1,
            0,
            3,
            1,
            3,
            0,
            0,
            1,
            4,
            4,
            0,
            2,
            3,
            3,
            3,
            1,
            3,
            4,
            1,
            4,
            3,
            0,
            4,
            4,
            2,
            0,
            4,
            4,
            0,
            2,
            1,
            1,
            2,
            4,
            4,
            3,
            3,
            2,
            1,
            0,
            1,
            2,
            0,
            3,
            4,
            4
        ]
    })

    const entityContext = useContext(EntityContext)
    const userContext = useContext(UserContext)

    const location = useLocation()
    const navigate = useNavigate()

    const contracts = mockContracts.filter((v) => vendor.contracts.includes(v.id))
    const activeContracts = contracts.filter((v) => {
        const parts = v.end.split('/');
        const date = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
        const today = new Date()

        return date >= today
    })

    const preferences = mockPreferences.filter((v) => vendor.procedures.includes(v.id))
    const orders = mockOrders.filter((v) => vendor.orders.toArray().includes(v.id))
    const procedures = mockProcedures.filter((v) => vendor.procedures.toArray().includes(v.id))
    const lastUpdatedUser = userContext?.users.find((v) => v.id === vendor.lastUpdatedBy)
    const previousLocation = location.state?.previousLocationPathName.split('/').at(-1) || ''

    useEffect(() => {
        setCurrentPage(location.hash)
    }, [location])

    useEffect(() => {
        document.title = `Vendor — ${vendor.name}`
    }, [vendor.name])

    useEffect(() => {
        const startTime = Date.now()

        return () => {
            const path = location.pathname + location.hash
            var storedData: { [path: string]: { timeSpent: number, clicks: number, title: string, type: string } } = JSON.parse(localStorage.getItem('recents') || '{}') || {}

            const endTime = Date.now()
            const totalTimeSpent = endTime - startTime

            if (storedData[path] === undefined) {
                storedData[path] = { timeSpent: 0, clicks: 0, title: '', type: 'vendor' }
            }

            storedData[path].timeSpent = (storedData[path]?.timeSpent || 0) + totalTimeSpent
            storedData[path].title = vendor.name
            localStorage.setItem('recents', JSON.stringify(storedData))
        }
    }, [])

    useEffect(() => {
        const handleClick = () => {
            const path = location.pathname + location.hash
            var storedData: { [path: string]: { timeSpent: number, clicks: number, title: string, type: string } } = JSON.parse(localStorage.getItem('recents') || '{}') || {}

            if (storedData[path] === undefined) {
                storedData[path] = { timeSpent: 0, clicks: 0, title: '', type: 'vendor' }
            }

            storedData[path].clicks += 1
            storedData[path].title = vendor.name
            localStorage.setItem('recents', JSON.stringify(storedData));
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [])

    const calculateTotalMonths = (startDate: string, endDate: string): number => {
        const startDateParts = startDate.split('/');
        const endDateParts = endDate.split('/');

        const startYear = parseInt(startDateParts[2]);
        const startMonth = parseInt(startDateParts[1]);

        const endYear = parseInt(endDateParts[2]);
        const endMonth = parseInt(endDateParts[1]);

        return (endYear - startYear) * 12 + (endMonth - startMonth);
    };

    const calculateMonthsPassed = (startDate: string, endDate: string): number => {
        const startDateParts = startDate.split('/');
        const endDateParts = endDate.split('/');

        const startYear = parseInt(startDateParts[2]);
        const startMonth = parseInt(startDateParts[1]);
        const startDay = parseInt(startDateParts[0]);

        const endYear = parseInt(endDateParts[2]);
        const endMonth = parseInt(endDateParts[1]);
        const endDay = parseInt(endDateParts[0]);

        let monthsPassed = (endYear - startYear) * 12 + (endMonth - startMonth);

        if (startDay > endDay) {
            monthsPassed--;
        }

        return monthsPassed;
    };

    return (
        <>
            <div className="relative flex mt-4 pt-2 px-4 pb-4">
                <div className="w-[265px] top-[80px] bottom-4 fixed flex flex-col border border-neutral-2 bg-neutral-1 rounded-md">
                    <div onClick={() => navigate(-1)} className="cursor-pointer flex items-center gap-3 p-2">
                        <img className="h-[12px] cursor-pointer" src={process.env.PUBLIC_URL + '/images/icons/ic-chevron-left.svg'}></img>
                        <p className="font-title-3 font-semibold">{previousLocation.charAt(0).toUpperCase() + previousLocation.slice(1)}</p>
                    </div>

                    <div className="h-[1px] bg-neutral-2"></div>

                    <div className="flex p-2">
                        <div className="flex flex-col gap-1">
                            <p className="font-body line-clamp-1 text-ellipsis">{vendor.name}</p>

                            <div className="flex gap-2 items-center">
                                <div className="w-[10px] h-[10px] bg-success rounded-full"></div>
                                <p className="font-body text-neutral-5">Active</p>
                            </div>
                        </div>

                        <div className="ml-auto flex items-center">
                            <Toggle
                                value={vendor.status === "TRUE"}
                                onChange={() => {
                                    if (vendor.status === "TRUE") {
                                        setActiveDialog(true)
                                    } else {
                                        setVendor({ ...vendor, status: vendor.status === "TRUE" ? "FALSE" : "TRUE" })
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="h-[1px] bg-neutral-2"></div>

                    <div className="flex flex-col gap-1 p-2">
                        <SidebarNavigationLink
                            title="Overview"
                            icon="ic-gauge.svg"
                            hashLink="#overview"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Procedures"
                            icon="ic-menu.svg"
                            hashLink="#procedures"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Contracts"
                            icon="ic-signature-blue.svg"
                            hashLink="#contracts"
                            currentHashLink={currentPage}
                        />

                        <SidebarNavigationLink
                            title="Orders"
                            icon="ic-shipping-box.svg"
                            hashLink="#orders"
                            currentHashLink={currentPage}
                        />
                    </div>

                    {lastUpdatedUser && (
                        <div className="flex mt-auto p-2 gap-2 items-center">
                            <img className="h-[26px] w=[26px] p-1.5 bg-gradient-to-t from-neutral-4 to-neutral-3 rounded-md" src={'/images/icons/ic-pencil.svg'}></img>

                            <div className="flex flex-col justify-start">
                                <p className="font-body">{lastUpdatedUser.name}</p>
                                <p className="font-subheadline text-neutral-5">{`Edited ${moment(vendor.lastUpdated?.toDate()).fromNow()}`}</p>
                            </div>
                        </div>
                    )}
                </div>

                <div className="ml-[265px] flex-grow">
                    <div className="w-[825px] items-start mx-auto">
                        {currentPage === '#overview' && (
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                    <div className="flex gap-3 items-center">
                                        <p className="font-large-title font-bold">{vendor?.name}</p>
                                    </div>

                                    <div className="flex gap-1 items-center">
                                        {vendor.tag.toArray().map((tag) => {
                                            return (
                                                <Tag title={tag}></Tag>
                                            )
                                        })}

                                        <div className="font-subheadline font-bold text-primary-4">
                                            Add Tag
                                        </div>
                                    </div>
                                </div>

                                <div className="sticky top-0 z-[100] flex p-2 w-full bg-white border-neutral-2 border rounded-md gap-2">
                                    <PrimaryButton
                                        title="New Contract"
                                        type="primary"
                                        size="xl"
                                    />

                                    {subscribe ?
                                        <Select
                                            size="xl"
                                            options={[
                                                {
                                                    label: 'All',
                                                    value: 'all',
                                                    icon: 'ic-bell-waves-fill.svg'
                                                },
                                                {
                                                    label: 'Personalised',
                                                    value: 'personalised',
                                                    icon: 'ic-bell.svg'
                                                },
                                                {
                                                    label: 'Unsubscribe',
                                                    value: 'unsubscribe',
                                                    icon: 'ic-minus.svg'
                                                },
                                            ]}
                                            selectedOption={subscribe}
                                            label="Subscribed"
                                            onChange={(value) => {
                                                if (value === 'unsubscribe') {
                                                    setSubscribe('')
                                                } else {
                                                    setSubscribe(value)
                                                }
                                            }}
                                        />
                                        :
                                        <PrimaryButton
                                            title="Subscribe"
                                            type="gray"
                                            size="xl"
                                            onClick={() => setSubscribeDialog(true)}
                                        />
                                    }

                                    <div className="flex ml-auto mr-2 items-center gap-[40px]">
                                        <div>
                                            <p className="font-callout font-semibold">{vendor?.id}</p>
                                            <p className="font-subheadline text-neutral-5">ID</p>
                                        </div>

                                        <div>
                                            <p className="font-callout font-semibold">{`${vendor.contactName}`}</p>
                                            <p className="font-subheadline text-neutral-5">Contact</p>
                                        </div>

                                        <div>
                                            <p className="font-callout font-semibold">{`aluttgard@gmail.com`}</p>
                                            <p className="font-subheadline text-neutral-5">Email</p>
                                        </div>

                                        <div>
                                            <p className="font-callout font-semibold">{`★ ${vendor.industryRating}`}</p>
                                            <p className="font-subheadline text-neutral-5">Industry Rating</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <Card title="Spend" subtitle={`£ ${((vendor.spendWeekly.slice(-6) || []).reduce((a, b) => a + b, 0) / 6).toFixed(0)} avg. cost`} rightArrow={true}>
                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: vendor.spendWeekly.slice(-6) || [],
                                                        color: 'neutral-4'
                                                    }
                                                ]}
                                                breakpoints={[0, 1000, 2000, 3000, 5000]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </Card>

                                    <Card title="Contracts" subtitle={`${activeContracts.length} active`}>
                                        <div className="max-h-full rounded-md border border-neutral-2 mt-2 overflow-y-auto">
                                            {activeContracts.map((contract, index) => {
                                                return (
                                                    <>
                                                        <ListItemRow
                                                            title={`${calculateTotalMonths(contract.start, contract.end) - calculateMonthsPassed(contract.start, contract.end)} of ${calculateTotalMonths(contract.start, contract.end)} months left`}
                                                            subtitle={`£${(parseInt(contract.spent)).toFixed(0)}k of £${(parseInt(contract.agreedSpendPound)).toFixed(0)}k spent so far`}
                                                            arrowRight={true}
                                                            arrowLabel={`${contract.fillRate}% fill`}
                                                            hideBorder={true}
                                                            hideBackground={true}
                                                        />

                                                        {index !== activeContracts.length - 1 && (
                                                            <div className="h-[1px] bg-neutral-2 mx-2"></div>
                                                        )}
                                                    </>

                                                )
                                            })}
                                        </div>
                                    </Card>
                                </div>

                                <div className="grid grid-cols-2 gap-4 w-full">
                                    <Card title="Orders" subtitle="Mostly backordered" rightArrow={true}>
                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: vendor.orderAccepted.splice(-6),
                                                        color: 'neutral-4'
                                                    },
                                                    {
                                                        data: vendor.orderBackordered.splice(-6),
                                                        color: 'caution'
                                                    },
                                                    {
                                                        data: vendor.orderHold.splice(-6),
                                                        color: 'info'
                                                    },
                                                    {
                                                        data: vendor.orderRejected.splice(-6),
                                                        color: 'critical'
                                                    },
                                                ]}
                                                breakpoints={[0, 10, 20, 30, 40]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Rejected",
                                                        color: 'critical'
                                                    },
                                                    {
                                                        title: "Hold",
                                                        color: "info"
                                                    },
                                                    {
                                                        title: "Backordered",
                                                        color: "caution"
                                                    }
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </Card>

                                    <Card title="Issues" subtitle="80% as expected" rightArrow={true}>
                                        <div className="w-full h-full pt-2">
                                            <BarChart
                                                datasets={[
                                                    {
                                                        data: vendor.issueQuantity,
                                                        color: 'cyan'
                                                    },
                                                    {
                                                        data: vendor.issueDelay,
                                                        color: 'teal'
                                                    },
                                                    {
                                                        data: vendor.issueQuality,
                                                        color: 'caution'
                                                    },
                                                    {
                                                        data: vendor.issueIncomplete,
                                                        color: 'purple'
                                                    },
                                                ]}
                                                breakpoints={[0, 3, 6, 9, 12]}
                                                labels={getPastSixWeeksStartDates()}
                                                legends={[
                                                    {
                                                        title: "Incomplete",
                                                        color: 'purple'
                                                    },
                                                    {
                                                        title: "Quality",
                                                        color: 'caution'
                                                    },
                                                    {
                                                        title: "Delayed",
                                                        color: 'teal'
                                                    },
                                                    {
                                                        title: "Wrong",
                                                        color: 'cyan'
                                                    },
                                                ]}
                                                selectedLabel={getPastSixWeeksStartDates()[5]}
                                            />
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        )}

                        {currentPage === '#procedures' && (
                            <div className="flex flex-col gap-4">
                                <div className="flex gap-5">
                                    <div>
                                        <p className="font-body">Preferences</p>
                                        <p className="font-large-title font-bold">{`${preferences.length} surgeons`}</p>
                                    </div>
                                </div>

                                <StackedListBar
                                    title="Procedures"
                                    subtitle={`${procedures.filter((v) => v.requiredItems.map((v) => entityContext?.medicalItems.find((v) => v.disruption !== "None"))).length} of ${procedures.length} at risk`}
                                    datasets={[
                                        {
                                            title: 'At Risk',
                                            count: procedures.filter((v) => v.requiredItems.map((v) => entityContext?.medicalItems.find((v) => v.disruption !== "None"))).length,
                                            color: 'caution',

                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="z-[100] flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 340px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {procedures.sort().map((procedure) => {
                                            const preferences = mockPreferences.filter((v) => v.id === procedure.id)
                                            return (
                                                <Accordion
                                                    title={procedure.name || 'No Name'}
                                                    subtitle={`${procedure.requiredItems.length} Items • £${procedure.cost}`}
                                                    arrowLabel={`${preferences.length} preferences`}
                                                    icon={{
                                                        icon: 'ic-warning-triangle.svg',
                                                        gradient: 'orange'
                                                    }}
                                                >
                                                    {preferences.map((item) => {
                                                        return (
                                                            <>
                                                                <ListItemRow
                                                                    title={item.surgeon}
                                                                    subtitle={`${item.add.toArray().length + item.remove.toArray().length} Items • £${item.cost}`}
                                                                    hideBorder={true}
                                                                    icon={(entityContext?.medicalItems.filter((v) => item.add.toArray().includes(v.id) && v.disruption !== "None").length || 0) > 0 ? {
                                                                        icon: 'ic-warning-triangle.svg',
                                                                        gradient: 'orange'
                                                                    } : undefined}
                                                                >
                                                                    <div className="flex gap-4 items-center mr-4">
                                                                        <img className="h-[14px] w-[14px]" src={'/images/icons/ic-trash.svg'}></img>

                                                                        <PrimaryButton
                                                                            title="Replace"
                                                                            type="gray"
                                                                            onClick={() => {
                                                                                setSwapDialog(true)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </ListItemRow>
                                                                <div className="h-[1px] bg-neutral-2 mx-2"></div>
                                                            </>
                                                        )
                                                    })}
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#contracts' && (
                            <div className="flex flex-col gap-4">
                                <StackedListBar
                                    title="Performance"
                                    subtitle={`${contracts.filter((v) => v.performance !== 'As Expected').length} of ${contracts.length} in alert`}
                                    datasets={[
                                        {
                                            title: 'As Expected',
                                            count: contracts.filter((v) => v.performance === 'As Expected').length,
                                            color: 'info',

                                        },
                                        {
                                            title: 'Needs Improvement',
                                            count: contracts.filter((v) => v.performance === 'Needs Improvement').length,
                                            color: 'caution',

                                        },
                                        {
                                            title: 'Underperforming',
                                            count: contracts.filter((v) => v.performance === 'Underperforming').length,
                                            color: 'danger',
                                        },
                                        {
                                            title: 'Critical',
                                            count: contracts.filter((v) => v.performance === 'Critical').length,
                                            color: 'critical',
                                        },
                                        {
                                            title: contracts.length - activeContracts.length > 0 ? `${contracts.length - activeContracts.length} expired` : '',
                                            count: contracts.length - activeContracts.length,
                                            color: '',
                                            showLabel: true
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Vendor',
                                                    value: 'vendor',
                                                }
                                            ]}
                                            selectedOption="vendor"
                                        />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Status',
                                                    value: 'status',
                                                }
                                            ]}
                                            selectedOption="status"
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 275px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {contracts.map((contract) => {
                                            return (
                                                <ListItemRow
                                                    title={contract.name}
                                                    subtitle={`Agreed Spend £${contract.agreedSpendPound}`}
                                                    icon={contract.performance === 'Critical' ? {
                                                        icon: 'ic-info-circle-fill-white.svg',
                                                        gradient: 'red'
                                                    } : contract.performance === 'Underperforming' ? {
                                                        icon: 'ic-exclamation-mark.svg',
                                                        gradient: 'yellow'
                                                    } : contract.performance === 'Needs Improvement' ? {
                                                        icon: 'ic-warning-triangle.svg',
                                                        gradient: 'orange'
                                                    } : {
                                                        icon: 'ic-checkmark.svg',
                                                        gradient: 'blue'
                                                    }}
                                                    arrowLabel={`${contract.fillRate.toFixed(0)}% fill`}
                                                    arrowRight={true}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentPage === '#orders' && (
                            <div className="flex flex-col gap-4">
                                <StackedListBar
                                    title="Orders"
                                    subtitle={`${orders.filter((v) => v.status === 'Pending').length} of ${orders.length} in progress`}
                                    datasets={[
                                        {
                                            title: 'Accepted',
                                            count: orders.filter((v) => v.status === 'Accepted').length,
                                            color: 'success',

                                        },
                                        {
                                            title: 'Filled',
                                            count: orders.filter((v) => v.status === 'Filled').length,
                                            color: 'info',

                                        },
                                        {
                                            title: 'Hold',
                                            count: orders.filter((v) => v.status === 'Hold').length,
                                            color: 'caution',
                                        },
                                        {
                                            title: 'Disrupted',
                                            count: orders.filter((v) => v.status === 'Disrupted').length,
                                            color: 'critical',
                                        },
                                        {
                                            title: orders.filter((v) => v.status === 'Pending').length > 0 ? `${orders.filter((v) => v.status === 'Pending').length} Pending` : '',
                                            count: orders.filter((v) => v.status === 'Pending').length,
                                            color: '',
                                            showLabel: true
                                        },
                                    ]}
                                />

                                <div className="flex flex-col border border-neutral-2 bg-page rounded-md w-[730px]">
                                    <div className="flex bg-white rounded-t-md p-2 gap-2 border-b border-neutral-2 backdrop-blur-lg">
                                        <SearchBar />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Vendor',
                                                    value: 'vendor',
                                                }
                                            ]}
                                            selectedOption="vendor"
                                        />

                                        <Select
                                            size="lg"
                                            options={[
                                                {
                                                    label: 'Status',
                                                    value: 'status',
                                                }
                                            ]}
                                            selectedOption="status"
                                        />
                                    </div>

                                    <div style={{ maxHeight: 'calc(100vh - 275px)' }} className="flex flex-col gap-2 p-2 overflow-y-auto">
                                        {orders.map((order) => {
                                            return (
                                                <ListItemRow
                                                    title={order.name}
                                                    subtitle={`${order.itemsTotal} Items`}
                                                    icon={order.status === 'Disrupted' ? {
                                                        icon: 'ic-info-circle-fill-white.svg',
                                                        gradient: 'red'
                                                    } : order.status === 'Fill' ? {
                                                        icon: 'ic-warning-triangle.svg',
                                                        gradient: 'orange'
                                                    } : {
                                                        icon: 'ic-checkmark.svg',
                                                        gradient: 'blue'
                                                    }}
                                                    arrowLabel={order.location}
                                                    arrowRight={true}
                                                    onArrowClick={() => setSelectedOrder(order)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {subscribeDialog && (
                    <SubscribeDialog
                        onSubmit={() => {
                            setSubscribeDialog(false)
                            setSubscribe('all')
                        }}

                        onDismiss={() => {
                            setSubscribeDialog(false)
                        }}
                    />
                )}

                {activeDialog && (
                    <DisableDialog
                        onSubmit={() => {
                            setVendor({ ...vendor, status: "FALSE" })
                            setActiveDialog(false)
                        }}
                        onDismiss={() => setActiveDialog(false)}
                    />
                )}
            </div>

            {selectedOrder && (
                <SidePanel
                    title={selectedOrder?.name || ''}
                    titleLabel="Order"
                    icon="ic-box-truck-black.svg"
                    iconGradient="yellow"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedOrder(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Overview</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder.id}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="pt-2">
                                        <div style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.13) inset' }} className="flex w-full h-[21px] gap-[2px] bg-neutral-1 rounded-md">
                                            <div style={{ width: `${selectedOrder.receivedQuantity / selectedOrder.itemsTotal * 100}%` }} className={`bg-info rounded-s-md`}>

                                            </div>

                                            <div style={{ width: `${selectedOrder.leftQuantity / selectedOrder.itemsTotal * 100}%` }} className="font-caption-2 text-neutral-5 text-center my-auto">
                                                {`${selectedOrder.leftQuantity} left`}
                                            </div>


                                        </div>

                                        <div className="flex py-1 gap-2">
                                            <div className="flex items-center font-caption-1 gap-1.5">
                                                <div className={`h-2 w-2 bg-info rounded-full`}>

                                                </div>

                                                <div>
                                                    <p className="font-caption-1 text-neutral-5">Received</p>
                                                    <p className="font-caption-1 text-neutral-6">{selectedOrder.receivedQuantity}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Location</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Branch</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedOrder?.location}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Financial</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5 items-center">
                                        <p className="font-body">Items</p>
                                        <p className="font-body text-neutral-5 ml-auto">

                                            <PrimaryButton
                                                title="View All"
                                                type="white"
                                                size="md"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full">
                            <PrimaryButton
                                title="Call Supplier"
                                type="primary"
                                size="xl"
                                icon="ic-phone-white.svg"
                                iconSize={12}
                                maxWidth={true}
                            />
                        </div>

                    }
                />
            )}

            {selectedMedicalItem && (
                <SidePanel
                    title={selectedMedicalItem.name || ''}
                    titleLabel="Item"
                    icon="ic-shipping-box-black.svg"
                    iconGradient="teal"
                    chatRoomIds={[]}
                    onDismiss={() => setSelectedMedicalItem(undefined)}
                    content={
                        <div className="flex flex-col px-2.5 py-4">
                            <div className="flex flex-col gap-2">
                                <p className="font-body font-semibold px-2">Manufacturer</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Name</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedMedicalItem?.manufacturer}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Item ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{selectedMedicalItem?.manufacturerID}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Vendor</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Name</p>
                                        <p className="font-body text-neutral-5 ml-auto">{mockVendors.find((v) => v.id === selectedMedicalItem.vendor)?.name}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Item ID</p>
                                        <p className="font-body text-neutral-5 ml-auto">{mockVendors.find((v) => v.id === selectedMedicalItem.vendor)?.id}</p>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Settings</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-1">
                                    <div className="flex px-2.5 pt-1 pb-2.5 items-center">
                                        <p className="font-body">Open</p>
                                        <div className="flex items-center border border-neutral-3 p-1 rounded-md ml-auto">
                                            <p className="ml-2 w-[50px] font-body">2</p>
                                            <img className="rounded-md p-1.5 bg-neutral-2 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down.svg`}></img>
                                        </div>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex px-2.5 pt-2.5 pb-1 items-center">
                                        <p className="font-body">Hold</p>

                                        <div className="flex items-center border border-neutral-3 p-1 rounded-md ml-auto">
                                            <p className="ml-2 w-[50px] font-body">2</p>
                                            <img className="rounded-md p-1.5 bg-neutral-2 h-[24px] w-[24px]" src={`/images/icons/ic-chevron-up-down.svg`}></img>
                                        </div>
                                    </div>
                                </div>

                                <p className="font-body font-semibold px-2">Utilisation</p>

                                <div className="rounded-[5px] border border-neutral-2 bg-card p-2">
                                    <div className="flex p-2.5">
                                        <p className="font-body">Units Used</p>

                                        <p className="font-body text-neutral-5 ml-auto">{(mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyUsage.slice(-6).reduce((a, b) => a + b, 0) || 0) + (mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyWasted.slice(-6).reduce((a, b) => a + b, 0) || 0)}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="flex p-2.5">
                                        <p className="font-body">Cases</p>
                                        <p className="font-body text-neutral-5 ml-auto">{mockProcedures.filter((v) => v.requiredItems.includes(selectedMedicalItem.id)).length}</p>
                                    </div>

                                    <div className="h-[1px] bg-neutral-2 mx-1"></div>

                                    <div className="h-[250px] mt-2">
                                        <BarChart
                                            datasets={[
                                                {
                                                    data: mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyUsage.slice(-6) || [],
                                                    color: 'neutral-4'
                                                },
                                                {
                                                    data: mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyWasted.slice(-6) || [],
                                                    color: 'info'
                                                }
                                            ]}
                                            breakpoints={[0, 50, 100, 150, 200]}
                                            labels={getPastSixWeeksStartDates()}
                                            legends={[
                                                {
                                                    title: "Used",
                                                    subtitle: `${mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyUsage.slice(-6).reduce((a, b) => a + b, 0)} units`,
                                                    color: 'neutral-4'
                                                },
                                                {
                                                    title: "Wasted",
                                                    subtitle: `${mockMedicalItemUsages.find((v) => v.id === selectedMedicalItem.id)?.weeklyWasted.slice(-6).reduce((a, b) => a + b, 0)} units`,
                                                    color: "info"
                                                }
                                            ]}
                                            selectedLabel={getPastSixWeeksStartDates()[5]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    footer={
                        <div className="flex w-full gap-2">
                            <PrimaryButton
                                title="Delete"
                                type="ghost"
                                size="xl"
                                iconSize={10}
                            />

                            <PrimaryButton
                                title="Replace"
                                type="white"
                                size="xl"
                                maxWidth={true}
                            />
                        </div>

                    }
                />
            )}
        </>
    )
}

export default VendorItem