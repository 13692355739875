import { useState, useRef } from "react"
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { auth, firestore } from "../../firebase/firebase"
import { storage } from "../../firebase/firebase"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import Input from "../input/input"
import { mockSpecialties } from "../../model/specialty/specialty"
import Toggle from "../toggle/toggle"
import { useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie"
import { collection, doc, setDoc } from "firebase/firestore"
import { User } from "../../model/user/user"

const CreateAccountDialog = (props: { onSubmit: () => void }) => {
    const [currentPage, setCurrentPage] = useState("details")

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [reportNotification, setReportNotification] = useState(false)

    const [currentFile, setCurrentFile] = useState<File>();
    const [previewImage, setPreviewImage] = useState<string>("");

    const [selectedLocation, setSelectedLocation] = useState<string>()
    const [selectedTeam, setSelectedTeam] = useState<string>()

    const [cookies, setCookie, removeCookie] = useCookies(['refreshToken', 'email', 'displayName', 'uid']);

    const navigate = useNavigate()

    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const selectedFiles = files as FileList;
        setCurrentFile(selectedFiles?.[0]);
        setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));
    };

    const createUser = async () => {
        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;

                setCookie('refreshToken', userCredential.user.refreshToken, { path: '/' })
                setCookie('email', userCredential.user.email, { path: '/' })
                setCookie('uid', userCredential.user.uid, { path: '/' })
                setCookie('displayName', `${name} ${surname}`, { path: '/' })

                props.onSubmit()

                updateProfile(user, {
                    displayName: `${name} ${surname}`,
                }).then(() => {

                }).catch((error) => {

                });

                if (currentFile) {
                    const storageRef = ref(storage, `/images/${userCredential.user.uid}/${currentFile?.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, currentFile);

                    uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err), () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            updateProfile(user, {
                                photoURL: url
                            }).then(() => {
                                setDoc(doc(collection(firestore, 'users'), userCredential.user.uid), {
                                    photoURL: url
                                }, { merge: true })
                                    .then(() => { })
                                    .catch((e) => { })
                            }).catch((error) => {

                            })
                        })
                    })
                }

                const userData: User = {
                    id: userCredential.user.uid,
                    name: `${name} ${surname}`,
                    email: userCredential.user.email || '',
                    photoURL: user.photoURL || '',
                    location: selectedLocation,
                    team: selectedTeam
                }

                setDoc(doc(collection(firestore, 'users'), userCredential.user.uid), userData, { merge: true })
                    .then(() => { })
                    .catch((e) => { })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <>
            <Dialog>
                <div className={`flex flex-col relative w-[800px] h-[540px] rounded-md`}>

                    <div className="flex flex-col p-5 mx-[120px]">
                        {currentPage === "details" && (
                            <>
                                <div style={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.16)' }} className="relative h-[96px] w-[96px] p-6 rounded-full bg-white mt-12 mx-auto">
                                    <img src={'/images/icons/ic-profile-picture-placeholder.svg'}></img>

                                    {previewImage && (
                                        <img className="h-[96px] w-[96px] absolute top-0 left-0 rounded-full" src={previewImage} alt="" />
                                    )}

                                    <button onClick={() => fileInputRef.current?.click()} className={`${previewImage ? 'text-white' : 'text-neutral-6'} cursor-pointer absolute font-body bottom-3 left-[36px]`}>edit</button>
                                </div>

                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                />

                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                    <p className="font-large-title font-neutral-6 font-bold">Account Details</p>
                                </div>

                                <div className="flex flex-col gap-2 mx-[100px]">
                                    <Input
                                        type="text"
                                        label="Name"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(v) => setName(v)}
                                    />

                                    <Input
                                        type="text"
                                        label="Surname"
                                        placeholder="Surname"
                                        value={surname}
                                        onChange={(v) => setSurname(v)}
                                    />

                                    <Input
                                        type="text"
                                        label="Company Email"
                                        placeholder="Company Email"
                                        value={email}
                                        onChange={(v) => setEmail(v)}
                                    />
                                </div>
                            </>
                        )}

                        {currentPage === "location" && (
                            <>
                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                    <img className="h-[56px]" src={'/images/icons/ic-buildings-gradient.svg'}></img>
                                    <p className="font-large-title font-neutral-6 font-bold mt-[20px]">Select Your Location</p>
                                </div>

                                <div className="flex flex-col gap-2.5 mx-[40px] overflow-y-hidden h-[230px] bg-[#F3F3F3] border-neutral-2 border rounded-md pt-2.5">
                                    <div className="px-2.5">
                                        <div className="flex items-center w-full font-body placeholder:text-neutral-4 focus:outline-none text-neutral-6 bg-transparent p-2 rounded-md border border-neutral-2">
                                            <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                                            <input className="w-full bg-transparent focus:outline-none" type="text" placeholder="Search"></input>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-2.5 overflow-y-auto px-2.5 pb-2.5">
                                        {[
                                            {
                                                address: "London Hospital",
                                                city: "London, 33 GP",
                                            },
                                            {
                                                address: "Moorgate Outpatient Centre",
                                                city: "London, 55 MO",
                                            },
                                            {
                                                address: "Portland Place Outpatient Center",
                                                city: "London, 24 PO",
                                            },

                                        ].map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    if (item.address === selectedLocation) {
                                                        setSelectedLocation(undefined)
                                                    } else {
                                                        setSelectedLocation(item.address)
                                                    }
                                                }} className={`cursor-pointer flex gap-2 ${item.address === selectedLocation ? 'bg-info' : 'bg-white'} rounded-md border border-neutral-2 px-3 py-2.5 items-center`}>
                                                    <div>
                                                        <p className={`font-body ${item.address === selectedLocation ? 'text-white' : 'text-neutral-6'}`}>{item.address}</p>
                                                        <p className={`font-subheadline ${item.address === selectedLocation ? 'text-white/50' : 'text-neutral-5'}`}>{item.city}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </>
                        )}

                        {currentPage === "password" && (
                            <>
                                <img className="h-[64px] mt-12  mr-1.5" src={'/images/icons/ic-lock-shield-gradient.svg'}></img>

                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                    <p className="font-large-title font-neutral-6 font-bold">Account Protection</p>
                                </div>

                                <div className="flex flex-col gap-2 mx-[100px]">
                                    <Input
                                        type="password"
                                        label="Password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(v) => setPassword(v)}
                                    />

                                    <Input
                                        type="password"
                                        label="Confirm Password"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(v) => setConfirmPassword(v)}
                                    />
                                </div>
                            </>
                        )}

                        {currentPage === "team" && (
                            <>
                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                    <img className="h-[60px]" src={'/images/icons/ic-person-3-fill-gradient.svg'}></img>
                                    <p className="font-large-title font-neutral-6 font-bold mt-[20px]">Select Your Team</p>
                                </div>

                                <div className="flex flex-col gap-2.5 mx-[40px] overflow-y-hidden h-[230px] bg-[#F3F3F3] border-neutral-2 border rounded-md pt-2.5">
                                    <div className="px-2.5">
                                        <div className="flex items-center w-full font-body placeholder:text-neutral-4 focus:outline-none text-neutral-6 bg-transparent p-2 rounded-md border border-neutral-2">
                                            <img className="h-[12px] mr-1.5" src={'/images/icons/ic-magnifying-glass.svg'}></img>
                                            <input className="w-full bg-transparent focus:outline-none" type="text" placeholder="Search"></input>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-2.5 overflow-y-auto px-2.5 pb-2.5">
                                        <div className="flex-col gap-2 rounded-md border border-neutral-2 p-1 items-center bg-white">


                                            {mockSpecialties.map((item, index) => {
                                                return (
                                                    <>
                                                        <div onClick={() => {
                                                            if (item === selectedTeam) {
                                                                setSelectedTeam(undefined)
                                                            } else {
                                                                setSelectedTeam(item)
                                                            }
                                                        }} className={`cursor-pointer  ${item === selectedTeam ? 'bg-info' : 'bg-white'} `}>
                                                            <p className={`p-3 font-body ${item === selectedTeam ? 'text-white' : 'text-neutral-6'}`}>{item}</p>
                                                        </div>

                                                        {index !== mockSpecialties.length - 1 && (
                                                            <div className="h-[1px] bg-neutral-2"></div>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {currentPage === "notifications" && (
                            <>
                                <div className="flex flex-col gap-[10px] text-center my-[20px] py-[10px]">
                                    <p className="font-large-title text-neutral-6 font-bold mt-[20px]">Notifications</p>
                                    <p className="font-title-3 text-neutral-5">Notification features adapt to your individual needs. You can turn them on now to help you finish setting up.</p>
                                </div>

                                <div className="flex flex-col w-[440px] mx-auto">
                                    <div className="flex items-center w-full gap-4 p-4">
                                        <img className="h-[32px] w-[32px]" src={'/images/icons/ic-tray-arrow-down.svg'}></img>

                                        <div>
                                            <p className="font-body font-semibold">Weekly Reports</p>
                                            <p className="font-body text-neutral-5">Overview of the working week, sent weekly</p>
                                        </div>

                                        <div className="ml-auto">
                                            <Toggle
                                                value={reportNotification}
                                                onChange={() => setReportNotification(!reportNotification)}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center w-full gap-4 p-4">
                                        <img className="h-[32px] w-[32px]" src={'/images/icons/ic-stacked-alerts.svg'}></img>

                                        <div>
                                            <p className="font-body font-semibold">In-App Alerts</p>
                                            <p className="font-body text-neutral-5">When somebody tags you and assigns you tasks</p>
                                        </div>

                                        <div className="ml-auto">
                                            <Toggle
                                                disabled={true}
                                                value={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center w-full gap-4 p-4">
                                        <img className="h-[32px] w-[32px]" src={'/images/icons/ic-bell-blue.svg'}></img>

                                        <div>
                                            <p className="font-body font-semibold">Subscriptions</p>
                                            <p className="font-body text-neutral-5">Get notified on entity changes you subscribed to</p>
                                        </div>

                                        <div className="ml-auto">
                                            <Toggle
                                                disabled={true}
                                                value={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="mt-auto h-[1px] bg-neutral-2"></div>

                    <div className="flex px-5 h-[76px] items-center">
                        <PrimaryButton
                            title="Dismiss"
                            type="gray"
                            size="xl"
                            onClick={() => props.onSubmit()}
                        />

                        <div className="flex gap-3 ml-auto">
                            {currentPage !== "details" && (
                                <PrimaryButton
                                    title="Back"
                                    type="gray"
                                    size="xl"
                                    onClick={() => {
                                        switch (currentPage) {
                                            case "location":
                                                setCurrentPage("details")
                                                break
                                            case "password":
                                                setCurrentPage("location")
                                                break
                                            case "team":
                                                setCurrentPage("password")
                                                break
                                            case "notifications":
                                                setCurrentPage("team")
                                                break
                                        }
                                    }}
                                />
                            )}

                            {currentPage === "details" && (
                                <PrimaryButton
                                    title={"Continue"}
                                    type="white"
                                    size="xl"
                                    onClick={() => setCurrentPage("location")}
                                />
                            )}

                            {currentPage === "location" && (
                                <PrimaryButton
                                    title={"Continue"}
                                    type="white"
                                    size="xl"
                                    disabled={!selectedLocation}
                                    onClick={() => setCurrentPage("password")}
                                />
                            )}

                            {currentPage === "password" && (
                                <PrimaryButton
                                    title={"Continue"}
                                    type="white"
                                    size="xl"
                                    onClick={() => setCurrentPage("team")}
                                />
                            )}

                            {currentPage === "team" && (
                                <PrimaryButton
                                    title={"Continue"}
                                    type="white"
                                    size="xl"
                                    disabled={!selectedTeam}
                                    onClick={() => setCurrentPage("notifications")}
                                />
                            )}

                            {currentPage === "notifications" && (
                                <PrimaryButton
                                    title={"Launch Osmosis"}
                                    type="primary"
                                    size="xl"
                                    onClick={() => {
                                        createUser()
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default CreateAccountDialog