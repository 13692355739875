import { ReactNode, useContext, useEffect, useState } from "react"
import { Notification } from "../../model/notification/notification"
import { UserContext } from "../../context/globalContext"
import ProfilePicture from "../profilePicture/profilePicture"

const NotificationAlert = (props: { notification: Notification, onDismiss: () => void }) => {
    const [visible, setVisible] = useState(true)

    const userContext = useContext(UserContext)

    const user = userContext?.users.find((v) => v.id === props.notification.createdBy)

    useEffect(() => {
        setTimeout(() => {
            setVisible(true)
        }, 1000)

        setTimeout(() => {
            setVisible(false)
            props.onDismiss()
        }, 3000)
    }, [])

    return (
        <>
            {visible && (
                <div key={props.notification.id} className="fixed bottom-0 left-1/2 right-1/2 flex items-center justify-center z-[500] pointer-events-none animate-[notification-slide-up_0.2s_ease-in-out_forwards]">
                    <div className="bg-dialog backdrop-blur-2xl rounded-lg shadow-lg border border-neutral-2">
                        <div className="flex gap-2 p-3 w-[344px]">
                            <div>
                                {props.notification.type === 'substituteUsed' ?
                                    <div className={`h-[26px] min-w-[26px] gradient-green rounded-md flex items-center justify-center`}>
                                        <img className="h-[16px] w-[16px]" src={`/images/icons/${'ic-substitute-white.svg'}`}></img>
                                    </div>
                                    :
                                    <div className={`h-[26px] min-w-[26px] gradient-teal rounded-md flex items-center justify-center`}>
                                        <img className="h-[16px] w-[16px]" src={`/images/icons/${'ic-shipping-box-white.svg'}`}></img>
                                    </div>
                                }
                            </div>

                            <div className="flex flex-col gap-0.5 w-full">
                                <p className="font-headline font-bold">{props.notification.title}</p>
                                <p className="font-body">{props.notification.subtitle}</p>
                            </div>

                            <div className="flex flex-col gap-2 items-end justify-start">
                                <p className="font-subheadline text-neutral-4">now</p>

                                <div className="w-max">
                                    {user && props.notification.type !== 'substituteUsed' && (
                                        <ProfilePicture
                                            user={user}
                                            size={30}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default NotificationAlert