import { Link } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { mockVendors, Vendor } from "../../model/vendor/vendor"
import { mockContracts } from "../../model/contract/contract"
import { EntityContext, LocationContext, TeamContext, UserContext } from "../../context/globalContext"
import StackedListBar from "../../components/stackedListBar/stackedListBar"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { firestore } from "../../firebase/firebase"
import { Chatroom } from "../../model/chat/chat"

const Overview = () => {
    const [itemCategoryTop4, setItemCategoryTop4] = useState<string[]>([])
    const [tasksData, setTasksData] = useState<{ todo: number, inProgress: number, needsInput: number, resolved: number }>({ todo: 0, inProgress: 0, needsInput: 0, resolved: 0 })

    const entityContext = useContext(EntityContext)
    const userContext = useContext(UserContext)
    const locationContext = useContext(LocationContext)
    const teamContext = useContext(TeamContext)

    const itemCategories = new Set(entityContext?.medicalItems.map((v) => v.category))

    const personalisedItems = entityContext?.medicalItems.filter((item) => {
        if (userContext?.user?.personalisedView) {
            if (item.team !== userContext.user.team) { return false }
            if (item.location !== userContext.user.location) { return false }
        }

        if (locationContext?.selectedLocation && locationContext.selectedLocation !== 'all') {
            if (item.location !== locationContext.selectedLocation) { return false }
        }

        if (teamContext?.selectedTeam && teamContext.selectedTeam !== 'all') {
            if (item.team !== teamContext.selectedTeam) { return false }
        }

        return true
    }) || []

    useEffect(() => {
        document.title = 'Overview'

        let itemCategoryAmounts: { [category: string]: number } = {}

        Array.from(itemCategories).forEach((category) => {
            const priceTotal = (entityContext?.medicalItems || []).filter((v) => v.category === category).map((v) => v.price).reduce((a, b) => a + b, 0)
            itemCategoryAmounts[category] = priceTotal
        })

        setItemCategoryTop4(Object.entries(itemCategoryAmounts).sort((a, b) => b[1] - a[1]).slice(0, 4).map((v) => v[0]))
    }, [entityContext?.medicalItems])

    useEffect(() => {
        const q = query(collection(firestore, "chats"),
            where('hasEntity', '==', true),
            where('hasPoll', '==', true),
            where('resolved', '==', false),
        )

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)

            setTasksData(v => { return { ...v, needsInput: chats.length } })
        }, (err) => console.log(err))

        return () => {
            unsub()
        }
    }, [])

    useEffect(() => {
        const q = query(collection(firestore, "chats"),
            where('resolved', '==', true)
        )

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)

            setTasksData(v => { return { ...v, resolved: chats.length } })
        })

        return () => {
            unsub()
        }
    }, [])

    useEffect(() => {
        const q = query(collection(firestore, "chats"),
            where('hasEntity', '==', true),
            where('hasPoll', '==', true),
            where('answeredPoll', '==', true),
            where('resolved', '==', false),
        )

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)

            setTasksData(v => { return { ...v, inProgress: chats.length + ((entityContext?.disruptions.filter((v) => !v.resolved && v.assignee).length || 0)) } })
        }, (err) => console.log(err))

        return () => {
            unsub()
        }
    }, [entityContext?.disruptions])

    useEffect(() => {
        const q = query(collection(firestore, "chats"),
            where('hasEntity', '==', true),
            where('hasPoll', '==', true),
            where('answeredPoll', '==', false),
            where('resolved', '==', false),
        )

        const unsub = onSnapshot(q, (collection) => {
            const docs = collection.docs

            const chats = docs.map((doc) => doc.data() as Chatroom)

            setTasksData(v => { return { ...v, todo: chats.length + ((entityContext?.disruptions.filter((v) => !v.resolved && !v.assignee).length || 0)) } })
        }, (err) => console.log(err))

        return () => {
            unsub()
        }
    }, [entityContext?.disruptions])

    return (
        <div className="flex mt-12">
            <div className="mx-auto">
                <p className="font-large-title text-neutral-5 pt-16 pb-10">Welcome To <span className="font-semibold text-black">{userContext?.user?.location || ''}</span>, {userContext?.user?.name.split(' ')[0]}</p>

                <div className="flex gap-1">
                    <div className="flex flex-col border border-neutral-2 bg-page rounded-md p-3 w-[370px] h-[320px]">
                        <div className="flex items-center gap-1">
                            <img className="h-[10px]" src={process.env.PUBLIC_URL + '/images/icons/ic-budget.svg'}></img>
                            <p className="font-subheadline text-neutral-5">BUDGET</p>
                        </div>

                        <p className="font-large-title font-bold mt-1">£30m</p>
                        <p className="font-title-3">-4% vs last month</p>

                        <div className="py-4">
                            <div className="flex w-full h-[21px] gap-[2px] bg-neutral-1 rounded-md">
                                <div style={{ width: `${(entityContext?.medicalItems || []).filter((v) => v.category === itemCategoryTop4[0]).map((v) => v.amount).reduce((a, b) => a + b, 0) / (entityContext?.medicalItems || []).map((v) => v.amount).reduce((a, b) => a + b, 0) * 100}%` }} className="bg-success rounded-s-md">

                                </div>

                                <div style={{ width: `${(entityContext?.medicalItems || []).filter((v) => v.category === itemCategoryTop4[1]).map((v) => v.amount).reduce((a, b) => a + b, 0) / (entityContext?.medicalItems || []).map((v) => v.amount).reduce((a, b) => a + b, 0) * 100}%` }} className="bg-info">

                                </div>

                                <div style={{ width: `${(entityContext?.medicalItems || []).filter((v) => v.category === itemCategoryTop4[2]).map((v) => v.amount).reduce((a, b) => a + b, 0) / (entityContext?.medicalItems || []).map((v) => v.amount).reduce((a, b) => a + b, 0) * 100}%` }} className="bg-caution">

                                </div>

                                <div style={{ width: `${(entityContext?.medicalItems || []).filter((v) => v.category === itemCategoryTop4[3]).map((v) => v.amount).reduce((a, b) => a + b, 0) / (entityContext?.medicalItems || []).map((v) => v.amount).reduce((a, b) => a + b, 0) * 100}%` }} className="bg-pink">

                                </div>
                            </div>

                            <div className="flex flex-col py-1 gap-2 mt-1">
                                <div className="flex items-center font-caption-1 gap-1.5">
                                    <div className="h-2 w-2 bg-success rounded-full">

                                    </div>

                                    <p>{itemCategoryTop4[0]}</p>
                                </div>

                                <div className="flex items-center font-caption-1 gap-1.5">
                                    <div className="h-2 w-2 bg-info rounded-full">

                                    </div>

                                    <p>{itemCategoryTop4[1]}</p>
                                </div>

                                <div className="flex items-center font-caption-1 gap-1.5">
                                    <div className="h-2 w-2 bg-caution rounded-full">

                                    </div>

                                    <p>{itemCategoryTop4[2]}</p>
                                </div>

                                <div className="flex items-center font-caption-1 gap-1.5">
                                    <div className="h-2 w-2 bg-pink rounded-full">

                                    </div>

                                    <p>{itemCategoryTop4[3]}</p>
                                </div>

                                <div className="flex items-center font-caption-1 gap-1.5">
                                    <div className="h-2 w-2 bg-neutral-1 rounded-full">

                                    </div>

                                    <p>Others</p>
                                </div>
                            </div>
                        </div>

                        <p className="font-subheadline font-bold">from a yearly budget of £100m</p>

                        <div className="flex justify-end mt-auto">
                            <img src="/images/icons/ic-arrow-right.svg"></img>
                        </div>
                    </div>

                    <div className="flex flex-col gap-1">
                        <Link to={'inventory#items'}>
                            <div className="flex flex-col border border-neutral-2 bg-page rounded-md py-3 px-1 w-[370px] h-[158px]">
                                <div className="flex items-center gap-1 mx-2">
                                    <img className="h-[10px]" src={'/images/icons/ic-box.svg'}></img>
                                    <p className="font-subheadline text-neutral-5">INVENTORY</p>
                                </div>

                                <StackedListBar
                                    title="10 locations"
                                    subtitle={`${personalisedItems.filter((item) => entityContext?.disruptions.find((v) => v.entity === item.id && !v.resolved)).length} of ${personalisedItems.length} disrupted`}
                                    showBorder={false}
                                    datasets={[
                                        {
                                            title: 'Stable',
                                            count: personalisedItems.length - personalisedItems.filter((item) => entityContext?.disruptions.find((v) => v.entity === item.id && !v.resolved)).length,
                                            color: 'primary-4',

                                        },
                                        {
                                            title: 'In progress',
                                            count: entityContext?.disruptions.filter((v) => personalisedItems.some((i) => i.id === v.entity) && !v.resolved && v.assignee).length || 0,
                                            color: 'success',

                                        },
                                        {
                                            title: 'Disrupted',
                                            count: entityContext?.disruptions.filter((v) => personalisedItems.some((i) => i.id === v.entity) && !v.resolved && !v.assignee).length || 0,
                                            color: 'critical',
                                        },
                                    ]}
                                />

                                <div className="flex justify-end mt-auto">
                                    <img src="/images/icons/ic-arrow-right.svg"></img>
                                </div>
                            </div>
                        </Link>

                        <Link to={'tasks'}>
                            <div className="flex flex-col border border-neutral-2 bg-page rounded-md py-3 px-1 w-[370px] h-[158px]">
                                <div className="flex items-center gap-1 mx-2">
                                    <img className="h-[10px]" src='/images/icons/ic-tasks-gray.svg'></img>
                                    <p className="font-subheadline text-neutral-5">TASKS</p>
                                </div>

                                <StackedListBar
                                    title="Tasks"
                                    showBorder={false}
                                    subtitle={`${Object.values(tasksData).reduce((a, b) => a + b, 0) - tasksData.resolved} of ${Object.values(tasksData).reduce((a, b) => a + b, 0)} pending`}
                                    datasets={[
                                        {
                                            title: 'To Do',
                                            count: tasksData.todo,
                                            color: 'brown',

                                        },
                                        {
                                            title: 'In progress',
                                            count: tasksData.inProgress,
                                            color: 'danger',

                                        },

                                        {
                                            title: 'Needs Input',
                                            count: tasksData.needsInput,
                                            color: 'info',

                                        }, {
                                            title: `${tasksData.resolved}`,
                                            count: tasksData.resolved,
                                            color: '',
                                            showLabel: true,
                                        },
                                    ]}
                                />

                                <div className="flex justify-end mt-auto mx-2">
                                    <img src="/images/icons/ic-arrow-right.svg"></img>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="flex flex-col gap-1">
                        <div className="flex flex-col border border-neutral-2 bg-page rounded-md p-3 w-[170px] h-[158px]">
                            <div className="flex items-center gap-1">
                                <img className="h-[10px]" src={process.env.PUBLIC_URL + '/images/icons/ic-timer.svg'}></img>
                                <p className="font-subheadline text-neutral-5">FILL RATE</p>
                            </div>

                            <p className="font-large-title font-bold mt-1">{`${(mockContracts.map((v) => v.fillRate).reduce((a, b) => a + b, 0) / mockContracts.length).toFixed(2)}%`}</p>
                            <p className="font-title-3">past 30d</p>

                            <div className="flex relative gap-1 py-2 mt-auto">
                                <div className="w-[40%] h-1 bg-cyan rounded-full"></div>
                                <div className="w-[40%] h-1 bg-danger rounded-full"></div>
                                <div className="w-[10%] h-1 bg-caution rounded-full"></div>

                                <div className={`flex w-2 h-2 bg-black rounded-full border border-white absolute mt-[-2px] ml-[65.55%]`}>

                                </div>
                            </div>

                            <p className="font-subheadline font-semibold">Above average</p>
                        </div>

                        <div className="flex flex-col border border-neutral-2 bg-page rounded-md p-3 w-[170px] h-[158px]">
                            <div className="flex items-center gap-1">
                                <img className="h-[10px]" src={process.env.PUBLIC_URL + '/images/icons/ic-signature.svg'}></img>
                                <p className="font-subheadline text-neutral-5">CONTRACTS</p>
                            </div>

                            <p className="font-large-title font-bold mt-1">{`${mockContracts.filter((v) => {
                                const parts = v.end.split('/');
                                const date = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]))
                                const today = new Date();
                                const next30Days = new Date();
                                next30Days.setDate(today.getDate() + 180);

                                return date >= today && date <= next30Days
                            }).length} expiring`}</p>
                            <p className="font-title-3">in 6 months</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview