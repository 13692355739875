declare global {
    interface String {
        toArray(): string[];
    }
}

String.prototype.toArray = function () {
    let trimmedString = this.toString().trim();
    if (trimmedString.startsWith("[") && trimmedString.endsWith("]")) {
        trimmedString = trimmedString.slice(1, -1);
    }
    return trimmedString.split(",");
};

export {}