import { useState, useContext, useRef } from "react"

import { UserContext } from "../../context/globalContext"

import Dialog from "../dialog/dialog"
import PrimaryButton from "../primaryButton/primaryButton"
import ProfilePicture from "../profilePicture/profilePicture"

import Input from "../input/input"
import { Poll } from "../../model/chat/chat"
import uuid from "react-uuid"
import { Timestamp } from "firebase/firestore"

const CreatePollDialog = (props: { onDismiss: () => void, createPoll: (poll: Poll) => void }) => {
    const [page, setPage] = useState('create')
    const [reviewers, setReviewers] = useState<string[]>([])

    const [pollQuestion, setPollQuestion] = useState<string>('')
    const [pollDialogQuestion, setPollDialogQuestion] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [messageDialogText, setMessageDialogText] = useState<string>('')

    const [messageDialog, setMessageDialog] = useState(false)
    const [questionDialog, setQuestionDialog] = useState(false)

    const [reviewerSearch, setReviewerSearch] = useState<string>('')
    const [reviewerDropdown, setReviewerDropdown] = useState(false)

    const addReviewerButtonRef = useRef<HTMLDivElement>(null)
    const reviewerDropdownRef = useRef<HTMLDivElement>(null)

    const userContext = useContext(UserContext)

    return (
        <>
            <Dialog>
                <div className={`flex flex-col relative w-[500px] h-[600px] rounded-md`}>
                    <div className="flex flex-col p-5 h-full w-full">
                        {page === 'create' && (
                            <div className="flex flex-col justify-center w-full">
                                <p className="font-large-title font-bold text-center my-[40px]">Create Poll</p>

                                <div className="flex flex-col gap-6">
                                    <div className="flex gap-2 w-[360px] justify-start mx-auto">
                                        <img className="h-[36px] w-[36px]" src={`/images/icons/ic-person-2-blue.svg`}></img>

                                        <div className="flex flex-col gap-0">
                                            <p className="font-body font-semibold">Add relevant persons</p>
                                            <p className="font-body text-neutral-5">Choose team members to add as voters for a request you’re creating. The person you choose must have an account in Osmosis.</p>
                                        </div>
                                    </div>

                                    <div className="flex gap-2 w-[360px] justify-start mx-auto">
                                        <img className="h-[36px] w-[36px]" src={`/images/icons/ic-list-bullet-blue.svg`}></img>

                                        <div className="flex flex-col gap-0">
                                            <p className="font-body font-semibold">Ask for help</p>
                                            <p className="font-body text-neutral-5">If you need help to complete a request, you can ask reviewers to vote to quickly complete it.</p>
                                        </div>
                                    </div>

                                    <div className="flex gap-2 w-[360px] justify-start mx-auto">
                                        <img className="h-[36px] w-[48px] p-1.5" src={`/images/icons/ic-checkmark-blue.svg`}></img>

                                        <div className="flex flex-col gap-0">
                                            <p className="font-body font-semibold">Resolve poll</p>
                                            <p className="font-body text-neutral-5">Your reviewers can’t complete the poll but can only vote the poll. Remember to close it once you took a decision.</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )}

                        {page === 'reviewer' && (
                            <div className="flex flex-col justify-center w-full gap-4">
                                <p className="font-large-title font-bold text-center mt-[40px]">Add Reviewer Profile</p>

                                <p className="font-body text-center w-[360px] mx-auto">Choose someone you need to help you review this request to the inventory. The person you choose must have an Osmosis account</p>

                                <div className="max-h-[350px] grid grid-cols-3 gap-4 w-full overflow-y-auto">
                                    {reviewers.map((userId) => {
                                        const user = userContext?.users.find((v) => v.id === userId)

                                        return (
                                            <div onClick={() => setReviewers(reviewers.filter((v) => v !== userId))} className="flex flex-col gap-1.5 w-full">
                                                {user && (
                                                    <div className="relative mx-auto">
                                                        <ProfilePicture user={user} size={72} />
                                                        <img className="absolute right-2 bottom-0 w-[20px]" src="/images/icons/ic-checkmark-circle-fill-blue.svg" />
                                                    </div>
                                                )}

                                                <div className="flex flex-col">
                                                    <p className="font-body text-center mx-auto">{user?.name}</p>
                                                    <p className="font-body text-center mx-auto">{user?.team}</p>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="flex flex-col gap-1.5 w-full">
                                        <div ref={addReviewerButtonRef} onClick={() => setReviewerDropdown(!reviewerDropdown)} className="flex items-center justify-center w-[72px] h-[72px] rounded-full bg-neutral-2 mx-auto cursor-pointer">
                                            <img className="w-[20px] h-[20px]" src="/images/icons/ic-plus-blue.svg" />
                                        </div>

                                        <p className="font-body text-center w-[90px] mx-auto">Choose Someone Else</p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {page === 'message' && (
                            <div className="flex flex-col justify-center w-full h-full gap-4">
                                <p className="font-large-title font-bold text-center mt-[40px]">Attach Message & Question</p>

                                <p className="font-body text-center w-[360px] mx-auto">Ask question and send a message to your team to let them know you’d like to ask their opinion for the request. You can write a message or use the one provided.</p>

                                <div className="flex justify-center h-full">
                                    <div className="flex gap-1 my-auto">
                                        {userContext?.user && (
                                            <div className="mt-auto mb-2 h-[30px] w-[34px]">
                                                <ProfilePicture user={userContext?.user} size={30} />
                                            </div>
                                        )}

                                        <div className="flex flex-col px-2 w-full">
                                            <p onClick={() => setQuestionDialog(true)} className={`cursor-pointer mr-auto font-body px-3 py-2 p-chat ${!pollQuestion ? `send-gray before:bg-${false ? '[#0F62FE]' : 'transparent'} bg-[#0F62FE]` : `receive-gray before:bg-${false ? '[#E1E1E2]' : 'transparent'} bg-[#E1E1E2]`}`}>{pollQuestion || 'Click to define poll question'}</p>
                                            <p onClick={() => setMessageDialog(true)} className={`cursor-pointer mr-auto font-body px-3 py-2 p-chat ${false ? `send-gray before:bg-${false ? '[#0F62FE]' : 'transparent'} bg-[#0F62FE]` : `receive-gray before:bg-${false ? '[#E1E1E2]' : 'transparent'} bg-[#E1E1E2]`}`}>{message || 'Please join this conversation to share your input. We\'ll discuss the issue together and make a collaborative decision. Once we\'ve decided, the conversation will conclude.'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex px-5 h-[76px] items-center">
                        <PrimaryButton
                            title="Dismiss"
                            type="gray"
                            size="xl"
                            onClick={() => props.onDismiss()}
                        />

                        <div className="flex gap-2 ml-auto">
                            {page === 'create' && (
                                <PrimaryButton
                                    title="Add Reviewer"
                                    type="gray"
                                    size="xl"
                                    icon='ic-arrow-right.svg'
                                    onClick={() => setPage('reviewer')}
                                />
                            )}

                            {page === 'reviewer' && (
                                <PrimaryButton
                                    title="Attach Message"
                                    type="gray"
                                    size="xl"
                                    icon='ic-arrow-right.svg'
                                    disabled={reviewers.length === 0}
                                    onClick={() => setPage('message')}
                                />
                            )}

                            {page === 'message' && (
                                <>
                                    <PrimaryButton
                                        title="Back"
                                        type="gray"
                                        size="xl"
                                        onClick={() => setPage('reviewer')}
                                    />

                                    <PrimaryButton
                                        title="Edit Message"
                                        type="white"
                                        size="xl"
                                        onClick={() => setMessageDialog(true)}
                                    />

                                    <PrimaryButton
                                        title="Create Poll"
                                        type="primary"
                                        size="xl"
                                        onClick={() => {
                                            props.createPoll({
                                                id: uuid(),
                                                question: pollQuestion,
                                                message: message,
                                                reviewers: reviewers,
                                                createdAt: Timestamp.now(),
                                                approvedBy: [],
                                                rejectedBy: [],
                                            })

                                            props.onDismiss()
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>

            {questionDialog && (
                <Dialog>
                    <div className="relative p-5 w-[330px]">
                        <div className="flex">
                            <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                                <img className="h-[36px] w-[36px] gradient-light-grey p-1.5 rounded-full" src={'/images/icons/ic-pencil-lines-white.svg'}></img>
                            </div>

                        </div>

                        <div className="flex flex-col gap-[10px] my-[20px]">
                            <p className="font-body font-neutral-6 font-semibold text-center">Change Question</p>
                            <p className="font-subheadline font-neutral-2 text-center">You can change the poll question to help team members understand what you need.</p>

                            <Input
                                type='text'
                                placeholder="Write question..."
                                label="Poll Question"
                                value={pollDialogQuestion}
                                onChange={(v) => setPollDialogQuestion(v)}
                            />
                        </div>

                        <div className="flex gap-[10px]">
                            <PrimaryButton
                                title="Dismiss"
                                type="gray"
                                size="xl"
                                onClick={() => setQuestionDialog(false)}
                            />

                            <div className="ml-auto">
                                <PrimaryButton
                                    title="Update Question"
                                    type="primary"
                                    size="xl"
                                    disabled={!pollDialogQuestion}
                                    onClick={() => {
                                        setPollQuestion(pollDialogQuestion)
                                        setPollDialogQuestion('')
                                        setQuestionDialog(false)
                                    }}
                                />
                            </div>
                        </div>

                        <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
                    </div>
                </Dialog>
            )}

            {messageDialog && (
                <Dialog>
                    <div className="relative p-5 w-[330px]">
                        <div className="flex">
                            <div className="bg-white rounded-full p-[2px] mx-auto shadow-md">
                                <img className="h-[36px] w-[36px] gradient-light-grey p-1.5 rounded-full" src={'/images/icons/ic-pencil-lines-white.svg'}></img>
                            </div>

                        </div>

                        <div className="flex flex-col gap-[10px] my-[20px]">
                            <p className="font-body font-neutral-6 font-semibold text-center">Change Question</p>
                            <p className="font-subheadline font-neutral-2 text-center">You can change the poll question to help team members understand what you need.</p>

                            <Input
                                type='text'
                                placeholder="Write question..."
                                label="Poll Question"
                                value={pollDialogQuestion}
                                onChange={(v) => setPollDialogQuestion(v)}
                            />

                            <Input
                                type='text'
                                placeholder="Write message..."
                                label="Title"
                                value={messageDialogText}
                                onChange={(v) => setMessageDialogText(v)}
                            />
                        </div>

                        <div className="flex gap-[10px]">
                            <PrimaryButton
                                title="Dismiss"
                                type="gray"
                                size="xl"
                                onClick={() => setMessageDialog(false)}
                            />

                            <div className="ml-auto">
                                <PrimaryButton
                                    title="Update Question"
                                    type="primary"
                                    size="xl"
                                    disabled={!pollDialogQuestion}
                                    onClick={() => {
                                        setPollQuestion(pollDialogQuestion)
                                        setMessage(messageDialogText)
                                        setPollDialogQuestion('')
                                        setMessageDialogText('')
                                        setMessageDialog(false)
                                    }}
                                />
                            </div>
                        </div>

                        <img className="absolute right-3 top-3 h-[16px] w-[16px] bg-neutral-2 p-[3px] rounded-full" src={'/images/icons/ic-question-mark.svg'}></img>
                    </div>
                </Dialog>
            )}

            {reviewerDropdown && (
                <div ref={reviewerDropdownRef} style={{ top: (addReviewerButtonRef.current?.getBoundingClientRect().top || 0) + 80, left: addReviewerButtonRef.current?.getBoundingClientRect().left }} className="max-h-[300px] origin-top-left overflow-y-auto w-min fixed z-[500] rounded-md bg-material-medium ring-1 ring-black ring-opacity-5 focus:outline-none backdrop-blur-2xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                    <div className="flex flex-col p-2">
                        <div className="flex gap-1 p-2">
                            <img src="/images/icons/ic-magnifying-glass.svg" />
                            <input
                                autoFocus
                                className="font-body placeholder:text-neutral-5 bg-transparent focus:outline-none"
                                placeholder="Search..."
                                value={reviewerSearch}
                                onChange={(e) => setReviewerSearch(e.target.value)}
                            // onBlur={() => setReviewerDropdown(false)}
                            />
                        </div>

                        <div className="h-[1px] m-1 bg-neutral-2"></div>

                        <div role="none">
                            {userContext?.users.filter((option) => option.name.toLowerCase().includes(reviewerSearch.toLowerCase()) || !reviewerSearch).splice(0, 5).map((option) => {
                                return (
                                    <div onClick={() => {
                                        setReviewers([...reviewers, option.id])
                                        setReviewerDropdown(false)
                                    }} className="group cursor-pointer flex gap-2 w-full p-1 hover:bg-primary-4 rounded-md items-center">
                                        {option.photoURL ?
                                            <img className="w-[24px] h-[24px] rounded-full" src={option?.photoURL}></img>
                                            :
                                            <div className="w-[24px] h-[24px] gradient-light-grey rounded-full text-white text-center">
                                                <p className="font-body mt-[4px]">{option.name[0]}</p>
                                            </div>
                                        }

                                        <div>
                                            <p className="font-body whitespace-nowrap group-hover:text-white">{option.name}</p>
                                            <p className="font-subheadline whitespace-nowrap text-neutral-5 group-hover:text-white/50">{option.email}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CreatePollDialog