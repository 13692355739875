export const getPastSixWeeksStartDates = () => {
    const startDates = [];
    let startDate = new Date();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let i = 0; i < 6; i++) {
        startDate.setDate(startDate.getDate() - 7);
        const formattedDate = `${startDate.getDate()} ${months[startDate.getMonth()]}`
        startDates.unshift(formattedDate)
    }

    return startDates;
};