export interface ProcedureUtilisation {
  id: string
  openWeekly: number[],
  holdWeekly: number[],
  wastedWeekly: number[]
}

export var mockProcedureUtilisations: ProcedureUtilisation[] = [
  {
    "id": "PR-001",
    "openWeekly": [
      5,
      3,
      4,
      2,
      0,
      2,
      3,
      0,
      2,
      5,
      0,
      3,
      2,
      3,
      2,
      3,
      0,
      0,
      0,
      1,
      5,
      5,
      5,
      5,
      0,
      4,
      1,
      2,
      3,
      1,
      1,
      0,
      3,
      2,
      2,
      4,
      1,
      0,
      4,
      0,
      0,
      1,
      5,
      5,
      0,
      3,
      0,
      2,
      5,
      1,
      3,
      5
    ],
    "holdWeekly": [
      1,
      1,
      0,
      0,
      1,
      0,
      3,
      2,
      2,
      2,
      3,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      2,
      0,
      3,
      2,
      2,
      0,
      0,
      0,
      2,
      0,
      3,
      2,
      2,
      0,
      0,
      0,
      1,
      3,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      3,
      1,
      3,
      1,
      1,
      3
    ],
    "wastedWeekly": [
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      0,
      1,
      2,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      1
    ]
  },
  {
    "id": "PR-002",
    "openWeekly": [
      5,
      4,
      0,
      2,
      3,
      0,
      3,
      4,
      2,
      2,
      3,
      1,
      0,
      1,
      5,
      0,
      0,
      5,
      3,
      1,
      0,
      3,
      1,
      0,
      3,
      2,
      0,
      5,
      3,
      5,
      2,
      5,
      5,
      1,
      1,
      5,
      3,
      5,
      2,
      0,
      3,
      5,
      3,
      4,
      1,
      0,
      0,
      2,
      0,
      1,
      3,
      4
    ],
    "holdWeekly": [
      0,
      1,
      0,
      0,
      2,
      2,
      0,
      3,
      0,
      1,
      3,
      2,
      2,
      2,
      0,
      0,
      2,
      2,
      3,
      0,
      2,
      0,
      3,
      2,
      3,
      1,
      1,
      3,
      0,
      3,
      1,
      1,
      2,
      1,
      3,
      0,
      0,
      1,
      3,
      1,
      1,
      0,
      1,
      1,
      2,
      3,
      1,
      1,
      3,
      1,
      2,
      1
    ],
    "wastedWeekly": [
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      2,
      0,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-003",
    "openWeekly": [
      5,
      0,
      0,
      2,
      1,
      0,
      5,
      4,
      3,
      4,
      0,
      1,
      4,
      4,
      1,
      0,
      2,
      5,
      2,
      2,
      0,
      5,
      1,
      2,
      5,
      2,
      3,
      2,
      3,
      5,
      5,
      4,
      4,
      0,
      3,
      2,
      5,
      5,
      0,
      2,
      1,
      1,
      5,
      2,
      2,
      3,
      1,
      0,
      2,
      1,
      2,
      4
    ],
    "holdWeekly": [
      3,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      3,
      3,
      2,
      1,
      3,
      2,
      3,
      3,
      3,
      0,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      0,
      3,
      0,
      1,
      3,
      1,
      0,
      2,
      1,
      0,
      3,
      3,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      3,
      3,
      0,
      1,
      2
    ],
    "wastedWeekly": [
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      0,
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-004",
    "openWeekly": [
      0,
      5,
      3,
      3,
      1,
      1,
      5,
      4,
      5,
      5,
      5,
      3,
      2,
      2,
      5,
      5,
      4,
      5,
      5,
      4,
      3,
      5,
      0,
      0,
      5,
      1,
      3,
      4,
      3,
      5,
      4,
      2,
      5,
      0,
      5,
      4,
      1,
      0,
      1,
      0,
      1,
      2,
      4,
      2,
      0,
      3,
      0,
      3,
      0,
      2,
      1,
      1
    ],
    "holdWeekly": [
      1,
      3,
      0,
      2,
      1,
      2,
      1,
      3,
      3,
      1,
      0,
      0,
      0,
      2,
      0,
      2,
      3,
      1,
      1,
      2,
      1,
      3,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      2,
      3,
      1,
      3,
      2,
      0,
      0,
      0,
      1,
      2,
      3,
      2,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      3,
      0,
      3,
      1
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      2
    ]
  },
  {
    "id": "PR-005",
    "openWeekly": [
      2,
      5,
      2,
      5,
      0,
      0,
      5,
      4,
      1,
      5,
      0,
      0,
      5,
      4,
      0,
      5,
      1,
      0,
      0,
      1,
      0,
      0,
      3,
      3,
      3,
      0,
      2,
      2,
      5,
      3,
      1,
      4,
      2,
      5,
      5,
      5,
      5,
      1,
      3,
      3,
      1,
      5,
      5,
      3,
      0,
      1,
      0,
      0,
      1,
      5,
      3,
      0
    ],
    "holdWeekly": [
      2,
      1,
      0,
      1,
      1,
      1,
      3,
      0,
      1,
      2,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      0,
      2,
      3,
      3,
      0,
      3,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      2,
      3,
      0,
      1,
      3,
      1,
      0,
      2,
      2,
      2,
      2,
      2,
      2,
      3,
      1,
      2,
      2,
      2
    ],
    "wastedWeekly": [
      2,
      0,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-006",
    "openWeekly": [
      5,
      1,
      2,
      2,
      2,
      1,
      3,
      1,
      5,
      5,
      2,
      2,
      0,
      0,
      3,
      3,
      1,
      5,
      4,
      0,
      0,
      2,
      0,
      1,
      5,
      4,
      5,
      0,
      5,
      5,
      2,
      2,
      5,
      4,
      0,
      5,
      5,
      3,
      1,
      0,
      3,
      2,
      5,
      0,
      1,
      2,
      4,
      5,
      0,
      5,
      1,
      0
    ],
    "holdWeekly": [
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      2,
      2,
      3,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      0,
      3,
      3,
      0,
      2,
      3,
      1,
      2,
      2,
      2,
      3,
      0,
      3,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      3,
      2,
      3,
      2,
      1,
      1,
      3,
      3,
      2,
      3,
      2,
      3
    ],
    "wastedWeekly": [
      0,
      0,
      1,
      0,
      0,
      0,
      2,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      0,
      0,
      2,
      0,
      2,
      2,
      0,
      1,
      2,
      2,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      2
    ]
  },
  {
    "id": "PR-007",
    "openWeekly": [
      4,
      2,
      2,
      3,
      1,
      0,
      4,
      5,
      0,
      2,
      2,
      1,
      0,
      1,
      3,
      5,
      1,
      4,
      5,
      2,
      0,
      5,
      1,
      5,
      2,
      0,
      4,
      5,
      4,
      5,
      4,
      0,
      5,
      3,
      0,
      3,
      5,
      2,
      2,
      4,
      0,
      2,
      5,
      4,
      1,
      5,
      3,
      2,
      4,
      5,
      3,
      3
    ],
    "holdWeekly": [
      1,
      2,
      1,
      0,
      2,
      3,
      1,
      0,
      2,
      3,
      1,
      2,
      0,
      2,
      0,
      1,
      0,
      3,
      1,
      2,
      0,
      0,
      0,
      2,
      0,
      0,
      2,
      3,
      0,
      1,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      3,
      1,
      1,
      3,
      2,
      2,
      0,
      2,
      2
    ],
    "wastedWeekly": [
      1,
      1,
      0,
      2,
      0,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      1,
      2,
      0,
      2,
      2,
      1
    ]
  },
  {
    "id": "PR-008",
    "openWeekly": [
      4,
      3,
      1,
      0,
      3,
      0,
      4,
      2,
      3,
      5,
      2,
      1,
      2,
      0,
      1,
      2,
      0,
      4,
      1,
      0,
      2,
      0,
      5,
      5,
      0,
      0,
      3,
      3,
      5,
      3,
      4,
      1,
      5,
      1,
      2,
      4,
      4,
      1,
      1,
      5,
      0,
      3,
      5,
      4,
      0,
      2,
      5,
      5,
      3,
      2,
      1,
      5
    ],
    "holdWeekly": [
      2,
      3,
      2,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      0,
      2,
      3,
      2,
      1,
      3,
      3,
      1,
      3,
      1,
      1,
      0,
      1,
      0,
      2,
      1,
      3,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      1,
      3,
      2,
      0,
      0,
      0,
      1,
      1,
      3,
      3,
      3,
      3,
      0,
      2,
      3,
      0
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      2,
      1,
      1,
      0,
      0,
      0,
      2,
      2,
      0,
      0
    ]
  },
  {
    "id": "PR-009",
    "openWeekly": [
      4,
      5,
      2,
      3,
      3,
      4,
      3,
      1,
      3,
      5,
      1,
      4,
      1,
      4,
      4,
      2,
      1,
      1,
      1,
      3,
      0,
      4,
      4,
      3,
      3,
      3,
      0,
      3,
      4,
      4,
      2,
      2,
      5,
      3,
      1,
      4,
      4,
      1,
      0,
      2,
      1,
      4,
      2,
      2,
      4,
      3,
      1,
      4,
      0,
      2,
      5,
      4
    ],
    "holdWeekly": [
      3,
      3,
      2,
      3,
      1,
      1,
      2,
      2,
      1,
      3,
      2,
      0,
      0,
      1,
      3,
      1,
      3,
      3,
      1,
      2,
      2,
      0,
      2,
      0,
      0,
      2,
      3,
      0,
      0,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      3,
      3,
      2,
      1,
      3,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      1,
      2
    ],
    "wastedWeekly": [
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      2
    ]
  },
  {
    "id": "PR-010",
    "openWeekly": [
      3,
      4,
      3,
      4,
      3,
      1,
      1,
      1,
      4,
      5,
      2,
      2,
      3,
      4,
      3,
      5,
      2,
      1,
      5,
      0,
      4,
      2,
      1,
      3,
      5,
      3,
      3,
      0,
      5,
      2,
      4,
      3,
      5,
      0,
      0,
      3,
      5,
      1,
      2,
      2,
      0,
      1,
      5,
      4,
      1,
      0,
      0,
      0,
      0,
      4,
      1,
      3
    ],
    "holdWeekly": [
      3,
      1,
      0,
      2,
      1,
      1,
      0,
      3,
      2,
      3,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      3,
      2,
      3,
      3,
      2,
      0,
      3,
      3,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      3,
      0,
      1,
      3,
      1,
      2,
      3,
      1,
      3,
      1,
      3,
      1,
      3,
      0,
      0,
      1,
      1,
      3
    ],
    "wastedWeekly": [
      1,
      2,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-011",
    "openWeekly": [
      1,
      1,
      1,
      4,
      0,
      3,
      5,
      4,
      4,
      5,
      2,
      4,
      5,
      5,
      0,
      5,
      1,
      3,
      4,
      0,
      0,
      4,
      0,
      0,
      5,
      5,
      3,
      5,
      3,
      5,
      1,
      4,
      0,
      4,
      1,
      3,
      3,
      2,
      3,
      0,
      5,
      5,
      5,
      2,
      0,
      3,
      0,
      5,
      4,
      2,
      3,
      2
    ],
    "holdWeekly": [
      3,
      0,
      0,
      2,
      0,
      1,
      3,
      2,
      0,
      2,
      3,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      0,
      2,
      1,
      2,
      1,
      0,
      3,
      3,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      3,
      0,
      2
    ],
    "wastedWeekly": [
      0,
      2,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      0,
      1,
      1,
      1
    ]
  },
  {
    "id": "PR-012",
    "openWeekly": [
      5,
      4,
      1,
      1,
      0,
      5,
      5,
      5,
      0,
      5,
      4,
      2,
      2,
      4,
      5,
      1,
      3,
      4,
      4,
      5,
      5,
      5,
      3,
      1,
      0,
      0,
      4,
      3,
      5,
      4,
      4,
      2,
      5,
      5,
      3,
      3,
      1,
      1,
      0,
      0,
      1,
      4,
      5,
      2,
      0,
      1,
      4,
      5,
      1,
      2,
      0,
      3
    ],
    "holdWeekly": [
      1,
      1,
      1,
      1,
      3,
      0,
      0,
      1,
      2,
      3,
      3,
      1,
      3,
      1,
      3,
      1,
      1,
      3,
      1,
      3,
      2,
      0,
      3,
      2,
      0,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      2,
      3,
      0,
      0,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      1,
      3,
      3,
      0,
      2,
      2
    ],
    "wastedWeekly": [
      2,
      2,
      0,
      2,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      0,
      0,
      2,
      0,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-013",
    "openWeekly": [
      0,
      3,
      1,
      3,
      5,
      1,
      3,
      5,
      2,
      0,
      3,
      3,
      0,
      2,
      4,
      3,
      3,
      4,
      3,
      1,
      0,
      5,
      0,
      2,
      5,
      2,
      3,
      5,
      4,
      2,
      4,
      3,
      3,
      5,
      5,
      5,
      3,
      1,
      3,
      0,
      1,
      0,
      5,
      5,
      1,
      0,
      2,
      2,
      0,
      5,
      4,
      2
    ],
    "holdWeekly": [
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      0,
      2,
      0,
      3,
      1,
      2,
      2,
      1,
      3,
      1,
      3,
      0,
      1,
      1,
      3,
      0,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      3,
      0,
      2,
      0,
      0,
      0,
      1,
      3,
      1,
      2,
      2,
      1,
      2,
      2,
      3,
      3,
      2,
      2,
      3,
      3
    ],
    "wastedWeekly": [
      2,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      0,
      0,
      0,
      0,
      1
    ]
  },
  {
    "id": "PR-014",
    "openWeekly": [
      2,
      5,
      3,
      2,
      2,
      1,
      0,
      0,
      4,
      2,
      1,
      5,
      5,
      3,
      0,
      4,
      0,
      1,
      0,
      3,
      1,
      1,
      4,
      0,
      0,
      5,
      3,
      4,
      5,
      4,
      2,
      2,
      5,
      1,
      3,
      5,
      5,
      1,
      5,
      2,
      0,
      5,
      4,
      5,
      0,
      0,
      0,
      5,
      2,
      5,
      4,
      4
    ],
    "holdWeekly": [
      2,
      3,
      2,
      0,
      0,
      3,
      0,
      0,
      1,
      1,
      3,
      3,
      2,
      1,
      3,
      3,
      2,
      1,
      3,
      0,
      0,
      1,
      3,
      1,
      0,
      1,
      2,
      2,
      3,
      1,
      2,
      1,
      3,
      2,
      1,
      2,
      0,
      3,
      0,
      1,
      3,
      1,
      1,
      2,
      3,
      0,
      2,
      3,
      2,
      2,
      2,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      1
    ]
  },
  {
    "id": "PR-015",
    "openWeekly": [
      1,
      4,
      2,
      2,
      1,
      1,
      2,
      0,
      1,
      5,
      1,
      2,
      1,
      0,
      2,
      5,
      2,
      5,
      3,
      4,
      5,
      1,
      2,
      1,
      1,
      1,
      5,
      5,
      5,
      2,
      3,
      0,
      4,
      0,
      4,
      5,
      5,
      0,
      0,
      1,
      0,
      3,
      3,
      3,
      0,
      3,
      0,
      4,
      0,
      1,
      5,
      4
    ],
    "holdWeekly": [
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      3,
      3,
      0,
      0,
      1,
      2,
      0,
      1,
      2,
      0,
      3,
      0,
      3,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      0,
      1,
      1,
      0,
      0,
      3,
      2,
      1,
      2,
      1,
      3,
      0,
      3,
      0,
      2,
      3,
      3,
      2,
      3,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      0,
      1,
      0
    ]
  },
  {
    "id": "PR-016",
    "openWeekly": [
      0,
      3,
      1,
      0,
      3,
      4,
      5,
      5,
      4,
      2,
      5,
      5,
      4,
      5,
      5,
      1,
      1,
      3,
      4,
      0,
      2,
      4,
      0,
      4,
      5,
      3,
      3,
      5,
      3,
      3,
      5,
      2,
      3,
      3,
      0,
      0,
      5,
      0,
      0,
      2,
      3,
      4,
      4,
      2,
      0,
      0,
      3,
      1,
      0,
      4,
      4,
      5
    ],
    "holdWeekly": [
      3,
      0,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      3,
      1,
      3,
      1,
      1,
      3,
      3,
      3,
      3,
      2,
      1,
      1,
      0,
      0,
      2,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      1,
      0,
      1,
      2,
      3,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      3,
      3
    ],
    "wastedWeekly": [
      0,
      0,
      1,
      1,
      0,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      0,
      0,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      2
    ]
  },
  {
    "id": "PR-017",
    "openWeekly": [
      2,
      4,
      4,
      2,
      1,
      0,
      2,
      0,
      0,
      1,
      1,
      3,
      4,
      3,
      0,
      2,
      4,
      5,
      2,
      3,
      3,
      4,
      0,
      5,
      4,
      3,
      3,
      3,
      5,
      5,
      0,
      1,
      2,
      2,
      2,
      3,
      5,
      0,
      3,
      0,
      2,
      4,
      5,
      0,
      0,
      0,
      1,
      4,
      2,
      4,
      2,
      3
    ],
    "holdWeekly": [
      2,
      1,
      0,
      3,
      2,
      0,
      2,
      1,
      2,
      3,
      3,
      1,
      0,
      1,
      3,
      3,
      1,
      0,
      1,
      2,
      2,
      0,
      3,
      1,
      0,
      3,
      0,
      3,
      2,
      1,
      0,
      0,
      3,
      2,
      3,
      1,
      2,
      0,
      1,
      0,
      3,
      0,
      3,
      1,
      3,
      2,
      2,
      1,
      2,
      2,
      2,
      3
    ],
    "wastedWeekly": [
      2,
      2,
      0,
      2,
      1,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      0,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      0,
      2,
      2,
      0,
      2,
      2,
      0,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2
    ]
  },
  {
    "id": "PR-018",
    "openWeekly": [
      5,
      5,
      0,
      3,
      0,
      4,
      5,
      1,
      0,
      1,
      3,
      2,
      2,
      2,
      0,
      0,
      1,
      5,
      1,
      0,
      1,
      5,
      2,
      1,
      5,
      5,
      1,
      5,
      3,
      4,
      5,
      4,
      5,
      5,
      4,
      3,
      0,
      1,
      2,
      0,
      3,
      4,
      0,
      5,
      2,
      5,
      5,
      3,
      2,
      2,
      1,
      0
    ],
    "holdWeekly": [
      3,
      0,
      1,
      0,
      2,
      0,
      2,
      1,
      1,
      1,
      3,
      1,
      2,
      2,
      3,
      2,
      3,
      2,
      3,
      0,
      1,
      0,
      1,
      0,
      2,
      1,
      3,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      3,
      3,
      2,
      2,
      2,
      2,
      2,
      3,
      1,
      3,
      3,
      1,
      3,
      3,
      2,
      1,
      2
    ],
    "wastedWeekly": [
      2,
      2,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      2,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      0
    ]
  },
  {
    "id": "PR-019",
    "openWeekly": [
      5,
      3,
      2,
      5,
      1,
      0,
      2,
      3,
      0,
      2,
      2,
      4,
      5,
      5,
      3,
      4,
      0,
      4,
      4,
      1,
      2,
      1,
      3,
      1,
      5,
      1,
      5,
      3,
      5,
      2,
      3,
      5,
      2,
      0,
      5,
      0,
      0,
      1,
      1,
      0,
      2,
      4,
      5,
      5,
      0,
      1,
      1,
      3,
      4,
      5,
      4,
      5
    ],
    "holdWeekly": [
      0,
      3,
      0,
      1,
      3,
      3,
      2,
      2,
      0,
      3,
      3,
      1,
      0,
      3,
      1,
      3,
      1,
      2,
      3,
      2,
      2,
      1,
      0,
      3,
      0,
      0,
      1,
      2,
      0,
      0,
      0,
      0,
      3,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      3,
      1,
      3,
      2,
      2,
      2,
      3,
      3,
      3,
      1,
      2,
      1
    ],
    "wastedWeekly": [
      2,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      2,
      0,
      1,
      1,
      0
    ]
  },
  {
    "id": "PR-020",
    "openWeekly": [
      5,
      3,
      3,
      1,
      2,
      1,
      3,
      2,
      0,
      3,
      1,
      2,
      1,
      0,
      5,
      0,
      0,
      5,
      5,
      0,
      5,
      2,
      4,
      5,
      5,
      3,
      2,
      4,
      2,
      4,
      2,
      2,
      1,
      0,
      0,
      0,
      5,
      4,
      1,
      5,
      3,
      5,
      5,
      3,
      1,
      2,
      4,
      5,
      0,
      3,
      4,
      0
    ],
    "holdWeekly": [
      1,
      0,
      0,
      2,
      3,
      0,
      0,
      1,
      3,
      2,
      1,
      3,
      0,
      2,
      2,
      3,
      3,
      2,
      1,
      0,
      2,
      0,
      0,
      2,
      0,
      0,
      3,
      2,
      0,
      2,
      3,
      2,
      3,
      0,
      0,
      2,
      1,
      3,
      2,
      1,
      2,
      0,
      0,
      2,
      2,
      3,
      3,
      2,
      2,
      2,
      3,
      2
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      2,
      2
    ]
  },
  {
    "id": "PR-021",
    "openWeekly": [
      2,
      2,
      0,
      0,
      0,
      0,
      5,
      5,
      1,
      5,
      0,
      3,
      1,
      0,
      0,
      5,
      0,
      5,
      5,
      0,
      0,
      5,
      0,
      0,
      5,
      2,
      5,
      3,
      0,
      2,
      4,
      0,
      5,
      0,
      4,
      5,
      5,
      0,
      0,
      3,
      0,
      2,
      3,
      2,
      1,
      3,
      2,
      4,
      1,
      2,
      2,
      0
    ],
    "holdWeekly": [
      3,
      0,
      1,
      3,
      3,
      1,
      1,
      3,
      1,
      1,
      1,
      3,
      2,
      3,
      1,
      1,
      1,
      1,
      3,
      2,
      0,
      0,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      0,
      1,
      2,
      0,
      0,
      2,
      3,
      3,
      2,
      1,
      3,
      3,
      3,
      2,
      0,
      1,
      2
    ],
    "wastedWeekly": [
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      2
    ]
  },
  {
    "id": "PR-022",
    "openWeekly": [
      2,
      5,
      1,
      3,
      1,
      4,
      2,
      5,
      2,
      5,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      3,
      3,
      2,
      5,
      2,
      4,
      5,
      4,
      0,
      5,
      2,
      1,
      5,
      4,
      2,
      5,
      5,
      5,
      0,
      2,
      2,
      0,
      3,
      4,
      3,
      0,
      0,
      0,
      0,
      5,
      1,
      5,
      5,
      3
    ],
    "holdWeekly": [
      3,
      0,
      2,
      0,
      3,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      3,
      2,
      2,
      3,
      0,
      2,
      2,
      0,
      3,
      2,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      3,
      2,
      2,
      3,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      2,
      0,
      3,
      2,
      0,
      2,
      3,
      1
    ],
    "wastedWeekly": [
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      2,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      0
    ]
  },
  {
    "id": "PR-023",
    "openWeekly": [
      2,
      1,
      2,
      2,
      1,
      4,
      4,
      2,
      1,
      5,
      5,
      4,
      2,
      5,
      1,
      5,
      3,
      4,
      3,
      1,
      2,
      0,
      1,
      3,
      1,
      4,
      5,
      0,
      5,
      4,
      1,
      1,
      3,
      5,
      1,
      3,
      5,
      5,
      3,
      0,
      2,
      4,
      5,
      1,
      0,
      4,
      2,
      4,
      1,
      2,
      4,
      5
    ],
    "holdWeekly": [
      2,
      0,
      1,
      2,
      1,
      1,
      1,
      2,
      3,
      3,
      1,
      2,
      2,
      1,
      0,
      0,
      3,
      1,
      3,
      3,
      2,
      1,
      0,
      2,
      1,
      3,
      3,
      0,
      3,
      1,
      1,
      1,
      3,
      0,
      3,
      0,
      2,
      2,
      3,
      0,
      2,
      0,
      3,
      2,
      3,
      2,
      2,
      3,
      2,
      0,
      2,
      0
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      2,
      2,
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      2,
      2,
      1
    ]
  },
  {
    "id": "PR-024",
    "openWeekly": [
      4,
      3,
      3,
      1,
      1,
      0,
      4,
      5,
      0,
      1,
      3,
      3,
      2,
      4,
      1,
      2,
      0,
      0,
      5,
      0,
      0,
      3,
      0,
      0,
      4,
      3,
      0,
      5,
      5,
      0,
      2,
      1,
      5,
      3,
      1,
      3,
      5,
      0,
      2,
      0,
      4,
      5,
      5,
      5,
      0,
      3,
      3,
      2,
      2,
      5,
      1,
      5
    ],
    "holdWeekly": [
      0,
      1,
      0,
      2,
      2,
      1,
      0,
      3,
      0,
      2,
      3,
      1,
      0,
      1,
      3,
      2,
      0,
      3,
      0,
      2,
      0,
      1,
      2,
      2,
      2,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      3,
      2,
      3,
      0,
      2,
      0,
      2,
      3,
      3,
      1,
      3,
      2,
      2,
      3,
      1,
      3,
      3,
      0,
      3,
      1
    ],
    "wastedWeekly": [
      2,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      2,
      0,
      1,
      0,
      1,
      0,
      2,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      1,
      2,
      1
    ]
  },
  {
    "id": "PR-025",
    "openWeekly": [
      5,
      5,
      0,
      4,
      4,
      2,
      4,
      4,
      2,
      1,
      0,
      3,
      4,
      5,
      3,
      1,
      1,
      5,
      3,
      4,
      0,
      5,
      2,
      5,
      1,
      1,
      5,
      4,
      5,
      4,
      5,
      4,
      4,
      5,
      3,
      5,
      1,
      1,
      4,
      3,
      0,
      1,
      3,
      5,
      0,
      5,
      0,
      1,
      5,
      4,
      4,
      3
    ],
    "holdWeekly": [
      1,
      0,
      1,
      2,
      2,
      3,
      2,
      2,
      3,
      2,
      1,
      1,
      3,
      1,
      2,
      2,
      1,
      3,
      3,
      2,
      2,
      0,
      1,
      1,
      3,
      2,
      2,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      0,
      2,
      2,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      3,
      1,
      2
    ],
    "wastedWeekly": [
      1,
      0,
      2,
      0,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      2,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      1,
      2,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      1
    ]
  },
  {
    "id": "PR-026",
    "openWeekly": [
      5,
      1,
      2,
      5,
      0,
      3,
      3,
      0,
      3,
      5,
      3,
      3,
      0,
      4,
      0,
      2,
      0,
      0,
      1,
      5,
      0,
      3,
      0,
      3,
      4,
      2,
      0,
      2,
      5,
      3,
      3,
      1,
      5,
      3,
      4,
      5,
      0,
      2,
      0,
      0,
      4,
      3,
      5,
      3,
      0,
      2,
      0,
      5,
      5,
      3,
      2,
      5
    ],
    "holdWeekly": [
      2,
      1,
      1,
      2,
      2,
      2,
      2,
      3,
      0,
      0,
      1,
      0,
      3,
      2,
      3,
      3,
      0,
      2,
      0,
      1,
      3,
      1,
      3,
      2,
      3,
      2,
      2,
      0,
      0,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      1,
      3,
      0,
      3,
      3,
      0,
      3,
      3,
      1,
      2,
      3,
      2,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      0
    ]
  },
  {
    "id": "PR-027",
    "openWeekly": [
      0,
      5,
      3,
      1,
      1,
      1,
      5,
      1,
      0,
      5,
      1,
      5,
      4,
      1,
      2,
      4,
      2,
      5,
      2,
      3,
      5,
      0,
      0,
      5,
      5,
      5,
      0,
      0,
      5,
      1,
      5,
      1,
      3,
      0,
      1,
      5,
      5,
      5,
      4,
      2,
      1,
      1,
      5,
      2,
      3,
      5,
      1,
      5,
      0,
      0,
      5,
      5
    ],
    "holdWeekly": [
      2,
      0,
      2,
      3,
      1,
      3,
      0,
      2,
      2,
      3,
      2,
      1,
      0,
      2,
      2,
      0,
      2,
      1,
      3,
      1,
      3,
      0,
      0,
      3,
      1,
      3,
      1,
      2,
      2,
      0,
      0,
      1,
      0,
      3,
      2,
      0,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      0,
      3,
      2,
      2,
      2,
      0,
      0,
      0,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      0,
      2,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      2
    ]
  },
  {
    "id": "PR-028",
    "openWeekly": [
      2,
      0,
      2,
      4,
      1,
      1,
      3,
      2,
      0,
      3,
      0,
      0,
      3,
      4,
      1,
      5,
      2,
      5,
      1,
      0,
      0,
      0,
      0,
      3,
      5,
      5,
      0,
      0,
      3,
      4,
      3,
      5,
      5,
      0,
      3,
      3,
      3,
      4,
      4,
      4,
      4,
      1,
      3,
      1,
      2,
      0,
      3,
      1,
      1,
      4,
      1,
      5
    ],
    "holdWeekly": [
      2,
      1,
      1,
      1,
      3,
      2,
      1,
      3,
      2,
      1,
      1,
      3,
      2,
      2,
      3,
      2,
      1,
      1,
      3,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      3,
      3,
      0,
      3,
      0,
      1,
      1,
      3,
      3,
      1,
      1,
      3,
      0,
      1,
      3,
      0,
      1,
      0,
      3,
      3,
      3,
      3,
      3,
      0,
      1,
      0
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      1
    ]
  },
  {
    "id": "PR-029",
    "openWeekly": [
      3,
      4,
      2,
      5,
      0,
      1,
      2,
      5,
      0,
      5,
      5,
      1,
      5,
      1,
      0,
      2,
      2,
      2,
      2,
      0,
      1,
      5,
      2,
      1,
      5,
      3,
      1,
      4,
      5,
      5,
      4,
      2,
      5,
      5,
      1,
      1,
      5,
      0,
      5,
      0,
      0,
      1,
      5,
      5,
      0,
      2,
      1,
      3,
      2,
      5,
      2,
      1
    ],
    "holdWeekly": [
      1,
      0,
      0,
      0,
      3,
      3,
      0,
      2,
      1,
      3,
      3,
      2,
      3,
      1,
      2,
      0,
      3,
      3,
      1,
      0,
      2,
      0,
      1,
      2,
      0,
      3,
      2,
      2,
      2,
      1,
      1,
      0,
      3,
      3,
      3,
      2,
      0,
      2,
      1,
      0,
      3,
      1,
      0,
      3,
      3,
      3,
      1,
      2,
      2,
      1,
      3,
      2
    ],
    "wastedWeekly": [
      2,
      1,
      2,
      0,
      2,
      2,
      0,
      2,
      0,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      1,
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      0,
      2,
      0
    ]
  },
  {
    "id": "PR-030",
    "openWeekly": [
      3,
      1,
      5,
      5,
      5,
      1,
      3,
      0,
      2,
      0,
      2,
      5,
      2,
      4,
      5,
      0,
      1,
      2,
      0,
      3,
      1,
      5,
      1,
      2,
      4,
      2,
      5,
      4,
      3,
      4,
      0,
      3,
      5,
      2,
      0,
      5,
      2,
      4,
      0,
      5,
      4,
      5,
      5,
      2,
      0,
      4,
      2,
      5,
      0,
      4,
      3,
      5
    ],
    "holdWeekly": [
      2,
      0,
      1,
      2,
      3,
      2,
      2,
      1,
      2,
      0,
      2,
      2,
      0,
      2,
      1,
      2,
      0,
      3,
      3,
      3,
      3,
      0,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      0,
      0,
      3,
      3,
      1,
      0,
      2,
      0,
      2,
      0,
      0,
      3,
      0,
      1,
      3,
      3,
      3,
      3,
      2,
      3,
      0,
      2,
      3
    ],
    "wastedWeekly": [
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      0,
      0,
      2,
      1,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      2,
      0,
      0,
      2,
      2,
      0,
      2,
      2,
      0,
      0,
      2,
      0,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      0
    ]
  },
  {
    "id": "PR-031",
    "openWeekly": [
      5,
      4,
      4,
      1,
      0,
      1,
      2,
      5,
      1,
      1,
      5,
      1,
      0,
      5,
      3,
      5,
      0,
      3,
      5,
      0,
      0,
      3,
      2,
      0,
      3,
      4,
      3,
      0,
      5,
      2,
      0,
      2,
      3,
      1,
      4,
      5,
      5,
      3,
      1,
      0,
      2,
      0,
      5,
      4,
      0,
      3,
      0,
      3,
      4,
      0,
      3,
      4
    ],
    "holdWeekly": [
      2,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      3,
      1,
      0,
      1,
      0,
      3,
      1,
      3,
      3,
      3,
      1,
      2,
      0,
      1,
      1,
      2,
      2,
      3,
      2,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      1,
      3,
      1,
      1,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      3
    ],
    "wastedWeekly": [
      0,
      1,
      2,
      0,
      2,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      1
    ]
  },
  {
    "id": "PR-032",
    "openWeekly": [
      5,
      1,
      4,
      4,
      1,
      0,
      3,
      2,
      0,
      5,
      2,
      2,
      5,
      4,
      5,
      5,
      1,
      5,
      4,
      0,
      1,
      1,
      5,
      1,
      1,
      2,
      1,
      0,
      0,
      3,
      2,
      4,
      5,
      0,
      0,
      3,
      3,
      0,
      5,
      0,
      0,
      4,
      5,
      3,
      2,
      2,
      4,
      3,
      3,
      2,
      3,
      0
    ],
    "holdWeekly": [
      2,
      1,
      1,
      2,
      0,
      1,
      0,
      2,
      2,
      3,
      3,
      0,
      2,
      2,
      0,
      3,
      1,
      2,
      2,
      2,
      3,
      2,
      3,
      2,
      2,
      2,
      3,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      3,
      0,
      1,
      2,
      3,
      0,
      3,
      0,
      1,
      0,
      2,
      1,
      3,
      2,
      3,
      1,
      0,
      0
    ],
    "wastedWeekly": [
      0,
      1,
      0,
      0,
      0,
      2,
      0,
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      0,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-033",
    "openWeekly": [
      2,
      0,
      2,
      5,
      2,
      0,
      2,
      2,
      4,
      5,
      1,
      4,
      0,
      0,
      0,
      1,
      0,
      4,
      0,
      4,
      2,
      4,
      5,
      3,
      5,
      0,
      0,
      5,
      3,
      5,
      2,
      0,
      4,
      2,
      3,
      5,
      5,
      2,
      5,
      0,
      0,
      0,
      5,
      2,
      0,
      5,
      4,
      1,
      1,
      1,
      5,
      3
    ],
    "holdWeekly": [
      2,
      0,
      1,
      1,
      2,
      0,
      2,
      3,
      0,
      1,
      2,
      2,
      0,
      3,
      2,
      0,
      2,
      3,
      2,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      3,
      0,
      3,
      1,
      0,
      3,
      3,
      1,
      3,
      0,
      1,
      3,
      0,
      1,
      1,
      0,
      1,
      1,
      2,
      3,
      0,
      3,
      0,
      2,
      0,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      2,
      0,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      2,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      0
    ]
  },
  {
    "id": "PR-034",
    "openWeekly": [
      5,
      0,
      2,
      4,
      0,
      0,
      5,
      1,
      1,
      5,
      0,
      5,
      0,
      1,
      0,
      3,
      0,
      1,
      0,
      0,
      1,
      1,
      4,
      3,
      4,
      5,
      4,
      4,
      4,
      5,
      0,
      2,
      4,
      1,
      0,
      4,
      5,
      0,
      4,
      2,
      5,
      4,
      5,
      1,
      0,
      5,
      2,
      5,
      2,
      5,
      3,
      4
    ],
    "holdWeekly": [
      2,
      0,
      1,
      2,
      0,
      2,
      3,
      1,
      0,
      2,
      3,
      2,
      0,
      3,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      2,
      3,
      3,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      3,
      0,
      2,
      2,
      1,
      1,
      3,
      3,
      3,
      1,
      2,
      3,
      2,
      2,
      3,
      3,
      0,
      2
    ],
    "wastedWeekly": [
      0,
      1,
      0,
      0,
      0,
      2,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      1
    ]
  },
  {
    "id": "PR-035",
    "openWeekly": [
      3,
      1,
      5,
      5,
      3,
      1,
      2,
      1,
      4,
      5,
      3,
      2,
      2,
      4,
      0,
      5,
      1,
      5,
      5,
      4,
      1,
      4,
      0,
      4,
      4,
      2,
      5,
      5,
      0,
      5,
      5,
      3,
      4,
      5,
      2,
      4,
      5,
      0,
      4,
      3,
      1,
      5,
      4,
      3,
      0,
      3,
      4,
      0,
      4,
      4,
      1,
      5
    ],
    "holdWeekly": [
      2,
      1,
      3,
      1,
      2,
      1,
      1,
      3,
      2,
      1,
      1,
      2,
      0,
      2,
      3,
      1,
      2,
      1,
      1,
      3,
      0,
      1,
      1,
      3,
      2,
      0,
      3,
      0,
      0,
      0,
      0,
      0,
      3,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      3,
      2,
      2,
      1,
      3,
      2,
      1
    ],
    "wastedWeekly": [
      1,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      2,
      2,
      0,
      1,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      2,
      0,
      1,
      0,
      2,
      2,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      0,
      0,
      1,
      1,
      1,
      0
    ]
  },
  {
    "id": "PR-036",
    "openWeekly": [
      0,
      3,
      2,
      1,
      1,
      5,
      5,
      3,
      1,
      4,
      3,
      2,
      4,
      5,
      0,
      0,
      0,
      5,
      3,
      0,
      2,
      5,
      3,
      1,
      3,
      5,
      1,
      2,
      3,
      2,
      2,
      1,
      5,
      2,
      0,
      0,
      4,
      1,
      0,
      0,
      1,
      4,
      4,
      3,
      0,
      0,
      4,
      3,
      2,
      3,
      3,
      1
    ],
    "holdWeekly": [
      3,
      0,
      1,
      1,
      3,
      1,
      2,
      0,
      1,
      3,
      1,
      1,
      3,
      2,
      3,
      1,
      3,
      2,
      1,
      0,
      0,
      0,
      3,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      2,
      1,
      3,
      0,
      2,
      0,
      0,
      3,
      1,
      1,
      3,
      3,
      1,
      2,
      3,
      3,
      3,
      1,
      0,
      2,
      2,
      1
    ],
    "wastedWeekly": [
      2,
      2,
      1,
      2,
      2,
      0,
      0,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      2
    ]
  },
  {
    "id": "PR-037",
    "openWeekly": [
      3,
      5,
      5,
      5,
      2,
      0,
      0,
      3,
      2,
      4,
      3,
      2,
      5,
      5,
      0,
      4,
      5,
      4,
      3,
      0,
      5,
      4,
      0,
      3,
      5,
      0,
      0,
      3,
      0,
      0,
      0,
      5,
      2,
      1,
      1,
      3,
      3,
      2,
      0,
      0,
      5,
      4,
      2,
      4,
      0,
      2,
      3,
      5,
      5,
      3,
      5,
      4
    ],
    "holdWeekly": [
      2,
      0,
      0,
      1,
      3,
      0,
      0,
      2,
      2,
      3,
      0,
      2,
      2,
      0,
      1,
      0,
      3,
      3,
      1,
      3,
      3,
      1,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      3,
      3,
      3,
      1,
      2,
      3,
      2,
      1,
      2,
      0,
      1,
      1,
      3,
      1,
      2,
      2,
      2,
      3,
      0,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      0,
      0,
      2,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-038",
    "openWeekly": [
      1,
      5,
      0,
      0,
      0,
      5,
      2,
      4,
      4,
      3,
      3,
      1,
      1,
      0,
      2,
      4,
      0,
      5,
      2,
      1,
      5,
      0,
      3,
      3,
      5,
      4,
      5,
      0,
      5,
      3,
      4,
      4,
      5,
      1,
      2,
      3,
      5,
      3,
      0,
      0,
      3,
      2,
      2,
      0,
      0,
      4,
      5,
      3,
      2,
      5,
      4,
      4
    ],
    "holdWeekly": [
      3,
      1,
      1,
      3,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      3,
      3,
      1,
      0,
      0,
      1,
      1,
      2,
      2,
      1,
      3,
      2,
      2,
      1,
      2,
      0,
      2,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      3,
      3,
      3,
      1,
      3,
      2,
      3,
      3
    ],
    "wastedWeekly": [
      0,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      2,
      1,
      2
    ]
  },
  {
    "id": "PR-039",
    "openWeekly": [
      2,
      0,
      2,
      3,
      3,
      2,
      5,
      0,
      1,
      2,
      3,
      5,
      3,
      4,
      2,
      0,
      0,
      4,
      5,
      5,
      0,
      4,
      0,
      2,
      4,
      3,
      5,
      1,
      5,
      4,
      1,
      2,
      4,
      5,
      4,
      3,
      4,
      4,
      0,
      5,
      1,
      4,
      5,
      0,
      0,
      4,
      0,
      4,
      1,
      5,
      0,
      2
    ],
    "holdWeekly": [
      3,
      0,
      1,
      1,
      1,
      1,
      0,
      3,
      3,
      2,
      3,
      3,
      0,
      3,
      3,
      2,
      3,
      3,
      1,
      1,
      1,
      0,
      0,
      2,
      0,
      2,
      3,
      3,
      1,
      3,
      3,
      1,
      3,
      2,
      2,
      0,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      3,
      2,
      3,
      2,
      3,
      2,
      0,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      0,
      2,
      2,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      0,
      2,
      0,
      2,
      1,
      2,
      2,
      2,
      0
    ]
  },
  {
    "id": "PR-040",
    "openWeekly": [
      3,
      1,
      4,
      4,
      1,
      0,
      5,
      3,
      5,
      5,
      3,
      3,
      5,
      3,
      2,
      3,
      0,
      5,
      0,
      0,
      2,
      2,
      2,
      4,
      5,
      0,
      3,
      3,
      3,
      5,
      2,
      3,
      4,
      4,
      3,
      2,
      1,
      0,
      2,
      0,
      1,
      2,
      5,
      1,
      3,
      0,
      4,
      5,
      3,
      5,
      3,
      5
    ],
    "holdWeekly": [
      3,
      0,
      1,
      2,
      0,
      2,
      2,
      0,
      0,
      1,
      1,
      2,
      2,
      3,
      3,
      2,
      3,
      1,
      3,
      0,
      1,
      0,
      3,
      3,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      0,
      3,
      1,
      0,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      3,
      3,
      1,
      3,
      2,
      2,
      3,
      0
    ],
    "wastedWeekly": [
      2,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      2,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1
    ]
  },
  {
    "id": "PR-041",
    "openWeekly": [
      0,
      0,
      3,
      3,
      4,
      5,
      3,
      4,
      5,
      5,
      1,
      0,
      1,
      2,
      2,
      1,
      0,
      4,
      1,
      1,
      1,
      3,
      3,
      0,
      5,
      3,
      1,
      3,
      4,
      2,
      5,
      3,
      2,
      5,
      3,
      5,
      4,
      5,
      3,
      4,
      0,
      3,
      2,
      0,
      0,
      3,
      4,
      4,
      0,
      5,
      1,
      2
    ],
    "holdWeekly": [
      3,
      0,
      1,
      0,
      0,
      1,
      2,
      0,
      0,
      3,
      1,
      3,
      0,
      3,
      2,
      1,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      2,
      3,
      1,
      0,
      1,
      0,
      2,
      3,
      2,
      2,
      2,
      2,
      3,
      1,
      0,
      2,
      0,
      0,
      0,
      2,
      1,
      2,
      1,
      2,
      2,
      2,
      1
    ],
    "wastedWeekly": [
      2,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      2,
      0,
      2,
      0,
      0,
      2,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      2,
      0,
      1,
      1,
      0
    ]
  },
  {
    "id": "PR-042",
    "openWeekly": [
      2,
      2,
      3,
      1,
      0,
      0,
      5,
      1,
      1,
      5,
      3,
      5,
      5,
      5,
      2,
      4,
      1,
      4,
      2,
      4,
      5,
      1,
      3,
      4,
      5,
      3,
      2,
      1,
      0,
      4,
      5,
      4,
      2,
      4,
      4,
      2,
      2,
      1,
      2,
      3,
      0,
      4,
      5,
      5,
      0,
      0,
      4,
      3,
      0,
      5,
      0,
      4
    ],
    "holdWeekly": [
      2,
      0,
      2,
      0,
      2,
      0,
      0,
      2,
      3,
      2,
      2,
      1,
      0,
      2,
      2,
      2,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      2,
      0,
      2,
      0,
      2,
      0,
      2,
      0,
      1,
      0,
      2,
      2,
      2,
      3,
      0,
      1,
      0,
      2,
      3,
      3,
      1,
      2,
      3,
      1,
      3
    ],
    "wastedWeekly": [
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      2,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      1,
      1
    ]
  },
  {
    "id": "PR-043",
    "openWeekly": [
      5,
      3,
      5,
      1,
      1,
      1,
      5,
      1,
      2,
      2,
      2,
      3,
      4,
      1,
      3,
      3,
      0,
      4,
      1,
      2,
      5,
      1,
      0,
      1,
      5,
      0,
      1,
      2,
      5,
      5,
      3,
      0,
      5,
      2,
      0,
      5,
      5,
      5,
      2,
      5,
      2,
      2,
      2,
      1,
      5,
      4,
      5,
      0,
      0,
      0,
      3,
      1
    ],
    "holdWeekly": [
      3,
      0,
      2,
      2,
      3,
      1,
      0,
      3,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      1,
      3,
      0,
      0,
      1,
      2,
      2,
      1,
      2,
      3,
      2,
      1,
      0,
      1,
      1,
      0,
      1,
      0,
      1,
      3,
      1,
      3,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      1,
      0,
      1,
      1,
      1
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      0,
      2,
      2,
      0,
      0,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      2,
      0,
      0,
      1,
      0,
      2,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      2
    ]
  },
  {
    "id": "PR-044",
    "openWeekly": [
      5,
      5,
      0,
      0,
      0,
      3,
      0,
      1,
      5,
      5,
      1,
      3,
      5,
      3,
      2,
      2,
      1,
      5,
      5,
      3,
      2,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      3,
      4,
      2,
      0,
      5,
      0,
      3,
      4,
      3,
      5,
      2,
      1,
      0,
      5,
      5,
      1,
      0,
      0,
      5,
      5,
      1,
      5,
      2,
      1
    ],
    "holdWeekly": [
      2,
      1,
      0,
      1,
      3,
      0,
      0,
      0,
      0,
      3,
      1,
      1,
      3,
      2,
      1,
      3,
      1,
      2,
      1,
      2,
      0,
      2,
      1,
      1,
      2,
      3,
      3,
      1,
      0,
      2,
      3,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      2,
      0,
      3,
      2,
      3,
      3,
      2,
      3,
      2,
      0,
      3,
      3
    ],
    "wastedWeekly": [
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      0,
      0,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      2,
      1,
      0,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      0
    ]
  },
  {
    "id": "PR-045",
    "openWeekly": [
      4,
      1,
      0,
      1,
      1,
      0,
      5,
      4,
      0,
      4,
      0,
      4,
      3,
      0,
      5,
      1,
      1,
      3,
      4,
      0,
      0,
      2,
      1,
      5,
      3,
      4,
      0,
      2,
      3,
      2,
      0,
      0,
      5,
      5,
      5,
      3,
      4,
      4,
      1,
      1,
      4,
      4,
      4,
      5,
      0,
      0,
      0,
      5,
      2,
      0,
      1,
      3
    ],
    "holdWeekly": [
      1,
      0,
      2,
      0,
      1,
      1,
      2,
      2,
      0,
      0,
      1,
      3,
      0,
      3,
      3,
      2,
      1,
      1,
      3,
      1,
      0,
      0,
      3,
      2,
      2,
      0,
      3,
      3,
      2,
      3,
      2,
      3,
      0,
      2,
      1,
      3,
      0,
      0,
      2,
      0,
      1,
      1,
      2,
      2,
      3,
      2,
      1,
      1,
      1,
      2,
      3,
      2
    ],
    "wastedWeekly": [
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      0,
      2,
      0,
      0,
      0,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      2,
      2,
      1
    ]
  },
  {
    "id": "PR-046",
    "openWeekly": [
      2,
      0,
      0,
      2,
      5,
      0,
      1,
      5,
      0,
      5,
      3,
      0,
      5,
      4,
      0,
      2,
      1,
      5,
      5,
      0,
      2,
      2,
      1,
      3,
      5,
      4,
      4,
      0,
      3,
      5,
      0,
      3,
      3,
      2,
      5,
      5,
      5,
      0,
      2,
      2,
      2,
      5,
      5,
      2,
      3,
      2,
      0,
      2,
      1,
      5,
      2,
      4
    ],
    "holdWeekly": [
      2,
      3,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      3,
      1,
      2,
      3,
      2,
      1,
      1,
      0,
      3,
      1,
      1,
      0,
      0,
      3,
      3,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      0,
      3,
      2,
      3,
      0,
      3,
      3,
      3,
      3,
      1,
      1,
      2,
      2,
      3
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      2,
      1,
      0,
      0,
      1,
      1,
      0,
      2,
      1,
      0,
      2,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-047",
    "openWeekly": [
      4,
      0,
      3,
      5,
      0,
      0,
      5,
      3,
      0,
      1,
      0,
      3,
      1,
      1,
      3,
      2,
      0,
      5,
      5,
      3,
      0,
      5,
      3,
      1,
      4,
      3,
      3,
      1,
      1,
      4,
      0,
      0,
      1,
      0,
      2,
      4,
      0,
      3,
      0,
      4,
      0,
      5,
      2,
      2,
      0,
      5,
      1,
      2,
      2,
      5,
      3,
      1
    ],
    "holdWeekly": [
      2,
      2,
      2,
      0,
      3,
      0,
      2,
      2,
      2,
      1,
      1,
      3,
      0,
      1,
      0,
      2,
      1,
      3,
      1,
      0,
      0,
      0,
      3,
      3,
      1,
      2,
      2,
      3,
      1,
      2,
      1,
      2,
      3,
      3,
      1,
      0,
      1,
      2,
      0,
      2,
      1,
      1,
      0,
      3,
      3,
      3,
      2,
      1,
      1,
      2,
      0,
      1
    ],
    "wastedWeekly": [
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      2,
      0,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      2,
      2,
      2,
      2,
      2,
      2,
      0,
      2,
      1,
      1,
      2,
      1,
      2
    ]
  },
  {
    "id": "PR-048",
    "openWeekly": [
      5,
      2,
      4,
      2,
      5,
      0,
      4,
      1,
      3,
      2,
      0,
      5,
      3,
      4,
      3,
      3,
      0,
      5,
      4,
      1,
      4,
      0,
      2,
      3,
      3,
      3,
      5,
      1,
      5,
      4,
      5,
      1,
      4,
      5,
      0,
      5,
      4,
      0,
      1,
      3,
      4,
      4,
      5,
      0,
      0,
      3,
      4,
      1,
      3,
      5,
      3,
      4
    ],
    "holdWeekly": [
      2,
      0,
      0,
      0,
      3,
      3,
      3,
      2,
      2,
      3,
      3,
      0,
      0,
      1,
      3,
      1,
      1,
      1,
      3,
      1,
      2,
      3,
      3,
      2,
      1,
      3,
      3,
      1,
      0,
      1,
      1,
      2,
      3,
      0,
      2,
      1,
      2,
      3,
      1,
      0,
      3,
      3,
      1,
      3,
      2,
      1,
      1,
      3,
      1,
      1,
      0,
      1
    ],
    "wastedWeekly": [
      0,
      2,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      2,
      2,
      2,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      1,
      0,
      1,
      2
    ]
  },
  {
    "id": "PR-049",
    "openWeekly": [
      2,
      4,
      4,
      2,
      2,
      2,
      3,
      5,
      0,
      5,
      5,
      0,
      0,
      4,
      1,
      4,
      3,
      1,
      4,
      2,
      4,
      4,
      0,
      1,
      4,
      3,
      3,
      2,
      4,
      5,
      1,
      5,
      2,
      4,
      1,
      5,
      4,
      0,
      5,
      0,
      0,
      5,
      1,
      0,
      0,
      3,
      2,
      2,
      1,
      4,
      3,
      2
    ],
    "holdWeekly": [
      1,
      1,
      1,
      1,
      2,
      2,
      3,
      3,
      0,
      3,
      2,
      2,
      2,
      1,
      1,
      3,
      1,
      2,
      3,
      1,
      1,
      0,
      3,
      2,
      0,
      2,
      0,
      3,
      0,
      0,
      0,
      2,
      0,
      1,
      3,
      2,
      2,
      0,
      1,
      1,
      0,
      2,
      3,
      1,
      2,
      1,
      1,
      2,
      3,
      3,
      3,
      1
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      2,
      0,
      1,
      0,
      1,
      2,
      2,
      1,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      2,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      2,
      0,
      1,
      0,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      2,
      2,
      0
    ]
  },
  {
    "id": "PR-050",
    "openWeekly": [
      4,
      3,
      3,
      2,
      2,
      0,
      5,
      4,
      1,
      4,
      0,
      3,
      0,
      3,
      0,
      4,
      0,
      5,
      0,
      3,
      1,
      5,
      1,
      2,
      5,
      5,
      0,
      0,
      5,
      5,
      0,
      4,
      2,
      0,
      3,
      5,
      5,
      5,
      1,
      5,
      0,
      4,
      4,
      5,
      0,
      1,
      2,
      5,
      5,
      1,
      0,
      4
    ],
    "holdWeekly": [
      0,
      3,
      0,
      1,
      3,
      3,
      2,
      2,
      3,
      2,
      0,
      1,
      3,
      3,
      3,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      3,
      3,
      3,
      0,
      3,
      1,
      2,
      1,
      0,
      0,
      2,
      2,
      3,
      3,
      0,
      2,
      3,
      1,
      0,
      1,
      1,
      1,
      3,
      2,
      2,
      1,
      3,
      3,
      1,
      1
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      2,
      1,
      0,
      2,
      1,
      1,
      2,
      1,
      0,
      2,
      0,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-051",
    "openWeekly": [
      5,
      2,
      2,
      5,
      0,
      1,
      4,
      1,
      4,
      1,
      1,
      5,
      2,
      0,
      4,
      0,
      0,
      5,
      4,
      5,
      0,
      3,
      3,
      1,
      2,
      2,
      5,
      0,
      5,
      4,
      2,
      1,
      4,
      5,
      5,
      5,
      1,
      0,
      1,
      1,
      2,
      5,
      3,
      2,
      1,
      1,
      0,
      0,
      5,
      1,
      3,
      1
    ],
    "holdWeekly": [
      2,
      0,
      0,
      1,
      3,
      1,
      3,
      2,
      1,
      2,
      1,
      2,
      2,
      2,
      2,
      1,
      2,
      3,
      3,
      2,
      2,
      2,
      3,
      2,
      2,
      1,
      0,
      2,
      3,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      3,
      1,
      3,
      1,
      2,
      3,
      3,
      2,
      1,
      1,
      1,
      2
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      1,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      0
    ]
  },
  {
    "id": "PR-052",
    "openWeekly": [
      5,
      5,
      4,
      3,
      1,
      0,
      4,
      5,
      1,
      5,
      4,
      0,
      3,
      3,
      1,
      4,
      3,
      5,
      0,
      1,
      3,
      3,
      0,
      1,
      5,
      4,
      2,
      0,
      3,
      4,
      2,
      3,
      0,
      0,
      1,
      3,
      5,
      3,
      0,
      0,
      1,
      2,
      4,
      2,
      0,
      2,
      4,
      0,
      5,
      3,
      2,
      5
    ],
    "holdWeekly": [
      1,
      2,
      0,
      2,
      2,
      1,
      2,
      2,
      0,
      3,
      1,
      2,
      2,
      3,
      3,
      0,
      2,
      3,
      3,
      0,
      2,
      0,
      3,
      2,
      0,
      1,
      0,
      2,
      2,
      0,
      0,
      0,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      0,
      2,
      1,
      1,
      3,
      1,
      1,
      3,
      3,
      3,
      2,
      2,
      1
    ],
    "wastedWeekly": [
      1,
      2,
      1,
      2,
      0,
      2,
      0,
      0,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      1,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      0
    ]
  },
  {
    "id": "PR-053",
    "openWeekly": [
      0,
      0,
      1,
      2,
      2,
      0,
      2,
      5,
      0,
      5,
      0,
      0,
      0,
      3,
      1,
      0,
      0,
      3,
      1,
      3,
      0,
      0,
      1,
      1,
      4,
      3,
      2,
      4,
      2,
      5,
      3,
      5,
      5,
      1,
      5,
      4,
      3,
      3,
      3,
      4,
      2,
      5,
      0,
      3,
      0,
      5,
      0,
      3,
      0,
      3,
      0,
      0
    ],
    "holdWeekly": [
      3,
      0,
      0,
      2,
      2,
      1,
      1,
      3,
      0,
      3,
      1,
      0,
      2,
      3,
      1,
      2,
      1,
      2,
      2,
      3,
      3,
      0,
      1,
      1,
      2,
      0,
      3,
      3,
      0,
      1,
      0,
      1,
      3,
      0,
      1,
      1,
      1,
      3,
      2,
      1,
      3,
      1,
      0,
      1,
      2,
      1,
      3,
      3,
      0,
      0,
      1,
      0
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      0,
      2,
      1,
      2,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      1,
      1,
      1,
      0,
      1,
      2,
      2
    ]
  },
  {
    "id": "PR-054",
    "openWeekly": [
      4,
      5,
      0,
      1,
      0,
      0,
      3,
      5,
      2,
      3,
      0,
      4,
      3,
      3,
      5,
      0,
      0,
      4,
      5,
      4,
      2,
      0,
      0,
      5,
      5,
      4,
      5,
      4,
      4,
      5,
      3,
      1,
      5,
      5,
      2,
      2,
      5,
      1,
      0,
      2,
      3,
      1,
      5,
      1,
      0,
      4,
      0,
      5,
      1,
      2,
      5,
      3
    ],
    "holdWeekly": [
      2,
      3,
      3,
      2,
      0,
      2,
      2,
      3,
      3,
      3,
      3,
      3,
      1,
      2,
      3,
      1,
      1,
      3,
      1,
      1,
      0,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      3,
      0,
      3,
      0,
      2,
      3,
      0,
      0,
      3,
      2,
      1,
      0,
      2,
      0,
      1,
      3,
      1,
      2,
      0,
      3,
      1,
      2,
      2
    ],
    "wastedWeekly": [
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      2,
      2,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      2,
      1,
      2,
      2,
      2,
      1,
      0,
      1,
      2,
      2,
      2,
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      2,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      2,
      1
    ]
  },
  {
    "id": "PR-055",
    "openWeekly": [
      5,
      5,
      4,
      2,
      1,
      1,
      4,
      2,
      0,
      1,
      1,
      1,
      5,
      2,
      3,
      3,
      1,
      5,
      0,
      2,
      0,
      2,
      5,
      1,
      5,
      0,
      5,
      1,
      4,
      2,
      2,
      1,
      5,
      2,
      0,
      4,
      4,
      1,
      2,
      1,
      2,
      5,
      2,
      4,
      1,
      5,
      3,
      0,
      3,
      3,
      5,
      0
    ],
    "holdWeekly": [
      2,
      0,
      3,
      0,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      1,
      3,
      3,
      3,
      1,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      3,
      2,
      0,
      2,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      3,
      0,
      3,
      3,
      1,
      3,
      3,
      1,
      3,
      3,
      2,
      1,
      3,
      1,
      1
    ],
    "wastedWeekly": [
      1,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      2,
      0,
      1,
      0,
      0,
      1,
      2,
      1,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      2,
      2,
      2
    ]
  },
  {
    "id": "PR-056",
    "openWeekly": [
      5,
      5,
      2,
      2,
      0,
      3,
      2,
      2,
      1,
      3,
      2,
      5,
      1,
      0,
      5,
      2,
      0,
      5,
      0,
      0,
      4,
      3,
      0,
      1,
      5,
      0,
      5,
      5,
      3,
      5,
      3,
      0,
      4,
      3,
      0,
      5,
      1,
      0,
      0,
      4,
      3,
      0,
      5,
      0,
      2,
      0,
      0,
      3,
      0,
      5,
      5,
      5
    ],
    "holdWeekly": [
      2,
      1,
      2,
      0,
      1,
      1,
      3,
      1,
      3,
      0,
      1,
      3,
      1,
      1,
      1,
      2,
      1,
      2,
      1,
      0,
      0,
      0,
      3,
      2,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      3,
      3,
      0,
      0,
      0,
      1,
      0,
      0,
      3,
      2,
      0,
      3,
      3,
      3,
      3,
      3,
      1,
      2,
      3,
      1,
      3
    ],
    "wastedWeekly": [
      0,
      2,
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      2,
      2,
      1,
      0,
      1,
      2,
      1,
      2,
      0,
      1,
      2,
      1,
      1,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-057",
    "openWeekly": [
      2,
      0,
      4,
      2,
      1,
      1,
      3,
      1,
      0,
      5,
      0,
      2,
      2,
      1,
      2,
      1,
      5,
      1,
      5,
      2,
      2,
      4,
      1,
      1,
      4,
      3,
      5,
      3,
      2,
      5,
      2,
      0,
      1,
      2,
      5,
      3,
      5,
      3,
      0,
      3,
      0,
      5,
      2,
      0,
      1,
      5,
      1,
      3,
      0,
      0,
      0,
      3
    ],
    "holdWeekly": [
      1,
      1,
      1,
      1,
      0,
      0,
      0,
      3,
      3,
      3,
      3,
      2,
      2,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      1,
      3,
      2,
      2,
      2,
      0,
      1,
      1,
      0,
      2,
      3,
      0,
      0,
      2,
      0,
      3,
      3,
      3,
      2,
      2,
      1,
      3,
      2,
      2,
      1,
      1,
      0
    ],
    "wastedWeekly": [
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      0,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      0,
      0,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      0
    ]
  },
  {
    "id": "PR-058",
    "openWeekly": [
      4,
      4,
      0,
      3,
      1,
      4,
      4,
      1,
      3,
      5,
      1,
      5,
      2,
      1,
      3,
      1,
      0,
      4,
      5,
      0,
      2,
      2,
      2,
      2,
      5,
      1,
      1,
      4,
      0,
      5,
      1,
      2,
      5,
      5,
      0,
      5,
      5,
      3,
      5,
      0,
      4,
      4,
      5,
      2,
      0,
      0,
      5,
      3,
      0,
      5,
      4,
      1
    ],
    "holdWeekly": [
      3,
      2,
      0,
      1,
      3,
      0,
      0,
      3,
      2,
      3,
      1,
      0,
      2,
      1,
      3,
      2,
      3,
      3,
      0,
      1,
      0,
      0,
      0,
      3,
      2,
      1,
      2,
      3,
      2,
      1,
      0,
      1,
      3,
      0,
      2,
      2,
      2,
      2,
      2,
      0,
      2,
      0,
      0,
      2,
      3,
      1,
      2,
      3,
      1,
      2,
      2,
      3
    ],
    "wastedWeekly": [
      1,
      2,
      2,
      2,
      0,
      0,
      2,
      1,
      1,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      0,
      1,
      2,
      0,
      0,
      2,
      1,
      0,
      0,
      0,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      0,
      1,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      0,
      2,
      0
    ]
  },
  {
    "id": "PR-059",
    "openWeekly": [
      5,
      3,
      3,
      0,
      4,
      0,
      5,
      2,
      1,
      5,
      1,
      1,
      5,
      5,
      2,
      3,
      0,
      2,
      2,
      5,
      0,
      5,
      5,
      3,
      2,
      0,
      4,
      4,
      2,
      5,
      0,
      2,
      5,
      5,
      0,
      5,
      5,
      3,
      0,
      5,
      2,
      1,
      2,
      3,
      0,
      3,
      2,
      2,
      2,
      3,
      3,
      0
    ],
    "holdWeekly": [
      3,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      0,
      2,
      0,
      2,
      2,
      3,
      2,
      3,
      3,
      2,
      3,
      3,
      0,
      0,
      2,
      2,
      3,
      1,
      2,
      0,
      2,
      0,
      0,
      0,
      3,
      1,
      1,
      3,
      1,
      0,
      2,
      1,
      2,
      0,
      2,
      2,
      3,
      2,
      2,
      3,
      1,
      2,
      3,
      0
    ],
    "wastedWeekly": [
      1,
      2,
      0,
      1,
      1,
      1,
      0,
      2,
      1,
      1,
      1,
      2,
      0,
      2,
      1,
      2,
      1,
      2,
      1,
      0,
      2,
      1,
      2,
      0,
      0,
      1,
      2,
      0,
      1,
      0,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      2,
      1,
      1,
      1,
      0,
      0,
      0,
      1,
      2,
      2,
      2,
      1
    ]
  },
  {
    "id": "PR-060",
    "openWeekly": [
      3,
      1,
      2,
      2,
      1,
      0,
      4,
      1,
      3,
      5,
      1,
      5,
      0,
      0,
      5,
      3,
      1,
      5,
      1,
      3,
      3,
      2,
      2,
      3,
      2,
      3,
      5,
      0,
      5,
      3,
      0,
      5,
      2,
      3,
      4,
      5,
      3,
      5,
      0,
      4,
      0,
      1,
      5,
      0,
      0,
      5,
      0,
      5,
      5,
      2,
      0,
      5
    ],
    "holdWeekly": [
      2,
      1,
      1,
      1,
      0,
      1,
      0,
      3,
      1,
      2,
      1,
      2,
      1,
      2,
      1,
      1,
      2,
      3,
      2,
      3,
      0,
      0,
      0,
      1,
      3,
      0,
      3,
      3,
      2,
      3,
      1,
      3,
      3,
      1,
      2,
      2,
      2,
      0,
      2,
      1,
      2,
      3,
      1,
      1,
      1,
      1,
      1,
      2,
      0,
      1,
      1,
      3
    ],
    "wastedWeekly": [
      0,
      2,
      2,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      0,
      2,
      0,
      1,
      2,
      1,
      2,
      1,
      1,
      0,
      2,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1,
      0,
      1,
      1,
      2,
      2,
      2,
      2,
      2,
      2,
      0,
      2,
      0,
      1,
      2,
      0,
      0,
      0,
      2,
      2,
      0,
      2,
      1,
      1
    ]
  },
  {
    "id": "PR-061",
    "openWeekly": [
      5,
      5,
      3,
      3,
      0,
      5,
      0,
      0,
      4,
      3,
      0,
      5,
      0,
      4,
      0,
      4,
      4,
      4,
      1,
      5,
      3,
      3,
      1,
      5,
      5,
      2,
      1,
      3,
      2,
      3,
      5,
      0,
      2,
      1,
      5,
      3,
      3,
      0,
      0,
      0,
      3,
      5,
      5,
      0,
      0,
      1,
      5,
      3,
      5,
      5,
      4,
      3
    ],
    "holdWeekly": [
      2,
      3,
      2,
      0,
      1,
      0,
      2,
      1,
      0,
      2,
      3,
      2,
      2,
      2,
      1,
      2,
      1,
      3,
      3,
      1,
      2,
      0,
      3,
      3,
      1,
      2,
      0,
      0,
      2,
      1,
      1,
      2,
      3,
      0,
      3,
      0,
      0,
      3,
      1,
      3,
      1,
      1,
      2,
      3,
      1,
      2,
      1,
      1,
      2,
      0,
      1,
      2
    ],
    "wastedWeekly": [
      2,
      2,
      1,
      2,
      2,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      1,
      2,
      0,
      0,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      1,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      1,
      2,
      2,
      0,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      1,
      0,
      2,
      0,
      0
    ]
  },
  {
    "id": "PR-062",
    "openWeekly": [
      4,
      1,
      0,
      5,
      2,
      4,
      1,
      5,
      0,
      2,
      1,
      0,
      3,
      0,
      1,
      2,
      0,
      5,
      4,
      3,
      0,
      5,
      4,
      0,
      0,
      5,
      5,
      3,
      4,
      4,
      0,
      1,
      5,
      2,
      2,
      3,
      4,
      0,
      0,
      1,
      0,
      2,
      3,
      0,
      2,
      2,
      0,
      3,
      3,
      0,
      5,
      5
    ],
    "holdWeekly": [
      1,
      3,
      1,
      2,
      2,
      1,
      2,
      1,
      3,
      3,
      1,
      2,
      3,
      1,
      1,
      3,
      1,
      1,
      3,
      2,
      1,
      2,
      3,
      0,
      0,
      0,
      3,
      1,
      0,
      1,
      1,
      2,
      2,
      3,
      3,
      3,
      2,
      0,
      2,
      1,
      2,
      0,
      0,
      2,
      0,
      3,
      1,
      0,
      2,
      2,
      1,
      1
    ],
    "wastedWeekly": [
      2,
      1,
      1,
      2,
      0,
      1,
      0,
      1,
      1,
      0,
      2,
      2,
      2,
      0,
      2,
      1,
      0,
      2,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      0,
      1,
      2,
      1,
      2,
      2,
      1,
      2,
      2,
      0,
      0,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      1,
      2,
      0,
      0,
      1,
      0,
      1,
      1,
      0
    ]
  },
  {
    "id": "PR-063",
    "openWeekly": [
      5,
      1,
      3,
      4,
      4,
      3,
      5,
      0,
      2,
      5,
      5,
      5,
      5,
      3,
      3,
      1,
      0,
      3,
      5,
      0,
      0,
      5,
      3,
      0,
      3,
      5,
      4,
      4,
      5,
      5,
      0,
      2,
      5,
      4,
      5,
      4,
      2,
      0,
      0,
      3,
      0,
      4,
      5,
      4,
      3,
      4,
      4,
      2,
      1,
      3,
      2,
      1
    ],
    "holdWeekly": [
      2,
      1,
      0,
      2,
      3,
      3,
      0,
      0,
      2,
      3,
      3,
      0,
      2,
      2,
      1,
      3,
      3,
      1,
      1,
      3,
      2,
      1,
      3,
      3,
      1,
      3,
      3,
      2,
      2,
      2,
      1,
      1,
      3,
      3,
      0,
      3,
      1,
      3,
      2,
      1,
      2,
      1,
      3,
      1,
      3,
      2,
      3,
      3,
      2,
      2,
      2,
      1
    ],
    "wastedWeekly": [
      2,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      2,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      0,
      2,
      0,
      1,
      2,
      1,
      0,
      2,
      2,
      1,
      1,
      2,
      2,
      0,
      1,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      0,
      0,
      1,
      0
    ]
  },
  {
    "id": "PR-064",
    "openWeekly": [
      4,
      5,
      4,
      3,
      0,
      3,
      3,
      5,
      0,
      1,
      2,
      2,
      1,
      2,
      1,
      2,
      0,
      0,
      3,
      0,
      0,
      4,
      5,
      1,
      2,
      5,
      5,
      5,
      2,
      5,
      0,
      0,
      2,
      5,
      1,
      5,
      3,
      5,
      0,
      0,
      5,
      3,
      5,
      5,
      0,
      1,
      1,
      0,
      4,
      4,
      0,
      5
    ],
    "holdWeekly": [
      2,
      1,
      2,
      0,
      2,
      0,
      3,
      2,
      0,
      3,
      2,
      1,
      0,
      3,
      1,
      2,
      2,
      1,
      1,
      2,
      0,
      0,
      3,
      2,
      2,
      0,
      2,
      0,
      1,
      0,
      0,
      2,
      3,
      0,
      3,
      1,
      0,
      0,
      1,
      1,
      3,
      2,
      1,
      0,
      3,
      1,
      0,
      0,
      1,
      3,
      3,
      0
    ],
    "wastedWeekly": [
      0,
      1,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      2,
      2,
      0,
      0,
      0,
      1,
      1,
      1,
      2,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      2,
      1,
      0,
      1,
      0,
      0,
      2,
      0,
      1,
      1,
      0,
      2,
      2,
      1,
      1,
      2,
      1,
      0,
      0,
      2,
      2,
      1,
      2,
      1
    ]
  },
  {
    "id": "PR-065",
    "openWeekly": [
      2,
      2,
      0,
      0,
      2,
      5,
      0,
      5,
      0,
      2,
      3,
      3,
      0,
      3,
      2,
      0,
      0,
      3,
      0,
      0,
      3,
      2,
      1,
      2,
      2,
      2,
      5,
      3,
      5,
      5,
      0,
      1,
      5,
      2,
      1,
      4,
      5,
      5,
      2,
      4,
      1,
      2,
      5,
      5,
      1,
      5,
      1,
      2,
      5,
      4,
      4,
      2
    ],
    "holdWeekly": [
      1,
      1,
      2,
      2,
      2,
      1,
      3,
      0,
      2,
      0,
      1,
      1,
      1,
      3,
      3,
      0,
      2,
      3,
      0,
      0,
      3,
      0,
      2,
      0,
      1,
      1,
      3,
      3,
      3,
      0,
      0,
      3,
      3,
      0,
      3,
      0,
      0,
      0,
      2,
      1,
      3,
      3,
      3,
      1,
      2,
      3,
      0,
      1,
      3,
      3,
      1,
      0
    ],
    "wastedWeekly": [
      1,
      0,
      1,
      0,
      2,
      2,
      1,
      2,
      2,
      0,
      1,
      0,
      0,
      2,
      1,
      0,
      1,
      2,
      0,
      1,
      1,
      2,
      1,
      2,
      1,
      1,
      1,
      0,
      2,
      1,
      2,
      2,
      2,
      1,
      1,
      0,
      0,
      1,
      1,
      0,
      1,
      1,
      1,
      1,
      1,
      0,
      1,
      1,
      0,
      0,
      1,
      1
    ]
  },
  {
    "id": "PR-066",
    "openWeekly": [
      3,
      3,
      3,
      4,
      0,
      0,
      2,
      3,
      1,
      2,
      0,
      5,
      0,
      0,
      0,
      5,
      0,
      4,
      0,
      5,
      4,
      4,
      2,
      1,
      4,
      5,
      2,
      5,
      3,
      4,
      2,
      0,
      5,
      4,
      1,
      5,
      5,
      5,
      2,
      4,
      0,
      0,
      5,
      1,
      0,
      4,
      0,
      0,
      1,
      4,
      4,
      5
    ],
    "holdWeekly": [
      2,
      1,
      3,
      1,
      0,
      1,
      2,
      3,
      3,
      0,
      1,
      3,
      0,
      1,
      1,
      2,
      1,
      3,
      3,
      0,
      0,
      1,
      0,
      2,
      3,
      0,
      1,
      3,
      2,
      1,
      2,
      2,
      3,
      0,
      2,
      0,
      1,
      0,
      2,
      1,
      2,
      3,
      3,
      1,
      3,
      2,
      2,
      3,
      2,
      2,
      3,
      1
    ],
    "wastedWeekly": [
      1,
      2,
      2,
      1,
      1,
      2,
      1,
      1,
      2,
      1,
      0,
      1,
      0,
      1,
      2,
      0,
      0,
      0,
      2,
      0,
      2,
      0,
      2,
      2,
      2,
      1,
      2,
      0,
      2,
      1,
      2,
      2,
      1,
      1,
      0,
      1,
      0,
      0,
      1,
      2,
      1,
      1,
      1,
      1,
      2,
      2,
      0,
      1,
      2,
      0,
      1,
      0
    ]
  }
]